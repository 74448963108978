import BaseCollection from "./BaseCollection";

//////////////////////////////////
//Phần thay thế
import TemplateModel from "common/models/TemplateModel";
import { TemplateJson, TemplateCollectionJson } from "common/types/Template";

class TemplateCollection extends BaseCollection<
	TemplateJson,
	TemplateModel,
	TemplateCollectionJson
> {
	itemsFromJson(jsonItems: TemplateJson[]): TemplateModel[] {
		return jsonItems.map((item) => new TemplateModel(item));
	}
}
export default TemplateCollection;
