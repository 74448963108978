import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Popconfirm,
  Row,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";

import { IconPhoto, IconPlus, IconTrash } from "@tabler/icons-react";
import Collection from "common/constants/Collection";
import File from "common/constants/File";
import FileModel from "common/models/FileModel";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import CollectionRepository from "common/repositories/CollectionRepository";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import {
  CollectionCascadeOptions,
  CollectionFormData,
} from "common/types/Collection";
import {
  ProductCollectionAddEditJson,
  URLWebJson,
} from "common/types/ProductCollection";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FormSelectNormal from "components/form/FormSelectNormal";
import ProductCollectionFormHeader from "./ProductCollectionFormHeader";
import AddListToItemComponent from "./children/AddListToItemComponent";
import ProductModel from "common/models/ProductModel";
import { SelectOption } from "common/types/SelectOption";
import { IconEye } from "@tabler/icons-react";
import NoPhoto from "components/NoPhoto";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import TagConstant from "common/constants/Tag";
import TagDetailModel from "common/models/TagDetailModel";
import usePathParams from "hooks/usePathParams";
import ProductCollectionConstant from "common/constants/ProductCollection";
import TextArea from "antd/lib/input/TextArea";
import FormItemHandleInput from "./children/FormItemHandleInput";

type Props = {
  model: ProductCollectionModel;
};
const ProductCollectionForm = ({ model }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;
  const [getValue, setValue] = useFormDefaultStore((state) => [
    state.getValue,
    state.setValue,
  ]);

  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [typeCollection, setTypeCollection] = useState(Collection.TYPE_STORY);
  const [categoryList, setCategoryList] = useState<CollectionCascadeOptions[]>(
    []
  );
  const [type, setType] = useState<number>(
    model.type > 0 ? model.type : ProductCollectionConstant.TYPE.MANUAL
  );
  const [url, setUrl] = useState<string>(model.handle);
  const [urlValue, setUrlValue] = useState<URLWebJson>({ url: "" });

  console.log("🥺 ~ ProductCollectionForm ~ type:", type);
  //image

  const [bannerDesktop, setBannerDesktop] = useState<{
    url: string;
    id: number;
  }>({
    url: model.banner_desktop,
    id: 0,
  });
  const [bannerMobile, setBannerMobile] = useState<{
    url: string;
    id: number;
  }>({
    url: model.banner_mobile,
    id: 0,
  });

  const [images, setImages] = useState<
    {
      url: string;
      id: number;
    }[]
  >([]);
  console.log("🥺 ~ ProductCollectionForm ~ bannerDesktop:", bannerDesktop);

  //add product
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>(model.item_ids);

  //add tag
  const [selectTagReload, setSelectTagReload] = useState<boolean>(false);
  const [existTagValues, setExistTagValue] = useState<number[]>([]);

  //cascade
  const [openItems, setOpenItems] = useState<CollectionCascadeOptions[]>([]);
  const [parentItems, setParentItems] = useState<number[]>([]);

  //default data
  const initialValues = useMemo(() => {
    return {
      title: model.title,
      handle: model.handle,
      parent_id:
        // parentItems,
        model.parent_id,
      publish_from:
        model.publish_from > 0 ? dayjs(new Date(model.publish_from * 1000)) : 0,
      publish_to:
        model.publish_to > 0 ? dayjs(new Date(model.publish_to * 1000)) : 0,
      date_published:
        model.date_published > 0
          ? dayjs(new Date(model.date_published * 1000))
          : 0,
      // banner_desktop_image: 0,
      banner_desktop: [],
      banner_mobile: [],
      images: [],
      // banner_mobile_image: 0,
      // image: FileModel.convertToUploadFiles(
      //   model.images.map((item) => {
      //     return new FileModel(item);
      //   })
      // ),
      // banner_desktop_image:
      //   model.banner_destop !== null
      //     ? FileModel.convertToUploadFiles([new FileModel(model.banner_destop)])
      //     : FileModel.convertToUploadFiles([]),
      // banner_mobile_image:
      //   model.banner_mobile !== null
      //     ? FileModel.convertToUploadFiles([new FileModel(model.banner_mobile)])
      //     : FileModel.convertToUploadFiles([]),
      // banner_desktop_image:
      //   model.banner_destop.length > 0 && model.banner_destop
      //     ? FileModel.convertToUploadFiles(
      //         model.banner_destop.map((item) => new FileModel(item))
      //       )
      //     : FileModel.convertToUploadFiles([]),
      // banner_mobile_image:
      //   model.banner_mobile.length > 0 && model.banner_mobile
      //     ? FileModel.convertToUploadFiles(
      //         model.banner_mobile.map((item) => new FileModel(item))
      //       )
      //     : FileModel.convertToUploadFiles([]),
      // date_range: [
      //   model.publish_from > 0
      //     ? dayjs(new Date(model.publish_from * 1000))
      //     : null,
      //   model.publish_to > 0 ? dayjs(new Date(model.publish_to * 1000)) : null,
      // ],
      pin: model.pin > 0 ? model.pin : 0,
      description: model.description,
      status: model.id === 0 ? 1 : model.status,
      allow_promotion: model.id === 0 ? 1 : model.allow_promotion,
      type: model.type > 0 ? model.type : ProductCollectionConstant.TYPE.MANUAL,
      dynamic: model.dynamic,
    };
  }, [model, getValue, parentItems]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      console.log(images)
      console.log("🥺 ~ ProductCollectionForm ~ formData:", formData);
      const submitData: any = {
        id: model.id,
        title: formData.title.trim(),
        handle: formData.handle.trim(),
        // parent_id:
        //   formData.parent_id.length > 0
        //     ? formData.parent_id[formData.parent_id.length - 1]
        //     : 0,
        parent_id: 0,
        // date_published: Helper.datePickerValueToTimestamp(
        //   formData.date_published
        // ),
        // publish_from: Helper.datePickerValueToTimestamp(formData.date_range[0]),
        // publish_to: Helper.datePickerValueToTimestamp(formData.date_range[1]),
        publish_from:
          Helper.datePickerValueToTimestamp(formData.publish_from) > 0
            ? Helper.datePickerValueToTimestamp(formData.publish_from)
            : 0,
        publish_to:
          Helper.datePickerValueToTimestamp(formData.publish_to) > 0
            ? Helper.datePickerValueToTimestamp(formData.publish_to)
            : 0,
        date_published:
          Helper.datePickerValueToTimestamp(formData.date_published) > 0
            ? Helper.datePickerValueToTimestamp(formData.date_published)
            : 0,
        description:
          formData.description.length > 0 ? formData.description.trim() : "",
        // images:
        //   formData.image &&
        //   formData.image.length > 0 &&
        //   formData.image[0].response
        //     ? formData.image[0].response.id
        //     : 0,
        // banner_destop:
        //   formData.banner_desktop_image &&
        //   formData.banner_desktop_image.length > 0 &&
        //   formData.banner_desktop_image[0].response
        //     ? formData.banner_desktop_image[0]?.response.id
        //     : undefined,
        images:
          images.length > 0 && images[0].id > 0
            ? images.map((item) => item.id).toString()
            : images.length > 0 && images[0].id === 0
            ? undefined
            : [],
        banner_desktop:
          bannerDesktop.id > 0
            ? bannerDesktop.id
            : bannerDesktop.url.length > 0
            ? undefined
            : 0,
        banner_mobile:
          bannerMobile.id > 0
            ? bannerMobile.id
            : bannerMobile.url.length > 0
            ? undefined
            : 0,
        // banner_mobile:
        //   formData.banner_mobile_image &&
        //   formData.banner_mobile_image.length > 0 &&
        //   formData.banner_mobile_image[0].response
        //     ? formData.banner_mobile_image[0]?.response.id
        //     : undefined,
        pin: formData.pin,
        status: formData.status,
        allow_promotion: formData.allow_promotion === true ? 1 : 0,
        type: formData.type,
        dynamic:
          type === ProductCollectionConstant.TYPE.DYNAMIC
            ? formData.dynamic
            : "",
      };

      setValue("productcategory:parent_id", formData.parent_id);

      return submitData;
    },
    [model.id, setValue, type, bannerDesktop, bannerMobile, images]
  );

  //functions
  const convertToTree = (data: any[], parent_id = 0) => {
    const tree = [];

    for (const item of data) {
      if (item.parent_id === parent_id) {
        const children: any[] = convertToTree(data, item.id);
        if (children.length) {
          item.children = children;
        }
        tree.push({
          value: item.id,
          label: item.title,
          children: children,
        });
      }
    }

    return tree;
  };

  const findItemAndParents = (
    items: CollectionCascadeOptions[],
    idToFind: number
  ) => {
    let foundParents: number[] = [];

    const findItemRecursively = (
      items: CollectionCascadeOptions[],
      idToFind: number
    ) => {
      for (const item of items) {
        if (item.value === idToFind) {
          foundParents = [item.value, ...foundParents];
          setParentItems((prev) => [item.value, ...prev]);
          setOpenItems((prev) => [item, ...prev]);
          return item;
        }

        if (item.children && item.children.length > 0) {
          if (findItemRecursively(item.children, idToFind)) {
            foundParents = [item.value, ...foundParents];
            setParentItems((prev) => [item.value, ...prev]);
            setOpenItems((prev) => [item, ...prev]);
            return item;
          }
        }
      }
      return null;
    };

    const foundItem = findItemRecursively(items, idToFind);

    return { foundItem, foundParents };
  };

  //submit data to server
  const onSubmit = async (formData: CollectionFormData) => {
    console.log(
      "🥺 ~ onSubmit ~ formData:",
      console.log(doPrepareData(formData))
    );
    const response: ProductCollectionModel =
      await new ProductCollectionRepository().saveRemote(
        doPrepareData(formData)
      );
    if (!response.hasError()) {
      setIsSuccess(true);
    } else {
      setErrors(response.error.errors);
    }
  };

  //sidebar form
  const sidebarItems = <></>;

  const fetchUrlWebsite = async (handle: string) => {
    // const reponse = await new ProductCollectionRepository().getUrlFromHandle({
    //   collection_id: handle,
    // });
    // if (!reponse.error) {
    //   setUrlValue(reponse);
    // }
  };

  useEffect(() => {
    if (model.handle.length > 0) {
      fetchUrlWebsite(model.handle);
    }
    if (model.id > 0) {
      setBannerDesktop({
        id: 0,
        url: model.banner_desktop,
      });
      setBannerMobile({
        id: 0,
        url: model.banner_mobile,
      });
      setImages(
        model.images.map((item) => {
          return {
            id: 0,
            url: item.url,
          };
        })
      );
    }
  }, [model]);

  return (
    <div>
      <ProductCollectionFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess="/productcollection"
        error_translate_prefix="productcollection:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("productcollection:form.section_collection")}
          subtitle={t("productcollection:form.section_collection_subtitle")}
          divider
        >
          <Row gutter={8}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("productcategory:name")}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t("productcollection:rules.title_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("productcollection:effective_date")}
                name="date_published"
              >
                <DatePicker
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                required
                label={t("storycollection:publish_from")}
                name="publish_from"
                style={{ width: "100%" }}
                rules={[
                  {
                    message: t("leave:form.error.required_date"),
                    validator: (_: any, value: Dayjs) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                  {
                    message: t("storycollection:form.error.error_publish_from"),
                    validator: (_: any, value: any) => {
                      if (
                        Helper.datePickerValueToTimestamp(
                          form.getFieldValue("date_published")
                        ) >= Helper.datePickerValueToTimestamp(value) ||
                        !Helper.datePickerValueToTimestamp(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("storycollection:publish_to")}
                name="publish_to"
                style={{ width: "100%" }}
                rules={[
                  {
                    message: t("leave:form.error.error_range_date"),
                    validator: (_: any, value: any) => {
                      if (
                        Helper.datePickerValueToTimestamp(
                          form.getFieldValue("publish_from")
                        ) < Helper.datePickerValueToTimestamp(value) ||
                        !Helper.datePickerValueToTimestamp(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                  disabledDate={(current) => {
                    return current.isBefore(form.getFieldValue("publish_from"));
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <FormSelectNormal
                name="pin"
                label={t("productcollection:pin")}
                onChange={(e) => {
                  setTypeCollection(Number(e));
                  form.setFieldsValue({ parent_id: [] });
                }}
                options={[
                  { value: 1, label: "Có" },
                  { value: 0, label: "Không" },
                ]}
              />
            </Col>
            <Col md={8} xs={24}>
              <FormSelectNormal
                name="type"
                label={"Loại"}
                onChange={(e) => {
                  setType(Number(e));
                }}
                options={ProductCollectionModel.getTypeSelectList()}
              />
            </Col>
            {type === ProductCollectionConstant.TYPE.DYNAMIC && (
              <>
                <Col md={24} xs={24}>
                  <Form.Item
                    label={"Dynamic (chỉ IT nhập)"}
                    name="dynamic"
                    rules={[
                      {
                        validator(_, value, callback) {
                          if (type === ProductCollectionConstant.TYPE.DYNAMIC) {
                            if (value.length === 0) {
                              return Promise.reject("Some message here");
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                        message: "Vui lòng nhập",
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col md={8} xs={24}>
              <FormSelectNormal
                name="status"
                label={"Trạng thái"}
                options={[
                  { value: 1, label: "Hiện" },
                  { value: 0, label: "Ẩn" },
                ]}
              />
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                name="allow_promotion"
                valuePropName="checked"
                label={""}
              >
                <Checkbox>{t("product:allow_promotion")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("productcollection:form.section_norequired")}
          subtitle={t(
            "productcollection:form.section_collection_subtitle_norequired"
          )}
        >
          <Row gutter={8}>
            <Col md={24} xs={24}>
              <Form.Item label={t("productcollection:handle")} name="handle">
                <Input
                  placeholder={t("product:placeholder_URL")}
                  onBlur={() => {
                    if (url.length > 0) {
                      fetchUrlWebsite(url);
                    }
                  }}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
              </Form.Item>
              <FormItemHandleInput
                prefix={process.env.SHOP_DOMAIN_URL + "/collections" || ""}
                value={url}
              />
            </Col>

            <Col md={24} span={24}>
              <Typography.Text>Images</Typography.Text>
              {images.length > 0 ? (
                <>
                  <div className="flex items-start flex-wrap">
                    {images.map((banner, index) => (
                      <div key={index} className="relative m-2">
                        <Image
                          className="cursor-pointer"
                          src={banner.url}
                          width={100}
                          height={100}
                          preview={true}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                        <Popconfirm
                          title="Bạn có muốn xóa ảnh"
                          onConfirm={() => {
                            setImages(images.filter((_, i) => i !== index));
                            form.setFieldValue(
                              "images",
                              images.filter((_, i) => i !== index)
                            );
                          }}
                        >
                          <Button
                            // type="danger"
                            shape="circle"
                            icon={
                              <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                            }
                            className="absolute top-0 right-0"
                          ></Button>
                        </Popconfirm>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
              <Form.Item className="m-0">
                <FileUploaderButton
                  form={form}
                  formName="images"
                  label={t("Images")}
                  icon={<IconPhoto size="24" color="grey" />}
                  origin="avatar"
                  uploadRenderType="thumbnail"
                  isSingleFile={false}
                  maxCount={10} // Số lượng ảnh tối đa
                  initFiles={[]}
                  showUploadList={false}
                  onUploadCompleted={(uploadedFiles) => {
                    setImages([
                      ...images,
                      {
                        id: uploadedFiles.id,
                        url: uploadedFiles.url,
                      },
                    ]);
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={24}>
              <Typography.Text> Banner desktop</Typography.Text>
              {bannerDesktop.url.length > 0 ? (
                <>
                  <div className="flex items-start flex-wrap">
                    <div className="relative m-2">
                      <Image
                        className="cursor-pointer"
                        src={bannerDesktop.url}
                        width={100}
                        height={100}
                        preview={true}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      <Popconfirm
                        title="Bạn có muốn xóa ảnh"
                        onConfirm={() => {
                          setBannerDesktop({ url: "", id: 0 });
                          form.setFieldValue("banner_desktop", []);
                        }}
                      >
                        <Button
                          shape="circle"
                          icon={
                            <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                          }
                          className="absolute top-0 right-0"
                        ></Button>
                      </Popconfirm>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="banner_desktop"
                      label={t("Banner desktop")}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setBannerDesktop({ id: f.id, url: f.url });
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </Col>

            <Col md={24}>
              <Typography.Text> Banner mobile</Typography.Text>
              {bannerMobile.url.length > 0 ? (
                <>
                  <div className="flex items-start flex-wrap">
                    <div className="relative m-2">
                      <Image
                        className="cursor-pointer"
                        src={bannerMobile.url}
                        width={100}
                        height={100}
                        preview={true}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      <Popconfirm
                        title="Bạn có muốn xóa ảnh"
                        onConfirm={() => {
                          setBannerMobile({ url: "", id: 0 });
                          form.setFieldValue("banner_mobile", []);
                        }}
                      >
                        <Button
                          shape="circle"
                          icon={
                            <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                          }
                          className="absolute top-0 right-0"
                        ></Button>
                      </Popconfirm>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="banner_mobile"
                      label={t("Banner mobile")}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setBannerMobile({ id: f.id, url: f.url });
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </Col>

            <Col md={24} xs={24}>
              <Form.Item label={t("collection:describe")} name="description">
                <Input.TextArea
                  allowClear
                  rows={3}
                  placeholder={t("collection:describe_placeholder")}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default ProductCollectionForm;
