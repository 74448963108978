import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Divider, Typography, Button } from "antd";
import useFunction from "hooks/useFunction";
import UserResetPassForm from "features/user/UserResetPassForm";

const UserResetPassPage = () => {
  const { t } = useTranslation();
  const { doLogOut } = useFunction();
  return (
    <div className="p-6 mt-4 mx-auto max-w-md">
      <Typography.Title level={3}>
        {t("user:heading_resetpass")}
      </Typography.Title>
      <p>{t("user:subtitle_resetpass")}</p>
      <UserResetPassForm />
      <Divider />
      <Link to="/user/login">
        <Button type="link" onClick={() => doLogOut()}>
          &laquo; {t("user:form.resetpass_help_text2")}
        </Button>
      </Link>
    </div>
  );
};

export default UserResetPassPage;
