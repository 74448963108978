import { ProductCollectionDetailJson } from "common/types/ProductCollection";
import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";

const defaultDataProductCollection: ProductCollectionDetailJson = {
  id: 0,
  creator_id: 0,
  collection_id: 0,
  product_id: 0,
  status: 0,
  date_created: 0,
  date_modified: 0,
};

class ProductCollectionDetailModel
  extends BaseModel
  implements BaseModelJson<ProductCollectionDetailJson>
{
  id: number = defaultDataProductCollection.id;
  creator_id: number = defaultDataProductCollection.creator_id;
  collection_id: number = defaultDataProductCollection.collection_id;
  product_id: number = defaultDataProductCollection.product_id;
  date_created: number = defaultDataProductCollection.date_created;
  date_modified: number = defaultDataProductCollection.date_modified;
  status: number = defaultDataProductCollection.status;

  constructor(json: ProductCollectionDetailJson) {
    super();
    Object.assign(this, { ...defaultDataProductCollection, ...json });
  }
  static getDefaultData(): ProductCollectionDetailJson {
    return { ...defaultDataProductCollection };
  }
  toJson(): ProductCollectionDetailJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getPinSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Ghim",
      },
      {
        value: 0,
        label: "Không ghim",
      },
    ];
  }

  static getStatusSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Hiện",
      },
      {
        value: 0,
        label: "Ẩn",
      },
    ];
  }
}

export default ProductCollectionDetailModel;
