import { TagJson } from "common/types/Tag";
import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";

const defaultData: TagJson = {
  id: 0,
  resource_id: 0,
  company_id: 0,
  creator_id: 0,
  name: "",
  code: "",
  value: "",
  type_id: 0,
  type: [],
  status: 0,
  is_deleted: 0,
  date_created: 0,
  date_modified: 0,
  date_deleted: 0,
  resource_type: 0,
  is_root: 0,
};

class TagModel extends BaseModel implements BaseModelJson<TagJson> {
  id: number = defaultData.id;
  resource_id: number = defaultData.resource_id;
  creator_id: number = defaultData.creator_id;
  company_id: number = defaultData.company_id;
  name: string = defaultData.name;
  code: string = defaultData.code;
  value: string = defaultData.value;
  status: number = defaultData.status;
  type_id: number = defaultData.type_id;
  type: { name: string; code: string }[] = defaultData.type;
  is_deleted: number = defaultData.is_deleted;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;
  date_deleted: number = defaultData.date_deleted;
  resource_type: number = defaultData.resource_type;
  is_root: number = defaultData.is_root;

  constructor(json: TagJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): TagJson {
    return { ...defaultData };
  }
  toJson(): TagJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getStatusSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Hiện",
      },
      {
        value: 2,
        label: "Nháp",
      },
      {
        value: 3,
        label: "Ẩn",
      },
    ];
  }

  static getResouceTypeList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Sản phẩm",
      },
      {
        value: 2,
        label: "Bài viết",
      },
      {
        value: 3,
        label: "Danh mục sản phẩm",
      },
      {
        value: 4,
        label: "Danh mục bài viết",
      },
      {
        value: 5,
        label: "Thương hiệu",
      },
    ];
  }

  static getIsRoot(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Hệ thống",
      },
      {
        value: 2,
        label: "Người dùng",
      },
    ];
  }

  static getResouceTypeValue(value: number): string {
    switch (value) {
      case 1:
        return "Sản phẩm";
      case 2:
        return "Bài viết";
      case 3:
        return "Danh mục sản phẩm";
      case 4:
        return "Danh mục bài viết";
      default:
        return "";
    }
  }
}

export default TagModel;
