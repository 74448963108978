import { Alert, App, Button, List, Skeleton, Tag } from "antd";
import RbacRoleUserRepository from "common/repositories/RbacRoleUserRepository";
import RbacSubjectRepository from "common/repositories/RbacSubjectRepository";
import { RoleUserDetail } from "common/types/RbacRoleUserDetail";
import Error from "components/LayoutError";
import TextUser from "components/TextUser";
import SelectEmployee from "features/employee/SelectEmployee";
import RbacRoleUserHeader from "features/rbacrole/user/RbacRoleUserHeader";
import RbacRoleUserTable from "features/rbacrole/user/RbacRoleUserTable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import useDidMount from "@rooks/use-did-mount";
import { IconUser } from "@tabler/icons-react";

import RbacRoleAssignedSubject from "../list/RbacRoleAssignedSubject";

const RbacRoleUser = ({ user_id }: { user_id: number }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { message } = App.useApp();
  const companyOwner = useLoginAccountStore(
    (state) => state.account.company.user_id
  );

const roles = useLoginAccountStore(
    (state) => state.account.role
  );

  const [loading, setLoading] = useState(true);
  const [roleUserDetail, setRoleUserDetail] = useState<RoleUserDetail>();
  const [showNotPermissionSubjects, setShowNotPermissionSubjects] = useState(true);



  const handleChangeUser = (values: number[]) => {
    if (values.length > 0) {
      navigate("/role/user/id/" + values[0]);
    }
  };

  const loadData = useCallback(
    async (user_id: number) => {
      setLoading(true);
      message.destroy("message");
      const responseRoleUserDetailModel = await new RbacRoleUserRepository().getRolesFromUser(user_id);
      console.log(responseRoleUserDetailModel)


      setLoading(false);
      if (responseRoleUserDetailModel.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("role:form.error.heading_load_setting")}
              translate_prefix="role:form.error"
              items={responseRoleUserDetailModel.error.errors}>
              <Button type="link" block onClick={() => loadData(user_id)}>
                {t("common:table.retry_load_data")}
              </Button>
            </Error>
          ),
          className: "message_error",
          key: "message",
          duration: 10
        });
      } else {
        setRoleUserDetail(responseRoleUserDetailModel);
      }
    },
    [t, message]
  );

  // const saveRoleUserSubject = async (
  //   userId: number,
  //   subjectId: number,
  //   ticket: string,
  //   subjectObjectIdList: number[]
  // ) => {
  //   const ticketText = t("permission:role." + ticket);

  //   //update subject
  //   message.loading({
  //     content: t("role:limit.saving_text", { ticket: ticketText }),
  //     key: "message-" + subjectId,
  //     duration: 0
  //   });

  //   const requestErrors = await new RbacRoleUserRepository().limitObjectIdList({
  //     subject_id: subjectId,
  //     user_id: userId,
  //     list_ids: subjectObjectIdList,
  //     is_delete: subjectObjectIdList.length === 0 ? 1 : 0
  //   });

  //   if (requestErrors.length > 0) {
  //     message.error({
  //       content: (
  //         <Error
  //           onClickClose={() => {
  //             message.destroy("message-" + subjectId);
  //           }}
  //           heading={t("role:form.error.heading_save_user_setting", {
  //             ticket: ticketText
  //           })}
  //           translate_prefix="role:form.error"
  //           items={requestErrors}>
  //           <Button
  //             type="link"
  //             block
  //             onClick={() =>
  //               saveRoleUserSubject(
  //                 userId,
  //                 subjectId,
  //                 ticket,
  //                 subjectObjectIdList
  //               )
  //             }>
  //             {t("common:form.retry_submit")}
  //           </Button>
  //         </Error>
  //       ),
  //       className: "message_error",
  //       key: "message-" + subjectId,
  //       duration: 10
  //     });
  //   } else {
  //     message.success({
  //       content: t("role:limit.save_success", { ticket: ticketText }),
  //       className: "message_success",
  //       key: "message-" + subjectId,
  //       duration: 2
  //     });

  //     if (typeof roleUserDetail !== "undefined") {
  //       //update subject item had been change id_list

  //       //Kiem tra xem, tai khoan nay da duoc gioi han id_list cho subject_id nay chua
  //       //neu chua co thi assign moi, co roi thi update
  //       const isLimitExisted =
  //         roleUserDetail.subjects.findIndex(
  //           (s) => s.subject_id === subjectId
  //         ) >= 0;
  //       if (isLimitExisted) {
  //         //update list
  //         setRoleUserDetail({
  //           ...roleUserDetail,
  //           subjects: roleUserDetail.subjects.map((s) =>
  //             s.subject_id !== subjectId
  //               ? s
  //               : { ...s, id_list: subjectObjectIdList }
  //           )
  //         });
  //       } else {
  //         //append list
  //         setRoleUserDetail({
  //           ...roleUserDetail,
  //           subjects: [
  //             ...roleUserDetail.subjects,
  //             {
  //               subject_id: subjectId,
  //               id_list: subjectObjectIdList
  //             }
  //           ]
  //         });
  //       }
  //     }
  //   }
  // };

  // useDidMount(() => {
  //   loadData(user_id);
  // });


  useEffect(() => {
    loadData(user_id);
  }, [user_id])

  return (
    <>
      <RbacRoleUserHeader
        full_name={"User ID #" + (roleUserDetail?.user_id || "...") + ""}>
        <SelectEmployee
          width={240}
          placeholder={t("role:view_one_user")}
          isMulti={false}
          values={[]}
          onChange={handleChangeUser}
          icon={<IconUser size="12" />}
        />
      </RbacRoleUserHeader>

      {loading ? (
        <Skeleton active className="px-4" />
      ) : (
        <>
          {typeof roleUserDetail !== "undefined" ? (
            <div>
              <div className="p-4 pt-0 m-4 mb-6 border bg-gray-50">
                <div className="">
                  <List
                    header={
                      <div className="text-base pl-3.5">
                        {t("role:user_assigned_role_heading", {
                          count_role: roleUserDetail.roles.length
                        })}
                      </div>
                    }
                    itemLayout="horizontal"
                    size="small"
                    dataSource={roleUserDetail.roles}
                    renderItem={(item) => (
                      <List.Item className="pl-0">
                        <List.Item.Meta
                          description={
                            <>
                              <span className="mr-2 text-gray-600">
                                {item.name}:
                              </span>
                              <RbacRoleAssignedSubject
                                subjects={item.assigned_subjects}
                              />
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>

              <div className="pl-4 text-lg">
                {t("role:all_role_of_user")}&nbsp;
                <TextUser id={roleUserDetail?.user_id} />
                {roleUserDetail?.user_id === companyOwner ? (
                  <>
                    <Tag color="#f50">{t("role:owner_badge")}</Tag>
                  </>
                ) : null}
                {roleUserDetail?.user_id === companyOwner ? (
                  <Alert
                    message={t("role:owner_warning_heading")}
                    description={t("role:owner_warning")}
                    type="warning"
                    showIcon
                    className="my-2 mr-4"
                  />
                ) : null}
                <span className="float-right mr-4">
                  <Button
                    onClick={() =>
                      setShowNotPermissionSubjects(!showNotPermissionSubjects)
                    }>
                    {showNotPermissionSubjects
                      ? t("role:link_disable_not_permission_subject")
                      : t("role:link_show_not_permission_subject")}
                  </Button>
                </span>
              </div>

              {/* {RbacSubjectRepository.getAllSubjects().map((group) => (
                <RbacRoleUserTable
                  key={group.name}
                  subjectGroup={group}
                  roleUserDetail={roleUserDetail}
                  saveRoleUserSubject={saveRoleUserSubject}
                  showNotPermissionSubjects={showNotPermissionSubjects}
                />
              ))} */}
            </div>
          ) : (
            <>Not Found</>
          )}
        </>
      )}
    </>
  );
};

export default RbacRoleUser;
