import { Button, Tooltip } from "antd";
import CustomerPointCollection from "common/collections/CustomerPointCollection";
import CustomerPoint from "common/constants/CustomerPoint";
import Role from "common/constants/Role";
import CustomerPointModel from "common/models/CustomerPointModel";
import CustomerPointRepository from "common/repositories/CustomerPointRepository";
import { FilterCustomerPoint } from "common/types/CustomerPoint";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import CustomerPointListHeader from "features/customerpoint/list/CustomerPointListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CustomerPointListFilter from "./CustomerPointListFilter";

const CustomerPointList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterCustomerPoint = useMemo(
    () => CustomerPointRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterCustomerPoint>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<CustomerPointModel[]>([]);
  const fetchData = useCallback(async (): Promise<CustomerPointCollection> => {
    let collection = await new CustomerPointRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  //Table columns
  const columns: TableColumnsType<CustomerPointModel> = [
    {
      title: t("common:sid"),
      key: "id",
      width: 60,
      align: "center",
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            link={"/customerpoint/edit/id/" + record.id}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("customerpoint:customer"),
      key: "enrich_customer_full_name",
      width: 160,
      render: (enrich_customer_full_name, record) => {
        return (
          <>
            {enrich_customer_full_name}{" "}
            <Tooltip title={t("customer:id")}>
              <span className="text-gray-300 cursor-default hover:text-black">
                #{record.customer_id}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: t("customerpoint:value"),
      key: "value",
      align: "right",
      width: 120,
      render: (value: number) => {
        return (
          <>
            <span
              className={"" + (value > 0 ? "text-green-500" : "text-red-500")}
            >
              {value > 0 ? "+" : ""}
              {value}
            </span>{" "}
            <span className="text-gray-300">{t("customerpoint:point")}</span>
          </>
        );
      },
    },
    {
      title: t("customerpoint:type"),
      key: "type",
      render: (type: number, record) => {
        let typeInfo = CustomerPointModel.getType(type);
        return (
          <>
            {typeInfo?.label}
            {type === CustomerPoint.TYPE_ORDER ? (
              <>
                <Link
                  target={"_blank"}
                  className="ml-1 px-1 py-0.5 text-xs text-blue-500 border border-blue-300 bg-gray-50"
                  to={"/order/edit/id/" + record.object_id}
                >
                  SO #{record.object_id}
                </Link>
              </>
            ) : null}
            {type === CustomerPoint.TYPE_CASHFLOW ? (
              <>
                <Link
                  target={"_blank"}
                  className="ml-1 px-1 py-0.5 text-xs text-blue-500 border border-blue-300 bg-gray-50"
                  to={"/cashflowreceipt?id=" + record.object_id}
                >
                  {t("customerpoint:cashflowreceipt_id")}
                  {record.object_id}
                </Link>
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: t("customerpoint:note"),
      key: "note",
    },
    {
      title: t("common:status"),
      key: "status",
      width: 100,
      render: (status: number) => {
        let statusInfo = CustomerPointModel.getStatus(status);
        return (
          <span
            className={
              status === CustomerPoint.STATUS_DISABLE ? "text-red-500" : ""
            }
          >
            {statusInfo?.label}
          </span>
        );
      },
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 100,
      render: (ts: number) => <TextDateTime ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 125,
      render: (_: any, record: CustomerPointModel) => (
        <RoleCheck roles={[Role.COMPANY_SETTING]} hideOnFail>
          <TableEdit link={"/customerpoint/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="customerpoint:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new CustomerPointRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <CustomerPointListHeader>
        <RoleCheck roles={[Role.COMPANY_SETTING]}>
          <Link to={"/customerpoint/add"} className="">
            <Button type="primary">{t("common:table.add_button")}</Button>
          </Link>
        </RoleCheck>
      </CustomerPointListHeader>

      <CustomerPointListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<
        FilterCustomerPoint,
        CustomerPointModel,
        CustomerPointCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "customerpoint",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default CustomerPointList;
