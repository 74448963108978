import { MoreOutlined } from "@ant-design/icons";
import { IconInfoCircle } from "@tabler/icons-react";
import {
	Button,
	Checkbox,
	Col,
	Dropdown,
	MenuProps,
	Row,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";

import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import usePostActionStore from "zustands/usePostActionStore";

import DeliveryCollection from "common/collections/DeliveryCollection";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import { ActionCode, FilterDelivery } from "common/types/Delivery";
import { TableColumnsType } from "common/types/Table";

import TextCashflowReceiptMethod from "components/TextCashflowReceiptMethod";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import PageDataTable from "components/page/PageDataTable";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import DeliverySumByManager from "features/delivery/sumby/DeliverySumbyManager";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";

import Delivery from "common/constants/Delivery";
import PageDataPagination from "components/page/PageDataPagination";
import ButtonAction from "features/delivery/action/ButtonAction";
import PostAction from "features/delivery/action/PostAction";
import DeliveryManagerListFilter from "./DeliveryManagerListFilter";
import DeliveryManagerListHeader from "./DeliveryManagerListHeader";

const DeliveryManagerList = () => {
	const { t } = useTranslation();
	const messageKey = "completed_d";
	const defaultFilters: any = useMemo(() => {
		return {
			page: 1,
			limit: 20,
			sortby: "id",
			sorttype: "DESC",
			status: -1,
			keyword: "",
			action_code: null,
			internal_id: "",
			soandcode: "",
			date_created: -1,
			date_completed: -1,
			actor_id: -1,
			find_internal: "",
		};
	}, []);
	const { reload } = usePostActionStore();
	const navigate = useNavigate();

	//state
	const [showModal, setShowModal] = useState<boolean>(false);
	const [filters, setFilters] = useStateFilter<FilterDelivery>(defaultFilters);
	const [selectDeliveryArr, setSelectDeliveryArr] = React.useState<
		DeliveryModel[]
	>([]);

	const [employeesD, setEmployeesD] = useState<any[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<DeliveryModel[]>([]);
	const [showContactFields, setShowContactFields] = useState(false);
	const [modalId, setModalId] = useState<number>(0);
	const [creatorsD, setCreatorsD] = useState<any[]>([]);
	const [disabled, setDisabled] = useState<boolean>(true);
	const [actionCode, setActionCode] = useState<ActionCode>("");
	//newcheckboxlist

	const checkSameActionCode = (
		selectDeliveryArr: DeliveryModel[],
		action_code: ActionCode
	) => {
		for (let i = 0; i < selectDeliveryArr.length; i++) {
			if (selectDeliveryArr[i].action_code !== action_code) {
				return false;
			}
		}
		setActionCode(action_code);
		return true;
	};

	const handleCheckBoxSelect = (selectItem: DeliveryModel) => {
		let newArr = [...selectDeliveryArr];
		const isExist = selectDeliveryArr.findIndex(
			(item) => item.id === selectItem.id
		);
		if (isExist >= 0) {
			newArr.splice(isExist, 1);
		} else {
			newArr = [...newArr, selectItem];
		}
		setSelectDeliveryArr(newArr);
	};

	//////////////////////////////////////////
	//Filtering
	useFilterLocation(defaultFilters, filters);

	//actor
	const fetchingEmployeeD = useCallback(async (id_list: number[]) => {
		const collection = await new IdFetcherRepository().doFetching({
			object_type: "employee",
			id_list,
		});

		setEmployeesD(
			collection.items.find((i) => i.object_type === "employee")?.result || []
		);
	}, []);

	//////////////////////////////////////////
	//Fetch data from this collections
	const fetchData = useCallback(async (): Promise<DeliveryCollection> => {
		const collection = await new DeliveryRepository().getItemsDeilveryManager({
			filters,
		});
		if (collection) {
			if (collection?.items.length > 0) {
				fetchingEmployeeD(collection.items.map((item) => item.actor_id));
				fetchingCreatorD(collection.items.map((item) => item.creator_id));
			}

			setTotal(collection.total);
			setDataSource(collection.items);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}
		setSelectDeliveryArr([]);
		return collection;
	}, [filters, reload]);

	//creator_id
	//actor

	const fetchingCreatorD = useCallback(async (id_list: number[]) => {
		const collection = await new IdFetcherRepository().doFetching({
			object_type: "employee",
			id_list,
		});

		setCreatorsD(
			collection.items.find((i) => i.object_type === "employee")?.result || []
		);
	}, []);

	const handleClickCard = (id: number) => {
		setModalId(id);
	};

	const checkSameActionCodeTitleCheckbox = (
		selectDeliveryArr: DeliveryModel[],
		action_code: ActionCode[]
	) => {
		for (let i = 0; i < selectDeliveryArr.length; i++) {
			for (let j = 0; j < action_code.length; j++) {
				if (selectDeliveryArr[i].action_code !== action_code[j]) {
					return false;
				}
			}
		}
		return true;
	};

	//

	const convertArr = useMemo(() => {
		let arr: Array<DeliveryModel[]> = [];
		if (selectDeliveryArr.length > 0) {
			const check = (item: DeliveryModel) => {
				for (let index = 0; index < arr.length; index++) {
					const arrFind = arr[index];
					if (arrFind.find((i) => i.action_code === item.action_code)) {
						arr[index].push(item);
						return 0;
					} else {
						return 1;
					}
				}
			};

			for (let index = 0; index < selectDeliveryArr.length; index++) {
				if (index === 0) {
					arr.push([selectDeliveryArr[index]]);
				} else {
					if (check(selectDeliveryArr[index]) === 1) {
						arr.push([selectDeliveryArr[index]]);
					}
				}
			}
		}

		return arr;
	}, [selectDeliveryArr]);

	//Table columns
	const columns: TableColumnsType<DeliveryModel> = [
		{
			title: (
				<>
					<Checkbox
						disabled={checkSameActionCodeTitleCheckbox(dataSource, [
							"ACTION_NEW",
							"ACTION_RETURNED",
							"ACTION_REFUND",
						])}
						onChange={(e) => {
							if (e.target.checked) {
								dataSource.forEach((item) => {
									if (
										!selectDeliveryArr.includes(item) &&
										[
											"ACTION_REFUND",
											"ACTION_APPROVE_REFUND",
											"ACTION_NEW",
											"ACTION_RETURNED",
										].includes(item.action_code)
										// item.action_code === ActionDelivery.ACTION_DELIVERED
									) {
										setSelectDeliveryArr((prev) => [...prev, item]);
									}
								});
							} else {
								setSelectDeliveryArr([]);
							}
						}}></Checkbox>
				</>
			),
			key: "checkbox",
			width: 50,
			fixed: "left",
			togglelabel: "Checkbox",
			render: (_: any, record: DeliveryModel) => {
				return (
					<Checkbox
						disabled={
							![
								"ACTION_REFUND",
								"ACTION_APPROVE_REFUND",
								"ACTION_NEW",
								"ACTION_RETURNED",
								"ACTION_DRAFT",
							].includes(record.action_code)
						}
						checked={selectDeliveryArr.includes(record)}
						onClick={() => {
							// handleCheckBoxClick(record.id);
							handleCheckBoxSelect(record);
						}}
					/>
				);
			},
		},
		{
			title: t("order:id"),
			key: "sale_order_id",
			// sortby: "id",
			width: 160,
		},

		{
			title: t("delivery:table.delivery_id"),
			key: "id",
			width: 100,
		},

		{
			title: t("order:billing"),
			key: "billing",
			width: 300,
			render: (_: any, record: DeliveryModel) => {
				return (
					<>
						<div>
							<div>
								<span className="font-bold">{record.contact_fullname}</span>
								<span className=""> / {record.contact_phone}</span>
							</div>
							<div className="text-xs text-gray-500">
								{record.contact_address}
							</div>
						</div>
					</>
				);
			},
		},
		{
			title: t("delivery:table.cost_total"),
			key: "cost_total",
			align: "right",
			sortby: "cost_total",
			width: 120,
			render: (price_final: number) => {
				return <TextMoney money={price_final} />;
			},
		},
		{
			title: t("delivery:table.cod_total"),
			key: "cod_total",
			align: "right",
			sortby: "cod_total",
			width: 120,
			render: (price_final: number) => {
				return <TextMoney money={price_final} />;
			},
		},
		{
			title: t("delivery:table.cod"),
			key: "cod",
			align: "right",
			sortby: "cod",
			width: 120,
			render: (price_final: number) => {
				return <TextMoney money={price_final} />;
			},
		},
		{
			title: t("delivery:table.cost_on_transfer"),
			key: "cost_on_transfer",
			align: "right",
			sortby: "cod",
			width: 120,
			render: (cost_on_transfer: number) => {
				return <TextMoney money={cost_on_transfer} />;
			},
		},
		{
			title: t("delivery:table.shipper"),
			key: "actor_id",
			width: 150,
			render: (actor_id: any) => {
				return (
					<Typography.Text>
						{actor_id === 0 ? (
							<Tag>{t("delivery:action.empty")}</Tag>
						) : (
							<Tag
								className="cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									handleClickCard(actor_id);
									setShowContactFields(true);
								}}>
								{employeesD.length > 0
									? employeesD.find((i) => i.value === actor_id)?.text
									: t("delivery:action.no")}
							</Tag>
						)}
					</Typography.Text>
				);
			},
		},
		{
			title: (
				<>
					<Tooltip title={t("cashflowreceipt:filter_method")}>
						{t("cashflowreceipt:method_sort")}
					</Tooltip>
				</>
			),
			key: "payment_method",
			align: "center",
			width: 70,
			render: (method: number) => {
				return <TextCashflowReceiptMethod method={method} />;
			},
		},
		{
			title: t("common:status"),
			key: "action_code",
			render: (action_code: string) => {
				return (
					<Tag color="#0089FF">
						{DeliveryModel.getActionStatus(action_code)}
					</Tag>
				);
			},
			width: 150,
		},
		{
			title: t("delivery:table.created_by"),
			key: "creator_id",
			width: 200,
			render: (creator_id) => {
				return (
					<Typography.Text>
						{creator_id === 0 ? (
							"BOT"
						) : (
							<Tag
								className="cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									handleClickCard(creator_id);
									setShowContactFields(true);
								}}>
								{creatorsD.length > 0
									? creatorsD.find((i) => i.value === creator_id)?.text
									: t("delivery:action.empty")}
							</Tag>
						)}
					</Typography.Text>
				);
			},
		},

		{
			title: t("delivery:table.date_created"),
			key: "date_created",
			width: 100,
			render: (ts: number) => <TextDateTime format="HH:mm, DD/MM" ts={ts} />,
		},
		{
			title: "",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 50,
			toggletype: "trigger",
			render: (_, record: DeliveryModel) => {
				const items: MenuProps["items"] = [
					{
						key: "3",
						label: (
							<Button
								size="small"
								type="text"
								onClick={() => {
									navigate(`/delivery/manager_detail/id/${record.id}`);
								}}
								icon={
									<IconInfoCircle
										size="18"
										className={" -mt-0.5 text-gray-400 hover:text-gray-500"}
									/>
								}>
								Chi tiết
							</Button>
						),
					},
					// {
					//   key: "2",
					//   label: record.action_code === "ACTION_NEW" && (
					//     <TableDelete
					//       error_translate_prefix="delivery:form.error"
					//       title={t("delivery:action.confirm.delete")}
					//       onDeleteCallback={(id) => {
					//         setDataSource(
					//           dataSource.filter((item: DeliveryModel) => item.id !== id)
					//         );
					//       }}
					//       repository={new DeliveryRepository()}
					//       id={record.id}
					//     ></TableDelete>
					//   ),
					// },
					// {
					//   key: "3",
					//   label: (
					//     <Button
					//       size="small"
					//       type="text"
					//       onClick={() => {
					//         navigate(`/delivery/detail/id/${record.id}`);
					//       }}
					//     >
					//       <IconInfoCircle
					//         size="18"
					//         className={" -mt-0.5 text-gray-400 hover:text-gray-500"}
					//       />
					//     </Button>
					//   ),
					// },
				];
				if (record.action_code === "ACTION_NEW") {
					items.unshift(
						{
							key: "1",
							label: (
								<TableEdit
									link=""
									className="flex items-center"
									icon={<></>}
									onClick={() => {
										navigate(`/delivery/edit/id/${record.id}`);
									}}
								/>
							),
						},
						{
							key: "2",
							label: (
								<TableDelete
									error_translate_prefix="delivery:form.error"
									title={t("delivery:action.confirm.delete")}
									onDeleteCallback={(id) => {
										setDataSource(
											dataSource.filter((item: DeliveryModel) => item.id !== id)
										);
									}}
									repository={new DeliveryRepository()}
									id={record.id}
									label="Xóa"
									className="flex items-center gap-2 hover:text-red-500"></TableDelete>
							),
						}
					);
				}
				return (
					<>
						<Dropdown trigger={["hover", "click"]} menu={{ items }}>
							<MoreOutlined
								style={{ fontSize: "16pt" }}
								className="cursor-pointer "
							/>
						</Dropdown>
					</>
				);
			},
		},
	];

	//setdisable
	useEffect(() => {
		if (
			selectDeliveryArr.length > 0 &&
			(checkSameActionCode(selectDeliveryArr, "ACTION_REFUND") ||
				checkSameActionCode(selectDeliveryArr, "ACTION_RETURNED") ||
				checkSameActionCode(selectDeliveryArr, "ACTION_NEW"))
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [selectDeliveryArr, reload]);
	function convertArrSelectDeliveryToStringIDs(arr: DeliveryModel[]) {
		return arr.map((item) => item.id).join(",");
	}

	return (
		<>
			<DeliveryManagerListHeader />
			<DeliveryManagerListFilter
				defaultFilters={defaultFilters}
				filters={filters}
				setFilters={setFilters}
				total={total}
			/>

			<DeliverySumByManager
				type="manager"
				setFilters={setFilters}
				filters={filters}
				defaultFilters={defaultFilters}
			/>
			<Row justify="end" className={disabled ? "" : ""}>
				{convertArr.length > 0 &&
					convertArr.map((action, index) => {
						return (
							<Col key={index} className="pr-4">
								<ButtonAction
									matrix_action={Delivery.MANAGER_MAXTRIX_ACTION}
									action_code={action[0].action_code}
									renderModal={({
										nextAction,
										closeModal,
										gpsRequire,
										noteRequire,
										uploadRequire,
									}) => {
										return (
											<div>
												<PostAction
													key={1}
													action={nextAction}
													gpsRequire={gpsRequire}
													noteRequire={noteRequire}
													uploadRequire={uploadRequire}
													data={{
														deliveries_id:
															convertArrSelectDeliveryToStringIDs(action),
													}}
													handlePostActionSuccess={() => {
														closeModal();
														message.success({
															content: "Thao tác thành công !",
														});
														fetchData && fetchData();
													}}></PostAction>
											</div>
										);
									}}
									// single_action={{
									//   action_code: "ACTION_WAITING",
									//   label: t("delivery:waiting.button.take"),
									// }}
									prefix_translate_button="delivery:postaction"
									prefix_button="delivery:postaction"></ButtonAction>
							</Col>
						);
					})}
				{/* <Col className="pr-4">
          <ButtonAction
            action_code="ACTION_WAITING"
            matrix_action={[
              ["ACTION_CREATE", false, false, false,["ACTION_WAITING"] ],
            ]}
            single_action={{ label: "add", action_code: "ACTION_WAITING" }}
            renderModal={{
              
            }}
          />
          <ButtonActionManager
            disabled={disabled}
            selectedDelivery={selectDeliveryArr}
          />
        </Col> */}
				{/* <Col className="pr-4">
        
          <ButtonAction
            matrix_action={Delivery.MANAGER_MAXTRIX_ACTION}
            action_code={actionCode}
            renderModal={({
              nextAction,
              closeModal,
              gpsRequire,
              noteRequire,
              uploadRequire,
            }) => {
              return (
                <div>
                  <PostAction
                    key={1}
                    action={nextAction}
                    gpsRequire={gpsRequire}
                    noteRequire={noteRequire}
                    uploadRequire={uploadRequire}
                    data={{
                      deliveries_id:
                        convertArrSelectDeliveryToStringIDs(selectDeliveryArr),
                    }}
                    handlePostActionSuccess={() => {
                      closeModal();
                      message.success({
                        content: "Thao tác thành công !",
                      });
                      fetchData && fetchData();
                    }}
                  ></PostAction>
                </div>
              );
            }}
            // single_action={{
            //   action_code: "ACTION_WAITING",
            //   label: t("delivery:waiting.button.take"),
            // }}
            prefix_translate_button="delivery:postaction"
          ></ButtonAction>
        </Col> */}
			</Row>

			<PageDataTable<FilterDelivery, DeliveryModel, DeliveryCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "deliverymanager",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<PhoneBookModal
				id={modalId}
				visible={showContactFields}
				setVisible={(isVisible) => {
					setShowContactFields(isVisible);
				}}
			/>
		</>
	);
};

export default DeliveryManagerList;
