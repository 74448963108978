import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import { StoreJson } from "common/types/Store";
import TextMoney from "components/TextMoney";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import ReportChartNumber from "features/report/chart/ReportChartNumber";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback } from "react";

import { IconReportMoney, IconShoppingCart } from "@tabler/icons-react";

const ReportOrderPromotionGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const [storeItemsWithLimit] = useDatabaseTable<StoreJson>("store");
  const formatterStoreValue = useCallback(
    (value: number) => {
      return storeItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [storeItemsWithLimit]
  );

  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartNumber
              className="p-2"
              label={"Số sản phẩm bán ra"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="sum_quantity"
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="sum(quantity)"
              dataFilter="order_status < 20"
              dataJoin="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          {/* <Col xs={24} sm={12} lg={8}>
            <ReportChartNumber
              className="p-2"
              label={"Số sản phẩm có giảm giá"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="sum_quantity"
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="sum(quantity)"
              dataFilter="order_status < 20,itemunitdiscount != 0"
              dataJoin="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col> */}

          <Col xs={24} sm={12} lg={12}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng giá trị giảm"}
              icon={<IconReportMoney size="18" />}
              keyMapping="sum_discounttotal"
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="sum(discounttotal)"
              dataFilter="order_status < 20,discounttotal != 0"
              dataJoin="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          {/* <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Số lượng sản phẩm giảm giá theo cửa hàng"}
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="sum(quantity), order_store"
              dataFilter="order_status < 20,discounttotal != 0"
              dataGroupBy="order_store"
              dataOrderBy="sum(quantity) desc"
              dataJoin="order"
              keyMapping={{
                name: "status",
                value: "sum_pricefinal",
                formatter: (value: number | string) => {
                  return value.toString();
                }
              }}
              height={350}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col> */}
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Giảm giá sản phẩm theo cửa hàng"}
              dataTable="orderdetail"
              dataService="orderdetail"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "order_store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  }
                },
                {
                  title: "Số lượng sản phẩm",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(quantity)"
                },
                {
                  title: "Tổng giá trị giảm",
                  name: "sum_discounttotal",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(discounttotal)"
                }
              ]}
              dataSelect="sum(quantity), order_store, sum(discounttotal)"
              dataFilter="order_status < 20,discounttotal != 0"
              dataGroupBy="order_store"
              dataOrderBy="sum(quantity) DESC"
              dataJoin="order"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportOrderPromotionGeneral;
