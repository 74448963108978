import { BaseModelJson } from "common/interfaces/BaseModelJson";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import { LeaveHistoryJson } from "common/types/Leave";

class LeaveHistoryModel
  extends BaseModel
  implements BaseModelJson<LeaveHistoryJson>
{
  employee_approver_id: number;
  approver_history_id: number;
  approver_id: number;
  resourceid: number;
  note: string;
  status: number;
  datecreated: number;
  datemodified: number;
  isdeleted: number;
  datdeleted: number;
  type: number;

  constructor(json: LeaveHistoryJson) {
    super();
    this.employee_approver_id = json.employee_approver_id || 0;
    this.approver_history_id = json.approver_history_id || 0;
    this.approver_id = json.approver_id || 0;
    this.resourceid = json.resourceid || 0;
    this.note = json.note || "";
    this.status = json.status || 0;
    this.datecreated = json.datecreated || 0;
    this.datemodified = json.datemodified || 0;
    this.isdeleted = json.isdeleted || 0;
    this.datdeleted = json.datdeleted || 0;
    this.type = json.type || 0;
  }

  static getDefaultData(): LeaveHistoryJson {
    return {
      employee_approver_id: 1,
      approver_history_id: 0,
      approver_id: 0,
      resourceid: 0,
      note: "",
      status: 0,
      datecreated: 0,
      datemodified: 0,
      isdeleted: 0,
      datdeleted: 0,
      type: 0,
    };
  }

  toJson(): LeaveHistoryJson {
    return {
      employee_approver_id: this.employee_approver_id,
      approver_history_id: this.approver_history_id,
      approver_id: this.approver_id,
      resourceid: this.resourceid,
      note: this.note,
      status: this.status,
      datecreated: this.datecreated,
      datemodified: this.datemodified,
      isdeleted: this.isdeleted,
      datdeleted: this.datdeleted,
      type: this.type,
    };
  }
  // static getDefaultData(): LeaveJson {
  //   return {
  //     company_id: 0,
  //     employee_id: 0,
  //     leavetypes_id: 0,
  //     id: 0,
  //     dayoff: 0,
  //     date_from: 0,
  //     date_to: 0,
  //     note: "",
  //     status: 0,
  //     date_created: 0,
  //     date_modified: 0,
  //   };
  // }
}

export default LeaveHistoryModel;
