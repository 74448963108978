const Shipper = {
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,

  GENDER_UNKNOWN: 0,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2,

  TYPE_MOTOBIKE: 1,
  TYPE_CAR: 3,

  DEFAULT_SPEED: 20,
};

export default Shipper;
