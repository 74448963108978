import BaseCollection from "common/collections/BaseCollection";
import ReportLeaveModel from "common/models/ReportLeaveModel";
import {
  ReportLeaveCollectionJson,
  ReportLeaveJson,
} from "common/types/ReportLeave";

class ReportLeaveCollection extends BaseCollection<
  ReportLeaveJson,
  ReportLeaveModel,
  ReportLeaveCollectionJson
> {
  itemsFromJson(jsonItems: ReportLeaveJson[]): ReportLeaveModel[] {
    return jsonItems.map((item) => new ReportLeaveModel(item));
  }
}

export default ReportLeaveCollection;
