import ReportCheckinApi from "common/api/ReportCheckinApi";
import ReportCheckinModel from "common/models/ReportCheckinModel";
import {
  FilterReportCheckin,
  ReportCheckinAddEditJson,
} from "common/types/ReportCheckin";
import BaseRepository from "./BaseRepository";

class ReportCheckinRepository extends BaseRepository<ReportCheckinApi> {
  _api: ReportCheckinApi | null;

  constructor() {
    super();
    this._api = new ReportCheckinApi(true);
  }

  static getDefaultFilters(): FilterReportCheckin {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      status: "",
      keyword: "",
      date_from: -1,
      date_to: -1,
    };
  }

  async exportReport(
    data: ReportCheckinAddEditJson
  ): Promise<ReportCheckinModel> {
    return this._api
      ? await this._api.exportExcel(data)
      : new ReportCheckinModel(ReportCheckinModel.getDefaultData());
  }

  async checkFileReport(file_name: string): Promise<string[]> {
    return this._api ? await this._api.checkFileReport(file_name) : [];
  }

  async downloadExcel(file_name: string): Promise<boolean> {
    return this._api ? await this._api.downloadExcel(file_name) : false;
  }
}

export default ReportCheckinRepository;
