import { App, Col, DatePicker, Form, Input, Row } from "antd";
import ShippingOrder from "common/constants/ShippingOrder";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import ShippingRouteRepository from "common/repositories/ShippingRouteRepository";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import dayjs from "dayjs";
import ShipperFormSelect from "features/shipper/ShipperFormSelect";
import ShippingHubFormSelect from "features/shippinghub/ShippingHubFormSelect";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { ShippingRouteJsonAddEdit } from "common/types/ShippingRoute";
const ShippingRouteForm = ({
  id,
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  id: number;
  model: ShippingRouteModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: ShippingRouteModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const account = useLoginAccountStore((state) => state.account);

  const initialValues = useMemo(() => {
    return {
      code: model.code,
      shipper_id: model.shipper_id > 0 ? model.shipper_id : null,
      from_warehouse_id:
        model.from_warehouse_id > 0 ? model.from_warehouse_id : null,
      ymd: dayjs(
        dayjs(model.ymd.toString(), "YYYYMMDD").format("DD/MM/YYYY"),
        "DD/MM/YYYY"
      ),
      status: model.status > 0 ? model.status : null,
      from_type: model.from_type > 0 ? model.from_type : null
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ShippingRouteJsonAddEdit = {
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,
        code: formData.code,
        shipper_id: formData.shipper_id,
        from_warehouse_id: formData.from_warehouse_id,
        ymd: +dayjs(formData.ymd).format("YYYYMMDD"),
        status: formData.status,
        from_type: formData.from_type
      };

      return submitData;
    },
    [model.id, account]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ShippingRouteModel =
      await new ShippingRouteRepository().saveRemote(doPrepareData(formData));

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="shippingroute:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            label={t("shippingroute:ymd")}
            name="ymd"
            required
            rules={[
              {
                required: true,
                message: t("shippingroute:form.error.error_ymd_required")
              }
            ]}>
            <DatePicker
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              changeOnBlur
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          {model.from_type === ShippingOrder.FROM_TYPE_OFFICE ? (
            <ShippingHubFormSelect
              label={t("shippingroute:from_place")}
              name="from_warehouse_id"
            />
          ) : null}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <ShipperFormSelect
            label={t("shippingroute:shipper")}
            name="shipper_id"
          />
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            label={t("shippingroute:code")}
            name="code"
            required
            rules={[
              {
                required: true,
                message: t("shippingroute:form.error.error_code_required")
              }
            ]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <FormSelect
            label={t("common:status")}
            name="status"
            options={ShippingRouteModel.getStatusList()}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ShippingRouteForm;
