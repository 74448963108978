import { App } from "antd";
import EcomCollection from "common/collections/EcomCollection";
import Ecom from "common/constants/Ecom";
import Role from "common/constants/Role";
import EcomModel from "common/models/EcomModel";
import EcomRepository from "common/repositories/EcomRepository";
import EcomUrlRepository from "common/repositories/EcomUrlRepository";
import { FilterEcom } from "common/types/Ecom";
import { TableColumnsType } from "common/types/Table";
import Error from "components/Error";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextStore from "components/TextStore";
import EcomFormModal from "features/ecom/form/EcomFormModal";
import EcomListHeader from "features/ecom/list/EcomListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { IconPackage, IconShoppingCart } from "@tabler/icons-react";

import EcomAddModal from "../EcomAddModal";
import EcomInitModal from "../EcomInitModal";
import EcomTypeSelectModal from "../EcomTypeSelectModal";
import EcomItem from "../item/EcomItem";
import EcomItemWebhook from "../item/EcomItemWebhook";
import EcomListFilter from "./EcomListFilter";

const EcomList = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const messageKey = "ecom_add";
  const [pickerVisible, setPickerVisible] = useState(false);

  //used for initing popup process
  const [initingTypeUuid, setInitingTypeUuid] = useState("");
  const [initingType, setInitingType] = useState(0);
  const [initingVisible, setInitingVisible] = useState(false);

  const openPopup = ({
    url,
    width,
    height
  }: {
    url: string;
    width: number;
    height: number;
  }) => {
    // To fix issues with window.screen in multi-monitor setups, the easier option is to
    // center the pop-up over the parent window.
    const top = window.outerHeight / 2 + window.screenY - height / 2;
    const left = window.outerWidth / 2 + window.screenX - width / 2;
    return window.open(
      url,
      "OAuth Popup",
      `height=${height},width=${width},top=${top},left=${left},titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no`
    );
  };

  /** Callback after user want to create new ecomplatfrom, and select ONE type from modal */

  const [addVisible, setAddVisible] = useState(false);
  const onSelectEcomType = (type: number) => {
    switch (type) {
      case Ecom.TYPE_WOO_COMMERCE:
        setInitingType(type);
        setAddVisible(true);
        setPickerVisible(false);
        setInitingTypeUuid(uuidv4());
        break;
      default:
        onSelectEcomTypeOauthUrl(type);
    }
  };

  const onSelectEcomTypeOauthUrl = useCallback(
    async (type: number) => {
      const authorizeUrl = await new EcomUrlRepository().getOauthUrl(type);

      setPickerVisible(false);

      if (authorizeUrl.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKey);
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="ecom:form.error"
              items={authorizeUrl.error.errors}
              contentPadding={0}
            />
          ),
          className: "message_error",
          key: messageKey,
          duration: 3
        });
      } else {
        if (authorizeUrl.url.length > 0) {
          //open popup window
          openPopup({ url: authorizeUrl.url, width: 420, height: 680 });

          setInitingTypeUuid(uuidv4());
          setInitingType(type);
          setInitingVisible(true);
        } else {
          alert("ERROR: Authorize URL is empty.");
        }
      }
    },
    [messageKey, t, message]
  );

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterEcom = useMemo(
    () => EcomRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterEcom>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<EcomModel[]>([]);
  const [total, setTotal] = useState<number>(0);
  const fetchData = useCallback(async (): Promise<EcomCollection> => {
    let collection = await new EcomRepository().getItems({
      filters
    });
    if (!collection.hasError()) {
      setDataSource(collection.items);
      setTotal(collection.total);
    }

    return collection;
  }, [filters]);

  // modal editing
  const [editingVisible, setEditingVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  const onEdit = (id: number) => {
    setEditingId(id);
    setEditingVisible(true);
  };

  const onSaveSuccess = (item: EcomModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item
          }
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item]
        })
      );

      //we can set editingId to allow editing after add
      onEdit(item.id);
    }
  };

  //Table columns
  const columns: TableColumnsType<EcomModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(id)}>
            {id}
          </TableLinkText>
        );
      }
    },
    {
      title: t("ecom:title"),
      key: "title",
      sortby: "title",
      render: (_: any, record: EcomModel) => <EcomItem record={record} />
    },
    {
      title: t("ecom:store"),
      key: "store",
      width: 170,
      render: (store) => <TextStore id={store} />
    },
    {
      title: t("ecom:webhook"),
      key: "date_webhook_registered",
      render: (_: any, record: EcomModel) => (
        <EcomItemWebhook record={record} onSaveSuccess={onSaveSuccess} />
      )
    },
    {
      title: t("ecom:remote_data"),
      key: "channel_order",
      width: 190,
      render: (_: any, record: EcomModel) => (
        <>
          <TableLinkText
            link={`/ecomchannelorder/index/channel/${record.id}`}
            className="block mb-2">
            <IconShoppingCart size={16} className="-mt-0.5 mr-2" />
            {t("ecom:order_list")}
          </TableLinkText>
          <TableLinkText
            link={`/ecomchannelproduct/index/channel/${record.id}`}>
            <IconPackage size={16} className="-mt-0.5 mr-2" />
            {t("ecom:product_list")}
          </TableLinkText>
        </>
      )
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 125,
      render: (_: any, record: EcomModel) => (
        <RoleCheck roles={[Role.COMPANY_SETTING]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="ecom:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new EcomRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      )
    }
  ];

  return (
    <>
      <EcomListHeader>
        <RoleCheck roles={[Role.SETTING_SALE]}>
          <PageHeaderButton link="#" onClick={(e) => setPickerVisible(true)}>
            {t("ecom:add_button")}
          </PageHeaderButton>
        </RoleCheck>
      </EcomListHeader>

      <EcomListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterEcom, EcomModel, EcomCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "ecom"
        }}></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <EcomFormModal
        id={editingId}
        key={editingId}
        open={editingVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setEditingVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />

      <EcomTypeSelectModal
        open={pickerVisible}
        setOpen={setPickerVisible}
        onSelect={onSelectEcomType}
      />

      {initingType === Ecom.TYPE_HARAVAN ? (
        <EcomInitModal
          key={initingTypeUuid}
          type={initingType}
          open={initingVisible}
          setOpen={setInitingVisible}
          onAddSuccess={onSaveSuccess}
        />
      ) : null}

      {addVisible ? (
        <EcomAddModal
          key={initingType}
          type={initingType}
          open={addVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setInitingType(0);
            }
            setAddVisible(isOpen);
          }}
          onAddSuccess={onSaveSuccess}
        />
      ) : null}
    </>
  );
};

export default EcomList;
