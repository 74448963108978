import DmsBannerModel from "common/models/DmsBannerModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import { useTranslation } from "react-i18next";

const DmsBannerListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}>
      <FormSelect
        name="position"
        placeholder={t("dmsbanner:position")}
        options={DmsBannerModel.getTypeList()}
        allowClear
        popupMatchSelectWidth={false}
      />
    </PageDataFilterForm>
  );
};

export default DmsBannerListFilter;
