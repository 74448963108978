import BaseRepository from "./BaseRepository";

import {
	DefaultFilterStory,
	FilterStory,
	FilterStoryDetail,
	StoryJsonAddEdit,
} from "common/types/Story";
import StoryApi from "common/api/StoryApi";
import StoryModel from "common/models/StoryModel";
import type { GetListProps } from "common/types/Filter";
import StoryCollection from "common/collections/StoryCollection";

class StoryRepository extends BaseRepository<StoryApi> {
	_api: StoryApi | null;
	constructor() {
		super();
		this._api = new StoryApi(true);
	}

	static getDefaultFilter(props?: DefaultFilterStory): FilterStory {
		return {
			page: 1,
			limit: props?.limit || 12,
			sortby: "",
			sorttype: "",
			keyword: "",
			status: "",
			publish_from: -1,
			publish_to: -1,
			collection_id: -1,
			pin: 0,
			type: -1,
			not_id: "",
			tags: undefined,
		};
	}

	async getItems(props: GetListProps<FilterStory>): Promise<StoryCollection> {
		return this._api ? await this._api.getList(props) : new StoryCollection();
	}

	async getItemsPublic(
		props: GetListProps<FilterStory>
	): Promise<StoryCollection> {
		return this._api
			? await this._api.getListPublic(props)
			: new StoryCollection();
	}

	async getItem(id: number | string): Promise<StoryModel> {
		return this._api
			? await this._api.getItem(id)
			: new StoryModel(StoryModel.getDefaultData());
	}

	async getItemPublic(props: FilterStoryDetail): Promise<StoryModel> {
		return this._api
			? await this._api.getItemPublic(props)
			: new StoryModel(StoryModel.getDefaultData());
	}

	async getItemPublicById(id: number): Promise<StoryModel> {
		return this._api
			? await this._api.getItemPublicById(id)
			: new StoryModel(StoryModel.getDefaultData());
	}

	async getItemPublicByHandle(handle: string): Promise<StoryModel> {
		return this._api
			? await this._api.getItemPublicByHandle(handle)
			: new StoryModel(StoryModel.getDefaultData());
	}

	async saveRemote(data: Partial<StoryJsonAddEdit>): Promise<StoryModel> {
		if (this._api) {
			if (data && data?.id) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new StoryModel(StoryModel.getDefaultData());
		}
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}
}

export default StoryRepository;
