import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";

import {
  FilterITRequest,
  ITRequestJsonAddEdit,
  ITRequestJsonEdit,
} from "common/types/ITRequest";
import Helper from "common/utils/helper";
import { GetListProps } from "common/types/Filter";
import ITRequestCollection from "common/collections/ITRequestCollection";
import ITRequestModel from "common/models/ITRequestModel";

const SERVICE_URL = "/tasks/request";
class ITRequestApi extends BaseApi {
  //
  async getListMine(
    props: GetListProps<FilterITRequest>
  ): Promise<ITRequestCollection> {
    let collection = new ITRequestCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<ITRequestCollection>(
        SERVICE_URL + "/mine",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getDetail(id: number): Promise<ITRequestModel> {
    let item = new ITRequestModel(ITRequestModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>("tasks/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ITRequestModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add(props: ITRequestJsonAddEdit): Promise<ITRequestModel> {
    let item = new ITRequestModel(ITRequestModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ITRequestModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: ITRequestJsonAddEdit): Promise<ITRequestModel> {
    let item = new ITRequestModel(ITRequestModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ITRequestModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  //
  async editDisplayOrder(props: ITRequestJsonEdit): Promise<ITRequestModel> {
    let item = new ITRequestModel(ITRequestModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ITRequestModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ITRequestApi;
