import { Button, Col, Input, Row, Table, Tooltip } from "antd";
import Pos from "common/constants/Pos";
import Product from "common/constants/Product";
import ProductVariant from "common/constants/ProductVariant";
import InventoryStockModel from "common/models/InventoryStockModel";
import InventoryStockRepository from "common/repositories/InventoryStockRepository";
import { SearchResultItem } from "common/types/Pos";
import { PosCartOrderDetail } from "common/types/PosCart";
import { PosOrder } from "common/types/PosOrder";
import { TableColumnsType } from "common/types/Table";
import eventEmitter from "common/utils/eventEmitter";
import TextMoney from "components/TextMoney";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import dbm from "dbm";
import escapeStringRegexp from "escape-string-regexp";
import update from "immutability-helper";
import { find, findIndex, isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import addToCartFx from "sounds/pos/notify.mp3";
import useDeepCompareEffect from "use-deep-compare-effect";
import useSound from "use-sound";
import useCompanySettingStore from "zustands/useCompanySettingStore";
import usePosCartStore from "zustands/usePosCartStore";
import usePosStore from "zustands/usePosStore";

import useDidMount from "@rooks/use-did-mount";
import { IconLockOpen, IconX } from "@tabler/icons-react";

import PosSearchItemInventory from "./PosSearchItemInventory";
import PosSearchWarehouse from "./PosSearchWarehouse";

const PosSearch = () => {
  const { t } = useTranslation();
  const height = usePosStore((state) => state.height);
  const enableSoundFx = usePosStore((state) => state.enableSoundFx);
  const eventEmitterRef = useRef<any>();
  const [soundFxAddToCartSuccess] = useSound(addToCartFx);
  const activeWarehouseId = usePosCartStore(
    (state) => state.getActiveCart()?.warehouse_id || 0
  );
  const activeCartId = usePosCartStore((state) => state.activeCartId || "");
  const [quantitySignature, setQuantitySignature] = useState<any[]>([]);
  const lastQuantitySignatureRef = useRef("");

  const details = usePosCartStore(
    (state) => state.getActiveCart()?.details || []
  );

  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);

  const posCheckOutOfStock = useCompanySettingStore(
    (state) => state.getSetting("pos_check_out_of_stock") || 0
  );

  const textInputRef = useRef<any>(null);
  const lastKeywordSearchRef = useRef<string>("");
  const [keyword, setKeyword] = useState("");
  const [items, setItems] = useState<SearchResultItem[]>([]);

  const addToCart = useCallback(
    (item: SearchResultItem) => {
      //use variable for use later
      const serialNumber = "";
      const row_identifier = item.id + "-" + serialNumber;
      let insertItem: PosCartOrderDetail = {
        row_identifier: row_identifier,
        item_quantity: 1,
        product_id: item.product_id,
        product_variant_id: item.id,
        sku: item.sku,
        product_sell_on_zero: item.sell_on_zero,
        price: item.price,
        price_before_promotion: item.price,
        item_name: item.product_name,
        item_title: item.title,
        item_color: item.color,
        item_size: item.size,
        item_code: item.product_code,
        item_serial_number: serialNumber,
        promotions: [],
        promotion_options: [],
        manual_discount: false,
        manual_discount_type: 0,
        manual_discount_value: 0
      };

      //find item to update quantity
      const foundIndex = details.findIndex(
        (it) => it.row_identifier === row_identifier
      );
      if (foundIndex >= 0) {
        updateActiveCart({
          details: update(details, {
            [foundIndex]: {
              item_quantity: {
                $apply: (x) => x + 1
              }
            }
          })
        });
      } else {
        updateActiveCart({
          details: update(details, {
            $push: [insertItem]
          })
        });
      }

      //sound for success
      if (enableSoundFx) {
        soundFxAddToCartSuccess();
      }

      //focus
      if (textInputRef.current != null) {
        textInputRef.current.focus();
      }
    },
    [details, updateActiveCart, soundFxAddToCartSuccess, enableSoundFx]
  );

  const doSearch = useCallback(
    async (query: string, isPressEnter: boolean) => {
      //trim space
      query = query.trim();

      if (query.length === 0) {
        const defaultItems: SearchResultItem[] = await dbm
          .getCollection("productvariant")
          .chain()
          .find({})
          .simplesort("id", { desc: true })
          .limit(20)
          .data()
          .filter(
            (i) =>
              i.status === ProductVariant.STATUS_ENABLE &&
              i.product_status === Product.STATUS_ENABLE
          );

        setItems(defaultItems);
      } else {
        //Query DB to search
        const foundItems: SearchResultItem[] = await dbm
          .getCollection("productvariant")
          .chain()
          .find({
            search: { $regex: [escapeStringRegexp(query), "i"] }
          })
          .simplesort("id")
          .offset(0)
          .limit(20)
          .data()
          .filter(
            (i) =>
              i.status === ProductVariant.STATUS_ENABLE &&
              i.product_status === Product.STATUS_ENABLE
          );

        //check to add to cart automatically
        if (
          foundItems.length === 1 &&
          foundItems[0].sku === query &&
          isPressEnter
        ) {
          setKeyword("");

          addToCart({ ...foundItems[0] });
        } else {
          setItems(foundItems);
        }
      }
    },
    [addToCart]
  );

  const doSearchDelay = useCallback(
    (query: string) => {
      doSearch(query, false);
    },
    [doSearch]
  );

  const getRowClassName = useCallback(
    (_: any, index: number) => {
      let className = "";

      // 8935049501981: ko ton
      // 8935049502011: co ton
      if (posCheckOutOfStock && items[index].sell_on_zero === 0) {
        let outOfStock = true;

        if (items[index].inventory_details.length > 0) {
          const foundInventoryStock = find(items[index].inventory_details, {
            warehouse_id: activeWarehouseId
          });

          if (typeof foundInventoryStock !== "undefined") {
            if (foundInventoryStock.available > 0) {
              outOfStock = false;
            }
          }
        }

        if (outOfStock) {
          className = "pos-item-out-of-stock";
        }
      }

      return className;
    },
    [items, activeWarehouseId, posCheckOutOfStock]
  );

  const updateItemWithInventoryStock = useCallback(
    (results: InventoryStockModel[]) => {
      if (items.length > 0 && results.length > 0) {
        const newItems = items.map((item) => {
          const foundIndex = findIndex(results, {
            product_variant_id: item.id
          });

          return {
            ...item,
            inventory_details: foundIndex >= 0 ? results[foundIndex].stocks : []
          };
        });

        //VERY IMPORTANT, to prevent update
        if (!isEqual(newItems, items)) {
          setItems(newItems);
        }
      }
    },
    [items]
  );

  const doRequestInventory = useCallback(() => {
    const idList = quantitySignature.map((item) => item.product_variant_id);
    if (idList.length > 0) {
      //check to prevent load multiple
      if (lastQuantitySignatureRef.current !== idList.join(",")) {
        //store to prevent duplicate
        lastQuantitySignatureRef.current = idList.join(",");

        // console.log(
        //   "load with signature",
        //   lastQuantitySignatureRef.current,
        //   idList.join(","),
        //   lastQuantitySignatureRef.current !== idList.join(",")
        // );

        (async () => {
          const collection =
            await new InventoryStockRepository().getListProductVariantQuantity(
              idList.join(","),
              "possearch"
            );

          if (!collection.hasError()) {
            updateItemWithInventoryStock(collection.items);
          }
        })();
      } else {
        console.log("Already fetch inventory with list", idList.join(","));
      }
    }
  }, [quantitySignature, updateItemWithInventoryStock]);

  useEffect(() => {
    //only search when keyword change from saved value
    if (lastKeywordSearchRef.current !== keyword) {
      lastKeywordSearchRef.current = keyword;
      doSearchDelay(keyword);
    }
  }, [keyword, doSearchDelay]);

  //change quantity signature
  //to prepare for update inventory check
  useEffect(() => {
    //set state
    setQuantitySignature(
      items.map((item) => {
        return {
          product_variant_id: item.id
        };
      })
    );
  }, [items]);

  //check inventory when quantitysignature change
  useDeepCompareEffect(() => {
    doRequestInventory();
  }, [doRequestInventory]);

  /**
   * Reset quantity signature when order change
   */
  useEffect(() => {
    doRequestInventory();
  }, [activeCartId, doRequestInventory]);

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "POS_CREATE_ORDER_SUCCESS",
        (data: PosOrder) => {
          //clear signature to refresh inventory
          lastQuantitySignatureRef.current = "";
          doRequestInventory();
          console.log("POS_CREATE_ORDER_SUCCESS", "refetch inventory stock");
        }
      );
    }
  });

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);

  useDidMount(() => {
    doSearch("", false);
  });

  //Table columns
  const columns: TableColumnsType<SearchResultItem> = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      width: 140
    },
    {
      title: "TÊN SẢN PHẨM",
      dataIndex: "product_name"
    },
    {
      title: "CHI TIẾT",
      render: (_, record) => (
        <span>
          {record.sell_on_zero === 1 ? (
            <div className="float-right">
              <Tooltip
                title={t("product:sell_on_zero_tag")}
                mouseEnterDelay={0.4}>
                <IconLockOpen className="text-green-500" size={18} />
              </Tooltip>
            </div>
          ) : null}
          <TextProductVariantColor id={record.color} /> /{" "}
          <TextProductVariantSize id={record.size} />
        </span>
      )
    },
    {
      title: "GIÁ BÁN",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: 100,
      render: (price: number) => <TextMoney money={price} />
    },
    {
      title: "TỒN",
      dataIndex: "inventory",
      key: "id",
      align: "right",
      width: 80,
      render: (_, record) => {
        return <PosSearchItemInventory data={record} />;
      }
    }
  ];

  return (
    <>
      <div
        style={{ height: Pos.SEARCH_INPUT_WRAPPER_HEIGHT, overflow: "hidden" }}>
        <div className="p-1 mb-0" style={{ backgroundColor: "#E8E8E8" }}>
          <Row gutter={0}>
            <Col span={16}>
              <Input
                allowClear={{
                  clearIcon: (
                    <Button
                      type="default"
                      size="middle"
                      icon={<IconX size={20} />}
                      className="-mr-1 "></Button>
                  )
                }}
                autoFocus
                placeholder={t("pos:product_search_placeholder")}
                size="large"
                value={keyword}
                ref={textInputRef}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    doSearch(lastKeywordSearchRef.current, true);
                  }
                }}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
            </Col>
            <Col span={8}>
              <PosSearchWarehouse />
            </Col>
          </Row>
        </div>
      </div>
      <Scrollbars
        style={{
          height: height - Pos.SEARCH_INPUT_WRAPPER_HEIGHT,
          overflow: "hidden"
        }}
        autoHide>
        <Table
          rowKey="sku"
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                addToCart(items[rowIndex ?? -1]);
              } // click row
            };
          }}
          rowClassName={getRowClassName}
          size="small"
          tableLayout="fixed"
          columns={columns}
          dataSource={items}
        />
      </Scrollbars>
    </>
  );
};

export default PosSearch;
