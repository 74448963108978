import CmsNewsCategoryModel from 'common/models/CmsNewsCategoryModel';
import CmsNewsCategoryRepository from 'common/repositories/CmsNewsCategoryRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import CmsNewsCategoryForm from 'features/cmsnewscategory/form/CmsNewsCategoryForm';
import CmsNewsCategoryFormHeader from 'features/cmsnewscategory/form/CmsNewsCategoryFormHeader';
import CmsNewsCategoryList from 'features/cmsnewscategory/list/CmsNewsCategoryList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const CmsNewsCategoryPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<CmsNewsCategoryModel>(
    new CmsNewsCategoryModel(CmsNewsCategoryModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new CmsNewsCategoryModel(CmsNewsCategoryModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <CmsNewsCategoryForm
          model={
            new CmsNewsCategoryModel(CmsNewsCategoryModel.getDefaultData())
          }
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <CmsNewsCategoryForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new CmsNewsCategoryRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<CmsNewsCategoryFormHeader isEditing={true} />}
          />
        );

      break;
    default:
      com = <CmsNewsCategoryList />;
  }

  return com;
};

export default CmsNewsCategoryPage;
