import { Col, Empty, List, Row } from "antd";
import File from "common/constants/File";
import FileModel from "common/models/FileModel";
import FileRepository from "common/repositories/FileRepository";
import FileItemDirectory from "components/file/item/FileItemDirectory";
import FileItemFile from "components/file/item/FileItemFile";
import FileItemEditDirectory from "components/file/itemedit/FileItemEditDirectory";
import FileListToolbar from "components/file/list/FileListToolbar";
import SimplePagination from "components/SimplePagination";
import update from "immutability-helper";
import { matchSorter } from "match-sorter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import useFileManagerStore from "zustands/useFileManagerStore";

import type {
  ViewType,
  FileJsonAddEdit,
  EditingQueue,
  FileRoute,
  FilterFile,
} from "common/types/File";
const FileList = ({
  onSelectFile,
  allowExtensions,
  objectType,
  origin,
  rootLabel,
}: {
  allowExtensions: string[];
  onSelectFile: (f: FileModel) => void;
  objectType: number;
  origin: string;
  rootLabel?: string;
}) => {
  const isExplorable =
    objectType === File.OBJECTTYPE_FILE || objectType === File.OBJECTTYPE_CMS;

  const defaultViewType: ViewType = "grid";

  const defaultFilters: FilterFile = useMemo(
    () => FileRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useState<FilterFile>({
    ...defaultFilters,
    object_type: objectType,
    origin,
  });

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [viewType, setViewType] = useState<ViewType>(defaultViewType);
  const [total, setTotal] = useState(0);
  const [foundFiles, setFoundFiles] = useState<FileModel[]>([]);
  const [foundDirectories, setFoundDirectories] = useState<FileModel[]>([]);
  const [editingDirectoryQueue, setEditingDirectoryQueue] = useState<
    EditingQueue[]
  >([]);
  const [routes, setRoutes] = useState<FileRoute[]>([
    {
      path: "",
      breadcrumbName: rootLabel || t("file:home"),
    },
  ]);

  const [activeFile] = useFileManagerStore((state) => [state.activeFile]);

  /**
   * For file filtering, we just filter from browser result, with `keyword` and extension
   */
  const getDisplayFoundFiles = useCallback(
    (files: FileModel[]) => {
      return matchSorter(
        allowExtensions.length > 0
          ? files.filter((f) =>
              allowExtensions.includes(f.extension.toLowerCase())
            )
          : files,
        keyword,
        {
          keys: ["title"],
        }
      );
    },
    [allowExtensions, keyword]
  );

  const moveActiveFile = useCallback(
    (offset: number) => {
      if (typeof activeFile !== "undefined") {
        //find current file position
        const displayFiles = getDisplayFoundFiles(foundFiles);

        const foundIndex = displayFiles.findIndex(
          (f) => f.id === activeFile.id
        );
        if (foundIndex >= 0) {
          //we rotate the offset
          let newIndex = foundIndex + offset;
          //move LEFT from first element, jump to Last element
          if (newIndex < 0) {
            newIndex = displayFiles.length - 1;
          } else if (newIndex > displayFiles.length - 1) {
            //move RIGHT from last element, jump to First element
            newIndex = 0;
          }
          onSelectFile(displayFiles[newIndex]);
        }
      }
    },
    [activeFile, foundFiles, onSelectFile, getDisplayFoundFiles]
  );

  useHotkeys("left, up", () => moveActiveFile(-1), [moveActiveFile]);
  useHotkeys("right, down", () => moveActiveFile(1), [moveActiveFile]);

  const loadData = useCallback(async () => {
    setLoading(true);

    let collection = await new FileRepository().getFileAndDirectories({
      filters: { ...filters, object_type: objectType, origin },
    });

    setTotal(collection.total);
    setFoundDirectories(collection.items.filter((i) => i.is_directory));
    setFoundFiles(collection.items.filter((i) => !i.is_directory));
    setKeyword("");

    setLoading(false);
  }, [filters, objectType, origin]);

  const onUploadCompleted = (file: FileModel) => {
    //ensure viewing the same parent directory
    if (file.directory_id === filters.directory_id) {
      if (file.is_directory) {
        setFoundDirectories([...foundDirectories, file]);
      } else {
        setFoundFiles([...foundFiles, file]);
      }
    }
  };

  const onDeleteCompleted = (file: FileModel) => {
    if (file.is_directory) {
      //delete directory
      setFoundDirectories(foundDirectories.filter((f) => f.id !== file.id));
    } else {
      //delete file
      setFoundFiles(foundFiles.filter((f) => f.id !== file.id));
    }
  };

  const onClickEditDirectoryToggle = (file: FileModel) => {
    const findInQueue = editingDirectoryQueue.findIndex(
      (q) => q.id === file.id
    );
    if (findInQueue >= 0) {
      //remove from queue
      setEditingDirectoryQueue(
        editingDirectoryQueue.filter((q) => q.id !== file.id)
      );
    } else {
      setEditingDirectoryQueue(
        update(editingDirectoryQueue, {
          $push: [
            {
              id: file.id,
              processing: false,
              errors: [],
            },
          ],
        })
      );
    }
  };

  //click save button (submit) rename
  const onSaveDirectory = async (data: FileJsonAddEdit) => {
    //find editing file in queue
    const queueIndex = editingDirectoryQueue.findIndex(
      (f: EditingQueue) => f.id === data.id
    );

    if (queueIndex >= 0) {
      //mark as processing
      setEditingDirectoryQueue(
        update(editingDirectoryQueue, {
          [queueIndex]: {
            $set: {
              id: data.id,
              processing: true,
              errors: [],
            },
          },
        })
      );

      const updatedDirectory: FileModel =
        await new FileRepository().saveDirectoryRemote(data);

      if (updatedDirectory.hasError()) {
        //mark as processed & found error
        setEditingDirectoryQueue(
          update(editingDirectoryQueue, {
            [queueIndex]: {
              $set: {
                id: data.id,
                processing: false,
                errors: updatedDirectory.error.errors,
              },
            },
          })
        );
        console.log(
          update(editingDirectoryQueue, {
            [queueIndex]: {
              $set: {
                id: data.id,
                processing: false,
                errors: updatedDirectory.error.errors,
              },
            },
          })
        );
      } else {
        //remove from editing list
        onClickEditDirectoryToggle(updatedDirectory);

        //update model back to directory
        const listingIndex = foundDirectories.findIndex(
          (f: FileModel) => f.id === updatedDirectory.id
        );
        setFoundDirectories(
          update(foundDirectories, {
            [listingIndex]: {
              $set: updatedDirectory,
            },
          })
        );
      }
    }
  };

  //Click on one directory
  const onClickDirectory = (file: FileModel) => {
    setFilters({ ...filters, directory_id: file.id });
    setRoutes([
      ...routes,
      {
        path: "" + file.id,
        breadcrumbName: file.title,
      },
    ]);
  };

  //Click on file
  const onClickFile = (file: FileModel) => {
    if (typeof onSelectFile === "function") {
      onSelectFile(file);
    }
  };

  //deepcompare to prevent duplicate loading with same filters set
  useEffect(() => {
    loadData();
  }, [loadData]);

  //Datasource = (filtered & sorted directories) + (filtered & sorted & limit to allow extensions files)
  const dataSource = matchSorter(foundDirectories, keyword, {
    keys: ["title"],
  }).concat(getDisplayFoundFiles(foundFiles));

  return (
    <>
      <FileListToolbar
        objectType={objectType}
        origin={origin}
        filters={filters}
        setFilters={setFilters}
        keyword={keyword}
        setKeyword={setKeyword}
        routes={routes}
        setRoutes={setRoutes}
        viewType={viewType}
        setViewType={setViewType}
        allowExtensions={allowExtensions}
        onUploadCompleted={onUploadCompleted}
        onDeleteCompleted={onDeleteCompleted}
      >
        <Row className="pl-4">
          <Col>
            <div className="text-xs text-gray-500">
              <div className="mt-1">
                {isExplorable ? (
                  <>
                    {dataSource.length - foundDirectories.length}{" "}
                    {t("file:result.file")} & {foundDirectories.length}{" "}
                    {t("file:result.directory")}
                  </>
                ) : (
                  <>
                    {total} {t("file:result.file")}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col className="">
            <SimplePagination
              filters={filters}
              setFilters={setFilters}
              total={total}
              size="small"
              hideOnSinglePage={true}
            />
          </Col>
        </Row>
      </FileListToolbar>
      <List
        key={viewType}
        size="small"
        grid={
          viewType === "grid"
            ? {
                gutter: 4,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 6,
                xxl: 7,
              }
            : undefined
        }
        loading={loading}
        dataSource={dataSource}
        renderItem={(item) =>
          item.is_directory ? (
            editingDirectoryQueue.findIndex((q) => q.id === item.id) >= 0 ? (
              <FileItemEditDirectory
                viewType={viewType}
                item={item}
                onClickEditToggle={onClickEditDirectoryToggle}
                onSave={onSaveDirectory}
                editingQueue={
                  editingDirectoryQueue.find((q) => q.id === item.id) || {
                    id: 0,
                    processing: false,
                    errors: [],
                  }
                }
              />
            ) : (
              <FileItemDirectory
                viewType={viewType}
                highlight={keyword}
                item={item}
                onClick={onClickDirectory}
                onClickEditToggle={onClickEditDirectoryToggle}
                onDeleteCompleted={onDeleteCompleted}
              />
            )
          ) : (
            <FileItemFile
              viewType={viewType}
              highlight={keyword}
              item={item}
              onClick={onClickFile}
              onDeleteCompleted={onDeleteCompleted}
            />
          )
        }
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("file:empty")}
            />
          ),
        }}
      />
    </>
  );
};

export default FileList;
