import { Skeleton } from "antd";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import ShippingRouteRepository from "common/repositories/ShippingRouteRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import ShippingRouteForm from "features/shippingroute/form/ShippingRouteForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ShippingRouteFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: ShippingRouteModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<ShippingRouteModel>(
    new ShippingRouteModel(ShippingRouteModel.getDefaultData()),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new ShippingRouteRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={
        id > 0
          ? t("shippingroute:heading_edit")
          : t("shippingroute:heading_add")
      }
      width={720}
      confirmLoading={processing}
      open={open}
      setOpen={setOpen}
      formId="shippingroute-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <ShippingRouteForm
                  id={id}
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <ShippingRouteForm
          id={0}
          model={new ShippingRouteModel(ShippingRouteModel.getDefaultData())}
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default ShippingRouteFormModal;
