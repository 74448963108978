import React, { useMemo } from "react";

const InfoContentItem = ({
  label,
  content,
  size,
  className,
}: {
  label: React.ReactNode;
  content: React.ReactNode;
  size?: "small" | "medium" | "large";
  className?: string;
}) => {
  const paddingClassName = useMemo(() => {
    let paddingCl = "py-4";
    switch (size) {
      case "small":
        paddingCl = "py-2";
        break;
      case "medium":
        paddingCl = "py-4";
        break;
      case "large":
        paddingCl = "py-6";
        break;
    }

    return paddingCl;
  }, [size]);
  return (
    <>
      <div
        className={
          "px-4 hover:bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 " +
          paddingClassName +
          " " +
          className
        }
      >
        <dt
          className={
            "mt-0.5 text-sm text-gray-900" +
            (size === "small" ? " font-normal" : "")
          }
        >
          {label}
        </dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {content}
        </dd>
      </div>
    </>
  );
};

export default InfoContentItem;
