import WorkScheduleManage from 'features/workschedule/manage/WorkScheduleManage';
import WorkScheduleMine from 'features/workschedule/mine/WorkScheduleMine';
import usePathParams from 'hooks/usePathParams';

const WorkSchedulePage = () => {
  const [pathParams] = usePathParams();

  let com = null;
  switch (pathParams.action) {
    case "manage":
      com = <WorkScheduleManage />;
      break;
    default:
      com = <WorkScheduleMine />;
  }

  return com;
};

export default WorkSchedulePage;
