import { Tooltip } from "antd";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

import { IconClock, IconUser } from "@tabler/icons-react";

const PurchaseOrderFormSectionInfo = () => {
  const { t } = useTranslation();
  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );
  const iconProps = { size: 16 };

  return (
    <div className="py-4 leading-7 opacity-80 hover:opacity-100">
      {purchaseOrder.id > 0 ? (
        <>
          <div>
            <Tooltip title={t("common:creator_id")} placement="left">
              <IconUser {...iconProps} />
            </Tooltip>
            &nbsp;: <TextUser id={purchaseOrder.creator_id} />
          </div>

          <div>
            <Tooltip title={t("purchaseorder:date_created")} placement="left">
              <IconClock {...iconProps} />
            </Tooltip>
            &nbsp;:{" "}
            <TextDateTime
              format="HH:mm, DD/MM/YYYY"
              ts={purchaseOrder.date_created}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PurchaseOrderFormSectionInfo;
