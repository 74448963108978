import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Skeleton,
  Typography,
  message,
} from "antd";
import { useTranslation } from "react-i18next";

import { PlusOutlined } from "@ant-design/icons";
import { IconPencil, IconX } from "@tabler/icons-react";
import { OrderProductJson } from "common/types/Delivery";
import FileModel from "common/models/FileModel";
import useHandleSetting from "hooks/useHandleSetting";
import SettingApi from "common/constants/Setting";
import { useEffect, useState } from "react";
import ListDeleteButton from "components/list/ListDeleteButton";
import ShopSectionAboutForm from "./ShopSectionAboutForm";
import FormSelect from "components/form/FormSelect";
import FormSelectNormal from "components/form/FormSelectNormal";
import PageSortList from "components/list/PageSortList";
import { DropResult } from "react-beautiful-dnd";
import { ShopSectionJson } from "../list/ShopCollectionList";
import { FileJson } from "common/types/File";
const { RangePicker } = DatePicker;

export type SectionAboutJson = {
  icon: string;
  title: string;
  content: string;
};

const ShopSectionAboutEdit = ({
  isEditing,
  model,
}: {
  isEditing: boolean;
  model: ShopSectionJson;
}) => {
  const { t } = useTranslation();
  const ARRAY_ABOUT = [
    {
      label: "about_namperfume_1",
      value: "about_namperfume_1",
    },
    {
      label: "about_namperfume_2",
      value: "about_namperfume_2",
    },
  ];

  const [form] = Form.useForm();

  const defaultDataPopup: SectionAboutJson = {
    icon: "",
    title: "",
    content: "",
  };
  //state
  const [open, setOpen] = useState<boolean>(false);
  const [handleSelect, setHandleSelect] = useState<string>(
    model.handle.length > 0 ? model.handle : "about_namperfume_1"
  );
  console.log("🥺 ~ handleSelect:", handleSelect);

  const [data, setData] = useState<{
    data: SectionAboutJson;
    index: number;
    type: "add" | "edit";
  }>({
    data: defaultDataPopup,
    index: -1,
    type: "add",
  });

  const {
    dataSource,
    loading,
    addItemSetting,
    deleteItemSetting,
    editItemSetting,
    handleDrag,
  } = useHandleSetting<SectionAboutJson>(handleSelect, "vi");

  const handleDragEnd = async (result: DropResult) => {
    try {
      if (!result.destination) return;
      await handleDrag(result.source.index, result.destination.index);
      message.success({
        content: "Thao tác thành công",
        duration: 1,
      });
    } catch (error) {
      message.error({
        content: "Thao tác thất bại",
        duration: 1,
      });
    }
  };

  function handlelOpenModalAdd(data: SectionAboutJson) {
    setData({
      data: data,
      type: "add",
      index: -1,
    });
    setOpen(true);
  }
  function handlelOpenModalEdit(data: SectionAboutJson, index: number) {
    setData({
      data: data,
      type: "edit",
      index,
    });
    setOpen(true);
  }

  async function handleSumit(dataRes: SectionAboutJson) {
    try {
      if (data.type === "add") {
        await addItemSetting(dataRes);
      } else {
        await editItemSetting(dataRes, data.index);
      }
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }

  async function handleDelete(data: SectionAboutJson, index: number) {
    try {
      await deleteItemSetting(data, index);
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }

  const initialValues = {
    handle: model.handle.length > 0 ? model.handle : "about_namperfume_1",
  };

  useEffect(() => {
    form.setFieldsValue({
      ...(initialValues as any),
    });
  }, [model]);

  return (
    <>
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <FormSelectNormal
              disabled={isEditing}
              value={handleSelect}
              defaultValue={"about_namperfume_1"}
              name="handle"
              rules={[{ required: true, message: "Vui lòng chọn" }]}
              label="Chọn section"
              options={ARRAY_ABOUT}
              onChange={(e) => {
                if (e) {
                  setHandleSelect(e + "");
                }
              }}
            />
          </Col>
        </Row>
      </Form>

      <div className="flex justify-end items-end">
        <Button
          type="primary"
          onClick={() => handlelOpenModalAdd(defaultDataPopup)}
        >
          Thêm
        </Button>
      </div>

      {!loading ? (
        <>
          <PageSortList
            dataSource={dataSource}
            handleDragEnd={handleDragEnd}
            listKey="shopcollectionlist"
            renderListItem={(item: SectionAboutJson, index) => {
              return (
                <>
                  <div className="p-2">
                    <div className="flex  items-center justify-between">
                      <div className="flex flex-col">
                        <Typography.Text className="font-bold">
                          {item.title}
                        </Typography.Text>
                      </div>
                      <div className=" flex gap-2">
                        <Button
                          onClick={() => {
                            handlelOpenModalEdit(item, index);
                          }}
                        >
                          Sửa
                        </Button>

                        <ListDeleteButton
                          handleDelete={() => {
                            handleDelete(item, index);
                          }}
                          id={index}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        </>
      ) : (
        <Skeleton active />
      )}
      <ShopSectionAboutForm
        type={data.type}
        data={data.data}
        open={open}
        onCancel={() => setOpen(false)}
        onFinish={(data) => {
          handleSumit(data);
        }}
      />
    </>
  );
};

export default ShopSectionAboutEdit;
