import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import { Card, Col, Row, Skeleton, Space } from "antd";
import NextSlideIcon from "features/academy/icon/NextSlideIcon";
import PrevSlideIcon from "features/academy/icon/PrevSlideIcon";
import {
  A11y,
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
  EffectCards,
} from "swiper/modules";

type ScrollbarOptions = {
  hide: boolean;
  draggable: boolean;
  dragSize: number | "auto";
  dragClass: string;
  verticalClass: string;
  lockClass: string;
  snapOnRelease: boolean;
  enable: boolean;
  scrollbarDisabledClass: string;
};

type PaginationOptions = {
  bulletActiveClass: string;
  bulletClass: string;
  bulletElement: string;
  clickable: boolean;
  clickableClass: string;
  currentClass: string;
  dynamicBullets: boolean;
  dynamicMainBullets: number;
  // el: null | HTMLElement | React.CSSProperties;
  enabled: boolean;
  // formatFractionCurrent: (number: number) => void;
  // formatFractionTotal: (number: number) => void;
  hiddenClass: string;
  hideOnClick: boolean;
  horizontalClass: string;
  lockClass: string;
  modifierClass: string;
  paginationDisabledClass: string;
  progressbarFillClass: string;
  progressbarOpposite: boolean;
  progressbarOppositeClass: string;
  // renderBullet: string;
  // renderCustom: string;
  // renderFraction: string;
  // renderProgressbar: string;
  totalClass: string;
  type: "bullets" | "fraction" | "progressbar" | "custom";
  verticalClass: string;
};
type breakpoints = {
  xxs: s;
  xs: s;
  sm: s;
  md: s;
  lg: s;
  xl: s;
};
type s = {
  spaceBetween: number;
  slidesPerView: number;
};
type responsive<breakpoints> = {
  [Property in keyof breakpoints]: breakpoints[Property];
};
export type PropsAcademySlide = {
  dataSource: any[];
  itemRender: (item: any, index: number) => React.ReactNode;
  isloading: boolean;
  responsive: responsive<breakpoints>;
  styleWrapper?: React.CSSProperties | undefined;
  spaceBetween?: string | number | undefined;
  slidesPerView?: number | "auto" | undefined;
  navigation?: boolean;
  pagination?: boolean | PaginationOptions | undefined;
  scrollbar?: boolean | ScrollbarOptions | undefined;
  onSwiper?: ((swiper: any) => void) | undefined;
  onSlideChange?: ((swiper: any) => void) | undefined;
  allowSlideNext?: boolean | undefined;
  allowSlidePrev?: boolean | undefined;
  allowTouchMove?: boolean | undefined;
  autoplay?: boolean | { delay: number; disableOnInteraction: boolean };
  loop?: boolean;
  widthAutoSlide?: boolean;
  handleError?: () => void;
  slidesPerGroup?: number;
  styleButton?: React.CSSProperties | undefined;
  styleButton_next?: React.CSSProperties | undefined;
  styleButton_prev?: React.CSSProperties | undefined;
  initialSlide: number;
  classButtonSlide?: string;
  idSwiper?: string;
  setButtonNext?: string;
  setButtonPrev?: string;
  speed?: number | undefined;
  classWrapper?: string;
};
const AcademySlide = ({
  dataSource = [],
  itemRender,
  isloading = true,
  handleError,
  styleWrapper = { width: "100%" },
  spaceBetween = 4,
  slidesPerView = 6,
  navigation = true,
  pagination = false,
  scrollbar = false,
  onSwiper = (swiper) => {
    return;
  },
  onSlideChange = () => console.log("slide change"),
  allowSlideNext = true,
  allowSlidePrev = true,
  allowTouchMove = true,
  loop = false,
  autoplay = {
    delay: 1500,
    disableOnInteraction: false,
  },
  responsive,
  widthAutoSlide = false,
  slidesPerGroup = 1,
  styleButton,
  styleButton_next,
  styleButton_prev,
  initialSlide,
  classButtonSlide,
  idSwiper,
  setButtonNext,
  setButtonPrev,
  speed = 1500,
  classWrapper,
}: PropsAcademySlide) => {
  const { Meta } = Card;

  const swiperRef = useRef<any>();

  const swiper = useSwiper();

  //

  const handleSlideNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  const handleSlidePrev = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  //

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(initialSlide);
    }
  }, [initialSlide]);

  useEffect(() => {
    if (swiper) {
      const buttonPrev = document.getElementById("button_swiper_prev");
      if (buttonPrev) {
        buttonPrev.addEventListener("click", handleSlidePrev);
      }
      const buttonNext = document.getElementById("button_swiper_next");
      if (buttonNext) {
        buttonNext.addEventListener("click", handleSlideNext);
      }
    }
    return () => {
      if (swiper) {
        const buttonNext = document.getElementById("button_swiper_next");
        if (buttonNext) {
          buttonNext.removeEventListener("click", handleSlideNext);
        }
      }
    };
  }, [swiper]);
  return (
    <div className={classWrapper} style={{ ...styleWrapper }}>
      {isloading ? (
        <Space>
          <Row
            style={{ flexWrap: "nowrap", overflow: "hidden" }}
            gutter={[8, 8]}
          >
            {Array.from(new Array(6)).map((i, index) => (
              <Col key={index} span={24} sm={6} md={4}>
                <Card style={{ width: "100%" }} loading={isloading}>
                  <Skeleton loading={isloading} avatar active />
                </Card>
              </Col>
            ))}
          </Row>
        </Space>
      ) : dataSource.length <= 0 ? null : (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <Swiper
            style={{ padding: "5px 0" }}
            ref={swiperRef}
            // install Swiper modules
            id={idSwiper}
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectFade,
              EffectCards,
            ]}
            effect="cards"
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            // initialSlide={initialSlide}
            navigation={
              navigation
                ? {
                    nextEl: setButtonNext
                      ? `.${setButtonNext}`
                      : "swiper-button-next",
                    prevEl: setButtonPrev
                      ? `.${setButtonPrev}`
                      : "button_swiper_prev",
                    // disabledClass: "disable_hide",
                  }
                : false
            }
            pagination={
              pagination
                ? pagination
                : { clickable: false, renderBullet: () => "" }
            }
            scrollbar={
              scrollbar !== false
                ? { draggable: true, hide: true, enabled: false }
                : false
            }
            // pagination={pagination ? pagination : { clickable: false, renderBullet: () => "" }}
            // scrollbar={scrollbar ? scrollbar : { draggable: true, hide: true, enabled: false }}
            onSwiper={onSwiper}
            onSlideChange={onSlideChange}
            allowSlideNext={allowSlideNext}
            allowSlidePrev={allowSlidePrev}
            allowTouchMove={allowTouchMove}
            autoplay={autoplay}
            loop={loop}
            speed={speed}
            slidesPerGroup={slidesPerGroup}
            breakpoints={{
              279: responsive.xxs,
              325: responsive.xs,
              414: responsive.sm,
              768: responsive.md,
              1024: responsive.lg,
              1500: responsive.xl,
            }}
          >
            {dataSource.map((item: any, index) => {
              return (
                <SwiperSlide key={index}>{itemRender(item, index)}</SwiperSlide>
              );
            })}
          </Swiper>
          {navigation ? (
            <>
              <button
                id="button_swiper_next"
                style={{ ...styleButton, ...styleButton_next }}
                className={`swiper-button-next ${setButtonNext}  ${
                  classButtonSlide ? classButtonSlide : ""
                }`}
              >
                <NextSlideIcon></NextSlideIcon>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-right"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.75"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  color="#3a393a"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 6l6 6l-6 6"></path>
                </svg> */}
              </button>
              <button
                id="button_swiper_prev"
                style={{ ...styleButton, ...styleButton_prev }}
                className={`swiper-button-prev ${setButtonPrev} ${
                  classButtonSlide ? classButtonSlide : ""
                }`}
              >
                <PrevSlideIcon></PrevSlideIcon>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-left"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.75"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  color="#3a393a"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 6l-6 6l6 6"></path>
                </svg> */}
              </button>
            </>
          ) : null}
          {/* <SlidePrevButton></SlidePrevButton>
          <SlideNextButton></SlideNextButton> */}
        </div>
      )}
    </div>
  );
};

export default AcademySlide;
