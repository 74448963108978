import { Form, Select } from "antd";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import { FilterTagType } from "common/types/TagType";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDebounce } from "rooks";

interface Option {
  value: number;
  label: string;
  resource_type: number;
}
function removeItemDuplicate(arr: Option[]) {
  let uniqueArray = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    let foundDuplicate = false;
    for (let j = i - 1; j >= 0; j--) {
      if (arr[i].value === arr[j].value) {
        foundDuplicate = true;
        break;
      }
    }
    if (!foundDuplicate) {
      uniqueArray.unshift(arr[i]);
    }
  }
  return uniqueArray;
}

const TagTypeFormSelectNormal = (props: {
  values?: number[];
  ignoreValues?: number[];
  multiple?: boolean;
  name?: string;
  placeholder?: string;
  isMulti?: boolean;
  className?: string;
  icon?: React.ReactNode;
  width?: number;
  onChange: (v: number[]) => void;
}) => {
  //state
  const [tagTypeOption, setTagTypeOption] = React.useState<any[]>([]);
  const [keyword, setKeyword] = React.useState<string>("");

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const defaultFilters: FilterTagType = useMemo(() => {
    return { ...TagTypeRepository.getDefaultFilters(), status: 1 };
  }, []);
  const fetchProductValueDefault = useCallback(async (ids: number[]) => {
    if (!ids.length) return;
    const myObj = await new TagTypeRepository().getItem(ids[0]);
    setLoading(false);

    if (myObj.hasError()) {
      setError(myObj.error.errors[0]);
    } else {
      const defauilValueOption = [
        {
          value: myObj.id,
          label: `${myObj.name} (ID:${myObj.id})`,
          resource_type: myObj.resource_type,
        },
      ];

      return defauilValueOption;
    }
  }, []);
  const fetchDataOptions = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      let collection = await new TagTypeRepository().getItems({
        filters: keyword
          ? { ...defaultFilters, keyword: keyword }
          : { ...defaultFilters },
      });
      if (collection.hasError()) {
        setError(collection.error.errors[0]);
      } else {
        const productArrayOption = collection.items.map((emp) => {
          return {
            value: emp.id,
            label: `${emp.name} (ID:${emp.id})`,
            resource_type: emp.resource_type,
          };
        });
        if (props.values?.length && props.values[0] > 0) {
          let dataDe = await fetchProductValueDefault(props.values);
          if (dataDe) {
            const mergeOption: any[] = [...dataDe, ...productArrayOption];
            const options = removeItemDuplicate(mergeOption);
            setTagTypeOption(options);
          }
        } else {
          setTagTypeOption(productArrayOption);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [defaultFilters, fetchProductValueDefault, keyword, props.values]);

  const onChangeHandle = (value: any) => {
    const findItem = tagTypeOption.find(
      (item) => item.value === value
    );
    if (findItem) {
      if (typeof props.onChange !== "undefined") {
        if (typeof value != "undefined") {
          setKeyword("");
          if (Array.isArray(value)) {
            props.onChange(findItem);
          } else {
            props.onChange([findItem]);
          }
        } else {
          props.onChange([]);
        }
      }
    }
  };
  const handleInputChage = useDebounce((value: string) => {
    setKeyword(value);
    setLoading(false);
  }, 1000);
  useEffect(() => {
    fetchDataOptions();
  }, [fetchDataOptions, keyword]);

  return (
    <Form.Item noStyle>
      <Select<number[]>
        {...(props.icon && { suffixIcon: props.icon })}
        virtual={false}
        // dropdownClassName={className}
        style={{ width: props.width || "100%" }}
        onChange={onChangeHandle}
        onSearch={debounce((value) => {
          setLoading(true);
          handleInputChage(value);
        }, 1000)}
        loading={loading}
        placeholder={props.placeholder}
        allowClear={true}
        showSearch={true}
        mode={props.multiple ? "multiple" : undefined}
        defaultValue={props.values || []}
        optionFilterProp="label"
        filterOption={(input: any, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.label
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[đĐ]/g, "d")
            .toLowerCase()
            .indexOf(
              input
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[đĐ]/g, "d")
                .toLowerCase()
            ) >= 0
        }
        options={tagTypeOption}
      ></Select>
    </Form.Item>
  );
};

export default TagTypeFormSelectNormal;
