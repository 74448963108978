import i18n from "i18n";

import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "common/models/BaseModel";
import { LeaveNoteJson } from "common/types/LeaveNote";

class LeaveNoteModel extends BaseModel implements BaseModelJson<LeaveNoteJson> {
  // stt?: number;
  ah_id: number;
  ce_id: number;
  ah_note: string;
  ah_status: number;

  constructor(json: LeaveNoteJson) {
    super();
    // this.stt = json.stt || 0;
    this.ah_id = json.ah_id || 0;
    this.ce_id = json.ce_id || 0;
    this.ah_note = json.ah_note || "";
    this.ah_status = json.ah_status || 0;
  }

  static getDefaultData(): LeaveNoteJson {
    return {
      ah_id: 0,
      ce_id: 0,
      ah_note: "",
      ah_status: 0,
    };
  }

  toJson(): LeaveNoteJson {
    return {
      ah_id: this.ah_id,
      ce_id: this.ce_id,
      ah_note: this.ah_note,
      ah_status: this.ah_status,
    };
  }
}

export default LeaveNoteModel;
