import BaseCollection from 'common/collections/BaseCollection';
import ShippingBillModel from 'common/models/ShippingBillModel';
import { ShippingBillCollectionJson, ShippingBillJson } from 'common/types/ShippingBill';

class ShippingBillCollection extends BaseCollection<
  ShippingBillJson,
  ShippingBillModel,
  ShippingBillCollectionJson
> {
  itemsFromJson(jsonItems: ShippingBillJson[]): ShippingBillModel[] {
    return jsonItems.map((item) => new ShippingBillModel(item));
  }
}

export default ShippingBillCollection;
