import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children: React.ReactNode;
};
const ContractTypesListHeader = ({ children }: Props) => {
  const { t } = useTranslation();
  const heading = t("contract:manager_type_contract");
  return <PageHeader heading={heading}>{children}</PageHeader>;
};

export default ContractTypesListHeader;
