import { useEffect, useState } from "react";
import styles from "../css/AcademyLayout.module.css";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import LogoDesktopIcon from "../icon/LogoDesktopIcon";
import AcademyMenu from "../component/AcademyMenu";
import AcademyCustomCollection from "../component/AcademyCustomColleciton";
import { Drawer } from "antd";
const AcademyLayout = () => {
  const [idCategory, setIdCategory] = useState<number>(0);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const handleActive = () => {
    handleShowSidebar();
  };

  const handleShowSidebar = () => {
    setIsOpenSidebar((prev) => !prev);
  };

  return (
    <div id="academy_layout" className={styles.academyLayout}>
      <div className="hidden md:block academy_logo text-4xl mb-4  mx-10">
        <Link to={"/academy"}>
          <LogoDesktopIcon></LogoDesktopIcon>
        </Link>
      </div>
      <AcademyMenu onMenuClick={handleActive} defaultKeys={["0"]} />
      <div className={styles.academyBody}>
        <Outlet></Outlet>
      </div>
      <div className={styles.sidebar_mobile}>
        <Drawer
          title="Danh mục"
          placement="right"
          onClose={() => {
            setIsOpenSidebar(false);
          }}
          open={isOpenSidebar}
        >
          <AcademyCustomCollection
            isMobile={true}
            onChange={handleShowSidebar}
          ></AcademyCustomCollection>
        </Drawer>
      </div>
    </div>
  );
};

export default AcademyLayout;
