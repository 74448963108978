
import { Card, message, Typography } from "antd";
import { Button } from "antd";
import { Col, Form, Input, Row } from "antd";
import { DataZoneBD, ZoneDataBanner } from "common/types/Event";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";
import { useForm } from "antd/es/form/Form";
import { CloseOutlined } from "@ant-design/icons";

const SectionSubbannerDB = ({
	data,
	onSubmit
}: {
	data: ZoneDataBanner[];
	onSubmit: (data: DataZoneBD) => void;

}) => {
	const { t } = useTranslation();

	const [form] = useForm()


	const init = data
	const onFinish = (values: any) => {
		const value = values.items
		onSubmit(value)
	};

	const setDynamicFieldValue = () => {
		const users = form.getFieldValue('items') || [];
		if (users.length > 0) {
			users[0].name = 'Updated Name'; // Cập nhật trường name của người dùng đầu tiên
			form.setFieldsValue({ users });
		}
	};
	return (
		<Form
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			form={form}
			name="dynamic_form_sub_banner"
			autoComplete="off"
			initialValues={{ items: init }}
			layout="vertical"
			onFinish={onFinish}
		>
			<Form.List name="items">
				{(fields, { add, remove }) => (
					<div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
						{fields.map((field) => (
							<Card
								size="default"
								title={`Hình ${field.name + 1}`}
								key={field.key}
								extra={
									<CloseOutlined
										onClick={() => {
											remove(field.name);
										}}
									/>
								}
							>
								<Row>




									<Col span={12}>
										<Form.Item
											name={[field.name, "image_desktop"]}
											className="min-h-[150px]  w-full"
											label="Ảnh desktop">
											<UploadEvent
												className="flex gap-2"
												height={120}
												defaultUrl={data?.[field.name]?.image_desktop || ""}
												onSuccess={(url) => {
													message.success("Thay đổi thành công");
													form.setFieldValue(["items", field.name, "image_desktop"], url);

												}}></UploadEvent>
										</Form.Item>

									</Col>
									<Col span={12}>
										<Form.Item
											name={[field.name, "image_mobile"]}
											className="min-h-[150px]  w-full"
											label="Ảnh mobile">
											<UploadEvent
												className="flex gap-2"
												height={120}
												defaultUrl={data?.[field.name]?.image_mobile || ""}
												onSuccess={(url) => {
													message.success("Thay đổi thành công");
													form.setFieldValue(["items", field.name, "image_mobile"], url);

												}}></UploadEvent>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											labelCol={{ span: 24 }}
											className="mb-4"

											label="Đường dẫn"
											name={[field.name, "url"]}
										>
											<Input size="middle"></Input>

										</Form.Item>
									</Col>
								</Row>

							</Card>
						))}

						<Button type="dashed" onClick={() => add()} block>
							+ Thêm nhóm
						</Button>

						<Button className="  absolute bottom-10 right-4 w-[100px]" type="primary" htmlType="submit">
							Lưu
						</Button>
					</div>
				)}
			</Form.List>
		</Form>
	);
};

export default SectionSubbannerDB;

