import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React from "react";

const RegionFormFilter = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  defaultOptions,
  placeholder,
  className,
  popupMatchSelectWidth,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  className?: string;
  popupMatchSelectWidth?: boolean;
}) => {
  return (
    <FormSelect
      className={className}
      disabled={disabled}
      required={required}
      showSearch
      label={label}
      name={name}
      options={defaultOptions || []}
      value={value}
      onChange={onChange}
      labelAlign={labelAlign}
      allowClear={allowClear}
      placeholder={placeholder}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
};

export default RegionFormFilter;
