import ProductReceipt from "common/constants/ProductReceipt";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";

import { IconFileMinus, IconFilePlus } from "@tabler/icons-react";

const ProductReceiptListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("productreceipt:heading_list")}
      siteMenuSelectedKey="/productreceipt">
      <RoleCheck roles={[Role.INVENTORY_ADD]}>
        <PageHeaderButton
          type="primary"
          link={
            "/productreceipt/add/direction/" + ProductReceipt.DIRECTION_INPUT
          }
          className="mr-2"
          icon={<IconFilePlus size={18} className="mr-0.5 -mt-0.5" />}>
          {t("productreceipt:add_button_in")}
        </PageHeaderButton>

        <PageHeaderButton
          type="primary"
          link={
            "/productreceipt/add/direction/" + ProductReceipt.DIRECTION_OUTPUT
          }
          icon={<IconFileMinus size={18} className="mr-0.5 -mt-0.5" />}>
          {t("productreceipt:add_button_out")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default ProductReceiptListHeader;
