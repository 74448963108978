const TagConstant = {
  RESOURCE_TYPE: {
    PRODUCT: 1,
    STORY: 2,
    PRODUCT_COLLECTION: 3,
    STORY_COLLECTION: 4,
    BRAND: 5,
  },
  TYPE: {
    PRODUCT_STRUCT: 2,
  },
  ROOT: {
    SYSTEM: 1,
    USER: 2,
  },
  RELATED_TYPE: {
    ONE_PARENT_TO_MORE_CHILDREN: 1,
    ONE_CHILDREN_TO_MORE_PARENT: 2,
  },
};
// 1: product, 2: story, 3: collection product, 4: collection story
export default TagConstant;
