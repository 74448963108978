import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";
import TagApi from "common/api/TagApi";
import {
  DeleteProductRelatedTagJson,
  FilterTag,
  TagAddEditJson,
  TagDetailAddJson,
  TagDisplayUpdateJson,
  TagProductStructAddEditJson,
  TagRelatedAddJson,
} from "common/types/Tag";
import TagCollection from "common/collections/TagCollection";
import TagModel from "common/models/TagModel";
import TagDetailCollection from "common/collections/TagDetailCollection";
import { FilterTagDetail, TagStructItem } from "common/types/TagDetail";

class TagRepository extends BaseRepository<TagApi> {
  _api: TagApi | null;
  constructor() {
    super();
    this._api = new TagApi(true);
  }

  static getDefaultFilters(): FilterTag {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: -1,
      type: -1,
      resource_id: -1,
      is_root: 2,
      resource_type: -1,
    };
  }

  static getDefaultTagDetailFilters(): FilterTagDetail {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: -1,
      resource_id: -1,
      resource_type: -1,
      tag_id: -1,
      is_root: -1,
    };
  }

  async getItems(props: GetListProps<FilterTag>): Promise<TagCollection> {
    return this._api ? await this._api.getList(props) : new TagCollection();
  }

  async getStructItems(
    props: GetListProps<FilterTag>
  ): Promise<TagStructItem[]> {
    return this._api ? await this._api.getStructList(props) : [];
  }

  async getItem(id: number): Promise<TagModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new TagModel(TagModel.getDefaultData());
  }

  //add - edit
  async saveRemote(data: TagAddEditJson): Promise<TagModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new TagModel(TagModel.getDefaultData());
    }
  }

  //delete
  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  //get tag detial
  async getItemsDetail(
    props: GetListProps<FilterTagDetail>
  ): Promise<TagDetailCollection> {
    return this._api
      ? await this._api.getTagDetailList(props)
      : new TagDetailCollection();
  }

  //add tag detail to product
  async addTagDetail(data: TagDetailAddJson) {
    if (this._api) {
      return await this._api.addTagDetail(data);
    } else {
      return new TagModel(TagModel.getDefaultData());
    }
  }

  //delete tag detail
  async deleteTagDetailItem(id: number[]): Promise<string[]> {
    return this._api ? await this._api.deleteTagDetailItem(id) : [];
  }

  //display tag detail
  async updateTagDetailDisplay(data: TagDisplayUpdateJson) {
    if (this._api) {
      return await this._api.updateTagDetailDisplay(data);
    } else {
      return new TagModel(TagModel.getDefaultData());
    }
  }

  //display tag detail
  async updateTagDetailDisplayProduct(data: TagDisplayUpdateJson) {
    if (this._api) {
      return await this._api.updateTagDetailDisplayProduct(data);
    } else {
      return new TagModel(TagModel.getDefaultData());
    }
  }

  async updateTagProductStruct(data: TagProductStructAddEditJson) {
    if (this._api) {
      return await this._api.updateTagProductStruct(data);
    } else {
      return new TagModel(TagModel.getDefaultData());
    }
  }

  //delete product related
  async deleteProductRelatedInTag(data: DeleteProductRelatedTagJson) {
    return this._api
      ? await this._api.deleteProductRelatedTagDetailItem(data)
      : [];
  }

  //add related Item
  async addTagRelated(data: TagRelatedAddJson) {
    if (this._api) {
      return await this._api.addTagRelated(data);
    } else {
      return new TagModel(TagModel.getDefaultData());
    }
  }
}

export default TagRepository;
