import React, { useCallback, useState } from "react";

import LayoutForm from "components/form/LayoutForm";
import { useForm } from "antd/lib/form/Form";
import { Checkbox, Col, Form, Input, Row } from "antd";

import { useTranslation } from "react-i18next";

import FormSection from "components/form/FormSection";
import BirthdayInput from "components/BirthdayInput";
import LabelDateFormatHelper from "components/LabelDateFormatHelper";

//////////////////////////////////
//Phần thay thế
import TemplateFormHeader from "./TemplateFormHeader";
import { TemplateJsonAddEdit } from "common/types/Template";
import TemplateModel from "common/models/TemplateModel";
import TemplateRepository from "common/repositories/TemplateRepository";

type Props = {
  model: TemplateModel;
};
const TemplateForm = ({ model }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;

  /////////////////////////////////
  //state
  const [isSuccess, setIsSucess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  /////////////////////////////////
  //default data
  const initialValues = {
    id: model.id,
  };

  /////////////////////////////////
  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: TemplateJsonAddEdit = {
        ...TemplateModel.getDefaultData(),
        id: model.id,
      };

      return submitData;
    },
    [model.id]
  );
  /////////////////////////////////
  //submit data to server
  const onSubmit = async (formData: TemplateJsonAddEdit) => {
    const response = await new TemplateRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      setIsSucess(true);
    } else {
      setErrors(response.error.errors);
    }
  };
  /////////////////////////////////
  //sidebar form
  const sidebarItems = (
    <>
      {/* <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.ignore_auto_upgrade !== currentValues.ignore_auto_upgrade
        }
      >
        {({ getFieldValue }) => (
          <>
            <CustomerTypeFormSelect
              name="customer_type_id"
              label={t("template:customer_type")}
              disabled={!getFieldValue("ignore_auto_upgrade")}
              className="mb-0"
            />
            <Form.Item name="ignore_auto_upgrade" valuePropName="checked">
              <Checkbox>{t("template:ignore_auto_upgrade")}</Checkbox>
            </Form.Item>
          </>
        )}
      </Form.Item> */}
      <Form.Item label={t("template:note")} name="note">
        <Input.TextArea autoSize style={{ minHeight: 60 }} />
      </Form.Item>
    </>
  );

  return (
    <div>
      <TemplateFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("template:form.success.update")
            : t("template:form.success.add")
        }
        redirectOnSuccess="/template"
        error_translate_prefix="template:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("template:customer_info_title")}
          subtitle={t("template:customer_info_description")}
          divider
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("common:full_name")}
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: t("template:form.error.error_fullname_required"),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:phone")} name="phone">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:email")} name="email">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("template:code")} name="code">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <>
                    {t("template:birthday")}
                    <LabelDateFormatHelper />
                  </>
                }
                name="birthday"
                rules={[
                  {
                    pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4}$/i),
                    message: t("common:error.error_date_format_invalid"),
                  },
                ]}
              >
                <BirthdayInput />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default TemplateForm;
