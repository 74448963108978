import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { TemplateJson } from "common/types/Template";
import BaseModel from "./BaseModel";
const defaultData: TemplateJson = {
	id: 0,
};
class TemplateModel extends BaseModel implements BaseModelJson<TemplateJson> {
	id: number;

	constructor(json: TemplateJson) {
		super();
		this.id = json.id || 0;
	}

	toJson(): TemplateJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getDefaultData(): TemplateJson {
		return { ...defaultData };
	}
}

export default TemplateModel;
