import Supplier from 'common/constants/Supplier';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { SupplierJson } from "common/types/Supplier";

class SupplierModel extends BaseModel implements BaseModelJson<SupplierJson> {
  creator_id: number;
  company_id: number;
  id: number;
  name: string;
  code: string;
  product_type: number;
  contact_fullname: string;
  contact_phone: string;
  contact_email: string;
  note: string;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: SupplierJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.code = json.code || "";
    this.product_type = json.product_type || 0;
    this.contact_fullname = json.contact_fullname || "";
    this.contact_phone = json.contact_phone || "";
    this.contact_email = json.contact_email || "";
    this.note = json.note || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): SupplierJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      name: "",
      code: "",
      product_type: Supplier.PRODUCTTYPE_GOODS,
      contact_fullname: "",
      contact_phone: "",
      contact_email: "",
      note: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): SupplierJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      name: this.name,
      code: this.code,
      product_type: this.product_type,
      contact_fullname: this.contact_fullname,
      contact_phone: this.contact_phone,
      contact_email: this.contact_email,
      note: this.note,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: Supplier.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: Supplier.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getProductTypeList(): SelectOption[] {
    return [
      {
        value: Supplier.PRODUCTTYPE_GOODS,
        label: i18n.t("supplier:PRODUCTTYPE_GOODS"),
      },
      {
        value: Supplier.PRODUCTTYPE_SERVICE,
        label: i18n.t("supplier:PRODUCTTYPE_SERVICE"),
      },
    ];
  }

  static getProductType(value: number): SelectOption | undefined {
    return this.getProductTypeList().find((item) => item.value === value);
  }
}

export default SupplierModel;
