
import TagCollection from "common/collections/TagCollection";
import TagConstant from "common/constants/Tag";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { SelectOptionNormal } from "common/types/SelectOption";
import FormSelectNormal from "components/form/FormSelectNormal";
import useStateFilter from "hooks/useStateFilter";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "rooks";

type SelectedItem = {
  id: number;
  handle: string;
};

type Props = {
  existValue: number;
  className?: string;
  onClear?: () => void;
  changeDataProductCollection: (v: TagModel) => void;
  required?: boolean;
};

function removeItemDuplicate(arr: SelectOptionNormal[]) {
  let uniqueArray = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    let foundDuplicate = false;
    for (let j = i - 1; j >= 0; j--) {
      if (arr[i].value === arr[j].value) {
        foundDuplicate = true;
        break;
      }
    }
    if (!foundDuplicate) {
      uniqueArray.unshift(arr[i]);
    }
  }
  return uniqueArray;
}

const TagSingleFormSelect = ({
  existValue,
  className = "",
  onClear,
  changeDataProductCollection,
  required = false,
}: Props) => {
  console.log("🥺 ~ existValue:", existValue);
  const [options, setOptions] = useState<SelectOptionNormal[]>([]);
  console.log("🥺 ~ options:", options);
  const [keyword, setKeyWord] = useState<string>("");
  const defaultFilters = useMemo(() => {
    return { ...TagRepository.getDefaultFilters(), limit: 50, resource_type: TagConstant.RESOURCE_TYPE.STORY };
  }, []);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<TagModel[]>([]);
  const [value, setValue] = useState<number>(existValue);
  const [selectedItem, setSelectedItem] = useState<TagModel>(
    new TagModel({
      ...TagModel.getDefaultData(),
      // id: existValue > 0 ? existValue : 0,
    })
  );
  console.log("🥺 ~ selectedItem:", selectedItem);

  const [loading, setLoading] = useState<boolean>(false);

  const fetchDataDefaultOption = async (id: number) => {
    const myObj = await new TagRepository().getItem(id);
    setLoading(false);

    if (!myObj.hasError()) {
      setSelectedItem(myObj);
      changeDataProductCollection(myObj);

      const defauilValueOption = [
        {
          label: `${myObj.name}`,
          value: myObj.code,
        },
      ];
      return defauilValueOption;
    } else {
      setSelectedItem(
        new TagModel({
          ...TagModel.getDefaultData(),
          id: id,
        })
      );
      changeDataProductCollection(
        new TagModel({
          ...TagModel.getDefaultData(),
          id: id,
        })
      );
      return [];
    }
  };

  const fetchData = async (): Promise<TagCollection> => {
    setLoading(true);
    const response = await new TagRepository().getItems({
      filters: filters,
    });
    if (!response.hasError()) {
      setTotal(response.total);
      const convertedItem = response.items.map((item) => {
        return {
          label: `${item.name}`,
          value: item.code,
        };
      });
      if (existValue > 0) {
        let dataDe = await fetchDataDefaultOption(existValue);
        if (dataDe.length > 0) {
          setOptions(removeItemDuplicate([...dataDe, ...convertedItem]));
        } else {
          setOptions(convertedItem);
        }
      } else {
        setOptions(convertedItem);
      }
      setDataSource(response.items);
    }
    setLoading(false);

    return response;
  };

  const handleSearchChange = useDebounce((value: string) => {
    setTimeout(() => {
      setFilters({ ...filters, keyword: value });
    }, 500);
  }, 500);

  const handleChangeSelect = (e: number) => {
    setValue(e);
    const findItem = dataSource.find((item) => item.id === e);
    if (findItem) {
      setSelectedItem(findItem);
      changeDataProductCollection(findItem);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  return (
    <>
      {/* <Form.Item label="Danh mục sản phẩm" initialValue={selectedItem.title}> */}
      <FormSelectNormal
        className={className}
        placeholder="Tag"
        rules={
          required
            ? [
                {
                  validator(rule, value, callback) {
                    if (selectedItem.id === 0) {
                      return Promise.reject("Some message here");
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: "Vui lòng nhập.",
                },
              ]
            : []
        }
        name="tags"
        defaultValue={value}
        showSearch
        loading={loading}
        value={value}
        onClear={onClear}
        onChange={(e) => {
          handleChangeSelect(Number(e));
        }}
        allowClear={true}
        options={options}
        onSearch={(v) => {
          handleSearchChange(v);
        }}
        filterOption={false}
      />
      {/* </Form.Item> */}
    </>
  );
};

export default TagSingleFormSelect;
