import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EmployeeEditorHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("employee:heading_nav"),
      link: "/employee",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(isEditing ? "employee:heading_edit" : "employee:heading_add")}
      siteMenuSelectedKey="/employee"
      siteMenuHidden
    ></PageHeader>
  );
};

export default EmployeeEditorHeader;
