import ShippingOrderModel from 'common/models/ShippingOrderModel';
import { ShippingOrderFromPointJson, ShippingOrderToPointJson } from 'common/types/ShippingOrder';
import ShippingOrderProvider from 'contextproviders/ShippingOrderProvider';
import React from 'react';

const ShippingOrderContext = React.createContext<ShippingOrderProvider>(
  new ShippingOrderProvider({
    changeOrderWeight: false,
    setChangeOrderWeight: (V: boolean) => {},
    changeOrderFromPoint: false,
    setChangeOrderFromPoint: (V: boolean) => {},
    changeOrderToPoint: false,
    setChangeOrderToPoint: (V: boolean) => {},
    orderWeight: 0,
    setOrderWeight: (v: number) => {},
    orderFromPoint: ShippingOrderModel.getDefaultFromData(),
    setOrderFromPoint: (v: ShippingOrderFromPointJson) => {},
    orderToPoint: ShippingOrderModel.getDefaultToData(),
    setOrderToPoint: (v: ShippingOrderToPointJson) => {},
    totalDistance: 0,
    setTotalDistance: (v: number) => {},
  })
);

export default ShippingOrderContext;
