import React, { useCallback, useEffect, useMemo, useState } from "react";

import LayoutForm from "components/form/LayoutForm";
import { useForm } from "antd/lib/form/Form";
import {
	Button,
	Checkbox,
	Col,
	Divider,
	Form,
	Input,
	List,
	Row,
	Tag,
	Typography,
	message,
} from "antd";

import { useTranslation } from "react-i18next";

import FormSection from "components/form/FormSection";

//////////////////////////////////
//Phần thay thế
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryDetailHeader from "./DeliveryDetailHeader";
import TextMoney from "components/TextMoney";
import DeliveryHistoryList from "./DeliveryHistoryList";
import ButtonAction, { SingleActionItem } from "../action/ButtonAction";
import Delivery from "common/constants/Delivery";
import PostAction from "../action/PostAction";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import usePathParams from "hooks/usePathParams";
import { ActionCode } from "common/types/Delivery";
import PostActionTransfer from "../accountant/component/PostActionTransfer";
import AccoutantConfirmActionForm from "../accountant/component/AccountantConfirmActionForm";
import usePostActionStore from "zustands/usePostActionStore";
import { IconBrandMessenger, IconMapPin } from "@tabler/icons-react";
import MoneyInput from "components/MoneyInput";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import DeliveryMineTransferForm from "./../mine/form/DeliveryMineTransferForm";
import { NoticeType } from "antd/es/message/interface";
import DeliveryAccountantTransferForm from "../accountant/component/DeliveryAccountantTransferForm";
import DeliveryAccoutantConfirmForm from "../accountant/component/DeliveryAccoutantConfirmForm";
import DeliveryPrint from "./DeliveryPrint";
import { LoadingOutlined } from "@ant-design/icons";
import Helper from "common/utils/helper";
import Error from "components/Error";

type Props = {
	model: DeliveryModel;
	redirectOnSuccess?: string;
	type?: "mine" | "default" | "manager" | "accountant";
	id?: number | string;
};
const DeliveryDetail = ({
	model: modelInit,
	redirectOnSuccess,
	type = "default",
	id,
}: Props) => {
	const [form] = useForm();
	const [messageApi, conText] = message.useMessage();
	const { t } = useTranslation();
	const { Text, Link, Paragraph } = Typography;
	const [pathParams] = usePathParams();
	const { reload, setReload } = usePostActionStore();

	const isEditing = modelInit.id > 0;

	/////////////////////////////////
	//state
	const [model, setModel] = useState(modelInit);

	const [isSuccess, setIsSucess] = useState(false);
	const [loading, setLoading] = useState(false);

	const [errors, setErrors] = useState<string[]>([]);

	/////////////////////////////////
	//default data
	const initialValues = {
		id: model.id,
	};

	/////////////////////////////////
	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: any = {
				...DeliveryModel.getDefaultData(),
				id: model.id,
			};

			return submitData;
		},
		[model.id]
	);
	/////////////////////////////////
	//submit data to server
	const onSubmit = async (formData: any) => {};

	// Function
	const handleOpenMap = ({ address }: { address: string }) => {
		if (address !== undefined && address !== "") {
			window.open(
				`https://www.google.com/maps/search/?api=1&query=${address}
        `,
				"_blank"
			);
		}
	};

	const openMessage = ({
		type,
		content,
	}: {
		type: NoticeType | undefined;
		content: React.ReactNode;
	}) => {
		messageApi.open({
			type: type,
			content: content,
			duration: 2,
		});
	};
	const fetchData = async (idFetchData?: string | number) => {
		const idFetch = idFetchData || Number(pathParams.id) || 0;
		if (Helper.checkValueValidStringNumber(idFetch)) {
			setLoading(true);

			const fetchModel = await new DeliveryRepository().getItem(idFetch);
			if (!fetchModel.hasError()) {
				setModel(fetchModel);
			} else {
				setErrors(fetchModel.error.errors);
			}
			setLoading(false);
		}
	};
	/////////////////////////////////
	// process

	const listActionAccountant: SingleActionItem[] = useMemo(() => {
		let actions: SingleActionItem[] = [];
		if (
			!["ACTION_CANCEL", "ACTION_DELIVERED", "ACTION_COMPLETE"].includes(
				model.action_code
			)
		) {
			actions.push({
				actionCode: "ACTION_APPROVE_TRANSFERS",
			});
			// if (model.cost_on_transfer > 0) {
			//   items.push({
			//     actionCode: "ACTION_TIPS",
			//   });
			// }
		}
		if (model.action_code === "ACTION_DELIVERED") {
			actions.push({
				actionCode: "ACTION_APPROVE_RECEIVE_COD",
			});
		}

		return actions;
	}, [model]);

	useEffect(() => {
		if (id && Helper.checkValueValidStringNumber(id)) {
			fetchData(id);
		}
	}, [id]);

	/////////////////////////////////
	if (loading) {
		return <LoadingOutlined></LoadingOutlined>;
	}

	if (errors.length > 0) {
		return (
			<Error contentPadding={0} items={errors} translate_prefix=""></Error>
		);
	}
	return (
		<div>
			{conText}
			<DeliveryDetailHeader isEditing={isEditing} type={type} />
			<DeliveryPrint delivery={model} />
			{/* {model && model.action_code !== "ACTION_COMPLETE" && (
        <ButtonActionAccountant
          selectedDelivery={model}
          action_code={model.action_code}
          cost_total={model.cod}
          cost_on_transfer={model.cost_on_transfer}
          ReloadOnSuccess={setReload}
        />
      )} */}
			<div className="p-3 relative lg:p-10">
				<LayoutForm
					form={form}
					initialValues={initialValues}
					errors={errors}
					isSuccess={isSuccess}
					successTitle={
						isEditing
							? t("delivery:form.success.update")
							: t("delivery:form.success.add")
					}
					redirectOnSuccess={redirectOnSuccess}
					error_translate_prefix="delivery:form.error"
					onSubmit={onSubmit}
					submitText={
						isEditing ? t("common:form.save") : t("common:form.submit")
					}
					hideSidebar={true}
					sidebarItems={<></>}>
					{/* Vận đơn  */}
					<FormSection
						title={t("delivery:details.delivery_title")}
						subtitle={t("delivery:details.delivery_des")}
						divider>
						<Row gutter={[8, 8]}>
							<Col span={24}>
								<div className="flex justify-between items-center">
									<Text>Mã vận đơn</Text>
									<Tag color="blue-inverse">{model.id}</Tag>
								</div>
							</Col>
							<Col span={24}>
								<div className="flex justify-between items-center">
									<Text>Tình trạng</Text>
									<Tag color="warning">
										{t(`delivery:postaction.${model.action_code}`)}
									</Tag>
								</div>
							</Col>
						</Row>
					</FormSection>
					{/* sản phẩm */}
					<FormSection
						title={t("delivery:details.ship_title")}
						subtitle={t("delivery:details.ship_des")}
						divider>
						<Row gutter={16}>
							<Col md={12} xs={24}>
								<List
									itemLayout="horizontal"
									size="small"
									dataSource={model.order_detail}
									renderItem={(item, index) => (
										<List.Item>
											<List.Item.Meta
												title={
													<div className="flex justify-between">
														<p>
															#{item.product_sku}-{item.product_name}
														</p>
														<Text
															style={{
																fontWeight: "bold",
															}}>
															x
															{item.product_quantity > 0
																? item.product_quantity
																: 0}
														</Text>
													</div>
												}
												description={item.product_name}
											/>
										</List.Item>
									)}
								/>
							</Col>
						</Row>
					</FormSection>
					{/* Thanh toán  */}
					<FormSection
						title={t("delivery:details.payment")}
						subtitle={t("delivery:details.payment_des")}
						divider>
						<Row gutter={[8, 8]}>
							<Col span={24}>
								<div className="flex justify-between items-center">
									<Text>Giá trị đơn hàng</Text>
									<Text>
										<TextMoney money={model.cost_total} />
									</Text>
								</div>
							</Col>
							{type !== "accountant" && (
								<Col span={24}>
									<div className="flex justify-between items-center">
										<Text>Đã thu</Text>
										<Text>
											<TextMoney money={model.cost_note_transfer} />
										</Text>
									</div>
								</Col>
							)}
							{type === "accountant" && (
								<Col span={24}>
									<div className="flex justify-between items-center">
										<Text>Chuyển khoản</Text>
										<Text>
											<TextMoney money={model.cost_on_transfer} />
										</Text>
									</div>
								</Col>
							)}
							<Divider className="my-2" dashed />
							<Col span={24}>
								<div className="flex justify-between items-center">
									<Text>Số tiền phải thu</Text>
									<Text type="danger">
										<TextMoney money={model.cod} />
									</Text>
								</div>
							</Col>
							{type === "accountant" && (
								<Col span={24}>
									<div className="flex justify-between items-center">
										<Text>Tiền tip</Text>
										<Text>
											<TextMoney money={model.cotips} />
										</Text>
									</div>
								</Col>
							)}
						</Row>
					</FormSection>
					{/* Khách hàng */}
					<FormSection
						title={t("delivery:details.customer")}
						subtitle={t("delivery:details.payment_des")}
						divider>
						<Row gutter={[8, 8]}>
							<Col span={24}>
								<div className="flex justify-between items-center">
									<Text>Họ & Tên khách hàng</Text>
									<Text>{model.contact_fullname}</Text>
								</div>
							</Col>
							<Col span={24}>
								<div className="flex justify-between items-center">
									<Text>Số điện thoại</Text>

									<Link
										copyable={{ tooltips: model.contact_phone }}
										href={`tel:${model.contact_phone}`}>
										{model.contact_phone}
									</Link>
								</div>
							</Col>
							<Col span={24}>
								<div className="flex justify-between items-start">
									<Text className="flex-1">Địa chỉ</Text>
									<div className="basis-[80%] flex justify-end gap-1">
										<Paragraph
											ellipsis={{ rows: 3, tooltip: model.contact_address }}>
											{model.contact_address}
										</Paragraph>
										<IconMapPin
											className="inline-block basis-[70px]"
											onClick={() =>
												handleOpenMap({ address: model.contact_address })
											}
											cursor="pointer"
											color="#4EBA00"
										/>
									</div>
								</div>
							</Col>
							<Col span={24}>
								<div className="flex justify-between items-center gap-2">
									<Text className="shrink-0">Ghi chú:</Text>
									<Paragraph
										className="mb-0"
										ellipsis={{ rows: 3, tooltip: model.note }}>
										{model.note.length > 0 ? model.note : <Tag>Trống</Tag>}
									</Paragraph>
								</div>
							</Col>
						</Row>
					</FormSection>
					{/* lịch sử */}
					<FormSection
						title={t("delivery:details.history")}
						subtitle={t("delivery:details.history_des")}
						divider>
						<Row gutter={[8, 8]}>
							<Col span={24}>
								<DeliveryHistoryList id={id || model.id} model={model} />
							</Col>
						</Row>
					</FormSection>
				</LayoutForm>
				{type === "mine" && (
					<div className="sticky bottom-0 left-0 right-0">
						<ButtonAction
							matrix_action={Delivery.SHIPPER_MATRIX_ACTION}
							action_code={model.action_code}
							renderModal={({
								nextAction,
								closeModal,
								gpsRequire,
								noteRequire,
								uploadRequire,
							}) => {
								return (
									<div>
										{nextAction === "ACTION_RECEIVE_COD" ? (
											<DeliveryMineTransferForm
												gpsRequire={gpsRequire}
												uploadRequire={uploadRequire}
												nextAction={nextAction}
												noteRequire={noteRequire}
												model={model}
												handleSucess={() => {
													closeModal();
													fetchData();
													openMessage({
														type: "success",
														content: "Thao tác thành công !",
													});
													setReload();
												}}
											/>
										) : (
											<PostAction
												action={nextAction}
												data={{ deliveries_id: model.id }}
												gpsRequire={gpsRequire}
												uploadRequire={uploadRequire}
												noteRequire={noteRequire}
												handlePostActionSuccess={() => {
													closeModal();
													openMessage({
														type: "success",
														content: "Thao tác thành công !",
													});
													fetchData();
													setReload();
												}}></PostAction>
										)}
									</div>
								);
							}}
							prefix_translate_button="delivery:postaction"></ButtonAction>
					</div>
				)}
				{/*Manager detail button*/}
				{type === "manager" && (
					<div className="sticky bottom-0 left-0 right-0">
						<ButtonAction
							matrix_action={Delivery.MANAGER_MAXTRIX_ACTION}
							action_code={model.action_code}
							renderModal={({
								nextAction,
								closeModal,
								gpsRequire,
								noteRequire,
								uploadRequire,
							}) => {
								return (
									<div>
										<PostAction
											key={1}
											action={nextAction}
											gpsRequire={gpsRequire}
											noteRequire={noteRequire}
											uploadRequire={uploadRequire}
											data={{
												deliveries_id: model.id,
											}}
											handlePostActionSuccess={() => {
												closeModal();
												openMessage({
													type: "success",
													content: "Thao tác thành công !",
												});
												fetchData && fetchData();
												setReload();
											}}></PostAction>
									</div>
								);
							}}
							// single_action={{
							//   action_code: "ACTION_WAITING",
							//   label: t("delivery:waiting.button.take"),
							// }}
							prefix_translate_button="delivery:postaction"></ButtonAction>
					</div>
				)}

				{/*Accoutant detail button*/}
				{type === "accountant" && model.action_code !== "ACTION_COMPLETE" && (
					<div className="sticky bottom-0 left-0 right-0">
						<ButtonAction
							matrix_action={Delivery.FULL_MATRIX_ACTION}
							action_code={model.action_code}
							renderModal={({
								nextAction,
								closeModal,
								gpsRequire,
								noteRequire,
								uploadRequire,
							}) => {
								if (nextAction === "ACTION_APPROVE_TRANSFERS") {
									return (
										<div>
											<DeliveryAccountantTransferForm
												action_code={nextAction}
												GpsRequire={gpsRequire}
												UpLoadRequire={false}
												widthImg={370}
												heightImg={300}
												delivery_id={model.id}
												cost_total={model.cod}
												handleSucess={() => {
													closeModal();
													openMessage({
														type: "success",
														content: "Thao tác thành công !",
													});
													fetchData && fetchData();
													setReload();
												}}></DeliveryAccountantTransferForm>
										</div>
									);
								}
								// else if (nextAction === "ACTION_TIPS") {
								//   return (
								//     <AccoutantConfirmActionForm
								//       action_code={"ACTION_TIPS"}
								//       noteMoney={true}
								//       noteRequire={false}
								//       GpsRequire={true}
								//       UpLoadRequire={false}
								//       widthImg={370}
								//       heightImg={370}
								//       delivery_id={model.id}
								//       showNote={false}
								//       showCod={true}
								//       showCostTransfer={true}
								//       cost_total={model.cost_total}
								//       cost_on_transfer={model.cost_on_transfer}
								//       handleSuccess={() => {
								//         closeModal();
								//         fetchData && fetchData();
								//         setReload();
								//       }}
								//     ></AccoutantConfirmActionForm>
								//   );
								// }
								else if (nextAction === "ACTION_APPROVE_RECEIVE_COD") {
									return (
										<DeliveryAccoutantConfirmForm
											action_code={"ACTION_APPROVE_RECEIVE_COD"}
											noteMoney={false}
											noteRequire={false}
											GpsRequire={true}
											UpLoadRequire={false}
											widthImg={370}
											heightImg={370}
											delivery_id={model.id}
											showNote={false}
											showCod={true}
											showCostTransfer={true}
											cost_cod={model.cod}
											cost_on_transfer={model.cost_on_transfer}
											handleSucess={() => {
												closeModal();
												openMessage({
													type: "success",
													content: "Thao tác thành công !",
												});
												fetchData && fetchData();
												setReload();
											}}></DeliveryAccoutantConfirmForm>
									);
								} else {
									return <></>;
								}
							}}
							single_action={{
								action_code: listActionAccountant,
								prefix_translate: "delivery:postaction",
							}}
							prefix_translate_button="delivery:postaction"></ButtonAction>
					</div>
				)}
			</div>
		</div>
	);
};

export default DeliveryDetail;
