import SettingRepository from "common/repositories/SettingRepository";
import { useEffect, useState } from "react";
import { convertTemplateToHtmlString } from "utils";

export default function usePrint({ key, data }: { key: string; data: any }) {
	const [tempalte, setTemplate] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const data = await new SettingRepository().getItem(key, "vi");
				setTemplate(data.value);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		})();
	}, [key]);

	async function waitForImagesToLoad(doc: Document): Promise<void> {
		const images: HTMLImageElement[] = Array.from(
			doc.images
		) as HTMLImageElement[];
		const imageLoadPromises: Promise<void>[] = images.map(
			(img: HTMLImageElement) => {
				return new Promise<void>((resolve) => {
					if (img.complete) {
						resolve();
					} else {
						img.onload = () => resolve();
						img.onerror = () => resolve(); // resolve on error to avoid blocking
					}
				});
			}
		);
		await Promise.all(imageLoadPromises);
	}
	async function printBill(html: string): Promise<void> {
		const iframe = document.createElement("iframe");
		iframe.style.display = "none";
		document.body.appendChild(iframe);
		const doc = iframe.contentDocument || iframe.contentWindow!.document;
		doc.open();
		doc.write(html);
		doc.close();
		await waitForImagesToLoad(doc);

		iframe.contentWindow!.focus();
		iframe.contentWindow!.print();
	}
	const dataPrintHTML = convertTemplateToHtmlString(tempalte, data);

	return {
		tempalte,
		isLoading,
		dataPrintHTML,
		printBill,
	};
}
