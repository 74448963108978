import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type {
  DataGridTableRowData,
  DataGridTableRowJson,
} from "common/types/DataGridTableRow";

class DataGridTableRowModel
  extends BaseModel
  implements BaseModelJson<DataGridTableRowJson>
{
  company_id: number;
  creator_id: number;
  datagrid_table_id: number;
  id: number;
  data: DataGridTableRowData;
  display_order: number;
  date_created: number;
  date_modified: number;

  constructor(json: DataGridTableRowJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.datagrid_table_id = json.datagrid_table_id || 0;
    this.id = json.id || 0;
    this.data = json.data || {};
    this.display_order = json.display_order || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): DataGridTableRowJson {
    return {
      company_id: 0,
      creator_id: 0,
      datagrid_table_id: 0,
      id: 0,
      data: {},
      display_order: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): DataGridTableRowJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      datagrid_table_id: this.datagrid_table_id,
      id: this.id,
      data: this.data,
      display_order: this.display_order,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default DataGridTableRowModel;
