import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography,
} from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useCallback, useMemo, useRef, useState } from "react";

import QRCodeHeader from "./QRCodeHeader";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";

import ErrorAlert from "components/ErrorAlert";
import ToolModel from "common/models/ToolModel";
import ToolRepository from "common/repositories/ToolRepository";

const QRCode = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  //state
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [checked, setChecked] = useState(true);
  const [size, setSize] = useState(300);
  const [qrBase64, setQrBase64] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const QRRef = useRef<HTMLDivElement>(null);

  const initialValues = useMemo(() => {
    return {
      out_size: 300,
      with_logo: 1,
    };
  }, []);

  //function
  const doPrepareData = useCallback(
    (formData: any) => {
      return {
        content: formData.content,
        out_size: formData.out_size,
        with_logo: checked ? 1 : 0,
      };
    },
    [checked]
  );

  const onSubmit = async (formData: any) => {
    setLoading(true);

    let myObj: ToolModel = await new ToolRepository().addQrCode(
      doPrepareData(formData)
    );

    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setDisabled(true);
      setQrBase64(myObj.result);
      setIsSuccess(true);
    }
    setLoading(false);
  };
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };
  const handleReset = () => {
    setDisabled(false);
    setQrBase64("");
    form.resetFields();
  };

  //
  const sidebarItems = (
    <>
      <Form.Item>
        {qrBase64.length <= 0 && (
          <Button
            className="mt-2"
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
            block
          >
            <PlusOutlined /> {t("tool:create")}
          </Button>
        )}
        {qrBase64.length > 0 && (
          <Button
            htmlType="button"
            className="mt-2"
            size="large"
            type="primary"
            ghost
            onClick={handleReset}
            block
          >
            <ReloadOutlined /> {t("tool:redo")}
          </Button>
        )}

        {qrBase64.length > 0 && (
          <ReactToPrint
            trigger={() => (
              <Button
                htmlType="button"
                type="primary"
                size="large"
                className="mt-2"
                style={{ width: "100%" }}
              >
                <PrinterOutlined />
                {t("tool:print_qr_code")}
              </Button>
            )}
            content={() => QRRef.current}
            bodyClass="print-container"
            suppressErrors={true}
            removeAfterPrint={true}
          />
        )}
      </Form.Item>
    </>
  );

  return (
    <div>
      <QRCodeHeader />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        onSubmit={onSubmit}
        sidebarItems={sidebarItems}
        isSuccess={isSuccess}
        errors={errors}
        error_translate_prefix={t("tool:error")}
        successTitle={t("tool:form.success.add")}
        hideSubmitButton={true}
      >
        <FormSection
          title={t("tool:info_qr_code")}
          subtitle={t("tool:details_qr_code")}
          anotherSubtitle={
            disabled && (
              <Typography.Text type="success">
                {t("tool:create_new")}
              </Typography.Text>
            )
          }
          divider={true}
        >
          <Row gutter={[16, 0]}>
            <Col span={24} md={12}>
              <Form.Item
                label={t("tool:content")}
                name="content"
                rules={[
                  {
                    required: true,
                    message: t("tool:rules.content_required"),
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  placeholder={t("tool:placeholder.enter_content")}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="out_size" label={t("tool:image_size")}>
                <Input
                  name="out_size"
                  type="number"
                  defaultValue={300}
                  min={50}
                  step={50}
                  disabled={disabled}
                  onChange={(e) => {
                    setSize(Number(e.target.value));
                  }}
                  addonAfter={<span>{t("tool:pixel")}</span>}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name="with_logo"
                valuePropName="checked"
                label={t("tool:add_logo")}
              >
                <Checkbox
                  disabled={disabled}
                  defaultChecked={checked}
                  onChange={onCheckboxChange}
                >
                  {t("tool:contain_logo")}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("tool:images_on_page")}>
                <Input
                  name="count"
                  type="number"
                  defaultValue={1}
                  min={1}
                  step={1}
                  onChange={(e) => {
                    setQuantity(Number(e.target.value));
                  }}
                  addonAfter={<span>{t("tool:quantity")}</span>}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
        <FormSection
          title={t("tool:qr_code")}
          subtitle={t("tool:qr_code_here")}
        >
          <Row gutter={[16, 0]} justify="center" style={{ width: "100%" }}>
            {qrBase64.length > 0 ? (
              <Col span={24}>
                <div
                  className="print-container"
                  ref={QRRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "2px",
                  }}
                >
                  {Array.from(new Array(quantity)).map((i, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: `${size}px`,
                          height: `${size}px`,
                          border: "1px solid black",
                          display: "inline-block",
                          margin: "8px",
                        }}
                      >
                        <Image
                          wrapperStyle={{ width: "100%", height: "100%" }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={qrBase64}
                        ></Image>
                      </div>
                    );
                  })}
                </div>
              </Col>
            ) : (
              <Col span={24}>
                <Alert type="info" message={t("tool:demo")} />
              </Col>
            )}
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default QRCode;
