import BaseModel from "./BaseModel";

import { LeaveStepJson } from "common/types/LeaveStep";
import { BaseModelJson } from "common/interfaces/BaseModelJson";

class LeaveStepModel extends BaseModel implements BaseModelJson<LeaveStepJson> {
  // stt?: number;
  steps: number;
  approve_name: string;
  approve: boolean;
  approve_note: string;
  approve_type: number | string | null;

  constructor(json: LeaveStepJson) {
    super();
    // this.stt = json.stt || 0;
    this.steps = json.steps || 0;
    this.approve_name = json.approve_name || "";
    this.approve = json.approve || false;
    this.approve_note = json.approve_note || "";
    this.approve_type = json.approve_type;
  }

  static getDefaultData(): LeaveStepJson {
    return {
      steps: 0,
      approve_name: "",
      approve: false,
      approve_note: "",
      approve_type: null,
    };
  }

  toJson(): LeaveStepJson {
    return {
      steps: this.steps,
      approve_name: this.approve_name,
      approve: this.approve,
      approve_note: this.approve_note,
      approve_type: this.approve_type,
    };
  }
}

export default LeaveStepModel;
