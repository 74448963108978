// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  color: #001737;
  text-align: left;
  background-color: #fff;
  overflow-y: scroll;
}

body.overflowhidden {
  overflow-y: hidden;
}

.content-fixed {
  margin-top: 60px;
}

@media (min-width: 992px) {
  .content-fixed {
    padding-top: 60px;
    margin-top: 0;
  }
}

.content-data {
  border-top: 1px solid #eee;
}

.content-form-sidebar {
  background: #f0f0f0;
  border-left: 1px solid #ddd;
}

.content {
  padding: 20px;
}

@media (min-width: 992px) {
  .content {
    padding: 25px;
  }
}
@media (min-width: 1200px) {
  .content {
    padding: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/layout/container.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,cAAc;EACd,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;IACjB,aAAa;EACf;AACF;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["body {\n  margin: 0;\n  color: #001737;\n  text-align: left;\n  background-color: #fff;\n  overflow-y: scroll;\n}\n\nbody.overflowhidden {\n  overflow-y: hidden;\n}\n\n.content-fixed {\n  margin-top: 60px;\n}\n\n@media (min-width: 992px) {\n  .content-fixed {\n    padding-top: 60px;\n    margin-top: 0;\n  }\n}\n\n.content-data {\n  border-top: 1px solid #eee;\n}\n\n.content-form-sidebar {\n  background: #f0f0f0;\n  border-left: 1px solid #ddd;\n}\n\n.content {\n  padding: 20px;\n}\n\n@media (min-width: 992px) {\n  .content {\n    padding: 25px;\n  }\n}\n@media (min-width: 1200px) {\n  .content {\n    padding: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
