import { useTranslation } from "react-i18next";
import { Col, Image, Modal, Row, Space, Tag, Typography } from "antd";

import Helper from "common/utils/helper";
import DeliveryAddress from "./DeliveryAddress";
import FileModel from "common/models/FileModel";
import { DeliveryHistoryJson } from "common/types/Delivery";

import TextDateTime from "components/TextDateTime";
import DeliveryModel from "common/models/DeliveryModel";

type Data = DeliveryHistoryJson & {
  loading?: boolean;
};

type Props = {
  open: boolean;
  setOpen: (o: boolean) => void;
  model: Data;
  employee: any;
  deliveryModel: DeliveryModel;
};

const DeliveryHistoryModal = ({
  open,
  setOpen,
  model,
  employee,
  deliveryModel,
}: Props) => {
  const { t } = useTranslation();

  //functions
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title={
        <>
          <Tag color="blue-inverse">
            {t(`delivery:action_code.${model.action_code}`)}
          </Tag>
          <Tag color="blue">
            <TextDateTime
              format="HH:mm -- DD/MM/YYYY"
              ts={model.date_created}
            />
          </Tag>
        </>
      }
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Space
        direction="vertical"
        style={{ gap: "1rem", display: "flex", width: "100%" }}
      >
        <Row gutter={[4, 8]}>
          <Col span={10}>
            <Typography.Text>
              <>{t("delivery:detail.person_name")}:</>
            </Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text>
              <Tag>{employee.text + " (" + employee.internal_id + ")"}</Tag>
            </Typography.Text>
          </Col>
        </Row>
        {model.action_code === "ACTION_ADDRESS" ? (
          <>
            <Row gutter={[4, 8]}>
              <Col span={10}>
                <Typography.Text>
                  <Typography.Text>Địa chỉ mới:</Typography.Text>
                </Typography.Text>
              </Col>
              <Col span={14}>
                {model.note !== "" ? (
                  <Typography.Text>
                    {model.note.substring(0, model.note.indexOf("|"))}
                  </Typography.Text>
                ) : (
                  <Typography.Text type="warning">
                    {t("delivery:detail.empty")}:
                  </Typography.Text>
                )}
              </Col>
            </Row>
            <Row gutter={[4, 8]}>
              <Col span={10}>
                <Typography.Text>
                  <>{t("Lý do")}:</>
                </Typography.Text>
              </Col>
              <Col span={14}>
                {model.note !== "" ? (
                  <Typography.Text>
                    {model.note.substring(model.note.indexOf("|") + 1)}
                  </Typography.Text>
                ) : (
                  <Typography.Text type="warning">
                    {t("delivery:detail.empty")}
                  </Typography.Text>
                )}
              </Col>
            </Row>
          </>
        ) : [
            "ACTION_APPROVE_TRANSFERS",
            "ACTION_TIPS",
            "ACTION_RECEIVE_COD",
            "ACTION_TRANSFER",
            "ACTION_APPROVE_RECEIVE_COD",
          ].includes(model.action_code) === true ? (
          <>
            <Row gutter={[4, 8]}>
              <Col span={10}>
                <Typography.Text>
                  <>{t("Số tiền")}:</>
                </Typography.Text>
              </Col>
              <Col span={14}>
                {model.action_code === "ACTION_APPROVE_RECEIVE_COD" ? (
                  <Typography.Text>
                    {Helper.moneyFormat(deliveryModel.cod)}
                  </Typography.Text>
                ) : model.note !== "" ? (
                  <Typography.Text>
                    {Helper.moneyFormat(Number(model.note))}
                  </Typography.Text>
                ) : (
                  <Typography.Text type="warning">
                    {t("delivery:detail.empty")}
                  </Typography.Text>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={[4, 8]}>
              <Col span={10}>
                <Typography.Text>
                  <>
                    {[
                      "ACTION_PAUSE",
                      "ACTION_REFUND",
                      "ACTION_APPROVE_REFUND",
                      "ACTION_CANCEL",
                    ].includes(model.action_code) === true
                      ? "Lý do"
                      : t("delivery:detail.note")}
                  </>
                </Typography.Text>
              </Col>
              <Col span={14}>
                {model.note !== "" ? (
                  <Typography.Text>{model.note}</Typography.Text>
                ) : (
                  <Typography.Text type="warning">
                    {t("delivery:detail.empty")}
                  </Typography.Text>
                )}
              </Col>
            </Row>
          </>
        )}
        <Row gutter={[4, 8]}>
          {[
            "ACTION_APPROVE_TRANSFER",
            "ACTION_APPROVE_REFUND",
            "ACTION_CANCEL",
            "ACTION_PAUSE",
            "ACTION_REFUND",
            "ACTION_APPROVE_RECEIVE_COD",
            "ACTION_TIPS",
            "ACTION_APPROVE_TIPS",
            "ACTION_APPROVE_TRANSFERS",
            "ACTION_NEW",
          ].includes(model.action_code) === false ? (
            <>
              {" "}
              <Col span={10}>
                <Typography.Text>
                  <>{t("delivery:detail.gps")} </>
                </Typography.Text>
              </Col>
              <Col span={14}>
                <DeliveryAddress
                  latitude={model.latitude}
                  longitude={model.longitude}
                />
              </Col>
            </>
          ) : null}
        </Row>
        <Row gutter={[4, 8]}>
          {model?.image.length > 0 ? (
            <>
              <Col span={24}>
                <Typography.Text>
                  <>{t("delivery:detail.file")}</>
                </Typography.Text>
              </Col>
              <Col span={24}>
                <div className="grid grid-cols-4 gap-2 mt-4">
                  {model.image.map((tmp) => {
                    return (
                      <Image
                        key={tmp.id}
                        className="object-cover"
                        src={FileModel.getThumbnailFromUrl(tmp.file_path)}
                        alt=""
                      />
                    );
                  })}
                </div>
              </Col>
            </>
          ) : (
            <Col span={24} className="text-center">
              <Typography.Text type="warning">
                {t("delivery:detail.file_no_require")}
              </Typography.Text>
            </Col>
          )}
        </Row>
      </Space>
    </Modal>
  );
};

export default DeliveryHistoryModal;
