import { Button, Divider, Skeleton, Typography, message } from "antd";
import SettingApi from "common/constants/Setting";

import FileModel from "common/models/FileModel";
import ListDeleteButton from "components/list/ListDeleteButton";
import PageSortList from "components/list/PageSortList";
import ShopPopupHeaderList from "features/shoppopup/list/ShopPopupHeaderList";
import useHandleSetting from "hooks/useHandleSetting";
import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import ShopFooterForm from "../form/ShopFooterForm";
import ShopFooterHeaderList from "./ShopFooterHeaderList";
import { ShopFooterJson } from "common/types/ShopFooter";
import ShopFooterFilter from "./ShopFooterFilter";

const ShopFooterList = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [lang, setLang] = useState<string>("vi");

  const defaultDataPopup: ShopFooterJson = {
    title: "",
    menu: [],
  };

  const [data, setData] = useState<{
    data: ShopFooterJson;
    index: number;
    type: "add" | "edit";
  }>({
    data: defaultDataPopup,
    index: -1,
    type: "add",
  });

  const {
    dataSource,
    loading,
    addItemSetting,
    deleteItemSetting,
    editItemSetting,
    handleDrag,
  } = useHandleSetting<ShopFooterJson>(SettingApi.KEY.k_footer_menu, lang);

  const handleDragEnd = async (result: DropResult) => {
    try {
      if (!result.destination) return;
      await handleDrag(result.source.index, result.destination.index);
      message.success({
        content: "Thao tác thành công",
        duration: 1,
      });
    } catch (error) {
      message.error({
        content: "Thao tác thất bại",
        duration: 1,
      });
    }
  };

  function handlelOpenModalAdd(data: ShopFooterJson) {
    setData({
      data: data,
      type: "add",
      index: -1,
    });
    setOpen(true);
  }
  function handlelOpenModalEdit(data: ShopFooterJson, index: number) {
    setData({
      data: data,
      type: "edit",
      index,
    });
    setOpen(true);
  }

  async function handleSumit(dataRes: ShopFooterJson) {
    try {
      if (data.type === "add") {
        await addItemSetting(dataRes);
      } else {
        await editItemSetting(dataRes, data.index);
      }
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }

  async function handleDelete(data: ShopFooterJson, index: number) {
    try {
      await deleteItemSetting(data, index);
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }

  const changeLang = (newLang: string) => {
    setLang(newLang);
  };
  return (
    <>
      <ShopFooterHeaderList>
        {/* <Button
          type="primary"
          onClick={() =>
            handlelOpenModalAdd(defaultDataPopup as ShopFooterJson)
          }
        >
          Thêm
        </Button> */}
      </ShopFooterHeaderList>
      <ShopFooterFilter defaultLang={lang} changeLang={changeLang} />
      <Divider />
      {!loading ? (
        <>
          <PageSortList
            dataSource={dataSource}
            handleDragEnd={handleDragEnd}
            listKey="shopfooter"
            renderListItem={(item: ShopFooterJson, index) => {
              return (
                <>
                  <div className="p-2">
                    <div className="flex  items-center justify-between">
                      <Typography.Text className="font-bold">
                        {item.title}
                      </Typography.Text>
                      <div className=" flex gap-2">
                        <Button
                          onClick={() => {
                            handlelOpenModalEdit(item, index);
                          }}
                        >
                          Sửa
                        </Button>

                        <ListDeleteButton
                          handleDelete={() => {
                            handleDelete(item, index);
                          }}
                          id={index}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        </>
      ) : (
        <Skeleton active />
      )}
      <ShopFooterForm
        type={data.type}
        data={data.data}
        open={open}
        onCancel={() => setOpen(false)}
        onFinish={async (data) => {
          await handleSumit(data);
        }}
      />
    </>
  );
};

export default ShopFooterList;
