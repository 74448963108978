import { Breadcrumb, Space } from "antd";
import React from "react";
import { FilterTask } from "common/types/Task";
import { IconHomeMove } from "@tabler/icons-react";
import usePathParams from "hooks/usePathParams";
import { useNavigate } from "react-router-dom";

type Props = {
  filters: FilterTask;
  dataBreadCrumb: any[];
  setFilters: (val: FilterTask) => void;
  setDataBreadCrumb: React.Dispatch<React.SetStateAction<any[]>>;
  setVisible: (t: number) => void;
  type?: "public" | "user";
  noStyleWrapper?: boolean;
};

const BreadCrumbTask = ({
  filters,
  dataBreadCrumb,
  setFilters,
  setDataBreadCrumb,
  setVisible,
  type = "public",
  noStyleWrapper = false,
}: Props) => {
  const [pathParams] = usePathParams();
  const navigate = useNavigate();

  return (
    <Space
      direction="vertical"
      style={!noStyleWrapper ? { padding: "0.5rem 1.5rem" } : undefined}
    >
      <Breadcrumb separator={"/"}>
        {dataBreadCrumb?.length > 0 &&
          dataBreadCrumb.map((i, index) => (
            <Breadcrumb.Item
              key={index}
              className="cursor-pointer hover:text-blue-500 transition-all"
              onClick={() => {
                setVisible(i.id);
                setDataBreadCrumb((prev) => {
                  return prev.slice(0, prev.indexOf(i) + 1);
                });
                if (pathParams.parent_id === i.id) {
                  return;
                } else {
                  // setFilters({ ...filters, parent_id: i.id });
                  navigate(`/task/details?parent_id=${i.id}`);
                }
              }}
            >
              {i === 0 ? (
                <IconHomeMove
                  size={19}
                  className="mb-[6px]"
                  onClick={() => {
                    // setFilters({
                    //   ...filters,
                    //   parent_id: 0,
                    // });
                    navigate(`/task`);
                  }}
                />
              ) : (
                `${i.name}`
              )}
            </Breadcrumb.Item>
          ))}
      </Breadcrumb>
    </Space>
  );
};

export default BreadCrumbTask;
