import { Col, Form, Input, Row, message } from "antd";
import ContractTypeModel from "common/models/ContractTypeModel";
import ContractTypeRepository from "common/repositories/ContractTypeRepositories";
import Error from "components/LayoutError";
import FormSelect from "components/form/FormSelect";
import React, { useCallback,  useMemo } from "react";
import { useTranslation } from "react-i18next";

const TextArea = Input.TextArea;
const ContractTypesForm = ({
  model,
  setVisible,
  setProcessing,
  onSaveSuccess,
}: {
  model: ContractTypeModel;
  setVisible: (v: boolean) => void;
  setProcessing: (p: boolean) => void;
  onSaveSuccess: (m: ContractTypeModel) => void;
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return {
      id: model.id,
      name: model.name,
      note: model.note,
      work_status: model.work_status,
      // status: model.status,
    };
  }, [model]);

  //prepare data to submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: any = {
        // ...ContractTypeModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        note: formData.note,
        work_status: formData.work_status,
        // status: formData.status,
      };
      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("loading..."),
      key: "message",
      duration: 0,
    });
    let item: ContractTypeModel = await new ContractTypeRepository().saveRemote(
      doPrepareData(formData)
    );
    setProcessing(false);

    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="Có lỗi rồi"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setVisible(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
      id="contracttypes-form"
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label={t("contract:name_contract")}
            name="name"
            rules={[
              {
                required: true,
                message: t("contract:form.error.required_name"),

              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <FormSelect
            showSearch
            required
            label={t("contract:type_contract")}
            placeholder={t("contract:select_type_contract")}
            name="work_status"
            options={ContractTypeModel.getWorkStatus()}
            rules={[
              {
                required: true,
                message: t("contract:form.error.required_type"),
              },
            ]}
          ></FormSelect>
        </Col>
        {/* {model.id > 0 && (
          <Col span={24}>
            <FormSelect
              showSearch
              required
              label={t("Trạng thái")}
              placeholder={t("Chọn trạng thái")}
              name="status"
              options={ContractTypeModel.getStatus()}
              rules={[
                {
                  required: true,
                  message: t("Vui lòng chọn trạng thái"),
                },
              ]}
            ></FormSelect>
          </Col>
        )} */}
        <Col span={24}>
          <Form.Item
            label={t("contract:note_contract")}
            name="note"
            rules={[
              {
                required: true,
                message: t("contract:form.error.required_note"),

              },
            ]}
          >
            <TextArea rows={5} cols={20} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractTypesForm;
