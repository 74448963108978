import ShippingOrderApi from "common/api/ShippingOrderApi";
import ShippingOrderCollection from "common/collections/ShippingOrderCollection";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  ShippingOrderJsonEdit,
  FilterShippingOrder,
} from 'common/types/ShippingOrder';

class ShippingOrderRepository extends BaseRepository<ShippingOrderApi> {
  _api: ShippingOrderApi | null;

  constructor() {
    super();
    this._api = new ShippingOrderApi(true);
  }

  static getDefaultFilters(): FilterShippingOrder {
    return {
      page: 1,
      limit: 20,
      sortby: 'id',
      sorttype: 'DESC',
      status: -1,
      keyword: '',
      creator_id: -1,
      from_warehouse_id: -1,
      to_warehouse_id: -1,
      merchant_id: -1,
      office_id: -1,
      office_id_to: -1,
      service_type_id: -1,
      foreign_id: -1,
      ensurance_type: -1,
      shipping_type: -1,
      type: -1,
      product_type: -1,
      order_shipping_region: -1,
      order_shipping_subregion: -1,
      order_shipping_thirdregion: -1,
      route_id: -1,
      source: -1,
      delivery_status: -1,
      pickup_status: -1,
      return_status: -1,
      cod_status: -1,
      fail_reason: -1,
      ymd: -1,
      ymdstart: -1,
      ymdend: -1,
      date_started: -1,
      date_ended: -1,
      id: -1,
      hourstart: -1,
      hourend: -1,
      random_code: '',
      route_ids: '',
      statuses: '',
      shipper_id: -1,
      status_asigned_route: -1,
    };
  }

  async getItems(props: GetListProps<FilterShippingOrder>) {
    return this._api
      ? await this._api.getList(props)
      : new ShippingOrderCollection();
  }

  async getItem(id: number): Promise<ShippingOrderModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShippingOrderModel(ShippingOrderModel.getDefaultData());
  }

  async saveRemote(data: ShippingOrderJsonEdit): Promise<ShippingOrderModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShippingOrderModel(ShippingOrderModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default ShippingOrderRepository;
