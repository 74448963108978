import { Form, InputNumber } from "antd";

const PosRightPaymentMethodInputMoney = ({
  myPayValue,
  setMyPayValue,
  payValue,
}: {
  myPayValue: number;
  setMyPayValue: (v: number) => void;
  payValue: number;
}) => {
  return (
    <Form.Item label="Tổng tiền muốn thanh toán">
      <InputNumber<number>
        className="w-full"
        value={myPayValue}
        max={payValue}
        min={0}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) =>
          typeof value !== "undefined"
            ? Number(value.replace(/\$\s?|(,*)/g, ""))
            : 0
        }
        stringMode={false}
        onChange={(value) => {
          setMyPayValue(value ?? 0);
        }}
        precision={0}
        decimalSeparator=""
      />
    </Form.Item>
  );
};

export default PosRightPaymentMethodInputMoney;
