import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StoreEditorHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();
  const nav: Nav = [
    {
      text: t("setting:menu.store"),
      link: "/store",
    },
  ];
  return (
    <PageHeader
      heading={t(isEditing ? "store:heading_edit" : "store:heading_add")}
      siteMenuSelectedKey="/store"
      siteMenuOpenKey="setting_sale"
      siteMenuHidden
      nav={nav}
    ></PageHeader>
  );
};

export default StoreEditorHeader;
