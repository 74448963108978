import React, { useRef } from "react";

const TaskNote = ({ note }: { note: string }) => {
  const NodeRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: note,
      }}
      id="note_detail"
      className="ql-editor font-semibold"
    ></div>
  );
};

export default TaskNote;
