import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";

const ProductInventoryCheckingListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("productinventorychecking:heading_list")}
      siteMenuSelectedKey="/productinventorychecking">
      {/* <RoleCheck roles={[Role.INVENTORY_STOCK_CHECK]}>
        <PageHeaderButton
          type="primary"
          ghost
          link={"/productinventorychecking/merge"}
          className="mr-2">
          {t("productinventorychecking:merge_button")}
        </PageHeaderButton>

        <PageHeaderButton link={"/productinventorychecking/add"}>
          {t("productinventorychecking:add_button")}
        </PageHeaderButton>
      </RoleCheck> */}
    </PageHeader>
  );
};

export default ProductInventoryCheckingListHeader;
