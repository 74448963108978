import ShippingOrderList from "features/shippingorder/list/ShippingOrderList";
import usePathParams from "hooks/usePathParams";
import React from "react";

const ShippingOrderPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "add":
      break;
    case "edit":
      break;
    default:
      com = <ShippingOrderList />;
  }

  return com;
};

export default ShippingOrderPage;
