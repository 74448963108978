import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

import ShopSettingList from "features/shopsetting/list/ShopSettingList";
import SettingApi from "common/constants/Setting";
import { SettingGroupType } from "common/types/Setting";

const AllSettingPage = () => {
	const [pathParams] = usePathParams();

	const [errors, setErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setErrors([]);
	}, [pathParams.action]);

	let com = <></>;

	switch (pathParams.action) {
		default:
			com = <ShopSettingList type={SettingApi.GROUP.ALL as SettingGroupType} />;
			break;
	}
	return com;
};

export default AllSettingPage;
