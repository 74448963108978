import BaseCollection from 'common/collections/BaseCollection';
import ShippingCarrierModel from 'common/models/ShippingCarrierModel';
import { ShippingCarrierCollectionJson, ShippingCarrierJson } from 'common/types/ShippingCarrier';

class ShippingCarrierCollection extends BaseCollection<
  ShippingCarrierJson,
  ShippingCarrierModel,
  ShippingCarrierCollectionJson
> {
  itemsFromJson(jsonItems: ShippingCarrierJson[]): ShippingCarrierModel[] {
    return jsonItems.map((item) => new ShippingCarrierModel(item));
  }
}

export default ShippingCarrierCollection;
