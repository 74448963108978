import { useTranslation } from "react-i18next";

import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";

import { Form, Input } from "antd";
import TagModel from "common/models/TagModel";
import { Filter } from "common/types/Filter";
import TagTypeModel from "common/models/TagTypeModel";

const TagInsideListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
  record,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  record: TagTypeModel;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        extra={false}
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["status", "type", "is_root"]}
      >
        <FormSelect
          name="status"
          placeholder={"Trạng thái"}
          options={TagModel.getStatusSelectList()}
          allowClear
          popupMatchSelectWidth={false}
        />

        {record.id !== 2 && (
          <FormSelect
            name="is_root"
            placeholder={"Phân loại"}
            options={TagModel.getIsRoot()}
            allowClear
            popupMatchSelectWidth={false}
          />
        )}

        <Form.Item name="keyword">
          <Input allowClear placeholder={t("Nhập tên")} />
        </Form.Item>
      </PageDataFilterForm>
    </>
  );
};

export default TagInsideListFilter;
