import { useState } from "react";
import { useLocation } from "react-router-dom";

const getQueryStringVal = (search: string, key: string): string | null => {
  return new URLSearchParams(search).get(key);
};

const useQueryParam = (key: string, defaultVal: string): [string] => {
  const locationSearch = useLocation().search;
  const [query] = useState(
    getQueryStringVal(locationSearch, key) || defaultVal
  );

  return [query];
};

export default useQueryParam;
