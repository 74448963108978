import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import { CustomerTypeJson } from "common/types/CustomerType";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback } from "react";

import ReportChartCategory from "../chart/ReportChartCategory";
import ReportChartDataTable from "../chart/ReportChartDataTable";

const ReportCustomerType = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const [customerTypeItemsWithLimit] =
    useDatabaseTable<CustomerTypeJson>("customertype");
  const formatterCustomerTypeValue = useCallback(
    (value: number) => {
      return customerTypeItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [customerTypeItemsWithLimit]
  );

  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Số khách hàng theo nhóm"}
              dataTable="customer"
              dataService="customer"
              dataSelect="count(id), type"
              dataFilter=""
              dataGroupBy="type"
              dataOrderBy="count(id) desc"
              keyMapping={{
                name: "type",
                value: "count_id",
                formatter: (value: number) => {
                  return formatterCustomerTypeValue(value) || "--";
                }
              }}
              height={400}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo khách hàng theo nhóm khách hàng"}
              dataTable="customer"
              dataService="customer"
              keyMapping={[
                {
                  title: "Nhóm khách hàng",
                  name: "type",
                  formatter: (value: number | string) => {
                    return <>{formatterCustomerTypeValue(+value)}</>;
                  }
                },
                {
                  title: "Số khách hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                },
                {
                  title: "Tổng số đơn hàng",
                  name: "sum_countorder",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(countorder)"
                },
                {
                  title: "Tổng giá trị đơn hàng",
                  name: "sum_money",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(money)"
                }
              ]}
              dataSelect="count(id), type, sum(countorder), sum(money)"
              dataFilter=""
              dataGroupBy="type"
              dataOrderBy="count(id) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCustomerType;
