const Role = {
  COMPANY_SETTING: 1,

  //SALE
  SETTING_SALE: 100,
  PRODUCT_MANAGE: 110,
  PRODUCT_VIEW_COST: 111,
  PROMOTION_VIEW: 112,
  PROMOTION_MANAGE: 113,
  ORDER_VIEW: 120,
  ORDER_ADD: 121,
  ORDER_EDIT: 122,
  ORDER_STATUS_COMPLETE: 123,
  ORDER_STATUS_CANCEL: 124,
  ORDER_MANAGE_SHIPPING: 125,

  SELF_ORDER_VIEW :1020,
  SELF_ORDER_ADD :1021,
  SELF_ORDER_EDIT :1022,
  SELF_ORDER_DELETE :1023,

  CROSS_ORDER_VIEW :1120,
  CROSS_ORDER_ADD :1121,
  CROSS_ORDER_EDIT :1122,
  CROSS_ORDER_DELETE :1123,

  CUSTOMER_VIEW: 130,
  CUSTOMER_MANAGE: 131,
  CUSTOMER_POINT_VIEW: 132,
  CUSTOMER_POINT_MANAGE: 133,
  CUSTOMER_POINT_MANAGE_RULE: 134,
  CASHFLOW_VIEW: 140,
  CASHFLOW_ADD: 141,
  CASHFLOW_EDIT: 142,
  CASHFLOW_STATUS_APPROVE: 143,
  CASHFLOW_STATUS_COMPLETE: 144,
  CASHFLOW_STATUS_CANCEL: 145,

  INVENTORY_VIEW: 150,
  INVENTORY_ADD: 151,
  INVENTORY_NUMBER: 152,
  INVENTORY_STATUS_APPROVE: 153,
  INVENTORY_STATUS_CHECK: 154,
  INVENTORY_STATUS_COMPLETE: 155,
  INVENTORY_STATUS_CANCEL: 156,
  INVENTORY_TRANSFER_REQUEST: 157,
  INVENTORY_TRANSFER_APPROVE: 158,
  INVENTORY_STOCK_CHECK: 159,
  PURCHASE_ORDER_VIEW: 170,
  PURCHASE_ORDER_ADD: 171,
  PURCHASE_ORDER_EDIT: 172,
  PURCHASE_ORDER_STATUS_COMPLETE: 173,
  PURCHASE_ORDER_STATUS_CANCEL: 174,
  TAX_INVOICE_VIEW: 180,
  TAX_INVOICE_EDIT_INFO: 181,
  TAX_INVOICE_ISSUE: 182,
  TAX_INVOICE_SIGN: 183,

  //2XX
  SETTING_HRM: 200,
  EMPLOYEE_MANAGE: 210,
  EMPLOYEE_MANAGETAG: 211,

  CHECKIN_SCHEDULING: 220,
  CHECKIN_EDIT: 221,
  CHECKIN_VERIFY: 222,
  PROJECT_MANAGE: 230,
  FILE_MANAGE: 240,
  FILE_UPLOAD: 241,

  PUBLICATION_MANAGER: 250,
  COMMENT_MANAGER: 251,
  STORY_MANAGER: 252,
  COLLECTION_MANAGER: 253,
  SOCIALLIVE_MANAGER: 254,
  AILIBRARY_MANAGER: 255,

  LEAVE_MANAGER: 260,

  //3XX
  SETTING_DMS: 300,
  SHIPPER_VIEW: 310,
  SHIPPER_MANAGE: 311,
  SHIPPER_MANAGE_JOB: 312,
  ECOM_SHIPPER_APPLY_MANAGE: 313,
  DELIVERY_SHIPPER: 314,
  DELIVERY_MANAGER: 315,
  DELIVERY_ACCOUNTANTS: 316,
  ORDER_MANAGER_DELIVERY: 317,
  SHIPPING_ORDER_MANAGE: 320,
  SHIPPING_ORDER_VIEW: 321,
  SHIPPING_ROUTE_MANAGE: 330,
  SHIPPING_ROUTE_VIEW: 331,
  MERCHANT_MANAGE: 340,
  DASHBOARD_VIEW: 350,

  // ECOMNEWS_VIEW: 360,
  // ECOMNEWS_MANAGE: 361,
  // ECOMPOST_VIEW: 362,
  // ECOMPOST_MANAGE: 363,
  // ECOMBANNER_VIEW: 364,
  // ECOMBANNER_MANAGE: 365,

  //4xx
  SETTING_ADVANCED: 400,

  //5xx
  SETTING_CMS: 500,
  CMS_NEWS_VIEW: 510,
  CMS_NEWS_ADD: 511,
  CMS_NEWS_EDIT: 512,
  CMS_POST_VIEW: 520,
  CMS_POST_ADD: 521,
  CMS_POST_EDIT: 522,

  //9XX
  REPORT_SALE: 910,
  REPORT_CASHFLOW: 911,
  REPORT_CUSTOMER: 912,
  REPORT_INVENTORY: 913,
  REPORT_DELIVERY: 914,
  REPORT_HRM: 915,




  
};

export default Role;
