import BaseCollection from 'common/collections/BaseCollection';
import ProjectModel from 'common/models/ProjectModel';
import { ProjectCollectionJson, ProjectJson } from 'common/types/Project';

class ProjectCollection extends BaseCollection<
  ProjectJson,
  ProjectModel,
  ProjectCollectionJson
> {
  itemsFromJson(jsonItems: ProjectJson[]): ProjectModel[] {
    return jsonItems.map((item) => new ProjectModel(item));
  }
}

export default ProjectCollection;
