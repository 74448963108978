import { SelectProps } from "antd";
import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/lib/select";
import StoreRepository from "common/repositories/StoreRepository";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";

const TagTypeFormSelectFilter = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  defaultOptions,
  placeholder,
  popupMatchSelectWidth,
  size,
  status,
  className,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  popupMatchSelectWidth?: boolean;
  size?: SelectProps["size"];
  status?: SelectProps["status"];
  className?: string;
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new TagTypeRepository().getItems({
      filters: { ...TagTypeRepository.getDefaultFilters(), limit: 50 },
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (defaultOptions && defaultOptions.length > 0) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (loading && typeof defaultOptions === "undefined") {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [loading, fetchData, defaultOptions]);

  return (
    <FormSelect
      className={className}
      size={size}
      disabled={disabled}
      required={required}
      loading={loading}
      showSearch={options.length > 6}
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
      value={value}
      onChange={onChange}
      labelAlign={labelAlign}
      allowClear={allowClear}
      placeholder={placeholder}
      popupMatchSelectWidth={popupMatchSelectWidth}
      status={status}
    />
  );
};

export default TagTypeFormSelectFilter;
