import CashflowReceipt from "common/constants/CashflowReceipt";
import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const CashflowReceiptFormHeader = ({
	isEditing,
	direction,
	onlyView,
	id,
}: {
	isEditing: boolean;
	direction: number;
	onlyView?: boolean;
	id: number;
}) => {
	const { t } = useTranslation();

	const nav: Nav = [
		{
			text: t("cashflowreceipt:heading_nav"),
			link: "/cashflowreceipt",
		},
	];

	return (
		<>
			{direction === CashflowReceipt.DIRECTION_INCOME ? (
				<PageHeader
					nav={nav}
					heading={
						t(
							onlyView
								? "cashflowreceipt:heading_view_income"
								: isEditing
								? "cashflowreceipt:heading_edit_income"
								: "cashflowreceipt:heading_add_income"
						) + (id > 0 ? " #" + id : "")
					}
					siteMenuSelectedKey="/cashflowreceipt"
					siteMenuHiddenBackLink="/cashflowreceipt"
					siteMenuHidden></PageHeader>
			) : direction === CashflowReceipt.DIRECTION_EXPENSE ? (
				<PageHeader
					nav={nav}
					heading={
						t(
							onlyView
								? "cashflowreceipt:heading_view_expense"
								: isEditing
								? "cashflowreceipt:heading_edit_expense"
								: "cashflowreceipt:heading_add_expense"
						) + (id > 0 ? " #" + id : "")
					}
					siteMenuSelectedKey="/cashflowreceipt"
					siteMenuHiddenBackLink="/cashflowreceipt"
					siteMenuHidden></PageHeader>
			) : null}
		</>
	);
};

export default CashflowReceiptFormHeader;
