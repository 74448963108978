import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";

import PageHeader from "components/page/PageHeader";

const QRCodeHeader = () => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:tool"),
      link: "/tool",
    },
    {
      text: t("tool:heading_list_qr_code"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t("tool:heading_list_qr_code")}
      nav={nav}
      siteMenuOpenKey="tool"
      siteMenuSelectedKey="/tool/qrcode"
    ></PageHeader>
  );
};

export default QRCodeHeader;
