import { Table } from "antd";
import Promotion from "common/constants/Promotion";
import InventoryStockRepository from "common/repositories/InventoryStockRepository";
import {
  PosCartDetailQuantitySignature,
  PosCartOrderDetail,
  PosCartOutOfStock,
  PosCartProductPriceChange,
  PosCartPromotionProductDetail,
} from "common/types/PosCart";
import { TableColumnsType } from "common/types/Table";
import update from "immutability-helper";
import { find, findIndex, isUndefined } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import outOfStockFx from "sounds/pos/error.mp3";
import useDeepCompareEffect from "use-deep-compare-effect";
import useSound from "use-sound";
import usePosCartStore from "zustands/usePosCartStore";
import usePosStore from "zustands/usePosStore";

import PosCartItemDelete from "./PosCartItemDelete";
import PosCartItemDetail from "./PosCartItemDetail";
import PosCartItemDetailReturn from "./PosCartItemDetailReturn";
import PosCartItemQuantityInput from "./PosCartItemQuantityInput";
import PosCartItemSubtotal from "./PosCartItemSubtotal";
import PosCartModalProductDiscount from "./PosCartModalProductDiscount";

const PosCart = () => {
  const enableSoundFx = usePosStore((state) => state.enableSoundFx);
  const [soundFxOutOfStock] = useSound(outOfStockFx);

  const activeCart = usePosCartStore((state) => state.getActiveCart());
  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);
  const activeWarehouseId = useMemo(
    () => activeCart?.warehouse_id || 0,
    [activeCart]
  );
  const details = useMemo(() => activeCart?.details || [], [activeCart]);

  const [editingDetailIndex, setEditingDetailIndex] = useState(-1);
  const [quantitySignature, setQuantitySignature] = useState<
    PosCartDetailQuantitySignature[]
  >([]);
  const [outOfStock, setOutOfStock] = useState<PosCartOutOfStock[]>([]);

  const doChangeQuantity = useCallback(
    (item: PosCartOrderDetail, newquantity: number) => {
      //find item to change quantity
      const foundIndex = details.findIndex(
        (it) => it.row_identifier === item.row_identifier
      );
      if (foundIndex >= 0) {
        //Show modal to change price or some thing
        updateActiveCart({
          details: update(details, {
            [foundIndex]: {
              item_quantity: {
                $set: newquantity,
              },
            },
          }),
        });
      }
    },
    [details, updateActiveCart]
  );

  const onClickItemPrice = useCallback(
    (item: PosCartOrderDetail) => {
      //find item to remove
      const foundIndex = details.findIndex(
        (it) => it.row_identifier === item.row_identifier
      );
      if (foundIndex >= 0) {
        setEditingDetailIndex(foundIndex);
      }
    },
    [details]
  );

  //Update price and discount from manual discount
  const onChangeProductDiscount = useCallback(
    ({
      price_discount,
      discount_type,
      discount_value,
    }: PosCartProductPriceChange) => {
      console.log(
        "Update discount for index",
        editingDetailIndex,
        details[editingDetailIndex].sku,
        price_discount
      );

      updateActiveCart({
        details: update(details, {
          [editingDetailIndex]: {
            price: {
              $set:
                details[editingDetailIndex].price_before_promotion -
                (discount_type > 0 ? price_discount : 0),
            },
            manual_discount: { $set: discount_type > 0 },
            manual_discount_type: { $set: discount_type },
            manual_discount_value: {
              $set: discount_type > 0 ? discount_value : 0,
            },
            promotions: {
              $set: [],
            },
          },
        }),
      });
    },
    [editingDetailIndex, details, updateActiveCart]
  );

  //process remove row from cart
  const onDeleteItem = useCallback(
    (deleteItem: PosCartOrderDetail) => {
      //find item to remove
      const foundIndex = details.findIndex(
        (item) => item.row_identifier === deleteItem.row_identifier
      );
      if (foundIndex >= 0) {
        updateActiveCart({
          //remove detail line
          details: update(details, {
            $splice: [[foundIndex, 1]],
          }),
        });
      }
    },
    [details, updateActiveCart]
  );

  //update PROMOTION FOR DETAIL ITEM
  useDeepCompareEffect(() => {
    if (typeof activeCart === "undefined") {
      return;
    }

    let newDetails: PosCartOrderDetail[] = [];

    details.forEach((detail) => {
      //Find ALL product promotions contains current detail's variant_id
      let detailPromotions: PosCartPromotionProductDetail[] = [];

      //apply promotion detail will apply to current item
      //this list will be ignore if manual_discount of current item is TURN ON
      let applyPromotions = [];

      ////////////////////////////////////////////////////////////
      //loop through all products promotions in cart (all variants)
      if (detail.item_quantity > 0) {
        activeCart.product_promotions.forEach((productpromotion) => {
          if (typeof productpromotion.products !== "undefined") {
            //find to check whether current detail variant_id appears in promotionproductdetal list
            const foundPromotionProductDetailIndex = findIndex(
              productpromotion.products,
              (p) => {
                if (p.product_variant_id > 0) {
                  return p.product_variant_id === detail.product_variant_id;
                } else {
                  return p.product_id === detail.product_id;
                }
              }
            );

            //if variant_id appear in promotion detail list,
            //pick it and save for current detail promotions
            if (foundPromotionProductDetailIndex >= 0) {
              detailPromotions.push({
                ...productpromotion.products[foundPromotionProductDetailIndex],
                promotion_id: productpromotion.id,
                promotion_code: productpromotion.code,
                promotion_name: productpromotion.name,
                promotion_group: productpromotion.group,
                promotion_type: productpromotion.type,
                promotion_min_check: productpromotion.min_check,
              });
            }
          }
        });
      }

      //init new detail data with all promotions and old data (price, quantity...)
      //The price can be changed if found apply promotions
      let newDetail: PosCartOrderDetail = {
        ...detail,
        promotion_options: detailPromotions,

        //very important, clear saved promotion before
        //The applied promotion will set with bellow logic
        promotions: [],
      };

      ////////////////////////////////////////////////////////////
      //apply at least one promotion to detail when found promotions and not manual discount
      if (detailPromotions.length > 0 && !detail.manual_discount) {
        //check if current detail had been apply promotionid before ^^!
        const foundApplyPromotionIndex = findIndex(detail.promotions, {
          product_variant_id: detail.product_variant_id,
        });

        //if found, keep existed applied promotion
        if (foundApplyPromotionIndex >= 0) {
          //ignore
          applyPromotions = [...detail.promotions];
        } else {
          //if not apply before, just get the first
          applyPromotions = [detailPromotions[0]];
        }

        //calculate the discount price
        let priceDiscount = 0;
        switch (applyPromotions[0].value_type) {
          case Promotion.VALUETYPE_PERCENT:
            priceDiscount = Math.floor(
              (applyPromotions[0].value / 100) * detail.price_before_promotion
            );
            break;
          case Promotion.VALUETYPE_CURRENCY:
            priceDiscount = Math.floor(applyPromotions[0].value);
            break;
          case Promotion.VALUETYPE_FIXPRICE:
            priceDiscount =
              detail.price_before_promotion - applyPromotions[0].value;
            break;
          default:
        }

        //re-calculate the price
        newDetail = update(newDetail, {
          promotions: { $set: applyPromotions },
          price: { $set: detail.price_before_promotion - priceDiscount },
        });
      }

      //register to full details list
      newDetails.push(newDetail);
    });

    //NOW, WE HAVE ALL ITEMS WITH UPDATE PROMOTIONS INFO
    updateActiveCart({
      details: newDetails,
    });
  }, [activeCart, details, updateActiveCart]);

  //change quantity signature
  //to prepare for update inventory check
  useEffect(() => {
    setQuantitySignature(
      details
        .filter((item) => item.product_sell_on_zero === 0)
        .map((item) => {
          return {
            warehouse_id: activeWarehouseId,
            row_identifier: item.row_identifier,
            product_variant_id: item.product_variant_id,
            item_quantity: item.item_quantity,
          };
        })
    );
  }, [details, activeWarehouseId]);

  //check inventory when quantitysignature change
  useDeepCompareEffect(() => {
    // console.log("Request Inventory in Cart", quantitySignature);
    const idList = quantitySignature.map((item) => item.product_variant_id);

    if (idList.length > 0) {
      (async () => {
        const collection =
          await new InventoryStockRepository().getListProductVariantQuantity(
            idList.join(","),
            "poscart"
          );

        if (!collection.hasError()) {
          let outOfStockList: PosCartOutOfStock[] = [];

          //loop though inventory check items
          quantitySignature.forEach((item) => {
            let outOfStock = true;
            let available = 0;

            const inventory = collection.items.find(
              (inventoryresult) =>
                inventoryresult.product_variant_id === item.product_variant_id
            );

            if (inventory != null && !isUndefined(inventory)) {
              //check
              const inventoryDetail = find(inventory.stocks, {
                warehouse_id: activeWarehouseId,
              });

              if (!isUndefined(inventoryDetail)) {
                if (inventoryDetail.available >= item.item_quantity) {
                  outOfStock = false;
                }
                available = inventoryDetail.available;
              }
            }

            if (outOfStock) {
              outOfStockList.push({
                warehouse_id: activeWarehouseId,
                product_variant_id: item.product_variant_id,
                item_quantity: item.item_quantity,
                available: available,
              });
            }
          });

          setOutOfStock(outOfStockList);
        }
      })();
    } else {
      setOutOfStock([]);
    }
  }, [quantitySignature]);

  //play soundFX when out of stock change
  useEffect(() => {
    if (outOfStock.length > 0 && enableSoundFx) {
      soundFxOutOfStock();
    }

    //store for process at another step (such as validate in submit)
    updateActiveCart({
      out_of_stock: outOfStock,
    });
  }, [outOfStock, soundFxOutOfStock, updateActiveCart, enableSoundFx]);

  const columns: TableColumnsType<PosCartOrderDetail> = [
    {
      title: " ",
      dataIndex: "quantity",
      width: 80,
      render: (_, item) => (
        <PosCartItemQuantityInput
          item={item}
          onChangeQuantity={(newquantity: number) =>
            doChangeQuantity(item, newquantity)
          }
        />
      ),
    },
    {
      title: " ",
      dataIndex: "detail",
      render: (_, item) =>
        item.item_quantity < 0 ? (
          <PosCartItemDetailReturn item={item} />
        ) : (
          <PosCartItemDetail
            item={item}
            outOfStockItem={outOfStock.find(
              (outofstockitem: PosCartOutOfStock) =>
                outofstockitem.product_variant_id === item.product_variant_id
            )}
            onClickItemPrice={() => onClickItemPrice(item)}
          />
        ),
    },
    {
      title: " ",
      dataIndex: "subtotal",
      align: "right",
      width: 100,
      render: (_, item) => <PosCartItemSubtotal item={item} />,
    },
    {
      title: " ",
      dataIndex: "delete",
      align: "right",
      width: 30,
      render: (_, item) => (
        <PosCartItemDelete item={item} onDelete={() => onDeleteItem(item)} />
      ),
    },
  ];

  const dataSource = details.map((item) => {
    return {
      ...item,
      key:
        item.product_variant_id +
        "-" +
        (item.item_quantity < 0 ? "return" : "new"),
    };
  });

  return (
    <div id="pos-cart">
      <Table
        locale={{ emptyText: "Giỏ hàng trống" }}
        showHeader={false}
        pagination={false}
        size="small"
        rowClassName={() => "pos-cart-row"}
        columns={columns}
        dataSource={dataSource}
      />

      {editingDetailIndex >= 0 ? (
        <PosCartModalProductDiscount
          key={editingDetailIndex}
          item={details[editingDetailIndex]}
          show={editingDetailIndex >= 0}
          clearEditingIndex={() => setEditingDetailIndex(-1)}
          onChange={onChangeProductDiscount}
        />
      ) : null}
    </div>
  );
};

export default PosCart;
