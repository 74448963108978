import FileUploaderProvider from "contextproviders/FileUploaderProvider";
import FileUploaderContext from "contexts/FileUploaderContext";
import React, { useEffect, useRef, useState } from "react";
import Typography from "@tiptap/extension-typography";

import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { EditorContent, useEditor } from "@tiptap/react";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Placeholder from "@tiptap/extension-placeholder";

import StarterKit from "@tiptap/starter-kit";

import HtmlEditorBubbleMenu from "./bubblemenu/HtmlEditorBubbleMenu";
import HtmlEditorExtensionFullLink from "./extensions/HtmlEditorExtensionFullLink";
import { HtmlEditorExtensionImagePicker } from "./extensions/HtmlEditorExtensionImagePicker";
import HtmlEditorMenuBar from "./HtmlEditorMenuBar";

import type {
  HtmlEditor,
  HtmlEditorInputProps,
} from "common/interfaces/HtmlEditorInput";
import HtmlEditorExtensionSlashCommand from "./extensions/HtmlEditorExtensionSlashCommand";
const HtmlEditorInput: React.FC<HtmlEditorInputProps> = ({
  placeholder,
  value,
  onChange,
  fileObjectType,
  fileOrigin,
}: HtmlEditorInputProps) => {
  const [html, setHtml] = useState(value);
  const [selectionEmpty, setSelectionEmpty] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const onChangeTimeoutRef = useRef<any>();

  const triggerChange = (changedValue: string) => {
    onChange?.(changedValue);
  };

  const updateHtml = (newHtml: string) => {
    setHtml(newHtml);
    triggerChange(newHtml);
  };

  useEffect(() => {
    setHtml(value);
  }, [value]);

  const editor: HtmlEditor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
        },
      }),
      Link.configure({
        openOnClick: false,
      }),
      HtmlEditorExtensionFullLink.configure({
        openOnClick: false,
      }),
      HtmlEditorExtensionImagePicker,
      Table.configure({
        resizable: true,
      }),
      TableHeader,
      TableRow,
      TableCell,
      Highlight,
      TextStyle,
      Color,
      Typography.configure({
        servicemark: undefined,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
        alignments: ["left", "right", "center"],
        defaultAlignment: "left",
      }),
      TaskList.configure({
        HTMLAttributes: {
          class: "task-list",
        },
      }),
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        emptyNodeClass: "is-empty",
        placeholder: placeholder,
      }),
      HtmlEditorExtensionSlashCommand,
    ],
    editorProps: {
      attributes: {
        class:
          "prose-lg xl:prose-2xl text-md border border-gray-300 rounded-b border-t-0 min-h-[100px]",
      },
      handleDOMEvents: {
        keydown: (_view, event) => {
          // prevent default event listeners from firing when slash command is active
          if (["ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
            const slashCommand = document.querySelector("#slash-command");
            if (slashCommand) {
              return true;
            }
          }
        },
      },
      handlePaste: (view, event) => {
        if (
          event.clipboardData &&
          event.clipboardData.files &&
          event.clipboardData.files[0]
        ) {
          event.preventDefault();
          const file = event.clipboardData.files[0];
          console.log("Upload Image from paste image");
          // return handleImageUpload(file, view, event);
        }
        return false;
      },
      handleDrop: (view, event, _slice, moved) => {
        if (
          !moved &&
          event.dataTransfer &&
          event.dataTransfer.files &&
          event.dataTransfer.files[0]
        ) {
          event.preventDefault();
          const file = event.dataTransfer.files[0];
          console.log("Upload Image from drop image");
          // return handleImageUpload(file, view, event);
        }
        return false;
      },
    },
    autofocus: true,
    editable: true,
    injectCSS: false,
    onSelectionUpdate: ({ editor }) => {
      setSelectionEmpty(editor?.view?.state?.selection?.empty);

      //ensure if there is not selection, we OFF all popup
      //incase the popup process from another modal not work ^^!
      if (editor?.view?.state?.selection?.empty) {
        setPopupVisible(false);
      }
    },

    onBlur: ({ editor }) => {},
    onCreate({ editor }) {
      // The editor is ready.
    },
    onDestroy() {
      // The editor is being destroyed.
    },
    // triggered on every change
    onUpdate: ({ editor }) => {
      //for performance, we check previous timeout to clear before create newtimeout
      if (onChangeTimeoutRef.current) {
        clearTimeout(onChangeTimeoutRef.current);
      }

      //set timeout for running
      onChangeTimeoutRef.current = setTimeout(() => {
        updateHtml(editor.getHTML());
      }, 1000);
    },
    content: html,
  });

  return (
    <>
      <FileUploaderContext.Provider
        value={new FileUploaderProvider(fileObjectType, fileOrigin)}
      >
        <HtmlEditorMenuBar
          editor={editor}
          selectionEmpty={selectionEmpty}
          popupVisible={popupVisible}
          setPopupVisible={setPopupVisible}
        />
      </FileUploaderContext.Provider>
      <HtmlEditorBubbleMenu editor={editor} popupVisible={popupVisible} />
      <EditorContent
        editor={editor}
        className="he-editor"
        style={{ padding: "0 !important" }}
      />
    </>
  );
};

export default HtmlEditorInput;
