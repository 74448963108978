import BaseCollection from "common/collections/BaseCollection";
import { LeaveJson, LeaveCollectionJson } from "common/types/Leave";
import LeaveModel from "./../models/LeaveModel";

class LeaveCollection extends BaseCollection<
  LeaveJson,
  LeaveModel,
  LeaveCollectionJson
> {
  itemsFromJson(jsonItems: LeaveJson[]): LeaveModel[] {
    return jsonItems.map((item) => new LeaveModel(item));
  }
}

export default LeaveCollection;
