import ProjectApi from 'common/api/ProjectApi';
import ProjectCollection from 'common/collections/ProjectCollection';
import ProjectModel from 'common/models/ProjectModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProjectJsonAddEdit,
  FilterProject,
  ProjectReportJson,
} from "common/types/Project";

class ProjectRepository extends BaseRepository<ProjectApi> {
  _api: ProjectApi | null;

  constructor() {
    super();
    this._api = new ProjectApi(true);
  }

  static getDefaultFilters(): FilterProject {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterProject>) {
    return this._api ? await this._api.getList(props) : new ProjectCollection();
  }

  async getItem(id: number): Promise<ProjectModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProjectModel(ProjectModel.getDefaultData());
  }

  async getReport(companyId: number): Promise<ProjectReportJson> {
    return this._api
      ? await this._api.getReport(companyId)
      : ProjectModel.getDefaultDataReport();
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: ProjectJsonAddEdit): Promise<ProjectModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProjectModel(ProjectModel.getDefaultData());
    }
  }
}

export default ProjectRepository;
