import { Checkbox, Dropdown, Tag, Tooltip, Typography } from "antd";
import PromotionCollection from "common/collections/PromotionCollection";
import Promotion from "common/constants/Promotion";
import Role from "common/constants/Role";
import PromotionModel from "common/models/PromotionModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import { FilterPromotion, PromotionJson } from "common/types/Promotion";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import dayjs from "dayjs";
import PromotionListHeader from "features/promotion/list/PromotionListHeader";
import PromotionCouponModal from "features/promotioncoupon/form/PromotionCouponModal";
import PromotionProductModal from "features/promotionproduct/form/PromotionProductModal";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
	IconEye,
	IconEyeDiscount,
	IconPencil,
	IconSettings,
} from "@tabler/icons-react";

import PromotionFormModal from "../form/PromotionFormModal";
// import PromotionFormSelectGroupModal from "../form/PromotionFormSelectGroupModal";
import PromotionSettingStoreModal from "../form/PromotionSettingStoreModal";
import PromotionListFilter from "./PromotionListFilter";
import { MoreOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import PromotionCouponList from "features/promotioncoupon/list/PromotionCouponList";
import CouponList from "./CouponList";
import TableInfoRow from "components/table/TableInfoRow";

const PromotionList = () => {
	const { t } = useTranslation();

	// modal editing store apply promotion
	const [editPromotionStoreVisible, setEditPromotionStoreVisible] =
		useState(false);

	// modal setting Compo code
	const [editPromotionCouponVisible, setEditPromotionCouponVisible] =
		useState(false);

	// modal setting promotion Product
	const [editPromotionProductVisible, setEditPromotionProductVisible] =
		useState(false);

	// modal setting promotion
	const [editPromotionVisible, setEditPromotionVisible] = useState(false);

	const [addPromotionVisible, setPromotionVisible] = useState(false);

	const [editingPromotionModel, setEditingPromotionModel] =
		useState<PromotionModel>(
			new PromotionModel(PromotionModel.getDefaultData())
		);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

	const [group, setGroup] = useState(0);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterPromotion = useMemo(
		() => PromotionRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] = useStateFilter<FilterPromotion>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<PromotionModel[]>([]);
	const fetchData = useCallback(async (): Promise<PromotionCollection> => {
		let collection = await new PromotionRepository().getItems({
			filters,
		});
		setDataSource(collection.items);
		setTotal(collection.total);

		return collection;
	}, [filters]);

	// modal promotion form select group
	const [formSelectGroupVisible, setFormSelectGroupVisible] = useState(false);

	const onSaveSuccess = (item: PromotionModel) => {
		//detech this is NEW or UPDATE
		const foundIndex = dataSource.findIndex((r) => r.id === item.id);
		if (foundIndex >= 0) {
			//update current role item info
			setDataSource(
				update(dataSource, {
					[foundIndex]: {
						$set: item,
					},
				})
			);
		} else {
			//append new item to list
			setDataSource(
				update(dataSource, {
					$unshift: [item],
				})
			);
		}
	};

	const onEdit = (promotion: PromotionModel) => {
		setEditingPromotionModel(promotion);
		setEditPromotionVisible(true);
	};

	//Table columns
	const columns: TableColumnsType<PromotionModel> = [
		{
			title: t("promotion:code"),
			key: "code",
			align: "center",
			width: 100,
			render: (id, record) => {
				return <Typography.Link copyable>{id}</Typography.Link>;
			},
		},
		{
			title: t("promotion:name"),
			key: "name",
			width: 250,
		},
		{
			title: t("promotion:group"),
			width: 100,
			key: "group",
			render: (group: number) => {
				let groupInfo = PromotionModel.getGroup(group);
				return <span>{groupInfo?.label}</span>;
			},
		},
		{
			title: t("promotion:type"),
			width: 160,
			key: "type",
			render: (type: number) => {
				let groupInfo = PromotionModel.getType(type);
				return <span>{groupInfo?.label}</span>;
			},
		},

		{
			title:
				t("worktrackingrange:time_start") +
				" - " +
				t("worktrackingrange:time_end"),
			dataIndex: "time_start",
			width: 150,
			key: "date_start",
			render: (text: string, record: PromotionModel) => (
				<span>
					<TextDateTime
						format={
							dayjs
								.unix(record.start_date)
								.isSame(dayjs.unix(record.end_date), "year")
								? "DD/MM"
								: undefined
						}
						ts={record.start_date}
					/>{" "}
					-
					<TextDateTime ts={record.end_date} />
				</span>
			),
		},
		// {
		//   title: t("promotion:form.section_store_title"),
		//   width: 100,
		//   key: "store_apply",
		//   toggletype: "extend",
		//   render: (_: any, record: PromotionModel) => {
		//     return (
		//       <Tooltip title={t("promotion:form.store")} mouseEnterDelay={0.4}>
		//         <Tag
		//           icon={<IconPencil size={14} />}
		//           className="opacity-50 cursor-pointer hover:opacity-100"
		//           onClick={() => {
		//             setEditPromotionStoreVisible(true);
		//             setEditingPromotionModel(record);
		//           }}
		//         >
		//           ...
		//         </Tag>
		//       </Tooltip>
		//     );
		//   },
		// },
		{
			title: t("Điều kiện áp dụng"),
			key: "s",
			render: (_: any, record: PromotionModel) => {
				const keys: string[] = ["max_quantity_check", "store_list"];
				return (
					<div>
						<Tooltip
							title={
								<div className="flex gap-2">
									<span>Số lượng:</span>
									<span>
										{record.max_quantity_check === 0
											? "Vô hạn"
											: record.max_quantity_check}
									</span>
								</div>
							}>
							<IconEye></IconEye>
						</Tooltip>
					</div>
				);
			},
			width: 120,
		},
		{
			title: t("common:status"),
			key: "status",
			render: (status: number) => {
				const statusInfor = PromotionModel.getStatus(status);
				return (
					<div>
						<Tag color={statusInfor?.color}>{statusInfor?.label}</Tag>
					</div>
				);
			},
			width: 120,
		},
		{
			title: t("Thiết lập nhóm hàng"),
			key: "type",

			width: 120,
			render: (_: any, record: PromotionModel) => {
				return (
					<div>
						<Tag
							color="blue"
							icon={<IconSettings size={14} />}
							className=" ml-1 cursor-pointer"
							onClick={() => {
								setEditPromotionProductVisible(true);
								setEditingPromotionModel(record);
							}}>
							Thiết lập
						</Tag>
					</div>
				);
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 150,
			render: (_: any, record: PromotionModel) => {
				let items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<TableEdit
								label={t("Chỉnh sửa")}
								link=""
								onClick={() => onEdit(record)}></TableEdit>
						),
					},
				];

				if (record.group == Promotion.GROUP_COUPON_ID) {
					items.push({
						key: "2",
						label: (
							<TableEdit
								label="Thiết lập mã"
								link={`/promotion/edit/id/${record.id}`}></TableEdit>
						),
					});
				}
				return (
					<RoleCheck roles={[Role.PROMOTION_MANAGE]} hideOnFail>
						{/* <TableEdit link="" onClick={() => onEdit(record)} /> */}
						<div className="flex justify-end">
							<Dropdown trigger={["click"]} menu={{ items }}>
								<MoreOutlined
									style={{ fontSize: "16pt" }}
									className="cursor-pointer "
								/>
							</Dropdown>
							{/* {record.group === "coupon" && (
								<TableEdit
									label="Thiết lập mã"
									link={`/promotion/edit/id/${record.id}`}></TableEdit>
							)} */}
							<TableInfo record={record}></TableInfo>
						</div>
					</RoleCheck>
				);
			},
		},
	];

	//expand

	const expandable = useMemo(
		() => ({
			expandedRowRender: (record: PromotionModel) => {
				if (record.group !== Promotion.GROUP_COUPON_ID) {
					return null;
				}
				// return <ProductItemExpand recordId={record.id} />;
				return <CouponList promotionId={record.id}></CouponList>;
			},
			expandedRowClassName: () => "ant-table-expanded-row-dimmer",
			expandRowByClick: true,
			showExpandColumn: false,
			expandedRowKeys: expandedRowKeys,
			onExpand: (expanded: boolean, record: PromotionModel) => {
				if (expanded) {
					setExpandedRowKeys([record.id]);
				} else {
					setExpandedRowKeys([]);
				}
			},
		}),
		[expandedRowKeys]
	);

	return (
		<>
			<PromotionListHeader>
				<RoleCheck roles={[Role.PROMOTION_MANAGE]}>
					<PageHeaderButton
						type="primary"
						link=""
						onClick={() => {
							// setFormSelectGroupVisible(true);
							setEditPromotionVisible(true);
							setEditingPromotionModel(
								new PromotionModel(PromotionModel.getDefaultData())
							);
						}}>
						{t("promotion:add_button")}
					</PageHeaderButton>
				</RoleCheck>
			</PromotionListHeader>

			<PromotionListFilter
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>

			<PageDataTable<FilterPromotion, PromotionModel, PromotionCollection>
				{...{
					columns,
					// expandable,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					rowClassName: "cursor-pointer",
					tableColumnToggleKey: "promotion",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			{/* {editPromotionStoreVisible ? (
        <PromotionSettingStoreModal
          model={editingPromotionModel}
          open={editPromotionStoreVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPromotionModel(
                new PromotionModel(PromotionModel.getDefaultData())
              );
            }
            setEditPromotionStoreVisible(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      ) : null}

      {editPromotionCouponVisible ? (
        <PromotionCouponModal
          model={editingPromotionModel}
          open={editPromotionCouponVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPromotionModel(
                new PromotionModel(PromotionModel.getDefaultData())
              );
            }
            setEditPromotionCouponVisible(isOpen);
          }}
        />
      ) : null} */}

			{editPromotionProductVisible ? (
				<PromotionProductModal
					model={editingPromotionModel}
					open={editPromotionProductVisible}
					onUpdate={() => {
						fetchData();
					}}
					setOpen={(isOpen) => {
						//clear editing id when close
						if (!isOpen) {
							setEditingPromotionModel(
								new PromotionModel(PromotionModel.getDefaultData())
							);
						}
						setEditPromotionProductVisible(isOpen);
					}}
				/>
			) : null}

			{/* <PromotionFormSelectGroupModal
        key={"cashflow-form-select-type-modal"}
        open={formSelectGroupVisible}
        setOpen={(isOpen) => {
          setFormSelectGroupVisible(isOpen);
        }}
        setGroup={(group) => {
          setGroup(group);
          setEditPromotionVisible(true);
        }}
      /> */}

			{editPromotionVisible ? (
				<PromotionFormModal
					id={editingPromotionModel.id}
					open={editPromotionVisible}
					group={group}
					setOpen={(isOpen) => {
						//clear editing id when close
						if (!isOpen) {
							setEditingPromotionModel(
								new PromotionModel(PromotionModel.getDefaultData())
							);
						}
						setEditPromotionVisible(isOpen);
					}}
					onSaveSuccess={onSaveSuccess}
				/>
			) : null}
		</>
	);
};

export default PromotionList;
