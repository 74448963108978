import OrderModel from "common/models/OrderModel";
import OrderSellerRepository from "common/repositories/OrderSellerRepository";
import { CartOrderJson } from "common/types/Cart";
import FormEditFetcher from "components/form/FormEditFetcher";
import OrderSellerForm from "features/orderSeller/form/OrderSellerForm";
import OrderSellerFormHeader from "features/orderSeller/form/OrderSellerFormHeader";
import OrderSellerList from "features/orderSeller/list/OrderSellerList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const OrderSellerPage = () => {
	const [pathParams] = usePathParams();

	const [model, setModel] = useState<OrderModel>(
		new OrderModel(OrderModel.getDefaultData())
	);
	const [fetchErrors, setFetchErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setModel(new OrderModel(OrderModel.getDefaultData()));
		setFetchErrors([]);
	}, [pathParams.action]);

	let com = null;
	const isModel =
		(typeof model.id === "string" && model.id.length > 0) ||
		(typeof model.id === "number" && model.id > 0);

	switch (pathParams.action) {
		case "add":
			break;
		case "edit":
			com = isModel ? (
				<OrderSellerForm  order={model.toJson()}/>
			) : (
				<FormEditFetcher
					doLoad={async () => {
						let fetchedModel = await new OrderSellerRepository().getItem(
							pathParams.id.toString()
						);
						if (fetchedModel.hasError()) {
							setFetchErrors(fetchedModel.error.errors);
						}else{
							setModel(fetchedModel)
						}
						
					}}
					errors={fetchErrors}
					heading={<OrderSellerFormHeader id={+pathParams.id} isEditing={true} />}
				/>
			);

			break;
		default:
			com = <OrderSellerList />;
	}

	return com;
};

export default OrderSellerPage;
