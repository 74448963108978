import { Button } from 'antd';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';
import { PosCartPayment } from 'common/types/PosCart';
import TextMoney from 'components/TextMoney';

const PosRightPaymentItem = ({
  payment,
  onRemove,
}: {
  payment: PosCartPayment;
  onRemove: () => void;
}) => {
  const foundMethod = CashflowReceiptModel.getMethod(payment.method);

  return (
    <div>
      - {foundMethod?.label}:{" "}
      <strong>
        <TextMoney money={payment.value} />
      </strong>
      <Button type="link" onClick={onRemove}>
        (Xóa)
      </Button>
    </div>
  );
};

export default PosRightPaymentItem;
