import BaseCollection from "common/collections/BaseCollection";
import BiCenterModel from "common/models/BiCenterModel";
import type {
  BiCenterJson,
  BiCenterCollectionJson,
} from "common/types/BiCenter";

class BiCenterCollection extends BaseCollection<
  BiCenterJson,
  BiCenterModel,
  BiCenterCollectionJson
> {
  itemsFromJson(jsonItems: BiCenterJson[]): BiCenterModel[] {
    return jsonItems.map((item) => new BiCenterModel(item));
  }
}

export default BiCenterCollection;
