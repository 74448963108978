import React, { useRef, useState } from "react";
import { Checkbox, Form } from "antd";
import MoneyInput from "components/MoneyInput";
import PostAction from "features/delivery/action/PostAction";
import { ActionCode } from "common/types/Delivery";
import DeliveryModel from "common/models/DeliveryModel";
import { useTranslation } from "react-i18next";

import { CheckboxChangeEvent } from "antd/es/checkbox";
import { debounce } from "lodash";
type Props = {
  nextAction: ActionCode;
  gpsRequire: boolean;
  uploadRequire: boolean;
  noteRequire: boolean;
  model: DeliveryModel;
  handleSucess?: () => void;
};
const DeliveryMineTransferForm = ({
  nextAction,
  gpsRequire,
  uploadRequire,
  noteRequire,
  model,
  handleSucess,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const cod = model.cod;

  // state
  const [isFull, setIsFull] = useState(false);
  const [codValue, setCodValue] = useState(model.cod);

  const [transferValue, setTransferValue] = useState(0);

  //handleChangeMoneyCOD
  const handleChangeMoneyCOD = debounce((e: number | null) => {
    if (typeof e === "number") {
      if (e > cod) {
        setCodValue(e);
        setTransferValue(0);
      } else {
        setTransferValue(cod - e);
        setCodValue(e);
        form.setFieldValue("transfer", cod - e);
      }
    }
  }, 500);

  const handleChangeMoneyTransfer = debounce((e: number | null) => {
    if (typeof e === "number") {
      if (e > cod) {
        setCodValue(0);
        setTransferValue(e);
        form.setFieldValue("cod", 0);
      } else {
        setCodValue(cod - e);
        setTransferValue(e);
        form.setFieldValue("cod", cod - e);
      }
    }
  }, 500);

  const handleFillFullCOD = (e: CheckboxChangeEvent) => {
    setIsFull(e.target.checked);
    if (e.target.checked) {
      setCodValue(0);
      setTransferValue(cod);
      form.setFieldValue("cod", 0);
      form.setFieldValue("transfer", cod);
    } else {
      setCodValue(cod);
      setTransferValue(0);
      form.setFieldValue("cod", cod);
      form.setFieldValue("transfer", 0);
    }
  };

  return (
    <div>
      <Form
        validateTrigger="onChange"
        form={form}
        onFinish={() => {}}
        initialValues={{ cod: model.cod, transfer: 0 }}
      >
        <Form.Item
          name="cod"
          label={t("delivery:mine.detail.cod")}
          rules={[
            {
              message: "COD không hợp lệ",
              validator: (_, value) => {
                return new Promise((resolve, reject) => {
                  if (value > model.cod) {
                    reject(".");
                  } else {
                    resolve(1);
                  }
                });
              },
            },
          ]}
        >
          <MoneyInput
            className="block pl-1"
            name="cod"
            onChange={handleChangeMoneyCOD}
            addonAfter={<>VNĐ</>}
            min={0}
            max={model.cod}
            disabled={isFull}
          ></MoneyInput>
        </Form.Item>

        <Form.Item name="transfer" label={t("delivery:mine.detail.transfer")}>
          <MoneyInput
            className="block pl-1"
            onChange={handleChangeMoneyTransfer}
            addonAfter={<>VNĐ</>}
            min={0}
            disabled={isFull}
          ></MoneyInput>
        </Form.Item>

        <Form.Item name="full" label={t("delivery:mine.detail.fulltransfer")}>
          <Checkbox onChange={handleFillFullCOD} />
        </Form.Item>
      </Form>
      <PostAction
        key={nextAction}
        action={nextAction}
        gpsRequire={gpsRequire}
        noteRequire={noteRequire}
        uploadRequire={uploadRequire}
        isShowNote={false}
        data={{
          deliveries_id: model.id,
          note: codValue.toString(),
        }}
        anotherPostAction={[
          {
            action: "ACTION_TRANSFER",
            data: {
              deliveries_id: model.id,
              note: transferValue.toString(),
            },
          },
        ]}
        handlePostActionSuccess={() => {
          handleSucess && handleSucess();
        }}
      ></PostAction>
    </div>
  );
};

export default DeliveryMineTransferForm;
