const ShippingCarrier = {
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,

  APITYPE_EMPTY: 1,
  APITYPE_INHOUSE: 3,
  APITYPE_GHN: 5,
  APITYPE_GHTK: 7,
  APITYPE_JTEXPRESS: 9,

  JTEXPRESS_PAYTYPE_PP_PM: 1,
  JTEXPRESS_PAYTYPE_PP_CASH: 3,
  JTEXPRESS_PAYTYPE_CC_CASH: 5,

  APISETTING_GHN_SERVICE_TYPE_EXPRESS: 1,
  APISETTING_GHN_SERVICE_TYPE_STANDARD: 2,
  APISETTING_GHN_SERVICE_TYPE_SAVE: 3,
};

export default ShippingCarrier;
