import { Button, ButtonProps, InputNumber, Popover, Tooltip } from "antd";
import { Filter } from "common/types/Filter";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const SimplePagination = <F extends Filter>({
  filters,
  setFilters,
  total,
  size,
  hideOnSinglePage,
}: {
  filters: F;
  setFilters: (f: F) => any;
  total: number;
  size?: ButtonProps["size"];
  hideOnSinglePage?: boolean;
}) => {
  const { t } = useTranslation();

  const lastPage = useMemo(
    () => Math.ceil(total / +filters.limit),
    [filters.limit, total]
  );

  const goToPage = useCallback(
    (newPage: number) => {
      let refinedPage = newPage;
      if (newPage < 1) {
        refinedPage = 1;
      } else if (newPage > lastPage) {
        refinedPage = lastPage;
      }

      setFilters({
        ...filters,
        page: refinedPage,
      });
    },
    [setFilters, filters, lastPage]
  );

  if (
    lastPage === 0 ||
    (lastPage === 1 &&
      typeof hideOnSinglePage !== "undefined" &&
      hideOnSinglePage)
  ) {
    return null;
  }

  return (
    <div className="flex items-center justify-center justify-items-center">
      <Tooltip
        title={t("common:pagination.prev")}
        placement="bottom"
        mouseEnterDelay={0.4}
      >
        <Button
          disabled={filters.page === 1}
          icon={<IconChevronLeft className="mb-1" />}
          className=""
          type="text"
          onClick={() => goToPage(+filters.page - 1)}
          size={size}
        />
      </Tooltip>
      <Popover
        content={
          <div>
            <InputNumber
              min={1}
              max={lastPage}
              style={{ width: 200 }}
              addonBefore={t("common:pagination.go_to_page")}
              autoFocus
              addonAfter={<>/ {lastPage}</>}
              onPressEnter={(e) =>
                goToPage(parseInt((e.target as HTMLInputElement).value))
              }
              size={size}
            />
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <Button type="text" size={size}>
          <strong className="hidden md:inline-block font-normal">
            {t("common:pagination.page")}
          </strong>{" "}
          &nbsp;{filters.page} /&nbsp;{" "}
          <span className="text-gray-400">{lastPage}</span>
        </Button>
      </Popover>

      <Tooltip
        title={t("common:pagination.next")}
        placement="bottom"
        mouseEnterDelay={0.4}
      >
        <Button
          size={size}
          disabled={filters.page === lastPage}
          icon={<IconChevronRight className="mb-1" />}
          className=""
          type="text"
          onClick={() => goToPage(+filters.page + 1)}
        />
      </Tooltip>
    </div>
  );
};

export default SimplePagination;
