import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import eventEmitter from "common/utils/eventEmitter";

import { IconPhoto, IconResize } from "@tabler/icons-react";

import { ImageAttribute } from "./extensions/HtmlEditorExtensionImagePicker";
import HtmlEditorImagePickerModal from "./HtmlEditorImagePickerModal";
import HtmlEditorMenuBarButton from "./HtmlEditorMenuBarButton";

const DEFAULT_IMAGE_PICKER_ATTRIBUTE: ImageAttribute = {
  src: "",
  alt: "",
  title: " ",
  width: "",
  height: "",
};

const HtmlEditorMenuBarButtonImagePicker = ({
  editor,
  selectionEmpty,
  setPopupVisible,
}: {
  editor: HtmlEditor;
  selectionEmpty: boolean;
  setPopupVisible: (open: boolean) => void;
}) => {
  const { t } = useTranslation("htmleditor");
  const eventEmitterRef = useRef<any>();

  const [open, setOpen] = useState(false);
  const [editingAttribute, setEditingAttribute] = useState<ImageAttribute>(
    DEFAULT_IMAGE_PICKER_ATTRIBUTE
  );

  //clear editing data after close model
  useEffect(() => {
    if (!open) {
      setEditingAttribute(DEFAULT_IMAGE_PICKER_ATTRIBUTE);
    }
    setPopupVisible(open);
  }, [open, setPopupVisible]);

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "HTMLEDITOR_BUBBLE_MENU_IMAGE_PICKER",
        (data: ImageAttribute) => {
          setEditingAttribute(data as ImageAttribute);
          setOpen(true);
        }
      );
    }
  });

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);

  if (!editor) {
    return null;
  }

  return (
    <>
      <HtmlEditorMenuBarButton
        active={false}
        disabled={false}
        icon={editor.isActive("image") ? <IconResize /> : <IconPhoto />}
        title={editor.isActive("image") ? t("image_edit") : t("image_add")}
        onClick={() => {
          if (editor.isActive("image")) {
            const attr = editor.getAttributes("image");
            setEditingAttribute({
              src: attr.src || "",
              alt: attr.alt || "",
              title: attr.title || "",
              width: attr.width || "",
              height: attr.height || "",
            });
          } else {
            setEditingAttribute(DEFAULT_IMAGE_PICKER_ATTRIBUTE);
          }

          setOpen(true);
        }}
      />
      <HtmlEditorImagePickerModal
        open={open}
        setOpen={setOpen}
        attributes={editingAttribute}
        onSubmit={(attributes: ImageAttribute) => {
          editor
            .chain()
            .focus()
            .setImageExtra({
              src: attributes.src,
              alt: attributes.alt,
              title: attributes.title,
              width: attributes.width,
              height: attributes.height,
            })
            .run();
        }}
      />
    </>
  );
};

export default HtmlEditorMenuBarButtonImagePicker;
