import { Form, Input } from "antd";
import Role from "common/constants/Role";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import { Filter } from "common/types/Filter";
import { WarehouseJson } from "common/types/Warehouse";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import WarehouseFormFilter from "features/warehouse/WarehouseFormFilter";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useTranslation } from "react-i18next";

const ProductInventoryCheckingListFilter = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	total,
}: {
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
	total: number;
}) => {
	const { t } = useTranslation();
	const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
		"warehouse",
		Role.INVENTORY_VIEW
	);
	return (
		<PageDataFilterForm
			filters={filters}
			setFilters={setFilters}
			total={total}
			defaultFilters={defaultFilters}
			convertValueByKeyFilter={["warehouse_id", "status"]}>
			<Form.Item name="keyword">
				<Input
					allowClear
					placeholder={t("productinventorychecking:filter_keyword_placeholder")}
				/>
			</Form.Item>
			<Form.Item name="id">
				<Input allowClear placeholder={t("productinventorychecking:id")} />
			</Form.Item>
			<FormSelect
				name="status"
				placeholder={t("common:status")}
				options={ProductInventoryCheckingModel.getStatusList()}
				allowClear
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="is_complete"
				placeholder={t("Tình trạng")}
				options={[
					{
						label: "Chưa hoàn thành",
						value: 0,
					},
					{
						label: "Đã hoàn thành",
						value: 1,
					},
				]}
				allowClear
				popupMatchSelectWidth={false}
			/>
			<WarehouseFormFilter
				placeholder={t("productinventorychecking:warehouse")}
				name="warehouse_id"
				allowClear
				defaultOptions={warehouseItemsWithLimit.map((i) => ({
					label: i.name,
					value: i.id,
				}))}
				popupMatchSelectWidth={false}
			/>
		</PageDataFilterForm>
	);
};

export default ProductInventoryCheckingListFilter;
