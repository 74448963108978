import { useNavigate } from "react-router-dom";

const PageDataFilterFormExtraItem = ({
  className,
  children,
  link,
  onClick,
}: {
  className?: string;
  children?: React.ReactNode;
  link?: string;
  onClick?: () => void;
}) => {
  let navigate = useNavigate();

  if (typeof link !== "undefined" || typeof onClick !== "undefined") {
    return (
      <span
        onClick={() => {
          if (typeof link !== "undefined") {
            navigate(link);
          }
          if (typeof onClick !== "undefined") {
            onClick();
          }
        }}
        className={
          className || "text-blue-600 cursor-pointer hover:underline text-sm"
        }
      >
        {children}
      </span>
    );
  }

  return (
    <span className={className || "text-gray-500 text-sm"}>{children}</span>
  );
};

export default PageDataFilterFormExtraItem;
