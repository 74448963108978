import EmployeeModel from "common/models/EmployeeModel";
import {
  EmployeeJson,
  EmployeeCollectionFetchingJson,
} from "common/types/Employee";
import BaseCollectionFetching from "./BaseCollectionFetching";
class EmployeeCollectionIdFetching extends BaseCollectionFetching<
  EmployeeJson,
  EmployeeModel,
  EmployeeCollectionFetchingJson
> {
  itemsFromJson(jsonItems: EmployeeJson[]): EmployeeModel[] {
    return jsonItems.map((item) => new EmployeeModel(item));
  }
}

export default EmployeeCollectionIdFetching;
