import CashflowReceipt from 'common/constants/CashflowReceipt';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';

const TextCashflowReceiptDirection = ({
  direction,
  prefix,
}: {
  direction: number;
  prefix?: string;
}) => {
  let directionInfo = CashflowReceiptModel.getDirection(direction);
  return (
    <span
      className={
        direction === CashflowReceipt.DIRECTION_INCOME
          ? "text-green-500"
          : "text-red-500"
      }
    >
      {prefix}
      {directionInfo?.label}
    </span>
  );
};

export default TextCashflowReceiptDirection;
