import ProductReceipt from "common/constants/ProductReceipt";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { ProductReceiptJson } from "common/types/ProductReceipt";

class ProductReceiptModel
	extends BaseModel
	implements BaseModelJson<ProductReceiptJson>
{
	id: number;
	company_id: number;
	creator_id: number;
	warehouse_id: number;
	identifier: string;
	type: number;
	direction: number;
	sale_order_id: number;
	purchase_order_id: number;
	note: string;
	status: number;
	date_created: number;
	date_modified: number;

	constructor(json: ProductReceiptJson) {
		super();

		this.id = json.id || 0;
		this.company_id = json.company_id || 0;
		this.creator_id = json.creator_id || 0;
		this.warehouse_id = json.warehouse_id || 0;
		this.identifier = json.identifier || "";
		this.type = json.type || 0;
		this.direction = json.direction || 0;
		this.sale_order_id = json.sale_order_id || 0;
		this.purchase_order_id = json.purchase_order_id || 0;
		this.note = json.note || "";
		this.status = json.status || 0;
		this.date_created = json.date_created || 0;
		this.date_modified = json.date_modified || 0;
	}
	static getDefaultData(): ProductReceiptJson {
		return {
			id: 0,
			company_id: 0,
			creator_id: 0,
			identifier: "",
			warehouse_id: 0,
			type: 0,
			direction: 0,
			sale_order_id: 0,
			purchase_order_id: 0,
			note: "",
			status: ProductReceipt.STATUS_DRAFT,
			date_created: 0,
			date_modified: 0,
			details: [],
		};
	}

	toJson(): ProductReceiptJson {
		return {
			id: this.id,
			company_id: this.company_id,
			creator_id: this.creator_id,
			warehouse_id: this.warehouse_id,
			identifier: this.identifier,
			type: this.type,
			direction: this.direction,
			sale_order_id: this.sale_order_id,
			purchase_order_id: this.purchase_order_id,
			note: this.note,
			status: this.status,
			date_created: this.date_created,
			date_modified: this.date_modified,
			details: [],
		};
	}

	static getDirectionList(): SelectOption[] {
		return [
			{
				value: ProductReceipt.DIRECTION_INPUT,
				label: i18n.t("productreceipt:DIRECTION_INPUT"),
			},
			{
				value: ProductReceipt.DIRECTION_OUTPUT,
				label: i18n.t("productreceipt:DIRECTION_OUTPUT"),
			},
		];
	}

	static getDirection(value: number): SelectOption | undefined {
		return this.getDirectionList().find((item) => item.value === value);
	}

	static getTypeList(): SelectOption[] {
		return [
			{
				value: ProductReceipt.TYPE_BUYING,
				label: i18n.t("productreceipt:TYPE_BUYING"),
			},
			{
				value: ProductReceipt.TYPE_TRANSFERSTORE,
				label: i18n.t("productreceipt:TYPE_TRANSFERSTORE"),
			},
			{
				value: ProductReceipt.TYPE_BALANCESTORE,
				label: i18n.t("productreceipt:TYPE_BALANCESTORE"),
			},
			{
				value: ProductReceipt.TYPE_INPUTRECEIVE,
				label: i18n.t("productreceipt:TYPE_INPUTRECEIVE"),
			},
			{
				value: ProductReceipt.TYPE_SELLING,
				label: i18n.t("productreceipt:TYPE_SELLING"),
			},
			{
				value: ProductReceipt.TYPE_SELLING,
				label: i18n.t("productreceipt:TYPE_SELLING"),
			},
			{
				value: ProductReceipt.TYPE_OUTPUTRECEIVE,
				label: i18n.t("productreceipt:TYPE_OUTPUTRECEIVE"),
			},
			{
				value: ProductReceipt.TYPE_OUTPUTCORRUPT,
				label: i18n.t("productreceipt:TYPE_OUTPUTCORRUPT"),
			},
			{
				value: ProductReceipt.TYPE_OUTPUTLOSS,
				label: i18n.t("productreceipt:TYPE_OUTPUTLOSS"),
			},
			{
				value: ProductReceipt.TYPE_OTHER,
				label: i18n.t("productreceipt:TYPE_OTHER"),
			},
		];
	}

	static getTypeInList(): SelectOption[] {
		return [
			{
				value: ProductReceipt.TYPE_BUYING,
				label: i18n.t("productreceipt:TYPE_BUYING"),
			},
			{
				value: ProductReceipt.TYPE_TRANSFERSTORE,
				label: i18n.t("productreceipt:TYPE_TRANSFERSTORE"),
			},
			{
				value: ProductReceipt.TYPE_BALANCESTORE,
				label: i18n.t("productreceipt:TYPE_BALANCESTORE"),
			},
			{
				value: ProductReceipt.TYPE_INPUTRECEIVE,
				label: i18n.t("productreceipt:TYPE_INPUTRECEIVE"),
			},
			{
				value: ProductReceipt.TYPE_OTHER,
				label: i18n.t("productreceipt:TYPE_OTHER"),
			},
		];
	}

	static getTypeIn(value: number): SelectOption | undefined {
		return this.getTypeInList().find((item) => item.value === value);
	}

	static getTypeOutList(): SelectOption[] {
		return [
			{
				value: ProductReceipt.TYPE_SELLING,
				label: i18n.t("productreceipt:TYPE_SELLING"),
			},
			{
				value: ProductReceipt.TYPE_SELLING,
				label: i18n.t("productreceipt:TYPE_SELLING"),
			},
			{
				value: ProductReceipt.TYPE_TRANSFERSTORE,
				label: i18n.t("productreceipt:TYPE_TRANSFERSTORE"),
			},
			{
				value: ProductReceipt.TYPE_BALANCESTORE,
				label: i18n.t("productreceipt:TYPE_BALANCESTORE"),
			},
			{
				value: ProductReceipt.TYPE_OUTPUTRECEIVE,
				label: i18n.t("productreceipt:TYPE_OUTPUTRECEIVE"),
			},
			{
				value: ProductReceipt.TYPE_OUTPUTCORRUPT,
				label: i18n.t("productreceipt:TYPE_OUTPUTCORRUPT"),
			},
			{
				value: ProductReceipt.TYPE_OUTPUTLOSS,
				label: i18n.t("productreceipt:TYPE_OUTPUTLOSS"),
			},
			{
				value: ProductReceipt.TYPE_OTHER,
				label: i18n.t("productreceipt:TYPE_OTHER"),
			},
		];
	}

	static getTypeOut(value: number): SelectOption | undefined {
		return this.getTypeOutList().find((item) => item.value === value);
	}

	static getStatusList(): SelectOption[] {
		return [
			{
				value: ProductReceipt.STATUS_DRAFT,
				label: i18n.t("productreceipt:STATUS_DRAFT"),
				color: "black",
			},
			{
				value: ProductReceipt.STATUS_WAIT_FOR_APPROVAL,
				label: i18n.t("productreceipt:STATUS_WAIT_FOR_APPROVAL"),
				color: "orange",
			},
			{
				value: ProductReceipt.STATUS_APPROVED,
				label: i18n.t("productreceipt:STATUS_APPROVED"),
				color: "orange",
			},
			{
				value: ProductReceipt.STATUS_CHECKED,
				label: i18n.t("productreceipt:STATUS_CHECKED"),
				color: "orange",
			},
			{
				value: ProductReceipt.STATUS_COMPLETED,
				label: i18n.t("productreceipt:STATUS_COMPLETED"),
				color: "green",
			},
			{
				value: ProductReceipt.STATUS_CANCELLED,
				label: i18n.t("productreceipt:STATUS_CANCELLED"),
				color: "red",
			},
		];
	}

	static getStatusLessList(): SelectOption[] {
		return [
			{
				value: ProductReceipt.STATUS_DRAFT,
				label: i18n.t("productreceipt:STATUS_DRAFT"),
				color: "black",
			},
			{
				value: ProductReceipt.STATUS_WAIT_FOR_APPROVAL,
				label: i18n.t("productreceipt:STATUS_WAIT_FOR_APPROVAL"),
				color: "orange",
			},
			{
				value: ProductReceipt.STATUS_APPROVED,
				label: i18n.t("productreceipt:STATUS_APPROVED"),
				color: "orange",
			},
			{
				value: ProductReceipt.STATUS_CHECKED,
				label: i18n.t("productreceipt:STATUS_CHECKED"),
				color: "orange",
			},
			{
				value: ProductReceipt.STATUS_COMPLETED,
				label: i18n.t("productreceipt:STATUS_COMPLETED"),
				color: "green",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}
}

export default ProductReceiptModel;
