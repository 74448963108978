import { Form, Input } from "antd";
import { SelectValue } from "antd/es/select";
import ProductModel from "common/models/ProductModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import StoreFormSelectNormal from "features/store/StoreFormSelectNormal";
import SupplierFormSelect from "features/supplier/SupplierFormSelect";
import { useTranslation } from "react-i18next";
import ProductCollectionFormSelectFilter from "./ProductCollectionFormSelectFilter";

const ProductListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
  list_store,
  // list_type,
  listTags
}: {
  filters: any;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
  list_store: SelectValue[];
  list_type?: SelectValue[];
  listTags: SelectValue[];
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={[
        "status",
        "category_id",
        "supplier_id",
        "store_id",
        "collection_id",
        "tags_code",
      ]}
    >
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("product:filter_keyword_placeholder")}
        />
      </Form.Item>
      {/* <Form.Item name="id">
        <Input allowClear placeholder={t("common:sid")} />
      </Form.Item> */}
      {/* <ProductCategoryFormSelect
        placeholder={t("product:category_id")}
        name="category_id"
        localOnly={false}
        popupMatchSelectWidth={false}
      /> */}
      <ProductCollectionFormSelectFilter
        existValue={filters.collection_id}
        changeDataProductCollection={() => {}}
        required={false}
      />
      <SupplierFormSelect
        name="supplier_id"
        placeholder={t("product:supplier")}
        popupMatchSelectWidth={false}
      />
      <StoreFormSelectNormal
      placeholder="Chọn cửa hàng"

        name="store_id"
        popupMatchSelectWidth={false}
        options={[{label : "Toàn bộ" , value : 0},...list_store]}
      />
      <FormSelect
        name="status"
        options={ProductModel.getStatusList()}
        allowClear
        placeholder={t("common:status")}
        popupMatchSelectWidth={false}
      />
      <StoreFormSelectNormal
      placeholder="Phân loại (tag)"
        name="tags_code"
        popupMatchSelectWidth={false}
        options={listTags}
      />
    </PageDataFilterForm>
  );
};

export default ProductListFilter;
