import { CloseCircleOutlined , EyeFilled } from "@ant-design/icons";


import { Button, Image, Popconfirm, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FileJson } from "common/types/File";
import FileModel from "common/models/FileModel";
type Props = {
  handleDeleteImage: (id: number) => void;
  modelImage: FileJson;
};
const ProcessImage = ({ handleDeleteImage, modelImage }: Props) => {
  const { t } = useTranslation();

  //////////////////////////////
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  //////////////////////////////
  const handleDeleteImages = (id: number) => {
    setLoading(true);
    setTimeout(() => {
      handleDeleteImage && handleDeleteImage(id);
      setLoading(false);
      setOpen(false);
    }, 2000);
  };
  return (
    <div className="relative">
      <span className="absolute t-0 right-0 transform  -translate-y-6 z-20 cursor-pointer">
        <Popconfirm
          trigger={["click"]}
          title={t("Xác nhận xóa ảnh này")}
          placement={"topLeft"}
          onConfirm={() => handleDeleteImages(modelImage.id)}
          okText={t("common:table.confirm_ok")}
          cancelText={t("common:table.confirm_cancel")}
          okButtonProps={{ loading: loading }}
          onCancel={() => {
            setOpen(false);
          }}
          open={open}
          // disabled={processing || disabled}
        >
          {/* {processing ? <Spin size="small" /> : comButton} */}
          {/* <Spin size="small" /> */}
          {/* <IconTrash
                              className=" text-gray-400 hover:text-red-500"
                              size="18"
                            /> */}
          <CloseCircleOutlined
            onClick={() => {
              setOpen(true);
            }}
            style={{ color: "red", fontSize: "12pt" }}
          />
        </Popconfirm>
      </span>
      <Image
        className="rounded-md border border-gray-300 object-cover"
        width={120}
        height={120}
        src={FileModel.getThumbnailFromUrl(
          modelImage.file_path,
          1080,
          756,
          "resize"
        )}
        preview={{
          mask: (
            <>
              <Tooltip title={"Xem"}>
                <Button
                  size="small"
                  type="text"
                  icon={
                    <EyeFilled
                      // size="16"
                      // color="white"
                      // style={{ marginRight: 5 }}
                    />
                  }
                />
              </Tooltip>
            </>
          ),
        }}
      />
    </div>
  );
};

export default ProcessImage;
