import {
	Button,
	Col,
	FormInstance,
	Modal,
	Popover,
	Row,
	Table,
	Tag,
	Typography,
} from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import ShippingBillModel from "common/models/ShippingBillModel";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import ShippingBillRepository from "common/repositories/ShippingBillRepository";
import { ShippingBillJson } from "common/types/ShippingBill";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import TextDateTime from "components/TextDateTime";
import TextWarehouse from "components/TextWarehouse";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import { IconLink, IconRefresh, IconSquarePlus } from "@tabler/icons-react";

import OrderFormSectionProductReceiptLinkForm from "./OrderFormSectionProductReceiptLinkForm";
import OrderFormSectionInventoryReceiptItem from "./OrderFormSectionInventoryReceiptItem";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";
import InventoryReceiptForm from "features/inventoryreceipt/form/InventoryReceiptForm";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import Order from "common/constants/Order";
import TableInfo from "components/table/TableInfo";
import { InventoryReceiptJson } from "common/types/InventoryResceipt";
import InventoryReceiptTarget from "../inventoryReceipt/InventoryReceiptTarget";
import InventoryReceiptSource from "../inventoryReceipt/InventoryReceiptSource";

const OrderFormSectionProductReceipt = ({
	form,
	allowEditInput,
	isSuccess,
	status,
}: {
	form: FormInstance;
	allowEditInput: boolean;
	isSuccess: boolean;
	status: number;
}) => {
	const { t } = useTranslation();
	const hasRole = useLoginAccountStore((state) => state.hasRole);

	const [itemView, setItemView] = useState<InventoryReceiptModel | null>(null);
	const [openItemView, setOpenItemView] = useState(false);
	const order = useEditingSaleOrderStore((state) => state.order);
	const [dataSource, setDataSource] = useState<InventoryReceiptModel[]>([]);

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);

	const [shippingBills, setShippingBills] = useState<ShippingBillModel[]>([]);

	//get productreceipt of current order
	const fetchData = useCallback(async () => {
		setLoading(true);

		//First, fetch product receipt
		let collection = await new InventoryReceiptRepository().getItems({
			filters: {
				...InventoryReceiptRepository.getDefaultFilters(),
				source_id: +order.id,
				source_type: 1,
			},
		});
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			//Second, fetch ShippingBill
			const shippingBillCollection =
				await new ShippingBillRepository().getItems({
					filters: {
						...ShippingBillRepository.getDefaultFilters(),
						sale_order_id: order.invoice_id,
					},
				});
			setLoading(false);
			if (shippingBillCollection.hasError()) {
				setErrors(shippingBillCollection.error.errors);
			} else {
				//Everything is OK, set data for display
				setDataSource(collection.items);
				setShippingBills(shippingBillCollection.items);
			}
		}
	}, [order.invoice_id]);

	const deleteShippingBillCallback = useCallback(
		(id: number) => {
			setShippingBills(shippingBills.filter((i) => i.id !== id));
		},
		[shippingBills]
	);

	const updateShippingBillCallback = useCallback(
		(item: ShippingBillJson) => {
			const foundIndex = shippingBills.findIndex((i) => i.id === item.id);

			//not found
			if (foundIndex === -1) {
				setShippingBills([...shippingBills, new ShippingBillModel(item)]);
			} else {
				//update
				setShippingBills(
					shippingBills.map((i, index) =>
						index === foundIndex ? new ShippingBillModel(item) : i
					)
				);
			}
		},
		[shippingBills]
	);

	function handlerViewItem(item: InventoryReceiptModel) {
		setItemView(item);
		setOpenItemView(true);
	}

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (isSuccess) {
			fetchData();
		}
	}, [isSuccess]);

	//Table columns
	const columns: TableColumnsType<InventoryReceiptModel> = [
		{
			title: t("productreceipt:id"),
			dataIndex: "id",
			align: "center",
			width: 120,
			render: (_, record) => {
				return (
					<>
						<Typography
							className=" cursor-pointer font-semibold text-blue-500"
							onClick={() => {
								handlerViewItem(record);
							}}>
							{record.id}
						</Typography>

						{/* <TableLinkText
							link={"/inventoryreceipt/edit/id/" + record.id}
							target="_blank"
							className="font-bold text-md">
							{record.id}
						</TableLinkText> */}
					</>
				);
			},
		},
		{
			title: t("order:inventoryreceipt.direction"),
			dataIndex: "direction",
			sortby: "direction",
			render: (direction: number) => {
				let directionInfo = InventoryReceiptModel.getDirectionLabel(direction);
				return <Tag color={directionInfo?.color}>{directionInfo?.label}</Tag>;
			},
			width: 120,
		},
		{
			title: t("common:status"),
			dataIndex: "status",
			sortby: "status",
			render: (status: number) => {
				let statusInfo = InventoryReceiptModel.getStatus(status);
				return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
			},
			width: 120,
		},
		{
			title: t("productreceipt:warehouse_id"),
			dataIndex: "warehouse_id",
			width: 150,
			render: (warehouse_id: number) => {
				return <TextWarehouse id={warehouse_id} />;
			},
		},
		{
			title: t("productreceipt:source"),
			dataIndex: "source",
			width: 150,
			render: (source: InventoryReceiptJson["source"]) => {
				return (
					<InventoryReceiptSource source={source}></InventoryReceiptSource>
				);
			},
		},
		{
			title: t("productreceipt:target"),
			dataIndex: "target",
			width: 150,
			render: (target: InventoryReceiptJson["target"]) => {
				return (
					<InventoryReceiptTarget target={target}></InventoryReceiptTarget>
				);
			},
		},

		{
			title: t("order:inventoryreceipt.delivery_column"),
			dataIndex: "id",
			render: (id: number, record) => {
				return (
					<>
						<OrderFormSectionInventoryReceiptItem
							inventory_receipt={record.toJson()}
							form={form}
							allowEditInput={
								allowEditInput
							}></OrderFormSectionInventoryReceiptItem>
						{/* <OrderFormSectionProductReceiptItem
              form={form}
              product_receipt={record.toJson()}
              deleteCallback={deleteShippingBillCallback}
              updateCallback={updateShippingBillCallback}
              shipping_bill={
                shippingBills
                  .find((i) => i.product_receipt_id === id)
                  ?.toJson() || ShippingBillModel.getDefaultData()
              }
              key={id}
              allowEditInput={allowEditInput}
            /> */}
					</>
				);
			},
		},

		{
			dataIndex: "date_created",
			align: "end",
			render: (_, record) => {
				return <TableInfo record={record}></TableInfo>;
			},
		},
	];

	return (
		<div className="mt-8">
			<Row className="bg-gray-100 p-2">
				<Col
					span={3}
					className="text-xl text-gray-600 font-extrabold uppercase text-right px-2 bg-gray-100">
					{t("order:form.section_receiptprefix")}
					<br />
					{/* {t("order:form.section_productreceipt_3")}- */}
					{t("order:form.section_inventoryreceipt")}
				</Col>
				<Col span={21}>
					{errors.length > 0 ? (
						<ErrorAlert
							items={errors}
							heading={t("common:error.error_fetching_data")}
							onRetry={() => {
								setErrors([]);
								fetchData();
							}}
							translate_prefix="order:form.error"
						/>
					) : null}

					{!loading && dataSource.length === 0 ? (
						<div className="italic">{t("order:product_receipt_empty")}</div>
					) : null}

					<div>
						<Table<InventoryReceiptModel>
							className="border"
							rowKey={"id"}
							pagination={{
								pageSize: 100,
								hideOnSinglePage: true,
							}}
							columns={columns}
							dataSource={dataSource}
							loading={loading}
						/>

						<div className="my-2">
							<Button
								onClick={() => fetchData()}
								icon={<IconRefresh size={16} className="mr-1" />}
								className="opacity-40 hover:opacity-80"
								size="small"
								type="text">
								{t("order:form.refresh_receipt")}
							</Button>
							{status !== Order.STATUS_CANCEL && (
								<>
									<RoleCheck roles={[Role.INVENTORY_ADD]}>
										<Link
											to={
												"/productreceipt/add/direction/" +
												ProductReceipt.DIRECTION_INPUT +
												"/sale_order_id/" +
												order.id
											}
											target="_blank">
											<Button
												icon={
													<IconSquarePlus size={16} className="mr-1 -mt-0.5" />
												}
												className="opacity-40 hover:opacity-80"
												size="small"
												type="text">
												{t("order:form.add_more_inventoryreceipt_in")}
											</Button>
										</Link>
									</RoleCheck>
									<RoleCheck roles={[Role.INVENTORY_ADD]}>
										<Link
											to={
												"/productreceipt/add/direction/" +
												ProductReceipt.DIRECTION_OUTPUT +
												"/sale_order_id/" +
												order.id
											}
											target="_blank">
											<Button
												icon={
													<IconSquarePlus size={16} className="mr-1 -mt-0.5" />
												}
												className="opacity-40 hover:opacity-80"
												size="small"
												type="text">
												{t("order:form.add_more_inventoryreceipt_out")}
											</Button>
										</Link>
									</RoleCheck>
								</>
							)}

							<Popover
								title={t("order:form.link_receipt.title")}
								content={
									<OrderFormSectionProductReceiptLinkForm
										onSuccess={() => fetchData()}
									/>
								}
								placement="rightTop"
								trigger={["click"]}>
								<Button
									icon={<IconLink size={16} className="mr-1 -mt-0.5" />}
									className="opacity-40 hover:opacity-80"
									size="small"
									type="text">
									{t("order:form.link_receipt.button")}
								</Button>
							</Popover>
						</div>
					</div>
				</Col>
			</Row>

			<Modal
				className="min-w-[80vw]"
				open={openItemView}
				onCancel={() => setOpenItemView(false)}
				bodyStyle={{ maxHeight: 600, overflow: "auto" }}
				footer={
					<div className=" flex gap-4  justify-between">
						<RoleCheck roles={[Role.INVENTORY_STATUS_APPROVE]} hideOnFail>
							<Link
								target="_blank"
								to={`/inventoryreceipt/edit/id/${itemView?.id}`}>
								<Button type="link" htmlType="button" color="blue">
									Cập nhập phiếu #{itemView?.id}
								</Button>
							</Link>
						</RoleCheck>

						<Button onClick={() => setOpenItemView(false)}>Đóng</Button>
					</div>
				}>
				{itemView && (
					<InventoryReceiptForm
						disable={!hasRole(Role.INVENTORY_ADD)}
						model={itemView}
						refetch={() => {
							fetchData();
							setOpenItemView(false);
						}}
						// disable={hasRole(Role.INV)}
					/>
				)}
			</Modal>
		</div>
	);
};

export default OrderFormSectionProductReceipt;
