import CmsNewsCategory from "common/constants/CmsNewsCategory";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

import type { CmsNewsCategoryJson } from "common/types/CmsNewsCategory";
class CmsNewsCategoryModel
  extends BaseModel
  implements BaseModelJson<CmsNewsCategoryJson>
{
  creator_id: number;
  company_id: number;
  id: number;
  name: string;
  parent_id: number;
  seo_slug: string;
  seo_title: string;
  seo_keyword: string;
  seo_description: string;
  graph_image_id: number;
  graph_image: FileModel;
  display_order: number;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: CmsNewsCategoryJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.parent_id = json.parent_id || 0;
    this.seo_slug = json.seo_slug || "";
    this.seo_title = json.seo_title || "";
    this.seo_keyword = json.seo_keyword || "";
    this.seo_description = json.seo_description || "";
    this.graph_image_id = json.graph_image_id || 0;
    this.graph_image = new FileModel(
      json.graph_image || FileModel.getDefaultData()
    );
    this.display_order = json.display_order || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): CmsNewsCategoryJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      name: "",
      parent_id: 0,
      seo_slug: "",
      seo_title: " ",
      seo_keyword: "",
      seo_description: "",
      graph_image_id: 0,
      graph_image: new FileModel(FileModel.getDefaultData()),
      display_order: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): CmsNewsCategoryJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      name: this.name,
      parent_id: this.parent_id,
      seo_slug: this.seo_slug,
      seo_title: this.seo_title,
      seo_keyword: this.seo_keyword,
      seo_description: this.seo_description,
      graph_image_id: this.graph_image_id,
      graph_image: this.graph_image.toJson(),
      display_order: this.display_order,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: CmsNewsCategory.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: CmsNewsCategory.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default CmsNewsCategoryModel;
