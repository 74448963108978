import { Form, FormInstance, Input, InputNumber } from "antd";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import MoneyInput from "components/MoneyInput";
import SimpleCollapse from "components/SimpleCollapse";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconCopy } from "@tabler/icons-react";

const ShippingOrderFormSectionWhat = ({
  form,
  model
}: {
  form: FormInstance;
  model: ShippingOrderModel;
}) => {
  const { t } = useTranslation();
  const [priceFinal, setPriceFinal] = useState<number>(model.cod);
  const onClickCopyOrderPriceFinal = useCallback(
    (e: any) => {
      e.preventDefault();
      form.setFieldValue("cod", priceFinal);
    },
    [form, priceFinal]
  );

  return (
    <>
      <SimpleCollapse
        header={t("shippingorder:orderform_heading_what")}
        defaultOpen={true}
        className="mb-4"
        childrenItem={
          <>
            {" "}
            <Form.Item name="invoice_id">
              <Input
                addonBefore={t("shippingorder:invoice_id")}
                placeholder={t("shippingorder:invoice_id_placeholder")}
              />
            </Form.Item>
            <Form.Item name="order_price_final">
              <MoneyInput
                addonBefore={t("shippingorder:order_price_final")}
                style={{ width: "100%" }}
                onChange={(value: number | null) => setPriceFinal(value || 0)}
              />
            </Form.Item>
            <Form.Item name="cod">
              <MoneyInput
                addonBefore={t("shippingorder:cod")}
                style={{ width: "100%" }}
                addonAfter={
                  <IconCopy
                    className="cursor-pointer"
                    size={16}
                    onClick={onClickCopyOrderPriceFinal}
                  />
                }
              />
            </Form.Item>
            <Form.Item name="order_weight">
              <Input
                addonBefore={t("shippingorder:order_weight")}
                addonAfter={"gram"}
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  {t("shippingorder:order_dimension")}
                  &nbsp;
                  {"("}
                  {t("shippingorder:order_length")}
                  &nbsp;&times;&nbsp;
                  {t("shippingorder:order_width")}
                  &nbsp;&times;&nbsp;
                  {t("shippingorder:order_height")}
                  {")"}
                </>
              }
              className="mb-0">
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(33% - 20px)"
                }}
                name="order_length">
                <InputNumber
                  min={10}
                  step={5}
                  controls={false}
                  addonAfter="cm"
                />
              </Form.Item>
              <span
                style={{
                  display: "inline-block",
                  width: "28px",
                  lineHeight: "32px",
                  textAlign: "center"
                }}>
                &nbsp;&times;&nbsp;
              </span>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(33% - 20px)"
                }}
                name="order_width">
                <InputNumber
                  min={10}
                  step={5}
                  controls={false}
                  addonAfter="cm"
                />
              </Form.Item>
              <span
                style={{
                  display: "inline-block",
                  width: "28px",
                  lineHeight: "32px",
                  textAlign: "center"
                }}>
                &nbsp;&times;&nbsp;
              </span>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(33% - 20px)"
                }}
                name="order_height">
                <InputNumber
                  min={10}
                  step={5}
                  controls={false}
                  addonAfter="cm"
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label={t("shippingorder:order_note")}
              name="order_note"
              className="mb-0">
              <Input.TextArea
                rows={3}
                placeholder={t("shippingorder:order_note_placeholder")}
              />
            </Form.Item>
          </>
        }
      />
    </>
  );
};

export default ShippingOrderFormSectionWhat;
