import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";

const ProductInventoryCheckingUserListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("productinventorychecking:heading_list")}
      siteMenuSelectedKey="/productinventorycheckinguser">
      <RoleCheck roles={[Role.INVENTORY_STOCK_CHECK]}>
    

        <PageHeaderButton link={"/productinventorychecking/add"}>
          {t("productinventorychecking:add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default ProductInventoryCheckingUserListHeader;
