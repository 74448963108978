import ITRequestModel from "common/models/ITRequestModel";
import ITRequestRepository from "common/repositories/ITRequestRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import ITRequestFormHeader from "features/itrequest/form/ITRequestFormHeader";
import ITRequestForm from "features/itrequest/form/ITRequestForm";
import ITRequestList from "features/itrequest/list/ITRequestList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const ITRequestPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new ITRequestModel(ITRequestModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new ITRequestModel(ITRequestModel.getDefaultData()));
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    case "add":
      com = <ITRequestForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ITRequestForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new ITRequestRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<ITRequestFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    default:
      com = <ITRequestList />;
      break;
  }
  return com;
};

export default ITRequestPage;
