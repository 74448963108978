import { PlusOutlined } from "@ant-design/icons";
import { IconPhoto, IconTrash, IconX } from "@tabler/icons-react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Typography,
} from "antd";
import FileModel from "common/models/FileModel";
import { ShopFooterJson } from "common/types/ShopFooter";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ShopFooterForm({
  data,
  open,
  type = "add",
  onFinish,
  onCancel,
}: {
  type: "add" | "edit";
  data: ShopFooterJson;
  open: boolean;
  onFinish: (data: ShopFooterJson) => void;
  onCancel: () => void;
}) {
  console.log("🥺 ~ data:", data);
  const { t } = useTranslation();

  const [form] = Form.useForm<ShopFooterJson>();

  const [listImageIcon, setListImageIcon] = useState<string[]>([]);
  console.log("🥺 ~ listImageIcon:", listImageIcon);

  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    menu: data.menu,
  };

  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: any = {
        // ...data,
        title: data.title,
        menu: formData.menu ? formData.menu : [],
      };

      return submitData;
    },
    [data]
  );

  const onSubmit = async (formData: any) => {
    console.log("🥺 ~ onSubmit ~ formData:", formData);
    console.log("🥺 ~ onSubmit ~ formData:", doPrepareData(formData));
    setLoading(true);
    await onFinish({
      ...data,
      ...doPrepareData(formData),
    });

    setLoading(false);
  };

  const handleOnClose = () => {
    form.resetFields();

    onCancel();
  };

  useEffect(() => {
    if (data.menu.length > 0) {
      let arrayIcon: string[] = [];
      for (let i = 0; i < data.menu.length; i++) {
        if (data.menu[i].icon && data.menu[i].icon.length > 0) {
          arrayIcon.push(data.menu[i].icon);
        } else {
          // arrayIcon.push("none");
        }
      }
      setListImageIcon(arrayIcon);
      console.log("🥺 ~ arrayIcon ~ arrayIcon:", arrayIcon);
    } else {
      setListImageIcon([]);
    }

    //
    form.setFieldsValue({
      ...(initialValues as any),
    });
  }, [data, form]);

  return (
    <Modal
      title={data.title}
      open={open}
      footer={<></>}
      onCancel={() => {
        handleOnClose();
      }}
      maskClosable={false}
      width={800}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      destroyOnClose
    >
      <>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.List name="menu" initialValue={data.menu}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key}>
                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          {...restField}
                          label={
                            <span className="flex items-center">
                              {t("Tên ")}
                              {name + 1}
                              <IconX
                                onClick={() => remove(name)}
                                className="p-1 cursor-pointer hover:text-red-500"
                              />
                            </span>
                          }
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: t("Bạn chưa nhập thông tin"),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          {...restField}
                          label={
                            <span>
                              {t("Link ")}
                              {name + 1}
                            </span>
                          }
                          name={[name, "link"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* {listImageIcon.length > 0 && (
                      <Row>
                        {listImageIcon[key].length > 0 ? (
                          <>
                            <div className="flex">
                              <div className="bg-gray-500">
                                <Image
                                  className="cursor-pointer"
                                  src={data.menu[key].icon}
                                  width={24}
                                  height={24}
                                  preview={true}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                              </div>
                              <Popconfirm
                                title="Bạn có muốn xóa ảnh"
                                onConfirm={() => {
                                  let cloneArray = [...listImageIcon];
                                  cloneArray[key] = "";
                                  setListImageIcon(cloneArray);
                                  // form.setFieldValue("image", []);
                                }}
                              >
                                <Button
                                  icon={
                                    <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                                  }
                                ></Button>
                              </Popconfirm>
                            </div>
                          </>
                        ) : (
                          <>
                            <Form.Item className="m-0">
                              <FileUploaderButton
                                form={form}
                                // formName={`icon_${key}`}
                                label={t("Ảnh")}
                                icon={<IconPhoto size="24" color="grey" />}
                                origin="avatar"
                                uploadRenderType="thumbnail"
                                isSingleFile={true}
                                maxCount={1}
                                initFiles={[]}
                                onUploadCompleted={(f) => {
                                  let cloneArray = [...listImageIcon];
                                  cloneArray[key] = f.url;
                                  setListImageIcon(cloneArray);
                                }}
                              />
                            </Form.Item>
                          </>
                        )}
                      </Row>
                    )} */}
                  </div>
                ))}
                {fields.length < 20 ? (
                  <Row gutter={16}>
                    <Col md={11}>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Thêm
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            )}
          </Form.List>
          <Form.Item className="mt-4">
            <div className="flex gap-4">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Hoàn tất
              </Button>
              <Button
                type="default"
                onClick={() => {
                  handleOnClose();
                }}
              >
                Đóng
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
}
