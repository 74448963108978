import { Skeleton, Typography } from "antd";
import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import React, { useEffect, useMemo, useState } from "react";
import {
  IconBrandDaysCounter,
  IconChartInfographic,
} from "@tabler/icons-react";

import { ReportLeaveJson, FilterReportLeave } from "common/types/ReportLeave";
import ReportLeaveRepository from "common/repositories/ReportLeaveRepository";

import HomeLeftListWrapper from "../HomeLeftListWrapper";

type Item = {
  title: string;
  value: number;
};
type Data = Item & {
  loading?: boolean;
};
const HomeLeftLeaveUser = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  //////////////////////////////
  //state
  const [dataSource, setDataSource] = useState<Data[]>(
    Array.from({ length: 6 }).map((item, index) => ({
      title: `${index}`,
      value: 0,
      loading: false,
    }))
  );
  const defaultFilters = useMemo(() => {
    return ReportLeaveRepository.getDefaultFilters();
  }, []);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  ////////////////////////
  //fetch data
  const fetchData = async () => {
    const response = await new ReportLeaveRepository().getItemsUser({
      filters: filters,
    });

    if (!response.hasError()) {
      if (response.items.length >= 1) {
        const resData: Pick<ReportLeaveJson, "sum" | "unpaid_leave" | "paid_leave" | "insurance_leave"> = {
          insurance_leave: 0,
          paid_leave: 0,
          unpaid_leave: 0,
          sum: 0,
        };
        Object.defineProperties(resData, {
          ncl: {
            value: response.items[0].paid_leave,
          },
          nkl: {
            value: response.items[0].unpaid_leave,
          },
          nbh: {
            value: response.items[0].insurance_leave,
          },
          sum: {
            value: response.items[0].sum,
          },
        });
        const data: Data[] = [];
        Object.entries(resData).forEach(
          ([key, value]: [key: string, value: number]) => {
            data.push({
              title: `${t(`homepage:left.leave_analysiss.${key}`)} ${
                key === "sum" ? response.items[0].month : ""
              }`,
              value: value,
            });
          }
        );
        setDataSource(data);
      } else {
        setDataSource([]);
      }
    } else {
      setErrors(response.error.errors);
    }
    setLoading(false);
  };

  ///////////////////////
  useEffect(() => {
    fetchData();
  }, []);

  //////////////////////

  return (
    <div>
      <HomeLeftListWrapper<FilterReportLeave, Data>
        {...{
          filters,
          setFilters,
          dataSource,
          errors,
          iconTitle: <IconChartInfographic size={18} />,
          loading: loading,
          title: t("common:home.leave_user"),

          renderItem(item) {
            return (
              <Skeleton
                className="px-2"
                loading={item.loading}
                active
                avatar
                title={false}
                round
              >
                <div className="flex flex-col justify-between gap-1 border-[1px] border-gray-200 p-2 my-2 cursor-pointer">
                  <Text className="text-gray-400">{item.title}</Text>
                  <div className="flex justify-start items-center gap-2">
                    <IconBrandDaysCounter size={16} />
                    <h4 className="m-0">{item.value} ngày</h4>
                  </div>
                </div>
              </Skeleton>
            );
          },
        }}
      ></HomeLeftListWrapper>
    </div>
  );
};

export default HomeLeftLeaveUser;
