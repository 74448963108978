import {
  BoardType,
  CardBag,
  CardType,
  ColumnType,
  DestinationType,
  SourceType,
  moveCard,
} from "@asseinfo/react-kanban";
import {
  IconChevronLeft,
  IconChevronRight,
  IconDotsVertical,
  IconInfoCircle,
  IconArrowDown,
  IconArrowUp,
} from "@tabler/icons-react";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Dropdown,
  Menu,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import TaskCollection from "common/collections/TaskCollection";
import Task from "common/constants/Task";
import TaskModel from "common/models/TaskModel";
import TaskRepository from "common/repositories/TaskRepository";
import { FilterTask } from "common/types/Task";
import TextDateTime from "components/TextDateTime";
import Kanban from "components/kanban/Kanban";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import "../components/TaskKanban.css";
import TableDelete from "components/table/TableDelete";
import TaskKanbanListHeader from "./TaskKanbanListHeader";
import TaskFormModal from "../components/TaskFormModal";
import TaskKanbanListFilter from "./TaskKanbanListFilter";

const TaskKanbanList = () => {
  const { t } = useTranslation();
  const intialBoard: BoardType = {
    columns: [
      // {
      //   id: 5,
      //   title: "BACKLOG",
      //   cards: [],
      // },
      {
        id: 0,
        title: "TODO",
        cards: [],
      },
      {
        id: 2,
        title: "INPROGRESS",
        cards: [],
      },
      {
        id: 1,
        title: "PENDING",
        cards: [],
      },
      {
        id: 3,
        title: "DONE",
        cards: [],
      },
    ],
  };

  const [board, setBoard] = useState<BoardType>(intialBoard);

  //state
  const [loading, setLoading] = useState<boolean>(false);

  const [currentColumn, setCurrentColumn] = useState<number[]>([-1, -1]);

  //filters
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const defaultFilters: FilterTask = useMemo(() => {
    return {
      ...TaskRepository.getDefaultFilter(),
      parent_id: -1,
    };
  }, []);
  const [filters, setFilters] = useStateFilter<FilterTask>(defaultFilters);

  const [filtersTodo, setFiltersTodo] =
    useStateFilter<FilterTask>(defaultFilters);
  const [filtersInprogress, setFiltersInProgress] =
    useStateFilter<FilterTask>(defaultFilters);
  const [filtersDone, setFiltersDone] =
    useStateFilter<FilterTask>(defaultFilters);
  const [filtersPending, setFiltersPending] =
    useStateFilter<FilterTask>(defaultFilters);

  // useEffect(() => {
  //   setFiltersTodo({ ...filters });
  //   setFiltersInProgress({ ...filters });
  //   setFiltersPending({ ...filters });
  //   setFiltersDone({ ...filters });
  // }, [filters]);

  useEffect(() => {
    if (!firstRender) {
      setFiltersTodo({ ...filters });
      setFiltersInProgress({ ...filters });
      setFiltersPending({ ...filters });
      setFiltersDone({ ...filters });
    } else {
      // Lần thay đổi đầu tiên, không thực hiện đoạn mã này
      setFirstRender(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchDataTodo();
  }, [filtersTodo]);

  useEffect(() => {
    fetchDataInprogress();
  }, [filtersInprogress]);

  useEffect(() => {
    fetchDataDone();
  }, [filtersDone]);

  useEffect(() => {
    fetchDataPending();
  }, [filtersPending]);

  //BACKLOG
  // const [dataSourceBacklog, setDataSourceBacklog] = useState<CardType[]>([]);

  // const fetchDataBacklog = useCallback(async (): Promise<TaskCollection> => {
  //   setLoading(true);
  //   let collection = await new TaskRepository().getItemsUser({
  //     filters: {
  //       ...filters,
  //       limit: 500,
  //       sortby: "my_display_order",
  //       sorttype: "DESC",
  //       status: Task.STATUS.BACKLOG,
  //     },
  //   });

  //   if (collection.error.errors.length > 0) {
  //     setLoading(false);

  //     message.open({
  //       type: "error",
  //       content: "Có lỗi rồi !",
  //     });
  //   } else {
  //     const newArr = collection.items.map((item) => ({
  //       id: item.id,
  //       title: item.name,
  //       description: item.parent_id.toString(),
  //       display_order: item.display_order,
  //       task_model: item,
  //     }));
  //     const updatedBoard = updateTodoColumn("BACKLOG", board, newArr);
  //     setDataSourceBacklog(newArr);
  //     setBoard(updatedBoard);
  //     setLoading(false);
  //   }

  //   return collection;
  // }, [filters]);

  //TODO
  const [dataSourceTodo, setDataSourceTodo] = useState<CardType[]>([]);
  const [endPageTodo, setEndPageTodo] = useState<boolean>(false);
  const [totalTodo, setTotalTodo] = useState<number>(0);

  const fetchDataTodo = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);
    let collection = await new TaskRepository().getItemsUser({
      filters: {
        ...filtersTodo,
        limit: 20,
        sortby: "my_display_order",
        sorttype: "ASC",
        status: Task.STATUS.TODO,
        parent_id: undefined,
        no_parent: 1,
      },
    });

    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("TODO", board, newArr);
      setDataSourceTodo(newArr);
      setTotalTodo(collection.total);
      setEndPageTodo(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersTodo]);

  //DONE
  const [dataSourceDone, setDataSourceDone] = useState<CardType[]>([]);
  const [endPageDone, setEndPageDone] = useState<boolean>(false);
  const [totalDone, setTotalDone] = useState<number>(0);

  const fetchDataDone = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);

    let collection = await new TaskRepository().getItemsUser({
      filters: {
        ...filtersDone,
        limit: 20,
        sortby: "my_display_order",
        sorttype: "ASC",
        status: Task.STATUS.DONE,
        parent_id: undefined,
        no_parent: 1,
      },
    });
    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("DONE", board, newArr);
      setDataSourceDone(newArr);
      setEndPageDone(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setTotalDone(collection.total);
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersDone]);

  //Pending
  const [dataSourcePending, setDataSourcePending] = useState<CardType[]>([]);
  const [endPagePending, setEndPagePending] = useState<boolean>(false);
  const [totalPending, setTotalPending] = useState<number>(0);

  const fetchDataPending = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);

    let collection = await new TaskRepository().getItemsUser({
      filters: {
        ...filtersPending,
        limit: 20,
        sortby: "my_display_order",
        sorttype: "ASC",
        status: Task.STATUS.PENDING,
        parent_id: undefined,
        no_parent: 1,
      },
    });
    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("PENDING", board, newArr);
      setDataSourcePending(newArr);
      setEndPagePending(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setTotalPending(collection.total);
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersPending]);

  //Inprogress
  const [dataSourceInprogress, setDataSourceInprogess] = useState<CardType[]>(
    []
  );
  const [endPageInprogress, setEndPageInprogress] = useState<boolean>(false);
  const [totalInprogress, setTotalInprogress] = useState<number>(0);

  const fetchDataInprogress = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);
    let collection = await new TaskRepository().getItemsUser({
      filters: {
        ...filtersInprogress,
        limit: 20,
        sortby: "my_display_order",
        sorttype: "ASC",
        status: Task.STATUS.INPROGRESS,
        parent_id: undefined,
        no_parent: 1,
      },
    });
    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("INPROGRESS", board, newArr);
      setDataSourceInprogess(newArr);
      setEndPageInprogress(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setTotalInprogress(collection.total);
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersInprogress]);

  const updateTodoColumn = (
    columnName: string,
    board: BoardType,
    dataSourceTodo: CardType[]
  ) => {
    const newBoard = { ...board };

    const todoColumnIndex = newBoard.columns.findIndex(
      (column) => column.title === columnName
    );

    if (todoColumnIndex !== -1) {
      const updatedTodoColumn = { ...newBoard.columns[todoColumnIndex] };

      updatedTodoColumn.cards = dataSourceTodo;

      newBoard.columns[todoColumnIndex] = updatedTodoColumn;
    }

    return newBoard;
  };

  const fetchData = (columnID: number) => {
    if (columnID === Task.STATUS.TODO) {
      fetchDataTodo();
    } else if (columnID === Task.STATUS.PENDING) {
      fetchDataPending();
    } else if (columnID === Task.STATUS.DONE) {
      fetchDataDone();
    } else if (columnID === Task.STATUS.INPROGRESS) {
      fetchDataInprogress();
    } else if (columnID === Task.STATUS.BACKLOG) {
      // fetchDataBacklog();
    }
  };

  const handleCardMove = async (
    card: any,
    source: SourceType,
    destination: DestinationType
  ) => {
    setLoading(true);
    const { card1, card2 } = findItemSwap(source, destination);

    if (card1 && card2) {
      const updatedBoard = moveCard(board, source, destination);
      setBoard(updatedBoard);

      if (source.fromColumnId !== destination.toColumnId) {
        const myObj: TaskModel = await new TaskRepository().editKanbanPosition(
          card.id,
          destination.toColumnId,
          card2.task_model?.my_display_order || 0
        );
        if (!myObj.hasError()) {
          message.success({
            content: "Thay đổi thành công !",
            className: "message_success",
            key: "message",
            duration: 2,
          });
          fetchDataAll();
        } else {
          // const updatedBoard = moveCard(
          //   board,
          //   {
          //     fromColumnId: destination.toColumnId,
          //     fromPosition: destination.toPosition,
          //   },
          //   { toColumnId: source.fromColumnId, toPosition: source.fromPosition }
          // );
          // setBoard(updatedBoard);
          fetchDataAll();
          message.error({
            content: t(`task:form.error.${myObj.error.errors[0]}`),
            className: "message_error",
            key: "message",
            duration: 4,
          });
        }
      } else {
        const updatedBoard = moveCard(board, source, destination);
        setBoard(updatedBoard);
        await new TaskRepository()
          .editKanbanDisplayOrder(
            card1.id,
            card2.task_model?.my_display_order || 0
          )
          .then((res) => {
            if (res.hasError()) {
              // const updatedBoard = moveCard(
              //   board,
              //   {
              //     fromColumnId: destination.toColumnId,
              //     fromPosition: destination.toPosition,
              //   },
              //   {
              //     toColumnId: source.fromColumnId,
              //     toPosition: source.fromPosition,
              //   }
              // );
              // setBoard(updatedBoard);
              fetchDataAll();
              message.error({
                content: t(`task:form.error.${res.error.errors[0]}`),
                className: "message_error",
                key: "message",
                duration: 4,
              });
            } else {
              message.success({
                content: "Thay đổi thành công !",
                className: "message_success",
                key: "message",
                duration: 2,
              });

              fetchDataAll();
            }
          })
          .catch((err) => {
            message.error({
              content: t(`task:form.error.${err[0]}`),
              className: "message_error",
              key: "message",
              duration: 4,
            });
            fetchDataAll();
            // const updatedBoard = moveCard(
            //   board,
            //   {
            //     fromColumnId: destination.toColumnId,
            //     fromPosition: destination.toPosition,
            //   },
            //   {
            //     toColumnId: source.fromColumnId,
            //     toPosition: source.fromPosition,
            //   }
            // );
            // setBoard(updatedBoard);
          });
      }
    } else if (card1) {
      const updatedBoard = moveCard(board, source, destination);
      setBoard(updatedBoard);

      if (source.fromColumnId !== destination.toColumnId) {
        const myObj: TaskModel = await new TaskRepository().editStatus(
          destination.toColumnId,
          card.id
        );
        if (!myObj.hasError()) {
          message.success({
            content: "Thay đổi thành công !",
            className: "message_success",
            key: "message",
            duration: 2,
          });

          // const updatedBoard = moveCard(board, source, destination);
          // setBoard(updatedBoard);
          fetchDataAll();
        } else {
          message.error({
            content: t(`task:form.error.${myObj.error.errors[0]}`),
            className: "message_error",
            key: "message",
            duration: 4,
          });
          fetchDataAll();
          // const updatedBoard = moveCard(
          //   board,
          //   {
          //     fromColumnId: destination.toColumnId,
          //     fromPosition: destination.toPosition,
          //   },
          //   { toColumnId: source.fromColumnId, toPosition: source.fromPosition }
          // );
          // setBoard(updatedBoard);
        }
      }
    } else {
      message.error({
        content: t(`Thay đổi thất bại`),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    }
    setLoading(false);
  };

  const findItemSwap = (source: SourceType, destination: DestinationType) => {
    const columnId1 = source.fromColumnId;
    const columnId2 = destination.toColumnId;

    const newIndexInColumn1 = source.fromPosition;
    const newIndexInColumn2 = destination.toPosition;

    const column1 = board.columns.find((column) => column.id === columnId1);
    const column2 = board.columns.find((column) => column.id === columnId2);

    if (column1 && column2) {
      const card1 = column1.cards[newIndexInColumn1];
      const card2 = column2.cards[newIndexInColumn2];

      return { card1: card1, card2: card2 };
    } else {
      return { card1: undefined, card2: undefined };
    }
  };

  //TaskModal
  //setId for Modal
  const [showModal, setShowModal] = useState(false);
  const [taskId, setTaskId] = useState<number>(0);
  const handleClickCard = (id: number) => {
    setTaskId(id);
  };

  const onSaveSuccess = (item: TaskModel) => {
    fetchDataAll();
  };

  const fetchDataAll = () => {
    fetchDataTodo();
    fetchDataDone();
    fetchDataInprogress();
    fetchDataPending();
  };

  const renderColor = (status: number) => {
    let color = "#87e8de";
    switch (status) {
      case 0:
        color = "#87e8de";
        break;
      case 1:
        color = "#096dd9";
        break;
      case 2:
        color = "#c41d7f";
        break;
      case 3:
        color = "#389e0d";
        break;

      default:
        break;
    }
    return color;
  };

  const Deviation = ({ deviation }: { deviation: number }) => {
    const day = Math.floor(Math.abs(deviation / (3600 * 24)));
    const houre = Math.floor(Math.abs(deviation / 3600) % 24);
    const minutes = Math.round(
      Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
    );

    const is_deviation = (day || houre || minutes) !== 0;
    return (
      <>
        {is_deviation ? (
          <Tag
            color={deviation > 0 ? "volcano" : "green"}
            style={{ margin: 0, height: "22px" }}
          >
            {day > 0 && day + " ngày "}
            {houre > 0 && houre + " giờ "}
            {minutes > 0 && minutes + " phút"}
            {/* {minutes} */}{" "}
            {is_deviation ? (
              deviation > 0 ? (
                <IconArrowDown size={16} style={{ color: "#cf1322" }} />
              ) : (
                <IconArrowUp size={16} style={{ color: "#3f8600" }} />
              )
            ) : null}
          </Tag>
        ) : (
          <Tag style={{ margin: 0, height: "22px" }}>-</Tag>
        )}
      </>
    );
  };

  const getPagination = (column: ColumnType) => {
    if (column.title === "TODO") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            disabled={filtersTodo.page === 1}
            style={{ height: "22px", width: "32px" }}
            onClick={() => {
              setFiltersTodo({
                ...filtersTodo,
                page: Number(filtersTodo.page) - 1,
              });
            }}
          >
            <IconChevronLeft size={16} />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPageTodo}
            onClick={() => {
              setFiltersTodo({
                ...filtersTodo,
                page: Number(filtersTodo.page) + 1,
              });
            }}
          >
            <IconChevronRight size={16} />
          </Button>
        </div>
      );
    } else if (column.title === "INPROGRESS") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={filtersInprogress.page === 1}
            onClick={() => {
              setFiltersInProgress({
                ...filtersInprogress,
                page: Number(filtersInprogress.page) - 1,
              });
            }}
          >
            <IconChevronLeft size={16} />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPageInprogress}
            onClick={() => {
              setFiltersInProgress({
                ...filtersInprogress,
                page: Number(filtersInprogress.page) + 1,
              });
            }}
          >
            <IconChevronRight size={16} />
          </Button>
        </div>
      );
    } else if (column.title === "PENDING") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={filtersPending.page === 1}
            onClick={() => {
              setFiltersPending({
                ...filtersPending,
                page: Number(filtersPending.page) - 1,
              });
            }}
          >
            <IconChevronLeft size={16} />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPagePending}
            onClick={() => {
              setFiltersPending({
                ...filtersPending,
                page: Number(filtersPending.page) + 1,
              });
            }}
          >
            <IconChevronRight size={16} />
          </Button>
        </div>
      );
    } else if (column.title === "DONE") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={filtersDone.page === 1}
            onClick={() => {
              setFiltersDone({
                ...filtersDone,
                page: Number(filtersDone.page) - 1,
              });
            }}
          >
            <IconChevronLeft size={16} />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPageDone}
            onClick={() => {
              setFiltersDone({
                ...filtersDone,
                page: Number(filtersDone.page) + 1,
              });
            }}
          >
            <IconChevronRight size={16} />
          </Button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getTotalNumber = (column: ColumnType) => {
    if (column.title === "TODO") {
      return totalTodo;
    } else if (column.title === "INPROGRESS") {
      return totalInprogress;
    } else if (column.title === "PENDING") {
      return totalPending;
    } else if (column.title === "DONE") {
      return totalDone;
    } else {
      return totalTodo + totalInprogress + totalPending + totalDone;
    }
  };

  return (
    <>
      <TaskKanbanListHeader />
      <TaskKanbanListFilter
        total={getTotalNumber({
          id: 0,
          title: "",
          cards: [],
        })}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <div className="taskpublicuserkanban__wrapper flex justify-center mt-6 w-100%">
        <Spin spinning={loading} style={{ width:'100%'}}>
          <Kanban
          
            intialBoard={board}
            disableCardDrag={false}
            onCardDragEnd={handleCardMove}
            allowRemoveColumn={false}
            renderColumnHeader={(column: ColumnType) => {
              const color =
                column.title === "PENDING"
                  ? "blue"
                  : column.title === "INPROGRESS"
                  ? "magenta"
                  : column.title === "DONE"
                  ? "green"
                  : column.title === "BLOCK"
                  ? "volcano"
                  : column.title === "TODO"
                  ? "cyan"
                  : "";
              return (
                <div className="flex justify-between mb-2 items-center">
                  <div className="flex">
                    <Badge count={getTotalNumber(column)}>
                      <Tag color={color}>{column.title}</Tag>
                    </Badge>
                  </div>
                  <div>{getPagination(column)}</div>
                </div>
              );
            }}
            renderCard={(content: CardType, cardBag: CardBag) => {
              const menu = (
                <Menu>
                  <Menu.Item key="1" className="">
                    <Button
                      size="small"
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setShowModal(true);
                        handleClickCard(content.id);
                      }}
                    >
                      <div className="h-full w-full flex items-center gap-2">
                        <IconInfoCircle
                          className="-mt-0.5 text-gray-400 hover:text-red-500"
                          size="18"
                        />
                        Chi tiết
                      </div>
                    </Button>
                  </Menu.Item>
                  {content.task_model?.status === Task.STATUS.TODO && (
                    <Menu.Item key="2" className="">
                      <TableDelete
                        error_translate_prefix="task:form.error"
                        onDeleteCallback={(id) => {
                          fetchData(content.task_model?.status || 0);
                        }}
                        repository={new TaskRepository()}
                        id={content.task_model?.id || 0}
                        label="Xóa"
                        className="h-full w-full flex items-center gap-2"
                      />
                    </Menu.Item>
                  )}
                </Menu>
              );
              return (
                <>
                  <Collapse
                    className="kanban__item"
                    destroyInactivePanel
                    key={content.id}
                    collapsible="disabled"
                  >
                    <Collapse.Panel
                      collapsible="disabled"
                      showArrow={false}
                      header={
                        <>
                          {content.task_model && (
                            <div
                              id="currentTask"
                              className="relative"
                              style={{
                                minHeight: "70px",
                                borderLeft: `3px solid ${renderColor(
                                  content.task_model.status
                                )}`,
                                width: "100%",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              <Row
                                gutter={[4, 8]}
                                justify="space-between"
                                align="middle"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  minHeight: 65,
                                }}
                              >
                                <Col span={24}>
                                  <Typography.Title
                                    level={5}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <span style={{ color: "#ec5a00" }}>
                                      {"("}
                                      <TextDateTime
                                        ts={content.task_model.org_fromtime}
                                        format="DD-MM"
                                      />
                                      {") "}
                                    </span>
                                    {content.task_model.name}
                                  </Typography.Title>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 8,
                                  }}
                                >
                                  <Row
                                    gutter={[4, 4]}
                                    style={{ width: "100%" }}
                                  >
                                    <Col
                                    // span={10}
                                    // style={{
                                    //   textAlign: "right",
                                    //   display: "flex",
                                    //   justifyContent: "flex-end",
                                    //   gap: 8,
                                    // }}
                                    >
                                      <Typography.Text strong>
                                        {"NTEAM-" +
                                          content.task_model.id
                                            .toString()
                                            .toUpperCase()}
                                      </Typography.Text>
                                    </Col>
                                  </Row>
                                  <Row
                                    gutter={[4, 4]}
                                    style={{ width: "100%" }}
                                    className="flex justify-between items-center"
                                  >
                                    <Deviation
                                      deviation={content.task_model.deviation}
                                    ></Deviation>
                                    <Dropdown
                                      overlay={menu}
                                      placement="bottomRight"
                                      className="cursor-pointer"
                                    >
                                      <IconDotsVertical size={16} />
                                    </Dropdown>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </>
                      }
                      key={content.id}
                    ></Collapse.Panel>
                  </Collapse>
                </>
              );
            }}
          />
        </Spin>
      </div>

      <TaskFormModal
        id={taskId}
        onSaveSuccess={onSaveSuccess}
        visible={showModal}
        setVisible={(isVisible) => {
          setShowModal(isVisible);
          setTaskId(0);
        }}
      />
    </>
  );
};

export default TaskKanbanList;
