import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { Modal, Skeleton } from "antd";

import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";
import ErrorAlert from "components/ErrorAlert";

import LeaveTypeForm from "./LeaveTypeForm";

type Props = {
  id: number;
  visible: boolean;
  setVisible: (v: boolean) => any;
  onSaveSuccess: (m: LeaveTypeModel) => void;
};

const LeaveTypeFormModal = ({
  id,
  visible,
  setVisible,
  onSaveSuccess,
}: Props) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<LeaveTypeModel>(
    new LeaveTypeModel(LeaveTypeModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new LeaveTypeRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <Modal
      title={id > 0 ? t("leavetype:heading_edit") : t("leavetype:heading_add")}
      width={400}
      open={visible}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        setModel(new LeaveTypeModel(LeaveTypeModel.getDefaultData()));
        setVisible(false);
      }}
      okButtonProps={{ form: "leavetypes-form", htmlType: "submit" }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      confirmLoading={processing}
      destroyOnClose
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <LeaveTypeForm
                  model={model}
                  setVisible={setVisible}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <LeaveTypeForm
          model={new LeaveTypeModel(LeaveTypeModel.getDefaultData())}
          setVisible={setVisible}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </Modal>
  );
};

export default LeaveTypeFormModal;
