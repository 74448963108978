import { Select } from "antd";
import StoreCollection from "common/collections/StoreCollection";
import StoreRepository from "common/repositories/StoreRepository";
import { FilterStore, StoreJson } from "common/types/Store";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";

export default function SelectStore({ defaultValue, onChange }: { defaultValue?: number[], onChange?: (store: StoreJson[]) => void }) {
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<StoreJson[]>([]);
  const defaultFilters: FilterStore = useMemo(
    () => StoreRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterStore>(defaultFilters);

  useFilterLocation(defaultFilters, filters);

  const fetchData = useCallback(async (): Promise<StoreCollection> => {
    let collection = await new StoreRepository().getItems({
      filters,
    });
    setDataSource(collection.toJson().items);
    setTotal(collection.total);
    return collection;
  }, [filters]);


  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChange = (value: string[]) => {
    const res : StoreJson[] = []

    value.forEach(id => {
      const store = dataSource.find(data => data.id === Number(id))
      if (store) {
        res.push(store)
      }
    })

    onChange?.(res)

  };

  return (
    <Select
      mode="multiple"
      allowClear
      defaultValue={defaultValue?.map(String)}
      placeholder="Chọn cửa hàng"
      className=" min-w-[200px]"
      onChange={handleChange} options={dataSource.map(data => ({ label: data.name, value: data.id.toString() }))} />
  )

}