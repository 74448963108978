import { App, Col, Form, Input, Row } from "antd";
import ShippingCarrier from "common/constants/ShippingCarrier";
import ShippingCarrierModel from "common/models/ShippingCarrierModel";
import ShippingCarrierRepository from "common/repositories/ShippingCarrierRepository";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ApiTypeGhn from "../apitype/ApiTypeGhn";
import ApiTypeGhtk from "../apitype/ApiTypeGhtk";
import ApiTypeJTExpress from "../apitype/ApiTypeJTExpress";

import type { ShippingCarrierJsonAddEdit } from "common/types/ShippingCarrier";
const ShippingCarrierForm = ({
  id,
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  id: number;
  model: ShippingCarrierModel;
  setOpen: (v: boolean) => void;
  setProcessing: (p: boolean) => void;
  onSaveSuccess: (o: ShippingCarrierModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const isEditing = id > 0;
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      display_order: model.display_order,
      status: model.status > 0 ? model.status : ShippingCarrier.STATUS_ENABLE,
      api_type:
        model.api_type > 0 ? model.api_type : ShippingCarrier.APITYPE_EMPTY,
      api_setting: model.api_setting
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let apiSetting: any = [];
      if (formData.api_type === ShippingCarrier.APITYPE_GHN) {
        apiSetting = {
          is_sandbox: formData.is_sandbox,
          shop_id: formData.shop_id,
          token: formData.token,
          return_phone: formData.return_phone,
          return_address: formData.return_address,
          return_province_id: formData.regions.region_id,
          return_district_id: formData.regions.sub_region_id,
          return_ward_id: formData.regions.sub_sub_region_id,
          service_type_id: formData.service_type_id
        };
      } else if (formData.api_type === ShippingCarrier.APITYPE_GHTK) {
        apiSetting = {
          is_sandbox: formData.is_sandbox,
          token: formData.token,
          pick_tel: formData.pick_tel,
          pick_email: formData.pick_email,
          pick_name: formData.pick_name,
          pick_address: formData.pick_address,
          pick_province: formData.pick_regions.region_id,
          pick_district: formData.pick_regions.sub_region_id,
          pick_ward: formData.pick_regions.sub_sub_region_id
        };
      } else if (formData.api_type === ShippingCarrier.APITYPE_JTEXPRESS) {
        apiSetting = {
          is_sandbox: formData.is_sandbox,
          ec_company_id: formData.ec_company_id,
          customer_id: formData.customer_id,
          key: formData.key,
          sender_phone: formData.sender_phone,
          sender_address: formData.sender_address,
          sender_province_id: formData.regions.region_id,
          sender_district_id: formData.regions.sub_region_id,
          sender_ward_id: formData.regions.sub_sub_region_id,
          pay_type: formData.pay_type
        };
      }

      const submitData: ShippingCarrierJsonAddEdit = {
        ...ShippingCarrierModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        display_order: formData.display_order,
        api_type: formData.api_type,
        api_setting: apiSetting,
        status: formData.status || ShippingCarrier.STATUS_ENABLE
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ShippingCarrierModel =
      await new ShippingCarrierRepository().saveRemote(doPrepareData(formData));

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="shippingcarrier:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  useEffect(() => {
    const apiSetting: any = model.api_setting;
    switch (model.api_type) {
      case ShippingCarrier.APITYPE_GHN:
        let InitialGhnValues = {
          ...initialValues,
          is_sandbox: Number(apiSetting.issandbox) === 1 ? true : false,
          shop_id: apiSetting.shop_id,
          token: apiSetting.token,
          return_phone: apiSetting.return_phone,
          return_address: apiSetting.return_address,
          regions: {
            region_id: Number(apiSetting.return_province_id),
            sub_region_id: Number(apiSetting.return_district_id),
            sub_sub_region_id: Number(apiSetting.return_ward_id)
          },
          service_type_id: Number(apiSetting.service_type_id),
          payment_type_id: Number(apiSetting.payment_type_id)
        };
        form.setFieldsValue(InitialGhnValues);
        break;

      case ShippingCarrier.APITYPE_GHTK:
        let initialGhtkValues = {
          ...initialValues,
          is_sandbox: apiSetting.issandbox === 1 ? true : false,
          token: apiSetting.token,
          pick_address: apiSetting.pickaddress,
          pick_email: apiSetting.pickemail,
          pick_name: apiSetting.pickname,
          pick_regions: {
            region_id: Number(apiSetting.pickprovince),
            sub_region_id: Number(apiSetting.pickdistrict),
            sub_sub_region_id: Number(apiSetting.pickward)
          },
          pick_tel: apiSetting.picktel
        };
        form.setFieldsValue(initialGhtkValues);
        break;

      case ShippingCarrier.APITYPE_JTEXPRESS:
        let InitialJTExpressValues = {
          ...initialValues,
          is_sandbox: Number(apiSetting.issandbox) === 1 ? true : false,
          ec_company_id: apiSetting.ec_company_id,
          customer_id: apiSetting.customer_id,
          key: apiSetting.key,
          sender_phone: apiSetting.sender_phone,
          sender_address: apiSetting.sender_address,
          regions: {
            region_id: Number(apiSetting.sender_province_id),
            sub_region_id: Number(apiSetting.sender_district_id),
            sub_sub_region_id: Number(apiSetting.sender_ward_id)
          },
          pay_type: Number(apiSetting.pay_type)
        };
        form.setFieldsValue(InitialJTExpressValues);
        break;

      default:
        form.setFieldsValue(initialValues);
    }
  }, [form, initialValues, model]);

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            label={t("shippingcarrier:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_name_required")
              }
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col md={6} xs={24}>
          <Form.Item
            label={t("shippingcarrier:display_order")}
            name="display_order">
            <Input />
          </Form.Item>
        </Col>
        <Col md={6} xs={24}>
          <FormSelect
            label={t("common:status")}
            name="status"
            options={ShippingCarrierModel.getStatusList()}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <FormSelect
            label={t("shippingcarrier:api_type")}
            name="api_type"
            options={ShippingCarrierModel.getApiTypeList()}
            disabled={isEditing ? true : false}
          />
        </Col>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.api_type !== currentValues.api_type
        }>
        {({ getFieldValue }) =>
          getFieldValue("api_type") === ShippingCarrier.APITYPE_GHTK ? (
            <ApiTypeGhtk />
          ) : null
        }
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.api_type !== currentValues.api_type
        }>
        {({ getFieldValue }) =>
          getFieldValue("api_type") === ShippingCarrier.APITYPE_GHN ? (
            <ApiTypeGhn />
          ) : null
        }
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.api_type !== currentValues.api_type
        }>
        {({ getFieldValue }) =>
          getFieldValue("api_type") === ShippingCarrier.APITYPE_JTEXPRESS ? (
            <ApiTypeJTExpress />
          ) : null
        }
      </Form.Item>
    </Form>
  );
};

export default ShippingCarrierForm;
