import { Alert, Form, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import classNames from "classnames";
import Order from "common/constants/Order";
import OrderModel from "common/models/OrderModel";
import Error from "components/Error";
import FormSelect from "components/form/FormSelect";
import SimpleFormSelect from "components/form/SimpleFormSelect";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

const OrderFormSectionStatus = ({
	allowStatusComplete,
	allowStatusCancel,
	status,
	setStatus,
	allowEditInput,
	initStatus,
	type,
	isSuccess,
}: {
	allowStatusComplete: boolean;
	allowStatusCancel: boolean;
	status: number;
	type: number;
	setStatus: (v: number) => void;
	allowEditInput: boolean;
	initStatus: number;
	isSuccess: boolean;
}) => {
	const allow = true;

	const { t } = useTranslation();
	const order = useEditingSaleOrderStore((state) => state.order);

	const [viewNoteCancle, setViewNoteCancle] = useState(false);
	const [statusOrder, setstatusOrder] = useState(status);

	const backgroundColor = useMemo(() => {
		const statusInfo = OrderModel.getStatus(status, type);
		let color = statusInfo?.color;
		return color;
	}, [status, type]);

	useEffect(() => {
		if (isSuccess) {
			setstatusOrder(status);
		}
	}, [status, isSuccess]);
	return (
		<div
			// style={{ backgroundColor: backgroundColor }}
			className="px-4 py-2  mb-0">
			<div
				style={{ backgroundColor: backgroundColor }}
				className="p-2  mb-0 rounded-lg">
				<SimpleFormSelect
					value={status}
					rules={[
						{
							required: status === Order.STATUS_CANCEL,
						},
					]}
					disabled={!allow && order.status === Order.STATUS_CANCEL}
					label={
						<Tag color={backgroundColor}>
							{order.type === Order.TYPE_INVOICE
								? t("order:status_long_invoice")
								: t("order:status_long")}
						</Tag>
					}
					size="large"
					options={OrderModel.getStatusList(type).map((i) => {
						if (i.value === Order.STATUS_COMPLETE) {
							return allowStatusComplete ? i : { ...i, disabled: !allow };
						} else if (i.value === Order.STATUS_CANCEL) {
							return allowStatusCancel ? i : { ...i, disabled: !allow };
						} else {
							return {
								...i,
								disabled:
									i.value < initStatus || !allowEditInput ? !allow : false,
							};
						}
					})}
					//  options={OrderModel.getStatusList(type)}
					className="mb-2  text-white"
					onChange={(v) => (typeof v === "number" ? setStatus(v) : null)}
				/>
			</div>

			<div className={"" + (status !== Order.STATUS_CANCEL ? " hidden" : "")}>
				<FormSelect
					value={order.cancel_reason}
					disabled={!allow && order.status === Order.STATUS_CANCEL}
					showSearch
					label={t("order:cancel_reason")}
					name="cancel_reason"
					rules={[
						{
							required: status === Order.STATUS_CANCEL,
							message: (
								<p className="text-orange-300">Vui lòng chọn lý do hủy</p>
							),
						},
					]}
					onChange={(e) => {
						if (e == Order.CANCEL_REASON_OTHER) {
							setViewNoteCancle(true);
						} else {
							setViewNoteCancle(false);
						}
					}}
					options={OrderModel.getCancelReasonList()}
				/>

				{viewNoteCancle && (
					<Form.Item
						rules={[
							{
								required: true,
								message: (
									<p className="text-orange-300">Vui lòng nhập lý do hủy</p>
								),
							},
						]}
						name={"note_cancle"}>
						<TextArea placeholder="Lý do hủy đơn..."> </TextArea>
					</Form.Item>
				)}

				<div className="mb-2">
					<Alert
						type="warning"
						message={t("order:cancel_dependency_before_note_heading")}
						description={
							<>
								<Error
									contentPadding={0}
									heading={t("Điều kiện hủy đơn")}
									translate_prefix={""}
									items={[t("order:cancel_dependency_before_note")]}
								/>
								<Error
									contentPadding={0}
									heading={t("Trường hợp khác")}
									translate_prefix={""}
									items={[t("order:cancel_dependency_before_note_1")]}
								/>
							</>
						}></Alert>
				</div>
			</div>
		</div>
	);
};

export default OrderFormSectionStatus;
