import CmsPostCollection from "common/collections/CmsPostCollection";
import CmsPost from "common/constants/CmsPost";
import Role from "common/constants/Role";
import CmsPostModel from "common/models/CmsPostModel";
import CmsPostRepository from "common/repositories/CmsPostRepository";
import { CmsPostJson, FilterCmsPost } from "common/types/CmsPost";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import CmsPostListHeader from "features/cmspost/list/CmsPostListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CmsPostListFilter from "./CmsPostListFilter";

const CmsPostList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterCmsPost = useMemo(
    () => CmsPostRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterCmsPost>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<CmsPostModel[]>([]);
  const fetchData = useCallback(async (): Promise<CmsPostCollection> => {
    let collection = await new CmsPostRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);
    return collection;
  }, [filters]);

  //Table columns
  const columns: TableColumnsType<CmsPostModel> = [
    {
      title: t("cmspost:filter_type"),
      key: "type",
      width: 120,
      render: (_: any, record: CmsPostModel) => {
        let typeInfo = CmsPostModel.getType(record.type);
        return <span>{typeInfo?.label}</span>;
      },
    },
    {
      title: t("cmspost:identifier"),
      key: "identifier",
    },
    {
      title: t("cmspost:title"),
      key: "title",
    },
    {
      title: t("cmspost:slug"),
      key: "seo_slug",
    },
    {
      title: t("cmspost:display_order"),
      key: "display_order",
      width: 120,
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 120,
      render: (ts: number) => <TextDateTime ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_: any, record: CmsPostJson) => (
        <RoleCheck roles={[Role.CMS_POST_EDIT]} hideOnFail>
          <TableEdit link={"/cmspost/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="cmspost:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new CmsPostRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <CmsPostListHeader />

      <CmsPostListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterCmsPost, CmsPostModel, CmsPostCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          rowClassName: (record) =>
            record.status === CmsPost.STATUS_ENABLED
              ? ""
              : "table-row-stripes opacity-70",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default CmsPostList;
