import { useTranslation } from "react-i18next";

import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";

import { Filter } from "common/types/Filter";
import CollectionModel from "common/models/CollectionModel";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import { DatePickerProps, Form, Input } from "antd";
import DateFormFilter from "components/datetime/DateFormFilter";
import { useMemo } from "react";
import dayjs from "dayjs";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";

const ProductCollectionListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  const getValueFilter = useMemo(() => {
    const entries = Object.entries(filters).filter(
      ([key, val]) => key === "date_published"
    );

    const [createdVal] = entries.map(([key, val]) => +val);

    if (createdVal > 0) {
      const createdDate = dayjs.unix(createdVal).format("DD/MM/YYYY");
      return dayjs(createdDate, "DD/MM/YYYY");
    }

    return null;
  }, [filters]);

  const onChange: DatePickerProps["onChange"] = (
    value: DatePickerProps["value"]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        date_published: value.unix(),
      });
    } else {
      setFilters({
        ...filters,
        date_published: 0,
      });
    }
  };

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["type"]}
      >
        <FormSelect
          name="pin"
          placeholder={"Trạng thái ghim"}
          options={ProductCollectionModel.getPinSelectList()}
          allowClear
          popupMatchSelectWidth={false}
        />
        <FormSelect
          name="status"
          placeholder={"Ẩn/Hiện"}
          options={ProductCollectionModel.getStatusSelectList()}
          allowClear
          popupMatchSelectWidth={false}
        />
        <Form.Item name="keyword">
          <Input allowClear placeholder={t("Tên danh mục")} />
        </Form.Item>
        <Form.Item>
          <DateFormFilter
            value={getValueFilter}
            allowClear={true}
            onChange={onChange}
            mode="single"
            format="DD/MM/YYYY"
            className="w-full"
            placeholder={t("comment:filter.date_created")}
          />
        </Form.Item>
        <DatePickerRangeFilter
          filters={filters}
          setFilters={setFilters}
          keyEnd="publish_to"
          keyStart="publish_from"
        />
      </PageDataFilterForm>
    </>
  );
};

export default ProductCollectionListFilter;
