import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const CouponListHeader = ({ children }: { children?: React.ReactNode }) => {
	const { t } = useTranslation();

	return (
		<PageHeader
			heading={t("Thiết lập danh sách mã coupon")}
			siteMenuSelectedKey="/promotion">
			{children}
		</PageHeader>
	);
};

export default CouponListHeader;
