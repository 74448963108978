import React, { useEffect, useState } from "react";
import BrandListHeader from "./BrandListHeader";
import SettingRepository from "common/repositories/SettingRepository";
import { SettingJson } from "common/types/Setting";
import {
	Col,
	Image,
	List,
	message,
	Modal,
	Popconfirm,
	Row,
	Table,
	Tabs,
	TabsProps,
	Typography,
} from "antd";
import { BrandData } from "common/types/Shop";
import TableEdit from "components/table/TableEdit";
import { IconTrash } from "@tabler/icons-react";
import { TableColumnsType } from "antd";
import BrandForm from "../form/BrandForm";
import useSetting from "hooks/useSetting";
import SettingModel from "common/models/SettingModel";

export default function BrandList() {
	const keys = "section_brand_namperfume,banner_brand";

	const [messageApi, context] = message.useMessage();

	const [slideJson, setSlideJson] = useState<SettingJson>(
		SettingModel.getDefaultData()
	);
	const [gridJson, setGridJson] = useState<SettingJson>(
		SettingModel.getDefaultData()
	);

	const [slides, setSlides] = useState<BrandData[]>([]);
	const [grids, setGrids] = useState<BrandData[]>([]);

	const [dataEdit, setDataEdit] = useState<BrandData | null>(null);
	const [indexEdit, setIndexEdit] = useState<number>(-1);

	const [typeSubmit, setTypeSubmit] = useState<"add" | "edit" | "">("");

	const [loading, setLoading] = useState(false);
	const [on, setOn] = useState<"grid" | "slide" | "">("");

	const { onAdd: addGrid, onUpdate: updateGrid } = useSetting<BrandData>({
		settingJson: gridJson,
	});
	const { onAdd: addSlide, onUpdate: updateSlide } = useSetting<BrandData>({
		settingJson: slideJson,
	});

	const getBrandData = async () => {
		setLoading(true);
		const resSlide = new SettingRepository().getItem("banner_brand");
		const resGrid = new SettingRepository().getItem("section_brand_namperfume");

		Promise.all([resSlide, resGrid])
			.then(([slide, grid]) => {
				if (!slide.hasError()) {
					setSlideJson(slide.toJson());
					setSlides(slide.toJson().value as BrandData[]);
				}

				if (!grid.hasError()) {
					setGridJson(grid.toJson());
					setGrids(grid.toJson().value as BrandData[]);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onReset = () => {
		setIndexEdit(-1);
		setDataEdit(null);
		setTypeSubmit("");
		setOn("");
	};

	const handleFinish = (data: BrandData) => {
		if (typeSubmit === "edit") {
			if (on === "grid") {
				dataEdit &&
					indexEdit >= 0 &&
					updateGrid(data, dataEdit, indexEdit)
						.then(() => {
							messageApi.success("Thay đổi thành công!");

							getBrandData();
							// setReload((prev) => ({ ...prev, grid: true }));
						})
						.catch(() => {
							messageApi.success("Thay đổi thất bại!");
						});
			} else if (on === "slide") {
				dataEdit &&
					indexEdit >= 0 &&
					updateSlide(data, dataEdit, indexEdit)
						.then(() => {
							getBrandData();
							messageApi.success("Thay đổi thành công!");

							// setReload((prev) => ({ ...prev, slide: true }));
						})
						.catch(() => {
							messageApi.success("Thay đổi thất bại!");
						});
			}
		}
	};

	useEffect(() => {
		getBrandData();
	}, []);

	const columnSlide: TableColumnsType<BrandData> = [
		{
			title: "ID",
			key: "id",
			dataIndex: "id",
			render(value, record, index) {
				return (
					<>
						<Typography.Text>{value}</Typography.Text>
					</>
				);
			},
		},
		{
			title: "Ảnh mobile",
			key: "image_desktop",
			dataIndex: "image_desktop",
			render(value, record, index) {
				return (
					<>
						<Image width={60} height={60} src={record.image_mobile}></Image>
					</>
				);
			},
		},
		{
			title: "Ảnh desktop",
			key: "image",
			dataIndex: "image",
			render(value, record, index) {
				return <Image width={60} height={60} src={record.image}></Image>;
			},
		},
		{
			title: "Link",
			key: "link",
			dataIndex: "link",
			render(value, record, index) {
				return (
					<>
						<Typography.Link>{value}</Typography.Link>
					</>
				);
			},
		},
		{
			title: "alt",
			key: "alt",
			dataIndex: "alt",
			render(value, record, index) {
				return (
					<>
						<Typography.Link>{value}</Typography.Link>
					</>
				);
			},
		},

		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (id: number, record, index) => {
				return (
					<div className="flex gap-1 justify-end">
						<TableEdit
							link=""
							onClick={() => {
								// setTypeAction("edit");
								// setIndexUpdate(index);
								// setDataEditing(record);
								setDataEdit(record);
								setIndexEdit(index);
								setTypeSubmit("edit");
								setOn("slide");
							}}></TableEdit>

						<Popconfirm
							placement="topRight"
							title={<>Bạn chắc chắn muốn xóa ?</>}
							description={"Gỡ chương trình khỏi cài đặt "}
							okText="Đồng ý"
							cancelText="Hủy"
							onConfirm={() => {}}>
							{/* <TableEdit label="" icon={<IconTrash></IconTrash>} link={""} /> */}
							<IconTrash
								className=" cursor-pointer text-gray-400 hover:text-red-500"
								size="18"
							/>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const columnGrid: TableColumnsType<BrandData> = [
		{
			title: "ID",
			key: "id",
			dataIndex: "id",
			render(value, record, index) {
				return (
					<>
						<Typography.Text>{value}</Typography.Text>
					</>
				);
			},
		},
		{
			title: "Ảnh mobile",
			key: "image_desktop",
			dataIndex: "image_desktop",
			render(value, record, index) {
				return (
					<>
						<Image width={60} height={60} src={record.image_mobile}></Image>
					</>
				);
			},
		},
		{
			title: "Ảnh desktop",
			key: "image",
			dataIndex: "image",
			render(value, record, index) {
				return <Image width={60} height={60} src={record.image}></Image>;
			},
		},
		{
			title: "Link",
			key: "link",
			dataIndex: "link",
			render(value, record, index) {
				return (
					<>
						<Typography.Link>{value}</Typography.Link>
					</>
				);
			},
		},
		{
			title: "alt",
			key: "alt",
			dataIndex: "alt",
			render(value, record, index) {
				return (
					<>
						<Typography.Link>{value}</Typography.Link>
					</>
				);
			},
		},

		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (id: number, record, index) => {
				return (
					<div className="flex gap-1 justify-end">
						<TableEdit
							link=""
							onClick={() => {
								// setTypeAction("edit");
								// setIndexUpdate(index);
								// setDataEditing(record);
								setDataEdit(record);
								setIndexEdit(index);
								setTypeSubmit("edit");
								setOn("grid");
							}}></TableEdit>

						<Popconfirm
							placement="topRight"
							title={<>Bạn chắc chắn muốn xóa ?</>}
							description={"Gỡ chương trình khỏi cài đặt "}
							okText="Đồng ý"
							cancelText="Hủy"
							onConfirm={() => {}}>
							{/* <TableEdit label="" icon={<IconTrash></IconTrash>} link={""} /> */}
							<IconTrash
								className=" cursor-pointer text-gray-400 hover:text-red-500"
								size="18"
							/>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const items: TabsProps["items"] = [
		{
			key: "1",
			label: <div className="px-2">Slide Trái</div>,
			children: (
				<Table
					loading={loading}
					columns={columnSlide}
					rowKey={"id"}
					dataSource={slides || []}></Table>
			),
		},
		{
			key: "2",
			label: <div className="px-2">Grid phải</div>,
			children: (
				<Table
					loading={loading}
					columns={columnGrid}
					rowKey={"id"}
					dataSource={grids || []}></Table>
			),
		},
	];

	return (
		<div>
			{context}
			<BrandListHeader></BrandListHeader>
			<Tabs items={items}></Tabs>
			<Modal
				width={"800px"}
				okButtonProps={{ className: "hidden" }}
				destroyOnClose
				title="Chỉnh sửa brand"
				open={dataEdit !== null}
				onCancel={() => {
					onReset();
				}}>
				{/* {dataEditing && (
					<AdsCollectionForm
						initialValue={dataEditing}
						onFinish={(data) => {
							hanleUpdate(data);
						}}></AdsCollectionForm>
				)} */}
				{dataEdit && (
					<BrandForm
						initialValue={dataEdit}
						onFinish={handleFinish}></BrandForm>
				)}
			</Modal>
		</div>
	);
}
