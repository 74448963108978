import BaseCollection from "common/collections/BaseCollection";
import DataGridTableFieldModel from "common/models/DataGridTableFieldModel";
import {
  DataGridTableFieldJson,
  DataGridTableFieldCollectionJson,
} from "common/types/DataGridTableField";

class DataGridTableFieldCollection extends BaseCollection<
  DataGridTableFieldJson,
  DataGridTableFieldModel,
  DataGridTableFieldCollectionJson
> {
  itemsFromJson(
    jsonItems: DataGridTableFieldJson[]
  ): DataGridTableFieldModel[] {
    return jsonItems.map((item) => new DataGridTableFieldModel(item));
  }
}

export default DataGridTableFieldCollection;
