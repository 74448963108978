import ProductReceipt from "common/constants/ProductReceipt";
import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import usePathParams from "hooks/usePathParams";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const InventoryReceiptFormHeader = ({
	isEditing,
	direction,
	id,
}: {
	isEditing: boolean;
	direction: number;
	id: string;
}) => {
	const { t } = useTranslation();
	const [pathParams] = usePathParams();

	const nav: Nav = [
		{
			text: t("productreceipt:heading_nav"),
			link: "/inventoryreceipt",
		},
	];

	const siteMenuSelectUrl = useMemo(() => {
		let url = "/inventoryreceipt";
		if (pathParams?.direction && pathParams.direction) {
			url += `/add/direction/${pathParams.direction}`;
		}

		if (pathParams?.type) {
			url += `/type/${pathParams.type}`;
		}
		return url;
	}, [pathParams]);

	return (
		<>
			<PageHeader
				nav={nav}
				heading={
					(direction === ProductReceipt.DIRECTION_INPUT
						? t(
								isEditing
									? "productreceipt:heading_edit_in"
									: "productreceipt:heading_add_in"
						  )
						: t(
								isEditing
									? "productreceipt:heading_edit_out"
									: "productreceipt:heading_add_out"
						  )) + (id.length > 0 ? " #" + id : "")
				}
				siteMenuSelectedKey={siteMenuSelectUrl}
				siteMenuHiddenBackLink="/inventoryreceipt"></PageHeader>
		</>
	);
};

export default InventoryReceiptFormHeader;
