import { GetListProps } from "common/types/Filter";

import TagTypeApi from "common/api/TagTypeApi";
import TagTypeCollection from "common/collections/TagTypeCollection";
import TagTypeModel from "common/models/TagTypeModel";
import { FilterTagType, TagTypeAddEditJson } from "common/types/TagType";
import BaseRepository from "./BaseRepository";

class TagTypeRepository extends BaseRepository<TagTypeApi> {
  _api: TagTypeApi | null;
  constructor() {
    super();
    this._api = new TagTypeApi(true);
  }

  static getDefaultFilters(): FilterTagType {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: -1,
      resource_type: -1,
    };
  }

  async getItems(
    props: GetListProps<FilterTagType>
  ): Promise<TagTypeCollection> {
    return this._api ? await this._api.getList(props) : new TagTypeCollection();
  }

  async getItem(id: number): Promise<TagTypeModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new TagTypeModel(TagTypeModel.getDefaultData());
  }

  //add - edit
  async saveRemote(data: TagTypeAddEditJson): Promise<TagTypeModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new TagTypeModel(TagTypeModel.getDefaultData());
    }
  }

  //delete
  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default TagTypeRepository;
