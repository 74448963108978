import { Form, Radio, RadioChangeEvent } from 'antd';
import { Rule } from 'antd/lib/form';
import { SelectValue } from 'antd/lib/select';
import { SelectOption } from 'common/types/SelectOption';
import React, { useCallback } from 'react';

const FormRadio = ({
  value,
  onChange,
  name,
  rules,
  options,
  label,
  noStyle,
  required,
  className,
  disabled,
  size,
}: {
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  name: string;
  rules?: Rule[];
  options: SelectOption[];
  label?: string;
  noStyle?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  size?: "large" | "middle" | "small";
}) => {
  const onChangeHandler = useCallback(
    (e: RadioChangeEvent) => {
      if (typeof onChange !== "undefined") {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  return (
    <Form.Item
      name={name}
      rules={rules}
      noStyle={noStyle}
      label={label}
      required={required}
      className={className}
    >
      <Radio.Group
        style={{ width: "100%" }}
        disabled={disabled}
        size={size}
        options={options}
        value={value}
        onChange={onChangeHandler}
        optionType="button"
        buttonStyle="solid"
      ></Radio.Group>
    </Form.Item>
  );
};
export default FormRadio;
