import { App, Form, Input, Switch, Col, Row, Typography, Select } from "antd";
import { IconHash } from "@tabler/icons-react";
import Error from "components/LayoutError";

import type { WorkChatJsonAddEdit } from "../../common/types/WorkChat";
import WorkChat from "../../common/constants/WorkChat";
import WorkChatModel from "../../common/models/WorkChatModel";
import WorkChatRepository from "../../common/respositories/WorkChatRepository";

import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const WorkChatChannelForm = ({
  id,
  model,
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  id: number;
  model: WorkChatModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: WorkChatModel) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const account = useLoginAccountStore((state) => state.account);

  const initialValues = useMemo(() => {
    const formData = {
      creator_id: model.creator_id,
      type: model.type || WorkChat.TYPE_CHANNEL,
      mode: model.mode === WorkChat.MODE_PRIVATE ? true : false,
      name: model.name,
      topic: model.topic,
      slug: model.slug,
      status: model.status || WorkChat.STATUS_ENABLE,
      description: model.description,
      is_default: model.is_default,
      is_featured: model.is_featured,
      is_readonly: model.is_readonly === WorkChat.READ_ENABLE ? true : false,
      // event: model.event,
      meta_data: model.meta_data,

      url: "",
      group_event: "",
      headers: [{ key: "", value: "" }],
    };
    // if (model.event.length > 0) {
    //   formData.group_event = model.event.split(".")[0];
    // }
    // if (model.meta_data.hasOwnProperty("workchat")) {
    //   if (model.meta_data.workchat.hasOwnProperty("url")) {
    //     formData.url = model.meta_data.workchat.url;
    //   }

    //   if (model.meta_data.workchat.hasOwnProperty("headers")) {
    //     var headers = [];
    //     for (let props in model.meta_data.workchat.headers) {
    //       headers.push({
    //         key: props,
    //         value: model.meta_data.workchat.headers[props],
    //       });
    //     }

    //     formData.headers = headers;
    //   }
    // }
    return formData;
  }, [model]);

  //Prepare Data For Submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let metaData = {
        workchat: {
          url: "",
          headers: {},
        },
      };

      metaData.workchat.url = formData.url;
      let refineHeaders: any = {};
      if (typeof formData.headers !== "undefined") {
        const headers = formData.headers;
        for (var i = 0; i < headers.length; i++) {
          var header = headers[i];
          refineHeaders[header.key] = header.value;
        }
      }
      metaData.workchat.headers = refineHeaders;

      //Mode
      let modeValue = parseInt(formData.mode, 10);
      if (isNaN(modeValue)) {
        modeValue = formData.mode
          ? WorkChat.MODE_PRIVATE
          : WorkChat.MODE_PUBLIC;
      }

      modeValue = formData.mode ? WorkChat.MODE_PRIVATE : WorkChat.MODE_PUBLIC;

      //Read Only
      let readValue = parseInt(formData.is_readonly, 10);
      if (isNaN(readValue)) {
        readValue = formData.is_readonly
          ? WorkChat.READ_ENABLE
          : WorkChat.READ_DISABLE;
      }

      readValue = formData.is_readonly
        ? WorkChat.READ_ENABLE
        : WorkChat.READ_DISABLE;

      const submitData: WorkChatJsonAddEdit = {
        ...WorkChatModel.getDefaultData(),
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,
        type: formData.type || WorkChat.TYPE_CHANNEL,
        mode: modeValue,
        name: formData.name,
        topic: formData.topic,
        slug: formData.slug,
        status: formData.status,
        description: formData.description,
        is_default: formData.is_default,
        is_featured: formData.is_featured,
        is_readonly: readValue,

        // event: formData.event,
        meta_data: metaData,
      };

      return submitData;
    },
    [model.id, account]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });
    console.log(doPrepareData(formData));
    let item: WorkChatModel = await new WorkChatRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="workchat:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("workchat:form.success.update"),
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  //Select Member
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: "member" + i,
      value: i.toString(36) + i,
    });
  }

  const handleChange = (value: any) => {
    console.log(`selected ${value}`);
  };

  return (
    <Form
      id="channel-form"
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      onFieldsChange={(changedFields) => {
        //detect change group, reset event
        if (
          changedFields.length === 1 &&
          Array.isArray(changedFields[0].name) &&
          changedFields[0].name.includes("group_event") &&
          changedFields[0].touched
        ) {
          form.setFieldValue("event", null);
        }
      }}
    >
      {/* Name  */}
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <Form.Item
            className="font-blod"
            label={<span className="font-bold">Name</span>}
            name="name"
            rules={[
              {
                required: true,
                message: t("workchat:form.error.name_required"),
              },
            ]}
          >
            <Input
              placeholder={t("Channel name")}
              suffix={
                <IconHash stroke-width="0.75" style={{ color: "#000" }} />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      {/* Topic */}
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <Form.Item
            className="font-blod"
            label={<span className="font-bold">Topic</span>}
            name="topic"
            rules={[
              {
                required: true,
                message: t("workchat:form.error.topic_required"),
              },
            ]}
          >
            <Input placeholder={t("What is this channel about?")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify="space-between" className="items-center ">
        <Col>
          <Form.Item
            label={<span className="font-bold">Private</span>}
            // name="mode"
            noStyle
          >
            <Typography.Text style={{ marginRight: "8px" }}>
              {<span className="font-bold">Private</span>}
            </Typography.Text>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Private"
            name="mode"
            valuePropName="checked"
            noStyle
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      {/* Text mode */}
      <Row gutter={16} className="items-center mb-5">
        <Col>
          <Typography.Text
            style={{ marginRight: "8px" }}
            className="text-gray-400"
          >
            {t("Everyone can access this channel")}
          </Typography.Text>
        </Col>
      </Row>

      {/* Read Only Switch  */}
      <Row gutter={16} justify="space-between" className="items-center ">
        <Col>
          <Form.Item
            label={<span className="font-normal">Readonly</span>}
            // name="is_readonly"
            noStyle
          >
            <Typography.Text style={{ marginRight: "8px" }}>
              {<span className="font-normal">Readonly</span>}
            </Typography.Text>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label={<span className="font-normal">Readonly</span>}
            name="is_readonly"
            valuePropName="checked"
            noStyle
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      {/* Text read */}
      <Row gutter={16} className="items-center mb-5">
        <Col>
          <Typography.Text
            style={{ marginRight: "8px" }}
            className="text-gray-400"
          >
            {t("All users in the channel can write new messages")}
          </Typography.Text>
        </Col>
      </Row>

      {/* Select Memeber */}
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <Form.Item
            className="font-blod"
            label={<span className="font-bold">Add Members (optional)</span>}
            name="members"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Please select"
              defaultValue={["a10", "c12"]}
              onChange={handleChange}
              options={options}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default WorkChatChannelForm;
