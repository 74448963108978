import { IconBuildingStore } from "@tabler/icons-react";
import { Card, Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const TaxInvoiceFormSectionSellerInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <Card
        title={
          <span className="uppercase">
            <IconBuildingStore className="mr-1 -mt-1" />
            {t("taxinvoice:group_seller")}
          </span>
        }
        headStyle={{ background: "#eee" }}
      >
        <Row gutter={32}>
          <Col md={8} xs={8}>
            <Form.Item label={t("taxinvoice:seller_name")} name="seller_name">
              <Input />
            </Form.Item>
          </Col>
          <Col md={8} xs={8}>
            <Form.Item
              label={t("taxinvoice:tax_number")}
              name="seller_tax_number"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={8} xs={8}>
            <Form.Item label={t("taxinvoice:phone")} name="seller_phone">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col md={8} xs={8}>
            <Form.Item
              label={t("taxinvoice:account_number")}
              name="seller_account_number"
              className="mb-0"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={16} xs={24}>
            <Form.Item
              label={t("taxinvoice:address")}
              name="seller_address"
              className="mb-0"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TaxInvoiceFormSectionSellerInfo;
