import { Table, TableColumnsType } from "antd";
import {
  InventoryStock,
  InventoryStockNumberType,
} from "common/types/InventoryStock";
import TextWarehouse from "components/TextWarehouse";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import usePosCartStore from "zustands/usePosCartStore";

const InventoryBySkuCellPopover = ({
  items,
  activeItem,
  activeWarehouseId,
  type,
}: {
  items: InventoryStock[];
  activeItem?: InventoryStock;
  activeWarehouseId: number;
  type: InventoryStockNumberType;
}) => {
  const { t } = useTranslation();
  const [activeStore] = usePosCartStore((state) => [state.activeStore]);

  if (typeof activeStore === "undefined") return null;

  const columns: TableColumnsType<InventoryStock> = [
    {
      title: t("pos:warehouse.label"),
      dataIndex: "warehouse_id",
      key: "warehouse_id",
      render: (warehouse_id: number) => <TextWarehouse id={warehouse_id} />,
    },
    {
      title: t("pos:warehouse.onhand"),
      dataIndex: "onhand",
      key: "onhand",
      align: "right",
      render: (onhand: number, record: InventoryStock) => (
        <span
          className={
            activeItem?.warehouse_id === record.warehouse_id &&
            type === "onhand"
              ? "border rounded-lg border-green-400 px-2"
              : ""
          }
        >
          {onhand}
        </span>
      ),
    },
    {
      title: t("pos:warehouse.allocated"),
      dataIndex: "allocated",
      key: "allocated",
      align: "right",
      render: (allocated: number, record: InventoryStock) => (
        <span
          className={
            activeItem?.warehouse_id === record.warehouse_id &&
            type === "allocated"
              ? "border rounded-lg border-green-400 px-2"
              : ""
          }
        >
          {allocated}
        </span>
      ),
    },
    {
      title: t("pos:warehouse.available"),
      dataIndex: "available",
      key: "available",
      align: "right",
      render: (available: number, record: InventoryStock) => (
        <span
          className={
            activeItem?.warehouse_id === record.warehouse_id &&
            type === "available"
              ? "border rounded-lg border-green-400 px-2"
              : ""
          }
        >
          {available}
        </span>
      ),
    },
    {
      title: t("pos:warehouse.incoming"),
      dataIndex: "incoming",
      key: "incoming",
      align: "right",
      render: (incoming: number, record: InventoryStock) => (
        <span
          className={
            activeItem?.warehouse_id === record.warehouse_id &&
            type === "incoming"
              ? "border rounded-lg border-green-400 px-2"
              : ""
          }
        >
          {incoming}
        </span>
      ),
    },
  ];

  const datasource = items
    .filter((item) => item.warehouse_id === activeWarehouseId)
    .map((item) => {
      return {
        ...item,
        key: item.warehouse_id,
      };
    });

  return (
    <>
      <Table
        rowKey={"warehouse_id"}
        className="pos-inventory-popover-table"
        size="small"
        rowClassName={(record, index) =>
          isEqual(record.warehouse_id, activeItem?.warehouse_id)
            ? "pos-inventory-popover-row active"
            : "pos-inventory-popover-row"
        }
        pagination={{ hideOnSinglePage: true, pageSize: 5 }}
        columns={columns}
        dataSource={datasource}
      />
    </>
  );
};

export default InventoryBySkuCellPopover;
