import React, { useEffect, useState } from "react";
import FormEditFetcher from "components/form/FormEditFetcher";
import usePathParams from "hooks/usePathParams";

////////////////////////////
//Phần thay thế
import LeaveApproveRepository from "common/repositories/LeaveApproveRepository";
import LeaveRepository from "common/repositories/LeaveRepository";
import LeaveAssignModel from "common/models/LeaveAssignModel";
import LeaveModel from "common/models/LeaveModel";
//Gán quyền duyệt
import LeaveAssignFormHeader from "features/leaveapprove/assgin/form/LeaveAssignFormHeader";
import LeaveAssignForm from "features/leaveapprove/assgin/form/LeaveAssignForm";
import LeaveAssignList from "features/leaveapprove/assgin/list/LeaveAssignList";
//duyệt nghỉ phép
import LeaveApprovalList from "features/leaveapprove/approval/list/LeaveApprovalList";
import LeaveApprovalForm from "features/leaveapprove/approval/form/LeaveApprovalForm";

const LeaveApprovePage = () => {
  const [pathParams] = usePathParams();

  /////////////////////////////
  //state

  const [modelApproval, setModelApproval] = useState(
    new LeaveModel(LeaveModel.getDefaultData())
  );

  const [errors, setErrors] = useState<string[]>([]);

  let com = <></>;
  //
  useEffect(() => {
    setModelApproval(new LeaveModel(LeaveModel.getDefaultData()));
  }, [pathParams.id]);
  switch (pathParams.action) {
    case "approval":
      com = <LeaveApprovalList />;
      break;

    case "approval_edit":
      com =
        modelApproval.id > 0 ? (
          <LeaveApprovalForm model={modelApproval} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new LeaveRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModelApproval(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<LeaveAssignFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    case "assign":
      com = <LeaveAssignList />;
      break;
    default:
      com = <LeaveAssignList />;
      break;
  }
  return com;
};

export default LeaveApprovePage;
