import PageDataTable from "components/page/PageDataTable";
import React, { useCallback, useMemo, useState } from "react";
import PromotionCouponModel from "common/models/PromotionCouponModel";
import { FilterPromotionCoupon } from "common/types/PromotionCoupon";
import { PromotionCouponCollectionJson } from "common/types/PromotionCoupon";
import PromotionCouponRepository from "common/repositories/PromotionCouponRepository";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import PageDataPagination from "components/page/PageDataPagination";
import { message, Modal, TableColumnsType, Tag } from "antd";
import PromotionCouponCollection from "common/collections/PromotionCouponCollection";
import usePathParams from "hooks/usePathParams";
import TextDateTime from "components/TextDateTime";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import Role from "common/constants/Role";
import PromotionListHeader from "./PromotionListHeader";
import CouponListHeader from "./CouponListHeader";
import TableDelete from "components/table/TableDelete";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useTranslation } from "react-i18next";
import CouponForm from "../form/CouponForm";

export default function CouponList() {
	const [pathParams] = usePathParams();
	const { t } = useTranslation();
	const [messageApi, context] = message.useMessage();
	const id = pathParams.id;
	const defaultFilters: FilterPromotionCoupon = useMemo(
		() => ({
			...PromotionCouponRepository.getDefaultFilters(),
			promotion_id: Number(pathParams.id),
		}),
		[pathParams.id]
	);
	const [filters, setFilters] =
		useStateFilter<FilterPromotionCoupon>(defaultFilters);

	const [openAdd, setOpenAdd] = useState(false);
	// useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<PromotionCouponModel[]>([]);
	const fetchData =
		useCallback(async (): Promise<PromotionCouponCollection> => {
			let collection = await new PromotionCouponRepository().getItems({
				filters,
			});
			setDataSource(collection.items);
			setTotal(collection.total);

			return collection;
		}, [filters]);

	const onDelete = async (id: number) => {
		// const res = await new PromotionCouponRepository().deleteItem(id);
		// if (!res.length > 0) {
		// 	messageApi.success("Xóa thành công");
		// } else {
		// 	messageApi.warning("Xóa thất bại");
		// }
	};
	const y = [
		{
			dataSource: {
				params: {
					collection_id: "deal-thom",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 411209,
				},
			},
			from_time: 1712136268,
			handle: "deal-thom",
			id: 1004120518,
			link: "collections/deal-thom",
			link_more: "collections/deal-thom",
			title: "Deal Thơm",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			dataSource: {
				params: {
					collection_id: "tuan-le-thuong-hieu",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712831589,
			handle: "tuan-le-thuong-hieu",
			id: 745228,
			link: "/collections/tuan-le-thuong-hieu",
			link_more: "/collections/tuan-le-thuong-hieu",
			title: "Tuần Lễ Thương Hiệu",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			dataSource: {
				params: {
					collection_id: "promotion-khuyen-mai-thang-7",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712831589,
			handle: "promotion-khuyen-mai-thang-7",
			id: 1004120694,
			link: "/collections/khuyen-mai-thang-7",
			link_more: "/collections/khuyen-mai-thang-7",
			title: "Khuyến Mãi Tháng 7",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			dataSource: {
				params: {
					collection_id: "nuoc-hoa-moi",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712136268,
			handle: "nuoc-hoa-moi",
			id: 794249,
			link: "collections/nuoc-hoa-moi",
			link_more: "collections/nuoc-hoa-moi",
			title: "New Arrivals",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			dataSource: {
				params: {
					collection_id: "yeu-thich-nhat",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712136268,
			handle: "bestsellers",
			id: 794151,
			link: "collections/yeu-thich-nhat",
			link_more: "collections/yeu-thich-nhat",
			title: "Bestsellers",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			from_time: 1712136268,
			handle: "banner_nnn",
			id: 0,
			link: "",
			link_more: "",
			title: "",
			title_more: "Xem thêm",
			to_time: 0,
			type: "section_banner",
		},
		{
			dataSource: {
				params: {
					collection_id: "nuoc-hoa-mini",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712136268,
			handle: "nuoc-hoa-mini",
			id: 452899,
			link: "collections/nuoc-hoa-mini",
			link_more: "collections/nuoc-hoa-mini",
			title: "Nước Hoa Mini",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			dataSource: {
				params: {
					collection_id: "thuong-hieu-hugo-boss",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712136268,
			handle: "thuong-hieu-hugo-boss",
			id: 707613,
			link: "collections/thuong-hieu-hugo-boss",
			link_more: "collections/thuong-hieu-hugo-boss",
			title: "Tuần lễ thương hiệu Hugo Boss",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			dataSource: {
				params: {
					collection_id: "giftset-nuoc-hoa",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712136268,
			handle: "giftset-nuoc-hoa",
			id: 452892,
			link: "collections/giftset-nuoc-hoa",
			link_more: "collections/giftset-nuoc-hoa",
			title: "Giftset",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			dataSource: {
				params: {
					collection_id: "bodycare-homecare",
					limit: 20,
					page: 1,
					parent_id: 0,
					store_id: 181189,
				},
			},
			from_time: 1712136268,
			handle: "bodycare-homecare",
			id: 794282,
			link: "collections/bodycare-homecare",
			link_more: "collections/bodycare-homecare",
			title: "Bodycare & Homecare",
			title_more: "Xem thêm",
			to_time: 0,
			type: "collection",
		},
		{
			from_time: 1712136268,
			handle: "",
			id: 0,
			link: "",
			link_more: "",
			title: "namperfume TV",
			title_more: "Xem thêm",
			to_time: 0,
			type: "namperfumetv",
		},
		{
			from_time: 1712136268,
			handle: "brand_nmagazine",
			id: 509,
			link: "",
			link_more: "",
			title: "Nmagazine",
			title_more: "Xem thêm",
			to_time: 0,
			type: "nmagazine",
		},
		{
			from_time: 1712136268,
			handle: "about_namperfume_1",
			id: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
			to_time: 0,
			type: "section_about",
		},
		{
			from_time: 1712136268,
			handle: "about_namperfume_2",
			id: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
			to_time: 0,
			type: "section_about",
		},
	];

	const columns: TableColumnsType<PromotionCouponModel> = [
		{
			title: "Mã code",
			key: "code",
			render: (code, record) => {
				return <Tag>{code}</Tag>;
			},
		},

		{
			title: "Tình trạng",
			key: "status",
			render: (_, record) => {
				const status = record.status;
				let color = "default";
				let value = "";
				switch (status) {
					case 1:
						value = "Đã phát hành";
						color = "green";
						break;
					case 2:
						value = "Chưa phát hành";
						color = "blue";

						break;
					case 3:
						value = "Ẩn";
						color = "danger";

						break;
					case 4:
						color = "gray";

						value = "Đã sử dụng";

						break;
					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 150,
			render: (_: any, record: PromotionCouponModel) => (
				<RoleCheck roles={[Role.PROMOTION_MANAGE]} hideOnFail>
					{/* <TableEdit link="" onClick={() => onEdit(record)} /> */}
					<div className="flex justify-end">
						{/* <TableEdit link=""></TableEdit> */}
						<TableDelete
							onDeleteCallback={onDelete}
							id={record.id}
							repository={new PromotionCouponRepository()}></TableDelete>
					</div>
				</RoleCheck>
			),
		},
	];

	return (
		<div>
			{context}
			<CouponListHeader>
				{dataSource.length > 0 && (
					<RoleCheck roles={[]}>
						<PageHeaderButton
							type="primary"
							link=""
							onClick={() => {
								setOpenAdd(true);
							}}>
							{t("promotion:add_button")}
						</PageHeaderButton>
					</RoleCheck>
				)}
			</CouponListHeader>
			<PageDataTable<
				FilterPromotionCoupon,
				PromotionCouponModel,
				PromotionCouponCollection
			>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "promotion",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
			<Modal
				okButtonProps={{ className: "hidden" }}
				title="Tạo mới các mã coupon"
				open={openAdd}
				onCancel={() => {
					setOpenAdd((prev) => !prev);
				}}>
				<CouponForm
					onFinish={() => {
						fetchData();
					}}
					idEditing={Number(id)}
					initialValue={{ codes: [] }}></CouponForm>
			</Modal>
		</div>
	);
}
