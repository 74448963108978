import CashflowReceipt from "common/constants/CashflowReceipt";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import CashflowReceiptRepository from "common/repositories/CashflowReceiptRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import CashflowReceiptForm from "features/cashflowreceipt/form/CashflowReceiptForm";
import CashflowReceiptFormHeader from "features/cashflowreceipt/form/CashflowReceiptFormHeader";
import CashflowReceiptList from "features/cashflowreceipt/list/CashflowReceiptList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const CashflowReceiptPage = () => {
  const [pathParams] = usePathParams();

  const [model, setModel] = useState<CashflowReceiptModel>(
    new CashflowReceiptModel(CashflowReceiptModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    if (pathParams.action === "add" || pathParams.action === "edit") {
      return
    }
    setModel(new CashflowReceiptModel(CashflowReceiptModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);




  let com = null;

  switch (pathParams.action) {
    case "add":
      if (pathParams.direction !== undefined) {
        com = (
          <CashflowReceiptForm
            setModel={setModel}
            model={
              new CashflowReceiptModel({
                ...model,
                direction: +pathParams.direction,
                // source: CashflowReceipt.SOURCE_MANUAL
              })
            }
            need_link_sale_order_id={+pathParams.sale_order_id || undefined}
            need_link_purchase_order_id={
              +pathParams?.purchase_order_id || undefined
            }
          />
        );
      }

      break;
    case "edit":
      com =
        model.id > 0 ? (
          <CashflowReceiptForm
            setModel={setModel}

            model={model} refetch={async () => {
              let fetchedModel = await new CashflowReceiptRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }} />
        ) : (
          <FormEditFetcher

            doLoad={async () => {
              let fetchedModel = await new CashflowReceiptRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={
              <CashflowReceiptFormHeader
                direction={
                  pathParams.direction !== undefined
                    ? +pathParams.direction
                    : model.direction
                }
                isEditing={true}
                id={+pathParams.id}
              />
            }
          />
        );

      break;
    default:
      com = <CashflowReceiptList />;
  }

  return com;
};

export default CashflowReceiptPage;
