import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";

import { Button, Col, Collapse, Row } from "antd";
import ProductReceiptPrintVariable from "features/productreceipt/ProductReceiptPrintVariable";
import posPrinterImage from "images/posprinter.webp";
import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { useTranslation } from "react-i18next";

import { IconReceipt } from "@tabler/icons-react";

import type { CollapseProps } from "antd";

import type { HtmlInputProps } from "common/interfaces/HtmlInput";
import { CartOrderJson, ProductJsonOrder } from "common/types/Cart";
const ReceiptTemplateInput: React.FC<HtmlInputProps> = ({
	value,
	onChange,
}: HtmlInputProps) => {
	const { t } = useTranslation();
	const [html, setHtml] = useState(value);

	const triggerChange = (changedValue: string) => {
		onChange?.(changedValue);
	};

	const updateHtml = (newHtml: string) => {
		setHtml(newHtml);
		triggerChange(newHtml);
	};

	useEffect(() => {
		setHtml(value);
	}, [value]);

	// const renderItems = (items: CartOrderJson[]) => {
	//   return items
	//     .map(
	//       (item) => `
	//    <tr valign="top" style="border-top: 1px solid #d9d9d9;">
	//      <td colspan="3" style="padding: 5px 0px 0">
	//        <p style="margin-bottom: 5px;">${item.item_title} -
	//          <span style="margin: 0">${item.product_json.option_name}</span> - <span>(sku: ${item.product_json.sku})</span>
	//        </p>
	//      </td>
	//    </tr>
	//    <tr valign="top">
	//      <td style="padding: 5px 0px;text-align:left;">
	//        ${item.item_unit_price}
	//      </td>
	//      <td style="padding: 5px 0px;text-align:center;">${item.item_quantity}</td>
	//      <td style="padding: 5px 0px;text-align:right;">${item.price_final}</td>
	//    </tr>
	//  `
	//     )
	//     .join("");
	// };

	const tem = `
<meta charset="UTF-8">
<style>
  p {
    margin: 0;
  }
  .table_detail {
    border-collapse: collapse;
  }
  .table_detail tbody tr:first-child {
    border-top: 2px solid #000 !important;
  }
</style>
<div style="margin:0;font-family: Helvetica;font-size:12px;page-break-after:always;">
  <div>
    <div style="margin: 10px 0 12px 0;">
      <p style="margin-bottom: 1em;"><strong style="font-size: 18px;">namperfume</strong></p>
      
      <p><strong>Địa chỉ:</strong> 420/6 Lê Văn Sỹ, Phường 14, Quận 3,TP. Hồ Chí Minh</p>
      
      
      <p><strong>Điện thoại:</strong> 19000129</p>
      
      <p><strong>Website:</strong> https://namperfume.net</p>
      <p><strong>Ngày bán:</strong> {{ date_sale }} </p>
    </div>
    <div style="clear:both"></div>
  </div>
  <div style="width:100%;text-align:center;">
    <h3 style="font-size: 14px;line-height: 12px;text-transform: uppercase;text-align: center;margin: 0;">
      Hóa đơn: {{ code }}
    </h3>
    <img style="margin:auto;height: 68px;z-index: 1;position: relative;" class="print_barcode order" val="#9999" height="68px" src="{{ barcode_img }}">
		<div style="width: 100%;height: 28px;background: #ffffff;margin-top: -28px;z-index: 10;position: relative;"></div>
  </div>
  <div style="width:100%;">
    
    <div style="margin: 0 0 12px 0; white-space: normal;">
      <p><strong>KH:</strong> {{ shipping_fullname }}</p>
      
      <p><strong>Địa chỉ:</strong> {{ shipping_address_full }}</p>
      
      
      <p><strong>SĐT:</strong> {{ shipping_phone }}</p>
      
      
    </div>
    
    <table style="margin: 0 0 12px 0;font-size: 12px;width:100%;border-top: 2px solid #000;border-bottom: 1px dashed #333;" class="table_detail">
      <thead>
        <tr>
          <th style="width:60%;text-align: left;padding: 5px 0px;">Đgiá/Ggiảm</th>
          <th style="width:10%;text-align: center;padding: 5px 0px;">SL</th>
          <th style="width:30%;text-align: right;padding: 5px ;">T.Tiền</th>
        </tr>
      </thead>
      <tbody>
        
  {{ product_items }}
        
      </tbody>
    </table>
    <table style="font-size: 12px;width: 50%; margin: 0 0 12px 0;text-align: right;float: right;">
      
      <tbody><tr>
        <td colspan="2" style="width: 50%;padding: 5px 0px">Phí vận chuyển: {{ price_shipping }}₫</td>
      </tr>
      
      
      
      <tr>
        <td colspan="2" style="padding: 5px 0px"><strong>Thành tiền: {{ price_final }}₫</strong></td>
      </tr>
      <tr>
        <td colspan="2" style="width: 50%">Tiền phải thanh toán: <strong>{{ debt }}₫</strong></td>
      </tr>
      <tr>
        <td colspan="2" style="width: 50%;padding: 5px 0px">Tiền đã thanh toán: <strong>{{ deposit }}₫</strong></td>
      </tr>
    </tbody></table>
		
		
  </div>
  <div style="clear:both"></div>
  <p>Nếu bạn có thắc mắc, vui lòng liên hệ chúng tôi qua email hotro@namperfume.net  hoặc 19000129</p>
</div>
`;

	const defaultPrintTemplate = `
<div class='body'>
   <center style=" margin-bottom: 0px;">

      <center>
         <p style='font-size:20px;'><strong>[[LOCATION]]</strong></p>
        <p style='font-size:12px;'><strong>[[LOCATION_PHONE]]</strong></p>
      </center>
   </center>
   <div style='font-size:12px'>
      <center>
         <p style='font-size:30px;'><strong>HÓA ĐƠN BÁN HÀNG</strong></p>
      </center>
<center style=" margin-bottom: 0px;">

      <center>
      <p style=" margin-bottom: 2px;"><b>Ngày: [[ORDER_DATE]]</b></p>
      <p style=" margin-bottom: 2px;"><b>Mã ĐH: [[ORDER_ID]]</b></p>
      <p style=" margin-bottom: 2px;"><b>Thu ngân: [[CREATOR]]</b></p>
      <p style=" margin-bottom: 2px;"><b>Khách hàng: [[CUSTOMER_FULL_NAME]] - [[CUSTOMER_PHONE]]</b></p>
      <p style=" margin-bottom: 2px;"><b>Đ/c: [[CUSTOMER_ADDRESS]], [[CUSTOMER_SUB_SUB_REGION]],[[CUSTOMER_SUB_REGION]], [[CUSTOMER_REGION]]</b> </p>
   </div>
   </br>                        <br/>
   <table class='table' width='100%' align='left' border='0px'>
      <thead>
         <tr width='25%' hight='45' style='font-size:13px'>
            <th align='left'>Tên hàng</th>
            <th align='center'>Giá</th>
            <th align='center'>Số lượng</th>
            <th align='right'>Thành tiền</th>
         </tr>
      </thead>
      <tbody style='font-size:13px'>
         <tr width='25%' hight='45' style='font-size:13px'>                    [[ORDER_ITEM_ROW]]                </tr>
      </tbody>
   </table>
   </br>
   <table cellpadding='4' width='100%' style='font-size:12px;line-height: 15px;'>
      <tfoot>
         <tr>
            <td colspan='3' align='right' style='border-top:1px '><strong><big>Thành tiền</big></strong></td>
            <td align='right' style='border-top:1px dashed #000'><strong><big>[[ORDER_SUBTOTAL]]</big></strong></td>
         </tr>
         <tr>
            <td colspan='3' align='right'><b>Giảm giá</b></td>
            <td align='right'><b>[[ORDER_DISCOUNT]]</b></td>
         </tr>
         <tr>
            <td colspan='3' align='right'><b>Giao hàng</b></td>
            <td align='right'><b>[[ORDER_SHIPPING]]<b></td>
         </tr>
         <tr>
            <td colspan='4'  align='right'>
               <p style="margin-bottom: 0.2px;">----------------------------</p>
            </td>
         </tr>
         <tr>
            <td colspan='3' align='right' >
               <p style="margin-bottom: 1px;"><strong><big>Tổng tiền  </big></strong></p>
            </td>
            <td align='right' >
               <p style="margin-bottom: 1px;">  <strong><big>[[ORDER_TOTAL_AMOUNT]]</big></strong></p>
            </td>
         </tr>
          <tr>
             <td colspan='4'  align='left'>

               <p style="margin-bottom: 1px; ">  <strong>[[PAYMENT_METHOD]]</strong></p>
            </td>
         </tr>
         <tr>
            <td colspan='3' align='right' >
               <p style="margin-bottom: 1px;"><strong>Tiền khách đưa </strong></p>
            </td>
            <td align='right' >
               <p style="margin-bottom: 1px;">  <strong>[[ORDER_CUSTOMER_PAID]]</strong></p>
            </td>
         </tr>
         <tr>
            <td colspan='3' align='right' >
               <p style="margin-bottom: 1px;"><strong>Tiền trả lại khách </strong></p>
            </td>
            <td align='right' >
               <p style="margin-bottom: 1px;">  <strong>[[ORDER_CUSTOMER_RETURN]]</strong></p>
            </td>
         </tr>
      </tfoot>
   </table>
   </br>
   <center>

      <i>
         <p style=" margin-bottom: 1px;">Cám ơn bạn và hẹn sớm gặp lại bạn!</p>
      </i>
      <p style=" margin-bottom: 1px;">---***---</p>

   </center>    </center>
</div>
</div>
  `;

	const productReceiptPrintVariableItems: CollapseProps["items"] = [
		{
			key: "1",
			label: "Các biến hỗ trợ trong định dạng in đơn hàng",
			children: <ProductReceiptPrintVariable />,
		},
	];

	return (
		<>
			<Row gutter={16}>
				<Col flex="500px" className="">
					<div>
						<Row gutter={8}>
							<Col span={12}>
								<img
									src={posPrinterImage}
									alt="Printer"
									width={100}
									height={100}
								/>
							</Col>
							<Col span={17}>
								<div className="font-bold text-md">
									{t("setting:pos_print_section")}
								</div>
								<div>{t("setting:pos_print_section_subtitle")}</div>
							</Col>
						</Row>
					</div>

					<div className="p-2 mt-2 bg-white border border-gray-600">
						<iframe height={400} width={500} srcDoc={tem} title="htmlinput" />
					</div>
					<Button
						type="primary"
						className="mt-1"
						size="large"
						icon={<IconReceipt className="mr-1 -mt-0.5" />}
						ghost
						block
						onClick={() => updateHtml(defaultPrintTemplate)}>
						Sử dụng định dạng IN mặc định
					</Button>
				</Col>
				<Col flex="auto">
					<AceEditor
						setOptions={{ useWorker: false }}
						mode="html"
						theme="github"
						onChange={updateHtml}
						name="htmlinput"
						width="100%"
						wrapEnabled={true}
						editorProps={{ $blockScrolling: true }}
						value={html}
					/>

					<div className="py-1">
						<Collapse items={productReceiptPrintVariableItems} />
					</div>
				</Col>
			</Row>

			<div className="">
				<div>
					<Row gutter={8}>
						<Col span={12}>
							<img
								src={posPrinterImage}
								alt="Printer"
								width={100}
								height={100}
							/>
						</Col>
						<Col span={17}>
							<div className="font-bold text-md">{t("Mẫu in hóa đơn")}</div>
						</Col>
					</Row>
				</div>

				<div className="p-2 mt-8 bg-white border border-gray-600">
					<iframe
						height={530}
						className=" w-full"
						srcDoc={tem}
						title="htmlinput"
					/>
				</div>
				<Button
					type="primary"
					className="mt-1"
					size="large"
					icon={<IconReceipt className="mr-1 -mt-0.5" />}
					ghost
					block
					onClick={() => updateHtml(defaultPrintTemplate)}>
					Sử dụng định dạng IN mặc định
				</Button>
			</div>
		</>
	);
};

export default ReceiptTemplateInput;
