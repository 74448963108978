import ShipperModel from "common/models/ShipperModel";
import ShippingHubModel from "common/models/ShippingHubModel";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingTypeModel from "common/models/ShippingTypeModel";

class DeliveryDashboardProvider {
  orderItems: ShippingOrderModel[];
  setOrderItems: (v: ShippingOrderModel[]) => void;
  hubItems: ShippingHubModel[];
  setHubItems: (v: ShippingHubModel[]) => void;
  shipperItems: ShipperModel[];
  setShipperItems: (v: ShipperModel[]) => void;
  shippingTypeItems: ShippingTypeModel[];
  setShippingTypeItems: (v: ShippingTypeModel[]) => void;

  constructor({
    orderItems,
    setOrderItems,
    hubItems,
    setHubItems,
    shipperItems,
    setShipperItems,
    shippingTypeItems,
    setShippingTypeItems,
  }: {
    orderItems: ShippingOrderModel[];
    setOrderItems: (v: ShippingOrderModel[]) => void;
    hubItems: ShippingHubModel[];
    setHubItems: (v: ShippingHubModel[]) => void;
    shipperItems: ShipperModel[];
    setShipperItems: (v: ShipperModel[]) => void;
    shippingTypeItems: ShippingTypeModel[];
    setShippingTypeItems: (v: ShippingTypeModel[]) => void;
  }) {
    this.orderItems = orderItems;
    this.setOrderItems = setOrderItems;
    this.hubItems = hubItems;
    this.setHubItems = setHubItems;
    this.shipperItems = shipperItems;
    this.setShipperItems = setShipperItems;
    this.shippingTypeItems = shippingTypeItems;
    this.setShippingTypeItems = setShippingTypeItems;
  }
}

export default DeliveryDashboardProvider;
