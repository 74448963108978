import DepartmentCollection from 'common/collections/DepartmentCollection';
import NestedCollection from 'common/collections/NestedCollection';
import DepartmentModel from 'common/models/DepartmentModel';
import update from 'immutability-helper';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

import type { GetListProps } from "common/types/Filter";
import type {
  DepartmentJsonWithChildren,
  FilterDepartment,
  DepartmentJsonAddEdit,
} from "common/types/Department";

const SERVICE_URL = "/departments";

class DepartmentApi extends BaseApi {
  async getAll(): Promise<NestedCollection<DepartmentJsonWithChildren>> {
    let allItems: DepartmentJsonWithChildren[] = [];

    let page = 1;

    do {
      let queryData = {
        params: {
          page: page,
          limit: 50,
          sort_by: "display_order",
          sort_type: "ASC",
        },
      };

      const currentPageCollection = await AxiosClient().get<any>(
        SERVICE_URL,
        queryData
      );

      if (currentPageCollection.hasOwnProperty("data")) {
        allItems = update(allItems, {
          $push: currentPageCollection.data.items,
        });

        if (currentPageCollection.data.items.length < queryData.params.limit) {
          break;
        } else {
          page++;
        }
      } else {
        break;
      }
    } while (true);

    let collection = new NestedCollection<DepartmentJsonWithChildren>();
    collection.buildNested(allItems);

    return collection;
  }

  async getList(
    props: GetListProps<FilterDepartment>
  ): Promise<DepartmentCollection> {
    let collection = new DepartmentCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<DepartmentModel> {
    let item = new DepartmentModel(DepartmentModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new DepartmentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: DepartmentJsonAddEdit): Promise<DepartmentModel> {
    let item = new DepartmentModel(DepartmentModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new DepartmentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: DepartmentJsonAddEdit): Promise<DepartmentModel> {
    let item = new DepartmentModel(DepartmentModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new DepartmentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default DepartmentApi;
