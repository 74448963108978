import TextProductVariant from 'components/TextProductVariant';
import React from 'react';

const ProductReceiptDetailProductInfo = ({
  variant_id,
}: {
  variant_id: number;
}) => {
  return <TextProductVariant id={variant_id} />;
};

export default ProductReceiptDetailProductInfo;
