import BaseCollection from "./BaseCollection";

import CommentModel from "common/models/CommentModel";
import { CommentJson, CommentCollectionJson } from "common/types/Comment";

class CommentCollection extends BaseCollection<
  CommentJson,
  CommentModel,
  CommentCollectionJson
> {
  itemsFromJson(jsonItems: CommentJson[]): CommentModel[] {
    return jsonItems.map((item) => new CommentModel(item));
  }
}
export default CommentCollection;
