import { Dropdown, Image, List, Tooltip } from "antd";
import Role from "common/constants/Role";
import FileModel from "common/models/FileModel";
import FileRepository from "common/repositories/FileRepository";
import TextUtil from "common/utils/TextUtil";
import TableDelete from "components/table/TableDelete";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import prettyBytes from "pretty-bytes";
import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import useFileManagerStore from "zustands/useFileManagerStore";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconEye, IconFileX } from "@tabler/icons-react";

import type { MenuProps } from "antd";
import type { ViewType } from "common/types/File";
const FileItemFile = ({
  item,
  viewType,
  highlight,
  onClick,
  onDeleteCompleted,
}: {
  item: FileModel;
  viewType: ViewType;
  highlight: string;
  onClick: (f: FileModel) => void;
  onDeleteCompleted: (f: FileModel) => void;
}) => {
  const { t } = useTranslation();
  const activeFile = useFileManagerStore((state) => state.activeFile);
  const [account, hasRole] = useLoginAccountStore((state) => [
    state.account,
    state.hasRole,
  ]);

  //check if current logged user have the permission to edit this file
  const isEditable =
    hasRole(Role.FILE_MANAGE) ||
    (item.creator_id === account.id && !item.isOldFile());

  const actions = isEditable
    ? [
        <div key="filesize" className="text-xs">
          {prettyBytes(item.size_in_byte)}
        </div>,
        <div key="creator" className="text-xs w-28">
          <TextUser id={item.creator_id} />
        </div>,

        <div key="date_created" className="text-xs w-20">
          <TextDateTime ts={item.date_created} />
        </div>,
        <TableDelete
          key="delete"
          error_translate_prefix="file:form.error"
          onDeleteCallback={(id) => {
            onDeleteCompleted(item);
          }}
          repository={new FileRepository()}
          id={item.id}
          title={t("file:delete_file_confirm")}
        />,
      ]
    : [];

  const dropdownMenu: MenuProps["items"] = isEditable
    ? [
        {
          label: (
            <>
              <TableDelete
                key="delete"
                error_translate_prefix="file:form.error"
                onDeleteCallback={(id) => {
                  onDeleteCompleted(item);
                }}
                repository={new FileRepository()}
                id={item.id}
                title={t("file:delete_file_confirm")}
                label={t("file:delete_file")}
                icon={
                  <IconFileX className="-mt-0.5 mr-1 text-red-400" size="18" />
                }
                buttonBlock={true}
                buttonSize="middle"
              />
            </>
          ),
          key: "delete",
        },
      ]
    : [];

  return (
    <List.Item
      actions={viewType === "list" ? actions : []}
      className={
        viewType === "list"
          ? item.id === activeFile?.id
            ? "bg-yellow-50"
            : "hover:bg-gray-50"
          : ""
      }
    >
      {viewType === "list" ? (
        <div>
          <span
            style={{
              width: 30,
              height: 30,
              display: "inline-block",
              float: "left",
              textAlign: "center",
            }}
          >
            {item.isImage() ? (
              <Image
                height={30}
                width={30}
                style={{ borderRadius: 4 }}
                src={
                  item.isImage() ? item.getThumbnailSquare(30) : item.getIcon()
                }
                preview={{
                  src: item.url,
                  mask: (
                    <Tooltip
                      title={t("file:preview")}
                      placement="left"
                      mouseEnterDelay={0.4}
                    >
                      <IconEye size="16" />
                    </Tooltip>
                  ),
                }}
              />
            ) : (
              <img
                style={{ borderRadius: 4 }}
                height={30}
                width={30}
                src={item.getIcon()}
                alt={item.extension}
              />
            )}
          </span>
          <TableLinkText
            className="pl-2 py-2 mt-1 leading-8"
            onClick={() => {
              onClick(item);
            }}
          >
            {item.title.length > 0 ? (
              <Highlighter
                highlightClassName=""
                searchWords={[highlight]}
                autoEscape={true}
                textToHighlight={item.title}
              />
            ) : (
              <>Untitled</>
            )}
          </TableLinkText>
        </div>
      ) : (
        <Dropdown
          menu={{ items: dropdownMenu, className: "menu-without-item-padding" }}
          trigger={["contextMenu"]}
        >
          <div
            className={
              "rounded border-2   cursor-pointer p-1 " +
              (item.id === activeFile?.id
                ? "border-yellow-100 bg-yellow-50 hover:border-yellow-200"
                : "border-white hover:border-blue-100")
            }
            onClick={(e) => {
              e.preventDefault();
              onClick(item);
            }}
          >
            <div className="text-center">
              {item.isImage() ? (
                <img
                  src={item.getThumbnailSquare(200)}
                  className="rounded"
                  alt={item.title}
                />
              ) : (
                <img
                  src={item.getIcon()}
                  alt={item.title}
                  style={{ width: "100%" }}
                />
              )}
            </div>
            <div className="text-center text-xs text-gray-500 mt-2 break-all">
              {TextUtil.truncateFromMiddle(item.title, 28, "... ")}
            </div>
          </div>
        </Dropdown>
      )}
    </List.Item>
  );
};

export default FileItemFile;
