import ReportCashflowReceiptExpenseWrapper from "features/report/cashflowreceiptexpense/ReportCashflowReceiptExpenseWrapper";
import ReportCashflowReceiptIncomeWrapper from "features/report/cashflowreceiptincome/ReportCashflowReceiptIncomeWrapper";
import ReportCheckin from "features/report/checkin/ReportCheckin";
import ReportCustomerWrapper from "features/report/customer/ReportCustomerWrapper";
import ReportDelivery from "features/report/delivery/ReportDelivery";
import ReportInventoryWrapper from "features/report/inventory/ReportInventoryWrapper";
import ReportLeave from "features/report/leave/ReportLeave";
import ReportOrderWrapper from "features/report/order/ReportOrderWrapper";
import ReportSaleWrapper from "features/report/sale/ReportSaleWrapper";
import ReportView from "features/report/view/ReportView";
import usePathParams from "hooks/usePathParams";
import React from "react";
import { useLocation } from "react-router-dom";

const ReportPage = () => {
  const [pathParams] = usePathParams();
  const location = useLocation();

  let com = null;
  switch (pathParams.action) {
    case "sale":
      com = (
        <ReportSaleWrapper
          siteMenuOpenKey={pathParams.action}
          siteMenuSelectedKey={location.pathname}
        />
      );
      break;
    case "order":
      com = (
        <ReportOrderWrapper
          siteMenuOpenKey={pathParams.action}
          siteMenuSelectedKey={location.pathname}
        />
      );
      break;
    case "customer":
      com = (
        <ReportCustomerWrapper
          siteMenuOpenKey={pathParams.action}
          siteMenuSelectedKey={location.pathname}
        />
      );
      break;
    case "cashflowreceiptincome":
      com = (
        <ReportCashflowReceiptIncomeWrapper
          siteMenuOpenKey={pathParams.action}
          siteMenuSelectedKey={location.pathname}
        />
      );
      break;
    case "cashflowreceiptexpense":
      com = (
        <ReportCashflowReceiptExpenseWrapper
          siteMenuOpenKey={pathParams.action}
          siteMenuSelectedKey={location.pathname}
        />
      );
      break;
    case "inventory":
      com = (
        <ReportInventoryWrapper
          siteMenuOpenKey={pathParams.action}
          siteMenuSelectedKey={location.pathname}
        />
      );
      break;

    case "leave":
      com = <ReportLeave />;
      break;
    case "delivery":
      com = <ReportDelivery />;
      break;
    case "checkin":
      com = <ReportCheckin />;
      break;
    default:
      com = <ReportView />;
  }

  return com;
};

export default ReportPage;
