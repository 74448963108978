import { IconDownload } from "@tabler/icons-react";
import { Button, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ReportDeliveryRepository from "common/repositories/ReportDeliveryRepository";
import { FilterReportDelivery } from "common/types/ReportDelivery";

type Props = {
  filters: FilterReportDelivery;
};

const ReportDeliveryExport = ({ filters }: Props) => {
  const { t } = useTranslation();
  const messageKeyExportPrefix = "export_item_";

  const [processing, setProcessing] = useState(false);

  const onExportExcel = async () => {
    //for null delete (id == 0), we just delete it without call to server
    setProcessing(true);
    message.loading({
      content: t("reportdelivery:export.loading"),
      key: messageKeyExportPrefix + 1,
      duration: 0,
    });

    const deletedErrors = await new ReportDeliveryRepository().exportExcel({
      filters,
    });

    setProcessing(false);
    if (deletedErrors === true) {
      message.success({
        content: t("reportdelivery:export.success"),
        className: "message_success",
        key: messageKeyExportPrefix + 1,
        duration: 1,
      });
    } else {
      message.error({
        content: t("reportdelivery:export.error"),
        className: "message_error",
        key: messageKeyExportPrefix + 1,
        duration: 10,
      });
    }
  };

  return (
    <>
      <Button
        type="primary"
        icon={
          <IconDownload
            size={16}
            stroke={3}
            className="-mt-0.5 hidden sm:inline-block"
          />
        }
        onClick={onExportExcel}
      >
        Xuất báo cáo
      </Button>
    </>
  );
};
export default ReportDeliveryExport;
