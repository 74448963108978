import { AutoComplete, Form, FormItemProps, Input, Space, Tag } from "antd";
import OrderRepository from "common/repositories/OrderRepository";
import PublicationsRepository from "common/repositories/PublicationsRepository";
import StoryRepository from "common/repositories/StoryRepository";
import SupplierRepository from "common/repositories/SupplierRepository";
import { SelectOption } from "common/types/SelectOption";
import React, { useCallback, useEffect, useState } from "react";

export default function SupplierSearchSelect(props: FormItemProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [keyword, setKeyword] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    const collection = await new SupplierRepository().getItems({
      filters: { ...SupplierRepository.getDefaultFilters(), keyword },
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,

        }))
      );
    }
    setLoading(false);
  }, [keyword]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Form.Item {...props}>
        <AutoComplete
          options={options}
          style={{ width: 300 }}
          onSearch={(text) => setKeyword(text)}
        >
          <Input.Search placeholder="nhập từ khóa" />
        </AutoComplete>
      </Form.Item>
    </>
  );
}
