import { Avatar, AvatarProps, Tooltip } from "antd";
import ColorHash from "color-hash";
import { AccountJson } from "common/types/Account";
import { EmployeeJson } from "common/types/Employee";
import { UserJson } from "common/types/User";
import { useMemo } from "react";

const UserAvatar = ({
  user,
  className,
  hideTooltip,
  size,
}: {
  user: AccountJson;
  className?: string;
  hideTooltip?: boolean;
  size?: AvatarProps["size"];
}) => {
  // const url = useMemo(() => {
  //   if (user.avatar.id > 0) {
  //     return new FileModel(user.avatar).getThumbnailSquare(80);
  //   } else {
  //     return "";
  //   }
  // }, [user]);

  const letter = useMemo(() => {
    if (user && user.full_name.length > 0) {
      return user.full_name.split(" ").slice(-1).toString().charAt(0);
    } else {
      return "";
    }
  }, [user]);

  const backgroundColor = useMemo(() => {
    return new ColorHash().hex(user.full_name);
  }, [user]);

  // const avatar =
  //   url.length > 0 ? (
  //     <Avatar src={url} />
  //   ) : (
  //     <Avatar style={{ backgroundColor: backgroundColor }}>{letter}</Avatar>
  //   );
  const avatar = (
    <Avatar style={{ backgroundColor: backgroundColor }}>{letter}</Avatar>
  );

  return (
    <div className={"avatar avatar-sm " + className}>
      {typeof hideTooltip === "undefined" || !hideTooltip ? (
        <Tooltip title={user.full_name}>{avatar}</Tooltip>
      ) : (
        avatar
      )}
    </div>
  );
};

export default UserAvatar;
