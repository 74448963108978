import { Col, Row } from "antd";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartCategory from "features/report/chart/ReportChartCategory";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React, { useCallback } from "react";

const ReportInventoryOutputType = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const formatterTypeValue = useCallback((value: number) => {
    return ProductReceiptModel.getTypeList().find((i) => i.value === value)
      ?.label;
  }, []);
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Số lượng sản phẩm xuất theo loại"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              dataSelect="sum(quantity), productreceipt_type"
              dataFilter="productreceipt_direction = 1,productreceipt_status = 9"
              dataGroupBy="productreceipt_type"
              dataOrderBy="sum(quantity) desc"
              dataJoin="productreceipt"
              keyMapping={{
                name: "productreceipt_type",
                value: "sum_quantity",
                formatter: (value: number) => {
                  return formatterTypeValue(value) || "";
                }
              }}
              height={350}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo xuất kho theo loại"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              keyMapping={[
                {
                  title: "Loại",
                  name: "productreceipt_type",
                  formatter: (value: number | string) => {
                    return <>{formatterTypeValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng số sản phẩm",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(quantity)"
                },
                {
                  title: "Phiếu xuất",
                  name: "countdistinct_receipt",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "countdistinct(receipt)"
                }
              ]}
              dataSelect="countdistinct(receipt), productreceipt_type, sum(quantity)"
              dataFilter="productreceipt_direction = 1,productreceipt_status = 9"
              dataGroupBy="productreceipt_type"
              dataOrderBy="sum(quantity) DESC"
              dataJoin="productreceipt"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportInventoryOutputType;
