import BaseCollection from "common/collections/BaseCollection";
import CustomerTypeModel from "common/models/CustomerTypeModel";
import { CustomerTypeJson, CustomerTypeCollectionJson } from "common/types/CustomerType";

class CustomerTypeCollection extends BaseCollection<
  CustomerTypeJson,
  CustomerTypeModel,
  CustomerTypeCollectionJson
> {
  itemsFromJson(jsonItems: CustomerTypeJson[]): CustomerTypeModel[] {
    return jsonItems.map((item) => new CustomerTypeModel(item));
  }
}

export default CustomerTypeCollection;


