import { Typography } from "antd";
import UserLoginForm from "features/user/userLoginForm";
import { useTranslation } from "react-i18next";

const UserLoginPage = () => {
  const { t } = useTranslation();
  
  return (
    <div className="p-6 mt-4 mx-auto max-w-md">
      <Typography.Title level={3}>{t("user:heading_login")}</Typography.Title>
      <p className="mb-6">{t("user:subtitle_login")}</p>

      <UserLoginForm />

      <div>
        {t("user:form.login_help_text1")} {t("user:form.login_help_text2")}
      </div>
      <div
        style={{ color: "#aaa" }}
        className="sm:my-28  my-12 text-center text-sm "
      >
        {/* {t("common:footer.copyright")} */}
        {`Copyright 2013-${new Date().getFullYear()} © namperfume. All Rights Reserved.`}
      </div>
    </div>
  );
};

export default UserLoginPage;
