import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import ReportCheckinModel from "common/models/ReportCheckinModel";
import { ReportCheckinAddEditJson } from "common/types/ReportCheckin";
const SERVICE_URL = "/employees";
const SERVICE_URL2 = "/workcheckinganalysiss";

class ReportCheckinApi extends BaseApi {
  async exportExcel(
    data: ReportCheckinAddEditJson
  ): Promise<ReportCheckinModel> {
    let item = new ReportCheckinModel(ReportCheckinModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/reports",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ReportCheckinModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async checkFileReport(file_name: string): Promise<string[]> {
    let resultErrors: string[] = [];

    try {
      let queryData = {
        params: {
          file_name: file_name,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL2 + "/checkfiles",
        queryData
      );

      if (response.status < 400) {
        return resultErrors;
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async downloadExcel(file_name: string): Promise<boolean> {
    let queryData = {
      params: {
        file_name: file_name.length > 0 ? file_name : "",
        time: Math.floor(Math.random() * 100),
      },
    };

    try {
      // const response = await AxiosClient().get<any>(
      //   SERVICE_URL + "/export",
      //   queryData
      // );

      let url = process.env.REACT_APP_BASE_URL + SERVICE_URL2 + "/download?";
      Object.entries(queryData.params).forEach(([key, value]) => {
        if (value !== null) {
          url += key + "=" + value + "&";
        }
      });
      let loginAccount = BaseApi.loginAccount;
      url += "__jwtAuthorization=" + loginAccount.jwt;

      window.open(url, "_blank");
    } catch (error) {
      return false;
    }
    return true;
  }
}

export default ReportCheckinApi;
