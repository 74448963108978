import { InputRef } from "rc-input";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Form, Input, Select, Space, message } from "antd";

import { TaskScopeItemJson } from "common/types/Task";
import TaskRepository from "common/repositories/TaskRepository";
import { useTranslation } from "react-i18next";

const FormSelectCustom = ({
  label,
  name,
  options,
}: {
  label: string;
  name: string;
  options: number[];
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<InputRef>(null);

  const [orgId, setOrgId] = useState(0);
  const [items, setItems] = useState<number[]>(options);
  const [listTask, setListTask] = useState<TaskScopeItemJson[]>([]);

  useEffect(() => {
    if (options) {
      setItems(options);
    }
  }, [options]);

  //functions
  const onOrgIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrgId(Number(event.target.value));
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();

    setItems([...items, orgId]);
    setOrgId(0);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  //fetch data
  const fetchListScope = async () => {
    const response = await new TaskRepository().getItemsScope(items);

    if (!response.hasOwnProperty("error")) {
      setListTask(response);
    } else {
      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    }
  };

  useEffect(() => {
    fetchListScope();
  }, [items]);

  return (
    <Form.Item label={label} name={name}>
      <Select
        // style={{ width: 300 }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Input
                placeholder="Please enter item"
                ref={inputRef}
                value={orgId}
                onChange={onOrgIdChange}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                {t("Thêm")}
              </Button>
            </Space>
          </>
        )}
        options={listTask.map((item) => ({
          label: `${item.id} - ${item.name}`,
          value: item.id,
        }))}
      />
    </Form.Item>
  );
};

export default FormSelectCustom;
