import i18nextResource from "i18n_resource";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18next

	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)

	// pass the i18n instance to react-i18next.
	.use(initReactI18next)

	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: "vi_VN",
		debug: false,
		lng: "vi_VN",
		returnNull: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: i18nextResource,
	});

export default i18next;
