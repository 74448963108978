import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";
import { ContractTypeJson } from "common/types/ContractType";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";
class ContractTypeModel
  extends BaseModel
  implements BaseModelJson<ContractTypeJson>
{
  company_id: number;
  id: number;
  name: string;
  status: number;
  work_status: number;
  note: string;
  is_deleted: number;
  date_created: number;
  date_modified: number;
  date_deleted: number;

  constructor(json: ContractTypeJson) {
    super();

    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.status = json.status || 0;
    this.work_status = json.work_status || 0;
    this.note = json.note || "";
    this.is_deleted = json.is_deleted || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_deleted = json.date_deleted || 0;
  }

  static getDefaultData(): ContractTypeJson {
    return {
      company_id: 0,
      id: 0,
      name: "",
      status: 0,
      work_status: 1,
      note: "",
      is_deleted: 0,
      date_created: 0,
      date_modified: 0,
      date_deleted: 0,
    };
  }

  toJson(): ContractTypeJson {
    return {
      company_id: this.company_id,
      id: this.id,
      name: this.name,
      work_status: this.work_status,
      note: this.note,
      status: this.status,
      is_deleted: this.is_deleted,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_deleted: this.date_deleted,
    };
  }

  static getWorkStatus(): SelectOption[] {
    return [
      {
        label: i18n.t("Nhân viên chính thức"),
        value: 1,
      },
      {
        label: i18n.t("Nhân viên thử việc"),
        value: 2,
      },
    ];
  }

  static getStatus(): SelectOption[] {
    return [
      {
        label: i18n.t("Hoàn thiện"),
        value: 1,
      },
      {
        label: i18n.t("Bản nháp"),
        value: 2,
      },
    ];
  }
}

export default ContractTypeModel;
