import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import CashflowReceipt from "common/constants/CashflowReceipt";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import momo from "images/momo.png";
import vnpay from "images/vnpay.png";

import {
  IconCashBanknote,
  IconCreditCard,
  IconGift,
  IconMedal,
  IconUserCircle,
  IconHomeDollar,
} from "@tabler/icons-react";

const TextCashflowReceiptMethod = ({
  method,
  tooltipPlacement,
}: {
  method: number;
  tooltipPlacement?: TooltipPlacement;
}) => {
  let methodInfo = CashflowReceiptModel.getMethod(method);

  const iconStyle = {
    size: 30,
    strokeWidth: 1,
    className: "text-gray-500 hover:text-black",
  };

  let iconCom = null;
  switch (method) {
    case CashflowReceipt.METHOD_CASH:
      iconCom = <IconCashBanknote {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_BANK:
      iconCom = <IconCreditCard {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_GIFTCARD:
      iconCom = <IconGift {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_CUSTOMER_POINT:
      iconCom = <IconMedal {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_MOMO:
      iconCom = <img src={momo} className="inline m-4" width="24" alt="momo" />;
      break;
    case CashflowReceipt.METHOD_VNPAY:
      iconCom = (
        <img src={vnpay} width="24" alt="vnpay" className="inline m-4" />
      );
      break;
    case CashflowReceipt.METHOD_MEMBERCARD:
      iconCom = <IconUserCircle {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_COD:
      iconCom = <IconHomeDollar {...iconStyle} />;
      break;
    default:
  }

  return (
    <span>
      <Tooltip
        title={methodInfo?.label}
        placement={tooltipPlacement || "right"}
      >
        {iconCom}
      </Tooltip>
    </span>
  );
};

export default TextCashflowReceiptMethod;
