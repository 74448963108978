import DeliverySumbyEmployeeModel from "common/models/DeliverySumbyEmployeeModel";
import { FilterDeliverySumByEmployee } from "common/types/DeliverySumbyEmployee";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/deliveries";

class DeliverySumbyEmployeeApi extends BaseApi {
  async getSumByEmployee(
    filters: FilterDeliverySumByEmployee
  ): Promise<DeliverySumbyEmployeeModel> {
    let item = new DeliverySumbyEmployeeModel(
      DeliverySumbyEmployeeModel.getDefaultData()
    );

    try {
      // let filters = props.filters;
      let queryData = {
        params: {
          // key: filters.key,
          actor_id: filters.actor_id > 0 ? filters.actor_id : null,
          date_created: filters.date_created > 0 ? filters.date_created : null,
          date_completed: filters.date_completed > 0 ? filters.date_completed : null,
          // actor_already: 1,
          soandcode: filters.soandcode ? filters.soandcode : null,
          action_code: filters.action_code ? filters.action_code : null,
          keyword: filters.keyword ? filters.keyword : null,
          find_internal: filters.find_internal ? filters.find_internal : null,
          // day: filters.day > 0 ? filters.day : null,
          // month: filters.month > 0 ? filters.month : null,
          // year: filters.year > 0 ? filters.year : null,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/sumbyemployee",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        item = new DeliverySumbyEmployeeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default DeliverySumbyEmployeeApi;
