import WorkScheduleModel from "common/models/WorkScheduleModel";
import WorkScheduleRepository from "common/repositories/WorkScheduleRepository";
import TableDelete from "components/table/TableDelete";
import React, { useMemo } from "react";
import useWorkScheduleManageStore from "zustands/useWorkScheduleManage";

const WorkScheduleManageItem = ({
  model,
  onDeleteSuccess
}: {
  model: WorkScheduleModel;
  onDeleteSuccess: (v: WorkScheduleModel) => void;
}) => {
  const [ranges] = useWorkScheduleManageStore((state) => [state.ranges]);

  const range = useMemo(() => {
    return ranges.find((r) => r.id === model.work_tracking_range_id);
  }, [ranges, model.work_tracking_range_id]);

  if (typeof range === "undefined") {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: range.color }}
      className="h-8 p-2 mb-1 text-xs text-center rounded">
      <span className="float-left">{range.name}</span>
      <TableDelete
        error_translate_prefix="workschedule:form.error"
        className="float-right -mt-1.5 opacity-30 hover:opacity-100"
        onDeleteCallback={(id) => {
          onDeleteSuccess(model);
        }}
        repository={new WorkScheduleRepository()}
        id={model.id}
      />
    </div>
  );
};

export default WorkScheduleManageItem;
