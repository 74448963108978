import React from "react";

type Props = {
  size?: "medium" | "small";
  color?: "main" | "gray";
};

const ThumbUpIcon = ({ size, color = "main" }: Props) => {
  let icon;
  switch (size) {
    case "medium":
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.36334 10.7042L6.17168 12.1042C6.40501 12.3375 6.93001 12.4542 7.28001 12.4542H9.49668C10.1967 12.4542 10.955 11.9292 11.13 11.2292L12.53 6.97086C12.8217 6.15419 12.2967 5.45419 11.4217 5.45419H9.08834C8.73834 5.45419 8.44668 5.16252 8.50501 4.75419L8.79668 2.88752C8.91334 2.36252 8.56334 1.77919 8.03834 1.60419C7.57168 1.42919 6.98834 1.66252 6.75501 2.01252L4.36334 5.57086"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
          />
          <path
            d="M1.38831 10.7042V4.98748C1.38831 4.17082 1.73831 3.87915 2.55497 3.87915H3.13831C3.95497 3.87915 4.30497 4.17082 4.30497 4.98748V10.7042C4.30497 11.5208 3.95497 11.8125 3.13831 11.8125H2.55497C1.73831 11.8125 1.38831 11.5208 1.38831 10.7042Z"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;

    case "small":
      icon = (
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.11664 7.64584L4.4083 8.64584C4.57497 8.81251 4.94997 8.89584 5.19997 8.89584H6.78331C7.28331 8.89584 7.82497 8.52084 7.94997 8.02084L8.94997 4.97917C9.15831 4.39584 8.78331 3.89584 8.15831 3.89584H6.49164C6.24164 3.89584 6.0333 3.68751 6.07497 3.39584L6.2833 2.06251C6.36664 1.68751 6.11664 1.27084 5.74164 1.14584C5.4083 1.02084 4.99164 1.18751 4.82497 1.43751L3.11664 3.97917"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeWidth="0.8"
            strokeMiterlimit={10}
          />
          <path
            d="M0.991638 7.64581V3.56248C0.991638 2.97915 1.24164 2.77081 1.82497 2.77081H2.24164C2.82497 2.77081 3.07497 2.97915 3.07497 3.56248V7.64581C3.07497 8.22915 2.82497 8.43748 2.24164 8.43748H1.82497C1.24164 8.43748 0.991638 8.22915 0.991638 7.64581Z"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.36334 10.7042L6.17168 12.1042C6.40501 12.3375 6.93001 12.4542 7.28001 12.4542H9.49668C10.1967 12.4542 10.955 11.9292 11.13 11.2292L12.53 6.97086C12.8217 6.15419 12.2967 5.45419 11.4217 5.45419H9.08834C8.73834 5.45419 8.44668 5.16252 8.50501 4.75419L8.79668 2.88752C8.91334 2.36252 8.56334 1.77919 8.03834 1.60419C7.57168 1.42919 6.98834 1.66252 6.75501 2.01252L4.36334 5.57086"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
          />
          <path
            d="M1.38831 10.7042V4.98748C1.38831 4.17082 1.73831 3.87915 2.55497 3.87915H3.13831C3.95497 3.87915 4.30497 4.17082 4.30497 4.98748V10.7042C4.30497 11.5208 3.95497 11.8125 3.13831 11.8125H2.55497C1.73831 11.8125 1.38831 11.5208 1.38831 10.7042Z"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
  return icon;
};

export default ThumbUpIcon;
