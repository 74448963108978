import Role from "common/constants/Role";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import TableEdit from "components/table/TableEdit";
import React, { useMemo } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const CashflowReceiptItemEdit = ({
	model,
	children,
	notPermissionFallback,
}: {
	model: CashflowReceiptModel;
	children?: React.ReactNode;
	notPermissionFallback?: React.ReactNode;
}) => {
	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);

	const havePermission = useMemo(() => {
		return (
			hasRoleAndLimit(Role.CASHFLOW_EDIT, model.store_id) ||
			hasRoleAndLimit(Role.CASHFLOW_STATUS_APPROVE, model.store_id) ||
			hasRoleAndLimit(Role.CASHFLOW_STATUS_COMPLETE, model.store_id) ||
			hasRoleAndLimit(Role.CASHFLOW_STATUS_CANCEL, model.store_id)
		);
	}, [hasRoleAndLimit, model.store_id]);

	return (
		<>
			{havePermission ? (
				<>
					{children || (
						<TableEdit link={"/cashflowreceipt/edit/id/" + model.id} />
					)}
				</>
			) : (
				notPermissionFallback
			)}
		</>
	);
};

export default CashflowReceiptItemEdit;
