import EcomChannelOrderCollection from 'common/collections/EcomChannelOrderCollection';
import EcomSyncOrderModel from 'common/models/EcomSyncOrderModel';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

import type { GetListProps } from "common/types/Filter";
import type { FilterEcomChannelOrder } from "common/types/EcomChannelOrder";

const SERVICE_URL = "/ecomchannelorders";

class EcomChannelOrderApi extends BaseApi {
  async getList(
    props: GetListProps<FilterEcomChannelOrder>,
    channel_id: number
  ): Promise<EcomChannelOrderCollection> {
    let collection = new EcomChannelOrderCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          ids: filters.ids.length > 0 ? filters.ids : null,
        },
      };

      const response = await AxiosClient().get<any>(
        `${SERVICE_URL}/${channel_id}`,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async syncOrder(
    channel_id: number,
    order_id: number
  ): Promise<EcomSyncOrderModel> {
    let item = new EcomSyncOrderModel(EcomSyncOrderModel.getDefaultData());

    try {
      const response = await AxiosClient().post<any>(
        `${SERVICE_URL}/sync/${channel_id}/${order_id}`
      );
      if (response.hasOwnProperty("data")) {
        item = new EcomSyncOrderModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default EcomChannelOrderApi;
