import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';

const ReportViewHeader = ({
  heading,
  children,
  nav,
  siteMenuOpenKey,
  siteMenuSelectedKey,
}: {
  heading: string;
  children: React.ReactNode;
  nav?: Nav;
  siteMenuOpenKey?: string;
  siteMenuSelectedKey?: string;
}) => {
  return (
    <PageHeader
      heading={heading}
      nav={nav}
      siteMenuOpenKey={siteMenuOpenKey}
      siteMenuSelectedKey={siteMenuSelectedKey}
    >
      {children}
    </PageHeader>
  );
};

export default ReportViewHeader;
