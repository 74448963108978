import ProjectTaskChangeCollection from "common/collections/ProjectTaskChangeCollection";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type { FilterProjectTaskChange } from "common/types/ProjectTaskChange";

const SERVICE_URL = "/projecttaskchanges";

class ProjectTaskChangeApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProjectTaskChange>,
  ): Promise<ProjectTaskChangeCollection> {
    let collection = new ProjectTaskChangeCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          project_task_id:
            +filters.project_task_id > 0 ? filters.project_task_id : null,
          project_id: +filters.project_id > 0 ? filters.project_id : null,
          company_id: +filters.company_id > 0 ? filters.company_id : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default ProjectTaskChangeApi;
