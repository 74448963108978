const SettingApi = {
	KEY: {
		k_section_footer_namperfume: "section_footer_namperfume",
		k_menu_home: "menu_home",
		k_banner_home: "banner_home",
		k_about_namperfume_1: "about_namperfume_1",
		k_about_namperfume_2: "about_namperfume_2",
		k_categories: "banner_nnn",
		k_settings_website_namperfume_net: "settings_website_namperfume_net",
		k_blog_banner: "shop_blog_banner",
		k_meta_data_home: "meta_data_home",
		k_map_namperfume_store: "map_namperfume_store",
		k_images_namperfume_store_quan_1: "adress_shop_quan_1",
		k_setting_id_store_web_shop: "setting_id_store_web_shop",
		k_setting_web_popup: "setting_web_popup",
		k_footer_menu: "footer_menu",
		k_uat_namefragrance_vn_config: "	uat.namefragrance.vn_config",
		K_custom_landing_page: "custom_landing_page",
		K_custom_landing_page_birthdate: "custom_landing_page_birthdate",
	},
	GROUP: {
		ERP: "ERP",
		SHOP: "SHOP",
		LANGUAGE: "LANGUAGE",
		COMMON: "COMMON",
		PAGE: "PAGE",
		ALL: "ALL",
	},
};

export default SettingApi;
