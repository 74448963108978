const DataGridTableField = {
  TYPE_TEXT: 1,
  TYPE_LONG_TEXT: 3,
  TYPE_LINK_TO_TABLE: 5,
  TYPE_NUMBER: 7,
  TYPE_RATING: 9,
  TYPE_BOOLEAN: 11,
  TYPE_DATE: 13,
  TYPE_URL: 15,
  TYPE_EMAIL: 17,
  TYPE_FILE: 19,
  TYPE_SINGLE_SELECT: 21,
  TYPE_MULTI_SELECT: 23,
  TYPE_PHONE_NUMBER: 25,
  TYPE_COLLABORATOR: 27,
};

export default DataGridTableField;
