import { PosCartPromotionOrder } from 'common/types/PosCart';
import { PromotionCouponCheckResultJson } from 'common/types/PromotionCheck';
import { PromotionCouponJson } from 'common/types/PromotionCoupon';

import BaseModel from './BaseModel';

class PromotionCouponCheckResultModel extends BaseModel {
  coupon?: PromotionCouponJson;
  promotion?: PosCartPromotionOrder;

  constructor(json?: PromotionCouponCheckResultJson) {
    super();

    this.coupon = json?.coupon;
    this.promotion = json?.promotion;
  }

  static getDefaultData(): PromotionCouponCheckResultJson {
    return {
      coupon: undefined,
      promotion: undefined,
    };
  }

  toJson(): PromotionCouponCheckResultJson {
    return {
      coupon: this.coupon,
      promotion: this.promotion,
    };
  }
}

export default PromotionCouponCheckResultModel;
