import BaseCollection from "common/collections/BaseCollection";
import OrderModel from "common/models/OrderModel";
import { OrderJson, OrderCollectionJson } from "common/types/Order";

class OrderCollection extends BaseCollection<
  OrderJson,
  OrderModel,
  OrderCollectionJson
> {
  itemsFromJson(jsonItems: any[]): OrderModel[] {
    return jsonItems.map((item) => new OrderModel(item));
  }
}

export default OrderCollection;
