import BaseRepository from "./BaseRepository";

import {
  FilterTask,
  TaskEmployeeCollectionJson,
  TaskJsonAddEdit,
  TaskJsonEdit,
  TaskMOMCollectionJson,
  TaskScopeItemJson,
} from "common/types/Task";
import TaskApi from "common/api/TaskApi";
import TaskModel from "common/models/TaskModel";
import type { GetListProps } from "common/types/Filter";
import TaskCollection from "common/collections/TaskCollection";
import TaskHistoryCollection from "common/collections/TaskHistoryCollection";

class TaskRepository extends BaseRepository<TaskApi> {
  _api: TaskApi | null;
  constructor() {
    super();
    this._api = new TaskApi(true);
  }

  static getDefaultFilter(): FilterTask {
    return {
      page: 1,
      limit: 20,
      sortby: "org_fromtime",
      sorttype: "DESC",
      keyword: "",
      status: -1,
      parent_id: 0,
      type: -1,
      date_from: -1,
      date_to: -1,
      list_employee_id: "",
      no_child: -1,
      no_parent: -1,
      islate: -1,
    };
  }

  async getItems(props: GetListProps<FilterTask>): Promise<TaskCollection> {
    return this._api ? await this._api.getList(props) : new TaskCollection();
  }

  async getItemsUser(props: GetListProps<FilterTask>): Promise<TaskCollection> {
    return this._api
      ? await this._api.getListUser(props)
      : new TaskCollection();
  }

  async getItemsHistoryTask(
    props: GetListProps<FilterTask>
  ): Promise<TaskHistoryCollection> {
    return this._api
      ? await this._api.getListHistoryTask(props)
      : new TaskHistoryCollection();
  }

  async getItem(id: number): Promise<TaskModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new TaskModel(TaskModel.getDefaultData());
  }

  async getItemsCurrentEmployee(
    props: GetListProps<FilterTask>
  ): Promise<TaskEmployeeCollectionJson> {
    return this._api
      ? await this._api.getListCurrentEmployee(props)
      : {
          total: 0,
          items: [],
          error: [],
        };
  }

  async getItemsMOMEmployee(
    props: GetListProps<Pick<FilterTask, "page" | "limit" | "employee_id">>
  ): Promise<TaskMOMCollectionJson> {
    return this._api
      ? await this._api.getListMOMEmployee(props)
      : {
          total: 0,
          currentpage: 0,
          limit: 0,
          standup_today: [],
          standup_yesterday: [],
          standup_lastndays: [],
          error: [],
        };
  }

  async saveRemote(data: TaskJsonAddEdit): Promise<TaskModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new TaskModel(TaskModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async editStatus(status: number, id: number): Promise<any> {
    return this._api
      ? await this._api.editStatus(status, id)
      : new TaskModel(TaskModel.getDefaultData());
  }

  async editDisplayOrder(
    id: number,
    display_order: number
  ): Promise<TaskModel> {
    return this._api
      ? await this._api.editDisplayOrder(id, display_order)
      : new TaskModel(TaskModel.getDefaultData());
  }

  async getItemsScope(ids: number[]): Promise<TaskScopeItemJson[]> {
    return this._api ? await this._api.getListScope(ids) : [];
  }

  async editKanbanPosition(
    id: number,
    status: number,
    display_order: number
  ): Promise<any> {
    return this._api
      ? await this._api.editKanbanPosition(id, status, display_order)
      : new TaskModel(TaskModel.getDefaultData());
  }

  async editKanbanDisplayOrder(
    id: number,
    display_order: number
  ): Promise<TaskModel> {
    return this._api
      ? await this._api.editKanbanDisplayOrder(id, display_order)
      : new TaskModel(TaskModel.getDefaultData());
  }

  async editKanbanDepartmentPosition(
    id: number,
    status: number,
    display_order: number
  ): Promise<any> {
    return this._api
      ? await this._api.editKanbanDepartmentPosition(id, status, display_order)
      : new TaskModel(TaskModel.getDefaultData());
  }

  async editFullDisplayOrder(
    id: number,
    display_order: number
  ): Promise<TaskModel> {
    return this._api
      ? await this._api.editFullDisplayOrder(id, display_order)
      : new TaskModel(TaskModel.getDefaultData());
  }
}

export default TaskRepository;
