import React from "react";
import { Skeleton, SkeletonProps } from "antd";
import styles from "./styles.module.css";
type Props = {
  children: React.ReactNode;
  loading: boolean;
  isImage?: boolean;
  skeletonProps?: SkeletonProps;
  heightImage?: number;
  styleWrapper?: React.CSSProperties;
};
const AcademySkeleton = ({
  children,
  loading,
  isImage = true,
  skeletonProps,
  heightImage = 150,
  styleWrapper,
}: Props) => {
  return (
    <div
      style={styleWrapper}
      className={`${styles.skeletonWrapper} academy_skeleton`}
    >
      {loading && isImage && (
        <Skeleton.Image style={{ width: "100%", minHeight: heightImage }} />
      )}
      <Skeleton loading={loading} {...skeletonProps}>
        {children}
      </Skeleton>
    </div>
  );
};

export default AcademySkeleton;
