import create, { StateCreator } from 'zustand';

type FieldValue = string | number | null;

type FormDefaultStore = {
  data: Record<string, FieldValue>;
  getValue: (key: string) => FieldValue;
  setValue: (key: string, value: FieldValue) => void;
};

//init store data
let store: StateCreator<FormDefaultStore> = (set, get) => ({
  data: {},
  getValue: (key) => get().data?.[key],
  setValue: (key, value) =>
    set((state) => ({ ...state, data: { ...state.data, [key]: value } })),
});

//create store
const useFormDefaultStore = create(store);

export default useFormDefaultStore;
