import BaseCollection from "common/collections/BaseCollection";
import PurchaseOrderDetailModel from "common/models/PurchaseOrderDetailModel";
import {
  PurchaseOrderDetailCollectionJson,
  PurchaseOrderDetailJson,
} from "common/types/PurchaseOrderDetail";

class PurchaseOrderDetailCollection extends BaseCollection<
  PurchaseOrderDetailJson,
  PurchaseOrderDetailModel,
  PurchaseOrderDetailCollectionJson
> {
  itemsFromJson(
    jsonItems: PurchaseOrderDetailJson[],
  ): PurchaseOrderDetailModel[] {
    return jsonItems.map((item) => new PurchaseOrderDetailModel(item));
  }
}

export default PurchaseOrderDetailCollection;
