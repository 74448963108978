import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import Promotion from 'common/constants/Promotion';
import PromotionModel from 'common/models/PromotionModel';
import FormSelect from 'components/form/FormSelect';
import MoneyInput from 'components/MoneyInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const PromotionFormSeasonal = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={16}>
        <Col md={16} xs={24}>
          <FormSelect
            options={PromotionModel.getTypeListInSeasonal()}
            name="type"
            label={t("promotion:type")}
            disabled={isEditing ? true : false}
            required={true}
            rules={[
              {
                required: true,
                message: t("promotion:form.error.error_type_required"),
              },
            ]}
          />
        </Col>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) => {
          switch (getFieldValue("type")) {
            case Promotion.TYPE_SEASONAL_ORDER_DISCOUNT:
              return (
                <Form.List name="values">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={16}>
                          <Col md={5}>
                            <Form.Item
                              {...restField}
                              name={[name, "from"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "promotion:promotionseasonal.row_from"
                                  ),
                                },
                              ]}
                            >
                              <MoneyInput
                                placeholder={t(
                                  "promotion:promotionseasonal.row_from"
                                )}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "to"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "promotion:promotionseasonal.row_to"
                                  ),
                                },
                              ]}
                            >
                              <MoneyInput
                                placeholder={t(
                                  "promotion:promotionseasonal.row_to"
                                )}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={7}>
                            <Form.Item
                              {...restField}
                              name={[name, "type"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "promotion:promotionseasonal.row_type"
                                  ),
                                },
                              ]}
                            >
                              <Select
                                options={PromotionModel.getValueApplyList()}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={5}>
                            <Form.Item
                              {...restField}
                              name={[name, "value"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "promotion:promotionseasonal.row_value"
                                  ),
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                style={{ width: "100%" }}
                                placeholder={t(
                                  "promotion:promotionseasonal.row_value"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={1}>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Col>
                        </Row>
                      ))}
                      {fields.length < 5 ? (
                        <Row gutter={16}>
                          <Col md={11}>
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                {t("promotion:promotionseasonal.add_row")}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  )}
                </Form.List>
              );
            case Promotion.TYPE_SEASONAL_PRODUCT_DISCOUNT:
              return (
                <Row gutter={16}>
                  <Col md={16} xs={24}>
                    <Form.Item
                      label={t("promotion:value_type")}
                      name="value_type"
                      required
                      rules={[
                        {
                          required: true,
                          message: t(
                            "promotion:form.error.error_value_type_required"
                          ),
                        },
                      ]}
                    >
                      <Select
                        options={PromotionModel.getValueTypeProductList()}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} xs={24}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.value_type !== currentValues.value_type
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("value_type") ===
                        Promotion.VALUETYPE_CURRENCY ? (
                          <Form.Item
                            label={t("promotion:value")}
                            name="value"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "promotion:form.error.error_value_required"
                                ),
                              },
                            ]}
                          >
                            <MoneyInput style={{ width: "100%" }} />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            label={t("promotion:value")}
                            name="value"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "promotion:form.error.error_value_required"
                                ),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              max={100}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </Col>
                </Row>
              );
            case Promotion.TYPE_SEASONAL_PRODUCT_GIFT:
              return (
                <Row gutter={16}>
                  <Col md={16} xs={24}>
                    <Form.Item
                      label={t("promotion:promotionseasonal.min_check")}
                      name="min_check"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "promotion:form.error.error_min_check_down_required"
                          ),
                        },
                      ]}
                    >
                      <InputNumber min={1} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col md={16} xs={24}>
                    <Form.Item
                      label={t("promotion:promotionseasonal.value")}
                      name="value"
                      required
                      rules={[
                        {
                          required: true,
                          message: t(
                            "promotion:form.error.error_min_check_down_required"
                          ),
                        },
                      ]}
                    >
                      <InputNumber min={1} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
              );
            case Promotion.TYPE_SEASONAL_ORDER_BATCH:
              return (
                <Row gutter={16}>
                  <Col md={16} xs={24}>
                    <Form.Item
                      label={t("promotion:promotionseasonal.min_check_down")}
                      name="min_check"
                      required
                      rules={[
                        {
                          required: true,
                          message: t(
                            "promotion:form.error.error_min_check_down_required"
                          ),
                        },
                      ]}
                    >
                      <InputNumber min={1} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col md={16} xs={24}>
                    <Form.Item
                      label={t("promotion:value_type")}
                      name="value_type"
                    >
                      <Select options={PromotionModel.getValueTypeList()} />
                    </Form.Item>
                  </Col>
                  <Col md={16} xs={24}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.value_type !== currentValues.value_type
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("value_type") ===
                        Promotion.VALUETYPE_CURRENCY ? (
                          <Form.Item
                            label={t("promotion:value")}
                            name="value"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "promotion:form.error.error_value_required"
                                ),
                              },
                            ]}
                          >
                            <MoneyInput style={{ width: "100%" }} />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            label={t("promotion:value")}
                            name="value"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "promotion:form.error.error_value_required"
                                ),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              max={100}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </Col>
                </Row>
              );
            case Promotion.TYPE_SEASONAL_ORDER_GIFT:
              return (
                <Row gutter={16}>
                  <Col md={16} xs={24}>
                    <Form.Item
                      label={t("promotion:promotionseasonal.value_order")}
                      name="value"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "promotion:form.error.error_value_order_required"
                          ),
                        },
                      ]}
                    >
                      <MoneyInput style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
              );
            default:
              return <></>;
          }
        }}
      </Form.Item>
    </>
  );
};

export default PromotionFormSeasonal;
