import { IconSearch } from "@tabler/icons-react";
import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import { useTranslation } from "react-i18next";

const DeliveryMineListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["soandcode"]}
      >
        <Form.Item name="keyword">
          <Input
            autoFocus
            allowClear={true}
            placeholder={t("delivery:filter.type_soandcode")}
            prefix={<IconSearch size="16" color="lightgrey" />}
            // suffix={<DeliveryScannerModal />}
          ></Input>
        </Form.Item>
      </PageDataFilterForm>
    </>
  );
};

export default DeliveryMineListFilter;
