import { Button } from "antd";
import SupplierCollection from "common/collections/SupplierCollection";
import Role from "common/constants/Role";
import SupplierModel from "common/models/SupplierModel";
import SupplierRepository from "common/repositories/SupplierRepository";
import { FilterSupplier } from "common/types/Supplier";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import SupplierFormModal from "features/supplier/form/SupplierFormModal";
import SupplierListHeader from "features/supplier/list/SupplierListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SupplierListFilter from "./SupplierListFilter";

const SupplierList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterSupplier = useMemo(
    () => SupplierRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterSupplier>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<SupplierModel[]>([]);
  const fetchData = useCallback(async (): Promise<SupplierCollection> => {
    let collection = await new SupplierRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);

    return collection;
  }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<SupplierModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(record.id)}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("supplier:name"),
      key: "name",
      sortby: "name",
    },
    {
      title: t("supplier:product_type"),
      width: 140,
      key: "product_type",
      sortby: "product_type",
      render: (product_type: number) => {
        let typeInfo = SupplierModel.getProductType(product_type);
        return <>{typeInfo?.label}</>;
      },
    },
    {
      title: t("supplier:contact_fullname"),
      key: "contact_fullname",
      width: 150,
    },
    {
      title: t("supplier:contact_phone"),
      width: 120,
      key: "contact_phone",
    },
    {
      title: t("supplier:contact_email"),
      key: "contact_email",
      width: 200,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: SupplierModel) => (
        <RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="supplier:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new SupplierRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: SupplierModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        })
      );
    }
  };

  return (
    <>
      <SupplierListHeader>
        <RoleCheck roles={[Role.PRODUCT_MANAGE]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </SupplierListHeader>
      <SupplierListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<FilterSupplier, SupplierModel, SupplierCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      {addVisible ? (
        <SupplierFormModal
          id={editingId}
          key={editingId}
          open={addVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingId(0);
            }
            setAddVisible(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      ) : null}
    </>
  );
};

export default SupplierList;
