import { Table } from "antd";
import ReportCollection from "common/collections/BiCenterCollection";
import BiCenterModel from "common/models/BiCenterModel";
import ReportRepository from "common/repositories/BiCenterRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import {
  TableColumnsType,
  TableColumnType,
  TableFilters,
  TablePaginationConfig,
  TableSorter
} from "common/types/Table";
import InfinitePagination from "components/InfinitePagination";
import Error from "components/LayoutError";
import ReportChartDataTableCell from "features/report/chart/ReportChartDataTableCell";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";

import { IconTable } from "@tabler/icons-react";

import type { DataQuery, DateRange } from "common/types/BiCenter";
import type {
  ChartDataTableProps,
  ChartDataTableDatum,
  ChartDataTableKeyMapping
} from "common/types/BiCenterChart";
type DataSourceItem = ChartDataTableDatum & {
  no: number;
  key: number;
};

const ReportChartDataTable = ({
  title,
  subtitle,
  className,
  dateRange,
  keyMapping,
  dataSelect,
  dataService,
  dataTable,
  dataFilter,
  dataGroupBy,
  dataOrderBy,
  dataLimit,
  dataPage,
  dataJoin,
  height,
  fetcherObject,
  fetcherKeyMapping
}: ChartDataTableProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<ChartDataTableDatum[]>([]);
  const [orderBy, setOrderBy] = useState(dataOrderBy || "");
  const [page, setPage] = useState(dataPage || 1);
  const [limit] = useState(dataLimit || 10);

  //load data for chart
  const loadData = useCallback(
    async ({
      range,
      page,
      limit,
      orderBy
    }: {
      range: DateRange;
      page: number;
      limit: number;
      orderBy: string;
    }) => {
      setLoading(true);

      var props: DataQuery = {
        service: dataService,
        start: BiCenterModel.momentToString(range[0]),
        end: BiCenterModel.momentToString(range[1]),
        startcompare: "",
        endcompare: "",
        table: dataTable,
        join: dataJoin || "",
        select: dataSelect,
        filter: dataFilter,
        groupby: dataGroupBy,
        orderby: orderBy,
        limit: limit.toString(),
        offset: ((page - 1) * limit).toString(),
        timeserie: ""
      };

      //Call repository to fetch data
      const collection: ReportCollection =
        await new ReportRepository().queryRemote(props);
      setLoading(false);
      if (collection.hasError()) {
        setErrors(collection.error.errors);
      } else {
        setErrors([]);

        //extract data for chart
        let newData: ChartDataTableDatum[] = [];
        let fetchedData: ChartDataTableDatum[] = [];

        if (collection.items.length === 1) {
          fetchedData = collection.items[0].rows.map((row) => {
            return {
              ...row
            };
          });
        }

        if (
          fetchedData.length > 0 &&
          fetcherObject !== undefined &&
          fetcherKeyMapping !== undefined
        ) {
          let id_list = fetchedData.map(
            (item) => item[fetcherKeyMapping]
          ) as number[];
          const collectionCustomer = await new IdFetcherRepository().doFetching(
            {
              object_type: fetcherObject,
              id_list
            }
          );
          let customerResults =
            collectionCustomer.items.find(
              (i) => i.object_type === fetcherObject
            )?.result || [];
          customerResults.forEach((result, i) => {
            if (result.text === "[invalid]") {
              customerResults[i].text = "---";
            }
          });
          fetchedData.map((i) => {
            let findCustomer = customerResults.find(
              (j) => j.value === i[fetcherKeyMapping]
            );
            return newData.push({
              ...i,
              [fetcherKeyMapping]:
                findCustomer !== undefined ? findCustomer.text : "--"
            });
          });
        }

        setData(newData);
      }
    },
    [
      dataService,
      dataTable,
      dataJoin,
      dataSelect,
      dataFilter,
      dataGroupBy,
      fetcherObject,
      fetcherKeyMapping
    ]
  );

  const dataSource: DataSourceItem[] = data.map((row, index) => {
    return {
      ...row,
      no: (page - 1) * limit + index + 1,
      key: index
    };
  });

  const columns: TableColumnsType<DataSourceItem> = [
    {
      title: "#",
      dataIndex: "no",
      align: "center",
      key: "no",
      width: 10
    },
    ...keyMapping.map(
      (km) =>
        ({
          title: km.title,
          align: km.align,
          dataIndex: km.name,
          key: km.name,
          sorter:
            typeof km.sortField !== "undefined" && km.sortField.length > 0,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (value: number | string) => {
            return (
              <ReportChartDataTableCell value={value} km={km} data={data} />
            );
          }
        } as TableColumnType<DataSourceItem>)
    )
  ];

  const handleTableChange = (
    _: TablePaginationConfig,
    __: TableFilters,
    sorter: TableSorter<DataSourceItem>
  ) => {
    if (!Array.isArray(sorter)) {
      if (
        typeof sorter !== "undefined" &&
        typeof sorter.column !== "undefined" &&
        typeof sorter.columnKey !== "undefined"
      ) {
        //find the column keymap with sortable (sortField)
        const foundKeyMappingColumn = keyMapping.find(
          (km: ChartDataTableKeyMapping) =>
            km.name === sorter.columnKey &&
            typeof km.sortField !== "undefined" &&
            km.sortField.length > 0
        );

        if (typeof foundKeyMappingColumn !== "undefined") {
          setOrderBy(
            foundKeyMappingColumn.sortField +
              " " +
              (sorter.order === "ascend" ? "ASC" : "DESC")
          );
        }
      }
    } else {
      console.log("Do not support multiple sorter");
    }
  };

  //Reload data if change daterange
  useDeepCompareEffect(() => {
    loadData({
      range: dateRange,
      page,
      limit,
      orderBy
    });
  }, [dateRange, page, limit, orderBy, loadData]);

  return (
    <div className={"report_time_series " + className} style={{ height }}>
      <div className="flex">
        <div className="grow">
          <div className="text-2xl font-semibold">
            <IconTable className="-mt-1 text-green-600" /> {title}
          </div>
          {typeof subtitle !== "undefined" ? (
            <div className="ml-8 text-gray-400 text-md">{subtitle}</div>
          ) : null}
        </div>
        <div className="shrink"></div>
      </div>

      {errors.length > 0 ? (
        <Error
          className="report_error"
          heading={t("report:error.query_heading")}
          contentPadding={0}
          translate_prefix="report:error"
          items={errors}
        />
      ) : (
        <div className="mt-4">
          <Table<DataSourceItem>
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            size="small"
            pagination={{
              pageSize: data.length,
              hideOnSinglePage: true
            }}
            onChange={handleTableChange}
          />

          <InfinitePagination
            className="text-right"
            size="small"
            current={page}
            onChange={(newpage) => setPage(newpage)}
            hideOnSinglePage={true}
            isContinue={data.length === limit}
          />
        </div>
      )}
    </div>
  );
};

export default ReportChartDataTable;
