import { useEffect } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const LayoutTitle = ({ title }: { title: string }) => {
  const account = useLoginAccountStore((state) => state.account);

  useEffect(() => {
    document.title = title + " | " + account.company.name;
  }, [title, account.company.name]);

  return <></>;
};

export default LayoutTitle;
