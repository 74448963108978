import { useTranslation } from "react-i18next";

import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";

import { Filter } from "common/types/Filter";
import CollectionModel from "common/models/CollectionModel";

const CollectionListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["type"]}
      >
    
          <FormSelect
            name="type"
            placeholder={t("story:filter.type")}
            options={CollectionModel.getCollectionTypeList()}
            allowClear
            popupMatchSelectWidth={false}
          />
      
      </PageDataFilterForm>
    </>
  );
};

export default CollectionListFilter;
