import { EmployeeJson } from 'common/types/Employee';
import useDatabaseTable from 'hooks/useDatabaseTable';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const TextEmployee = ({
  user_id,
  isSimple,
}: {
  user_id: number;
  isSimple?: boolean;
}) => {
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");
  const { t } = useTranslation();

  const employee = useMemo(() => {
    return employeeItems.find((i) => i.user_id === user_id);
  }, [employeeItems, user_id]);

  if (typeof isSimple !== "undefined" && isSimple) {
    return <>{employee?.full_name}</>;
  } else {
    return (
      <span>
        {employee?.full_name} - {employee?.job_title} ({t("common:phone")}:{" "}
        {employee?.phone}){" "}
      </span>
    );
  }
};

export default TextEmployee;
