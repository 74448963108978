import { LineItem } from 'common/types/EcomChannelOrder';
import TextMoney from 'components/TextMoney';
import React from 'react';

const EcomChannelOrderItemLine = ({ lineitems }: { lineitems: LineItem[] }) => {
  return (
    <ul>
      {lineitems.map((item) => {
        return (
          <li key={item.id} className="flex items-center mb-1 text-xs">
            <div className="bg-gray-200 w-5 h-5 flex justify-center items-center rounded">
              {item.quantity}
            </div>
            <div className="px-1">
              {item.name} (<span>{item.sku}</span>)
            </div>
            x
            <div className="px-1">
              <TextMoney money={item.unitprice} />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default EcomChannelOrderItemLine;
