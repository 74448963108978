import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import {
  ToolQrCodeJsonAddEdit,
  ToolWifiQrCodeJsonAddEdit,
} from "common/types/Tool";
import ToolModel from "common/models/ToolModel";

const SERVICE_URL = "/tools";

class ToolApi extends BaseApi {
  //
  async addQrCode(data: ToolQrCodeJsonAddEdit): Promise<any> {
    let item = new ToolModel(ToolModel.getDefaultData());
    try {
      let postData = { ...data };
      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/qrcode",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ToolModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async addWifiQrCode(data: ToolWifiQrCodeJsonAddEdit): Promise<any> {
    let item = new ToolModel(ToolModel.getDefaultData());
    try {
      let postData = { ...data };
      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/wifiqrcode",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ToolModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
}

export default ToolApi;
