import { TagTypeJson } from "common/types/TagType";
import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";

const defaultData: TagTypeJson = {
  id: 0,
  creator_id: 0,
  company_id: 0,
  name: "",
  code: "",
  status: 0,
  is_deleted: 0,
  date_created: 0,
  date_modified: 0,
  date_deleted: 0,
  resource_type: 0,
};

class TagTypeModel extends BaseModel implements BaseModelJson<TagTypeJson> {
  id: number = defaultData.id;
  creator_id: number = defaultData.creator_id;
  company_id: number = defaultData.company_id;
  name: string = defaultData.name;
  code: string = defaultData.code;
  status: number = defaultData.status;
  is_deleted: number = defaultData.is_deleted;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;
  date_deleted: number = defaultData.date_deleted;
  resource_type: number = defaultData.resource_type;

  constructor(json: TagTypeJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): TagTypeJson {
    return { ...defaultData };
  }
  toJson(): TagTypeJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getStatusSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Hiện",
      },
      {
        value: 2,
        label: "Nháp",
      },
      {
        value: 3,
        label: "Ẩn",
      },
    ];
  }

  static getResouceTypeValue(value: number): string {
    switch (value) {
      case 1:
        return "Sản phẩm";
      case 2:
        return "Bài viết";
      case 3:
        return "Danh mục sản phẩm";
      case 4:
        return "Danh mục bài viết";
      case 5:
        return "Thương hiệu";
      default:
        return "";
    }
  }

  static getNameTypeTranslate(value: string): string {
    switch (value) {
      case "PRODUCT PREMIUM":
        return "Tag premium";
      case "PRODUCT RELATED CARD":
        return "Tag chương trình";
      case "PRODUCT SIZE":
        return "Tag dung tích";
      case "PRODUCT TESTER":
        return "Tag tester";
      case "PRODUCT POLICY":
        return "Tag chính sách";
      case "PRODUCT PERFAMER":
        return "Tag nhóm hương";
      case "PRODUCT GENDER":
        return "Tag giới tính";
      case "PRODUCT STRUCT":
        return "Tag cấu trúc";
      case "PRODUCT CARD":
        return "Tag trên card sản phẩm";
      default:
        return value;
    }
  }

  static getResouceTypeList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Sản phẩm",
      },
      {
        value: 2,
        label: "Bài viết",
      },
      {
        value: 3,
        label: "Danh mục sản phẩm",
      },
      {
        value: 4,
        label: "Danh mục bài viết",
      },
      {
        value: 5,
        label: "Thương hiệu",
      },
    ];
  }
}

export default TagTypeModel;
