import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShipperEditorHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("shipper:heading_nav"),
      link: "/shipper",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(isEditing ? "shipper:heading_edit" : "shipper:heading_add")}
      siteMenuSelectedKey="/shipper"
      siteMenuHidden
    ></PageHeader>
  );
};

export default ShipperEditorHeader;
