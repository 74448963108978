import { Form, Input, TimeRangePickerProps } from "antd";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import { Filter } from "common/types/Filter";
import Helper from "common/utils/helper";
import DateFormFilter from "components/datetime/DateFormFilter";
import TimeFormFilter from "components/datetime/TimeFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import ShipperFormFilter from "features/shipper/ShipperFormFilter";
import ShippingHubFormFilter from "features/shippinghub/ShippingHubFormFilter";
import ShippingTypeFormFilter from "features/shippingtype/ShippingTypeFormFilter";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import type { RangePickerProps } from "antd/es/date-picker";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const ShippingOrderListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();
  const locationSearch = useLocation();

  const checkHiddenFilterRouteId = useMemo(() => {
    return (
      typeof Object.fromEntries(
        new URLSearchParams(locationSearch.search).entries()
      )["route_id"] !== "undefined" &&
      Object.fromEntries(new URLSearchParams(locationSearch.search).entries())[
        "route_id"
      ] !== ""
    );
  }, [locationSearch.search]);

  // FILTER: YMDSTART AND YMDEND
  const getYmdFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "ymdstart" || key === "ymdend")
      .map(([key, val]) => {
        return val;
      }) as number[];
    return (
      +entries[0] > 0 && +entries[1] > 0
        ? [
            dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
            dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY")
          ] || null
        : [null, null] || null
    ) as RangeValue;
  }, [filters]);
  const onChangeYmdFilter: RangePickerProps["onChange"] = (
    value: RangePickerProps["value"],
    dateString: [string, string]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        ymdstart: dayjs(
          "00:00, " + value[0]?.format("DD/MM/YYYY").toString(),
          "HH:mm, DDMMYYYY"
        ).unix(),
        ymdend: dayjs(
          "00:00, " + value[1]?.format("DD/MM/YYYY").toString(),
          "HH:mm, DDMMYYYY"
        ).unix()
      });
    } else {
      setFilters({
        ...filters,
        ymdstart: -1,
        ymdend: -1
      });
    }
  };
  // FILTER: HOURSTART AND HOUREND
  const getHourFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "hourstart" || key === "hourend")
      .map(([key, val]) => {
        return val;
      }) as number[];
    return (
      +entries[0] >= 0 && +entries[1] >= 0
        ? [
            dayjs(Helper.checkTimeString(entries[0]), "HH:mm"),
            dayjs(Helper.checkTimeString(entries[1]), "HH:mm")
          ] || null
        : [null, null] || null
    ) as RangeValue;
  }, [filters]);
  const onChangeHourFilter: TimeRangePickerProps["onChange"] = (
    value: TimeRangePickerProps["value"],
    dateString: [string, string]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        hourstart: value[0]?.format("HHmm"),
        hourend: value[1]?.format("HHmm")
      });
    } else {
      setFilters({
        ...filters,
        hourstart: -1,
        hourend: -1
      });
    }
  };
  // FILTER: DATESTART AND DATEEND
  const getDateCreatedFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "date_started" || key === "date_ended")
      .map(([key, val]) => {
        return val;
      }) as number[];
    return (
      +entries[0] > 0 && +entries[1] > 0
        ? [
            dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
            dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY")
          ] || null
        : [null, null] || null
    ) as RangeValue;
  }, [filters]);
  const onChangeDateCreatedFilter: RangePickerProps["onChange"] = (
    value: RangePickerProps["value"],
    dateString: [string, string]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        date_started: +dayjs(
          "00:00, " + value[0]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix(),
        date_ended: +dayjs(
          "23:59, " + value[1]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix()
      });
    } else {
      setFilters({
        ...filters,
        date_started: -1,
        date_ended: -1
      });
    }
  };

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={[
        "route_id",
        "shipper_id",
        "type",
        "shipping_type",
        "office_id",
        "status"
      ]}>
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("shippingorder:filter_keyword_placeholder")}
        />
      </Form.Item>

      <Form.Item name="route_id" hidden={!checkHiddenFilterRouteId}>
        <Input
          allowClear
          placeholder={t("shippingorder:filter_route_id_placeholder")}
        />
      </Form.Item>

      <ShipperFormFilter
        name="shipper_id"
        allowClear
        placeholder={t("shippingorder:filter_shipper_placeholder")}
        popupMatchSelectWidth={false}
      />

      <FormSelect
        name="type"
        options={ShippingOrderModel.getTypeList()}
        allowClear
        placeholder={t("shippingorder:filter_type_placeholder")}
        popupMatchSelectWidth={false}
      />

      <ShippingTypeFormFilter
        name="shipping_type"
        allowClear
        placeholder={t("shippingorder:filter_shipping_type_placehoder")}
        popupMatchSelectWidth={false}
      />

      <ShippingHubFormFilter
        name="office_id"
        allowClear
        placeholder={t("shippingorder:filter_hub_placehoder")}
        popupMatchSelectWidth={false}
      />

      <FormSelect
        name="status"
        options={ShippingOrderModel.getStatusList()}
        allowClear
        placeholder={t("shippingorder:filter_status_placehoder")}
        popupMatchSelectWidth={false}
      />

      {/* FILTER: YMDSTART AND YMDEND */}
      <Form.Item label={t("deliveryplanning:filter_ymd")} labelCol={{span: 6}}>
        <DateFormFilter
          rangeValue={getYmdFilter}
          allowClear={true}
          showNow={false}
          mode="range"
          format="DD/MM/YYYY"
          onRangeChange={onChangeYmdFilter}
        />
      </Form.Item>

      {/* FILTER: HOURSTART AND HOUREND */}
      <Form.Item label={t("deliveryplanning:filter_hour")} labelCol={{span: 6}}>
        <TimeFormFilter
          rangeValue={getHourFilter}
          allowClear={true}
          showNow={false}
          mode="range"
          format="HH:mm"
          onRangeChange={onChangeHourFilter}
        />
      </Form.Item>

      {/* FILTER: DATESTART AND DATEEND */}
      <Form.Item label={t("common:date_created")} labelCol={{span: 6}}>
        <DateFormFilter
          rangeValue={getDateCreatedFilter}
          allowClear={true}
          showNow={false}
          mode="range"
          format="DD/MM/YYYY"
          onRangeChange={onChangeDateCreatedFilter}
        />
      </Form.Item>
    </PageDataFilterForm>
  );
};

export default ShippingOrderListFilter;
