import BaseRepository from "./BaseRepository";

import PhoneBookApi from "common/api/PhoneBookApi";
import type { GetListProps } from "common/types/Filter";
import { FilterPhoneBook } from "common/types/PhoneBook";
import PhoneBookModel from "common/models/PhoneBookModel";
import PhoneBookCollection from "common/collections/PhoneBookCollection";

class PhoneBookRepository extends BaseRepository<PhoneBookApi> {
	_api: PhoneBookApi | null;
	constructor() {
		super();
		this._api = new PhoneBookApi(true);
	}

	static getDefaultFilter(): FilterPhoneBook {
		return {
			page: 1,
			limit: 24,
			sortby: "",
			sorttype: "",

			sort_by: "last_checkin",
			sort_type: "DESC",
			status: -1,
			keyword: "",
			is_not_deleted: 1,
			department_id: -1,
			office_id: -1,
			today_checkin: -1,

			to_last_checkin: -1,
			from_last_checkin: -1,
			to_last_checkout: -1,
			from_last_checkout: -1,
			from_last_login: -1,
			to_last_login: -1,
		};
	}

	async getItems(
		props: GetListProps<FilterPhoneBook>
	): Promise<PhoneBookCollection> {
		return this._api
			? await this._api.getList(props)
			: new PhoneBookCollection();
	}

	async getItem(id: number): Promise<PhoneBookModel> {
		return this._api
			? await this._api.getDetail(id)
			: new PhoneBookModel(PhoneBookModel.getDefaultData());
	}
}

export default PhoneBookRepository;
