import WorkScheduleApi from 'common/api/WorkScheduleApi';
import WorkScheduleCollection from 'common/collections/WorkScheduleCollection';
import WorkScheduleModel from 'common/models/WorkScheduleModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  WorkScheduleJsonAddEdit,
  FilterWorkSchedule,
} from "common/types/WorkSchedule";

class WorkScheduleRepository extends BaseRepository<WorkScheduleApi> {
  _api: WorkScheduleApi | null;

  constructor() {
    super();
    this._api = new WorkScheduleApi(true);
  }

  static getDefaultFilters(): FilterWorkSchedule {
    return {
      page: 1,
      limit: 100,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      user_id: -1,
      office_id: -1,
      work_tracking_range_id: -1,
      date_schedule_started: -1,
      date_schedule_ended: -1,
      date_scheduled: -1,
      company_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterWorkSchedule>) {
    return this._api
      ? await this._api.getList(props)
      : new WorkScheduleCollection();
  }

  async getMine(props: GetListProps<FilterWorkSchedule>) {
    return this._api
      ? await this._api.getMineList(props)
      : new WorkScheduleCollection();
  }

  async getItem(id: number): Promise<WorkScheduleModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new WorkScheduleModel(WorkScheduleModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: WorkScheduleJsonAddEdit): Promise<WorkScheduleModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new WorkScheduleModel(WorkScheduleModel.getDefaultData());
    }
  }
}

export default WorkScheduleRepository;
