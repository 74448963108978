import ProductTransmitApi from 'common/api/ProductTransmitApi';
import ProductTransmitCollection from 'common/collections/ProductTransmitCollection';
import ProductTransmitModel from 'common/models/ProductTransmitModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProductTransmitJsonAddEdit,
  FilterProductTransmit,
} from "common/types/ProductTransmit";

class ProductTransmitRepository extends BaseRepository<ProductTransmitApi> {
  _api: ProductTransmitApi | null;

  constructor() {
    super();
    this._api = new ProductTransmitApi(true);
  }

  static getDefaultFilters(): FilterProductTransmit {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      tag: "",
      warehouse_id_from: -1,
      warehouse_id_to: -1,
      id: -1,
    };
  }

  async getItems(props: GetListProps<FilterProductTransmit>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductTransmitCollection();
  }

  async getItem(id: number): Promise<ProductTransmitModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductTransmitModel(ProductTransmitModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductTransmitJsonAddEdit
  ): Promise<ProductTransmitModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductTransmitModel(ProductTransmitModel.getDefaultData());
    }
  }

  async approve(id: number): Promise<ProductTransmitModel> {
    return this._api
      ? await this._api.approve(id)
      : new ProductTransmitModel(ProductTransmitModel.getDefaultData());
  }

  async completed(id: number): Promise<ProductTransmitModel> {
    return this._api
      ? await this._api.completed(id)
      : new ProductTransmitModel(ProductTransmitModel.getDefaultData());
  }

  async reject(id: number): Promise<ProductTransmitModel> {
    return this._api
      ? await this._api.reject(id)
      : new ProductTransmitModel(ProductTransmitModel.getDefaultData());
  }
}

export default ProductTransmitRepository;
