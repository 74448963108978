import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import dbm from "dbm";
import React, { useCallback, useEffect, useState } from "react";

const TextUser = ({ id }: { id: number }) => {
	const [employee, setEmployee] = useState<EmployeeModel>(
		new EmployeeModel(EmployeeModel.getDefaultData())
	);

	const getDetailEmp = useCallback(async () => {
		const res = await new EmployeeRepository().getItem(id);
		if (!res.hasError()) {
			setEmployee(res);
		}
	}, [id]);

	useEffect(() => {
		if (id > 0 && employee.id === 0) {
			(async () => {
				const collection = dbm.getCollection("employee");
				if (typeof collection !== "undefined") {
					const foundItems = await collection
						?.chain()
						.find({
							user_id: id,
						})
						.limit(1)
						.data();

					if (foundItems && foundItems.length > 0) {
						setEmployee(new EmployeeModel(foundItems[0]));
					} else {
						getDetailEmp();
					}
				}
			})();
		}
	});

	// useEffect(() => {

	//   getDetailEmp()
	// },[getDetailEmp])

	return <span>{employee.full_name}</span>;
};

export default TextUser;
