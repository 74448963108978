import React from "react";
import ProductList from "features/product/list/ProductList";
import usePathParams from "hooks/usePathParams";

const ProductPage = () => {
	const [pathParams] = usePathParams();

	let com = null;
	const data1 = [
		{
			id: 1000032907,
			handle: "deal-thom",
			title: "Tuần Lễ Thương Hiệu",
			type: "collection",
			from_time: 1712831589,
			to_time: 0,
			link: "/collections/tuan-le-thuong-hieu",
			link_more: "/collections/tuan-le-thuong-hieu",
			title_more: "Xem tất cả",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "tuan-le-thuong-hieu",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			id: 1000032907,
			type: "collection",
			title: "Deal Thơm",
			title_more: "Xem tất cả",
			handle: "deal-thom",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/deal-thom",
			link_more: "collections/deal-thom",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "deal-thom",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			id: 1000032901,
			type: "collection",
			title: "New Arrivals",
			title_more: "Xem tất cả",
			handle: "nuoc-hoa-moi",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-moi",
			link_more: "collections/nuoc-hoa-moi",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-moi",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 1000032931,
			title: "Bestsellers",
			title_more: "Xem tất cả",
			handle: "yeu-thich-nhat",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/yeu-thich-nhat",
			link_more: "collections/yeu-thich-nhat",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "yeu-thich-nhat",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 0,
			title: "Tuần lễ thương hiệu Hugo Boss",
			title_more: "Xem tất cả",
			handle: "thuong-hieu-hugo-boss",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/thuong-hieu-hugo-boss",
			link_more: "collections/thuong-hieu-hugo-boss",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "tuan-le-thuong-hieu-hugo-boss",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "section_banner",
			id: 0,
			handle: "banner_nnn",
			from_time: 1712136268,
			title_more: "Xem thêm",
			to_time: 0,
			link: "",
			link_more: "",
			title: "",
		},
		{
			type: "collection",
			id: 1010720,
			title: "Mini & Travel Size",
			title_more: "Xem tất cả",
			handle: "nuoc-hoa-mini",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-mini",
			link_more: "collections/nuoc-hoa-mini",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-mini",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 456353,
			title: "Giftset",
			title_more: "Xem thêm",
			handle: "giftset-nuoc-hoa",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/giftset-nuoc-hoa",
			link_more: "collections/giftset-nuoc-hoa",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "giftset-nuoc-hoa",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 0,
			title: "Bodycare & Homecare",
			title_more: "Xem tất cả",
			handle: "bodycare-homecare",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/bodycare-homecare",
			link_more: "collections/bodycare-homecare",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "bodycare-homecare",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "namperfumetv",
			id: 0,
			handle: "",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "namperfume TV",
			title_more: "Xem thêm",
		},
		{
			type: "nmagazine",
			id: 509,
			handle: "brand_nmagazine",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Nmagazine",
			title_more: "Xem thêm",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_1",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_2",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
		},
	];

	switch (pathParams.action) {
		case "edit":
			break;

		default:
			com = <ProductList />;
	}

	return com;
};

export default ProductPage;
