import { Dropdown, MenuProps } from "antd";
import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import { useTranslation } from "react-i18next";

import {
  IconAlignBoxCenterMiddle,
  IconAlignBoxLeftMiddle,
  IconAlignBoxRightMiddle,
  IconAlignCenter,
  IconAlignJustified,
  IconAlignLeft,
  IconAlignRight,
} from "@tabler/icons-react";

import HtmlEditorMenuBarButton from "./HtmlEditorMenuBarButton";

const HtmlEditorMenuBarButtonTextAlign = ({
  editor,
}: {
  editor: HtmlEditor;
}) => {
  const { t } = useTranslation("htmleditor");

  if (!editor) {
    return null;
  }

  const onClickItem = ({ key }: { key: string }) => {
    switch (key) {
      case "textalign_left":
        editor.chain().focus().setTextAlign("left").run();
        break;
      case "textalign_center":
        editor.chain().focus().setTextAlign("center").run();
        break;
      case "textalign_right":
        editor.chain().focus().setTextAlign("right").run();
        break;

      default:
    }
  };

  let align = "left";
  if (editor?.isActive({ textAlign: "justify" })) {
    align = "justify";
  } else if (editor?.isActive({ textAlign: "center" })) {
    align = "center";
  } else if (editor?.isActive({ textAlign: "right" })) {
    align = "right";
  }

  let icon = <IconAlignLeft />;
  switch (align) {
    case "center":
      icon = <IconAlignCenter />;
      break;
    case "right":
      icon = <IconAlignRight />;
      break;
    case "justify":
      icon = <IconAlignJustified />;
      break;
  }

  const iconProps = { size: 18 };

  const items: MenuProps["items"] = [
    {
      key: "textalign_left",
      label: t("textalign.textalign_left"),
      icon:
        align === "left" ? (
          <IconAlignBoxLeftMiddle {...iconProps} />
        ) : (
          <IconAlignLeft {...iconProps} />
        ),
    },
    {
      key: "textalign_center",
      label: t("textalign.textalign_center"),
      icon:
        align === "center" ? (
          <IconAlignBoxCenterMiddle {...iconProps} />
        ) : (
          <IconAlignCenter {...iconProps} />
        ),
    },
    {
      key: "textalign_right",
      label: t("textalign.textalign_right"),
      icon:
        align === "right" ? (
          <IconAlignBoxRightMiddle {...iconProps} />
        ) : (
          <IconAlignRight {...iconProps} />
        ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items, onClick: onClickItem }} trigger={["click"]}>
        <HtmlEditorMenuBarButton
          active={false}
          disabled={false}
          icon={icon}
          title={""}
          onClick={() => {}}
          showCaret
        />
      </Dropdown>
    </>
  );
};

export default HtmlEditorMenuBarButtonTextAlign;
