import ShippingCarrierList from 'features/shippingcarrier/list/ShippingCarrierList';
import usePathParams from 'hooks/usePathParams';
import React from 'react';

const ShippingCarrierPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <ShippingCarrierList />;
  }

  return com;
};

export default ShippingCarrierPage;
