import { Button } from "antd";
import ProductVariantColorCollection from "common/collections/ProductVariantColorCollection";
import Role from "common/constants/Role";
import ProductVariantColorModel from "common/models/ProductVariantColorModel";
import ProductVariantColorRepository from "common/repositories/ProductVariantColorRepository";
import { FilterProductVariantColor } from "common/types/ProductVariantColor";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import ProductVariantColorFormModal from "features/productvariantcolor/form/ProductVariantColorFormModal";
import ProductVariantColorListHeader from "features/productvariantcolor/list/ProductVariantColorListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductVariantColorListFilter from "./ProductVariantColorListFilter";

const ProductVariantColorList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterProductVariantColor = useMemo(
    () => ProductVariantColorRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterProductVariantColor>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ProductVariantColorModel[]>([]);
  const fetchData =
    useCallback(async (): Promise<ProductVariantColorCollection> => {
      let collection = await new ProductVariantColorRepository().getItems({
        filters,
      });
      setDataSource(collection.items);
      setTotal(collection.total);

      return collection;
    }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<ProductVariantColorModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 100,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(record.id)}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("productvariantcolor:name"),
      key: "name",
      sortby: "name",
      width: 200,
    },
    {
      title: t("productvariantcolor:description"),
      key: "description",
    },
    {
      title: t("common:display_order_long"),
      width: 150,
      align: "center",
      key: "display_order",
      sortby: "display_order",
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: ProductVariantColorModel) => (
        <RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="productvariantcolor:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ProductVariantColorRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: ProductVariantColorModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        })
      );
    }
  };

  return (
    <>
      <ProductVariantColorListHeader>
        <RoleCheck roles={[Role.PRODUCT_MANAGE]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </ProductVariantColorListHeader>
      <ProductVariantColorListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterProductVariantColor,
        ProductVariantColorModel,
        ProductVariantColorCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <ProductVariantColorFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ProductVariantColorList;
