import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import React from "react";

import { IconReportMoney, IconShoppingCart } from "@tabler/icons-react";

import ReportChartNumber from "../chart/ReportChartNumber";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportOrderGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số đơn hàng"}
              icon={<IconShoppingCart size="18" />}
              dataService="order"
              keyMapping="count_id"
              dataTable="order"
              dataSelect="count(id)"
              dataFilter="status < 20"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số sản phẩm bán ra"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="sum_quantity"
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="sum(quantity)"
              dataFilter="order_status < 20"
              dataJoin="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Sản phẩm có đơn hàng"}
              icon={<IconReportMoney size="18" />}
              keyMapping="countdistinct_product"
              dataTable="orderdetail"
              dataSelect="countdistinct(product)"
              dataFilter="order_status < 20"
              dataJoin="order"
              dataService="orderdetail"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"SKU có đơn hàng"}
              icon={<IconReportMoney size="18" />}
              keyMapping="countdistinct_sku"
              dataTable="orderdetail"
              dataSelect="countdistinct(sku)"
              dataFilter="order_status < 20"
              dataJoin="order"
              dataService="orderdetail"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng số lượng bán ra"}
              dataTable="orderdetail"
              dataSelect="sum(quantity)"
              dataFilter="order_status < 20"
              dataGroupBy=""
              dataOrderBy=""
              dataJoin="order"
              keyMapping={{ name: "comparetext", value: "sum_quantity" }}
              height={350}
              dataService="orderdetail"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportOrderGeneral;
