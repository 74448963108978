import { Button, TableColumnsType, Tag, Tooltip, message } from "antd";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import RoleCheck from "components/RoleCheck";

import PageDataTable from "components/page/PageDataTable";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";

import StoryCollection from "common/collections/StoryCollection";
import StoryCollectionCollection from "common/collections/StoryCollectionCollection";
import Role from "common/constants/Role";
import StoryCollectionModel from "common/models/StoryCollectionModel";
import StoryCollectionRepository from "common/repositories/StoryCollectionRepository";
import StoryRepository from "common/repositories/StoryRepository";
import { FilterStoryCollection } from "common/types/StoryCollection";
import PageDataPagination from "components/page/PageDataPagination";
import StoryCollectionListFilter from "./StoryCollectionFilter";
import StoryCollectionListHeader from "./StoryCollectionListHeader";
import { IconTag } from "@tabler/icons-react";
import StoryCollectionTagModal from "../tag/StoryCollectionTagModal";

const StoryCollectionList = () => {
  const { t } = useTranslation();

  // default filter
  const defaultFilters = useMemo(() => {
    return StoryCollectionRepository.getDefaultFilters();
  }, []);

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<StoryCollectionModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //modal tag
  const [addTagVisible, setAddTagVisible] = useState(false);
  const [editingTagId, setEditingTagId] = useState(0);
  const onEditTag = (id: number) => {
    setEditingTagId(id);
    setAddTagVisible(true);
  };

  //Table columns
  const columns: TableColumnsType<StoryCollectionModel> = [
    {
      title: t("common:columns.id"),
      key: "id",
      width: 100,
      render: (id) => {
        return <Tag color="blue">{id}</Tag>;
      },
    },
    {
      title: t("common:columns.title"),
      key: "title",
      width: 200,
      render: (title: string) => <p className="line-clamp-2 m-0">{title}</p>,
    },
    {
      title: t(""),
      key: "actions",
      width: 100,
      align: "right",
      fixed: "right",
      render: (_: any, record: StoryCollectionModel) => (
        <>
          <TableEdit link={`/storycollection/edit/id/${record.id}`} />
          <Button
            size={"small"}
            type={"text"}
            onClick={() => onEditTag(record.id)}
          >
            <Tooltip title="Quản lý tag">
              <IconTag
                className="-mt-0.5 text-gray-400 hover:text-red-500"
                size="18"
              />
            </Tooltip>
          </Button>
          <TableDelete
            error_translate_prefix="department:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
              fetchData();
            }}
            repository={new StoryCollectionRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </>
      ),
    },
  ];

  //function
  const fetchData =
    useCallback(async (): Promise<StoryCollectionCollection> => {
      const response = await new StoryCollectionRepository().getItems({
        filters: filters,
      });

      if (response) {
        setTotal(response.total);
        setDataSource(response.items);
      } else {
        message.error(t("common:error.error_fetching_data"));
      }

      return response;
    }, [filters]);

  return (
    <div>
      <StoryCollectionListHeader />
      <StoryCollectionListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<
        FilterStoryCollection,
        StoryCollectionModel,
        StoryCollectionCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "storycollection",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <StoryCollectionTagModal
        id={editingTagId}
        key={editingTagId}
        open={addTagVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingTagId(0);
          }
          setAddTagVisible(isOpen);
        }}
      />
    </div>
  );
};

export default StoryCollectionList;
