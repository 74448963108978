import BaseCollection from "common/collections/BaseCollection";
import ProductTransmitModel from "common/models/ProductTransmitModel";
import { ProductTransmitJson, ProductTransmitCollectionJson } from "common/types/ProductTransmit";

class ProductTransmitCollection extends BaseCollection<
  ProductTransmitJson,
  ProductTransmitModel,
  ProductTransmitCollectionJson
> {
  itemsFromJson(jsonItems: ProductTransmitJson[]): ProductTransmitModel[] {
    return jsonItems.map((item) => new ProductTransmitModel(item));
  }
}

export default ProductTransmitCollection;


