import {
  App,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Tooltip
} from "antd";
import OfficeModel from "common/models/OfficeModel";
import OfficeRepository from "common/repositories/OfficeRepository";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconInfoCircle } from "@tabler/icons-react";

import type { OfficeJsonAddEdit } from "common/types/Office";
const OfficeForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: OfficeModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: OfficeModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      display_order: model.display_order,
      address: model.address,
      date_review_checkin: model.date_review_checkin,
      allow_early_time: model.allow_early_time,
      allow_late_time: model.allow_late_time
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: OfficeJsonAddEdit = {
        id: model.id,
        code: model.code,
        name: formData.name,
        address: formData.address,
        display_order: formData.display_order,
        date_review_checkin: formData.date_review_checkin,
        allow_early_time: formData.allow_early_time,
        allow_late_time: formData.allow_late_time
      };

      return submitData;
    },
    [model.id, model.code]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: OfficeModel = await new OfficeRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="office:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={16} xs={24}>
          <Form.Item
            label={t("office:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("office:form.error.error_name_required")
              }
            ]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            label={t("common:display_order_long")}
            name="display_order">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("office:address")}
            name="address"
            className="mb-0">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      {model.id > 0 ? (
        <Divider orientation="left" className="pt-4">
          {t("office:form.checkin_setting_section")}
        </Divider>
      ) : null}

      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            label={t("office:date_review_checkin")}
            name="date_review_checkin"
            className="mb-0">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            label={
              <Tooltip
                placement="top"
                title={t("office:time_help")}
                mouseEnterDelay={0.4}>
                {t("office:allow_late_time")} <IconInfoCircle size={15} />
              </Tooltip>
            }
            name="allow_late_time"
            className="mb-0">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            label={
              <Tooltip
                title={t("office:time_help")}
                placement="top"
                mouseEnterDelay={0.4}>
                {t("office:allow_early_time")} <IconInfoCircle size={15} />
              </Tooltip>
            }
            name="allow_early_time"
            className="mb-0">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OfficeForm;
