import CustomerApi from "common/api/CustomerApi";
import CustomerCollection from "common/collections/CustomerCollection";
import CustomerModel from "common/models/CustomerModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
	CustomerJsonAddEdit,
	FilterCustomer,
} from "common/types/Customer";
class CustomerRepository extends BaseRepository<CustomerApi> {
	_api: CustomerApi | null;

	constructor() {
		super();
		this._api = new CustomerApi(true);
	}

	static getDefaultFilters(): FilterCustomer {
		return {
			page: 1,
			limit: 20,
			sortby: "id",
			sorttype: "DESC",
			status: -1,
			keyword: "",
			id: -1,
			gender: 0,
			full_name: "",
			code: "",
			phone: "",
			email: "",
			customer_type_id: -1,
			debt_from: 0,
			debt_to: 0,
			debt: -1,
		};
	}

	async getItems(props: GetListProps<FilterCustomer>) {
		return this._api
			? await this._api.getList(props)
			: new CustomerCollection();
	}

	async getItem(id: number): Promise<CustomerModel> {
		return this._api
			? await this._api.getDetail(id)
			: new CustomerModel(CustomerModel.getDefaultData());
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	async saveRemote(data: CustomerJsonAddEdit): Promise<CustomerModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new CustomerModel(CustomerModel.getDefaultData());
		}
	}

	
	async edit( id : number ,data: Partial<CustomerJsonAddEdit>): Promise<CustomerModel> {
		if (this._api) {
			
				return await this._api.edit({...data , id});
			
		} else {
			return new CustomerModel(CustomerModel.getDefaultData());
		}
	}
}

export default CustomerRepository;
