import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { LogAuditJson } from "common/types/LogAudit";
class LogAuditModel extends BaseModel implements BaseModelJson<LogAuditJson> {
  company_id: number;
  creator_id: number;
  id: number;
  display_name: string;
  method: number;
  severity: number;
  controller: string;
  action: string;
  object_id: number;
  tag: string;
  more_data: any;
  visibility: number;
  date_created: number;
  dynamic_data: any;

  constructor(json: LogAuditJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.display_name = json.display_name || "";
    this.method = json.method || 0;
    this.severity = json.severity || 0;
    this.controller = json.controller || "";
    this.action = json.action || "";
    this.object_id = json.object_id || 0;
    this.tag = json.tag || "";
    this.more_data = json.more_data || {};
    this.visibility = json.visibility || 0;
    this.date_created = json.date_created || 0;
    this.dynamic_data = json.dynamic_data || {};
  }

  static getDefaultData(): LogAuditJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      display_name: "",
      method: 0,
      severity: 0,
      controller: "",
      action: "",
      object_id: 0,
      tag: "",
      more_data: {},
      visibility: 0,
      date_created: 0,
      dynamic_data: {},
    };
  }

  toJson(): LogAuditJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      display_name: this.display_name,
      method: this.method,
      severity: this.severity,
      controller: this.controller,
      action: this.action,
      object_id: this.object_id,
      tag: this.tag,
      more_data: this.more_data,
      visibility: this.visibility,
      date_created: this.date_created,
      dynamic_data: this.dynamic_data,
    };
  }

  getObjectTextAndLink(): { text: string; link: string } {
    let text = "";
    let link = "";

    switch (this.controller) {
      case "order":
        text = "#" + this.object_id;
        link = "/order/edit/id/" + this.object_id;
        break;
      case "customer":
        text = "#" + this.object_id;
        link = "/customer/edit/id/" + this.object_id;
        break;
      case "cashflowreceipt":
        text = "#" + this.object_id;
        link = "/cashflowreceipt/edit/id/" + this.object_id;
        break;
      case "promotion":
        text = "#" + this.object_id;
        link = "/promotion?id=" + this.object_id;
        break;
      case "productreceipt":
        text = "#" + this.object_id;
        link = "/productreceipt/edit/id/" + this.object_id;
        break;
      case "user":
        text = "";
        break;
      default:
        text = " có ID #" + this.object_id;
    }

    return { text, link };
  }
}

export default LogAuditModel;
