import CashflowReceipt from "common/constants/CashflowReceipt";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { CashflowReceiptJson } from "common/types/CashflowReceipt";

class CashflowReceiptModel
	extends BaseModel
	implements BaseModelJson<CashflowReceiptJson>
{
	company_id: number;
	creator_id: number;
	cashflow_group: number;
	id: number;
	direction: number;
	store_id: number;
	source: number;
	source_id: number;
	target: number;
	target_id: number;
	identifier: string;
	name: string;
	note: string;
	method: number;
	value: number;
	status: number;
	date_created: number;
	date_modified: number;
	// date_affected: number;
	target_label: string;

	constructor(json: CashflowReceiptJson) {
		super();

		this.creator_id = json.creator_id || 0;
		this.company_id = json.company_id || 0;
		this.cashflow_group = json.cashflow_group || 0;
		this.id = json.id || 0;
		this.direction = json.direction || 0;
		this.store_id = json.store_id || 0;
		this.source = json.source || 0;
		this.source_id = json.source_id || 0;
		this.target = json.target || 0;
		this.target_id = json.target_id || 0;
		this.identifier = json.identifier || "";
		this.name = json.name || "";
		this.note = json.note || "";
		this.method = json.method || 0;
		this.value = json.value || 0;
		this.status = json.status || 0;
		this.date_created = json.date_created || 0;
		this.date_modified = json.date_modified || 0;
		// this.date_affected = json.date_affected || 0;
		this.target_label = json.target_label || "";
	}

	static getDefaultData(): CashflowReceiptJson {
		return {
			creator_id: 0,
			company_id: 0,
			cashflow_group: CashflowReceipt.GROUP_IN_SALE,
			id: 0,
			direction: CashflowReceipt.DIRECTION_INCOME,
			store_id: 0,
			source: CashflowReceipt.SOURCE_SALE_ORDER,
			source_id: 0,
			target: CashflowReceipt.TARGET_CUSTOMER,
			target_id: 0,
			identifier: "",
			name: "",
			note: "",
			method: 0,
			value: 0,
			status: 1,
			date_created: 0,
			date_modified: 0,
			// date_affected: 0,
			target_label: "",
		};
	}

	toJson(): CashflowReceiptJson {
		return {
			creator_id: this.creator_id,
			company_id: this.company_id,
			cashflow_group: this.cashflow_group,
			id: this.id,
			direction: this.direction,
			store_id: this.store_id,
			source: this.source,
			source_id: this.source_id,
			target: this.target,
			target_id: this.target_id,
			identifier: this.identifier,
			name: this.name,
			note: this.note,
			method: this.method,
			value: this.value,
			status: this.status,
			date_created: this.date_created,
			date_modified: this.date_modified,
			// date_affected: this.date_affected,
			target_label: this.target_label,
		};
	}

	static getStatusList(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.STATUS_DRAFT,
				label: i18n.t("cashflowreceipt:STATUS_DRAFT"),
				color: "gray",
			},
			{
				value: CashflowReceipt.STATUS_WAIT_FOR_APPROVAL,
				label: i18n.t("cashflowreceipt:STATUS_WAIT_FOR_APPROVAL"),
				color: "orange",
			},
			{
				value: CashflowReceipt.STATUS_APPROVED,
				label: i18n.t("cashflowreceipt:STATUS_APPROVED"),
				color: "blue",
			},
			{
				value: CashflowReceipt.STATUS_COMPLETED,
				label: i18n.t("cashflowreceipt:STATUS_COMPLETED"),
				color: "green",
			},
			{
				value: CashflowReceipt.STATUS_CANCELLED,
				label: i18n.t("cashflowreceipt:STATUS_CANCELLED"),
				color: "red",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}

	static getSourceList(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.SOURCE_SALE_ORDER,
				label: i18n.t("cashflowreceipt:SOURCE_SALE_ORDER"),
			},
			{
				value: CashflowReceipt.SOURCE_PURCHASE_ORDER,
				label: i18n.t("cashflowreceipt:SOURCE_PURCHASE_ORDER"),
			},
			{
				value: CashflowReceipt.SOURCE_MANUAL,
				label: i18n.t("cashflowreceipt:SOURCE_MANUAL"),
			},
		];
	}

	static getSource(value: number): SelectOption | undefined {
		return this.getSourceList().find((item) => item.value === value);
	}

	// CASH = 1,
	// TRASNFER = 3,
	// COD = 11,
	// VNPAY = 15,
	// CARD = 19,
	// VOUCHER = 9999,

	static getMethodList(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.METHOD_CASH,
				label: i18n.t("cashflowreceipt:METHOD_CASH"),
			},
			{
				value: CashflowReceipt.METHOD_BANK,
				label: i18n.t("cashflowreceipt:METHOD_BANK"),
			},
			// {
			// 	value: CashflowReceipt.METHOD_GIFTCARD,
			// 	label: i18n.t("cashflowreceipt:METHOD_GIFTCARD"),
			// },
			// {
			// 	value: CashflowReceipt.METHOD_CUSTOMER_POINT,
			// 	label: i18n.t("cashflowreceipt:METHOD_CUSTOMER_POINT"),
			// },
			{
				value: CashflowReceipt.METHOD_COD,
				label: i18n.t("cashflowreceipt:METHOD_COD"),
			},
			// {
			// 	value: CashflowReceipt.METHOD_MOMO,
			// 	label: i18n.t("cashflowreceipt:METHOD_MOMO"),
			// },
			{
				value: CashflowReceipt.METHOD_VNPAY,
				label: i18n.t("cashflowreceipt:METHOD_VNPAY"),
			},
			{
				value: CashflowReceipt.METHOD_VOUCHER,
				label: i18n.t("Voucher"),
			},
			// {
			// 	value: CashflowReceipt.METHOD_MEMBERCARD,
			// 	label: i18n.t("cashflowreceipt:METHOD_MEMBERCARD"),
			// },
		];
	}

	static getMethod(value: number): SelectOption | undefined {
		return this.getMethodList().find((item) => item.value === value);
	}

	static getMethodListForPos(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.METHOD_CASH,
				label: i18n.t("cashflowreceipt:METHOD_CASH"),
			},
			{
				value: CashflowReceipt.METHOD_BANK,
				label: i18n.t("cashflowreceipt:METHOD_BANK"),
			},
			{
				value: CashflowReceipt.METHOD_GIFTCARD,
				label: i18n.t("cashflowreceipt:METHOD_GIFTCARD"),
			},
			{
				value: CashflowReceipt.METHOD_CUSTOMER_POINT,
				label: i18n.t("cashflowreceipt:METHOD_CUSTOMER_POINT"),
			},
		];
	}

	static getMethodForPos(value: number): SelectOption | undefined {
		return this.getMethodListForPos().find((item) => item.value === value);
	}

	static getDirectionList(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.DIRECTION_INCOME,
				label: i18n.t("cashflowreceipt:DIRECTION_INCOME"),
				color: "green",
			},
			{
				value: CashflowReceipt.DIRECTION_EXPENSE,
				label: i18n.t("cashflowreceipt:DIRECTION_EXPENSE"),
				color: "red",
			},
		];
	}

	static getDirection(value: number): SelectOption | undefined {
		return this.getDirectionList().find((item) => item.value === value);
	}

	static getTargetList(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.TARGET_EMPLOYEE,
				label: i18n.t("cashflowreceipt:TARGET_EMPLOYEE"),
			},
			{
				value: CashflowReceipt.TARGET_CUSTOMER,
				label: i18n.t("cashflowreceipt:TARGET_CUSTOMER"),
			},
			{
				value: CashflowReceipt.TARGET_SUPPLIER,
				label: i18n.t("cashflowreceipt:TARGET_SUPPLIER"),
			},
			{
				value: CashflowReceipt.TARGET_OTHER,
				label: i18n.t("cashflowreceipt:TARGET_OTHER"),
			},
		];
	}

	static getTarget(value: number): SelectOption | undefined {
		return this.getTargetList().find((item) => item.value === value);
	}

	// static getOrderPaymentMethodMappingList(): SelectOption[] {
	//   return [
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_CASH,
	//       label: i18n.t("cashflowreceipt:BIT_PAYMENT_METHOD_CASH"),
	//     },
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_BANK,
	//       label: i18n.t("cashflowreceipt:BIT_PAYMENT_METHOD_BANK"),
	//     },
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_GIFTCARD,
	//       label: i18n.t("cashflowreceipt:BIT_PAYMENT_METHOD_GIFTCARD"),
	//     },
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_CUSTOMER_POINT,
	//       label: i18n.t("cashflowreceipt:BIT_PAYMENT_METHOD_CUSTOMER_POINT"),
	//     },
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_COD,
	//       label: i18n.t("cashflowreceipt:BIT_PAYMENT_METHOD_COD"),
	//     },
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_MOMO,
	//       label: i18n.t("cashflowreceipt:BIT_PAYMENT_METHOD_MOMO"),
	//     },
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_VNPAY,
	//       label: i18n.t("cashflowreceipt:BIT_PAYMENT_METHOD_VNPAY"),
	//     },
	//     {
	//       value: Order.BIT_PAYMENT_METHOD_MEMBERCARD,
	//       label: i18n.t("cashflowreceipt:GIFTCARD_TYPE_VOUCHER"),
	//     },
	//   ];
	// }

	// static getOrderPaymentMethodMapping(value: number): SelectOption | undefined {
	//   return this.getOrderPaymentMethodMappingList().find(
	//     (item) => item.value === value
	//   );
	// }

	static getGroupList(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.GROUP_IN_SALE,
				label: i18n.t("cashflowreceipt:GROUP_IN_SALE"),
			},
			{
				value: CashflowReceipt.GROUP_IN_OTHER,
				label: i18n.t("cashflowreceipt:GROUP_IN_OTHER"),
			},
			{
				value: CashflowReceipt.GROUP_OUT_PURCHASE,
				label: i18n.t("cashflowreceipt:GROUP_OUT_PURCHASE"),
			},
			{
				value: CashflowReceipt.GROUP_OUT_RETURN,
				label: i18n.t("cashflowreceipt:GROUP_OUT_RETURN"),
			},
			{
				value: CashflowReceipt.GROUP_OUT_OTHER,
				label: i18n.t("cashflowreceipt:GROUP_OUT_OTHER"),
			},
		];
	}

	static getGroup(value: number): SelectOption | undefined {
		return this.getGroupList().find((item) => item.value === value);
	}

	static getBankList(): SelectOption[] {
		return [
			{
				value: 1001,
				label: "Mastercard",
			},
			{
				value: 1002,
				label: "Visa",
			},
			{
				value: 1,
				label: "Ngân hàng Á Châu (Asia Commercial Bank, ACB)",
			},
			{
				value: 2,
				label: "Ngân hàng An Bình (ABBank)",
			},
			{
				value: 3,
				label: "Ngân hàng ANZ Việt Nam (Australia And Newzealand Bank)",
			},
			{
				value: 4,
				label: "Ngân hàng Bắc Á (NASBank, NASB)",
			},
			{
				value: 5,
				label: "Ngân hàng Bản Việt (VIET CAPITAL BANK, VCCB)",
			},
			{
				value: 6,
				label: "Ngân hàng Bảo Việt (BaoVietBank, BVB)",
			},
			{
				value: 7,
				label: "Ngân hàng BIDC (BIDC)",
			},
			{
				value: 8,
				label: "Ngân hàng Bưu Điện Liên Việt (LienVietPostBank)",
			},
			{
				value: 9,
				label: "Ngân hàng Công thương (Vietinbank)",
			},
			{
				value: 10,
				label: "Ngân hàng Crédit Agricole (Ca-CIB)",
			},
			{
				value: 11,
				label: "Ngân hàng Đại chúng (PVcombank)",
			},
			{
				value: 12,
				label: "Ngân hàng Đại Dương (Oceanbank)",
			},
			{
				value: 13,
				label: "Ngân hàng Dầu khí Toàn Cầu (GP.Bank)",
			},
			{
				value: 14,
				label: "Ngân hàng Đầu tư (BIDV)",
			},
			{
				value: 15,
				label: "Ngân hàng Deutsche Bank Việt Nam (Deutsche Bank AG, Vietnam)",
			},
			{
				value: 16,
				label: "Ngân hàng Đông Á (DongA Bank, DAB)",
			},
			{
				value: 17,
				label: "Ngân hàng Đông Nam Á (SeABank)",
			},
			{
				value: 18,
				label: "Ngân hàng Hàng Hải Việt Nam (Maritime Bank, MSB)",
			},
			{
				value: 19,
				label: "Ngân hàng Kiên Long (KienLongBank)",
			},
			{
				value: 20,
				label: "Ngân hàng Kỹ Thương Việt Nam (Techcombank)",
			},
			{
				value: 21,
				label: "Ngân hàng Mizuho",
			},
			{
				value: 22,
				label: "Ngân hàng Nam Á (Nam A Bank)",
			},
			{
				value: 23,
				label: "Ngân hàng Citibank Việt Nam ( Citibank)",
			},
			{
				value: 24,
				label: "Ngân hàng Commonwealth Bank tại Việt Nam",
			},
			{
				value: 25,
				label: "Ngân hàng Đầu tư và Phát triển Campuchia (BIDC)",
			},
			{
				value: 26,
				label:
					"Ngân hàng Hong Leong Việt Nam (Hong Leong Bank Vietnam Limited - HLBVN)",
			},
			{
				value: 27,
				label: "Ngân hàng Indovina (IVB)",
			},
			{
				value: 28,
				label: "Ngân hàng ShinhanVina (SVB)",
			},
			{
				value: 29,
				label: "Ngân hàng TMCP Sài Gòn Thương Tín (Sacombank)",
			},
			{
				value: 30,
				label: "Ngân hàng TNHH một thành viên HSBC (Việt Nam) ( HSBC)",
			},
			{
				value: 31,
				label:
					"Ngân hàng TNHH MTV Shinhan Việt Nam (Shinhan Vietnam Bank Limited - SHBVN)",
			},
			{
				value: 32,
				label: "Ngân hàng Việt - Lào (LVB)",
			},
			{
				value: 33,
				label: "Ngân hàng Việt - Thái (VSB)",
			},
			{
				value: 34,
				label: "Ngân hàng Việt-Nga (VRB)",
			},
			{
				value: 35,
				label: "Ngân hàng Ngoại thương (Vietcombank)",
			},
			{
				value: 36,
				label: "Ngân hàng Nông nghiệp (Agribank)",
			},
			{
				value: 37,
				label: "Ngân hàng Phát Triển Mê Kông (MDB)",
			},
			{
				value: 38,
				label: "Ngân hàng Phát triển Nhà ĐBSCL (MHB)",
			},
			{
				value: 39,
				label: "Ngân hàng Phát Triển Nhà TPHCM (HDBank)",
			},
			{
				value: 40,
				label: "Ngân hàng Phương Đông (Orient Commercial Bank, OCB)",
			},
			{
				value: 41,
				label: "Ngân hàng Phương Nam (Southern Bank, PNB)",
			},
			{
				value: 42,
				label: "Ngân hàng Quân Đội (Military Bank, MB)",
			},
			{
				value: 43,
				label: "Ngân hàng Quốc Dân (National Citizen Bank)",
			},
			{
				value: 44,
				label: "Ngân hàng Quốc tế (VIBBank, VIB)",
			},
			{
				value: 45,
				label: "Ngân hàng Sài Gòn – Hà Nội (Sài Gòn Hà Nội Bank, SHB)",
			},
			{
				value: 46,
				label: "Ngân hàng Sài Gòn (Sài Gòn, SCB)",
			},
			{
				value: 47,
				label: "Ngân hàng Sài Gòn Công Thương (Saigonbank)",
			},
			{
				value: 48,
				label:
					"Ngân hàng Standard Chartered (Standard Chartered Bank (Vietnam) Limited, Standard Chartered)",
			},
			{
				value: 49,
				label: "Ngân hàng Sumitomo Mitsui Bank",
			},
			{
				value: 50,
				label: "Ngân hàng Tiên Phong (TienPhongBank)",
			},
			{
				value: 51,
				label: "Ngân hàng Tokyo-Mitsubishi UFJ",
			},
			{
				value: 52,
				label: "Ngân hàng VID Public Bank (VID PB)",
			},
			{
				value: 53,
				label: "Ngân hàng Việt Á (VietABank, VAB)",
			},
			{
				value: 54,
				label: "Ngân hàng Việt Nam Thịnh Vượng (VPBank)",
			},
			{
				value: 55,
				label: "Ngân hàng Việt Nam Thương Tín (VietBank)",
			},
			{
				value: 56,
				label: "Ngân hàng Xăng dầu Petrolimex (Petrolimex Group Bank, PG Bank)",
			},
			{
				value: 57,
				label: "Ngân hàng Xây dựng (Vietnam construction bank)",
			},
			{
				value: 58,
				label: "Ngân hàng Xuất nhập khẩu (Eximbank, EIB)",
			},
		];
	}

	static getGiftcardList(): SelectOption[] {
		return [
			{
				value: 1,
				label: "Giftcard",
			},
			{
				value: 2,
				label: "Voucher",
			},
		];
	}
}

export default CashflowReceiptModel;
