import React from "react";

type Props = {
	children: React.ReactNode;
};
const FilterBody = ({ children }: Props) => {
	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-y-2 gap-2">
			{children}
		</div>
	);
};

export default FilterBody;
