import ProductCategoryRepository from "common/repositories/ProductCategoryRepository";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductCategoryFormSelect = ({
  label,
  name,
  localOnly,
  className,
  parentId,
  ignoreValues,
  placeholder,
  popupMatchSelectWidth,
}: {
  label?: string;
  name: string;
  localOnly?: boolean;
  className?: string;
  parentId?: number;
  ignoreValues?: number[];
  placeholder?: string;
  popupMatchSelectWidth?: boolean;
}) => {
  const { t } = useTranslation();
  const [localItems] = useDatabaseTable<ProductCategoryJson>("productcategory");

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(
    typeof localOnly === "undefined" || !localOnly,
  );
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new ProductCategoryRepository().getItems({
      filters: ProductCategoryRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
          parent_id: item.parent_id,
        })),
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  const displayOptions: SelectOption[] =
    typeof localOnly !== "undefined" && localOnly
      ? _.orderBy(localItems, ["display_order"], ["asc"]).map((item) => ({
          value: item.id,
          label: item.name,
          parent_id: item.parent_id,
        }))
      : options;

  return (
    <FormSelect
      isTree={typeof parentId === "undefined"}
      allowClear
      loading={loading}
      showSearch
      label={label}
      className={className}
      placeholder={placeholder ?? t("common:form.select_category")}
      name={name}
      options={displayOptions
        .filter((item) =>
          typeof parentId === "undefined" ? true : parentId === item.parent_id,
        )
        .filter(
          (item) =>
            typeof ignoreValues === "undefined" ||
            !ignoreValues.includes(item.value),
        )}
      errors={errors}
      errorRetry={fetchData}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
};

export default ProductCategoryFormSelect;
