import { PromotionGiftCheckResultJson } from 'common/types/PromotionCheck';

import BaseModel from './BaseModel';

class PromotionGiftCheckResultModel extends BaseModel {
  sku: string;

  constructor(json: PromotionGiftCheckResultJson) {
    super();

    this.sku = json.sku || "";
  }

  static getDefaultData(): PromotionGiftCheckResultJson {
    return {
      sku: "",
    };
  }

  toJson(): PromotionGiftCheckResultJson {
    return {
      sku: this.sku,
    };
  }
}

export default PromotionGiftCheckResultModel;
