import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import React from "react";

import { IconReportMoney, IconShoppingCart } from "@tabler/icons-react";

import ReportChartNumber from "../chart/ReportChartNumber";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportCustomerPoint = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số giao dịch"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="count_id"
              dataTable="customerpoint"
              dataService="customerpoint"
              dataSelect="count(id)"
              dataFilter=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số điểm cộng"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="sum_value"
              dataTable="customerpoint"
              dataService="customerpoint"
              dataSelect="sum(value)"
              dataFilter="value > 0"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số điểm trừ"}
              icon={<IconReportMoney size="18" />}
              keyMapping="sum_value"
              dataTable="customerpoint"
              dataService="customerpoint"
              dataSelect="sum(value)"
              dataFilter="value < 0"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Số khách có giao dịch"}
              icon={<IconReportMoney size="18" />}
              keyMapping="countdistinct_customer"
              dataTable="customerpoint"
              dataService="customerpoint"
              dataSelect="countdistinct(customer)"
              dataFilter=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng giá trị điểm tích lũy dương"}
              dataTable="customerpoint"
              dataSelect="sum(value)"
              dataGroupBy=""
              dataOrderBy=""
              dataFilter="value > 0"
              keyMapping={{ name: "comparetext", value: "sum_value" }}
              height={250}
              dataService="customerpoint"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>

        {/* <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Giá trị điểm cộng theo cửa hàng"}
              dataTable="customerpoint"
              dataService="customerpoint"
              dataSelect="sum(value), store"
              dataFilter="value > 0"
              dataGroupBy="store"
              dataOrderBy="sum(value) desc"
              keyMapping={{
                name: "store",
                value: "sum_value",
                formatter: (value: number | string) => {
                  return value.toString();
                }
              }}
              height={400}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Điểm tích lũy cộng theo cửa hàng"}
              dataTable="customerpoint"
              dataService="customerpoint"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {storeItemsWithLimit.find((i) => i.id === value)?.name}
                      </>
                    );
                  }
                },
                {
                  title: "Tổng điểm",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số giao dịch",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(value)"
              dataFilter="value > 0"
              dataGroupBy="store"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row> */}

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng giá trị điểm âm"}
              dataTable="customerpoint"
              dataSelect="sum(value)"
              dataGroupBy=""
              dataOrderBy=""
              dataFilter="value < 0"
              keyMapping={{ name: "comparetext", value: "sum_value" }}
              height={250}
              dataService="customerpoint"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>

        {/* <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartTimeSeries
              className="p-4"
              title={"Giá trị điểm trừ theo cửa hàng"}
              dataTable="customerpoint"
              dataService="customerpoint"
              dataSelect="sum(value), store"
              dataFilter="value < 0"
              dataGroupBy="store"
              dataOrderBy="sum(value) ASC"
              keyMapping={{ name: "store", value: "sum_value" }}
              height={300}
              dataInterval=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Điểm trừ theo cửa hàng"}
              dataTable="customerpoint"
              dataService="customerpoint"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {storeItemsWithLimit.find((i) => i.id === value)?.name}
                      </>
                    );
                  }
                },
                {
                  title: "Tổng điểm",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số giao dịch",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(value)"
              dataFilter="value < 0"
              dataGroupBy="store"
              dataOrderBy="sum(value) ASC"
              dateRange={getRange()}
            />
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default ReportCustomerPoint;
