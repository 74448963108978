import { Button, Col, ColorPicker, Divider, Form, Input, Row } from "antd";
import { DealthomConfig, TypeTagConfig } from "common/types/Promotion";
import { TagPromotionInfo } from "common/types/Promotion";
import React, { useMemo, useState } from "react";
import FormSelect from "components/form/FormSelect";
import { SelectOption } from "common/types/SelectOption";

type Props = {
	tag_infor: TagPromotionInfo[];
	onUpdate?: (data: Partial<DealthomConfig>) => Promise<unknown>;
};
export default function DealthomStyleForm({ tag_infor, onUpdate }: Props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const initialValues = useMemo(() => {
		return { tag_infor: tag_infor };
	}, [tag_infor]);

	const options: SelectOption[] = [
		{
			label: "Card sản phẩm",
			value: TypeTagConfig.CARD,
		},
		{
			label: "Các sku",
			value: TypeTagConfig.VARIANT,
		},
	];

	const onSubmit = (formData: Pick<Props, "tag_infor">) => {
		setLoading(false);

		onUpdate &&
			onUpdate({ tag_infor: formData.tag_infor }).finally(() => {
				setLoading(false);
			});
	};
	return (
		<div>
			<Form form={form} onFinish={onSubmit} initialValues={initialValues}>
				<Form.List name={"tag_infor"}>
					{(fields, { add, remove }) => {
						return (
							<Row gutter={8}>
								{fields.map((field) => (
									<>
										<Col span={24}>
											<Form.Item
												className="text-left"
												labelCol={{ span: 5, style: { textAlign: "left" } }}
												label="Tên tag"
												name={[field.name, "name"]}>
												<Input></Input>
											</Form.Item>
											{/* <Form.Item
												className="text-left"
												labelCol={{ span: 5, style: { textAlign: "left" } }}
												hidden
												name={[field.name, "bg_color"]}></Form.Item> */}
											<Form.Item
												// name={[field.name, "bg_color"]}
												initialValue={
													initialValues.tag_infor[field.key]?.["bg_color"] ||
													"#d72229"
												}
												label="Màu nền"
												labelCol={{ span: 5, style: { textAlign: "left" } }}>
												<ColorPicker
													defaultValue={
														initialValues.tag_infor[field.key]?.["bg_color"] ||
														"#d72229"
													}
													onChange={(value, hex: string) => {
														const defaultData = form.getFieldValue(
															"tag_infor"
														) as TagPromotionInfo[];
														const key = field.key;
														if (defaultData?.[key]) {
															defaultData[key].bg_color = hex;
															form.setFieldValue("tag_infor", defaultData);
														}
													}}></ColorPicker>
											</Form.Item>
											<Form.Item
												className="text-left"
												labelCol={{ span: 5, style: { textAlign: "left" } }}
												label="Màu chữ"
												name={[field.name, "text_color"]}>
												<ColorPicker
													defaultValue={
														initialValues.tag_infor[field.key]?.[
															"text_color"
														] || "#fff"
													}
													onChange={(value, hex: string) => {
														const defaultData = form.getFieldValue(
															"tag_infor"
														) as TagPromotionInfo[];
														const key = field.key;
														if (defaultData?.[key]) {
															defaultData[key].text_color = hex;
															form.setFieldValue("tag_infor", defaultData);
														}
													}}></ColorPicker>
											</Form.Item>
											<Form.Item
												className="text-left"
												labelCol={{ span: 5, style: { textAlign: "left" } }}
												label="Nơi hiển thị"
												name={[field.name, "type"]}>
												<FormSelect options={options}></FormSelect>
											</Form.Item>
										</Col>
									</>
								))}
							</Row>
						);
					}}
				</Form.List>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Xác nhận
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
