import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { DeliverySumbyEmployeeJson } from "common/types/DeliverySumbyEmployee";
import BaseModel from "./BaseModel";

class DeliverySumbyEmployeeModel
  extends BaseModel
  implements BaseModelJson<DeliverySumbyEmployeeJson>
{
  totalcod_count: number;
  totalcod_sum: number;
  transfer_count: number;
  transfer_sum: number;
  current_count: number;
  current_sum: number;
  returned_count: number;
  returned_sum: number;
  receiving_count: number;
  receiving_sum: number;
  tips_count: number;
  tips_sum: number;

  constructor(json: DeliverySumbyEmployeeJson) {
    super();
    this.tips_count = json.tips_count;
    this.tips_sum = json.tips_sum;
    this.totalcod_count = json.totalcod_count || 0;
    this.totalcod_sum = json.totalcod_sum || 0;
    this.transfer_count = json.transfer_count || 0;
    this.transfer_sum = json.transfer_sum || 0;
    this.current_count = json.current_count || 0;
    this.current_sum = json.current_sum || 0;
    this.returned_count = json.receiving_count || 0;
    this.returned_sum = json.receiving_sum || 0;
    this.receiving_count = json.receiving_count || 0;
    this.receiving_sum = json.receiving_sum || 0;
  }

  static getDefaultData(): DeliverySumbyEmployeeJson {
    return {
      tips_count: 0,
      tips_sum: 0,
      totalcod_count: 0,
      totalcod_sum: 0,
      transfer_count: 0,
      transfer_sum: 0,
      current_count: 0,
      current_sum: 0,
      returned_count: 0,
      returned_sum: 0,
      receiving_count: 0,
      receiving_sum: 0,
    };
  }

  toJson(): DeliverySumbyEmployeeJson {
    return {
      tips_count: this.tips_count,
      tips_sum: this.tips_sum,
      totalcod_count: this.totalcod_count,
      totalcod_sum: this.totalcod_sum,
      transfer_count: this.transfer_count,
      transfer_sum: this.transfer_sum,
      current_count: this.current_count,
      current_sum: this.current_sum,
      returned_count: this.returned_count,
      returned_sum: this.returned_sum,
      receiving_count: this.receiving_count,
      receiving_sum: this.receiving_sum,
    };
  }
}

export default DeliverySumbyEmployeeModel;
