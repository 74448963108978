import dayjs from "dayjs";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd";

import Helper from "common/utils/helper";
import TaskModel from "common/models/TaskModel";
import { TaskJsonAddEdit } from "common/types/Task";
import TaskRepository from "common/repositories/TaskRepository";

import TaskFormHeader from "./TaskFormHeader";
import ErrorAlert from "components/ErrorAlert";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";
import FormSelectCustom from "../components/FormSelectCustom";

import "react-quill/dist/quill.snow.css";

const TaskEditForm = ({
  model,
  type,
}: {
  model: TaskModel;
  type?: "edit_request";
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  //state
  const [parentModel, setParentModel] = useState<TaskModel>(
    new TaskModel(TaskModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  //functions
  const fetchParentDetail = async (id: number) => {
    setParentModel(await new TaskRepository().getItem(id));
  };

  function validateManyId(input: string) {
    // Sử dụng biểu thức chính quy để kiểm tra định dạng
    var regex = /^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$/;
    return regex.test(input);
  }

  const createScopeIdOption = (scope_ids: number[]) => {
    if (model.org_id > 0 && !scope_ids.includes(model.org_id)) {
      return [...scope_ids, model.org_id];
    } else {
      return scope_ids;
    }
  };

  //initial values
  const initialValues = useMemo(() => {
    return {
      id: model.id,
      name: model.name,
      org_cost: model.org_cost,
      type: model.type > 0 ? model.type : 1,
      priority: model.priority > 0 ? model.priority : 1,
      ability: model.ability,
      flag: model.flag > 0 ? model.flag : 0,
      note: model.note,
      org_complete: model.org_complete,
      org_id: model.org_id > 0 ? model.org_id : 0,
      parent_id: model.parent_id > 0 ? model.parent_id : 0,
      public: model.public,
      scope_ids: model.scope_ids.length > 0 ? model.scope_ids : "",
      org_fromtime:
        model.org_fromtime > 0 ? dayjs(new Date(model.org_fromtime * 1000)) : 0,
      org_totime:
        model.org_totime > 0 ? dayjs(new Date(model.org_totime * 1000)) : 0,
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: TaskJsonAddEdit) => {
      const submitData: TaskJsonAddEdit = {
        id: model.id,
        name: formData.name,
        org_cost: formData.org_cost,
        type: formData.type,
        note: formData.note,
        priority: formData.priority,
        scope_ids:
          formData.scope_ids.length > 0
            ? (formData.scope_ids.join(",") as any)
            : "",
        org_fromtime:
          Helper.convertedDateToTimeStamp(formData.org_fromtime as any) > 0
            ? Helper.convertedDateToTimeStamp(formData.org_fromtime as any)
            : 0,
        org_totime:
          Helper.convertedDateToTimeStamp(formData.org_totime as any) > 0
            ? Helper.convertedDateToTimeStamp(formData.org_totime as any)
            : 0,
        org_id:
          Number(formData.org_id) >= 0 ? Number(formData.org_id) : model.org_id,
        parent_id:
          Number(formData.parent_id) >= 0
            ? Number(formData.parent_id)
            : model.parent_id,
        // ability: Number(formData.ability) > 0 ? Number(formData.ability) : 0,
        // flag: Number(formData.flag),
        // public: formData.public,
        // percent_complete: Number(formData.percent_complete),
      };
      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    doPrepareData(formData);
    let myObj: TaskModel = await new TaskRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
      setErrors([]);
    }
  };

  //
  useEffect(() => {
    if (model.parent_id > 0) {
      fetchParentDetail(model.parent_id);
    }
  }, []);

  //for edit, show status
  const sidebarItems = (
    <>
      {/* {errors.length > 0 && (
        <ErrorAlert
          items={errors}
          translate_prefix={`task:form.error.${errors[0]}`}
          className="mb-4"
        />
      )} */}
    </>
  );

  const toolbarOptions = [
    [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction"],
    ["link", "image", "video", "formula"],
    ["clean"],
  ];

  return (
    <>
      <TaskFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess=""
        error_translate_prefix="common:form.error.heading"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("task:form.section_task")}
          subtitle={
            model.parent_id > 0
              ? `Task cha: #${parentModel.id} - ${parentModel.name}`
              : t("task:form.section_task_subtitle")
          }
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("task:name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("task:form.error.error_name_require"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_cost")}
                name="org_cost"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: t("task:form.error.error_org_cost_require"),
                  },
                  {
                    message: t("task:form.error.error_org_cost"),
                    validator: (_: any, value: number) => {
                      if (value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <InputNumber className="w-full" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_fromtime")}
                name="org_fromtime"
                style={{ width: "100%" }}
              >
                <DatePicker
                  showTime
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_totime")}
                name="org_totime"
                style={{ width: "100%" }}
              >
                <DatePicker
                  showTime
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                label={t("task:type")}
                name="type"
                placeholder="Chọn loại task"
                options={TaskModel.getTypeOption()}
              />
            </Col>
            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                label={t("task:priority")}
                name="priority"
                placeholder="Độ ưu tiên"
                options={TaskModel.getPriorityOption()}
              />
            </Col>
            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                label={t("task:ability")}
                name="ability"
                placeholder="Khả thi"
                options={TaskModel.getAbilityOption()}
              />
            </Col>
            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                label={t("task:public")}
                name="public"
                placeholder=""
                options={TaskModel.getAbilityOption()}
              />
            </Col>
            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                label={t("task:flag")}
                name="flag"
                options={[
                  { label: "Có", value: 1 },
                  { label: "Không", value: 0 },
                ]}
              />
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:percent_complete")}
                name="org_complete"
                style={{ width: "100%" }}
                rules={[
                  {
                    message: t("task:form.error.error_org_complete_value"),
                    validator: (_: any, value: number) => {
                      if (value >= 0 && value <= 100) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <InputNumber addonAfter="%" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("ID các task liên quan")}
                name="scope_ids"
                rules={[
                  {
                    message: t(
                      "Các ID viết liền và chỉ cách nhau bởi dấu phẩy"
                    ),
                    validator: (_: any, value: string) => {
                      console.log(value);
                      if (
                        validateManyId(value) ||
                        value.length === 0 ||
                        value?.[0].length === 0
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <FormSelectCustom
                label={t("ID task gốc")}
                name="org_id"
                options={createScopeIdOption(parentModel.scope_ids)}
              />
            </Col>
            <Col md={8} xs={24}>
              <Form.Item label={t("ID task cha")} name="parent_id">
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item name="note" label={t("task:note")}>
                <ReactQuill
                  theme="snow"
                  style={{
                    height: "500px",
                    width: "100%",
                    marginBottom: "70px",
                  }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default TaskEditForm;
