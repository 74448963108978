import EcomLogCollection from "common/collections/EcomLogCollection";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type { FilterEcomLog } from "common/types/EcomLog";

const SERVICE_URL = "/ecomplatformlogs";

class EcomLogApi extends BaseApi {
  async getList(
    props: GetListProps<FilterEcomLog>,
  ): Promise<EcomLogCollection> {
    let collection = new EcomLogCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          type: filters.type >= 0 ? filters.type : null,
          topic: filters.topic.length >= 0 ? filters.topic : null,
          status: +filters.status >= 0 ? filters.status : null,
          ecom_platform_id: filters.ecom_platform_id,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default EcomLogApi;
