import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter, FilterValue } from "./Filter";


export enum StoreShowweb {
  NONE,
  SHOW
}
type StoreJson = {
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  code: string;
  phone: string;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
  address: string;
  lat: number;
  long: number;
  warehouse_ids?: { id: number, name: string }[];
  tax_seller_name: string;
  tax_seller_address: string;
  tax_seller_phone: string;
  tax_seller_account_number: string;
  tax_seller_tax_number: string;
  status: number;
  date_created: number;
  date_modified: number;
  country: number,
  province: number,
  district: number,
  ward: number,
  road: string
  link_map: string
  show_web: StoreShowweb
};

type FilterStore = Filter & {
  region_id: FilterValue;
};

type StoreCollectionJson = BaseCollectionJson<StoreJson>;

export type { StoreJson, StoreCollectionJson, FilterStore };
