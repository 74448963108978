import ReportDeliveryApi from "common/api/ReportDeliveryApi";
import { FilterReportDelivery } from "common/types/ReportDelivery";
import { GetListProps } from "common/types/Filter";
import ReportDeliveryCollection from "common/collections/ReportDeliveryCollection";

import BaseRepository from "./BaseRepository";

class ReportDeliveryRepository extends BaseRepository<ReportDeliveryApi> {
  _api: ReportDeliveryApi | null;

  constructor() {
    super();
    this._api = new ReportDeliveryApi(true);
  }

  static getDefaultFilters(): FilterReportDelivery {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      date_created: -1,
      date_completed: -1,
      actor_id: -1,
      action_code: "",
      internal_id: "",
      soandcode: "",
      find_internal: "",
    };
  }

  async getItems(
    props: GetListProps<FilterReportDelivery>
  ): Promise<ReportDeliveryCollection> {
    return this._api
      ? await this._api.getList(props)
      : new ReportDeliveryCollection();
  }

  async exportExcel(
    props: GetListProps<FilterReportDelivery>
  ): Promise<boolean> {
    return this._api ? await this._api.exportExcel(props) : false;
  }

  async getSumByReport(filters: FilterReportDelivery) {
    return this._api ? await this._api.getSumByReport(filters) : {};
  }
}

export default ReportDeliveryRepository;
