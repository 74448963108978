import { Tag } from "antd";
import dbm from "dbm";
import React, { useEffect, useState } from "react";

const TextRegion = ({
  id,
  prefix,
  tagColor,
}: {
  id: number;
  prefix?: string;
  tagColor?: string;
}) => {
  const [name, setName] = useState("");

  // useEffect(() => {
  //   (async () => {
  //     const foundItems = await dbm
  //       .getCollection("region")
  //       .chain()
  //       .find({
  //         id,
  //       })
  //       .limit(1)
  //       .data();
  //     if (foundItems.length > 0) {
  //       setName(foundItems[0].name);
  //     }
  //   })();
  // }, [id]);

  const displayName = name.replace("Thành phố", "TP.");
  return (
    <span>
      {name.length > 0 ? <>{prefix} </> : null}
      {typeof tagColor !== "undefined" ? (
        <Tag color={tagColor}>{displayName}</Tag>
      ) : (
        displayName
      )}
    </span>
  );
};

export default TextRegion;
