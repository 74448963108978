import React, { useEffect } from "react";
import usePathParams from "hooks/usePathParams";

import WorkTrackingApprovalList from "features/worktrackingapproval/list/WorkTrackingApprovalList";
import WorkTrackingList from "features/worktracking/list/WorkTrackingList";
import CheckinList from "features/chekinversion1.2/list/CheckinList";
import CheckinV2Wrapper from "features/chekinversion1.2/CheckinV2Wrapper";
import CheckinMineList from "features/chekinversion1.2/list/CheckinMineList";

const WorkTrackingPage = () => {
  const [pathParams] = usePathParams();

  //clear edit model when change action
  useEffect(() => {}, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = <CheckinV2Wrapper />;
      break;
    case "approval":
      com = <WorkTrackingApprovalList />;
      break;
    case "public":
      com = <CheckinList />;
      break;
    default:
      com = <CheckinMineList />;
  }

  return com;
};

export default WorkTrackingPage;
