import WorkChatApi from "../api/WorkChatApi";
import WorkChatCollection from "../collections/WorkChatCollection";
import WorkChatModel from "../models/WorkChatModel";

import { GetListProps } from "common/types/Filter";
import type { WorkChatJsonAddEdit, FilterWorkChat } from "../types/WorkChat";

import BaseRepository from "common/repositories/BaseRepository";

class WorkChatRepository extends BaseRepository<WorkChatApi> {
  _api: WorkChatApi | null;

  constructor() {
    super();
    this._api = new WorkChatApi(true);
  }

  static getDefaultFilters(): FilterWorkChat {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      keyword: "",
      status: -1,
    };
  }

  async getItems(props: GetListProps<FilterWorkChat>) {
    return this._api
      ? await this._api.getList(props)
      : new WorkChatCollection();
  }

  async getItem(id: number): Promise<WorkChatModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new WorkChatModel(WorkChatModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: WorkChatJsonAddEdit): Promise<WorkChatModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new WorkChatModel(WorkChatModel.getDefaultData());
    }
  }
}

export default WorkChatRepository;
