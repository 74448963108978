import { App, Modal, Skeleton } from "antd";
import ShippingCarrierModel from "common/models/ShippingCarrierModel";
import ShippingCarrierRepository from "common/repositories/ShippingCarrierRepository";
import {
  FeeShiping,
  ShippingCarrierJsonEditPrice
} from "common/types/ShippingCarrier";
import ErrorAlert from "components/ErrorAlert";
import Error from "components/LayoutError";
import ShippingCarrierFeeForm from "features/shippingcarrier/fee/form/ShippingCarrierFeeForm";
import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ShippingCarrierFeeList from "../list/ShippingCarrierFeeList";

const ShippingCarrierFeeFormModal = ({
  id,
  open,
  setOpen
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [model, setModel] = useState<ShippingCarrierModel>(
    new ShippingCarrierModel(ShippingCarrierModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new ShippingCarrierRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  //convert detail string to dataStruct
  const [dataSource, setDataSource] = useState<FeeShiping[]>([]);
  useEffect(() => {
    if (model.detail.length > 0) {
      setLoading(true);
      let items: FeeShiping[] = [];
      const detailArr = model.detail.split(",");

      detailArr.map((item: string) => {
        const parseItem = item.split(":0:");
        return items.push({
          region_id: parseInt(parseItem[0]),
          fee: parseInt(parseItem[1])
        });
      });

      setDataSource(items);
      setLoading(false);
    }
  }, [model.detail]);

  const onHandleAdd = (items: FeeShiping[]) => {
    let newItems = [...dataSource];
    items.forEach((item: FeeShiping) => {
      const foundIndex = dataSource.findIndex(
        (r) => r.region_id === item.region_id
      );
      if (foundIndex === -1) {
        newItems.push(item);
      }
    });

    setDataSource(newItems);
  };

  const onUpdateItem = (item: FeeShiping) => {
    const foundIndex = dataSource.findIndex(
      (r) => r.region_id === item.region_id
    );
    if (foundIndex >= 0) {
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item
          }
        })
      );
    }
  };

  const doPrepareData = useCallback(() => {
    const details = dataSource.map((item: FeeShiping) => {
      return `${item.region_id}:0:${item.fee}`;
    });

    const submitData: ShippingCarrierJsonEditPrice = {
      detail: details.toString()
    };

    return submitData;
  }, [dataSource]);

  const onSubmit = async () => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ShippingCarrierModel =
      await new ShippingCarrierRepository().saveRemotePrice(
        model.id,
        doPrepareData()
      );

    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="shippingcarrier:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("shippingcarrier:form.success.update"),
        className: "message_success",
        key: "message",
        duration: 2
      });
    }
    setProcessing(false);
    setOpen(false);
  };

  return (
    <Modal
      title={
        id > 0 ? `${t("shippingcarrier:fee_detail")}: ${model.name}` : null
      }
      width={1000}
      open={open}
      closable={true}
      maskClosable={true}
      {...(id > 0 && !model.id && { footer: null })}
      onCancel={() => {
        setModel(
          new ShippingCarrierModel(ShippingCarrierModel.getDefaultData())
        );
        setOpen(false);
      }}
      onOk={onSubmit}
      okButtonProps={{ form: "office-form", htmlType: "submit" }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      confirmLoading={processing}>
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <>
                  <ShippingCarrierFeeForm
                    dataSource={dataSource}
                    onHandleAdd={onHandleAdd}
                  />
                  <ShippingCarrierFeeList
                    dataSource={dataSource}
                    loading={loading}
                    onUpdateItem={onUpdateItem}
                    onDelete={(region_id: number) => {
                      setDataSource(
                        dataSource.filter(
                          (item) => item.region_id !== region_id
                        )
                      );
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : null}
    </Modal>
  );
};

export default ShippingCarrierFeeFormModal;
