import { GoongMapCoordinates } from 'common/types/goongmap/GoongMapType';
import GoongMapMarker from 'components/goongmap/GoongMapMarker';
import GoongMapPin from 'components/goongmap/GoongMapPin';
import React from 'react';

const DeliveryDashboardSectionRightGoongMapMarker = ({
  className,
  item,
  img,
  onClick,
}: {
  className?: string;
  item: GoongMapCoordinates;
  img?: React.ReactNode;
  onClick: (v: GoongMapCoordinates) => void;
}) => {
  return (
    <GoongMapMarker latitude={item.latitude} longitude={item.longitude}>
      <GoongMapPin item={item} img={img} onClick={onClick} />
    </GoongMapMarker>
  );
};

export default DeliveryDashboardSectionRightGoongMapMarker;
