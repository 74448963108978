import { Button, Spin, Tooltip } from "antd";
import BiCenterCollection from "common/collections/BiCenterCollection";
import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import {
  ChartCategoryDatum,
  ChartCategoryProps
} from "common/types/BiCenterChart";
import ReportUtil from "common/utils/report";
import Error from "components/LayoutError";
import TextMoney from "components/TextMoney";
import TextNumber from "components/TextNumber";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";

import { Column, Pie } from "@ant-design/charts";
import {
  IconCategory2,
  IconChartBar,
  IconChartPie2
} from "@tabler/icons-react";

import type { DataQuery, DateRange } from "common/types/BiCenter";
const ReportChartCategory = ({
  title,
  subtitle,
  height,
  chartType,
  className,
  dateRange,
  keyMapping,
  dataSelect,
  dataService,
  dataTable,
  dataFilter,
  dataGroupBy,
  dataOrderBy,
  dataJoin,
  valueType
}: ChartCategoryProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<ChartCategoryDatum[]>([]);
  const [renderChartType, setRenderChartType] = useState(chartType || "pie");

  //load data for chart
  const loadData = useCallback(
    async ({ range }: { range: DateRange }) => {
      setLoading(true);

      var props: DataQuery = {
        service: dataService,
        start: BiCenterModel.momentToString(range[0]),
        end: BiCenterModel.momentToString(range[1]),
        startcompare: "",
        endcompare: "",
        table: dataTable,
        join: dataJoin || "",
        select: dataSelect,
        filter: dataFilter,
        groupby: dataGroupBy,
        orderby: dataOrderBy,
        timeserie: "",
        limit: "",
        offset: ""
      };

      //Call repository to fetch data
      const collection: BiCenterCollection =
        await new BiCenterRepository().queryRemote(props);
      setLoading(false);
      if (collection.hasError()) {
        setErrors(collection.error.errors);
      } else {
        setErrors([]);

        //extract data for chart
        let fetchedData: ChartCategoryDatum[] = [];

        if (collection.items.length === 1) {
          if (typeof collection.items[0] !== "undefined") {
            fetchedData = collection.items[0].rows.map((row) => {
              return {
                id: +row[keyMapping.name],
                name: keyMapping.formatter(+row[keyMapping.name]),
                value: +row[keyMapping.value] || 0
              };
            });
          }
        }

        setData(fetchedData);
      }
    },
    [
      dataService,
      dataTable,
      dataJoin,
      dataSelect,
      dataFilter,
      dataGroupBy,
      dataOrderBy,
      keyMapping
    ]
  );

  //Reload data if change daterange
  useDeepCompareEffect(() => {
    loadData({
      range: dateRange
    });
  }, [dateRange, loadData]);

  const configPie = {
    color: ReportUtil.getColors(),
    colorField: "name",
    radius: 0.9,
    angleField: "value",
    label: {
      type: "outer",
      content: "{name}"
    },
    state: {
      active: {
        style: {
          lineWidth: 0.5,
          fillOpacity: 0.8
        }
      }
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }]
  };

  const configColumn = {
    color: "#1890ff",
    xField: "name",
    yField: "value",
    xAxis: {
      label: {
        rotate: 45,
        offset: 30
      }
    },
    yAxis: {
      tickCount: 5,
      label: {
        formatter: ReportUtil.numberFormatter
      }
    }
  };

  //chart config
  const config = {
    data: data,
    height: height,
    forceFit: true,
    responsive: true,
    appendPadding: 10,
    legend: undefined,
    tooltip: {
      customContent: (title: string, data: any[]) => {
        if (data.length > 0) {
          if (data[0] && typeof data[0].data !== "undefined") {
            if (typeof data[0].data.value !== "undefined") {
              return (
                <div className="pd-10">
                  {title}:{" "}
                  {data && data.length > 0 ? (
                    <>
                      {typeof valueType !== "undefined" &&
                      valueType === "money" ? (
                        <TextMoney money={data[0].data.value} />
                      ) : (
                        <TextNumber number={data[0].data.value} />
                      )}
                    </>
                  ) : null}
                </div>
              );
            }
          }
        }
        return "";
      }
    }
  };

  return (
    <div className={"report_time_series " + className}>
      <div className="flex">
        <div className="grow">
          <div className="text-2xl font-semibold">
            <IconCategory2 className="-mt-1 text-blue-600" /> {title}
          </div>
          {typeof subtitle !== "undefined" ? (
            <div className="ml-8 text-gray-400 text-md">{subtitle}</div>
          ) : null}
        </div>
        <div className="shrink">
          <Spin className="mg-l-20" spinning={loading} size="small"></Spin>
          <Tooltip title={t("report:toggle_chart_type")} mouseEnterDelay={0.4}>
            <Button
              onClick={() =>
                setRenderChartType(renderChartType === "pie" ? "column" : "pie")
              }
              type="link"
              icon={
                renderChartType === "pie" ? (
                  <IconChartBar size="16" />
                ) : (
                  <IconChartPie2 size="16" />
                )
              }
            />
          </Tooltip>
        </div>
      </div>

      <div>
        {errors.length > 0 ? (
          <Error
            className="report_error"
            heading={t("report:error.query_heading")}
            contentPadding={0}
            translate_prefix="report:error"
            items={errors}
          />
        ) : (
          <div className="mt-8">
            {renderChartType === "pie" ? (
              <Pie {...config} {...configPie} />
            ) : (
              <Column {...config} {...configColumn} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportChartCategory;
