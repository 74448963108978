import { Col, Row } from "antd";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import React from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

const DeliveryDashboardSectionCenterRouteStatus = () => {
  const activeRoute = useDeliveryDashboardStore((state) => state.activeRoute);
  return (
    <>
      <Row>
        <Col span={24}>
          <div
            className="flex justify-center py-1 text-white uppercase font-light text-xs"
            style={{
              backgroundColor: ShippingRouteModel.getStatus(
                activeRoute !== undefined ? activeRoute.status : 0
              )?.color,
            }}
          >
            {
              ShippingRouteModel.getStatus(
                activeRoute !== undefined ? activeRoute.status : 0
              )?.label
            }
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DeliveryDashboardSectionCenterRouteStatus;
