import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const CompanyPlanCheck = ({
  minPlan,
  children,
  hideOnFail,
  componentOnFail,
  prefix,
}: {
  minPlan: number;
  hideOnFail?: boolean;
  children?: React.ReactNode;
  componentOnFail?: React.ReactNode;
  prefix?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  const plan = useLoginAccountStore((state) => state.account.company.plan);

  const hasPlan = plan >= minPlan;

  if (hasPlan) {
    return <>{children}</>;
  } else {
    if (typeof hideOnFail !== "undefined" && hideOnFail) {
      return null;
    } else {
      if (typeof componentOnFail !== "undefined") {
        return <>{componentOnFail}</>;
      } else {
        return (
          <div className="content content-auth-alt">
            <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
              <div className="max-w-max mx-auto mt-4">
                <main className="sm:flex">
                  <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">
                    {prefix}
                  </p>
                  <div className="sm:ml-6">
                    <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                      <h1 className="text-3xl font-bold text-gray-900 tracking-tight">
                        {t("company:plan_not_allow")}
                      </h1>
                      <p className="mt-2 text-base text-gray-500">
                        {t("company:plan_not_allow_help")}
                        <br />
                        <Link to={"/company/upgrade"} className="mt-4">
                          <Button type="primary" className="mt-4">
                            {t("company:plan.upgrade_label")}
                          </Button>
                        </Link>
                      </p>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        );
      }
    }
  }
};

export default CompanyPlanCheck;
