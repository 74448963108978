import ProductReceiptApi from "common/api/ProductReceiptApi";
import ProductReceiptCollection from "common/collections/ProductReceiptCollection";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  ProductReceiptJsonAddEdit,
  FilterProductReceipt
} from "common/types/ProductReceipt";

class ProductReceiptRepository extends BaseRepository<ProductReceiptApi> {
  _api: ProductReceiptApi | null;

  constructor() {
    super();
    this._api = new ProductReceiptApi(true);
  }

  static getDefaultFilters(): FilterProductReceipt {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
      direction: -1,
      warehouse_id: -1,
      type: -1,
      source_id: -1,
      purchase_order_id: -1,
      date_started: -1,
      date_ended: -1
    };
  }

  async getItems(props: GetListProps<FilterProductReceipt>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductReceiptCollection();
  }

  async getItem(id: number): Promise<ProductReceiptModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductReceiptModel(ProductReceiptModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductReceiptJsonAddEdit
  ): Promise<ProductReceiptModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductReceiptModel(ProductReceiptModel.getDefaultData());
    }
  }
}

export default ProductReceiptRepository;
