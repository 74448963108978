import { IconTrash } from "@tabler/icons-react";
import { Button, Popconfirm, Spin } from "antd";
import { ButtonProps } from "antd/lib/button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ListDeleteButton = ({
  onDeleteErrorCallback,
  className,
  id,
  disabled,
  error_translate_prefix,
  icon,
  title,
  label,
  buttonBlock,
  buttonSize,
  buttonType="default",
  block = false,
  handleDelete,
}: {
  id: number;
  onDeleteErrorCallback?: (id: number) => void;
  className?: string;
  disabled?: boolean;
  error_translate_prefix?: string;
  icon?: React.ReactNode;
  title?: string;
  label?: string;
  buttonBlock?: ButtonProps["block"];
  buttonSize?: ButtonProps["size"];
  buttonType?: ButtonProps["type"];
  handleDelete: any;
  block?: boolean;
}) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState<boolean>(false);

  const comButton = (
    <Button
      disabled={disabled}
      size={buttonSize || "middle"}
      type={buttonType || "text"}
      className={className}
      block={buttonBlock}
    >
      {label || "Xóa"}
    </Button>
  );
  return (
    <>
      <Popconfirm
        title={title || t("common:table.confirm_delete_title")}
        placement="topRight"
        onConfirm={handleDelete}
        okText={t("common:table.confirm_ok")}
        cancelText={t("common:table.confirm_cancel")}
        disabled={processing || disabled}
      >
        {processing ? <Spin size="small" /> : comButton}
      </Popconfirm>
    </>
  );
};

export default ListDeleteButton;
