import InfoContent from "components/InfoContent";

import CompanyInfoSystemSearchIndexInventoryStock from "./CompanyInfoSystemSearchIndexInventoryStock";

const CompanyInfoSystemSearchIndex = () => {
  return (
    <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8">
      <InfoContent>
        <CompanyInfoSystemSearchIndexInventoryStock />
      </InfoContent>
    </div>
  );
};

export default CompanyInfoSystemSearchIndex;
