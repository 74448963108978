import update from "immutability-helper";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import NestedCollection from "common/collections/NestedCollection";
import ProductCollectionCollection from "common/collections/ProductCollectionCollection";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import { GetListProps } from "common/types/Filter";
import {
	AddCollectionToCollection,
	CollectionDetailDelete,
	DataChangeDislayOrder,
	FilterDetailProductCollection,
	FilterProductCollection,
	FilterProductCollectionDetail,
	ProductCollectionAddEditJson,
	ProductCollectionDetailAddEditJson,
	ProductCollectionJsonWithChildren,
	ProductDetailDelete,
	URLToHandleJson,
	URLWebJson,
} from "common/types/ProductCollection";
import Helper from "common/utils/helper";
import ProductCollectionDetailCollection from "common/collections/ProductCollectionDetail";
import DetailProductCollectionCollection from "common/collections/DetailProductCollectionCollection";

const SERVICE_URL = "/productcollections";
const SERVICE_URL_2 = "/storycollections";

class ProductCollectionApi extends BaseApi {
	async getList(
		props: GetListProps<FilterProductCollection>
	): Promise<ProductCollectionCollection> {
		let collection = new ProductCollectionCollection();
		console.log(props.filters)
		const convertProps = Helper.convertParams(props.filters);
		console.log(convertProps)
		const queryData = {
			params: {
				...convertProps,
			},
		};

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL,
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}

	async getAll(
		props: GetListProps<FilterProductCollection>
	): Promise<NestedCollection<ProductCollectionJsonWithChildren>> {
		let allItems: ProductCollectionJsonWithChildren[] = [];
		let collection = new NestedCollection<ProductCollectionJsonWithChildren>();
		let page = 1;

		const convertProps = Helper.convertParams(props.filters);
		const queryData = {
			params: {
				...convertProps,
				page: page,
				limit: 50,
				sort_by: "display_order",
				sort_type: "ASC",
			},
		};

		try {
			do {
				const currentPageCollection = await AxiosClient().get<any>(
					SERVICE_URL,
					queryData
				);

				if (currentPageCollection.hasOwnProperty("data")) {
					allItems = update(allItems, {
						$push: currentPageCollection.data.items,
					});

					if (
						currentPageCollection.data.items.length < queryData.params.limit
					) {
						break;
					} else {
						page++;
					}
				} else {
					break;
				}
			} while (true);

			collection.buildNested(allItems);
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: number | string): Promise<ProductCollectionModel> {
		let item = new ProductCollectionModel(
			ProductCollectionModel.getDefaultData()
		);

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/" + id
			);
			if (response.hasOwnProperty("data")) {
				item = new ProductCollectionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}

	async add(
		props: Partial<ProductCollectionAddEditJson>
	): Promise<ProductCollectionModel> {
		let item = new ProductCollectionModel(
			ProductCollectionModel.getDefaultData()
		);

		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			const response = await AxiosClient().post<any>(
				SERVICE_URL,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new ProductCollectionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
	//
	async edit(
		props: Partial<ProductCollectionAddEditJson>
	): Promise<ProductCollectionModel> {
		let item = new ProductCollectionModel(
			ProductCollectionModel.getDefaultData()
		);
		// remove id key
		///////////////
		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);
		try {
			const postData = {
				...convertProps,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + props.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new ProductCollectionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	// async delete(id: number): Promise<string[]> {
	//   let resultErrors = [];

	//   try {
	//     const response = await AxiosClient().delete(SERVICE_URL + "/admin/" + id);
	//     if (response.status !== 200) {
	//       resultErrors.push("error_delete_response_not_204");
	//     }
	//   } catch (error) {
	//     resultErrors = BaseApi.handleError(error).errors;
	//   }

	//   return resultErrors;
	// }

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];
		const params = {
			collection_ids: [id],
		};
		try {
			const response = await AxiosClient().delete(SERVICE_URL, {
				data: params,
			});
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	//add product->collection
	async addDetail(
		props: ProductCollectionDetailAddEditJson
	): Promise<ProductCollectionModel> {
		let item = new ProductCollectionModel(
			ProductCollectionModel.getDefaultData()
		);

		let dataProps: any = { ...props };
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			const response = await AxiosClient().post<any>(
				SERVICE_URL + "/details",
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new ProductCollectionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}

	//detele product
	async deleteDetail(data: ProductDetailDelete): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/details", {
				data: { ...data },
			});
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	//detele collection in collection
	async deleteCollectionDetail(
		data: CollectionDetailDelete
	): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/many", {
				data: { ...data },
			});
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	//detele collection in collection
	async addCollectionToCollectionDetail(
		props: AddCollectionToCollection
	): Promise<ProductCollectionModel> {
		let item = new ProductCollectionModel(
			ProductCollectionModel.getDefaultData()
		);

		let dataProps: any = { ...props };
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/parent",
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new ProductCollectionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}

	async getProductColelctionDetailList(
		props: GetListProps<FilterProductCollectionDetail>
	): Promise<ProductCollectionDetailCollection> {
		let collection = new ProductCollectionDetailCollection();
		const convertProps = Helper.convertParams(props.filters);
		const queryData = {
			params: {
				...convertProps,
			},
		};

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/public/detail",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}

	async URLFromHandle(data: URLToHandleJson): Promise<URLWebJson> {
		let result: URLWebJson = { url: "" };

		const queryData = {
			params: {
				...data,
			},
		};
		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL_2 + "/public/link",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				result = response.data;
			}
		} catch (error: any) {
			result.error = error?.response?.data?.error;
		}
		return result;
	}
	async getDetailProductCollection(
		props: FilterDetailProductCollection
	): Promise<DetailProductCollectionCollection> {
		let dataProps: any = {
			...props,
			sort_by: props.sortby,
			sort_type: props.sorttype,
			sortby: null,
			sorttype: null,
		};

		const convertProps = {
			...Helper.convertParams(dataProps),
		};

		let collection = new DetailProductCollectionCollection();

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/public/detail",
				{ params: convertProps }
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}

	async updateDisplayOrderDetail(
		data: DataChangeDislayOrder
	): Promise<string[]> {
		const postData = {
			data: [
				{
					id: data.id,
					display_order: data.display_order,
				},
			],
		};
		let result: string[] = [];
		try {
			const response = await AxiosClient().put<any>(
				SERVICE_URL + `/details`,
				postData
			);
			if (response.data) {
			}
		} catch (error) {
			result = BaseApi.handleError(error).errors;
		}

		return result;
	}
}

export default ProductCollectionApi;
