import TaxInvoiceModel from "common/models/TaxInvoiceModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/taxinvoicegateways";

class TaxInvoiceGatewayApi extends BaseApi {
  async createRemoteOrder(id: number): Promise<TaxInvoiceModel> {
    let item = new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());

    try {
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/" + id + "/createremote"
      );
      if (response.hasOwnProperty("data")) {
        item = new TaxInvoiceModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async syncStatus(id: number): Promise<TaxInvoiceModel> {
    let item = new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());

    try {
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/" + id + "/syncremote"
      );
      if (response.hasOwnProperty("data")) {
        item = new TaxInvoiceModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default TaxInvoiceGatewayApi;
