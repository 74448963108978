import { Col, Form, Row, Typography } from "antd";
import FormSection from "components/form/FormSection";
import React from "react";
import UploadEvent from "./UploadEvent";
import { ZoneDataSupport } from "common/types/Event";
import { FormInstance } from "antd/lib/form";

export default function SectionSupport({
	data,
	form,
}: {
	data: ZoneDataSupport;
	form: FormInstance<any>;
}) {
	return (
		<div>
			{" "}
			<FormSection
				title={"Thông banner banner suppport"}
				subtitle={"Cài đặt 2 banner support mobile & desktop"}
				leftColumnSize={6}
				divider>
				<Form.Item initialValue={data} name="zone_support">
					<Row gutter={16}>
						<Col span={24}>
							<Typography.Text>banner support desktop</Typography.Text>
							<UploadEvent
								className="flex gap-2"
								height={120}
								defaultUrl={data.image_desktop}
								onSuccess={(url) => {
									const key = "image_desktop";
									let datas = form.getFieldValue("zone_support");
									datas = { ...data, [key]: url };
									form.setFieldValue("zone_support", datas);
								}}></UploadEvent>
						</Col>
						<Col span={24}>
							<Typography.Text>banner support mobile</Typography.Text>
							<UploadEvent
								className="flex gap-2"
								height={120}
								defaultUrl={data.image_mobile}
								onSuccess={(url) => {
									const key = "image_mobile";
									let datas = form.getFieldValue("zone_support");

									datas = { ...datas, [key]: url };
									form.setFieldValue("zone_support", datas);
								}}></UploadEvent>
						</Col>
					</Row>
				</Form.Item>
			</FormSection>
		</div>
	);
}
