import Role from 'common/constants/Role';
import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import RoleCheck from 'components/RoleCheck';
import { useTranslation } from 'react-i18next';

const CmsPostListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("cmspost:heading_list")}
      siteMenuOpenKey="sitemenu:group_cms"
      siteMenuSelectedKey="/cmspost"
    >
      <RoleCheck roles={[Role.CMS_POST_ADD]} hideOnFail>
        <PageHeaderButton link="/cmspost/add" className="ml-2">
          {t("cmspost:add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default CmsPostListHeader;
