import React, { useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Image,
  List,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";

import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import {
  IconCameraRotate,
  IconRefresh,
  IconReload,
  IconX,
} from "@tabler/icons-react";
import useDidMount from "@rooks/use-did-mount";
import { WindowsFilled } from "@ant-design/icons";

export type FacingModel = "user" | "environment";

const DeliveryMultipleWebCam = ({
  imgScreenshotSrc,
  changeImgSrc,
  widthImg,
  heightImg,
  enableWebcam,
  maxNumberImg = 1,
  pending,
  defaultFacingMode = "user",
}: {
  imgScreenshotSrc: string[];
  changeImgSrc: (images: string[]) => void;
  enableWebcam: boolean;
  widthImg?: number | string;
  heightImg?: number | string;
  maxNumberImg?: number;
  pending: boolean;
  defaultFacingMode?: FacingModel;
}) => {
  const { t } = useTranslation();
  const webcamRef = useRef(null) as MutableRefObject<any>;
  const { Text, Title } = Typography;

  const [facingMode, setFacingMode] = useState<FacingModel>("environment");

  // state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [capturedImages, setCapturedImages] = useState<string[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  // function
  const captureScreenshot = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      width: widthImg,
      height: heightImg,
    });
    if (capturedImages.length < maxNumberImg) {
      setCapturedImages([...capturedImages, imageSrc]);
    } else {
      messageApi.open({
        type: "error",
        content: "Bạn đã chụp đủ ảnh !",
        duration: 3,
      });
    }
  }, [webcamRef, changeImgSrc, setCapturedImages]);

  const switchCamera = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === "user" ? "environment" : "user"
    );
  };
  ///////////////////////////////////////
  useEffect(() => {
    changeImgSrc(capturedImages);
  }, [capturedImages]);

  const handleLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // effect
  // useDidMount(async () => {
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       video: { facingMode: { ideal: "environment" } },
  //       audio: false,
  //     })
  //     .then((stream) => {
  //       // setVideoConstraints({ facingMode: { exact: "user" } });
  //       setError(false);
  //     })
  //     .catch((error) => {
  //       message.open({
  //         type: "warning",
  //         content: "Bạn hãy mở quyền truy cập camera !",
  //         key: "err",
  //         duration: 2,
  //       });
  //     });
  //   return async () => {
  //     await navigator.mediaDevices.getUserMedia({ video: false });
  //   };
  // });

  React.useEffect(() => {
    handleLoading();
    // return () => {
    //   let stream = webcamRef.current?.video?.srcObject;
    //   const tracks = stream?.getTracks();
    //   tracks?.forEach((track: any) => track.stop());
    // };
    const cleanup = async () => {
      let stream = webcamRef.current?.video?.srcObject;
      if (stream) {
        const tracks = stream?.getTracks();
        tracks?.forEach((track: any) => track.stop());
      }
    };

    return () => {
      cleanup();
    };
  }, []);

  const deleteImageInList = (selectImg: string) => {
    const newCapList = capturedImages;
    const findElementIndex = newCapList.findIndex((ite) => ite === selectImg);
    if (findElementIndex >= 0) {
      newCapList.splice(findElementIndex, 1);
      setCapturedImages([...newCapList]);
    }
  };

  return (
    <Space
      className="relative mb-1"
      style={{ width: "100%", height: "auto", display: "inline-block" }}
    >
      {contextHolder}
      {/* <Tooltip trigger="hover" placement="topLeft" title={t("Thử lại")}>
        <Button type="default" className="h-full" onClick={switchCamera}>
          <IconReload />
        </Button>
      </Tooltip> */}
      {error && errorCount < 5 ? (
        <>
          <Alert
            message={
              <Text>
                Yêu cầu truy cập camera{". "}
                <Typography.Link>Nhấn để truy cập</Typography.Link>{" "}
              </Text>
            }
            type="warning"
            showIcon
            closable={false}
            onClick={async () => {
              await switchCamera();
              await navigator.mediaDevices
                .getUserMedia({ video: true })
                .then((stream) => {
                  // setVideoConstraints({ facingMode: { exact: "user" } });
                  setErrorCount(0);
                  setError(false);
                })
                .catch((error) => {
                  message.open({
                    type: "warning",
                    content: (
                      <div className="flex flex-col items-center justify-center">
                        <Typography.Text>
                          Bạn hãy mở quyền truy cập camera !
                        </Typography.Text>
                        <Typography.Text>{error.toString()}</Typography.Text>
                      </div>
                    ),
                    key: "err",
                    duration: 2,
                  });
                  setErrorCount((prev) => prev + 1);
                });
            }}
          />
        </>
      ) : error && errorCount >= 5 ? (
        <>
          <Alert
            message={
              <Text>
                Có lỗi xảy ra. Vui lòng kiểm tra quyền truy cập camera.
                <Typography.Link
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Nhấn để reload lại trang.
                </Typography.Link>{" "}
              </Text>
            }
            type="error"
            showIcon
            closable={false}
          />
        </>
      ) : (
        <>
          <Row className="block md:flex" style={{ margin: 4 }}>
            {enableWebcam ? (
              <Col span={24} flex={"325px"}>
                {/* <Button
                type="link"
                onClick={() => {
                  setEnableWebcam(false);
                  //   setImgScreenshotSrc("");
                  changeImgSrc("");
                }}
              >
                <Text> {t("worktracking:enablewebcam_off")}</Text>
              </Button> */}
                <Tooltip
                  trigger="hover"
                  placement="topLeft"
                  title={t("đổi camera")}
                >
                  <Button
                    type="default"
                    className="h-full"
                    onClick={switchCamera}
                  >
                    <IconCameraRotate />
                  </Button>
                </Tooltip>
              </Col>
            ) : null}
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24} className="md:flex md:justify-center">
              {enableWebcam && !loading ? (
                <>
                  {facingMode === "user" ? (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      style={{
                        margin: "0 auto",
                        // height: 250,
                      }}
                      width={200}
                      height={200}
                      className="relative mx-auto w-[100%] md:w-[325px]"
                      screenshotFormat="image/webp"
                      videoConstraints={{ facingMode: "user" }}
                      imageSmoothing
                      onUserMediaError={(error: string | DOMException) => {
                        if (error) {
                          setError(true);
                        }
                      }}
                      // mirrored={false}

                      // onUserMedia={handleLoading}
                    />
                  ) : (
                    <></>
                  )}
                  {facingMode === "environment" ? (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      style={{
                        margin: "0 auto",
                        // height: 250,
                      }}
                      width={200}
                      height={200}
                      className="relative mx-auto w-[100%] md:w-[325px]"
                      screenshotFormat="image/webp"
                      videoConstraints={{
                        facingMode: { ideal: "environment" },
                      }}
                      imageSmoothing
                      onUserMediaError={(error: string | DOMException) => {
                        if (error) {
                          setError(true);
                        }
                      }}
                      // mirrored={false}

                      // onUserMedia={handleLoading}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Skeleton active></Skeleton>
              )}
            </Col>
            {capturedImages.length > 0 && (
              <Col
                span={24}
                className="max-h-[200px] overflow-y-auto hide_scrollbar"
              >
                <List
                  dataSource={capturedImages}
                  bordered={false}
                  grid={{
                    gutter: 16,
                    xs: 2,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 3,
                    xxl: 4,
                  }}
                  renderItem={(item) => {
                    return (
                      <List.Item>
                        <Popconfirm
                          title="Bạn có muốn xóa ảnh"
                          onConfirm={() => {
                            deleteImageInList(item);
                          }}
                        >
                          <div className="relative">
                            <Image
                              src={item}
                              preview={false}
                              width={"100%"}
                              height={100}
                              className="max-w-full cursor-pointer"
                            ></Image>
                          </div>
                        </Popconfirm>
                      </List.Item>
                    );
                  }}
                ></List>
              </Col>
            )}
            {enableWebcam && !error && !loading && (
              <Col span={24}>
                <Button
                  disabled={
                    !enableWebcam ||
                    capturedImages.length >= maxNumberImg ||
                    pending
                  }
                  block
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    captureScreenshot();
                  }}
                >
                  {t("worktracking:capture")}
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
    </Space>
  );
};

export default DeliveryMultipleWebCam;
