import { Col, Form, Row, Spin, Tooltip } from "antd";
import PosCartModel from "common/models/PosCartModel";
import CustomerHelperRepository from "common/repositories/CustomerHelperRepository";
import { PosCartPayment } from "common/types/PosCart";
import TextMoney from "components/TextMoney";
import React, { useCallback, useEffect, useState } from "react";
import { useOnline } from "rooks";
import usePosCartStore from "zustands/usePosCartStore";

import PosRightPaymentMethodInputMoney from "./input/PosRightPaymentMethodInputMoney";
import PosRightPaymentMethodInputNote from "./input/PosRightPaymentMethodInputNote";

const PosRightPaymentMethodCustomerPoint = ({
  disabled,
  method,
  payValue,
  setEditingPayment,
  children,
}: {
  disabled: boolean;
  method: number;
  payValue: number;
  setEditingPayment: (v: PosCartPayment) => void;
  children?: React.ReactNode;
}) => {
  const onlineStatus = useOnline();
  const [myPayValue, setMyPayValue] = useState(0);
  const [note, setNote] = useState("");
  const [usePoint, setUsePoint] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pointExchange, setPointExchange] = useState(0);
  const [remainPoint, setRemainPoint] = useState(0);

  const activeCustomer = usePosCartStore(
    (state) =>
      state.getActiveCart()?.customer || PosCartModel.getDefaultCartCustomer()
  );

  const loadCurrentPoint = useCallback(async () => {
    setLoading(true);

    let data = await new CustomerHelperRepository().getPointForPos(
      activeCustomer.id
    );

    setRemainPoint(data.point);
    setPointExchange(data.point_exchange);
    setLoading(false);

    //calculate to set default mypayvalue
    if (data.point * data.point_exchange > payValue) {
      setMyPayValue(payValue);
    } else {
      setMyPayValue(data.point * data.point_exchange);
    }
  }, [activeCustomer, payValue]);

  useEffect(() => {
    setEditingPayment({
      _id: "",
      method: method,
      value:
        remainPoint * pointExchange > myPayValue
          ? myPayValue
          : remainPoint * pointExchange,
      note,
    });
  }, [setEditingPayment, myPayValue, note, method, remainPoint, pointExchange]);

  //re-calculate point when change value
  useEffect(() => {
    if (!loading && pointExchange > 0) {
      setUsePoint(
        Math.ceil(
          (remainPoint * pointExchange >= myPayValue
            ? myPayValue
            : remainPoint * pointExchange) / pointExchange
        )
      );
    }
  }, [myPayValue, loading, pointExchange, remainPoint]);

  //load currentpoint when didmount
  useEffect(() => {
    loadCurrentPoint();
  }, [loadCurrentPoint]);

  useEffect(() => {
    //detect not enough point
    if (remainPoint * pointExchange < myPayValue) {
      const maxAllowPayValue = Math.ceil(remainPoint * pointExchange);
      if (maxAllowPayValue < myPayValue) {
        setMyPayValue(maxAllowPayValue);
      }
    }
  }, [myPayValue, pointExchange, remainPoint]);

  return (
    <Form layout="vertical">
      {onlineStatus ? null : (
        <div className="alert alert-danger">
          Hiện tại POS không có kết nối đến INTERNET. Vui lòng kiểm tra lại
          đường truyền INTERNET bởi vì tính năng này bắt buộc POS phải kết nối
          mạng.
        </div>
      )}
      <Row>
        <Col span={8}>
          <div>Thông tin khách hàng: </div>
          {activeCustomer.id > 0 ? (
            <div>
              - {activeCustomer.full_name} (
              {loading ? (
                <Spin size="small"></Spin>
              ) : (
                <strong>{remainPoint}</strong>
              )}{" "}
              điểm)
            </div>
          ) : (
            <div>Bạn chưa chọn khách hàng</div>
          )}
        </Col>
        <Col span={6}>
          <PosRightPaymentMethodInputMoney
            myPayValue={myPayValue}
            setMyPayValue={setMyPayValue}
            payValue={payValue}
          />
          <div style={{ marginTop: -20, color: "#16ab39" }}>
            <Tooltip
              mouseEnterDelay={0.4}
              title={
                <>
                  1 điểm đổi lấy&nbsp;
                  <TextMoney money={pointExchange} />
                </>
              }
            >
              Sẽ trừ: {usePoint} điểm.
            </Tooltip>
          </div>
        </Col>

        <Col span={10}>
          <PosRightPaymentMethodInputNote note={note} setNote={setNote} />
        </Col>
      </Row>
    </Form>
  );
};

export default PosRightPaymentMethodCustomerPoint;
