import { Route, Routes, useNavigate } from "react-router-dom";

import Header from "components/header/Header";
import ApiKeyPage from "pages/ApiKeyPage";
import CashflowReceiptPage from "pages/CashflowReceiptPage";
import CheckInPage from "pages/CheckInPage";
import CmsNewsCategoryPage from "pages/CmsNewsCategoryPage";
import CmsNewsPage from "pages/CmsNewsPage";
import CmsPostPage from "pages/CmsPostPage";
import CompanyPage from "pages/CompanyPage";
import CustomerPage from "pages/CustomerPage";
import CustomerPointPage from "pages/CustomerPointPage";
import CustomerTypePage from "pages/CustomerTypePage";
import DataGridPage from "pages/DataGridPage";
import DeliveryDashboardPage from "pages/DeliveryDashboardPage";
import DeliveryPlanningPage from "pages/DeliveryPlanningPage";
import DepartmentPage from "pages/DepartmentPage";
import DmsBannerPage from "pages/DmsBannerPage";
import DmsNewsCategoryPage from "pages/DmsNewsCategoryPage";
import DmsNewsPage from "pages/DmsNewsPage";
import DmsPostPage from "pages/DmsPostPage";
import EcomChannelOrderPage from "pages/EcomChannelOrderPage";
import EcomChannelProductPage from "pages/EcomChannelProductPage";
import EcomLogPage from "pages/EcomLogPage";
import EcomPage from "pages/EcomPage";
import EmployeePage from "pages/EmployeePage";
import FilePage from "pages/FilePage";
import HomePage from "pages/HomePage";
import ITRequestPage from "pages/ITRequestPage";
import InventoryPage from "pages/InventoryPage";
import LeaveApprovePage from "pages/LeaveApprovePage";
import NotFoundPage from "pages/NotFoundPage";
import OfficePage from "pages/OfficePage";
import OrderPage from "pages/OrderPage";
import PageWrapper from "pages/PageWrapper";
import PhoneBookPage from "pages/PhoneBookPage";
import PosPage from "pages/PosPage";
import ProductCategoryPage from "pages/ProductCategoryPage";
import ProductInventoryCheckingPage from "pages/ProductInventoryCheckingPage";
import ProductPage from "pages/ProductPage";
import ProductReceiptPage from "pages/ProductReceiptPage";
import ProductTransmitPage from "pages/ProductTransmitPage";
import ProductVariantColorPage from "pages/ProductVariantColorPage";
import ProductVariantSizePage from "pages/ProductVariantSizePage";
import ProfilePage from "pages/ProfilePage";
import ProjectPage from "pages/ProjectPage";
import PromotionPage from "pages/PromotionPage";
import PurchaseOrderPage from "pages/PurchaseOrderPage";
import ReportPage from "pages/ReportPage";
import RolePage from "pages/RolePage";
import SettingPage from "pages/SettingPage";
import ShipperApplyPage from "pages/ShipperApplyPage";
import ShipperPage from "pages/ShipperPage";
import ShippingCarrierPage from "pages/ShippingCarrierPage";
import ShippingHubPage from "pages/ShippingHubPage";
import ShippingOrderPage from "pages/ShippingOrderPage";
import ShippingRoutePage from "pages/ShippingRoutePage";
import ShippingTypePage from "pages/ShippingTypePage";
import StorePage from "pages/StorePage";
import SupplierPage from "pages/SupplierPage";
import TaxInvoicePage from "pages/TaxInvoicePage";
import TemplatePage from "pages/TemplatePage";
import ToolPage from "pages/ToolPage";
import UserForgotPassPage from "pages/UserForgotPassPage";
import UserLoginPage from "pages/UserLoginPage";
import UserResetPassPage from "pages/UserResetPassPage";
import WarehousePage from "pages/WarehousePage";
import WebHookPage from "pages/WebHookPage";
import WorkChatPage from "pages/WorkChatPage";
import WorkSchedulePage from "pages/WorkSchedulePage";
//
import CollectionPage from "pages/CollectionPage";
import CommentPage from "pages/CommentPage";
import ContractTypesPage from "pages/ContractTypePage";
import DeliveryPage from "pages/DeliveryPage";
import LeavePage from "pages/LeavePage";
import LeaveTypesPage from "pages/LeaveTypePage";
import NotificationPage from "pages/NotificationPage";
import StoryPage from "pages/StoryPage";
import TaskPage from "pages/TaskPage";
import useLoginAccountStore from "zustands/useLoginAccountStore";
//academy
import AcademyDetail from "features/academy/component/AcademyDetail";
import AcademyHompage from "features/academy/component/AcademyHomePage";
import AcademyListCollectionDetail from "features/academy/component/AcademyListCollectionDetail";
import AcademySearchPage from "features/academy/component/AcademySearchPage";
import AcademyStoryDetail from "features/academy/component/AcademyStoryDetail";
import AcademyPage from "pages/AcademyPage";
import ContactMailPage from "pages/ContactMailPage";
import ProductCollectionPage from "pages/ProductCollectionPage";
import StoryCollectionPage from "pages/StoryCollectionPage";
import TagPage from "pages/TagPage";
import TagTypePage from "pages/TagTypePage";
import ShopCollectionPage from "pages/ShopCollectionPage";
import ShopMegaMenuPage from "pages/ShopMegaMenuPage";
import ShopSettingPage from "pages/ShopSettingPage";
import SettingPromotionsPage from "pages/SettingPromotionsPage";
import ShopPopupPage from "pages/ShopPopupPage";
import TagProductPage from "pages/TagProductPage";
import TagStoryCollectionPage from "pages/TagStoryCollectionPage";
import TagStoryPage from "pages/TagStoryPage";
import TagProductCollectionPage from "pages/TagProductCollectionPage";
import BrandPage from "pages/BrandPage";
import TagBrandPage from "pages/TagBrandPage";
import ERPSettingPage from "pages/ERPSettingPage";
import LanguageSettingPage from "pages/LanguageSettingPage";
import CommonSettingPage from "pages/CommonSettingPage";
import ShopFooterPage from "pages/ShopFooterPage";
import ShopPageSettingPage from "pages/ShopPageSettingPage";
import ShopEventPage from "pages/ShopEventPage";
import AllSettingPage from "pages/AllSettingPage";
import QuickSettingPromotion from "pages/QuickSettingPromotion";
import AdsCollectionPage from "pages/AdsCollectionPage";
import DealthomPage from "pages/DealthomPage";
import ShopBannerHomePage from "pages/ShopBannerHomePage";
import ShopBrandHomePage from "pages/ShopBrandHome";
import ShopAnotherPage from "pages/ShopAnotherPage";
import OrderSellerPage from "pages/OrderSellerPage";
import InventoryReceiptPage from "pages/InventoryReceiptPage";
import { useEffect } from "react";
import ProductInventoryCheckingUserPage from "pages/ProductInventoryCheckingUserPage";
import DocumentPage from "pages/DocumentPage";
//

const AppRoute = () => {
	const account = useLoginAccountStore((state) => state.account);
	const navigate = useNavigate();
	// cmt temp

	useEffect(() => {
		if (account.id <= 0) {
			navigate("/");
		}
	}, [account]);
	return (
		<>
			{account.id > 0 ? (
				<>
					<Header />
					<PageWrapper>
						<Routes>
							<Route path="/" element={<HomePage />} />
							<Route path="/employee/*" element={<EmployeePage />} />
							<Route path="/order/*" element={<OrderPage />} />
							<Route path="/orderseller/*" element={<OrderSellerPage />} />
							<Route path="/store/*" element={<StorePage />} />
							<Route path="/role/*" element={<RolePage />} />
							<Route path="/report/*" element={<ReportPage />} />
							<Route path="/office" element={<OfficePage />} />
							<Route path="/warehouse/*" element={<WarehousePage />} />
							<Route path="/customertype/*" element={<CustomerTypePage />} />
							<Route path="/supplier/*" element={<SupplierPage />} />
							<Route
								path="/productvariantcolor/*"
								element={<ProductVariantColorPage />}
							/>
							<Route
								path="/productvariantsize/*"
								element={<ProductVariantSizePage />}
							/>
							<Route path="/promotion/*" element={<PromotionPage />} />
							<Route path="/customer/*" element={<CustomerPage />} />
							<Route path="/customerpoint/*" element={<CustomerPointPage />} />
							<Route path="/department/*" element={<DepartmentPage />} />
							<Route path="/phonebook/*" element={<PhoneBookPage />} />
							<Route path="/product/*" element={<ProductPage />} />
							<Route
								path="/shippingcarrier/*"
								element={<ShippingCarrierPage />}
							/>
							<Route
								path="/productreceipt/*"
								element={<ProductReceiptPage />}
							/>
							<Route
								path="/inventoryreceipt/*"
								element={<InventoryReceiptPage />}
							/>
							<Route path="/purchaseorder/*" element={<PurchaseOrderPage />} />
							<Route
								path="/productcategory/*"
								element={<ProductCategoryPage />}
							/>
							<Route
								path="/productcollection/*"
								element={<ProductCollectionPage />}
							/>
							<Route path="/tagtype/*" element={<TagTypePage />} />
							<Route path="/tag/*" element={<TagPage />} />
							<Route path="/tagproduct/*" element={<TagProductPage />} />
							<Route path="/tagbrand/*" element={<TagBrandPage />} />
							<Route
								path="/tagproductcollection/*"
								element={<TagProductCollectionPage />}
							/>
							<Route
								path="/tagstorycollection/*"
								element={<TagStoryCollectionPage />}
							/>
							<Route path="/tagstory/*" element={<TagStoryPage />} />
							<Route path="/setting/*" element={<SettingPage />} />
							<Route
								path="/productinventorychecking/*"
								element={<ProductInventoryCheckingPage />}
							/>
								<Route
								path="/productinventorycheckinguser/*"
								element={<ProductInventoryCheckingUserPage />}
							/>
							<Route
								path="/cashflowreceipt/*"
								element={<CashflowReceiptPage />}
							/>
							<Route path="/taxinvoice/*" element={<TaxInvoicePage />} />
							<Route path="/pos/*" element={<PosPage />} />
							<Route path="/checkin/*" element={<CheckInPage />} />
							<Route path="/inventory/*" element={<InventoryPage />} />
							<Route path="/ecom/*" element={<EcomPage />} />
							<Route path="/ecomlog/*" element={<EcomLogPage />} />
							<Route
								path="/ecomchannelorder/*"
								element={<EcomChannelOrderPage />}
							/>
							<Route
								path="/ecomchannelproduct/*"
								element={<EcomChannelProductPage />}
							/>
							<Route path="/project/*" element={<ProjectPage />} />
							<Route path="/shippingorder/*" element={<ShippingOrderPage />} />
							<Route path="/shippingroute/*" element={<ShippingRoutePage />} />
							<Route path="/shipper/*" element={<ShipperPage />} />
							<Route path="/dmsnews/*" element={<DmsNewsPage />} />
							<Route
								path="/dmsnewscategory/*"
								element={<DmsNewsCategoryPage />}
							/>
							<Route path="/dmsbanner/*" element={<DmsBannerPage />} />
							<Route path="/dmspost/*" element={<DmsPostPage />} />
							<Route path="/shipperapply/*" element={<ShipperApplyPage />} />
							<Route path="/cmsnews/*" element={<CmsNewsPage />} />
							<Route
								path="/cmsnewscategory/*"
								element={<CmsNewsCategoryPage />}
							/>
							<Route path="/cmspost/*" element={<CmsPostPage />} />
							<Route path="/shippinghub/*" element={<ShippingHubPage />} />
							<Route path="/shippingtype/*" element={<ShippingTypePage />} />
							<Route
								path="/producttransmit/*"
								element={<ProductTransmitPage />}
							/>
							<Route path="/webhook/*" element={<WebHookPage />} />
							<Route path="/apikey/*" element={<ApiKeyPage />} />
							<Route path="/workschedule/*" element={<WorkSchedulePage />} />

							<Route
								path="/deliveryplanning/*"
								element={<DeliveryPlanningPage />}
							/>
							<Route
								path="/deliverydashboard/*"
								element={<DeliveryDashboardPage />}
							/>

							<Route path="/filemanager/*" element={<FilePage />} />
							<Route path="/company/*" element={<CompanyPage />} />
							<Route path="/profile/*" element={<ProfilePage />} />
							<Route path="/etable/view/*" element={<DataGridPage />} />
							<Route path="/workchat/*" element={<WorkChatPage />} />
							<Route path="*" element={<NotFoundPage />} />

							{/* new route nteam 5.6 */}
							<Route path="/leaveapprove/*" element={<LeaveApprovePage />} />
							<Route path="/leave/*" element={<LeavePage />} />
							<Route path="/tool/*" element={<ToolPage />} />
							<Route path="/itrequest/*" element={<ITRequestPage />} />
							<Route path="/notification/*" element={<NotificationPage />} />
							<Route path="/notification/*" element={<NotificationPage />} />
							<Route path="/documents/*" element={<DocumentPage />} />
							<Route path="/leavetype/*" element={<LeaveTypesPage />} />
							<Route path="/story/*" element={<StoryPage />} />
							<Route
								path="/storycollection/*"
								element={<StoryCollectionPage />}
							/>
							<Route
								path="/shopcollection/*"
								element={<ShopCollectionPage />}
							/>
							<Route path="/brand/*" element={<BrandPage />} />
							<Route path="/shoppopup/*" element={<ShopPopupPage />} />
							<Route path="/shopfooter/*" element={<ShopFooterPage />} />
							<Route
								path="/settingpromotions/*"
								element={<SettingPromotionsPage />}
							/>
							<Route path="/shopmegamenu/*" element={<ShopMegaMenuPage />} />
							<Route path="/shopbannerhome*" element={<ShopBannerHomePage />} />
							<Route path="/shopbrandhome*" element={<ShopBrandHomePage />} />

							<Route path="/shopbrandhome*" element={<ShopBrandHomePage />} />
							<Route path="/shopsetting/*" element={<ShopSettingPage />} />
							<Route
								path="/shopadscollection/*"
								element={<AdsCollectionPage />}
							/>
							<Route
								path="/shopadscollection/*"
								element={<AdsCollectionPage />}
							/>
							<Route path="/dealthomsetting/*" element={<DealthomPage />} />

							<Route path="/shopevent/*" element={<ShopEventPage />} />
							<Route path="/shopanother/*" element={<ShopAnotherPage />} />
							<Route path="/shopsettingall/*" element={<AllSettingPage />} />

							<Route path="/pagesetting/*" element={<ShopPageSettingPage />} />
							<Route path="/erpsetting/*" element={<ERPSettingPage />} />
							<Route
								path="/languagesetting/*"
								element={<LanguageSettingPage />}
							/>
							<Route path="/commonsetting/*" element={<CommonSettingPage />} />

							<Route path="/contactmail/*" element={<ContactMailPage />} />
							<Route path="/collection/*" element={<CollectionPage />} />
							<Route path="/delivery/*" element={<DeliveryPage />} />
							<Route path="/task/*" element={<TaskPage />} />
							<Route path="/contracttype" element={<ContractTypesPage />} />
							<Route path="/comment/*" element={<CommentPage />} />

							<Route path="/academy/*" element={<AcademyPage />}>
								<Route index element={<AcademyHompage />} />
								<Route path=":catid/*" element={<AcademyDetail />}>
									<Route index element={<AcademyListCollectionDetail />} />
									<Route path=":storeid/*" element={<AcademyStoryDetail />} />
								</Route>
								<Route path="search/*" element={<AcademySearchPage />} />
							</Route>

							{/* template route  */}
							<Route
								path="/template/*"
								element={<TemplatePage></TemplatePage>}></Route>
						</Routes>
					</PageWrapper>
				</>
			) : (
				<Routes>
					<Route path="/" element={<UserLoginPage />} />
					<Route path="/user/login" element={<UserLoginPage />} />
					<Route path="*" element={<NotFoundPage />} />
					<Route path="/user/resetpassword" element={<UserResetPassPage />} />
					<Route path="/user/forgotpass" element={<UserForgotPassPage />} />
				</Routes>
			)}
		</>
	);
};

export default AppRoute;
