import { Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { LinkAttribute } from "./extensions/HtmlEditorExtensionFullLink";

const { Option } = Select;

const HtmlEditorLinkModal = ({
  open,
  setOpen,
  onSubmit,
  attributes,
}: {
  open: boolean;
  setOpen: (open: boolean) => any;
  onSubmit: (attributes: LinkAttribute) => void;
  attributes: LinkAttribute;
}) => {
  const inputRef = useRef<any>(null);
  const { t } = useTranslation("htmleditor");

  const [form] = Form.useForm();
  const initialValues = {
    href: attributes.href,
    target: attributes.target,
    title: attributes.title,
    rel: attributes.rel,
  };

  const onSave = async (formData: any) => {
    const submitData: LinkAttribute = {
      href: formData.href,
      target: formData.target,
      title: formData.title,
      rel: formData.rel,
    };
    onSubmit(submitData);
    setOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        typeof inputRef !== "undefined" &&
          inputRef.current &&
          inputRef.current.focus();
      }, 10);
    }
  }, [open]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        href: attributes.href,
        title: attributes.title,
        target: attributes.target,
        rel: attributes.rel,
      });
    }
  }, [form, attributes]);

  return (
    <Modal
      title={"Thêm liên kết"}
      width={620}
      open={open}
      closable={true}
      maskClosable={true}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        form.submit();
      }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      forceRender
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onSave}
      >
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Form.Item
              label={t("link_modal.url")}
              name="href"
              rules={[
                {
                  required: true,
                  message: t("link_modal.error_url_required"),
                },
              ]}
            >
              <Input allowClear placeholder="https://..." ref={inputRef} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={8} xs={24}>
            <Form.Item label={t("link_modal.target")} name="target">
              <Select>
                <Option title="_self" value="_self">
                  {t("link_modal.target_self")}
                </Option>
                <Option title="_blank" value="_blank">
                  {t("link_modal.target_blank")}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={16} xs={24}>
            <Form.Item label={t("link_modal.rel")} name="rel">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Form.Item label={t("link_modal.title")} name="title">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default HtmlEditorLinkModal;
