import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import { Badge, Col, Row, Space, Tag } from "antd";
import useFilterLocation from "hooks/useFilterLocation";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  FilterTask,
  TaskMOMCollectionJson,
  TaskMOMJson,
} from "common/types/Task";
import { EmployeeJson } from "common/types/Employee";
import TaskRepository from "common/repositories/TaskRepository";

import PageHeader from "components/page/PageHeader";
import TaskRecent from "./TaskRecent";

type Props = {
  userInfor: EmployeeJson;
};
const TaskMOMList = ({ userInfor }: Props) => {
  const { t } = useTranslation();

  // state
  const [todayMOM, setTodayMOM] = useState<TaskMOMJson[]>([]);
  const [yesterdayMOM, setYesterdayMOM] = useState<TaskMOMJson[]>([]);
  const [lastDaysMOM, setLastDaysMOM] = useState<TaskMOMJson[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string[]>([]);

  // default filter
  const defaultFilters: FilterTask = useMemo(
    () => TaskRepository.getDefaultFilter(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterTask>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  // fetch Data
  const fetchData = useCallback(
    async (employee_id: number): Promise<TaskMOMCollectionJson> => {
      setLoading(true);
      const collection = await new TaskRepository().getItemsMOMEmployee({
        filters: {
          ...filters,
          page: 1,
          limit: 20,
          employee_id: employee_id,
        },
      });
      if (collection && collection.error.length <= 0) {
        setTodayMOM(collection.standup_today);
        setYesterdayMOM(collection.standup_yesterday);
        setLastDaysMOM(collection.standup_lastndays);
      } else {
        setError(collection.error);
      }
      setLoading(false);
      return collection;
    },
    [filters, userInfor]
  );

  // functions
  const genExtra = ({ type }: { type: "today" | "yesterday" }) => {
    const size: "default" | "small" | undefined = "default";
    const data = {
      todo: {
        count: {
          today: todayMOM.filter((i) => i.status === 0).length,
          yesterday: yesterdayMOM.filter((i) => i.status === 0).length,
        },
      },
      progress: {
        count: {
          today: todayMOM.filter((i) => i.status === 2).length,
          yesterday: yesterdayMOM.filter((i) => i.status === 2).length,
        },

        // 2
      },
      pending: {
        count: {
          today: todayMOM.filter((i) => i.status === 1).length,
          yesterday: yesterdayMOM.filter((i) => i.status === 1).length,
        },

        // 1
      },
      done: {
        count: {
          today: todayMOM.filter((i) => i.status === 3).length,
          yesterday: yesterdayMOM.filter((i) => i.status === 3).length,
        },

        // 3
      },
    };
    return (
      <Space direction="horizontal" style={{ columnGap: 16 }}>
        {/* todo */}
        <Badge
          className="site-badge-count-109"
          count={
            type === "today" ? data.todo.count.today : data.todo.count.yesterday
          }
          style={{
            backgroundColor: "#e6fffb",
            border: "1px solid #87e8de ",
            color: "#08979c",
          }}
          size={size}
        >
          {/* <FileSyncOutlined style={{ fontSize: 24 }} /> */}
        </Badge>
        {/* inprogress */}
        <Badge
          className="site-badge-count-109"
          count={
            type === "today"
              ? data.progress.count.today
              : data.progress.count.yesterday
          }
          style={{
            backgroundColor: "#fff0f6",
            color: "#c41d7f",
            border: "1px solid #ffadd2",
          }}
          size={size}
        ></Badge>
        {/* pending */}
        <Badge
          className="site-badge-count-109"
          count={
            type === "today"
              ? data.pending.count.today
              : data.pending.count.yesterday
          }
          style={{
            backgroundColor: "#e6f7ff",
            color: "#096dd9",
            border: "1px solid #91d5ff",
          }}
          size={size}
        ></Badge>
        {/* done */}
        <Badge
          className="site-badge-count-109"
          count={
            type === "today" ? data.done.count.today : data.done.count.yesterday
          }
          style={{
            backgroundColor: "#f6ffed",
            color: "#389e0d",
            border: "1px solid #b7eb8f",
          }}
          size={size}
        >
          {/* <FileDoneOutlined style={{ fontSize: 24 }} /> */}
        </Badge>
      </Space>
    );
  };

  const Deviation = ({ deviation }: { deviation: number }) => {
    const day = Math.floor(Math.abs(deviation / (3600 * 24)));
    const houre = Math.floor(Math.abs(deviation / 3600) % 24);
    const minutes = Math.round(
      Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
    );

    const is_deviation = (day || houre || minutes) !== 0;
    return (
      <>
        {is_deviation ? (
          <Tag
            color={deviation > 0 ? "volcano" : "green"}
            style={{ margin: 0 }}
          >
            {day > 0 && day + " ngày "}
            {houre > 0 && houre + " giờ "}
            {minutes > 0 && minutes + " phút"}
            {/* {minutes} */}{" "}
            {is_deviation ? (
              deviation > 0 ? (
                <IconArrowDown style={{ color: "#cf1322" }} />
              ) : (
                <IconArrowUp style={{ color: "#3f8600" }} />
              )
            ) : null}
          </Tag>
        ) : (
          <Tag style={{ margin: 0 }}>-</Tag>
        )}
      </>
    );
  };

  //useEffect
  useEffect(() => {
    fetchData(userInfor.id);
  }, [userInfor]);

  const col1 = useMemo(() => {
    return [
      {
        name: "Hôm qua",
        data: yesterdayMOM,
      },

      {
        name: "Những ngày trước",
        data: lastDaysMOM,
      },
    ];
  }, [userInfor, loading]);
  const col2 = useMemo(() => {
    return [
      {
        name: "Hôm nay",
        data: todayMOM,
      },
    ];
  }, [userInfor, loading]);

  return (
    <div id="taskMOM">
      <PageHeader
        heading={`Thông tin Task của ${userInfor.full_name}`}
        siteMenuOpenKey="task"
        siteMenuSelectedKey={"/task/department"}
      />

      <Row
        gutter={[8, 8]}
        style={{
          marginTop: "1rem",
          marginRight: "4px",
          paddingBottom: "0.5rem",
        }}
      >
        <Col span={12}>
          <Row gutter={[8, 8]}>
            {col1.map((i, index) => (
              <TaskRecent
                key={index}
                dataSource={i.data}
                name={i.name}
                loading={loading}
                defaultActiveKey={i.data.length > 0 ? "1" : undefined}
              />
            ))}
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[4, 4]}>
            {col2.map((i, index) => (
              <TaskRecent
                key={index}
                dataSource={i.data}
                name={i.name}
                loading={loading}
                defaultActiveKey={i.data.length > 0 ? "1" : undefined}
              />
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TaskMOMList;
