import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

import type { ProjectTaskCommentJson } from "common/types/ProjectTaskComment";
class ProjectTaskCommentModel
  extends BaseModel
  implements BaseModelJson<ProjectTaskCommentJson>
{
  company_id: number;
  creator_id: number;
  project_id: number;
  project_task_id: number;
  id: number;
  text: string;
  title: string;
  count_reply: number;
  files: FileModel[];
  parent_id: number;
  date_created?: number;
  date_modified?: number;
  constructor(json: ProjectTaskCommentJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.project_id = json.project_id || 0;
    this.project_task_id = json.project_task_id || 0;
    this.id = json.id || 0;
    this.text = json.text || "";
    this.title = json.title || "";
    this.count_reply = json.count_reply || 0;
    this.parent_id = json.parent_id || 0;
    this.files = [];
    if (typeof json.files !== "undefined" && Array.isArray(json.files)) {
      this.files = json.files.map((file) => new FileModel(file));
    }
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ProjectTaskCommentJson {
    return {
      company_id: 0,
      creator_id: 0,
      project_id: 0,
      project_task_id: 0,
      id: 0,
      text: "",
      title: " ",
      count_reply: 0,
      parent_id: 0,
      files: [],
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): ProjectTaskCommentJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      project_id: this.project_id,
      project_task_id: this.project_task_id,
      id: this.id,
      text: this.text,
      title: this.title,
      count_reply: this.count_reply,
      parent_id: this.parent_id,
      files: this.files,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default ProjectTaskCommentModel;
