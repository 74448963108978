// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_detailWrapper__t-8l1 {
}
.styles_detailBody__CXVo9 {
  display: flex;
}

.styles_nav__XaudU {
  flex: 1 1;
}
.styles_nav_mobile__nrR3B {
  display: none;
}
.styles_content__vLKfp {
  padding-top: 38px;
  width: 100%;
  max-width: 938px;
}

@media screen and (max-width: 768px) {
  .styles_detailBody__CXVo9 {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .styles_content__vLKfp {
    flex: 1 1;
    padding-top: 0;
  }
  .styles_nav__XaudU {
    flex: 0 1;
    display: none;
  }
  .styles_nav_mobile__nrR3B {
    display: unset;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyDetail/styles.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,aAAa;AACf;;AAEA;EACE,SAAO;AACT;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,SAAO;IACP,cAAc;EAChB;EACA;IACE,SAAO;IACP,aAAa;EACf;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":[".detailWrapper {\n}\n.detailBody {\n  display: flex;\n}\n\n.nav {\n  flex: 1;\n}\n.nav_mobile {\n  display: none;\n}\n.content {\n  padding-top: 38px;\n  width: 100%;\n  max-width: 938px;\n}\n\n@media screen and (max-width: 768px) {\n  .detailBody {\n    flex-direction: column;\n    margin-bottom: 24px;\n  }\n  .content {\n    flex: 1;\n    padding-top: 0;\n  }\n  .nav {\n    flex: 0;\n    display: none;\n  }\n  .nav_mobile {\n    display: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailWrapper": `styles_detailWrapper__t-8l1`,
	"detailBody": `styles_detailBody__CXVo9`,
	"nav": `styles_nav__XaudU`,
	"nav_mobile": `styles_nav_mobile__nrR3B`,
	"content": `styles_content__vLKfp`
};
export default ___CSS_LOADER_EXPORT___;
