import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";
import { IconAward } from "@tabler/icons-react";

const LeaveApprovalListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("leaveapprove:approval.heading_list")}
      siteMenuOpenKey="hrm"
    >
      {/* <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
          <RoleCheck roles={[]}>
            <PageHeaderButton
              link="/template"
              type="default"
              icon={<IconAward size={18} className=" mr-1 -mt-0.5" />}
            >
              {t("template:point_list_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <RoleCheck roles={[]}>
            <PageHeaderButton link="/template/add">
              {t("template:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row> */}
    </PageHeader>
  );
};

export default LeaveApprovalListHeader;
