import CmsPost from "common/constants/CmsPost";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

import type { CmsPostJson } from "common/types/CmsPost";
class CmsPostModel extends BaseModel implements BaseModelJson<CmsPostJson> {
  creator_id: number;
  company_id: number;
  id: number;
  type: number;
  file_id: number;
  image: FileModel;
  title: string;
  identifier: string;
  content: string;
  seo_slug: string;
  seo_title: string;
  seo_keyword: string;
  seo_description: string;
  graph_image_id: number;
  graph_image: FileModel;
  display_order: number;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: CmsPostJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.type = json.type || 0;
    this.file_id = json.file_id || 0;
    this.image = new FileModel(json.image || FileModel.getDefaultData());
    this.title = json.title || "";
    this.identifier = json.identifier || "";
    this.content = json.content || "";
    this.seo_slug = json.seo_slug || "";
    this.seo_title = json.seo_title || "";
    this.seo_keyword = json.seo_keyword || "";
    this.seo_description = json.seo_description || "";
    this.graph_image_id = json.graph_image_id || 0;
    this.graph_image = new FileModel(
      json.graph_image || FileModel.getDefaultData()
    );
    this.display_order = json.display_order || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): CmsPostJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      type: 0,
      file_id: 0,
      image: new FileModel(FileModel.getDefaultData()),
      title: " ",
      identifier: "",
      content: "",
      seo_slug: "",
      seo_title: " ",
      seo_keyword: "",
      seo_description: "",
      graph_image_id: 0,
      graph_image: new FileModel(FileModel.getDefaultData()),
      display_order: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): CmsPostJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      type: this.type,
      file_id: this.file_id,
      image: this.image,
      title: this.title,
      identifier: this.identifier,
      content: this.content,
      seo_slug: this.seo_slug,
      seo_title: this.seo_title,
      seo_keyword: this.seo_keyword,
      seo_description: this.seo_description,
      graph_image_id: this.graph_image_id,
      graph_image: this.graph_image,
      display_order: this.display_order,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: CmsPost.STATUS_ENABLED,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: CmsPost.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: CmsPost.TYPE_SINGLE_PAGE,
        label: i18n.t("Cmspost:TYPE_SINGLE_PAGE"),
      },
      {
        value: CmsPost.TYPE_EMBED_CONTENT,
        label: i18n.t("Cmspost:TYPE_EMBED_CONTENT"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }
}

export default CmsPostModel;
