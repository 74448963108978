import FullDataApi from "common/api/FullDataApi";
import FullDataCollection from "common/collections/FullDataCollection";
import BaseRepository from "./BaseRepository";
import type { FetchingRequest } from "common/types/FullData";

class FullDataRepository extends BaseRepository<FullDataApi> {
  _api: FullDataApi | null;

  constructor() {
    super();
    this._api = new FullDataApi(true);
  }

  async doFetching(props: FetchingRequest) {
    return this._api
      ? await this._api.doFetching(props)
      : new FullDataCollection();
  }
}

export default FullDataRepository;
