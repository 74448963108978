import Order from "common/constants/Order";
import Role from "common/constants/Role";
import { OrderJson } from "common/types/Order";
import { useMemo } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

export default function useActionsOrder({ order }: { order: OrderJson }) {
	const initStatus = order.status;
	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);

	//check can edit update input info (add/edit features)
	const allowEditInput = useMemo(() => {
		return (
			hasRoleAndLimit(Role.ORDER_EDIT, order.store_id) &&
			initStatus < Order.STATUS_COMPLETE &&
			order.type !== Order.TYPE_INVOICE &&
			order.type !== Order.TYPE_INVOICE_ORDER
		);
	}, [initStatus, order.store_id, hasRoleAndLimit, order.type]);

	//check allow set status to COMPLETE
	const allowStatusComplete = useMemo(() => {
		return (
			hasRoleAndLimit(Role.ORDER_STATUS_COMPLETE, order.store_id) &&
			initStatus < Order.STATUS_COMPLETE
		);
	}, [order.store_id, hasRoleAndLimit, initStatus]);

	//check allow set status to COMPLETE
	const allowStatusCompleteSelect = useMemo(() => {
		return (
			hasRoleAndLimit(Role.ORDER_STATUS_COMPLETE, order.store_id) &&
			initStatus <= Order.STATUS_COMPLETE
		);
	}, [order.store_id, hasRoleAndLimit, initStatus]);
	//check allow set status to CANCEL
	const allowStatusCancel = useMemo(() => {
		return (
			hasRoleAndLimit(Role.ORDER_STATUS_CANCEL, order.store_id) &&
			initStatus < Order.STATUS_CANCEL
		);
	}, [order.store_id, hasRoleAndLimit, initStatus]);

	const enableSubmitButton = useMemo(() => {
		return allowEditInput || allowStatusComplete || allowStatusCancel;
	}, [allowEditInput, allowStatusComplete, allowStatusCancel]);

	return {
		allowEditInput,
		allowStatusComplete,
		allowStatusCancel,
		enableSubmitButton,
		allowStatusCompleteSelect,
	};
}
