import DepartmentList from 'features/department/list/DepartmentList';
import usePathParams from 'hooks/usePathParams';
import React from 'react';

const DepartmentPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <DepartmentList />;
  }

  return com;
};

export default DepartmentPage;
