import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";
import { DetailProductCollectionItem } from "common/types/ProductCollection";

const defaultValue: DetailProductCollectionItem = {
	id: 0,
	creator_id: 0,
	collection_id: 0,
	product_id: 0,
	status: 0,
	displayorder: 0,
	date_created: 0,
	date_modified: 0,
};

class DetailProductCollectionModel
	extends BaseModel
	implements BaseModelJson<DetailProductCollectionItem>
{
	id: number = defaultValue.id;
	creator_id: number = defaultValue.id;
	collection_id: number = defaultValue.id;
	product_id: number = defaultValue.id;
	status: number = defaultValue.id;
	displayorder: number = defaultValue.id;
	date_created: number = defaultValue.id;
	date_modified: number = defaultValue.id;
	constructor(json: DetailProductCollectionItem) {
		super();
		Object.assign(this, { ...defaultValue, ...json });
	}

	static getDefaultData(): DetailProductCollectionItem {
		return { ...defaultValue };
	}

	toJson(): DetailProductCollectionItem {
		return JSON.parse(JSON.stringify({ ...this }));
	}
}

export default DetailProductCollectionModel;
