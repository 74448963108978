import {
  App,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import File from "common/constants/File";
import ProductModel from "common/models/ProductModel";
import ProductRepository from "common/repositories/ProductRepository";
import FileInput from "components/form/fileinput/FileInput";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import ProductCategoryFormSelect from "features/productcategory/ProductCategoryFormSelect";
import SupplierFormSelect from "features/supplier/SupplierFormSelect";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconPhoto, IconPlus, IconTag, IconX } from "@tabler/icons-react";
import TagModel from "common/models/TagModel";
import type { ProductJsonAddEdit2 } from "common/types/Product";
import { SelectOption } from "common/types/SelectOption";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import TagRepository from "common/repositories/TagRepository";
import TagConstant from "common/constants/Tag";
import TagDetailModel from "common/models/TagDetailModel";
import FileModel from "common/models/FileModel";
import MoneyInput from "components/MoneyInput";
import RateStars from "features/comment/components/RateStars";
import dayjs from "dayjs";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
const ProductForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  model: ProductModel;
  setOpen: (v: boolean) => void;
  setProcessing: (p: boolean) => void;
  onSaveSuccess: (o: ProductModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const formChangedProvider = useContext(FormChangedContext);

  //state
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>([]);

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      sku: model.sku,
      price: model.price,
      compare_at_price: model.compare_at_price,
      brand_id: model.brand ? model.brand.id : 0,
      allow_sale: model.id === 0 ? 1 : model.allow_sale,
      full_name: model.full_name,
      barcode: model.barcode,
      show_web: model.id === 0 ? 1 : model.show_web,
      allow_promotion: model.id === 0 ? true : model.allow_promotion,
      images:
        model.images.length > 0
          ? FileModel.convertToUploadFiles(
              model.images.map((item) => new FileModel(item))
            )
          : [],
      handle: model.handle,
      id: model.id,
      origin: model.origin,
      parent_id: model.parent_id,
      production_year: model.production_year,
      display_from:
        model.display_from > 0 ? dayjs(new Date(model.display_from * 1000)) : 0,
      display_to:
        model.display_to > 0 ? dayjs(new Date(model.display_to * 1000)) : 0,
      // quantity: model.quantity,
      // image_input: {
      // 	files: FileModel.convertToUploadFiles([model.image]),
      // 	file_id_list: model.image_id > 0 ? [model.image_id] : [],
      // category_id: model.category_id > 0 ? model.category_id : null,
      // supplier_id: model.supplier_id.length > 0 ? model.supplier_id : [],
      // unit: model.unit,
      // sell_on_zero: model.sell_on_zero,
      // },
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      console.log("🥺 ~ formData:", formData);
      const submitData: any = {
        // ...ProductModel.getDefaultData(),
        // category_id: formData.category_id,
        // supplier_id: formData.supplier_id,
        // unit: formData.unit,
        // sell_on_zero: formData.sell_on_zero,
        id: model.id,
        name: formData.name,
        sku: formData.sku,
        price: formData.price,
        barcode: formData.barcode,
        compare_at_price: formData.compare_at_price,
        full_name: formData.full_name,
        handle: formData.handle,
        origin: formData.origin,
        production_year: formData.production_year,
        // quantity:
        //   formData.quantity.length > 0 ? Number(formData.quantity.trim()) : 0,
        // quantity: model.quantity,
        show_web: formData.status,
        // images:
        //   formData.images &&
        //   formData.images.file_id_list &&
        //   formData.images.file_id_list.length > 0
        //     ? formData.images.file_id_list.toString()
        //     : "",
        images:
          formData.images && formData.images.length > 0
            ? formData.images
                .map((item: any) => item?.response?.id)
                .filter((tmp: any) => Number(tmp > 0))
                .toString()
            : "",
        allow_promotion: formData.allow_promotion === true ? 1 : 0,
        allow_sale: formData.allow_promotion === true ? 1 : 0,
        //
        brand_id: model.brand ? model.brand.id : 0,
        parent_id: model.parent_id,
        display_from:
          Helper.datePickerValueToTimestamp(formData.display_from) > 0
            ? Helper.datePickerValueToTimestamp(formData.display_from)
            : undefined,
        display_to:
          Helper.datePickerValueToTimestamp(formData.display_to) > 0
            ? Helper.datePickerValueToTimestamp(formData.display_to)
            : undefined,
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = useCallback(
    async (formData: any) => {
      setProcessing(true);
      message.loading({
        content: t("common:form.processing"),
        key: "message",
        duration: 0,
      });

      let item: ProductModel = await new ProductRepository().saveRemote(
        doPrepareData(formData)
      );

      setProcessing(false);
      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="product:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4,
        });
      } else {
        message.success({
          content: t("common:form.success.save"),
          className: "message_success",
          key: "message",
          duration: 2,
        });
        onSaveSuccess(item);
        setOpen(false);

        //success in form, reset form
        if (model.id === 0) {
          form.resetFields(["name", "code", "image_input"]);
        }
      }
    },
    [
      form,
      t,
      onSaveSuccess,
      setOpen,
      setProcessing,
      doPrepareData,
      model.id,
      message,
    ]
  );

  //submit tag detail

  const renderRate = (rate: number) => {
    const rateStars =
      rate === 1 ? (
        <RateStars stars={1} style={{ color: "#d72229", fontSize: "16px" }} />
      ) : rate === 2 ? (
        <RateStars stars={2} style={{ color: "#d72229", fontSize: "16px" }} />
      ) : rate === 3 ? (
        <RateStars stars={3} style={{ color: "#d72229", fontSize: "16px" }} />
      ) : rate === 4 ? (
        <RateStars stars={4} style={{ color: "#d72229", fontSize: "16px" }} />
      ) : rate === 5 ? (
        <RateStars stars={5} style={{ color: "#d72229", fontSize: "16px" }} />
      ) : (
        <Tag>{t("comment:model.empty")}</Tag>
      );
    return rateStars;
  };

  return (
    <>
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onSubmit}
        id={formChangedProvider.id}
        onFieldsChange={() => formChangedProvider.setChanged(true)}
      >
        <Row gutter={32}>
          <Col lg={18} xs={24} className="pt-2">
            <Form.Item
              label={t("product:name")}
              name="name"
              required
              rules={[
                {
                  required: true,
                  message: t("product:form.error.error_name_required"),
                },
              ]}
            >
              <Input autoFocus />
            </Form.Item>

            <Row gutter={16}>
              <Col lg={16} xs={24}>
                <Form.Item label={t("product:handle")} name="handle">
                  <Input placeholder={t("product:placeholder_URL")} />
                </Form.Item>
                {/* <ProductCategoryFormSelect
                  label={t("product:category_id")}
                  name="category_id"
                  localOnly={false}
                /> */}
              </Col>
              <Col lg={8} xs={24}>
                <Form.Item label={t("product:code")} name="sku">
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item label={t("product:full_name")} name="full_name">
                  <Input />
                </Form.Item>
              </Col>

              {/* <Col md={8} xs={24}>
                <Form.Item label={t("product:quantity")} name="quantity">
                  <Input />
                </Form.Item>
              </Col> */}
              <Col md={8} xs={24}>
                <Form.Item label={t("product:price")} name="price">
                  <MoneyInput className="w-full" addonAfter="VND" />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label={t("product:compare_at_price")}
                  name="compare_at_price"
                  rules={[
                    {
                      message: "Giá so sánh phải lớn hơn giá sản phẩm",
                      validator: (_: any, value: string) => {
                        if (
                          Number(value) === 0 ||
                          Number(value) > Number(form.getFieldValue("price"))
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      },
                    },
                  ]}
                >
                  <MoneyInput className="w-full" addonAfter="VND" />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item label={t("product:barcode")} name="barcode">
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item label={t("product:origin")} name="origin">
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label={t("storycollection:publish_from")}
                  name="display_from"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      message: t(
                        "storycollection:form.error.error_publish_from"
                      ),
                      validator: (_: any, value: any) => {
                        if (
                          Helper.datePickerValueToTimestamp(
                            form.getFieldValue("date_published")
                          ) >= Helper.datePickerValueToTimestamp(value) ||
                          !Helper.datePickerValueToTimestamp(value)
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={"HH:mm dd/mm/yyyy"}
                    format="HH:mm DD/MM/YYYY"
                    showTime
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label={t("storycollection:publish_to")}
                  name="display_to"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      message: t("leave:form.error.error_range_date"),
                      validator: (_: any, value: any) => {
                        if (
                          Helper.datePickerValueToTimestamp(
                            form.getFieldValue("publish_from")
                          ) < Helper.datePickerValueToTimestamp(value) ||
                          !Helper.datePickerValueToTimestamp(value)
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={"HH:mm dd/mm/yyyy"}
                    format="HH:mm DD/MM/YYYY"
                    showTime
                    disabledDate={(current) => {
                      return current.isBefore(
                        form.getFieldValue("publish_from")
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label={t("product:production_year")}
                  name="production_year"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={8} xs={24}>
                <Form.Item name="allow_promotion" valuePropName="checked">
                  <Checkbox>{t("product:allow_promotion")}</Checkbox>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item name="allow_sale" valuePropName="checked">
                  <Checkbox>{t("product:allow_sale")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {/* <SupplierFormSelect
              name="supplier_id"
              label={t("product:supplier")}
              multiple={true}
            /> */}

            {/* <Form.Item name="sell_on_zero" valuePropName="checked">
              <Checkbox>{t("product:sell_on_zero")}</Checkbox>
            </Form.Item> */}
          </Col>
          <Col lg={6} xs={24} className="pt-2 bg-gray-50">
            <FormSelect
              label={"Hiển thị"}
              name="show_web"
              options={ProductModel.getShowWebList()}
            />

            {/* <FormSelect
              label={t("product:unit_label")}
              name="unit"
              options={ProductModel.getUnitList()}
            /> */}
            <Form.Item label="Đánh giá">
              {renderRate(model.rate.rate)}
            </Form.Item>
            <Form.Item name="images" label={t("product:image")}>
              {/* <FileInput
                objectType={File.OBJECTTYPE_ATTACHMENT}
                origin="product"
              />
               */}
              <FileUploaderButton
                form={form}
                formName="images"
                label={t("story:image")}
                icon={<IconPhoto size="24" color="grey" />}
                origin="company"
                uploadRenderType="thumbnail"
                isSingleFile={false}
                initFiles={initialValues.images}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ProductForm;
