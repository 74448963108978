class TextUtil {
  static truncateFromMiddle(
    string: string,
    length: number,
    separator?: string
  ): string {
    if (string.length <= length) {
      return string;
    }

    const sep = separator || "...";

    const sepLen = sep.length;
    const charsToShow = length - sepLen;
    const charPartLength = Math.ceil(charsToShow / 2);

    return (
      string.substring(0, charPartLength) +
      sep +
      string.substring(string.length - charPartLength)
    );
  }
}

export default TextUtil;
