import { Button, Col, Form, Input, Popconfirm, Row, message } from "antd";
import axios from "axios";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import usePostActionStore from "zustands/usePostActionStore";
import AxiosClient from "common/api/AxiosClient";
import BaseApi from "common/api/BaseApi";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import { ActionCode } from "common/types/Delivery";
import useConvertBase64ToFile from "hooks/useConvertBase64ToFile";
import usePathParams from "hooks/usePathParams";
import useGeoLocation from "hooks/useGeoLocation";

type Props = {
  selectDeliveryArr: DeliveryModel[];
  action_code: ActionCode;
  GpsRequire?: boolean;
  UpLoadRequire?: boolean;
  noteRequire?: boolean;
  widthImg?: number | string;
  heightImg?: number | string;
  handleSuccess?: any;
  delivery_id?: number;
};

const ConfirmManyAction = ({
  action_code,
  GpsRequire = false,
  UpLoadRequire = false,
  noteRequire = false,
  widthImg = 1080,
  heightImg = 768,
  handleSuccess,
  selectDeliveryArr = [],
}: Props) => {
  // tes t
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { convertBast64ToFile } = useConvertBase64ToFile();
  const { location } = useGeoLocation();

  const [pathParams] = usePathParams();
  const { t } = useTranslation();
  const loginAccount = useLoginAccountStore((state) => state.account);
  const { setReload } = usePostActionStore();
  const [messageApi, contextHolder] = message.useMessage();

  ////////////
  // state
  const [imgFile, setImgFile] = React.useState<any>(null);
  const [img, setImg] = React.useState<string>("");
  const [enableWebcam, setEnableWebcam] = React.useState<any>(true);
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState<boolean>(false);
  ///////////
  //

  const jwtError = "error_jwt_hash_not_found";
  // TYPE_REQUIRE
  const GPS_REQUIRE: string = GpsRequire ? "GPS_REQUIRE" : "GPS_NO_REQUIRE";
  const UPLOAD_REQUIRE: string = UpLoadRequire
    ? "UPLOAD_REQUIRE"
    : "UPLOAD_NO_REQUIRE";

  // Function

  const handleSubmit = useCallback(
    async (value: any) => {
      setPending(true);

      if (UPLOAD_REQUIRE === "UPLOAD_REQUIRE" && imgFile === null) {
        messageApi.open({
          type: "error",
          content: "Bạn chưa chụp ảnh !",
          duration: 3,
        });
        // messageApi
        //   .open({
        //     type: "loading",
        //     content: "Action in progress..",
        //     duration: 2.5,
        //   })
        //   .then(() => message.success("Loading finished", 2.5))
        //   .then(() => message.info("Loading finished", 2.5));

        return;
      }

      let TYPE_ERROR =
        UpLoadRequire &&
        GpsRequire &&
        location.isError &&
        imgFile === (null || "")
          ? "ERROR_FULL"
          : GpsRequire && location.isError
          ? "NO_LOCATION"
          : UpLoadRequire && imgFile === null
          ? "NO_IMGFILE"
          : "NO_ERROR";

      if (TYPE_ERROR === "NO_ERROR") {
        post(imgFile).then(async (res: any) => {
          try {
            const response: DeliveryModel =
              await new DeliveryRepository().addAction({
                deliveries_id: selectDeliveryArr
                  .map((item) => item.id)
                  .join(","),
                action_code: action_code,
                image: UPLOAD_REQUIRE === "UPLOAD_REQUIRE" ? res.data.id : 0,
                location_latitude:
                  GPS_REQUIRE === "GPS_REQUIRE" ? location.coordinates.lat : 0,
                location_longitude:
                  GPS_REQUIRE === "GPS_REQUIRE" ? location.coordinates.lng : 0,
                note: value.note || "",
              });
            if (!response.hasError()) {
              setPending(false);
              openNotificationWithIcon(
                "success",
                t("delivery:button_action.notification.success.title"),
                t("delivery:button_action.notification.success.description")
              );
              setReload();
              setIsSuccess(true);
              handleSuccess !== undefined && handleSuccess();
            } else {
              setPending(false);
              openNotificationWithIcon(
                "error",
                t("delivery:button_action.notification.error.title"),
                response.error.errors[0] === jwtError
                  ? t("delivery:list.item.error.error_jwt_hash_not_found")
                  : t(
                      `delivery:button_action.notification.error.${response.error.errors[0]}`
                    )
              );
              setIsSuccess(false);
            }
          } catch (error: any) {
            setPending(false);
            openNotificationWithIcon(
              "error",
              t("delivery:button_action.notification.error.title"),
              t("delivery:button_action.notification.error.description")
            );
            setIsSuccess(false);
          }
        });
      } else {
        setPending(false);
        openNotificationWithIcon(
          "warning",
          "Có lỗi rồi !",
          t("delivery:button_action.notification.error.description_gps")
        );
        return;
      }
    },
    [imgFile, location]
  );

  const handleChangeImg = (imgBase64: string) => {
    setImg(imgBase64);
    const file = convertBast64ToFile(imgBase64, `image_${pathParams.id}.jpg`);
    setImgFile(file);
  };

  const post = async (file: any): Promise<any> => {
    setPending(true);
    if (UPLOAD_REQUIRE === "UPLOAD_NO_REQUIRE") {
      return new Promise((resolve, reject) => {
        resolve({
          id: 0,
          coordinates: {
            lat: 0,
            lng: 0,
          },
        });
      });
    } else {
      const formData = new FormData();
      formData.append("company_id", "10308");
      formData.append("creator_id", JSON.stringify(loginAccount.id));
      formData.append("directory_id", "0");
      formData.append("is_directory", "0");
      formData.append("status", "1");
      formData.append("object_type", "30");
      formData.append("object_id", "0");
      formData.append("origin", "image");
      formData.append("file", file);

      // const response = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.post(
            AxiosClient().defaults.baseURL + "/files",
            formData,
            {
              headers: {
                Authorization: BaseApi.loginAccount.jwt,
                // ContentType: "multipart/form-data",
              },
            }
          );
          if (res) {
            resolve(res);
          }
        } catch (error) {
          openNotificationWithIcon(
            "error",
            t("delivery:button_action.notification.error.title"),
            t("delivery:button_action.notification.error.description")
          );
          setPending(false);
          setIsSuccess(true);
          reject(error);
        }
      });
    }
  };

  const handleSubmitForm = () => {
    form.submit();
  };

  const handleChange = ({ filList }: any) => {};
  const openNotificationWithIcon = (
    type: "error" | "info" | "success" | "warning" | "loading",
    message: string = "",
    title: string = ""
  ) => {
    messageApi.open({
      type: type,
      content: t(`${title}`),
      duration: 10,
    });
  };

  ///////////////
  // useEffect

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        handleSuccess();
      }, 2000);
    } else {
    }
  }, [isSuccess]);

  return (
    <div className="my-1 h-full w-full ">
      {contextHolder}
      <Form
        form={form}
        name="PostAction"
        onSubmitCapture={handleSubmit}
        onFinish={handleSubmit}
      >
        <Row gutter={[8, 8]}>
          {enableWebcam && (
            <Col span={24}>
              <Form.Item
                name="note"
                rules={[
                  {
                    required: noteRequire ? true : false,
                    message: t("delivery:form.rule.note_require"),
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Note ..."></TextArea>
              </Form.Item>
            </Col>
          )}
        </Row>
        {enableWebcam && (
          <Row style={{ marginTop: 8 }} gutter={[8, 8]}>
            <Col span={24}>
              <Popconfirm
                placement="top"
                title={t(`delivery:action.confirm.${action_code}`)}
                onConfirm={() => handleSubmitForm()}
                okButtonProps={{
                  style: {
                    width: "100%",
                    height: "2rem",
                    display: "block",

                    marginLeft: 0,
                  },
                }}
                cancelButtonProps={{
                  style: {
                    width: "100%",
                    height: "2rem",
                    display: "block",
                    marginBottom: "8px",
                    marginLeft: 0,
                  },
                }}
              >
                <Button
                  loading={pending}
                  disabled={pending}
                  block
                  htmlType="button"
                >
                  Xác nhận
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default ConfirmManyAction;
