import { App, FormInstance, Skeleton } from "antd";
import Order from "common/constants/Order";
import PurchaseOrderDetailModel from "common/models/PurchaseOrderDetailModel";
import PurchaseOrderDetailRepository from "common/repositories/PurchaseOrderDetailRepository";
import { ProductVariantInMemory } from "common/types/ProductVariant";
import { PurchaseOrderDetailJson } from "common/types/PurchaseOrderDetail";
import ErrorAlert from "components/ErrorAlert";
import dbm from "dbm";
import ProductVariantSearch from "features/product/ProductVariantSearch";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import addToListFx from "sounds/pos/notify.mp3";
import useSound from "use-sound";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";
import useUiSettingStore from "zustands/useUiSettingStore";

import PurchaseOrderFormSectionDetailList from "./detail/PurchaseOrderFormSectionDetailList";
import PurchaseOrderFormSectionPriceSummary from "./PurchaseOrderFormSectionPriceSummary";

const PurchaseOrderFormSectionDetail = ({
  setDetails,
  form,
  allowEditInput
}: {
  setDetails: (v: PurchaseOrderDetailJson[]) => void;
  form: FormInstance;
  allowEditInput: boolean;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );
  const supplier = useEditingPurchaseOrderStore((state) => state.supplier);
  const enableSoundFx = useUiSettingStore((state) => state.enableSoundFx);
  const [soundFxAddToListSuccess] = useSound(addToListFx);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<PurchaseOrderDetailModel[]>([]);
  const [dataSourceEditable, setDataSourceEditable] = useState<
    PurchaseOrderDetailModel[]
  >([]);
  const [errors, setErrors] = useState<string[]>([]);

  const totalQuantity = useMemo(() => {
    let quantity = 0;
    dataSourceEditable.forEach((i) => {
      quantity += i.item_quantity;
    });
    return quantity;
  }, [dataSourceEditable]);

  const onChangeItem = useCallback(
    (item: PurchaseOrderDetailModel) => {
      setDataSourceEditable(
        dataSourceEditable.map((i) => (i.sku === item.sku ? item : i))
      );
    },
    [dataSourceEditable]
  );

  const onRemoveItem = useCallback(
    (item: PurchaseOrderDetailModel) => {
      setDataSourceEditable(
        dataSourceEditable.filter((i) => {
          if (item.id > 0) {
            return i.id !== item.id;
          } else {
            //new append on UI
            return i.sku !== item.sku;
          }
        })
      );
    },
    [dataSourceEditable]
  );

  const onSelectVariant = useCallback(
    async (product_variant_id: number) => {
      let insertPass = true;

      const foundIndex = dataSourceEditable.findIndex(
        (r) => r.product_variant_id === product_variant_id
      );
      let newItems: PurchaseOrderDetailModel[] = [];
      if (foundIndex >= 0) {
        //update current item
        newItems = update(dataSourceEditable, {
          [foundIndex]: {
            $set: new PurchaseOrderDetailModel({
              ...dataSourceEditable[foundIndex],
              item_quantity: dataSourceEditable[foundIndex].item_quantity + 1
            })
          }
        });
      } else {
        await (async () => {
          const foundItems = await dbm
            .getCollection("productvariant")
            .chain()
            .find({
              id: product_variant_id
            })
            .limit(1)
            .data();
          if (foundItems.length > 0) {
            const foundVariant: ProductVariantInMemory = foundItems[0];
            //append new item to list
            newItems = update(dataSourceEditable, {
              $push: [
                new PurchaseOrderDetailModel({
                  ...PurchaseOrderDetailModel.getDefaultData(),
                  purchase_order_id: purchaseOrder.id,
                  product_id: foundVariant.product_id,
                  product_variant_id: product_variant_id,
                  sku: foundVariant.sku,
                  item_name: foundVariant.product_name,
                  item_title: foundVariant.title,
                  item_size: foundVariant.size,
                  item_color: foundVariant.color,
                  item_quantity: 1,
                  item_unit_price: 0
                })
              ]
            });
          } else {
            insertPass = false;
            message.error(
              t("productvariant:id_not_found", { id: product_variant_id })
            );
          }
        })();
      }

      if (insertPass) {
        setDataSourceEditable(newItems);
        if (enableSoundFx) {
          soundFxAddToListSuccess();
        }
      }
    },
    [
      dataSourceEditable,
      setDataSourceEditable,
      enableSoundFx,
      soundFxAddToListSuccess,
      t,
      purchaseOrder.id,
      message
    ]
  );

  const fetchData = async (purchase_order_id: number) => {
    setLoading(true);
    setErrors([]);
    const collection =
      await new PurchaseOrderDetailRepository().getFullDetailOfPurchaseOrder(
        purchase_order_id
      );
    setLoading(false);
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setDataSource(collection.items);
      setDataSourceEditable(collection.items);
    }
  };

  //Check and fetch detail data
  useEffect(() => {
    if (purchaseOrder.id > 0) {
      fetchData(purchaseOrder.id);
    }
  }, [purchaseOrder.id]);

  useEffect(() => {
    setDetails(
      dataSourceEditable.map((i) => {
        return i.toJson();
      })
    );
  }, [setDetails, dataSourceEditable]);

  return (
    <div>
      <div className="mb-2">
        {loading ? null : (
          <div className="mb-4">
            {/* <ProductVariantSearch
              size="large"
              filterBySupplierId={supplier.id}
              disabled={
                purchaseOrder.status >= Order.STATUS_SHIPPING || !allowEditInput
              }
              onSelect={(product_variant_id) =>
                onSelectVariant(product_variant_id)
              }
            /> */}
          </div>
        )}
      </div>

      {loading ? (
        <Skeleton loading />
      ) : (
        <>
          {errors.length > 0 ? (
            <ErrorAlert
              items={errors}
              heading={t("common:error.error_fetching_data")}
              onRetry={() => {
                setErrors([]);
                fetchData(purchaseOrder.id);
              }}
            />
          ) : (
            <>
              <PurchaseOrderFormSectionDetailList
                totalQuantity={totalQuantity}
                readonlyItems={dataSource}
                items={dataSourceEditable}
                onChangeItem={onChangeItem}
                onRemoveItem={onRemoveItem}
                allowEditInput={allowEditInput}
              />
              <PurchaseOrderFormSectionPriceSummary
                items={dataSourceEditable}
                form={form}
                allowEditInput={allowEditInput}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PurchaseOrderFormSectionDetail;
