import { useEffect, useMemo, useState } from "react";
import { OrderJson } from "common/types/Order";
import { Button, Tag } from "antd";
import moment from "moment";
import AxiosClient from "common/api/AxiosClient";
import { LocationJson } from "components/LocationPicker";
import {
	ExclamationCircleOutlined,
	LoadingOutlined,
	PrinterOutlined,
} from "@ant-design/icons";
import usePrint from "hooks/usePrint";
import useBarcode from "hooks/useBarcode";

const KEY_TEMPLATE = "setting_template_print_bill_order";

const getLocation = async (code: number, type: number) => {
	try {
		const { data } = await AxiosClient().get<LocationJson>(
			`/locations/public/${code}?type=${type}`
		);
		return data;
	} catch (error) {
		console.log(error);
	}
};

export default function OrderPrintBill({ order }: { order: OrderJson }) {
	const [isLoadingAddress, setIsLoadingAddress] = useState(false);
	const { barcodeSrc } = useBarcode(order.code);

	const promotionsItem = order.details.data.flatMap((data) =>
		data.promotions.map((pro) => ({
			...pro,
			discount: data.price_discount,
		}))
	);
	const promotions = [...promotionsItem, ...order.promotions];

	const datastore = {
		website: "https://namperfume.net",
		phone: "19000129",
		address: "45-47 CMT8, Phường Bến Thành, Quận 1, Hồ Chí Minh, Vietnam",
		name: "namperfume",
		email: "hotro@namperfume.net",
		owner_name: "Namperfume",
	};
	const dataPrint = useMemo(() => {
		return {
			...order,
			shipping_fullname: order.shipping_fullname.length
				? order.shipping_fullname
				: "Khách vãng lai",
			date_created: moment.unix(order.date_created).format("DD/MM/YYYY HH:mm"),
			barcode_img: barcodeSrc,
			shipping_address_full: order.shipping_address,
			debt: order.debt > 0 ? order.debt : 0,
			promotions: promotions.map((promotion) => ({
				code: promotion.promotion_detail.campaign_info.name,
				discount: promotion.discount,
			})),
			store: datastore,
			details: {
				...order.details,
				data: order.details.data.map((pro) => ({
					...pro,
					price_sell: pro.item_unit_price * pro.item_quantity,
				})),
			},
		};
	}, [barcodeSrc, order, datastore]);
	// useEffect(() => {
	// 	getAddressFormCode(
	// 		{
	// 			ward: order.shipping_ward,
	// 			district: order.shipping_district,
	// 			province: order.shipping_province,
	// 			country: order.shipping_country,
	// 		},
	// 		order.shipping_address
	// 	);
	// }, [
	// 	order.shipping_country,
	// 	order.shipping_district,
	// 	order.shipping_province,
	// 	order.shipping_ward,
	// 	order.shipping_address,
	// ]);

	const { dataPrintHTML, isLoading, printBill } = usePrint({
		key: KEY_TEMPLATE,
		data: dataPrint,
	});

	// async function getAddressFormCode(
	// 	infoAddress: {
	// 		ward: number;
	// 		district: number;
	// 		province: number;
	// 		country: number;
	// 	},
	// 	address: string
	// ) {
	// 	const types = [110, 10, 1, 0];
	// 	setIsLoadingAddress(true);
	// 	const values = Object.values(infoAddress);
	// 	if (values.every((value) => value === 0)) {
	// 		setFullAddress("Trống");
	// 		setIsLoadingAddress(false);

	// 		return;
	// 	}
	// 	const datas = await Promise.all(
	// 		values.map((value, index) => getLocation(value, types[index]))
	// 	);
	// 	const stringAddress = datas.map((data) => data?.name || "_").join(" , ");
	// 	const fullAddress = `${address ? address + " , " : ""}${stringAddress}`;
	// 	setFullAddress(fullAddress);
	// 	setIsLoadingAddress(false);
	// }
	if (isLoading || !barcodeSrc.length || isLoadingAddress) {
		return (
			<div className=" flex justify-center">
				<p>Đang chuẩn bị dữ liệu...</p>
				<LoadingOutlined />
			</div>
		);
	}
	if (!order.shipping_address.length) {
		return (
			<div className=" flex justify-center">
				<Tag icon={<ExclamationCircleOutlined />} color="blue" className="p-1">
					Chưa có thông tin địa chỉ để in bill
				</Tag>
			</div>
		);
	}
	return (
		<Button
			className=""
			type="primary"
			onClick={() => {
				printBill(dataPrintHTML);
			}}
			icon={<PrinterOutlined />}>
			In
		</Button>
	);
}

const templates = [
	{
		key: "BILL",
		label: "In hóa đơn",
		value: `<meta charset="UTF-8">
    <style>
    @print  {
          size: A4; /* Set page size */
          margin: 10cm; /* Set margins */

          #bill {
            width: 58mm; /* Set the paper width */
            font-size: 12px;
            height:400px
          }
          .receipt {
            width: 100%;
            position: relative;
          }
        }
      p {
      margin: 0;
      }
      .table_detail {
      border-collapse: collapse;
      }
      .table_detail tbody tr:first-child {
      border-top: 2px solid #000 !important;
      }
    </style>
    <div id="bill" class="receipt" style="margin:0;font-family: Helvetica;font-size:12px;page-break-after:always;">
      <div>
      <div style="margin: 10px 0 12px 0;">
        <p style="margin-bottom: 1em;"><strong style="font-size: 18px;">{{ store.name }}</strong></p>

        <p><strong>Địa chỉ:</strong> {{ store.address }} </p>

        <p><strong>Điện thoại:</strong> {{ store.phone }} </p>

        <p><strong>Website:</strong> {{ store.website }} </p>
        <p><strong>Ngày bán:</strong> {{ date_created }} </p>
      </div>
      <div style="clear:both"></div>
      </div>
      <div style="width:100%;text-align:center;">
      <h3 style="font-size: 14px;line-height: 12px;text-transform: uppercase;text-align: center;margin: 0;">
        Hóa đơn: {{ code }}
      </h3>
      <img style="margin:auto;height: 68px;z-index: 1;position: relative;" class="print_barcode order" val="#9999" height="68px" src="{{ barcode_img }}">
        <div style="width: 100%;height: 28px;background: #ffffff;margin-top: -28px;z-index: 10;position: relative;"></div>
      </div>
      <div style="width:100%;">

      <div style="margin: 0 0 12px 0; white-space: normal;">
        <p><strong>KH:</strong> {{ shipping_fullname }}</p>

        {% if shipping_address_full %}
        <p><strong>Địa chỉ:</strong> {{ shipping_address_full }}</p>
        {% endif %}

        <p><strong>SĐT:</strong> {{ shipping_phone }}</p>

        {% if billing_email %}
        <p><strong>Email:</strong> {{ billing_email }}</p>
        {% endif %}
      </div>

      <table style="margin: 0 0 12px 0;font-size: 12px;width:100%;border-top: 2px solid #000;border-bottom: 1px dashed #333;" class="table_detail">
        <thead>
        <tr>
          <th style="width:60%;text-align: left;padding: 5px 0px;">Đgiá/Ggiảm</th>
          <th style="width:10%;text-align: center;padding: 5px 0px;">SL</th>
          <th style="width:30%;text-align: right;padding: 5px ;">T.Tiền</th>
        </tr>
        </thead>
        <tbody>

      {% for product in details.data %}
      <tr valign="top" style="border-top: 1px solid #d9d9d9;">
      <td colspan="3" style="padding: 5px 0px 0">
      <p style="margin-bottom: 5px;">{{ product.item_name }} {% if product.product_json.option_name  %} - 
        <span style="margin: 0">{{ product.product_json.option_name }}</span>
				{% endif %}
				 - <span>
        {{ product.product_json.sku }}</span>
      </p>
      </td>
    </tr>
    <tr valign="top">
      <td style="padding: 5px 0px;text-align:left;">
      {{ product.item_unit_price | currency }}
      </td>
      <td style="padding: 5px 0px;text-align:center;">{{ product.item_quantity }}</td>
      <td style="padding: 5px 0px;text-align:right;">{{ product.price_sell | currency }}</td>
    </tr>
      {% endfor %}

        </tbody>
      </table>
      <table style="font-size: 12px;width: 100%; margin: 0 0 12px 0;text-align: right;float: right;">

        <tbody><tr>
        <td colspan="2" style="width: 50%;padding: 5px 0px">Phí vận chuyển: {{ price_shipping | currency }}</td>
        </tr>

        {% for promotion in promotions %}
        <tr>
        <td colspan="2" style="padding: 5px 0px">Khuyến mãi "{{ promotion.code }}": {{ promotion.discount | currency }}</td>
        </tr>
        {% endfor %}

        <tr>
        <td colspan="2" style="padding: 5px 0px"><strong>Thành tiền: {{ price_final | currency }}</strong></td>
        </tr>
        <tr>
        <td colspan="2" style="width: 50%">Tiền phải thanh toán: <strong>{{ debt | currency }}</strong></td>
        </tr>
        <tr>
        <td colspan="2" style="width: 50%;padding: 5px 0px">Tiền đã thanh toán: <strong>{{ deposit | currency }}</strong></td>
        </tr>
      </tbody></table>
      {% if note %}

      <table style="font-size: 12px;width: 100%; margin: 0 0 12px 0;">
      <tr>
        <td>
        <strong>Ghi chú</strong>: {{ note }}
        </td>
      </tr>
      </table>
      {% endif %}

      </div>
      <div style="clear:both"></div>
      <p>Nếu bạn có thắc mắc, vui lòng liên hệ chúng tôi qua email {{ store.email }}  hoặc {{ store.phone }} </p>
    </div>
    `,
	},
	{
		key: "POS",
		label: "In POS",
		value: `
    <meta charset="UTF-8">
    <style>
    @print  {
          size: A4; /* Set page size */
          margin: 1cm; /* Set margins */
        }
      p {
      margin: 0;
      }
      .table_detail {
      border-collapse: collapse;
      }
      .table_detail tbody tr:first-child {
      border-top: 2px solid #000 !important;
      }
    </style>
    <div style="margin:0;font-family: Helvetica;font-size:12px;page-break-after:always;">
      <div>
      <div style="margin: 10px 0 12px 0;">

        <p><strong>Địa chỉ:</strong> 420/6 Lê Văn Sỹ, Phường 14, Quận 3,TP. Hồ Chí Minh</p>

        <p><strong>Điện thoại:</strong> 19000129</p>

        <p><strong>Website:</strong> https://namperfume.net</p>
        <p><strong>Ngày bán:</strong> {{ date_created }} </p>
      </div>
      <div style="clear:both"></div>
      </div>
      <div style="width:100%;text-align:center;">
      <h3 style="font-size: 14px;line-height: 12px;text-align: center;margin: 0;">
        Hóa đơn: {{ code }}
      </h3>

      </div>
      <div style="width:100%;">

      <div style="margin: 0 0 12px 0; white-space: normal;">
        <p><strong>KH:</strong> {{ shipping_fullname }}</p>

        {% if shipping_address_full %}
        <p><strong>Địa chỉ:</strong> {{ shipping_address_full }}</p>
        {% endif %}

        <p><strong>SĐT:</strong> {{ shipping_phone }}</p>
      </div>

      <table style="margin: 0 0 12px 0;font-size: 12px;width:100%;border-top: 2px solid #000;border-bottom: 1px dashed #333;" class="table_detail">
        <thead>
        <tr>
          <th style="width:60%;text-align: left;padding: 5px 0px;">Đgiá/Ggiảm</th>
          <th style="width:10%;text-align: center;padding: 5px 0px;">SL</th>
          <th style="width:30%;text-align: right;padding: 5px ;">T.Tiền</th>
        </tr>
        </thead>
        <tbody>

      {% for product in products %}
      <tr valign="top" style="border-top: 1px solid #d9d9d9;">
      <td colspan="3" style="padding: 5px 0px 0">
      <p style="margin-bottom: 5px;">{{ product.item_name }} -
        <span style="margin: 0">{{ product.product_json.option_name }}</span> - <span>
        {{ product.product_json.sku }}</span>
      </p>
      </td>
    </tr>
    <tr valign="top">
      <td style="padding: 5px 0px;text-align:left;">
      {{ product.item_unit_price }}
      </td>
      <td style="padding: 5px 0px;text-align:center;">{{ product.item_quantity }}</td>
      <td style="padding: 5px 0px;text-align:right;">{{ product.price_sell }}</td>
    </tr>
      {% endfor %}

        </tbody>
      </table>
      <table style="font-size: 12px;width: 50%; margin: 0 0 12px 0;text-align: right;float: right;">

        <tbody><tr>
        <td colspan="2" style="width: 50%;padding: 5px 0px">Phí vận chuyển: {{ price_shipping }}</td>
        </tr>

        {% for promotion in promotions %}
        <tr>
        <td colspan="2" style="padding: 5px 0px">Khuyến mãi "{{ promotion.code }}": {{ promotion.discount }}</td>
        </tr>
        {% endfor %}

        <tr>
        <td colspan="2" style="padding: 5px 0px"><strong>Thành tiền: {{ price_final }}</strong></td>
        </tr>
        <tr>
        <td colspan="2" style="width: 50%">Tiền phải thanh toán: <strong>{{ debt }}</strong></td>
        </tr>
        <tr>
        <td colspan="2" style="width: 50%;padding: 5px 0px">Tiền đã thanh toán: <strong>{{ deposit }}</strong></td>
        </tr>
      </tbody></table>
      {% if note %}

      <table style="font-size: 12px;width: 50%; margin: 0 0 12px 0">
      <tr>
        <td>
        <strong>Ghi chú</strong>: {{ note }}
        </td>
      </tr>
      </table>
      {% endif %}

      </div>

    </div>
    `,
	},
];
console.log("🚀 ~ templates:", templates);
