import eventEmitter from "common/utils/eventEmitter";
import Pusher from "pusher-js";
import React, { useEffect, useRef } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

// @ts-ignore
Pusher.Runtime.createXHR = function () {
  var xhr = new XMLHttpRequest();
  // xhr.withCredentials = true;
  return xhr;
};

const AppSocket = ({ children }: { children: React.ReactNode }) => {
  const pusherRef = useRef<Pusher | null>(null);
  const loginAccount = useLoginAccountStore((state) => state.account);

  const handleCompanyChannelMessageCompanyResourceUpdate = (data: any) => {
    eventEmitter.emit("COMPANYRESOURCE_UPDATE", {
      resource: data.table,
      version: data.version,
    });
  };

  useEffect(() => {
    if (
      loginAccount.id > 0 &&
      loginAccount.company.id > 0 &&
      pusherRef !== null &&
      pusherRef.current === null
    ) {
      // const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      //   cluster: process.env.REACT_APP_PUSHER_CLUSTER || "mt1",

      //   authEndpoint:
      //     process.env.REACT_APP_BASE_URL + "/usersessions/socketchannelauth",
      //   process.env.REACT_APP_BASE_URL + "/users/socketchannelauth",
      //   channelAuthorization: {
      //     endpoint: process.env.REACT_APP_BASE_URL + "/users/socketchannelauth",
      //     headers: { Authorization: loginAccount.jwt },
      //     transport: "jsonp",

      //   },
      // });

      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER || "mt1",
        authEndpoint:
          process.env.REACT_APP_BASE_URL + "/users/socketchannelauth",
        auth: {
          headers: {
            Authorization: loginAccount.jwt,
          },
        },
      });

      //write log to console
      // Pusher.logToConsole = true;

      //Create channel for company event
      const companyChannel = pusher.subscribe(
        "private-c" + loginAccount?.company?.id
      );
      companyChannel.bind(
        "event_fetchcompanyresource",
        handleCompanyChannelMessageCompanyResourceUpdate
      );

      pusherRef.current = pusher;
    } else if (loginAccount.id === 0 && pusherRef.current !== null) {
      console.log("Reset pusher and disconnected.");
      pusherRef.current.disconnect();
      pusherRef.current = null;
    }
  }, [loginAccount]);

  return <>{children}</>;
};

export default AppSocket;
