import { Col, Form, Row } from 'antd';
import { PosCartPayment } from 'common/types/PosCart';
import React, { useEffect, useState } from 'react';

import PosRightPaymentMethodInputMoney from './input/PosRightPaymentMethodInputMoney';
import PosRightPaymentMethodInputNote from './input/PosRightPaymentMethodInputNote';

const PosRightPaymentMethodCash = ({
  disabled,
  method,
  payValue,
  setEditingPayment,
  children,
}: {
  disabled: boolean;
  method: number;
  payValue: number;
  setEditingPayment: (v: PosCartPayment) => void;
  children?: React.ReactNode;
}) => {
  const [myPayValue, setMyPayValue] = useState(payValue);
  const [note, setNote] = useState("");

  useEffect(() => {
    setEditingPayment({
      _id: "",
      method: method,
      value: myPayValue,
      note,
    });
  }, [setEditingPayment, myPayValue, note, method]);

  return (
    <Form layout="vertical">
      <Row gutter={16}>
        {React.Children.count(children) > 0 ? (
          <Col span={10}>{children}</Col>
        ) : null}
        <Col span={6}>
          <PosRightPaymentMethodInputMoney
            myPayValue={myPayValue}
            setMyPayValue={setMyPayValue}
            payValue={payValue}
          />
        </Col>
        <Col span={React.Children.count(children) > 0 ? 8 : 18}>
          <PosRightPaymentMethodInputNote note={note} setNote={setNote} />
        </Col>
      </Row>
    </Form>
  );
};

export default PosRightPaymentMethodCash;
