import MainNavigationWrapper from "components/mainnavigation/MainNavigationWrapper";
import useBreakpoint from "hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import useMenuStore from "zustands/useMenuStore";

const DEFAULT_SIDEBAR_WIDTH = 320;

const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  const isRoutePos = useMatch("/pos/*") !== null;
  const [isSmall] = useBreakpoint();
  const [sidebarWidth, setSidebarWidth] = useState(DEFAULT_SIDEBAR_WIDTH);
  const [minimizeMenu] = useMenuStore((state) => [state.minimize]);
  const [showOnSmall] = useMenuStore((state) => [state.showOnSmall]);
  const [forceHidden] = useMenuStore((state) => [state.forceHidden]);

  useEffect(() => {
    if (minimizeMenu && !isSmall) {
      setSidebarWidth(80);
    } else {
      setSidebarWidth(DEFAULT_SIDEBAR_WIDTH);
    }
  }, [minimizeMenu, isSmall]);

  return (
    <div className="content-fixed">
      <div className="relative">
        {!isRoutePos ? (
          <div
            style={{ float: "left", width: sidebarWidth }}
            className={
              "fixed z-50" + (isSmall && !showOnSmall ? " invisible" : "")
            }
          >
            <MainNavigationWrapper />
          </div>
        ) : null}

        <div
          style={{
            marginLeft: isSmall || isRoutePos ? 0 : sidebarWidth,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
