import React from 'react';
import WorkChatMessage from './WorkChatMessBox';
import WorkChatUser from './WorkChatUser';
import items from './WorkChatData';

interface Button {
	icon: string;
	count: string;
  }
  
  interface Item {
	id: number;
	username: string;
	avatar: string
	realtime: string;
	text: string;
	button: {
	  icon: string;
	  count: string;
	}[];
  }
const WorkChat = () => {
  return (
    <div className="flex flex-col flex-grow">
		<div className="flex items-center flex-shrink-0 h-16 bg-white border-b border-gray-300 px-4">
			<div>
				<h1 className="text-sm font-bold leading-none">#council-of-elrond</h1>
				<span className="text-xs leading-none">Let's sort this ring thing out hey!?!</span>
			</div>
		</div>
		<div className="flex flex-col flex-grow overflow-auto">
			 {items.map((item: Item) => (
				<WorkChatUser
					key={item.id}
					username={item.username}
					avatar={item.avatar}
					realtime={item.realtime}
					text={item.text}
					button={item.button.map(({ icon, count }: Button) => ({
						icon: icon,
						count: count,
					}))}
				/>
				))}
		</div>
		
		<WorkChatMessage className="h-12 bg-white px-4 pb-4"/>
	</div>
  )
}

export default WorkChat
