// npm i -S debounce
//Based on https://tailwindcss.com/docs/responsive-design

import { useState, useEffect } from "react";
import { debounce } from "lodash";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export const SIZE_XS: Breakpoint = "xs";
export const SIZE_SM: Breakpoint = "sm";
export const SIZE_MD: Breakpoint = "md";
export const SIZE_LG: Breakpoint = "lg";
export const SIZE_XL: Breakpoint = "xl";
export const SIZE_2XL: Breakpoint = "2xl";

const resolveBreakpoint = (width: number): Breakpoint => {
  if (width < 640) {
    return SIZE_XS;
  } else if (width >= 640 && width < 768) {
    return SIZE_SM;
  } else if (width >= 768 && width < 1024) {
    return SIZE_MD;
  } else if (width >= 1024 && width < 1280) {
    return SIZE_LG;
  } else if (width >= 1280 && width < 1536) {
    return SIZE_XL;
  } else if (width >= 1536) {
    return SIZE_2XL;
  }

  return SIZE_2XL;
};

/** Get Media Query Breakpoints in React */
const useBreakpoint = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const [isSmall, setIsSmall] = useState(window.innerWidth < 640);

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
      setIsSmall(window.innerWidth < 640);
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return [isSmall, size] as const;
};

export default useBreakpoint;
