import BaseCollection from "common/collections/BaseCollection";
import { DeliveryCollectionJson, DeliveryJson } from "common/types/Delivery";
import DeliveryModel from "common/models/DeliveryModel";

class DeliveryCollection extends BaseCollection<
  DeliveryJson,
  DeliveryModel,
  DeliveryCollectionJson
> {
  itemsFromJson(jsonItems: DeliveryJson[]): DeliveryModel[] {
    return jsonItems.map((item) => new DeliveryModel(item));
  }
}

export default DeliveryCollection;
