import React, { useEffect, useState } from "react";
import usePathParams from "hooks/usePathParams";

import NotificationModel from "common/models/NotificationModel";
import NotificationList from "features/notification/list/NotificationList";
import NotificationForm from "features/notification/form/NotificationForm";
import FormEditFetcher from "components/form/FormEditFetcher";
import NotificationRepository from "common/repositories/NotificationRepository";
import NotificationFormHeader from "features/notification/form/NotificationFormHeader";

const NotificationPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new NotificationModel(NotificationModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new NotificationModel(NotificationModel.getDefaultData()));
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    case "add":
      com = <NotificationForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <NotificationForm model={model}></NotificationForm>
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new NotificationRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<NotificationFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    default:
      com = <NotificationList />;
      break;
  }
  return com;
};

export default NotificationPage;
