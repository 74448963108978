


import { message, Typography } from "antd";
import { Button } from "antd";
import { Col, Form, Input, Row } from "antd";
import { DataZoneBD,  ZoneDataSupport } from "common/types/Event";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";
import { useForm } from "antd/es/form/Form";

const SectionSupportDB = ({
	data,
	onSubmit
}: {
	data: ZoneDataSupport;
	onSubmit: (data: DataZoneBD) => void;

}) => {
	const { t } = useTranslation();
	const [imgMobile, setImgMobile] = useState(data.image_mobile);
	const [imgDesktop, setImgDesktop] = useState(data.image_desktop);

	const [form] = useForm()


	const init = data
	const onFinish = (values: any) => {
		const data  : any= {
			image_mobile :imgMobile ,
			image_desktop : imgDesktop
		}
		onSubmit(data)
	};


	return (

		<Form
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			form={form}
			name="dynamic_form_products"
			autoComplete="off"
			initialValues={{ items: init }}
			layout="vertical"
			onFinish={onFinish}
		>
		
		<Form.Item initialValue={data} name="zone_support">
					<Row gutter={16}>
						<Col span={24}>
							<Typography.Text>banner support desktop</Typography.Text>
							<UploadEvent
								className="flex gap-2"
								height={120}
								defaultUrl={data.image_desktop}
								onSuccess={(url) => {
									message.success("Thay đổi thành công");
									form.setFieldValue("image_desktop", url);
									setImgDesktop(url);
								}}></UploadEvent>
						</Col>
						<Col span={24}>
							<Typography.Text>banner support mobile</Typography.Text>
							<UploadEvent
								className="flex gap-2"
								height={120}
								defaultUrl={data.image_mobile}
								onSuccess={(url) => {
									message.success("Thay đổi thành công");
									console.log(url)
										form.setFieldValue("image_mobile", url);
										setImgMobile(url);
								}}></UploadEvent>
						</Col>
					</Row>
				</Form.Item>

			<Button className="  absolute bottom-10 right-4 w-[100px]" type="primary" htmlType="submit">
				Lưu
			</Button>
		</Form>
	);
};

export default SectionSupportDB;

