import { Skeleton } from "antd";
import WorkTrackingModel from "common/models/WorkTrackingModel";
import WorkTrackingReviewRepository from "common/repositories/WorkTrackingReviewRepository";
import { WorkTrackingRangeJson } from "common/types/WorkTrackingRange";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import TextUser from "components/TextUser";
import WorkTrackingApprovalForm from "features/worktrackingapproval/form/WorkTrackingApprovalForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WorkTrackingApprovalFormModal = ({
  id,
  open,
  setOpen,
  ranges,
  onSaveSuccess,
  officeId,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  ranges?: WorkTrackingRangeJson[];
  onSaveSuccess: (m: WorkTrackingModel) => void;
  officeId: number;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<WorkTrackingModel>(
    new WorkTrackingModel(WorkTrackingModel.getDefaultData()),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number, office_id: number) => {
    let myObj = await new WorkTrackingReviewRepository().getItem(id, office_id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id, officeId);
    }
  }, [id, errors, officeId]);

  return (
    <FormModal
      title={
        <>
          {t("worktracking:checkin_info_update_history")}{" "}
          {model.id > 0 && model.creator_id ? (
            <>
              {" - "}
              <TextUser id={model.creator_id} />
              {" ("}
              {<TextDateTimeRelative ts={model.date_created} />}
              {")"}
            </>
          ) : (
            ""
          )}
        </>
      }
      width={720}
      style={{ top: 20 }}
      confirmLoading={processing}
      open={open}
      setOpen={setOpen}
      formId="worktracking-approval-form"
      bodyStyle={{ paddingBottom: 0 }}
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id, officeId);
                  }}
                />
              ) : (
                <WorkTrackingApprovalForm
                  model={model}
                  ranges={ranges}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <WorkTrackingApprovalForm
          model={new WorkTrackingModel(WorkTrackingModel.getDefaultData())}
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default WorkTrackingApprovalFormModal;
