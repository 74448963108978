import React from "react";
import WorkChatMessThread from "./WorkChatThreadMessBox";
import WorkChatThreadUser from "./WorkChatThreadUser";
import items from "./WorkChatThreadData";
import { IconSquareLetterX } from "@tabler/icons-react";

interface Button {
  icon: string;
  count: string;
}

interface Item {
  id: number;
  username: string;
  avatar: string;
  realtime: string;
  text: string;
  button: {
    icon: string;
    count: string;
  }[];
}

const WorkChatThread = ({ onClick }: { onClick: () => void }) => {
  const handleThreadClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div
      className="flex flex-col flex-shrink-0 w-1/4 max-w-xs border-l border-gray-300 bg-gray-100"
      onClick={handleThreadClick}
    >
      <div className="flex items-center h-16 border-b border-gray-300 px-4">
        <div className="">
          <h2 className="text-sm font-semibold leading-none">Thread</h2>
          <a className="text-xs leading-none" href="#">
            council-of-elrond
          </a>
        </div>
        <button
          className="flex items-center justify-center rounded hover:bg-gray-300 ml-auto bg-transparent"
          onClick={onClick}
        >
          <IconSquareLetterX width={24} height={24} strokeWidth={1.25} />
        </button>
      </div>
      <div className="flex flex-col flex-grow overflow-auto">
        {items.map((item: Item) => (
          <WorkChatThreadUser
            key={item.id}
            username={item.username}
            avatar={item.avatar}
            realtime={item.realtime}
            text={item.text}
            button={item.button.map(({ icon, count }: Button) => ({
              icon: icon,
              count: count,
            }))}
          />
        ))}
        <WorkChatMessThread className="bg-gray-100 px-4 pb-3 mt-auto" />
      </div>
    </div>
  );
};

export default WorkChatThread;
