import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

import TaskModel from "common/models/TaskModel";
import TaskRepository from "common/repositories/TaskRepository";

import TaskList from "features/task/all/list/TaskList";
import TaskForm from "features/task/all/form/TaskForm";
import TaskDetails from "features/task/all/list/TaskDetails";
import FormEditFetcher from "components/form/FormEditFetcher";
import TaskMineList from "features/task/mine/list/TaskMineList";
import TaskKanbanList from "features/task/mine/kanban/list/TaskKanbanList";
import TaskDepartment from "features/task/department/list/TaskDepartmentList";
import TaskEditForm from "features/task/all/form/TaskEditForm";
import TaskFormHeader from "features/task/all/form/TaskFormHeader";
import TaskCurrentList from "features/task/current/list/TaskCurrentList";

const TaskPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(new TaskModel(TaskModel.getDefaultData()));
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new TaskModel(TaskModel.getDefaultData()));
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;
  switch (pathParams.action) {
    case "add":
      com = <TaskForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <TaskEditForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new TaskRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={errors}
            heading={<TaskFormHeader isEditing={true} />}
          />
        );

      break;
    case "edit_request":
      com =
        model.id > 0 ? (
          <TaskEditForm model={model} type="edit_request" />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new TaskRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={errors}
            heading={<TaskFormHeader isEditing={true} />}
          />
        );

      break;
    case "details":
      com = <TaskDetails type="all" />;
      break;
    case "mine":
      com = <TaskMineList />;
      break;
    // case "mine_add":
    //   com = <TaskMineForm model={model} />;
    //   break;
    // case "mine_edit":
    //   com =
    //     model.id > 0 ? (
    //       <TaskMineForm model={model}></TaskMineForm>
    //     ) : (
    //       <FormEditFetcher
    //         doLoad={async () => {
    //           const fetchModel = await new TaskRepository().getItem(
    //             Number(pathParams.id) || 0
    //           );
    //           if (!fetchModel.hasError()) {
    //             setModel(fetchModel);
    //           } else {
    //             setErrors(fetchModel.error.errors);
    //           }
    //         }}
    //         heading={<TaskMineFormHeader isEditing={true} />}
    //         errors={errors}
    //       />
    //     );
    //   break;
    case "kanban":
      com = <TaskKanbanList />;
      break;
    case "department":
      com = <TaskDepartment type="department" />;
      break;
    case "current":
      com = <TaskCurrentList />;
      break;
    // case "departmentkanban":
    //   com = <TaskDepartment type="kanban" />;
    //   break;
    default:
      com = <TaskList type="all" />;
      break;
  }
  return com;
};

export default TaskPage;
