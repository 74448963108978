import { Result, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

const FormEditFetcher = ({
  heading,
  errors,
  doLoad,
}: {
  heading: React.ReactNode;
  errors: string[];
  doLoad: () => void;
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading && errors.length === 0) {
      // Using an IIFE
      (async () => {
        setLoading(true);
        doLoad();
        setLoading(false);
      })();
    }
  }, [loading, errors, doLoad]);

  return (
    <>
      {heading}
      {errors.length > 0 ? (
        <Result status="warning" title={errors.join(".")} />
      ) : (
        <Skeleton active className="px-6 py-4" />
      )}
    </>
  );
};

export default FormEditFetcher;
