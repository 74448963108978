import React from "react";
import ProductCategoryList from "features/productcategory/list/ProductCategoryList";
import usePathParams from "hooks/usePathParams";

const ProductCategoryPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <ProductCategoryList />;
  }

  return com;
};

export default ProductCategoryPage;
