import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import { DateRange, FilterBiCenter } from "common/types/BiCenter";
import dayjs from "dayjs";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReportDateRangePicker from "../ReportDateRangePicker";
import ReportViewHeader from "../view/ReportViewHeader";
import ReportOrderProductAttributeColor from "./productattribute/ReportOrderProductAttributeColor";
import ReportOrderProductAttributeGeneral from "./productattribute/ReportOrderProductAttributeGeneral";
import ReportOrderProductAttributeMaterial from "./productattribute/ReportOrderProductAttributeMaterial";
import ReportOrderProductAttributeSize from "./productattribute/ReportOrderProductAttributeSize";
import ReportOrderProductAttributeStyle from "./productattribute/ReportOrderProductAttributeStyle";
import ReportOrderProductAttributeTitle from "./productattribute/ReportOrderProductAttributeTitle";
import ReportOrderPromotionGeneral from "./promotion/ReportOrderPromotionGeneral";
import ReportOrderPromotionProduct from "./promotion/ReportOrderPromotionProduct";
import ReportOrderPromotionProductCategory from "./promotion/ReportOrderPromotionProductCategory";
import ReportOrderPromotionProductOption from "./promotion/ReportOrderPromotionProductOption";
import ReportOrderGeneral from "./ReportOrderGeneral";
import ReportOrderProduct from "./ReportOrderProduct";
import ReportOrderProductCategory from "./ReportOrderProductCategory";
import ReportOrderProductOption from "./ReportOrderProductOption";

const ReportOrderWrapper = ({
  siteMenuOpenKey,
  siteMenuSelectedKey
}: {
  siteMenuOpenKey: string;
  siteMenuSelectedKey: string;
}) => {
  const { t } = useTranslation();

  const defaultFilters: FilterBiCenter = useMemo(
    () => BiCenterRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterBiCenter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const getPathNameParts = useMemo(() => {
    let pathNameParts = siteMenuSelectedKey.split("/");
    //discard first item (empty string)
    pathNameParts.shift();
    //discard controller item
    pathNameParts.shift();
    //discard action item
    pathNameParts.shift();
    return pathNameParts;
  }, [siteMenuSelectedKey]);

  const getNavByPathLocation = useMemo(() => {
    const nav = [
      {
        text: t("report:heading_view")
      },
      {
        text: t("report:heading_report_order")
      }
    ];
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          nav.push({
            text: t("report:heading_report_order_general")
          });
          break;
        case "productcategory":
          nav.push({
            text: t("report:heading_report_order_product_category")
          });
          break;
        case "product":
          nav.push({
            text: t("report:heading_report_order_product")
          });
          break;
        case "productoption":
          nav.push({
            text: t("report:heading_report_order_product_option")
          });
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      if (getPathNameParts[0] === "productattribute") {
        nav.push({
          text: t("report:heading_report_order_product_attribute")
        });
        switch (getPathNameParts[1]) {
          case "general":
            nav.push({
              text: t("report:heading_report_order_product_attribute_general")
            });
            break;
          case "title":
            nav.push({
              text: t("report:heading_report_order_product_attribute_title")
            });
            break;
          case "color":
            nav.push({
              text: t("report:heading_report_order_product_attribute_color")
            });
            break;
          case "size":
            nav.push({
              text: t("report:heading_report_order_product_attribute_size")
            });
            break;
          case "material":
            nav.push({
              text: t("report:heading_report_order_product_attribute_material")
            });
            break;
          case "style":
            nav.push({
              text: t("report:heading_report_order_product_attribute_style")
            });
            break;
        }
      }

      if (getPathNameParts[0] === "promotion") {
        nav.push({
          text: t("report:heading_report_order_promotion")
        });
        switch (getPathNameParts[1]) {
          case "general":
            nav.push({
              text: t("report:heading_report_order_promotion_general")
            });
            break;
          case "productcategory":
            nav.push({
              text: t("report:heading_report_order_promotion_product_category")
            });
            break;
          case "product":
            nav.push({
              text: t("report:heading_report_order_promotion_product")
            });
            break;
          case "productoption":
            nav.push({
              text: t("report:heading_report_order_promotion_product_option")
            });
            break;
        }
      }
    }

    return nav;
  }, [t, getPathNameParts]);

  const getHeadingByPathLocation = useMemo(() => {
    let heading = "";
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          heading = "Báo cáo theo chi tiết đơn hàng";
          break;
        case "productcategory":
          heading = "Báo cáo theo danh mục sản phẩm trong đơn hàng";
          break;
        case "product":
          heading = "Báo cáo theo sản phẩm trong đơn hàng";
          break;
        case "productoption":
          heading = "Báo cáo theo kiểu sản phẩm trong đơn hàng";
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      if (getPathNameParts[0] === "productattribute") {
        switch (getPathNameParts[1]) {
          case "general":
            heading = "Báo cáo theo thuộc tính sản phẩm trong đơn hàng";
            break;
          case "title":
            heading = "Báo cáo theo thuộc tính tiêu đề";
            break;
          case "color":
            heading = "Báo cáo theo thuộc tính màu sắc";
            break;
          case "size":
            heading = "Báo cáo theo thuộc tính kích cỡ";
            break;
          case "material":
            heading = "Báo cáo theo thuộc tính chất liệu";
            break;
          case "style":
            heading = "Báo cáo theo thuộc tính kiểu dáng";
            break;
        }
      }

      if (getPathNameParts[0] === "promotion") {
        switch (getPathNameParts[1]) {
          case "general":
            heading = "Báo cáo theo khuyến mãi & giảm giá sản phẩm";
            break;
          case "productcategory":
            heading = "Báo cáo giảm giá theo danh mục sản phẩm trong đơn hàng";
            break;
          case "product":
            heading = "Báo cáo giảm giá theo sản phẩm trong đơn hàng";
            break;
          case "productoption":
            heading = "Báo cáo giảm giá theo kiểu sản phẩm trong đơn hàng";
            break;
        }
      }
    }
    return heading;
  }, [getPathNameParts]);

  const onChangeDateRange = (
    value: DateRange,
    value_compare: DateRange | null
  ) => {
    setFilters({
      ...filters,
      start: BiCenterModel.momentToString(value[0]),
      end: BiCenterModel.momentToString(value[1]),
      ...(value_compare
        ? {
            startcompare: BiCenterModel.momentToString(value_compare[0]),
            endcompare: BiCenterModel.momentToString(value_compare[1])
          }
        : { startcompare: "", endcompare: "" })
    });
  };

  const getRange = useCallback((): DateRange => {
    if (filters.start.length > 0 && filters.end.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.start),
        BiCenterModel.stringToMoment(filters.end)
      ];
    } else {
      //default: last30days
      return [
        dayjs().subtract(30, "days").startOf("day"),
        dayjs().subtract(1, "days").endOf("day")
      ];
    }
  }, [filters.start, filters.end]);

  const getRangeCompare = useCallback((): DateRange | null => {
    if (filters.startcompare.length > 0 && filters.endcompare.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.startcompare),
        BiCenterModel.stringToMoment(filters.endcompare)
      ];
    } else {
      //user already set date start by MANUAL,
      //so, do not return default with compare (disable compare)
      if (filters.start.length > 0 && filters.end.length > 0) {
        return null;
      } else {
        //default: previousperiod of last30days
        return [
          dayjs().subtract(60, "days").startOf("day"),
          dayjs().subtract(31, "days").endOf("day")
        ];
      }
    }
  }, [filters.startcompare, filters.endcompare, filters.start, filters.end]);

  const getComponentByPathLocation = useMemo(() => {
    let com = null;
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          com = (
            <ReportOrderGeneral
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "productcategory":
          com = (
            <ReportOrderProductCategory
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "product":
          com = (
            <ReportOrderProduct
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "productoption":
          com = (
            <ReportOrderProductOption
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      if (getPathNameParts[0] === "productattribute") {
        switch (getPathNameParts[1]) {
          case "general":
            com = (
              <ReportOrderProductAttributeGeneral
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "title":
            com = (
              <ReportOrderProductAttributeTitle
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "color":
            com = (
              <ReportOrderProductAttributeColor
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "size":
            com = (
              <ReportOrderProductAttributeSize
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "material":
            // com = (
            //   <ReportOrderProductAttributeMaterial
            //     getRange={getRange}
            //     getRangeCompare={getRangeCompare}
            //   />
            // );
            break;
          case "style":
            // com = (
            //   <ReportOrderProductAttributeStyle
            //     getRange={getRange}
            //     getRangeCompare={getRangeCompare}
            //   />
            // );
            break;
        }
      }
      if (getPathNameParts[0] === "promotion") {
        switch (getPathNameParts[1]) {
          case "general":
            com = (
              <ReportOrderPromotionGeneral
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          // case "productcategory":
          //   com = (
          //     <ReportOrderPromotionProductCategory
          //       getRange={getRange}
          //       getRangeCompare={getRangeCompare}
          //     />
          //   );
          //   break;
          case "product":
            // com = (
            //   <ReportOrderPromotionProduct
            //     getRange={getRange}
            //     getRangeCompare={getRangeCompare}
            //   />
            // );
            break;
          case "productoption":
            com = (
              <ReportOrderPromotionProductOption
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
        }
      }
    }

    return com;
  }, [getPathNameParts, getRange, getRangeCompare]);

  return (
    <>
      <ReportViewHeader
        heading={getHeadingByPathLocation}
        nav={getNavByPathLocation}
        siteMenuOpenKey={siteMenuOpenKey}
        siteMenuSelectedKey={siteMenuSelectedKey}>
        <ReportDateRangePicker
          range={getRange()}
          range_compare={getRangeCompare()}
          onChange={onChangeDateRange}
          format="DD/MM/YYYY"
        />
      </ReportViewHeader>
      {getComponentByPathLocation}
    </>
  );
};

export default ReportOrderWrapper;
