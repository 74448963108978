const WorkTracking = {
  STATUS_PENDING: 1,
  STATUS_COMPLETED: 2,
  STATUS_VERIFIED: 3,
  STATUS_ERROR: 5,

  VERIFY_STATUS_UNVERIFIED: 1,
  VERIFY_STATUS_VERIFIED: 3,
  VERIFY_STATUS_IGNORE: 5,

  SOURCE_WEB: 1,
  SOURCE_APP: 3,
  SOURCE_API: 5,
  SOURCE_DEVICE: 7,

  ALLOW_OT_NOT: 0,
  ALLOW_OT_FIRST: 1,
  ALLOW_OT_LAST: 3,
  ALLOW_OT_ALL: 5,

  ALLOW_LATE_NOT_SET: 0,
  ALLOW_LATE_ALLOW: 1,
  ALLOW_LATE_NOT_ALLOW: 3,

  ALLOW_EARLY_NOT_SET: 0,
  ALLOW_EARLY_ALLOW: 1,
  ALLOW_EARLY_NOT_ALLOW: 3,

  LATE_NOT: 0,
  LATE_IN_RULE: 3,
  LATE_OUT_RULE: 5,

  EARLY_NOT: 0,
  EARLY_IN_RULE: 3,
  EARLY_OUT_RULE: 5,
};

export default WorkTracking;
