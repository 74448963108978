import {
	Button,
	Checkbox,
	Col,
	Collapse,
	ColorPicker,
	DatePicker,
	Form,
	Input,
	Row,
	Select,
	Tag,
	Typography,
} from "antd";
import FormSection from "components/form/FormSection";
import UploadEvent from "./UploadEvent";
import { AvailableType, ZoneDataProduct } from "common/types/Event";
import { FormInstance } from "antd/lib/form";
import TextDateTime from "components/TextDateTime";
import dayjs from "dayjs";
import DateFormFilter, { RangeValue } from "components/datetime/DateFormFilter";
import Helper from "common/utils/helper";
import { MinusCircleOutlined } from "@ant-design/icons";
export default function SectionProduct({
	data,
	form,
	index,
}: {
	data: ZoneDataProduct[];
	form: FormInstance<any>;
	index: number;
}) {
	const dateFormat = "HH:mm:ss DD/MM/YYYY";
	const { RangePicker } = DatePicker;
	const { Panel } = Collapse;
	const relIndex = Math.ceil(index / 2) - 1;
	const name = `zone_product_${relIndex}`;
	return (
		<div>
			<FormSection
				title={`Thông tin nhóm sản phẩm ${relIndex}`}
				subtitle={`Cài đặt thông tin ( hình ảnh, collection sản phẩm )`}
				leftColumnSize={6}
				divider>
				<Form.List name={name} initialValue={data}>
					{(fileds, opt) => (
						<Row gutter={[4, 4]}>
							{fileds.map((field, index) => {
								let d: AvailableType[] = [];

								const handleSetForKey = (
									key: keyof ZoneDataProduct,
									data: Partial<ZoneDataProduct>
								) => {
									const dataRel = form.getFieldValue(name) as ZoneDataProduct[];
									const dataSet: ZoneDataProduct = {
										available_type: [],
										collection_handle: "",
										collection_id: 0,
										collection_title: "none",
										image_desktop: "",
										image_mobile: "",
										link: "",
										show: 1,
										show_hide_price: 0,
										show_time: 0,
										style: {
											bg: "",
											button: "",
										},
									};

									const dataSave = { ...dataSet, data };
									let dataSaveFinal = { ...dataSet, ...dataRel[field.key] };
									form.setFieldValue(field.name, dataSaveFinal);
								};
								if (data[index]) {
									d = [...data[index].available_type].sort(
										(a, b) => b.from_time - a.from_time
									);
								}

								const handleSetValue = (
									dateFrom: number,
									dateTo: number,
									subKey: number
								) => {
									const key = field.key;

									let dataProduct: ZoneDataProduct[] = form.getFieldValue(name);
									if (dataProduct[key]) {
										let avData = dataProduct[key].available_type;
										avData[subKey].from_time = dateFrom;
										avData[subKey].to_time = dateTo;

										dataProduct[key] = {
											...dataProduct[key],
											["available_type"]: [...avData],
										};
										form.setFieldValue(name, dataProduct);
									} else {
										let dataAv: AvailableType[] = [];
										if (subKey > 0) {
											for (let index = 0; index < subKey; index++) {
												if (index === subKey) {
													dataAv.push({ from_time: dateFrom, to_time: dateTo });
												} else {
													dataAv.push({ from_time: 0, to_time: 0 });
												}
											}
										}
										handleSetForKey("available_type", {
											available_type: dataAv,
										});
									}
								};

								return (
									<Col span={24}>
										<Collapse defaultActiveKey={["-1"]}>
											<Panel
												header={
													<div className="flex justify-between w-full gap-1">
														<Typography.Text className="m-0">
															{" "}
															Chương trình {index + 1}{" "}
														</Typography.Text>
														<div>
															<span className="font-bold">
																<TextDateTime
																	format="HH:mm DD/MM/YYYY"
																	ts={d?.[0]?.from_time || 0}
																/>
															</span>{" "}
															-{" "}
															<span className="font-bold">
																<TextDateTime
																	format="HH:mm DD/MM/YYYY"
																	ts={d?.[d.length - 1]?.to_time || 0}
																/>
															</span>
														</div>
													</div>
												}
												key={index}>
												<Row gutter={[4, 4]}>
													<Col span={10}>
														<Form.Item
															className="mb-4"
															rules={[
																{
																	required: true,
																	message: "Yêu cầu nhập",
																},
															]}
															label="handle collection gọi api "
															initialValue={
																data?.[index]?.collection_handle || ""
															}
															name={[field.name, "collection_handle"]}>
															<Input size="middle"></Input>
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															className="mb-4"
															rules={[
																{
																	required: true,
																	message: "Yêu cầu nhập",
																},
															]}
															label="Ẩn & hiện chương trình"
															initialValue={data?.[index]?.show || true}
															name={[field.name, "show"]}>
															<Select
																defaultValue={data?.[index]?.show || true}
																onChange={(value) => {}}
																options={[
																	{ label: "Hiện", value: 1 },
																	{ label: "Ẩn", value: 0 },
																]}></Select>
														</Form.Item>
													</Col>
													<Col span={6}>
														<Form.Item
															className="mb-4"
															rules={[
																{
																	required: true,
																	message: "Yêu cầu nhập",
																},
															]}
															label="Ẩn & hiện thời gian"
															initialValue={data?.[index]?.show_time || false}
															name={[field.name, "show_time"]}>
															<Select
																defaultValue={data?.[index]?.show || false}
																onChange={(value) => {}}
																options={[
																	{ label: "Hiện", value: 1 },
																	{ label: "Ẩn", value: 0 },
																]}></Select>
														</Form.Item>
													</Col>
													<Col span={16}>
														<Form.Item
															className="mb-4"
															label="Link banner"
															initialValue={data?.[index]?.link || ""}
															name={[field.name, "link"]}>
															<Input size="middle"></Input>
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															className="mb-4"
															label="Ẩn & hiện ? giá"
															initialValue={data?.[index]?.show_hide_price || 0}
															name={[field.name, "show_hide_price"]}>
															<Select
																defaultValue={data?.[index]?.show || 0}
																onChange={(value) => {}}
																options={[
																	{ label: "Hiện", value: 1 },
																	{ label: "Ẩn", value: 0 },
																]}></Select>
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={[4, 4]}>
													<Col span={8}>
														<Form.Item
															className="mb-4"
															rules={[
																{
																	required: true,
																	message: "Yêu cầu nhập",
																},
															]}
															label="Style"
															initialValue={
																data?.[index]?.style || { bg: "", button: "" }
															}>
															<Tag>Background</Tag>
															<ColorPicker
																defaultValue={data?.[index]?.style?.bg || ""}
																onChange={(value, hex) => {
																	const key = field.key;
																	let data = form.getFieldValue(
																		name
																	) as ZoneDataProduct[];
																	if (data[key]) {
																		data[key] = {
																			...data[key],
																			style: {
																				...data[key].style,
																				bg: hex,
																			},
																		};
																		form.setFieldValue(name, data);
																	} else {
																		handleSetForKey("style", {
																			style: {
																				button: "",
																				bg: hex,
																			},
																		});
																	}
																}}
															/>
															<Tag>button</Tag>
															<ColorPicker
																defaultValue={
																	data?.[index]?.style?.button || ""
																}
																onChange={(value, hex) => {
																	const key = field.key;
																	let data = form.getFieldValue(
																		name
																	) as ZoneDataProduct[];
																	if (data[key]) {
																		data[key] = {
																			...data[key],
																			style: {
																				...data[key].style,
																				button: hex,
																			},
																		};
																		form.setFieldValue(name, data);
																		handleSetForKey("style", {
																			style: {
																				button: hex,
																				bg: "",
																			},
																		});
																	} else {
																		handleSetForKey("style", {
																			style: {
																				button: hex,
																				bg: "",
																			},
																		});
																	}
																}}
															/>
														</Form.Item>
													</Col>
												</Row>

												<Typography.Text className="mb-4 block">
													Các mốc thời gian chạy{" "}
												</Typography.Text>

												<Form.List
													initialValue={data?.[index]?.available_type || []}
													name={[field.name, "available_type"]}>
													{(subFields, subOption) => (
														<Row gutter={[8, 8]} className="mb-2">
															{subFields.map((subfield, subindex) => {
																return (
																	<Col
																		span={24}
																		key={subindex}
																		className="flex gap-2 justify-center items-center w-full">
																		<Form.Item
																			className="mb-2"
																			name={[subfield.name, "dates"]}
																			initialValue={[
																				dayjs(
																					data?.[index]?.available_type?.[
																						subindex
																					]?.from_time * 1000 || Date.now()
																				),
																				dayjs(
																					data?.[index]?.available_type?.[
																						subindex
																					]?.to_time * 1000 || Date.now()
																				),
																			]}
																			rules={[
																				{
																					type: "array" as const,
																					required: true,
																					message: "Yêu cầu nhập!",
																				},
																			]}>
																			<RangePicker
																				inputReadOnly
																				onChange={(
																					dates: RangeValue,
																					formats: [string, string]
																				) => {
																					let dateFrom =
																						data?.[index]?.available_type?.[
																							subindex
																						]?.from_time || 0;
																					let dateTo =
																						data?.[index]?.available_type?.[
																							subindex
																						]?.to_time || 0;

																					if (dates?.[0]) {
																						dateFrom =
																							Helper.datePickerValueToTimestamp(
																								dates[0]
																							);
																					}
																					if (dates?.[1]) {
																						dateTo =
																							Helper.datePickerValueToTimestamp(
																								dates[1]
																							);
																					}
																					handleSetValue(
																						dateFrom,
																						dateTo,
																						subfield.key
																					);
																				}}
																				format={dateFormat}
																				placeholder={["bắt đầu", "kết thúc"]}
																				allowClear={false}
																				defaultValue={[
																					dayjs(
																						data?.[index]?.available_type?.[
																							subindex
																						]?.from_time * 1000 || 0
																					),
																					dayjs(
																						data?.[index]?.available_type?.[
																							subindex
																						]?.to_time * 1000 || 0
																					),
																				]}
																				showTime={{
																					showNow: true,
																					format: "HH:mm:ss",
																				}}
																			/>
																		</Form.Item>
																		{subFields.length > 1 ? (
																			<MinusCircleOutlined
																				className="dynamic-delete-button"
																				onClick={() =>
																					subOption.remove(subfield.name)
																				}
																			/>
																		) : null}
																	</Col>
																);
															})}
															<Col span={24}>
																<Button
																	type="dashed"
																	onClick={() => {
																		subOption.add({
																			from_time: Math.round(Date.now() / 1000),
																			to_time: Math.round(Date.now() / 1000),
																		});
																	}}
																	block>
																	+ Thêm mốc thời gian
																</Button>
															</Col>
														</Row>
													)}
												</Form.List>
												<Row gutter={4}>
													<Col span={12}>
														<Typography.Text>banner desktop</Typography.Text>
														<UploadEvent
															height={100}
															width={100}
															defaultUrl={data?.[index]?.image_desktop || ""}
															onSuccess={(url) => {
																const key = field.key;
																let data = form.getFieldValue(name);
																if (data[key]) {
																	data[key] = {
																		...data[key],
																		["image_desktop"]: url,
																	};
																	form.setFieldValue(name, data);
																} else {
																	handleSetForKey("image_desktop", {
																		image_desktop: url,
																	});
																}
															}}></UploadEvent>
													</Col>

													<Col span={12}>
														<Typography.Text className="font-[600]">
															banner mobile
														</Typography.Text>
														<UploadEvent
															height={100}
															width={100}
															defaultUrl={data?.[index]?.image_mobile || ""}
															onSuccess={(url) => {
																const key = field.key;
																let data = form.getFieldValue(name);
																if (data[key]) {
																	data[key] = {
																		...data[key],
																		["image_mobile"]: url,
																	};
																	form.setFieldValue(name, data);
																} else {
																	handleSetForKey("image_mobile", {
																		image_mobile: url,
																	});
																}
															}}></UploadEvent>
													</Col>
												</Row>
											</Panel>
										</Collapse>
									</Col>
								);
							})}
							<Col span={24}>
								<Button
									onClick={() => {
										opt.add({
											available_type: [],
											collection_handle: "",
											collection_id: 0,
											collection_title: "none",
											image_desktop:
												"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37636-908931715918887-1715918887.jpg",
											image_mobile:
												"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37637-777071715918892-1715918892.jpg",
											link: "",
											show: 1,
											show_hide_price: 0,
											show_time: 0,
											style: {
												bg: "",
												button: "",
											},
										});
									}}>
									ADD
								</Button>
							</Col>
						</Row>
					)}
				</Form.List>
			</FormSection>
		</div>
	);
}
