import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import { GoongMapCoordinates } from "common/types/goongmap/GoongMapType";
import eventEmitter from "common/utils/eventEmitter";
import TextDateTime from "components/TextDateTime";
import DeliveryDashboardContext from "contexts/DeliveryDashboardContext";
import DeliveryDashboardGetMarkerImage from "features/deliverydashboard/DeliveryDashboardGetMarkerImage";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import DeliveryDashboardSectionRightGoongMapMarker from "./DeliveryDashboardSectionRightGoongMapMarker";

const DeliveryDashboardSectionRightMapMarkerOrder = ({
  index,
  order,
  setActivePopupInfo,
  setInfoWindow,
  setOffsetTop,
  setOffsetLeft,
}: {
  index: number;
  order: ShippingOrderModel;
  setActivePopupInfo: (v: GoongMapCoordinates) => void;
  setInfoWindow: (v: React.ReactNode) => void;
  setOffsetTop: (v: number) => void;
  setOffsetLeft: (v: number) => void;
}) => {
  const eventEmitterRef = useRef<any>();
  const deliveryDashboardProvider = useContext(DeliveryDashboardContext);

  const getDefaultGoongMapCoordinates = useMemo(() => {
    return ShippingRouteModel.getDefaultGoongMapCoordinates();
  }, []);

  const [activeRoute] = useDeliveryDashboardStore((state) => [
    state.activeRoute,
  ]);

  let findShippingTypeInfo = deliveryDashboardProvider.shippingTypeItems.find(
    (i) => i.id === order.shipping_type
  );

  const getCoordinates = useCallback(() => {
    let coordinates: GoongMapCoordinates = getDefaultGoongMapCoordinates;

    if (activeRoute && activeRoute.id > 0) {
      coordinates.latitude = order.order_shipping_lat;
      coordinates.longitude = order.order_shipping_long;
    }

    return coordinates;
  }, [activeRoute, getDefaultGoongMapCoordinates, order]);

  const renderInfoWindow = useMemo(() => {
    return (
      <>
        <div className="w-96">
          <div className="flex items-center">
            <div className="flex w-20 h-full">
              <DeliveryDashboardGetMarkerImage
                index={index + 1}
                status={order.status}
                width={40}
                height={40}
                className="rounded"
              />
            </div>
            <div className="flex ml-2">
              <div className="text-sm">
                <strong className="text-gray-900">
                  {order.order_shipping_address}
                </strong>
              </div>
            </div>
          </div>
          <div className="flex my-2">
            <div className="text-xs">
              <strong>{"Lượt giao hàng: "}</strong>
              <span>{order.route?.code}</span>
            </div>
          </div>
          <div className="flex text-xs border-t-2 border-black border-solid">
            <div className="mt-2">
              <div>
                <strong>{"Loại dịch vụ: "}</strong>
                <span>
                  {findShippingTypeInfo !== undefined
                    ? findShippingTypeInfo.name
                    : "---"}
                </span>
              </div>
              <div>
                <strong>{"Tên người nhận: "}</strong>
                <span>
                  {order.order_customer_fullname} - {order.order_customer_phone}
                </span>
              </div>
              <div>
                <strong> {"Ghi chú thông tin đơn hàng: "}</strong>
                <span>
                  {order.order_note !== "" ? order.order_note : "---"}
                </span>
              </div>
              <div>
                <strong>{"Ngày cập nhật: "}</strong>
                <span>
                  <TextDateTime
                    ts={order.date_modified}
                    format={"HH:mm, DD/MM/YYYY"}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }, [findShippingTypeInfo, index, order]);

  const onClickMarker = useCallback(
    (v: GoongMapCoordinates) => {
      setActivePopupInfo(v);
      setInfoWindow(renderInfoWindow);
      setOffsetTop(-20);
      setOffsetLeft(-25);
    },
    [
      setActivePopupInfo,
      setInfoWindow,
      renderInfoWindow,
      setOffsetTop,
      setOffsetLeft,
    ]
  );

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "DASHBOARD_GOONGMAP_GOTOMARKER",
        (_data: any) => {
          if (
            _data.latitude === order.order_shipping_lat &&
            _data.longitude === order.order_shipping_long
          ) {
            onClickMarker({
              latitude: _data.latitude,
              longitude: _data.longitude,
            });
          }
        }
      );
    }
  }, [onClickMarker, order]);

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);
  return (
    <>
      <DeliveryDashboardSectionRightGoongMapMarker
        item={getCoordinates()}
        img={
          <DeliveryDashboardGetMarkerImage
            index={index + 1}
            status={order.status}
            width={32}
            height={32}
            className="rounded"
          />
        }
        onClick={onClickMarker}
      />
    </>
  );
};

export default DeliveryDashboardSectionRightMapMarkerOrder;
