import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Row,
  Space,
  Spin,
  Steps,
  Timeline,
  message,
} from "antd";
import { Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { IconCircleCheck } from "@tabler/icons-react";

import LayoutForm from "components/form/LayoutForm";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Leave from "common/constants/Leave";
import DepartmentModel from "common/models/DepartmentModel";
import EmployeeModel from "common/models/EmployeeModel";
import LeaveModel from "common/models/LeaveModel";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import DepartmentRepository from "common/repositories/DepartmentRepository";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import LeaveEmployeeDetailHeader from "./LeaveEmployeeDetailHeader";
import TextDateTime from "components/TextDateTime";
import LeaveApprovalhistoryList from "features/leaveapprove/approval/list/LeaveApprovalhistoryList";
import { useNavigate } from "react-router-dom";
type Props = {
  leaveTypes: LeaveTypeModel[];
  model: LeaveModel;
  //   leaveHistory: LeaveHistoryJson[];
};
const LeaveEmployeeDetail = ({ leaveTypes, model }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  //
  const { Text, Title } = Typography;
  const { Step } = Steps;
  const { Panel } = Collapse;

  //state
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [type, setType] = useState("");
  const [employeeDetail, setEmployeeDetail] = React.useState<EmployeeModel>(
    new EmployeeModel(EmployeeModel.getDefaultData())
  );
  const [deparmentDetail, setDepartmentDetail] =
    React.useState<DepartmentModel>();

  // LeaveHistory

  /////////////////////////////////

  //////////////////////////////

  //leavelist

  //Fetch data from this collections
  const fetchEmployeeDetail = async (empoyee_id: number) => {
    setLoading(true);
    const item = await new EmployeeRepository().getItem(empoyee_id);
    setLoading(false);
    if (item.id > 0) {
      setEmployeeDetail(item);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }
  };

  const fetchDepartmentDetail = async (deparment_id: number) => {
    setLoading(true);
    const item = await new DepartmentRepository().getItem(deparment_id);
    setLoading(false);
    if (item.id > 0) {
      setDepartmentDetail(item);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }
  };

  // useEffect
  useEffect(() => {
    if (model.id > 0) {
      fetchEmployeeDetail(model.employee_id);
    }
  }, [model]);

  useEffect(() => {
    if (employeeDetail.id > 0) {
      fetchDepartmentDetail(employeeDetail.department_id);
    }
  }, [employeeDetail]);
  //////////////////////////////
  //Function

  const renderSwitchStepApprover = useCallback(() => {
    switch (model.status) {
      case 0:
        return t("leave:detail.col_1.second_1-0");
      case 1:
        return t("leave:detail.col_1.second_1-1");
      case 2:
        return t("leave:detail.col_1.second_1-2");
      case 3:
        return t("leave:detail.col_1.second_1-3");
      case 4:
        return t("leave:detail.col_1.second_1-4");
      default:
        break;
    }
  }, [Object.keys(model).length]);

  //////////////////////////////

  //submit data to server
  const onSubmit = async (formData: any) => {
    return;
  };

  //////////////////////////////
  // Custom T
  const sidebarItems = (
    <div className="flex justify-center">
      {/* <Title level={4}>{t("leave:detail.heading_history")}</Title> */}
      {/* <LeaveHistory leaveId={model.id}></LeaveHistory> */}
      <Col span={24}>
        <Form.Item>
          <Button
            type="default"
            size="large"
            block
            onClick={() => {
              navigate(-1);
            }}
          >
            Đóng
          </Button>
        </Form.Item>
      </Col>
    </div>
  );

  return (
    <>
      <LeaveEmployeeDetailHeader isEditing={true} />
      {/* <LeaveOfAbsenceHeader type={props.type}></LeaveOfAbsenceHeader> */}
      {loading && (
        <Space
          style={{
            position: "fixed",
            inset: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          align="center"
        >
          <Spin tip="Loading" size="large"></Spin>
        </Space>
      )}
      <LayoutForm
        onSubmit={onSubmit}
        form={form}
        errors={errors}
        isSuccess={isSuccess}
        error_translate_prefix="leave:detail.form.errors"
        sidebarItems={sidebarItems}
        hideSubmitButton={true}
      >
        <Space>
          <Row gutter={[16, 32]}>
            <Col xs={0} md={10} span={10}>
              <Title level={5}>{t("leave:detail.col_1.title_1")}</Title>
              <Text>{renderSwitchStepApprover()}</Text>
            </Col>
            <Col xs={24} md={14} span={14}>
              <div className="w-auto">
                <Steps
                  direction="horizontal"
                  status="process"
                  percent={60}
                  responsive={true}
                  current={
                    model.status === Leave.STATUS_APPROVED
                      ? 3
                      : model.status === Leave.STATUS_DENED
                      ? 4
                      : 1
                  }
                >
                  <Step title={t("leave:detail.col_2.step_1")} />
                  <Step
                    title={t("leave:detail.col_2.step_2")}
                    subTitle={
                      <div className="pt-3 sm:pl-2 pl-0  ">
                        {model.status === Leave.STATUS_PENDING ? (
                          <>
                            <Timeline>
                              <Timeline.Item
                                dot={
                                  <ClockCircleOutlined
                                    style={{ fontSize: 16 }}
                                  />
                                }
                              >
                                {t("leave:detail.col_2.pendding_3")}
                              </Timeline.Item>
                            </Timeline>
                            <Timeline>
                              <Timeline.Item
                                dot={
                                  <ClockCircleOutlined
                                    style={{ fontSize: 16 }}
                                  />
                                }
                              >
                                {t("leave:detail.col_2.pendding_4")}
                              </Timeline.Item>
                            </Timeline>
                          </>
                        ) : model.status === Leave.STATUS_APPROVED_1 ? (
                          <div>
                            <Timeline.Item
                              dot={
                                <IconCircleCheck size={20}></IconCircleCheck>
                              }
                              color="green"
                            >
                              {t(`leave:detail.col_2.approved_3`)}
                            </Timeline.Item>
                            <Timeline>
                              <Timeline.Item
                                dot={
                                  <ClockCircleOutlined
                                    style={{ fontSize: 16 }}
                                  />
                                }
                              >
                                {t("leave:detail.col_2.pendding_4")}
                              </Timeline.Item>
                            </Timeline>
                          </div>
                        ) : model.status === 4 ? (
                          <div>
                            <Timeline.Item
                              dot={
                                <IconCircleCheck size={20}></IconCircleCheck>
                              }
                              color="green"
                            >
                              {t(`leave:detail.col_2.approved_4`)}
                            </Timeline.Item>
                            <Timeline>
                              <Timeline.Item
                                dot={
                                  <ClockCircleOutlined
                                    style={{ fontSize: 16 }}
                                  />
                                }
                              >
                                {t("leave:detail.col_2.pendding_3")}
                              </Timeline.Item>
                            </Timeline>
                          </div>
                        ) : null}
                      </div>
                    }
                  />
                  {model.status === Leave.STATUS_APPROVED_1 && (
                    <Step
                      status="finish"
                      description={t("leave:detail.leave_approved")}
                      title={t("leave:detail.col_2.approved")}
                    />
                  )}
                  {model.status === Leave.STATUS_DENED && (
                    <Step
                      status="error"
                      description={t("leave:detail.leave_dened")}
                      title={t("leave:detail.col_2.dened")}
                    />
                  )}
                </Steps>
              </div>
            </Col>
            <Col xs={0} md={10} span={10}>
              <Title level={5}>{t("leave:detail.col_1.title_2")}</Title>
              <Text>{t("leave:detail.col_1.second_2")}</Text>
            </Col>
            <Col
              xs={24}
              md={14}
              span={14}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
            >
              <Row>
                <Col xs={24} sm={16} md={14} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("common:columns.full_name")}:
                  </div>
                  <Text
                    style={{
                      margin: "0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {employeeDetail ? employeeDetail.full_name : ""}
                  </Text>
                </Col>
                <Col xs={24} sm={8} md={10} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    ID:
                  </div>

                  <Text
                    style={{
                      margin: "0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {employeeDetail ? employeeDetail.internal_id : "invalid"}
                  </Text>
                </Col>
                <Divider style={{ margin: "4px 0 0 0" }}></Divider>
              </Row>
              <Row>
                <Col xs={24} sm={16} md={14} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("common:columns.department")}:
                  </div>
                  <Text
                    style={{
                      margin: "0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {deparmentDetail?.name}
                  </Text>
                </Col>
                <Col xs={24} sm={8} md={10} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("common:columns.job_title")}:
                  </div>

                  <Text
                    style={{
                      margin: "0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {employeeDetail?.job_title}
                  </Text>
                </Col>
                <Divider style={{ margin: "4px 0 0 0" }}></Divider>
              </Row>
              <Row>
                <Col xs={24} sm={16} md={14} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("leave:detail.col_2.date_from")}:
                  </div>

                  <Text
                    style={{
                      margin: "0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    <TextDateTime
                      ts={model.date_from}
                      format="DD/MM/YYYY"
                    ></TextDateTime>
                  </Text>
                </Col>
                <Col xs={24} sm={8} md={10} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("leave:detail.col_2.date_to")}:
                  </div>

                  <Text
                    // type="secondary"
                    style={{
                      margin: "0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    <TextDateTime
                      ts={model.date_to}
                      format="DD/MM/YYYY"
                    ></TextDateTime>
                  </Text>
                </Col>
                <Divider style={{ margin: "4px 0 0 0" }}></Divider>
              </Row>
              <Row>
                <Col>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("leave:list.day_off")}:
                  </div>

                  <Text
                    style={{
                      margin: "0 1rem",
                    }}
                  >
                    <Text
                      style={{
                        margin: " 0 0.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      {model && model.dayoff}
                    </Text>
                    {t("leave:day").toLowerCase()}
                  </Text>
                </Col>
                <Divider style={{ margin: "4px 0 0 0" }}></Divider>
              </Row>
              <Row>
                <Col xs={24} sm={16} md={14} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("leave:leave_type")}:
                  </div>

                  <Text
                    style={{
                      margin: " 0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {model &&
                      leaveTypes.find((type) => type.id === model.leavetypes_id)
                        ?.name}
                  </Text>
                </Col>
                <Col xs={24} sm={8} md={10} lg={12}>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("leave:salary")} :
                  </div>

                  <Text
                    style={{
                      margin: " 0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {t(
                      `leave:salary_type.${
                        leaveTypes.find(
                          (item) => item.id === model.leavetypes_id
                        )?.salary_type || ""
                      }`
                    )}
                  </Text>
                </Col>
                <Divider style={{ margin: "4px 0 0 0" }}></Divider>
              </Row>
              <Row>
                <Col>
                  <div style={{ minWidth: 90, display: "inline-block" }}>
                    {t("leave:reason")}:{" "}
                  </div>

                  <Text
                    style={{
                      margin: " 0 1rem",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {model && model.note}
                  </Text>
                </Col>
                <Divider style={{ margin: "4px 0 0 0" }}></Divider>
              </Row>
            </Col>

            <Col span={24} style={{ paddingTop: 12 }}>
              <Row gutter={16}>
                <Col xs={0} sm={10}>
                  <Title level={5}>{t("leave:detail.col_1.title_3")}</Title>
                  <Text>{t("leave:detail.col_1.second_3")}</Text>
                </Col>
                <Col xs={24} sm={14}>
                  <LeaveApprovalhistoryList leave_id={model.id} />
                  {/* <Title level={5}>
                    {leaveHistory.length > 0 && model.status === 0
                      ? t("leave:detail.col_2.title_area_dened")
                      : t("leave:detail.col_2.title_area")}
                  </Title>

                  {leaveHistory.length > 0 &&
                    leaveHistory
                      .filter((item) => item.note !== (null || ""))
                      .map((i) => {
                        return (
                          <Form.Item
                            name={`note`}
                            label={
                              i.type === 0
                                ? t(
                                    `leave:detail.form.errors.error_note_required_${i.status}`
                                  )
                                : t(
                                    `leave:detail.form.errors.error_note_required_success_${i.status}`
                                  )
                            }
                          >
                            <TextArea
                              // key={!noteByApproved ? "notLoadedYet" : "loaded"}
                              name="note"
                              rows={4}
                              defaultValue={i.note}
                              className="w-full"
                              // defaultValue={"sdfdsf"}
                              disabled={true}
                              placeholder={
                                i.note !== null
                                  ? t(
                                      `leave:detail.form.errors.error_note_required_${i}`
                                    )
                                  : t("leave:empty")
                              }
                            />
                          </Form.Item>
                        );
                      })} */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      </LayoutForm>
    </>
  );
};

export default LeaveEmployeeDetail;
