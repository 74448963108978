import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import ProfileEditor from "features/profile/editor/ProfileEditor";
import usePathParams from "hooks/usePathParams";
import React, { useState } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const ProfilePage = () => {
	const [pathParams] = usePathParams();
	const account = useLoginAccountStore((state) => state.account);
	//employee model
	const [model, setModel] = useState<EmployeeModel>(
		new EmployeeModel(EmployeeModel.getDefaultData())
	);
	const [fetchErrors, setFetchErrors] = useState<string[]>([]);

	let com = null;
	switch (pathParams.action) {
		default:
			com =
				model.id > 0 ? (
					<ProfileEditor model={model} />
				) : (
					<FormEditFetcher
						doLoad={async () => {
							let fetchedModel =
								await new EmployeeRepository().getItemFullDetail2(
									account.company.employee.id
								);
							if (fetchedModel.hasError()) {
								setFetchErrors(fetchedModel.error.errors);
							} else {
								setModel(fetchedModel);
							}
						}}
						errors={fetchErrors}
						heading=""
					/>
				);
	}

	return com;
};

export default ProfilePage;
