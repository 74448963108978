const Cashflow = {
	STATUS_DRAFT: 1,
	STATUS_WAIT_FOR_APPROVAL: 2,
	STATUS_APPROVED: 3,
	STATUS_COMPLETED: 9,
	STATUS_CANCELLED: 11,
	// STATUS_NEW : 12,

	DIRECTION_INCOME: 5,
	DIRECTION_EXPENSE: 10,

	SOURCE_SALE_ORDER: 1,
	SOURCE_PURCHASE_ORDER: 5,
	SOURCE_PURCHASE_ORDER_RETURN : 7,
	SOURCE_SALE_ORDER_RETURN  : 1,
	SOURCE_OTHER : 9,

	TARGET_EMPLOYEE: 1,
	TARGET_CUSTOMER: 3,
	TARGET_SUPPLIER: 5,
	TARGET_OTHER: 7,
	// TARGET_CASHFLOW_RECEIPT : 7,

	
	METHOD_CASH: 1,
	METHOD_BANK: 3,
	METHOD_GIFTCARD: 5,
	METHOD_CUSTOMER_POINT: 7,
	METHOD_COD: 11,
	METHOD_MOMO: 13,
	METHOD_VNPAY: 15,
	METHOD_MEMBERCARD: 17,
	METHOD_VOUCHER : 9999,

	GROUP_IN_SALE: 101,
	GROUP_IN_OTHER: 199,
	GROUP_OUT_PURCHASE: 201,
	GROUP_OUT_RETURN: 202,
	GROUP_OUT_OTHER: 299,
};



export const LIST_GROUP = {
    [Cashflow.DIRECTION_INCOME]: [
        Cashflow.GROUP_IN_SALE,
        Cashflow.GROUP_IN_OTHER
    ],
    [Cashflow.DIRECTION_EXPENSE]: [
        Cashflow.GROUP_OUT_PURCHASE,
        Cashflow.GROUP_OUT_RETURN,
        Cashflow.GROUP_OUT_OTHER
    ]
};

export const LIST_SOURCE = {
    [Cashflow.DIRECTION_INCOME]: [
        Cashflow.SOURCE_SALE_ORDER,
        Cashflow.SOURCE_PURCHASE_ORDER_RETURN,
        Cashflow.SOURCE_OTHER
    ],
    [Cashflow.DIRECTION_EXPENSE]: [
        Cashflow.SOURCE_SALE_ORDER_RETURN,
        Cashflow.SOURCE_PURCHASE_ORDER,
        Cashflow.SOURCE_OTHER
    ]
};

export const LIST_TARGET = {
    [Cashflow.DIRECTION_INCOME]: [
        Cashflow.TARGET_EMPLOYEE,
        Cashflow.TARGET_CUSTOMER,
        Cashflow.TARGET_OTHER
    ],
    [Cashflow.DIRECTION_EXPENSE]: [
        Cashflow.TARGET_SUPPLIER,
        Cashflow.TARGET_CUSTOMER,
        Cashflow.TARGET_OTHER
    ]
};


export default Cashflow;


