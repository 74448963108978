import { valueType } from "antd/lib/statistic/utils";
import { NumericFormat } from "react-number-format";

const TextNumber = ({ number }: { number: valueType }) => {
  return (
    <NumericFormat
      value={number}
      displayType="text"
      thousandSeparator=","
      decimalScale={2}
    />
  );
};

export default TextNumber;
