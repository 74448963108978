import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";
import { IconAward } from "@tabler/icons-react";

const InventoryListHeader = () => {
	const { t } = useTranslation();
	return (
		<PageHeader
			heading={t("inventory:heading_list")}
			siteMenuOpenKey="inventory"
			siteMenuSelectedKey="/inventory/product">
			<Row gutter={16} className="text-right">
				<Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
					<RoleCheck roles={[]}>
						<PageHeaderButton
							link="/inventory/product"
							type="default"
							icon={<IconAward size={18} className=" mr-1 -mt-0.5" />}>
							{t("inventory:point_list_button")}
						</PageHeaderButton>
					</RoleCheck>
				</Col>

				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					<RoleCheck roles={[]}>
						<PageHeaderButton link="/inventory/product/add">
							{t("inventory:add_button")}
						</PageHeaderButton>
					</RoleCheck>
				</Col>
			</Row>
		</PageHeader>
	);
};

export default InventoryListHeader;
