import { Spin } from "antd";
import BiCenterCollection from "common/collections/BiCenterCollection";
import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import {
  ChartDatum,
  ChartTimeSeriesDatum,
  ChartTimeSeriesProps
} from "common/types/BiCenterChart";
import ReportUtil from "common/utils/report";
import Error from "components/LayoutError";
import dayjs from "dayjs";
import ReportChartTimeSeriesTooltip from "features/report/chart/ReportChartTimeSeriesTooltip";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";

import { Area } from "@ant-design/charts";
import { IconGraph } from "@tabler/icons-react";

import type { DataQuery, DateRange } from "common/types/BiCenter";
const lineColors: Record<string, string> = {
  Main: ReportUtil.getColors()[0],
  Compare: ReportUtil.getColors()[1]
};

type Seri = keyof typeof lineColors;

const ReportChartTimeSeries = ({
  title,
  subtitle,
  height,
  className,
  dateRange,
  dateRangeCompare,
  keyMapping,
  dataInterval,
  dataSelect,
  dataService,
  dataTable,
  dataFilter,
  dataGroupBy,
  dataOrderBy,
  dataJoin,
  valueType
}: ChartTimeSeriesProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<ChartTimeSeriesDatum[]>([]);

  //load data for chart
  const loadData = useCallback(
    async ({
      range,
      rangeCompare
    }: {
      range: DateRange;
      rangeCompare: DateRange | null;
    }) => {
      setLoading(true);

      var props: DataQuery = {
        service: dataService,
        start: BiCenterModel.momentToString(range[0]),
        end: BiCenterModel.momentToString(range[1]),
        startcompare: rangeCompare
          ? BiCenterModel.momentToString(rangeCompare[0])
          : "",
        endcompare: rangeCompare
          ? BiCenterModel.momentToString(rangeCompare[1])
          : "",
        table: dataTable,
        join: dataJoin || "",
        select: dataSelect,
        filter: dataFilter,
        groupby: dataGroupBy,
        orderby: dataOrderBy,
        timeserie: dataInterval || "day",
        limit: "",
        offset: ""
      };

      //Call repository to fetch data
      const collection: BiCenterCollection =
        await new BiCenterRepository().queryRemote(props);
      setLoading(false);
      if (collection.hasError()) {
        setErrors(collection.error.errors);
      } else {
        setErrors([]);

        //extract data for chart
        let fetchedData: ChartTimeSeriesDatum[] = [];

        collection.items.forEach((item: BiCenterModel) => {
          if (item.start.toString() !== "-1") {
            //check if found compare
            if (item.havecompare && rangeCompare) {
              const seriTextCompare = item.rows[1][keyMapping.name].toString();

              fetchedData.push({
                ts: item.start,
                label: dayjs.unix(item.startcompare).format("DD/MM/YYYY"),
                value: +item.rows[1][keyMapping.value],
                seri: Object.keys(lineColors).includes(seriTextCompare)
                  ? (seriTextCompare as Seri)
                  : "Compare"
              });
            }

            //Main data item append AFTER compare data, so that, its chart will ABOVE the compare chart
            const seriText =
              item.rows[0][keyMapping.name]?.toString() ||
              t("report:default_seri_text");
            fetchedData.push({
              ts: item.start,
              label: dayjs.unix(item.start).format("DD/MM/YYYY"),
              value: +item.rows[0][keyMapping.value],
              seri: Object.keys(lineColors).includes(seriText)
                ? (seriText as Seri)
                : t("report:default_seri_text")
            });
          }
        });
        setData(fetchedData);
      }
    },
    [
      dataService,
      dataTable,
      dataJoin,
      dataSelect,
      dataFilter,
      dataGroupBy,
      dataOrderBy,
      dataInterval,
      keyMapping,
      t
    ]
  );

  //Reload data if change daterange
  useDeepCompareEffect(() => {
    loadData({
      range: dateRange,
      rangeCompare: dateRangeCompare
    });
  }, [dateRange, dateRangeCompare, loadData]);

  //chart config
  const config = {
    data: data,
    height: height,
    responsive: true,
    forceFit: true,
    xField: "ts",
    yField: "value",
    legend: undefined,
    seriesField: "seri",
    xAxis: {
      tickCount: 15,
      label: {
        formatter: (v: string) => {
          return dayjs.unix(+v).format("DD/MM");
        }
      }
    },
    yAxis: {
      tickCount: 3,
      label: {
        formatter: ReportUtil.numberFormatter
      }
    },
    meta: {
      value: {
        formatter: ReportUtil.numberFormatter
      }
    },
    slider:
      data.length > 60
        ? {
            height: 40,
            start: 1 - 60 / data.length,
            end: 1,
            formatter: (v: string) => {
              return dayjs.unix(+v).format("DD/MM");
            }
          }
        : false,

    isStack: false
  };

  return (
    <div className={"report_time_series " + className}>
      <div className="flex">
        <div className="grow">
          <div className="text-2xl font-semibold">
            <IconGraph className="-mt-1 text-gray-600" /> {title}{" "}
            <Spin className="mg-l-20" spinning={loading} size="small"></Spin>
          </div>
          {typeof subtitle !== "undefined" ? (
            <div className="ml-8 text-gray-400 text-md">{subtitle}</div>
          ) : null}
        </div>
        <div className="shrink"></div>
      </div>

      {errors.length > 0 ? (
        <Error
          className="report_error"
          heading={t("report:error.query_heading")}
          contentPadding={0}
          translate_prefix="report:error"
          items={errors}
        />
      ) : (
        <div className="mt-8">
          <Area
            {...config}
            point={{
              size: 4,
              style: (datum: ChartDatum) => {
                if (typeof datum.seri !== "undefined") {
                  return {
                    stroke: lineColors[datum.seri],
                    fill: lineColors[datum.seri]
                  };
                } else {
                  return {
                    stroke: "000000",
                    fill: "000000"
                  };
                }
              }
            }}
            areaStyle={(datum: ChartDatum) => {
              if (typeof datum.seri !== "undefined") {
                return {
                  fillOpacity: 0.3,
                  fill: lineColors[datum.seri]
                };
              } else {
                return {
                  fillOpacity: 0.3,
                  fill: "000000"
                };
              }
            }}
            color={(datum: ChartDatum) =>
              typeof datum.seri !== "undefined"
                ? lineColors[datum.seri]
                : "000000"
            }
            line={{
              size: 4,
              color: (datum: ChartDatum) => {
                if (typeof datum.seri !== "undefined") {
                  return lineColors[datum.seri];
                } else {
                  return "000000";
                }
              }
            }}
            tooltip={{
              customContent: (title: string, data: ChartTimeSeriesDatum[]) => {
                return (
                  <div>
                    <ReportChartTimeSeriesTooltip
                      data={data}
                      showChange={dateRangeCompare !== null}
                      lineColors={lineColors}
                      valueType={valueType}
                    />
                  </div>
                );
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ReportChartTimeSeries;
