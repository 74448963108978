import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PromotionListHeader = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("Thiết lập nhanh chương trình khuyến mãi")}
      siteMenuSelectedKey="/promotion"
    >
      {children}
    </PageHeader>
  );
};

export default PromotionListHeader;
