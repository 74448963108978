import {
  ContactMailCollectionJson,
  ContactMailJson,
} from "common/types/ContactMail";
import BaseCollection from "./BaseCollection";
import ContactMailModel from "common/models/ContactMailModel";

class ContactMailCollection extends BaseCollection<
  ContactMailJson,
  ContactMailModel,
  ContactMailCollectionJson
> {
  itemsFromJson(jsonItems: ContactMailJson[]): ContactMailModel[] {
    return jsonItems.map((item) => new ContactMailModel(item));
  }
}
export default ContactMailCollection;
