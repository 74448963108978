import React from "react";

type Props = {
  size?: "medium" | "small";
  color?: "main" | "gray";
};

const UserIcon = ({ size, color = "main" }: Props) => {
  let icon;
  switch (size) {
    case "medium":
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99998 7.43752C5.15081 7.43752 3.64581 5.93252 3.64581 4.08335C3.64581 2.23419 5.15081 0.729187 6.99998 0.729187C8.84915 0.729187 10.3541 2.23419 10.3541 4.08335C10.3541 5.93252 8.84915 7.43752 6.99998 7.43752ZM6.99998 1.60419C5.63498 1.60419 4.52081 2.71835 4.52081 4.08335C4.52081 5.44835 5.63498 6.56252 6.99998 6.56252C8.36498 6.56252 9.47915 5.44835 9.47915 4.08335C9.47915 2.71835 8.36498 1.60419 6.99998 1.60419Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
          <path
            d="M12.0109 13.2708C11.7718 13.2708 11.5734 13.0725 11.5734 12.8333C11.5734 10.8208 9.52009 9.1875 7.00009 9.1875C4.48009 9.1875 2.42676 10.8208 2.42676 12.8333C2.42676 13.0725 2.22842 13.2708 1.98926 13.2708C1.75009 13.2708 1.55176 13.0725 1.55176 12.8333C1.55176 10.3425 3.99592 8.3125 7.00009 8.3125C10.0043 8.3125 12.4484 10.3425 12.4484 12.8333C12.4484 13.0725 12.2501 13.2708 12.0109 13.2708Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
        </svg>
      );
      break;

    case "small":
      icon = (
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.00002 5.31248C3.67919 5.31248 2.60419 4.23748 2.60419 2.91665C2.60419 1.59581 3.67919 0.520813 5.00002 0.520813C6.32085 0.520813 7.39585 1.59581 7.39585 2.91665C7.39585 4.23748 6.32085 5.31248 5.00002 5.31248ZM5.00002 1.14581C4.02502 1.14581 3.22919 1.94165 3.22919 2.91665C3.22919 3.89165 4.02502 4.68748 5.00002 4.68748C5.97502 4.68748 6.77085 3.89165 6.77085 2.91665C6.77085 1.94165 5.97502 1.14581 5.00002 1.14581Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
          <path
            d="M8.57923 9.47917C8.4084 9.47917 8.26673 9.3375 8.26673 9.16667C8.26673 7.72917 6.80006 6.5625 5.00006 6.5625C3.20006 6.5625 1.7334 7.72917 1.7334 9.16667C1.7334 9.3375 1.59173 9.47917 1.4209 9.47917C1.25007 9.47917 1.1084 9.3375 1.1084 9.16667C1.1084 7.3875 2.85423 5.9375 5.00006 5.9375C7.1459 5.9375 8.89173 7.3875 8.89173 9.16667C8.89173 9.3375 8.75006 9.47917 8.57923 9.47917Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99998 7.43752C5.15081 7.43752 3.64581 5.93252 3.64581 4.08335C3.64581 2.23419 5.15081 0.729187 6.99998 0.729187C8.84915 0.729187 10.3541 2.23419 10.3541 4.08335C10.3541 5.93252 8.84915 7.43752 6.99998 7.43752ZM6.99998 1.60419C5.63498 1.60419 4.52081 2.71835 4.52081 4.08335C4.52081 5.44835 5.63498 6.56252 6.99998 6.56252C8.36498 6.56252 9.47915 5.44835 9.47915 4.08335C9.47915 2.71835 8.36498 1.60419 6.99998 1.60419Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
          <path
            d="M12.0109 13.2708C11.7718 13.2708 11.5734 13.0725 11.5734 12.8333C11.5734 10.8208 9.52009 9.1875 7.00009 9.1875C4.48009 9.1875 2.42676 10.8208 2.42676 12.8333C2.42676 13.0725 2.22842 13.2708 1.98926 13.2708C1.75009 13.2708 1.55176 13.0725 1.55176 12.8333C1.55176 10.3425 3.99592 8.3125 7.00009 8.3125C10.0043 8.3125 12.4484 10.3425 12.4484 12.8333C12.4484 13.0725 12.2501 13.2708 12.0109 13.2708Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
        </svg>
      );
  }
  return icon;
};

export default UserIcon;
