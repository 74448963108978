import { IconPhoto, IconTrash } from "@tabler/icons-react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Typography,
} from "antd";
import FileModel from "common/models/FileModel";
import { ShopPopupJson } from "common/types/ShopPopup";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid"
export default function ShopPopupForm({
  data,
  open,
  type = "add",
  onFinish,
  onCancel,
}: {
  type: "add" | "edit";
  data: ShopPopupJson;
  open: boolean;
  onFinish: (data: ShopPopupJson) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm<ShopPopupJson>();

  const [imageDesktop, setImageDesktop] = useState<string>("");
  const [imageDesktopUrl, setImageDesktopUrl] = useState<string>("");
  const [imageDesktopItem, setImageDesktopItem] = useState<FileModel>(
    new FileModel(FileModel.getDefaultData())
  );

  const [imageMobile, setImageMobile] = useState<string>("");
  const [imageMobileUrl, setImageMobileUrl] = useState<string>("");
  const [imageMobileItem, setImageMobileItem] = useState<FileModel>(
    new FileModel(FileModel.getDefaultData())
  );
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    alt: data.alt.length > 0 ? data.alt : "",
    from_time: data.from_time > 0 ? dayjs(new Date(data.from_time * 1000)) : 0,
    to_time: data.to_time > 0 ? dayjs(new Date(data.to_time * 1000)) : 0,
    key: data.key,
    link: data.link,
    name: data.name.length > 0 ? data.name : "",
    time_delay: data.time_delay,
    time_limit: data.time_limit,
    title: data.title.length > 0 ? data.title : "",
    // image_desktop: data.image.desktop.url,
    // image_mobile: data.image.mobile.url,
    image_desktop: FileModel.convertToUploadFiles([
      new FileModel({ ...FileModel.getDefaultData() }),
    ]),
    image_mobile: FileModel.convertToUploadFiles([
      new FileModel({ ...FileModel.getDefaultData() }),
    ]),
  };

  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: any = {
        id:
          data.id && data.id.toString().length > 0
            ? Number(data.id)
            : undefined,
        alt: formData.alt,
        key: data.key ? data.key : uuid(),
        link:
          formData.link.length === 0 || formData.link === "#"
            ? "#"
            : formData.link,
        name: formData.name.trim(),
        time_delay:
          Number(formData.time_delay) > 0 ? Number(formData.time_delay) : 0,
        title: formData.title.trim(),
        from_time:
          Helper.datePickerValueToTimestamp(formData.from_time) > 0
            ? Helper.datePickerValueToTimestamp(formData.from_time)
            : 0,
        to_time:
          Helper.datePickerValueToTimestamp(formData.to_time) > 0
            ? Helper.datePickerValueToTimestamp(formData.to_time)
            : 0,
        time_limit:
          Number(formData.time_limit) > 0 ? Number(formData.time_limit) : 0,
        image: {
          desktop: {
            url: imageDesktop,
          },
          mobile: {
            url: imageMobile,
          },
        },
      };

      return submitData;
    },
    [data, imageDesktop, imageMobile]
  );

  const onSubmit = async (formData: any) => {
    console.log("🥺 ~ onSubmit ~ formData:", doPrepareData(formData));
    setLoading(true);
    await onFinish({
      ...data,
      ...doPrepareData(formData),
    });
    setImageDesktopUrl("");
    setImageDesktopItem(new FileModel(FileModel.getDefaultData()));
    setImageMobileUrl("");
    setImageMobileItem(new FileModel(FileModel.getDefaultData()));
    setLoading(false);
  };

  const handleOnClose = () => {
    form.resetFields();
    setImageDesktopUrl("");
    setImageDesktopItem(new FileModel(FileModel.getDefaultData()));
    setImageMobileUrl("");
    setImageMobileItem(new FileModel(FileModel.getDefaultData()));
    onCancel();
  };

  useEffect(() => {
    if (data.name.length > 0) {
      setImageDesktop(data.image.desktop.url);
      setImageMobile(data.image.mobile.url);
    } else {
      setImageDesktop("");
      setImageMobile("");
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      ...(initialValues as any),
    });
  }, [data, form]);

  return (
    <Modal
      title={type === "edit" ? "Cập nhật" : "Thêm mới"}
      open={open}
      footer={<></>}
      onCancel={() => {
        handleOnClose();
      }}
      maskClosable={false}
      width={800}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      destroyOnClose
    >
      <>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Row gutter={[16, 16]}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Tên Popup"
                name="name"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Tiêu đề"
                name="title"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Link"
                name="link"
                // rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            {/* <Col md={8} xs={24}>
              <Form.Item
                label="Key"
                name="key"
                // rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col> */}
            <Col md={8} xs={24}>
              <Form.Item
                label="Mô tả"
                name="alt"
                // rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Số lần hiển thị tối đa"
                name="time_limit"
                rules={[
                  {
                    validator: (_, value, callback) => {
                      const reg = /^[0-9]+$/;
                      if (!reg.test(value)) {
                        callback("Vui lòng nhập số");
                      } else {
                        callback();
                      }
                    },
                    message: "Vui lòng nhập số",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Thời gian mở lại (giây)"
                name="time_delay"
                rules={[
                  {
                    validator: (_, value, callback) => {
                      const reg = /^[0-9]+$/;
                      if (!reg.test(value)) {
                        callback("Vui lòng nhập số");
                      } else {
                        callback();
                      }
                    },
                    message: "Vui lòng nhập số",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Form.Item label={"Thời gian hiển thị từ"} name="from_time">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={"Thời gian hiển thị đến"}
                name="to_time"
                rules={[
                  {
                    message: t("Thời gian không hợp lệ"),
                    validator: (_: any, value: any) => {
                      if (
                        Helper.datePickerValueToTimestamp(
                          form.getFieldValue("from_time")
                        ) < Helper.datePickerValueToTimestamp(value) ||
                        !Helper.datePickerValueToTimestamp(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[4, 0]}>
            {imageDesktop.length === 0 && (
              <>
                <Col md={24} xs={24}>
                  <Form.Item
                    label="URL ảnh desktop"
                    name=""
                    // rules={[{ required: true, message: "Vui lòng nhập" }]}
                  >
                    {imageDesktopUrl.length === 0 && (
                      <>
                        <Col md={12} xs={24}>
                          <Form.Item className="m-0">
                            <FileUploaderButton
                              form={form}
                              formName="image_desktop"
                              label={t("Chọn file")}
                              icon={<IconPhoto size="24" color="grey" />}
                              origin="avatar"
                              uploadRenderType="thumbnail"
                              isSingleFile={true}
                              maxCount={1}
                              initFiles={initialValues.image_desktop}
                              onUploadCompleted={(f) => {
                                setImageDesktopItem(f);
                                setImageDesktop(f.url);
                              }}
                              onDeleteCompleted={(f) => {
                                setImageDesktopItem(
                                  new FileModel(FileModel.getDefaultData())
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    hoặc
                  </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                  <Input
                    disabled={imageDesktopItem.id > 0}
                    allowClear
                    value={imageDesktopUrl}
                    placeholder="hoặc dán URL ảnh vào đây"
                    onChange={(e) => {
                      console.log("🥺 ~ e:", e);
                      // setTimeout(() => {
                      setImageDesktopUrl(e.target.value.trim());
                      setImageDesktop(e.target.value.trim());
                      // }, 500);
                    }}
                  />
                </Col>
              </>
            )}
            {imageDesktopUrl.length > 0 && (
              <Col md={24} xs={24}>
                <Input
                  disabled={imageDesktopItem.id > 0}
                  allowClear
                  value={imageDesktopUrl}
                  placeholder="hoặc dán URL ảnh vào đây"
                  onChange={(e) => {
                    console.log("🥺 ~ e:", e);
                    // setTimeout(() => {
                    setImageDesktopUrl(e.target.value.trim());
                    setImageDesktop(e.target.value.trim());
                    // }, 500);
                  }}
                />
              </Col>
            )}
            {imageDesktop.length > 0 && (
              <Col md={24} xs={24}>
                <div className="flex flex-col">
                  <Typography.Text> Ảnh desktop đã chọn</Typography.Text>
                  <div className="flex items-start">
                    <Image
                      height={100}
                      width={100}
                      style={{ borderRadius: 4 }}
                      src={imageDesktop}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImageDesktop("");
                        setImageDesktopItem(
                          new FileModel(FileModel.getDefaultData())
                        );
                        setImageDesktopUrl("");
                        form.setFieldValue("image_desktop", []);
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </div>
              </Col>
            )}
          </Row>

          <Row gutter={[4, 0]}>
            {imageMobile.length === 0 && (
              <>
                <Col md={24} xs={24}>
                  <Form.Item
                    label="URL ảnh mobile"
                    name=""
                    // rules={[{ required: true, message: "Vui lòng nhập" }]}
                  >
                    {imageMobileUrl.length === 0 && (
                      <>
                        <Col md={12} xs={24}>
                          <Form.Item className="m-0">
                            <FileUploaderButton
                              form={form}
                              formName="image_mobile"
                              label={t("Chọn file")}
                              icon={<IconPhoto size="24" color="grey" />}
                              origin="avatar"
                              uploadRenderType="thumbnail"
                              isSingleFile={true}
                              maxCount={1}
                              initFiles={initialValues.image_mobile}
                              onUploadCompleted={(f) => {
                                setImageMobileItem(f);
                                setImageMobile(f.url);
                              }}
                              onDeleteCompleted={(f) => {
                                setImageMobileItem(
                                  new FileModel(FileModel.getDefaultData())
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    hoặc
                  </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                  <Input
                    disabled={imageMobileItem.id > 0}
                    allowClear
                    value={imageMobileUrl}
                    placeholder="hoặc dán URL ảnh vào đây"
                    onChange={(e) => {
                      console.log("🥺 ~ e:", e);
                      // setTimeout(() => {
                      setImageMobileUrl(e.target.value.trim());
                      setImageMobile(e.target.value.trim());
                      // }, 500);
                    }}
                  />
                </Col>
              </>
            )}
            {imageMobileUrl.length > 0 && (
              <Col md={24} xs={24}>
                <Input
                  disabled={imageMobileItem.id > 0}
                  allowClear
                  value={imageMobileUrl}
                  placeholder="hoặc dán URL ảnh vào đây"
                  onChange={(e) => {
                    console.log("🥺 ~ e:", e);
                    // setTimeout(() => {
                    setImageMobileUrl(e.target.value.trim());
                    setImageMobile(e.target.value.trim());
                    // }, 500);
                  }}
                />
              </Col>
            )}
            {imageMobile.length > 0 && (
              <Col md={24} xs={24}>
                <div className="flex flex-col">
                  <Typography.Text> Ảnh mobile đã chọn</Typography.Text>
                  <div className="flex items-start">
                    <Image
                      height={100}
                      width={100}
                      style={{ borderRadius: 4 }}
                      src={imageMobile}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImageMobile("");
                        setImageMobileItem(
                          new FileModel(FileModel.getDefaultData())
                        );
                        setImageMobileUrl("");
                        form.setFieldValue("image_mobile", []);
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </div>
              </Col>
            )}
          </Row>

          <Form.Item className="mt-4">
            <div className="flex gap-4">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Hoàn tất
              </Button>
              <Button
                type="default"
                onClick={() => {
                  handleOnClose();
                }}
              >
                Đóng
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
}
