import CustomerPoint from 'common/constants/CustomerPoint';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { CustomerPointJson } from "common/types/CustomerPoint";
class CustomerPointModel
  extends BaseModel
  implements BaseModelJson<CustomerPointJson>
{
  creator_id: number;
  company_id: number;
  customer_id: number;
  id: number;
  type: number;
  object_id: number;
  order_amount: number;
  value: number;
  note: string;
  status: number;
  date_created: number;
  date_modified: number;
  enrich_customer_full_name: string;

  constructor(json: CustomerPointJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.customer_id = json.customer_id || 0;
    this.id = json.id || 0;
    this.type = json.type || 0;
    this.object_id = json.object_id || 0;
    this.order_amount = json.order_amount || 0;
    this.value = json.value || 0;
    this.note = json.note || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.enrich_customer_full_name = json.enrich_customer_full_name || "";
  }

  static getDefaultData(): CustomerPointJson {
    return {
      creator_id: 0,
      company_id: 0,
      customer_id: 0,
      id: 0,
      type: 0,
      object_id: 0,
      order_amount: 0,
      value: 0,
      note: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
      enrich_customer_full_name: "",
    };
  }

  toJson(): CustomerPointJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      customer_id: this.customer_id,
      id: this.id,
      type: this.type,
      object_id: this.object_id,
      order_amount: this.order_amount,
      value: this.value,
      note: this.note,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      enrich_customer_full_name: this.enrich_customer_full_name,
    };
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: CustomerPoint.TYPE_MANUAL,
        label: i18n.t("customerpoint:TYPE_MANUAL"),
      },
      {
        value: CustomerPoint.TYPE_ORDER,
        label: i18n.t("customerpoint:TYPE_ORDER"),
      },
      {
        value: CustomerPoint.TYPE_CASHFLOW,
        label: i18n.t("customerpoint:TYPE_CASHFLOW"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: CustomerPoint.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: CustomerPoint.STATUS_DISABLE,
        label: i18n.t("common:STATUS_DISABLE"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default CustomerPointModel;
