import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Upload,
  message,
  notification,
  Checkbox,
  Tooltip,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActionCode, DeliveryAddEditJson } from "common/types/Delivery";
import useConvertBase64ToFile from "hooks/useConvertBase64ToFile";
import AxiosClient from "common/api/AxiosClient";
import axios from "axios";
import BaseApi from "common/api/BaseApi";
import FileModel from "common/models/FileModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import usePathParams from "hooks/usePathParams";
import { useTranslation } from "react-i18next";

import MoneyInput from "components/MoneyInput";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import { Switch } from "antd";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import usePostActionStore from "zustands/usePostActionStore";
import { IconPhoto } from "@tabler/icons-react";
import PostAction from "features/delivery/action/PostAction";
import DeliveryMultipleWebCam from "features/delivery/action/components/DeliveryMultipleWebcame";

////////////////
// type
type NotificationType = "success" | "info" | "warning" | "error";
type Props = {
  action_code: ActionCode;
  GpsRequire?: boolean;
  UpLoadRequire?: boolean;
  widthImg?: number | string;
  heightImg?: number | string;
  delivery_id: number;
  cost_total: number;
  handleSucess?: () => void;
};
type UploadImg = "UPLOAD_REQUIRE" | "UPLOAD_NO_REQUIRE";

type Gps = "GPS_REQUIRE" | "GPS_NO_REQUIRE";

const DeliveryAccountantTransferForm = ({
  action_code,
  GpsRequire = false,
  UpLoadRequire = false,
  widthImg = 1080,
  heightImg = 768,
  delivery_id,
  cost_total = 0,
  handleSucess,
}: Props) => {
  const [form] = Form.useForm();
  const { convertBast64ToFile } = useConvertBase64ToFile();

  const { t } = useTranslation();
  const loginAccount = useLoginAccountStore((state) => state.account);
  const { setAction } = usePostActionStore();
  const [messageApi, contextHolder] = message.useMessage();
  ////////////
  // state
  const [imgFile, setImgFile] = React.useState<any[]>([]);

  const [priceTotal, setPriceTotal] = useState<number>(0);
  const [pending, setPending] = useState<boolean>(false);
  const [images, setImages] = useState<string>("");
  const [imagesCamera, setImagesCamera] = useState<string[]>([]);
  const [note, setNote] = useState("0");

  const [enableWebcam, setEnableWebcam] = React.useState<any>(true);
  const [showTip, setShowTip] = useState<boolean>(false);
  const [checkTotal, setCheckTotal] = useState<boolean>(false);
  ///////////
  // TYPE_REQUIRE
  const GPS_REQUIRE: string = GpsRequire ? "GPS_REQUIRE" : "GPS_NO_REQUIRE";
  const UPLOAD_REQUIRE: string =
    action_code === ("ACTION_START" || "ACTION_START")
      ? "UPLOAD_NO_REQUIRE"
      : UpLoadRequire
      ? "UPLOAD_REQUIRE"
      : "UPLOAD_NO_REQUIRE";

  // Function

  const handleChangeImg = useCallback(
    (imgBase64s: string[]) => {
      if (imagesCamera.length < 5) {
        setImagesCamera(imgBase64s);
        let files: any[] = [];
        for (let i = 0; i < imgBase64s.length; i++) {
          files.push(
            convertBast64ToFile(
              imgBase64s[i],
              `image_${i}_${Math.floor(
                Math.random() * (999999 - 0 + 1) + 0
              ).toString()}.jpg`
            )
          );
        }

        setImgFile(files);
      } else {
        message.open({
          type: "error",
          content: "Bạn đã chụp đủ ảnh !",
          duration: 3,
        });
      }
    },
    [imagesCamera]
  );

  const handlePostImage = async (file: Blob): Promise<FileModel> => {
    setPending(true);
    // setCameraPending(true);
    let item = new FileModel(FileModel.getDefaultData());
    if (!enableWebcam) {
      return item;
    } else {
      const formData = new FormData();
      const companyId =
        loginAccount.company.id > 0 ? loginAccount.company.id : 0;
      formData.append("company_id", companyId.toString());
      formData.append("creator_id", JSON.stringify(loginAccount.id));
      formData.append("directory_id", "0");
      formData.append("is_directory", "0");
      formData.append("status", "1");
      formData.append("object_type", "31");
      formData.append("object_id", "1");
      formData.append("origin", "company");
      formData.append("file", file);

      try {
        const res = await axios.post(
          AxiosClient().defaults.baseURL + "/files",
          formData,
          {
            headers: {
              Authorization: BaseApi.loginAccount.jwt,
              // ContentType: "multipart/form-data",
            },
          }
        );
        if (res) {
          item = new FileModel(res.data);
        }
      } catch (error) {
        item.withError(BaseApi.handleError(error));
      }

      return item;
    }
  };

  const handleSubmitImage = async () => {
    let image_ids: number[] = [];

    if (imgFile.length > 0) {
      let ImagePromises: Promise<FileModel>[] = [];

      for (let i = 0; i < imgFile.length; i++) {
        ImagePromises.push(
          new Promise(async (resolve, reject) => {
            const res = await handlePostImage(imgFile[i]);
            if (res.hasError()) {
              message.error(t(`upload:errors.${res.error.errors[0]}`));
            } else {
              setImages((prev) => {
                if (prev.length > 0) {
                  return prev + "," + res.id.toString();
                } else {
                  return prev + res.id.toString();
                }
              });
            }
          })
        );
      }

      Promise.all(ImagePromises)
        .then((res) => {
          res.forEach((i) => {
            image_ids.push(i.id);
          });
        })
        .catch(() => {
          message.error({
            content: t("delivery:button_action.notification.error.title"),
            children: t(
              "delivery:button_action.notification.error.description"
            ),
          });
        })
        .finally(async () => {
          console.log(image_ids);
          setImages(
            image_ids
              .map((item) => item)
              .join(",")
              .toString()
          );
        });
    }
  };

  ///////////////
  // useEffect
  useEffect(() => {
    if (imgFile.length > 0) {
      handleSubmitImage();
    }
  }, [imgFile]);

  return (
    <div className="my-1 h-full w-full ">
      {contextHolder}
      <Form
        initialValues={{
          note: 0,
          cost_transfer_full: checkTotal,
          cost_tip: false,
        }}
        form={form}
        name="PostAction"
        onSubmitCapture={() => {}}
        onFinish={() => {}}
      >
        {/* <div className="flex justify-end"> */}
        <Tooltip title="Bật/tắt cam">
          <Switch
            defaultChecked
            className="mb-5"
            onChange={() => {
              setEnableWebcam(!enableWebcam);
              setImages("");
              form.resetFields();
            }}
          />
        </Tooltip>
        {/* </div> */}

        <Row gutter={[8, 8]}>
          {/* {enableWebcam && UPLOAD_REQUIRE === "UPLOAD_REQUIRE" && ( */}
          {enableWebcam && (
            <Col span={24}>
              <DeliveryMultipleWebCam
                changeImgSrc={handleChangeImg}
                enableWebcam={true}
                pending={false}
                imgScreenshotSrc={imagesCamera}
                maxNumberImg={1}
              />
            </Col>
          )}

          {!enableWebcam && (
            <Form.Item>
              <FileUploaderButton
                form={form}
                formName="files"
                label={t("file:select_file_image")}
                icon={<IconPhoto size="24" color="grey" />}
                origin="avatar"
                uploadRenderType="thumbnail"
                isSingleFile={true}
                multipleSize={1}
                // initFiles={initialValues.files}
                onDeleteCompleted={(f) => {
                  const tmpImages = images.split(",").map((x) => Number(x));
                  const findIndex = tmpImages.findIndex((val) => val === f.id);
                  if (findIndex >= 0) {
                    tmpImages.splice(findIndex, 1);
                  }
                  setImages(tmpImages.toString());
                }}
                onUploadCompleted={(f) => {
                  setImages((prev) => {
                    if (prev.length > 0) {
                      return prev + "," + f.id.toString();
                    } else {
                      return prev + f.id.toString();
                    }
                  });
                }}
              />
            </Form.Item>
          )}
          {/* {enableWebcam && ( */}
          <>
            <Col span={24}>
              <Form.Item
                name="note"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24, offset: "0" }}
                labelAlign="left"
                validateFirst
                rules={[
                  {
                    message: "Số tiền chuyển khoản phải lớn hơn 0",
                    validator: (_: any, value: number) => {
                      if (value && value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Error");
                      }
                    },
                  },
                ]}
                label={
                  <>
                    <div className="flex items-center justify-between w-full">
                      <Typography.Text>Tiền chuyển khoản: </Typography.Text>
                      <Form.Item
                        name="cost_transfer_full"
                        valuePropName="checked"
                        style={{ marginBottom: 0, marginLeft: "1rem" }}
                      >
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              setNote(JSON.stringify(cost_total));
                              form.setFieldsValue({ note: cost_total });
                              setShowTip(false);
                            } else {
                              if (showTip) {
                                setShowTip(false);
                                form.setFieldsValue({ cost_tip: false });
                              }
                              setNote("0");
                              form.setFieldsValue({ note: 0 });
                            }
                          }}
                        >
                          Đủ ({Helper.moneyFormat(cost_total)})
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </>
                }
              >
                <MoneyInput
                  style={{ width: "100%" }}
                  addonAfter="VND"
                  // defaultValue={0}
                  // value={priceTotal}
                  onChange={(e: any) => {
                    setPriceTotal(e);
                    if (e > cost_total) {
                      setNote(JSON.stringify(cost_total));
                      form.setFieldsValue({ cost_transfer_full: true });
                      setShowTip(true);
                    } else if (e === cost_total) {
                      form.setFieldsValue({ cost_transfer_full: true });
                      setShowTip(false);
                    } else {
                      form.setFieldsValue({ cost_transfer_full: false });
                      form.setFieldsValue({ cost_tip: false });
                      setShowTip(false);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </>
        </Row>
      </Form>
      <div
        onClick={() => {
          // form.submit();
        }}
      >
        <PostAction
          onComponentFormSubmit={() => {
            form.submit();
            if (form.getFieldValue("note") > 0) {
              return true;
            } else {
              return false;
            }
          }}
          key={"1"}
          action={action_code}
          gpsRequire={GpsRequire}
          noteRequire={false}
          uploadRequire={UpLoadRequire}
          isShowNote={false}
          data={{
            deliveries_id: delivery_id,
            note: form.getFieldValue("note") || "0",
            image: images,
          }}
          handlePostActionSuccess={() => {
            handleSucess && handleSucess();
          }}
        ></PostAction>
      </div>
    </div>
  );
};

export default DeliveryAccountantTransferForm;
