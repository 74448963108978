import CustomerCollection from "common/collections/CustomerCollection";
import Role from "common/constants/Role";
import CustomerModel from "common/models/CustomerModel";
import CustomerRepository from "common/repositories/CustomerRepository";
import { FilterCustomer } from "common/types/Customer";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableInfoRow from "components/table/TableInfoRow";
import TableLinkText from "components/table/TableLinkText";
import TextAddress from "components/TextAddress";
import TextCustomerType from "components/TextCustomerType";
import TextMoney from "components/TextMoney";
import TextRegion from "components/TextRegion";
import CustomerFormLatestPointModal from "features/customer/form/CustomerFormLatestPointModal";
import CustomerListHeader from "features/customer/list/CustomerListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomerListFilter from "./CustomerListFilter";

const CustomerList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterCustomer = useMemo(
    () => CustomerRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterCustomer>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<CustomerModel[]>([]);

  //////////////////////////////////////////
  // function
  const fetchData = useCallback(async (): Promise<CustomerCollection> => {
    let collection = await new CustomerRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  //////////////////////////////////////////
  // modal show customer latest point
  const [formLatestPointVisible, setFormLatestPointVisible] =
    useState<boolean>(false);
  const [customerPointId, setCustomerPointId] = useState<number>(0);

  //Table columns
  const columns: TableColumnsType<CustomerModel> = [
    {
      title: t("customer:id"),
      key: "id",
      align: "center",
      width: 100,
      render: (id, record) => {
        return (
          <TableLinkText link={"/customer/edit/id/" + id}>{id}</TableLinkText>
        );
      },
    },
    {
      title: t("customer:full_name"),
      key: "full_name",
    },
    {
      title: t("common:phone"),
      key: "phone",
      width: 120,
    },

    {
      title: t("common:region"),
      width: 200,
      key: "region_id",
      render: (region_id: number) => <TextRegion id={region_id} />,
    },
    {
      title: t("customer:count_order_label"),
      width: 100,
      key: "count_order",
      sortby: "count_order",
      align: "right",
      render: (count_order: number, record: CustomerModel) =>
        count_order > 0 ? (
          <TableLinkText
            link={"/order?customer_id=" + record.id}
            title={t("order:status") + ": " + t("order:STATUS_COMPLETE")}
          >
            {Helper.numberFormat(record.count_order)}{" "}
            {t("customer:count_order_unit")}
          </TableLinkText>
        ) : (
          <>0</>
        ),
    },
    {
      title: t("customer:count_money"),
      width: 120,
      key: "money",
      sortby: "money",
      align: "right",
      render: (money: number, record: CustomerModel) =>
        money > 0 ? (
          <TableLinkText
            link={"/order?customer_id=" + record.id}
            title={t("order:status") + ": " + t("order:STATUS_COMPLETE")}
          >
            <TextMoney money={money} />
          </TableLinkText>
        ) : (
          <>0</>
        ),
    },

    {
      title: t("customer:customer_point"),
      width: 130,
      key: "point",
      align: "right",
      sortby: "point",
      render: (_: any, record: CustomerModel) => (
        <TableLinkText onClick={() => onShowFormLatestPoint(record.id)}>
          <TextMoney money={record.point} noSymbol />
        </TableLinkText>
      ),
    },
    {
      title: t("customer:customer_type_short"),
      key: "customer_type_id",
      width: 150,
      render: (customer_type_id: any) => (
        <TextCustomerType id={customer_type_id} />
      ),
    },
    {
      title: " ",
      key: "action",
      align: "right",
      fixed: "right",
      width: 100,
      toggletype: "trigger",
      render: (_: any, record: CustomerModel) => {
        const genderInfo = CustomerModel.getGender(record.gender);

        return (
          <RoleCheck roles={[Role.CUSTOMER_MANAGE]} hideOnFail>
            <TableEdit link={"/customer/edit/id/" + record.id} />
            <TableInfo record={record}>
              <TableInfoRow label={t("common:email")} content={record.email} />
              <TableInfoRow
                label={t("common:gender")}
                content={genderInfo?.label}
              />

              <TableInfoRow
                label={t("customer:address")}
                content={
                  <>
                    <TextAddress
                      address={record.address}
                      region_id={record.region_id}
                      sub_region_id={record.sub_region_id}
                      sub_sub_region_id={record.sub_sub_region_id}
                    />
                  </>
                }
              />
              <TableInfoRow
                label={t("customer:birthday")}
                content={record.birthday}
              />
            </TableInfo>
          </RoleCheck>
        );
      },
    },
  ];

  const onShowFormLatestPoint = (customerId: number) => {
    setCustomerPointId(customerId);
    setFormLatestPointVisible(true);
  };

  return (
    <>
      <CustomerListHeader />

      <CustomerListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterCustomer, CustomerModel, CustomerCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "customer",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <CustomerFormLatestPointModal
        customerId={customerPointId}
        key={"customer-form-latest-point-modal-" + customerPointId}
        open={formLatestPointVisible}
        setOpen={(isOpen) => {
          setFormLatestPointVisible(isOpen);
        }}
      />
    </>
  );
};

export default CustomerList;
