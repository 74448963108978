import { useTranslation } from "react-i18next";

import PageHeader from "components/page/PageHeader";

const TaskDepartmentListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("Công việc phòng ban")}
      siteMenuOpenKey="task"
      siteMenuSelectedKey="/task/department"
    ></PageHeader>
  );
};

export default TaskDepartmentListHeader;
