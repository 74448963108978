import { Modal } from "antd";
import EcomModel from "common/models/EcomModel";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import EcomFormAdd from "./form/EcomFormAdd";
import EcomItemTypeImage from "./item/EcomItemTypeImage";

const EcomAddModal = ({
  type,
  open,
  setOpen,
  onAddSuccess,
}: {
  type: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onAddSuccess: (o: EcomModel) => void;
}) => {
  const { t } = useTranslation();

  const [processing, setProcessing] = useState(false);

  return (
    <Modal
      destroyOnClose={true}
      centered
      confirmLoading={processing}
      width={800}
      open={open}
      maskClosable={false}
      onCancel={() => {
        setOpen(false);
      }}
      okButtonProps={{ form: "ecominit-form", htmlType: "submit" }}
      okText={t("ecom:form.init_save")}
      cancelText={t("common:close")}
    >
      <div className="text-center">
        <div style={{ width: 100, display: "inline-block" }}>
          <EcomItemTypeImage type={type} width={120} height={120} />
        </div>
      </div>

      <EcomFormAdd
        initialValues={{
          ...EcomModel.getDefaultData(),
          type: type,
        }}
        setOpen={setOpen}
        setProcessing={setProcessing}
        onAddSuccess={onAddSuccess}
      />
    </Modal>
  );
};

export default EcomAddModal;
