import { Form, Radio } from "antd";
import ProductInventoryChecking from "common/constants/ProductInventoryChecking";
import React from "react";
import { useTranslation } from "react-i18next";

const ProductInventoryCheckingFormStatus = ({
  status,
  isDisable,
  isedit,
}: {
  isDisable: boolean;
  isedit: boolean;
  status: number;
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("common:status")} className="mb-0" name="status">
      <Radio.Group buttonStyle="solid" value={status}>
        {isedit ? (
          <Radio.Button
            disabled={
              isDisable ||
              status === ProductInventoryChecking.STATUS_COMPLETED ||
              status === ProductInventoryChecking.STATUS_CANCELLED
            }
            value={ProductInventoryChecking.STATUS_REJECT}
          >
            {t("Chưa chấp nhận")}
          </Radio.Button>
        ) : (
          <Radio.Button
            disabled={
              isDisable ||
              status === ProductInventoryChecking.STATUS_COMPLETED ||
              status === ProductInventoryChecking.STATUS_CANCELLED
            }
            value={ProductInventoryChecking.STATUS_PENDING}
          >
            {t("productinventorychecking:STATUS_PENDING")}
          </Radio.Button>
        )}

        <Radio.Button
          disabled={
            isDisable ||
            status === ProductInventoryChecking.STATUS_COMPLETED ||
            status === ProductInventoryChecking.STATUS_CANCELLED
          }
          value={ProductInventoryChecking.STATUS_PROCESSING}
        >
          {t("productinventorychecking:STATUS_PROCESSING")}
        </Radio.Button>
        <Radio.Button
          value={ProductInventoryChecking.STATUS_COMPLETED}
          disabled={
            isDisable ||
            status >= ProductInventoryChecking.STATUS_COMPLETED ||
            !isedit
          }
        >
          {t("productinventorychecking:STATUS_COMPLETED")}
        </Radio.Button>
        <Radio.Button
          value={ProductInventoryChecking.STATUS_CANCELLED}
          disabled={
            isDisable ||
            status === ProductInventoryChecking.STATUS_COMPLETED ||
            !isedit
          }
        >
          {t("productinventorychecking:STATUS_CANCELLED")}
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export default ProductInventoryCheckingFormStatus;
