import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const LeaveAssignFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("template:heading_nav"),
      link: "/template",
    },
  ];

  return (
    <PageHeader
      heading={t(
        isEditing ? "leaveapprove:heading_edit" : "leaveapprove:heading_add"
      )}
      nav={nav}
      siteMenuOpenKey="hrm"
      siteMenuHidden
    ></PageHeader>
  );
};

export default LeaveAssignFormHeader;
