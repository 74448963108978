import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";

const TaskFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("Công việc"),
      link: "/task",
    },
    {
      text: t(isEditing ? "template:heading_edit" : "template:heading_add"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t(isEditing ? "template:heading_edit" : "template:heading_add")}
      nav={nav}
      siteMenuOpenKey="task"
      siteMenuSelectedKey="/task"
      siteMenuHidden
    ></PageHeader>
  );
};

export default TaskFormHeader;
