import SettingImageList from "features/shopanother/store/list/SettingImageList";
import usePathParams from "hooks/usePathParams";

const ShopAnotherPage = () => {
	const [pathParams] = usePathParams();
	let com = <></>;

	switch (pathParams.action) {
		case "storeImage":
			com = <SettingImageList></SettingImageList>;
			break;
		default:
			com = <></>;
			break;
	}
	return com;
};

export default ShopAnotherPage;
