import { useTranslation } from "react-i18next";
import PageHeader from "components/page/PageHeader";

type Props = {
  children: React.ReactNode;
};

const LeaveTypeListHeader = ({ children }: Props) => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("leavetype:heading_list")}
      siteMenuOpenKey="leavetype"
      siteMenuSelectedKey="/leavetype"
    >
      {children}
    </PageHeader>
  );
};

export default LeaveTypeListHeader;
