import { Checkbox, Tag, Tooltip } from "antd";
import PromotionCollection from "common/collections/PromotionCollection";
import Promotion from "common/constants/Promotion";
import Role from "common/constants/Role";
import PromotionModel from "common/models/PromotionModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import { FilterPromotion } from "common/types/Promotion";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import dayjs from "dayjs";
import PromotionListHeader from "features/promotion/list/PromotionListHeader";
import PromotionCouponModal from "features/promotioncoupon/form/PromotionCouponModal";
import PromotionProductModal from "features/promotionproduct/form/PromotionProductModal";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconPencil, IconSettings } from "@tabler/icons-react";

import PromotionFormModal from "../form/PromotionFormModal";
// import PromotionFormSelectGroupModal from "../form/PromotionFormSelectGroupModal";
import PromotionSettingStoreModal from "../form/PromotionSettingStoreModal";
import PromotionListFilter from "./PromotionListFilter";

const PromotionList = () => {
	const { t } = useTranslation();

	// modal editing store apply promotion
	const [editPromotionStoreVisible, setEditPromotionStoreVisible] =
		useState(false);

	// modal setting Compo code
	const [editPromotionCouponVisible, setEditPromotionCouponVisible] =
		useState(false);

	// modal setting promotion Product
	const [editPromotionProductVisible, setEditPromotionProductVisible] =
		useState(false);

	// modal setting promotion
	const [editPromotionVisible, setEditPromotionVisible] = useState(false);
	const [editingPromotionModel, setEditingPromotionModel] =
		useState<PromotionModel>(
			new PromotionModel(PromotionModel.getDefaultData())
		);

	const [group, setGroup] = useState(0);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterPromotion = useMemo(
		() => PromotionRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] = useStateFilter<FilterPromotion>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<PromotionModel[]>([]);
	const fetchData = useCallback(async (): Promise<PromotionCollection> => {
		let collection = await new PromotionRepository().getItems({
			filters,
		});
		setDataSource(collection.items);
		setTotal(collection.total);

		return collection;
	}, [filters]);

	// modal promotion form select group
	const [formSelectGroupVisible, setFormSelectGroupVisible] = useState(false);

	const onSaveSuccess = (item: PromotionModel) => {
		//detech this is NEW or UPDATE
		const foundIndex = dataSource.findIndex((r) => r.id === item.id);
		if (foundIndex >= 0) {
			//update current role item info
			setDataSource(
				update(dataSource, {
					[foundIndex]: {
						$set: item,
					},
				})
			);
		} else {
			//append new item to list
			setDataSource(
				update(dataSource, {
					$unshift: [item],
				})
			);
		}
	};

	//Table columns
	const columns: TableColumnsType<PromotionModel> = [
		{
			title: t("promotion:code"),
			key: "code",
			align: "center",
			width: 80,
			render: (id, record) => {
				return (
					<TableLinkText
						title={t("")}
						// onClick={() => onEdit(record)}
					>
						{id}
					</TableLinkText>
				);
			},
		},
		{
			title: t("promotion:name"),
			key: "name",
			width: 250,
		},
		{
			title: t("promotion:group"),
			width: 100,
			key: "group",
			render: (group: string) => {
				let groupInfo = PromotionModel.getGroup(group);
				return <span>{groupInfo?.label}</span>;
			},
		},
		{
			title: t("promotion:type"),
			width: 160,
			key: "type",
			render: (type: number) => {
				let groupInfo = PromotionModel.getType(type);
				return <span>{groupInfo?.label}</span>;
			},
		},

		{
			title:
				t("worktrackingrange:time_start") +
				" - " +
				t("worktrackingrange:time_end"),
			dataIndex: "time_start",
			width: 150,
			key: "date_start",
			render: (text: string, record: PromotionModel) => (
				<span>
					<TextDateTime
						format={
							dayjs
								.unix(record.start_date)
								.isSame(dayjs.unix(record.end_date), "year")
								? "DD/MM"
								: undefined
						}
						ts={record.start_date}
					/>{" "}
					-
					<TextDateTime ts={record.end_date} />
				</span>
			),
		},
		// {
		//   title: t("promotion:form.section_store_title"),
		//   width: 100,
		//   key: "store_apply",
		//   toggletype: "extend",
		//   render: (_: any, record: PromotionModel) => {
		//     return (
		//       <Tooltip title={t("promotion:form.store")} mouseEnterDelay={0.4}>
		//         <Tag
		//           icon={<IconPencil size={14} />}
		//           className="opacity-50 cursor-pointer hover:opacity-100"
		//           onClick={() => {
		//             setEditPromotionStoreVisible(true);
		//             setEditingPromotionModel(record);
		//           }}
		//         >
		//           ...
		//         </Tag>
		//       </Tooltip>
		//     );
		//   },
		// },
		{
			title: t("common:status"),
			key: "status",
			render: (status: boolean) => {
				return (
					<div>
						{status ? (
							<Tag color="green">Đang áp dụng</Tag>
						) : (
							<Tag color="yellow">Chưa áp dụng</Tag>
						)}
					</div>
				);
			},
			width: 120,
		},
		{
			title: t("Thiết lập nhóm hàng"),
			key: "type",

			width: 120,
			render: (_: any, record: PromotionModel) => {
				return (
					<div>
						<Tag
							color="blue"
							icon={<IconSettings size={14} />}
							className=" ml-1 cursor-pointer"
							onClick={() => {
								setEditPromotionProductVisible(true);
								setEditingPromotionModel(record);
							}}>
							Thiết lập
						</Tag>
					</div>
				);
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 150,
			render: (_: any, record: PromotionModel) => (
				<RoleCheck roles={[Role.PROMOTION_MANAGE]} hideOnFail>
					{/* <TableEdit link="" onClick={() => onEdit(record)} /> */}
					<div className="flex justify-end">
						{record.group === "coupon" && (
							<TableEdit
								label="Thiết lập mã"
								link={`/promotion/edit/id/${record.id}`}></TableEdit>
						)}
						<TableInfo record={record}></TableInfo>
					</div>
				</RoleCheck>
			),
		},
	];

	const onEdit = (promotion: PromotionModel) => {
		setEditingPromotionModel(promotion);
		setEditPromotionVisible(true);
	};

	return (
		<>
			<PromotionListHeader>
				{/* <RoleCheck roles={[Role.PROMOTION_MANAGE]}>
          <PageHeaderButton
            type="primary"
            link=""
            onClick={() => {
              setFormSelectGroupVisible(true);
            }}
          >
            {t("promotion:add_button")}
          </PageHeaderButton>
        </RoleCheck> */}
			</PromotionListHeader>

			<PromotionListFilter
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>

			<PageDataTable<FilterPromotion, PromotionModel, PromotionCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "promotion",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			{/* {editPromotionStoreVisible ? (
        <PromotionSettingStoreModal
          model={editingPromotionModel}
          open={editPromotionStoreVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPromotionModel(
                new PromotionModel(PromotionModel.getDefaultData())
              );
            }
            setEditPromotionStoreVisible(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      ) : null}

      {editPromotionCouponVisible ? (
        <PromotionCouponModal
          model={editingPromotionModel}
          open={editPromotionCouponVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPromotionModel(
                new PromotionModel(PromotionModel.getDefaultData())
              );
            }
            setEditPromotionCouponVisible(isOpen);
          }}
        />
      ) : null} */}

			{editPromotionProductVisible ? (
				<PromotionProductModal
					model={editingPromotionModel}
					open={editPromotionProductVisible}
					onUpdate={() => {
						fetchData();
					}}
					setOpen={(isOpen) => {
						//clear editing id when close
						if (!isOpen) {
							setEditingPromotionModel(
								new PromotionModel(PromotionModel.getDefaultData())
							);
						}
						setEditPromotionProductVisible(isOpen);
					}}
				/>
			) : null}

			{/* <PromotionFormSelectGroupModal
        key={"cashflow-form-select-type-modal"}
        open={formSelectGroupVisible}
        setOpen={(isOpen) => {
          setFormSelectGroupVisible(isOpen);
        }}
        setGroup={(group) => {
          setGroup(group);
          setEditPromotionVisible(true);
        }}
      /> */}

			{/* {editPromotionVisible ? (
        <PromotionFormModal
          id={editingPromotionModel.id}
          open={editPromotionVisible}
          group={group}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPromotionModel(
                new PromotionModel(PromotionModel.getDefaultData())
              );
            }
            setEditPromotionVisible(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      ) : null} */}
		</>
	);
};

export default PromotionList;
