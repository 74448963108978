import {
  Alert,
  App,
  Button,
  Form,
  Popconfirm,
  Space,
  Table,
  Tooltip
} from "antd";
import WorkTrackingRange from "common/constants/WorkTrackingRange";
import WorkTrackingRangeModel from "common/models/WorkTrackingRangeModel";
import WorkTrackingRangeRepository from "common/repositories/WorkTrackingRangeRepository";
import {
  WorkTrackingRangeColorJson,
  WorkTrackingRangeJsonAddEdit
} from "common/types/WorkTrackingRange";
import CustomColorPicker from "components/color/CustomColorPicker";
import Error from "components/LayoutError";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import dayjs from "dayjs";
import WorkTrackingRangeFormTableCell from "features/worktrackingrange/form/WorkTrackingRangeFormTableCell";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconAlertTriangle, IconSquarePlus, IconX } from "@tabler/icons-react";

const TIME_FORMAT = "HH:mm";

const WorkTrackingRangeList = ({
  dataSource,
  handleDelete,
  onSaveSuccess
}: {
  dataSource: WorkTrackingRangeModel[];
  handleDelete: (id: number) => void;
  onSaveSuccess: (item: WorkTrackingRangeModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const ADD_OPTION_KEY = 9000000;
  const [showAdd, setShowAdd] = useState(false);
  const [editingKey, setEditingKey] = useState<string | number>("");

  const [colorHex, setColorHex] = useState<WorkTrackingRangeColorJson[]>([]);
  const [model, setModel] = useState<WorkTrackingRangeModel>(
    new WorkTrackingRangeModel(WorkTrackingRangeModel.getDefaultData())
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const isEditing = (record: WorkTrackingRangeJsonAddEdit) =>
    record.key === editingKey;

  const doPrepareData = useCallback(
    (formData: any) => {
      let id = model.id !== ADD_OPTION_KEY ? model.id : 0;

      const submitData: WorkTrackingRangeJsonAddEdit = {
        ...WorkTrackingRangeModel.getDefaultData(),
        company_id: model.company_id,
        id: id,
        name: formData.name,
        ratio: formData.ratio,
        time_start: `${dayjs(formData.time_picker[0]).format(TIME_FORMAT)}`,
        time_end: `${dayjs(formData.time_picker[1]).format(TIME_FORMAT)}`,
        ignore_duration: formData.ignore_duration,
        display_order: formData.display_order,
        status: WorkTrackingRange.STATUS_ENABLE
      };

      if (typeof formData.color === "string") {
        // type string
        submitData.color = formData.color;
      } else {
        // type object color
        submitData.color = formData.color.toHexString();
      }

      return submitData;
    },
    [model, ADD_OPTION_KEY]
  );

  const initialValues = useMemo(() => {
    return new WorkTrackingRangeModel({
      ...WorkTrackingRangeModel.getDefaultData(),
      color: "df0000",
      time_picker: [dayjs(), dayjs()],
      status: WorkTrackingRange.STATUS_ENABLE,
      id: ADD_OPTION_KEY
    });
  }, [ADD_OPTION_KEY]);

  const onSubmit = async (formData: any) => {
    const submitData = doPrepareData(formData);

    try {
      setIsProcessing(true);
      message.loading({
        content: t("common:form.processing"),
        key: "message",
        duration: 0
      });

      const item: WorkTrackingRangeModel =
        await new WorkTrackingRangeRepository().saveRemote(submitData);

      setIsProcessing(false);

      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="worktrackingrange:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4
        });
      } else {
        message.success({
          content: t("worktrackingrange:form.success.update"),
          className: "message_success",
          key: "message",
          duration: 2
        });

        //update success
        setEditingKey("");
        if (showAdd) {
          setShowAdd(false);
        }

        onSaveSuccess(item);
        setModel(
          new WorkTrackingRangeModel(WorkTrackingRangeModel.getDefaultData())
        );
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: t("worktrackingrange:name"),
      dataIndex: "name",
      editable: true,
      width: 250,
      require: true,
      inputType: "input"
    },
    {
      title: t("worktrackingrange:color"),
      dataIndex: "color",
      inputType: "color_picker",
      editable: true,
      require: true,
      width: 130,
      render: (_: any, record: WorkTrackingRangeModel) => {
        let findColor = colorHex.find((i) => i.id === record.id);
        return (
          <>
            <Space>
              <CustomColorPicker
                disabled
                format="hex"
                value={findColor?.color}
              />
              <span>{findColor?.color}</span>
            </Space>
          </>
        );
      }
    },
    {
      title:
        t("worktrackingrange:time_start") +
        " - " +
        t("worktrackingrange:time_end"),
      dataIndex: "time_start",
      editable: true,
      require: true,
      width: 180,
      inputType: "time_picker",
      render: (text: string, record: WorkTrackingRangeModel) => (
        <span>
          {record.time_start} - {record.time_end}
        </span>
      )
    },
    {
      title: t("worktrackingrange:ratio"),
      dataIndex: "ratio",
      editable: true,
      require: true,
      inputType: "input",
      align: "center" as "center",
      width: 100,
      render: (ratio: number) => {
        return (
          <span className={ratio === 0 ? "text-red-500" : ""}>
            {ratio.toFixed(2)}{" "}
            {ratio === 0 ? (
              <>
                <Tooltip title={t("worktrackingrange:warning_zero_ratio")}>
                  <IconAlertTriangle
                    className="text-red-500 -mt-0.5"
                    size={16}
                  />
                </Tooltip>
              </>
            ) : null}
          </span>
        );
      }
    },
    {
      title: t("worktrackingrange:ignore_duration"),
      dataIndex: "ignore_duration",
      editable: true,
      require: false,
      inputType: "input",
      width: 100
    },
    {
      title: " ",
      dataIndex: "operation",
      width: 100,
      render: (_: any, record: WorkTrackingRangeModel) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => form.submit()}
              loading={isProcessing}
              style={{ marginRight: 8 }}
              type="primary"
              size="small">
              {t("common:table.save")}
            </Button>

            <Popconfirm
              title={t("common:table.confirm_cancel_editing_title")}
              onConfirm={cancel}
              okText={t("common:table.confirm_ok")}
              cancelText={t("common:table.confirm_cancel")}
              disabled={isProcessing}
              placement="topRight">
              <Button
                type="default"
                size="small"
                icon={<IconX size={14} color="red" />}
                disabled={isProcessing}></Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <TableEdit key="edit" link="" onClick={() => edit(record)} />
            <TableDelete
              error_translate_prefix="worktrackingrange:form.error"
              onDeleteCallback={(id) => {
                handleDelete(id);
              }}
              repository={new WorkTrackingRangeRepository()}
              id={record.id}
            />
          </span>
        );
      }
    }
  ];

  const edit = (record: WorkTrackingRangeModel) => {
    setModel(record);
    form.setFieldsValue({
      ...record,
      time_picker: [
        dayjs(record.time_start, TIME_FORMAT),
        dayjs(record.time_end, TIME_FORMAT)
      ]
    });

    setEditingKey(record.key);
  };

  const cancel = () => {
    if (editingKey === ADD_OPTION_KEY) {
      setShowAdd(false);
    }

    setModel(
      new WorkTrackingRangeModel(WorkTrackingRangeModel.getDefaultData())
    );

    setEditingKey("");
  };

  const toggleAddMode = useCallback(() => {
    if (showAdd) {
      setEditingKey("");
      setModel(
        new WorkTrackingRangeModel(WorkTrackingRangeModel.getDefaultData())
      );
    } else {
      setModel(initialValues);
      setEditingKey(ADD_OPTION_KEY);
    }

    setShowAdd(!showAdd);
  }, [ADD_OPTION_KEY, showAdd, initialValues]);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: WorkTrackingRangeJsonAddEdit) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        require: col.require,
        inputType: col.inputType,
        editing: isEditing(record)
      })
    };
  });

  const mergeDataSource = [
    ...dataSource,
    //append fake model for inline ADD
    ...(model.key === ADD_OPTION_KEY ? [model] : [])
  ];

  const mappingSourceToColorHex = useCallback(() => {
    let colorHexList: WorkTrackingRangeColorJson[] = [];
    dataSource.map((i) => {
      return colorHexList.push({ id: i.id, color: i.color });
    });
    setColorHex(colorHexList);
  }, [dataSource]);

  useEffect(() => {
    //reset
    form.setFieldsValue(initialValues);
  }, [form, initialValues, showAdd]);

  useEffect(() => {
    mappingSourceToColorHex();
  }, [dataSource, mappingSourceToColorHex]);

  return (
    <div>
      <Form
        form={form}
        component={false}
        initialValues={initialValues}
        onFinish={onSubmit}>
        <Table
          components={{
            body: {
              cell: WorkTrackingRangeFormTableCell
            }
          }}
          size="small"
          className="table-dashboard"
          dataSource={mergeDataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          bordered
          pagination={{ hideOnSinglePage: true, pageSize: 500 }}
          footer={() => (
            <div>
              {editingKey !== ADD_OPTION_KEY ? (
                <Button
                  disabled={
                    editingKey.toString().length > 0 &&
                    editingKey !== ADD_OPTION_KEY
                  }
                  size="small"
                  type="primary"
                  onClick={() => toggleAddMode()}
                  icon={<IconSquarePlus size="16" />}>
                  {t("worktrackingrange:form.add")}
                </Button>
              ) : null}
            </div>
          )}
        />
      </Form>
      <Alert
        style={{ marginTop: "10px" }}
        message={t("worktrackingrange:ignore_duration_help")}
        type="warning"
      />
    </div>
  );
};

export default WorkTrackingRangeList;
