import React from "react";

const DataGridHeader = ({
  height,
  children
}: {
  height: number;
  children: React.ReactNode;
}) => {
  return (
    <div className="border-b-2 border-gray-200  bg-gray-50" style={{ height }}>
      {children}
    </div>
  );
};

export default DataGridHeader;
