import { Button, Divider, Form, Input, Modal, Table } from "antd";
import Customer from "common/constants/Customer";
import CustomerModel from "common/models/CustomerModel";
import PosCartModel from "common/models/PosCartModel";
import CustomerRepository from "common/repositories/CustomerRepository";
import { CustomerTypeJson } from "common/types/CustomerType";
import { PosCartCustomer } from "common/types/PosCart";
import { TableColumnsType } from "common/types/Table";
import TextCustomerType from "components/TextCustomerType";
import TextRegion from "components/TextRegion";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useState } from "react";
import validator from "validator";

import { IconChevronsLeft } from "@tabler/icons-react";

import PosRightCustomerAdd from "./PosRightCustomerAdd";

const PosRightCustomerModal = ({
  activeCustomer,
  show,
  setShow,
  onSelect,
}: {
  activeCustomer: PosCartCustomer;
  show: boolean;
  setShow: Function;
  onSelect: (v: PosCartCustomer) => void;
}) => {
  const [form] = Form.useForm();
  const [customerTypeItems] =
    useDatabaseTable<CustomerTypeJson>("customertype");

  const toggleActive = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [foundCustomers, setFoundCustomers] = useState<CustomerModel[]>([]);
  const [showAdd, setShowAdd] = useState(true);

  const resetForm = useCallback(() => {
    setFoundCustomers([]);
    setSearchKeyword("");
  }, []);

  const showFormAdd = useCallback(() => {
    setShowAdd(true);

    const checkKeywordIsPhone = validator.isMobilePhone(searchKeyword);
    const checkKeywordIsEmail = validator.isEmail(searchKeyword);
    const checkKeywordIsCode =
      validator.isUUID(searchKeyword) || validator.isInt(searchKeyword);

    form.setFieldsValue({
      full_name:
        !checkKeywordIsPhone && !checkKeywordIsEmail && !checkKeywordIsCode
          ? searchKeyword
          : "",
      phone: checkKeywordIsPhone ? searchKeyword : "",
      email: checkKeywordIsEmail ? searchKeyword : "",
      code: checkKeywordIsCode ? searchKeyword : "",
      gender: Customer.GENDER_UNKNOWN,
      customer_type_id:
        customerTypeItems.length > 0 ? customerTypeItems[0].id : null,
    });
  }, [setShowAdd, form, searchKeyword, customerTypeItems]);

  useEffect(() => {
    setShowAdd(false);
    resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    setFoundCustomers([]);
  }, [searchKeyword]);

  const doSearch = async (e: any) => {
    setLoading(true);
    setFoundCustomers([]);
    let collection = await new CustomerRepository().getItems({
      filters: {
        ...CustomerRepository.getDefaultFilters(),
        page: 1,
        limit: 10,
        keyword: searchKeyword,
      },
    });
    setLoading(false);
    setFoundCustomers(collection.items);
  };

  const onSelectCustomer = useCallback(
    (item: CustomerModel) => {
      const cartCustomer: PosCartCustomer = {
        id: item.id,
        full_name: item.full_name,
        email: item.email,
        phone: item.phone,
        code: item.code,
        gender: item.gender,
        birthday: item.birthday,
        address: item.address,
        region_id: item.region_id,
        sub_region_id: item.sub_region_id,
        sub_sub_region_id: item.sub_sub_region_id,
        is_new: false,
        count_order: item.count_order,
        point: item.point,
        customer_type_id: item.customer_type_id,
      };
      onSelect(cartCustomer);

      //clear data
      resetForm();

      setShow(false);
    },
    [setShow, onSelect, resetForm]
  );

  const onCustomerAddFormSubmit = useCallback(
    (addFormData: any) => {
      onSelect({
        ...PosCartModel.getDefaultCartCustomer(),
        full_name: addFormData.full_name,
        email: addFormData.email,
        phone: addFormData.phone,
        gender: addFormData.gender,
        customer_type_id: addFormData.customer_type_id,
        address: addFormData.address,
        region_id: addFormData.regions?.region_id || 0,
        sub_region_id: addFormData.regions?.sub_region_id || 0,
        sub_sub_region_id: addFormData.regions?.sub_sub_region_id || 0,
        birthday: addFormData.birthday,
        is_new: true,
      });
      form.resetFields();

      setShow(false);
    },
    [onSelect, setShow, form]
  );

  const columns: TableColumnsType<CustomerModel> = [
    {
      title: "Họ & Tên",
      dataIndex: "full_name",
    },
    {
      title: "Tỉnh / TP",
      dataIndex: "address",
      render: (_, item: CustomerModel) => (
        <>
          <TextRegion id={item.region_id} />
        </>
      ),
    },
    {
      title: "Điện thoại",
      dataIndex: "phone",
    },
    {
      title: "Nhóm",
      dataIndex: "customer_type_id",
      render: (customer_type_id: number) => (
        <TextCustomerType id={customer_type_id} />
      ),
    },
    {
      title: "Điểm tích lũy",
      dataIndex: "point",
      align: "right",
    },
    {
      title: "Đơn hàng",
      dataIndex: "count_order",
      align: "right",
    },
    {
      title: " ",
      align: "right",
      width: 70,
      render: (_, item: CustomerModel) => (
        <Button
          type={item.id === activeCustomer.id ? "default" : "primary"}
          onClick={() => onSelectCustomer(item)}
        >
          Chọn
        </Button>
      ),
    },
  ];

  let modalOptions = {};
  if (showAdd) {
    modalOptions = {
      footer: [
        <Button key="back" onClick={() => setShowAdd(false)}>
          Quay Lại
        </Button>,
        <Button
          key="primary"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Lưu khách hàng & tiếp tục
        </Button>,
      ],
    };
  } else {
    modalOptions = {
      width: 980,
      footer: null,
    };
  }

  return (
    <Modal
      {...modalOptions}
      maskClosable={false}
      destroyOnClose
      style={{ top: 50 }}
      width={920}
      open={show}
      onCancel={toggleActive}
      title={
        showAdd ? (
          <>
            <Button
              type="primary"
              ghost
              icon={<IconChevronsLeft className="-mt-0.5 mr-1" />}
              shape="round"
              className="mr-2"
              onClick={() => setShowAdd(false)}
            >
              Tìm kiếm
            </Button>
            Thêm khách hàng mới
          </>
        ) : (
          "Tìm kiếm khách hàng"
        )
      }
    >
      {showAdd ? null : (
        <Input.Search
          allowClear
          loading={loading}
          size="large"
          placeholder="Tìm theo Tên, Điện thoại, Email, Mã khách hàng..."
          value={searchKeyword}
          autoFocus={true}
          onPressEnter={doSearch}
          onChange={(e) => setSearchKeyword(e.target.value)}
          enterButton="Tìm khách hàng"
          onSearch={doSearch}
          disabled={showAdd}
        />
      )}

      {!showAdd && searchKeyword.length > 0 ? (
        <div>
          <Divider orientation="left">
            Kết quả tìm kiếm ({foundCustomers.length})
          </Divider>
          {foundCustomers.length > 0 ? (
            <div>
              <Table
                rowKey="id"
                size="small"
                pagination={{ hideOnSinglePage: true }}
                columns={columns}
                dataSource={foundCustomers}
              />
            </div>
          ) : (
            <>Không tìm thấy khách hàng thỏa điều kiện tìm kiếm</>
          )}
        </div>
      ) : null}

      {showAdd ? null : (
        <>
          <Divider orientation="left">Thêm khách hàng</Divider>
          <div>
            <Button shape="round" type="primary" onClick={() => showFormAdd()}>
              Nhấn vào đây
            </Button>{" "}
            để thêm khách hàng mới.
          </div>
        </>
      )}

      <div className={showAdd ? "block" : "hidden"}>
        <PosRightCustomerAdd
          form={form}
          onFormSubmit={onCustomerAddFormSubmit}
        />
      </div>
    </Modal>
  );
};

export default PosRightCustomerModal;
