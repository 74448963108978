import { App, Button, Col, Divider, Form, Input, Row } from "antd";
import ApiKey from "common/constants/ApiKey";
import ApiKeyModel from "common/models/ApiKeyModel";
import ApiKeyRepository from "common/repositories/ApiKeyRepository";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { ApiKeyJsonAddEdit } from "common/types/ApiKey";

const ApiKeyForm = ({
  id,
  model,
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  id: number;
  model: ApiKeyModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: ApiKeyModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const isEditing = id > 0;
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      description: model.description,
      type: model.type,
      api_key: model.api_key,
      api_secret: model.api_secret,
      status: model.status,
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ApiKeyJsonAddEdit = {
        ...ApiKeyModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        type: ApiKey.TYPE_PRIVATE,
        description: formData.description,
        api_key: formData.api_key,
        api_secret: formData.api_secret,
        status: formData.status || ApiKey.STATUS_ENABLE,
      };

      return submitData;
    },
    [model.id],
  );

  const onSubmitChangeSecret = async () => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    let item: ApiKeyModel = await new ApiKeyRepository().changeSecretItem(
      model.id,
      model.status,
    );
    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="apikey:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("apikey:form.success.update_key"),
        className: "message_success",
        key: "message",
        duration: 2,
      });

      form.setFieldsValue({
        api_key: item.api_key,
        api_secret: item.api_secret,
      });
    }
  };

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    let item: ApiKeyModel = await new ApiKeyRepository().saveRemote(
      doPrepareData(formData),
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="apikey:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id="apikey-form">
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("apikey:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("apikey:form.error.error_name_is_required"),
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("apikey:description")}
            name="description"
            className={`${!isEditing ? "mb-0" : ""}`}>
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Row
        hidden={
          !isEditing || model.status === ApiKey.STATUS_LOCKED ? true : false
        }
        gutter={16}>
        <Col md={12} xs={24}>
          <FormSelect
            label={t("common:status")}
            name="status"
            options={ApiKeyModel.getStatusList().filter(
              (item) => item.value !== ApiKey.STATUS_LOCKED,
            )}
            className="mb-0"
          />
        </Col>
      </Row>
      {isEditing ? <Divider /> : null}
      <Row gutter={16} hidden={!isEditing}>
        <Col md={24} xs={24}>
          <Form.Item label={t("apikey:api_key")} name="api_key">
            <Input readOnly={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} hidden={!isEditing}>
        <Col md={24} xs={24}>
          <Form.Item label={t("apikey:api_secret")} name="api_secret">
            <Input.Password readOnly={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!isEditing}>
        <Col md={24} xs={24}>
          <Button danger onClick={onSubmitChangeSecret}>
            {t("apikey:change_secret")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ApiKeyForm;
