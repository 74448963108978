import CashflowReceipt from 'common/constants/CashflowReceipt';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';
import FormSelect from 'components/form/FormSelect';
import { useEffect, useMemo, useState } from 'react';

import CashflowReceiptTargetCustomer from './CashflowReceiptTargetCustomer';
import CashflowReceiptTargetEmployee from './CashflowReceiptTargetEmployee';
import CashflowReceiptTargetSupplier from './CashflowReceiptTargetSupplier';

const CashflowReceiptTargetSelect = ({
  label,
  model,
  targetId,
  setTargetId,
  disabled,
}: {
  label: string;
  model: CashflowReceiptModel;
  targetId: number;
  setTargetId: (v: number) => void;
  disabled: boolean;
}) => {
  const [target, setTarget] = useState(model.target);


  const detailCom = useMemo(() => {
    let com = null;
    switch (target) {
      case CashflowReceipt.TARGET_EMPLOYEE:
        com = (
          <CashflowReceiptTargetEmployee
            id={targetId}
            setId={setTargetId}
            disabled={disabled}
          />
        );
        break;
      case CashflowReceipt.TARGET_CUSTOMER:
          console.log("hehe")
        com = (
          <CashflowReceiptTargetCustomer
            id={targetId}
            setId={setTargetId}
            disabled={disabled}
          />
        );
        break;
      case CashflowReceipt.TARGET_SUPPLIER:
        com = (
          <CashflowReceiptTargetSupplier
            id={targetId}
            setId={setTargetId}
            disabled={disabled}
          />
        );
        break;
      case CashflowReceipt.TARGET_OTHER:
        com = <p>Khác</p>
        break;
    }

    return com;
  }, [target, targetId, setTargetId, disabled]);



  useEffect(()=>{
    setTarget(model.target)
  },[model])

  return (
    <>
      <FormSelect
        required
        className="mb-0 w-1/2"
        disabled={disabled}
        label={label}
        name="target"
        options={CashflowReceiptModel.getTargetList()}
        onChange={(value) => {
          if (typeof value !== "undefined") {
            setTarget(+value);
            setTargetId(0);
          }
        }}
      />
      <div className="pt-2">{detailCom}</div>
    </>
  );
};

export default CashflowReceiptTargetSelect;
