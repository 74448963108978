import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DmsPostFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:group_delivery"),
      link: "",
    },
    {
      text: t("sitemenu:delivery_portal"),
      link: "/dmspost",
    },
    {
      text: t("sitemenu:delivery_portal_post"),
      link: "/dmspost",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(isEditing ? "dmspost:heading_edit" : "dmspost:heading_add")}
    ></PageHeader>
  );
};

export default DmsPostFormHeader;
