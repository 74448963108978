import BaseCollection from 'common/collections/BaseCollection';
import EcomChannelOrderModel from 'common/models/EcomChannelOrderModel';
import {
    EcomChannelOrderCollectionJson, EcomChannelOrderJson
} from 'common/types/EcomChannelOrder';

class EcomChannelOrderCollection extends BaseCollection<
  EcomChannelOrderJson,
  EcomChannelOrderModel,
  EcomChannelOrderCollectionJson
> {
  itemsFromJson(jsonItems: EcomChannelOrderJson[]): EcomChannelOrderModel[] {
    return jsonItems.map((item) => new EcomChannelOrderModel(item));
  }
}

export default EcomChannelOrderCollection;
