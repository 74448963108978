import { Button, Popover } from "antd";
import TextEmployee from "components/TextEmployee";
import SelectEmployee from "features/employee/SelectEmployee";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconCircleCheck, IconUser } from "@tabler/icons-react";

const CashflowReceiptTargetEmployee = ({
  id,
  setId,
  disabled,
}: {
  id: number;
  setId: (v: number) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  const employeePopover = useMemo(() => {
    return (
      <>
        <div className="mb-2">{t("cashflowreceipt:TARGET_EMPLOYEE")}: </div>
        <SelectEmployee
          multiple={false}
          width={240}
          placeholder={t("cashflowreceipt:target.employee_placeholder")}
          onChange={(value) => (value.length > 0 ? setId(value[0]) : null)}
          icon={<IconUser size="12" />}
        />
      </>
    );
  }, [setId, t]);
  return (
    <>
      {id > 0 ? (
        <>
          <IconCircleCheck size={16} className="-mt-0.5 mr-1 text-green-500" />
          <span className="">
            <TextEmployee user_id={id} />
          </span>
          {!disabled ? (
            <Button danger type="text" onClick={() => setId(0)}>
              {t("cashflowreceipt:target.clear")}
            </Button>
          ) : null}
        </>
      ) : (
        <>
          {t("cashflowreceipt:target.employee_empty")}{" "}
          {!disabled ? (
            <Popover
              content={employeePopover}
              trigger="click"
              placement="right"
              defaultOpen
            >
              <Button type="link" size="small">
                {t("cashflowreceipt:target.select")}
              </Button>
            </Popover>
          ) : null}
        </>
      )}
    </>
  );
};

export default CashflowReceiptTargetEmployee;
