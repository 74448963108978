import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import RoleCheck from "components/RoleCheck";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";

const TaskCurrentListHeader = ({ employeeName }: { employeeName: string }) => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("Công việc của") + " " + employeeName}
      siteMenuOpenKey="currentTask"
      siteMenuSelectedKey="/task/current"
    ></PageHeader>
  );
};

export default TaskCurrentListHeader;
