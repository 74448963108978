import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
} from "antd";
import Promotion from "common/constants/Promotion";
import PromotionModel from "common/models/PromotionModel";
import FormSelect from "components/form/FormSelect";
import MoneyInput from "components/MoneyInput";
import React from "react";
import { useTranslation } from "react-i18next";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconX } from "@tabler/icons-react";
import { OrderProductJson } from "common/types/Delivery";
const { RangePicker } = DatePicker;

const DeliveryInternalOrderDetail = ({
	isEditing,
	order_detail,
	resourceType,
}: {
	isEditing: boolean;
	order_detail: OrderProductJson[];
	resourceType: number;
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Form.List
				name="order_detail"
				initialValue={order_detail}
				// initialValue={[
				//   {
				//     from: "2",
				//     to: "2023-03-22T08:28:52.356Z",
				//     positionn: "w",
				//   },
				// ]}
			>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<div key={key}>
								<Row gutter={16}>
									<Col md={8} xs={24}>
										<Form.Item
											{...restField}
											label={
												<span className="flex items-center">
													{t("Mã sản phẩm ")}
													{name + 1}
													<IconX
														onClick={() => remove(name)}
														className="p-1 cursor-pointer hover:text-red-500"
													/>
												</span>
											}
											name={[name, "product_sku"]}
											rules={[
												{
													required: resourceType === 2 ? true : false,
													message: t("Bạn chưa nhập thông tin"),
												},
											]}>
											<Input />
										</Form.Item>
									</Col>

									<Col md={16} xs={24}>
										<Form.Item
											{...restField}
											label={
												<span>
													{t("Tên sản phẩm ")}
													{name + 1}
												</span>
											}
											name={[name, "product_name"]}
											rules={[
												{
													required: resourceType === 2 ? true : false,
													message: t("Bạn chưa nhập thông tin"),
												},
											]}>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									{/* <Col md={16} xs={24}>
                    <Form.Item
                      {...restField}
                      label={
                        <span>
                          {t("Biến thể / Dung tích ")}
                          {name + 1}
                        </span>
                      }
                      name={[name, "variant_name"]}
                      rules={[
                        {
                          required: resourceType === 2 ? true : false,
                          message: t("Bạn chưa nhập thông tin"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}

									<Col md={12} xs={24}>
										<Form.Item
											{...restField}
											style={{
												display: "flex",
												//   flexDirection: "row-reverse",
												justifyContent: "space-between",
											}}
											label={
												<span>
													{t("Số lượng ")}
													{/* {name + 1} */}
												</span>
											}
											name={[name, "product_quantity"]}
											rules={[
												{
													required: resourceType === 2 ? true : false,
													message: t("Bạn chưa nhập thông tin"),
												},
											]}>
											<Input />
										</Form.Item>
									</Col>
									<Col md={12} xs={24}>
										<Form.Item
											{...restField}
											style={{
												display: "flex",
												//   flexDirection: "row-reverse",
												justifyContent: "space-between",
											}}
											label={
												<span>
													{t("Thành tiền ")}
													{/* {name + 1} */}
												</span>
											}
											name={[name, "price"]}
											rules={[
												{
													required: resourceType === 2 ? true : false,
													message: t("Bạn chưa nhập thông tin"),
												},
											]}>
											<Input />
										</Form.Item>
									</Col>
								</Row>
							</div>
						))}
						{fields.length < 20 ? (
							<Row gutter={16}>
								<Col md={11}>
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => add()}
											block
											icon={<PlusOutlined />}>
											Thêm sản phẩm
										</Button>
									</Form.Item>
								</Col>
							</Row>
						) : null}
					</>
				)}
			</Form.List>
		</>
	);
};

export default DeliveryInternalOrderDetail;
