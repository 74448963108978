import EcomModel from 'common/models/EcomModel';
import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EcomLogListHeader = ({
  children,
  channel_id,
  model,
}: {
  children?: React.ReactNode;
  channel_id: number;
  model?: EcomModel;
}) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("ecom:title"),
      link: "/ecom",
    },
    {
      text: typeof model !== "undefined" ? model.title : "",
    },
  ];

  return (
    <PageHeader
      heading={
        t("ecomlog:heading_list") +
        (typeof model !== "undefined" ? " [" + model.title + "]" : "...")
      }
      nav={nav}
      siteMenuHidden
    >
      {children}
    </PageHeader>
  );
};

export default EcomLogListHeader;
