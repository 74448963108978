import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WebHookListHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("webhook:heading_list")}
      siteMenuSelectedKey="/webhook"
    >
      {children}
    </PageHeader>
  );
};

export default WebHookListHeader;
