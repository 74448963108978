import WorkTrackingRangeApi from "common/api/WorkTrackingRangeApi";
import WorkTrackingRangeCollection from "common/collections/WorkTrackingRangeCollection";
import WorkTrackingRangeModel from "common/models/WorkTrackingRangeModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  WorkTrackingRangeJsonAddEdit,
  FilterWorkTrackingRange,
} from "common/types/WorkTrackingRange";

class WorkTrackingRangeRepository extends BaseRepository<WorkTrackingRangeApi> {
  _api: WorkTrackingRangeApi | null;

  constructor() {
    super();
    this._api = new WorkTrackingRangeApi(true);
  }

  static getDefaultFilters(): FilterWorkTrackingRange {
    return {
      page: 1,
      limit: 50,
      sortby: "name",
      sorttype: "ASC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterWorkTrackingRange>) {
    return this._api
      ? await this._api.getList(props)
      : new WorkTrackingRangeCollection();
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: WorkTrackingRangeJsonAddEdit
  ): Promise<WorkTrackingRangeModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new WorkTrackingRangeModel(
        WorkTrackingRangeModel.getDefaultData()
      );
    }
  }
}

export default WorkTrackingRangeRepository;
