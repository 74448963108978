import React from "react";
import { useTranslation } from "react-i18next";
import TextNumber from "components/TextNumber";
import TextMoney from "components/TextMoney";
import ReportChartNumberChange from "features/report/chart/ReportChartNumberChange";
import { ChartDatum } from "common/types/BiCenterChart";

const ReportChartTimeSeriesTooltip = ({
  lineColors,
  data,
  showChange,
  valueType,
}: {
  lineColors: Record<string, string>;
  data: ChartDatum[];
  showChange: boolean;
  valueType?: "number" | "money";
}) => {
  const { t } = useTranslation();

  return (
    <ul className="report_chart_tooltip_list">
      {data.map((item, index) => {
        return (
          <li className="report_chart_tooltip_list_item" key={item.data.seri}>
            <span
              className="report_chart_tooltip_marker"
              style={{ backgroundColor: lineColors[item.data.seri] }}
            ></span>
            <span>{item.data.label}</span>:
            <span className="report_chart_tooltip_value">
              {typeof valueType !== "undefined" && valueType === "money" ? (
                <TextMoney money={item.data.value} />
              ) : (
                <TextNumber number={item.data.value} />
              )}
            </span>
          </li>
        );
      })}
      {typeof data !== "undefined" && data && data.length > 1 && showChange ? (
        <li className="report_chart_tooltip_list_item">
          <span>{t("report:changed")}:</span>
          <span className="report_chart_tooltip_value">
            <ReportChartNumberChange
              from={data[0].data.value}
              to={data[1].data.value}
              valueType={valueType}
            />
          </span>
        </li>
      ) : null}
    </ul>
  );
};

export default ReportChartTimeSeriesTooltip;
