import { App, Button, Tooltip } from "antd";
import EcomChannelProductRepository from "common/repositories/EcomChannelProductRepository";
import { Variant } from "common/types/EcomChannelProduct";
import Error from "components/Error";
import TextMoney from "components/TextMoney";
import React, { useCallback, useState } from "react";
import Ecom from "common/constants/Ecom";
import { useTranslation } from "react-i18next";
import EcomSyncProductModel from "common/models/EcomSyncProductModel";
import { IconCircleCheck } from "@tabler/icons-react";

const EcomChannelProductVariant = ({
  channel_id,
  ecom_type,
  product_id,
  variants,
  ecomProductCheckList,
  onResfesh,
}: {
  channel_id: number;
  ecom_type: number;
  product_id: number;
  variants: Variant[];
  ecomProductCheckList: EcomSyncProductModel[];
  onResfesh: (action: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const messageKey = "sync_link_sku";

  //sync Order
  const syncByProductSku = useCallback(
    async (channel_id: number, id: number, sku: string) => {
      message.loading({
        content: t("common:form.processing"),
        key: messageKey,
        duration: 3,
      });

      let syncOrderResult =
        await new EcomChannelProductRepository().linkByProductSku(
          channel_id,
          id,
          sku
        );

      if (syncOrderResult.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKey);
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="ecomchannelproduct:form.error"
              items={syncOrderResult.error.errors}
              contentPadding={0}
            />
          ),
          className: "message_error",
          key: messageKey,
          duration: 3,
        });
      } else {
        message.success({
          content: t("ecomchannelproduct:form.success.link_sku_success"),
          className: "message_success",
          key: messageKey,
          duration: 2,
        });
        onResfesh(true);
      }
    },
    [t, messageKey, message, onResfesh]
  );

  return (
    <ul>
      {variants.map((item: Variant) => {
        const foundEcomProductCheck = ecomProductCheckList.find(
          (i) => `${i.channel_variant_id}` === `${item.id}`
        );

        return (
          <li key={item.id} className="flex items-center mb-1 text-xs">
            <div className="bg-gray-200 w-auto px-1.5 h-5 flex justify-center items-center rounded">
              {item.inventoryquantity}
            </div>
            <div className="px-2">
              {item.title} - <TextMoney money={item.price} />
            </div>
            | <div className="px-1">{item.sku}</div>
            <div>
              {typeof foundEcomProductCheck !== "undefined" ? (
                <Tooltip title={t("ecomchannelproduct:productlinked")}>
                  <IconCircleCheck size="16" style={{ color: "#008000" }} />
                </Tooltip>
              ) : (
                <Button
                  type="default"
                  onClick={() => {
                    if (ecom_type === Ecom.TYPE_HARAVAN) {
                      syncByProductSku(channel_id, product_id, item.sku);
                    } else if (ecom_type === Ecom.TYPE_WOO_COMMERCE) {
                      if (ecom_type === Ecom.TYPE_HARAVAN) {
                        syncByProductSku(channel_id, +item.id, item.sku);
                      }
                    }
                  }}
                  size="small"
                  className="text-xs"
                >
                  {t("ecomchannelproduct:link_product_sku")}
                </Button>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default EcomChannelProductVariant;
