import ProductTransmitDetailCollection from "common/collections/ProductTransmitDetailCollection";
import ProductTransmitDetailModel from "common/models/ProductTransmitDetailModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterProductTransmitDetail,
  ProductTransmitDetailJsonAddEdit,
} from "common/types/ProductTransmitDetail";

const SERVICE_URL = "/producttransmitdetails";

class ProductTransmitDetailApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProductTransmitDetail>,
  ): Promise<ProductTransmitDetailCollection> {
    let collection = new ProductTransmitDetailCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          product_transmit_id:
            +filters.product_transmit_id > 0 ? filters.product_transmit_id : -1,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ProductTransmitDetailModel> {
    let item = new ProductTransmitDetailModel(
      ProductTransmitDetailModel.getDefaultData(),
    );

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ProductTransmitDetailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(
    data: ProductTransmitDetailJsonAddEdit,
  ): Promise<ProductTransmitDetailModel> {
    let item = new ProductTransmitDetailModel(
      ProductTransmitDetailModel.getDefaultData(),
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProductTransmitDetailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(
    data: ProductTransmitDetailJsonAddEdit,
  ): Promise<ProductTransmitDetailModel> {
    let item = new ProductTransmitDetailModel(
      ProductTransmitDetailModel.getDefaultData(),
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new ProductTransmitDetailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ProductTransmitDetailApi;
