import { App, Col, Form, Input, InputNumber, Row } from "antd";
import ProductCategory from "common/constants/ProductCategory";
import ProductCategoryModel from "common/models/ProductCategoryModel";
import ProductCategoryRepository from "common/repositories/ProductCategoryRepository";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import ProductCategoryFormSelect from "../ProductCategoryFormSelect";

import type { ProductCategoryJsonAddEdit } from "common/types/ProductCategory";
const ProductCategoryForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: ProductCategoryModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: ProductCategoryModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [getValue, setValue] = useFormDefaultStore((state) => [
    state.getValue,
    state.setValue
  ]);

  const [form] = Form.useForm();
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      parent_id:
        model.parent_id > 0
          ? model.parent_id
          : getValue("productcategory:parent_id"),
      display_order: model.display_order
    };
  }, [model, getValue]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProductCategoryJsonAddEdit = {
        ...ProductCategoryModel.getDefaultData(),
        id: model.id,
        code: model.code,
        name: formData.name,
        parent_id: formData.parent_id,
        display_order: formData.display_order,
        status: formData.status || ProductCategory.STATUS_ENABLE
      };

      setValue("productcategory:parent_id", formData.parent_id);

      return submitData;
    },
    [model.id, model.code, setValue]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ProductCategoryModel =
      await new ProductCategoryRepository().saveRemote(doPrepareData(formData));

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="productcategory:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <ProductCategoryFormSelect
            key={model.id === 0 ? new Date().getTime() : undefined}
            label={t("productcategory:parent_id")}
            placeholder={t("productcategory:form.parent_placeholder")}
            name="parent_id"
            parentId={0}
            ignoreValues={model.id > 0 ? [model.id] : []}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={16} xs={24}>
          <Form.Item
            label={t("productcategory:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("productcategory:form.error.error_name_require")
              }
            ]}
            className="mb-0">
            <Input />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("common:display_order_long")}
            tooltip={t("common:display_order_help")}
            name="display_order"
            className="mb-0">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductCategoryForm;
