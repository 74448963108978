import dayjs from 'dayjs';

const TextDateTimeYmd = ({
  ts,
  format,
  dateFormat,
}: {
  ts: number;
  format?: string;
  dateFormat?: string;
}) => {
  let dateString = ts.toString();
  dateString = ts.toString().length < 8 ? "0" + dateString : dateString;
  let myDate = dayjs(dateString, format);
  return <span>{myDate.format(dateFormat)}</span>;
};

export default TextDateTimeYmd;
