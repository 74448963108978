import ContractTypesList from "features/contractType/list/ContractTypeList";
import usePathParams from "hooks/usePathParams";

const ContractTypesPage = () => {
  const [pathParams] = usePathParams();
  let com = null;
  switch (pathParams.action) {
    default:
      com = <ContractTypesList />;
  }

  return com;
};

export default ContractTypesPage;
