import React from "react";

type Props = {
  size?: "extraLarge" | "large" | "small";
  color?: "main" | "gray";
};

const ChevronDownIcon = ({ size, color = "main" }: Props) => {
  let icon;
  switch (size) {
    case "extraLarge":
      icon = (
        <svg
          width={42}
          height={42}
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.86 15.6625L23.45 27.0725C22.1025 28.42 19.8975 28.42 18.55 27.0725L7.14001 15.6625"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    case "large":
      icon = (
        <svg
          width={18}
          height={18}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9401 6.71252L10.0501 11.6025C9.47256 12.18 8.52756 12.18 7.95006 11.6025L3.06006 6.71252"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    case "small":
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.62 5.22083L7.81667 9.02416C7.36751 9.47333 6.63251 9.47333 6.18334 9.02416L2.38 5.22083"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width={18}
          height={18}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9401 6.71252L10.0501 11.6025C9.47256 12.18 8.52756 12.18 7.95006 11.6025L3.06006 6.71252"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
  return icon;
};

export default ChevronDownIcon;
