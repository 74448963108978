import Role from 'common/constants/Role';
import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import RoleCheck from 'components/RoleCheck';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShipperApplyListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("shipperapply:heading_list")}
      siteMenuSelectedKey="/shipperapply"
    >
      <RoleCheck roles={[Role.ECOM_SHIPPER_APPLY_MANAGE]}>
        <PageHeaderButton link="/shipperapply/add">
          {t("shipperapply:add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default ShipperApplyListHeader;
