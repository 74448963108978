import TagConstant from "common/constants/Tag";
import TagOutSideList from "features/tagproduct/list/TagOutSideList";
import usePathParams from "hooks/usePathParams";

const TagProductPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <TagOutSideList type={TagConstant.RESOURCE_TYPE.PRODUCT} />;
  }

  return com;
};

export default TagProductPage;
