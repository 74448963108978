import { FilterDeliverySumByEmployee } from "common/types/DeliverySumbyEmployee";
import BaseRepository from "./BaseRepository";
import DeliverySumbyEmployeeApi from "common/api/DeliverySumbyEmployeeApi";
import DeliverySumbyEmployeeModel from "common/models/DeliverySumbyEmployeeModel";

class DeliverySumbyEmployeeRepository extends BaseRepository<DeliverySumbyEmployeeApi> {
  _api: DeliverySumbyEmployeeApi | null;

  constructor() {
    super();
    this._api = new DeliverySumbyEmployeeApi(true);
  }

  async getSumByEmployee(
    filters: FilterDeliverySumByEmployee
  ): Promise<DeliverySumbyEmployeeModel> {
    return this._api
      ? await this._api.getSumByEmployee(filters)
      : new DeliverySumbyEmployeeModel(
          DeliverySumbyEmployeeModel.getDefaultData()
        );
  }
}

export default DeliverySumbyEmployeeRepository;
