import FileListIndex from 'features/file/list/FileListIndex';
import usePathParams from 'hooks/usePathParams';

const FilePage = () => {
  const [pathParams] = usePathParams();

  let com = null;
  switch (pathParams.action) {
    default:
      com = <FileListIndex />;
  }

  return com;
};

export default FilePage;
