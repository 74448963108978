import EmployeeModel from 'common/models/EmployeeModel';
import { EmployeeJson } from 'common/types/Employee';
import { SelectOption } from 'common/types/SelectOption';
import { WorkTrackingRangeJson } from 'common/types/WorkTrackingRange';
import create, { StateCreator } from 'zustand';

type WorkScheduleManageStore = {
  office: SelectOption;
  employee: EmployeeJson;
  date_unix: number;
  ranges: WorkTrackingRangeJson[];
  setOffice: (v: SelectOption) => void;
  setEmployee: (v?: EmployeeJson) => void;
  setDateUnix: (v: number) => void;
  setRanges: (v: WorkTrackingRangeJson[]) => void;
};

//init store data
let store: StateCreator<WorkScheduleManageStore> = (set) => ({
  office: { value: 0, label: "" },
  ranges: [],
  employee: EmployeeModel.getDefaultData(),
  date_unix: 0,
  setOffice: (office) => set((state) => ({ ...state, office })),
  setEmployee: (employee) =>
    set((state) => ({
      ...state,
      employee: employee ?? EmployeeModel.getDefaultData(),
    })),
  setDateUnix: (date_unix) => set((state) => ({ ...state, date_unix })),
  setRanges: (ranges) => set((state) => ({ ...state, ranges })),
});

//create store
const useWorkScheduleManageStore = create(store);

export default useWorkScheduleManageStore;
