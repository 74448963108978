const PurchaseOrder = {
  STATUS_DRAFT: 1,
  STATUS_WAIT_FOR_APPROVAL: 3,
  STATUS_APPROVED: 5,
  STATUS_COMPLETED: 11,
  STATUS_CANCELLED: 13,

  CANCEL_REASON_OUT_OF_STOCK: 1,
  CANCEL_REASON_DUPLICATE: 9,
  CANCEL_REASON_INCORRECT_ITEM: 11,
  CANCEL_REASON_HIGH_SHIPPING_FEE: 13,
  CANCEL_REASON_DELIVERY_NOT_ONTIME: 15,
  CANCEL_REASON_CUSTOMER_NOT_SATISFY: 17,
  CANCEL_REASON_SYSTEM_ERROR: 19,
  CANCEL_REASON_PARTNER_ERROR: 21,
  CANCEL_REASON_OTHER: 99,
};

export default PurchaseOrder;
