import { Form, Select, SelectProps, TreeSelect } from "antd";
import { SelectCommonPlacement } from "antd/es/_util/motion";
import { Rule } from "antd/es/form";
import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import ErrorAlert from "../ErrorAlert";

const FormSelectNormal = ({
  value,
  defaultValue,
  onChange,
  isTree,
  name,
  rules,
  options,
  label,
  colon,
  noStyle,
  required,
  className,
  popupClassName,
  allowClear,
  autoFocus,
  disabled,
  loading,
  showSearch,
  size,
  listHeight,
  placeholder,
  suffixIcon,
  dropdownStyle,
  errors,
  errorRetry,
  labelAlign,
  multiple,
  tooltip,
  placement,
  onClear,
  bordered,
  popupMatchSelectWidth,
  status,
  onSearch,
  filterOption,
}: {
  value?: SelectValue;
  defaultValue?: SelectValue;
  onChange?: (v: SelectValue) => void;
  isTree?: boolean;
  name?: string | string[];
  rules?: Rule[];
  options: SelectOptionNormal[] | any;
  label?: React.ReactNode;
  colon?: boolean;
  noStyle?: boolean;
  required?: boolean;
  className?: string;
  popupClassName?: string;
  allowClear?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  loading?: boolean;
  showSearch?: boolean;
  size?: SelectProps["size"];
  listHeight?: number;
  placeholder?: string;
  suffixIcon?: React.ReactNode;
  dropdownStyle?: CSSProperties;
  errors?: string[];
  errorRetry?: () => void;
  labelAlign?: FormLabelAlign;
  multiple?: boolean;
  tooltip?: string;
  placement?: SelectCommonPlacement;
  onClear?: () => void;
  bordered?: boolean;
  popupMatchSelectWidth?: boolean;
  status?: SelectProps["status"];
  onSearch?: (v: string) => void;
  filterOption?: boolean;
}) => {
  const [allOptions, setAllOptions] = useState<SelectOption[]>(options);

  useEffect(() => {
    setAllOptions(options);
  }, [options]);

  const renderer = useCallback(
    (menu: React.ReactNode) => {
      if (Array.isArray(errors) && errors.length > 0) {
        return (
          <ErrorAlert items={errors} translate_prefix="" onRetry={errorRetry} />
        );
      }

      return <>{menu}</>;
    },
    [errors, errorRetry]
  );

  return (
    <Form.Item
      name={name}
      rules={rules}
      noStyle={noStyle}
      label={label}
      required={required}
      className={className}
      labelAlign={labelAlign}
      tooltip={tooltip}
      colon={colon}
    >
      {isTree ? (
        <TreeSelect
          defaultValue={defaultValue}
          showSearch
          loading={loading}
          placeholder={placeholder}
          dropdownRender={renderer}
          style={{ width: "100%" }}
          allowClear
          filterTreeNode={(input: any, item: any) =>
            item.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            item.title
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[đĐ]/g, "d")
              .toLowerCase()
              .indexOf(
                input
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replace(/[đĐ]/g, "d")
                  .toLowerCase()
              ) >= 0
          }
          treeData={allOptions.map((option) => ({
            id: option.value,
            pId: option.parent_id,
            value: option.value,
            title: option.label,
          }))}
          treeDataSimpleMode={true}
          treeDefaultExpandAll={true}
          treeLine={{ showLeafIcon: false }}
          popupMatchSelectWidth={popupMatchSelectWidth}
        ></TreeSelect>
      ) : (
        <Select
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onClear={onClear}
          options={allOptions}
          placement={placement}
          style={{ width: "100%" }}
          allowClear={allowClear}
          autoFocus={autoFocus}
          disabled={disabled}
          loading={loading}
          showSearch={showSearch}
          size={size}
          popupClassName={popupClassName}
          popupMatchSelectWidth={popupMatchSelectWidth}
          listHeight={listHeight}
          dropdownStyle={dropdownStyle}
          suffixIcon={suffixIcon}
          placeholder={placeholder}
          dropdownRender={renderer}
          onSearch={onSearch}
          bordered={bordered}
          optionFilterProp="label"
          mode={typeof multiple !== "undefined" ? "multiple" : undefined}
          status={status}
          filterOption={
            !filterOption
              ? false
              : (input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/[đĐ]/g, "d")
                    .toLowerCase()
                    .indexOf(
                      input
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replace(/[đĐ]/g, "d")
                        .toLowerCase()
                    ) >= 0
          }
        ></Select>
      )}
    </Form.Item>
  );
};
export default FormSelectNormal;
