import { Button, Col, Form, Input, Popconfirm, Row, message } from "antd";
import React, { useCallback } from "react";

import axios from "axios";
import useConvertBase64ToFile from "hooks/useConvertBase64ToFile";
import useGeoLocation from "hooks/useGeoLocation";
import usePathParams from "hooks/usePathParams";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import usePostActionStore from "zustands/usePostActionStore";

import { ActionCode } from "common/types/Delivery";
import AxiosClient from "common/api/AxiosClient";
import BaseApi from "common/api/BaseApi";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import DeliveryModel from "common/models/DeliveryModel";
import Helper from "common/utils/helper";
import MoneyInput from "components/MoneyInput";

import DeliveryWebcam from "./DeliveryWebcam";
import PostAction from "features/delivery/action/PostAction";

////////////////
// type
type NotificationType = "success" | "info" | "warning" | "error";
type Props = {
  action_code: ActionCode;
  GpsRequire?: boolean;
  UpLoadRequire?: boolean;
  noteRequire?: boolean;
  widthImg?: number | string;
  heightImg?: number | string;
  handleSucess?: () => void;
  delivery_id: number;
  noteMoney?: boolean;
  showNote?: boolean;
  showCod?: boolean;
  showCostTransfer?: boolean;
  cost_cod?: number;
  cost_on_transfer?: number;
};
type UploadImg = "UPLOAD_REQUIRE" | "UPLOAD_NO_REQUIRE";

type Gps = "GPS_REQUIRE" | "GPS_NO_REQUIRE";

const DeliveryAccoutantConfirmForm = ({
  action_code,
  GpsRequire = false,
  UpLoadRequire = false,
  noteRequire = false,
  widthImg = 1080,
  heightImg = 768,
  noteMoney = false,
  handleSucess,
  delivery_id,
  showNote = true,
  showCod = false,
  showCostTransfer = false,
  cost_cod = 0,
  cost_on_transfer = 0,
}: Props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { convertBast64ToFile } = useConvertBase64ToFile();
  const { location } = useGeoLocation();

  const [pathParams] = usePathParams();
  const { t } = useTranslation();
  const loginAccount = useLoginAccountStore((state) => state.account);
  const { setReload } = usePostActionStore();
  const [messageApi, contextHolder] = message.useMessage();

  ////////////
  // state
  const [imgFile, setImgFile] = React.useState<any>(null);
  const [img, setImg] = React.useState<string>("");
  const [enableWebcam, setEnableWebcam] = React.useState<any>(true);
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState<boolean>(false);
  ///////////
  //

  const jwtError = "error_jwt_hash_not_found";
  // TYPE_REQUIRE
  const GPS_REQUIRE: string = GpsRequire ? "GPS_REQUIRE" : "GPS_NO_REQUIRE";
  const UPLOAD_REQUIRE: string = UpLoadRequire
    ? "UPLOAD_REQUIRE"
    : "UPLOAD_NO_REQUIRE";

  // Function

  const handleSubmit = useCallback(
    async (value: any) => {
      if (UPLOAD_REQUIRE === "UPLOAD_REQUIRE" && imgFile === null) {
        messageApi.open({
          type: "error",
          content: "Bạn chưa chụp ảnh !",
          duration: 3,
        });
        // messageApi
        //   .open({
        //     type: "loading",
        //     content: "Action in progress..",
        //     duration: 2.5,
        //   })
        //   .then(() => message.success("Loading finished", 2.5))
        //   .then(() => message.info("Loading finished", 2.5));

        return;
      }

      let TYPE_ERROR =
        UpLoadRequire &&
        GpsRequire &&
        location.isError &&
        imgFile === (null || "")
          ? "ERROR_FULL"
          : GpsRequire && location.isError
          ? "NO_LOCATION"
          : UpLoadRequire && imgFile === null
          ? "NO_IMGFILE"
          : "NO_ERROR";

      if (TYPE_ERROR === "NO_ERROR") {
        post(imgFile).then(async (res: any) => {
          try {
            const response: DeliveryModel =
              await new DeliveryRepository().addAction({
                deliveries_id: delivery_id || Number(pathParams.id),
                action_code: action_code,
                image: UPLOAD_REQUIRE === "UPLOAD_REQUIRE" ? res.data.id : 0,
                location_latitude:
                  GPS_REQUIRE === "GPS_REQUIRE" ? location.coordinates.lat : 0,
                location_longitude:
                  GPS_REQUIRE === "GPS_REQUIRE" ? location.coordinates.lng : 0,
                note: value.note || "",
              });
            if (!response.hasError()) {
              setPending(false);
              openNotificationWithIcon(
                "success",
                t("delivery:button_action.notification.success.title"),
                t("delivery:button_action.notification.success.description")
              );
              setReload();
              setIsSuccess(true);
              handleSucess !== undefined && handleSucess();
            } else {
              setPending(false);
              openNotificationWithIcon(
                "error",
                t("delivery:button_action.notification.error.title"),
                response.error.errors[0] === jwtError
                  ? t("delivery:list.item.error.error_jwt_hash_not_found")
                  : t(
                      `delivery:button_action.notification.error.${response.error.errors[0]}`
                    )
              );
              setIsSuccess(false);
            }
          } catch (error: any) {
            setPending(false);
            openNotificationWithIcon(
              "error",
              t("delivery:button_action.notification.error.title"),
              t("delivery:button_action.notification.error.description")
            );
            setIsSuccess(false);
          }
        });
      } else {
        setPending(false);
        openNotificationWithIcon(
          "warning",
          "Có lỗi rồi !",
          t("delivery:button_action.notification.error.description_gps")
        );
        return;
      }
    },
    [imgFile, location]
  );
  const handleChangeImg = (imgBase64: string) => {
    setImg(imgBase64);
    const file = convertBast64ToFile(imgBase64, `image_${pathParams.id}.jpg`);
    setImgFile(file);
  };
  const post = async (file: any): Promise<any> => {
    setPending(true);
    if (UPLOAD_REQUIRE === "UPLOAD_NO_REQUIRE") {
      return new Promise((resolve, reject) => {
        resolve({
          id: 0,
          coordinates: {
            lat: 0,
            lng: 0,
          },
        });
      });
    } else {
      const formData = new FormData();
      const companyId =
        loginAccount.company.id > 0 ? loginAccount.company.id : 0;
      formData.append("company_id", companyId.toString());
      formData.append("creator_id", JSON.stringify(loginAccount.id));
      formData.append("directory_id", "0");
      formData.append("is_directory", "0");
      formData.append("status", "1");
      formData.append("object_type", "30");
      formData.append("object_id", "0");
      formData.append("origin", "image");
      formData.append("file", file);

      // const response = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.post(
            AxiosClient().defaults.baseURL + "/files",
            formData,
            {
              headers: {
                Authorization: BaseApi.loginAccount.jwt,
                // ContentType: "multipart/form-data",
              },
            }
          );
          if (res) {
            resolve(res);
          }
        } catch (error) {
          openNotificationWithIcon(
            "error",
            t("delivery:button_action.notification.error.title"),
            t("delivery:button_action.notification.error.description")
          );
          setPending(false);
          setIsSuccess(true);
          reject(error);
        }
      });
    }
  };

  const handleSubmitForm = () => {
    form.submit();
  };

  const handleChange = ({ filList }: any) => {};
  const openNotificationWithIcon = (
    type: "error" | "info" | "success" | "warning" | "loading",
    message: string = "",
    title: string = ""
  ) => {
    messageApi.open({
      type: type,
      content: t(`${title}`),
      duration: 10,
    });
  };

  ///////////////
  // useEffect

  //   React.useEffect(() => {
  //     if (isSuccess) {
  //       setTimeout(() => {
  //         handleSuccess && handleSuccess();
  //       }, 2000);
  //     } else {
  //     }
  //   }, [isSuccess]);

  return (
    <div className="my-1 h-full w-full ">
      {contextHolder}
      <Form
        form={form}
        name="PostAction"
        onSubmitCapture={() => {}}
        onFinish={() => {}}
      >
        <Row gutter={[8, 8]}>
          {/* <Col>
                <Space
                  style={{ margin: 0, padding: 4, display: "block" }}
                  className="bg-gray-100   "
                >
                  <Button
                    block
                    type="default"
                    onClick={() => setEnableWebcam(true)}
                  >
                    {t("Xác nhận đơn hàng")}
                  </Button>
                </Space>
              </Col> */}
          {enableWebcam && UPLOAD_REQUIRE === "UPLOAD_REQUIRE" && (
            <Col span={24}>
              <DeliveryWebcam
                widthImg={widthImg}
                heightImg={heightImg}
                changeImgSrc={handleChangeImg}
                imgScreenshotSrc={img}
                enableWebcam={enableWebcam}
                // setEnableWebcam={setEnableWebcam}
              ></DeliveryWebcam>
            </Col>
          )}

          {/**Show money */}

          {showCod && (
            <Col span={24}>
              <p>Số tiền mặt: {Helper.moneyFormat(cost_cod)}</p>
            </Col>
          )}

          {showCostTransfer && (
            <Col span={24}>
              <p>
                Số tiền chuyển khoản: {Helper.moneyFormat(cost_on_transfer)}
              </p>
            </Col>
          )}
          {enableWebcam &&
            !noteMoney &&
            action_code !== "ACTION_APPROVE_RECEIVE_COD" && (
              <Col span={24}>
                <Form.Item
                  name="note"
                  rules={[
                    {
                      required: noteRequire ? true : false,
                      message: t("delivery:form.rule.note_require"),
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Note ..."></TextArea>
                </Form.Item>
              </Col>
            )}

          {noteMoney && (
            <Col span={24}>
              <Form.Item
                name="note"
                rules={[
                  {
                    required: noteRequire ? true : false,
                    message: t("delivery:form.rule.note_require"),
                  },
                ]}
              >
                <MoneyInput
                  style={{ width: "100%" }}
                  addonAfter="VND"
                  defaultValue={0}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      <PostAction
        key={"1"}
        action={action_code}
        gpsRequire={GpsRequire}
        noteRequire={false}
        uploadRequire={UpLoadRequire}
        isShowNote={false}
        data={{
          deliveries_id: delivery_id,
          note: cost_cod.toString(),
        }}
        handlePostActionSuccess={() => {
          handleSucess && handleSucess();
        }}
      ></PostAction>
    </div>
  );
};

export default DeliveryAccoutantConfirmForm;
