import SiteMenuRepository from "common/repositories/SiteMenuRepository";
import usePathParams from "hooks/usePathParams";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useMenuStore from "zustands/useMenuStore";

export type PageSiteMenuProps = {
  siteMenuOpenKey?: string;
  siteMenuSelectedKey?: string;
  siteMenuSelectedParentKey?: string;
  siteMenuHidden?: boolean;
  siteMenuHiddenBackLink?: string | (() => void);
};
const PageSiteMenu = ({
  siteMenuOpenKey,
  siteMenuSelectedKey,
  siteMenuHidden,
  siteMenuHiddenBackLink,
  siteMenuSelectedParentKey,
}: PageSiteMenuProps) => {
  const { pathname } = useLocation();
  const account = useLoginAccountStore((state) => state.account);

  const [setOpenKeys, setSelectedKeys, setForceHidden, setActiveGroup] =
    useMenuStore((state) => [
      state.setOpenKeys,
      state.setSelectedKeys,
      state.setForceHidden,
      state.setActiveGroup,
    ]);

  useEffect(() => {
    if (typeof siteMenuOpenKey !== "undefined") {
      if (siteMenuOpenKey.indexOf(".") >= 0) {
        setOpenKeys([siteMenuOpenKey, siteMenuOpenKey.split(".")[0]]);
      } else {
        setOpenKeys([siteMenuOpenKey]);
      }
    }
  }, [siteMenuOpenKey, setOpenKeys]);

  useEffect(() => {
    if (typeof siteMenuSelectedKey !== "undefined") {
      setSelectedKeys([siteMenuSelectedKey]);

      //find activeGroup for this key
      //LOOP THROUGHT EACH GROUP
      SiteMenuRepository.getSiteMenuWithRoleCheck(account).forEach((group) => {
        group.children.every((item: any) => {
          //in each group, we check if item matched
          if (item?.key === siteMenuSelectedKey) {
            setActiveGroup(group.key);
            return false;
          } else if (Array.isArray(item.children) && item.children.length > 0) {
            //if not match, we can find in submenu of current item to find
            item.children.every((subitem: any) => {
              if (subitem.key === siteMenuSelectedKey) {
                setActiveGroup(group.key);
                return false;
              }

              return true;
            });
          }

          return true;
        });
      });
    } else {
      //remove all selected key on menu
      if (pathname.length > 0) {
        if (typeof siteMenuSelectedParentKey !== "undefined") {
          setSelectedKeys([siteMenuSelectedParentKey]);
        } else {
          setSelectedKeys([pathname]);
        }
      } else {
        setSelectedKeys([]);
      }
    }
  }, [siteMenuSelectedKey, setSelectedKeys, account, setActiveGroup, pathname]);

  useEffect(() => {
    setForceHidden(
      typeof siteMenuHidden !== "undefined" && siteMenuHidden,
      siteMenuHiddenBackLink
    );

    return () => {
      //reset after unmount component
      if (typeof siteMenuHidden !== "undefined" && siteMenuHidden) {
        setForceHidden(false);
      }
    };
  }, [siteMenuHidden, setForceHidden, siteMenuHiddenBackLink]);

  return <></>;
};

export default PageSiteMenu;
