const items = [
    {
        id:1,
        username:"Sam",
        avatar:"https://images.pexels.com/photos/4588435/pexels-photo-4588435.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Anyone know if Frodo is awake yet?",
        button: [
            {
                icon:"🤷",
                count:"2",
            }
        ]
    },
    {
        id:2,
        username:"Elrond",
        avatar:"https://images.pexels.com/photos/4588052/pexels-photo-4588052.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Strangers from distant lands, friends of old. You have been summoned here to answer the threat of Mordor. Middle-Earth stands upon the brink of destruction. None can escape it. You will unite or you will fall. Each race is bound to this fate–this one doom. (gestures to the pedestal) Bring forth the Ring, Frodo.",
        button: [
            {
                icon:"❤️",
                count:"5",
            }
        ]
    },
    {
        id:3,
        username:"Boromi",
        avatar:"https://images.pexels.com/photos/3090875/pexels-photo-3090875.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"So it is true… \nIt is a gift. A gift to the foes of Mordor. Why not use this Ring? (paces) Long has my father, the Steward of Gondor, kept the forces of Mordor at bay. By the blood of our people are your lands kept safe! Give Gondor the weapon of the Enemy. Let us use it against him!",
        button: [
            {
                icon:"👎",
                count:"1",
            },
            {
                icon:"🤔",
                count:"3",
            }
        ]
    },
    {
        id:4,
        username:"Aragorn ",
        avatar:"https://images.pexels.com/photos/2951921/pexels-photo-2951921.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"You cannot wield it! None of us can. The One Ring answers to Sauron alone. It has no other master.",
        button: [
            {
                icon:"",
                count:"",
            }
        ]
    },
    {
        id:5,
        username:"Boromir ",
        avatar:"https://images.pexels.com/photos/733835/pexels-photo-733835.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"And what would a ranger know of this matter?",
        button: [
            {
                icon:"😲",
                count:"2",
            }
        ]
    },
    {
        id:6,
        username:"Legolas ",
        avatar:"https://images.pexels.com/photos/4056462/pexels-photo-4056462.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"This is no mere ranger. He is Aragorn, son of Arathorn. You owe him your allegiance.",
        button: [
            {
                icon:"",
                count:"",
            }
        ]
    },
    {
        id:7,
        username:"Boromim ",
        avatar:"https://images.pexels.com/photos/3104709/pexels-photo-3104709.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Aragorn? This… is Isildur’s heir?",
        button: [
            {
                icon:"",
                count:"",
            }
        ]
    },
    {
        id:8,
        username:"Boromir ",
        avatar:"https://images.pexels.com/photos/733835/pexels-photo-733835.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Gondor has no king. Gondor needs no king.",
        button: [
            {
                icon:"",
                count:"",
            }
        ]
    },
    {
        id:9,
        username:"Gandalf ",
        avatar:"https://images.pexels.com/photos/3196887/pexels-photo-3196887.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Aragorn is right. We cannot use it.",
        button: [
            {
                icon:"👍",
                count:"6",
            },
            {
                icon:"🧙",
                count:"3",
            }
        ]
    },
    {
        id:10,
        username:"Elrond",
        avatar:"https://images.pexels.com/photos/4588052/pexels-photo-4588052.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"You have only one choice. The Ring must be destroyed.",
        button: [
            {
                icon:"💍",
                count:"1",
            }
        ]
    },
    {
        id:11,
        username:"Gimli ",
        avatar:"https://images.pexels.com/photos/2171583/pexels-photo-2171583.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"What are we waiting for?",
        button: [
            {
                icon:"",
                count:"",
            }
        ]
    },
    {
        id:12,
        username:"Elrond  ",
        avatar:"https://images.pexels.com/photos/4588052/pexels-photo-4588052.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"The Ring cannot be destroyed, Gimli, son of Gloin by any craft that we here possess. The Ring was made in the fires of Mount Doom. Only there can it be unmade. It must be taken deep into Mordor and cast back into the fiery chasm from whence it came.\nOne of you must do this.",
        button: [
            {
                icon:"🗻",
                count:"2",
            },
            {
                icon:"🔥",
                count:"3",
            }
        ]
    },
    {
        id:13,
        username:"Boromir   ",
        avatar:"https://images.pexels.com/photos/733835/pexels-photo-733835.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"One does not simply walk into Mordor. Its black gates are guarded by more than just orcs. There is evil there that does not sleep. And the great Eye is ever watchful. It is a barren wasteland riddled with fire and ash and dust. The very air you breathe is a poisonous fume. Not with ten thousand men could you do this. It is folly!",
        button: [
            {
                icon:"",
                count:"",
            },
        ]
    },
]

export default items;