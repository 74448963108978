import BaseCollection from "common/collections/BaseCollection";
import CompanyResourceModel from "common/models/CompanyResourceModel";
import {
  CompanyResourceJson,
  CompanyResourceCollectionJson,
} from "common/types/CompanyResource";

class CompanyResourceCollection extends BaseCollection<
  CompanyResourceJson,
  CompanyResourceModel,
  CompanyResourceCollectionJson
> {
  itemsFromJson(jsonItems: CompanyResourceJson[]): CompanyResourceModel[] {
    return jsonItems.map((item) => new CompanyResourceModel(item));
  }
}

export default CompanyResourceCollection;
