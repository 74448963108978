import BaseCollection from 'common/collections/BaseCollection';
import ShipperApplyModel from 'common/models/ShipperApplyModel';
import { ShipperApplyCollectionJson, ShipperApplyJson } from 'common/types/ShipperApply';

class ShipperApplyCollection extends BaseCollection<
  ShipperApplyJson,
  ShipperApplyModel,
  ShipperApplyCollectionJson
> {
  itemsFromJson(jsonItems: ShipperApplyJson[]): ShipperApplyModel[] {
    return jsonItems.map((item) => new ShipperApplyModel(item));
  }
}

export default ShipperApplyCollection;
