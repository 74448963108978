import { Skeleton } from "antd";
import DataGridWorkspaceModel from "common/models/DataGridWorkspaceModel";
import DataGridWorkspaceRepository from "common/repositories/DataGridWorkspaceRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import DataGridWorkspaceForm from "features/datagrid/workspace/DataGridWorkspaceForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DataGridWorkspaceFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: DataGridWorkspaceModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<DataGridWorkspaceModel>(
    new DataGridWorkspaceModel(DataGridWorkspaceModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new DataGridWorkspaceRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={
        id > 0
          ? t("datagridworkspace:heading_edit")
          : t("datagridworkspace:heading_add")
      }
      width={640}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="datagridworkspace-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <DataGridWorkspaceForm
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <DataGridWorkspaceForm
          model={
            new DataGridWorkspaceModel(DataGridWorkspaceModel.getDefaultData())
          }
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default DataGridWorkspaceFormModal;
