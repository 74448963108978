import { DatePickerProps, Form, Input } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DepartmentFormFilter from "features/department/DepartmentFormFilter";

import { Filter } from "common/types/Filter";
import DateFormFilter from "components/datetime/DateFormFilter";
import FilterBody from "components/filter/FilterBody";
import PageDataFilterForm from "components/page/PageDataFilterForm";

const ReportLeaveFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  const getYmdInFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "month" || key === "year")
      .map(([key, val]) => {
        return val;
      }) as number[];

    return entries[0] > 0
      ? dayjs(new Date(entries[1], entries[0] - 1))
      : undefined;
  }, [filters]);

  const onChangeMonthYear: DatePickerProps["onChange"] = (
    value: DatePickerProps["value"],
    dateString: string
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        month: value.month() + 1,
        year: value.year(),
      });
    } else {
      setFilters({
        ...filters,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      });
    }
  };

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[
          "department_id",
          // "type",
          // "status",
        ]}
      >
      
          <Form.Item name="keyword">
            <Input
              allowClear
              placeholder={t("reportleave:placeholder_keyword")}
            />
          </Form.Item>

          <DepartmentFormFilter
            placeholder={t("employee:department")}
            name="department_id"
            allowClear
            popupMatchSelectWidth={false}
          />

          <Form.Item
            className="lg:w-[400px] gap-1 "
            // label={t("common:date_created")}
          >
            <DateFormFilter
              value={getYmdInFilter}
              onChange={onChangeMonthYear}
              allowClear={true}
              showNow={false}
              mode="single"
              format="MM/YYYY"
            />
          </Form.Item>
    
      </PageDataFilterForm>
    </>
  );
};

export default ReportLeaveFilter;
