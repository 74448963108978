import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

import ShopMegaMenuList from "features/shopmegamenu/list/ShopMegaMenuList";

const ShopMegaMenuPage = () => {
	const [pathParams] = usePathParams();

	const [errors, setErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setErrors([]);
	}, [pathParams.action]);

	let com = <></>;
	const fakeData = [
		{
			link: "/pages/sinh-nhat-11-nam",
			name: "BIRTHDAY",
			to_time: 1725123544,
			from_time: 1722790800,
			is_event: true,
      submenu: null,
      hiden_in_page:true,
      style: {
        color:"#d72229"
      }
		},
		{
			name: "namperfume Favorites",
			link: "/blogs/thuong-hieu-nuoc-hoa/namperfume-favorites",
			is_event: true,
			submenu: null,
			from_time: 1712136268,
			to_time: 0,
      id: 2,
      style: {
        color:"#d72229"
      }
		},
		{
			name: "Deal Thơm",
			link: "/collections/deal-thom",
			submenu: null,
			is_event: true,
			from_time: 1712136268,
			to_time: 1722759309,
      id: 1,
      style: {
        color:"#3a393a"
      }
      
		},
		{
			name: "Nước Hoa Nam",
			link: "/collections/nuoc-hoa-nam",
			from_time: 0,
      to_time: 0,
      style: {
        color:"#3a393a"
      },
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-nam?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-nam?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nam",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41754-579051721185703-1721185703.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41755-698421721185710-1721185710.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41756-814521721185712-1721185712.jpg",
						alt: "third",
					},
				],
			},
		},
		{
			name: "Nước Hoa Nữ",
			link: "/collections/nuoc-hoa-nu",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-nu?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-nu?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nu",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41757-407431721185814-1721185814.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41758-874371721185816-1721185816.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41759-135341721185819-1721185819.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
      to_time: 0,
      style: {
        color:"#3a393a"
      },
		},
		{
			name: "Nước Hoa Mini",
			link: "/collections/nuoc-hoa-mini",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/nuoc-hoa-mini?sort_by=date_created&sort_type=DESC&page=1",
								title: "Mới nhất",
							},
							{
								link: "/collections/nuoc-hoa-mini?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Yêu thích nhất",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Nước hoa Niche",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Giftset",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Nước hoa unisex",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "Xem tất cả",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41760-235331721186216-1721186216.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41761-877821721186218-1721186218.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41762-250731721186220-1721186220.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
      to_time: 0,
      style: {
        color:"#3a393a"
      },
		},
		{
			name: "Giftset",
			link: "/collections/giftset-nuoc-hoa",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/giftset-nam",
								title: "Giftset nước hoa nam",
							},
							{
								link: "/collections/giftset-nu",
								title: "Giftset nước hoa nữ",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Giftset nước hoa mini",
							},
						],
					},
					{
						id: 2,
						type: "Mức giá",
						details: [
							{
								title: "Dưới 1.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=1000000&price_min=0&page=1",
							},
							{
								title: "Dưới 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=2000000&price_min=0&page=1",
							},
							{
								title: "Trên 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_min=2000000&page=1",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41763-202711721186317-1721186317.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41764-534361721186319-1721186319.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41765-860271721186321-1721186321.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
      to_time: 0,
      style: {
        color:"#3a393a"
      },
		},
		{
			name: "Nước Hoa Niche",
			link: "/collections/nuoc-hoa-niche",
      submenu: null,
      style: {
        color:"#3a393a"
      },
		},
		{
			name: "Thương hiệu",
			link: "/pages/thuong-hieu-a-z",
			submenu: {
				category: [
					{
						id: 1,
						type: "Thương hiệu mới",
						details: [
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/maison-margiela",
								title: "MAISON MARGIELA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/kilian",
								title: "KILIAN",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/mancera",
								title: "MANCERA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/parfums-de-marly",
								title: "PERFUMS DE MARLY",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/creed",
								title: "CREED",
							},
							{
								link: "",
								title: "",
							},
							{
								link: "/pages/thuong-hieu-a-z",
								title: "Thương hiệu A-Z",
							},
						],
					},
					{
						id: 2,
						type: "Thương hiệu",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41766-149571721186423-1721186423.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41767-792551721186425-1721186425.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41768-496611721186427-1721186427.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
      to_time: 0,
      style: {
        color:"#3a393a"
      },
		},
		{
			name: "Bodycare & Homecare",
			link: "/collections/bodycare-homecare",
			submenu: {
				category: [
					{
						id: 1,
						type: "Phân loại",
						details: [
							{
								link: "/collections/cham-soc-toc",
								title: "Chăm sóc tóc",
							},
							{
								link: "/collections/cham-soc-da",
								title: "Chăm sóc da",
							},
							{
								link: "/collections/tinh-dau-nuoc-hoa",
								title: "Tinh dầu nước hoa",
							},
							{
								link: "/collections/lan-khu-mui",
								title: "Lăn khử mùi",
							},
							{
								link: "/collections/thuc-pham-chuc-nang",
								title: "Thực phẩm chức năng",
							},
							{
								link: "/collections/qua-tang",
								title: "Quà Tặng",
							},
							{
								link: "/collections/hop-qua",
								title: "Hộp quà",
							},
						],
					},
				],
				images: [
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41770-788861721186550-1721186550.jpg",
						alt: "first",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41769-750951721186550-1721186550.jpg",
						alt: "second",
					},
					{
						url: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/July/41771-798141721186553-1721186553.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
      to_time: 0,
      style: {
        color:"#3a393a"
      },
		},
		{
			name: "Son Môi",
			link: "/collections/son-moi",
      submenu: null,
      style: {
        color:"#3a393a"
      },
		},
	];

	switch (pathParams.action) {
		default:
			com = <ShopMegaMenuList />;
			break;
	}
	return com;
};

export default ShopMegaMenuPage;
