import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { Menu, MenuProps, Input } from "antd";
import useDebounce from "@rooks/use-debounce";

import { Category } from "common/types/Collection";
import Helper from "common/utils/helper";

import styles from "./styles.module.css";
import LogoMobileIcon from "features/academy/icon/LogoMobileIcon";
import ChevronDownIcon from "features/academy/icon/ChevronDownIcon";
import Collection from "common/constants/Collection";
import useCollectionStore from "zustands/useCollectionStore";
import CollectionRepository from "common/repositories/CollectionRepository";
type Props = {
  onMenuClick: () => void;
  defaultKeys: string[];
};
const AcademyMenu = ({ onMenuClick, defaultKeys }: Props) => {
  const { catid } = useParams();
  const handleCategories = catid ? [catid] : ["0"]!;

  const navigate = useNavigate();
  const location = useLocation();
  const setSubCategories = useCollectionStore(
    (state) => state.setSubCategories
  );
  const setKeywordGlobal = useCollectionStore((state) => state.setKeyword);
  const setCategories = useCollectionStore((state) => state.setCategories);

  const [keys, setkeys] = useState<string[]>(handleCategories);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [handleActive, setHandleActive] = useState<string[]>(handleCategories);

  const handleClick: MenuProps["onClick"] = (e) => {
    setkeys(e.keyPath);
  };

  const getCategoryList = async () => {
    // setLoading(true);
    await new CollectionRepository()
      .getItemsCategoryPublic({
        filters: { collection_id: 0, type: Collection.TYPE_ACADEMY },
      })
      .then((collection) => {
        setCategories(collection);
        setCategoryList(collection);
      })
      .catch(() => {
        navigate("/academy/notfound");
      });

    // setLoading(false);
  };

  //keyword
  const [searchParams] = useSearchParams();
  const keywordSearchParams = searchParams.get("keyword");
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>(keywordSearchParams || "");
  const isSearch = useRef(false);

  const keywordParam = useDebounce((payload: string) => {
    // if (location.pathname) {
    // }
    // setSearchParams({ ...searchParams, keyword: keyword });
    navigate(
      {
        pathname: "/academy/search",

        search: `${createSearchParams({
          keyword: payload,
        })}`,
      },
      {
        state: {
          ignoreUpdateFilters: true,
        },
      }
    );

    setLoadingSearch(false);
  }, 1000);

  const handleSearchChange = useDebounce((values: string) => {
    if (location.pathname.includes("search")) {
      isSearch.current && setKeywordGlobal(values);
    } else {
      isSearch.current &&
        navigate({
          pathname: "/academy/search",
          search: `${createSearchParams({
            keyword: values,
          })}`,
        });
    }

    setLoadingSearch(false);
  }, 1000);

  function findItemAndParents(items: Category[], idToFind: string) {
    let foundParents: number[] = [];

    function findItemRecursively(items: Category[], idToFind: string) {
      for (const item of items) {
        if (item.handle === idToFind) {
          foundParents = [item.parent_id, ...foundParents];
          setHandleActive((prev) => [item.handle]);
          return item;
        }

        if (item.sub_categories.length > 0) {
          if (findItemRecursively(item.sub_categories, idToFind)) {
            foundParents = [item.parent_id, ...foundParents];
            setHandleActive((prev) => [item.handle]);
            return item;
          }
        }
      }
      return null;
    }

    const foundItem = findItemRecursively(items, idToFind);

    return { foundItem, foundParents };
  }

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (catid && catid.length > 0) {
      const { foundItem, foundParents } = findItemAndParents(
        categoryList,
        catid || ""
      );

      if (foundItem) {
      } else {
        // navigate("/academy/notfound");
      }
    } else {
      setHandleActive(["0"]);
    }
  }, [categoryList, catid]);

  return (
    <div className="academymenu__wrapper px-10">
      <div className={styles.mainMenu}>
        <div className={styles.logo}>
          <LogoMobileIcon></LogoMobileIcon>
        </div>
        <div className={styles.search}>
          <Input
            style={{
              height: 40,
              width: "100%",
              borderRadius: 4,
              marginBottom: 6,
            }}
            placeholder="Tìm kiếm"
            prefix={<SearchOutlined />}
            onChange={(event) => {
              isSearch.current = true;
              if (event.target.value.trim().length > 0) {
                setLoadingSearch(true);
              }
              handleSearchChange(event.target.value.trim());
            }}
            allowClear={true}
            // onKeyDown={handleEnterKey}
            defaultValue={keyword}
            suffix={
              loadingSearch && <LoadingOutlined style={{ color: "#1677ff" }} />
            }
          ></Input>
        </div>
      </div>
      <Menu
        mode="horizontal"
        // defaultSelectedKeys={[handleActive[0]]}
        overflowedIndicator={
          <span
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            Xem thêm <ChevronDownIcon size="small"></ChevronDownIcon>
          </span>
        }
        theme="light"
        color="red"
        selectedKeys={[handleActive[0]]}
        onChange={(values: any) => {}}
        onClick={handleClick}
        style={{ flex: 1, border: "none" }}
      >
        <Menu.Item key="0">
          <span className="test">
            <Link to={"/academy"}> Tổng hợp</Link>
          </span>
        </Menu.Item>
        {categoryList.map((item, index) => {
          return (
            <Menu.Item
              onClick={() => {
                if (Helper.isMobile()) {
                  if (catid === item.handle) {
                    onMenuClick();
                  } else {
                    setSubCategories(item.sub_categories);
                    navigate(`/academy/${item.handle}`);
                  }
                } else {
                  setSubCategories(item.sub_categories);
                  navigate(`/academy/${item.handle}`);
                }
              }}
              key={item.handle}
            >
              <span className="test">{item.title}</span>
            </Menu.Item>
          );
        })}
      </Menu>
      <div className="hidden md:inline-block" style={{ flexBasis: "228px" }}>
        <Input
          style={{
            height: 40,
            width: "100%",
            borderRadius: 4,
            marginBottom: 6,
          }}
          placeholder="Tìm kiếm"
          prefix={<SearchOutlined />}
          onChange={(event) => {
            isSearch.current = true;
            if (event.target.value.trim().length > 0) {
              setLoadingSearch(true);
            }
            handleSearchChange(event.target.value.trim());
          }}
          allowClear={true}
          // onKeyDown={handleEnterKey}
          defaultValue={keyword}
          suffix={
            loadingSearch && <LoadingOutlined style={{ color: "#1677ff" }} />
          }
        ></Input>
      </div>
    </div>
  );
};

export default AcademyMenu;
