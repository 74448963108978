import { Button, ButtonProps } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { IconPlus } from "@tabler/icons-react";
import { diff } from "deep-object-diff";
import { useTranslation } from "react-i18next";

const PageHeaderButton = ({
  link,
  title,
  children,
  onClick,
  type,
  danger,
  ghost,
  className,
  icon,
  disabled,
  target,
}: {
  link: string;
  title?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  type?: ButtonProps["type"];
  danger?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactNode;
  target?: React.HTMLAttributeAnchorTarget;
}) => {
  const { t } = useTranslation();

  const btn = (
    <Button
      type={type || "primary"}
      disabled={disabled}
      danger={danger}
      ghost={ghost}
      className={className}
      onClick={(e) => {
        console.log("ok");
        onClick && onClick(e);
      }}
      icon={
        icon || (
          <IconPlus
            size={16}
            stroke={3}
            className="-mt-0.5 hidden sm:inline-block"
          />
        )
      }
    >
      {children}
    </Button>
  );

  if ((typeof disabled === "undefined" || !disabled) && link.length > 0) {
    return (
      <Link to={link} title={title} target={target}>
        {btn}
      </Link>
    );
  } else {
    return btn;
  }
};

export default PageHeaderButton;
