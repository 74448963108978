import { Col, Row } from "antd";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartCategory from "features/report/chart/ReportChartCategory";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React, { useCallback } from "react";

const ReportCashflowReceiptExpensePartnerGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const formatterTargetValue = useCallback((value: number) => {
    return CashflowReceiptModel.getTarget(value)?.label;
  }, []);

  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Giá trị phiếu chi theo nhóm đối tượng"}
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              dataSelect="sum(value), target"
              dataFilter="direction = 10,status < 2"
              dataGroupBy="target"
              dataOrderBy="sum(value) desc"
              keyMapping={{
                name: "target",
                value: "sum_value",
                formatter: (value: number) => {
                  return formatterTargetValue(value) || "";
                }
              }}
              height={350}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo phiếu chi theo nhóm đối tượng"}
              subtitle="Chỉ tính dựa trên phiếu chi chưa thanh toán và đã thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhóm đối tượng",
                  name: "target",
                  formatter: (value: number | string) => {
                    return <>{formatterTargetValue(+value)}</>;
                  },
                  sortField: "target"
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), target, sum(value)"
              dataFilter="direction = 10,status < 2"
              dataGroupBy="target"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền cần chi theo nhóm đối tượng"}
              subtitle="Chỉ tính các phiếu chi có trạng thái chưa thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhóm đối tượng",
                  name: "target",
                  formatter: (value: number | string) => {
                    return <>{formatterTargetValue(+value)}</>;
                  },
                  sortField: "target"
                },
                {
                  title: "Tiền cần chi",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), target, sum(value)"
              dataFilter="direction = 10,status = 0"
              dataGroupBy="target"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền đã chi theo nhóm đối tượng"}
              subtitle="Chỉ tính các phiếu chi có trạng thái đã thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhóm đối tượng",
                  name: "target",
                  formatter: (value: number | string) => {
                    return <>{formatterTargetValue(+value)}</>;
                  }
                },
                {
                  title: "Tiền đã chi",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), target, sum(value)"
              dataFilter="direction = 10,status = 1"
              dataGroupBy="target"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Giá trị phiếu chi hủy theo nhóm đối tượng"}
              subtitle="Chỉ tính các phiếu chi có trạng thái hủy"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhóm đối tượng",
                  name: "target",
                  formatter: (value: number | string) => {
                    return <>{formatterTargetValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), target, sum(value)"
              dataFilter="direction = 10,status = 2"
              dataGroupBy="target"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCashflowReceiptExpensePartnerGeneral;
