import {
	App,
	Form,
	FormInstance,
	Input,
	List,
	Modal,
	Tag,
	Typography,
} from "antd";
import ShippingBillModel from "common/models/ShippingBillModel";
import ShippingBillRepository from "common/repositories/ShippingBillRepository";
import { ProductReceiptJson } from "common/types/ProductReceipt";
import {
	ShippingBillJson,
	ShippingBillJsonAddEdit,
} from "common/types/ShippingBill";
import { ShippingCarrierJson } from "common/types/ShippingCarrier";
import Error from "components/LayoutError";
import TextMoney from "components/TextMoney";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import OrderFormSectionShippingBillAdd from "../shippingbill/OrderFormSectionShippingBillAdd";
import OrderFormSectionShippingBillEdit from "../shippingbill/OrderFormSectionShippingBillEdit";
import OrderFormSectionShippingCarrier from "./OrderFormSectionShippingCarrier";
import { InventoryReceiptJson } from "common/types/InventoryResceipt";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import DeliveryDetail from "features/delivery/detail/DeliveryDetail";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import Role from "common/constants/Role";

const OrderFormSectionInventoryReceiptItem = ({
	inventory_receipt,
	deleteCallback,
	updateCallback,
	form,
	allowEditInput,
}: {
	inventory_receipt: InventoryReceiptJson;
	deleteCallback?: (v: number) => void;
	updateCallback?: (v: ShippingBillJson) => void;
	form: FormInstance;
	allowEditInput: boolean;
}) => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const [shippingCarrierItems] =
		useDatabaseTable<ShippingCarrierJson>("shippingcarrier");
	const order = useEditingSaleOrderStore((state) => state.order);
	const hasRole = useLoginAccountStore((state) => state.hasRole);

	const [codAmount, setCodAmount] = useState(order.cod_amount);
	const [insuranceValue, setInsuranceValue] = useState(order.price_final);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string[]>([]);

	const [deliveryDetail, setDeliveryDetail] = useState(
		DeliveryModel.getDefaultData()
	);

	const [showDetailDelivery, setShowDetailDelivery] = useState(false);

	const getDetailDelivery = useCallback(async () => {
		setLoading(true);
		const res = await new DeliveryRepository().getItem(
			+inventory_receipt.target.id
		);
		if (!res.hasError()) {
			setDeliveryDetail(res.toJson());
		} else {
			setError(res.error.errors);
		}

		setLoading(false);
	}, [inventory_receipt.id]);

	useEffect(() => {
		getDetailDelivery();
	}, [getDetailDelivery]);
	return (
		<div className="w-full">
			{inventory_receipt.detail.length > 0 ? (
				<div className="mb-2">
					{/* <strong>
            <OrderFormSectionShippingCarrier
              id={.shipping_carrier_id}
            />
          </strong> */}
					<Typography.Link
						disabled={loading}
						onClick={() => {
							// hasRole(Role.DELIVERY_MANAGER) && setShowDetailDelivery(true);
						}}>
						{loading ? (
							<LoadingOutlined title="Đang tải">Đang tải </LoadingOutlined>
						) : (
							<div>
								<Tag className="ml-2" color="blue">
									{DeliveryModel.getStatus(deliveryDetail.action_code)}{" "}
								</Tag>
								{/* <Typography.Text copyable >
									{deliveryDetail.id}
								</Typography.Text> */}
							</div>
						)}
					</Typography.Link>
				</div>
			) : (
				<div>
					<Typography.Text color="warning">
						Chưa thêm liên kết đơn vị vận chuyển
					</Typography.Text>
				</div>
			)}
			<Modal
				className="min-w-[80vw]"
				title="Thông tin vận chuyển"
				cancelText="Đóng"
				open={showDetailDelivery}
				onCancel={() => setShowDetailDelivery(false)}
				okButtonProps={{
					children: "Đóng",
					style: {
						display: "none",
					},
				}}>
				<DeliveryDetail
					model={new DeliveryModel(deliveryDetail)}
					type="default"></DeliveryDetail>
			</Modal>
			{/* {shipping_bill.id > 0 ? (
        <OrderFormSectionShippingBillEdit
          shipping_bill={shipping_bill}
          doAddEditShippingBill={doAddEditShippingBill}
          processing={processing}
          setProcessing={setProcessing}
          updateCallback={updateCallback}
          deleteCallback={deleteCallback}
          status={status}
          setStatus={setStatus}
          trackingCode={trackingCode}
          setTrackingCode={setTrackingCode}
          allowEditInput={allowEditInput}
        />
      ) : (
        <OrderFormSectionShippingBillAdd
          doAddEditShippingBill={doAddEditShippingBill}
          shippingCarrier={shippingCarrier}
          setShippingCarrier={setShippingCarrier}
          codAmount={codAmount}
          setCodAmount={setCodAmount}
          processing={processing}
          insuranceValue={insuranceValue}
          setInsuranceValue={setInsuranceValue}
          allowEditInput={allowEditInput}
          shippingCarrierItems={shippingCarrierItems}
        />
      )} */}
		</div>
	);
};

export default OrderFormSectionInventoryReceiptItem;
