import OrderModel from 'common/models/OrderModel';
import { OrderJson } from 'common/types/Order';
import create, { StateCreator } from 'zustand';

type EditingSaleOrderStore = {
  order: OrderJson;
  setOrder: (v: OrderJson) => void;
};

//init store data
let store: StateCreator<EditingSaleOrderStore> = (set) => ({
  order: OrderModel.getDefaultData(),
  setOrder: (order) => set((state) => ({ ...state, order })),
});

//create store
const useEditingSaleOrderStore = create(store);

export default useEditingSaleOrderStore;
