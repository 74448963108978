import SettingRepository from "common/repositories/SettingRepository";
import React, { useEffect, useState } from "react";
import { CollectionAds, CollectionAds2 } from "common/types/Ads";
import {
	Card,
	Col,
	Empty,
	Image,
	message,
	Modal,
	Popconfirm,
	Row,
	Table,
	Tag,
	Typography,
} from "antd";
import AdsCollectionListHeader from "./AdsCollectionHeader";
import Meta from "antd/es/card/Meta";
import {
	EditOutlined,
	EllipsisOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import SettingModel from "common/models/SettingModel";
import AdsCollectionForm from "../form/AdsCollectionForm";
import { SettingJson } from "common/types/Setting";
import { TableColumnsType } from "common/types/Table";
import TextDateTime from "components/TextDateTime";
import { IconTrash } from "@tabler/icons-react";
import TableEdit from "components/table/TableEdit";
import useSetting from "hooks/useSetting";
import TableDelete from "components/table/TableDelete";

export default function AdsCollectionList() {
	const [messageApi, context] = message.useMessage();

	const [data, setData] = useState<CollectionAds | null>(null);
	const [data2, setData2] = useState<CollectionAds2[] | null>(null);

	const [settingJson, setSettingJson] = useState<SettingJson>(
		SettingModel.getDefaultData()
	);
	const [indexUpdate, setIndexUpdate] = useState(-1);
	const [typeAction, setTypeAction] = useState<"edit" | "add" | "">("");

	const [dataEditing, setDataEditing] = useState<CollectionAds2 | null>(null);
	const [idEditing, setIdEditing] = useState<number>(0);
	// const getDataAll = async () => {
	// 	const response = await new SettingRepository().getItem(
	// 		"collection_ads_all",
	// 		"vi"
	// 	);
	// 	if (!response.hasError()) {
	// 		try {
	// 			const dataAds = response.value as CollectionAds;
	// 			setData(dataAds);
	// 			setSettingJson(response.toJson());
	// 		} catch (error) {}
	// 	}
	// };

	///////////////////////////////////
	const { onAdd, onUpdate } = useSetting<CollectionAds2>({
		settingJson: settingJson,
	});

	///////////////////////////////////
	const hanleUpdate = (data: CollectionAds2, onSuccess?: () => void) => {
		dataEditing &&
			onUpdate(data, dataEditing, indexUpdate)
				.then(() => {
					getDataAll();
					messageApi.success("Thay đổi thành công!");
					onSuccess && onSuccess();
					setTypeAction("");
				})
				.catch(() => {
					messageApi.error("Thay đổi thất bại!");
				});
	};
	const onReset = () => {
		setTypeAction("");
		setDataEditing(null);
		setIndexUpdate(-1);
	};
	async function getDataAll() {
		const response = await new SettingRepository().getItem(
			"collection_ads",
			"vi"
		);
		if (!response.hasError()) {
			try {
				const dataAds = response.value as CollectionAds2[];
				setData2(dataAds);
				setSettingJson(response.toJson());
			} catch (error) {}
		}
	}
	useEffect(() => {
		getDataAll();
	}, []);

	const d: CollectionAds2[] = [
		{
			data: [
				{
					from_time: 0,
					data: [
						{
							img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/39628-770531719299000-1719299000.jpg",
							link: "#",
						},
					],
					location_view: 0,
					location_view_desktop: 0,
					status: 1,
					to_time: 0,
					type: "card",
				},
			],
			from_time: 0,
			handle_applys: [],
			id: 0,
			status: 1,
			title: "123",
			to_time: 0,
		},
	];

	const column: TableColumnsType<CollectionAds2> = [
		{
			title: "ID",
			key: "id",
			dataIndex: "title",
			render(value, record, index) {
				return (
					<>
						<Typography.Text>{value}</Typography.Text>
					</>
				);
			},
		},
		{
			title: "Tiêu đề",
			key: "title",
			dataIndex: "title",
			render(value, record, index) {
				return (
					<>
						<Typography.Text>{value}</Typography.Text>
					</>
				);
			},
		},
		{
			title: "Danh mục áp dụng",
			key: "handle_applys",
			dataIndex: "handle_applys",
			render(value, record, index) {
				if (value.includes("all")) {
					return <Tag color="blue">Tất cả</Tag>;
				}

				if (!value.length) {
					return <Tag color="blue">Trống</Tag>;
				}

				return (
					<>
						<Tag color="blue">{value.length}</Tag>
					</>
				);
			},
		},
		{
			title: "Bắt đầu",
			key: "from_time",
			dataIndex: "from_time",
			render(value, record, index) {
				return (
					<>
						<TextDateTime ts={value}></TextDateTime>
					</>
				);
			},
		},
		{
			title: "Kết thúc",
			key: "to_time",
			dataIndex: "to_time",
			render(value, record, index) {
				return (
					<>
						<TextDateTime ts={value}></TextDateTime>
					</>
				);
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (id: number, record, index) => {
				return (
					<div className="flex gap-1 justify-end">
						<TableEdit
							link=""
							onClick={() => {
								setTypeAction("edit");
								setIndexUpdate(index);
								setDataEditing(record);
							}}></TableEdit>

						<Popconfirm
							placement="topRight"
							title={<>Bạn chắc chắn muốn xóa ?</>}
							description={"Gỡ chương trình khỏi cài đặt "}
							okText="Đồng ý"
							cancelText="Hủy"
							onConfirm={() => {}}>
							{/* <TableEdit label="" icon={<IconTrash></IconTrash>} link={""} /> */}
							<IconTrash
								className=" cursor-pointer text-gray-400 hover:text-red-500"
								size="18"
							/>
						</Popconfirm>
					</div>
				);
			},
		},
	];
	const brands = [
		{
			id: 1,
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133914-814501720606365-1720606365.jpg",
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40668-957101719570430-1719570430.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
			title: "Gucci",
			alt: "Gucci",
		},
		{
			id: 2,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133913-538441720606353-1720606353.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133879-724211720604978-1720604978.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
			title: "Jean Paul Gaultier",
			alt: "Jean Paul Gaultier",
		},
		{
			id: 3,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133912-609201720606340-1720606340.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133880-776481720604993-1720604993.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
			title: "Narciso Rodriguez",
			alt: "Narciso Rodriguez",
		},
		{
			id: 4,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133910-882881720606320-1720606320.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133884-880791720605418-1720605418.jpg",

			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/giorgio-armani",
			title: "Giorgio Armani",
			alt: "Giorgio Armani",
		},
		{
			id: 5,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133909-871861720606307-1720606307.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133882-929561720605031-1720605031.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/paco-rabanne",
			title: "Paco Rabanne",
			alt: "Paco Rabanne",
		},
		{
			id: 6,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133908-411531720606291-1720606291.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133886-141861720605661-1720605661.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
			title: "Calvin Klein",
			alt: "Calvin Klein",
		},
		{
			id: 7,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133907-485981720606278-1720606278.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133887-828101720605686-1720605686.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/versace",
			title: "VERSACE",
			alt: "VERSACE",
		},
		{
			id: 8,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133906-171921720606256-1720606256.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133888-110141720605700-1720605700.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
			title: "Burberry",
			alt: "Burberry",
		},
		{
			id: 9,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133905-407401720606246-1720606246.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133890-233011720605975-1720605975.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/marc-jacobs",
			title: "Marc Jacobs",
			alt: "Marc Jacobs",
		},
		{
			id: 10,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133904-741241720606229-1720606229.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133891-629391720605993-1720605993.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
			title: "VALENTINO",
			alt: "VALENTINO",
		},
		{
			id: 11,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133903-737281720606219-1720606219.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133892-883801720606012-1720606012.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/prada",
			title: "PRADA",
			alt: "PRADA",
		},
		{
			id: 12,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133902-374021720606206-1720606206.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133893-590781720606024-1720606024.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
			title: "Carolina Herrera",
			alt: "Carolina Herrera",
		},
		{
			id: 13,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133901-336391720606192-1720606192.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133894-199581720606038-1720606038.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
			title: "Ralph Lauren",
			alt: "Ralph Lauren",
		},
		{
			id: 14,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133900-187921720606180-1720606180.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133895-493111720606060-1720606060.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/viktor-rolf",
			title: "VIKTOR & ROLF",
			alt: "VIKTOR & ROLF",
		},
		{
			id: 15,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133899-121481720606160-1720606160.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133896-528451720606114-1720606114.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/lacoste",
			title: "LACOSTE",
			alt: "LACOSTE",
		},
		{
			id: 16,
			image_mobile:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133898-984401720606148-1720606148.jpg",
			image:
				"https://api.namefragrance.vn/uploads/filecloud/10308/2024/July/133897-669261720606124-1720606124.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/chloe",
			title: "Chloé",
			alt: "Chloé",
		},
	];

	return (
		<div>
			{context}
			<AdsCollectionListHeader>
				<></>
			</AdsCollectionListHeader>
			{/* {data ? (
				<Row className="mt-5 px-4">
					<Col span={4}>
						<Card
							actions={[
								<SettingOutlined key="setting" />,
								<EditOutlined
									key="edit"
									onClick={() => {
										setIdEditing(settingJson.id);
									}}
								/>,
								<EllipsisOutlined key="ellipsis" />,
							]}
							cover={
								<Image
									alt="example"
									src={data.data?.[0].images?.[0].url || ""}
								/>
							}>

						</Card>
					</Col>
				</Row>
			) : (
				<Empty className="mt-6"></Empty>
			)}
			<Modal
				destroyOnClose
				title="Cài đặt card quảng cáo"
				open={idEditing > 0}
				onCancel={() => {
					setIdEditing(0);
				}}>
				<AdsCollectionForm
					settingJson={settingJson}
					onFinish={() => {
						getDataAll();
					}}></AdsCollectionForm>
			</Modal> */}
			<Table columns={column} rowKey={"id"} dataSource={data2 || []}></Table>
			<Modal
				width={"800px"}
				okButtonProps={{ className: "hidden" }}
				destroyOnClose
				title="Cài đặt card quảng cáo"
				open={typeAction !== ""}
				onCancel={() => {
					onReset();
				}}>
				{dataEditing && (
					<AdsCollectionForm
						initialValue={dataEditing}
						onFinish={(data) => {
							hanleUpdate(data);
						}}></AdsCollectionForm>
				)}
			</Modal>
		</div>
	);
}
