import _ from "lodash";

import BaseModel from "./BaseModel";

import type { CompanyResourceJson } from "common/types/CompanyResource";

class CompanyResourceModel extends BaseModel {
  id: number;
  company_id: number;
  jsversion: CompanyResourceJson["jsversion"];
  date_created: number;
  date_modified: number;

  constructor(json: CompanyResourceJson) {
    super();

    this.id = json.id || 0;
    this.company_id = json.company_id || 0;
    this.jsversion = json.jsversion || {};
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  toJson(): CompanyResourceJson {
    return {
      id: this.id,
      company_id: this.company_id,
      jsversion: this.jsversion,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  getJsVersionForUi(): CompanyResourceJson["jsversion"] {
    const blackList = ["user", "origin", "cancelreason", "cashflowgroup"];

    let newJsVersion: CompanyResourceJson["jsversion"] = {};
    _.forEach(this.jsversion, (value, key) => {
      if (!blackList.includes(key)) {
        newJsVersion[key] = value;
      }
    });

    return newJsVersion;
  }
}

export default CompanyResourceModel;
