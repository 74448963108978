import apikey from "./data/apikey.json";
import app from "./data/app.json";
import cashflowreceipt from "./data/cashflowreceipt.json";
import cashflowtype from "./data/cashflowtype.json";
import cmsnews from "./data/cmsnews.json";
import cmsnewscategory from "./data/cmsnewscategory.json";
import cmspost from "./data/cmspost.json";
import common from "./data/common.json";
import company from "./data/company.json";
import crmfeed from "./data/crmfeed.json";
import customer from "./data/customer.json";
import customerpoint from "./data/customerpoint.json";
import customertag from "./data/customertag.json";
import customertype from "./data/customertype.json";
import customerupgrade from "./data/customerupgrade.json";
import customerupgradedetail from "./data/customerupgradedetail.json";
import datagrid from "./data/datagrid.json";
import datagridtable from "./data/datagridtable.json";
import datagridtablefield from "./data/datagridtablefield.json";
import datagridtablerow from "./data/datagridtablerow.json";
import datagridworkspace from "./data/datagridworkspace.json";
import deliverydashboard from "./data/deliverydashboard.json";
import deliveryplanning from "./data/deliveryplanning.json";
import department from "./data/department.json";
import dmsbanner from "./data/dmsbanner.json";
import dmsnews from "./data/dmsnews.json";
import dmsnewscategory from "./data/dmsnewscategory.json";
import dmspost from "./data/dmspost.json";
import ecom from "./data/ecom.json";
import ecomchannelorder from "./data/ecomchannelorder.json";
import ecomchannelproduct from "./data/ecomchannelproduct.json";
import ecomlog from "./data/ecomlog.json";
import employee from "./data/employee.json";
import file from "./data/file.json";
import homepage from "./data/homepage.json";
import htmleditor from "./data/htmleditor.json";
import inventory from "./data/inventory.json";
import logaudit from "./data/logaudit.json";
import news from "./data/news.json";
import newscategory from "./data/newscategory.json";
import notify from "./data/notify.json";
import office from "./data/office.json";
import order from "./data/order.json";
import permission from "./data/permission.json";
import pos from "./data/pos.json";
import product from "./data/product.json";
import productcategory from "./data/productcategory.json";
import productinventorychecking from "./data/productinventorychecking.json";
import productreceipt from "./data/productreceipt.json";
import producttransmit from "./data/producttransmit.json";
import productvariant from "./data/productvariant.json";
import productvariantcolor from "./data/productvariantcolor.json";
import productvariantsize from "./data/productvariantsize.json";
import project from "./data/project.json";
import projectissue from "./data/projectissue.json";
import projectiteration from "./data/projectiteration.json";
import projecttask from "./data/projecttask.json";
import promotion from "./data/promotion.json";
import promotionproduct from "./data/promotionproduct.json";
import purchaseorder from "./data/purchaseorder.json";
import report from "./data/report.json";
import role from "./data/role.json";
import setting from "./data/setting.json";
import shipper from "./data/shipper.json";
import shipperapply from "./data/shipperapply.json";
import shippingbill from "./data/shippingbill.json";
import shippingcarrier from "./data/shippingcarrier.json";
import shippinghub from "./data/shippinghub.json";
import shippingorder from "./data/shippingorder.json";
import shippingroute from "./data/shippingroute.json";
import shippingtype from "./data/shippingtype.json";
import sitemenu from "./data/sitemenu.json";
import store from "./data/store.json";
import supplier from "./data/supplier.json";
import taxinvoice from "./data/taxinvoice.json";
import user from "./data/user.json";
import warehouse from "./data/warehouse.json";
import webhook from "./data/webhook.json";
import workchat from "./data/workchat.json";
import workschedule from "./data/workschedule.json";
import worktracking from "./data/worktracking.json";
import worktrackingrange from "./data/worktrackingrange.json";
import template from "./data/template.json";
import checkin from "./data/checkin.json";
import phonebook from "./data/phonebook.json";
import tool from "./data/tool.json";
import itrequest from "./data/itrequest.json";
import notification from "./data/notification.json";
import leave from "./data/leave.json";
import leaveapprove from "./data/leaveapprove.json";
import leavetype from "./data/leavetype.json";
import story from "./data/story.json";
import collection from "./data/collection.json";
import reportleave from "./data/reportleave.json";
import reportdelivery from "./data/reportdelivery.json";
import delivery from "./data/delivery.json";
import workcheckin from "./data/workcheckin.json";
import task from "./data/task.json";
import contract from "./data/contract.json";
import comment from "./data/comment.json";
import reportcheckin from "./data/reportcheckin.json";
import upload from "./data/upload.json";
import errors from "./data/errors.json";
import productcollection from "./data/productcollection.json";
import tagtype from "./data/tagtype.json";
import tag from "./data/tag.json";
import storycollection from "./data/storycollection.json";
import contactmail from "./data/contactmail.json";
import shopsetting from "./data/shopsetting.json";
import brand from "./data/brand.json";
import inventoryreceipt from "./data/inventoryreceipt.json";

const resource = {
	common,
	sitemenu,
	user,
	employee,
	shipper,
	app,
	shippingtype,
	role,
	permission,
	notify,
	report,
	office,
	shippinghub,
	customer,
	customertag,
	news,
	newscategory,
	htmleditor,
	file,
	store,
	warehouse,
	worktrackingrange,
	origin,
	setting,
	shipperapply,
	customertype,
	supplier,
	cashflowtype,
	shippingroute,
	productvariantcolor,
	productvariantsize,
	productcategory,
	shippingorder,
	promotion,
	dmsnewscategory,
	cmsnewscategory,
	customerpoint,
	department,
	crmfeed,
	dmsnews,
	cmsnews,
	product,
	cashflowreceipt,
	shippingcarrier,
	order,
	customerupgrade,
	dmsbanner,
	dmspost,
	cmspost,
	productvariant,
	project,
	projectissue,
	projectiteration,
	projecttask,
	customerupgradedetail,
	pos,
	promotionproduct,
	worktracking,
	apikey,
	workchat,
	webhook,
	workschedule,
	ecom,
	ecomlog,
	ecomchannelorder,
	ecomchannelproduct,
	productreceipt,
	producttransmit,
	inventory,
	shippingbill,
	homepage,
	logaudit,
	deliveryplanning,
	deliverydashboard,
	productinventorychecking,
	company,
	purchaseorder,
	datagridworkspace,
	datagrid,
	datagridtable,
	datagridtablefield,
	datagridtablerow,
	taxinvoice,
	template,
	checkin,
	phonebook,
	tool,
	itrequest,
	notification,
	leave,
	leaveapprove,
	leavetype,
	story,
	collection,
	reportleave,
	reportdelivery,
	delivery,
	workcheckin,
	task,
	comment,
	contract,
	reportcheckin,
	upload,
	errors,
	productcollection,
	tagtype,
	tag,
	storycollection,
	contactmail,
	shopsetting,
	brand,
	inventoryreceipt,
};

export default resource;
