const ProductReceipt = {
  STATUS_DRAFT: 1,
  STATUS_WAIT_FOR_APPROVAL: 3,
  STATUS_APPROVED: 5,
  STATUS_CHECKED: 7,
  STATUS_COMPLETED: 9,
  STATUS_CANCELLED: 11,

  DIRECTION_OUTPUT: 1,
  DIRECTION_INPUT: 3,

  TYPE_BUYING: 1, // mua
  TYPE_SELLING_ONE: 3, // ban le
  TYPE_SELLING_MANY: 9, //ban si
  TYPE_TRANSFERSTORE: 5, // chuyen kho
  TYPE_BALANCESTORE: 7, // can bang kho
  TYPE_INPUTRECEIVE: 11, //nhap tra
  TYPE_OUTPUTRECEIVE: 13, // xuat tra
  TYPE_OUTPUTBALANCE: 14, // xuat tra
  TYPE_OUTPUTCORRUPT: 15, // hu
  TYPE_OUTPUTLOSS: 17, // mat
  TYPE_OTHER: 21, // khac
};

export default ProductReceipt;
