import { Button, Popconfirm, Table, TableColumnType } from "antd";
import PurchaseOrderDetailModel from "common/models/PurchaseOrderDetailModel";
import { TableColumnsType } from "common/types/Table";
import QuantityInput from "components/QuantityInput";
import TextMoney from "components/TextMoney";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import TextProductVariantSku from "components/TextProductVariantSku";
import produce from "immer";
import { useTranslation } from "react-i18next";

import { IconTrash } from "@tabler/icons-react";

import PurchaseOrderFormSectionDetailCostInput from "./PurchaseOrderFormSectionDetailCostInput";

const PurchaseOrderFormSectionDetailList = ({
  totalQuantity,
  readonlyItems,
  items,
  onChangeItem,
  onRemoveItem,
  allowEditInput,
}: {
  totalQuantity: number;
  readonlyItems: PurchaseOrderDetailModel[];
  items: PurchaseOrderDetailModel[];
  onChangeItem: (v: PurchaseOrderDetailModel) => void;
  onRemoveItem: (v: PurchaseOrderDetailModel) => void;
  allowEditInput: boolean;
}) => {
  const { t } = useTranslation();
  const columns: TableColumnsType<PurchaseOrderDetailModel> = [
    ...(allowEditInput
      ? [
          {
            title: " ",
            dataIndex: "id",
            width: 50,
            align: "center",
            render: (_, record) => {
              return allowEditInput ? (
                <Popconfirm
                  title={t("common:table.confirm_delete_title")}
                  placement="topRight"
                  onConfirm={() => {
                    onRemoveItem(record);
                  }}
                  okText={t("common:table.confirm_ok")}
                  cancelText={t("common:table.confirm_cancel")}
                >
                  <Button
                    type="text"
                    size="small"
                    className="text-gray-300"
                    icon={<IconTrash size={18} />}
                    disabled={!allowEditInput}
                    title={t("order:detail.remove")}
                  ></Button>
                </Popconfirm>
              ) : null;
            },
          } as TableColumnType<PurchaseOrderDetailModel>,
        ]
      : []),
    {
      title: t("purchaseorder:detail.no"),
      dataIndex: "id",
      align: "center",
      render: (_, __, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("purchaseorder:detail.item"),
      dataIndex: "item_name",
      render: (item_name: string, record) => {
        return (
          <>
            <div className="font-semibold">{item_name}</div>
            <div className="text-gray-400 ">
              <TextProductVariantColor id={record.item_color} /> &middot;{" "}
              <TextProductVariantSize id={record.item_size} /> | SKU:
              <TextProductVariantSku id={record.product_variant_id} />
            </div>
          </>
        );
      },
    },
    {
      title: t("purchaseorder:detail.cost"),
      dataIndex: "item_unit_price",
      align: "right",
      render: (_: number, record) => {
        return (
          <PurchaseOrderFormSectionDetailCostInput
            record={record}
            allowEditInput={allowEditInput}
            onChangeItem={onChangeItem}
          />
        );
      },
    },
    {
      title: (
        <>
          {t("purchaseorder:detail.quantity")} ({totalQuantity})
        </>
      ),
      dataIndex: "item_quantity",
      align: "center",
      width: 80,
      render: (item_quantity: number, record) => {
        const readonlyRecord = readonlyItems.find((i) => i.id === record.id);
        let colorClass = "";
        if (
          typeof readonlyRecord === "undefined" ||
          readonlyRecord.item_quantity !== record.item_quantity
        ) {
          colorClass = "border-yellow-300 ";
        }

        return (
          <>
            <QuantityInput
              controlsHoverOnly
              disabled={!allowEditInput}
              wrapperClassName={colorClass}
              className={"w-12"}
              size="middle"
              value={item_quantity}
              onChange={(value) =>
                onChangeItem(
                  new PurchaseOrderDetailModel(
                    produce(record.toJson(), (draft) => ({
                      ...draft,
                      item_quantity: value ?? 0,
                    }))
                  )
                )
              }
            />
          </>
        );
      },
    },
    {
      title: t("purchaseorder:detail.subtotal"),
      dataIndex: "id",
      align: "right",
      width: 120,
      render: (_, record) => {
        return (
          <>
            <TextMoney money={record.item_unit_price * record.item_quantity} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        size="small"
        rowKey="product_variant_id"
        pagination={{ hideOnSinglePage: true }}
        columns={columns}
        dataSource={items}
        scroll={{ x: 700 }}
      />
    </>
  );
};

export default PurchaseOrderFormSectionDetailList;
