import BaseCollection from "common/collections/BaseCollection";
import {
  LeaveHistoryJson,
  LeaveHistoryCollectionJson,
} from "common/types/Leave";
import LeaveHistoryModel from "common/models/LeaveHistoryModel";
class LeaveHistoryCollection extends BaseCollection<
  LeaveHistoryJson,
  LeaveHistoryModel,
  LeaveHistoryCollectionJson
> {
  itemsFromJson(jsonItems: LeaveHistoryJson[]): LeaveHistoryModel[] {
    return jsonItems.map((item) => new LeaveHistoryModel(item));
  }
}

export default LeaveHistoryCollection;
