class FilterProvider {
  filters: any;
  setFilters: (v: any) => void;

  constructor(filters: any, setFilters: (v: any) => void) {
    this.filters = filters;
    this.setFilters = setFilters;
  }
}

export default FilterProvider;
