import { Button, Col, Empty, Form, Input, Row, message } from "antd";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RateStarsSelect from "../components/RateStarsSelect";
import TextArea from "antd/lib/input/TextArea";
import CommentModel from "common/models/CommentModel";
import { useTranslation } from "react-i18next";
import { FileJson } from "common/types/File";
import LayoutForm from "components/form/LayoutForm";
import CommentRepository from "common/repositories/CommentRepository";
import { CommentJsonAddEditNew } from "common/types/Comment";
import ProcessImage from "./ProcessImage";
import { NoticeType } from "antd/es/message/interface";
type Props = {
  model: CommentModel;
};
const ProcessMainDetail = ({ model }: Props) => {
  const { t } = useTranslation();
  const [messageApi, conText] = message.useMessage();

  ///////////////////////////////

  const [images, setImages] = useState<FileJson[]>([]);

  ///////////////////////////////
  const [isSuccess, setIsSucess] = useState(false);
  const [errors, setErros] = useState<string[]>([]);

  ///////////////////////////////
  const initialValues = useMemo(() => {
    return {
      item_id: model.item_id,
      item_type: model.item_type,
      type: model.type,
      rate: model.rate,
      count_chill: model.count_chill,
      status: model.status,
      email: model.email,
      images: model.images,
      detail: model.detail,
      phone: model.phone,
      name: model.name,
      title: model.title,
    };
  }, [model]);
  const doPrepareData = useCallback(
    (formData: CommentJsonAddEditNew) => {
      const submitData: CommentJsonAddEditNew = {
        detail: formData.detail,
        id: model.id,
        title: formData.title,
        images: images.map((i) => i.id).join(","),
      };

      return submitData;
    },
    [model.id, images]
  );
  ///////////////////////////////
  const handleSubmitForm = async (formData: CommentJsonAddEditNew) => {
    const response = await new CommentRepository().edit(
      doPrepareData(formData)
    );
    if (response.hasError()) {
      setErros(response.error.errors);
      openMessage({
        type: "error",
        content: "Thao tác gặp lỗi ! Vui lòng thử lại",
      });
    } else {
      // setIsSucess(true);
      openMessage({
        type: "success",
        content: "Thao tác thành công !",
      });
    }
  };

  const openMessage = ({
    type,
    content,
  }: {
    type: NoticeType | undefined;
    content: React.ReactNode;
  }) => {
    messageApi.open({
      type: type,
      content: content,
      duration: 2,
    });
  };
  const handleDeleteImage = (id: number) => {
    setImages((prev) => [...prev].filter((i) => i.id !== id));
  };
  ///////////////////////////////

  useEffect(() => {
    if (model) {
      setImages([...model.images]);
    }
  }, [model]);
  ///////////////////////////////

  return (
    <div className="m-[-16px] p-[16px] max-h-[500px] min-h-[500px] overflow-y-auto overflow-x-hidden">
      {conText}
      <LayoutForm
        initialValues={initialValues}
        layout="vertical"
        onSubmit={handleSubmitForm}
        hideSidebar={true}
        isSuccess={isSuccess}
        sidebarItems={<></>}
        successTitle={t("comment:form.success.update")}
        errors={errors}
        disable_status_loading={true}
      >
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Row gutter={16}>
              <Col md={12} span={24}>
                <Form.Item label={t("comment:form.item_id")} name="item_id">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={12} span={24}>
                <FormSelect
                  label={t("comment:form.item_type")}
                  name="item_type"
                  placeholder=""
                  disabled
                  options={CommentModel.getItemType()}
                />
              </Col>
              <Col md={12} span={24}>
                <FormSelect
                  label={t("comment:form.type")}
                  name="type"
                  placeholder=""
                  disabled
                  options={CommentModel.getType()}
                />
              </Col>
              {model.type === 1 && (
                <Col md={12} span={24}>
                  <Form.Item label={t("comment:form.rate")} name="rate">
                    <RateStarsSelect defaultValue={model.rate} disable={true} />
                  </Form.Item>
                </Col>
              )}
              <Col md={12} span={24}>
                <Form.Item
                  label={t("comment:form.count_chill")}
                  name="count_chill"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              {/* <Col md={12} span={24}>
            <FormSelect
              className="m-0"
              name="status"
              placeholder=""
              disabled
              label={t("comment:form.status")}
              options={CommentModel.getStatus()}
            />
          </Col> */}
              <Col md={12} span={24}>
                <Form.Item
                  label={t("comment:form.name_customer")}
                  name={"name"}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={12} span={24}>
                <Form.Item label={t("comment:form.email")} name="email">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={12} span={24}>
                <Form.Item label={t("comment:form.phone")} name={"phone"}>
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {/* Right */}
          <Col span={24} md={12}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  className="mb-2"
                  label={t("comment:form.images")}
                  name="images"
                >
                  <div className="flex flex-wrap gap-2 relative mt-2">
                    {images.length > 0 ? (
                      images.map((item: FileJson, index: number) => {
                        return (
                          <ProcessImage
                            modelImage={item}
                            key={index}
                            handleDeleteImage={handleDeleteImage}
                          />
                        );
                      })
                    ) : (
                      <Empty
                        style={{}}
                        imageStyle={{ width: "60px", height: "60px" }}
                        description=""
                      />
                    )}
                  </div>
                </Form.Item>
              </Col>
              {model.type === 1 && (
                <Col span={24}>
                  <Form.Item label={t("comment:form.title")} name="title">
                    <Input max={50} maxLength={50} showCount={true}></Input>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item label={t("comment:form.detail")} name="detail">
                  <TextArea
                    maxLength={800}
                    showCount={true}
                    rows={6}
                  ></TextArea>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button htmlType="submit" type="primary">
                    Lưu thay đổi
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutForm>
    </div>
  );
};

export default ProcessMainDetail;
