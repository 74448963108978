import { Modal, Skeleton } from "antd";
import PromotionModel from "common/models/PromotionModel";
import React from "react";

import PromotionProductList from "../list/PromotionProductList";

const PromotionProductModal = ({
	model,
	open,
	setOpen,
	onUpdate,
}: {
	model: PromotionModel;
	open: boolean;
	setOpen: (b: boolean) => any;
	onUpdate?: () => void;
}) => {
	return (
		<Modal
			title={`Danh sách nhóm hàng áp dụng cho ${model.name} - ${model.code} `}
			open={open}
			closable={true}
			maskClosable={false}
			width="700px"
			onCancel={() => {
				setOpen(false);
			}}
			onOk={() => {}}
			footer={null}>
			{model.id > 0 ? (
				<PromotionProductList
					promotion_id={model.id}
					promotion={model.toJson()}
					onUpdate={onUpdate}
				/>
			) : (
				<Skeleton />
			)}
		</Modal>
	);
};

export default PromotionProductModal;
