import ReactQuill from "react-quill";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useState } from "react";

import NotificationModel from "common/models/NotificationModel";
import { NotificationJsonAddEdit } from "common/types/Notification";
import NotificationRepository from "common/repositories/NotificationRepository";

import LayoutForm from "components/form/LayoutForm";
import FormSelect from "components/form/FormSelect";
import FormSection from "components/form/FormSection";
import NotificationFormHeader from "./NotificationFormHeader";
import FileModel from "common/models/FileModel";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import { IconPhoto } from "@tabler/icons-react";

type Props = {
  model: NotificationModel;
};
const NotificationForm = ({ model }: Props) => {
  console.log("🍳 ~ file: NotificationForm.tsx:21 ~ model:", model);
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;

  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  //default data
  const initialValues = useMemo(() => {
    return {
      type: model.type,
      meta_title: model.meta_title,
      meta_summary: model.meta_summary,
      meta_detail: model.meta_detail,
      meta_image: FileModel.convertToUploadFiles([model.meta_image]),
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: NotificationJsonAddEdit = {
        ...NotificationModel.getDefaultData(),
        id: model.id,
        type: formData.type,
        meta_title: formData.meta_title.trim(),
        meta_summary: formData.meta_summary.trim(),
        meta_detail: formData.meta_detail.trim(),
        meta_image: formData.meta_image
          ? formData.meta_image[0]?.response.id
          : 0,
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: NotificationJsonAddEdit) => {
    const response = await new NotificationRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      setIsSuccess(true);
    } else {
      setErrors(response.error.errors);
    }
  };
  //sidebar form
  const sidebarItems = (
    <FormSelect
      name="type"
      label={t("notification:type")}
      options={NotificationModel.getTypeNotificationList()}
    />
  );

  const toolbarOptions = [
    [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction"],
    ["link", "image", "video", "formula"],
    ["clean"],
  ];

  return (
    <div>
      <NotificationFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess="/notification"
        error_translate_prefix="common:form.error.heading"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("notification:form.content")}
          subtitle={t("notification:form.content_detail")}
          divider
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                name="meta_title"
                label={t("notification:title")}
                required
                rules={[
                  {
                    required: true,
                    message: t(
                      "notification:form.error.error_meta_title_required"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item name="meta_summary" label={t("notification:summary")}>
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label={"Ảnh bìa"}>
                <FileUploaderButton
                  form={form}
                  formName="meta_image"
                  label={t("file:select_file_image")}
                  icon={<IconPhoto size="24" color="grey" />}
                  origin="avatar"
                  uploadRenderType="thumbnail"
                  isSingleFile={true}
                  initFiles={initialValues.meta_image}
                />
              </Form.Item>
            </Col>
            {/* <Col md={12} xs={24}>
              <FormSelect
                name="type"
                label="Hiển thị thông báo"
                options={[
                  { label: "Hiện", value: 1 },
                  { label: "Ẩn", value: 0 },
                ]}
              />
            </Col> */}

            <Col md={24} xs={24}>
              <Form.Item
                name="meta_detail"
                label={t("notification:form.detail")}
                required
                validateFirst
                rules={[
                  {
                    required: true,
                    message: t("notification:form.error.error_detail_required"),
                  },
                  {
                    message: t("notification:form.error.error_detail_required"),
                    validator: (_: any, value: string) => {
                      if (value !== "<p><br></p>") {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  // value={valueEditor}
                  style={{
                    height: "300px",
                    width: "100%",
                    marginBottom: "46px",
                  }}
                  // onChange={setValueEditor}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                  className="pb-6"
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default NotificationForm;
