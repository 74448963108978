import { App, Col, Divider, Form, Input, Radio, Row } from "antd";
import Ecom from "common/constants/Ecom";
import EcomModel from "common/models/EcomModel";
import EcomRepository from "common/repositories/EcomRepository";
import Error from "components/LayoutError";
import StoreFormSelect from "features/store/StoreFormSelect";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import EcomEditFormWooCommerce from "./EcomFormWooCommerceEdit";

import type { EcomJsonAddEdit } from "common/types/Ecom";
const EcomEditForm = ({
  id,
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  id: number;
  model: EcomModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: EcomModel) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const initialValues = useMemo(() => {
    const formData = {
      title: model.title,
      url: model.url,
      store: model.store > 0 ? model.store : null,
      receipt_warehouse: null,
      inventory_warehouses: [null],
      inventory_source: "available",
      hook_secret: ""
    };

    if (model.settings.hasOwnProperty("receipt_warehouse")) {
      formData.receipt_warehouse = model.settings.receipt_warehouse;
    }
    if (model.settings.hasOwnProperty("inventory_warehouses")) {
      formData.inventory_warehouses = model.settings.inventory_warehouses[0];
    }

    if (model.settings.hasOwnProperty("inventory_source")) {
      formData.inventory_source = model.settings.inventory_source;
    }

    if (model.settings.hasOwnProperty("hook_secret")) {
      formData.hook_secret = model.settings.hook_secret;
    }

    return formData;
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let settings = {
        receipt_warehouse: formData.receipt_warehouse,
        inventory_warehouses: [null],
        inventory_source: null,
        hook_secret: null
      };

      settings.inventory_warehouses = [formData.inventory_warehouses];
      settings.inventory_source = formData.inventory_source;
      settings.hook_secret = formData.hook_secret;

      const submitData: EcomJsonAddEdit = {
        ...EcomModel.getDefaultData(),
        id: model.id,
        url: model.url,
        api_key: model.api_key,
        api_secret: model.api_secret,
        status: model.status,
        title: formData.title,
        store: formData.store,
        settings: settings
      };

      return submitData;
    },
    [model.id, model.url, model.status, model.api_key, model.api_secret]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: EcomModel = await new EcomRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="ecom:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <>
      <Form
        layout="horizontal"
        labelAlign="left"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        initialValues={initialValues}
        form={form}
        onFinish={onSubmit}
        id="ecom-form">
        <Form.Item label={t("ecom:form.url")} name="url" labelAlign="right">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t("ecom:form.title")}
          name="title"
          required
          labelAlign="right">
          <Input />
        </Form.Item>
        {model.type === Ecom.TYPE_WOO_COMMERCE ? (
          <EcomEditFormWooCommerce />
        ) : null}
        <Divider orientation="left" style={{ marginTop: 0 }}>
          {t("ecom:form.setting_order")}
        </Divider>

        <Row gutter={16}>
          <Col md={24} xs={24}>
            <StoreFormSelect
              name="store"
              label={t("ecom:form.store")}
              labelAlign="right"
            />
          </Col>
        </Row>

        <Divider orientation="left" style={{ marginTop: 0 }}>
          {t("ecom:form.setting_inventory")}
        </Divider>

        <WarehouseFormSelect
          allowClear
          name="receipt_warehouse"
          label={t("ecom:form.receipt_warehouse")}
          placeholder={t("ecom:form.receipt_warehouse_placeholder")}
          labelAlign="right"
        />

        <div style={{ display: "none" }}>
          <WarehouseFormSelect
            name="inventory_warehouses"
            label={t("ecom:form.inventory_warehouses")}
            placeholder={t("ecom:form.inventory_warehouses_placeholder")}
            labelAlign="right"
            allowClear
          />
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.inventory_warehouses !==
              currentValues.inventory_warehouses
            }>
            {({ getFieldValue }) =>
              getFieldValue("inventory_warehouses") > 0 ? (
                <>
                  <Form.Item
                    label={t("ecom:form.inventory_source")}
                    name="inventory_source"
                    labelAlign="right">
                    <Radio.Group
                      buttonStyle="solid"
                      optionType="button"
                      options={EcomModel.getInventorySourceList()}
                    />
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default EcomEditForm;
