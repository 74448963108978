import ProductReceiptSyncStockApi from 'common/api/ProductReceiptSyncStockApi';
import ProductReceiptSyncStockCollection from 'common/collections/ProductReceiptSyncStockCollection';
import ProductReceiptSyncStockModel from 'common/models/ProductReceiptSyncStockModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProductReceiptSyncStockJsonAdd,
  FilterProductReceiptSyncStock,
} from "common/types/ProductReceiptSyncStock";

class ProductReceiptSyncStockRepository extends BaseRepository<ProductReceiptSyncStockApi> {
  _api: ProductReceiptSyncStockApi | null;

  constructor() {
    super();
    this._api = new ProductReceiptSyncStockApi(true);
  }

  static getDefaultFilters(): FilterProductReceiptSyncStock {
    return {
      page: 1,
      limit: 100,
      sortby: "id",
      sorttype: "ASC",
      status: -1,
      keyword: "",
      warehouse_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterProductReceiptSyncStock>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductReceiptSyncStockCollection();
  }

  async saveRemote(
    data: ProductReceiptSyncStockJsonAdd
  ): Promise<ProductReceiptSyncStockModel> {
    if (this._api) {
      return await this._api.add(data);
    } else {
      return new ProductReceiptSyncStockModel(
        ProductReceiptSyncStockModel.getDefaultData()
      );
    }
  }
}

export default ProductReceiptSyncStockRepository;
