import { Col, Form, Input, Row } from "antd";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import {
  InventoryReceiptJson,
  InventoryReceiptSourceType,
  InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import FormSelect from "components/form/FormSelect";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  disabled?: boolean;
  model: InventoryReceiptJson;
};
const InventoryReceiptSource = ({ disabled, model }: Props) => {
  return (
    <div>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            className="mb-0"
            
            rules={[
              {
                required: true,
                message: "Yêu cầu nhập",
              },
            ]}
            label="Loại"
            name={["source", "type"]}
          >
            <FormSelect
            
              disabled={disabled}
              options={[{label : "Chọn loại" , value : 0 , disabled : true},...InventoryReceiptModel.getSourceTypeOpntion()]}
            ></FormSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="mb-0"
            rules={[
              {
                required: true,
                message: "Yêu cầu nhập",
              },
            ]}
            label="Mã"
            name={["source", "id"]}
          >
            <Input placeholder="Giá trị" disabled={disabled}></Input>
          </Form.Item>
        </Col>
      </Row>
      {model.source.type === InventoryReceiptSourceType.ORDER && (
        <Link target="_blank" to={`/order/edit/id/${model.source.id}`}>
          Chi tiết đơn hàng # {model.source.id}
        </Link>
      )}
    </div>
  );
};

export default InventoryReceiptSource;
