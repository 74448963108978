import { Select, Tag } from "antd";
import ColorHash from "color-hash";
import React, { useEffect, useState } from "react";

import { IconX } from "@tabler/icons-react";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import type { TagStringInputProps } from "common/interfaces/TagStringInput";
const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={new ColorHash({ lightness: 0.75 }).hex(value)}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      closeIcon={
        <span className="pl-1 py-1 text-gray-500 hover:text-red-800">
          <IconX size={12} className="-mt-0.5 " />
        </span>
      }
      onClose={onClose}
      style={{ marginRight: 3, color: "black" }}
    >
      {label}
    </Tag>
  );
};

const TagStringInput: React.FC<TagStringInputProps> = ({
  value,
  onChange,
  ...props
}: TagStringInputProps) => {
  const [tagList, setTagList] = useState<string[]>([]);

  const triggerChange = (changedValue: string) => {
    onChange?.(changedValue, { value: changedValue, label: changedValue });
  };

  const onChangeSelect = (value: string[]) => {
    setTagList(value);
    triggerChange(value.join(","));
  };

  useEffect(() => {
    if (typeof value === "string" && value.length > 0) {
      setTagList(value.split(","));
    } else {
      setTagList([]);
    }
  }, [value]);

  return (
    <Select
      tagRender={tagRender}
      value={tagList}
      mode="tags"
      onChange={onChangeSelect}
      disabled={props.disabled}
    />
  );
};

export default TagStringInput;
