import ReportUtil from "common/utils/report";
import React from "react";

import type {
  ChartDataTableKeyMapping,
  ChartDataTableDatum
} from "common/types/BiCenterChart";

const ReportChartDataTableCell = ({
  value,
  km,
  data
}: {
  value: number | string;
  km: ChartDataTableKeyMapping;
  data: ChartDataTableDatum[];
}) => {
  if (typeof km.formatter !== "undefined") {
    if (typeof km.barColorIndex !== "undefined" && km.barColorIndex >= 0) {
      const maxValue = Math.max.apply(
        Math,
        data.map(function (o) {
          return typeof o[km.name] === "number" ? +o[km.name] : 0;
        })
      );

      const percent = (+value / maxValue) * 100;

      return (
        <div style={{ position: "relative" }}>
          <div
            className="float-right pd-3"
            style={{
              opacity: 0.15,
              width: percent + "%",
              backgroundColor: ReportUtil.getColors()[km.barColorIndex]
            }}>
            &nbsp;
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 3
            }}>
            <span className="format-number">{km.formatter(value)}</span>
          </div>
        </div>
      );
    } else {
      return <>{km.formatter(value)}</>;
    }
  } else {
    return <>{value}</>;
  }
};

export default ReportChartDataTableCell;
