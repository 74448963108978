import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { InventoryStock } from "common/types/InventoryStock";

import BaseModel from "./BaseModel";

import type { ProductVariantInMemory } from "common/types/ProductVariant";
class ProductVariantInMemoryModel
  extends BaseModel
  implements BaseModelJson<ProductVariantInMemory>
{
  product_id: number;
  id: number;
  sku: string;
  title: string;
  color: number;
  size: number;
  price: number;
  cost: number;
  status: number;

  product_name: string;
  product_code: string;
  product_status: number;
  search: string;
  category_id: number;
  sell_on_zero: number;
  supplier_id: number[];

  inventory_details: InventoryStock[];

  constructor(json: ProductVariantInMemory) {
    super();
    this.product_id = json.product_id || 0;
    this.id = json.id || 0;
    this.sku = json.sku || "";
    this.title = json.title || "";
    this.color = json.color || 0;
    this.size = json.size || 0;
    this.cost = json.cost || 0;
    this.price = json.price || 0;
    this.status = json.status || 0;
    this.product_name = json.product_name || "";
    this.product_code = json.product_code || "";
    this.product_status = json.product_status || 0;
    this.search = json.search || "";
    this.category_id = json.category_id || 0;
    this.sell_on_zero = json.sell_on_zero || 0;
    this.inventory_details = json.inventory_details || [];
    this.supplier_id = json.supplier_id || [];
  }

  static getDefaultData(): ProductVariantInMemory {
    return {
      product_id: 0,
      id: 0,
      sku: "",
      title: " ",
      color: 0,
      size: 0,
      cost: 0,
      price: 0,
      status: 0,
      product_name: "",
      product_code: "",
      product_status: 0,
      search: "",
      category_id: 0,
      sell_on_zero: 0,
      inventory_details: [],
      supplier_id: [],
    };
  }

  toJson(): ProductVariantInMemory {
    return {
      product_id: this.product_id,
      id: this.id,
      sku: this.sku,
      title: this.title,
      color: this.color,
      size: this.size,
      cost: this.cost,
      price: this.price,
      status: this.status,
      product_name: this.product_name,
      product_code: this.product_code,
      product_status: this.product_status,
      search: this.search,
      category_id: this.category_id,
      sell_on_zero: this.sell_on_zero,
      inventory_details: this.inventory_details,
      supplier_id: this.supplier_id,
    };
  }
}

export default ProductVariantInMemoryModel;
