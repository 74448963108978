import { App, Button, Spin, Tag } from "antd";
import InventoryStockStatsModel from "common/models/InventoryStockStatsModel";
import InventoryStockRepository from "common/repositories/InventoryStockRepository";
import ErrorAlert from "components/ErrorAlert";
import InfoContentItem from "components/InfoContentItem";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconAlertTriangle, IconCircleCheck } from "@tabler/icons-react";

const CompanyInfoSystemSearchIndexInventoryStock = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();

  const [stats, setStats] = useState<InventoryStockStatsModel>();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const fetchStats = useCallback(async () => {
    setLoading(true);
    const foundStats =
      await new InventoryStockRepository().getSearchIndexStats();
    setLoading(false);
    if (foundStats.hasError()) {
      setErrors(foundStats.error.errors);
    } else {
      setStats(foundStats);
    }
  }, []);

  /**
   * Do request to create index
   */
  const doInitInventoryStock = useCallback(async () => {
    setProcessing(true);
    const foundErrors = await new InventoryStockRepository().initSearchIndex();
    setProcessing(false);
    if (foundErrors.length > 0) {
      setErrors(foundErrors);
    } else {
      message.success(
        t("company:info.system.searchindex.inventorystock_init_sucess")
      );
      fetchStats();
    }
  }, [t, fetchStats, message]);

  useEffect(() => {
    if (typeof stats === "undefined") {
      fetchStats();
    }
  }, [fetchStats, stats]);

  return (
    <InfoContentItem
      label={t("company:info.system.searchindex.inventorystock")}
      content={
        <>
          {loading ? (
            <Spin />
          ) : (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchStats();
                  }}
                />
              ) : (
                <>
                  {typeof stats !== "undefined" ? (
                    <>
                      <div className="mb-2">
                        {t("company:info.system.searchindex.es_index")}:<br />
                        <Tag color="gray">{stats.es_index}</Tag>
                      </div>
                      <div>
                        {t("company:info.system.searchindex.es_index_inited")}:{" "}
                        <br />
                        {stats.es_index_is_inited ? (
                          <>
                            <Tag
                              color="green"
                              icon={
                                <IconCircleCheck
                                  size={16}
                                  className="mr-1 -mt-0.5"
                                />
                              }>
                              {t(
                                "company:info.system.searchindex.inventorystock_is_inited"
                              )}
                            </Tag>
                            <Link
                              to={"/inventory"}
                              className="text-xs text-blue-500">
                              {t(
                                "company:info.system.searchindex.goto_inventory_page"
                              )}{" "}
                              &raquo;
                            </Link>
                          </>
                        ) : (
                          <>
                            <Tag
                              color="red"
                              icon={
                                <IconAlertTriangle
                                  size={16}
                                  className="mr-1 -mt-0.5"
                                />
                              }>
                              {t(
                                "company:info.system.searchindex.inventorystock_is_not_inited"
                              )}
                            </Tag>

                            <Button
                              onClick={() => doInitInventoryStock()}
                              type="primary"
                              size="small"
                              loading={processing}>
                              {t(
                                "company:info.system.searchindex.inventorystock_init_button"
                              )}
                            </Button>
                          </>
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default CompanyInfoSystemSearchIndexInventoryStock;
