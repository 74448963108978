import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";
import DataGridTableFieldModel from "./DataGridTableFieldModel";

import type { DataGridTableJson } from "common/types/DataGridTable";
class DataGridTableModel
  extends BaseModel
  implements BaseModelJson<DataGridTableJson>
{
  company_id: number;
  creator_id: number;
  datagrid_workspace_id: number;
  id: number;
  name: string;
  description: string;
  tag: string;
  visibility: number;
  display_order: number;
  date_created: number;
  date_modified: number;
  fields: DataGridTableFieldModel[];

  constructor(json: DataGridTableJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.datagrid_workspace_id = json.datagrid_workspace_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.description = json.description || "";
    this.tag = json.tag || "";
    this.visibility = json.visibility || 0;
    this.display_order = json.display_order || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.fields = Array.isArray(json.fields)
      ? json.fields.map((f) => new DataGridTableFieldModel(f))
      : [];
  }

  static getDefaultData(): DataGridTableJson {
    return {
      company_id: 0,
      creator_id: 0,
      datagrid_workspace_id: 0,
      id: 0,
      name: "",
      description: "",
      tag: "",
      visibility: 0,
      display_order: 0,
      date_created: 0,
      date_modified: 0,
      fields: [],
    };
  }

  toJson(): DataGridTableJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      datagrid_workspace_id: this.datagrid_workspace_id,
      id: this.id,
      name: this.name,
      description: this.description,
      tag: this.tag,
      visibility: this.visibility,
      display_order: this.display_order,
      date_created: this.date_created,
      date_modified: this.date_modified,
      fields: this.fields.map((f) => f.toJson()),
    };
  }
}

export default DataGridTableModel;
