import db from "db";
import { useCallback, useEffect, useState } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

type UseStatus<MJ> = {
	loading: boolean;
	data: MJ[];
	error: string;
};

const useDatabaseTable = <MJson,>(table: string, role?: number) => {
	const [status, setStatus] = useState<UseStatus<MJson>>({
		loading: false,
		data: [],
		error: "",
	});

	const getRoleObjectList = useLoginAccountStore(
		(state) => state.getRoleObjectList
	);

	const fetchNow = useCallback(
		async (url: string) => {
			setStatus({ data: [], loading: true, error: "" });

			if (db.hasOwnProperty(table) && db.table(table) !== null) {
				let items = await db.table(table).toArray();
				if (
					typeof items !== "undefined" &&
					items.hasOwnProperty("length") &&
					items.length > 0
				) {
					//check limit role
					const roleObjectList =
						typeof role !== "undefined" ? getRoleObjectList(role) : [];

					setStatus({
						error: "",
						loading: false,
						data: items.filter(
							(i) =>
								roleObjectList.length === 0 || roleObjectList.includes(i.id)
						),
					});
				}
			} else {
				setStatus({ data: [], loading: false, error: "table not found" });
			}
		},
		[table, role, getRoleObjectList]
	);

	useEffect(() => {
		if (table) {
			fetchNow(table);
		}
	}, [table, fetchNow]);

	return [status.data, status.loading, status.error] as const;
};

export default useDatabaseTable;
