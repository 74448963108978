import OrderHelperApi from "common/api/OrderHelperApi";
import { EcomOrderCheck } from "common/types/EcomSyncOrder";

import BaseRepository from "./BaseRepository";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import { OrderInitTaxInvoiceRequest } from "common/types/Order";

class OrderHelperRepository extends BaseRepository<OrderHelperApi> {
  _api: OrderHelperApi | null;

  constructor() {
    super();
    this._api = new OrderHelperApi(true);
  }

  async getEcomOrderCheck(
    channelId: number,
    orderIdList: string[]
  ): Promise<EcomOrderCheck[]> {
    return this._api
      ? await this._api.getEcomOrderCheck(channelId, orderIdList)
      : [];
  }

  async linkProductReceipt(
    order_id: number,
    product_receipt_id: string
  ): Promise<string[]> {
    return this._api
      ? await this._api.linkProductReceipt(order_id, product_receipt_id)
      : [];
  }

  async linkCashflowReceipt(
    order_id: number,
    cashflow_receipt_id: string
  ): Promise<string[]> {
    return this._api
      ? await this._api.linkCashflowReceipt(order_id, cashflow_receipt_id)
      : [];
  }

  async initTaxInvoice(
    data: OrderInitTaxInvoiceRequest
  ): Promise<TaxInvoiceModel> {
    if (this._api) {
      return await this._api.initTaxInvoice(data);
    } else {
      return new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());
    }
  }
}

export default OrderHelperRepository;
