import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import type { MapServiceAutoCompletePredictionJson } from "common/types/MapServiceAutoCompletePrediction";

class MapServiceAutoCompletePredictionModel
  extends BaseModel
  implements BaseModelJson<MapServiceAutoCompletePredictionJson>
{
  service: string;
  id: string;
  name: string;
  place_id: string;
  details: Array<Object>;

  constructor(json: MapServiceAutoCompletePredictionJson) {
    super();

    this.service = json.service || "";
    this.id = json.id || "";
    this.name = json.name || "";
    this.place_id = json.place_id || "";
    this.details = json.details || [];
  }

  static getDefaultData(): MapServiceAutoCompletePredictionJson {
    return {
      service: "",
      id: "",
      name: "",
      place_id: "",
      details: [],
    };
  }

  toJson(): MapServiceAutoCompletePredictionJson {
    return {
      service: this.service,
      id: this.id,
      name: this.name,
      place_id: this.place_id,
      details: this.details,
    };
  }
}

export default MapServiceAutoCompletePredictionModel;
