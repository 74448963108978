import { Col, Form, Input, Row, Select } from "antd";
import DmsPost from "common/constants/DmsPost";
import File from "common/constants/File";
import DmsPostModel from "common/models/DmsPostModel";
import FileModel from "common/models/FileModel";
import DmsPostRepository from "common/repositories/DmsPostRepository";
import FileInput from "components/form/fileinput/FileInput";
import FormSection from "components/form/FormSection";
import FormSectionSeo from "components/form/FormSectionSeo";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import HtmlEditorInput from "components/htmleditorinput/HtmlEditorInput";
import DmsPostEditorHeader from "features/dmspost/form/DmsPostFormHeader";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { DmsPostJsonAddEdit } from "common/types/DmsPost";
const DmsPostEditor = ({ model }: { model: DmsPostModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const account = useLoginAccountStore((state) => state.account);

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const [type, setType] = useState<number>(0);

  // modal editing
  const initialValues = {
    type: model.type > 0 ? model.type : DmsPost.TYPE_SINGLE_PAGE,
    identifier: model.identifier,

    title: model.title,
    content: model.content,

    seo_slug: model.seo_slug,
    seo_title: model.seo_title,
    seo_keyword: model.seo_keyword,
    seo_description: model.seo_description,
    graph_image_id: model.graph_image_id,
    graph_image_input: {
      files: FileModel.convertToUploadFiles([model.graph_image]),
      first_file_id: model.graph_image_id,
    },

    file_id: model.file_id,
    image_input: {
      files: FileModel.convertToUploadFiles([model.image]),
      first_file_id: model.file_id,
    },
    display_order: model.display_order,
    status: model.status || DmsPost.STATUS_ENABLED,
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let file_id =
        typeof formData.image_input.file_id_list?.[0] !== "undefined"
          ? formData.image_input.file_id_list?.[0]
          : model.file_id;

      let graph_image_id =
        typeof formData.graph_image_input.file_id_list?.[0] !== "undefined"
          ? formData.graph_image_input.file_id_list?.[0]
          : model.graph_image_id;

      const submitData: DmsPostJsonAddEdit = {
        ...DmsPostModel.getDefaultData(),
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,

        type: formData.type,
        identifier: formData.identifier,

        title: formData.title,
        content: formData.content,

        seo_slug: formData.seo_slug,
        seo_title: formData.seo_title,
        seo_keyword: formData.seo_keyword,
        seo_description: formData.seo_description,
        graph_image_id: graph_image_id,

        status: formData.status || DmsPost.STATUS_ENABLED,
        file_id: file_id,
        display_order: formData.display_order,
      };

      return submitData;
    },
    [model.id, model.graph_image_id, model.file_id, account]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: DmsPostModel = await new DmsPostRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  const onTypeChange = (type: number) => {
    setType(type);
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <Col>
        <Form.Item name="image_input" label={t("dmspost:image")}>
          <FileInput
            objectType={File.OBJECTTYPE_ATTACHMENT}
            origin="dmsportal"
            max_item={1}
            allowExtensions={["pdf", "png", "jpeg"]}
          />
        </Form.Item>
      </Col>
      {isEditing ? (
        <Col>
          <Form.Item name="display_order" label={t("dmspost:display_order")}>
            <Input />
          </Form.Item>
        </Col>
      ) : null}
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={DmsPostModel.getStatusList()}
      />
    </div>
  );

  useEffect(() => {
    if (model.id > 0) {
      setType(model.type);
    }
  }, [model]);

  return (
    <>
      <DmsPostEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("dmspost:form.success.update")
            : t("dmspost:form.success.add")
        }
        redirectOnSuccess="/dmspost"
        error_translate_prefix="dmspost:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("dmspost:form.section_title")}
          subtitle={t("dmspost:form.section_description")}
          divider
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("dmspost:filter_type")}
                name="type"
                required
                rules={[
                  {
                    required: true,
                    message: t("dmspost:form.error.error_type_required"),
                  },
                ]}
              >
                <Select
                  disabled={isEditing ? true : false}
                  onChange={onTypeChange}
                  options={DmsPostModel.getTypeList()}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("dmspost:identifier")}
                name="identifier"
                required
                rules={[
                  {
                    required: true,
                    message: t("dmspost:form.error.error_identifier_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("dmspost:title")}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t("dmspost:form.error.error_title_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("news:content")} name="content" required>
                <HtmlEditorInput
                  fileObjectType={File.OBJECTTYPE_CMS}
                  fileOrigin="dmsportal"
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        {type === DmsPost.TYPE_SINGLE_PAGE ? (
          <FormSectionSeo
            fileObjectType={File.OBJECTTYPE_ATTACHMENT}
            fileOrigin="dmsportal"
          />
        ) : null}
      </LayoutForm>
    </>
  );
};

export default DmsPostEditor;
