import BaseRepository from "./BaseRepository";

import {
  FilterNotification,
  NotificationJsonAddEdit,
} from "common/types/Notification";
import type { GetListProps } from "common/types/Filter";
import NotificationApi from "common/api/NotificationApi";
import NotificationModel from "common/models/NotificationModel";
import NotificationCollection from "common/collections/NotificationCollection";

class NotificationRepository extends BaseRepository<NotificationApi> {
  _api: NotificationApi | null;
  constructor() {
    super();
    this._api = new NotificationApi(true);
  }

  static getDefaultFilters(): FilterNotification {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      pin: -1,
    };
  }

  async getItems(
    props: GetListProps<FilterNotification>
  ): Promise<NotificationCollection> {
    return this._api
      ? await this._api.getList(props)
      : new NotificationCollection();
  }

  async getItem(id: number): Promise<NotificationModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new NotificationModel(NotificationModel.getDefaultData());
  }

  async saveRemote(data: NotificationJsonAddEdit): Promise<NotificationModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new NotificationModel(NotificationModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async editPin(data: NotificationJsonAddEdit): Promise<string[]> {
    return this._api ? await this._api.editPin(data) : [];
  }

  async editStatus(data: NotificationJsonAddEdit): Promise<string[]> {
    return this._api ? await this._api.editStatus(data) : [];
  }
}

export default NotificationRepository;
