import BaseCollection from 'common/collections/BaseCollection';
import ShippingTypeModel from 'common/models/ShippingTypeModel';
import { ShippingTypeCollectionJson, ShippingTypeJson } from 'common/types/ShippingType';

class ShippingTypeCollection extends BaseCollection<
  ShippingTypeJson,
  ShippingTypeModel,
  ShippingTypeCollectionJson
> {
  itemsFromJson(jsonItems: ShippingTypeJson[]): ShippingTypeModel[] {
    return jsonItems.map((item) => new ShippingTypeModel(item));
  }
}

export default ShippingTypeCollection;
