import Store from "common/constants/Store";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { StoreJson } from "common/types/Store";
import { StoreShowweb } from "common/types/Store";
class StoreModel extends BaseModel implements BaseModelJson<StoreJson> {
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  code: string;
  phone: string;
  address: string;
  lat: number;
  long: number;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
  warehouse_ids: { id: number, name: string }[];
  tax_seller_name: string;
  tax_seller_address: string;
  tax_seller_phone: string;
  tax_seller_account_number: string;
  tax_seller_tax_number: string;
  status: number;
  date_created: number;
  date_modified: number;

  country: number
  province: number
  district: number
  ward: number
  road: string
  link_map: string
  show_web: StoreShowweb


  constructor(json: StoreJson) {
    super();

    this.id = json.id || 0;
    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.name = json.name || "";
    this.code = json.code || "";
    this.phone = json.phone || "";
    this.address = json.address || "";
    this.lat = json.lat || 0;
    this.long = json.long || 0;
    this.region_id = json.region_id || 0;
    this.sub_region_id = json.sub_region_id || 0;
    this.sub_sub_region_id = json.sub_sub_region_id || 0;
    this.warehouse_ids = json.warehouse_ids || [];
    this.tax_seller_name = json.tax_seller_name || "";
    this.tax_seller_address = json.tax_seller_address || "";
    this.tax_seller_phone = json.tax_seller_phone || "";
    this.tax_seller_account_number = json.tax_seller_account_number || "";
    this.tax_seller_tax_number = json.tax_seller_tax_number || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.country = json.country || 0
    this.province = json.province || 0
    this.district = json.district || 0
    this.ward = json.ward || 0

    this.link_map = json.link_map || ""
    this.road = json.road || ""
    this.show_web = json.show_web || StoreShowweb.NONE
  }

  static getDefaultData(): StoreJson {
    return {
      id: 0,
      creator_id: 0,
      company_id: 0,
      name: "",
      code: "",
      phone: "",
      address: "",
      lat: 0,
      long: 0,
      region_id: 0,
      sub_region_id: 0,
      sub_sub_region_id: 0,
      warehouse_ids: [],
      tax_seller_name: "",
      tax_seller_address: "",
      tax_seller_phone: "",
      tax_seller_account_number: "",
      tax_seller_tax_number: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
      country: 0,
      district: 0,
      province: 0,
      ward: 0,
      road: "",
      link_map: "",
      show_web: StoreShowweb.NONE
    };
  }

  toJson(): StoreJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      code: this.code,
      phone: this.phone,
      address: this.address,
      lat: this.lat,
      long: this.long,
      region_id: this.region_id,
      sub_region_id: this.sub_region_id,
      sub_sub_region_id: this.sub_sub_region_id,
      warehouse_ids: this.warehouse_ids,
      tax_seller_name: this.tax_seller_name,
      tax_seller_phone: this.tax_seller_phone,
      tax_seller_address: this.tax_seller_address,
      tax_seller_account_number: this.tax_seller_account_number,
      tax_seller_tax_number: this.tax_seller_tax_number,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      country: this.country,
      district: this.district,
      province: this.province,
      ward: this.ward,
      road: this.road,
      link_map: this.link_map,
      show_web: this.show_web
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: Store.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: Store.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getShowList(): SelectOption[] {
    return [
      {
        value: Store.SHOW_WEB,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: Store.NOT_SHOW_WEB,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }
  static getShow(value: number): SelectOption | undefined {
    return this.getShowList().find((item) => item.value === value);
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default StoreModel;
