import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const AdsCollectionListHeader = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const { t } = useTranslation();

	return (
		<PageHeader
			heading={t("Cài đặt thẻ quảng cáo ")}
			siteMenuSelectedKey="/shopadscollection"
			siteMenuOpenKey="shopads">
			{children}
		</PageHeader>
	);
};

export default AdsCollectionListHeader;
