import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const DeliveryInternalFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();
  const nav: Nav = [
    {
      text: t("sitemenu:group_delivery"),
      link: "",
    },
    {
      text: t("delivery:manage"),
      link: "/delivery/manager",
    },
    // {
    //   text: isEditing ? "Sửa vận đơn" : "Thêm mới vận đơn",
    // },
  ];
  return (
    <PageHeader
      nav={nav}
      heading={t(
        isEditing
          ? t("delivery:form.heading_edit")
          : t("delivery:form.heading_add")
      )}
      siteMenuOpenKey="delivery"
      siteMenuSelectedKey="/delivery/manager"
    ></PageHeader>
  );
};

export default DeliveryInternalFormHeader;
