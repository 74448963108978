import { OfficeJson } from "common/types/Office";
import useDatabaseTable from "hooks/useDatabaseTable";

const TextOffice = ({ id }: { id: number }) => {
  const [items] = useDatabaseTable<OfficeJson>("companyoffice");

  return <>{items.find((item) => item.id === id)?.name || "--"}</>;
};

export default TextOffice;
