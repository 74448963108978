import PromotionCouponCollection from "common/collections/PromotionCouponCollection";
import PromotionCouponCheckResultModel from "common/models/PromotionCouponCheckResultModel";
import PromotionCouponModel from "common/models/PromotionCouponModel";
import {
	PromotionCouponCheckQuery,
	PromotionCouponCheckResultJson,
} from "common/types/PromotionCheck";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
	FilterPromotionCoupon,
	PromotionCouponJsonAddEdit,
} from "common/types/PromotionCoupon";
import Helper from "common/utils/helper";
const SERVICE_URL = "/promotioncoupons";

class PromotionCouponApi extends BaseApi {
	async getList(
		props: GetListProps<FilterPromotionCoupon>
	): Promise<PromotionCouponCollection> {
		let collection = new PromotionCouponCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					status: +filters.status >= 0 ? filters.status : null,
					promotion_id: +filters.promotion_id > 0 ? filters.promotion_id : -1,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: number): Promise<PromotionCouponModel> {
		let item = new PromotionCouponModel(PromotionCouponModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new PromotionCouponModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (200 > response.status && response.status >= 300) {
				resultErrors.push("error_delete_response_not_2xx");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async add(data: PromotionCouponJsonAddEdit): Promise<PromotionCouponModel> {
		let item = new PromotionCouponModel(PromotionCouponModel.getDefaultData());

		try {
			let postData = {
				...data,
			};

			const convertData = Helper.convertParams(postData);

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, convertData);
			if (response.hasOwnProperty("data")) {
				item = new PromotionCouponModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(data: PromotionCouponJsonAddEdit): Promise<PromotionCouponModel> {
		let item = new PromotionCouponModel(PromotionCouponModel.getDefaultData());

		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new PromotionCouponModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async check(
		data: PromotionCouponCheckQuery
	): Promise<PromotionCouponCheckResultModel> {
		let promotionCouponCheckResult = new PromotionCouponCheckResultModel({});

		try {
			let postData = {
				...data,
			};

			const response = await AxiosClient().post<PromotionCouponCheckResultJson>(
				"/promotioncoupons/check",
				postData
			);
			if (response.hasOwnProperty("data")) {
				promotionCouponCheckResult = new PromotionCouponCheckResultModel(
					response.data
				);
			}
		} catch (error) {
			promotionCouponCheckResult.withError(BaseApi.handleError(error));
		}

		return promotionCouponCheckResult;
	}
}

export default PromotionCouponApi;
