import { Button, Popconfirm, Table, Tooltip, Typography } from "antd";
import Order from "common/constants/Order";
import OrderDetailModel from "common/models/OrderDetailModel";
import { TableColumnsType } from "common/types/Table";
import MoneyInput from "components/MoneyInput";
import QuantityInput from "components/QuantityInput";
import TextMoney from "components/TextMoney";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import TextProductVariantSku from "components/TextProductVariantSku";
import produce from "immer";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import { IconTrash } from "@tabler/icons-react";
import OrderFormListPromotion from "./OrderFormListPromotion";
import { CartOrderJson } from "common/types/Cart";
import { GiftFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ProductRepository from "common/repositories/ProductRepository";
import { ProductJson, ProductJson2 } from "common/types/Product";

const OrderFormListItem = ({
	totalQuantity,
	readonlyItems,
	items,
	onChangeItem,
	onRemoveItem,
	allowEditInput,
}: {
	totalQuantity: number;
	readonlyItems: CartOrderJson[];
	items: CartOrderJson[];
	onChangeItem: (v: CartOrderJson) => void;
	onRemoveItem: (v: CartOrderJson) => void;
	allowEditInput: boolean;
}) => {
	console.log("🚀 ~ items:", items);
	const { t } = useTranslation();
	const order = useEditingSaleOrderStore((state) => state.order);

	const columns: TableColumnsType<CartOrderJson> = [
		// {
		//   title: " ",
		//   dataIndex: "id",
		//   width: 50,
		//   align: "center",
		//   render: (_, record) => {
		//     return (
		//       <Popconfirm
		//         title={t("common:table.confirm_delete_title")}
		//         placement="topRight"
		//         onConfirm={() => {
		//           onRemoveItem(record);
		//         }}
		//         okText={t("common:table.confirm_ok")}
		//         cancelText={t("common:table.confirm_cancel")}
		//         disabled={
		//           order.status === Order.STATUS_COMPLETE ||
		//           order.status === Order.STATUS_CANCEL
		//             ? true
		//             : false
		//         }
		//       >
		//         <Button
		//           type="text"
		//           size="small"
		//           className="text-gray-300"
		//           icon={<IconTrash size={18} />}
		//           disabled={!allowEditInput}
		//           title={t("order:detail.remove")}
		//         ></Button>
		//       </Popconfirm>
		//     );
		//   },
		// },
		{
			title: t("order:detail.no"),
			dataIndex: "id",
			width: 60,
			align: "center",
			render: (_, __, index) => {
				return <>{index + 1}</>;
			},
		},
		{
			title: t("order:detail.item"),
			dataIndex: "item_name",
			width: 150,
			ellipsis: { showTitle: true },
			render: (item_name: string, record) => {
				return (
					<Tooltip
						title={
							<>
								{" "}
								<div className="flex flex-col">
									<div className="text-blue-600 text-md font-semibold">
										{item_name}
									</div>

									<div className="text-xs text-gray-400 mt-1">
										<Typography.Text
											className="text-white"
											ellipsis={{
												tooltip: <>Mã sản phẩm: {record.product_json.sku}</>,
											}}>
											Mã sản phẩm: {record.product_json.sku}
										</Typography.Text>
									</div>
								</div>
							</>
						}>
						<div className="flex flex-col">
							<div className="text-blue-600 text-md font-semibold text-wrap line-clamp-2">
								{item_name}{" "}
							</div>
							<div className="text-xs text-gray-400 mt-1">
								Mã sản phẩm: {record.product_json.sku}
							</div>
							<div>{record.promotions.length ? <GiftFilled /> : null}</div>
						</div>
						{/* <div className="text-gray-400 ">
              <TextProductVariantColor id={record.item_color} /> &middot;{" "}
              <TextProductVariantSize id={record.item_size} /> | SKU:
              <TextProductVariantSku id={record.product_variant_id} />
              {record.promotion_id > 0 &&
              typeof record.promotion_detail === "object" ? (
                <OrderFormListPromotion
                  promotion_detail={record.promotion_detail}
                />
              ) : null}
            </div> */}
					</Tooltip>
				);
			},
		},
		{
			title: t("order:detail.price"),
			dataIndex: "item_unit_price",
			align: "right",
			width: 150,
			render: (item_unit_price: number, record) => {
				//check to show original price different
				const readonlyRecord = readonlyItems.find((i) => i.id === record.id);
				const productJson = record.product_json;
				let oldPriceCom = null;
				if (
					typeof readonlyRecord !== "undefined" &&
					readonlyRecord.item_unit_price !==
						readonlyRecord.item_unit_price_original &&
					productJson.compare_at_price
				) {
					oldPriceCom = (
						<Tooltip
							title={
								<>
									{t("order:detail.price_original")}: &nbsp;
									{/* <TextMoney money={record.item_unit_price_original} /> */}
									<TextMoney money={productJson.compare_at_price} />
								</>
							}
							mouseEnterDelay={0.4}>
							<span
								className="inline-block pr-1 text-gray-500 line-through"
								style={{ verticalAlign: "sub" }}>
								{/* <TextMoney money={record.item_unit_price_original} /> */}
								<TextMoney money={productJson.compare_at_price} />
							</span>
						</Tooltip>
					);
				}
				return (
					<>
						{/* {oldPriceCom} */}
						<MoneyInput
							style={{ width: 120 }}
							disabled={!allowEditInput}
							value={item_unit_price}
						/>
					</>
				);
			},
		},
		{
			title: (
				<>
					{t("order:detail.quantity")} ({totalQuantity})
				</>
			),
			dataIndex: "item_quantity",
			align: "center",
			width: 120,
			render: (item_quantity: number, record) => {
				const readonlyRecord = readonlyItems.find((i) => i.id === record.id);
				let colorClass = "";
				if (
					typeof readonlyRecord === "undefined" ||
					readonlyRecord.item_quantity !== record.item_quantity
				) {
					colorClass = "border-yellow-300 ";
				}

				return (
					<>
						<QuantityInput
							controlsHoverOnly
							disabled={!allowEditInput}
							wrapperClassName={colorClass}
							className={"w-12"}
							size="middle"
							value={item_quantity}
						/>
					</>
				);
			},
		},
		{
			title: t("giá tạm tính"),
			dataIndex: "id",
			align: "right",
			width: 120,
			render: (_, record) => {
				return (
					<>
						<TextMoney money={record.item_unit_price * record.item_quantity} />
					</>
				);
			},
		},
		{
			title: t("giảm KM"),
			dataIndex: "id",
			align: "right",
			width: 120,
			render: (_, record) => {
				return (
					<>
						<TextMoney money={record.price_discount || 0} />
					</>
				);
			},
		},
		{
			title: t("order:detail.subtotal"),
			dataIndex: "id",
			align: "right",
			width: 120,
			render: (_, record) => {
				return (
					<>
						<TextMoney money={record.price_final} />
					</>
				);
			},
		},
	];

	return (
		<>
			<Table
				size="small"
				rowKey="id"
				pagination={{ hideOnSinglePage: true }}
				columns={columns}
				dataSource={items}
				scroll={{ x: 700 }}
			/>
		</>
	);
};

export default OrderFormListItem;
