import { useEffect, useState, useCallback } from "react";

import "./AcademyCustomCollection.css";
import { useNavigate, useParams } from "react-router-dom";
import usePathParams from "hooks/usePathParams";
import styles from "./styles.module.css";
import CollectionRepository from "common/repositories/CollectionRepository";
import Collection from "common/constants/Collection";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
type Category = {
  id: number;
  title: string;
  sub_categories: Category[];
  parent_id: number;
  description: string;
  detail: string;
  handle: string;
};
type Props = {
  onChange?: () => void;
  isMobile?: boolean;
};
const AcademyCustomCollection = ({ onChange, isMobile }: Props) => {
  const { catid } = useParams();

  const [pathParams] = usePathParams();
  const navigate = useNavigate();

  const firstHandle = pathParams.action.toString();
  const defaultCategory: Category = {
    id: 0,
    title: "",
    sub_categories: [],
    parent_id: -1,
    description: "",
    detail: "",
    handle: "",
  };

  const [categories, setCategories] = useState<Category[]>([]);

  //fetchItem
  // Component SidebarItem để hiển thị mục menu
  const [openItems, setOpenItems] = useState<Category[]>([]);

  const [parentItems, setParentItems] = useState<number[]>([]);

  const getListSideBar = async () => {
    const response = await new CollectionRepository().getItemsCategoryPublic({
      filters: { collection_id: 0, type: Collection.TYPE_ACADEMY },
    });
    if (response) {
      setCategories(response);
      // setOpenItems([response[0]]);
    }
  };

  const removeItemInArray = (selectArray: Category[], parent_id: number) => {
    for (let i = 0; i < selectArray.length; i++) {
      if (selectArray[i].parent_id === parent_id) {
        selectArray.splice(i, 1);
      }
    }
    return selectArray;
  };

  const toggleSubmenu = (item: Category) => {
    if (item.parent_id === 0) {
      setOpenItems([item]);
      setParentItems([0]);
    } else {
      let selectedItem = openItems;
      const findParentIdIndex: number = parentItems.findIndex(
        (date) => date === item.parent_id
      );
      if (findParentIdIndex > 0) {
        for (let i = 0; i < selectedItem.length; i++) {
          if (selectedItem[i].parent_id === item.parent_id) {
            let removeID = selectedItem[i].id;
            selectedItem.splice(i, 1);
            selectedItem = removeItemInArray(selectedItem, removeID);
          }
        }
        setOpenItems([...selectedItem, item]);
      } else {
        setParentItems((prev) => [...prev, item.parent_id]);
        setOpenItems((prev) => [...prev, item]);
      }
    }
  };

  const GetArrowIcon = useCallback(
    (item: Category) => {
      if (item.sub_categories.length > 0) {
        if (isActive(item.id)) {
          return <IconChevronUp />;
        } else {
          return <IconChevronDown />;
        }
      } else {
        return null;
      }
    },
    [openItems]
  );

  function findItemAndParents(items: Category[], idToFind: string) {
    let foundParents: number[] = [];

    function findItemRecursively(items: Category[], idToFind: string) {
      for (const item of items) {
        if (item.handle === idToFind) {
          foundParents = [item.parent_id, ...foundParents];
          setParentItems((prev) => [item.parent_id, ...prev]);
          setOpenItems((prev) => [item, ...prev]);
          return item;
        }

        if (item.sub_categories.length > 0) {
          if (findItemRecursively(item.sub_categories, idToFind)) {
            foundParents = [item.parent_id, ...foundParents];
            setParentItems((prev) => [item.parent_id, ...prev]);
            setOpenItems((prev) => [item, ...prev]);
            return item;
          }
        }
      }
      return null;
    }

    const foundItem = findItemRecursively(items, idToFind);

    return { foundItem, foundParents };
  }

  const isParent = useCallback(
    (parentId: number) => {
      if (parentId <= 0) {
        return true;
      } else {
        return false;
      }
    },
    [categories, openItems]
  );

  const isActive = (id: number) => {
    if (openItems.find((val) => val.id === id)) {
      return true;
    } else {
      return false;
    }
  };

  // Hàm tìm một item dựa trên handle

  useEffect(() => {
    setOpenItems([]);
    setParentItems([]);
    const { foundItem, foundParents } = findItemAndParents(
      categories,
      firstHandle
    );

    if (foundItem) {
    } else {
    }
  }, [categories, firstHandle]);

  useEffect(() => {
    getListSideBar();
  }, []);

  const SidebarItem = ({ item }: { item: Category }) => {
    return (
      <li
        className={`${styles.sidebar_item} ${
          isParent(item.parent_id) ? styles.sidebar_item_0 : ""
        } ${
          isParent(item.parent_id) && isActive(item.id)
            ? styles.sidebar_item_0_active
            : ""
        } ${
          isParent(item.parent_id) && isActive(item.id)
            ? styles.sidebar_item_active
            : ""
        }`}
      >
        <div
          onClick={() => {
            if (isMobile) {
              if (catid === item.handle) {
                onChange && onChange();
                return;
              } else {
                toggleSubmenu(item);
                navigate(`/academy/${item.handle}`);
                onChange && onChange();
              }
            } else {
              if (catid === item.handle) {
                return;
              } else {
                toggleSubmenu(item);
                navigate(`/academy/${item.handle}`);
              }
            }
          }}
          className={`inline-block ${styles.sidebar_item__title} ${
            isParent(item.parent_id) && isActive(item.id)
              ? styles.sidebar_item_0_title___active
              : ""
          } ${isActive(item.id) ? styles.sidebar_item_title___active : ""}`}
        >
          <span>{item.title}</span>
          <span>{GetArrowIcon(item)}</span>
        </div>
        {/* <AcademyTransition
          show={isActive(item.id) ? true : false}
          classNames="sub-menu"
          children={
            <ul
              className={`${styles.submenu} ${
                isActive(item.id) ? styles.submenu_active : ""
              }`}
            >
              {item.sub_categories.map((subItem) => (
                <SidebarItem key={subItem.id} item={subItem} />
              ))}
            </ul>
          }
        ></AcademyTransition> */}
        {isActive(item.id) && (
          <ul
            className={`${styles.submenu} ${
              isActive(item.id) ? styles.submenu_active : ""
            }`}
          >
            {item.sub_categories.map((subItem) => (
              <SidebarItem key={subItem.id} item={subItem} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className={`${styles.wrapper} ${isMobile && styles.wrapper_mobile}`}>
      <ul className={styles.ul}>
        {openItems.length > 0 &&
          categories.length > 0 &&
          categories
            .filter((i) => i.id === openItems[0].id)
            .map((item) => <SidebarItem key={item.id} item={item} />)}
      </ul>
    </div>
  );
};

export default AcademyCustomCollection;
