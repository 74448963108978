const ActionDelivery = {
  ACTION_CREATE: "ACTION_CREATE",
  ACTION_START: "ACTION_START",
  ACTION_COMPLETE: "ACTION_COMPLETE",
  ACTION_PAUSE: "ACTION_PAUSE",
  ACTION_CANCEL: "ACTION_CANCEL",
  ACTION_AGAIN: "ACTION_AGAIN",
  ACTION_TRANSFER: "ACTION_TRANSFER",
  ACTION_RECEIVE_COD: "ACTION_RECEIVE_COD",
  ACTION_PAYMENT_COD: "ACTION_PAYMENT_COD",
  ACTION_TIPS: "ACTION_TIPS",
  ACTION_APPROVE_TRANSFERS: "ACTION_APPROVE_TRANSFERS",
  ACTION_APPROVE_TIPS: "ACTION_APPROVE_TIPS",
  ACTION_APPROVE_RECEIVE_COD: "ACTION_APPROVE_RECEIVE_COD",
  ACTION_ARRIVED: "ACTION_ARRIVED",
  ACTION_DELIVERED: "ACTION_DELIVERED",
  ACTION_REFUND: "ACTION_REFUND",
  ACTION_APPROVE_REFUND: "ACTION_APPROVE_REFUND",
  ACTION_RETURNED: "ACTION_RETURNED",
  ACTION_WAITING: "ACTION_WAITING",
  ACTION_NEW: "ACTION_NEW",
};

export default ActionDelivery;
