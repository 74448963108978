import WarehouseModel from "common/models/WarehouseModel";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { WarehouseJson } from "common/types/Warehouse";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useState } from "react";

const TextWarehouse = ({
	id,
	warehouseItems,
}: {
	id: number;
	warehouseItems?: WarehouseJson[];
}) => {
	const [warehouseItemsDB] = useDatabaseTable<WarehouseJson>("warehouse");
	const items = warehouseItems || warehouseItemsDB;

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const [warehouse, setWareHouse] = useState(WarehouseModel.getDefaultData());

	const fetchData = useCallback(async () => {
		if (warehouseItems) {
			const dataFind = warehouseItems.find((i) => i.id === id);
			if (dataFind) {
				setWareHouse(dataFind);
			}
		} else {
			const data = await new WarehouseRepository().getItem(id);
			if (data.hasError()) {
				setErrors(data.error.errors);
			} else {
				setWareHouse(data.toJson());
			}
		}

		setLoading(false);
	}, [id, warehouseItems]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return <>{warehouse.name.length > 0 ? warehouse.name : "--"}</>;
};

export default TextWarehouse;
