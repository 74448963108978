import { App, Badge, Spin } from "antd";
import ProjectTask from "common/constants/ProjectTask";
import Role from "common/constants/Role";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectIterationRepository from "common/repositories/ProjectIterationRepository";
import ProjectTaskRepository from "common/repositories/ProjectTaskRepository";
import {
  FilterProjectTask,
  ProjectTaskKanbanColumn,
  ProjectTaskKanbanDraggableLocation,
  ProjectTaskKanbanTotalTaskPerColumn
} from "common/types/ProjectTask";
import eventEmitter from "common/utils/eventEmitter";
import Error from "components/LayoutError";
import useFilterLocation from "hooks/useFilterLocation";
import useWindowDimensions from "hooks/useWindowDimensions";
import update from "immutability-helper";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useProjectTaskLayoutStore from "zustands/useProjectTaskLayoutStore";

import {
  DragDropContext,
  DraggableLocation,
  DragStart,
  DragUpdate,
  DropResult
} from "@hello-pangea/dnd";

import ProjectTaskKanbanBoard from "./ProjectTaskKanbanBoard";
import ProjectTaskKanbanBoardMoreAction from "./ProjectTaskKanbanBoardMoreAction";

const ProjectTaskKanbanContainer = ({
  filters,
  projectModel,
  setTotal,
  setViewingTask
}: {
  filters: FilterProjectTask;
  projectModel: ProjectModel;
  setTotal: (v: number) => void;
  setViewingTask: (v: ProjectTaskModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const account = useLoginAccountStore((state) => state.account);
  const accountRoleSubjects = useLoginAccountStore((state) =>
    state.account.role.map((r) => r.subject_id)
  );
  const [layoutActived] = useProjectTaskLayoutStore((state) => [
    state.layoutActived
  ]);
  const eventEmitterAddTaskRef = useRef<any>();
  const eventEmitterChangeIterationRef = useRef<any>();

  const pageHeaderNavHeight = 60;
  const pageHeaderHeight = 56;
  const pageFilterHeight = 56;
  const pageHeaderTitleHeight = 45;

  //get screen height
  const { height, width } = useWindowDimensions();
  const boardHeight =
    height -
    pageHeaderNavHeight -
    pageHeaderHeight -
    pageFilterHeight -
    pageHeaderTitleHeight;

  const defaulKanbanColumns = useMemo(() => {
    return ProjectTaskModel.getStatusListForKanbanColumn();
  }, []);
  const [kanbanColumns, setKanbanColumns] =
    useState<ProjectTaskKanbanColumn[]>(defaulKanbanColumns);

  //////////////////////////////////////////
  // Kanban more action
  const [openMoreAction, setOpenMoreAction] = useState<boolean>(false);
  const [source, setSource] =
    useState<ProjectTaskKanbanDraggableLocation | null>(null);
  const [dest, setDest] = useState<DraggableLocation | null>(null);

  //////////////////////////////////////////
  //convert data to kanban items
  const [loading, setLoading] = useState<boolean>(true);
  const [kanbanTotalTaskPerColumn, setKanbanTotalTaskPerColumn] = useState<
    ProjectTaskKanbanTotalTaskPerColumn[]
  >([]);

  //////////////////////////////////////////
  // filter data
  const defaultFilters: FilterProjectTask = useMemo(
    () => ProjectTaskRepository.getDefaultFilters(),
    []
  );
  useFilterLocation(defaultFilters, filters);

  const convertDataToKanbanItems = useCallback(
    (dataSource: ProjectTaskModel[]) => {
      let getStatusList = ProjectTaskModel.getKanbanColumn();
      let kanbanColumns: ProjectTaskKanbanColumn[] = [];
      // init set total task per column
      let totalTaskPerColumn: ProjectTaskKanbanTotalTaskPerColumn[] = [];

      for (let index = 0; index < getStatusList.length; index++) {
        let status = getStatusList[index];
        let findValue = defaulKanbanColumns.find(
          (i) => i.value === status.value
        );
        if (findValue && findValue.value > 0) {
          let color = "";
          let filterItems: ProjectTaskModel[] = dataSource.filter(
            (i) => i.status === status.value
          );
          switch (status.value) {
            case ProjectTask.STATUS_PENDING:
              color = "#e798ba";
              break;
            case ProjectTask.STATUS_ASSIGNED:
              color = "#3498db";
              break;
            case ProjectTask.STATUS_WORKING:
              color = "#2980b9";
              break;
            case ProjectTask.STATUS_NEED_FOR_APPROVAL:
              color = "#ac0";
              break;
            case ProjectTask.STATUS_REVIEWING:
              color = "#8e44ad";
              break;
            case ProjectTask.STATUS_COMPLETED_WITH_BUG:
              color = "#CC4C9A";
              break;
            case ProjectTask.STATUS_COMPLETED:
              color = "#2ecc71";
              break;
            case ProjectTask.STATUS_CANCELLED:
              color = "#7f8c8d";
              break;
          }

          // filter by keyword
          if (filters.keyword !== "") {
            filterItems = dataSource.filter(
              (fKeyword) =>
                fKeyword.status === status.value &&
                (fKeyword.name
                  .toLowerCase()
                  .indexOf(filters.keyword.toString().toLowerCase()) >= 0 ||
                  fKeyword.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/[đĐ]/g, "d")
                    .toLowerCase()
                    .indexOf(
                      filters.keyword
                        .toString()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replace(/[đĐ]/g, "d")
                        .toLowerCase()
                    ) >= 0 ||
                  fKeyword.tags
                    .toLowerCase()
                    .indexOf(filters.keyword.toString().toLowerCase()) >= 0 ||
                  fKeyword.tags
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/[đĐ]/g, "d")
                    .toLowerCase()
                    .indexOf(
                      filters.keyword
                        .toString()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replace(/[đĐ]/g, "d")
                        .toLowerCase()
                    ) >= 0)
            );
          }
          // filter by assignee_tpm
          if (
            typeof filters.assignee_tpm === "string" &&
            filters.assignee_tpm !== ""
          ) {
            if (filters.assignee_tpm === "me") {
              filterItems = dataSource.filter(
                (fAssigneeMe) =>
                  fAssigneeMe.status === status.value &&
                  fAssigneeMe.assignees
                    .split(",")
                    .map((i) => +i)
                    .includes(account.id)
              );
            }
            if (filters.assignee_tpm === "orther") {
              filterItems = dataSource.filter(
                (fAssigneeMe) =>
                  fAssigneeMe.status === status.value &&
                  fAssigneeMe.assignees
                    .split(",")
                    .filter((i) => +i !== account.id).length > 0
              );
            }
          }
          // filter by code
          if (
            typeof filters.code === "string" &&
            filters.code !== "" &&
            filters.code.includes("-")
          ) {
            let taskId =
              typeof filters.code === "string" && filters.code?.split("-")[1];
            if (+taskId > 0) {
              filterItems = dataSource.filter(
                (fCode) => fCode.status === status.value && fCode.id === +taskId
              );
            }
          }
          // filter by priority
          if (filters.priority !== undefined && +filters.priority > 0) {
            filterItems = dataSource.filter(
              (fPriority) =>
                fPriority.status === status.value &&
                fPriority.priority === filters.priority
            );
          }
          // filter by type
          if (filters.type !== undefined && +filters.type > 0) {
            filterItems = dataSource.filter(
              (fType) =>
                fType.status === status.value && fType.type === +filters.type
            );
          }
          // filter by iteration_id
          if (filters.iteration_id !== undefined && +filters.iteration_id > 0) {
            filterItems = dataSource.filter(
              (fIterationId) =>
                fIterationId.status === status.value &&
                fIterationId.project_iteration_id === +filters.iteration_id
            );
          }

          if (
            filters.keyword !== "" ||
            (typeof filters.code === "string" &&
              filters.code !== "" &&
              filters.code.includes("-")) ||
            (typeof filters.assignee_tpm === "string" &&
              filters.assignee_tpm !== "") ||
            (filters.priority !== undefined && +filters.priority > 0) ||
            (filters.type !== undefined && +filters.type > 0) ||
            (filters.iteration_id !== undefined && +filters.iteration_id > 0)
          ) {
          }

          totalTaskPerColumn.push({
            status: status.value,
            total: filterItems.length,
            load_more: true // default true
          });

          kanbanColumns.push({
            value: status.value,
            label: status.label,
            color: color,
            items: filterItems
          });
        }
      }

      setKanbanTotalTaskPerColumn(totalTaskPerColumn);
      setKanbanColumns(kanbanColumns);
      setLoading(false);
    },
    [defaulKanbanColumns, filters, account.id]
  );

  const [projectIterationItems, setProjectIterationItems] = useState<
    ProjectIterationModel[]
  >([]);
  const fetchProjectIterationByIds = useCallback(async (ids: string) => {
    const collection = await new ProjectIterationRepository().getItemsByIds(
      ids
    );
    if (!collection.hasError()) {
      setProjectIterationItems(collection.items);
    }
  }, []);

  const [dataSource, setDataSource] = useState<ProjectTaskModel[]>([]);
  const fetchDataForKanban = useCallback(async () => {
    let collection = await new ProjectTaskRepository().getItems({
      filters: {
        ...ProjectTaskRepository.getDefaultFilters(),
        project_id: projectModel.id,
        company_id: account.company.id,
        is_pipeline: 1
      }
    });

    if (!collection.hasError()) {
      setTotal(collection.items.length);
      setDataSource(collection.items);

      // get project iteration
      let projectIterationIdList = collection.items
        .map((item) => item.project_iteration_id)
        .filter((i) => i > 0 && i !== undefined)
        .filter((x, i, a) => a.indexOf(x) === i);
      if (projectIterationIdList.length > 0) {
        fetchProjectIterationByIds(projectIterationIdList.join(","));
      }

      // convert item to kanban
      convertDataToKanbanItems(collection.items);
    }
  }, [
    account.company.id,
    convertDataToKanbanItems,
    projectModel.id,
    fetchProjectIterationByIds,
    setTotal
  ]);

  //////////////////////////////////////////
  //load more data for kanban
  const loadMoreData = useCallback(
    async (pageCount: number, status: number) => {
      let collection = await new ProjectTaskRepository().getItems({
        filters: {
          ...filters,
          project_id: projectModel.id,
          company_id: account.company.id,
          page: pageCount,
          status: status
        }
      });

      if (!collection.hasError()) {
        let newSource: ProjectTaskModel[] = update(dataSource, {
          $push: collection.items
        });
        setTotal(newSource.length);
        setDataSource(newSource);

        // get project iteration
        let projectIterationIdList = newSource
          .map((item) => item.project_iteration_id)
          .filter((i) => i > 0 && i !== undefined)
          .filter((x, i, a) => a.indexOf(x) === i);
        if (projectIterationIdList.length > 0) {
          fetchProjectIterationByIds(projectIterationIdList.join(","));
        }

        // set total task to column
        let foundIndex = kanbanTotalTaskPerColumn.findIndex(
          (i) => i.status === status
        );
        if (foundIndex >= 0) {
          // update total task per column, set load_more
          setKanbanTotalTaskPerColumn(
            update(kanbanTotalTaskPerColumn, {
              [foundIndex]: {
                $set: {
                  ...kanbanTotalTaskPerColumn[foundIndex],
                  total:
                    kanbanTotalTaskPerColumn[foundIndex].total +
                    collection.items.length,
                  load_more: collection.items.length > 50 ? true : false
                }
              }
            })
          );
        }

        // convert item to kanban
        convertDataToKanbanItems(newSource);
      }
    },
    [
      account.company.id,
      convertDataToKanbanItems,
      dataSource,
      filters,
      projectModel.id,
      setTotal,
      fetchProjectIterationByIds,
      kanbanTotalTaskPerColumn
    ]
  );

  //////////////////////////////////////////
  //Submit: update status
  const getTaskCode = useCallback(
    (item: ProjectTaskModel) => {
      let code = "";
      if (projectModel.story_prefix === "") {
        code = item.id.toString();
      } else {
        code = projectModel.story_prefix + "-" + item.id.toString();
      }

      return code;
    },
    [projectModel.story_prefix]
  );
  const canUpdateStatus = useCallback(
    (item: ProjectTaskModel) => {
      let canUpdate = 0;
      let canEdit = 0;
      // if current logged in user include ONE role need to check, it's PASS
      const hasRole =
        [Role.PROJECT_MANAGE].filter((r) => accountRoleSubjects.includes(r))
          .length > 0;

      if (
        hasRole ||
        projectModel.scrum_master.findIndex((i) => i === account.id) >= 0
      ) {
        canEdit = 1;
      }

      if (
        canEdit ||
        item.assignees.split(",").findIndex((i) => +i === account.id) >= 0
      ) {
        canUpdate = 1;
      }
      return canUpdate;
    },
    [accountRoleSubjects, account, projectModel]
  );
  const updateStatus = async (
    sourceItem: ProjectTaskModel,
    status: number,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 2
    });

    let myObj: ProjectTaskModel =
      await new ProjectTaskRepository().updateStatus({
        id: sourceItem.id,
        company_id: account.company.id,
        creator_id: account.id,
        status: status,
        progress_percent: 0,
        change_note: ""
      });

    if (myObj.hasError()) {
      setLoading(false);
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="projecttask:form.error"
            items={myObj.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 1
      });
      // do not update destination column and source column
      if (sourceIndex >= 0 && destinationIndex >= 0) {
        setKanbanColumns(
          update(kanbanColumns, {
            [destinationIndex]: {
              items: (sourceItems) =>
                sourceItems.filter((i) => i.id !== sourceItem.id)
            }
            // [sourceIndex]: {
            //   items: { $unshift: [sourceItem] }
            // }
          })
        );
      }
    } else {
      // update project task source after change status
      onSaveSuccess(myObj);
      setLoading(false);
    }
  };
  const onSaveSuccess = useCallback(
    (item: ProjectTaskModel) => {
      if (item.id > 0) {
        item.code = getTaskCode(item);
        item.can_update_status = canUpdateStatus(item);
        let foundIndex = dataSource.findIndex((i) => i.id === item.id);
        if (foundIndex >= 0) {
          // update
          let newSource = update(dataSource, {
            [foundIndex]: {
              $set: item
            }
          });

          setDataSource(newSource);

          // get project iteration
          let projectIterationIdList = newSource
            .map((item) => item.project_iteration_id)
            .filter((i) => i > 0 && i !== undefined)
            .filter((x, i, a) => a.indexOf(x) === i);
          if (projectIterationIdList.length > 0) {
            fetchProjectIterationByIds(projectIterationIdList.join(","));
          }

          // convert item to kanban
          convertDataToKanbanItems(newSource);
        } else {
          // do notthing
        }

        message.success({
          content: t("common:form.success.save"),
          className: "message_success",
          key: "message",
          duration: 3
        });
      }
    },
    [
      dataSource,
      t,
      getTaskCode,
      canUpdateStatus,
      convertDataToKanbanItems,
      fetchProjectIterationByIds,
      message
    ]
  );

  //////////////////////////////////////////
  //Handle event: onDragStart
  const handleOnDragStart = (start: DragStart) => {
    const { source } = start;
    setSource({ ...source, columnValue: +source.droppableId });
  };

  //////////////////////////////////////////
  //Handle event: onDragEnd
  const handleOnDragEnd = (result: DropResult) => {
    // if the user drops outside of a droppable destination
    if (!result.destination) {
      // close action
      setOpenMoreAction(false);
      setSource(null);
      setDest(null);
      return;
    }

    const { source, destination, draggableId } = result;

    // If the user drops in a different postion
    if (source.droppableId !== destination.droppableId) {
      let sourceColumn = kanbanColumns.find(
        (i) => i.value === +source.droppableId
      );
      let destColumn = kanbanColumns.find(
        (i) => i.value === +destination.droppableId
      );

      if (
        sourceColumn &&
        sourceColumn.value > 0 &&
        destColumn &&
        destColumn.value > 0
      ) {
        let findSourceItem = sourceColumn.items.find(
          (i) => i.id === +draggableId
        );

        if (findSourceItem && findSourceItem.id > 0) {
          // check if the user brlongs to scrum_master
          let checkUserInScrumMaster = projectModel.scrum_master.includes(
            account.id
          );
          // check if the user belongs to that project or not
          let findUserInTask = findSourceItem.assignees
            .split(",")
            .map((i) => {
              return +i;
            })
            .includes(account.id);
          if (findUserInTask || checkUserInScrumMaster) {
            // find source index to move item from source
            let findSourceIndex = kanbanColumns.findIndex(
              (i) => i.value === +source.droppableId
            );
            // find dest index to add item to destination
            let findDestinationIndex = kanbanColumns.findIndex(
              (i) => i.value === +destination.droppableId
            );

            // update total task for source
            let findSourceIndexInKanbanTotalTask =
              kanbanTotalTaskPerColumn.findIndex(
                (i) => i.status === sourceColumn?.value
              );
            // update total task for destination
            let findDestinationIndexInKanbanTotalTask =
              kanbanTotalTaskPerColumn.findIndex(
                (i) => i.status === destColumn?.value
              );

            if (
              findSourceIndexInKanbanTotalTask >= 0 &&
              findDestinationIndexInKanbanTotalTask >= 0
            ) {
              let kanbanTotalTaskInSource =
                kanbanTotalTaskPerColumn[findSourceIndexInKanbanTotalTask]
                  .total - 1;
              let kanbanTotalTaskInDestination =
                kanbanTotalTaskPerColumn[findDestinationIndexInKanbanTotalTask]
                  .total + 1;
              setKanbanTotalTaskPerColumn(
                update(kanbanTotalTaskPerColumn, {
                  [findSourceIndexInKanbanTotalTask]: {
                    $set: {
                      ...kanbanTotalTaskPerColumn[
                        findSourceIndexInKanbanTotalTask
                      ],
                      total: kanbanTotalTaskInSource
                    }
                  },
                  [findDestinationIndexInKanbanTotalTask]: {
                    $set: {
                      ...kanbanTotalTaskPerColumn[
                        findDestinationIndexInKanbanTotalTask
                      ],
                      total: kanbanTotalTaskInDestination
                    }
                  }
                })
              );
            }

            if (findSourceIndex >= 0 && findDestinationIndex >= 0) {
              setKanbanColumns(
                update(kanbanColumns, {
                  [findSourceIndex]: {
                    items: (sourceItems) =>
                      sourceItems.filter((i) => i.id !== findSourceItem?.id)
                  },
                  [findDestinationIndex]: {
                    items: { $unshift: [findSourceItem] }
                  }
                })
              );
            }

            updateStatus(
              findSourceItem,
              destColumn.value,
              findSourceIndex,
              findDestinationIndex
            );
          }
        }
      }
    } else {
      // If the user drags and drops back in the same position
      // do not thing
    }

    // close action
    setOpenMoreAction(false);
    setSource(null);
    setDest(null);
  };

  useEffect(() => {
    // call api init
    if (layoutActived === ProjectTask.LAYOUT_KANBAN && loading) {
      fetchDataForKanban();
    }
  }, [fetchDataForKanban, layoutActived, loading, filters]);

  useEffect(() => {
    fetchDataForKanban();
  }, [fetchDataForKanban, filters]);

  useEffect(() => {
    if (!eventEmitterAddTaskRef.current) {
      eventEmitterAddTaskRef.current = eventEmitter.addListener(
        "PROJECTTASK_KANBAN_CHANGE_TASK",
        (_data: any) => {
          fetchDataForKanban();
        }
      );
    }

    if (!eventEmitterChangeIterationRef.current) {
      eventEmitterChangeIterationRef.current = eventEmitter.addListener(
        "PROJECTTASK_KANBAN_CHANGE_ITERATION",
        (_data: any) => {
          setProjectIterationItems(_data);
        }
      );
    }
  }, [fetchDataForKanban]);

  useEffect(() => {
    return () => {
      if (eventEmitterAddTaskRef.current) {
        eventEmitterAddTaskRef.current.remove();
      }
      if (eventEmitterChangeIterationRef.current) {
        eventEmitterChangeIterationRef.current.remove();
      }
    };
  }, []);

  return (
    <>
      <div style={{ height: height }}>
        <DragDropContext
          key={"project-task-kanban-board"}
          onBeforeCapture={() => setOpenMoreAction(true)}
          onBeforeDragStart={(start: DragStart) => handleOnDragStart(start)}
          onDragUpdate={(update: DragUpdate) => {
            if (update.destination !== null) {
              setDest(update.destination);
            }
          }}
          onDragEnd={(result: DropResult) => handleOnDragEnd(result)}>
          <Spin spinning={loading}>
            <div className="grid grid-cols-6 gap-2">
              {Object.entries(kanbanColumns).map(
                ([columnId, column], index) => {
                  return (
                    <div
                      style={{
                        height:
                          column.value !== ProjectTask.STATUS_COMPLETED &&
                          column.value !== ProjectTask.STATUS_CANCELLED
                            ? boardHeight
                            : 60,
                        marginLeft:
                          column.value === ProjectTask.STATUS_COMPLETED
                            ? 0
                            : column.value === ProjectTask.STATUS_CANCELLED
                            ? width / 3 - 14
                            : 0
                      }}
                      key={columnId}>
                      {column.value !== ProjectTask.STATUS_COMPLETED &&
                      column.value !== ProjectTask.STATUS_CANCELLED ? (
                        <div
                          className="flex items-center"
                          style={{ height: pageHeaderTitleHeight }}>
                          <h2
                            className="my-2 text-sm font-bold text-gray-400"
                            style={{ color: column.color }}>
                            {column.label}
                            <Badge
                              className="ml-2 -mt-1"
                              count={column.items.length}
                              showZero
                              style={{ background: column.color }}
                            />
                          </h2>
                        </div>
                      ) : null}

                      {column.value !== ProjectTask.STATUS_COMPLETED &&
                      column.value !== ProjectTask.STATUS_CANCELLED ? (
                        <ProjectTaskKanbanBoard
                          key={"project-task-kanban-board-" + column.value}
                          column={column}
                          projectIterationItems={projectIterationItems}
                          setViewingTask={setViewingTask}
                          source={source}
                          destination={dest}
                          loadMoreData={(page: number, status: number) =>
                            loadMoreData(page, status)
                          }
                          kanbanTotalTaskPerColumn={kanbanTotalTaskPerColumn}
                        />
                      ) : (
                        <div className="grid grid-cols-2 gap-2">
                          <ProjectTaskKanbanBoardMoreAction
                            key={
                              "project-task-kanban-board-more-action-" +
                              column.value
                            }
                            projectModel={projectModel}
                            column={column}
                            activeAction={openMoreAction}
                          />
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </Spin>
        </DragDropContext>
      </div>
    </>
  );
};

export default ProjectTaskKanbanContainer;
