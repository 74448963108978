import ProductTransmit from 'common/constants/ProductTransmit';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';
import ProductReceiptModel from './ProductReceiptModel';

import type { ProductTransmitJson } from "common/types/ProductTransmit";

class ProductTransmitModel
  extends BaseModel
  implements BaseModelJson<ProductTransmitJson>
{
  company_id: number;
  creator_id: number;
  warehouse_id_from: number;
  warehouse_id_to: number;
  id: number;
  identifier: string;
  note: string;
  tags: string;
  notify_user_id_list: number[];
  receipt_id_from: number;
  receipt_id_to: number;
  receipt_from: ProductReceiptModel;
  receipt_to: ProductReceiptModel;
  approver: number;
  status: number;
  date_created: number;
  date_modified: number;
  date_approved: number;

  constructor(json: ProductTransmitJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.warehouse_id_from = json.warehouse_id_from || 0;
    this.warehouse_id_to = json.warehouse_id_to || 0;
    this.id = json.id || 0;
    this.identifier = json.identifier || "";
    this.note = json.note || "";
    this.tags = json.tags || "";
    this.notify_user_id_list = json.notify_user_id_list || [];
    this.receipt_id_from = json.receipt_id_from || 0;
    this.receipt_id_to = json.receipt_id_to || 0;
    this.receipt_from = json.receipt_from || [];
    this.receipt_to = json.receipt_to || [];
    this.approver = json.approver || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_approved = json.date_approved || 0;
  }

  static getDefaultData(): ProductTransmitJson {
    return {
      company_id: 0,
      creator_id: 0,
      warehouse_id_from: 0,
      warehouse_id_to: 0,
      id: 0,
      identifier: "",
      note: "",
      tags: "",
      notify_user_id_list: [],
      receipt_id_from: 0,
      receipt_id_to: 0,
      receipt_to: new ProductReceiptModel(ProductReceiptModel.getDefaultData()),
      receipt_from: new ProductReceiptModel(
        ProductReceiptModel.getDefaultData()
      ),
      approver: 0,
      status: ProductTransmit.STATUS_PENDING,
      date_created: 0,
      date_modified: 0,
      date_approved: 0,
    };
  }

  toJson(): ProductTransmitJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      warehouse_id_from: this.warehouse_id_from,
      warehouse_id_to: this.warehouse_id_to,
      id: this.id,
      identifier: this.identifier,
      note: this.note,
      tags: this.tags,
      notify_user_id_list: this.notify_user_id_list,
      receipt_id_from: this.receipt_id_from,
      receipt_id_to: this.receipt_id_to,
      receipt_from: this.receipt_from,
      receipt_to: this.receipt_to,
      approver: this.approver,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_approved: this.date_approved,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ProductTransmit.STATUS_PENDING,
        label: i18n.t("producttransmit:STATUS_PENDING"),
        color: "gray",
      },
      {
        value: ProductTransmit.STATUS_APPROVED,
        label: i18n.t("producttransmit:STATUS_APPROVED"),
        color: "green",
      },
      {
        value: ProductTransmit.STATUS_CANCELLED,
        label: i18n.t("producttransmit:STATUS_CANCELLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default ProductTransmitModel;
