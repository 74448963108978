import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductTransmitFormHeader = ({
  isEditing,
  id,
}: {
  isEditing: boolean;
  id: number;
}) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("producttransmit:heading_nav"),
      link: "/producttransmit",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={
        t(
          isEditing
            ? "producttransmit:heading_edit"
            : "producttransmit:heading_add"
        ) + (id > 0 ? " #" + id : "")
      }
      siteMenuSelectedKey="inventory"
      siteMenuHidden
    ></PageHeader>
  );
};

export default ProductTransmitFormHeader;
