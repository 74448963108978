import {IconTag } from "@tabler/icons-react";
import {
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import TagConstant from "common/constants/Tag";
import ProductModel from "common/models/ProductModel";
import TagDetailModel from "common/models/TagDetailModel";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { SelectOption } from "common/types/SelectOption";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductTag = ({
  id,
  onSaveSuccess,
}: {
  id: number;
  onSaveSuccess: (m: ProductModel | any) => void;
}) => {
  const { t } = useTranslation();

  const [processing, setProcessing] = useState(false);
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>([]);

  //submit tag detail
  const onSubmitTagDetail = async (v: SelectOption[]) => {
    setProcessing(true);
    if (v.length > 0) {
      message.loading({ content: "Vui lòng đợi", duration: 1 });
      const response = await new TagRepository().addTagDetail({
        details: v.map((item, index) => {
          return {
            tag_id: item.value,
            resource_id: id,
            resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
            // display_order: index + 1,
          };
        }),
      });
      if (!response.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue([...existValues, ...v.map((item) => item.value)]);
        setSelectReload(!selectReload);
        setProcessing(false);
        return true;
      } else {
        message.error(response.error.errors);
        setProcessing(false);
        return false;
      }
    }
  };

  //delete collection detial
  const onDeleteSelectedItem = async (v: SelectOption) => {
    setProcessing(true);
    message.loading({ content: "Vui lòng đợi", duration: 1 });
    const findTagDetailId = await new TagRepository().getItemsDetail({
      filters: {
        ...TagRepository.getDefaultTagDetailFilters(),
        resource_id: id,
        resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
        tag_id: v.value,
      },
    });
    if (!findTagDetailId.hasError() && findTagDetailId.items.length > 0) {
      const reponse = await new TagRepository().deleteTagDetailItem([
        findTagDetailId.items[0].id,
      ]);
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue(existValues.filter((item) => item !== v.value));
        setSelectReload(!selectReload);
      } else {
        message.error("Thao tác thất bại");
      }
    } else {
      message.error("Thao tác thất bại");
    }
    setProcessing(false);
  };

  //update existvalue
  const changeTagExistValue = (newTag: TagDetailModel[]) => {
    setExistValue(newTag.map((item) => item.tag_id));
  };

  const handleRemoveCurrentItem = async (v: SelectOption[]) => {
    setProcessing(true);

    if (v.length > 0) {
      const reponse = await new TagRepository().deleteTagDetailItem(
        v.map((item) => item.value)
      );
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        // setExistValue(existValues.filter((item) => item !== v.value));
        setSelectReload(!selectReload);
        setProcessing(false);
        return true;
      } else {
        message.error("Thao tác thất bại");
        setProcessing(false);
        return false;
      }
    }
    setProcessing(false);
  };


  return (

    <div>
      <p className="mb-4">
        <IconTag className="mr-2" size={18} />
        Quản lý tag
      </p>
      <AddListToItemComponent
        type={"tagproduct"}
        reload={selectReload}
        existValues={existValues}
        id={id}
        onSaveSuccess={onSubmitTagDetail}
        handleRemoveItem={(v: SelectOption) => {
          onDeleteSelectedItem(v);
        }}
        handleRemoveCurrentItem={handleRemoveCurrentItem}
        changeExistValue={changeTagExistValue}
        renderItems={(item: TagModel) => {
          return (
            <>
              <div className="flex justify-between items-center">
                <Tooltip
                  title={
                    <>
                      <div>ID: {item.id}</div>
                      <div>Code: {item.code}</div>
                      <div>Tên: {item.name}</div>
                      <div>Giá trị: {item.value}</div>
                    </>
                  }
                >
                  <Tag className="w-fit">{item.name}</Tag>
                </Tooltip>
                {item.type.length > 0 && (
                  <Typography.Text className="">
                    (Loại: {item.name})
                    (giá trị: {item.value})
                  </Typography.Text>
                )}
              </div>
            </>
          );
        }}
      ></AddListToItemComponent>
    </div>
  );
};

export default ProductTag;
