import StoreCollection from "common/collections/StoreCollection";
import Role from "common/constants/Role";
import Store from "common/constants/Store";
import StoreModel from "common/models/StoreModel";
import StoreRepository from "common/repositories/StoreRepository";
import { FilterStore, StoreJson } from "common/types/Store";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import StoreListHeader from "features/store/list/StoreListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import StoreListFilter from "./StoreListFilter";
import { Select } from "antd";

const StoreList = () => {
  const { t } = useTranslation();
  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterStore = useMemo(
    () => StoreRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterStore>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<StoreModel[]>([]);
  const fetchData = useCallback(async (): Promise<StoreCollection> => {
    let collection = await new StoreRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
    return collection;
  }, [filters]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<StoreModel> = [
    // {
    //   title: t("common:sid"),
    //   key: "id",
    //   align: "center",
    //   width: 60,
    //   render: (id, record) => {
    //     return (
    //       <TableLinkText
    //         title={t("common:table.edit")}
    //         link={"/store/edit/id/" + record.id}
    //       >
    //         {id}
    //       </TableLinkText>
    //     );
    //   },
    // },
    {
      title: t("store:name"),
      key: "name",
      sortby: "name",
      width: 300,

      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            link={"/store/edit/id/" + record.id}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("store:code"),
      width: 200,
      key: "code",
      sortby: "code",
    },
    {
      title: t("store:address"),
      key: "address",
    },
    {
      title: t("common:phone"),
      key: "phone",
      width: 120,
    },
    {
      title: t("common:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = StoreModel.getStatus(status);
        return (
          <span
            className={status === Store.STATUS_DISABLED ? "text-red-500" : ""}
          >
            {statusInfo?.label}
          </span>
        );
      },
      width: 110,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: StoreModel) => (
        <RoleCheck roles={[Role.SETTING_SALE]} hideOnFail>
          <TableEdit link={"/store/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="store:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new StoreRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <StoreListHeader>
        <RoleCheck roles={[Role.SETTING_SALE]}>
          <PageHeaderButton link="/store/add">
            {t("common:table.add_button")}
          </PageHeaderButton>
        </RoleCheck>
      </StoreListHeader>
      <StoreListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<FilterStore, StoreModel, StoreCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default StoreList;

