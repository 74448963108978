import { DatePicker, DatePickerProps } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import {
	DataPickerPlacements,
	DateTimePickerModes,
} from "common/types/DateTime";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;
export type RangeValue = [Dayjs | null, Dayjs | null] | null;

const DateFormFilter = ({
	value,
	rangeValue,
	mode,
	format,
	placement,
	className,
	placeholder,
	placeholderRange,
	onChange,
	onRangeChange,
	showNow,
	allowClear,
	rangeDefaultValue,
}: {
	value?: Dayjs | null;
	rangeValue?: RangeValue;
	mode: DateTimePickerModes;
	format: string;
	placement?: DataPickerPlacements;
	className?: string;
	placeholder?: string;
	placeholderRange?: [string, string];
	onChange?: DatePickerProps["onChange"];
	onRangeChange?: RangePickerProps["onChange"];
	showNow?: boolean;
	allowClear?: boolean;
	rangeDefaultValue?: [dayjs.Dayjs, dayjs.Dayjs];
}) => {
	return (
		<>
			{mode === "single" ? (
				<DatePicker
					value={value}
					changeOnBlur
					allowClear={allowClear}
					showNow={showNow}
					className={className}
					format={format}
					placement={placement}
					onChange={(date, dateString) => {
						if (onChange !== undefined && typeof date !== "undefined") {
							onChange(date, dateString);
						}
					}}
				/>
			) : (
				<RangePicker
					defaultValue={rangeDefaultValue}
					value={rangeValue}
					changeOnBlur
					allowEmpty={[true, true]}
					allowClear={allowClear}
					showNow={showNow}
					className={className}
					format={format}
					placement={placement}
					placeholder={placeholderRange}
					onChange={(date, dateString) => {
						if (onRangeChange !== undefined) {
							onRangeChange(date, dateString);
						}
					}}
				/>
			)}
		</>
	);
};

export default DateFormFilter;
