import CompanySettingModel from "common/models/CompanySettingModel";
import CompanySettingRepository from "common/repositories/CompanySettingRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import SettingGeneral from "features/setting/SettingGeneral";
import SettingGeneralHeader from "features/setting/SettingGeneralHeader";
import SettingTax from "features/setting/SettingTax";
import SettingTaxHeader from "features/setting/SettingTaxHeader";
import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

const SettingPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<CompanySettingModel>(
    new CompanySettingModel(CompanySettingModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new CompanySettingModel(CompanySettingModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;
  switch (pathParams.action) {
    case "general":
      com =
        typeof model.data.currency !== "undefined" ? (
          <SettingGeneral data={model.data} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel =
                await new CompanySettingRepository().getCurrentSettings();
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<SettingGeneralHeader />}
          />
        );

      break;
    case "tax":
      com =
        typeof model.data.currency !== "undefined" ? (
          <SettingTax data={model.data} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel =
                await new CompanySettingRepository().getCurrentSettings();
              if (fetchedModel.hasError()) {
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<SettingTaxHeader />}
          />
        );

      break;
    default:
  }

  return com;
};

export default SettingPage;
