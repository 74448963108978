import ProductInventoryChecking from "common/constants/ProductInventoryChecking";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type {
  ProductInventoryAttachments,
  ProductInventoryCheckingJson,
} from "common/types/ProductInventoryChecking";

class ProductInventoryCheckingModel
  extends BaseModel
  implements BaseModelJson<ProductInventoryCheckingJson>
{
  company_id: number;
  creator_id: number;
  warehouse_id: number;
  id: number;
  identifier: string;
  price_sub_total: number;
  price_shipping: number;
  price_handling: number;
  price_discount: number;
  price_total: number;
  note: string;
  sku_group: any;
  status: number;
  date_created: number;
  date_modified: number;
  attachments: ProductInventoryAttachments[];
  warning: number;
  description: string;
  code: string;
  constructor(json: ProductInventoryCheckingJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.warehouse_id = json.warehouse_id || 0;
    this.id = json.id || 0;
    this.identifier = json.identifier || "";
    this.price_sub_total = json.price_sub_total || 0;
    this.price_shipping = json.price_shipping || 0;
    this.price_handling = json.price_handling || 0;
    this.price_discount = json.price_discount || 0;
    this.price_total = json.price_total || 0;
    this.note = json.note || "";
    // this.sku_group = json.sku_group || [];
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.attachments = json.attachments;
    this.warning = json.warning;
    this.note = json.note;
    this.description = json.description;
    this.code = json.code;
  }

  static getDefaultData(): ProductInventoryCheckingJson {
    return {
      company_id: 0,
      creator_id: 0,
      warehouse_id: 0,
      id: 0,
      identifier: "",
      price_sub_total: 0,
      price_shipping: 0,
      price_handling: 0,
      price_discount: 0,
      price_total: 0,
      note: "",
      // sku_group: [],
      status: ProductInventoryChecking.STATUS_PENDING,
      date_created: 0,
      date_modified: 0,
      attachments: [],
      warning: 0,
	  code : "",
	  description :""
    };
  }

  toJson(): ProductInventoryCheckingJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      warehouse_id: this.warehouse_id,
      id: this.id,
      identifier: this.identifier,
      price_sub_total: this.price_sub_total,
      price_shipping: this.price_shipping,
      price_handling: this.price_handling,
      price_discount: this.price_discount,
      price_total: this.price_total,
      note: this.note,
      // sku_group: this.sku_group,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      attachments: this.attachments,
      warning: this.warning,
	  code : this.code,
	  description : this.description
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ProductInventoryChecking.STATUS_PENDING,
        label: i18n.t("productinventorychecking:STATUS_PENDING"),
        color: "gray",
      },
      {
        value: ProductInventoryChecking.STATUS_REJECT,
        label: i18n.t("Bị từ chối"),
        color: "red",
      },
      {
        value: ProductInventoryChecking.STATUS_PROCESSING,
        label: i18n.t("productinventorychecking:STATUS_PROCESSING"),
        color: "yellow",
      },
      {
        value: ProductInventoryChecking.STATUS_COMPLETED,
        label: i18n.t("productinventorychecking:STATUS_COMPLETED"),
        color: "green",
      },
      {
        value: ProductInventoryChecking.STATUS_CANCELLED,
        label: i18n.t("productinventorychecking:STATUS_CANCELLED"),
        color: "red",
      },
    ];
  }

  static getWarningList(): SelectOption[] {
    return [
      {
        value: ProductInventoryChecking.WARNING_CHECK,
        label: i18n.t("productinventorychecking:WARNING_CHECK"),
        color: "red",
      },
      {
        value: ProductInventoryChecking.WARNING_NONE,
        label: i18n.t("productinventorychecking:WARNING_NONE"),
        color: "green",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getWarning(value: number): SelectOption | undefined {
    return this.getWarningList().find((item) => item.value === value);
  }
}

export default ProductInventoryCheckingModel;
