import React from 'react';

const TableInfoRow = ({
  label,
  content,
}: {
  content?: React.ReactNode;
  label?: React.ReactNode;
}) => {
  return (
    <div className=" leading-relaxed">
      <span className="text-xs uppercase text-gray-500 font-semibold">
        {label}:{" "}
      </span>
      {content}
    </div>
  );
};

export default TableInfoRow;
