import BaseCollection from "common/collections/BaseCollection";
import EcomModel from "common/models/EcomModel";
import { EcomJson, EcomCollectionJson } from "common/types/Ecom";

class EcomCollection extends BaseCollection<
  EcomJson,
  EcomModel,
  EcomCollectionJson
> {
  itemsFromJson(jsonItems: EcomJson[]): EcomModel[] {
    return jsonItems.map((item) => new EcomModel(item));
  }
}

export default EcomCollection;


