import ProductReceipt from "common/constants/ProductReceipt";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";

import { IconFileMinus, IconFilePlus } from "@tabler/icons-react";
import usePathParams from "hooks/usePathParams";
import { useMemo } from "react";

const InventoryReceiptListHeader = () => {
	const [pathParams] = usePathParams();

	const { t } = useTranslation();
	const heading = useMemo(() => {
		switch (pathParams.action) {
			case "in":
				return "Phiếu nhập";
			case "out":
				return "Phiếu xuất";
			default:
				return "";
		}
	}, [pathParams.action]);

	const siteMenuSelectUrl = useMemo(() => {
		let url = "/inventoryreceipt";
		if (pathParams.action) {
			url += `/${pathParams.action}`;
		}

		return url;
	}, [pathParams.action]);
	return (
		<PageHeader heading={heading} siteMenuSelectedKey={siteMenuSelectUrl}>
			<RoleCheck roles={[Role.INVENTORY_ADD]}>
				{pathParams.action === "in" ? (
					<PageHeaderButton
						type="primary"
						link={
							"/inventoryreceipt/add/direction/" +
							ProductReceipt.DIRECTION_INPUT
						}
						className="mr-2"
						icon={<IconFilePlus size={18} className="mr-0.5 -mt-0.5" />}>
						{t("productreceipt:add_button_in")}
					</PageHeaderButton>
				) : (
					<></>
				)}
				{pathParams.action === "out" ? (
					<PageHeaderButton
						type="primary"
						link={
							"/inventoryreceipt/add/direction/" +
							ProductReceipt.DIRECTION_OUTPUT
						}
						icon={<IconFileMinus size={18} className="mr-0.5 -mt-0.5" />}>
						{t("productreceipt:add_button_out")}
					</PageHeaderButton>
				) : (
					<></>
				)}
			</RoleCheck>
		</PageHeader>
	);
};

export default InventoryReceiptListHeader;
