const ApiKey = {
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,
  STATUS_LOCKED: 5,

  TYPE_PRIVATE: 1,
  TYPE_PUBLIC: 3,
};

export default ApiKey;
