const StoryCollectionConstant = {
  TYPE_STORY: 1,
  TYPE_ACADEMY: 2,
  TYPE_ADMIN: 3,
  CONTENT_TYPE: {
    CONTENT_IMAGE: 1,
    CONTENT_VIDEO: 2,
    CONTENT_AUDIO: 3,
  },
};

export default StoryCollectionConstant;
