import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
const AcademyNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h1>KHÔNG TÌM THẤY TRANG </h1>
      </div>
      <div className={styles.content}>
        <h3>Xin lỗi, chúng tôi không tìm thấy nội dung !</h3>
        <div className={styles.content_action}>
          <button
            onClick={() => {
              navigate(-2);
            }}
            className={styles.action_back}
          >
            Trở lại
          </button>

          <button
            onClick={() => {
              navigate("/academy");
            }}
            className={styles.action_back}
          >
            Quay về Trang chủ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcademyNotFound;
