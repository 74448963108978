import SettingRepository from "common/repositories/SettingRepository";
import { SettingAddEditJson, SettingJson } from "common/types/Setting";
type Props = {
	settingJson: SettingJson;
};
export default function useSetting<T extends object | any[]>({
	settingJson,
}: Props) {
	const onUpdate = async (
		data: Partial<T>,
		modelEdit: T,
		indexEditing: number
	) => {
		return new Promise(async (resolve, reject) => {
			try {
				let dataEditing: T = { ...modelEdit };
				const listValue = settingJson.value as T[];
				for (const key in data) {
					if (dataEditing.hasOwnProperty(key)) {
						dataEditing = Object.assign(dataEditing, data);
					}
				}

				const newSettingConfigs = listValue.reduce(
					(curr: T[], prev: T, index) => {
						if (index === indexEditing) {
							curr.push(Object.assign(prev, dataEditing));
						} else {
							curr.push(prev);
						}

						return curr;
					},
					[]
				);

				const dataEdit: SettingAddEditJson = {
					...settingJson,
					value: JSON.stringify(newSettingConfigs),
				};
				const res = await new SettingRepository().saveRemote(dataEdit);
				if (res.hasError()) {
					reject();
				} else {
					resolve(1);
				}
				resolve(1);
			} catch (error) {
				reject();
			}
		});
	};

	const onAdd = async (data: T) => {
		return new Promise(async (resolve, reject) => {
			try {
				const listValue = settingJson.value as T[];
				const newListConfig = [...listValue].concat(data);
				const res = await new SettingRepository().saveRemote({
					...settingJson,
					value: JSON.stringify(newListConfig),
				});
				if (!res.hasError()) {
					resolve(1);
				} else {
					reject();
				}
			} catch (error) {
				reject();
			}
		});
	};

	return { onUpdate, onAdd };
}
