import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductCategoryListHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("productcategory:heading_list")}
      siteMenuSelectedKey="/productcategory"
      siteMenuOpenKey="product"
    >
      {children}
    </PageHeader>
  );
};

export default ProductCategoryListHeader;
