import app from "./data/app.json";
import common from "./data/common.json";
import customer from "./data/customer.json";
import customertag from "./data/customertag.json";
import employee from "./data/employee.json";
import file from "./data/file.json";
import htmleditor from "./data/htmleditor.json";
import news from "./data/news.json";
import newscategory from "./data/newscategory.json";
import notify from "./data/notify.json";
import office from "./data/office.json";
import report from "./data/report.json";
import role from "./data/role.json";
import shipperapply from "./data/shipperapply.json";
import shippingtype from "./data/shippingtype.json";
import sitemenu from "./data/sitemenu.json";
import user from "./data/user.json";
import warehouse from "./data/warehouse.json";
import phonebook from "./data/phonebook.json";

const resource = {
  common,
  sitemenu,
  user,
  employee,
  app,
  shippingtype,
  role,
  notify,
  report,
  office,
  customer,
  customertag,
  news,
  newscategory,
  htmleditor,
  file,
  warehouse,
  shipperapply,
  phonebook,
};

export default resource;
