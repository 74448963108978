import {
  IconAlarm,
  IconArrowDown,
  IconArrowUp,
  IconArrowsExchange2,
  IconClipboardData,
  IconClockPause,
  IconPaperclip,
} from "@tabler/icons-react";
import {
  Badge,
  Col,
  Collapse,
  List,
  Row,
  Space,
  Steps,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";

import TaskRepository from "common/repositories/TaskRepository";
import { TaskMOMJson, TaskScopeItemJson } from "common/types/Task";

import TaskNote from "./TaskNote";
import TaskStatus from "./TaskStatus";
import TextDateTime from "components/TextDateTime";
import TextDateTimeRelative from "components/TextDateTimeRelative";

type Props = {
  dataSource: TaskMOMJson[];
  name: string;
  loading: boolean;
  defaultActiveKey?: string | number | (string | number)[] | undefined;
};
const TaskRecent = ({ dataSource, name, loading, defaultActiveKey }: Props) => {
  const { Panel } = Collapse;
  const { Step } = Steps;

  ////
  const [ids, setIds] = useState<number[]>([]);
  const [taskScopeList, setTaskScopeList] = useState<TaskScopeItemJson[]>([]);
  //
  const renderColor = (status: number) => {
    let color = "#87e8de";
    switch (status) {
      case 0:
        color = "#87e8de";
        break;
      case 1:
        color = "#096dd9";
        break;
      case 2:
        color = "#c41d7f";
        break;
      case 3:
        color = "#389e0d";
        break;

      default:
        break;
    }
    return color;
  };

  const Deviation = ({ deviation }: { deviation: number }) => {
    const day = Math.floor(Math.abs(deviation / (3600 * 24)));
    const houre = Math.floor(Math.abs(deviation / 3600) % 24);
    const minutes = Math.round(
      Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
    );

    const is_deviation = (day || houre || minutes) !== 0;
    return (
      <>
        {is_deviation ? (
          <Tag
            color={deviation > 0 ? "volcano" : "green"}
            style={{ margin: 0, height: "22px" }}
          >
            {day > 0 && day + " ngày "}
            {houre > 0 && houre + " giờ "}
            {minutes > 0 && minutes + " phút"}
            {/* {minutes} */}{" "}
            {is_deviation ? (
              deviation > 0 ? (
                <IconArrowDown style={{ color: "#cf1322" }} size={16} />
              ) : (
                <IconArrowUp style={{ color: "#3f8600" }} size={16} />
              )
            ) : null}
          </Tag>
        ) : (
          <Tag style={{ margin: 0, height: "22px" }}>-</Tag>
        )}
      </>
    );
  };

  // fetch scope list task
  const fetchScopeList = async (ids: number[]) => {
    const response = await new TaskRepository().getItemsScope(ids);
    if (!response.hasOwnProperty("error")) {
      setTaskScopeList(response);
    } else {
      message.open({
        type: "error",
        content: "Có lỗi rồi!",
      });
    }
  };

  const idsList = useMemo(() => {
    let newIds = dataSource.map((item) => item.org_id);
    setIds(newIds);
  }, [dataSource]);

  useEffect(() => {
    if (ids.length > 0) {
      fetchScopeList(ids);
    }
  }, [ids]);

  return (
    <Col span={24} md={24}>
      <div className="shadow-lg">
        <Collapse defaultActiveKey={"1"}>
          <Panel
            key={"1"}
            header={name}
            extra={
              <Badge size="small" count={dataSource.length} showZero>
                <IconClipboardData size={16} />
              </Badge>
            }
          >
            <List
              loading={loading}
              // grid={{
              //   gutter: 8,
              //   xs: 1,
              //   sm: 2,
              //   md: 2,
              //   lg: 2,
              //   xl: 2,
              //   xxl: 3,
              // }}
              grid={{
                gutter: 4,
                column: 1,
              }}
              style={{ height: "100%" }}
              dataSource={dataSource}
              renderItem={(item) => (
                <List.Item
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #d9d9d9",
                  }}
                >
                  <div
                    id="currentTask"
                    className="relative"
                    style={{
                      minHeight: "70px",
                      borderLeft: `3px solid ${renderColor(item.status)}`,
                      padding: "0.5rem",
                    }}
                  >
                    <Row
                      gutter={[4, 8]}
                      justify="space-between"
                      align="middle"
                      style={{
                        width: "100%",
                        height: "100%",
                        minHeight: 65,
                      }}
                    >
                      <Col span={24}>
                        <Typography.Title level={5} style={{ marginBottom: 0 }}>
                          <span style={{ color: "#ec5a00" }}>
                            {"("}
                            <TextDateTime
                              ts={item.org_fromtime}
                              format="DD-MM"
                            />
                            {") "}
                          </span>
                          {item.name}
                        </Typography.Title>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                        }}
                      >
                        <Row gutter={[4, 4]} style={{ width: "100%" }}>
                          <Col span={14}>
                            <Tag>
                              <IconAlarm size={16} /> {item.org_cost} giờ
                            </Tag>
                            <TaskStatus status={item.status} has_icon={false} />
                            <Tag>
                              <IconClockPause size={16} />{" "}
                              {Math.ceil(item.time_pending / 3600)} giờ
                            </Tag>
                          </Col>
                          <Col
                            span={10}
                            style={{
                              textAlign: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: 8,
                            }}
                          >
                            <Typography.Text strong>
                              {"NTEAM-" + item.id.toString().toUpperCase()}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row gutter={[4, 4]} style={{ width: "100%" }}>
                          <Col
                            span={18}
                            sm={12}
                            lg={16}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 4,
                            }}
                          >
                            <Tag color="blue">
                              <span>
                                {item.org_fromtime > 0 ? (
                                  <TextDateTimeRelative
                                    ts={item.org_fromtime}
                                  />
                                ) : (
                                  // <TextDateTime
                                  //   format="HH:mm, DD/MM/YYYY"
                                  //   ts={item.org_fromtime}
                                  // />
                                  "---"
                                )}
                              </span>
                              <span className="px-1">
                                <IconArrowsExchange2 size={16} />
                              </span>
                              <span>
                                {item.org_totime > 0 ? (
                                  <TextDateTimeRelative ts={item.org_totime} />
                                ) : (
                                  // <TextDateTime
                                  //   format="HH:mm, DD/MM/YYYY"
                                  //   ts={item.org_totime}
                                  // />
                                  "---"
                                )}
                              </span>
                            </Tag>

                            <Tag color="volcano">
                              <span>
                                {item.datestart > 0 ? (
                                  <TextDateTimeRelative ts={item.datestart} />
                                ) : (
                                  // <TextDateTime
                                  //   format="HH:mm, DD/MM/YYYY"
                                  //   ts={item.rel_fromtime}
                                  // />
                                  "---"
                                )}
                              </span>
                              <span className="px-1">
                                <IconArrowsExchange2 size={16} />
                              </span>
                              <span>
                                {item.datecompleted > 0 ? (
                                  <TextDateTimeRelative
                                    ts={item.datecompleted}
                                  />
                                ) : (
                                  // <TextDateTime
                                  //   format="HH:mm, DD/MM/YYYY"
                                  //   ts={item.rel_totime}
                                  // />
                                  "---"
                                )}
                              </span>
                            </Tag>
                          </Col>
                          <Col
                            span={6}
                            sm={12}
                            lg={8}
                            style={{
                              textAlign: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: 8,
                            }}
                          >
                            <Deviation deviation={item.deviation}></Deviation>
                          </Col>
                        </Row>
                      </Col>
                      {item.org_id > 0 && (
                        <Col span={24} style={{ opacity: 0.6 }}>
                          <IconPaperclip size={16} />{" "}
                          <Typography.Text>
                            Task gốc: {item.org_id} {" - "}
                            {taskScopeList.find((t) => t.id === item.org_id)
                              ?.name || "..."}
                          </Typography.Text>
                        </Col>
                      )}
                      <Col span={24}>
                        <Collapse bordered={false} defaultActiveKey={"1"}>
                          <Panel
                            showArrow={false}
                            key={"1"}
                            style={{ backgroundColor: "white" }}
                            header={
                              <Typography.Text
                                strong
                                style={{ marginBottom: 0 }}
                              >
                                Nội dung:
                              </Typography.Text>
                            }
                          >
                            <TaskNote note={item.note} />
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  </div>
                </List.Item>
              )}
            />
          </Panel>
        </Collapse>
      </div>
    </Col>
  );
};

export default TaskRecent;
