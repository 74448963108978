import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { RowProps } from "antd";
import { ColProps } from "antd";
import { Col, Row, Typography } from "antd";
import { PhoneBookJson } from "common/types/PhoneBook";
import Helper from "common/utils/helper";
import { cn } from "common/utils/utils";
import React from "react";

type Props = {
	item: Pick<PhoneBookJson, "last_checkin" | "last_checkout" | "last_login">;
	titleCheckin?: string;
	titleCheckout?: string;
	rowProps1?: {
		props?: RowProps;
		col1?: ColProps;
		col2?: ColProps;
	};
	rowProps2?: {
		props?: RowProps;
		col1?: ColProps;
		col2?: ColProps;
	};
};
const PhoneBookCheckin = ({
	item,
	titleCheckin = "Checkin:",
	titleCheckout = "Checkout:",
	rowProps1,
	rowProps2,
}: Props) => {
	return (
		<>
			{item.last_checkin > 0 && (
				<Row
					className={cn("w-full justify-center")}
					gutter={{ xs: 8, sm: 16 }}
					{...rowProps1?.props}>
					<Col
						className={cn("font-semibold text-center p-0")}
						style={{ padding: 0 }}
						{...rowProps1?.col1}>
						<Typography.Text className="text-center">
							<LoginOutlined color="green" className="text-gray-400" />{" "}
							{titleCheckin}
						</Typography.Text>
					</Col>
					<Col {...rowProps1?.col2}>
						<Typography.Text
							ellipsis={{
								tooltip: Helper.formatTimestampToText2(item.last_checkin),
							}}
							className="capitalize whitespace-nowrap line-clamp-1 text-gray-400 ">
							{Helper.formatTimestampToText2(item.last_checkin)}
						</Typography.Text>
					</Col>
				</Row>
			)}
			{item.last_checkout > 0 && (
				<Row
					className={cn("w-full justify-center")}
					gutter={{ xs: 8, sm: 16 }}
					{...rowProps2?.props}>
					<Col
						style={{ padding: 0 }}
						className={cn("font-semibold text-center p-0")}
						{...rowProps2?.col1}>
						<Typography.Text className="text-center">
							<LogoutOutlined className="text-gray-400" /> {titleCheckout}
						</Typography.Text>
					</Col>
					<Col {...rowProps2?.col2}>
						<Typography.Text
							ellipsis={{
								tooltip: Helper.formatTimestampToText2(item.last_checkout),
							}}
							className="capitalize whitespace-nowrap line-clamp-1 text-gray-400 ">
							{Helper.formatTimestampToText2(item.last_checkout)}
						</Typography.Text>

						<Typography.Text className="capitalize line-clamp-1"></Typography.Text>
					</Col>
				</Row>
			)}
		</>
	);
};

export default PhoneBookCheckin;
