import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconCalendar } from "@tabler/icons-react";

const WorkTrackingApprovalListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("worktracking:heading_approval_list")}
      siteMenuSelectedKey="/checkin/approval"
    >
      <RoleCheck roles={[Role.CHECKIN_EDIT, Role.CHECKIN_VERIFY]} hideOnFail>
        <PageHeaderButton
          link="/workschedule/manage"
          type="primary"
          className="ml-2"
          icon={<IconCalendar className="mr-1 -mt-0.5" size={14} />}
        >
          {t("workschedule:manage_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default WorkTrackingApprovalListHeader;
