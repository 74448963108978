import { BaseModelJson } from "common/interfaces/BaseModelJson";

import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import BaseModel from "./BaseModel";
import { SettingJson } from "common/types/Setting";

const defaultData: SettingJson = {
  key: "",
  value: "",
  id: 0,
  group: "",
  status: 0,
  lang: "",
};

class SettingModel extends BaseModel implements BaseModelJson<SettingJson> {
  id: number = defaultData.id;
  value: any = defaultData.value;
  key: string = defaultData.key;
  group: string = defaultData.group;
  status: number = defaultData.status;
  lang: string = defaultData.lang;

  constructor(json: SettingJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): SettingJson {
    return { ...defaultData };
  }
  toJson(): SettingJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getGroupSelect(): SelectOptionNormal[] {
    return [
      {
        value: "ERP",
        label: "ERP",
      },
      {
        value: "COMMON",
        label: "COMMON",
      },
      {
        value: "SHOP",
        label: "SHOP",
      },
    ];
  }
  static getLangSelect(): SelectOptionNormal[] {
    return [
      {
        value: "vi",
        label: "Tiếng Việt",
      },
      {
        value: "en",
        label: "Tiếng Anh",
      },
      {
        value: "*",
        label: "Đa ngôn ngữ",
      },
      {
        value: "",
        label: "Tất cả",
      },
    ];
  }

  static getLangSelectValue(): SelectOptionNormal[] {
    return [
      {
        value: "vi",
        label: "Tiếng Việt",
      },
      {
        value: "en",
        label: "Tiếng Anh",
      },
      {
        value: "*",
        label: "Đa ngôn ngữ",
      },
    ];
  }
}

export default SettingModel;
