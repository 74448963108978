import { App, Col, Form, Input, Row } from "antd";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { TagAddEditJson } from "common/types/Tag";
import FormSelect from "components/form/FormSelect";
import SettingModel from "common/models/SettingModel";
import { SettingAddEditJson, SettingGroupType } from "common/types/Setting";
import SettingRepository from "common/repositories/SettingRepository";
import TextArea from "antd/lib/input/TextArea";
import FormSelectNormal from "components/form/FormSelectNormal";
const SettingForm = ({
  model,
  type,
  lang,
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  model: SettingModel;
  type: SettingGroupType;
  lang: string;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: SettingModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [getValue, setValue] = useFormDefaultStore((state) => [
    state.getValue,
    state.setValue,
  ]);
  const [chooseLang, setChooseLang] = useState<string>(
    lang.length > 0 ? lang : "vi"
  );
  const [form] = Form.useForm();
  const initialValues = useMemo(() => {
    if (model.id > 0) {
      return {
        id: model.id,
        group: model.group.length > 0 ? model.group : "SHOP",
        value: model.id > 0 ? JSON.stringify(model.value) : "",
        key: model.key,
        lang: model.lang,
      };
    }
    return {
      id: model.id,
      group: model.group.length > 0 ? model.group : "SHOP",
      value: model.id > 0 ? JSON.stringify(model.value) : "",
      lang: chooseLang,
      key: model.key,
    };
  }, [model, getValue]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: any = {
        id: model.id,
        group: type,
        key: formData.key.length > 0 ? formData.key.trim() : "",
        value:
          formData.value.length > 0
            ? JSON.parse(JSON.stringify(formData.value.trim()))
            : "",
        lang: chooseLang && chooseLang.length > 0 ? chooseLang : undefined,
      };

      return submitData;
    },
    [model.id, setValue, type, lang, chooseLang]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    console.log("🥺 ~ onSubmit ~ formData:", doPrepareData(formData));
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    let item: SettingModel = await new SettingRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="setting:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}
    >
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={"Key"}
            name="key"
            rules={[
              {
                required: true,
                message: t("Vui lòng nhập"),
              },
            ]}
            className="mb-0"
          >
            <Input />
          </Form.Item>
        </Col>
        {model.id <= 0 && (
          <Col md={24} xs={24} style={{ marginTop: 10 }}>
            <FormSelectNormal
              label="Ngôn ngữ"
              name="lang"
              placeholder={"Phân loại"}
              options={SettingModel.getLangSelectValue()}
              allowClear
              value={chooseLang}
              onChange={(e) => {
                console.log(e);
                setChooseLang(e + "");
              }}
              popupMatchSelectWidth={false}
            />
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("tag:value")}
            name="value"
            className="mb-0"
            rules={[
              {
                required: true,
                message: t("Vui lòng nhập"),
              },
            ]}
          >
            <TextArea rows={10} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SettingForm;
