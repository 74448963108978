import LeaveTypeList from "features/leavetype/list/LeaveTypeList";
import usePathParams from "hooks/usePathParams";

const LeaveTypesPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <LeaveTypeList />;
      break;
  }

  return com;
};

export default LeaveTypesPage;
