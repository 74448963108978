// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__BCeOt {
  min-height: 35vh;
}
.styles_breadcrumb__O7vU6 {
  display: none;
}

.styles_title__kyzGI {
  color: #000;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 600;
}
.styles_information__YGN54 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 20px;
  color: var(--tag-color);
}
.styles_created__FU\\+pE span {
  display: flex;
  align-items: center;
  gap: 8px;
}
.styles_tag__uSJcJ {
  display: flex;
  gap: 24px;
}
.styles_spanTag__HjfTP {
  text-align: center;
  line-height: 0.8rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .styles_title__kyzGI {
    font-size: 20px;
  }
  .styles_breadcrumb__O7vU6 {
    display: inline-block;
    margin: 6px 0 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyStoryDetail/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;AACF","sourcesContent":[".wrapper {\n  min-height: 35vh;\n}\n.breadcrumb {\n  display: none;\n}\n\n.title {\n  color: #000;\n  font-size: 32px;\n  margin-bottom: 20px;\n  font-weight: 600;\n}\n.information {\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n  margin-bottom: 20px;\n  color: var(--tag-color);\n}\n.created span {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n.tag {\n  display: flex;\n  gap: 24px;\n}\n.spanTag {\n  text-align: center;\n  line-height: 0.8rem;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n@media screen and (max-width: 768px) {\n  .title {\n    font-size: 20px;\n  }\n  .breadcrumb {\n    display: inline-block;\n    margin: 6px 0 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__BCeOt`,
	"breadcrumb": `styles_breadcrumb__O7vU6`,
	"title": `styles_title__kyzGI`,
	"information": `styles_information__YGN54`,
	"created": `styles_created__FU+pE`,
	"tag": `styles_tag__uSJcJ`,
	"spanTag": `styles_spanTag__HjfTP`
};
export default ___CSS_LOADER_EXPORT___;
