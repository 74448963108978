import React, { useState, useEffect, useCallback } from "react";
import AccountRepository from "common/repositories/AccountRepository";
import Error from "components/LayoutError";
import { Button, Input, Form, message, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useQueryParam from "hooks/useQueryParam";
import Helper from "common/utils/helper";
import { IconKey } from "@tabler/icons-react";
import UserHostname from "./UserHostName";

const UserResetPassForm = () => {
  const messageKey = "editor";

  const { t } = useTranslation();
  const [paramId] = useQueryParam("email", "");
  const [paramCode] = useQueryParam("code", "");
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);

  //show message loading when change processing ON
  useEffect(() => {
    if (processing) {
      message.loading({
        content: t("common:form.processing"),
        key: messageKey,
        duration: 0,
      });
    }
  }, [processing, t]);

  const formSubmit = useCallback(
    async (
      id: string,
      verification_code: string,
      new_password: string,
      hostname: string
    ) => {
      let repository = new AccountRepository();
      setProcessing(true);
      const responseErrors = await repository.resetpassword(
        id,
        verification_code,
        new_password,
        hostname
      );
      setProcessing(false);
      if (responseErrors.length === 0) {
        message.destroy(messageKey);

        Modal.success({
          title: t("user:form.success.resetpass_heading"),
          content: <div>{t("user:form.success.resetpass_text_1")}</div>,
          onOk: () => {
            navigate("/user/login");
          },
        });
      } else {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKey);
              }}
              heading={t("user:form.error.general_heading")}
              translate_prefix={"user:form.error" || ""}
              items={responseErrors}
            />
          ),
          className: "message_error",
          key: messageKey,
          duration: 10,
        });
      }
    },
    [t, navigate]
  );

  //formsubmit onFinish proxy, wrapping to set processing (to show spinner) and call onSubmit callback from parent
  const onFormSubmit = useCallback(
    (formData: any) => {
      formSubmit(paramId, paramCode, formData.new_password, formData.hostname);
    },
    [formSubmit, paramCode, paramId]
  );

  return (
    <Form
      initialValues={{ hostname: Helper.getHostname() }}
      form={form}
      onFinish={onFormSubmit}
      className="mt-4"
      layout="vertical"
    >
      <UserHostname />

      <Row gutter={16}>
        <Col flex="auto">
          <Form.Item
            name="new_password"
            rules={[
              {
                required: true,
                message: t("user:form.error.required_new_password"),
              },
            ]}
          >
            <Input.Password
              prefix={<IconKey size="18" color="grey" />}
              autoComplete="new-password"
            />
          </Form.Item>
        </Col>
        <Col flex="120px">
          <Form.Item>
            <Button type="primary" loading={processing} htmlType="submit">
              {t("user:resetpass_btn")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserResetPassForm;
