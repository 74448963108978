import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";
import { WarehouseStoreJson } from "common/types/Warehouse";

const defaultData: WarehouseStoreJson = {
  id: 0,
  company_id: 0,
  creator_id: 0,
  store_id: 0,
  warehouse_id: 0,
};

class WarehouseStoreModel
  extends BaseModel
  implements BaseModelJson<WarehouseStoreJson>
{
  id: number = defaultData.id;
  company_id: number = defaultData.company_id;
  creator_id: number = defaultData.creator_id;
  store_id: number = defaultData.store_id;
  warehouse_id: number = defaultData.warehouse_id;

  constructor(json: WarehouseStoreJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): WarehouseStoreJson {
    return { ...defaultData };
  }
  toJson(): WarehouseStoreJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }
}

export default WarehouseStoreModel;
