import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const ContactMailListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("contactmail:heading_list")}
      siteMenuSelectedKey="/contactmail"
      siteMenuOpenKey="sale"
    >
      {/* <PageHeaderButton link="/employee/add">
          {t("common:table.add_button")}
        </PageHeaderButton> */}
    </PageHeader>
  );
};

export default ContactMailListHeader;
