import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import { useTranslation } from 'react-i18next';

const DmsPostListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("dmspost:heading_list")}
      siteMenuOpenKey="delivery.portal"
      siteMenuSelectedKey="/dmspost"
    >
      <PageHeaderButton link="/dmspost/add" className="ml-2">
        {t("dmspost:add_button")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default DmsPostListHeader;
