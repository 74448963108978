import React, { useEffect, useMemo } from "react";
import { Tabs, TabsProps } from "antd";
import DeliveryMineListHeader from "./DeliveryMineListHeader";
import DeliveryMineListFilter from "./DeliveryMineListFilter";
import DeliveryMineListRender from "./DeliveryMineListRender";
import useStateFilter from "hooks/useStateFilter";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import useFilterLocation from "hooks/useFilterLocation";

const DeliveryMineList = () => {
  ///////////////////////////////
  //

  ///////////////////////////////
  // process
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Đơn chờ giao",
      children: <DeliveryMineListRender isComplete={0} />,
    },
    {
      key: "2",
      label: "Đơn đã hoàn thành",
      children: <DeliveryMineListRender isComplete={1} />,
    },
  ];

  return (
    <div>
      <DeliveryMineListHeader />
      <Tabs
        tabBarStyle={{ margin: 0 }}
        defaultActiveKey="1"
        items={items}
        centered
      ></Tabs>
    </div>
  );
};

export default DeliveryMineList;
