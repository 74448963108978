import { Statistic } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import BiCenterCollection from "common/collections/BiCenterCollection";
import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import { ChartNumberProps } from "common/types/BiCenterChart";
import Error from "components/LayoutError";
import TextMoney from "components/TextMoney";
import TextNumber from "components/TextNumber";
import ReportChartNumberChange from "features/report/chart/ReportChartNumberChange";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";

import type { DataQuery, DateRange } from "common/types/BiCenter";
const ReportChartNumber = ({
  label,
  icon,
  dateRange,
  dateRangeCompare,
  keyMapping,
  dataSelect,
  dataService,
  dataTable,
  dataFilter,
  dataJoin,
  valueType,
  className
}: ChartNumberProps) => {
  const { t } = useTranslation();
  const [number, setNumber] = useState(0);
  const [numberCompare, setNumberCompare] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(
    async ({
      range,
      rangeCompare
    }: {
      range: DateRange;
      rangeCompare: DateRange | null;
    }) => {
      setLoading(true);

      var props: DataQuery = {
        service: dataService,
        start: BiCenterModel.momentToString(range[0]),
        end: BiCenterModel.momentToString(range[1]),
        startcompare: rangeCompare
          ? BiCenterModel.momentToString(rangeCompare[0])
          : "",
        endcompare: rangeCompare
          ? BiCenterModel.momentToString(rangeCompare[1])
          : "",
        table: dataTable,
        join: dataJoin || "",
        select: dataSelect,
        filter: dataFilter,
        groupby: "",
        orderby: "",
        limit: "",
        offset: "",
        timeserie: ""
      };

      //Call repository to fetch data
      const collection: BiCenterCollection =
        await new BiCenterRepository().queryRemote(props);
      setLoading(false);
      if (collection.hasError()) {
        setErrors(collection.error.errors);
      } else {
        setErrors([]);

        //extract NUMBER from model
        setNumber(+collection.items[0].rows[0][keyMapping]);

        //check if found compare
        if (collection.items[0].havecompare) {
          //extract NUMBERCOMPARE from model
          setNumberCompare(+collection.items[0].rows[1][keyMapping]);
        } else {
          setNumberCompare(0);
        }
      }
    },
    [keyMapping, dataService, dataTable, dataJoin, dataSelect, dataFilter]
  );

  const formatter = (value: valueType) =>
    typeof valueType !== "undefined" && valueType === "money" ? (
      <TextMoney money={value} />
    ) : (
      <TextNumber number={value} />
    );

  useDeepCompareEffect(() => {
    loadData({
      range: dateRange,
      rangeCompare: dateRangeCompare
    });
  }, [dateRange, dateRangeCompare, loadData]);

  return (
    <Statistic
      loading={loading}
      title={label}
      className={
        className +
        " report_number " +
        (errors.length > 0 ? "report_error_number" : "")
      }
      prefix={icon}
      suffix={
        errors.length > 0 ? (
          <Error
            className="report_error"
            heading={t("report:error.query_heading")}
            contentPadding={0}
            translate_prefix="report:error"
            items={errors}
          />
        ) : dateRangeCompare ? (
          <ReportChartNumberChange
            from={numberCompare}
            to={number}
            valueType={valueType || "money"}
          />
        ) : null
      }
      value={number}
      formatter={formatter}
    />
  );
};

export default ReportChartNumber;
