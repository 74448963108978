import EcomChannelProductCollection from "common/collections/EcomChannelProductCollection";
import EcomSyncProductCollection from "common/collections/EcomSyncProductCollection";
import EcomSyncProductModel from "common/models/EcomSyncProductModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type { FilterEcomChannelProduct } from "common/types/EcomChannelProduct";
const SERVICE_URL = "/ecomchannelproducts";

class EcomChannelProductApi extends BaseApi {
  async getList(
    props: GetListProps<FilterEcomChannelProduct>,
    channel_id: number
  ): Promise<EcomChannelProductCollection> {
    let collection = new EcomChannelProductCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          id: filters.id > 0 ? filters.id : null,
        },
      };

      const response = await AxiosClient().get<any>(
        `${SERVICE_URL}/${channel_id}`,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async findLinkedVariant(
    channel_id: number,
    skuList: string[]
  ): Promise<EcomSyncProductCollection> {
    let collection = new EcomSyncProductCollection();

    try {
      let postData = {
        skulist: skuList,
      };

      const response = await AxiosClient().post<any>(
        `${SERVICE_URL}/findlink/${channel_id}`,
        postData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async linkByProductSku(
    channel_id: number,
    product_id: number,
    sku: string
  ): Promise<EcomSyncProductModel> {
    let item = new EcomSyncProductModel(EcomSyncProductModel.getDefaultData());

    try {
      //For create item
      const response = await AxiosClient().post<any>(
        `${SERVICE_URL}/link/${channel_id}/${product_id}/${sku}`
      );
      if (response.hasOwnProperty("data")) {
        item = new EcomSyncProductModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default EcomChannelProductApi;
