import { Avatar, Image } from "antd";
import DmsBannerCollection from "common/collections/DmsBannerCollection";
import DmsBanner from "common/constants/DmsBanner";
import Role from "common/constants/Role";
import DmsBannerModel from "common/models/DmsBannerModel";
import DmsBannerRepository from "common/repositories/DmsBannerRepository";
import { DmsBannerJson, FilterDmsBanner } from "common/types/DmsBanner";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import DmsBannerListHeader from "features/dmsbanner/list/DmsBannerListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DmsBannerListFilter from "./DmsBannerListFilter";

const DmsBannerList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterDmsBanner = useMemo(
    () => DmsBannerRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterDmsBanner>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<DmsBannerModel[]>([]);
  const fetchData = useCallback(async (): Promise<DmsBannerCollection> => {
    let collection = await new DmsBannerRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);
    return collection;
  }, [filters]);

  //Table columns
  const columns: TableColumnsType<DmsBannerModel> = [
    {
      title: t("dmsbanner:image"),
      key: "image",
      width: 50,
      render: (_: any, record: DmsBannerModel) => {
        return (
          <Avatar
            src={<Image src={record.image.url} style={{ width: 32 }} />}
          />
        );
      },
    },
    {
      title: t("dmsbanner:position"),
      key: "position",
      render: (_: any, record: DmsBannerModel) => {
        let positionInfo = DmsBannerModel.getType(record.position);
        return <span>{positionInfo?.label}</span>;
      },
    },
    {
      title: t("dmsbanner:link"),
      key: "link",
    },
    {
      title: t("dmsbanner:caption"),
      key: "caption",
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 120,
      render: (ts: number) => <TextDateTime ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_: any, record: DmsBannerJson) => (
        <RoleCheck roles={[Role.COMPANY_SETTING]} hideOnFail>
          <TableEdit link={"/dmsbanner/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="dmsbanner:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new DmsBannerRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <DmsBannerListHeader />

      <DmsBannerListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterDmsBanner, DmsBannerModel, DmsBannerCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          rowClassName: (record) =>
            record.status === DmsBanner.STATUS_ENABLED
              ? ""
              : "table-row-stripes opacity-70",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default DmsBannerList;
