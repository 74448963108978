import ProductVariantColorApi from 'common/api/ProductVariantColorApi';
import ProductVariantColorCollection from 'common/collections/ProductVariantColorCollection';
import ProductVariantColorModel from 'common/models/ProductVariantColorModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProductVariantColorJsonAddEdit,
  FilterProductVariantColor,
} from "common/types/ProductVariantColor";

class ProductVariantColorRepository extends BaseRepository<ProductVariantColorApi> {
  _api: ProductVariantColorApi | null;

  constructor() {
    super();
    this._api = new ProductVariantColorApi(true);
  }

  static getDefaultFilters(): FilterProductVariantColor {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterProductVariantColor>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductVariantColorCollection();
  }

  async getItem(id: number): Promise<ProductVariantColorModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductVariantColorModel(ProductVariantColorModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductVariantColorJsonAddEdit
  ): Promise<ProductVariantColorModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductVariantColorModel(
        ProductVariantColorModel.getDefaultData()
      );
    }
  }
}

export default ProductVariantColorRepository;
