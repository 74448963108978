import Pos from "common/constants/Pos";
import { Scrollbars } from "react-custom-scrollbars";
import usePosStore from "zustands/usePosStore";

import PosCart from "./cart/PosCart";
import PosCartSummary from "./cart/PosCartSummary";

const PosLeft = () => {
  const height = usePosStore((state) => state.height);

  return (
    <>
      <Scrollbars
        style={{
          height: height - Pos.SUMMARY_SECTION_HEIGHT,
          overflow: "hidden",
        }}
        autoHide
      >
        <PosCart />
      </Scrollbars>

      <Scrollbars
        style={{
          height: Pos.SUMMARY_SECTION_HEIGHT,
          overflow: "hidden",
        }}
        autoHide
      >
        <PosCartSummary />
      </Scrollbars>
    </>
  );
};

export default PosLeft;
