import CustomerCollection from "common/collections/CustomerCollection";
import CustomerModel from "common/models/CustomerModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterCustomer,
  CustomerJsonAddEdit,
} from "common/types/Customer";
const SERVICE_URL = "/customers";

class CustomerApi extends BaseApi {
  async getList(
    props: GetListProps<FilterCustomer>,
  ): Promise<CustomerCollection> {
    let collection = new CustomerCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          id: +filters.id > 0 ? filters.id : null,
          gender: +filters.gender > 0 ? filters.gender : null,
          full_name: filters.full_name ? filters.full_name : null,
          code: filters.code ? filters.code : null,
          phone: filters.phone ? filters.phone : null,
          email: filters.email ? filters.email : null,
          customer_type_id:
            filters.customer_type_id > 0 ? filters.customer_type_id : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<CustomerModel> {
    let item = new CustomerModel(CustomerModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new CustomerModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: CustomerJsonAddEdit): Promise<CustomerModel> {
    let item = new CustomerModel(CustomerModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new CustomerModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: CustomerJsonAddEdit): Promise<CustomerModel> {
    let item = new CustomerModel(CustomerModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new CustomerModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default CustomerApi;
