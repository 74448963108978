import React from "react";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useTranslation } from "react-i18next";
import { Nav } from "common/types/PageNavigation";

import { IconCalendar, IconHistory } from "@tabler/icons-react";
import usePathParams from "hooks/usePathParams";

const CheckinV2WrapperHeader = () => {
  const { t } = useTranslation();
  const [pathParams] = usePathParams();

  return (
    <PageHeader
      heading={t("worktracking:heading_add")}
      siteMenuSelectedKey="/checkin/add"
      siteMenuHidden
    >
      <div className="grid grid-cols-1">
        {/* <div className="mb-1">
          <PageHeaderButton
            type="primary"
            link="/workschedule"
            // disabled={
            //   account.company.employee.checkin_type !== Employee.CHECKIN_TYPE_SCHEDULE
            // }
            icon={<IconCalendar size={18} className="mr-1 -mt-0.5" />}
          >
            {t("workschedule:heading_mine_list")}
            
          </PageHeaderButton>
        </div> */}

        <div>
          <PageHeaderButton
            type="primary"
            ghost
            link="/checkin"
            icon={<IconHistory size={18} className="mr-1 -mt-0.5" />}
          >
            {t("worktracking:heading_nav")}
          </PageHeaderButton>
        </div>
      </div>
    </PageHeader>
  );
};

export default CheckinV2WrapperHeader;
