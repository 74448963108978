import { Form, Radio, Tooltip } from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import Role from "common/constants/Role";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconBan, IconCircleCheck } from "@tabler/icons-react";
import { CashflowreceiptsStatus } from "common/types/CashflowReceipt";

const CashflowReceiptFormStatus = ({
  id,
  store_id,
  initStatus,
  status,
  setStatus,
  disable
}: {
  id: number;
  store_id: number;
  initStatus: number;
  status: number;
  setStatus: (v: number) => void;
  disable?: boolean
}) => {
  const { t } = useTranslation();
  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );

  //check allow set status to APPROVE
  const allowStatusApprove = useMemo(() => {
    return (
      hasRoleAndLimit(Role.CASHFLOW_STATUS_APPROVE, store_id) &&
      initStatus <= CashflowReceipt.STATUS_APPROVED
    );
  }, [store_id, hasRoleAndLimit, initStatus]);

  //check allow set status to COMPLETE
  const allowStatusComplete = useMemo(() => {
    return (
      hasRoleAndLimit(Role.CASHFLOW_STATUS_COMPLETE, store_id) &&
      initStatus <= CashflowReceipt.STATUS_COMPLETED
    );
  }, [store_id, hasRoleAndLimit, initStatus]);

  //check allow set status to CANCEL
  const allowStatusCancel = useMemo(() => {
    if(initStatus === CashflowreceiptsStatus.SUCCSECC) return false

    return (
      id > 0 &&
      hasRoleAndLimit(Role.CASHFLOW_STATUS_CANCEL, store_id) &&
      initStatus < CashflowReceipt.STATUS_CANCELLED
    );
  }, [id, store_id, hasRoleAndLimit, initStatus]);

  return (
    <Form.Item
      label={t("cashflowreceipt:status")}
      className="mb-0"
      tooltip={t("cashflowreceipt:status_help")}

    >
      <Radio.Group
        buttonStyle="outline"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        disabled={disable}
        size="small"
        // optionType="button"
      >
        <Radio.Button
          value={CashflowReceipt.STATUS_DRAFT}
          disabled={initStatus > CashflowReceipt.STATUS_DRAFT || disable}

        >
          {t("cashflowreceipt:STATUS_DRAFT")}
        </Radio.Button>
        <Radio.Button
          value={CashflowReceipt.STATUS_WAIT_FOR_APPROVAL}
          disabled={initStatus > CashflowReceipt.STATUS_WAIT_FOR_APPROVAL || disable}
        >
          {t("cashflowreceipt:STATUS_WAIT_FOR_APPROVAL")}
        </Radio.Button>
        <Radio.Button
          value={CashflowReceipt.STATUS_APPROVED}
          disabled={!allowStatusApprove || disable}
        >
          {t("cashflowreceipt:STATUS_APPROVED")}
        </Radio.Button>
        <Radio.Button
          value={CashflowReceipt.STATUS_COMPLETED}
          disabled={!allowStatusComplete || disable}
        >
          <Tooltip
            title={t("cashflowreceipt:warning_for_status_complete_cancel")}
            mouseEnterDelay={0.4}
          >
            <IconCircleCheck
              size={18}
              className="-mt-0.5 mr-1 text-green-500"
            />
            {t("cashflowreceipt:STATUS_COMPLETED")}
          </Tooltip>
        </Radio.Button>

        <Radio.Button
          value={CashflowReceipt.STATUS_CANCELLED}
          disabled={!allowStatusCancel || disable}
        >
          <Tooltip
            title={t("cashflowreceipt:warning_for_status_complete_cancel")}
            mouseEnterDelay={0.4}
          >
            <IconBan size={18} className="-mt-0.5 mr-1 text-red-500" />
            {t("cashflowreceipt:STATUS_CANCELLED")}
          </Tooltip>
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export default CashflowReceiptFormStatus;
