import React, { ReactElement, ReactNode } from 'react';

import { Status, Wrapper } from '@googlemaps/react-wrapper';

const GoogleMapWrapper = ({
  apiKey,
  children,
  render,
  callback,
}: {
  apiKey: string;
  children?: ReactNode;
  render?: (status: Status) => ReactElement;
  callback?: (status: Status) => void;
}) => {
  return (
    <Wrapper {...render} apiKey={apiKey}>
      {children}
    </Wrapper>
  );
};

export default GoogleMapWrapper;
