import DataGridTableFieldApi from "common/api/DataGridTableFieldApi";
import DataGridTableFieldCollection from "common/collections/DataGridTableFieldCollection";
import DataGridTableFieldModel from "common/models/DataGridTableFieldModel";
import BaseRepository from "./BaseRepository";
import { GetListProps } from "common/types/Filter";
import type {
  DataGridTableFieldJsonAddEdit,
  FilterDataGridTableField,
} from "common/types/DataGridTableField";

class DataGridTableFieldRepository extends BaseRepository<DataGridTableFieldApi> {
  _api: DataGridTableFieldApi | null;

  constructor() {
    super();
    this._api = new DataGridTableFieldApi(true);
  }

  static getDefaultFilters(): FilterDataGridTableField {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      datagrid_table_id: 0,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterDataGridTableField>) {
    return this._api
      ? await this._api.getList(props)
      : new DataGridTableFieldCollection();
  }

  async getItem(id: number): Promise<DataGridTableFieldModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new DataGridTableFieldModel(DataGridTableFieldModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: DataGridTableFieldJsonAddEdit
  ): Promise<DataGridTableFieldModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new DataGridTableFieldModel(
        DataGridTableFieldModel.getDefaultData()
      );
    }
  }
}

export default DataGridTableFieldRepository;
