import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import type {
  FullDataJson,
  FullDataItem,
  FullDataState,
} from "common/types/FullData";

class FullDataModel extends BaseModel implements BaseModelJson<FullDataJson> {
  resource: string;
  version: number;
  state: FullDataState;
  http_status: number;
  reason: string[];
  data: FullDataItem[];

  constructor(json: FullDataJson) {
    super();

    this.resource = json.resource || "";
    this.version = json.version || 0;
    this.state = json.state || "pending";
    this.http_status = json.http_status || 0;
    this.reason = json.reason || [];
    this.data = json.data || [];
  }

  static getDefaultData(): FullDataJson {
    return {
      resource: "",
      version: 0,
      state: "pending",
      http_status: 0,
      reason: [],
      data: [],
    };
  }

  toJson(): FullDataJson {
    return {
      resource: this.resource,
      version: this.version,
      state: this.state,
      http_status: this.http_status,
      reason: this.reason,
      data: this.data,
    };
  }
}

export default FullDataModel;
