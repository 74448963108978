import dayjs from "dayjs";
import { useForm } from "antd/lib/form/Form";
import { Col, DatePicker, Form, Input, InputNumber, Row, message } from "antd";
import { useTranslation } from "react-i18next";
import usePathParams from "hooks/usePathParams";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Helper from "common/utils/helper";
import TaskModel from "common/models/TaskModel";
import { TaskJsonAddEdit } from "common/types/Task";
import TaskRepository from "common/repositories/TaskRepository";

import TaskFormHeader from "./TaskFormHeader";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";
import BirthdayInput from "components/BirthdayInput";
import LabelDateFormatHelper from "components/LabelDateFormatHelper";
import ErrorAlert from "components/ErrorAlert";
import ReactQuill from "react-quill";
import FormSelectNormal from "components/form/FormSelectNormal";
import FormSelectCustom from "../components/FormSelectCustom";

type Props = {
  model: TaskModel;
};
const TaskForm = ({ model }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [pathParams] = usePathParams();
  const isEditing = model.id > 0;

  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [parentModel, setParentModel] = useState<TaskModel>(
    new TaskModel(TaskModel.getDefaultData())
  );

  //default data
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      org_cost: model.org_cost,
      org_fromtime:
        model.org_fromtime > 0 ? dayjs(model.org_fromtime * 1000) : 0,
      org_totime: model.org_totime > 0 ? dayjs(model.org_totime * 1000) : 0,
      type: model.type > 0 ? model.type : 2,
      status: model.status > 0 ? model.status : 0,
      note: model.note,
      priority: model.priority > 0 ? model.priority : 1,
      scope_ids: model.scope_ids.length > 0 ? model.scope_ids : "",
      org_id: model.org_id,
      public: model.public,
      ability: model.ability,
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: TaskJsonAddEdit = {
        // ...TaskModel.getDefaultData(),
        id: 0,
        employee_ids: null,
        name: formData.name,
        type: formData.type,
        org_cost: formData.org_cost,
        org_fromtime:
          Helper.convertedDateToTimeStamp(formData.org_fromtime) > 0
            ? Helper.convertedDateToTimeStamp(formData.org_fromtime)
            : 0,
        org_totime:
          Helper.convertedDateToTimeStamp(formData.org_totime) > 0
            ? Helper.convertedDateToTimeStamp(formData.org_totime)
            : 0,
        parent_id: Number(pathParams.id) ? Number(pathParams.id) : 0,
        note: formData.note,
        priority: formData.priority,
        scope_ids: formData.scope_ids.length > 0 ? formData.scope_ids : "",
        org_id: Number(formData.org_id),
        public: Number(formData.public) > 0 ? Number(formData.public) : 0,
        ability: Number(formData.ability) > 0 ? Number(formData.ability) : 0,
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: TaskJsonAddEdit) => {
    const response = await new TaskRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      setIsSuccess(true);
      form.resetFields();
    } else {
      setErrors(response.error.errors);
    }
  };

  //functions
  function validateManyId(input: string) {
    // Sử dụng biểu thức chính quy để kiểm tra định dạng
    var regex = /^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$/;
    return regex.test(input);
  }

  //fetch data
  const fetchParentDetail = async (id: number) => {
    const item = await new TaskRepository().getItem(id);
    if (item.hasError()) {
      message.open({
        type: "warning",
        content: "Có lỗi rồi !",
        duration: 2,
      });
      return;
    } else {
      setParentModel(item);
    }
  };

  useEffect(() => {
    if (Number(pathParams.id) > 0) {
      fetchParentDetail(Number(pathParams.id));
    }
  }, []);

  //sidebar form
  const sidebarItems = (
    <>
      {errors.length > 0 && (
        <ErrorAlert
          items={errors}
          translate_prefix={`task:form.error.${errors[0]}`}
        />
      )}
    </>
  );

  const toolbarOptions = [
    [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction"],
    ["link", "image", "video", "formula"],
    ["clean"],
  ];

  return (
    <div>
      <TaskFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess=""
        error_translate_prefix="common:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("task:form.section_task")}
          subtitle={
            Number(pathParams.id) > 0
              ? `Task cha: #${parentModel.id} - ${parentModel.name}`
              : t("task:form.section_task_subtitle")
          }
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("task:name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("task:form.error.error_name_require"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_cost")}
                name="org_cost"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: t("task:form.error.required_time"),
                  },
                  {
                    message: t("task:form.error.error_org_cost"),
                    validator: (_: any, value: number) => {
                      if (value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <InputNumber className="w-full" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_fromtime")}
                name="org_fromtime"
                style={{ width: "100%" }}
                validateFirst
                // rules={[
                //   {
                //     required: true,
                //     message: t("task:form.error.required_time"),
                //   },
                //   {
                //     message: t("task:form.error.required_time"),
                //     validator: (_: any, value: Moment) => {
                //       if (value) {
                //         return Promise.resolve();
                //       } else {
                //         return Promise.reject("Some message here");
                //       }
                //     },
                //   },
                // ]}
              >
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  showTime
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_totime")}
                name="org_totime"
                validateFirst
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: t("task:form.error.required_time"),
                //   },
                //   {
                //     message: t("task:form.error.required_time"),
                //     validator: (_: any, value: Moment) => {
                //       if (value) {
                //         return Promise.resolve();
                //       } else {
                //         return Promise.reject("Some message here");
                //       }
                //     },
                //   },
                //   {
                //     message: t("Thời gian kết thúc phải sau bắt đầu"),
                //     validator: (_: any, value: Moment) => {
                //       if (
                //         moment(form.getFieldValue("org_fromtime")) <
                //         moment(value)
                //       ) {
                //         return Promise.resolve();
                //       } else {
                //         return Promise.reject("Some message here");
                //       }
                //     },
                //   },
                // ]}
              >
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  showTime
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  className="w-full"
                />
              </Form.Item>
            </Col>

            {/* <Col md={8} xs={24}>
              <FormSelect
                showSearch
                // required
                label={t("task:status")}
                name="status"
                options={TaskModel.getStatusOption()}
                // rules={[
                //   {
                //     required: true,
                //     message: t("common:error.required_gender"),
                //   },
                // ]}
              />
            </Col> */}

            {/* <Col md={8} xs={24}>
              <Form.Item
                label={t("task:date_start")}
                name="date_start"
                style={{ width: "100%" }}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: t("task:form.error.required_time"),
                    // },
                    // {
                    //   message: t("task:form.error.required_time"),
                    //   validator: (_: any, value: Moment) => {
                    //     if (value) {
                    //       return Promise.resolve();
                    //     } else {
                    //       return Promise.reject("Some message here");
                    //     }
                    //   },
                    // },
                  ]
                }
              >
                <DatePicker
                  // defaultValue={moment(new Date())}
                  inputReadOnly
                  showTime
                  style={{ width: "100%" }}
                  placeholder={"dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:date_completed")}
                name="date_completed"
                style={{ width: "100%" }}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: t("task:form.error.required_time"),
                    // },
                    // {
                    //   message: t("task:form.error.required_time"),
                    //   validator: (_: any, value: Moment) => {
                    //     if (value) {
                    //       return Promise.resolve();
                    //     } else {
                    //       return Promise.reject("Some message here");
                    //     }
                    //   },
                    // },
                    // {
                    //   message: t("task:form.error.error_value_time"),
                    //   validator: (_: any, value: Moment) => {
                    //     console.log(form.getFieldValue("date_start"));
                    //     if (
                    //       (form.getFieldValue("date_start") !== 0 &&
                    //         moment(form.getFieldValue("date_start")) <
                    //           moment(value)) ||
                    //       form.getFieldValue("date_start") === 0
                    //     ) {
                    //       return Promise.resolve();
                    //     } else {
                    //       return Promise.reject("Some message here");
                    //     }
                    //   },
                    // },
                  ]
                }
              >
                <DatePicker
                  // defaultValue={moment(new Date())}
                  showTime
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                />
              </Form.Item>
            </Col> */}
            <Col md={8} xs={24}>
              <FormSelectNormal
                showSearch
                // required
                label={t("task:type")}
                name="type"
                placeholder="Chọn loại task"
                options={TaskModel.getTypeOption()}
              />
            </Col>

            <Col md={8} xs={24}>
              <FormSelectNormal
                showSearch
                // required
                label={t("task:priority")}
                name="priority"
                placeholder="Độ ưu tiên"
                options={TaskModel.getPriorityOption()}
              />
            </Col>
            <Col md={8} xs={24}>
              <FormSelectNormal
                showSearch
                // required
                label={t("task:ability")}
                name="ability"
                placeholder="Khả thi"
                options={TaskModel.getAbilityOption()}
              />
            </Col>
            <Col md={8} xs={24}>
              <FormSelectNormal
                showSearch
                // required
                label={t("task:public")}
                name="public"
                placeholder="Chia sẻ"
                options={TaskModel.getPublicOption()}
              />
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:scope_ids")}
                name="scope_ids"
                rules={[
                  {
                    message: t("task:form.error.error_id_related"),
                    validator: (_: any, value: string) => {
                      if (validateManyId(value) || value.length === 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col md={8} xs={24}>
              <Form.Item label={t("ID task gốc")} name="org_id">
                <Input />
              </Form.Item>
            </Col> */}

            <Col md={8} xs={24}>
              <FormSelectCustom
                label={t("ID task gốc")}
                name="org_id"
                options={parentModel.scope_ids}
              />
            </Col>

            {/* <Col md={24} xs={24}>
              <Form.Item label={t("task:note")} name="note">
                <TextArea rows={4} />
              </Form.Item>
            </Col> */}
            <Col md={24} xs={24}>
              <Form.Item name="note" label={t("task:note")}>
                <ReactQuill
                  theme="snow"
                  // value={valueEditor}
                  style={{
                    height: "500px",
                    width: "100%",
                    marginBottom: "70px",
                  }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default TaskForm;
