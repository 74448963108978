import { Col } from "antd";
import FormSubmit from "components/form/FormSubmit";
import useWindowDimensions from "hooks/useWindowDimensions";
import React, { useMemo } from "react";

const LayoutFormSidebar = ({
  submitIcon,
  submitText,
  isInsideModal,
  children,
  submitDisabled,
  span,
  hideSubmitButton,
  hideFormSubmit = false,
}: {
  submitIcon: React.ReactNode;
  submitText: string;
  children: React.ReactNode;
  isInsideModal?: boolean;
  submitDisabled?: boolean;
  span?: number;
  hideSubmitButton?: boolean;
  hideFormSubmit?: boolean;
}) => {
  const { height } = useWindowDimensions();

  const sidebarHeight = height - 60 - 95;
  const _isInsideModal = useMemo(() => {
    return typeof isInsideModal !== "undefined" && isInsideModal;
  }, [isInsideModal]);

  return (
    <Col lg={span || 6} xs={24} className="border-l bg-gray-50">
      <div
        style={{
          minHeight: _isInsideModal ? "auto" : sidebarHeight,
        }}
      >
        <div className="p-6">
          {children}
          {!_isInsideModal && !hideFormSubmit ? (
            <FormSubmit
              loading={false}
              icon={submitIcon}
              text={submitText}
              disabled={submitDisabled}
              hideSubmitButton={hideSubmitButton}
            />
          ) : null}
        </div>
      </div>
    </Col>
  );
};

export default LayoutFormSidebar;
