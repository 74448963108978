import BaseCollection from 'common/collections/BaseCollection';
import ShippingHubModel from 'common/models/ShippingHubModel';
import { ShippingHubCollectionJson, ShippingHubJson } from 'common/types/ShippingHub';

class ShippingHubCollection extends BaseCollection<
  ShippingHubJson,
  ShippingHubModel,
  ShippingHubCollectionJson
> {
  itemsFromJson(jsonItems: ShippingHubJson[]): ShippingHubModel[] {
    return jsonItems.map((item) => new ShippingHubModel(item));
  }
}

export default ShippingHubCollection;
