import { Col, DatePicker, Form, Input, Row, Switch } from "antd";
import Project from "common/constants/Project";
import ProjectModel from "common/models/ProjectModel";
import ProjectRepository from "common/repositories/ProjectRepository";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import dayjs from "dayjs";
import ProjectFormHeader from "features/project/form/ProjectFormEditorHeader";
import ProjectEmpoyeeFormSelect from "features/project/ProjectEmployeeFormSelect";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { ProjectJsonAddEdit } from "common/types/Project";
const ProjectFormEditor = ({ model }: { model: ProjectModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;
  const account = useLoginAccountStore((state) => state.account);

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const [selectedDevelopmentTeam, setSelectedDevelopmentTeam] = useState<
    number[]
  >(model.development_team);
  const [selectedScrumMaster, setSelectedScrumMaster] = useState<number[]>(
    model.scrum_master
  );

  const initialValues = {
    status: model.status || Project.STATUS_ENABLE,
    name: model.name,
    description: model.description,
    date_started:
      model.date_started !== undefined && model.date_started > 0
        ? dayjs(
            dayjs.unix(model.date_started).format("DD/MM/YYYY"),
            "DD/MM/YYYY"
          )
        : dayjs(dayjs().format("DD/MM/YYYY"), "DD/MM/YYYY"),
    date_completed_plan:
      model.date_completed_plan !== undefined && model.date_completed_plan > 0
        ? dayjs(
            dayjs.unix(model.date_completed_plan).format("DD/MM/YYYY"),
            "DD/MM/YYYY"
          )
        : dayjs(dayjs().add(1, "days").format("DD/MM/YYYY"), "DD/MM/YYYY"),
    story_prefix: model.story_prefix,
    is_scrum: 0,
    development_team_add_story:
      model.role_permission.development_team_add_story,
    scrum_master_add_story: model.role_permission.scrum_master_add_story
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProjectJsonAddEdit = {
        ...ProjectModel.getDefaultData(),
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,

        name: formData.name,
        description: formData.description,
        date_started: dayjs(formData.date_started, "DD/MM/YYYY").unix(),
        date_completed_plan: dayjs(
          formData.date_completed_plan,
          "DD/MM/YYYY"
        ).unix(),
        story_prefix: formData.story_prefix,
        status: formData.status,
        is_scrum: 0,
        development_team_add_story: formData.development_team_add_story ? 1 : 0,
        scrum_master_add_story: formData.scrum_master_add_story ? 1 : 0,
        development_team: selectedDevelopmentTeam,
        scrum_master: selectedScrumMaster
      };

      return submitData;
    },
    [
      model.id,
      account.company.id,
      account.id,
      selectedDevelopmentTeam,
      selectedScrumMaster
    ]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    doPrepareData(formData);

    let myObj: ProjectModel = await new ProjectRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <FormSelect
        label={t("common:status")}
        name="status"
        options={ProjectModel.getStatusList()}
      />
    </div>
  );

  return (
    <>
      <ProjectFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("project:form.success.update")
            : t("project:form.success.add")
        }
        redirectOnSuccess="/project"
        error_translate_prefix="project:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}>
        <FormSection
          title={t("project:form.section_project_info_title")}
          subtitle={t("project:form.section_project_info_content")}
          className="p-6 pb-4 mb-6 -m-6 border-b border-gray-100 rounded-sm"
          gutter={0}>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("project:name")}
                name="name"
                required
                rules={[
                  {
                    required: true,
                    message: t("project:form.error.error_name_required")
                  }
                ]}>
                <Input autoFocus />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("project:story_prefix")}
                name="story_prefix"
                tooltip={t("project:story_prefix_help")}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("project:description")} name="description">
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("project:date_started")} name="date_started">
                <DatePicker
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  changeOnBlur
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("project:date_completed_plan")}
                name="date_completed_plan">
                <DatePicker
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  changeOnBlur
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("project:form.section_division_info_title")}
          subtitle={t("project:form.section_division_info_content")}
          divider>
          <Row gutter={16}>
            <Col md={18} xs={24}>
              <Form.Item label={t("project:member")}>
                <ProjectEmpoyeeFormSelect
                  placeholder={t("common:form.select_employees")}
                  multiple={true}
                  onChange={(ids: number[]) => setSelectedDevelopmentTeam(ids)}
                  values={model.development_team}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label={t("project:can_add_task")}
                name="development_team_add_story"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={18} xs={24}>
              <Form.Item
                label={t("project:project_manager")}
                extra={t("project:warning_choose_project_manager")}
                required
                rules={[
                  {
                    required: true,
                    message: t("project:form.error.error_scrum_master_required")
                  }
                ]}>
                <ProjectEmpoyeeFormSelect
                  placeholder={t("common:form.select_employees")}
                  multiple={true}
                  onChange={(ids: number[]) => setSelectedScrumMaster(ids)}
                  values={model.scrum_master}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label={t("project:can_add_task")}
                name="scrum_master_add_story"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default ProjectFormEditor;
