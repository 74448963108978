import { Avatar, Tag } from "antd";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import TextTagList from "components/TextTagList";
import UserIdAvatar from "components/UserIdAvatar";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconMessages } from "@tabler/icons-react";

const ProjectTaskKanbanBoardItem = ({
  item,
  projectIterationItems,
  isDragging
}: {
  item: ProjectTaskModel;
  projectIterationItems: ProjectIterationModel[];
  isDragging?: boolean;
}) => {
  const { t } = useTranslation();

  const findIterration = useMemo(() => {
    return projectIterationItems.find(
      (i) => i.id === item.project_iteration_id
    );
  }, [projectIterationItems, item]);

  return (
    <>
      <div
        className={`bg-gray-50 p-2 rounded-lg hover:bg-yellow-50 ${
          isDragging ? "border-dotted border-2 border-gray-900 bg-blue-100" : ""
        }`}>
        <div>
          <div>
            <p className="text-sm break-words">{item.name}</p>
            {item.count_comment > 0 ? (
              <Tag
                className="mt-2"
                icon={<IconMessages size={14} color="gray" />}>
                {item.count_comment}
              </Tag>
            ) : (
              ""
            )}
          </div>

          <div className="flex items-center my-2 text-xs text-gray-400">
            <p>
              # {ProjectTaskModel.getType(item.type)?.label}
              {" - "}
              {findIterration
                ? findIterration.name
                : t("projectiteration:un_milestone")}
            </p>
          </div>

          {item.assignees !== "" ? (
            <div className="text-left">
              <Avatar.Group maxCount={5} size="small">
                {item.assignees.split(",").map((i) => (
                  <UserIdAvatar key={i} id={+i} hideTooltip={false} />
                ))}
              </Avatar.Group>
            </div>
          ) : null}

          {item.tags.length > 0 ? (
            <div className="text-left">
              <TextTagList tag={item.tags} filterName={"tag"} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ProjectTaskKanbanBoardItem;
