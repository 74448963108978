import { Col, Pagination, Row } from "antd";
import BaseModel from "common/models/BaseModel";
import { Filter } from "common/types/Filter";
import ReportUtil from "common/utils/report";
import React from "react";
import { useTranslation } from "react-i18next";

const PageDataPagination = <F extends Filter, M extends BaseModel>({
  filters,
  setFilters,
  total,
  dataSource,
}: {
  filters: F;
  setFilters: (f: F) => void;
  total: number;
  dataSource: M[];
}) => {
  const { t } = useTranslation();

  // if (+filters.limit >= total) return null;

  return (
    <div className="py-2 -mt-0.5 text-center bg-gray-200">
      <Row gutter={32} className="items-center inline-flex py-1.5 px-2">
        <Col className="text-gray-600">
          {total > 0
            ? t("common:table.result", {
                from: ReportUtil.numberFormatter(
                  1 + (+filters.page - 1) * +filters.limit
                ),
                to: ReportUtil.numberFormatter(
                  dataSource.length + (+filters.page - 1) * +filters.limit
                ),
                total: ReportUtil.numberFormatter(total),
              })
            : t("common:table.empty_result")}
        </Col>
        <Col className="">
          <Pagination
            className="text-right"
            current={+filters.page}
            pageSize={+filters.limit}
            total={total}
            hideOnSinglePage={false}
            showQuickJumper={true}
            onChange={(newpage, pageSize) =>
              setFilters({ ...filters, page: newpage, limit: pageSize })
            }
            pageSizeOptions={["10", "20", "50"]}
            showSizeChanger={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PageDataPagination;
