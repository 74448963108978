import BaseCollection from "common/collections/BaseCollection";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import { InventoryReceiptJson } from "common/types/InventoryResceipt";
import { InventoryReceiptCollectionJson } from "common/types/InventoryResceipt";

class InventoryReceiptCollection extends BaseCollection<
	InventoryReceiptJson,
	InventoryReceiptModel,
	InventoryReceiptCollectionJson
> {


	itemsFromJson(jsonItems: InventoryReceiptJson[]): InventoryReceiptModel[] {
		return jsonItems.map((item) => new InventoryReceiptModel(item));
	}
	
}

export default InventoryReceiptCollection;
