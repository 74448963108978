import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { FileStatsByType, FileStatsJson } from 'common/types/FileStats';

import BaseModel from './BaseModel';

class FileStatsModel extends BaseModel implements BaseModelJson<FileStatsJson> {
  company_id: number;
  size_by_type: FileStatsByType[];
  count_file_by_type: FileStatsByType[];
  quota: number;
  allow_file_types: string[];
  file_max_size: number;
  date_created: number;

  constructor(json: FileStatsJson) {
    super();

    this.company_id = json.company_id || 0;
    this.size_by_type = json.size_by_type || [];
    this.count_file_by_type = json.count_file_by_type || [];
    this.quota = json.quota || 0;
    this.allow_file_types = json.allow_file_types || [];
    this.file_max_size = json.file_max_size || 0;
    this.date_created = json.date_created || 0;
  }

  toJson(): FileStatsJson {
    return {
      company_id: this.company_id,
      size_by_type: this.size_by_type,
      count_file_by_type: this.count_file_by_type,
      quota: this.quota,
      allow_file_types: this.allow_file_types,
      file_max_size: this.file_max_size,
      date_created: this.date_created,
    };
  }

  static getDefaultData(): FileStatsJson {
    return {
      company_id: 0,
      size_by_type: [],
      count_file_by_type: [],
      quota: 0,
      allow_file_types: [],
      file_max_size: 0,
      date_created: 0,
    };
  }
}

export default FileStatsModel;
