import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useTranslation } from "react-i18next";

import { IconFolders } from "@tabler/icons-react";

const DmsNewsListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("dmsnews:heading_list")}
      siteMenuOpenKey="delivery.portal"
      siteMenuSelectedKey="/dmsnews"
    >
      <PageHeaderButton
        type="default"
        link="/dmsnewscategory"
        icon={<IconFolders className="mr-1" size={14} />}
      >
        {t("dmsnews:category_management")}
      </PageHeaderButton>
      <PageHeaderButton link="/dmsnews/add" className="ml-2">
        {t("dmsnews:add_button")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default DmsNewsListHeader;
