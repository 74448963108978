import BaseCollection from 'common/collections/BaseCollection';
import DmsBannerModel from 'common/models/DmsBannerModel';
import { DmsBannerCollectionJson, DmsBannerJson } from 'common/types/DmsBanner';

class DmsBannerCollection extends BaseCollection<
  DmsBannerJson,
  DmsBannerModel,
  DmsBannerCollectionJson
> {
  itemsFromJson(jsonItems: DmsBannerJson[]): DmsBannerModel[] {
    return jsonItems.map((item) => new DmsBannerModel(item));
  }
}

export default DmsBannerCollection;
