import { App, Col, Form, Input, Row } from "antd";
import Ecom from "common/constants/Ecom";
import EcomModel from "common/models/EcomModel";
import EcomRepository from "common/repositories/EcomRepository";
import Error from "components/LayoutError";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IconBan, IconCircleCheck } from "@tabler/icons-react";

import type { EcomJsonAddEdit } from "common/types/Ecom";
const EcomFormAdd = ({
  initialValues,
  setOpen,
  setProcessing,
  onAddSuccess,
}: {
  initialValues: any;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onAddSuccess: (o: EcomModel) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = App.useApp();

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let assetData = initialValues;
      if (initialValues.type === Ecom.TYPE_WOO_COMMERCE) {
        assetData = {
          ...initialValues,
          title: formData.title,
          url: formData.url,
          api_key: formData.api_key,
          api_secret: formData.api_secret,
        };
      }
      const submitData: EcomJsonAddEdit = {
        ...EcomModel.getDefaultData(),
        ...assetData,
        status: Ecom.STATUS_ENABLE,
      };

      return submitData;
    },
    [initialValues]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    console.log("abs");
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    let item: EcomModel = await new EcomRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="ecom:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onAddSuccess(item);
      setOpen(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      layout="horizontal"
      labelAlign="left"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id="ecominit-form"
    >
      {initialValues.type === Ecom.TYPE_HARAVAN ? (
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Form.Item
              label={t("ecom:form.url")}
              name="url"
              className="mb-0"
              labelAlign="right"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={" "}
              colon={false}
              className="mt-0"
              style={{ fontSize: "smaller" }}
            >
              Access Token:{" "}
              {initialValues.access_token.length > 0 ? (
                <>
                  <IconCircleCheck color="green" size={16} />
                  {initialValues.refresh_token.length > 0 ? (
                    <>
                      &nbsp; &nbsp; &nbsp; Refresh Token{" "}
                      <IconCircleCheck color="green" size={16} />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <IconBan color="red" size={16} />
                </>
              )}
            </Form.Item>
          </Col>
        </Row>
      ) : null}

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("ecom:form.title")}
            name="title"
            labelAlign="right"
            required
            rules={[
              {
                required: true,
                message: t("ecom:form.error.error_title_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {initialValues.type === Ecom.TYPE_WOO_COMMERCE ? (
        <>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("ecom:form.woocommerce_api_key")}
                name="api_key"
                labelAlign="right"
                required
                rules={[
                  {
                    required: true,
                    message: t("ecom:form.error.error_api_key_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("ecom:form.woocommerce_api_secret")}
                name="api_secret"
                labelAlign="right"
                required
                rules={[
                  {
                    required: true,
                    message: t("ecom:form.error.error_api_secret_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("ecom:form.url")}
                name="url"
                labelAlign="right"
                required
                rules={[
                  {
                    required: true,
                    message: t("ecom:form.error.error_url_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : null}
    </Form>
  );
};

export default EcomFormAdd;
