import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import CollectionModel from "common/models/CollectionModel";
import { Col, Drawer, Row } from "antd";
import AcademyCustomCollection from "../AcademyCustomColleciton";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import AcademyCard from "../AcademyCard";
import StoryRepository from "common/repositories/StoryRepository";
import StoryModel from "common/models/StoryModel";
import AcademyRelated from "../AcademyRelated";
import Helper from "common/utils/helper";
const AcademyDetail = () => {
  const { catid, storeid } = useParams();
  const categoriesId = catid!;
  const stoyId = storeid!;

  const navigate = useNavigate();

  const [model, setModel] = useState<CollectionModel>(
    new CollectionModel(CollectionModel.getDefaultData())
  );
  const [stories, setStories] = useState<StoryModel[]>([]);
  const [idCat, setIdCat] = useState(1);

  const defaultfilterStories = useMemo(() => {
    return StoryRepository.getDefaultFilter();
  }, []);

  const getStories = useCallback(async (id: string) => {
    const response = await new StoryRepository().getItemsPublic({
      filters: { ...defaultfilterStories, collection_id: id, not_id: stoyId },
    });
    if (!response.hasError()) {
      setStories(response.items);
    } else {
      navigate({ pathname: "/academy/notfound" });
      // setErrors(response.error.errors);
    }
  }, []);
  useEffect(() => {
    if (storeid && catid) {
      getStories(catid);
    }
  }, [catid, storeid]);

  return (
    <div className={styles.detailWrapper}>
      <div className={styles.detailBody}>
        <div className={styles.nav}>
          <AcademyCustomCollection></AcademyCustomCollection>
        </div>
        <div className={styles.content}>
          <Outlet></Outlet>
        </div>
      </div>
      {storeid && (
        <div className={styles.footer}>
          {stories.length > 0 && (
            <AcademyRelated
              title="Đọc thêm"
              slideProps={{
                setButtonNext: "swiper-button-galley-next",
                setButtonPrev: "swiper-button-galley-prev",
                styleButton_next: { right: "-50px", border: "none" },
                styleButton_prev: { left: "-50px", border: "none" },
                dataSource: stories,
                initialSlide: 0,
                isloading: false,
                navigation: true,
                scrollbar: false,
                styleWrapper: { height: "100%" },
                autoplay: false,
                responsive: {
                  xxs: { slidesPerView: 1.8, spaceBetween: 8 },
                  xs: { slidesPerView: 2.2, spaceBetween: 8 },
                  sm: { slidesPerView: 2.2, spaceBetween: 8 },
                  md: { slidesPerView: 3.2, spaceBetween: 8 },
                  lg: { slidesPerView: 4.3, spaceBetween: 28 },
                  xl: { slidesPerView: 4.3, spaceBetween: 28 },
                },
                itemRender: (i: StoryModel) => {
                  return (
                    <>
                      <AcademyCard
                        link={`/academy/${categoriesId}/${i.handle}`}
                        key={i.id}
                        item={i}
                        heightImage={300}
                        size="small"
                      ></AcademyCard>
                    </>
                  );
                },
              }}
            ></AcademyRelated>
          )}
        </div>
      )}
    </div>
  );
};

export default AcademyDetail;
