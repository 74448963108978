import { Node, nodeInputRule, mergeAttributes } from "@tiptap/core";
import { Component, FC, ReactElement } from "react";

export interface ImageOptions {
  inline: boolean;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
  resizeIcon: FC | Component | ReactElement;
  useFigure: boolean;
}

export type ImageAttribute = {
  src: string;
  alt?: string;
  title?: string;
  width?: string;
  height?: string;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imagepicker: {
      setImageExtra: (options: ImageAttribute) => ReturnType;
      updateImage: (options: ImageAttribute) => ReturnType;
    };
  }
}

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const HtmlEditorExtensionImagePicker = Node.create<ImageOptions>({
  name: "imagepicker",

  addOptions() {
    return {
      inline: true,
      allowBase64: true,
      HTMLAttributes: {},
      resizeIcon: <>⊙</>,
      useFigure: false,
    };
  },

  defaultOptions: {
    inline: true,
    allowBase64: true,
    HTMLAttributes: {},
    resizeIcon: <>⊙</>,
    useFigure: false,
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block";
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: "100%",
        renderHTML: (attributes) => {
          return {
            width: isNaN(parseInt(attributes.width.toString()))
              ? 50
              : attributes.width,
          };
        },
      },
      height: {
        default: "auto",
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
      },
      isDraggable: {
        default: true,
        renderHTML: (attributes) => {
          return {};
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64
          ? "img[src]"
          : 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "img",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setImageExtra:
        (options) =>
        ({ commands }) => {
          return commands.insertContent(
            {
              type: this.name,
              attrs: options,
            },
            { updateSelection: false }
          );
        },
      updateImage:
        (options) =>
        ({ commands, chain }) => {
          console.log("setNode", options);
          return chain().setNode(this.name, options).run();
          // return commands.insertContent({
          //   type: this.name,
          //   attrs: options,
          // });
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, alt, src, title, width, height] = match;
          return { src, alt, title, width, height };
        },
      }),
    ];
  },
});
