import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  ImportOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { IconPlus } from "@tabler/icons-react";
import {
  Button,
  Progress,
  Row,
  Typography,
  Upload,
  UploadProps,
  message,
  notification,
} from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import TextArea from "antd/lib/input/TextArea";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import React, { LegacyRef, Ref, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
type Props = {
  type:
    | "product"
    | "tagproduct"
    | "tagproductcollection"
    | "tagstorycollection"
    | "tagstory"
    | "productintag";
  id: number;
  handleAddSkuSuccess?: any;
  setOpen: any;
  existValues?: string[];
};

const AddProductBySku = ({
  id,
  handleAddSkuSuccess,
  setOpen,
  type,
  existValues,
}: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [skuList, setSkuList] = useState<string[]>([]);
  const [skuValue, setSkuValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [fileExcel, setfileExcel] = useState<File | null>(null);
  const [skuExist, setSkuExist] = useState([]);

  const handleChangeInput = (val: string) => {
    const formattedSKUs = val.trim().split(/\s*,\s*|\s+/);
    const uniqueSKUs = [...formattedSKUs].filter((item) => item !== "");
    setSkuList(uniqueSKUs);
  };

  const getPercentValue = (total: number) => {
    let percentValue = (20 / total) * 100;
    if (percentValue > 100) {
      setPercent(100);
    } else {
      setPercent(percentValue);
    }
  };

  const chunkArray = (array: string[], size: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };
  const submitSkuToItem = async () => {
    setLoading(true);
    let list = skuList;
    if (existValues) {
      list = skuList.filter(
        (sku) => !existValues.some((value) => value == sku)
      );
      if (!list.length) {
        notification.error({
          message: "tất cả sku đã tồn tại trong nhóm hàng",
        });
        setLoading(false);

        return;
      }
    }

    if (list.length > 0) {
      message.loading("Vui lòng đợi");
      const total = list.length;
      const response = await new ProductCollectionRepository().saveRemoteDetail(
        {
          collection_id: id,
          item_skus: list,
          time: Math.random(),
        }
      );

      if (!response.hasError()) {
        getPercentValue(total);
        setSkuList([]);
        setSkuValue("");
        message.success({
          content: "Thao tác add sku thành công",
        });
        handleAddSkuSuccess && handleAddSkuSuccess(list);
      } else {
        message.error({
          content: t(
            `productcollection:form.error.${response.error.errors[0]}`
          ),
        });
      }
    }
    setLoading(false);
  };

  const uploadFileExcelAndRead = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target?.result;
        if (typeof binaryStr === "string") {
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
            header: 1,
          });
          //  SKU in row 1
          const skuList = jsonData.slice(1).map((row) => row[0] as string);
          setSkuList(skuList);
          setSkuValue(skuList.join(","));
        }
      };
      setfileExcel(file);
      reader.readAsBinaryString(file);
    }
  };
  return (
    <>
      <div className=" mb-2 flex ">
        <input
          ref={inputRef}
          type="file"
          onChange={uploadFileExcelAndRead}
          className=" hidden"
          accept=".xls, .xlsx"
        ></input>
        {fileExcel?.name ? (
          <p>
            {fileExcel?.name}{" "}
            <CloseCircleOutlined
              onClick={() => {
                if (inputRef?.current) {
                  inputRef.current.value = "";
                  setfileExcel(null);
                }
              }}
            />
          </p>
        ) : (
          <Button
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
            icon={<ImportOutlined />}
          >
            Import từ file excel
          </Button>
        )}
      </div>
      <p className=" mb-2">Hoặc nhập sku vào ô dưới</p>
      <TextArea
        placeholder="Import SKU"
        value={skuValue}
        onChange={(e) => {
          e.preventDefault();
          setSkuValue(e.target.value);
          handleChangeInput(e.target.value);
        }}
      />

      <div className="flex gap-4 justify-end items-cente mt-4">
        {/* <Progress percent={percent} /> */}
        <Typography.Text>
          Tổng cộng: <span className="text-red-500">{skuList.length}</span> sản
          phẩm
        </Typography.Text>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            submitSkuToItem();
          }}
          loading={loading}
          type="primary"
          icon={
            <IconPlus
              size={16}
              stroke={3}
              className="-mt-0.5 hidden sm:inline-block"
            />
          }
        >
          Lưu
        </Button>
      </div>
    </>
  );
};

export default AddProductBySku;
