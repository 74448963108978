import { Avatar, Button, Divider, List, Modal } from "antd";
import CompanyModel from "common/models/CompanyModel";
import UserAvatar from "components/UserAvatar";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const HeaderCompanySwitcher = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (v: boolean) => any;
}) => {
  const { t } = useTranslation();

  const [account] = useLoginAccountStore((state) => [state.account]);

  return (
    <Modal
      title={"Thay đổi công ty truy cập"}
      open={open}
      closable={true}
      maskClosable={true}
      onCancel={() => {
        setOpen(false);
      }}
      footer={null}
      cancelText={t("common:close")}
    >
      {/* {account.company_list.length === 1 ? (
        <div className="text-center my-10">
          <div className="italic text-xs">
            Bạn chưa được thêm vào công ty nào khác.
          </div>
        </div>
      ) : (
        <List
          dataSource={account.company_list.filter(
            (i) => i.company.id !== account.company.id
          )}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button className="" key={"link"}>
                  <a
                    href={
                      new CompanyModel(item.company).getIdentityUrl() +
                      "/account/authorize"
                    }
                  >
                    Truy cập
                  </a>
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  item.employee.avatar.url.length > 0 ? (
                    <Avatar src={item.employee.avatar.url} />
                  ) : (
                    <UserAvatar user={item.employee} />
                  )
                }
                title={item.company.name}
                description={item.employee.job_title}
              />
            </List.Item>
          )}
        />
      )} */}

      <Divider className="my-2 mb-0.5" />
      <div className="text-center bg-gray-100 p-4">
        Bạn đang đăng nhập ở công ty{" "}
        <span className="font-bold">{account.company.name}</span>
      </div>
    </Modal>
  );
};

export default HeaderCompanySwitcher;
