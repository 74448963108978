import BaseCollection from 'common/collections/BaseCollection';
import ProductVariantColorModel from 'common/models/ProductVariantColorModel';
import {
    ProductVariantColorCollectionJson, ProductVariantColorJson
} from 'common/types/ProductVariantColor';

class ProductVariantColorCollection extends BaseCollection<
  ProductVariantColorJson,
  ProductVariantColorModel,
  ProductVariantColorCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductVariantColorJson[]
  ): ProductVariantColorModel[] {
    return jsonItems.map((item) => new ProductVariantColorModel(item));
  }
}

export default ProductVariantColorCollection;
