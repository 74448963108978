import { Skeleton } from "antd";
import DataGridTableFieldModel from "common/models/DataGridTableFieldModel";
import DataGridTableFieldRepository from "common/repositories/DataGridTableFieldRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DataGridTableFieldForm from "./DataGridTableFieldForm";

const DataGridTableFieldFormModal = ({
  id,
  newDisplayOrder,
  open,
  setOpen,
  onSaveSuccess,
  tableId,
}: {
  id: number;
  newDisplayOrder: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: DataGridTableFieldModel) => void;
  tableId: number;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<DataGridTableFieldModel>(
    new DataGridTableFieldModel(DataGridTableFieldModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new DataGridTableFieldRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={
        id > 0
          ? t("datagridtablefield:heading_edit")
          : t("datagridtablefield:heading_add")
      }
      width={640}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="datagridtablefield-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <DataGridTableFieldForm
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <DataGridTableFieldForm
          model={
            new DataGridTableFieldModel({
              ...DataGridTableFieldModel.getDefaultData(),
              datagrid_table_id: tableId,
              display_order: newDisplayOrder,
            })
          }
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default DataGridTableFieldFormModal;
