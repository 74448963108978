import { Form, Input, InputNumber } from "antd";
import CustomerModel from "common/models/CustomerModel";
import { Filter } from "common/types/Filter";
import FormRangeNumber from "components/form/FormRangeNumber";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import CustomerTypeFormSelect from "features/customertype/CustomerTypeFormSelect";
import { useTranslation } from "react-i18next";

const CustomerListFilter = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	total,
}: {
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
	total: number;
}) => {
	const { t } = useTranslation();

	return (
		<PageDataFilterForm
			filters={filters}
			setFilters={setFilters}
			total={total}
			defaultFilters={defaultFilters}
			convertValueByKeyFilter={["customer_type_id"]}>
			<Form.Item name="keyword">
				<Input
					allowClear
					placeholder={t("customer:filter_keyword_placeholder")}
				/>
			</Form.Item>
			<Form.Item name="id">
				<Input allowClear placeholder={t("customer:id")} />
			</Form.Item>

			<CustomerTypeFormSelect
				name="customer_type_id"
				label=""
				placeholder={t("customer:customer_type")}
				popupMatchSelectWidth={false}
			/>
		<Form.Item name="status">
		<FormSelect
						className="flex-1 m-0"
						name={"status"}
						placeholder="Trạng thái"
						options={CustomerModel.getStatusList()}></FormSelect>
			</Form.Item>

			<Form.Item className="flex-1 flex col-span-2">
				{/* <InputNumber placeholder="" min={0}></InputNumber> */}
				<div className="flex">
					<FormSelect
						className="flex-1 m-0"
						name={"debt"}
						placeholder="Công nợ khách hàng"
						options={[
							{
								label: "Có công nợ",
								value: 1,
							},
							{
								label: "Không công nợ",
								value: 0,
							},
						]}></FormSelect>
					<FormRangeNumber
						FormItemPropsMax={{ name: "debt_to" }}
						FormItemPropsMin={{ name: "debt_from" }}></FormRangeNumber>
				</div>
			</Form.Item>
		</PageDataFilterForm>
	);
};

export default CustomerListFilter;
