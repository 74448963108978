import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DepartmentFormFilter from "features/department/DepartmentFormFilter";
import OfficeFormFilter from "features/office/OfficeFormFilter";
import { useTranslation } from "react-i18next";
import FilterBody from "components/filter/FilterBody";

const LeaveAssignListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["department_id"]}
      >
        <Form.Item name="keyword">
          <Input
            allowClear
            placeholder={t("common:filter.filter_keyword_placeholder")}
          />
        </Form.Item>
        <DepartmentFormFilter
          placeholder={t("common:filter.department")}
          name="department_id"
          allowClear
          popupMatchSelectWidth={false}
        />
      </PageDataFilterForm>
    </>
  );
};

export default LeaveAssignListFilter;
