import { Col, Row } from "antd";

import PageHeader from "components/page/PageHeader";

const SettingPromotionsHeader = ({ children }: { children?: React.ReactNode }) => {
  return (
    <PageHeader
      heading={"Cài đặt cho chương trình khuyến mãi"}
      siteMenuOpenKey="content"
      siteMenuSelectedKey="/settingpromotions"
    >
      <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          {children}
        </Col>
      </Row>
    </PageHeader>
  );
};

export default SettingPromotionsHeader;
