import ProductVariantSizeCollection from 'common/collections/ProductVariantSizeCollection';
import ProductVariantSizeModel from 'common/models/ProductVariantSizeModel';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

import type { GetListProps } from "common/types/Filter";
import type {
  FilterProductVariantSize,
  ProductVariantSizeJsonAddEdit,
} from "common/types/ProductVariantSize";

const SERVICE_URL = "/productvariantsizes";

class ProductVariantSizeApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProductVariantSize>
  ): Promise<ProductVariantSizeCollection> {
    let collection = new ProductVariantSizeCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ProductVariantSizeModel> {
    let item = new ProductVariantSizeModel(
      ProductVariantSizeModel.getDefaultData()
    );

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ProductVariantSizeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(
    data: ProductVariantSizeJsonAddEdit
  ): Promise<ProductVariantSizeModel> {
    let item = new ProductVariantSizeModel(
      ProductVariantSizeModel.getDefaultData()
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProductVariantSizeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(
    data: ProductVariantSizeJsonAddEdit
  ): Promise<ProductVariantSizeModel> {
    let item = new ProductVariantSizeModel(
      ProductVariantSizeModel.getDefaultData()
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ProductVariantSizeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ProductVariantSizeApi;
