import WebHookApi from "common/api/WebHookApi";
import WebHookCollection from "common/collections/WebHookCollection";
import WebHookModel from "common/models/WebHookModel";
import BaseRepository from "./BaseRepository";
import { GetListProps } from "common/types/Filter";
import type { WebHookJsonAddEdit, FilterWebHook } from "common/types/WebHook";

class WebHookRepository extends BaseRepository<WebHookApi> {
  _api: WebHookApi | null;

  constructor() {
    super();
    this._api = new WebHookApi(true);
  }

  static getDefaultFilters(): FilterWebHook {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterWebHook>) {
    return this._api ? await this._api.getList(props) : new WebHookCollection();
  }

  async getItem(id: number): Promise<WebHookModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new WebHookModel(WebHookModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: WebHookJsonAddEdit): Promise<WebHookModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new WebHookModel(WebHookModel.getDefaultData());
    }
  }
}

export default WebHookRepository;

