import React from "react";
import styles from "./styles.module.css";
import AcademySlide from "../AcademySlide";
import { PropsAcademySlide } from "../AcademySlide";
type Props = {
  title?: string;
  slideProps: PropsAcademySlide;
};

const AcademyRelated = ({ title, slideProps }: Props) => {
  return (
    <div className={`${styles.warpper}`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>
        <AcademySlide {...slideProps}></AcademySlide>
      </div>
    </div>
  );
};

export default AcademyRelated;
