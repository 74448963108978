import ProjectTask from "common/constants/ProjectTask";
import ProjectModel from "common/models/ProjectModel";
import { ProjectTaskKanbanColumn } from "common/types/ProjectTask";
import useWindowDimensions from "hooks/useWindowDimensions";
import React, { useMemo } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { Droppable } from "@hello-pangea/dnd";
import { IconBan, IconCircleCheck } from "@tabler/icons-react";

const ProjectTaskKanbanBoardMoreAction = ({
  projectModel,
  column,
  activeAction,
}: {
  projectModel: ProjectModel;
  column: ProjectTaskKanbanColumn;
  activeAction: boolean;
}) => {
  const account = useLoginAccountStore((state) => state.account);

  const actionHeight = 60;

  //get screen width
  const { width } = useWindowDimensions();

  const canManage = useMemo(() => {
    return projectModel.scrum_master.findIndex((i) => i === account.id) >= 0;
  }, [account, projectModel]);

  return (
    <>
      {activeAction && canManage ? (
        <Droppable
          droppableId={column.value.toString()}
          key={column.value.toString()}
        >
          {(provided, snapshot) => {
            let collumnCompleteWidth = (width - 16) / 2 - 12;
            let collumnCancelWidth = (width - 16) / 2 - 12;
            return (
              <div
                style={{
                  height: actionHeight,
                  width:
                    column.value === ProjectTask.STATUS_COMPLETED
                      ? collumnCompleteWidth
                      : collumnCancelWidth,
                }}
                className={`fixed bottom-2 text-gray-900 cursor-pointer ${
                  column.value === ProjectTask.STATUS_COMPLETED
                    ? "bg-green-300 hover:bg-green-400"
                    : "bg-red-300 hover:bg-red-400"
                }`}
              >
                <div
                  className={`text-lg font-bold text-center`}
                  style={{
                    height: actionHeight,
                    width: (width - 16) / 2,
                    marginTop: actionHeight / 3,
                  }}
                >
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {column.value === ProjectTask.STATUS_COMPLETED ? (
                      <IconCircleCheck
                        size={25}
                        className="-mt-0.5 mr-1 text-green-600"
                      />
                    ) : (
                      <IconBan
                        size={25}
                        className="-mt-0.5 mr-1 text-red-600"
                      />
                    )}
                    {column.label}
                  </div>
                </div>
              </div>
            );
          }}
        </Droppable>
      ) : null}
    </>
  );
};

export default ProjectTaskKanbanBoardMoreAction;
