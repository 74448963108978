import Error from "common/api/Error";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import {
    BaseCollectionFetchingJson
} from "common/types/BaseCollectionJson";

// Generic Type "M" (Model) implements BaseModelJson Interface, so that
// all model will have "toJson()" method so that it can be used in collection.toJson() method

// Generic Type "J" (Collection Json) extends BaseCollectionJson, so that
// all collection will have items, total, currentpage, limit property
// for used in fromJson

abstract class BaseCollectionFetching<
  MJson,
  M extends BaseModelJson<MJson>,
  J extends BaseCollectionFetchingJson<MJson>
> {
  items: M[];
  error: Error;

  constructor(json?: J) {
    this.items = [];
    this.error = new Error({ statusCode: 0, errors: [] });

    if (typeof json !== "undefined" && json) {
      this.fromJson(json);
    }
  }

  abstract itemsFromJson(jsonItems: MJson[]): M[];

  fromJson(json: J) {
    this.items = this.itemsFromJson(json);
  }

  // toJson(): J {
  //   return {
  //     items: this.items.map((item) => item.toJson()),
  //   } as J;
  // }

  withError(error: Error) {
    this.items = [];
    this.error = error;
  }

  hasError() {
    return (
      this.error != null &&
      this.error.errors != null &&
      this.error.errors.length > 0
    );
  }
}

export default BaseCollectionFetching;
