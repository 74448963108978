import { Col, Row } from "antd";
import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import TextMoney from "components/TextMoney";
import TextNumber from "components/TextNumber";
import dayjs from "dayjs";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import ReportDateRangePicker from "features/report/ReportDateRangePicker";
import ReportViewHeader from "features/report/view/ReportViewHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { FilterBiCenter, DateRange } from "common/types/BiCenter";
const ReportView = () => {
  const { t } = useTranslation();
  const defaultFilters: FilterBiCenter = useMemo(
    () => BiCenterRepository.getDefaultFilters(),
    []
  );

  const [filters, setFilters] = useStateFilter<FilterBiCenter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const onChangeDateRange = (
    value: DateRange,
    value_compare: DateRange | null
  ) => {
    setFilters({
      ...filters,
      start: BiCenterModel.momentToString(value[0]),
      end: BiCenterModel.momentToString(value[1]),
      ...(value_compare
        ? {
            startcompare: BiCenterModel.momentToString(value_compare[0]),
            endcompare: BiCenterModel.momentToString(value_compare[1])
          }
        : { startcompare: "", endcompare: "" })
    });
  };

  const getRange = (): DateRange => {
    if (filters.start.length > 0 && filters.end.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.start),
        BiCenterModel.stringToMoment(filters.end)
      ];
    } else {
      //default: last30days
      return [
        dayjs().subtract(30, "days").startOf("day"),
        dayjs().subtract(1, "days").endOf("day")
      ];
    }
  };

  const getRangeCompare = (): DateRange | null => {
    if (filters.startcompare.length > 0 && filters.endcompare.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.startcompare),
        BiCenterModel.stringToMoment(filters.endcompare)
      ];
    } else {
      //user already set date start by MANUAL,
      //so, do not return default with compare (disable compare)
      if (filters.start.length > 0 && filters.end.length > 0) {
        return null;
      } else {
        //default: previousperiod of last30days
        return [
          dayjs().subtract(60, "days").startOf("day"),
          dayjs().subtract(31, "days").endOf("day")
        ];
      }
    }
  };

  return (
    <>
      <ReportViewHeader heading="Doanh thu">
        <ReportDateRangePicker
          range={getRange()}
          range_compare={getRangeCompare()}
          onChange={onChangeDateRange}
          format="DD/MM/YYYY"
        />
      </ReportViewHeader>
      <div className="p-6">
        <Row>
          <Col flex="1">
            <ReportChartDataTable
              className="pd-10 mg-10"
              title={"Báo cáo vận đơn theo shipper"}
              dataService=""
              dataTable="shippingorder"
              dataSelect="count(id), shipper, sum(cod)"
              dataFilter=""
              dataGroupBy="shipper"
              dataOrderBy="sum(cod) DESC"
              keyMapping={[
                {
                  name: "shipper",
                  title: t("report:table.order.shipper"),
                  formatter: (value: number | string) => (
                    <span>Shipper #{value}</span>
                  )
                },
                {
                  name: "sum_cod",
                  title: t("report:table.order.sum_cod"),
                  align: "right",
                  sortField: "sum(cod)",
                  barColorIndex: 0,
                  formatter: (value: number | string) => (
                    <TextMoney money={value} noSymbol={false} />
                  )
                },
                {
                  name: "count_id",
                  align: "right",
                  sortField: "count(id)",
                  barColorIndex: 1,
                  title: t("report:table.order.count_id"),
                  formatter: (value: number | string) => (
                    <TextNumber number={value} />
                  )
                }
              ]}
              dateRange={getRange()}
            />
          </Col>
          <Col flex="1"></Col>
        </Row>
      </div>
    </>
  );
};

export default ReportView;
