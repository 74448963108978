import BiCenterCollection from 'common/collections/BiCenterCollection';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';
import ApiError from './Error';

import type { DataQuery } from "common/types/BiCenter";
const SERVICE_URL = "/bicenters";

class BiCenterApi extends BaseApi {
  async query(data: DataQuery): Promise<BiCenterCollection> {
    let collection = new BiCenterCollection();

    try {
      let postData = {
        query: {
          ...data,
        },
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + (data.service !== "" ? "/" + data.service : ""),
        postData
      );

      if (response.hasOwnProperty("data")) {
        //for bicenter response, we need to wrap in valid collection structure
        collection.fromJson({
          total: response.data.length,
          currentpage: 1,
          limit: response.data.length,
          items: response.data,
        });
      }
    } catch (error: any) {
      //FOR REPORTING BICENTER, WE USE ANOTHER ERRORS RESPONSE
      if (
        error.hasOwnProperty("response") &&
        error?.response?.status === 422 &&
        !Array.isArray(error.response.data["error"])
      ) {
        let extractedErrors = [];
        for (const prop in error.response.data["error"]) {
          if (error.response.data["error"][prop] !== null) {
            extractedErrors.push(error.response.data["error"][prop]);
          }
        }
        collection.withError(
          new ApiError({ statusCode: 422, errors: extractedErrors })
        );
      } else {
        collection.withError(BaseApi.handleError(error));
      }
    }

    return collection;
  }
}

export default BiCenterApi;
