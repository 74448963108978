import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import { DateRange, FilterBiCenter } from "common/types/BiCenter";
import dayjs from "dayjs";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReportDateRangePicker from "../ReportDateRangePicker";
import ReportViewHeader from "../view/ReportViewHeader";
import ReportCustomerFeed from "./ReportCustomerFeed";
import ReportCustomerGeneral from "./ReportCustomerGeneral";
import ReportCustomerOrder from "./ReportCustomerOrder";
import ReportCustomerOrigin from "./ReportCustomerOrigin";
import ReportCustomerPoint from "./ReportCustomerPoint";
import ReportCustomerRegion from "./ReportCustomerRegion";
import ReportCustomerType from "./ReportCustomerType";

const ReportCustomerWrapper = ({
  siteMenuOpenKey,
  siteMenuSelectedKey
}: {
  siteMenuOpenKey: string;
  siteMenuSelectedKey: string;
}) => {
  const { t } = useTranslation();

  const defaultFilters: FilterBiCenter = useMemo(
    () => BiCenterRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterBiCenter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const getPathNameParts = useMemo(() => {
    let pathNameParts = siteMenuSelectedKey.split("/");
    //discard first item (empty string)
    pathNameParts.shift();
    //discard controller item
    pathNameParts.shift();
    //discard action item
    pathNameParts.shift();
    return pathNameParts;
  }, [siteMenuSelectedKey]);

  const getNavByPathLocation = useMemo(() => {
    const nav = [
      {
        text: t("report:heading_view")
      },
      {
        text: t("report:heading_report_customer")
      }
    ];
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          nav.push({
            text: t("report:heading_report_order_general")
          });
          break;
        case "region":
          nav.push({
            text: t("report:heading_report_customer_region")
          });
          break;
        case "origin":
          // nav.push({
          //   text: t("report:heading_report_customer_origin")
          // });
          break;
        case "type":
          nav.push({
            text: t("report:heading_report_customer_type")
          });
          break;
        case "feed":
          // nav.push({
          //   text: t("report:heading_report_customer_feed")
          // });
          break;
        case "order":
          nav.push({
            text: t("report:heading_report_customer_order")
          });
          break;
        case "point":
          nav.push({
            text: t("report:heading_report_customer_point")
          });
          break;
      }
    }
    return nav;
  }, [t, getPathNameParts]);

  const getHeadingByPathLocation = useMemo(() => {
    let heading = "";
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          heading = "Báo cáo tổng quan";
          break;
        case "region":
          heading = "Báo cáo khách hàng theo khu vực";
          break;
        case "origin":
          heading = "Báo cáo nguồn khách hàng";
          break;
        case "type":
          heading = "Báo cáo theo nhóm khách hàng";
          break;
        case "feed":
          heading = "Báo cáo theo hoạt động tương tác với khách hàng";
          break;
        case "order":
          heading = "Báo cáo theo doanh thu";
          break;
        case "point":
          heading = "Báo cáo giao dịch điểm tích lũy";
          break;
      }
    }

    return heading;
  }, [getPathNameParts]);

  const onChangeDateRange = (
    value: DateRange,
    value_compare: DateRange | null
  ) => {
    setFilters({
      ...filters,
      start: BiCenterModel.momentToString(value[0]),
      end: BiCenterModel.momentToString(value[1]),
      ...(value_compare
        ? {
            startcompare: BiCenterModel.momentToString(value_compare[0]),
            endcompare: BiCenterModel.momentToString(value_compare[1])
          }
        : { startcompare: "", endcompare: "" })
    });
  };

  const getRange = useCallback((): DateRange => {
    if (filters.start.length > 0 && filters.end.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.start),
        BiCenterModel.stringToMoment(filters.end)
      ];
    } else {
      //default: last30days
      return [
        dayjs().subtract(30, "days").startOf("day"),
        dayjs().subtract(1, "days").endOf("day")
      ];
    }
  }, [filters.start, filters.end]);

  const getRangeCompare = useCallback((): DateRange | null => {
    if (filters.startcompare.length > 0 && filters.endcompare.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.startcompare),
        BiCenterModel.stringToMoment(filters.endcompare)
      ];
    } else {
      //user already set date start by MANUAL,
      //so, do not return default with compare (disable compare)
      if (filters.start.length > 0 && filters.end.length > 0) {
        return null;
      } else {
        //default: previousperiod of last30days
        return [
          dayjs().subtract(60, "days").startOf("day"),
          dayjs().subtract(31, "days").endOf("day")
        ];
      }
    }
  }, [filters.startcompare, filters.endcompare, filters.start, filters.end]);

  const getComponentByPathLocation = useMemo(() => {
    let com = null;
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          com = (
            <ReportCustomerGeneral
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "region":
          com = (
            <ReportCustomerRegion
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "origin":
          com = (
            <ReportCustomerOrigin
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "type":
          com = (
            <ReportCustomerType
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "feed":
          com = (
            <ReportCustomerFeed
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "order":
          com = (
            <ReportCustomerOrder
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "point":
          com = (
            <ReportCustomerPoint
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
      }
    }

    return com;
  }, [getPathNameParts, getRange, getRangeCompare]);

  return (
    <>
      <ReportViewHeader
        heading={getHeadingByPathLocation}
        nav={getNavByPathLocation}
        siteMenuOpenKey={siteMenuOpenKey}
        siteMenuSelectedKey={siteMenuSelectedKey}>
        <ReportDateRangePicker
          range={getRange()}
          range_compare={getRangeCompare()}
          onChange={onChangeDateRange}
          format="DD/MM/YYYY"
        />
      </ReportViewHeader>
      {getComponentByPathLocation}
    </>
  );
};

export default ReportCustomerWrapper;
