import { App, Button, Col, Form, Row, Table, Tooltip } from "antd";
import ProductVariantCollection from "common/collections/ProductVariantCollection";
import ProductVariant from "common/constants/ProductVariant";
import Role from "common/constants/Role";
import ProductVariantModel from "common/models/ProductVariantModel";
import ProductVariantRepository from "common/repositories/ProductVariantRepository";
import { ProductVariantJsonAddEdit } from "common/types/ProductVariant";
import Error from "components/LayoutError";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TextMoney from "components/TextMoney";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import ProductVariantFormTableCell from "features/productvariant/form/ProductVariantFormTableCell";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconPlus } from "@tabler/icons-react";

const ProductVariantList = ({ product_id }: { product_id: number }) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const ADD_OPTION_KEY = 9000000;
  const [showAdd, setShowAdd] = useState(false);
  const [editingKey, setEditingKey] = useState<string | number>("");
  const [loading, setLoading] = useState(false);
  const recordPerPage = 100;

  //fetch ProductVariant
  const [dataSource, setDataSource] = useState<ProductVariantModel[]>([]);

  const fetchProductVariant = useCallback(async () => {
    setLoading(true);
    //fetch all
    let foundItem: ProductVariantModel[] = [];
    let page = 1;
    let fetchedCollection = new ProductVariantCollection();
    do {
      fetchedCollection = await new ProductVariantRepository().getItems({
        filters: {
          product_id,
          page,
          limit: recordPerPage,
          sortby: "id",
          sorttype: "ASC",
          keyword: "",
          status: 0
        }
      });

      if (!fetchedCollection.hasError()) {
        fetchedCollection.items.forEach((item: ProductVariantModel) => {
          foundItem.push(item);
        });
      }
      page++;
    } while (
      !fetchedCollection.hasError() &&
      fetchedCollection.items.length === recordPerPage
    );

    setLoading(false);
    setDataSource(foundItem);
  }, [product_id]);

  useEffect(() => {
    fetchProductVariant();
  }, [fetchProductVariant]);

  const onSaveSuccess = (item: ProductVariantModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item
          }
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $push: [item]
        })
      );
    }
  };

  const [model, setModel] = useState<ProductVariantModel>(
    new ProductVariantModel(ProductVariantModel.getDefaultData())
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const isEditing = (record: ProductVariantJsonAddEdit) =>
    record.key === editingKey;

  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProductVariantJsonAddEdit = {
        ...ProductVariantModel.getDefaultData(),
        company_id: model.company_id,
        id: model.id !== ADD_OPTION_KEY ? model.id : 0,
        product_id: product_id,
        sku: formData.sku,
        title: formData.title,
        color: formData.color,
        size: formData.size,
        weight: formData.weight,
        price: formData.price,
        cost: formData.cost,
        status: formData.status
      };

      return submitData;
    },
    [model, product_id, ADD_OPTION_KEY]
  );

  const initialValues = useMemo(() => {
    return new ProductVariantModel({
      ...ProductVariantModel.getDefaultData(),
      product_id: product_id,
      id: ADD_OPTION_KEY,
      status: ProductVariant.STATUS_ENABLE
    });
  }, [product_id, ADD_OPTION_KEY]);

  const onSubmit = async (formData: any) => {
    const submitData = doPrepareData(formData);

    try {
      setIsProcessing(true);
      message.loading({
        content: t("common:form.processing"),
        key: "message",
        duration: 0
      });

      const item: ProductVariantModel =
        await new ProductVariantRepository().saveRemote(submitData);

      setIsProcessing(false);

      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="productvariant:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4
        });
      } else {
        message.success({
          content: t("productvariant:form.success.update"),
          className: "message_success",
          key: "message",
          duration: 2
        });

        //update success
        setEditingKey("");
        if (showAdd) {
          setShowAdd(false);
        }

        onSaveSuccess(item);
        setModel(new ProductVariantModel(ProductVariantModel.getDefaultData()));
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: t("productvariant:sku"),
      dataIndex: "sku",
      editable: true,
      width: 160,
      inputType: "sku"
    },
    {
      title: t("productvariant:cost"),
      dataIndex: "cost",
      editable: true,
      require: false,
      inputType: "cost",
      align: "right" as "right",
      width: 120,
      render: (cost: number) => {
        return (
          <RoleCheck
            roles={[Role.PRODUCT_VIEW_COST]}
            componentOnFail={
              <div>
                <Tooltip title={t("productvariant:cost_not_permission")}>
                  *******
                </Tooltip>
              </div>
            }>
            <TextMoney money={cost} />
          </RoleCheck>
        );
      }
    },
    {
      title: t("productvariant:price"),
      dataIndex: "price",
      editable: true,
      require: false,
      inputType: "price",
      align: "right" as "right",
      width: 120,
      render: (price: number) => <TextMoney money={price} />
    },
    {
      title: t("productvariant:color_short"),
      dataIndex: "color",
      editable: true,
      require: false,
      inputType: "color",
      render: (color: number) => {
        if (color > 0) {
          return <TextProductVariantColor id={color} />;
        } else {
          return "-";
        }
      }
    },
    {
      title: t("productvariant:size_short"),
      dataIndex: "size",
      editable: true,
      require: false,
      inputType: "size",
      render: (size: number) => {
        if (size > 0) {
          return <TextProductVariantSize id={size} />;
        } else {
          return "-";
        }
      }
    },
    {
      title: t("productvariant:weight"),
      dataIndex: "weight",
      editable: true,
      require: false,
      inputType: "weight",
      width: 120
    },
    {
      title: t("common:status"),
      dataIndex: "status",
      editable: true,
      require: true,
      inputType: "status",
      width: 100,
      render: (status: number) => {
        let statusInfo = ProductVariantModel.getStatus(status);
        return (
          <span
            className={
              status === ProductVariant.STATUS_DISABLED ? "text-red-500" : ""
            }>
            {statusInfo?.label}
          </span>
        );
      }
    },
    {
      title: " ",
      dataIndex: "operation",
      width: 150,
      render: (_: any, record: ProductVariantModel) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <RoleCheck roles={[Role.PRODUCT_MANAGE]}>
              <Button
                onClick={() => cancel()}
                type="text"
                size="small"
                disabled={isProcessing}>
                {t("common:close")}
              </Button>

              <Button
                onClick={() => form.submit()}
                loading={isProcessing}
                style={{ marginRight: 8 }}
                type="primary"
                size="small">
                {t("common:table.save")}
              </Button>
            </RoleCheck>
          </div>
        ) : (
          <span>
            <RoleCheck roles={[Role.PRODUCT_MANAGE]}>
              <>
                <TableEdit link="" onClick={() => edit(record)} />

                <TableDelete
                  error_translate_prefix="product:form.error"
                  onDeleteCallback={(id) => {
                    setDataSource(dataSource.filter((item) => item.id !== id));
                  }}
                  repository={new ProductVariantRepository()}
                  id={record.id}
                />
              </>
            </RoleCheck>

            <TableInfo record={record} />
          </span>
        );
      }
    }
  ];

  const edit = (record: ProductVariantModel) => {
    setModel(record);
    form.setFieldsValue(record);

    setEditingKey(record.key);
  };

  const cancel = () => {
    if (editingKey === ADD_OPTION_KEY) {
      setShowAdd(false);
    }
    setModel(new ProductVariantModel(ProductVariantModel.getDefaultData()));
    setEditingKey("");
  };

  const toggleAddMode = useCallback(() => {
    if (showAdd) {
      setEditingKey("");
      setModel(new ProductVariantModel(ProductVariantModel.getDefaultData()));
    } else {
      setModel(initialValues);
      setEditingKey(ADD_OPTION_KEY);
    }

    setShowAdd(!showAdd);
  }, [ADD_OPTION_KEY, showAdd, initialValues]);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ProductVariantJsonAddEdit) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        require: col.require,
        inputType: col.inputType,
        editing: isEditing(record)
      })
    };
  });

  const mergeDataSource = [
    ...dataSource,
    //append fake model for inline ADD
    ...(model.key === ADD_OPTION_KEY ? [model] : [])
  ];

  useEffect(() => {
    //reset
    form.setFieldsValue({
      ...initialValues,
      size: initialValues.size > 0 ? initialValues.size : null,
      color: initialValues.color > 0 ? initialValues.color : null
    });
  }, [form, initialValues, showAdd]);

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <div
            className="inline-block mb-2 font-bold cursor-pointer hover:underline"
            onClick={() => fetchProductVariant()}>
            {t("product:variant_heading")} (
            <span className="font-bold">{dataSource.length}</span>)
          </div>
        </Col>
        <Col>
          <RoleCheck roles={[Role.PRODUCT_MANAGE]}>
            <Button
              disabled={
                editingKey.toString().length > 0 &&
                editingKey !== ADD_OPTION_KEY
              }
              type="primary"
              size="small"
              onClick={() => toggleAddMode()}
              className=""
              icon={<IconPlus className="mr-1 -mt-0.5" size={14} />}>
              {t("productvariant:form.add")}
            </Button>
          </RoleCheck>
        </Col>
      </Row>

      <Form
        form={form}
        component={false}
        initialValues={initialValues}
        onFinish={onSubmit}>
        <Table
          components={{
            body: {
              cell: ProductVariantFormTableCell
            }
          }}
          size="small"
          dataSource={mergeDataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          bordered
          pagination={{
            hideOnSinglePage: true,
            pageSize: dataSource.length + 1
          }}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default ProductVariantList;
