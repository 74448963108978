import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const CommentFormHeader = ({
	isEditing,
	id,
}: {
	isEditing: boolean;
	id: number;
}) => {
	const { t } = useTranslation();

	return (
		<PageHeader
			heading={
				isEditing
					? t("comment:heading_edit")
					: t("comment:heading_add") + (id > 0 ? "#" + id : "")
			}
			siteMenuSelectedKey="/comment"
			siteMenuHidden></PageHeader>
	);
};

export default CommentFormHeader;
