import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import { WarehouseJson } from "common/types/Warehouse";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DepartmentFormFilter from "features/department/DepartmentFormFilter";
import OfficeFormFilter from "features/office/OfficeFormFilter";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const InventoryProductFilters = <F extends Filter>({
	warehouses,
	total,
	filters,
	setFilters,
	defaultFilters,
}: {
	warehouses: WarehouseJson[];
	total: number;
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
}) => {
	const { t } = useTranslation();

	const optionWarehouse = useMemo(() => {
		const results = warehouses.map((i) => ({ label: i.name, value: i.id }));
		return results;
	}, [warehouses]);
	return (
		<>
			<PageDataFilterForm
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				convertValueByKeyFilter={["warehouse_id", "status", "keyword"]}>
				<FormSelect
					name="warehouse_id"
					placeholder="Chọn kho"
					options={optionWarehouse}></FormSelect>
			</PageDataFilterForm>
		</>
	);
};

export default InventoryProductFilters;
