import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";

import PageHeader from "components/page/PageHeader";

const DocumentFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("document:heading_nav"),
      link: "/documents",
    },
    {
      text: t(isEditing ? "document:heading_edit" : "document:heading_add"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t(isEditing ? "document:heading_edit" : "document:heading_add")}
      nav={nav}
      siteMenuOpenKey="content"
      siteMenuSelectedKey="/documents"
      siteMenuHidden
    ></PageHeader>
  );
};

export default DocumentFormHeader;
