import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { InventoryStockStats } from "common/types/InventoryStock";

class InventoryStockStatsModel
  extends BaseModel
  implements BaseModelJson<InventoryStockStats>
{
  company_id: number;
  es_index: string;
  es_index_is_inited: boolean;
  total_document: number;

  constructor(json: InventoryStockStats) {
    super();

    this.company_id = json.company_id || 0;
    this.es_index = json.es_index || "";
    this.es_index_is_inited = json.es_index_is_inited || false;
    this.total_document = json.total_document || 0;
  }

  static getDefaultData(): InventoryStockStats {
    return {
      company_id: 0,
      es_index: "",
      es_index_is_inited: false,
      total_document: 0,
    };
  }

  toJson(): InventoryStockStats {
    return {
      company_id: this.company_id,
      es_index: this.es_index,
      es_index_is_inited: this.es_index_is_inited,
      total_document: this.total_document,
    };
  }
}

export default InventoryStockStatsModel;
