import { App, Form } from "antd";
import ShippingOrder from "common/constants/ShippingOrder";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import {
  DpFromPlaceSelectOption,
  DpShipperSelectOption
} from "common/types/DeliveryPlanning";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DeliveryPlanningCartSectionAssign = ({
  shippingRouteItems,
  shippingHubItems,
  shipperItems,
  selectedOrder,
  addRouteIdToOrder
}: {
  shippingRouteItems: ShippingRouteModel[];
  shippingHubItems: DpFromPlaceSelectOption[];
  shipperItems: DpShipperSelectOption[];
  selectedOrder: ShippingOrderModel[];
  addRouteIdToOrder: (v: number) => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [loading, setLoading] = useState<boolean>(true);
  const [shippingRouteSelectOption, setShippingRouteSelectOption] = useState<
    SelectOption[]
  >([]);
  //submit data to server
  const onSubmit = async (formData: any) => {
    message.loading({
      content: t("deliveryplanning:form.assign.processing"),
      key: "message",
      duration: 1
    });

    if (checkLatLngOrder()) {
      addRouteIdToOrder(formData.route_id);
    } else {
      message.error({
        content: t(
          "deliveryplanning:form.error.error_exist_order_miss_lat_lng"
        ),
        className: "message_error",
        key: "message",
        duration: 5
      });
    }
  };

  const checkLatLngOrder = () => {
    let pass = true;
    for (let i = 0; i < selectedOrder.length; i++) {
      let orderItem = selectedOrder[i];
      if (
        orderItem.order_shipping_lat <= 0 ||
        orderItem.order_shipping_long <= 0
      ) {
        pass = false;
        break;
      }
    }
    return pass;
  };

  const mappingMoreDataToRoute = useCallback(() => {
    let shippingRouteOptions: SelectOption[] = [];
    shippingRouteItems.forEach((item) => {
      let label = item.code;
      let getShipper = shipperItems.find((i) => i.value === item.shipper_id);
      let getShippingHub = shippingHubItems.find(
        (i) =>
          i.value === item.from_warehouse_id &&
          item.from_type === ShippingOrder.FROM_TYPE_OFFICE
      );

      if (getShippingHub) {
        label += " - " + getShippingHub.label;
      }

      if (getShipper) {
        label += " - " + getShipper.label;
      }

      return shippingRouteOptions.push({
        label: label,
        value: item.id
      });
    });
    setShippingRouteSelectOption(shippingRouteOptions);
    setLoading(false);
  }, [shipperItems, shippingHubItems, shippingRouteItems]);

  useEffect(() => {
    if (loading) {
      mappingMoreDataToRoute();
    }
  }, [loading, mappingMoreDataToRoute]);
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={(formx) => console.log(formx)}
        id="assign-route-form"
        className="px-4 py-2">
        <Form.Item
          label={
            <>
              <div className="block text-white">
                {t("deliveryplanning:cart.choose_route")}
              </div>
            </>
          }
          name="route_id"
          className="mb-0">
          <FormSelect
            options={shippingRouteSelectOption}
            allowClear
            placeholder={t("deliveryplanning:cart.choose_route_placeholder")}
            placement="topRight"
            showSearch
            className="mb-0"
          />
        </Form.Item>
        <div className="block py-2 text-xs italic text-red-300 opacity-60">
          {t("deliveryplanning:cart.choose_route_extra")}
        </div>
      </Form>
    </>
  );
};

export default DeliveryPlanningCartSectionAssign;
