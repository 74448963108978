import File from 'common/constants/File';
import FileModel from 'common/models/FileModel';
import { FileOrigin } from 'common/types/File';
import create, { StateCreator } from 'zustand';

type FileManagerStore = {
  height: number;
  width: number;
  activeObjectType: number;
  activeOrigin: FileOrigin;
  activeFile?: FileModel;
  setWidthHeight: (width: number, height: number) => void;
  setActiveObjectType: (v: number) => void;
  setActiveOrigin: (v: FileOrigin) => void;
  setActiveFile: (v?: FileModel) => void;
};

//init store data
let store: StateCreator<FileManagerStore> = (set) => ({
  height: 0,
  width: 0,
  setWidthHeight: (width, height) =>
    set((state) => ({ ...state, width, height })),
  activeObjectType: File.OBJECTTYPE_FILE,
  setActiveObjectType: (activeObjectType) =>
    set((state) => ({ ...state, activeObjectType })),
  activeOrigin: "company",
  setActiveOrigin: (activeOrigin) =>
    set((state) => ({ ...state, activeOrigin })),
  activeFile: undefined,
  setActiveFile: (activeFile) => set((state) => ({ ...state, activeFile })),
});

//create store
const useFileManagerStore = create(store);

export default useFileManagerStore;
