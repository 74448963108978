import { Button, Tag, Tooltip } from "antd";
import ShippingOrderCollection from "common/collections/ShippingOrderCollection";
import Role from "common/constants/Role";
import ShippingOrder from "common/constants/ShippingOrder";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import ShippingOrderRepository from "common/repositories/ShippingOrderRepository";
import { IdFetcherResult } from "common/types/IdFetcher";
import { FilterShippingOrder } from "common/types/ShippingOrder";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import TextDateTimeYmd from "components/TextDateTimeYmd";
import dayjs from "dayjs";
import ShippingOrderFormModal from "features/shippingorder/form/ShippingOrderFormModal";
import TextEmptyCoord from "features/shippingorder/TextEmptyCoord";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import { xor } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconBox, IconFolder } from "@tabler/icons-react";

import DeliveryPlanningCart from "../cart/DeliveryPlanningCart";
import DeliveryPlanningListFilter from "./DeliveryPlanningListFilter";
import DeliveryPlanningListHeader from "./DeliveryPlanningListHeader";
import DeliveryPlanningListTotal from "./DeliveryPlanningListTotal";

const DeliveryPlanningList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShippingOrder = useMemo(
    () => ShippingOrderRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterShippingOrder>({
    ...defaultFilters,
    status_asigned_route: ShippingOrder.STATUS_UNASSIGNED_ROUTE
  });
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  // modal editing
  const [open, setOpen] = useState<boolean>(false);
  const [editingOrder, setEditingOrder] = useState<ShippingOrderModel>(
    new ShippingOrderModel(ShippingOrderModel.getDefaultData())
  );

  //////////////////////////////////////////
  // call idfetcher
  const [shippingType, setShippingType] = useState<
    IdFetcherResult[] | undefined
  >();
  const [shippingHub, setShippingHub] = useState<
    IdFetcherResult[] | undefined
  >();
  const fetchingShippingHub = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "shippinghub",
      id_list
    });

    let results =
      collection.items.find((i) => i.object_type === "shippinghub")?.result ||
      [];

    results.forEach((result, i) => {
      if (result.text === "[invalid]") {
        results[i].text = "---";
      } else {
        results[i].text = results[i].text.toString();
      }
    });
    setShippingHub(results);
  }, []);
  const fetchingShippingType = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "shippingtype",
      id_list
    });

    let results =
      collection.items.find((i) => i.object_type === "shippingtype")?.result ||
      [];

    results.forEach((result, i) => {
      if (result.text === "[invalid]") {
        results[i].text = "---";
      }
    });
    setShippingType(results);
  }, []);

  const mappingShippingHubIdsToFetcher = useCallback(
    (items: ShippingOrderModel[]) => {
      let shippingHubIdList = items
        .map((item) =>
          item.from_type === ShippingOrder.FROM_TYPE_OFFICE ? item.office_id : 0
        )
        .filter((i) => i > 0 && i !== undefined);
      if (shippingHubIdList.length > 0) {
        fetchingShippingHub(shippingHubIdList);
      }
    },
    [fetchingShippingHub]
  );
  const mappingShippingTypeIdsToFetcher = useCallback(
    (items: ShippingOrderModel[]) => {
      let shippingTypeIdList = items
        .map((item) => item.shipping_type)
        .filter((i) => i > 0 && i !== undefined);
      if (shippingTypeIdList.length > 0) {
        fetchingShippingType(shippingTypeIdList);
      }
    },
    [fetchingShippingType]
  );

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ShippingOrderModel[]>([]);
  const fetchData = useCallback(async (): Promise<ShippingOrderCollection> => {
    let collection = await new ShippingOrderRepository().getItems({
      filters: { ...filters, status: ShippingOrder.STATUS_NEW }
    });
    setDataSource(collection.items);
    setTotal(collection.total);

    // call idfetcher
    mappingShippingHubIdsToFetcher(collection.items);
    mappingShippingTypeIdsToFetcher(collection.items);
    return collection;
  }, [
    filters,
    mappingShippingHubIdsToFetcher,
    mappingShippingTypeIdsToFetcher
  ]);

  //////////////////////////////////////////
  //Selected Table Row
  const [selectedIdList, setSelectedIdList] = useState<number[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<ShippingOrderModel[]>([]);

  const onSelectChange = useCallback(
    (newSelectedRowKeys: React.Key[], selectedRows: ShippingOrderModel[]) => {
      console.log("onSelectChange changed: ", newSelectedRowKeys);
      setSelectedIdList(newSelectedRowKeys.map((k) => Number(k)));
      setSelectedOrder(selectedRows);
    },
    []
  );

  const onRowClick = useCallback(
    (record: ShippingOrderModel) => {
      setSelectedIdList(xor(selectedIdList, [record.id]));
      setSelectedOrder(xor(selectedOrder, [record]));
    },
    [selectedIdList, selectedOrder]
  );

  const onSaveSuccess = (items: ShippingOrderModel[]) => {
    fetchData();
  };

  //////////////////////////////////////////
  //Table columns
  const hourDurationString = (record: ShippingOrderModel) => {
    let hourStart = dayjs(
      Helper.checkTimeString(record.order_shipping_hourstart),
      "HH:mm"
    ).format("HH:mm");
    let hourEnd = dayjs(
      Helper.checkTimeString(record.order_shipping_hourend),
      "HH:mm"
    ).format("HH:mm");
    return hourStart + " - " + hourEnd;
  };
  const onClickEditOrder = (item: ShippingOrderModel) => {
    setOpen(true);
    setEditingOrder(item);
  };

  const columns: TableColumnsType<ShippingOrderModel> = [
    {
      title: t("shippingorder:random_code_short"),
      key: "random_code",
      width: 100,
      render: (_: any, record: ShippingOrderModel) => {
        return record.random_code !== "" ? record.random_code : "---";
      }
    },
    {
      title: t("shippingorder:shipping_time_short"),
      key: "shipping_time_short",
      width: 70,
      render: (_: any, record: ShippingOrderModel) => {
        return (
          <>
            {record.order_shipping_hourstart > 0 &&
            record.order_shipping_hourend > 0
              ? hourDurationString(record)
              : "---"}
          </>
        );
      }
    },
    {
      title: t("shippingorder:ymd"),
      key: "ymd",
      width: 70,
      render: (_: any, record: ShippingOrderModel) =>
        record.ymd > 0 ? (
          <TextDateTimeYmd
            ts={record.ymd}
            format={"YYYYMMDD"}
            dateFormat={"DD/MM"}
          />
        ) : (
          "---"
        )
    },
    {
      title: t("shippingorder:hub"),
      key: "office_id",
      width: 140,
      render: (_: any, record: ShippingOrderModel) => {
        return (
          <>
            {" "}
            <div className="mb-1 font-bold">
              {record.office_id > 0 ? (
                <IdFetcherRender
                  value={record.office_id}
                  mapping={shippingHub}
                />
              ) : (
                "---"
              )}
            </div>
            <div>
              <Tooltip
                title={t("shippingorder:invoice_id")}
                placement="left"
                className="text-xs text-gray-500 hover:text-black"
                mouseLeaveDelay={0.4}>
                <IconBox size={14} className="mr-1 -mt-0.5" />
                {record.invoice_id}
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={t("shippingorder:shipping_type")}
                placement="left"
                className="text-xs text-gray-500 hover:text-black"
                mouseLeaveDelay={0.4}>
                <IconFolder size={14} className="mr-1 -mt-0.5" />
                {record.shipping_type > 0 ? (
                  <IdFetcherRender
                    value={record.shipping_type}
                    mapping={shippingType}
                  />
                ) : (
                  "---"
                )}
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      title: t("shippingorder:route"),
      key: "route_id",
      width: 100,
      render: (_: any, record: ShippingOrderModel) =>
        record.route_id > 0
          ? record.route?.code
          : "[" + t("shippingorder:label_route_empty") + "]"
    },
    {
      title: t("shippingorder:order_shipping_address"),
      key: "order_shipping_address",
      width: 220,
      render: (_: any, record: ShippingOrderModel) => (
        <>
          <div className="mb-1 font-bold">
            {record.order_customer_fullname} ({record.order_customer_phone})
            <div className="float-right">
              {record.cod > 0 ? (
                <Tooltip
                  mouseEnterDelay={0.4}
                  color={"gray"}
                  placement="topLeft"
                  title={
                    t("shippingorder:cod") +
                    ": " +
                    Helper.moneyFormat(record.cod)
                  }>
                  <Tag color={"gray"}>COD</Tag>
                </Tooltip>
              ) : null}
            </div>
          </div>
          <div className="text-xs text-gray-500 hover:text-black">
            {record.order_shipping_address}
          </div>

          {record.order_shipping_lat === 0 ||
          record.order_shipping_long === 0 ? (
            <TextEmptyCoord />
          ) : null}
        </>
      )
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 70,
      render: (ts: number) => {
        return <TextDateTime ts={ts} format="HH:mm, DD/MM" />;
      }
    },
    {
      title: " ",
      toggletype: "trigger",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 40,
      render: (_: any, record: ShippingOrderModel) => (
        <RoleCheck roles={[Role.SHIPPING_ORDER_MANAGE]} hideOnFail>
          <TableEdit
            link={""}
            onClick={() => {
              onClickEditOrder(record);
            }}
          />
        </RoleCheck>
      )
    }
  ];

  const onSaveOrderSuccess = (item: ShippingOrderModel) => {
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current order item
      let newItems = update(dataSource, {
        [foundIndex]: {
          $set: item
        }
      });
      setDataSource(newItems);
      // recall fetcher
      mappingShippingHubIdsToFetcher(newItems);
      mappingShippingTypeIdsToFetcher(newItems);
    } else {
      // add current order item
      setDataSource(update(dataSource, { $unshift: [item] }));
    }
  };

  return (
    <>
      <div>
        <div className="float-right shadow-lg w-80">
          <DeliveryPlanningCart
            idList={selectedIdList}
            setIdList={setSelectedIdList}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            onSaveSuccess={onSaveSuccess}
          />
        </div>
        <div className="mr-80">
          <div>
            <DeliveryPlanningListHeader
              afterHeading={
                <>
                  <DeliveryPlanningListTotal total={total} />
                </>
              }>
              <RoleCheck roles={[Role.SHIPPING_ORDER_MANAGE]}>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                  }}>
                  {t("deliveryplanning:add_order_button")}
                </Button>
              </RoleCheck>
            </DeliveryPlanningListHeader>

            <DeliveryPlanningListFilter
              filters={filters}
              setFilters={setFilters}
              defaultFilters={defaultFilters}
              total={total}
            />

            <PageDataTable<
              FilterShippingOrder,
              ShippingOrderModel,
              ShippingOrderCollection
            >
              {...{
                columns,
                defaultFilters,
                filters,
                setFilters,
                dataSource,
                fetchData,
                tableColumnToggleKey: "shipping-order",
                rowSelection: {
                  type: "checkbox",
                  fixed: "left",
                  selectedRowKeys: selectedIdList,
                  onChange: onSelectChange,
                  preserveSelectedRowKeys: true
                },
                rowClassName: "cursor-pointer",
                onRow: (record) => {
                  return {
                    onClick: (e) => {
                      e.preventDefault();
                      onRowClick(record);
                    }
                  };
                }
              }}></PageDataTable>

            <PageDataPagination
              total={total}
              filters={filters}
              setFilters={setFilters}
              dataSource={dataSource}
            />
          </div>
        </div>
      </div>

      {open ? (
        <ShippingOrderFormModal
          editingOrder={editingOrder}
          key={editingOrder.id}
          open={open}
          setOpen={(isOpen) => {
            //clear when close
            if (!isOpen) {
              setEditingOrder(
                new ShippingOrderModel(ShippingOrderModel.getDefaultData())
              );
            }
            setOpen(isOpen);
          }}
          onSaveSuccess={onSaveOrderSuccess}
        />
      ) : null}
    </>
  );
};

export default DeliveryPlanningList;
