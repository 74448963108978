import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SupplierListHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("supplier:heading_list")}
      siteMenuSelectedKey="/supplier"
      siteMenuOpenKey="product"
    >
      {children}
    </PageHeader>
  );
};

export default SupplierListHeader;
