import { Alert } from "antd";
import CheckinModel from "common/models/CheckinModel";
import TextDateTime from "components/TextDateTime";
import { useTranslation } from "react-i18next";

const CheckinV2PendingItem = ({
  pending,
  isCheckin,
}: {
  pending: CheckinModel;
  isCheckin: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-8">
      <Alert
        showIcon
        type="info"
        message={
          <>
            {isCheckin
              ? t("workcheckin:type_checkin.in")
              : t("workcheckin:type_checkin.out")}
            &nbsp;
            <span className="font-bold">
              [<TextDateTime ts={pending.datecreated} format="HH:mm" />]
            </span>
            &nbsp;
            {isCheckin
              ? t("workcheckin:type_checkin.text_in")
              : t("workcheckin:type_checkin.text_out")}
          </>
        }
      />{" "}
    </div>
  );
};

export default CheckinV2PendingItem;
