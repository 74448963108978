import SettingRepository from "common/repositories/SettingRepository";
import { useCallback, useEffect, useState } from "react";
import { FilterSetting, SettingJson } from "common/types/Setting";
import { message, notification } from "antd";
import { useTranslation } from "react-i18next";
import Helper from "common/utils/helper";

export default function useHandleSetting<T>(
  key: string | number,
  lang: string
) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<T[]>([]);
  const [setting, setSetting] = useState<SettingJson>({} as SettingJson);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  async function reload() {
    await fetchData();
  }
  const fetchData = useCallback(async () => {
    setLoading(true);
    const collection = await new SettingRepository().getItem(key, lang);

    if (!collection.hasError()) {
      setSetting(collection.toJson());
      const convertArray: T[] = collection.value || [];
      setDataSource(convertArray);
    } else {
      setError(collection.error.errors[0]);
    }
    setLoading(false);
  }, [key, lang]);
  async function saveData(data: T[], isReload: boolean = true) {
    setLoading(true);
    const res = await new SettingRepository().saveRemote({
      ...setting,
      value: JSON.stringify(data),
    });
    if (!res.hasError()) {
      await reload();
      message.success("Thao tác thành công");
    } else {
      setLoading(false);
      message.error(t(`shopsetting:error.${res.error.errors[0]}`));
      throw Error(res.error.errors[0]);
    }
    setLoading(false);
  }

  async function saveDisplay(data: T[]) {
    const res = await new SettingRepository().saveRemote({
      ...setting,
      value: JSON.stringify(data),
    });
    if (!res.hasError()) {
      const collection = await new SettingRepository().getItem(key, lang);

      if (!collection.hasError()) {
        setSetting(collection.toJson());
        const convertArray: T[] = collection.value || [];
        setDataSource(convertArray);
      } else {
        throw Error(collection.error.errors[0]);
      }
    } else {
      setLoading(false);
      throw Error(res.error.errors[0]);
    }
  }

  async function addItemSetting(data: T) {
    const res = [data, ...dataSource];
    await saveData(res);
  }
  async function editItemSetting(data: T, indexEdit: number) {
    let res: T[] = [];
    // const index = dataSource.findIndex((item, index) => index == indexEdit)

    if (indexEdit < 0) {
      notification.error({ message: "Có lỗi xảy ra" });
      return;
    }

    if (indexEdit >= 0) {
      const arr = [...dataSource];
      arr.splice(indexEdit, 1, data);

      res = [...arr];
      await saveData(res);
    }
  }
  async function deleteItemSetting(data: T, indexEdit: number) {
    let res: T[] = [];
    // const indexEdit = dataSource.findIndex((item) => item[key] == data[key])
    if (indexEdit >= 0) {
      const arr = [...dataSource];
      arr.splice(indexEdit, 1);

      res = [...arr];
      await saveData(res);
    }
  }

  async function handleDrag(src: number, target: number) {
    const arr = [...dataSource];
    Helper.moveItem<T>(arr, src, target);
    await saveDisplay(arr);
  }

  async function editItemWithId(data: T, indexEdit: number) {
    let newArray = [...dataSource];
    const findItemWithId = dataSource.find(
      (item: any) => item.id === indexEdit
    );
    const findItemIndex = dataSource.findIndex(
      (item: any) => item.id === indexEdit
    );
    if (findItemWithId) {
      newArray.splice(findItemIndex, 1);
      newArray.push(data);
    } else {
      newArray.push(data);
    }

    await saveData(newArray);
  }

  async function deleteItemWithId(data: T, indexEdit: number) {
    let newArray = [...dataSource];

    const findItemIndex = dataSource.findIndex(
      (item: any) => item.id === indexEdit
    );
    if (findItemIndex >= 0) {
      newArray.splice(findItemIndex, 1);
    }

    await saveData(newArray);
  }

  useEffect(() => {
    fetchData();
  }, [fetchData, lang]);

  return {
    dataSource,
    editItemWithId,
    deleteItemWithId,
    loading,
    error,
    setting,
    addItemSetting,
    editItemSetting,
    deleteItemSetting,
    reload,
    handleDrag,
  };
}
