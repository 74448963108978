import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter } from "./Filter";

type FilterTag = Filter & {
  type: number;
  resource_id: number;
  is_root: number;
  resource_type: number;
};

type TagJson = {
  id: number;
  resource_id: number;
  company_id: number;
  creator_id: number;
  name: string;
  code: string;
  value: string;
  type_id: number;
  status: number;
  is_deleted: number;
  date_created: number;
  date_modified: number;
  date_deleted: number;
  type: { name: string; code: string }[];
  resource_type: number;
  is_root: number;
};

type TagAddEditJson = {
  id: number;
  name: string;
  code: string; // nhập nếu rỗng tự sinh
  value: string; // value của tag
  type: number;
  status: number;
  resource_type: number;
  resource_id?: number;
  is_root: number;
};

type TagCollectionJson = BaseCollectionJson<TagJson>;

type TagDisplayUpdateJson = {
  display_order: number;
  tagdetail_id: number;
};

type TagDetailAddJson = {
  details: {
    resource_id: number; // id product
    tag_id: number; // id tag
    resource_type: number;
  }[];
};

type TagRelatedAddJson = {
  tag_code: string;
  relate_type: number;
  type: "product";
  item_id: number; // id của product cha (có thể sau này là blog ,...)
  res_item_ids: number[];
};

type TagProductStructAddEditJson = {
  details: {
    tag_name: string; //truyền tag_name
    tag_value: string; // add với cấu trúc name - id product
    resource_id: number[]; // id product // bắt buộc
    resource_type: number; // 1: product
    type_id: number; // type tag
    status: number; // active: 1 | enable: 2 -- draft | hidden: 3
  }[];
};

export enum TagResourceType{
  PRODUCT =1 ,
  STORY,
  PRODUCT_COLLECTION,
  STORY_COLLECTION,
  BRAND,
}


type DeleteProductRelatedTagJson = {
  tags: string;
  resource_id: string;
  resource_type: TagResourceType;
};

export type {
  TagJson,
  TagAddEditJson,
  FilterTag,
  TagCollectionJson,
  TagDetailAddJson,
  TagDisplayUpdateJson,
  TagProductStructAddEditJson,
  DeleteProductRelatedTagJson,
  TagRelatedAddJson,
};
