import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import React from "react";

import { IconReportMoney, IconShoppingCart } from "@tabler/icons-react";

import ReportChartNumber from "../chart/ReportChartNumber";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportCashflowReceiptIncomeGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số phiếu thu"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="count_id"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              dataSelect="count(id)"
              dataFilter="direction = 5"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Số phiếu chưa thu tiền"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="count_id"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              dataSelect="count(id)"
              dataFilter="direction = 5,status = 0"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Số phiếu đã thu tiền"}
              icon={<IconReportMoney size="18" />}
              keyMapping="count_id"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              dataSelect="count(id)"
              dataFilter="direction = 5,status = 1"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Số phiếu đã hủy"}
              icon={<IconReportMoney size="18" />}
              keyMapping="count_id"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              dataSelect="count(id)"
              dataFilter="direction = 5,status = 2"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng giá trị của phiếu thu"}
              subtitle="Lấy phiếu thu có trạng thái chưa thanh toán và đã thanh toán"
              dataTable="cashflowreceipt"
              dataSelect="sum(value)"
              dataFilter="direction = 5,status < 2"
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "sum_value" }}
              height={250}
              dataService="cashflowreceipt"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng số tiền đã thu"}
              subtitle="Lấy phiếu thu có trạng thái đã thanh toán"
              dataTable="cashflowreceipt"
              dataSelect="sum(value)"
              dataFilter="direction = 5,status = 1"
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "sum_value" }}
              height={250}
              dataService="cashflowreceipt"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng số tiền chưa thu"}
              subtitle="Lấy phiếu thu có trạng thái chưa thanh toán"
              dataTable="cashflowreceipt"
              dataSelect="sum(value)"
              dataFilter="direction = 5,status = 0"
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "sum_value" }}
              height={250}
              dataService="cashflowreceipt"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCashflowReceiptIncomeGeneral;
