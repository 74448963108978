import { Skeleton } from "antd";
import TagModel from "common/models/TagModel";

import TagRepository from "common/repositories/TagRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SettingModel from "common/models/SettingModel";
import SettingRepository from "common/repositories/SettingRepository";
import { SettingGroupType } from "common/types/Setting";
import LanguageForm from "./LanguageForm";

const LanguageSettingModal = ({
  type,
  lang,
  handle_key,
  open,
  setOpen,
  onSaveSuccess,
}: {
  open: boolean;
  handle_key: string;
  type: SettingGroupType;
  lang: string;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: SettingModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<SettingModel>(
    new SettingModel(SettingModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (handle_key: string) => {
    let myObj = await new SettingRepository().getItem(handle_key, "");
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (handle_key.length > 0 && errors.length === 0) {
      fetchDetail(handle_key);
    }
  }, [handle_key, errors]);

  return (
    <FormModal
      title={"Chọn ngôn ngữ cho web shop"}
      width={480}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="shopsetting-form"
    >
      {handle_key.length > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("tag:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(handle_key);
                  }}
                />
              ) : (
                <LanguageForm
                  lang={lang}
                  type={type}
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <LanguageForm
          lang={lang}
          type={type}
          model={new SettingModel(SettingModel.getDefaultData())}
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default LanguageSettingModal;
