const EcomChannelOrder = {
  FULFILLMENT_STATUS_NULL: 1,
  FULFILLMENT_STATUS_PARTIAL: 3,
  FULFILLMENT_STATUS_FULLFILLED: 5,
  FULFILLMENT_STATUS_RESTOCKED: 7,

  FINANCIAL_STATUS_PENDING: 1,
  FINANCIAL_STATUS_AUTHORIZED: 3,
  FINANCIAL_STATUS_PARTIALLY_PAID: 5,
  FINANCIAL_STATUS_PAID: 7,
  FINANCIAL_STATUS_PARTIALLY_REFUND: 9,
  FINANCIAL_STATUS_REFUNDED: 11,
  FINANCIAL_STATUS_VOIDED: 13,
};

export default EcomChannelOrder;
