import TextRegion from 'components/TextRegion';

const TextAddress = ({
  address,
  region_id,
  sub_region_id,
  sub_sub_region_id,
}: {
  address: string;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
}) => {
  const separator = ", ";

  return (
    <span>
      {address}
      {sub_sub_region_id > 0 ? (
        <span>
          {separator}
          <TextRegion id={sub_sub_region_id} />
        </span>
      ) : (
        ""
      )}
      {sub_region_id > 0 ? (
        <span>
          {separator}
          <TextRegion id={sub_region_id} />
        </span>
      ) : (
        ""
      )}
      {region_id > 0 ? (
        <span>
          {separator}
          <TextRegion id={region_id} />
        </span>
      ) : (
        ""
      )}
    </span>
  );
};

export default TextAddress;
