import { Col, Form, Input, InputNumber, Row } from 'antd';
import DmsNewsCategory from 'common/constants/DmsNewsCategory';
import File from 'common/constants/File';
import DmsNewsCategoryModel from 'common/models/DmsNewsCategoryModel';
import FileModel from 'common/models/FileModel';
import DmsNewsCategoryRepository from 'common/repositories/DmsNewsCategoryRepository';
import FormSection from 'components/form/FormSection';
import FormSectionSeo from 'components/form/FormSectionSeo';
import FormSelect from 'components/form/FormSelect';
import LayoutForm from 'components/form/LayoutForm';
import DmsNewsCategoryEditorHeader from 'features/dmsnewscategory/form/DmsNewsCategoryFormHeader';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DmsNewsCategoryFormSelect from '../DmsNewsCategoryFormSelect';

import type { DmsNewsCategoryJsonAddEdit } from "common/types/DmsNewsCategory";
const DmsNewsCategoryEditor = ({ model }: { model: DmsNewsCategoryModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  // modal editing

  const initialValues = {
    name: model.name,
    parent_id: model.parent_id === 0 ? null : model.parent_id,
    display_order: model.display_order,
    status: model.status || DmsNewsCategory.STATUS_ENABLE,
    seo_slug: model.seo_slug,
    seo_title: model.seo_title,
    seo_keyword: model.seo_keyword,
    seo_description: model.seo_description,
    graph_image_id: model.graph_image_id,
    graph_image_input: {
      files: FileModel.convertToUploadFiles([model.graph_image]),
      file_id_list: model.graph_image_id > 0 ? [model.graph_image_id] : [],
    },
  };
  console.log("model: ", model);
  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      console.log(formData);
      const submitData: DmsNewsCategoryJsonAddEdit = {
        ...DmsNewsCategoryModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        parent_id: formData.parent_id,
        display_order: formData.display_order || 0,
        status: formData.status || DmsNewsCategory.STATUS_ENABLE,
        seo_slug: formData.seo_slug,
        seo_title: formData.seo_title,
        seo_keyword: formData.seo_keyword,
        seo_description: formData.seo_description,
        graph_image_id: formData.graph_image_input.file_id_list?.[0],
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: DmsNewsCategoryModel =
      await new DmsNewsCategoryRepository().saveRemote(doPrepareData(formData));
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={DmsNewsCategoryModel.getStatusList()}
      />
    </div>
  );

  return (
    <>
      <DmsNewsCategoryEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("dmsnewscategory:form.success.update")
            : t("dmsnewscategory:form.success.add")
        }
        redirectOnSuccess="/dmsnewscategory"
        error_translate_prefix="dmsnewscategory:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("dmsnewscategory:form.section_title")}
          subtitle={t("dmsnewscategory:form.section_description")}
          divider
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("dmsnewscategory:name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t(
                      "dmsnewscategory:form.error.error_name_required"
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={16} xs={24}>
              <DmsNewsCategoryFormSelect
                label={t("dmsnewscategory:parent_id")}
                placeholder={t("dmsnewscategory:form.parent_placeholder")}
                name="parent_id"
                parentId={0}
                ignoreValues={model.id > 0 ? [model.id] : []}
              />
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                hidden={model.id === 0}
                name="display_order"
                label={t("common:display_order_long")}
                tooltip={t("common:display_order_help")}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSectionSeo
          fileObjectType={File.OBJECTTYPE_ATTACHMENT}
          fileOrigin="dmsportal"
        />
      </LayoutForm>
    </>
  );
};

export default DmsNewsCategoryEditor;
