import { useCallback, useMemo, useState } from "react";
import { Col, Dropdown, MenuProps, Row, Typography, message } from "antd";

import { MoreOutlined } from "@ant-design/icons";

import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
//Phần thay thế

import LeaveTypeCollection from "common/collections/LeaveTypeCollection";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";
import { FilterLeaveType } from "common/types/LeaveType";
import { TableColumnsType } from "common/types/Table";

import TextDateTime from "components/TextDateTime";
import PageHeaderButton from "components/page/PageHeaderButton";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";

import LeaveTypeFormModal from "../form/LeaveTypeFormModal";
import LeaveTypeListHeader from "./LeaveTypeListHeader";

const LeaveTypeList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  ////////////////////////
  // default filter
  const defaultFilters = useMemo(() => {
    return LeaveTypeRepository.getDefaultFilters();
  }, []);
  ////////////////////////
  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<LeaveTypeModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  // modal editing
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<number>(0);

  useFilterLocation(defaultFilters, filters);

  //Table columns
  const columns: TableColumnsType<LeaveTypeModel> = [
    {
      title: t("common:sid"),
      key: "id",
      width: 60,
    },
    {
      title: t("leavetype:list.columns.name"),
      key: "name",
      // sortby: "name",
      width: 200,
    },

    {
      title: t("leavetype:list.columns.salary_type"),
      key: "salary_type",
      width: 120,
      render: (salary_type) => {
        return (
          <Typography.Text>
            {t(`leavetype:salary_type.${salary_type}`)}
          </Typography.Text>
        );
      },
    },
    {
      title: t("leavetype:list.columns.summax"),
      key: "summax",
      width: 120,
    },
    {
      title: t("leavetype:list.columns.date_created"),
      key: "date_created",
      width: 120,
      render(value, record) {
        return (
          <TextDateTime ts={record.date_created} format="HH:mm, DD/MM/YYYY" />
        );
      },
    },
    {
      title: "",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 50,
      render: (_: any, record: LeaveTypeModel) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: <TableEdit link="" onClick={() => onEdit(record.id)} />,
          },
          {
            key: "2",
            label: (
              <TableDelete
                error_translate_prefix="leave:form.error"
                onDeleteCallback={(id) => {
                  setDataSource(dataSource.filter((item) => item.id !== id));
                }}
                repository={new LeaveTypeRepository()}
                label={t("common:table.delete")}
                id={record.id}
              />
            ),
          },
        ];
        return (
          <div className="flex items-center justify-end">
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              className="cursor-pointer"
            >
              <MoreOutlined
                style={{ fontSize: "16pt" }}
                className="cursor-pointer "
              />
            </Dropdown>
            <TableInfo record={record} />
          </div>
        );
      },
    },
  ];

  ///////////////////////
  //function
  const fetchData = useCallback(async (): Promise<LeaveTypeCollection> => {
    const response = await new LeaveTypeRepository().getListLeaveType({
      filters: filters,
    });
    if (response) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      message.error("common:error.error_fetching_data");
    }

    return response;
  }, [filters]);

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: LeaveTypeModel) => {
    //detech this is NEW or UPDATE
    fetchData();
  };

  return (
    <div>
      <LeaveTypeListHeader>
        <Row gutter={16} className="text-right">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <PageHeaderButton
              link=""
              onClick={() => {
                setEditingId(0);
                setAddVisible(true);
              }}
            >
              {t("template:add_button")}
            </PageHeaderButton>
          </Col>
        </Row>
      </LeaveTypeListHeader>

      <PageDataTable<FilterLeaveType, LeaveTypeModel, LeaveTypeCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "leavetype",
        }}
      ></PageDataTable>

      <LeaveTypeFormModal
        id={editingId}
        key={editingId}
        visible={addVisible}
        setVisible={(isVisible) => {
          //clear editing id when close
          if (!isVisible) {
            setEditingId(0);
          }
          setAddVisible(isVisible);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </div>
  );
};

export default LeaveTypeList;
