import React, { useCallback, useEffect, useMemo, useState } from "react";

import LayoutForm from "components/form/LayoutForm";
import { useForm } from "antd/lib/form/Form";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  StepProps,
  Steps,
  Typography,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import FormSection from "components/form/FormSection";

//////////////////////////////////
//Phần thay thế
import LeaveApprovalFormHeader from "./LeaveApprovalFormHeader";

import { EmployeeJson } from "common/types/Employee";
import { LeaveJson } from "common/types/Leave";
import { LeaveStepJson } from "common/types/LeaveStep";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import EmployeeModel from "common/models/EmployeeModel";
import DepartmentRepository from "common/repositories/DepartmentRepository";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveModel from "common/models/LeaveModel";
import LeaveStepRepository from "common/repositories/LeaveStepRepository";
import ErrorAlert from "components/ErrorAlert";
import LeaveRepository from "common/repositories/LeaveRepository";
import LeaveApproveRepository from "common/repositories/LeaveApproveRepository";
import TextDateTime from "components/TextDateTime";
import LeaveApprovalhistoryList from "../list/LeaveApprovalhistoryList";

type Props = {
  model: LeaveModel;
};
type FieldRender = {
  label: string;
  value: string | number | React.ReactNode;
};
type OptionApprove = {
  label: string;
  value: string;
  disabled: boolean;
};
type FormData = {
  status: string[];
  note: string;
  type: number;
};

type NoteProps = {
  label: string;
  name: string;
  required: boolean;
  disabled: boolean;
};
const LeaveApprovalForm = ({ model: modelInit }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { Text } = Typography;
  const CheckboxGroup = Checkbox.Group;
  /////////////////////////////////
  //state
  const [isSuccess, setIsSucess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [errorsStep, setErrorsStep] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  //
  const [model, setModel] = useState(modelInit);

  const [employee, setEmployee] = useState(EmployeeModel.getDefaultData());
  const [leaveTypes, setLeaveTypes] = useState(LeaveTypeModel.getDefaultData());
  //
  const [steps, setSteps] = useState<LeaveStepJson[]>([]);
  const [stepAprove, setStepApprove] = useState<LeaveStepJson[]>([]);
  console.log(
    "🚀 ~ file: LeaveApprovalForm.tsx:91 ~ LeaveApprovalForm ~ stepAprove:",
    stepAprove
  );
  const [stepChecked, setStepChecked] = useState<CheckboxValueType[]>([]);
  const [type, setType] = useState(1);

  // const [stepApproves, setStepApproves] = useState([]);

  const [fieldEmployeeList, setFieldEmployeeList] = useState<FieldRender[]>([]);
  const [optionApprove, setOptionApprove] = useState<OptionApprove[]>([]);

  /////////////////////////////////
  //default data
  const initialValues = {
    type: 1,
  };
  /////////////////////////////////
  //prepare data for submit
  const doPrepareData = useCallback((formData: any) => {
    const submitData: any = {
      ...formData,
    };

    return submitData;
  }, []);

  /////////////////////////////////
  //call api
  const getDetailLeave = useCallback(async () => {
    const response = await new LeaveRepository().getItem(model.id);
    if (response.hasError()) {
      setErrors(response.error.errors);
    } else {
      setModel(response);
    }
  }, [model.id]);

  const getDetailEmployee = async () => {
    let dataRender: FieldRender[] = [];
    const keyEmployeeRenders: (keyof EmployeeJson)[] = [
      "full_name",
      "internal_id",
      "department_id",
      "job_title",
    ];

    const response = await new EmployeeRepository().getItem(model.employee_id);
    if (!response.hasError()) {
      setEmployee(response.toJson());

      const departmentResponse = await new DepartmentRepository().getItem(
        response.department_id
      );
      if (!departmentResponse.hasError()) {
        keyEmployeeRenders.forEach((key) => {
          if (response.hasOwnProperty(key)) {
            dataRender.push({
              label: t(`leaveapprove:approval.form.${key}`),
              value:
                key === "department_id"
                  ? departmentResponse.name
                  : response[key],
            });
          }
        });
        setFieldEmployeeList(dataRender);
      } else {
        setErrors(response.error.errors);
      }
    } else {
      setErrors(response.error.errors);
    }
  };

  const getDetailLeaveType = async () => {
    const response = await new LeaveTypeRepository().getItem(
      model.leavetypes_id
    );
    if (!response.hasError()) {
      setLeaveTypes(response.toJson());
    } else {
      setErrors(response.error.errors);
    }
  };

  const getStepApproval = async () => {
    const response = await new LeaveStepRepository().getItem(model.id);
    if (!response.hasError()) {
      let option: OptionApprove[] = [];
      setStepApprove(response.items.map((step) => step.toJson()));
      response.items.forEach((item: LeaveStepJson) => {
        option.push({
          label: t(`leaveapprove:approval.form.steps.${item.approve_name}`),
          value: JSON.stringify(item.steps),
          disabled: item.approve,
        });
      });
      setOptionApprove(option);
    } else {
      setErrorsStep(response.error.errors);
    }
  };

  // async function getListApprovalHistory() {
  //   const filter = LeaveApproveRepository.getDefaultFilter();
  //   const response = await new LeaveApproveRepository().getItemsApprovalHistory(
  //     { ...filter, leavetype: leaveTypes.id }
  //   );
  //   if (!response.hasError()) {
  //     console.log(response.items);
  //   } else {
  //     // setErrors(response.error.errors.);
  //   }
  // }

  const getAllStepApprove = async () => {
    const response = await new LeaveStepRepository().getItems();
    if (!response.hasError()) {
      setSteps(response.items);
    } else {
      // setErrors(response.error.errors.);
    }
  };

  // call init
  const handleProcessApprove = async () => {
    setLoading(true);
    await getDetailEmployee();
    await getDetailLeaveType();
    await getStepApproval();
    await getAllStepApprove();
    // await getListApprovalHistory();
    setLoading(false);
  };

  /////////////////////////////////
  // event
  //submit data to server
  const onSubmit = async (formData: FormData) => {
    const prepareData = doPrepareData(formData);
    const putStepPromise: Promise<LeaveModel>[] = [];

    const statusList = formData.status.filter(
      (st) => !stepApproved.includes(st)
    );

    for (const step of statusList) {
      putStepPromise.push(
        new Promise(async (resolve) => {
          const res = await new LeaveApproveRepository().editStatus({
            id: model.id,
            note: prepareData[`note_${step}`],
            status: Number(step),
            type: formData.type,
          });
          resolve(res);
        })
      );
    }
    Promise.all(putStepPromise)
      .then((response) => {
        if (response.some((e) => e.hasError())) {
          let errors: string[] = [];
          response
            .filter((res) => res.hasError())
            .forEach((r, index) => {
              if (!errors.includes(r.error.errors[0])) {
                errors.push(...r.error.errors);
              }
            });
          setOptionApprove((prev: OptionApprove[]) =>
            prev.map((i: OptionApprove) => {
              return {
                ...i,
                disabled: true,
              };
            })
          );
          setErrors(errors);
        } else {
          getDetailLeave();
          getStepApproval();
          getAllStepApprove();
          setIsSucess(true);
        }
      })
      .catch((error) => {
        setErrors([JSON.stringify(error)]);
      })
      .finally(() => {
        // getStepApproval();
      });
  };

  const handleSumitForm = (type: "dened" | "approve") => {
    if (type === "dened") {
      setType(0);
      form.setFieldValue("type", 0);
    } else {
      form.setFieldValue("type", 1);
      setType(1);
    }

    form.submit();
  };

  const handleStepChange = (value: CheckboxValueType[]) => {
    setStepChecked((prev) => [...value]);
  };
  /////////////////////////////////
  //process variable
  const isDisableOptions = useMemo(() => {
    let statusInstance = [2, 3, 4];
    if (statusInstance.includes(model.status)) {
      return false;
    } else {
      return true;
    }
  }, [model]);

  //disalbe button submit form
  const isDisalbeSubmit = useMemo(() => {
    let statusInstance = [2, 3, 4];
    let isDisable = false;

    if (
      optionApprove.length === 0 ||
      // model.status <= 1 ||
      optionApprove.every((opt) => opt.disabled === true) ||
      !statusInstance.includes(model.status)
    ) {
      isDisable = true;
    }
    return isDisable;
  }, [optionApprove]);

  //step disable list
  const stepApproved = useMemo(() => {
    let steps: string[] = [];
    if (optionApprove) {
      steps = optionApprove
        .filter((opt) => opt.disabled === true)
        .map((i: OptionApprove) => i.value);
    }
    return steps;
  }, [optionApprove]);

  /////////////////////////////////
  //node list
  const fieldLeaveList: FieldRender[] = useMemo(() => {
    let dataRender: FieldRender[] = [];
    const keyLeaveRenders: (keyof LeaveJson)[] = [
      "date_from",
      "date_to",
      "dayoff",
      "leavetypes_id",
      "note",
    ];
    if (model.id > 0) {
      keyLeaveRenders.forEach((key) => {
        if (model.hasOwnProperty(key)) {
          dataRender.push({
            label: t(`leaveapprove:approval.form.${key}`),
            value:
              key === "leavetypes_id" ? (
                leaveTypes.name
              ) : key === "date_from" || key === "date_to" ? (
                <TextDateTime
                  ts={model[key]}
                  format="DD/MM/YYYY"
                ></TextDateTime>
              ) : (
                model[key]
              ),
          });
        }
      });

      dataRender.push({
        label: t(`leaveapprove:approval.form.salary_type`),
        value: t(`leaveapprove:${leaveTypes.salary_type}`),
      });
    }
    return dataRender;
  }, [leaveTypes, model]);

  const fieldNoteList: NoteProps[] = useMemo(() => {
    let noteList: NoteProps[] = [];
    noteList = optionApprove.map((opt: OptionApprove, index) => {
      return {
        label: opt.label,
        name: `note_${opt.value}`,
        required: type === 0 && stepChecked.includes(opt.value),
        disabled: model.status === 0 ? true : opt.disabled,
      };
    });
    return noteList;
  }, [optionApprove, stepChecked, form, type]);

  const stepsList: StepProps[] = useMemo(() => {
    let stepList: StepProps[] = [];
    if (steps.length > 1 && optionApprove.length > 0) {
      steps
        .filter((i) => ![1, 0].includes(i.steps))
        .toSorted((a, b) => {
          const item1 = optionApprove.find((i) => Number(i.value) === a.steps);
          const item2 = optionApprove.find((i) => Number(i.value) === b.steps);
          if (item1 && item2) {
            if (item1.value === item2.value) {
              return 0;
            } else {
              if (item1.disabled) {
                return -1;
              } else {
                return 1;
              }
            }
          } else {
            return 0;
          }
        })
        .forEach((step) => {
          let status: "wait" | "error" | "finish" | "process" = "wait";
          const findApproveType = stepAprove.find(
            (i) => i.steps === step.steps
          )?.approve_type;

          if (typeof findApproveType !== "undefined") {
            switch (findApproveType) {
              case 0:
                status = "error";
                break;
              case 1:
                status = "finish";
                break;

              default:
                status = "process";
                break;
            }
          }
          if (model.status === 1) {
            status = "finish";
          }

          if (
            status === "process" &&
            stepAprove.some((i) => i.approve_type === 0)
          ) {
            status = "wait";
          }
          stepList.push({
            title: t(`leaveapprove:approval.form.steps.${step.approve_name}`),
            status: status,
            description: t(`leaveapprove:approval.status.${status}`),
          });
        });
    }
    if (model.status === 1) {
      stepList.push({
        title: t(`leaveapprove:approval.done`),
        status: "finish",
      });
    } else if (model.status === 0) {
      stepList.push({
        title: t(`leaveapprove:approval.dened`),
        status: "error",
      });
    }
    return stepList;
  }, [steps, optionApprove, model, stepAprove]);

  //current step approve
  const currentStep = useMemo(() => {
    if (stepsList.length === 0 || model.status === 2) {
      return 0;
    }

    if ([0, 1].includes(model.status) && stepsList.length > 0) {
      return stepsList.length;
    } else {
      return (
        steps
          .filter((i) => ![0, 1].includes(i.steps))
          .findIndex((i) => i.steps === model.status) || 0
      );
    }
  }, [model.status, stepApproved, stepsList]);

  /////////////////////////////////
  //sidebar form
  const sidebarItems = (
    <div>
      {errorsStep.length > 0 ? (
        <ErrorAlert items={errors} translate_prefix="leaveapprove:form.error" />
      ) : (
        <>
          {/* options approve */}
          <Row>
            <Col span={24}>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: t(
                      "leaveapprove:approval.form.error.require_step_approve"
                    ),
                  },
                ]}
              >
                <CheckboxGroup
                  options={optionApprove.filter((i) => i.value !== "0")}
                  disabled={isDisableOptions}
                  onChange={(checkedValue) => handleStepChange(checkedValue)}
                  className="gap-1"
                  // onChange={(e: CheckboxValueType[]) => handleStatusChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider type="horizontal" />
          {/* acction approve  */}
          <Button
            size="large"
            block
            htmlType="button"
            type="primary"
            className="mt-2"
            onClick={() => {
              handleSumitForm("approve");
            }}
            disabled={isDisalbeSubmit}
          >
            {t("leaveapprove:approval.form.button_approve")}
          </Button>
          <Popconfirm
            title="Bạn chắc chắn từ chối ?"
            align={{}}
            placement="bottom"
            okButtonProps={{ loading: loading }}
            onConfirm={() => {
              handleSumitForm("dened");
            }}
          >
            <Button
              size="large"
              block
              htmlType="button"
              className="mt-2"
              onClick={() => {
                form.validateFields();
              }}
              disabled={isDisalbeSubmit}
            >
              {t("leaveapprove:approval.form.button_cancel")}
            </Button>
          </Popconfirm>
        </>
      )}
    </div>
  );
  //record item
  const fieldRender = ({ label, value }: FieldRender) => {
    return (
      <Col key={label} span={24} md={12}>
        <div className="flex justify-start gap-1 items-center p-1">
          <Text strong type="warning" className="whitespace-nowrap">
            {label}:
          </Text>
          <Text ellipsis={{ tooltip: <>{value}</> }}>{value}</Text>
        </div>
        <Divider style={{ margin: "4px 0 0 0" }} />
      </Col>
    );
  };
  /////////////////////////////////
  useEffect(() => {
    if (model.employee_id > 0) {
      handleProcessApprove();
    }
  }, []);

  useEffect(() => {
    if (stepApproved.length < optionApprove.length && isSuccess === true) {
      setIsSucess(false);
    }
  }, [stepApproved, optionApprove]);

  return (
    <Spin spinning={loading} tip={<>{t("common:loading")}</>}>
      <LeaveApprovalFormHeader isEditing={true} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        error_heading={t("leaveapprove:approval.form.error.heading")}
        successTitle={t("leaveapprove:approval.form.success.update")}
        error_translate_prefix="leaveapprove:approval.form.error"
        onSubmit={onSubmit}
        submitText={t("common:form.save")}
        sidebarItems={sidebarItems}
        hideFormSubmit={true}
      >
        <FormSection
          title={t("leaveapprove:approval.form.section_step")}
          subtitle={t("leaveapprove:approval.form.section_step_des_pending")}
          divider
        >
          <Space className="block">
            <Steps
              current={currentStep}
              size="small"
              responsive={true}
              items={stepsList}
              status={model.status === 0 ? "error" : "finish"}
            />
          </Space>
        </FormSection>

        <FormSection
          title={t("leaveapprove:approval.form.section_employee")}
          subtitle={t("leaveapprove:approval.form.section_employee_des")}
          divider
        >
          <Space direction="horizontal" className="block">
            <Row gutter={[4, 4]}>
              {fieldEmployeeList.map((i) =>
                fieldRender({ label: i.label, value: i.value })
              )}
            </Row>
            <Row gutter={[16, 4]}>
              {fieldLeaveList.map((i) =>
                fieldRender({ label: i.label, value: i.value })
              )}
            </Row>
          </Space>
        </FormSection>

        <FormSection
          title={t("leaveapprove:approval.form.section_note")}
          subtitle={t("leaveapprove:approval.form.section_note_des")}
          anotherSubtitle={t(
            "leaveapprove:approval.form.section_another_note_des"
          )}
          divider
        >
          <Row gutter={[4, 4]}>
            {fieldNoteList.map((opt: NoteProps, index) => {
              return (
                <Col key={index} span={24}>
                  <FormItem
                    validateFirst={true}
                    name={opt.name}
                    label={
                      <>
                        {t("leaveapprove:approval.form.note")}
                        <Text className="mx-2" type="warning">
                          ({opt.label})
                        </Text>
                      </>
                    }
                    rules={[
                      {
                        required: opt.required,
                        message: t("leaveapprove:approval.form.note_required"),
                      },
                    ]}
                  >
                    <TextArea disabled={opt.disabled} rows={2} />
                  </FormItem>
                </Col>
              );
            })}
          </Row>
          <Form.Item name="type" hidden>
            <Input></Input>
          </Form.Item>
        </FormSection>

        <FormSection
          title={t("Lịch sử duyệt phép")}
          // subtitle={t("leaveapprove:approval.form.section_step_des_pending")}
          // divider
        >
          <LeaveApprovalhistoryList leave_id={model.id} />
        </FormSection>
      </LayoutForm>
    </Spin>
  );
};

export default LeaveApprovalForm;
