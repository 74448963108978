const Customer = {
  GENDER_FEMALE: 1,
  GENDER_MALE: 3,
  GENDER_OTHER: 5,
  GENDER_UNKNOWN: 7,


  STATUS_UNACTIVATED: 0,
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,
  STATUS_DELETED: 5,
  STATUS_ALREADY_CREATED: 6,
};

export default Customer;
