const Collection = {
  TYPE_STORY: 1,
  TYPE_PRODUCT: 2,
  TYPE_IMAGE: 3,
  TYPE_CUSTOMER: 4,
  TYPE_ACADEMY: 5,
  TYPE_AI_NTEAM: 6,
  CONTENT_TYPE: {
    CONTENT_IMAGE: 1,
    CONTENT_VIDEO: 2,
    CONTENT_AUDIO: 3,
  },
  TYPE_FILTER: {
    TYPE_STORY: 1,
    TYPE_ACADEMY: 2,
    TYPE_AI_NTEAM: 3,
    TYPE_WEBPAGE: 4,
  },
};

export default Collection;
