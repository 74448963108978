import ProductModel from "common/models/ProductModel";
import ProductVariantList from "features/productvariant/list/ProductVariantList";
import React from "react";

const ProductItemExpand = ({ record }: { record: ProductModel }) => {
  return (
    <div className="pb-8" style={{ marginLeft: 42 }}>
      <div>
        <ProductVariantList product_id={record.id} />
      </div>
    </div>
  );
};

export default ProductItemExpand;
