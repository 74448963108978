import { IconAB2, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import {
  Button,
  Checkbox,
  Divider,
  List,
  Skeleton,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import StoreCollection from "common/collections/StoreCollection";
import TagConstant from "common/constants/Tag";
import StoreModel from "common/models/StoreModel";
import TagModel from "common/models/TagModel";
import ProductRepository from "common/repositories/ProductRepository";
import StoreRepository from "common/repositories/StoreRepository";
import TagRepository from "common/repositories/TagRepository";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { FilterStore } from "common/types/Store";
import PageDataPagination from "components/page/PageDataPagination";
import useDatabaseTable from "hooks/useDatabaseTable";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import SettingModel from "common/models/SettingModel";
import SettingRepository from "common/repositories/SettingRepository";
import SettingApi from "common/constants/Setting";
import AddListToItemModal from "features/productcollection/form/children/AddListToItemModal";
import AddListToItemFilter from "features/productcollection/form/children/AddListToItemFilter";
import ProductModel from "common/models/ProductModel";
import { ProductStructJson } from "common/types/Product";
import { SelectOptionNormal } from "common/types/SelectOption";
import ProductRelatedAddTagModal from "./ProductRelatedAddTagModal";
import ConfirmDeleteProductStruct from "./ConfirmDeleteProductStruct";

const PRODUCT_STRUCT = "PRODUCT STRUCT";

type TabItemType = {
  label: string;
  children: ReactNode;
  key: string;
  closable?: boolean;
};

function removeItemDuplicate(arr: SelectOptionNormal[]) {
  let uniqueArray = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    let foundDuplicate = false;
    for (let j = i - 1; j >= 0; j--) {
      if (arr[i].value === arr[j].value) {
        foundDuplicate = true;
        break;
      }
    }
    if (!foundDuplicate) {
      uniqueArray.unshift(arr[i]);
    }
  }
  return uniqueArray;
}

const ProductRelatedComponent = ({
  type,
  id,
  activeTag,
  productDetail,
  tags,
  existValues,
  onSaveSuccess,
  renderItems,
  handleAfterAddSuccess,
  handleRemoveItem,
  reload,
  reloadListTag,
  children,
  changeExistValue,
  handleAddSkuSuccess,
  handleRemoveCurrentItem,
  handleRemoveTabItem,
  onAddTagStructSuccess,
  changeActiveTagValue,
  onSaveReverted,
}: {
  type:
    | "product"
    | "tagproduct"
    | "tagproductcollection"
    | "tagstorycollection"
    | "tagstory"
    | "productintag"
    | "productrelated";
  id: number;
  activeTag: TagModel;
  productDetail: ProductModel;
  existValues: number[];
  onSaveSuccess: any;
  renderItems: any;
  handleRemoveItem: any;
  handleAfterAddSuccess?: any;
  reload: boolean;
  reloadListTag: boolean;
  children?: React.ReactNode;
  changeExistValue?: any;
  handleAddSkuSuccess?: any;
  onAddTagStructSuccess?: any;
  handleRemoveCurrentItem?: any;
  handleRemoveTabItem?: any;
  onSaveReverted?: any;
  changeActiveTagValue: (t: TagModel) => void;
  tags?: string;
}) => {
  console.log("🥺 ~ existValues:", existValues);
  const [categoryItems] =
    useDatabaseTable<ProductCategoryJson>("productcategory");
  const defaultFilters: any = useMemo(() => {
    return (
      type === "productrelated" && {
        ...ProductRepository.getDefaultFilters(),
        limit: 50,
      }
    );
  }, [existValues]);

  //state
  const [mode, setMode] = useState<"delete" | "revert">("delete");
  const [open, setOpen] = useState<boolean>(false);
  const [dataSource, setDataSouce] = useState<any[]>([]);
  console.log("🥺 ~ setDataSouce:", dataSource);
  const [filters, setFilters] = useState(defaultFilters);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [btnTitle, setBtnTitle] = useState<string>("Gắn sản phẩm mới");
  const [selectedValue, setSelectedValue] = useState<SelectOptionNormal[]>([]);
  const [afterRevertArray, setAfterRevertArray] = useState<
    SelectOptionNormal[]
  >([]);
  //tab
  const [tabItems, setTabItems] = useState<TabItemType[]>([]);

  const [listTag, setListTag] = useState<TagModel[]>([]);
  const [addTagProductStructVisible, setAddTagProductStructVisible] =
    useState<boolean>(false);
  const [addTagPopupId, setAddTagPopupId] = useState<number>(-1);
  const [btnDeleteLoading, setBtnDeleteLoading] = useState<boolean>(false);
  const [btnRevertedLoading, setBtnRevertedLoading] = useState<boolean>(false);

  //confirm
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  //fetch store list
  const defaultFiltersStore: FilterStore = useMemo(
    () => StoreRepository.getDefaultFilters(),
    []
  );
  const [filtersStore, setFiltersStore] =
    useState<FilterStore>(defaultFiltersStore);
  const [storeList, setStoreList] = useState<StoreModel[]>([]);
  const [storeValue, setStoreValue] = useState<SettingModel>(
    new SettingModel(SettingModel.getDefaultData())
  );

  //fetchDataStore
  const fetchDataStoreId = useCallback(async (): Promise<SettingModel> => {
    let collection = await new SettingRepository().getItem(
      SettingApi.KEY.k_setting_id_store_web_shop, ""
    );
    if (!collection.hasError()) {
      const convertId = JSON.parse(collection.value);
      setStoreList([
        new StoreModel({ ...StoreModel.getDefaultData(), id: convertId }),
      ]);
    }
    return collection;
  }, [filtersStore]);

  const fetchDataOptions = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (type === "productrelated") {
        if (storeList.length > 0) {
          const collection = await new ProductRepository().getItems({
            filters: {
              ...filters,
              store_id: storeList[0].id,
              tags: activeTag.code + "-" + id,
            },
          });
          if (collection.hasError()) {
            setError(collection.error.errors[0]);
          } else {
            changeExistValue && changeExistValue(collection.items);
            const convertArray: any[] = collection.items.map((item) => {
              return { ...item, id: item.id + "" };
            });
            setDataSouce([...convertArray]);
            setTotal(collection.total);
          }
        } else {
          await fetchDataStoreId();
        }
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [filters, existValues, storeList, activeTag]);

  useEffect(() => {
    if (activeTag.code.length > 0) {
      if (type === "productrelated") {
        if (storeList.length === 0) {
          fetchDataStoreId();
        } else {
          fetchDataOptions();
        }
      }
    }
  }, [filters, reload, storeList, activeTag]);

  const renderTabItems = async (productDetail: ProductModel) => {
    setLoading(true);
    const listTagStruct = await new TagRepository().getStructItems({
      filters: {
        ...TagRepository.getDefaultFilters(),
        // resource_id: productDetail.id,
        type: TagConstant.TYPE.PRODUCT_STRUCT,
      },
    });
    if (listTagStruct.length > 0) {
      const PRODUCT_STRUCT_ARRAY = listTagStruct;
      if (PRODUCT_STRUCT_ARRAY.length > 0) {
        changeActiveTagValue(
          new TagModel({
            ...TagModel.getDefaultData(),
            name: PRODUCT_STRUCT_ARRAY[0].name,
            code: PRODUCT_STRUCT_ARRAY[0].code,
          })
        );
        setListTag(
          PRODUCT_STRUCT_ARRAY.map((item) => {
            return new TagModel({
              ...TagModel.getDefaultData(),
              name: item.name,
              code: item.code,
            });
          })
        );
        let resultItems: TabItemType[] = [];
        PRODUCT_STRUCT_ARRAY.map((item) => {
          resultItems.push({
            key: item.code,
            label: item.name,
            children: <></>,
            closable: false,
          });
        });

        setTabItems(resultItems);
      }
    }
    // setLoading(false);
  };

  const handleChangeSelectTab = (key: string) => {
    const findItem = listTag.find((item) => item.code === key);
    if (findItem) {
      changeActiveTagValue(findItem);
    }
    setSelectedValue([]);
    setAfterRevertArray([]);
    setFilters(defaultFilters);
  };

  const handleCheckBoxId = ({ item }: { item: SelectOptionNormal }) => {
    const findIndex = selectedValue.findIndex(
      (tmp) => tmp.value === item.value
    );
    if (findIndex >= 0) {
      const cloneArray = [...selectedValue];
      cloneArray.splice(findIndex, 1);
      handleSelectedValue(cloneArray);
    } else {
      handleSelectedValue([
        ...selectedValue,
        { label: item.label, value: item.value },
      ]);
    }
  };

  const tagRender = ({ item }: { item: SelectOptionNormal }) => {
    return (
      <Tag
        closable
        onClose={() => {
          handleCheckBoxId({ item: item });
        }}
      >
        {item.label}
      </Tag>
    );
  };

  const handleSelectedValue = (v: SelectOptionNormal[]) => {
    setSelectedValue(v);
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const chooseItem = dataSource.find(
      (item) => item.id === result.draggableId
    );
    const destinationItem = { ...dataSource[result.destination.index] };
    if (chooseItem && destinationItem) {
      // const response = await new TagRepository().updateTagDetailDisplay({
      //   display_order: destinationItem.display_order,
      //   tagdetail_id: Number(chooseItem.id),
      // });
      const reorderedItems = Array.from(dataSource);
      const [removed] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, removed);

      setDataSouce(reorderedItems);
    }
  };

  const renderListItem = (item: any): ReactNode => {
    if (type === "productrelated" && existValues.includes(Number(item.id))) {
      return (
        <>
          <List.Item className="w-full block">
            <div className="flex justify-between items-center w-full">
              <div className="flex justify-start gap-2">
                <div className="flex gap-2 items-center">
                  {mode === "delete" && (
                    <Checkbox
                      checked={
                        selectedValue.findIndex(
                          (tmp) => tmp.value === item.id
                        ) >= 0
                      }
                      onChange={(e) => {
                        if (!e.target.checked) {
                          const findIndex = selectedValue.findIndex(
                            (tmp) => tmp.value === item.id
                          );
                          if (findIndex >= 0) {
                            const cloneArray = [...selectedValue];
                            cloneArray.splice(findIndex, 1);
                            handleSelectedValue(cloneArray);
                          }
                        } else {
                          handleSelectedValue([
                            ...selectedValue,
                            { label: item.name, value: item.id },
                          ]);
                        }
                      }}
                    />
                  )}
                  {mode === "revert" && (
                    <Checkbox
                      checked={
                        selectedValue.findIndex(
                          (tmp) => tmp.value === item.id
                        ) >= 0
                      }
                      onChange={(e) => {
                        if (!e.target.checked) {
                          const findIndex = selectedValue.findIndex(
                            (tmp) => tmp.value === item.id
                          );
                          if (findIndex >= 0) {
                            const cloneArray = [...selectedValue];
                            cloneArray.splice(findIndex, 1);
                            handleSelectedValue(cloneArray);
                          }
                        } else {
                          handleSelectedValue([
                            ...selectedValue,
                            { label: item.name, value: item.id },
                          ]);
                        }
                      }}
                    />
                  )}
                  {renderItems(item)}
                </div>
                <div>
                  <Typography.Text className="text-green-600 font-bold text-xs">
                    {afterRevertArray.findIndex((da) => da.value === item.id) >=
                      0 && <>(đã gắn ngược)</>}
                  </Typography.Text>
                </div>
              </div>
              {existValues &&
              existValues.findIndex((tmp) => tmp + "" === item.id) >= 0 ? (
                <IconX
                  onClick={() => {
                    handleRemoveItem &&
                      handleRemoveItem(
                        { label: item.name, value: item.id },
                        activeTag
                      );
                  }}
                  className="hover:text-red-500 cursor-pointer min-w-6 min-h-6"
                />
              ) : (
                <></>
              )}
            </div>
          </List.Item>
        </>
      );
    } else {
      return <></>;
    }
  };

  const onRemoveTabItem = async () => {
    if (handleRemoveTabItem) {
      const reponse = await handleRemoveTabItem(
        { label: "This product", value: productDetail.id },
        activeTag
      );
      if (reponse) {
        setShowConfirm(false);
      }
    }
  };

  const onEditTag = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      setAddTagProductStructVisible(true);
      setAddTagPopupId(0);
    } else {
      setShowConfirm(true);
    }
  };

  const afterRemoveCurrent = async (selectedValue: SelectOptionNormal[]) => {
    setBtnDeleteLoading(true);
    const response = await handleRemoveCurrentItem(selectedValue);
    if (response) {
      setSelectedValue([]);
    }
    setBtnDeleteLoading(false);
  };

  const afterRevertCurrent = async (selectedValue: SelectOptionNormal[]) => {
    const selectedArray = selectedValue;
    setBtnRevertedLoading(true);
    const response = await onSaveReverted(selectedValue);
    if (response) {
      setAfterRevertArray(
        removeItemDuplicate([...selectedArray, ...afterRevertArray])
      );
      setSelectedValue([]);
    }
    setBtnRevertedLoading(false);
  };

  useEffect(() => {
    if (productDetail.id > 0) {
      renderTabItems(productDetail);
    }
  }, [productDetail, reloadListTag]);

  //clear checkbox
  useEffect(() => {
    if (dataSource.length === 0) {
      setSelectedValue([]);
    }
  }, [dataSource]);

  return (
    <div>
      <Tabs
        type="editable-card"
        items={tabItems}
        onChange={handleChangeSelectTab}
        onEdit={onEditTag}
        activeKey={activeTag.code}
      />
      {/* <div className="mb-4">{selectedValue.length} sản phẩm đã chọn</div>
      {selectedValue.map((item, index) => {
        return <span key={item.value}>{tagRender({ item: item })}</span>;
      })}
      {selectedValue.length > 0 && (
        <div className="flex">
          <Button
            className="mt-4"
            type="primary"
            loading={btnDeleteLoading}
            disabled={btnDeleteLoading}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await afterRemoveCurrent(selectedValue);
            }}
          >
            Xóa
          </Button>
        </div>
      )}
      <Divider /> */}
      <div className="flex flex-wrap justify-between">
        <AddListToItemFilter
          defaultFilters={defaultFilters}
          filters={filters}
          setFilters={setFilters}
          total={total}
          type={type}
        />
      </div>
      <div className="flex justify-end">
        {listTag.length > 0 && (
          <>
            {children ? (
              <div
                onClick={(e) => {
                  setOpen(true);
                }}
              >
                {children}
              </div>
            ) : (
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                icon={
                  <IconPlus
                    size={16}
                    stroke={3}
                    className="-mt-0.5 hidden sm:inline-block"
                  />
                }
                type="primary"
              >
                {btnTitle}
              </Button>
            )}
          </>
        )}
      </div>
      <div className="flex justify-between items-center">
        <Checkbox
          checked={
            selectedValue.length === existValues.length &&
            existValues.length > 0
          }
          disabled={existValues.length === 0}
          onChange={(e) => {
            if (!e.target.checked) {
              //untick
              setSelectedValue([]);
            } else {
              //tick
              setSelectedValue(
                dataSource
                  .filter(
                    (item) =>
                      existValues.findIndex((tmp) => tmp == item.id) >= 0
                  )
                  .map((pro) => {
                    return {
                      label: pro.name,
                      value: pro.id,
                    };
                  })
              );
            }
          }}
        >
          {" "}
          Chọn tất cả
        </Checkbox>
        <div className="flex gap-2">
          {selectedValue.length > 0 && (
            <Button
              className="mt-4"
              type="default"
              loading={btnDeleteLoading}
              disabled={btnDeleteLoading}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await afterRemoveCurrent(selectedValue);
              }}
              icon={
                <IconTrash
                  className="text-gray-400 hover:text-red-500"
                  size="18"
                />
              }
            ></Button>
          )}
          {selectedValue.length > 0 && (
            <Tooltip
              title={`Gắn sản phẩm vào tag ${activeTag.name} của những sản phẩm đã chọn`}
            >
              <Button
                className="mt-4"
                type="default"
                loading={btnRevertedLoading}
                disabled={btnRevertedLoading}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (false) {
                    console.log("------");
                  } else {
                    await afterRevertCurrent(selectedValue);
                  }
                }}
                icon={<IconPlus className="text-gray-400" size="18" />}
              >
                Gắn ngược
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      {!loading ? (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col gap-2"
                >
                  {dataSource.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="shadow-sm"
                          style={{
                            userSelect: "none",
                            backgroundColor: "white",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {renderListItem(item)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <Skeleton active />
      )}
      {dataSource.length > 0 && (
        <div className="my-4">
          <PageDataPagination
            dataSource={dataSource}
            filters={filters}
            setFilters={setFilters}
            total={total}
          />
        </div>
      )}
      <ProductRelatedAddTagModal
        id={addTagPopupId}
        key={addTagPopupId}
        open={addTagProductStructVisible}
        productId={productDetail.id}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setAddTagPopupId(-1);
          }
          setAddTagProductStructVisible(isOpen);
        }}
        onSaveSuccess={(m) => {
          onAddTagStructSuccess && onAddTagStructSuccess();
        }}
      />

      <AddListToItemModal
        activeTag={activeTag}
        type={type}
        reload={reload}
        key={id}
        id={id}
        store_id={storeList.length > 0 ? storeList[0].id : -1}
        open={open}
        setOpen={setOpen}
        existValues={
          type === "tagproduct" ||
          type === "tagproductcollection" ||
          type === "tagstorycollection" ||
          type === "tagstory"
            ? dataSource.map((item) => item.tag_id)
            : existValues
        }
        onSaveSuccess={onSaveSuccess}
        renderItems={renderItems}
        handleRemoveItem={handleRemoveItem}
        handleAfterAddSuccess={handleAfterAddSuccess}
        handleAddSkuSuccess={handleAddSkuSuccess}
      />
      <ConfirmDeleteProductStruct
        open={showConfirm}
        setOpen={setShowConfirm}
        id={activeTag.id}
        onSaveSuccess={() => {}}
        handleRemoveTabItem={onRemoveTabItem}
      />
    </div>
  );
};

export default ProductRelatedComponent;
