import { useCallback, useEffect, useState } from "react";

import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Row,
	Spin,
	Typography,
	message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import LayoutForm from "components/form/LayoutForm";
import TextArea from "antd/es/input/TextArea";

import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import FormSection from "components/form/FormSection";
import ErrorAlert from "components/ErrorAlert";

//////////////////////////////////
//Phần thay thế
import LeaveModel from "common/models/LeaveModel";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import TemplateModel from "common/models/TemplateModel";
import DepartmentRepository from "common/repositories/DepartmentRepository";
import LeaveRepository from "common/repositories/LeaveRepository";
import LeaveSummaryRepository from "common/repositories/LeaveSummaryRepository";
import { LeaveJsonAddEdit, LeaveUser } from "common/types/Leave";
import Helper from "common/utils/helper";
import FormSelect from "components/form/FormSelect";
import LeaveFormHeader from "./LeaveFormHeader";
import ReportLeaveRepository from "common/repositories/ReportLeaveRepository";
import { debounce } from "lodash";

type Props = {
	model: LeaveModel;
	leaveTypes: LeaveTypeModel[];
};
const LeaveForm = ({ model, leaveTypes }: Props) => {
	const [form] = useForm();
	const { t } = useTranslation();
	const isEditing = model.id > 0;
	const account = useLoginAccountStore((state: any) => state.account);

	/////////////////////////////////
	//state
	const [isSuccess, setIsSucess] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
	const [user, setUser] = useState<LeaveUser>();

	const [leaveTypesItem, setLeaveTypesItem] = useState<any[]>([]);
	const [leaveSumAvaiable, setLeaveSumAvaiable] = useState<number>(0);
	const [leaveSummax, setLeaveSummax] = useState<number>(0);
	const [leaveNote, setLeaveNote] = useState<string>("");
	const [salaryType, setSalaryType] = useState<string>("none");
	const [dayOff, setDayOff] = useState<number>(0);

	const [dayWarning, setDayWarning] = useState<number>(0);

	/////////////////////////////////
	const leaveDateFrom = Form.useWatch("date_from", form);
	const timestamp = Helper.convertedDateToTimeStamp(leaveDateFrom);
	const monthOfFromDate = new Date(timestamp * 1000).getMonth() + 1;
	//default data
	const initialValues = {
		type_id: model.leavetypes_id > 0 ? model.leavetypes_id : null,
		dayoff: model.dayoff.toString() || 0,
		date_from:
			model.date_from > 0 ? dayjs(new Date(model.date_from * 1000)) : null,
		date_to: model.date_to > 0 ? dayjs(new Date(model.date_to * 1000)) : null,
		status: model.status || 0,
		note: model.note || "",
	};

	//action
	const handleLeaveType = async (leavetype_id: number) => {
		//get date avaiable/summax
		const avaiable: any = await new LeaveSummaryRepository().getSummaryDate(
			leavetype_id
		);
		if (avaiable) {
			setLeaveSumAvaiable(avaiable.available_days);
			const max: any = leaveTypes.find((item) => item.id === leavetype_id) || {
				summax: 0,
			};
			setLeaveSummax(max.summax);

			//salary type
			//Get note from list leaveItems
			for (let i = 0; i < leaveTypes.length; i++) {
				if (leaveTypes[i].id === leavetype_id) {
					setSalaryType(leaveTypes[i].salary_type);
					setLeaveNote(leaveTypes[i].note);
					break;
				}
			}
		} else {
			message.error(`leave:form.error.${avaiable.error[0]}`);
		}
	};

	const resetLeaveForm = () => {
		if (isEditing) {
			form.setFieldsValue({
				date_from: dayjs(new Date(model.date_from * 1000)),
				date_to: dayjs(new Date(model.date_to * 1000)),
				dayoff: model.dayoff,
				note: model.note,
			});
			// setDateFrom(dayjs(model.date_from * 1000));
			// setDateTo(dayjs(model.date_to * 1000));
		} else {
			form.setFieldsValue({
				type_id: null,
				date_from: null,
				date_to: null,
				dayoff: 1,
				note: "",
			});
			setLeaveNote("");
			setLeaveSumAvaiable(0);
			setLeaveSummax(0);
			setSalaryType("...");
		}
	};

	/////////////////////////////////
	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: LeaveJsonAddEdit = {
				...TemplateModel.getDefaultData(),
				id: model.id,
				type_id: formData.type_id,
				dayoff: Number(formData.dayoff),
				note: formData.note || "",
				date_from:
					Helper.datePickerValueToTimestamp(formData.date_from) > 0
						? Helper.datePickerValueToTimestamp(formData.date_from)
						: -1,
				date_to:
					Helper.datePickerValueToTimestamp(formData.date_to) > 0
						? Helper.datePickerValueToTimestamp(formData.date_to)
						: -1,
			};

			return submitData;
		},
		[model.id]
	);
	/////////////////////////////////
	//submit data to server
	const onSubmit = async (formData: LeaveJsonAddEdit) => {
		doPrepareData(formData);
		setLoading(true);
		const myObj: LeaveModel = await new LeaveRepository().saveRemote(
			doPrepareData(formData)
		);
		if (myObj.hasError()) {
			setErrors(myObj.error.errors);
		} else {
			setIsSucess(true);
		}
		setLoading(false);
	};
	/////////////////////////////////
	//sidebar form
	const sidebarItems = (
		<>
			<Form.Item>
				<Button
					size="large"
					block
					htmlType="button"
					onClick={() => {
						// navigate("/leave-of-absence");
						resetLeaveForm();
					}}>
					{t("leave:form.button.refresh")}
				</Button>
				<Button
					className="mt-2"
					type="primary"
					htmlType="submit"
					loading={loading}
					size="large"
					block>
					{isEditing ? t("leave:form.button.edit") : t("leave:form.button.add")}
				</Button>
			</Form.Item>
		</>
	);

	//fetch data
	const getInfoUser = async () => {
		// setLoadingInfo(true);
		const dept = await new DepartmentRepository().getItem(
			account.company.employee.department_id
		);
		if (dept) {
			setUser({
				internal_id: account.company.employee.internal_id,
				full_name: account.full_name,
				department: dept.name,
				job_title: account.company.employee.job_title,
			});
		} else {
			message.error(t("common:error.error_fetching_data"));
		}
		// setLoadingInfo(false);
	};

	const getDataWarningLeaveDate = debounce(async () => {
		if (!leaveDateFrom) {
			return;
		}

		const res = await new ReportLeaveRepository().getItems({
			filters: {
				...ReportLeaveRepository.getDefaultFilters(),
				employee_id: account.company.employee.id,
				month: monthOfFromDate,
			},
		});
		if (!res.hasError() && res.items.length > 0) {
			setDayWarning(res.items[0].sum);
		} else {
			setDayWarning(0);
		}
	}, 500);

	useEffect(() => {
		// getInfoUser();
		getDataWarningLeaveDate();
	}, [leaveDateFrom]);

	const createItemsForSelect = (leaveTypes: LeaveTypeModel[]) => {
		const data = leaveTypes.map((item: LeaveTypeModel) => {
			return {
				value: item.id,
				label: item.name,
			};
		});

		setLeaveTypesItem(data);
		return data;
	};

	useEffect(() => {
		setLoadingInfo(true);
		getInfoUser();
		createItemsForSelect(leaveTypes);
		if (model.id > 0) {
			handleLeaveType(model.leavetypes_id);
		}
		// else {
		//   handleLeaveType(1);
		// }
		setDayOff(model.dayoff);
		// setDateFrom(moment(model.date_from * 1000));
		// setDateTo(momentt(model.date_to * 1000));
		setLoadingInfo(false);
	}, [model]);

	return (
		<div>
			<LeaveFormHeader isEditing={isEditing} />
			{/* {loadingInfo && (
        <Space
          style={{
            position: "fixed",
            inset: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
          align="center"
        >
          <Spin tip="Loading" size="large" spinning={loadingInfo}></Spin>
        </Space>
      )} */}
			<Spin spinning={loadingInfo}>
				<LayoutForm
					onSubmit={onSubmit}
					form={form}
					initialValues={initialValues}
					errors={errors}
					isSuccess={isSuccess}
					successTitle={
						isEditing
							? t("leave:form.success.update")
							: t("leave:form.success.add")
					}
					redirectOnSuccess="/leave"
					error_translate_prefix="leave:form.error"
					sidebarItems={sidebarItems}
					hideSubmitButton={true}>
					<FormSection
						title={t("leave:form.section_off")}
						subtitle={t("leave:form.section_off_subtitle")}
						leftColumnSize={6}>
						<Form.Item
							label={
								<span className="font-bold">{t("leave:form.employee")}</span>
							}>
							<Row className="bg-gray-200 flex justify-between py-1 px-4 flex-wrap">
								{user ? (
									<>
										<Col xs={24} md={6}>
											{user.internal_id}
										</Col>
										<Col xs={24} md={6}>
											{user.full_name}
										</Col>
										<Col xs={24} md={6}>
											{user.department}
										</Col>
										<Col xs={24} md={6}>
											{user.job_title}
										</Col>
									</>
								) : (
									<> {t("leave:form.loading")}</>
								)}
							</Row>
						</Form.Item>

						<Row gutter={16}>
							<Col md={8} xs={24}>
								<FormSelect
									showSearch
									disabled={isEditing}
									required
									label={t("leave:leave_type")}
									placeholder={t("leave:filter.choose_leave_type")}
									name="type_id"
									options={leaveTypesItem}
									rules={[
										{
											required: true,
											message: t("leave:form.error.required_leave_type"),
										},
									]}
									onChange={(e: any) => {
										handleLeaveType(e);
									}}
								/>
							</Col>
							<Col md={8} xs={24}>
								<Form.Item label={t("leave:max_day_off")}>
									<p className="m-0">
										<span className="font-bold">{leaveSumAvaiable}</span> /{" "}
										{leaveSummax} {t("leave:day")}
									</p>
								</Form.Item>
							</Col>
							<Col md={8} xs={24}>
								<Form.Item label={t("leave:salary")}>
									<span className="font-bold">
										{t(`leave:salary_type.${salaryType}`)}
									</span>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							{leaveNote.length > 0 ? (
								<div
									className="text-red-500"
									style={{ marginTop: "-15px", marginBottom: "24px" }}>
									<p className="mb-0">{t("leave:note")}</p>
									<p className="mb-0">{leaveNote}</p>
								</div>
							) : (
								<div></div>
							)}
						</Row>

						<Row gutter={16}>
							<Col md={8} xs={24}>
								<Form.Item
									label={t("leave:off_start")}
									name="date_from"
									validateFirst
									rules={[
										{
											required: true,
											message: t("leave:form.error.required_date"),
										},
										{
											message: t("leave:form.error.required_date"),
											validator: (_: any, value: Dayjs) => {
												if (value) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
									]}>
									<DatePicker
										inputReadOnly
										className="w-full"
										placeholder={"HH:mm dd/mm/yyyy"}
										format="HH:mm DD/MM/YYYY"
										showTime
										name="date_from"
									/>
								</Form.Item>
								{
									<>
										{dayWarning > 0 && leaveDateFrom && (
											<Typography.Text className="block mt-1" type="warning">
												*Lưu ý: Bạn đã nghỉ <strong>{dayWarning}</strong>
												{" ngày "}
												trong tháng <strong>{monthOfFromDate}</strong>
											</Typography.Text>
										)}
									</>
								}
							</Col>
							<Col md={8} xs={24}>
								<Form.Item
									label={t("leave:off_end")}
									name="date_to"
									validateFirst
									rules={[
										{
											required: true,
											message: t("leave:form.error.required_date"),
										},
										{
											message: t("leave:form.error.required_date"),
											validator: (_: any, value: any) => {
												if (value) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
										{
											message: t("leave:form.error.error_range_date"),
											validator: (_: any, value: any) => {
												if (
													Helper.datePickerValueToTimestamp(
														form.getFieldValue("date_from")
													) < Helper.datePickerValueToTimestamp(value)
												) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
									]}>
									<DatePicker
										inputReadOnly
										className="w-full"
										placeholder={"HH:mm dd/mm/yyyy"}
										format="HH:mm DD/MM/YYYY"
										showTime
										disabledDate={(current) =>
											current &&
											current.isBefore(
												dayjs(form.getFieldValue("date_from")).subtract(
													100,
													"millisecond"
												)
											)
										}
									/>
								</Form.Item>
							</Col>
							<Col md={8} xs={24}>
								<Form.Item
									label={t("leave:list.day_off")}
									validateFirst
									name="dayoff"
									wrapperCol={{ span: 24 }}
									// validateTrigger={["onBlur", "onChange"]}
									rules={[
										{
											required: true,
											message: t("leave:form.error.required_dayoff"),
										},
										{
											message: t("leave:form.error.error_leave_dayoff_format"),
											validator: (_, value) => {
												// console.log(typeof value)
												// if (typeof value === "number" && value > 0) {
												if (Number(value) > 0) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
										{
											message: t("leave:form.error.error_leave_dayoff"),
											validator: (_, value) => {
												// console.log(typeof value)
												// if (typeof value === "number" && value > 0) {
												if (
													isEditing === false &&
													Number(value) <= leaveSumAvaiable
												) {
													return Promise.resolve();
												} else if (
													isEditing === true &&
													Number(value) <= model.dayoff + leaveSumAvaiable
												) {
													return Promise.resolve();
												} else {
													return Promise.reject("Some message here");
												}
											},
										},
									]}>
									<Input
										type="number"
										allowClear={true}
										style={{ textAlign: "right", width: "100%" }}
										className="md:text-right text-left w-full"
										defaultValue={dayOff}
										step={0.5}
									/>
								</Form.Item>
							</Col>
						</Row>

						{/* <Row>
							<Form.Item>
								<p>
									Tổng cộng: <span className="font-bold">{dayOff}</span> ngày.
								</p>
							</Form.Item>
						</Row> */}

						<Row gutter={16}>
							<Col md={24} xs={24}>
								<Form.Item
									required
									label={t("leave:reason")}
									name="note"
									rules={[
										{
											min: 8,
											message: t("leave:form.error.error_leave_note_length"),
										},
										{
											required: true,
											message: t("leave:form.error.error_leave_note_required"),
										},
									]}>
									<TextArea rows={4} placeholder={t("leave:reason")} />
								</Form.Item>
							</Col>
						</Row>
					</FormSection>
				</LayoutForm>
			</Spin>
		</div>
	);
};

export default LeaveForm;
