import BaseRepository from "./BaseRepository";

import {
  DefaultFilterStory,
  FilterStory,
  FilterStoryDetail,
  PublicationsStatus,
  StoryJsonAddEdit,
} from "common/types/Story";
import StoryApi from "common/api/StoryApi";
import StoryModel from "common/models/StoryModel";
import type { GetListProps } from "common/types/Filter";
import StoryCollection from "common/collections/StoryCollection";
import PublicationsApi from "common/api/PublicationsApi";

class PublicationsRepository extends BaseRepository<PublicationsApi> {
  _api: PublicationsApi | null;
  constructor() {
    super();
    this._api = new PublicationsApi(true);
  }

  static getDefaultFilter(props?: DefaultFilterStory): FilterStory {
    return {
      page: 1,
      limit: props?.limit || 12,
      sortby: "id",
      sorttype: "ASC",
      keyword: "",
      status: "",
      publish_from: -1,
      publish_to: -1,
      content_type: 3,
      collection_id: -1,
      pin: 0,
      type: -1,
      not_id: "",
      tags: undefined,
    };
  }

  async getItems(props: GetListProps<FilterStory>): Promise<StoryCollection> {
    return this._api ? await this._api.getList(props) : new StoryCollection();
  }

  async getItem(id: number): Promise<StoryModel> {
    return this._api
      ? await this._api.getItem(id)
      : new StoryModel(StoryModel.getDefaultData());
  }

  async updateStatus(data : { id: number , status : PublicationsStatus }): Promise<StoryModel> {
    return this._api
      ? await this._api.updateStatus(data)
      : new StoryModel(StoryModel.getDefaultData());
  }

  async saveRemote(data: Partial<StoryJsonAddEdit>): Promise<StoryModel> {
    if (this._api) {
      if (data && data?.id) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new StoryModel(StoryModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default PublicationsRepository;
