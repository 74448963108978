// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-kanban-board > div {
  display: flex !important;
  justify-content: space-between !important;
  /* flex-wrap: wrap !important; */
}

.taskpublicuserkanban__wrapper .ant-collapse-header {
  display: block !important;
}

.taskpublicuserkanban__wrapper .kanban__item {
  /* min-width: 200px; */
  /* width: calc(100vw / 4 - 16px - 40px); */
  background-color: white;
  width: calc(100vw / 4 - 75px);
  margin: 8px 0;
}

.taskpublicuserkanban__wrapper .ant-collapse-header {
  cursor: grab !important;
}

@media screen and (max-width: 1600px) {
  .taskpublicuserkanban__wrapper .kanban__item {
    width: calc(100vw / 4 - 115px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/task/mine/kanban/components/TaskKanban.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,yCAAyC;EACzC,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,0CAA0C;EAC1C,uBAAuB;EACvB,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".react-kanban-board > div {\n  display: flex !important;\n  justify-content: space-between !important;\n  /* flex-wrap: wrap !important; */\n}\n\n.taskpublicuserkanban__wrapper .ant-collapse-header {\n  display: block !important;\n}\n\n.taskpublicuserkanban__wrapper .kanban__item {\n  /* min-width: 200px; */\n  /* width: calc(100vw / 4 - 16px - 40px); */\n  background-color: white;\n  width: calc(100vw / 4 - 75px);\n  margin: 8px 0;\n}\n\n.taskpublicuserkanban__wrapper .ant-collapse-header {\n  cursor: grab !important;\n}\n\n@media screen and (max-width: 1600px) {\n  .taskpublicuserkanban__wrapper .kanban__item {\n    width: calc(100vw / 4 - 115px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
