import { CloseCircleOutlined, ImportOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import PromotionCouponRepository from "common/repositories/PromotionCouponRepository";
import AddProductBySku from "features/productcollection/form/children/AddProductBySku";
import React, { useMemo, useRef, useState } from "react";
import * as XLSX from "xlsx";

type Props = {
	initialValue: { codes: string[] };
	idEditing: number;
	onFinish?: () => void;
};
export default function CouponForm({
	initialValue,
	idEditing,
	onFinish,
}: Props) {
	const [messageApi, context] = message.useMessage();
	const [form] = Form.useForm();
	const initialValues = useMemo(() => {
		return { ...initialValue };
	}, [initialValue]);

	const inputRef = useRef<HTMLInputElement>(null);
	const [fileExcel, setfileExcel] = useState<File | null>(null);
	const [skuList, setSkuList] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const handleFinish = async (data: any) => {
		setLoading(true);
		const codeString = data.codes as string;
		const dataCodes = codeString.trim().split(",");
		const res = await new PromotionCouponRepository().saveRemote({
			promotion_id: idEditing,
			codes: dataCodes,
			id: 0,
		});

		if (!res.hasError()) {
			messageApi.success("sucess");
			onFinish && onFinish();
		}
		setLoading(false);
	};

	const uploadFileExcelAndRead = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				const binaryStr = event.target?.result;
				if (typeof binaryStr === "string") {
					const workbook = XLSX.read(binaryStr, { type: "binary" });
					const sheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[sheetName];
					const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
						header: 1,
					});
					//  SKU in row 1
					const skuList = jsonData
						.slice(1)
						.map((row: any) => row[0] as string) as string[];
					setSkuList(skuList);
					if (skuList.length > 0) {
						form.setFieldValue("codes", skuList.join(",").trim());
					}
				}
			};
			setfileExcel(file);
			reader.readAsBinaryString(file);
		}
	};
	return (
		<>
			{context}
			<div className="mb-4">
				<input
					ref={inputRef}
					type="file"
					onChange={uploadFileExcelAndRead}
					className=" hidden"
					accept=".xls, .xlsx"></input>
				{fileExcel?.name ? (
					<p>
						{fileExcel?.name}{" "}
						<CloseCircleOutlined
							onClick={() => {
								if (inputRef?.current) {
									inputRef.current.value = "";
									setfileExcel(null);
								}
							}}
						/>
					</p>
				) : (
					<Button
						onClick={() => {
							if (inputRef.current) {
								inputRef.current.click();
							}
						}}
						icon={<ImportOutlined />}>
						Import từ file excel
					</Button>
				)}
			</div>
			<Form form={form} initialValues={initialValues} onFinish={handleFinish}>
				<Form.Item hidden name={"product_id"}></Form.Item>
				<Form.Item
					labelCol={{ span: 24 }}
					help="Nhập mã cách nhau bởi dấu phẩy"
					label="Nhập mã code"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập",
						},
					]}
					initialValue={initialValue.codes.join(",")}
					name={"codes"}>
					<TextArea
						placeholder="Nhập list mã code"
						value={initialValue.codes.join(",")}
						onChange={(e) => {
							e.preventDefault();
							// setSkuValue(e.target.value);
							// handleChangeInput(e.target.value);
						}}
					/>
				</Form.Item>
				<Form.Item className="mt-4">
					<Button
						loading={loading}
						className="mt-4"
						htmlType="submit"
						type="primary">
						Xác nhận
					</Button>
				</Form.Item>
			</Form>
		</>
	);
}
