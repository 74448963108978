import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TableColumnsType } from "antd";
import { useTranslation } from "react-i18next";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import useStateFilter from "hooks/useStateFilter";
import Role from "common/constants/Role";

import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableDelete from "components/table/TableDelete";
import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
//Phần thay thế
import { FilterTemplate } from "common/types/Template";
import { TemplateJson } from "common/types/Template";

import TemplateListFilter from "./TemplateListFilter";
import TemplateModel from "common/models/TemplateModel";
import TemplateCollection from "common/collections/TemplateCollection";
import TemplateRepository from "common/repositories/TemplateRepository";
import TemplateListHeader from "./TemplateListHeader";
import PageDataPagination from "components/page/PageDataPagination";

const TemplateList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  ////////////////////////
  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  ////////////////////////
  // default filter
  const defaultFilters = useMemo(() => {
    return TemplateRepository.getDefaultFilter();
  }, []);

  ///////////////////////
  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<TemplateModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //Table columns
  const columns: TableColumnsType<TemplateModel> = [
    {
      title: t("common:columns.id"),
      key: "id",
      width: 100,
    },
    {
      title: t("title"),
      key: "title",
      width: 100,
    },

    {
      title: t(""),
      key: "actions",
      width: 100,
      align: "right",
      fixed: "right",
      render: (_: any, record: TemplateJson) => (
        <RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="department:form.error"
            onDeleteCallback={(id) => {
              fetchData();
            }}
            repository={new TemplateRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  ///////////////////////
  //function
  const fetchData = useCallback(async (): Promise<TemplateCollection> => {
    const response = await new TemplateRepository().getItems({
      filters: filters,
    });
    setTotal(response.total);
    setDataSource(response.items);
    return response;
  }, [filters]);

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };
  return (
    <div>
      <TemplateListHeader></TemplateListHeader>
      <TemplateListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<FilterTemplate, TemplateModel, TemplateCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "template",
        }}
      ></PageDataTable>
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </div>
  );
};

export default TemplateList;
