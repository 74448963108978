import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import { Filter } from "common/types/Filter";
import NotificationModel from "common/models/NotificationModel";

import FormSelect from "components/form/FormSelect";

import PageDataFilterForm from "components/page/PageDataFilterForm";

const NotificationListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["pin", "status"]}
      >
       
          <Form.Item name="keyword">
            <Input allowClear placeholder={t("notification:filter.content")} />
          </Form.Item>
          <FormSelect
            name="pin"
            placeholder={t("notification:filter.pin")}
            options={NotificationModel.getPinList()}
            allowClear
            popupMatchSelectWidth={false}
          />
          <FormSelect
            name="status"
            placeholder={t("notification:filter.status")}
            options={NotificationModel.getStatusList()}
            allowClear
            popupMatchSelectWidth={false}
          />
     
      </PageDataFilterForm>
    </>
  );
};

export default NotificationListFilter;
