import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { ProductReceiptSyncStockJson } from 'common/types/ProductReceiptSyncStock';
import { ProductVariantInMemory } from 'common/types/ProductVariant';
import dbm from 'dbm';

import BaseModel from './BaseModel';

class ProductReceiptSyncStockModel
  extends BaseModel
  implements BaseModelJson<ProductReceiptSyncStockJson>
{
  product_variant_id: number;
  warehouse_id: number;
  date_created: number;
  product_variant?: ProductVariantInMemory;

  constructor(json: ProductReceiptSyncStockJson) {
    super();

    this.product_variant_id = json.product_variant_id || 0;
    this.warehouse_id = json.warehouse_id || 0;
    this.date_created = json.date_created || 0;
    if (json.product_variant_id > 0) {
      (async () => {
        const foundItems = await dbm
          .getCollection("productvariant")
          .chain()
          .find({
            id: json.product_variant_id,
          })
          .limit(1)
          .data();
        if (foundItems.length > 0) {
          this.product_variant = foundItems[0];
        }
      })();
    }
  }

  static getDefaultData(): ProductReceiptSyncStockJson {
    return {
      product_variant_id: 0,
      warehouse_id: 0,
      date_created: 0,
    };
  }

  toJson(): ProductReceiptSyncStockJson {
    return {
      product_variant_id: this.product_variant_id,
      warehouse_id: this.warehouse_id,
      date_created: this.date_created,
    };
  }
}

export default ProductReceiptSyncStockModel;
