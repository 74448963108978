import WebHook from "common/constants/WebHook";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { WebHookJson } from "common/types/WebHook";

class WebHookModel extends BaseModel implements BaseModelJson<WebHookJson> {
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  description: string;
  event: string;
  action: number;
  meta_data: any;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: WebHookJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.description = json.description || "";
    this.event = json.event || "";
    this.action = json.action || 0;
    this.meta_data = json.meta_data || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): WebHookJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      description: "",
      event: "",
      action: 0,
      meta_data: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): WebHookJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      description: this.description,
      event: this.event,
      action: this.action,
      meta_data: this.meta_data,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: WebHook.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: WebHook.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getGroupList() {
    return [
      {
        value: "order",
        label: i18n.t("webhook:group_order"),
      },
      {
        value: "promotion",
        label: i18n.t("webhook:group_promotion"),
      },
      {
        value: "productreceipt",
        label: i18n.t("webhook:group_inventory"),
      },
      {
        value: "cashflowreceipt",
        label: i18n.t("webhook:group_cashflowreceipt"),
      },
      {
        value: "customer",
        label: i18n.t("webhook:group_customer"),
      },
      {
        value: "product",
        label: i18n.t("webhook:group_product"),
      },
    ];
  }

  static getGroupOrderList() {
    return [
      {
        value: "order.add",
        label: i18n.t("webhook:event_order_add") + " (order.add)",
      },
      {
        value: "order.edit",
        label: i18n.t("webhook:event_order_edit") + " (order.edit)",
      },
    ];
  }

  static getGroupPromotionList() {
    return [
      {
        value: "promotion.add",
        label: i18n.t("webhook:event_promotion_add") + " (promotion.add)",
      },
      {
        value: "promotion.edit",
        label: i18n.t("webhook:event_promotion_edit") + " (promotion.edit)",
      },
    ];
  }

  static getGroupInventoryList() {
    return [
      {
        value: "productreceipt.add",
        label:
          i18n.t("webhook:event_productreceipt_add") + " (productreceipt.add)",
      },
      {
        value: "productreceipt.edit",
        label:
          i18n.t("webhook:event_productreceipt_edit") +
          " (productreceipt.edit)",
      },
    ];
  }

  static getGroupCashflowList() {
    return [
      {
        value: "cashflowreceipt.add",
        label:
          i18n.t("webhook:event_cashflowreceipt_add") +
          " (cashflowreceipt.add)",
      },
      {
        value: "cashflowreceipt.edit",
        label:
          i18n.t("webhook:event_cashflowreceipt_edit") +
          " (cashflowreceipt.edit)",
      },
    ];
  }

  static getGroupCustomerList() {
    return [
      {
        value: "customer.add",
        label: i18n.t("webhook:event_customer_add"),
      },
      {
        value: "customer.edit",
        label: i18n.t("webhook:event_customer_edit"),
      },
    ];
  }

  static getGroupProductList() {
    return [
      {
        value: "product.add",
        label: i18n.t("webhook:event_product_add") + " (product.add)",
      },
      {
        value: "product.edit",
        label: i18n.t("webhook:event_product_edit") + " (product.edit)",
      },
      {
        value: "product.delete",
        label: i18n.t("webhook:event_product_delete") + " (product.delete)",
      },
      {
        value: "product.addsku",
        label: i18n.t("webhook:event_product_addsku") + " (product.addsku)",
      },
      {
        value: "product.editsku",
        label: i18n.t("webhook:event_product_editsku") + " (product.editsku)",
      },
      {
        value: "product.deletesku",
        label:
          i18n.t("webhook:event_product_deletesku") + " (product.deletesku)",
      },
    ];
  }
}

export default WebHookModel;
