import { Button, Form, Input, Modal } from "antd";
import Promotion from "common/constants/Promotion";
import PromotionCouponRepository from "common/repositories/PromotionCouponRepository";
import { PromotionCouponCheckResultJson } from "common/types/PromotionCheck";
import ErrorAlert from "components/ErrorAlert";
import TextMoney from "components/TextMoney";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import usePosCartStore from "zustands/usePosCartStore";

const PosRightPromotionCouponModal = ({
  show,
  setShow,
  onSelectCoupon,
}: {
  show: boolean;
  setShow: (v: boolean) => void;
  onSelectCoupon: (v: PromotionCouponCheckResultJson) => void;
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  const [couponCode, setCouponCode] = useState("");
  const [couponCheckResult, setCouponCheckResult] =
    useState<PromotionCouponCheckResultJson>();
  const [error, setError] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const storeId = usePosCartStore((state) => state.activeStore?.id || 0);

  const doSearch = useCallback(async () => {
    setError([]);
    setLoading(true);
    let repository = new PromotionCouponRepository();
    let couponCheckResult = await repository.posCheckCoupons({
      coupon_code: couponCode,
      store_id: storeId,
    });
    setLoading(false);

    if (couponCheckResult.hasError()) {
      setError(couponCheckResult.error.errors);
    } else {
      setCouponCheckResult(couponCheckResult.toJson());
    }
  }, [couponCode, storeId]);

  const onApply = useCallback(() => {
    if (typeof couponCheckResult !== "undefined") {
      onSelectCoupon(couponCheckResult);
    }
    setCouponCheckResult(undefined);
    setCouponCode("");
    setShow(false);
  }, [onSelectCoupon, couponCheckResult, setShow]);
  console.log(error.length);
  return (
    <Modal
      width={480}
      open={show}
      onCancel={handleClose}
      title="Nhập mã giảm giá / khuyến mãi"
      footer={[
        <Button
          disabled={couponCheckResult == null}
          key="primary"
          type="primary"
          onClick={onApply}
        >
          {t("pos:promotion.coupon.apply")}
        </Button>,
      ]}
    >
      <Form.Item label="Nhập mã">
        <Input.Search
          autoFocus={true}
          enterButton="Kiểm tra"
          value={couponCode}
          suffix={null}
          loading={loading}
          onChange={(e) => {
            setError([]);
            setCouponCheckResult(undefined);
            setCouponCode(e.currentTarget.value);
          }}
          onSearch={doSearch}
        />

        <div>
          {couponCheckResult != null && error.length === 0 ? (
            <div className="bg-green-50 text-green-700 p-2 mt-2">
              {t("pos:promotion.coupon.success_found_prefix")}{" "}
              {couponCheckResult.promotion?.value_type ===
              Promotion.VALUETYPE_PERCENT ? (
                <span>{couponCheckResult.promotion?.value + "%"}</span>
              ) : null}
              {couponCheckResult.promotion?.value_type ===
                Promotion.VALUETYPE_CURRENCY &&
              !Array.isArray(couponCheckResult.promotion?.value) ? (
                <span>
                  <TextMoney money={couponCheckResult.promotion?.value} />
                </span>
              ) : null}
            </div>
          ) : null}

          {error.length > 0 ? (
            <div className="alert alert-danger">
              <ErrorAlert items={error} translate_prefix="pos:form.error" />
            </div>
          ) : null}
        </div>
      </Form.Item>
    </Modal>
  );
};

export default PosRightPromotionCouponModal;
