import { Tag, Tooltip } from "antd";
import CashflowReceiptCollection from "common/collections/CashflowReceiptCollection";
import Role from "common/constants/Role";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import CashflowReceiptRepository from "common/repositories/CashflowReceiptRepository";
import { FilterCashflowReceipt } from "common/types/CashflowReceipt";
import { StoreJson } from "common/types/Store";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextCashflowReceiptDirection from "components/TextCashflowReceiptDirection";
import TextCashflowReceiptMethod from "components/TextCashflowReceiptMethod";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import CashflowReceiptListHeader from "features/cashflowreceipt/list/CashflowReceiptListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CashflowReceiptItemEdit from "../item/CashflowReceiptItemEdit";
import CashflowReceiptItemSource from "../item/CashflowReceiptItemSource";
import CashflowReceiptItemTarget from "../item/CashflowReceiptItemTarget";
import CashflowReceiptListFilter from "./CashflowReceiptListFilter";

const CashflowReceiptList = () => {
	const { t } = useTranslation();

	const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
		"store",
		Role.CASHFLOW_VIEW
	);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterCashflowReceipt = useMemo(
		() => CashflowReceiptRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] =
		useStateFilter<FilterCashflowReceipt>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<CashflowReceiptModel[]>([]);
	const fetchData =
		useCallback(async (): Promise<CashflowReceiptCollection> => {
			let collection = await new CashflowReceiptRepository().getItems({
				filters,
			});
			setTotal(collection.total);
			setDataSource(collection.items);
			return collection;
		}, [filters]);

	//////////////////////////////////////////
	//Table columns
	const columns: TableColumnsType<CashflowReceiptModel> = [
		{
			title: t("cashflowreceipt:id"),
			key: "id",
			align: "center",
			fixed: "left",
			width: 100,
			render: (id, record) => {
				return (
					<CashflowReceiptItemEdit model={record} notPermissionFallback={id}>
						<TableLinkText link={"/cashflowreceipt/edit/id/" + record.id}>
							{id}
						</TableLinkText>
					</CashflowReceiptItemEdit>
				);
			},
		},
		{
			title: t("cashflowreceipt:direction"),
			key: "direction",
			fixed: "left",
			width: 85,
			render: (direction: number) => {
				return <TextCashflowReceiptDirection direction={direction} />;
			},
		},
		{
			title: t("cashflowreceipt:filter_store_id"),
			key: "store_id",
			toggletype: "extend",
			render: (store_id: number) => {
				if (store_id > 0) {
					const found = storeItemsWithLimit.find((i) => i.id === store_id);
					if (typeof found !== "undefined") {
						return <span>{found.name}</span>;
					} else {
						return "ID #" + store_id;
					}
				} else {
					return "-";
				}
			},
		},

		{
			title: t("cashflowreceipt:name"),
			key: "name",
			width: 120,
			toggletype: "extend",
		},
		{
			title: t("cashflowreceipt:value"),
			key: "value",
			align: "right",
			className: "font-bold",
			width: 120,
			render: (value: number) => {
				return <TextMoney money={value} />;
			},
		},
		{
			title: (
				<>
					{t("cashflowreceipt:filter_cashflow_group")} &{" "}
					{t("cashflowreceipt:source")}
				</>
			),
			key: "cashflow_group",
			render: (cashflow_group: number, record) => {
				let groupCom = null;
				if (cashflow_group > 0) {
					const group = CashflowReceiptModel.getGroup(cashflow_group);
					groupCom = <>{group?.label} </>;
				} else {
					groupCom = "";
				}

				return (
					<>
						<span>{groupCom}</span>
						<CashflowReceiptItemSource model={record} />
					</>
				);
			},
		},
		{
			title: t("cashflowreceipt:filter_target"),
			key: "target",
			ellipsis: true,
			render: (_, record) => {
				return <CashflowReceiptItemTarget model={record} />;
			},
		},
		{
			title: (
				<>
					<Tooltip
						title={t("cashflowreceipt:filter_method")}
						mouseEnterDelay={0.4}>
						{t("cashflowreceipt:method_sort")}
					</Tooltip>
				</>
			),
			key: "method",
			align: "center",
			togglelabel: t("cashflowreceipt:filter_method"),
			width: 70,
			render: (method: number) => {
				return <TextCashflowReceiptMethod method={method} />;
			},
		},
		{
			title: t("common:status"),
			key: "status",
			render: (status: number) => {
				let statusInfo = CashflowReceiptModel.getStatus(status);
				return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
			},
			width: 150,
		},
		// {
		//   title: t("cashflowreceipt:date_affected_short"),
		//   togglelabel: t("cashflowreceipt:date_affected"),
		//   key: "date_affected",
		//   render: (ts: number) => {
		//     return <TextDateTime format="HH:mm, DD/MM" ts={ts} />;
		//   },
		// },
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 95,
			toggletype: "trigger",
			render: (_: any, record: CashflowReceiptModel) => (
				<div className="flex gap-1">
					<CashflowReceiptItemEdit model={record} />
					<TableInfo record={record}></TableInfo>
				</div>
			),
		},
	];

	return (
		<>
			<CashflowReceiptListHeader />

			<CashflowReceiptListFilter
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>

			<PageDataTable<
				FilterCashflowReceipt,
				CashflowReceiptModel,
				CashflowReceiptCollection
			>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "cashflowreceipt",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default CashflowReceiptList;
