import RoleCheck from "components/RoleCheck";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";

import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

const ITRequestListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("itrequest:heading_list")}
      siteMenuOpenKey="tool"
      siteMenuSelectedKey="/itrequest"
    >
      <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <RoleCheck roles={[]}>
            <PageHeaderButton link="/itrequest/add">
              {t("template:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default ITRequestListHeader;
