import { Button, Tag } from "antd";
import PosCartModel from "common/models/PosCartModel";
import { PosCartCustomer } from "common/types/PosCart";
import TextAddress from "components/TextAddress";
import TextCustomerType from "components/TextCustomerType";
import TextNumber from "components/TextNumber";
import React, { useCallback, useState } from "react";
import usePosCartStore from "zustands/usePosCartStore";

import { IconSearch } from "@tabler/icons-react";

import PosRightCustomerModal from "./PosRightCustomerModal";

const PosRightCustomer = () => {
  const activeCustomer = usePosCartStore(
    (state) =>
      state.getActiveCart()?.customer || PosCartModel.getDefaultCartCustomer()
  );
  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);

  const [showModal, setShowModal] = useState(false);

  const onSelect = useCallback(
    (customer: PosCartCustomer) => {
      updateActiveCart({ customer });
    },
    [updateActiveCart]
  );

  return (
    <div className="p-4 border-l-4 border-red-500 border-t">
      <div className="float-right">
        <Button
          type="primary"
          style={{ marginTop: -10 }}
          icon={<IconSearch size={16} className="mr-1 -mt-1" />}
          onClick={(e) => {
            e.preventDefault();
            setShowModal(true);
          }}
        >
          Chọn..
        </Button>
      </div>
      <div className="font-bold uppercase mb-2">KHÁCH HÀNG</div>
      {activeCustomer.id === 0 && !activeCustomer.is_new ? (
        <div>Chưa chọn khách hàng nào.</div>
      ) : (
        <div>
          <strong>
            {activeCustomer.full_name} &nbsp;
            {activeCustomer.is_new ? (
              <Tag color="green">New</Tag>
            ) : (
              <span style={{ fontWeight: "normal" }}>
                <Tag color="blue">
                  <TextCustomerType id={activeCustomer.customer_type_id} />
                </Tag>
                <span>
                  <TextNumber number={activeCustomer.point} /> điểm
                </span>
              </span>
            )}
          </strong>
          <div>
            <span className="text-muted">
              {activeCustomer.phone} / {activeCustomer.email}
              <br />
              <TextAddress
                address={activeCustomer.address}
                region_id={activeCustomer.region_id}
                sub_region_id={activeCustomer.sub_region_id}
                sub_sub_region_id={activeCustomer.sub_sub_region_id}
              />
            </span>
          </div>
          <Button
            onClick={() => onSelect(PosCartModel.getDefaultCartCustomer())}
          >
            Hủy
          </Button>
        </div>
      )}

      <PosRightCustomerModal
        activeCustomer={activeCustomer}
        show={showModal}
        setShow={setShowModal}
        onSelect={onSelect}
      />
    </div>
  );
};

export default PosRightCustomer;
