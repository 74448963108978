import { Tag, Tooltip } from "antd";
import RbacSubjectRepository from "common/repositories/RbacSubjectRepository";
import { useTranslation } from "react-i18next";

import { IconCheck, IconX } from "@tabler/icons-react";

const RbacRoleAssignedSubject = ({ subjects }: { subjects: number[] }) => {
  const { t } = useTranslation();

  const foundAssignedGroups =
    RbacSubjectRepository.findAssignedGroups(subjects);

  return (
    <>
      {foundAssignedGroups.length === 0 ? (
        <span className="mr-2 ml-1 italic opacity-70 text-yellow-800">
          {t("role:assigned_subject_empty")}
        </span>
      ) : (
        foundAssignedGroups.map((group) => {
          const isFull = group.items.length === group.assigned_items.length;

          return (
            <Tooltip
              mouseEnterDelay={0.4}
              key={group.name}
              title={
                <>
                  <div>{t("permission:group." + group.name)}:</div>
                  {group.items.map((item) => {
                    return (
                      <div key={item.id}>
                        <small>
                          {group.assigned_items.findIndex(
                            (i) => i.id === item.id
                          ) >= 0 ? (
                            <IconCheck size={16} stroke={2} color="green" />
                          ) : (
                            <IconX size={16} stroke={2} color="red" />
                          )}
                          <span className="ml-1">
                            {t("permission:role." + item.ticket)}
                          </span>
                        </small>
                      </div>
                    );
                  })}
                </>
              }
            >
              <Tag color={isFull ? "success" : "default"}>
                {t("permission:group." + group.name)}
              </Tag>
            </Tooltip>
          );
        })
      )}
    </>
  );
};

export default RbacRoleAssignedSubject;
