import { Col } from "antd";
import React from "react";

const LayoutFormContent = ({
  children,
  isInsideModal,
  span,
}: {
  children: React.ReactNode;
  isInsideModal?: boolean;
  span?: number;
}) => {
  return (
    <Col
      lg={span || 18}
      xs={24}
      className={
        typeof isInsideModal !== "undefined" && isInsideModal ? "pr-6" : "p-6"
      }
    >
      {children}
    </Col>
  );
};

export default LayoutFormContent;
