import { Col, Form, Input, Row, Typography } from "antd";
import DeliveryCollection from "common/collections/DeliveryCollection";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import { DeliveryJson, FilterDelivery } from "common/types/Delivery";
import {
	InventoryReceiptJson,
	InventoryReceiptSourceType,
	InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import DeliverySearch from "components/delivery/DeliverySearch";
import FormSelect from "components/form/FormSelect";
import SearchComponent from "components/search/SearchLayout";
import SupplierSearchSelect from "components/SupplierSearchSelect";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
	disabled?: boolean;
	model: InventoryReceiptJson;
};
const InventoryReceiptTarget = ({ disabled, model }: Props) => {
	const { t } = useTranslation();

	const [isRequire, setIsRequire] = useState({
		targetId: true,
	});

	const [targetType, setTargetType] = useState(model.target.type);

	const renderField = useMemo(() => {
		switch (targetType) {
			case InventoryReceiptTargetType.WAREHOUSE:
				return (
					<WarehouseFormSelect
						rules={[
							{
								required: true,
								message: "Vui lòng chọn",
							},
						]}
						label="Chọn kho"
						name={["target", "id"]}></WarehouseFormSelect>
					// <Form.Item
					// 	{...field}
					// 	name={[field.name, "id"]}
					// 	rules={[{ required: true, message: t("common:required") }]}>
					// 	<Input placeholder={t("id")} disabled={!isAllowEdit} />
					// </Form.Item>
				);
			case InventoryReceiptTargetType.SUPPLIER:
				return (
					<SupplierSearchSelect
						labelAlign="left"
						rules={[
							{
								required: true,
								message: "Vui lòng chọn",
							},
						]}
						label="Chọn nhà cung cấp"
						name={["target", "id"]}
					/>
				);
			case InventoryReceiptTargetType.DELIVERY:
				return (
					<SearchComponent<DeliveryJson, FilterDelivery, DeliveryCollection>
						fetchRepository={(filers) => {
							return new DeliveryRepository().getItemsDeilveryManager({
								filters: filers,
							});
						}}
						defaultFilters={DeliveryRepository.getDefaultFilters()}
						formItemProps={{
							label: "Mã vận đơn",
							name: ["target", "id"],
							rules: [
								{
									required: true,
									message: t("common:form.required"),
								},
							],
						}}
						disabled={disabled}
						keyValue="id"
						showFilters={["keyword"]}
						defaultUseFilters={["keyword"]}
						renderLabel={(item) => {
							return (
								<>
									<Typography.Text>Vận đơn - {item.id}</Typography.Text>
									<Typography.Text mark copyable>
										{" "}
										{item.resource_id}
									</Typography.Text>
								</>
							);
						}}
					/>
					// <DeliverySearch
					// 	labelAlign="left"
					// 	rules={[
					// 		{
					// 			required: true,
					// 			message: "Vui lòng chọn",
					// 		},
					// 	]}
					// 	label="Mã vận đơn"
					// 	name={["target", "id"]}
					// />
				);

			default:
				return (
					<Form.Item
						className="mb-0"
						rules={[
							{
								required: isRequire.targetId,
								message: "Yêu cầu nhập",
							},
						]}
						name={["target", "id"]}
						label="Mã">
						<Input disabled={disabled} placeholder="Giá trị"></Input>
					</Form.Item>
				);
		}
	}, [targetType, disabled]);

	return (
		<div>
			<Row gutter={8}>
				<Col span={12}>
					<Form.Item
						className="mb-0"
						rules={[
							{
								required: true,
								message: "Yêu cầu nhập",
							},
						]}
						name={["target", "type"]}
						label="Loại">
						<FormSelect
							className="mb-0"
							disabled={disabled}
							onChange={(val) => {
								if (typeof val === "number") {
									setTargetType(Number(val));
								}
								if (val === InventoryReceiptTargetType.OTHER) {
									setIsRequire((prev) => ({ ...prev, targetId: false }));
								}
							}}
							options={[
								{ label: "Chọn loại", value: 0, disabled: true },
								...InventoryReceiptModel.getTargetTypeOpntion(),
							]}></FormSelect>
					</Form.Item>
				</Col>
				<Col span={12}>{renderField}</Col>
			</Row>

			{model.target.type === InventoryReceiptTargetType.DELIVERY && (
				<Link target="_blank" to={`/delivery/edit/id/${model.target.id}`}>
					Chi tiết vận đơn # {model.target.id}
				</Link>
			)}
		</div>
	);
};

export default InventoryReceiptTarget;
