import { Col, Popover, Row, Statistic } from "antd";
import ProjectTask from "common/constants/ProjectTask";
import ProjectModel from "common/models/ProjectModel";
import ProjectRepository from "common/repositories/ProjectRepository";
import { ProjectReportJson } from "common/types/Project";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
	IconBriefcase,
	IconCalendarOff,
	IconHourglassHigh,
} from "@tabler/icons-react";

const ProjectListReport = () => {
	const { t } = useTranslation();
	const account = useLoginAccountStore((state) => state.account);

	const [projectReport, setProjectReport] = useState<ProjectReportJson>(
		ProjectModel.getDefaultDataReport()
	);

	const fetchProjectReport = async (companyId: number) => {
		let myObj = await new ProjectRepository().getReport(companyId);
		setProjectReport(myObj);
	};

	useEffect(() => {
		if (account.company.id > 0) {
			fetchProjectReport(account.company.id);
		}
	}, [account]);

	const reportProjectWorkingContent = (
		<div>
			{projectReport.project !== undefined &&
			projectReport.project.items.length > 0 ? (
				projectReport.project.items.map((i) => (
					<p key={i.id}>
						<Link
							to={"/project/detail/id/" + i.id + "?assignee_tpm=me"}
							className="text-blue-500"
							target="_blank">
							{i.name}
						</Link>
					</p>
				))
			) : (
				<p> {t("project:report_project_working_no_data")}</p>
			)}
		</div>
	);

	const reportTaskNotCompleteContent = (
		<div>
			{projectReport.uncomplete_task !== undefined &&
			projectReport.uncomplete_task.items.length > 0 ? (
				projectReport.uncomplete_task.items.map((i) => (
					<p key={i.id}>
						<Link
							to={
								"/project/detail/id/" +
								i.id +
								"?assignee_tpm=me&status_list=" +
								ProjectTask.STATUS_PENDING +
								"," +
								ProjectTask.STATUS_ASSIGNED +
								"," +
								ProjectTask.STATUS_WORKING +
								"&expired=0"
							}
							className="text-blue-500"
							target="_blank">
							{i.name}
							{" ("}
							{i.count_task}
							{" )"}
						</Link>
					</p>
				))
			) : (
				<p> {t("project:report_task_is_complete_no_data")}</p>
			)}
		</div>
	);

	const reportTaskExpiredContent = (
		<div>
			{projectReport.expired_task !== undefined &&
			projectReport.expired_task.items.length > 0 ? (
				projectReport.expired_task.items.map((i) => (
					<p key={i.id}>
						<Link
							to={
								"/project/detail/id/" +
								i.id +
								"?assignee_tpm=me&status_list=" +
								ProjectTask.STATUS_PENDING +
								"," +
								ProjectTask.STATUS_ASSIGNED +
								"," +
								ProjectTask.STATUS_WORKING +
								"&expired=1"
							}
							className="text-blue-500"
							target="_blank">
							{i.name} {" ("}
							{i.count_task_expired}
							{" )"}
						</Link>
					</p>
				))
			) : (
				<p> {t("project:report_task_expired_no_data")}</p>
			)}
		</div>
	);

	return (
		<>
			<Row gutter={16} className="px-4 py-4 bg-gray-100">
				<Col span={8}>
					<Popover content={reportProjectWorkingContent} trigger={"click"}>
						<div className="p-4 border-4 border-gray-300 rounded-md cursor-pointer hover:border-blue-100">
							<Statistic
								title={t("project:report_project_working")}
								value={projectReport.project.total}
								prefix={<IconBriefcase className="mr-1 -mt-1" />}
							/>
						</div>
					</Popover>
				</Col>
				<Col span={8}>
					<Popover content={reportTaskNotCompleteContent} trigger="click">
						<div className="p-4 border-4 border-gray-300 rounded-md cursor-pointer hover:border-blue-100">
							<Statistic
								title={t("project:report_task_is_complete")}
								value={projectReport.uncomplete_task.total}
								prefix={<IconHourglassHigh className="mr-1 -mt-1" />}
							/>
						</div>
					</Popover>
				</Col>

				<Col span={8}>
					<Popover content={reportTaskExpiredContent} trigger="click">
						<div className="p-4 border-4 border-gray-300 rounded-md cursor-pointer hover:border-blue-100">
							<Statistic
								title={t("project:report_task_expired")}
								value={projectReport.expired_task.total}
								prefix={<IconCalendarOff className="mr-1 -mt-1" />}
							/>
						</div>
					</Popover>
				</Col>
			</Row>
		</>
	);
};

export default ProjectListReport;
