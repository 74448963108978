import OrderDetailCollection from 'common/collections/OrderDetailCollection';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

const SERVICE_URL = "/orderdetails";

class OrderDetailApi extends BaseApi {
  async getListOfOrder(order_id: number): Promise<OrderDetailCollection> {
    let collection = new OrderDetailCollection();

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/order/" + order_id
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default OrderDetailApi;
