import { Button, Col, Form, Row } from 'antd';
import { RegionJson } from 'common/types/Region';
import { FeeShiping } from 'common/types/ShippingCarrier';
import MoneyInput from 'components/MoneyInput';
import RegionStandalonePicker from 'components/RegionStandalonePicker';
import dbm from 'dbm';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ShippingCarrierFeeForm = ({
  dataSource,
  onHandleAdd,
}: {
  dataSource: FeeShiping[];
  onHandleAdd: (items: FeeShiping[]) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [regionItems, setRegionItems] = useState<RegionJson[]>([]);
  const [regionIdSelected, setRegionIdSelected] = useState<number[]>([]);
  const [excludeRegionIdList, setExcludeRegionIdList] = useState<number[]>(
    dataSource.map((i) => i.region_id)
  );

  const handleUpdateDetail = useCallback(async () => {
    const formDataValues = form.getFieldsValue();

    if (Array.isArray(formDataValues.region_id_list)) {
      const newItems = formDataValues.region_id_list.map((id: number) => {
        return { region_id: id, fee: formDataValues.fee };
      });

      onHandleAdd(newItems);
      form.setFieldsValue({ region_id_list: [] });
    }
  }, [form, onHandleAdd]);

  const onSelectAllRegion = useCallback(() => {
    const regionIds = regionItems
      .filter((i) => i.parent_id === 0 && !excludeRegionIdList.includes(i.id))
      .map((i) => i.id);

    form.setFieldsValue({ region_id_list: regionIds });
  }, [regionItems, form, excludeRegionIdList]);

  useEffect(() => {
    setExcludeRegionIdList(dataSource.map((i) => i.region_id));
  }, [dataSource]);

  useEffect(() => {
    let ids: number[] = [];
    dataSource.map((item: FeeShiping) => ids.push(item.region_id));
    setRegionIdSelected(ids);
  }, [dataSource]);

  //get regions
  useEffect(() => {
    let items = dbm.getCollection("region")?.chain().data();
    if (
      typeof items !== "undefined" &&
      items.hasOwnProperty("length") &&
      items.length > 0
    ) {
      setRegionItems(items);
    }
    form.setFieldsValue({ fee: 0, regigon_ids: [] });
  }, [form, regionIdSelected]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={() => {}}
      id="fee-shipping-form"
    >
      <Row gutter={16} className="mb-3">
        <Col md={18} xs={24}>
          <Form.Item name="region_id_list" className="mb-0">
            <RegionStandalonePicker excludeValue={excludeRegionIdList} />
          </Form.Item>

          <Button onClick={onSelectAllRegion} type="link" className="p-0">
            [{t("shippingcarrier:detail.select_all")}]
          </Button>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            name="fee"
            label={t("shippingcarrier:detail.fee")}
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_fee_required"),
              },
            ]}
          >
            <MoneyInput min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col md={2} xs={24}>
          <Form.Item label="&nbsp;">
            <Button
              size="middle"
              block
              type="primary"
              loading={false}
              onClick={handleUpdateDetail}
            >
              {t("shippingcarrier:detail.add")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ShippingCarrierFeeForm;
