import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { TaxInvoiceDetailJson } from "common/types/TaxInvoiceDetail";
import { SelectOption } from "common/types/SelectOption";
import TaxInvoiceDetail from "common/constants/TaxInvoiceDetail";
import i18n from "i18n";

class TaxInvoiceDetailModel
  extends BaseModel
  implements BaseModelJson<TaxInvoiceDetailJson>
{
  company_id: number;
  creator_id: number;
  product_id: number;
  product_variant_id: number;
  tax_invoice_id: number;
  id: number;
  sku: string;
  item_type: number;
  item_name: string;
  item_unit_name: string;
  item_unit_price: number;
  item_unit_cost: number;
  item_quantity: number;
  tax: number;
  price_sell: number;
  price_tax: number;
  price_final: number;
  note: string;
  date_created: number;
  date_modified: number;

  constructor(json: TaxInvoiceDetailJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.product_variant_id = json.product_variant_id || 0;
    this.product_id = json.product_id || 0;
    this.tax_invoice_id = json.tax_invoice_id || 0;
    this.id = json.id || 0;
    this.sku = json.sku || "";
    this.item_type = json.item_type || 0;
    this.item_name = json.item_name || "";
    this.item_unit_name = json.item_unit_name || "";
    this.item_unit_price = json.item_unit_price || 0;
    this.item_unit_cost = json.item_unit_cost || 0;
    this.item_quantity = json.item_quantity || 0;
    this.tax = json.tax || 0;
    this.price_sell = json.price_sell || 0;
    this.price_tax = json.price_tax || 0;
    this.price_final = json.price_final || 0;
    this.note = json.note || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): TaxInvoiceDetailJson {
    return {
      id: 0,
      company_id: 0,
      creator_id: 0,
      tax_invoice_id: 0,
      product_id: 0,
      product_variant_id: 0,
      sku: "",
      item_type: 0,
      item_name: "",
      item_unit_name: "",
      item_unit_price: 0,
      item_unit_cost: 0,
      item_quantity: 0,
      tax: 0,
      price_sell: 0,
      price_tax: 0,
      price_final: 0,
      note: "",
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): TaxInvoiceDetailJson {
    return {
      id: this.id,
      company_id: this.company_id,
      creator_id: this.creator_id,
      tax_invoice_id: this.tax_invoice_id,
      product_id: this.product_id,
      product_variant_id: this.product_variant_id,
      sku: this.sku,
      item_type: this.item_type,
      item_name: this.item_name,
      item_unit_name: this.item_unit_name,
      item_unit_price: this.item_unit_price,
      item_unit_cost: this.item_unit_cost,
      item_quantity: this.item_quantity,
      tax: this.tax,
      price_sell: this.price_sell,
      price_tax: this.price_tax,
      price_final: this.price_final,
      note: this.note,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getItemTypeTypeList(): SelectOption[] {
    return [
      {
        value: TaxInvoiceDetail.ITEM_TYPE_PRODUCT,
        label: i18n.t("taxinvoice:ITEM_TYPE_PRODUCT"),
      },
      {
        value: TaxInvoiceDetail.ITEM_TYPE_SHIPPING_FEE,
        label: i18n.t("taxinvoice:ITEM_TYPE_SHIPPING_FEE"),
      },
    ];
  }

  static getItemTypeType(value: number): SelectOption | undefined {
    return this.getItemTypeTypeList().find((item) => item.value === value);
  }
}

export default TaxInvoiceDetailModel;
