import { Tag } from "antd";
import ColorHash from "color-hash";
import { Filter } from "common/types/Filter";
import React, { useEffect, useState } from "react";

const TextTagList = <F extends Filter | undefined>({
  tag,
  filters,
  filterName,
  setFilters,
}: {
  tag: string;
  filters?: F;
  filterName: string;
  setFilters?: (f: F) => void;
}) => {
  const [tagList, setTagList] = useState<string[]>([]);

  useEffect(() => {
    // extractTagList
    let rawtags = tag.split(",");
    let tags = [];

    tags = rawtags.filter(
      (value, index, self) => index === self.findIndex((t) => t === value)
    );

    setTagList(tags);
  }, [tag]);

  const isClickable =
    typeof setFilters !== "undefined" && typeof filters !== "undefined";

  return (
    <>
      {tagList.length > 0
        ? tagList.map((item, index) => (
            <Tag
              key={item + "_" + index}
              color={new ColorHash({ lightness: 0.75 }).hex(item)}
              style={{ color: "black" }}
              className={
                "my-0.5 mr-1 rounded-xl  py-1 px-2 leading-none " +
                (isClickable ? " cursor-pointer hover:opacity-70" : "")
              }
              onClick={
                isClickable
                  ? () =>
                      setFilters({
                        ...filters,
                        [filterName]: item || "",
                        page: 1,
                      })
                  : undefined
              }
            >
              {item}
            </Tag>
          ))
        : null}
    </>
  );
};

export default TextTagList;
