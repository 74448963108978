import { Tag } from "antd";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import { InventoryReceiptJson } from "common/types/InventoryResceipt";
import React from "react";

const InventoryReceiptSource = ({
	source,
}: {
	source: InventoryReceiptJson["source"];
}) => {
	const dataLabel = InventoryReceiptModel.getSourceLabel(source.type);
	return <Tag color={dataLabel.color}>{dataLabel.label}</Tag>;
};

export default InventoryReceiptSource;
