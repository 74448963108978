import {
	Button,
	Col,
	Image,
	Row,
	Statistic,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import Role from "common/constants/Role";
import ProductRepository from "common/repositories/ProductRepository";
import { FilterProduct, ProductStatus, Quantitie } from "common/types/Product";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { TableColumnsType } from "common/types/Table";
import NoPhoto from "components/NoPhoto";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import ProductListHeader from "features/product/list/ProductListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconEye, IconMinus, IconPerfume, IconTag } from "@tabler/icons-react";

import StoreCollection from "common/collections/StoreCollection";
import StoreModel from "common/models/StoreModel";
import StoreRepository from "common/repositories/StoreRepository";
import { FileJson } from "common/types/File";
import { FilterStore } from "common/types/Store";
import ProductListFilter from "./ProductListFilter";
import { convertNumberToPriceVND } from "utils";
import ProductPublicCollection from "common/collections/ProductPublicCollection";
import ProductPublicModel from "common/models/ProductPublicModel";
import { cn } from "common/utils/utils";
import { Link } from "react-router-dom";
import ProductItemPublicExpand from "../item/ProductItemPublicExpand";
import ProductParentChild from "../item/ProductParentChild";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import { TagResourceType } from "common/types/Tag";
import TagTypeModel from "common/models/TagTypeModel";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";

const ProductList = () => {
	const { t } = useTranslation();
	const enableResyncProductCompanyResource = false;

	//////////////////////////////////////////
	// modal sync product
	const [formProductSyncVisible, setFormProductSyncVisible] = useState(false);
	//////////////////////////////////////////
	//Filtering
	const [categoryItems] =
		useDatabaseTable<ProductCategoryJson>("productcategory");

	const defaultFilters: FilterProduct = useMemo(() => {
		return {
			...ProductRepository.getDefaultFilters(),
			parent_id: 0,
			store_id: 0,
		};
	}, []);
	const [filters, setFilters] = useStateFilter<FilterProduct>(defaultFilters);
	useFilterLocation(defaultFilters, filters);
	// const [types, setTypes] = useState<TagTypeModel[]>([]);

	const defaultFiltersStore: FilterStore = useMemo(
		() => StoreRepository.getDefaultFilters(),
		[]
	);
	const [filtersStore, setFiltersStore] =
		useState<FilterStore>(defaultFiltersStore);
	const [storeList, setStoreList] = useState<StoreModel[]>([]);

	//fetchDataStore
	const fetchDataStoreList = useCallback(async (): Promise<StoreCollection> => {
		let collection = await new StoreRepository().getItems({
			filters: { ...filtersStore, limit: 50 },
		});
		if (collection.items.length > 0) {
			setStoreList(collection.items);
		}
		return collection;
	}, [filtersStore]);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<ProductPublicModel[]>([]);

	const fetchData = useCallback(async (): Promise<ProductPublicCollection> => {
		let collection = new ProductPublicCollection();
		collection = await new ProductRepository().getItemsPublic(
			{
				filters: { ...filters, parent_id: filters.keyword ? undefined : 0 },
			},
			categoryItems
		);
		setDataSource(collection.items);
		setTotal(collection.total);
		return collection;
	}, [categoryItems, JSON.stringify(filters)]);

	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [tags, setTags] = useState<TagModel[]>([]);
	//modal tag
	const [addTagVisible, setAddTagVisible] = useState(false);
	const [editingTagId, setEditingTagId] = useState(0);

	useEffect(() => {
		fetchDataStoreList();
	}, [fetchDataStoreList]);

	// useEffect(() => {
	//   if (storeList.length) {
	//     setFilters((pre) => ({
	//       ...pre,
	//       store_id: pre.store_id > 0 ? pre.store_id : storeList[0].id,
	//     }));
	//   }
	// }, [setFilters, storeList]);

	// useEffect(() => {
	//   if (storeList.length) {
	//     setFilters((pre) => ({
	//       ...pre,
	//       store_id: pre.store_id > 0 ? pre.store_id : storeList[0].id,
	//     }));
	//   }
	// }, [setFilters]);
	const onSaveSuccess = () => {
		//detech this is NEW or UPDATE
		// const foundIndex = dataSource.findIndex((r) => r.id === item.id);
		// if (foundIndex >= 0) {
		//   //update current role item info
		//   setDataSource(
		//     update(dataSource, {
		//       [foundIndex]: {
		//         $set: item,
		//       },
		//     })
		//   );
		// } else {
		//   //append new item to list
		//   setDataSource(
		//     update(dataSource, {
		//       $unshift: [item],
		//     })
		//   );
		// }

		fetchData();
	};
	const expandable = useMemo(
		() => ({
			expandedRowRender: (record: ProductPublicModel) => {
				if (!record.parent_id && record.count_childs > 1) {
					return (
						<ProductParentChild store_id={filters.store_id} product={record} />
					);
				}
				// return <ProductItemExpand recordId={record.id} />;
				return (
					<ProductItemPublicExpand
						product={record}
						storeList={storeList}
						onSaveSuccess={onSaveSuccess}
						onDeleteSuccess={onDeleteCallback}
					/>
				);
			},
			expandedRowClassName: () => "ant-table-expanded-row-dimmer",
			expandRowByClick: true,
			showExpandColumn: false,
			expandedRowKeys: expandedRowKeys,
			onExpand: (expanded: boolean, record: ProductPublicModel) => {
				if (expanded) {
					setExpandedRowKeys([record.id]);
				} else {
					setExpandedRowKeys([]);
				}
			},
		}),
		[expandedRowKeys, onDeleteCallback, storeList]
	);

	//product
	const [addProductRelatedVisible, setAddProductRelatedVisible] =
		useState(false);
	const [editingProductRelatedId, setEditingProductRelatedId] =
		useState<number>(0);
	const [chooseProductRelated, setChooseProductRelated] =
		useState<ProductPublicModel>(
			new ProductPublicModel(ProductPublicModel.getDefaultData())
		);

	const onEditProductRelated = (record: ProductPublicModel) => {
		setEditingProductRelatedId(record.id);
		setChooseProductRelated(record);
		setAddProductRelatedVisible(true);
	};

	function onDeleteCallback(id: number) {
		setDataSource(dataSource.filter((item) => item.id !== id));
	}

	useEffect(() => {
		(async () => {
			const res = await new TagRepository().getItems({
				filters: {
					...TagRepository.getDefaultFilters(),
					type: 6,
					is_root: -1,
				},
			});
			if (!res.hasError()) {
				setTags(res.items);
			}
		})();
	}, []);

	//Table columns
	const columns: TableColumnsType<ProductPublicModel> = [
		{
			title: t("product:image_short"),
			key: "images",
			align: "center",
			width: 50,
			render: (images: FileJson[], record) => {
				return images.length > 0 ? (
					<div onClick={(e) => e.stopPropagation()}>
						<Image
							width="40"
							src={images[0].url}
							preview={{
								src: images[0].url,
								mask: <IconEye size="16" />,
							}}
						/>
					</div>
				) : (
					<NoPhoto width={40} height={40} />
				);
			},
		},

		{
			title: t("product:name"),
			key: "name",
			sortby: "name",
			render: (name: string, record) => {
				return (
					<>
						{/* <>
              {record.sell_on_zero === 1 ? (
                <div className="float-right pt-2">
                  <Tooltip
                    title={t("product:sell_on_zero_tag")}
                    mouseEnterDelay={0.4}
                  >
                    <IconLockOpen className="text-green-500" size={18} />
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </> */}

						<div className=" text-md font-bold line-clamp-2">
							{record.count_childs > 1 ? `(${record.count_childs} size) ` : ""}
							{record.count_childs > 1
								? record.name
								: record.full_name
								? record.full_name
								: record.name}
						</div>
						{/* <div className="text-xs text-gray-400 mt-1">{record.sku}</div> */}
					</>
				);
			},
		},
		{
			title: t("product:sku"),
			key: "sku",
			width: 120,
			align: "center",
		},
		{
			title: t("tồn kho tại cửa hàng"),
			key: "quantity",
			sortby: "quantity",

			width: 250,
			render: (_quantities: Quantitie[], record) => {
				const dataStoreFilter = record.quantities.find(
					(i) => i.store_id === Number(filters.store_id)
				);
				if (dataStoreFilter) {
					return (
						<>
							<Row gutter={16}>
								<Col span={12}>
									<Statistic
										title={
											<>
												{/* <Tooltip
                          title={
                            <Typography.Text className="text-white">
                              Số lượng tồn thực tế trong kho trừ đi số lượng
                              khách hàng đã đặt hàng
                            </Typography.Text>
                          }
                        >
                          Khách đặt
                        </Tooltip> */}
												<Tooltip
													title={
														<Typography.Text className="text-white">
															Số lượng khách đang đặt hàng trên website
														</Typography.Text>
													}>
													Khách đặt
												</Tooltip>
											</>
										}
										value={dataStoreFilter.reserved}
										// precision={2}
										valueStyle={{ color: "#3f8600" }}
										// prefix={
										//   dataStoreFilter.actual_quantity < 0 ? (
										//     <IconMinus></IconMinus>
										//   ) : null
										// }
										// suffix="%"
									/>
								</Col>
								<Col span={12}>
									<Statistic
										title={
											<>
												<Tooltip
													title={
														<Typography.Text className="text-white">
															Số lượng tồn kho thực tế trong kho
														</Typography.Text>
													}>
													Tồn kho
												</Tooltip>
											</>
										}
										value={dataStoreFilter.quantity}
										// precision={2}
										valueStyle={{ color: "#cf1322" }}
										prefix={
											dataStoreFilter.quantity < 0 ? (
												<IconMinus></IconMinus>
											) : null
										}
										// suffix="%"
									/>
								</Col>
							</Row>
						</>
					);
				}
				// const caseQuatity = {
				// 	isZero: total_quantity === 0,
				// 	isNegative: total_quantity < 0,
				// };
				return (
					<>
						<Tag> Không có thông tin</Tag>
					</>
				);
			},
		},
		{
			title: t("product:total_quantity"),
			key: "total_quantity",
			width: 150,
			render: (total_quantity: number, record) => {
				const caseQuatity = {
					isZero: total_quantity === 0,
					isNegative: total_quantity < 0,
				};
				return (
					<>
						<Typography.Text
							className={cn("", {
								"text-yellow-500": caseQuatity.isZero,
								"text-red-500": caseQuatity.isNegative,
							})}>
							{" "}
							{total_quantity}
						</Typography.Text>
					</>
				);
			},
		},
		{
			title: t("product:price"),
			key: "price",
			sortby: "price",
			width: 160,
			render: (price: number) => {
				return <p>{convertNumberToPriceVND(price)}</p>;
			},
		},
		{
			title: t("common:status"),
			key: "status",
			sortby: "status",
			render: (status: number) => {
				let statusInfo = ProductPublicModel.getStatus(status);
				return (
					<span
						className={
							status === ProductStatus.UNACTIVE
								? "text-red-500"
								: " text-green-500"
						}>
						{statusInfo?.label}
					</span>
				);
			},
			width: 110,
		},
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (_: any, record: ProductPublicModel) => {
				// const unitInfo = ProductPublicModel.getUnit(record.unit);

				return (
					<RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
						<div onClick={(e) => e.stopPropagation()}>
							<TableEdit link={`/product/edit/id/${record.id}`} />
							<Button
								size={"small"}
								type={"text"}
								onClick={() => onEditTag(record.id)}>
								<Tooltip title="Quản lý phân loại sản phẩm (tag)">
									<IconTag
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
							<Button
								size={"small"}
								type={"text"}
								onClick={() => onEditProductRelated(record)}>
								<Tooltip title="Sản phẩm liên quan">
									<IconPerfume
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
							<TableDelete
								error_translate_prefix="product:form.error"
								onDeleteCallback={(id) => {
									setDataSource(dataSource.filter((item) => item.id !== id));
								}}
								repository={new ProductRepository()}
								id={record.id}
							/>
						</div>
					</RoleCheck>
				);
			},
		},
	];

	const onEditTag = (id: number) => {
		setEditingTagId(id);
		setAddTagVisible(true);
	};

	return (
		<>
			<ProductListHeader>
				{enableResyncProductCompanyResource ? (
					<Button
						type="default"
						className="mr-3"
						onClick={() => {
							setFormProductSyncVisible(true);
						}}>
						{t("product:sync_product")}
					</Button>
				) : null}
				<RoleCheck roles={[Role.PRODUCT_MANAGE]}>
					<Link to={"/product/add"}>
						<Button type="primary">{t("common:table.add_button")}</Button>
					</Link>
				</RoleCheck>
			</ProductListHeader>
			<ProductListFilter
				// list_type={types.map((item) => {
				//   return { label: item.name, value: item.code };
				// })}
				listTags={tags.map((item) => {
					return { label: item.name, value: item.code };
				})}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
				list_store={storeList.map((item) => {
					return { label: item.name, value: item.id };
				})}
			/>
			<PageDataTable<FilterProduct, ProductPublicModel, ProductPublicCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					expandable,
					tableColumnToggleKey: "product",
					rowClassName: "cursor-pointer",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			{/* {enableResyncProductCompanyResource ? (
        <ProductSyncFormModal
          key={"product-sync-modal"}
          open={formProductSyncVisible}
          setOpen={(isOpen) => {
            setFormProductSyncVisible(isOpen);
          }}
        />
      ) : null} */}
			{/* <ProductRelatedModal
        id={editingProductRelatedId}
        data={chooseProductRelated}
        key={editingProductRelatedId}
        open={addProductRelatedVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingProductRelatedId(0);
          }
          setAddProductRelatedVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      /> */}

			{/* <ProductTagModal
        id={editingTagId}
        key={editingTagId}
        open={addTagVisible}
        setOpen={(isOpen) => {
          if (!isOpen) {
            setEditingTagId(0);
          }
          setAddTagVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      /> */}
		</>
	);
};

export default ProductList;
