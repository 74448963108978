const Order = {
	
	STATUS_PROCESSING: 15,
	STATUS_COMPLETE: 19,
	STATUS_CANCEL: 21,
	STATUS_CONFIRM: 14,


	STATUS_DRAFT: 11,
	STATUS_OPEN: 12,



	STATUS_SHIPPING: 16,
	STATUS_SHIPPED: 17,
	STATUS_RETURNING: 18,


	STATUS_PAYMENT_SUCCESS: 1,
	STATUS_PAYMENT_NONE: 3,

	TYPE_INVOICE_ORDER: 0,
	TYPE_INVOICE: 1,

	STATUSTEXT_OPEN: "order_status_text_open",
	STATUSTEXT_CONFIRM: "order_status_text_open",
	STATUSTEXT_PROCESSING: "order_status_text_processing",
	STATUSTEXT_SHIPPING: "order_status_text_shipping",
	STATUSTEXT_SHIPPED: "order_status_text_shipped",
	STATUSTEXT_RETURNING: "order_status_text_returning",
	STATUSTEXT_COMPLETE: "order_status_text_complete",
	STATUSTEXT_CANCEL: "order_status_text_cancel",

	ECOM_PLATFORM_TYPE_POS: 1,
	ECOM_PLATFORM_TYPE_API: 3,
	ECOM_PLATFORM_TYPE_HARAVAN: 5,
	ECOM_PLATFORM_TYPE_WOO_COMMERCE: 7,

	BIT_PAYMENT_METHOD_CASH: 1,
	BIT_PAYMENT_METHOD_BANK: 2,
	BIT_PAYMENT_METHOD_GIFTCARD: 4,
	BIT_PAYMENT_METHOD_CUSTOMER_POINT: 8,
	BIT_PAYMENT_METHOD_DEBIT_CREDIT: 16,
	BIT_PAYMENT_METHOD_COD: 32,
	BIT_PAYMENT_METHOD_MOMO: 64,
	BIT_PAYMENT_METHOD_VNPAY: 128,
	BIT_PAYMENT_METHOD_MEMBERCARD: 256,

	DELIVERY_STATUS_NEW: 1,
	DELIVERY_STATUS_PENDING: 3,
	DELIVERY_STATUS_PACKAGING: 5,
	DELIVERY_STATUS_PACKED: 7,
	DELIVERY_STATUS_WAITFORPICKING: 9,
	DELIVERY_STATUS_PICKING: 11,
	DELIVERY_STATUS_PICKED: 13,
	DELIVERY_STATUS_PROCESSING: 15,
	DELIVERY_STATUS_SHIPPING: 17,
	DELIVERY_STATUS_SHIPPED: 19,
	DELIVERY_STATUS_PARTIALSHIPPED: 21,
	DELIVERY_STATUS_RETURNING: 23,
	DELIVERY_STATUS_PARTIALRETURNED: 25,
	DELIVERY_STATUS_RETURNED: 27,
	DELIVERY_STATUS_CANCEL: 29,

	SOURCE_INTERNAL: 0,
	SOURCE_WORDPRESS: 3,
	SOURCE_MAGENTO: 5,
	SOURCE_PRESTASHOP: 7,
	SOURCE_OPENCART: 9,
	SOURCE_OSCOMMERCE: 11,
	SOURCE_OTHER: 13,
	SOURCE_WOOECOMMERCE: 15,
	SOURCE_MOBILEAPP: 17,
	SOURCE_BIZWEB: 19,
	SOURCE_HARAVAN: 21,
	SOURCE_SHOPIFY: 23,
	SOURCE_BIGCOMMERCE: 25,
	SOURCE_LAZADA: 27,
	SOURCE_SHOPEE: 29,
	SOURCE_SENDO: 31,
	SOURCE_TIKI: 33,
	SOURCE_ZALO: 35,
	SOURCE_FACEBOOKSHOP: 37,
	SOURCE_FACEBOOKMESSENGER: 39,
	SOURCE_INSTAGRAM: 41,

	CANCEL_REASON_OUT_OF_STOCK: 1,
	CANCEL_REASON_CUSTOMER_DISCARD: 3,
	CANCEL_REASON_CUSTOMER_DISCONNECT: 5,
	CANCEL_REASON_CUSTOMER_NOT_PAID: 7,
	CANCEL_REASON_DUPLICATE: 9,
	CANCEL_REASON_INCORRECT_ITEM: 11,
	CANCEL_REASON_HIGH_SHIPPING_FEE: 13,
	CANCEL_REASON_DELIVERY_NOT_ONTIME: 15,
	CANCEL_REASON_CUSTOMER_NOT_SATISFY: 17,
	CANCEL_REASON_SYSTEM_ERROR: 19,
	CANCEL_REASON_PARTNER_ERROR: 21,
	CANCEL_REASON_OTHER: 99,
};

export default Order;
