import { SelectValue } from "antd/es/select";
import ShippingHubRepository from "common/repositories/ShippingHubRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";

const ShippingHubFormSelect = ({
  label,
  name,
  placeholder,
  onChange,
}: {
  label: string;
  name: string;
  placeholder?: string;
  onChange?: (v: SelectValue) => void;
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new ShippingHubRepository().getItems({
      filters: {
        ...ShippingHubRepository.getDefaultFilters(),
        sortby: "display_order",
      },
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  return (
    <FormSelect
      onChange={onChange}
      loading={loading}
      showSearch
      label={label}
      name={name}
      placeholder={placeholder}
      options={options}
      errors={errors}
      errorRetry={fetchData}
    />
  );
};

export default ShippingHubFormSelect;
