const ShippingBill = {
  STATUS_UNKNOW: 100,
  STATUS_NEW: 1,
  STATUS_PENDING: 3,
  STATUS_PACKAGING: 5,
  STATUS_PACKED: 7,
  STATUS_WAITFORPICKING: 9,
  STATUS_PICKING: 11,
  STATUS_PICKED: 13,
  STATUS_PROCESSING: 15,
  STATUS_SHIPPING: 17,
  STATUS_SHIPPED: 19,
  STATUS_PARTIALSHIPPED: 21,
  STATUS_RETURNING: 23,
  STATUS_PARTIALRETURNED: 25,
  STATUS_RETURNED: 27,
  STATUS_CANCEL: 29,

  GATEWAY_STATUS_NOT_AVAILABLE: 1,
  GATEWAY_STATUS_PENDING: 3,
  GATEWAY_STATUS_CREATED_SUCCESS: 5,
  GATEWAY_STATUS_CREATED_FAIL: 7,
  GATEWAY_STATUS_CANCELLED: 9,
};

export default ShippingBill;
