import TaxInvoice from "common/constants/TaxInvoice";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import { TaxInvoiceDetailJson } from "common/types/TaxInvoiceDetail";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { TaxInvoiceJson } from "common/types/TaxInvoice";
class TaxInvoiceModel
  extends BaseModel
  implements BaseModelJson<TaxInvoiceJson>
{
  company_id: number;
  creator_id: number;
  order_id: number;
  id: number;
  code: string;
  invoice_type: number;
  note: string;
  status: number;
  seller_store_id: number;
  seller_name: string;
  seller_address: string;
  seller_phone: string;
  seller_account_number: string;
  seller_tax_number: string;
  buyer_customer_id: number;
  buyer_name: string;
  buyer_address: string;
  buyer_phone: string;
  buyer_account_number: string;
  buyer_tax_number: string;
  buyer_email: string;
  buyer_receive_method: number;
  currency: string;
  payment_method: number;
  price_sell: number;
  price_tax: number;
  price_final: number;
  parent_invoice_id: number;
  issue_provider: number;
  issue_is_sandbox: number;
  issue_status: number;
  issue_transaction_id: string;
  issue_request: string;
  issue_response: string;
  issuer: number;
  issue_ymd: number;
  issue_note: string;
  date_created: number;
  date_modified: number;
  date_approved: number;
  date_issued: number;
  date_signed: number;
  details: TaxInvoiceDetailJson[];

  constructor(json: TaxInvoiceJson) {
    super();

    this.id = json.id || 0;
    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.order_id = json.order_id || 0;
    this.code = json.code || "";
    this.invoice_type = json.invoice_type || 0;
    this.note = json.note || "";
    this.status = json.status || 0;
    this.seller_store_id = json.seller_store_id || 0;
    this.seller_name = json.seller_name || "";
    this.seller_address = json.seller_address || "";
    this.seller_phone = json.seller_phone || "";
    this.seller_account_number = json.seller_account_number || "";
    this.seller_tax_number = json.seller_tax_number || "";
    this.buyer_customer_id = json.buyer_customer_id || 0;
    this.buyer_name = json.buyer_name || "";
    this.buyer_address = json.buyer_address || "";
    this.buyer_phone = json.buyer_phone || "";
    this.buyer_account_number = json.buyer_account_number || "";
    this.buyer_tax_number = json.buyer_tax_number || "";
    this.buyer_email = json.buyer_email || "";
    this.buyer_receive_method = json.buyer_receive_method || 0;
    this.currency = json.currency || "";
    this.payment_method = json.payment_method || 0;
    this.price_sell = json.price_sell || 0;
    this.price_tax = json.price_tax || 0;
    this.price_final = json.price_final || 0;
    this.parent_invoice_id = json.parent_invoice_id || 0;
    this.issue_provider = json.issue_provider || 0;
    this.issue_is_sandbox = json.issue_is_sandbox || 0;
    this.issue_status = json.issue_status || 0;
    this.issue_transaction_id = json.issue_transaction_id || "";
    this.issue_request = json.issue_request || "";
    this.issue_response = json.issue_response || "";
    this.issuer = json.issuer || 0;
    this.issue_ymd = json.issue_ymd || 0;
    this.issue_note = json.issue_note || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_approved = json.date_approved || 0;
    this.date_issued = json.date_issued || 0;
    this.date_signed = json.date_signed || 0;
    this.details = json.details || [];
  }
  static getDefaultData(): TaxInvoiceJson {
    return {
      id: 0,
      company_id: 0,
      creator_id: 0,
      order_id: 0,
      code: "",
      invoice_type: TaxInvoice.TYPE_EINVOICE,
      note: "",
      status: TaxInvoice.STATUS_DRAFT,
      seller_store_id: 0,
      seller_name: "",
      seller_address: "",
      seller_phone: "",
      seller_account_number: "",
      seller_tax_number: "",
      buyer_customer_id: 0,
      buyer_name: "",
      buyer_address: "",
      buyer_phone: "",
      buyer_account_number: "",
      buyer_tax_number: "",
      buyer_email: "",
      buyer_receive_method: TaxInvoice.BUYER_RECEIVE_METHOD_DIRECT,
      currency: "VND",
      payment_method: TaxInvoice.METHOD_CASH,
      price_sell: 0,
      price_tax: 0,
      price_final: 0,
      parent_invoice_id: 0,
      issue_provider: TaxInvoice.ISSUE_PROVIDER_WINTECH,
      issue_is_sandbox: 0,
      issue_status: TaxInvoice.ISSUE_STATUS_PENDING,
      issue_transaction_id: "",
      issue_request: "",
      issue_response: "",
      issuer: 0,
      issue_ymd: 0,
      issue_note: "",
      date_created: 0,
      date_modified: 0,
      date_approved: 0,
      date_issued: 0,
      date_signed: 0,
      details: [],
    };
  }

  toJson(): TaxInvoiceJson {
    return {
      id: this.id,
      company_id: this.company_id,
      creator_id: this.creator_id,
      order_id: this.order_id,
      invoice_type: this.invoice_type,
      code: this.code,
      note: this.note,
      status: this.status,
      seller_store_id: this.seller_store_id,
      seller_name: this.seller_name,
      seller_address: this.seller_address,
      seller_phone: this.seller_phone,
      seller_account_number: this.seller_account_number,
      seller_tax_number: this.seller_tax_number,
      buyer_customer_id: this.buyer_customer_id,
      buyer_name: this.buyer_name,
      buyer_address: this.buyer_address,
      buyer_phone: this.buyer_phone,
      buyer_account_number: this.buyer_account_number,
      buyer_tax_number: this.buyer_tax_number,
      buyer_email: this.buyer_email,
      buyer_receive_method: this.buyer_receive_method,
      currency: this.currency,
      payment_method: this.payment_method,
      price_sell: this.price_sell,
      price_tax: this.price_tax,
      price_final: this.price_final,
      parent_invoice_id: this.parent_invoice_id,
      issue_provider: this.issue_provider,
      issue_is_sandbox: this.issue_is_sandbox,
      issue_status: this.issue_status,
      issue_transaction_id: this.issue_transaction_id,
      issue_request: this.issue_request,
      issue_response: this.issue_response,
      issuer: this.issuer,
      issue_ymd: this.issue_ymd,
      issue_note: this.issue_note,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_approved: this.date_approved,
      date_issued: this.date_issued,
      date_signed: this.date_signed,
      details: [],
    };
  }

  static getInvoiceTypeList(): SelectOption[] {
    return [
      {
        value: TaxInvoice.TYPE_EINVOICE,
        label: i18n.t("taxinvoice:TYPE_EINVOICE"),
      },
      {
        value: TaxInvoice.TYPE_EINVOICE_POS,
        label: i18n.t("taxinvoice:TYPE_EINVOICE_POS"),
      },
    ];
  }

  static getInvoiceType(value: number): SelectOption | undefined {
    return this.getInvoiceTypeList().find((item) => item.value === value);
  }

  static getPaymentMethodList(): SelectOption[] {
    return [
      {
        value: TaxInvoice.METHOD_CASH,
        label: i18n.t("taxinvoice:METHOD_CASH"),
      },
      {
        value: TaxInvoice.METHOD_BANK,
        label: i18n.t("taxinvoice:METHOD_BANK"),
      },
    ];
  }

  static getPaymentMethod(value: number): SelectOption | undefined {
    return this.getPaymentMethodList().find((item) => item.value === value);
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: TaxInvoice.STATUS_DRAFT,
        label: i18n.t("taxinvoice:STATUS_DRAFT"),
        color: "yellow",
      },
      {
        value: TaxInvoice.STATUS_WAIT_FOR_APPROVAL,
        label: i18n.t("taxinvoice:STATUS_WAIT_FOR_APPROVAL"),
        color: "gray",
      },
      {
        value: TaxInvoice.STATUS_APPROVED,
        label: i18n.t("taxinvoice:STATUS_APPROVED"),
        color: "orange",
      },
      {
        value: TaxInvoice.STATUS_ISSUED,
        label: i18n.t("taxinvoice:STATUS_ISSUED"),
        color: "blue",
      },
      {
        value: TaxInvoice.STATUS_COMPLETED,
        label: i18n.t("taxinvoice:STATUS_COMPLETED"),
        color: "green",
      },
      {
        value: TaxInvoice.STATUS_CANCELLED,
        label: i18n.t("taxinvoice:STATUS_CANCELLED"),
        color: "red",
      },
      {
        value: TaxInvoice.STATUS_CLOSED,
        label: i18n.t("taxinvoice:STATUS_CLOSED"),
        color: "black",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getBuyerReceiveMethodList(): SelectOption[] {
    return [
      {
        value: TaxInvoice.BUYER_RECEIVE_METHOD_IGNORE,
        label: i18n.t("taxinvoice:BUYER_RECEIVE_METHOD_IGNORE"),
      },
      {
        value: TaxInvoice.BUYER_RECEIVE_METHOD_EMAIL,
        label: i18n.t("taxinvoice:BUYER_RECEIVE_METHOD_EMAIL"),
      },
      {
        value: TaxInvoice.BUYER_RECEIVE_METHOD_DIRECT,
        label: i18n.t("taxinvoice:BUYER_RECEIVE_METHOD_DIRECT"),
      },
      {
        value: TaxInvoice.BUYER_RECEIVE_METHOD_LATER,
        label: i18n.t("taxinvoice:BUYER_RECEIVE_METHOD_LATER"),
      },
      {
        value: TaxInvoice.BUYER_RECEIVE_METHOD_OTHER,
        label: i18n.t("taxinvoice:BUYER_RECEIVE_METHOD_OTHER"),
      },
    ];
  }

  static getBuyerReceiveMethod(value: number): SelectOption | undefined {
    return this.getBuyerReceiveMethodList().find(
      (item) => item.value === value
    );
  }

  static getIsSandboxList(): SelectOption[] {
    return [
      {
        value: 0,
        label: i18n.t("taxinvoice:IS_SANDBOX_NO"),
      },
      {
        value: 1,
        label: i18n.t("taxinvoice:IS_SANDBOX_YES"),
      },
    ];
  }

  static getIssueProviderList(): SelectOption[] {
    return [
      {
        value: TaxInvoice.ISSUE_PROVIDER_SELF,
        label: i18n.t("taxinvoice:ISSUE_PROVIDER_SELF"),
      },
      {
        value: TaxInvoice.ISSUE_PROVIDER_WINTECH,
        label: i18n.t("taxinvoice:ISSUE_PROVIDER_WINTECH"),
      },
    ];
  }

  static getIssueProvider(value: number): SelectOption | undefined {
    return this.getIssueProviderList().find((item) => item.value === value);
  }

  static getIssueStatusList(): SelectOption[] {
    return [
      {
        value: TaxInvoice.ISSUE_STATUS_PENDING,
        label: i18n.t("taxinvoice:ISSUE_STATUS_PENDING"),
        color: "",
      },
      {
        value: TaxInvoice.ISSUE_STATUS_WIP,
        label: i18n.t("taxinvoice:ISSUE_STATUS_WIP"),
        color: "yellow",
      },
      {
        value: TaxInvoice.ISSUE_STATUS_FAILED,
        label: i18n.t("taxinvoice:ISSUE_STATUS_FAILED"),
        color: "red",
      },
      {
        value: TaxInvoice.ISSUE_STATUS_CREATED,
        label: i18n.t("taxinvoice:ISSUE_STATUS_CREATED"),
        color: "blue",
      },
      {
        value: TaxInvoice.ISSUE_STATUS_CANCELLED,
        label: i18n.t("taxinvoice:ISSUE_STATUS_CANCELLED"),
        color: "orange",
      },
      {
        value: TaxInvoice.ISSUE_STATUS_SIGNED,
        label: i18n.t("taxinvoice:ISSUE_STATUS_SIGNED"),
        color: "green",
      },
    ];
  }

  static getIssueStatus(value: number): SelectOption | undefined {
    return this.getIssueStatusList().find((item) => item.value === value);
  }

  getRemoteUrl(): string {
    let url = "";
    switch (this.issue_provider) {
      case TaxInvoice.ISSUE_PROVIDER_WINTECH:
        if (this.issue_is_sandbox) {
          url = "http://demo.evat.vn/";
        } else {
          url = "https://quanly.wininvoice.vn/";
        }
        url +=
          "admin/w_invoice/edit?oid=" +
          encodeURIComponent(this.issue_transaction_id);
        break;
    }

    return url;
  }
}

export default TaxInvoiceModel;
