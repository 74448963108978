import CouponList from "features/promotion/list/CouponList";
import PromotionList from "features/promotion/list/PromotionList";
import usePathParams from "hooks/usePathParams";
import React from "react";

const PromotionPage = () => {
	const [pathParams] = usePathParams();

	let com = null;

	switch (pathParams.action) {
		case "edit":
			com = <CouponList></CouponList>;
			//   com =
			//   model.id > 0 ? (
			//     <EmployeeForm model={model} />
			//   ) : (
			//     <FormEditFetcher
			//       doLoad={async () => {
			//         let fetchedModel = await new EmployeeRepository().getItemFullDetail(
			//           parseInt(pathParams.id.toString())
			//         );
			//         if (fetchedModel.hasError()) {
			//           setFetchErrors(fetchedModel.error.errors);
			//         } else {
			//           setModel(fetchedModel);
			//         }
			//       }}
			//       errors={fetchErrors}
			//       heading={<EmployeeFormHeader isEditing={true} />}
			//     />
			//   );

			break;

		default:
			com = <PromotionList />;
	}

	return com;
};

export default PromotionPage;
