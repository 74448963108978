import {
	DeleteFilled,
	EditFilled,
	PlusCircleOutlined,
} from "@ant-design/icons";
import {
	Button,
	Checkbox,
	List,
	Modal,
	Popconfirm,
	Table,
	Typography,
} from "antd";
import React, { useMemo, useState } from "react";
import Divider from "antd";
import { TableColumnsType } from "common/types/Table";
import { PromotionJson } from "common/types/Promotion";
import { DealthomConfig } from "common/types/Promotion";
import usePromotion from "hooks/usePromotion";

type Props = {
	promotion_id: number[];
	idPromtionUsed: number[];
	onUpdate?: (data: Partial<DealthomConfig>) => Promise<unknown>;
};
export default function DealthomPromotionForm({
	promotion_id,
	onUpdate,
}: Props) {
	const {
		dataSource: dataPromotion,
		promotions,
		errors,
		fetchData,
		loading: loadingPromotion,
	} = usePromotion({});

	const { promotions: promotionIsUse } = usePromotion({
		defaultFilters: { list_id: promotion_id.join(",") },
		skip: promotion_id.length <= 0,
	});

	const [promtionIds, setPromtionIds] = useState<number[]>(promotion_id);

	const [openAdd, setOpenAdd] = useState(false);
	const [loading, setLoading] = useState(false);

	const columns: TableColumnsType<PromotionJson> = [
		{
			title: "Chọn",
			key: "",
			dataIndex: "",
			width: 100,
			render: (_, record) => {
				return (
					<Checkbox
						checked={promtionIds.some((col) => col === record.id)}
						onChange={(e) => {
							const value = e.target.checked;
							if (value) {
								setPromtionIds((pre) => [...pre, record.id]);
							} else {
								setPromtionIds((pre) => pre.filter((id) => id !== record.id));
							}
						}}
					/>
				);
			},
		},
		{
			title: "Tên chương trình",
			key: "name",
			dataIndex: "name",
			width: 100,
			render: (_, record) => {
				return <Typography.Text>{record.name}</Typography.Text>;
			},
		},
	];

	const onSubmit = () => {
		setLoading(true);
		onUpdate &&
			onUpdate({ promotion_id: promtionIds })
				.then((res) => {
					setOpenAdd(false);
				})
				.finally(() => {
					setLoading(false);
				});
	};

	const handleRemove = (item: PromotionJson) => {
		setPromtionIds((prev) => prev.filter((i) => i !== item.id));
		setLoading(true);

		onUpdate &&
			onUpdate({ promotion_id: promtionIds.filter((i) => i !== item.id) })
				.then((res) => {})
				.finally(() => {
					setLoading(false);
				});
	};

	//////////////////////////

	return (
		<div>
			<Button
				onClick={() => {
					setOpenAdd(true);
				}}
				icon={<PlusCircleOutlined />}
				type="primary">
				Cập nhập nhóm Ckhuyến mãi
			</Button>
			<List
				className="max-h-[70vh] overflow-y-auto"
				loading={loading}
				size="small"
				header={
					<>
						Đã áp dụng <strong>{promotionIsUse.length}</strong> chương trình
					</>
				}
				dataSource={promotionIsUse}
				renderItem={(item: PromotionJson, index) => {
					return (
						<List.Item
							actions={[
								<Popconfirm
									placement="topRight"
									title={<>Bạn chắc chắn muốn xóa ?</>}
									description={"Gỡ chương trình khỏi cài đặt "}
									okText="Đồng ý"
									cancelText="Hủy"
									onConfirm={() => handleRemove(item)}>
									{" "}
									<DeleteFilled className="cursor-pointer"></DeleteFilled>
								</Popconfirm>,
							]}>
							<List.Item.Meta
								description={item.description}
								title={
									<Typography.Text mark>
										[{item.code}] {item.name}
									</Typography.Text>
								}>
								{" "}
							</List.Item.Meta>
						</List.Item>
					);
				}}></List>
			<Modal
				destroyOnClose
				title="Cập nhật danh sách khuyễn mãi áp dụng"
				open={openAdd}
				onCancel={() => {
					setOpenAdd(false);
				}}
				okButtonProps={{ className: "hidden" }}>
				<p>
					Đã chọn {promtionIds.length} / {dataPromotion?.total} Khuyến mãi
				</p>
				<Table
					loading={loading || loadingPromotion}
					size="small"
					className="table-dashboard"
					dataSource={dataPromotion?.items}
					columns={columns}
					rowKey={"id"}
					bordered
					pagination={{
						pageSizeOptions: [10, 20],
						hideOnSinglePage: true,
						total: dataPromotion?.total,
						onChange(page, pageSize) {
							fetchData({ page: page, limit: pageSize });
						},
					}}
					footer={() => (
						<div className=" flex justify-end">
							<Button disabled={loading} onClick={onSubmit} type="primary">
								Lưu
							</Button>
						</div>
					)}
				/>
			</Modal>
		</div>
	);
}
