import { Button, Tag, Tooltip, message } from "antd";
import { TableColumnsType } from "common/types/Table";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import BrandCollection from "common/collections/BrandCollection";
import BrandModel from "common/models/BrandModel";
import BrandRepository from "common/repositories/BrandRepository";
import { FilterBrand } from "common/types/Brand";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import { useNavigate } from "react-router-dom";
import BrandListFilter from "./BrandListFilter";
import BrandListHeader from "./BrandListHeader";
import TableDelete from "components/table/TableDelete";
import { IconTag } from "@tabler/icons-react";
import BrandTagModal from "../tag/BrandTagModal";

const BrandList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // default filter
  const defaultFilters = useMemo(() => {
    return BrandRepository.getDefaultFilters();
  }, []);
  //////////////////////////////////////////
  //Fetch data from this collections
  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [dataSource, setDataSource] = useState<BrandModel[]>([]);
  const [total, setTotal] = useState<number>(0);

  //modal tag
  const [addTagVisible, setAddTagVisible] = useState(false);
  const [editingTagId, setEditingTagId] = useState(0);

  const fetchData = useCallback(async (): Promise<BrandCollection> => {
    const response = await new BrandRepository().getItems({
      filters: filters,
    });
    if (!response.hasError()) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      message.error("common:error.error_fetching_data");
    }

    return response;
  }, [filters]);

  // modal editing

  const renderStatus = (value: number) => {
    switch (value) {
      case 1:
        return <Tag color="green">Hiện</Tag>;
      case 2:
        return <Tag>Nháp</Tag>;
      case 3:
        return <Tag color="red">Ẩn</Tag>;
      default:
        return null;
    }
  };

  const onEditTag = (id: number) => {
    setEditingTagId(id);
    setAddTagVisible(true);
  };

  //Table columns
  const columns: TableColumnsType<BrandModel> = [
    {
      title: "ID",
      key: "id",
    },
    {
      title: t("brand:title"),
      key: "title",
    },

    {
      title: t("brand:status"),
      key: "status",
      render(value, record, index) {
        return renderStatus(value);
      },
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 200,
      render: (_: any, record: BrandModel) => (
        <RoleCheck roles={[]} hideOnFail>
          <TableEdit
            key="edit"
            link=""
            onClick={() => {
              navigate(`/brand/edit/id/${record.id}`);
            }}
          />
          <Button
            size={"small"}
            type={"text"}
            onClick={() => onEditTag(record.id)}
          >
            <Tooltip title="Quản lý tag">
              <IconTag
                className="-mt-0.5 text-gray-400 hover:text-red-500"
                size="18"
              />
            </Tooltip>
          </Button>
          <TableDelete
            error_translate_prefix="brand:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new BrandRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const onSaveSuccess = () => {
    fetchData();
  };

  return (
    <>
      <BrandListHeader>
        <Button
          type="primary"
          onClick={() => {
            navigate(`/brand/add`);
          }}
        >
          {t("common:table.add_button")}
        </Button>
      </BrandListHeader>

      <BrandListFilter
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        total={total}
      />

      <PageDataTable<FilterBrand, BrandModel, BrandCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "brand",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <BrandTagModal
        id={editingTagId}
        key={editingTagId}
        open={addTagVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingTagId(0);
          }
          setAddTagVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default BrandList;
