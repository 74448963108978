import ShippingRouteCollection from "common/collections/ShippingRouteCollection";
import ShippingRouteRepository from "common/repositories/ShippingRouteRepository";
import { FilterShippingRoute } from "common/types/ShippingRoute";
import LayoutTitle from "components/LayoutTitle";
import PageSiteMenu from "components/page/PageSiteMenu";
import FilterProvider from "contextproviders/FilterProvider";
import FilterContext from "contexts/FilterContext";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DeliveryDashboardContainer from "./DeliveryDashboardContainer";
import DeliveryDashboardFilter from "./DeliveryDashboardFilter";

const DeliveryDashboard = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShippingRoute = useMemo(
    () => ShippingRouteRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterShippingRoute>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [collection, setCollection] = useState<ShippingRouteCollection>();

  const fetchData = useCallback(async (): Promise<ShippingRouteCollection> => {
    let collection = await new ShippingRouteRepository().getItems({
      filters: { ...filters, get_full_info: true },
    });
    setCollection(collection);

    return collection;
  }, [filters]);

  //fetch data when change fetchData() (trigger by change filters from parent ^^!)
  useEffect(() => {
    (async () => {
      //call parent fetchData()
      await fetchData();
    })();
  }, [fetchData]);

  return (
    <>
      <LayoutTitle title={t("deliverydashboard:heading_list")} />
      <PageSiteMenu siteMenuSelectedKey="/deliverydashboard" siteMenuHidden />

      <DeliveryDashboardFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />

      <FilterContext.Provider value={new FilterProvider(filters, setFilters)}>
        <DeliveryDashboardContainer collection={collection} />
      </FilterContext.Provider>
    </>
  );
};

export default DeliveryDashboard;
