import ProductInventoryCheckingDetailCollection from "common/collections/ProductInventoryCheckingDetailCollection";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterProductInventoryCheckingDetail,
  ProductInventoryCheckingDetailJsonEdit,
  ProductInventoryCheckingDetailJsonAdd,
} from "common/types/ProductInventoryCheckingDetail";

const SERVICE_URL = "/productinventorycheckingdetails";

class ProductInventoryCheckingDetailApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProductInventoryCheckingDetail>,
  ): Promise<ProductInventoryCheckingDetailCollection> {
    let collection = new ProductInventoryCheckingDetailCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          product_inventory_checking_id:
            filters.product_inventory_checking_id > 0
              ? filters.product_inventory_checking_id
              : 0,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ProductInventoryCheckingDetailModel> {
    let item = new ProductInventoryCheckingDetailModel(
      ProductInventoryCheckingDetailModel.getDefaultData(),
    );

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ProductInventoryCheckingDetailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }

      
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(
    data: ProductInventoryCheckingDetailJsonAdd,
  ): Promise<ProductInventoryCheckingDetailModel> {
    let item = new ProductInventoryCheckingDetailModel(
      ProductInventoryCheckingDetailModel.getDefaultData(),
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProductInventoryCheckingDetailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async addMany(
    datas: ProductInventoryCheckingDetailJsonAdd[],
  ): Promise<ProductInventoryCheckingDetailModel> {
    let item = new ProductInventoryCheckingDetailModel(
      ProductInventoryCheckingDetailModel.getDefaultData(),
    );

    try {
      let postData = {
        ...datas,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProductInventoryCheckingDetailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(
    data: ProductInventoryCheckingDetailJsonEdit,
  ): Promise<ProductInventoryCheckingDetailModel> {
    let item = new ProductInventoryCheckingDetailModel(
      ProductInventoryCheckingDetailModel.getDefaultData(),
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new ProductInventoryCheckingDetailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ProductInventoryCheckingDetailApi;
