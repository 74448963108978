import SiteLanguageRepository from 'common/repositories/SiteLanguageRepository';
import { SiteLanguageItem } from 'common/types/SiteLanguage';
import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

type SiteLanguageStore = {
  language: SiteLanguageItem | undefined;
  setLanguage: (language: SiteLanguageItem) => void;
};

//init store data
let store: StateCreator<SiteLanguageStore> = (set) => ({
  language: SiteLanguageRepository.getAvailableLanguages()[0],
  setLanguage: (language) => set((state) => ({ ...state, language })),
});

//create store
const useSiteLanguageStore = create(
  persist(store, { name: "MAIN::SiteLanguage" })
);

export default useSiteLanguageStore;
