import { Alert } from "antd";
import PurchaseOrder from "common/constants/PurchaseOrder";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import FormSelect from "components/form/FormSelect";
import SimpleFormSelect from "components/form/SimpleFormSelect";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

const PurchaseOrderFormSectionStatus = ({
  allowStatusComplete,
  allowStatusCancel,
  status,
  setStatus,
  allowEditInput,
}: {
  allowStatusComplete: boolean;
  allowStatusCancel: boolean;
  status: number;
  setStatus: (v: number) => void;
  allowEditInput: boolean;
}) => {
  const { t } = useTranslation();

  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );

  const backgroundColor = useMemo(() => {
    const statusInfo = PurchaseOrderModel.getStatus(status);
    let color = statusInfo?.color;

    return color;
  }, [status]);

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="px-4 py-2 -m-6 mb-0"
    >
      <SimpleFormSelect
        value={status}
        label={<span className="text-white">{t("purchaseorder:status")}</span>}
        size="large"
        options={PurchaseOrderModel.getStatusList().map((i) => {
          if (i.value === PurchaseOrder.STATUS_COMPLETED) {
            return allowStatusComplete ? i : { ...i, disabled: true };
          } else if (i.value === PurchaseOrder.STATUS_CANCELLED) {
            return allowStatusCancel ? i : { ...i, disabled: true };
          } else {
            return {
              ...i,
              disabled:
                i.value < purchaseOrder.status || !allowEditInput
                  ? true
                  : false,
            };
          }
        })}
        className="mb-2"
        onChange={(v) => (typeof v === "number" ? setStatus(v) : null)}
      />

      <div
        className={
          "" + (status !== PurchaseOrder.STATUS_CANCELLED ? " hidden" : "")
        }
      >
        <FormSelect
          showSearch
          label={t("purchaseorder:cancel_reason")}
          name="cancel_reason"
          options={PurchaseOrderModel.getCancelReasonList()}
        />
        <div className="mb-2">
          <Alert
            showIcon
            type="warning"
            message={t("purchaseorder:cancel_dependency_before_note_heading")}
            description={t("purchaseorder:cancel_dependency_before_note")}
          ></Alert>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderFormSectionStatus;
