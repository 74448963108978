const CmsPost = {
  TYPE_SINGLE_PAGE: 1,
  TYPE_EMBED_CONTENT: 3,

  STATUS_ENABLED: 1,
  STATUS_DISABLED: 3,

  POST_ABOUTUS: 1,
  POST_CHAT: 2,
  POST_SUPPORT: 3,
  POST_DELIVERYPOLICY: 4,
};

export default CmsPost;
