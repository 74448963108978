import EcomChannelProductApi from "common/api/EcomChannelProductApi";
import EcomChannelProductCollection from "common/collections/EcomChannelProductCollection";
import EcomSyncProductCollection from "common/collections/EcomSyncProductCollection";
import EcomSyncProductModel from "common/models/EcomSyncProductModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type { FilterEcomChannelProduct } from "common/types/EcomChannelProduct";

class EcomChannelProductRepository extends BaseRepository<EcomChannelProductApi> {
  _api: EcomChannelProductApi | null;

  constructor() {
    super();
    this._api = new EcomChannelProductApi(true);
  }

  static getDefaultFilters(): FilterEcomChannelProduct {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
    };
  }

  async getItems(
    props: GetListProps<FilterEcomChannelProduct>,
    channel_id: number
  ) {
    return this._api
      ? await this._api.getList(props, channel_id)
      : new EcomChannelProductCollection();
  }

  async findLinkedVariant(channel_id: number, skuList: string[]) {
    return this._api
      ? await this._api.findLinkedVariant(channel_id, skuList)
      : new EcomSyncProductCollection();
  }

  async linkByProductSku(channel_id: number, product_id: number, sku: string) {
    return this._api
      ? await this._api.linkByProductSku(channel_id, product_id, sku)
      : new EcomSyncProductModel(EcomSyncProductModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default EcomChannelProductRepository;
