import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const DeliveryAccountantListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("delivery:heading_accountant")}
      siteMenuSelectedKey="/delivery/accountant"
    >
 
    </PageHeader>
  );
};

export default DeliveryAccountantListHeader;
