import {
  CheckSquareOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Popconfirm,
  Row,
  Tag,
  Typography,
  message,
} from "antd";
import CommentModel from "common/models/CommentModel";
import CommentRepository from "common/repositories/CommentRepository";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CommentJsonAddEditNew } from "common/types/Comment";
import TextArea, { TextAreaRef } from "antd/lib/input/TextArea";
import EmployeeModel from "common/models/EmployeeModel";
import { useTranslation } from "react-i18next";
import { IconCheck, IconX } from "@tabler/icons-react";
import { divide } from "lodash";
type Props = {
  modelItem: CommentModel;
  handleEditSuccess: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
  employees: EmployeeModel[];
  className?: string;
};
const ProcessCommentItem = ({
  modelItem,
  handleEdit,
  handleDelete,
  handleEditSuccess,
  employees,
  className,
}: Props) => {
  const [messageApi, Context] = message.useMessage();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  ////////////////////////
  const [model, setModel] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [is_edit, setIs_edit] = useState(false);

  //
  const TextAreaNodeRef: React.Ref<TextAreaRef> | undefined = useRef(null);
  ////////////////////////
  const ininitalValue = useMemo(() => {
    return {
      detail: modelItem.detail,
    };
  }, [modelItem]);

  ////////////////////////
  const doPrepareData = useCallback(
    (formData: CommentJsonAddEditNew) => {
      const submitData: CommentJsonAddEditNew = {
        // images: model.images.map((i) => i.id).join(","),
        ...formData,
        detail: formData.detail,
        id: modelItem.id,
      };
      return submitData;
    },
    [modelItem.id]
  );

  ////////////////////////

  const handleError = ({ errors }: { errors: string[] }) => {
    errors.length > 0 &&
      messageApi.open({
        content: "Có lỗi thao tác thất bại !",
        type: "error",
      });

    errors.length === 0 &&
      messageApi.open({
        content: "Thao tác thành công  !",
        type: "success",
      });
  };

  const handleApproveItem = async ({ status }: { status: 1 | 2 }) => {
    setLoading(true);
    const res = await new CommentRepository().edit(
      doPrepareData({
        status: status,
        id: modelItem.id,
      })
    );
    if (res.hasError()) {
      handleError({ errors: res.error.errors });
    } else {
      handleError({ errors: [] });
      handleEditSuccess && handleEditSuccess();
    }
    setOpenApprove(false);
    setLoading(false);
  };

  const handleDeleteItem = async () => {
    const res = await new CommentRepository().deleteItem(modelItem.id);

    if (res.length > 0) {
      handleError({ errors: res });
    } else {
      handleError({ errors: [] });
      handleEditSuccess && handleEditSuccess();
    }
    setOpenDelete(false);
    setLoading(false);
  };

  const handleEditDetail = () => {
    if (TextAreaNodeRef && TextAreaNodeRef.current !== null) {
      TextAreaNodeRef.current.focus();
    }
    form.submit();
  };

  const onSubmitForm = async (formData: any) => {
    setLoading(true);
    const response = await new CommentRepository().edit(
      doPrepareData(formData)
    );
    if (response.hasError()) {
      handleError({ errors: response.error.errors });
    } else {
      handleError({ errors: [] });
      handleEditSuccess && handleEditSuccess();
      setIsSubmit(false);
    }
    setOpenEdit(false);
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      detail: modelItem.detail,
    });
  }, [modelItem]);

  // if (document.activeElement !== TextAreaNodeRef.current) {
  //   setIs_edit(false);
  // }
  return (
    <>
      {Context}
      <List.Item className={className} actions={[]}>
        <Form
          form={form}
          initialValues={ininitalValue}
          onFinish={onSubmitForm}
          className="my-2"
        >
          <Form.Item
            name="detail"
            rules={[
              {
                required: true,
                message: "Nội dung không được để trống !",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  return new Promise((resolve, reject) => {
                    if (value === modelItem.detail) {
                      reject(new Error("Nội dung chưa có gì thay đổi !"));
                    } else {
                      resolve(1);
                    }
                  });
                },
              }),
            ]}
          >
            <TextArea
              onChange={(e) => {
                if (e.target.value !== modelItem.detail) {
                  setIsSubmit(true);
                } else {
                  setIsSubmit(false);
                }
              }}
              // disabled={!is_edit}
              maxLength={800}
              showCount={true}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              ref={TextAreaNodeRef}
              // defaultValue={ininitalValue.detail}
            >
              {/* <Typography.Text
              style={{
                display: "inline-block",
              }}
              type="secondary"
            >
              {modelItem.detail}
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
              illo eveniet nobis amet ad. Dolorum rem labore repellendus
              nesciunt hic! Minima nobis assumenda at, eaque dolores maiores
              nulla architecto quisquam.
            </Typography.Text> */}
            </TextArea>
          </Form.Item>
          <div className="flex flex-col xl:flex-row gap-4 justify-between mt-7">
            <div className="flex items-center gap-1 mb-1">
              <Tag color={modelItem.status === 2 ? "magenta" : "green"}>
                {modelItem.status === 2
                  ? t("comment:model.not_approved")
                  : t("comment:model.approved")}
              </Tag>
              <Tag color="blue"># {modelItem.id}</Tag>
              {employees.length > 0 ? (
                <Tag>
                  {employees.filter((i) => i.id === modelItem.employee_id)[0]
                    ?.full_name || "---"}
                </Tag>
              ) : (
                modelItem && <Tag> {modelItem.name}</Tag>
              )}
            </div>
            <div className="flex gap-2 items-center justify-end">
              {/* <Popconfirm
                  visible={openDelete}
                  onConfirm={handleDeleteItem}
                  onCancel={() => {
                    setOpenDelete(false);
                  }}
                  title="Xác nhận xóa"
                  okButtonProps={{ loading: loading }}
                >
                  <Button
                    htmlType="button"
                    size="small"
                    type="default"
                    onClick={() => {
                      setOpenDelete(true);
                    }}
                  >
                    <DeleteOutlined style={{ marginRight: 4 }} />
                    Xóa
                  </Button>
                </Popconfirm> */}

              <Button
                size="small"
                type="primary"
                ghost
                // htmlType="submit"
                htmlType="button"
                onClick={handleEditDetail}
              >
                <EditOutlined title="edit" style={{ marginRight: 4 }} />
                {isSubmit ? t("comment:form.button.confirm") : "Sửa"}
              </Button>
              <Popconfirm
                trigger={["hover"]}
                open={openApprove}
                title={`Xác nhận ${
                  modelItem.status === 1
                    ? t("comment:form.button.cancel_approved")
                    : t("comment:form.button.approved")
                }`}
                onConfirm={() =>
                  handleApproveItem({
                    status: modelItem.status === 1 ? 2 : 1,
                  })
                }
                onCancel={() => {
                  setOpenApprove(false);
                }}
                okButtonProps={{ loading: loading }}
              >
                <Button
                  onClick={() => {
                    setOpenApprove(true);
                  }}
                  size="small"
                  type="primary"
                  htmlType="button"
                  style={{
                    backgroundColor:
                      modelItem.status === 1 ? "#ef4444" : "#22c55e",
                    border: modelItem.status === 1 ? "#ef4444" : "#22c55e",
                  }}
                  className="flex items-center gap-1"
                >
                  {modelItem.status === 1 ? (
                    <IconX size={16} color="white" />
                  ) : (
                    <IconCheck size={16} color="white" />
                  )}
                  {modelItem.status === 1
                    ? t("comment:form.button.cancel_approved")
                    : t("comment:form.button.approved")}
                </Button>
              </Popconfirm>
            </div>
          </div>
        </Form>
      </List.Item>
    </>
  );
};

export default ProcessCommentItem;
