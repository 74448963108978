import { MatrixArray } from "common/types/Delivery";
const Delivery = {

  STATUS_SUCCESS: 9,
  STATUS_NEW: 1,
  STATUS_DELIVER: 2,
  STATUS_CANCEL: 11,
  //shipper
  ACTION_CREATE: "ACTION_CREATE",
  ACTION_START: "ACTION_START",
  ACTION_COMPLETE: "ACTION_COMPLETE",
  ACTION_ARRIVED: "ACTION_ARRIVED",
  ACTION_PAUSE: "ACTION_PAUSE",
  ACTION_CANCEL: "ACTION_CANCEL",
  ACTION_AGAIN: "ACTION_AGAIN",
  ACTION_TRANSFER: "ACTION_TRANSFER",
  ACTION_RECEIVE_COD: "ACTION_RECEIVE_COD",
  ACTION_PAYMENT_COD: "ACTION_PAYMENT_COD",
  ACTION_TIPS: "ACTION_TIPS",
  ACTION_DELIVERED: "ACTION_DELIVERED",
  ACTION_ADDRESS: "ACTION_ADDRESS",
  ACTION_RETURNED: "ACTION_RETURNED",
  ACTION_WAITING: "ACTION_WAITING",
  ACTION_NEW: "ACTION_NEW",
  //accountant
  ACTION_APPROVE_TRANSFERS: "ACTION_APPROVE_TRANSFERS",
  ACTION_APPROVE_TIPS: "ACTION_APPROVE_TIPS",
  ACTION_APPROVE_RECEIVE_COD: "ACTION_APPROVE_RECEIVE_COD",
  ACTION_REFUND: "ACTION_REFUND",
  ACTION_APPROVE_REFUND: "ACTION_APPROVE_REFUND",

  // payment method
  METHOD_CASH: 1,
  METHOD_BANK: 3,
  METHOD_GIFTCARD: 5,
  METHOD_CUSTOMER_POINT: 7,
  METHOD_DEBIT_CREDIT: 9,
  METHOD_COD: 11,
  METHOD_MOMO: 13,
  METHOD_VNPAY: 15,
  METHOD_MEMBERCARD: 17,


  PARTNER_NSHIP: 1,
  PARTNER_VNPOST: 10,

  // matrix action
  //  [nowAction , showImageUpload , locationRequire , noteRequire, NextActions]

  FULL_MATRIX_ACTION: [
    [
      "ACTION_WAITING",
      false,
      true,
      false,
      ["ACTION_CREATE", "ACTION_APPROVE_TRANSFERS"],
    ],

    [
      "ACTION_CREATE",
      true,
      true,
      false,
      [
        "ACTION_START",
        "ACTION_ADDRESS",
        "ACTION_REFUND",
        "ACTION_APPROVE_TRANSFERS",
      ],
    ],
    [
      "ACTION_PAUSE",
      false,
      true,
      true,
      [
        "ACTION_AGAIN",
        "ACTION_CANCEL",
        "ACTION_ADDRESS",
        "ACTION_APPROVE_TRANSFERS",
      ],
    ],
    [
      "ACTION_START",
      false,
      true,
      false,
      [
        "ACTION_PAUSE",
        "ACTION_CANCEL",
        "ACTION_ARRIVED",
        "ACTION_ADDRESS",
        "ACTION_APPROVE_TRANSFERS",
      ],
    ],
    [
      "ACTION_AGAIN",
      false,
      true,
      false,
      [
        "ACTION_PAUSE",
        "ACTION_CANCEL",
        "ACTION_ARRIVED",
        "ACTION_ADDRESS",
        "ACTION_APPROVE_TRANSFERS",
      ],
    ],
    ["ACTION_CANCEL", false, true, true, [""]],
    [
      "ACTION_ARRIVED",
      true,
      true,
      false,
      [
        "ACTION_PAUSE",
        "ACTION_CANCE",
        "ACTION_RECEIVE_COD",
        "ACTION_ADDRESS",
        "ACTION_REFUND",
        "ACTION_APPROVE_TRANSFERS",
      ],
    ],
    ["ACTION_RECEIVE_COD", false, true, true, [""]],
    ["ACTION_ADDRESS", false, true, true, ["ACTION_APPROVE_TRANSFERS"]],
    ["ACTION_APPROVE_TIPS", false, false, false, [""]],
    ["ACTION_REFUND", false, true, true, ["ACTION_APPROVE_TRANSFERS"]],

    // Accoutant
    ["ACTION_APPROVE_RECEIVE_COD", false, false, false, ["ACTION_COMPLETE"]],
    ["ACTION_COMPLETE", false, false, false, [""]],
    ["ACTION_APPROVE_TRANSFERS", false, false, true, [""]],
    ["ACTION_TIPS", false, false, true, [""]],
  ] as MatrixArray,
  //
  SHIPPER_MATRIX_ACTION: [
    ["ACTION_WAITING", false, true, false, ["ACTION_CREATE"]],

    [
      "ACTION_CREATE",
      true,
      true,
      false,
      ["ACTION_START", "ACTION_ADDRESS", "ACTION_REFUND"],
    ],
    [
      "ACTION_PAUSE",
      false,
      true,
      true,
      ["ACTION_AGAIN", "ACTION_REFUND", "ACTION_ADDRESS"],
    ],
    [
      "ACTION_START",
      false,
      true,
      false,
      ["ACTION_PAUSE", "ACTION_REFUND", "ACTION_ARRIVED", "ACTION_ADDRESS"],
    ],
    [
      "ACTION_AGAIN",
      false,
      true,
      false,
      ["ACTION_PAUSE", "ACTION_REFUND", "ACTION_ARRIVED", "ACTION_ADDRESS"],
    ],
    ["ACTION_CANCEL", false, true, true, [""]],
    [
      "ACTION_ARRIVED",
      true,
      true,
      false,
      [
        "ACTION_PAUSE",
        "ACTION_CANCE",
        "ACTION_RECEIVE_COD",
        "ACTION_ADDRESS",
        "ACTION_REFUND",
      ],
    ],
    ["ACTION_RECEIVE_COD", false, true, true, [""]],
    ["ACTION_ADDRESS", false, true, true, [""]],
    ["ACTION_REFUND", false, true, true, ""],
  ] as MatrixArray,

  MANAGER_MAXTRIX_ACTION: [
    ["ACTION_NEW", false, false, false, ["ACTION_WAITING"]],
    ["ACTION_WAITING", false, true, false, ["ACTION_CREATE"]],
    [
      "ACTION_RETURNED",
      false,
      false,
      true,
      ["ACTION_CANCEL", "ACTION_WAITING"],
    ],
    [
      "ACTION_APPROVE_REFUND",
      false,
      false,
      true,
      ["ACTION_CANCEL", "ACTION_WAITING"],
    ],
    ["ACTION_REFUND", false, false, true, ["ACTION_APPROVE_REFUND"]],
    ["ACTION_CANCEL", false, true, true, [""]],
  ] as MatrixArray,
};

export default Delivery;
