import { Tag } from "antd";
import ProductReceiptCollection from "common/collections/ProductReceiptCollection";
import Role from "common/constants/Role";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import ProductReceiptRepository from "common/repositories/ProductReceiptRepository";
import { FilterProductReceipt } from "common/types/ProductReceipt";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextProductReceiptDirection from "components/TextProductReceiptDirection";
import ProductReceiptListHeader from "features/productreceipt/list/ProductReceiptListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductReceiptItemEdit from "../item/ProductReceiptItemEdit";
import ProductReceiptListFilter from "./ProductReceiptListFilter";

const ProductReceiptList = () => {
  const { t } = useTranslation();

  const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
    "warehouse",
    Role.INVENTORY_VIEW
  );

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterProductReceipt = useMemo(
    () => ProductReceiptRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterProductReceipt>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ProductReceiptModel[]>([]);
  const fetchData = useCallback(async (): Promise<ProductReceiptCollection> => {
    let collection = await new ProductReceiptRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  const typeList = ProductReceiptModel.getTypeList();

  //Table columns
  const columns: TableColumnsType<ProductReceiptModel> = [
    {
      title: t("productreceipt:id"),
      key: "id",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <TableLinkText link={"/productreceipt/edit/id/" + record.id}>
              {record.id}
            </TableLinkText>
          </>
        );
      },
    },
    {
      title: t("productreceipt:direction"),
      key: "direction",
      render: (direction: number) => {
        return <TextProductReceiptDirection direction={direction} />;
      },
      width: 80,
    },
    {
      title: t("productreceipt:warehouse_id"),
      key: "warehouse_id",
      width: 150,
      render: (warehouse_id: number) => {
        const found = warehouseItemsWithLimit.find(
          (i) => i.id === warehouse_id
        );
        if (typeof found !== "undefined") {
          return <span>{found.name}</span>;
        } else {
          return "ID #" + warehouse_id;
        }
      },
    },
    {
      title: t("productreceipt:type"),
      key: "type",
      render: (type: number) => {
        return <>{typeList.find((i) => i.value === type)?.label}</>;
      },
    },
    {
      title: t("productreceipt:order"),
      key: "sale_order_id",
      width: 130,
      render: (_, record) => {
        return (
          <>
            {record.sale_order_id > 0 ? (
              <TableLinkText
                link={"/order/edit/id/" + record.sale_order_id}
                title={t("productreceipt:sale_order")}
                target="_blank"
              >
                {t("productreceipt:sale_order_short")} #{record.sale_order_id}
              </TableLinkText>
            ) : null}
          </>
        );
      },
    },
    {
      title: t("common:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = ProductReceiptModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 120,
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 100,
      render: (ts: number) => <TextDateTime format="HH:mm, DD/MM" ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 95,
      render: (_: any, record: ProductReceiptModel) => (
        <>
          <ProductReceiptItemEdit model={record} />
          <TableInfo record={record}></TableInfo>
        </>
      ),
    },
  ];
  return (
    <>
      <ProductReceiptListHeader />

      <ProductReceiptListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<
        FilterProductReceipt,
        ProductReceiptModel,
        ProductReceiptCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "productreceipt",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ProductReceiptList;
