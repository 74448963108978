import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import { WarehouseJson } from "common/types/Warehouse";
import TextMoney from "components/TextMoney";
import ReportChartCategory from "features/report/chart/ReportChartCategory";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback } from "react";

const ReportInventoryOutputWarehouse = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const [warehouseItemsWithLimit] =
    useDatabaseTable<WarehouseJson>("warehouse");
  const formatterWarehouseValue = useCallback(
    (value: number) => {
      return warehouseItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [warehouseItemsWithLimit]
  );
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Số lượng sản phẩm xuất theo nhà kho"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              dataSelect="sum(quantity), productreceipt_warehouse"
              dataFilter="productreceipt_direction = 1,productreceipt_status = 9"
              dataGroupBy="productreceipt_warehouse"
              dataOrderBy="sum(quantity) desc"
              dataJoin="productreceipt"
              keyMapping={{
                name: "productreceipt_warehouse",
                value: "sum_quantity",
                formatter: (value: number) => {
                  return formatterWarehouseValue(value) || "--";
                }
              }}
              height={350}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo xuất kho theo nhà kho"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              keyMapping={[
                {
                  title: "Nhà kho",
                  name: "productreceipt_warehouse",
                  formatter: (value: number | string) => {
                    return <>{formatterWarehouseValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng số sản phẩm",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(quantity)"
                },
                {
                  title: "Phiếu xuất",
                  name: "countdistinct_receipt",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "countdistinct(receipt)"
                }
              ]}
              dataSelect="countdistinct(receipt), productreceipt_warehouse, sum(quantity)"
              dataFilter="productreceipt_direction = 1,productreceipt_status = 9"
              dataGroupBy="productreceipt_warehouse"
              dataOrderBy="sum(quantity) DESC"
              dataJoin="productreceipt"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportInventoryOutputWarehouse;
