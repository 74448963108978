import BaseCollection from "./BaseCollection";

import StoryModel from "common/models/StoryModel";
import { StoryJson, StoryCollectionJson } from "common/types/Story";

class StoryCollection extends BaseCollection<
  StoryJson,
  StoryModel,
  StoryCollectionJson
> {
  itemsFromJson(jsonItems: StoryJson[]): StoryModel[] {
    return jsonItems.map((item) => new StoryModel(item));
  }
}
export default StoryCollection;
