import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import TaxInvoiceRepository from "common/repositories/TaxInvoiceRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import TaxInvoiceForm from "features/taxinvoice/form/TaxInvoiceForm";
import TaxInvoiceFormHeader from "features/taxinvoice/form/TaxInvoiceFormHeader";
import TaxInvoiceList from "features/taxinvoice/list/TaxInvoiceList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const TaxInvoicePage = () => {
  const [pathParams] = usePathParams();

  const [model, setModel] = useState<TaxInvoiceModel>(
    new TaxInvoiceModel(TaxInvoiceModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new TaxInvoiceModel(TaxInvoiceModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "edit":
      com =
        model.id > 0 ? (
          <TaxInvoiceForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new TaxInvoiceRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={
              <TaxInvoiceFormHeader isEditing={true} id={+pathParams.id} />
            }
          />
        );

      break;
    default:
      com = <TaxInvoiceList />;
  }

  return com;
};

export default TaxInvoicePage;
