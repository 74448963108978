import ShippingOrder from "common/constants/ShippingOrder";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import { GoongMapCoordinates } from "common/types/goongmap/GoongMapType";
import eventEmitter from "common/utils/eventEmitter";
import MapMarkersOffice from "images/mapmarkers/office.png";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import DeliveryDashboardSectionRightGoongMapMarker from "./DeliveryDashboardSectionRightGoongMapMarker";

const DeliveryDashboardSectionRightMapMarkerOffice = ({
  setActivePopupInfo,
  setInfoWindow,
  setOffsetTop,
  setOffsetLeft,
}: {
  setActivePopupInfo: (v: GoongMapCoordinates) => void;
  setInfoWindow: (v: React.ReactNode) => void;
  setOffsetTop: (v: number) => void;
  setOffsetLeft: (v: number) => void;
}) => {
  const eventEmitterRef = useRef<any>();

  const getDefaultGoongMapCoordinates = useMemo(() => {
    return ShippingRouteModel.getDefaultGoongMapCoordinates();
  }, []);

  const [activeRoute, hubItemByActiveRoute] = useDeliveryDashboardStore(
    (state) => [state.activeRoute, state.hubItemByActiveRoute]
  );

  const getCoordinates = useCallback(() => {
    let coordinates: GoongMapCoordinates = getDefaultGoongMapCoordinates;

    if (activeRoute && activeRoute.id > 0) {
      if (
        activeRoute.from_type === ShippingOrder.FROM_TYPE_OFFICE &&
        hubItemByActiveRoute !== undefined &&
        hubItemByActiveRoute.lat > 0
      ) {
        coordinates.latitude = hubItemByActiveRoute.lat;
        coordinates.longitude = hubItemByActiveRoute.long;
      }
    }

    return coordinates;
  }, [activeRoute, hubItemByActiveRoute, getDefaultGoongMapCoordinates]);

  const renderInfoWindow = useMemo(() => {
    return (
      <>
        <div className="w-64">
          <div className="flex items-center">
            <div className="flex">
              <img src={MapMarkersOffice} alt="" />
            </div>
            <div className="flex ml-2">
              <div className="text-sm">
                <strong>{"Bưu cục: "}</strong>
                <span>
                  {hubItemByActiveRoute !== undefined
                    ? hubItemByActiveRoute.name
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="flex mt-2">
            <div className="text-xs">
              <strong>{"Địa chỉ: "}</strong>
              <span>
                {hubItemByActiveRoute !== undefined
                  ? hubItemByActiveRoute.address
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }, [hubItemByActiveRoute]);

  const onClickMarker = useCallback(
    (v: GoongMapCoordinates) => {
      setActivePopupInfo(v);
      setInfoWindow(renderInfoWindow);
      setOffsetTop(-20);
      setOffsetLeft(0);
    },
    [
      renderInfoWindow,
      setActivePopupInfo,
      setInfoWindow,
      setOffsetLeft,
      setOffsetTop,
    ]
  );

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "DASHBOARD_GOONGMAP_GOTOMARKER",
        (_data: any) => {
          if (
            hubItemByActiveRoute &&
            _data.latitude === hubItemByActiveRoute.lat &&
            _data.longitude === hubItemByActiveRoute.long
          ) {
            onClickMarker({
              latitude: _data.latitude,
              longitude: _data.longitude,
            });
          }
        }
      );
    }
  }, [onClickMarker, hubItemByActiveRoute]);

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);
  return (
    <>
      <DeliveryDashboardSectionRightGoongMapMarker
        item={getCoordinates()}
        img={<img src={MapMarkersOffice} alt="" />}
        onClick={onClickMarker}
      />
    </>
  );
};

export default DeliveryDashboardSectionRightMapMarkerOffice;
