import { Button, Col, Row, Tooltip } from "antd";
import PosCartModel from "common/models/PosCartModel";
import TextMoney from "components/TextMoney";
import React, { useCallback, useMemo, useState } from "react";
import useCompanySettingStore from "zustands/useCompanySettingStore";
import usePosCartStore from "zustands/usePosCartStore";
import usePosStore from "zustands/usePosStore";

import { IconPencil, IconVolume, IconVolume3 } from "@tabler/icons-react";

import PosCartModalDiscount from "./PosCartModalDiscount";
import PosCartModalShipping from "./PosCartModalShipping";

const PosCartSummary = () => {
  const width = usePosStore((state) => state.width);
  const disableManualDiscount = useCompanySettingStore(
    (state) => state.getSetting("disable_manual_promotion") || 0
  );
  const [enableSoundFx, setEnableSoundFx] = usePosStore((state) => [
    state.enableSoundFx,
    state.setEnableSoundFx,
  ]);

  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);
  const activeCart = usePosCartStore((state) => state.getActiveCart());
  const calculatedData = useMemo(
    () =>
      typeof activeCart !== "undefined"
        ? PosCartModel.calculatePrices(activeCart)
        : undefined,
    [activeCart]
  );

  const [showModalDiscount, setShowModalDiscount] = useState(false);
  const [showModalShipping, setShowModalShipping] = useState(false);

  const onChangeDiscount = useCallback(
    (manualDiscount: number) => {
      if (manualDiscount === 0) {
        updateActiveCart({
          price_discount: 0,
          promotions: [],
          coupons: [],
        });
      } else {
        updateActiveCart({
          price_discount: manualDiscount,
          manual_discount: true,
          promotions: [],
          coupons: [],
        });
      }
    },
    [updateActiveCart]
  );

  const onChangeShipping = useCallback(
    (newShippingPrice: number) => {
      updateActiveCart({
        price_shipping: newShippingPrice,
      });
    },
    [updateActiveCart]
  );

  if (
    typeof activeCart === "undefined" ||
    typeof calculatedData === "undefined"
  ) {
    return null;
  }

  return (
    <div id="pos-cart-money" style={{ width: width * 0.3333333 }}>
      <Row>
        <Col flex={"100px"}>
          <div className="p-2">SL: {calculatedData.quantity}</div>
        </Col>
        <Col flex={"auto"}>
          <div className="w-full">
            <Row className="p-2">
              <Col className="text-right" flex="auto">
                Tổng tiền
              </Col>
              <Col className="text-right" flex={"150px"}>
                <TextMoney money={calculatedData.price_sell} />
              </Col>
            </Row>
            <Row className="p-2">
              <Col className="text-right" flex="auto">
                {disableManualDiscount ||
                activeCart.coupons.length > 0 ||
                activeCart.promotions.length > 0 ||
                !activeCart.manual_discount ? (
                  <span>Giảm giá</span>
                ) : (
                  <span
                    className="pos-cart-money-edit-label"
                    onClick={() => setShowModalDiscount(true)}
                  >
                    Giảm giá <IconPencil size={14} color="#cccccc" />
                  </span>
                )}
              </Col>
              <Col className="text-right" flex={"150px"}>
                {calculatedData.price_discount > 0 ? (
                  <Tooltip
                    placement="right"
                    mouseEnterDelay={0.4}
                    title={
                      <div>
                        {calculatedData.price_discount_by_coupon > 0 ? (
                          <div>
                            - Giảm giá từ Coupon:{" "}
                            <TextMoney
                              money={calculatedData.price_discount_by_coupon}
                            />
                          </div>
                        ) : null}
                        {calculatedData.price_discount_by_promotion > 0 ? (
                          <div>
                            - Giảm giá từ CTKM (
                            {activeCart?.promotions[0]?.name}):{" "}
                            <TextMoney
                              money={calculatedData.price_discount_by_promotion}
                            />
                          </div>
                        ) : null}
                        {calculatedData.price_discount_by_manual > 0 ? (
                          <div>
                            - Giảm giá do nhập trực tiếp:{" "}
                            <TextMoney
                              money={calculatedData.price_discount_by_manual}
                            />
                          </div>
                        ) : null}
                      </div>
                    }
                  >
                    <span>
                      {calculatedData.price_discount > 0 ? "- " : ""}
                      <TextMoney money={calculatedData.price_discount} />
                    </span>
                  </Tooltip>
                ) : (
                  <TextMoney money={calculatedData.price_discount} />
                )}
              </Col>
            </Row>
            <Row className="p-2">
              <Col className="text-right" flex="auto">
                <span
                  className="pos-cart-money-edit-label"
                  onClick={() => setShowModalShipping(true)}
                >
                  Phí giao hàng <IconPencil size={14} color="#cccccc" />
                </span>
              </Col>
              <Col className="text-right" flex={"150px"}>
                <TextMoney money={calculatedData.price_shipping} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row id="pos-cart-money-final" className="p-2">
        <Col className="text-right" flex={"auto"}>
          <div className="float-left">
            <Button
              size="small"
              type="text"
              icon={
                enableSoundFx ? (
                  <IconVolume color="#ffffff" />
                ) : (
                  <IconVolume3 color="#ffffff" />
                )
              }
              onClick={() => setEnableSoundFx(!enableSoundFx)}
            />
          </div>
          THÀNH TIỀN
        </Col>
        <Col className="text-right" flex={"150px"}>
          <TextMoney money={calculatedData.price_final} />
        </Col>
      </Row>
      <PosCartModalShipping
        delivery={activeCart.delivery}
        key={calculatedData.price_shipping}
        defaultValue={calculatedData.price_shipping}
        show={showModalShipping}
        setShow={setShowModalShipping}
        onChange={onChangeShipping}
      />
      <PosCartModalDiscount
        priceSell={calculatedData.price_sell}
        defaultValue={calculatedData.price_discount_by_manual}
        show={showModalDiscount}
        setShow={setShowModalDiscount}
        onChange={onChangeDiscount}
      />
    </div>
  );
};

export default PosCartSummary;
