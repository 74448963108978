import BaseCollection from 'common/collections/BaseCollection';
import InventoryStockModel from 'common/models/InventoryStockModel';
import { InventoryStockCollectionJson, InventoryStockJson } from 'common/types/InventoryStock';

class InventoryStockCollection extends BaseCollection<
  InventoryStockJson,
  InventoryStockModel,
  InventoryStockCollectionJson
> {
  itemsFromJson(jsonItems: InventoryStockJson[]): InventoryStockModel[] {
    return jsonItems.map((item) => new InventoryStockModel(item));
  }
}

export default InventoryStockCollection;
