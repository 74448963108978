import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const DeliveryWaitingFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("delivery:waiting.heading_nav"),
      link: "/delivery/waiting",
    },
  ];

  return (
    <PageHeader
      heading={t(
        isEditing
          ? "delivery:waiting.heading_edit"
          : "delivery:waiting.heading_add"
      )}
      nav={nav}
      siteMenuOpenKey="delivery"
      siteMenuHidden
    ></PageHeader>
  );
};

export default DeliveryWaitingFormHeader;
