import AccountApi from "common/api/AccountApi";
import AccountModel from "common/models/AccountModel";

import BaseRepository from "./BaseRepository";
import { PasswordUpdateJson, ProfileUpdateJson } from "common/types/Account";
import AuthorizedCompanyModel from "common/models/AuthorizedCompanyModel";

class AccountRepository extends BaseRepository<AccountApi> {
  _api: AccountApi;

  constructor() {
    super();
    this._api = new AccountApi(true);
  }
  async login(
    username: string,
    password: string,
    hostname: string
  ): Promise<AccountModel> {
    return await this._api.login(username, password, hostname);
  }
  async getAuthorizedCompany(): Promise<AuthorizedCompanyModel> {
    return await this._api.getAuthorizedCompany();
  }

  async logout(): Promise<string[]> {
    return await this._api.logout();
  }

  async updateProfile(data: ProfileUpdateJson): Promise<AccountModel> {
    if (this._api) {
      return await this._api.edit(data);
    } else {
      return new AccountModel(AccountModel.getDefaultData());
    }
  }
  async forgotpassword(username: string, hostname: string): Promise<string[]> {
    return await this._api.forgotpassword(username, hostname);
  }

  async resetpassword(
    username: string,
    verification_code: string,
    new_password: string,
    hostname: string
  ): Promise<string[]> {
    return await this._api.resetpassword(
      username,
      verification_code,
      new_password,
      hostname
    );
  }

  async updatePassword(data: PasswordUpdateJson): Promise<string[]> {
    if (this._api) {
      return await this._api.editpassword(data);
    } else {
      return [];
    }
  }
}

export default AccountRepository;
