import EmployeeApi from "common/api/EmployeeApi";
import EmployeeCollection from "common/collections/EmployeeCollection";
import EmployeeModel from "common/models/EmployeeModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
	BankInfoEmployee,
	EmployeeFetching,
	EmployeeJsonAddEdit,
	EmployeeProfileJsonEdit,
	EmployeeTypeWork,
	FilterEmployee,
	PasswordUpdateJson,
} from "common/types/Employee";
import EmployeeCollectionIdFetching from "common/collections/EmployeeCollectionIdFetching";
class EmployeeRepository extends BaseRepository<EmployeeApi> {
	_api: EmployeeApi | null;

	constructor() {
		super();
		this._api = new EmployeeApi(true);
	}

	static getDefaultFilters(): FilterEmployee {
		return {
			page: 1,
			limit: 20,
			sortby: "id",
			sorttype: "DESC",
			status: -1,
			keyword: "",
			office_id: -1,
			department_id: -1,
			type: -1,
			keyword_name: "",
		};
	}

	async getFullData(
		companyId: number,
		version: number
	): Promise<EmployeeCollection> {
		return this._api
			? await this._api.getFullData({ companyId, version })
			: new EmployeeCollection();
	}

	async getItems(props: GetListProps<FilterEmployee>) {
		return this._api
			? await this._api.getList(props)
			: new EmployeeCollection();
	}

	async getItem(id: number): Promise<EmployeeModel> {
		return this._api
			? await this._api.getDetail(id)
			: new EmployeeModel(EmployeeModel.getDefaultData());
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	async saveRemote(data: EmployeeJsonAddEdit): Promise<EmployeeModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new EmployeeModel(EmployeeModel.getDefaultData());
		}
	}

	// async updateProfile(data: AccountUpdateJson): Promise<EmployeeModel> {
	//   if (this._api) {
	//     return await this._api.updateProfile(data);
	//   } else {
	//     return new EmployeeModel(EmployeeModel.getDefaultData());
	//   }
	// }

	async setPassword(data: PasswordUpdateJson): Promise<string[]> {
		if (this._api) {
			return await this._api.setPassword(data);
		} else {
			return [];
		}
	}

	async getBank(): Promise<BankInfoEmployee[]> {
		if (this._api) {
			return await this._api.getBank();
		} else {
			return [];
		}
	}

	async getTypeWork(): Promise<EmployeeTypeWork[]> {
		if (this._api) {
			return await this._api.getTypeWork();
		} else {
			return [];
		}
	}

	async getItemFullDetail(id: number): Promise<EmployeeModel> {
		return this._api
			? await this._api.getFullDetail(id)
			: new EmployeeModel(EmployeeModel.getDefaultData());
	}

	async getItemFullDetail2(id: number): Promise<EmployeeModel> {
		return this._api
			? await this._api.getFullDetail2(id)
			: new EmployeeModel(EmployeeModel.getDefaultData());
	}

	async getIdsItems(
		props: EmployeeFetching
	): Promise<EmployeeCollectionIdFetching> {
		return this._api
			? await this._api.getIdsList(props)
			: new EmployeeCollectionIdFetching();
	}

	async updateInfoEmployee(
		data: EmployeeProfileJsonEdit
	): Promise<EmployeeModel> {
		return this._api
			? await this._api.updateInfoEmployee(data)
			: new EmployeeModel(EmployeeModel.getDefaultData());
	}

	async updateAvatar(id: number): Promise<EmployeeModel> {
		return this._api
			? await this._api.updateAvatar(id)
			: new EmployeeModel(EmployeeModel.getDefaultData());
	}

	//////////////////////////////
	async getListBirthday(): Promise<EmployeeCollection> {
		return this._api
			? await this._api.getListBirthday()
			: new EmployeeCollection();
	}
}

export default EmployeeRepository;
