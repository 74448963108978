import BaseRepository from "./BaseRepository";
import CompanyResourceApi from "common/api/CompanyResourceApi";

class CompanyResourceRepository extends BaseRepository<CompanyResourceApi> {
  _api: CompanyResourceApi;

  constructor() {
    super();
    this._api = new CompanyResourceApi(true);
  }

  async getSingleCompanyResource(companyId: number) {
    return await this._api.getList({ companyId });
  }
}

export default CompanyResourceRepository;
