import { Checkbox, Col, Form, Input, Row } from "antd";
import CustomerModel from "common/models/CustomerModel";
import CustomerRepository from "common/repositories/CustomerRepository";
import BirthdayInput from "components/BirthdayInput";
import FormRadio from "components/form/FormRadio";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";
import LabelDateFormatHelper from "components/LabelDateFormatHelper";
import RegionPicker from "components/RegionPicker";
import CustomerFormHeader from "features/customer/form/CustomerFormHeader";
import CustomerTypeFormSelect from "features/customertype/CustomerTypeFormSelect";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import type { CustomerJsonAddEdit } from "common/types/Customer";
import LocationPicker from "components/LocationPicker";
const CustomerForm = ({ model }: { model: CustomerModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = {
    customer_type_id:
      model.customer_type_id > 0 ? model.customer_type_id : null,
    code: model.code,
    full_name: model.full_name,
    gender: model.gender,
    birthday: model.birthday,
    phone: model.phone,
    email: model.email,
    address: model.address,
    first_name: model.first_name,
    last_name: model.last_name,
    regions: {
      shipping_province: model.province,
      shipping_district: model.district,
      shipping_ward: model.ward,
    },
    note: model.note,
    ignore_auto_upgrade: model.ignore_auto_upgrade === 1,
    tax_buyer_name: model.tax_buyer_name,
    tax_buyer_address: model.tax_buyer_address,
    tax_buyer_phone: model.tax_buyer_phone,
    tax_buyer_account_number: model.tax_buyer_account_number,
    tax_buyer_tax_number: model.tax_buyer_tax_number,
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: CustomerJsonAddEdit = {
        ...CustomerModel.getDefaultData(),
        id: model.id,
        phone: formData.phone,
        gender: formData.gender,
        customer_type_id: formData.customer_type_id,

        address: formData.address,
        province_id: formData.regions.shipping_province,
        district_id: formData.regions.shipping_district,
        ward_id: formData.regions.shipping_ward,
        note: formData.note,
        code: formData.code,
        birthday: formData.birthday,
        email: formData.email,
        ignore_auto_upgrade: formData.ignore_auto_upgrade ? 1 : 0,
        tax_buyer_name: formData.tax_buyer_name,
        tax_buyer_address: formData.tax_buyer_address,
        tax_buyer_phone: formData.tax_buyer_phone,
        tax_buyer_account_number: formData.tax_buyer_account_number,
        tax_buyer_tax_number: formData.tax_buyer_tax_number,
        first_name: formData.first_name,
        last_name: formData.last_name,
        full_name: `${formData.last_name} ${formData.first_name}`,
        
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    doPrepareData(formData);

    let myObj: CustomerModel = await new CustomerRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  const sidebarItems = (
    <>
      {/* <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.ignore_auto_upgrade !== currentValues.ignore_auto_upgrade
        }
      >
        {({ getFieldValue }) => (
          <>
            <CustomerTypeFormSelect
              name="customer_type_id"
              label={t("customer:customer_type")}
              disabled={!getFieldValue("ignore_auto_upgrade")}
              className="mb-0"
            />
            <Form.Item name="ignore_auto_upgrade" valuePropName="checked">
              <Checkbox>{t("customer:ignore_auto_upgrade")}</Checkbox>
            </Form.Item>
          </>
        )}
      </Form.Item>
      <Form.Item label={t("customer:note")} name="note">
        <Input.TextArea autoSize style={{ minHeight: 60 }} />
      </Form.Item> */}
    </>
  );

  return (
    <>
      <CustomerFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("customer:form.success.update")
            : t("customer:form.success.add")
        }
        redirectOnSuccess={isEditing ? "" : "/customer"}
        error_translate_prefix="customer:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("customer:customer_info_title")}
          subtitle={t("customer:customer_info_description")}
          divider
        >
          <Row gutter={16}>
          <Col md={12} xs={24}>
              <Form.Item
                label={t("common:last_name")}
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: t("customer:form.error.error_last_name_required"),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("common:first_name")}
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: t("customer:form.error.error_first_name_required"),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
            </Col>
            {/* <Col md={12} xs={24}>
              <Form.Item
                label={t("common:full_name")}
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: t("customer:form.error.error_fullname_required"),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
            </Col> */}
        
            <Col md={12} xs={24}>
              <Form.Item label={t("customer:code")} name="code">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <FormRadio
                label={t("common:gender")}
                name="gender"
                options={CustomerModel.getGenderList()}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:phone")} name="phone">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:email")} name="email">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
         
            <Col md={12} xs={24}>
              <Form.Item
                label={
                  <>
                    {t("customer:birthday")}
                    <LabelDateFormatHelper />
                  </>
                }
                name="birthday"
                // rules={[
                //   {
                //     pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4}$/i),
                //     message: t("common:error.error_date_format_invalid"),
                //   },
                // ]}
              >
                <BirthdayInput />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection title={t("customer:customer_contact_title")} divider>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("customer:address")} name="address">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="regions" noStyle>
								<LocationPicker  className="mb-0" />
							</Form.Item>
        </FormSection>

        <FormSection
          title={t("customer:section_tax")}
          subtitle={t("customer:section_tax_subtitle")}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("customer:tax_buyer_name")}
                name="tax_buyer_name"
              >
                <Input placeholder={t("customer:tax_buyer_name_placeholder")} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("customer:tax_buyer_phone")}
                name="tax_buyer_phone"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("customer:tax_buyer_address")}
                name="tax_buyer_address"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("customer:tax_buyer_tax_number")}
                name="tax_buyer_tax_number"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("customer:tax_buyer_account_number")}
                name="tax_buyer_account_number"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default CustomerForm;
