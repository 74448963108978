import axios from "axios";
import sha256 from "crypto-js/sha256";

import BaseApi from "./BaseApi";

let AxiosClient1 = () => {
  let instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_2,
    timeout: 60 * 1000,
    // headers: {
    //   "Cache-Control": "public, max-age=3600", // Cache sẽ được lưu trong 1 giờ (3600 giây)
    // },
  });

  //add interceptors
  instance.interceptors.request.use(
    (config) => {
      let loginAccount = BaseApi.loginAccount;

      //add authorization token
      if (typeof loginAccount !== "undefined") {
        const companyId =
          loginAccount.company.id > 0 ? loginAccount.company.id : 0;
        // const allowedOrigins =
        //   process.env.NODE_ENV === "production"
        //     ? [process.env.REACT_APP_ORIGIN_HOST]
        //     : ["https://nperp-api.namperfume.ldev", "*"];
        //change baseurl
        if (loginAccount.company.id > 0) {
          //For logging & data missing (GET), we always append company_id to request
          config.headers["Authorization"] = `${loginAccount.jwt}`;
          // config.headers["Access-Control-Allow-Origin"] = allowedOrigins;

          // config.headers["Authorization"] = `b6dc86fae0ac5ba8f1fe4ba28e96841a`;

          config.params = {
            ...config.params,
            company_id: companyId,
          };
        }

        //if this is GET method, we append jwt to param, to prevent prefetch request
        if (config.method !== "get") {
          //append company_id to POST/PUT request data
          if (companyId > 0) {
            config.data = {
              ...config.data,
              company_id: companyId,
            };
          }

          //append creator_id to POST/PUT request data
          if (
            loginAccount.id > 0 &&
            (!config.data.hasOwnProperty("creator_id") ||
              config.data.creator_id === 0)
          ) {
            config.data = {
              ...config.data,
              creator_id: loginAccount.id,
            };
          }

          //ensure headers already set
          if (typeof config.headers !== "undefined") {
            //Append idempotency header
            let idempotencyInputData = {
              method: config.method,
              url: config.url,
              params: config.params,
              data: config.data,
              //timeframe same if request in 5 second slot
              timeframe: Math.floor(new Date().getTime() / 1000 / 5),
            };
            config.headers["Idempotency-Key"] = sha256(
              JSON.stringify(idempotencyInputData)
            ).toString();
            //end process idempotencyKey
          }
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

export default AxiosClient1;
