import React, { useCallback } from "react";
import { Link } from "react-router-dom";

const TableLinkText = ({
  link,
  title,
  onClick,
  className,
  children,
  target,
}: {
  link?: string;
  title?: string;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  target?: React.HTMLAttributeAnchorTarget;
}) => {
  const onClickProxy = useCallback(
    (e: any) => {
      e.preventDefault();
      if (typeof onClick !== "undefined") {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <Link
      target={target}
      title={title}
      className={"text-blue-600 " + (className || "")}
      to={link || "/"}
      onClick={typeof onClick !== "undefined" ? onClickProxy : undefined}
    >
      {children}
    </Link>
  );
};

export default TableLinkText;
