import ShippingOrder from 'common/constants/ShippingOrder';
import SpotlightWaypointBlue from 'images/mapmarkers/spotlight-waypoint-blue.png';
import SpotlightWaypointGreen from 'images/mapmarkers/spotlight-waypoint-green.png';
import SpotlightWaypointPurple from 'images/mapmarkers/spotlight-waypoint-purple.png';
import SpotlightWaypointRed from 'images/mapmarkers/spotlight-waypoint-red.png';
import SpotlightWaypointYellow from 'images/mapmarkers/spotlight-waypoint-yellow.png';
import React, { useMemo } from 'react';

const DeliveryDashboardGetMarkerImage = ({
  index,
  status,
  alt,
  width,
  height,
  className,
  style,
}: {
  index: number;
  status: number;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  // link get markerImage https://www.google.com/maps/d/viewer?mid=1icXjgXJ5da1l2BQjMNgXAI4dlkw&hl=en_US&ll=-0.002000000000007509%2C0&z=16
  const getStatusImage = useMemo(() => {
    let img = "";
    switch (status) {
      case ShippingOrder.STATUS_NEW:
        img = SpotlightWaypointYellow;
        break;
      case ShippingOrder.STATUS_PENDING:
        img = SpotlightWaypointPurple;
        break;
      case ShippingOrder.STATUS_INPROGRESS:
        img = SpotlightWaypointBlue;
        break;
      case ShippingOrder.STATUS_SUCCESS:
        img = SpotlightWaypointGreen;
        break;
      case ShippingOrder.STATUS_FAIL:
        img = SpotlightWaypointRed;
        break;
      case ShippingOrder.STATUS_SUB_SUCCESS:
        img = SpotlightWaypointGreen;
        break;
      case ShippingOrder.STATUS_CANCELLED:
        img = SpotlightWaypointRed;
        break;
    }

    return img;
  }, [status]);

  return (
    <div className="relative">
      <div className="absolute flex justify-center w-full h-full mt-1.5 text-xs font-bold">
        {index}
      </div>
      <img
        src={getStatusImage}
        alt={alt}
        width={width}
        height={height}
        className={className}
        style={style}
      />
    </div>
  );
};

export default DeliveryDashboardGetMarkerImage;
