import { Col, Form, Input, Row } from 'antd';
import DmsNews from 'common/constants/DmsNews';
import File from 'common/constants/File';
import DmsNewsModel from 'common/models/DmsNewsModel';
import FileModel from 'common/models/FileModel';
import DmsNewsRepository from 'common/repositories/DmsNewsRepository';
import FileInput from 'components/form/fileinput/FileInput';
import FormSection from 'components/form/FormSection';
import FormSectionSeo from 'components/form/FormSectionSeo';
import FormSelect from 'components/form/FormSelect';
import LayoutForm from 'components/form/LayoutForm';
import HtmlEditorInput from 'components/htmleditorinput/HtmlEditorInput';
import DmsNewsEditorHeader from 'features/dmsnews/form/DmsNewsFormEditorHeader';
import DmsNewsCategoryFormSelect from 'features/dmsnewscategory/DmsNewsCategoryFormSelect';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DmsNewsJsonAddEdit } from "common/types/DmsNews";
const DmsNewsFormEditor = ({ model }: { model: DmsNewsModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  // modal editing

  const initialValues = {
    title: model.title,
    content: model.content,
    news_category_id:
      model.news_category_id > 0 ? model.news_category_id : null,
    status: model.status || DmsNews.STATUS_ENABLE,
    file_id: model.file_id,
    image_input: {
      files: FileModel.convertToUploadFiles([model.image]),
      first_file_id: model.file_id,
    },
    seo_slug: model.seo_slug,
    seo_title: model.seo_title,
    seo_keyword: model.seo_keyword,
    seo_description: model.seo_description,
    graph_image_id: model.graph_image_id,
    graph_image_input: {
      files: FileModel.convertToUploadFiles([model.graph_image]),
      first_file_id: model.graph_image_id,
    },
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let file_id =
        typeof formData.image_input.file_id_list?.[0] !== "undefined"
          ? formData.image_input.file_id_list?.[0]
          : model.file_id;

      let graph_image_id =
        typeof formData.graph_image_input.file_id_list?.[0] !== "undefined"
          ? formData.graph_image_input.file_id_list?.[0]
          : model.graph_image_id;

      const submitData: DmsNewsJsonAddEdit = {
        ...DmsNewsModel.getDefaultData(),
        id: model.id,
        title: formData.title,
        content: formData.content,
        news_category_id: formData.news_category_id,
        status: formData.status || DmsNews.STATUS_ENABLE,
        file_id: file_id,
        seo_slug: formData.seo_slug,
        seo_title: formData.seo_title,
        seo_keyword: formData.seo_keyword,
        seo_description: formData.seo_description,
        graph_image_id: graph_image_id,
      };

      return submitData;
    },
    [model.id, model.graph_image_id, model.file_id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: DmsNewsModel = await new DmsNewsRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <Col>
        <Form.Item name="image_input" label={t("dmsnews:file_id")}>
          <FileInput
            objectType={File.OBJECTTYPE_ATTACHMENT}
            origin="dmsportal"
          />
        </Form.Item>
      </Col>
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={DmsNewsModel.getStatusList()}
      />
    </div>
  );

  return (
    <>
      <DmsNewsEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("dmsnews:form.success.update")
            : t("dmsnews:form.success.add")
        }
        redirectOnSuccess="/dmsnews"
        error_translate_prefix="dmsnews:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("dmsnews:form.section_title")}
          subtitle={t("dmsnews:form.section_description")}
          divider
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("dmsnews:title")}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t("dmsnews:form.error.error_title_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <DmsNewsCategoryFormSelect
                label={t("dmsnews:news_category_id")}
                name="news_category_id"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("news:content")} name="content">
                <HtmlEditorInput
                  fileObjectType={File.OBJECTTYPE_CMS}
                  fileOrigin="dmsportal"
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSectionSeo
          fileObjectType={File.OBJECTTYPE_ATTACHMENT}
          fileOrigin="dmsportal"
        />
      </LayoutForm>
    </>
  );
};

export default DmsNewsFormEditor;
