import { StoryCollectionCollectionJson, StoryCollectionJson } from "common/types/StoryCollection";
import BaseCollection from "./BaseCollection";
import StoryCollectionModel from "common/models/StoryCollectionModel";



class StoryCollectionCollection extends BaseCollection<
  StoryCollectionJson,
  StoryCollectionModel,
  StoryCollectionCollectionJson
> {
  itemsFromJson(jsonItems: StoryCollectionJson[]): StoryCollectionModel[] {
    return jsonItems.map((item) => new StoryCollectionModel(item));
  }
}
export default StoryCollectionCollection;
