import { Form, Input } from "antd";
import Helper from "common/utils/helper";
import React from "react";
import { useTranslation } from "react-i18next";

const UserHostname = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      hidden={Helper.getHostname().length > 0}
      name="hostname"
      rules={[
        {
          required: true,
          message: t("user:form.error.error_hostname_is_required"),
        },
      ]}
      label={t("user:hostname")}
      className="p-4 bg-blue-50 rounded-md"
    >
      <Input
        autoFocus={Helper.getHostname().length === 0}
        addonBefore="https://"
        placeholder="erp.yourcompany.com"
      />
    </Form.Item>
  );
};

export default UserHostname;
