import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductVariantColorListHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("productvariantcolor:heading_list")}
      siteMenuSelectedKey="/productvariantcolor"
      siteMenuOpenKey="product"
    >
      {children}
    </PageHeader>
  );
};

export default ProductVariantColorListHeader;
