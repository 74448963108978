import "antd/dist/reset.css";
import "@goongmaps/goong-js/dist/goong-js.css";
import "css/index.css";
import "css/layout/container.css";
import "css/layout/header.css";
import "css/layout/mainnavigation.css";
import "css/layout/antd-override.css";
import "css/layout/print.css";
import "css/prosemirror.css";
import "css/htmleditor.css";
import "css/daterangepicker.css";
import "css/report.css";
import "css/pos.css";
import "css/delivery.css";
import "css/goongmap.css";

import AppWithLocale from "AppWithLocale";
import AppWithSessionProvider from "AppWithSessionProvider";
import AppWithThemeProvider from "AppWithThemeProvider";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <AppWithLocale>
      <AppWithThemeProvider>
        <AppWithSessionProvider>
          <App />
        </AppWithSessionProvider>
      </AppWithThemeProvider>
    </AppWithLocale>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
