import BaseCollection from 'common/collections/BaseCollection';
import WorkTrackingRangeModel from 'common/models/WorkTrackingRangeModel';
import {
    WorkTrackingRangeCollectionJson, WorkTrackingRangeJson
} from 'common/types/WorkTrackingRange';

class WorkTrackingRangeCollection extends BaseCollection<
  WorkTrackingRangeJson,
  WorkTrackingRangeModel,
  WorkTrackingRangeCollectionJson
> {
  itemsFromJson(jsonItems: WorkTrackingRangeJson[]): WorkTrackingRangeModel[] {
    return jsonItems.map((item) => new WorkTrackingRangeModel(item));
  }
}

export default WorkTrackingRangeCollection;
