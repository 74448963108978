import { Button, Col, Form, Row } from "antd";
import SettingModel from "common/models/SettingModel";
import FormSelectNormal from "components/form/FormSelectNormal";
import { useState } from "react";

type Props = {
  defaultLang: string;
  changeLang: (newLang: string) => void;
};

const ShopFooterFilter = ({ defaultLang, changeLang }: Props) => {
  const [chooseLang, setChooseLang] = useState<string>(
    defaultLang.length > 0 ? defaultLang : "vi"
  );

  const initialValue = {
    lang: defaultLang.length > 0 ? defaultLang : "vi",
  };

  const onFilters = () => {
    changeLang(chooseLang);
  };

  return (
    <Form
      initialValues={initialValue}
      onFinish={() => {
        console.log("submit");
      }}
      className="w-full h-fit"
    >
      <Row gutter={[8, 0]} className="p-2">
        <Col>
          <span className="leading-8">Bộ lọc:</span>
        </Col>
        <Col md={4}>
          <FormSelectNormal
            name="lang"
            placeholder={"Phân loại"}
            options={SettingModel.getLangSelect()}
            allowClear
            value={chooseLang}
            onChange={(e) => {
              setChooseLang(e + "");
            }}
            popupMatchSelectWidth={false}
          />
        </Col>

        <Col>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                onFilters();
              }}
            >
              Lọc
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ShopFooterFilter;
