import "dayjs/locale/vi";
import "dayjs/locale/en";

import { ConfigProvider } from "antd";
// Ant-design provided locales
import enUS from "antd/locale/en_US";
import viVN from "antd/locale/vi_VN";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import i18n from "i18n";
import { useEffect, useMemo } from "react";
import { I18nextProvider } from "react-i18next";
import useSiteLanguageStore from "zustands/useSiteLanguageStore";

dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const AppWithLocale = ({ children }: { children: React.ReactNode }) => {
  const language = useSiteLanguageStore((state) => state.language);

  // use locale globally
  dayjs.locale(language?.locale_short);

  const antdLocale = useMemo(() => {
    let locale = enUS;

    switch (language?.locale_short) {
      case "vi":
        locale = viVN;
        break;
      case "en":
        locale = enUS;
        break;
      default:
        break;
    }

    return locale;
  }, [language]);

  useEffect(() => {
    //update main locale
    if (i18n.language !== language?.locale) {
      i18n.changeLanguage(language?.locale);
    }
  }, [language]);

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>
    </I18nextProvider>
  );
};

export default AppWithLocale;
