import { BaseCollectionJson } from "./BaseCollectionJson";
import { FileJson } from "./File";
import { Filter } from "./Filter";
import { ProductCollectionJson as ProductCollectionCateJson } from "./ProductCollection"
type ProductJson = {
	status: number;
	id: number;
	company_id: number;
	creator_id: number;
	name: string;
	category_id: number;
	code: string;
	supplier_id: number[];
	unit: number;
	sell_on_zero: number;
	date_created: number;
	date_modified: number;
	image_id: number;
	image: FileJson;
};

type ProductJson1 = {
	id: number;
	status: string;
	sku: string;
	name: string;
	barcode: string;
	handle: string;
	summary: string;
	description: string;
	detail: string;
	meta_title: string;
	meta_description: string;
	supplier_id: string;
	brand: BrandJson[];
	price: number;
	compare_at_price: number;
	tags: TagJson[];
	images: FileJson[];
	options: OptionJson[];
	created_at: number;
	updated_at: number;
};

type ProductJson2 = {
	status: number;
	allow_sale: number;
	company_id: number;
	id: number;
	name: string;
	full_name: string;
	min_buy : number,
	sku: string;
	barcode: string;
	handle: string;
	images: number[];
	brand: number;
	production_year: number;
	origin: string;
	price: number;
	price_min: number;
	price_max: number;
	compare_at_price: number;
	compare_at_price_min: number;
	compare_at_price_max: number;
	collections : ProductCollectionCateJson[]
	tags: TagJson[];
	parent_id: number;
	created_at: number;
	updated_at: number;
	options: OptionJson[];
	count_childs: number;
	quantity: number;
	kiotviet_id: number;
	kiotviet_collection: number;
	allow_promotion: number;
	rate: ProductRateJson;
	display_from: number;
	display_to: number;
	limit_sale: number;
	show_web: number;
	option_name: string;
	show_pos  : number
	supplier_id : number
};
export enum ProductAllowSale {
	NOT_ALLOW_SALE,
	ALLOW_SALE,
}
export type ProductBrandJson = {
	id: number;
	title: string;
	handle: string;
};
export type Images = {
	url: string;
};

export type ProductTagJson = {
	name: string;
	type: string;
	code: string;
	description: string;
	value: string;
	id: number;
	
};
type ProductJsonPublic = {
	allow_sale: ProductAllowSale;
	status: number;
	id: number;
	name: string;
	full_name: string;
	sku: string;
	barcode: string;
	handle: string;
	quantity: number;
	brand: ProductBrandJson | null;
	price: number;
	compare_at_price: number;
	price_min: number;
	price_max: number;
	parent_handle: string;
	compare_at_price_min: number;
	compare_at_price_max: number;
	type_id: number;
	images: Images[];
	tags: ProductTagJson[];
	created_at: number;
	updated_at: number;
	parent_id: number;
	count_childs: number;
	summary: string;
	description: string;
	detail: string;
	store_id : number
	meta_keyword: string;
	meta_title: string;
	meta_description: string;

	//key client custom for productJson to process promotion
	// promotions: PromotionJson[];
	option_name: string;

	kiotviet_image: string;
	kiotviet_id: number;
	production_year: number;
	origin: string;

	list_childs: number[];

	//price discount / price compare
	compare_discount: number;
	compare_discount_percent: number;

	limit_sale: number;
	display_from: number;
	display_to: number;
	collections: ProductColletionJson[];
	variant_id: number;
	quantities: Quantitie[];
};

interface Quantitie {
	store_id: number;
	quantity: number;
	actual_quantity: number;
	reserved: number;
	in_coming: number;
}
export type ProductColletionJson = {
	id: number;
	title: string;
	handle: string;
};
type ProductRateJson = {
	rate: number;
	count_rate: number;
	count_rate_1: number;
	count_rate_2: number;
	count_rate_3: number;
	count_rate_4: number;
	count_rate_5: number;
};

type TagJson = {
	code: string;
	display_order: number;
	name: string;
	type: string;
	value: string;
};

type BrandJson = {
	id: number;
	title: string;
	handle: string;
};

type OptionJson = {
	key: string;
	value: string;
};

type ProductJsonAddEdit = ProductJson & {};
type ProductJsonAddEdit1 = ProductJson1 & {
	brand_id?: string[];
};

export enum ProductStatus {
	UNACTIVE,
	ACTIVE,
}
export enum ProductShowWeb {
	UNACTIVE,
	ACTIVE,
}

type ProductJsonAddEdit2 = {
	id: number;
	status: ProductStatus;
	show_web: ProductShowWeb;
	show_pos :ProductShowWeb, 
	allow_sale?: ProductAllowSale;
	sku: string;
	name: string;
	option_name : string
	full_name: string;
	handle: string;
	limit_sale: number;
	min_buy: number;
	barcode?: string;
	brand_id?: number;
	images: string;
	parent_id: number;
	price: number;
	compare_at_price: number;
	origin: string;
	production_year: number;
	allow_promotion: number;
	display_to?: number;
	display_from?: number;
	description: string;
	summary: string;
	meta_title: string;
	meta_description: string;
	meta_keyword: string;
	supplier_id?: number
	store_id?: string
	collections: number[];
};

export type ProductJsonEdit2 = Partial<ProductJsonAddEdit2>

type ProductCollectionJson = BaseCollectionJson<ProductJson>;
type ProductCollectionJson1 = BaseCollectionJson<ProductJson1>;
type ProductCollectionJson2 = BaseCollectionJson<ProductJson2>;
type ProductPublicCollectionJson = BaseCollectionJson<ProductJsonPublic>;

type FilterProduct = Filter & {
	id: number;
	category_id: number;
	supplier_id: number;
	list_product_id: string;
	store_id: number;
	collection_id: number;
	tags: string;
	parent_id?: number;
          tags_code?:string
		  skus?: string

};

type sortBy = "" | "";

type ProductRelatedFilter = {
	id: number;
	store_id: number;
	tags: string;
};

type ProductStructJson = {
	code: string;
	name: string;
	type: string;
};

export type {
	ProductJson,
	ProductJson1,
	ProductJson2,
	ProductJsonPublic,
	ProductJsonAddEdit,
	ProductJsonAddEdit1,
	ProductJsonAddEdit2,
	ProductCollectionJson,
	ProductCollectionJson1,
	ProductCollectionJson2,
	FilterProduct,
	TagJson,
	BrandJson,
	OptionJson,
	ProductRateJson,
	ProductRelatedFilter,
	ProductStructJson,
	ProductPublicCollectionJson,
	Quantitie,
};
