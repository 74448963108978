import EcomModel from 'common/models/EcomModel';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

const SERVICE_URL = "/ecomplatformwebhooks";

class EcomPlatformWebhookApi extends BaseApi {
  async registerWebhook(id: number): Promise<EcomModel> {
    let item = new EcomModel(EcomModel.getDefaultData());

    try {
      const response = await AxiosClient().post<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new EcomModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async unregisterWebhook(id: number): Promise<EcomModel> {
    let item = new EcomModel(EcomModel.getDefaultData());

    try {
      const response = await AxiosClient().delete<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new EcomModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default EcomPlatformWebhookApi;
