import { App, Col, Form, Input, Radio, Row } from "antd";
import SupplierModel from "common/models/SupplierModel";
import SupplierRepository from "common/repositories/SupplierRepository";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { SupplierJsonAddEdit } from "common/types/Supplier";
const SupplierForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: SupplierModel;
  setOpen: (v: boolean) => void;
  setProcessing: (p: boolean) => void;
  onSaveSuccess: (o: SupplierModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      contact_fullname: model.contact_fullname,
      contact_email: model.contact_email,
      contact_phone: model.contact_phone,
      product_type: model.product_type,
      note: model.note
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: SupplierJsonAddEdit = {
        ...SupplierModel.getDefaultData(),
        id: model.id,
        code: model.code,
        name: formData.name,
        contact_fullname: formData.contact_fullname,
        contact_email: formData.contact_email,
        contact_phone: formData.contact_phone,
        product_type: formData.product_type,
        note: formData.note
      };

      return submitData;
    },
    [model.id, model.code]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: SupplierModel = await new SupplierRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="supplier:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={16} xs={24}>
          <Form.Item
            label={t("supplier:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("supplier:form.error.error_name_required")
              }
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item label={t("supplier:product_type")} name="product_type">
            <Radio.Group
              size="middle"
              optionType="button"
              buttonStyle="outline"
              options={SupplierModel.getProductTypeList()}></Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={16} xs={24}>
          <Form.Item
            label={t("supplier:contact_fullname")}
            name="contact_fullname">
            <Input />
          </Form.Item>
          <Form.Item label={t("supplier:contact_phone")} name="contact_phone">
            <Input />
          </Form.Item>
          <Form.Item
            label={t("supplier:contact_email")}
            name="contact_email"
            className="mb-0">
            <Input />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item label={t("supplier:note")} name="note" className="mb-0">
            <Input.TextArea rows={9} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SupplierForm;
