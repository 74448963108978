import BaseCollection from "common/collections/BaseCollection";
import PromotionModel from "common/models/PromotionModel";
import { PromotionJson, PromotionCollectionJson } from "common/types/Promotion";

class PromotionCollection extends BaseCollection<
  PromotionJson,
  PromotionModel,
  PromotionCollectionJson
> {
  itemsFromJson(jsonItems: PromotionJson[]): PromotionModel[] {
    return jsonItems.map((item) => new PromotionModel(item));
  }
}

export default PromotionCollection;


