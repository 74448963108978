import ShippingOrder from "common/constants/ShippingOrder";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import { GoongMapCoordinates } from "common/types/goongmap/GoongMapType";
import GoongMapInfoWindow from "components/goongmap/GoongMapInfoWindow";
import GoongMapWrapper from "components/goongmap/GoongMapWrapper";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import DeliveryDashboardSectionRightMapMarkerOffice from "./DeliveryDashboardSectionRightGoogleMapMarkerOffice";
import DeliveryDashboardSectionRightMapMarkerOrder from "./DeliveryDashboardSectionRightGoogleMapMarkerOrder";

const DeliveryDashboardSectionRightGoongMapWrapper = ({
  apiKey,
}: {
  apiKey: string;
}) => {
  const [activePopupInfo, setActivePopupInfo] = useState<GoongMapCoordinates>(
    ShippingRouteModel.getDefaultGoongMapCoordinates()
  );
  const [infoWindow, setInfoWindow] = useState<ReactNode>();
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const [offsetLeft, setOffsetLeft] = useState<number>(0);

  const getDefaultGoongMapCoordinates = useMemo(() => {
    return ShippingRouteModel.getDefaultGoongMapCoordinates();
  }, []);

  const [activeRoute, hubItemByActiveRoute, orderItemsByActiveRoute] =
    useDeliveryDashboardStore((state) => [
      state.activeRoute,
      state.hubItemByActiveRoute,
      state.orderItemsByActiveRoute,
    ]);

  const isActiveMapMarkerOffice = useMemo(() => {
    let isActive = false;
    if (
      activeRoute &&
      activeRoute.from_type === ShippingOrder.FROM_TYPE_OFFICE &&
      hubItemByActiveRoute !== undefined &&
      hubItemByActiveRoute.lat > 0 &&
      hubItemByActiveRoute.long > 0
    ) {
      isActive = true;
    }
    return isActive;
  }, [activeRoute, hubItemByActiveRoute]);

  const getMapCenter = useCallback(() => {
    let viewport: GoongMapCoordinates = getDefaultGoongMapCoordinates;

    if (activeRoute && activeRoute.id > 0) {
      if (
        activeRoute.from_type === ShippingOrder.FROM_TYPE_OFFICE &&
        hubItemByActiveRoute !== undefined &&
        hubItemByActiveRoute.lat > 0
      ) {
        viewport.latitude = hubItemByActiveRoute.lat;
        viewport.longitude = hubItemByActiveRoute.long;
      }
    }

    return viewport;
  }, [activeRoute, hubItemByActiveRoute, getDefaultGoongMapCoordinates]);

  useEffect(() => {
    // reset active popup info when change activeRoute
    setActivePopupInfo(ShippingRouteModel.getDefaultGoongMapCoordinates());
  }, [activeRoute, getMapCenter]);

  return activeRoute && activeRoute.id > 0 ? (
    <GoongMapWrapper goongApiAccessToken={apiKey} latLng={getMapCenter()}>
      {isActiveMapMarkerOffice ? (
        <DeliveryDashboardSectionRightMapMarkerOffice
          setActivePopupInfo={setActivePopupInfo}
          setInfoWindow={setInfoWindow}
          setOffsetLeft={setOffsetLeft}
          setOffsetTop={setOffsetTop}
        />
      ) : null}

      {orderItemsByActiveRoute.length > 0
        ? orderItemsByActiveRoute.map((item, index) => {
            return (
              <DeliveryDashboardSectionRightMapMarkerOrder
                key={item.id}
                index={index}
                order={item}
                setActivePopupInfo={setActivePopupInfo}
                setInfoWindow={setInfoWindow}
                setOffsetLeft={setOffsetLeft}
                setOffsetTop={setOffsetTop}
              />
            );
          })
        : ""}

      {activePopupInfo.latitude > 0 && activePopupInfo.longitude > 0 ? (
        <GoongMapInfoWindow
          placement={"bottom"}
          mapCoordinates={activePopupInfo}
          children={infoWindow}
          offsetTop={offsetTop}
          offsetLeft={offsetLeft}
          onClose={() =>
            setActivePopupInfo(
              ShippingRouteModel.getDefaultGoongMapCoordinates()
            )
          }
        />
      ) : null}
    </GoongMapWrapper>
  ) : null;
};

export default DeliveryDashboardSectionRightGoongMapWrapper;
