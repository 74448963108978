import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";

import Helper from "common/utils/helper";
import {
  CommentJsonAddEdit,
  CommentJsonAddEditNew,
  FilterComment,
} from "common/types/Comment";
import CommentModel from "common/models/CommentModel";
import CommentCollection from "common/collections/CommentCollection";

const SERVICE_URL = "comments";

class CommentApi extends BaseApi {
  //
  async getList(props: FilterComment): Promise<CommentCollection> {
    let collection = new CommentCollection();
    const convertProps = Helper.convertParams(props);
    const queryData = {
      params: {
        ...convertProps,
        rate: props.rate > 0 ? props.rate : null,
        type: props.type >= 0 ? props.type : null,
        item_id: props.item_id ? props.item_id : null,
        is_user: props.is_user >= 0 ? props.is_user : null,
        item_type: props.item_type ? props.item_type : null,
        is_admin: props.is_admin > 0 ? props.is_admin : null,
        status: Number(props.status) > 0 ? props.status : -1,
        parent_id: props.parent_id > 0 ? props.parent_id : null,
        is_customer: props.is_customer > -1 ? props.is_customer : null,
        date_created: props.date_created > 0 ? props.date_created : null,
        customer_id: props.customer_id > -1 ? props.customer_id : null,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/admin",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getListReply(props: FilterComment): Promise<CommentCollection> {
    let collection = new CommentCollection();
    const convertProps = Helper.convertParams(props);
    const queryData = {
      params: {
        ...convertProps,
        rate: props.rate > 0 ? props.rate : null,
        type: props.type >= 0 ? props.type : null,
        item_id: props.item_id ? props.item_id : null,
        is_user: props.is_user >= 0 ? props.is_user : null,
        item_type: props.item_type ? props.item_type : null,
        is_admin: props.is_admin > 0 ? props.is_admin : null,
        status: Number(props.status) > 0 ? props.status : -1,
        parent_id: props.parent_id > 0 ? props.parent_id : null,
        is_customer: props.is_customer > -1 ? props.is_customer : null,
        date_created: props.date_created > 0 ? props.date_created : null,
        customer_id: props.customer_id > -1 ? props.customer_id : null,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/admin",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getDetail(id: number): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/admin/detail/" + id
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add(props: CommentJsonAddEdit): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/admin",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add2(props: CommentJsonAddEditNew): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/admin",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: CommentJsonAddEdit): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/admin/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async edit2(props: CommentJsonAddEditNew): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/admin/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editStatus(
    props: Pick<CommentJsonAddEdit, "id" | "status">
  ): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/admin/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editQA(props: CommentJsonAddEdit): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/admin/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editQA2(props: CommentJsonAddEditNew): Promise<CommentModel> {
    let item = new CommentModel(CommentModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/admin/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(
        SERVICE_URL + "/admin/delete/" + id
      );
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default CommentApi;
