import { Spin } from "antd";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import SupplierRepository from "common/repositories/SupplierRepository";
import ErrorAlert from "components/ErrorAlert";
import PurchaseOrderForm from "features/purchaseorder/form/PurchaseOrderForm";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

const PurchaseOrderFormWrapper = ({ model }: { model: PurchaseOrderModel }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  const setPurchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.setPurchaseOrder
  );

  //fetch supplier
  const fetchSupplier = useCallback(async () => {
    const mySupplier = await new SupplierRepository().getItem(
      model.supplier_id
    );
    setLoading(false);
    if (mySupplier.hasError()) {
      setErrors(mySupplier.error.errors);
    } else {
      setPurchaseOrder(model.toJson(), mySupplier.toJson());
    }
  }, [model, setPurchaseOrder]);

  //load supplier before go to view
  useEffect(() => {
    if (loading) {
      fetchSupplier();
    }
  }, [fetchSupplier, loading]);
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          {errors.length > 0 ? (
            <>
              <ErrorAlert
                items={errors}
                heading={t("common:error.error_fetching_data")}
                onRetry={() => {
                  setLoading(true);
                  fetchSupplier();
                }}
              />
            </>
          ) : (
            <PurchaseOrderForm />
          )}
        </>
      )}
    </>
  );
};

export default PurchaseOrderFormWrapper;
