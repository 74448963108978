import { cn } from "common/utils/utils";
import React from "react";

const TableInfoRow = ({
	label,
	content,
	className,
}: {
	content?: React.ReactNode;
	label?: React.ReactNode;
	className?: string;
}) => {
	return (
		<div className={cn(" leading-relaxed", className)}>
			<span className="text-xs uppercase text-gray-500 font-semibold">
				{label}:{" "}
			</span>
			{content}
		</div>
	);
};

export default TableInfoRow;
