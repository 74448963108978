import { Tag, Tooltip, Typography } from "antd";
import Role from "common/constants/Role";
import TextDateTime from "components/TextDateTime";
import TextStore from "components/TextStore";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
  IconBuildingStore,
  IconClock,
  IconPhone,
  IconUser,
} from "@tabler/icons-react";
import EcomRepository from "common/repositories/EcomRepository";
import { Ecomplatform } from "common/types/Ecom";
import OrderModel from "common/models/OrderModel";
import { OrderJson } from "common/types/Order";

const OrderFormSectionInfo = ({orderDefault} : { orderDefault?: OrderJson} ) => {
  const { t } = useTranslation();
  const orderState =  useEditingSaleOrderStore((state) => state.order);

  const order = orderDefault || orderState
  const iconProps = { size: 16, className: "mr-1 -mt-0.5" };

  const [channels, setChannels] = useState<Ecomplatform[]>([]);

  const accountRoleSubjects = useLoginAccountStore((state) =>
    state.account.role.map((r) => r.subject_id)
  );
  const checkRoleCustomerManage = useMemo(() => {
    return (
      [Role.CUSTOMER_MANAGE].filter((r) => accountRoleSubjects.includes(r))
        .length > 0
    );
  }, [accountRoleSubjects]);

  async function getChannels() {
    try {
      const api = new EcomRepository();
      const datas = await api.getItemsChannel();
      // data.
      if (datas) {
        setChannels(datas);
      }
    } catch (error) { }
  }
  const statusPayment = OrderModel.getStatusPayment(order.payment_status);


  
  const statusDeliver = OrderModel.getStatusDelivery(order.delivery_status);
  const statusInven = OrderModel.getStatusInven(order.inventory_status);


  useEffect(() => {
    getChannels()
  }, []);

  return (
    <div className="py-4 leading-7 opacity-80 hover:opacity-100">
      <div className=" flex gap-2 mb-2 items-center">
        <span>Trạng thái thanh toán:</span>
        <Tag className=" h-fit" bordered color={statusPayment?.color}>{statusPayment?.label}</Tag>

      </div>
      <div className=" flex gap-2 items-center mb-2">
        <span>Trạng thái phiếu kho:</span>
        <Tag  className=" h-fit" bordered color={statusInven?.color}>{statusInven?.label}</Tag>

      </div>
      <div className=" flex gap-2 items-center">
        <span>Trạng thái giao hàng:</span>
        <Tag  className=" h-fit" bordered color={statusDeliver?.color}>{statusDeliver?.label}</Tag>

      </div>
      <div>
        <span className="text-gray-400 mr-1">
          <IconUser {...iconProps} />
          {t("order:customer")}:
        </span>
        {checkRoleCustomerManage ? (
          <Link
            className="text-blue-700 hover:text-blue-500"
            to={"/customer?id=" + order.customer_id}
            target="_blank"
          >
            {order.billing_fullname}&nbsp;
            <Tooltip title={t("customer:id")}>
              <span className="text-gray-400 cursor-default hover:text-black">
                #{order.customer_id}
              </span>
            </Tooltip>
          </Link>
        ) : (
          <>
            {order.billing_fullname}&nbsp;
            <Tooltip title={t("customer:id")}>
              <span className="text-gray-400 cursor-default hover:text-black">
                #{order.customer_id}
              </span>
            </Tooltip>
          </>
        )}
        <br />
        <span className="text-gray-400 mr-1">
          <IconPhone {...iconProps} />
          {t("order:billing_phone")}:
        </span>{" "}
        {order.billing_phone}
      </div>

      <div>
        <span className="text-gray-400 mr-1">
          <IconClock {...iconProps} />
          {t("order:date_created")}:
        </span>

        <TextDateTime format="HH:mm, DD/MM/YYYY" ts={order.date_created} />
      </div>
      <div>
        <span className="text-gray-400 mr-1">
          <IconBuildingStore {...iconProps} />
          {t("order:store")}:
        </span>
        <TextStore id={order.store_id} />
      </div>
      <div className="mb-2">
        <span className="text-gray-400 mr-1">
          Kênh bán hàng: <strong>{channels.find(chanel => chanel.id === order.sale_channel)?.title}</strong>
        </span>
      </div>
    </div>
  );
};

export default OrderFormSectionInfo;
