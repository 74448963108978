import { Skeleton } from "antd";
import OfficeModel from "common/models/OfficeModel";
import OfficeRepository from "common/repositories/OfficeRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import OfficeForm from "features/office/form/OfficeForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const OfficeFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: OfficeModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<OfficeModel>(
    new OfficeModel(OfficeModel.getDefaultData()),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new OfficeRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={id > 0 ? t("office:heading_edit") : t("office:heading_add")}
      width={720}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="office-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <OfficeForm
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <OfficeForm
          model={new OfficeModel(OfficeModel.getDefaultData())}
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default OfficeFormModal;
