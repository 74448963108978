import { PageDataTableColumnInfo } from 'common/types/Table';
import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

type PageDataColumnToggleStore = {
  tables: PageDataTableColumnInfo[];
  getTable: (table: string) => PageDataTableColumnInfo | undefined;
  updateTable: (info: PageDataTableColumnInfo) => void;
};

//init store data
let store: StateCreator<PageDataColumnToggleStore> = (set, get) => ({
  tables: [],
  getTable: (table: string) => get().tables.find((t) => t.table === table),
  updateTable: (info: PageDataTableColumnInfo) => {
    const foundTable = get().tables.find((t) => t.table === info.table);
    if (typeof foundTable !== "undefined") {
      //update cart item
      set((state) => ({
        ...state,
        tables: get().tables.map((t) => (t.table === info.table ? info : t)),
      }));
    } else {
      //insert new info
      set((state) => ({
        ...state,
        tables: [...state.tables, info],
      }));
    }
  },
});

//create store
const usePageDataColumnToggleStore = create(
  persist(store, { name: "MAIN::PageDataTableColumnToggle" })
);

export default usePageDataColumnToggleStore;
