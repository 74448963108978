// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report_number,
.report_time_series {
  border: 1px solid #eee;
  border-radius: 2px;
}

.report_number .ant-statistic-content-suffix {
  float: right;
}

.report_number .ant-statistic-content-value {
  margin-left: 5px;
  font-size: 24px;
  font-weight: bold;
}

.report_error_number .ant-statistic-content-value {
  display: none;
}

.ant-statistic .report_error {
  font-size: 12px;
  color: red;
  line-height: 1;
}

.ant-statistic .report_error ul {
  list-style-type: none;
}

.report_change {
  font-size: 12px;
}

.report_change_up {
  color: green;
}
.report_change_down {
  color: red;
}

.report_chart_tooltip_list {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}

.report_chart_tooltip_list_item {
  list-style-type: none;
  padding: 0px;
  margin: 12px 0px;
}

.report_chart_tooltip_marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.report_chart_tooltip_value {
  display: inline-block;
  float: right;
  margin-left: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/css/report.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".report_number,\n.report_time_series {\n  border: 1px solid #eee;\n  border-radius: 2px;\n}\n\n.report_number .ant-statistic-content-suffix {\n  float: right;\n}\n\n.report_number .ant-statistic-content-value {\n  margin-left: 5px;\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.report_error_number .ant-statistic-content-value {\n  display: none;\n}\n\n.ant-statistic .report_error {\n  font-size: 12px;\n  color: red;\n  line-height: 1;\n}\n\n.ant-statistic .report_error ul {\n  list-style-type: none;\n}\n\n.report_change {\n  font-size: 12px;\n}\n\n.report_change_up {\n  color: green;\n}\n.report_change_down {\n  color: red;\n}\n\n.report_chart_tooltip_list {\n  margin: 0px;\n  list-style-type: none;\n  padding: 0px;\n}\n\n.report_chart_tooltip_list_item {\n  list-style-type: none;\n  padding: 0px;\n  margin: 12px 0px;\n}\n\n.report_chart_tooltip_marker {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  display: inline-block;\n  margin-right: 8px;\n}\n\n.report_chart_tooltip_value {\n  display: inline-block;\n  float: right;\n  margin-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
