import FileCollection from "common/collections/FileCollection";
import FileModel from "common/models/FileModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type { FilterFile, FileJsonAddEdit } from "common/types/File";

const SERVICE_URL = "/files";

class FileApi extends BaseApi {
  async getList(props: GetListProps<FilterFile>): Promise<FileCollection> {
    let collection = new FileCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          directory_id:
            typeof filters.directory_id === "number" &&
            filters.directory_id >= 0
              ? filters.directory_id
              : null,
          object_type:
            typeof filters.object_type === "number" && filters.object_type >= 0
              ? filters.object_type
              : null,
          origin: filters.origin,
          list_file_ids : filters.list_file_ids
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<FileModel> {
    let item = new FileModel(FileModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new FileModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: FileJsonAddEdit): Promise<FileModel> {
    let item = new FileModel(FileModel.getDefaultData());

    try {
      let postData = {
        ...data,
        directory_id: data.directory_id,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new FileModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: FileJsonAddEdit): Promise<FileModel> {
    let item = new FileModel(FileModel.getDefaultData());

    try {
      let postData = {
        ...data,
        directory_id: data.directory_id,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new FileModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  getUploadProps({
    directory_id,
    object_type,
    object_id,
    origin,
  }: {
    directory_id: number;
    object_type: number;
    object_id: number;
    origin: string;
  }): Object {
    return {
      action: AxiosClient().defaults.baseURL + SERVICE_URL,
      // withCredentials: true,
      data: {
        company_id: BaseApi.loginAccount.company.id,
        creator_id: BaseApi.loginAccount.id,
        directory_id: directory_id,
        is_directory: 0,
        status: 1,
        object_type: object_type,
        object_id: object_id,
        origin: origin,
      },
      headers: {
        "X-Requested-With": null,
        Authorization: BaseApi.loginAccount.jwt,
      },
      name: "file",
    };
  }
}

export default FileApi;
