import { Button, Image, Tooltip } from "antd";
import ProductCollection from "common/collections/ProductCollection";
import Product from "common/constants/Product";
import Role from "common/constants/Role";
import ProductModel from "common/models/ProductModel";
import ProductRepository from "common/repositories/ProductRepository";
import { FilterProduct } from "common/types/Product";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { TableColumnsType } from "common/types/Table";
import NoPhoto from "components/NoPhoto";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableLinkText from "components/table/TableLinkText";
import ProductFormModal from "features/product/form/ProductFormModal";
import ProductSyncFormModal from "features/product/form/ProductSyncFormModal";
import ProductListHeader from "features/product/list/ProductListHeader";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconEye, IconPerfume, IconTag } from "@tabler/icons-react";

import StoreCollection from "common/collections/StoreCollection";
import StoreModel from "common/models/StoreModel";
import StoreRepository from "common/repositories/StoreRepository";
import { FileJson } from "common/types/File";
import { FilterStore } from "common/types/Store";
import ProductItemExpand from "../item/ProductItemExpand";
import ProductItemSummary from "../item/ProductItemSummary";
import ProductListFilter from "./ProductListFilter";
import ProductTagModal from "../tag/ProductTagModal";
import FileModel from "common/models/FileModel";
import ProductRelatedModal from "../productrelated/ProductRelatedModal";

const ProductList = () => {
	const { t } = useTranslation();
	const enableResyncProductCompanyResource = false;

	//////////////////////////////////////////
	// modal sync product
	const [formProductSyncVisible, setFormProductSyncVisible] = useState(false);
	//////////////////////////////////////////
	//Filtering
	const [categoryItems] =
		useDatabaseTable<ProductCategoryJson>("productcategory");

	const defaultFilters: FilterProduct = useMemo(
		() => ProductRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] = useStateFilter<FilterProduct>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	const defaultFiltersStore: FilterStore = useMemo(
		() => StoreRepository.getDefaultFilters(),
		[]
	);
	const [filtersStore, setFiltersStore] =
		useState<FilterStore>(defaultFiltersStore);
	const [storeList, setStoreList] = useState<StoreModel[]>([]);

	//fetchDataStore
	const fetchDataStoreList = useCallback(async (): Promise<StoreCollection> => {
		let collection = await new StoreRepository().getItems({
			filters: { ...filtersStore, limit: 50 },
		});
		if (collection.items.length > 0) {
			setStoreList(collection.items);
			setFilters({ ...filters, store_id: collection.items[0].id });
		}
		return collection;
	}, [filtersStore]);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<ProductModel[]>([]);
	const fetchData = useCallback(async (): Promise<ProductCollection> => {
		let collection = new ProductCollection();
		if (storeList.length > 0) {
			collection = await new ProductRepository().getItems(
				{
					filters: { ...filters, store_id: storeList[0].id },
				},
				categoryItems
			);
			setDataSource(collection.items);
			setTotal(collection.total);
		} else {
			await fetchDataStoreList();
		}
		return collection;
	}, [filters, storeList]);

	// modal editing
	const [addVisible, setAddVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

	//modal tag
	const [addTagVisible, setAddTagVisible] = useState(false);
	const [editingTagId, setEditingTagId] = useState(0);

	const expandable = useMemo(
		() => ({
			expandedRowRender: (record: ProductModel) => {
				return <ProductItemExpand record={record} />;
			},
			expandedRowClassName: () => "ant-table-expanded-row-dimmer",
			expandRowByClick: true,
			showExpandColumn: false,
			expandedRowKeys: expandedRowKeys,
			onExpand: (expanded: boolean, record: ProductModel) => {
				if (expanded) {
					setExpandedRowKeys([record.id]);
				} else {
					setExpandedRowKeys([]);
				}
			},
		}),
		[expandedRowKeys]
	);

	//product
	const [addProductRelatedVisible, setAddProductRelatedVisible] =
		useState(false);
	const [editingProductRelatedId, setEditingProductRelatedId] =
		useState<number>(0);
	const [chooseProductRelated, setChooseProductRelated] =
		useState<ProductModel>(new ProductModel(ProductModel.getDefaultData()));

	const onEditProductRelated = (record: ProductModel) => {
		setEditingProductRelatedId(record.id);
		setChooseProductRelated(record);
		setAddProductRelatedVisible(true);
	};

	//Table columns
	const columns: TableColumnsType<ProductModel> = [
		{
			title: t("product:id"),
			key: "id",
			sortby: "id",
			width: 70,
			align: "center",
			toggletype: "extend",
			render: (id, record) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<TableLinkText
							title={t("common:table.edit")}
							onClick={() => onEdit(id)}>
							{id}
						</TableLinkText>
					</div>
				);
			},
		},
		{
			title: t("product:image_short"),
			key: "images",
			align: "center",
			width: 50,
			render: (images: FileJson[], record) => {
				return images.length > 0 ? (
					<div onClick={(e) => e.stopPropagation()}>
						<Image
							width="40"
							// src={FileModel.getThumbnailFromUrl(images[0].file_path)}
							// src={FileModel.getThumbnailFromUrl(
							//   images[0].file_path,
							//   40,
							//   40
							// )}
							src={FileModel.getThumbnailFromUrl(images[0].url)}
							preview={{
								src: images[0].url,
								mask: <IconEye size="16" />,
							}}
						/>
					</div>
				) : (
					<NoPhoto width={40} height={40} />
				);
			},
		},
		{
			title: t("product:name"),
			key: "name",
			sortby: "name",
			render: (name: string, record) => {
				return (
					<>
						{/* <>
              {record.sell_on_zero === 1 ? (
                <div className="float-right pt-2">
                  <Tooltip
                    title={t("product:sell_on_zero_tag")}
                    mouseEnterDelay={0.4}
                  >
                    <IconLockOpen className="text-green-500" size={18} />
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </> */}

						<div className="text-blue-600 text-md font-semibold">
							{record.full_name.length > 0 ? record.full_name : record.name}
						</div>
						<div className="text-xs text-gray-400 mt-1">
							<ProductItemSummary record={record} />
						</div>
					</>
				);
			},
		},
		{
			title: t("product:code"),
			toggletype: "extend",
			key: "code",
			sortby: "code",
			width: 180,
		},
		{
			title: t("product:category_id"),
			key: "category_id",
			width: 160,
			render: (category_id: number) => {
				if (category_id > 0) {
					const foundCategory = categoryItems.find((i) => i.id === category_id);
					if (typeof foundCategory !== "undefined") {
						return <span>{foundCategory.name}</span>;
					} else {
						return "ID #" + category_id;
					}
				} else {
					return "-";
				}
			},
		},
		{
			title: t("common:status"),
			key: "status",
			sortby: "status",
			render: (status: number) => {
				let statusInfo = ProductModel.getStatus(status);
				return (
					<span
						className={
							status === Product.STATUS_DISABLED ? "text-red-500" : ""
						}>
						{statusInfo?.label}
					</span>
				);
			},
			width: 110,
		},
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (_: any, record: ProductModel) => {
				// const unitInfo = ProductModel.getUnit(record.unit);

				return (
					<RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
						<div onClick={(e) => e.stopPropagation()}>
							<TableEdit link="" onClick={() => onEdit(record.id)} />
							<Button
								size={"small"}
								type={"text"}
								onClick={() => onEditTag(record.id)}>
								<Tooltip title="Quản lý tag">
									<IconTag
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
							<Button
								size={"small"}
								type={"text"}
								onClick={() => onEditProductRelated(record)}>
								<Tooltip title="Sản phẩm liên quan">
									<IconPerfume
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
							<TableDelete
								error_translate_prefix="product:form.error"
								onDeleteCallback={(id) => {
									setDataSource(dataSource.filter((item) => item.id !== id));
								}}
								repository={new ProductRepository()}
								id={record.id}
							/>
							{/* <TableInfo record={record}>
                <TableInfoRow
                  label={t("product:unit_label")}
                  content={<>{unitInfo?.label}</>}
                />
              </TableInfo> */}
						</div>
					</RoleCheck>
				);
			},
		},
	];

	const onEdit = (id: number) => {
		setEditingId(id);
		setAddVisible(true);
	};

	const onSaveSuccess = (item: ProductModel) => {
		//detech this is NEW or UPDATE
		const foundIndex = dataSource.findIndex((r) => r.id === item.id);
		if (foundIndex >= 0) {
			//update current role item info
			setDataSource(
				update(dataSource, {
					[foundIndex]: {
						$set: item,
					},
				})
			);
		} else {
			//append new item to list
			setDataSource(
				update(dataSource, {
					$unshift: [item],
				})
			);
		}
	};

	const onEditTag = (id: number) => {
		setEditingTagId(id);
		setAddTagVisible(true);
	};



	const dataldev = [
		{
			id: 745228,
			handle: "tuan-le-thuong-hieu",
			title: "Tuần Lễ Thương Hiệu",
			type: "collection",
			from_time: 1712831589,
			to_time: 0,
			link: "/collections/tuan-le-thuong-hieu",
			link_more: "/collections/tuan-le-thuong-hieu",
			title_more: "Xem zui",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "tuan-le-thuong-hieu",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			id: 1004120518,
			type: "collection",
			title: "Deal Thơm",
			title_more: "Xem thêm",
			handle: "deal-thom",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/deal-thom",
			link_more: "collections/deal-thom",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "deal-thom",
					store_id: 411209,
					parent_id: 0,
				},
			},
		},
		{
			id: 794249,
			type: "collection",
			title: "New Arrivals",
			title_more: "Xem thêm",
			handle: "nuoc-hoa-moi",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-moi",
			link_more: "collections/nuoc-hoa-moi",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-moi",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 794151,
			title: "Bestsellers",
			title_more: "Xem thêm",
			handle: "bestsellers",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/yeu-thich-nhat",
			link_more: "collections/yeu-thich-nhat",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "yeu-thich-nhat",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "section_banner",
			id: 0,
			handle: "banner_nnn",
			from_time: 1712136268,
			title_more: "Xem thêm",
			to_time: 0,
			link: "",
			link_more: "",
			title: "",
		},
		{
			type: "collection",
			id: 452899,
			title: "Nước Hoa Mini",
			title_more: "Xem thêm",
			handle: "nuoc-hoa-mini",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-mini",
			link_more: "collections/nuoc-hoa-mini",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-mini",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 707613,
			title: "Tuần lễ thương hiệu Hugo Boss",
			title_more: "Xem thêm",
			handle: "thuong-hieu-hugo-boss",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/thuong-hieu-hugo-boss",
			link_more: "collections/thuong-hieu-hugo-boss",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "thuong-hieu-hugo-boss",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 452892,
			title: "Giftset",
			title_more: "Xem thêm",
			handle: "giftset-nuoc-hoa",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/giftset-nuoc-hoa",
			link_more: "collections/giftset-nuoc-hoa",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "giftset-nuoc-hoa",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 794282,
			title: "Bodycare & Homecare",
			title_more: "Xem thêm",
			handle: "bodycare-homecare",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/bodycare-homecare",
			link_more: "collections/bodycare-homecare",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "bodycare-homecare",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "namperfumetv",
			id: 0,
			handle: "",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "namperfume TV",
			title_more: "Xem thêm",
		},
		{
			type: "nmagazine",
			id: 509,
			handle: "brand_nmagazine",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Nmagazine",
			title_more: "Xem thêm",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_1",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_2",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
		},
	];

	return (
		<>
			<ProductListHeader>
				{enableResyncProductCompanyResource ? (
					<Button
						type="default"
						className="mr-3"
						onClick={() => {
							setFormProductSyncVisible(true);
						}}>
						{t("product:sync_product")}
					</Button>
				) : null}
				{/* <RoleCheck roles={[Role.PRODUCT_MANAGE]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(-1);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck> */}
			</ProductListHeader>
			<ProductListFilter
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
				list_store={storeList.map((item) => {
					return { label: item.name, value: item.id };
				})}
			/>
			<PageDataTable<FilterProduct, ProductModel, ProductCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					expandable,
					tableColumnToggleKey: "product",
					rowClassName: "cursor-pointer",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			{enableResyncProductCompanyResource ? (
				<ProductSyncFormModal
					key={"product-sync-modal"}
					open={formProductSyncVisible}
					setOpen={(isOpen) => {
						setFormProductSyncVisible(isOpen);
					}}
				/>
			) : null}

			<ProductFormModal
				store_id={filters.store_id}
				id={editingId}
				key={editingId}
				open={addVisible}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingId(-1);
					}
					setAddVisible(isOpen);
				}}
				onSaveSuccess={onSaveSuccess}
			/>

			<ProductTagModal
				id={editingTagId}
				key={editingTagId}
				open={addTagVisible}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingTagId(0);
					}
					setAddTagVisible(isOpen);
				}}
				onSaveSuccess={onSaveSuccess}
			/>

			<ProductRelatedModal
				id={editingProductRelatedId}
				data={chooseProductRelated}
				key={editingProductRelatedId}
				open={addProductRelatedVisible}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingProductRelatedId(0);
					}
					setAddProductRelatedVisible(isOpen);
				}}
				onSaveSuccess={onSaveSuccess}
			/>
		</>
	);
};

export default ProductList;
