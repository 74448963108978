import { Button, Input, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCompanySettingStore from "zustands/useCompanySettingStore";

import { IconMap } from "@tabler/icons-react";

import AddressInputAutocomplete from "./AddressInputAutocomplete";
import AddressInputLatLong from "./AddressInputLatLong";

import type {
  AddressValue,
  AddressInputProps,
} from "common/interfaces/AddressInput";
const AddressInput: React.FC<AddressInputProps> = ({
  value,
  onChange,
}: AddressInputProps) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState(value?.address);
  const [latlong, setLatLong] = useState(value?.lat + ", " + value?.long);
  const [, setMapPlaceId] = useState(value?.map_place_id);

  const useAutocomplete = useCompanySettingStore(
    (state) => state.getSetting("map_address_autocomplete") || 0
  );

  const mapViewSource = useCompanySettingStore(
    (state) => state.getSetting("map_view_source") || "google"
  );

  const triggerChange = useCallback(
    (changedValue: AddressValue) => {
      onChange?.({ ...value, ...changedValue });
    },
    [onChange, value]
  );

  const updateAddress = (newAddress: string) => {
    setAddress(newAddress);
    setLatLong("0, 0");
    setMapPlaceId("");

    triggerChange({
      address: newAddress,
      lat: 0,
      long: 0,
      map_place_id: "",
    });
  };

  const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateAddress(e.target.value);
  };

  const goToExternalMapPage = useCallback(() => {
    //Only routing to Google Maps page
    const url = t("common:address_input:open_map_query_url_google", {
      query: encodeURI(address || ""),
    });

    window.open(url, "_blank");
  }, [address, t]);

  useEffect(() => {
    setAddress(value?.address);
    setLatLong(value?.lat + ", " + value?.long);
    setMapPlaceId(value?.map_place_id);
  }, [value]);

  //sub components
  const queryMapButton = (
    <Tooltip
      title={
        <small>
          {t("common:address_input.open_map_tooltip")} <br />
          <a
            href={t("common:address_input.open_map_link") ?? ""}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#e5ffdb" }}
          >
            {t("common:address_input.open_map_more")}
          </a>
        </small>
      }
    >
      <Button
        disabled={address?.length === 0}
        type="link"
        size="small"
        onMouseDown={() => goToExternalMapPage()}
        icon={<IconMap size="14" style={{ marginRight: 10 }} />}
      >
        {t("common:address_input.open_map")}
      </Button>
    </Tooltip>
  );

  return (
    <>
      {useAutocomplete ? (
        <AddressInputAutocomplete
          service={mapViewSource.toString()}
          address={address || ""}
          setAddress={setAddress}
          triggerChange={triggerChange}
          updateAddress={updateAddress}
          queryMapButton={queryMapButton}
        />
      ) : (
        <Input
          style={{ zIndex: 1 }}
          size="middle"
          allowClear
          value={address}
          onChange={onChangeAddress}
          addonAfter={queryMapButton}
        />
      )}
      <AddressInputLatLong
        value={latlong}
        onChange={(lat: number, long: number) =>
          triggerChange({
            address: address || "",
            lat,
            long,
            map_place_id: "",
          })
        }
      />
    </>
  );
};

export default AddressInput;
