import PromotionApi from "common/api/PromotionApi";
import PromotionCollection from "common/collections/PromotionCollection";
import PromotionCheckResultModel from "common/models/PromotionCheckResultModel";
import PromotionGiftCheckResultModel from "common/models/PromotionGiftCheckResultModel";
import PromotionModel from "common/models/PromotionModel";
import { GetListProps } from "common/types/Filter";
import {
  PromotionCheckQuery,
  PromotionGiftCheckQuery
} from "common/types/PromotionCheck";

import BaseRepository from "./BaseRepository";

import type {
  PromotionJsonAddEdit,
  FilterPromotion
} from "common/types/Promotion";
class PromotionRepository extends BaseRepository<PromotionApi> {
  _api: PromotionApi | null;

  constructor() {
    super();
    this._api = new PromotionApi(true);
  }

  static getDefaultFilters(): FilterPromotion {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
      group: -1,
      type: -1,
      date_started: -1,
      date_ended: -1
    };
  }

  async getItems(props: GetListProps<FilterPromotion>) {
    return this._api
      ? await this._api.getList(props)
      : new PromotionCollection();
  }

  async getItem(id: number): Promise<PromotionModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new PromotionModel(PromotionModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: PromotionJsonAddEdit): Promise<PromotionModel> {
    if (this._api) {
      if (data.id > 0) {
    
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new PromotionModel(PromotionModel.getDefaultData());
    }
  }

  async posCheckPromotions(
    data: PromotionCheckQuery
  ): Promise<PromotionCheckResultModel> {
    if (this._api) {
      return await this._api?.check(data);
    } else {
      return new PromotionCheckResultModel(
        PromotionCheckResultModel.getDefaultData()
      );
    }
  }

  async posCheckGift(
    data: PromotionGiftCheckQuery
  ): Promise<PromotionGiftCheckResultModel> {
    if (this._api) {
      return await this._api?.checkGift(data);
    } else {
      return new PromotionGiftCheckResultModel(
        PromotionGiftCheckResultModel.getDefaultData()
      );
    }
  }
}

export default PromotionRepository;
