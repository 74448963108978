import BaseCollection from "./BaseCollection";

import ProductCollectionModel from "common/models/ProductCollectionModel";
import {
    ProductCollectionCollectionJson,
    ProductCollectionJson,
} from "common/types/ProductCollection";

class ProductCollectionCollection extends BaseCollection<
  ProductCollectionJson,
  ProductCollectionModel,
  ProductCollectionCollectionJson
> {
  itemsFromJson(jsonItems: ProductCollectionJson[]): ProductCollectionModel[] {
    return jsonItems.map((item) => new ProductCollectionModel(item));
  }
}
export default ProductCollectionCollection;
