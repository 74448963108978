import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const OfficeListHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("office:heading_list")}
      siteMenuOpenKey="setting_hrm"
      siteMenuSelectedKey="/office"
    >
      {children}
    </PageHeader>
  );
};

export default OfficeListHeader;
