import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";

import PageHeader from "components/page/PageHeader";

const ProductCollectionFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("productcollection:heading_nav"),
      link: "/productcollection",
    },
    {
      text: t(isEditing ? "productcollection:heading_edit" : "productcollection:heading_add"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t(
        isEditing ? "productcollection:heading_edit" : "productcollection:heading_add"
      )}
      nav={nav}
      siteMenuOpenKey="product"
      siteMenuSelectedKey="/productcollection"
      siteMenuHidden
    ></PageHeader>
  );
};

export default ProductCollectionFormHeader;
