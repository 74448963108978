import BaseCollection from "./BaseCollection";

//////////////////////////////////
//Phần thay thế
import InventoryModel from "common/models/InventoryModel";
import { InventoryJson, InventoryCollectionJson } from "common/types/Inventory";

class InventoryCollection extends BaseCollection<
	InventoryJson,
	InventoryModel,
	InventoryCollectionJson
> {
	itemsFromJson(jsonItems: InventoryJson[]): InventoryModel[] {
		return jsonItems.map((item) => new InventoryModel(item));
	}
}
export default InventoryCollection;
