import ProductReceiptSyncStockCollection from 'common/collections/ProductReceiptSyncStockCollection';
import ProductReceiptSyncStockModel from 'common/models/ProductReceiptSyncStockModel';
import {
    FilterProductReceiptSyncStock, ProductReceiptSyncStockJsonAdd
} from 'common/types/ProductReceiptSyncStock';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

import type { GetListProps } from "common/types/Filter";
const SERVICE_URL = "/productreceiptsyncstocks";

class ProductReceiptSyncStockApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProductReceiptSyncStock>
  ): Promise<ProductReceiptSyncStockCollection> {
    let collection = new ProductReceiptSyncStockCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          warehouse_id: filters.warehouse_id >= 0 ? filters.warehouse_id : null,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/waiting",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async add(
    data: ProductReceiptSyncStockJsonAdd
  ): Promise<ProductReceiptSyncStockModel> {
    let item = new ProductReceiptSyncStockModel(
      ProductReceiptSyncStockModel.getDefaultData()
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/waiting",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ProductReceiptSyncStockModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ProductReceiptSyncStockApi;
