import WarehouseCollection from "common/collections/WarehouseCollection";
import Role from "common/constants/Role";
import WarehouseModel from "common/models/WarehouseModel";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { TableColumnsType } from "common/types/Table";
import { FilterWarehouse } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import WarehouseListHeader from "features/warehouse/list/WarehouseListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import WarehouseListFilter from "./WarehouseListFilter";

const WarehouseList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterWarehouse = useMemo(
    () => WarehouseRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterWarehouse>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<WarehouseModel[]>([]);
  const fetchData = useCallback(async (): Promise<WarehouseCollection> => {
    let collection = await new WarehouseRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
    return collection;
  }, [filters]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<WarehouseModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            link={"/warehouse/edit/id/" + record.id}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("warehouse:name"),
      key: "name",
      sortby: "name",
    },
    {
      title: t("warehouse:code"),
      key: "code",
      sortby: "code",
      width: 120,
    },
    {
      title: t("warehouse:address"),
      key: "address",
    },
    {
      title: t("warehouse:contact_fullname"),
      key: "contact_fullname",
      width: 180,
    },
    {
      title: t("warehouse:contact_phone"),
      key: "contact_phone",
      width: 140,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: WarehouseModel) => (
        <RoleCheck roles={[Role.SETTING_SALE]} hideOnFail>
          <TableEdit link={"/warehouse/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="warehouse:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new WarehouseRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <WarehouseListHeader />
      <WarehouseListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<FilterWarehouse, WarehouseModel, WarehouseCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default WarehouseList;
