import ShippingRouteCollection from "common/collections/ShippingRouteCollection";
import ShippingRouteModel from "common/models/ShippingRouteModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterShippingRoute,
  ShippingRouteDistanceTwoPoint,
  ShippingRouteJsonAddEdit,
  ShippingRouteTwoPointCoordinates,
} from "common/types/ShippingRoute";

const SERVICE_URL = "/shippingroutes";

class ShippingRouteApi extends BaseApi {
  async getList(
    props: GetListProps<FilterShippingRoute>,
  ): Promise<ShippingRouteCollection> {
    let collection = new ShippingRouteCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,

          creator_id: +filters.creator_id > 0 ? filters.creator_id : null,
          shipper_id: +filters.shipper_id > 0 ? filters.shipper_id : null,
          from_warehouse_id:
            +filters.from_warehouse_id > 0 ? filters.from_warehouse_id : null,
          ymd: +filters.ymd > 0 ? filters.ymd : null,
          id: +filters.id > 0 ? filters.id : null,
          ymdstart: +filters.ymdstart > 0 ? filters.ymdstart : null,
          ymdend: +filters.ymdend > 0 ? filters.ymdend : null,
          date_started: +filters.date_started > 0 ? filters.date_started : null,
          date_ended: +filters.date_ended > 0 ? filters.date_ended : null,
          not_status_list: filters.not_status_list
            ? filters.not_status_list
            : null,
          get_full_info: filters.get_full_info,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ShippingRouteModel> {
    let item = new ShippingRouteModel(ShippingRouteModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ShippingRouteModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: ShippingRouteJsonAddEdit): Promise<ShippingRouteModel> {
    let item = new ShippingRouteModel(ShippingRouteModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ShippingRouteModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: ShippingRouteJsonAddEdit): Promise<ShippingRouteModel> {
    let item = new ShippingRouteModel(ShippingRouteModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new ShippingRouteModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async getDistanceTwoPoint(
    coordinates: ShippingRouteTwoPointCoordinates,
  ): Promise<ShippingRouteDistanceTwoPoint> {
    let result = ShippingRouteModel.getDefaultDataCoordinates();

    try {
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/distancetwopoints",
        coordinates,
      );
      if (response.hasOwnProperty("data")) {
        result = response.data;
      }
    } catch (error) {
      //
    }

    return result;
  }
}

export default ShippingRouteApi;
