import { Button, Popover, Tooltip } from "antd";
import FileModel from "common/models/FileModel";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconPhotoPlus, IconUpload } from "@tabler/icons-react";

const FileUploaderPopover = ({
  directoryId,
  objectType,
  origin,
  onUploadCompleted,
  onDeleteCompleted,
  allowExtensions,
  disabled,
}: {
  directoryId: number;
  objectType: number;
  origin: string;
  onUploadCompleted: (f: FileModel) => void;
  onDeleteCompleted: (f: FileModel) => void;
  allowExtensions: string[];
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const uploadPopupRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const btn =
    allowExtensions.length === 0 ? (
      <Button
        title={t("file:button_upload_image_title", {
          extensions: FileModel.getImageExtensions()
            .map((i) => "." + i.toUpperCase())
            .join(", "),
        })}
        size="small"
        type="primary"
        icon={<IconPhotoPlus size="14" style={{ marginRight: 5 }} />}
        disabled={disabled}
        danger={disabled}
      >
        {t("file:button_upload_image")}
      </Button>
    ) : (
      <Button
        title={t("file:button_upload_file_title", {
          extensions: FileModel.getAllSupportUploadExtensions()
            .map((i) => "." + i.toUpperCase())
            .join(", "),
        })}
        size="small"
        type="primary"
        icon={<IconUpload size="14" style={{ marginRight: 5 }} />}
        disabled={disabled}
        danger={disabled}
      >
        {t("file:button_upload_file")}
      </Button>
    );

  if (disabled) {
    return <Tooltip title={t("file:not_permission_upload")}>{btn}</Tooltip>;
  }

  return (
    <>
      <Popover
        content={
          <FileUploaderButton
            directoryId={directoryId}
            objectType={objectType}
            origin={origin}
            onUploadCompleted={onUploadCompleted}
            onDeleteCompleted={onDeleteCompleted}
            allowExtensions={allowExtensions}
            uploadRenderType="list"
            allowHideList
          />
        }
        trigger="click"
        placement="bottomRight"
        open={open}
        onOpenChange={(value) => setOpen(value)}
        overlayInnerStyle={{ width: 360 }}
        getPopupContainer={() => uploadPopupRef.current}
      >
        {btn}
      </Popover>
      <div ref={uploadPopupRef}></div>
    </>
  );
};

export default FileUploaderPopover;
