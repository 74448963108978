import CompanySettingModel from "common/models/CompanySettingModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { CompanySettingEntry } from "common/types/CompanySetting";
const SERVICE_URL = "/companysettings";

class CompanySettingApi extends BaseApi {
  async getCurrentSettings(): Promise<CompanySettingModel> {
    let item = new CompanySettingModel(CompanySettingModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/setting");
      if (response.hasOwnProperty("data")) {
        item = new CompanySettingModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async updateSettingGeneral(
    data: CompanySettingEntry
  ): Promise<CompanySettingModel> {
    let item = new CompanySettingModel(CompanySettingModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/updatesettinggeneral",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CompanySettingModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async updateSettingTax(
    data: CompanySettingEntry
  ): Promise<CompanySettingModel> {
    let item = new CompanySettingModel(CompanySettingModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/updatesettingtax",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CompanySettingModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default CompanySettingApi;
