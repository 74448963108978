import { Skeleton } from "antd";
import ProductCategoryModel from "common/models/ProductCategoryModel";
import ProductCategoryRepository from "common/repositories/ProductCategoryRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import ProductCategoryForm from "features/productcategory/form/ProductCategoryForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductCategoryFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: ProductCategoryModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<ProductCategoryModel>(
    new ProductCategoryModel(ProductCategoryModel.getDefaultData()),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new ProductCategoryRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={
        id > 0
          ? t("productcategory:heading_edit")
          : t("productcategory:heading_add")
      }
      width={480}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="productcategory-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <ProductCategoryForm
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <ProductCategoryForm
          model={
            new ProductCategoryModel(ProductCategoryModel.getDefaultData())
          }
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default ProductCategoryFormModal;
