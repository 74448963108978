const ProjectTask = {
  STATUS_PENDING: 1,
  STATUS_ASSIGNED: 3,
  STATUS_WORKING: 5,
  STATUS_NEED_FOR_APPROVAL: 6,
  STATUS_REVIEWING: 7,
  // STATUS_TESTING: 8,
  STATUS_COMPLETED_WITH_BUG: 9,
  STATUS_COMPLETED: 11,
  STATUS_CANCELLED: 13,

  PRIORITY_IMMEDIATE: 1,
  PRIORITY_HIGH: 3,
  PRIORITY_MEDIUM: 5,
  PRIORITY_LOW: 7,

  TYPE_FEATURE: 1,
  TYPE_ISSUE: 3,
  TYPE_ENHANCEMENT: 5,
  TYPE_PROPOSE: 7,
  TYPE_DESIGN: 9,

  LAYOUT_LIST: 1,
  LAYOUT_KANBAN: 3,
};

export default ProjectTask;
