import BaseCollection from "common/collections/BaseCollection";
import OfficeModel from "common/models/OfficeModel";
import { OfficeJson, OfficeCollectionJson } from "common/types/Office";

class OfficeCollection extends BaseCollection<
  OfficeJson,
  OfficeModel,
  OfficeCollectionJson
> {
  itemsFromJson(jsonItems: OfficeJson[]): OfficeModel[] {
    return jsonItems.map((item) => new OfficeModel(item));
  }
}

export default OfficeCollection;
