import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import { useTranslation } from 'react-i18next';

const DmsBannerListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("dmsbanner:heading_list")}
      siteMenuOpenKey="delivery.portal"
      siteMenuSelectedKey="/dmsbanner"
    >
      <PageHeaderButton link="/dmsbanner/add" className="ml-2">
        {t("dmsbanner:add_button")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default DmsBannerListHeader;
