import { Form, Input, Modal, message } from "antd";

import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import AccountModel from "common/models/AccountModel";
import AccountRepository from "common/repositories/AccountRepository";
import { PasswordUpdateJson } from "common/types/Account";


type Props = {
  model: AccountModel;
  visible: boolean;
  setVisible: (b: boolean) => any;
};

const ProfilePasswordModal = ({
  model,
  visible,
  setVisible,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {};

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: PasswordUpdateJson = {
        id: model.id,
        oldpass: formData.oldpass,
        newpass: formData.newpass,
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = useCallback(
    async (formData: any) => {
      setErrors([]);
      message.loading({
        content: t("common:form.processing"),
        key: "key_avatar" + 1,
        duration: 0,
      });
      const data = doPrepareData(formData);

      let requestErrors = await new AccountRepository().updatePassword(data);
      // setLoading(false);

      if (requestErrors.length > 0) {
        setErrors(requestErrors);
        setIsSuccess(false);
        message.error({
          content: t(`user:form.error.${requestErrors[0]}`),
          className: "message_error",
          key: "key_avatar" + 1,
          duration: 3,
        });
      } else {
        setVisible(false);
        setIsSuccess(true);

        form.resetFields();
        message.success({
          content: t("user:form.success.change_password"),
          className: "message_success",
          key: "key_avatar" + 1,
          duration: 1,
        });
      }
    },
    [doPrepareData, setVisible]
  );

  return (
    <Modal
      title={t("user:profile.change_password_heading")}
      open={visible}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        setVisible(false);
        setIsSuccess(false);
      }}
      onOk={() => {
        form.submit();
      }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      destroyOnClose
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        // errors={errors}
        // isSuccess={isSuccess}
        // successTitle={t("user:form.success.change_password")}
        // error_translate_prefix="user:form.error"
        onFinish={onSubmit}
        // submitText={t("common:form.save")}
        // sidebarItems={[]}
        // hideSidebar
        // isModal={true}
      >
        <Form.Item
          label={t("user:profile.old_password")}
          name="oldpass"
          rules={[
            {
              required: true,
              message: t("user:form.error.error_oldpassword_required"),
            },
          ]}
        >
          <Input.Password autoComplete="new-password1" />
        </Form.Item>
        <Form.Item
          label={t("user:profile.new_password")}
          name="newpass"
          rules={[
            {
              required: true,
              message: t("user:form.error.error_password_required"),
            },
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProfilePasswordModal;
