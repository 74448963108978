import { Tag, Tooltip } from "antd";
import PurchaseOrderCollection from "common/collections/PurchaseOrderCollection";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import PurchaseOrderRepository from "common/repositories/PurchaseOrderRepository";
import { FilterPurchaseOrder } from "common/types/PurchaseOrder";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import TextSupplier from "components/TextSupplier";
import OrderCashflowReceiptItem from "features/order/list/OrderCashflowReceiptItem";
import OrderProductReceiptItem from "features/order/list/OrderProductReceiptItem";
import PurchaseOrderListHeader from "features/purchaseorder/list/PurchaseOrderListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PurchaseOrderItemEdit from "../item/PurchaseOrderItemEdit";
import PurchaseOrderListFilter from "./PurchaseOrderListFilter";

const PurchaseOrderList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterPurchaseOrder = useMemo(
    () => PurchaseOrderRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterPurchaseOrder>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<PurchaseOrderModel[]>([]);
  const fetchData = useCallback(async (): Promise<PurchaseOrderCollection> => {
    let collection = await new PurchaseOrderRepository().getItems({
      filters
    });

    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  //Table columns
  const columns: TableColumnsType<PurchaseOrderModel> = [
    {
      title: t("purchaseorder:id"),
      key: "id",
      sortby: "id",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <TableLinkText link={"/purchaseorder/edit/id/" + record.id}>
              {record.id}
            </TableLinkText>
          </>
        );
      }
    },
    {
      title: t("purchaseorder:supplier_id"),
      key: "supplier_id",
      width: 150,
      render: (supplier_id: number) => {
        if (supplier_id > 0) {
          return <TextSupplier id={supplier_id} />;
        } else {
          return "ID #" + supplier_id;
        }
      }
    },
    {
      title: t("purchaseorder:price_discount"),
      key: "price_discount",
      align: "right",
      toggletype: "extend",
      render: (price_discount: number, record) => {
        return (
          <>
            <TextMoney money={price_discount} />
          </>
        );
      }
    },
    {
      title: t("purchaseorder:price_final"),
      key: "price_final",
      align: "right",
      width: 120,
      render: (price_final: number) => {
        return (
          <span className="font-bold">
            <TextMoney money={price_final} />
          </span>
        );
      }
    },
    {
      title: (
        <>
          <Tooltip
            title={t("purchaseorder:product_receipt_column_tooltip")}
            mouseEnterDelay={0.4}>
            {t("order:product_receipt_column")}
          </Tooltip>
        </>
      ),
      togglelabel: t("purchaseorder:product_receipt_column_tooltip"),
      key: "product_receipt_list",
      align: "left",
      width: 130,
      render: (product_receipt_list: ProductReceiptModel[]) => {
        return (
          <>
            {product_receipt_list.map((item) => (
              <OrderProductReceiptItem key={item.id} data={item} />
            ))}
          </>
        );
      }
    },
    {
      title: (
        <>
          <Tooltip
            title={t("purchaseorder:cashflow_column_tooltip")}
            mouseEnterDelay={0.4}>
            {t("purchaseorder:cashflow_column")}
          </Tooltip>
        </>
      ),
      togglelabel: t("purchaseorder:cashflow_column_tooltip"),
      key: "cashflow_receipt_list",
      align: "left",
      width: 130,
      render: (cashflow_receipt_list: CashflowReceiptModel[]) => {
        return (
          <div className="leading-none">
            {cashflow_receipt_list.map((item) => (
              <OrderCashflowReceiptItem key={item.id} data={item} />
            ))}
          </div>
        );
      }
    },
    {
      title: t("common:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = PurchaseOrderModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 120
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 100,
      render: (ts: number) => <TextDateTime format="HH:mm, DD/MM" ts={ts} />
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 95,
      render: (_: any, record: PurchaseOrderModel) => (
        <>
          <PurchaseOrderItemEdit model={record} />
          <TableInfo record={record}></TableInfo>
        </>
      )
    }
  ];

  return (
    <>
      <PurchaseOrderListHeader />

      <PurchaseOrderListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<
        FilterPurchaseOrder,
        PurchaseOrderModel,
        PurchaseOrderCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "purchaseorder"
        }}></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default PurchaseOrderList;
