import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import dbm from "dbm";
import React, { useCallback } from "react";

import ReportChartDataTable from "../chart/ReportChartDataTable";

const ReportCustomerOrder = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const regionItemsWithLimit = dbm.getCollection("region")?.chain().data();
  const formatterRegionValue = useCallback(
    (value: number) => {
      return regionItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [regionItemsWithLimit]
  );
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Doanh thu theo khách hàng"}
              subtitle="Chỉ lấy đơn hàng có trạng thái hoàn thành"
              fetcherObject="customer"
              fetcherKeyMapping="customer"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Khách hàng",
                  name: "customer",
                  formatter: (value: number | string) => {
                    return <>{<span>{value.toString()}</span>}</>;
                  }
                },

                {
                  title: "Điện thoại đặt hàng",
                  name: "billingphone",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  }
                },
                {
                  title: "Tỉnh / Thành phố",
                  name: "shippingregion",
                  formatter: (value: number | string) => {
                    return <>{formatterRegionValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng giá trị đơn hàng",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return <>{+value}</>;
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Tổng số đơn hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{+value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), billingphone, shippingregion, customer, sum(pricefinal)"
              dataFilter="status = 19"
              dataGroupBy="customer"
              dataOrderBy="sum(pricefinal) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCustomerOrder;
