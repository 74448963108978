import { App, Col, Row } from "antd";
import DataGridTableModel from "common/models/DataGridTableModel";
import DataGridTableRepository from "common/repositories/DataGridTableRepository";
import eventEmitter from "common/utils/eventEmitter";
import FormEditFetcher from "components/form/FormEditFetcher";
import Error from "components/LayoutError";
import DataGridContainer from "features/datagrid/DataGridContainer";
import DataGridWorkspaceHome from "features/datagrid/DataGridWorkspaceHome";
import usePathParams from "hooks/usePathParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IconAlertTriangle } from "@tabler/icons-react";

import DataGridTableFormModal from "../features/datagrid/table/form/DataGridTableFormModal";

const DataGridPage = () => {
  const { t } = useTranslation();
  const { message, modal } = App.useApp();
  let navigate = useNavigate();
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<DataGridTableModel>(
    new DataGridTableModel(DataGridTableModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //////////////////////////////////////////
  // editing table
  const [editTableVisible, setEditTableVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);
  const [processing, setProcessing] = useState(false);
  const messageKeyDeletePrefix = "delete_table_";

  const onSaveTableSuccess = useCallback(
    (item: DataGridTableModel) => {
      setModel(item);
      eventEmitter.emit("CHANGED_DATAGRIDTABLE", {
        action: "edit",
        item: item.toJson()
      });
    },
    [setModel]
  );

  const workspaceId = useMemo(() => {
    return typeof pathParams.workspace === "string" ||
      typeof pathParams.workspace === "number"
      ? isNaN(parseInt(pathParams.workspace + ""))
        ? 0
        : parseInt(pathParams.workspace + "")
      : 0;
  }, [pathParams]);

  const onDeleteItem = useCallback(async () => {
    const { id, name } = model;

    setProcessing(true);
    message.loading({
      content: t("datagridtable:delete_on_progress", { id, name }),
      key: messageKeyDeletePrefix + id,
      duration: 0
    });

    const deletedErrors = await new DataGridTableRepository().deleteItem(id);
    setProcessing(false);
    if (deletedErrors.length === 0) {
      message.success({
        content: t("datagridtable:form.success.delete", { id, name }),
        className: "message_success",
        key: messageKeyDeletePrefix + id,
        duration: 1
      });

      //redirect to main workspace
      navigate("/etable/view/workspace/" + workspaceId);

      //trigger mainnavigation update table list
      eventEmitter.emit("CHANGED_DATAGRIDTABLE", {
        action: "delete",
        item: model.toJson()
      });
    } else {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy(messageKeyDeletePrefix + id);
            }}
            heading={t("common:error.table_delete_heading")}
            translate_prefix={"datagridtable:form.error"}
            items={deletedErrors}
          />
        ),
        className: "message_error",
        key: messageKeyDeletePrefix + id,
        duration: 10
      });
    }
  }, [model, t, navigate, workspaceId, message]);

  const onClickDeleteTable = useCallback(() => {
    //show confirm dialog
    modal.confirm({
      icon: null,
      title: t("datagridtable:delete_confirm_title", { name: model.name }),
      content: (
        <div className="mt-4">
          <Row>
            <Col span={4}>
              <IconAlertTriangle size={48} className="text-red-500" />
            </Col>
            <Col span={20}>
              {t("datagridtable:delete_confirm", { name: model.name })}
            </Col>
          </Row>
        </div>
      ),
      okButtonProps: { danger: true },
      cancelText: t("common:close"),
      onOk() {
        onDeleteItem();
      },
      onCancel() {}
    });
  }, [t, onDeleteItem, model.name, modal]);

  //clear edit model when change id
  useEffect(() => {
    if (model.id !== +pathParams.id && model.id > 0) {
      setModel(new DataGridTableModel(DataGridTableModel.getDefaultData()));
    }
    setFetchErrors([]);
  }, [pathParams.id, model.id]);

  return (
    <>
      {typeof pathParams.id !== "undefined" &&
      parseInt(pathParams.id.toString()) > 0 ? (
        model.id > 0 ? (
          <DataGridContainer
            isDeleting={processing}
            model={model}
            onClickEditTable={() => {
              setEditingId(model.id);
              setEditTableVisible(true);
            }}
            onClickDeleteTable={onClickDeleteTable}
            key={model.id}
          />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new DataGridTableRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<></>}
          />
        )
      ) : (
        <DataGridWorkspaceHome
          workspaceId={
            typeof pathParams.workspace === "string" ||
            typeof pathParams.workspace === "number"
              ? isNaN(parseInt(pathParams.workspace + ""))
                ? 0
                : parseInt(pathParams.workspace + "")
              : 0
          }
        />
      )}

      <DataGridTableFormModal
        workspaceId={workspaceId}
        id={editingId}
        open={editTableVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setEditTableVisible(isOpen);
        }}
        onSaveSuccess={onSaveTableSuccess}
      />
    </>
  );
};

export default DataGridPage;
