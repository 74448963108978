import { Col, Row, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  IconClockHour3,
  IconMap2,
  IconPackage,
  IconWeight,
} from "@tabler/icons-react";

const DeliveryDashboardSectionCenterRouteStatistic = ({
  countOrder,
  sumOrderWeight,
  sumDurationTitle,
  sumDuration,
  sumDistance,
}: {
  countOrder: string;
  sumOrderWeight: string;
  sumDurationTitle: string;
  sumDuration: string;
  sumDistance: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="p-2">
        <Col span={6} className="flex justify-center w-full">
          <Tooltip
            title={t("deliverydashboard:total_order")}
            mouseEnterDelay={0.4}
          >
            <div className="text-center">
              <IconPackage size={40} />
            </div>

            <div className="text-center text-2xl">{countOrder}</div>
          </Tooltip>
        </Col>
        <Col span={6} className="flex justify-center w-full">
          <Tooltip
            title={t("deliverydashboard:total_weight")}
            mouseEnterDelay={0.4}
          >
            <div className="text-center">
              <IconWeight size={40} />
            </div>

            <div className="text-center text-2xl">{sumOrderWeight}</div>
          </Tooltip>
        </Col>
        <Col span={6} className="flex justify-center w-full">
          <Tooltip title={sumDurationTitle} mouseEnterDelay={0.4}>
            <div className="text-center">
              <IconClockHour3 size={40} />
            </div>

            <div className="text-center text-2xl">{sumDuration}</div>
          </Tooltip>
        </Col>
        <Col span={6} className="flex justify-center w-full">
          <Tooltip
            title={t("deliverydashboard:distance_help")}
            mouseEnterDelay={0.4}
          >
            <div className="text-center">
              <IconMap2 size={40} />
            </div>

            <div className="text-center text-2xl">{sumDistance}</div>
          </Tooltip>
        </Col>
      </Row>
    </>
  );
};

export default DeliveryDashboardSectionCenterRouteStatistic;
