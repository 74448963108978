import { Popover, Tooltip } from "antd";
import { InventoryStock } from "common/types/InventoryStock";
import { SearchResultItem } from "common/types/Pos";
import { findIndex } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePosCartStore from "zustands/usePosCartStore";

import { IconAlertTriangle } from "@tabler/icons-react";

import PosCenterSearchInventoryPopover from "./PosCenterSearchInventoryPopover";

const PosSearchItemInventory = ({ data }: { data: SearchResultItem }) => {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState(false);
  const activeWarehouseId = usePosCartStore(
    (state) => state.getActiveCart()?.warehouse_id || 0
  );

  const [activeInventoryStock, setActiveInventoryStock] =
    useState<InventoryStock>();

  useEffect(() => {
    const foundIndex: number = findIndex(data.inventory_details, {
      warehouse_id: activeWarehouseId
    });

    if (foundIndex >= 0) {
      setActiveInventoryStock(data.inventory_details[foundIndex]);
    } else {
      setActiveInventoryStock(undefined);
    }
  }, [data, activeWarehouseId]);

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Popover
        overlayInnerStyle={{ width: 460 }}
        open={showPopover}
        onOpenChange={(open) => setShowPopover(open)}
        overlayClassName="pos-inventory-popover"
        className="pos-inventory-popover"
        content={
          <PosCenterSearchInventoryPopover
            items={data.inventory_details}
            activeItem={activeInventoryStock}
          />
        }
        title={
          <span>
            {data.product_name} / {data.sku}{" "}
            <span
              onClick={() => setShowPopover(false)}
              style={{ cursor: "pointer" }}
              className="text-muted">
              {t("pos:popover_close")}
            </span>
          </span>
        }
        trigger="click">
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {typeof activeInventoryStock !== "undefined" &&
          activeInventoryStock.warehouse_id >= 0 ? (
            <span
              className="text-blue-500 cursor-pointer"
              title={t("pos:warehouse.view_detail")}>
              {activeInventoryStock.available}
            </span>
          ) : (
            <span className="cursor-pointer">
              <Tooltip
                mouseEnterDelay={0.4}
                placement="right"
                title={t("pos:warehouse.no_transaction")}>
                <IconAlertTriangle
                  size={14}
                  className="mr-1 -mt-0.5 opacity-50"
                />
                0
              </Tooltip>
            </span>
          )}
        </span>
      </Popover>
    </span>
  );
};

export default PosSearchItemInventory;
