import { Button, InputNumber, Modal, Select } from 'antd';
import Promotion from 'common/constants/Promotion';
import { PosCartOrderDetail, PosCartProductPriceChange } from 'common/types/PosCart';
import TextMoney from 'components/TextMoney';
import { useEffect, useRef, useState } from 'react';

const PosCartModalProductDiscount = ({
  item,
  show,
  clearEditingIndex,
  onChange,
}: {
  item: PosCartOrderDetail;
  show: boolean;
  clearEditingIndex: Function;
  onChange: (v: PosCartProductPriceChange) => void;
}) => {
  const inputRef = useRef<any>(null);

  const handleClose = () => {
    clearEditingIndex();
  };

  const [manualDiscount, setManualDiscount] = useState(
    item.price_before_promotion - item.price
  );
  const [discountValue, setDiscountValue] = useState(
    item.manual_discount_value
  );
  const [discountType, setDiscountType] = useState(item.manual_discount_type);

  useEffect(() => {
    if (discountType === Promotion.VALUETYPE_PERCENT) {
      setManualDiscount((item.price_before_promotion * discountValue) / 100);
    } else if (discountType === Promotion.VALUETYPE_CURRENCY) {
      setManualDiscount(discountValue);
    } else if (discountType === Promotion.VALUETYPE_FIXPRICE) {
      setManualDiscount(item.price_before_promotion - discountValue);
    } else {
      setManualDiscount(0);
      setDiscountValue(0);
    }
  }, [discountType, item.price_before_promotion, discountValue]);

  const onApply = () => {
    onChange({
      price_discount: manualDiscount,
      discount_type: discountType,
      discount_value: discountValue,
    });
    clearEditingIndex();
  };

  const discountTypeOptions = [
    {
      value: 0,
      label: "Theo mặc định",
    },
    {
      value: Promotion.VALUETYPE_PERCENT,
      label: "Giảm theo % giá trị",
    },
    {
      value: Promotion.VALUETYPE_CURRENCY,
      label: "Giảm theo số tiền",
    },
    {
      value: Promotion.VALUETYPE_FIXPRICE,
      label: "Bán giá cố định",
    },
  ];

  return (
    <Modal
      width={400}
      open={show}
      onCancel={handleClose}
      title={
        item.item_name +
        " (" +
        item.item_color +
        " / " +
        item.item_size +
        " - " +
        item.sku +
        ")"
      }
      footer={[
        <div key="value" className="float-left">
          Giá trị giảm:{" "}
          <span style={{ color: "green" }}>
            <TextMoney money={manualDiscount} />
          </span>
        </div>,
        <Button key="primary" type="primary" onClick={onApply}>
          Áp dụng
        </Button>,
      ]}
    >
      <div className="w-100">
        <div
          className="row pd-10"
          style={{ borderBottom: "1px solid #eeeeee" }}
        >
          <div className="col">Giá bán hiện tại</div>
          <div className="col text-right">
            <TextMoney money={item.price_before_promotion} />
          </div>
        </div>
        <div
          className="row pd-10"
          style={{ borderBottom: "1px solid #eeeeee" }}
        >
          <div className="col">
            <Select
              options={discountTypeOptions}
              value={discountType}
              onChange={(value) => {
                setDiscountType(value);
                setDiscountValue(0);
                if (inputRef.current != null) {
                  inputRef.current.focus();
                }
              }}
            />
          </div>
          <div className="col text-right">
            {discountType > 0 ? (
              <span>
                <InputNumber
                  width="200"
                  ref={inputRef}
                  autoFocus={true}
                  value={discountValue}
                  min={0}
                  max={
                    discountType === Promotion.VALUETYPE_PERCENT
                      ? 100
                      : item.price_before_promotion
                  }
                  onChange={(value) => setDiscountValue(value ?? 0)}
                  precision={0}
                  decimalSeparator="."
                  step={discountType === Promotion.VALUETYPE_PERCENT ? 5 : 1000}
                />
                &nbsp;{" "}
                {discountType === Promotion.VALUETYPE_PERCENT ? "%" : "₫"}
              </span>
            ) : null}
          </div>
        </div>
        <div
          className="row pd-10"
          style={{ fontWeight: "bold", color: "#22baa0" }}
        >
          <div className="col">Giá bán mới</div>
          <div className="col text-right">
            <TextMoney money={item.price_before_promotion - manualDiscount} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PosCartModalProductDiscount;
