import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Upload } from "antd";
import { UploadProps } from "antd/lib/upload/interface";
import { RcFile } from "antd/es/upload";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// import * as fs from "fs";

type Props = {
  out_size?: number;
};
const GenerateCanvas = ({ out_size = 1080 }: Props) => {
  const { t } = useTranslation();
  const [frameImage, setFrameImage] = useState<string>("");
  const [sizeResult, setSizeResult] = useState<number>(out_size);

  //
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  //
  const backgroundImagesView = "/imgs/nteam/bg180.png";
  const backgroundImages = "/imgs/nteam/bg.png";

  const sizeView = 360;

  //
  function getCanvas(imgAvatar: string) {
    const bg = document.getElementById("bg") as HTMLCanvasElement;
    const frame = document.getElementById("frame") as HTMLCanvasElement;
    if (bg) {
      const ctxBg = bg.getContext("2d");
      if (ctxBg) {
        ctxBg.beginPath();
        let imageObj1 = new Image();
        imageObj1.src = backgroundImagesView;
        imageObj1.onload = () => {
          ctxBg?.drawImage(imageObj1, 0, 0, sizeView, sizeView);
        };
      }
    }
    if (frame && imgAvatar.length > 0) {
      const ctxFrame = frame.getContext("2d");
      if (ctxFrame) {
        ctxFrame.beginPath();
        // ctx2.fillStyle = "pink";
        // ctx2.arc(50, 50, 50, 0, Math.PI * 2, b);

        let imageObj2 = new Image();
        imageObj2.src = imgAvatar;
        imageObj2.onload = () => {
          // const aspectRatio = imageObj2.width / imageObj2.height;

          // // Calculate the dimensions to maintain aspect ratio and cover the canvas
          // let drawWidth, drawHeight;

          // if (bg.width / frame.height > aspectRatio) {
          //   drawHeight = frame.height;
          //   drawWidth = frame.height * aspectRatio;
          // } else {
          //   drawWidth = frame.width;
          //   drawHeight = frame.width / aspectRatio;
          // }

          // // Center the image on the canvas
          // const x = (frame.width - drawWidth) / 2;
          // const y = (frame.height - drawHeight) / 2;
          ctxFrame?.drawImage(imageObj2, 0, 0, sizeView, sizeView);
        };
      }
    } else {
      const ctxFrame = frame.getContext("2d");
      if (ctxFrame) {
        ctxFrame.clearRect(0, 0, frame.width, frame.height);
      }
    }
  }

  const download = async () => {
    ////////////////////////
    const result = document.createElement("canvas");
    const ctxResult = result.getContext("2d");
    if (result && ctxResult) {
      result.width = sizeResult;
      result.height = sizeResult;

      const bgimg = new Image();
      bgimg.src = backgroundImages;

      const promise1 = new Promise((resolve, reject) => {
        bgimg.onload = () => {
          ctxResult.drawImage(bgimg, 0, 0, sizeResult, sizeResult);
          resolve(1);
        };
      });

      const frameimg = new Image();
      frameimg.src = frameImage;
      let widthImage = frameimg.width;
      let heightImage = frameimg.height;

      if (frameimg.width < 500) {
        widthImage = 500;
      } else if (frameimg.width < 1080) {
        widthImage = frameimg.width;
      } else {
        widthImage = sizeResult;
      }

      if (frameimg.height < 500) {
        heightImage = 500;
      } else if (frameimg.height < 1080) {
        heightImage = frameimg.height;
      } else {
        heightImage = sizeResult;
      }
      //
      const imgAspect = frameimg.width / frameimg.height;
      const canvasAspect = result.width / result.height;

      let scaleX = 1;
      let scaleY = 1;

      if (imgAspect > canvasAspect) {
        scaleX = result.width / frameimg.width;
      } else {
        scaleY = result.height / frameimg.height;
      }

      const translateX = (result.width - frameimg.width) * 0.5;

      const translateY = (result.height - frameimg.height) * 0.5;

      const promise2 = new Promise((resolve, reject) => {
        frameimg.onload = () => {
          ctxResult.drawImage(frameimg, 0, 0, sizeResult, sizeResult);
          resolve(1);
        };
      });

      Promise.all([promise1, promise2]).then(() => {
        const dataURL = result.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = dataURL;
        downloadLink.download = "picture_frame.png";
        downloadLink.click();
      });
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleChangeBg: UploadProps["onChange"] = ({
    file,
    fileList: newFileList,
  }) => {
    if (file) {
      getBase64(file as RcFile)
        .then((res) => {
          setFrameImage(res);
          // createCanvasResult();
        })
        .catch((err) => {
          console.log("🚀 ~ file: MainTest.tsx:157 ~ getBase64 ~ err:", err);
        });
    }
  };

  useEffect(() => {
    getCanvas(frameImage);
  }, [frameImage]);

  useEffect(() => {
    setSizeResult(out_size);
  }, [out_size]);

  // useEffect(() => {
  //   const frame = document.getElementById("frame") as HTMLCanvasElement;
  //   const cropButton = document.getElementById("cropButton");
  //   const context = frame.getContext("2d");

  //   if (frame && frameImage.length > 0 && context) {
  //     const frameimage = new Image();
  //     frameimage.src = frameImage;
  //     let cropping = false;
  //     let startX: number, startY: number, width: number, height: number;

  //     frame.addEventListener("mousedown", (event) => {
  //       cropping = true;
  //       startX = event.clientX - frame.getBoundingClientRect().left;
  //       startY = event.clientY - frame.getBoundingClientRect().top;
  //     });

  //     frame.addEventListener("mousemove", (event) => {
  //       if (!cropping) return;
  //       width = event.clientX - frame.getBoundingClientRect().left - startX;
  //       height = event.clientY - frame.getBoundingClientRect().top - startY;
  //       drawCroppingRect();
  //     });

  //     frame.addEventListener("mouseup", () => {
  //       cropping = false;
  //     });
  //     const drawCroppingRect = () => {
  //       context.clearRect(0, 0, frame.width, frame.height);
  //       context.drawImage(frameimage, 0, 0, frame.width, frame.height);
  //       context.strokeStyle = "red";
  //       context.lineWidth = 2;
  //       context.strokeRect(startX, startY, width, height);
  //     };

  //     cropButton?.addEventListener("click", () => {
  //       const imgAspect = frameimage.width / frameimage.height;
  //       const canvasAspect = frame.width / frame.height;

  //       let scaleX = 1;
  //       let scaleY = 1;

  //       if (imgAspect > canvasAspect) {
  //         scaleX = frameimage.width / frame.width;
  //       } else {
  //         scaleY = frameimage.height / frame.height;
  //       }

  //       const croppedImageWidth = width * scaleX;
  //       const croppedImageHeight = height * scaleY;

  //       const croppedImageX = startX * scaleX;
  //       const croppedImageY = startY * scaleY;

  //       context.clearRect(0, 0, frame.width, frame.height);
  //       context.drawImage(
  //         frameimage,
  //         croppedImageX,
  //         croppedImageY,
  //         croppedImageWidth,
  //         croppedImageHeight,
  //         0,
  //         0,
  //         frame.width,
  //         frame.height
  //       );
  //     });
  //   }
  // }, []);

  return (
    <div id="result" style={{ width: "100%" }}>
      <Row>
        <Col span={24} md={16}>
          <div
            ref={resultRef}
            id="test"
            style={{
              // display: "inline-block",
              width: sizeView,
              height: sizeView,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <canvas
              width={sizeView}
              height={sizeView}
              id="frame"
              className="absolute"
            ></canvas>
            <canvas
              ref={canvasRef}
              width={sizeView}
              height={sizeView}
              id="bg"
              className="absolute"
            ></canvas>
            {/* <canvas width={180} height={180} id="result"></canvas> */}
          </div>
        </Col>
        <Col span={24} md={8}>
          <div style={{ width: sizeView, height: sizeView }}>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".jpg,.png,.jpeg"
              beforeUpload={() => false}
              onChange={handleChangeBg}
              onRemove={(file) => {
                setFrameImage("");
              }}
            >
              <Button
                disabled={frameImage.length > 0}
                icon={<UploadOutlined />}
                type="primary"
              >
                {t("tool:upload")}
              </Button>
            </Upload>
            {frameImage.length > 0 && (
              <Button
                style={{ marginTop: 8 }}
                icon={<DownloadOutlined />}
                onClick={download}
              >
                {t("tool:download")}
              </Button>
            )}
            {/* <Button id="cropButton">Crop</Button> */}
          </div>
        </Col>
        <Col span={12}></Col>
      </Row>
    </div>
  );
};

export default GenerateCanvas;
