import BaseApi from "./BaseApi";
import Helper from "common/utils/helper";
import AxiosClient from "./AxiosClient";

import {
	FilterCheckin,
	CheckinJsonAddEdit,
	FilterCheckingReview,
} from "common/types/Checkin";
import CheckinCollection from "common/collections/CheckinCollection";
import CheckinModel from "common/models/CheckinModel";
import { GetListProps } from "common/types/Filter";

const SERVICE_URL = "/workcheckings";
class CheckinApi extends BaseApi {
	//
	async getList(props: FilterCheckin): Promise<CheckinCollection> {
		let collection = new CheckinCollection();
		const convertProps = Helper.convertParams(props, true);
		const queryData = {
			params: {
				...convertProps,
			},
		};

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}

	async getMineList(props: FilterCheckin): Promise<CheckinCollection> {
		let collection = new CheckinCollection();
		const convertProps = Helper.convertParams(props, true);
		const queryData = {
			params: {
				...convertProps,
			},
		};

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/employee",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}
	//
	async getDetail(id: number): Promise<CheckinModel> {
		let item = new CheckinModel(CheckinModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + id);
			if (response.hasOwnProperty("data")) {
				item = new CheckinModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
	//
	async add(props: CheckinJsonAddEdit): Promise<CheckinModel> {
		let item = new CheckinModel(CheckinModel.getDefaultData());

		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new CheckinModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
	//
	async edit(props: CheckinJsonAddEdit): Promise<CheckinModel> {
		let item = new CheckinModel(CheckinModel.getDefaultData());
		// remove id key
		///////////////
		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);
		try {
			const postData = {
				...convertProps,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + props.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new CheckinModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
	//
	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().put(SERVICE_URL + "/delete/" + id);
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_200");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}
	//
	async getListToday(
		props: GetListProps<FilterCheckingReview>
	): Promise<CheckinCollection> {
		let collection = new CheckinCollection();
		const convertProps = Helper.convertParams(props.filters);
		const queryData = {
			params: {
				...convertProps,
			},
		};
		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/today",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}
}

export default CheckinApi;
