import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { EcomUrlJson } from 'common/types/EcomUrl';

import BaseModel from './BaseModel';

class EcomUrlModel extends BaseModel implements BaseModelJson<EcomUrlJson> {
  company_id: number;
  id: string;
  type: number;
  url: string;
  date_created: number;

  constructor(json: EcomUrlJson) {
    super();

    this.company_id = json.company_id || 0;
    this.id = json.id || "";
    this.type = json.type || 0;
    this.url = json.url || "";
    this.type = json.type || 0;
    this.date_created = json.date_created || 0;
  }

  toJson(): EcomUrlJson {
    return {
      company_id: this.company_id,
      id: this.id,
      type: this.type,
      url: this.url,
      date_created: this.date_created,
    };
  }

  static getDefaultData(): EcomUrlJson {
    return {
      company_id: 0,
      id: "",
      type: 0,
      url: "",
      date_created: 0,
    };
  }
}

export default EcomUrlModel;
