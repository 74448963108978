import { EcomOrderCheck } from "common/types/EcomSyncOrder";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import { OrderInitTaxInvoiceRequest } from "common/types/Order";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";

const SERVICE_URL = "/orderhelpers";

class OrderHelperApi extends BaseApi {
  async getEcomOrderCheck(
    channelId: number,
    orderIdList: string[]
  ): Promise<EcomOrderCheck[]> {
    let items: EcomOrderCheck[] = [];

    try {
      let queryData = {
        params: {
          ecom_platform_id: channelId,
          ecom_platform_order_id_list: orderIdList.join(","),
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/ecomplatformordercheck",
        queryData
      );
      if (
        response.hasOwnProperty("data") &&
        response.data.hasOwnProperty("items")
      ) {
        items = response.data.items;
      }
    } catch (error) {}

    return items;
  }

  async linkProductReceipt(
    order_id: number,
    product_receipt_id: string
  ): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(
        SERVICE_URL +
          "/linkproductreceipt/" +
          order_id +
          "/" +
          product_receipt_id
      );
      if (response.status !== 200) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async linkCashflowReceipt(
    order_id: number,
    cashflow_receipt_id: string
  ): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(
        SERVICE_URL +
          "/linkcashflowreceipt/" +
          order_id +
          "/" +
          cashflow_receipt_id
      );
      if (response.status !== 200) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async initTaxInvoice(
    data: OrderInitTaxInvoiceRequest
  ): Promise<TaxInvoiceModel> {
    let item = new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/inittaxinvoice/" + data.order_id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaxInvoiceModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default OrderHelperApi;
