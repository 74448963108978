import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

type UiSettingState = {
  enableSoundFx: boolean;
  setEnableSoundFx: (v: boolean) => void;
};

//init store data
let store: StateCreator<UiSettingState> = (set) => ({
  enableSoundFx: true,
  setEnableSoundFx: (enableSoundFx) =>
    set((state) => ({ ...state, enableSoundFx })),
});

//create store
const useUiSettingStore = create(persist(store, { name: "MAIN::UiSetting" }));

export default useUiSettingStore;
