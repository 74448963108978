import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import { useTranslation } from "react-i18next";

const ProductVariantSizeListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
    >
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("productvariantsize:filter_keyword_placeholder")}
        />
      </Form.Item>
    </PageDataFilterForm>
  );
};

export default ProductVariantSizeListFilter;
