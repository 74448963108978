import {
	CloseOutlined,
	DeleteOutlined,
	EditOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import { IconPhoto } from "@tabler/icons-react";
import { InputRef, message, Upload } from "antd";
import { Button, Collapse, FormInstance, Image, Space, Typography } from "antd";
import { Col, Form, Input, Row } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import FileApi from "common/api/FileApi";
import File from "common/constants/File";
import { ZoneDataBanner } from "common/types/Event";
import { FileJson } from "common/types/File";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FormSection from "components/form/FormSection";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";

const SectionBanner = ({
	data,
	form,
}: {
	data: ZoneDataBanner;
	form: FormInstance<any>;
}) => {
	const { t } = useTranslation();
	const [imgMobile, setImgMobile] = useState(data.image_mobile);
	const [imgDesktop, setImgDesktop] = useState(data.image_desktop);

	const [valueDesktop, setValueDesktop] = useState("");
	const [valueMobile, setValueMobile] = useState("");

	const desktopInputRef = useRef<InputRef | null>(null);

	const [edit, setEdit] = useState({
		desktop: false,
		mobile: false,
	});

	// const items: CollapseProps["items"] = [
	// 	{
	// 		key: "1",
	// 		label: "Ảnh Desktop",
	// 		children: <Typography.Text>Ảnh desktop</Typography.Text>,
	// 	},
	// 	{
	// 		key: "2",
	// 		label: "Ảnh Mobile",
	// 		children: <Typography.Text>Ảnh mobile</Typography.Text>,
	// 	},
	// ];

	const closeEdit = (type: "desktop" | "mobile") => {
		setEdit((prev) => {
			return {
				...prev,
				[`${type}`]: false,
			};
		});
	};
	useEffect(() => {
		// form.setFieldValue("banner_link", "222222");
	}, [imgMobile, imgDesktop]);

	return (
		<FormSection
			title={t("shopsetting:shopevent.banner_title")}
			subtitle={t("shopsetting:shopevent.banner_des")}
			leftColumnSize={6}
			divider>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item
						initialValue={data.url}
						label={"Đường dẫn banner"}
						name="banner_link"
						rules={[
							{
								required: true,
								message: t("template:form.error.error_fullname_required"),
							},
						]}>
						<Input autoFocus />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item
								name="banner_image_desktop"
								initialValue={data.image_desktop}
								className="min-h-[150px]  w-full"
								label="Ảnh banner desktop">
								<UploadEvent
									defaultUrl={imgDesktop}
									height={150}
									onSuccess={(url) => {
										message.success("Thay đổi thành công");
										form.setFieldValue("banner_image_desktop", url);
										setImgMobile(url);
									}}></UploadEvent>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="banner_image_mobile"
								initialValue={data.image_mobile}
								label="Ảnh banner mobile"
								className="min-h-[150px]">
								<UploadEvent
									defaultUrl={imgMobile}
									height={150}
									width={150}
									onSuccess={(url) => {
										message.success("Thay đổi thành công");
										form.setFieldValue("banner_image_mobile", url);
										setImgMobile(url);
									}}></UploadEvent>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>
		</FormSection>
	);
};

export default SectionBanner;

const BoxImage = ({
	file,
	actions,
}: {
	actions: {
		onDelete: () => void;
		onConfirm: () => void;
	};
	file: UploadFile<FileJson>;
}) => {
	return (
		<div className="border border-gray-400 mt-2 p-2 rounded-md gap-1 flex">
			<Image
				loading="eager"
				height={60}
				src={file?.response?.url || ""}></Image>
			<div className="flex flex-col gap-2 flex-1">
				<Typography.Text
					className="flex-1"
					ellipsis={{ tooltip: <p>{file.name}</p> }}>
					{file.name}
				</Typography.Text>
				<Row className="w-full" gutter={4}>
					<Col span={12}>
						{" "}
						<Button
							onClick={() => actions.onDelete()}
							size="small"
							className=" w-full flex justify-center min-w-[80px]">
							Xóa
						</Button>
					</Col>
					<Col span={12}>
						<Button
							type="primary"
							size="small"
							className="flex w-full justify-center min-w-[80px]"
							onClick={() => {
								actions.onConfirm();
							}}>
							xác nhận
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
};
