import {
  ProductCollectionDetailCollectionJson,
  ProductCollectionDetailJson,
} from "common/types/ProductCollection";
import BaseCollection from "./BaseCollection";
import ProductCollectionDetailModel from "common/models/ProductCollectionDetailModel";

class ProductCollectionDetailCollection extends BaseCollection<
  ProductCollectionDetailJson,
  ProductCollectionDetailModel,
  ProductCollectionDetailCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductCollectionDetailJson[]
  ): ProductCollectionDetailModel[] {
    return jsonItems.map((item) => new ProductCollectionDetailModel(item));
  }
}
export default ProductCollectionDetailCollection;
