import {
	Col,
	Divider,
	Form,
	Image,
	Input,
	List,
	message,
	Row,
	Typography,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { ZoneDataInfo } from "common/types/Event";
import { ZoneDataSubBanner } from "common/types/Event";
import FormSection from "components/form/FormSection";
import React from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";

export default function SectionInfor({
	data,
	form,
}: {
	data: ZoneDataInfo;
	form: FormInstance<any>;
}) {
	const { t } = useTranslation();
	return (
		<div>
			<FormSection
				title={t("Thông tin Tiêu đề chương trình & Brand")}
				subtitle={t("Cài đặt các tiêu đề & sologan & ảnh thương hiệu")}
				leftColumnSize={6}
				divider>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							rules={[
								{
									required: true,
									message: "Yêu cầu nhập",
								},
							]}
							initialValue={data.title}
							name="info_title"
							label={`Title`}>
							<Input defaultValue={data.title}></Input>
						</Form.Item>

						<Form.List name={`info_des`} initialValue={data.descriptions}>
							{(fileds, { add, remove }) => (
								<>
									{fileds.map((filed, index) => {
										return (
											<div>
												<Form.Item
													rules={[
														{
															required: true,
															message: "Yêu cầu nhập",
														},
													]}
													initialValue={filed}
													key={index}
													label={`Text ${index + 1}`}
													name={filed.name}>
													<Input></Input>
												</Form.Item>
											</div>
										);
									})}
								</>
							)}
						</Form.List>
					</Col>
					<Divider></Divider>

					<Col span={12}>
						<div className="max-h-[600px] overflow-y-scroll pr-1">
							<Form.List initialValue={data.grid} name="brand_grid">
								{(fileds, { add, remove }) => (
									<>
										{fileds.map((field, index) => {
											return (
												<div key={index} className="mb-4 ">
													<Form.Item
														label={
															<p className="text-sm font-bold m-0">
																Link chuyển hướng #{index + 1}
															</p>
														}
														className="mb-2"
														initialValue={data.grid[index].url}
														name={[field.name, "url"]}>
														<Input size="small"></Input>
													</Form.Item>
													<Form.Item
														label={
															<p className="text-sm font-bold m-0">
																Đường dẫn của hình ảnh
															</p>
														}
														className="mb-2"
														initialValue={data.grid[index].img}
														name={[field.name, "img"]}>
														<Input readOnly size="small"></Input>
													</Form.Item>
													<UploadEvent
														height={60}
														defaultUrl={data.grid[index].img}
														onSuccess={(url) => {
															const key = field.key;

															let data = form.getFieldValue("brand_grid");
															data[key] = { ...data[key], ["img"]: url };
															form.setFieldValue("brand_grid", data);
														}}></UploadEvent>
													<Divider></Divider>
												</div>
											);
										})}
									</>
								)}
							</Form.List>
						</div>
					</Col>
					<Col span={12}>
						<div className="max-h-[600px] overflow-y-scroll pr-1">
							<Form.List initialValue={data.slide} name="brand_slide">
								{(fileds, { add, remove }) => (
									<>
										{fileds.map((field, index) => {
											return (
												<div key={index} className="mb-4 ">
													<Form.Item
														label={
															<p className="text-sm font-bold m-0">
																Link chuyển hướng #{index + 1}
															</p>
														}
														className="mb-2"
														initialValue={data.slide[index].url}
														name={[field.name, "url"]}>
														<Input size="small"></Input>
													</Form.Item>
													<Form.Item
														label={
															<p className="text-sm font-bold m-0">
																Đường dẫn của hình ảnh ( chỉ đọc )
															</p>
														}
														className="mb-2"
														initialValue={data.slide[index].img}
														name={[field.name, "img"]}>
														<Input readOnly size="small"></Input>
													</Form.Item>
													<UploadEvent
														height={60}
														defaultUrl={data.slide[index].img}
														onSuccess={(url) => {
															const key = field.key;

															let data = form.getFieldValue("brand_slide");
															data[key] = { ...data[key], ["img"]: url };
															form.setFieldValue("brand_slide", data);
														}}></UploadEvent>
													<Divider></Divider>
												</div>
											);
										})}
									</>
								)}
							</Form.List>
						</div>
					</Col>
				</Row>
			</FormSection>
		</div>
	);
}
