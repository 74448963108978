import WorkTracking from "common/constants/WorkTracking";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

import type {
  WorkTrackingDetailViewHistoryJson,
  WorkTrackingJson,
  WorkTrackingDetailJson,
} from "common/types/WorkTracking";
class WorkTrackingModel
  extends BaseModel
  implements BaseModelJson<WorkTrackingJson>
{
  creator_id?: number;
  company_id?: number;
  employee_id: number;
  reviewer_id: number;
  worktracking_range_id: number;
  id: number;
  project_id: number;
  date: number;
  office_id: number;
  assigned_office_id: number;
  check_in_time: number;
  check_in_time_final: number;
  check_in_delta: number;
  check_out_time: number;
  check_out_time_final: number;
  check_out_delta: number;
  duration: number;
  ot_allow: number;
  allow_late: number;
  allow_early: number;
  late_in_rule: number;
  early_in_rule: number;
  ot_ratio: number;
  ot_duration: number;
  ratio_final: number;
  check_in_file_id_list: string;
  check_out_file_id_list: string;
  status: number;
  verify_status: number;
  date_created: number;
  date_modified: number;
  duration_format: string;
  duration_second_format: string;
  ot_duration_format: string;
  ot_duration_second_format: string;
  detail: WorkTrackingDetailJson;
  check_in_file_detail: FileModel;
  check_out_file_detail: FileModel;

  constructor(json: WorkTrackingJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.employee_id = json.employee_id || 0;
    this.reviewer_id = json.reviewer_id || 0;
    this.worktracking_range_id = json.worktracking_range_id || 0;
    this.id = json.id || 0;
    this.project_id = json.project_id || 0;
    this.date = json.date || 0;
    this.office_id = json.office_id || 0;
    this.assigned_office_id = json.assigned_office_id || 0;
    this.check_in_time = json.check_in_time || 0;
    this.check_in_time_final = json.check_in_time_final || 0;
    this.check_in_delta = json.check_in_delta || 0;
    this.check_out_time = json.check_out_time || 0;
    this.check_out_time_final = json.check_out_time_final || 0;
    this.check_out_delta = json.check_out_delta || 0;
    this.duration = json.duration || 0;
    this.ot_allow = json.ot_allow || 0;
    this.allow_late = json.allow_late || 0;
    this.allow_early = json.allow_early || 0;
    this.late_in_rule = json.late_in_rule || 0;
    this.early_in_rule = json.early_in_rule || 0;
    this.ot_ratio = json.ot_ratio || 0;
    this.ot_duration = json.ot_duration || 0;
    this.ratio_final = json.ratio_final || 0;
    this.check_in_file_id_list = json.check_in_file_id_list || "";
    this.check_out_file_id_list = json.check_out_file_id_list || "";
    this.status = json.status || 0;
    this.verify_status = json.verify_status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.duration_format = json.duration_format || "";
    this.duration_second_format = json.duration_second_format || "";
    this.ot_duration_format = json.ot_duration_format || "";
    this.ot_duration_second_format = json.ot_duration_second_format || "";
    this.detail =
      json.detail || WorkTrackingModel.getDefaultWorkTrackingDetailData();
    this.check_in_file_detail = new FileModel(
      json.check_in_file_detail || FileModel.getDefaultData()
    );
    this.check_out_file_detail = new FileModel(
      json.check_out_file_detail || FileModel.getDefaultData()
    );
  }

  static getDefaultWorkTrackingDetailViewHistoryData(): WorkTrackingDetailViewHistoryJson {
    return {
      action: "",
      allowearlyfrom: 0,
      allowearlyto: 0,
      allowlatefrom: 3,
      allowlateto: 0,
      allowotfrom: 0,
      allowotto: 0,
      checkinfrom: 0,
      checkinto: 0,
      checkoutfrom: 0,
      checkoutto: 0,
      datecreated: 0,
      editnote: "",
      editor: 0,
      ip: 0,
    };
  }

  static getDefaultWorkTrackingDetailData(): WorkTrackingDetailJson {
    return {
      id: 0,
      check_in_office: 0,
      check_in_source: 0,
      check_in_sourceid: "",
      check_in_trackingcode: "",
      check_in_ipaddress: "",
      check_in_lat: 0,
      check_in_long: 0,
      check_in_useragent: "",
      check_in_note: "",
      check_in_notemore: "",
      check_out_office: 0,
      check_out_source: 0,
      check_out_sourceid: "",
      check_out_trackingcode: "",
      check_out_ipaddress: "",
      check_out_lat: 0,
      check_out_long: 0,
      check_out_useragent: "",
      check_out_note: "",
      review_history: [],
    };
  }

  static getDefaultData(): WorkTrackingJson {
    return {
      creator_id: 0,
      company_id: 0,
      employee_id: 0,
      reviewer_id: 0,
      worktracking_range_id: 0,
      id: 0,
      project_id: 0,
      date: 0,
      office_id: 0,
      assigned_office_id: 0,
      check_in_time: 0,
      check_in_time_final: 0,
      check_in_delta: 0,
      check_out_time: 0,
      check_out_time_final: 0,
      check_out_delta: 0,
      duration: 0,
      ot_allow: 0,
      allow_late: 0,
      allow_early: 0,
      late_in_rule: 0,
      early_in_rule: 0,
      ot_ratio: 0,
      ot_duration: 0,
      ratio_final: 0,
      check_in_file_id_list: "",
      check_out_file_id_list: "",
      status: 0,
      verify_status: 0,
      date_created: 0,
      date_modified: 0,
      duration_format: "",
      duration_second_format: "",
      ot_duration_format: "",
      ot_duration_second_format: "",
      detail: WorkTrackingModel.getDefaultWorkTrackingDetailData(),
      check_in_file_detail: FileModel.getDefaultData(),
      check_out_file_detail: FileModel.getDefaultData(),
    };
  }

  toJson(): WorkTrackingJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      employee_id: this.employee_id,
      reviewer_id: this.reviewer_id,
      worktracking_range_id: this.worktracking_range_id,
      id: this.id,
      project_id: this.project_id,
      date: this.date,
      office_id: this.office_id,
      assigned_office_id: this.assigned_office_id,
      check_in_time: this.check_in_time,
      check_in_time_final: this.check_in_time_final,
      check_in_delta: this.check_in_delta,
      check_out_time: this.check_out_time,
      check_out_time_final: this.check_out_time_final,
      check_out_delta: this.check_out_delta,
      duration: this.duration,
      ot_allow: this.ot_allow,
      allow_late: this.allow_late,
      allow_early: this.allow_early,
      late_in_rule: this.late_in_rule,
      early_in_rule: this.early_in_rule,
      ot_ratio: this.ot_ratio,
      ot_duration: this.ot_duration,
      ratio_final: this.ratio_final,
      check_in_file_id_list: this.check_in_file_id_list,
      check_out_file_id_list: this.check_out_file_id_list,
      status: this.status,
      verify_status: this.verify_status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      duration_format: this.duration_format,
      duration_second_format: this.duration_second_format,
      ot_duration_format: this.ot_duration_format,
      ot_duration_second_format: this.ot_duration_second_format,
      detail: this.detail,
      check_in_file_detail: this.check_in_file_detail,
      check_out_file_detail: this.check_out_file_detail,
    };
  }

  static getLateInRuleList(): SelectOption[] {
    return [
      {
        value: WorkTracking.LATE_NOT,
        label: i18n.t("worktracking:LATE_NOT"),
      },
      {
        value: WorkTracking.LATE_IN_RULE,
        label: i18n.t("worktracking:LATE_IN_RULE"),
      },
      {
        value: WorkTracking.LATE_OUT_RULE,
        label: i18n.t("worktracking:LATE_OUT_RULE"),
      },
    ];
  }

  static getLateInRule(value: number): SelectOption | undefined {
    return this.getLateInRuleList().find((item) => item.value === value);
  }

  static getEarlyInRuleList(): SelectOption[] {
    return [
      {
        value: WorkTracking.EARLY_NOT,
        label: i18n.t("worktracking:EARLY_NOT"),
      },
      {
        value: WorkTracking.EARLY_IN_RULE,
        label: i18n.t("worktracking:EARLY_IN_RULE"),
      },
      {
        value: WorkTracking.EARLY_OUT_RULE,
        label: i18n.t("worktracking:EARLY_OUT_RULE"),
      },
    ];
  }

  static getEarlyInRule(value: number): SelectOption | undefined {
    return this.getEarlyInRuleList().find((item) => item.value === value);
  }

  static getEarlyAllowList(): SelectOption[] {
    return [
      {
        value: WorkTracking.ALLOW_EARLY_NOT_SET,
        label: i18n.t("worktracking:ALLOW_EARLY_NOT_SET"),
      },
      {
        value: WorkTracking.ALLOW_EARLY_ALLOW,
        label: i18n.t("worktracking:ALLOW_EARLY_ALLOW"),
      },
      {
        value: WorkTracking.ALLOW_EARLY_NOT_ALLOW,
        label: i18n.t("worktracking:ALLOW_EARLY_NOT_ALLOW"),
      },
    ];
  }

  static getEarlyAllow(value: number): SelectOption | undefined {
    return this.getEarlyAllowList().find((item) => item.value === value);
  }

  static getLateAllowList(): SelectOption[] {
    return [
      {
        value: WorkTracking.ALLOW_LATE_NOT_SET,
        label: i18n.t("worktracking:ALLOW_LATE_NOT_SET"),
      },
      {
        value: WorkTracking.ALLOW_LATE_ALLOW,
        label: i18n.t("worktracking:ALLOW_LATE_ALLOW"),
      },
      {
        value: WorkTracking.ALLOW_LATE_NOT_ALLOW,
        label: i18n.t("worktracking:ALLOW_LATE_NOT_ALLOW"),
      },
    ];
  }

  static getLateAllow(value: number): SelectOption | undefined {
    return this.getLateAllowList().find((item) => item.value === value);
  }

  static getSourceList(): SelectOption[] {
    return [
      {
        value: WorkTracking.SOURCE_WEB,
        label: i18n.t("worktracking:SOURCE_WEB"),
      },
      {
        value: WorkTracking.SOURCE_APP,
        label: i18n.t("worktracking:SOURCE_APP"),
      },
      {
        value: WorkTracking.SOURCE_API,
        label: i18n.t("worktracking:SOURCE_API"),
      },
      {
        value: WorkTracking.SOURCE_DEVICE,
        label: i18n.t("worktracking:SOURCE_DEVICE"),
      },
    ];
  }

  static getSource(value: number): SelectOption | undefined {
    return this.getSourceList().find((item) => item.value === value);
  }

  static getOTList(): SelectOption[] {
    return [
      {
        value: WorkTracking.ALLOW_OT_NOT,
        label: i18n.t("worktracking:ALLOW_OT_NOT"),
      },
      {
        value: WorkTracking.ALLOW_OT_FIRST,
        label: i18n.t("worktracking:ALLOW_OT_FIRST"),
      },
      {
        value: WorkTracking.ALLOW_OT_LAST,
        label: i18n.t("worktracking:ALLOW_OT_LAST"),
      },
      {
        value: WorkTracking.ALLOW_OT_ALL,
        label: i18n.t("worktracking:ALLOW_OT_ALL"),
      },
    ];
  }

  static getOT(value: number): SelectOption | undefined {
    return this.getOTList().find((item) => item.value === value);
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: WorkTracking.STATUS_PENDING,
        label: i18n.t("worktracking:STATUS_PENDING"),
        color: "gray",
      },
      {
        value: WorkTracking.STATUS_COMPLETED,
        label: i18n.t("worktracking:STATUS_COMPLETED"),
        color: "blue",
      },
      {
        value: WorkTracking.STATUS_VERIFIED,
        label: i18n.t("worktracking:STATUS_VERIFIED"),
        color: "green",
      },
      {
        value: WorkTracking.STATUS_ERROR,
        label: i18n.t("worktracking:STATUS_ERROR"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getVerifyList(): SelectOption[] {
    return [
      {
        value: WorkTracking.VERIFY_STATUS_UNVERIFIED,
        label: i18n.t("worktracking:VERIFY_STATUS_UNVERIFIED"),
        color: "gray",
      },
      {
        value: WorkTracking.VERIFY_STATUS_VERIFIED,
        label: i18n.t("worktracking:VERIFY_STATUS_VERIFIED"),
        color: "green",
      },
      {
        value: WorkTracking.VERIFY_STATUS_IGNORE,
        label: i18n.t("worktracking:VERIFY_STATUS_IGNORE"),
        color: "red",
      },
    ];
  }

  static getVerify(value: number): SelectOption | undefined {
    return this.getVerifyList().find((item) => item.value === value);
  }
}

export default WorkTrackingModel;
