import { Button, Spin, Tooltip } from "antd";
import PurchaseOrderDetailModel from "common/models/PurchaseOrderDetailModel";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import ErrorAlert from "components/ErrorAlert";
import MoneyInput from "components/MoneyInput";
import TextMoney from "components/TextMoney";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

import { IconRefresh } from "@tabler/icons-react";

const PurchaseOrderFormSectionDetailCostInput = ({
  record,
  allowEditInput,
  onChangeItem,
}: {
  record: PurchaseOrderDetailModel;
  allowEditInput: boolean;
  onChangeItem: (v: PurchaseOrderDetailModel) => void;
}) => {
  const { t } = useTranslation();

  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );
  const supplier = useEditingPurchaseOrderStore((state) => state.supplier);
  const [loading, setLoading] = useState(false);
  const [costFetched, setCostFetched] = useState(false);
  const [latestCost, setLatestCost] = useState(0);

  const [errors, setErrors] = useState<string[]>([]);

  const onChangeItemProxy = useCallback(
    (value: number) => {
      onChangeItem(
        new PurchaseOrderDetailModel(
          produce(record.toJson(), (draft) => ({
            ...draft,
            item_unit_price: value ?? 0,
          }))
        )
      );
    },
    [onChangeItem, record]
  );

  const fetchCost = useCallback(async () => {
    setLatestCost(0);
    setErrors([]);
    setLoading(true);

    const costSummary =
      await new PurchaseOrderHelperRepository().getCostSummary(
        record.product_variant_id,
        supplier.id
      );
    setLoading(false);
    if (costSummary.hasError()) {
      setErrors(costSummary.error.errors);
    } else {
      setCostFetched(true);
      //store latestcost for ref
      if (costSummary.latest_cost > 0) {
        setLatestCost(costSummary.latest_cost);
      }
      onChangeItemProxy(costSummary.original_cost);
    }
  }, [supplier.id, record.product_variant_id, onChangeItemProxy]);

  useEffect(() => {
    if (!costFetched && record.item_unit_price === 0 && !loading) {
      fetchCost();
    }
  });

  return (
    <>
      {loading ? (
        <span className="mt-3">
          <Spin size="small" />
        </span>
      ) : null}
      {latestCost > 0 ? (
        <span className="text-xs leading-7 mr-2 inline-block">
          {t("purchaseorder:detail.cost_latest")}{" "}
          <Tooltip title={t("purchaseorder:detail.cost_latest_use_tooltip")}>
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => onChangeItemProxy(latestCost)}
            >
              <TextMoney money={latestCost} />
            </span>
          </Tooltip>
        </span>
      ) : null}
      {errors.length > 0 ? (
        <ErrorAlert translate_prefix="" items={errors} type="warning" />
      ) : null}

      <span>
        {purchaseOrder.id === 0 && record.item_unit_price === 0 && (
          <Tooltip title={t("purchaseorder:detail.cost_refresh")}>
            <Button
              size="small"
              onClick={() => fetchCost()}
              type="link"
              icon={<IconRefresh size={18} />}
            />
          </Tooltip>
        )}
      </span>
      <MoneyInput
        style={{ width: 160 }}
        disabled={!allowEditInput}
        value={record.item_unit_price}
        onChange={(value) => onChangeItemProxy(value || 0)}
      />
    </>
  );
};

export default PurchaseOrderFormSectionDetailCostInput;
