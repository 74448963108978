import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkScheduleMineHeader = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("workschedule:heading_mine_list")}
      siteMenuSelectedKey="/checkin/add"
    >
      <PageHeaderButton link="/checkin/add">
        {t("worktracking:heading_add")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default WorkScheduleMineHeader;
