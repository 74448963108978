import BaseCollection from 'common/collections/BaseCollection';
import WorkTrackingModel from 'common/models/WorkTrackingModel';
import { WorkTrackingCollectionJson, WorkTrackingJson } from 'common/types/WorkTracking';

class WorkTrackingCollection extends BaseCollection<
  WorkTrackingJson,
  WorkTrackingModel,
  WorkTrackingCollectionJson
> {
  itemsFromJson(jsonItems: WorkTrackingJson[]): WorkTrackingModel[] {
    return jsonItems.map((item) => new WorkTrackingModel(item));
  }
}

export default WorkTrackingCollection;
