import Ecom from "common/constants/Ecom";
import cropany from "images/ecom/cropany.png";
import haravan from "images/ecom/haravan.png";
import kiotviet from "images/ecom/kiotviet.jpg";

import shopify from "images/ecom/shopify.png";
import woocommerce from "images/ecom/woocommerce.png";

const EcomItemTypeImage = ({
  type,
  alt,
  width,
  height,
  className,
  style,
}: {
  type: number;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const getEcomImage = (type: number): string => {
    let img = "";
    switch (type) {
      case -1:
        img = cropany;
        break;
      case Ecom.TYPE_HARAVAN:
        img = haravan;
        break;
      case Ecom.TYPE_KIOTVIET:
        img = kiotviet;
        break;
      case Ecom.TYPE_SHOPIFY:
        img = shopify;
        break;
      case Ecom.TYPE_WOO_COMMERCE:
        img = woocommerce;
        break;
    }

    return img;
  };

  return (
    <img
      src={getEcomImage(type)}
      alt={alt}
      width={width}
      height={height}
      className={className}
      style={style}
    />
  );
};

export default EcomItemTypeImage;
