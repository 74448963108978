import React, { useCallback, useEffect, useMemo, useState } from "react";
import AcademyCard from "../AcademyCard";
import { List } from "antd";
import AcademySkeleton from "../AcademySkeleton/AcademySkeleton";
import StoryRepository from "common/repositories/StoryRepository";
import { FilterStory } from "common/types/Story";
import StoryModel from "common/models/StoryModel";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { ListGridType } from "antd/lib/list";
import { SizeCard } from "../AcademyCard/types";

type Props = {
  idCategory: number;
  heightImage?: number;
  sizeCard?: SizeCard;
  prefixLink?: string;
  grid?: ListGridType | undefined;
  limit?: number;
};
const AcademyList = ({
  idCategory,
  heightImage = 300,
  sizeCard,
  prefixLink,
  limit,
  grid = {
    gutter: 16,
    xs: 2,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3,
  },
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<StoryModel[]>([]);
  const [total, setTotal] = useState<number>(0);

  const defaultFilters = useMemo(() => {
    return StoryRepository.getDefaultFilter({ limit: limit });
  }, [limit]);

  const [filters, setFilters] = useStateFilter<FilterStory>({
    ...defaultFilters,
    type: 2
  });
  useFilterLocation(defaultFilters, filters);

  ////////////////
  const dataLoading: StoryModel[] = Array.from(
    new Array(4).fill(new StoryModel(StoryModel.getDefaultData()))
  );

  const handleFetchData = useCallback(async () => {
    setLoading(true);
    if (idCategory !== undefined) {
      const response = await new StoryRepository().getItems({
        filters: {
          ...filters, collection_id: idCategory,
          type: 2
        },
      });
      if (!response.hasError()) {
        setData(response.items);
        setTotal(response.total);
      } else {
      }
    }
    setLoading(false);
  }, [filters, idCategory]);

  useEffect(() => {
    handleFetchData();
  }, [filters, idCategory]);


  return (
    <div className="w-full">
      <List
        itemLayout="vertical"
        pagination={{
          total: total,
          pageSize: +defaultFilters.limit,
          defaultPageSize: +filters.page,
          responsive: true,
          size: "small",
          showSizeChanger: false,
          style: { textAlign: "center", height: 35 },
          className: "academyList",
          hideOnSinglePage: true,
          current: +filters.page,
          onChange(page, pageSize) {
            setFilters({ ...filters, page: page });
          },

        }}
        grid={grid}
        dataSource={loading ? dataLoading : data}
        loading={{
          tip: <div>Đang tải</div>,
          spinning: loading,
        }}
        renderItem={(item: StoryModel, index) => (
          <List.Item key={index}>
            <>
              <AcademySkeleton
                loading={loading}
                skeletonProps={{
                  active: true,
                  paragraph: { rows: 4 },
                  title: true,
                }}
                heightImage={heightImage}
                children={
                  <AcademyCard
                    link={`/academy/${prefixLink
                        ? prefixLink + "/" + item.handle
                          : item.collections.length > 0
                          ? item.collections[0].handle + "/" + item.handle
                          :  "test/"  + item.handle
                      }`}
                    size={sizeCard}
                    item={item}
                    heightImage={heightImage}
                  ></AcademyCard>
                }
              />
            </>
          </List.Item>
        )}
      />
    </div>
  );
};

export default AcademyList;
