import { Form, Input } from "antd";
import Role from "common/constants/Role";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import { Filter } from "common/types/Filter";
import { StoreJson } from "common/types/Store";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import StoreFormSelect from "features/store/StoreFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { RangePickerProps } from "antd/es/date-picker";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const CashflowReceiptListFilter = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	total,
}: {
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
	total: number;
}) => {
	const { t } = useTranslation();

	const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
		"store",
		Role.CASHFLOW_VIEW
	);

	// FILTER: DATESTARTED AND DATEENDED
	const getDateCreatedFilter = useMemo(() => {
		let entries = Object.entries(filters)
			.filter(([key, val]) => key === "date_started" || key === "date_ended")
			.map(([key, val]) => {
				return val;
			}) as number[];
		return (
			+entries[0] > 0 && +entries[1] > 0
				? [
						dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
						dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
				  ] || null
				: [null, null] || null
		) as RangeValue;
	}, [filters]);
	const onChangeDateCreatedFilter: RangePickerProps["onChange"] = (
		value: RangePickerProps["value"],
		dateString: [string, string]
	) => {
		if (typeof value !== "undefined" && value !== null) {
			setFilters({
				...filters,
				date_started: +dayjs(
					"00:00, " + value[0]?.format("DD/MM").toString(),
					"HH:mm, DDMM"
				).unix(),
				date_ended: +dayjs(
					"23:59, " + value[1]?.format("DD/MM").toString(),
					"HH:mm, DDMM"
				).unix(),
			});
		} else {
			setFilters({
				...filters,
				date_started: -1,
				date_ended: -1,
			});
		}
	};

	return (
		<PageDataFilterForm
			filters={filters}
			setFilters={setFilters}
			total={total}
			defaultFilters={defaultFilters}
			convertValueByKeyFilter={[
				"direction",
				"store_id",
				"cashflow_group",
				"target",
				"method",
				"source",
				"status",
			]}>
			<Form.Item name="keyword">
				<Input
					allowClear
					placeholder={t("cashflowreceipt:filter_keyword_placeholder")}
				/>
			</Form.Item>
			<Form.Item name="id">
				<Input allowClear placeholder={t("cashflowreceipt:id")} />
			</Form.Item>
			<FormSelect
				name="direction"
				placeholder={t("cashflowreceipt:direction_sort")}
				options={CashflowReceiptModel.getDirectionList()}
				allowClear
				popupMatchSelectWidth={false}
			/>

			<StoreFormSelect
				placeholder={t("cashflowreceipt:filter_store_id")}
				name="store_id"
				allowClear
				defaultOptions={storeItemsWithLimit.map((i) => ({
					label: i.name,
					value: i.id,
				}))}
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="cashflow_group"
				placeholder={t("cashflowreceipt:filter_cashflow_group")}
				options={CashflowReceiptModel.getGroupList()}
				allowClear
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="target"
				placeholder={t("cashflowreceipt:filter_target")}
				options={CashflowReceiptModel.getTargetList()}
				allowClear
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="method"
				placeholder={t("cashflowreceipt:method_sort")}
				options={CashflowReceiptModel.getMethodList()}
				allowClear
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="source"
				placeholder={t("cashflowreceipt:source")}
				options={CashflowReceiptModel.getSourceList()}
				allowClear
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="is_complete"
				placeholder={t("Tình trạng")}
				options={[
					{
						label: "Chưa hoàn thành",
						value: 0,
					},
					{
						label: "Đã hoàn thành",
						value: 1,
					},
				]}
				allowClear
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				name="status"
				placeholder={t("common:status")}
				options={CashflowReceiptModel.getStatusList()}
				allowClear
				popupMatchSelectWidth={false}
			/>

			{/* FILTER: DATESTART AND DATEEND */}
			<Form.Item label={t("common:date_created")} labelCol={{ span: 6 }}>
				<DateFormFilter
					rangeValue={getDateCreatedFilter}
					allowClear={true}
					showNow={false}
					mode="range"
					format="DD/MM/YYYY"
					onRangeChange={onChangeDateCreatedFilter}
				/>
			</Form.Item>
		</PageDataFilterForm>
	);
};

export default CashflowReceiptListFilter;
