import ShippingRoute from 'common/constants/ShippingRoute';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type {
  StatsJson,
  ShippingRouteJson,
  ShippingRouteDistanceTwoPoint,
} from "common/types/ShippingRoute";

import type { GoongMapCoordinates } from "common/types/goongmap/GoongMapType";
class ShippingRouteModel
  extends BaseModel
  implements BaseModelJson<ShippingRouteJson>
{
  company_id?: number;
  creator_id?: number;
  id: number;
  code: string;
  note: string;
  shipper_id: number;
  from_warehouse_id: number;
  to_warehouse_id: number;
  distance_geotravel_ratio: number;
  count_total: number;
  count_done: number;
  count_success: number;
  count_fail: number;
  count_remain: number;
  cod_total: number;
  cod_success: number;
  cod_fail: number;
  cod_remain: number;
  ymd: number;
  status: number;
  date_created?: number;
  date_modified?: number;
  from_type: number;
  total_distance: number;
  stats: StatsJson;

  constructor(json: ShippingRouteJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.code = json.code || "";
    this.note = json.note || "";
    this.shipper_id = json.shipper_id || 0;
    this.from_warehouse_id = json.from_warehouse_id || 0;
    this.to_warehouse_id = json.to_warehouse_id || 0;
    this.distance_geotravel_ratio = json.distance_geotravel_ratio || 0;
    this.count_total = json.count_total || 0;
    this.count_done = json.count_done || 0;
    this.count_success = json.count_success || 0;
    this.count_fail = json.count_fail || 0;
    this.count_remain = json.count_remain || 0;
    this.cod_total = json.cod_total || 0;
    this.cod_success = json.cod_success || 0;
    this.cod_fail = json.cod_fail || 0;
    this.cod_remain = json.cod_remain || 0;
    this.ymd = json.ymd | 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.from_type = json.from_type || 0;
    this.total_distance = json.total_distance || 0;
    this.stats = json.stats || {
      count_order: 0,
      sum_order_weight: 0,
      sum_distance: 0,
      sum_duration: 0,
      speed_km_per_hour: 0,
      rest_minute_per_order: 0,
      vehicle_id: 0,
      distance_between_point: [],
    };
  }

  static getDefaultData(): ShippingRouteJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      code: "",
      note: "",
      shipper_id: 0,
      from_warehouse_id: 0,
      to_warehouse_id: 0,
      distance_geotravel_ratio: 0,
      count_total: 0,
      count_done: 0,
      count_success: 0,
      count_fail: 0,
      count_remain: 0,
      cod_total: 0,
      cod_success: 0,
      cod_fail: 0,
      cod_remain: 0,
      ymd: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
      from_type: 0,
      total_distance: 0,
      stats: {
        count_order: 0,
        sum_order_weight: 0,
        sum_distance: 0,
        sum_duration: 0,
        speed_km_per_hour: 0,
        rest_minute_per_order: 0,
        vehicle_id: 0,
        distance_between_point: [],
      },
    };
  }

  static getDefaultDataCoordinates(): ShippingRouteDistanceTwoPoint {
    return {
      total_distance: 0,
    };
  }

  static getDefaultGoongMapCoordinates(): GoongMapCoordinates {
    return {
      latitude: 0,
      longitude: 0,
    };
  }

  toJson(): ShippingRouteJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      code: this.code,
      note: this.note,
      shipper_id: this.shipper_id,
      from_warehouse_id: this.from_warehouse_id,
      to_warehouse_id: this.to_warehouse_id,
      distance_geotravel_ratio: this.distance_geotravel_ratio,
      count_total: this.count_total,
      count_done: this.count_done,
      count_success: this.count_success,
      count_fail: this.count_fail,
      count_remain: this.count_remain,
      cod_total: this.count_total,
      cod_success: this.cod_success,
      cod_fail: this.cod_fail,
      cod_remain: this.cod_remain,
      ymd: this.ymd,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      from_type: this.from_type,
      total_distance: this.total_distance,
      stats: this.stats,
    };
  }

  static getFromList(): SelectOption[] {
    return [
      {
        value: ShippingRoute.FROM_INTERNAL,
        label: i18n.t("shippingroute:FROM_INTERNAL"),
      },
      {
        value: ShippingRoute.FROM_MERCHANT,
        label: i18n.t("shippingroute:FROM_MERCHANT"),
      },
      {
        value: ShippingRoute.FROM_CUSTOMER,
        label: i18n.t("shippingroute:FROM_CUSTOMER"),
      },
      {
        value: ShippingRoute.FROM_OTHER,
        label: i18n.t("shippingroute:FROM_OTHER"),
      },
    ];
  }

  static getFrom(value: number): SelectOption | undefined {
    return this.getFromList().find((item) => item.value === value);
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ShippingRoute.STATUS_NEW,
        label: i18n.t("shippingroute:STATUS_NEW"),
        color: "gray",
      },
      {
        value: ShippingRoute.STATUS_PENDING,
        label: i18n.t("shippingroute:STATUS_PENDING"),
        color: "orange",
      },
      {
        value: ShippingRoute.STATUS_INPROGRESS,
        label: i18n.t("shippingroute:STATUS_INPROGRESS"),
        color: "blue",
      },
      {
        value: ShippingRoute.STATUS_COMPLETED,
        label: i18n.t("shippingroute:STATUS_COMPLETED"),
        color: "green",
      },
      {
        value: ShippingRoute.STATUS_CANCELLED,
        label: i18n.t("shippingroute:STATUS_CANCELLED"),
        color: "red",
      },
      {
        value: ShippingRoute.STATUS_NOT_SUBMITTED_COD,
        label: i18n.t("shippingroute:STATUS_NOT_SUBMITTED_COD"),
        color: "red",
      },
      {
        value: ShippingRoute.STATUS_SUBMITTED_COD,
        label: i18n.t("shippingroute:STATUS_SUBMITTED_COD"),
        color: "green",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default ShippingRouteModel;
