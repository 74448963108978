import { Empty, Modal, Table, TableColumnType } from "antd";
import CompanyResourceModel from "common/models/CompanyResourceModel";
import ErrorAlert from "components/ErrorAlert";
import db from "db";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CompanyInfoSystemCompanyResourceDataModal = ({
  companyResource,
  table,
  onClose,
}: {
  companyResource: CompanyResourceModel;
  table: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<any[] | undefined>();

  const fetchLocalData = useCallback(async () => {
    setOpen(true);
    setDataSource(undefined);

    if (db.hasOwnProperty(table) && db.table(table) !== null) {
      let items = await db.table(table).toArray();
      if (typeof items !== "undefined" && items.hasOwnProperty("length")) {
        setDataSource(items);
      } else {
        setErrors([
          t("company:info.system.companyresource_table_fetch_data_error", {
            table,
          }),
        ]);
      }
    } else {
      setErrors([
        t("company:info.system.companyresource_table_not_found", { table }),
      ]);
    }
  }, [table, t]);

  const getTableColumns = useCallback(() => {
    let columns: TableColumnType<any>[] = [];

    if (typeof dataSource !== "undefined" && dataSource.length > 0) {
      _.forEach(dataSource[0], (value, prop) => {
        columns.push({
          dataIndex: prop,
          title: prop,
          align: typeof value === "number" ? "center" : "left",
        });
      });

      console.log("columns", columns);
    }

    return columns;
  }, [dataSource]);

  useEffect(() => {
    if (table.length > 0 && errors.length === 0) {
      fetchLocalData();
    }
  }, [fetchLocalData, errors, table.length]);

  console.log(dataSource);
  return (
    <Modal
      title={
        <>
          {t("company:info.system.companyresource")} /{" "}
          {t("company:info.companyresource." + table)} (v.
          {companyResource.jsversion[table]})
        </>
      }
      width={1200}
      open={open}
      closable={true}
      maskClosable={true}
      onCancel={() => {
        setOpen(false);
        onClose();
      }}
      destroyOnClose
      footer={null}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
    >
      {errors.length > 0 ? (
        <div className="">
          <ErrorAlert items={errors} />
        </div>
      ) : (
        <></>
      )}
      {typeof dataSource !== "undefined" ? (
        <>
          {dataSource.length > 0 ? (
            <>
              <div className="text-xs mb-4 italic text-yellow-600">
                {t("company:info.system.companyresource_table_heading", {
                  total: dataSource.length,
                })}
              </div>
              <Table
                size="small"
                bordered
                scroll={{ x: 900 }}
                columns={getTableColumns()}
                dataSource={dataSource}
              />
            </>
          ) : (
            <>
              <Empty
                description={t(
                  "company:info.system.companyresource_table_nodata"
                )}
              ></Empty>
            </>
          )}
        </>
      ) : null}
    </Modal>
  );
};

export default CompanyInfoSystemCompanyResourceDataModal;
