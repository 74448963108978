import dayjs from "dayjs";
import { useMemo } from "react";
import { DatePickerProps, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import { Filter } from "common/types/Filter";
import CommentModel from "common/models/CommentModel";

import RateStarsSelect from "../components/RateStarsSelect";
import FormSelectNormal from "components/form/FormSelectNormal";
import DateFormFilter from "components/datetime/DateFormFilter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";

const CommentListFilter = <F extends Filter>({
	total,
	filters,
	setFilters,
	defaultFilters,
}: {
	total: number;
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
}) => {
	const { t } = useTranslation();

	const getValueFilter = useMemo(() => {
		const entries = Object.entries(filters).filter(
			([key, val]) => key === "date_created"
		);

		const [createdVal] = entries.map(([key, val]) => +val);

		if (createdVal > 0) {
			const createdDate = dayjs.unix(createdVal).format("DD/MM/YYYY");
			return dayjs(createdDate, "DD/MM/YYYY");
		}

		return null;
	}, [filters]);

	const onChange: DatePickerProps["onChange"] = (
		value: DatePickerProps["value"]
	) => {
		if (typeof value !== "undefined" && value !== null) {
			setFilters({
				...filters,
				date_created: value.unix(),
			});
		} else {
			setFilters({
				...filters,
				date_created: 0,
			});
		}
	};

	return (
		<>
			<PageDataFilterForm
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				convertValueByKeyFilter={[
					"date_to",
					"date_from",
					"item_type",
					"type",
					"status",
					"date_created",
				]}>
				<DatePickerRangeFilter
					filters={filters}
					setFilters={setFilters}
					keyEnd="date_to"
					keyStart="date_from"
				/>
				<FormSelectNormal
					name="item_type"
					placeholder={t("comment:filter.item_type")}
					options={CommentModel.getItemType()}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<FormSelectNormal
					name="is_user"
					placeholder={t("comment:filter.customer_comment")}
					options={CommentModel.getCustomerType()}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<FormSelectNormal
					name="type"
					placeholder={t("comment:filter.type")}
					options={CommentModel.getType()}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<Form.Item name="rate">
					<RateStarsSelect placeholder={t("comment:filter.rate")} />
				</Form.Item>
				<FormSelectNormal
					name="status"
					placeholder={t("comment:filter.status")}
					options={CommentModel.getStatus()}
					allowClear
					popupMatchSelectWidth={false}
				/>

				<Form.Item>
					<DateFormFilter
						value={getValueFilter}
						allowClear={true}
						onChange={onChange}
						mode="single"
						format="DD/MM/YYYY"
						className="w-full"
						placeholder={t("comment:filter.date_created")}
					/>
				</Form.Item>
				<Form.Item name="keyword">
					<Input allowClear placeholder={t("comment:filter.keyword")} />
				</Form.Item>
			</PageDataFilterForm>
		</>
	);
};

export default CommentListFilter;
