import BaseCollection from "common/collections/BaseCollection";
import SupplierModel from "common/models/SupplierModel";
import { SupplierJson, SupplierCollectionJson } from "common/types/Supplier";

class SupplierCollection extends BaseCollection<
  SupplierJson,
  SupplierModel,
  SupplierCollectionJson
> {
  itemsFromJson(jsonItems: SupplierJson[]): SupplierModel[] {
    return jsonItems.map((item) => new SupplierModel(item));
  }
}

export default SupplierCollection;


