import InventoryStockCollection from "common/collections/InventoryStockCollection";
import InventoryStockModel from "common/models/InventoryStockModel";
import InventoryStockStatsModel from "common/models/InventoryStockStatsModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/inventorystocks";

class InventoryStockApi extends BaseApi {
  async getListProductVariantQuantity(
    ids: string,
    from: string
  ): Promise<InventoryStockCollection> {
    let collection = new InventoryStockCollection();

    try {
      let queryData = {
        params: {
          from,
          ids,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/productvariant",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getOneProductVariantQuantity(
    product_variant_id: number
  ): Promise<InventoryStockModel> {
    let item = new InventoryStockModel(InventoryStockModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/productvariant/" + product_variant_id
      );
      if (response.hasOwnProperty("data")) {
        item = new InventoryStockModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async getOneProductQuantity(
    product_id: number
  ): Promise<InventoryStockModel> {
    let item = new InventoryStockModel(InventoryStockModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/product/" + product_id
      );
      if (response.hasOwnProperty("data")) {
        item = new InventoryStockModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async getSearchIndexStats(): Promise<InventoryStockStatsModel> {
    let item = new InventoryStockStatsModel(
      InventoryStockStatsModel.getDefaultData()
    );

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/stats");
      if (response.hasOwnProperty("data")) {
        item = new InventoryStockStatsModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async initSearchIndex(): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().post(SERVICE_URL + "/init");
      if (response.status !== 200) {
        resultErrors.push("error_invalid_status");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default InventoryStockApi;
