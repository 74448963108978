import create, { StateCreator } from "zustand";
import { persist } from "zustand/middleware";

type HiddenBackLink = string | (() => void);

type MenuStore = {
  showOnSmall: boolean;
  minimize: boolean;
  activeGroup: string;
  openKeys: string[];
  selectedKeys: string[];
  forceHidden: boolean;
  forceHiddenBackLink?: HiddenBackLink;
  setMinimize: (minimize: boolean) => void;
  setShowOnSmall: (showOnSmall: boolean) => void;
  setForceHidden: (
    forceHidden: boolean,
    forceHiddenBackLink?: HiddenBackLink
  ) => void;
  setActiveGroup: (activeGroup: string) => void;
  setOpenKeys: (openKeys: string[]) => void;
  setSelectedKeys: (selectedKeys: string[]) => void;
};

//init store data
let store: StateCreator<MenuStore> = (set, get) => ({
  showOnSmall: false,
  minimize: false,
  activeGroup: "sale",
  openKeys: [],
  selectedKeys: [],
  forceHidden: false,
  forceHiddenBackLink: undefined,
  setActiveGroup: (activeGroup) => set((state) => ({ ...state, activeGroup })),
  setMinimize: (minimize) =>
    set((state) => ({ ...state, minimize, openKeys: [], selectedKeys: [] })),
  setShowOnSmall: (showOnSmall) =>
    set((state) => ({ ...state, showOnSmall, openKeys: [], selectedKeys: [] })),
  setForceHidden: (forceHidden, forceHiddenBackLink) =>
    set((state) => ({ ...state, forceHidden, forceHiddenBackLink })),
  setOpenKeys: (openKeys) =>
    set((state) => ({
      ...state,
      openKeys:
        get().minimize && !state.showOnSmall ? state.openKeys : openKeys,
    })),
  setSelectedKeys: (selectedKeys) =>
    set((state) => ({ ...state, selectedKeys })),
});

//create store
const useMenuStore = create(
  persist(store, {
    name: "MAIN::Menu",
    partialize: (state) => ({
      minimize: state.minimize,
      activeGroup: state.activeGroup,
    }),
  })
);

export default useMenuStore;
