import DataGridTableField from "common/constants/DataGridTableField";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import {
  GridColumn,
  GridColumnIcon,
  SpriteMap,
} from "@glideapps/glide-data-grid";

import BaseModel from "./BaseModel";

import type { DataGridTableFieldJson } from "common/types/DataGridTableField";
class DataGridTableFieldModel
  extends BaseModel
  implements BaseModelJson<DataGridTableFieldJson>
{
  company_id: number;
  creator_id: number;
  datagrid_table_id: number;
  id: number;
  name: string;
  type: number;
  width: number;
  is_fixed: number;
  is_primary: number;
  is_readonly: number;
  value_options: string;
  display_order: number;
  date_created: number;
  date_modified: number;

  constructor(json: DataGridTableFieldJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.datagrid_table_id = json.datagrid_table_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.type = json.type || 0;
    this.width = json.width || 0;
    this.is_fixed = json.is_fixed || 0;
    this.is_primary = json.is_primary || 0;
    this.is_readonly = json.is_readonly || 0;
    this.value_options = json.value_options || "";
    this.display_order = json.display_order || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): DataGridTableFieldJson {
    return {
      company_id: 0,
      creator_id: 0,
      datagrid_table_id: 0,
      id: 0,
      name: "",
      type: 0,
      width: 0,
      is_fixed: 0,
      is_primary: 0,
      is_readonly: 0,
      value_options: "",
      display_order: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): DataGridTableFieldJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      datagrid_table_id: this.datagrid_table_id,
      id: this.id,
      name: this.name,
      type: this.type,
      width: this.width,
      is_fixed: this.is_fixed,
      is_primary: this.is_primary,
      is_readonly: this.is_readonly,
      value_options: this.value_options,
      display_order: this.display_order,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: DataGridTableField.TYPE_TEXT,
        label: i18n.t("datagridtablefield:TYPE_TEXT"),
      },
      {
        value: DataGridTableField.TYPE_NUMBER,
        label: i18n.t("datagridtablefield:TYPE_NUMBER"),
      },

      {
        value: DataGridTableField.TYPE_BOOLEAN,
        label: i18n.t("datagridtablefield:TYPE_BOOLEAN"),
      },

      {
        value: DataGridTableField.TYPE_URL,
        label: i18n.t("datagridtablefield:TYPE_URL"),
      },
      {
        value: DataGridTableField.TYPE_EMAIL,
        label: i18n.t("datagridtablefield:TYPE_EMAIL"),
      },
      {
        value: DataGridTableField.TYPE_PHONE_NUMBER,
        label: i18n.t("datagridtablefield:TYPE_PHONE_NUMBER"),
      },
      // {
      //   value: DataGridTableField.TYPE_FILE,
      //   label: i18n.t("datagridtablefield:TYPE_FILE"),
      // },
      {
        value: DataGridTableField.TYPE_SINGLE_SELECT,
        label: i18n.t("datagridtablefield:TYPE_SINGLE_SELECT"),
      },
      {
        value: DataGridTableField.TYPE_MULTI_SELECT,
        label: i18n.t("datagridtablefield:TYPE_MULTI_SELECT"),
      },
      {
        value: DataGridTableField.TYPE_DATE,
        label: i18n.t("datagridtablefield:TYPE_DATE"),
      },
      {
        value: DataGridTableField.TYPE_COLLABORATOR,
        label: i18n.t("datagridtablefield:TYPE_COLLABORATOR"),
      },
      {
        value: DataGridTableField.TYPE_RATING,
        label: i18n.t("datagridtablefield:TYPE_RATING"),
      },
    ];
  }

  static getGridColumnIcon(type: number): GridColumnIcon | string {
    //parse icon
    let icon: GridColumnIcon | string = GridColumnIcon.HeaderString;

    switch (type) {
      case DataGridTableField.TYPE_TEXT:
        icon = "text";
        break;
      case DataGridTableField.TYPE_NUMBER:
        icon = "number";
        break;
      case DataGridTableField.TYPE_RATING:
        icon = "star";
        break;
      case DataGridTableField.TYPE_BOOLEAN:
        icon = "toggle";
        break;
      case DataGridTableField.TYPE_DATE:
        icon = "date";
        break;
      case DataGridTableField.TYPE_URL:
        icon = "world";
        break;
      case DataGridTableField.TYPE_EMAIL:
        icon = "mail";
        break;
      case DataGridTableField.TYPE_FILE:
        icon = "file";
        break;
      case DataGridTableField.TYPE_SINGLE_SELECT:
        icon = "select";
        break;
      case DataGridTableField.TYPE_MULTI_SELECT:
        icon = "tag";
        break;
      case DataGridTableField.TYPE_PHONE_NUMBER:
        icon = "phone";
        break;
      case DataGridTableField.TYPE_COLLABORATOR:
        icon = "user";
        break;
    }

    return icon;
  }

  static getHeaderIcons() {
    const icons: SpriteMap = {};

    //define border for all icon
    const svgWrapper = (svg: string) =>
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="1" width="22" height="22" rx="2" fill="white" stroke="#999" stroke-width="1.5"/><g fill="none" stroke="#777" stroke-width="1.5" transform="translate(3 3) scale(0.75)">${svg}</g></svg>`;

    /////////
    //icon list
    icons.text = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M6 4l12 0"></path><path d="M12 4l0 16"></path>'
      );

    icons.star = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>'
      );

    icons.toggle = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M2 6m0 6a6 6 0 0 1 6 -6h8a6 6 0 0 1 6 6v0a6 6 0 0 1 -6 6h-8a6 6 0 0 1 -6 -6z"></path>'
      );

    icons.date = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z"></path><path d="M16 3v4"></path><path d="M8 3v4"></path><path d="M4 11h16"></path>'
      );

    icons.world = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path><path d="M3.6 9h16.8"></path><path d="M3.6 15h16.8"></path><path d="M11.5 3a17 17 0 0 0 0 18"></path><path d="M12.5 3a17 17 0 0 1 0 18"></path>'
      );

    icons.mail = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path><path d="M3 7l9 6l9 -6"></path>'
      );

    icons.select = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M8 9l4 -4l4 4"></path><path d="M16 15l-4 4l-4 -4"></path>'
      );

    icons.tag = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="8.5" cy="8.5" r="1" fill="currentColor"></circle><path d="M4 7v3.859c0 .537 .213 1.052 .593 1.432l8.116 8.116a2.025 2.025 0 0 0 2.864 0l4.834 -4.834a2.025 2.025 0 0 0 0 -2.864l-8.117 -8.116a2.025 2.025 0 0 0 -1.431 -.593h-3.859a3 3 0 0 0 -3 3z"></path>'
      );

    icons.phone = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>'
      );

    icons.user = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>'
      );

    icons.file = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5"></path>'
      );

    icons.number = () =>
      svgWrapper(
        '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M13 20v-16l-5 5"></path>'
      );

    return icons;
  }

  getEditorGridColumnDefine(): GridColumn {
    return {
      title: this.name,
      id: "f_" + this.id,
      hasMenu: true,
      icon: DataGridTableFieldModel.getGridColumnIcon(this.type),
      width: this.width >= 50 ? this.width : undefined,
    };
  }
}

export default DataGridTableFieldModel;
