const Leave = {
  STATUS_PENDING: 2,
  STATUS_APPROVED: 1,
  STATUS_DENED: 0,
  STATUS_APPROVED_1: 3,
  TYPE_1: 1,
  TYPE_2: 2,
  TYPE_3: 3,
  TYPE_4: 4,
  TYPE_5: 5,
  SALARY_1: 1,
  SALARY_2: 2,
  SALARY_3: 3,
  STEP_3: 3,
  STEP_0: 0,

  STEP_4: 4,
};

export default Leave;
