import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import PurchaseOrderRepository from "common/repositories/PurchaseOrderRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import PurchaseOrderFormHeader from "features/purchaseorder/form/PurchaseOrderFormHeader";
import PurchaseOrderFormWrapper from "features/purchaseorder/form/PurchaseOrderFormWrapper";
import PurchaseOrderList from "features/purchaseorder/list/PurchaseOrderList";
import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

const PurchaseOrderPage = () => {
  const [pathParams] = usePathParams();

  const [model, setModel] = useState<PurchaseOrderModel>(
    new PurchaseOrderModel(PurchaseOrderModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new PurchaseOrderModel(PurchaseOrderModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      if (pathParams.supplier_id !== undefined) {
        com = (
          <PurchaseOrderFormWrapper
            model={
              new PurchaseOrderModel({
                ...PurchaseOrderModel.getDefaultData(),
                supplier_id: +pathParams.supplier_id,
              })
            }
          />
        );
      }

      break;
    case "edit":
      com =
        model.id > 0 ? (
          <PurchaseOrderFormWrapper model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new PurchaseOrderRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<PurchaseOrderFormHeader />}
          />
        );

      break;
    default:
      com = <PurchaseOrderList />;
  }

  return com;
};

export default PurchaseOrderPage;
