import ShippingType from "common/constants/ShippingType";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";
import { v4 as uuidv4 } from "uuid";

import BaseModel from "./BaseModel";

import type {
  PriceSettingJson,
  ShippingTypeJson,
  SettingJson,
} from "common/types/ShippingType";
class ShippingTypeModel
  extends BaseModel
  implements BaseModelJson<ShippingTypeJson>
{
  id: number;
  creator_id: number;
  company_id: number;
  name: string;
  description: string;
  fromregionlist: number[];
  toregionlist: number[];
  price_setting: PriceSettingJson;
  display_order: number;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: ShippingTypeJson) {
    super();

    this.id = json.id || 0;
    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.name = json.name || "";
    this.fromregionlist = json.fromregionlist || [];
    this.toregionlist = json.toregionlist || [];
    this.price_setting = json.price_setting || {};
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.description = json.description || "";
    this.display_order = json.display_order || 0;
    this.status = json.status || 0;
  }

  static getDefaultPriceSettingData(): PriceSettingJson {
    return { type: 0, items: [] };
  }

  static getDefaultData(): ShippingTypeJson {
    return {
      id: 0,
      name: "",
      creator_id: 0,
      company_id: 0,
      fromregionlist: [],
      toregionlist: [],
      price_setting: ShippingTypeModel.getDefaultPriceSettingData(),
      date_created: 0,
      date_modified: 0,
      description: "",
      display_order: 0,
      status: 0,
    };
  }

  static getDefaultPriceSettingNewRow(): SettingJson {
    return {
      key: uuidv4(),
      from: 0,
      to: 0,
      fee: 0,
    };
  }

  toJson(): ShippingTypeJson {
    return {
      id: this.id,
      name: this.name,
      creator_id: this.creator_id,
      company_id: this.company_id,
      fromregionlist: this.fromregionlist,
      toregionlist: this.toregionlist,
      price_setting: this.price_setting,
      date_created: this.date_created,
      date_modified: this.date_modified,
      description: this.description,
      display_order: this.display_order,
      status: this.status,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ShippingType.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ShippingType.STATUS_DISABLE,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        label: i18n.t("shippingtype:TYPE_WEIGHT"),
        value: ShippingType.TYPE_WEIGHT,
        color: "",
      },
      {
        label: i18n.t("shippingtype:TYPE_KM"),
        value: ShippingType.TYPE_KM,
        color: "",
      },
    ];
  }
}

export default ShippingTypeModel;
