import TaxInvoiceDetailCollection from "common/collections/TaxInvoiceDetailCollection";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/taxinvoicedetails";

class TaxInvoiceDetailApi extends BaseApi {
  async getFullDetailOfInvoice(
    invoiceId: number
  ): Promise<TaxInvoiceDetailCollection> {
    let collection = new TaxInvoiceDetailCollection();

    try {
      let queryData = {
        params: {},
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/invoice/" + invoiceId,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default TaxInvoiceDetailApi;
