import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import Helper from "common/utils/helper";

//////////////////////////////////
//Phần thay thế
import {
  FilterLeaveAssign,
  LeaveAssignJsonAddEdit,
} from "common/types/LeaveAssign";

import { DataLeave } from "common/fake/data";
import { FilterHistoryLeave, FilterLeave, LeaveJsonEditStatus } from "common/types/Leave";

import LeaveAssignCollection from "common/collections/LeaveAssignCollection";
import LeaveAssignModel from "common/models/LeaveAssignModel";
import LeaveCollection from "common/collections/LeaveCollection";
import LeaveModel from "common/models/LeaveModel";
import LeaveNoteCollection from "common/collections/LeaveNoteCollection";
import LeaveStepCollection from "common/collections/LeaveStepCollection";
import LeaveHistoryCollection from "common/collections/LeaveHistoryCollection";

const SERVICE_URL = "/approvers";
const SERVICE_URL_LEAVE = "/leaves";
const SERVICE_URL_APPROVER_HISTORY = "/approverhistorys";

class LeaveApproveApi extends BaseApi {
  //
  async getList(props: FilterLeaveAssign): Promise<LeaveAssignCollection> {
    let collection = new LeaveAssignCollection();
    const convertProps = Helper.convertParams(props);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/getlistapprover",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getDetail(id: number): Promise<LeaveAssignModel> {
    let item = new LeaveAssignModel(LeaveAssignModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + id);
      if (response.hasOwnProperty("data")) {
        item = new LeaveAssignModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add(props: LeaveAssignJsonAddEdit): Promise<LeaveAssignModel> {
    let item = new LeaveAssignModel(LeaveAssignModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new LeaveAssignModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: LeaveAssignJsonAddEdit): Promise<LeaveAssignModel> {
    let item = new LeaveAssignModel(LeaveAssignModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.approver_id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new LeaveAssignModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(SERVICE_URL + "/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async getListApproval(props: FilterLeave) {
    let collection = new LeaveCollection();
    const convertProps = Helper.convertParams(props);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL_LEAVE + "/approver",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      // collection.fromJson(DataLeave);
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  //chưa dùng đến
  async getNoteApprove(props: any) {
    let collection = new LeaveNoteCollection();
    const convertProp = Helper.convertParams(props);
    try {
      let queryData = {
        params: {
          ...convertProp,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL_APPROVER_HISTORY + "/getnote",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  /////////////////////

  async editStatus(props: LeaveJsonEditStatus): Promise<LeaveModel> {
    let item = new LeaveModel(LeaveModel.getDefaultData());

    try {
      let postData = {
        ...props,
      };

      const response = await AxiosClient().put<any>(
        SERVICE_URL_LEAVE + "/status/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new LeaveModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }


  async getListApprovalHistory(props: FilterHistoryLeave): Promise<LeaveHistoryCollection> {
    let collection = new LeaveHistoryCollection();
    const convertProps = Helper.convertParams(props);
    const queryData = {
      params: {
        ...convertProps,
        leavetypes_id : props.id
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL_APPROVER_HISTORY ,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
}

export default LeaveApproveApi;
