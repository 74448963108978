import { Button, Col, DatePicker, Row, Select, Table } from "antd";
import Employee from "common/constants/Employee";
import WorkScheduleModel from "common/models/WorkScheduleModel";
import WorkScheduleRepository from "common/repositories/WorkScheduleRepository";
import WorkTrackingRangeRepository from "common/repositories/WorkTrackingRangeRepository";
import { EmployeeJson } from "common/types/Employee";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import TextUser from "components/TextUser";
import UserIdAvatar from "components/UserIdAvatar";
import dayjs, { Dayjs } from "dayjs";
import useDatabaseTable from "hooks/useDatabaseTable";
import useDbForSelect from "hooks/useDbForSelect";
import update from "immutability-helper";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import useWorkScheduleManageStore from "zustands/useWorkScheduleManage";

import { IconPlus } from "@tabler/icons-react";

import WorkScheduleManageFormModal from "./form/WorkScheduleManageFormModal";
import WorkScheduleManageHeader from "./WorkScheduleManageHeader";
import WorkScheduleManageItem from "./WorkScheduleManageItem";

const WorkScheduleManage = () => {
  const { t } = useTranslation();
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");
  const [officeItems] = useDbForSelect("companyoffice");
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf("week"));
  const [items, setItems] = useState<WorkScheduleModel[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [addVisible, setAddVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const [loadingRange, setLoadingRange] = useState(true);

  const [externalUserIdList, setExternalUserIdList] = useState<number[]>([]);
  const [office, setOffice, setRanges, setEmployee, setDateUnix] =
    useWorkScheduleManageStore((state) => [
      state.office,
      state.setOffice,
      state.setRanges,
      state.setEmployee,
      state.setDateUnix
    ]);

  const allExternalUserIdList = useMemo(() => {
    return employeeItems
      .filter((item) => item.office_id !== office.value)
      .map((item) => item.user_id);
  }, [employeeItems, office]);

  const fetchData = useCallback(async () => {
    if (loadingRef.current === false && office.value > 0) {
      setErrors([]);
      setLoading(true);

      const collection = await new WorkScheduleRepository().getItems({
        filters: {
          ...WorkScheduleRepository.getDefaultFilters(),
          office_id: office.value || -1,
          date_schedule_started: startDate.unix(),
          date_schedule_ended: dayjs(startDate).endOf("week").unix()
        }
      });
      setLoading(false);

      if (collection.hasError()) {
        setErrors(collection.error.errors);
      } else {
        setItems(collection.items);

        //tracking external id from schedules
        setExternalUserIdList(
          collection.items
            .filter((item) => allExternalUserIdList.includes(item.user_id))
            .map((item) => item.user_id)
        );
      }
    }
  }, [startDate, office, allExternalUserIdList]);

  const fetchRanges = useCallback(async () => {
    const collection = await new WorkTrackingRangeRepository().getItems({
      filters: {
        ...WorkTrackingRangeRepository.getDefaultFilters()
      }
    });
    setLoadingRange(false);

    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setRanges(collection.items.map((item) => item.toJson()));
    }
  }, [setRanges]);

  const onSaveSuccess = (newitems: WorkScheduleModel[]) => {
    //detech this is NEW or UPDATE
    let userIdList: number[] = newitems
      .filter((item) => allExternalUserIdList.includes(item.user_id))
      .map((item) => item.user_id);

    setExternalUserIdList(
      update(externalUserIdList, {
        $unshift: userIdList
      })
    );
    setItems(
      update(items, {
        $unshift: newitems
      })
    );
  };

  const onDeleteSuccess = useCallback(
    (deletedItem: WorkScheduleModel) => {
      setItems(items.filter((i) => i.id !== deletedItem.id));
    },
    [items]
  );

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  //when change date, or office, just mark this loading
  useEffect(() => {
    if (office.value > 0) {
      setLoading(true);
    }
  }, [startDate, office]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getPickerDisplayText = useMemo(() => {
    return t("workschedule:week_range", {
      start: startDate.format("DD/MM"),
      end: dayjs(startDate).endOf("week").format("DD/MM/YYYY")
    });
  }, [startDate, t]);

  const dataSource = useMemo(() => {
    return employeeItems
      .filter(
        (employee) =>
          (employee.office_id === office.value ||
            externalUserIdList.includes(employee.user_id)) &&
          employee.checkin_type === Employee.CHECKIN_TYPE_SCHEDULE
      )
      .map((employee) => {
        let obj: any = {
          key: employee.id,
          employee
        };

        Array(7)
          .fill(1)
          .forEach((i, index) => {
            const date = startDate.clone().add(index, "d");

            //find schedule on this DATE for this USER
            obj["events-of-" + date.format("D-M")] = items.filter(
              (item) =>
                item.user_id === employee.user_id &&
                dayjs.unix(item.date_scheduled).isSame(date, "day")
            );
          });

        return obj;
      });
  }, [startDate, employeeItems, office, items, externalUserIdList]);

  const getDateColumns = useMemo(() => {
    return Array(7)
      .fill(1)
      .map((i, index) => {
        const date = startDate.clone().add(index, "d");
        return {
          title: (
            <>
              <Row className="cursor-pointer hover:text-blue-500">
                <Col flex="auto" className="leading-6 capitalize">
                  {date.format("dddd, D/M")}
                </Col>
                <Col flex="26px" className="right-align">
                  <Button
                    size="small"
                    type="primary"
                    disabled={office.value === 0}
                    onClick={() => {
                      setAddVisible(true);
                      setDateUnix(date.unix());
                      setEmployee();
                    }}
                    icon={
                      <IconPlus className="-mt-0.5" strokeWidth={2} size={14} />
                    }
                  />
                </Col>
              </Row>
            </>
          ),
          dataIndex: "events-of-" + date.format("D-M"),
          render: (events: WorkScheduleModel[], record: any) => {
            return (
              <>
                <div className="text-left">
                  {events.map((event) => (
                    <WorkScheduleManageItem
                      model={event}
                      key={event.id}
                      onDeleteSuccess={onDeleteSuccess}
                    />
                  ))}
                  <Button
                    title={t("workschedule:add_label")}
                    block
                    size="small"
                    type="text"
                    className="opacity-20 hover:opacity-100"
                    onClick={() => {
                      setAddVisible(true);
                      setDateUnix(date.unix());
                      setEmployee(record.employee);
                    }}
                    icon={
                      <IconPlus
                        className="-mt-0.5 mr-2"
                        strokeWidth={2}
                        size={14}
                      />
                    }
                  />
                </div>
              </>
            );
          }
        };
      });
  }, [startDate, t, setEmployee, setDateUnix, office.value, onDeleteSuccess]);

  useEffect(() => {
    if (loadingRange) {
      fetchRanges();
    }
  }, [loadingRange, fetchRanges]);

  const columns: TableColumnsType<any> = [
    {
      title: " ",
      dataIndex: "employee",
      width: 50,
      render: (employee: EmployeeJson) => {
        return <UserIdAvatar id={employee.user_id} />;
      }
    },
    {
      title: t("workschedule:employee"),
      dataIndex: "employee",
      width: 220,
      render: (employee: EmployeeJson) => {
        return <TextUser id={employee.user_id} />;
      }
    },
    ...getDateColumns
  ];

  return (
    <>
      <WorkScheduleManageHeader></WorkScheduleManageHeader>

      <div className="p-4">
        {errors.length > 0 ? (
          <ErrorAlert items={errors} translate_prefix="" onRetry={fetchData} />
        ) : null}
        <Row gutter={16} className="mb-4">
          <Col>
            <Select
              status={office.value > 0 ? "" : "error"}
              options={officeItems}
              style={{ width: 240 }}
              placeholder={t("workschedule:office_placeholder")}
              value={office.value === 0 ? undefined : office.value}
              onChange={(value) => {
                setOffice(
                  officeItems.find((i) => i.value === value) ?? {
                    value: 0,
                    label: ""
                  }
                );
              }}
              size="large"
            />
          </Col>
          <Col>
            <DatePicker
              changeOnBlur
              size="large"
              allowClear={false}
              value={startDate}
              style={{ width: "100%" }}
              picker="week"
              format={getPickerDisplayText}
              onChange={(value) =>
                setStartDate(
                  value
                    ? (value.startOf("week") as Dayjs)
                    : (dayjs().startOf("week") as Dayjs)
                )
              }
            />
          </Col>
        </Row>

        <Table
          columns={columns}
          bordered
          size="small"
          dataSource={dataSource}
          loading={loading}
          pagination={{
            pageSize: 1000,
            hideOnSinglePage: true
          }}
        />
      </div>

      <WorkScheduleManageFormModal
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            //
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default WorkScheduleManage;
