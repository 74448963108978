import {
  Button,
  Col,
  Divider,
  Drawer,
  Image,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import ProjectTaskCommentCollection from "common/collections/ProjectTaskCommentCollection";
import FileModel from "common/models/FileModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskCommentModel from "common/models/ProjectTaskCommentModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectTaskCommentRepository from "common/repositories/ProjectTaskCommentRepository";
import { ProjectTaskPermissionJson } from "common/types/ProjectTask";
import TextDateTime from "components/TextDateTime";
import TextTagList from "components/TextTagList";
import UserIdAvatar from "components/UserIdAvatar";
import ProjectTaskItemComment from "features/projecttask/item/ProjectTaskItemComment";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconDownload, IconEye, IconPencil } from "@tabler/icons-react";

const ProjectTaskItemExpand = ({
  open,
  setOpen,
  onClose,
  record,
  projectModel,
  permissions,
  onEditProjectTaskForm,
  onViewProjectTaskFormUpdateStatus,
  updateCountComment,
}: {
  open: boolean;
  setOpen: (v: boolean) => void;
  onClose: () => void;
  record: ProjectTaskModel;
  projectModel: ProjectModel;
  permissions: ProjectTaskPermissionJson;
  onEditProjectTaskForm: (v: number) => any;
  onViewProjectTaskFormUpdateStatus: (v: number) => any;
  updateCountComment: (v: boolean) => void;
}) => {
  const { t } = useTranslation();
  const account = useLoginAccountStore((state) => state.account);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<ProjectTaskCommentModel[]>([]);

  const fetchTaskCommentData =
    useCallback(async (): Promise<ProjectTaskCommentCollection> => {
      let collection = await new ProjectTaskCommentRepository().getItems({
        filters: {
          ...ProjectTaskCommentRepository.getDefaultFilters(),
          project_task_id: record.id,
          comment_id_before: 0,
        },
      });
      setDataSource(collection.items);
      return collection;
    }, [record.id]);

  const onSaveCommentSuccess = (item: ProjectTaskCommentModel) => {
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //neu co, thi la update
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //them moi
      setDataSource(
        update(dataSource, {
          $push: [item],
        })
      );
    }

    // trigger update count comment
    updateCountComment(true);
  };

  const canEdit = useMemo(() => {
    return projectModel.scrum_master.includes(account.id);
  }, [projectModel, account]);

  const onDeleteCallback = (id: number) => {
    setDataSource(dataSource.filter((item) => item.id !== id));

    // trigger update count comment
    updateCountComment(true);
  };

  const previewButton = (myFile: FileModel) => {
    if (FileModel.getImageExtensions().includes(myFile.extension)) {
      return (
        <div>
          <Tooltip title={t("file:preview")} mouseEnterDelay={0.4}>
            <Button
              size="small"
              type="text"
              icon={<IconEye className="-mt-0.5" size="18" color="white" />}
            />
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  const downloadButton = (myFile: FileModel) => {
    if (FileModel.getAllSupportUploadExtensions().includes(myFile.extension)) {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <Tooltip title={t("file:download")} mouseEnterDelay={0.4}>
            <a
              href={myFile.url}
              download={myFile.title}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                size="small"
                type="text"
                icon={
                  <IconDownload className="-mt-0.5" size="18" color="white" />
                }
                style={{ marginLeft: 3 }}
              />
            </a>
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (record.id > 0) {
      fetchTaskCommentData();
    }
  }, [record.id, fetchTaskCommentData]);
  return (
    <>
      <Drawer
        title={
          <span className="font-normal">
            <span className="pr-3 mr-3 border-r">{record.code}</span>
            <span className="font-normal text-gray-400">
              {t("projecttask:heading_detail")}
            </span>
          </span>
        }
        width={1000}
        open={open}
        extra={[
          <Space key={"buttons"}>
            <Button
              type="primary"
              ghost
              disabled={
                record.can_update_status === 1 || canEdit ? false : true
              }
              onClick={() => {
                onViewProjectTaskFormUpdateStatus(record.id);
              }}
            >
              {t("projecttask:change_status")}
            </Button>

            <Button
              disabled={
                permissions.can_edit_story === 1 || canEdit ? false : true
              }
              onClick={() => {
                onEditProjectTaskForm(record.id);
              }}
              type="primary"
              icon={<IconPencil size={18} className="mr-2" />}
            >
              {t("projecttask:update")}
            </Button>
          </Space>,
        ]}
        afterOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div>
          <div className="px-4">
            <Row gutter={24}>
              <Col md={17} xs={24}>
                <Typography.Title level={2}>{record.name}</Typography.Title>

                <Row justify={"space-between"} className="text-gray-600">
                  <Col>
                    <UserIdAvatar
                      id={record.creator_id ? record.creator_id : 0}
                      showFullName
                      size={"small"}
                    />
                    &nbsp; - {t("projecttask:created")}:{" "}
                    <TextDateTime
                      ts={record.date_created ? record.date_created : 0}
                      format="HH:mm, DD/MM/YYYY"
                    />
                  </Col>
                  <Col className="text-right">
                    <div className="">
                      {t("projecttask:priority")}:
                      <Tag
                        className="ml-1"
                        color={
                          ProjectTaskModel.getPriority(record.priority)?.color
                        }
                      >
                        {ProjectTaskModel.getPriority(record.priority)?.label}
                      </Tag>
                    </div>
                  </Col>
                </Row>

                <div className="w-12 my-4 border-t-4 border-blue-200"></div>
                <div
                  className="text-base ProseMirror"
                  style={{ padding: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: record.description.replace(/\n/g, "<br />"),
                  }}
                ></div>

                {record.files.length > 0 ? (
                  <div className="p-4 mt-4 bg-gray-100 border-l-8 border-gray-400">
                    <div className="mb-4 text-xs font-bold text-gray-600 uppercase">
                      {t("projecttask:file_list")} ({record.files.length}):
                    </div>
                    <div className="relative flex items-center">
                      {record.files.map((i) => [
                        <Image
                          key={i.id}
                          className="mr-2"
                          width={100}
                          height={100}
                          src={i.getThumbnailSquare(100)}
                          preview={{
                            src: i.url,
                            mask: (
                              <>
                                {previewButton(i)}
                                {downloadButton(i)}
                              </>
                            ),
                          }}
                        />,
                        <div
                          className="flex justify-center absolute left-0 bottom-0 right-0 p-1.5 bg-gray-300 rounded-b-sm h-1/3"
                          style={{
                            width: "100px",
                            maxHeight: "50%",
                            maxWidth: "100px",
                          }}
                          key={"tooltip_" + i.id}
                        >
                          <Tooltip title={i.title} mouseEnterDelay={0.4}>
                            <a
                              href={i.url}
                              target="_blank"
                              rel="noreferrer"
                              className="block text-sm italic font-light tracking-tight text-white break-words truncate"
                            >
                              {i.title}
                            </a>
                          </Tooltip>
                        </div>,
                      ])}
                    </div>
                  </div>
                ) : null}

                <ProjectTaskItemComment
                  record={record}
                  projectModel={projectModel}
                  dataSource={dataSource}
                  onSaveCommentSuccess={onSaveCommentSuccess}
                  onDeleteCallback={onDeleteCallback}
                />
              </Col>

              <Col md={7} xs={24} className="">
                <div className="p-4 bg-gray-100 rounded-md">
                  <div className="mb-3 text-xs font-semibold uppercase">
                    {t("projecttask:who_working_on_this")} (
                    {record.assignees.split(",").length}):
                  </div>

                  <div>
                    {record.assignees !== "" ? (
                      <div className="leading-loose">
                        {record.assignees.split(",").map((i) => (
                          <div key={i} className="flex items-center mb-2">
                            <UserIdAvatar
                              id={+i}
                              className="mb-1"
                              showFullName
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-xs text-gray-500">
                        {t("projecttask:assignee_empty")}
                      </div>
                    )}
                  </div>

                  <Divider className="my-4" />

                  <dd className="text-sm text-gray-900">
                    <Typography.Text>
                      {t("projecttask:start_date")}:{" "}
                      {record.date_started ? (
                        <TextDateTime ts={record.date_started} />
                      ) : (
                        "---"
                      )}
                    </Typography.Text>
                  </dd>
                  <dd className="text-sm text-gray-900">
                    <Typography.Text>
                      {t("projecttask:last_modified")}:{" "}
                      <TextDateTime
                        ts={record.date_modified ? record.date_modified : 0}
                        format="HH:mm, DD/MM/YYYY"
                      />
                    </Typography.Text>
                  </dd>
                  <dd className="text-sm text-gray-900">
                    <Typography.Text>
                      {t("projecttask:finish_date")}:{" "}
                      {record.date_completed ? (
                        <TextDateTime ts={record.date_completed} />
                      ) : (
                        "---"
                      )}
                    </Typography.Text>
                  </dd>
                </div>

                {record.tags.length > 0 ? (
                  <div className="pt-4">
                    Tag: <TextTagList tag={record.tags} filterName={"tag"} />
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ProjectTaskItemExpand;
