import BaseCollection from "common/collections/BaseCollection";
import ProductModel from "common/models/ProductModel";
import { ProductCollectionJson2, ProductJson2 } from "common/types/Product";

class ProductCollection extends BaseCollection<
	ProductJson2,
	ProductModel,
	ProductCollectionJson2
> {
	itemsFromJson(jsonItems: ProductJson2[]): ProductModel[] {
		return jsonItems.map((item) => new ProductModel(item));
	}
}

export default ProductCollection;
