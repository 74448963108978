import OrderCollection from "common/collections/OrderCollection";
import OrderModel from "common/models/OrderModel";
import { PosOrder } from "common/types/PosOrder";
import produce from "immer";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type { FilterOrder, OrderJsonEdit, OrderSellerJsonEdit } from "common/types/Order";
import Helper from "common/utils/helper";

const SERVICE_URL = "orders";

class OrderSellerApi extends BaseApi {
	async getList(props: GetListProps<FilterOrder>): Promise<OrderCollection> {
		let collection = new OrderCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					status:
						typeof filters.status === "number" && filters.status > 0
							? filters.status
							: null,
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					id: filters.id > 0 ? filters.id : null,
					origin: filters.origin > 0 ? filters.origin : null,
					store_id: filters.store_id > 0 ? filters.store_id : null,
					tag: filters.tag ? filters.tag : null,
					sale_channel:
						filters.sale_channel > 0 ? filters.sale_channel : null,
					code: filters.code.length > 0 ? filters.code.trim() : null,
					from_date: filters.from_date > 0 ? filters.from_date : null,
					to_date: filters.to_date > 0 ? filters.to_date : null,
					type: filters.type >= 0 ? filters.type : null,
					delivery_status: filters.delivery_status || null,
					seller_id : filters.seller_id
				},
			};
			// ?type_sale=employee
			const response = await AxiosClient().get<any>(SERVICE_URL + "/cart/mycart?type_sale=employee", queryData);

			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: string): Promise<OrderModel> {
		let item = new OrderModel(OrderModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new OrderModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async delete(id: string): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 204) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async add(data: PosOrder): Promise<OrderModel> {
		let item = new OrderModel(OrderModel.getDefaultData());

		try {
			//remove some property before request to server
			let postData = produce<any>(data, (draft: any) => {
				delete draft["id"];
				delete draft["status"];
				delete draft["remote_sync_result"];
				delete draft["cash_customer_paid"];
				delete draft["cash_customer_return"];

				//remove unrequired in details
				draft["payments"] = data.payments.map((item) => {
					return produce<any>(item, (subdraft: any) => {
						delete subdraft["_id"];
					});
				});

				//remove unrequired in details
				draft["details"] = data.details.map((item) => {
					return produce<any>(item, (subdraft: any) => {
						delete subdraft["row_identifier"];
						delete subdraft["product_sell_on_zero"];
						delete subdraft["item_code"];
						delete subdraft["item_serial_number"];
						delete subdraft["promotion_options"];
						delete subdraft["promotions"];
						delete subdraft["manual_discount"];
						delete subdraft["manual_discount_type"];
						delete subdraft["manual_discount_value"];
					});
				});
			});

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new OrderModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(data: OrderSellerJsonEdit): Promise<OrderModel> {
		let item = new OrderModel(OrderModel.getDefaultData());
		const convertData = Helper.convertParams(data);
		try {
			let postData = {
				...convertData,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new OrderModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
}

export default OrderSellerApi;
