import TypeOfWorkApi from "common/api/TypeOfWorkApi";
import TypeOfWorkCollection from "common/collections/TypeOfWorkCollection";
import { FilterTypeWork } from "common/types/TypeOfWork";
import BaseRepository from "./BaseRepository";

class TypeOfWorkRepository extends BaseRepository<TypeOfWorkApi> {
  _api: TypeOfWorkApi | null;

  constructor() {
    super();
    this._api = new TypeOfWorkApi(true);
  }
  static getDefaultFilters(): FilterTypeWork {
    return {
      page: 1,
      limit: 50,
      sortby: "id",
      sorttype: "DESC",
      status: 0,
      keyword: "",
    };
  }
  async getItems() {
    return this._api ? await this._api.getList() : new TypeOfWorkCollection();
  }

  async getLabel() {
    const collection = this._api
      ? await this._api.getList()
      : new TypeOfWorkCollection();
    if (collection) {
      let newLabels: any[] = [];
      collection.items.forEach((item) => {
        newLabels.push({
          value: item.type_work_id,
          label: item.type_work_name,
        });
      });
      return newLabels;
    }
  }
}
export default TypeOfWorkRepository;
