import { IconAlarm } from "@tabler/icons-react";
import { Tag } from "antd";
import TaskModel from "common/models/TaskModel";
import React from "react";

const TaskStatus = ({
  status,
  has_icon = false,
}: {
  status: number;
  has_icon: boolean;
}) => {
  const color =
    status === 1
      ? "blue"
      : status === 2
      ? "magenta"
      : status === 3
      ? "green"
      : status === 4
      ? "volcano"
      : "cyan";
  return (
    <>
      <Tag color={color}>
        {has_icon && <IconAlarm size={16} />} {TaskModel.getStatus(status)}
      </Tag>
    </>
  );
};

export default TaskStatus;
