import ShippingBill from 'common/constants/ShippingBill';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { ShippingBillJson } from "common/types/ShippingBill";

class ShippingBillModel
  extends BaseModel
  implements BaseModelJson<ShippingBillJson>
{
  company_id: number;
  creator_id: number;
  id: number;
  shipping_carrier_id: number;
  sale_order_id: number;
  product_receipt_id: number;
  transaction_id: string;
  address: string;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
  contact_full_name: string;
  contact_phone: string;
  weight: number;
  cod: number;
  insurance_value: number;
  carrier_fee: number;
  tracking_code: string;
  time: number;
  note: string;
  gateway_identifier: string;
  gateway_status: number;
  gateway_log: any;
  status: number;
  date_created: number;
  date_modified: number;
  date_last_synced: number;

  constructor(json: ShippingBillJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.shipping_carrier_id = json.shipping_carrier_id || 0;
    this.sale_order_id = json.sale_order_id || 0;
    this.product_receipt_id = json.product_receipt_id || 0;
    this.transaction_id = json.transaction_id || "";
    this.address = json.address || "";
    this.region_id = json.region_id || 0;
    this.sub_region_id = json.sub_region_id || 0;
    this.sub_sub_region_id = json.sub_sub_region_id || 0;
    this.contact_full_name = json.contact_full_name || "";
    this.contact_phone = json.contact_phone || "";
    this.weight = json.weight || 0;
    this.cod = json.cod || 0;
    this.insurance_value = json.insurance_value || 0;
    this.carrier_fee = json.carrier_fee || 0;
    this.tracking_code = json.tracking_code || "";
    this.time = json.time || 0;
    this.note = json.note || "";
    this.gateway_identifier = json.gateway_identifier || "";
    this.gateway_status = json.gateway_status || 0;
    this.gateway_log = json.gateway_log || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_last_synced = json.date_last_synced || 0;
  }

  static getDefaultData(): ShippingBillJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      shipping_carrier_id: 0,
      sale_order_id: 0,
      product_receipt_id: 0,
      transaction_id: "",
      address: "",
      region_id: 0,
      sub_region_id: 0,
      sub_sub_region_id: 0,
      contact_full_name: "",
      contact_phone: "",
      weight: 0,
      cod: 0,
      insurance_value: 0,
      carrier_fee: 0,
      tracking_code: "",
      time: 0,
      note: "",
      gateway_identifier: "",
      gateway_status: 0,
      gateway_log: null,
      status: 0,
      date_created: 0,
      date_modified: 0,
      date_last_synced: 0,
    };
  }

  toJson(): ShippingBillJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      shipping_carrier_id: this.shipping_carrier_id,
      sale_order_id: this.sale_order_id,
      product_receipt_id: this.product_receipt_id,
      transaction_id: this.transaction_id,
      address: this.address,
      region_id: this.region_id,
      sub_region_id: this.sub_region_id,
      sub_sub_region_id: this.sub_sub_region_id,
      contact_full_name: this.contact_full_name,
      contact_phone: this.contact_phone,
      weight: this.weight,
      cod: this.cod,
      insurance_value: this.insurance_value,
      carrier_fee: this.carrier_fee,
      tracking_code: this.tracking_code,
      time: this.time,
      note: this.note,
      gateway_identifier: this.gateway_identifier,
      gateway_status: this.gateway_status,
      gateway_log: this.gateway_log,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_last_synced: this.date_last_synced,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ShippingBill.STATUS_UNKNOW,
        label: i18n.t("shippingbill:STATUS_UNKNOW"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_NEW,
        label: i18n.t("shippingbill:STATUS_NEW"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PENDING,
        label: i18n.t("shippingbill:STATUS_PENDING"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PACKAGING,
        label: i18n.t("shippingbill:STATUS_PACKAGING"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PACKED,
        label: i18n.t("shippingbill:STATUS_PACKED"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_WAITFORPICKING,
        label: i18n.t("shippingbill:STATUS_WAITFORPICKING"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PICKING,
        label: i18n.t("shippingbill:STATUS_PICKING"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PICKED,
        label: i18n.t("shippingbill:STATUS_PICKED"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PROCESSING,
        label: i18n.t("shippingbill:STATUS_PROCESSING"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_SHIPPING,
        label: i18n.t("shippingbill:STATUS_SHIPPING"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_SHIPPED,
        label: i18n.t("shippingbill:STATUS_SHIPPED"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PARTIALSHIPPED,
        label: i18n.t("shippingbill:STATUS_PARTIALSHIPPED"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_RETURNING,
        label: i18n.t("shippingbill:STATUS_RETURNING"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_PARTIALRETURNED,
        label: i18n.t("shippingbill:STATUS_PARTIALRETURNED"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_RETURNED,
        label: i18n.t("shippingbill:STATUS_RETURNED"),
        color: "gray",
      },
      {
        value: ShippingBill.STATUS_CANCEL,
        label: i18n.t("shippingbill:STATUS_CANCEL"),
        color: "gray",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getGatewayStatusList(): SelectOption[] {
    return [
      {
        value: ShippingBill.GATEWAY_STATUS_NOT_AVAILABLE,
        label: i18n.t("shippingbill:GATEWAY_STATUS_NOT_AVAILABLE"),
      },
      {
        value: ShippingBill.GATEWAY_STATUS_PENDING,
        label: i18n.t("shippingbill:GATEWAY_STATUS_PENDING"),
      },
      {
        value: ShippingBill.GATEWAY_STATUS_CREATED_SUCCESS,
        label: i18n.t("shippingbill:GATEWAY_STATUS_CREATED_SUCCESS"),
      },
      {
        value: ShippingBill.GATEWAY_STATUS_CREATED_FAIL,
        label: i18n.t("shippingbill:GATEWAY_STATUS_CREATED_FAIL"),
      },
      {
        value: ShippingBill.GATEWAY_STATUS_CANCELLED,
        label: i18n.t("shippingbill:GATEWAY_STATUS_CANCELLED"),
      },
    ];
  }

  static getGatewayStatus(value: number): SelectOption | undefined {
    return this.getGatewayStatusList().find((item) => item.value === value);
  }
}

export default ShippingBillModel;
