import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";

//////////////////////////////////
//Phần thay thế
import { FilterInventory, InventoryJsonAddEdit } from "common/types/Inventory";
import InventoryCollection from "common/collections/InventoryCollection";
import InventoryModel from "common/models/InventoryModel";

const SERVICE_URL = "/inventories";
class InventoryApi extends BaseApi {
	//
	async getList(props: FilterInventory): Promise<InventoryCollection> {
		let collection = new InventoryCollection();
		const convertProps = Helper.convertParams(props);
		const queryData = {
			params: {
				...convertProps,
			},
		};

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}
	//
	async getDetail(id: number): Promise<InventoryModel> {
		let item = new InventoryModel(InventoryModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + id);
			if (response.hasOwnProperty("data")) {
				item = new InventoryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
	//
	async add(props: InventoryJsonAddEdit): Promise<InventoryModel> {
		let item = new InventoryModel(InventoryModel.getDefaultData());

		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new InventoryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
	//
	async edit(props: InventoryJsonAddEdit): Promise<InventoryModel> {
		let item = new InventoryModel(InventoryModel.getDefaultData());
		// remove id key
		///////////////
		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);
		try {
			const postData = {
				...convertProps,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + props.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new InventoryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
	//
	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().put(SERVICE_URL + "/delete/" + id);
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_200");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}
}

export default InventoryApi;
