import { Button, Input, InputNumber, Tooltip } from "antd";
import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IconDimensions,
  IconLock,
  IconLockOff,
  IconTrash,
} from "@tabler/icons-react";

const HtmlEditorBubbleMenuImagePicker = ({
  editor,
}: {
  editor: HtmlEditor;
}) => {
  const { t } = useTranslation("htmleditor");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [alt, setAlt] = useState("");
  const [isRatioLocked, setIsRatioLocked] = useState(true);

  const onApply = useCallback(() => {
    if (editor) {
      const attributes = editor.getAttributes("imagepicker");

      //override width
      editor
        .chain()
        .focus()
        .setImageExtra({
          ...attributes,
          width: width,
          height: height,
          alt: alt,
        } as any)
        .run();
    }
  }, [editor, width, height, alt]);

  const onChangeWidth = useCallback(
    (newWidth: number) => {
      //we calculate the new Height because ratio is Lock
      if (isRatioLocked) {
        setHeight(Math.ceil((newWidth * height) / width));
      }

      setWidth(newWidth);
    },
    [isRatioLocked, width, height]
  );

  const onChangeHeight = useCallback(
    (newHeight: number) => {
      //we calculate the new Height because ratio is Lock
      if (isRatioLocked) {
        setWidth(Math.ceil((newHeight * width) / height));
      }

      setHeight(newHeight);
    },
    [isRatioLocked, width, height]
  );

  useEffect(() => {
    if (editor) {
      const attr = editor.getAttributes("imagepicker");
      setWidth(attr.width);
      setHeight(attr.height);
      setAlt(attr.alt);
    }
  }, [editor]);
  if (!editor) {
    return null;
  }

  return (
    <>
      {editor.isActive("imagepicker") ? (
        <div className="p-1">
          <div>
            <Input.TextArea
              placeholder={t("image_picker_modal.alt")}
              value={alt}
              onChange={(e) => setAlt(e.target.value)}
              rows={1}
              autoSize
            />
          </div>
          <div className="mt-1">
            <InputNumber
              min={1}
              title={t("image_picker_modal.width")}
              addonBefore={
                <IconDimensions size={18} className="text-gray-500" />
              }
              value={width}
              onChange={(v) => onChangeWidth(v || 50)}
              step={10}
              style={{ width: 120 }}
            />
            <Tooltip
              mouseEnterDelay={0.4}
              title={t("image_picker_modal.toggle_ratio_lock")}
            >
              <Button
                type="dashed"
                size="small"
                onClick={() => setIsRatioLocked(!isRatioLocked)}
                style={{ width: 44, color: "#999" }}
              >
                -
                {isRatioLocked ? (
                  <IconLock size={14} />
                ) : (
                  <IconLockOff size={14} />
                )}
                -
              </Button>
            </Tooltip>
            <InputNumber
              min={1}
              title={t("image_picker_modal.height")}
              value={height}
              onChange={(v) => onChangeHeight(v || 50)}
              step={10}
              style={{ width: 80 }}
            />
            <span className="inline-block ml-1">
              <Button
                onClick={() => onApply()}
                type="primary"
                size="small"
                style={{ width: 60 }}
                className="mr-1 inline-block bg-gray-500"
              >
                {t("common:form.save_short")}
              </Button>
              <Tooltip title={t("image_remove")} mouseEnterDelay={0.4}>
                <Button
                  size="small"
                  type="link"
                  onClick={(e) => {
                    //remove image node
                    editor
                      .chain()
                      .focus()
                      .command(({ tr }) => {
                        // manipulate the transaction
                        tr.deleteSelection();
                        return true;
                      })
                      .run();
                  }}
                  icon={<IconTrash size="14" color="grey" />}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HtmlEditorBubbleMenuImagePicker;
