import ShippingCarrier from "common/constants/ShippingCarrier";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type {
  ShippingCarrierJson,
  ApiSettingGhnJson,
  ApiSettingGhtkJson,
} from "common/types/ShippingCarrier";

class ShippingCarrierModel
  extends BaseModel
  implements BaseModelJson<ShippingCarrierJson>
{
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  description: string;
  status: number;
  display_order: number;
  api_type: number;
  api_setting: string[];
  detail: string;
  date_created: number;
  date_modified: number;

  constructor(json: ShippingCarrierJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.description = json.description || "";
    this.status = json.status || 0;
    this.display_order = json.display_order || 0;
    this.api_type = json.api_type || 0;
    this.api_setting = json.api_setting || [];
    this.detail = json.detail || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ShippingCarrierJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      description: "",
      status: 0,
      display_order: 0,
      api_type: 0,
      api_setting: [],
      detail: "",
      date_created: 0,
      date_modified: 0,
    };
  }

  static getDefaultSettingGhn(): ApiSettingGhnJson {
    return {
      issandbox: 1,
      shop_id: "",
      token: "",
      return_phone: "",
      return_address: "",
      return_province_id: 0,
      return_district_id: 0,
      return_ward_id: 0,
      service_type_id: 0,
    };
  }

  static getDefaultSettingGhtk(): ApiSettingGhtkJson {
    return {
      issandbox: 0,
      pickaddress: "",
      pickdistrict: 0,
      pickward: 0,
      pickemail: "",
      pickname: "",
      pickprovince: 0,
      picktel: "",
      token: "",
    };
  }

  toJson(): ShippingCarrierJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      display_order: this.display_order,
      api_type: this.api_type,
      api_setting: this.api_setting,
      detail: this.detail,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ShippingCarrier.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ShippingCarrier.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getApiTypeList(): SelectOption[] {
    return [
      {
        value: ShippingCarrier.APITYPE_EMPTY,
        label: i18n.t("shippingcarrier:APITYPE_EMPTY"),
      },
      {
        value: ShippingCarrier.APITYPE_INHOUSE,
        label: i18n.t("shippingcarrier:APITYPE_INHOUSE"),
      },
      {
        value: ShippingCarrier.APITYPE_GHN,
        label: i18n.t("shippingcarrier:APITYPE_GHN"),
      },
      {
        value: ShippingCarrier.APITYPE_GHTK,
        label: i18n.t("shippingcarrier:APITYPE_GHTK"),
      },
      {
        value: ShippingCarrier.APITYPE_JTEXPRESS,
        label: i18n.t("shippingcarrier:APITYPE_JTEXPRESS"),
      },
    ];
  }

  static getApiType(value: number): SelectOption | undefined {
    return this.getApiTypeList().find((item) => item.value === value);
  }

  static getGhnServiceTypeList(): SelectOption[] {
    return [
      {
        value: ShippingCarrier.APISETTING_GHN_SERVICE_TYPE_EXPRESS,
        label: i18n.t("shippingcarrier:APISETTING_GHN_SERVICE_TYPE_EXPRESS"),
      },
      {
        value: ShippingCarrier.APISETTING_GHN_SERVICE_TYPE_STANDARD,
        label: i18n.t("shippingcarrier:APISETTING_GHN_SERVICE_TYPE_STANDARD"),
      },
      {
        value: ShippingCarrier.APISETTING_GHN_SERVICE_TYPE_SAVE,
        label: i18n.t("shippingcarrier:APISETTING_GHN_SERVICE_TYPE_SAVE"),
      },
    ];
  }

  static getJTExpressPayTypeList(): SelectOption[] {
    return [
      {
        value: ShippingCarrier.JTEXPRESS_PAYTYPE_PP_PM,
        label: i18n.t("shippingcarrier:form.PP_PM"),
      },
      {
        value: ShippingCarrier.JTEXPRESS_PAYTYPE_PP_CASH,
        label: i18n.t("shippingcarrier:form.PP_CASH"),
      },
      {
        value: ShippingCarrier.JTEXPRESS_PAYTYPE_CC_CASH,
        label: i18n.t("shippingcarrier:form.CC_CASH"),
      },
    ];
  }

  static getGhnServiceType(value: number): SelectOption | undefined {
    return this.getGhnServiceTypeList().find((item) => item.value === value);
  }
}

export default ShippingCarrierModel;
