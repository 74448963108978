import React from "react";
import { StarFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type Props = {
  stars: number;
  size?: number;
  style?: React.CSSProperties;
};

const RateStars = ({ stars, size = 14, style = { color: "#d72229", fontSize: size } }: Props) => {
  const { t } = useTranslation();

  const styles = {
    starInactive: {
      color: "#dadce0",
    },
  };
  switch (stars) {
    case 0:
      return <>{t("comment:model.all")}</>;
    case 1:
      return (
        <>
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
        </>
      );

    case 2:
      return (
        <>
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
        </>
      );

    case 3:
      return (
        <>
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
        </>
      );

    case 4:
      return (
        <>
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style, ...styles.starInactive }} />
        </>
      );

    case 5:
      return (
        <>
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
          <StarFilled style={{ ...style }} />
        </>
      );
    default:
      return <></>;
  }
};

export default RateStars;
