import React, { useCallback, useEffect, useMemo, useState } from "react";
import { List, Skeleton, Tag } from "antd";
import Item from "antd/es/list/Item";
import { useTranslation } from "react-i18next";
import TextDateTime from "components/TextDateTime";
import useStateFilter from "hooks/useStateFilter";
import usePostActionStore from "zustands/usePostActionStore";

import DeliveryHistoryModal from "./DeliveryHistoryModal";
import { DeliveryHistoryJson } from "common/types/Delivery";
import DeliveryHistoryModel from "common/models/DeliveryHistoryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import ErrorAlert from "components/ErrorAlert";
import DeliveryModel from "common/models/DeliveryModel";

type Data = DeliveryHistoryJson & {
	loading?: boolean;
};
type Props = {
	id: number | string;
	model: DeliveryModel;
};
const DeliveryHistoryList = ({ id, model }: Props) => {
	const { t } = useTranslation();
	const { reload } = usePostActionStore();

	//filter default
	const defaultFilter = useMemo(() => {
		return DeliveryRepository.getDefaultFilters();
	}, []);
	const [filters, setFilters] = useStateFilter(defaultFilter);

	//state
	const [dataSource, setDateSource] = useState<Data[]>(
		Array.from({ length: 4 }).map(() => ({
			...DeliveryHistoryModel.getDefaultData(),
			loading: true,
		}))
	);

	const [errors, setErrors] = useState<string[]>([]);
	const [modal, setModal] = useState<Data>({
		...DeliveryHistoryModel.getDefaultData(),
		loading: false,
	});
	const [open, setOpen] = useState<boolean>(false);
	const [employeesD, setEmployeesD] = useState<any[]>([]);

	//functions
	const getEmployeeInfo = (creator_id: number) => {
		const findEmp = employeesD.find((item) => item.value === creator_id);
		return findEmp ? findEmp : {};
	};

	//fetch employee
	const fetchingEmployeeD = useCallback(async (id_list: number[]) => {
		const collection = await new IdFetcherRepository().doFetching({
			object_type: "employee",
			id_list,
		});
		setEmployeesD(
			collection.items.find((i) => i.object_type === "employee")?.result || []
		);
	}, []);

	// fetch data
	const fetchData = useCallback(async () => {
		const response = await new DeliveryRepository().getItemsDeliveryHistory(
			{ filters: filters },
			id
		);

		if (response.hasError()) {
			setErrors(response.error.errors);
		}

		setDateSource(response.items.map((i) => i.toJson()));
		if (response?.items.length > 0) {
			fetchingEmployeeD(response.items.map((item) => item.creator));
		}
	}, [id]);

	//
	useEffect(() => {
		fetchData();
	}, [fetchData, reload]);

	return (
		<>
			<div className="max-h-[300px] overflow-y-auto smooth_scrollbar px-2">
				{errors.length > 0 ? (
					<ErrorAlert
						items={errors}
						translate_prefix="delivery:detail.errors"
					/>
				) : (
					<List
						itemLayout="vertical"
						size="default"
						className="relative"
						pagination={{
							size: "small",
							hideOnSinglePage: true,
							style: { margin: 0, position: "sticky", bottom: 0, right: 0 },
							onChange: (page) => {
								setFilters({ ...filters, page: page });
							},
							pageSize: 10,
						}}
						dataSource={dataSource}
						renderItem={(item) => {
							return (
								<List.Item
									onClick={() => {
										setModal(item);
										setOpen(true);
									}}
									className="cursor-pointer">
									<Skeleton active loading={item.loading}>
										<div className="flex justify-between items-center">
											<Tag color="blue">
												{t(`delivery:action_code.${item.action_code}`)}:
											</Tag>
											<Tag color="blue-inverse">
												<TextDateTime
													format="HH:mm -- DD/MM/YYYY"
													ts={item.date_created}
												/>
											</Tag>
										</div>
									</Skeleton>
								</List.Item>
							);
						}}
					/>
				)}
			</div>
			<DeliveryHistoryModal
				deliveryModel={model}
				model={modal}
				open={open}
				setOpen={setOpen}
				employee={getEmployeeInfo(modal.creator)}
			/>
		</>
	);
};

export default DeliveryHistoryList;
