import { useTranslation } from "react-i18next";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";

const DeliveryStatisticalListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("delivery:statistical.heading_statistical")}
      siteMenuSelectedKey="/delivery/statistical"
    ></PageHeader>
  );
};

export default DeliveryStatisticalListHeader;
