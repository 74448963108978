import { App, Button, Col, Form, Input, Row, Select, Tag, Tooltip } from "antd";
import ProductTransmit from "common/constants/ProductTransmit";
import Role from "common/constants/Role";
import ProductTransmitDetailModel from "common/models/ProductTransmitDetailModel";
import ProductTransmitModel from "common/models/ProductTransmitModel";
import ProductTransmitRepository from "common/repositories/ProductTransmitRepository";
import LayoutForm from "components/form/LayoutForm";
import Error from "components/LayoutError";
import RoleCheck from "components/RoleCheck";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import ProductTransmitFormHeader from "features/producttransmit/form/ProductTransmitFormHeader";
import ProductTransmitDetailList from "features/producttransmitdetail/list/ProductTransmitDetailList";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconClock, IconUser } from "@tabler/icons-react";

import type { ProductTransmitJsonAddEdit } from "common/types/ProductTransmit";
const ProductTransmitForm = ({ myObj }: { myObj: ProductTransmitModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [model, setModel] = useState<ProductTransmitModel>(myObj);
  const isEditing = model.id > 0;

  const [detailItems, setDetailItems] = useState<ProductTransmitDetailModel[]>(
    []
  );

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const [rejectProcessing, setRejectProcessing] = useState(false);
  const [approveProcessing, setApproveProcessing] = useState(false);

  const initialValues = {
    warehouse_id_from:
      model.warehouse_id_from > 0 ? model.warehouse_id_from : null,
    warehouse_id_to: model.warehouse_id_to > 0 ? model.warehouse_id_to : null,
    tags: model.tags.length > 1 ? model.tags.split(",") : [],
    note: model.note,
    status: model.status > 0 ? model.status : ProductTransmit.STATUS_PENDING
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProductTransmitJsonAddEdit = {
        ...ProductTransmitModel.getDefaultData(),
        id: model.id,
        warehouse_id_from: formData.warehouse_id_from,
        warehouse_id_to: formData.warehouse_id_to,
        tags: formData.tags,
        notify_user_id_list: model.notify_user_id_list,
        note: formData.note,
        status: model.status
      };

      if (model.id === 0) {
        submitData.products = detailItems.map((item) => item.toJson());
      } else {
        submitData.change_details = detailItems.map((item) => item.toJson());
      }

      return submitData;
    },
    [model.id, model.status, model.notify_user_id_list, detailItems]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setIsSuccess(false);
    doPrepareData(formData);
    let myObj: ProductTransmitModel =
      await new ProductTransmitRepository().saveRemote(doPrepareData(formData));
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  const onApprove = async () => {
    setApproveProcessing(true);

    const item = await new ProductTransmitRepository().approve(model.id);

    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="producttransmit:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      setModel(item);
      message.success({
        content: t("producttransmit:form.success.approve"),
        className: "message_success",
        key: "message",
        duration: 2
      });
    }

    setApproveProcessing(false);
  };

  const onReject = async () => {
    setRejectProcessing(true);

    const item = await new ProductTransmitRepository().reject(model.id);

    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="producttransmit:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      setModel(item);

      message.success({
        content: t("producttransmit:form.success.reject"),
        className: "message_success",
        key: "message",
        duration: 4
      });
    }
    setRejectProcessing(false);
  };

  let sidebarFields = (
    <>
      {model.id > 0 ? (
        <div className="mb-2">
          <div className="mb-1 text-xs text-gray-400">
            <Tooltip title={t("common:date_created")} mouseEnterDelay={0.4}>
              <IconClock size={14} className=" -mt-0.5" />{" "}
              <TextDateTime
                format="HH:mm, DD/MM/YYYY"
                ts={model.date_created}
              />
            </Tooltip>
          </div>
          <div className="text-xs text-gray-400">
            <Tooltip title={t("common:creator_id")} mouseEnterDelay={0.4}>
              <IconUser size={14} className=" -mt-0.5" />{" "}
              <TextUser id={model.creator_id} />
            </Tooltip>
          </div>
        </div>
      ) : null}
      <WarehouseFormSelect
        label={t("producttransmit:warehouse_id_from")}
        name="warehouse_id_from"
        disabled={isEditing ?? true}
        required
      />
      <WarehouseFormSelect
        label={t("producttransmit:warehouse_id_to")}
        name="warehouse_id_to"
        disabled={isEditing ?? true}
        required
      />
      <Form.Item label={t("producttransmit:tags")} name="tags">
        <Select mode="tags" style={{ width: "100%" }}></Select>
      </Form.Item>
      <Form.Item label={t("producttransmit:note")} name="note">
        <Input.TextArea autoSize style={{ maxHeight: 120, minHeight: 56 }} />
      </Form.Item>
    </>
  );

  //for
  let sidebarItems = null;
  if (model.status === ProductTransmit.STATUS_PENDING && model.id > 0) {
    sidebarItems = (
      <>
        <RoleCheck
          roles={[Role.INVENTORY_TRANSFER_APPROVE]}
          hideOnFail={false}
          componentOnFail={
            <Row gutter={8}>
              <Col span={14}>
                <Button type="primary" disabled={true} block size="large">
                  {t("producttransmit:approve")}
                </Button>
              </Col>
              <Col span={10}>
                <Button danger disabled={true} block size="large">
                  {t("producttransmit:cancel")}
                </Button>
              </Col>
            </Row>
          }>
          <Row gutter={8}>
            <Col span={14}>
              <Button
                type="primary"
                loading={approveProcessing}
                onClick={onApprove}
                disabled={rejectProcessing ? true : false}
                block
                size="large">
                {t("producttransmit:approve")}
              </Button>
            </Col>
            <Col span={10}>
              <Button
                loading={rejectProcessing}
                danger
                onClick={onReject}
                disabled={approveProcessing ? true : false}
                block
                size="large">
                {t("producttransmit:cancel")}
              </Button>
            </Col>
          </Row>
        </RoleCheck>
      </>
    );
  } else if (model.status !== ProductTransmit.STATUS_PENDING && model.id > 0) {
    let statusInfo = ProductTransmitModel.getStatus(model.status);
    sidebarItems = (
      <div className="mb-6">
        {t("common:status")}: <br />
        <Tag color={statusInfo?.color} className="px-2 py-1 mt-2 text-lg">
          {statusInfo?.label}
        </Tag>
      </div>
    );
  }

  return (
    <>
      <ProductTransmitFormHeader isEditing={isEditing} id={model.id} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("producttransmit:form.success.update")
            : t("producttransmit:form.success.add")
        }
        redirectOnSuccess={model.id === 0 ? "/producttransmit" : ""}
        error_translate_prefix="producttransmit:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={
          <>
            {sidebarItems}
            {model.id > 0 ? (
              <div className="h-3 mt-6 mb-6 bg-white rounded-md shadow-inner"></div>
            ) : null}
            {sidebarFields}
          </>
        }>
        <ProductTransmitDetailList
          productTranmitModel={model}
          detailItems={detailItems}
          setDetailItems={setDetailItems}
        />
      </LayoutForm>
    </>
  );
};

export default ProductTransmitForm;
