import { Button, Divider, Tooltip, Typography } from "antd";
import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import eventEmitter from "common/utils/eventEmitter";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconLink, IconPencil, IconTrash } from "@tabler/icons-react";

import { LinkAttribute } from "../extensions/HtmlEditorExtensionFullLink";

const { Link } = Typography;

const HtmlEditorBubbleMenuLink = ({ editor }: { editor: HtmlEditor }) => {
  const { t } = useTranslation("htmleditor");

  if (!editor) {
    return null;
  }

  //extract link data
  const attr = editor.getAttributes("link");

  //onclick edit button
  const onClickEdit = () => {
    //prepare data to trigger from toolbar
    const publishData: LinkAttribute = {
      href: attr.href || "",
      title: attr.title || "",
      target: attr.target || "",
      rel: attr.rel,
    };
    eventEmitter.emit("HTMLEDITOR_BUBBLE_MENU_LINK", publishData);
  };

  return (
    <>
      {editor.isActive("link") ? (
        <>
          <Tooltip title={t("link_open_new_window")} mouseEnterDelay={0.4}>
            <Link
              ellipsis
              href={attr.href}
              title={attr.href}
              target="_blank"
              rel="noreferrer"
              style={{ paddingLeft: 10 }}
            >
              {attr.href}
            </Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={t("link_edit")} mouseEnterDelay={0.4}>
            <Button
              size="small"
              type="link"
              onClick={onClickEdit}
              icon={<IconPencil size="14" color="grey" />}
            />
          </Tooltip>
          <Tooltip title={t("link_remove")} mouseEnterDelay={0.4}>
            <Button
              size="small"
              type="link"
              onClick={(e) => {
                editor.chain().focus().unsetLink().run();
              }}
              icon={<IconTrash size="14" color="grey" />}
            />
          </Tooltip>
        </>
      ) : (
        <Tooltip title={t("link_add")} mouseEnterDelay={0.4}>
          <Button
            size="small"
            type="link"
            onClick={onClickEdit}
            icon={<IconLink size="16" />}
          />
        </Tooltip>
      )}
    </>
  );
};

export default HtmlEditorBubbleMenuLink;
