import { IconEye, IconLoader, IconTag } from "@tabler/icons-react";
import { Image, Modal, Typography, message } from "antd";
import TagConstant from "common/constants/Tag";
import FileModel from "common/models/FileModel";
import ProductModel from "common/models/ProductModel";
import TagDetailModel from "common/models/TagDetailModel";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import TagRepository from "common/repositories/TagRepository";
import { SelectOption } from "common/types/SelectOption";
import NoPhoto from "components/NoPhoto";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import usePathParams from "hooks/usePathParams";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ProductToTagModal = ({
  id,
  tags,
  open,
  setOpen,
  tagName,
}: {
  id: number;
  tags: string;
  open: boolean;
  setOpen: (v: boolean) => void;
  tagName: string;
}) => {
  const { t } = useTranslation();

  const [processing, setProcessing] = useState<boolean>(false);
  console.log("🥺 ~ processing:", processing);
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>([]);
  console.log("🚀 ~ existValues:", existValues);

  //submit tag detail
  const onSubmitTagDetail = async (v: SelectOption[]) => {
    setProcessing(true);
    if (v.length > 0) {
      message.loading({ content: "Vui lòng đợi", duration: 1 });
      const response = await new TagRepository().addTagDetail({
        details: v.map((item, index) => {
          return {
            tag_id: id,
            resource_id: item.value,
            resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
            // display_order: index + 1,
          };
        }),
      });
      if (!response.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue([...existValues, ...v.map((item) => item.value)]);
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error(response.error.errors);
        setProcessing(false);

        return false;
      }
    }
    setProcessing(false);
  };

  //delete collection detial
  const onDeleteSelectedItem = async (v: SelectOption) => {
    setProcessing(true);
    message.loading({ content: "Vui lòng đợi", duration: 1 });
    const findTagDetailId = await new TagRepository().getItemsDetail({
      filters: {
        ...TagRepository.getDefaultTagDetailFilters(),
        resource_id: v.value,
        resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
        tag_id: id,
      },
    });
    if (!findTagDetailId.hasError() && findTagDetailId.items.length > 0) {
      const reponse = await new TagRepository().deleteTagDetailItem([
        findTagDetailId.items[0].id,
      ]);
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue(existValues.filter((item) => item !== v.value));
        setSelectReload(!selectReload);
      } else {
        message.error("Thao tác thất bại");
      }
    } else {
      message.error("Thao tác thất bại");
    }
    setProcessing(false);
  };

  //update existvalue
  const changeProductExistValue = (newTag: ProductModel[]) => {
    setExistValue(newTag.map((item) => item.id));
  };

  const handleAddSkuSuccess = async (skus: string[]) => {
    setSelectReload(!selectReload);
  };

  //remove current
  const handleRemoveCurrentItem = async (v: SelectOption[]) => {
    setProcessing(true);

    console.log("🥺 ~ handleRemoveCurrentItem ~ v:", v);
    if (v.length > 0) {
      const reponse = await new TagRepository().deleteProductRelatedInTag({
        tags: tags,
        resource_id: v.map((item) => item.value).toString(),
      });
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        // setExistValue(existValues.filter((item) => item !== v.value));
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error("Thao tác thất bại");
        setProcessing(false);

        return false;
      }
    }
    setProcessing(false);
  };

  return (
    <Modal
      title={
        <>
          <div>
            <Typography.Text>{`Quản lý sản phẩm có tag ${tagName}`}</Typography.Text>
            {processing && <IconLoader className="animate-spin" />}
          </div>
        </>
      }
      width={920}
      bodyStyle={{
        paddingTop: 8,
        paddingBottom: 8,
        overflowY: "auto",
        maxHeight: "calc(100vh - 250px)",
      }}
      open={open}
      confirmLoading={processing}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        setOpen(false);
      }}
      maskClosable={false}
      okButtonProps={{ style: { display: "none" } }}
      cancelText={"Đóng"}
      destroyOnClose
    >
      <AddListToItemComponent
        type={"productintag"}
        tags={tags}
        reload={selectReload}
        existValues={existValues}
        id={id}
        onSaveSuccess={onSubmitTagDetail}
        handleRemoveItem={(v: SelectOption) => {
          onDeleteSelectedItem(v);
        }}
        handleAddSkuSuccess={handleAddSkuSuccess}
        changeExistValue={changeProductExistValue}
        handleRemoveCurrentItem={handleRemoveCurrentItem}
        renderItems={(item: ProductModel) => {
          return (
            <>
              <div onClick={(e) => e.stopPropagation()}>
                {item.images.length > 0 ? (
                  <Image
                    className="max-w-[35px] max-h-[35px]"
                    src={FileModel.getThumbnailFromFilePath(
                      item.images[0].file_path,
                      40,
                      40
                    )}
                    preview={{
                      src: item.images[0].url,
                      mask: <IconEye size="16" />,
                    }}
                  />
                ) : (
                  <NoPhoto width={35} height={35} />
                )}
              </div>

              <div className="flex flex-col">
                <div className="text-blue-600 text-md font-semibold">
                  {item.full_name.length > 0 ? item.full_name : item.name}
                </div>
                <div className="text-xs text-gray-400 mt-1">
                  Mã sản phẩm: {item.sku}
                </div>
              </div>
            </>
          );
        }}
      ></AddListToItemComponent>
    </Modal>
  );
};

export default ProductToTagModal;
