import RbacRoleList from 'features/rbacrole/list/RbacRoleList';
import RbackRoleListHeader from 'features/rbacrole/list/RbacRoleListHeader';
import RbacRoleUser from 'features/rbacrole/user/RbacRoleUser';
import usePathParams from 'hooks/usePathParams';
import NotFoundPage from 'pages/NotFoundPage';
import React from 'react';

const RolePage = () => {
  const [pathParams] = usePathParams();

  let com = null;
  switch (pathParams.action) {
    case "user":
      if (typeof pathParams.id === "undefined" || +pathParams.id <= 0) {
        com = (
          <NotFoundPage>
            <RbackRoleListHeader />
          </NotFoundPage>
        );
      } else {
        com = <RbacRoleUser key={pathParams.id} user_id={+pathParams.id} />;
      }

      break;

    default:
      com = <RbacRoleList />;
  }

  return com;
};

export default RolePage;
