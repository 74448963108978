import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  TimePicker
} from "antd";
import AddressInput from "components/addressinput/AddressInput";
import RegionPicker from "components/RegionPicker";
import SimpleCollapse from "components/SimpleCollapse";
import { useTranslation } from "react-i18next";

const ShippingOrderFormSectionTo = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation();

  return (
    <SimpleCollapse
      header={t("shippingorder:orderform_heading_to")}
      defaultOpen={true}
      className="mb-4"
      childrenItem={
        <Row gutter={32} className="mb-2">
          <Col md={8} xs={24}>
            <Form.Item
              label={t("shippingorder:order_customer_fullname")}
              name="order_customer_fullname"
              required
              rules={[
                {
                  required: true,
                  message: t(
                    "shippingorder:form.error.error_order_customer_fullname_required"
                  )
                }
              ]}>
              <Input
                placeholder={t(
                  "shippingorder:order_customer_fullname_placeholder"
                )}
              />
            </Form.Item>
            <Form.Item
              label={t("shippingorder:order_customer_phone")}
              name="order_customer_phone"
              required
              rules={[
                {
                  required: true,
                  message: t(
                    "shippingorder:form.error.error_order_customer_phone_required"
                  )
                }
              ]}>
              <Input
                placeholder={t(
                  "shippingorder:order_customer_phone_placeholder"
                )}
              />
            </Form.Item>

            <Form.Item label={t("shippingorder:shipping_time")} name="hour">
              <TimePicker.RangePicker
                style={{ width: "100%" }}
                format={"HH:mm"}
                changeOnBlur
              />
            </Form.Item>

            <Form.Item label={t("shippingorder:ymd")} name="ymd">
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                changeOnBlur
              />
            </Form.Item>
          </Col>
          <Col md={16} xs={24} className="border-l-2 border-gray-200">
            <Form.Item
              key={"address"}
              label={t("shippingorder:order_shipping_address")}
              name="address"
              required
              rules={[
                {
                  required: true,
                  message: t(
                    "shippingorder:form.error.error_order_shipping_address_required"
                  )
                }
              ]}>
              <AddressInput />
            </Form.Item>

            <Form.Item key={"regions"} name="regions" noStyle>
              <RegionPicker span={8} required />
            </Form.Item>

            <Form.Item
              key={"order_shipping_address_detail"}
              label={t("shippingorder:order_shipping_address_detail")}
              name="order_shipping_address_detail">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      }
    />
  );
};

export default ShippingOrderFormSectionTo;
