import WorkChat from "../constants/WorkChat";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "common/models/BaseModel";

import type { WorkChatJson } from "../types/WorkChat";

class WorkChatModel extends BaseModel implements BaseModelJson<WorkChatJson> {
  company_id: number;
  creator_id: number;
  id: number;
  type: number;
  mode: number;
  name: string;
  topic: string;
  slug: string;
  status: number;
  description: string;
  is_default: number;
  is_featured: number;
  is_readonly: number;

  // event: string;
  meta_data: any;
  constructor(json: WorkChatJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.type = json.type || 0;
    this.mode = json.mode || 0;
    this.name = json.name || "";
    this.topic = json.topic || "";
    this.slug = json.slug || "";
    this.status = json.status || 0;
    this.description = json.description || "";
    this.is_default = json.is_default || 0;
    this.is_featured = json.is_featured || 0;
    this.is_readonly = json.is_readonly || 0;

    // // this.event = json.event || "";
    this.meta_data = json.meta_data || "";
  }
  static getDefaultData(): WorkChatJson {
    return {
      id: 0,
      company_id: 0,
      creator_id: 0,
      type: 0,
      mode: 0,
      name: "",
      topic: "",
      slug: "",
      status: 0,
      description: "",
      is_default: 0,
      is_featured: 0,
      is_readonly: 0,

      // event: "",
      meta_data: "",
    };
  }

  toJson(): WorkChatJson {
    return {
      id: this.id,
      company_id: this.company_id,
      creator_id: this.creator_id,
      type: this.type,
      mode: this.mode,
      name: this.name,
      topic: this.topic,
      slug: this.slug,
      status: this.status,
      description: this.description,
      is_default: this.is_default,
      is_featured: this.is_featured,
      is_readonly: this.is_readonly,

      // // event: this.event,
      meta_data: this.meta_data,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: WorkChat.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: WorkChat.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
      {
        value: WorkChat.STATUS_DELETED,
        label: i18n.t("common:STATUS_DELETED"),
        color: "red",
      },
      {
        value: WorkChat.STATUS_UNACTIVATED,
        label: i18n.t("common:STATUS_UNACTIVATED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: WorkChat.TYPE_CHANNEL,
        label: i18n.t("workchat:TYPE_CHANNEL"),
      },
      {
        value: WorkChat.TYPE_DIRECT_MESSAGE,
        label: i18n.t("workchat:TYPE_DIRECT_MESSAGE"),
      },
      {
        value: WorkChat.TYPE_DISCUSSION,
        label: i18n.t("workchat:TYPE_DISCUSSION"),
      },
      {
        value: WorkChat.TYPE_THREAD,
        label: i18n.t("workchat:TYPE_THREAD"),
      },
    ];
  }

  static getModeList(): SelectOption[] {
    return [
      {
        value: WorkChat.MODE_PRIVATE,
        label: i18n.t("common:MODE_PRIVATE"),
      },
      {
        value: WorkChat.MODE_PUBLIC,
        label: i18n.t("common:MODE_PUBLIC"),
      },
    ];
  }

  static getMode(value: number): SelectOption | undefined {
    return this.getModeList().find((item) => item.value === value);
  }

  static getGroupWorkChatList() {
    return [
      {
        value: "workchat.add",
        label: i18n.t("workchat:event_customor_add"),
      },
      {
        value: "workchat.edit",
        label: i18n.t("workchat:event_customor_edit"),
      },
    ];
  }
}

export default WorkChatModel;
