import { Button, Col, Input, Row, Space, Spin } from "antd";
import File from "common/constants/File";
import { FileInputValue } from "common/interfaces/FileInput";
import FileInput from "components/form/fileinput/FileInput";
import UserIdAvatar from "components/UserIdAvatar";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconPaperclip } from "@tabler/icons-react";

const CommentEditor = ({
  value,
  rows,
  cols,
  placeholder,
  autoComplete,
  autoFocus,
  disabled,
  name,
  readOnly,
  required,
  onChange,
  submitting,
  onSubmit,
  uploadedFileList,
  onChangeUpload,
}: {
  value: string;
  rows?: number;
  cols?: number;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (v: string) => void;
  submitting: boolean;
  onSubmit: () => void;
  uploadedFileList: FileInputValue;
  onChangeUpload: (value: FileInputValue) => void;
}) => {
  const { t } = useTranslation();

  const account = useLoginAccountStore((state) => state.account);

  return (
    <>
      <div className="p-4 mt-2 bg-gray-100 border border-gray-200 rounded-b-lg">
        <Spin tip="Loading..." spinning={submitting}>
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Row gutter={8}>
              <Col flex={"40px"}>
                <UserIdAvatar id={account.id} />
              </Col>
              <Col flex={"auto"}>
                <Input.TextArea
                  rows={rows}
                  cols={cols}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  placeholder={placeholder}
                  disabled={disabled}
                  autoComplete={autoComplete}
                  autoFocus={autoFocus}
                  name={name}
                  readOnly={readOnly}
                  required={required}
                />
              </Col>
              <Col flex={"80px"} className="flex items-start justify-center">
                <Button
                  htmlType="submit"
                  onClick={onSubmit}
                  type="primary"
                  block
                >
                  {t("common:form.send")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FileInput
                  objectType={File.OBJECTTYPE_ATTACHMENT}
                  origin="project"
                  max_item={10}
                  listType="text"
                  icon={<IconPaperclip size="18" style={{ marginRight: 5 }} />}
                  allowExtensions={[
                    "pdf",
                    "png",
                    "jpeg",
                    "docx",
                    "doc",
                    "xls",
                    "xlsx",
                  ]}
                  onChange={onChangeUpload}
                  value={uploadedFileList}
                />
              </Col>
            </Row>
          </Space>
        </Spin>
      </div>
    </>
  );
};

export default CommentEditor;
