import ProductReceipt from "common/constants/ProductReceipt";
import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const ProductReceiptFormHeader = ({
  isEditing,
  direction,
  id,
}: {
  isEditing: boolean;
  direction: number;
  id: number;
}) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("productreceipt:heading_nav"),
      link: "/productreceipt",
    },
  ];

  return (
    <>
      <PageHeader
        nav={nav}
        heading={
          (direction === ProductReceipt.DIRECTION_INPUT
            ? t(
                isEditing
                  ? "productreceipt:heading_edit_in"
                  : "productreceipt:heading_add_in",
              )
            : t(
                isEditing
                  ? "productreceipt:heading_edit_out"
                  : "productreceipt:heading_add_out",
              )) + (id > 0 ? " #" + id : "")
        }
        siteMenuSelectedKey="/productreceipt"
        siteMenuHiddenBackLink="/productreceipt"
        siteMenuHidden></PageHeader>
    </>
  );
};

export default ProductReceiptFormHeader;
