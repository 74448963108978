import { App, Col, Form, Input, InputNumber, Row } from "antd";
import DepartmentModel from "common/models/DepartmentModel";
import DepartmentRepository from "common/repositories/DepartmentRepository";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import DepartmentFormSelect from "../DepartmentFormSelect";

import type { DepartmentJsonAddEdit } from "common/types/Department";
const DepartmentForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: DepartmentModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: DepartmentModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      display_order: model.display_order,
      parent_id: model.parent_id > 0 ? model.parent_id : null
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: DepartmentJsonAddEdit = {
        ...DepartmentModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        display_order: formData.display_order || 0,
        parent_id: formData.parent_id
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: DepartmentModel = await new DepartmentRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="department:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("department:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("department:form.error.error_name_require")
              }
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <DepartmentFormSelect
            label={t("department:parent_id")}
            placeholder={t("department:form.parent_placeholder")}
            name="parent_id"
            parentId={0}
            ignoreValues={model.id > 0 ? [model.id] : []}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            name="display_order"
            label={t("common:display_order_long")}
            tooltip={t("common:display_order_help")}
            className="mb-0">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DepartmentForm;
