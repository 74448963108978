import { Col, Form, FormInstance, Input, Row } from "antd";
import CustomerModel from "common/models/CustomerModel";
import BirthdayInput from "components/BirthdayInput";
import FormRadio from "components/form/FormRadio";
import FormSection from "components/form/FormSection";
import LabelDateFormatHelper from "components/LabelDateFormatHelper";
import RegionPicker from "components/RegionPicker";
import CustomerTypeFormSelect from "features/customertype/CustomerTypeFormSelect";
import { useTranslation } from "react-i18next";

const PosRightCustomerAdd = ({
  form,
  onFormSubmit,
}: {
  form?: FormInstance;
  onFormSubmit: (v: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Form layout="vertical" form={form} onFinish={onFormSubmit}>
      <FormSection
        leftColumnSize={7}
        title={t("customer:customer_info_title")}
        divider
        gutter={0}
      >
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              label={t("common:full_name")}
              name="full_name"
              rules={[
                {
                  required: true,
                  message: t("customer:form.error.error_fullname_required"),
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <FormRadio
              label={t("common:gender")}
              name="gender"
              options={CustomerModel.getGenderList()}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              label="Điện thoại"
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("customer:form.error.error_phone_required"),
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item label="Email" name="email">
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col md={12} xs={24}>
            <CustomerTypeFormSelect
              name="customer_type_id"
              label={t("customer:customer_type")}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              label={
                <>
                  {t("customer:birthday")}
                  <LabelDateFormatHelper />
                </>
              }
              name="birthday"
              rules={[
                {
                  pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4}$/i),
                  message: t("common:error.error_date_format_invalid"),
                },
              ]}
            >
              <BirthdayInput />
            </Form.Item>
          </Col>
        </Row>
      </FormSection>
      <FormSection
        leftColumnSize={7}
        title={t("pos:customer.section_address")}
        gutter={0}
      >
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Form.Item label={t("customer:address")} name="address">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="regions" noStyle>
          <RegionPicker />
        </Form.Item>
      </FormSection>
    </Form>
  );
};
export default PosRightCustomerAdd;
