import i18n from "i18n";

import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import CashflowReceipt from "common/constants/CashflowReceipt";
import Delivery from "common/constants/Delivery";
import {
	ActionCode,
	DeliveryAddressDetail,
	DeliveryJson,
	OrderProductJson,
} from "common/types/Delivery";

import BaseModel from "./BaseModel";

class DeliveryModel extends BaseModel implements BaseModelJson<DeliveryJson> {
	company_id: number;
	sale_order_id: string;
	resource_id: string;
	resource_type: number;
	id: number;
	code: string;
	cost_total: number;
	cod: number;
	cod_total: number;
	cost_on_transfer: number;
	cost_note_transfer: number;
	contact_fullname: string;
	contact_phone: string;
	contact_address: string;
	note: string;
	payment_method: number;
	actor_id: number;
	action_code: ActionCode;
	distance: number;
	status: number;
	creator_id: number;
	date_created: number;
	date_modified: number;
	date_started: number;
	date_completed: number;
	date_delivered: number;
	action_started_id: number;
	action_completed_id: number;
	action_delivered_id: number;
	cotips: number;
	hastips: number;
	return_tips: number;
	date_return_tips: number;
	receive_cod: number;
	return_cod: number;
	date_return_cod: number;
	type: string;
	order_detail: OrderProductJson[];
	order_description: string;
	resource_number: string;
	item_quantity: string;
	display_order: number;
	address_detail: DeliveryAddressDetail;
	partner_delivery: number;

	constructor(json: DeliveryJson) {
		super();
		// this.stt = json.stt || 0;
		this.company_id = json.company_id;
		this.sale_order_id = json.sale_order_id;
		this.resource_id = json.resource_id;
		this.resource_type = json.resource_type;
		this.id = json.id;
		this.code = json.code;
		this.cost_total = json.cost_total;
		this.cod = json.cod;
		this.cod_total = json.cod_total;
		this.cost_on_transfer = json.cost_on_transfer;
		this.cost_note_transfer = json.cost_note_transfer;
		this.contact_fullname = json.contact_fullname;
		this.contact_phone = json.contact_phone;
		this.contact_address = json.contact_address;
		this.note = json.note;
		this.payment_method = json.payment_method;
		this.actor_id = json.actor_id;
		this.action_code = json.action_code;
		this.distance = json.distance;
		this.status = json.status;
		this.creator_id = json.creator_id;
		this.date_created = json.date_created;
		this.date_modified = json.date_modified;
		this.date_started = json.date_started;
		this.date_completed = json.date_completed;
		this.date_delivered = json.date_delivered;
		this.action_started_id = json.action_started_id;
		this.action_completed_id = json.action_completed_id;
		this.action_delivered_id = json.action_delivered_id;
		this.cotips = json.cotips;
		this.hastips = json.hastips;
		this.return_tips = json.return_tips;
		this.date_return_tips = json.date_return_tips;
		this.receive_cod = json.receive_cod;
		this.return_cod = json.return_cod;
		this.date_return_cod = json.date_return_cod;
		this.type = json.type;
		this.order_detail = json.order_detail;
		this.order_description = json.order_description;
		this.resource_number = json.resource_number;
		this.display_order = json.display_order;
		this.item_quantity = json.item_quantity;

		this.address_detail = json.address_detail;
		this.partner_delivery = json.partner_delivery;
	}

	static getDefaultData(): DeliveryJson {
		return {
			partner_delivery: 0,
			company_id: 0,
			sale_order_id: "",
			resource_id: "",
			resource_type: 0,
			id: 0,
			code: "",
			cost_total: 0,
			cod: 0,
			cod_total: 0,
			cost_on_transfer: 0,
			cost_note_transfer: 0,
			contact_fullname: "",
			contact_phone: "",
			contact_address: "",
			note: "",
			payment_method: 0,
			actor_id: 0,
			action_code: "",
			distance: 0,
			status: 0,
			creator_id: 0,
			date_created: 0,
			date_modified: 0,
			date_started: 0,
			date_completed: 0,
			date_delivered: 0,
			action_started_id: 0,
			action_completed_id: 0,
			action_delivered_id: 0,
			cotips: 0,
			hastips: 0,
			return_tips: 0,
			date_return_tips: 0,
			receive_cod: 0,
			return_cod: 0,
			date_return_cod: 0,
			type: "",
			order_detail: [],
			order_description: "",
			resource_number: "",
			display_order: 0,
			item_quantity: "",
			address_detail: {
				shipping_ward: 0,
				shipping_district: 0,
				shipping_province: 0,
				shipping_country: 0,
				shipping_carrier: 0,
				shipping_address: "",

				billing_address: "",
				billing_country: 0,
				billing_district: 0,
				billing_province: 0,
				billing_ward: 0,
			},
		};
	}

	toJson(): DeliveryJson {
		return {
			company_id: this.company_id,
			sale_order_id: this.sale_order_id,
			resource_id: this.resource_id,
			resource_type: this.resource_type,
			id: this.id,
			code: this.code,
			cost_total: this.cost_total,
			cod: this.cod,
			cod_total: this.cod_total,
			cost_on_transfer: this.cost_on_transfer,
			cost_note_transfer: this.cost_note_transfer,
			contact_fullname: this.contact_fullname,
			contact_phone: this.contact_phone,
			contact_address: this.contact_address,
			note: this.note,
			payment_method: this.payment_method,
			actor_id: this.actor_id,
			action_code: this.action_code,
			distance: this.distance,
			status: this.status,
			creator_id: this.creator_id,
			date_created: this.date_created,
			date_modified: this.date_modified,
			date_started: this.date_started,
			date_completed: this.date_completed,
			date_delivered: this.date_delivered,
			action_started_id: this.action_started_id,
			action_completed_id: this.action_completed_id,
			action_delivered_id: this.action_delivered_id,
			cotips: this.cotips,
			hastips: this.hastips,
			return_tips: this.return_tips,
			date_return_tips: this.date_return_tips,
			receive_cod: this.receive_cod,
			return_cod: this.return_cod,
			date_return_cod: this.date_return_cod,
			type: this.type,
			order_detail: this.order_detail,
			order_description: this.order_description,
			resource_number: this.resource_number,
			display_order: this.display_order,
			item_quantity: this.item_quantity,
			address_detail: this.address_detail,
			partner_delivery: this.partner_delivery,
		};
	}

	static getActionStatus(value: string) {
		const actionNames: any = {
			ACTION_CREATE: i18n.t("delivery:newstatus.ACTION_CREATE"),
			ACTION_DRAFT: i18n.t("delivery:newstatus.ACTION_DRAFT"),
			ACTION_START: i18n.t("delivery:newstatus.ACTION_START"),
			ACTION_COMPLETE: i18n.t("delivery:newstatus.ACTION_COMPLETE"),
			ACTION_ARRIVED: i18n.t("delivery:newstatus.ACTION_ARRIVED"),
			ACTION_PAUSE: i18n.t("delivery:newstatus.ACTION_PAUSE"),
			ACTION_CANCEL: i18n.t("delivery:newstatus.ACTION_CANCEL"),
			ACTION_AGAIN: i18n.t("delivery:newstatus.ACTION_AGAIN"),
			// ACTION_TRANSFER: i18n.t("delivery:newstatus.ACTION_TRANSFER"),
			ACTION_RECEIVE_COD: i18n.t("delivery:newstatus.ACTION_RECEIVE_COD"),
			ACTION_DELIVERED: i18n.t("delivery:newstatus.ACTION_DELIVERED"),
			ACTION_ADDRESS: i18n.t("delivery:newstatus.ACTION_ADDRESS"),
			// ACTION_PAYMENT_COD: i18n.t("delivery:newstatus.ACTION_PAYMENT_COD"),
			ACTION_TIPS: i18n.t("delivery:newstatus.ACTION_TIPS"),
			ACTION_APPROVE_TRANSFERS: i18n.t(
				"delivery:newstatus.ACTION_APPROVE_TRANSFERS"
			),
			ACTION_APPROVE_TIPS: i18n.t("delivery:newstatus.ACTION_APPROVE_TIPS"),
			ACTION_APPROVE_RECEIVE_COD: i18n.t(
				"delivery:newstatus.ACTION_APPROVE_RECEIVE_COD"
			),
			ACTION_REFUND: i18n.t("delivery:newstatus.ACTION_REFUND"),
			ACTION_APPROVE_REFUND: i18n.t("delivery:newstatus.ACTION_APPROVE_REFUND"),
			ACTION_RETURNED: i18n.t("delivery:newstatus.ACTION_RETURNED"),
			ACTION_WAITING: i18n.t("delivery:newstatus.ACTION_WAITING"),
			ACTION_NEW: i18n.t("delivery:newstatus.ACTION_NEW"),
		};
		return value.length > 0 ? actionNames[value] : "";
	}
	static getStatus(value: string) {
		const actionNames: any = {
			ACTION_CREATE: i18n.t("delivery:newstatus.ACTION_CREATE"),
			ACTION_START: i18n.t("delivery:newstatus.ACTION_START"),
			ACTION_COMPLETE: i18n.t("delivery:newstatus.ACTION_COMPLETE"),
			ACTION_ARRIVED: i18n.t("delivery:newstatus.ACTION_ARRIVED"),
			ACTION_PAUSE: i18n.t("delivery:newstatus.ACTION_PAUSE"),
			ACTION_CANCEL: i18n.t("delivery:newstatus.ACTION_CANCEL"),
			ACTION_AGAIN: i18n.t("delivery:newstatus.ACTION_AGAIN"),
			ACTION_TRANSFER: i18n.t("delivery:newstatus.ACTION_TRANSFER"),
			ACTION_RECEIVE_COD: i18n.t("delivery:newstatus.ACTION_RECEIVE_COD"),
			ACTION_DELIVERED: i18n.t("delivery:newstatus.ACTION_DELIVERED"),
			ACTION_ADDRESS: i18n.t("delivery:newstatus.ACTION_ADDRESS"),
			// ACTION_PAYMENT_COD: i18n.t("delivery:newstatus.ACTION_PAYMENT_COD"),
			ACTION_TIPS: i18n.t("delivery:newstatus.ACTION_TIPS"),
			ACTION_APPROVE_TRANSFERS: i18n.t(
				"delivery:newstatus.ACTION_APPROVE_TRANSFERS"
			),
			ACTION_APPROVE_TIPS: i18n.t("delivery:newstatus.ACTION_APPROVE_TIPS"),
			ACTION_APPROVE_RECEIVE_COD: i18n.t(
				"delivery:newstatus.ACTION_APPROVE_RECEIVE_COD"
			),
			ACTION_REFUND: i18n.t("delivery:newstatus.ACTION_REFUND"),
			ACTION_APPROVE_REFUND: i18n.t("delivery:newstatus.ACTION_APPROVE_REFUND"),
			ACTION_RETURNED: i18n.t("delivery:newstatus.ACTION_RETURNED"),
			ACTION_WAITING: i18n.t("delivery:newstatus.ACTION_WAITING"),
			ACTION_NEW: i18n.t("delivery:newstatus.ACTION_NEW"),
			ACTION_DRAFT: i18n.t("delivery:newstatus.ACTION_DRAFT"),
		};
		return value.length > 0 ? actionNames[value] : "...";
	}

	static getPaymentMethod(method: number) {
		switch (method) {
			case CashflowReceipt.METHOD_CASH:
				return i18n.t("delivery:method.METHOD_CASH");
			case CashflowReceipt.METHOD_BANK:
				return i18n.t("delivery:method.METHOD_BANK");
			case CashflowReceipt.METHOD_GIFTCARD:
				return i18n.t("delivery:method.METHOD_GIFTCARD");
			case CashflowReceipt.METHOD_CUSTOMER_POINT:
				return i18n.t("delivery:method.METHOD_CUSTOMER_POINT");
			case CashflowReceipt.METHOD_MOMO:
				return i18n.t("delivery:method.METHOD_MOMO");
			case CashflowReceipt.METHOD_VNPAY:
				return i18n.t("delivery:method.METHOD_VNPAY");
			case CashflowReceipt.METHOD_MEMBERCARD:
				return i18n.t("delivery:method.METHOD_MEMBERCARD");
			default:
		}
	}

	static getPaymentMethodSelect(): SelectOption[] {
		return [
			{
				value: CashflowReceipt.METHOD_CASH,
				label: i18n.t("delivery:method.METHOD_CASH"),
			},
			{
				value: CashflowReceipt.METHOD_BANK,
				label: i18n.t("delivery:method.METHOD_BANK"),
			},
			{
				value: CashflowReceipt.METHOD_GIFTCARD,
				label: i18n.t("delivery:method.METHOD_GIFTCARD"),
			},
			{
				value: CashflowReceipt.METHOD_CUSTOMER_POINT,
				label: i18n.t("delivery:method.METHOD_CUSTOMER_POINT"),
			},
			{
				value: CashflowReceipt.METHOD_MOMO,
				label: i18n.t("delivery:method.METHOD_MOMO"),
			},
			{
				value: CashflowReceipt.METHOD_VNPAY,
				label: i18n.t("delivery:method.METHOD_VNPAY"),
			},
			{
				value: CashflowReceipt.METHOD_MEMBERCARD,
				label: i18n.t("delivery:method.METHOD_MEMBERCARD"),
			},
		];
	}

	static getActionStatusList(): SelectOptionNormal[] {
		return [
			{
				value: Delivery.ACTION_CREATE,
				label: i18n.t("delivery:newstatus.ACTION_CREATE"),
			},
			{
				value: Delivery.ACTION_START,
				label: i18n.t("delivery:newstatus.ACTION_START"),
			},
			{
				value: Delivery.ACTION_COMPLETE,
				label: i18n.t("delivery:newstatus.ACTION_COMPLETE"),
			},
			{
				value: Delivery.ACTION_ARRIVED,
				label: i18n.t("delivery:newstatus.ACTION_ARRIVED"),
			},
			{
				value: Delivery.ACTION_PAUSE,
				label: i18n.t("delivery:newstatus.ACTION_PAUSE"),
			},
			{
				value: Delivery.ACTION_CANCEL,
				label: i18n.t("delivery:newstatus.ACTION_CANCEL"),
			},
			{
				value: Delivery.ACTION_AGAIN,
				label: i18n.t("delivery:newstatus.ACTION_AGAIN"),
			},
			{
				value: Delivery.ACTION_RECEIVE_COD,
				label: i18n.t("delivery:newstatus.ACTION_RECEIVE_COD"),
			},
			{
				value: Delivery.ACTION_DELIVERED,
				label: i18n.t("delivery:newstatus.ACTION_DELIVERED"),
			},
			{
				value: Delivery.ACTION_ADDRESS,
				label: i18n.t("delivery:newstatus.ACTION_ADDRESS"),
			},
			{
				value: Delivery.ACTION_TIPS,
				label: i18n.t("delivery:newstatus.ACTION_TIPS"),
			},
			{
				value: Delivery.ACTION_APPROVE_TRANSFERS,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_TRANSFERS"),
			},
			{
				value: Delivery.ACTION_APPROVE_TIPS,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_TIPS"),
			},
			{
				value: Delivery.ACTION_APPROVE_RECEIVE_COD,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_RECEIVE_COD"),
			},
			{
				value: Delivery.ACTION_REFUND,
				label: i18n.t("delivery:newstatus.ACTION_REFUND"),
			},
			{
				value: Delivery.ACTION_APPROVE_REFUND,
				label: i18n.t("delivery:newstatus.ACTION_APPROVE_REFUND"),
			},
			{
				value: Delivery.ACTION_RETURNED,
				label: i18n.t("delivery:newstatus.ACTION_RETURNED"),
			},
			{
				value: Delivery.ACTION_WAITING,
				label: i18n.t("delivery:newstatus.ACTION_WAITING"),
			},
			{
				value: Delivery.ACTION_NEW,
				label: i18n.t("delivery:newstatus.ACTION_NEW"),
			},
		];
	}

	static getShipperActionStatusList(): SelectOptionNormal[] {
		return [
			{
				value: Delivery.ACTION_CREATE,
				label: i18n.t("delivery:newstatus.ACTION_CREATE"),
			},
			{
				value: Delivery.ACTION_START,
				label: i18n.t("delivery:newstatus.ACTION_START"),
			},
			{
				value: Delivery.ACTION_PAUSE,
				label: i18n.t("delivery:newstatus.ACTION_PAUSE"),
			},
			{
				value: Delivery.ACTION_AGAIN,
				label: i18n.t("delivery:newstatus.ACTION_AGAIN"),
			},
			{
				value: Delivery.ACTION_ARRIVED,
				label: i18n.t("delivery:newstatus.ACTION_ARRIVED"),
			},
			{
				value: Delivery.ACTION_DELIVERED,
				label: i18n.t("delivery:newstatus.ACTION_DELIVERED"),
			},
			{
				value: Delivery.ACTION_COMPLETE,
				label: i18n.t("delivery:newstatus.ACTION_COMPLETE"),
			},
			{
				value: Delivery.ACTION_REFUND,
				label: i18n.t("delivery:newstatus.ACTION_REFUND"),
			},
		];
	}

	static getManagerActionStatusList(): SelectOptionNormal[] {
		return [
			{
				value: Delivery.ACTION_NEW,
				label: i18n.t("delivery:newstatus.ACTION_NEW"),
			},
			{
				value: Delivery.ACTION_WAITING,
				label: i18n.t("delivery:newstatus.ACTION_WAITING"),
			},
			{
				value: Delivery.ACTION_CREATE,
				label: i18n.t("delivery:newstatus.ACTION_CREATE"),
			},
			{
				value: Delivery.ACTION_START,
				label: i18n.t("delivery:newstatus.ACTION_START"),
			},
			{
				value: Delivery.ACTION_PAUSE,
				label: i18n.t("delivery:newstatus.ACTION_PAUSE"),
			},
			{
				value: Delivery.ACTION_AGAIN,
				label: i18n.t("delivery:newstatus.ACTION_AGAIN"),
			},
			{
				value: Delivery.ACTION_ARRIVED,
				label: i18n.t("delivery:newstatus.ACTION_ARRIVED"),
			},
			{
				value: Delivery.ACTION_DELIVERED,
				label: i18n.t("delivery:newstatus.ACTION_DELIVERED"),
			},
			{
				value: Delivery.ACTION_COMPLETE,
				label: i18n.t("delivery:newstatus.ACTION_COMPLETE"),
			},
			{
				value: Delivery.ACTION_REFUND,
				label: i18n.t("delivery:newstatus.ACTION_REFUND"),
			},
			{
				value: Delivery.ACTION_RETURNED,
				label: i18n.t("delivery:newstatus.ACTION_RETURNED"),
			},
			{
				value: Delivery.ACTION_CANCEL,
				label: i18n.t("delivery:newstatus.ACTION_CANCEL"),
			},
		];
	}

	static getPartnersShip(): SelectOptionNormal[] {
		return [
			{
				value: Delivery.PARTNER_NSHIP,
				label: i18n.t("NShip"),
			},
			{
				value: Delivery.PARTNER_VNPOST,
				label: i18n.t("VNPost"),
			},
		];
	}
}

export default DeliveryModel;
