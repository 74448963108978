import { Button, Col, Row } from "antd";
import ProjectModel from "common/models/ProjectModel";
import { FilterProjectTask } from "common/types/ProjectTask";
import ProjectIterationList from "features/projectiteration/list/ProjectIterationList";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconPlus } from "@tabler/icons-react";

const ProjectIterationFormInformation = ({
  setOpen,
  projectModel,
  totalTask,
  canAddIteration,
  filters,
  setFilters,
  defaultFilters,
  setProjectIterationId,
  inputKeyword,
}: {
  setOpen: (v: boolean) => any;
  projectModel: ProjectModel;
  totalTask: number;
  canAddIteration: boolean;
  filters: FilterProjectTask;
  setFilters: (f: FilterProjectTask) => void;
  defaultFilters: FilterProjectTask;
  setProjectIterationId: (id: number) => void;
  inputKeyword: string | number;
}) => {
  const { t } = useTranslation();

  const doFilter = (iterationId: number) => {
    setFilters({ ...filters, iteration_id: iterationId });
    setOpen(false);
  };

  const resetFilter = () => {
    setFilters(defaultFilters);
    setOpen(false);
  };

  return (
    <>
      {canAddIteration ? (
        <div className="pb-4 border-b-2">
          <Row gutter={16}>
            <Col md={14} xs={24}>
              {t("projectiteration:create_iteration")}
            </Col>
            <Col md={10} xs={24}>
              <div className="text-right">
                <Button
                  type="link"
                  size="small"
                  style={{ whiteSpace: "break-spaces" }}
                  icon={<IconPlus size={14} />}
                  onClick={(e) => {
                    e.preventDefault();
                    setProjectIterationId(0);
                  }}
                ></Button>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}

      <div className="py-4">
        <Row
          gutter={16}
          className="mb-4 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            resetFilter();
          }}
        >
          <Col md={14} xs={24}>
            <strong>{t("projectiteration:all_task")}</strong>
          </Col>
          <Col md={10} xs={24}>
            <div className="mr-2 text-right">{totalTask}</div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            md={24}
            xs={24}
            className="cursor-pointer "
            onClick={(e) => {
              e?.preventDefault();
              doFilter(0);
            }}
          >
            <p>{t("projectiteration:un_milestone")}</p>
          </Col>
        </Row>
      </div>

      <ProjectIterationList
        projectModel={projectModel}
        canManage={canAddIteration}
        inputKeyword={inputKeyword}
        doFilter={doFilter}
        setProjectIterationId={setProjectIterationId}
      />
    </>
  );
};

export default ProjectIterationFormInformation;
