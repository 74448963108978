import { Skeleton } from "antd";
import TagModel from "common/models/TagModel";

import TagRepository from "common/repositories/TagRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductRelatedAddTagForm from "./ProductRelatedAddTagForm";

const ProductRelatedAddTagModal = ({
  id,
  open,
  productId,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  productId: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: TagModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<TagModel>(
    new TagModel(TagModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  return (
    <FormModal
      title={id > 0 ? t("Chỉnh sửa") : "Thêm và gắn tag mới vào sản phẩm này"}
      width={480}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="tagtype-form"
      destroyOnClose
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("tag:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                  }}
                />
              ) : (
                <ProductRelatedAddTagForm
                  productId={productId}
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <ProductRelatedAddTagForm
          productId={productId}
          model={new TagModel(TagModel.getDefaultData())}
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default ProductRelatedAddTagModal;
