import { AutoComplete, Col, Input, Row, Spin } from "antd";
import SiteSearchRepository from "common/repositories/SiteSearchRepository";
import { SiteSearchItem } from "common/types/SiteSearch";
import Helper from "common/utils/helper";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconSearch } from "@tabler/icons-react";

const renderTitle = (title: string) => <span>{title}</span>;

const renderItem = (item: SiteSearchItem) => ({
  value: item.name,
  key: item.link,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}>
      <Row>
        <Col flex="auto">{item.name}</Col>
      </Row>

      <span style={{ color: "#cccccc" }}></span>
    </div>
  ),
});

const HeaderSearch = () => {
  const { t } = useTranslation();

  useHotkeys("cmd+k,ctrl+f", () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });
  const inputRef = useRef<any>();

  const account = useLoginAccountStore((state) => state.account);
  const [options, setOptions] = useState<any>();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const onSelectSearchResult = useCallback(
    (value: any, option: any) => {
      if (typeof option.key === "string" && option.key.length > 0) {
        setQuery("");
        navigate(option.key);
      }
    },
    [navigate],
  );

  const doSearchInSite = useCallback(
    (q: any): SiteSearchItem[] => {
      const results: SiteSearchItem[] = [];
      const refinedQuery = Helper.codau2khongdau(q).toLowerCase();

      SiteSearchRepository.getAllResults(account).forEach((item) => {
        //check matching item
        if (
          Helper.codau2khongdau(item.query)
            .toLowerCase()
            .indexOf(refinedQuery) >= 0
        ) {
          results.push({
            name: item.name,
            link: item.link,
            query: item.query,
          });
        }
      });

      return results;
    },
    [account],
  );

  const doSearch = useCallback(
    (q: string) => {
      setLoading(true);

      //search in SiteMenu
      const results = doSearchInSite(q);

      //building result
      const renderedOptions = [];

      if (results.length > 0) {
        renderedOptions.push({
          label: renderTitle(
            t("common:header_search.menu_label", { count: results.length }),
          ),
          options: results.map((resultItem) => renderItem(resultItem)),
        });
      }

      setOptions(renderedOptions);
      setLoading(false);
    },
    [doSearchInSite, t],
  );

  useEffect(() => {
    //only search when keyword lenth >= 2
    if (query.length >= 2) {
      doSearch(query);
    } else {
      setOptions([]);
    }
  }, [query, doSearch]);

  return (
    <div className="header-search">
      <AutoComplete
        popupMatchSelectWidth={500}
        options={options}
        onSelect={onSelectSearchResult}
        value={query}>
        <Input
          ref={inputRef}
          style={{ borderRadius: 20 }}
          suffix={
            loading ? (
              <Spin style={{ marginTop: 3, marginRight: 3 }} size="small" />
            ) : (
              <IconSearch size={18} color="#DDDDDD" />
            )
          }
          allowClear={!loading}
          size="middle"
          placeholder={t("common:header_search.placeholder")}
          onChange={(e) => setQuery(e.target.value)}
        />
      </AutoComplete>
    </div>
  );
};

export default HeaderSearch;
