import ProductCollectionApi from "common/api/ProductCollectionApi";
import ProductCollectionCollection from "common/collections/ProductCollectionCollection";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import { GetListProps } from "common/types/Filter";
import {
	AddCollectionToCollection,
	CollectionDetailDelete,
	DataChangeDislayOrder,
	FilterDetailProductCollection,
	FilterProductCollection,
	FilterProductCollectionDetail,
	ProductCollectionAddEditJson,
	ProductCollectionDetailAddEditJson,
	ProductCollectionJsonWithChildren,
	ProductDetailDelete,
	URLToHandleJson,
	URLWebJson,
} from "common/types/ProductCollection";
import BaseRepository from "./BaseRepository";
import NestedCollection from "common/collections/NestedCollection";
import ProductCollectionDetailCollection from "common/collections/ProductCollectionDetail";
import DetailProductCollectionCollection from "common/collections/DetailProductCollectionCollection";

class ProductCollectionRepository extends BaseRepository<ProductCollectionApi> {
	_api: ProductCollectionApi | null;
	constructor() {
		super();
		this._api = new ProductCollectionApi(true);
	}

	static getDefaultFilters(): FilterProductCollection {
		return {
			page: 1,
			limit: 20,
			sortby: "",
			sorttype: "",
			keyword: "",
			status: 1,
			publish_from: -1,
			publish_to: -1,
			pin: -1,
			parent_id: -1,
			ids: "",
		};
	}

	static getDefaultDetailFilters(): FilterDetailProductCollection {
		return {
			page: 1,
			limit: 20,
			sortby: "",
			sorttype: "",
			keyword: "",
			status: 1,
		};
	}

	static getDefaultProductCollectionDetailFilters(): FilterProductCollectionDetail {
		return {
			collection_id: -1,
			product_ids: -1,
		};
	}

	async getItems(
		props: GetListProps<FilterProductCollection>
	): Promise<ProductCollectionCollection> {
		return this._api
			? await this._api.getList(props)
			: new ProductCollectionCollection();
	}

	async getFullNestedItems(props: GetListProps<FilterProductCollection>) {
		return this._api
			? await this._api.getAll(props)
			: new NestedCollection<ProductCollectionJsonWithChildren>();
	}

	async getItem(id: number | string): Promise<ProductCollectionModel> {
		return this._api
			? await this._api.getDetail(id)
			: new ProductCollectionModel(ProductCollectionModel.getDefaultData());
	}

	//add - edit
	async saveRemote(
		data: Partial<ProductCollectionAddEditJson>
	): Promise<ProductCollectionModel> {
		if (this._api) {
			if (data?.id && data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new ProductCollectionModel(
				ProductCollectionModel.getDefaultData()
			);
		}
	}

	//delete
	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	datas = [
		{
			id: 1000032907,
			handle: "deal-thom",
			title: "Tuần Lễ Thương Hiệu",
			type: "collection",
			from_time: 1712831589,
			to_time: 0,
			link: "/collections/tuan-le-thuong-hieu",
			link_more: "/collections/tuan-le-thuong-hieu",
			title_more: "Xem tất cả",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "tuan-le-thuong-hieu",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 0,
			title: "Sale Tháng 7",
			title_more: "Xem tất cả",
			handle: "sales-thang-7",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/sales-thang-7",
			link_more: "collections/sales-thang-7",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "sales-thang-7",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			id: 1000032907,
			type: "collection",
			title: "Deal Thơm",
			title_more: "Xem tất cả",
			handle: "deal-thom",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/deal-thom",
			link_more: "collections/deal-thom",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "deal-thom",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			id: 1000032901,
			type: "collection",
			title: "New Arrivals",
			title_more: "Xem tất cả",
			handle: "nuoc-hoa-moi",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-moi",
			link_more: "collections/nuoc-hoa-moi",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-moi",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 1000032931,
			title: "Bestsellers",
			title_more: "Xem tất cả",
			handle: "yeu-thich-nhat",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/yeu-thich-nhat",
			link_more: "collections/yeu-thich-nhat",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "yeu-thich-nhat",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},

		{
			type: "section_banner",
			id: 0,
			handle: "banner_nnn",
			from_time: 1712136268,
			title_more: "Xem tất cả",
			to_time: 0,
			link: "",
			link_more: "",
			title: "",
		},
		{
			type: "collection",
			id: 1010720,
			title: "Mini & Travel Size",
			title_more: "Xem tất cả",
			handle: "nuoc-hoa-mini",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-mini",
			link_more: "collections/nuoc-hoa-mini",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-mini",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 456353,
			title: "Giftset",
			title_more: "Xem tất cả",
			handle: "giftset-nuoc-hoa",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/giftset-nuoc-hoa",
			link_more: "collections/giftset-nuoc-hoa",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "giftset-nuoc-hoa",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 0,
			title: "Bodycare & Homecare",
			title_more: "Xem tất cả",
			handle: "bodycare-homecare",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/bodycare-homecare",
			link_more: "collections/bodycare-homecare",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "bodycare-homecare",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "namperfumetv",
			id: 0,
			handle: "",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "namperfume TV",
			title_more: "Xem tất cả",
		},
		{
			type: "nmagazine",
			id: 509,
			handle: "brand_nmagazine",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Nmagazine",
			title_more: "Xem tất cả",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_1",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem tất cả",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_2",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem tất cả",
		},
	];

	//add collection detail
	async saveRemoteDetail(
		data: ProductCollectionDetailAddEditJson
	): Promise<ProductCollectionModel> {
		if (this._api) {
			return await this._api.addDetail(data);
		} else {
			return new ProductCollectionModel(
				ProductCollectionModel.getDefaultData()
			);
		}
	}

	//delete collection detail
	async deleteItemDetail(data: ProductDetailDelete): Promise<string[]> {
		return this._api ? await this._api.deleteDetail(data) : [];
	}

	async getProductCollectionDetail(
		props: GetListProps<FilterProductCollectionDetail>
	) {
		return this._api
			? await this._api.getProductColelctionDetailList(props)
			: new ProductCollectionDetailCollection();
	}

	//get URL website
	async getUrlFromHandle(data: URLToHandleJson): Promise<URLWebJson> {
		return this._api ? await this._api.URLFromHandle(data) : { url: "" };
	}

	//delete collection in collection
	async deleteCollectionItemCDetail(
		data: CollectionDetailDelete
	): Promise<string[]> {
		return this._api ? await this._api.deleteCollectionDetail(data) : [];
	}

	//add collection in collection
	async addCollectionInCollection(
		data: AddCollectionToCollection
	): Promise<ProductCollectionModel> {
		return this._api
			? await this._api.addCollectionToCollectionDetail(data)
			: new ProductCollectionModel(ProductCollectionModel.getDefaultData());
	}

	async getListDetailProductCollection(props: FilterDetailProductCollection) {
		return this._api
			? this._api.getDetailProductCollection(props)
			: new DetailProductCollectionCollection();
	}

	async editDisplayOrderProductCollection(
		props: DataChangeDislayOrder
	): Promise<string[]> {
		return this._api ? this._api.updateDisplayOrderDetail(props) : [];
	}
}

export default ProductCollectionRepository;
