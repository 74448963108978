import BaseCollection from "common/collections/BaseCollection";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import {
  PurchaseOrderCollectionJson,
  PurchaseOrderJson,
} from "common/types/PurchaseOrder";

class PurchaseOrderCollection extends BaseCollection<
  PurchaseOrderJson,
  PurchaseOrderModel,
  PurchaseOrderCollectionJson
> {
  itemsFromJson(jsonItems: PurchaseOrderJson[]): PurchaseOrderModel[] {
    return jsonItems.map((item) => new PurchaseOrderModel(item));
  }
}

export default PurchaseOrderCollection;
