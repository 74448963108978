import { Button, Col, Form, Input, Row } from "antd";
import File from "common/constants/File";
import Shipper from "common/constants/Shipper";
import FileModel from "common/models/FileModel";
import ShipperModel from "common/models/ShipperModel";
import ShipperRepository from "common/repositories/ShipperRepository";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FormRadio from "components/form/FormRadio";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import ShipperEditorHeader from "features/shipper/form/ShipperFormHeader";
import ShippingHubFormSelect from "features/shippinghub/ShippingHubFormSelect";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconPencil, IconPhoto } from "@tabler/icons-react";

import ShipperPasswordModal from "./ShipperPasswordModal";

import type { ShipperJsonAddEdit } from "common/types/Shipper";
const ShipperEditor = ({ model }: { model: ShipperModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const [errors, setErrors] = useState<string[]>([]);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = {
    email: model.email,
    full_name: model.full_name,
    gender: model.gender >= 0 ? model.gender : Shipper.GENDER_MALE,
    shipping_hub_id: model.shipping_hub_id > 0 ? model.shipping_hub_id : null,
    phone: model.phone,
    internal_id: model.internal_id,
    status: model.status || Shipper.STATUS_ENABLE,
    files: FileModel.convertToUploadFiles([model.avatar]),
    password: model.password,
    transportation: model.transportation || Shipper.TYPE_MOTOBIKE,
    transportation_note: model.transportation_note,
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ShipperJsonAddEdit = {
        ...ShipperModel.getDefaultData(),
        id: model.id,
        shipping_hub_id: formData.shipping_hub_id,
        email: formData.email,
        full_name: formData.full_name,
        password: formData.password,
        gender: formData.gender,
        phone: formData.phone,
        internal_id: formData.internal_id,
        status: formData.status,
        transportation: formData.transportation,
        transportation_note: formData.transportation_note,
        avatar_file_id: FileModel.getFirstFileId(formData.files),
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    doPrepareData(formData);
    let myObj: ShipperModel = await new ShipperRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
      setIsSuccess(false);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <Form.Item label={t("shipper:avatar")}>
        <FileUploaderButton
          form={form}
          formName="files"
          label={
            isEditing ? t("shipper:update_avatar") : t("shipper:select_avatar")
          }
          icon={<IconPhoto size="24" color="grey" />}
          origin="avatar"
          objectType={File.OBJECTTYPE_ATTACHMENT}
          uploadRenderType="thumbnail"
          initFiles={initialValues.files}
          maxCount={1}
        />
      </Form.Item>
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={ShipperModel.getStatusList()}
      />
    </div>
  );

  return (
    <>
      <ShipperEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("shipper:form.success.update")
            : t("shipper:form.success.add")
        }
        redirectOnSuccess="/shipper"
        error_translate_prefix="shipper:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("shipper:form.section_account")}
          subtitle={t("shipper:form.section_account_subtitle")}
          className="p-6 pb-4 mb-6 -m-6 border-b border-gray-100 rounded-sm bg-green-50"
          gutter={0}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("common:phone")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("shipper:form.error.error_phone_required"),
                  },
                ]}
              >
                <Input autoFocus autoComplete="off" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("shipper:password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("shipper:form.error.error_password_required"),
                  },
                ]}
              >
                {isEditing ? (
                  <Button
                    type="primary"
                    danger
                    ghost
                    icon={<IconPencil size="14" className="mr-2" />}
                    onClick={() => setShowPasswordFields(!showPasswordFields)}
                  >
                    {t("shipper:password_change")}
                  </Button>
                ) : (
                  <Input.Password autoComplete="new-password" />
                )}
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("shipper:form.section_contact")}
          subtitle={t("shipper:form.section_contact_subtitle")}
          divider
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("common:full_name")}
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: t("common:error.required_full_name"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <FormRadio
                required
                label={t("common:gender")}
                name="gender"
                options={ShipperModel.getGenderList()}
                rules={[
                  {
                    required: true,
                    message: t("common:error.required_gender"),
                  },
                ]}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:email")} name="email">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("shipper:internal_id")}
                name="internal_id"
                tooltip={t("shipper:internal_id_help")}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <ShippingHubFormSelect
                label={t("shipper:shipping_hub")}
                name="shipping_hub_id"
                placeholder={t("shipper:shipping_hub_placeholder")}
              />
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("shipper:form.section_transportation")}
          subtitle={t("shipper:form.section_transportation_subtitle")}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <FormSelect
                label={t("shipper:transportation")}
                name="transportation"
                options={ShipperModel.getTransportationList()}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("shipper:transportation_note")}
                name="transportation_note"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>

      {showPasswordFields ? (
        <ShipperPasswordModal
          model={model}
          open={showPasswordFields}
          setOpen={(isOpen) => {
            setShowPasswordFields(isOpen);
          }}
        />
      ) : null}
    </>
  );
};

export default ShipperEditor;
