import { ProductVariantInMemory } from 'common/types/ProductVariant';
import dbm from 'dbm';
import React, { useEffect, useState } from 'react';

const TextProductVariantSku = ({ id }: { id: number }) => {
  const [variant, setVariant] = useState<ProductVariantInMemory>();

  useEffect(() => {
    (async () => {
      const foundItems = await dbm
        .getCollection("productvariant")
        .chain()
        .find({
          id,
        })
        .limit(1)
        .data();
      if (foundItems.length > 0) {
        setVariant(foundItems[0]);
      }
    })();
  }, [id]);

  if (typeof variant === "undefined") {
    return null;
  }

  return <>{variant.sku}</>;
};

export default TextProductVariantSku;
