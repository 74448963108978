import PurchaseOrderCollection from "common/collections/PurchaseOrderCollection";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterPurchaseOrder,
  PurchaseOrderJsonAddEdit
} from "common/types/PurchaseOrder";
const SERVICE_URL = "purchaseorders";

class PurchaseOrderApi extends BaseApi {
  async getList(
    props: GetListProps<FilterPurchaseOrder>
  ): Promise<PurchaseOrderCollection> {
    let collection = new PurchaseOrderCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status:
            typeof filters.status === "number" && filters.status > 0
              ? filters.status
              : null,
          id: filters.id > 0 ? filters.id : null,
          supplier_id: filters.supplier_id > 0 ? filters.supplier_id : null,
          tag: filters.tag ? filters.tag : null,
          date_started: filters.date_started > 0 ? filters.date_started : null,
          date_ended: filters.date_ended > 0 ? filters.date_ended : null
        }
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<PurchaseOrderModel> {
    let item = new PurchaseOrderModel(PurchaseOrderModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new PurchaseOrderModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: PurchaseOrderJsonAddEdit): Promise<PurchaseOrderModel> {
    let item = new PurchaseOrderModel(PurchaseOrderModel.getDefaultData());
    try {
      let postData = {
        ...data
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new PurchaseOrderModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  async edit(data: PurchaseOrderJsonAddEdit): Promise<PurchaseOrderModel> {
    let item = new PurchaseOrderModel(PurchaseOrderModel.getDefaultData());

    try {
      let postData = {
        ...data
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new PurchaseOrderModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default PurchaseOrderApi;
