import CompanyInfoIndex from "features/company/info/CompanyInfoIndex";
import CompanyUpgradeIndex from "features/company/upgrade/CompanyUpgradeIndex";
import usePathParams from "hooks/usePathParams";

const CompanyPage = () => {
  const [pathParams] = usePathParams();

  let com = null;
  switch (pathParams.action) {
    case "upgrade":
      com = <CompanyUpgradeIndex />;
      break;
    case "info":
      com = <CompanyInfoIndex />;
      break;
    default:
      com = null;
  }

  return com;
};

export default CompanyPage;
