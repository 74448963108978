import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkScheduleManageHeader = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("workschedule:heading_manage")}
      siteMenuSelectedKey="/checkin/approval"
      siteMenuHidden
    >
      {children}
    </PageHeader>
  );
};

export default WorkScheduleManageHeader;
