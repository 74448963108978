import { Empty, Table, Tag } from 'antd';
import CustomerPointModel from 'common/models/CustomerPointModel';
import { IdFetcherResult } from 'common/types/IdFetcher';
import { TableColumnsType } from 'common/types/Table';
import Helper from 'common/utils/helper';
import TextDateTimeRelative from 'components/TextDateTimeRelative';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomerFormLatestPoint = ({
  customerId,
  dataSource,
  mappingOrder,
  setOpen,
}: {
  customerId: number;
  dataSource: CustomerPointModel[];
  mappingOrder?: IdFetcherResult[];
  setOpen: (v: boolean) => any;
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<CustomerPointModel> = [
    {
      title: t("common:date_created"),
      key: "date_created",
      dataIndex: "date_created",
      width: 100,
      render: (ts: number) => <TextDateTimeRelative ts={ts} />,
    },
    {
      title: t("customerpoint:type"),
      key: "type",
      dataIndex: "type",
      render: (type: number) => {
        let typeInfo = CustomerPointModel.getType(type);
        return <span>{typeInfo?.label}</span>;
      },
      width: 120,
    },
    {
      title: t("customerpoint:point"),
      key: "value",
      dataIndex: "value",
      width: 100,
      render: (_: any, record: CustomerPointModel) =>
        record.value > 0 ? (
          <Tag color={"green"}>{"+" + Helper.numberFormat(record.value)}</Tag>
        ) : (
          <Tag color={"red"}>{Helper.numberFormat(record.value)}</Tag>
        ),
    },
  ];

  return (
    <>
      {dataSource.length > 0 ? (
        <Table
          rowKey="id"
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default CustomerFormLatestPoint;
