import { Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";

import NotificationModel from "common/models/NotificationModel";
import NotificationRepository from "common/repositories/NotificationRepository";

import NotificationDetail from "./NotificationDetail";
import NotificationFeature from "./NotificationFeature";

type Props = {
  id: number;
  setId: (id: number) => void;
  open: boolean;
  setOpen: (o: boolean) => void;
  list: NotificationModel[];
};
const NotificationDetailModal = React.memo(
  ({ id, setId, open, setOpen, list }: Props) => {
    const { t } = useTranslation();
    //state

    const [model, setModel] = useState<NotificationModel>(
      new NotificationModel(NotificationModel.getDefaultData())
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    // fetch detail
    const fetchDetail = useCallback(async (id: number) => {
      setLoading(true);

      const response = await new NotificationRepository().getItem(id);

      if (response.hasError()) {
        setErrors(response.error.errors);
      }

      setModel(response);
      setLoading(false);
    }, []);

    useEffect(() => {
      id > 0 && fetchDetail(id);
    }, [id]);

    return (
      <Modal
        width={"100%"}
        open={open}
        closable={true}
        centered
        destroyOnClose={true}
        onCancel={() => {
          setId(0);
          setOpen(false);
        }}
        bodyStyle={{ padding: "40px 0 16px 16px" }}
        className=" max-w-[90vw] lg:max-w-[60vw] m-6"
        footer={null}
      >
        <Spin spinning={loading}>
          {errors.length > 0 ? errors[0] : <NotificationDetail model={model} />}
          <div className="mr-4">
            <NotificationFeature model={model} list={list} setId={setId} />
          </div>
        </Spin>
      </Modal>
    );
  }
);

export default NotificationDetailModal;
