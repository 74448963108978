import { Breadcrumb } from "antd";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { Nav } from "common/types/PageNavigation";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const PageBreadcrumb = ({ nav }: { nav?: Nav }) => {
  let breadcrumbItems: ItemType[] = useMemo(() => {
    if (nav && nav.length > 0) {
      return nav.map((i) => {
        return {
          title: i?.text || "",
          path: i?.link || "",
        };
      }) as ItemType[];
    } else {
      return [];
    }
  }, [nav]);

  return (
    <>
      {nav && nav.length > 0 ? (
        <Breadcrumb
          items={breadcrumbItems}
          itemRender={(
            route: ItemType,
            params: any,
            routes: ItemType[],
            paths: string[]
          ) => {
            return (
              <>
                {route.path != null && route.path.length > 0 ? (
                  <Link to={route.path}>{route.title}</Link>
                ) : (
                  <span>{route.title}</span>
                )}
              </>
            );
          }}
        />
      ) : // <Breadcrumb className="mb-2">
      //   {nav.map((item) => {
      //     return (
      //       <Breadcrumb.Item key={item.text}>
      //         {item.link != null && item.link.length > 0 ? (
      //           <Link to={item.link}>{item.text}</Link>
      //         ) : (
      //           <span>{item.text}</span>
      //         )}
      //       </Breadcrumb.Item>
      //     );
      //   })}
      // </Breadcrumb>
      null}
    </>
  );
};

export default PageBreadcrumb;
