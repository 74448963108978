import ShipperModel from 'common/models/ShipperModel';
import ShipperRepository from 'common/repositories/ShipperRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import ShipperForm from 'features/shipper/form/ShipperForm';
import ShipperFormHeader from 'features/shipper/form/ShipperFormHeader';
import ShipperList from 'features/shipper/list/ShipperList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const ShipperPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<ShipperModel>(
    new ShipperModel(ShipperModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new ShipperModel(ShipperModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <ShipperForm model={new ShipperModel(ShipperModel.getDefaultData())} />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ShipperForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new ShipperRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<ShipperFormHeader isEditing={true} />}
          />
        );

      break;
    default:
      com = <ShipperList />;
  }

  return com;
};

export default ShipperPage;
