import OrderModel from "common/models/OrderModel";
import OrderForm from "features/order/form/OrderForm";
import { useEffect, useState } from "react";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

const OrderFormWrapper = ({ model }: { model: OrderModel }) => {
  const [loaded, setLoaded] = useState(false);

  const setOrder = useEditingSaleOrderStore((state) => state.setOrder);

  useEffect(() => {
    if (!loaded) {
      setOrder(model.toJson());
      setLoaded(true);
    }
  }, [model, setOrder, setLoaded, loaded]);
  return <>{loaded ? <OrderForm /> : null}</>;
};

export default OrderFormWrapper;
