import PositionApi from "common/api/PositionApi";
import PositionCollection from "common/collections/PositionCollection";
import { FilterPosition } from "common/types/Position";
import BaseRepository from "./BaseRepository";

class PositionRepository extends BaseRepository<PositionApi> {
  _api: PositionApi | null;

  constructor() {
    super();
    this._api = new PositionApi(true);
  }
  static getDefaultFilters(): FilterPosition {
    return {
      page: 1,
      limit: 50,
      sortby: "id",
      sorttype: "DESC",
      status: 0,
      keyword: "",
    };
  }
  async getItems() {
    return this._api ? await this._api.getList() : new PositionCollection();
  }

  async getLabel() {
    const collection = this._api
      ? await this._api.getList()
      : new PositionCollection();
    if (collection) {
      let newLabels: any[] = [];
      collection.items.forEach((item) => {
        newLabels.push({
          value: item.position_id,
          label: item.position_name,
        });
      });
      return newLabels;
    }
  }
}
export default PositionRepository;
