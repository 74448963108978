// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_mainMenu__b2jxJ {
  justify-content: space-between;
  align-items: center;
  display: none;
  padding-top: 12px;
}

@media screen and (max-width: 768px) {
  .styles_mainMenu__b2jxJ {
    display: flex;
    gap: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyMenu/styles.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,QAAQ;EACV;AACF","sourcesContent":[".mainMenu {\n  justify-content: space-between;\n  align-items: center;\n  display: none;\n  padding-top: 12px;\n}\n\n@media screen and (max-width: 768px) {\n  .mainMenu {\n    display: flex;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainMenu": `styles_mainMenu__b2jxJ`
};
export default ___CSS_LOADER_EXPORT___;
