import BaseCollection from 'common/collections/BaseCollection';
import ProductVariantInMemoryModel from 'common/models/ProductVariantInMemoryModel';
import {
    ProductVariantInMemory, ProductVariantInMemoryCollectionJson
} from 'common/types/ProductVariant';

class ProductVariantInMemoryCollection extends BaseCollection<
  ProductVariantInMemory,
  ProductVariantInMemoryModel,
  ProductVariantInMemoryCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductVariantInMemory[]
  ): ProductVariantInMemoryModel[] {
    return jsonItems.map((item) => new ProductVariantInMemoryModel(item));
  }
}

export default ProductVariantInMemoryCollection;
