import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import type { RbacRoleJson } from "common/types/RbacRole";

class RbacRoleModel extends BaseModel implements BaseModelJson<RbacRoleJson> {
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  description: string;
  date_created: number;
  date_modified: number;
  count_user: number;
  assigned_subjects: number[];

  constructor(json: RbacRoleJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.description = json.description || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.count_user = json.count_user || 0;
    this.assigned_subjects = json.assigned_subjects || [];
  }

  static getDefaultData(): RbacRoleJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      description: "",
      date_created: 0,
      date_modified: 0,
      count_user: 0,
      assigned_subjects: [],
    };
  }

  toJson(): RbacRoleJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      description: this.description,
      date_created: this.date_created,
      date_modified: this.date_modified,
      count_user: this.count_user,
      assigned_subjects: this.assigned_subjects,
    };
  }
}

export default RbacRoleModel;
