import React from "react";
import { Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { cn } from "common/utils/utils";

type Props = {
	className?: string;
	loading: boolean;
	children?: React.ReactNode;
};
export default function FormLoading({ className, loading, children }: Props) {
	return (
		<div className={cn(" p-2", className)}>
			{loading ? (
				<div className="mx-auto my-4">
					{" "}
					<Typography.Text>Đang tải</Typography.Text> <LoadingOutlined />{" "}
				</div>
			) : (
				<>{children && children}</>
			)}
		</div>
	);
}
