import { Col, Row } from "antd";
import ShippingRouteCollection from "common/collections/ShippingRouteCollection";
import ShippingOrder from "common/constants/ShippingOrder";
import ShipperModel from "common/models/ShipperModel";
import ShippingHubModel from "common/models/ShippingHubModel";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingTypeModel from "common/models/ShippingTypeModel";
import ShipperRepository from "common/repositories/ShipperRepository";
import ShippingHubRepository from "common/repositories/ShippingHubRepository";
import ShippingOrderRepository from "common/repositories/ShippingOrderRepository";
import ShippingTypeRepository from "common/repositories/ShippingTypeRepository";
import DeliveryDashboardProvider from "contextproviders/DeliveryDashboardProvider";
import DeliveryDashboardContext from "contexts/DeliveryDashboardContext";
import useWindowDimensions from "hooks/useWindowDimensions";
import React, { useCallback, useEffect, useState } from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import DeliveryDashboardSectionCenter from "./section/center/DeliveryDashboardSectionCenter";
import DeliveryDashboardSectionLeft from "./section/left/DeliveryDashboardSectionLeft";
import DeliveryDashboardSectionRight from "./section/right/DeliveryDashboardSectionRight";

const DeliveryDashboardContainer = ({
  collection,
}: {
  collection?: ShippingRouteCollection;
}) => {
  const pageHeaderHeight = 60;
  const pageFilterHeight = 65;

  const activeRoute = useDeliveryDashboardStore((state) => state.activeRoute);

  //get screen height
  const { width, height } = useWindowDimensions();

  //ready to set to pos height
  const setWidthHeight = useDeliveryDashboardStore(
    (state) => state.setWidthHeight
  );

  //////////////////////////////////////////
  // Lazy loaded dynamic
  //shipper
  const [shipperItems, setShipperItems] = useState<ShipperModel[]>([]);
  const [loadingShipper, setLoadingShipper] = useState(true);
  const fetchShipper = useCallback(async () => {
    setLoadingShipper(true);
    const collection = await new ShipperRepository().getItems({
      filters: { ...ShipperRepository.getDefaultFilters(), limit: 100 },
    });
    if (!collection.hasError()) {
      setShipperItems(collection.items);
    }
    setLoadingShipper(false);
  }, []);
  //////////////////////////////////////////
  // Lazy loaded dynamic
  //shipping order
  const [shippingOrderItems, setShippingOrderItems] = useState<
    ShippingOrderModel[]
  >([]);
  const [loadingShippingOrder, setLoadingShippingOrder] = useState(true);
  const fetchShippingOrder = useCallback(async (routeIds: string) => {
    setLoadingShippingOrder(true);
    const collection = await new ShippingOrderRepository().getItems({
      filters: {
        ...ShippingOrderRepository.getDefaultFilters(),
        limit: 100,
        route_ids: routeIds,
        sorttype: "ASC",
        sortby: "display_order",
      },
    });
    if (!collection.hasError()) {
      setShippingOrderItems(collection.items);
    }
    setLoadingShippingOrder(false);
  }, []);
  //////////////////////////////////////////
  // Lazy loaded dynamic
  //shipping hub
  const [shippingHubItems, setShippingHubItems] = useState<ShippingHubModel[]>(
    []
  );
  const [loadingShippingHub, setLoadingShippingHub] = useState(true);
  const fetchingShippingHub = useCallback(async (ids: string) => {
    setLoadingShippingHub(true);
    const collection = await new ShippingHubRepository().getItems({
      filters: {
        ...ShippingHubRepository.getDefaultFilters(),
        limit: 100,
        ids: ids,
      },
    });
    if (!collection.hasError()) {
      setShippingHubItems(collection.items);
    }
    setLoadingShippingHub(false);
  }, []);

  //////////////////////////////////////////
  // Lazy loaded dynamic
  //shipping type
  const [shippingTypeItems, setShippingTypeItems] = useState<
    ShippingTypeModel[]
  >([]);
  const [loadingShippingType, setLoadingShippingType] = useState(true);
  const fetchingShippingType = useCallback(async (ids: string) => {
    setLoadingShippingType(true);
    const collection = await new ShippingTypeRepository().getItems({
      filters: {
        ...ShippingTypeRepository.getDefaultFilters(),
        limit: 100,
        ids: ids,
      },
    });
    if (!collection.hasError()) {
      setShippingTypeItems(collection.items);
    }
    setLoadingShippingType(false);
  }, []);

  useEffect(() => {
    if (width > 0 && height > 0) {
      setWidthHeight(width, height - pageHeaderHeight - pageFilterHeight);
    }
  }, [width, height, setWidthHeight]);

  useEffect(() => {
    document.body.classList.add("deliverydashboard");
    return function cleanup() {
      document.body.classList.remove("deliverydashboard");
    };
  });

  useEffect(() => {
    if (loadingShipper) {
      fetchShipper();
    }
  }, [loadingShipper, fetchShipper]);
  useEffect(() => {
    if (loadingShippingOrder) {
      let routeIdList = collection?.items
        .map((item) => item.id)
        .filter((i) => i > 0 && i !== undefined);
      if (routeIdList !== undefined && routeIdList.length > 0) {
        fetchShippingOrder(routeIdList.join(","));
      }
    }
  }, [loadingShippingOrder, fetchShippingOrder, collection?.items]);
  useEffect(() => {
    if (loadingShippingHub) {
      let shippingHubIdList = collection?.items
        .map((item) =>
          item.from_type === ShippingOrder.FROM_TYPE_OFFICE
            ? item.from_warehouse_id
            : 0
        )
        .filter((i) => i > 0 && i !== undefined);

      if (shippingHubIdList !== undefined && shippingHubIdList.length > 0) {
        fetchingShippingHub(shippingHubIdList.join(","));
      }
    }
  }, [loadingShippingHub, collection?.items, fetchingShippingHub]);

  useEffect(() => {
    if (loadingShippingType) {
      let shippingTypeIdList = shippingOrderItems
        .map((item) => item.shipping_type)
        .filter((i) => i > 0 && i !== undefined);

      if (shippingTypeIdList !== undefined && shippingTypeIdList.length > 0) {
        fetchingShippingType(shippingTypeIdList.join(","));
      }
    }
  }, [loadingShippingType, fetchingShippingType, shippingOrderItems]);

  return (
    <div style={{ height: height - pageHeaderHeight - pageFilterHeight }}>
      <DeliveryDashboardContext.Provider
        value={
          new DeliveryDashboardProvider({
            orderItems: shippingOrderItems,
            setOrderItems: setShippingOrderItems,
            hubItems: shippingHubItems,
            setHubItems: setShippingHubItems,
            shipperItems: shipperItems,
            setShipperItems: setShipperItems,
            shippingTypeItems: shippingTypeItems,
            setShippingTypeItems: setShippingTypeItems,
          })
        }
      >
        <Row gutter={0} className="h-full">
          <Col xs={5}>
            <DeliveryDashboardSectionLeft shippingRouteItem={collection} />
          </Col>
          <Col xs={7}>
            {activeRoute !== undefined && activeRoute.id > 0 ? (
              <DeliveryDashboardSectionCenter />
            ) : null}
          </Col>
          <Col xs={12}>
            <DeliveryDashboardSectionRight />
          </Col>
        </Row>
      </DeliveryDashboardContext.Provider>
    </div>
  );
};

export default DeliveryDashboardContainer;
