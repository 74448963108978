import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";
import { ITRequestJson } from "common/types/ITRequest";
import { SelectOption, SelectOptionColor } from "common/types/SelectOption";
import i18n from "i18n";

type SelectStringOption = {
  value: string;
  label: string;
  parent_id?: number;
  color?: SelectOptionColor;
  disabled?: boolean;
};

class ITRequestModel extends BaseModel implements BaseModelJson<ITRequestJson> {
  company_id: number;
  office_id: number;
  department_id: number;
  employee_id: number;
  id: number;
  parent_id: number;
  code: string;
  org_id: number;
  scope_ids: number[];
  has_child: number;
  name: string;
  type: number;
  priority: number;
  flag: number;
  note: string;
  org_cost: number;
  org_fromtime: number;
  org_totime: number;
  rel_cost: number;
  rel_fromtime: number;
  rel_totime: number;
  time_pending: number;
  deviation: number;
  org_complete: number;
  complete: number;
  deadline: number;
  approve_by: string;
  can_do: number;
  time_complete: number;
  status: number;
  display_order: number;
  islate: number;
  isdeletete: number;
  datecreated: number;
  datestart: number;
  date_pending: number;
  datemodified: number;
  datedeleted: number;
  datecompleted: number;
  ability: number;
  public: number;

  constructor(json: ITRequestJson) {
    super();

    this.company_id = json.company_id || 0;
    this.office_id = json.office_id || 0;
    this.department_id = json.department_id || 0;
    this.employee_id = json.employee_id || 0;
    this.id = json.id || 0;
    this.parent_id = json.parent_id || 0;
    this.name = json.name || "";
    this.type = json.type || 0;
    this.flag = json.flag || 0;
    this.note = json.note || "";
    this.org_cost = json.org_cost || 0;
    this.org_fromtime = json.org_fromtime || 0;
    this.org_totime = json.org_totime || 0;
    this.org_complete = json.org_complete || 0;
    this.has_child = json.has_child || 0;
    this.deviation = json.deviation || 0;
    this.complete = json.complete || 0;
    this.status = json.status || 0;
    this.islate = json.islate || 0;
    this.isdeletete = json.isdeletete || 0;
    this.datecreated = json.datecreated || 0;
    this.datestart = json.datestart || 0;
    this.datemodified = json.datemodified || 0;
    this.datedeleted = json.datedeleted || 0;
    this.datecompleted = json.datecompleted || 0;
    this.rel_cost = json.rel_cost || 0;
    this.rel_fromtime = json.rel_fromtime || 0;
    this.rel_totime = json.rel_totime || 0;
    this.priority = json.priority || 0;
    this.display_order = json.display_order || 0;
    this.time_pending = json.time_pending || 0;
    this.scope_ids = json.scope_ids || [];
    this.org_id = json.org_id || 0;
    this.code = json.code || "";
    this.approve_by = json.approve_by || "";
    this.can_do = json.can_do || 0;
    this.date_pending = json.date_pending || 0;
    this.time_complete = json.time_complete || 0;
    this.deadline = json.deadline || 0;
    this.ability = json.ability || 0;
    this.public = json.public || 0;
  }

  toJson(): ITRequestJson {
    return {
      company_id: this.company_id,
      office_id: this.office_id,
      department_id: this.department_id,
      employee_id: this.employee_id,
      id: this.id,
      parent_id: this.parent_id,
      name: this.name,
      type: this.type,
      flag: this.flag,
      note: this.note,
      has_child: this.has_child,
      org_cost: this.org_cost,
      org_fromtime: this.org_fromtime,
      org_totime: this.org_totime,
      org_complete: this.org_complete,
      deviation: this.deviation,
      complete: this.complete,
      status: this.status,
      islate: this.islate,
      isdeletete: this.isdeletete,
      datecreated: this.datecreated,
      datestart: this.datestart,
      datemodified: this.datemodified,
      datedeleted: this.datedeleted,
      datecompleted: this.datecompleted,
      rel_cost: this.rel_cost,
      rel_fromtime: this.rel_fromtime,
      rel_totime: this.rel_totime,
      priority: this.priority,
      display_order: this.display_order,
      time_pending: this.time_pending,
      scope_ids: this.scope_ids,
      org_id: this.org_id,
      approve_by: this.approve_by,
      can_do: this.can_do,
      code: this.code,
      date_pending: this.date_pending,
      deadline: this.deadline,
      time_complete: this.time_complete,
      ability: this.ability,
      public: this.public,
    };
  }

  static getDefaultData(): ITRequestJson {
    return {
      company_id: 0,
      office_id: 0,
      department_id: 0,
      employee_id: 0,
      id: 0,
      parent_id: 0,
      name: "",
      type: 0,
      flag: 0,
      note: "",
      has_child: 0,
      org_cost: 0,
      org_fromtime: 0,
      org_complete: 0,
      org_totime: 0,
      deviation: 0,
      complete: 0,
      status: 0,
      islate: 0,
      isdeletete: 0,
      datecreated: 0,
      datestart: 0,
      datemodified: 0,
      datedeleted: 0,
      datecompleted: 0,
      rel_cost: 0,
      rel_fromtime: 0,
      rel_totime: 0,
      display_order: 0,
      time_pending: 0,
      scope_ids: [],
      org_id: 0,
      approve_by: "",
      can_do: 0,
      code: "",
      date_pending: 0,
      deadline: 0,
      priority: 0,
      time_complete: 0,
      ability: 1,
      public: 1,
    };
  }

  // static getDefaultCurrentEmpData(): TaskEmployeeCollectionJson {
  //   return {
  //     total: 0,
  //     items: [],
  //     error: [],
  //   };
  // }

  static getIsLate(islate: number): any {
    switch (islate) {
      case 0:
        return i18n.t("itrequest:filter.late_yet");
      case 1:
        return i18n.t("itrequest:filter.late");

      default:
        return islate;
    }
  }
  static getIsLateOption(): SelectOption[] {
    return [
      {
        label: i18n.t("itrequest:filter.late"),
        value: 0,
      },
      {
        label: i18n.t("itrequest:filter.late_yet"),
        value: 1,
      },
    ];
  }
  static getStatus(status: number): any {
    switch (status) {
      case 0:
        return i18n.t("itrequest:filter.todo");
      case 1:
        return i18n.t("itrequest:filter.pending");
      case 2:
        return i18n.t("itrequest:filter.in_progress");
      case 3:
        return i18n.t("itrequest:filter.done");
      case 4:
        return i18n.t("itrequest:filter.block");
      default:
        return status;
    }
  }
  static getStatusOption(): SelectOption[] {
    return [
      {
        label: i18n.t("itrequest:filter.todo"),
        value: 0,
      },
      {
        label: i18n.t("itrequest:filter.pending"),
        value: 1,
      },
      {
        label: i18n.t("itrequest:filter.in_progress"),
        value: 2,
      },
      {
        label: i18n.t("itrequest:filter.done"),
        value: 3,
      },
      {
        label: i18n.t("itrequest:filter.block"),
        value: 4,
      },
    ];
  }
  static getTypeOption(): SelectOption[] {
    return [
      {
        label: i18n.t("task:typetask.1"),
        value: 1,
      },
      {
        label: i18n.t("task:typetask.2"),
        value: 2,
      },
      {
        label: i18n.t("task:typetask.3"),
        value: 3,
      },
      {
        label: i18n.t("task:typetask.4"),
        value: 4,
      },
    ];
  }
  static getTypeTask(type: number): any {
    switch (type) {
      case 1:
        return i18n.t("task:typetask.1");
      case 2:
        return i18n.t("task:typetask.2");
      case 3:
        return i18n.t("task:typetask.3");
      case 4:
        return i18n.t("task:typetask.4");
      default:
        return type;
    }
  }

  static getPriorityOption(): SelectOption[] {
    return [
      { label: "Trung bình", value: 1 },
      { label: "Cao", value: 2 },
      { label: "Cao nhất", value: 3 },
    ];
  }

  static getApproveByOption(): SelectStringOption[] {
    return [
      { label: "Giám đốc", value: "APPROVE_BY_DIRECTOR" },
      { label: "Chủ tịch", value: "APPROVE_BY_CHAIRPERSON" },
      { label: "Quản lý", value: "APPROVE_BY_MANAGER" },
    ];
  }
  static getAbilityOption(): SelectOption[] {
    return [
      { label: "Có", value: 1 },
      { label: "Không", value: 0 },
    ];
  }

  static getPublicOption(): SelectOption[] {
    return [
      { label: "Có", value: 1 },
      { label: "Không", value: 0 },
    ];
  }

  static getPriority(value: number) {
    switch (value) {
      case 1:
        return i18n.t("Trung bình");
      case 2:
        return i18n.t("Cao");
      case 3:
        return i18n.t("Cao nhất");
      default:
        return value;
    }
  }

  static getApproveBy(value: string) {
    switch (value) {
      case "APPROVE_BY_DIRECTOR":
        return i18n.t("Giám đốc");
      case "APPROVE_BY_CHAIRPERSON":
        return i18n.t("Chủ tịch");
      case "APPROVE_BY_MANAGER":
        return i18n.t("Quản lý");
      default:
        return value;
    }
  }
}

export default ITRequestModel;
