import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import FormEditFetcher from "components/form/FormEditFetcher";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";
import InventoryReceiptFormHeader from "features/inventoryreceipt/form/InventoryReceiptFormHeader";
import InventoryReceiptList from "features/inventoryreceipt/list/InventoryReceiptList";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import ProductReceipt from "common/constants/ProductReceipt";
import { InventoryReceiptDirection } from "common/types/InventoryResceipt";
import InventoryReceiptForm from "features/inventoryreceipt/form/InventoryReceiptForm";

const InventoryReceiptPage = () => {
	const [pathParams] = usePathParams();
	const [model, setModel] = useState<InventoryReceiptModel>(
		new InventoryReceiptModel(InventoryReceiptModel.getDefaultData())
	);
	const [fetchErrors, setFetchErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setModel(new InventoryReceiptModel(InventoryReceiptModel.getDefaultData()));
		setFetchErrors([]);
	}, [pathParams.action]);

	useEffect(() => {
		setModel(
			new InventoryReceiptModel({
				...InventoryReceiptModel.getDefaultData(),
				...model,
				type: +pathParams?.type,
				direction: +pathParams.direction,
			})
		);
		setFetchErrors([]);
	}, [pathParams.direction, pathParams.type]);

	async function fetchData() {
		let fetchedModel = await new InventoryReceiptRepository().getItem(
			parseInt(pathParams.id.toString())
		);
		if (fetchedModel.hasError()) {
			setFetchErrors(fetchedModel.error.errors);
		} else {
			setModel(fetchedModel);
		}
	}

	let com = null;

	switch (pathParams.action) {
		case "add":
			if (pathParams.direction !== undefined) {
				com = (
					<InventoryReceiptForm
						// refetch={fetchData}
						setInven={setModel}
						model={
							new InventoryReceiptModel({
								...model,
								type: +pathParams?.type
									? +pathParams?.type
									: +pathParams.direction === ProductReceipt.DIRECTION_INPUT
									? ProductReceipt.TYPE_BUYING
									: ProductReceipt.TYPE_SELLING,
								direction: +pathParams.direction,
							})
						}
						need_link_sale_order_id={+pathParams.sale_order_id || undefined}
						need_link_purchase_order_id={
							+pathParams?.purchase_order_id || undefined
						}
					/>
				);
			}

			break;
		case "edit":
			com =
				model.id > 0 ? (
					<InventoryReceiptForm
						model={model}
						setInven={setModel}
						refetch={fetchData}
					/>
				) : (
					<FormEditFetcher
						doLoad={async () => {
							await fetchData();
						}}
						errors={fetchErrors}
						heading={
							<></>
							// <InventoryReceiptFormHeader
							//   id={pathParams.id.toString()}
							//   direction={
							//     pathParams.direction !== undefined
							//       ? +pathParams.direction
							//       : model.direction
							//   }
							//   isEditing={true}
							// />
						}
					/>
				);

			break;

		// case "export_sale":
		// 	if (pathParams.direction !== undefined) {
		// 		com = (
		// 			<InventoryReceiptForm
		// 				refetch={fetchData}
		// 				setInven={setModel}
		// 				model={
		// 					new InventoryReceiptModel({
		// 						...model,
		// 						direction: +pathParams.direction,
		// 					})
		// 				}
		// 				need_link_sale_order_id={+pathParams.sale_order_id || undefined}
		// 				need_link_purchase_order_id={
		// 					+pathParams?.purchase_order_id || undefined
		// 				}
		// 			/>
		// 		);
		// 	}

		// 	break;
		// case "export_transfer":
		// 	if (pathParams.direction !== undefined) {
		// 		com = (
		// 			<InventoryReceiptForm
		// 				refetch={fetchData}
		// 				setInven={setModel}
		// 				model={
		// 					new InventoryReceiptModel({
		// 						...model,
		// 						direction: +pathParams.direction,
		// 					})
		// 				}
		// 				need_link_sale_order_id={+pathParams.sale_order_id || undefined}
		// 				need_link_purchase_order_id={
		// 					+pathParams?.purchase_order_id || undefined
		// 				}
		// 			/>
		// 		);
		// 	}

		// 	break;
		// case "export_cancel":
		// 	if (pathParams.direction !== undefined) {
		// 		com = (
		// 			<InventoryReceiptForm
		// 				refetch={fetchData}
		// 				setInven={setModel}
		// 				model={
		// 					new InventoryReceiptModel({
		// 						...model,
		// 						direction: +pathParams.direction,
		// 					})
		// 				}
		// 				need_link_sale_order_id={+pathParams.sale_order_id || undefined}
		// 				need_link_purchase_order_id={
		// 					+pathParams?.purchase_order_id || undefined
		// 				}
		// 			/>
		// 		);
		// 	}

		// 	break;

		case "out":
			com = <InventoryReceiptList direction={InventoryReceiptDirection.OUT} />;
			break;
		case "in":
			com = <InventoryReceiptList direction={InventoryReceiptDirection.IN} />;
			break;
		default:
		// com = <InventoryReceiptList direction={InventoryReceiptDirection.IN} />;
	}

	return com;
};

export default InventoryReceiptPage;
