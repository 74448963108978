import BaseCollection from "common/collections/BaseCollection";
import DeliveryHistoryModel from "common/models/DeliveryHistoryModel";
import {
  DeliveryHistoryCollectionJson,
  DeliveryHistoryJson,
} from "common/types/Delivery";

class DeliveryHistoryCollection extends BaseCollection<
  DeliveryHistoryJson,
  DeliveryHistoryModel,
  DeliveryHistoryCollectionJson
> {
  itemsFromJson(jsonItems: DeliveryHistoryJson[]): DeliveryHistoryModel[] {
    return jsonItems.map((item) => new DeliveryHistoryModel(item));
  }
}

export default DeliveryHistoryCollection;
