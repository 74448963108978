import {
  App,
  Col,
  Form,
  Input,
  Result,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography
} from "antd";
import WorkTracking from "common/constants/WorkTracking";
import WorkTrackingModel from "common/models/WorkTrackingModel";
import WorkTrackingRangeModel from "common/models/WorkTrackingRangeModel";
import WorkTrackingReviewRepository from "common/repositories/WorkTrackingReviewRepository";
import {
  WorkTrackingRangeJson,
  WorkTrackingRangeJsonFetcherResult
} from "common/types/WorkTrackingRange";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import dayjs from "dayjs";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { WorkTrackingJsonAddEdit } from "common/types/WorkTracking";
const WorkTrackingApprovalForm = ({
  model,
  ranges,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: WorkTrackingModel;
  ranges?: WorkTrackingRangeJson[];
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: WorkTrackingModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();
  const account = useLoginAccountStore((state) => state.account);
  const [workTrackingRange, setWorkTrackingRange] =
    useState<WorkTrackingRangeJsonFetcherResult>(
      WorkTrackingRangeModel.getDefaultFetcherResultData()
    );
  const [oTAllow, setOTAllow] = useState<number>(model.ot_allow);

  /**
   * initStartEndTime
   */
  const initStartEndTime = useCallback(() => {
    let startHour =
      model.check_in_time_final > 0
        ? dayjs.unix(model.check_in_time_final).format("HH")
        : dayjs(dayjs()).format("HH");
    let startMinute =
      model.check_in_time_final > 0
        ? dayjs.unix(model.check_in_time_final).format("mm")
        : dayjs(dayjs()).format("mm");

    let endHour = dayjs(dayjs()).format("HH");
    if (model.check_out_time_final > 0) {
      if (
        dayjs.unix(model.check_out_time_final).format("DD") !==
        dayjs.unix(model.check_in_time_final).format("DD")
      ) {
        endHour = dayjs
          .unix(model.check_out_time_final)
          .add(24, "hours")
          .format("HH");
        endHour = ("0" + endHour).slice(-2);
      } else {
        endHour = dayjs.unix(model.check_out_time_final).format("HH");
      }
    }

    let endMinute =
      model.check_out_time_final > 0
        ? dayjs.unix(model.check_out_time_final).format("mm")
        : dayjs(dayjs()).format("mm");

    return {
      startHour: startHour,
      startMinute: startMinute,
      endHour: endHour,
      endMinute: endMinute
    };
  }, [model.check_in_time_final, model.check_out_time_final]);

  const initialValues = useMemo(() => {
    let getStartEndTime = initStartEndTime();
    return {
      status: model.status,
      allow_late:
        model.allow_late === WorkTracking.ALLOW_LATE_ALLOW ? true : false,
      allow_early:
        model.allow_early === WorkTracking.ALLOW_EARLY_ALLOW ? true : false,
      ot_allow: model.ot_allow ?? null,
      edit_note: "",
      worktracking_id: model.worktracking_range_id,
      worktracking_range_id: model.worktracking_range_id,
      verify_status: model.verify_status,
      start_time_in: dayjs(
        getStartEndTime.startHour + ":" + getStartEndTime.startMinute,
        "HH:mm"
      ),
      end_time_out: dayjs(
        getStartEndTime.endHour + ":" + getStartEndTime.endMinute,
        "HH:mm"
      )
    };
  }, [model, initStartEndTime]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let allowLate = WorkTracking.ALLOW_LATE_NOT_SET;
      let allowEarly = WorkTracking.ALLOW_EARLY_NOT_SET;

      if (model.late_in_rule > 0) {
        allowLate = formData.allow_late
          ? WorkTracking.ALLOW_LATE_ALLOW
          : WorkTracking.ALLOW_LATE_NOT_ALLOW;
      }

      if (model.early_in_rule > 0) {
        allowEarly = formData.allow_early
          ? WorkTracking.ALLOW_EARLY_ALLOW
          : WorkTracking.ALLOW_EARLY_NOT_ALLOW;
      }

      let endTime = dayjs.unix(model.check_in_time).format("YYYY-MM-DD");
      let startTimeIn = dayjs(formData.start_time_in).format("HH:mm");
      let endTimeOut = dayjs(formData.end_time_out).format("HH:mm");

      let resultStartTimeIn = dayjs(
        endTime + " " + startTimeIn,
        "YYYY-MM-DD HH:mm"
      ).unix();
      let resultEndTimeOut = dayjs(
        endTime + " " + endTimeOut,
        "YYYY-MM-DD HH:mm"
      ).unix();

      const submitData: WorkTrackingJsonAddEdit = {
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,
        office_id: model.office_id,
        check_in_time_final: resultStartTimeIn,
        check_out_time_final: resultEndTimeOut,
        status: 0,
        allow_late: allowLate,
        allow_early: allowEarly,
        ot_allow: oTAllow,
        edit_note: formData.edit_note,
        worktracking_range_id: model.worktracking_range_id,
        verify_status: model.verify_status
      };

      return submitData;
    },
    [
      model.id,
      model.check_in_time,
      model.early_in_rule,
      model.late_in_rule,
      model.office_id,
      model.verify_status,
      model.worktracking_range_id,
      account.company.id,
      account.id,
      oTAllow
    ]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });
    let item: WorkTrackingModel = await new WorkTrackingReviewRepository().edit(
      doPrepareData(formData)
    );
    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="worktracking:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("worktracking:form.success.update"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  const setTimeOT = (ot: number) => {
    setOTAllow(ot);

    let startTimeIn = form.getFieldValue("start_time_in");
    let endTimeOut = form.getFieldValue("end_time_out");
    let getStartEndTime = initStartEndTime();

    let startHour =
      startTimeIn.get("hour") < 10
        ? "0" + startTimeIn.get("hour")
        : startTimeIn.get("hour");
    let startMinute =
      startTimeIn.get("minute") < 10
        ? "0" + startTimeIn.get("minute")
        : startTimeIn.get("minute");
    let endHour =
      endTimeOut.get("hour") < 10
        ? "0" + endTimeOut.get("hour")
        : endTimeOut.get("hour");
    let endMinute =
      endTimeOut.get("minute") < 10
        ? "0" + endTimeOut.get("minute")
        : endTimeOut.get("minute");

    switch (ot) {
      case WorkTracking.ALLOW_OT_FIRST:
        startHour = dayjs.unix(model.check_in_time).format("H");
        startMinute = dayjs.unix(model.check_in_time).format("mm");
        endHour = getStartEndTime.endHour;
        endMinute = getStartEndTime.endMinute;
        break;
      case WorkTracking.ALLOW_OT_LAST:
        startHour = getStartEndTime.startHour;
        startMinute = getStartEndTime.startMinute;
        endHour = dayjs.unix(model.check_out_time).format("H");
        endMinute = dayjs.unix(model.check_out_time).format("mm");
        break;
      case WorkTracking.ALLOW_OT_ALL:
        startHour = dayjs.unix(model.check_in_time).format("H");
        startMinute = dayjs.unix(model.check_in_time).format("mm");
        endHour = dayjs.unix(model.check_out_time).format("H");
        endMinute = dayjs.unix(model.check_out_time).format("mm");
        break;
      default:
        // set in initialValues
        startHour = getStartEndTime.startHour;
        startMinute = getStartEndTime.startMinute;
        endHour = getStartEndTime.endHour;
        endMinute = getStartEndTime.endMinute;
        break;
    }

    // set field value
    form.setFieldsValue({
      start_time_in: dayjs(startHour + ":" + startMinute, "HH:mm"),
      end_time_out: dayjs(endHour + ":" + endMinute, "HH:mm")
    });
  };

  const handleChangeGetFullTime = (checked: boolean) => {
    let getStartEndTime = initStartEndTime();
    let startHour = getStartEndTime.startHour;
    let startMinute = getStartEndTime.startMinute;
    let endHour = getStartEndTime.endHour;
    let endMinute = getStartEndTime.endMinute;

    if (checked && workTrackingRange) {
      let arrStart = workTrackingRange.time_start.split(":");
      let arrEnd = workTrackingRange.time_end.split(":");
      if (arrStart.length === 2) {
        startHour = arrStart[0];
        startMinute = arrStart[1];
      }
      if (arrEnd.length === 2) {
        endHour = arrEnd[0];
        endMinute = arrEnd[1];
      }
    }

    // set field value
    form.setFieldsValue({
      start_time_in: dayjs(startHour + ":" + startMinute, "HH:mm"),
      end_time_out: dayjs(endHour + ":" + endMinute, "HH:mm")
    });
  };

  useEffect(() => {
    let foundWorkTrackingRange = ranges?.find(
      (i) => i.id === model.worktracking_range_id
    );

    foundWorkTrackingRange && setWorkTrackingRange(foundWorkTrackingRange);
  }, [ranges, model.worktracking_range_id]);

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      {model.id > 0 && model.check_out_time_final <= 0 ? (
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Result
              className="pt-0 pb-5"
              status="warning"
              title={
                <>
                  <Typography.Title level={1}>
                    {t("worktracking:not_have_checkout")}
                  </Typography.Title>
                  <Typography.Text>
                    {t("worktracking:if_veryfied_is_system_auto_checkout")}
                  </Typography.Text>
                </>
              }
            />
          </Col>
        </Row>
      ) : null}

      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item label={t("worktracking:timein")} name="start_time_in">
            <TimePicker style={{ width: "100%" }} format={"HH:mm"} />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item label={t("worktracking:timeout")} name="end_time_out">
            <TimePicker style={{ width: "100%" }} format={"HH:mm"} />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item label={t("worktracking:ot_allow_long")} name="ot_allow">
            <Select
              options={WorkTrackingModel.getOTList()}
              onChange={setTimeOT}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("worktracking:get_full_time")}
            name="get_full_time"
            valuePropName="checked">
            <Switch onChange={handleChangeGetFullTime} />
          </Form.Item>
        </Col>
        {model.late_in_rule > WorkTracking.LATE_NOT ? (
          <Col md={8} xs={24}>
            <Form.Item
              label={t("worktracking:allowlate")}
              name="allow_late"
              valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        ) : null}
        {model.early_in_rule > WorkTracking.EARLY_NOT ? (
          <Col md={8} xs={24}>
            <Form.Item
              label={t("worktracking:allowearly")}
              name="allow_early"
              valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        ) : null}
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("worktracking:edit_note")}
            name="edit_note"
            required
            rules={[
              {
                required: true,
                message: t("worktracking:form.error.error_edit_note_required")
              }
            ]}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default WorkTrackingApprovalForm;
