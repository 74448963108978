import EcomApi from 'common/api/EcomApi';
import EcomCollection from 'common/collections/EcomCollection';
import EcomModel from 'common/models/EcomModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type { EcomJsonAddEdit, FilterEcom } from "common/types/Ecom";

class EcomRepository extends BaseRepository<EcomApi> {
  _api: EcomApi | null;

  constructor() {
    super();
    this._api = new EcomApi(true);
  }

  static getDefaultFilters(): FilterEcom {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterEcom>) {
    return this._api ? await this._api.getList(props) : new EcomCollection();
  }
  async getItemsChannel() {
    return this._api ? await this._api.getListEcom(): []
  }

  async getItem(id: number): Promise<EcomModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new EcomModel(EcomModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: EcomJsonAddEdit): Promise<EcomModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new EcomModel(EcomModel.getDefaultData());
    }
  }
}

export default EcomRepository;
