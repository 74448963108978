import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ContactMailJson } from "common/types/ContactMail";
import { SelectOption } from "common/types/SelectOption";
import BaseModel from "./BaseModel";

const defaultData: ContactMailJson = {
  id: 0,
  contact: "",
  item_id: "",
  type: 0,
  status: 0,
  is_deleted: 0,
  date_deleted: 0,
  date_created: 0,
  date_modified: 0,
};

class ContactMailModel
  extends BaseModel
  implements BaseModelJson<ContactMailJson>
{
  id: number = defaultData.id;
  contact: string = defaultData.contact;
  item_id: string = defaultData.item_id;
  type: number = defaultData.type;
  status: number = defaultData.status;
  is_deleted: number = defaultData.is_deleted;
  date_deleted: number = defaultData.date_deleted;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;

  constructor(json: ContactMailJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): ContactMailJson {
    return { ...defaultData };
  }
  toJson(): ContactMailJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getPinSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Ghim",
      },
      {
        value: 0,
        label: "Không ghim",
      },
    ];
  }

  static getStatusSelectList(): SelectOption[] {
    return [
      {
        value: 2,
        label: "Nháp",
      },
      {
        value: 1,
        label: "Hiện",
      },
      {
        value: 0,
        label: "Ẩn",
      },
    ];
  }
}

export default ContactMailModel;
