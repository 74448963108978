import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React from "react";

const ReportCashflowReceiptExpensePartnerCustomer = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col flex={1}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo phiếu chi theo khách hàng"}
              subtitle="Chỉ tính dựa trên phiếu chi chưa thanh toán và đã thanh toán"
              fetcherObject="customer"
              fetcherKeyMapping="targetid"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Khách hàng",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "targetid"
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 10,status < 2,target = 3"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền cần chi theo khách hàng"}
              subtitle="Chỉ tính các phiếu chi có trạng thái chưa thanh toán"
              fetcherObject="customer"
              fetcherKeyMapping="targetid"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Khách hàng",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "targetid"
                },
                {
                  title: "Tiền cần chi",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 10,status = 0,target = 3"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền đã chi theo khách hàng"}
              subtitle="Chỉ tính các phiếu chi có trạng thái đã thanh toán"
              fetcherObject="customer"
              fetcherKeyMapping="targetid"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Khách hàng",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  }
                },
                {
                  title: "Tiền đã chi",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 10,status = 1,target = 3"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Giá trị phiếu chi hủy theo khách hàng"}
              subtitle="Chỉ tính các phiếu chi có trạng thái hủy"
              fetcherObject="customer"
              fetcherKeyMapping="targetid"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Khách hàng",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  }
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 10,status = 2,target = 3"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCashflowReceiptExpensePartnerCustomer;
