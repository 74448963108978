import ProductCollection from "common/collections/ProductCollection";
import ProductModel from "common/models/ProductModel";
import { ProductCategoryJson } from "common/types/ProductCategory";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterProduct,
  ProductJsonAddEdit,
  ProductJsonAddEdit2,
  ProductRelatedFilter,
  ProductStructJson,
} from "common/types/Product";
import AxiosClient1 from "./AxiosClient1";
import ProductPublicCollection from "common/collections/ProductPublicCollection";
const SERVICE_URL = "/products";

class ProductApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProduct>,
    categoryItems: ProductCategoryJson[]
  ): Promise<ProductCollection> {
    let collection = new ProductCollection();

    try {
      let filters = props.filters;

      //for category filter, we need to find all children id
      let filteredCategoryIdList: number[] = [];
      if (filters.category_id > 0) {
        filteredCategoryIdList = [
          ...categoryItems
            .filter((item) => item.parent_id === filters.category_id)
            .map((item) => item.id),
          filters.category_id,
        ];
      }

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword.toString().trim() : null,
          status: +filters.status >= 0 ? filters.status : null,
          supplier_id: filters.supplier_id > 0 ? filters.supplier_id : null,
          id: filters.id > 0 ? filters.id : null,
          category_ids:
            filteredCategoryIdList.length > 0
              ? filteredCategoryIdList.join(",")
              : null,
          store_id: filters.store_id > 0 ? filters.store_id : null,
          list_product_id:
            filters.list_product_id.length > 0 ? filters.list_product_id : null,
          collection_id:
            filters.collection_id > 0 ? filters.collection_id : null,
          tags: filters.tags.length > 0 ? filters.tags : null,
          parent_id: filters?.parent_id

        },
      };

      const response = await AxiosClient1().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getListPublic(
    props: GetListProps<FilterProduct>,
    categoryItems: ProductCategoryJson[] = []
  ): Promise<ProductPublicCollection> {
    let collection = new ProductPublicCollection();

    try {
      let filters = props.filters;

      //for category filter, we need to find all children id
      let filteredCategoryIdList: number[] = [];
      if (filters.category_id > 0) {
        filteredCategoryIdList = [
          ...categoryItems
            .filter((item) => item.parent_id === filters.category_id)
            .map((item) => item.id),
          filters.category_id,
        ];
      }

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          supplier_id: filters.supplier_id > 0 ? filters.supplier_id : null,
          id: filters.id > 0 ? filters.id : null,
          category_ids:
            filteredCategoryIdList.length > 0
              ? filteredCategoryIdList.join(",")
              : null,
          store_id: filters.store_id > 0 ? filters.store_id : null,
          list_product_id:
            filters.list_product_id.length > 0 ? filters.list_product_id : null,
          collection_id:
            filters.collection_id > 0 ? filters.collection_id : null,
          tags: filters.tags.length > 0 ? filters.tags : null,
          parent_id : typeof  filters.parent_id !== "undefined" && filters.parent_id >= 0 ?  filters.parent_id: null,
          tags_code : filters.tags_code,
          skus : filters.skus
        },
      };

      const response = await AxiosClient1().get<any>(
        SERVICE_URL + "/public",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number, store_id?: number): Promise<ProductModel> {
    let item = new ProductModel(ProductModel.getDefaultData());

    let queryData = {
      params: {
        store_id: store_id ? store_id : null,
      },
    };

    try {
      const response = await AxiosClient1().get<any>(
        SERVICE_URL + "/" + id,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        item = new ProductModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient1().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: ProductJsonAddEdit2): Promise<ProductModel> {
    let item = new ProductModel(ProductModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient1().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProductModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: Partial<ProductJsonAddEdit2>): Promise<ProductModel> {
    let item = new ProductModel(ProductModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient1().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ProductModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async resyncCompanyResource(): Promise<string[]> {
    let resultResponse = [];

    try {
      const response = await AxiosClient1().post(
        SERVICE_URL + "/resynccompanyresource"
      );
      if (response.status !== 200) {
        resultResponse.push("error_response_not_200");
      }
    } catch (error) {
      resultResponse = BaseApi.handleError(error).errors;
    }

    return resultResponse;
  }

  async getVariants(props: ProductRelatedFilter): Promise<ProductCollection> {
    let collection = new ProductCollection();

    try {
      let filters = props;

      let queryData = {
        params: {
          store_id: filters.store_id > 0 ? filters.store_id : null,
          id: filters.id > 0 ? filters.id : null,

          tags: filters.tags.length > 0 ? filters.tags : null,
        },
      };

      const response = await AxiosClient1().get<any>(
        SERVICE_URL + "/public/related",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getGroupStruct(id: number): Promise<ProductStructJson[]> {
    let items = [];

    try {
      let queryData = {
        params: { product_id: id },
      };
      const response = await AxiosClient1().get<any>(
        SERVICE_URL + "/public/group",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        items = response.data;
      }
    } catch (error) {
      // collection.withError(BaseApi.handleError(error));
    }

    return items;
  }
}

export default ProductApi;
