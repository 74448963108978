import Ecom from "common/constants/Ecom";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { EcomJson } from "common/types/Ecom";

class EcomModel extends BaseModel implements BaseModelJson<EcomJson> {
  creator_id: number;
  company_id: number;
  id: number;
  title: string;
  url: string;
  type: number;
  api_key: string;
  api_secret: string;
  access_token: string;
  refresh_token: string;
  store: number;
  settings: any;
  status: number;
  date_created: number;
  date_modified: number;
  date_last_synced: number;
  date_last_order_synced: number;
  date_last_hooked: number;
  date_webhook_registered: number;

  constructor(json: EcomJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.title = json.title || "";
    this.url = json.url || "";
    this.type = json.type || 0;
    this.api_key = json.api_key || "";
    this.api_secret = json.api_secret || "";
    this.access_token = json.access_token || "";
    this.refresh_token = json.refresh_token || "";
    this.store = json.store || 0;
    this.settings = json.settings || [];
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_last_synced = json.date_last_synced || 0;
    this.date_last_order_synced = json.date_last_order_synced || 0;
    this.date_last_hooked = json.date_last_hooked || 0;
    this.date_webhook_registered = json.date_webhook_registered || 0;
  }

  static getDefaultData(): EcomJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      title: " ",
      url: "",
      type: 0,
      api_key: "",
      api_secret: "",
      access_token: "",
      refresh_token: "",
      store: 0,
      settings: [],
      status: 0,
      date_created: 0,
      date_modified: 0,
      date_last_synced: 0,
      date_last_order_synced: 0,
      date_last_hooked: 0,
      date_webhook_registered: 0,
    };
  }

  toJson(): EcomJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      title: this.title,
      url: this.url,
      type: this.type,
      api_key: this.api_key,
      api_secret: this.api_secret,
      access_token: this.access_token,
      refresh_token: this.refresh_token,
      store: this.store,
      settings: this.settings,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_last_synced: this.date_last_synced,
      date_last_order_synced: this.date_last_order_synced,
      date_last_hooked: this.date_last_hooked,
      date_webhook_registered: this.date_webhook_registered,
    };
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: Ecom.TYPE_HARAVAN,
        label: i18n.t("ecom:TYPE_HARAVAN"),
      },
      {
        value: Ecom.TYPE_KIOTVIET,
        label: i18n.t("ecom:TYPE_KIOTVIET"),
      },
      // {
      //   value: Ecom.TYPE_SHOPIFY,
      //   label: i18n.t("ecom:TYPE_SHOPIFY"),
      // },
      // {
      //   value: Ecom.TYPE_WOO_COMMERCE,
      //   label: i18n.t("ecom:TYPE_WOO_COMMERCE"),
      // },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: Ecom.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: Ecom.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getInventorySourceList() {
    return [
      {
        value: "onhand",
        label: i18n.t("ecom:form.inventory_source_onhand"),
      },
      {
        value: "available",
        label: i18n.t("ecom:form.inventory_source_available"),
      },
    ];
  }

  static getConfig(type: number) {
    var obj = {
      prefix: "https://",
      suffix: "",
    };

    switch (type) {
      case Ecom.TYPE_HARAVAN:
        obj.suffix = ".myharavan.com";
        break;
    }
    return obj;
  }
}

export default EcomModel;
