import ShipperApi from 'common/api/ShipperApi';
import ShipperCollection from 'common/collections/ShipperCollection';
import ShipperModel from 'common/models/ShipperModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ShipperJsonAddEdit,
  FilterShipper,
  PasswordUpdateJson,
} from "common/types/Shipper";

class ShipperRepository extends BaseRepository<ShipperApi> {
  _api: ShipperApi | null;

  constructor() {
    super();
    this._api = new ShipperApi(true);
  }

  static getDefaultFilters(): FilterShipper {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      internal_id: "",
      shipping_hub_id: -1,
      transportation: -1,
    };
  }

  async getFullData(
    companyId: number,
    version: number
  ): Promise<ShipperCollection> {
    return this._api
      ? await this._api.getFullData({ companyId, version })
      : new ShipperCollection();
  }

  async getItems(props: GetListProps<FilterShipper>) {
    return this._api ? await this._api.getList(props) : new ShipperCollection();
  }

  async getItem(id: number): Promise<ShipperModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShipperModel(ShipperModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: ShipperJsonAddEdit): Promise<ShipperModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShipperModel(ShipperModel.getDefaultData());
    }
  }

  async setPassword(data: PasswordUpdateJson): Promise<string[]> {
    if (this._api) {
      return await this._api.setPassword(data);
    } else {
      return [];
    }
  }
}

export default ShipperRepository;
