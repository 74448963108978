import {
	Button,
	Card,
	Col,
	Collapse,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	message,
	Row,
	Select,
	Typography,
} from "antd";
import SettingRepository from "common/repositories/SettingRepository";
import { CollectionAds, CollectionAds2 } from "common/types/Ads";
import { SettingJson } from "common/types/Setting";
import UploadEvent from "features/event/form/components/UploadEvent";
import React, { useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { CollectionAdsData } from "common/types/Ads";
import dayjs, { Dayjs } from "dayjs";
import Helper from "common/utils/helper";
type Props = {
	initialValue: CollectionAds2;
	onFinish?: (data: CollectionAds2, onSuccess?: () => void) => void;
};
export default function AdsCollectionForm({ initialValue, onFinish }: Props) {
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();

	const initialValues = useMemo(() => {
		return {
			...initialValue,
			from_time: initialValue.from_time
				? dayjs(initialValue.from_time * 1000)
				: undefined,
			to_time: initialValue.to_time
				? dayjs(initialValue.to_time * 1000)
				: undefined,
			data: initialValue.data.map((i) => ({
				...i,
				from_time: i.from_time ? dayjs(i.from_time * 1000) : undefined,
				to_time: i.to_time ? dayjs(i.to_time * 1000) : undefined,
			})),
		};
	}, [initialValue]);

	const doPrepareData = (formData: any): CollectionAds2 => {
		return {
			...formData,
			data: formData.data.map((i: any) => {
				return {
					...i,
					from_time: Helper.convertedDateToTimeStamp(i.from_time, 0),
					to_time: Helper.convertedDateToTimeStamp(i.to_time, 0),
					location_view: i.location_view,
				};
			}),
			from_time: Helper.convertedDateToTimeStamp(formData.from_time, 0),
			to_time: Helper.convertedDateToTimeStamp(formData.to_time, 0),
		};
	};

	const onSubmit = async (formData: any) => {
		const d: CollectionAds2 = doPrepareData(formData);
		console.log("🚀 ~ onSubmit ~ d:", d);
		onFinish && onFinish(d);
	};

	const g = [
		{
			id: 745228,
			handle: "gift-set",
			title: "Siêu Deal Tháng 7",
			type: "collection",
			from_time: 1712831589,
			to_time: 0,
			link: "/collections/tuan-le-thuong-hieu",
			link_more: "/collections/tuan-le-thuong-hieu",
			title_more: "Xem zui",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "tuan-le-thuong-hieu",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			id: 745228,
			handle: "tuan-le-thuong-hieu",
			title: "Tuần Lễ Thương Hiệu",
			type: "collection",
			from_time: 1712831589,
			to_time: 0,
			link: "/collections/tuan-le-thuong-hieu",
			link_more: "/collections/tuan-le-thuong-hieu",
			title_more: "Xem zui",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "tuan-le-thuong-hieu",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			id: 1004120518,
			type: "collection",
			title: "Deal Thơm",
			title_more: "Xem thêm",
			handle: "deal-thom",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/deal-thom",
			link_more: "collections/deal-thom",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "deal-thom",
					store_id: 411209,
					parent_id: 0,
				},
			},
		},
		{
			id: 794249,
			type: "collection",
			title: "New Arrivals",
			title_more: "Xem thêm",
			handle: "nuoc-hoa-moi",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-moi",
			link_more: "collections/nuoc-hoa-moi",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-moi",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 794151,
			title: "Bestsellers",
			title_more: "Xem thêm",
			handle: "bestsellers",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/yeu-thich-nhat",
			link_more: "collections/yeu-thich-nhat",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "yeu-thich-nhat",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "section_banner",
			id: 0,
			handle: "banner_nnn",
			from_time: 1712136268,
			title_more: "Xem thêm",
			to_time: 0,
			link: "",
			link_more: "",
			title: "",
		},
		{
			type: "collection",
			id: 452899,
			title: "Nước Hoa Mini",
			title_more: "Xem thêm",
			handle: "nuoc-hoa-mini",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/nuoc-hoa-mini",
			link_more: "collections/nuoc-hoa-mini",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "nuoc-hoa-mini",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 707613,
			title: "Tuần lễ thương hiệu Hugo Boss",
			title_more: "Xem thêm",
			handle: "thuong-hieu-hugo-boss",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/thuong-hieu-hugo-boss",
			link_more: "collections/thuong-hieu-hugo-boss",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "thuong-hieu-hugo-boss",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 452892,
			title: "Giftset",
			title_more: "Xem thêm",
			handle: "giftset-nuoc-hoa",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/giftset-nuoc-hoa",
			link_more: "collections/giftset-nuoc-hoa",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "giftset-nuoc-hoa",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "collection",
			id: 794282,
			title: "Bodycare & Homecare",
			title_more: "Xem thêm",
			handle: "bodycare-homecare",
			from_time: 1712136268,
			to_time: 0,
			link: "collections/bodycare-homecare",
			link_more: "collections/bodycare-homecare",
			dataSource: {
				params: {
					page: 1,
					limit: 20,
					collection_id: "bodycare-homecare",
					store_id: 181189,
					parent_id: 0,
				},
			},
		},
		{
			type: "namperfumetv",
			id: 0,
			handle: "",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "namperfume TV",
			title_more: "Xem thêm",
		},
		{
			type: "nmagazine",
			id: 509,
			handle: "brand_nmagazine",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Nmagazine",
			title_more: "Xem thêm",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_1",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
		},
		{
			type: "section_about",
			id: 0,
			handle: "about_namperfume_2",
			from_time: 1712136268,
			to_time: 0,
			link: "",
			link_more: "",
			title: "Về namperfume",
			title_more: "Xem thêm",
		},
	];

	return (
		<Form
			scrollToFirstError
			className="min-w-[600px] w-full max-w-[80vw]"
			title="Cài đặt card quảng cáo"
			form={form}
			onFinish={onSubmit}
			initialValues={initialValues}>
			{/* <Form.List name={"data"} initialValue={initialValues.data}>
				{(fileds, { add, remove }) => (
					<>
						<Row gutter={4} className="gap-2">
							{fileds.map((field, index) => {
								return (
									<Col span={8} className="border">
										<Form.Item
											name={[field.name, "index"]}
											initialValue={
												initialValues.data?.[index]?.index || index + 1
											}>
											<InputNumber></InputNumber>
										</Form.Item>
										<Form.List
											name={[field.name, "images"]}
											initialValue={
												initialValues.data?.[index]?.images || [
													{ url: "", link: "" },
												]
											}>
											{(subField, { add, remove }) => (
												<>
													{subField.map((subFields, subIndex) => {
														return (
															<Form.Item name={[subFields.name, "url"]}>
																<UploadEvent
																	height={80}
																	width={80}
																	defaultUrl={
																		initialValues.data?.[index]?.images?.[
																			subIndex
																		].url || ""
																	}
																	onSuccess={(url) => {
																		const key = subFields.key;
																		let data = form.getFieldValue(
																			"data"
																		) as CollectionAdsData[];
																		data[index].images[subIndex].url = url;
																		form.setFieldValue("data", data);
																	}}></UploadEvent>
															</Form.Item>
														);
													})}
												</>
											)}
										</Form.List>
										<Form.Item>
											<Button
												onClick={() => {
													remove(index);
												}}>
												xóa
											</Button>
										</Form.Item>
									</Col>
								);
							})}
						</Row>
						<Button
							onClick={() => {
								add();
							}}
							size="small"
							type="dashed"
							className="mt-2">
							Thêm mới <PlusOutlined></PlusOutlined>
						</Button>
					</>
				)}
			</Form.List> */}

			<Row gutter={4}>
				<Col span={24}>
					<Form.Item label="Tiêu đề" name={"title"}>
						<Input></Input>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={"Bắt đầu"}
						name="from_time"
						validateFirst
						// rules={[
						// 	{
						// 		required: true,
						// 		message: "Vui lòng nhập",
						// 	},
						// 	{
						// 		message: "Vui lòng nhập",
						// 		validator: (_: any, value: Dayjs) => {
						// 			if (value) {
						// 				return Promise.resolve();
						// 			} else {
						// 				return Promise.reject("Some message here");
						// 			}
						// 		},
						// 	},
						// ]}
					>
						<DatePicker
							inputReadOnly
							className="w-full"
							placeholder={"HH:mm dd/mm/yyyy"}
							format="HH:mm DD/MM/YYYY"
							showTime
							name="date_from"
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item
						label={"Kết thúc"}
						name="to_time"
						validateFirst
						// rules={[
						// 	{
						// 		required: true,
						// 		message: "Vui lòng nhập",
						// 	},
						// 	{
						// 		message: "Vui lòng nhập",
						// 		validator: (_: any, value: Dayjs) => {
						// 			if (value) {
						// 				return Promise.resolve();
						// 			} else {
						// 				return Promise.reject("Some message here");
						// 			}
						// 		},
						// 	},
						// ]}
					>
						<DatePicker
							inputReadOnly
							className="w-full"
							placeholder={"HH:mm dd/mm/yyyy"}
							format="HH:mm DD/MM/YYYY"
							showTime
							name="date_from"
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						className="mb-4"
						rules={[
							{
								required: true,
								message: "Yêu cầu nhập",
							},
						]}
						label="Danh mục áp dụng"
						name={"handle_applys"}>
						<Select
							onChange={(value) => {}}
							options={[
								{ label: "Tất cả", value: "all" },
								{ label: "Nước hoa nam", value: "nuoc-hoa-nam" },
								{ label: "Nước hoa nữ", value: "nuoc-hoa-nu" },
							]}></Select>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						className="mb-4"
						rules={[
							{
								required: true,
								message: "Yêu cầu nhập",
							},
						]}
						label="Ẩn & hiện"
						name={"status"}>
						<Select
							placeholder="Trống"
							onChange={(value) => {}}
							options={[
								{ label: "Hiện", value: 1 },
								{ label: "Ẩn", value: 0 },
							]}></Select>
					</Form.Item>
				</Col>
			</Row>
			<Form.List name={"data"}>
				{(fileds, { add, remove }) => (
					<Row gutter={4}>
						<Divider>
							<small>Danh sách các thẻ quảng cáo</small>{" "}
						</Divider>
						<Collapse
							className="w-full"
							items={fileds.map((field, index) => {
								return {
									key: index.toString(),
									label: (
										<div className="flex w-full justify-between">
											<Typography.Text>
												Card hiển thị{" "}
												<strong>
													{initialValue.data?.[field.key]?.location_view ||
														field.key + 1}
												</strong>{" "}
											</Typography.Text>
											<Button onClick={() => remove(field.key)} size="small">
												Xóa
											</Button>
										</div>
									),
									children: (
										<div className=" w-full rounded-sm p-1" key={index}>
											<Form.Item label="Loại " name={[field.name, "type"]}>
												<Select
													disabled
													placeholder="Trống"
													onChange={(value) => {}}
													options={[
														{ label: "video", value: "video" },
														{ label: "card", value: "card" },
														{ label: "slide", value: "slide" },
													]}></Select>
											</Form.Item>
											<div className="flex gap-4">
												<Form.Item
													label="Vị trí hiển thị mobile"
													name={[field.name, "location_view"]}
													initialValue={
														initialValue.data?.[field.key]?.location_view ||
														field.key + 1
													}
													rules={[
														{
															required: true,
															message: "Vui lòng nhập",
														},
														{
															message: "Vui lòng nhập số bắt đầu từ 1",
															validator(rule, value, callback) {
																if (value <= 0) {
																	return Promise.reject("Giá trị phải là số");
																}
																return Promise.resolve();
															},
														},
													]}>
													<InputNumber
														defaultValue={
															initialValue.data?.[field.key]?.location_view ||
															field.key + 1
														}></InputNumber>
												</Form.Item>
												<Form.Item
													label="Vị trí hiển thị desktop"
													name={[field.name, "location_view_desktop"]}
													initialValue={
														initialValue.data?.[field.key]
															?.location_view_desktop || field.key + 1
													}
													rules={[
														{
															required: true,
															message: "Vui lòng nhập",
														},
														{
															message: "Vui lòng nhập số bắt đầu từ 1",
															validator(rule, value, callback) {
																if (value <= 0) {
																	return Promise.reject("Giá trị phải là số");
																}
																return Promise.resolve();
															},
														},
													]}>
													<InputNumber
														defaultValue={
															initialValue.data?.[field.key]?.location_view ||
															field.key + 1
														}></InputNumber>
												</Form.Item>
											</div>

											<Form.Item
												label={"Bắt đầu"}
												name={[field.name, "from_time"]}
												validateFirst
												// rules={[
												// 	{
												// 		required: true,
												// 		message: "Vui lòng nhập",
												// 	},
												// 	{
												// 		message: "Vui lòng nhập",
												// 		validator: (_: any, value: Dayjs) => {
												// 			if (value) {
												// 				return Promise.resolve();
												// 			} else {
												// 				return Promise.reject("Some message here");
												// 			}
												// 		},
												// 	},
												// ]}
											>
												<DatePicker
													// defaultValue={dayjs(
													// 	initialValue.data?.[index]?.from_time * 1000 ||
													// 		Date.now()
													// )}
													inputReadOnly
													className="w-full"
													placeholder={"HH:mm dd/mm/yyyy"}
													format="HH:mm DD/MM/YYYY"
													showTime
													name="date_from"
												/>
											</Form.Item>
											<Form.Item
												label={"Kết thúc"}
												name={[field.name, "to_time"]}
												validateFirst
												// rules={[
												// 	{
												// 		required: true,
												// 		message: "Vui lòng nhập",
												// 	},
												// 	{
												// 		message: "Vui lòng nhập",
												// 		validator: (_: any, value: Dayjs) => {
												// 			if (value) {
												// 				return Promise.resolve();
												// 			} else {
												// 				return Promise.reject("Some message here");
												// 			}
												// 		},
												// 	},
												// ]}
											>
												<DatePicker
													// defaultValue={dayjs(
													// 	initialValue.data?.[field.key]?.to_time || 0
													// )}

													inputReadOnly
													className="w-full"
													placeholder={"HH:mm dd/mm/yyyy"}
													format="HH:mm DD/MM/YYYY"
													showTime
													name="to_time"
												/>
											</Form.Item>
											<Form.Item
												className="mb-4"
												rules={[
													{
														required: true,
														message: "Yêu cầu nhập",
													},
												]}
												label="Ẩn & hiện"
												name={[field.name, "status"]}>
												<Select
													placeholder="Trống"
													onChange={(value) => {}}
													options={[
														{ label: "Hiện", value: 1 },
														{ label: "Ẩn", value: 0 },
													]}></Select>
											</Form.Item>
											<Form.List name={[field.name, "data"]}>
												{(subFields, { add: addData, remove: removeData }) => {
													return (
														<>
															<Row>
																{subFields.map((subField) => (
																	<Col>
																		<Form.Item name={[subField.name, "link"]}>
																			<Input></Input>
																		</Form.Item>
																		<Form.Item name={[subField.name, "img"]}>
																			<UploadEvent
																				defaultUrl={
																					initialValue.data?.[field.key]
																						?.data?.[subField.key]?.img || ""
																				}
																				height={60}
																				onSuccess={(url) => {
																					form.setFieldValue(
																						[
																							"data",
																							field.name,
																							"data",
																							subField.name,
																							"img",
																						],
																						url
																					);
																				}}></UploadEvent>
																		</Form.Item>
																	</Col>
																))}
															</Row>
														</>
													);
												}}
											</Form.List>
										</div>
									),
								};
							})}></Collapse>
						<button
							type="button"
							className="mt-2"
							onClick={() => {
								add({
									status: 1,
									from_time: 0,
									to_time: 0,
									data: [{ img: "", link: "" }],
									type: "card",
								});
							}}>
							ADD
						</button>
					</Row>
				)}
			</Form.List>
			{/* <Row gutter={4}>
				<Col>
					<Form.Item name="from_time">
						<DatePicker></DatePicker>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="to_time">
						<DatePicker></DatePicker>
					</Form.Item>
				</Col>
			</Row> */}

			<Form.Item className="text-right mt-4">
				<Button type="primary" htmlType="submit">
					Xác nhận
				</Button>
			</Form.Item>
		</Form>
	);
}
