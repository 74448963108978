import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import React from "react";

import ReportChartDataTable from "../chart/ReportChartDataTable";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportCustomerFeed = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Báo cáo hoạt động tương tác với khách hàng"}
              dataTable="customerfeed"
              dataSelect="count(id)"
              dataGroupBy=""
              dataOrderBy=""
              dataFilter="creator > 0"
              keyMapping={{ name: "comparetext", value: "count_id" }}
              height={250}
              dataService="customerfeed"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartTimeSeries
              className="p-4"
              title={"Số hoạt động theo danh mục"}
              dataTable="customerfeed"
              dataService="customerfeed"
              dataSelect="count(id), category"
              dataFilter="creator > 0"
              dataGroupBy="category"
              dataOrderBy="count(id) DESC"
              keyMapping={{ name: "category", value: "count_id" }}
              height={400}
              dataInterval=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Tương tác với khách hàng theo danh mục"}
              dataTable="customerfeed"
              dataService="customerfeed"
              keyMapping={[
                {
                  title: "Loại hoạt động",
                  name: "category",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "category"
                },
                {
                  title: "Tổng số hoạt động",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), category"
              dataFilter="creator > 0"
              dataGroupBy="category"
              dataOrderBy="count(id) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={
                "Số hoạt động tương tác với Khách hàng theo danh mục và nhân viên"
              }
              dataTable="customerfeed"
              dataService="customerfeed"
              keyMapping={[
                {
                  title: "Nhân viên",
                  name: "creator",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "creator"
                },
                {
                  title: "Loại hoạt động",
                  name: "category",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "category"
                },
                {
                  title: "Tổng số hoạt động",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), creator, category"
              dataFilter="creator > 0"
              dataGroupBy="category, creator"
              dataOrderBy="count(id) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCustomerFeed;
