import { App, Button } from "antd";
import ProjectTaskCollection from "common/collections/ProjectTaskCollection";
import ProjectTask from "common/constants/ProjectTask";
import Role from "common/constants/Role";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectIterationRepository from "common/repositories/ProjectIterationRepository";
import ProjectTaskRepository from "common/repositories/ProjectTaskRepository";
import {
  FilterProjectTask,
  ProjectTaskPermissionJson
} from "common/types/ProjectTask";
import eventEmitter from "common/utils/eventEmitter";
import ProjectIterationFormModal from "features/projectiteration/form/ProjectIterationFormModal";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import useWindowDimensions from "hooks/useWindowDimensions";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useProjectTaskLayoutStore from "zustands/useProjectTaskLayoutStore";

import {
  IconLayoutKanban,
  IconLayoutList,
  IconPlus,
  IconVersions
} from "@tabler/icons-react";

import ProjectTaskFormModal from "./form/ProjectTaskFormModal";
import ProjectTaskFormUpdateStatusModal from "./form/ProjectTaskFormUpdateStatusModal";
import ProjectTaskItemExpand from "./item/ProjectTaskItemExpand";
import ProjectTaskKanbanContainer from "./kanban/ProjectTaskKanbanContainer";
import ProjectTaskList from "./list/ProjectTaskList";
import ProjectTaskFilter from "./ProjectTaskFilter";
import ProjectTaskHeader from "./ProjectTaskHeader";

const ProjectTaskWrapper = ({
  projectModel
}: {
  projectModel: ProjectModel;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const account = useLoginAccountStore((state) => state.account);
  const accountRoleSubjects = useLoginAccountStore((state) =>
    state.account.role.map((r) => r.subject_id)
  );

  const pageHeaderNavHeight = 60;
  const pageHeaderHeight = 56;
  const pageFilterHeight = 56;
  //get screen height
  const { height } = useWindowDimensions();

  const [layoutActived, setLayoutActived] = useProjectTaskLayoutStore(
    (state) => [state.layoutActived, state.setLayoutActived]
  );

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterProjectTask = useMemo(
    () => ProjectTaskRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterProjectTask>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  // total task
  const [total, setTotal] = useState<number>(0);

  //////////////////////////////////////////
  // modal project iteration
  const [isModalIterationOpen, setIsModalIterationOpen] =
    useState<boolean>(false);

  //////////////////////////////////////////
  // modal editing, update status
  const [processing, setProcessing] = useState<boolean>(false);
  const [isModalAddEditTaskOpen, setIsModalAddEditTaskOpen] =
    useState<boolean>(false);
  const [editingTaskId, setEditingTaskId] = useState<number>(0);
  //////////////////////////////////////////
  // viewingTask
  const [isPanelViewingTaskOpen, setIsPanelViewingTaskOpen] =
    useState<boolean>(false);
  const [viewingTask, setViewingTask] = useState<ProjectTaskModel>(
    new ProjectTaskModel(ProjectTaskModel.getDefaultData())
  );
  //////////////////////////////////////////
  // modal update status
  const [editingTaskIdUpdateStatus, setEditingTaskIdUpdateStatus] =
    useState<number>(0);
  const [isModalTaskUpdateStatusOpen, setIsModalTaskUpdateStatusOpen] =
    useState<boolean>(false);
  //////////////////////////////////////////
  //Fetch data
  const [dataSource, setDataSource] = useState<ProjectTaskModel[]>([]);
  const [permissions, setPermissions] = useState<ProjectTaskPermissionJson>(
    ProjectTaskModel.getDefaultDataPermission()
  );
  const [projectIterationItems, setProjectIterationItems] = useState<
    ProjectIterationModel[]
  >([]);

  const fetchProjectIterationByIds = useCallback(async (ids: string) => {
    const collection = await new ProjectIterationRepository().getItemsByIds(
      ids
    );
    if (!collection.hasError()) {
      setProjectIterationItems(collection.items);
    }
  }, []);

  const fetchData = useCallback(async (): Promise<ProjectTaskCollection> => {
    let collection = await new ProjectTaskRepository().getItems({
      filters: {
        ...filters,
        project_id: projectModel.id,
        company_id: account.company.id,
        is_pipeline: 0
      }
    });

    if (!collection.hasError()) {
      setPermissions((prev) => ({
        ...prev,
        can_add_story: collection.can_add_story,
        can_edit_story: collection.can_edit_story
      }));

      setTotal(collection.total);

      // set data for ProjectTask: LAYOUT_LIST
      setDataSource(collection.items);

      // get project iteration
      let projectIterationIdList = collection.items
        .map((item) => item.project_iteration_id)
        .filter((i) => i > 0 && i !== undefined);
      if (projectIterationIdList.length > 0) {
        fetchProjectIterationByIds(projectIterationIdList.join(","));
      }
    }
    return collection;
  }, [
    filters,
    projectModel.id,
    account.company.id,
    fetchProjectIterationByIds
  ]);

  const onViewProjectIterationFormModal = () => {
    setIsModalIterationOpen(true);
  };

  const canAddEditProjectTask = useMemo(() => {
    let canAdd = false;

    let uid = account.id;
    let rolePermission = projectModel.role_permission;
    let developmentTeam = projectModel.development_team;
    let scrumMaster = projectModel.scrum_master;

    for (var i = 0; i < developmentTeam.length; i++) {
      if (
        uid === developmentTeam[i] &&
        rolePermission.development_team_add_story === 1
      ) {
        canAdd = true;
        break;
      }
    }

    for (var j = 0; j < scrumMaster.length; j++) {
      if (
        uid === scrumMaster[j] &&
        rolePermission.scrum_master_add_story === 1
      ) {
        canAdd = true;
        break;
      }
    }
    return canAdd;
  }, [account, projectModel]);

  const canMagage = useMemo(() => {
    if (projectModel.scrum_master.findIndex((i) => i === account.id) >= 0)
      return true;
    return false;
  }, [account, projectModel]);

  const onEditProjectTaskForm = (id: number) => {
    setEditingTaskId(id);
    setIsModalAddEditTaskOpen(true);
  };

  const updateCountComment = (isUpdate: boolean) => {
    if (isUpdate) {
      fetchData();
    }
  };

  const onViewProjectTaskFormUpdateStatus = (id: number) => {
    setEditingTaskIdUpdateStatus(id);
    setIsModalTaskUpdateStatusOpen(true);
  };

  const onOpenPanelViewingTask = (item: ProjectTaskModel) => {
    setIsPanelViewingTaskOpen(true);
    setViewingTask(item);
  };

  const getTaskCode = useCallback(
    (item: ProjectTaskModel) => {
      let code = "";
      if (projectModel.story_prefix === "") {
        code = item.id.toString();
      } else {
        code = projectModel.story_prefix + "-" + item.id.toString();
      }

      return code;
    },
    [projectModel.story_prefix]
  );

  const canUpdateStatus = useCallback(
    (item: ProjectTaskModel) => {
      let canUpdate = 0;
      let canEdit = 0;
      // if current logged in user include ONE role need to check, it's PASS
      const hasRole =
        [Role.PROJECT_MANAGE].filter((r) => accountRoleSubjects.includes(r))
          .length > 0;

      if (
        hasRole ||
        projectModel.scrum_master.findIndex((i) => i === account.id) >= 0
      ) {
        canEdit = 1;
      }

      if (
        canEdit ||
        item.assignees.split(",").findIndex((i) => +i === account.id) >= 0
      ) {
        canUpdate = 1;
      }
      return canUpdate;
    },
    [accountRoleSubjects, account, projectModel]
  );

  const onSaveSuccess = useCallback(
    (item: ProjectTaskModel) => {
      if (item.id > 0) {
        item.code = getTaskCode(item);
        item.can_update_status = canUpdateStatus(item);

        let foundIndex = dataSource.findIndex((i) => i.id === item.id);
        if (foundIndex >= 0) {
          // update
          setDataSource(
            update(dataSource, {
              [foundIndex]: {
                $set: item
              }
            })
          );
        } else {
          // add
          setDataSource(
            update(dataSource, {
              $unshift: [item]
            })
          );
          // update total
          setTotal(total + 1);
        }

        // trigger change task to kanban
        eventEmitter.emit("PROJECTTASK_KANBAN_CHANGE_TASK", item);

        message.success({
          content: t("common:form.success.save"),
          className: "message_success",
          key: "message",
          duration: 3
        });

        // reset modal
        setProcessing(false);
        setEditingTaskId(0);
        setIsModalAddEditTaskOpen(false);
        setIsPanelViewingTaskOpen(false);
      }
    },
    [
      setEditingTaskId,
      setIsModalAddEditTaskOpen,
      setProcessing,
      dataSource,
      t,
      getTaskCode,
      canUpdateStatus,
      total,
      message
    ]
  );

  const onSaveProjectIterationSuccess = useCallback(
    (item: ProjectIterationModel) => {
      if (item.id > 0) {
        let foundIndex = projectIterationItems.findIndex(
          (i) => i.id === item.id
        );
        let newItem = projectIterationItems;
        if (foundIndex >= 0) {
          // update
          newItem = update(projectIterationItems, {
            [foundIndex]: {
              $set: item
            }
          });

          setProjectIterationItems(newItem);
        } else {
          // add
          newItem = update(projectIterationItems, {
            $unshift: [item]
          });

          setProjectIterationItems(newItem);
        }

        // trigger change iteration to kanban
        eventEmitter.emit("PROJECTTASK_KANBAN_CHANGE_ITERATION", newItem);
      }
    },
    [projectIterationItems]
  );

  const onChangeLayout = (activedKey: number) => {
    if (activedKey === ProjectTask.LAYOUT_LIST) {
      setLayoutActived(ProjectTask.LAYOUT_KANBAN);
    } else if (activedKey === ProjectTask.LAYOUT_KANBAN) {
      setLayoutActived(ProjectTask.LAYOUT_LIST);
    }
  };

  useEffect(() => {
    if (layoutActived === ProjectTask.LAYOUT_LIST) {
      fetchData();
    }
  }, [fetchData, layoutActived]);

  return (
    <>
      <div
        style={{
          overflow: layoutActived === ProjectTask.LAYOUT_KANBAN ? "hidden" : ""
        }}>
        <ProjectTaskHeader model={projectModel}>
          <Button
            type="link"
            className="mr-2"
            icon={
              layoutActived === ProjectTask.LAYOUT_LIST ? (
                <IconLayoutKanban className="mr-1 -mt-0.5" size={16} />
              ) : layoutActived === ProjectTask.LAYOUT_KANBAN ? (
                <IconLayoutList className="mr-1 -mt-0.5" size={16} />
              ) : null
            }
            onClick={() => onChangeLayout(layoutActived)}>
            {layoutActived === ProjectTask.LAYOUT_LIST
              ? t("projecttask:LAYOUT_KANBAN")
              : layoutActived === ProjectTask.LAYOUT_KANBAN
              ? t("projecttask:LAYOUT_LIST")
              : ""}
          </Button>

          <Button
            className="mr-2"
            icon={<IconVersions className="mr-1 -mt-0.5" size={16} />}
            onClick={(e) => {
              e.preventDefault();
              onViewProjectIterationFormModal();
            }}>
            {t("projectiteration:heading_list")}
          </Button>

          {canAddEditProjectTask ? (
            <Button
              type="primary"
              icon={<IconPlus className="mr-1 -mt-0.5" size={16} />}
              onClick={(e) => {
                e.preventDefault();
                setEditingTaskId(0);
                setIsModalAddEditTaskOpen(true);
              }}>
              {t("projecttask:add_button")}
            </Button>
          ) : null}
        </ProjectTaskHeader>

        <ProjectTaskFilter
          filters={filters}
          setFilters={setFilters}
          defaultFilters={defaultFilters}
          total={total}
          extra={layoutActived === ProjectTask.LAYOUT_LIST ? true : false}
          projectId={projectModel.id}
        />

        {layoutActived === ProjectTask.LAYOUT_LIST ? (
          <ProjectTaskList
            fetchData={fetchData}
            dataSource={dataSource}
            total={total}
            setDataSource={setDataSource}
            projectIterationItems={projectIterationItems}
            projectModel={projectModel}
            filters={filters}
            setFilters={setFilters}
            setEditingTaskId={setEditingTaskId}
            setIsModalAddEditTaskOpen={setIsModalAddEditTaskOpen}
            setViewingTask={onOpenPanelViewingTask}
            onViewProjectTaskFormUpdateStatus={
              onViewProjectTaskFormUpdateStatus
            }
          />
        ) : layoutActived === ProjectTask.LAYOUT_KANBAN ? (
          <div
            className="px-5 bg-gray-50"
            style={{
              height:
                height -
                pageHeaderNavHeight -
                pageHeaderHeight -
                pageFilterHeight
            }}>
            <ProjectTaskKanbanContainer
              filters={filters}
              projectModel={projectModel}
              setTotal={setTotal}
              setViewingTask={onOpenPanelViewingTask}
            />
          </div>
        ) : null}

        {isModalAddEditTaskOpen ? (
          <ProjectTaskFormModal
            id={editingTaskId}
            projectModel={projectModel}
            key={"project_task_form_modal_" + editingTaskId}
            open={isModalAddEditTaskOpen}
            setOpen={(isOpen) => {
              //clear modal when close
              if (!isOpen) {
                setEditingTaskId(0);
              }
              setIsModalAddEditTaskOpen(isOpen);
            }}
            processing={processing}
            setProcessing={setProcessing}
            onSaveSuccess={onSaveSuccess}
          />
        ) : null}

        {isModalIterationOpen ? (
          <ProjectIterationFormModal
            key={"project_iteration_form_modal_" + projectModel.id}
            open={isModalIterationOpen}
            setOpen={(isOpen) => {
              setIsModalIterationOpen(isOpen);
            }}
            projectModel={projectModel}
            totalTask={total}
            canAddIteration={canMagage}
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            onSaveSuccess={onSaveProjectIterationSuccess}
          />
        ) : null}

        {isModalTaskUpdateStatusOpen ? (
          <ProjectTaskFormUpdateStatusModal
            projectTaskId={editingTaskIdUpdateStatus}
            key={
              "project_task_form_update_status_modal_" +
              editingTaskIdUpdateStatus
            }
            open={isModalTaskUpdateStatusOpen}
            setOpen={(isOpen) => {
              //clear modal when close
              if (!isOpen) {
                setEditingTaskIdUpdateStatus(0);
              }
              setIsModalTaskUpdateStatusOpen(isOpen);
            }}
            onSaveSuccess={onSaveSuccess}
            projectModel={projectModel}
          />
        ) : null}

        {isPanelViewingTaskOpen ? (
          <ProjectTaskItemExpand
            open={isPanelViewingTaskOpen}
            setOpen={(isOpen) => {
              if (!isOpen) {
                setViewingTask(
                  new ProjectTaskModel(ProjectTaskModel.getDefaultData())
                );
              }
              setIsPanelViewingTaskOpen(isOpen);
            }}
            key={"project_task_item_expand_" + viewingTask.id}
            record={viewingTask}
            onClose={() =>
              setViewingTask(
                new ProjectTaskModel(ProjectTaskModel.getDefaultData())
              )
            }
            projectModel={projectModel}
            permissions={permissions}
            onEditProjectTaskForm={onEditProjectTaskForm}
            onViewProjectTaskFormUpdateStatus={
              onViewProjectTaskFormUpdateStatus
            }
            updateCountComment={updateCountComment}
          />
        ) : null}
      </div>
    </>
  );
};

export default ProjectTaskWrapper;
