import React from "react";
import { Typography } from "antd";
type Props = {
  title: string;
  icon?: React.ReactNode;
};
const HomeTitle = ({ title, icon }: Props) => {
  return (
    <div className="block">
      <Typography.Title
        level={5}
        className="flex items-center gap-1 text-slate-400"
      >
        {icon && icon} {title}
      </Typography.Title>
    </div>
  );
};

export default HomeTitle;
