import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import React from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const OrderSellerListHeader = () => {
  const { t } = useTranslation();
	const account = useLoginAccountStore((state) => state.account);
  return (
    <PageHeader
      heading={t("order:heading_list") + " của " + account.full_name}
      nav={[]}
      siteMenuOpenKey="orderseller"
      siteMenuSelectedKey="/orderseller">
      <PageHeaderButton type="primary" link={`${process.env.REACT_APP_SHOP_DOMAIN_URL}/pos`} target="_blank">
        {t("order:pos_button")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default OrderSellerListHeader;
