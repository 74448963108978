import BaseCollection from 'common/collections/BaseCollection';
import ShippingRouteModel from 'common/models/ShippingRouteModel';
import { ShippingRouteCollectionJson, ShippingRouteJson } from 'common/types/ShippingRoute';

class ShippingRouteCollection extends BaseCollection<
  ShippingRouteJson,
  ShippingRouteModel,
  ShippingRouteCollectionJson
> {
  itemsFromJson(jsonItems: ShippingRouteJson[]): ShippingRouteModel[] {
    return jsonItems.map((item) => new ShippingRouteModel(item));
  }
}

export default ShippingRouteCollection;
