import { Button } from "antd";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import useProcessStore from "zustands/useProcessStore";
import ErrorAlert from "components/ErrorAlert";
import useFunction from "hooks/useFunction";

type Props = {
  title?: React.ReactNode;
};
const LogoutModal = ({ title }: Props) => {
  const { t } = useTranslation();
  const { doLogOut } = useFunction();
  const [fetchErrors, setFetchErrors] = useProcessStore((state) => [
    state.fetchErrors,
    state.setFetchErrors,
  ]);

  const triggerLogout = async () => {
    await doLogOut();
  };
  return (
    <div className="min-w-[325px] h-auto text-center py-2 px-4 bg-white rounded-md flex flex-col justify-center items-center">
      <Typography.Title level={3}>
        {title ? title : t(`common:errors.error_jwt_hash_not_found`)}
      </Typography.Title>
      <ErrorAlert
        style={{ marginTop: 10 }}
        translate_prefix="common:session.error"
        items={fetchErrors}
        type="warning"
      />
      <Button type="primary" className="mt-4" onClick={() => triggerLogout()}>
        {t("user:logout.title")}
      </Button>
    </div>
  );
};

export default LogoutModal;
