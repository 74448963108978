const items = [
    {
        id:1,
        username:"Boromim ",
        avatar:"https://images.pexels.com/photos/3104709/pexels-photo-3104709.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Aragorn? This… is Isildur’s heir?",
        button: [
            {
                icon:"",
                count:"",
            }
        ]
    },
    {
        id:2,
        username:"Legolas ",
        avatar:"https://images.pexels.com/photos/4056462/pexels-photo-4056462.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"And heir to the throne of Gondor.",
        button: [
            {
                icon:"🤯",
                count:"7",
            },
            {
                icon:"🏰",
                count:"2",
            },
            {
                icon:"👑",
                count:"3",
            }
        ]
    },
    {
        id:3,
        username:"Aragorn ",
        avatar:"https://images.pexels.com/photos/2951921/pexels-photo-2951921.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Havo dad Legolas.",
        button: [
            {
                icon:"❓",
                count:"1",
            },
        ]
    },
    {
        id:4,
        username:"Pippin  ",
        avatar:"https://images.pexels.com/photos/4587987/pexels-photo-4587987.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        realtime:"01:26",
        text:"Is that elvish for second breakfast?",
        button: [
            {
                icon:"🍳",
                count:"3",
            }
        ]
    },
]

export default items;