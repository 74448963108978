import { Divider, Modal, Radio } from "antd";
import classNames from "classnames";
import Company from "common/constants/Company";
import CompanyModel from "common/models/CompanyModel";
import LayoutTitle from "components/LayoutTitle";
import PageSiteMenu from "components/page/PageSiteMenu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { CheckCircleOutlined } from "@ant-design/icons";

type Frequency = Record<string, string>;
type Tier = {
  name: string;
  id: number;
  href: string;
  price: Record<string, string>;
  description: string;
  features: string[];
  mostPopular: boolean;
};

const CompanyUpgradeIndex = () => {
  const frequencies: Frequency[] = [
    { value: "monthly", label: "tháng", priceSuffix: "/tháng" },
    { value: "annually", label: "năm", priceSuffix: "/năm" },
  ];

  const tiers: Tier[] = [
    {
      name: "Free",
      id: Company.PLAN_01,
      href: "#",
      price: { monthly: "Miễn phí", annually: "Miễn phí" },
      description:
        "Dành cho các cửa hàng nhỏ, tự kinh doanh, chưa có nhiều nhân sự và đơn hàng",
      features: ["1 cửa hàng", "1 Kênh bán hàng", "500 sản phẩm"],
      mostPopular: false,
    },
    {
      name: "Basic",
      id: Company.PLAN_02,
      href: "#",
      price: { monthly: "400.000", annually: "4.800.000" },
      description:
        "Dành cho doanh nghiệp bắt đầu mở rộng quy mô kinh doanh và có nhiều dữ liệu",
      features: [
        "5 Cửa hàng",
        "3 Kênh bán hàng",
        "10.000 Sản phẩm",
        "*Kết nối API",
        "*Webhook",
      ],
      mostPopular: true,
    },

    {
      name: "Premium",
      id: Company.PLAN_ON_PREMISE,
      href: "#",
      price: { monthly: "Liên hệ", annually: "Liên hệ" },
      description:
        "Dành cho những doanh nghiệp với quy mô vừa, muốn tối ưu và tuỳ biến phần mềm.",
      features: [
        "Không giới hạn Cửa hàng",
        "Không giới hạn Kênh bán hàng",
        "Không giới hạn Sản phẩm",
        "Kết nối API",
        "Webhook",
        "*Tên miền riêng",
        "*Database riêng",
        "*Cài đặt trên server riêng",
      ],
      mostPopular: false,
    },
  ];

  const { t } = useTranslation();
  const [frequency, setFrequency] = useState<Frequency>(frequencies[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const account = useLoginAccountStore((state) => state.account);

  return (
    <>
      <LayoutTitle title={t("company:heading_upgrade")} />
      <PageSiteMenu siteMenuSelectedKey={""} siteMenuHidden />

      <div className="px-12 pt-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600">
            Bảng giá
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Các gói phần mềm
          </p>
        </div>

        <div className="flex justify-center mt-8">
          <span className="mr-2 mt-1 text-sm">Xem giá theo:</span>
          <Radio.Group
            buttonStyle="solid"
            value={frequency.value}
            onChange={(e) =>
              setFrequency(
                frequencies.find((i) => e.target.value === i.value) ||
                  frequencies[0]
              )
            }
          >
            {frequencies.map((option: Frequency) => (
              <Radio.Button
                key={option.value}
                value={option.value}
                className={classNames(
                  option.value === frequency.value
                    ? "bg-blue-600 text-white"
                    : "text-gray-500",
                  "cursor-pointer "
                )}
              >
                <span>{option.label}</span>
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>

        <div className="isolate max-w-7xl mx-auto mt-10 grid grid-cols-1 gap-8 md:grid-cols-3 xl:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-2 ring-blue-600"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8"
              )}
            >
              <h3
                id={tier.id + ""}
                className={classNames(
                  tier.mostPopular ? "text-blue-600" : "text-gray-600",
                  "text-md font-extrabold leading-8 uppercase"
                )}
              >
                {tier.name}
              </h3>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  {tier.price[frequency.value]}
                </span>
                {tier.id !== Company.PLAN_ON_PREMISE ? (
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequency.priceSuffix}
                  </span>
                ) : null}
              </p>

              {account.company.plan === tier.id ? (
                <span
                  aria-describedby={tier.name}
                  className={classNames(
                    "text-gray-600 ring-1 ring-inset ring-gray-200",
                    "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  )}
                >
                  Đang sử dụng
                </span>
              ) : (
                <span
                  onClick={() => setIsModalOpen(true)}
                  aria-describedby={tier.name}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-blue-600 text-white shadow-sm hover:bg-blue-500"
                      : "text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300",
                    "cursor-pointer mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  )}
                >
                  Nâng cấp
                </span>
              )}

              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    {feature[0] === "*" ? (
                      <>
                        <CheckCircleOutlined
                          className="h-6 w-5 flex-none text-green-600"
                          aria-hidden="true"
                        />
                        {feature.substring(1)}
                      </>
                    ) : (
                      <>
                        <CheckCircleOutlined
                          className="h-6 w-5 flex-none text-blue-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="text-center pt-6 text-gray-400 text-sm italic">
          (Lưu ý: Thanh toán tối thiểu 1 năm cho gói BASIC.)
        </div>
      </div>

      <Modal
        title="Yêu cầu nâng cấp gói phần mềm"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <p>
          Nếu có mong muốn nâng cấp gói phần mềm, vui lòng liên hệ email&nbsp;
          <a
            className="text-blue-500"
            href="mailto:support@cropany.com"
            target="_blank"
            rel="noreferrer"
          >
            support@cropany.com
          </a>{" "}
          để được hỗ trợ.
        </p>

        <Divider />
        <p className="">
          Một số thông tin liên quan đến tài khoản công ty của bạn: <br />
          <ul className="list-disc ml-8">
            <li>
              ID công ty: <strong>{account.company.id}</strong>
            </li>
            <li>
              Tên công ty: <strong>{account.company.name}</strong>
            </li>
            <li>
              Gói hiện tại:{" "}
              <strong>
                {CompanyModel.getPlan(account.company.plan)?.label}
              </strong>
            </li>
          </ul>
        </p>
      </Modal>
    </>
  );
};

export default CompanyUpgradeIndex;
