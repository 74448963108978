import { Button, Col, Form, Input, Row } from "antd";
import { BrandData } from "common/types/Shop";
import UploadEvent from "features/event/form/components/UploadEvent";
import React, { useState } from "react";

type Props = {
	initialValue: BrandData;
	onFinish?: (data: BrandData) => void;
};
export default function BrandForm({ initialValue, onFinish }: Props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onSubmit = (formData: BrandData) => {
		setLoading(true);

		onFinish && onFinish(formData);

		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};
	return (
		<div>
			<Form form={form} initialValues={initialValue} onFinish={onSubmit}>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item
							label="Ảnh Desktop"
							labelCol={{ span: 24 }}
							name={"image"}>
							<UploadEvent
								height={60}
								width={60}
								defaultUrl={initialValue.image}
								onSuccess={(url) => {
									form.setFieldValue("image", url);
								}}></UploadEvent>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							label="Ảnh Mobile"
							labelCol={{ span: 24 }}
							name={"image_mobile"}>
							<UploadEvent
								height={60}
								width={60}
								defaultUrl={initialValue.image_mobile}
								onSuccess={(url) => {
									form.setFieldValue("image_mobile", url);
								}}></UploadEvent>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Link hình ảnh"
							labelCol={{ span: 24 }}
							name={"link"}>
							<Input></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Alt Ảnh" labelCol={{ span: 24 }} name={"alt"}>
							{<Input></Input>}
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item className="text-right">
							<Button type="primary" loading={loading} htmlType="submit">
								Xác nhận
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	);
}
