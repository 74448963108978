import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const TaxInvoiceFormHeader = ({
  isEditing,

  id
}: {
  isEditing: boolean;

  id: number;
}) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("taxinvoice:heading_nav"),
      link: "/taxinvoice"
    }
  ];

  return (
    <>
      <PageHeader
        nav={nav}
        heading={
          t(isEditing ? "taxinvoice:heading_edit" : "taxinvoice:heading_add") +
          (id > 0 ? " #" + id : "")
        }
        siteMenuSelectedKey="/taxinvoice"
        siteMenuHiddenBackLink="/taxinvoice"
        siteMenuHidden></PageHeader>
    </>
  );
};

export default TaxInvoiceFormHeader;
