import TaxInvoiceDetailModel from "common/models/TaxInvoiceDetailModel";

import TaxInvoiceFormSectionDetailList from "./detail/TaxInvoiceFormSectionDetailList";
import TaxInvoiceFormSectionBuyerInfo from "./TaxInvoiceFormSectionBuyerInfo";
import TaxInvoiceFormSectionPriceSummary from "./TaxInvoiceFormSectionPriceSummary";
import TaxInvoiceFormSectionSellerInfo from "./TaxInvoiceFormSectionSellerInfo";

const TaxInvoiceFormSectionDetail = ({
  taxInvoiceDetails,
}: {
  taxInvoiceDetails: TaxInvoiceDetailModel[];
}) => {
  return (
    <div>
      <TaxInvoiceFormSectionSellerInfo />
      <div className="h-4" />
      <TaxInvoiceFormSectionBuyerInfo />
      <div className="h-4" />
      <TaxInvoiceFormSectionDetailList taxInvoiceDetails={taxInvoiceDetails} />
      <TaxInvoiceFormSectionPriceSummary
        taxInvoiceDetails={taxInvoiceDetails}
      />
    </div>
  );
};

export default TaxInvoiceFormSectionDetail;
