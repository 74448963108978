import CollectionApi from "common/api/CollectionApi";
import BaseRepository from "./BaseRepository";
import type { GetListProps } from "common/types/Filter";
import {
  Category,
  CollectionFormDataPost,
  CollectionJsonAddEdit,
  CollectionJsonWithChildren,
  FilterCollection,
  FilterCollectionCategory,
} from "common/types/Collection";
import CollectionCollection from "common/collections/CollectionCollection";
import CollectionModel from "common/models/CollectionModel";
import NestedCollection from "common/collections/NestedCollection";

class CollectionRepository extends BaseRepository<CollectionApi> {
  _api: CollectionApi | null;
  constructor() {
    super();
    this._api = new CollectionApi(true);
  }

  static getDefaultFilters(): FilterCollection {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: "",
      date_from: -1,
      date_to: -1,
      item_type: -1,
    };
  }

  //list
  async getItems(
    props: GetListProps<FilterCollection>
  ): Promise<CollectionCollection> {
    return this._api
      ? await this._api.getList(props)
      : new CollectionCollection();
  }

  async getItemsPublic(
    props: GetListProps<FilterCollection>
  ): Promise<CollectionCollection> {
    return this._api
      ? await this._api.getListPublic(props)
      : new CollectionCollection();
  }

  async getItemsCategoryPublic(
    props: GetListProps<FilterCollectionCategory>
  ): Promise<Category[]> {
    return this._api ? await this._api.getCategoryListPublic(props) : [];
  }

  async getFullNestedItems(props: GetListProps<FilterCollection>) {
    return this._api
      ? await this._api.getAll(props)
      : new NestedCollection<CollectionJsonWithChildren>();
  }

  async getFullFetcher(props: GetListProps<FilterCollection>) {
    return this._api ? await this._api.getFullFetcher(props) : [];
  }

  //detail
  // async getItem(props: { handle: string | number }): Promise<CollectionModel> {
  //   return this._api
  //     ? await this._api.getDetail(props)
  //     : new CollectionModel(CollectionModel.getDefaultData());
  // }
  async getItem(id: number | string): Promise<CollectionModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new CollectionModel(CollectionModel.getDefaultData());
  }
  //add - edit
  async saveRemote(
    data: CollectionJsonAddEdit | CollectionFormDataPost
  ): Promise<CollectionModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new CollectionModel(CollectionModel.getDefaultData());
    }
  }
  //delete
  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default CollectionRepository;
