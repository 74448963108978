import { Alert, App, Button } from "antd";
import EcomChannelOrderCollection from "common/collections/EcomChannelOrderCollection";
import EcomChannelOrderModel from "common/models/EcomChannelOrderModel";
import EcomModel from "common/models/EcomModel";
import EcomChannelOrderRepository from "common/repositories/EcomChannelOrderRepository";
import OrderHelperRepository from "common/repositories/OrderHelperRepository";
import { FilterEcomChannelOrder } from "common/types/EcomChannelOrder";
import { EcomOrderCheck } from "common/types/EcomSyncOrder";
import { TableColumnsType } from "common/types/Table";
import Error from "components/Error";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import EcomChannelOrderListHeader from "features/ecomchannelorder/list/EcomChannelOrderListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import EcomChannelOrderItemLine from "../item/EcomChannelOrderItemLine";
import EcomChannelOrderListFilter from "./EcomChannelOrderListFilter";

const EcomChannelOrderList = ({
  channel_id,
  model,
}: {
  channel_id: number;
  model: EcomModel;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [ecomOrderCheckList, setEcomOrderCheckList] = useState<
    EcomOrderCheck[]
  >([]);

  const doEcomOrderCheck = useCallback(
    async (channel_id: number, orderIdList: string[]) => {
      const checkItems = await new OrderHelperRepository().getEcomOrderCheck(
        channel_id,
        orderIdList
      );

      setEcomOrderCheckList(checkItems);
    },
    []
  );

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterEcomChannelOrder = useMemo(
    () => EcomChannelOrderRepository.getDefaultFilters(),
    []
  );

  const messageKey = "sync_order_submit";
  const [filters, setFilters] =
    useStateFilter<FilterEcomChannelOrder>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<EcomChannelOrderModel[]>([]);
  const fetchData =
    useCallback(async (): Promise<EcomChannelOrderCollection> => {
      let collection = await new EcomChannelOrderRepository().getItems(
        {
          filters,
        },
        channel_id
      );
      console.log(filters);
      setDataSource(collection.items);
      setTotal(collection.total);
      doEcomOrderCheck(
        channel_id,
        collection.items.map((i) => i.id)
      );

      return collection;
    }, [filters, channel_id, doEcomOrderCheck]);

  //sync Order
  const synOrder = useCallback(
    async (channel_id: number, order_id: number) => {
      message.loading({
        content: t("common:form.processing"),
        key: messageKey,
        duration: 3,
      });

      let syncOrderResult = await new EcomChannelOrderRepository().syncOrder(
        channel_id,
        order_id
      );

      if (syncOrderResult.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKey);
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="ecomchannelorder:form.error"
              items={syncOrderResult.error.errors}
              contentPadding={0}
            />
          ),
          className: "message_error",
          key: messageKey,
          duration: 3,
        });
      } else {
        message.success({
          content: t("ecomchannelorder:form.success.sync_order_success"),
          className: "message_success",
          key: messageKey,
          duration: 2,
        });

        fetchData();
      }
    },
    [t, messageKey, message, fetchData]
  );

  //Table columns
  const columns: TableColumnsType<EcomChannelOrderModel> = [
    {
      title: t("order:id"),
      key: "invoiceid",
      align: "center",
      width: 100,
      render: (_any, record: EcomChannelOrderModel) => {
        const foundEcomOrderCheck = ecomOrderCheckList.find(
          (i) => `${i.ecom_platform_order_id}` === `${record.id}`
        );

        if (typeof foundEcomOrderCheck === "undefined") {
          return (
            <div>
              <Button
                type="primary"
                onClick={() => synOrder(channel_id, +record.id)}
                size="small"
              >
                {t("ecomchannelorder:sync_order")}
              </Button>
            </div>
          );
        } else {
          return (
            <Link
              to={"/order/edit/id/" + foundEcomOrderCheck.id}
              target="_blank"
              className="text-blue-500"
            >
              {foundEcomOrderCheck.id}
            </Link>
          );
        }
      },
    },
    {
      title: "Channel Order ID",
      key: "id",
      width: 160,
      align: "center",
    },
    {
      title: "Channel Invoice ID",
      key: "invoiceid",
      align: "center",
      width: 140,
      render: (_any, record: EcomChannelOrderModel) => {
        return (
          <div>
            <div className="mb-1">{record.invoiceid}</div>
          </div>
        );
      },
    },
    {
      title: t("ecomchannelorder:info_billing"),
      key: "info_billing",
      width: 200,
      render: (_: any, record: EcomChannelOrderModel) => {
        return (
          <>
            <div>
              <div>
                <span className="block font-bold">
                  {record.billingfullname}
                </span>
                <span className="block">{record.billingphone}</span>
              </div>
              <div className="text-xs text-gray-500">
                <span>{record.billingaddress}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: t("ecomchannelorder:info_shipping"),
      key: "info_shipping",
      width: 200,
      render: (_: any, record: EcomChannelOrderModel) => {
        return (
          <>
            <div>
              <div>
                <span className="font-bold">{record.shippingfullname}</span>
                <span className="block">{record.shippingphone}</span>
              </div>
              <div className="text-xs text-gray-500">
                <span>{record.shippingaddress}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: t("ecomchannelorder:orderdetail"),
      key: "order_detail",
      render: (_: any, record: EcomChannelOrderModel) => (
        <EcomChannelOrderItemLine lineitems={record.lineitems} />
      ),
    },
    {
      title: t("ecomchannelorder:total"),
      width: 120,
      key: "pricefinal",
      align: "right",
      render: (pricefinal: number) => <TextMoney money={pricefinal} />,
    },
    {
      title: t("common:date_created"),
      key: "datecreated",
      width: 150,
      render: (ts: number) => (
        <TextDateTime format="HH:mm, DD/MM/YYYY" ts={ts} />
      ),
    },
  ];

  return (
    <>
      <EcomChannelOrderListHeader channel_id={channel_id} model={model}>
        <></>
      </EcomChannelOrderListHeader>
      <EcomChannelOrderListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterEcomChannelOrder,
        EcomChannelOrderModel,
        EcomChannelOrderCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>
      <Alert
        type="info"
        className="m-6"
        description={t("ecomchannelorder:order_help", {
          channel_title: model.title,
        })}
      />

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default EcomChannelOrderList;
