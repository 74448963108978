import TagTypeList from "features/tagtype/list/TagTypeList";
import usePathParams from "hooks/usePathParams";

const TagTypePage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <TagTypeList />;
  }

  return com;
};

export default TagTypePage;
