import { ActionCode } from "common/types/Delivery";
import create, { StateCreator } from "zustand";
import { persist } from "zustand/middleware";

type PostAction = {
  ActionNow: ActionCode | null;
  setAction: (action: ActionCode) => void;
  reload: boolean;
  setReload: () => void;
};

//init store data
let store: StateCreator<PostAction> = (set) => ({
  ActionNow: null,
  reload: false,
  setReload: () => set((state) => ({ ...state, reload: !state.reload })),
  setAction: (action: ActionCode) =>
    set((state) => ({ ...state, ActionNow: action })),
});

//create store
const usePostActionStore = create(persist(store, { name: "MAIN::Pos" }));

export default usePostActionStore;
