import { Popover } from "antd";
import SiteLanguageRepository from "common/repositories/SiteLanguageRepository";
import emojiFlags from "emoji-flags";
import { useTranslation } from "react-i18next";

import { IconCircleCheck } from "@tabler/icons-react";
import useSiteLanguageStore from "zustands/useSiteLanguageStore";

const HeaderLanguageSwitch = () => {
  const { t } = useTranslation();

  const [language, setLanguage] = useSiteLanguageStore((state) => [
    state.language,
    state.setLanguage,
  ]);

  const dropdownContent = (
    <div className="dropdown-menu dropdown-menu-right">
      <div className="dropdown-header border-b" style={{ marginBottom: 0 }}>
        {t("common:language_switch.header")}
      </div>
      <div className="mt-1">
        {SiteLanguageRepository.getAvailableLanguages().map((lang) => (
          <div
            className="px-4 py-2 cursor-pointer hover:text-blue-600 hover:bg-gray-50 text-xs"
            onClick={() => setLanguage(lang)}
            key={lang.locale}
          >
            {lang.icon} &nbsp; {lang.title}{" "}
            {lang.locale === language?.locale ? (
              <span className="float-right" title="Active">
                <IconCircleCheck color="#389e0d" size="16" />
              </span>
            ) : null}
          </div>
        ))}

        {/* <div className="px-4 py-2   text-gray-400 text-xs" key={"en_US"}>
          {emojiFlags.GB.emoji} &nbsp; {"English (Coming Soon) "}
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="dropdown dropdown-notification mr-3">
      <Popover
        content={dropdownContent}
        trigger={["click"]}
        placement="bottomRight"
      >
        <span className="dropdown-link cursor-pointer rounded-sm text-lg px-1 py-0.5 hover:border-blue-300 hover:text-blue-400">
          {language?.icon}
        </span>
      </Popover>
    </div>
  );
};

export default HeaderLanguageSwitch;
