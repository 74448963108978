import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Popconfirm,
  Row,
  Spin,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useCallback, useMemo, useState } from "react";
import { ActionCode, DeliveryAddEditJson } from "common/types/Delivery";
import useGeoLocation from "hooks/useGeoLocation";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import useFunction from "hooks/useFunction";
import DeliveryMultipleWebCam from "./components/DeliveryMultipleWebcame";
import { File } from "buffer";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import BaseApi from "common/api/BaseApi";
import AxiosClient from "common/api/AxiosClient";
import axios from "axios";
import usePathParams from "hooks/usePathParams";
import FileModel from "common/models/FileModel";
import DeliveryModel from "common/models/DeliveryModel";
import useLoginAccountStore from "zustands/useLoginAccountStore";

type DataSyncPostAction = {
  image_ids: number[];
  formData: Pick<DeliveryAddEditJson, "note">;
};

type AnotherPostAction = {
  action: ActionCode;
  data: Omit<
    DeliveryAddEditJson,
    "location_latitude" | "location_longitude" | "action_code"
  >;
};
type Props = {
  gpsRequire: boolean;
  noteRequire: boolean;
  uploadRequire: boolean;
  action: ActionCode;
  data: Omit<
    DeliveryAddEditJson,
    "location_latitude" | "location_longitude" | "action_code"
  >;
  anotherPostAction?: AnotherPostAction[];
  isShowNote?: boolean;
  defaultFacingMode?: "user" | "environment";
  handlePostActionSuccess?: () => void;
  onComponentFormSubmit?: () => boolean;
};
const PostAction = ({
  gpsRequire,
  action,
  noteRequire,
  uploadRequire,
  data,
  anotherPostAction,
  isShowNote = true,
  defaultFacingMode,
  handlePostActionSuccess,
  onComponentFormSubmit,
}: Props) => {
  const [form] = useForm();
  const [pathParams] = usePathParams();
  const { location } = useGeoLocation();
  const { convertBast64ToFile } = useFunction();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const loginAccount = useLoginAccountStore((state) => state.account);

  //state
  const [enableWebcam, setEnaleWebcam] = useState(true);
  const [images, setImages] = useState<string[]>([]);
  const [imgFile, setImgFile] = useState<any[]>([]);
  const [pending, setPending] = useState<boolean>(false);
  const [cameraPending, setCameraPending] = useState<boolean>(false);
  const [dataSyncPostAction, setDataSyncPostAction] =
    useState<DataSyncPostAction | null>(null);
  //
  const jwtError = "error_jwt_hash_not_found";

  //function
  const handleSubmit = async (formData: Pick<DeliveryAddEditJson, "note">) => {
    setPending(true);

    if (typeError === "no_error") {
      let image_ids: number[] = [];

      if (imgFile.length > 0) {
        let ImagePromises: Promise<FileModel>[] = [];

        for (let i = 0; i < imgFile.length; i++) {
          ImagePromises.push(
            new Promise(async (resolve, reject) => {
              const res = await handlePostImage(imgFile[i]);
              resolve(res);
            })
          );
        }

        Promise.all(ImagePromises).then((res) => {
          if (res.some((i) => i.hasError())) {
            // message.error({
            //   content: t("delivery:button_action.notification.error.title"),
            //   children: t(
            //     "delivery:button_action.notification.error.description"
            //   ),
            // });
            setDataSyncPostAction({ formData, image_ids });
          } else {
            res.forEach((i) => {
              image_ids.push(i.id);
            });

            handlePostAction({ image_ids: image_ids, formData: formData });
          }
        });
        // .catch(() => {})
        // .finally(async () => {
        //   handlePostAction({ image_ids: image_ids, formData: formData });
        // });
      } else {
        handlePostAction({ image_ids: image_ids, formData: formData });
      }

      // await Helper.delay(15000); // Dừng 15 giây trước khi tiếp tục vòng lặp
    } else {
      setPending(false);
      setCameraPending(false);
      openNotificationWithIcon(
        "warning",
        "Có lỗi rồi !",
        t(`delivery:button_action.notification.error.${typeError}`)
      );
      return;
    }
  };
  const handlePostImage = async (file: Blob): Promise<FileModel> => {
    setPending(true);
    setCameraPending(true);
    let item = new FileModel(FileModel.getDefaultData());
    if (!uploadRequire) {
      return item;
    } else {
      const formData = new FormData();
      const companyId =
        loginAccount.company.id > 0 ? loginAccount.company.id : 0;
      formData.append("company_id", companyId.toString());
      formData.append("creator_id", JSON.stringify(loginAccount.id));
      formData.append("directory_id", "0");
      formData.append("is_directory", "0");
      formData.append("status", "1");
      formData.append("object_type", "31");
      formData.append("object_id", "1");
      formData.append("origin", "company");
      formData.append("file", file);

      // const response = async () => {
      try {
        const res = await axios.post(
          AxiosClient().defaults.baseURL + "/files",
          formData,
          {
            headers: {
              Authorization: BaseApi.loginAccount.jwt,
              // ContentType: "multipart/form-data",
            },
          }
        );
        if (res) {
          item = new FileModel(res.data);
        }
      } catch (error) {
        item.withError(BaseApi.handleError(error));
      }

      return item;
    }
  };

  const handlePostAction = async ({
    image_ids,
    formData,
  }: {
    image_ids?: number[];
    formData: Pick<DeliveryAddEditJson, "note">;
  }) => {
    const response = await new DeliveryRepository().addAction({
      ...data,
      deliveries_id: data.deliveries_id || Number(pathParams.id),
      action_code: action,
      // image: uploadRequire === "uploadRequire" ? res.data.id : 0,
      image:
        data.image && data.image?.length > 0
          ? data.image
          : image_ids
          ? image_ids
              .map((item) => item)
              .join(",")
              .toString()
          : undefined,
      location_latitude: gpsRequire ? location.coordinates.lat : 0,
      location_longitude: gpsRequire ? location.coordinates.lng : 0,
      note: getNote(formData.note || ""),
    });
    if (!response.hasError()) {
      if (anotherPostAction && anotherPostAction.length > 0) {
        const anotherActionPromise: Promise<DeliveryModel>[] = [];
        for (const action of anotherPostAction) {
          anotherActionPromise.push(
            new Promise(async (resolve) => {
              const res = await new DeliveryRepository().addAction({
                deliveries_id: action.data.deliveries_id,
                action_code: action.action,
                image: action.data.image || undefined,
                location_latitude: gpsRequire ? location.coordinates.lat : 0,
                location_longitude: gpsRequire ? location.coordinates.lng : 0,
                note: action.data.note || "",
              });
              resolve(res);
            })
          );
        }
        Promise.all(anotherActionPromise).then((res) => {
          if (res.some((i) => i.hasError())) {
            const resError = res.filter((i) => i.hasError());
            const errors = [
              ...new Set(resError.map((i) => i.error.errors).flat()),
            ];
            openNotificationWithIcon(
              "error",
              t("delivery:button_action.notification.error.title"),
              errors.includes(jwtError)
                ? t("delivery:list.item.error.error_jwt_hash_not_found")
                : t(`delivery:button_action.notification.error.${errors[0]}`)
            );
          }
        });
      }
      handlePostActionSuccess && handlePostActionSuccess();
    } else {
      openNotificationWithIcon(
        "error",
        t("delivery:button_action.notification.error.title"),
        response.error.errors[0] === jwtError
          ? t("delivery:list.item.error.error_jwt_hash_not_found")
          : t(
              `delivery:button_action.notification.error.${response.error.errors[0]}`
            )
      );
    }
    setPending(false);
    setCameraPending(false);
  };

  const handleSyncPostAction = async () => {
    if (dataSyncPostAction !== null) {
      const imgs = dataSyncPostAction.image_ids!;
      const data = dataSyncPostAction.formData!;
      await handlePostAction({ image_ids: imgs, formData: data });
    }
  };

  const handleChangeImg = useCallback(
    (imgBase64s: string[]) => {
      if (images.length < 5) {
        setImages(imgBase64s);
        let files: any = [];
        for (let i = 0; i < imgBase64s.length; i++) {
          files.push(
            convertBast64ToFile(
              imgBase64s[i],
              `image_${i}_${Math.floor(
                Math.random() * (999999 - 0 + 1) + 0
              ).toString()}.jpg`
            )
          );
        }

        setImgFile(files);
      } else {
        message.open({
          type: "error",
          content: "Bạn đã chụp đủ ảnh !",
          duration: 3,
        });
      }
    },
    [images]
  );

  const openNotificationWithIcon = (
    type: "error" | "info" | "success" | "warning" | "loading",
    message: string = "",
    title: string = ""
  ) => {
    messageApi.open({
      type: type,
      content: t(`${title}`),
      duration: 10,
    });
  };

  const getNote = (note: string | undefined) => {
    const noteForm = note || "";
    const noteData = data.note || "";
    switch (action) {
      case "ACTION_ADDRESS":
        return note + "|" + noteData;
      case "ACTION_RECEIVE_COD":
        return noteData;
      case "ACTION_APPROVE_TRANSFERS":
        return noteData;
      case "ACTION_APPROVE_RECEIVE_COD":
        return noteData;
      default:
        return noteForm;
    }
  };

  //process

  const typeError = useMemo(() => {
    if (
      noteRequire &&
      gpsRequire &&
      uploadRequire &&
      location.isError &&
      imgFile.length <= 0
    ) {
      return "error_all";
    } else if (gpsRequire && location.isError) {
      return "error_gps";
    } else {
      return "no_error";
    }
  }, [gpsRequire, uploadRequire, noteRequire, imgFile, location]);

  const isConfirmSyncPostAction = useMemo(() => {
    if (dataSyncPostAction === null) {
      return false;
    } else {
      return true;
    }
  }, [dataSyncPostAction]);
  return (
    <div className="w-full">
      {contextHolder}
      <Spin spinning={pending} size="small">
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            {enableWebcam && uploadRequire && (
              <DeliveryMultipleWebCam
                changeImgSrc={handleChangeImg}
                enableWebcam={enableWebcam}
                pending={cameraPending}
                imgScreenshotSrc={images}
                maxNumberImg={5}
                defaultFacingMode={defaultFacingMode}
              />
            )}
          </Row>
          {/* {noteRequire && ( */}
          {isShowNote && (
            <Row>
              <Col span={24}>
                <Form.Item
                  name="note"
                  rules={[
                    {
                      required: noteRequire,
                      message: "Vui lòng nhập.",
                    },
                  ]}
                >
                  <TextArea
                    className="w-full"
                    rows={3}
                    placeholder="Ghi chú"
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* )} */}
          <Row>
            <Col span={24}>
              <Popconfirm
                placement="top"
                title={t(`delivery:action.confirm.${action}`)}
                onConfirm={() => {
                  if (onComponentFormSubmit) {
                    if (onComponentFormSubmit()) {
                      form.submit();
                    }
                  } else {
                    form.submit();
                  }
                }}
                okButtonProps={{
                  style: {
                    width: "100%",
                    height: "2rem",
                    display: "block",

                    marginLeft: 0,
                  },
                }}
                cancelButtonProps={{
                  style: {
                    width: "100%",
                    height: "2rem",
                    display: "block",
                    marginBottom: "8px",
                    marginLeft: 0,
                  },
                }}
              >
                <Button
                  loading={pending}
                  disabled={pending}
                  block
                  htmlType="button"
                >
                  Xác nhận
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Modal
        key="upload"
        open={isConfirmSyncPostAction}
        closable={false}
        onCancel={() => {
          setPending(false);
          setDataSyncPostAction(null);
        }}
        maskClosable={false}
        onOk={handleSyncPostAction}
      >
        <div className="w-full min-w-[325px]">
          <Alert message="Gặp lỗi trong quá trình upload ! Bạn vẫn muốn xác nhận hàng động này chứ."></Alert>
        </div>
      </Modal>
    </div>
  );
};

export default PostAction;
