import Company from "common/constants/Company";
import CompanyPlanCheck from "components/CompanyPlanCheck";
import ApiKeyList from "features/apikey/list/ApiKeyList";
import usePathParams from "hooks/usePathParams";
import React from "react";

const ApiKeyPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    default:
      com = (
        <CompanyPlanCheck minPlan={Company.PLAN_02} prefix="API Key">
          <ApiKeyList />
        </CompanyPlanCheck>
      );
  }

  return com;
};

export default ApiKeyPage;
