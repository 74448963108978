import { Button } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const InfinitePagination = ({
  className,
  size,
  current,
  onChange,
  isContinue,
  hideOnSinglePage,
  hidePage,
}: {
  className: string;
  size: SizeType;
  current: number;
  onChange: (n: number) => void;
  isContinue: boolean;
  hideOnSinglePage: boolean;
  hidePage?: boolean;
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(current || 1);

  const goToPage = useCallback(
    (index: number) => {
      setPage(page + index);
    },
    [page]
  );

  useEffect(() => {
    onChange(page);
  }, [page, onChange]);

  return typeof hideOnSinglePage !== "undefined" &&
    hideOnSinglePage &&
    page === 1 &&
    !isContinue ? null : (
    <div className={className}>
      <Button
        disabled={page === 1}
        size={size}
        onClick={() => goToPage(-1)}
        icon={<IconChevronLeft size="14" />}
      ></Button>
      {typeof hidePage !== "undefined" && hidePage ? (
        <span className="mg-x-5"></span>
      ) : (
        <span className="mg-x-10" style={{ opacity: 0.5 }}>
          {size === "small" ? (
            <small>
              {t("common:pagination.page")} {page}
            </small>
          ) : (
            <>
              {t("common:pagination.page")} {page}
            </>
          )}
        </span>
      )}
      <Button
        disabled={!isContinue}
        size={size}
        onClick={() => goToPage(1)}
        icon={<IconChevronRight size="14" />}
      ></Button>
    </div>
  );
};

export default InfinitePagination;
