import BaseCollection from 'common/collections/BaseCollection';
import EcomChannelProductModel from 'common/models/EcomChannelProductModel';
import {
    EcomChannelProductCollectionJson, EcomChannelProductJson
} from 'common/types/EcomChannelProduct';

class EcomChannelProductCollection extends BaseCollection<
  EcomChannelProductJson,
  EcomChannelProductModel,
  EcomChannelProductCollectionJson
> {
  itemsFromJson(
    jsonItems: EcomChannelProductJson[]
  ): EcomChannelProductModel[] {
    return jsonItems.map((item) => new EcomChannelProductModel(item));
  }
}

export default EcomChannelProductCollection;
