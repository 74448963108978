import BaseCollection from "./BaseCollection";

import { TagTypeCollectionJson, TagTypeJson } from "common/types/TagType";
import TagTypeModel from "common/models/TagTypeModel";

class TagTypeCollection extends BaseCollection<
  TagTypeJson,
  TagTypeModel,
  TagTypeCollectionJson
> {
  itemsFromJson(jsonItems: TagTypeJson[]): TagTypeModel[] {
    return jsonItems.map((item) => new TagTypeModel(item));
  }
}
export default TagTypeCollection;
