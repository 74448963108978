import Customer from "common/constants/Customer";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { Birthday, CustomerJson } from "common/types/Customer";

class CustomerModel extends BaseModel implements BaseModelJson<CustomerJson> {
  creator_id: number;
  company_id: number;
  id: number;
  customer_type_id: number;
  code: string;
  full_name: string;
  gender: number;
  birthday: Birthday;
  email: string;
  phone: string;
  address: string;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
  note: string;
  count_order: number;
  point: number;
  money: number;
  ignore_auto_upgrade: number;
  tax_buyer_name: string;
  tax_buyer_address: string;
  tax_buyer_phone: string;
  tax_buyer_account_number: string;
  tax_buyer_tax_number: string;
  date_created: number;
  date_modified: number;
  date_first_ordered: number;
  date_last_ordered: number;

  constructor(json: CustomerJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.customer_type_id = json.customer_type_id || 0;
    this.code = json.code || "";
    this.full_name = json.full_name || "";
    this.gender = json.gender || 0;
    this.birthday = json.birthday || "";
    this.email = json.email || "";
    this.phone = json.phone || "";
    this.address = json.address || "";
    this.region_id = json.region_id || 0;
    this.sub_region_id = json.sub_region_id || 0;
    this.sub_sub_region_id = json.sub_sub_region_id || 0;
    this.note = json.note || "";
    this.count_order = json.count_order || 0;
    this.point = json.point || 0;
    this.money = json.money || 0;
    this.ignore_auto_upgrade = json.ignore_auto_upgrade || 0;
    this.tax_buyer_name = json.tax_buyer_name || "";
    this.tax_buyer_address = json.tax_buyer_address || "";
    this.tax_buyer_phone = json.tax_buyer_phone || "";
    this.tax_buyer_account_number = json.tax_buyer_account_number || "";
    this.tax_buyer_tax_number = json.tax_buyer_tax_number || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_first_ordered = json.date_first_ordered || 0;
    this.date_last_ordered = json.date_last_ordered || 0;
  }

  static getDefaultData(): CustomerJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      customer_type_id: 0,
      code: "",
      full_name: "",
      gender: Customer.GENDER_UNKNOWN,
      birthday: "",
      email: "",
      phone: "",
      address: "",
      region_id: 0,
      sub_region_id: 0,
      sub_sub_region_id: 0,
      note: "",
      count_order: 0,
      point: 0,
      money: 0,
      ignore_auto_upgrade: 0,
      tax_buyer_name: "",
      tax_buyer_address: "",
      tax_buyer_phone: "",
      tax_buyer_account_number: "",
      tax_buyer_tax_number: "",
      date_created: 0,
      date_modified: 0,
      date_first_ordered: 0,
      date_last_ordered: 0,
    };
  }

  toJson(): CustomerJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      customer_type_id: this.customer_type_id,
      code: this.code,
      full_name: this.full_name,
      gender: this.gender,
      birthday: this.birthday,
      email: this.email,
      phone: this.phone,
      address: this.address,
      region_id: this.region_id,
      sub_region_id: this.sub_region_id,
      sub_sub_region_id: this.sub_sub_region_id,
      note: this.note,
      count_order: this.count_order,
      point: this.point,
      money: this.money,
      ignore_auto_upgrade: this.ignore_auto_upgrade,
      tax_buyer_name: this.tax_buyer_name,
      tax_buyer_address: this.tax_buyer_address,
      tax_buyer_phone: this.tax_buyer_phone,
      tax_buyer_account_number: this.tax_buyer_account_number,
      tax_buyer_tax_number: this.tax_buyer_tax_number,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_first_ordered: this.date_first_ordered,
      date_last_ordered: this.date_last_ordered,
    };
  }

  static getGenderList(): SelectOption[] {
    return [
      {
        value: Customer.GENDER_UNKNOWN,
        label: "--",
      },
      {
        value: Customer.GENDER_FEMALE,
        label: i18n.t("common:gender_female"),
      },
      {
        value: Customer.GENDER_MALE,
        label: i18n.t("common:gender_male"),
      },
      // {
      //   value: Customer.GENDER_OTHER,
      //   label: i18n.t("common:gender_other"),
      // },
    ];
  }

  static getGender(value: number): SelectOption | undefined {
    return this.getGenderList().find((item) => item.value === value);
  }
}

export default CustomerModel;
