import { useTranslation } from "react-i18next";

import PageHeader from "components/page/PageHeader";

const PhoneBookListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("sitemenu:phone_book")}
      siteMenuOpenKey="hrm"
      siteMenuSelectedKey="/phonebook"
    ></PageHeader>
  );
};

export default PhoneBookListHeader;
