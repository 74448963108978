import { Image } from "antd";
import FileModel from "common/models/FileModel";
import useGetfilesByIds from "hooks/useGetfilesByIds";
import React, { useEffect, useState } from "react";

export default function ProductImageByIdFile({ ids }: { ids: number[] }) {
  const [files, setFiles] = useState<FileModel[]>([]);
  const { files: filess } = useGetfilesByIds(ids, {
    origin: "product",
  });

  useEffect(() => {
    if (filess) {
      const files = ids
        .map((file : any) => {
          return filess[file];
        })
        .filter(Boolean) as FileModel[];
      setFiles(files);
    }
  }, [filess, ids]);

  return (
    <div className=" flex gap-2">
      {files.map((file) => {
        return <Image src={file.url} title={file.title} />;
      })}
    </div>
  );
}
