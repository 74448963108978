import { Button, Form, Input, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyOutlined } from '@ant-design/icons';

const EcomEditFormWooCommerce = () => {
  const hookUrl = process.env.REACT_APP_BASE_URL + "/ecomwoocommerce/webhook";
  const { t } = useTranslation();
  const [textToltip, setTextToltip] = useState("hook_url_copy");
  const copyToClipboard = () => {
    navigator.clipboard.writeText(hookUrl);
    setTextToltip("hook_url_copyed");
  };
  return (
    <>
      <Form.Item
        label={t("ecom:form.hook_secret")}
        name="hook_secret"
        labelAlign="right"
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={t("ecom:form.hook_url")}
        name="webhook_url"
        labelAlign="right"
      >
        <Input.Group compact>
          <Input
            style={{ width: "calc(100% - 31px)" }}
            readOnly
            defaultValue={hookUrl}
          />
          <Tooltip title={t(`ecom:form.${textToltip}`)} mouseEnterDelay={0.4}>
            <Button onClick={copyToClipboard} icon={<CopyOutlined />} />
          </Tooltip>
        </Input.Group>
      </Form.Item>
    </>
  );
};

export default EcomEditFormWooCommerce;
