import EcomUrlModel from 'common/models/EcomUrlModel';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

const SERVICE_URL = "/ecomplatformurls";

class EcomUrlApi extends BaseApi {
  async getOauthUrl(type: number): Promise<EcomUrlModel> {
    let item = new EcomUrlModel(EcomUrlModel.getDefaultData());

    try {
      let queryData = {
        params: {
          type,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        item = new EcomUrlModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default EcomUrlApi;
