import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  BoardType,
  CardBag,
  CardType,
  ColumnType,
  DestinationType,
  SourceType,
  moveCard,
} from "@asseinfo/react-kanban";

import {
  Badge,
  Button,
  Col,
  Collapse,
  Dropdown,
  Menu,
  MenuProps,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import TaskCollection from "common/collections/TaskCollection";
import Task from "common/constants/Task";
import TaskModel from "common/models/TaskModel";
import TaskRepository from "common/repositories/TaskRepository";
import { FilterTask } from "common/types/Task";
import TextDateTime from "components/TextDateTime";
import Kanban from "components/kanban/Kanban";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// import "../../kanban/TaskPublicUserKanban.css";
import { EmployeeJson } from "common/types/Employee";
import TableDelete from "components/table/TableDelete";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import {
  IconChevronLeft,
  IconChevronRight,
  IconDotsVertical,
  IconInfoCircle,
} from "@tabler/icons-react";
import TaskFormModal from "features/task/mine/kanban/components/TaskFormModal";
import TaskDeptKanbanFilter from "./TaskDeptKanbanFilter";

export type SelectOptionTask = {
  value: string;
  label: string;
  parent_id?: number;
  disabled?: boolean;
};

const TaskDeptKanbanComponent = ({
  ids_list,
  list_employee,
}: {
  ids_list: number[];
  list_employee: EmployeeJson[];
}) => {
  const { t } = useTranslation();
  const { account } = useLoginAccountStore();
  const intialBoard: BoardType = {
    columns: [
      // {
      //   id: 5,
      //   title: "BACKLOG",
      //   cards: [],
      // },
      {
        id: 0,
        title: "TODO",
        cards: [],
      },
      {
        id: 2,
        title: "INPROGRESS",
        cards: [],
      },
      {
        id: 1,
        title: "PENDING",
        cards: [],
      },
      {
        id: 3,
        title: "DONE",
        cards: [],
      },
    ],
  };

  const [board, setBoard] = useState<BoardType>(intialBoard);

  //state
  const [loading, setLoading] = useState<boolean>(false);

  const [currentColumn, setCurrentColumn] = useState<number[]>([-1, -1]);

  //filters
  const defaultFilters: any = useMemo(() => {
    return {
      ...TaskRepository.getDefaultFilter(),
      parent_id: -1,
      list_employee_id: ids_list.join(","),
    };
  }, []);
  const [filters, setFilters] = useStateFilter<FilterTask>(defaultFilters);

  // useFilterLocation(defaultFilters, filters);

  const [filtersTodo, setFiltersTodo] =
    useStateFilter<FilterTask>(defaultFilters);
  const [filtersInprogress, setFiltersInProgress] =
    useStateFilter<FilterTask>(defaultFilters);
  const [filtersDone, setFiltersDone] =
    useStateFilter<FilterTask>(defaultFilters);
  const [filtersPending, setFiltersPending] =
    useStateFilter<FilterTask>(defaultFilters);

  useEffect(() => {
    setFiltersTodo({ ...filters });
    setFiltersInProgress({ ...filters });
    setFiltersPending({ ...filters });
    setFiltersDone({ ...filters });
  }, [filters]);

  useEffect(() => {
    if (ids_list.length > 0) {
      fetchDataTodo();
    }
  }, [filtersTodo, ids_list]);

  useEffect(() => {
    if (ids_list.length > 0) {
      fetchDataInprogress();
    }
  }, [filtersInprogress, ids_list]);

  useEffect(() => {
    if (ids_list.length > 0) {
      fetchDataDone();
    }
  }, [filtersDone, ids_list]);

  useEffect(() => {
    if (ids_list.length > 0) {
      fetchDataPending();
    }
  }, [filtersPending, ids_list]);

  //TODO
  const [dataSourceTodo, setDataSourceTodo] = useState<CardType[]>([]);
  const [endPageTodo, setEndPageTodo] = useState<boolean>(false);
  const [totalTodo, setTotalTodo] = useState<number>(0);

  const fetchDataTodo = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);
    let collection = await new TaskRepository().getItems({
      filters: {
        ...filtersTodo,
        limit: 20,
        sortby: "full_display_order",
        sorttype: "ASC",
        status: Task.STATUS.TODO,
        no_parent: 1,
        list_employee_id:
          filters.list_employee_id && filters.list_employee_id.length > 0
            ? filters.list_employee_id
            : ids_list.join(","),
      },
    });

    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("TODO", board, newArr);
      setDataSourceTodo(newArr);
      setTotalTodo(collection.total);
      setEndPageTodo(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersTodo, ids_list]);

  //DONE
  const [dataSourceDone, setDataSourceDone] = useState<CardType[]>([]);
  const [endPageDone, setEndPageDone] = useState<boolean>(false);
  const [totalDone, setTotalDone] = useState<number>(0);

  const fetchDataDone = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);

    let collection = await new TaskRepository().getItems({
      filters: {
        ...filtersDone,
        limit: 20,
        sortby: "full_display_order",
        sorttype: "ASC",
        status: Task.STATUS.DONE,
        no_parent: 1,
        list_employee_id:
          filters.list_employee_id && filters.list_employee_id.length > 0
            ? filters.list_employee_id
            : ids_list.join(","),
      },
    });
    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("DONE", board, newArr);
      setDataSourceDone(newArr);
      setEndPageDone(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setTotalDone(collection.total);
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersDone, ids_list]);

  //Pending
  const [dataSourcePending, setDataSourcePending] = useState<CardType[]>([]);
  const [endPagePending, setEndPagePending] = useState<boolean>(false);
  const [totalPending, setTotalPending] = useState<number>(0);

  const fetchDataPending = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);

    let collection = await new TaskRepository().getItems({
      filters: {
        ...filtersPending,
        limit: 20,
        sortby: "full_display_order",
        sorttype: "ASC",
        status: Task.STATUS.PENDING,
        no_parent: 1,
        list_employee_id:
          filters.list_employee_id && filters.list_employee_id.length > 0
            ? filters.list_employee_id
            : ids_list.join(","),
      },
    });
    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("PENDING", board, newArr);
      setDataSourcePending(newArr);
      setEndPagePending(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setTotalPending(collection.total);
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersPending, ids_list]);

  //Inprogress
  const [dataSourceInprogress, setDataSourceInprogess] = useState<CardType[]>(
    []
  );
  const [endPageInprogress, setEndPageInprogress] = useState<boolean>(false);
  const [totalInprogress, setTotalInprogress] = useState<number>(0);

  const fetchDataInprogress = useCallback(async (): Promise<TaskCollection> => {
    setLoading(true);
    let collection = await new TaskRepository().getItems({
      filters: {
        ...filtersInprogress,
        limit: 20,
        sortby: "full_display_order",
        sorttype: "ASC",
        status: Task.STATUS.INPROGRESS,
        no_parent: 1,
        list_employee_id:
          filters.list_employee_id && filters.list_employee_id.length > 0
            ? filters.list_employee_id
            : ids_list.join(","),
      },
    });
    if (collection.error.errors.length > 0) {
      setLoading(false);

      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      const newArr = collection.items.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.parent_id.toString(),
        display_order: item.display_order,
        task_model: item,
      }));
      const updatedBoard = updateTodoColumn("INPROGRESS", board, newArr);
      setDataSourceInprogess(newArr);
      setEndPageInprogress(
        collection.currentpage * Number(filters.limit) >= collection.total
      );
      setTotalInprogress(collection.total);
      setBoard(updatedBoard);
      setLoading(false);
    }

    return collection;
  }, [filtersInprogress, ids_list]);

  const updateTodoColumn = (
    columnName: string,
    board: BoardType,
    dataSourceTodo: CardType[]
  ) => {
    const newBoard = { ...board };

    const todoColumnIndex = newBoard.columns.findIndex(
      (column) => column.title === columnName
    );

    if (todoColumnIndex !== -1) {
      const updatedTodoColumn = { ...newBoard.columns[todoColumnIndex] };

      updatedTodoColumn.cards = dataSourceTodo;

      newBoard.columns[todoColumnIndex] = updatedTodoColumn;
    }

    return newBoard;
  };

  const fetchData = (columnID: number) => {
    if (columnID === Task.STATUS.TODO) {
      fetchDataTodo();
    } else if (columnID === Task.STATUS.PENDING) {
      fetchDataPending();
    } else if (columnID === Task.STATUS.DONE) {
      fetchDataDone();
    } else if (columnID === Task.STATUS.INPROGRESS) {
      fetchDataInprogress();
    } else if (columnID === Task.STATUS.BACKLOG) {
      // fetchDataBacklog();
    }
  };

  const handleCardMove = async (
    card: any,
    source: SourceType,
    destination: DestinationType
  ) => {
    setLoading(true);
    const { card1, card2 } = findItemSwap(source, destination);

    if (card1 && card2) {
      const updatedBoard = moveCard(board, source, destination);
      setBoard(updatedBoard);
      if (source.fromColumnId !== destination.toColumnId) {
        const myObj: TaskModel =
          await new TaskRepository().editKanbanDepartmentPosition(
            card.id,
            destination.toColumnId,
            card2.task_model?.full_display_order || 0
          );
        if (!myObj.hasError()) {
          message.success({
            content: "Thay đổi thành công !",
            className: "message_success",
            key: "message",
            duration: 2,
          });
          fetchDataAll();
        } else {
          // const updatedBoard = moveCard(
          //   board,
          //   {
          //     fromColumnId: destination.toColumnId,
          //     fromPosition: destination.toPosition,
          //   },
          //   { toColumnId: source.fromColumnId, toPosition: source.fromPosition }
          // );
          // setBoard(updatedBoard);
          fetchDataAll();
          message.error({
            content: t(`task:form.error.${myObj.error.errors[0]}`),
            className: "message_error",
            key: "message",
            duration: 4,
          });
        }
      } else {
        const updatedBoard = moveCard(board, source, destination);
        setBoard(updatedBoard);
        await new TaskRepository()
          .editFullDisplayOrder(
            card1.id,
            card2.task_model?.full_display_order || 0
          )
          .then((res) => {
            if (res.hasError()) {
              // const updatedBoard = moveCard(
              //   board,
              //   {
              //     fromColumnId: destination.toColumnId,
              //     fromPosition: destination.toPosition,
              //   },
              //   {
              //     toColumnId: source.fromColumnId,
              //     toPosition: source.fromPosition,
              //   }
              // );
              // setBoard(updatedBoard);
              fetchDataAll();
              message.error({
                content: t(`task:form.error.${res.error.errors[0]}`),
                className: "message_error",
                key: "message",
                duration: 4,
              });
            } else {
              message.success({
                content: "Thay đổi thành công !",
                className: "message_success",
                key: "message",
                duration: 2,
              });

              fetchDataAll();
            }
          })
          .catch((err) => {
            message.error({
              content: t(`task:form.error.${err[0]}`),
              className: "message_error",
              key: "message",
              duration: 4,
            });
            fetchDataAll();
            // const updatedBoard = moveCard(
            //   board,
            //   {
            //     fromColumnId: destination.toColumnId,
            //     fromPosition: destination.toPosition,
            //   },
            //   {
            //     toColumnId: source.fromColumnId,
            //     toPosition: source.fromPosition,
            //   }
            // );
            // setBoard(updatedBoard);
          });
      }
    } else if (card1) {
      const updatedBoard = moveCard(board, source, destination);
      setBoard(updatedBoard);

      if (source.fromColumnId !== destination.toColumnId) {
        const myObj: TaskModel = await new TaskRepository().editStatus(
          destination.toColumnId,
          card.id
        );
        if (!myObj.hasError()) {
          message.success({
            content: "Thay đổi thành công !",
            className: "message_success",
            key: "message",
            duration: 2,
          });

          // const updatedBoard = moveCard(board, source, destination);
          // setBoard(updatedBoard);
          fetchDataAll();
        } else {
          message.error({
            content: t(`task:form.error.${myObj.error.errors[0]}`),
            className: "message_error",
            key: "message",
            duration: 4,
          });
          fetchDataAll();
          // const updatedBoard = moveCard(
          //   board,
          //   {
          //     fromColumnId: destination.toColumnId,
          //     fromPosition: destination.toPosition,
          //   },
          //   { toColumnId: source.fromColumnId, toPosition: source.fromPosition }
          // );
          // setBoard(updatedBoard);
        }
      }
    } else {
      message.error({
        content: t(`Thay đổi thất bại`),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    }
    setLoading(false);
  };

  const findItemSwap = (source: SourceType, destination: DestinationType) => {
    const columnId1 = source.fromColumnId;
    const columnId2 = destination.toColumnId;

    const newIndexInColumn1 = source.fromPosition;
    const newIndexInColumn2 = destination.toPosition;

    const column1 = board.columns.find((column) => column.id === columnId1);
    const column2 = board.columns.find((column) => column.id === columnId2);

    if (column1 && column2) {
      const card1 = column1.cards[newIndexInColumn1];
      const card2 = column2.cards[newIndexInColumn2];

      return { card1: card1, card2: card2 };
    } else {
      return { card1: undefined, card2: undefined };
    }
  };

  //TaskModal
  //setId for Modal
  const [showModal, setShowModal] = useState(false);
  const [taskId, setTaskId] = useState<number>(0);
  const handleClickCard = (id: number) => {
    setTaskId(id);
  };

  const onSaveSuccess = (item: TaskModel) => {
    fetchDataAll();
  };

  const fetchDataAll = () => {
    fetchDataTodo();
    fetchDataDone();
    fetchDataInprogress();
    fetchDataPending();
  };

  const style = {
    icon: {
      fontSize: "8px",
      width: "14px",
      height: "14px",
    },
  };

  const renderColor = (status: number) => {
    let color = "#87e8de";
    switch (status) {
      case 0:
        color = "#87e8de";
        break;
      case 1:
        color = "#096dd9";
        break;
      case 2:
        color = "#c41d7f";
        break;
      case 3:
        color = "#389e0d";
        break;

      default:
        break;
    }
    return color;
  };

  const Deviation = ({ deviation }: { deviation: number }) => {
    const day = Math.floor(Math.abs(deviation / (3600 * 24)));
    const houre = Math.floor(Math.abs(deviation / 3600) % 24);
    const minutes = Math.round(
      Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
    );

    const is_deviation = (day || houre || minutes) !== 0;
    return (
      <>
        {is_deviation ? (
          <Tag
            color={deviation > 0 ? "volcano" : "green"}
            style={{ margin: 0, height: "22px" }}
          >
            {day > 0 && day + " ngày "}
            {houre > 0 && houre + " giờ "}
            {minutes > 0 && minutes + " phút"}
            {/* {minutes} */}{" "}
            {is_deviation ? (
              deviation > 0 ? (
                <ArrowDownOutlined style={{ color: "#cf1322" }} />
              ) : (
                <ArrowUpOutlined style={{ color: "#3f8600" }} />
              )
            ) : null}
          </Tag>
        ) : (
          <Tag style={{ margin: 0, height: "22px" }}>-</Tag>
        )}
      </>
    );
  };

  const getPagination = (column: ColumnType) => {
    if (column.title === "TODO") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            disabled={filtersTodo.page === 1}
            style={{ height: "22px", width: "32px" }}
            onClick={() => {
              setFiltersTodo({
                ...filtersTodo,
                page: Number(filtersTodo.page) - 1,
              });
            }}
          >
            <IconChevronLeft />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPageTodo}
            onClick={() => {
              setFiltersTodo({
                ...filtersTodo,
                page: Number(filtersTodo.page) + 1,
              });
            }}
          >
            <IconChevronRight />
          </Button>
        </div>
      );
    } else if (column.title === "INPROGRESS") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={filtersInprogress.page === 1}
            onClick={() => {
              setFiltersInProgress({
                ...filtersInprogress,
                page: Number(filtersInprogress.page) - 1,
              });
            }}
          >
            <IconChevronLeft />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPageInprogress}
            onClick={() => {
              setFiltersInProgress({
                ...filtersInprogress,
                page: Number(filtersInprogress.page) + 1,
              });
            }}
          >
            <IconChevronRight />
          </Button>
        </div>
      );
    } else if (column.title === "PENDING") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={filtersPending.page === 1}
            onClick={() => {
              setFiltersPending({
                ...filtersPending,
                page: Number(filtersPending.page) - 1,
              });
            }}
          >
            <IconChevronLeft />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPagePending}
            onClick={() => {
              setFiltersPending({
                ...filtersPending,
                page: Number(filtersPending.page) + 1,
              });
            }}
          >
            <IconChevronRight />
          </Button>
        </div>
      );
    } else if (column.title === "DONE") {
      return (
        <div className="flex gap-2">
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={filtersDone.page === 1}
            onClick={() => {
              setFiltersDone({
                ...filtersDone,
                page: Number(filtersDone.page) - 1,
              });
            }}
          >
            <IconChevronLeft />
          </Button>
          <Button
            className="p-0 outline-none flex justify-center items-center"
            style={{ height: "22px", width: "32px" }}
            disabled={endPageDone}
            onClick={() => {
              setFiltersDone({
                ...filtersDone,
                page: Number(filtersDone.page) + 1,
              });
            }}
          >
            <IconChevronRight />
          </Button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getTotalNumber = (column: ColumnType) => {
    if (column.title === "TODO") {
      return totalTodo;
    } else if (column.title === "INPROGRESS") {
      return totalInprogress;
    } else if (column.title === "PENDING") {
      return totalPending;
    } else if (column.title === "DONE") {
      return totalDone;
    } else {
      return totalTodo + totalInprogress + totalPending + totalDone;
    }
  };

  //fetchFilterEmployees
  const [employees, setEmployees] = useState<SelectOptionTask[]>([]);

  const fetchingEmployeeD = async () => {
    let collection = await new EmployeeRepository().getIdsItems({
      ids: ids_list,
      object_type: "employee",
    });
    setEmployees(
      collection.items.map((item) => ({
        value: item.id.toString(),
        label: item.full_name,
      }))
    );
  };

  useEffect(() => {
    if (ids_list.length > 0) {
      fetchingEmployeeD();
    }
  }, [ids_list]);

  // useEffect(() => {
  //   if (list_employee.length > 0) {
  //     setEmployees(
  //       list_employee.map((item) => ({
  //         value: item.id.toString(),
  //         label: item.full_name,
  //       }))
  //     );
  //   }
  // }, [list_employee]);

  return (
    <>
      <TaskDeptKanbanFilter
        defaultFilters={{ ...defaultFilters }}
        filters={{ ...filters }}
        setFilters={setFilters}
        total={getTotalNumber({
          id: 0,
          title: "",
          cards: [],
        })}
        employee_list={employees}
      />
      <Space
        style={{
          margin: "16px 8px 0",
          height: "auto",
          display: "block",
        }}
      >
        <Spin spinning={loading}>
          <div
            className="taskpublicuserkanban__wrapper flex justify-center"
            // style={{ display: "flex", justifyContent: "center" }}
          >
            <Kanban
              intialBoard={board}
              disableCardDrag={false}
              onCardDragEnd={handleCardMove}
              renderColumnHeader={(column: ColumnType) => {
                const color =
                  column.title === "PENDING"
                    ? "blue"
                    : column.title === "INPROGRESS"
                    ? "magenta"
                    : column.title === "DONE"
                    ? "green"
                    : column.title === "BLOCK"
                    ? "volcano"
                    : column.title === "TODO"
                    ? "cyan"
                    : "";
                return (
                  <>
                    <div className="flex justify-between mb-2 items-center">
                      <div className="flex">
                        <Badge count={getTotalNumber(column)}>
                          <Tag color={color}>{column.title}</Tag>
                        </Badge>
                      </div>
                      <div>{getPagination(column)}</div>
                    </div>
                  </>
                );
              }}
              renderCard={(content: CardType, cardBag: CardBag) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: (
                      <Button
                        size="small"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setShowModal(true);
                          handleClickCard(content.id);
                        }}
                        icon={
                          <IconInfoCircle
                            size="18"
                            className={
                              " -mt-0.5 text-gray-400 hover:text-gray-500"
                            }
                          />
                        }
                      >
                        Chi tiết
                      </Button>
                    ),
                  },
                ];

                if (content.task_model?.status === Task.STATUS.TODO) {
                  if (
                    content.task_model.public === 1 ||
                    content.task_model.employee_id ===
                      account.company.employee.id
                  ) {
                    items.push({
                      key: "2",
                      label: (
                        <TableDelete
                          className="flex items-center gap-2"
                          error_translate_prefix="task:form.error"
                          onDeleteCallback={(id) => {
                            fetchData(content.task_model?.status || 0);
                          }}
                          repository={new TaskRepository()}
                          id={content.task_model?.id || 0}
                          label="Xóa"
                        />
                      ),
                    });
                  }
                }

                return (
                  <>
                    <Collapse
                      className="kanban__item"
                      destroyInactivePanel
                      key={content.id}
                      style={{ width: "calc(75vw / 4 - 30px)" }}
                      collapsible="disabled"
                    >
                      <Collapse.Panel
                        collapsible="disabled"
                        showArrow={false}
                        header={
                          <>
                            {content.task_model && (
                              <div
                                id="currentTask"
                                className="relative"
                                style={{
                                  minHeight: "70px",
                                  borderLeft: `3px solid ${renderColor(
                                    content.task_model.status
                                  )}`,
                                  width: "100%",
                                  paddingLeft: "0.5rem",
                                }}
                              >
                                <Row
                                  gutter={[4, 8]}
                                  justify="space-between"
                                  align="middle"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    minHeight: 65,
                                  }}
                                >
                                  <Col span={24}>
                                    <Typography.Title
                                      level={5}
                                      style={{ marginBottom: 0 }}
                                    >
                                      <span style={{ color: "#ec5a00" }}>
                                        {"("}
                                        <TextDateTime
                                          ts={content.task_model.org_fromtime}
                                          format="DD-MM"
                                        />
                                        {") "}
                                      </span>
                                      {content.task_model.name}
                                    </Typography.Title>
                                  </Col>
                                  <Col
                                    span={24}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 8,
                                    }}
                                  >
                                    <Row
                                      gutter={[4, 4]}
                                      style={{ width: "100%" }}
                                    >
                                      <Col
                                      // span={10}
                                      // style={{
                                      //   textAlign: "right",
                                      //   display: "flex",
                                      //   justifyContent: "flex-end",
                                      //   gap: 8,
                                      // }}
                                      >
                                        <Typography.Text strong>
                                          {"NTEAM-" +
                                            content.task_model.id
                                              .toString()
                                              .toUpperCase()}
                                        </Typography.Text>
                                      </Col>
                                    </Row>
                                    <Row
                                      gutter={[4, 4]}
                                      style={{ width: "100%" }}
                                    >
                                      <Col
                                      // span={10}
                                      // style={{
                                      //   textAlign: "right",
                                      //   display: "flex",
                                      //   justifyContent: "flex-end",
                                      //   gap: 8,
                                      // }}
                                      >
                                        <Typography.Text>
                                          {list_employee.find(
                                            (item) =>
                                              item.id ===
                                              content.task_model?.employee_id
                                          )?.full_name || "abc"}
                                        </Typography.Text>
                                      </Col>
                                    </Row>
                                    <Row
                                      gutter={[4, 4]}
                                      style={{ width: "100%" }}
                                      className="flex justify-between items-center"
                                    >
                                      <Deviation
                                        deviation={content.task_model.deviation}
                                      ></Deviation>

                                      <Dropdown
                                        menu={{ items }}
                                        placement="bottomRight"
                                        className="cursor-pointer"
                                      >
                                        <IconDotsVertical
                                          style={{ fontSize: "16pt" }}
                                          className="cursor-pointer text-black"
                                        />
                                      </Dropdown>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </>
                        }
                        key={content.id}
                      ></Collapse.Panel>
                    </Collapse>
                  </>
                );
              }}
            />
          </div>
        </Spin>
      </Space>
      <TaskFormModal
        id={taskId}
        onSaveSuccess={onSaveSuccess}
        visible={showModal}
        setVisible={(isVisible) => {
          setShowModal(isVisible);
          setTaskId(0);
        }}
      />
    </>
  );
};

export default TaskDeptKanbanComponent;
