import { Button, Divider, Popover } from "antd";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconInfoCircle } from "@tabler/icons-react";

import TableInfoRow from "./TableInfoRow";

type InfoGeneral = {
	id?: number | string;
	creator_id?: number;
	date_created?: number;
	date_modified?: number;
	note?: string;
};

const TableInfo = ({
	record,
	className,
	children,
	title,
}: {
	record: InfoGeneral;
	className?: string;
	children?: React.ReactNode;
	title?: React.ReactNode;
}) => {
	const { t } = useTranslation();

	const popoverContent = (
		<>
			{typeof record.id !== "undefined" ? (
				<TableInfoRow label={t("common:sid_long")} content={record.id} />
			) : null}

			{typeof record.creator_id !== "undefined" ? (
				<TableInfoRow
					label={t("common:creator_id")}
					content={<TextUser id={record.creator_id} />}
				/>
			) : null}

			{typeof record.date_created !== "undefined" && record.date_created > 0 ? (
				<TableInfoRow
					label={t("common:date_created")}
					content={
						<TextDateTime format="HH:mm, DD/MM/YYYY" ts={record.date_created} />
					}
				/>
			) : null}

			{typeof record.date_modified !== "undefined" ? (
				<TableInfoRow
					label={t("common:date_modified")}
					content={
						<>
							{record.date_modified > 0 ? (
								<TextDateTime
									format="HH:mm, DD/MM/YYYY"
									ts={record.date_modified}
								/>
							) : (
								<>--</>
							)}
						</>
					}
				/>
			) : null}

			{typeof record.note !== "undefined" && record.note.length > 0 ? (
				<TableInfoRow label={t("common:note")} content={<>{record.note}</>} />
			) : null}

			{typeof children !== "undefined" ? (
				<>
					<Divider className="my-1" /> {children}
				</>
			) : null}
		</>
	);

	return (
		<Popover
			trigger={"click"}
			title={title}
			content={popoverContent}
			placement="topRight">
			<Button size="small" type="text">
				<IconInfoCircle
					size="18"
					className={
						" -mt-0.5 " + (className || "text-gray-400 hover:text-gray-500")
					}
				/>
			</Button>
		</Popover>
	);
};

export default TableInfo;
