import {
	InventoryReceiptJson,
	InventoryReceiptJsonAddEdit,
} from "common/types/InventoryResceipt";
import BaseApi from "./BaseApi";
import { InventoryReceiptFilter } from "common/types/InventoryResceipt";
import { GetListProps } from "common/types/Filter";
import AxiosClient from "./AxiosClient";
import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import Helper from "common/utils/helper";

import InventoryReceiptModel from "common/models/InventoryReceiptModel";

const SERVICE_URL = "/inventoryreceipts";

class InventoryReceiptApi extends BaseApi {
	async getList(
		props: GetListProps<InventoryReceiptFilter>
	): Promise<InventoryReceiptCollection> {
		let collection = new InventoryReceiptCollection();

		try {
			let queryData = {
				params: Helper.convertParams(props.filters),
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: number): Promise<InventoryReceiptModel> {
		let item = new InventoryReceiptModel(
			InventoryReceiptModel.getDefaultData()
		);

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new InventoryReceiptModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 204) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async add(data: any): Promise<InventoryReceiptModel> {
		let item = new InventoryReceiptModel(
			InventoryReceiptModel.getDefaultData()
		);

		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new InventoryReceiptModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(data: any): Promise<InventoryReceiptModel> {
		let item = new InventoryReceiptModel(
			InventoryReceiptModel.getDefaultData()
		);

		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new InventoryReceiptModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
	async editDetails(
		data: Pick<InventoryReceiptJsonAddEdit, "action" | "details" | "id">
	): Promise<InventoryReceiptModel> {
		let item = new InventoryReceiptModel(
			InventoryReceiptModel.getDefaultData()
		);

		try {
			let postData = {
				...data,
			};

			const dataDetails = postData.details.map((i) => Helper.convertParams(i));
			const dataPost = Helper.convertParams(postData) as any;

			if (dataPost.hasOwnProperty("id")) {
				delete dataPost.id;
			}

			if (dataPost.hasOwnProperty("details")) {
				dataPost.details = dataDetails;
			}
			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/detail/" + data.id,
				dataPost
			);
			if (response.hasOwnProperty("data")) {
				item = new InventoryReceiptModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
}

export default InventoryReceiptApi;
