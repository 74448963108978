import { App, Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import ProductReceiptDetailModel from "common/models/ProductReceiptDetailModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import ProductReceiptDetailRepository from "common/repositories/ProductReceiptDetailRepository";
import { ProductVariantInMemory } from "common/types/ProductVariant";
import { TableColumnsType } from "common/types/Table";
import TextDateTime from "components/TextDateTime";
import dbm from "dbm";
import ProductVariantSearch from "features/product/ProductVariantSearch";
import update from "immutability-helper";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import addToListFx from "sounds/pos/notify.mp3";
import useSound from "use-sound";
import useUiSettingStore from "zustands/useUiSettingStore";

import { IconX } from "@tabler/icons-react";

import ProductReceiptDetailProductInfo from "../item/ProductReceiptDetailProductInfo";
import ProductReceiptDetailQuantityInputWrapper from "../item/ProductReceiptDetailQuantityInputWrapper";

const ProductReceiptDetailList = ({
	productReceiptModel,
	detailItems,
	setDetailItems,
	allowEditInput,
	productReceiptStatus,
}: {
	productReceiptModel: ProductReceiptModel;
	detailItems: ProductReceiptDetailModel[];
	setDetailItems: (items: ProductReceiptDetailModel[]) => void;
	allowEditInput: boolean;
	productReceiptStatus: number;
}) => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const enableSoundFx = useUiSettingStore((state) => state.enableSoundFx);
	const [soundFxAddToListSuccess] = useSound(addToListFx);
	const confirmingDeleteSku = useRef("");
	const isDraft = useMemo(
		() => productReceiptStatus === ProductReceipt.STATUS_DRAFT,
		[productReceiptStatus]
	);

	const doRemoveRowWithSku = useCallback(
		(sku: string) => {
			setDetailItems(detailItems.filter((item) => item.sku !== sku));
			confirmingDeleteSku.current = "";
		},
		[detailItems, setDetailItems]
	);

	//binding ENTER key and depends to detailItems state
	useHotkeys(
		"enter",
		() => {
			if (confirmingDeleteSku.current !== "") {
				doRemoveRowWithSku(confirmingDeleteSku.current);
			}
		},
		[detailItems]
	);

	const [loading, setLoading] = useState(false);

	const fetchProductReceiptDetail = useCallback(
		async (id: number) => {
			setLoading(true);
			const collection =
				await new ProductReceiptDetailRepository().getFullDetailOfReceipt(id);

			if (!collection.hasError()) {
				// setDetailItems(collection.items);
			} else {
			}

			setLoading(false);
		},
		[setDetailItems]
	);

	useEffect(() => {
		if (productReceiptModel.id > 0) {
			fetchProductReceiptDetail(productReceiptModel.id);
		}
	}, [productReceiptModel.id, fetchProductReceiptDetail]);

	const updateRow = useCallback(
		async (product_variant_id: number, quantity: number) => {
			let insertPass = true;

			const foundIndex = detailItems.findIndex(
				(r) => r.product_variant_id === product_variant_id
			);
			let newItems: ProductReceiptDetailModel[] = [];
			if (foundIndex >= 0) {
				//update current item
				newItems = update(detailItems, {
					[foundIndex]: {
						$set: new ProductReceiptDetailModel({
							...detailItems[foundIndex],
							item_quantity: quantity,
							item_quantity_signed:
								productReceiptModel.direction === ProductReceipt.DIRECTION_INPUT
									? quantity
									: 0 - quantity,
						}),
					},
				});
			} else {
				await (async () => {
					const foundItems = await dbm
						.getCollection("productvariant")
						.chain()
						.find({
							id: product_variant_id,
						})
						.limit(1)
						.data();
					if (foundItems.length > 0) {
						const foundVariant: ProductVariantInMemory = foundItems[0];
						//append new item to list
						newItems = update(detailItems, {
							$push: [
								new ProductReceiptDetailModel({
									...ProductReceiptDetailModel.getDefaultData(),
									product_receipt_id: productReceiptModel.id,
									product_variant_id: product_variant_id,
									product_id: foundVariant.product_id,
									sku: foundVariant.sku,
									item_quantity: 1,
									item_quantity_signed:
										productReceiptModel.direction ===
										ProductReceipt.DIRECTION_INPUT
											? 1
											: -1,
								}),
							],
						});
					} else {
						insertPass = false;
						message.error(
							t("productvariant:id_not_found", { id: product_variant_id })
						);
					}
				})();
			}

			if (insertPass) {
				setDetailItems(newItems);
				if (enableSoundFx) {
					soundFxAddToListSuccess();
				}
			}
		},
		[
			detailItems,
			setDetailItems,
			enableSoundFx,
			soundFxAddToListSuccess,
			productReceiptModel.id,
			productReceiptModel.direction,
			t,
			message,
		]
	);

	const onSelectVariant = useCallback(
		(product_variant_id: number) => {
			const foundItem = detailItems.find(
				(r) => r.product_variant_id === product_variant_id
			);

			updateRow(
				product_variant_id,
				typeof foundItem !== "undefined" ? foundItem.item_quantity + 1 : 1
			);
		},
		[detailItems, updateRow]
	);

	const columns: TableColumnsType<ProductReceiptDetailModel> = [
		{
			title: t("common:table.index"),
			dataIndex: "id",
			align: "center",
			render: (_, __, index: number) => {
				return <>{index + 1}</>;
			},
			width: 50,
		},
		{
			title: t("productvariant:sku"),
			dataIndex: "sku",
			render: (sku: string, record) => {
				return (
					<>
						{sku}{" "}
						{record.item_date_deleted > 0 ? (
							<>
								<Tooltip
									mouseEnterDelay={0.4}
									title={
										<>
											{t("productreceipt:item_deleted_at")}{" "}
											<TextDateTime
												format="HH:mm, DD/MM/YYYY"
												ts={record.item_date_deleted}
											/>
										</>
									}>
									<Tag color={"red"}>{t("productreceipt:item_deleted")}</Tag>
								</Tooltip>
							</>
						) : null}
					</>
				);
			},
		},
		{
			title: t("productreceipt:form.product_info"),
			dataIndex: "product_variant_id",
			render: (_: any, record: ProductReceiptDetailModel) => {
				return (
					<ProductReceiptDetailProductInfo
						variant_id={record.product_variant_id}
					/>
				);
			},
		},
		...(!isDraft
			? [
					{
						title: t("productreceipt:form.item_quantity_initial"),
						dataIndex: "item_quantity_initial",
						width: 140,
						render: (item_quantity_initial: number) => {
							return (
								<>
									<Input
										style={{ width: "100%" }}
										size="large"
										value={item_quantity_initial}
										className="text-center"
										disabled
									/>
								</>
							);
						},
					},
			  ]
			: []),
		{
			title: isDraft
				? t("productreceipt:form.item_quantity_initial")
				: t("productreceipt:form.item_quantity"),
			dataIndex: "item_quantity",
			width: 140,
			align: "center",
			render: (item_quantity: number, record: ProductReceiptDetailModel) => {
				return (
					<>
						{!allowEditInput ? (
							<Input
								style={{ width: "100%" }}
								size="large"
								value={item_quantity}
								className="text-center"
								disabled
							/>
						) : (
							<ProductReceiptDetailQuantityInputWrapper
								item_quantity={item_quantity}
								product_variant_id={record.product_variant_id}
								onChange={(quantity) =>
									updateRow(record.product_variant_id, quantity ?? 0)
								}
							/>
						)}
					</>
				);
			},
		},
		...(isDraft
			? [
					{
						title: " ",
						dataIndex: "operation",
						width: 50,
						render: (_: any, record: ProductReceiptDetailModel) => {
							return (
								<>
									<Popconfirm
										title={t("common:table.confirm_delete_title")}
										placement="topRight"
										onConfirm={() => {
											doRemoveRowWithSku(record.sku);
										}}
										onCancel={() => (confirmingDeleteSku.current = "")}
										okText={t("common:table.confirm_ok")}
										cancelText={t("common:table.confirm_cancel")}>
										<Button
											type="link"
											size="small"
											className="text-red-200 hover:text-red-500"
											onClick={() =>
												(confirmingDeleteSku.current = record.sku)
											}>
											<IconX />
										</Button>
									</Popconfirm>
								</>
							);
						},
					},
			  ]
			: []),
	];

	let newColumns = [...columns];

	return (
		<div>
			<div className="mb-2">
				{/* <ProductVariantSearch
					disabled={productReceiptModel.status !== ProductReceipt.STATUS_DRAFT}
					size="large"
					onSelect={(product_variant_id) => onSelectVariant(product_variant_id)}
				/> */}
			</div>

			<Table
				size="small"
				className="w-full"
				dataSource={detailItems}
				columns={newColumns}
				rowKey={(record) => record.product_variant_id + "-" + record.sku}
				bordered
				loading={loading}
				pagination={{ hideOnSinglePage: true }}
				footer={() => null}
				scroll={{ x: 800 }}
			/>
		</div>
	);
};

export default ProductReceiptDetailList;
