import ShippingOrder from "common/constants/ShippingOrder";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import { ShippingRouteJson } from "common/types/ShippingRoute";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";
import ShippingRouteModel from "./ShippingRouteModel";

import type {
  ShippingOrderFromPointJson,
  ShippingOrderToPointJson,
  ShippingOrderJson,
} from "common/types/ShippingOrder";

class ShippingOrderModel
  extends BaseModel
  implements BaseModelJson<ShippingOrderJson>
{
  company_id?: number;
  creator_id?: number;
  job_title?: string;
  id: number;
  route_id: number;
  shipper_id: number;
  random_code: string;
  type: number;
  from_lat: number;
  from_long: number;
  km: number;
  from_address: string;
  from_phone: string;
  from_location: string;
  from_warehouse_id: number;
  merchant_id: number;
  office_id: number;
  office_id_to: number;
  foreign_id: number;
  invoice_id: string;
  cod: number;
  shipping_type: number;
  shipping_type_name: string;
  product_type: number;
  product_name: string;
  order_price_sell: number;
  order_price_discount: number;
  order_price_shipping: number;
  order_price_final: number;
  price_incurred: number;
  order_note: string;
  order_customer_fullname: string;
  order_customer_email: string;
  order_customer_phone: string;
  order_shipping_address: string;
  order_shipping_country: string;
  order_shipping_region: number;
  order_shipping_subregion: number;
  order_shipping_lat: number;
  order_shipping_long: number;
  order_shipping_map_place_id: string;
  order_detail?: string;
  order_length: number;
  order_width: number;
  order_height: number;
  order_weight: number;
  price_sell: number;
  price_discount: number;
  price_final: number;
  source: number;
  pickup_status: number;
  return_status: number;
  cod_status: number;
  display_order: number;
  status: number;
  ymd: number;
  date_created: number;
  date_modified: number;
  date_started: number;
  date_completed: number;
  file_id_list: number[];
  files: FileModel[];
  shipper_note: string;
  from_type: number;
  order_shipping_hourstart: number;
  order_shipping_hourend: number;
  order_shipping_thirdregion: number;
  order_shipping_address_detail: string;
  notes: string[];
  route?: ShippingRouteJson;

  constructor(json: ShippingOrderJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.job_title = json.job_title || "";
    this.id = json.id || 0;
    this.route_id = json.route_id || 0;
    this.shipper_id = json.shipper_id || 0;
    this.random_code = json.random_code || "";
    this.type = json.type || 0;
    this.from_lat = json.from_lat || 0;
    this.from_long = json.from_long || 0;
    this.km = json.km || 0;
    this.from_address = json.from_address || "";
    this.from_phone = json.from_phone || "";
    this.from_location = json.from_location || "";
    this.from_warehouse_id = json.from_warehouse_id || 0;
    this.merchant_id = json.merchant_id || 0;
    this.office_id = json.office_id || 0;
    this.office_id_to = json.office_id_to || 0;
    this.foreign_id = json.foreign_id || 0;
    this.invoice_id = json.invoice_id || "";
    this.cod = json.cod || 0;
    this.shipping_type = json.shipping_type || 0;
    this.shipping_type_name = json.shipping_type_name || "";
    this.product_type = json.product_type || 0;
    this.product_name = json.product_name || "";
    this.order_price_sell = json.order_price_sell || 0;
    this.order_price_discount = json.order_price_discount || 0;
    this.order_price_shipping = json.order_price_shipping || 0;
    this.order_price_final = json.order_price_final || 0;
    this.price_incurred = json.price_incurred || 0;
    this.order_note = json.order_note || "";
    this.order_customer_fullname = json.order_customer_fullname || "";
    this.order_customer_phone = json.order_customer_phone || "";
    this.order_customer_email = json.order_customer_email || "";
    this.order_shipping_address = json.order_shipping_address || "";
    this.order_shipping_country = json.order_shipping_country || "";
    this.order_shipping_region = json.order_shipping_region || 0;
    this.order_shipping_subregion = json.order_shipping_subregion || 0;
    this.order_shipping_lat = json.order_shipping_lat || 0;
    this.order_shipping_long = json.order_shipping_long || 0;
    this.order_shipping_map_place_id = json.order_shipping_map_place_id || "";
    this.order_detail = json.order_detail || "";
    this.order_length = json.order_length || 0;
    this.order_width = json.order_width || 0;
    this.order_height = json.order_height || 0;
    this.order_weight = json.order_weight || 0;
    this.price_sell = json.price_sell || 0;
    this.price_discount = json.price_discount || 0;
    this.price_final = json.price_final || 0;
    this.source = json.source || 0;
    this.pickup_status = json.pickup_status || 0;
    this.return_status = json.return_status || 0;
    this.cod_status = json.cod_status || 0;
    this.display_order = json.display_order || 0;
    this.status = json.status || 0;
    this.ymd = json.ymd || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_started = json.date_started || 0;
    this.date_completed = json.date_completed || 0;
    this.file_id_list = json.file_id_list || [];
    this.files = [];
    if (typeof json.files !== "undefined" && Array.isArray(json.files)) {
      this.files = json.files.map((file) => new FileModel(file));
    }
    this.shipper_note = json.shipper_note || "";
    this.from_type = json.from_type || 0;
    this.order_shipping_hourstart = json.order_shipping_hourstart || 0;
    this.order_shipping_hourend = json.order_shipping_hourend || 0;
    this.order_shipping_thirdregion = json.order_shipping_thirdregion || 0;
    this.order_shipping_address_detail =
      json.order_shipping_address_detail || "";
    this.notes = json.notes || [];
    this.route = json.route || ShippingRouteModel.getDefaultData();
  }

  static getDefaultFromData(): ShippingOrderFromPointJson {
    return {
      from_type: 0,
      hub_id: 0,
      merchant_pickup_id: 0,
      lat: 0,
      lng: 0,
      region_id: 0,
    };
  }

  static getDefaultToData(): ShippingOrderToPointJson {
    return {
      type: 0,
      hub_id: 0,
      lat: 0,
      lng: 0,
      region_id: 0,
    };
  }

  static getDefaultData(): ShippingOrderJson {
    return {
      company_id: 0,
      creator_id: 0,
      job_title: " ",
      id: 0,
      route_id: 0,
      shipper_id: 0,
      random_code: "",
      type: 0,
      from_lat: 0,
      from_long: 0,
      km: 0,
      from_address: "",
      from_phone: "",
      from_location: "",
      from_warehouse_id: 0,
      merchant_id: 0,
      office_id: 0,
      office_id_to: 0,
      foreign_id: 0,
      invoice_id: "",
      cod: 0,
      shipping_type: 0,
      shipping_type_name: "",
      product_name: "",
      product_type: 0,
      order_price_sell: 0,
      order_price_discount: 0,
      order_price_shipping: 0,
      order_price_final: 0,
      price_incurred: 0,
      order_note: "",
      order_customer_fullname: "",
      order_customer_email: "",
      order_customer_phone: "",
      order_shipping_address: "",
      order_shipping_country: "",
      order_shipping_region: 0,
      order_shipping_subregion: 0,
      order_shipping_lat: 0,
      order_shipping_long: 0,
      order_shipping_map_place_id: "",
      order_detail: "",
      order_length: 0,
      order_width: 0,
      order_height: 0,
      order_weight: 0,
      price_sell: 0,
      price_discount: 0,
      price_final: 0,
      source: 0,
      pickup_status: 0,
      return_status: 0,
      cod_status: 0,
      display_order: 0,
      status: 0,
      ymd: 0,
      date_created: 0,
      date_modified: 0,
      date_started: 0,
      date_completed: 0,
      file_id_list: [],
      files: [],
      shipper_note: "",
      from_type: 0,
      order_shipping_hourstart: 0,
      order_shipping_hourend: 0,
      order_shipping_thirdregion: 0,
      order_shipping_address_detail: "",
      notes: [],
      route: {
        company_id: 0,
        creator_id: 0,
        id: 0,
        code: "",
        note: "",
        shipper_id: 0,
        from_warehouse_id: 0,
        to_warehouse_id: 0,
        distance_geotravel_ratio: 0,
        count_total: 0,
        count_done: 0,
        count_success: 0,
        count_fail: 0,
        count_remain: 0,
        cod_total: 0,
        cod_success: 0,
        cod_fail: 0,
        cod_remain: 0,
        ymd: 0,
        status: 0,
        date_created: 0,
        date_modified: 0,
        from_type: 0,
        total_distance: 0,
        stats: {
          count_order: 0,
          sum_order_weight: 0,
          sum_distance: 0,
          sum_duration: 0,
          speed_km_per_hour: 0,
          rest_minute_per_order: 0,
          vehicle_id: 0,
          distance_between_point: [],
        },
      },
    };
  }

  toJson(): ShippingOrderJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      job_title: this.job_title,
      id: this.id,
      route_id: this.route_id,
      shipper_id: this.shipper_id,
      random_code: this.random_code,
      type: this.type,
      from_lat: this.from_lat,
      from_long: this.from_long,
      km: this.km,
      from_address: this.from_address,
      from_phone: this.from_phone,
      from_location: this.from_location,
      from_warehouse_id: this.from_warehouse_id,
      merchant_id: this.merchant_id,
      office_id: this.office_id,
      office_id_to: this.office_id_to,
      foreign_id: this.foreign_id,
      invoice_id: this.invoice_id,
      cod: this.cod,
      shipping_type: this.shipping_type,
      shipping_type_name: this.shipping_type_name,
      product_name: this.product_name,
      product_type: this.product_type,
      order_price_sell: this.order_price_sell,
      order_price_discount: this.order_price_discount,
      order_price_shipping: this.order_price_shipping,
      order_price_final: this.order_price_final,
      price_incurred: this.price_incurred,
      order_note: this.order_note,
      order_customer_fullname: this.order_customer_fullname,
      order_customer_email: this.order_customer_email,
      order_customer_phone: this.order_customer_phone,
      order_shipping_address: this.order_shipping_address,
      order_shipping_country: this.order_shipping_country,
      order_shipping_region: this.order_shipping_region,
      order_shipping_subregion: this.order_shipping_subregion,
      order_shipping_lat: this.order_shipping_lat,
      order_shipping_long: this.order_shipping_long,
      order_shipping_map_place_id: this.order_shipping_map_place_id,
      order_detail: this.order_detail,
      order_length: this.order_length,
      order_width: this.order_width,
      order_height: this.order_height,
      order_weight: this.order_weight,
      price_sell: this.price_sell,
      price_discount: this.price_discount,
      price_final: this.price_final,
      source: this.source,
      pickup_status: this.pickup_status,
      return_status: this.return_status,
      cod_status: this.cod_status,
      display_order: this.display_order,
      status: this.status,
      ymd: this.ymd,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_started: this.date_started,
      date_completed: this.date_completed,
      file_id_list: this.file_id_list,
      files: this.files.map((file) => file.toJson()),
      shipper_note: this.shipper_note,
      from_type: this.from_type,
      order_shipping_hourstart: this.order_shipping_hourstart,
      order_shipping_hourend: this.order_shipping_hourend,
      order_shipping_thirdregion: this.order_shipping_thirdregion,
      order_shipping_address_detail: this.order_shipping_address_detail,
      notes: this.notes,
      route: this.route,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ShippingOrder.STATUS_NEW,
        label: i18n.t("shippingorder:STATUS_NEW"),
        color: "yellow",
      },
      {
        value: ShippingOrder.STATUS_PENDING,
        label: i18n.t("shippingorder:STATUS_PENDING"),
        color: "purple",
      },
      {
        value: ShippingOrder.STATUS_INPROGRESS,
        label: i18n.t("shippingorder:STATUS_INPROGRESS"),
        color: "blue",
      },
      {
        value: ShippingOrder.STATUS_SUCCESS,
        label: i18n.t("shippingorder:STATUS_SUCCESS"),
        color: "green",
      },
      {
        value: ShippingOrder.STATUS_FAIL,
        label: i18n.t("shippingorder:STATUS_FAIL"),
        color: "red",
      },
      {
        value: ShippingOrder.STATUS_SUB_SUCCESS,
        label: i18n.t("shippingorder:STATUS_SUB_SUCCESS"),
        color: "green",
      },
      {
        value: ShippingOrder.STATUS_CANCELLED,
        label: i18n.t("shippingorder:STATUS_CANCELLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: ShippingOrder.TYPE_CUSTOMER,
        label: i18n.t("shippingorder:TYPE_CUSTOMER"),
      },
      {
        value: ShippingOrder.TYPE_INTERNAL,
        label: i18n.t("shippingorder:TYPE_INTERNAL"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }

  static getTypeFromList(): SelectOption[] {
    return [
      {
        value: ShippingOrder.FROM_TYPE_OFFICE,
        label: i18n.t("shippingorder:FROM_TYPE_OFFICE"),
      },
      {
        value: ShippingOrder.FROM_TYPE_MERCHANT,
        label: i18n.t("shippingorder:FROM_TYPE_MERCHANT"),
      },
    ];
  }

  static getTypeFrom(value: number): SelectOption | undefined {
    return this.getTypeFromList().find((item) => item.value === value);
  }

  static getPickupStatusList(): SelectOption[] {
    return [
      {
        value: ShippingOrder.PICKUP_STATUS_NOPICKUP,
        label: i18n.t("shippingorder:PICKUP_STATUS_NOPICKUP"),
      },
      {
        value: ShippingOrder.PICKUP_STATUS_INPROGRESS,
        label: i18n.t("shippingorder:PICKUP_STATUS_INPROGRESS"),
      },
      {
        value: ShippingOrder.PICKUP_STATUS_COMPLETE,
        label: i18n.t("shippingorder:PICKUP_STATUS_COMPLETE"),
      },
      {
        value: ShippingOrder.PICKUP_STATUS_FAIL,
        label: i18n.t("shippingorder:PICKUP_STATUS_FAIL"),
      },
    ];
  }

  static getPickupStatus(value: number): SelectOption | undefined {
    return this.getPickupStatusList().find((item) => item.value === value);
  }

  static getReturnStatusList(): SelectOption[] {
    return [
      {
        value: ShippingOrder.RETURN_STATUS_NO,
        label: i18n.t("shippingorder:RETURN_STATUS_NO"),
      },
      {
        value: ShippingOrder.RETURN_STATUS_PENDING,
        label: i18n.t("shippingorder:RETURN_STATUS_PENDING"),
      },
      {
        value: ShippingOrder.RETURN_STATUS_INPROGRESS,
        label: i18n.t("shippingorder:RETURN_STATUS_INPROGRESS"),
      },
      {
        value: ShippingOrder.RETURN_STATUS_COMPLETE,
        label: i18n.t("shippingorder:RETURN_STATUS_COMPLETE"),
      },
      {
        value: ShippingOrder.RETURN_STATUS_FAIL,
        label: i18n.t("shippingorder:RETURN_STATUS_FAIL"),
      },
    ];
  }

  static getReturnStatus(value: number): SelectOption | undefined {
    return this.getReturnStatusList().find((item) => item.value === value);
  }

  static getProductTypeList(): SelectOption[] {
    return [
      {
        value: ShippingOrder.PRODUCT_TYPE_NORMAL,
        label: i18n.t("shippingorder:PRODUCT_TYPE_NORMAL"),
      },
      {
        value: ShippingOrder.PRODUCT_TYPE_FOOD,
        label: i18n.t("shippingorder:PRODUCT_TYPE_FOOD"),
      },
      {
        value: ShippingOrder.PRODUCT_TYPE_FROZENFOOD,
        label: i18n.t("shippingorder:PRODUCT_TYPE_FROZENFOOD"),
      },
      {
        value: ShippingOrder.PRODUCT_TYPE_FRAGILE,
        label: i18n.t("shippingorder:PRODUCT_TYPE_FRAGILE"),
      },
    ];
  }

  static getProductType(value: number): SelectOption | undefined {
    return this.getProductTypeList().find((item) => item.value === value);
  }

  static getStatusAssignedList(): SelectOption[] {
    return [
      {
        value: ShippingOrder.STATUS_ASSIGNED_ROUTE,
        label: i18n.t("shippingorder:STATUS_ASSIGNED_ROUTE"),
      },
      {
        value: ShippingOrder.STATUS_UNASSIGNED_ROUTE,
        label: i18n.t("shippingorder:STATUS_UNASSIGNED_ROUTE"),
      },
    ];
  }

  static getStatusAssigned(value: number): SelectOption | undefined {
    return this.getStatusAssignedList().find((item) => item.value === value);
  }
}

export default ShippingOrderModel;
