import TaxInvoiceGatewayApi from "common/api/TaxInvoiceGatewayApi";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";

import BaseRepository from "./BaseRepository";

class TaxInvoiceGatewayRepository extends BaseRepository<TaxInvoiceGatewayApi> {
  _api: TaxInvoiceGatewayApi | null;

  constructor() {
    super();
    this._api = new TaxInvoiceGatewayApi(true);
  }

  async createRemoteOrder(id: number): Promise<TaxInvoiceModel> {
    if (this._api) {
      return await this._api.createRemoteOrder(id);
    } else {
      return new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());
    }
  }

  async syncStatus(id: number): Promise<TaxInvoiceModel> {
    if (this._api) {
      return await this._api.syncStatus(id);
    } else {
      return new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());
    }
  }
}

export default TaxInvoiceGatewayRepository;
