import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ReportCheckinJson } from "common/types/ReportCheckin";

import BaseModel from "./BaseModel";

class ReportCheckinModel
  extends BaseModel
  implements BaseModelJson<ReportCheckinJson>
{
  creator_id: number;
  company_id: number;
  user_id: number;
  file_name: string;
  date_from: number;
  date_to: number;

  constructor(json: ReportCheckinJson) {
    super();
    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.user_id = json.user_id || 0;
    this.file_name = json.file_name || "";
    this.date_from = json.date_from || 0;
    this.date_to = json.date_to || 0;
  }

  static getDefaultData(): ReportCheckinJson {
    return {
      creator_id: 0,
      company_id: 0,
      user_id: 0,
      file_name: "",
      date_from: 0,
      date_to: 0,
    };
  }

  toJson(): ReportCheckinJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      user_id: this.user_id,
      file_name: this.file_name,
      date_from: this.date_from,
      date_to: this.date_to,
    };
  }
}

export default ReportCheckinModel;
