import {
  Button,
  Checkbox,
  Empty,
  Modal,
  Spin,
  Table,
  Tooltip,
  notification,
} from "antd";
import { TableColumnsType } from "common/types/Table";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PromotionJson } from "common/types/Promotion";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ProductCollectionProductModal from "features/productcollection/product/ProductCollectionProductModal";
import moment from "moment";
import PromotionRepository from "common/repositories/PromotionRepository";
import { IconEye, IconTrash } from "@tabler/icons-react";
const PromotionProductList = ({
  promotion_id,
  promotion,
  onUpdate,
}: {
  promotion_id: number;
  promotion: PromotionJson;
  onUpdate?: () => void;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [colIds, setColIds] = useState(promotion.collection_list);
  //fetch PromotionProduct
  const [dataSource, setDataSource] = useState<ProductCollectionModel[]>([]);
  const [total, setTotal] = useState(0);

  const fetchDataCollectionProduct = useCallback(async () => {
    setLoading(true);
    if (!colIds) {
      setDataSource([]);
      setLoading(false);
      return;
    }
    const collection = await new ProductCollectionRepository().getItems({
      filters: {
        ...ProductCollectionRepository.getDefaultFilters(),
        ids: colIds.join(","),
        page: 1,
      },
    });
    if (!collection.hasError()) {
      setDataSource(collection.items);
      // const ids = colIds.split(",").map(Number)
      // const itemsNotExits = ids.filter(id=>!collection.items.some(item=>item.id === id))
      setTotal(collection.total);
      setLoading(false);
    }
  }, [promotion_id, colIds]);

  useEffect(() => {
    fetchDataCollectionProduct();
  }, [fetchDataCollectionProduct]);

  const columns: TableColumnsType<ProductCollectionModel> = [
    {
      title: t("Tên nhóm hàng"),
      key: "name",
      render: (_: any, record: ProductCollectionModel) => {
        return <p>{record.title}</p>;
      },
    },
    {
      title: t("Ngày tạo"),
      key: "name",
      width: 200,
      render: (_: any, record: ProductCollectionModel) => {
        return (
          <p>{moment.unix(record.date_created).format("DD/MM/YYYY HH:mm")}</p>
        );
      },
    },

    {
      title: "Hành động",
      key: "",
      dataIndex: "",
      width: 100,
      render: (_, record) => {
        return (
          <div className=" flex  justify-evenly items-center gap-1">
            <ProductsOfCol col_id={record.id} />
            <Tooltip title="Bỏ áp dụng">
              <CloseCircleOutlined
                className=" cursor-pointer"
                onClick={() => {
                  const res = dataSource
                    .map((item) => item.id)
                    .filter((item) => item !== record.id);
                  updateColPromotion(res);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  async function updateColPromotion(col_ids: number[]) {
    setLoading(true);
    const res = await new PromotionRepository().saveRemote({
      id: promotion_id,
      collection_list: col_ids.join(","),
    });
    if (!res.hasError()) {
      notification.success({
        message: "Cập nhập thành công",
      });
      setColIds(col_ids);
      onUpdate?.();
    } else {
      notification.error({
        message: "Cập nhập thất bại vui lòng thử lại",
      });
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <div className=" min-h-72 flex justify-center items-center">
        <Spin />;
      </div>
    );
  }

  return (
    <>
      <div>
        <div className=" flex justify-end mb-2">
          <Button
            onClick={() => setOpen(true)}
            icon={<PlusCircleOutlined />}
            type="primary"
          >
            Cập nhập nhóm hàng
          </Button>
        </div>
        {!dataSource.length ? (
          <div className="mt-10 flex flex-col justify-center items-center">
            <p className="  text-base font-semibold">
              Chưa áp dụng cho nhóm hàng nào
            </p>
            <Empty />
          </div>
        ) : (
          <div className=" mt-10">
            <p>{`Tất cả (${total})`}</p>
            <Table
              // summary={}
              size="small"
              className="table-dashboard"
              dataSource={dataSource}
              columns={columns}
              rowKey={"id"}
              bordered
              loading={loading}
              pagination={{ hideOnSinglePage: true }}
              footer={() => null}
            />
          </div>
        )}
      </div>

      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        title="Thêm nhóm hàng cho chương trình khuyến mãi"
        footer={null}
      >
        <AddCollectionToPromotion
          collection_list={dataSource.map((item) => item.id)}
          promotion_id={promotion.id}
          onUpdateSuccec={(ids) => {
            setColIds(ids);
          }}
        />
      </Modal>
    </>
  );
};

export default PromotionProductList;

function AddCollectionToPromotion({
  collection_list,
  promotion_id,
  onUpdateSuccec,
}: {
  collection_list: number[];
  promotion_id: number;
  onUpdateSuccec: (col_ids: number[]) => void;
}) {
  const [collectionIds, setCollectionIds] = useState(collection_list);

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [spinning, setSpinning] = React.useState(false);
  const [inputKeyword, setInputKeyword] = useState("");
  //fetch PromotionProduct
  const [dataSource, setDataSource] = useState<ProductCollectionModel[]>([]);
  const [total, setTotal] = useState(0);

  const fetchDataCollectionProduct = useCallback(async () => {
    const collection = await new ProductCollectionRepository().getItems({
      filters: {
        ...ProductCollectionRepository.getDefaultFilters(),
        page: 1,
        limit: -1,
      },
    });
    if (!collection.hasError()) {
      setDataSource(collection.items);
      setTotal(collection.total);
      setLoading(false);
    } else {
      notification.error({
        message: "Có lỗi vui lòng thử lại",
      });
    }
  }, []);

  async function onSubmit() {
    setSpinning(true);

    const res = await new PromotionRepository().saveRemote({
      id: promotion_id,
      collection_list: collectionIds.join(","),
    });
    setSpinning(false);

    if (!res.hasError()) {
      notification.success({
        message: "Cập nhập thành công",
      });
      onUpdateSuccec?.(collectionIds);
    } else {
      notification.error({
        message: "Cập nhập thất bại vui lòng thử lại",
      });
    }
  }

  useEffect(() => {
    fetchDataCollectionProduct();
  }, [fetchDataCollectionProduct]);

  const columns: TableColumnsType<ProductCollectionModel> = [
    {
      title: "Chọn",
      key: "",
      dataIndex: "",
      width: 100,
      render: (_, record) => {
        return (
          <Checkbox
            checked={collectionIds.some((col) => col === record.id)}
            onChange={(e) => {
              const value = e.target.checked;
              if (value) {
                setCollectionIds((pre) => [...pre, record.id]);
              } else {
                setCollectionIds((pre) => pre.filter((id) => id !== record.id));
              }
            }}
          />
        );
      },
    },
    {
      title: t("Tên nhóm hàng"),
      key: "name",
      render: (_: any, record: ProductCollectionModel) => {
        return <p>{record.title}</p>;
      },
    },
    {
      title: t("Ngày tạo"),
      key: "name",
      width: 150,
      render: (_: any, record: ProductCollectionModel) => {
        return (
          <p>{moment.unix(record.date_created).format("DD/MM/YYYY HH:mm")}</p>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={spinning} delay={500}>
        <p>
          Đã chọn {collectionIds.length} / {total} nhóm hàng
        </p>

        <Table
          size="small"
          className="table-dashboard"
          dataSource={dataSource}
          columns={columns}
          rowKey={"id"}
          bordered
          loading={loading}
          pagination={{ hideOnSinglePage: true }}
          footer={() => (
            <div className=" flex justify-end">
              <Button onClick={onSubmit} type="primary">
                Lưu
              </Button>
            </div>
          )}
        />
      </Spin>
    </>
  );
}

function ProductsOfCol({ col_id }: { col_id: number }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title="Xem danh sách sản phẩm">
        <IconEye className=" cursor-pointer" onClick={() => setOpen(true)} />
      </Tooltip>
      <ProductCollectionProductModal
        open={open}
        id={col_id}
        setOpen={() => setOpen(false)}
        onSuccsec={() => {}}
      />
    </>
  );
}
