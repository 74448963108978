import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

import ShopCollectionList from "features/shopcollection/list/ShopCollectionList";

const ShopCollectionPage = () => {
	const [pathParams] = usePathParams();

	const [errors, setErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setErrors([]);
	}, [pathParams.action]);

	let com = <></>;

	switch (pathParams.action) {
		case "edit":
			// com =
			//   model.id > 0 ? (
			//     <EmployeeForm model={model} />
			//   ) : (
			//     <FormEditFetcher
			//       doLoad={async () => {
			//         let fetchedModel =
			//           await new EmployeeRepository().getItemFullDetail(
			//             parseInt(pathParams.id.toString())
			//           );
			//         if (fetchedModel.hasError()) {
			//           setFetchErrors(fetchedModel.error.errors);
			//         } else {
			//           setModel(fetchedModel);
			//         }
			//       }}
			//       errors={fetchErrors}
			//       heading={<EmployeeFormHeader isEditing={true} />}
			//     />
			//   );

			break;
		default:
			com = <ShopCollectionList />;
			break;
	}
	return com;
};

export default ShopCollectionPage;
