import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Avatar, Button, Dropdown, MenuProps, Tag, message } from "antd";
import Role from "common/constants/Role";
import RoleCheck from "components/RoleCheck";
import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import { useTranslation } from "react-i18next";

import TableInfo from "components/table/TableInfo";
import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
import ColorHash from "color-hash";
import useDbForSelect from "hooks/useDbForSelect";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { MoreOutlined } from "@ant-design/icons";
import { IconBox, IconCalendar, IconEdit } from "@tabler/icons-react";

import EmployeeCollection from "common/collections/EmployeeCollection";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import { FilterEmployee } from "common/types/Employee";
import { IdFetcherResult } from "common/types/IdFetcher";
import { TableColumnsType } from "common/types/Table";

import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import TableInfoRow from "components/table/TableInfoRow";
import TableLinkText from "components/table/TableLinkText";

import EmployeeListFilter from "./EmployeeListFilter";
import EmployeeListHeader from "./EmployeeListHeader";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableActionItem from "components/table/TableActionItem";

const EmployeeList = () => {
	const { t } = useTranslation();
	const [officeItems] = useDbForSelect("companyoffice");
	const navigate = useNavigate();

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterEmployee = useMemo(
		() => EmployeeRepository.getDefaultFilters(),
		[]
	);

	//state
	const [showModal, setShowModal] = useState<boolean>(false);
	const [employee, setEmployee] = useState<EmployeeModel>(
		new EmployeeModel(EmployeeModel.getDefaultData())
	);
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<EmployeeModel[]>([]);

	const [filters, setFilters] = useStateFilter<FilterEmployee>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	// call idfetcher
	const [employeeDepartments, setEmployeeDepartments] = useState<
		IdFetcherResult[] | undefined
	>();
	const fetchingEmployeeDepartments = useCallback(async (id_list: number[]) => {
		const collection = await new IdFetcherRepository().doFetching({
			object_type: "department",
			id_list,
		});
		if (collection) {
			setEmployeeDepartments(
				collection.items.find((i) => i.object_type === "department")?.result ||
					[]
			);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}
	}, []);

	//////////////////////////////////////////
	//Fetch data from this collections
	const fetchData = useCallback(async (): Promise<EmployeeCollection> => {
		let collection = await new EmployeeRepository().getItems({
			filters,
		});

		if (collection) {
			setTotal(collection.total);
			setDataSource(collection.items);
			if (collection.total > 0)
				fetchingEmployeeDepartments(
					collection.items.map((item) => item.department_id)
				);
		} else {
			message.error(t("common:error.error_fetching_data"));
		}

		return collection;
	}, [filters, fetchingEmployeeDepartments]);

	//////////////////////////////////////////
	//Table columns
	const accountRoleSubjects = useLoginAccountStore((state) =>
		state.account.role.map((r) => r.subject_id)
	);
	// if current logged in user include ONE role need to check, it's PASS
	const hasRole =
		[Role.EMPLOYEE_MANAGE].filter((r) => accountRoleSubjects.includes(r))
			.length > 0;

	const columns: TableColumnsType<EmployeeModel> = [
		{
			title: " ",
			key: "user_id",
			togglelabel: t("employee:avatar"),
			width: 50,
			render: (user_id: number, record: EmployeeModel) => {
				return (
					<>
						<Avatar
							shape="circle"
							// size={200}
							style={{
								backgroundColor: new ColorHash().hex(record.full_name),
							}}>
							{record.full_name
								.trim()
								.split(" ")
								.slice(-1)
								.toString()
								.charAt(0)}
						</Avatar>
					</>
				);
			},
		},
		{
			title: t("employee:internal_id"),
			key: "internal_id",
			width: 120,
			// align: "center",
			// toggletype: "extend",
		},
		{
			title: t("Mã chấm công"),
			key: "attendance_id",
			width: 120,
			// align: "center",
			// toggletype: "extend",
		},
		{
			title: t("common:full_name"),
			key: "full_name",
			width: 200,
			render: (_, record) => {
				return (
					<TableLinkText
						link={hasRole ? "/employee/edit/id/" + record.id : ""}
						className={hasRole ? "" : "cursor-not-allowed"}>
						{record.full_name}
					</TableLinkText>
				);
			},
		},
		{
			title: t("employee:job_title"),
			key: "job_title",
			dataIndex: "job_title",
			width: 150,
		},
		{
			title: t("employee:office_short"),
			key: "office_id",
			width: 150,
			render: (office_id: number) => {
				if (office_id > 0) {
					const foundOffice = officeItems.find((i) => i.value === office_id);
					if (typeof foundOffice !== "undefined") {
						return <span>{foundOffice.label}</span>;
					} else {
						return "ID #" + office_id;
					}
				} else {
					return "-";
				}
			},
		},
		{
			title: t("employee:department"),
			key: "department_id",
			width: 140,
			render: (department_id: number) =>
				department_id > 0 ? (
					<IdFetcherRender
						value={department_id}
						mapping={employeeDepartments}
					/>
				) : (
					"-"
				),
		},
		// {
		//   title: t("employee:email"),
		//   key: "email",
		//   width: 220,
		//   render: (email, record) => {
		//     return (
		//       <>
		//         {email}
		//         {record.user_id === 0 ? (
		//           <>
		//             <Tag color="error">{t("employee:user_id_not_set")}</Tag>
		//           </>
		//         ) : null}
		//       </>
		//     );
		//   },
		// },
		// {
		//   title: t("employee:username"),
		//   key: "username",
		//   toggletype: "extend",
		// },
		// {
		//   title: (
		//     <Tooltip title={t("employee:checkin_type")}>
		//       <IconClock size={16} />
		//     </Tooltip>
		//   ),
		//   width: 30,
		//   key: "checkin_type",
		//   align: "center",
		//   render: (checkin_type: number) => (
		//     <Tooltip
		//       placement="left"
		//       title={
		//         EmployeeModel.getCheckinTypeList().find(
		//           (option) => option.value === checkin_type
		//         )?.label
		//       }
		//     >
		//       {checkin_type === Employee.CHECKIN_TYPE_OFFICE ? (
		//         <IconClock size={16} />
		//       ) : checkin_type === Employee.CHECKIN_TYPE_SCHEDULE ? (
		//         <IconCalendarEvent size={16} className="text-yellow-600" />
		//       ) : (
		//         <>-</>
		//       )}
		//     </Tooltip>
		//   ),
		// },
		{
			title: t("employee:contract_work"),
			key: "contract_work",

			render: (contract_work: number) => (
				<>{EmployeeModel.getContractWork(contract_work)}</>
			),
			width: 150,
		},
		{
			title: t("employee:contract_date_extant"),
			key: "contract_date_extant",
			render: (contract_date_extant: number) => <>{contract_date_extant}</>,

			width: 120,
		},
		{
			title: t("employee:type"),
			key: "type",
			width: 160,
			render: (type: number) => (
				<>
					{
						EmployeeModel.getTypeList().find((option) => option.value === type)
							?.label
					}
				</>
			),
			toggletype: "extend",
		},

		{
			title: t("common:status"),
			key: "status",
			width: 100,
			render: (status, record) => {
				let statusInfo = EmployeeModel.getStatus(status);
				return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 125,
			render: (_: any, record: EmployeeModel) => {
				const checkInType = EmployeeModel.getCheckinTypeList().find(
					(option) => option.value === record.checkin_type
				)?.label;
				const items: MenuProps["items"] = [
					{
						key: "1",
						label: (
							<TableEdit
								key="edit"
								link=""
								onClick={() => {
									navigate(`/employee/edit/id/${record.id}`);
								}}
							/>
						),
					},
					// {
					//   key: "2",
					//   label: (
					//     <TableDelete
					//       className="h-full w-full"
					//       error_translate_prefix="employee:form.error"
					//       onDeleteCallback={(id) => {
					//         setDataSource(dataSource.filter((item) => item.id !== id));
					//       }}
					//       repository={new EmployeeRepository()}
					//       label="Xóa"
					//       id={record.id}
					//     />
					//   ),
					// },
					// {
					//   key: "3",
					//   label: (
					//     <Link to={`/approver/add/id/${record.id}`}>
					//       <Button size="small" type="text">
					//         <div className="h-full w-full flex items-center gap-2">
					//           <IconBox
					//             className=" text-gray-400 hover:text-red-500"
					//             size="18"
					//           />
					//           {t("employee:approver")}
					//         </div>
					//       </Button>
					//     </Link>
					//   ),
					// },
					// {
					//   key: "4",
					//   label: (
					//     <TableActionItem
					//       link=""
					//       onClick={() => {
					//         setEmployee(record);
					//         setShowModal(true);
					//       }}
					//       label="Ngày nghỉ"
					//     />
					//   ),
					// },
				];
				return (
					<RoleCheck roles={[Role.EMPLOYEE_MANAGE]} hideOnFail>
						<TableEdit
							key="edit"
							link=""
							onClick={() => {
								navigate(`/employee/edit/id/${record.id}`);
							}}
						/>
						<TableInfo record={record}>
							<TableInfoRow
								label={t("employee:user_id")}
								content={record.user_id}
							/>
							<TableInfoRow label={t("common:phone")} content={record.phone} />
							<TableInfoRow label={t("common:email")} content={record.email} />

							<TableInfoRow
								label={t("employee:checkin_type")}
								content={checkInType}
							/>
						</TableInfo>
					</RoleCheck>
				);
			},
		},
	];

	return (
		<>
			<EmployeeListHeader />
			<EmployeeListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>

			<PageDataTable<FilterEmployee, EmployeeModel, EmployeeCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "employee",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
			{/* <EmployeeLeaveFormModal
        setModel={setEmployee}
        model={employee}
        visible={showModal}
        setVisible={setShowModal}
        onSaveSuccess={onSaveSuccess}
      /> */}
		</>
	);
};

export default EmployeeList;
