import {
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	List,
	notification,
	Popconfirm,
	Row,
	Space,
	Tag,
	Typography,
} from "antd";
import ProductInventoryChecking from "common/constants/ProductInventoryChecking";
import Role from "common/constants/Role";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingRepository from "common/repositories/ProductInventoryCheckingRepository";
import { WarehouseJson } from "common/types/Warehouse";
import LayoutForm from "components/form/LayoutForm";
import TextWarehouse from "components/TextWarehouse";
import ProductInventoryCheckingDetailList from "features/productinventorycheckingdetail/list/ProductInventoryCheckingDetailList";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import ProductInventoryCheckingBalanceFormModal from "./ProductInventoryCheckingBalanceUserFormModal";

import type { ProductInventoryCheckingJsonAddEdit } from "common/types/ProductInventoryChecking";
import { ProductJson2, ProductJsonPublic } from "common/types/Product";
import ProductInventoryCheckingUserFormHeader from "./ProductInventoryCheckingUserFormHeader";
import ProductRepository from "common/repositories/ProductRepository";
import BaseApi from "common/api/BaseApi";
import { ProductInventoryCheckingDetailJsonAdd } from "common/types/ProductInventoryCheckingDetail";
import ProductInventoryCheckingDetailApi from "common/api/ProductInventoryCheckingDetailApi";
import ProductInventoryCheckingFormStatus from "features/productinventorychecking/form/ProductInventoryCheckingFormStatus";
import ProductInventoryCheckingDetailRepository from "common/repositories/ProductInventoryCheckingDetailRepository";

const ProductInventoryCheckingUserForm = ({
	model,
	refetch,
}: {
	model: ProductInventoryCheckingModel;
	refetch?: () => void;
}) => {
	const allow = true;
	const [api, contextHolder] = notification.useNotification();

	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);

	const havePermission = useMemo(() => {
		return (
			hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, model.warehouse_id) ||
			hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, model.warehouse_id) ||
			hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, model.warehouse_id) ||
			hasRoleAndLimit(Role.INVENTORY_STATUS_CANCEL, model.warehouse_id)
		);
	}, [hasRoleAndLimit, model.warehouse_id]);

	const isDisable =
		false &&
		(!havePermission ||
			model.status === ProductInventoryChecking.STATUS_CANCELLED);
	const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
		"warehouse",
		Role.INVENTORY_STOCK_CHECK
	);

	const [status, setStatus] = useState(model.status);

	const { t } = useTranslation();
	const [form] = Form.useForm();

	const isEditing = model.id > 0;
	const isAllowEdit =
		model.status !== ProductInventoryChecking.STATUS_PROCESSING &&
		havePermission &&
		model.status < ProductInventoryChecking.STATUS_COMPLETED &&
		status === ProductInventoryChecking.STATUS_PENDING;

	const [detailItems, setDetailItems] = useState<
		ProductInventoryCheckingDetailModel[]
	>([]);

	const [Items, setItems] = useState<ProductJsonPublic[]>([]);

	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [productIventoryCheckingModel, setProductIventoryCheckingModel] =
		useState<ProductInventoryCheckingModel>(model);
	const [modalVisible, setModalVisible] = useState(false);
	const [defaultWarehouseId, setDefaultWarehouseId] = useState<number>(
		model.warehouse_id
	);

	const [isWarning, setIsWarning] = useState(false);

	const initialValues = {
		note: model.note,
		description: model.description,
		warning: model.warning === 1 ? true : false,

		warehouse_id: model.id > 0 ? model.warehouse_id : null,
		status:
			model.status > 0 ? model.status : ProductInventoryChecking.STATUS_PENDING,
	};

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: ProductInventoryCheckingJsonAddEdit = {
				id: model.id,
				warehouse_id: defaultWarehouseId,
				note: formData.note,
				status: formData.status,
				description: formData.description,
				attachments: [],
				// details: detailItems.map((item) => {
				// 	return {
				// 		id: item.id,
				// 		note: item.note,
				// 		product_id: item.product_id,
				// 		quality: item.quality,
				// 		quantity: item.quantity,
				// 		serial_number: "",
				// 	};
				// }),
			};

			return submitData;
		},
		[model.id, detailItems, defaultWarehouseId]
	);

	async function createOrUpdateData(formData: any) {
		setIsSuccess(false);
		if (!detailItems.length) {
			notification.error({ message: "Chi tiết phiếu kiểm kho trống" });
			return;
		}

		if (!defaultWarehouseId) {
			notification.error({ message: "Vui lòng chọn kho kiểm" });
			return;
		}

		if (isEditing) {
			if (detailItems.length) {
				const details = detailItems
					.filter((item) => !item.id)
					.map((item: any) => {
						return {
							receipt_id: model.id,
							note: item.note,
							product_id: item.product_id,
							quality: item.quality,
							quantity: item.quantity,
							serial_number: "",
						};
					});
				await createItems(details);
			}
		}

		let myObj: ProductInventoryCheckingModel =
			await new ProductInventoryCheckingRepository().saveRemote(
				doPrepareData(formData)
			);

		if (myObj.hasError()) {
			setErrors(myObj.error.errors);
		} else {
			if (!isEditing) {
				let items: ProductInventoryCheckingDetailModel[] = [];
				if (detailItems.length) {
					const details = detailItems
						.filter((item) => !item.id)
						.map((item: any) => {
							return {
								receipt_id: myObj.id,
								note: item.note,
								product_id: item.product_id,
								quality: item.quality,
								quantity: item.quantity,
								serial_number: "",
							};
						});
					items = await createItems(details);
					if (details.length) {
						setDetailItems(items);
					}
				}
			}
			refetch?.();
			setStatus(myObj.toJson().status);
			setIsSuccess(true);
			setProductIventoryCheckingModel(myObj);
		}
	}

	async function createItems(items: ProductInventoryCheckingDetailJsonAdd[]) {
		const res = await Promise.all(
			items.map((item) => {
				return new ProductInventoryCheckingDetailRepository().saveRemoteAdd(
					item
				);
			})
		);
		return res;
	}

	//submit data to server
	const onSubmit = async (formData: any) => {
		setIsSubmit(true);
		setErrors([]);
		const res = await checkSystemQuantity(doPrepareData(formData));
		if (res) {
			await createOrUpdateData(formData);
		}

		setIsSubmit(false);
	};

	const checkSystemQuantity = async (
		formData: ProductInventoryCheckingJsonAddEdit
	) => {
		if (
			formData.status === ProductInventoryChecking.STATUS_PROCESSING &&
			isAllowEdit &&
			isEditing
		) {
			// gọi api check chênh lệch tồn kho hệ thống
			try {
				const dataCheck =
					await new ProductInventoryCheckingRepository().checkSytemQuantiry(
						model.id
					);
				if (dataCheck && dataCheck?.skewed_product.length) {
					const productCollection = await new ProductRepository().getItems({
						filters: {
							...ProductRepository.getDefaultFilters(),
							store_id: model.warehouse_id,
							limit: 500,
							list_product_id: dataCheck.skewed_product.join(","),
						},
					});
					const products = productCollection.items;

					// setIsWarning(true);
					api.warning({
						key: "system_quantity",
						message: "Các sản phẩm có phát sinh thay đổi số lượng",

						description: (
							<>
								<Typography.Text>
									Bạn vui lòng tiến hành kiểm tra các sku sau:
								</Typography.Text>

								<div className="max-h-[60vh] overflow-auto">
									<List
										dataSource={dataCheck.skewed_product}
										renderItem={(item, index) => {
											const productInventoryCheckin = products.find(
												(i) => i.id === item
											);

											const itemInventoryCheckim = detailItems.find(
												(i) => i.product_id === item
											);
											return (
												<List.Item>
													<Row>
														<Col span={24}>
															{productInventoryCheckin ? (
																<>
																	<Typography.Text
																		className="block w-full"
																		mark>
																		[{productInventoryCheckin.sku}]
																	</Typography.Text>{" "}
																	<Typography.Text className="block w-full">
																		[{productInventoryCheckin.full_name}]
																	</Typography.Text>{" "}
																</>
															) : (
																<Tag color="warning">
																	<Typography.Text
																		copyable
																		className="block w-full">
																		{itemInventoryCheckim?.product_sku}
																	</Typography.Text>{" "}
																</Tag>
															)}
														</Col>
													</Row>
												</List.Item>
											);
										}}></List>
								</div>
							</>
						),
						duration: 0,
						closeIcon: null,
						btn: (
							<Space>
								<Button
									type="primary"
									size="small"
									onClick={() => {
										api.destroy("system_quantity");
										createOrUpdateData(formData);
									}}>
									Xác nhận
								</Button>
							</Space>
						),
						onClick: () => {
							console.log("Notification Clicked!");
						},
						// onClose: async () => {
						//   form.setFieldValue(
						//     "status",
						//     ProductInventoryChecking.STATUS_PENDING
						//   );
						//   model.status = ProductInventoryChecking.STATUS_PENDING;
						//   let myObj: ProductInventoryCheckingModel =
						//     await new ProductInventoryCheckingRepository().saveRemote({
						//       ...formData,
						//       status: ProductInventoryChecking.STATUS_PENDING,
						//     });
						//   if (myObj) {
						//   }
						// },
					});
					return false;
				}
				return true;
			} catch (error) {
				const dataError = BaseApi.handleError(error);
				setErrors(dataError.errors);
				return false;
			}
		}
		return true;
	};

	//set default warehouse if only one warehouse
	useEffect(() => {
		if (
			warehouseItemsWithLimit.length === 1 &&
			initialValues.warehouse_id === null
		) {
			form.setFieldValue("warehouse_id", warehouseItemsWithLimit[0].id);
		}
	}, [warehouseItemsWithLimit, initialValues.warehouse_id, form]);

	useEffect(() => {
		setDetailItems(model.data_detail);
	}, [model.data_detail]);

	let sidebarItems = (
		<>
			{isEditing ? (
				<div className="pb-4 mb-4 border-b border-gray-300">
					{t("productinventorychecking:form.warehouse_id")}
					: <br />
					<span className="text-lg font-bold">
						<TextWarehouse id={model.warehouse_id} />
					</span>
				</div>
			) : (
				<>
					{defaultWarehouseId > 0 ? (
						<div className="pb-4 mb-4 border-b border-gray-300">
							{t("productinventorychecking:form.warehouse_id")}
							: <br />
							<span className="text-lg font-bold">
								<TextWarehouse id={defaultWarehouseId} />
							</span>
							<Popconfirm
								title="Khi đổi kho thì danh sách sản phẩm vừa thêm vào phiếu trước đó sẽ bị mất. Bạn có chắc chắn muốn đổi kho?"
								onConfirm={() => {
									setDefaultWarehouseId(0);
									setDetailItems([]);
									form.setFieldValue("warehouse_id", null);
								}}
								okText="Có"
								cancelText="Không">
								<span className="inline-block p-3 text-xs text-red-500 cursor-pointer">
									[{"Đổi kho"}]
								</span>
							</Popconfirm>
						</div>
					) : (
						<WarehouseFormSelect
							size="large"
							required
							disabled={model.id > 0}
							label={t("productinventorychecking:form.warehouse_id")}
							name="warehouse_id"
							rules={[
								{
									required: true,
									message: "Vui lòng chọn",
								},
							]}
							initOptions={warehouseItemsWithLimit.map((i) => ({
								value: i.id,
								label: i.name,
							}))}
							onChangeSelect={(value) => {
								if (value !== undefined) {
									setDefaultWarehouseId(+value);
								}
							}}
						/>
					)}
				</>
			)}
			{/* <Form.Item
				label={t("Có lệch tồn kho")}
				name="warning"
				valuePropName="checked">
				<Checkbox disabled={!isAllowEdit} />
			</Form.Item> */}
			<Form.Item label={t("productinventorychecking:note")} name="note">
				<Input.TextArea rows={3} disabled={!isAllowEdit} />
			</Form.Item>

			<Form.Item
				label={t("productinventorychecking:description")}
				name="description">
				<Input.TextArea rows={3} disabled={!isAllowEdit} />
			</Form.Item>
		</>
	);
	return (
		<>
			{contextHolder}
			<ProductInventoryCheckingUserFormHeader
				isEditing={isEditing}
				id={model.id}
			/>

			<LayoutForm
				disable_message
				disable_status_loading
				messageKeyForm=""
				form={form}
				initialValues={initialValues}
				errors={errors}
				submitDisabled={!allow && (!isAllowEdit || isSubmit)}
				isSuccess={isSuccess}
				successTitle={
					isEditing
						? t("productinventorychecking:form.success.update")
						: t("productinventorychecking:form.success.add")
				}
				redirectOnSuccess={
					model.id === 0 ? "/productinventorycheckinguser" : ""
				}
				error_translate_prefix="productinventorychecking:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={<>{sidebarItems}</>}>
				<div className="my-6">
					<ProductInventoryCheckingFormStatus
						isDisable={isDisable}
						status={model.status}
						isedit={isEditing}
						role="employee"
					/>
				</div>
				<ProductInventoryCheckingDetailList
					isAllowEdit={isAllowEdit}
					productIventoryCheckingModel={productIventoryCheckingModel}
					detailItems={detailItems}
					setDetailItems={setDetailItems}
					defaultWarehouseId={defaultWarehouseId}
					isFull={false}
					isDisable={!allow && isEditing}
					refetch={refetch}
				/>

				{/* <div className=" my-6">
					<ProductInventoryCheckingFormAttachments
						isEditing={isEditing}
						files={attachments}
						isAllowEdit={isAllowEdit}
					/>
				</div> */}

				{/* {havePermission ? (
					<>
						{model.id > 0 && detailItems.length > 0 ? (
							<Button
								disabled={!isAllowEdit}
								className="p-0 m-0"
								type="link"
								onClick={() => setModalVisible(true)}>
								{t("productinventorychecking:form.create_balance_sheet")}
							</Button>
						) : null}
					</>
				) : null} */}
			</LayoutForm>
			{modalVisible ? (
				<ProductInventoryCheckingBalanceFormModal
					open={modalVisible}
					setOpen={setModalVisible}
					warehouse_id={model.warehouse_id}
					detailItems={detailItems}
				/>
			) : null}
		</>
	);
};

export default ProductInventoryCheckingUserForm;
