import LayoutTitle from "components/LayoutTitle";
import PageSiteMenu from "components/page/PageSiteMenu";
import { useTranslation } from "react-i18next";

import CompanyInfoGeneral from "./CompanyInfoGeneral";
import CompanyInfoSystem from "./CompanyInfoSystem";

const CompanyInfoIndex = () => {
  const { t } = useTranslation();

  return (
    <>
      <LayoutTitle title={t("company:heading_info")} />
      <PageSiteMenu siteMenuSelectedKey={""} siteMenuHidden />

      <div className="bg-gray-100">
        <CompanyInfoGeneral />
        <CompanyInfoSystem />
      </div>
    </>
  );
};

export default CompanyInfoIndex;
