import { Alert, Button, Tag } from "antd";
import CompanyResourceModel from "common/models/CompanyResourceModel";
import { CompanyResourceJson } from "common/types/CompanyResource";
import ErrorAlert from "components/ErrorAlert";
import InfoContent from "components/InfoContent";
import InfoContentItem from "components/InfoContentItem";
import db from "db";
import { map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconTable } from "@tabler/icons-react";

import CompanyInfoSystemCompanyResourceDataModal from "./CompanyInfoSystemCompanyResourceDataModal";

const CompanyInfoSystemCompanyResource = () => {
  const { t } = useTranslation();

  const companyId = useLoginAccountStore((state) => state.account.company.id);
  const [companyResource, setCompanyResource] = useState<
    CompanyResourceModel | undefined
  >();
  const [errors, setErrors] = useState<string[]>([]);
  const [viewingTable, setViewingTable] = useState("");

  const viewLocalData = useCallback((table: string) => {
    setViewingTable(table);
  }, []);
  /**
   * Get companyresource from db (indexdb)
   */
  const fetchLocalCompanyResource = useCallback(() => {
    db.table("companyresource")
      .where("company_id")
      .equals(companyId)
      .first()
      .then((data: CompanyResourceJson) => {
        setCompanyResource(new CompanyResourceModel(data));
      })
      .catch((e) => {
        setErrors(["Error while finding companyresource on local indexDb"]);
        console.log(e);
      });
  }, [companyId]);

  //trigger load if not init companyresource
  useEffect(() => {
    if (typeof companyResource == "undefined" && errors.length === 0) {
      fetchLocalCompanyResource();
    }
  }, [fetchLocalCompanyResource, companyResource, errors]);

  return (
    <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8">
      {typeof companyResource === "undefined" && errors.length > 0 ? (
        <div className="mx-auto max-w-4xl">
          <ErrorAlert items={errors} />
        </div>
      ) : (
        <>
          {typeof companyResource !== "undefined" ? (
            <>
              <InfoContent
                title={"IndexedDB"}
                subTitle={
                  <div className="mt-4">
                    <Alert
                      type="info"
                      message={
                        <span className="">
                          {t(
                            "company:info.system.companyresource_infobox_text1"
                          )}
                          <br />
                          <span className="text-xs">
                            {t(
                              "company:info.system.companyresource_infobox_text2"
                            )}
                          </span>
                        </span>
                      }
                    />
                  </div>
                }
              >
                <InfoContentItem
                  className="bg-gray-50"
                  size="small"
                  label={
                    <span className="uppercase font-bold">
                      {t("company:info.system.resourcetable")}
                    </span>
                  }
                  content={
                    <span className="uppercase font-bold">
                      {t("company:info.system.resourceversion")}
                    </span>
                  }
                />
                {map(companyResource.getJsVersionForUi(), (value, key) => {
                  return (
                    <InfoContentItem
                      key={key}
                      label={
                        <span title={key}>
                          <IconTable
                            className="mr-1 -mt-1 text-green-400"
                            size={18}
                          />
                          {t("company:info.companyresource." + key)}
                        </span>
                      }
                      content={
                        <div className="flex">
                          <div className="flex-grow">
                            <Tag color="green" className="float-right">
                              {key}
                            </Tag>
                            <span className="font-mono text-xs font-semibold">
                              v.{value}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Button
                              type="link"
                              onClick={() => viewLocalData(key)}
                              size="small"
                            >
                              <span className="text-xs">
                                {t(
                                  "company:info.system.companyresource_view_button"
                                )}
                              </span>
                            </Button>
                          </div>
                        </div>
                      }
                      size="small"
                    />
                  );
                })}
              </InfoContent>
            </>
          ) : null}
        </>
      )}
      {viewingTable.length > 0 && typeof companyResource !== "undefined" ? (
        <CompanyInfoSystemCompanyResourceDataModal
          key={viewingTable}
          companyResource={companyResource}
          table={viewingTable}
          onClose={() => setViewingTable("")}
        />
      ) : null}
    </div>
  );
};

export default CompanyInfoSystemCompanyResource;
