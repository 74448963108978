import CompanySettingApi from "common/api/CompanySettingApi";
import CompanySettingModel from "common/models/CompanySettingModel";

import BaseRepository from "./BaseRepository";

import type { CompanySettingEntry } from "common/types/CompanySetting";
class CompanySettingRepository extends BaseRepository<CompanySettingApi> {
  _api: CompanySettingApi | null;

  constructor() {
    super();
    this._api = new CompanySettingApi(true);
  }

  async updateSettingGeneral(
    data: CompanySettingEntry
  ): Promise<CompanySettingModel> {
    const emptyObj = new CompanySettingModel(
      CompanySettingModel.getDefaultData()
    );

    if (this._api) {
      return await this._api.updateSettingGeneral(data);
    } else {
      return emptyObj;
    }
  }

  async updateSettingTax(
    data: CompanySettingEntry
  ): Promise<CompanySettingModel> {
    const emptyObj = new CompanySettingModel(
      CompanySettingModel.getDefaultData()
    );

    if (this._api) {
      return await this._api.updateSettingTax(data);
    } else {
      return emptyObj;
    }
  }

  async getCurrentSettings(): Promise<CompanySettingModel> {
    return this._api
      ? await this._api.getCurrentSettings()
      : new CompanySettingModel(CompanySettingModel.getDefaultData());
  }
}

export default CompanySettingRepository;
