// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__AztBJ {
}

.styles_title__JKMAH {
  color: #000;
  font-weight: 700;
  font-size: 16px;
  border-left: 5px solid var(--active-color);
  margin: 48px 0;
  padding-left: 8px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .styles_title__JKMAH {
    margin: 24px 0;
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyRelated/styles.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,0CAA0C;EAC1C,cAAc;EACd,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE;IACE,cAAc;IACd,eAAe;EACjB;AACF","sourcesContent":[".wrapper {\n}\n\n.title {\n  color: #000;\n  font-weight: 700;\n  font-size: 16px;\n  border-left: 5px solid var(--active-color);\n  margin: 48px 0;\n  padding-left: 8px;\n  text-transform: uppercase;\n}\n\n@media screen and (max-width: 768px) {\n  .title {\n    margin: 24px 0;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__AztBJ`,
	"title": `styles_title__JKMAH`
};
export default ___CSS_LOADER_EXPORT___;
