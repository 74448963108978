import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { ProductInventoryCheckingDetailJson } from "common/types/ProductInventoryCheckingDetail";

class ProductInventoryCheckingDetailModel
  extends BaseModel
  implements BaseModelJson<ProductInventoryCheckingDetailJson>
{
  company_id: number;
  creator_id: number;
  product_id: number;
  product_sku: string;
  id: number;
  quantity: number;
  quality: number;
  system_quantity: number;
  system_quantity_date: number;
  unitprice: number;
  subtotal: number;
  note: string;
  changelog: any;
  date_created: number;
  date_modified: number;
  deviant_quantity: number;

  constructor(json: ProductInventoryCheckingDetailJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.product_id = json.product_id || 0;
    this.id = json.id || 0;
    this.product_sku = json.product_sku || "";
    this.quantity = json.quantity || 0;
    this.system_quantity = json.system_quantity || 0;
    this.system_quantity_date = json.system_quantity_date || 0;
    this.unitprice = json.unitprice || 0;
    this.subtotal = json.subtotal || 0;
    this.note = json.note || "";
    this.changelog = json.changelog || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.deviant_quantity = json.quantity - json.system_quantity;
    this.quality = json.quality || 0;
  }

  static getDefaultData(): ProductInventoryCheckingDetailJson {
    return {
      company_id: 0,
      creator_id: 0,
      product_id: 0,
      product_sku: "",
      id: 0,
      quantity: 0,
      system_quantity: 0,
      system_quantity_date: 0,
      unitprice: 0,
      subtotal: 0,
      note: "",
      changelog: "",
      date_created: 0,
      date_modified: 0,
      quality : 0
    };
  }

  toJson(): ProductInventoryCheckingDetailJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      product_id: this.product_id,
      product_sku: this.product_sku,
      id: this.id,
      quantity: this.quantity,
      system_quantity: this.system_quantity,
      system_quantity_date: this.system_quantity_date,
      unitprice: this.unitprice,
      subtotal: this.subtotal,
      note: this.note,
      changelog: this.changelog,
      date_created: this.date_created,
      date_modified: this.date_modified,
      quality : this.quality
    };
  }
}

export default ProductInventoryCheckingDetailModel;
