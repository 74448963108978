import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import OrderCollection from "common/collections/OrderCollection";
import Order from "common/constants/Order";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import OrderModel from "common/models/OrderModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import OrderRepository from "common/repositories/OrderRepository";
import { FilterOrder, OrderJson } from "common/types/Order";
import { TableColumnsType } from "common/types/Table";
import { TaxInvoiceJson } from "common/types/TaxInvoice";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import TextRegion from "components/TextRegion";
import TextStore from "components/TextStore";
import TextTagList from "components/TextTagList";
import OrderListHeader from "features/order/list/OrderListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconDiscountCheck, IconTruck } from "@tabler/icons-react";

import OrderItemEcomPlatformTypeImage from "../item/OrderItemEcomPlatformTypeImage";
import OrderListFilter from "./OrderListFilter";
import Helper from "common/utils/helper";
import {
  EyeOutlined,
  MoreOutlined,
  SmallDashOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";
import OrderQuickView from "./OrderQuickView";
import Delivery from "common/constants/Delivery";

const OrderList = () => {
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);

  const [orderView, setOrderView] = useState(OrderModel.getDefaultData());
  const [openOrderView, setOpenOrderView] = useState(false);
  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterOrder = useMemo(() => {
    return {
      ...OrderRepository.getDefaultFilters(),
      type: Order.TYPE_INVOICE_ORDER,
    };
  }, []);
  const [filters, setFilters] = useStateFilter<FilterOrder>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<OrderModel[]>([]);

  const fetchData = useCallback(async (): Promise<OrderCollection> => {
    let collection = await new OrderRepository().getItems({
      filters,
    });

    setDataSource(collection.items);
    setTotal(collection.total);

    return collection;
  }, [filters]);

  function handlerOpenView(item: OrderJson) {
    setOrderView(item);
    setOpenOrderView(true);
  }

  //Table columns
  const columns: TableColumnsType<OrderModel> = [
    {
      title: " ",
      key: "",
      align: "left",
      width: 50,
      render: (id: number, record) => {
        return (
          <Button
            onClick={() => handlerOpenView(record.toJson())}
            type="text"
            title="Xem chi tiết"
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
    {
      title: t("order:id"),
      key: "code",
      width: 160,
      align: "center",
      toggletype: "ignore",
      render: (code, record) => {
        return (
          <RoleCheck roles={[Role.ORDER_EDIT]}>
            <TableLinkText
              title={t("order:detail_button")}
              link={"/order/edit/id/" + record.id}
              className="text-base font-bold"
            >
              <Typography.Text className="text-blue-600" copyable>
                {code}
              </Typography.Text>
            </TableLinkText>
          </RoleCheck>
        );
      },
    },
    {
      title: " ",
      togglelabel: t("order:ecom_platform_type"),
      key: "ecom_platform_type",
      width: 50,
      toggletype: "extend",
      render: (ecom_platform_type: number) => {
        const typeInfo = OrderModel.getEcomPlatformType(ecom_platform_type);
        return (
          <Tooltip
            placement="topLeft"
            mouseEnterDelay={0.4}
            title={
              <>
                {t("order:ecom_platform_type_prefix")}&nbsp;
                {typeInfo?.label}
              </>
            }
          >
            <div>
              <OrderItemEcomPlatformTypeImage
                type={ecom_platform_type}
                width={40}
                height={40}
                className="p-1"
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t("cashflowreceipt:store"),
      key: "store_id",
      toggletype: "extend",
      render: (store_id: number, record) => {
        return (
          <>
            <TextStore id={store_id} />
            <br />
            {record.tag.length > 0 ? (
              <TextTagList<FilterOrder>
                tag={record.tag}
                filters={filters}
                filterName={"tag"}
                setFilters={setFilters}
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: t("order:billing"),
      key: "",
      width: 140,
      render: (_: any, record: OrderModel) => {
        const billingInfor = {
          billing_fullname: record.billing_fullname.length
            ? record.billing_fullname
            : record.shipping_fullname,
          billing_phone: record.billing_phone.length
            ? record.billing_phone
            : record.shipping_phone,
          billing_province:
            record.billing_province > 0
              ? record.billing_province
              : record.shipping_province,
        };
        return (
          <>
            <div>
              <div>
                <span className="">{billingInfor.billing_fullname}</span>
                <span className=""> / {billingInfor.billing_phone}</span>
              </div>
              <div className="text-xs text-gray-500">
                <TextRegion id={billingInfor.billing_province} />
              </div>
            </div>
          </>
        );
      },
    },
    // {
    //   title: t("order:price_discount"),
    //   key: "price_discount",
    //   align: "right",
    //   toggletype: "extend",
    //   render: (price_discount: number, record) => {
    //     return (
    //       <>
    //         {record.promotion_id > 0 &&
    //         typeof record.promotion_detail === "object" ? (
    //           <Tooltip
    //             title={<>{record.promotion_detail}</>}
    //             mouseEnterDelay={0.4}
    //           >
    //             <IconDiscountCheck
    //               size={16}
    //               className="mr-1 -mt-0.5"
    //               color="green"
    //             />
    //           </Tooltip>
    //         ) : null}
    //         <TextMoney money={price_discount} />
    //       </>
    //     );
    //   },
    // },
    // {
    // 	title: " ",
    // 	togglelabel: t("order:tax_invoice"),
    // 	key: "tax_invoice_list",
    // 	align: "center",
    // 	width: 80,
    // 	render: (tax_invoice_list: TaxInvoiceJson[]) => {
    // 		return (
    // 			<div className="leading-none">
    // 				{tax_invoice_list.map((item) => (
    // 					<OrderTaxInvoiceItem key={item.id} data={item} />
    // 				))}
    // 			</div>
    // 		);
    // 	},
    // },
    {
      title: t("order:date_created"),
      key: "date_created",
      width: 140,
      render: (ts: number) => <p>{Helper.formatTimestampToText(ts)}</p>,
    },
    {
      title: t("order:price_final"),
      key: "price_final",
      align: "right",
      width: 120,
      render: (price_final: number) => {
        return (
          <span className="font-bold">
            <TextMoney money={price_final} />
          </span>
        );
      },
    },

    // {
    // 	title: (
    // 		<>
    // 			<Tooltip
    // 				title={t("order:product_receipt_column_tooltip")}
    // 				mouseEnterDelay={0.4}>
    // 				{t("order:product_receipt_column")}
    // 			</Tooltip>
    // 		</>
    // 	),
    // 	togglelabel: t("order:product_receipt_column_tooltip"),
    // 	key: "product_receipt_list",
    // 	align: "left",
    // 	width: 130,
    // 	render: (product_receipt_list: ProductReceiptModel[]) => {
    // 		return (
    // 			<>
    // 				{product_receipt_list.map((item) => (
    // 					<OrderProductReceiptItem key={item.id} data={item} />
    // 				))}
    // 			</>
    // 		);
    // 	},
    // },
    // {
    // 	title: (
    // 		<>
    // 			<Tooltip
    // 				title={t("order:cashflow_column_tooltip")}
    // 				mouseEnterDelay={0.4}>
    // 				{t("order:cashflow_column")}
    // 			</Tooltip>
    // 		</>
    // 	),
    // 	togglelabel: t("order:cashflow_column_tooltip"),
    // 	key: "cashflow_receipt_list",
    // 	align: "left",
    // 	width: 130,
    // 	render: (cashflow_receipt_list: CashflowReceiptModel[]) => {
    // 		return (
    // 			<div className="leading-none">
    // 				{cashflow_receipt_list.map((item) => (
    // 					<OrderCashflowReceiptItem key={item.id} data={item} />
    // 				))}
    // 			</div>
    // 		);
    // 	},
    // },
    {
      title: t("common:status"),
      key: "status",
      render: (status: number, record) => {
        let statusInfo = OrderModel.getStatus(status, record.type);
        return (
          <>
            <Tag bordered color={statusInfo?.color}>
              {statusInfo?.label}
            </Tag>
            {status === Order.STATUS_CANCEL && record.cancel_reason > 0 ? (
              <div
                className="px-2 text-xs text-red-400"
                style={{ fontSize: 9 }}
              >
                {OrderModel.getCancelReason(record.cancel_reason)?.label}
              </div>
            ) : null}
          </>
        );
      },
      width: 180,
    },
    {
      title: t("Thanh toán"),
      key: "payment_status",
      render: (payment_status: number, record) => {
        let statusInfo = OrderModel.getStatusPayment(payment_status);
        return (
          <>
            <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
          </>
        );
      },
      width: 180,
    },
    {
      title: t("Giao hàng"),
      key: "delivery_status",
      render: (status: number, record) => {
        let statusInfo = OrderModel.getStatusDelivery(status);
        return (
          <>
            <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
          </>
        );
      },
      width: 180,
    },

    {
      title: " ",
      toggletype: "trigger",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 120,
      render: (id: number, record) => {
        const delis = record.delivery_lits_id;
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <RoleCheck roles={[Role.DELIVERY_MANAGER]}>
                <Link
                  to={`/delivery/manager?soandcode=${encodeURIComponent(
                    record.code
                  )}`}
                >
                  <Button type="text" title="Xem vận đơn">
                    Xem vận đơn
                  </Button>
                </Link>
              </RoleCheck>
            ),
          },
          {
            key: "2",
            label: (
              <RoleCheck roles={[Role.DELIVERY_MANAGER]}>
                <Link to={`/delivery/add/id/${record.id}`}>
                  <Button type="text" title="Tạo vận đơn">
                    Tạo vận đơn
                  </Button>
                </Link>
              </RoleCheck>
            ),
          },
          // {
          // 	key: "3",
          // 	label: <TableInfo record={record} className="mr-4" />,
          // },
        ];
        return (
          <>
            <Dropdown trigger={["click"]} menu={{ items }}>
              <MoreOutlined
                style={{ fontSize: "16pt" }}
                className="cursor-pointer "
              />
            </Dropdown>
            <TableInfo record={record} className="mr-4" />

            {/* <Button
              className=" w-full"
              onClick={() => handlerOpenView(record.toJson())}
              type="primary"
              title="Xem chi tiết"
            >
              <EyeOutlined />
            </Button> */}
            {/* <div className="flex  items-center pr-4">
							<Button
								onClick={() => handlerOpenView(record.toJson())}
								type="text"
								title="Xem chi tiết">
								<EyeOutlined />
							</Button>
							{false ? (
								<RoleCheck roles={[Role.DELIVERY_MANAGER]}>
									<Link to={`/delivery/manager?soandcode=${record.code}`}>
										<Button type="text" title="Xem vận đơn">
											Xem vận đơn
										</Button>
									</Link>
								</RoleCheck>
							) : (
								<RoleCheck roles={[Role.DELIVERY_MANAGER]}>
									<Link to={`/delivery/add/id/${record.id}`}>
										<Button type="text" title="Tạo vận đơn">
											Tạo vận đơn
										</Button>
									</Link>
								</RoleCheck>
							)}
							<TableInfo record={record} className="mr-4" />
						</div> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [fetchData]);

  return (
    <>
      <OrderListHeader />

      <OrderListFilter<FilterOrder>
        filters={{
          ...filters,
          type: !isNaN(Number(filters.type)) ? Number(filters.type) : -1,
        }}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterOrder, OrderModel, OrderCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "order",
        }}
      />

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <Modal
        open={openOrderView}
        onCancel={() => setOpenOrderView(false)}
        style={{ minWidth: 1000 }}
        destroyOnClose
        centered
        title={
          <>
            <p className=" flex gap-2 items-center">
              {t(
                orderView.type === Order.TYPE_INVOICE ? `Hóa đơn:` : `Đặt hàng:`
              )}
              <Link to={`/order/edit/id/${orderView.id}`}>
                #{orderView.code}
              </Link>
            </p>
          </>
        }
        bodyStyle={{ maxHeight: 540, overflow: "auto" }}
        footer={null}
        // footer={<div className=" flex gap-4  justify-end">
        // 	<RoleCheck roles={[Role.CASHFLOW_EDIT]} hideOnFail>
        // 		<Link target="_blank" to={`/cashflowreceipt/edit/id/${orderView?.id}`}>
        // 			<Button color="blue">Cập nhập</Button>
        // 		</Link>
        // 	</RoleCheck>

        // 	<Button onClick={() => setOpenOrderView(false)}>Đóng</Button>
        // </div>}
      >
        {orderView && (
          <OrderQuickView orderId={orderView.id} onUpdate={fetchData} />
        )}
      </Modal>
    </>
  );
};

export default OrderList;
