import { App, Col, Form, Input, InputNumber, Row } from "antd";
import ProductVariantColor from "common/constants/ProductVariantColor";
import ProductVariantColorModel from "common/models/ProductVariantColorModel";
import ProductVariantColorRepository from "common/repositories/ProductVariantColorRepository";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { ProductVariantColorJsonAddEdit } from "common/types/ProductVariantColor";
const ProductVariantColorForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: ProductVariantColorModel;
  setOpen: (v: boolean) => void;
  setProcessing: (p: boolean) => void;
  onSaveSuccess: (o: ProductVariantColorModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      description: model.description,
      display_order: model.display_order
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProductVariantColorJsonAddEdit = {
        ...ProductVariantColorModel.getDefaultData(),
        id: model.id,
        code: model.code,
        name: formData.name,
        description: formData.description,
        display_order: formData.display_order,
        status: formData.status || ProductVariantColor.STATUS_ENABLE
      };

      return submitData;
    },
    [model.id, model.code]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ProductVariantColorModel =
      await new ProductVariantColorRepository().saveRemote(
        doPrepareData(formData)
      );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="productvariantcolor:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("productvariantcolor:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("productvariantcolor:form.error.error_name_require")
              }
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("productvariantcolor:description")}
            name="description">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            label={t("common:display_order_long")}
            name="display_order"
            className="mb-0">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductVariantColorForm;
