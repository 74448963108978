import { Badge, Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import { SelectValue } from "antd/lib/select";
import { SelectOption } from "common/types/SelectOption";
import React from "react";

const SimpleFormSelect = ({
  value,
  onChange,
  name,
  rules,
  options,
  label,
  noStyle,
  required,
  className,
  popupClassName,
  allowClear,
  disabled,
  size,
  placeholder,
}: {
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  name?: string;
  rules?: Rule[];
  options: SelectOption[];
  label?: React.ReactNode;
  noStyle?: boolean;
  required?: boolean;
  className?: string;
  popupClassName?: string;
  allowClear?: boolean;
  disabled?: boolean;
  size?: "large" | "middle" | "small";
  placeholder?: string;
}) => {
  return (
    <Form.Item
      name={name}
      rules={rules}
      noStyle={noStyle}
      label={label}
      required={required}
      className={className}
    >
      <Select
        className="simple-form-select"
        value={value}
        onChange={onChange}
        style={{ width: "100%" }}
        allowClear={allowClear}
        disabled={disabled}
        size={size}
        popupClassName={popupClassName}
        placeholder={placeholder}
      >
        {options.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            disabled={option.disabled || false}
          >
            <Badge
              color={option?.color || "gray"}
              className="mr-1 inline-block"
            />{" "}
            <span className="inline-block mt-1">{option.label}</span>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
export default SimpleFormSelect;
