import BaseCollection from "common/collections/BaseCollection";
import FileModel from "common/models/FileModel";
import { FileJson, FileCollectionJson } from "common/types/File";

class FileCollection extends BaseCollection<
  FileJson,
  FileModel,
  FileCollectionJson
> {
  itemsFromJson(jsonItems: FileJson[]): FileModel[] {
    return jsonItems.map((item) => new FileModel(item));
  }
}

export default FileCollection;
