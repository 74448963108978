import { Popover } from "antd";
import {
  InventoryStock,
  InventoryStockNumberType,
} from "common/types/InventoryStock";
import { SearchResultItem } from "common/types/Pos";
import { findIndex } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import InventoryBySkuCellPopover from "./InventoryBySkuCellPopover";
import { Quantitie } from "common/types/Product";
import { cn } from "common/utils/utils";

const InventoryBySkuCell = ({
  data,
  activeWarehouseId,
  type,
}: {
  data: Quantitie[];
  activeWarehouseId: number;
  type: InventoryStockNumberType;
}) => {
  const [showPopover, setShowPopover] = useState(false);

  const [activeInventoryStock, setActiveInventoryStock] = useState<Quantitie>();

  const displayStockNumber = useCallback(
    (inventoryStock: Quantitie) => {
      switch (type) {
        case "onhand":
          return inventoryStock.quantity;
        case "allocated":
          return inventoryStock.reserved;
        case "incoming":
          return inventoryStock.in_coming;
        case "available":
          return inventoryStock.actual_quantity;
      }
    },
    [type]
  );

  useEffect(() => {
    const quantitie = data.find((da) => da.store_id === activeWarehouseId);

    if (quantitie) {
      setActiveInventoryStock(quantitie);
    } else {
      setActiveInventoryStock(undefined);
    }
  }, [data, activeWarehouseId]);

  return (
    // <span onClick={(e) => e.stopPropagation()}>
    //   <Popover
    //     overlayInnerStyle={{ width: 460 }}
    //     open={showPopover}
    //     onOpenChange={(open) => setShowPopover(open)}
    //     overlayClassName="pos-inventory-popover"
    //     className="pos-inventory-popover"
    //     placement="right"
    //     content={
    //       <InventoryBySkuCellPopover
    //         items={data.inventory_details}
    //         activeItem={activeInventoryStock}
    //         activeWarehouseId={activeWarehouseId}
    //         type={type}
    //       />
    //     }
    //     title={
    //       <span>
    //         {data.product_name} / {data.sku}
    //       </span>
    //     }
    //     trigger="click"
    //   >

    //   </Popover>
    // </span>
    <>
      {typeof activeInventoryStock !== "undefined" &&
      activeInventoryStock.store_id >= 0 ? (
        <span className={
          displayStockNumber(activeInventoryStock) > 0 ? " text-lg text-green-500" :" text-lg text-red-500"
        }>
          {displayStockNumber(activeInventoryStock)}
        </span>
      ) : (
        <span>Chưa có</span>
      )}
    </>
  );
};

export default InventoryBySkuCell;
