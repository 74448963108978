import { useTranslation } from "react-i18next";
import PageHeader from "components/page/PageHeader";

const CommentListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("comment:heading_list")}
      siteMenuOpenKey="content"
      siteMenuSelectedKey="/comment"
    ></PageHeader>
  );
};

export default CommentListHeader;
