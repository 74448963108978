import ProjectTaskCommentApi from 'common/api/ProjectTaskCommentApi';
import ProjectTaskCommentCollection from 'common/collections/ProjectTaskCommentCollection';
import ProjectTaskCommentModel from 'common/models/ProjectTaskCommentModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProjectTaskCommentJsonAdd,
  FilterProjectTaskComment,
} from "common/types/ProjectTaskComment";

class ProjectTaskCommentRepository extends BaseRepository<ProjectTaskCommentApi> {
  _api: ProjectTaskCommentApi | null;

  constructor() {
    super();
    this._api = new ProjectTaskCommentApi(true);
  }

  static getDefaultFilters(): FilterProjectTaskComment {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      project_task_id: 0,
      company_id: 0,
      comment_id_before: 0,
    };
  }

  async getItems(props: GetListProps<FilterProjectTaskComment>) {
    return this._api
      ? await this._api.getList(props)
      : new ProjectTaskCommentCollection();
  }

  async getItem(id: number): Promise<ProjectTaskCommentModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProjectTaskCommentModel(ProjectTaskCommentModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProjectTaskCommentJsonAdd
  ): Promise<ProjectTaskCommentModel> {
    if (this._api) {
      return await this._api.add(data);
    } else {
      return new ProjectTaskCommentModel(
        ProjectTaskCommentModel.getDefaultData()
      );
    }
  }
}

export default ProjectTaskCommentRepository;
