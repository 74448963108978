import { useEffect, useState } from "react";

import {
	FilterPromotion,
	PromotionCollectionJson,
	PromotionJson,
} from "common/types/Promotion";
import PromotionRepository from "common/repositories/PromotionRepository";

type Props = {
	defaultFilters?: Partial<FilterPromotion>;
	skip?: boolean;
};

export default function usePromotion({ defaultFilters, skip }: Props) {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [filters, setFilters] = useState(
		defaultFilters
			? Object.assign(
					{},
					{ ...PromotionRepository.getDefaultFilters(), ...defaultFilters }
			  )
			: PromotionRepository.getDefaultFilters()
	);
	const [dataSource, setDataSource] = useState<null | PromotionCollectionJson>(
		null
	);
	const [promotions, setPromotions] = useState<PromotionJson[]>([]);

	const fetchData = async (f?: Partial<FilterPromotion>) => {
		setLoading(true);
		const dataFilter = f ? Object.assign({}, { ...filters, ...f }) : filters;
		const res = await new PromotionRepository().getItems({
			filters: dataFilter,
		});
		if (!res.hasError()) {
			setDataSource(res.toJson());
			setPromotions(res.toJson().items);
		} else {
			setErrors(res.error.errors);
		}
		setLoading(false);
	};

	useEffect(() => {
		!skip && fetchData();
	}, [skip]);

	return { dataSource, promotions, loading, errors, fetchData };
}
