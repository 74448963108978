import DataGridWorkspaceApi from "common/api/DataGridWorkspaceApi";
import DataGridWorkspaceCollection from "common/collections/DataGridWorkspaceCollection";
import DataGridWorkspaceModel from "common/models/DataGridWorkspaceModel";
import BaseRepository from "./BaseRepository";
import { GetListProps } from "common/types/Filter";
import type { DataGridWorkspaceJsonAddEdit, FilterDataGridWorkspace } from "common/types/DataGridWorkspace";

class DataGridWorkspaceRepository extends BaseRepository<DataGridWorkspaceApi> {
  _api: DataGridWorkspaceApi | null;

  constructor() {
    super();
    this._api = new DataGridWorkspaceApi(true);
  }

  static getDefaultFilters(): FilterDataGridWorkspace {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterDataGridWorkspace>) {
    return this._api ? await this._api.getList(props) : new DataGridWorkspaceCollection();
  }

  async getItem(id: number): Promise<DataGridWorkspaceModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new DataGridWorkspaceModel(DataGridWorkspaceModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: DataGridWorkspaceJsonAddEdit): Promise<DataGridWorkspaceModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new DataGridWorkspaceModel(DataGridWorkspaceModel.getDefaultData());
    }
  }
}

export default DataGridWorkspaceRepository;
