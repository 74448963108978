import BaseRepository from "./BaseRepository";
import type { GetListProps } from "common/types/Filter";

//////////////////////////////////
//Phần thay thế
import { FilterTemplate, TemplateJsonAddEdit } from "common/types/Template";
import TemplateApi from "common/api/TemplateApi";
import TemplateCollection from "common/collections/TemplateCollection";
import TemplateModel from "common/models/TemplateModel";

class TemplateRepository extends BaseRepository<TemplateApi> {
  _api: TemplateApi | null;
  constructor() {
    super();
    this._api = new TemplateApi(true);
  }

  static getDefaultFilter(): FilterTemplate {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "DESC",
      keyword: "",
      status: "",
    };
  }

  async getItems(
    props: GetListProps<FilterTemplate>
  ): Promise<TemplateCollection> {
    return this._api
      ? await this._api.getList(props.filters)
      : new TemplateCollection();
  }

  async getItem(id: number): Promise<TemplateModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new TemplateModel(TemplateModel.getDefaultData());
  }

  async saveRemote(data: TemplateJsonAddEdit): Promise<TemplateModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new TemplateModel(TemplateModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default TemplateRepository;
