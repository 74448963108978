import { Button, Tag, Tooltip } from "antd";
import ShippingOrderCollection from "common/collections/ShippingOrderCollection";
import Role from "common/constants/Role";
import ShippingOrder from "common/constants/ShippingOrder";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import ShippingOrderRepository from "common/repositories/ShippingOrderRepository";
import { IdFetcherResult } from "common/types/IdFetcher";
import { FilterShippingOrder } from "common/types/ShippingOrder";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import dayjs from "dayjs";
import ShippingOrderListHeader from "features/shippingorder/list/ShippingOrderListHeader";
import TextEmptyCoord from "features/shippingorder/TextEmptyCoord";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconBox, IconFolder } from "@tabler/icons-react";

import ShippingOrderFormModal from "../form/ShippingOrderFormModal";
import ShippingOrderListFilter from "./ShippingOrderListFilter";

const ShippingOrderList = () => {
  const { t } = useTranslation();

  // modal editing
  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [editingOrder, setEditingOrder] = useState<ShippingOrderModel>(
    new ShippingOrderModel(ShippingOrderModel.getDefaultData())
  );
  const onClickEditOrder = (item: ShippingOrderModel) => {
    setEditingOrder(item);
    setOrderModalVisible(true);
  };

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShippingOrder = useMemo(
    () => ShippingOrderRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterShippingOrder>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  // call idfetcher
  const [shipperUser, setShipperUser] = useState<
    IdFetcherResult[] | undefined
  >();
  const [shippingHub, setShippingHub] = useState<
    IdFetcherResult[] | undefined
  >();
  const [shippingType, setShippingType] = useState<
    IdFetcherResult[] | undefined
  >();

  const fetchingShipperUser = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "shipper",
      id_list,
    });

    let results =
      collection.items.find((i) => i.object_type === "shipper")?.result || [];

    results.forEach((result, i) => {
      if (result.text === "[invalid]") {
        results[i].text = "---";
      }
    });

    setShipperUser(results);
  }, []);
  const fetchingShippingHub = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "shippinghub",
      id_list,
    });

    let results =
      collection.items.find((i) => i.object_type === "shippinghub")?.result ||
      [];

    results.forEach((result, i) => {
      if (result.text === "[invalid]") {
        results[i].text = "---";
      } else {
        results[i].text = results[i].text.toString();
      }
    });
    setShippingHub(results);
  }, []);
  const fetchingShippingType = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "shippingtype",
      id_list,
    });

    let results =
      collection.items.find((i) => i.object_type === "shippingtype")?.result ||
      [];

    results.forEach((result, i) => {
      if (result.text === "[invalid]") {
        results[i].text = "---";
      }
    });
    setShippingType(results);
  }, []);

  const mappingShipperIdsToFetcher = useCallback(
    (items: ShippingOrderModel[]) => {
      let shipperUserIdList = items
        .map((item) => item.shipper_id)
        .filter((i) => i > 0 && i !== undefined);
      if (shipperUserIdList.length > 0) {
        fetchingShipperUser(shipperUserIdList);
      }
    },
    [fetchingShipperUser]
  );
  const mappingShippingHubIdsToFetcher = useCallback(
    (items: ShippingOrderModel[]) => {
      let shippingHubIdList = items
        .map((item) =>
          item.from_type === ShippingOrder.FROM_TYPE_OFFICE ? item.office_id : 0
        )
        .filter((i) => i > 0 && i !== undefined);
      if (shippingHubIdList.length > 0) {
        fetchingShippingHub(shippingHubIdList);
      }
    },
    [fetchingShippingHub]
  );
  const mappingShippingTypeIdsToFetcher = useCallback(
    (items: ShippingOrderModel[]) => {
      let shippingTypeIdList = items
        .map((item) => item.shipping_type)
        .filter((i) => i > 0 && i !== undefined);
      if (shippingTypeIdList.length > 0) {
        fetchingShippingType(shippingTypeIdList);
      }
    },
    [fetchingShippingType]
  );

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ShippingOrderModel[]>([]);
  const fetchData = useCallback(async (): Promise<ShippingOrderCollection> => {
    let collection = await new ShippingOrderRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    // call idfetcher
    mappingShipperIdsToFetcher(collection.items);
    mappingShippingHubIdsToFetcher(collection.items);
    mappingShippingTypeIdsToFetcher(collection.items);
    return collection;
  }, [
    filters,
    mappingShipperIdsToFetcher,
    mappingShippingHubIdsToFetcher,
    mappingShippingTypeIdsToFetcher,
  ]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ShippingOrderModel> = [
    {
      title: t("shippingorder:random_code_short"),
      key: "random_code",
      width: 100,
      align: "center",
      render: (random_code, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            link={""}
            onClick={() => onClickEditOrder(record)}
          >
            {random_code}
          </TableLinkText>
        );
      },
    },
    {
      title: t("shippingorder:hub"),
      key: "office_id",
      width: 130,
      render: (_: any, record: ShippingOrderModel) => {
        return (
          <>
            {" "}
            <div className="mb-1 font-bold">
              {record.office_id > 0 ? (
                <IdFetcherRender
                  value={record.office_id}
                  mapping={shippingHub}
                />
              ) : (
                "---"
              )}
            </div>
            <div>
              <Tooltip
                title={t("shippingorder:invoice_id")}
                placement="left"
                className="text-xs text-gray-500 hover:text-black"
                mouseLeaveDelay={0.4}
              >
                <IconBox size={14} className="mr-1 -mt-0.5" />
                {record.invoice_id}
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={t("shippingorder:shipping_type")}
                placement="left"
                className="text-xs text-gray-500 hover:text-black"
                mouseLeaveDelay={0.4}
              >
                <IconFolder size={14} className="mr-1 -mt-0.5" />
                {record.shipping_type > 0 ? (
                  <IdFetcherRender
                    value={record.shipping_type}
                    mapping={shippingType}
                  />
                ) : (
                  "---"
                )}
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      title: t("shippingorder:route"),
      key: "route_id",
      align: "center",
      width: 100,
      render: (_: any, record: ShippingOrderModel) => {
        return (
          <>
            {record.route_id > 0 ? (
              <Tooltip
                title={
                  record.shipper_id > 0 ? (
                    <IdFetcherRender
                      value={record.shipper_id}
                      mapping={shipperUser}
                    />
                  ) : (
                    "---"
                  )
                }
                placement="left"
                mouseLeaveDelay={0.4}
              >
                <Link
                  to={"/shippingorder?route_id=" + record.route_id}
                  className="text-blue-500"
                >
                  {record.route?.code}
                </Link>
              </Tooltip>
            ) : (
              "[" + t("shippingorder:label_route_empty") + "]"
            )}
          </>
        );
      },
    },
    {
      title: t("shippingorder:order_shipping_address"),
      key: "order_shipping_address",
      width: 220,

      render: (_: any, record: ShippingOrderModel) => (
        <>
          <div className="mb-1 font-bold">
            {record.order_customer_fullname} ({record.order_customer_phone})
            <div className="float-right">
              {record.cod > 0 ? (
                <Tooltip
                  mouseEnterDelay={0.4}
                  color={"gray"}
                  placement="topLeft"
                  title={
                    t("shippingorder:cod") +
                    ": " +
                    Helper.moneyFormat(record.cod)
                  }
                >
                  <Tag color={"gray"}>COD</Tag>
                </Tooltip>
              ) : null}
            </div>
          </div>
          <div className="text-xs text-gray-500 hover:text-black">
            {record.order_shipping_address}
          </div>

          {record.order_shipping_lat === 0 ||
          record.order_shipping_long === 0 ? (
            <TextEmptyCoord />
          ) : null}
        </>
      ),
    },
    {
      title: t("shippingorder:status"),
      key: "status",
      width: 100,
      render: (_: any, record: ShippingOrderModel) => {
        let statusInfo = ShippingOrderModel.getStatus(record.status);
        return (
          <Tooltip
            mouseEnterDelay={0.4}
            overlayStyle={{ maxWidth: "500px" }}
            placement="topLeft"
            title={
              <>
                {t("common:date_started") +
                  ": " +
                  (record.date_started > 0
                    ? dayjs
                        .unix(record.date_started)
                        .format("HH:mm, DD/MM/YYYY")
                    : "---")}
                {<br></br>}
                {t("common:date_completed") +
                  ": " +
                  (record.date_completed > 0
                    ? dayjs
                        .unix(record.date_completed)
                        .format("HH:mm, DD/MM/YYYY")
                    : "---")}
              </>
            }
          >
            <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
          </Tooltip>
        );
      },
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 80,
      render: (ts: number) => {
        return <TextDateTime ts={ts} format="HH:mm, DD/MM" />;
      },
    },
    {
      title: " ",
      toggletype: "trigger",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 90,
      render: (_: any, record: ShippingOrderModel) => (
        <>
          <RoleCheck roles={[Role.SHIPPING_ORDER_MANAGE]} hideOnFail>
            <TableEdit link={""} onClick={() => onClickEditOrder(record)} />
          </RoleCheck>
          <TableInfo record={record}></TableInfo>
        </>
      ),
    },
  ];

  const onSaveOrderSuccess = useCallback(
    (item: ShippingOrderModel) => {
      let findIndex = dataSource.findIndex((i) => i.id === item.id);
      if (findIndex >= 0) {
        // update
        let newDataSource = update(dataSource, {
          [findIndex]: {
            $set: item,
          },
        });
        setDataSource(newDataSource);

        // recall idfetcher
        mappingShipperIdsToFetcher(newDataSource);
        mappingShippingHubIdsToFetcher(newDataSource);
        mappingShippingTypeIdsToFetcher(newDataSource);
      } else {
        // append
        setDataSource(
          update(dataSource, {
            $unshift: [item],
          })
        );
      }
      setEditingOrder(
        new ShippingOrderModel(ShippingOrderModel.getDefaultData())
      );
    },
    [
      dataSource,
      mappingShipperIdsToFetcher,
      mappingShippingHubIdsToFetcher,
      mappingShippingTypeIdsToFetcher,
    ]
  );

  return (
    <>
      <ShippingOrderListHeader>
        <RoleCheck roles={[Role.SETTING_DMS]}>
          <Link to={"/shippingtype"}>
            <Button
              className="mr-2"
              icon={<IconFolder className="mr-2" size={18} />}
            >
              {t("shippingorder:shippingtype_manage")}
            </Button>
          </Link>
        </RoleCheck>
        <RoleCheck roles={[Role.SHIPPING_ORDER_MANAGE]}>
          <PageHeaderButton
            link=""
            onClick={() =>
              onClickEditOrder(
                new ShippingOrderModel(ShippingOrderModel.getDefaultData())
              )
            }
          >
            {t("common:table.add_button")}
          </PageHeaderButton>
        </RoleCheck>
      </ShippingOrderListHeader>

      <ShippingOrderListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<
        FilterShippingOrder,
        ShippingOrderModel,
        ShippingOrderCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "shipping-order",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      {orderModalVisible ? (
        <ShippingOrderFormModal
          key={"shipping-order-" + editingOrder}
          open={orderModalVisible}
          setOpen={(isOpen: boolean) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingOrder(
                new ShippingOrderModel(ShippingOrderModel.getDefaultData())
              );
            }
            setOrderModalVisible(isOpen);
          }}
          editingOrder={editingOrder}
          onSaveSuccess={onSaveOrderSuccess}
        />
      ) : null}
    </>
  );
};

export default ShippingOrderList;
