import { Select } from "antd";
import { WarehouseJson } from "common/types/Warehouse";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import usePosCartStore from "zustands/usePosCartStore";

const PosSearchWarehouse = () => {
  const { t } = useTranslation();
  const [activeStore, activeCart, updateActiveCart] = usePosCartStore(
    (state) => [
      state.activeStore,
      state.getActiveCart(),
      state.updateActiveCart,
    ]
  );
  const [warehouseItems] = useDatabaseTable<WarehouseJson>("warehouse");

  const changeWarehouse = useCallback(
    (warehouse_id: number) => {
      updateActiveCart({ warehouse_id });
    },
    [updateActiveCart]
  );

  if (typeof activeStore === "undefined" || typeof activeCart === "undefined")
    return null;

  return (
    <span className="font-bold">
      <div className="text-xs ml-3 mt-1 leading-none ">
        {t("pos:warehouse.select_label")}:
      </div>
      <Select
        bordered={false}
        value={activeCart.warehouse_id}
        onChange={(value) => changeWarehouse(value)}
        style={{ width: "100%", fontSize: "16px" }}
        rootClassName="font-bold"
        options={warehouseItems
          // .filter((item) => activeStore.warehouse_ids?.includes(item.id))
          .map((item) => ({
            value: item.id,
            label: item.name,
          }))}
      />
    </span>
  );
};

export default PosSearchWarehouse;
