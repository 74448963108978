import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter } from "./Filter";

type CashflowReceiptJsonAddEdit = {
	cashflow_group: number;
	id: number;
	direction: CashflowreceiptsType;
	store_id: number;
	source: number;
	source_id: number;
	target: number;
	target_id: number;
	identifier: string;
	name: string;
	note: string;
	method: CashflowreceiptsMethod;
	value: number;
	status: CashflowreceiptsStatus;
	// date_affected: number;
};

export enum CashflowreceiptsStatus {
	DRAFT = 1,
	PENDING,
	ACCEPT,
	SUCCSECC = 9,
	CANCEL = 11,
	NEW = 12,
}

export enum CashflowreceiptsType {
	RECEIPT = 5,
	PAYMENT = 10,
}

export enum CashflowreceiptsMethod {
	UNKNOW = 0,
	CASH = 1,
	TRASNFER = 3,
	COD = 11,
	VNPAY = 15,
	CARD = 19,
	VOUCHER = 9999,
}

type CashflowReceiptJson = CashflowReceiptJsonAddEdit & {
	company_id: number;
	creator_id: number;
	date_created: number;
	date_modified: number;
	target_label?: string;
};

type CashflowReceiptCollectionJson = BaseCollectionJson<CashflowReceiptJson>;

type FilterCashflowReceipt = Filter & {
	id: number;
	cashflow_group: number;
	direction: number;
	store_id: number;
	source: number;
	target: number;
	method: number;
	source_id: number;
	date_started: number;
	date_ended: number;
	is_complete: number;
};

export type {
	CashflowReceiptJson,
	CashflowReceiptJsonAddEdit,
	CashflowReceiptCollectionJson,
	FilterCashflowReceipt,
};
