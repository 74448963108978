import { Button, notification, Popconfirm } from "antd";
import PosCartModel from "common/models/PosCartModel";
import { forEach } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import outOfStockFx from "sounds/pos/error.mp3";
import useSound from "use-sound";
import usePosCartStore from "zustands/usePosCartStore";
import usePosStore from "zustands/usePosStore";

import { IconTrash } from "@tabler/icons-react";

import PosSubmitReview from "./PosSubmitReview";

const PosSubmitCreateOrder = ({ height }: { height: number }) => {
  const enableSoundFx = usePosStore((state) => state.enableSoundFx);
  const [soundFxOutOfStock] = useSound(outOfStockFx);
  const activeCart = usePosCartStore((state) => state.getActiveCart());
  const removeActiveCart = usePosCartStore((state) => state.removeActiveCart);

  const [showReview, setShowReview] = useState(false);

  const canCreateOrder = useMemo(() => {
    if (typeof activeCart !== "undefined") {
      //check COD on not-shipping order
      let remainMoney = PosCartModel.calculatePrices(activeCart).price_final;
      forEach(activeCart.payments, (payment) => {
        remainMoney -= payment.value;
      });

      return (
        activeCart.details.length > 0 &&
        (remainMoney === 0 || activeCart.delivery.shipping_carrier_id > 0)
      );
    } else {
      return false;
    }
  }, [activeCart]);

  const onClickSubmitButton = useCallback(() => {
    if (typeof activeCart !== "undefined") {
      if (activeCart.out_of_stock.length > 0) {
        if (enableSoundFx) {
          soundFxOutOfStock();
        }
        notification.error({
          duration: 3,
          style: {
            backgroundColor: "#fff6f6",
          },
          message: "Lỗi tồn kho",
          description: (
            <div>
              Không thể thêm đơn hàng vì có {activeCart.out_of_stock.length} sản
              phẩm đang hết hàng hoặc không đủ tồn kho.
            </div>
          ),
        });
      } else {
        setShowReview(true);
      }
    }
  }, [enableSoundFx, activeCart, soundFxOutOfStock]);

  if (typeof activeCart === "undefined") {
    return null;
  }

  return (
    <div
      style={{ height }}
      id="pos-submit"
      className="p-2 bg-gray-200 shadow-lg"
    >
      <div className="float-left">
        <Popconfirm
          title="Bạn có muốn xóa đơn hàng tạm này?"
          onConfirm={removeActiveCart}
          okText="Có"
          cancelText="Không"
        >
          <span
            className="inline-block p-3 cursor-pointer"
            title={"Hủy bỏ đơn hàng tạm này (" + activeCart._cart_name + ")"}
          >
            <IconTrash size="16" />
          </span>
        </Popconfirm>
      </div>
      <Button
        size="large"
        type={activeCart.out_of_stock.length === 0 ? "primary" : "dashed"}
        disabled={!canCreateOrder}
        onClick={onClickSubmitButton}
        shape="round"
        className="float-right"
      >
        TẠO ĐƠN HÀNG
      </Button>

      <PosSubmitReview showReview={showReview} setShowReview={setShowReview} />
    </div>
  );
};

export default PosSubmitCreateOrder;
