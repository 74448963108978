import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import type { GetListProps } from "common/types/Filter";
import LeaveTypeCollection from "common/collections/LeaveTypeCollection";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import { LeaveTypeJson } from "common/types/LeaveType";
import ContractTypeModel from "common/models/ContractTypeModel";
import {
  ContractTypeJson,
  FilterContractType,
} from "common/types/ContractType";
import ContractTypeCollection from "common/collections/ContractTypeCollection";

const SERVICE_URL = "/contracttypes";

class ContractTypeApi extends BaseApi {
  async getList(
    props: GetListProps<FilterContractType>
  ): Promise<ContractTypeCollection> {
    let collection = new ContractTypeCollection();
    try {
      const response = await AxiosClient().get<any>(SERVICE_URL);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async getFullData() {
    let items = [];
    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/fulldata");
      if (response.hasOwnProperty("data")) {
        // collection.fromJson(response.data);
        items = response.data;
      }
    } catch (error) {
      //   collection.withError(BaseApi.handleError(error));
      items.push({ error: error });
    }

    return items;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(
        SERVICE_URL + "/delete/" + id
      );
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_201");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: ContractTypeJson): Promise<any> {
    // console.log(data);
    let item = new ContractTypeModel(ContractTypeModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ContractTypeModel(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: ContractTypeJson): Promise<any> {
    let item = new ContractTypeModel(ContractTypeModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ContractTypeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async getDetail(id: number): Promise<ContractTypeModel> {
    let item = new ContractTypeModel(ContractTypeModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ContractTypeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ContractTypeApi;
