import { ErrorLocal } from "common/api/BaseApi";
import create, { StateCreator } from "zustand";
import { persist } from "zustand/middleware";

type HiddenBackLink = string | (() => void);

type ProcessStore = {
  fetchErrors: string[];
  setFetchErrors: (fetchErrors: string[]) => void;
};

//init store data
let store: StateCreator<ProcessStore> = (set, get) => ({
  fetchErrors: [],
  setFetchErrors: (fetchErrors) => set((state) => ({ ...state, fetchErrors })),
});

//create store
const useProcessStore = create(
  persist(store, {
    name: "MAIN::Process",
    onRehydrateStorage: (state) => {
      return (state) => {
        const errorLocal = localStorage.getItem("MAIN::Process")!;
        const errorLocalParse = JSON.parse(errorLocal) as ErrorLocal;
        state?.setFetchErrors(errorLocalParse.state.fetchErrors);
      };
    },
  })
);

export default useProcessStore;
