import AdsCollectionList from "features/ads/collection/list/AdsCollectionList";
import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

const AdsCollectionPage = () => {
	const [pathParams] = usePathParams();

	const [errors, setErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setErrors([]);
	}, [pathParams.action]);

	let com = <></>;

	switch (pathParams.action) {
		default:
			com = <AdsCollectionList></AdsCollectionList>;
			break;
	}
	return com;
};

export default AdsCollectionPage;
