import { Button, Form, Input, Select, Space } from "antd";
import { ProductInventoryAttachments } from "common/types/ProductInventoryChecking";
import React from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const ProductInventoryCheckingFormAttachments = ({
  files,
  isEditing = true,
  isAllowEdit,
}: {
  files: ProductInventoryAttachments[];
  isEditing: boolean;
  isAllowEdit: boolean;
}) => {
  const { t } = useTranslation();

  // if (!files.length) {
  //   return <p>Không có chứng từ</p>;
  // }

  return (
    <Form.Item label={t("Chứng từ")} className="mb-0" name="attachments">
      <Form.List name="attachments" initialValue={files}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space
                key={field.key}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item
                  {...field}
                  name={[field.name, "type"]}
                  rules={[{ required: true, message: t("common:required") }]}
                >
                  <Select
                    placeholder={t("Loại")}
                    disabled={!isAllowEdit}
                    style={{ width: 200 }}
                  >
                    <Option value="inventory_receipt">
                      {t("common:inventory_receipt")}
                    </Option>
                    <Option value="file">{t("common:file")}</Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  {...field}
                  name={[field.name, "receipt_type"]}
                >
                  <Input
                    placeholder={t("common:receiptType")}
                    // disabled={!isEditing}
                  />
                </Form.Item> */}
                <Form.Item
                  {...field}
                  name={[field.name, "id"]}
                  rules={[{ required: true, message: t("common:required") }]}
                >
                  <Input placeholder={t("id")} disabled={!isAllowEdit} />
                </Form.Item>

                {!isAllowEdit && (
                <MinusCircleOutlined
                  disabled={!isAllowEdit}
                  onClick={() => remove(field.name)}
                />
                )}
              </Space>
            ))}
            {isAllowEdit && (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ width: "100%" }}
                >
                  {t("Thêm chứng từ")}
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default ProductInventoryCheckingFormAttachments;
