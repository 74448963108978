import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import React from "react";
import { Link as LinkRoute } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DeliveryJson } from "common/types/Delivery";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import { IconMapPin, IconPhoneCall } from "@tabler/icons-react";
import ButtonAction from "features/delivery/action/ButtonAction";
import Delivery from "common/constants/Delivery";
import PostAction from "features/delivery/action/PostAction";
import RoleCheck from "components/RoleCheck";
import Role from "common/constants/Role";

type Props = {
  item: DeliveryJson;
  typeItem?: any;
  title_button?: string;
  type?: "done";
  fetchData?: () => Promise<void>;
};
const DeliveryWaitingItem = ({
  item,
  typeItem,
  title_button,
  type,
  fetchData,
}: Props) => {
  const { Text, Title, Link, Paragraph } = Typography;
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  // function
  const handleTake = async (id: number) => {};

  const getPostion = (string: string, substring: string, index: number) => {
    return string.split(",", index).join(",").length;
  };

  const handleOpenMap = ({ address }: { address: string }) => {
    if (address !== undefined && address !== "") {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${address}
          `,
        "_blank"
      );
    }
  };

  return (
    <div className="h-full">
      {contextHolder}
      <Collapse
        size="small"
        defaultActiveKey={1}
        accordion={true}
        activeKey={1}
        collapsible="icon"
      >
        <Panel
          showArrow={false}
          key={1}
          header={
            <div className="w-full">
              <div className="flex gap-1 flex-col">
                <div className="flex justify-between items-center gap-1  lg:gap-2">
                  <Text>
                    <Tag color="#0089FF">{item.sale_order_id}</Tag>
                    <Tag>{item.id}</Tag>
                  </Text>

                  <div className="flex items-center gap-1">
                    <LinkRoute to={`/delivery/waiting_detail/id/${item.id}`}>
                      <Button size="small">
                        {title_button || t("delivery:waiting.button.detail")}
                      </Button>
                    </LinkRoute>
                    <RoleCheck roles={[Role.DELIVERY_SHIPPER]} hideOnFail>
                      <ButtonAction
                        matrix_action={Delivery.SHIPPER_MATRIX_ACTION}
                        action_code={item.action_code}
                        single_action={{
                          action_code: [
                            {
                              actionCode: "ACTION_CREATE",
                            },
                          ],
                          prefix_translate: "delivery:newstatus",
                          size: "small",
                        }}
                        prefix_translate_button="delivery"
                        renderModal={({
                          nextAction,
                          closeModal,
                          gpsRequire,
                          noteRequire,
                          uploadRequire,
                        }) => {
                          return (
                            <div>
                              <PostAction
                                key={item.id}
                                action={nextAction}
                                gpsRequire={gpsRequire}
                                noteRequire={noteRequire}
                                uploadRequire={uploadRequire}
                                data={{ deliveries_id: item.id }}
                                handlePostActionSuccess={() => {
                                  closeModal();
                                  fetchData && fetchData();
                                }}
                                defaultFacingMode="environment"
                              ></PostAction>
                            </div>
                          );
                        }}
                      ></ButtonAction>
                    </RoleCheck>
                  </div>
                </div>
                <div className="flex gap-2 justify-end items-center">
                  {/* <Paragraph
                    ellipsis={{ rows: 2, tooltip: item.contact_address }}
                  >
                    {item.contact_address}
                  </Paragraph> */}
                  <TextDateTimeRelative
                    className="shrink-0"
                    ts={item.date_created}
                  />
                </div>
              </div>
            </div>
          }
        >
          <div className="flex flex-col gap-1">
            {/* đơn hàng */}
            <div className="flex flex-col gap-1">
              <div className="flex justify-between items-center gap-1">
                <Text>Giá trị đơn hàng :</Text>
                <Text strong>
                  {item.cost_total ? (
                    item.cost_total.toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                      compactDisplay: "long",
                    }) + " (COD)"
                  ) : (
                    // <Tag color="gold">
                    //   {t("delivery:list.item.none.cost_total")}
                    // </Tag>
                    <span>0</span>
                  )}
                </Text>
              </div>
              <div className="flex justify-between items-center gap-1">
                <Text>Phải thu :</Text>
                <Text strong type="danger">
                  {item.cod
                    ? item.cod.toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                        compactDisplay: "long",
                      })
                    : 0}
                </Text>
              </div>
            </div>
            <Divider style={{ margin: "6px 0" }}></Divider>
            {/* khách hàng         */}
            <div className="flex flex-col gap-1">
              <div className="flex justify-between items-center gap-1">
                <Text strong>
                  {item.contact_fullname ? (
                    item.contact_fullname
                  ) : (
                    <Tag color="error">
                      {t("delivery:list.item.none.full_name")}
                    </Tag>
                  )}
                </Text>
                <Link
                  copyable={{ tooltips: item.contact_phone }}
                  href={`tel:${item.contact_phone}`}
                  underline
                >
                  {item.contact_phone}
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex justify-between items-center gap-1">
                <Paragraph
                  className="mb-0"
                  ellipsis={{ tooltip: item.note, rows: 1 }}
                  style={{ marginBottom: 0 }}
                >
                  {item.contact_address}
                </Paragraph>
                <div className="basis-[30%] text-right">
                  <Tooltip placement="left" title="Xem địa chỉ google map">
                    <IconMapPin
                      onClick={() =>
                        handleOpenMap({ address: item.contact_address })
                      }
                      cursor="pointer"
                      color="#4EBA00"
                      size="16px"
                      className="text-right"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            {/* ghi ghú */}
            <div className="flex flex-col gap-1">
              <div className="flex justify-between gap-2">
                <Text className="shrink-0">
                  {t("delivery:list.item.note")} :
                </Text>
                <Paragraph
                  className="mb-0"
                  ellipsis={{ tooltip: item.note, rows: 1 }}
                >
                  {item.note !== "" ? (
                    item.note
                  ) : (
                    <Tag className="text-right">Trống</Tag>
                  )}
                </Paragraph>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default DeliveryWaitingItem;
