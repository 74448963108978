import BaseRepository from "./BaseRepository";
import type { GetListProps } from "common/types/Filter";

//////////////////////////////////
//Phần thay thế
import {
	EventHistoryFilters,
	EventHistoryJsonAddEdit,
} from "common/types/EventHistory";
import EventHistoryApi from "common/api/EventHistoryApi";
import EventHistoryCollection from "common/collections/EventHistoryCollection";
import EventHistoryModel from "common/models/EventHistoryModel";

class EventHistoryRepository extends BaseRepository<EventHistoryApi> {
	_api: EventHistoryApi | null;
	constructor() {
		super();
		this._api = new EventHistoryApi(true);
	}

	static getDefaultFilters(): EventHistoryFilters {
		return {
			page: 1,
			limit: 20,
			sortby: "",
			sorttype: "DESC",
			keyword: "",
			status: "",
			source_id: -1,
			source_type: 1,
		};
	}

	async getItems(
		props: GetListProps<EventHistoryFilters>
	): Promise<EventHistoryCollection> {
		return this._api
			? await this._api.getList(props.filters)
			: new EventHistoryCollection();
	}

	async getItem(id: number): Promise<EventHistoryModel> {
		return this._api
			? await this._api.getDetail(id)
			: new EventHistoryModel(EventHistoryModel.getDefaultData());
	}

	async saveRemote(data: EventHistoryJsonAddEdit): Promise<EventHistoryModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new EventHistoryModel(EventHistoryModel.getDefaultData());
		}
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}
}

export default EventHistoryRepository;
