import { Button, Skeleton, Typography, message } from "antd";
import SettingApi from "common/constants/Setting";

import PageSortList from "components/list/PageSortList";
import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import useHandleSetting from "hooks/useHandleSetting";
import ShopPopupHeaderList from "./ShopPopupHeaderList";
import { ShopPopupJson } from "common/types/ShopPopup";
import ShopPopupForm from "../form/ShopPopupForm";
import ListDeleteButton from "components/list/ListDeleteButton";
import FileModel from "common/models/FileModel";

const ShopPopupList = () => {
  const [open, setOpen] = useState<boolean>(false);

  const defaultDataPopup: ShopPopupJson = {
    name: "",
    key: "",
    time_delay: 0,
    time_limit: 0,
    from_time: 0,
    to_time: 0,
    title: "",
    alt: "",
    link: "#",
    image: {
      desktop: FileModel.getDefaultData(),
      mobile: FileModel.getDefaultData(),
    },
    id: 0,
  };

  const [data, setData] = useState<{
    data: ShopPopupJson;
    index: number;
    type: "add" | "edit";
  }>({
    data: defaultDataPopup,
    index: -1,
    type: "add",
  });

  const {
    dataSource,
    loading,
    addItemSetting,
    deleteItemSetting,
    editItemSetting,
    handleDrag,
  } = useHandleSetting<ShopPopupJson>(SettingApi.KEY.k_setting_web_popup, "vi");

  const handleDragEnd = async (result: DropResult) => {
    try {
      if (!result.destination) return;
      await handleDrag(result.source.index, result.destination.index);
      message.success({
        content: "Thao tác thành công",
        duration: 1,
      });
    } catch (error) {
      message.error({
        content: "Thao tác thất bại",
        duration: 1,
      });
    }
  };

  function handlelOpenModalAdd(data: ShopPopupJson) {
    setData({
      data: data,
      type: "add",
      index: -1,
    });
    setOpen(true);
  }
  function handlelOpenModalEdit(data: ShopPopupJson, index: number) {
    setData({
      data: data,
      type: "edit",
      index,
    });
    setOpen(true);
  }

  async function handleSumit(dataRes: ShopPopupJson) {
    try {
      if (data.type === "add") {
        await addItemSetting(dataRes);
      } else {
        await editItemSetting(dataRes, data.index);
      }
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }

  async function handleDelete(data: ShopPopupJson, index: number) {
    try {
      await deleteItemSetting(data, index);
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }
  return (
    <>
      <ShopPopupHeaderList>
        <Button
          type="primary"
          onClick={() => handlelOpenModalAdd(defaultDataPopup as ShopPopupJson)}
        >
          Thêm
        </Button>
      </ShopPopupHeaderList>
      {!loading ? (
        <>
          <PageSortList
            dataSource={dataSource}
            handleDragEnd={handleDragEnd}
            listKey="shopppopup"
            renderListItem={(item: ShopPopupJson, index) => {
              return (
                <>
                  <div className="p-2">
                    <div className="flex  items-center justify-between">
                      <Typography.Text className="font-bold">
                        {item.name}
                      </Typography.Text>
                      <div className=" flex gap-2">
                        <Button
                          onClick={() => {
                            handlelOpenModalEdit(item, index);
                          }}
                        >
                          Sửa
                        </Button>

                        <ListDeleteButton
                          handleDelete={() => {
                            handleDelete(item, index);
                          }}
                          id={index}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        </>
      ) : (
        <Skeleton active />
      )}
      <ShopPopupForm
        type={data.type}
        data={data.data}
        open={open}
        onCancel={() => setOpen(false)}
        onFinish={async (data) => {
          await handleSumit(data);
        }}
      />
    </>
  );
};

export default ShopPopupList;
