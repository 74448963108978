import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { Button, Col, Form, Image, Input, Modal, Popconfirm, Row } from "antd";
import { ShopBannerHomeJson } from "common/types/ShopBannerHome";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";

import { useCallback, useEffect, useState } from "react";

export default function ShopBannerHomeForm({
  data,
  open,
  type = "add",
  onFinish,
  onCancel,
}: {
  type: "add" | "edit";
  data: ShopBannerHomeJson;
  open: boolean;
  onFinish: (data: ShopBannerHomeJson) => void;
  onCancel: () => void;
}) {
  const [form] = Form.useForm<ShopBannerHomeJson>();

  const [imageFirst, setImageFirst] = useState<string>(data.image || "");
  const [imageMobile, setImageMobile] = useState<string>(
    data.image_mobile || ""
  );
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: ShopBannerHomeJson = {
    ...data,
    width: data.width ? data.width : 2550,
    height: data.height ? data.height : 896,
  };

  const doPrepareData = useCallback(
    (formData: ShopBannerHomeJson) => {
      const submitData: ShopBannerHomeJson = {
        ...data,
        ...formData,
        id:
          data.id && data.id.toString().length > 0
            ? Number(data.id)
            : undefined,
        link:
          formData.link.length === 0 || formData.link === "#"
            ? "#"
            : formData.link,
        image: imageFirst,
        image_mobile: imageMobile,
        width: +formData.width,
        height: +formData.height,
      };

      return submitData;
    },
    [data, imageFirst, imageMobile]
  );

  const onSubmit = async (formData: any) => {
    setLoading(true);
    onFinish({
      ...data,
      ...doPrepareData(formData),
    });
    setLoading(false);
  };

  const handleOnClose = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    form.setFieldsValue({
      ...(initialValues as any),
    });
    setImageFirst(data.image || "");
    setImageMobile(data.image_mobile || "");
  }, [data]);

  return (
    <Modal
      title={type === "edit" ? `Cập nhật` : "Thêm mới"}
      open={open}
      footer={<></>}
      onCancel={() => {
        handleOnClose();
      }}
      maskClosable={false}
      width={1000}
      destroyOnClose
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
    >
      <>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
          autoComplete="off"
          className=" relative"
        >
          <Row gutter={[16, 16]}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Tiêu đề ảnh"
                name="title"
                // rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={16} xs={24}>
              <Form.Item label="Đường dẫn" name="link">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Mô tả hình ảnh (alt)"
                name="alt"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Chiều rộng"
                name="width"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Chiều Cao"
                name="height"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col md={14}>
              {imageFirst.length > 0 ? (
                <>
                  <p>Desktop</p>
                  <div className="flex items-start">
                    <Image
                      className="cursor-pointer"
                      src={imageFirst}
                      width={640}
                      height={225}
                      preview={true}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImageFirst("");
                        form.setFieldValue("imageFirst", []);
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="imageFirst"
                      label={"Ảnh desktop"}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setImageFirst(f.url);
                      }}
                      showInput
                    />
                  </Form.Item>
                </>
              )}
            </Col>

            <Col md={10}>
              {imageMobile.length > 0 ? (
                <>
                  <p>Mobile</p>

                  <div className="flex items-start">
                    <Image
                      className="cursor-pointer"
                      src={imageMobile}
                      width={225}
                      height={225}
                      preview={true}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImageMobile("");
                        form.setFieldValue("imageMobile", []);
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="imageMobile"
                      label={"Ảnh mobile"}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setImageMobile(f.url);
                      }}
                      showInput

                    />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>

          <Form.Item className=" flex justify-end mt-10 sticky bottom-0">
            <div className="flex gap-4">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Hoàn tất
              </Button>
              <Button
                type="default"
                onClick={() => {
                  handleOnClose();
                }}
              >
                Đóng
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
}
