import {
  NotificationCollectionJson,
  NotificationJson,
} from "common/types/Notification";
import NotificationModel from "common/models/NotificationModel";

import BaseCollection from "./BaseCollection";

class NotificationCollection extends BaseCollection<
  NotificationJson,
  NotificationModel,
  NotificationCollectionJson
> {
  itemsFromJson(jsonItems: NotificationJson[]): NotificationModel[] {
    return jsonItems.map((item) => new NotificationModel(item));
  }
}
export default NotificationCollection;
