import BaseCollection from "common/collections/BaseCollection";
import WorkChatModel from "../models/WorkChatModel";
import { WorkChatJson, WorkChatCollectionJson } from "../types/WorkChat";

class WorkChatCollection extends BaseCollection<
  WorkChatJson,
  WorkChatModel,
  WorkChatCollectionJson
> {
  itemsFromJson(jsonItems: WorkChatJson[]): WorkChatModel[] {
    return jsonItems.map((item) => new WorkChatModel(item));
  }
}

export default WorkChatCollection;
