import ProjectTaskChangeApi from 'common/api/ProjectTaskChangeApi';
import ProjectTaskChangeCollection from 'common/collections/ProjectTaskChangeCollection';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type { FilterProjectTaskChange } from "common/types/ProjectTaskChange";

class ProjectTaskChangeRepository extends BaseRepository<ProjectTaskChangeApi> {
  _api: ProjectTaskChangeApi | null;

  constructor() {
    super();
    this._api = new ProjectTaskChangeApi(true);
  }

  static getDefaultFilters(): FilterProjectTaskChange {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      project_id: -1,
      project_task_id: -1,
      company_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterProjectTaskChange>) {
    return this._api
      ? await this._api.getList(props)
      : new ProjectTaskChangeCollection();
  }
}

export default ProjectTaskChangeRepository;
