import BaseCollection from 'common/collections/BaseCollection';
import ShippingOrderModel from 'common/models/ShippingOrderModel';
import { ShippingOrderCollectionJson, ShippingOrderJson } from 'common/types/ShippingOrder';

class ShippingOrderCollection extends BaseCollection<
  ShippingOrderJson,
  ShippingOrderModel,
  ShippingOrderCollectionJson
> {
  itemsFromJson(jsonItems: ShippingOrderJson[]): ShippingOrderModel[] {
    return jsonItems.map((item) => new ShippingOrderModel(item));
  }
}

export default ShippingOrderCollection;
