import { Button, Col, Collapse, CollapseProps, Form, Input, Row, Skeleton, Typography } from "antd";
import SettingApi from "common/constants/Setting";
import SettingRepository from "common/repositories/SettingRepository";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import ShopCollectionHeader from "./SettingPromotionsHeaderList";
import PageSortList from "components/list/PageSortList";
import PageHeaderButton from "components/page/PageHeaderButton";
import SettingPromotionsHeader from "./SettingPromotionsHeaderList";
import { MegaMenuJson } from "common/types/ShopMegaMenu";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import { FormProps } from "antd/lib/form";

type ShopPromotionsJson = {
  title: string;
  id: number;

};

type ShopSectionJson = {
  type: string;
  id: number;
  handle: string;
};

const SettingPromotionsList = () => {
  const [dataSource, setDataSource] = useState<ShopPromotionsJson[]>([
    { id: 1, title: "Deal thơm" },
    { id: 2, title: "Tuần lễ thương hiệu" }
  ]);
  const [form] = Form.useForm();


  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = {};
  //prepare data for submit
  //   const doPrepareData = useCallback(
  //     (formData: any) => {
  //       const submitData: SettingAddEditJson = {
  //         id: model.id,
  //         group: formData.
  //       };

  //       return submitData;
  //     },
  //     [model.id]
  //   );

  //submit data to server
  const onSubmit = async (formData: any) => {
    // doPrepareData(formData);

    // let myObj: StoreModel = await new StoreRepository().saveRemote(
    //   doPrepareData(formData)
    // );
    // if (myObj.hasError()) {
    //   setErrors(myObj.error.errors);
    // } else {
    //   setIsSuccess(true);
    // }
  };


  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    // const menus = await new SettingRepository().getItem(
    //   SettingApi.KEY.k_menu_home
    // );
    // const collections = await new SettingRepository().getItem(
    //   SettingApi.KEY.k_settings_website_namperfume_net
    // );

    // return [menus.value, collections.value]
  }

  type FieldType = {
    name:string
    linmk:string
 
  };

  const onFinish = (values: any, id: number) => {
    console.log('Success:', values , id);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const tem = [
    {
      key: 1,
      label: "thiết lập Menu",
      children: <div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={(va) => onFinish(va, 1)}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="tiêu đề"
            name="name"
            rules={[{ required: true, message: 'vui lòng nhập' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="Liên kết"
            name="link"

          >
            <Input />
          </Form.Item>



          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Hoàn tất
            </Button>
          </Form.Item>
        </Form>
      </div>
    },
    {
      key: 2,
      label: "thiết lập Slider",
      children: <p>slide</p>
    },
  ]

  const items: CollapseProps['items'] = dataSource.map((item) => {
    return {
      key: item.id,
      label: item.title,
      children: <Collapse items={tem} />
    }
  });
  return (
    <>
      <SettingPromotionsHeader></SettingPromotionsHeader>
      {!loading ? (
        <>
          <Collapse items={items} />;
          {/* <PageSortList
            dataSource={dataSource}
            handleDragEnd={handleDragEnd}
            listKey="shopcollection"
            renderListItem={(item: ShopPromotionsJson) => {
              return (
                <>
                  <div className="flex flex-col min-h-[50px] p-4" >
                    <Typography.Text className="font-bold">
                      ID: {item.id}
                    </Typography.Text>
                    <Typography.Text>{item.title}</Typography.Text>
                  </div>
                </>
              );
            }}
          /> */}
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};

export default SettingPromotionsList;
