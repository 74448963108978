import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import Helper from "common/utils/helper";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SectionBannerNNNJson } from "../form/ShopCollectionForm";
import FileModel from "common/models/FileModel";
import {
  IconLoader,
  IconPhoto,
  IconTrack,
  IconTrash,
} from "@tabler/icons-react";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";

export default function ShopSectionBannerForm({
  data,
  open,
  type = "add",
  onFinish,
  onCancel,
}: {
  type: "add" | "edit";
  data: SectionBannerNNNJson;
  open: boolean;
  onFinish: (data: SectionBannerNNNJson) => void;
  onCancel: () => void;
}) {
  console.log("🥺 ~ data:", data);
  const { t } = useTranslation();

  const [form] = Form.useForm<SectionBannerNNNJson>();

  const [imageDesktop, setImageDesktop] = useState<string>(data.image_desktop);
  const [imageMobile, setImageMobile] = useState<string>(data.image_mobile);

  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = useMemo(() => {
    return {
      title: data.title,
      alt: data.alt,
      link: data.link,
      // image_desktop: FileModel.convertToUploadFiles([
      // 	new FileModel({
      //     ...FileModel.getDefaultData(),
      // 		url: data.image_desktop,
      // 		extension: "jpg",
      // 		id: 999,
      // 	}),
      // ]),
      // image_mobile: FileModel.convertToUploadFiles([
      // 	new FileModel({
      // 		...FileModel.getDefaultData(),
      // 		url: data.image_mobile,
      // 		extension: "jpg",
      // 		id: 999,
      // 	}),
      // ]),

      image_desktop: data.image_desktop,
      image_mobile: data.image_mobile,
    };
  }, [data]);

  const doPrepareData = useCallback(
    (formData: any): Partial<SectionBannerNNNJson> => {
      const submitData: Partial<SectionBannerNNNJson> = {
        id: data.id && Number(data.id) > 0 ? Number(data.id) : undefined,
        alt: formData.alt,
        title: formData.title,
        link:
          formData.link.length === 0 || formData.link === "#"
            ? "#"
            : formData.link,
        image_desktop: imageDesktop,
        image_mobile: imageMobile,
      };

      return submitData;
    },
    [data, imageDesktop, imageMobile]
  );

  const onSubmit = async (formData: any) => {
    setLoading(true);
    onFinish({
      ...data,
      ...doPrepareData(formData),
    });
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...(initialValues as any),
    });
  }, [data, form, initialValues]);

  useEffect(() => {
    if (data.image_desktop.length > 0) {
      setImageDesktop(data.image_desktop);
    }

    if (data.image_mobile.length > 0) {
      setImageMobile(data.image_mobile);
    }
  }, [data]);

  return (
    <Modal
      title={type === "edit" ? "Cập nhật" : "Thêm mới"}
      open={open}
      footer={<></>}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      maskClosable={false}
      destroyOnClose
    >
      <>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Row gutter={[16, 16]}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Tiêu đề"
                name="title"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Mô tả"
                name="alt"
                // rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Link"
                name="link"
                // rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col md={24}>
              <Typography.Text>Banner desktop</Typography.Text>
              {imageDesktop.length > 0 ? (
                <>
                  <div className="flex items-start">
                    <Image
                      className="cursor-pointer"
                      src={imageDesktop}
                      width={100}
                      height={100}
                      preview={true}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImageDesktop("");
                        // form.setFieldValue("image", []);
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="images"
                      label={t("Ảnh")}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setImageDesktop(f.url);
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
            <Col md={24}>
              <Typography.Text>Banner mobile</Typography.Text>

              {imageMobile.length > 0 ? (
                <>
                  <div className="flex items-start">
                    <Image
                      className="cursor-pointer"
                      src={imageMobile}
                      width={100}
                      height={100}
                      preview={true}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImageMobile("");
                        // form.setFieldValue("image", []);
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="images"
                      label={t("Ảnh")}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setImageMobile(f.url);
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>

          {/* <Row gutter={[16, 16]}>
            <Col md={24} xs={24}>
              <Form.Item className="m-0">
                <FileUploaderButton
                  form={form}
                  formName="image"
                  label={t("Ảnh")}
                  icon={<IconPhoto size="24" color="grey" />}
                  origin="avatar"
                  uploadRenderType="thumbnail"
                  isSingleFile={true}
                  maxCount={1}
                  initFiles={initialValues.image}
                />
              </Form.Item>
            </Col>
          </Row> */}

          <Form.Item className="mt-4">
            <div className="flex gap-4">
              <Button type="primary" htmlType="submit">
                Hoàn tất
              </Button>
              <Button
                type="default"
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
              >
                Đóng
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
}
