import BaseRepository from "./BaseRepository";
import { FilterCheckin, CheckinJsonAddEdit } from "common/types/Checkin";
import CheckinCollection from "common/collections/CheckinCollection";
import CheckinModel from "common/models/CheckinModel";
import type { GetListProps } from "common/types/Filter";
import CheckinApi from "common/api/CheckinApi";

class CheckinRepository extends BaseRepository<CheckinApi> {
	_api: CheckinApi | null;
	constructor() {
		super();
		this._api = new CheckinApi(true);
	}

	static getDefaultFilters(): FilterCheckin {
		return {
			page: 1,
			limit: 20,
			sort_by: "",
			sort_type: "",
			sortby: "",
			sorttype: "",
			status: -1,
			keyword: "",
			id: -1,
			office_id: -1,
			employee_id: -1,
			department_id: -1,
			type: -1,
			date_created: -1,
		};
	}

	async getItems(
		props: GetListProps<FilterCheckin>
	): Promise<CheckinCollection> {
		return this._api
			? await this._api.getList(props.filters)
			: new CheckinCollection();
	}

	async getMineItems(
		props: GetListProps<FilterCheckin>
	): Promise<CheckinCollection> {
		return this._api
			? await this._api.getMineList(props.filters)
			: new CheckinCollection();
	}

	async getItem(id: number): Promise<CheckinModel> {
		return this._api
			? await this._api.getDetail(id)
			: new CheckinModel(CheckinModel.getDefaultData());
	}

	async saveRemote(data: CheckinJsonAddEdit): Promise<CheckinModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.add(data);
			} else {
				// return await this._api.edit(data);
				return await this._api.add(data);
			}
		} else {
			return new CheckinModel(CheckinModel.getDefaultData());
		}
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	async getItemsToday(props: GetListProps<FilterCheckin>) {
		return this._api
			? await this._api.getListToday(props)
			: new CheckinCollection();
	}
}

export default CheckinRepository;
