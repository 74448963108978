import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter, FilterValue } from "./Filter";

type WarehouseJson = {
	creator_id?: number;
	company_id?: number;
	id: number;
	name: string;
	code: string;
	address: string;
	lat: number;
	long: number;
	map_place_id: string;
	foreign_id: string;
	contact_email: string;
	contact_fullname: string;
	contact_phone: string;
	date_created?: number;
	date_modified?: number;
	description: string;
	display_order: number;
	foreign_id_more: string;
	region_id: number;
	sub_region_id: number;
	sub_sub_region_id: number;
	status: number;
	direction_in: DirectionAlow;
	direction_out: DirectionAlow;
};
export enum DirectionAlow {
	denied,
	accepted,
}
type WarehouseJsonAddEdit = WarehouseJson & {};

type WarehouseCollectionJson = BaseCollectionJson<WarehouseJson>;
type WarehouseStoreCollectionJson = BaseCollectionJson<WarehouseStoreJson>;

type FilterWarehouse = Filter & {
	id: FilterValue;
	creator_id: FilterValue;
	company_id: FilterValue;
	code: FilterValue;
	id_list: FilterValue;
};

type WarehouseStoreJson = {
	id: number;
	store_id: number;
	creator_id: number;
	warehouse_id: number;
	company_id: number;
};

export type {
	WarehouseJson,
	FilterWarehouse,
	WarehouseCollectionJson,
	WarehouseJsonAddEdit,
	WarehouseStoreJson,
	WarehouseStoreCollectionJson,
};
