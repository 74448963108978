import BaseCollection from "common/collections/BaseCollection";
import PromotionCouponModel from "common/models/PromotionCouponModel";
import { PromotionCouponJson, PromotionCouponCollectionJson } from "common/types/PromotionCoupon";

class PromotionCouponCollection extends BaseCollection<
  PromotionCouponJson,
  PromotionCouponModel,
  PromotionCouponCollectionJson
> {
  itemsFromJson(jsonItems: PromotionCouponJson[]): PromotionCouponModel[] {
    return jsonItems.map((item) => new PromotionCouponModel(item));
  }
}

export default PromotionCouponCollection;


