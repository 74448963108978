import React from "react";
import { Divider, List, Skeleton, Spin } from "antd";
import HomeTitle from "../HomeTitle";
import ErrorAlert from "components/ErrorAlert";
import { useTranslation } from "react-i18next";
import { Filter } from "common/types/Filter";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
const HomeLeftListWrapper = <F extends Filter, D>({
  iconTitle,
  title,
  loading,
  errors,
  dataSource,
  filters,
  setFilters,
  renderItem,
}: {
  iconTitle: React.ReactNode;
  title: string;
  loading: boolean;
  errors: string[];
  dataSource: D[];
  filters: F;
  setFilters: (f: F) => any;
  renderItem: (item: D) => React.ReactNode;
}) => {
  const { t } = useTranslation();

  //

  return (
    <div>
      <HomeTitle title={title} icon={iconTitle} />
      <Spin spinning={false} tip={t("common.tip")}>
        {errors.length > 0 ? (
          <ErrorAlert
            style={{ marginTop: 10 }}
            translate_prefix="common:errors"
            items={errors}
            type="warning"
          />
        ) : (
          <div className="max-h-[300px] overflow-y-auto smooth_scrollbar pr-2">
            <List
              itemLayout="vertical"
              grid={{ column: 1 }}
              size="small"
              className="relative"
              pagination={{
                size: "small",
                hideOnSinglePage: true,
                style: { margin: 0, position: "sticky", bottom: 0, right: 0 },
                onChange: (page) => {
                  setFilters({ ...filters, page: page });
                },
                pageSize: 10,
              }}
              dataSource={dataSource}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  style={{ marginBlockEnd: 0, padding: 0 }}
                >
                  {renderItem(item)}
                </List.Item>
              )}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default HomeLeftListWrapper;
