import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import {
  IconAddressBook,
  IconAlarmPlus,
  IconAlignBoxLeftMiddle,
  IconApps,
  IconBarcode,
  IconCalendarPlus,
  IconChartBar,
  IconDeviceDesktop,
} from "@tabler/icons-react";

type ActionItem = {
  link: string;
  label: string;
  icon: React.ReactNode;
};
type RowData = "row1" | "row2";
type Data = Record<RowData, ActionItem[]>;
const HomeRightAction = () => {
  const IconClassname = "mr-1";
  const IconSize = 16;
  const data: Data = {
    row1: [
      {
        label: "Chấm công",
        link: "/checkin/add",
        icon: <IconAlarmPlus size={IconSize} className={IconClassname} />,
      },
      {
        label: "Tạo đơn nghỉ",
        link: "/leave/add",
        icon: <IconCalendarPlus size={IconSize} className={IconClassname} />,
      },
    ],
    row2: [
      {
        label: "Đơn nghỉ của tôi",
        link: "/leave",
        icon: (
          <IconAlignBoxLeftMiddle size={IconSize} className={IconClassname} />
        ),
      },
      {
        label: "Danh bạ",
        link: "/phonebook",
        icon: <IconAddressBook size={IconSize} className={IconClassname} />,
      },
    ],
    // row3: [
    //   {
    //     label: "Báo cáo",
    //     link: "/report/sale",
    //     icon: <IconChartBar size={IconSize} className={IconClassname} />,
    //   },
    //   {
    //     label: " Sản phẩm",
    //     link: "/product",
    //     icon: <IconBarcode size={IconSize} className={IconClassname} />,
    //   },
    // ],
  };
  return (
    <>
      <div className="px-3 mb-6">
        <div className="font-semibold text-base mb-2">
          <IconApps className="-mt-1" /> Truy cập nhanh{" "}
        </div>
        <div className="ml-3 pl-4 border-l border-gray-300 leading-relaxed">
          {Object.entries(data).map(
            ([key, value]: [key: any, value: ActionItem[]]) => {
              return (
                <Row key={key} gutter={[8, 8]} className="mb-4">
                  {value.map((item, index) => {
                    return (
                      <Col key={index} span={24} xl={12}>
                        <Link to={item.link}>
                          <Button
                            className="flex items-center justify-center"
                            block
                            shape="round"
                            type="primary"
                            size="middle"
                            icon={item.icon}
                          >
                            {item.label}
                          </Button>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default HomeRightAction;
