import React from "react";

const InfoContent = ({
  title,
  subTitle,
  className,
  children,
}: {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <div className={"mx-auto max-w-4xl " + className}>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          {typeof title !== "undefined" || typeof subTitle !== "undefined" ? (
            <div className="px-4 py-6 sm:px-6">
              {typeof title !== "undefined" ? (
                <h2 className="text-2xl font-semibold leading-7 text-gray-900">
                  {title}
                </h2>
              ) : null}

              {typeof subTitle !== "undefined" ? (
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  {subTitle}
                </p>
              ) : null}
            </div>
          ) : null}

          <div
            className={
              typeof title !== "undefined" || typeof subTitle !== "undefined"
                ? "border-t border-gray-100"
                : ""
            }
          >
            <dl className="divide-y divide-gray-100">{children}</dl>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoContent;
