import { CheckOutlined } from "@ant-design/icons";
import { Empty, FormItemProps, Typography } from "antd";
import { AutoComplete, Form, Input } from "antd";
import { DefaultOptionType } from "antd/es/select";
import StoryCollection from "common/collections/StoryCollection";
import PublicationsRepository from "common/repositories/PublicationsRepository";
import { FilterStory, StoryJson } from "common/types/Story";
import SearchComponent from "components/search/SearchLayout";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
type Props = FormItemProps & {
	disabled?: boolean;
};

const AttachmentSearch = (props: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<SearchComponent<StoryJson, FilterStory, StoryCollection>
				fetchRepository={(filers) => {
					return new PublicationsRepository().getItems({
						filters: filers,
					});
				}}
				defaultFilters={PublicationsRepository.getDefaultFilter()}
				formItemProps={{
					...props,
				}}
				disabled={props.disabled}
				keyValue="id"
				showFilters={["keyword"]}
				defaultUseFilters={["keyword"]}
				renderLabel={(item) => {
					return <>{item.meta_title}</>;
				}}
			/>
		</>
	);
};

export default AttachmentSearch;
