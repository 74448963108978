import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";

import { IconFolders } from "@tabler/icons-react";

const CmsNewsListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("cmsnews:heading_list")}
      siteMenuOpenKey="sitemenu:group_cms"
      siteMenuSelectedKey="/cmsnews"
    >
      <PageHeaderButton
        type="default"
        link="/cmsnewscategory"
        icon={<IconFolders className="mr-1" size={14} />}
      >
        {t("cmsnews:category_management")}
      </PageHeaderButton>
      <RoleCheck roles={[Role.CMS_NEWS_ADD]} hideOnFail>
        <PageHeaderButton link="/cmsnews/add" className="ml-2">
          {t("cmsnews:add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default CmsNewsListHeader;
