import InventoryStockApi from "common/api/InventoryStockApi";
import InventoryStockCollection from "common/collections/InventoryStockCollection";
import InventoryStockModel from "common/models/InventoryStockModel";
import InventoryStockStatsModel from "common/models/InventoryStockStatsModel";

import BaseRepository from "./BaseRepository";

import type { FilterInventoryStock } from "common/types/InventoryStock";
class InventoryStockRepository extends BaseRepository<InventoryStockApi> {
  _api: InventoryStockApi | null;

  constructor() {
    super();
    this._api = new InventoryStockApi(true);
  }

  static getDefaultFilters(): FilterInventoryStock {
    return {
      page: 1,
      limit: 20,
      sortby: "name",
      sorttype: "ASC",
      status: -1,
      keyword: "",
      ids: "",
    };
  }

  async getListProductVariantQuantity(ids: string, from: string) {
    return this._api
      ? await this._api.getListProductVariantQuantity(ids, from)
      : new InventoryStockCollection();
  }

  async getOneProductVariantQuantity(
    product_variant_id: number
  ): Promise<InventoryStockModel> {
    return this._api
      ? await this._api.getOneProductVariantQuantity(product_variant_id)
      : new InventoryStockModel(InventoryStockModel.getDefaultData());
  }

  async getOneProductQuantity(
    product_id: number
  ): Promise<InventoryStockModel> {
    return this._api
      ? await this._api.getOneProductQuantity(product_id)
      : new InventoryStockModel(InventoryStockModel.getDefaultData());
  }

  async getSearchIndexStats(): Promise<InventoryStockStatsModel> {
    return this._api
      ? await this._api.getSearchIndexStats()
      : new InventoryStockStatsModel(InventoryStockStatsModel.getDefaultData());
  }

  async initSearchIndex(): Promise<string[]> {
    return this._api ? await this._api.initSearchIndex() : [];
  }
}

export default InventoryStockRepository;
