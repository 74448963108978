import { Button, Col, Divider, Form, Input, Row, Table } from "antd";
import CustomerModel from "common/models/CustomerModel";
import CustomerRepository from "common/repositories/CustomerRepository";
import { TableColumnsType } from "common/types/Table";
import TextCustomerType from "components/TextCustomerType";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CustomerSearchWidget = ({
  onSelect,
}: {
  onSelect: (v: CustomerModel) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [searchPhone, setSearchPhone] = useState("");
  const [searchFullName, setSearchFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const [foundCustomers, setFoundCustomers] = useState<CustomerModel[]>([]);

  useEffect(() => {
    setFoundCustomers([]);
  }, [searchPhone, searchFullName]);

  const handleSubmit = useCallback(
    async (e: any) => {
      setLoading(true);
      setFoundCustomers([]);
      let collection = await new CustomerRepository().getItems({
        filters: {
          ...CustomerRepository.getDefaultFilters(),
          page: 1,
          limit: 10,
          phone: searchPhone.trim(),
          full_name: searchFullName.trim(),
        },
      });
      setLoading(false);
      setFoundCustomers(collection.items);
    },
    [searchPhone, searchFullName]
  );

  const columns: TableColumnsType<CustomerModel> = [
    {
      title: "Họ & Tên",
      dataIndex: "full_name",
    },

    {
      title: "Điện thoại",
      dataIndex: "phone",
    },
    {
      title: "Nhóm",
      dataIndex: "customer_type_id",
      render: (customer_type_id: number) => (
        <TextCustomerType id={customer_type_id} />
      ),
    },
    {
      title: " ",
      align: "right",
      width: 70,
      render: (_, item: CustomerModel) => (
        <Button type="link" size="small" onClick={() => onSelect(item)}>
          Chọn
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="container">
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Điện thoại" name="phone">
                <Input
                  value={searchPhone}
                  autoFocus={true}
                  onPressEnter={handleSubmit}
                  onChange={(e) => setSearchPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("customer:full_name")} name="full_name">
                <Input
                  value={searchFullName}
                  onPressEnter={handleSubmit}
                  onChange={(e) => setSearchFullName(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="&nbsp;">
                <Button
                  type="primary"
                  block
                  onClick={handleSubmit}
                  loading={loading}
                  className="w-100"
                >
                  {loading ? null : "Tìm"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      {foundCustomers.length > 0 ? (
        <div>
          <Divider orientation="left" style={{ marginTop: 0 }}>
            Kết quả tìm kiếm ({foundCustomers.length})
          </Divider>
          {foundCustomers.length > 0 ? (
            <div>
              <Table
                size="small"
                rowKey="id"
                pagination={{ hideOnSinglePage: true }}
                columns={columns}
                dataSource={foundCustomers}
              />
            </div>
          ) : (
            <>Không tìm thấy khách hàng thỏa điều kiện tìm kiếm</>
          )}
        </div>
      ) : null}
    </>
  );
};

export default CustomerSearchWidget;
