import { Button, Popover, Spin } from "antd";
import SupplierModel from "common/models/SupplierModel";
import SupplierRepository from "common/repositories/SupplierRepository";
import dayjs from "dayjs";
import SupplierFormSelect from "features/supplier/SupplierFormSelect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconCircleCheck } from "@tabler/icons-react";

const CashflowReceiptTargetSupplier = ({
  id,
  setId,
  disabled,
}: {
  id: number;
  setId: (v: number) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const [supplier, setSupplier] = useState<SupplierModel>();

  const fetchSupllier = useCallback(async (supplierId: number) => {
    const supplierModel = await new SupplierRepository().getItem(supplierId);
    if (supplierModel.hasError()) {
    } else {
      setSupplier(supplierModel);
    }
  }, []);

  const supplierPopover = useMemo(() => {
    return (
      <>
        <div className="mb-2">{t("cashflowreceipt:TARGET_SUPPLIER")}:</div>
        <SupplierFormSelect
          label=""
          name={dayjs().unix().toString()}
          className="mb-0"
          onChange={(value) => setId(value)}
        />
      </>
    );
  }, [setId, t]);

  useEffect(() => {
    if (id > 0) {
      setSupplier(undefined);
      fetchSupllier(id);
    }
  }, [id, fetchSupllier]);

  return (
    <>
      {id > 0 ? (
        <>
          <IconCircleCheck size={16} className="-mt-0.5 mr-1 text-green-500" />
          {typeof supplier !== "undefined" ? (
            <>
              {supplier.hasError() ? (
                <>Load error (ID: {id}).</>
              ) : (
                <>{supplier.name}</>
              )}
            </>
          ) : (
            <>
              <Spin size="small" />
            </>
          )}

          {!disabled ? (
            <Button danger type="text" onClick={() => setId(0)}>
              {t("cashflowreceipt:target.clear")}
            </Button>
          ) : null}
        </>
      ) : (
        <>
          {t("cashflowreceipt:target.supplier_empty")}{" "}
          {!disabled ? (
            <Popover
              placement="right"
              content={supplierPopover}
              trigger="click"
              overlayInnerStyle={{ width: 400 }}
              defaultOpen
            >
              <Button type="link" size="small">
                {t("cashflowreceipt:target.select")}
              </Button>
            </Popover>
          ) : null}
        </>
      )}
    </>
  );
};

export default CashflowReceiptTargetSupplier;
