import { Button, Tag, message } from "antd";
import { TableColumnsType } from "common/types/Table";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import TagTypeCollection from "common/collections/TagTypeCollection";
import TagTypeModel from "common/models/TagTypeModel";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import { FilterTagType } from "common/types/TagType";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import TagTypeFormModal from "../form/TagTypeFormModal";
import TagTypeListHeader from "./TagTypeListHeader";
import TagTypeListFilter from "./TagTypeListFilter";

const TagTypeList = () => {
  const { t } = useTranslation();
  // default filter
  const defaultFilters = useMemo(() => {
    return TagTypeRepository.getDefaultFilters();
  }, []);
  //////////////////////////////////////////
  //Fetch data from this collections
  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [dataSource, setDataSource] = useState<TagTypeModel[]>([]);
  const [total, setTotal] = useState<number>(0);
  const fetchData = useCallback(async (): Promise<TagTypeCollection> => {
    const response = await new TagTypeRepository().getItems({
      filters: filters,
    });
    if (!response.hasError()) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      message.error("common:error.error_fetching_data");
    }

    return response;
  }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  const renderTagStatus = (value: number) => {
    switch (value) {
      case 1:
        return <Tag color="green">Hiện</Tag>;
      case 2:
        return <Tag>Nháp</Tag>;
      case 3:
        return <Tag color="red">Ẩn</Tag>;
      default:
        return null;
    }
  };

  //Table columns
  const columns: TableColumnsType<TagTypeModel> = [
    {
      title: "ID",
      key: "id",
    },
    {
      title: t("tagtype:code"),
      key: "code",
    },
    {
      title: t("tagtype:name"),
      key: "name",
    },
    {
      title: t("Dùng cho"),
      key: "resource_type",
      render(value, record, index) {
        return <>{TagTypeModel.getResouceTypeValue(value)}</>;
      },
    },
    {
      title: t("tagtype:status"),
      key: "status",
      render(value, record, index) {
        return renderTagStatus(value);
      },
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 125,
      render: (_: any, record: TagTypeModel) => (
        <RoleCheck roles={[]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          {/* <TableDelete
            error_translate_prefix="tagtype:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new TagTypeRepository()}
            id={record.id}
          /> */}
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: TagTypeModel) => {
    fetchData();
  };

  return (
    <>
      <TagTypeListHeader>
        {/* <RoleCheck roles={[Role.PRODUCT_MANAGE]}> */}
        <Button
          type="primary"
          onClick={() => {
            setEditingId(0);
            setAddVisible(true);
          }}
        >
          {t("common:table.add_button")}
        </Button>
        {/* </RoleCheck> */}
      </TagTypeListHeader>

      <TagTypeListFilter
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        total={total}
      />

      <PageDataTable<FilterTagType, TagTypeModel, TagTypeCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "tagtype",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <TagTypeFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default TagTypeList;
