import { App, Form, Tabs } from "antd";
import ProjectTaskChangeCollection from "common/collections/ProjectTaskChangeCollection";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskChangeModel from "common/models/ProjectTaskChangeModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectTaskChangeRepository from "common/repositories/ProjectTaskChangeRepository";
import ProjectTaskRepository from "common/repositories/ProjectTaskRepository";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import ProjectTaskTabUpdateHistory from "features/projecttask/tab/ProjectTaskTabUpdateHistory";
import ProjectTaskTabUpdateStatus from "features/projecttask/tab/ProjectTaskTabUpdateStatus";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { ProjectTaskJsonUpdateStatus } from "common/types/ProjectTask";
const ProjectTaskFormInfo = ({
  projectModel,
  projectTaskModel,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  projectModel: ProjectModel;
  projectTaskModel: ProjectTaskModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: ProjectTaskModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const account = useLoginAccountStore((state) => state.account);

  const initialValues = useMemo(() => {
    return {
      status: projectTaskModel.status,
      progress_percent: 0,
      change_note: projectTaskModel.note
    };
  }, [projectTaskModel]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProjectTaskJsonUpdateStatus = {
        id: projectTaskModel.id,
        company_id: account.company.id,
        creator_id: account.id,
        status: formData.status,
        progress_percent: 0,
        change_note: formData.change_note
      };

      return submitData;
    },
    [projectTaskModel.id, account.company.id, account.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });
    let item: ProjectTaskModel = await new ProjectTaskRepository().updateStatus(
      doPrepareData(formData)
    );
    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="projecttask:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSourceProjectTaskChange, setDataSourceProjectTaskChange] =
    useState<ProjectTaskChangeModel[]>([]);
  const fetchProjectTaskChange =
    useCallback(async (): Promise<ProjectTaskChangeCollection> => {
      let collection = await new ProjectTaskChangeRepository().getItems({
        filters: {
          ...ProjectTaskChangeRepository.getDefaultFilters(),
          project_id: projectModel.id,
          project_task_id: projectTaskModel.id,
          company_id: account.company.id
        }
      });
      setDataSourceProjectTaskChange(collection.items);

      return collection;
    }, [account.company.id, projectModel.id, projectTaskModel.id]);

  useEffect(() => {
    if (projectModel.id > 0 && projectTaskModel.id > 0) {
      fetchProjectTaskChange();
    }
  }, [projectModel.id, projectTaskModel.id, fetchProjectTaskChange]);

  const tabItems = [
    {
      label: t("projecttask:update_status"),
      key: "tab_update_status",
      children: <ProjectTaskTabUpdateStatus projectModel={projectModel} />
    },
    {
      label: t("projecttask:update_history"),
      key: "tab_update_history",
      children: (
        <ProjectTaskTabUpdateHistory dataSource={dataSourceProjectTaskChange} />
      )
    }
  ];

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Tabs defaultActiveKey="tab_update_status" items={tabItems} />
    </Form>
  );
};

export default ProjectTaskFormInfo;
