import React, { useEffect } from "react";
import LayoutTitle from "components/LayoutTitle";
import { useTranslation } from "react-i18next";
import notfound from "images/illustrators/empty_street.png";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
import ErrorAlert from "components/ErrorAlert";

const NotFoundPage = ({ children }: { children?: React.ReactNode }) => {
	const { t } = useTranslation();
	const account = useLoginAccountStore((state) => state.account);
	const navigate = useNavigate();
	// cmt temp

	if (account.id <= 0)
		return (
			<div className="h-screen flex items-center justify-center">
				<div className="min-w-[325px] h-auto text-center py-2 px-4 bg-white rounded-md flex flex-col justify-center items-center">
					<Typography.Title level={3}>
						{t(`common:errors.error_jwt_hash_not_found`)}
					</Typography.Title>
					<ErrorAlert
						style={{ marginTop: 10 }}
						translate_prefix="common:session.error"
						items={["jwt_required"]}
						type="warning"
					/>
					<Button type="primary" className="mt-4" onClick={() => navigate("/")}>
						{t("Về đăng nhập")}
					</Button>
				</div>
			</div>
		);
	return (
		<>
			{children}
			<div className="content content-auth-alt">
				<LayoutTitle title={t("common:not_found_page.heading")} />
				<div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
					<img src={notfound} alt="notfound" className="w-1/3" />
					<div className="max-w-max mx-auto mt-4">
						<main className="sm:flex">
							<p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">
								404
							</p>
							<div className="sm:ml-6">
								<div className="sm:border-l sm:border-gray-200 sm:pl-6">
									<h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
										{t("common:not_found_page.heading")}
									</h1>
									<p className="mt-1 text-base text-gray-500">
										{t("common:not_found_page.subtitle")}
									</p>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotFoundPage;
