import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

import ShopBannerHomeList from "features/shopbannerhome/list/ShopBannerHomeList";

const ShopBannerHomePage = () => {
  const [pathParams] = usePathParams();
  let com = <></>;

  switch (pathParams.action) {
    default:
      com = <ShopBannerHomeList />;
      break;
  }
  return com;
};

export default ShopBannerHomePage;
