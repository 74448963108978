import { Tooltip } from "antd";
import ProductModel from "common/models/ProductModel";
import TextMoney from "components/TextMoney";
import dbm from "dbm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconTag } from "@tabler/icons-react";

const ProductItemSummary = ({ record }: { record: ProductModel }) => {
  const { t } = useTranslation();
  const [countVariant, setCountVariant] = useState<number | undefined>();
  const [minPrice, setMinPrice] = useState(-1);
  const [maxPrice, setMaxPrice] = useState(-1);

  useEffect(() => {
    (async () => {
      const foundItems = await dbm
        .getCollection("productvariant")
        .chain()
        .find({
          product_id: record.id,
        })
        .data();

      //first, get count variant
      setCountVariant(foundItems.length);

      //second, find the MIN, MAX price from all variant
      if (foundItems.length > 0) {
        setMinPrice(Math.min(...foundItems.map((i) => i.price)));
        setMaxPrice(Math.max(...foundItems.map((i) => i.price)));
      }
    })();
  }, [record.id]);

  return (
    <>
      {typeof countVariant !== "undefined" ? (
        <>
          {countVariant > 0 ? (
            <>
              <strong>{countVariant}</strong> SKU
            </>
          ) : (
            <span>{t("product:variant_empty")}</span>
          )}
        </>
      ) : (
        <></>
      )}

      {minPrice !== -1 && maxPrice !== -1 ? (
        <>
          <span className="mx-2">|</span>
          <span>
            <Tooltip title={t("productvariant:price")}>
              <IconTag size={16} className="-mt-0.5 mr-1" />
            </Tooltip>

            <span>
              {minPrice === maxPrice ? (
                <>
                  <TextMoney money={minPrice} />
                </>
              ) : (
                <>
                  <TextMoney money={minPrice} /> -{" "}
                  <TextMoney money={maxPrice} />
                </>
              )}
            </span>
          </span>
        </>
      ) : null}
    </>
  );
};

export default ProductItemSummary;
