import { Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddressInputLatLong = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (lat: number, long: number) => void;
}) => {
  const { t } = useTranslation();

  const [text, setText] = useState(value);

  const onFocusInput = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const checkAndSetLatLong = useCallback(
    (newLatLong: string) => {
      let lat = 0;
      let long = 0;
      const parts = newLatLong.split(",");
      if (parts.length === 2) {
        lat = parseFloat(parts[0].trim());
        long = parseFloat(parts[1].trim());
      }
      onChange(lat, long);
    },
    [onChange]
  );

  //update current input value if form value changed
  useEffect(() => {
    setText(value);
  }, [value]);

  const manualLatLongStyle = {
    zIndex: 0,
    marginTop: -2,
    padding: "1px 0 0 0",
    background: "#d9d9d9",
    borderRadius: "0 0 4px 4px",
  };

  return (
    <Input
      style={manualLatLongStyle}
      placeholder="Lat, Long"
      addonBefore={
        <span style={{ color: "#999" }}>
          {t("common:address_input.latlong")}:
        </span>
      }
      value={text === "0, 0" ? "" : text}
      onFocus={onFocusInput}
      onBlur={(e) => checkAndSetLatLong(e.target.value)}
      onChange={(e) => setText(e.target.value)}
    />
  );
};

export default AddressInputLatLong;
