import ShippingTypeApi from 'common/api/ShippingTypeApi';
import ShippingTypeCollection from 'common/collections/ShippingTypeCollection';
import ShippingTypeModel from 'common/models/ShippingTypeModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ShippingTypeJsonAddEdit,
  FilterShippingType,
} from "common/types/ShippingType";

class ShippingTypeRepository extends BaseRepository<ShippingTypeApi> {
  _api: ShippingTypeApi | null;

  constructor() {
    super();
    this._api = new ShippingTypeApi(true);
  }

  static getDefaultFilters(): FilterShippingType {
    return {
      page: 1,
      limit: 50,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
      creator_id: -1,
      ids: "",
    };
  }

  async getFullData(
    companyId: number,
    version: number
  ): Promise<ShippingTypeCollection> {
    return this._api
      ? await this._api.getFullData({ companyId, version })
      : new ShippingTypeCollection();
  }

  async getItems(props: GetListProps<FilterShippingType>) {
    return this._api
      ? await this._api.getList(props)
      : new ShippingTypeCollection();
  }

  async getItem(id: number): Promise<ShippingTypeModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShippingTypeModel(ShippingTypeModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: ShippingTypeJsonAddEdit): Promise<ShippingTypeModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShippingTypeModel(ShippingTypeModel.getDefaultData());
    }
  }
}

export default ShippingTypeRepository;
