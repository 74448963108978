import "@glideapps/glide-data-grid-cells/dist/index.css";
import "@glideapps/glide-data-grid/dist/index.css";

import { App, Button, Col, Popconfirm, Row } from "antd";
import ColorHash from "color-hash";
import DataGridTableField from "common/constants/DataGridTableField";
import DataGridTableFieldModel from "common/models/DataGridTableFieldModel";
import DataGridTableModel from "common/models/DataGridTableModel";
import DataGridTableRowModel from "common/models/DataGridTableRowModel";
import DataGridTableFieldRepository from "common/repositories/DataGridTableFieldRepository";
import DataGridTableRowRepository from "common/repositories/DataGridTableRowRepository";
import { DataGridTableFieldMenu } from "common/types/DataGridTableField";
import {
  DataGridTableRowData,
  FilterDataGridTableRow
} from "common/types/DataGridTableRow";
import { EmployeeJson } from "common/types/Employee";
import Error from "components/LayoutError";
import LayoutTitle from "components/LayoutTitle";
import PageSiteMenu from "components/page/PageSiteMenu";
import dayjs from "dayjs";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import useWindowDimensions from "hooks/useWindowDimensions";
import produce from "immer";
import update from "immutability-helper";
import { sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLayer } from "react-laag";
import { Link } from "react-router-dom";

import {
  CompactSelection,
  DataEditor,
  EditableGridCell,
  GridCell,
  GridCellKind,
  GridColumn,
  GridSelection,
  Item,
  Rectangle
} from "@glideapps/glide-data-grid";
import {
  DatePickerCell,
  DatePickerType,
  DropdownCell,
  DropdownCellType,
  StarCell,
  StarCellType,
  TagsCell,
  TagsCellType,
  useExtraCells
} from "@glideapps/glide-data-grid-cells";
import { IconAlertTriangle, IconTable, IconTrash } from "@tabler/icons-react";

import DataGridHeader from "./DataGridHeader";
import DataGridEditorHeaderFieldAddButton from "./editor/header/DataGridEditorHeaderFieldAddButton";
import DataGridEditorHeaderMenu from "./editor/header/DataGridEditorHeaderMenu";
import DataGridTableAction from "./table/DataGridTableAction";
import DataGridTableFieldFormModal from "./table/field/DataGridTableFieldFormModal";

const DataGridContainer = ({
  model,
  onClickEditTable,
  onClickDeleteTable,
  isDeleting
}: {
  model: DataGridTableModel;
  onClickEditTable: () => void;
  onClickDeleteTable: () => void;
  isDeleting: boolean;
}) => {
  const { t } = useTranslation();

  const { message, modal } = App.useApp();

  const saveRemoteFieldWidthTimeoutRef = useRef<any>();
  const { height } = useWindowDimensions();
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");
  const [dataSource, setDataSource] = useState<DataGridTableRowModel[]>();
  // const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [fields, setFields] = useState(model.fields);

  //deleing field
  // const [processingField, setProcessingField] = useState(false);
  const messageKeyDeleteFieldPrefix = "delete_tablefield_";

  //init empty, useeffect to set columns
  // const [columns, setColumns] = useState<GridColumn[]>([]);
  const [selection, setSelection] = useState<GridSelection>();
  const [selectedRowList, setSelectRowList] = useState<number[]>([]);

  // editing column (table field)
  const [newTableFieldDisplayOrder, setNewTableFieldFieldDisplayOrder] =
    useState(0);
  const [editingTableFieldId, setEditingTableFieldId] = useState(0);
  const [editTableFieldVisible, setEditTableFieldVisible] = useState(false);

  const keyHeader = useMemo(() => {
    return fields.map((field) => "f_" + field.id || "");
  }, [fields]);

  //parse column from fields (fields can update from add/edit column)
  const columns = useMemo(() => {
    return fields.map((field) => field.getEditorGridColumnDefine());
  }, [fields]);

  //field menu
  const [menu, setMenu] = useState<DataGridTableFieldMenu>();
  //use ref to prevent hide menu too fash (bug on react-laag and react-18)
  const menuOpenTimeRef = useRef(0);

  const isOpen = menu !== undefined;

  const { layerProps, renderLayer } = useLayer({
    isOpen,
    auto: true,
    placement: "bottom-end",
    triggerOffset: 2,
    onOutsideClick: () => {
      //because of bug of react-laag and react-18
      //we need to check opentime ref to prevent call outsideclick too fast
      if (dayjs().valueOf() - menuOpenTimeRef.current > 200) {
        //hide menu
        setMenu(undefined);

        //clear show time
        menuOpenTimeRef.current = 0;
      }
    },
    trigger: {
      getBounds: () => ({
        left: menu?.bounds.x ?? 0,
        top: menu?.bounds.y ?? 0,
        width: menu?.bounds.width ?? 0,
        height: menu?.bounds.height ?? 0,
        right: (menu?.bounds.x ?? 0) + (menu?.bounds.width ?? 0),
        bottom: (menu?.bounds.y ?? 0) + (menu?.bounds.height ?? 0)
      })
    }
  });

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterDataGridTableRow = useMemo(
    () => DataGridTableRowRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterDataGridTableRow>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const fetchData = useCallback(async () => {
    // setLoading(true);
    let collection = await new DataGridTableRowRepository().getItems({
      filters: {
        ...filters,
        datagrid_table_id: model.id
      }
    });
    // setLoading(false);
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setDataSource(collection.items);
    }
  }, [model.id, filters]);

  const onSubmitUpdateRow = useCallback(
    async (formData: DataGridTableRowModel): Promise<DataGridTableRowModel> => {
      let item: DataGridTableRowModel =
        await new DataGridTableRowRepository().saveRemote({
          id: formData.id,
          datagrid_table_id: formData.datagrid_table_id,
          data: formData.data,
          display_order: formData.display_order
        });

      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="datagridtablerow:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4
        });
      }
      return item;
    },
    [t, message]
  );

  const onCellEdited = useCallback(
    (cell: Item, newValue: EditableGridCell) => {
      if (Array.isArray(dataSource)) {
        console.log("newValue", newValue);
        const indexes: (keyof any)[] = keyHeader;
        const [col, row] = cell;
        const key = indexes[col];

        let parsedCellData = newValue.data;
        if (newValue.kind === GridCellKind.Custom) {
          if (DropdownCell.isMatch(newValue)) {
            parsedCellData = newValue.data.value;
          } else if (StarCell.isMatch(newValue)) {
            parsedCellData = newValue.data.rating;
          } else if (DatePickerCell.isMatch(newValue)) {
            parsedCellData = dayjs(newValue.data.date).format("DD/MM/YYYY");
          } else if (TagsCell.isMatch(newValue)) {
            parsedCellData = newValue.data.tags;
          }
        }

        const itemData = dataSource[row];
        const newItem = new DataGridTableRowModel({
          ...itemData,
          data: {
            ...itemData.data,
            [key]: parsedCellData
          } as DataGridTableRowData
        });

        const foundIndex = dataSource.findIndex((r) => r.id === itemData.id);
        if (foundIndex >= 0) {
          setDataSource(
            update(dataSource, {
              [foundIndex]: {
                $set: newItem
              }
            })
          );
          //update current role item info
          onSubmitUpdateRow(newItem);
        }
      }
    },

    [dataSource, keyHeader, onSubmitUpdateRow]
  );

  const getContentColumnOptions = useCallback(
    (col: number): string[] => {
      let options: string[] = [];

      //extract options base on field type
      const field = fields[col];

      if (typeof field !== "undefined") {
        switch (field.type) {
          case DataGridTableField.TYPE_SINGLE_SELECT:
          case DataGridTableField.TYPE_MULTI_SELECT:
            options =
              field.value_options.length > 0
                ? field.value_options.split(",").map((i) => i.trim())
                : [];
            break;
          case DataGridTableField.TYPE_COLLABORATOR:
            options = employeeItems
              .filter((i) => i.status > 0 && i.user_id > 0)
              .map((i) => i.full_name);
            break;
        }
      }

      return options;
    },
    [fields, employeeItems]
  );

  // Grid columns may also provide icon, overlayIcon, menu, style, and theme overrides
  const getContent = useCallback(
    (cell: Item): GridCell => {
      const [col, row] = cell;

      const indexes: (keyof any)[] = keyHeader;
      const dataField = Array.isArray(dataSource) ? dataSource[row]?.data : {};

      const cellData = dataField[indexes[col] as string];

      //find the value_option
      let valueOptions = getContentColumnOptions(col);

      switch (fields[col].type) {
        case DataGridTableField.TYPE_NUMBER:
          return {
            kind: GridCellKind.Number,
            allowOverlay: true,
            readonly: false,
            displayData: Number(cellData || 0).toLocaleString(),
            data: Number(cellData || 0)
          };
        case DataGridTableField.TYPE_BOOLEAN:
          return {
            kind: GridCellKind.Boolean,
            allowOverlay: false,
            readonly: false,
            data: typeof cellData === "boolean" && cellData
          };
        case DataGridTableField.TYPE_URL:
          return {
            kind: GridCellKind.Uri,
            allowOverlay: true,
            readonly: false,
            data: typeof cellData !== "undefined" ? cellData?.toString() : ""
          };

        case DataGridTableField.TYPE_PHONE_NUMBER:
          return {
            kind: GridCellKind.Text,
            allowOverlay: true,
            readonly: false,
            displayData:
              typeof cellData !== "undefined" ? cellData?.toString() : "",
            data: typeof cellData !== "undefined" ? cellData?.toString() : ""
          };
        case DataGridTableField.TYPE_SINGLE_SELECT:
          return {
            kind: GridCellKind.Custom,
            allowOverlay: true,
            copyData: cellData?.toString(),
            data: {
              kind: "dropdown-cell",
              allowedValues: valueOptions,
              value: typeof cellData === "undefined" ? "" : cellData?.toString()
            }
          } as DropdownCellType;
        case DataGridTableField.TYPE_MULTI_SELECT:
          //process valueOptions because this require format {tag:.., color:..}
          return {
            kind: GridCellKind.Custom,
            allowOverlay: true,
            readonly: false,
            copyData: Array.isArray(cellData) ? cellData.join(",") : "",
            data: {
              kind: "tags-cell",
              possibleTags: valueOptions.map((i) => ({
                tag: i,
                color: new ColorHash({ lightness: 0.75 }).hex(i)
              })),
              tags: Array.isArray(cellData) ? cellData : []
            }
          } as TagsCellType;
        case DataGridTableField.TYPE_COLLABORATOR:
          return {
            kind: GridCellKind.Custom,
            allowOverlay: true,
            copyData: cellData?.toString(),
            data: {
              kind: "dropdown-cell",
              allowedValues: valueOptions,
              value: typeof cellData === "undefined" ? "" : cellData?.toString()
            }
          } as DropdownCellType;
        case DataGridTableField.TYPE_RATING:
          return {
            kind: GridCellKind.Custom,
            allowOverlay: true,
            copyData: Number(cellData || 3).toString(),
            data: {
              kind: "star-cell",
              label: "Test",
              rating: Number(cellData || 3)
            }
          } as StarCellType;
        case DataGridTableField.TYPE_DATE:
          return {
            kind: GridCellKind.Custom,
            allowOverlay: true,
            copyData: cellData?.toString(),
            data: {
              kind: "date-picker-cell",
              format: "date",
              displayDate: dayjs(cellData?.toString(), "DD/MM/YYYY").isValid()
                ? cellData?.toString()
                : dayjs().format("DD/MM/YYYY"),
              date: dayjs(cellData?.toString(), "DD/MM/YYYY").isValid()
                ? dayjs(cellData?.toString(), "DD/MM/YYYY")
                    .endOf("day")
                    .toDate()
                : new Date()
            }
          } as DatePickerType;

        case DataGridTableField.TYPE_EMAIL:
        case DataGridTableField.TYPE_TEXT:
        case DataGridTableField.TYPE_LONG_TEXT:
        default:
          return {
            kind: GridCellKind.Text,
            allowOverlay: true,
            readonly: false,
            displayData:
              typeof cellData !== "undefined" ? cellData.toString() : "",
            data: typeof cellData !== "undefined" ? cellData.toString() : ""
          };
      }
    },

    [keyHeader, dataSource, fields, getContentColumnOptions]
  );

  const onSubmitAddRow =
    useCallback(async (): Promise<DataGridTableRowModel> => {
      let item: DataGridTableRowModel =
        await new DataGridTableRowRepository().saveRemote({
          ...DataGridTableRowModel.getDefaultData(),
          datagrid_table_id: model.id
        });

      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="datagridtablerow:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4
        });
      }

      return item;
    }, [t, model.id, message]);

  const onRowAppended = useCallback(() => {
    onSubmitAddRow().then((item) => {
      setDataSource(
        update(dataSource, {
          $push: [item]
        })
      );
    });
  }, [dataSource, onSubmitAddRow]);

  const onHeaderMenuClick = useCallback((col: number, bounds: Rectangle) => {
    setMenu({ col, bounds });
    menuOpenTimeRef.current = dayjs().valueOf();
  }, []);

  /**
   * Clicked on Edit column
   */
  const onClickEditField = useCallback(() => {
    if (typeof menu !== "undefined") {
      const foundField = fields[menu.col];
      if (typeof foundField !== "undefined") {
        setEditingTableFieldId(foundField.id);
        setEditTableFieldVisible(true);
      }
    }
  }, [menu, fields]);

  const onClickAddField = useCallback(
    (offset: number) => {
      if (typeof menu !== "undefined") {
        const foundField = fields[menu.col];
        if (typeof foundField !== "undefined") {
          let newDipslayOrder = 0;
          //base of offset & current active menu
          //we calculate the displayorder of new field
          const fieldIndex = fields.findIndex((i) => i.id === foundField.id);
          if (fieldIndex >= 0) {
            if (offset < 0) {
              //insert on LEFT
              if (fieldIndex === 0) {
                //not found left item
                newDipslayOrder = (0 + foundField.display_order) / 2;
              } else {
                //found left item
                newDipslayOrder =
                  (fields[fieldIndex - 1].display_order +
                    foundField.display_order) /
                  2;
              }
            } else if (offset > 0) {
              //insert on RIGHT
              if (fieldIndex === fields.length - 1) {
                //not found right item
                newDipslayOrder = fields[fields.length - 1].display_order + 1;
              } else {
                newDipslayOrder =
                  (foundField.display_order +
                    fields[fieldIndex + 1].display_order) /
                  2;
              }
            }
          }
          //end finding new displayorder
          // alert("new displayorder " + newDipslayOrder);
          setNewTableFieldFieldDisplayOrder(newDipslayOrder);
          setEditingTableFieldId(0);
          setEditTableFieldVisible(true);
        }
      }
    },
    [menu, fields]
  );

  const onDeleteField = useCallback(
    async (field: DataGridTableFieldModel) => {
      const { id, name } = field;

      // setProcessingField(true);
      message.loading({
        content: t("datagridtablefield:delete_on_progress", { id, name }),
        key: messageKeyDeleteFieldPrefix + id,
        duration: 0
      });

      const deletedErrors = await new DataGridTableFieldRepository().deleteItem(
        id
      );
      // setProcessingField(false);
      if (deletedErrors.length === 0) {
        message.success({
          content: t("datagridtablefield:form.success.delete", { id, name }),
          className: "message_success",
          key: messageKeyDeleteFieldPrefix + id,
          duration: 1
        });

        //update field list
        setFields(fields.filter((i) => i.id !== id));
      } else {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKeyDeleteFieldPrefix + id);
              }}
              heading={t("common:error.table_delete_heading")}
              translate_prefix={"datagridtablefield:form.error"}
              items={deletedErrors}
            />
          ),
          className: "message_error",
          key: messageKeyDeleteFieldPrefix + id,
          duration: 10
        });
      }
    },
    [t, fields, message]
  );

  /**
   * Clicked on Delete column
   */
  const onClickDeleteField = useCallback(() => {
    if (typeof menu !== "undefined") {
      const foundField = fields[menu.col];
      if (typeof foundField !== "undefined") {
        //show confirm dialog
        modal.confirm({
          icon: null,
          title: t("datagridtablefield:delete_confirm_title", {
            name: foundField.name
          }),
          content: (
            <div className="mt-4">
              <Row>
                <Col span={4}>
                  <IconAlertTriangle size={48} className="text-red-500" />
                </Col>
                <Col span={20}>
                  {t("datagridtablefield:delete_confirm", {
                    name: foundField.name
                  })}
                </Col>
              </Row>
            </div>
          ),
          okButtonProps: { danger: true },
          cancelText: t("common:close"),
          onOk() {
            onDeleteField(foundField);
          },
          onCancel() {}
        });
      }
    }
  }, [menu, fields, t, onDeleteField, modal]);

  const onSaveTableFieldSuccess = useCallback(
    (item: DataGridTableFieldModel) => {
      // console.log("Saved field success", item);
      // detech this is NEW or UPDATE
      const foundIndex = fields.findIndex((field) => field.id === item.id);
      if (foundIndex >= 0) {
        //update current role item info
        setFields(
          sortBy(
            update(fields, {
              [foundIndex]: {
                $set: item
              }
            }),
            ["display_order"]
          )
        );
      } else {
        //append new item to list
        setFields(sortBy([...fields, item], ["display_order"]));
      }
    },
    [fields]
  );

  const onSubmitUpdateField = useCallback(
    async (
      formData: DataGridTableFieldModel
    ): Promise<DataGridTableFieldModel> => {
      let item: DataGridTableFieldModel =
        await new DataGridTableFieldRepository().saveRemote(formData.toJson());

      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="datagridtablefield:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4
        });
      }
      return item;
    },
    [t, message]
  );

  const onDeleteSelectedRow = useCallback(async () => {
    if (selectedRowList.length > 0 && typeof dataSource !== "undefined") {
      setDeleting(true);

      const deletePromises: Promise<string[]>[] = [];
      selectedRowList.forEach((rowNo) => {
        if (typeof dataSource[rowNo] !== "undefined") {
          deletePromises.push(
            new DataGridTableRowRepository().deleteItem(dataSource[rowNo].id)
          );
        }
      });

      //wait for all promise done
      const promiseErrors: string[] = [];
      const successRemoveRows: number[] = [];
      await Promise.all(deletePromises).then((result) => {
        result.map((err, i) => {
          if (err.length > 0) {
            promiseErrors.push(
              "Error while delete line #" +
                (selectedRowList[i] + 1) +
                " (Row ID=" +
                dataSource[selectedRowList[i]].id +
                ")"
            );
          } else {
            successRemoveRows.push(selectedRowList[i]);
          }

          return null;
        });
      });
      setDeleting(false);

      //clear selection
      setSelection(undefined);
      setSelectRowList([]);

      if (promiseErrors.length > 0) {
        alert(promiseErrors.join("\n"));
      }

      if (successRemoveRows.length > 0) {
        setDataSource(
          dataSource.filter((item, index) => !successRemoveRows.includes(index))
        );
      }
    }
  }, [selectedRowList, dataSource]);

  const updateRowDisplayOrderAfterRowMove = useCallback(
    (
      updatedItem: DataGridTableRowModel,
      originalItem: DataGridTableRowModel,
      originalRowFrom: number
    ) => {
      onSubmitUpdateRow(updatedItem)
        .then(() => {})
        .catch((e) => {
          console.log("Update row display order fail");

          //rollback datasource with originalItem
          //Todo:
        });
    },
    [onSubmitUpdateRow]
  );

  const onRowMoved = useCallback(
    (rowFrom: number, rowTo: number) => {
      if (typeof dataSource !== "undefined") {
        //save for rollback if update froms erver fail
        const originalItem = dataSource[rowFrom];

        //Tim 1 cap item, se chua sourceItem, dua vao rowTo
        const destinationItemStart = dataSource[rowTo].display_order;
        const destinationItemEnd =
          rowFrom < rowTo
            ? rowTo === dataSource.length - 1
              ? dataSource[rowTo].display_order + 1
              : dataSource[rowTo + 1].display_order
            : rowTo === 0
            ? dataSource[rowTo].display_order / 2
            : dataSource[rowTo - 1].display_order;

        const newDisplayOrder = (destinationItemStart + destinationItemEnd) / 2;

        //update new model with new displayorder
        const updatedSourceItem = new DataGridTableRowModel(
          produce(dataSource[rowFrom].toJson(), (draft) => {
            draft.display_order = newDisplayOrder;
          })
        );

        //update datasource (change order for display)
        setDataSource((data) => {
          if (typeof data !== "undefined") {
            const d = [...data];

            //get the drag item out
            const removed = d.splice(rowFrom, 1);
            //and update new displayorder
            removed[0].display_order = newDisplayOrder;

            //insert drag item to new position in list
            d.splice(rowTo, 0, ...removed);

            //return new list
            return d;
          }
          return [];
        });

        //udpate server
        updateRowDisplayOrderAfterRowMove(
          updatedSourceItem,
          originalItem,
          rowFrom
        );
      }
    },

    [dataSource, updateRowDisplayOrderAfterRowMove]
  );

  /**
   * Detect update multiple rows select
   */
  const onGridSelectionChange = useCallback(
    (newSel: GridSelection) => {
      //detect selectRow on marker
      // console.log("select", newSel);

      if (typeof newSel.current === "undefined") {
        let newRows = CompactSelection.empty();

        const currentRowIndex = newSel.rows.first();
        let newRowList: number[] = [];

        if (typeof currentRowIndex === "number") {
          //check if rowindex existed (de-select)
          if (selectedRowList.includes(currentRowIndex)) {
            newRowList = selectedRowList.filter((r) => r !== currentRowIndex);
          } else {
            newRowList = [...selectedRowList, currentRowIndex];
          }

          //build new list and update to UI
          for (var i = 0; i < newRowList.length; i++) {
            newRows = newRows.add(newRowList[i]);
          }
        }

        setSelection({ ...newSel, rows: newRows });
        setSelectRowList(newRowList);
      } else {
        setSelection(newSel);
        setSelectRowList([]);
      }
    },
    [selectedRowList]
  );

  const onColumnResize = useCallback(
    (column: GridColumn, newSize: number) => {
      const selectedField = fields.find((f) => "f_" + f.id === column.id);
      if (typeof selectedField !== "undefined") {
        const newFields = fields.map((f) => {
          if ("f_" + f.id === column.id) {
            return new DataGridTableFieldModel({
              ...f.toJson(),
              width: newSize
            });
          } else {
            return f;
          }
        });

        //clear existed timeout
        if (saveRemoteFieldWidthTimeoutRef.current) {
          clearTimeout(saveRemoteFieldWidthTimeoutRef.current);
        }
        //settimeout for update (to prevent update to fast)
        saveRemoteFieldWidthTimeoutRef.current = setTimeout(() => {
          //optimistic save
          new DataGridTableFieldRepository().saveRemote({
            ...selectedField.toJson(),
            width: newSize
          });
        }, 300);

        setFields(newFields);
      }
    },
    [fields]
  );

  const updateColDisplayOrderAfterColumnMove = useCallback(
    (
      updatedItem: DataGridTableFieldModel,
      originalItem: DataGridTableFieldModel,
      originalRowFrom: number
    ) => {
      onSubmitUpdateField(updatedItem)
        .then(() => {})
        .catch((e) => {
          console.log("Update column display order fail");

          //rollback fields with originalItem
          //Todo:
        });
    },
    [onSubmitUpdateField]
  );

  const onColumnMoved = useCallback(
    (colFrom: number, colTo: number) => {
      console.log("colmove");
      if (typeof fields !== "undefined") {
        //save for rollback if update froms erver fail
        const originalItem = fields[colFrom];

        //Tim 1 cap item, se chua sourceItem, dua vao colTo
        const destinationItemStart = fields[colTo].display_order;
        const destinationItemEnd =
          colFrom < colTo
            ? colTo === fields.length - 1
              ? fields[colTo].display_order + 1
              : fields[colTo + 1].display_order
            : colTo === 0
            ? fields[colTo].display_order / 2
            : fields[colTo - 1].display_order;

        const newDisplayOrder = (destinationItemStart + destinationItemEnd) / 2;

        //update new model with new displayorder
        const updatedSourceItem = new DataGridTableFieldModel(
          produce(fields[colFrom].toJson(), (draft) => {
            draft.display_order = newDisplayOrder;
          })
        );

        //update fields (change order for display)
        setFields((data) => {
          if (typeof data !== "undefined") {
            const d = [...data];

            //get the drag item out
            const removed = d.splice(colFrom, 1);
            //and update new displayorder
            removed[0].display_order = newDisplayOrder;

            //insert drag item to new position in list
            d.splice(colTo, 0, ...removed);

            //return new list
            return d;
          }
          return [];
        });

        //udpate server
        updateColDisplayOrderAfterColumnMove(
          updatedSourceItem,
          originalItem,
          colFrom
        );
      }
    },

    [fields, updateColDisplayOrderAfterColumnMove]
  );

  useEffect(() => {
    if (typeof dataSource === "undefined" && errors.length === 0) {
      fetchData();
    }
  }, [dataSource, fetchData, errors]);

  //use for render extracells
  const cellProps = useExtraCells();

  const headerIcons = useMemo(() => {
    return DataGridTableFieldModel.getHeaderIcons();
  }, []);

  return (
    <>
      <LayoutTitle title={t("datagrid:heading_general")} />
      <PageSiteMenu siteMenuSelectedKey={""} />
      <DataGridHeader height={50}>
        <Row>
          <Col flex={"auto"}>
            <div className="inline-block pt-2 pl-2 mr-2 text-xl font-semibold">
              <Link
                to={"/etable/view/workspace/" + model.datagrid_workspace_id}
                title={t("datagrid:backto_workspace")}>
                <IconTable
                  size={32}
                  className="mr-1 -mt-1 text-green-500 hover:text-black"
                />
              </Link>
              {model.name}

              <DataGridTableAction
                isDeleting={isDeleting}
                onClickEditTable={onClickEditTable}
                onClickDeleteTable={onClickDeleteTable}
              />
            </div>

            {selectedRowList.length > 0 ? (
              <div className="inline-block">
                <Popconfirm
                  title={t("datagridtable:confirm_delete_row_title")}
                  placement="topRight"
                  onConfirm={() => {
                    onDeleteSelectedRow();
                  }}
                  okText={t("common:table.confirm_ok")}
                  cancelText={t("common:table.confirm_cancel")}
                  disabled={deleting}>
                  <Button type="default" danger size="small">
                    <IconTrash className="mr-1 -mt-0.5" size={18} />{" "}
                    {t("datagridtable:delete_row_button")}
                  </Button>
                </Popconfirm>
              </div>
            ) : null}
          </Col>
          <Col flex={"200px"} className="text-right"></Col>
        </Row>
      </DataGridHeader>

      <DataEditor
        {...cellProps}
        getCellContent={getContent}
        rows={dataSource?.length || 0}
        width="100%"
        height={height - 60 - 50}
        columns={columns}
        onCellEdited={onCellEdited}
        onRowAppended={onRowAppended}
        rightElement={
          <DataGridEditorHeaderFieldAddButton
            onClick={() => {
              //default set displayorder to zero, to use the ceil(max displayorder + 1)
              setNewTableFieldFieldDisplayOrder(0);

              //show popup
              setEditTableFieldVisible(true);
              setEditingTableFieldId(0);
            }}
          />
        }
        rightElementProps={{
          fill: false,
          sticky: false
        }}
        rowMarkers="both"
        trailingRowOptions={{
          sticky: true,
          tint: true,
          hint: t("datagridtable:add_row")
        }}
        headerIcons={headerIcons}
        onHeaderMenuClick={onHeaderMenuClick}
        onCellContextMenu={(_, e) => e.preventDefault()}
        onRowMoved={onRowMoved}
        onColumnResize={onColumnResize}
        onColumnMoved={onColumnMoved}
        gridSelection={selection}
        onGridSelectionChange={onGridSelectionChange}
        theme={{
          baseFontStyle: "0.8125rem"
        }}
      />

      {isOpen &&
        renderLayer(
          <div {...layerProps}>
            <DataGridEditorHeaderMenu
              onClickEditField={onClickEditField}
              onClickDeleteField={onClickDeleteField}
              onClickAddField={onClickAddField}
            />
          </div>
        )}

      <DataGridTableFieldFormModal
        id={editingTableFieldId}
        newDisplayOrder={newTableFieldDisplayOrder}
        key={editingTableFieldId}
        open={editTableFieldVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingTableFieldId(0);
          }
          setEditTableFieldVisible(isOpen);
        }}
        onSaveSuccess={onSaveTableFieldSuccess}
        tableId={model.id}
      />
    </>
  );
};

export default DataGridContainer;
