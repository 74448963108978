import { App, Button, Popconfirm, Spin } from "antd";
import Ecom from "common/constants/Ecom";
import EcomModel from "common/models/EcomModel";
import EcomPlatformWebhookRepository from "common/repositories/EcomPlatformWebhookRepository";
import LayoutError from "components/LayoutError";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconChecks } from "@tabler/icons-react";

const EcomItemWebhook = ({
  record,
  onSaveSuccess
}: {
  record: EcomModel;
  onSaveSuccess: (o: EcomModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [processing, setProcessing] = useState(false);
  const messageKeyDeletePrefix = "webhook_item_";

  const doRegisterWebhook = useCallback(async () => {
    setProcessing(true);
    const newItem = await new EcomPlatformWebhookRepository().registerWebhook(
      record.id
    );
    if (newItem.hasError()) {
      message.error({
        content: (
          <LayoutError
            onClickClose={() => {
              message.destroy(messageKeyDeletePrefix + record.id);
            }}
            heading={t("ecom:form.error.update")}
            translate_prefix={"ecom:form.error"}
            items={newItem.error.errors}
          />
        ),
        className: "message_error",
        key: messageKeyDeletePrefix + record.id,
        duration: 10
      });
    } else {
      onSaveSuccess(newItem);
    }
    setProcessing(false);
  }, [record.id, t, onSaveSuccess, message]);

  const doUnRegisterWebhook = useCallback(async () => {
    setProcessing(true);
    const newItem = await new EcomPlatformWebhookRepository().unregisterWebhook(
      record.id
    );
    if (newItem.hasError()) {
      message.error({
        content: (
          <LayoutError
            onClickClose={() => {
              message.destroy(messageKeyDeletePrefix + record.id);
            }}
            heading={t("ecom:form.error.update")}
            translate_prefix={"ecom:form.error"}
            items={newItem.error.errors}
          />
        ),
        className: "message_error",
        key: messageKeyDeletePrefix + record.id,
        duration: 10
      });
    } else {
      onSaveSuccess(newItem);
    }
    setProcessing(false);
  }, [record.id, onSaveSuccess, messageKeyDeletePrefix, t, message]);

  return (
    <div className="space-align-block">
      {processing ? (
        <>
          <Spin size="small" />{" "}
          {record.date_webhook_registered > 0
            ? t("ecom:webhook_unregistering")
            : t("ecom:webhook_registering")}
        </>
      ) : (
        <>
          {record.date_webhook_registered > 0 ? (
            <>
              <Button
                type="text"
                size="small"
                icon={<IconChecks color="green" size={18} className="mr-1" />}>
                {t("ecom:webhook_already")}
              </Button>
              <Popconfirm
                title={t("ecom:webhook_unregister_tooltip")}
                placement="topRight"
                onConfirm={() => {
                  doUnRegisterWebhook();
                }}
                okText={t("common:table.confirm_ok")}
                cancelText={t("common:table.confirm_cancel")}
                disabled={
                  record.type === Ecom.TYPE_WOO_COMMERCE ? true : false
                }>
                <Button
                  size="small"
                  danger
                  disabled={
                    record.type === Ecom.TYPE_WOO_COMMERCE ? true : false
                  }>
                  {t("ecom:webhook_unregister")}
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Button type="primary" ghost onClick={(e) => doRegisterWebhook()}>
              {t("ecom:webhook_register")}
            </Button>
          )}
        </>
      )}

      <Link to={`/ecomlog/index/channel/${record.id}`}>
        <Button type="link" size="small">
          {t("ecom:log_list")}
        </Button>
      </Link>
    </div>
  );
};

export default EcomItemWebhook;
