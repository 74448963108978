import WorkTrackingModel from "common/models/WorkTrackingModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import React from "react";
import { useTranslation } from "react-i18next";

const WorkTrackingApprovalListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
  children,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={["status"]}>
      {children}
      <FormSelect
        name="status"
        options={WorkTrackingModel.getStatusList()}
        allowClear
        placeholder={t("common:status")}
        popupMatchSelectWidth={false}
      />
    </PageDataFilterForm>
  );
};

export default WorkTrackingApprovalListFilter;
