import DmsBannerCollection from "common/collections/DmsBannerCollection";
import DmsBannerModel from "common/models/DmsBannerModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterDmsBanner,
  DmsBannerJsonAddEdit,
} from "common/types/DmsBanner";

const SERVICE_URL = "/dmsbanners";

class DmsBannerApi extends BaseApi {
  async getList(
    props: GetListProps<FilterDmsBanner>,
  ): Promise<DmsBannerCollection> {
    let collection = new DmsBannerCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<DmsBannerModel> {
    let item = new DmsBannerModel(DmsBannerModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new DmsBannerModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: DmsBannerJsonAddEdit): Promise<DmsBannerModel> {
    let item = new DmsBannerModel(DmsBannerModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new DmsBannerModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: DmsBannerJsonAddEdit): Promise<DmsBannerModel> {
    let item = new DmsBannerModel(DmsBannerModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new DmsBannerModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default DmsBannerApi;
