import { Button, Col, Input, Row, Tooltip } from "antd";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import File from "common/constants/File";
import Role from "common/constants/Role";
import FileModel from "common/models/FileModel";
import FileListBreadcrumb from "components/file/list/FileListBreadcrumb";
import FileListDirectoryAdd from "components/file/list/FileListDirectoryAdd";
import FileUploaderPopover from "components/file/uploader/FileUploaderPopover";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
  IconLayoutGrid,
  IconLayoutList,
  IconSearch,
} from "@tabler/icons-react";

import type { ViewType, FileRoute, FilterFile } from "common/types/File";
const FileListToolbar = ({
  objectType,
  origin,
  filters,
  setFilters,
  keyword,
  setKeyword,
  routes,
  setRoutes,
  viewType,
  setViewType,
  allowExtensions,
  onUploadCompleted,
  onDeleteCompleted,
  children,
}: {
  objectType: number;
  origin: string;
  filters: FilterFile;
  setFilters: (v: FilterFile) => void;
  keyword: string;
  setKeyword: (k: string) => void;
  routes: FileRoute[];
  setRoutes: (r: FileRoute[]) => void;
  viewType: ViewType;
  setViewType: (v: ViewType) => void;
  allowExtensions: string[];
  onUploadCompleted: (f: FileModel) => void;
  onDeleteCompleted: (f: FileModel) => void;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const hasRole = useLoginAccountStore((state) => state.hasRole);

  const onClickBreadcrumb = useCallback(
    (route: ItemType) => {
      if (route.path === "") {
        setFilters({ ...filters, directory_id: 0 });
      } else {
        if (route.path) {
          setFilters({ ...filters, directory_id: parseInt(route.path) });
        }
      }

      //update routes
      const pos = routes.findIndex((r) => r.path === route.path);
      if (typeof pos !== "undefined") {
        setRoutes(routes.slice(0, pos + 1));
      }
    },
    [routes, setRoutes, setFilters, filters],
  );

  const isExplorable =
    objectType === File.OBJECTTYPE_FILE || objectType === File.OBJECTTYPE_CMS;

  const isUploadable =
    (objectType === File.OBJECTTYPE_FILE && hasRole(Role.FILE_UPLOAD)) ||
    objectType === File.OBJECTTYPE_CMS;

  return (
    <div
      style={{
        background: "#eee",
        padding: "10px 12px",
        marginBottom: 10,
        borderRadius: 6,
      }}>
      <Row gutter={8}>
        <Col flex="auto">
          <div className="float-right pr-2">{children}</div>
          <FileListBreadcrumb routes={routes} onClick={onClickBreadcrumb} />
        </Col>

        {isExplorable ? (
          <>
            <Col flex="90px" key="uploader">
              <FileUploaderPopover
                directoryId={+filters.directory_id}
                objectType={objectType}
                origin={origin}
                onUploadCompleted={onUploadCompleted}
                onDeleteCompleted={onDeleteCompleted}
                allowExtensions={allowExtensions}
                disabled={!isUploadable}
              />
            </Col>
            {isUploadable ? (
              <Col flex="110px" key="adddirectory">
                <FileListDirectoryAdd
                  directoryId={+filters.directory_id}
                  objectType={objectType}
                  origin={origin}
                  onUploadCompleted={onUploadCompleted}
                />
              </Col>
            ) : null}

            <Col flex="120px" key="search">
              <Input
                className="file-manager-search-input"
                size="small"
                allowClear
                value={keyword}
                suffix={<IconSearch size="12" color="#ccc" />}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Col>
          </>
        ) : null}

        <Col flex="25px">
          {viewType === "list" ? (
            <Tooltip title={t("file:grid_view")} mouseEnterDelay={0.4}>
              <Button
                onClick={(e) => setViewType("grid")}
                size="small"
                type="text"
                icon={<IconLayoutGrid size="14" />}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t("file:list_view")} mouseEnterDelay={0.4}>
              <Button
                onClick={(e) => setViewType("list")}
                size="small"
                type="text"
                icon={<IconLayoutList size="14" />}
              />
            </Tooltip>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FileListToolbar;
