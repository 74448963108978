import BaseCollection from 'common/collections/BaseCollection';
import WarehouseModel from 'common/models/WarehouseModel';
import { WarehouseCollectionJson, WarehouseJson } from 'common/types/Warehouse';

class WarehouseCollection extends BaseCollection<
  WarehouseJson,
  WarehouseModel,
  WarehouseCollectionJson
> {
  itemsFromJson(jsonItems: WarehouseJson[]): WarehouseModel[] {
    return jsonItems.map((item) => new WarehouseModel(item));
  }
}

export default WarehouseCollection;
