import usePathParams from "hooks/usePathParams";
import { useCallback, useEffect, useState } from "react";

import LeaveModel from "common/models/LeaveModel";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveRepository from "common/repositories/LeaveRepository";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";

import LeaveEmployeeDetail from "features/leave/detail/LeaveEmployeeDetail";
import LeaveEmployeeDetailHeader from "features/leave/detail/LeaveEmployeeDetailHeader";
import LeaveForm from "features/leave/form/LeaveForm";
import LeaveFormHeader from "features/leave/form/LeaveFormHeader";
import LeaveList from "features/leave/list/LeaveList";

import FormEditFetcher from "components/form/FormEditFetcher";

const LeavePage = () => {
  let com = <></>;

  const [pathParams] = usePathParams();
  const [model, setModel] = useState<LeaveModel>(
    new LeaveModel(LeaveModel.getDefaultData())
  );
  const [leaveTypes, setLeaveTypes] = useState<LeaveTypeModel[]>([]);
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  /////////////////////////////////
  const fetchLeaveTypes = useCallback(async () => {
    const collection = await new LeaveTypeRepository().getItems();
    setLeaveTypes(collection);
    return collection;
  }, []);

  useEffect(() => {
    setModel(new LeaveModel(LeaveModel.getDefaultData()));
    setFetchErrors([]);
    fetchLeaveTypes();
  }, [pathParams.action, fetchLeaveTypes]);
  /////////////////////////////////

  switch (pathParams.action) {
    case "add":
      com = (
        <LeaveForm
          model={new LeaveModel(LeaveModel.getDefaultData())}
          leaveTypes={leaveTypes}
        />
      );
      break;

    case "edit":
      com =
        model.id > 0 ? (
          <LeaveForm model={model} leaveTypes={leaveTypes} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new LeaveRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<LeaveFormHeader isEditing={true} />}
          />
        );

      break;

    case "detail":
      com =
        model.id > 0 ? (
          <LeaveEmployeeDetail model={model} leaveTypes={leaveTypes} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let currentDetail = await new LeaveRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (currentDetail.hasError()) {
                setFetchErrors(currentDetail.error.errors);
              } else {
                setModel(currentDetail);
              }
            }}
            errors={fetchErrors}
            heading={<LeaveEmployeeDetailHeader isEditing={true} />}
          />
        );
      break;

    default:
      com = <LeaveList listLeaveType={leaveTypes} />;
      break;
  }
  return com;
};

export default LeavePage;
