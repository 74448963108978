import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import NestedCollection from "common/collections/NestedCollection";
import { TableColumnsType } from "common/types/Table";
import useBreakpoint from "hooks/useBreakpoint";
import { RowClassName } from "rc-table/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconFolder, IconFolderMinus, IconFolders } from "@tabler/icons-react";

const PageDataTableWithChildren = <M extends { children?: any[] }>({
  columns,
  dataSource,
  fetchData,
  rowClassName,
}: {
  columns: TableColumnsType<M>;
  dataSource: M[];
  fetchData: () => Promise<NestedCollection<M>>;
  rowClassName?: RowClassName<M>;
}) => {
  const { t } = useTranslation();
  const [isSmall] = useBreakpoint();

  //listing data
  const [loading, setLoading] = useState(true);

  //fetch data when change fetchData() (trigger by change filters from parent ^^!)
  useEffect(() => {
    (async () => {
      setLoading(true);

      //call parent fetchData()
      await fetchData();

      setLoading(false);
    })();
  }, [fetchData]);

  //refine columns for using in <Table..>
  // - remove "sortby" key because not use for <Table..>
  // - add "dataIndex" from "key"
  // - add related sorter keys (from input sortby props): sorter, sortDirections, sortOrder
  // - add "filterMultiple" to false when enable "filters"
  const processedColumns: ColumnsType<M> = columns.map((column) => {
    return {
      ...column,
      dataIndex: Number(column.dataIndex) || Number(column.key),
    };
  });

  return (
    <div className="p-6">
      <Table<M>
        rowKey="id"
        key={loading ? "loading" : "loading-done"}
        loading={loading}
        defaultExpandAllRows={true}
        indentSize={48}
        size="small"
        sticky={{ offsetHeader: isSmall ? -2 : 59 }}
        scroll={{ x: 800 }}
        pagination={{
          pageSize: 1000,
          hideOnSinglePage: true,
        }}
        rowClassName={rowClassName}
        columns={processedColumns}
        dataSource={dataSource}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            return (
              <>
                <Button
                  size="small"
                  type="text"
                  className="mr-1"
                  onClick={(e) => onExpand(record, e)}
                  icon={
                    typeof record.children !== "undefined" &&
                    record.children.length > 0 ? (
                      <span
                        title={t("common:toggle_children")}
                        className="hover:opacity-50"
                      >
                        {expanded ? (
                          <IconFolderMinus
                            size={24}
                            className="-mt-2 text-yellow-500"
                          />
                        ) : (
                          <IconFolders
                            size={24}
                            className="-mt-2 text-yellow-500"
                          />
                        )}
                      </span>
                    ) : (
                      <IconFolder size={24} className="-mt-2 text-gray-300" />
                    )
                  }
                />
              </>
            );
          },
        }}
      />
    </div>
  );
};

export default PageDataTableWithChildren;
