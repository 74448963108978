import BaseCollection from "common/collections/BaseCollection";
import LeaveNoteModel from "common/models/LeaveNoteModel";
import { LeaveNoteCollectionJson, LeaveNoteJson } from "common/types/LeaveNote";

class LeaveNoteCollection extends BaseCollection<
  LeaveNoteJson,
  LeaveNoteModel,
  LeaveNoteCollectionJson
> {
  itemsFromJson(jsonItems: LeaveNoteJson[]): LeaveNoteModel[] {
    return jsonItems.map((item) => new LeaveNoteModel(item));
  }
}

export default LeaveNoteCollection;
