import Order from "common/constants/Order";
import OrderModel from "common/models/OrderModel";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import { IconPlugConnected } from "@tabler/icons-react";

const OrderFormSectionEcomPlatform = () => {
  const { t } = useTranslation();
  const order = useEditingSaleOrderStore((state) => state.order);

  if (
    typeof order.ecom_platform_type === "undefined" ||
    order.ecom_platform_type === Order.ECOM_PLATFORM_TYPE_POS ||
    order.ecom_platform_type === Order.ECOM_PLATFORM_TYPE_API
  ) {
    return null;
  }

  const typeInfo = OrderModel.getEcomPlatformType(order.ecom_platform_type);

  return (
    <div className="p-4 border-t border-gray-100 bg-gray-50 text-gray-600 text-xs">
      <IconPlugConnected className="mr-1 -mt-0.5 text-blue-400" />{" "}
      {t("order:ecomplatform_section_info", { ecom_platform: typeInfo?.label })}
      &nbsp; (Ecom Invoice ID: <strong>
        {order.ecom_platform_invoice_id}
      </strong>{" "}
      , Ecom Order ID: <strong>{order.ecom_platform_order_id}</strong>)
    </div>
  );
};

export default OrderFormSectionEcomPlatform;
