import PurchaseOrderDetailCollection from "common/collections/PurchaseOrderDetailCollection";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/purchaseorderdetails";

class PurchaseOrderDetailApi extends BaseApi {
  async getListOfPurchaseOrder(
    order_id: number
  ): Promise<PurchaseOrderDetailCollection> {
    let collection = new PurchaseOrderDetailCollection();

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/order/" + order_id
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getFullDetailOfPurchaseOrder(
    purchaseOrderId: number
  ): Promise<PurchaseOrderDetailCollection> {
    let collection = new PurchaseOrderDetailCollection();

    try {
      let queryData = {
        params: {},
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/purchaseorder/" + purchaseOrderId,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default PurchaseOrderDetailApi;
