import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React from "react";

const ReportInventoryInputVender = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col flex={1}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo nhập hàng theo nhà thương hiệu"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              keyMapping={[
                {
                  title: "Thương hiệu",
                  name: "vendor",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "vendor"
                },
                {
                  title: "Tổng số sản phẩm",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(quantity)"
                },
                {
                  title: "Phiếu nhập",
                  name: "countdistinct_receipt",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "countdistinct(receipt)"
                }
              ]}
              dataSelect="sum(quantity), vendor, countdistinct(receipt)"
              dataFilter="productreceipt_direction = 3,productreceipt_status = 9"
              dataGroupBy="vendor"
              dataOrderBy="sum(quantity) DESC"
              dataJoin="productreceipt"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex={1}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo nhập hàng theo nhà cung cấp và kho"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              keyMapping={[
                {
                  title: "Nhà kho",
                  name: "productreceipt_warehouse",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "productreceipt_warehouse"
                },
                {
                  title: "Thương hiệu",
                  name: "vendor",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "vendor"
                },
                {
                  title: "Tổng số sản phẩm",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(quantity)"
                },
                {
                  title: "Phiếu nhập",
                  name: "countdistinct_receipt",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "countdistinct(receipt)"
                }
              ]}
              dataSelect="sum(quantity), vendor, productreceipt_warehouse, countdistinct(receipt)"
              dataFilter="productreceipt_direction = 3,productreceipt_status = 9"
              dataGroupBy="productreceipt_warehouse, vendor"
              dataOrderBy="sum(quantity) DESC"
              dataJoin="productreceipt"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportInventoryInputVender;
