import PurchaseOrderHelperApi from "common/api/PurchaseOrderHelperApi";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import PurchaseOrderCostSummaryModel from "common/models/PurchaseOrderCostSummaryModel";
import {
  PurchaseOrderInitCashflowReceiptRequest,
  PurchaseOrderInitProductReceiptRequest,
} from "common/types/PurchaseOrder";

import BaseRepository from "./BaseRepository";

class PurchaseOrderHelperRepository extends BaseRepository<PurchaseOrderHelperApi> {
  _api: PurchaseOrderHelperApi | null;

  constructor() {
    super();
    this._api = new PurchaseOrderHelperApi(true);
  }

  async getCostSummary(
    productVariantId: number,
    supplierId: number
  ): Promise<PurchaseOrderCostSummaryModel> {
    return this._api
      ? await this._api.getCostSummary(productVariantId, supplierId)
      : new PurchaseOrderCostSummaryModel(
          PurchaseOrderCostSummaryModel.getDefaultData()
        );
  }

  async initProductReceipt(
    data: PurchaseOrderInitProductReceiptRequest
  ): Promise<ProductReceiptModel> {
    if (this._api) {
      return await this._api.initProductReceipt(data);
    } else {
      return new ProductReceiptModel(ProductReceiptModel.getDefaultData());
    }
  }

  async initCashflowReceipt(
    data: PurchaseOrderInitCashflowReceiptRequest
  ): Promise<CashflowReceiptModel> {
    if (this._api) {
      return await this._api.initCashflowReceipt(data);
    } else {
      return new CashflowReceiptModel(CashflowReceiptModel.getDefaultData());
    }
  }

  async linkProductReceipt(
    purchase_order_id: number,
    product_receipt_id: string
  ): Promise<string[]> {
    return this._api
      ? await this._api.linkProductReceipt(
          purchase_order_id,
          product_receipt_id
        )
      : [];
  }

  async linkCashflowReceipt(
    purchase_order_id: number,
    cashflow_receipt_id: string
  ): Promise<string[]> {
    return this._api
      ? await this._api.linkCashflowReceipt(
          purchase_order_id,
          cashflow_receipt_id
        )
      : [];
  }
}

export default PurchaseOrderHelperRepository;
