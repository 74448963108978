import { App, Form, Input } from "antd";
import { RefModalSubmit } from "common/interfaces/ModalSubmit";
import RbacRoleModel from "common/models/RbacRoleModel";
import RbacRoleRepository from "common/repositories/RbacRoleRepository";
import Error from "components/LayoutError";
import SelectEmployee from "features/employee/SelectEmployee";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import type { Ref } from "react";
const RbacRoleEditorForm = forwardRef(
  (
    {
      id,
      inputRef,
      model,
      setOpen,
      setProcessing,
      onSaveSuccess,
      defaultUserIdList,
    }: {
      id: number;
      inputRef: Ref<any>;
      model: RbacRoleModel;
      setOpen: (v: boolean) => any;
      setProcessing: (p: boolean) => any;
      onSaveSuccess: (r: RbacRoleModel) => void;
      defaultUserIdList: number[];
    },
    ref: Ref<RefModalSubmit>
  ) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [name, setName] = useState(model.name);
    const [selectedEmployees, setSelectedEmployees] =
      useState(defaultUserIdList);

    useImperativeHandle(ref, () => ({
      doSubmit() {
        doSubmitProcess();
      },
      resetForm() {
        setName("");
        setSelectedEmployees([]);
      },
    }));

    const doSubmitProcess = useCallback(async () => {
      setProcessing(true);
      message.loading({
        content: t("common:form.processing"),
        key: "message",
        duration: 0,
      });

      const item = await new RbacRoleRepository().saveRemote({
        id: id,
        name,
        description: "",
        user_ids: selectedEmployees.join(","),
      });
      setProcessing(false);
      if (item.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="role:form.error"
              items={item.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4,
        });
      } else {
        message.success({
          content: t("common:form.success.save"),
          className: "message_success",
          key: "message",
          duration: 2,
        });
        setName("");
        setSelectedEmployees([]);
        onSaveSuccess(item);
        setOpen(false);
      }
    }, [
      selectedEmployees,
      id,
      name,
      onSaveSuccess,
      setProcessing,
      setOpen,
      t,
      message,
    ]);

    const handleChangeEmployee = (values: number[]) => {
      setSelectedEmployees(values);
    };

    return (
      <Form layout="vertical">
        <Form.Item label={t("role:name")}>
          <Input
            ref={inputRef}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("role:users")}>
          <SelectEmployee
            placeholder={t("common:form.select_employees")}
            multiple={true}
            onChange={handleChangeEmployee}
            values={selectedEmployees}
          />
        </Form.Item>
      </Form>
    );
  }
);

export default RbacRoleEditorForm;
