import RbacRoleApi from 'common/api/RbacRoleApi';
import RbacRoleCollection from 'common/collections/RbacRoleCollection';
import RbacRoleModel from 'common/models/RbacRoleModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  RbacRoleJsonAddEdit,
  FilterRbacRole,
  RbacRoleJsonEditPermission,
} from "common/types/RbacRole";

class RbacRoleRepository extends BaseRepository<RbacRoleApi> {
  _api: RbacRoleApi | null;

  constructor() {
    super();
    this._api = new RbacRoleApi(true);
  }

  static getDefaultFilters(): FilterRbacRole {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterRbacRole>) {
    return this._api
      ? await this._api.getList(props)
      : new RbacRoleCollection();
  }

  async getItem(id: number): Promise<RbacRoleModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new RbacRoleModel(RbacRoleModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: RbacRoleJsonAddEdit): Promise<RbacRoleModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new RbacRoleModel(RbacRoleModel.getDefaultData());
    }
  }

  async savePermission(
    data: RbacRoleJsonEditPermission
  ): Promise<RbacRoleModel> {
    if (this._api) {
      return await this._api.editPermission(data);
    } else {
      return new RbacRoleModel(RbacRoleModel.getDefaultData());
    }
  }
}

export default RbacRoleRepository;
