// import { GeoLocation } from "common/types/Gps";
// import { useEffect, useState } from "react";

const useConvertBase64ToFile = () => {
  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const convertBast64ToFile = (imageBase64: string, fileName: string) => {
    if (imageBase64) {
      var file = dataURLtoFile(`${imageBase64}`, fileName);

      // const base64Response = await fetch(imageBase64);
      // const blob = await base64Response.blob();
      return file;
    }
  };
  return {
    convertBast64ToFile,
  };
};
export default useConvertBase64ToFile;
