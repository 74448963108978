import { App, Input } from "antd";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import ErrorAlert from "components/ErrorAlert";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

const PurchaseOrderFormSectionProductReceiptLinkForm = ({
  onSuccess
}: {
  onSuccess: () => void;
}) => {
  const { message } = App.useApp();
  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );

  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);

  const doLinking = useCallback(
    async (receipt_id: string) => {
      setProcessing(true);
      setErrors([]);
      const error: string[] =
        await new PurchaseOrderHelperRepository().linkProductReceipt(
          purchaseOrder.id,
          receipt_id
        );
      setProcessing(false);
      if (error.length > 0) {
        setErrors(error);
      } else {
        //success
        message.success(t("purchaseorder:form.link_receipt.success"));
        onSuccess();
      }
    },
    [setProcessing, purchaseOrder.id, onSuccess, t, message]
  );

  return (
    <>
      <Input.Search
        placeholder={t("purchaseorder:form.link_receipt.product_receipt_id")}
        addonBefore="#"
        onSearch={(value) => (value.length > 0 ? doLinking(value) : {})}
        loading={processing}
        enterButton={t("purchaseorder:form.link_receipt.submit")}
        allowClear
      />
      {errors.length > 0 && (
        <>
          <ErrorAlert
            items={errors}
            translate_prefix="purchaseorder:form.link_receipt.error"
            className="p-2 mt-2"
          />
        </>
      )}
    </>
  );
};

export default PurchaseOrderFormSectionProductReceiptLinkForm;
