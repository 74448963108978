import { Affix, ColorPicker, Divider, Tooltip } from "antd";
import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IconArrowBackUp,
  IconArrowForwardUp,
  IconBlockquote,
  IconBold,
  IconClearFormatting,
  IconCode,
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconHighlight,
  IconItalic,
  IconList,
  IconListNumbers,
  IconPilcrow,
  IconSeparator,
  IconSourceCode,
  IconSquareCheck,
  IconStrikethrough,
  IconTextWrap,
} from "@tabler/icons-react";

import HtmlEditorMenuBarButton from "./HtmlEditorMenuBarButton";
import HtmlEditorMenuBarButtonImagePicker from "./HtmlEditorMenuBarButtonImagePicker";
import HtmlEditorMenuBarButtonLink from "./HtmlEditorMenuBarButtonLink";
import HtmlEditorMenuBarButtonTable from "./HtmlEditorMenuBarButtonTable";
import HtmlEditorMenuBarButtonTextAlign from "./HtmlEditorMenuBarButtonTextAlign";

const HtmlEditorMenuBar = ({
  editor,
  selectionEmpty,
  popupVisible,
  setPopupVisible,
}: {
  editor: HtmlEditor;
  selectionEmpty: boolean;
  popupVisible: boolean;
  setPopupVisible: (open: boolean) => void;
}) => {
  const { t } = useTranslation("htmleditor");
  const [isAffix, setIsAffix] = useState(false);

  if (!editor) {
    return null;
  }

  const onChangeAffix = (affix: any) => {
    setIsAffix(affix as boolean);
  };

  return (
    <Affix offsetTop={60} onChange={onChangeAffix}>
      <div className={"he-menu" + (isAffix ? " he-menu-affix" : "")}>
        <HtmlEditorMenuBarButton
          icon={<IconBold />}
          title={t("bold")}
          active={editor.isActive("bold")}
          onClick={() => editor.chain().focus().toggleBold().run()}
        />
        <HtmlEditorMenuBarButton
          icon={<IconItalic />}
          title={t("italic")}
          active={editor.isActive("italic")}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
        <HtmlEditorMenuBarButton
          icon={<IconStrikethrough />}
          title={t("strikethrough")}
          active={editor.isActive("strike")}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        />
        <span className="inline-block align-middle mr-1">
          <ColorPicker
            className=""
            value={editor.getAttributes("textStyle").color || "#000000"}
            onChange={(color) =>
              editor
                .chain()
                .focus()
                .setColor("#" + color.toHex())
                .run()
            }
          >
            <Tooltip mouseEnterDelay={0.4} title={t("text_color")}>
              <span
                className="border font-extrabold rounded-sm border-gray-200 hover:border-gray-600 text-center inline-block cursor-pointer"
                style={{
                  color: editor.getAttributes("textStyle").color || "#000000",
                  width: 24,
                  height: 24,
                }}
              >
                A
              </span>
            </Tooltip>
          </ColorPicker>
        </span>
        <HtmlEditorMenuBarButton
          icon={<IconHighlight />}
          title={t("highlight")}
          active={editor.isActive("highlight")}
          onClick={() => editor.chain().focus().toggleHighlight().run()}
        />

        <HtmlEditorMenuBarButton
          icon={<IconCode />}
          title={t("code")}
          active={editor.isActive("code")}
          onClick={() => editor.chain().focus().toggleCode().run()}
        />

        <Divider type="vertical" />

        <HtmlEditorMenuBarButton
          icon={<IconPilcrow />}
          title={t("paragraph")}
          active={editor.isActive("paragraph")}
          onClick={() => editor.chain().focus().setParagraph().run()}
        />

        <HtmlEditorMenuBarButton
          icon={<IconH1 />}
          title={t("heading_1")}
          active={editor.isActive("heading", { level: 1 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        />
        <HtmlEditorMenuBarButton
          icon={<IconH2 />}
          title={t("heading_2")}
          active={editor.isActive("heading", { level: 2 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        />
        <HtmlEditorMenuBarButton
          icon={<IconH3 />}
          title={t("heading_3")}
          active={editor.isActive("heading", { level: 3 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        />
        {/* <HtmlEditorMenuBarButton
          icon={<IconH4 />}
          title={t("heading_4")}
          active={editor.isActive("heading", { level: 4 })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
        /> */}

        <Divider type="vertical" />

        <HtmlEditorMenuBarButtonTextAlign editor={editor} />

        <HtmlEditorMenuBarButton
          icon={<IconList />}
          title={t("bullet_list")}
          active={editor.isActive("bulletList")}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />

        <HtmlEditorMenuBarButton
          icon={<IconListNumbers />}
          title={t("ordered_list")}
          active={editor.isActive("orderedList")}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />

        <HtmlEditorMenuBarButton
          icon={<IconBlockquote />}
          title={t("blockquote")}
          active={editor.isActive("blockquote")}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
        />
        <HtmlEditorMenuBarButton
          icon={<IconSourceCode />}
          title={t("codeblock")}
          active={editor.isActive("codeBlock")}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        />
        <HtmlEditorMenuBarButton
          active={false}
          icon={<IconSeparator />}
          title={t("horizontal_rule")}
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        />
        <HtmlEditorMenuBarButton
          active={false}
          icon={<IconTextWrap />}
          title={t("hard_break")}
          onClick={() => editor.chain().focus().setHardBreak().run()}
        />

        <HtmlEditorMenuBarButton
          active={false}
          icon={<IconClearFormatting />}
          title={t("clear_formatting")}
          onClick={() =>
            editor.chain().focus().clearNodes().unsetAllMarks().run()
          }
        />

        <Divider type="vertical" />
        <HtmlEditorMenuBarButton
          icon={<IconSquareCheck />}
          title={t("todo_list")}
          active={editor.isActive("tasklist")}
          onClick={() => editor.chain().focus().toggleTaskList().run()}
        />

        <HtmlEditorMenuBarButtonTable editor={editor} />

        <HtmlEditorMenuBarButtonLink
          editor={editor}
          selectionEmpty={selectionEmpty}
          setPopupVisible={setPopupVisible}
        />
        <HtmlEditorMenuBarButtonImagePicker
          editor={editor}
          selectionEmpty={selectionEmpty}
          setPopupVisible={setPopupVisible}
        />
        <Divider type="vertical" />
        <HtmlEditorMenuBarButton
          active={false}
          icon={<IconArrowBackUp />}
          title={t("undo")}
          onClick={() => editor.chain().focus().undo().run()}
        />
        <HtmlEditorMenuBarButton
          active={false}
          icon={<IconArrowForwardUp />}
          title={t("redo")}
          onClick={() => editor.chain().focus().redo().run()}
        />
      </div>
    </Affix>
  );
};

export default HtmlEditorMenuBar;
