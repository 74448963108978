import ShipperRepository from 'common/repositories/ShipperRepository';
import { SelectOption } from 'common/types/SelectOption';
import FormSelect from 'components/form/FormSelect';
import React, { useCallback, useEffect, useState } from 'react';

const ShipperFormSelect = ({
  label,
  name,
}: {
  label: string;
  name: string;
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new ShipperRepository().getItems({
      filters: ShipperRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.full_name,
        }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  return (
    <FormSelect
      loading={loading}
      showSearch
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
    />
  );
};

export default ShipperFormSelect;
