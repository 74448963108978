import { Tooltip } from "antd";
import ReportUtil from "common/utils/report";
import TextMoney from "components/TextMoney";
import TextNumber from "components/TextNumber";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";

const ReportChartNumberChange = ({
  from,
  to,
  valueType,
}: {
  from: number;
  to: number;
  valueType?: "number" | "money";
}) => {
  const { t } = useTranslation();

  const change = ReportUtil.calculateChange(from, to);
  let changeclass = "";
  if (from < to) {
    changeclass = "report_change_up";
  } else if (from > to) {
    changeclass = "report_change_down";
  }

  return (
    <Tooltip
      mouseEnterDelay={0.4}
      title={
        <>
          {typeof valueType !== "undefined" && valueType === "money" ? (
            <span>
              <TextMoney money={to} /> {t("report:tooltip.vs")}{" "}
              <TextMoney money={from} />
            </span>
          ) : (
            <span>
              <TextNumber number={to} /> {t("report:tooltip.vs")}{" "}
              <TextNumber number={from} />
            </span>
          )}
        </>
      }
    >
      <span className={"report_change " + changeclass}>
        {from < to ? <IconArrowUp size="12" /> : null}
        {from > to ? <IconArrowDown size="12" /> : null}
        {change}%
      </span>
    </Tooltip>
  );
};

export default ReportChartNumberChange;
