import { Form, Input } from "antd";
import ProductInventoryChecking from "common/constants/ProductInventoryChecking";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingRepository from "common/repositories/ProductInventoryCheckingRepository";
import LayoutForm from "components/form/LayoutForm";
import ProductInventoryCheckingMergeFormHeader from "features/productinventorycheckingmerge/form/ProductInventoryCheckingMergeFormHeader";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductInventoryCheckingMergeList from "../list/ProductInventoryCheckingMergeList";

import type { ProductInventoryMerge } from "common/types/ProductInventoryChecking";
const ProductInventoryCheckingMergeForm = ({
  model,
}: {
  model: ProductInventoryCheckingModel;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const selectedWarehouseId = Form.useWatch("warehouse_id", form);

  const isEditing = model.id > 0;

  const [checkingIds, setCheckingIds] = useState<number[]>([]);

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = {
    note: model.note,
    warehouse_id: model.id > 0 ? model.warehouse_id : null,
    status:
      model.status > 0 ? model.status : ProductInventoryChecking.STATUS_PENDING,
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProductInventoryMerge = {
        warehouse_id: formData.warehouse_id,
        note: formData.note,
        id_list: checkingIds.join(","),
      };

      return submitData;
    },
    [checkingIds]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setIsSuccess(false);

    doPrepareData(formData);
    let myObj: ProductInventoryCheckingModel =
      await new ProductInventoryCheckingRepository().merge(
        doPrepareData(formData)
      );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  let sidebarItems = (
    <>
      <Form.Item label={t("productinventorychecking:note")} name="note">
        <Input.TextArea rows={3} />
      </Form.Item>
    </>
  );

  return (
    <>
      <ProductInventoryCheckingMergeFormHeader />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("productinventorychecking:form.success.update")
            : t("productinventorychecking:form.success.add")
        }
        redirectOnSuccess="/productinventorychecking"
        error_translate_prefix="productinventorychecking:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={<>{sidebarItems}</>}
      >
        <ProductInventoryCheckingMergeList
          form={form}
          warehouse_id={selectedWarehouseId}
          setCheckingIds={(ids: number[]) => setCheckingIds(ids)}
        />
      </LayoutForm>
    </>
  );
};

export default ProductInventoryCheckingMergeForm;
