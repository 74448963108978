// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagInfor_wrapper__HiIQ\\+ {
  position: relative;
  font-size: var(--tag-fontsize);
  color: var(--tag-color);
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.TagInfor_wrapper__HiIQ\\+ span {
  gap: 5px;
}
@media screen and (max-width: 768px) {
  .TagInfor_wrapper__HiIQ\\+ span {
    gap: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyCard/components/TagInfor/TagInfor.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,uBAAuB;EACvB,oBAAoB;EACpB,8BAA8B;EAC9B,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,QAAQ;AACV;AACA;EACE;IACE,QAAQ;EACV;AACF","sourcesContent":[".wrapper {\n  position: relative;\n  font-size: var(--tag-fontsize);\n  color: var(--tag-color);\n  display: inline-flex;\n  justify-content: space-between;\n  width: 100%;\n  align-items: center;\n}\n.wrapper span {\n  gap: 5px;\n}\n@media screen and (max-width: 768px) {\n  .wrapper span {\n    gap: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TagInfor_wrapper__HiIQ+`
};
export default ___CSS_LOADER_EXPORT___;
