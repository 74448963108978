import { Button, Table, Tag } from "antd";
import { FeeShiping } from "common/types/ShippingCarrier";
import { TableColumnsType } from "common/types/Table";
import MoneyInput from "components/MoneyInput";
import TextRegion from "components/TextRegion";
import { useTranslation } from "react-i18next";

const ShippingCarrierFeeList = ({
  dataSource,
  loading,
  onUpdateItem,
  onDelete,
}: {
  dataSource: FeeShiping[];
  loading: boolean;
  onUpdateItem: (item: FeeShiping) => void;
  onDelete: (region_id: number) => void;
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<FeeShiping> = [
    {
      title: t("common:sid"),
      dataIndex: "region_id",
      width: 80,
      align: "center",
    },
    {
      title: t("shippingcarrier:detail.region_id"),
      dataIndex: "region_id",
      render: (region_id: number) => {
        return <TextRegion id={region_id} />;
      },
    },
    {
      title: " ",
      dataIndex: "fee",
      align: "right",
      width: 100,
      render: (fee: number) => {
        return fee > 0 ? null : <Tag color="gold">Free Ship</Tag>;
      },
    },
    {
      title: t("shippingcarrier:detail.fee"),
      dataIndex: "fee",
      width: 120,
      render: (_: any, record: FeeShiping) => {
        return (
          <MoneyInput
            size="small"
            onPressEnter={(e: any) =>
              onUpdateItem({
                region_id: record.region_id,
                fee: Number(e.target.value.replace(/\$\s?|(,*)/g, "")),
              })
            }
            onBlur={(e: any) =>
              onUpdateItem({
                region_id: record.region_id,
                fee: Number(e.target.value.replace(/\$\s?|(,*)/g, "")),
              })
            }
            className="text-center w-full"
            value={record.fee}
          />
        );
      },
    },
    {
      title: " ",
      dataIndex: "operation",
      width: 30,
      align: "center",
      render: (_: any, record: FeeShiping) => {
        return (
          <Button
            type="link"
            onClick={() => onDelete(record.region_id)}
            size="small"
            className="text-red-500"
          >
            {t("common:table.delete")}
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        size="small"
        dataSource={dataSource}
        columns={columns}
        rowKey={"region_id"}
        loading={loading}
        pagination={{ hideOnSinglePage: true, defaultPageSize: 20 }}
        footer={() => null}
      />
    </div>
  );
};

export default ShippingCarrierFeeList;
