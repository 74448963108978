import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductInventoryCheckingFormMergeHeader = () => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("productinventorychecking:heading_nav"),
      link: "/productinventorychecking",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t("productinventorychecking:heading_merge")}
      siteMenuSelectedKey="inventory"
      siteMenuHidden
    ></PageHeader>
  );
};

export default ProductInventoryCheckingFormMergeHeader;
