import ShippingRouteList from 'features/shippingroute/list/ShippingRouteList';
import usePathParams from 'hooks/usePathParams';
import React from 'react';

const ShippingRoutePage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <ShippingRouteList />;
  }

  return com;
};

export default ShippingRoutePage;
