import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProjectFormEditorHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("project:heading_nav"),
      link: "/project",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(isEditing ? "project:heading_edit" : "project:heading_add")}
      siteMenuSelectedKey="/project"
      siteMenuHidden
    ></PageHeader>
  );
};

export default ProjectFormEditorHeader;
