import ProductVariantSizeList from 'features/productvariantsize/list/ProductVariantSizeList';
import usePathParams from 'hooks/usePathParams';
import React from 'react';

const ProductVariantSizePage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <ProductVariantSizeList />;
  }

  return com;
};

export default ProductVariantSizePage;
