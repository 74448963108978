import { ProductCollectionJson } from "common/types/ProductCollection";
import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import FileModel from "./FileModel";
import { FileJson } from "common/types/File";
import ProductColelctionConstant from "common/constants/ProductCollection";

const defaultDataProductCollection: ProductCollectionJson = {
  id: 0,
  item_ids: [],
  images: [],
  banner_mobile: "",
  banner_desktop: "",
  title: "",
  handle: "",
  publish_from: 0,
  publish_to: 0,
  pin: 0,
  status: 0,
  tags: [],
  date_created: 0,
  date_modified: 0,
  description: "",
  detail: "",
  date_published: 0,
  parent_id: 0,
  allow_promotion: 0,
  type: 0,
  dynamic: "",
};

class ProductCollectionModel
  extends BaseModel
  implements BaseModelJson<ProductCollectionJson>
{
  id: number = defaultDataProductCollection.id;
  item_ids: number[] = defaultDataProductCollection.item_ids;
  images: {url:string}[] = defaultDataProductCollection.images;
  banner_mobile: string = defaultDataProductCollection.banner_mobile;
  banner_desktop: string = defaultDataProductCollection.banner_desktop;
  title: string = defaultDataProductCollection.title;
  handle: string = defaultDataProductCollection.handle;
  publish_from: number = defaultDataProductCollection.publish_from;
  publish_to: number = defaultDataProductCollection.publish_to;
  pin: number = defaultDataProductCollection.pin;
  status: number = defaultDataProductCollection.status;
  tags: any[] = defaultDataProductCollection.tags;
  date_created: number = defaultDataProductCollection.date_created;
  date_modified: number = defaultDataProductCollection.date_modified;
  description: string = defaultDataProductCollection.description;
  detail: string = defaultDataProductCollection.detail;
  date_published: number = defaultDataProductCollection.date_published;
  parent_id: number = defaultDataProductCollection.parent_id;
  allow_promotion: number = defaultDataProductCollection.allow_promotion;
  type: number = defaultDataProductCollection.type;
  dynamic: string = defaultDataProductCollection.dynamic;

  constructor(json: ProductCollectionJson) {
    super();
    Object.assign(this, { ...defaultDataProductCollection, ...json });
  }
  static getDefaultData(): ProductCollectionJson {
    return { ...defaultDataProductCollection };
  }
  toJson(): ProductCollectionJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getPinSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Ghim",
      },
      {
        value: 0,
        label: "Không ghim",
      },
    ];
  }

  static getStatusSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Hiện",
      },
      {
        value: 0,
        label: "Ẩn",
      },
    ];
  }

  static getTypeSelectList(): SelectOption[] {
    return [
      {
        value: ProductColelctionConstant.TYPE.DYNAMIC,
        label: "Dynamic",
      },
      {
        value: ProductColelctionConstant.TYPE.MANUAL,
        label: "Manual",
      },
    ];
  }
}

export default ProductCollectionModel;
