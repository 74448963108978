import { PosCartOrderDetail } from 'common/types/PosCart';
import TextMoney from 'components/TextMoney';

const PosCartItemSubtotal = ({ item }: { item: PosCartOrderDetail }) => {
  return (
    <span style={{ color: "white" }}>
      <TextMoney money={item.price * item.item_quantity} />
    </span>
  );
};

export default PosCartItemSubtotal;
