import WarehouseModel from 'common/models/WarehouseModel';
import WarehouseRepository from 'common/repositories/WarehouseRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import WarehouseForm from 'features/warehouse/form/WarehouseForm';
import WarehouseFormHeader from 'features/warehouse/form/WarehouseFormHeader';
import WarehouseList from 'features/warehouse/list/WarehouseList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const WarehousePage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<WarehouseModel>(
    new WarehouseModel(WarehouseModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new WarehouseModel(WarehouseModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <WarehouseForm
          model={new WarehouseModel(WarehouseModel.getDefaultData())}
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <WarehouseForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new WarehouseRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<WarehouseFormHeader isEditing={true} />}
          />
        );

      break;
    default:
      com = <WarehouseList />;
  }

  return com;
};

export default WarehousePage;
