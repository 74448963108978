import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {  Progress, TableColumnsType, Tag} from "antd";
import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import TableEdit from "components/table/TableEdit";
import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
//Phần thay thế
import { FilterTask, TaskJson } from "common/types/Task";

import TaskModel from "common/models/TaskModel";
import TaskCollection from "common/collections/TaskCollection";
import TaskRepository from "common/repositories/TaskRepository";

import TaskMineListHeader from "./TaskMineListHeader";
import TaskMineListFilter from "./TaskMineListFilter";
import TextDateTime from "components/TextDateTime";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";
import Task from "common/constants/Task";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import {
  CheckOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  RiseOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Dropdown, Space, message } from "antd";
import PageDataDragTable from "components/page/PageDataDragTable";
enum StatusUpdate {
  Pending = 1,
  Done = 3,
  Block = 4,
}

const TaskMineList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  ////////////////////////
  const { account } = useLoginAccountStore();

  ////////////////////////
  // default filter
  const defaultFilters = useMemo(() => {
    return TaskRepository.getDefaultFilter();
  }, []);

  ///////////////////////
  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<TaskModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);

  useFilterLocation(defaultFilters, filters);

  const onRow = (record: TaskJson, rowIndex: number) => {
    return {
      onClick: (e: any) => {}, // click row
    };
  };
  const { STATUS } = Task;
  async function updateStatusTask(id: number, statusUpdate: StatusUpdate) {
    try {
      const response = await new TaskRepository().editStatus(statusUpdate, id);
      if (response.hasError()) {
        response?.error?.errors?.forEach((err: string) => {
          let errMessage = t(`task:form.error.${err}`);
          message.open({
            type: "error",
            content: errMessage,
            duration: 2,
          });
        });

        return;
      }
      message.open({
        type: "success",
        content: t(`task:form.success.update`),
        duration: 2,
      });

      fetchData();
    } catch (error) {
      message.open({
        type: "error",
        content: <>{error}</>,
        duration: 2,
      });
    }
  }

  function renderAction(param: number) {
    switch (param) {
      case STATUS.DONE:
        return [];
      case STATUS.TODO:
        return [
          {
            label: "Inprogess",
            status: STATUS.INPROGRESS,
            icon: <RiseOutlined />,
          },
        ];
      case STATUS.PENDING:
        return [
          {
            label: "Inprogess",
            status: STATUS.INPROGRESS,
            icon: <RiseOutlined />,
          },
        ];
      case STATUS.INPROGRESS:
        return [
          {
            label: "Pending",
            status: STATUS.PENDING,
            icon: <WarningOutlined />,
          },
          {
            label: "Done",
            status: STATUS.DONE,
            icon: <CheckOutlined />,
          },
          {
            label: "Block",
            status: STATUS.BLOCK,
            icon: <StopOutlined />,
          },
        ];
      default:
        return [];
    }
  }

  //Table columns
  const columns: TableColumnsType<TaskModel> = [
    {
      title: t("task:id"),
      key: "id",

      dataIndex: "id",
      width: 60,
      render: (id) => {
        return <Tag color="blue">{id}</Tag>;
      },
      // defaultSortOrder: "ascend",
    },

    {
      title: t("task:name"),
      key: "name",
      dataIndex: "name",
      width: 160,
      render: (name, record: TaskModel) => {
        return (
          <p>
            {record.org_id > 0 && (
              <span style={{ color: "#ec5a00" }}>
                {"("}
                {record.org_id}
                {") "}
              </span>
            )}
            {name}
          </p>
        );
      },
      // defaultSortOrder: "ascend",
    },
    {
      title: t("task:type"),
      key: "type",
      dataIndex: "type",
      width: 80,
      // defaultSortOrder: "ascend",
      render: (type: number) => {
        const type_name = TaskModel.getTypeTask(type);
        const color =
          type_name === "Feature"
            ? "blue"
            : type_name === "Task"
            ? "green"
            : type_name === "Bug"
            ? "red"
            : "orange";
        return <Tag color={color}>{type_name}</Tag>;
      },
    },
    {
      title: t("task:org_fromtime"),
      key: "org_fromtime",
      dataIndex: "org_fromtime",
      width: 120,
      render: (org_fromtime, record: TaskModel) => (
        <div>
          <p>
            {org_fromtime > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={org_fromtime} />
            ) : (
              "----"
            )}
          </p>
          <p>
            {record.datestart > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={record.datestart} />
            ) : (
              "----"
            )}
          </p>
        </div>
      ),
    },

    {
      title: t("task:org_totime"),
      key: "org_totime",
      dataIndex: "org_totime",

      width: 120,
      render: (org_totime, record: TaskModel) => (
        <div>
          <p>
            {org_totime > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={org_totime} />
            ) : (
              "----"
            )}
          </p>
          <p>
            {record.datecompleted > 0 ? (
              <TextDateTime
                format="HH:mm, DD/MM/YYYY"
                ts={record.datecompleted}
              />
            ) : (
              "----"
            )}
          </p>
        </div>
      ),
    },

    {
      title: t("task:list.org_complete"),
      key: "org_cost",
      dataIndex: "org_cost",
      width: 100,
      render: (_, record) => {
        return <div>{record.org_cost} giờ</div>;
      },
    },
    {
      title: t("task:number_task_child"),
      key: "has_child",
      dataIndex: "has_child",
      width: 100,
      render: (_, record: TaskJson) => {
        return <>{record.has_child}</>;
      },
    },
    {
      title: t("task:list.deviation"),
      key: "deviation",
      dataIndex: "deviation",
      width: 150,
      render: (deviation, record: TaskModel) => {
        const day = Math.floor(Math.abs(deviation / (3600 * 24)));
        const houre = Math.floor(Math.abs(deviation / 3600) % 24);
        const minutes = Math.round(
          Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
        );
        const is_deviation = (day || houre || minutes) !== 0;
        return (
          <>
            {is_deviation ? (
              <Tag color={deviation > 0 ? "volcano" : "green"}>
                {day > 0 && day + " ngày "}
                {houre > 0 && houre + " giờ "}
                {minutes > 0 && minutes + " phút"}
                {is_deviation ? (
                  deviation > 0 ? (
                    <IconArrowDown style={{ color: "#cf1322" }} size={14} />
                  ) : (
                    <IconArrowUp style={{ color: "#3f8600" }} size={14} />
                  )
                ) : (
                  ""
                )}
              </Tag>
            ) : (
              "0"
            )}
          </>
        );
      },
    },
    {
      title: t("task:list.time_pending"),
      key: "time_pending",
      dataIndex: "time_pending",
      width: 150,
      render: (deviation, record: TaskModel) => {
        const day = Math.floor(Math.abs(deviation / (3600 * 24)));
        const houre = Math.floor(Math.abs(deviation / 3600) % 24);
        const minutes = Math.round(
          Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
        );
        const is_deviation = (day || houre || minutes) !== 0;
        return (
          <>
            {is_deviation ? (
              <Tag color={deviation > 0 ? "volcano" : "green"}>
                {day > 0 && day + " ngày "}
                {houre > 0 && houre + " giờ "}
                {minutes > 0 && minutes + " phút"}
                {/* {minutes} */}
              </Tag>
            ) : (
              "0"
            )}
          </>
        );
      },
    },
    {
      title: t("task:list.progress"),
      key: "complete",
      dataIndex: "complete",
      width: 100,
      render: (_, record: TaskModel) => {
        return (
          <div>
            <Progress
              percent={record.org_complete}
              status="active"
              strokeColor={{ from: "#108ee9", to: "#87d068" }}
            />
            <Progress
              percent={record.complete}
              status="active"
              strokeColor={{ from: "#f61a5e", to: "#ffcd00" }}
            />
          </div>
        );
      },
    },
    {
      title: t("task:status"),
      key: "status",
      dataIndex: "status",
      width: 100,
      render: (_, record: TaskJson) => {
        const colors = ["blue", "magenta", "green", "volcano"];
        let color = "cyan";
        if (colors[record.status]) {
          color = colors[record.status];
        }

        return <Tag color={color}>{TaskModel.getStatus(record.status)}</Tag>;
      },
    },
    {
      title: t(""),
      key: "actions",
      width: 50,
      align: "right",
      fixed: "right",
      render: (_: any, record: TaskModel) => {
        const menu = renderAction(record.status);
        const menuActions = menu.map((item, index) => {
          return {
            key: `${index + 3}`,
            label: (
              <Space direction="horizontal" align="center" size="small">
                {item.icon}
                <TableEdit
                  link={""}
                  label={item.label}
                  className=" text-black hover:text-black"
                  onClick={() => {
                    updateStatusTask(record.id, item.status);
                  }}
                />
              </Space>
            ),
          };
        });
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <Link to={`/task/add/id/${record.id}`}>

                      <Space direction="horizontal" align="center" size="small">
                        <PlusOutlined />
                        <TableEdit
                          link={`/task/add/id/${record.id}`}
                          label={t("task:add")}
                          className=" text-black hover:text-black"
                        />
                      </Space>
                      </Link>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <>
                        {record.status === Task.STATUS.TODO ? (
                          record.public === 1 ||
                          record.employee_id === account.company.employee.id ? (
                            <Link to={`/task/edit/id/${record.id}`}>
                              <Space
                                direction="horizontal"
                                align="center"
                                size="small"
                              >
                                <EditOutlined />
                                <TableEdit
                                  link={`/task/edit/id/${record.id}`}
                                  className=" text-black hover:text-black"
                                />
                              </Space>
                            </Link>
                          ) : null
                        ) : null}
                      </>
                    ),
                  },
                  ...menuActions,
                ],
              }}
              placement="bottom"
              trigger={["hover", "click"]}
            >
              <MoreOutlined
                style={{ fontSize: "16pt" }}
                className="cursor-pointer "
              />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  ///////////////////////
  //function
  const fetchData = useCallback(async (): Promise<TaskCollection> => {
    const response = await new TaskRepository().getItemsUser({
      filters: {
        ...filters,
        parent_id: -1,
        employee_id: account.id,
      },
    });
    setTotal(response.total);
    setDataSource(response.items);
    return response;
  }, [account.id, filters]);

  const handleSortChange = async ({
    old_index,
    new_index,
    id,
  }: {
    old_index: number;
    new_index: number;
    id: number;
  }) => {
    await new TaskRepository()
      .editDisplayOrder(id, new_index)
      .then((res) => {
        if (res.hasError()) {
          message.open({
            type: "error",
            content: "task:form.error.error_update_status",

            duration: 2,
          });
        } else {
          message.open({
            type: "success",
            content: t("task:form.success.update"),

            duration: 2,
          });
        }
      })
      .catch((err) => {
        message.open({
          type: "error",
          content: "task:form.error.error_update_status",

          duration: 2,
        });
      });
  };

  return (
    <div>
      <TaskMineListHeader></TaskMineListHeader>
      <TaskMineListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataDragTable<FilterTask, TaskModel, TaskCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          setDataSource,
          handleSortChange,
          onRow,
          expandable: {
            expandRowByClick: true,
            onExpand(expanded, record) {
              navigate(`/task/details?parent_id=${record.id}`);
            },

            expandIcon: ({ expanded, onExpand, record }) => {
              return null;
            },
          },
        }}
      />
    </div>
  );
};

export default TaskMineList;
