import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const OrderListHeader = () => {
	const { t } = useTranslation();

	const [params] = useSearchParams();
	const hasFilterInvoice = params.get("type");

	return (
		<PageHeader
			heading={
				!!hasFilterInvoice
					? t("order:invoice_heading_list")
					: t("order:heading_list")
			}
			nav={[]}
			siteMenuOpenKey="sale"
			siteMenuSelectedKey={hasFilterInvoice ? "/order?type=1" : "/order"}>
			<PageHeaderButton type="primary" link="/pos" target="_blank">
				{t("order:pos_button")}
			</PageHeaderButton>
		</PageHeader>
	);
};

export default OrderListHeader;
