import { Typography } from "antd";
import { useEffect, useState } from "react";

type Props = {
  prefix: string;
  value: string;
};

export default function FormItemHandleInput({
  prefix = "",
  value = "",
}: Props) {
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    setUrl(value);
  }, [value]);

  return (
    <>
      {/* {url.length > 0 && (
        <>
          URL được tạo:{" "}
          <Typography.Text className="text-red-500">{`${prefix}/${url}`}</Typography.Text>
        </>
      )} */}
    </>
  );
}
