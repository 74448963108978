import { TaskHistoryCollectionJson, TaskHistoryJson } from "common/types/Task";
import BaseCollection from "./BaseCollection";
import TaskHistoryModel from "common/models/TaskHistoryModel";

class TaskHistoryCollection extends BaseCollection<
  TaskHistoryJson,
  TaskHistoryModel,
  TaskHistoryCollectionJson
> {
  itemsFromJson(jsonItems: TaskHistoryJson[]): TaskHistoryModel[] {
    return jsonItems.map((item) => new TaskHistoryModel(item));
  }
}

export default TaskHistoryCollection;
