import BaseCollection from "common/collections/BaseCollection";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import {
  TaxInvoiceCollectionJson,
  TaxInvoiceJson,
} from "common/types/TaxInvoice";

class TaxInvoiceCollection extends BaseCollection<
  TaxInvoiceJson,
  TaxInvoiceModel,
  TaxInvoiceCollectionJson
> {
  itemsFromJson(jsonItems: TaxInvoiceJson[]): TaxInvoiceModel[] {
    return jsonItems.map((item) => new TaxInvoiceModel(item));
  }
}

export default TaxInvoiceCollection;
