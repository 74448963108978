import { Button, Col, Form, Input, Row } from "antd";
import FormSection from "components/form/FormSection";
import React from "react";
import UploadEvent from "./UploadEvent";
import { ZoneDataBrandList } from "common/types/Event";
import { FormInstance } from "antd/lib/form";

export default function SectionBrandList({
	data,
	form,
	index,
}: {
	data: ZoneDataBrandList[];
	form: FormInstance<any>;
	index: number;
}) {
	const relIndex = Math.ceil(index / 2) - 1;
	const name = `brand_list_${relIndex}`;
	return (
		<div>
			<FormSection
				title={`Thông tin list thương hiệu ${relIndex}`}
				subtitle={"Cài đặt ảnh và link thương hiệu"}
				leftColumnSize={6}
				divider>
				<Row gutter={16}>
					<Form.List name={name} initialValue={data}>
						{(fileds, { add, remove }) => (
							<>
								<Row gutter={[4, 4]}>
									{fileds.map((field, index) => {
										return (
											<Col span={6}>
												<Form.Item name={[field.name, "img"]}>
													<UploadEvent
														height={80}
														width={80}
														defaultUrl={data?.[index]?.img || ""}
														onSuccess={(url) => {
															const key = field.key;
															let data = form.getFieldValue(name);
															data[key] = { ...data[key], ["img"]: url };
															form.setFieldValue(name, data);
														}}></UploadEvent>
												</Form.Item>

												<Form.Item
													initialValue={data?.[index].url}
													name={[field.name, "url"]}>
													<Input></Input>
												</Form.Item>
												<Button
													onClick={() => {
														remove(index);
													}}>
													xóa
												</Button>
											</Col>
										);
									})}
								</Row>
								<Button
									onClick={() => {
										add();
									}}
									size="small">
									add
								</Button>
							</>
						)}
					</Form.List>
				</Row>
			</FormSection>
		</div>
	);
}
