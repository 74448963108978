import { Alert } from "antd";
import EcomChannelProductCollection from "common/collections/EcomChannelProductCollection";
import EcomChannelProductModel from "common/models/EcomChannelProductModel";
import EcomModel from "common/models/EcomModel";
import EcomChannelProductRepository from "common/repositories/EcomChannelProductRepository";
import { FilterEcomChannelProduct } from "common/types/EcomChannelProduct";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TextDateTime from "components/TextDateTime";
import EcomChannelProductListHeader from "features/ecomchannelproduct/list/EcomChannelProductListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import EcomSyncProductModel from "common/models/EcomSyncProductModel";
import EcomChannelProductVariant from "../item/EcomChannelProductVariant";
import EcomChannelProductListFilter from "./EcomChannelProductListFilter";

const EcomChannelProductList = ({
  channel_id,
  model,
}: {
  channel_id: number;
  model: EcomModel;
}) => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterEcomChannelProduct = useMemo(
    () => EcomChannelProductRepository.getDefaultFilters(),
    []
  );

  const [ecomProductCheckList, setEcomProductCheckList] = useState<
    EcomSyncProductModel[]
  >([]);

  const doEcomProductCheck = useCallback(
    async (channel_id: number, skuIdList: string[]) => {
      const checkItems =
        await new EcomChannelProductRepository().findLinkedVariant(
          channel_id,
          skuIdList
        );

      setEcomProductCheckList(checkItems.items);
    },
    []
  );

  const [filters, setFilters] =
    useStateFilter<FilterEcomChannelProduct>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<EcomChannelProductModel[]>([]);
  const fetchData =
    useCallback(async (): Promise<EcomChannelProductCollection> => {
      let collection = await new EcomChannelProductRepository().getItems(
        {
          filters,
        },
        channel_id
      );
      setDataSource(collection.items);
      setTotal(collection.total);

      let variants: string[] = [];
      collection.items.forEach((i) => {
        i.variants.forEach((variant) => {
          variants.push(variant.sku);
        });
      });

      doEcomProductCheck(channel_id, variants);

      return collection;
    }, [filters, channel_id, doEcomProductCheck]);

  //Table columns
  const columns: TableColumnsType<EcomChannelProductModel> = [
    {
      title: "ID",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: t("ecomchannelproduct:title"),
      key: "title",
      width: 300,
    },
    {
      title: t("ecomchannelproduct:vendor"),
      key: "vendor",
      width: 150,
    },
    {
      title: t("ecomchannelproduct:variants"),
      key: "variant",
      render: (t_: any, record: EcomChannelProductModel) => (
        <EcomChannelProductVariant
          channel_id={channel_id}
          ecom_type={model.type}
          product_id={+record.id}
          variants={record.variants}
          ecomProductCheckList={ecomProductCheckList}
          onResfesh={(action) => (action === true ? fetchData() : null)}
        />
      ),
    },
    {
      title: t("common:date_created"),
      key: "datecreated",
      width: 150,
      render: (ts: number) => (
        <TextDateTime format="HH:mm, DD/MM/YYYY" ts={ts} />
      ),
    },
  ];

  return (
    <>
      <EcomChannelProductListHeader channel_id={channel_id} model={model}>
        <></>
      </EcomChannelProductListHeader>
      <EcomChannelProductListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterEcomChannelProduct,
        EcomChannelProductModel,
        EcomChannelProductCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>
      <Alert
        type="info"
        className="m-6"
        description={t("ecomchannelproduct:product_help", {
          channel_title: model.title,
        })}
      />

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default EcomChannelProductList;
