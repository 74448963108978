import { Filter } from "common/types/Filter";
import { useTranslation } from "react-i18next";

import PageDataFilterFormExtraDivider from "./PageDataFilterFormExtraDivider";
import PageDataFilterFormExtraItem from "./PageDataFilterFormExtraItem";

const PageDataFilterFormExtra = <F extends Filter>({
  countFiltering,
  filters,
  setFilters,
  defaultFilters,
  total,
  extra,
  labelAll,
  children,
}: {
  countFiltering: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
  extra?: React.ReactNode | boolean;
  labelAll?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="px-6 pb-3 bg-gray-100">
        {countFiltering > 0 ? (
          <>
            <PageDataFilterFormExtraItem
              onClick={() => setFilters(defaultFilters)}
            >
              {labelAll || t("common:table.tab_all")}
            </PageDataFilterFormExtraItem>

            <PageDataFilterFormExtraDivider />

            <PageDataFilterFormExtraItem>
              {t("common:table.tab_search_result")} ({total})
            </PageDataFilterFormExtraItem>
          </>
        ) : (
          <PageDataFilterFormExtraItem>
            {labelAll || t("common:table.tab_all")} ({total})
          </PageDataFilterFormExtraItem>
        )}
        {typeof extra !== "undefined" ? (
          <>
            <PageDataFilterFormExtraDivider />
            {extra}
          </>
        ) : null}
        {children}
      </div>
    </>
  );
};

export default PageDataFilterFormExtra;
