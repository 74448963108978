import React from "react";
import EcomList from "features/ecom/list/EcomList";
import usePathParams from "hooks/usePathParams";

const EcomPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <EcomList />;
  }

  return com;
};

export default EcomPage;
