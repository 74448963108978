import { Form, Input } from "antd";
import ProjectTask from "common/constants/ProjectTask";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectIterationRepository from "common/repositories/ProjectIterationRepository";
import { EmployeeJson } from "common/types/Employee";
import { Filter } from "common/types/Filter";
import { ProjectTaskAssigneeOption } from "common/types/ProjectTask";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDidMount } from "rooks";
import useProjectTaskLayoutStore from "zustands/useProjectTaskLayoutStore";

const ProjectTaskFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
  extra,
  projectId,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
  extra?: React.ReactNode | boolean;
  projectId: number;
}) => {
  const { t } = useTranslation();
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");

  const [layoutActived] = useProjectTaskLayoutStore((state) => [
    state.layoutActived,
  ]);

  const defaultOptions: ProjectTaskAssigneeOption[] = useMemo(() => {
    return ProjectTaskModel.getDefaultAssignUser();
  }, []);
  const [assigneeUserItems, setAssigneeUserItems] = useState<
    ProjectTaskAssigneeOption[]
  >([]);

  const [projectIterationOption, setProjectIterationOption] = useState<
    SelectOption[]
  >([]);

  const fetchAssigneeUser = useCallback(async () => {
    let newOptions: ProjectTaskAssigneeOption[] = defaultOptions;

    if (employeeItems.length > 0) {
      employeeItems.map((i) => {
        return newOptions.push({
          value: i.user_id,
          label: i.full_name,
        });
      });
    }

    setAssigneeUserItems(newOptions);
  }, [employeeItems, defaultOptions]);

  useEffect(() => {
    fetchAssigneeUser();
  }, [fetchAssigneeUser]);

  const fetchProjectIteration = useCallback(async () => {
    let collection = await new ProjectIterationRepository().getItems({
      filters: {
        ...ProjectIterationRepository.getDefaultFilters(),
        project_id: projectId,
        limit: 50,
      },
    });

    if (!collection.hasError()) {
      setProjectIterationOption(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      );
    }
  }, [projectId]);

  useDidMount(() => {
    fetchProjectIteration();
  });

  return (
    <PageDataFilterForm
      total={total}
      extra={extra}
      filters={filters}
      setFilters={setFilters}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={["priority", "type", "status", "iteration_id"]}>
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("projecttask:filter_keyword_placeholder")}
        />
      </Form.Item>

      <Form.Item name="code">
        <Input allowClear placeholder={t("projecttask:code")} />
      </Form.Item>

      <FormSelect
        name="assignee_tpm"
        options={assigneeUserItems as SelectOption[]}
        allowClear
        placeholder={t("projecttask:assign_for")}
        popupMatchSelectWidth={false}
      />

      <FormSelect
        name="priority"
        options={ProjectTaskModel.getPriorityList()}
        allowClear
        placeholder={t("projecttask:priority")}
        popupMatchSelectWidth={false}
      />

      <FormSelect
        name="type"
        options={ProjectTaskModel.getTypeList()}
        allowClear
        placeholder={t("projecttask:type")}
        popupMatchSelectWidth={false}
      />

      {layoutActived === ProjectTask.LAYOUT_LIST ? (
        <FormSelect
          name="status"
          options={ProjectTaskModel.getStatusList()}
          allowClear
          placeholder={t("common:status")}
          popupMatchSelectWidth={false}
        />
      ) : null}

      <FormSelect
        name="iteration_id"
        options={projectIterationOption as SelectOption[]}
        allowClear
        placeholder={t("projecttask:iteration")}
        popupMatchSelectWidth={false}
      />
    </PageDataFilterForm>
  );
};

export default ProjectTaskFilter;
