import { App, Col, Form, Row } from "antd";
import ShippingOrder from "common/constants/ShippingOrder";
import FileModel from "common/models/FileModel";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingOrderRepository from "common/repositories/ShippingOrderRepository";
import { ShippingOrderJsonEdit } from "common/types/ShippingOrder";
import Helper from "common/utils/helper";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import dayjs from "dayjs";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import ShippingOrderFormSectionFrom from "./section/ShippingOrderFormSectionFrom";
import ShippingOrderFormSectionHow from "./section/ShippingOrderFormSectionHow";
import ShippingOrderFormSectionShipper from "./section/ShippingOrderFormSectionShipper";
import ShippingOrderFormSectionTo from "./section/ShippingOrderFormSectionTo";
import ShippingOrderFormSectionWhat from "./section/ShippingOrderFormSectionWhat";

const ShippingOrderForm = ({
  setProcessing,
  model,
  onSaveSuccess,
  setOpen
}: {
  setProcessing: (p: boolean) => any;
  model: ShippingOrderModel;
  onSaveSuccess: (o: ShippingOrderModel) => void;
  setOpen: (v: boolean) => any;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();
  const account = useLoginAccountStore((state) => state.account);

  const initialValues = useMemo(() => {
    return {
      /**
       * * WHAT GROUP
       */
      invoice_id: model.invoice_id,
      order_price_final: model.order_price_final,
      cod: model.cod,
      order_weight: model.order_weight,
      order_note: model.order_note,
      order_length: model.order_length,
      order_width: model.order_width,
      order_height: model.order_height,

      /**
       * * FROM GROUP
       */
      office_id: model.office_id > 0 ? model.office_id : null,
      random_code: model.random_code,

      /**
       * * TO GROUP
       */
      order_customer_fullname: model.order_customer_fullname,
      order_customer_phone: model.order_customer_phone,
      order_customer_email: model.order_customer_email,
      address: {
        address: model.order_shipping_address,
        lat: model.order_shipping_lat,
        long: model.order_shipping_long,
        map_place_id: model.order_shipping_map_place_id
      },
      order_shipping_address_detail: model.order_shipping_address_detail,
      regions: {
        region_id: model.order_shipping_region,
        sub_region_id: model.order_shipping_subregion,
        sub_sub_region_id: model.order_shipping_thirdregion
      },
      ymd:
        model.ymd > 0
          ? dayjs(
              dayjs(Helper.checkDateString(model.ymd), "YYYYMMDD").format(
                "DD/MM/YYYY"
              ),
              "DD/MM/YYYY"
            )
          : null,
      hour: [
        model.order_shipping_hourstart > 0
          ? dayjs(
              Helper.checkTimeString(model.order_shipping_hourstart),
              "HH:mm"
            )
          : null,
        model.order_shipping_hourend > 0
          ? dayjs(Helper.checkTimeString(model.order_shipping_hourend), "HH:mm")
          : null
      ],

      /**
       * * HOW GROUP
       */
      shipping_type: model.shipping_type > 0 ? model.shipping_type : null,
      status: model.status > 0 ? model.status : ShippingOrder.STATUS_NEW,
      shipper_note: model.shipper_note,
      graph_image_input: {
        files: FileModel.convertToUploadFiles(model.files)
      }
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ShippingOrderJsonEdit = {
        ...ShippingOrderModel.getDefaultData(),
        id: model.id,
        route_id: model.route_id,
        company_id: account.company.id,
        creator_id: account.id,

        /**
         * * WHAT GROUP
         */
        invoice_id: formData.invoice_id,
        order_price_final: formData.order_price_final,
        cod: formData.cod,
        order_weight: formData.order_weight,
        order_note: formData.order_note,
        order_length: formData.order_length,
        order_width: formData.order_width,
        order_height: formData.order_height,

        /**
         * * FROM GROUP
         */
        from_type: ShippingOrder.FROM_TYPE_OFFICE,
        office_id: formData.office_id,
        random_code: formData.random_code,

        /**
         * * TO GROUP
         */
        type: ShippingOrder.TYPE_CUSTOMER,
        order_customer_fullname: formData.order_customer_fullname,
        order_customer_phone: formData.order_customer_phone,
        order_shipping_address: formData.address.address,
        order_shipping_address_detail: formData.order_shipping_address_detail,
        order_shipping_lat: formData.address.lat,
        order_shipping_long: formData.address.long,
        order_shipping_map_place_id: formData.address.mmap_place_id,
        order_shipping_region: formData.regions.region_id,
        order_shipping_subregion: formData.regions.sub_region_id,
        order_shipping_thirdregion: formData.regions.sub_sub_region_id,
        ymd: +dayjs(formData.ymd).format("YYYYMMDD"),
        order_shipping_hourstart: +dayjs(formData.hour[0]).format("HHmm"),
        order_shipping_hourend: +dayjs(formData.hour[1]).format("HHmm"),

        /**
         * * HOW GROUP
         */
        shipping_type: formData.shipping_type,
        status: formData.status ?? ShippingOrder.STATUS_NEW,
        shipper_note: formData.shipper_note
      };

      if (model.id > 0) {
        submitData.file_id_list = formData.graph_image_input.files.map(
          (f: any) => (typeof f.response === "object" ? f.response?.id || 0 : 0)
        );
      }
      return submitData;
    },
    [model, account]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);

    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ShippingOrderModel =
      await new ShippingOrderRepository().saveRemote(doPrepareData(formData));

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="shippingorder:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={0}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="pr-4">
          <ShippingOrderFormSectionFrom model={model} />
          <ShippingOrderFormSectionTo form={form} />
          {model.id > 0 ? <ShippingOrderFormSectionShipper /> : null}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} className="">
          <div className="px-4 py-4 mb-4 bg-gray-100">
            <ShippingOrderFormSectionHow form={form} />
          </div>
          <ShippingOrderFormSectionWhat form={form} model={model} />
        </Col>
      </Row>
    </Form>
  );
};

export default ShippingOrderForm;
