import PosCartModel from 'common/models/PosCartModel';
import { PosCart, PosCartInsertProps } from 'common/types/PosCart';
import { StoreJson } from 'common/types/Store';
import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

type PosCartStore = {
  carts: PosCart[];
  activeCartId: string;
  activeStore?: StoreJson;
  getActiveCart: () => PosCart | undefined;
  updateActiveCart: (c: any) => void;
  setActiveCartId: (v: string) => void;
  setActiveStore: (v?: StoreJson, v2?: PosCartInsertProps) => void;
  insertNewCart: (v: PosCartInsertProps) => void;
  removeActiveCart: () => void;
};

//init store data
let store: StateCreator<PosCartStore> = (set, get) => ({
  carts: [],
  activeCartId: "",
  activeStore: undefined,
  getActiveCart: () => get().carts.find((c) => c._id === get().activeCartId),
  updateActiveCart: (changes: any) => {
    const activeCart = get().carts.find((c) => c._id === get().activeCartId);
    if (typeof activeCart !== "undefined") {
      //update cart item
      set((state) => ({
        ...state,
        carts: get().carts.map((item) =>
          item._id === activeCart._id ? { ...activeCart, ...changes } : item
        ),
      }));
    }
  },
  removeActiveCart: () => {
    const allcarts = get().carts;
    const currentCartCount = allcarts.length;
    const activeCartIndex = allcarts.findIndex(
      (c) => c._id === get().activeCartId
    );
    if (currentCartCount > 0 && activeCartIndex >= 0) {
      //save current select warehouse_id for inventory (for next create cart)
      const currentActiveWarehouseId = allcarts[activeCartIndex].warehouse_id;

      //stack only contains ONE cart
      if (allcarts.length === 1) {
        //copy and used for next insert cart
        const newEmptyCart = {
          ...PosCartModel.getDefaultCart(get().activeStore),
          company_id: allcarts[activeCartIndex].company_id,
          creator_id: allcarts[activeCartIndex].creator_id,
          warehouse_id: currentActiveWarehouseId,
        };

        //replace cart with ONLY ONE cart item
        set((state) => ({
          ...state,
          carts: [newEmptyCart],
          activeCartId: newEmptyCart._id,
        }));
      } else {
        let nextActiveCartIndex = -1;

        //find next active
        if (activeCartIndex < allcarts.length - 1) {
          nextActiveCartIndex = activeCartIndex + 1;
        } else if (activeCartIndex === allcarts.length - 1) {
          nextActiveCartIndex = activeCartIndex - 1;
        }

        set((state) => ({
          ...state,
          carts: allcarts.filter((i, index) => index !== activeCartIndex),
          activeCartId: allcarts[nextActiveCartIndex]._id,
        }));
      }
    } else {
      console.log("Can not found activeCart --> Weird");
    }
  },
  setActiveCartId: (activeCartId) =>
    set((state) => ({ ...state, activeCartId })),
  setActiveStore: (activeStore, props) => {
    //clear selected store
    if (typeof activeStore === "undefined") {
      set((state) => ({ ...state, activeStore, carts: [], activeCartId: "" }));
    } else {
      //select NEW store
      const newEmptyCart = {
        ...PosCartModel.getDefaultCart(activeStore),
        ...props,
      };
      set((state) => ({
        ...state,
        activeStore,
        carts: [newEmptyCart],
        activeCartId: newEmptyCart._id,
      }));
    }
  },
  insertNewCart: (props: PosCartInsertProps) => {
    //find current active warehouse
    const allcarts = get().carts;
    const currentCartCount = allcarts.length;
    const activeCartIndex = allcarts.findIndex(
      (c) => c._id === get().activeCartId
    );
    const currentActiveWarehouseId =
      currentCartCount > 0 && activeCartIndex >= 0
        ? allcarts[activeCartIndex].warehouse_id
        : 0;

    //prepare new empty cart
    const newEmptyCart = {
      ...PosCartModel.getDefaultCart(get().activeStore),
      ...props,
      ...(currentActiveWarehouseId > 0
        ? { warehouse_id: currentActiveWarehouseId }
        : {}),
    };

    set((state) => ({
      ...state,
      carts: [...state.carts, newEmptyCart],
      activeCartId: newEmptyCart._id,
    }));
  },
});

//create store
const usePosCartStore = create(persist(store, { name: "MAIN::PosCart" }));

export default usePosCartStore;
