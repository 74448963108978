import { App, Button, Form, Input } from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import Role from "common/constants/Role";
import OrderHelperRepository from "common/repositories/OrderHelperRepository";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";

import InventoryReceiptFormStatus from "./InventoryReceiptFormStatus";

import InventoryReceiptFormHeader from "./InventoryReceiptFormHeader";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import {
  InventoryReceiptDirection,
  InventoryReceiptJson,
  InventoryReceiptJsonAddEdit,
  InventoryReceiptSourceType,
  InventoryReceiptStatus,
  ProductInventoryReceiptJson,
} from "common/types/InventoryResceipt";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import WarehouseCollection from "common/collections/WarehouseCollection";
import WarehouseModel from "common/models/WarehouseModel";
import { Link, useNavigate } from "react-router-dom";
import InventoryReceiptProduct from "./components/InventoryReceiptProduct";
import Helper from "common/utils/helper";
import InventoryReceiptTarget from "./components/InventoryReceiptTarget";
import InventoryReceiptSource from "./components/InventoryReceiptSource";
import FormSection from "components/form/FormSection";
import { WarehouseJson } from "common/types/Warehouse";
import StoreModel from "common/models/StoreModel";
import StoreRepository from "common/repositories/StoreRepository";

const InventoryReceiptForm = ({
  model,
  need_link_sale_order_id,
  need_link_purchase_order_id,
  disable,
  refetch,
  setInven,
}: {
  model: InventoryReceiptModel;
  need_link_sale_order_id?: number;
  need_link_purchase_order_id?: number;
  disable?: boolean;
  refetch?: () => void;
  setInven?: (model: InventoryReceiptModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const typeReceipt = model.direction;
  const isIn = typeReceipt === ProductReceipt.DIRECTION_INPUT;

  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );
  const [warehouseItems, setWarehouseItems] = useState<WarehouseModel[]>([]);
  const [warehouseItem, setWarehouseItem] = useState<WarehouseJson>(
    WarehouseModel.getDefaultData()
  );

  const [status, setStatus] = useState(model.status);
  const [loading, setLoading] = useState(model.status);

  const navigate = useNavigate();
  const [storeId, setStoreId] = useState(model.warehouse_id);

  //check can edit update input info (add/edit features)
  const allowEditInput = useMemo(() => {
    if (disable) return false;
    return (
      (model.id === 0 ||
        hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, model.warehouse_id) ||
        hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, model.warehouse_id) ||
        hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, model.warehouse_id)) &&
      model.status < ProductReceipt.STATUS_COMPLETED
    );
  }, [disable, model.id, model.warehouse_id, model.status, hasRoleAndLimit]);

  //limit select warehouse option
  // const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
  //   "warehouse",
  //   Role.INVENTORY_ADD
  // );

  useEffect(() => {
    setStatus(model.status);
  }, [model]);

  const fetchDataWarehouse =
    useCallback(async (): Promise<WarehouseCollection> => {
      let collection = await new WarehouseRepository().getItems({
        filters: WarehouseRepository.getDefaultFilters(),
      });
      setWarehouseItems(collection.items);

      return collection;
    }, []);

  useEffect(() => {
    fetchDataWarehouse();
  }, [fetchDataWarehouse]);

  const isEditing = model.id > 0;

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  //fetch all ProductReceiptDetail
  const [detailItems, setDetailItems] = useState<ProductInventoryReceiptJson[]>(
    model.detail
  );

  const initialValues = useMemo(
    () => ({
      ...model,
      type:
        model.id > 0
          ? model.type
          : model.direction === ProductReceipt.DIRECTION_INPUT
          ? ProductReceipt.TYPE_BUYING
          : ProductReceipt.TYPE_SELLING_ONE,
      warehouse_id: model.id > 0 ? model.warehouse_id : 0,
      note: model.note,

      detail: model.detail,
      source: model.source.id
        ? model.source
        : {
            id: null,
            type: null,
          },
      target: model.target.id
        ? model.target
        : {
            id: null,
            type: null,
          },
    }),
    [model]
  );

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: InventoryReceiptJsonAddEdit = {
        id: model.id,
        direction: model.direction,
        status: status,
        warehouse_id: formData.warehouse_id,
        type: formData.type,
        note: formData.note,
        source_id: +formData.source.id || +model.source.id,
        source_type: formData.source.type || model.source.type,
        target_id: +formData.target.id || +model.target.id,
        target_type: formData.target.type || model.target.type,
        details: detailItems,
      };

      return submitData;
    },
    [
      JSON.stringify(model),
      model.id,
      model.direction,
      model.source.id,
      model.source.type,
      model.target.id,
      model.target.type,
      status,
      detailItems,
    ]
  );

  function createRollbackReceipt() {
    let body: InventoryReceiptJson = {
      ...InventoryReceiptModel.getDefaultData(),
      id: 0,
      direction: model.direction,
      status: InventoryReceiptStatus.WAIT_APPROVE,
      warehouse_id: model.warehouse_id,
      type: model.type,
      note: model.note,

      source: {
        id: model.source.id,
        type: model.source.type,
      },
      target: {
        id: model.target.id,
        type: model.target.type,
      },
      detail: detailItems,
    };
    if (model.direction === InventoryReceiptDirection.IN) {
      body = { ...body, direction: InventoryReceiptDirection.OUT };
    }

    if (model.direction === InventoryReceiptDirection.OUT) {
      body = { ...body, direction: InventoryReceiptDirection.IN };
    }
    const inven = new InventoryReceiptModel({ ...body });
    setInven?.(inven);
    navigate(`/inventoryreceipt/add/direction/${inven.direction}`);
  }

  const doLinkingSaleOrderAfterAdd = useCallback(
    async (receipt_id: number) => {
      if (typeof need_link_sale_order_id === "number") {
        message.loading({
          content: t("productreceipt:form.linking_with_sale_order", {
            id: need_link_sale_order_id,
          }),
          key: "message_linking",
        });

        const error: string[] =
          await new OrderHelperRepository().linkProductReceipt(
            need_link_sale_order_id,
            receipt_id.toString()
          );

        //detect error on Linking
        if (error.length > 0) {
          message.error({ content: error, key: "message_linking" });
        } else {
          message.destroy("message_linking");
        }

        //show success on Product receipt
        setIsSuccess(true);
      }
    },
    [need_link_sale_order_id, t, message]
  );

  const doLinkingPurchaseOrderAfterAdd = useCallback(
    async (receipt_id: number) => {
      if (typeof need_link_purchase_order_id === "number") {
        message.loading({
          content: t("productreceipt:form.linking_with_purchase_order", {
            id: need_link_purchase_order_id,
          }),
          key: "message_linking",
        });

        const error: string[] =
          await new PurchaseOrderHelperRepository().linkProductReceipt(
            need_link_purchase_order_id,
            receipt_id.toString()
          );

        //detect error on Linking
        if (error.length > 0) {
          message.error({ content: error, key: "message_linking" });
        } else {
          message.destroy("message_linking");
        }

        //show success on Product receipt
        setIsSuccess(true);
      }
    },
    [need_link_purchase_order_id, t, message]
  );
  //submit data to server
  const onSubmit = useCallback(
    async (formData: any) => {
      const dataDeatils = formData.detail as ProductInventoryReceiptJson[];
      const dataPrev = model.detail;
      const dataAdd = dataDeatils.filter(
        (newItem) => !dataPrev.some((prevItem) => prevItem.id === newItem.id)
      );
      const dataUpdate = dataDeatils.filter((newItem) =>
        dataPrev.some((prevItem) => prevItem.id === newItem.id)
      );
      const dataDetele = dataPrev.filter(
        (prevItem) => !dataDeatils.some((newItem) => newItem.id === prevItem.id)
      );

      // const dataAdd = dataDeatils.filter((i) =>
      //   InventoryReceiptModel.checkDetailItemHaveKeyValue(
      //     i,
      //     "typeAction",
      //     "add"
      //   )
      // );
      // const dataDetele = dataDeatils.filter((i) =>
      //   InventoryReceiptModel.checkDetailItemHaveKeyValue(
      //     i,
      //     "typeAction",
      //     "delete"
      //   )
      // );
      // const dataUpdate = Helper.distributeElements(
      //   dataDeatils,
      //   dataAdd,
      //   dataDetele
      // ).array3;

      if (isEditing) {
        try {
          if (dataAdd.length > 0) {
            const res = await new InventoryReceiptRepository().editDetail({
              details: dataAdd,
              action: "add",
              id: model.id,
            });

            if (res.hasError()) {
              setErrors(res.error.errors);
              throw Error(res.error.errors[0]);
            }
          }

          if (dataDetele.length > 0) {
            const res = await new InventoryReceiptRepository().editDetail({
              details: dataDetele,
              action: "delete",
              id: model.id,
            });
            if (res.hasError()) {
              setErrors(res.error.errors);
              throw Error(res.error.errors[0]);
            }
          }

          if (dataUpdate.length > 0) {
            const res = await new InventoryReceiptRepository().editDetail({
              details: dataUpdate,
              action: "update",
              id: model.id,
            });

            if (res.hasError()) {
              setErrors(res.error.errors);

              throw Error(res.error.errors[0]);
            }
          }

          const body = doPrepareData(formData);
          let myObj: InventoryReceiptModel =
            await new InventoryReceiptRepository().saveRemote(body);
          if (myObj.hasError()) {
            setErrors(myObj.error.errors);
          } else {
            refetch?.();
            setStatus(myObj.status);
            setIsSuccess(true);
          }
        } catch (error) {
        } finally {
        }
      } else {
        const body = doPrepareData(formData);
        let myObj: InventoryReceiptModel =
          await new InventoryReceiptRepository().saveRemote(body);
        if (myObj.hasError()) {
          setErrors(myObj.error.errors);
        } else {
          refetch?.();
          setStatus(myObj.status);
          setIsSuccess(true);
          if (dataAdd.length > 0) {
            const res = await new InventoryReceiptRepository().editDetail({
              details: dataAdd,
              action: "add",
              id: model.id,
            });

            if (res.hasError()) {
              setErrors(res.error.errors);
              throw Error(res.error.errors[0]);
            }
          }
        }
      }
    },
    [
      JSON.stringify(model),
      doPrepareData,
      need_link_sale_order_id,
      need_link_purchase_order_id,
      doLinkingSaleOrderAfterAdd,
      doLinkingPurchaseOrderAfterAdd,
      refetch,
      isEditing,
    ]
  );

  //set default warehouse if only one warehouse
  useEffect(() => {
    if (warehouseItems.length === 1 && initialValues.warehouse_id === 0) {
      form.setFieldValue("warehouse_id", warehouseItems[0].id);
    }
  }, [warehouseItems, initialValues.warehouse_id, form]);

  useEffect(() => {
    form.setFieldValue("detail", model.detail);
  }, [form, model.detail]);

  const warehouse_id = Form.useWatch("warehouse_id", form);
  const items = Form.useWatch("detail", form);
  let sidebarItems = (
    <>
      {model.status === InventoryReceiptStatus.COMPLETE && (
        <Button onClick={createRollbackReceipt}>Tạo phiếu đối xứng</Button>
      )}
      {isEditing ? (
        <div className="pb-4 mb-4 border-b border-gray-300">
          {model.direction === ProductReceipt.DIRECTION_INPUT
            ? t("productreceipt:warehouse_id_in")
            : t("productreceipt:warehouse_id_out")}
          : <br />
          <span className="text-lg font-bold">
            <TextStore warehouse_id={model.warehouse_id} />
            {/* <TextWarehouse
             
              warehouseItems={warehouseItems}
            /> */}
          </span>
        </div>
      ) : (
        <WarehouseFormSelect
          size="large"
          required
          disabled={model.id > 0}
          label={
            model.direction === ProductReceipt.DIRECTION_INPUT
              ? t("productreceipt:warehouse_id_in")
              : t("productreceipt:warehouse_id_out")
          }
          name="warehouse_id"
          initOptions={warehouseItems.map((i) => ({
            value: i.id,
            label: i.name,
          }))}
        />
      )}

      <FormSelect
        required
        disabled={model.id > 0}
        label={
          model.direction === ProductReceipt.DIRECTION_INPUT
            ? t("productreceipt:type_in")
            : t("productreceipt:type_out")
        }
        name="type"
        options={
          model.direction === ProductReceipt.DIRECTION_INPUT
            ? InventoryReceiptModel.getTypeInList()
            : InventoryReceiptModel.getTypeOutList()
        }
      />

      {model.source.type === InventoryReceiptSourceType.ORDER ? (
        <div>
          Nguồn:
          <Link target="_blank" to={`/order/edit/id/${model.source.id}`}>
            Đơn hàng #{model.source.id}
          </Link>
        </div>
      ) : null}

      <Form.Item label={t("productreceipt:note")} name="note">
        <Input.TextArea rows={3} disabled={!allowEditInput || disable} />
      </Form.Item>
    </>
  );

  return (
    <>
      <InventoryReceiptFormHeader
        isEditing={isEditing}
        direction={model.direction}
        id={model.code}
      />

      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        disable_status_loading
        submitDisabled={disable || !allowEditInput}
        successTitle={
          isEditing
            ? t("productreceipt:form.success.update")
            : t("productreceipt:form.success.add")
        }
        error_translate_prefix="inventoryreceipt:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
        id="productreceipt-form"
        redirectOnSuccess={isEditing ? "" : "/inventoryreceipt"}
      >
        <InventoryReceiptFormStatus
          warehouse_id={model.warehouse_id}
          id={model.id}
          initStatus={model.status}
          status={status}
          setStatus={setStatus}
          disable={!allowEditInput}
        />
        <br />
        <InventoryReceiptProduct
          store_id={warehouse_id}
          model={model}
          data={model.detail}
          disabled={!allowEditInput}
          onUpdate={(data) => {
            form.setFieldValue("detail", data);
          }}
        ></InventoryReceiptProduct>
        <FormSection
          subtitle={
            isIn
              ? "Thông tin chi tiết về nguồn phát sinh phiếu nhập"
              : "Thông tin chi tiết về nguồn phát sinh phiếu xuất"
          }
          title={`Nguồn phiếu ${isIn ? "nhập" : "xuất"}`}
        >
          <InventoryReceiptSource
            model={model}
            disabled={!allowEditInput}
          ></InventoryReceiptSource>
        </FormSection>

        <FormSection
          subtitle={
            isIn
              ? "Thông tin chi tiết về đích phiếu nhập"
              : "Thông tin chi tiết về đích phiếu xuất"
          }
          title={`Đích phiếu ${isIn ? "nhập" : "xuất"}`}
        >
          <InventoryReceiptTarget
            model={model}
            disabled={!allowEditInput}
          ></InventoryReceiptTarget>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default InventoryReceiptForm;

function TextStore({ warehouse_id }: { warehouse_id: number }) {
  //   const [warehouseItems, setWarehouseItems] = useState<WarehouseStoreModel[]>(
  //     []
  //   );
  const [store, setStore] = useState(StoreModel.getDefaultData());

  const fetchDataWarehouseStore = useCallback(async () => {
    let collection = await new WarehouseRepository().getItemWarehouseStore(
      warehouse_id
    );
    if (!collection.hasError()) {
      const warehouseStore = collection;
      if (warehouseStore) {
        const storeJson = await new StoreRepository().getItem(
          warehouseStore.store_id
        );
        setStore(storeJson.toJson());
      }
    }
  }, [warehouse_id]);

  useEffect(() => {
    fetchDataWarehouseStore();
  }, [fetchDataWarehouseStore]);

  return <p>{store.name ? store.name : "không tìm thấy"}</p>;
}
