import { Button, Dropdown, MenuProps, Spin } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronDown, IconPencil, IconTrash } from "@tabler/icons-react";

const DataGridTableAction = ({
  onClickEditTable,
  onClickDeleteTable,
  isDeleting
}: {
  onClickEditTable: () => void;
  onClickDeleteTable: () => void;
  isDeleting: boolean;
}) => {
  const { t } = useTranslation();

  const menuItems: MenuProps["items"] = useMemo(() => {
    const items: MenuProps["items"] = [];

    items.push({
      key: "edit",
      label: (
        <span
          onClick={() => {
            onClickEditTable();
          }}>
          {t("datagridtable:edit")}
        </span>
      ),
      icon: <IconPencil size={18} className="text-gray-400" />
    });

    items.push({ type: "divider" });
    items.push({
      key: "delete",
      danger: true,
      label: (
        <span
          onClick={() => {
            onClickDeleteTable();
          }}>
          {t("datagridtable:delete")}
        </span>
      ),
      icon: <IconTrash size={18} />
    });

    return items;
  }, [onClickDeleteTable, onClickEditTable, t]);

  return (
    <>
      {isDeleting ? (
        <Spin size="small" />
      ) : (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: menuItems
          }}>
          <Button
            className="ml-1 -mt-1 opacity-50 hover:opacity-100"
            type="text"
            icon={
              <IconChevronDown size={18} className="-mt-1 text-gray-800" />
            }></Button>
        </Dropdown>
      )}
    </>
  );
};

export default DataGridTableAction;
