import React, { useState, useEffect, useCallback } from "react";
import AccountRepository from "common/repositories/AccountRepository";
import Error from "components/LayoutError";
import { Button, Input, Form, message, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Helper from "common/utils/helper";
import { IconMail } from "@tabler/icons-react";
import UserHostname from "./UserHostName";

const UserForgotPassForm = () => {
  const messageKey = "editor";

  const { t } = useTranslation();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);

  //show message loading when change processing ON
  useEffect(() => {
    if (processing) {
      message.loading({
        content: t("common:form.processing"),
        key: messageKey,
        duration: 0,
      });
    }
  }, [processing, t]);

  const formSubmit = useCallback(
    async (email: string, hostname: string) => {
      setProcessing(true);
      let repository = new AccountRepository();
      const responseErrors = await repository.forgotpassword(email, hostname);
      setProcessing(false);
      if (responseErrors.length === 0) {
        message.destroy(messageKey);

        Modal.success({
          title: t("user:form.success.forgotpass_heading"),
          content: (
            <div>
              {t("user:form.success.forgotpass_text_1")} <br />
              <br />
              <em>{t("user:form.success.forgotpass_text_2")}</em>
            </div>
          ),
          onOk: () => {
            navigate("/user/login");
          },
        });
      } else {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKey);
              }}
              heading={t("user:form.error.general_heading")}
              translate_prefix={"user:form.error" || ""}
              items={responseErrors}
            />
          ),
          className: "message_error",
          key: messageKey,
          duration: 10,
        });
      }
    },
    [t, navigate]
  );

  //formsubmit onFinish proxy, wrapping to set processing (to show spinner) and call onSubmit callback from parent
  const onFormSubmit = useCallback(
    (formData: any) => {
      formSubmit(formData.email, formData.hostname);
    },
    [formSubmit]
  );

  return (
    <Form
      initialValues={{ hostname: Helper.getHostname() }}
      form={form}
      onFinish={onFormSubmit}
      className="mt-4"
      layout="vertical"
    >
      <UserHostname />

      <Row gutter={16}>
        <Col flex="auto">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t("user:form.error.required_account"),
              },
            ]}
          >
            <Input
              prefix={<IconMail size={18} color="grey" />}
              placeholder={t("common:email2")}
            />
          </Form.Item>
        </Col>
        <Col flex="120px">
          <Form.Item>
            <Button type="primary" loading={processing} htmlType="submit" block>
              {t("user:forgotpass_btn")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForgotPassForm;
