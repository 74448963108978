import { Col, Form, Input, Row } from "antd";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import {
  InventoryReceiptJson,
  InventoryReceiptTargetType,
} from "common/types/InventoryResceipt";
import FormSelect from "components/form/FormSelect";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  disabled?: boolean;
  model: InventoryReceiptJson;
};
const InventoryReceiptTarget = ({ disabled, model }: Props) => {
  return (
    <div>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            className="mb-0"
            rules={[
              {
                required: true,
                message: "Yêu cầu nhập",
              },
            ]}
            name={["target", "type"]}
            label="Loại"
          >
            <FormSelect
              disabled={disabled}
              options={[
                { label: "Chọn loại", value: 0, disabled: true },
                ...InventoryReceiptModel.getTargetTypeOpntion(),
              ]}
            ></FormSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="mb-0"
            rules={[
              {
                required: true,
                message: "Yêu cầu nhập",
              },
            ]}
            name={["target", "id"]}
            label="Mã"
          >
            <Input disabled={disabled} placeholder="Giá trị"></Input>
          </Form.Item>
        </Col>
      </Row>

      {model.target.type === InventoryReceiptTargetType.DELIVERY && (
        <Link target="_blank" to={`/delivery/edit/id/${model.target.id}`}>
          Chi tiết vận đơn # {model.target.id}
        </Link>
      )}
    </div>
  );
};

export default InventoryReceiptTarget;
