import { TableColumnsType } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";

import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import CollectionHeader from "./CollectionListHeader";
import CollectionFilter from "./CollectionListFilter";
import TableDelete from "components/table/TableDelete";
import useFilterLocation from "hooks/useFilterLocation";

import { CollectionJsonWithChildren } from "common/types/Collection";
import CollectionRepository from "common/repositories/CollectionRepository";
import PageDataTableWithChildren from "components/page/PageDataTableWithChildren";
import NestedCollection from "common/collections/NestedCollection";

const CollectionList = () => {
  const { t } = useTranslation();

  // default filter
  const defaultFilters = useMemo(() => {
    return CollectionRepository.getDefaultFilters();
  }, []);

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<CollectionJsonWithChildren[]>(
    []
  );
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //Table columns
  const columns: TableColumnsType<CollectionJsonWithChildren> = [
    {
      title: t("collection:columns.title"),
      key: "title",
      width: 100,
      render: (_: any, record: CollectionJsonWithChildren) => {
        return <>{record.title}</>;
      },
    },
    {
      title: "",
      key: "id",
      align: "right",
      fixed: "right",
      width: 100,
      render: (id: number, record: CollectionJsonWithChildren) => {
        return (
          <RoleCheck roles={[]} hideOnFail>
            <TableEdit link={`/collection/edit/id/${record.id}`} />
            <TableDelete
              error_translate_prefix="collection:form.error"
              onDeleteCallback={(id) => {
                setDataSource(dataSource.filter((item) => item.id !== id));
              }}
              repository={new CollectionRepository()}
              id={record.id}
            />
            <TableInfo record={record} />
          </RoleCheck>
        );
      },
    },
  ];

  //function
  const fetchData = useCallback(async (): Promise<
    NestedCollection<CollectionJsonWithChildren>
  > => {
    const response = await new CollectionRepository().getFullNestedItems({
      filters: filters,
    });
    if (!response.hasError()) {
      setTotal(response.total);
      setDataSource(response.items);
    }
    return response;
  }, [filters]);

  return (
    <div>
      <CollectionHeader />
      <CollectionFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />

      <PageDataTableWithChildren<CollectionJsonWithChildren>
        {...{
          columns,
          dataSource,
          fetchData,
        }}
      />
    </div>
  );
};

export default CollectionList;
