import BaseCollection from "common/collections/BaseCollection";
import ProductTransmitDetailModel from "common/models/ProductTransmitDetailModel";
import { ProductTransmitDetailJson, ProductTransmitDetailCollectionJson } from "common/types/ProductTransmitDetail";

class ProductTransmitDetailCollection extends BaseCollection<
  ProductTransmitDetailJson,
  ProductTransmitDetailModel,
  ProductTransmitDetailCollectionJson
> {
  itemsFromJson(jsonItems: ProductTransmitDetailJson[]): ProductTransmitDetailModel[] {
    return jsonItems.map((item) => new ProductTransmitDetailModel(item));
  }
}

export default ProductTransmitDetailCollection;


