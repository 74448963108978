import { Checkbox, Col, Form, Input, Popover, Row, Table } from "antd";
import CompanySettingRepository from "common/repositories/CompanySettingRepository";
import { CompanySettingEntry } from "common/types/CompanySetting";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";
import MoneyInput from "components/MoneyInput";
import TagStringInput from "components/TagStringInput";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import ReceiptTemplateInput from "./input/ReceiptTemplateInput";
import SettingGeneralHeader from "./SettingGeneralHeader";

const SettingGeneral = ({ data }: { data: CompanySettingEntry }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = data;

  //prepare data for submit
  const doPrepareData = useCallback((formData: any) => {
    const submitData: CompanySettingEntry = {
      ...formData
    };

    return submitData;
  }, []);

  //submit data to server
  const onSubmit = async (formData: any) => {
    setErrors([]);

    let myObj = await new CompanySettingRepository().updateSettingGeneral(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  const weightCodeTooltip = (
    <Table
      size="small"
      columns={[
        {
          title: t("setting:label.weighted_barcode_format_part"),
          dataIndex: "code",
          key: "code"
        },
        {
          title: t("setting:label.weighted_barcode_format_part_description"),
          dataIndex: "description",
          key: "description"
        }
      ]}
      dataSource={[
        {
          code: t("setting:label.weighted_barcode_format_prefix"),
          description: t("setting:label.weighted_barcode_format_prefix_note")
        },
        {
          code: "S",
          description: t("setting:label.weighted_barcode_format_s")
        },
        {
          code: "G",
          description: t("setting:label.weighted_barcode_format_g")
        },
        {
          code: "C",
          description: t("setting:label.weighted_barcode_format_c")
        }
      ]}
      pagination={{
        pageSize: 10,
        hideOnSinglePage: true
      }}
    />
  );

  return (
    <>
      <SettingGeneralHeader></SettingGeneralHeader>
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={t("setting:form.success.update")}
        redirectOnSuccess=""
        error_translate_prefix="setting:form.error"
        onSubmit={onSubmit}
        submitText={t("common:form.save_short")}
        sidebarItems={[]}
        span={21}>
        <FormSection
          title={t("setting:point_section")}
          subtitle={t("setting:point_section_subtitle")}
          divider>
          <Form.Item
            label={t("setting:label.point_formula")}
            tooltip={t("setting:label.point_formula_note")}
            name="point_formula">
            <MoneyInput
              addonBefore={t("setting:label.point_formula_prefix")}
              addonAfter={
                <>
                  {t("setting:label.point_formula_suffix")}{" "}
                  <span className="font-bold">
                    {t("setting:label.point_one")}
                  </span>
                </>
              }
              placeholder={t("setting:label.point_formula_placeholder")}
              prefix={data.currency_prefix.toString()}
            />
          </Form.Item>

          <Form.Item
            label={t("setting:label.point_convert")}
            name="point_convert"
            className="mb-0">
            <MoneyInput
              addonBefore={
                <>
                  <span className="font-bold">
                    {t("setting:label.point_one")}
                  </span>
                  &nbsp;{t("setting:label.point_convert_prefix")}
                </>
              }
              addonAfter={data.currency.toString()}
              prefix={data.currency_prefix.toString()}
            />
          </Form.Item>
        </FormSection>
        <FormSection
          title={t("setting:sale_section")}
          subtitle={t("setting:sale_section_subtitle")}
          divider>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                className="mb-0"
                name="disable_manual_promotion"
                valuePropName="checked">
                <Checkbox>
                  {t("setting:label.disable_manual_promotion")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                hidden
                name="enable_weighted_barcode_item"
                valuePropName="checked">
                <Checkbox>
                  {t("setting:label.enable_weighted_barcode_item")}
                </Checkbox>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.enable_weighted_barcode_item !==
                  currentValues.enable_weighted_barcode_item
                }>
                {({ getFieldValue }) =>
                  getFieldValue("enable_weighted_barcode_item") ? (
                    <Form.Item name="weighted_barcode_format" className="-mt-4">
                      <Popover
                        placement="bottomRight"
                        trigger={"focus"}
                        content={weightCodeTooltip}>
                        <Input
                          addonBefore={t(
                            "setting:label.weighted_barcode_format"
                          )}
                          placeholder="22SSSSSGGGGGC,23SSSSSGGGGGC..."
                        />
                      </Popover>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="pos_check_out_of_stock" valuePropName="checked">
                <Checkbox>{t("setting:label.pos_check_out_of_stock")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                className="mb-0"
                label={t("setting:label.tag_default")}
                name="tag_default">
                <TagStringInput />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <Form.Item name="pos_receipt_template">
          <ReceiptTemplateInput />
        </Form.Item>
      </LayoutForm>
    </>
  );
};

export default SettingGeneral;
