import { Col, Form, FormInstance, Input, Row } from "antd";
import PurchaseOrderDetailModel from "common/models/PurchaseOrderDetailModel";
import { OrderEditingPrice } from "common/types/Order";
import MoneyInput from "components/MoneyInput";
import TextMoney from "components/TextMoney";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

import { IconTruck } from "@tabler/icons-react";

const PurchaseOrderFormSectionPriceSummary = ({
  items,
  form,
  allowEditInput,
}: {
  items: PurchaseOrderDetailModel[];
  form: FormInstance;
  allowEditInput: boolean;
}) => {
  const { t } = useTranslation();
  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );
  const [priceDiscount, setPriceDiscount] = useState<number>(
    purchaseOrder.price_discount
  );
  const [priceShipping, setPriceShipping] = useState(
    purchaseOrder.price_shipping
  );

  const calculatePrices = useCallback((): Omit<
    OrderEditingPrice,
    "price_tax" | "price_before_tax"
  > => {
    let priceSell = 0;
    let priceFinal = 0;

    items.forEach((item) => {
      priceSell += item.item_unit_price * item.item_quantity;
    });

    //finally
    priceFinal = priceSell - priceDiscount + priceShipping;

    const result = {
      price_sell: priceSell,
      price_final: priceFinal >= 0 ? priceFinal : 0,
    };

    return result;
  }, [items, priceShipping, priceDiscount]);

  const orderPrices = useMemo(() => calculatePrices(), [calculatePrices]);

  useEffect(() => {
    //sync to formdata
    form.setFieldsValue({
      price_sell: orderPrices.price_sell,
      price_final: orderPrices.price_final,
    });
  }, [form, orderPrices]);

  return (
    <div className="px-2 pt-2">
      <Row gutter={16} className="mb-4">
        <Col flex={"auto"} className="text-right">
          {t("purchaseorder:price_sell")} :
        </Col>
        <Col flex={"170px"} className="text-right">
          <span className="font-bold">
            <TextMoney money={orderPrices.price_sell} />
            <Form.Item hidden name="price_sell">
              <Input type={"hidden"} />
            </Form.Item>
          </span>
        </Col>
      </Row>

      <Row gutter={16} className="mb-2">
        <Col flex={"auto"} className="text-right">
          <div className="mt-1">{t("purchaseorder:price_discount")} :</div>
        </Col>
        <Col flex={"170px"} className="text-right">
          <Form.Item name="price_discount" noStyle>
            <MoneyInput
              disabled={!allowEditInput}
              style={{ width: "100%" }}
              onChange={(v) => setPriceDiscount(v !== null ? +v : 0)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="mb-2">
        <Col flex={"auto"} className="text-right">
          <div className="mt-1">{t("purchaseorder:price_shipping")} :</div>
        </Col>
        <Col flex={"170px"} className="text-right">
          <Form.Item name="price_shipping" noStyle>
            <MoneyInput
              disabled={!allowEditInput}
              addonBefore={<IconTruck size={16} />}
              style={{ width: "100%" }}
              onChange={(v) => setPriceShipping(v ?? 0)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="text-xl py-3 px-1 bg-green-50">
        <Col flex={"auto"} className="text-right text-xl">
          {t("purchaseorder:price_final")} :
        </Col>
        <Col flex={"170px"} className="text-right font-bold text-xl">
          <TextMoney money={orderPrices.price_final} />
          <Form.Item hidden name="price_final">
            <Input type={"hidden"} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseOrderFormSectionPriceSummary;
