import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";

const ITRequestFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("itrequest:heading_nav"),
      link: "/itrequest",
    },
    {
      text: isEditing ? t("itrequest:form.edit") : t("itrequest:form.add"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t(isEditing ? "itrequest:form.edit" : "itrequest:form.add")}
      nav={nav}
      siteMenuOpenKey="tool"
      siteMenuSelectedKey="/itrequest"
      siteMenuHidden
    ></PageHeader>
  );
};

export default ITRequestFormHeader;
