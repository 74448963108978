// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  /* These rules only apply to the page when printed */
  .hide_on_print {
    display: none !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/layout/print.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["@media print {\n  /* These rules only apply to the page when printed */\n  .hide_on_print {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
