import { Form } from "antd";
import React, { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import Helper from "common/utils/helper";
import DateFormFilter from "./DateFormFilter";
import { useTranslation } from "react-i18next";
import { RangePickerProps } from "antd/es/date-picker";
import { Filter } from "common/types/Filter";
import { cn } from "common/utils/utils";
import DayJSUtc from "dayjs/plugin/utc";
import DayJSTimezone from "dayjs/plugin/timezone";

dayjs.extend(DayJSUtc);
dayjs.extend(DayJSTimezone);

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const DatePickerRangeFilter = <F extends Filter>({
	filters,
	setFilters,
	keyStart = "date_started",
	keyEnd = "date_ended",
	label,
	placeholderRange,
	className,
	rangeDefaultValue,
}: {
	filters: F;
	setFilters: (f: F) => any;
	keyStart: string;
	keyEnd: string;
	label?: string;
	placeholderRange?: [string, string];
	className?: string;
	rangeDefaultValue?: [dayjs.Dayjs, dayjs.Dayjs];
}) => {
	const { t } = useTranslation();

	////////////////////////////
	const getValueFilter = useMemo(() => {
		let entries = Object.entries(filters)
			.filter(([key, val]) => key === keyStart || key === keyEnd)
			.map(([key, val]) => {
				return val;
			}) as number[];
		return (
			+entries[0] > 0 && +entries[1] > 0
				? [
						dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
						dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
				  ] || null
				: [null, null] || null
		) as RangeValue;
	}, [filters, keyEnd, keyStart]);
	////////////////////////////////
	// const onChangeValueFilter: RangePickerProps["onChange"] = (
	//   value: RangePickerProps["value"],
	//   dateString: [string, string]
	// ) => {
	//   const newFilter: { [key: string]: any } = {};
	//   newFilter[keyStart] = -1;
	//   newFilter[keyEnd] = -1;
	//   if (typeof value !== "undefined" && value !== null) {
	//     newFilter[keyStart] = +dayjs(
	//       "00:00, " + value[0]?.format("DD/MM").toString(),
	//       "HH:mm, DDMM"
	//     ).unix();
	//     newFilter[keyEnd] = +dayjs(
	//       "00:00, " + value[1]?.format("DD/MM").toString(),
	//       "HH:mm, DDMM"
	//     ).unix();
	//   }

	//   setFilters({
	//     ...filters,
	//     ...newFilter,
	//   });
	// };
	const onChangeValueFilter: RangePickerProps["onChange"] = (
		value: RangePickerProps["value"],
		dateString: [string, string]
	) => {
		const newFilter: { [key: string]: any } = {};
		newFilter[keyStart] = -1;
		newFilter[keyEnd] = -1;
		if (value && value[0] && value[1]) {
			newFilter[keyStart] = +dayjs(value[0]).startOf("day").unix();
			newFilter[keyEnd] = +dayjs(value[1].endOf("day")).unix();
		}

		setFilters({
			...filters,
			...newFilter,
		});
	};

	return (
		<Form.Item label={label} className={cn("", className)}>
			<DateFormFilter
				rangeDefaultValue={rangeDefaultValue}
				rangeValue={getValueFilter}
				allowClear={true}
				showNow={false}
				mode="range"
				format="DD/MM/YYYY"
				onRangeChange={onChangeValueFilter}
				className="w-full"
				placeholderRange={placeholderRange}
			/>
		</Form.Item>
	);
};

export default DatePickerRangeFilter;
