import { Button, Row, Tag, Tooltip, message } from "antd";
import { TableColumnsType } from "common/types/Table";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";

import TagCollection from "common/collections/TagCollection";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { FilterTag } from "common/types/Tag";

import { IconPerfume } from "@tabler/icons-react";
import TagConstant from "common/constants/Tag";
import TagTypeModel from "common/models/TagTypeModel";
import TableDelete from "components/table/TableDelete";
import ProductToTagModal from "features/tag/product/ProductToTagModal";
import TagInsideFormModal from "./TagInsideFormModal";
import TagInsideListFilter from "./TagInsideListFilter";

const TagInsideList = ({ record }: { record: TagTypeModel }) => {
	const { t } = useTranslation();
	// default filter
	const defaultFilters = useMemo(() => {
		return {
			...TagRepository.getDefaultFilters(),
			type: record.id,
			is_root:
				record.id === 2 ? TagConstant.ROOT.USER : TagConstant.ROOT.SYSTEM,
		};
	}, []);
	//////////////////////////////////////////
	//Fetch data from this collections
	const [filters, setFilters] = useState(defaultFilters);
	// useFilterLocation(defaultFilters, filters);

	const [dataSource, setDataSource] = useState<TagModel[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const fetchData = useCallback(async (): Promise<TagCollection> => {
		setLoading(true);
		const response = await new TagRepository().getItems({
			filters: filters,
		});
		if (!response.hasError()) {
			setTotal(response.total);
			setDataSource(response.items);
		} else {
			message.error("common:error.error_fetching_data");
		}

		setLoading(false);
		return response;
	}, [filters]);

	// modal editing
	const [addVisible, setAddVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);

	const renderTagStatus = (value: number) => {
		switch (value) {
			case 1:
				return <Tag color="green">Hiện</Tag>;
			case 2:
				return <Tag>Nháp</Tag>;
			case 3:
				return <Tag color="red">Ẩn</Tag>;
			default:
				return null;
		}
	};

	const [addProductVisible, setAddProductVisible] = useState(false);
	const [tags, setTags] = useState<string>("");
	const [tagName, setTagName] = useState<string>("");
	const [editingProductTagId, setEditingProductTagId] = useState<number>(0);
	const onEditProduct = (record: TagModel) => {
		setEditingProductTagId(record.id);
		setTags(record.code);
		setTagName(record.name);
		setAddProductVisible(true);
	};
	const p = [
		{
			id: 1,
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-gucci.png",
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40668-957101719570430-1719570430.jpg",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
			title: "Gucci",
			alt: "Gucci",
		},
		{
			id: 2,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40669-694161719570454-1719570454.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-jean-paul-gaultier.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
			title: "Jean Paul Gaultier",
			alt: "Jean Paul Gaultier",
		},
		{
			id: 3,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40670-289691719570475-1719570475.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-narciso-rodriguez.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
			title: "Narciso Rodriguez",
			alt: "Narciso Rodriguez",
		},
		{
			id: 4,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40671-103441719570497-1719570497.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-giorgio-armani.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/giorgio-armani",
			title: "Giorgio Armani",
			alt: "Giorgio Armani",
		},
		{
			id: 5,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40672-851861719570513-1719570513.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-paco-rabanne.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/paco-rabanne",
			title: "Paco Rabanne",
			alt: "Paco Rabanne",
		},
		{
			id: 6,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40673-368761719570531-1719570531.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-calvin-klein.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
			title: "Calvin Klein",
			alt: "Calvin Klein",
		},
		{
			id: 7,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40674-441301719570551-1719570551.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-versace.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/versace",
			title: "VERSACE",
			alt: "VERSACE",
		},
		{
			id: 8,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40675-553381719570571-1719570571.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-burberryx.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
			title: "Burberry",
			alt: "Burberry",
		},
		{
			id: 9,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40676-740551719570587-1719570587.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-marc-jacobss.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/marc-jacobs",
			title: "Marc Jacobs",
			alt: "Marc Jacobs",
		},
		{
			id: 10,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40677-995931719570601-1719570601.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-valentino.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
			title: "VALENTINO",
			alt: "VALENTINO",
		},
		{
			id: 11,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40678-310881719570629-1719570629.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-prada.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/prada",
			title: "PRADA",
			alt: "PRADA",
		},
		{
			id: 12,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40679-911381719570654-1719570654.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-carolina-herrera.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
			title: "Carolina Herrera",
			alt: "Carolina Herrera",
		},
		{
			id: 13,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40680-763791719570665-1719570665.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-ralph-lauren.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
			title: "Ralph Lauren",
			alt: "Ralph Lauren",
		},
		{
			id: 14,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40681-779291719570692-1719570692.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-viktor-rolf.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/viktor-rolf",
			title: "VIKTOR & ROLF",
			alt: "VIKTOR & ROLF",
		},
		{
			id: 15,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40682-115041719570702-1719570702.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-lacoste.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/lacoste",
			title: "LACOSTE",
			alt: "LACOSTE",
		},
		{
			id: 16,
			image_mobile:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/June/40683-316221719570714-1719570714.jpg",
			image:
				"https://theme.hstatic.net/1000340570/1000964732/14/logo-brand-chloe.png",
			width: 612,
			height: 288,
			link: "/blogs/thuong-hieu-nuoc-hoa/chloe",
			title: "Chloé",
			alt: "Chloé",
		},
	];

	//Table columns
	const columns: TableColumnsType<TagModel> = [
		{
			title: "ID",
			key: "id",
		},
		{
			title: t("tag:code"),
			key: "code",
		},
		{
			title: t("tag:name"),
			key: "name",
		},
		{
			title: t("Loại tag"),
			key: "type",
			render(value, record, index) {
				return <>{record.type.length > 0 ? record.type[0]?.name : ""}</>;
			},
		},
		{
			title: t("tag:value"),
			key: "value",
		},
		{
			title: t("Dùng cho"),
			key: "resource_type",
			render(value, record, index) {
				return <>{TagModel.getResouceTypeValue(value)}</>;
			},
		},
		{
			title: t("tag:status"),
			key: "status",
			render(value, record, index) {
				return renderTagStatus(value);
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			toggletype: "trigger",
			width: 189,
			render: (_: any, record: TagModel) => (
				<RoleCheck roles={[]} hideOnFail>
					{record.is_root === 2 ? (
						<TableEdit link="" onClick={() => onEdit(record.id)} />
					) : (
						<></>
					)}
					{record.resource_type === TagConstant.RESOURCE_TYPE.PRODUCT ? (
						<>
							<Button
								size={"small"}
								type={"text"}
								onClick={() => onEditProduct(record)}>
								<Tooltip title="Quản lý sản phẩm">
									<IconPerfume
										className="-mt-0.5 text-gray-400 hover:text-red-500"
										size="18"
									/>
								</Tooltip>
							</Button>
						</>
					) : (
						<></>
					)}
					{record.is_root === 2 ? (
						<>
							<TableDelete
								error_translate_prefix="tag:form.error"
								onDeleteCallback={(id) => {
									setDataSource(dataSource.filter((item) => item.id !== id));
								}}
								repository={new TagRepository()}
								id={record.id}
							/>
						</>
					) : (
						<></>
					)}
					<TableInfo record={record} />
				</RoleCheck>
			),
		},
	];

	const onEdit = (id: number) => {
		setEditingId(id);
		setAddVisible(true);
	};

	const onSaveSuccess = (item: TagModel) => {
		fetchData();
	};

	return (
		<>
			<TagInsideListFilter
				defaultFilters={defaultFilters}
				filters={filters}
				setFilters={setFilters}
				total={total}
				record={record}
			/>

			<Row className="flex justify-end my-4">
				<Button
					type="primary"
					onClick={() => {
						setEditingId(0);
						setAddVisible(true);
					}}>
					{t("common:table.add_button")}
				</Button>
			</Row>

			<PageDataTable<FilterTag, TagModel, TagCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "taginsidelist",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<TagInsideFormModal
				id={editingId}
				key={editingId}
				open={addVisible}
				tagTypeDetail={record}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingId(0);
					}
					setAddVisible(isOpen);
				}}
				onSaveSuccess={onSaveSuccess}
			/>

			<ProductToTagModal
				id={editingProductTagId}
				key={editingProductTagId}
				open={addProductVisible}
				tags={tags}
				tagName={tagName}
				setOpen={(isOpen) => {
					//clear editing id when close
					if (!isOpen) {
						setEditingProductTagId(0);
						setTags("");
					}
					setAddProductVisible(isOpen);
				}}
			/>
		</>
	);
};

export default TagInsideList;
