import { Button, Divider, Modal, Popover, Spin } from "antd";
import CompanyModel from "common/models/CompanyModel";
import AccountRepository from "common/repositories/AccountRepository";
import { LogoutStatus } from "common/types/Account";
import eventEmitter from "common/utils/eventEmitter";
import UserAvatar from "components/UserAvatar";
import db from "db";
import dbm from "dbm";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
  IconInfoCircle,
  IconLogout,
  IconSwitchHorizontal,
  IconUserCircle,
} from "@tabler/icons-react";

import HeaderCompanySwitcher from "./HeaderCompanySwitcher";
import AccountModel from "common/models/AccountModel";

const HeaderProfile = () => {
  const { t } = useTranslation();
  const eventEmitterRef = useRef<any>();

  const [logoutStatus, setLogoutStatus] = useState<LogoutStatus>("");
  const [showCompanySwitcher, setShowCompanySwither] = useState(false);

  const [account, setAccount, setInitialPathName] = useLoginAccountStore(
    (state) => [state.account, state.setAccount, state.setInitialPathName]
  );

  const doLogOut = useCallback(async () => {
    setLogoutStatus("processing");

    //Clear old In-memory DB tables
    const listCollections = dbm.listCollections();
    listCollections.map((collection) => {
      //IMPORTANT: WE DO NOT REMOVE REGION collection
      if (collection.name !== "region") {
        console.log(
          "Remove In-memory DB Table",
          collection.name,
          ", records: ",
          collection.count
        );
        dbm.removeCollection(collection.name);
      }

      return null;
    });

    //Remove Dexie Database (IndexedDB)
    if (typeof db !== "undefined" && db) {
      db.tables.forEach(function (table) {
        table.count().then((countItem) => {
          console.log(
            "Remove IndexedDB Table",
            table.name,
            ", records: ",
            countItem
          );
          table.clear();
        });
      });
    }

    //call web-api logout
    await new AccountRepository().logout().finally(() => {
      setInitialPathName("/");
      setAccount(new AccountModel(AccountModel.getDefaultData()));
    });

    setTimeout(() => setLogoutStatus("done"), 1000);
  }, []);

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "DO_LOGOUT",
        (_data: { source: string }) => {
          doLogOut();
        }
      );
    }
  });

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);

  const dropDownContent = (
    <div className="">
      <div className="font-bold text-lg mb-1">
        {account.full_name || "👋 Chào bạn!"}
      </div>

      <div className="text-gray-400 text-xs">
        {account.company.employee.job_title}
      </div>

      <Divider className="my-2" />
      {account.id > 0 ? (
        <div className="leading-loose">
          <Link to={"/company/info"} className="block">
            <IconInfoCircle className="mr-1" size={16} />
            Thông tin công ty
          </Link>
          <Link to={"/profile"} className="block">
            <IconUserCircle className="mr-1" size={16} /> Cập nhật Tài khoản
          </Link>
          <Divider className="my-2" />
          <span
            onClick={() => setShowCompanySwither(true)}
            className="block cursor-pointer"
          >
            <IconSwitchHorizontal className="mr-1" size={16} /> Lựa chọn công ty
            truy cập
          </span>

          <Link
            className="block text-red-700"
            to={""}
            onClick={() => doLogOut()}
          >
            <IconLogout className="mr-1" size={16} />
            Thoát
          </Link>
        </div>
      ) : (
        <div className="leading-loose">
          <Link className="block text-blue-600" to="/user/login">
            Đăng nhập
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <div className="">
      <Popover
        autoAdjustOverflow={true}
        overlayClassName="fixed"
        overlayInnerStyle={{ marginTop: 8, width: 240 }}
        placement="bottomRight"
        content={dropDownContent}
        trigger={["click"]}
      >
        <span className="cursor-pointer">
          <UserAvatar user={account} hideTooltip />
        </span>
      </Popover>
      {logoutStatus !== "" ? (
        <>
          <Modal
            open={true}
            title={t("user:logout.title")}
            destroyOnClose
            closable={false}
            footer={null}
          >
            {logoutStatus === "processing" ? (
              <>
                <Spin /> &nbsp;Đang thoát khỏi hệ thống
              </>
            ) : (
              <>
                <h3 className="mb-4">{t("user:logout.success_message")}</h3>
                <Link to="/user/login">
                  <Button type="primary">{t("user:login_btn")}</Button>
                </Link>
              </>
            )}
          </Modal>
        </>
      ) : null}

      <HeaderCompanySwitcher
        open={showCompanySwitcher}
        setOpen={(isOpen) => {
          setShowCompanySwither(isOpen);
        }}
      />
    </div>
  );
};

export default HeaderProfile;
