import { Input } from "antd";
import EcomLogCollection from "common/collections/EcomLogCollection";
import Ecom from "common/constants/Ecom";
import EcomLogModel from "common/models/EcomLogModel";
import EcomModel from "common/models/EcomModel";
import EcomLogRepository from "common/repositories/EcomLogRepository";
import { FilterEcomLog } from "common/types/EcomLog";
import { TableColumnsType } from "common/types/Table";
import PageDataTable from "components/page/PageDataTable";
import TextDateTime from "components/TextDateTime";
import EcomItemTypeImage from "features/ecom/item/EcomItemTypeImage";
import EcomLogListHeader from "features/ecomlog/list/EcomLogListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const EcomLogList = ({
  channel_id,
  model,
}: {
  channel_id: number;
  model: EcomModel;
}) => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterEcomLog = useMemo(
    () => EcomLogRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterEcomLog>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<EcomLogModel[]>([]);
  const fetchData = useCallback(async (): Promise<EcomLogCollection> => {
    let collection = await new EcomLogRepository().getItems({
      filters: {
        ...filters,
        ecom_platform_id: channel_id,
      },
    });
    setDataSource(collection.items);

    return collection;
  }, [filters, channel_id]);

  //Table columns
  const columns: TableColumnsType<EcomLogModel> = [
    {
      title: t("common:sid"),
      key: "id",
      width: 80,
      align: "center",
    },
    {
      title: " ",
      width: 50,
      key: "ecom_platform_id",
      render: (ecom_platform_id: number, record) => {
        return (
          <>
            {record.identifier.indexOf("haravan") >= 0 ? (
              <EcomItemTypeImage
                type={Ecom.TYPE_HARAVAN}
                width={50}
                height={50}
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: t("common:type"),
      key: "type",
      render: (type: number) => {
        let typeInfo = EcomLogModel.getType(type);
        return <span>{typeInfo?.label}</span>;
      },
      width: 110,
    },
    {
      title: t("ecomlog:topic"),
      width: 110,
      key: "topic",
    },

    {
      title: t("ecomlog:data"),
      key: "data",
      render: (data: string) => {
        return <Input.TextArea defaultValue={data} rows={3} />;
      },
    },
    {
      title: t("ecomlog:trigger_action"),
      width: 110,
      key: "trigger_action",
    },
    {
      title: t("common:status"),
      key: "status",
      filters: EcomLogModel.getStatusList().map((option) => ({
        ...option,
        text: option.label,
      })),
      filteredValue: +filters.status >= 0 ? [+filters.status] : null,
      render: (status: number) => {
        let statusInfo = EcomLogModel.getStatus(status);
        return <span>{statusInfo?.label}</span>;
      },
      width: 110,
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 100,
      render: (ts: number) => (
        <TextDateTime format="HH:mm, DD/MM/YYYY" ts={ts} />
      ),
    },
  ];

  return (
    <>
      <EcomLogListHeader channel_id={channel_id} model={model}>
        <></>
      </EcomLogListHeader>
      <PageDataTable<FilterEcomLog, EcomLogModel, EcomLogCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>
    </>
  );
};

export default EcomLogList;
