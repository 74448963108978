import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import RoleCheck from "components/RoleCheck";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";

const StoryCollectionListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("storycollection:heading_list")}
      siteMenuOpenKey="content"
      siteMenuSelectedKey="/storycollection"
    >
      <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <RoleCheck roles={[]}>
            <PageHeaderButton link="/storycollection/add">
              {t("template:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default StoryCollectionListHeader;
