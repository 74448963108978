const CustomerPoint = {
  STATUS_ENABLE: 1,
  STATUS_DISABLE: 3,

  TYPE_MANUAL: 1,
  TYPE_ORDER: 3,
  TYPE_CASHFLOW: 5,
};

export default CustomerPoint;
