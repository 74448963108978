import { Button } from "antd";
import i18n from "i18n";
import React from "react";

import { IconX } from "@tabler/icons-react";

const LayoutError = ({
  heading,
  items,
  translate_prefix,
  className,
  children,
  contentPadding,
  onClickClose,
}: {
  items: string[];
  translate_prefix: string;
  onClickClose?: (...args: Array<any>) => any;
  contentPadding?: number;
  children?: React.ReactNode;
  heading?: string;
  className?: string;
}) => {
  const withHeading =
    typeof heading !== "undefined" && heading && heading.length > 0;

  return (
    <span className={className}>
      {withHeading ? (
        <span>
          <strong>{heading}</strong>
          {typeof onClickClose !== "undefined" ? (
            <Button
              size="small"
              shape="circle"
              type="text"
              className="mg-t-5 mg-l-10"
              onClick={onClickClose}
              icon={<IconX size="14" color="lightgrey" />}
            />
          ) : null}
        </span>
      ) : null}
      <ul
        className={
          "mg-b-5" +
          (typeof contentPadding !== "undefined"
            ? ""
            : " pd-l-" + contentPadding)
        }
      >
        {items.map((item) => (
          <li
            className="text-left"
            key={item}
            style={
              items.length === 1 && !withHeading
                ? { listStyleType: "none" }
                : {}
            }
          >
            {typeof translate_prefix !== "undefined" &&
            translate_prefix.length > 0 &&
            !item.includes(" ")
              ? i18n.t(translate_prefix + "." + item)
              : item}
          </li>
        ))}
      </ul>
      {children}
    </span>
  );
};

export default LayoutError;
