import BaseCollection from "common/collections/BaseCollection";
import TaxInvoiceDetailModel from "common/models/TaxInvoiceDetailModel";
import {
  TaxInvoiceDetailCollectionJson,
  TaxInvoiceDetailJson,
} from "common/types/TaxInvoiceDetail";

class TaxInvoiceDetailCollection extends BaseCollection<
  TaxInvoiceDetailJson,
  TaxInvoiceDetailModel,
  TaxInvoiceDetailCollectionJson
> {
  itemsFromJson(jsonItems: TaxInvoiceDetailJson[]): TaxInvoiceDetailModel[] {
    return jsonItems.map((item) => new TaxInvoiceDetailModel(item));
  }
}

export default TaxInvoiceDetailCollection;
