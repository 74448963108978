import CustomerModel from 'common/models/CustomerModel';
import CustomerRepository from 'common/repositories/CustomerRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import CustomerForm from 'features/customer/form/CustomerForm';
import CustomerFormHeader from 'features/customer/form/CustomerFormHeader';
import CustomerList from 'features/customer/list/CustomerList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const CustomerPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<CustomerModel>(
    new CustomerModel(CustomerModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new CustomerModel(CustomerModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <CustomerForm
          model={new CustomerModel(CustomerModel.getDefaultData())}
        />
      );
      break;

    case "edit":
      com =
        model.id > 0 ? (
          <CustomerForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new CustomerRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<CustomerFormHeader isEditing={true} />}
          />
        );
      break;

    default:
      com = <CustomerList />;
  }

  return com;
};

export default CustomerPage;
