import BaseRepository from "./BaseRepository";
import type { GetListProps } from "common/types/Filter";

//////////////////////////////////
//Phần thay thế
import { FilterLeaveStep, LeaveStepJsonAddEdit } from "common/types/LeaveStep";
import LeaveStepApi from "common/api/LeaveStepApi";
import LeaveStepCollection from "common/collections/LeaveStepCollection";
import LeaveStepModel from "common/models/LeaveStepModel";

class LeaveStepRepository extends BaseRepository<LeaveStepApi> {
  _api: LeaveStepApi | null;
  constructor() {
    super();
    this._api = new LeaveStepApi(true);
  }

  static getDefaultFilter(): FilterLeaveStep {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "DESC",
      keyword: "",
      status: "",
    };
  }

  async getItems(): Promise<LeaveStepCollection> {
    return this._api ? await this._api.getList() : new LeaveStepCollection();
  }

  async getItem(id: number): Promise<LeaveStepCollection> {
    return this._api
      ? await this._api.getDetail(id)
      : new LeaveStepCollection();
  }

  async saveRemote(data: LeaveStepJsonAddEdit): Promise<LeaveStepModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new LeaveStepModel(LeaveStepModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default LeaveStepRepository;
