import ProductCategory from 'common/constants/ProductCategory';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { ProductCategoryJson } from "common/types/ProductCategory";

class ProductCategoryModel
  extends BaseModel
  implements BaseModelJson<ProductCategoryJson>
{
  creator_id: number;
  company_id: number;
  id: number;
  name: string;
  code: string;
  level: number;
  parent_id: number;
  display_order: number;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: ProductCategoryJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.code = json.code || "";
    this.level = json.level || 0;
    this.parent_id = json.parent_id || 0;
    this.display_order = json.display_order || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ProductCategoryJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      name: "",
      code: "",
      parent_id: 0,
      level: 0,
      display_order: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): ProductCategoryJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      name: this.name,
      code: this.code,
      level: this.level,
      parent_id: this.parent_id,
      display_order: this.display_order,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ProductCategory.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ProductCategory.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default ProductCategoryModel;
