import ProductInventoryCheckingCollection from "common/collections/ProductInventoryCheckingCollection";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
	CheckSystemQuantityJson,
	FilterProductInventoryChecking,
	ProductInventoryCheckingJsonAddEdit,
	ProductInventoryMerge,
} from "common/types/ProductInventoryChecking";

const SERVICE_URL = "/productinventorycheckings";

class ProductInventoryCheckingApi extends BaseApi {
	async getList(
		props: GetListProps<FilterProductInventoryChecking>
	): Promise<ProductInventoryCheckingCollection> {
		let collection = new ProductInventoryCheckingCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					id: filters.id > 0 ? filters.id : null,
					status: +filters.status >= 0 ? filters.status : null,
					warehouse_id: filters.warehouse_id > 0 ? filters.warehouse_id : null,
					is_complete: filters.is_complete >= 0 ? filters.is_complete : null,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}
	async getListUser(
		props: GetListProps<FilterProductInventoryChecking>
	): Promise<ProductInventoryCheckingCollection> {
		let collection = new ProductInventoryCheckingCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					id: filters.id > 0 ? filters.id : null,
					status: +filters.status >= 0 ? filters.status : null,
					warehouse_id: filters.warehouse_id > 0 ? filters.warehouse_id : null,
				},
			};

			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/user",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}
	async getDetail(id: number): Promise<ProductInventoryCheckingModel> {
		let item = new ProductInventoryCheckingModel(
			ProductInventoryCheckingModel.getDefaultData()
		);

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new ProductInventoryCheckingModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 204) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async add(
		data: ProductInventoryCheckingJsonAddEdit
	): Promise<ProductInventoryCheckingModel> {
		let item = new ProductInventoryCheckingModel(
			ProductInventoryCheckingModel.getDefaultData()
		);

		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new ProductInventoryCheckingModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(
		data: ProductInventoryCheckingJsonAddEdit
	): Promise<ProductInventoryCheckingModel> {
		let item = new ProductInventoryCheckingModel(
			ProductInventoryCheckingModel.getDefaultData()
		);

		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new ProductInventoryCheckingModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async merge(
		data: ProductInventoryMerge
	): Promise<ProductInventoryCheckingModel> {
		let item = new ProductInventoryCheckingModel(
			ProductInventoryCheckingModel.getDefaultData()
		);

		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().post<any>(
				SERVICE_URL + "/merge",
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new ProductInventoryCheckingModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async checkSystemQuantiry(id: number) {
		try {
			//For create item
			const response = await AxiosClient().get<CheckSystemQuantityJson>(
				SERVICE_URL + "/checksystemquantity/" + id
			);
			if (response.hasOwnProperty("data")) {
				const defaults: CheckSystemQuantityJson = {
					skewed_product: [],
				};

				return response.data as CheckSystemQuantityJson;
			}
		} catch (error) {
			console.log(
				"🚀 ~ ProductInventoryCheckingApi ~ checkSystemQuantiry ~ error:",
				error
			);
		}
	}
}

export default ProductInventoryCheckingApi;
