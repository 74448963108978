import EcomUrlApi from 'common/api/EcomUrlApi';
import EcomUrlModel from 'common/models/EcomUrlModel';

import BaseRepository from './BaseRepository';

class EcomUrlRepository extends BaseRepository<EcomUrlApi> {
  _api: EcomUrlApi | null;

  constructor() {
    super();
    this._api = new EcomUrlApi(true);
  }

  async getOauthUrl(type: number): Promise<EcomUrlModel> {
    return this._api
      ? await this._api.getOauthUrl(type)
      : new EcomUrlModel(EcomUrlModel.getDefaultData());
  }
}

export default EcomUrlRepository;
