import { Button, Divider } from "antd";
import { useTranslation } from "react-i18next";

import {
  IconColumnInsertLeft,
  IconColumnInsertRight,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";

const DataGridEditorHeaderMenu = ({
  onClickEditField,
  onClickDeleteField,
  onClickAddField,
}: {
  onClickEditField: () => void;
  onClickDeleteField: () => void;
  onClickAddField: (v: number) => void;
}) => {
  const { t } = useTranslation();

  const iconProps = { size: 16, className: "mr-2 -mt-0.5" };

  return (
    <div className="w-44 rounded flex flex-col bg-gray-50 border border-gray-300 text-sm">
      <Button
        type="text"
        className="rounded-none text-left"
        onClick={() => onClickEditField()}
      >
        <IconPencil {...iconProps} /> {t("datagrid:editor.header.edit_field")}
      </Button>
      <Button
        type="text"
        className="rounded-none text-left"
        onClick={() => onClickAddField(-1)}
      >
        <IconColumnInsertLeft {...iconProps} />
        {t("datagrid:editor.header.add_field_left")}
      </Button>
      <Button
        type="text"
        className="rounded-none text-left"
        onClick={() => onClickAddField(1)}
      >
        <IconColumnInsertRight {...iconProps} />
        {t("datagrid:editor.header.add_field_right")}
      </Button>
      <Divider className="m-0" />
      <Button
        danger
        type="text"
        className="rounded-none text-left"
        onClick={() => onClickDeleteField()}
      >
        <IconTrash {...iconProps} /> {t("datagrid:editor.header.delete_field")}
      </Button>
    </div>
  );
};

export default DataGridEditorHeaderMenu;
