import ProductCategoryApi from 'common/api/ProductCategoryApi';
import NestedCollection from 'common/collections/NestedCollection';
import ProductCategoryCollection from 'common/collections/ProductCategoryCollection';
import ProductCategoryModel from 'common/models/ProductCategoryModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProductCategoryJsonWithChildren,
  ProductCategoryJsonAddEdit,
  FilterProductCategory,
} from "common/types/ProductCategory";

class ProductCategoryRepository extends BaseRepository<ProductCategoryApi> {
  _api: ProductCategoryApi | null;

  constructor() {
    super();
    this._api = new ProductCategoryApi(true);
  }

  static getDefaultFilters(): FilterProductCategory {
    return {
      page: 1,
      limit: 500,
      sortby: "display_order",
      sorttype: "ASC",
      status: -1,
      keyword: "",
    };
  }

  async getFullNestedItems() {
    return this._api
      ? await this._api.getAll()
      : new NestedCollection<ProductCategoryJsonWithChildren>();
  }

  async getItems(props: GetListProps<FilterProductCategory>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductCategoryCollection();
  }

  async getItem(id: number): Promise<ProductCategoryModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductCategoryModel(ProductCategoryModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductCategoryJsonAddEdit
  ): Promise<ProductCategoryModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductCategoryModel(ProductCategoryModel.getDefaultData());
    }
  }
}

export default ProductCategoryRepository;
