import Dexie from "dexie";

const CURRENT_DB_VERSION = 16;

const db = new Dexie("namperfumeCom");
db.version(CURRENT_DB_VERSION).stores({
  companyresource: "&id, company_id",
  employee: "&id",
  companyoffice: "&id, name",
  companysetting: "&id",
  warehouse: "&id, name",
  store: "&id, name",
  productcategory: "&id, name",
  product: "&id, name, code, status",
  productvariant: "&id, sku, status",
  productvariantcolor: "&id, name",
  productvariantsize: "&id, name",
  customertype: "&id, name",
  shippingcarrier: "&id, name",
});

export default db;
