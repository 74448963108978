import BaseCollection from "./BaseCollection";

import CollectionModel from "common/models/CollectionModel";
import {
  CollectionCollectionJson,
  CollectionJson,
} from "common/types/Collection";

class CollectionCollection extends BaseCollection<
  CollectionJson,
  CollectionModel,
  CollectionCollectionJson
> {
  itemsFromJson(jsonItems: CollectionJson[]): CollectionModel[] {
    return jsonItems.map((item) => new CollectionModel(item));
  }
}
export default CollectionCollection;
