import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import CustomerPoint from 'common/constants/CustomerPoint';
import CustomerPointModel from 'common/models/CustomerPointModel';
import CustomerPointRepository from 'common/repositories/CustomerPointRepository';
import FormSection from 'components/form/FormSection';
import FormSelect from 'components/form/FormSelect';
import LayoutForm from 'components/form/LayoutForm';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomerPointFormHeader from './CustomerPointFormHeader';
import CustomerPointTargetCustomer from './CustomerPointTargetCustomer';

import type { CustomerPointJsonAddEdit } from "common/types/CustomerPoint";
const CustomerPointForm = ({ model }: { model: CustomerPointModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const isEditing = model.id > 0;

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState(model.customer_id);

  const initialValues = useMemo(
    () => ({
      note: model.note,
      value_unsigned: Math.abs(model.value),
      value_multiplier: model.value >= 0 ? 1 : -1,
      status: model.status > 0 ? model.status : CustomerPoint.STATUS_ENABLE,
    }),
    [model]
  );

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: CustomerPointJsonAddEdit = {
        ...CustomerPointModel.getDefaultData(),
        id: model.id,
        customer_id: customerId,
        note: formData.note,
        value: formData.value_unsigned * formData.value_multiplier,
        status: formData.status,
      };

      return submitData;
    },
    [model, customerId]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: CustomerPointModel =
      await new CustomerPointRepository().saveRemote(doPrepareData(formData));
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  let sidebarItems = (
    <>
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={CustomerPointModel.getStatusList()}
      />
    </>
  );

  return (
    <>
      <CustomerPointFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("customerpoint:form.success.update")
            : t("customerpoint:form.success.add")
        }
        redirectOnSuccess={isEditing ? "" : "/customerpoint"}
        error_translate_prefix="customerpoint:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
        id="customerpoint-form"
      >
        <FormSection title={t("customerpoint:form.section_info_general")}>
          <Row>
            <Col md={24} xs={24}>
              <Form.Item label={t("customerpoint:customer")}>
                <CustomerPointTargetCustomer
                  id={customerId}
                  setId={setCustomerId}
                  disabled={isEditing}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                name="value_multiplier"
                label={t("customerpoint:value")}
              >
                <Input.Group compact>
                  <Form.Item noStyle name="value_multiplier">
                    <Select
                      style={{
                        float: "none",
                        display: "inline-block",
                        width: 120,
                        background: "#eeeeee !important",
                      }}
                    >
                      <Select.Option value={1}>
                        {t("customerpoint:point_plus")}
                      </Select.Option>
                      <Select.Option value={-1}>
                        {t("customerpoint:point_minus")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="value_unsigned"
                    required
                    rules={[
                      {
                        required: true,
                        message: t(
                          "customerpoint:form.error.error_value_required"
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ float: "none", width: 150 }}
                      addonAfter={t("customerpoint:point")}
                      controls
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("customerpoint:note")} name="note">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default CustomerPointForm;
