import BaseCollection from "common/collections/BaseCollection";
import {
  LeaveAssignCollectionJson,
  LeaveAssignJson,
} from "common/types/LeaveAssign";
import LeaveApproveModel from "common/models/LeaveAssignModel";
class LeaveAssignCollection extends BaseCollection<
  LeaveAssignJson,
  LeaveApproveModel,
  LeaveAssignCollectionJson
> {
  itemsFromJson(jsonItems: LeaveAssignJson[]): LeaveApproveModel[] {
    return jsonItems.map((item) => new LeaveApproveModel(item));
  }
}

export default LeaveAssignCollection;
