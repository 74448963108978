import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";
import { DeliveryHistoryJson } from "common/types/Delivery";
import { FileJson } from "common/types/File";

class DeliveryHistoryModel
  extends BaseModel
  implements BaseModelJson<DeliveryHistoryJson>
{
  delivery_id: number;
  id: number;
  date_created: number;
  action_code: string;
  latitude: number;
  longitude: number;
  creator: number;
  note: string;
  status: number;
  image: FileJson[];

  constructor(json: DeliveryHistoryJson) {
    super();
    this.delivery_id = json.delivery_id || 0;
    this.id = json.id || 0;
    this.date_created = json.date_created || 0;
    this.action_code = json.action_code || "";
    this.latitude = json.latitude || 0;
    this.longitude = json.longitude || 0;
    this.image = json.image || [];
    this.creator = json.creator || 0;
    this.note = json.note || "";
    this.status = json.status;
  }

  static getDefaultData(): DeliveryHistoryJson {
    return {
      delivery_id: 0,
      id: 0,
      date_created: 0,
      action_code: "",
      latitude: 0,
      longitude: 0,
      image: [],
      creator: 0,
      note: "",
      status: 0,
    };
  }

  toJson(): DeliveryHistoryJson {
    return {
      delivery_id: this.delivery_id,
      id: this.id,
      date_created: this.date_created,
      action_code: this.action_code,
      latitude: this.latitude,
      longitude: this.longitude,
      image: this.image,
      creator: this.creator,
      note: this.note,
      status: this.status,
    };
  }
}

export default DeliveryHistoryModel;
