import { List } from "antd";
import LogAuditModel from "common/models/LogAuditModel";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import TextUser from "components/TextUser";
import UserIdAvatar from "components/UserIdAvatar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const LogAuditItem = ({ model }: { model: LogAuditModel }) => {
  const { t } = useTranslation();

  const { text, link } = model.getObjectTextAndLink();

  return (
    <List.Item.Meta
      avatar={<UserIdAvatar id={model.creator_id} />}
      title={
        <span>
          <strong>
            <TextUser id={model.creator_id} />
          </strong>{" "}
          <span style={{ fontWeight: "normal" }}>
            {t("logaudit:text." + model.controller + "." + model.action)}{" "}
            {text.length > 0 ? (
              <>
                {link.length > 0 ? (
                  <Link to={link} className="text-blue-500">
                    {text}
                  </Link>
                ) : (
                  text
                )}
              </>
            ) : null}
          </span>
        </span>
      }
      description={<TextDateTimeRelative ts={model.date_created} />}
    />
  );
};

export default LogAuditItem;
