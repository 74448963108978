import React from "react";
import AcademyList from "../AcademyList/index";
import styles from "./styles.module.css";
import AcademyBanner from "../AcademyBanner/AcademyBanner";
import { useSearchParams } from "react-router-dom";
const AcademyHompage = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  return (
    <div className={styles.academyHomepage}>
      {/* {!page && <AcademyBanner />} */}

      <AcademyList
        grid={{ gutter: 16, xs: 2, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        idCategory={0}
        sizeCard="default"
      />
    </div>
  );
};

export default AcademyHompage;
