import Role from 'common/constants/Role';
import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import RoleCheck from 'components/RoleCheck';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WarehouseListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("warehouse:heading_list")}
      siteMenuSelectedKey="/warehouse"
    >
      <RoleCheck roles={[Role.SETTING_SALE]}>
        <PageHeaderButton link="/warehouse/add">
          {t("common:table.add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default WarehouseListHeader;
