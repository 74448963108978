import BaseModel from 'common/models/BaseModel';
import dayjs, { Dayjs } from 'dayjs';

import type {
  BiCenterJson,
  PresetDateRange,
  RowData,
} from "common/types/BiCenter";
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

class BiCenterModel extends BaseModel {
  columns: string[];
  start: number;
  end: number;
  startcompare: number;
  endcompare: number;
  havecompare: boolean;
  rows: RowData[];
  rows_compare: RowData[];

  constructor(json: BiCenterJson) {
    super();

    this.columns = json.columns || [];
    this.start = json.start || 0;
    this.end = json.end || 0;
    this.startcompare = json.startcompare || 0;
    this.endcompare = json.endcompare || 0;
    this.havecompare = json.havecompare || false;
    this.rows = json.rows || [];
    this.rows_compare = json.rows_compare || [];
  }

  static getDefaultData(): BiCenterJson {
    return {
      columns: [],
      start: 0,
      end: 0,
      startcompare: 0,
      endcompare: 0,
      havecompare: false,
      rows: [],
      rows_compare: [],
    };
  }

  toJson(): BiCenterJson {
    return {
      columns: this.columns,
      start: this.start,
      end: this.end,
      startcompare: this.startcompare,
      endcompare: this.endcompare,
      havecompare: this.havecompare,
      rows: this.rows,
      rows_compare: this.rows_compare,
    };
  }

  static momentToString(value: Dayjs): string {
    return value.format(DATE_FORMAT);
  }

  static stringToMoment(valueString: string): Dayjs {
    return dayjs(valueString, DATE_FORMAT);
  }

  static getPresetDateRangePicker(): Readonly<PresetDateRange>[] {
    return [
      {
        id: "today",
        range: [dayjs().startOf("day"), dayjs().endOf("day")],
      },
      {
        id: "yesterday",
        range: [
          dayjs().subtract(1, "days").startOf("day"),
          dayjs().subtract(1, "days").endOf("day"),
        ],
      },
      {
        id: "lastweek",
        range: [
          dayjs().subtract(1, "weeks").startOf("week"),
          dayjs().subtract(1, "weeks").endOf("week"),
        ],
      },
      {
        id: "lastmonth",
        range: [
          dayjs().subtract(1, "months").startOf("month"),
          dayjs().subtract(1, "months").endOf("month"),
        ],
      },
      {
        id: "last7days",
        range: [
          dayjs().subtract(7, "days").startOf("day"),
          dayjs().subtract(1, "days").endOf("day"),
        ],
      },
      {
        id: "last30days",
        range: [
          dayjs().subtract(30, "days").startOf("day"),
          dayjs().subtract(1, "days").endOf("day"),
        ],
      },
      {
        id: "custom",
        range: [dayjs(), dayjs()],
      },
    ];
  }

  static getComparePreset(): string[] {
    return ["previousperiod", "previousyear"];
  }
}

export default BiCenterModel;
