import Role from "common/constants/Role";
import TableLinkText from "components/table/TableLinkText";
import React, { useMemo } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const ColumnHeaderItem = ({
  receipt_id,
  type,
  warehouse_id,
}: {
  receipt_id: number;
  type: string;
  warehouse_id: number;
}) => {
  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );

  const havePermission = useMemo(() => {
    return hasRoleAndLimit(Role.INVENTORY_VIEW, warehouse_id);
  }, [hasRoleAndLimit, warehouse_id]);
  console.log(havePermission);
  return (
    <div className="text-center">
      {type === "to" ? <div>{"Phiếu nhập"}</div> : <div>{"Phiếu xuất"}</div>}
      {receipt_id > 0 ? (
        <div className="font-normal">
          {havePermission ? (
            <TableLinkText link={"/productreceipt/edit/id/" + receipt_id}>
              {receipt_id}
            </TableLinkText>
          ) : (
            <>{receipt_id}</>
          )}
        </div>
      ) : (
        <div className="text-red-500 font-medium">[{"Chưa thêm"}]</div>
      )}
    </div>
  );
};

export default ColumnHeaderItem;
