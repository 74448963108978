import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import SimpleFormSelect from "components/form/SimpleFormSelect";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const TaxInvoiceFormSectionStatus = ({
  status,
  setStatus,
  initStatus
}: {
  status: number;
  setStatus: (v: number) => void;
  initStatus: number;
}) => {
  const { t } = useTranslation();

  const backgroundColor = useMemo(() => {
    const statusInfo = TaxInvoiceModel.getStatus(status);
    let color = statusInfo?.color;

    return color;
  }, [status]);

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="px-4 py-2 mb-0 -m-6">
      <SimpleFormSelect
        value={status}
        label={
          <span className="text-white">{t("taxinvoice:status_long")}</span>
        }
        size="large"
        options={TaxInvoiceModel.getStatusList()}
        className="mb-2"
        onChange={(v) => (typeof v === "number" ? setStatus(v) : null)}
      />
    </div>
  );
};

export default TaxInvoiceFormSectionStatus;
