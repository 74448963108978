import DmsNewsCategoryApi from 'common/api/DmsNewsCategoryApi';
import DmsNewsCategoryCollection from 'common/collections/DmsNewsCategoryCollection';
import NestedCollection from 'common/collections/NestedCollection';
import DmsNewsCategoryModel from 'common/models/DmsNewsCategoryModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  DmsNewsCategoryJsonAddEdit,
  DmsNewsCategoryJsonWithChildren,
  FilterDmsNewsCategory,
} from "common/types/DmsNewsCategory";
class DmsNewsCategoryRepository extends BaseRepository<DmsNewsCategoryApi> {
  _api: DmsNewsCategoryApi | null;

  constructor() {
    super();
    this._api = new DmsNewsCategoryApi(true);
  }

  static getDefaultFilters(): FilterDmsNewsCategory {
    return {
      page: 1,
      limit: 500,
      sortby: "display_order",
      sorttype: "ASC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterDmsNewsCategory>) {
    return this._api
      ? await this._api.getList(props)
      : new DmsNewsCategoryCollection();
  }

  async getFullNestedItems() {
    return this._api
      ? await this._api.getAll()
      : new NestedCollection<DmsNewsCategoryJsonWithChildren>();
  }

  async getItem(id: number): Promise<DmsNewsCategoryModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new DmsNewsCategoryModel(DmsNewsCategoryModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: DmsNewsCategoryJsonAddEdit
  ): Promise<DmsNewsCategoryModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new DmsNewsCategoryModel(DmsNewsCategoryModel.getDefaultData());
    }
  }
}

export default DmsNewsCategoryRepository;
