import { Spin } from "antd";
import { IdFetcherResult } from "common/types/IdFetcher";
import React from "react";

type K = keyof IdFetcherResult;
const IdFetcherRender = ({
  value,
  mapping,
  key_value,
}: {
  value: number;
  mapping?: IdFetcherResult[];
  key_value?: K;
}) => {
  if (typeof mapping === "undefined") {
    return <Spin size="small" />;
  } else {
    if (key_value && mapping && mapping.length > 0) {
      const findMapping = mapping.filter((i) => i.value === value)[0];

      // if (key_value === "text") {
      //   return <>{findMapping.text}</>;
      // }
      if (findMapping && findMapping.hasOwnProperty(key_value)) {
        return <>{findMapping[key_value]}</>;
      } else {
        return <>Trống</>;
        // return <>{findMapping[key_value]}</>;
      }
    }

    return <>{mapping.find((i) => i.value === value)?.text || value}</>;
  }
};

export default IdFetcherRender;
