import BaseCollection from "./BaseCollection";

import TaskModel from "common/models/TaskModel";
import { TaskCollectionJson, TaskJson } from "common/types/Task";

class TaskCollection extends BaseCollection<
  TaskJson,
  TaskModel,
  TaskCollectionJson
> {
  itemsFromJson(jsonItems: TaskJson[]): TaskModel[] {
    return jsonItems.map((item) => new TaskModel(item));
  }
}
export default TaskCollection;
