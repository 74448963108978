import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const ProductListHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("product:heading_list")}
      siteMenuSelectedKey="/product"
      siteMenuOpenKey="product">
      {children}
    </PageHeader>
  );
};

export default ProductListHeader;
