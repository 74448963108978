import EcomModel from 'common/models/EcomModel';
import EcomRepository from 'common/repositories/EcomRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import EcomChannelProductList from 'features/ecomchannelproduct/list/EcomChannelProductList';
import EcomChannelProductListHeader from 'features/ecomchannelproduct/list/EcomChannelProductListHeader';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

import NotFoundPage from './NotFoundPage';

const EcomChannelProductPage = () => {
  const [pathParams] = usePathParams();

  const [model, setModel] = useState<EcomModel>(
    new EcomModel(EcomModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new EcomModel(EcomModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;
  switch (pathParams.action) {
    case "index":
      com =
        model.id > 0 ? (
          <EcomChannelProductList channel_id={model.id} model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new EcomRepository().getItem(
                parseInt(pathParams.channel.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={
              <EcomChannelProductListHeader channel_id={+pathParams.channel} />
            }
          />
        );

      break;
    default:
      com = <NotFoundPage />;
  }

  return com;
};

export default EcomChannelProductPage;
