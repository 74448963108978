import { CustomerTypeJson } from "common/types/CustomerType";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";

const CustomerTypeFormSelect = ({
  label,
  name,
  disabled,
  placeholder,
  className,
  popupMatchSelectWidth,
}: {
  label: string;
  name: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  popupMatchSelectWidth?: boolean;
}) => {
  const [localItems] = useDatabaseTable<CustomerTypeJson>("customertype");
  const displayOptions: SelectOption[] = localItems
    .sort((a, b) => {
      return a.id > b.id ? -1 : 1;
    })
    .map((item) => ({
      value: item.id,
      label: item.name,
    }));

  return (
    <FormSelect
      allowClear
      disabled={disabled}
      showSearch
      label={label}
      name={name}
      options={displayOptions}
      placeholder={placeholder}
      className={className}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
};

export default CustomerTypeFormSelect;
