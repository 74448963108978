import { Col, Form, Input, Row } from "antd";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingHubRepository from "common/repositories/ShippingHubRepository";
import { ShippingHubSelectOption } from "common/types/ShippingHub";
import FormSelect from "components/form/FormSelect";
import SimpleCollapse from "components/SimpleCollapse";
import ShippingTypeFormSelect from "features/shippingtype/ShippingTypeFormSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ShippingOrderFormSectionFrom = ({
  model
}: {
  model: ShippingOrderModel;
}) => {
  const { t } = useTranslation();
  const [loadingHub, setLoadingHub] = useState<boolean>(true);
  const [hubOptions, setHubOptions] = useState<ShippingHubSelectOption[]>([]);

  const fetchShippingHub = useCallback(async () => {
    const hubCollection = await new ShippingHubRepository().getItems({
      filters: {
        ...ShippingHubRepository.getDefaultFilters(),
        sortby: "display_order"
      }
    });
    if (!hubCollection.hasError()) {
      setHubOptions(
        hubCollection.items.map((item) => ({
          value: item.id,
          label: item.name,
          lat: item.lat,
          long: item.long,
          region_id: item.region_id,
          sub_region_id: item.sub_region_id,
          third_region_id: item.third_region_id
        }))
      );
    }
    setLoadingHub(false);
  }, []);
  useEffect(() => {
    if (loadingHub) {
      fetchShippingHub();
    }
  }, [loadingHub, fetchShippingHub]);
  return (
    <>
      <SimpleCollapse
        header={t("shippingorder:orderform_heading_from")}
        defaultOpen={true}
        className="mb-4"
        childrenItem={
          <Row gutter={32}>
            <Col xs={24} md={8} lg={8}>
              <FormSelect
                label={t("shippingorder:hub")}
                name="office_id"
                required
                rules={[
                  {
                    required: true,
                    message: t(
                      "shippingorder:form.error.error_office_id_required"
                    )
                  }
                ]}
                allowClear
                showSearch
                options={hubOptions}
                placeholder={t("shippingorder:hub_placeholder")}
                popupMatchSelectWidth={false}
              />
            </Col>
            <Col xs={24} md={8} lg={8}>
              <ShippingTypeFormSelect
                label={t("shippingorder:shipping_type")}
                name="shipping_type"
                placeholder={t("shippingorder:shipping_type_placeholder")}
              />
            </Col>
            <Col xs={24} md={8} lg={8}>
              <Form.Item
                label={t("shippingorder:random_code")}
                name="random_code">
                <Input
                  placeholder={t("shippingorder:random_code_placeholder")}
                />
              </Form.Item>
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default ShippingOrderFormSectionFrom;
