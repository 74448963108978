import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
	isEditing: boolean;
	type?: "mine" | "default" | "manager" | "accountant";
};

const DeliveryDetailHeader = ({ isEditing, type }: Props) => {
	const { t } = useTranslation();

	const nav: Nav = [
		type === "manager"
			? {
					text: t("delivery:heading_manager"),
					link: "/delivery/manager",
			  }
			: type === "accountant"
			? {
					text: t("delivery:heading_accountant"),
					link: "/delivery/accountant",
			  }
			: {
					text: t("delivery:waiting.heading_nav"),
					link: "/delivery/waiting",
			  },
	];

	return (
		<PageHeader
			heading={
				type === "default"
					? "Thông tin vận đơn"
					: t(
							isEditing
								? "delivery:waiting.heading_edit"
								: "delivery:waiting.heading_add"
					  )
			}
			nav={nav}
			siteMenuSelectedKey={
				type === "manager"
					? "/delivery/manager"
					: type === "accountant"
					? "/delivery/accountant"
					: "/delivery"
			}
			siteMenuOpenKey="delivery"
			siteMenuHidden></PageHeader>
	);
};

export default DeliveryDetailHeader;
