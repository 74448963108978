import { Button, Col, Popover, Row, Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { SearchResultItem, SearchResultItemQuery } from "common/types/Pos";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import dbm from "dbm";
import escapeStringRegexp from "escape-string-regexp";
import debounce from "lodash/debounce";
import React, {
  KeyboardEvent,
  UIEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  IconBan,
  IconBarcode,
  IconFolder,
  IconSearch,
} from "@tabler/icons-react";

import ProductVariantWidget from "./widget/ProductVariantWidget";
import ProductRepository from "common/repositories/ProductRepository";
import {
  FilterProduct,
  ProductJson2,
  ProductJsonPublic,
} from "common/types/Product";

const ProductVariantSearch = ({
  disabled,
  placeholder,
  size,
  onSelect,
  disableWidget,
  loading,
  popoverWidthClassName,
  filterBySupplierId,
  filter,
}: {
  disabled?: boolean;
  disableWidget?: boolean;
  placeholder?: string;
  size?: SizeType;
  onSelect: (productVariant: ProductJsonPublic) => void;
  loading?: boolean;
  popoverWidthClassName?: string;
  filterBySupplierId?: number;
  filter?: Partial<FilterProduct>;
}) => {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const containerCategoryRef = useRef<any>(null);
  const lastKeywordRef = useRef("");
  const recordPerPage = 10;
  const currentPageRef = useRef(1);

  const [items, setItems] = useState<ProductJsonPublic[]>([]);

  const doSelect = useCallback(
    (productVariantId: number) => {
      if (typeof onSelect !== "undefined" && productVariantId > 0) {
        const item = items.find(item=>item.id === productVariantId)
        if(item){
          onSelect(item);

        }
      }
    },
    [items, onSelect]
  );

  const doSearch = useCallback(
    async (query: string, isPressEnter: boolean) => {
      query = query.trim();
      setKeyword(query);

      const res = await new ProductRepository().getItemsPublic(
        {
          filters: {
            ...ProductRepository.getDefaultFilters(),
            ...filter,
            keyword: query,
          },
        },
        []
      );

      if (!res.hasError()) {
        setItems(res.toJson().items);
      }
    },
    [filter]
  );

  // const onSelectFromWidget = useCallback(
  //   (product_variant_id: number) => {
  //     onSelect(product_variant_id);
  //   },
  //   [onSelect]
  // );

  const handlePopupScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const target = e.target;
      if (
        (target as any).scrollTop + (target as any).offsetHeight ===
        (target as any).scrollHeight
      ) {
        // if not load all;
        if (items.length % recordPerPage === 0) {
          //can be still have search result
          currentPageRef.current++;
          doSearch(lastKeywordRef.current, false);
        }
      }
    },
    [items, doSearch]
  );

  const handleInputKeydown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        doSearch(e.currentTarget.value, true);
      }
    },
    [doSearch]
  );

  const handleFocus = useCallback(() => {
    doSearch("", false);
  }, [doSearch]);

  return (
    <div>
      <Row className="">
        <Col flex={"auto"}>
          <Select
            showArrow={true}
            suffixIcon={<IconSearch size={18} color="#dddddd" />}
            bordered={true}
            loading={loading}
            disabled={disabled}
            autoClearSearchValue
            placeholder={placeholder ?? t("productvariant:search_placeholder")}
            onInputKeyDown={handleInputKeydown}
            size={size}
            style={{ width: "100%" }}
            showSearch
            value={null}
            onSearch={debounce((value) => doSearch(value, false), 500)}
            filterOption={false}
            dropdownRender={(menu) => <>{menu}</>}
            onSelect={(selectedId) => {
              doSelect(selectedId ?? 0);
            }}
            onFocus={handleFocus}
            onPopupScroll={handlePopupScroll}
            notFoundContent={
              <>
                {keyword.length > 0 ||
                typeof filterBySupplierId === "number" ? (
                  <>
                    <IconBan size={24} className="-mt-0.5 mr-1" />
                    {t("productvariant:search_notfound_empty")}
                  </>
                ) : (
                  <>
                    <IconBarcode size={24} className="-mt-0.5 mr-1" />
                    {t("productvariant:search_notfound_empty_keyword")}
                  </>
                )}
              </>
            }
          >
            {items.map((item, index) => (
              <Select.Option key={item.id} value={item.id}>
                <span className="text-right inline-block text-gray-400 text-xs w-5">
                  {index + 1}.
                </span>{" "}
                {item.name} |{" "}
                <span className="font-bold">{item.option_name}</span> -{" "}
                <span className="font-bold">{item.sku}</span> -{" "}
                {/* <span className="text-gray-400">
                  {t("productvariant:color_short")}:
                </span>{" "} */}
                {/* <TextProductVariantColor id={item.color} />{" "}
                <span className="text-gray-400">
                  {t("productvariant:size_short")}:{" "}
                </span>
                <TextProductVariantSize id={item.size} /> */}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {typeof disableWidget === "undefined" && !disableWidget ? (
          <Col
            flex={"40px"}
            className="text-right rounded-tr-sm rounded-br-sm bg-gray-200"
          >
            <div>
              <Popover
                open={
                  typeof disabled !== "undefined" && disabled
                    ? false
                    : undefined
                }
                placement="bottomRight"
                // content={
                //   <ProductVariantWidget
                //     onSelect={onSelectFromWidget}
                //     filterBySupplierId={filterBySupplierId}
                //   />
                // }
                trigger="click"
                overlayClassName={
                  (popoverWidthClassName || "w-11/12") +
                  " ant-popover-inner-nopadding"
                }
                getPopupContainer={() => containerCategoryRef.current}
              >
                <Button
                  disabled={disabled}
                  className=""
                  shape="default"
                  style={{ width: 40 }}
                  type="text"
                  size={size}
                  icon={
                    <IconFolder className="-mt-0.5 text-gray-400 hover:text-blue-400" />
                  }
                />
              </Popover>
            </div>
          </Col>
        ) : null}
      </Row>

      <div className="" ref={containerCategoryRef}></div>
    </div>
  );
};

export default ProductVariantSearch;
