import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

import Collection from "common/constants/Collection";
import CollectionModel from "common/models/CollectionModel";
import CollectionRepository from "common/repositories/CollectionRepository";
import { Filter } from "common/types/Filter";
import { SelectOption } from "common/types/SelectOption";

import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import FormSelectNormal from "components/form/FormSelectNormal";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import TagSingleFormSelect from "./TagSingleFormSelect";

const StoryListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: any;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[
          "collection_id",
          "type",
          "publish_from",
          "publish_to",
        ]}
      >
        <DatePickerRangeFilter
          filters={filters}
          setFilters={setFilters}
          keyEnd="publish_to"
          keyStart="publish_from"
        />
        <Form.Item name="keyword">
          <Input allowClear placeholder={t("Nhập tiêu đề")} />
        </Form.Item>
        {/* <FormSelectNormal
          name="collection_id"
          placeholder={t("story:filter.category")}
          options={categoryList}
          allowClear
          popupMatchSelectWidth={false}
        /> */}
        <FormSelectNormal
          name="type"
          placeholder={t("story:filter.type")}
          options={CollectionModel.getCollectionTypeList()}
          allowClear
          popupMatchSelectWidth={false}
        />

        <TagSingleFormSelect
          existValue={filters.tags}
          changeDataProductCollection={() => {}}
          required={false}
        />
      </PageDataFilterForm>
    </>
  );
};

export default StoryListFilter;
