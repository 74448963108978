import { Col, Row, Space, message } from "antd";
import DeliveryRepository from "common/repositories/DeliveryRepository";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeliveryStatisic from "./DeliveryStatisic";
import DeliverySumbyEmployeeModel from "common/models/DeliverySumbyEmployeeModel";
import DeliverySumbyEmployeeRepository from "common/repositories/DeliverySumbyEmployeeRepository";
import { FilterDeliverySumByEmployee } from "common/types/DeliverySumbyEmployee";

const DeliverySumByShipper = ({
  actorid = 0,
  defaultFilters,
  setFilters,
  filters,
}: {
  actorid?: number;
  defaultFilters: FilterDeliverySumByEmployee;
  setFilters: any;
  filters: FilterDeliverySumByEmployee;
}) => {
  const { t } = useTranslation();

  //
  const [dataSumBy, setDataSumBy] = useState<DeliverySumbyEmployeeModel>(
    new DeliverySumbyEmployeeModel(DeliverySumbyEmployeeModel.getDefaultData())
  );

  const [error, setError] = useState<null | string>(null);

  //sumby

  const fetchData = async () => {
    const collection =
      await new DeliverySumbyEmployeeRepository().getSumByEmployee(filters);
    if (collection) {
      setDataSumBy(collection);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  return (
    <div className="">
      <Space
        style={{
          margin: "24px 16px 0px",
          height: "auto",
          display: "block",
        }}
      >
        {/* {error != null ? ( */}

        {/* <DeliverySumByFilter
            defaultFilters={defaultFiltersSumBy}
            setFilters={setFiltersSumBy}
            is_keyWord={false}
            filters={filtersSumBy}
            is_rangeDate={true}
            is_leaveType={false}
            is_department={false}
            className="m-2"
          ></DeliverySumByFilter> */}
      </Space>
      <Row gutter={[16, 16]} className="p-5">
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title="Tổng tip"
            count={dataSumBy.tips_count}
            total={dataSumBy.tips_sum}
            explainContent="Tổng tiền tip vận đơn shipper đã nhận"
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title="Tổng vận đơn đã vận chuyển"
            count={dataSumBy.totalcod_count}
            total={dataSumBy.totalcod_sum}
            explainContent="Tổng giá trị vận đơn shipper đã nhận"
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title="Tổng chuyển khoản"
            count={dataSumBy.transfer_count}
            total={dataSumBy.transfer_sum}
            explainContent="Tổng tiền chuyển khoản vận đơn của shipper mà kế toán đã nhận"
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title="Tổng thu hộ ghi nhận"
            count={dataSumBy.current_count}
            total={dataSumBy.current_sum}
            explainContent="Tổng tiền thu hộ của vận đơn shipper đã nhận"
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title="Tổng đã thu của khách hàng"
            count={dataSumBy.returned_count}
            total={dataSumBy.returned_sum}
            explainContent="Tổng tiền thu hộ vận đơn shipper đã thu khách hàng"
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title="Tổng phải thu khách hàng"
            count={dataSumBy.receiving_count}
            total={dataSumBy.receiving_sum}
            explainContent="Tổng thu hộ vận đơn shipper phải thu khách hàng"
          />
        </Col>
      </Row>

      {/* ) : (
            <Result
              status="500"
              title="500"
              subTitle={t("delivery:list.item.error.reload")}
            />
          )} */}
      {/* </Space> */}
    </div>
  );
};

export default DeliverySumByShipper;
