import BaseCollection from "common/collections/BaseCollection";
import PositionModel from "common/models/PositionModel";
import { PositionJson } from "common/types/Position";
import { PositionCollectionJson } from "common/types/Position";

class PositionCollection extends BaseCollection<
  PositionJson,
  PositionModel,
  PositionCollectionJson
> {
  itemsFromJson(jsonItems: PositionJson[]): PositionModel[] {
    return jsonItems.map((item) => new PositionModel(item));
  }
}

export default PositionCollection;
