import PurchaseOrderDetailApi from "common/api/PurchaseOrderDetailApi";
import PurchaseOrderDetailCollection from "common/collections/PurchaseOrderDetailCollection";

import BaseRepository from "./BaseRepository";

class PurchaseOrderDetailRepository extends BaseRepository<PurchaseOrderDetailApi> {
  _api: PurchaseOrderDetailApi | null;

  constructor() {
    super();
    this._api = new PurchaseOrderDetailApi(true);
  }

  async getItemsOfPurchaseOrder(purchase_order_id: number) {
    return this._api
      ? await this._api.getListOfPurchaseOrder(purchase_order_id)
      : new PurchaseOrderDetailCollection();
  }

  async getFullDetailOfPurchaseOrder(purchase_order_id: number) {
    return this._api
      ? await this._api.getFullDetailOfPurchaseOrder(purchase_order_id)
      : new PurchaseOrderDetailCollection();
  }
}

export default PurchaseOrderDetailRepository;
