import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconHourglass } from "@tabler/icons-react";

const InventoryBySkuHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("inventory:heading_list")}
      siteMenuOpenKey="inventory"
      siteMenuSelectedKey="/inventory"
    >
      <PageHeaderButton
        type="default"
        link="/inventory/waiting"
        icon={<IconHourglass size="18" className="mr-1" />}
      >
        {t("inventory:waiting_sync_list")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default InventoryBySkuHeader;
