import { Modal, Skeleton } from "antd";
import WorkTrackingRangeModel from "common/models/WorkTrackingRangeModel";
import WorkTrackingRangeRepository from "common/repositories/WorkTrackingRangeRepository";
import ErrorAlert from "components/ErrorAlert";
import WorkTrackingRangeList from "features/worktrackingrange/list/WorkTrackingRangeList";
import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WorkTrackingRangeFormModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (b: boolean) => any;
}) => {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState<WorkTrackingRangeModel[]>([]);
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState<string[]>([]);

  const fetchData = useCallback(async () => {
    const fetchedCollection = await new WorkTrackingRangeRepository().getItems({
      filters: WorkTrackingRangeRepository.getDefaultFilters(),
    });
    setLoading(false);

    if (fetchedCollection.hasError()) {
      setErrors(fetchedCollection.error.errors);
    } else {
      let foundItems = fetchedCollection.items;

      //set data for editor
      foundItems.sort((a, b) => {
        if (a.time_start > b.time_start) {
          return 1;
        }
        if (a.time_start < b.time_start) {
          return -1;
        }
        return 0;
      });

      setDataSource(foundItems);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  const onSaveSuccess = (item: WorkTrackingRangeModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $push: [item],
        })
      );
    }
  };

  return (
    <Modal
      title={t("worktrackingrange:heading_list")}
      open={open}
      closable={true}
      maskClosable={true}
      onCancel={() => {
        setOpen(false);
      }}
      width={1000}
      footer={null}
      destroyOnClose
    >
      <>
        {!loading || errors.length > 0 ? (
          <>
            {errors.length > 0 ? (
              <ErrorAlert
                items={errors}
                heading={t("common:error.error_fetching_data")}
                onRetry={() => {
                  setErrors([]);
                  setLoading(true);
                  fetchData();
                }}
              />
            ) : (
              <>
                <WorkTrackingRangeList
                  dataSource={dataSource}
                  handleDelete={(id: number) => {
                    setDataSource(dataSource.filter((item) => item.id !== id));
                  }}
                  onSaveSuccess={onSaveSuccess}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Skeleton active />
          </>
        )}
      </>
    </Modal>
  );
};

export default WorkTrackingRangeFormModal;
