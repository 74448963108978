import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

type PosStore = {
  height: number;
  width: number;
  setWidthHeight: (width: number, height: number) => void;
  enableSoundFx: boolean;
  setEnableSoundFx: (v: boolean) => void;
};

//init store data
let store: StateCreator<PosStore> = (set) => ({
  height: 0,
  width: 0,
  setWidthHeight: (width, height) =>
    set((state) => ({ ...state, width, height })),
  enableSoundFx: false,
  setEnableSoundFx: (enableSoundFx) =>
    set((state) => ({ ...state, enableSoundFx })),
});

//create store
const usePosStore = create(persist(store, { name: "MAIN::Pos" }));

export default usePosStore;
