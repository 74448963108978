import DmsNewsModel from 'common/models/DmsNewsModel';
import DmsNewsRepository from 'common/repositories/DmsNewsRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import DmsNewsFormEditor from 'features/dmsnews/form/DmsNewsFormEditor';
import DmsNewsFormEditorHeader from 'features/dmsnews/form/DmsNewsFormEditorHeader';
import DmsNewsList from 'features/dmsnews/list/DmsNewsList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const DmsNewsPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<DmsNewsModel>(
    new DmsNewsModel(DmsNewsModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new DmsNewsModel(DmsNewsModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <DmsNewsFormEditor
          model={new DmsNewsModel(DmsNewsModel.getDefaultData())}
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <DmsNewsFormEditor model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new DmsNewsRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<DmsNewsFormEditorHeader isEditing={true} />}
          />
        );

      break;
    default:
      com = <DmsNewsList />;
  }

  return com;
};

export default DmsNewsPage;
