import BaseApi from "common/api/BaseApi";
import AccountModel from "common/models/AccountModel";
import { AccountJson } from "common/types/Account";
import create, { StateCreator } from "zustand";
import { devtools, persist } from "zustand/middleware";

type LoginAccountStore = {
  account: AccountJson;
  setAccount: (v: AccountJson) => void;
  hasRole: (v: number) => boolean;
  getRoleObjectList: (v: number) => number[];
  hasRoleAndLimit: (role: number, objectId: number) => boolean;
  initialPathName: string;
  setInitialPathName: (initial: string) => void;
};

//init store data
let store: StateCreator<LoginAccountStore> = (set, get) => ({
  account: new AccountModel(AccountModel.getDefaultData()),
  setAccount: (account) => {
    //Set account for baseApi (use jwt token)
    BaseApi.loginAccount = new AccountModel(account);

    return set((state) => ({ ...state, account }));
  },
  hasRole: (subject_id: number) =>
    typeof get().account.role.find((r) => r.subject_id === subject_id) !==
    "undefined",
  getRoleObjectList: (subject_id: number) => {
    const foundRole = get().account.role.find(
      (r) => r.subject_id === subject_id
    );
    if (typeof foundRole !== "undefined") {
      return foundRole.id_list;
    } else {
      return [];
    }
  },
  hasRoleAndLimit(role, objectId) {
    let pass = false;
    if (get().hasRole(role)) {
      const limitObjectIdList = get().getRoleObjectList(role);
      console.log(limitObjectIdList)
      pass =
        limitObjectIdList.length === 0 || limitObjectIdList.includes(objectId);
    }

    return pass;
  },
  initialPathName: "/",

  setInitialPathName: (initial: string) =>
    set((state) => ({ ...state, initialPathName: initial })),
});

//create store

//create store
const useLoginAccountStore = create(
  persist(store, {
    name: "MAIN::LoginAccount",
    onRehydrateStorage: (state) => {
      return (state) => {
        state?.setAccount(state.account);
      };
    },
  })
);

export default useLoginAccountStore;
