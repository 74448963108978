import { useTranslation } from "react-i18next";

import { Filter } from "common/types/Filter";

import FilterBody from "components/filter/FilterBody";
import { SelectOptionTask } from "./TaskDeptKanbanComponent";
import FormSelectNormal from "components/form/FormSelectNormal";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import useFilterLocation from "hooks/useFilterLocation";

const TaskDeptKanbanFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
  employee_list,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  employee_list: SelectOptionTask[];
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      total={total}
      filters={filters}
      setFilters={setFilters}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={["date_from", "date_to"]}
    >
   
        <DatePickerRangeFilter
          filters={filters}
          setFilters={setFilters}
          keyEnd="date_to"
          keyStart="date_from"
        />
        <FormSelectNormal
          onChange={(value) => {
            setFilters({ ...filters, list_employee_id: value?.toString() });
          }}
          allowClear
          options={employee_list}
          placeholder={"Chọn nhân viên"}
          multiple
        />
  
    </PageDataFilterForm>
  );
};

export default TaskDeptKanbanFilter;
