import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import React from "react";

import ReportChartDataTable from "../chart/ReportChartDataTable";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportCustomerOrigin = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartTimeSeries
              className="p-4"
              title={"Số khách hàng theo nguồn"}
              dataTable="customer"
              dataService="customer"
              dataSelect="count(id), origin"
              dataFilter=""
              dataGroupBy="origin"
              dataOrderBy="count(id) desc"
              keyMapping={{ name: "origin", value: "count_id" }}
              height={400}
              dataInterval=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo khách hàng theo nguồn khách hàng"}
              dataTable="customer"
              dataService="customer"
              keyMapping={[
                {
                  title: "Nguồn khách hàng",
                  name: "origin",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  }
                },
                {
                  title: "Số khách hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                },
                {
                  title: "Tổng số đơn hàng",
                  name: "sum_countorder",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(countorder)"
                },
                {
                  title: "Tổng giá trị đơn hàng",
                  name: "sum_money",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(money)"
                }
              ]}
              dataSelect="count(id), origin, sum(countorder), sum(money)"
              dataFilter=""
              dataGroupBy="origin"
              dataOrderBy="count(id) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCustomerOrigin;
