import LogAuditApi from "common/api/LogAuditApi";
import LogAuditCollection from "common/collections/LogAuditCollection";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type { FilterLogAudit } from "common/types/LogAudit";

class LogAuditRepository extends BaseRepository<LogAuditApi> {
	_api: LogAuditApi | null;

	constructor() {
		super();
		this._api = new LogAuditApi(true);
	}

	static getDefaultFilters(): FilterLogAudit {
		return {
			page: 1,
			limit: 20,
			sortby: "id",
			sorttype: "DESC",
			status: -1,
			keyword: "",
			object_id: -1,
			severity: -1,
			controller: "",
			action: "",
			creator_id: -1,
		};
	}

	async getItems(props: GetListProps<FilterLogAudit>) {
		return this._api
			? await this._api.getList(props)
			: new LogAuditCollection();
	}
}

export default LogAuditRepository;
