import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React from "react";

const ReportOrderProductAttributeTitle = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo bán hàng theo thuộc tính tiêu đề"}
              dataTable="orderdetail"
              dataService="orderdetail"
              keyMapping={[
                {
                  title: "Tiêu đề",
                  name: "title",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "title"
                },
                {
                  title: "Tổng thành tiền",
                  name: "sum_subtotal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(subtotal)"
                },
                {
                  title: "Tổng số lượng",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(quantity)"
                }
              ]}
              dataSelect="sum(subtotal), itemtitle, sum(quantity)"
              dataFilter="order_status < 20"
              dataGroupBy="itemtitle"
              dataOrderBy="sum(subtotal) DESC"
              dataJoin="order"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportOrderProductAttributeTitle;
