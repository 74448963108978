import BaseCollection from 'common/collections/BaseCollection';
import EcomSyncProductModel from 'common/models/EcomSyncProductModel';
import { EcomSyncProductCollectionJson, EcomSyncProductJson } from 'common/types/EcomSyncProduct';

class EcomSyncProductCollection extends BaseCollection<
  EcomSyncProductJson,
  EcomSyncProductModel,
  EcomSyncProductCollectionJson
> {
  itemsFromJson(jsonItems: EcomSyncProductJson[]): EcomSyncProductModel[] {
    return jsonItems.map((item) => new EcomSyncProductModel(item));
  }
}

export default EcomSyncProductCollection;
