import { Col, Divider, Row } from "antd";
import useLoginAccountStore from "zustands/useLoginAccountStore";

// import HomeLeftSummary from "./left/HomeLeftSummary";
import HomeLeftNotification from "./left/notificaiton/HomeLeftNotification";
import HomeLeftItem from "./left/HomeLeftItem";
import HomeLeftBirthday from "./left/birthday/HomeLeftBirthday";
import HomeLeftLeaveOff from "./left/leaveoff/HomeLeftLeaveOff";
import HomeLeftLeaveUser from "./left/leaveoff/HomeLeftLeaveUser";
import HomeLeftNewsfeed from "./left/newsfeed/HomeLeftNewsfeed";

const HomeLeft = () => {
  const account = useLoginAccountStore((state) => state.account);

  //////////////////////////////

  return (
    <div className={` h-full mb-2`}>
      <div className="px-4 pt-2 text-gray-400 uppercase font-bold">
        Công ty {account.company.name}
      </div>
      <div className="px-4 text-xl text-gray-800">
        Chào bạn, {account.full_name} 👋
      </div>
      <Divider className="my-2" />
      {/* <HomeLeftSummary /> */}
      <div className="px-4">
        <Row gutter={[8, 8]} className="mt-4">
          <Col span={24} md={16}>
            <HomeLeftItem>
              <HomeLeftNotification />
            </HomeLeftItem>
          </Col>
          <Col span={24} md={8}>
            <HomeLeftItem>
              <HomeLeftBirthday />
            </HomeLeftItem>
          </Col>
        </Row>

        <Row gutter={[8, 8]} className="mt-3">
          <Col span={24} md={8}>
            <HomeLeftItem>
              <HomeLeftNewsfeed />
            </HomeLeftItem>
          </Col>
          <Col span={24} md={8}>
            <HomeLeftItem>
              <HomeLeftLeaveOff />
            </HomeLeftItem>
          </Col>
          <Col span={24} md={8}>
            <HomeLeftItem>
              <HomeLeftLeaveUser />
            </HomeLeftItem>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeLeft;
