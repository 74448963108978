import FullDataCollection from 'common/collections/FullDataCollection';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

import type { FetchingRequest } from "common/types/FullData";

const SERVICE_URL = "/companyresources";

class FullDataApi extends BaseApi {
  async doFetching(props: FetchingRequest): Promise<FullDataCollection> {
    let collection = new FullDataCollection();

    try {
      let queryData = {
        params: {
          resource: props.resources
            .map((item) => item.resource + ":" + item.version)
            .join(","),
          creator_id: BaseApi.loginAccount.id,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/fulldata",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default FullDataApi;
