import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { EcomSyncOrderJson } from 'common/types/EcomSyncOrder';

import BaseModel from './BaseModel';

class EcomSyncOrderModel
  extends BaseModel
  implements BaseModelJson<EcomSyncOrderJson>
{
  id: number;
  invoice_id: string;
  price_final: number;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: EcomSyncOrderJson) {
    super();

    this.id = json.id || 0;
    this.invoice_id = json.invoice_id || "";
    this.price_final = json.price_final || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  toJson(): EcomSyncOrderJson {
    return {
      id: this.id,
      invoice_id: this.invoice_id,
      price_final: this.price_final,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getDefaultData(): EcomSyncOrderJson {
    return {
      id: 0,
      invoice_id: "",
      price_final: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }
}

export default EcomSyncOrderModel;
