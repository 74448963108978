import { App, Button, ButtonProps, Popconfirm, Spin } from "antd";
import Error from "components/LayoutError";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IconTrash } from "@tabler/icons-react";

interface IDeleteItem {
  deleteItem: (id: number) => Promise<string[]>;
}

const TableDelete = ({
  onDeleteCallback,
  onDeleteErrorCallback,
  className,
  id,
  repository,
  disabled,
  error_translate_prefix,
  icon,
  title,
  label,
  buttonBlock,
  buttonSize,
  buttonType,
  block = false,
}: {
  id: number;
  repository: IDeleteItem;
  onDeleteCallback: (id: number) => void;
  onDeleteErrorCallback?: (id: number) => void;
  className?: string;
  disabled?: boolean;
  error_translate_prefix?: string;
  icon?: React.ReactNode;
  title?: string;
  label?: string;
  buttonBlock?: ButtonProps["block"];
  buttonSize?: ButtonProps["size"];
  buttonType?: ButtonProps["type"];
  block?: boolean;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [processing, setProcessing] = useState(false);

  const messageKeyDeletePrefix = "delete_item_";

  const onDeleteItem = async (id: number) => {
    //for null delete (id == 0), we just delete it without call to server
    if (id <= 0) {
      if (onDeleteCallback) {
        onDeleteCallback(id);
      }
    } else {
      setProcessing(true);
      message.loading({
        content: t("common:table.deleting_sid", { sid: id }),
        key: messageKeyDeletePrefix + id,
        duration: 0,
      });

      const deletedErrors = await repository.deleteItem(id);
      setProcessing(false);
      if (deletedErrors.length === 0) {
        message.success({
          content: t("common:table.delete_item_success"),
          className: "message_success",
          key: messageKeyDeletePrefix + id,
          duration: 1,
        });

        if (onDeleteCallback) {
          onDeleteCallback(id);
        }
      } else {
        if (onDeleteErrorCallback) {
          onDeleteErrorCallback(id);
        }
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKeyDeletePrefix + id);
              }}
              heading={t("common:error.table_delete_heading")}
              translate_prefix={error_translate_prefix || ""}
              items={deletedErrors}
            />
          ),
          className: "message_error",
          key: messageKeyDeletePrefix + id,
          duration: 10,
        });
      }
    }
  };

  const comButton = (
    <Button
      disabled={disabled}
      size={buttonSize || "small"}
      type={buttonType || "text"}
      className={className}
      onClick={() => id <= 0 && onDeleteItem(0)}
      block={buttonBlock}
    >
      {icon || (
        <IconTrash
          className="-mt-0.5 text-gray-400 hover:text-red-500"
          size="18"
        />
      )}
      {label}
    </Button>
  );

  //incase null delete (id <= 0) do not use popconfirm
  if (id <= 0) return comButton;

  return (
    <Popconfirm
      title={title || t("common:table.confirm_delete_title")}
      placement="topRight"
      onConfirm={() => {
        onDeleteItem(id);
      }}
      okText={t("common:table.confirm_ok")}
      cancelText={t("common:table.confirm_cancel")}
      disabled={processing || disabled}
    >
      {processing ? <Spin size="small" /> : comButton}
    </Popconfirm>
  );
};

export default TableDelete;
