import SupplierModel from "common/models/SupplierModel";
import SupplierRepository from "common/repositories/SupplierRepository";
import { useCallback, useEffect, useState } from "react";

const TextSupplier = ({ id }: { id: number }) => {
  const [loading, setLoading] = useState(true);
  const [supplierItems, setSupplierItems] = useState<SupplierModel[]>([]);
  const fetchSupplierData = useCallback(async () => {
    const collection = await new SupplierRepository().getItems({
      filters: SupplierRepository.getDefaultFilters(),
    });
    if (!collection.hasError()) {
      setSupplierItems(collection.items);
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    if (loading) {
      fetchSupplierData();
    }
  }, [loading, fetchSupplierData]);

  return <>{supplierItems.find((item) => item.id === id)?.name || "--"}</>;
};

export default TextSupplier;
