import BaseCollection from "common/collections/BaseCollection";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import { LeaveTypeJson, LeaveTypeCollectionJson } from "common/types/LeaveType";

class LeaveTypeCollection extends BaseCollection<
  LeaveTypeJson,
  LeaveTypeModel,
  LeaveTypeCollectionJson
> {
  itemsFromJson(jsonItems: LeaveTypeJson[]): LeaveTypeModel[] {
    return jsonItems.map((item) => new LeaveTypeModel(item));
  }
}

export default LeaveTypeCollection;
