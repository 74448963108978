import BaseCollection from 'common/collections/BaseCollection';
import ProjectTaskChangeModel from 'common/models/ProjectTaskChangeModel';
import {
    ProjectTaskChangeCollectionJson, ProjectTaskChangeJson
} from 'common/types/ProjectTaskChange';

class ProjectTaskChangeCollection extends BaseCollection<
  ProjectTaskChangeJson,
  ProjectTaskChangeModel,
  ProjectTaskChangeCollectionJson
> {
  itemsFromJson(jsonItems: ProjectTaskChangeJson[]): ProjectTaskChangeModel[] {
    return jsonItems.map((item) => new ProjectTaskChangeModel(item));
  }
}

export default ProjectTaskChangeCollection;
