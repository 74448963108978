import {
  Alert,
  App,
  Button,
  Col,
  Empty,
  Form,
  FormInstance,
  Input,
  Popover,
  Row,
  Table,
  Tag,
  Tooltip
} from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import PurchaseOrder from "common/constants/PurchaseOrder";
import Role from "common/constants/Role";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import CashflowReceiptRepository from "common/repositories/CashflowReceiptRepository";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import { StoreJson } from "common/types/Store";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import FormSelect from "components/form/FormSelect";
import MoneyInput from "components/MoneyInput";
import RoleCheck from "components/RoleCheck";
import TableLinkText from "components/table/TableLinkText";
import TextCashflowReceiptMethod from "components/TextCashflowReceiptMethod";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import TextStore from "components/TextStore";
import StoreFormSelect from "features/store/StoreFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

import { IconLink, IconRefresh, IconSquarePlus } from "@tabler/icons-react";

import PurchaseOrderFormSectionCashflowReceiptLinkForm from "./PurchaseOrderFormSectionCashflowReceiptLinkForm";

const PurchaseOrderFormSectionCashflowReceipt = ({
  form
}: {
  form: FormInstance;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const priceFinalValue = Form.useWatch("price_final", form);

  const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
    "store",
    Role.CASHFLOW_ADD
  );
  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );

  const [dataSource, setDataSource] = useState<CashflowReceiptModel[]>([]);
  const [storeId, setStoreId] = useState(0);
  const [addValue, setAddValue] = useState(purchaseOrder.price_final);
  const [addMethod, setAddMethod] = useState(CashflowReceipt.METHOD_BANK);
  const [addNote, setAddNote] = useState("");

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    let collection = await new CashflowReceiptRepository().getItems({
      filters: {
        ...CashflowReceiptRepository.getDefaultFilters(),
        source: CashflowReceipt.SOURCE_PURCHASE_ORDER,
        source_id: purchaseOrder.id
      }
    });

    setLoading(false);
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setDataSource(collection.items);
    }
  }, [purchaseOrder.id]);

  const initFirstCashflowReceipt = useCallback(async () => {
    //check status before init
    if (purchaseOrder.status === PurchaseOrder.STATUS_APPROVED) {
      setProcessing(true);
      setErrors([]);

      const newCashflowReceipt =
        await new PurchaseOrderHelperRepository().initCashflowReceipt({
          store_id: storeId,
          purchase_order_id: purchaseOrder.id,
          note: addNote,
          value: addValue,
          method: addMethod
        });

      setProcessing(false);

      if (newCashflowReceipt.hasError()) {
        setErrors(newCashflowReceipt.error.errors);
      } else {
        //success, reload
        fetchData();
      }
    } else {
      message.error(
        t(
          "purchaseorder:form.error.error_init_first_cashflow_receipt_status_approved_required"
        )
      );
    }
  }, [
    purchaseOrder,
    storeId,
    addNote,
    addMethod,
    addValue,
    t,
    fetchData,
    message
  ]);

  //listen the pricefinal from form, to update addValue
  useEffect(() => {
    setAddValue(priceFinalValue);
  }, [priceFinalValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<CashflowReceiptModel> = [
    {
      title: t("cashflowreceipt:id"),
      dataIndex: "id",
      align: "center",
      fixed: "left",
      width: 120,
      render: (id, record) => {
        return (
          <TableLinkText
            link={"/cashflowreceipt/edit/id/" + record.id}
            target="_blank"
            className="font-bold text-md">
            {id}
          </TableLinkText>
        );
      }
    },

    {
      title: t("cashflowreceipt:filter_store_id"),
      dataIndex: "store_id",
      width: 150,
      render: (store_id: number) => {
        return <TextStore id={store_id} />;
      }
    },
    {
      title: t("cashflowreceipt:name"),
      dataIndex: "name"
    },
    {
      title: t("cashflowreceipt:value"),
      dataIndex: "value",
      align: "right",
      className: "font-bold",
      width: 120,
      render: (value: number) => {
        return <TextMoney money={value} />;
      }
    },

    {
      title: (
        <>
          <Tooltip
            title={t("cashflowreceipt:filter_method")}
            mouseEnterDelay={0.4}>
            {t("cashflowreceipt:method_sort")}
          </Tooltip>
        </>
      ),
      dataIndex: "method",
      align: "center",
      width: 70,
      render: (method: number) => {
        return <TextCashflowReceiptMethod method={method} />;
      }
    },
    {
      title: t("common:status"),
      dataIndex: "status",
      render: (status: number) => {
        let statusInfo = CashflowReceiptModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 120
    },
    {
      title: t("cashflowreceipt:date_affected_short"),
      togglelabel: t("cashflowreceipt:date_affected"),
      dataIndex: "date_affected",
      width: 120,
      render: (ts: number) => {
        return <TextDateTime format="HH:mm, DD/MM" ts={ts} />;
      }
    }
  ];

  const moreButtons = (
    <div className="my-2">
      <Button
        onClick={() => fetchData()}
        icon={<IconRefresh size={16} className="mr-1" />}
        className="opacity-40 hover:opacity-80"
        size="small"
        type="text">
        {t("purchaseorder:form.refresh_receipt")}
      </Button>

      {dataSource.length > 0 ? (
        <Link
          to={
            "/cashflowreceipt/add/direction/" +
            CashflowReceipt.DIRECTION_EXPENSE +
            "/purchase_order_id/" +
            purchaseOrder.id
          }
          target="_blank">
          <Button
            icon={<IconSquarePlus size={16} className="mr-1 -mt-0.5" />}
            className="opacity-40 hover:opacity-80"
            size="small"
            type="text">
            {t("purchaseorder:form.add_more_receipt")}
          </Button>
        </Link>
      ) : null}

      <Popover
        title={t("purchaseorder:form.link_receipt.title")}
        content={
          <PurchaseOrderFormSectionCashflowReceiptLinkForm
            onSuccess={() => fetchData()}
          />
        }
        placement="rightTop"
        trigger={["click"]}>
        <Button
          icon={<IconLink size={16} className="mr-1 -mt-0.5" />}
          className="opacity-40 hover:opacity-80"
          size="small"
          type="text">
          {t("purchaseorder:form.link_receipt.button")}
        </Button>
      </Popover>
    </div>
  );

  return (
    <div className="mt-8">
      <Row className="p-2 bg-gray-100">
        <Col
          span={3}
          className="px-2 text-xl font-extrabold text-right text-gray-600 uppercase bg-gray-100">
          {t("purchaseorder:form.section_receiptprefix")}
          <br />
          {t("purchaseorder:form.section_cashflowreceipt")}
        </Col>
        <Col span={21}>
          {errors.length > 0 ? (
            <div className="p-2">
              <ErrorAlert
                items={errors}
                translate_prefix="purchaseorder:form.error"
              />
            </div>
          ) : null}

          {!loading && dataSource.length === 0 ? (
            <div className="p-4 m-2 bg-white rounded-md">
              <Empty
                imageStyle={{ height: 40 }}
                description={
                  <div className="">
                    {t("purchaseorder:form.cashflow_receipt_empty")}
                  </div>
                }>
                <Popover
                  title={t("purchaseorder:form.init_cashflow_receipt.heading")}
                  content={
                    <>
                      <RoleCheck
                        roles={[Role.CASHFLOW_ADD]}
                        componentOnFail={
                          <Alert
                            type="warning"
                            showIcon
                            message={t(
                              "purchaseorder:form.cashflow_receipt_not_add_permission"
                            )}></Alert>
                        }>
                        <>
                          <MoneyInput
                            className="mb-2"
                            value={addValue}
                            onChange={(v) => setAddValue(v || 0)}
                            addonBefore={t(
                              "purchaseorder:form.init_cashflow_receipt.addvalue"
                            )}
                          />
                          <FormSelect
                            options={CashflowReceiptModel.getMethodList()}
                            className="mb-2"
                            value={addMethod}
                            onChange={(v) =>
                              setAddMethod(
                                typeof v !== "undefined"
                                  ? +v
                                  : CashflowReceipt.METHOD_BANK
                              )
                            }
                          />
                          <StoreFormSelect
                            defaultOptions={storeItemsWithLimit.map((i) => ({
                              value: i.id,
                              label: i.name
                            }))}
                            className="mb-2"
                            name="store_id"
                            placeholder={t(
                              "purchaseorder:form.init_cashflow_receipt.addstore"
                            )}
                            onChange={(value) =>
                              setStoreId(
                                typeof value !== "undefined" ? +value : 0
                              )
                            }
                          />

                          <Input.TextArea
                            className="mb-2"
                            placeholder={t(
                              "purchaseorder:form.init_cashflow_receipt.addnote"
                            )}
                            value={addNote}
                            onChange={(e) => setAddNote(e.target.value)}
                          />
                          <Button
                            type="primary"
                            block
                            loading={processing}
                            onClick={() => initFirstCashflowReceipt()}>
                            {t(
                              "purchaseorder:form.init_cashflow_receipt.submit"
                            )}
                          </Button>
                        </>
                      </RoleCheck>
                    </>
                  }
                  placement="rightTop"
                  trigger={["click"]}>
                  <Button type="primary" ghost loading={processing}>
                    {t("purchaseorder:form.init_cashflow_receipt.button")}
                  </Button>
                </Popover>
                <div className="mt-4">{moreButtons}</div>
              </Empty>
            </div>
          ) : null}

          <div className={!loading && dataSource.length > 0 ? "" : "hidden"}>
            <Table<CashflowReceiptModel>
              className="w-full border"
              rowKey={"id"}
              pagination={{
                pageSize: 100,
                hideOnSinglePage: true
              }}
              columns={columns}
              dataSource={dataSource}
              loading={loading}
            />

            {moreButtons}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseOrderFormSectionCashflowReceipt;
