import ShipperApplyApi from 'common/api/ShipperApplyApi';
import ShipperApplyCollection from 'common/collections/ShipperApplyCollection';
import ShipperApplyModel from 'common/models/ShipperApplyModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ShipperApplyJsonAddEdit,
  FilterShipperApply,
} from "common/types/ShipperApply";

class ShipperApplyRepository extends BaseRepository<ShipperApplyApi> {
  _api: ShipperApplyApi | null;

  constructor() {
    super();
    this._api = new ShipperApplyApi(true);
  }

  static getDefaultFilters(): FilterShipperApply {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
    };
  }

  async getItems(props: GetListProps<FilterShipperApply>) {
    return this._api
      ? await this._api.getList(props)
      : new ShipperApplyCollection();
  }

  async getItem(id: number): Promise<ShipperApplyModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShipperApplyModel(ShipperApplyModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: ShipperApplyJsonAddEdit): Promise<ShipperApplyModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShipperApplyModel(ShipperApplyModel.getDefaultData());
    }
  }
}

export default ShipperApplyRepository;
