import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";
import { IconAward } from "@tabler/icons-react";

const CheckinListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("checkin:heading_list")}
      siteMenuOpenKey="hrm"
      siteMenuSelectedKey="/checkin/public"
    >
      {/* <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 4 }}>
          <RoleCheck roles={[]}>
            <PageHeaderButton link="/template/add">
              {t("template:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row> */}
    </PageHeader>
  );
};

export default CheckinListHeader;
