import {
  FilterHistoryLeave,
  FilterLeave,
  LeaveJsonAddEdit,
} from "common/types/Leave";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import LeaveCollection from "common/collections/LeaveCollection";
import LeaveModel from "common/models/LeaveModel";
import LeaveHistoryCollection from "common/collections/LeaveHistoryCollection";
import { GetListProps } from "common/types/Filter";
const SERVICE_URL = "/leaves";
const SERVICE_URL_APPROVER_HISTORY = "/approverhistorys";
class LeaveApi extends BaseApi {
  async getList(props: GetListProps<FilterLeave>): Promise<LeaveCollection> {
    let collection = new LeaveCollection();
    try {
      let filters = props.filters;
      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          fltid:
            Number(filters.leavetype_id) >= 0 ? filters.leavetype_id : null,
          // ignore_group: 1,
          // is_not_deleted: 1,
          fkeyword: filters.keyword ? filters.keyword : null,
          fstatus: Number(filters.status) >= 0 ? filters.status : null,
          // office_id: filters.office_id > 0 ? filters.office_id : null,
          fcdid:
            Number(filters.department_id) > 0 ? filters.department_id : null,
          // type: filters.type >= 0 ? filters.type : null,
          fdatefrom: Number(filters.date_from) >= 0 ? filters.date_from : null,
          fdateto: Number(filters.date_to) >= 0 ? filters.date_to : null,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/employee",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  //

  async getDetail(id: number): Promise<LeaveModel> {
    let item = new LeaveModel(LeaveModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new LeaveModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  // getList LeaveOff to day

  async getLeaveOffToday(): Promise<LeaveCollection> {
    let collection = new LeaveCollection();

    try {
      let queryData = {
        params: {
          leave_inday: 1,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async add(data: LeaveJsonAddEdit): Promise<LeaveModel> {
    let item = new LeaveModel(LeaveModel.getDefaultData());
    let dataProps: any = { ...data };
    delete dataProps.id;
    try {
      let postData = {
        ...dataProps,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new LeaveModel(response.data);
      }
    } catch (error) {
      // console.log(error);
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: LeaveJsonAddEdit): Promise<LeaveModel> {
    let item = new LeaveModel(LeaveModel.getDefaultData());
    let dataProps: any = { ...data };
    delete dataProps.id;
    try {
      let postData = {
        ...dataProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new LeaveModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(SERVICE_URL + "/delete/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async getListHistory(
    props: FilterHistoryLeave
  ): Promise<LeaveHistoryCollection> {
    let collection = new LeaveHistoryCollection();
    try {
      let filters = props as FilterHistoryLeave;
      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          leavetypes_id: filters.id,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  /////////////////////////////////////////////////////////////
}

export default LeaveApi;
