import { Skeleton } from "antd";
import ProductVariantSizeModel from "common/models/ProductVariantSizeModel";
import ProductVariantSizeRepository from "common/repositories/ProductVariantSizeRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import ProductVariantSizeForm from "features/productvariantsize/form/ProductVariantSizeForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductVariantSizeFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => void;
  onSaveSuccess: (m: ProductVariantSizeModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<ProductVariantSizeModel>(
    new ProductVariantSizeModel(ProductVariantSizeModel.getDefaultData()),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new ProductVariantSizeRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={
        id > 0
          ? t("productvariantsize:heading_edit")
          : t("productvariantsize:heading_add")
      }
      width={480}
      confirmLoading={processing}
      open={open}
      setOpen={setOpen}
      formId="productvariantsize-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <ProductVariantSizeForm
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <ProductVariantSizeForm
          model={
            new ProductVariantSizeModel(
              ProductVariantSizeModel.getDefaultData(),
            )
          }
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default ProductVariantSizeFormModal;
