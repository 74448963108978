import React, { useState } from "react";
import { Modal, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import WorkChatDirectForm from "./WorkChatDirectForm";
const WorkChatDirectFormModal = ({
  open,
  setOpen,
  onSaveSuccess,
}: {
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  return (
    <Modal
      title={"Direct Messages"}
      width={720}
      open={open}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        setOpen(false);
      }}
      okButtonProps={{ form: "direct-form", htmlType: "submit" }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      confirmLoading={processing}
    >
      <WorkChatDirectForm
        setOpen={setOpen}
        setProcessing={setProcessing}
        onSaveSuccess={onSaveSuccess}
      />
    </Modal>
  );
};

export default WorkChatDirectFormModal;
