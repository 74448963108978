import { Skeleton } from "antd";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectTaskRepository from "common/repositories/ProjectTaskRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import ProjectTaskForm from "features/projecttask/form/ProjectTaskForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProjectTaskFormModal = ({
  id,
  projectModel,
  open,
  setOpen,
  processing,
  setProcessing,
  onSaveSuccess,
}: {
  id: number;
  projectModel: ProjectModel;
  open: boolean;
  setOpen: (v: boolean) => any;
  processing: boolean;
  setProcessing: (v: boolean) => any;
  onSaveSuccess: (m: ProjectTaskModel) => void;
}) => {
  const { t } = useTranslation();

  const [projectTaskModel, setProjectTaskModel] = useState<ProjectTaskModel>(
    new ProjectTaskModel(ProjectTaskModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  const fetchDetail = async (id: number) => {
    let myObj = await new ProjectTaskRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setProjectTaskModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      wrapClassName="ant-modal-padding-left-only"
      title={
        projectTaskModel.id > 0 ? (
          <span className="font-bold">{t("projecttask:heading_edit")}</span>
        ) : (
          <span className="font-bold">{t("projecttask:heading_add")}</span>
        )
      }
      width={1200}
      confirmLoading={processing}
      open={open}
      setOpen={setOpen}
      formId="project-task-form"
      className=""
    >
      {id > 0 ? (
        <>
          {projectTaskModel.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <ProjectTaskForm
                  projectModel={projectModel}
                  projectTaskModel={projectTaskModel}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <ProjectTaskForm
          projectModel={projectModel}
          projectTaskModel={projectTaskModel}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default ProjectTaskFormModal;
