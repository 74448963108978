import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import React from "react";

import { IconReportMoney, IconShoppingCart } from "@tabler/icons-react";

import ReportChartNumber from "../chart/ReportChartNumber";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportSaleGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số đơn hàng"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="count_id"
              dataService="order"
              dataTable="order"
              dataSelect="count(id)"
              dataFilter=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng số đơn hoàn thành"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="count_id"
              dataTable="order"
              dataSelect="count(id)"
              dataFilter="status = 19"
              dataService="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng giá trị hoàn thành"}
              icon={<IconReportMoney size="18" />}
              keyMapping="sum_pricefinal"
              dataTable="order"
              dataSelect="sum(pricefinal)"
              dataFilter="status = 19"
              dataService="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Giá trị trung bình"}
              icon={<IconReportMoney size="18" />}
              keyMapping="avg_pricefinal"
              dataTable="order"
              dataSelect="avg(pricefinal)"
              dataFilter="status = 19"
              dataService="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng giá trị đơn hàng"}
              dataTable="order"
              dataSelect="sum(pricefinal)"
              dataFilter=""
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "sum_pricefinal" }}
              height={250}
              dataService="order"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Số đơn hàng hoàn thành"}
              height={300}
              dataService="order"
              dataTable="order"
              dataSelect="count(id)"
              dataFilter="status = 19"
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "count_id" }}
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportSaleGeneral;
