import React, { useMemo } from "react";
import styles from "./styles.module.css";
import AcademyImage from "../AcademyImage/AcademyImage";
import StoryModel from "common/models/StoryModel";
import EyesIcon from "../../icon/EyesIcon";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import MessageIcon from "features/academy/icon/MessageIcon";
import ThumbUpIcon from "features/academy/icon/ThumbUpIcon";
import { Link } from "react-router-dom";
import FileModel from "common/models/FileModel";
import TagInfor from "./components/TagInfor/TagInfor";
import { SizeCard } from "./types";
import TagCategories from "./components/TagCategories/TagCategories";
import useGetfilesByIds from "hooks/useGetfilesByIds";

type Props = {
  heightImage: number;
  item: StoryModel;
  className?: string;
  size?: SizeCard;
  link?: string;
};
const AcademyCard = ({
  heightImage,
  item,
  className,
  size = "small",
  link,
}: Props) => {



  const ids = item.images.split(",").map(Number)
  const { files } = useGetfilesByIds(ids)

  const cardImage = files && files?.[ids?.[0]] ? files[ids[0]]?.url : ""
  const title = ` Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum soluta consectetur quisquam dicta reprehenderit`;

  const des = `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum explicabo, eveniet ratione adipisci voluptates, quibusdam ipsum maxime iusto ullam suscipit incidunt ea aliquam animi similique eligendi nihil optio magni a. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum soluta corporis doloremque ut sit cupiditate libero eaque repellat harum non quos adipisci laboriosam, doloribus inventore! Porro vero voluptate quod autem?`;

  const styleFont: React.CSSProperties = useMemo(() => {
    return {
      small: {
        fontSize: 14,
      },
      default: {
        fontSize: 20,
      },
    }[size];
  }, [size]);

  const styleContent: React.CSSProperties = useMemo(() => {
    return {
      small: {
        padding: "16px 10px",
      },
      default: {
        padding: "20px 10px",
      },
    }[size];
  }, [size]);

  // const urlImage: string | null = useMemo(() => {
  //   if (item.id > 0 && item.images[0].file_path.length > 0) {
  //     return FileModel.getThumbnailFromUrl(
  //       item.images[0].file_path,
  //       400,
  //       400,
  //       "resize"
  //     );
  //   } else {
  //     return null;
  //   }
  // }, [item]);
  return (
    <div
      className={`${styles.cardWrapper} ${size === "small" ? styles.cardSmall : styles.cardDefault
        } ${className && className}`}
    >
      <div className={styles.cardImage}>
        <img src={cardImage} alt="" />
        {/* {link ? (
          <Link to={link}>
            <AcademyImage data={item.toJson()} src={urlImage}></AcademyImage>
          </Link>
        ) : (
          <AcademyImage data={item.toJson()} src={urlImage}></AcademyImage>
        )} */}
      </div>
      <div className={styles.cardContent}>
        <div className={`${styles.cardTitle}`}>
          {link ? (
            <Link to={link}>
              <h4>{item.title}</h4>
            </Link>
          ) : (
            <h4>{item.title}</h4>
          )}
        </div>
        {/* <div className={styles.cardDescription}>
          {item.collection_list.length > 0 &&
            item.collection_list.map((item) => {
              return (
                <Link to={`/academy/${item.handle}`}>
                  {" "}
                  <span className={styles.tag}>{item.title}</span>
                </Link>
              );
            })}
        </div> */}
        <TagCategories data={item.collections} size={size}></TagCategories>
        <TagInfor item={item.toJson()} size={size}></TagInfor>
      </div>
    </div>
  );
};

export default AcademyCard;
