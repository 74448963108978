import { Form, Col, Row, Typography, Select } from "antd";
import React from "react";

const WorkChatDirectForm = ({
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: () => void;
}) => {
  const [form] = Form.useForm();

  //Select Member
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: "member" + i,
      value: i.toString(36) + i,
    });
  }

  const handleChange = (value: any) => {
    console.log(`selected ${value}`);
  };
  return (
    <Form id="direct-form" layout="vertical" form={form}>
      {/* Text read */}
      <Row gutter={16} className="items-center mb-5">
        <Col>
          <Typography.Text
            style={{ marginRight: "8px" }}
            className="text-gray-400"
          >
            "You are about to create a chat with multiple users. Add the ones
            you would like to talk, everyone in the same place, using direct
            messages."
          </Typography.Text>
        </Col>
      </Row>

      {/* Select Memeber */}
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <Form.Item
            className="font-blod"
            label={<span className="font-bold">Add Members (optional)</span>}
            name="members"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Please select"
              defaultValue={["a10", "c12"]}
              onChange={handleChange}
              options={options}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default WorkChatDirectForm;
