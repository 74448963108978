import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import type { IdFetcherJson, IdFetcherResult } from "common/types/IdFetcher";

class IdFetcherModel extends BaseModel implements BaseModelJson<IdFetcherJson> {
  object_type: string;
  id_list: number[];
  result: IdFetcherResult[];

  constructor(json: IdFetcherJson) {
    super();

    this.object_type = json.object_type || "";
    this.id_list = json.id_list || [];
    this.result = json.result || [];
  }

  static getDefaultData(): IdFetcherJson {
    return {
      object_type: "",
      id_list: [],
      result: [],
    };
  }

  toJson(): IdFetcherJson {
    return {
      object_type: this.object_type,
      id_list: this.id_list,
      result: this.result,
    };
  }
}

export default IdFetcherModel;
