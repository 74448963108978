import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import StoryCollectionCollection from "common/collections/StoryCollectionCollection";
import StoryCollectionModel from "common/models/StoryCollectionModel";
import type { GetListProps } from "common/types/Filter";
import {
  FilterStoryCollection,
  StoryCollectionAddEditJson,
  StoryCollectionDetailAddEditJson,
} from "common/types/StoryCollection";
import Helper from "common/utils/helper";

const SERVICE_URL = "/storycollections";

class StoryCollectionApi extends BaseApi {
  async getList(
    props: GetListProps<FilterStoryCollection>
  ): Promise<StoryCollectionCollection> {
    let collection = new StoryCollectionCollection();
    console.log(props.filters);
    try {
      const convertProps = Helper.convertParams(props.filters);
      const queryData = {
        params: {
          ...convertProps,
          // tags: props.filters.tags ? props.filters.tags.toString() : null,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/public",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<StoryCollectionModel> {
    let item = new StoryCollectionModel(StoryCollectionModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL +"/" + id
      );
      if (response.hasOwnProperty("data")) {
        item = new StoryCollectionModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: StoryCollectionAddEditJson): Promise<StoryCollectionModel> {
    let item = new StoryCollectionModel(StoryCollectionModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new StoryCollectionModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: StoryCollectionAddEditJson): Promise<StoryCollectionModel> {
    let item = new StoryCollectionModel(StoryCollectionModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new StoryCollectionModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async addStoryCollectionDetail(
    data: StoryCollectionDetailAddEditJson
  ): Promise<StoryCollectionModel> {
    let item = new StoryCollectionModel(StoryCollectionModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/detail",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new StoryCollectionModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default StoryCollectionApi;
