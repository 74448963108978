import BaseCollection from 'common/collections/BaseCollection';
import CmsNewsCategoryModel from 'common/models/CmsNewsCategoryModel';
import { CmsNewsCategoryCollectionJson, CmsNewsCategoryJson } from 'common/types/CmsNewsCategory';

class CmsNewsCategoryCollection extends BaseCollection<
  CmsNewsCategoryJson,
  CmsNewsCategoryModel,
  CmsNewsCategoryCollectionJson
> {
  itemsFromJson(jsonItems: CmsNewsCategoryJson[]): CmsNewsCategoryModel[] {
    return jsonItems.map((item) => new CmsNewsCategoryModel(item));
  }
}

export default CmsNewsCategoryCollection;
