import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";
import { IconAward } from "@tabler/icons-react";
import { Nav } from "common/types/PageNavigation";

const CheckinMineListHeader = () => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("checkin:add"),
      link: "/checkin/add",
    },
    {
      text: t("checkin:heading_mine_list"),
      link: "",
    },
  ];
  return (
    <PageHeader
      heading={t("checkin:heading_mine_list")}
      siteMenuOpenKey="checkin"
      siteMenuSelectedKey="/checkin"
      nav={nav}
    >
      {/* <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 4 }}>
          <RoleCheck roles={[]}>
            <PageHeaderButton link="/template/add">
              {t("template:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row> */}
    </PageHeader>
  );
};

export default CheckinMineListHeader;
