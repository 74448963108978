import { ProductVariantInMemory } from "common/types/ProductVariant";
import QuantityInput from "components/QuantityInput";
import dbm from "dbm";
import { useEffect, useState } from "react";

const ProductReceiptDetailQuantityInputWrapper = ({
  product_variant_id,
  item_quantity,
  onChange,
}: {
  product_variant_id: number;
  item_quantity: number;
  onChange: (value: number | null) => void;
}) => {
  const [variant, setVariant] = useState<ProductVariantInMemory>();

  useEffect(() => {
    (async () => {
      const foundItems = await dbm
        .getCollection("productvariant")
        .chain()
        .find({
          id: product_variant_id,
        })
        .limit(1)
        .data();
      if (foundItems.length > 0) {
        setVariant(foundItems[0]);
      }
    })();
  }, [product_variant_id]);

  if (typeof variant === "undefined") return <></>;

  return (
    <>
      <QuantityInput value={item_quantity} onChange={onChange} />
    </>
  );
};

export default ProductReceiptDetailQuantityInputWrapper;
