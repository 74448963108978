import { Badge, Button, Card, Image, Skeleton, Typography } from "antd";
import React, { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import NotificationModel from "common/models/NotificationModel";
import { NotificationJson } from "common/types/Notification";
import { URL } from "common/constants/UrlDefaultAsset";
import { IconChevronRight } from "@tabler/icons-react";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
type Props = {
  model: NotificationModel;
  list: NotificationModel[];
  setId: (id: number) => void;
};
const NotificationFeature = memo(({ model, list, setId }: Props) => {
  const { Meta } = Card;

  return (
    <div className="mt-4 block relative w-full">
      <Typography.Title className="flex items-center gap-1" level={5}>
        Thông báo khác <IconChevronRight size={16} />
      </Typography.Title>

      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        style={{ padding: "0 8px 16px 0" }}
        spaceBetween={50}
        slidesPerView={3}
        allowTouchMove
        defaultValue={model.id}
        breakpoints={{
          325: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          1530: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        }}
      >
        {list?.length > 0 ? (
          list.map(
            (item: NotificationJson) =>
              item.status === 1 && (
                <SwiperSlide
                  key={item.id}
                  onClick={() => {
                    setId(item.id);
                  }}
                >
                  <Badge.Ribbon
                    color={item.type === 2 ? "red" : "blue"}
                    text={`${NotificationModel.getType(item.type)?.label}`}
                  >
                    <Card
                      id="card_feature"
                      hoverable
                      bodyStyle={{ padding: "16px 8px" }}
                      cover={
                        <Image
                          className="rounded-md overflow-hidden h-[150px] relative object-cover"
                          preview={false}
                          width="100%"
                          height={105}
                          alt="example"
                          // src={`${URL.homePage}default_logo_180x120.jpg`}
                          fallback={"/imgs/nteam/default_logo.jpg"}
                          src={item.meta_image.url}
                        />
                      }
                    >
                      <Meta title={item.meta_title} />
                    </Card>
                  </Badge.Ribbon>
                </SwiperSlide>
              )
          )
        ) : (
          <Skeleton></Skeleton>
        )}
      </Swiper>
    </div>
  );
});

export default NotificationFeature;
