import { Checkbox } from "antd";
import PosCartModel from "common/models/PosCartModel";
import { PosCartDelivery } from "common/types/PosCart";
import TextAddress from "components/TextAddress";
import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import usePosCartStore from "zustands/usePosCartStore";

import PosRightDeliveryModal from "./PosRightDeliveryModal";
import PosRightDeliveryServiceList from "./PosRightDeliveryServiceList";

const PosRightDelivery = () => {
  const activeCustomer = usePosCartStore(
    (state) =>
      state.getActiveCart()?.customer || PosCartModel.getDefaultCartCustomer()
  );
  const activeDelivery = usePosCartStore(
    (state) =>
      state.getActiveCart()?.delivery || PosCartModel.getDefaultCartDelivery()
  );
  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);

  const [checkedCopyFromCustomer, setCheckedCopyFromCustomer] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editCompleted, setEditCompleted] = useState(false);

  //when check, false the customize
  useEffect(() => {
    if (checkedCopyFromCustomer) {
      updateActiveCart({
        delivery: update(activeDelivery, {
          is_customize: { $set: false },
        }),
      });
    }
  }, [checkedCopyFromCustomer, activeDelivery, updateActiveCart]);

  //when uncheck, show editor (when needed)
  //we separate with useEffect on check above
  useEffect(() => {
    if (!checkedCopyFromCustomer) {
      setShowModal(true);
      setEditCompleted(false);
    }
  }, [checkedCopyFromCustomer]);

  useEffect(() => {
    setCheckedCopyFromCustomer(!activeDelivery.is_customize);
  }, [activeDelivery]);

  //for update info
  const onSelect = useCallback(
    (delivery: PosCartDelivery) => {
      updateActiveCart({
        delivery,
      });
    },
    [updateActiveCart]
  );

  return (
    <div className="p-4 border-t border-l-4 border-yellow-600">
      <div className="float-right">
        <Checkbox
          checked={checkedCopyFromCustomer}
          onChange={(e) => setCheckedCopyFromCustomer(e.target.checked)}
        >
          <small>Giống thông tin KH</small>
        </Checkbox>
      </div>
      <div className="mb-2 font-bold uppercase">GIAO HÀNG</div>
      {!activeDelivery.is_customize ? null : (
        <div>
          <div>Thông tin nhận hàng</div>
          <div
            className="p-2 mb-2 text-xs border border-blue-300 cursor-pointer hover:bg-blue-100"
            onClick={() => {
              setShowModal(true);
              setEditCompleted(false);
            }}
          >
            <div className="font-bold">
              {activeDelivery.full_name} / {activeDelivery.phone}
            </div>

            <TextAddress
              address={activeDelivery.address}
              region_id={activeDelivery.region_id}
              sub_region_id={activeDelivery.sub_region_id}
              sub_sub_region_id={activeDelivery.sub_sub_region_id}
            />
          </div>
        </div>
      )}

      <PosRightDeliveryServiceList
        region_id={
          activeDelivery.is_customize
            ? activeDelivery.region_id
            : activeCustomer.region_id
        }
        sub_region_id={
          activeDelivery.is_customize
            ? activeDelivery.sub_region_id
            : activeCustomer.sub_region_id
        }
      />
      {/* <PosRightDeliveryDateArrived /> */}
      <PosRightDeliveryModal
        show={showModal && !editCompleted}
        setShow={setShowModal}
        setEditCompleted={setEditCompleted}
        setCheckedCopyFromCustomer={setCheckedCopyFromCustomer}
        onSelect={onSelect}
      />
    </div>
  );
};

export default PosRightDelivery;
