import { Col, Menu, Row } from "antd";
import { useEffect, useState } from "react";

import {
  BugOutlined,
  ClearOutlined,
  CrownOutlined,
  FormOutlined,
  HomeOutlined,
  CalendarOutlined,
  FileSearchOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { EmployeeJson } from "common/types/Employee";
import TaskDeptKanbanComponent from "./TaskDeptKanbanComponent";
type Props = {
  listEmployee: EmployeeJson[];
  department_id: number;
};
const TaskDeptKanban = ({ listEmployee, department_id }: Props) => {
  const { SubMenu } = Menu;

  const dataDev = [
    {
      key: "box",
      item: [1],
      title: (
        <span>
          <CalendarOutlined />
          <span>SPRINTS</span>
        </span>
      ),
    },
    {
      key: "ba",
      item: [3996, 3999, 4004],
      title: (
        <span>
          <ProjectOutlined />
          <span>BA</span>
        </span>
      ),
    },
    {
      key: "uiux",
      item: [4025],
      title: (
        <span>
          <ClearOutlined />
          <span>UI/UX</span>
        </span>
      ),
    },
    {
      key: "fe",
      item: [3907, 3910, 3993],
      title: (
        <span>
          <BugOutlined />
          <span>FRONTEND</span>
        </span>
      ),
    },
    {
      key: "be",
      item: [3, 4, 5],
      title: (
        <span>
          <BugOutlined />
          <span>BACKEND</span>
        </span>
      ),
    },
    {
      key: "qc",
      item: [3996, 3999, 4004],
      title: (
        <span>
          <FileSearchOutlined />
          <span>QUALITY CONTROL</span>
        </span>
      ),
    },
  ];

  const dataMenu = [
    {
      key: "box",
      item: [3929],
      title: (
        <span>
          <CrownOutlined />
          <span>SPRINTS</span>
        </span>
      ),
    },
    // {
    //   key: "ba",
    //   item: [4034],
    //   title: (
    //     <span>
    //       <FormOutlined />
    //       <span>BA</span>
    //     </span>
    //   ),
    // },
    {
      key: "uiux",
      item: [4056, 4072],
      title: (
        <span>
          <ClearOutlined />
          <span>UI/UX</span>
        </span>
      ),
    },
    {
      key: "fe",
      item: [3994, 4002, 4037, 4071],
      title: (
        <span>
          <BugOutlined />
          <span>FRONTEND</span>
        </span>
      ),
    },
    {
      key: "be",
      item: [3965, 3957, 3998, 3963, 4075],
      title: (
        <span>
          <BugOutlined />
          <span>BACKEND</span>
        </span>
      ),
    },
    {
      key: "qc",
      item: [4035],
      title: (
        <span>
          <ClearOutlined />
          <span>QUALITY CONTROL</span>
        </span>
      ),
    },
  ];

  //
  const [idsList, setIdsList] = useState<number[]>([]);
  const [allEmps, setAllEmps] = useState<number[]>([]);

  useEffect(() => {
    if (listEmployee.length > 0) {
      setIdsList(listEmployee.map((item) => item.id));
      setAllEmps(listEmployee.map((item) => item.id));
    }
  }, [listEmployee]);
  // function

  // const getTaskOfEmployee = (emp: EmployeeJson) => {
  //   setUserInfor((prev) => emp);
  // };

  //
  return (
    <>
      {listEmployee.length > 0 ? (
        <div style={{ height: "100%" }}>
          <Row gutter={8}>
            <Col xxl={3} xl={3} lg={3} md={3} span={24}>
              <Menu
                style={{ height: "100%" }}
                // defaultSelectedKeys={["box", "fe", "be", "qc"]}
                // defaultOpenKeys={["box", "fe", "be", "qc", "ba"]}
                // defaultActiveFirst={department_id === 1223 ? false : true}
                defaultActiveFirst={true}
                mode={"inline"}
                theme={"light"}
              >
                {department_id === 1223 && (
                  <Menu.Item
                    key={"all"}
                    onClick={() => {
                      setIdsList(allEmps);
                    }}
                  >
                    <span>
                      <HomeOutlined />
                      <span>TẤT CẢ</span>
                    </span>
                  </Menu.Item>
                )}
                {listEmployee.length > 0 && department_id === 1223 ? (
                  dataMenu.map((data) => {
                    return (
                      <SubMenu
                        key={data.key}
                        // onClick={() => {
                        //   setIdsList(data.item);
                        // }}
                        title={<span>{data.title}</span>}
                      >
                        <Menu.Item
                          key={"all_" + data.item.toString()}
                          onClick={() => {
                            setIdsList(data.item);
                          }}
                        >
                          <span>
                            <span>TẤT CẢ</span>
                          </span>
                        </Menu.Item>
                        {data.item.map((item) => {
                          return (
                            <Menu.Item
                              key={item}
                              onClick={() => {
                                setIdsList([item]);
                              }}
                            >
                              {listEmployee.find((data) => data.id === item)
                                ?.full_name || item}
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  })
                ) : (
                  <SubMenu
                    title={
                      <span>
                        <HomeOutlined /> TẤT CẢ
                      </span>
                    }
                    onTitleClick={() => {
                      setIdsList(allEmps);
                    }}
                  >
                    {listEmployee.map((emp, index) => (
                      <Menu.Item
                        key={"all"}
                        onClick={() => {
                          setIdsList([emp.id]);
                        }}
                      >
                        <span>{emp.full_name}</span>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                  // listEmployee.map((emp, index) => (
                  //     <Menu.Item
                  //       key={"all"}
                  //       onClick={() => {
                  //         setIdsList(allEmps);
                  //       }}
                  //     >
                  //       <span>
                  //         <HomeOutlined />
                  //         <span>TẤT CẢ</span>
                  //       </span>
                  //     </Menu.Item>
                  //   ))
                )}
              </Menu>
            </Col>
            <Col xxl={21} xl={21} lg={21} md={21} span={24}>
              <TaskDeptKanbanComponent
                ids_list={idsList}
                list_employee={listEmployee}
              />
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
};

export default TaskDeptKanban;
