import { Collapse, CollapseProps } from "antd";
import { useState } from "react";

const SimpleCollapse = ({
  header,
  headerSummary,
  className,
  defaultOpen,
  disableCollapse,
  childrenItem
}: {
  header: React.ReactNode;
  headerSummary?: React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
  disableCollapse?: boolean;
  childrenItem?: React.ReactNode;
}) => {
  const MY_KEY = "mykey";

  const [activeKey, setActiveKey] = useState(
    typeof defaultOpen === "undefined" || defaultOpen ? [MY_KEY] : []
  );

  const onChange = (key: string | string[]) => {
    setActiveKey(typeof key === "string" ? [key] : key);
  };

  const items: CollapseProps["items"] = [
    {
      key: MY_KEY,
      label: (
        <>
          {" "}
          <div>
            <div className="font-bold uppercase">{header}</div>
            {activeKey.length === 0 && headerSummary}
          </div>
        </>
      ),
      forceRender: true,
      children: <>{childrenItem}</>
    }
  ];

  return (
    <>
      <Collapse
        activeKey={activeKey}
        className={"shadow " + className}
        onChange={onChange}
        items={items}
      />
    </>
  );
};

export default SimpleCollapse;
