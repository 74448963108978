import { Tag } from "antd";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import {
	InventoryReceiptJson,
	InventoryReceiptSourceType,
} from "common/types/InventoryResceipt";
import React from "react";
import { Link } from "react-router-dom";

const InventoryReceiptSource = ({
	source,
}: {
	source: InventoryReceiptJson["source"];
}) => {
	const dataLabel = InventoryReceiptModel.getSourceLabel(source.type);
	return (
		<>
			{source.type === InventoryReceiptSourceType.ORDER ? (
				<div>
					<Link target="_blank" to={`/order/edit/id/${source.id}`}>
						<Tag color={dataLabel.color}>{dataLabel.label}</Tag>
					</Link>
				</div>
			) : (
				<Tag color={dataLabel.color}>{dataLabel.label}</Tag>
			)}
		</>
	);
};

export default InventoryReceiptSource;
