import {
  App,
  Button,
  Col,
  FormInstance,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag
} from "antd";
import Role from "common/constants/Role";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingRepository from "common/repositories/ProductInventoryCheckingRepository";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import update from "immutability-helper";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconBan, IconBarcode, IconSearch, IconX } from "@tabler/icons-react";

const ProductInventoryCheckingMergeList = ({
  form,
  warehouse_id,
  setCheckingIds
}: {
  form: FormInstance;
  warehouse_id: number;
  setCheckingIds: (ids: number[]) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const confirmingDeleteSku = useRef(0);
  const filteringWarehouseId = useRef(0);

  const [keyword, setKeyword] = useState("");

  const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
    "warehouse",
    Role.INVENTORY_STOCK_CHECK
  );

  const messageKey = "merge_create";
  const [item, setItem] = useState<ProductInventoryCheckingModel>(
    new ProductInventoryCheckingModel(
      ProductInventoryCheckingModel.getDefaultData()
    )
  );
  const [detailItems, setDetailItems] = useState<
    ProductInventoryCheckingModel[]
  >([]);

  const doSearch = useCallback(async (query: string) => {
    //trim space
    query = query.trim();
    setKeyword(query);

    let collection = await new ProductInventoryCheckingRepository().getItems({
      filters: {
        ...ProductInventoryCheckingRepository.getDefaultFilters(),
        warehouse_id: filteringWarehouseId.current,
        id: +query
      }
    });

    if (collection.total > 0) {
      setItem(collection.items[0]);
    } else {
      setItem(
        new ProductInventoryCheckingModel(
          ProductInventoryCheckingModel.getDefaultData()
        )
      );
    }
  }, []);

  useEffect(() => {
    filteringWarehouseId.current = warehouse_id;
  }, [warehouse_id]);

  const doSelect = useCallback(
    (id: number) => {
      if (id > 0) {
        //detech this is NEW or UPDATE
        const foundIndex = detailItems.findIndex((r) => r.id === id);
        if (foundIndex >= 0) {
          //update current role item info
          // thong bao da ton tai

          message.warning({
            content: t("productinventorychecking:form.error_exist"),
            className: "message_warning",
            key: messageKey,
            duration: 1
          });
        } else {
          message.success({
            content: t("productinventorychecking:form.append_success"),
            className: "message_success",
            key: messageKey,
            duration: 1
          });
          //append new item to list
          let newItems = update(detailItems, {
            $unshift: [item]
          });

          setDetailItems(newItems);
          setCheckingIds(newItems.map((item) => item.id));
        }
      }
    },
    [detailItems, item, setCheckingIds, t, message]
  );

  //set default warehouse if only one warehouse
  useEffect(() => {
    if (warehouseItemsWithLimit.length === 1 && warehouse_id === null) {
      form.setFieldValue("warehouse_id", warehouseItemsWithLimit[0].id);
    }
  }, [warehouseItemsWithLimit, warehouse_id, form]);

  //Table columns
  const columns: TableColumnsType<ProductInventoryCheckingModel> = [
    {
      title: t("productinventorychecking:id"),
      key: "id",
      dataIndex: "id",
      width: 100
    },
    {
      title: t("productinventorychecking:sku_group"),
      key: "sku_group",
      dataIndex: "sku_group",
      width: 120
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      dataIndex: "date_created",
      width: 120,
      render: (ts: number) => <TextDateTime ts={ts} />
    },
    {
      title: t("common:status"),
      key: "status",
      dataIndex: "status",
      render: (status: number) => {
        let statusInfo = ProductInventoryCheckingModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 120
    },
    {
      title: t("common:creator_id"),
      key: "creator_id",
      dataIndex: "creator_id",
      width: 120,
      render: (creator_id: number) => <TextUser id={creator_id} />
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 60,
      render: (_: any, record: ProductInventoryCheckingModel) => {
        return (
          <>
            <Popconfirm
              title={t("common:table.confirm_delete_title")}
              placement="topRight"
              onConfirm={() => {
                setDetailItems(
                  detailItems.filter((item) => item.id !== record.id)
                );
                setCheckingIds(
                  detailItems
                    .filter((item) => item.id !== record.id)
                    .map((item) => item.id)
                );
              }}
              onCancel={() => (confirmingDeleteSku.current = 0)}
              okText={t("common:table.confirm_ok")}
              cancelText={t("common:table.confirm_cancel")}>
              <Button
                type="link"
                size="small"
                className="text-red-200 hover:text-red-500"
                onClick={() => (confirmingDeleteSku.current = record.id)}>
                <IconX />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  return (
    <div>
      <div className="mb-2">
        <div>
          <Row className="" gutter={16}>
            <Col flex={"80px"}>
              <div className="mt-2">
                {t("productinventorychecking:form.warehouse_id")}:
              </div>
            </Col>
            <Col flex={"300px"}>
              <WarehouseFormSelect
                size="large"
                required
                label=""
                name="warehouse_id"
                initOptions={warehouseItemsWithLimit.map((i) => ({
                  value: i.id,
                  label: i.name
                }))}
              />
            </Col>
            <Col flex={3}>
              <Select
                disabled={warehouse_id === null}
                showArrow={true}
                suffixIcon={<IconSearch size={18} color="#dddddd" />}
                bordered={true}
                autoClearSearchValue
                placeholder={t(
                  "productinventorychecking:form:search_identifier_placeholder"
                )}
                size="large"
                style={{ width: "100%" }}
                showSearch
                value={null}
                filterOption={false}
                dropdownRender={(menu) => <>{menu}</>}
                onSelect={(selectedId) => {
                  doSelect(selectedId ?? 0);
                }}
                onSearch={debounce((value) => doSearch(value), 500)}
                notFoundContent={
                  <>
                    {keyword.length > 0 ? (
                      <>
                        <IconBan size={24} className="-mt-0.5 mr-1" />
                        {t(
                          "productinventorychecking:form.search_notfound_empty"
                        )}
                      </>
                    ) : (
                      <>
                        <IconBarcode size={24} className="-mt-0.5 mr-1" />
                        {t(
                          "productinventorychecking:form.search_notfound_empty_keyword"
                        )}
                      </>
                    )}
                  </>
                }>
                {item.id > 0 ? (
                  <Select.Option key={item.id} value={item.id}>
                    <Tag color="#2db7f5">{item.id}</Tag>
                    {" - "}
                    <span className="text-gray-400">
                      {t("common:creator_id")}:{" "}
                      <TextUser id={item.creator_id} />
                    </span>
                    {" - "}
                    <span className="text-gray-400">
                      {t("productinventorychecking:sku_group")}:{" "}
                      {item.sku_group}
                    </span>
                    {" - "}
                    <span className="text-gray-400">
                      {t("common:status")}:{" "}
                      {
                        ProductInventoryCheckingModel.getStatus(item.status)
                          ?.label
                      }
                    </span>
                  </Select.Option>
                ) : null}
              </Select>
            </Col>
          </Row>
        </div>
      </div>
      <Table
        size="small"
        className="w-full table-dashboard"
        dataSource={detailItems}
        columns={columns}
        rowKey={"id"}
        bordered
        pagination={{ hideOnSinglePage: true }}
        footer={() => null}
      />
    </div>
  );
};

export default ProductInventoryCheckingMergeList;
