import React, { useEffect, useState } from "react";
import CommentModel from "common/models/CommentModel";
import CommentRepository from "common/repositories/CommentRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import CommentFormHeader from "features/comment/form/CommentFormHeader";
import CommentList from "features/comment/list/CommentList";
import usePathParams from "hooks/usePathParams";
import ProcessDetailComemnt from "features/comment/process/ProcessDetailComemnt";

const CommentPage = () => {
	const [pathParams] = usePathParams();
	const [model, setModel] = useState(
		new CommentModel(CommentModel.getDefaultData())
	);
	const [errors, setErrors] = useState<string[]>([]);

	let com = <></>;

	useEffect(() => {
		setModel(new CommentModel(CommentModel.getDefaultData()));
	}, [pathParams.id]);
	switch (pathParams.action) {
		case "process":
			com =
				model.id > 0 ? (
					<ProcessDetailComemnt model={model} />
				) : (
					<FormEditFetcher
						doLoad={async () => {
							let fetchedModel = await new CommentRepository().getItem(
								parseInt(pathParams.id.toString())
							);
							if (fetchedModel.hasError()) {
								setErrors(fetchedModel.error.errors);
							} else {
								setModel(fetchedModel);
							}
						}}
						errors={errors}
						heading={<CommentFormHeader isEditing={true} id={model.id} />}
					/>
				);
			break;
		default:
			com = <CommentList />;
			break;
	}
	return com;
};

export default CommentPage;
