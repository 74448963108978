import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Dropdown, MenuProps, Tag, Typography, message } from "antd";
import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import { useTranslation } from "react-i18next";

import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
//Phần thay thế

import { MoreOutlined } from "@ant-design/icons";
import LeaveCollection from "common/collections/LeaveCollection";
import Leave from "common/constants/Leave";
import LeaveModel from "common/models/LeaveModel";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveRepository from "common/repositories/LeaveRepository";
import { FilterLeave } from "common/types/Leave";
import { SelectOption } from "common/types/SelectOption";
import { TableColumnsType } from "common/types/Table";
import TextDateTime from "components/TextDateTime";
import PageDataPagination from "components/page/PageDataPagination";
import TableActionItem from "components/table/TableActionItem";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import LeaveListFilter from "./LeaveListFilter";
import LeaveListHeader from "./LeaveListHeader";
import TableInfo from "components/table/TableInfo";
import { IconInfoCircle } from "@tabler/icons-react";

type Props = {
  listLeaveType: LeaveTypeModel[];
};

const LeaveList = ({ listLeaveType }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  ////////////////////////
  // default filter
  const defaultFilters = useMemo(() => {
    return LeaveRepository.getDefaultFilters();
  }, []);

  ///////////////////////
  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<LeaveModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const [leaveTypeItems, setLeaveTypesItem] = useState<SelectOption[]>([]);

  const createItemsForSelect = (leaveTypes: LeaveTypeModel[]) => {
    const data = leaveTypes.map((item: LeaveTypeModel) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    setLeaveTypesItem(data);
    return data;
  };

  useEffect(() => {
    createItemsForSelect(listLeaveType);
  }, [listLeaveType]);

  //Table columns
  const columns: TableColumnsType<LeaveModel> = [
    {
      title: "ID",
      key: "id",
      width: 80,
      sorter: (a: any, b: any) => a.stt - b.stt,
      render: (id) => <Tag>DNP{id}</Tag>,
    },

    {
      title: t("leave:leave_type"),
      key: "leavetypes_id",
      width: 220,

      render: (type) => {
        return (
          <Typography.Text>
            {listLeaveType.length > 0
              ? listLeaveType.find((item: any) => item.id === type)?.name
              : t("leave:empty")}
          </Typography.Text>
        );
      },
    },

    {
      title: t("leave:list.day_off"),
      key: "dayoff",
      width: 120,
      sorter: (a: any, b: any) => a.leave_day - b.leave_day,
      render: (date) => <Typography.Text>{date}</Typography.Text>,
    },
    {
      title: t("common:columns.from"),
      key: "date_from",
      width: 200,
      render(value, record, index) {
        return <TextDateTime ts={value} format="HH:mm DD/MM/YYYY" />;
      },
    },

    {
      title: t("common:columns.to"),
      key: "date_to",
      width: 200,
      render(value, record, index) {
        return <TextDateTime ts={value} format="HH:mm DD/MM/YYYY" />;
      },
    },
    {
      title: t("leave:list.datecreated"),
      key: "date_created",
      width: 200,
      render(value, record, index) {
        return <TextDateTime ts={value} format="HH:mm DD/MM/YYYY" />;
      },
    },
    {
      title: t("leave:salary"),
      key: "salary_type",
      width: 150,
      render: (_, record: LeaveModel) => {
        const salaryTypes =
          listLeaveType.find((item) => item.id === record.leavetypes_id)
            ?.salary_type || "";
        return (
          <Typography.Text>
            {t(`leave:salary_type.${salaryTypes}`)}
          </Typography.Text>
        );
      },
    },

    {
      title: t("common:columns.status"),
      key: "status",
      align: "left",
      width: 150,

      render: (status) => {
        let statusInfo = LeaveModel.getStatus(status);
        let color =
          status === Leave.STATUS_APPROVED
            ? "#4EBA00"
            : status === Leave.STATUS_DENED
            ? "#D81A61"
            : status === Leave.STATUS_APPROVED_1
            ? "#FFCD00"
            : "#FF7805";
        let backgroundColor =
          status === Leave.STATUS_APPROVED
            ? "#53B40D26"
            : status === Leave.STATUS_DENED
            ? "#D81A6126"
            : status === Leave.STATUS_APPROVED_1
            ? "#FFCD0026"
            : "#FF780526";
        let borderColor =
          status === Leave.STATUS_APPROVED
            ? "#53B40D80"
            : status === Leave.STATUS_DENED
            ? "#D81A6180"
            : status === Leave.STATUS_APPROVED_1
            ? "#FFCD0080"
            : "#FF780580";
        return (
          <Tag
            key={status}
            style={{
              background: backgroundColor,
              color: color,
              border: ` 1px solid ${borderColor}`,
              fontWeight: "bold",
            }}
          >
            {statusInfo?.label}
          </Tag>
        );
      },
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 50,
      render: (_: any, record: LeaveModel) => {
        const items: MenuProps["items"] = [
          {
            key: "3",
            label: (
              <Button
                size="small"
                type="text"
                onClick={() => {
                  navigate(`/leave/detail/id/${record.id}`);
                }}
                icon={
                  <IconInfoCircle
                    size="18"
                    className={" -mt-0.5 text-gray-400 hover:text-gray-500"}
                  />
                }
              >
                Chi tiết
              </Button>
            ),
          },
        ];
        if (record.status === Leave.STATUS_PENDING) {
          items.unshift(
            {
              key: "1",
              label: (
                <TableEdit
                  icon={<></>}
                  key="edit"
                  link=""
                  onClick={() => {
                    navigate(`/leave/edit/id/${record.id}`);
                  }}
                />
              ),
            },
            {
              key: "2",
              label: (
                <TableDelete
                  className="flex items-center gap-2"
                  error_translate_prefix="leave:form.error"
                  onDeleteCallback={(id) => {
                    setDataSource(dataSource.filter((item) => item.id !== id));
                  }}
                  repository={new LeaveRepository()}
                  id={record.id}
                  label="Xóa"
                />
              ),
            }
          );
        }
        return (
          <>
            {/* {record.status === Leave.STATUS_PENDING && (
              <TableEdit
                key="edit"
                link=""
                onClick={() => {
                  navigate(`/leave/edit/id/${record.id}`);
                }}
              />
            )}
            {record.status === Leave.STATUS_PENDING && (
              <TableDelete
                error_translate_prefix="leave:form.error"
                onDeleteCallback={(id) => {
                  setDataSource(dataSource.filter((item) => item.id !== id));
                }}
                repository={new LeaveRepository()}
                id={record.id}
              />
            )}
            <Button
              size="small"
              type="text"
              onClick={() => {
                navigate(`/leave/detail/id/${record.id}`);
              }}
            >
              <IconInfoCircle
                size="18"
                className={" -mt-0.5 text-gray-400 hover:text-gray-500"}
              />
            </Button>*/}
            <Dropdown trigger={["hover", "click"]} menu={{ items }}>
              <MoreOutlined
                style={{ fontSize: "16pt" }}
                className="cursor-pointer "
              />
            </Dropdown>
          </>
        );
      },
    },
  ];

  ///////////////////////
  //function
  const fetchData = useCallback(async (): Promise<LeaveCollection> => {
    const response = await new LeaveRepository().getItems({
      filters: filters,
    });
    if (response) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      message.error("common:error.error_fetching_data");
    }

    return response;
  }, [filters]);

  return (
    <div>
      <LeaveListHeader />
      <LeaveListFilter
        leaveTypeItems={leaveTypeItems}
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      ></LeaveListFilter>

      <PageDataTable<FilterLeave, LeaveModel, LeaveCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "leave",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </div>
  );
};

export default LeaveList;
