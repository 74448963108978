import { Nav } from "common/types/PageNavigation";
import Helper from "common/utils/helper";
import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const OrderSellerFormHeader = ({
	isEditing,
	id,
}: {
	isEditing: boolean;
	id: number | string;
}) => {
	const { t } = useTranslation();

	const nav: Nav = [
		{
			text: t("order:heading_nav"),
			link: "/order",
		},
	];

	return (
		<>
			<PageHeader
				nav={nav}
				heading={
					t(isEditing ? "order:heading_edit" : "order:heading_add") +
					(Helper.checkEditOrder(id) ? " #" + id : "")
				}
				siteMenuSelectedKey="/order"
				siteMenuHidden></PageHeader>
		</>
	);
};

export default OrderSellerFormHeader;
