import i18n from "i18n";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

import { FileJson } from "common/types/File";
import {
  StoryCollectionItemJson,
  StoryImageJson,
  StoryJson,
  StoryTagJson,
} from "common/types/Story";
import { CollectionJson } from "common/types/Collection";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";

const defaultDataProductCollection: StoryJson = {
  id: 0,
  creator_id: 0,
  // image: FileModel.getDefaultData(),
  images: "",
  banner_desktop: 0,
  // banner_desktop_image: 0,
  banner_mobile: 0,
  // banner_mobile_image: [],
  title: "",
  handle: "",
  description: "",
  keyword: "",
  present: "",
  content_type: "",
  quote: "",
  comment: "",
  comment_total: 0,
  viewed: 0,
  visitor: 0,
  date_published: 0,
  publish_from: 0,
  publish_to: 0,
  pin: 0,
  status: 0,
  type: 0,
  web_id: 0,
  is_deleted: 0,
  date_created: 0,
  date_modified: 0,
  date_deleted: 0,
  tags: [],
  collections: [],
  detail: "",
  // image_id: 0,
  story_id: 0,
};

class StoryModel extends BaseModel implements BaseModelJson<StoryJson> {
  id: number = defaultDataProductCollection.id;
  creator_id: number = defaultDataProductCollection.creator_id;
  // image: FileJson = defaultDataProductCollection.image;
  images: string = "";
  banner_desktop: number = defaultDataProductCollection.banner_desktop;
  // banner_desktop_image: StoryImageJson[] = [];
  banner_mobile: number = defaultDataProductCollection.banner_mobile;
  // banner_mobile_image: StoryImageJson[] =
  //   defaultDataProductCollection.banner_mobile_image;
  title: string = defaultDataProductCollection.title;
  handle: string = defaultDataProductCollection.handle;
  description: string = defaultDataProductCollection.description;
  keyword: string = defaultDataProductCollection.keyword;
  present: string = defaultDataProductCollection.present;
  content_type: string = defaultDataProductCollection.content_type;
  quote: string = defaultDataProductCollection.quote;
  comment: string = defaultDataProductCollection.comment;
  comment_total: number = 0;
  viewed: number = 0;
  visitor: number = 0;
  date_published: number = 0;
  publish_from: number = 0;
  publish_to: number = 0;
  pin: number = 0;
  status: number = 0;
  type: number = 0;
  web_id: number = 0;
  is_deleted: number = 0;
  date_created: number = 0;
  date_modified: number = 0;
  date_deleted: number = 0;
  tags: StoryTagJson[] = defaultDataProductCollection.tags;
  collections: StoryCollectionItemJson[] =
    defaultDataProductCollection.collections;
  detail: string = defaultDataProductCollection.detail;
  // image_id: number = defaultDataProductCollection.image_id;
  story_id: number = defaultDataProductCollection.story_id;

  constructor(json: StoryJson) {
    super();
    Object.assign(this, { ...defaultDataProductCollection, ...json });
  }
  static getDefaultData(): StoryJson {
    return { ...defaultDataProductCollection };
  }
  toJson(): StoryJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getDefaultImagesArray = (files: FileJson[]) => {
    return files.map((f) => new FileModel(f));
  };

  static getDisplayList(): SelectOption[] {
    return [
      {
        value: 1,
        label: i18n.t("story:show"),
      },
      {
        value: 2,
        label: i18n.t("story:demo"),
      },
      {
        value: 0,
        label: i18n.t("story:hide"),
      },
    ];
  }
  static getDisplay(value: number): SelectOption | undefined {
    return this.getDisplayList().find((item) => item.value === value);
  }
}

export default StoryModel;
