import BaseCollection from 'common/collections/BaseCollection';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';
import { CashflowReceiptCollectionJson, CashflowReceiptJson } from 'common/types/CashflowReceipt';

class CashflowReceiptCollection extends BaseCollection<
  CashflowReceiptJson,
  CashflowReceiptModel,
  CashflowReceiptCollectionJson
> {
  itemsFromJson(jsonItems: CashflowReceiptJson[]): CashflowReceiptModel[] {
    return jsonItems.map((item) => new CashflowReceiptModel(item));
  }
}

export default CashflowReceiptCollection;
