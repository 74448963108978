import PurchaseOrderApi from "common/api/PurchaseOrderApi";
import PurchaseOrderCollection from "common/collections/PurchaseOrderCollection";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  FilterPurchaseOrder,
  PurchaseOrderJsonAddEdit
} from "common/types/PurchaseOrder";
class PurchaseOrderRepository extends BaseRepository<PurchaseOrderApi> {
  _api: PurchaseOrderApi | null;

  constructor() {
    super();
    this._api = new PurchaseOrderApi(true);
  }

  static getDefaultFilters(): FilterPurchaseOrder {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
      supplier_id: -1,
      tag: "",
      date_started: -1,
      date_ended: -1
    };
  }

  async getItems(props: GetListProps<FilterPurchaseOrder>) {
    return this._api
      ? await this._api.getList(props)
      : new PurchaseOrderCollection();
  }

  async getItem(id: number): Promise<PurchaseOrderModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new PurchaseOrderModel(PurchaseOrderModel.getDefaultData());
  }

  async saveRemote(
    data: PurchaseOrderJsonAddEdit
  ): Promise<PurchaseOrderModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new PurchaseOrderModel(PurchaseOrderModel.getDefaultData());
    }
  }
}

export default PurchaseOrderRepository;
