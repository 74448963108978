import TaxInvoiceCollection from "common/collections/TaxInvoiceCollection";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterTaxInvoice,
  TaxInvoiceJsonAddEdit,
} from "common/types/TaxInvoice";

const SERVICE_URL = "/taxinvoices";

class TaxInvoiceApi extends BaseApi {
  async getList(
    props: GetListProps<FilterTaxInvoice>
  ): Promise<TaxInvoiceCollection> {
    let collection = new TaxInvoiceCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status > 0 ? filters.status : null,
          id: filters.id > 0 ? filters.id : null,
          seller_store_id:
            filters.seller_store_id > 0 ? filters.seller_store_id : null,
          order_id: filters.order_id > 0 ? filters.order_id : null,
          date_started: filters.date_started > 0 ? filters.date_started : null,
          date_ended: filters.date_ended > 0 ? filters.date_ended : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<TaxInvoiceModel> {
    let item = new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new TaxInvoiceModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: TaxInvoiceJsonAddEdit): Promise<TaxInvoiceModel> {
    let item = new TaxInvoiceModel(TaxInvoiceModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaxInvoiceModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default TaxInvoiceApi;
