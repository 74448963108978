import { App, Modal, ModalProps } from "antd";
import FormChangedProvider from "contextproviders/FormChangedProvider";
import FormChangedContext from "contexts/FormChangedContext";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface ExtendedModalProps {
  formId?: string;
  setOpen?: (v: boolean) => void;
}

type FormModalProps = ModalProps & ExtendedModalProps;

const FormModal: FunctionComponent<FormModalProps> = (props) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const [formChanged, setFormChanged] = useState(props.maskClosable || false);

  return (
    <Modal
      width={props.width || 920}
      closable={props.closable || true}
      maskClosable={!formChanged}
      afterClose={() => {
        setFormChanged(false);
        if (typeof props.afterClose !== "undefined") {
          props.afterClose();
        }
      }}
      onCancel={(e) => {
        if (formChanged) {
          return modal.confirm({
            title: t("common:form.close_modal.warning"),
            okText: t("common:form.close_modal.yes"),
            okType: "danger",
            icon: null,
            cancelText: t("common:form.close_modal.no"),
            onOk() {
              typeof props.setOpen !== "undefined" && props.setOpen(false);
              if (typeof props.onCancel !== "undefined") {
                props.onCancel(e);
              }
            }
          });
        } else {
          typeof props.setOpen !== "undefined" && props.setOpen(false);
          if (typeof props.onCancel !== "undefined") {
            props.onCancel(e);
          }
        }
      }}
      okButtonProps={{
        ...props.okButtonProps,
        form: props.formId,
        htmlType: "submit",
        disabled: !formChanged
      }}
      okText={props.okText || t("common:form.save")}
      cancelText={props.cancelText || t("common:close")}
      destroyOnClose={props.destroyOnClose || true}
      {...props}>
      <FormChangedContext.Provider
        value={
          new FormChangedProvider(
            props.formId || "",
            formChanged,
            setFormChanged
          )
        }>
        {props.children}
      </FormChangedContext.Provider>
    </Modal>
  );
};

export default FormModal;
