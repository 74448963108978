import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";
import { LeaveAssignJson } from "common/types/LeaveAssign";

class LeaveAssignModel
  extends BaseModel
  implements BaseModelJson<LeaveAssignJson>
{
  company_id: number;
  employee_id: number;
  approver_id: number;
  type: string;
  stepname: string;
  resourcetype: string;
  resourceid: number;
  step: number;
  status: number;
  datecreated: number;
  datemodified: number;

  constructor(json: LeaveAssignJson) {
    super();
    // this.stt = json.stt || 0;
    this.company_id = json.company_id || 0;
    this.employee_id = json.employee_id || 0;
    this.approver_id = json.approver_id || 0;
    this.type = json.type || "";
    this.stepname = json.stepname || "";
    this.resourcetype = json.resourcetype || "";
    this.resourceid = json.resourceid || 0;
    this.step = json.step || 0;
    this.status = json.status || 0;
    this.datecreated = json.datecreated || 0;
    this.datemodified = json.datemodified || 0;
  }

  static getDefaultData(): LeaveAssignJson {
    return {
      company_id: 0,
      employee_id: 0,
      approver_id: 0,
      type: "",
      stepname: "",
      resourcetype: "",
      resourceid: 0,
      step: 0,
      status: 0,
      datecreated: 0,
      datemodified: 0,
    };
  }

  toJson(): LeaveAssignJson {
    return {
      company_id: this.company_id,
      employee_id: this.employee_id,
      approver_id: this.approver_id,
      type: this.type,
      stepname: this.stepname,
      resourcetype: this.resourcetype,
      resourceid: this.resourceid,
      step: this.step,
      status: this.status,
      datecreated: this.datecreated,
      datemodified: this.datemodified,
    };
  }
}

export default LeaveAssignModel;
