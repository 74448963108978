import { Col, DatePicker, Form, Row, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ReportCheckinModel from "common/models/ReportCheckinModel";
import ReportCheckinRepository from "common/repositories/ReportCheckinRepository";
import Helper from "common/utils/helper";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";

import ReportCheckinHeader from "./ReportCheckinHeader";
import DownloadModal from "./components/DownloadModal";
import { Dayjs } from "dayjs";

const ReportCheckin = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const messageKeyExportPrefix = "export_item_";
  const [form] = useForm();

  //state
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [calling, setCalling] = useState<boolean>(true);
  const [model, setModel] = useState<ReportCheckinModel>(
    new ReportCheckinModel(ReportCheckinModel.getDefaultData())
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const initialValues = useMemo(() => {
    return {
      date_from: 0,
      date_to: 0,
    };
  }, []);

  const doPrepareData = (formData: any) => {
    const submitData: any = {
      date_from:
        Helper.datePickerValueToTimestamp(formData.date_from) > 0
          ? Helper.datePickerValueToTimestamp(formData.date_from)
          : 0,
      date_to:
        Helper.datePickerValueToTimestamp(formData.date_to) > 0
          ? Helper.datePickerValueToTimestamp(formData.date_to)
          : 0,
    };

    return submitData;
  };

  const onSubmit = async (formData: any) => {
    setLoading(true);

    let myObj: ReportCheckinModel =
      await new ReportCheckinRepository().exportReport(doPrepareData(formData));

    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
      message.error({
        content: t(`reportcheckin:form.error.${myObj.error.errors[0]}`),
        className: "message_error",
        key: messageKeyExportPrefix,
        duration: 10,
      });
      setLoading(false);
    } else {
      setShowModal(true);
      setModel(myObj);
      setCalling(true);
    }
  };

  useEffect(() => {
    let intervalId: any;
    console.log(model);
    if (calling && model.file_name.length > 0) {
      intervalId = setInterval(async () => {
        // Gọi API liên tục
        const response = await new ReportCheckinRepository().checkFileReport(
          model.file_name
        );

        if (response.length === 0) {
          ///base condition
          setCalling(false);
          setLoading(false);
          clearInterval(intervalId); // Dừng interval khi API trả về true
        } else if (!response.includes("error_file_not_found")) {
          message.error({
            content: t(`reportcheckin:form.error.${response[0]}`),
            className: "message_error",
            key: messageKeyExportPrefix,
            duration: 10,
          });
        }
      }, 1000);
    }
    return () => {
      clearInterval(intervalId); // Đảm bảo dừng interval khi component unmount
    };
  }, [calling, model]);
  const checkReport = async (data: ReportCheckinModel) => {
    setShowModal(true);
    setLoading(true);
    setCalling(true);
    const response = await new ReportCheckinRepository().checkFileReport(
      data.file_name
    );

    if (response.length === 0) {
      setLoading(false);
      setCalling(false);
      message.success({
        content: t("Tạo file thành công"),
        className: "message_success",
        key: messageKeyExportPrefix,
        duration: 1,
      });
    } else {
      if (response.includes("error_file_not_found")) {
      } else {
        message.error({
          content: response[0],
          className: "message_error",
          key: messageKeyExportPrefix,
          duration: 10,
        });
      }
    }
  };

  const onCloseDownloadModal = () => {
    console.log("comehrere");
    setCalling(false);
    setLoading(false);
    setShowModal(false);
  };

  return (
    <>
      <ReportCheckinHeader />
      <LayoutForm
        disable_status_loading={true}
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={"Tiến hành xuất báo cáo"}
        error_translate_prefix="reportcheckin:form.error"
        onSubmit={onSubmit}
        submitText={t("Xác nhận")}
        sidebarItems={null}
      >
        <FormSection title={t("Thông tin báo cáo")}>
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_fromtime")}
                name="date_from"
                style={{ width: "100%" }}
                validateFirst
                rules={[
                  {
                    required: true,
                    message: t("task:form.error.required_time"),
                  },
                  {
                    message: t("task:form.error.required_time"),
                    validator: (_: any, value: Dayjs) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment(new Date())}
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"dd/mm/yyyy"}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("task:org_totime")}
                name="date_to"
                validateFirst
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: t("task:form.error.required_time"),
                  },
                  {
                    message: t("task:form.error.required_time"),
                    validator: (_: any, value: Dayjs) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                  {
                    message: t("Thời gian kết thúc phải sau bắt đầu"),
                    validator: (_: any, value: Dayjs) => {
                      if (
                        Helper.datePickerValueToTimestamp(
                          form.getFieldValue("date_from")
                        ) < Helper.datePickerValueToTimestamp(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment(new Date())}
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"dd/mm/yyyy"}
                  format="DD/MM/YYYY"
                  className="w-full"
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
      <DownloadModal
        fileName={model.file_name}
        loading={loading}
        visible={showModal}
        setVisible={(showModal) => setShowModal(showModal)}
        handleCloseModal={() => {
          onCloseDownloadModal();
        }}
      />
    </>
  );
};

export default ReportCheckin;
