const ProductInventoryChecking = {
  STATUS_PENDING: 0,
  STATUS_REJECT: 1,
  STATUS_APPROVED: 5,
  STATUS_PROCESSING: 3,
  STATUS_COMPLETED: 7,
  STATUS_CANCELLED: 9,

  WARNING_CHECK: 1,
  WARNING_NONE: 0,
};

export default ProductInventoryChecking;
