
import { useState } from "react";
import { DataZoneBD } from "common/types/Event";



import { Button, Divider, Modal, Skeleton, message, notification } from "antd";
import SettingApi from "common/constants/Setting";

import PageSortList from "components/list/PageSortList";
import { DropResult } from "react-beautiful-dnd";

import useHandleSetting from "hooks/useHandleSetting";
import ListDeleteButton from "components/list/ListDeleteButton";
import ShopEventHeader from "./ShopEventHeader";
import ShopEventBD11Form from "../form/ShopEventBD11Form";

const ShopEventListBD11 = () => {
	const defaultDataPopup: DataZoneBD = {
		zone_data: [],
		zone_name: "sub_banner",
		zone_title: ""
	};

	const [open, setOpen] = useState<boolean>(false);
	const [lang, setLang] = useState<string>("vi");

	const [data, setData] = useState<{
		data: DataZoneBD;
		index: number;
		type: "add" | "edit";
	}>({
		data: defaultDataPopup,
		index: -1,
		type: "add",
	});

	const {
		dataSource,
		loading,
		addItemSetting,
		deleteItemSetting,
		editItemSetting,
		handleDrag,
	} = useHandleSetting<DataZoneBD>(SettingApi.KEY.K_custom_landing_page_birthdate, lang);

	const handleDragEnd = async (result: DropResult) => {
		try {
			if (!result.destination) return;
			await handleDrag(result.source.index, result.destination.index);
			message.success({
				content: "Thao tác thành công",
				duration: 1,
			});
		} catch (error) {
			message.error({
				content: "Thao tác thất bại",
				duration: 1,
			});
		}
	};

	// function handlelOpenModalAdd(data: DataZoneBD) {
	// 	setData({
	// 		data: data,
	// 		type: "add",
	// 		index: -1,
	// 	});
	// 	setOpen(true);
	// }
	function handlelOpenModalEdit(data: DataZoneBD, index: number) {
		setData({
			data: data,
			type: "edit",
			index,
		});
		setOpen(true);
	}

	async function handleSumit(dataRes: DataZoneBD) {
		try {
			if (data.type === "add") {
				await addItemSetting(dataRes);
			} else {
				await editItemSetting(dataRes, data.index);
			}
			setOpen(false)
		} catch (error) {
			notification.error({
				message: "Có lỗi xảy ra vui lòng thử lại",
				description: JSON.stringify(error)
			})
		}
	}

	async function handleDelete(data: DataZoneBD, index: number) {
		try {
			await deleteItemSetting(data, index);
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}



	return (
		<>
			<ShopEventHeader>

			</ShopEventHeader>
			<Divider />
			{!loading ? (
				<>
					<PageSortList
						dataSource={dataSource}
						handleDragEnd={handleDragEnd}
						listKey="shopBannerHome"
						renderListItem={(item: DataZoneBD, index) => {
							return (
								<>
									<div className="p-2 hover:bg-gray-200">
										<div className="flex  items-center justify-between">

											<div className=" w-2/3 relative">
												<h3>{item.zone_title}</h3>
											</div>
											<div className="  w-1/3 flex justify-center gap-2">
												<Button
													onClick={() => {
														handlelOpenModalEdit(item, index);
													}}
												>
													Sửa
												</Button>

												<ListDeleteButton
													handleDelete={() => {
														handleDelete(item, index);
													}}
													id={index}
												/>
											</div>
										</div>
									</div>
								</>
							);
						}}
					/>
				</>
			) : (
				<Skeleton active />
			)}

			<Modal
				title={data.type === "edit" ? `Cập nhật ${data.data.zone_title}` : "Thêm mới"}
				open={open}
				footer={<></>}
				onCancel={() => {
					setOpen(false)
				}}
				maskClosable={false}
				width={1000}
				destroyOnClose
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
			>
				<ShopEventBD11Form data={data.data} onFinish={handleSumit} type="edit" />
			</Modal>

		</>
	);
};

export default ShopEventListBD11;
