const ShippingRoute = {
  STATUS_NEW: 1, // Mới
  STATUS_PENDING: 7, // Chờ phản hồi
  STATUS_INPROGRESS: 9, // Đang giao hàng
  STATUS_COMPLETED: 11, // Hoàn thành
  STATUS_CANCELLED: 13, // Đã hủy
  STATUS_NOT_SUBMITTED_COD: 15, // Chưa nộp COD
  STATUS_SUBMITTED_COD: 17, // Đã nộp COD

  FROM_INTERNAL: 1,
  FROM_MERCHANT: 3,
  FROM_CUSTOMER: 5,
  FROM_OTHER: 7,

  //because google maps directions service limit waypoint to 23
  //we must define constant here for split waypoints function
  MAX_WAYPOINT_PER_ROUTE_FOR_DIRECTION_SERVICE: 23,
};

export default ShippingRoute;
