import { GoongMapCoordinates } from 'common/types/goongmap/GoongMapType';
import React from 'react';

import { Popup } from '@goongmaps/goong-map-react';
import { PositionType } from '@goongmaps/goong-map-react/src/utils/dynamic-position';

const GoongMapInfoWindow = ({
  placement,
  mapCoordinates,
  children,
  offsetTop,
  offsetLeft,
  onClose,
}: {
  placement?: PositionType;
  mapCoordinates: GoongMapCoordinates;
  children?: React.ReactNode;
  offsetTop?: number;
  offsetLeft?: number;
  onClose: () => void;
}) => {
  return (
    <>
      <Popup
        tipSize={5}
        anchor={placement}
        offsetTop={offsetTop}
        longitude={mapCoordinates.longitude}
        latitude={mapCoordinates.latitude}
        closeOnClick={false}
        onClose={onClose}
      >
        {children}
      </Popup>
    </>
  );
};

export default GoongMapInfoWindow;
