import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WarehouseFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("warehouse:heading_nav"),
      link: "/warehouse",
    },
  ];

  return (
    <PageHeader
      heading={t(
        isEditing ? "warehouse:heading_edit" : "warehouse:heading_add"
      )}
      nav={nav}
      siteMenuSelectedKey="/warehouse"
      siteMenuHidden
    ></PageHeader>
  );
};

export default WarehouseFormHeader;
