import { Alert, Button, Popover, Result } from "antd";
import ProductReceiptSyncStockCollection from "common/collections/ProductReceiptSyncStockCollection";
import ProductReceiptSyncStockModel from "common/models/ProductReceiptSyncStockModel";
import ProductReceiptSyncStockRepository from "common/repositories/ProductReceiptSyncStockRepository";
import { FilterProductReceiptSyncStock } from "common/types/ProductReceiptSyncStock";
import { ProductVariantInMemory } from "common/types/ProductVariant";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import PageDataTable from "components/page/PageDataTable";
import TextDateTime from "components/TextDateTime";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import produce from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import InventoryWaitingAdd from "./InventoryWaitingAdd";
import InventoryWaitingHeader from "./InventoryWaitingHeader";

const InventoryWaiting = () => {
  const { t } = useTranslation();

  const [warehouseItems] = useDatabaseTable<WarehouseJson>("warehouse");

  //////////////////////////////////////////
  //Filtering

  const defaultFilters: FilterProductReceiptSyncStock = useMemo(
    () => ProductReceiptSyncStockRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterProductReceiptSyncStock>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<ProductReceiptSyncStockModel[]>(
    []
  );
  const fetchData =
    useCallback(async (): Promise<ProductReceiptSyncStockCollection> => {
      let collection = await new ProductReceiptSyncStockRepository().getItems({
        filters,
      });
      setDataSource(collection.items);

      return collection;
    }, [filters]);

  const onAddManualSuccess = useCallback(
    (newItem: ProductReceiptSyncStockModel) => {
      setDataSource(
        produce(dataSource, (draft) => {
          draft.unshift(newItem);
          return draft;
        })
      );
    },
    [dataSource]
  );

  //Table columns
  const columns: TableColumnsType<ProductReceiptSyncStockModel> = [
    {
      title: t("productvariant:sid"),
      key: "product_variant_id",
      width: 50,
    },
    {
      title: t("productvariant:sku"),
      key: "product_variant",
      width: 150,
      render: (product_variant?: ProductVariantInMemory) => {
        return <>{product_variant?.sku}</>;
      },
    },
    {
      title: t("productreceipt:warehouse_id"),
      key: "warehouse_id",
      width: 150,
      filters: warehouseItems.map((item) => ({
        value: item.id,
        text: item.name,
      })),
      filteredValue:
        +filters.warehouse_id >= 0 ? [+filters.warehouse_id] : null,
      filterSearch: true,
      render: (warehouse_id: number) => {
        if (warehouse_id === 0) {
          return t("inventory:all_warehouse");
        } else {
          const found = warehouseItems.find((i) => i.id === warehouse_id);
          if (typeof found !== "undefined") {
            return <span>{found.name}</span>;
          } else {
            return "ID #" + warehouse_id;
          }
        }
      },
    },
    {
      title: t("productvariant:color"),
      key: "product_variant",
      width: 150,
      render: (product_variant?: ProductVariantInMemory) => {
        return (
          <>
            <TextProductVariantColor id={product_variant?.color || 0} />
          </>
        );
      },
    },
    {
      title: t("productvariant:size"),
      key: "product_variant",
      width: 150,
      render: (product_variant?: ProductVariantInMemory) => {
        return (
          <>
            <TextProductVariantSize id={product_variant?.size || 0} />
          </>
        );
      },
    },
    {
      title: t("product:name"),
      key: "product_variant",
      render: (product_variant?: ProductVariantInMemory) => {
        return <>{product_variant?.product_name}</>;
      },
    },
    {
      title: t("product:code"),
      width: 150,
      key: "product_variant",
      render: (product_variant?: ProductVariantInMemory) => {
        return <>{product_variant?.product_code}</>;
      },
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 160,
      render: (ts: number) => (
        <TextDateTime format="DD/MM/YYYY HH:mm:ss" ts={ts} />
      ),
    },
  ];

  return (
    <>
      <InventoryWaitingHeader />
      <Alert
        message={
          <>
            {t("inventory:waiting_description")}
            <Popover
              overlayInnerStyle={{ width: 700 }}
              placement="bottom"
              trigger="click"
              content={
                <InventoryWaitingAdd onAddSuccess={onAddManualSuccess} />
              }
            >
              <Button type="primary" ghost size="small" className="ml-1">
                {t("inventory:waiting_add")}
              </Button>
            </Popover>
          </>
        }
        showIcon
        type="info"
      />
      {dataSource.length > 0 ? (
        <PageDataTable<
          FilterProductReceiptSyncStock,
          ProductReceiptSyncStockModel,
          ProductReceiptSyncStockCollection
        >
          {...{
            columns,
            defaultFilters,
            filters,
            setFilters,
            dataSource,
            fetchData,
            rowKey: "product_variant_id",
          }}
        ></PageDataTable>
      ) : (
        <>
          <Result
            status="success"
            title={t("inventory:waiting_result_title")}
            subTitle={t("inventory:waiting_result_subtitle")}
            extra={[]}
          />
        </>
      )}
    </>
  );
};

export default InventoryWaiting;
