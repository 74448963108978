import React, { useState, useEffect } from "react";
import EmployeeList from "features/employee/list/EmployeeList";
import EmployeeForm from "features/employee/form/EmployeeForm";
import EmployeeFormHeader from "features/employee/form/EmployeeFormHeader";
import FormEditFetcher from "components/form/FormEditFetcher";
import EmployeeModel from "common/models/EmployeeModel";
import usePathParams from "hooks/usePathParams";
import EmployeeRepository from "common/repositories/EmployeeRepository";

const EmployeePage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<EmployeeModel>(
    new EmployeeModel(EmployeeModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new EmployeeModel(EmployeeModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <EmployeeForm
          model={new EmployeeModel(EmployeeModel.getDefaultData())}
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <EmployeeForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new EmployeeRepository().getItemFullDetail(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<EmployeeFormHeader isEditing={true} />}
          />
        );

      break;
    default:
      com = <EmployeeList />;
  }

  return com;
};

export default EmployeePage;
