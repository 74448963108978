import { Form, FormInstance, Input } from 'antd';
import TagStringInput from 'components/TagStringInput';
import { useTranslation } from 'react-i18next';

const OrderFormSectionGeneral = ({
  form,
  allowEditInput,
}: {
  form: FormInstance;
  allowEditInput: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item label={t('order:tag')} name='tag'>
        <TagStringInput disabled={!allowEditInput} />
      </Form.Item>
      <Form.Item label={t('order:note')} name='note'>
        <Input.TextArea
          disabled={!allowEditInput}
          autoSize
          style={{ minHeight: 56, maxHeight: 120 }}
        />
      </Form.Item>
    </>
  );
};

export default OrderFormSectionGeneral;
