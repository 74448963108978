import { Form, Input } from "antd";
import TaxInvoice from "common/constants/TaxInvoice";
import TaxInvoiceDetailModel from "common/models/TaxInvoiceDetailModel";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import TaxInvoiceDetailRepository from "common/repositories/TaxInvoiceDetailRepository";
import TaxInvoiceRepository from "common/repositories/TaxInvoiceRepository";
import LayoutForm from "components/form/LayoutForm";
import TaxInvoiceFormHeader from "features/taxinvoice/form/TaxInvoiceFormHeader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import TaxInvoiceFormSectionDetail from "./section/TaxInvoiceFormSectionDetail";
import TaxInvoiceFormSectionStatus from "./section/TaxInvoiceFormSectionStatus";

import type { TaxInvoiceJsonAddEdit } from "common/types/TaxInvoice";
const TaxInvoiceForm = ({ model }: { model: TaxInvoiceModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [initStatus, setInitStatus] = useState(
    model.status || TaxInvoice.STATUS_DRAFT
  );
  const [taxInvoiceDetails, setTaxInvoiceDetails] = useState<
    TaxInvoiceDetailModel[]
  >([]);
  const [status, setStatus] = useState(initStatus);

  const isEditing = model.id > 0;
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      id: model.id,
      note: model.note,
      status: model.status,
      seller_name: model.seller_name,
      seller_address: model.seller_address,
      seller_phone: model.seller_phone,
      seller_account_number: model.seller_account_number,
      seller_tax_number: model.seller_tax_number,
      buyer_name: model.buyer_name,
      buyer_address: model.buyer_address,
      buyer_phone: model.buyer_phone,
      buyer_account_number: model.buyer_account_number,
      buyer_tax_number: model.buyer_tax_number,
      buyer_email: model.buyer_email,
      buyer_receive_method: model.buyer_receive_method
    }),
    [model]
  );

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: TaxInvoiceJsonAddEdit = {
        ...TaxInvoiceModel.getDefaultData(),
        status,
        id: model.id,
        note: formData.note
      };

      return submitData;
    },
    [model, status]
  );

  //submit data to server
  const onSubmit = useCallback(
    async (formData: any) => {
      setErrors([]);

      let myObj: TaxInvoiceModel = await new TaxInvoiceRepository().saveRemote(
        doPrepareData(formData)
      );
      if (myObj.hasError()) {
        setErrors(myObj.error.errors);
      } else {
        //do linking with sale order

        //trigget new status to update EDIT FORM LOGIC (status)
        setInitStatus(myObj.status);

        setIsSuccess(true);
      }
    },
    [doPrepareData]
  );

  const fetchTaxInvoiceDetail = useCallback(async (id: number) => {
    const collection =
      await new TaxInvoiceDetailRepository().getFullDetailOfInvoice(id);
    if (!collection.hasError()) {
      setTaxInvoiceDetails(collection.items);
    }
  }, []);

  useEffect(() => {
    // get tax invoice detail
    fetchTaxInvoiceDetail(model.id);
  }, [model.id, fetchTaxInvoiceDetail]);

  let sidebarItems = (
    <>
      <TaxInvoiceFormSectionStatus
        status={status}
        setStatus={setStatus}
        initStatus={initStatus}
      />
      <Form.Item className="mt-4" label={t("taxinvoice:note")} name="note">
        <Input.TextArea autoSize style={{ minHeight: 56, maxHeight: 120 }} />
      </Form.Item>
    </>
  );

  return (
    <>
      <TaxInvoiceFormHeader isEditing={isEditing} id={model.id} />

      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("taxinvoice:form.success.update")
            : t("taxinvoice:form.success.add")
        }
        redirectOnSuccess={isEditing ? "" : "/taxinvoice"}
        error_translate_prefix="taxinvoice:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
        id="taxinvoice-form">
        <TaxInvoiceFormSectionDetail taxInvoiceDetails={taxInvoiceDetails} />
      </LayoutForm>
    </>
  );
};

export default TaxInvoiceForm;
