import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import Helper from "common/utils/helper";

type Filter = {
  [key: string]: number | string;
};

export default function usePathParams<T = {}>(): [Filter, (f: T) => string] {
  const location = useLocation();
  const parts = location.pathname.split("/");

  //discard first item (empty string)
  parts.shift();

  //now, the first item is the controller
  let controller = parts.shift();
  if (controller == null || controller === "") {
    controller = "index";
  }

  //now, the first item is the action
  let action = parts.shift();
  if (action == null || action === "") {
    action = "index";
  }

  //process dynamic params
  let dynamicParams: Filter = {};
  if (parts.length > 0) {
    for (let i = 0; i < parts.length; i += 2) {
      let value = parts[i + 1];
      if (value !== null && typeof value !== "undefined" && value.length > 0) {
        dynamicParams[parts[i]] =
          value.length > 0 && Helper.isNumber(value)
            ? parseInt(value)
            : unescape(value);
      }
    }
  }

  const getUrl = useCallback(
    (filters: T) => {
      let url = "/" + controller + "/" + action;
      for (const key in filters) {
        url += "/" + key + "/" + filters[key as keyof T];
      }
      return url;
    },
    [controller, action]
  );

  return [{ controller, action, ...dynamicParams }, getUrl];
}
