import { CompanySettingEntry, CompanySettingEntryValue } from 'common/types/CompanySetting';
import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

type CompanySettingStore = {
  data: CompanySettingEntry;
  setData: (data: CompanySettingEntry) => void;
  getSetting: (key: string) => CompanySettingEntryValue | undefined;
};

//init store data
let store: StateCreator<CompanySettingStore> = (set, get) => ({
  data: {},
  setData: (data) => set((state) => ({ ...state, data })),
  getSetting: (key) => get().data[key] || undefined,
});

//create store
const useCompanySettingStore = create(
  persist(store, { name: "MAIN::CompanySetting" })
);

export default useCompanySettingStore;
