import BaseCollection from "common/collections/BaseCollection";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import AccountModel from "common/models/AccountModel";
import { BaseCollectionJson } from "common/types/BaseCollectionJson";

import AxiosClient from "./AxiosClient";
import Error from "./Error";
export type ErrorLocal = {
	state: {
		fetchErrors: string[];
	};
	version: number;
};
class BaseApi {
	static loginAccount: AccountModel;
	debug: boolean;

	constructor(debug: boolean) {
		this.debug = debug;
	}

	//Handing error of request
	static handleError(axiosError: any) {
		let statusCode = 0;
		let errors = [];
		let error_detail: string[] = [];
		const errorLocal = localStorage.getItem("MAIN::Process")!;
		const errorLocalParse = JSON.parse(errorLocal) as ErrorLocal;

		if (axiosError != null) {
			if (
				axiosError.hasOwnProperty("response") &&
				typeof axiosError.response !== "undefined"
			) {
				statusCode = axiosError.response.status;
				if (axiosError.response.data["error"] != null) {
					if (typeof axiosError.response.data["error"] == "string") {
						errors.push(axiosError.response.data["error"]);
					} else if (typeof axiosError.response.data["error"] == "object") {
						errors = axiosError.response.data["error"];
						if (
							axiosError.response.status === 406 &&
							axiosError.response.data.hasOwnProperty("error_detail")
						) {
							error_detail = axiosError.response.data["error_detail"];
						}
					}
				} else {
					errors.push(axiosError.response.statusText);
				}
			} else {
				errors.push("api_network_error");
			}
		} else {
			errors.push("api_error_unexpected");
		}

		for (let i = 0; i < errors.length; i++) {
			if (errors[i] === "error_jwt_blacklisted") {
				//fire blacklistjwt
				//Todo...
			}
		}

		if (errors.length > 0) {
			if (errorLocalParse?.state?.fetchErrors?.length > 0) {
				localStorage.setItem(
					"MAIN::Process",
					JSON.stringify({
						state: {
							fetchErrors: [
								...new Set([...errorLocalParse.state.fetchErrors, ...errors]),
							],
						},
						version: 0,
					})
				);
			} else {
				localStorage.setItem(
					"MAIN::Process",
					JSON.stringify({
						state: {
							fetchErrors: [...new Set([...errors])],
						},
						version: 0,
					})
				);
			}
		}

		return new Error({ statusCode, errors });
	}

	static getLoggedUserId(): number {
		return this.loginAccount.id;
	}

	async getFullDataFetcher<
		MJson,
		M extends BaseModelJson<MJson>,
		J extends BaseCollectionJson<MJson>,
		C extends BaseCollection<MJson, M, J>
	>({
		collection,
		companyId,
		version,
		url,
	}: {
		collection: C;
		companyId: number;
		version: number;
		url: string;
	}): Promise<C> {
		try {
			let queryData = {
				params: {
					company_id: companyId,
					version: version,
				},
			};
			const response = await AxiosClient().get<any>(url, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson({
					total: response.data.length,
					limit: response.data.length,
					items: response.data,
					currentpage: 1,
				} as J);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}
}

export default BaseApi;
