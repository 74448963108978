import EcomPlatformWebhookApi from 'common/api/EcomPlatformWebhookApi';
import EcomModel from 'common/models/EcomModel';

import BaseRepository from './BaseRepository';

class EcomPlatformWebhookRepository extends BaseRepository<EcomPlatformWebhookApi> {
  _api: EcomPlatformWebhookApi | null;

  constructor() {
    super();
    this._api = new EcomPlatformWebhookApi(true);
  }

  async registerWebhook(id: number): Promise<EcomModel> {
    if (this._api) {
      return await this._api.registerWebhook(id);
    } else {
      return new EcomModel(EcomModel.getDefaultData());
    }
  }

  async unregisterWebhook(id: number): Promise<EcomModel> {
    if (this._api) {
      return await this._api.unregisterWebhook(id);
    } else {
      return new EcomModel(EcomModel.getDefaultData());
    }
  }
}

export default EcomPlatformWebhookRepository;
