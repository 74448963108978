import { Modal, Skeleton } from 'antd';
import WorkTrackingReviewRepository from 'common/repositories/WorkTrackingReviewRepository';
import { WorkTrackingDetailViewHistoryJson, WorkTrackingJson } from 'common/types/WorkTracking';
import TextDateTime from 'components/TextDateTime';
import TextUser from 'components/TextUser';
import WorkTrackingApprovalFormViewHistory from 'features/worktrackingapproval/form/WorkTrackingApprovalFormViewHistory';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const WorkTrackingApprovalFormViewHistoryModal = ({
  dataSourceWorkTracking,
  open,
  setOpen,
}: {
  dataSourceWorkTracking: WorkTrackingJson;
  open: boolean;
  setOpen: (v: boolean) => any;
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<
    WorkTrackingDetailViewHistoryJson[]
  >([]);

  const fetchDetail = async (id: number, office_id: number) => {
    setLoading(true);
    let myObj = await new WorkTrackingReviewRepository().getItem(id, office_id);
    if (!myObj.hasError()) {
      setDataSource(myObj.detail.review_history.reverse());
    }
    setLoading(false);
  };

  useEffect(() => {
    if (dataSourceWorkTracking.id > 0) {
      fetchDetail(dataSourceWorkTracking.id, dataSourceWorkTracking.office_id);
    }
  }, [dataSourceWorkTracking.id, dataSourceWorkTracking.office_id]);

  return (
    <Modal
      title={
        <>
          {t("worktracking:checkin_info_update_history")}{" "}
          {dataSourceWorkTracking.creator_id &&
          dataSourceWorkTracking.creator_id > 0 ? (
            <>
              {" - "}
              <TextUser id={dataSourceWorkTracking.creator_id} />
              {" ("}
              {
                <TextDateTime
                  ts={dataSourceWorkTracking.date_created}
                  format={"HH:mm, DD/MM/YYYY"}
                />
              }
              {")"}
            </>
          ) : (
            ""
          )}
        </>
      }
      width={1200}
      style={{ top: 20 }}
      open={open}
      closable
      maskClosable
      footer={null}
      onCancel={() => {
        setOpen(false);
        setDataSource([]);
      }}
      cancelText={t("common:close")}
    >
      <>
        {!loading ? (
          <WorkTrackingApprovalFormViewHistory dataSource={dataSource} />
        ) : (
          <>
            <Skeleton active={loading} />
          </>
        )}
      </>
    </Modal>
  );
};

export default WorkTrackingApprovalFormViewHistoryModal;
