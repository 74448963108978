import ShippingHub from 'common/constants/ShippingHub';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { AddressGeo } from 'common/types/AddressGeo';
import { Status } from 'common/types/Status';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { ShippingHubJson } from "common/types/ShippingHub";
class ShippingHubModel
  extends BaseModel
  implements BaseModelJson<ShippingHubJson>
{
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  code: string;
  phone: string;
  description: string;
  address: string;
  lat: number;
  long: number;
  map_place_id: string;
  display_order: number;
  region_id: number;
  sub_region_id: number;
  third_region_id: number;
  country: string;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: ShippingHubJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.code = json.code || "";
    this.phone = json.phone || "";
    this.description = json.description || "";
    this.address = json.address || "";
    this.lat = json.lat || 0;
    this.long = json.long || 0;
    this.map_place_id = json.map_place_id || "";
    this.display_order = json.display_order || 0;
    this.region_id = json.region_id || 0;
    this.sub_region_id = json.sub_region_id || 0;
    this.third_region_id = json.third_region_id || 0;
    this.country = json.country || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ShippingHubJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      code: "",
      phone: "",
      description: "",
      address: "",
      lat: 0,
      long: 0,
      map_place_id: "",
      display_order: 0,
      region_id: 0,
      sub_region_id: 0,
      third_region_id: 0,
      country: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): ShippingHubJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      code: this.code,
      phone: this.phone,
      description: this.description,
      address: this.address,
      lat: this.lat,
      long: this.long,
      map_place_id: this.map_place_id,
      display_order: this.display_order,
      region_id: this.region_id,
      sub_region_id: this.sub_region_id,
      third_region_id: this.third_region_id,
      country: this.country,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): Status[] {
    return [
      {
        value: ShippingHub.STATUS_ENABLE,
        text: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ShippingHub.STATUS_DISABLED,
        text: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): Status {
    let foundItem = this.getStatusList().find((item) => item.value === value);
    if (typeof foundItem === "undefined") {
      foundItem = {
        value: 0,
        text: "",
        color: "",
      };
    }
    return foundItem;
  }

  getAddressGeo(): AddressGeo {
    return {
      address: this.address,
      lat: this.lat,
      long: this.long,
      map_place_id: this.map_place_id,
    };
  }
}

export default ShippingHubModel;
