import { Tooltip } from "antd";
import Company from "common/constants/Company";
import CompanyModel from "common/models/CompanyModel";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconBolt } from "@tabler/icons-react";

const HeaderCompanyPlan = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const account = useLoginAccountStore((state) => state.account);

  return (
    <div className="text-sm block pl-3 pt-5">
      {account.company.plan === Company.PLAN_01 ||
      (account.id === account.company.user_id &&
        account.company.plan !== Company.PLAN_ON_PREMISE) ? (
        <>
          <Tooltip
            title={t("company:plan.upgrade_label_tooltip", {
              plan_name: CompanyModel.getPlan(account.company.plan)?.label,
            })}
            placement="left"
            mouseEnterDelay={0.4}
          >
            <IconBolt
              size={16}
              className="cursor-pointer mr-1 -mt-0.5 text-yellow-500"
              onClick={() => navigate("/company/upgrade")}
            />
            <span className="font-bold mr-1 text-yellow-500 hidden sm:inline-block">
              {CompanyModel.getPlan(account.company.plan)?.label}
            </span>
            <Link
              to="/company/upgrade"
              className="text-gray-300 text-xs hover:text-blue-500 hidden sm:inline-block"
            >
              ({t("company:plan.upgrade_label")})
            </Link>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export default HeaderCompanyPlan;
