import BaseCollection from 'common/collections/BaseCollection';
import ShipperModel from 'common/models/ShipperModel';
import { ShipperCollectionJson, ShipperJson } from 'common/types/Shipper';

class ShipperCollection extends BaseCollection<
  ShipperJson,
  ShipperModel,
  ShipperCollectionJson
> {
  itemsFromJson(jsonItems: ShipperJson[]): ShipperModel[] {
    return jsonItems.map((item) => new ShipperModel(item));
  }
}

export default ShipperCollection;
