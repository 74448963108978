import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import RoleCheck from "components/RoleCheck";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const TaskDetailsHeader = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [taskId, setTaskId] = useState<number>(
    Number(searchParams.get("parent_id"))
  );

  useEffect(() => {
    if (Number(searchParams.get("parent_id")) !== taskId) {
      setTaskId(Number(searchParams.get("parent_id")));
    }
  }, [searchParams.get("parent_id")]);

  return (
    <PageHeader
      heading={t(`Chi tiết công việc`)}
      siteMenuOpenKey="task"
      siteMenuSelectedKey="/task"
    >
      <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <RoleCheck roles={[]}>
            <PageHeaderButton link={`/task/add/id/${taskId}`}>
              {t("template:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default TaskDetailsHeader;
