import { App } from "antd";
import regionData from "common/data/region.json";
import CompanyResourceModel from "common/models/CompanyResourceModel";
import CompanyResourceRepository from "common/repositories/CompanyResourceRepository";
import FullDataRepository from "common/repositories/FullDataRepository";
import { FullDataJson } from "common/types/FullData";
import { ProductMapping } from "common/types/Pos";
import { ProductJson } from "common/types/Product";
import {
  ProductVariantInMemory,
  ProductVariantJsonBase,
} from "common/types/ProductVariant";
import eventEmitter from "common/utils/eventEmitter";
import Helper from "common/utils/helper";
import CompanyResourceError from "components/companyresource/CompanyResourceError";
import Header from "components/header/Header";
import db from "db";
import dbm from "dbm";
import update from "immutability-helper";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { CompanyResourceUpdate } from "common/types/CompanyResource";
const CompanyResourceWrapper = ({
  children,
  setLoadCompanySettingDone,
}: {
  children: React.ReactNode;
  setLoadCompanySettingDone: (v: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [errorAuthorized, setErrorAuthorized] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const [ready, setReady] = useState(false);
  const regionInitRef = useRef(false);
  const companyId = useLoginAccountStore((state) => state.account.company.id);

  const loadRegionToMemory = useCallback(async () => {
    //Clear current data in memory (prepare for new data set)
    await dbm.addCollection("region", {
      indices: ["id", "display_order", "parent_id"],
      disableMeta: true,
    });
    await dbm.getCollection("region").removeDataOnly();
    await dbm.getCollection("region").insert(regionData);
    console.log(
      "✔️ Loaded region to memory: region(" + regionData.length + ")"
    );
    regionInitRef.current = true;
  }, []);

  ////////////////////////////////////////////////////////////////////////////////
  // for performance, after have database in indexedDb,
  // we clone some resource to memory for performance
  const initDatabaseMemory = useCallback(async () => {
    console.log("💻 Init some resources to browser memory...");

    //check init region first
    if (!regionInitRef.current) {
      await loadRegionToMemory();
    }

    //list of resource will store to Browser memory
    const requiredResources = [
      { name: "employee", indices: ["id", "full_name"] },
      { name: "product", indices: ["id", "code", "name"] },
      { name: "productvariant", indices: ["id", "sku"] },
      { name: "productvariantsize", indices: ["id"] },
      { name: "productvariantcolor", indices: ["id"] },
    ];

    //queue for exec insert process
    let dbBulkAddPromises = [];
    let insertLogs: string[] = [];

    //Confirm all DB exist before loaded to Memory
    for (let i = 0; i < requiredResources.length; i++) {
      let resource = requiredResources[i];
      if (!db.hasOwnProperty(resource.name)) {
        setError((prevState) =>
          update(prevState, {
            $push: [
              t("common:error.error_not_found_database_table", {
                table: resource.name,
              }),
            ],
          })
        );
      } else {
        //Important, create memoryDB tables (lokijs collection)
        dbm.addCollection(resource.name, {
          indices: resource.indices,
          disableMeta: true,
        });

        //Clear current data in memory (prepare for new data set)
        await dbm.getCollection(resource.name).removeDataOnly();

        //default data will store in dbm
        //If some resource need pre-processing, just manipulating the items
        //(see way of product, productvariant resource change items below)
        let items = await db.table(resource.name).toArray();

        /////////////////////////////////////////////////////////////////
        //for PRODUCT, we need to get "search" props
        //before insert to DBM
        if (resource.name === "product") {
          items = items.map((item: ProductJson) => {
            return {
              ...item,
              search:
                item.name +
                " " +
                Helper.codau2khongdau(item.name) +
                " " +
                item.code,
            };
          });
        }
        //end process PRODUCT

        /////////////////////////////////////////////////////////////////
        //for variant, we need to get some info from 'product'
        //before insert to DBM
        if (resource.name === "productvariant") {
          let newProductNameMappings: ProductMapping[] = [];
          if (db.hasOwnProperty("product")) {
            //build productmapping for performance when create variants
            const products: ProductJson[] = await db.table("product").toArray();

            products.forEach((item) => {
              newProductNameMappings[item.id] = {
                search:
                  item.name +
                  " " +
                  Helper.codau2khongdau(item.name) +
                  " " +
                  item.code,
                product_name: item.name,
                product_code: item.code,
                product_status: item.status,
                category_id: item.category_id,
                sell_on_zero: item.sell_on_zero,
                supplier_id: item.supplier_id,
              };
            });
          }

          items = items.map((item: ProductVariantJsonBase) => {
            const foundProduct = _.defaultTo(
              newProductNameMappings[item.product_id],
              null
            );

            if (foundProduct !== null) {
              return {
                ...item,
                product_name: _.defaultTo(foundProduct.product_name, ""),
                product_code: _.defaultTo(foundProduct.product_code, ""),
                product_status: _.defaultTo(foundProduct.product_status, ""),
                supplier_id: _.defaultTo(foundProduct.supplier_id, []),
                search:
                  _.defaultTo(foundProduct.search, "") + " " + item.sku + " ",
                category_id: _.defaultTo(foundProduct.category_id, 0),
                sell_on_zero: _.defaultTo(foundProduct.sell_on_zero, 1),
                inventory_details: [],
              };
            } else {
              return {
                ...item,
                product_name: "",
                product_code: "",
                search: item.sku,
                category_id: 0,
                sell_on_zero: 1,
                inventory_details: [],
              };
            }
          }) as ProductVariantInMemory[];
        }
        //end process productvariant

        insertLogs.push(resource.name + "(" + items.length + ")");

        //queue the
        dbBulkAddPromises.push(dbm.getCollection(resource.name).insert(items));
      }
    }

    //execute the task add to memory
    await Promise.all(dbBulkAddPromises)
      .then((result) => {
        console.log(
          "✔️ Loaded fast-access resource to memory:",
          insertLogs.join(", ")
        );

        //mark all resource loaded
        setReady(true);
      })
      .catch((err) => console.log(err));
  }, [loadRegionToMemory, t]);

  ////////////////////////////////////////////////////////////////////////////////
  // Store data after fetched from all collections
  const storePersistFullData = useCallback(
    async (
      myCompanyResource: CompanyResourceModel,
      allResults: FullDataJson[]
    ) => {
      /////////////////////////////////////////
      //clear all companyresource tables
      var dbClearPromises: Promise<any>[] = [];
      allResults.map((result) => {
        if ("resource" in result && db.table(result.resource) !== null) {
          return dbClearPromises.push(db.table(result.resource).clear());
        } else {
          return null;
        }
      });
      await Promise.all(dbClearPromises);

      /////////////////////////////////////////
      //Queue to store to indexDb for resources
      var dbBulkAddPromises: Promise<any>[] = [];
      allResults.map((result) => {
        if (db.table(result.resource) !== null) {
          //remove "error" property from Model before insert to db
          console.log("➕ bulkAdd on table", result.resource);
          try {
            //for performance, in 'productvariant', we do not get object property
            //we need to rebuild the object before insert to database
            if (
              result.resource === "productvariant" &&
              result.data.length > 0
            ) {
              return dbBulkAddPromises.push(
                db.table("productvariant").bulkAdd(
                  result.data.map((item) => ({
                    id: item[0],
                    sku: item[1],
                    price: item[2],
                    title: item[3],
                    size: item[4],
                    color: item[5],
                    product_id: item[6],
                    status: item[7],
                  }))
                )
              );
            } else {
              return result.data
                ? dbBulkAddPromises.push(
                    db.table(result.resource).bulkAdd(result.data)
                  )
                : null;
            }
          } catch (e) {
            alert("Error on add data to table " + result.resource);
            return null;
          }
        } else {
          return null;
        }
      });

      //execute the process of save to indexdb (exec queued table.bulkAdd())
      await Promise.all(dbBulkAddPromises);

      /////////////////////////////////////////
      //store to db (for use later or cache when offline)
      if (typeof myCompanyResource !== "undefined") {
        const storeResource = myCompanyResource.toJson();
        console.log(myCompanyResource);
        db.table("companyresource")
          .where("company_id")
          .equals(myCompanyResource.company_id)
          .count()
          .then((count) => {
            if (count === 0) {
              db.table("companyresource").add(storeResource);
            } else {
              db.table("companyresource").put(storeResource);
            }
          })
          .catch((e) =>
            console.log(
              "Error while finding companyresource on local indexDb",
              e
            )
          );
      }

      await initDatabaseMemory();

      /////////////////////////////////////////
      //everything Ok
      setLoadCompanySettingDone(true);
    },
    [setLoadCompanySettingDone, initDatabaseMemory]
  );
  //end processing for store data
  ////////////////////////////////////////////////////////////////////////////////

  const fetchUpdatedFullData = useCallback(
    async (
      myCompanyResource: CompanyResourceModel,
      resources: CompanyResourceUpdate[]
    ) => {
      console.log("fetching changed resources...");
      message.loading({
        content: t("common:companyresource.fetching_fulldata"),
        key: "fetching_fulldata",
        duration: 0,
      });

      const collection = await new FullDataRepository().doFetching({
        resources,
      });
      message.destroy("fetching_fulldata");
      if (!collection.hasError()) {
        const reasons: string[] = [];
        const allResults: FullDataJson[] = [];

        //loop to update db
        collection.items.forEach((fulldata) => {
          if (fulldata.state === "fulfilled") {
            allResults.push(fulldata.toJson());
          } else {
            reasons.push(fulldata.reason[0]);
          }
        });

        //detect error
        if (reasons.length > 0) {
          setError(reasons);
        }

        //store fulldata result
        if (allResults.length > 0) {
          storePersistFullData(myCompanyResource, allResults);
        }
      }
    },
    [storePersistFullData, t, message]
  );

  const getFullCompanyResource = useCallback(
    async (companyId: number) => {
      //get current companyresource from db
      const savedCompanyResources = await db.table("companyresource").toArray();

      let collection =
        await new CompanyResourceRepository().getSingleCompanyResource(
          companyId
        );

      if (collection.hasError()) {
        setError(collection.error.errors);

        if (
          collection.error.statusCode === 401 ||
          collection.error.statusCode === 400
        ) {
          setErrorAuthorized(true);
        }
      } else {
        if (collection.items.length === 0) {
          setError([t("common:error.error_company_resource_not_found")]);
        } else {
          //we can compare to saved from companyresource
          const newVersion = collection.items[0].jsversion;

          //list of resoruce to load
          let resources: CompanyResourceUpdate[] = [];

          //if no saved resource, we create fake resource to compare
          let savedVersion = null;
          if (savedCompanyResources.length > 0) {
            //Very important,
            //Check company_id of saved and new loaded resource
            if (
              savedCompanyResources[0].company_id ===
              collection.items[0].company_id
            ) {
              savedVersion = savedCompanyResources[0].jsversion;
            }
          }

          // calculate & company to saved resource to prevent download existed data
          if (
            savedVersion == null ||
            newVersion.companyoffice !== savedVersion.companyoffice
          ) {
            resources.push({
              resource: "companyoffice",
              version: newVersion.companyoffice,
            });
          }

          if (
            savedVersion == null ||
            newVersion.warehouse !== savedVersion.warehouse
          ) {
            resources.push({
              resource: "warehouse",
              version: newVersion.warehouse,
            });
          }

          if (savedVersion == null || newVersion.store !== savedVersion.store) {
            resources.push({
              resource: "store",
              version: newVersion.store,
            });
          }

          if (
            savedVersion == null ||
            newVersion.employee !== savedVersion.employee
          ) {
            resources.push({
              resource: "employee",
              version: newVersion.employee,
            });
          }
          if (
            savedVersion == null ||
            newVersion.productcategory !== savedVersion.productcategory
          ) {
            resources.push({
              resource: "productcategory",
              version: newVersion.productcategory,
            });
          }
          if (
            savedVersion == null ||
            newVersion.product !== savedVersion.product
          ) {
            resources.push({
              resource: "product",
              version: newVersion.product,
            });
          }
          if (
            savedVersion == null ||
            newVersion.productvariant !== savedVersion.productvariant
          ) {
            resources.push({
              resource: "productvariant",
              version: newVersion.productvariant,
            });
          }
          if (
            savedVersion == null ||
            newVersion.productvariantsize !== savedVersion.productvariantsize
          ) {
            resources.push({
              resource: "productvariantsize",
              version: newVersion.productvariantsize,
            });
          }
          if (
            savedVersion == null ||
            newVersion.productvariantcolor !== savedVersion.productvariantcolor
          ) {
            resources.push({
              resource: "productvariantcolor",
              version: newVersion.productvariantcolor,
            });
          }

          if (
            savedVersion == null ||
            newVersion.customertype !== savedVersion.customertype
          ) {
            resources.push({
              resource: "customertype",
              version: newVersion.customertype,
            });
          }

          if (
            savedVersion == null ||
            newVersion.shippingcarrier !== savedVersion.shippingcarrier
          ) {
            resources.push({
              resource: "shippingcarrier",
              version: newVersion.shippingcarrier,
            });
          }

          if (
            savedVersion == null ||
            newVersion.companysetting !== savedVersion.companysetting
          ) {
            resources.push({
              resource: "companysetting",
              version: newVersion.companysetting,
            });
          } else {
            setLoadCompanySettingDone(true);
          }

          //if there is nothing to load (all new already)
          if (resources.length === 0) {
            console.log("Need to fetch Company Resource: 0");
            setError([]);
            initDatabaseMemory();
          } else {
            //set to trigger
            fetchUpdatedFullData(collection.items[0], resources);
            console.log(
              "Need to fetch Company Resource: ",
              resources.length,
              "(",
              resources
                .map((r) => r.resource + " v." + r.version + "")
                .join(", "),
              ")"
            );
          }
        }
      }
    },
    [t, setLoadCompanySettingDone, initDatabaseMemory, fetchUpdatedFullData]
  );

  const triggerLogout = useCallback(() => {
    setError([]);
    eventEmitter.emit("DO_LOGOUT", { source: "company_resource_error" });
  }, []);

  useEffect(() => {
    if (companyId > 0) {
      getFullCompanyResource(companyId);
    } else if (companyId === 0 && !regionInitRef.current) {
      loadRegionToMemory();
    }
  }, [getFullCompanyResource, loadRegionToMemory, companyId]);

  return (
    <>
      {(regionInitRef.current && ready) || companyId === 0 ? (
        children
      ) : (
        <>
          <Header />
          {!process.env.REACT_APP_RUN_COMPANY_RESOURCE && children}
        </>
      )}
      {process.env.RUN_COMPANY_RESOURCE && error.length > 0 && (
        <div id="companyresource-wrapper">
          <div id="companyresource-inner">
            <CompanyResourceError
              error={error}
              errorAuthorized={errorAuthorized}
              setError={setError}
              doLogout={triggerLogout}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyResourceWrapper;
