import ProjectTaskApi from "common/api/ProjectTaskApi";
import ProjectTaskCollection from "common/collections/ProjectTaskCollection";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  ProjectTaskJsonAddEdit,
  FilterProjectTask,
  ProjectTaskJsonUpdateStatus
} from "common/types/ProjectTask";

class ProjectTaskRepository extends BaseRepository<ProjectTaskApi> {
  _api: ProjectTaskApi | null;

  constructor() {
    super();
    this._api = new ProjectTaskApi(true);
  }

  static getDefaultFilters(): FilterProjectTask {
    return {
      page: 1,
      limit: 50,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      company_id: -1,
      project_id: -1,
      priority: -1,
      type: -1,
      tag: "",
      iteration_id: -1,
      id: 0,
      code: "",
      assignee_tpm: -1 || "",
      assignee_user: -1,
      assignee_type: "",
      is_pipeline: 0,
      status_list: "",
      expired: -1
    };
  }

  async getItems(props: GetListProps<FilterProjectTask>) {
    return this._api
      ? await this._api.getList(props)
      : new ProjectTaskCollection();
  }

  async getItem(id: number): Promise<ProjectTaskModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProjectTaskModel(ProjectTaskModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: ProjectTaskJsonAddEdit): Promise<ProjectTaskModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProjectTaskModel(ProjectTaskModel.getDefaultData());
    }
  }

  async updateStatus(
    data: ProjectTaskJsonUpdateStatus
  ): Promise<ProjectTaskModel> {
    if (this._api) {
      return await this._api.updateStatus(data);
    } else {
      return new ProjectTaskModel(ProjectTaskModel.getDefaultData());
    }
  }
}

export default ProjectTaskRepository;
