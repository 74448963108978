import { List, Skeleton, Tag, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import HomeLeftListWrapper from "../HomeLeftListWrapper";
import TextDateTime from "components/TextDateTime";
import { FilterLeave, LeaveJson } from "common/types/Leave";
import LeaveModel from "common/models/LeaveModel";
import LeaveRepository from "common/repositories/LeaveRepository";
import UserAvatar from "components/UserAvatar";
import UserIdAvatar from "components/UserIdAvatar";
import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";
import useIdFetcher from "hooks/useIdFetcher";
import { IdFetcherResult } from "common/types/IdFetcher";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import UserNameAvatar from "components/template/UserNameAvatar";
import { IconCalendarTime } from "@tabler/icons-react";
import useProcessStore from "zustands/useProcessStore";
import useDatabaseTable from "hooks/useDatabaseTable";
import { EmployeeJson } from "common/types/Employee";
type Data = LeaveJson & {
  loading?: boolean;
};
const HomeLeftLeaveOff = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  const { getListIdFetcher } = useIdFetcher();
  //////////////////////////////
  //state
  const [dataSource, setDataSource] = useState<Data[]>(
    Array.from({ length: 10 }).map(() => ({
      ...LeaveModel.getDefaultData(),
      loading: false,
    }))
  );
  const defaultFilters = useMemo(() => {
    return LeaveRepository.getDefaultFilters();
  }, []);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  const [employeeId, setEmployeeId] = useState(0);
  // const [employeeResult, setEmployeeResult] = useState<IdFetcherResult[]>([]);
  const [employeeResult] = useDatabaseTable<EmployeeJson>("employee");

  ////////////////////////
  //fetch data
  const fetchData = async () => {
    const response = await new LeaveRepository().getLeaveOffToday();

    if (!response.hasError()) {
      setDataSource(response.items);
      // if (response.items.length > 0) {
      //   const employeeIds = response.items.map((i) => i.employee_id);
      //   const idFetcherResponse = await getListIdFetcher({
      //     ids: employeeIds,
      //     object_type: "employee",
      //   });
      //   if (!idFetcherResponse.hasError()) {
      //     setEmployeeResult(idFetcherResponse.items[0].result);
      //   }
      // }
    } else {
      setErrors(response.error.errors);
    }
    setLoading(false);
  };


  function renderInfoEmployee(id: number, key: keyof EmployeeJson) {
    const currentUser = employeeResult.find((item) => item.id === id)
    if (!currentUser) {
      return <>Trống</>
    }
    return <>
      {currentUser[key]}
    </>

  }

  ///////////////////////
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div>
      <HomeLeftListWrapper<FilterLeave, Data>
        {...{
          filters,
          setFilters,
          dataSource,
          errors,
          iconTitle: <IconCalendarTime size={18} />,
          loading: loading,
          title: t("common:home.leave_off"),

          renderItem(item) {
            return (
              <Skeleton
                className="px-2"
                loading={item.loading}
                active
                avatar
                title={false}
                round
              >
                <div
                  className="flex justify-between gap-2 border-[1px] border-gray-200 p-2 my-2 cursor-pointer"
                  key={item.id}
                  onClick={() => {
                    setEmployeeId(item.employee_id);
                  }}
                >
                  <div className="flex items-center">
                    <UserNameAvatar employee_id={item.employee_id} />
                  </div>
                  <div className="flex justify-center flex-col flex-1 gap-1">
                    <div className="flex items-center justify-between">
                      <Text>
                        {/* <IdFetcherRender
                          mapping={employeeResult}
                          key_value="text"
                          value={item.employee_id}
                        /> */}
                        {renderInfoEmployee(item.employee_id, "full_name")}

                        {/* <Text className="ml-2" strong color="blue">
                          <IdFetcherRender
                            mapping={employeeResult}
                            key_value="internal_id"
                            value={item.employee_id}
                          />
                      </Text> */}
                    </Text>
                    <Tag className="mr-0" color="blue">
                      {item.dayoff} ngày
                    </Tag>
                  </div>
                  <div className="flex justify-between items-center gap-1">
                    <Text type="secondary">
                      {t("common:columns.from")}:{" "}
                      {new Date(item.date_from * 1000).toLocaleDateString(
                        "en-GB"
                      )}
                    </Text>
                    <Text type="secondary">
                      {t("common:columns.to")}:{" "}
                      {new Date(item.date_to * 1000).toLocaleDateString(
                        "en-GB"
                      )}
                    </Text>
                  </div>
                </div>
              </div>
              </Skeleton>
  );
},
        }}
      ></HomeLeftListWrapper >
  <PhoneBookModal
    id={employeeId}
    visible={employeeId > 0}
    setVisible={() => {
      setEmployeeId(0);
    }}
  ></PhoneBookModal>
    </div >
  );
};

export default HomeLeftLeaveOff;
