import JsBarcode from "jsbarcode";
import { useEffect, useState } from "react";

export default function useBarcode(code: string) {
  const [barcodeSrc, setBarcodeSrc] = useState("");
  const generateBarcode = (text: string) => {
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, text, { format: "CODE128" });
    const src = canvas.toDataURL("image/png");
    setBarcodeSrc(src);
  };
  useEffect(() => {
    if(code){
      generateBarcode(code);
    }
  }, [code]);

  return { barcodeSrc };
}
