import { Col, DatePickerProps, Form, Input, Row } from "antd";
import { Filter } from "common/types/Filter";
import { useTranslation } from "react-i18next";

import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DepartmentFormFilter from "features/department/DepartmentFormFilter";
import CheckinModel from "common/models/CheckinModel";
import DateFormFilter from "components/datetime/DateFormFilter";
import { useMemo } from "react";
import dayjs from "dayjs";
import { FilterCheckin } from "features/phonebook/list/PhoneBookListFilter";

const CheckinListFilter = <F extends Filter>({
	total,
	filters,
	setFilters,
	defaultFilters,
}: {
	total: number;
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
}) => {
	const { t } = useTranslation();

	////////////////////////////////
	// process
	const getValueFilter = useMemo(() => {
		const entries = Object.entries(filters).filter(
			([key, val]) => key === "date_created"
		);

		const [createdVal] = entries.map(([key, val]) => +val);

		if (createdVal > 0) {
			const createdDate = dayjs.unix(createdVal).format("DD/MM/YYYY");
			return dayjs(createdDate, "DD/MM/YYYY");
		}

		return null;
	}, [filters]);
	////////////////////////////////////
	// event
	const onChange: DatePickerProps["onChange"] = (
		value: DatePickerProps["value"]
	) => {
		if (typeof value !== "undefined" && value !== null) {
			setFilters({
				...filters,
				date_created: value.unix(),
			});
		} else {
			setFilters({
				...filters,
				date_created: 0,
			});
		}
	};

	return (
		<div id="checkin_filter">
			<PageDataFilterForm
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				convertValueByKeyFilter={[]}>
				<Form.Item name="keyword">
					<Input allowClear placeholder={t("checkin:filter.key_word")} />
				</Form.Item>

				<Form.Item>
					<DateFormFilter
						mode="single"
						allowClear
						onChange={onChange}
						format="DD/MM/YYYY"
						value={getValueFilter}
						placeholder={t("checkin:filter.date_created")}
						className="w-full"
					/>
				</Form.Item>
				<DepartmentFormFilter
					placeholder={t("checkin:filter.department")}
					name="department_id"
					allowClear
					popupMatchSelectWidth={false}
				/>
				<Form.Item name="type">
					<FormSelect
						allowClear
						placeholder={t("checkin:filter.type")}
						options={CheckinModel.getCheckinTypeList()}
						name="type"
					/>
				</Form.Item>

				<FilterCheckin
					filters={filters}
					setFilters={setFilters}></FilterCheckin>
			</PageDataFilterForm>
		</div>
	);
};

export default CheckinListFilter;
