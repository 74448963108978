import CustomerPointApi from 'common/api/CustomerPointApi';
import CustomerPointCollection from 'common/collections/CustomerPointCollection';
import CustomerPointModel from 'common/models/CustomerPointModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  CustomerPointJsonAddEdit,
  FilterCustomerPoint,
} from "common/types/CustomerPoint";

class CustomerPointRepository extends BaseRepository<CustomerPointApi> {
  _api: CustomerPointApi | null;

  constructor() {
    super();
    this._api = new CustomerPointApi(true);
  }

  static getDefaultFilters(): FilterCustomerPoint {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      customer_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterCustomerPoint>) {
    return this._api
      ? await this._api.getList(props)
      : new CustomerPointCollection();
  }

  async getItem(id: number): Promise<CustomerPointModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new CustomerPointModel(CustomerPointModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: CustomerPointJsonAddEdit
  ): Promise<CustomerPointModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new CustomerPointModel(CustomerPointModel.getDefaultData());
    }
  }
}

export default CustomerPointRepository;
