import ToolApi from "common/api/ToolApi";
import BaseRepository from "./BaseRepository";
import {
  ToolQrCodeJsonAddEdit,
  ToolWifiQrCodeJsonAddEdit,
} from "common/types/Tool";
import ToolModel from "common/models/ToolModel";

class ToolRepository extends BaseRepository<ToolApi> {
  _api: ToolApi | null;

  constructor() {
    super();
    this._api = new ToolApi(true);
  }

  async addQrCode(data: ToolQrCodeJsonAddEdit): Promise<ToolModel> {
    if (this._api) {
      return this._api.addQrCode(data);
    } else {
      return new ToolModel(ToolModel.getDefaultData());
    }
  }

  async addWifiQrCode(data: ToolWifiQrCodeJsonAddEdit): Promise<ToolModel> {
    if (this._api) {
      return this._api.addWifiQrCode(data);
    } else {
      return new ToolModel(ToolModel.getDefaultData());
    }
  }
}

export default ToolRepository;
