import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { PurchaseOrderDetailJson } from "common/types/PurchaseOrderDetail";

class PurchaseOrderDetailModel
  extends BaseModel
  implements BaseModelJson<PurchaseOrderDetailJson>
{
  purchase_order_id: number;
  product_id: number;
  product_variant_id: number;
  id: number;
  sku: string;
  item_name: string;
  item_title: string;
  item_color: number;
  item_size: number;
  item_quantity: number;
  item_unit_price: number;

  constructor(json: PurchaseOrderDetailJson) {
    super();

    this.purchase_order_id = json.purchase_order_id || 0;
    this.product_id = json.product_id || 0;
    this.product_variant_id = json.product_variant_id || 0;
    this.id = json.id || 0;
    this.sku = json.sku || "";
    this.item_name = json.item_name || "";
    this.item_title = json.item_title || "";
    this.item_color = json.item_color || 0;
    this.item_size = json.item_size || 0;
    this.item_quantity = json.item_quantity || 0;
    this.item_unit_price = json.item_unit_price || 0;
  }

  static getDefaultData(): PurchaseOrderDetailJson {
    return {
      purchase_order_id: 0,
      product_id: 0,
      product_variant_id: 0,
      id: 0,
      sku: "",
      item_name: "",
      item_title: " ",
      item_size: 0,
      item_color: 0,
      item_quantity: 0,
      item_unit_price: 0,
    };
  }

  toJson(): PurchaseOrderDetailJson {
    return {
      purchase_order_id: this.purchase_order_id,
      product_id: this.product_id,
      product_variant_id: this.product_variant_id,
      id: this.id,
      sku: this.sku,
      item_name: this.item_name,
      item_title: this.item_title,
      item_size: this.item_size,
      item_color: this.item_color,
      item_quantity: this.item_quantity,
      item_unit_price: this.item_unit_price,
    };
  }
}

export default PurchaseOrderDetailModel;
