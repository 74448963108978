import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";

import PositionCollection from "common/collections/PositionCollection";
const SERVICE_URL = "/employees";

class PositionApi extends BaseApi {
  async getList(): // props: GetListProps<FilterTypeWork>
  Promise<PositionCollection> {
    let collection = new PositionCollection();

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/getposition"
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default PositionApi;
