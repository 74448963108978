const DmsBanner = {
  STATUS_ENABLED: 1,
  STATUS_DISABLED: 3,

  TYPE_LOGO: 1,
  TYPE_HOMEPAGE_COVER: 3,
  TYPE_GALLERY: 5,
};

export default DmsBanner;
