import BaseModel from "./BaseModel";

import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { TaskHistoryJson } from "common/types/Task";

class TaskHistoryModel
  extends BaseModel
  implements BaseModelJson<TaskHistoryJson>
{
  company_id: number;
  employee_id: number;
  fullname: string;
  internal_id: string;
  department_id: number;
  department_name: string;
  office_id: number;
  office_name: string;
  task_id: number;
  id: number;
  status: number;
  datecreated: number;
  new_obj: string;
  old_obj: string;

  constructor(json: TaskHistoryJson) {
    super();
    // this.stt = json.stt || 0;
    this.company_id = json.company_id || 0;
    this.employee_id = json.employee_id || 0;
    this.fullname = json.fullname || "";
    this.internal_id = json.internal_id || "";
    this.department_id = json.department_id || 0;
    this.department_name = json.department_name || "";
    this.office_id = json.office_id || 0;
    this.office_name = json.office_name || "";
    this.task_id = json.task_id || 0;
    this.id = json.id || 0;
    this.status = json.status || 0;
    this.datecreated = json.datecreated || 0;
    this.new_obj = json.new_obj;
    this.old_obj = json.old_obj;
  }

  toJson(): TaskHistoryJson {
    return {
      company_id: this.company_id,
      employee_id: this.employee_id,
      fullname: this.fullname,
      internal_id: this.internal_id,
      department_id: this.department_id,
      department_name: this.department_name,
      office_id: this.office_id,
      office_name: this.office_name,
      task_id: this.task_id,
      id: this.id,
      status: this.status,
      datecreated: this.datecreated,
      new_obj: this.new_obj,
      old_obj: this.old_obj,
    };
  }
  static getDefaultData(): TaskHistoryJson {
    return {
      company_id: 0,
      employee_id: 0,
      fullname: "",
      internal_id: "",
      department_id: 0,
      department_name: "",
      office_id: 0,
      office_name: "",
      task_id: 0,
      id: 0,
      status: 0,
      datecreated: 0,
      new_obj: "",
      old_obj: "",
    };
  }
}

export default TaskHistoryModel;
