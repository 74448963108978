import { Button, Form, InputNumber, Modal, Tooltip } from 'antd';
import { PosCartDelivery } from 'common/types/PosCart';
import TextMoney from 'components/TextMoney';
import { useState } from 'react';

const PosCartModalShipping = ({
  defaultValue,
  show,
  setShow,
  onChange,
  delivery,
}: {
  defaultValue: number;
  show: boolean;
  setShow: (v: boolean) => void;
  onChange: (v: number) => void;
  delivery: PosCartDelivery;
}) => {
  const handleClose = () => {
    setShow(false);
  };

  const [newShippingPrice, setNewShippingPrice] = useState(defaultValue);

  const onApply = () => {
    onChange(newShippingPrice);
    setShow(false);
  };

  return (
    <Modal
      open={show}
      onCancel={handleClose}
      title="Thay đổi phí giao hàng"
      footer={[
        <Button key="primary" type="primary" onClick={onApply}>
          Áp dụng
        </Button>,
      ]}
    >
      <div className="w-100">
        <Form.Item label="Phí giao hàng">
          <InputNumber
            value={newShippingPrice}
            style={{ width: 120 }}
            min={0}
            onChange={(value) => setNewShippingPrice(value ?? 0)}
            precision={0}
            decimalSeparator="."
            step={1000}
          />{" "}
          ₫
        </Form.Item>
        {delivery.shipping_carrier_price !== newShippingPrice ? (
          <div className="alert alert-warning">
            <span style={{ color: "" }}>
              Giá trị bạn nhập hiện đang khác với phí giao hàng lấy được từ dịch
              vụ bạn đã chọn ở cột bên phải, mục "GIAO HÀNG" là{" "}
              <Tooltip title={"Nhấn đề lấy giá trị này"} mouseEnterDelay={0.4}>
                <strong
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setNewShippingPrice(delivery.shipping_carrier_price)
                  }
                >
                  <TextMoney money={delivery.shipping_carrier_price} />
                </strong>{" "}
              </Tooltip>
            </span>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default PosCartModalShipping;
