import { Button } from "antd";
import ShippingTypeCollection from "common/collections/ShippingTypeCollection";
import Role from "common/constants/Role";
import ShippingTypeModel from "common/models/ShippingTypeModel";
import ShippingTypeRepository from "common/repositories/ShippingTypeRepository";
import { FilterShippingType } from "common/types/ShippingType";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import ShippingTypeFormModal from "features/shippingtype/form/ShippingTypeFormModal";
import ShippingTypeListHeader from "features/shippingtype/list/ShippingTypeListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ShippingTypeListFilter from "./ShippingTypeListFilter";

const ShippingTypeList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  // modal editing
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const [editing, setEditing] = useState<ShippingTypeModel>(
    new ShippingTypeModel(ShippingTypeModel.getDefaultData())
  );

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShippingType = useMemo(
    () => ShippingTypeRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterShippingType>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ShippingTypeModel[]>([]);
  const fetchData = useCallback(async (): Promise<ShippingTypeCollection> => {
    let collection = await new ShippingTypeRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
    return collection;
  }, [filters]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ShippingTypeModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            link={"/shippingtype/edit/id/" + id}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("shippingtype:name"),
      key: "name",
      sortby: "name",
    },
    {
      title: t("shippingtype:description"),
      key: "description",
      width: 300,
    },
    {
      title: t("common:display_order_long"),
      key: "display_order",
      sortby: "display_order",
      align: "center",
      width: 140,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: ShippingTypeModel) => (
        <RoleCheck roles={[Role.SETTING_DMS]} hideOnFail>
          <TableEdit link="" onClick={() => onClickEdit(record)} />
          <TableDelete
            error_translate_prefix="shippingtype:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ShippingTypeRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  const onClickEdit = useCallback((item: ShippingTypeModel) => {
    setEditing(item);
    setAddVisible(true);
  }, []);

  const onSaveSuccess = (item: ShippingTypeModel) => {
    let findIndex = dataSource.findIndex((i) => i.id === item.id);
    if (findIndex >= 0) {
      // update
      setDataSource(
        update(dataSource, {
          [findIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        })
      );
    }
  };

  return (
    <>
      <ShippingTypeListHeader>
        <RoleCheck roles={[Role.SETTING_DMS]}>
          <Button
            type="primary"
            onClick={() => {
              setEditing(
                new ShippingTypeModel(ShippingTypeModel.getDefaultData())
              );
              setAddVisible(true);
            }}
          >
            {t("shippingtype:add_button")}
          </Button>
        </RoleCheck>
      </ShippingTypeListHeader>
      <ShippingTypeListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterShippingType,
        ShippingTypeModel,
        ShippingTypeCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      {addVisible ? (
        <ShippingTypeFormModal
          key={"shipping-type-form-modal"}
          open={addVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditing(
                new ShippingTypeModel(ShippingTypeModel.getDefaultData())
              );
            }
            setAddVisible(isOpen);
          }}
          editing={editing}
          onSaveSuccess={onSaveSuccess}
        />
      ) : null}
    </>
  );
};

export default ShippingTypeList;
