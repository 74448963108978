import ProductReceiptDetailApi from 'common/api/ProductReceiptDetailApi';
import ProductReceiptDetailCollection from 'common/collections/ProductReceiptDetailCollection';

import BaseRepository from './BaseRepository';

import type { FilterProductReceiptDetail } from "common/types/ProductReceiptDetail";

class ProductReceiptDetailRepository extends BaseRepository<ProductReceiptDetailApi> {
  _api: ProductReceiptDetailApi | null;

  constructor() {
    super();
    this._api = new ProductReceiptDetailApi(true);
  }

  static getDefaultFilters(): FilterProductReceiptDetail {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      product_receipt_id: -1,
    };
  }

  async getFullDetailOfReceipt(receiptId: number) {
    return this._api
      ? await this._api.getFullDetailOfReceipt(receiptId)
      : new ProductReceiptDetailCollection();
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default ProductReceiptDetailRepository;
