import EcomModel from 'common/models/EcomModel';
import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EcomChannelProductListHeader = ({
  channel_id,
  model,
  children,
}: {
  channel_id: number;
  model?: EcomModel;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("ecom:title"),
      link: "/ecom",
    },
    {
      text: typeof model !== "undefined" ? model.title : "",
      link: "/ecomchannelproduct/index/channel/" + channel_id,
    },
  ];

  return (
    <PageHeader
      heading={
        t("ecomchannelproduct:heading_list") +
        (typeof model !== "undefined" ? "[" + model.title + "]" : "...")
      }
      nav={nav}
      siteMenuHidden
    >
      {children}
    </PageHeader>
  );
};

export default EcomChannelProductListHeader;
