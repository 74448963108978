import { Col, Row } from "antd";
import OrderModel from "common/models/OrderModel";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import React, { useCallback } from "react";

import ReportChartCategory from "../chart/ReportChartCategory";
import ReportChartDataTable from "../chart/ReportChartDataTable";

const ReportSaleStatus = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const formatterStatusValue = useCallback((value: number) => {
    return OrderModel.getStatus(value)?.label;
  }, []);

  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Giá trị đơn hàng theo trạng thái"}
              subtitle="Tất cả trạng thái đơn hàng"
              dataTable="order"
              dataService="order"
              dataSelect="sum(pricefinal), status"
              dataFilter=""
              dataGroupBy="status"
              dataOrderBy="status asc"
              keyMapping={{
                name: "status",
                value: "sum_pricefinal",
                formatter: (value: number) => {
                  return formatterStatusValue(value) || "";
                }
              }}
              height={350}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Giá trị đơn hàng theo trạng thái"}
              subtitle="Nhóm theo trạng thái đơn hàng"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Trạng thái",
                  name: "status",
                  formatter: (value: number | string) => {
                    return <>{formatterStatusValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng giá trị đơn hàng",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Tổng số đơn hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), status, sum(pricefinal)"
              dataFilter=""
              dataGroupBy="status"
              dataOrderBy="sum(pricefinal) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportSaleStatus;
