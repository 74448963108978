import { Alert, Button, Form, Tag } from "antd";
import Order from "common/constants/Order";
import Role from "common/constants/Role";
import OrderModel from "common/models/OrderModel";
import OrderRepository from "common/repositories/OrderRepository";
import { OrderDetailJson } from "common/types/OrderDetail";
import LayoutForm from "components/form/LayoutForm";
import RoleCheck from "components/RoleCheck";
import SimpleCollapse from "components/SimpleCollapse";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import OrderFormHeader from "./OrderFormHeader";
import OrderFormSectionEcomPlatform from "./section/OrderFormSectionEcomPlatform";
import OrderFormSectionGeneral from "./section/OrderFormSectionGeneral";
import OrderFormSectionInfo from "./section/OrderFormSectionInfo";
import OrderFormSectionStatus from "./section/OrderFormSectionStatus";
import OrderFormSectionTaxInvoice from "./section/OrderFormSectionTaxInvoice";
import OrderFormSectionCashflowReceipt from "./section/shipping/OrderFormSectionCashflowReceipt";
import OrderFormSectionProductReceipt from "./section/shipping/OrderFormSectionProductReceipt";
import OrderFormSectionShipping from "./section/shipping/OrderFormSectionShipping";

import type { OrderJsonEdit } from "common/types/Order";
import OrderDetailListForm from "./section/orderdetail/OrderDetailListForm";
import Helper from "common/utils/helper";
import OrderPrintBill from "./OrderPrintBill";
import OrderFormSectionNoteTaxInvoice from "./section/OrderFormSectionNoteTaxInvoice";
import useActionsOrder from "hooks/useActionsOrder";
import useMessage from "antd/es/message/useMessage";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import OrderFormSectionHistory from "./section/OrderFormSectionHistory";
import { CartOrderJson } from "common/types/Cart";

const OrderForm = () => {
	const { t } = useTranslation();
	const [message, contextMessage] = useMessage();
	const navigate = useNavigate();
	const hasRole = useLoginAccountStore((state) => state.hasRole);

	const [form] = Form.useForm();
	const order = useEditingSaleOrderStore((state) => state.order);
	const setOrder = useEditingSaleOrderStore((state) => state.setOrder);
	const [initStatus, setInitStatus] = useState(order.status);
	const [status, setStatus] = useState(initStatus);
	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isConfirmCashflow, setIsConfirmCashflow] = useState(false);

	const {
		allowEditInput,
		allowStatusCancel,
		allowStatusComplete,
		enableSubmitButton,
		allowStatusCompleteSelect,
	} = useActionsOrder({ order });

	const allow = true;

	const [details, setDetails] = useState<CartOrderJson[]>([]);

	const initialValues = useMemo(() => {
		return {
			shipping_full_name: order.shipping_fullname,
			shipping_address: order.shipping_address,
			shipping_phone: order.shipping_phone,
			shipping_regions: {
				shipping_province: order.shipping_province,
				shipping_district: order.shipping_district,
				shipping_ward: order.shipping_ward,
			},

			price_sell: order.price_sell,
			price_discount: order.price_discount,
			price_discount_coupon: order.price_discount_coupon,

			price_shipping: order.price_shipping,
			price_final: order.price_final,

			cancel_reason: order.cancel_reason > 0 ? order.cancel_reason : undefined,
			note: order.note,
			tag: order.tag,
			details: order.details,
			customer_credit_balance: order.customer_credit_balance,
		};
	}, [order]);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			console.log(formData);
			const submitData: OrderJsonEdit = {
				id: order.id,
				invoice_id: order.id,

				//shipping
				shipping_full_name: formData.shipping_full_name,
				shipping_phone: formData.shipping_phone,
				shipping_address: formData.shipping_address,
				shipping_region_id: formData.shipping_regions.region_id,
				shipping_sub_region_id: formData.shipping_regions.sub_region_id,
				shipping_sub_sub_region_id: formData.shipping_regions.sub_sub_region_id,
				shipping_district: formData.shipping_regions.shipping_district,
				shipping_ward: formData.shipping_regions.shipping_ward,
				shipping_province: formData.shipping_regions.shipping_province,
				status: status,
				cancel_reason: formData.cancel_reason,
				origin: formData.origin,
				tag: formData.tag,
				note: formData.note,

				price_sell: formData.price_sell,
				price_discount: formData.price_discount,
				tax_ratio: formData.tax_ratio,
				price_tax: formData.price_tax,
				price_shipping: formData.price_shipping,
				price_final: formData.price_final,
				price_cod: formData.price_cod,
				customer_credit_balance: formData.customer_credit_balance,
				note_invoice: {
					note_cancel: formData.note_cancle,
				},
				// details: formData.details,
				check_debt_cashflow: isConfirmCashflow ? false : undefined,
			};

			return submitData;
		},
		[order.id, status, isConfirmCashflow]
	);

	const fetchDetail = useCallback(async () => {
		setTimeout(async () => {
			if (order.id) {
				let fetchedModel = await new OrderRepository().getItem(+order.id);
				if (!fetchedModel.hasError()) {
					setOrder(fetchedModel.toJson());
				}
			}
		}, 1500);
	}, [order.id]);

	//submit data to server
	const onSubmit = useCallback(
		async (formData: any) => {
			console.log("🚀 ~ formData:", formData);
			setIsSuccess(false);

			let item: OrderModel = await new OrderRepository().saveRemote(
				doPrepareData(formData)
			);

			if (item.hasError()) {
				setErrors(item.error.errors);
				const errors = item.error.errors;
			} else {
				setOrder(item.toJson());
				setInitStatus(item.status);
				setIsSuccess(true);
			}
		},
		[doPrepareData]
	);
	let sidebarItems = (
		<div className=" -my-6 mb-2">
			<div className=" mb-2 mt-4  flex justify-end">
				<OrderPrintBill order={order} />
			</div>

			<OrderFormSectionStatus
				allowEditInput={allow || allowEditInput}
				allowStatusComplete={allow || allowStatusCompleteSelect}
				allowStatusCancel={allow || allowStatusCancel}
				status={status}
				setStatus={setStatus}
				initStatus={initStatus}
				type={order.type}
				isSuccess={isSuccess}
			/>
			<OrderFormSectionInfo />
			<div className="pt-4">
				<OrderFormSectionGeneral
					form={form}
					allowEditInput={allow || allowEditInput}
				/>
			</div>
		</div>
	);

	return (
		<>
			<RoleCheck
				roles={[Role.ORDER_VIEW]}
				componentOnFail={
					<Alert
						className="mt-4"
						type="warning"
						showIcon
						message={t("order:form.not_view_permission")}></Alert>
				}>
				{contextMessage}
				<OrderFormHeader
					isEditing={Helper.checkEditOrder(order.id)}
					id={order.code}
					type={order.type}
				/>
				<LayoutForm
					form={form}
					initialValues={initialValues}
					errors={errors}
					isSuccess={isSuccess}
					scrollToFirstError={{
						behavior: "smooth",
						block: "start",
					}}
					successTitle={
						Helper.checkEditOrder(order.id)
							? t("order:form.success.update")
							: t("order:form.success.add")
					}
					error_translate_prefix="order:form.error"
					onSubmit={onSubmit}
					submitText={
						Helper.checkEditOrder(order.id)
							? t("common:form.save")
							: t("common:form.submit")
					}
					submitDisabled={!allow && !enableSubmitButton}
					sidebarItems={sidebarItems}
					id="productreceipt-form"
					redirectOnSuccess={Helper.checkEditOrder(order.id) ? "" : "/order"}>
					{order.type === Order.TYPE_INVOICE_ORDER && order.invoice_id > 0 && (
						<Alert
							className="mb-4"
							message={
								<>
									Đơn hàng đặt hàng đã có phát sinh
									<Link
										target="_blank"
										to={`/order/edit/id/${order.invoice_id}`}
										className=" ml-1 uppercase font-semibold">
										hóa đơn
									</Link>
								</>
							}
							type="info"
							showIcon
						/>
					)}
					{order.type === Order.TYPE_INVOICE && order.parent_id > 0 && (
						<Alert
							className="mb-4"
							message={
								<>
									Hóa đơn bắt nguồn từ
									<Link
										target="_blank"
										to={`/order/edit/id/${order.parent_id}`}
										className=" ml-1 uppercase font-semibold">
										đơn hàng
									</Link>
								</>
							}
							type="info"
							showIcon
						/>
					)}
					<OrderFormSectionShipping
						form={form}
						allowEditInput={allow || allowEditInput}
						refetchOrder={() => fetchDetail()}
					/>
					<SimpleCollapse
						header={t("order:form.section_detail")}
						defaultOpen={true}
						childrenItem={
							<OrderDetailListForm
								model={order}
								setDetails={setDetails}
								form={form}
								allowEditInput={allowEditInput}
							/>
						}
					/>
					<RoleCheck
						roles={[Role.CASHFLOW_VIEW]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t(
									"order:form.cashflow_receipt_not_view_permission"
								)}></Alert>
						}>
						<OrderFormSectionCashflowReceipt
							status={status}
							isSuccess={isSuccess}
							order={order}
							refetchOrder={() => fetchDetail()}
							onConfirmCashFlow={(value) => {
								setIsConfirmCashflow(value);
							}}
						/>
					</RoleCheck>
					<RoleCheck
						roles={[Role.INVENTORY_VIEW]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t(
									"order:form.product_receipt_not_view_permission"
								)}></Alert>
						}>
						<OrderFormSectionProductReceipt
							status={status}
							isSuccess={isSuccess}
							form={form}
							allowEditInput={allow || allowEditInput}
							refetchOrder={() => fetchDetail()}
						/>
					</RoleCheck>

					<RoleCheck
						roles={[Role.TAX_INVOICE_VIEW]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t(
									"order:form.tax_invoice_not_view_permission"
								)}></Alert>
						}>
						<OrderFormSectionNoteTaxInvoice form={form} />
					</RoleCheck>
					<RoleCheck
						roles={[Role.CASHFLOW_VIEW, Role.INVENTORY_VIEW]}
						componentOnFail={
							<Alert
								className="mt-4"
								type="warning"
								showIcon
								message={t(
									"order:form.tax_invoice_not_view_permission"
								)}></Alert>
						}>
						<OrderFormSectionHistory order={order}></OrderFormSectionHistory>
					</RoleCheck>

					<OrderFormSectionEcomPlatform />
				</LayoutForm>
			</RoleCheck>
		</>
	);
};

export default OrderForm;
