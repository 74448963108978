import ProductTransmitDetailApi from 'common/api/ProductTransmitDetailApi';
import ProductTransmitDetailCollection from 'common/collections/ProductTransmitDetailCollection';
import ProductTransmitDetailModel from 'common/models/ProductTransmitDetailModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProductTransmitDetailJsonAddEdit,
  FilterProductTransmitDetail,
} from "common/types/ProductTransmitDetail";

class ProductTransmitDetailRepository extends BaseRepository<ProductTransmitDetailApi> {
  _api: ProductTransmitDetailApi | null;

  constructor() {
    super();
    this._api = new ProductTransmitDetailApi(true);
  }

  static getDefaultFilters(): FilterProductTransmitDetail {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "ASC",
      status: -1,
      keyword: "",
      product_transmit_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterProductTransmitDetail>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductTransmitDetailCollection();
  }

  async getItem(id: number): Promise<ProductTransmitDetailModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductTransmitDetailModel(
          ProductTransmitDetailModel.getDefaultData()
        );
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductTransmitDetailJsonAddEdit
  ): Promise<ProductTransmitDetailModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductTransmitDetailModel(
        ProductTransmitDetailModel.getDefaultData()
      );
    }
  }
}

export default ProductTransmitDetailRepository;
