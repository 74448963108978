import { App, Button, Divider, Empty, Image, List, Tooltip } from "antd";
import { FileInputValue } from "common/interfaces/FileInput";
import FileModel from "common/models/FileModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskCommentModel from "common/models/ProjectTaskCommentModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectTaskCommentRepository from "common/repositories/ProjectTaskCommentRepository";
import { ProjectTaskCommentJsonAdd } from "common/types/ProjectTaskComment";
import CommentEditor from "components/comment/CommentEditor";
import Error from "components/LayoutError";
import TableDelete from "components/table/TableDelete";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import TextUser from "components/TextUser";
import UserIdAvatar from "components/UserIdAvatar";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconDownload, IconEye, IconMessage2 } from "@tabler/icons-react";

const ProjectTaskItemComment = ({
  record,
  projectModel,
  dataSource,
  onSaveCommentSuccess,
  onDeleteCallback,
}: {
  record: ProjectTaskModel;
  projectModel: ProjectModel;
  dataSource: ProjectTaskCommentModel[];
  onSaveCommentSuccess: (item: ProjectTaskCommentModel) => void;
  onDeleteCallback: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const account = useLoginAccountStore((state) => state.account);
  const [comment, setComment] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [uploadedFileList, setUploadedFileList] = useState<FileInputValue>({
    file_id_list: [],
    files: [],
  });

  const handleSubmit = () => {
    if (!comment) {
      return;
    }

    setSubmitting(true);

    setTimeout(async () => {
      let formData: ProjectTaskCommentJsonAdd = {
        company_id: account.company.id,
        creator_id: account.id,
        project_task_id: record.id,
        text: comment,
        file_list: uploadedFileList.file_id_list.filter((fileId) => fileId > 0),
      };

      let myObj: ProjectTaskCommentModel =
        await new ProjectTaskCommentRepository().saveRemote(formData);
      if (myObj.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="projecttask:form.error"
              items={myObj.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4,
        });
      } else {
        setSubmitting(false);
        onSaveCommentSuccess(myObj);

        // reset upload file
        setUploadedFileList({ file_id_list: [], files: [] });
      }
    }, 1000);

    setComment("");
  };

  const onChangeUpload = useCallback((value: FileInputValue) => {
    setUploadedFileList(value);
  }, []);

  const canDelete = (item: ProjectTaskCommentModel) => {
    let canDelete = false;
    let scrumMaster = projectModel.scrum_master;

    if (
      account.id === item.creator_id ||
      scrumMaster.findIndex((i) => i === account.id) >= 0
    ) {
      canDelete = true;
    }

    return canDelete;
  };

  const previewButton = (myFile: FileModel) => {
    if (FileModel.getImageExtensions().includes(myFile.extension)) {
      return (
        <div>
          <Tooltip title={t("file:preview")} mouseEnterDelay={0.4}>
            <Button
              size="small"
              type="text"
              icon={<IconEye className="-mt-0.5" size="18" color="white" />}
            />
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  const downloadButton = (myFile: FileModel) => {
    if (FileModel.getAllSupportUploadExtensions().includes(myFile.extension)) {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <Tooltip title={t("file:download")} mouseEnterDelay={0.4}>
            <a
              href={myFile.url}
              download={myFile.title}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                size="small"
                type="text"
                icon={
                  <IconDownload className="-mt-0.5" size="18" color="white" />
                }
                style={{ marginLeft: 3 }}
              />
            </a>
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mt-8">
      <div className="bg-gray-800 text-white rounded font-bold text-lg px-4 py-2">
        <IconMessage2 className="-mt-0.5 mr-1" /> {t("projecttask:comment")} (
        {dataSource.length})
      </div>
      {dataSource.length > 0 ? (
        <List
          dataSource={dataSource}
          itemLayout="horizontal"
          renderItem={(item: ProjectTaskCommentModel) => (
            <List.Item key={item.id} style={{ padding: 8 }}>
              <List.Item.Meta
                avatar={
                  <UserIdAvatar id={item.creator_id ? item.creator_id : 0} />
                }
                title={
                  <>
                    <TextUser id={item.creator_id ? item.creator_id : 0} /> -{" "}
                    <TextDateTimeRelative
                      className="text-sm italic text-gray-500"
                      ts={item.date_created ? item.date_created : 0}
                    />
                  </>
                }
                description={
                  <>
                    <div className="flex">
                      <div className="mr-0">{item.text}</div>
                      <div className="flex flex-col items-end justify-between flex-1">
                        <div className="">
                          {canDelete(item) ? (
                            <TableDelete
                              error_translate_prefix="projecttask:form.error"
                              onDeleteCallback={onDeleteCallback}
                              repository={new ProjectTaskCommentRepository()}
                              id={item.id}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-2">
                      {item.files.length > 0 ? (
                        <div className="relative flex items-center">
                          {item.files.map((i) => (
                            <div key={i.id}>
                              <Image
                                key={"url-" + i.id}
                                className="mr-2"
                                width={100}
                                height={100}
                                src={i.getThumbnailSquare(100)}
                                preview={{
                                  src: i.url,
                                  mask: (
                                    <>
                                      {previewButton(i)}
                                      {downloadButton(i)}
                                    </>
                                  ),
                                }}
                              />
                              <div
                                key={"title-" + i.id}
                                className="flex justify-center absolute left-0 bottom-0 right-0 p-1.5 bg-gray-300 rounded-b-sm h-1/3"
                                style={{
                                  width: "100px",
                                  maxHeight: "50%",
                                  maxWidth: "100px",
                                }}
                              >
                                <Tooltip title={i.title} mouseEnterDelay={0.4}>
                                  <a
                                    href={i.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="block text-sm italic font-light tracking-tight text-white break-words truncate"
                                  >
                                    {i.title}
                                  </a>
                                </Tooltip>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <div className="mb-4">
          <Empty description={t("projecttask:comment_empty")}></Empty>
        </div>
      )}

      <CommentEditor
        onChange={setComment}
        onChangeUpload={onChangeUpload}
        onSubmit={handleSubmit}
        submitting={submitting}
        value={comment}
        uploadedFileList={uploadedFileList}
        rows={2}
        placeholder={t("projecttask:commentplaceholder")}
      />
    </div>
  );
};

export default ProjectTaskItemComment;
