import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Tooltip,
} from "antd";
import CompanySettingRepository from "common/repositories/CompanySettingRepository";
import { CompanySettingEntry } from "common/types/CompanySetting";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconHelp, IconPercentage } from "@tabler/icons-react";
import TaxInvoice from "common/constants/TaxInvoice";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import FormSelect from "components/form/FormSelect";
import SettingTaxHeader from "./SettingTaxHeader";

const SettingTax = ({ data }: { data: CompanySettingEntry }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const taxInvoiceProvider = Form.useWatch("tax_invoice_provider", form);

  const initialValues = {
    ...data,
    tax_invoice_auto_sign:
      typeof data.tax_invoice_auto_sign !== "undefined" &&
      data.tax_invoice_auto_sign === 1,
  };

  //prepare data for submit
  const doPrepareData = useCallback((formData: any) => {
    const submitData: CompanySettingEntry = {
      ...formData,
      tax_invoice_auto_sign: formData.tax_invoice_auto_sign ? 1 : 0,
    };

    return submitData;
  }, []);

  //submit data to server
  const onSubmit = async (formData: any) => {
    setErrors([]);

    let myObj = await new CompanySettingRepository().updateSettingTax(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  return (
    <>
      <SettingTaxHeader></SettingTaxHeader>
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={t("setting:form.success.update")}
        redirectOnSuccess=""
        error_translate_prefix="setting:form.error"
        onSubmit={onSubmit}
        submitText={t("common:form.save_short")}
        sidebarItems={[]}
        span={21}
      >
        <FormSection
          title={t("setting:tax_section")}
          subtitle={t("setting:tax_section_subtitle")}
          divider
        >
          <Row>
            <Col lg={12} xs={24}>
              <Form.Item
                name="default_product_tax_ratio"
                label={t("setting:label.default_product_tax_ratio")}
              >
                <InputNumber
                  min={0}
                  max={100}
                  step={1}
                  style={{ width: 120 }}
                  addonAfter={<IconPercentage size={18} className="-mt-0.5" />}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col lg={12} xs={24}>
              <Form.Item
                name="default_shipping_tax_ratio"
                label={t("setting:label.default_shipping_tax_ratio")}
              >
                <InputNumber
                  min={0}
                  max={100}
                  step={1}
                  style={{ width: 120 }}
                  addonAfter={<IconPercentage size={18} className="-mt-0.5" />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={24}>
              <Form.Item
                className="mb-0"
                name="product_price_including_tax"
                valuePropName="checked"
              >
                <Checkbox>
                  {t("setting:label.product_price_including_tax")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={24}>
              <Form.Item
                className="mb-0"
                name="shipping_price_including_tax"
                valuePropName="checked"
              >
                <Checkbox>
                  {t("setting:label.shipping_price_including_tax")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("setting:tax_invoice_provider_section")}
          subtitle={t("setting:tax_invoice_provider_section_subtitle")}
          divider
        >
          <Row>
            <Col lg={12} xs={24}>
              <Form.Item
                name="tax_invoice_provider"
                label={t("setting:label.tax_invoice_provider")}
                className="mb-0"
              >
                <FormSelect
                  options={TaxInvoiceModel.getIssueProviderList()}
                  className="mb-0"
                />
              </Form.Item>
            </Col>
          </Row>

          {taxInvoiceProvider === TaxInvoice.ISSUE_PROVIDER_WINTECH ? (
            <div>
              <Divider orientation="left" className="m-0">
                {t("taxinvoice:setting.wintech.heading")}
              </Divider>
              <div className="bg-gray-50 p-4">
                <Row gutter={16}>
                  <Col lg={8} xs={24}>
                    <Form.Item
                      name="tax_invoice_is_sandbox"
                      label={t("taxinvoice:setting.is_sandbox")}
                      className="mb-0"
                    >
                      <FormSelect
                        options={TaxInvoiceModel.getIsSandboxList()}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item
                      name="tax_invoice_symbol"
                      label={t("taxinvoice:setting.tax_invoice_symbol")}
                      className="mb-0"
                    >
                      <Input
                        allowClear
                        placeholder={t(
                          "taxinvoice:setting.tax_invoice_symbol_placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item
                      valuePropName="checked"
                      name="tax_invoice_auto_sign"
                      label={" "}
                      className="mb-0"
                    >
                      <Checkbox>
                        {t("taxinvoice:setting.tax_invoice_auto_sign")}
                        <Tooltip
                          title={t(
                            "taxinvoice:setting.tax_invoice_auto_sign_help"
                          )}
                        >
                          <IconHelp
                            size={18}
                            className="text-orange-500 ml-1"
                          />
                        </Tooltip>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={8} xs={24}>
                    <Form.Item
                      name="tax_invoice_client_id"
                      label={t("taxinvoice:setting.client_id")}
                      className="mb-0"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={16} xs={24}>
                    <Form.Item
                      name="__SECURE__tax_invoice_client_secret"
                      label={t("taxinvoice:setting.client_secret")}
                      className="mb-0"
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          ) : null}
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default SettingTax;
