import BaseRepository from "./BaseRepository";

import LeaveTypeApi from "common/api/LeaveTypeApi";
import LeaveCollection from "common/collections/LeaveCollection";
import LeaveTypeCollection from "common/collections/LeaveTypeCollection";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import { GetListProps } from "common/types/Filter";
import { FilterLeaveType, LeaveTypeAddEditJson, LeaveTypeJson } from "common/types/LeaveType";

class LeaveTypeRepository extends BaseRepository<LeaveTypeApi> {
  _api: LeaveTypeApi | null;

  constructor() {
    super();
    this._api = new LeaveTypeApi(true);
  }
  static getDefaultFilters(): FilterLeaveType {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: "",
    };
  }
  async getItems(): Promise<LeaveTypeModel[]> {
    return this._api ? await this._api.getList() : [];
  }

  async getItem(id: number): Promise<LeaveTypeModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new LeaveTypeModel(LeaveTypeModel.getDefaultData());
  }

  async saveRemote(data: LeaveTypeAddEditJson): Promise<LeaveTypeModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new LeaveTypeModel(LeaveTypeModel.getDefaultData());
    }
  }
  async getListLeaveType(
    props: GetListProps<FilterLeaveType>
  ): Promise<LeaveTypeCollection> {
    return this._api
      ? await this._api.getListLeaveType(props)
      : new LeaveTypeCollection();
  }
  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default LeaveTypeRepository;
