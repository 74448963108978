import FileApi from "common/api/FileApi";
import FileCollection from "common/collections/FileCollection";
import File from "common/constants/File";
import FileModel from "common/models/FileModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  FileJsonAddEdit,
  FilterFile,
  FileOrigin,
} from "common/types/File";
class FileRepository extends BaseRepository<FileApi> {
  _api: FileApi | null;

  constructor() {
    super();
    this._api = new FileApi(true);
  }

  static getDefaultFilters(): FilterFile {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      directory_id: 0,
      object_type: 0,
      origin: "",
      list_file_ids : ""
    };
  }

  async getFileAndDirectories(props: GetListProps<FilterFile>) {
    return this._api ? await this._api.getList(props) : new FileCollection();
  }

  async getItem(id: number): Promise<FileModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new FileModel(FileModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveDirectoryRemote(data: FileJsonAddEdit): Promise<FileModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new FileModel(FileModel.getDefaultData());
    }
  }

  async uploadBase64Image(data: {
    origin: string;
    data_uri: string;
  }): Promise<FileModel> {
    if (this._api) {
      return await this._api.add({
        ...data,
        id: 0,
        directory_id: 0,
        title: " ",
        description: "",
        object_type: File.OBJECTTYPE_ATTACHMENT,
      });
    } else {
      return new FileModel(FileModel.getDefaultData());
    }
  }

  static getAllowOrigin(): FileOrigin[] {
    return [
      "company",
      "project",
      "checkin",
      "dms",
      "avatar",
      "product",
      "productcategory",
    ];
  }
}

export default FileRepository;
