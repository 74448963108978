import ShipperModel from "common/models/ShipperModel";
import ShippingHubModel from "common/models/ShippingHubModel";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingTypeModel from "common/models/ShippingTypeModel";
import DeliveryDashboardProvider from "contextproviders/DeliveryDashboardProvider";
import React from "react";

const DeliveryDashboardContext = React.createContext<DeliveryDashboardProvider>(
  new DeliveryDashboardProvider({
    orderItems: [],
    setOrderItems: (v: ShippingOrderModel[]) => {},
    hubItems: [],
    setHubItems: (v: ShippingHubModel[]) => {},
    shipperItems: [],
    setShipperItems: (v: ShipperModel[]) => {},
    shippingTypeItems: [],
    setShippingTypeItems: (v: ShippingTypeModel[]) => {},
  })
);

export default DeliveryDashboardContext;
