import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import { DateRange, FilterBiCenter } from "common/types/BiCenter";
import dayjs from "dayjs";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReportDateRangePicker from "../ReportDateRangePicker";
import ReportViewHeader from "../view/ReportViewHeader";
import ReportSalePromotionCampain from "./promotion/ReportSalePromotionCampain";
import ReportSalePromotionEmployee from "./promotion/ReportSalePromotionEmployee";
import ReportSalePromotionGeneral from "./promotion/ReportSalePromotionGeneral";
import ReportSalePromotionStore from "./promotion/ReportSalePromotionStore";
import ReportSaleEmployee from "./ReportSaleEmployee";
import ReportSaleGeneral from "./ReportSaleGeneral";
import ReportSaleRegion from "./ReportSaleRegion";
import ReportSaleStatus from "./ReportSaleStatus";
import ReportSaleStore from "./ReportSaleStore";

const ReportSaleWrapper = ({
  siteMenuOpenKey,
  siteMenuSelectedKey
}: {
  siteMenuOpenKey: string;
  siteMenuSelectedKey: string;
}) => {
  const { t } = useTranslation();
  const defaultFilters: FilterBiCenter = useMemo(
    () => BiCenterRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterBiCenter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const getPathNameParts = useMemo(() => {
    let pathNameParts = siteMenuSelectedKey.split("/");
    //discard first item (empty string)
    pathNameParts.shift();
    //discard controller item
    pathNameParts.shift();
    //discard action item
    pathNameParts.shift();
    return pathNameParts;
  }, [siteMenuSelectedKey]);

  const getNavByPathLocation = useMemo(() => {
    const nav = [
      {
        text: t("report:heading_view")
      },
      {
        text: t("report:heading_report_sale")
      }
    ];
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          nav.push({
            text: t("report:heading_report_sale_general")
          });
          break;
        case "store":
          nav.push({
            text: t("report:heading_report_sale_store")
          });
          break;
        case "region":
          nav.push({
            text: t("report:heading_report_sale_region")
          });
          break;
        case "status":
          nav.push({
            text: t("report:heading_report_sale_status")
          });
          break;
        case "employee":
          nav.push({
            text: t("report:heading_report_sale_employee")
          });
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      nav.push({
        text: t("report:heading_report_sale_promotion")
      });
      switch (getPathNameParts[1]) {
        case "general":
          nav.push({
            text: t("report:heading_report_sale_promotion_general")
          });
          break;
        case "campain":
          nav.push({
            text: t("report:heading_report_sale_promotion_campaign")
          });
          break;
        case "store":
          nav.push({
            text: t("report:heading_report_sale_promotion_store")
          });
          break;
        case "employee":
          nav.push({
            text: t("report:heading_report_sale_promotion_employee")
          });
          break;
      }
    }

    return nav;
  }, [t, getPathNameParts]);

  const getHeadingByPathLocation = useMemo(() => {
    let heading = "";
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          heading = "Tổng quát bán hàng";
          break;
        case "store":
          heading = "Báo cáo theo cửa hàng";
          break;
        case "region":
          heading = "Báo cáo theo tỉnh / thành phố";
          break;
        case "status":
          heading = "Báo cáo theo trạng thái đơn hàng";
          break;
        case "employee":
          heading = "Báo cáo theo nhân viên bán hàng";
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      switch (getPathNameParts[1]) {
        case "general":
          heading = "Báo cáo theo chương trình khuyến mãi & giảm giá";
          break;
        case "campain":
          heading = "Báo cáo khuyến mãi, giảm giá theo chương trình KM";
          break;
        case "store":
          heading = "Báo cáo khuyến mãi, giảm giá theo cửa hàng";
          break;
        case "employee":
          heading = "Báo cáo khuyến mãi, giảm giá theo nhân viên";
          break;
      }
    }
    return heading;
  }, [getPathNameParts]);

  const onChangeDateRange = (
    value: DateRange,
    value_compare: DateRange | null
  ) => {
    setFilters({
      ...filters,
      start: BiCenterModel.momentToString(value[0]),
      end: BiCenterModel.momentToString(value[1]),
      ...(value_compare
        ? {
            startcompare: BiCenterModel.momentToString(value_compare[0]),
            endcompare: BiCenterModel.momentToString(value_compare[1])
          }
        : { startcompare: "", endcompare: "" })
    });
  };

  const getRange = useCallback((): DateRange => {
    if (filters.start.length > 0 && filters.end.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.start),
        BiCenterModel.stringToMoment(filters.end)
      ];
    } else {
      //default: last30days
      return [
        dayjs().subtract(30, "days").startOf("day"),
        dayjs().subtract(1, "days").endOf("day")
      ];
    }
  }, [filters.start, filters.end]);

  const getRangeCompare = useCallback((): DateRange | null => {
    if (filters.startcompare.length > 0 && filters.endcompare.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.startcompare),
        BiCenterModel.stringToMoment(filters.endcompare)
      ];
    } else {
      //user already set date start by MANUAL,
      //so, do not return default with compare (disable compare)
      if (filters.start.length > 0 && filters.end.length > 0) {
        return null;
      } else {
        //default: previousperiod of last30days
        return [
          dayjs().subtract(60, "days").startOf("day"),
          dayjs().subtract(31, "days").endOf("day")
        ];
      }
    }
  }, [filters.startcompare, filters.endcompare, filters.start, filters.end]);

  const getComponentByPathLocation = useMemo(() => {
    let com = null;
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          com = (
            <ReportSaleGeneral
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "store":
          com = (
            <ReportSaleStore
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "region":
          com = (
            <ReportSaleRegion
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "status":
          com = (
            <ReportSaleStatus
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "employee":
          com = (
            <ReportSaleEmployee
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      switch (getPathNameParts[1]) {
        case "general":
          com = (
            <ReportSalePromotionGeneral
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "campain":
          com = (
            <ReportSalePromotionCampain
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "store":
          com = (
            <ReportSalePromotionStore
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "employee":
          com = (
            <ReportSalePromotionEmployee
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
      }
    }

    return com;
  }, [getPathNameParts, getRange, getRangeCompare]);

  return (
    <>
      <ReportViewHeader
        heading={getHeadingByPathLocation}
        nav={getNavByPathLocation}
        siteMenuOpenKey={siteMenuOpenKey}
        siteMenuSelectedKey={siteMenuSelectedKey}>
        <ReportDateRangePicker
          range={getRange()}
          range_compare={getRangeCompare()}
          onChange={onChangeDateRange}
          format="DD/MM/YYYY"
        />
      </ReportViewHeader>
      {getComponentByPathLocation}
    </>
  );
};

export default ReportSaleWrapper;
