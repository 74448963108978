import {
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  message,
} from "antd";
import Error from "components/LayoutError";
import FormSelect from "components/form/FormSelect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import TaskModel from "common/models/TaskModel";
import TaskRepository from "common/repositories/TaskRepository";
import usePathParams from "hooks/usePathParams";
// import moment, { Moment } from "moment";

import Helper from "common/utils/helper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FormSection from "components/form/FormSection";
import Title from "antd/lib/typography/Title";
import FormSelectCustom from "features/task/all/components/FormSelectCustom";
import dayjs from "dayjs";

const { Panel } = Collapse;

const TaskKanbanForm = ({
  model,
  type,
  setVisible,
  setProcessing,
  onSaveSuccess,
}: {
  model: TaskModel;
  type?: "edit_request";
  setVisible: (v: boolean) => void;
  setProcessing: (p: boolean) => void;
  onSaveSuccess: (m: TaskModel) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [pathParams] = usePathParams();

  const isEditing = model.id > 0;
  //parent
  const [parentModel, setParentModel] = useState<TaskModel>(
    new TaskModel(TaskModel.getDefaultData())
  );

  const fetchParentDetail = async (id: number) => {
    setParentModel(await new TaskRepository().getItem(id));
  };

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formValues, setFormValues] = useState<any>({ id: model.id });

  function validateManyId(input: string) {
    // Sử dụng biểu thức chính quy để kiểm tra định dạng
    var regex = /^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$/;
    return regex.test(input);
  }

  const createScopeIdOption = (scope_ids: number[]) => {
    if (model.org_id > 0 && !scope_ids.includes(model.org_id)) {
      return [...scope_ids, model.org_id];
    } else {
      return scope_ids;
    }
  };

  //initital Values Form
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      org_cost: model.org_cost,
      org_fromtime:
        model.org_fromtime > 0 ? dayjs(new Date(model.org_fromtime * 1000)) : 0,
      org_totime:
        model.org_totime > 0 ? dayjs(new Date(model.org_totime * 1000)) : 0,
      type: model.type > 0 ? model.type : 1,
      flag: model.flag > 0 ? model.flag : 0,
      status: model.status >= 0 ? model.status : 1,
      note: model.note,
      org_complete: model.org_complete,
      rel_cost: model.rel_cost,
      priority: model.priority > 0 ? model.priority : 1,
      date_start:
        model.datestart > 0 ? dayjs(new Date(model.datestart * 1000)) : 0,
      date_completed:
        model.datecompleted > 0
          ? dayjs(new Date(model.datecompleted * 1000))
          : 0,
      scope_ids: model.scope_ids.length > 0 ? model.scope_ids.join(",") : "",
      org_id: model.org_id > 0 ? model.org_id : 0,
      parent_id: model.parent_id > 0 ? model.parent_id : 0,
      ability: model.ability,
      public: model.public,
    };
  }, [model]);

  // getFieldValues Change
  const handleFormChange = (changedValues: any, allValues: any) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      // const submitData: TaskJsonAddEdit = {
      //   ...TaskModel.getDefaultData(),
      //   id: model.id,
      //   employee_id: account.company.employee.id,
      //   employee_ids: null,
      //   parent_id: model.parent_id > 0 ? model.parent_id : 0,
      //   name: formData.name,
      //   type: formData.type,
      //   org_cost: formData.org_cost,
      //   org_fromtime: Helper.convertedDateToTimeStamp(formData.org_fromtime),
      //   org_totime: Helper.convertedDateToTimeStamp(formData.org_totime),
      //   flag: formData.flag,
      //   status: formData.status,
      //   rel_cost: formData.rel_cost,
      //   note: formData.note,
      //   org_complete: formData.org_complete,
      // };
      const submitDataFilter = {
        ...formValues,
        // date_start:
        //   Helper.convertedDateToTimeStamp(formValues.date_start) > 0
        //     ? Helper.convertedDateToTimeStamp(formValues.date_start)
        //     : 0,
        // date_completed:
        //   Helper.convertedDateToTimeStamp(formValues.date_completed) > 0
        //     ? Helper.convertedDateToTimeStamp(formValues.date_completed)
        //     : 0,
        org_fromtime:
          Helper.datePickerValueToTimestamp(formValues.org_fromtime) > 0
            ? Helper.datePickerValueToTimestamp(formValues.org_fromtime)
            : 0,
        org_totime:
          Helper.datePickerValueToTimestamp(formValues.org_totime) > 0
            ? Helper.datePickerValueToTimestamp(formValues.org_totime)
            : 0,
        org_id:
          Number(formValues.org_id) >= 0
            ? Number(formValues.org_id)
            : model.org_id,
        parent_id:
          Number(formValues.parent_id) >= 0
            ? Number(formValues.parent_id)
            : model.parent_id,
      };

      return submitDataFilter;
    },
    [model.id, formValues]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("Đang xử lý"),
      key: "message",
      duration: 0,
    });
    let item: TaskModel = await new TaskRepository().saveRemote(
      doPrepareData(formData)
    );
    setProcessing(false);

    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="task:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setVisible(false);
    }
  };

  //
  useEffect(() => {
    if (model.parent_id > 0) {
      fetchParentDetail(model.parent_id);
    }
  }, []);

  //for edit, show status
  const sidebarItems = <>{/* <Progress percent={100} />     */}</>;

  const toolbarOptions = [
    [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction"],
    ["link", "image", "video", "formula"],
    ["clean"],
  ];

  // const formSectionTitle_1: string = useMemo(() => {
  //   if (type && type === "edit_request") {
  //     return t("task:form.section_request_task_1");
  //   } else {
  //     return t("task:form.section_task");
  //   }
  // }, [type]);

  // const formSectionTitle_2: string = useMemo(() => {
  //   if (type && type === "edit_request") {
  //     return t("task:form.section_request_task_2");
  //   } else {
  //     return t("task:form.section_task");
  //   }
  // }, [type]);

  const deniedEdit = useCallback(
    (name: string) => {
      if (type) {
        switch (name) {
          case "name":
            return false;
          case "org_cost":
            return false;
          case "org_fromtime":
            return false;
          case "org_totime":
            return false;
          case "type":
            return false;
          case "priority":
            return false;
          case "flag":
            return false;
          case "status":
            return false;
          case "time_checking":
            return false;
          case "percent_complete":
            return false;
          case "scope_ids":
            return false;
          case "org_id":
            return false;
          case "note":
            return false;
          case "date_start":
            return false;
          case "date_completed":
            return false;
          default:
            return false;
            break;
        }
      } else {
        return false;
      }
    },
    [type]
  );

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        id="taskkanban-form"
        layout="vertical"
        onFinish={onSubmit}
        onValuesChange={handleFormChange}
      >
        <Title level={5}>
          {parentModel.id > 0
            ? `Task cha: #${parentModel.id} - ${parentModel.name}`
            : null}
        </Title>
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Form.Item
              label={t("task:name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("task:form.error.error_name_require"),
                },
              ]}
            >
              <Input disabled={deniedEdit("name")} />
            </Form.Item>
          </Col>

          <Col md={8} xs={24}>
            <Form.Item
              label={t("task:org_cost")}
              name="org_cost"
              validateFirst
              rules={[
                {
                  required: true,
                  message: t("task:form.error.error_org_cost_require"),
                },
                {
                  message: t("task:form.error.error_org_cost"),
                  validator: (_: any, value: number) => {
                    if (value > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  },
                },
              ]}
            >
              <InputNumber
                className="w-full"
                disabled={deniedEdit("org_cost")}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              label={t("task:org_fromtime")}
              name="org_fromtime"
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: t("task:form.error.required_time"),
              //   },
              //   {
              //     message: t("task:form.error.required_time"),
              //     validator: (_: any, value: Moment) => {
              //       if (value) {
              //         return Promise.resolve();
              //       } else {
              //         return Promise.reject("Some message here");
              //       }
              //     },
              //   },
              // ]}
            >
              <DatePicker
                // defaultValue={moment(new Date())}
                inputReadOnly
                showTime
                style={{ width: "100%" }}
                placeholder={"dd/mm/yyyy"}
                format="HH:mm DD/MM/YYYY"
                disabled={deniedEdit("org_fromtime")}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              label={t("task:org_totime")}
              name="org_totime"
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: t("task:form.error.required_time"),
              //   },
              //   {
              //     message: t("task:form.error.required_time"),
              //     validator: (_: any, value: Moment) => {
              //       if (value) {
              //         return Promise.resolve();
              //       } else {
              //         return Promise.reject("Some message here");
              //       }
              //     },
              //   },
              //   {
              //     message: t("task:form.error.error_value_time"),
              //     validator: (_: any, value: Moment) => {
              //       if (
              //         moment(form.getFieldValue("org_fromtime")) < moment(value)
              //       ) {
              //         return Promise.resolve();
              //       } else {
              //         return Promise.reject("Some message here");
              //       }
              //     },
              //   },
              // ]}
            >
              <DatePicker
                // defaultValue={moment(new Date())}
                showTime
                inputReadOnly
                style={{ width: "100%" }}
                placeholder={"HH:mm dd/mm/yyyy"}
                format="HH:mm DD/MM/YYYY"
                disabled={deniedEdit("org_totime")}
              />
            </Form.Item>
          </Col>
          {/* <Col md={8} xs={24}>
            <Form.Item
              label={t("task:date_start")}
              name="date_start"
              style={{ width: "100%" }}
            >
              <DatePicker
                // defaultValue={moment(new Date())}
                inputReadOnly
                showTime
                style={{ width: "100%" }}
                placeholder={"dd/mm/yyyy"}
                format="HH:mm DD/MM/YYYY"
                disabled={deniedEdit("date_start")}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              label={t("task:date_completed")}
              name="date_completed"
              style={{ width: "100%" }}
              rules={[]}
            >
              <DatePicker
                // defaultValue={moment(new Date())}
                showTime
                inputReadOnly
                style={{ width: "100%" }}
                placeholder={"HH:mm dd/mm/yyyy"}
                format="HH:mm DD/MM/YYYY"
                disabled={deniedEdit("date_completed")}
              />
            </Form.Item>
          </Col> */}
          <Col md={8} xs={24}>
            <FormSelect
              showSearch
              // required
              label={t("task:type")}
              name="type"
              placeholder="Chọn loại task"
              options={TaskModel.getTypeOption()}
              disabled={deniedEdit("type")}
            />
          </Col>

          <Col md={8} xs={24}>
            <FormSelect
              showSearch
              // required
              label={t("task:priority")}
              name="priority"
              placeholder="Độ ưu tiên"
              options={TaskModel.getPriorityOption()}
              disabled={deniedEdit("priority")}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormSelect
              showSearch
              // required
              label={t("task:ability")}
              name="ability"
              placeholder="Khả thi"
              options={TaskModel.getAbilityOption()}
              disabled={deniedEdit("ability")}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormSelect
              showSearch
              // required
              label={t("task:public")}
              name="public"
              placeholder=""
              options={TaskModel.getAbilityOption()}
              disabled={deniedEdit("ability")}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormSelect
              showSearch
              // required
              label={t("task:flag")}
              name="flag"
              disabled={deniedEdit("flag")}
              options={[
                { label: "Có", value: 1 },
                { label: "Không", value: 0 },
              ]}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormSelect
              showSearch
              // required
              label={t("task:status")}
              name="status"
              disabled={deniedEdit("status")}
              options={TaskModel.getStatusOption()}
            />
          </Col>

          <Col md={8} xs={24}>
            <Form.Item
              label={t("task:time_checking")}
              name="rel_cost"
              rules={[
                {
                  message: t("task:form.error.error_time_checking"),
                  validator: (_: any, value: number) => {
                    if (value >= 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  },
                },
              ]}
            >
              <Input disabled={deniedEdit("rel_cost")} />
            </Form.Item>
          </Col>

          <Col md={8} xs={24}>
            <Form.Item
              label={t("task:percent_complete")}
              name="org_complete"
              style={{ width: "100%" }}
              rules={[
                {
                  message: t("task:form.error.error_org_complete_value"),
                  validator: (_: any, value: number) => {
                    if (value >= 0 && value <= 100) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  },
                },
              ]}
            >
              <InputNumber
                addonAfter="%"
                style={{ width: "100%" }}
                disabled={deniedEdit("org_complete")}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              label={t("ID các task liên quan")}
              name="scope_ids"
              rules={[
                {
                  message: t("Các ID viết liền và chỉ cách nhau bởi dấu phẩy"),
                  validator: (_: any, value: string) => {
                    if (validateManyId(value) || value.length === 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  },
                },
              ]}
            >
              <Input disabled={deniedEdit("scope_ids")} />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <FormSelectCustom
              label={t("ID task gốc")}
              name="org_id"
              options={createScopeIdOption(parentModel.scope_ids)}
            />
          </Col>
          <Col md={8} xs={24}>
            <Form.Item label={t("ID task cha")} name="parent_id">
              <Input disabled={deniedEdit("org_id")} />
            </Form.Item>
          </Col>

          <Col md={24} xs={24}>
            <Form.Item name="note" label={t("task:note")}>
              <ReactQuill
                theme="snow"
                // value={valueEditor}
                style={{
                  //   maxHeight: "500px",
                  height: "100%",
                  width: "100%",
                  //   marginBottom: "70px",
                }}
                modules={{
                  toolbar: toolbarOptions,
                }}
                readOnly={deniedEdit("note")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TaskKanbanForm;
