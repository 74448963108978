import BaseCollection from 'common/collections/BaseCollection';
import StoreModel from 'common/models/StoreModel';
import { StoreCollectionJson, StoreJson } from 'common/types/Store';

class StoreCollection extends BaseCollection<
  StoreJson,
  StoreModel,
  StoreCollectionJson
> {
  itemsFromJson(jsonItems: StoreJson[]): StoreModel[] {
    return jsonItems.map((item) => new StoreModel(item));
  }
}

export default StoreCollection;
