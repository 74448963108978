import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TooltipPlacement } from "antd/lib/tooltip";
import { Button, message, Popconfirm, Spin, Typography } from "antd";
import { IconPin, IconPinnedOff, IconTrash } from "@tabler/icons-react";

import Error from "components/LayoutError";

interface IDeleteItem {
  editPin: (data: any) => Promise<any>;
}

const TablePin = ({
  onDeleteCallback,
  onDeleteErrorCallback,
  className,
  id,
  repository,
  disabled,
  error_translate_prefix,
  icon,
  title,
  text,
  icon_custom,
  placement,
  record,
}: {
  id: number;
  repository: IDeleteItem;
  onDeleteCallback: (id: number) => void;
  onDeleteErrorCallback?: (id: number) => void;
  className?: string;
  disabled?: boolean;
  error_translate_prefix?: string;
  icon?: React.ReactNode;
  title?: string;
  text?: string;
  icon_custom?: React.ReactNode;
  placement?: TooltipPlacement | undefined;
  record?: any;
}) => {
  // console.log("🚀 ~ file: TableDelete.tsx:40 ~ placement:", placement);
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);

  const messageKeyDeletePrefix = "delete_item_";

  const { Title } = Typography;

  const onDeleteItem = async (id: number, record: any) => {
    //for null delete (id == 0), we just delete it without call to server
    if (id <= 0) {
      if (onDeleteCallback) {
        onDeleteCallback(id);
      }
    } else {
      setProcessing(true);
      message.loading({
        content: t("common:form.processing", { sid: id }),
        key: messageKeyDeletePrefix + id,
        duration: 0,
      });

      const deletedErrors = await repository.editPin(record);
      setProcessing(false);
      if (deletedErrors.length === 0) {
        message.success({
          content: t("notification:dropdown.success"),
          className: "message_success",
          key: messageKeyDeletePrefix + id,
          duration: 1,
        });

        if (onDeleteCallback) {
          onDeleteCallback(id);
        }
      } else {
        if (onDeleteErrorCallback) {
          onDeleteErrorCallback(id);
        }
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKeyDeletePrefix + id);
              }}
              heading={t("common:error.table_delete_heading")}
              translate_prefix={error_translate_prefix || ""}
              items={deletedErrors}
            />
          ),
          className: "message_error",
          key: messageKeyDeletePrefix + id,
          duration: 10,
        });
      }
    }
  };

  const comButton = (
    <Button
      type="link"
      onClick={() => id <= 0 && onDeleteItem(0, null)}
      size={"small"}
      style={{ whiteSpace: "break-spaces" }}
      icon={typeof icon !== "undefined" ? icon : null}
      block={true}
      className={className}
    >
      {record.pin === 0 ? <>{text}</> : <>{text}</>}
    </Button>
  );

  //incase null delete (id <= 0) do not use popconfirm
  if (id <= 0) return comButton;

  return (
    <Popconfirm
      title={title || t("common:table.confirm_delete_title")}
      placement={placement || "topRight"}
      onConfirm={() => {
        onDeleteItem(id, record);
      }}
      okText={t("common:table.confirm_ok")}
      cancelText={t("common:table.confirm_cancel")}
      disabled={processing || disabled}
    >
      {processing ? <Spin size="small" /> : comButton}
    </Popconfirm>
  );
};

export default TablePin;
