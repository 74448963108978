import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

import FormSection from "components/form/FormSection";
import FormSelectNormal from "components/form/FormSelectNormal";
import LayoutForm from "components/form/LayoutForm";

import { IconPhoto } from "@tabler/icons-react";
import Collection from "common/constants/Collection";
import StoryCollectionConstant from "common/constants/StoryCollection";
import FileModel from "common/models/FileModel";
import StoryCollectionModel from "common/models/StoryCollectionModel";
import StoryCollectionRepository from "common/repositories/StoryCollectionRepository";
import { StoryCollectionAddEditJson } from "common/types/StoryCollection";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FormSelect from "components/form/FormSelect";
import StoryCollectionFormHeader from "./StoryCollectionFormHeader";
import StoryCollectionFormSelect from "./StoryCollectionFormSelect";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import { IconPlus } from "@tabler/icons-react";
import TagModel from "common/models/TagModel";
import { SelectOption } from "common/types/SelectOption";
import TagConstant from "common/constants/Tag";
import TagRepository from "common/repositories/TagRepository";
import TagDetailModel from "common/models/TagDetailModel";

type Props = {
  model: StoryCollectionModel;
};
const StoryCollectionForm = ({ model }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;

  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [typeCollection, setTypeCollection] = useState(Collection.TYPE_STORY);
  const [parentId, setParentId] = useState<number>(model.parent_id);
  const [selectTagReload, setSelectTagReload] = useState<boolean>(false);
  const [existTagValues, setExistTagValue] = useState<number[]>([]);

  //default data
  const initialValues = useMemo(() => {
    return {
      title: model.title,
      // images: FileModel.convertToUploadFiles([new FileModel(model.image)]),
      // banner_desktop_image: FileModel.convertToUploadFiles(
      //   model.banner_desktop.map((item) => {
      //     return new FileModel({
      //       ...FileModel.getDefaultData(),
      //       id: item.id,
      //       file_path: item.file_path,
      //       url: item.url,
      //       md5_hash: item.url,
      //       extension: "jpg",
      //     });
      //   })
      // ),
      // banner_mobile_image: FileModel.convertToUploadFiles(
      //   model.banner_mobile.map((item) => {
      //     return new FileModel({
      //       ...FileModel.getDefaultData(),
      //       id: item.id,
      //       file_path: item.file_path,
      //       url: item.url,
      //       md5_hash: item.url,
      //       extension: "jpg",
      //     });
      //   })
      // ),
      images: FileModel.convertToUploadFiles(
        model.images.map((item) => {
          return new FileModel({
            ...FileModel.getDefaultData(),
            id: item.id,
            file_path: item.file_path,
            url: item.url,
            md5_hash: item.url,
            extension: "jpg",
          });
        })
      ),
      description: model.description,
      publish_from:
        model.publish_from > 0 ? dayjs(new Date(model.publish_from * 1000)) : 0,
      publish_to:
        model.publish_to > 0 ? dayjs(new Date(model.publish_to * 1000)) : 0,
      date_published:
        model.date_published > 0
          ? dayjs(new Date(model.date_published * 1000))
          : 0,
      handle: model.handle,
      parent_id: model.parent_id > 0 ? model.parent_id : 0,
      pin: model.pin >= 0 ? model.pin : null,
      type: model.type > 0 ? model.type : null,
      status: model.id === 0 ? 1 : model.status,
    };
  }, [model]);
  console.log("🥺 ~ initialValues ~ initialValues:", initialValues);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      console.log("🥺 ~ StoryCollectionForm ~ formData:", formData);

      const submitData: any = {
        // ...StoryModel.getDefaultData(),
        id: model.id,
        title: formData.title,
        description: formData.description,
        publish_from:
          Helper.datePickerValueToTimestamp(formData.publish_from) > 0
            ? Helper.datePickerValueToTimestamp(formData.publish_from)
            : undefined,
        publish_to:
          Helper.datePickerValueToTimestamp(formData.publish_to) > 0
            ? Helper.datePickerValueToTimestamp(formData.publish_to)
            : undefined,
        handle: formData.handle,
        banner_desktop:
          formData.banner_desktop_image &&
            formData.banner_desktop_image.length > 0 &&
            formData.banner_desktop_image[0].response
            ? formData.banner_desktop_image[0]?.response.id
            : 0,
        banner_mobile:
          formData.banner_mobile_image &&
            formData.banner_mobile_image.length > 0 &&
            formData.banner_mobile_image[0].response
            ? formData.banner_mobile_image[0]?.response.id
            : 0,
        images:
          formData.images &&
            formData.images.length > 0 &&
            formData.images[0].response
            ? formData.images[0].response.id
            : 0,
        parent_id: parentId > 0 ? parentId : 0,
        date_published:
          Helper.datePickerValueToTimestamp(formData.date_published) > 0
            ? Helper.datePickerValueToTimestamp(formData.date_published)
            : undefined,
        type: formData.type,
        pin: formData.pin,
        status: formData.status,
      };

      return submitData;
    },
    [model.id, parentId]
  );

  //submit data to server
  const onSubmit = async (formData: StoryCollectionAddEditJson) => {
    console.log(doPrepareData(formData));
    const response = await new StoryCollectionRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      setIsSuccess(true);
    } else {
      setErrors(response.error.errors);
    }
  };

  //functions

  //sidebar form
  const sidebarItems = <></>;

  const toolbarOptions = [
    [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction"],
    ["link", "image", "video", "formula"],
    ["clean"],
  ];

  return (
    <div>
      <StoryCollectionFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess="/storycollection"
        error_translate_prefix="storycollection:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("storycollection:form.section_main")}
          subtitle={t("storycollection:form.section_subtitle_main")}
          divider
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="title"
                label={t("storycollection:title")}
                required
                rules={[
                  {
                    required: true,
                    message: t("storycollection:rules.title"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <FormSelectNormal
                required
                name="type"
                label={t("storycollection:story_type")}
                placeholder={t("storycollection:story_type")}
                // onChange={(e) => {
                //   setTypeCollection(Number(e));
                // }}
                rules={[
                  {
                    required: true,
                    message: t("storycollection:rules.story_type"),
                  },
                ]}
                options={[
                  { value: StoryCollectionConstant.TYPE_STORY, label: "Blog" },
                  {
                    value: StoryCollectionConstant.TYPE_ACADEMY,
                    label: "Academy",
                  },
                  { value: StoryCollectionConstant.TYPE_ADMIN, label: "Admin" },
                ]}
              />
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Danh mục cha" name="parent_id">
                <StoryCollectionFormSelect
                  placeholder="Chọn loại danh mục cha"
                  values={model.parent_id > 0 ? [model.parent_id] : []}
                  onChange={(v) => {
                    if (v.length > 0) {
                      setParentId(v[0]);
                    } else {
                      setParentId(0);
                    }
                  }}
                  isMulti={false}
                />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item name="handle" label={t("storycollection:handle")}>
                <Input placeholder={t("product:placeholder_URL")} />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}></Col>
            <Col md={24} xs={24}>
              <Form.Item
                name="description"
                label={t("storycollection:detail")}
                required
                validateFirst
                rules={[
                  {
                    required: true,
                    message: t("storycollection:rules.detail"),
                  },
                  {
                    message: t("storycollection:rules.detail"),
                    validator: (_: any, value: string) => {
                      if (value !== "<p><br></p>") {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  style={{
                    height: "300px",
                    width: "100%",
                    marginBottom: "70px",
                  }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("storycollection:form.section_sub")}
          subtitle={t("storycollection:form.section_subtitle_sub")}
        >
          <Row gutter={[8, 8]}>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("productcollection:effective_date")}
                name="date_published"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("storycollection:publish_from")}
                name="publish_from"
                style={{ width: "100%" }}
                rules={[
                  {
                    message: t("storycollection:form.error.error_publish_from"),
                    validator: (_: any, value: any) => {
                      if (
                        Helper.datePickerValueToTimestamp(
                          form.getFieldValue("date_published")
                        ) >= Helper.datePickerValueToTimestamp(value) ||
                        !Helper.datePickerValueToTimestamp(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("storycollection:publish_to")}
                name="publish_to"
                style={{ width: "100%" }}
                rules={[
                  {
                    message: t("leave:form.error.error_range_date"),
                    validator: (_: any, value: any) => {
                      if (
                        Helper.datePickerValueToTimestamp(
                          form.getFieldValue("publish_from")
                        ) < Helper.datePickerValueToTimestamp(value) ||
                        !Helper.datePickerValueToTimestamp(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                  showTime
                  disabledDate={(current) => {
                    return current.isBefore(form.getFieldValue("publish_from"));
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <FormSelect
                label={t("tag:status")}
                name="status"
                placeholder={"Trạng thái"}
                options={StoryCollectionModel.getStatusSelectList()}
                popupMatchSelectWidth={false}
              />
            </Col>

            <Col md={8} xs={8}>
              <FormSelect
                name="pin"
                label="Ghim"
                options={[
                  { label: "Có", value: 1 },
                  { label: "Không", value: 0 },
                ]}
              />
            </Col>

            <Col md={24} xs={24} span={24}>
              <Collapse className="mt-4 mb-2 bg-white">
                <Collapse.Panel header={t("collection:image_group")} key={"1"}>
                  <Row>
                    <Col md={8} xs={24}>
                      <Form.Item className="m-0" label="Ảnh chi tiết">
                        <FileUploaderButton
                          form={form}
                          formName="images"
                          label={t("storycollection:image")}
                          icon={<IconPhoto size="24" color="grey" />}
                          origin="avatar"
                          uploadRenderType="thumbnail"
                          isSingleFile={true}
                          initFiles={initialValues.images}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item className="m-0" label="Banner desktop">
                        <FileUploaderButton
                          form={form}
                          formName="banner_desktop_image"
                          label={t("Banner desktop")}
                          icon={<IconPhoto size="24" color="grey" />}
                          origin="avatar"
                          uploadRenderType="thumbnail"
                          isSingleFile={true}
                          initFiles={[]}

                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item className="m-0" label="Banner mobile">
                        <FileUploaderButton
                          form={form}
                          formName="banner_mobile_image"
                          label={t("Banner mobile")}
                          icon={<IconPhoto size="24" color="grey" />}
                          origin="avatar"
                          uploadRenderType="thumbnail"
                          isSingleFile={true}
                          initFiles={[]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default StoryCollectionForm;
