import { Skeleton } from "antd";
import DataGridTableModel from "common/models/DataGridTableModel";
import DataGridTableRepository from "common/repositories/DataGridTableRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import DataGridTableForm from "features/datagrid/table/form/DataGridTableForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DataGridTableFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
  workspaceId,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: DataGridTableModel) => void;
  workspaceId: number;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<DataGridTableModel>(
    new DataGridTableModel(DataGridTableModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new DataGridTableRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={
        id > 0
          ? t("datagridtable:heading_edit")
          : t("datagridtable:heading_add")
      }
      width={640}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="datagridtable-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <DataGridTableForm
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <DataGridTableForm
          model={
            new DataGridTableModel({
              ...DataGridTableModel.getDefaultData(),
              datagrid_workspace_id: workspaceId,
            })
          }
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default DataGridTableFormModal;
