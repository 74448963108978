import BaseCollection from "common/collections/BaseCollection";
import ProductModel from "common/models/ProductModel";
import ProductPublicModel from "common/models/ProductPublicModel";
import {
  ProductPublicCollectionJson,
  ProductJsonPublic,
} from "common/types/Product";

class ProductPublicCollection extends BaseCollection<
  ProductJsonPublic,
  ProductPublicModel,
  ProductPublicCollectionJson
> {
  itemsFromJson(jsonItems: ProductJsonPublic[]): ProductPublicModel[] {
    return jsonItems.map((item) => new ProductPublicModel(item));
  }
}

export default ProductPublicCollection;
