import CmsNewsModel from 'common/models/CmsNewsModel';
import CmsNewsRepository from 'common/repositories/CmsNewsRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import CmsNewsFormEditor from 'features/cmsnews/form/CmsNewsFormEditor';
import CmsNewsFormEditorHeader from 'features/cmsnews/form/CmsNewsFormEditorHeader';
import CmsNewsList from 'features/cmsnews/list/CmsNewsList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const CmsNewsPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<CmsNewsModel>(
    new CmsNewsModel(CmsNewsModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new CmsNewsModel(CmsNewsModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <CmsNewsFormEditor
          model={new CmsNewsModel(CmsNewsModel.getDefaultData())}
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <CmsNewsFormEditor model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new CmsNewsRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<CmsNewsFormEditorHeader isEditing={true} />}
          />
        );

      break;
    default:
      com = <CmsNewsList />;
  }

  return com;
};

export default CmsNewsPage;
