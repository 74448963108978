import { PosCartPromotionOrder } from 'common/types/PosCart';
import { PromotionCheckResultJson } from 'common/types/PromotionCheck';
import update from 'immutability-helper';
import _ from 'lodash';

import BaseModel from './BaseModel';

class PromotionCheckResultModel extends BaseModel {
  order: PosCartPromotionOrder[];
  product: PosCartPromotionOrder[];
  product_seasonal: PosCartPromotionOrder[];
  total: number;

  constructor(json: PromotionCheckResultJson) {
    super();

    this.order = json["order"] || [];

    if (!_.isEmpty(json["product"])) {
      //ensure the product item DO NOT CONTAIN quantity props
      //because it's will trigger compare on check useEffect ^^!
      this.product = json["product"].map((product) => {
        return {
          ...product,
          products: product?.products?.map((item) => {
            return update(item, { $unset: ["quantity"] });
          }),
        };
      });
    } else {
      this.product = [];
    }

    if (!_.isEmpty(json["product_seasonal"])) {
      //ensure the product item DO NOT CONTAIN quantity props
      //because it's will trigger compare on check useEffect ^^!
      this.product_seasonal = json["product_seasonal"].map((product) => {
        return {
          ...product,
          products: product?.products?.map((item) => {
            return update(item, { $unset: ["quantity"] });
          }),
        };
      });
    } else {
      this.product_seasonal = [];
    }

    this.total = json["total"] || 0;
  }

  static getDefaultData(): PromotionCheckResultJson {
    return {
      order: [],
      product: [],
      product_seasonal: [],
      total: 0,
    };
  }
}

export default PromotionCheckResultModel;
