// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --active-color: #d72229;
  --border-color: #d8d7d8;
  --title-color: #000;
  --title-color-default: #3a393a;
  --tag-color: #6f6f6f;

  /*  */
}

.sizeSmall {
  --title-fontsize: 12px;
  --text-fontsize: 10px;
  --tag-fontsize: 10px;
}

.sizeDefault {
  --title-fontsize: 14px;
  --text-fontsize: 14px;
  --tag-fontsize: 12px;
}

@media screen and (max-width: 768px) {
  .sizeSmall {
    --title-fontsize: 12px;
    --text-fontsize: 10px;
    --tag-fontsize: 8px;
  }

  .sizeDefault {
    --title-fontsize: 12px;
    --text-fontsize: 10px;
    --tag-fontsize: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/css/Academy-varible.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;EACnB,8BAA8B;EAC9B,oBAAoB;;EAEpB,KAAK;AACP;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE;IACE,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;EACrB;AACF","sourcesContent":[":root {\n  --active-color: #d72229;\n  --border-color: #d8d7d8;\n  --title-color: #000;\n  --title-color-default: #3a393a;\n  --tag-color: #6f6f6f;\n\n  /*  */\n}\n\n.sizeSmall {\n  --title-fontsize: 12px;\n  --text-fontsize: 10px;\n  --tag-fontsize: 10px;\n}\n\n.sizeDefault {\n  --title-fontsize: 14px;\n  --text-fontsize: 14px;\n  --tag-fontsize: 12px;\n}\n\n@media screen and (max-width: 768px) {\n  .sizeSmall {\n    --title-fontsize: 12px;\n    --text-fontsize: 10px;\n    --tag-fontsize: 8px;\n  }\n\n  .sizeDefault {\n    --title-fontsize: 12px;\n    --text-fontsize: 10px;\n    --tag-fontsize: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
