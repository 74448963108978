import DepartmentRepository from "common/repositories/DepartmentRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DepartmentFormSelect = ({
  label,
  name,
  parentId,
  ignoreValues,
  placeholder,
  required = false,
  disabled = false,
}: {
  label: string;
  name: string;
  parentId?: number;
  ignoreValues?: number[];
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new DepartmentRepository().getItems({
      filters: DepartmentRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
          parent_id: item.parent_id,
        }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  return (
    <FormSelect
      isTree={typeof parentId === "undefined"}
      placeholder={placeholder ?? t("employee:form.department_placeholder")}
      allowClear
      loading={loading}
      showSearch
      label={label}
      name={name}
      options={options
        .filter((item) =>
          typeof parentId === "undefined" ? true : parentId === item.parent_id
        )
        .filter(
          (item) =>
            typeof ignoreValues === "undefined" ||
            !ignoreValues.includes(item.value)
        )}
      errors={errors}
      errorRetry={fetchData}
      required={required}
      rules={
        required
          ? [
              {
                required: true,
                message: t("employee:form.error.error_department_id_require"),
              },
            ]
          : []
      }
      disabled={disabled}
    />
  );
};

export default DepartmentFormSelect;
