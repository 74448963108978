import DepartmentApi from 'common/api/DepartmentApi';
import DepartmentCollection from 'common/collections/DepartmentCollection';
import NestedCollection from 'common/collections/NestedCollection';
import DepartmentModel from 'common/models/DepartmentModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  DepartmentJsonWithChildren,
  DepartmentJsonAddEdit,
  FilterDepartment,
} from "common/types/Department";

class DepartmentRepository extends BaseRepository<DepartmentApi> {
  _api: DepartmentApi | null;

  constructor() {
    super();
    this._api = new DepartmentApi(true);
  }

  static getDefaultFilters(): FilterDepartment {
    return {
      page: 1,
      limit: 200,
      sortby: "display_order",
      sorttype: "ASC",
      status: -1,
      keyword: "",
    };
  }

  async getFullNestedItems() {
    return this._api
      ? await this._api.getAll()
      : new NestedCollection<DepartmentJsonWithChildren>();
  }

  async getItems(props: GetListProps<FilterDepartment>) {
    return this._api
      ? await this._api.getList(props)
      : new DepartmentCollection();
  }

  async getItem(id: number): Promise<DepartmentModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new DepartmentModel(DepartmentModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: DepartmentJsonAddEdit): Promise<DepartmentModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new DepartmentModel(DepartmentModel.getDefaultData());
    }
  }
}

export default DepartmentRepository;
