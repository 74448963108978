import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import TableLinkText from "components/table/TableLinkText";
import ReportChartNumber from "features/report/chart/ReportChartNumber";
import React from "react";

import { IconShoppingCart } from "@tabler/icons-react";

const ReportOrderProductAttributeGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartNumber
              className="p-2"
              label={"Giá trị của thuộc tính Tiêu đề"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="countdistinct_itemtitle"
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="countdistinct(itemtitle)"
              dataFilter="order_status < 20"
              dataJoin="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <TableLinkText
              link={"/report/order/productattribute/title"}
              className="text-base font-bold">
              {"Xem báo cáo bán hàng từng giá trị thuộc tính Tiêu đề"}
            </TableLinkText>
          </Col>
        </Row>
        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartNumber
              className="p-2"
              label={"Giá trị của thuộc tính Màu sắc"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="countdistinct_itemcolor"
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="countdistinct(itemcolor)"
              dataFilter="order_status < 20"
              dataJoin="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <TableLinkText
              link={"/report/order/productattribute/color"}
              className="text-base font-bold">
              {"Xem báo cáo bán hàng từng giá trị thuộc tính Màu sắc"}
            </TableLinkText>
          </Col>
        </Row>
        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartNumber
              className="p-2"
              label={"Giá trị của thuộc tính Kích cỡ"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="countdistinct_itemsize"
              dataTable="orderdetail"
              dataService="orderdetail"
              dataSelect="countdistinct(itemsize)"
              dataFilter="order_status < 20"
              dataJoin="order"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <TableLinkText
              link={"/report/order/productattribute/size"}
              className="text-base font-bold">
              {"Xem báo cáo bán hàng từng giá trị thuộc tính Kích cỡ"}
            </TableLinkText>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportOrderProductAttributeGeneral;
