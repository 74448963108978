import { Alert, Button, Divider, Skeleton, Typography, message } from "antd";
import SettingApi from "common/constants/Setting";

import { MegaMenuJson } from "common/types/ShopMegaMenu";
import PageSortList from "components/list/PageSortList";
import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import ShopMegaMenuHeader from "./ShopMegaMenuListHeader";
import ShopMegaMenuForm from "./ShopMegaMenuForm";
import useHandleSetting from "hooks/useHandleSetting";
import ListDeleteButton from "components/list/ListDeleteButton";
import ShopMegaMenuFilter from "./ShopMegaMenuFilter";

const ShopMegaMenuList = () => {
	const defaultDataPopup: MegaMenuJson = {
		id: 0,
		link: "",
		name: "",
		to_time: 0,
		from_time: 0,
		style: {
			color: "",
		},
		hide_in_page: false,
	};

	const [open, setOpen] = useState<boolean>(false);
	const [lang, setLang] = useState<string>("vi");

	const [data, setData] = useState<{
		data: MegaMenuJson;
		index: number;
		type: "add" | "edit";
	}>({
		data: defaultDataPopup,
		index: -1,
		type: "add",
	});

	const {
		error,
		dataSource,
		loading,
		addItemSetting,
		deleteItemSetting,
		editItemSetting,
		handleDrag,
	} = useHandleSetting<MegaMenuJson>(SettingApi.KEY.k_menu_home, lang);

	const handleDragEnd = async (result: DropResult) => {
		try {
			if (!result.destination) return;
			await handleDrag(result.source.index, result.destination.index);
			message.success({
				content: "Thao tác thành công",
				duration: 1,
			});
		} catch (error) {
			message.error({
				content: "Thao tác thất bại",
				duration: 1,
			});
		}
	};

	function handlelOpenModalAdd(data: MegaMenuJson) {
		setData({
			data: data,
			type: "add",
			index: -1,
		});
		setOpen(true);
	}
	function handlelOpenModalEdit(data: MegaMenuJson, index: number) {
		setData({
			data: data,
			type: "edit",
			index,
		});
		setOpen(true);
	}

	async function handleSumit(dataRes: MegaMenuJson) {
		try {
			if (data.type === "add") {
				// await editItemSetting(data)
				await addItemSetting(dataRes);
			} else {
				await editItemSetting(dataRes, data.index);
			}
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}

	async function handleDelete(data: MegaMenuJson, index: number) {
		try {
			await deleteItemSetting(data, index);
		} catch (error) {
		} finally {
			setOpen(false);
		}
	}

	const changeLang = (newLang: string) => {
		setLang(newLang);
	};

	if (error) {
		return <Alert message={error} />;
	}

	return (
		<>
			<ShopMegaMenuHeader>
				<Button
					type="primary"
					onClick={() => handlelOpenModalAdd(defaultDataPopup)}>
					Thêm
				</Button>
			</ShopMegaMenuHeader>
			<ShopMegaMenuFilter defaultLang={lang} changeLang={changeLang} />
			<Divider />
			{!loading ? (
				<>
					<PageSortList
						dataSource={dataSource}
						handleDragEnd={handleDragEnd}
						listKey="shopmegamenu"
						renderListItem={(item: MegaMenuJson, index) => {
							return (
								<>
									<div className="p-2 hover:bg-gray-200">
										<div className="flex  items-center justify-between">
											<Typography.Text className="font-bold">
												{item.name}
											</Typography.Text>
											<div className=" flex gap-2">
												<Button
													onClick={() => {
														handlelOpenModalEdit(item, index);
													}}>
													Sửa
												</Button>

												{/* <Button onClick={() => { handleDelete(item, index) }}>Xóa</Button> */}
												<ListDeleteButton
													handleDelete={() => {
														handleDelete(item, index);
													}}
													id={index}
												/>
											</div>
										</div>
									</div>
								</>
							);
						}}
					/>
				</>
			) : (
				<Skeleton active />
			)}
			<ShopMegaMenuForm
				type={data.type}
				data={data.data}
				open={open}
				onCancel={() => setOpen(false)}
				onFinish={async (data) => {
					await handleSumit(data);
				}}
			/>
		</>
	);
};

export default ShopMegaMenuList;
