import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CmsNewsCategoryFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:group_cms"),
      link: "",
    },
    {
      text: t("sitemenu:cms_news"),
      link: "/cmsnews",
    },
    {
      text: t("sitemenu:cms_news_category"),
      link: "/cmsnewscategory",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(
        isEditing
          ? "dmsnewscategory:heading_edit"
          : "dmsnewscategory:heading_add"
      )}
      siteMenuOpenKey="sitemenu:group_cms"
      siteMenuSelectedKey="/cmsnews"
    ></PageHeader>
  );
};

export default CmsNewsCategoryFormHeader;
