import BaseRepository from "./BaseRepository";
import MapServiceApi from "common/api/MapServiceApi";
import type { QueryPlaceProps } from "common/types/MapServiceAutoCompletePrediction";
import type { QueryPlaceDetailProps } from "common/types/MapServicePlace";
import MapServiceAutocompletePredictionCollection from "common/collections/MapServiceAutoCompletePredictionCollection";
import MapServicePlaceModel from "common/models/MapServicePlaceModel";

class MapServiceRepository extends BaseRepository<MapServiceApi> {
  _api: MapServiceApi | null;

  constructor() {
    super();
    this._api = new MapServiceApi(true);
  }

  async queryPlaceAutocomplete(
    props: QueryPlaceProps
  ): Promise<MapServiceAutocompletePredictionCollection> {
    return this._api
      ? await this._api.queryPlaceAutocomplete(props)
      : new MapServiceAutocompletePredictionCollection();
  }

  async queryPlaceDetail(
    props: QueryPlaceDetailProps
  ): Promise<MapServicePlaceModel> {
    return this._api
      ? await this._api.queryPlaceDetail(props)
      : new MapServicePlaceModel(MapServicePlaceModel.getDefaultData());
  }
}

export default MapServiceRepository;
