import OrderDetailApi from 'common/api/OrderDetailApi';
import OrderDetailCollection from 'common/collections/OrderDetailCollection';

import BaseRepository from './BaseRepository';

class OrderDetailRepository extends BaseRepository<OrderDetailApi> {
  _api: OrderDetailApi | null;

  constructor() {
    super();
    this._api = new OrderDetailApi(true);
  }

  async getItemsOfOrder(order_id: number) {
    return this._api
      ? await this._api.getListOfOrder(order_id)
      : new OrderDetailCollection();
  }
}

export default OrderDetailRepository;
