import { Divider, Skeleton } from "antd";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectTaskRepository from "common/repositories/ProjectTaskRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import ProjectTaskFormInfo from "features/projecttask/form/ProjectTaskFormUpdateStatus";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProjectTaskFormUpdateStatusModal = ({
  projectTaskId,
  open,
  setOpen,
  onSaveSuccess,
  projectModel,
}: {
  projectTaskId: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: ProjectTaskModel) => void;
  projectModel: ProjectModel;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<ProjectTaskModel>(
    new ProjectTaskModel(ProjectTaskModel.getDefaultData()),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new ProjectTaskRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (projectTaskId > 0 && errors.length === 0) {
      fetchDetail(projectTaskId);
    }
  }, [projectTaskId, errors]);

  return (
    <FormModal
      title={
        <span className="font-normal">
          <span>{model.code}</span>
          <Divider type="vertical" />
          <span className="font-normal text-gray-400">{model.name}</span>
        </span>
      }
      width={700}
      confirmLoading={processing}
      open={open}
      setOpen={setOpen}
      formId="project-task-form-update-status">
      <>
        {model.id > 0 || errors.length > 0 ? (
          <>
            {errors.length > 0 ? (
              <ErrorAlert
                items={errors}
                heading={t("common:error.error_fetching_data")}
                onRetry={() => {
                  setErrors([]);
                  fetchDetail(projectTaskId);
                }}
              />
            ) : (
              <ProjectTaskFormInfo
                projectModel={projectModel}
                projectTaskModel={model}
                setOpen={setOpen}
                setProcessing={setProcessing}
                onSaveSuccess={onSaveSuccess}
              />
            )}
          </>
        ) : (
          <>
            <Skeleton active />
          </>
        )}
      </>
    </FormModal>
  );
};

export default ProjectTaskFormUpdateStatusModal;
