import { Table } from "antd";
import ProductPublicModel from "common/models/ProductPublicModel";
import StoreModel from "common/models/StoreModel";
import TextStore from "components/TextStore";

export default function TableInventoryProductPublic({
    product,
    storeList,
  }: {
    product: ProductPublicModel;
    storeList?: StoreModel[];
  }) {
    const columns = [
      {
        title: "Chi nhánh",
        dataIndex: "store_id",
        key: "store_id",
        render: (id: number) => {
          return <TextStore stores={storeList} id={id} />;
        },
      },
      // {
      //   title: 'actual_quantity',
      //   dataIndex: 'actual_quantity',
      //   key: 'actual_quantity',
      // },
      {
        title: "Khách đặt",
        dataIndex: "reserved",
        key: "reserved",
      },
      {
        title: "Tồn kho",
        dataIndex: "quantity",
        key: "quantity",
      },
    ];
    return (
      <Table
        dataSource={product.quantities}
        columns={columns}
        pagination={false}
        summary={(pageData) => {
          let totalQuan = 0;
          let totalReserved = 0;
          pageData.forEach(({ quantity, reserved }) => {
            totalQuan += quantity;
            totalReserved += reserved;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <b>Tổng</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <b>{totalReserved}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <b>{totalQuan}</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    );
  }