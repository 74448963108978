import DepartmentRepository from "common/repositories/DepartmentRepository";
import OfficeRepository from "common/repositories/OfficeRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const OfficeFormSelect = ({
  label,
  name,
  parentId,
  ignoreValues,
  placeholder,
  disabled = false,
}: {
  label: string;
  name: string;
  parentId?: number;
  ignoreValues?: number[];
  placeholder?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new OfficeRepository().getItems({
      // filters: OfficeRepository.getDefaultFilters(),
      filters: {
        page: 1,
        limit: 200,
        sortby: "id",
        sorttype: "DESC",
        status: -1,
        keyword: "",
      },
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
          //   parent_id: item.parent_id,
        }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  return (
    <FormSelect
      required
      showSearch
      label={label}
      name={name}
      options={options}
      placeholder={placeholder}
      disabled={disabled}
      rules={[
        {
          required: true,
          message: t("employee:form.error.required_office"),
        },
      ]}
    />
  );
};

export default OfficeFormSelect;
