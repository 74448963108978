import Promotion from "common/constants/Promotion";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { PromotionJson } from "common/types/Promotion";

class PromotionModel extends BaseModel implements BaseModelJson<PromotionJson> {
	creator_id: number;
	company_id: number;
	id: number;
	group: number;
	type: number;
	code: string;
	name: string;
	store_scope: number;
	store_id_list: number[];
	customer_scope: number;
	min_check: number;
	value_type: number;
	value: string | string[];
	pass_code: string;
	status: number;
	start_date: number;
	end_date: number;
	modified_date: number;
	create_date: number;
	description: string;
	condition: object;
	date_created: number;
	date_modified: number;
	collection_list: number[];
	max_quantity_check: number;
	min_quantity_check: number;
	store_list: number[];

	constructor(json: PromotionJson) {
		super();

		this.creator_id = json.creator_id || 0;
		this.company_id = json.company_id || 0;
		this.id = json.id || 0;
		this.group = json.group || 0;
		this.type = json.type || 0;
		this.code = json.code || "";
		this.name = json.name || "";
		this.store_scope = json.store_scope || 0;
		this.store_id_list = json.store_id_list || [];
		this.customer_scope = json.customer_scope || 0;
		this.min_check = json.min_check || 0;
		this.value_type = json.value_type || 0;
		this.value = json.value || "";
		this.pass_code = json.pass_code || "";
		this.status = json.status || 0;
		this.start_date = json.start_date;
		this.end_date = json.end_date;
		this.modified_date = json.modified_date;
		this.create_date = json.create_date;
		this.status = json.status;
		this.type = json.type;
		this.value = json.value;
		this.value_type = json.value_type;
		this.description = json.description;
		this.condition = json.condition;
		this.name = json.name;
		this.date_created = json.date_created;
		this.date_modified = json.date_modified;
		this.collection_list = json.collection_list;
		this.max_quantity_check = json.max_quantity_check;
		this.min_quantity_check = json.min_quantity_check;
		this.store_list = json.store_list;
	}

	static getDefaultData(): PromotionJson {
		return {
			creator_id: 0,
			company_id: 0,
			id: 0,
			group: 0,
			type: 0,
			code: "",
			name: "",
			store_scope: 0,
			store_id_list: [],
			customer_scope: 0,
			min_check: 0,
			value_type: 0,
			value: "",
			pass_code: "",
			status: 0,
			date_created: 0,
			date_modified: 0,
			condition: {},
			create_date: 0,
			description: "",
			end_date: 0,
			modified_date: 0,
			start_date: 0,
			collection_list: [],
			max_quantity_check: 0,
			min_quantity_check: 0,
			store_list: [],
		};
	}

	toJson(): PromotionJson {
		return {
			creator_id: this.creator_id,
			company_id: this.company_id,
			id: this.id,
			group: this.group,
			type: this.type,
			code: this.code,
			name: this.name,
			store_scope: this.store_scope,
			store_id_list: this.store_id_list,
			customer_scope: this.customer_scope,
			min_check: this.min_check,
			value_type: this.value_type,
			value: this.value,
			pass_code: this.pass_code,
			status: this.status,
			date_created: this.date_created,
			date_modified: this.date_modified,
			condition: this.condition,
			create_date: this.create_date,
			description: this.description,
			end_date: this.end_date,
			modified_date: this.modified_date,
			start_date: this.start_date,
			collection_list: this.collection_list,
			max_quantity_check: this.max_quantity_check,
			min_quantity_check: this.min_quantity_check,
			store_list: this.store_list,
		};
	}

	static getStatusList(): SelectOption[] {
		return [
			{
				value: Promotion.STATUS_PENDING,
				label: i18n.t("promotion:STATUS_PENDING"),
				color: "gray",
			},
			{
				value: Promotion.STATUS_RUNNING,
				label: i18n.t("promotion:STATUS_RUNNING"),
				color: "green",
			},
			{
				value: Promotion.STATUS_PAUSED,
				label: i18n.t("promotion:STATUS_PAUSED"),
				color: "orange",
			},
			{
				value: Promotion.STATUS_ENDED,
				label: i18n.t("promotion:STATUS_ENDED"),
				color: "blue",
			},
			{
				value: Promotion.STATUS_REMOVED,
				label: i18n.t("promotion:STATUS_REMOVED"),
				color: "red",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}

	static getTypeList(): SelectOptionNormal[] {
		return [
			{
				value: Promotion.TYPE_SEASONAL_ORDER_DISCOUNT,
				label: i18n.t("promotion:TYPE_ORDER_DISCOUNT"),
			},
			{
				value: Promotion.TYPE_SEASONAL_PRODUCT_DISCOUNT,
				label: i18n.t("promotion:TYPE_PRODUCT_DISCOUNT"),
			},
		];
	}

	static getType(value: number): SelectOptionNormal | undefined {
		return this.getTypeList().find((item) => item.value === value);
	}

	static getGroupList(): { value: number; label: string }[] {
		return [
			{
				value: Promotion.GROUP_COUPON_ID,
				label: i18n.t("promotion:GROUP_COUPON"),
			},
			{
				value: Promotion.GROUP_SEASONAL_ID,
				label: i18n.t("promotion:GROUP_SEASONAL"),
			},
		];
	}

	static getTypeListInLoyalty(): SelectOption[] {
		return [
			{
				value: Promotion.TYPE_LOYALTY_COUPON,
				label: i18n.t("promotion:TYPE_LOYALTY_COUPON"),
			},
		];
	}

	static getTypeListInSeasonal(): SelectOption[] {
		return [
			{
				value: Promotion.TYPE_SEASONAL_ORDER_DISCOUNT,
				label: i18n.t("promotion:TYPE_SEASONAL_ORDER_DISCOUNT"),
			},
			{
				value: Promotion.TYPE_SEASONAL_PRODUCT_DISCOUNT,
				label: i18n.t("promotion:TYPE_SEASONAL_PRODUCT_DISCOUNT"),
			},
			{
				value: Promotion.TYPE_SEASONAL_PRODUCT_GIFT,
				label: i18n.t("promotion:TYPE_SEASONAL_PRODUCT_GIFT"),
			},
			{
				value: Promotion.TYPE_SEASONAL_ORDER_BATCH,
				label: i18n.t("promotion:TYPE_SEASONAL_ORDER_BATCH"),
			},
			{
				value: Promotion.TYPE_SEASONAL_ORDER_GIFT,
				label: i18n.t("promotion:TYPE_SEASONAL_ORDER_GIFT"),
			},
		];
	}

	static getTypeListPermanent(): SelectOption[] {
		return [
			{
				value: Promotion.TYPE_PERMANENT_PRODUCT_DISCOUNT,
				label: i18n.t("promotion:TYPE_PERMANENT_PRODUCT_DISCOUNT"),
			},
		];
	}

	static getGroup(value: number) {
		return this.getGroupList().find((item) => item.value === value);
	}

	static getGroupIdFromString(value: number) {
		return this.getGroupList().find((item) => item.value === value);
	}

	static getValueTypeList(): SelectOption[] {
		return [
			{
				value: Promotion.VALUETYPE_PERCENT,
				label: i18n.t("promotion:VALUETYPE_PERCENT"),
			},
			// {
			// 	value: Promotion.VALUETYPE_CURRENCY,
			// 	label: i18n.t("promotion:VALUETYPE_CURRENCY"),
			// },

			{
				value: Promotion.VALUETYPE_AMOUNT,
				label: i18n.t("promotion:VALUETYPE_AMOUNT"),
			},

			/*{
        value: Promotion.VALUETYPE_RANGE,
        label: i18n.t("promotion:VALUETYPE_RANGE"),
      },
      {
        value: Promotion.VALUETYPE_FIXPRICE,
        label: i18n.t("promotion:VALUETYPE_FIXPRICE"),
      },
      {
        value: Promotion.VALUETYPE_QUANTITY,
        label: i18n.t("promotion:VALUETYPE_QUANTITY"),
      }*/
		];
	}

	static getValueTypeProductList(): SelectOption[] {
		return [
			{
				value: Promotion.VALUETYPE_PERCENT,
				label: i18n.t("promotion:PRODUCT_VALUETYPE_PERCENT"),
			},
			{
				value: Promotion.VALUETYPE_CURRENCY,
				label: i18n.t("promotion:PRODUCT_VALUETYPE_CURRENCY"),
			},
		];
	}

	static getValueApplyList(): SelectOption[] {
		return [
			{
				value: Promotion.VALUETYPE_PERCENT,
				label: i18n.t("promotion:promotionseasonal.apply_percent"),
			},
			{
				value: Promotion.VALUETYPE_CURRENCY,
				label: i18n.t("promotion:promotionseasonal.apply_currency"),
			},
		];
	}

	static getValueType(value: number): SelectOption | undefined {
		return this.getValueTypeList().find((item) => item.value === value);
	}
	static getDiscountValueType(value: string) {
		switch (value) {
			case "percent":
				return "%";

			default:
				return "";
		}
	}
}

export default PromotionModel;
