import OrderApi from "common/api/OrderApi";
import OrderCollection from "common/collections/OrderCollection";
import OrderModel from "common/models/OrderModel";
import { GetListProps } from "common/types/Filter";
import { PosOrder } from "common/types/PosOrder";

import BaseRepository from "./BaseRepository";

import type { FilterOrder, OrderJsonEdit } from "common/types/Order";
import Delivery from "common/constants/Delivery";
class OrderRepository extends BaseRepository<OrderApi> {
  _api: OrderApi | null;

  constructor() {
    super();
    this._api = new OrderApi(true);
  }

  static getDefaultFilters(): FilterOrder {
    return {
      page: 1,
      limit: 20,
      sortby: "date_created",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
      origin: -1,
      store_id: -1,
      tag: "",
      sale_channel: -1,
      code: "",
      from_date: -1,
      to_date: -1,
      type: -1,
    };
  }

  async getItems(props: GetListProps<FilterOrder>) {
    return this._api ? await this._api.getList(props) : new OrderCollection();
  }

  async getItem(id: number): Promise<OrderModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new OrderModel(OrderModel.getDefaultData());
  }

  async createRemote(data: PosOrder): Promise<OrderModel> {
    return this._api
      ? await this._api.add(data)
      : new OrderModel(OrderModel.getDefaultData());
  }

  async saveRemote(data: OrderJsonEdit): Promise<OrderModel> {
    return this._api
      ? await this._api.edit(data)
      : new OrderModel(OrderModel.getDefaultData());
  }

  async confirm(data: Pick<OrderJsonEdit, "id" | "status">): Promise<OrderModel> {
    return this._api
      ? await this._api.comfirm(data)
      : new OrderModel(OrderModel.getDefaultData());
  }
  async cancel(data: Pick<OrderJsonEdit, "cancel_reason" | "id" | "status" | "note_cancel">): Promise<OrderModel> {
    return this._api
      ? await this._api.cancel(data)
      : new OrderModel(OrderModel.getDefaultData());
  }
}

export default OrderRepository;
