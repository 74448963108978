import { Divider, Modal, Spin } from "antd";
import EcomModel from "common/models/EcomModel";
import { EcomInitData, EcomInitPostMessage } from "common/types/Ecom";
import ErrorAlert from "components/ErrorAlert";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowEventListener } from "rooks";

import EcomFormAdd from "./form/EcomFormAdd";
import EcomItemTypeImage from "./item/EcomItemTypeImage";

const EcomInitModal = ({
  type,
  open,
  setOpen,
  onAddSuccess,
}: {
  type: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onAddSuccess: (o: EcomModel) => void;
}) => {
  const { t } = useTranslation();

  const [waiting, setWaiting] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [initData, setInitData] = useState<EcomInitData>();
  const [processing, setProcessing] = useState(false);

  /**
   * Handle message event, receive from other window (include OAuth)
   */
  const postMessageHandler: any = useCallback(
    (event: MessageEvent<EcomInitPostMessage>) => {
      //detect right message (because we will receive many message)
      if (
        event.data.hasOwnProperty("target") &&
        event.data.target === "ecomplatform_init"
      ) {
        //ensure "type" is matching
        if (event.data.data.type === type) {
          //stop waiting message
          setWaiting(false);

          console.log("Init data", event.data.data);
          if (event.data.data.access_token.length > 0) {
            //set init data (to prepare)
            setInitData({
              type,
              access_token: event.data?.data?.access_token || "",
              refresh_token: event.data?.data?.refresh_token || "",
              title: event.data?.data?.title || "",
              url: event.data?.data?.url || "",
            });
          } else {
            setErrors([t("ecom:form.error.oauth_access_token_empty")]);
          }
        } else {
          setErrors([
            "Receipt message data is not valid. event.data.data.type is not matched.",
          ]);
        }
      }
    },
    [type, t]
  );

  //listen "message" event (from oauth popup)
  useWindowEventListener("message", postMessageHandler);

  return (
    <Modal
      destroyOnClose={true}
      footer={typeof initData !== "undefined" ? undefined : null}
      centered
      confirmLoading={processing}
      width={waiting ? 300 : 800}
      style={{ left: waiting ? -450 : 0 }}
      bodyStyle={waiting ? { opacity: "0.5", cursor: "wait" } : {}}
      open={open}
      closable={typeof initData === "undefined"}
      maskClosable={false}
      onCancel={() => {
        setOpen(false);
      }}
      okButtonProps={{ form: "ecominit-form", htmlType: "submit" }}
      okText={t("ecom:form.init_save")}
      cancelText={t("common:close")}
    >
      <div className="text-center">
        <div style={{ width: 100, display: "inline-block" }}>
          <EcomItemTypeImage type={type} width={120} height={120} />
        </div>
      </div>

      {errors.length > 0 ? (
        <ErrorAlert
          style={{ marginTop: 10 }}
          translate_prefix=""
          items={errors}
          type="warning"
        />
      ) : null}

      {waiting ? (
        <div className="text-center">
          <div className="mb-4">
            <Spin />
          </div>
          {t("ecom:waiting_oauth")}
        </div>
      ) : (
        <>
          {typeof initData !== "undefined" ? (
            <div>
              <Divider className="mt-0" />
              <EcomFormAdd
                initialValues={initData}
                setOpen={setOpen}
                setProcessing={setProcessing}
                onAddSuccess={onAddSuccess}
              />
            </div>
          ) : null}
        </>
      )}
    </Modal>
  );
};

export default EcomInitModal;
