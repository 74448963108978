import ProjectTask from "common/constants/ProjectTask";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

import type {
  ProjectTaskJson,
  ProjectTaskReportJson,
  TotalProjectTaskJson,
  UnCompleteProjectTaskJson,
  ExpiredProjectTaskJson,
  ProjectTaskPermissionJson,
  ProjectTaskKanbanColumn,
  ProjectTaskAssigneeOption,
} from "common/types/ProjectTask";

class ProjectTaskModel
  extends BaseModel
  implements BaseModelJson<ProjectTaskJson>
{
  company_id: number;
  creator_id: number;
  project_id: number;
  project_iteration_id: number;
  id: number;
  name: string;
  code: string;
  story_prefix: string;
  description: string;
  files: FileModel[];
  count_bug: number;
  count_comment: number;
  assignees: string;
  can_update_status: number;
  note: string;
  parent_id: number;
  priority: number;
  progress_percent: number;
  rgb_color: string;
  tags: string;
  type: number;
  status: number;
  date_dot: string[];
  date_dot_future: number;
  date_dot_past: number;
  date_dot_present: number;
  date_completed?: number;
  date_completed_plan?: number;
  date_created?: number;
  date_deleted?: number;
  date_modified?: number;
  date_started?: number;

  constructor(json: ProjectTaskJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.project_id = json.project_id || 0;
    this.project_iteration_id = json.project_iteration_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.code = json.code || "";
    this.story_prefix = json.story_prefix || "";
    this.description = json.description || "";
    this.count_bug = json.count_bug || 0;
    this.count_comment = json.count_comment || 0;
    this.assignees = json.assignees || "";
    this.can_update_status = json.can_update_status || 0;
    this.note = json.note || "";
    this.parent_id = json.parent_id || 0;
    this.priority = json.priority || 0;
    this.progress_percent = json.progress_percent || 0;
    this.rgb_color = json.rgb_color || "";
    this.tags = json.tags || "";
    this.type = json.type || 0;
    this.files = [];
    if (typeof json.files !== "undefined" && Array.isArray(json.files)) {
      this.files = json.files.map((file) => new FileModel(file));
    }
    this.status = json.status || 0;
    this.date_dot = json.date_dot || 0;
    this.date_dot_future = json.date_dot_future || 0;
    this.date_dot_past = json.date_dot_past || 0;
    this.date_dot_present = json.date_dot_present || 0;
    this.date_completed = json.date_completed || 0;
    this.date_completed_plan = json.date_completed_plan || 0;
    this.date_created = json.date_created || 0;
    this.date_deleted = json.date_deleted || 0;
    this.date_modified = json.date_modified || 0;
    this.date_started = json.date_started || 0;
  }

  static getDefaultData(): ProjectTaskJson {
    return {
      company_id: 0,
      creator_id: 0,
      project_id: 0,
      project_iteration_id: 0,
      id: 0,
      name: "",
      code: "",
      story_prefix: "",
      description: "",
      files: [],
      count_bug: 0,
      count_comment: 0,
      assignees: "",
      can_update_status: 0,
      note: "",
      parent_id: 0,
      priority: 0,
      progress_percent: 0,
      rgb_color: "",
      tags: "",
      type: 0,
      status: 0,
      date_dot: [],
      date_dot_future: 0,
      date_dot_past: 0,
      date_dot_present: 0,
      date_completed: 0,
      date_completed_plan: 0,
      date_created: 0,
      date_deleted: 0,
      date_modified: 0,
      date_started: 0,
    };
  }

  static getDefaultDataProjectTaskReport(): ProjectTaskReportJson {
    return {
      id: 0,
      creator_id: 0,
      company_id: 0,
      name: "",
      count_task: 0,
      count_task_expired: 0,
      tasks: [],
    };
  }

  static getDefaultDataTotalProjectTask(): TotalProjectTaskJson {
    return {
      total: 0,
      items: [],
    };
  }

  static getDefaultDataUnCompleteProjectTask(): UnCompleteProjectTaskJson {
    return {
      total: 0,
      items: [],
    };
  }

  static getDefaultDataExpiredProjectTask(): ExpiredProjectTaskJson {
    return {
      total: 0,
      items: [],
    };
  }

  static getDefaultDataPermission(): ProjectTaskPermissionJson {
    return {
      can_edit_story: 0,
      can_add_story: 0,
    };
  }

  toJson(): ProjectTaskJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      project_id: this.project_id,
      project_iteration_id: this.project_iteration_id,
      id: this.id,
      name: this.name,
      code: this.code,
      story_prefix: this.story_prefix,
      description: this.description,
      files: this.files.map((file) => file.toJson()),
      count_bug: this.count_bug,
      count_comment: this.count_comment,
      assignees: this.assignees,
      can_update_status: this.can_update_status,
      note: this.note,
      parent_id: this.parent_id,
      priority: this.priority,
      progress_percent: this.progress_percent,
      rgb_color: this.rgb_color,
      tags: this.tags,
      type: this.type,
      status: this.status,
      date_dot: this.date_dot,
      date_dot_future: this.date_dot_future,
      date_dot_past: this.date_dot_past,
      date_dot_present: this.date_dot_present,
      date_completed: this.date_completed,
      date_completed_plan: this.date_completed_plan,
      date_created: this.date_created,
      date_deleted: this.date_deleted,
      date_modified: this.date_modified,
      date_started: this.date_started,
    };
  }

  static getDefaultAssignUser(): ProjectTaskAssigneeOption[] {
    return [
      {
        value: "me",
        label: i18n.t("projecttask:assigned_to_me"),
      },
      {
        value: "orther",
        label: i18n.t("projecttask:assigned_to_others"),
      },
    ];
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ProjectTask.STATUS_PENDING,
        label: i18n.t("projecttask:STATUS_PENDING"),
        color: "pink",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_ASSIGNED,
        label: i18n.t("projecttask:STATUS_ASSIGNED"),
        color: "cyan",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_WORKING,
        label: i18n.t("projecttask:STATUS_WORKING"),
        color: "geekblue",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_NEED_FOR_APPROVAL,
        label: i18n.t("projecttask:STATUS_NEED_FOR_APPROVAL"),
        color: "lime",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_REVIEWING,
        label: i18n.t("projecttask:STATUS_REVIEWING"),
        color: "purple",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_COMPLETED_WITH_BUG,
        label: i18n.t("projecttask:STATUS_COMPLETED_WITH_BUG"),
        color: "magenta",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_COMPLETED,
        label: i18n.t("projecttask:STATUS_COMPLETED"),
        color: "green",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_CANCELLED,
        label: i18n.t("projecttask:STATUS_CANCELLED"),
        color: "red",
        disabled: false,
      },
    ];
  }

  static getKanbanColumn(): SelectOption[] {
    return [
      {
        value: ProjectTask.STATUS_PENDING,
        label: i18n.t("projecttask:STATUS_PENDING"),
        color: "pink",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_ASSIGNED,
        label: i18n.t("projecttask:STATUS_ASSIGNED"),
        color: "cyan",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_WORKING,
        label: i18n.t("projecttask:STATUS_WORKING"),
        color: "geekblue",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_NEED_FOR_APPROVAL,
        label: i18n.t("projecttask:STATUS_NEED_FOR_APPROVAL"),
        color: "lime",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_REVIEWING,
        label: i18n.t("projecttask:STATUS_REVIEWING"),
        color: "purple",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_COMPLETED_WITH_BUG,
        label: i18n.t("projecttask:STATUS_COMPLETED_WITH_BUG"),
        color: "magenta",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_COMPLETED,
        label: i18n.t("projecttask:STATUS_COMPLETED"),
        color: "green",
        disabled: false,
      },
      {
        value: ProjectTask.STATUS_CANCELLED,
        label: i18n.t("projecttask:STATUS_CANCELLED"),
        color: "red",
        disabled: false,
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getPriorityList(): SelectOption[] {
    return [
      {
        value: ProjectTask.PRIORITY_LOW,
        label: i18n.t("projecttask:PRIORITY_LOW"),
        color: "gray",
      },
      {
        value: ProjectTask.PRIORITY_MEDIUM,
        label: i18n.t("projecttask:PRIORITY_MEDIUM"),
        color: "green",
      },

      {
        value: ProjectTask.PRIORITY_HIGH,
        label: i18n.t("projecttask:PRIORITY_HIGH"),
        color: "orange",
      },
      {
        value: ProjectTask.PRIORITY_IMMEDIATE,
        label: i18n.t("projecttask:PRIORITY_IMMEDIATE"),
        color: "red",
      },
    ];
  }

  static getPriority(value: number): SelectOption | undefined {
    return this.getPriorityList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: ProjectTask.TYPE_FEATURE,
        label: i18n.t("projecttask:TYPE_FEATURE"),
      },
      {
        value: ProjectTask.TYPE_ISSUE,
        label: i18n.t("projecttask:TYPE_ISSUE"),
      },
      {
        value: ProjectTask.TYPE_ENHANCEMENT,
        label: i18n.t("projecttask:TYPE_ENHANCEMENT"),
      },
      {
        value: ProjectTask.TYPE_PROPOSE,
        label: i18n.t("projecttask:TYPE_PROPOSE"),
      },
      {
        value: ProjectTask.TYPE_DESIGN,
        label: i18n.t("projecttask:TYPE_DESIGN"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }

  static getStatusListForKanbanColumn(): ProjectTaskKanbanColumn[] {
    let column: ProjectTaskKanbanColumn[] = [];
    this.getStatusList()
      // .filter(
      //   (i) =>
      //     i.value !== ProjectTask.STATUS_CANCELLED &&
      //     i.value !== ProjectTask.STATUS_COMPLETED
      // )
      .map((i) => {
        let color = "";
        switch (i.value) {
          case ProjectTask.STATUS_PENDING:
            color = "#e798ba";
            break;
          case ProjectTask.STATUS_ASSIGNED:
            color = "#3498db";
            break;
          case ProjectTask.STATUS_WORKING:
            color = "#2980b9";
            break;
          case ProjectTask.STATUS_NEED_FOR_APPROVAL:
            color = "#ac0";
            break;
          case ProjectTask.STATUS_REVIEWING:
            color = "#8e44ad";
            break;
          case ProjectTask.STATUS_COMPLETED_WITH_BUG:
            color = "#CC4C9A";
            break;
          case ProjectTask.STATUS_COMPLETED:
            color = "#2ecc71";
            break;
          case ProjectTask.STATUS_CANCELLED:
            color = "#7f8c8d";
            break;
        }

        return column.push({
          value: i.value,
          label: i.label,
          color: color,
          items: [],
        });
      });

    return column;
  }

  static getStatusListForKanbanMoreAction(): ProjectTaskKanbanColumn[] {
    let action: ProjectTaskKanbanColumn[] = [];
    this.getStatusList()
      .filter(
        (i) =>
          i.value === ProjectTask.STATUS_CANCELLED ||
          i.value === ProjectTask.STATUS_COMPLETED,
      )
      .map((i) => {
        let color = "";
        switch (i.value) {
          case ProjectTask.STATUS_COMPLETED:
            color = "#2ecc71";
            break;
          case ProjectTask.STATUS_CANCELLED:
            color = "#7f8c8d";
            break;
        }

        return action.push({
          value: i.value,
          label: i.label,
          color: color,
          items: [],
        });
      });
    return action;
  }
}

export default ProjectTaskModel;
