import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { NotificationJson } from "common/types/Notification";
import { SelectOption } from "common/types/SelectOption";

import BaseModel from "./BaseModel";

import i18n from "i18n";
import { FileJson } from "common/types/File";
import FileModel from "./FileModel";

class NotificationModel
  extends BaseModel
  implements BaseModelJson<NotificationJson>
{
  company_id: number;
  id: number;
  creator_id: number;
  type: number;
  meta_title: string;
  meta_summary: string;
  meta_detail: string;
  meta_image: FileModel;
  status: number;
  date_created: number;
  date_modified: number;
  date_deleted: number;
  pin: number;
  viewed: number;

  constructor(json: NotificationJson) {
    super();

    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.creator_id = json.creator_id || 0;
    this.type = json.type || 0;
    this.meta_title = json.meta_title || "";
    this.meta_summary = json.meta_summary || "";
    this.meta_detail = json.meta_detail || "";
    this.meta_image = new FileModel(
      json.meta_image || FileModel.getDefaultData()
    );
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_deleted = json.date_deleted || 0;
    this.pin = json.pin || 0;
    this.viewed = json.viewed || 0;
  }

  toJson(): NotificationJson {
    return {
      company_id: this.company_id,
      id: this.id,
      creator_id: this.creator_id,
      type: this.type,
      meta_title: this.meta_title,
      meta_summary: this.meta_summary,
      meta_detail: this.meta_detail,
      meta_image: this.meta_image,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_deleted: this.date_deleted,
      pin: this.pin,
      viewed: this.viewed,
    };
  }

  static getDefaultData(): NotificationJson {
    return {
      company_id: 0,
      id: 0,
      creator_id: 0,
      type: 1,
      meta_title: "",
      meta_summary: "",
      meta_detail: "",
      meta_image: FileModel.getDefaultData(),
      status: 0,
      date_created: 0,
      date_modified: 0,
      date_deleted: 0,
      pin: 0,
      viewed: 0,
    };
  }

  static getTypeNotification(type: number) {
    switch (type) {
      case 1:
        return i18n.t("notification:note");
      case 2:
        return i18n.t("notification:choice");
      default:
        return type;
    }
  }

  static getPinList(): SelectOption[] {
    return [
      {
        label: i18n.t("notification:pinon"),
        value: 1,
      },
      {
        label: i18n.t("notification:pinoff"),
        value: 0,
      },
    ];
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        label: i18n.t("notification:show"),
        value: 1,
      },
      {
        label: i18n.t("notification:hide"),
        value: 3,
      },
    ];
  }

  static getTypeNotificationList(): SelectOption[] {
    return [
      { label: i18n.t("notification:note"), value: 1 },
      { label: i18n.t("notification:choice"), value: 2 },
    ];
  }
  static getType(value: number): SelectOption | undefined {
    return this.getTypeNotificationList().find((item) => item.value === value);
  }
}

export default NotificationModel;
