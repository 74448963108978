import WorkTrackingCollection from "common/collections/WorkTrackingCollection";
import WorkTrackingModel from "common/models/WorkTrackingModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterWorkTrackingReview,
  WorkTrackingJsonVerify,
} from "common/types/WorkTracking";

const SERVICE_URL = "/worktrackingreviews";

class WorkTrackingReviewApi extends BaseApi {
  async getList(
    props: GetListProps<FilterWorkTrackingReview>,
  ): Promise<WorkTrackingCollection> {
    let collection = new WorkTrackingCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          status: filters.status ? filters.status : null,
          office_id: filters.office_id,
          id: +filters.id > 0 ? filters.id : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async edit(data: WorkTrackingJsonVerify): Promise<WorkTrackingModel> {
    let item = new WorkTrackingModel(WorkTrackingModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new WorkTrackingModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async verify(data: WorkTrackingJsonVerify): Promise<WorkTrackingModel> {
    let item = new WorkTrackingModel(WorkTrackingModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/" + data.id + "/verify",
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new WorkTrackingModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default WorkTrackingReviewApi;
