import ProjectModel from 'common/models/ProjectModel';
import ProjectRepository from 'common/repositories/ProjectRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import ProjectFormEditor from 'features/project/form/ProjectFormEditor';
import ProjectFormEditorHeader from 'features/project/form/ProjectFormEditorHeader';
import ProjectList from 'features/project/list/ProjectList';
import ProjectTaskHeader from 'features/projecttask/ProjectTaskHeader';
import ProjectTaskWrapper from 'features/projecttask/ProjectTaskWrapper';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const ProjectPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<ProjectModel>(
    new ProjectModel(ProjectModel.getDefaultData())
  );

  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new ProjectModel(ProjectModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <ProjectFormEditor
          model={new ProjectModel(ProjectModel.getDefaultData())}
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ProjectFormEditor model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new ProjectRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<ProjectFormEditorHeader isEditing={true} />}
          />
        );

      break;
    case "detail":
      com =
        model.id > 0 ? (
          <ProjectTaskWrapper projectModel={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new ProjectRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<ProjectTaskHeader model={model} />}
          />
        );
      break;
    default:
      com = <ProjectList />;
  }

  return com;
};

export default ProjectPage;
