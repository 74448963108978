import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShippingHubListHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("shippinghub:heading_list")}
      siteMenuOpenKey="setting_delivery"
      siteMenuSelectedKey="/shippinghub"
    >
      {children}
    </PageHeader>
  );
};

export default ShippingHubListHeader;
