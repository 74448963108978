import CashflowReceipt from 'common/constants/CashflowReceipt';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CashflowReceiptItemTargetCustomer from './target/CashflowReceiptItemTargetCustomer';
import CashflowReceiptItemTargetEmployee from './target/CashflowReceiptItemTargetEmployee';
import CashflowReceiptItemTargetSupplier from './target/CashflowReceiptItemTargetSupplier';

const CashflowReceiptItemTarget = ({
  model,
}: {
  model: CashflowReceiptModel;
}) => {
  const { t } = useTranslation();
  const targetCom = useMemo(() => {
    let com = null;
    switch (model.target) {
      case CashflowReceipt.TARGET_EMPLOYEE:
        com = <CashflowReceiptItemTargetEmployee model={model} />;
        break;
      case CashflowReceipt.TARGET_SUPPLIER:
        com = <CashflowReceiptItemTargetSupplier model={model} />;
        break;
      case CashflowReceipt.TARGET_CUSTOMER:
        com = <CashflowReceiptItemTargetCustomer model={model} />;
        break;
      case CashflowReceipt.TARGET_OTHER:
        com = <>{t("cashflowreceipt:TARGET_OTHER")}</>;
        break;
    }

    return com;
  }, [model, t]);

  return <>{targetCom}</>;
};

export default CashflowReceiptItemTarget;
