import Role from "common/constants/Role";
import Store from "common/constants/Store";
import { StoreJson } from "common/types/Store";
import useDatabaseTable from "hooks/useDatabaseTable";
import React from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import usePosCartStore from "zustands/usePosCartStore";

const PosStorePicker = () => {
  const { t } = useTranslation();
  const [company_id, creator_id, allowStoreIdList] = useLoginAccountStore(
    (state) => [
      state.account.company.id,
      state.account.id,
      state.getRoleObjectList(Role.ORDER_ADD),
    ]
  );
  const setActiveStore = usePosCartStore((state) => state.setActiveStore);

  const [storeItems] = useDatabaseTable<StoreJson>("store");
  return (
    <>
      <div className="px-64 pb-5">
        <div className="text-xl py-5 px-3 pt-8">
          {t("pos:store_pickup_heading")}
        </div>
        <div className="w-full text-gray-900 bg-white border border-gray-200">
          {storeItems
            .filter(
              (item) =>
                (allowStoreIdList.length === 0 ||
                  allowStoreIdList.includes(item.id)) &&
                item.status === Store.STATUS_ENABLE
            )
            .map((item) => (
              <button
                onClick={() =>
                  setActiveStore(item, {
                    company_id,
                    creator_id,
                  })
                }
                key={item.id}
                type="button"
                className="relative inline-flex w-full px-4 py-3 text-sm border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-1 focus:ring-blue-700 focus:text-blue-700"
              >
                {item.name}
              </button>
            ))}
        </div>
      </div>
    </>
  );
};

export default PosStorePicker;
