import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import RoleCheck from "components/RoleCheck";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";

const StoryListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("story:heading_list")}
      siteMenuOpenKey="content"
      siteMenuSelectedKey="/story"
    >
      <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <RoleCheck roles={[]}>
            <PageHeaderButton link="/story/add">
              {t("template:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default StoryListHeader;
