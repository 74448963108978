import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";

import PageHeader from "components/page/PageHeader";

const BrandFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("brand:heading_nav"),
      link: "/brand",
    },
    {
      text: t(isEditing ? "brand:heading_edit" : "brand:heading_add"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t(isEditing ? "brand:heading_edit" : "brand:heading_add")}
      nav={nav}
      siteMenuOpenKey="product"
      siteMenuSelectedKey="/brand"
      siteMenuHidden
    ></PageHeader>
  );
};

export default BrandFormHeader;
