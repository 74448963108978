import ReportLeaveApi from "common/api/ReportLeaveApi";
import ReportLeaveCollection from "common/collections/ReportLeaveCollection";
import { GetListProps } from "common/types/Filter";
import { FilterReportLeave } from "common/types/ReportLeave";

import BaseRepository from "./BaseRepository";

class ReportLeaveRepository extends BaseRepository<ReportLeaveApi> {
  _api: ReportLeaveApi | null;

  constructor() {
    super();
    this._api = new ReportLeaveApi(true);
  }

  static getDefaultFilters(): FilterReportLeave {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      status: "",
      keyword: "",
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      office_id: 0,
      department_id: -1,
    };
  }

  async getItems(
    props: GetListProps<FilterReportLeave>
  ): Promise<ReportLeaveCollection> {
    return this._api
      ? await this._api.getList(props)
      : new ReportLeaveCollection();
  }

  async getItemsUser(
    props: GetListProps<FilterReportLeave>
  ): Promise<ReportLeaveCollection> {
    return this._api
      ? await this._api.getListUser(props)
      : new ReportLeaveCollection();
  }

  async exportExcel(props: GetListProps<FilterReportLeave>): Promise<boolean> {
    return this._api ? await this._api.exportExcel(props) : false;
  }
}

export default ReportLeaveRepository;
