import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReportCheckinRepository from "common/repositories/ReportCheckinRepository";

const DownloadModal = ({
  loading,
  fileName,
  visible,
  setVisible,
  handleCloseModal,
}: {
  loading: boolean;
  fileName: string;
  visible: boolean;
  setVisible: (b: boolean) => any;
  handleCloseModal: any;
}) => {
  const { t } = useTranslation();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const messageKeyExportPrefix = "export_item_";
  const [processing, setProcessing] = useState<boolean>(false);

  const handleDownload = async () => {
    setProcessing(true);

    const exportErrors = await new ReportCheckinRepository().downloadExcel(
      fileName
    );

    setProcessing(false);

    if (exportErrors === true) {
      message.success({
        content: t("reportcheckin:export.success"),
        className: "message_success",
        key: messageKeyExportPrefix + 1,
        duration: 1,
      });
    } else {
      message.error({
        content: t("reportcheckin:export.error"),
        className: "message_error",
        key: messageKeyExportPrefix + 1,
        duration: 10,
      });
    }
  };

  const ConfirmModal = () => {
    return (
      <Modal
        title="Xác nhận"
        width={"440px"}
        closable={false}
        // centered
        open={confirmModal}
        onCancel={() => {
          setConfirmModal(false);
        }}
        onOk={() => {
          setConfirmModal(false);
          handleCloseModal && handleCloseModal();
        }}
        okText="OK"
        cancelText="Cancel"
        destroyOnClose
      >
        <p>Bạn có chắc muốn kết thúc quá trình xuất báo cáo ?</p>
      </Modal>
    );
  };

  return (
    <>
      <Modal
        width={"440px"}
        open={visible}
        title={"Xuất báo cáo"}
        closable={true}
        maskClosable={false}
        onCancel={() => {
          setConfirmModal(true);
        }}
        onOk={() => {
          setVisible(false);
        }}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <div className="flex justify-center">
          <Spin spinning={loading}>
            {loading ? (
              <>
                <p> Vui lòng chờ giây lát...</p>
              </>
            ) : (
              <>
                <Button
                  loading={processing}
                  type="primary"
                  icon={<DownloadOutlined />}
                  size="large"
                  onClick={handleDownload}
                >
                  Tải báo cáo
                </Button>
              </>
            )}
          </Spin>
        </div>
      </Modal>
      <ConfirmModal />
    </>
  );
};

export default DownloadModal;
