import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import React from "react";

import { IconReportMoney, IconShoppingCart } from "@tabler/icons-react";

import ReportChartNumber from "../chart/ReportChartNumber";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportCustomerGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng giá trị đơn hàng"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="sum_pricefinal"
              dataTable="order"
              dataService="order"
              dataSelect="sum(pricefinal)"
              dataFilter=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Số khách hàng có mua hàng"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="countdistinct_customer"
              dataTable="order"
              dataService="order"
              dataSelect="countdistinct(customer)"
              dataFilter="customer > 0"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Số khách hàng mới"}
              icon={<IconReportMoney size="18" />}
              keyMapping="count_id"
              dataTable="customer"
              dataService="customer"
              dataSelect="count(id)"
              dataFilter=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Điểm tích lũy mới"}
              icon={<IconReportMoney size="18" />}
              keyMapping="sum_value"
              dataTable="customerpoint"
              dataService="customerpoint"
              dataSelect="sum(value)"
              dataFilter=""
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Số khách hàng mới"}
              dataTable="customer"
              dataSelect="count(id)"
              dataFilter=""
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "count_id" }}
              height={250}
              dataService="customer"
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCustomerGeneral;
