import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";
import { IconAward } from "@tabler/icons-react";

const ShopEventHeader = ({ title }: { title?: string }) => {
	const { t } = useTranslation();
	return (
		<PageHeader
			heading={title ?? t("shopsetting:shopevent.heading_list")}
			siteMenuOpenKey="shop"
			siteMenuSelectedKey="/shopevent">
		</PageHeader>
	);
};

export default ShopEventHeader;
