import CustomerHelperApi from 'common/api/CustomerHelperApi';
import PosCartModel from 'common/models/PosCartModel';
import { PosCartCustomerPoint } from 'common/types/PosCart';

import BaseRepository from './BaseRepository';

class CustomerHelperRepository extends BaseRepository<CustomerHelperApi> {
  _api: CustomerHelperApi | null;

  constructor() {
    super();
    this._api = new CustomerHelperApi(true);
  }

  async getPointForPos(id: number): Promise<PosCartCustomerPoint> {
    return this._api
      ? await this._api.getPointForPos(id)
      : PosCartModel.getDefaultCartCustomerPoint();
  }
}

export default CustomerHelperRepository;
