import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import AcademyList from "../AcademyList/index";
import CollectionModel from "common/models/CollectionModel";
import CollectionRepository from "common/repositories/CollectionRepository";
import { useNavigate, useParams } from "react-router-dom";
import AcademySlide from "../AcademySlide";
import StoryRepository from "common/repositories/StoryRepository";
import AcademyCard from "../AcademyCard";
import StoryModel from "common/models/StoryModel";
import AcademySkeleton from "./../AcademySkeleton/AcademySkeleton";
const AcademyListCollectionDetail = () => {
  const { catid } = useParams();
  const categoryHandle = catid!;
  const navigate = useNavigate();
  const [modelCollection, setModelCollection] = useState(
    CollectionModel.getDefaultData()
  );

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getDetailCollection = useCallback(async () => {
    setLoading(true);
    if (catid) {
      const response = await new CollectionRepository().getItem(catid);
      if (!response.hasError()) {
        setModelCollection(response.toJson());
      } else {
        setErrors(response.error.errors);
        navigate({ pathname: "/academy/notfound" });
      }
    }
    setLoading(false);
  }, [catid]);

  useEffect(() => {
    getDetailCollection();
  }, [catid]);

  return (
    <div className={styles.wrapper}>
      <AcademySkeleton
        loading={loading}
        isImage={true}
        children={
          <AcademyList
            idCategory={modelCollection.id}
            heightImage={230}
            prefixLink={categoryHandle}
            limit={12}
          ></AcademyList>
        }
      ></AcademySkeleton>
    </div>
  );
};

export default AcademyListCollectionDetail;
