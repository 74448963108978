import BaseCollection from 'common/collections/BaseCollection';
import ProductReceiptDetailModel from 'common/models/ProductReceiptDetailModel';
import {
    ProductReceiptDetailCollectionJson, ProductReceiptDetailJson
} from 'common/types/ProductReceiptDetail';

class ProductReceiptDetailCollection extends BaseCollection<
  ProductReceiptDetailJson,
  ProductReceiptDetailModel,
  ProductReceiptDetailCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductReceiptDetailJson[]
  ): ProductReceiptDetailModel[] {
    return jsonItems.map((item) => new ProductReceiptDetailModel(item));
  }
}

export default ProductReceiptDetailCollection;
