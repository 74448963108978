import { useForm } from "antd/lib/form/Form";
import {
  Col,
  Form,
  Input,
  Row,
  Collapse,
  DatePicker,
  Cascader,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import LayoutForm from "components/form/LayoutForm";
import BirthdayInput from "components/BirthdayInput";
import FormSection from "components/form/FormSection";
import CollectionFormHeader from "./CollectionFormHeader";
import LabelDateFormatHelper from "components/LabelDateFormatHelper";

import {
  CollectionCascadeOptions,
  CollectionFormData,
  CollectionFormDataPost,
  CollectionJsonAddEdit,
} from "common/types/Collection";
import FileModel from "common/models/FileModel";
import Collection from "common/constants/Collection";
import CollectionModel from "common/models/CollectionModel";
import CollectionRepository from "common/repositories/CollectionRepository";
import Helper from "common/utils/helper";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import { IconPhoto } from "@tabler/icons-react";
import File from "common/constants/File";
import FormSelectNormal from "components/form/FormSelectNormal";
import ErrorAlert from "components/ErrorAlert";

type Props = {
  model: CollectionModel;
};
const CollectionForm = ({ model }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;
  const [getValue, setValue] = useFormDefaultStore((state) => [
    state.getValue,
    state.setValue,
  ]);

  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [typeCollection, setTypeCollection] = useState(Collection.TYPE_STORY);
  const [categoryList, setCategoryList] = useState<CollectionCascadeOptions[]>(
    []
  );

  //cascade
  const [openItems, setOpenItems] = useState<CollectionCascadeOptions[]>([]);
  const [parentItems, setParentItems] = useState<number[]>([]);

  //default data
  const initialValues = useMemo(() => {
    return {
      title: model.title,
      handle: model.handle,
      item_type: model.item_type,
      parent_id: parentItems,
      // model.parent_id > 0
      //   ? model.parent_id
      //   : getValue("productcategory:parent_id"),
      display_order: model.display_order,
      date_published:
        model.date_published > 0
          ? dayjs(new Date(model.date_published * 1000))
          : 0,
      publish_from:
        model.publish_from > 0
          ? dayjs(new Date(model.publish_from * 1000))
          : null,
      publish_to:
        model.publish_to > 0 ? dayjs(new Date(model.publish_to * 1000)) : null,
      // description: "",
      detail: model.detail,
      banner_desktop_image: FileModel.convertToUploadFiles([
        new FileModel(FileModel.getDefaultData()),
      ]),
      banner_mobile_image: FileModel.convertToUploadFiles([
        new FileModel(FileModel.getDefaultData()),
      ]),
      image: FileModel.convertToUploadFiles([
        new FileModel(FileModel.getDefaultData()),
      ]),
      date_range: [
        model.publish_from > 0
          ? dayjs(new Date(model.publish_from * 1000))
          : null,
        model.publish_to > 0 ? dayjs(new Date(model.publish_to * 1000)) : null,
      ],
    };
  }, [model, getValue, parentItems]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      formData.date_range.length > 0 &&
        console.log(Helper.convertedDateToTimeStamp(formData.date_range[0]));

      const submitData: CollectionFormDataPost = {
        id: model.id,
        title: formData.title.trim(),
        handle: formData.handle.trim(),
        parent_id:
          formData.parent_id.length > 0
            ? formData.parent_id[formData.parent_id.length - 1]
            : 0,
        date_published: Helper.convertedDateToTimeStamp(
          formData.date_published
        ),
        item_type: formData.item_type,
        publish_from: Helper.convertedDateToTimeStamp(formData.date_range[0]),
        publish_to: Helper.convertedDateToTimeStamp(formData.date_range[1]),
        // description: formData.description.trim(),
        detail: formData.detail.trim(),
        banner_desktop: formData.banner_desktop_image
          ? formData.banner_desktop_image[0].response.id
          : 0,
        banner_mobile: formData.banner_mobile_image
          ? formData.banner_mobile_image[0].response.id
          : 0,
        image: formData.image ? formData.image[0].response.id : 0,
      };

      setValue("productcategory:parent_id", formData.parent_id);

      return submitData;
    },
    [model.id, setValue]
  );

  //functions
  const convertToTree = (data: any[], parent_id = 0) => {
    const tree = [];

    for (const item of data) {
      if (item.parent_id === parent_id) {
        const children: any[] = convertToTree(data, item.id);
        if (children.length) {
          item.children = children;
        }
        tree.push({
          value: item.id,
          label: item.title,
          children: children,
        });
      }
    }

    return tree;
  };

  const findItemAndParents = (
    items: CollectionCascadeOptions[],
    idToFind: number
  ) => {
    let foundParents: number[] = [];

    const findItemRecursively = (
      items: CollectionCascadeOptions[],
      idToFind: number
    ) => {
      for (const item of items) {
        if (item.value === idToFind) {
          foundParents = [item.value, ...foundParents];
          setParentItems((prev) => [item.value, ...prev]);
          setOpenItems((prev) => [item, ...prev]);
          return item;
        }

        if (item.children && item.children.length > 0) {
          if (findItemRecursively(item.children, idToFind)) {
            foundParents = [item.value, ...foundParents];
            setParentItems((prev) => [item.value, ...prev]);
            setOpenItems((prev) => [item, ...prev]);
            return item;
          }
        }
      }
      return null;
    };

    const foundItem = findItemRecursively(items, idToFind);

    return { foundItem, foundParents };
  };

  //submit data to server
  const onSubmit = async (formData: CollectionFormData) => {
    const response: CollectionModel =
      await new CollectionRepository().saveRemote(doPrepareData(formData));
    if (!response.hasError()) {
      setIsSuccess(true);
    } else {
      setErrors(response.error.errors);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  //fetch collection list
  const getCategoryList = async () => {
    let collection = await new CollectionRepository().getItems({
      filters: {
        ...CollectionRepository.getDefaultFilters(),
        item_type: typeCollection,
      },
    });
    // setCategoryList(convertToAntdOptions(collection.items));
    // const treeData = convertToTree(collection.items);
    setCategoryList(convertToTree(collection.items));
    findItemAndParents(convertToTree(collection.items), model.parent_id);
  };

  useEffect(() => {
    if (typeCollection > 0) {
      getCategoryList();
    }
  }, [typeCollection]);

  //sidebar form
  const sidebarItems = <></>;

  return (
    <div>
      <CollectionFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess="/collection"
        error_translate_prefix="collection:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("collection:form.section_collection")}
          subtitle={t("collection:form.section_collection_subtitle")}
          divider
        >
          <Row gutter={8}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("productcategory:name")}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t("collection:rules.title_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("collection:effective_date")}
                name="date_published"
                required
                rules={[
                  // {
                  //     required: true,
                  //     message: t("collection:rules.effective_date_required"),
                  //   },
                  {
                    validator: (rule, value, callback) => {
                      if (value) {
                        return callback();
                      } else {
                        return callback(
                          t("collection:rules.effective_date_required")
                        );
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"dd/mm/yyyy"}
                  format={"DD/MM/YYYY"}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("collection:effective_date_range")}
                name="date_range"
                required
                rules={[
                  {
                    required: true,
                    message: t(
                      "collection:rules.effective_date_range_required"
                    ),
                  },
                ]}
              >
                <DatePicker.RangePicker
                  // defaultPickerValue={[
                  //   dayjs(initialValues.publish_from),
                  //   dayjs(initialValues.publish_to),
                  // ]}
                  defaultValue={[
                    initialValues.publish_from,
                    initialValues.publish_to,
                  ]}
                  style={{ width: "100%" }}
                  placement="bottomRight"
                  direction="ltr"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <FormSelectNormal
                required
                name="item_type"
                label={t("collection:category_type")}
                placeholder={t("collection:category_type_placeholder")}
                onChange={(e) => {
                  setTypeCollection(Number(e));
                  form.setFieldsValue({ parent_id: [] });
                }}
                rules={[
                  {
                    required: true,
                    message: t("collection:rules:category_type_required"),
                  },
                ]}
                options={[
                  { value: Collection.TYPE_STORY, label: "Story" },
                  { value: Collection.TYPE_ACADEMY, label: "Academy" },
                ]}
              />
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("collection:form.section_norequired")}
          subtitle={t("collection:form.section_collection_subtitle_norequired")}
        >
          <Row gutter={8}>
            <Col md={12} xs={24}>
              <Form.Item label={t("collection:handle")} name="handle">
                <Input placeholder={t("collection:handle_placeholder")} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              {/* <CollectionFormSelect
                key={model.id === 0 ? new Date().getTime() : model.id}
                label={t("productcategory:parent_id")}
                placeholder={t("productcategory:form.parent_placeholder")}
                name="parent_id"
                parentId={0}
                ignoreValues={model.id > 0 ? [model.id] : []}
                filters={{
                  ...CollectionRepository.getDefaultFilters(),
                  item_type: typeCollection,
                }}
              /> */}
              <Form.Item
                name="parent_id"
                label={t("collection:parent_category")}
              >
                <Cascader
                  expandTrigger="click"
                  changeOnSelect
                  displayRender={(labels: string[]) =>
                    labels[labels.length - 1]
                  }
                  options={categoryList}
                  style={{ width: "100%" }}
                  placeholder={t("collection:parent_category_placeholder")}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24} span={24}>
              <Collapse className="mt-4 mb-2 bg-white">
                <Collapse.Panel header={t("collection:image_group")} key={"1"}>
                  <Row>
                    <Col md={8} xs={24}>
                      <Form.Item className="m-0 text-center">
                        <FileUploaderButton
                          form={form}
                          formName="image"
                          label={t("collection:image")}
                          icon={<IconPhoto size="24" color="grey" />}
                          origin="avatar"
                          uploadRenderType="thumbnail"
                          objectType={File.OBJECTTYPE_FILE}
                          isSingleFile={true}
                          initFiles={initialValues.image}
                          maxCount={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item className="m-0 text-center">
                        <FileUploaderButton
                          form={form}
                          formName="banner_desktop_image"
                          label={t("collection:banner_desktop")}
                          icon={<IconPhoto size="24" color="grey" />}
                          origin="avatar"
                          uploadRenderType="thumbnail"
                          objectType={File.OBJECTTYPE_FILE}
                          isSingleFile={true}
                          initFiles={initialValues.banner_desktop_image}
                          maxCount={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item className="m-0 text-center">
                        <FileUploaderButton
                          form={form}
                          formName="banner_mobile_image"
                          label={t("collection:banner_mobile")}
                          icon={<IconPhoto size="24" color="grey" />}
                          origin="avatar"
                          uploadRenderType="thumbnail"
                          objectType={File.OBJECTTYPE_FILE}
                          isSingleFile={true}
                          initFiles={initialValues.banner_mobile_image}
                          maxCount={1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("collection:describe")}
                // name="description"
                name="detail"
                rules={[
                  {
                    required: true,
                    message: t("collection:rules.detail_required"),
                  },
                ]}
              >
                <Input.TextArea
                  allowClear
                  rows={3}
                  placeholder={t("collection:describe_placeholder")}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default CollectionForm;
