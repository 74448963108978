import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TableColumnsType, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import useStateFilter from "hooks/useStateFilter";
import Role from "common/constants/Role";

import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableDelete from "components/table/TableDelete";
import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
//Phần thay thế
import { FilterInventory } from "common/types/Inventory";
import { InventoryJson } from "common/types/Inventory";

import InventoryModel from "common/models/InventoryModel";
import InventoryCollection from "common/collections/InventoryCollection";
import InventoryRepository from "common/repositories/InventoryRepository";
import PageDataPagination from "components/page/PageDataPagination";
import InventoryListHeader from "./InventoryListHeader";
import InventoryProductFilters from "./InventoryProductFilters";
import ProductRepository from "common/repositories/ProductRepository";
import useWarehouseStore from "hooks/useWarehouseStore";
import { ProductJson2 } from "common/types/Product";
import InventoryConstant from "common/constants/Inventory";

const InventoryProduct = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	////////////////////////
	//hook
	const { warehouse } = useWarehouseStore();

	////////////////////////
	// modal editing
	const [addVisible, setAddVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);

	////////////////////////
	// default filter
	const defaultFilters = useMemo(() => {
		return InventoryRepository.getDefaultFilter();
	}, []);

	///////////////////////
	//state

	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<InventoryModel[]>([]);
	const [filters, setFilters] = useStateFilter(defaultFilters);
	useFilterLocation(defaultFilters, filters, true);

	const [products, setProducts] = useState<ProductJson2[]>([]);

	//Table columns
	const columns: TableColumnsType<InventoryModel> = [
		{
			title: t("common:columns.id"),
			key: "id",
			width: 60,
		},
		{
			title: t("Tên sản phẩm"),
			key: "item_id",
			width: 100,
			render: (item_id) => {
				const productData = products.find((p) => p.id === item_id);
				return (
					<Typography.Text
						ellipsis={{ tooltip: <>{productData?.full_name}</> }}>
						{productData?.full_name}
					</Typography.Text>
				);
			},
		},
		{
			title: t("SKU"),
			key: "item_id",
			width: 100,
			render: (item_id) => {
				const productData = products.find((p) => p.id === item_id);
				return <>{productData?.sku}</>;
			},
		},
		{
			title: t("Số lượng"),
			key: "quantity",
			width: 100,
		},

		{
			title: t("Trạng thái"),
			key: "status",
			width: 100,
			render: (status) => {
				const label =
					status === InventoryConstant.STATUS_DISABLE ? "Disable" : "Active";
				return (
					<Tag
						color={
							status === InventoryConstant.STATUS_DISABLE ? "gray" : "green"
						}>
						{label}
					</Tag>
				);
			},
		},

		{
			title: t(""),
			key: "actions",
			width: 100,
			align: "right",
			fixed: "right",
			render: (id: number, record: InventoryModel) => (
				<RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
					{/* <TableEdit link="" onClick={() => onEdit(record.id)} />
					<TableDelete
						error_translate_prefix="department:form.error"
						onDeleteCallback={(id) => {
							fetchData();
						}}
						repository={new InventoryRepository()}
						id={record.id}
					/> */}
					<TableInfo record={record} />
				</RoleCheck>
			),
		},
	];

	///////////////////////
	//function
	const fetchData = useCallback(async () => {
		if (filters.warehouse_id) {
			const response = await new InventoryRepository().getItems({
				filters: { ...filters },
			});
			if (!response.hasError()) {
				await getProducts(
					response.items.map((i) => i.item_id).join(","),
					filters.warehouse_id
				);

				setTotal(response.total);
				setDataSource(response.items);
			}

			return response;
		}

		return new InventoryCollection();
	}, [filters, warehouse]);

	// get data products
	const getProducts = async (ids: string, store_id: number) => {
		const res = await new ProductRepository().getItems({
			filters: {
				...ProductRepository.getDefaultFilters(),
				store_id: store_id,
				list_product_id: ids,
			},
		});
		if (!res.hasError()) {
			setProducts(res.toJson().items);
		}
	};

	const onEdit = (id: number) => {
		setEditingId(id);
		setAddVisible(true);
	};

	return (
		<div>
			<InventoryListHeader></InventoryListHeader>
			<InventoryProductFilters
				warehouses={warehouse}
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterInventory, InventoryModel, InventoryCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,

					tableColumnToggleKey: "template",
				}}></PageDataTable>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</div>
	);
};

export default InventoryProduct;
