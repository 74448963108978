import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const TaxInvoiceListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("taxinvoice:heading_list")}
      siteMenuSelectedKey="/taxinvoice"></PageHeader>
  );
};

export default TaxInvoiceListHeader;
