import EmployeeModel from "common/models/EmployeeModel";
import dbm from "dbm";
import React, { useEffect, useState } from "react";

const TextUser = ({ id }: { id: number }) => {
  const [employee, setEmployee] = useState<EmployeeModel>(
    new EmployeeModel(EmployeeModel.getDefaultData())
  );

  useEffect(() => {
    if (id > 0 && employee.id === 0) {
      (async () => {
        const collection = dbm.getCollection("employee");
        if (typeof collection !== "undefined") {
          const foundItems = await collection
            ?.chain()
            .find({
              user_id: id,
            })
            .limit(1)
            .data();

          if (foundItems && foundItems.length > 0) {
            setEmployee(new EmployeeModel(foundItems[0]));
          }
        }
      })();
    }
  });

  return <span>{employee.full_name}</span>;
};

export default TextUser;
