import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { useRef, useState, useEffect } from "react";
import { CompanyResourceUpdate } from "common/types/CompanyResource";
import eventEmitter from "common/utils/eventEmitter";
import { useTimeoutWhen } from "rooks";

const HeaderCompanyResourceUpdate = () => {
  const { t } = useTranslation();
  const eventEmitterRef = useRef<any>();

  const [newUpdate, setNewUpdate] = useState<
    CompanyResourceUpdate | undefined
  >();

  //hide update after 10s
  useTimeoutWhen(
    () => setNewUpdate(undefined),
    5000,
    typeof newUpdate !== "undefined"
  );

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "COMPANYRESOURCE_UPDATE",
        (data: CompanyResourceUpdate) => {
          setNewUpdate(data);
        }
      );
    }
  });

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);

  return (
    <div className="absolute right-0 top-0 leading-none">
      {typeof newUpdate !== "undefined" ? (
        <div className="bg-green-400 text-white text-xs px-1 rounded-none rounded-bl">
          <Tooltip
            title={t("common:companyresource.update_tooltip")}
            placement="leftTop"
          >
            <small>{t("common:companyresource.update_label", newUpdate)}</small>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default HeaderCompanyResourceUpdate;
