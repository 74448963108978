import BaseCollection from "./BaseCollection";

import TagDetailModel from "common/models/TagDetailModel";
import { TagDetailCollectionJson, TagDetailJson } from "common/types/TagDetail";

class TagDetailCollection extends BaseCollection<
  TagDetailJson,
  TagDetailModel,
  TagDetailCollectionJson
> {
  itemsFromJson(jsonItems: TagDetailJson[]): TagDetailModel[] {
    return jsonItems.map((item) => new TagDetailModel(item));
  }
}
export default TagDetailCollection;
