const EcomLog = {
  STATUS_DUPLICATE: 1,
  STATUS_IGNORE: 3,
  STATUS_QUEUEING: 5,
  STATUS_PROCESSING: 7,
  STATUS_SUCCESS: 9,
  STATUS_ERROR: 11,

  TYPE_WEBHOOK: 1,
};

export default EcomLog;
