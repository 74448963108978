import { Modal } from "antd";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";
import { CheckLog } from "common/types/ProductInventoryCheckingDetail";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconArrowRight } from "@tabler/icons-react";

const ProductInventoryCheckingDetailLogModal = ({
  open,
  setOpen,
  productIventoryCheckingDetailModel,
}: {
  open: boolean;
  setOpen: (v: boolean) => any;
  productIventoryCheckingDetailModel: ProductInventoryCheckingDetailModel;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        t("productinventorychecking:changelog") +
        " [" +
        productIventoryCheckingDetailModel.product_sku +
        "]"
      }
      width={600}
      open={open}
      closable={true}
      onCancel={() => {
        setOpen(false);
      }}
      footer={null}
      maskClosable={true}
    >
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 my-5">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              {t("common:date_modified")}
            </th>
            <th scope="col" className="py-3 px-6">
              {t("common:creator_id")}
            </th>
            <th scope="col" className="py-3 px-6 text-center">
              {t("productinventorychecking:quantity_from")}
            </th>
            <th></th>
            <th scope="col" className="py-3 px-6 text-center">
              {t("productinventorychecking:quantity_to")}
            </th>
          </tr>
        </thead>
        <tbody>
          {productIventoryCheckingDetailModel.changelog.map(
            (item: CheckLog) => {
              return (
                <tr
                  key={item.date_modified}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="py-4 px-6">
                    <TextDateTime
                      format="HH:mm, DD/MM/YYYY"
                      ts={item.date_modified}
                    />
                  </td>
                  <td className="py-4 px-6">
                    <TextUser id={item.user_id} />
                  </td>

                  <td className="py-4 px-6 text-center">
                    {item.quantity_from}
                  </td>
                  <td>
                    <IconArrowRight color="black" fontSize={20} />
                  </td>
                  <td className="py-4 px-6 text-center">{item.quantity_to}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </Modal>
  );
};

export default ProductInventoryCheckingDetailLogModal;
