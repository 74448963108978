import ShippingCarrierApi from 'common/api/ShippingCarrierApi';
import ShippingCarrierCollection from 'common/collections/ShippingCarrierCollection';
import ShippingCarrierModel from 'common/models/ShippingCarrierModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ShippingCarrierJsonAddEdit,
  FilterShippingCarrier,
  ShippingCarrierJsonEditPrice,
} from "common/types/ShippingCarrier";

class ShippingCarrierRepository extends BaseRepository<ShippingCarrierApi> {
  _api: ShippingCarrierApi | null;

  constructor() {
    super();
    this._api = new ShippingCarrierApi(true);
  }

  static getDefaultFilters(): FilterShippingCarrier {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterShippingCarrier>) {
    return this._api
      ? await this._api.getList(props)
      : new ShippingCarrierCollection();
  }

  async getItem(id: number): Promise<ShippingCarrierModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShippingCarrierModel(ShippingCarrierModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ShippingCarrierJsonAddEdit
  ): Promise<ShippingCarrierModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShippingCarrierModel(ShippingCarrierModel.getDefaultData());
    }
  }

  async saveRemotePrice(
    id: number,
    data: ShippingCarrierJsonEditPrice
  ): Promise<ShippingCarrierModel> {
    if (this._api) {
      return await this._api.editPrice(id, data);
    } else {
      return new ShippingCarrierModel(ShippingCarrierModel.getDefaultData());
    }
  }
}

export default ShippingCarrierRepository;
