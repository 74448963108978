import { ProductVariantSizeJson } from 'common/types/ProductVariantSize';
import { SelectOption } from 'common/types/SelectOption';
import FormSelect from 'components/form/FormSelect';
import useDatabaseTable from 'hooks/useDatabaseTable';

const ProductVariantSizeFormSelect = ({
  label,
  name,
  noStyle,
}: {
  label: string;
  name: string;
  noStyle?: boolean;
}) => {
  const [localItems] =
    useDatabaseTable<ProductVariantSizeJson>("productvariantsize");

  const displayOptions: SelectOption[] = localItems
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
    .map((item) => ({
      value: item.id,
      label: item.name,
    }));

  return (
    <FormSelect
      allowClear
      showSearch
      label={label}
      name={name}
      options={displayOptions}
      noStyle={noStyle}
    />
  );
};

export default ProductVariantSizeFormSelect;
