import TagConstant from "common/constants/Tag";
import PageHeader from "components/page/PageHeader";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TagProductListHeader = ({
  type,
  children,
}: {
  type: number;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  const renderTitle = (typeHeader: number) => {
    switch (typeHeader) {
      case 1:
        return "sản phẩm";

      case 2:
        return "bài viết";

      case 3:
        return "danh mục sản phẩm";

      case 4:
        return "danh mục bài viết";
      case 5:
        return "thương hiệu";
    }
  };

  const renderKey = (typeHeader: number) => {
    switch (typeHeader) {
      case 1:
        return "/tagproduct";

      case 2:
        return "/tagstory";

      case 3:
        return "/tagproductcollection";

      case 4:
        return "/tagstorycollection";
      case 5:
        return "/tagbrand";
    }
  };

  return (
    <PageHeader
      heading={t("tag:heading_list") + " " + renderTitle(type)}
      siteMenuSelectedKey={renderKey(type)}
      siteMenuOpenKey="tag"
    >
      {children}
    </PageHeader>
  );
};

export default TagProductListHeader;
