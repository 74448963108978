import React, { useEffect, useState } from "react";
import ShopEventHeader from "./ShopEventHeader";
import { DataZone } from "common/types/Event";
import SettingRepository from "common/repositories/SettingRepository";
import SettingApi from "common/constants/Setting";
import { Empty, Form, message, Space } from "antd";
import ShopEventForm from "../form/ShopEventForm";
import { SettingJson } from "common/types/Setting";
import SettingModel from "common/models/SettingModel";

const ShopEventList = () => {
	const [data, setData] = useState<SettingJson>(SettingModel.getDefaultData());
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const fetchData = async () => {
		setLoading(true);
		const res = await new SettingRepository().getItem(
			SettingApi.KEY.K_custom_landing_page,
			""
		);
		if (!res.hasError()) {
			setData(res.toJson());
		} else {
			setErrors(res.error.errors);
		}
		setLoading(false);
	};
	useEffect(() => {
		fetchData();
	}, []);

	if (errors.length > 0) {
		return (
			<>
				<ShopEventHeader></ShopEventHeader>
				<Space
					className="w-full justify-center items-center min-h-[70vh]"
					align="center">
					<Empty></Empty>
				</Space>
			</>
		);
	}
	return (
		<>
			<ShopEventHeader></ShopEventHeader>
			<Space direction="vertical" size="middle" className="px-4 w-full">
				{!loading && (
					<ShopEventForm
						data={data}
						onSucess={() => {
							fetchData();
						}}></ShopEventForm>
				)}
			</Space>
		</>
	);
};

export default ShopEventList;
