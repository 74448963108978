import { Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconAlertTriangle } from "@tabler/icons-react";

const TextEmptyCoord = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="py-1">
        <Tag icon={<IconAlertTriangle />} color={"red"}>
          <span title={t("shippingorder:empty_coord_title")}>
            {t("shippingorder:empty_coord")}
          </span>
        </Tag>
      </div>
    </>
  );
};

export default TextEmptyCoord;
