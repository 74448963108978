import BaseCollection from 'common/collections/BaseCollection';
import CmsPostModel from 'common/models/CmsPostModel';
import { CmsPostCollectionJson, CmsPostJson } from 'common/types/CmsPost';

class CmsPostCollection extends BaseCollection<
  CmsPostJson,
  CmsPostModel,
  CmsPostCollectionJson
> {
  itemsFromJson(jsonItems: CmsPostJson[]): CmsPostModel[] {
    return jsonItems.map((item) => new CmsPostModel(item));
  }
}

export default CmsPostCollection;
