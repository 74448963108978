import { Skeleton } from "antd";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import FormModal from "components/form/FormModal";
import ShippingOrderForm from "features/shippingorder/form/ShippingOrderForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ShippingOrderFormModal = ({
  open,
  setOpen,
  editingOrder,
  onSaveSuccess,
}: {
  open: boolean;
  setOpen: (v: boolean) => any;
  editingOrder: ShippingOrderModel;
  onSaveSuccess: (m: ShippingOrderModel) => void;
}) => {
  const { t } = useTranslation();
  const isEditting = editingOrder.id > 0;
  const [processing, setProcessing] = useState(false);
  const [model, setModel] = useState<ShippingOrderModel>(
    new ShippingOrderModel(ShippingOrderModel.getDefaultData())
  );
  useEffect(() => {
    setModel(editingOrder);
  }, [editingOrder]);

  return (
    <FormModal
      title={
        <div>
          <span className="font-bold">
            {isEditting
              ? t("shippingorder:heading_edit")
              : t("shippingorder:heading_add")}{" "}
          </span>
          {isEditting ? (
            <span className="font-bold text-blue-800">
              #{editingOrder.random_code}
            </span>
          ) : null}
        </div>
      }
      width={1200}
      confirmLoading={processing}
      open={open}
      setOpen={setOpen}
      formId={"shipping-order-" + model.id}
    >
      {editingOrder.id > 0 ? (
        <>
          {model.id > 0 ? (
            <ShippingOrderForm
              setProcessing={setProcessing}
              model={model}
              onSaveSuccess={onSaveSuccess}
              setOpen={setOpen}
            />
          ) : (
            <Skeleton active />
          )}
        </>
      ) : (
        <ShippingOrderForm
          setProcessing={setProcessing}
          model={new ShippingOrderModel(ShippingOrderModel.getDefaultData())}
          onSaveSuccess={onSaveSuccess}
          setOpen={setOpen}
        />
      )}
    </FormModal>
  );
};

export default ShippingOrderFormModal;
