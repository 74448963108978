class Error {
	statusCode: number;
	errors: string[];
	error_detail?: any[];

	constructor({
		statusCode,
		errors,
		error_detail,
	}: {
		statusCode: number;
		errors: string[];
		error_detail?: any[];
	}) {
		this.statusCode = statusCode;
		this.errors = errors;
		this.error_detail = error_detail;
	}
}

export default Error;
