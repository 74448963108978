import SettingPromotions from "features/settingpromotions/list/SettingPromotionsList";
import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";



const SettingPromotionsPage = () => {
  const [pathParams] = usePathParams();

  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    default:
      com = <SettingPromotions />;
      break;
  }
  return com;
};

export default SettingPromotionsPage;
