import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import { TableColumnsType, Tag, message } from "antd";
import useFilterLocation from "hooks/useFilterLocation";
import React, { useCallback, useMemo, useState } from "react";

import RoleCheck from "components/RoleCheck";
import TableInfo from "components/table/TableInfo";
import TextDateTime from "components/TextDateTime";
import TableEdit from "components/table/TableEdit";
import ITRequestListHeader from "./ITRequestListHeader";
import ITRequestListFilter from "./ITRequestListFilter";
import PageDataTable from "components/page/PageDataTable";

import { ITRequestJson } from "common/types/ITRequest";
import { FilterITRequest } from "common/types/ITRequest";
import ITRequestModel from "common/models/ITRequestModel";
import PageDataPagination from "components/page/PageDataPagination";
import ITRequestCollection from "common/collections/ITRequestCollection";
import ITRequestRepository from "common/repositories/ITRequestRepository";

const ITRequestList = () => {
  const { t } = useTranslation();

  // default filter
  const defaultFilters = useMemo(() => {
    return ITRequestRepository.getDefaultFilter();
  }, []);

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ITRequestModel[]>([]);

  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //Table columns
  const columns: TableColumnsType<ITRequestModel> = [
    {
      title: t("common:columns.id"),
      key: "id",
      dataIndex: "id",
      width: 60,
      render: (id) => {
        return <Tag color="blue">{id}</Tag>;
      },
    },
    {
      title: t("itrequest:name"),
      key: "name",
      dataIndex: "name",
      width: 200,
      render: (name) => {
        return <p className="line-clamp-2 m-0">{name}</p>;
      },
    },
    {
      title: t("itrequest:priority"),
      key: "priority",
      dataIndex: "priority",
      width: 100,
      render(value, record, index) {
        return <p className="my-2">{ITRequestModel.getPriority(value)}</p>;
      },
    },
    {
      title: t("itrequest:deadline"),
      key: "deadline",
      dataIndex: "deadline",
      width: 120,
      render: (deadline, record: ITRequestJson) => (
        <div>
          <p className="my-2">
            {deadline > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={deadline} />
            ) : (
              "----"
            )}
          </p>
        </div>
      ),
    },
    {
      title: t("itrequest:rel_totime"),
      key: "rel_totime",
      dataIndex: "rel_totime",
      width: 120,
      render: (rel_totime, record: ITRequestJson) => (
        <div>
          <p className="my-2">
            {rel_totime > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={rel_totime} />
            ) : (
              t("itrequest:rel_totime")
            )}
          </p>
        </div>
      ),
    },
    {
      title: t("itrequest:approve_by"),
      key: "approve_by",
      dataIndex: "approve_by",
      width: 100,
      render: (approve_by) => {
        return (
          <div>
            <p className="my-2">{ITRequestModel.getApproveBy(approve_by)}</p>
          </div>
        );
      },
    },
    {
      title: t("itrequest:status"),
      key: "status",
      dataIndex: "status",
      width: 100,
      render: (status) => {
        const color =
          status === 1
            ? "blue"
            : status === 2
            ? "magenta"
            : status === 3
            ? "green"
            : status === 4
            ? "volcano"
            : "cyan";
        return <Tag color={color}>{ITRequestModel.getStatus(status)}</Tag>;
      },
    },
    {
      title: "",
      key: "id",
      align: "right",
      fixed: "right",
      width: 100,
      render: (id: number, record: ITRequestModel) => {
        return (
          <RoleCheck roles={[]} hideOnFail>
            <TableEdit link={`/itrequest/edit/id/${record.id}`} />
            <TableInfo record={record} />
          </RoleCheck>
        );
      },
    },
  ];

  //function
  const fetchData = useCallback(async (): Promise<ITRequestCollection> => {
    const response = await new ITRequestRepository().getItemsMine({
      filters: filters,
    });

    if (response) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return response;
  }, [filters]);

  return (
    <div>
      <ITRequestListHeader />
      <ITRequestListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataTable<FilterITRequest, ITRequestModel, ITRequestCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "itrequest",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </div>
  );
};

export default ITRequestList;
