import { App, Button, Col, Row } from "antd";
import ProductReceiptSyncStockModel from "common/models/ProductReceiptSyncStockModel";
import ProductReceiptSyncStockRepository from "common/repositories/ProductReceiptSyncStockRepository";
import { WarehouseJson } from "common/types/Warehouse";
import FormSelect from "components/form/FormSelect";
import TextProductVariant from "components/TextProductVariant";
import ProductVariantSearch from "features/product/ProductVariantSearch";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const InventoryWaitingAdd = ({
  onAddSuccess
}: {
  onAddSuccess: (v: ProductReceiptSyncStockModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [warehouseItems] = useDatabaseTable<WarehouseJson>("warehouse");
  const [processing, setProcessing] = useState(false);
  const [selectedVariantId, setSelectedVariantId] = useState(0);
  const [warehouseId, setWarehouseId] = useState(0);

  const onSelect = useCallback((product_variant_id: number) => {
    setSelectedVariantId(product_variant_id);
  }, []);

  const doSubmit = useCallback(async () => {
    if (selectedVariantId > 0 && warehouseId > 0) {
      setProcessing(true);
      const newItem = await new ProductReceiptSyncStockRepository().saveRemote({
        product_variant_id: selectedVariantId,
        warehouse_id: warehouseId,
        date_created: 0
      });
      setProcessing(false);
      if (newItem.hasError()) {
        message.error(newItem.error.errors);
      } else {
        message.success(t("inventory:waiting_add_success"));
        onAddSuccess(newItem);
      }
    } else {
      message.error({
        content: (
          <>
            {t("inventory:waiting_form.product_variant_or_warehouse_require")}
          </>
        ),
        className: "message_error",
        duration: 3
      });
    }
  }, [selectedVariantId, warehouseId, t, onAddSuccess, message]);

  return (
    <>
      <div className="mb-2">{t("inventory:waiting_add_heading")}</div>
      {/* <ProductVariantSearch
        onSelect={onSelect}
        disableWidget={true}
        disabled={processing}
        loading={processing}
      /> */}
      {selectedVariantId > 0 ? (
        <div className="p-2 mb-2 text-xs bg-gray-200">
          <div className="mb-1">
            {t("inventory:waiting_form.selected_label")}
          </div>
          <TextProductVariant id={selectedVariantId} />{" "}
        </div>
      ) : null}

      <Row className="mt-4" gutter={16}>
        <Col span={16}>
          <FormSelect
            showSearch
            placeholder={t("inventory:waiting_form.select_warehouse")}
            value={warehouseId > 0 ? warehouseId : undefined}
            onChange={(v) => (typeof v === "number" ? setWarehouseId(v) : null)}
            options={warehouseItems.map((i) => ({
              value: i.id,
              label: i.name
            }))}
          />
        </Col>
        <Col span={8}>
          <Button type="primary" onClick={() => doSubmit()} block>
            {t("inventory:waiting_add")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default InventoryWaitingAdd;
