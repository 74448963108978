class FormChangedProvider {
  id: string;
  changed: boolean;
  setChanged: (v: boolean) => void;

  constructor(id: string, changed: boolean, setChanged: (v: boolean) => void) {
    this.id = id;
    this.changed = changed;
    this.setChanged = setChanged;
  }
}

export default FormChangedProvider;
