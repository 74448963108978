import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";
import BrandApi from "common/api/BrandApi";
import { BrandAddEditJson, FilterBrand } from "common/types/Brand";
import BrandCollection from "common/collections/BrandCollection";
import BrandModel from "common/models/BrandModel";

class BrandRepository extends BaseRepository<BrandApi> {
  _api: BrandApi | null;
  constructor() {
    super();
    this._api = new BrandApi(true);
  }

  static getDefaultFilters(): FilterBrand {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: -1,
    };
  }

  async getItems(
    props: GetListProps<FilterBrand>
  ): Promise<BrandCollection> {
    return this._api ? await this._api.getList(props) : new BrandCollection();
  }

  async getItem(id: number): Promise<BrandModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new BrandModel(BrandModel.getDefaultData());
  }

  //add - edit
  async saveRemote(data: BrandAddEditJson): Promise<BrandModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new BrandModel(BrandModel.getDefaultData());
    }
  }

  //delete
  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default BrandRepository;
