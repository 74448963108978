import { IconPhoto } from "@tabler/icons-react";
import { Button, Col, Divider, Form, Image, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import {
	AvailableType,
	DataZone,
	ZoneDataBrandList,
	ZoneDataProduct,
	ZoneDataSupport,
	ZoneImage,
} from "common/types/Event";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FormSection from "components/form/FormSection";
import LayoutForm from "components/form/LayoutForm";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionBanner from "./components/SectionBanner";
import { ZoneDataBanner } from "common/types/Event";
import SectionSubbanner from "./components/SectionSubbanner";
import { ZoneDataSubBanner } from "common/types/Event";
import SectionInfor from "./components/SectionInfor";
import { ZoneDataInfo } from "common/types/Event";
import SectionProduct from "./components/SectionProduct";
import SectionBrandList from "./components/SectionBrandList";
import SectionSupport from "./components/SectionSupport";
import SettingRepository from "common/repositories/SettingRepository";
import { SettingAddEditJson, SettingJson } from "common/types/Setting";

export type SectionZone = {
	data: any;
};

type Props = {
	data: SettingJson;
	onSucess?: () => void;
};
type Item = {
	[key: string]: any; // Định nghĩa kiểu cho các thuộc tính của phần tử
};
type BrandList = {
	[key in `brand_list_${number}`]: ZoneImage[];
};
type ProductList = {
	[key in `zone_product_${number}`]: ZoneDataProduct[];
};
type DataForm = BrandList &
	ProductList & {
		banner_image_desktop: string;
		banner_image_mobile: string;
		banner_link: string;
		brand_grid: ZoneImage[];
		brand_slide: ZoneImage[];
		info_des: string[];
		info_title: string;
		sub_banner: ZoneImage[];
		zone_support: {
			image_desktop: string;
			image_mobile: string;
		};
	};

type FormEvent = {
	banner_link: string;
	brand_grid: ZoneImage[];
};
export default function ShopEventForm({ data: dataSetting, onSucess }: Props) {
	const data = dataSetting.value as DataZone[];
	const [form] = useForm<FormEvent>();
	console.log(data)
	const [t] = useTranslation();

	const [isSuccess, setIsSucess] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const initialValues: FormEvent = {
		banner_link: "123",
		brand_grid: [],
	};
	const newDataDeve = [
		{
			zone_name: "banner",
			zone_show: 1,
			zone_data: {
				image_desktop:
					"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37537-142231715838524-1715838524.jpg",
				image_mobile:
					"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31137-111131715738626-1715738626.jpg",
				url: "#",
			},
		},
		{
			zone_name: "sub_banner",
			zone_show: 1,
			zone_data: [
				{
					from_time: 1715639277,
					to_time: 1715820702,
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37538-776051715838536-1715838536.jpg",
					url: "/products/mini-gift-set-burberry-london-4sp-5ml-x-4",
				},
				{
					from_time: 1715639277,
					to_time: 1715820702,
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37541-355011715838658-1715838658.jpg",
					url: "/products/gift-set-calvin-klein-euphoria-eau-de-parfum-3pcs-edp-100ml-edp-10ml-body-lotion-200ml",
				},
				{
					from_time: 1715639277,
					to_time: 1715820702,
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37542-984641715838662-1715838662.jpg",
					url: "/products/gift-set-chloe-nomade-3pcs-edp-75ml-edp-5ml-duong-the-100ml",
				},
			],
		},
		{
			zone_name: "info",
			zone_show: 1,
			zone_data: {
				descriptions: [
					"Săn Ngay Deal Khủng Từ Những Thương Hiệu Nước Hoa",
					"Được Yêu Thích Nhất Mùa Hè 2024",
				],
				title: "FLASH SALE MỖI NGÀY",
				grid: [
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_1.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_2.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_3.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_4.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_5.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_6.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_7.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_8.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_9.jpg?v=100817",
					},
				],
				slide: [
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_3.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_4.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_5.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_6.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_7.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
					},
				],
			},
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 1,
					show: 1,
					available_type: [
						{
							from_time: 1715639277,
							to_time: 1715820702,
						},
					],
					style: {
						bg: "#efdcd3",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom-20",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37550-538071715839244-1715839244.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37551-387231715839255-1715839255.jpg",
					link: "",
					show_hide_price: 1,
				},
				{
					show_time: 1,
					show: 1,
					available_type: [
						{
							from_time: 1715648968,
							to_time: 1715759427,
						},
					],
					style: {
						bg: "#efdcd3",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom-20",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37553-601321715839273-1715839273.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37552-151131715839267-1715839267.jpg",
					link: "",
					show_hide_price: 1,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31138-196981715738647-1715738647.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31139-763601715738666-1715738666.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31140-910801715738672-1715738672.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31141-107111715738678-1715738678.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31142-337491715738686-1715738686.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31143-315191715738691-1715738691.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31144-201971715738698-1715738698.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31145-135261715738704-1715738704.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1714965247,
							to_time: 1715329012,
						},
					],
					style: {
						bg: "#efbbb8",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37554-295141715839290-1715839290.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37555-540401715839297-1715839297.jpg",
					link: "https://namperfume.net/blogs/thuong-hieu-nuoc-hoa/2",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31146-909311715738710-1715738710.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31147-304831715738714-1715738714.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31148-392061715738718-1715738718.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31149-642771715738722-1715738722.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31150-643651715738735-1715738735.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31151-362921715738805-1715738805.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31152-192391715738811-1715738811.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31153-306831715738816-1715738816.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1665897369,
							to_time: 1716203269,
						},
					],
					style: {
						bg: "#d5d4be",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37556-525011715839319-1715839319.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37557-247371715839325-1715839325.jpg",
					link: "",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31154-662391715738823-1715738823.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31155-542021715738827-1715738827.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31156-794561715738831-1715738831.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31157-162201715738835-1715738835.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31158-141461715738842-1715738842.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31159-230121715738847-1715738847.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31160-490881715738851-1715738851.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31161-928951715738855-1715738855.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1665371647,
							to_time: 1716192286,
						},
					],
					style: {
						bg: "#b7d9ee",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37559-124071715839348-1715839348.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37560-871901715839356-1715839356.jpg",
					link: "",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31162-119021715738860-1715738860.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31163-516611715738864-1715738864.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31164-116051715738870-1715738870.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31165-466841715738874-1715738874.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31166-212291715738878-1715738878.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31167-345451715738882-1715738882.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31168-103901715738886-1715738886.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31169-370831715738890-1715738890.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1665371647,
							to_time: 1716192286,
						},
					],
					style: {
						bg: "#ded3c5",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37562-800461715839373-1715839373.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37561-942451715839365-1715839365.jpg",
					link: "",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31170-889091715738895-1715738895.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31171-873891715738898-1715738898.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31172-896321715738902-1715738902.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31173-635781715738906-1715738906.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31174-690641715738910-1715738910.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31175-514651715738913-1715738913.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31176-611931715738916-1715738916.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31177-440491715738920-1715738920.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1665371647,
							to_time: 1716192286,
						},
					],
					style: {
						bg: "#cce8db",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37563-347521715839386-1715839386.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37564-767461715839393-1715839393.jpg",
					link: "",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31178-624451715738924-1715738924.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31179-761331715738927-1715738928.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31180-147281715738931-1715738931.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31181-729581715738935-1715738935.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31182-221611715738940-1715738940.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31183-666801715738944-1715738944.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31184-887791715738948-1715738948.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31185-812991715738953-1715738953.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1665371647,
							to_time: 1716192286,
						},
					],
					style: {
						bg: "#d8c397",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37566-710061715839408-1715839408.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37565-822241715839402-1715839402.jpg",
					link: "",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31186-147221715738958-1715738958.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31187-552281715738961-1715738961.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31188-601981715738965-1715738965.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31189-311481715738970-1715738970.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31190-769761715738974-1715738974.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31191-227981715738978-1715738978.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31192-167131715738981-1715738981.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31193-818491715738984-1715738984.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1665371647,
							to_time: 1716192286,
						},
					],
					style: {
						bg: "#f3f0e5",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37567-660841715839416-1715839416.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37568-765401715839422-1715839422.jpg",
					link: "",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31194-326771715738989-1715738989.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31195-311701715738992-1715738992.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31196-607771715738996-1715738996.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31197-458211715739000-1715739000.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31198-568191715739005-1715739005.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31199-590151715739008-1715739008.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31200-424381715739013-1715739013.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31201-977831715739016-1715739016.jpg",
				},
			],
		},
		{
			zone_name: "product",
			zone_show: 1,
			zone_data: [
				{
					show_time: 0,
					show: 1,
					available_type: [
						{
							from_time: 1665371647,
							to_time: 1716192286,
						},
					],
					style: {
						bg: "#c1aa99",
						button: "#da1f26",
					},
					collection_id: 1003916686,
					collection_title: "BRANDBURBERRY",
					collection_handle: "deal-thom",
					image_desktop:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37570-532371715839439-1715839439.jpg",
					image_mobile:
						"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37569-906371715839432-1715839432.jpg",
					link: "",
					show_hide_price: 0,
				},
			],
		},
		{
			zone_name: "brandlist",
			zone_show: 1,
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31202-524501715739020-1715739020.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31203-326931715739024-1715739024.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31204-128861715739027-1715739027.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31205-235571715739030-1715739030.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31206-139261715739034-1715739034.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31207-996361715739038-1715739038.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31208-645261715739042-1715739042.jpg",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/31209-990541715739046-1715739046.jpg",
				},
			],
		},
		{
			zone_name: "support",
			zone_show: 1,
			zone_data: {
				image_desktop:
					"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37584-236961715843997-1715843997.jpg",
				image_mobile:
					"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/May/37583-957581715843898-1715843898.jpg",
			},
		},
	];
	// 	{
	// 		zone_name: "banner",
	// 		zone_show: 1,
	// 		zone_data: {
	// 			image_desktop:
	// 				"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-desk.jpg?v=100817",
	// 			image_mobile:
	// 				"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-mobile.jpg?v=100817",
	// 			url: "#",
	// 		},
	// 	},
	// 	{
	// 		zone_name: "sub_banner",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_1.jpg?v=100817",
	// 				url: "/products/mini-gift-set-burberry-london-4sp-5ml-x-4",
	// 			},
	// 			{
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_2.jpg?v=100817",
	// 				url: "/products/gift-set-calvin-klein-euphoria-eau-de-parfum-3pcs-edp-100ml-edp-10ml-body-lotion-200ml",
	// 			},
	// 			{
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_3.jpg?v=100817",
	// 				url: "/products/gift-set-chloe-nomade-3pcs-edp-75ml-edp-5ml-duong-the-100ml",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "info",
	// 		zone_show: 1,
	// 		zone_data: {
	// 			title: "BRAND OF THE YEAR",
	// 			descriptions: [
	// 				"Săn Ngay Deal Khủng Từ Những Thương Hiệu Nước Hoa",
	// 				"Được Yêu Thích Nhất của namperfume Trong Năm 2023",
	// 			],
	// 			slide: [
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_3.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_4.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_5.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_6.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_7.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
	// 				},
	// 			],
	// 			grid: [
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_1.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_2.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_3.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_4.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_5.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_6.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_7.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_8.jpg?v=100817",
	// 				},
	// 				{
	// 					url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_9.jpg?v=100817",
	// 				},
	// 			],
	// 		},
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,
	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716019486,
	// 					},
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716049486,
	// 					},
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716069486,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#E7BDA5",
	// 					button: "#A05F3F",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,

	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1715077816,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#D87786",
	// 					button: "#F0554B",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716192286,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#CEB389",
	// 					button: "#99753C",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716192286,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#CEB389",
	// 					button: "#99753C",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716192286,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#CEB389",
	// 					button: "#99753C",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716192286,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#CEB389",
	// 					button: "#99753C",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716192286,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#CEB389",
	// 					button: "#99753C",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716192286,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#CEB389",
	// 					button: "#99753C",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "product",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				show_time: 1,

	// 				show: 1,
	// 				available_type: [
	// 					{
	// 						from_time: 1665371647,
	// 						to_time: 1716192286,
	// 					},
	// 				],
	// 				style: {
	// 					bg: "#CEB389",
	// 					button: "#99753C",
	// 				},
	// 				collection_id: 1003916686,
	// 				collection_title: "BRANDBURBERRY",
	// 				collection_handle: "brandburberry",
	// 				image_desktop:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
	// 				image_mobile:
	// 					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "brandlist",
	// 		zone_show: 1,
	// 		zone_data: [
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
	// 			},
	// 			{
	// 				url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
	// 				img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		zone_name: "support",
	// 		zone_show: 1,
	// 		zone_data: {
	// 			image_mobile:
	// 				"//theme.hstatic.net/1000340570/1000885609/14/brandyear_uudai_mob.jpg?v=100824",
	// 			image_desktop:
	// 				"//theme.hstatic.net/1000340570/1000885609/14/brandyear_uudai_desk.jpg?v=100824",
	// 		},
	// 	},
	// ];
	// console.log("🚀 ~ ShopEventForm ~ datass:", JSON.stringify(datass));

	const doPrepareData = useCallback(
		(formData: DataForm) => {
			let indexProduct = 1;
			let indexBrand = 1;

			const submitData: SettingAddEditJson = {
				...dataSetting,
			};
			const newData = data.reduce((prev: DataZone[], curr, index) => {
				if (curr.zone_name === "banner") {
					const obj: ZoneDataBanner = {
						image_desktop: formData.banner_image_desktop,
						image_mobile: formData.banner_image_mobile,
						url: formData.banner_link,
					};
					prev.push({ ...curr, zone_data: obj });
				}

				if (curr.zone_name === "sub_banner") {
					prev.push({ ...curr, zone_data: formData.sub_banner });
				}

				if (curr.zone_name === "info") {
					const obj: ZoneDataInfo = {
						descriptions: formData.info_des,
						title: formData.info_title,
						grid: formData.brand_grid,
						slide: formData.brand_slide,
					};
					prev.push({ ...curr, zone_data: obj });
				}
				if (curr.zone_name === "product") {
					const key = `zone_product_${indexProduct}` as keyof ProductList;
					let obj = findItemWithConditionAndIndex(
						formData,
						key
					) as ZoneDataProduct[];

					if (indexProduct === 1) {
						obj = obj.map((i) => {
							return {
								...i,
								style: {
									bg: "#EFDCD3",
									button: "#DA1F26",
								},
							};
						});
					}
					prev.push({ ...curr, zone_data: obj });
					indexProduct++;
				}
				if (curr.zone_name === "brandlist") {
					const key = `brand_list_${indexBrand}` as keyof ProductList;
					const obj = findItemWithConditionAndIndex(
						formData,
						key
					) as ZoneImage[];
					prev.push({ ...curr, zone_data: obj });
					indexBrand++;
				}

				if (curr.zone_name === "support") {
					const obj = findItemWithConditionAndIndex(
						formData,
						"zone_support"
					) as ZoneDataSupport;
					prev.push({
						...curr,
						zone_data: {
							image_desktop: obj.image_desktop,
							image_mobile: obj.image_mobile,
						},
					});
				}

				return prev;
			}, []);
			submitData.value = JSON.stringify(newData);
			return submitData;
		},
		[data]
	);
	const onSubmit = async (data: DataForm) => {
		try {
			const res = await new SettingRepository().saveRemote(doPrepareData(data));
			if (!res.hasError()) {
				setIsSucess(true);
				onSucess && onSucess();
			} else {
				setErrors(res.error.errors);
			}
		} catch (error) {
			setErrors(["save failed"]);
		}
	};

	function findItemWithConditionAndIndex(
		object: DataForm,
		key: keyof DataForm
	): DataForm[keyof DataForm] | null {
		if (key in object) {
			if (key.includes("zone_product")) {
				let convertDataProduct = object[key] as ZoneDataProduct[];
				convertDataProduct = convertDataProduct.map((d) => {
					const at = d.available_type.map((a) => ({
						from_time: a.from_time,
						to_time: a.to_time,
					})) as AvailableType[];
					return { ...d, available_type: at };
				});
				return convertDataProduct;
			}

			if (key.includes("sub_banner")) {
				let convertDataSubbanner = object[key] as ZoneDataSubBanner[];
				convertDataSubbanner = convertDataSubbanner.map((d) => {
					return {
						from_time: d.from_time,
						to_time: d.to_time,
						img: d.img,
						url: d.url,
					};
				});
				return convertDataSubbanner;
			}
			return object[key];
		}
		return null;
	}
	return (
		<div>
			{" "}
			<LayoutForm
				form={form}
				initialValues={{ initialValues }}
				errors={errors}
				isSuccess={isSuccess}
				successTitle={t("template:form.success.add")}
				error_translate_prefix="template:form.error"
				onSubmit={onSubmit}
				submitText={t("common:form.save")}
				sidebarItems={<div></div>}
				span={20}
				hideSidebar={false}>
				{dataSetting.id > 0 &&
					data.map((zone, index) => {
						if (zone.zone_name === "banner") {
							return (
								<SectionBanner
									key={index}
									data={zone.zone_data as ZoneDataBanner}
									form={form}></SectionBanner>
							);
						}

						if (zone.zone_name === "sub_banner") {
							return (
								<SectionSubbanner
									key={index}
									data={zone.zone_data as ZoneDataSubBanner[]}
									form={form}></SectionSubbanner>
							);
						}

						if (zone.zone_name === "info") {
							return (
								<SectionInfor
									key={index}
									data={zone.zone_data as ZoneDataInfo}
									form={form}></SectionInfor>
							);
						}

						if (zone.zone_name === "product") {
							return (
								<SectionProduct
									key={index}
									index={index}
									data={zone.zone_data as ZoneDataProduct[]}
									form={form}></SectionProduct>
							);
						}

						if (zone.zone_name === "brandlist") {
							return (
								<SectionBrandList
									data={zone.zone_data as ZoneDataBrandList[]}
									form={form}
									index={index}
								/>
							);
						}
						if (zone.zone_name === "support") {
							return (
								<SectionSupport
									data={zone.zone_data as ZoneDataSupport}
									form={form}></SectionSupport>
							);
						}

						return null;
					})}
			</LayoutForm>
		</div>
	);
}
