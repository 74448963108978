import type { GetListProps } from "common/types/Filter";
import DeliveryCollection from "common/collections/DeliveryCollection";
import DeliveryModel from "common/models/DeliveryModel";
import EmployeeModel from "common/models/EmployeeModel";
import {
	DeliveryAddEditJson,
	DeliveryInternalAddEditJson,
	FilterDelivery,
} from "common/types/Delivery";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import Helper from "common/utils/helper";
import DeliveryHistoryCollection from "common/collections/DeliveryHistoryCollection";

const SERVICE_URL = "/deliveries";
class DeliveryApi extends BaseApi {
	async getListDeliveryManager(
		props: GetListProps<FilterDelivery>
	): Promise<DeliveryCollection> {
		let collection = new DeliveryCollection();
		try {
			let filters = props.filters;
			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					// actor_already: 1,
					// employee_id: filters.employee_id > 0 ? filters.employee_id : null,
					action_code: filters.action_code ? filters.action_code : null,
					internal_id: filters.internal_id ? filters.internal_id : null,
					soandcode: filters.soandcode ? filters.soandcode : null,
					date_created: filters.date_created > 0 ? filters.date_created : null,
					date_completed:
						filters.date_completed > 0 ? filters.date_completed : null,
					find_internal: filters.find_internal ? filters.find_internal : null,
				},
			};

			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/admin/manager",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getListDeliveryAccoutants(
		props: GetListProps<FilterDelivery>
	): Promise<DeliveryCollection> {
		let collection = new DeliveryCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					actor_already: 1,
					action_code: filters.action_code ? filters.action_code : null,
					internal_id: filters.internal_id ? filters.internal_id : null,
					soandcode: filters.soandcode ? filters.soandcode : null,
					date_created: filters.date_created > 0 ? filters.date_created : null,
					date_completed:
						filters.date_completed > 0 ? filters.date_completed : null,
					find_internal: filters.find_internal ? filters.find_internal : null,
				},
			};

			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/admin/accountants",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getListDeliveryShipperView(
		props: GetListProps<FilterDelivery>
	): Promise<DeliveryCollection> {
		let collection = new DeliveryCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					...Helper.convertParams(filters),
				},
			};

			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/shipperview",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getListItemsWaiting(
		props: GetListProps<FilterDelivery>
	): Promise<DeliveryCollection> {
		let collection = new DeliveryCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					deliverynotorder: 1,
					action_code: "ACTION_WAITING",
					soandcode: filters.soandcode ? filters.soandcode : null,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getListDeliveryHistory(
		props: GetListProps<FilterDelivery>,
		id: number | string
	): Promise<DeliveryHistoryCollection> {
		let collection = new DeliveryHistoryCollection();

		try {
			const convertProps = Helper.convertParams(props.filters);
			let queryData = {
				params: {
					...convertProps,
				},
			};

			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/" + id + "/action",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: number | string): Promise<DeliveryModel> {
		let item = new DeliveryModel(DeliveryModel.getDefaultData());
		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new DeliveryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async getDeliveryPersonDetail(id: number): Promise<EmployeeModel> {
		let item = new EmployeeModel(EmployeeModel.getDefaultData());
		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/" + id + "/actor"
			);
			if (response.hasOwnProperty("data")) {
				item = response.data;
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;

		// let item = new EmployeeModel(EmployeeModel.getDefaultData());
		// try {
		//   const response = await AxiosClient().get<any>("employees/" + id);
		//   if (response.hasOwnProperty("data")) {
		//     item = response.data;
		//   }
		// } catch (error) {
		//   item.withError(BaseApi.handleError(error));
		// }

		// return item;
	}

	async getDetailOrderNotInDelivery(id: number): Promise<DeliveryModel> {
		let item = new DeliveryModel(DeliveryModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/order/" + id
			);
			if (response.hasOwnProperty("data")) {
				item = new DeliveryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async addActionDelivery(data: DeliveryAddEditJson): Promise<DeliveryModel> {
		let item = new DeliveryModel(DeliveryModel.getDefaultData());

		try {
			let postData = {
				...Helper.convertParams(data),
			};

			//For create item
			const response = await AxiosClient().post<any>(
				SERVICE_URL + "/action",
				postData
			);

			if (response.hasOwnProperty("data")) {
				item = new DeliveryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(data: DeliveryInternalAddEditJson): Promise<DeliveryModel> {
		let item = new DeliveryModel(DeliveryModel.getDefaultData());
		try {
			let postData = {
				contact_fullname: data.contact_fullname,
				contact_phone: data.contact_phone,
				contact_address: data.contact_address,
				cost_total: data.cost_total > 0 ? data.cost_total : null,
				cost_note_transfer: data.cost_note_transfer
					? data.cost_note_transfer
					: null,
				cod: data.cod,
				note: data.note,
				payment_method: data.payment_method ? data.payment_method : null,
				order_detail: data.order_detail.length > 0 ? data.order_detail : null,
				partner_delivery: data.partner_delivery,
				code: data.code,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new DeliveryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
	async add(data: DeliveryInternalAddEditJson): Promise<DeliveryModel> {
		let item = new DeliveryModel(DeliveryModel.getDefaultData());
		try {
			let postData = {
				...data,
			};

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new DeliveryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async deleteItem(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async scanItem(order_id: string): Promise<DeliveryModel> {
		let item = new DeliveryModel(DeliveryModel.getDefaultData());
		try {
			let queryData = {
				params: {
					resource_code: order_id,
				},
			};
			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/resource",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				item = new DeliveryModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	///////////////////////////////////////////////////////////////
	// FULL DATA fetcher
}

export default DeliveryApi;
