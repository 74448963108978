import { Button, message } from "antd";
import NestedCollection from "common/collections/NestedCollection";
import Role from "common/constants/Role";
import DepartmentModel from "common/models/DepartmentModel";
import DepartmentRepository from "common/repositories/DepartmentRepository";
import { DepartmentJsonWithChildren } from "common/types/Department";
import { TableColumnsType } from "common/types/Table";
import PageDataTableWithChildren from "components/page/PageDataTableWithChildren";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import DepartmentFormModal from "features/department/form/DepartmentFormModal";
import DepartmentListHeader from "features/department/list/DepartmentListHeader";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronDownLeft } from "@tabler/icons-react";

const DepartmentList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<DepartmentJsonWithChildren[]>(
    []
  );

  const fetchData = useCallback(async (): Promise<
    NestedCollection<DepartmentJsonWithChildren>
  > => {
    let collection = await new DepartmentRepository().getFullNestedItems();
    if (collection) {
      setDataSource(collection.items);
    } else {
      message.error("common:error.error_fetching_data");
    }
    return collection;
  }, []);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<DepartmentJsonWithChildren> = [
    {
      title: t("department:name"),
      key: "name",
      sortby: "name",
      render(value, record, index) {
        return record.name;
      },
    },
    {
      title: t("common:display_order_long"),
      key: "display_order",
      width: 140,
      render: (_, record) => (
        <span>
          {record.parent_id > 0 ? (
            <IconChevronDownLeft
              size={16}
              color="#dddddd"
              className="-ml-0.5 mr-1.5"
            />
          ) : null}
          <span
            className="ml-0.5"
            style={{ opacity: record.parent_id === 0 ? 1 : 0.7 }}
          >
            {record.display_order}
          </span>
        </span>
      ),
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: DepartmentJsonWithChildren) => (
        <RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="department:form.error"
            onDeleteCallback={(id) => {
              fetchData();
            }}
            repository={new DepartmentRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: DepartmentModel) => {
    //detech this is NEW or UPDATE
    fetchData();
  };

  return (
    <>
      <DepartmentListHeader>
        <RoleCheck roles={[Role.SETTING_HRM]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </DepartmentListHeader>
      <PageDataTableWithChildren<DepartmentJsonWithChildren>
        {...{
          columns,
          dataSource,
          fetchData,
        }}
      ></PageDataTableWithChildren>

      <DepartmentFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default DepartmentList;
