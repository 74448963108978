const ShipperApply = {
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,

  GENDER_UNKNOWN: 0,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2,

  MARRIAGE_SINGLE: 1, //độc thân
  MARRIAGE_MARRY: 3, //kết hôn
  MARRIAGE_OTHER: 5, //li di, khac,..s

  TYPE_MOTOBIKE: 5,
  TYPE_CAR: 7,
};

export default ShipperApply;
