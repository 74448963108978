import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CmsPostFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:group_cms"),
      link: "",
    },
    {
      text: t("sitemenu:cms_post"),
      link: "/cmspost",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(isEditing ? "cmspost:heading_edit" : "cmspost:heading_add")}
      siteMenuOpenKey="sitemenu:group_cms"
      siteMenuSelectedKey="/cmspost"
    ></PageHeader>
  );
};

export default CmsPostFormHeader;
