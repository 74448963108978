import { Avatar, Tooltip } from "antd";
import ColorHash from "color-hash";
import ShipperModel from "common/models/ShipperModel";
import React from "react";

const ShipperAvatar = ({ shipper }: { shipper: ShipperModel | undefined }) => {
  return (
    <>
      {shipper !== undefined && shipper.avatar.id > 0 ? (
        <Tooltip
          title={shipper.full_name}
          placement="left"
          mouseLeaveDelay={0.4}
        >
          <Avatar src={shipper.avatar.url} />
        </Tooltip>
      ) : (
        <Tooltip
          title={shipper?.full_name}
          placement="left"
          mouseLeaveDelay={0.4}
        >
          <Avatar
            style={{
              backgroundColor: new ColorHash({ lightness: 0.75 }).hex(
                shipper?.full_name || ""
              ),
            }}
          >
            {shipper?.full_name.split(" ").slice(-1).toString().charAt(0)}
          </Avatar>
        </Tooltip>
      )}
    </>
  );
};

export default ShipperAvatar;
