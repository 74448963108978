import ProjectTaskCollection from "common/collections/ProjectTaskCollection";
import ProjectTaskModel from "common/models/ProjectTaskModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterProjectTask,
  ProjectTaskJsonAddEdit,
  ProjectTaskJsonUpdateStatus
} from "common/types/ProjectTask";
const SERVICE_URL = "/projecttasks";

class ProjectTaskApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProjectTask>
  ): Promise<ProjectTaskCollection> {
    let collection = new ProjectTaskCollection();

    try {
      let filters = props.filters;
      let assigneeType = null,
        assigneeUser = null;
      if (filters.assignee_tpm) {
        if (
          typeof filters.assignee_tpm === "number" &&
          filters.assignee_tpm > 0
        ) {
          assigneeUser = filters.assignee_tpm;
        } else if (
          typeof filters.assignee_tpm === "string" &&
          filters.assignee_tpm !== ""
        ) {
          assigneeType = filters.assignee_tpm;
        }
      }

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          company_id: +filters.company_id > 0 ? filters.company_id : null,
          project_id: +filters.project_id > 0 ? filters.project_id : null,
          priority: +filters.priority > 0 ? filters.priority : null,
          type: +filters.type > 0 ? filters.type : null,
          tag: filters.tag ? filters.tag : null,
          iteration_id:
            +filters.iteration_id >= 0 ? filters.iteration_id : null,
          id: +filters.id > 0 ? filters.id : null,
          code: filters.code ? filters.code : null,
          assignee_type: assigneeType,
          assignee_user: assigneeUser,
          is_pipeline: +filters.is_pipeline >= 0 ? filters.is_pipeline : null,
          status_list: filters.status_list ? filters.status_list : null,
          expired: filters.expired ? filters.expired : null
        }
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ProjectTaskModel> {
    let item = new ProjectTaskModel(ProjectTaskModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ProjectTaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: ProjectTaskJsonAddEdit): Promise<ProjectTaskModel> {
    let item = new ProjectTaskModel(ProjectTaskModel.getDefaultData());

    try {
      let postData = {
        ...data
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProjectTaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: ProjectTaskJsonAddEdit): Promise<ProjectTaskModel> {
    let item = new ProjectTaskModel(ProjectTaskModel.getDefaultData());

    try {
      let postData = {
        ...data
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ProjectTaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async updateStatus(
    data: ProjectTaskJsonUpdateStatus
  ): Promise<ProjectTaskModel> {
    let item = new ProjectTaskModel(ProjectTaskModel.getDefaultData());

    try {
      let postData = {
        ...data
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/updatestatus",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ProjectTaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ProjectTaskApi;
