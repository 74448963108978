import { SelectProps } from "antd";
import { SelectValue } from "antd/lib/select";
import { FormLabelAlign } from "antd/lib/form/interface";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";

const WarehouseFormSelect = ({
  label,
  name,
  disabled,
  required,
  allowClear,
  placeholder,
  labelAlign,
  size,
  status,
  initOptions,
  onChangeSelect,
  className,
}: {
  label: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  labelAlign?: FormLabelAlign;
  size?: SelectProps["size"];
  status?: SelectProps["status"];
  initOptions?: SelectOption[];
  onChangeSelect?: (v: SelectValue) => void;
  className?: string;
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(typeof initOptions === "undefined");
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new WarehouseRepository().getItems({
      filters: WarehouseRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading && typeof initOptions === "undefined") {
      fetchData();
    } else if (Array.isArray(initOptions)) {
      setOptions(initOptions);
    }
  }, [loading, fetchData, initOptions]);

  return (
    <FormSelect
      size={size}
      required={required}
      disabled={disabled ?? false}
      loading={loading}
      showSearch={options.length > 6}
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
      allowClear={allowClear}
      placeholder={placeholder}
      labelAlign={labelAlign}
      status={status}
      onChange={onChangeSelect}
      className={className}
    />
  );
};

export default WarehouseFormSelect;
