import { Form, Input } from "antd";
import Role from "common/constants/Role";
import OrderModel from "common/models/OrderModel";
import { Filter } from "common/types/Filter";
import { StoreJson } from "common/types/Store";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import StoreFormSelect from "features/store/StoreFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { RangePickerProps } from "antd/es/date-picker";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import DeliveryModel from "common/models/DeliveryModel";
import AxiosClient from "common/api/AxiosClient";
import BaseCollection from "common/collections/BaseCollection";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface Ecomplatform {
	id: number;
	company_id: number;
	title: string;
	code: string;
	status: number;
	date_created: number;
	date_modified: number;
}

const OrderListFilter = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	total,
}: {
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
	total: number;
}) => {
	const { t } = useTranslation();

	const [channels, setChannels] = useState<Ecomplatform[]>([]);

	const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
		"store",
		Role.ORDER_VIEW
	);

	// // FILTER: DATESTARTED AND DATEENDED
	// const getDateCreatedFilter = useMemo(() => {
	// 	let entries = Object.entries(filters)
	// 		.filter(([key, val]) => key === "date_started" || key === "date_ended")
	// 		.map(([key, val]) => {
	// 			return val;
	// 		}) as number[];
	// 	return (
	// 		+entries[0] > 0 && +entries[1] > 0
	// 			? [
	// 					dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
	// 					dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
	// 			  ] || null
	// 			: [null, null] || null
	// 	) as RangeValue;
	// }, [filters]);
	const onChangeDateCreatedFilter: RangePickerProps["onChange"] = (
		value: RangePickerProps["value"],
		dateString: [string, string]
	) => {
		if (typeof value !== "undefined" && value !== null) {
			setFilters({
				...filters,
				date_from: +dayjs(
					"00:00, " + value[0]?.format("DD/MM").toString(),
					"HH:mm, DDMM"
				).unix(),
				date_to: +dayjs(
					"23:59, " + value[1]?.format("DD/MM").toString(),
					"HH:mm, DDMM"
				).unix(),
			});
		} else {
			setFilters({
				...filters,
				date_from: -1,
				date_to: -1,
			});
		}
	};

	async function getSaleChanel() {
		try {
			const { data: channels } = await AxiosClient().get("/ecomplatforms");
			const items = channels?.items as Ecomplatform[];
			console.log(items);
			if (items) {
				setChannels(items);
			}
		} catch (error) {}
	}

	useEffect(() => {
		getSaleChanel();
	}, []);

	return (
		<div id="order_filter">
			<PageDataFilterForm
				triggerOnSubmit={["code"]}
				submitOnChange={false}
				filters={filters}
				setFilters={setFilters}
				total={total}
				defaultFilters={defaultFilters}
				convertValueByKeyFilter={[
					"store_id",
					"status",
					"sale_channel",
					"delivery_status",
					"type",
					// "code",
				]}>
				<Form.Item name="code">
					<Input allowClear placeholder={t("order:id")} htmlSize={15} />
				</Form.Item>

				<StoreFormSelect
					placeholder={t("order:store")}
					name="store_id"
					allowClear
					defaultOptions={storeItemsWithLimit.map((i) => ({
						label: i.name,
						value: i.id,
					}))}
					popupMatchSelectWidth={false}
				/>

				<Form.Item name="keyword">
					<Input
						allowClear
						placeholder={t("order:filter_keyword_placeholder")}
					/>
				</Form.Item>

				<FormSelect
					name="sale_channel"
					placeholder={t("order:ecom_platform_type")}
					options={channels.map((item) => ({
						label: item.title,
						value: item.id,
					}))}
					allowClear
					popupMatchSelectWidth={false}
				/>

				{/* FILTER: DATESTART AND DATEEND */}

				<FormSelect
					name="status"
					placeholder={t("order:status")}
					options={OrderModel.getStatusList()}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<FormSelect
					name="delivery_status"
					placeholder={t("order:shipping_status")}
					options={DeliveryModel.getManagerActionStatusList()}
					allowClear
					popupMatchSelectWidth={false}
				/>
				<FormSelect
					name="type"
					placeholder={t("Loại đơn hàng")}
					options={[...OrderModel.getTypeInvoiceList()]}
					// allowClear
					popupMatchSelectWidth={false}
				/>
				<Form.Item
					className="lg:w-[400px] gap-1 "
					label={t("common:date_created")}>
					{/* <DateFormFilter
						rangeValue={getDateCreatedFilter}
						allowClear={true}
						showNow={false}
						mode="range"
						format="DD/MM/YYYY"
						onRangeChange={onChangeDateCreatedFilter}
					/> */}

					<DatePickerRangeFilter
						filters={filters}
						keyStart="from_date"
						keyEnd="to_date"
						setFilters={setFilters}
						placeholderRange={[
							t("reportdelivery:placeholder_datefrom"),
							t("reportdelivery:placeholder_dateto"),
						]}
					/>
				</Form.Item>
			</PageDataFilterForm>
		</div>
	);
};

export default OrderListFilter;
