import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DmsNewsFormEditorHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:group_delivery"),
      link: "",
    },
    {
      text: t("sitemenu:delivery_portal"),
      link: "/dmsnews",
    },
    {
      text: t("sitemenu:delivery_portal_news"),
      link: "/dmsnews",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(
        isEditing
          ? "dmsnewscategory:heading_edit"
          : "dmsnewscategory:heading_add"
      )}
    ></PageHeader>
  );
};

export default DmsNewsFormEditorHeader;
