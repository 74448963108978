import { Radio, Space, Tag } from "antd";
import PosCartModel from "common/models/PosCartModel";
import { PosCartDeliveryListItem } from "common/types/PosCart";
import { ShippingCarrierJson } from "common/types/ShippingCarrier";
import TextMoney from "components/TextMoney";
import useDatabaseTable from "hooks/useDatabaseTable";
import update from "immutability-helper";
import { isUndefined } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import usePosCartStore from "zustands/usePosCartStore";

const PosRightDeliveryServiceList = ({
  region_id,
  sub_region_id,
}: {
  region_id: number;
  sub_region_id: number;
}) => {
  const activeDelivery = usePosCartStore(
    (state) =>
      state.getActiveCart()?.delivery || PosCartModel.getDefaultCartDelivery()
  );

  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);
  const [allShippingCarriers] =
    useDatabaseTable<ShippingCarrierJson>("shippingcarrier");

  const [validShippingCarrierDetails, setValidShippingCarrierDetails] =
    useState<PosCartDeliveryListItem[]>([]);

  const calculateValidShippingCarriers = useCallback(() => {
    //only load available when shippingcarriers had been loaded all
    if (allShippingCarriers.length > 0) {
      let validDetails: PosCartDeliveryListItem[] = [];
      //Loop through all methods to find valid methods
      allShippingCarriers
        .sort((a, b) => {
          return a.id > b.id ? -1 : 1;
        })
        .forEach((item) => {
          //check serve this region or not
          item.detail.split(",").forEach((detailitem) => {
            const parts = detailitem.split(":");
            if (parseInt(parts[0]) === region_id) {
              validDetails.push({
                shipping_method: item,
                region_id,
                sub_region_id,
                price_shipping: parseInt(parts[2]),
              });
            }
          });
        });
      setValidShippingCarrierDetails(validDetails);
    }
  }, [allShippingCarriers, region_id, sub_region_id]);

  useEffect(() => {
    if (allShippingCarriers.length > 0) {
      calculateValidShippingCarriers();
    }
  }, [allShippingCarriers, calculateValidShippingCarriers]);

  const handleChange = useCallback(
    (e: any) => {
      const methodId = parseInt(e.target.value);

      let changedInfo = {
        shipping_carrier_id: { $set: 0 },
        shipping_carrier_price: { $set: 0 },
      };

      let updatedCartShippingPrice = 0;
      if (methodId > 0) {
        const foundItem: PosCartDeliveryListItem | undefined =
          validShippingCarrierDetails.find(
            (item) => item.shipping_method.id === methodId
          );
        if (!isUndefined(foundItem) && foundItem != null) {
          changedInfo = {
            shipping_carrier_id: { $set: methodId },
            shipping_carrier_price: {
              $set: foundItem.price_shipping,
            },
          };
          updatedCartShippingPrice = foundItem.price_shipping;
        }
      }

      //save delivery info
      // & update shipping price !important, because it's will override the value manual input (shipping price popup) in cart summary
      updateActiveCart({
        delivery: update(activeDelivery, changedInfo),
        price_shipping: updatedCartShippingPrice,
      });
    },
    [updateActiveCart, activeDelivery, validShippingCarrierDetails]
  );

  return (
    <div className="mg-t-10">
      <Radio.Group
        onChange={handleChange}
        value={activeDelivery.shipping_carrier_id}
      >
        <Space direction="vertical">
          <Radio value={0} className="d-block pd-y-5">
            Không giao hàng
          </Radio>

          {validShippingCarrierDetails.map((item) => {
            return (
              <Radio
                value={item.shipping_method.id}
                key={item.shipping_method.id}
                className="d-block pd-y-5"
              >
                {item.shipping_method.name}{" "}
                <Tag>
                  <TextMoney money={item.price_shipping} />
                </Tag>
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </div>
  );
};

export default PosRightDeliveryServiceList;
