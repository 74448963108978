const ShippingOrder = {
  TYPE_CUSTOMER: 0,
  TYPE_INTERNAL: 1,

  ENSURANCE_TYPE_NO_ENSURANCE: 1,
  ENSURANCE_TYPE_SIMPLE_ENSURANCE: 3,

  PRODUCT_TYPE_NORMAL: 1,
  PRODUCT_TYPE_FOOD: 3,
  PRODUCT_TYPE_FROZENFOOD: 5,
  PRODUCT_TYPE_FRAGILE: 7,

  SOURCE_MANUAL: 1,
  SOURCE_API: 3,

  DELIVERY_STATUS_PENDING: 1,
  DELIVERY_STATUS_SHIPPING: 3,
  DELIVERY_STATUS_COMEIN: 5,
  DELIVERY_STATUS_COMPLETE: 7,
  DELIVERY_STATUS_FAIL: 9,

  PICKUP_STATUS_NOPICKUP: 1,
  PICKUP_STATUS_INPROGRESS: 5,
  PICKUP_STATUS_COMPLETE: 9,
  PICKUP_STATUS_FAIL: 11,

  RETURN_STATUS_NO: 1,
  RETURN_STATUS_PENDING: 3,
  RETURN_STATUS_INPROGRESS: 5,
  RETURN_STATUS_COMPLETE: 7,
  RETURN_STATUS_FAIL: 9,

  COD_STATUS_EMPTY: 1,
  COD_STATUS_ONHOLD: 3,
  COD_STATUS_RETURN: 5,

  STATUS_NEW: 1,
  STATUS_PENDING: 3,
  STATUS_INPROGRESS: 5,
  STATUS_SUCCESS: 7,
  STATUS_FAIL: 9,
  STATUS_SUCCESS_COD: 11,
  STATUS_SUB_SUCCESS: 13, //hoàn thành hộ
  STATUS_CANCELLED: 15, //hủy

  FROM_TYPE_OFFICE: 0,
  FROM_TYPE_MERCHANT: 1,

  STATUS_ASSIGNED_ROUTE: 1,
  STATUS_UNASSIGNED_ROUTE: 3,
};

export default ShippingOrder;
