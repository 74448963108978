import PromotionCollection from "common/collections/PromotionCollection";
import PromotionCheckResultModel from "common/models/PromotionCheckResultModel";
import PromotionGiftCheckResultModel from "common/models/PromotionGiftCheckResultModel";
import PromotionModel from "common/models/PromotionModel";
import {
	PromotionCheckQuery,
	PromotionCheckResultJson,
	PromotionGiftCheckQuery,
	PromotionGiftCheckResultJson,
} from "common/types/PromotionCheck";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
	FilterPromotion,
	PromotionJsonAddEdit,
} from "common/types/Promotion";
import Helper from "common/utils/helper";
const SERVICE_URL = "/promotions";

class PromotionApi extends BaseApi {
	async getList(
		props: GetListProps<FilterPromotion>
	): Promise<PromotionCollection> {
		let collection = new PromotionCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					status: +filters.status >= 0 ? filters.status : null,
					id: filters.id > 0 ? filters.id : null,
					group: filters.group > 0 ? filters.group : null,
					type: filters.type > 0 ? filters.type : null,
					date_started: filters.date_started > 0 ? filters.date_started : null,
					date_ended: filters.date_ended > 0 ? filters.date_ended : null,
					list_id: filters?.list_id?.length ? filters.list_id : null,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getDetail(id: number): Promise<PromotionModel> {
		let item = new PromotionModel(PromotionModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
			if (response.hasOwnProperty("data")) {
				item = new PromotionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async delete(id: number): Promise<string[]> {
		let resultErrors = [];

		try {
			const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
			if (response.status !== 204) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async add(data: PromotionJsonAddEdit): Promise<PromotionModel> {
		let item = new PromotionModel(PromotionModel.getDefaultData());

		try {
			let postData = Helper.convertParams(data, true);

			//For create item
			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new PromotionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async edit(data: PromotionJsonAddEdit): Promise<PromotionModel> {
		let item = new PromotionModel(PromotionModel.getDefaultData());

		try {
			let postData = Helper.convertParams(data, true);

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + data.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new PromotionModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async check(data: PromotionCheckQuery): Promise<PromotionCheckResultModel> {
		let promotionCheckResult = new PromotionCheckResultModel({
			order: [],
			product: [],
			product_seasonal: [],
			total: 0,
		});

		try {
			let postData = {
				...data,
			};

			const response = await AxiosClient().post<PromotionCheckResultJson>(
				"/promotionchecks/apply",
				postData
			);
			if (response.hasOwnProperty("data")) {
				promotionCheckResult = new PromotionCheckResultModel(response.data);
			}
		} catch (error) {
			promotionCheckResult.withError(BaseApi.handleError(error));
		}

		return promotionCheckResult;
	}

	async checkGift(
		data: PromotionGiftCheckQuery
	): Promise<PromotionGiftCheckResultModel> {
		let checkResult = new PromotionGiftCheckResultModel({
			sku: "",
		});

		try {
			let postData = {
				...data,
			};

			const response = await AxiosClient().post<PromotionGiftCheckResultJson>(
				"/promotionchecks/gift",
				postData
			);
			if (response.hasOwnProperty("data")) {
				checkResult = new PromotionGiftCheckResultModel(response.data);
			}
		} catch (error) {
			checkResult.withError(BaseApi.handleError(error));
		}

		return checkResult;
	}
}

export default PromotionApi;
