import { App, Tooltip } from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import Role from "common/constants/Role";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import TextProductReceiptDirection from "components/TextProductReceiptDirection";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconPackage } from "@tabler/icons-react";

const OrderProductReceiptItem = ({
  data,
  isFull
}: {
  data: ProductReceiptModel;
  isFull?: boolean;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();

  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );

  const havePermission = useMemo(() => {
    return (
      hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, data.warehouse_id) ||
      hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, data.warehouse_id) ||
      hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, data.warehouse_id) ||
      hasRoleAndLimit(Role.INVENTORY_STATUS_CANCEL, data.warehouse_id)
    );
  }, [hasRoleAndLimit, data.warehouse_id]);

  const boxClassName = useMemo(() => {
    if (data.status === ProductReceipt.STATUS_COMPLETED) {
      return "text-green-500";
    } else if (data.status === ProductReceipt.STATUS_CANCELLED) {
      return "text-red-500";
    } else if (data.status === ProductReceipt.STATUS_DRAFT) {
      return "text-grey-500";
    } else {
      return "text-yellow-500";
    }
  }, [data]);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (!havePermission) {
        e.preventDefault();
        message.warning(t("common:role.error_permission"));
      }
    },
    [havePermission, t, message]
  );

  if (typeof isFull !== "undefined" && isFull) {
    return (
      <Tooltip title={t("common:table.view_and_edit")}>
        <Link
          to={"/productreceipt/edit/id/" + data.id}
          target="_blank"
          onClick={onClick}
          className="cursor-pointer block py-1.5 px-4 border border-blue-100 bg-blue-50 mb-0.5 rounded text-xs leading-6 mr-2">
          <span className="block">
            <span className="text-gray-500">{t("productreceipt:id")}: </span>
            <span className="text-lg font-bold text-gray-900">#{data.id}</span>
          </span>
          <span className="block">
            <span className="text-gray-500">
              {t("productreceipt:status")}:{" "}
            </span>
            <span className={boxClassName}>
              {ProductReceiptModel.getStatus(data.status)?.label}
            </span>
          </span>
          {data.note.length > 0 ? (
            <span className="block px-2 py-1 mt-1 mb-2 text-xs italic bg-white rounded-lg">
              "{data.note}"
            </span>
          ) : null}
        </Link>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip
        mouseEnterDelay={0.4}
        title={
          <div className="text-xs leading-5">
            <div className="uppercase">
              <TextProductReceiptDirection
                direction={data.direction}
                prefix={t("productreceipt:direction") + " "}
              />{" "}
              #{data.id}
            </div>
            <div>
              {t("productreceipt:status")}:{" "}
              {ProductReceiptModel.getStatus(data.status)?.label}
            </div>
            <div>
              {t("productreceipt:note")}:{" "}
              {data.note.length > 0 ? data.note : "--"}
            </div>
          </div>
        }>
        <Link
          to={"/productreceipt/edit/id/" + data.id}
          target="_blank"
          onClick={onClick}
          className="cursor-pointer block px-1 py-0.5 border border-white hover:border-blue-200 mb-0.5 rounded-sm text-blue-600">
          <IconPackage
            className={"mr-1 -mt-0.5 " + boxClassName}
            size="22"
            strokeWidth={1.2}
          />
          <span className="text-xs">#{data.id}</span>
        </Link>
      </Tooltip>
    </>
  );
};

export default OrderProductReceiptItem;
