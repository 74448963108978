import React from "react";
import usePathParams from "hooks/usePathParams";
import QRCode from "features/tools/form/QRCode";
import WifiQrCode from "features/tools/form/WifiQRCode";
import AvatarFrame from "features/tools/form/AvatarFrame";

const ToolPage = () => {
  const [pathParams] = usePathParams();

  let com = <></>;

  switch (pathParams.action) {
    case "wifiqrcode":
      com = <WifiQrCode />;
      break;
    case "avatarframe":
      com = <AvatarFrame />;
      break;
    default:
      com = <QRCode />;
      break;
  }
  return com;
};

export default ToolPage;
