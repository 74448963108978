import { ProductVariantInMemory } from 'common/types/ProductVariant';
import dbm from 'dbm';
import React, { useEffect, useState } from 'react';

import TextProductVariantColor from './TextProductVariantColor';
import TextProductVariantSize from './TextProductVariantSize';

const TextProductVariant = ({ id }: { id: number }) => {
  const [variant, setVariant] = useState<ProductVariantInMemory>();

  useEffect(() => {
    (async () => {
      const foundItems = await dbm
        .getCollection("productvariant")
        .chain()
        .find({
          id,
        })
        .limit(1)
        .data();
      if (foundItems.length > 0) {
        setVariant(foundItems[0]);
      }
    })();
  }, [id]);

  if (typeof variant === "undefined") {
    return <>Product Variant ID: #{id}</>;
  }

  return (
    <div>
      <div>{variant.product_name}</div>
      <div className="text-xs text-gray-400">
        {variant.product_code}&nbsp; | &nbsp;{" "}
        <TextProductVariantColor id={variant.color} />
        &nbsp; | &nbsp; <TextProductVariantSize id={variant.size} />
      </div>
    </div>
  );
};

export default TextProductVariant;
