import { Modal, Skeleton } from 'antd';
import RbacRoleUserCollection from 'common/collections/RbacRoleUserCollection';
import { RefModalSubmit } from 'common/interfaces/ModalSubmit';
import RbacRoleModel from 'common/models/RbacRoleModel';
import RbacRoleUserModel from 'common/models/RbacRoleUserModel';
import RbacRoleRepository from 'common/repositories/RbacRoleRepository';
import RbacRoleUserRepository from 'common/repositories/RbacRoleUserRepository';
import ErrorAlert from 'components/ErrorAlert';
import RbacRoleEditorForm from 'features/rbacrole/editor/RbacRoleEditorForm';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const RbacRoleEditorModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (b: boolean) => any;
  onSaveSuccess: (r: RbacRoleModel) => void;
}) => {
  const inputRef = useRef<any>(null);
  const { t } = useTranslation();

  const [model, setModel] = useState<RbacRoleModel>(
    new RbacRoleModel(RbacRoleModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [selectedUserIdList, setSelectedUserIdList] = useState<number[]>([]);
  const [processing, setProcessing] = useState(false);
  const modalSubmitRef = useRef<RefModalSubmit>(null);

  const fetchDetail = async (id: number) => {
    let myObj = await new RbacRoleRepository().getItem(id);

    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      //fetch RoleUsers of this roles
      let foundUserIdList: number[] = [];
      let page = 1;
      let limit = 50;
      let fetchedCollection = new RbacRoleUserCollection();
      do {
        fetchedCollection = await new RbacRoleUserRepository().getItems({
          filters: {
            role_id: id,
            page,
            limit,
            sortby: "",
            sorttype: "",
            keyword: "",
            status: 0,
          },
        });
        if (fetchedCollection.hasError()) {
          setErrors(fetchedCollection.error.errors);
          break;
        } else {
          fetchedCollection.items.forEach((item: RbacRoleUserModel) => {
            foundUserIdList.push(item.user_id);
          });
        }
        page++;
      } while (
        !fetchedCollection.hasError() &&
        fetchedCollection.items.length === limit
      );

      //set data for editor
      setSelectedUserIdList(foundUserIdList);
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  useEffect(() => {
    if (open) {
      //for edit page, we need to include `model` in dependency to trigger focus
      setTimeout(() => {
        typeof inputRef !== "undefined" &&
          inputRef.current &&
          inputRef.current.focus();
      }, 10);
    }else{
      modalSubmitRef.current?.resetForm()
    }
  }, [open, model]);

  return (
    <Modal
      title={id > 0 ? t("role:heading_edit") : t("role:heading_add")}
      open={open}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        setModel(new RbacRoleModel(RbacRoleModel.getDefaultData()));
        setOpen(false);
      }}
      onOk={() => {
        modalSubmitRef.current?.doSubmit();
      }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      confirmLoading={processing}
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <RbacRoleEditorForm
                  ref={modalSubmitRef}
                  id={id}
                  model={model}
                  inputRef={inputRef}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  defaultUserIdList={selectedUserIdList}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <RbacRoleEditorForm
          ref={modalSubmitRef}
          id={0}
          model={new RbacRoleModel(RbacRoleModel.getDefaultData())}
          inputRef={inputRef}
          setOpen={setOpen}
          setProcessing={setProcessing}
          defaultUserIdList={[]}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </Modal>
  );
};

export default RbacRoleEditorModal;
