import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const ProfileEditorHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("user:heading_profile_change")}
      siteMenuHidden
    ></PageHeader>
  );
};

export default ProfileEditorHeader;
