import { Modal, Skeleton } from "antd";

import TaskModel from "common/models/TaskModel";
import TaskRepository from "common/repositories/TaskRepository";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TaskKanbanForm from "./TaskKanbanForm";
import ErrorAlert from "components/ErrorAlert";
import Task from "common/constants/Task";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const TaskFormModal = ({
  id,
  visible,
  setVisible,
  onSaveSuccess,
}: {
  id: number;
  visible: boolean;
  setVisible: (v: boolean) => void;
  onSaveSuccess: (m: TaskModel) => void;
}) => {
  const { t } = useTranslation();
  const account = useLoginAccountStore((state: any) => state.account);

  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  const [model, setModel] = useState<TaskModel>(
    new TaskModel(TaskModel.getDefaultData())
  );

  const fetchDetail = async (id: number) => {
    let myObj = await new TaskRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0) {
      fetchDetail(id);
    }
  }, [id]);

  //parent
  const [parentModel, setParentModel] = useState<TaskModel>(
    new TaskModel(TaskModel.getDefaultData())
  );

  const fetchParentDetail = async (id: number) => {
    setParentModel(await new TaskRepository().getItem(id));
  };

  // useEffect(() => {
  //   if (model.id > 0) {
  //     fetchParentDetail(model.parent_id);
  //   }
  // }, [model]);

  return (
    <Modal
      title={
        id > 0
          ? parentModel.id > 0
            ? `Chỉnh sửa task #${id} (Task cha: #${parentModel.id} - ${parentModel.name})`
            : `Chỉnh sửa task #${id}`
          : "Thêm mới"
      }
      width={1000}
      open={visible}
      closable={false}
      maskClosable={false}
      onOk={() => {
        setModel(new TaskModel(TaskModel.getDefaultData()));
        setParentModel(new TaskModel(TaskModel.getDefaultData()));
        setVisible(false);
      }}
      onCancel={() => {
        setModel(new TaskModel(TaskModel.getDefaultData()));
        setParentModel(new TaskModel(TaskModel.getDefaultData()));
        setVisible(false);
      }}
      okButtonProps={{
        form: "taskkanban-form",
        htmlType: "submit",
        disabled:
          model.status === Task.STATUS.TODO
            ? model.employee_id === account.company.employee.id ||
              model.public === 1
              ? false
              : true
            : true,
      }}
      okText={"Lưu thông tin"}
      cancelText={"Đóng"}
      confirmLoading={processing}
      destroyOnClose
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <TaskKanbanForm
                  model={model}
                  setVisible={setVisible}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <TaskKanbanForm
          model={new TaskModel(TaskModel.getDefaultData())}
          setVisible={setVisible}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </Modal>
  );
};

export default TaskFormModal;
