import DeliveryInternalForm from "features/delivery/manager/form/DeliveryInternalForm";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import { DeliveryInternalAddEditJson } from "common/types/Delivery";
import { useEffect, useState } from "react";
import Delivery from "common/constants/Delivery";
import {
	Button,
	Dropdown,
	MenuProps,
	message,
	Modal,
	notification,
	Select,
	Tag,
} from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import OrderModel from "common/models/OrderModel";
import { OrderDeliveryStatus } from "common/types/Order";
import { SelectOption } from "common/types/SelectOption";
import { useTranslation } from "react-i18next";

export default function DeliveryOrderForm({
	id,
	onConfirmSuccess,
}: {
	id: number;
	onConfirmSuccess?: (dely: DeliveryModel) => void;
}) {
	const { t } = useTranslation();
	const [model, setModel] = useState<DeliveryModel | null>(null);
	const [modal, contextHolder] = Modal.useModal();
	const [messageApi, contextHolderMess] = message.useMessage();
	async function featchData(id: number) {
		if (id) {
			const res = await new DeliveryRepository().getItem(id);
			if (!res.hasError()) {
				setModel(res);
			}
		}
	}

	const onSubmit = async (data: DeliveryInternalAddEditJson) => {
		messageApi.open({
			type: "loading",
			content: "Action in progress..",
			duration: 0,
		});
		let response: DeliveryModel = await new DeliveryRepository().saveRemote(
			data
		);

		if (response.hasError()) {
			notification.error({ message: response.error.errors[0] });
		} else {
			onConfirmSuccess?.(response);
		}

		messageApi.destroy();
	};
	async function handleAction(option: SelectOption) {
		messageApi.open({
			type: "loading",
			content: "Action in progress..",
			duration: 0,
		});
		// if (data.partner_delivery !== Delivery.PARTNER_NSHIP) {
		//   new DeliveryRepository().addAction({
		//     action_code: Delivery.ACTION_WAITING,
		//     deliveries_id: response.id.toString(),
		//   });
		// } else {
		//   new DeliveryRepository().addAction({
		//     action_code: Delivery.ACTION_NEW,
		//     deliveries_id: response.id.toString(),
		//   });
		// }

		if (!model) return;
		let action_code: string = "ACTION_NEW";

		switch (option.value) {
			// case OrderDeliveryStatus.STATUS_DRAFT:
			//   action_code = "ACTION_DRAFT";
			//   break;
			case OrderDeliveryStatus.STATUS_NEW:
				action_code = Delivery.ACTION_NEW;
				break;
			case OrderDeliveryStatus.STATUS_DELIVER:
				action_code = Delivery.ACTION_START;
				break;
			case OrderDeliveryStatus.STATUS_SUCCESS:
				action_code = Delivery.ACTION_DELIVERED;
				break;
			case OrderDeliveryStatus.STATUS_COMPLETE:
				action_code = Delivery.ACTION_COMPLETE;
				break;
			case OrderDeliveryStatus.STATUS_CANCEL:
				action_code = Delivery.ACTION_CANCEL;
				break;
			default:
				break;
		}

		const res = await new DeliveryRepository().addAction({
			action_code: action_code,
			deliveries_id: model.id.toString(),
		});

		if (!res.hasError()) {
			notification.success({ message: "Thành công" });
			featchData(id);
			onConfirmSuccess?.(res);
		} else {
			notification.error({ message: res.error.errors[0] });
		}

		messageApi.destroy();
	}

	const itemsAction: MenuProps["items"] =
		OrderModel.getStatusDeliveryActionList().map((option, index) => {
			return {
				label: (
					<>
						{contextHolder}
						<Button
							// disabled={!allowStatusComplete}
							onClick={() => handleAction(option)}
							type="primary"
							style={{ width: "100%" }}
							color={option.color}>
							{option.label}
						</Button>
					</>
				),
				key: index,
				// disabled: !allowStatusComplete,
			};
		});
	// [
	//   {
	//     label: (
	//       <>
	//         {contextHolder}

	//         <Button
	//           // disabled={!allowStatusComplete}
	//           // onClick={confirmComplete}
	//           type="primary"
	//           style={{ width: "100%" }}
	//         >
	//           Xác nhận
	//         </Button>
	//       </>
	//     ),
	//     key: "0",
	//     // disabled: !allowStatusComplete,
	//   },
	//   {
	//     label: (
	//       <>
	//         {contextHolder}

	//         <Button
	//           // disabled={!allowStatusCancel}
	//           // onClick={() => setOpenCancel(true)}
	//           type="primary"
	//           danger
	//           style={{ width: "100%" }}
	//         >
	//           Hủy
	//         </Button>
	//       </>
	//     ),
	//     key: "1",
	//     // disabled: !allowStatusCancel,
	//   },
	// ];

	useEffect(() => {
		featchData(id);
	}, [id]);

	if (!model) {
		return <LoadingOutlined />;
	}

	return (
		<div className=" flex flex-col gap-4 max-h-[500px] overflow-auto">
			{/* {model.partner_delivery &&
      model.partner_delivery !== Delivery.PARTNER_NSHIP ? ( */}
			<div className=" flex items-center gap-3">
				<div className=" flex items-center gap-1">
					<span>Trạng thái:</span>
					<Tag>{t(`delivery:action_code.${model.action_code}`)}</Tag>
				</div>
				<Dropdown
					menu={{ items: itemsAction }}
					trigger={["click"]}
					arrow
					placement="bottom">
					<Button icon={<DownOutlined />}>Thao tác</Button>
				</Dropdown>
			</div>

			<DeliveryInternalForm
				model={model}
				redirectOnSuccess={""}
				onSubmitForm={(data) => {
					onSubmit(data);
				}}
				isShowHeader={false}
				submitText={"Lưu"}
			/>
			{contextHolderMess}
		</div>
	);
}
