import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { DeliverySumbyManagerJson } from "common/types/DeliverySumbyManager";

import BaseModel from "./BaseModel";

class DeliverySumbyManagerModel
  extends BaseModel
  implements BaseModelJson<DeliverySumbyManagerJson>
{
  total_count: number;
  total_sum: number;
  totalcod_count: number;
  totalcod_sum: number;
  transfer_count: number;
  transfer_sum: number;
  current_count: number;
  current_sum: number;
  returned_count: number;
  returned_sum: number;
  receiving_count: number;
  receiving_sum: number;

  constructor(json: DeliverySumbyManagerJson) {
    super();
    this.total_count = json.total_count;
    this.total_sum = json.total_sum;
    this.totalcod_count = json.totalcod_count || 0;
    this.totalcod_sum = json.totalcod_sum || 0;
    this.transfer_count = json.transfer_count || 0;
    this.transfer_sum = json.transfer_sum || 0;
    this.current_count = json.current_count || 0;
    this.current_sum = json.current_sum || 0;
    this.returned_count = json.receiving_count || 0;
    this.returned_sum = json.receiving_sum || 0;
    this.receiving_count = json.receiving_count || 0;
    this.receiving_sum = json.receiving_sum || 0;
  }

  static getDefaultData(): DeliverySumbyManagerJson {
    return {
      total_count: 0,
      total_sum: 0,
      totalcod_count: 0,
      totalcod_sum: 0,
      transfer_count: 0,
      transfer_sum: 0,
      current_count: 0,
      current_sum: 0,
      returned_count: 0,
      returned_sum: 0,
      receiving_count: 0,
      receiving_sum: 0,
    };
  }

  toJson(): DeliverySumbyManagerJson {
    return {
      total_count: this.total_count,
      total_sum: this.total_sum,
      totalcod_count: this.totalcod_count,
      totalcod_sum: this.totalcod_sum,
      transfer_count: this.transfer_count,
      transfer_sum: this.transfer_sum,
      current_count: this.current_count,
      current_sum: this.current_sum,
      returned_count: this.returned_count,
      returned_sum: this.returned_sum,
      receiving_count: this.receiving_count,
      receiving_sum: this.receiving_sum,
    };
  }
}

export default DeliverySumbyManagerModel;
