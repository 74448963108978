import React, { useEffect, useMemo, useState } from "react";

import useStateFilter from "hooks/useStateFilter";

import HomeLeftListWrapper from "./../HomeLeftListWrapper";

import { EmployeeJson } from "common/types/Employee";
import { FilterEmployee } from "common/types/Employee";
import { Col, List, Row, Skeleton, Typography } from "antd";
import { useTranslation } from "react-i18next";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import { IconCake } from "@tabler/icons-react";

type Data = EmployeeJson & {
  loading?: boolean;
};
const HomeLeftBirthday = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  const [dataSource, setDataSource] = useState<Data[]>(
    Array.from({ length: 10 }).map(() => ({
      ...EmployeeModel.getDefaultData(),
      loading: false,
    }))
  );
  const defaultFilters = useMemo(() => {
    return EmployeeRepository.getDefaultFilters();
  }, []);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  ////////////////////////
  //fetch data
  const fetchData = async () => {
    const response = await new EmployeeRepository().getListBirthday();

    if (!response.hasError()) {
      setDataSource(response.items);
    } else {
      setErrors(response.error.errors);
    }
    setLoading(false);
  };

  ////////////////////////
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <HomeLeftListWrapper<FilterEmployee, Data>
        {...{
          filters,
          setFilters,
          dataSource,
          errors,
          iconTitle: <IconCake size={18} />,
          loading: loading,
          title: t("common:home.birthday"),

          renderItem(item) {
            return (
              <Skeleton
                className="px-2"
                loading={item.loading}
                active
                title={false}
                round
                key={item.id}
              >
                <div className="flex items-center justify-between border-[1px] border-gray-200 p-2 my-2">
                  <Text title={item.full_name}>{item.full_name}</Text>
                  {/* <Text
                    type="secondary"
                    ellipsis={{
                      tooltip: <TextDateTime ts={item.birthday} />,
                    }}
                  >
                    <TextDateTime ts={item.birthday} />
                  </Text> */}
                  <Text strong className="text-blue-600">
                    {new Date(item.birthday * 1000).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                      }
                    ) !==
                    new Date(Date.now()).toLocaleDateString("en-GB", {
                      day: "2-digit",
                    }) ? (
                      new Date(item.birthday * 1000).toLocaleDateString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                        }
                      )
                    ) : (
                      <span>🎂</span>
                    )}
                  </Text>
                </div>
              </Skeleton>
            );
          },
        }}
      ></HomeLeftListWrapper>
    </div>
  );
};

export default HomeLeftBirthday;
