import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DepartmentFormFilter from "features/department/DepartmentFormFilter";
import OfficeFormFilter from "features/office/OfficeFormFilter";
import { useTranslation } from "react-i18next";
import FilterBody from "components/filter/FilterBody";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import FormSelectNormal from "components/form/FormSelectNormal";
import LeaveModel from "common/models/LeaveModel";

const LeaveApprovalListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["department_id", "type", "status"]}
      >
        <Form.Item name="keyword">
          <Input
            allowClear
            placeholder={t(
              "leaveapprove:approval.filter.filter_keyword_placeholder"
            )}
          />
        </Form.Item>
        <Form.Item name="status">
          <FormSelectNormal
            name="status"
            options={LeaveModel.getLeaveStatus()}
            allowClear
            placeholder={t("leaveapprove:approval.filter.status")}
          ></FormSelectNormal>
        </Form.Item>
        <Form.Item>
          <DatePickerRangeFilter
            filters={filters}
            setFilters={setFilters}
            keyEnd="date_to"
            keyStart="date_from"
          ></DatePickerRangeFilter>
        </Form.Item>

        <DepartmentFormFilter
          placeholder={t("leaveapprove:approval.filter.department")}
          name="department_id"
          allowClear
          popupMatchSelectWidth={false}
        />
      </PageDataFilterForm>
    </>
  );
};

export default LeaveApprovalListFilter;
