import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { EcomSyncProductJson } from 'common/types/EcomSyncProduct';

import BaseModel from './BaseModel';

class EcomSyncProductModel
  extends BaseModel
  implements BaseModelJson<EcomSyncProductJson>
{
  id: number;
  product_id: number;
  product_option_id: number;
  sku: string;
  channel_id: number;
  channel_product_id: number;
  channel_variant_id: number;
  inventory_quantity: number;
  inventory_item_id: number;
  date_created: number;
  date_modified: number;
  date_last_synced: number;

  constructor(json: EcomSyncProductJson) {
    super();

    this.id = json.id || 0;
    this.product_id = json.product_id || 0;
    this.product_option_id = json.product_option_id || 0;
    this.sku = json.sku || "";
    this.channel_id = json.channel_id || 0;
    this.channel_product_id = json.channel_product_id || 0;
    this.channel_variant_id = json.channel_variant_id || 0;
    this.inventory_quantity = json.inventory_quantity || 0;
    this.inventory_item_id = json.inventory_item_id || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_last_synced = json.date_last_synced || 0;
  }

  toJson(): EcomSyncProductJson {
    return {
      id: this.id,
      product_id: this.product_id,
      product_option_id: this.product_option_id,
      sku: this.sku,
      channel_id: this.channel_id,
      channel_product_id: this.channel_product_id,
      channel_variant_id: this.channel_variant_id,
      inventory_quantity: this.inventory_quantity,
      inventory_item_id: this.inventory_item_id,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_last_synced: this.date_last_synced,
    };
  }

  static getDefaultData(): EcomSyncProductJson {
    return {
      id: 0,
      product_id: 0,
      product_option_id: 0,
      sku: "",
      channel_id: 0,
      channel_product_id: 0,
      channel_variant_id: 0,
      inventory_quantity: 0,
      inventory_item_id: 0,
      date_created: 0,
      date_modified: 0,
      date_last_synced: 0,
    };
  }
}

export default EcomSyncProductModel;
