import { useTranslation } from "react-i18next";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";

const DeliveryManagerListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("delivery:heading_manager")}
      siteMenuSelectedKey="/delivery/manager"
    >
      <PageHeaderButton link="/delivery/add">
        {t("common:table.add_button")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default DeliveryManagerListHeader;
