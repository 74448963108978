import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import { PosPrintMoreData } from "common/types/PosCart";
import { PosOrder, PosOrderDetail } from "common/types/PosOrder";
import Helper from "common/utils/helper";
import dayjs from "dayjs";
import _ from "lodash";
// For "react-to-print" to work only Class based components can be printed.
import React, { forwardRef, Ref, useCallback } from "react";

const PosSubmitPrintPaper = forwardRef(
  (
    {
      reprint,
      order,
      printTemplate,
      printMoreData,
    }: {
      reprint: boolean;
      order: PosOrder;
      printTemplate: string;
      printMoreData: PosPrintMoreData;
    },
    ref: Ref<any>
  ) => {
    const formatDetails = useCallback((details: PosOrderDetail[]) => {
      var order_item_row: any = [];

      /////////////////////
      //process ORDER_ITEM_ROW
      details.forEach(function (item) {
        var row = "<tr>";

        row +=
          "<td>" +
          item.item_name +
          "<em> (" +
          item.sku +
          ")</em>" +
          "</td>" +
          '<td align="right">' +
          (item.price_before_promotion !== item.price
            ? '<span style="text-decoration: line-through; color: #777">' +
              Helper.moneyFormat(item.price_before_promotion) +
              "</span><br/>"
            : "") +
          Helper.moneyFormat(item.price) +
          "</td>" +
          '<td align="center">' +
          Helper.numberFormat(item.item_quantity) +
          "</td>";
        row +=
          '<td align="right">' +
          (item.price_before_promotion !== item.price
            ? '<span style="text-decoration: line-through; color: #777">' +
              Helper.moneyFormat(
                item.item_quantity * item.price_before_promotion
              ) +
              "</span><br/>"
            : "") +
          Helper.moneyFormat(item.item_quantity * item.price) +
          "</td>";
        row += "</tr>";

        order_item_row.push(row);
      });

      return {
        ORDER_ITEM_ROW: order_item_row.join(""),
      };
    }, []);

    let paymentmethodText = "";
    order.payments.forEach((payment) => {
      //Build paymentmethodText
      const methodInfo = CashflowReceiptModel.getMethod(payment.method);
      if (methodInfo?.label !== "") {
        if (paymentmethodText !== "") {
          paymentmethodText += "<br />";
        }
        paymentmethodText +=
          "- " + methodInfo?.label + ": " + Helper.moneyFormat(payment.value);
        if (payment.note !== "") {
          paymentmethodText += " (" + payment.note + ")";
        }
      }
    });

    let objectReplace = {
      ...formatDetails(order.details),
      ORDER_ID: order.id + (reprint ? " (IN LẠI)" : ""),
      ORDER_DATE: dayjs.unix(order.date_created).format("DD/MM/YYYY HH:mm:ss"),
      CUSTOMER_FULL_NAME: order.billing_full_name || "",
      CUSTOMER_ID: order.customer_id || "",
      CUSTOMER_PHONE: order.billing_phone || "",
      CUSTOMER_EMAIL: order.contact_email || "",
      CUSTOMER_ADDRESS: order.billing_address || "",
      CUSTOMER_REGION: printMoreData.billing_region,
      CUSTOMER_SUB_REGION: printMoreData.billing_sub_region,
      CUSTOMER_SUB_SUB_REGION: printMoreData.billing_sub_sub_region,
      ORDER_NOTE: order.note || "",
      ORDER_SUBTOTAL: Helper.moneyFormat(order.price_sell),
      ORDER_DISCOUNT: Helper.moneyFormat(order.price_discount),
      ORDER_TOTAL_AMOUNT: Helper.moneyFormat(order.price_final),
      ORDER_SHIPPING: Helper.moneyFormat(order.price_shipping),
      TAG: order.tag || "",
      ORDER_CUSTOMER_PAID:
        order.cash_customer_paid > 0
          ? Helper.moneyFormat(order.cash_customer_paid)
          : 0,
      ORDER_CUSTOMER_RETURN:
        order.cash_customer_return > 0
          ? Helper.moneyFormat(order.cash_customer_return)
          : 0,
      LOCATION: printMoreData.store_name,
      LOCATION_ADDRESS: printMoreData.store_address,
      LOCATION_PHONE: printMoreData.store_phone,
      CREATOR: printMoreData.creator_full_name,
      SHIPPING_CARRIER: printMoreData.shipping_carrier_name,
      SHIPPING_ADDRESS: order.shipping_address,
      SHIPPING_REGION: printMoreData.shipping_region,
      SHIPPING_SUB_REGION: printMoreData.shipping_sub_region,
      SHIPPING_SUB_SUB_REGION: printMoreData.shipping_sub_sub_region,
      ORDER_DISCOUNT_PERCENT: "",
      PAYMENT_METHOD: paymentmethodText,
    };

    //format content for printing
    let renderedTemplate = printTemplate;
    _.forIn(objectReplace, (value, key) => {
      // console.log(`${key}: ${value}`);
      var reg = new RegExp("\\[\\[" + key + "\\]\\]", "gim");
      renderedTemplate = renderedTemplate.replace(reg, value);
    });

    return (
      <div ref={ref} dangerouslySetInnerHTML={{ __html: renderedTemplate }} />
    );
  }
);

export default PosSubmitPrintPaper;
