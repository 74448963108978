import ShippingOrder from "common/constants/ShippingOrder";
import GoogleMapWrapper from "components/googlemap/GoogleMapWrapper";
import OficeGrey from "images/mapmarkers/office_grey.png";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

const DeliveryDashboardSectionRightGoogleMapWrapper = ({
  apiKey,
}: {
  apiKey: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const [activeRoute, hubItemByActiveRoute] = useDeliveryDashboardStore(
    (state) => [state.activeRoute, state.hubItemByActiveRoute]
  );

  const zoom = 15;
  const getMapCenter = useCallback(():
    | google.maps.LatLng
    | google.maps.LatLngLiteral => {
    let center = {
      lat: 0,
      lng: 0,
    };

    if (activeRoute && activeRoute.id > 0) {
      if (
        activeRoute.from_type === ShippingOrder.FROM_TYPE_OFFICE &&
        hubItemByActiveRoute !== undefined &&
        hubItemByActiveRoute.lat > 0
      ) {
        center = {
          lat: hubItemByActiveRoute.lat,
          lng: hubItemByActiveRoute.long,
        };
      }
    }
    return center;
  }, [activeRoute, hubItemByActiveRoute]);

  const getMapOptions = useCallback((): google.maps.MapOptions => {
    let options: google.maps.MapOptions = {
      zoom: zoom,
      center: getMapCenter(),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    return options;
  }, [getMapCenter]);

  const initMap = useCallback(
    (ref: HTMLDivElement) => {
      // initMap
      let map = new window.google.maps.Map(ref, getMapOptions());
      // addMarker
      new google.maps.Marker({
        position: getMapCenter(),
        map: map,
        icon: OficeGrey,
      });

      setMap(map);
    },
    [getMapOptions, getMapCenter]
  );

  useEffect(() => {
    if (ref.current && !map) {
      initMap(ref.current);
    }
  }, [initMap, map]);

  return (
    <>
      <GoogleMapWrapper apiKey={apiKey}>
        <div ref={ref} className="w-full h-full" />
      </GoogleMapWrapper>
    </>
  );
};

export default DeliveryDashboardSectionRightGoogleMapWrapper;
