import { Tag } from "antd";
import { PosCartOrderDetail } from "common/types/PosCart";
import TextMoney from "components/TextMoney";

import { IconBan } from "@tabler/icons-react";

const PosCartItemDetailReturn = ({ item }: { item: PosCartOrderDetail }) => {
  return (
    <div>
      <strong style={{ color: "white" }}>{item.item_name.toUpperCase()}</strong>
      <div style={{ fontSize: 11 }} className="pos-cart-item-option">
        {item.item_color} <small>|</small>&nbsp;
        {item.item_size} <small>|</small>&nbsp;SKU: {item.sku}
        <div>
          <Tag icon={<IconBan />} color="warning">
            Trả hàng
          </Tag>
          <span>
            <TextMoney money={item.price} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default PosCartItemDetailReturn;
