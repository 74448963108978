import BaseCollection from "common/collections/BaseCollection";
import WebHookModel from "common/models/WebHookModel";
import { WebHookJson, WebHookCollectionJson } from "common/types/WebHook";

class WebHookCollection extends BaseCollection<
  WebHookJson,
  WebHookModel,
  WebHookCollectionJson
> {
  itemsFromJson(jsonItems: WebHookJson[]): WebHookModel[] {
    return jsonItems.map((item) => new WebHookModel(item));
  }
}

export default WebHookCollection;


