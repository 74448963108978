import { Avatar, AvatarProps } from "antd";
import { EmployeeJson } from "common/types/Employee";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useState } from "react";

import UserAvatar from "./UserAvatar";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const UserIdAvatar = ({
  id,
  className,
  showFullName,
  size,
  hideTooltip,
}: {
  id: number;
  className?: string;
  showFullName?: boolean;
  size?: AvatarProps["size"];
  hideTooltip?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState<EmployeeJson | undefined>();
  const [items] = useDatabaseTable<EmployeeJson>("employee");
  const account = useLoginAccountStore((state) => state.account);
  const findEmployee = useCallback(() => {
    const foundEmployee = items.find((i) => {
      return i.user_id === id;
    });

    if (typeof foundEmployee !== "undefined") {
      setEmployee(foundEmployee);
    }
    setLoading(false);
  }, [id, items]);

  useEffect(() => {
    if (loading && items.length > 0) {
      findEmployee();
    }
  });

  if (typeof employee === "undefined") {
    return <Avatar style={{ backgroundColor: "#eeeeee" }} size={size}></Avatar>;
  }

  const employeeAvatar = (
    <UserAvatar
      user={account}
      className={className}
      size={size}
      hideTooltip={hideTooltip !== undefined ? hideTooltip : true}
    />
  );

  return (
    <>
      {typeof showFullName !== "undefined" && showFullName ? (
        <span>
          <span>{employeeAvatar}&nbsp;&nbsp;</span>
          <span>{employee.full_name}</span>
        </span>
      ) : (
        employeeAvatar
      )}
    </>
  );
};

export default UserIdAvatar;
