import LogAuditCollection from "common/collections/LogAuditCollection";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type { FilterLogAudit } from "common/types/LogAudit";

const SERVICE_URL = "/logaudits";

class LogAuditApi extends BaseApi {
	async getList(
		props: GetListProps<FilterLogAudit>
	): Promise<LogAuditCollection> {
		let collection = new LogAuditCollection();

		try {
			let filters = props.filters;

			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sort_by: filters.sortby,
					sort_type: filters.sorttype,
					ignore_group: 1,
					is_not_deleted: 1,
					keyword: filters.keyword ? filters.keyword : null,
					controller: filters.controller ? filters.controller : null,
					action: filters.action ? filters.action : null,
					severity: +filters.severity > 0 ? filters.severity : null,
					object_id: +filters.object_id > 0 ? filters.object_id : null,
					creator_id: +filters.creator_id > 0 ? filters.creator_id : null,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}
}

export default LogAuditApi;
