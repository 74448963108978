import { Button, Col, Image, Progress, Row, Spin, Tooltip } from "antd";
import FileModel from "common/models/FileModel";
import FileRepository from "common/repositories/FileRepository";
import TextUtil from "common/utils/TextUtil";
import TableDelete from "components/table/TableDelete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IconAlertTriangle,
  IconCheck,
  IconDownload,
  IconEye,
  IconTrash,
} from "@tabler/icons-react";

import type { UploadRenderType, Upload } from "common/types/File";
const FileUploaderItem = ({
  file,
  onDeleteCompleted,
  uploadRenderType,
}: {
  file: Upload;
  onDeleteCompleted: (f: Upload) => void;
  uploadRenderType: UploadRenderType;
}) => {
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);

  let actions = [];
  let icon = null;
  const myFile = new FileModel(
    typeof file.response !== "undefined" &&
    typeof file.response !== "string" &&
    file?.response?.id > 0
      ? file.response
      : FileModel.getDefaultData()
  );

  const fileId =
    typeof file.response !== "undefined" && typeof file.response !== "string"
      ? file.response.id
      : 0;

  switch (file.status) {
    case "error":
      icon = <IconAlertTriangle color="red" style={{ marginTop: 6 }} />;
      break;
    case "done":
      icon =
        uploadRenderType === "list" ? (
          <img
            alt="icon"
            width="80"
            height="80"
            style={{ borderRadius: 4 }}
            src={myFile.getThumbnailSquare(80)}
          />
        ) : (
          <img
            alt="icon"
            style={{ borderRadius: 4, width: "100%" }}
            src={myFile.getThumbnailSquare(100)}
          />
        );
      actions.push(
        <TableDelete
          key="delete"
          onDeleteCallback={(id) => {
            onDeleteCompleted(file);
          }}
          onDeleteErrorCallback={(id) => {
            onDeleteCompleted(file);
          }}
          repository={new FileRepository()}
          id={fileId}
          title={t("file:delete_file_confirm")}
        />
      );
      break;
    default:
      icon = <Spin style={{ marginTop: 11 }} size="small" />;
  }

  //default actions
  if (actions.length === 0) {
    actions.push(
      <Button
        key={"delete"}
        onClick={(e) => onDeleteCompleted(file)}
        size="small"
        type="text"
        icon={<IconTrash size="14" />}
      />
    );
  }

  const previewButton = FileModel.getImageExtensions().includes(
    myFile.extension
  ) ? (
    <div>
      <Tooltip title={t("file:preview")}>
        <Button
          size="small"
          type="text"
          icon={<IconEye className="-mt-0.5" size="18" color="white" />}
        />
      </Tooltip>
    </div>
  ) : null;

  const downloadButton = FileModel.getAllSupportUploadExtensions().includes(
    myFile.extension
  ) ? (
    <div>
      <Tooltip title={t("file:download")}>
        <a href={myFile.url} download={myFile.title}>
          <Button
            size="small"
            type="text"
            icon={<IconDownload className="-mt-0.5" size="18" color="white" />}
            style={{ marginLeft: 3 }}
          />
        </a>
      </Tooltip>
    </div>
  ) : null;

  const deleteButton = (
    <div onClick={(e) => e.stopPropagation()}>
      <TableDelete
        onDeleteCallback={(id) => {
          onDeleteCompleted(file);
        }}
        onDeleteErrorCallback={(id) => {
          onDeleteCompleted(file);
        }}
        repository={new FileRepository()}
        id={fileId}
        title={t("file:delete_file_confirm")}
      />
    </div>
  );

  //parsing error message from response
  const errorMessage: string | undefined =
    file.status === "error" && typeof file.response === "string"
      ? file.response
      : undefined;

  return uploadRenderType === "list" ? (
    <Row className="border-t border-gray-100 pt-2.5 mt-1">
      <Col flex={"50px"}>
        <div
          style={{
            width: 40,
            height: 40,
            marginBottom: 10,
            textAlign: "center",
            borderRadius: 4,
          }}
        >
          {icon}
        </div>
      </Col>
      <Col flex={"250px"}>
        <div style={{ fontSize: 13 }}>
          <strong style={{ color: "#444" }}>
            {TextUtil.truncateFromMiddle(file.name, 32)}
          </strong>
          <br />

          {file.status === "uploading" ? (
            <Progress
              showInfo={false}
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              percent={file.percent}
            />
          ) : (
            <>
              {file.status === "done" ? (
                <IconCheck size="11" color="#089c63" />
              ) : null}{" "}
              {file.hasOwnProperty("status")
                ? t("file:upload_status." + file.status)
                : "-"}
              {file.status === "error" &&
              typeof errorMessage !== "undefined" ? (
                <div className="text-xs text-red-400">
                  {t("file:form.error." + errorMessage)}
                </div>
              ) : null}
            </>
          )}
        </div>
      </Col>
      <Col flex={"30px"} className="text-right">
        {actions}
      </Col>
    </Row>
  ) : (
    <div className="">
      {file.status === "uploading" ? (
        <Progress
          size="small"
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          percent={file.percent}
        />
      ) : (
        <>
          {file.status === "error" ? (
            <Tooltip overlayClassName={"text-xs"} title={<>{errorMessage}</>}>
              <div className="px-2 pb-2 border">
                {icon} <br />
                <div className="pt-2 text-xs text-red-400 ">
                  {t("file:upload_status." + file.status)}
                </div>
              </div>
            </Tooltip>
          ) : (
            <>
              <div className="relative">
                <Image
                  width={100}
                  height={100}
                  style={{ borderRadius: 4 }}
                  src={myFile.getThumbnailSquare(100)}
                  fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAmVBMVEXd3d1TU1NPT09NTU1RUVHh4eHi4uLj4+O+vr7Z2dnU1NTg4OBqamqcnJxiYmLb29utra1tbW2IiIhgYGCGhoZZWVlKSkqOjo7Ly8t3d3ekpKS2tbWoqKiLi4tycnJlZWVdXV3Pz8/ExMS6urqgoKCDg4N0dHRoaGhHR0fo6Ojl5eXW1ta4uLiTk5Nubm5UVFTJycmxsbF9fX0emSIXAAABnklEQVRYw+3V2W6jMBSAYexjMOaEZJIS01J2yL618/4PN2VKRKhKsInUi4r/jiPxXXiRjbHfF5pdoaHSLLe7ypUAMaHk+2j8KLBWBhi0Y6QayZhzjioAW668Vin7P/LDMLBRBZgKbjVxnFP2dOB7G2DrWUqA096YCnDQLBiB6BHgKGG72HGOAwF8Lsvy5PuRjcMAAznfBVvYBtZA4CMeAIGHgFIyCLgCgHa8PpfWVwCzY1H48TrOsQcwX4HKiLeA+q7uPUlpYfYClIDfBur4AgixtYG3g1MnVgMAcnlqWrIBAGE3kQrQWcQJ0NvUAHsy37jmJ7CazG86p6wfqH776HqSWqEHKkB31mIERkAXwKYhgBnEL3VrTwwAuC9pHSSDgBOwOroRqAkIxxGny/LaOXOcgw5gvafT1M2e67JjMk0jUwcIgUl3j3VWfqHwVw+QVLr8OsecUKkFmK/JJmmeQXybbxIfdRZxJoSYGU3V548dZcGt7nZRL5CGf+70/kJ7AELo3RjpA/q6DyQSFCo6gVnoqpRh9y5xlYyxsa/9A3+HNV7/teu0AAAAAElFTkSuQmCC"
                  onError={() => setImageError(true)}
                  preview={{
                    src: myFile.url,
                    mask: (
                      <>
                        {previewButton}
                        {downloadButton}
                        {deleteButton}
                      </>
                    ),
                  }}
                />

                <div
                  className="flex justify-center absolute left-0 bottom-0 right-0 p-1.5 bg-gray-300 rounded-b-sm h-1/3"
                  style={{
                    width: "100px",
                    maxHeight: "50%",
                    maxWidth: "100px",
                  }}
                >
                  <Tooltip title={myFile.title}>
                    <a
                      href={myFile.url}
                      target="_blank"
                      rel="noreferrer"
                      className="block text-sm italic font-light tracking-tight text-white break-words truncate"
                    >
                      {myFile.title}
                    </a>
                  </Tooltip>
                </div>
              </div>
            </>
          )}

          {imageError || file.status === "error" ? <>{deleteButton}</> : null}
        </>
      )}
    </div>
  );
};

export default FileUploaderItem;
