import { Col, Menu, Row, Tooltip } from "antd";
import React, { useState } from "react";
import { EmployeeJson } from "common/types/Employee";
import {
	BugOutlined,
	CalendarOutlined,
	ClearOutlined,
	CrownOutlined,
	FileSearchOutlined,
	FormOutlined,
	ProjectOutlined,
} from "@ant-design/icons";
import TaskMOMList from "./TaskMOMList";
import EmployeeModel from "common/models/EmployeeModel";

type Props = {
	listEmployee: EmployeeJson[];
	department_id: number;
};
const TaskMOM = ({ listEmployee, department_id }: Props) => {
	const { SubMenu } = Menu;

	//state
	const [userInfor, setUserInfor] = useState<EmployeeJson>(
		listEmployee[0] || EmployeeModel.getDefaultData()
	);

	// functions
	const getTaskOfEmployee = (emp: EmployeeJson) => {
		setUserInfor((prev) => emp);
	};

	const dataDev = [
		{
			key: "box",
			item: [3903],
			title: (
				<span>
					<CrownOutlined />
					<span>SPRINTS</span>
				</span>
			),
		},
		{
			key: "ba",
			item: [3996, 3999, 4004],
			title: (
				<span>
					<ClearOutlined />
					<span>BA</span>
				</span>
			),
		},
		{
			key: "uiux",
			item: [4025],
			title: (
				<span>
					<ClearOutlined />
					<span>UI/UX</span>
				</span>
			),
		},
		{
			key: "fe",
			item: [3907, 3910, 3993],
			title: (
				<span>
					<BugOutlined />
					<span>FRONTEND</span>
				</span>
			),
		},
		{
			key: "be",
			item: [3918, 3909, 3901, 3902],
			title: (
				<span>
					<BugOutlined />
					<span>BACKEND</span>
				</span>
			),
		},
		{
			key: "qc",
			item: [3996, 3999, 4004],
			title: (
				<span>
					<ClearOutlined />
					<span>QUALITY CONTROL</span>
				</span>
			),
		},
	];
	const dataMenu = false
		? dataDev
		: [
				{
					key: "box",
					item: [3929],
					title: (
						<span>
							<CalendarOutlined />
							<span>SPRINTS</span>
						</span>
					),
				},

				{
					key: "uiux",
					item: [4056, 4072],
					title: (
						<span>
							<ClearOutlined />
							<span>UI/UX</span>
						</span>
					),
				},
				{
					key: "fe",
					item: [3994, 4002, 4037, 4071],
					title: (
						<span>
							<BugOutlined />
							<span>FRONTEND</span>
						</span>
					),
				},
				{
					key: "be",
					item: [3965, 3957, 3998, 3963, 4075],
					title: (
						<span>
							<BugOutlined />
							<span>BACKEND</span>
						</span>
					),
				},
				{
					key: "qc",
					item: [4035],
					title: (
						<span>
							<FileSearchOutlined />
							<span>QUALITY CONTROL</span>
						</span>
					),
				},
		  ];

	//
	return (
		<>
			{listEmployee.length > 0 ? (
				<div style={{ height: "100%", marginLeft: "-24px" }}>
					<Row gutter={8}>
						<Col xxl={3} xl={3} lg={3} md={3} span={24}>
							<Menu
								style={{ height: "100%" }}
								defaultSelectedKeys={["box", "fe", "be", "qc", "uiux"]}
								defaultOpenKeys={["box", "fe", "be", "qc", "uiux"]}
								mode={"inline"}
								theme={"light"}>
								{listEmployee.length > 0 && department_id === 1223
									? dataMenu.map((data) => (
											<SubMenu key={data.key} title={data.title}>
												{data.item.map((i, index) => {
													const user = listEmployee.filter((em) => em.id === i);
													const render = () => {
														if (user.length > 0) {
															return (
																<Menu.Item
																	onClick={() => getTaskOfEmployee(user[0])}
																	key={`${data.key}+${index}`}>
																	<Tooltip
																		title={user[0].full_name}
																		placement="rightTop">
																		{user[0].full_name}
																	</Tooltip>
																</Menu.Item>
															);
														}
														return <></>;
													};
													return render();
												})}
											</SubMenu>
									  ))
									: listEmployee.map((emp, index) => (
											<Menu.Item
												onClick={() => getTaskOfEmployee(emp)}
												key={index + 1}>
												<Tooltip title={emp.full_name} placement="rightTop">
													{emp.full_name}
												</Tooltip>
											</Menu.Item>
									  ))}
							</Menu>
						</Col>
						<Col xxl={21} xl={21} lg={21} md={21} span={24}>
							<TaskMOMList userInfor={userInfor} />
						</Col>
					</Row>
				</div>
			) : null}
		</>
	);
};

export default TaskMOM;
