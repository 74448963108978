import { IconEdit, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { Button, Checkbox, Empty, List, Modal, Skeleton, message } from "antd";
import StoreCollection from "common/collections/StoreCollection";
import TagConstant from "common/constants/Tag";
import StoreModel from "common/models/StoreModel";
import TagModel from "common/models/TagModel";
import ProductRepository from "common/repositories/ProductRepository";
import StoreRepository from "common/repositories/StoreRepository";
import TagRepository from "common/repositories/TagRepository";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { FilterStore } from "common/types/Store";
import PageDataPagination from "components/page/PageDataPagination";
import useDatabaseTable from "hooks/useDatabaseTable";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
	DragDropContext,
	Draggable,
	DropResult,
	Droppable,
} from "react-beautiful-dnd";
import AddListToItemFilter from "./AddListToItemFilter";
import AddListToItemModal from "./AddListToItemModal";
import SettingModel from "common/models/SettingModel";
import SettingRepository from "common/repositories/SettingRepository";
import SettingApi from "common/constants/Setting";
import { SelectOptionNormal } from "common/types/SelectOption";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import FormEditDisplayOrder from "./FormEditDisplayOrder";
const AddListToItemComponent = ({
	type,
	process = "product",
	id,
	tags,
	existValues,
	onSaveSuccess,
	renderItems,
	handleAfterAddSuccess,
	handleRemoveItem,
	reload,
	children,
	changeExistValue,
	handleAddSkuSuccess,
	handleRemoveCurrentItem,
}: {
	process?: "display" | "product";
	type:
		| "product"
		| "tagproduct"
		| "tagproductcollection"
		| "tagstorycollection"
		| "tagstory"
		| "productintag"
		| "productrelated"
		| "tagbrand"
		| "collectionincollection";
	id: number;
	existValues: number[];
	existValuesSku?: string[];
	onSaveSuccess: any;
	renderItems: any;
	handleRemoveItem: any;
	handleAfterAddSuccess?: any;
	reload: boolean;
	children?: React.ReactNode;
	changeExistValue?: any;
	handleAddSkuSuccess?: any;
	tags?: string;
	handleRemoveCurrentItem?: any;
}) => {
	const [categoryItems] =
		useDatabaseTable<ProductCategoryJson>("productcategory");
	const defaultFilters: any = useMemo(() => {
		return type === "product"
			? {
					...ProductRepository.getDefaultFilters(),
					limit: 25,
					// list_product_id: existValues.toString(),
					collection_id: id,
			  }
			: type === "tagproduct"
			? {
					...TagRepository.getDefaultTagDetailFilters(),
					limit: 50,
					resource_id: id,
					resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
					is_root: -1,
			  }
			: type === "tagproductcollection"
			? {
					...TagRepository.getDefaultTagDetailFilters(),
					limit: 50,
					resource_id: id,
					resource_type: TagConstant.RESOURCE_TYPE.PRODUCT_COLLECTION,
					is_root: -1,
			  }
			: type === "tagstorycollection"
			? {
					...TagRepository.getDefaultTagDetailFilters(),
					limit: 50,
					resource_id: id,
					resource_type: TagConstant.RESOURCE_TYPE.STORY_COLLECTION,
					is_root: -1,
			  }
			: type === "productintag"
			? {
					...ProductRepository.getDefaultFilters(),
					limit: 25,
					// list_product_id: existValues.toString(),
					tags: tags,
			  }
			: type === "tagbrand"
			? {
					...TagRepository.getDefaultTagDetailFilters(),
					limit: 50,
					resource_id: id,
					resource_type: TagConstant.RESOURCE_TYPE.BRAND,
					is_root: -1,
			  }
			: type === "collectionincollection"
			? {
					...ProductCollectionRepository.getDefaultFilters(),
					limit: 50,
					parent_id: id,
			  }
			: {
					...TagRepository.getDefaultTagDetailFilters(),
					limit: 50,
					resource_id: id,
					resource_type: TagConstant.RESOURCE_TYPE.STORY,
					is_root: -1,
			  };
	}, [existValues]);

	//state
	const [open, setOpen] = useState<boolean>(false);
	const [dataSource, setDataSouce] = useState<any[]>([]);
	const [filters, setFilters] = useState(defaultFilters);
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [btnTitle, setBtnTitle] = useState<string>("Thêm tag mới");
	const [selectedValue, setSelectedValue] = useState<SelectOptionNormal[]>([]);
	const [currentValue, setCurrentValue] = useState<number[]>([]);
	const [btnDeleteLoading, setBtnDeleteLoading] = useState<boolean>(false);

	const [openDisplay, setOpenDisplay] = useState<boolean>(false);

	//fetch store list
	const defaultFiltersStore: FilterStore = useMemo(
		() => StoreRepository.getDefaultFilters(),
		[]
	);
	const [filtersStore, setFiltersStore] =
		useState<FilterStore>(defaultFiltersStore);
	const [storeList, setStoreList] = useState<StoreModel[]>([]);
	const [storeValue, setStoreValue] = useState<SettingModel>(
		new SettingModel(SettingModel.getDefaultData())
	);

	//fetchDataStore
	const fetchDataStoreId = useCallback(async (): Promise<SettingModel> => {
		let collection = await new SettingRepository().getItem(
			SettingApi.KEY.k_setting_id_store_web_shop,
			""
		);
		if (!collection.hasError()) {
			const convertId = JSON.parse(collection.value);
			setStoreList([
				new StoreModel({ ...StoreModel.getDefaultData(), id: convertId }),
			]);
		}
		return collection;
	}, [filtersStore]);

	const fetchDataOptions = useCallback(async (): Promise<void> => {
		try {
			setLoading(true);

			if (type === "product") {
				if (storeList.length > 0) {
					let collection = await new ProductRepository().getItems(
						{
							filters: {
								...filters,
								store_id: storeList[0].id,
							},
						},
						categoryItems
					);
					if (collection.hasError()) {
						setError(collection.error.errors[0]);
					} else {
						changeExistValue && changeExistValue(collection.items);
						const convertArray: any[] = collection.items.map((item) => {
							return { ...item, id: item.id + "" };
						});
						setDataSouce([...convertArray]);
						setTotal(collection.total);
					}
				} else {
					await fetchDataStoreId();
				}
			} else if (type === "collectionincollection") {
				let collection = await new ProductCollectionRepository().getItems({
					filters: { ...filters },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
					message.error(collection.error.errors[0]);
				} else {
					changeExistValue && changeExistValue(collection.items);
					setCurrentValue(collection.items.map((item) => item.id));
					const convertArray: any[] = collection.items.map((item) => {
						return { ...item, id: item.id + "" };
					});
					setDataSouce([...convertArray]);
					setTotal(collection.total);
				}
			} else if (type === "tagproduct") {
				let collection = await new TagRepository().getItemsDetail({
					filters: { ...filters, resource_id: id },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
					message.error(collection.error.errors[0]);
				} else {
					changeExistValue && changeExistValue(collection.items);
					setCurrentValue(collection.items.map((item) => item.id));
					const convertArray: any[] = collection.items.map((item) => {
						return { ...item, id: item.id + "" };
					});
					setDataSouce([...convertArray]);
					setTotal(collection.total);
				}
			} else if (type === "tagproductcollection") {
				let collection = await new TagRepository().getItemsDetail({
					filters: { ...filters, resource_id: id },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
					message.error(collection.error.errors[0]);
				} else {
					changeExistValue && changeExistValue(collection.items);
					setCurrentValue(collection.items.map((item) => item.id));
					const convertArray: any[] = collection.items.map((item) => {
						return { ...item, id: item.id + "" };
					});
					setDataSouce([...convertArray]);
					setTotal(collection.total);
				}
			} else if (type === "tagstorycollection") {
				let collection = await new TagRepository().getItemsDetail({
					filters: { ...filters, resource_id: id },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
					message.error(collection.error.errors[0]);
				} else {
					changeExistValue && changeExistValue(collection.items);
					setCurrentValue(collection.items.map((item) => item.id));
					const convertArray: any[] = collection.items.map((item) => {
						return { ...item, id: item.id + "" };
					});
					setDataSouce([...convertArray]);
					setTotal(collection.total);
				}
			} else if (type === "tagbrand") {
				let collection = await new TagRepository().getItemsDetail({
					filters: { ...filters, resource_id: id },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
					message.error(collection.error.errors[0]);
				} else {
					changeExistValue && changeExistValue(collection.items);
					setCurrentValue(collection.items.map((item) => item.id));
					const convertArray: any[] = collection.items.map((item) => {
						return { ...item, id: item.id + "" };
					});
					setDataSouce([...convertArray]);
					setTotal(collection.total);
				}
			} else if (type === "tagstory") {
				let collection = await new TagRepository().getItemsDetail({
					filters: { ...filters, resource_id: id },
				});
				if (collection.hasError()) {
					setError(collection.error.errors[0]);
					message.error(collection.error.errors[0]);
				} else {
					changeExistValue && changeExistValue(collection.items);
					setCurrentValue(collection.items.map((item) => item.id));
					const convertArray: any[] = collection.items.map((item) => {
						return { ...item, id: item.id + "" };
					});
					setDataSouce([...convertArray]);
					setTotal(collection.total);
				}
			} else if (type === "productintag") {
				if (storeList.length > 0) {
					let collection = await new ProductRepository().getItems(
						{
							filters: {
								...filters,
								store_id: storeList[0].id,
							},
						},
						categoryItems
					);

					if (collection.hasError()) {
						setError(collection.error.errors[0]);
					} else {
						changeExistValue && changeExistValue(collection.items);
						const convertArray: any[] = collection.items.map((item) => {
							return { ...item, id: item.id + "" };
						});
						setDataSouce([...convertArray]);
						setTotal(collection.total);
					}
				} else {
					await fetchDataStoreId();
				}
			}
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	}, [filters, existValues, storeList]);

	const handleEdit = (id: number) => {
		setOpenDisplay(true);
	};

	///////////////////////////////

	useEffect(() => {
		if (type === "product" || type === "productintag") {
			if (storeList.length === 0) {
				fetchDataStoreId();
			} else {
				// if (existValues.length > 0) {
				fetchDataOptions();
				// }
			}
		} else if (
			type === "tagproduct" ||
			type === "tagproductcollection" ||
			type === "tagstorycollection" ||
			type === "tagstory" ||
			type === "tagbrand" ||
			type === "collectionincollection"
		) {
			fetchDataOptions();
		}
	}, [filters, reload, storeList]);

	const renderCheckboxAll = () => {
		if (dataSource.length > 0) {
			if (type === "productintag") {
				return (
					<>
						<Checkbox
							checked={
								selectedValue.length === existValues.length &&
								existValues.length > 0
							}
							disabled={existValues.length === 0}
							onChange={(e) => {
								if (!e.target.checked) {
									//untick
									setSelectedValue([]);
								} else {
									//tick
									setSelectedValue(
										dataSource
											.filter(
												(item) =>
													existValues.findIndex((tmp) => tmp == item.id) >= 0
											)
											.map((pro) => {
												return {
													label: pro.name,
													value: pro.id,
												};
											})
									);
								}
							}}>
							{" "}
							Chọn tất cả
						</Checkbox>
					</>
				);
			} else if (type === "product") {
				return (
					<>
						<Checkbox
							checked={
								selectedValue.length === dataSource.length &&
								dataSource.length > 0
							}
							disabled={dataSource.length === 0}
							onChange={(e) => {
								if (!e.target.checked) {
									//untick
									setSelectedValue([]);
								} else {
									//tick
									if (["product"].includes(type)) {
										setSelectedValue(
											dataSource.map((product: any) => {
												return {
													label:
														product.full_name && product.full_name.length > 0
															? product.full_name
															: product.name,
													value: product.id,
												};
											})
										);
									}
								}
							}}>
							{" "}
							Chọn tất cả
						</Checkbox>
					</>
				);
			} else if (type === "collectionincollection") {
				return (
					<>
						<Checkbox
							checked={
								selectedValue.length === dataSource.length &&
								dataSource.length > 0
							}
							disabled={dataSource.length === 0}
							onChange={(e) => {
								if (!e.target.checked) {
									//untick
									setSelectedValue([]);
								} else {
									//tick
									if (["collectionincollection"].includes(type)) {
										setSelectedValue(
											dataSource.map((col: any) => {
												return {
													label: col.title,
													value: col.id,
												};
											})
										);
									}
								}
							}}>
							{" "}
							Chọn tất cả
						</Checkbox>
					</>
				);
			} else if (!["productintag", "productrelated"].includes(type)) {
				return (
					<>
						<Checkbox
							checked={
								selectedValue.length ===
									dataSource.filter(
										(item) => existValues.findIndex((tmp) => tmp == item.id) < 0
									).length &&
								dataSource.filter(
									(item) => existValues.findIndex((tmp) => tmp == item.id) < 0
								).length > 0
							}
							disabled={
								dataSource.filter(
									(item) => existValues.findIndex((tmp) => tmp == item.id) < 0
								).length === 0
							}
							onChange={(e) => {
								if (!e.target.checked) {
									//untick
									setSelectedValue([]);
								} else {
									//tick
									if (
										!["product", "productintag", "productrelated"].includes(
											type
										)
									) {
										setSelectedValue(
											dataSource
												.filter(
													(item) =>
														existValues.findIndex((tmp) => tmp == item.id) < 0
												)
												.map((tagdetail: any) => {
													return {
														label:
															tagdetail.tag.length > 0
																? tagdetail.tag[0].name
																: "",
														value: tagdetail.id,
													};
												})
										);
									}
								}
							}}>
							{" "}
							Chọn tất cả
						</Checkbox>
					</>
				);
			} else {
				return <></>;
			}
		} else {
			return <></>;
		}
	};

	const renderListItem = (item: any): ReactNode => {
		if (type === "product" && existValues.includes(Number(item.id))) {
			return (
				<>
					<List.Item className="w-full block">
						<div className="flex justify-between items-center w-full">
							<div className="flex gap-2 items-center">
								<Checkbox
									checked={
										selectedValue.findIndex(
											(select) => select.value == item.id
										) >= 0
									}
									onChange={(e) => {
										if (!e.target.checked) {
											//untick
											if (!["productintag", "productrelated"].includes(type)) {
												let cloneSelectOptions = [...selectedValue];
												const findIndex = cloneSelectOptions.findIndex(
													(tmp) => tmp.value == item.id
												);
												if (findIndex >= 0) {
													cloneSelectOptions.splice(findIndex, 1);
													setSelectedValue(cloneSelectOptions);
												}
											}
										} else {
											//tick
											if (!["productintag", "productrelated"].includes(type)) {
												let cloneSelectOptions = [...selectedValue];
												cloneSelectOptions.push({
													label:
														item.full_name && item.full_name.length > 0
															? item.full_name
															: item.name,
													value: item.id,
												});
												console.log(cloneSelectOptions);
												setSelectedValue(cloneSelectOptions);
											}
										}
									}}
								/>
								{renderItems(item)}
							</div>
							<div className="flex-1 basis-[100px] shrink-0 text-right flex w-full gap-2 justify-end">
								{process === "display" && (
									<IconEdit
										onClick={() => {
											handleEdit(item.id);
										}}
										className="cursor-pointer hover:text-red-500"
									/>
								)}

								{process === "product" && (
									<IconX
										onClick={() => {
											handleRemoveItem({
												label: item.name,
												value: item.id,
											});
										}}
										className="min-w-6 min-h-6 w-6 h-6 max-h-6 max-w-6 hover:text-red-500 cursor-pointer"
									/>
								)}
							</div>
						</div>
					</List.Item>
				</>
			);
		} else if (
			type === "productintag" &&
			existValues.includes(Number(item.id))
		) {
			return (
				<>
					<List.Item className="w-full block">
						<div className="flex justify-between items-center w-full">
							<div className="flex gap-2 items-center">
								<Checkbox
									checked={
										selectedValue.findIndex((tmp) => tmp.value === item.id) >= 0
									}
									onChange={(e) => {
										if (!e.target.checked) {
											const findIndex = selectedValue.findIndex(
												(tmp) => tmp.value === item.id
											);
											if (findIndex >= 0) {
												const cloneArray = [...selectedValue];
												cloneArray.splice(findIndex, 1);
												setSelectedValue(cloneArray);
											}
										} else {
											setSelectedValue([
												...selectedValue,
												{ label: item.name, value: item.id },
											]);
										}
									}}
								/>
								{renderItems(item)}
							</div>

							<IconX
								onClick={() => {
									handleRemoveCurrentItem &&
										handleRemoveCurrentItem([
											{ label: item.name, value: item.id },
										]);
								}}
								className="hover:text-red-500 cursor-pointer min-w-6 min-h-6 w-6 h-6 max-h-6 max-w-6"
							/>
						</div>
					</List.Item>
				</>
			);
		} else if (
			type === "tagproduct" ||
			type === "tagproductcollection" ||
			type === "tagstorycollection" ||
			type === "tagstory" ||
			type === "tagbrand"
		) {
			return (
				<>
					<List.Item className="w-full block">
						<div className="flex justify-between items-center w-full min-h-[50px]">
							<div className="flex gap-2 items-center">
								<Checkbox
									checked={
										selectedValue.findIndex(
											(select) => select.value == item.id
										) >= 0
									}
									onChange={(e) => {
										if (!e.target.checked) {
											//untick
											if (
												!["product", "productintag", "productrelated"].includes(
													type
												)
											) {
												let cloneSelectOptions = [...selectedValue];
												const findIndex = cloneSelectOptions.findIndex(
													(tmp) => tmp.value == item.id
												);
												if (findIndex >= 0) {
													cloneSelectOptions.splice(findIndex, 1);
													setSelectedValue(cloneSelectOptions);
												}
											}
										} else {
											//tick
											if (
												!["product", "productintag", "productrelated"].includes(
													type
												)
											) {
												let cloneSelectOptions = [...selectedValue];
												cloneSelectOptions.push({
													label: item.tag.length > 0 ? item.tag[0].name : "",
													value: item.id,
												});
												console.log(cloneSelectOptions);
												setSelectedValue(cloneSelectOptions);
											}
										}
									}}
								/>
								{type === "tagproduct" ||
								type === "tagproductcollection" ||
								type === "tagstorycollection" ||
								type === "tagstory" ||
								type === "tagbrand"
									? item.tag.length > 0 &&
									  renderItems(
											new TagModel({
												...TagModel.getDefaultData(),
												name: item.tag[0].name,
												code: item.tag[0].code,
												value: item.tag[0].value,
												id: item.tag_id,
												type: item.tag_type,
											})
									  )
									: renderItems(item)}
							</div>

							<IconX
								onClick={() => {
									afterRemoveCurrent([
										{
											label: item.name,
											value: item.id,
										},
									]);
								}}
								className="hover:text-red-500 cursor-pointer min-w-6 min-h-6 w-6 h-6 max-h-6 max-w-6"
							/>
						</div>
					</List.Item>
				</>
			);
		} else if (type === "collectionincollection") {
			return (
				<>
					<List.Item className="w-full block">
						<div className="flex justify-between items-center w-full">
							<div className="flex gap-2 items-center">
								<Checkbox
									checked={
										selectedValue.findIndex(
											(select) => select.value == item.id
										) >= 0
									}
									onChange={(e) => {
										if (!e.target.checked) {
											//untick
											if (!["productintag", "productrelated"].includes(type)) {
												let cloneSelectOptions = [...selectedValue];
												const findIndex = cloneSelectOptions.findIndex(
													(tmp) => tmp.value == item.id
												);
												if (findIndex >= 0) {
													cloneSelectOptions.splice(findIndex, 1);
													setSelectedValue(cloneSelectOptions);
												}
											}
										} else {
											//tick
											if (!["productintag", "productrelated"].includes(type)) {
												let cloneSelectOptions = [...selectedValue];
												cloneSelectOptions.push({
													label: item.title,
													value: item.id,
												});
												setSelectedValue(cloneSelectOptions);
											}
										}
									}}
								/>
								{renderItems(item)}
							</div>

							<IconX
								onClick={() => {
									handleRemoveItem({
										label: item.name,
										value: item.id,
									});
								}}
								className="min-w-6 min-h-6 w-6 h-6 max-h-6 max-w-6 hover:text-red-500 cursor-pointer"
							/>
						</div>
					</List.Item>
				</>
			);
		} else {
			return <></>;
		}
	};

	const handleDragEnd = async (result: DropResult) => {
		if (!result.destination) return;

		const chooseItem = dataSource.find(
			(item) => item.id === result.draggableId
		);
		const destinationItem = { ...dataSource[result.destination.index] };
		if (chooseItem && destinationItem) {
			// const response = await new TagRepository().updateTagDetailDisplay({
			//   display_order: destinationItem.display_order,
			//   tagdetail_id: Number(chooseItem.id),
			// });
			const reorderedItems = Array.from(dataSource);
			const [removed] = reorderedItems.splice(result.source.index, 1);
			reorderedItems.splice(result.destination.index, 0, removed);

			setDataSouce(reorderedItems);
		}
	};

	const afterRemoveCurrent = async (selectedValue: SelectOptionNormal[]) => {
		setBtnDeleteLoading(true);
		const response = await handleRemoveCurrentItem(selectedValue);
		if (response) {
			setSelectedValue([]);
		}
		setBtnDeleteLoading(false);
	};

	useEffect(() => {
		if (type === "product" || type === "productintag") {
			setBtnTitle("Gắn sản phẩm");
		} else if (type === "collectionincollection") {
			setBtnTitle("Gắn danh mục");
		} else {
			setBtnTitle("Gắn tag mới");
		}
	}, [type]);

	//clear checkbox
	useEffect(() => {
		if (dataSource.length === 0) {
			setSelectedValue([]);
		}
	}, [dataSource]);

	return (
		<div>
			<div className="flex justify-between">
				<AddListToItemFilter
					defaultFilters={defaultFilters}
					filters={filters}
					setFilters={setFilters}
					total={total}
					type={type}
				/>
				{process === "product" && (
					<div>
						{children ? (
							<div
								onClick={(e) => {
									setOpen(true);
								}}>
								{children}
							</div>
						) : (
							<Button
								onClick={() => {
									setOpen(true);
								}}
								icon={
									<IconPlus
										size={16}
										stroke={3}
										className="-mt-0.5 hidden sm:inline-block"
									/>
								}
								type="primary">
								{btnTitle}
							</Button>
						)}
					</div>
				)}
			</div>
			<div className="flex justify-between items-center">
				{renderCheckboxAll()}

				{selectedValue.length > 0 && (
					<Button
						type="default"
						loading={btnDeleteLoading}
						disabled={btnDeleteLoading}
						onClick={() => afterRemoveCurrent(selectedValue)}
						icon={
							<IconTrash
								className="text-gray-400 hover:text-red-500"
								size="18"
							/>
						}></Button>
				)}
			</div>
			{/**List component */}
			{!loading ? (
				<>
					{dataSource.length ? (
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="droppable">
								{(provided) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										className="flex flex-col gap-2">
										{dataSource.map((item, index) => (
											<Draggable
												key={item.id}
												draggableId={item.id}
												index={index}>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className="shadow-sm"
														style={{
															userSelect: "none",
															backgroundColor: "white",
															...provided.draggableProps.style,
														}}>
														{renderListItem(item)}
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					) : (
						<Empty />
					)}
					{dataSource.length > 0 && (
						<PageDataPagination
							dataSource={dataSource}
							filters={filters}
							setFilters={setFilters}
							total={total}
						/>
					)}
					{/* {Number(filters.page) * Number(filters.limit) < total ? (
            <>
              <Button
                className="cursor-pointer"
                onClick={() => {
                  setFilters({
                    ...filters,
                    page: Number(filters.page) + 1,
                  });
                }}
              >
                Xem thêm
              </Button>
            </>
          ) : (
            <>
              <p
                className="text-sm text-gray-500 cursor-pointer"
                onClick={() => {
                  setFilters({
                    ...filters,
                    page: 1,
                  });
                }}
              >
                Ẩn đi
              </p>
            </>
          )} */}
				</>
			) : (
				<Skeleton active />
			)}

			{/** */}
			<AddListToItemModal
				type={type}
				reload={reload}
				key={id}
				id={id}
				store_id={storeList.length > 0 ? storeList[0].id : -1}
				open={open}
				setOpen={setOpen}
				existValues={
					type === "tagproduct" ||
					type === "tagproductcollection" ||
					type === "tagstorycollection" ||
					type === "tagstory"
						? dataSource.map((item) => item.tag_id)
						: existValues
				}
				existValuesSku={dataSource.map((item) => item.sku)}
				onSaveSuccess={onSaveSuccess}
				renderItems={renderItems}
				handleRemoveItem={handleRemoveItem}
				handleAfterAddSuccess={handleAfterAddSuccess}
				handleAddSkuSuccess={handleAddSkuSuccess}
			/>
			<Modal
				title="Thay đổi ưu tiên hiển thị"
				okButtonProps={{ className: "hidden" }}
				open={openDisplay}
				onCancel={() => {
					setOpenDisplay(false);
				}}>
				<FormEditDisplayOrder
					initialValue={{ display_order: 0, id: 1 }}></FormEditDisplayOrder>
			</Modal>
		</div>
	);
};

export default AddListToItemComponent;
