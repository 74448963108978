import Project from 'common/constants/Project';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';
import ProjectTaskModel from './ProjectTaskModel';

import type {
  ProjectJson,
  RolePermissionJson,
  ProjectReportJson,
  ProjectPermissionJson,
} from "common/types/Project";
class ProjectModel extends BaseModel implements BaseModelJson<ProjectJson> {
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  color: string;
  complete_percent: number;
  count_activity: number;
  count_bug: number;
  count_member: number;
  count_story: number;
  description: string;
  development_team: number[];
  duration: number;
  is_completed: number;
  is_disabled: number;
  is_scrum: number;
  members: string[];
  role_permission: RolePermissionJson;
  scrum_master: number[];
  status: number;
  story_prefix: string;
  date_completed_plan?: number;
  date_created?: number;
  date_modified?: number;
  date_started?: number;

  constructor(json: ProjectJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.color = json.color || "";
    this.complete_percent = json.complete_percent || 0;
    this.count_activity = json.count_activity || 0;
    this.count_bug = json.count_bug || 0;
    this.count_member = json.count_member || 0;
    this.count_story = json.count_story || 0;
    this.description = json.description || "";
    this.development_team = json.development_team || [];
    this.duration = json.duration || 0;
    this.is_completed = json.is_completed || 0;
    this.is_scrum = json.is_scrum || 0;
    this.is_disabled = json.is_disabled || 0;
    this.members = json.members || [];
    this.role_permission =
      json.role_permission || ProjectModel.getDefaultRolePermissionData();
    this.scrum_master = json.scrum_master || 0;
    this.status = json.status || 0;
    this.story_prefix = json.story_prefix || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_completed_plan = json.date_completed_plan || 0;
    this.date_started = json.date_started || 0;
  }

  static getDefaultRolePermissionData(): RolePermissionJson {
    return {
      development_team_add_story: 0,
      scrum_master_add_story: 0,
    };
  }

  static getDefaultData(): ProjectJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      color: "",
      complete_percent: 0,
      count_activity: 0,
      count_bug: 0,
      count_member: 0,
      count_story: 0,
      description: "",
      development_team: [],
      duration: 0,
      is_completed: 0,
      is_disabled: 0,
      is_scrum: 0,
      members: [],
      role_permission: ProjectModel.getDefaultRolePermissionData(),
      scrum_master: [],
      status: 0,
      story_prefix: "",
      date_completed_plan: 0,
      date_created: 0,
      date_modified: 0,
      date_started: 0,
    };
  }

  static getDefaultDataReport(): ProjectReportJson {
    return {
      project: ProjectTaskModel.getDefaultDataTotalProjectTask(),
      uncomplete_task: ProjectTaskModel.getDefaultDataUnCompleteProjectTask(),
      expired_task: ProjectTaskModel.getDefaultDataExpiredProjectTask(),
      total: 0,
      tasknotcompletecount: 0,
      taskexpiredcount: 0,
      items: [],
    };
  }

  static getDefaultDataPermission(): ProjectPermissionJson {
    return {
      canadd: false,
      candelete: false,
      canedit: false,
    };
  }

  toJson(): ProjectJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      color: this.color,
      complete_percent: this.complete_percent,
      count_activity: this.count_activity,
      count_bug: this.count_bug,
      count_member: this.count_member,
      count_story: this.count_story,
      description: this.description,
      development_team: this.development_team,
      duration: this.duration,
      is_completed: this.is_completed,
      is_disabled: this.is_disabled,
      is_scrum: this.is_scrum,
      members: this.members,
      role_permission: this.role_permission,
      scrum_master: this.scrum_master,
      status: this.status,
      story_prefix: this.story_prefix,
      date_completed_plan: this.date_completed_plan,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_started: this.date_started,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: Project.STATUS_ENABLE,
        label: i18n.t("project:STATUS_ENABLE"),
        color: "yellow",
      },
      {
        value: Project.STATUS_WORKING,
        label: i18n.t("project:STATUS_WORKING"),
        color: "blue",
      },
      {
        value: Project.STATUS_COMPLETED,
        label: i18n.t("project:STATUS_COMPLETED"),
        color: "green",
      },
      {
        value: Project.STATUS_DISABLE,
        label: i18n.t("project:STATUS_DISABLE"),
        color: "gray",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: Project.TYPE_NORMAL,
        label: i18n.t("project:TYPE_NORMAL"),
      },
      {
        value: Project.TYPE_NORMAL,
        label: i18n.t("project:TYPE_NORMAL"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }
}

export default ProjectModel;
