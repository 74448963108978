import { GetListProps } from "common/types/Filter";
import StoryCollectionApi from "common/api/StoryCollectionApi";
import {
  FilterStoryCollection,
  StoryCollectionAddEditJson,
  StoryCollectionDetailAddEditJson,
} from "common/types/StoryCollection";
import StoryCollectionCollection from "common/collections/StoryCollectionCollection";
import StoryCollectionModel from "common/models/StoryCollectionModel";

import BaseRepository from "./BaseRepository";

class StoryCollectionRepository extends BaseRepository<StoryCollectionApi> {
  _api: StoryCollectionApi | null;

  constructor() {
    super();
    this._api = new StoryCollectionApi(true);
  }

  static getDefaultFilters(): FilterStoryCollection {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      publish_from: -1,
      publish_to: -1,
      date_published: -1,
      pin: -1,
      tags: "",
      parent_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterStoryCollection>) {
    return this._api
      ? await this._api.getList(props)
      : new StoryCollectionCollection();
  }

  async getItem(id: number): Promise<StoryCollectionModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new StoryCollectionModel(StoryCollectionModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: StoryCollectionAddEditJson
  ): Promise<StoryCollectionModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new StoryCollectionModel(StoryCollectionModel.getDefaultData());
    }
  }

  //storycollection detail
  async saveRemoteDetail(
    data: StoryCollectionDetailAddEditJson
  ): Promise<StoryCollectionModel> {
    if (this._api) {
      return await this._api.addStoryCollectionDetail(data);
    } else {
      return new StoryCollectionModel(StoryCollectionModel.getDefaultData());
    }
  }
}

export default StoryCollectionRepository;
