import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const LabelDateFormatHelper = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <span className={className || "ml-1 text-gray-500"}>
      ({t("common:date_format_prefix")}{" "}
      <Tooltip title={t("common:date_format_example")}>
        {t("common:date_format")}
      </Tooltip>
      )
    </span>
  );
};

export default LabelDateFormatHelper;
