import IdFetcherCollection from 'common/collections/IdFetcherCollection';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

import type { FetchingRequest } from "common/types/IdFetcher";

const SERVICE_URL = "/idfetchers";

class IdFetcherApi extends BaseApi {
  async doFetching(props: FetchingRequest): Promise<IdFetcherCollection> {
    let collection = new IdFetcherCollection();

    try {
      let queryData = {
        params: {
          ids: props.id_list.join(","),
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/" + props.object_type,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default IdFetcherApi;
