import React, { useCallback, useMemo, useState, useEffect } from "react";
import WorkChatChannelsFormModal from "../form/channel/WorkChatChannelsFormModal";
import WorkChat from "../common/constants/WorkChat";
import WorkChatModel from "../common/models/WorkChatModel";
import WorkChatRepository from "../common/respositories/WorkChatRepository";
import update from "immutability-helper";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";
import { useTranslation } from "react-i18next";
import { FilterWorkChat } from "../common/types/WorkChat";
import { Modal, Tooltip, Dropdown, Menu } from "antd";
import {
  IconHash,
  IconLock,
  IconHome,
  IconSearch,
  IconSortDescending,
  IconNotebook,
  IconEdit,
  IconDotsVertical,
} from "@tabler/icons-react";
import WorkChatDirectFormModal from "../form/direct/WorkChatDirectFormModal";

const WorkChatChannelList = () => {
  const { t } = useTranslation();

  //Tranlate usetate TRANSLATE USESTATE
  const [isHovered, setIsHovered] = useState(false);

  //FILTERING
  const defaultFilters: FilterWorkChat = useMemo(
    () => WorkChatRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterWorkChat>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //FETCH DATA FORM THIS COLLECTIONS
  const [dataSource, setDataSource] = useState<WorkChatModel[]>([]);
  const [total, setTotal] = useState<number>(0);

  const fetchData = useCallback(async (): Promise<void> => {
    let collection = await new WorkChatRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // MODAL EDITING
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  const handleClick = () => {
    setAddVisible(true);
    setEditingId(0);
  };

  const [addDirectVisible, setAddDirectVisible] = useState(false);

  const handleDirectClick = () => {
    setAddDirectVisible(true);
  };

  const onSaveSuccess = (item: WorkChatModel) => {
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        })
      );
    }
  };

  //Edit item

  function handleEditItem(id: number) {
    const item = dataSource.find((item) => item.id === id);
    if (item) {
      setAddVisible(true);
      setEditingId(id);
    }
  }

  //Delete item
  const workChatRepo = new WorkChatRepository();

  function handleDeleteItem(id: number) {
    Modal.confirm({
      title: "Bạn chắc chắn muốn xoá channel này?",
      okText: "Đồng ý",
      cancelText: "Huỷ",
      onOk: () => {
        workChatRepo.deleteItem(id).then(() => {
          setDataSource(dataSource.filter((item) => item.id !== id));
        });
      },
    });
  }

  //Item Create
  const menuItemsCreate = (
    <div>
      <Menu>
        <div className="menu-title px-8 pt-3 pb-1 font-bold">Create new</div>
        <Menu.Item key="1" onClick={handleClick}>
          Channel
        </Menu.Item>
        <Menu.Item key="2">Team</Menu.Item>
        <Menu.Item key="3" onClick={handleDirectClick}>
          Direct Messages
        </Menu.Item>
        <Menu.Item key="4">Discussion</Menu.Item>
      </Menu>
    </div>
  );

  //Item Channel
  const menuItemsChannel = (item: WorkChatModel) => (
    <div>
      <Menu>
        <Menu.Item key="1">
          <a
            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={() => handleEditItem(item.id)}
          >
            Edit
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a
            className=" px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={() => handleDeleteItem(item.id)}
          >
            Delete
          </a>
        </Menu.Item>
      </Menu>
    </div>
  );

  // Tách các phần tử của mảng `dataSource` thành hai mảng khác nhau dựa trên giá trị của thuộc tính `mode`
  const privateItems = dataSource.filter(
    (item: WorkChatModel) => item.mode === WorkChat.MODE_PRIVATE
  );
  const publicItems = dataSource.filter(
    (item: WorkChatModel) => item.mode === WorkChat.MODE_PUBLIC
  );

  return (
    <div className="flex flex-col flex-shrink-0 w-64 border-r border-gray-300 bg-gray-100 overflow-y-auto overflow-x-hidden">
      {/* Button Top Left  */}
      <div className="w-64 h-16 flex justify-around items-center border-b py-4 px-4">
        <a
          className="relative w-6 h-6 rounded bg-gray-400 hover:bg-gray-500 mr-2"
          href="#"
        >
          <span className="absolute w-3 h-3 rounded-full bg-blue-400 bottom-0 right-0 -mr-1 -mb-1"></span>
        </a>
        <div className="button-icon flex flex-grow">
          {/* <!-- Button Home --> */}
          <Tooltip placement="bottom" title="Home">
            <button
              data-tooltip-target="tooltip-home"
              type="button"
              className="bg-transparent hover:bg-gray-300 rounded group"
            >
              <IconHome className="w-5 h-5" />
            </button>
          </Tooltip>

          {/* <!-- Button Search --> */}
          <Tooltip title="Search" placement="bottom">
            <button
              data-tooltip-target="tooltip-seacrch"
              type="button"
              className="bg-transparent hover:bg-gray-300 rounded group"
            >
              <IconSearch className="w-5 h-5" />
            </button>
          </Tooltip>

          {/* <!-- Button Directory --> */}
          <Tooltip title="Directory" placement="bottom">
            <button
              data-tooltip-target="tooltip-directory"
              type="button"
              className="bg-transparent hover:bg-gray-300 rounded group"
            >
              <IconNotebook className="w-5 h-5" />
            </button>
          </Tooltip>

          {/* <!-- Button Sort --> */}
          <Tooltip title="Display" placement="bottom">
            <button
              data-tooltip-target="tooltip-directory"
              type="button"
              className="bg-transparent hover:bg-gray-300 rounded group"
            >
              <IconSortDescending className="w-5 h-5" />
            </button>
          </Tooltip>

          {/* <!-- Button Create --> */}
          <Tooltip title="Create" placement="bottom">
            <Dropdown overlay={menuItemsCreate} trigger={["click"]}>
              <button
                data-tooltip-target="tooltip-directory"
                type="button"
                className="bg-transparent hover:bg-gray-300 rounded group ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <IconEdit className="w-5 h-5" />
              </button>
            </Dropdown>
          </Tooltip>

          {/* <!-- Button Administration --> */}

          <Tooltip title="Administration" placement="bottom">
            <button
              data-tooltip-target="tooltip-directory"
              type="button"
              className="bg-transparent hover:bg-gray-300 rounded group"
            >
              <IconDotsVertical className="w-5 h-5" />
            </button>
          </Tooltip>
        </div>
      </div>

      {/* ////////////////////////////////////////////////////////////////////////////////////////// */}

      {/* Teams */}
      <div className="font-medium flex items-center h-8 hover:bg-gray-300 text-sm px-3">
        Teams
      </div>

      {/* Channels  */}
      <div className="font-medium flex items-center h-8 text-sm px-3">
        Channels ({total})
      </div>
      <div id="channels_list">
        <>
          {/* PRIVATE CHANNEL */}
          <div className="px-3">
            {privateItems.length > 0 && (
              <p className="mb-0 font-bold text-gray-500 text-xs">Private</p>
            )}
            {privateItems.map((item: WorkChatModel) => (
              <div key={item.id}>
                <a
                  className="flex justify-between items-center h-8 hover:bg-gray-300 hover:translate-x-0 text-black hover:text-black text-sm overflow-hidden"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <span className="ml-2 leading-none focus:font-bold">
                    {item.mode === WorkChat.MODE_PUBLIC && (
                      <>
                        <IconHash strokeWidth={1} width={16} height={16} />
                      </>
                    )}
                    {item.mode === WorkChat.MODE_PRIVATE && (
                      <>
                        <IconLock strokeWidth={1} width={16} height={16} />
                      </>
                    )}
                    {item.name}
                  </span>
                  <Tooltip title="Options" placement="top">
                    <Dropdown
                      overlay={() => menuItemsChannel(item)}
                      trigger={["click"]}
                    >
                      <button
                        className={`transition duration-200 ease-out hover:ease-in bg-transparent hover:bg-white rounded ant-dropdown-link  ${
                          isHovered ? "translate-x-0" : "translate-x-10"
                        }`}
                        onClick={(e) => e.preventDefault()}
                      >
                        <IconDotsVertical
                          className=" w-4 h-4 gray-500 tooltip tooltip-open"
                          data-tip="secondary"
                        />
                      </button>
                    </Dropdown>
                  </Tooltip>
                </a>
              </div>
            ))}
          </div>

          {/* PUBLIC */}
          <div className="px-3">
            {publicItems.length > 0 && (
              <p className="mb-0 font-bold text-gray-500 text-xs">Public</p>
            )}
            {publicItems.map((item: WorkChatModel) => (
              <div key={item.id}>
                <a
                  className="flex justify-between items-center h-8 hover:bg-gray-300 hover:translate-x-0 text-black hover:text-black text-sm overflow-hidden"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <span className="ml-2 leading-none focus:font-bold">
                    {item.mode === WorkChat.MODE_PUBLIC && (
                      <>
                        <IconHash strokeWidth={1} width={16} height={16} />
                      </>
                    )}
                    {item.mode === WorkChat.MODE_PRIVATE && (
                      <>
                        <IconLock strokeWidth={1} width={16} height={16} />
                      </>
                    )}
                    {item.name}
                  </span>
                  <Tooltip title="Options" placement="top">
                    <Dropdown
                      overlay={() => menuItemsChannel(item)}
                      trigger={["click"]}
                    >
                      <button
                        className={`transition duration-200 ease-out hover:ease-in bg-transparent hover:bg-white rounded ant-dropdown-link  ${
                          isHovered ? "translate-x-0" : "translate-x-10"
                        }`}
                        onClick={(e) => e.preventDefault()}
                      >
                        <IconDotsVertical
                          className=" w-4 h-4 gray-500 tooltip tooltip-open"
                          data-tip="secondary"
                        />
                      </button>
                    </Dropdown>
                  </Tooltip>
                </a>
              </div>
            ))}
          </div>
        </>
      </div>

      {/* Channel Form Modal */}
      <div className="mt-4">
        <WorkChatChannelsFormModal
          id={editingId}
          key={editingId}
          open={addVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingId(0);
            }
            setAddVisible(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      </div>
      {/* Direct Form Modal */}
      <div className="mt-4">
        <WorkChatDirectFormModal
          open={addDirectVisible}
          setOpen={(isOpen) => {
            setAddDirectVisible(isOpen);
          }}
          onSaveSuccess={() => {}}
        />
      </div>
    </div>
  );
};

export default WorkChatChannelList;
