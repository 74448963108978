import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { List } from "antd";
import { ListGridType } from "antd/lib/list";
import { FilterStory } from "common/types/Story";
import { SizeCard } from "../AcademyCard/types";

import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import useCollectionStore from "zustands/useCollectionStore";
import AcademyCard from "../AcademyCard";
import AcademySkeleton from "../AcademySkeleton/AcademySkeleton";
import StoryRepository from "common/repositories/StoryRepository";
import StoryModel from "common/models/StoryModel";

type Props = {
  heightImage?: number;
  sizeCard?: SizeCard;
  prefixLink?: string;
  grid?: ListGridType | undefined;
};
const AcademySearchPage = ({
  heightImage = 300,
  sizeCard,
  prefixLink,
  grid = {
    gutter: 28,
    xs: 2,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3,
  },
}: Props) => {
  const keyword = useCollectionStore((state) => state.keyword);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StoryModel[]>([]);
  const [total, setTotal] = useState<number>(0);

  const defaultFilters = useMemo(() => {
    return { ...StoryRepository.getDefaultFilter() };
  }, []);
  const [filters, setFilters] = useStateFilter<FilterStory>({
    ...defaultFilters,
  });
  useFilterLocation(defaultFilters, filters);

  ////////////////
  const dataLoading: StoryModel[] = Array.from(
    new Array(3).fill(new StoryModel(StoryModel.getDefaultData()))
  );

  const handleFetchData = useCallback(async () => {
    setLoading(true);
    const response = await new StoryRepository().getItemsPublic({
      filters: { ...filters },
    });
    if (!response.hasError()) {
      setData(response.items);
      setTotal(response.total);
    } else {
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    handleFetchData();
  }, [filters]);

  useEffect(() => {
    if (keyword.length > 0) {
      setFilters({ ...filters, keyword: keyword });
    } else {
      // setFilters({ ...filters, keyword: keyword });
    }
  }, [keyword]);

  return (
    <div className="w-full mt-4">
      {/* {keyword && keyword.length > 0 ? (
        <List
          itemLayout="vertical"
          pagination={{
            total: total,
            pageSize: +defaultFilters.limit,
            defaultPageSize: +filters.page,
            responsive: true,
            size: "small",
            showSizeChanger: false,
            style: { textAlign: "center", height: 35 },
            className: "academyList",
            hideOnSinglePage: true,
            current: +filters.page,
            // itemRender: () => {},
            // showTotal: (total) => {
            //   return <span>{total}</span>;
            onChange(page, pageSize) {
              setFilters({ ...filters, page: page });
            },

            // },
          }}
          grid={grid}
          dataSource={loading ? dataLoading : data}
          loading={{
            tip: <div>Đang tải</div>,
            spinning: loading,
          }}
          renderItem={(item: StoryModel, index) => (
            <List.Item key={index}>
              <>
                <Link
                  to={`/academy/${
                    prefixLink
                      ? prefixLink + "/" + item.handle
                      : item.collection_list.length > 0
                      ? item.collection_list[0].handle + "/" + item.handle
                      : ""
                  }`}
                >
                  <AcademySkeleton
                    loading={loading}
                    skeletonProps={{
                      active: true,
                      paragraph: { rows: 4 },
                      title: true,
                    }}
                    heightImage={heightImage}
                    children={
                      <AcademyCard
                        size={sizeCard}
                        item={item}
                        heightImage={heightImage}
                      ></AcademyCard>
                    }
                  />
                </Link>
              </>
            </List.Item>
          )}
        />
      ) : (
        <AcademyNotFound />
      )} */}

      <List
        itemLayout="vertical"
        pagination={{
          total: total,
          pageSize: +defaultFilters.limit,
          defaultPageSize: +filters.page,
          responsive: true,
          size: "small",
          showSizeChanger: false,
          style: { textAlign: "center", height: 35 },
          className: "academyList",
          hideOnSinglePage: true,
          current: +filters.page,
          // itemRender: () => {},
          // showTotal: (total) => {
          //   return <span>{total}</span>;
          onChange(page, pageSize) {
            setFilters({ ...filters, page: page });
          },

          // },
        }}
        grid={grid}
        dataSource={loading ? dataLoading : data}
        loading={{
          tip: <div>Đang tải</div>,
          spinning: loading,
        }}
        renderItem={(item: StoryModel, index) => (
          <List.Item key={index}>
            <>
              <Link
                to={`/academy/${
                  prefixLink
                    ? prefixLink + "/" + item.handle
                    : item.collections.length > 0
                    ? item.collections[0].handle + "/" + item.handle
                    : ""
                }`}
              >
                <AcademySkeleton
                  loading={loading}
                  skeletonProps={{
                    active: true,
                    paragraph: { rows: 4 },
                    title: true,
                  }}
                  heightImage={heightImage}
                  children={
                    <AcademyCard
                      size={sizeCard}
                      item={item}
                      heightImage={heightImage}
                    ></AcademyCard>
                  }
                />
              </Link>
            </>
          </List.Item>
        )}
      />
      {/* {Array.from(new Array(10)).map((item, key) => {
        return <AcademyCard key={key}></AcademyCard>;
      })} */}
    </div>
  );
};

export default AcademySearchPage;
