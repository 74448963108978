import { Button, Descriptions, Image } from "antd";
import TextDateTime from "components/TextDateTime";
import TextFileSize from "components/TextFileSize";
import TextUser from "components/TextUser";
import { useTranslation } from "react-i18next";
import useFileManagerStore from "zustands/useFileManagerStore";

import { IconDownload, IconZoomIn } from "@tabler/icons-react";

const FileDetail = () => {
  const { t } = useTranslation();

  const [item] = useFileManagerStore((state) => [state.activeFile]);

  if (typeof item === "undefined") {
    return null;
  }

  return (
    <div className="p-4">
      {typeof item !== "undefined" ? (
        <>
          {item.isImage() ? (
            <>
              <Image
                style={{ borderRadius: 4 }}
                className="rounded w-full"
                src={item.getThumbnail(260)}
                preview={{
                  src: item.url,
                  mask: (
                    <div className="text-center">
                      <IconZoomIn size="16" />
                      <br />
                      {t("file:preview")}
                    </div>
                  ),
                }}
              />
            </>
          ) : (
            <img src={item.getIcon()} alt={item.title} />
          )}
          <div className="mt-4">
            <div className="text-xs font-semibold uppercase text-gray-600">
              {t("file:description.heading")}
            </div>
          </div>
          <Descriptions
            className="mt-2"
            bordered={true}
            size="small"
            column={1}
            labelStyle={{ padding: 6, fontSize: 12, width: 90 }}
            contentStyle={{ padding: 6, fontSize: 12 }}
          >
            <Descriptions.Item label={t("common:sid")}>
              {item.id}
            </Descriptions.Item>
            <Descriptions.Item label={t("file:description.title")}>
              {item.title}
            </Descriptions.Item>
            <Descriptions.Item label={t("file:description.file_size")}>
              <TextFileSize value={item.size_in_byte} />
            </Descriptions.Item>
            {item.isImage() ? (
              <Descriptions.Item label={t("file:description.resolution")}>
                {item.width}&times;{item.height}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label={t("file:description.uploader")}>
              <TextUser id={item.creator_id} />
            </Descriptions.Item>
            <Descriptions.Item label={t("file:description.date_upload")}>
              <TextDateTime ts={item.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label={t("file:description.ip_address")}>
              {item.ip_address}
            </Descriptions.Item>
          </Descriptions>

          <div className="mt-2">
            <a
              href={item.url}
              download={item.title}
              target={"_blank"}
              rel="noreferrer"
            >
              <Button
                block
                type="primary"
                ghost
                size="middle"
                className=""
                icon={<IconDownload className="mr-1 -mt-1" size={18} />}
              >
                {t("file:download")}
              </Button>
            </a>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default FileDetail;
