import { App, Button, Col, Divider, Input, Modal, Row, Select } from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";
import ProductReceiptDetailModel from "common/models/ProductReceiptDetailModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import ProductReceiptRepository from "common/repositories/ProductReceiptRepository";
import { ProductReceiptJsonAddEdit } from "common/types/ProductReceipt";
import { ProductReceiptDetailJson } from "common/types/ProductReceiptDetail";
import Error from "components/LayoutError";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProductInventoryCheckingBalanceUserFormModal = ({
  warehouse_id,
  open,
  setOpen,
  detailItems
}: {
  warehouse_id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  detailItems: ProductInventoryCheckingDetailModel[];
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  let navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [postValues, setPostValues] = useState<ProductReceiptJsonAddEdit>({
    ...ProductReceiptModel.getDefaultData(),
    warehouse_id: warehouse_id,
    type: ProductReceipt.TYPE_BALANCESTORE,
    details: []
  });

  const createReceipt = useCallback(
    async (direction: number, details: ProductReceiptDetailJson[]) => {
      const formData = {
        ...postValues,
        direction: direction,
        details: details
      };

      let myObj: ProductReceiptModel =
        await new ProductReceiptRepository().saveRemote(formData);
      if (myObj.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="productinventorychecking:form.error"
              items={myObj.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4
        });
        setProcessing(false);
      } else {
        message.success({
          content: t("productinventorychecking:form.success.balance_success"),
          className: "message_success",
          key: "message",
          duration: 2
        });
      }
    },
    [t, postValues, message]
  );

  //submit data to server
  const onSubmitOnBalance = useCallback(async () => {
    const details = detailItems.map((item) => item.toJson());
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 1
    });

    const inputDetails: ProductReceiptDetailJson[] = [];
    const outputDetails: ProductReceiptDetailJson[] = [];

    details.map((item) => {
      const inventory = item.quantity - item.system_quantity;
      if (inventory > 0) {
        inputDetails.push({
          ...ProductReceiptDetailModel.getDefaultData(),
          item_quantity: inventory,
          sku: item.product_sku,
          // product_variant_id: item.product_variant_id
        });
      }
      return inputDetails;
    });

    if (inputDetails.length > 0) {
      createReceipt(ProductReceipt.DIRECTION_INPUT, inputDetails);
    }

    details.map((item) => {
      const inventory = item.quantity - item.system_quantity;
      if (inventory < 0) {
        outputDetails.push({
          ...ProductReceiptDetailModel.getDefaultData(),
          item_quantity: Math.abs(inventory),
          sku: item.product_sku,
          // product_variant_id: item.product_variant_id
        });
      }
      return outputDetails;
    });

    if (outputDetails.length > 0) {
      createReceipt(ProductReceipt.DIRECTION_OUTPUT, outputDetails);
    }

    navigate("/productinventorychecking");
    setOpen(false);
  }, [setOpen, t, detailItems, navigate, createReceipt, message]);

  return (
    <Modal
      title={t("productinventorychecking:balance_store_form")}
      width={600}
      open={open}
      closable={false}
      footer={[
        <Button key="cancel" onClick={() => setOpen(false)}>
          {t("productinventorychecking:form.balance_cancel")}
        </Button>,
        <Button
          loading={processing}
          key="submit"
          onClick={onSubmitOnBalance}
          type="primary">
          {t("productinventorychecking:form:balance_continue")}
        </Button>
      ]}
      maskClosable={true}>
      <Divider className="mt-0" />
      <Row gutter={16} className="mb-4">
        <Col md={4} xs={24} className="text-right">
          {t("productinventorychecking:note")}
        </Col>
        <Col md={20} xs={24}>
          <Input.TextArea
            rows={4}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setPostValues({
                ...postValues,
                note: event.target.value
              })
            }
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4} xs={24} className="flex items-center justify-end">
          {t("common:status")}
        </Col>
        <Col md={9} xs={24}>
          <Select
            defaultValue={ProductReceipt.STATUS_DRAFT}
            options={ProductReceiptModel.getStatusLessList()}
            className="w-full"
            onChange={(value: number) =>
              setPostValues({
                ...postValues,
                status: value
              })
            }
          />
        </Col>
      </Row>
      <Divider />
    </Modal>
  );
};

export default ProductInventoryCheckingBalanceUserFormModal;
