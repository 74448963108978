import Role from 'common/constants/Role';
import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import RoleCheck from 'components/RoleCheck';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductTransmitListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("producttransmit:heading_list")}
      siteMenuOpenKey="inventory"
      siteMenuSelectedKey="/producttransmit"
    >
      <RoleCheck roles={[Role.INVENTORY_TRANSFER_REQUEST]}>
        <PageHeaderButton link="/producttransmit/add">
          {t("producttransmit:add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default ProductTransmitListHeader;
