import { Select } from "antd";
import { EmployeeJson } from "common/types/Employee";
import useDatabaseTable from "hooks/useDatabaseTable";
import React from "react";
import { useTranslation } from "react-i18next";

const ProjectEmpoyeeFormSelect = ({
  values,
  multiple,
  ignoreValues,
  placeholder,
  ortherOptions,
  onChange,
}: {
  values?: number[];
  multiple?: boolean;
  ignoreValues?: number[];
  placeholder?: string;
  ortherOptions?: number[];
  onChange: (v: number[]) => void;
}) => {
  const { t } = useTranslation();
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");

  const employeeFilteredOptions =
    (employeeItems &&
      employeeItems
        .filter(
          (o) =>
            o.user_id > 0 &&
            (typeof ignoreValues === "undefined" ||
              (typeof ignoreValues !== "undefined" &&
                !ignoreValues.includes(o.user_id))),
        )
        .map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
          };
        })) ||
    [];

  const filterOrtherOption =
    (employeeItems &&
      employeeItems
        .filter((o) => ortherOptions?.indexOf(o.user_id) !== -1)
        .filter(
          (o) =>
            o.user_id > 0 &&
            (typeof ignoreValues === "undefined" ||
              (typeof ignoreValues !== "undefined" &&
                !ignoreValues.includes(o.user_id))),
        )
        .map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
          };
        })) ||
    [];

  const onChangeHandle = (value: any) => {
    if (typeof onChange !== "undefined") {
      if (typeof value != "undefined") {
        if (Array.isArray(value)) {
          onChange(value);
        } else {
          onChange([value]);
        }
      } else {
        onChange([]);
      }
    }
  };

  return (
    <Select
      placeholder={
        placeholder ?? t("project:form.project_employee_placeholder")
      }
      onChange={onChangeHandle}
      options={ortherOptions ? filterOrtherOption : employeeFilteredOptions}
      style={{ width: "100%" }}
      mode={multiple ? "multiple" : undefined}
      optionFilterProp="label"
      defaultValue={values || []}
      filterOption={(input: any, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.label
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[đĐ]/g, "d")
          .toLowerCase()
          .indexOf(
            input
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[đĐ]/g, "d")
              .toLowerCase(),
          ) >= 0
      }
    />
  );
};

export default ProjectEmpoyeeFormSelect;
