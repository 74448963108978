import { Alert, Tag } from "antd";
import Order from "common/constants/Order";
import OrderModel from "common/models/OrderModel";
import FormSelect from "components/form/FormSelect";
import SimpleFormSelect from "components/form/SimpleFormSelect";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const OrderFormSectionStatus = ({
  allowStatusComplete,
  allowStatusCancel,
  status,
  setStatus,
  allowEditInput,
  initStatus,
  type
}: {
  allowStatusComplete: boolean;
  allowStatusCancel: boolean;
  status: number;
  type: number;
  setStatus: (v: number) => void;
  allowEditInput: boolean;
  initStatus: number;
}) => {
  const { t } = useTranslation();

  const backgroundColor = useMemo(() => {
    const statusInfo = OrderModel.getStatus(status , type);
    let color = statusInfo?.color;

    return color;
  }, [status, type]);

  return (
    <div
      style={{ backgroundColor: backgroundColor   }}
      className="px-4 py-2  mb-0"
    >
      <SimpleFormSelect
        value={status}
        label={<Tag  color={backgroundColor}>{t("order:status_long")}</Tag>}
        size="large"
        options={OrderModel.getStatusList(type).map((i) => {
          if (i.value === Order.STATUS_COMPLETE) {
            return allowStatusComplete ? i : { ...i, disabled: true };
          } else if (i.value === Order.STATUS_CANCEL) {
            return allowStatusCancel ? i : { ...i, disabled: true };
          } else {
            return {
              ...i,
              disabled: i.value < initStatus || !allowEditInput ? true : false,
            };
          }
        })}
        className="mb-2"
        onChange={(v) => (typeof v === "number" ? setStatus(v) : null)}
      />

      <div className={"" + (status !== Order.STATUS_CANCEL ? " hidden" : "")}>
        <FormSelect
          showSearch
          label={t("order:cancel_reason")}
          name="cancel_reason"
          options={OrderModel.getCancelReasonList()}
        />
        <div className="mb-2">
          <Alert
            showIcon
            type="warning"
            message={t("order:cancel_dependency_before_note_heading")}
            description={t("order:cancel_dependency_before_note")}
          ></Alert>
        </div>
      </div>
    </div>
  );
};

export default OrderFormSectionStatus;
