import DealthomConfigList from "features/dealthom/list/DealthomConfigList";
import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

const DealthomPage = () => {
	const [pathParams] = usePathParams();

	const [errors, setErrors] = useState<string[]>([]);

	//clear edit model when change action
	useEffect(() => {
		setErrors([]);
	}, [pathParams.action]);

	let com = <></>;

	switch (pathParams.action) {
		default:
			com = <DealthomConfigList></DealthomConfigList>;
			break;
	}
	return com;
};

export default DealthomPage;
