import BaseCollection from "common/collections/BaseCollection";
import EmployeeModel from "common/models/EmployeeModel";
import { EmployeeJson, EmployeeCollectionJson } from "common/types/Employee";

class EmployeeCollection extends BaseCollection<
  EmployeeJson,
  EmployeeModel,
  EmployeeCollectionJson
> {
  itemsFromJson(jsonItems: EmployeeJson[]): EmployeeModel[] {
    return jsonItems.map((item) => new EmployeeModel(item));
  }
}

export default EmployeeCollection;
