import { IconEye, IconLoader } from "@tabler/icons-react";
import { Image, Modal, Typography, message } from "antd";
import TagConstant from "common/constants/Tag";
import ProductModel from "common/models/ProductModel";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { SelectOption } from "common/types/SelectOption";
import NoPhoto from "components/NoPhoto";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductRelatedComponent from "./children/ProductRelatedComponent";

const ProductRelatedModal = ({
  id,
  data,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  data: ProductModel;
  open: boolean;
  setOpen: (v: boolean) => void;
  onSaveSuccess: (m: ProductModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<ProductModel>(data);
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [listTagReload, setListTagReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>([]);
  const [activeTag, setActiveTag] = useState<TagModel>(
    new TagModel(TagModel.getDefaultData())
  );

  //submit tag detail
  const onSubmitTagDetail = async (v: SelectOption[], activeTag: TagModel) => {
    setProcessing(true);
    if (v.length > 0) {
      message.loading({ content: "Vui lòng đợi", duration: 1 });

      const response = await new TagRepository().addTagRelated({
        item_id: id,
        relate_type: TagConstant.RELATED_TYPE.ONE_PARENT_TO_MORE_CHILDREN,
        res_item_ids: v.map((item) => item.value),
        tag_code: activeTag.code,
        type: "product",
      });
      if (!response.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue([...existValues, ...v.map((item) => item.value)]);
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error(response.error.errors);
        setProcessing(false);

        return false;
      }
    }
    setProcessing(false);
  };

  //submit reverted tag detail
  const onSubmitRevertTagDetail = async (v: SelectOption[]) => {
    setProcessing(true);
    if (v.length > 0) {
      message.loading({ content: "Vui lòng đợi", duration: 1 });

      const response = await new TagRepository().addTagRelated({
        item_id: id,
        relate_type: TagConstant.RELATED_TYPE.ONE_CHILDREN_TO_MORE_PARENT,
        res_item_ids: v.map((item) => item.value),
        tag_code: activeTag.code,
        type: "product",
      });
      if (!response.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        // setExistValue([...existValues, ...v.map((item) => item.value)]);
        // setSelectReload(!selectReload);
        setProcessing(false);
        return true;
      } else {
        message.error(response.error.errors);
        setProcessing(false);
        return false;
      }
    }
    setProcessing(false);
  };

  //delete collection detial
  const onDeleteSelectedItem = async (v: SelectOption) => {
    message.loading({ content: "Vui lòng đợi", duration: 1 });
    setProcessing(true);

    const reponse = await new TagRepository().deleteProductRelatedInTag({
      tags: activeTag.code + "-" + id,
      resource_id: v.value.toString(),
    });
    if (reponse.length === 0) {
      message.success({ content: "Thao tác thành công", duration: 1 });
      // setExistValue(existValues.filter((item) => item !== v.value));
      setSelectReload(!selectReload);
      setProcessing(false);

      return true;
    } else {
      message.error("Thao tác thất bại");
      setProcessing(false);

      return false;
    }
    setProcessing(false);
  };

  const handleRemoveCurrentItem = async (v: SelectOption[]) => {
    setProcessing(true);
    console.log("🥺 ~ handleRemoveCurrentItem ~ v:", v);
    if (v.length > 0) {
      const reponse = await new TagRepository().deleteProductRelatedInTag({
        tags: activeTag.code + "-" + id,
        resource_id: v.map((item) => item.value).toString(),
      });
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        // setExistValue(existValues.filter((item) => item !== v.value));
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error("Thao tác thất bại");
        setProcessing(false);
        return false;
      }
    }
  };

  //update existvalue
  const changeTagExistValue = (newTag: ProductModel[]) => {
    setExistValue(newTag.map((item) => item.id));
  };

  //update activeTag
  const changeActiveTagValue = (newTag: TagModel) => {
    setActiveTag(newTag);
  };

  //reload after add struct
  const onAddTagStructSuccess = () => {
    setListTagReload(!listTagReload);
  };

  //detele struct

  //delete collection detial
  const onDeleteTabItem = async (v: SelectOption) => {
    setProcessing(true);
    message.loading({ content: "Vui lòng đợi", duration: 1 });
    const findTagDetailId = await new TagRepository().getItemsDetail({
      filters: {
        ...TagRepository.getDefaultTagDetailFilters(),
        resource_id: v.value,
        resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
        tag_id: activeTag.id,
      },
    });
    if (!findTagDetailId.hasError() && findTagDetailId.items.length > 0) {
      const reponse = await new TagRepository().deleteTagDetailItem([
        findTagDetailId.items[0].id,
      ]);
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setListTagReload(!listTagReload);
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error("Thao tác thất bại");
        setProcessing(false);
        return false;
      }
    } else {
      message.error("Thao tác thất bại");
      setProcessing(false);
      return false;
    }
    setProcessing(false);
  };

  return (
    <Modal
      title={
        <>
          <div>
            <Typography.Text>{`Gắn sản phẩm liên quan`}</Typography.Text>
            {processing && <IconLoader className="animate-spin" />}
          </div>
        </>
      }
      width={920}
      bodyStyle={{
        paddingTop: 8,
        paddingBottom: 8,
        overflowY: "auto",
        maxHeight: "calc(100vh - 250px)",
      }}
      open={open}
      confirmLoading={processing}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        setOpen(false);
      }}
      maskClosable={false}
      okButtonProps={{ style: { display: "none" } }}
      cancelText={"Đóng"}
      destroyOnClose
    >
      <ProductRelatedComponent
        type={"productrelated"}
        activeTag={activeTag}
        changeActiveTagValue={changeActiveTagValue}
        productDetail={model}
        reload={selectReload}
        reloadListTag={listTagReload}
        existValues={existValues}
        id={id}
        onSaveSuccess={onSubmitTagDetail}
        onSaveReverted={onSubmitRevertTagDetail}
        handleRemoveItem={onDeleteSelectedItem}
        handleRemoveCurrentItem={handleRemoveCurrentItem}
        handleRemoveTabItem={onDeleteTabItem}
        changeExistValue={changeTagExistValue}
        onAddTagStructSuccess={() => {
          onAddTagStructSuccess();
        }}
        renderItems={(item: ProductModel) => {
          return (
            <>
              <div onClick={(e) => e.stopPropagation()}>
                {item.images && item.images.length > 0 ? (
                  <Image
                    className="max-w-[35px] max-h-[35px]"
                    // src={FileModel.getThumbnailFromFilePath(
                    //   item.images[0].file_path,
                    //   40,
                    //   40
                    // )}
                    src={item.images[0].url}
                    preview={{
                      src: item.images[0].url,
                      mask: <IconEye size="16" />,
                    }}
                  />
                ) : (
                  <NoPhoto width={35} height={35} />
                )}
              </div>

              <div className="flex flex-col">
                <div className="text-blue-600 text-md font-semibold">
                  {item.full_name.length > 0 ? item.full_name : item.name}
                </div>
                <div className="text-xs text-gray-400 mt-1">
                  Mã sản phẩm: {item.sku}
                </div>
              </div>
            </>
          );
        }}
      ></ProductRelatedComponent>
    </Modal>
  );
};

export default ProductRelatedModal;
