import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePathParams from "hooks/usePathParams";

import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";

import FormEditFetcher from "components/form/FormEditFetcher";

import DeliveryAccountantsList from "features/delivery/accountant/list/DeliveryAccountantList";

import DeliveryInternalForm from "features/delivery/manager/form/DeliveryInternalForm";
import DeliveryInternalFormHeader from "features/delivery/manager/form/DeliveryInternalFormHeader";

import DeliveryManagerList from "features/delivery/manager/list/DeliveryManagerList";
import DeliveryWaitingList from "features/delivery/waiting/list/DeliveryWaitingList";

import DeliveryWaitingFormHeader from "features/delivery/waiting/form/DeliveryWaitingFormHeader";
import DeliveryDetail from "features/delivery/detail/DeliveryDetail";

import DeliveryStatisticalList from "features/delivery/statistical/list/DeliveryStatisticalList";

import DeliveryMineList from "features/delivery/mine/list/DeliveryMineList";
import usePostActionStore from "zustands/usePostActionStore";
import AxiosClient from "common/api/AxiosClient";
import { LocationJson } from "components/LocationPicker";

export type LocationNameJson = {
	province: string;
	ward: string;
	district: string;
};

const DeliveryPage = () => {
	const { t } = useTranslation();
	const [pathParams] = usePathParams();
	console.log("🚀 ~ DeliveryPage ~ pathParams:", pathParams);
	const { reload } = usePostActionStore();

	const [model, setModel] = useState<DeliveryModel>(
		new DeliveryModel(DeliveryModel.getDefaultData())
	);
	const [fetchErrors, setFetchErrors] = useState<string[]>([]);

	const [dataLocationName, setDataLocationName] = useState<LocationNameJson>({
		province: "",
		ward: "",
		district: "",
	});

	const getLocationList = async ({
		type,
		parent_id,
		code,
	}: {
		type?: number;
		parent_id?: number;
		code?: string;
	}) => {
		try {
			let queryData = {
				type: type,
				parent_id: parent_id ? parent_id : null,
				code: code ? code : null,
			};

			const { data } = await AxiosClient().get<any>(`/locations/public/type`, {
				params: queryData,
			});
			return data?.items as LocationJson[];
		} catch (error) {
			return [];
		}
	};

	//clear edit model when change action
	useEffect(() => {
		setModel(new DeliveryModel(DeliveryModel.getDefaultData()));
		setFetchErrors([]);
		setDataLocationName({
			province: "",
			ward: "",
			district: "",
		});
	}, [pathParams.action]);

	let com = null;
	console.log("🚀 ~ DeliveryPage ~ pathParams:", pathParams.id);
	console.log("🚀 ~ DeliveryPage ~ model:", model);

	switch (pathParams.action) {
		case "add":
			com =
				pathParams.id && Number(pathParams.id) > 0 ? (
					model.id < 0 ? (
						<DeliveryInternalForm
							model={model}
							dataLocationName={dataLocationName}
						/>
					) : (
						<FormEditFetcher
							doLoad={async () => {
								let fetchedModel =
									await new DeliveryRepository().getDetailOrderNotInDelivery(
										Number(pathParams.id)
									);

								// try {
								// 	const country =
								// 		fetchedModel?.address_detail.shipping_country || 0;
								// 	const province =
								// 		fetchedModel?.address_detail.shipping_province || 0;

								// 	const district =
								// 		fetchedModel?.address_detail.shipping_district || 0;
								// 	const ward = fetchedModel?.address_detail.shipping_ward || 0;

								// 	if (country > 0) {
								// 		const provincesData = await getLocationList({
								// 			type: 1,
								// 			parent_id: country,
								// 		});
								// 		if (provincesData.length > 0) {
								// 			const nameProvince =
								// 				provincesData.find((i) => i.location_id === province)
								// 					?.name || "";
								// 			setDataLocationName((prev) => ({
								// 				...prev,
								// 				province: nameProvince,
								// 			}));
								// 		}
								// 	}

								// 	if (province > 0) {
								// 		const districtData = await getLocationList({
								// 			type: 10,
								// 			parent_id: province,
								// 		});
								// 		if (districtData.length > 0) {
								// 			const nameWard =
								// 				districtData.find((i) => i.location_id === district)
								// 					?.name || "";
								// 			setDataLocationName((prev) => ({
								// 				...prev,
								// 				district: nameWard,
								// 			}));
								// 		}
								// 	}

								// 	if (district > 0) {
								// 		const wardData = await getLocationList({
								// 			type: 110,
								// 			parent_id: district,
								// 		});
								// 		if (wardData.length > 0) {
								// 			const nameWard =
								// 				wardData.find((i) => i.location_id === ward)?.name ||
								// 				"";
								// 			setDataLocationName((prev) => ({
								// 				...prev,
								// 				ward: nameWard,
								// 			}));
								// 		}
								// 	}
								// } catch (error) {}
								if (fetchedModel.hasError()) {
									setFetchErrors(fetchedModel.error.errors);
								} else {
									setModel(fetchedModel);
								}
							}}
							errors={fetchErrors}
							heading={<DeliveryInternalFormHeader isEditing={false} />}
						/>
					)
				) : (
					<DeliveryInternalForm
						model={model}
						dataLocationName={dataLocationName}
					/>
				);
			break;
		case "edit":
			com =
				model.id > 0 ? (
					<DeliveryInternalForm model={model} />
				) : (
					<FormEditFetcher
						doLoad={async () => {
							let fetchedModel = await new DeliveryRepository().getItem(
								Number(pathParams.id)
							);
							if (fetchedModel.hasError()) {
								setFetchErrors(fetchedModel.error.errors);
							} else {
								setModel(fetchedModel);
							}
						}}
						errors={fetchErrors}
						heading={<DeliveryInternalFormHeader isEditing={true} />}
					/>
				);
			break;

		case "detail":
			com =
				model.id > 0 ? (
					<DeliveryDetail model={model}></DeliveryDetail>
				) : (
					<FormEditFetcher
						doLoad={async () => {
							const fetchModel = await new DeliveryRepository().getItem(
								Number(pathParams.id) || 0
							);
							if (!fetchModel.hasError()) {
								setModel(fetchModel);
							} else {
								setFetchErrors(fetchModel.error.errors);
							}
						}}
						heading={<DeliveryWaitingFormHeader isEditing={true} />}
						errors={fetchErrors}
					/>
				);
			break;
		case "manager":
			com = <DeliveryManagerList />;
			break;

		case "accountant":
			com = <DeliveryAccountantsList />;
			break;

		case "statistical":
			com = <DeliveryStatisticalList />;
			break;

		// case "accountant":
		//   break;

		case "waiting":
			com = <DeliveryWaitingList />;
			break;
		case "waiting_detail":
			com =
				model.id > 0 ? (
					<DeliveryDetail model={model}></DeliveryDetail>
				) : (
					<FormEditFetcher
						doLoad={async () => {
							const fetchModel = await new DeliveryRepository().getItem(
								Number(pathParams.id) || 0
							);
							if (!fetchModel.hasError()) {
								setModel(fetchModel);
							} else {
								setFetchErrors(fetchModel.error.errors);
							}
						}}
						heading={<DeliveryWaitingFormHeader isEditing={true} />}
						errors={fetchErrors}
					/>
				);
			break;
		case "mine":
			com = <DeliveryMineList />;
			break;
		case "mine_detail":
			com =
				model.id > 0 ? (
					<DeliveryDetail type="mine" model={model}></DeliveryDetail>
				) : (
					<FormEditFetcher
						doLoad={async () => {
							const fetchModel = await new DeliveryRepository().getItem(
								Number(pathParams.id) || 0
							);
							if (!fetchModel.hasError()) {
								setModel(fetchModel);
							} else {
								setFetchErrors(fetchModel.error.errors);
							}
						}}
						heading={<DeliveryWaitingFormHeader isEditing={true} />}
						errors={fetchErrors}
					/>
				);
			break;

		case "manager_detail":
			com =
				model.id > 0 ? (
					<DeliveryDetail type="manager" model={model}></DeliveryDetail>
				) : (
					<FormEditFetcher
						doLoad={async () => {
							const fetchModel = await new DeliveryRepository().getItem(
								Number(pathParams.id) || 0
							);
							if (!fetchModel.hasError()) {
								setModel(fetchModel);
							} else {
								setFetchErrors(fetchModel.error.errors);
							}
						}}
						heading={<DeliveryWaitingFormHeader isEditing={true} />}
						errors={fetchErrors}
					/>
				);
			break;

		case "accountant_detail":
			com =
				model.id > 0 ? (
					<DeliveryDetail type="accountant" model={model}></DeliveryDetail>
				) : (
					<FormEditFetcher
						doLoad={async () => {
							const fetchModel = await new DeliveryRepository().getItem(
								Number(pathParams.id) || 0
							);
							if (!fetchModel.hasError()) {
								setModel(fetchModel);
							} else {
								setFetchErrors(fetchModel.error.errors);
							}
						}}
						heading={<DeliveryWaitingFormHeader isEditing={true} />}
						errors={fetchErrors}
					/>
				);
			break;

		default:
			// com = <DeliveryOrderList />;
			break;
	}

	return com;
};

export default DeliveryPage;
