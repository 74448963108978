import { Col, Progress, Row, Spin, Tooltip } from "antd";
import FileStatsModel from "common/models/FileStatsModel";
import FileHelperRepository from "common/repositories/FileHelperRepository";
import ErrorAlert from "components/ErrorAlert";
import TextFileSize from "components/TextFileSize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconDeviceDesktopAnalytics } from "@tabler/icons-react";

const FileStats = ({
  fileStats,
  setFileStats,
}: {
  fileStats?: FileStatsModel;
  setFileStats: (v: FileStatsModel) => void;
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  const totalUsageSize = useMemo(() => {
    if (typeof fileStats !== "undefined") {
      return fileStats.size_by_type.reduce((sum, { value }) => sum + value, 0);
    } else {
      return 0;
    }
  }, [fileStats]);

  const totalCountFile = useMemo(() => {
    if (typeof fileStats !== "undefined") {
      return fileStats.count_file_by_type.reduce(
        (sum, { value }) => sum + value,
        0
      );
    } else {
      return 0;
    }
  }, [fileStats]);

  const loadStats = useCallback(async () => {
    setErrors([]);
    setLoading(true);
    const loadedStats = await new FileHelperRepository().getStats();
    setLoading(false);
    if (loadedStats.hasError()) {
      setErrors(loadedStats.error.errors);
    } else {
      setFileStats(loadedStats);
    }
  }, [setFileStats]);

  useEffect(() => {
    if (typeof fileStats === "undefined" && loading) {
      loadStats();
    }
  }, [loading, fileStats, loadStats]);

  return (
    <div className="p-2 border-blue-200 bg-blue-200">
      {loading ? <Spin size="small" /> : null}

      {!loading && typeof fileStats !== "undefined" ? (
        <>
          {errors.length > 0 ? (
            <ErrorAlert
              items={errors}
              heading={t("common:error.error_fetching_data")}
              onRetry={() => {
                loadStats();
              }}
            />
          ) : (
            <>
              {fileStats.quota > 0 ? (
                <>
                  {" "}
                  <Row className="">
                    <Col flex={"40px"}>
                      <div className="mt-0.5 ml-1">
                        <IconDeviceDesktopAnalytics className="text-blue-800" />
                      </div>
                    </Col>
                    <Col flex={"auto"}>
                      <Tooltip
                        title={
                          <>
                            <h3 className="font-bold uppercase">
                              {t("file:stats.heading")}:
                            </h3>
                            <div className="text-sm leading-relaxed">
                              <div>
                                {t("file:stats.count_file")}:{" "}
                                <span className="font-bold">
                                  {totalCountFile}
                                </span>{" "}
                                {t("file:stats.unit")}
                              </div>
                              <div>
                                {t("file:stats.total_size")}:{" "}
                                <span className="font-bold">
                                  <TextFileSize value={totalUsageSize} />
                                </span>{" "}
                              </div>
                            </div>
                          </>
                        }
                        placement="bottomRight"
                      >
                        <Progress
                          showInfo={true}
                          size="small"
                          percent={Math.ceil(
                            (totalUsageSize * 100) / fileStats.quota
                          )}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default FileStats;
