import BaseRepository from "./BaseRepository";

import {
  CommentJsonAddEdit,
  CommentJsonAddEditNew,
  FilterComment,
} from "common/types/Comment";
import CommentApi from "common/api/CommentApi";
import CommentModel from "common/models/CommentModel";
import type { GetListProps } from "common/types/Filter";
import CommentCollection from "common/collections/CommentCollection";

class CommentRepository extends BaseRepository<CommentApi> {
  _api: CommentApi | null;
  constructor() {
    super();
    this._api = new CommentApi(true);
  }

  static getDefaultFilter(): FilterComment {
    return {
      page: 1,
      limit: 10,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: -1,
      item_id: "",
      item_type: "product",
      parent_id: 0,
      type: -1,
      rate: -1,
      date_created: 0,
      is_customer: -1,
      customer_id: -1,
      is_admin: -1,
      is_user: 3,
      date_from: 0,
      date_to: 0,
    };
  }

  async getItems(
    props: GetListProps<FilterComment>
  ): Promise<CommentCollection> {
    return this._api
      ? await this._api.getList(props.filters)
      : new CommentCollection();
  }

  async getItemsReply(
    props: GetListProps<FilterComment>
  ): Promise<CommentCollection> {
    return this._api
      ? await this._api.getListReply(props.filters)
      : new CommentCollection();
  }

  async getItem(id: number): Promise<CommentModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new CommentModel(CommentModel.getDefaultData());
  }

  async saveRemote(data: CommentJsonAddEdit): Promise<CommentModel> {
    if (this._api) {
      if (data.id > 0) {
        if (data.type === 3) {
          return await this._api.editQA(data);
        } else {
          return await this._api.edit(data);
        }
      } else {
        return await this._api.add(data);
      }
    } else {
      return new CommentModel(CommentModel.getDefaultData());
    }
  }

  async add(data: CommentJsonAddEditNew): Promise<CommentModel> {
    if (this._api) {
      return this._api.add2(data);
    } else {
      return new CommentModel(CommentModel.getDefaultData());
    }
  }

  async edit(data: CommentJsonAddEditNew): Promise<CommentModel> {
    if (this._api) {
      if (data.type === 3) {
        return await this._api.editQA2(data);
      } else {
        return await this._api.edit2(data);
      }
    } else {
      return new CommentModel(CommentModel.getDefaultData());
    }
  }

  async approve(
    data: Pick<CommentJsonAddEdit, "id" | "status">
  ): Promise<CommentModel> {
    if (this._api) {
      return await this._api.editStatus(data);
    } else {
      return new CommentModel(CommentModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default CommentRepository;
