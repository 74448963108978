import React, { useCallback, useEffect, useMemo, useState } from "react";
import { List, Skeleton } from "antd";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import useStateFilter from "hooks/useStateFilter";
import { DeliveryJson, FilterDelivery } from "common/types/Delivery";
import DeliveryWaitingListHeader from "./DeliveryWaitingListHeader";
import DeliveryWaitingItem from "./DeliveryWaitingItem";
import DeliveryWaitingListFilter from "./DeliveryWaitingListFilter";

type Data = DeliveryJson & {
  loading?: boolean;
};
const DeliveryWaitingList = () => {
  const defaultFilters = useMemo(() => {
    return DeliveryRepository.getDefaultFilters();
  }, []);

  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [dataSource, setDataSource] = useState<Data[]>(
    Array.from({ length: 10 }).map(() => ({
      ...DeliveryModel.getDefaultData(),
      loading: true,
    }))
  );
  const [total, setTotal] = React.useState(0);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  ///////////////////////////////
  // fetch data
  const fetchData = useCallback(async () => {
    setLoading(true);
    const response = await new DeliveryRepository().getItemsDeliveryWaiting({
      filters,
    });
    if (!response.hasError()) {
      setTotal(response.total);
      setDataSource(response.items);
      setLoading(false);
    } else {
      setErrors(response.error.errors);
    }
    setLoading(false);
  }, [filters]);

  ///////////////////////////////
  useEffect(() => {
    fetchData();
  }, [filters]);
  return (
    <div>
      <DeliveryWaitingListHeader />
      <DeliveryWaitingListFilter<FilterDelivery>
        {...{ defaultFilters, filters, setFilters, total }}
      />
      <div className="py-1 px-1">
        <List
          dataSource={dataSource}
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          pagination={{
            size: "small",
            hideOnSinglePage: true,
            style: { margin: 0, position: "sticky", bottom: 0, right: 0 },
            onChange: (page) => {
              setFilters({ ...filters, page: page });
            },
            pageSize: 10,
            total: total,
          }}
          renderItem={(item) => {
            return (
              <List.Item style={{ marginBlockEnd: 4 }}>
                <Skeleton
                  loading={item.loading}
                  active
                  className="w-full h-[200px]"
                >
                  <DeliveryWaitingItem fetchData={fetchData} item={item} />
                </Skeleton>
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
};

export default DeliveryWaitingList;
