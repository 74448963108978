import { Space } from 'antd';
import EcomModel from 'common/models/EcomModel';

import EcomItemTypeImage from './EcomItemTypeImage';

const EcomItem = ({ record }: { record: EcomModel }) => {
  return (
    <div className="space-align-block">
      <Space align="center">
        <EcomItemTypeImage
          type={record.type}
          width={70}
          height={70}
          className="border rounded"
        />
        <div>
          <div className="font-bold block">{record.title}</div>

          <span className="mock-block text-xs text-gray-900">{record.url}</span>
        </div>
      </Space>
    </div>
  );
};

export default EcomItem;
