import CompanySettingModel from "common/models/CompanySettingModel";
import React, { useCallback, useEffect } from "react";
import useCompanySettingStore from "zustands/useCompanySettingStore";

const CompanySettingWrapper = ({
  children,
  loadCompanySettingDone,
}: {
  children: React.ReactNode;
  loadCompanySettingDone: boolean;
}) => {
  const setData = useCompanySettingStore((state) => state.setData);

  const syncSetting = useCallback(async () => {
    const entry = await CompanySettingModel.getAllSettings();
    if (typeof entry !== "undefined" && entry !== null) {
      setData(entry);
    }
  }, [setData]);

  useEffect(() => {
    if (loadCompanySettingDone) {
      syncSetting();
    }
  }, [loadCompanySettingDone, syncSetting]);

  return <>{children}</>;
};

export default CompanySettingWrapper;
