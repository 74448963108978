import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Typography,
} from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useCallback, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import ToolRepository from "common/repositories/ToolRepository";
import ToolModel from "common/models/ToolModel";

import WifiQRCodeHeader from "./WifiQRCodeHeader";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";
import ErrorAlert from "components/ErrorAlert";

const WifiQrCode = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const [resultImg, setResultImg] = useState<string[]>([]);
  const [outSize, setOutSize] = useState(300);
  const [qrBase64, setQrBase64] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [sl, setSl] = useState(1);

  const componentPrintRef = useRef(null);

  /////////////////////////////////
  const handlePrint = useReactToPrint({
    copyStyles: false,
    removeAfterPrint: true, //prevent bug on set copyStyles to false
    suppressErrors: true, //prevent bug on set copyStyles to false

    content: () => {
      return componentPrintRef.current;
    },
    // onAfterPrint: () => {
    //   message.success("In thành công");
    // },
  });

  const initialValues = {
    name: "",
    password: "",
    security: "WPA",
    out_size: 300,
    with_logo: true,
    quality: 1,
  };

  //prepare data for submit
  const doPrepareData = useCallback((formData: any) => {
    const submitData: any = {
      name: formData.name,
      password: formData.password,
      security: formData.security,
      out_size: formData.out_size,
      with_logo: formData.with_logo ? 1 : 0,
    };

    return submitData;
  }, []);

  //submit data to server
  const onSubmit = async (formData: any) => {
    setLoading(true);
    doPrepareData(formData);

    let myObj: ToolModel = await new ToolRepository().addWifiQrCode(
      doPrepareData(formData)
    );

    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setDisabled(true);
      setQrBase64(myObj.result);
      setIsSuccess(true);
    }
    setLoading(false);
  };
  const handleReset = () => {
    setDisabled(false);
    setQrBase64("");
    form.resetFields();
  };

  const sidebarItems = (
    <>
      {errors.length > 0 && (
        <ErrorAlert items={errors} translate_prefix="tool:form.error" />
      )}
      <Form.Item>
        {qrBase64.length <= 0 && (
          <Button
            className="mt-2"
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
            block
          >
            <PlusOutlined /> {t("tool:create")}
          </Button>
        )}
        {qrBase64.length > 0 && (
          <Button
            htmlType="button"
            className="mt-2"
            size="large"
            type="primary"
            ghost
            onClick={handleReset}
            block
          >
            <ReloadOutlined /> {t("tool:redo")}
          </Button>
        )}

        {qrBase64.length > 0 && (
          <ReactToPrint
            trigger={() => (
              <Button
                htmlType="button"
                type="primary"
                size="large"
                className="mt-2"
                style={{ width: "100%" }}
              >
                <PrinterOutlined />
                {t("tool:print_qr_code")}
              </Button>
            )}
            content={() => componentPrintRef.current}
            bodyClass="print-container"
            suppressErrors={true}
            removeAfterPrint={true}
          />
        )}
      </Form.Item>
    </>
  );

  return (
    <>
      <WifiQRCodeHeader />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={t("tool:form.success.add")}
        error_translate_prefix="tool:form.error"
        onSubmit={onSubmit}
        sidebarItems={sidebarItems}
        hideSubmitButton={true}
      >
        <FormSection
          title={t("tool:info_wifi_qr_code")}
          subtitle={t("tool:details_qr_code")}
          anotherSubtitle={
            disabled && (
              <Typography.Text type="success">
                {t("tool:create_new")}
              </Typography.Text>
            )
          }
          divider
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("tool:wifi_name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("tool:rules.wifi_name_required"),
                  },
                ]}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("tool:password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("tool:rules.wifi_password_required"),
                  },
                ]}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label={t("tool:security")} name="security">
                <Radio.Group
                  disabled={disabled}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio.Button value={"WPA"}>WPA/WPA2</Radio.Button>
                  <Radio.Button value={"WEB"}>WEB</Radio.Button>
                  <Radio.Button value={"NONE"}>NONE</Radio.Button>
                  <Radio.Button value={"RAW"}>RAW</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item label={t("tool:size")} name="out_size">
                <Input disabled={disabled} addonAfter={t("tool:pixel")} />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={t("tool:add_logo")}
                name="with_logo"
                valuePropName="checked"
              >
                <Checkbox disabled={disabled}>
                  {t("tool:contain_logo")}
                </Checkbox>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={t("tool:images_on_page")}
                name="quality"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: t("tool:rules.wifi_quantity_required"),
                  },
                  {
                    message: t("tool:rules.wifi_min_quantity"),
                    validator: (_: any, value: string) => {
                      if (value && Number(value) > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <Input
                  name="count"
                  type="number"
                  defaultValue={1}
                  min={1}
                  step={1}
                  onChange={(e) => {
                    setSl(Number(e.target.value));
                  }}
                  addonAfter={<span>{t("tool:quantity")}</span>}
                  // value={quality}
                  // onChange={(e) => {
                  //   setQuality(Number(e.target.value));
                  // }}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSection
          title={t("tool:wifi_qr_code")}
          subtitle={t("tool:wifi_qr_code_here")}
        >
          <Row>
            {qrBase64.length > 0 ? (
              <Col span={24}>
                <div
                  className="print-container"
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "2px",
                  }}
                  ref={componentPrintRef}
                >
                  {Array.from(new Array(sl)).map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: `${outSize}px`,
                          height: `${outSize}px`,
                          border: "1px solid black",
                          display: "inline-block",
                          margin: "8px",
                        }}
                      >
                        <Image
                          wrapperStyle={{ width: "100%", height: "100%" }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={qrBase64}
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
            ) : (
              <Col span={24}>
                <Alert type="info" message={t("tool:demo")} />
              </Col>
            )}
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default WifiQrCode;
