import BaseCollection from "common/collections/BaseCollection";
import WorkScheduleModel from "common/models/WorkScheduleModel";
import { WorkScheduleJson, WorkScheduleCollectionJson } from "common/types/WorkSchedule";

class WorkScheduleCollection extends BaseCollection<
  WorkScheduleJson,
  WorkScheduleModel,
  WorkScheduleCollectionJson
> {
  itemsFromJson(jsonItems: WorkScheduleJson[]): WorkScheduleModel[] {
    return jsonItems.map((item) => new WorkScheduleModel(item));
  }
}

export default WorkScheduleCollection;


