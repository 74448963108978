import Role from 'common/constants/Role';
import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import RoleCheck from 'components/RoleCheck';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProjectListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("project:heading_list")}
      siteMenuSelectedKey="/project"
    >
      <RoleCheck roles={[Role.PROJECT_MANAGE]}>
        <PageHeaderButton link="/project/add">
          {t("project:add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default ProjectListHeader;
