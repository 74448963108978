import React from "react";

type Props = {
  size?: "medium" | "small";
  color?: "main" | "gray";
};

const MessageIcon = ({ size, color = "main" }: Props) => {
  let icon;
  switch (size) {
    case "medium":
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.95831 6.125H9.04165"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.08335 10.7508H6.41669L9.01253 12.4775C9.39753 12.7342 9.91669 12.46 9.91669 11.9933V10.7508C11.6667 10.7508 12.8334 9.58415 12.8334 7.83415V4.33415C12.8334 2.58415 11.6667 1.41748 9.91669 1.41748H4.08335C2.33335 1.41748 1.16669 2.58415 1.16669 4.33415V7.83415C1.16669 9.58415 2.33335 10.7508 4.08335 10.7508Z"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;

    case "small":
      icon = (
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.54169 4.375H6.45835"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeWidth="0.8"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.91665 7.67918H4.58331L6.43748 8.91252C6.71248 9.09585 7.08331 8.90001 7.08331 8.56668V7.67918C8.33331 7.67918 9.16665 6.84585 9.16665 5.59585V3.09585C9.16665 1.84585 8.33331 1.01251 7.08331 1.01251H2.91665C1.66665 1.01251 0.833313 1.84585 0.833313 3.09585V5.59585C0.833313 6.84585 1.66665 7.67918 2.91665 7.67918Z"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeWidth="0.8"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.95831 6.125H9.04165"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.08335 10.7508H6.41669L9.01253 12.4775C9.39753 12.7342 9.91669 12.46 9.91669 11.9933V10.7508C11.6667 10.7508 12.8334 9.58415 12.8334 7.83415V4.33415C12.8334 2.58415 11.6667 1.41748 9.91669 1.41748H4.08335C2.33335 1.41748 1.16669 2.58415 1.16669 4.33415V7.83415C1.16669 9.58415 2.33335 10.7508 4.08335 10.7508Z"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
  return icon;
};

export default MessageIcon;
