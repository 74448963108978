import { App, Col, Form, Input, InputNumber, Row } from "antd";
import ShippingHubModel from "common/models/ShippingHubModel";
import ShippingHubRepository from "common/repositories/ShippingHubRepository";
import AddressInput from "components/addressinput/AddressInput";
import Error from "components/LayoutError";
import RegionPicker from "components/RegionPicker";
import FormChangedContext from "contexts/FormChangedContext";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { ShippingHubJsonAddEdit } from "common/types/ShippingHub";
const ShippingHubForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: ShippingHubModel;
  setOpen: (v: boolean) => void;
  setProcessing: (p: boolean) => void;
  onSaveSuccess: (o: ShippingHubModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      description: model.description,
      phone: model.phone,
      display_order: model.display_order,
      region_id: model.region_id > 0 ? model.region_id : null,
      sub_region_id: model.sub_region_id > 0 ? model.sub_region_id : null,
      third_region_id: model.third_region_id > 0 ? model.third_region_id : null,
      regions: {
        region_id: model.region_id,
        sub_region_id: model.sub_region_id,
        sub_sub_region_id: model.third_region_id
      },
      status: model.status,
      country: model.country,
      address: {
        address: model.address,
        lat: model.lat,
        long: model.long,
        map_place_id: model.map_place_id
      }
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ShippingHubJsonAddEdit = {
        id: model.id,
        code: model.code,
        name: formData.name,
        description: formData.description,
        phone: formData.phone,
        display_order: formData.display_order,
        region_id: formData.regions.region_id,
        sub_region_id: formData.regions.sub_region_id,
        third_region_id: formData.regions.sub_sub_region_id,
        status: formData.status,
        country: formData.country,
        address: formData.address.address,
        lat: formData.address.lat,
        long: formData.address.long,
        map_place_id: formData.address.map_place_id
      };

      return submitData;
    },
    [model.id, model.code]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ShippingHubModel = await new ShippingHubRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="shippinghub:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={16} xs={24}>
          <Form.Item
            label={t("shippinghub:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("shippinghub:form.error.error_name_required")
              }
            ]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            label={t("common:display_order_long")}
            name="display_order"
            className="mb-0">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="regions" noStyle>
        <RegionPicker />
      </Form.Item>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item label={t("shippinghub:address")} name="address">
            <AddressInput />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item label={t("shippinghub:phone")} name="phone">
            <Input placeholder={t("shippinghub:phone_placeholder")} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="description" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="country" hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};

export default ShippingHubForm;
