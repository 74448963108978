import BaseCollection from "./BaseCollection";
import PhoneBookModel from "common/models/PhoneBookModel";
import { PhoneBookCollectionJson, PhoneBookJson } from "common/types/PhoneBook";

class PhoneBookCollection extends BaseCollection<
  PhoneBookJson,
  PhoneBookModel,
  PhoneBookCollectionJson
> {
  itemsFromJson(jsonItems: PhoneBookJson[]): PhoneBookModel[] {
    return jsonItems.map((item) => new PhoneBookModel(item));
  }
}
export default PhoneBookCollection;
