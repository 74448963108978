import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import eventEmitter from "common/utils/eventEmitter";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconLink, IconUnlink } from "@tabler/icons-react";

import { LinkAttribute } from "./extensions/HtmlEditorExtensionFullLink";
import LayoutFormHtmlEditorLinkModal from "./HtmlEditorLinkModal";
import HtmlEditorMenuBarButton from "./HtmlEditorMenuBarButton";

const DEFAULT_LINK_ATTRIBUTE: LinkAttribute = {
  href: "",
  title: " ",
  target: "_self",
};

const HtmlEditorMenuBarButtonLink = ({
  editor,
  selectionEmpty,
  setPopupVisible,
}: {
  editor: HtmlEditor;
  selectionEmpty: boolean;
  setPopupVisible: (open: boolean) => void;
}) => {
  const { t } = useTranslation("htmleditor");
  const eventEmitterRef = useRef<any>();
  const [open, setOpen] = useState(false);
  const [editingAttribute, setEditingAttribute] = useState<LinkAttribute>(
    DEFAULT_LINK_ATTRIBUTE
  );

  //clear editing data after close model
  useEffect(() => {
    if (!open) {
      setEditingAttribute(DEFAULT_LINK_ATTRIBUTE);
    }
    setPopupVisible(open);
  }, [open, setPopupVisible]);

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "HTMLEDITOR_BUBBLE_MENU_LINK",
        (data: LinkAttribute) => {
          setEditingAttribute(data);
          setOpen(true);
        }
      );
    }
  });

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);

  if (!editor) {
    return null;
  }

  return (
    <>
      <HtmlEditorMenuBarButton
        active={false}
        disabled={!editor.isActive("link") && selectionEmpty}
        icon={editor.isActive("link") ? <IconUnlink /> : <IconLink />}
        title={editor.isActive("link") ? t("link_edit") : t("link_add")}
        onClick={() => {
          if (editor.isActive("link")) {
            const attr = editor.getAttributes("link");
            setEditingAttribute({
              href: attr.href || "",
              title: attr.title || "",
              target: attr.target || "",
              rel: attr.rel,
            });
          } else {
            setEditingAttribute(DEFAULT_LINK_ATTRIBUTE);
          }

          setOpen(true);
        }}
      />
      <LayoutFormHtmlEditorLinkModal
        open={open}
        setOpen={setOpen}
        attributes={editingAttribute}
        onSubmit={(attributes: LinkAttribute) => {
          editor
            .chain()
            .focus()
            .setLinkExtra({
              target: attributes.target,
              href: attributes.href,
              title: attributes.title,
              rel: attributes.rel,
            })
            .run();
        }}
      />
    </>
  );
};

export default HtmlEditorMenuBarButtonLink;
