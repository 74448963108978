import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

import type { BirthdayInputProps } from "common/interfaces/BirthdayInput";
const BirthdayInput: React.FC<BirthdayInputProps> = ({
  value,
  onChange,
  format,
}: BirthdayInputProps) => {
  const [birthday, setBirthday] = useState(value || "");

  const triggerChange = (changedValue: string) => {
    onChange?.(changedValue);
  };

  const updateBirthday = (newBirthday: string) => {
    setBirthday(newBirthday);
    triggerChange(newBirthday);
  };

  useEffect(() => {
    setBirthday(value || "");
  }, [value]);

  return (
    <>
      <Input
        value={birthday}
        onChange={(e) => updateBirthday(e.target.value)}
      />
    </>
  );
};

export default BirthdayInput;
