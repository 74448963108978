import { BaseModelJson } from 'common/interfaces/BaseModelJson';

import BaseModel from './BaseModel';

import type { ProductReceiptDetailJson } from "common/types/ProductReceiptDetail";

class ProductReceiptDetailModel
  extends BaseModel
  implements BaseModelJson<ProductReceiptDetailJson>
{
  company_id: number;
  creator_id: number;
  id: number;
  product_receipt_id: number;
  product_id: number;
  product_variant_id: number;
  sku: string;
  item_quantity_initial: number;
  item_quantity: number;
  item_quantity_signed: number;
  item_date_deleted: number;
  date_created: number;
  date_modified: number;

  constructor(json: ProductReceiptDetailJson) {
    super();

    this.id = json.id || 0;
    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.sku = json.sku || "";
    this.product_variant_id = json.product_variant_id || 0;
    this.product_id = json.product_id || 0;
    this.product_receipt_id = json.product_receipt_id || 0;
    this.item_quantity_initial = json.item_quantity_initial || 0;
    this.item_quantity = json.item_quantity || 0;
    this.item_quantity_signed = json.item_quantity_signed || 0;
    this.item_date_deleted = json.item_date_deleted || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ProductReceiptDetailJson {
    return {
      id: 0,
      company_id: 0,
      creator_id: 0,
      product_receipt_id: 0,
      product_id: 0,
      product_variant_id: 0,
      sku: "",
      item_quantity_initial: 0,
      item_quantity: 0,
      item_quantity_signed: 0,
      item_date_deleted: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): ProductReceiptDetailJson {
    return {
      id: this.id,
      company_id: this.company_id,
      creator_id: this.creator_id,
      product_receipt_id: this.product_receipt_id,
      product_id: this.product_id,
      product_variant_id: this.product_variant_id,
      sku: this.sku,
      item_quantity_initial: this.item_quantity_initial,
      item_quantity: this.item_quantity,
      item_quantity_signed: this.item_quantity_signed,
      item_date_deleted: this.item_date_deleted,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default ProductReceiptDetailModel;
