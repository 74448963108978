import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import WarehouseFormFilter from "features/warehouse/WarehouseFormFilter";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { RangePickerProps } from "antd/es/date-picker";
import WarehouseModel from "common/models/WarehouseModel";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const InventoryReceiptListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
  warehouseItemsWithLimit
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
  warehouseItemsWithLimit: WarehouseModel[]
}) => {
  const { t } = useTranslation();

  // const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
  //   "warehouse",
  //   Role.INVENTORY_VIEW
  // );

  // FILTER: DATESTARTED AND DATEENDED
  const getDateCreatedFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "date_started" || key === "date_ended")
      .map(([key, val]) => {
        return val;
      }) as number[];
    return (
      +entries[0] > 0 && +entries[1] > 0
        ? [
          dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
          dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY")
        ] || null
        : [null, null] || null
    ) as RangeValue;
  }, [filters]);
  const onChangeDateCreatedFilter: RangePickerProps["onChange"] = (
    value: RangePickerProps["value"],
    dateString: [string, string]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        date_started: +dayjs(
          "00:00, " + value[0]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix(),
        date_ended: +dayjs(
          "23:59, " + value[1]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix()
      });
    } else {
      setFilters({
        ...filters,
        date_started: -1,
        date_ended: -1
      });
    }
  };

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={["direction", "warehouse_id", "type", "status" , "code"]}>
      {/* <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("productreceipt:filter_keyword_placeholder")}
        />
      </Form.Item> */}

      <Form.Item name="code">
        <Input allowClear placeholder={t("productreceipt:id")} />
      </Form.Item>

      <FormSelect
        name="direction"
        options={InventoryReceiptModel.getDirectionList()}
        allowClear
        placeholder={t("Loại phiếu")}
        popupMatchSelectWidth={false}
      />

      <WarehouseFormFilter
        placeholder={t("productreceipt:warehouse_id")}
        name="warehouse_id"
        allowClear
        defaultOptions={warehouseItemsWithLimit.map((i) => ({
          label: i.name,
          value: i.id
        }))}
        popupMatchSelectWidth={false}
      />

      <FormSelect
        name="type"
        placeholder={t("productreceipt:type")}
        options={InventoryReceiptModel.getTypeList()}
        allowClear
        popupMatchSelectWidth={false}
      />

      <FormSelect
        name="status"
        placeholder={t("common:status")}
        options={InventoryReceiptModel.getStatusList()}
        allowClear
        popupMatchSelectWidth={false}
      />

      {/* FILTER: DATESTART AND DATEEND */}
      <Form.Item label={t("common:date_created")} labelCol={{ span: 6 }}>
        <DateFormFilter
          rangeValue={getDateCreatedFilter}
          allowClear={true}
          showNow={false}
          mode="range"
          format="DD/MM/YYYY"
          onRangeChange={onChangeDateCreatedFilter}
        />
      </Form.Item>
    </PageDataFilterForm>
  );
};

export default InventoryReceiptListFilter;
