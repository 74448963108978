import {
  Tabs,
  TabsProps,
} from "antd";
import ProductPublicModel from "common/models/ProductPublicModel";
import StoreModel from "common/models/StoreModel";
import TableInventoryProductPublic from "./TableInventoryProductPublic";
import ProductTag from "../tag/ProductTag";
import ProductRelated from "../productrelated/ProductRelated";
import { t } from "i18next";
import ProductActions from "../form/ProductActions";
import ProductParentChild from "./ProductParentChild";

const ProductItemPublicExpand = ({
  product,
  storeList,
  onSaveSuccess,
  onDeleteSuccess,
  // storeIdActive
}: {
  product: ProductPublicModel;
  storeList?: StoreModel[];
  // storeIdActive : number
  onSaveSuccess: () => void;
  onDeleteSuccess?: (productId: number) => void
}) => {


  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin",
      children: (
        <>
        {/* <ProductParentChild store_id={storeIdActive} product={product}  /> */}
        <ProductActions onSaveSuccess={onSaveSuccess} product={product} onDeleteSuccess={onDeleteSuccess}/>
        </>
      ),
    },

    {
      key: "2",
      label: "Tồn kho",
      children: (
        // <RoleCheck roles={[Role.INVENTORY_VIEW]}>
        <div className=" bg-white">
          <TableInventoryProductPublic
            storeList={storeList}
            product={product}
          />
        </div>
        // </RoleCheck>
      ),
    },

    {
      key: "3",
      label: "Phân loại sản phẩm (tag)",
      children: <ProductTag id={product.id} onSaveSuccess={onSaveSuccess} />,
    },
    {
      key: "4",
      label: "Cấu trúc hiển thị sản phẩm",
      children: (
        <ProductRelated
          data={product}
          id={product.id}
          onSaveSuccess={() => {}}
        />
      ),
    },
  ];
  return (
    <div className="pb-8" style={{ marginLeft: 42 }}>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default ProductItemPublicExpand;
