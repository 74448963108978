import { Button, Form, Input, Modal, Spin } from "antd";
import InventoryStockRepository from "common/repositories/InventoryStockRepository";
import PromotionRepository from "common/repositories/PromotionRepository";
import { SearchResultItem } from "common/types/Pos";
import { PosCartOrderDetail } from "common/types/PosCart";
import ErrorAlert from "components/ErrorAlert";
import dbm from "dbm";
import escapeStringRegexp from "escape-string-regexp";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useCompanySettingStore from "zustands/useCompanySettingStore";
import usePosCartStore from "zustands/usePosCartStore";

const PosRightPromotionOrderGiftModal = ({
  show,
  setShow,
  onSelect,
}: {
  show: boolean;
  setShow: (v: boolean) => void;
  onSelect: (v: PosCartOrderDetail) => void;
}) => {
  const { t } = useTranslation();

  const posCheckOutOfStock = useCompanySettingStore(
    (state) => state.getSetting("pos_check_out_of_stock") || 0
  );

  const [outOfStock, setOutOfStock] = useState(false);
  const [fetchInventory, setFetchInventory] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const activeWarehouseId = usePosCartStore(
    (state) => state.getActiveCart()?.warehouse_id || 0
  );

  const details = usePosCartStore(
    (state) => state.getActiveCart()?.details || []
  );

  const promotions = usePosCartStore(
    (state) => state.getActiveCart()?.promotions || []
  );

  const [sku, setSku] = useState("");
  const [gift, setGift] = useState<PosCartOrderDetail>();
  const [error, setError] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const checkInventory = useCallback(
    async (insertedGift: PosCartOrderDetail) => {
      console.log("checkinventory", insertedGift);
      //sum this variant on cart details
      let requiredQuantityVariantIncludeGift = insertedGift.item_quantity;
      details.forEach((item) => {
        if (item.product_variant_id === insertedGift.product_variant_id) {
          requiredQuantityVariantIncludeGift += item.item_quantity;
        }
      });

      //get inventory stock of inserted sku
      setFetchInventory(true);
      const collection =
        await new InventoryStockRepository().getListProductVariantQuantity(
          insertedGift.product_variant_id.toString(),
          "pospromotiongift"
        );

      setFetchInventory(false);
      //default not out of stock
      let isOutOfStock = false;

      if (!collection.hasError() && collection.items.length > 0) {
        const foundInventoryStock = collection.items[0].stocks.find(
          (i) => i.warehouse_id === activeWarehouseId
        );

        if (typeof foundInventoryStock !== "undefined") {
          console.log(
            "Current available:",
            foundInventoryStock.available,
            "- required:",
            requiredQuantityVariantIncludeGift
          );
          if (
            posCheckOutOfStock &&
            // !collection.items[0].product_sell_on_zero &&
            foundInventoryStock.available < requiredQuantityVariantIncludeGift
          ) {
            isOutOfStock = true;
            console.log("OUT OF STOCK");
          }
        }
      }

      setOutOfStock(isOutOfStock);
    },
    [activeWarehouseId, posCheckOutOfStock, details]
  );

  const doSearch = useCallback(async () => {
    const foundProductVariants: SearchResultItem[] = await dbm
      .getCollection("productvariant")
      .chain()
      .find({
        sku: escapeStringRegexp(sku),
      })
      .simplesort("id")
      .offset(0)
      .limit(1)
      .data();

    //check to add to cart automatically
    if (
      foundProductVariants.length === 1 &&
      foundProductVariants[0].sku === sku
    ) {
      setLoading(true);
      let repository = new PromotionRepository();
      let promotionCheckGift = await repository.posCheckGift({
        promotion_id: promotions.length > 0 ? promotions[0].id : 0,
        product_id: foundProductVariants[0].product_id,
        product_code: foundProductVariants[0].product_code,
        product_option_id: foundProductVariants[0].id,
        sku: sku,
      });
      setLoading(false);

      if (promotionCheckGift.hasError()) {
        setError(promotionCheckGift.error.errors);
      } else {
        //prepare gift with cart type data
        if (promotionCheckGift.sku.length > 0) {
          let item = foundProductVariants[0];

          let insertItem: PosCartOrderDetail = {
            row_identifier: item.id + "-gift",
            item_quantity: 1,
            product_id: item.product_id,
            product_variant_id: item.id,
            sku: item.sku,
            product_sell_on_zero: item.sell_on_zero,
            price: 0,
            price_before_promotion: item.price,
            item_title: item.title,
            item_color: item.color,
            item_size: item.size,
            item_name: item.product_name,
            item_code: item.product_code,
            item_serial_number: item.id + "-gift",
            promotions: [],
            promotion_options: [],
            manual_discount: false,
            manual_discount_type: 0,
            manual_discount_value: 0,
          };
          setGift(insertItem);

          //check gift inventory
          checkInventory(insertItem);
        }
      }
    } else {
      //not found
      setError([t("pos:promotion.gift.notfound")]);
    }
  }, [sku, promotions, checkInventory, t]);

  const onApply = useCallback(() => {
    if (typeof gift !== "undefined") {
      onSelect(gift);
    }
    setGift(undefined);
    setOutOfStock(false);
    setSku("");
    setShow(false);
  }, [gift, onSelect, setShow]);

  return (
    <Modal
      width={480}
      open={show}
      onCancel={handleClose}
      title={t("pos:promotion.gift.heading")}
      footer={[
        <Button
          disabled={typeof gift === "undefined" || fetchInventory || outOfStock}
          key="primary"
          type="primary"
          onClick={() =>
            gift == null || fetchInventory || outOfStock ? null : onApply()
          }
        >
          {t("pos:promotion.gift.apply")}
        </Button>,
      ]}
    >
      <Form.Item label={t("pos:promotion.gift.placeholder")}>
        <Input.Search
          autoFocus={true}
          enterButton={t("pos:promotion.gift.check")}
          value={sku}
          suffix={null}
          loading={loading}
          onChange={(e) => {
            setError([]);
            setGift(undefined);
            setSku(e.currentTarget.value);
          }}
          onSearch={doSearch}
        />
      </Form.Item>
      <div>
        {gift != null ? (
          <div className="alert alert-success">
            {t("pos:promotion.gift.label")}{" "}
            <strong>
              {gift.item_name} (SKU: {gift.sku})
            </strong>
          </div>
        ) : null}

        {fetchInventory ? (
          <>
            <Spin>{t("pos:promotion.gift.check_inventory")}</Spin>
          </>
        ) : (
          <>
            {outOfStock && gift !== null ? (
              <div className="alert alert-danger">
                {t("pos:promotion.gift.out_of_stock")}.{" "}
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    setGift(undefined);
                    setSku("");
                  }}
                >
                  {t("pos:promotion.gift.select_another")}
                </Button>
              </div>
            ) : null}
          </>
        )}

        {error.length > 0 ? (
          <div className="alert alert-danger">
            <ErrorAlert items={error} />
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default PosRightPromotionOrderGiftModal;
