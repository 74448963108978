import React, { useEffect, useState } from "react";
import ProductCategoryList from "features/productcategory/list/ProductCategoryList";
import usePathParams from "hooks/usePathParams";
import ProductCollectionList from "features/productcollection/list/ProductCollectionList";
import ProductCollectionForm from "features/productcollection/form/ProductCollectionForm";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import FormEditFetcher from "components/form/FormEditFetcher";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import ProductCollectionFormHeader from "features/productcollection/form/ProductCollectionFormHeader";

const ProductCollectionPage = () => {
  let com = null;
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new ProductCollectionModel(ProductCollectionModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(
      new ProductCollectionModel(ProductCollectionModel.getDefaultData())
    );
    setErrors([]);
  }, [pathParams.action]);

  switch (pathParams.action) {
    case "add":
      com = <ProductCollectionForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ProductCollectionForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel =
                await new ProductCollectionRepository().getItem(
                  Number(pathParams.id) || 0
                );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<ProductCollectionFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;

    default:
      com = <ProductCollectionList />;
  }

  return com;
};

export default ProductCollectionPage;
