import OfficeApi from "common/api/OfficeApi";
import OfficeCollection from "common/collections/OfficeCollection";
import OfficeModel from "common/models/OfficeModel";
import BaseRepository from "./BaseRepository";
import { GetListProps } from "common/types/Filter";
import type { OfficeJsonAddEdit, FilterOffice } from "common/types/Office";

class OfficeRepository extends BaseRepository<OfficeApi> {
  _api: OfficeApi | null;

  constructor() {
    super();
    this._api = new OfficeApi(true);
  }

  static getDefaultFilters(): FilterOffice {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getFullData(
    companyId: number,
    version: number
  ): Promise<OfficeCollection> {
    return this._api
      ? await this._api.getFullData({ companyId, version })
      : new OfficeCollection();
  }

  async getItems(props: GetListProps<FilterOffice>) {
    return this._api ? await this._api.getList(props) : new OfficeCollection();
  }

  async getItem(id: number): Promise<OfficeModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new OfficeModel(OfficeModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: OfficeJsonAddEdit): Promise<OfficeModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new OfficeModel(OfficeModel.getDefaultData());
    }
  }
}

export default OfficeRepository;
