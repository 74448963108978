import React from "react";

const NextSlideIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5925 34.86L27.0025 23.45C28.35 22.1025 28.35 19.8975 27.0025 18.55L15.5925 7.14001"
        stroke="#292D32"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NextSlideIcon;
