import React from 'react';

import {
    FullscreenControl, GeolocateControl, NavigationControl, ScaleControl
} from '@goongmaps/goong-map-react';

const GeolocateStyle = {
  top: 0,
  left: 0,
  padding: "10px",
};

const FullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: "10px",
};

const NavStyle = {
  top: 72,
  left: 0,
  padding: "10px",
};

const ScaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: "10px",
};
const GoongMapControlPanel = () => {
  return (
    <>
      <GeolocateControl style={GeolocateStyle} />
      <FullscreenControl style={FullscreenControlStyle} />
      <NavigationControl style={NavStyle} />
      <ScaleControl style={ScaleControlStyle} />
    </>
  );
};

export default GoongMapControlPanel;
