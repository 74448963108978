import { Col, Row } from "antd";
import TaxInvoiceDetailModel from "common/models/TaxInvoiceDetailModel";
import TextMoney from "components/TextMoney";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const TaxInvoiceFormSectionPriceSummary = ({
  taxInvoiceDetails
}: {
  taxInvoiceDetails: TaxInvoiceDetailModel[];
}) => {
  const { t } = useTranslation();

  const calculatePrices = useCallback(() => {
    let sumPriceSell = 0;
    let sumPriceTax = 0;
    let sumPriceFinal = 0;

    taxInvoiceDetails.forEach((item) => {
      sumPriceSell += item.price_sell;
      sumPriceTax += item.price_tax;
    });

    sumPriceFinal = sumPriceSell + sumPriceTax;

    const result = {
      sum_price_sell: sumPriceSell,
      sum_price_tax: sumPriceTax,
      sum_price_final: sumPriceFinal
    };

    return result;
  }, [taxInvoiceDetails]);

  return (
    <div className="px-2 pt-2">
      <Row gutter={16} className="mb-4">
        <Col flex={"auto"} className="text-right">
          {t("taxinvoice:sum_price_sell")} :
        </Col>
        <Col flex={"170px"} className="text-right">
          <span className="font-bold">
            <TextMoney money={calculatePrices().sum_price_sell} />
          </span>
        </Col>
      </Row>

      <Row gutter={16} className="mb-4">
        <Col flex={"auto"} className="text-right">
          {t("taxinvoice:sum_price_tax")} :
        </Col>
        <Col flex={"170px"} className="text-right">
          <span className="font-bold">
            <TextMoney money={calculatePrices().sum_price_tax} />
          </span>
        </Col>
      </Row>

      <Row gutter={16} className="px-1 py-3 text-xl bg-green-50">
        <Col flex={"auto"} className="text-xl text-right">
          {t("taxinvoice:sum_price_final")} :
        </Col>
        <Col flex={"170px"} className="text-xl font-bold text-right">
          <TextMoney money={calculatePrices().sum_price_final} />
        </Col>
      </Row>
    </div>
  );
};

export default TaxInvoiceFormSectionPriceSummary;
