import { Upload as UploadAntd } from "antd";
import FileApi from "common/api/FileApi";
import File from "common/constants/File";
import FileModel from "common/models/FileModel";
import { Upload } from "common/types/File";
import ErrorAlert from "components/ErrorAlert";
import FileUploaderItem from "components/file/uploader/FileUploaderItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconPlus } from "@tabler/icons-react";

import type {
  FileInputValue,
  FileInputProps,
} from "common/interfaces/FileInput";
const FileInput: React.FC<FileInputProps> = ({
  value,
  onChange,
  max_item,
  directoryId,
  objectType,
  objectId,
  allowExtensions,
  onUploadCompleted,
  onDeleteCompleted,
  origin,
  listType,
  icon,
}: FileInputProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState(value?.files || []);
  const [errors, setErrors] = useState([]);

  const triggerChange = (changedValue: FileInputValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const updateFiles = (newFiles: Upload[]) => {
    setFiles(newFiles);

    triggerChange({
      files: newFiles,
      file_id_list: newFiles.map((f) =>
        typeof f.response === "object" ? f.response?.id || 0 : 0
      ),
    });
  };

  const onDeleteCompletedProxy = (file: Upload) => {
    //trigger remove from models
    const updatedList = files.filter((f) => f.uid !== file.uid);

    if (
      typeof file.response !== "undefined" &&
      typeof file.response !== "string" &&
      file?.response?.id > 0
    ) {
      if (typeof onDeleteCompleted === "function") {
        onDeleteCompleted(new FileModel(file.response));
      }
    }

    //remove from upload list first
    setFiles(updatedList);
  };

  const uploadPropsFileApi = new FileApi(false).getUploadProps({
    directory_id: directoryId || 0,
    object_type: objectType || File.OBJECTTYPE_ATTACHMENT,
    object_id: objectId || 0,
    origin: origin || "notset-origin",
  });

  //default: Images only
  const acceptExtensions =
    typeof allowExtensions !== "undefined" &&
    allowExtensions &&
    allowExtensions.length > 0
      ? allowExtensions
      : FileModel.getImageExtensions();

  //default: maxitem to 1
  const maxItem = max_item ?? 1;

  useEffect(() => {
    setFiles(value?.files || []);
  }, [value]);

  return (
    <>
      <UploadAntd
        className="mt-1.5"
        {...uploadPropsFileApi}
        multiple={false}
        listType={listType ?? "picture-card"}
        fileList={files}
        accept={
          acceptExtensions.length > 0
            ? acceptExtensions.map((e) => "." + e).join(",")
            : ""
        }
        beforeUpload={() => {
          if (files.length > maxItem) {
            setErrors([
              t("file:form.error.error_multiple_size_exceed", {
                maxFile: maxItem,
              }),
            ]);
            return UploadAntd.LIST_IGNORE;
          } else {
            setErrors([]);
          }

          return true;
        }}
        itemRender={(_originNode, file, _fileList, actions) => {
          //we extract file from state, not directory from `file` object,
          //because on error, the file.response in state will contain string, this is correct
          const foundFile = files.find((f) => f.uid === file.uid);

          return typeof foundFile !== "undefined" ? (
            <FileUploaderItem
              key={foundFile.uid}
              file={foundFile}
              uploadRenderType={
                listType && listType === "text" ? "list" : "thumbnail"
              }
              onDeleteCompleted={(f) => {
                onDeleteCompletedProxy(f);
                actions.remove();
              }}
            />
          ) : null;
        }}
        onChange={({ file, fileList }) => {
          //change the response of error file
          updateFiles(
            fileList.map((f) =>
              f.status === "error"
                ? {
                    ...f,
                    response:
                      typeof f.response !== "undefined" &&
                      typeof f.response.error !== "undefined"
                        ? typeof f.response.error === "string"
                          ? f.response.error
                          : f.response.error.join(".")
                        : "Request Error.",
                  }
                : f
            )
          );

          //append uploaded file to list
          if (
            file?.status === "done" &&
            typeof file?.response === "object" &&
            file?.response?.id > 0 &&
            typeof onUploadCompleted === "function"
          ) {
            const newFile = new FileModel(file.response);
            onUploadCompleted(newFile);
          }
        }}
      >
        {files.length >= maxItem ? null : (
          <div
            className={`${
              listType && listType === "text" ? "flex items-center" : ""
            }`}
          >
            {listType && listType === "text" ? (
              icon
            ) : (
              <IconPlus size="18" style={{ marginRight: 5 }} />
            )}
            <div style={{ marginTop: listType && listType === "text" ? 0 : 8 }}>
              {t("file:select_file")}
            </div>
          </div>
        )}
      </UploadAntd>
      {errors.length > 0 ? (
        <ErrorAlert
          style={{ marginTop: 10 }}
          translate_prefix=""
          items={errors}
          type="warning"
        />
      ) : null}
    </>
  );
};

export default FileInput;
