import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import dbm from "dbm";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React, { useCallback } from "react";

const ReportOrderProductAttributeSize = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const productVariantSizeItemsWithLimit = dbm
    .getCollection("productvariantsize")
    ?.chain()
    .data();
  const formatterProductVariantSizeValue = useCallback(
    (value: number) => {
      return productVariantSizeItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [productVariantSizeItemsWithLimit]
  );
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo bán hàng theo thuộc tính kích cỡ"}
              dataTable="orderdetail"
              dataService="orderdetail"
              keyMapping={[
                {
                  title: "Kích cỡ",
                  name: "itemsize",
                  formatter: (value: number | string) => {
                    return <>{formatterProductVariantSizeValue(+value)}</>;
                  },
                  sortField: "itemsize"
                },
                {
                  title: "Tổng thành tiền",
                  name: "sum_subtotal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(subtotal)"
                },
                {
                  title: "Tổng số lượng",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(quantity)"
                }
              ]}
              dataSelect="sum(subtotal), itemsize, sum(quantity)"
              dataFilter="order_status < 20"
              dataGroupBy="itemsize"
              dataOrderBy="sum(subtotal) DESC"
              dataJoin="order"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        {/* <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={
                "Báo cáo bán hàng theo thuộc tính kích cỡ và danh mục sản phẩm"
              }
              dataTable="orderdetail"
              dataService="orderdetail"
              keyMapping={[
                {
                  title: "Kích cỡ",
                  name: "itemsize",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "itemsize"
                },
                {
                  title: "Danh mục",
                  name: "category",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "category"
                },
                {
                  title: "Tổng thành tiền",
                  name: "sum_subtotal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(subtotal)"
                },
                {
                  title: "Tổng số lượng",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "sum(quantity)"
                }
              ]}
              dataSelect="sum(subtotal), category, itemsize, sum(quantity)"
              dataFilter="order_status < 20"
              dataGroupBy="itemsize, category"
              dataOrderBy="sum(subtotal) DESC"
              dataJoin="order"
              dateRange={getRange()}
            />
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default ReportOrderProductAttributeSize;
