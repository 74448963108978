import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { InventoryJson } from "common/types/Inventory";
import BaseModel from "./BaseModel";

const defaultData: InventoryJson = {
	id: 0,
	company_id: 0,
	status: 0,
	warehouse_id: 0,
	item_id: 0,
	item_type: 0,
	quantity: 0,
	note: "",
	date_created: 0,
	date_modified: 0,
};
class InventoryModel extends BaseModel implements BaseModelJson<InventoryJson> {
	id: number = defaultData.id;
	company_id: number = defaultData.company_id;
	status: number = defaultData.status;
	warehouse_id: number = defaultData.warehouse_id;
	item_id: number = defaultData.item_id;
	item_type: number = defaultData.item_type;
	cost_price?: number = defaultData.cost_price; // hiện không sài
	quantity: number = defaultData.quantity; // số lượng theo kho
	reserved?: number = defaultData.reserved; // hiện không sài
	display_order?: number = defaultData.display_order; // không sài
	note: string = defaultData.note;
	date_created: number = defaultData.date_created;
	date_modified: number = defaultData.date_modified;

	constructor(json: InventoryJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}

	toJson(): InventoryJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getDefaultData(): InventoryJson {
		return { ...defaultData };
	}
}

export default InventoryModel;
