import { SiteLanguage } from "common/types/SiteLanguage";
import emojiFlags from "emoji-flags";
import i18n from "i18n";

class SiteLanguageRepository {
  static getAvailableLanguages(): SiteLanguage {
    let items: SiteLanguage = [
      {
        locale: "vi_VN",
        locale_short: "vi",
        title: i18n.t("common:language_switch.vi_VN"),
        name: "VN",
        icon: emojiFlags.VN.emoji,
      },
      {
        locale: "en_US",
        locale_short: "en",
        title: i18n.t("common:language_switch.en_US"),
        name: "EN",
        icon: emojiFlags.GB.emoji,
      },
    ];

    return items;
  }
}

export default SiteLanguageRepository;
