import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React from "react";

const ReportInventoryOutputProductCategory = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col flex={1}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo xuất hàng theo danh mục"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              keyMapping={[
                {
                  title: "Danh mục",
                  name: "category",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "category"
                },
                {
                  title: "Tổng số sản phẩm",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(quantity)"
                },
                {
                  title: "Phiếu xuất",
                  name: "countdistinct_receipt",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "countdistinct(receipt)"
                }
              ]}
              dataSelect="sum(quantity), category, countdistinct(receipt)"
              dataFilter="productreceipt_direction = 1,productreceipt_status = 9"
              dataGroupBy="category"
              dataOrderBy="sum(quantity) DESC"
              dataJoin="productreceipt"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex={1}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo xuất hàng theo danh mục và kho"}
              dataTable="productreceiptdetail"
              dataService="inventory"
              keyMapping={[
                {
                  title: "Nhà kho",
                  name: "productreceipt_warehouse",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "productreceipt_warehouse"
                },
                {
                  title: "Danh mục",
                  name: "category",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "category"
                },
                {
                  title: "Tổng số sản phẩm",
                  name: "sum_quantity",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(quantity)"
                },
                {
                  title: "Phiếu xuất",
                  name: "countdistinct_receipt",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "countdistinct(receipt)"
                }
              ]}
              dataSelect="sum(quantity), category, productreceipt_warehouse, countdistinct(receipt)"
              dataFilter="productreceipt_direction = 1,productreceipt_status = 9"
              dataGroupBy="productreceipt_warehouse, category"
              dataOrderBy="productreceipt_warehouse ASC"
              dataJoin="productreceipt"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportInventoryOutputProductCategory;
