import EventHistoryModel from "common/models/EventHistoryModel";
import {
	EventHistoryJson,
	EventHistoryCollectionJson,
} from "common/types/EventHistory";
import BaseCollection from "./BaseCollection";
class EventHistoryCollection extends BaseCollection<
	EventHistoryJson,
	EventHistoryModel,
	EventHistoryCollectionJson
> {
	itemsFromJson(jsonItems: EventHistoryJson[]): EventHistoryModel[] {
		return jsonItems.map((item) => new EventHistoryModel(item));
	}
}

export default EventHistoryCollection;
