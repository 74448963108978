import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import React from "react";
import { useTranslation } from "react-i18next";

import DmsNewsFormFilter from "../DmsNewsFormFilter";

const DmsNewsListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}>
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("dmsnews:filter_keyword_placeholder")}
        />
      </Form.Item>

      <DmsNewsFormFilter
        placeholder={t("dmsnews:news_category_id")}
        name="news_category_id"
        allowClear
        popupMatchSelectWidth={false}
      />
    </PageDataFilterForm>
  );
};

export default DmsNewsListFilter;
