import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ApiKeyListHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("apikey:heading_list")}
      siteMenuSelectedKey="/apikey"
    >
      {children}
    </PageHeader>
  );
};

export default ApiKeyListHeader;
