import InventoryBySku from "features/inventory/InventoryBySku";
import InventoryProduct from "features/inventory/product/InventoryProduct";
import InventoryWaiting from "features/inventory/waiting/InventoryWaiting";
import usePathParams from "hooks/usePathParams";

const InventoryPage = () => {
	const [pathParams] = usePathParams();

	let com = null;

	switch (pathParams.action) {
		case "waiting":
			com = <InventoryWaiting />;
			break;

		case "product":
			com = <InventoryProduct />;
			break;
		default:
			com = <InventoryBySku />;
	}

	return com;
};

export default InventoryPage;
