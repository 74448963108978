import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { CheckinJson } from "common/types/Checkin";
import BaseModel from "./BaseModel";
import { FileJson } from "common/types/File";
import FileModel from "./FileModel";
import i18n from "i18n";
import { SelectOption } from "common/types/SelectOption";
class CheckinModel extends BaseModel implements BaseModelJson<CheckinJson> {
  company_id: number;
  office_id: number;
  department_id: number;
  creator_id: number;
  employee_id: number;
  cwsg_id: number;
  id: number;
  latitude: number;
  longitude: number;
  work_schedule_range_id: number;
  image: FileJson;
  status: number;
  type: number;
  is_late: number;
  is_final: number;
  verify: number;
  duration: number;
  datecreated: number;
  datemodified: number;

  constructor(json: CheckinJson) {
    super();
    this.id = json.id || 0;
    this.company_id = json.company_id || 0;
    this.office_id = json.office_id || 0;
    this.department_id = json.department_id || 0;
    this.creator_id = json.creator_id || 0;
    this.employee_id = json.employee_id || 0;
    this.cwsg_id = json.cwsg_id || 0;
    this.latitude = json.latitude || 0;
    this.longitude = json.longitude || 0;
    this.work_schedule_range_id = json.work_schedule_range_id || 0;
    this.image = json.image || 0;
    this.status = json.status || 0;
    this.type = json.type || 0;
    this.is_late = json.is_late || 0;
    this.is_final = json.is_final || 0;
    this.verify = json.verify || 0;
    this.duration = json.duration || 0;
    this.datecreated = json.datecreated || 0;
    this.datemodified = json.datemodified || 0;
  }

  toJson(): CheckinJson {
    return {
      id: this.id,
      company_id: this.company_id,
      office_id: this.office_id,
      department_id: this.department_id,
      creator_id: this.creator_id,
      employee_id: this.employee_id,
      cwsg_id: this.cwsg_id,
      latitude: this.latitude,
      longitude: this.longitude,
      work_schedule_range_id: this.work_schedule_range_id,
      image: this.image,
      status: this.status,
      type: this.type,
      is_late: this.is_late,
      is_final: this.is_final,
      verify: this.verify,
      duration: this.duration,
      datecreated: this.datecreated,
      datemodified: this.datemodified,
    };
  }

  static getDefaultData(): CheckinJson {
    return {
      id: 0,
      company_id: 0,
      office_id: 0,
      department_id: 0,
      creator_id: 0,
      employee_id: 0,
      cwsg_id: 0,
      latitude: 0,
      longitude: 0,
      work_schedule_range_id: 0,
      image: FileModel.getDefaultData(),
      status: 0,
      type: 0,
      is_late: 0,
      is_final: 0,
      verify: 0,
      duration: 0,
      datecreated: 0,
      datemodified: 0,
    };
  }
  static getCheckinTypeList(): SelectOption[] {
    return [
      {
        value: 0,
        label: i18n.t("checkin:checkout"),
      },
      {
        value: 1,
        label: i18n.t("checkin:checkin"),
      },
    ];
  }
  static getCheckinType(type: number) {
    return type === 0 ? i18n.t("checkin:checkout") : i18n.t("checkin:checkin");
  }
}

export default CheckinModel;
