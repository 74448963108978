import { CheckOutlined } from "@ant-design/icons";
import { IconCheck, IconPlus, IconSearch } from "@tabler/icons-react";
import {
	Checkbox,
	Col,
	Empty,
	FormItemProps,
	Popover,
	Row,
	Typography,
} from "antd";
import { AutoComplete, Form, Input } from "antd";
import { DefaultOptionType } from "antd/es/select";
import BaseCollection from "common/collections/BaseCollection";
import BaseModel from "common/models/BaseModel";
import { GetListProps } from "common/types/Filter";
import { Filter } from "common/types/Filter";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props<M, F, C> = {
	disabled?: boolean;
	fetchRepository: (f: F) => Promise<C>;
	renderLabel: (item: M) => React.ReactNode;
	filters?: F;
	keyValue: keyof M;
	formItemProps: FormItemProps;
	defaultFilters: F;
	showFilters?: Array<keyof Partial<F>>;
	defaultUseFilters?: Array<keyof Partial<F>>;
};

type Filters = {
	[key: string]: any;
} & {};

const SearchComponent = <
	M extends Object,
	F extends Filter,
	C extends BaseCollection<any, any, any>
>(
	props: Props<M, F, C>
) => {
	const { t } = useTranslation();
	const {
		filters,
		disabled,
		fetchRepository,
		renderLabel,
		keyValue,
		formItemProps,
		defaultFilters,
		showFilters,
		defaultUseFilters,
	} = props;

	const Filters = defaultFilters as Filters;
	const [errors, setErrors] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<DefaultOptionType[]>([]);
	const [keyword, setKeyword] = useState("");
	const [selected, setSelected] = useState<string | number | null>(null);

	const [useFilters, setUseFilters] = useState(
		defaultUseFilters ?? showFilters ?? []
	);
	const [open, setOpen] = useState(false);

	const fetchData = useCallback(async () => {
		setLoading(true);

		const f = useFilters.reduce((acc: any, key: any) => {
			if (typeof Filters[key] === "number" && !isNaN(+keyword)) {
				acc[key] = +keyword;
			} else if (typeof Filters[key] === "string") {
				acc[key] = keyword;
			}
			return acc;
		}, {} as F);
		const response = await fetchRepository({
			...defaultFilters,
			...f,
		});

		if (response.hasError()) {
			setErrors(response.error.errors);
		} else {
			setOptions(
				response.items.map((item) => {
					return {
						value: item[keyValue],
						label: <Typography.Text>{renderLabel(item)}</Typography.Text>,
					};
				})
			);
		}

		setLoading(false);
	}, [keyword, useFilters]);

	const debouncedFetchData = useCallback(debounce(fetchData, 500), [fetchData]);

	///////////////////////////////////
	const filterOptions = useMemo(() => {
		const defaultKeys: Array<keyof Partial<Filter>> = [
			"page",
			"limit",
			"sortby",
			"sorttype",
			// "keyword",
			"status",
		];
		// const hideF = showFilters ?? [];
		// const hidenFilterKeys = [...defaultKeys, ...hideF];
		// Create a new object excluding the default keys
		const filteredObject = Object.keys(defaultFilters).reduce((acc, key) => {
			if (showFilters && showFilters.includes(key as keyof Filter)) {
				acc[key as keyof F] = defaultFilters[key as keyof F];
			}
			return acc;
		}, {} as Partial<F>);

		return filteredObject;
	}, [showFilters, filters]);

	///////////////////////////////////
	useEffect(() => {
		if (keyword) {
			debouncedFetchData();
		}
		return () => {
			debouncedFetchData.cancel();
		};
	}, [debouncedFetchData]);

	return (
		<Form.Item {...formItemProps}>
			<AutoComplete
				notFoundContent={<Empty />}
				options={options.map((option) => ({
					value: option.value,
					label: (
						<>
							{option.label}
							{selected === option.value && (
								<CheckOutlined style={{ marginLeft: 10 }} />
							)}
						</>
					),
				}))}
				onSelect={(val) => setSelected(val)}
				// style={{ width: 300 }}
				// onSearch={(text) => setKeyword(text)}
				disabled={disabled}
				value={selected}>
				<Input.Search
					placeholder="Tìm kiếm"
					loading={loading}
					onChange={(e) => setKeyword(e.target.value)}
					enterButton={
						<Popover
							trigger="click"
							open={open}
							onOpenChange={(visible) => {
								setOpen(visible);
							}}
							content={
								<div className="relative max-w-[90vw]  overflow-auto md:max-w-[70vw] max-h-[70vh]">
									<div className="p-2 bg-gray-200 ">
										{t("Chọn field search")}:
									</div>
									<div className="p-2">
										<Row gutter={[16, 4]}>
											{Object.entries(filterOptions).map((item, index) => {
												const [key, value] = item;
												return (
													<Col key={index}>
														<>
															<span>{t(`common:search.${key}`)}</span>
															<Checkbox
																className="ml-1"
																value={key}
																onChange={(e) => {
																	const isExited = useFilters.some(
																		(i) => i === key
																	);

																	if (isExited) {
																		setUseFilters((prev) =>
																			prev.filter((i) => i !== key)
																		);
																	} else {
																		if (Filters.hasOwnProperty(key)) {
																			setUseFilters((prev: any) =>
																				[...prev].concat(key)
																			);
																		}
																	}
																}}
																defaultChecked={useFilters.some(
																	(i) => i === key
																)}></Checkbox>
														</>
													</Col>
												);
											})}
										</Row>
									</div>
								</div>
							}
							placement="topRight"
							defaultOpen>
							<IconSearch className="py-1"></IconSearch>
						</Popover>
					}
				/>
			</AutoComplete>
		</Form.Item>
	);
};

export default SearchComponent;
