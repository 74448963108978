import i18n from "i18n";

import { FileJson } from "common/types/File";
import { DetailProduct } from "common/types/Comment";
import { SelectOption } from "common/types/SelectOption";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { CommentJson, ItemTypeSelectOption } from "common/types/Comment";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

class CommentModel extends BaseModel implements BaseModelJson<CommentJson> {
  id: number;
  title: string;
  name: string;
  phone: string;
  email: string;
  images: FileJson[];
  detail: string;
  item_id: string;
  item_type: string;
  parent_id: number;
  has_child: number;
  rate: number;
  has_notify: number;
  has_replied: number;
  note: string;
  tag: string;
  type: number;
  like: number;
  dislike: number;
  status: number;
  isdeleted: number;
  date_created: number;
  date_modified: number;
  date_deleted: number;
  count_chill: number;
  employee_id: number;
  customer_id: number;
  item_detail: DetailProduct;

  constructor(json: CommentJson) {
    super();

    this.title = json.title || "";
    this.id = json.id || 0;
    this.name = json.name || "";
    this.phone = json.phone || "";
    this.email = json.email || "";
    this.images = json.images || [];
    this.detail = json.detail || "";
    this.item_id = json.item_id || "";
    this.item_type = json.item_type || "";
    this.parent_id = json.parent_id || 0;
    this.has_child = json.has_child || 0;
    this.rate = json.rate || 1;
    this.has_notify = json.has_notify || 0;
    this.has_replied = json.has_replied || 0;
    this.note = json.note || "";
    this.tag = json.tag || "";
    this.type = json.type || 0;
    this.like = json.like || 0;
    this.dislike = json.dislike || 0;
    this.status = json.status || 0;
    this.isdeleted = json.isdeleted || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_deleted = json.date_deleted || 0;
    this.count_chill = json.count_chill || 0;
    this.employee_id = json.employee_id || 0;
    this.customer_id = json.customer_id || -1;
    this.item_detail = json.item_detail || {
      data_gender: "",
      data_handle: "",
      data_image: "",
      data_title: "",
      data_vendor: "",
    };
  }

  toJson(): CommentJson {
    return {
      employee_id: this.employee_id,
      id: this.id,
      title: this.title,
      customer_id: this.customer_id,
      name: this.name,
      phone: this.phone,
      email: this.email,
      images: this.images,
      detail: this.detail,
      item_id: this.item_id,
      item_type: this.item_type,
      parent_id: this.parent_id,
      has_child: this.has_child,
      rate: this.rate,
      has_notify: this.has_notify,
      has_replied: this.has_replied,
      note: this.note,
      tag: this.tag,
      type: this.type,
      like: this.like,
      dislike: this.dislike,
      status: this.status,
      isdeleted: this.isdeleted,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_deleted: this.date_deleted,
      count_chill: this.count_chill,
      item_detail: this.item_detail,
    };
  }

  static getDefaultData(): CommentJson {
    return {
      id: 0,
      title: "",
      name: "",
      phone: "",
      email: "",
      images: [],
      detail: "",
      item_id: "",
      item_type: "",
      parent_id: 0,
      has_child: 0,
      rate: 1,
      has_notify: 0,
      has_replied: 0,
      note: "",
      tag: "",
      type: 0,
      like: 0,
      dislike: 0,
      status: 0,
      isdeleted: 0,
      date_created: 0,
      date_modified: 0,
      date_deleted: 0,
      count_chill: 0,
      employee_id: 0,
      customer_id: -1,
      item_detail: {
        data_gender: "",
        data_handle: "",
        data_image: "",
        data_title: "",
        data_vendor: "",
      },
    };
  }

  static getType(value?: number): SelectOption[] {
    const selectOption = [
      {
        label: i18n.t("comment:model.review"),
        value: 1,
      },
      {
        label: i18n.t("comment:model.comment"),
        value: 2,
      },
      {
        label: i18n.t("comment:model.qa"),
        value: 3,
      },
    ];
    if (value) {
      return selectOption.filter((s) => s.value === value);
    }
    return selectOption;
  }

  static getStatus(): SelectOption[] {
    return [
      {
        label: i18n.t("comment:model.approved"),
        value: 1,
      },
      {
        label: i18n.t("comment:model.not_approved"),
        value: 2,
      },
      {
        label: i18n.t("Tất cả"),
        value: -1,
      },
    ];
  }

  static getItemType(): ItemTypeSelectOption[] {
    return [
      {
        value: "product",
        label: i18n.t("comment:model.product"),
      },
      {
        value: "blog",
        label: i18n.t("comment:model.blog"),
      },
    ];
  }

  static getCustomerType(): SelectOption[] {
    return [
      {
        label: i18n.t("comment:form.passersby"),
        value: 0,
      },
      {
        label: i18n.t("comment:form.customer"),
        value: 1,
      },
      {
        label: i18n.t("Admin"),
        value: 2,
      },
      {
        label: i18n.t("Khách hàng & Vãng lai"),
        value: 3,
      },
    ];
  }

  static getDefaultImagesArray = (files: FileJson[]) => {
    return files.map((f) => new FileModel(f));
  };
}

export default CommentModel;
