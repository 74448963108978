import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductVariantSizeListHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("productvariantsize:heading_list")}
      siteMenuSelectedKey="/productvariantsize"
      siteMenuOpenKey="product"
    >
      {children}
    </PageHeader>
  );
};

export default ProductVariantSizeListHeader;
