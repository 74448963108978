import Company from "common/constants/Company";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import type { CompanyJson } from "common/types/Company";
import { EmployeeJson } from "common/types/Employee";
import EmployeeModel from "./EmployeeModel";
class CompanyModel {
  id: number;
  user_id: number;
  name: string;
  domain: string;
  screenname: string;
  email: string;
  phone: string;
  region: number;
  status: number;
  plan: number;
  quota: Record<string, number>;
  base_quota: Record<string, number>;
  employee: EmployeeJson;

  constructor(json: CompanyJson) {
    this.id = json.id || 0;
    this.user_id = json.user_id || 0;
    this.name = json.name || "";
    this.domain = json.domain || "";
    this.screenname = json.screenname || "";
    this.email = json.email || "";
    this.phone = json.phone || "";
    this.region = json.region || 0;
    this.status = json.status || 0;
    this.plan = json.plan || 0;
    this.quota =
      Array.isArray(json.quota) && json.quota.length === 0 ? {} : json.quota;
    this.base_quota = json.base_quota || {};
    this.employee = json.employee || EmployeeModel.getDefaultData();
  }

  static getDefaultData(): CompanyJson {
    return {
      id: 0,
      user_id: 0,
      name: "",
      domain: "",
      screenname: "",
      email: "",
      phone: "",
      region: 0,
      status: 0,
      plan: 0,
      quota: {},
      base_quota: {},
      employee: EmployeeModel.getDefaultData(),
    };
  }

  toJson(): CompanyJson {
    return {
      id: this.id,
      user_id: this.user_id,
      name: this.name,
      domain: this.domain,
      screenname: this.screenname,
      email: this.email,
      phone: this.phone,
      region: this.region,
      status: this.status,
      plan: this.plan,
      quota: this.quota,
      base_quota: this.base_quota,
      employee: this.employee,
    };
  }

  static getPlanList(): SelectOption[] {
    return [
      {
        value: Company.PLAN_01,
        label: i18n.t("company:plan.PLAN_01"),
      },
      {
        value: Company.PLAN_02,
        label: i18n.t("company:plan.PLAN_02"),
      },
      {
        value: Company.PLAN_03,
        label: i18n.t("company:plan.PLAN_03"),
      },
      {
        value: Company.PLAN_ON_PREMISE,
        label: i18n.t("company:plan.PLAN_ON_PREMISE"),
      },
    ];
  }

  static getPlan(value: number): SelectOption | undefined {
    return this.getPlanList().find((item) => item.value === value);
  }

  // getApiBaseUrl(): string {
  //   if (this.domain.length > 0) {
  //     return "https://" + this.domain + "/v1";
  //   } else {
  //     //eg: https://abc.cropany.com/v1
  //     return (
  //       "https://" +
  //       this.screenname +
  //       "." +
  //       process.env.REACT_APP_WS_DOMAIN +
  //       "/v1"
  //     );
  //   }
  // }

  // getIdentityUrl(): string {
  //   if (this.domain.length > 0) {
  //     return "https://" + this.domain + "/account/login";
  //   } else {
  //     //eg: https://abc.cropany.com/account/login
  //     return (
  //       "https://" +
  //       this.screenname +
  //       "." +
  //       process.env.REACT_APP_IDENTITY_DOMAIN
  //     );
  //   }
  // }
}

export default CompanyModel;
