import { Form, Select } from 'antd';
import WarehouseRepository from 'common/repositories/WarehouseRepository';
import { WarehouseJson } from 'common/types/Warehouse';
import useDatabaseTable from 'hooks/useDatabaseTable';
import React, { useCallback, useEffect, useState } from 'react';

const SelectWarehouse = (props: {
  values?: number[];
  multiple?: boolean;
  name?: string;
  placeholder?: string;
  isMulti?: boolean;
  className?: string;
  icon?: React.ReactNode;
  width?: number;
  onChange: (v: number[]) => void;
}) => {
  const [dataSource, setDataSource] = useState<WarehouseJson[]>([]);

  const fetchData = useCallback(
    async () => {
      const res = await new WarehouseRepository().getItems({ filters: WarehouseRepository.getDefaultFilters() })
      let warehouses: WarehouseJson[] = res.toJson().items

      setDataSource(warehouses);
    },
    []
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const warehouseFilteredOptions =
    (dataSource &&
      dataSource
        .filter((o) => o.id > 0)
        .map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })) ||
    [];

  let className = "ant-select-item-option-selected-hide";
  if (typeof props.className !== "undefined") {
    className += " " + props.className;
  }

  const onChangeHandle = (value: any) => {
    if (typeof props.onChange !== "undefined") {
      if (typeof value != "undefined") {
        if (Array.isArray(value)) {
          props.onChange(value);
        } else {
          props.onChange([value]);
        }
      } else {
        props.onChange([]);
      }
    }
  };

  return (
    <Form.Item noStyle>
      <Select<number[]>
        {...(typeof props.icon !== "undefined" && { suffixIcon: props.icon })}
        virtual={false}
        popupClassName={className}
        style={{ width: props.width || "100%" }}
        onChange={onChangeHandle}
        placeholder={props.placeholder}
        allowClear={true}
        showSearch={true}
        mode={props.multiple ? "multiple" : undefined}
        defaultValue={props.values || []}
        optionFilterProp="label"
        filterOption={(input: any, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.label
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[đĐ]/g, "d")
            .toLowerCase()
            .indexOf(
              input
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[đĐ]/g, "d")
                .toLowerCase()
            ) >= 0
        }
        options={warehouseFilteredOptions}
      ></Select>
    </Form.Item>
  );
};

export default SelectWarehouse;
