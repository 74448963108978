import { DeleteOutlined } from "@ant-design/icons";
import {
	Button,
	Col,
	Collapse,
	Form,
	Image as ImageAntd,
	Input,
	Popconfirm,
	Row,
	Select,
	Tag,
} from "antd";
import { FormListFieldData } from "antd/lib/form";
import { Image } from "common/types/Shop";
import { ImageStoreData } from "common/types/Shop";
import UploadEvent from "features/event/form/components/UploadEvent";
import { chunk } from "lodash";
import React, { useMemo, useState } from "react";

type Props = {
	initialValue: ImageStoreData;
	onFinish?: (data: ImageStoreData) => void;
};
export default function ImageForm({ initialValue, onFinish }: Props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onSubmit = (formData: ImageStoreData) => {
		setLoading(true);

		// onFinish && onFinish(formData);

		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};

	const imgsChunk = useMemo(() => {
		return chunk(initialValue.image, 2);
	}, [initialValue]);

	const findIndexInChunks = (
		data: Image[],
		chunkedData: Image[][],
		item: Image
	) => {
		for (let chunkIndex = 0; chunkIndex < chunkedData.length; chunkIndex++) {
			const chunk = chunkedData[chunkIndex];
			for (let itemIndex = 0; itemIndex < chunk.length; itemIndex++) {
				const chunkItem = chunk[itemIndex];
				if (
					chunkItem.alt === item.alt &&
					chunkItem.url === item.url &&
					chunkItem.type === item.type
				) {
					return { chunkIndex, itemIndex };
				}
			}
		}
		return null;
	};

	return (
		<div>
			<Form form={form} initialValues={initialValue} onFinish={onSubmit}>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item label="Tiêu đề" labelCol={{ span: 24 }} name={"title"}>
							<Input></Input>
						</Form.Item>
					</Col>

					<Col
						span={24}
						className="max-h-[60vh] overflow-y-auto place-content-center py-2">
						<Form.List name={"image"}>
							{(fields, { add, remove }) => {
								return (
									<Row gutter={8}>
										{/* <Collapse
											className="w-full"
											items={fields.map((field, index) => {
												const type = initialValue.image?.[field.key]?.type || 1;
												return {
													label: (
														<div className="flex justify-between items-center">
															<div>
																Hình ảnh {index + 1}{" "}
																<Tag color={type === 1 ? "blue" : ""}>
																	{type === 1 ? "Ảnh cao" : "Ảnh ngắn"}
																</Tag>
															</div>
															<Popconfirm
																onConfirm={() => remove(field.key)}
																title={<>Bạn chắc chắn muốn xóa ?</>}>
																<Button size="small">Xóa</Button>
															</Popconfirm>
														</div>
													),
													children: (
														<Row>
															<Col span={24}>
																<Form.Item
																	label="alt"
																	name={[field.name, "alt"]}>
																	<Input></Input>
																</Form.Item>

																<Form.Item
																	label="url"
																	name={[field.name, "url"]}>
																	<UploadEvent
																		height={60}
																		width={60}
																		defaultUrl={
																			initialValue.image?.[field.key]?.url || ""
																		}
																		onSuccess={(url) => {
																			form.setFieldValue(
																				["image", field.name, "url"],
																				url
																			);
																		}}></UploadEvent>
																</Form.Item>
																<Form.Item name={[field.name, "type"]}>
																	<Select
																		options={[
																			{
																				label: "Dài",
																				value: 1,
																			},
																			{
																				label: "Ngắn",
																				value: 2,
																			},
																		]}></Select>
																</Form.Item>
															</Col>
														</Row>
													),
												};
											})}></Collapse> */}
										<LayoutMapChunk
											arr={initialValue.image}
											fields={fields}
											itemRender={(field) => {
												return (
													<Row>
														<Col span={24}>
															{/* <Form.Item label="alt" name={[field.name, "alt"]}>
																<Input></Input>
															</Form.Item> */}

															<Form.Item
																className="mb-0"
																name={[field.name, "url"]}>
																<UploadEvent
																	styleCard={{ width: 100 }}
																	actionCard={[
																		<DeleteOutlined
																			onClick={() => {
																				remove(field.key);
																			}}
																		/>,
																	]}
																	defaultUrl={
																		initialValue.image?.[field.key]?.url || ""
																	}
																	onSuccess={(url) => {
																		form.setFieldValue(
																			["image", field.name, "url"],
																			url
																		);
																	}}></UploadEvent>
															</Form.Item>
															{/* <Form.Item name={[field.name, "type"]}>
																<Select
																	options={[
																		{
																			label: "Dài",
																			value: 1,
																		},
																		{
																			label: "Ngắn",
																			value: 2,
																		},
																	]}></Select>
															</Form.Item> */}
														</Col>
													</Row>
												);
											}}></LayoutMapChunk>
										<Col span={24} className="mt-2">
											<Button
												onClick={() => {
													add({ url: "", type: 1, alt: "ALT Image" });
												}}
												size="small"
												type="dashed">
												Thêm
											</Button>
										</Col>
									</Row>
								);
							}}
						</Form.List>
					</Col>

					<Col span={24} className="mt-2">
						<Form.Item className="text-right">
							<Button type="primary" loading={loading} htmlType="submit">
								Xác nhận
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	);
}

const LayoutMapChunk = ({
	arr,
	fields,
	itemRender,
}: {
	arr: Image[];
	fields: FormListFieldData[];
	itemRender: (field: FormListFieldData) => React.ReactNode;
}) => {
	const arrChunk = useMemo(() => {
		return chunk(fields, 2);
	}, [fields]);

	const findFieldItem = (findex: number, subIndex: number) => {
		const item = fields.find((i, index) => {
			const firstIndex = Math.floor(index / 2);
			const lastIndex = index - firstIndex * 2;
			if (firstIndex === findex && lastIndex === subIndex) {
				return true;
			} else {
				return false;
			}
		});

		return item;
	};

	return (
		<div className="grid grid-cols-2 gap-2 mx-auto">
			{arrChunk.map((item, index) => {
				return (
					<div className="flex flex-col gap-2 h-auto w-auto">
						{item.map((subItem, subIndex) => {
							const fieldItem = findFieldItem(index, subIndex);

							const itemArr = arr.find((i, ix) => {
								if (fieldItem) {
									return fieldItem.key === ix;
								}
								return false;
							});
							return (
								<div
									className={` w-full p-1 ${
										itemArr
											? itemArr.type === 1
												? "h-auto"
												: "h-auto"
											: "h-auto"
									}`}>
									{fieldItem && itemRender(fieldItem)}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};
