import { InputNumber } from 'antd';
import { PosCartOrderDetail } from 'common/types/PosCart';

const PosCartItemQuantityInput = ({
  item,
  onChangeQuantity,
}: {
  item: PosCartOrderDetail;
  onChangeQuantity: (v: number) => void;
}) => {
  //need to check sku vs weightbarcode to enable precision (for KG input, suchas: vegetable, meat..)
  const precision = 0;

  return (
    <InputNumber
      disabled={item.item_quantity < 0}
      value={item.item_quantity}
      onChange={(value) => onChangeQuantity(value ?? 0)}
      style={{ width: 80, backgroundColor: "#ffffff", borderWidth: 0 }}
      step={1}
      size="large"
      min={item.item_quantity < 0 ? item.item_quantity : 1}
      precision={precision}
    />
  );
};

export default PosCartItemQuantityInput;
