import { ShippingOrderFromPointJson, ShippingOrderToPointJson } from 'common/types/ShippingOrder';

class ShippingOrderProvider {
  changeOrderWeight: boolean;
  setChangeOrderWeight: (v: boolean) => void;
  changeOrderFromPoint: boolean;
  setChangeOrderFromPoint: (v: boolean) => void;
  changeOrderToPoint: boolean;
  setChangeOrderToPoint: (v: boolean) => void;
  orderWeight: number;
  setOrderWeight: (v: number) => void;
  orderFromPoint: ShippingOrderFromPointJson;
  setOrderFromPoint: (v: ShippingOrderFromPointJson) => void;
  orderToPoint: ShippingOrderToPointJson;
  setOrderToPoint: (v: ShippingOrderToPointJson) => void;
  totalDistance: number;
  setTotalDistance: (v: number) => void;

  constructor({
    changeOrderWeight,
    setChangeOrderWeight,
    changeOrderFromPoint,
    setChangeOrderFromPoint,
    changeOrderToPoint,
    setChangeOrderToPoint,
    orderWeight,
    setOrderWeight,
    orderFromPoint,
    setOrderFromPoint,
    orderToPoint,
    setOrderToPoint,
    totalDistance,
    setTotalDistance,
  }: {
    changeOrderWeight: boolean;
    setChangeOrderWeight: (v: boolean) => void;
    changeOrderFromPoint: boolean;
    setChangeOrderFromPoint: (v: boolean) => void;
    changeOrderToPoint: boolean;
    setChangeOrderToPoint: (v: boolean) => void;
    orderWeight: number;
    setOrderWeight: (v: number) => void;
    orderFromPoint: ShippingOrderFromPointJson;
    setOrderFromPoint: (v: ShippingOrderFromPointJson) => void;
    orderToPoint: ShippingOrderToPointJson;
    setOrderToPoint: (v: ShippingOrderToPointJson) => void;
    totalDistance: number;
    setTotalDistance: (v: number) => void;
  }) {
    this.changeOrderWeight = changeOrderWeight;
    this.setChangeOrderWeight = setChangeOrderWeight;
    this.changeOrderFromPoint = changeOrderFromPoint;
    this.setChangeOrderFromPoint = setChangeOrderFromPoint;
    this.changeOrderToPoint = changeOrderToPoint;
    this.setChangeOrderToPoint = setChangeOrderToPoint;
    this.orderWeight = orderWeight;
    this.setOrderWeight = setOrderWeight;
    this.orderFromPoint = orderFromPoint;
    this.setOrderFromPoint = setOrderFromPoint;
    this.orderToPoint = orderToPoint;
    this.setOrderToPoint = setOrderToPoint;
    this.totalDistance = totalDistance;
    this.setTotalDistance = setTotalDistance;
  }
}

export default ShippingOrderProvider;
