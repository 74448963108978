import { IconUser } from "@tabler/icons-react";
import { Card, Col, Form, Input, Row } from "antd";
import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import FormSelect from "components/form/FormSelect";
import React from "react";
import { useTranslation } from "react-i18next";

const TaxInvoiceFormSectionBuyerInfo = () => {
  const { t } = useTranslation();
  return (
    <Card
      title={
        <span className="uppercase">
          <IconUser className="mr-1 -mt-1" />
          {t("taxinvoice:group_buyer")}
        </span>
      }
      headStyle={{ background: "#eee" }}
    >
      <Row gutter={32}>
        <Col md={8} xs={8}>
          <Form.Item label={t("taxinvoice:buyer_name")} name="buyer_name">
            <Input />
          </Form.Item>
        </Col>
        <Col md={8} xs={8}>
          <Form.Item label={t("taxinvoice:tax_number")} name="buyer_tax_number">
            <Input />
          </Form.Item>
        </Col>
        <Col md={8} xs={8}>
          <Form.Item label={t("taxinvoice:phone")} name="buyer_phone">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col md={8} xs={8}>
          <FormSelect
            label={t("taxinvoice:payment_method")}
            name="buyer_receive_method"
            placeholder={t("common:status")}
            options={TaxInvoiceModel.getPaymentMethodList()}
            allowClear
            popupMatchSelectWidth={false}
          />
        </Col>
        <Col md={8} xs={8}>
          <Form.Item
            label={t("taxinvoice:account_number")}
            name="buyer_account_number"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col md={16} xs={24}>
          <Form.Item
            label={t("taxinvoice:address")}
            name="buyer_address"
            className="mb-0"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default TaxInvoiceFormSectionBuyerInfo;
