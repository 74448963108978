import BaseCollection from 'common/collections/BaseCollection';
import LogAuditModel from 'common/models/LogAuditModel';
import { LogAuditCollectionJson, LogAuditJson } from 'common/types/LogAudit';

class LogAuditCollection extends BaseCollection<
  LogAuditJson,
  LogAuditModel,
  LogAuditCollectionJson
> {
  itemsFromJson(jsonItems: LogAuditJson[]): LogAuditModel[] {
    return jsonItems.map((item) => new LogAuditModel(item));
  }
}

export default LogAuditCollection;
