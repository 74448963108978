import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";

//////////////////////////////////
//Phần thay thế
import { FilterTemplate, TemplateJsonAddEdit } from "common/types/Template";
import TemplateCollection from "common/collections/TemplateCollection";
import TemplateModel from "common/models/TemplateModel";

const SERVICE_URL = "/";
class TemplateApi extends BaseApi {
  //
  async getList(props: FilterTemplate): Promise<TemplateCollection> {
    let collection = new TemplateCollection();
    const convertProps = Helper.convertParams(props);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getDetail(id: number): Promise<TemplateModel> {
    let item = new TemplateModel(TemplateModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + id);
      if (response.hasOwnProperty("data")) {
        item = new TemplateModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add(props: TemplateJsonAddEdit): Promise<TemplateModel> {
    let item = new TemplateModel(TemplateModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new TemplateModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: TemplateJsonAddEdit): Promise<TemplateModel> {
    let item = new TemplateModel(TemplateModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TemplateModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(SERVICE_URL + "/delete/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default TemplateApi;
