import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dropdown, MenuProps, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import useStateFilter from "hooks/useStateFilter";
import useIdFetcher from "hooks/useIdFetcher";
import Role from "common/constants/Role";

import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableDelete from "components/table/TableDelete";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";

import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////
//Phần thay thế
import { LeaveJson, FilterLeave } from "common/types/Leave";
import { IdFetcherResult } from "common/types/IdFetcher";

import LeaveModel from "common/models/LeaveModel";
import LeaveCollection from "common/collections/LeaveCollection";
import LeaveApproveRepository from "common/repositories/LeaveApproveRepository";

import LeaveApprovalListHeader from "./LeaveApprovalListHeader";
import LeaveApprovalListFilter from "./LeaveApprovalListFilter";
import LeaveRepository from "common/repositories/LeaveRepository";
import { MoreOutlined } from "@ant-design/icons";
import TableInfoRow from "components/table/TableInfoRow";
import TableActionItem from "components/table/TableActionItem";
import TextDateTime from "components/TextDateTime";
import PageDataPagination from "components/page/PageDataPagination";
import { TableColumnsType } from "common/types/Table";
import useDatabaseTable from "hooks/useDatabaseTable";
import { EmployeeJson } from "common/types/Employee";

const LeaveApprovalList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { getListIdFetcher } = useIdFetcher();
	const [employeeResult] = useDatabaseTable<EmployeeJson>("employee");

	////////////////////////
	// modal editing
	const [addVisible, setAddVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);

	////////////////////////
	// default filter
	const defaultFilters = useMemo(() => {
		return LeaveRepository.getDefaultFilters();
	}, []);

	///////////////////////
	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<LeaveModel[]>([]);
	const [filters, setFilters] = useStateFilter(defaultFilters);

	const [employees, setEmployees] = useState<IdFetcherResult[]>([]);
	const [departments, setDepartments] = useState<IdFetcherResult[]>([]);

	useFilterLocation(defaultFilters, filters);

	function renderInfoEmployee(id: number, key: keyof EmployeeJson) {
		const currentUser = employeeResult.find((item) => item.id === id);
		if (!currentUser) {
			return <>Trống</>;
		}
		return <>{currentUser[key]}</>;
	}
	//Table columns
	const columns: TableColumnsType<LeaveModel> = [
		{
			title: t("common:columns.id"),
			key: "id",
			width: 100,
			toggletype: "ignore",
			render: (id) => <Tag>DNP{id}</Tag>,
		},
		{
			title: t("common:columns.internal_id"),
			key: "internal_id",
			width: 120,
			render: (_, record) => (
				// <IdFetcherRender
				//   value={record.employee_id}
				//   mapping={employees}
				//   key_value="internal_id"
				// />
				<>{renderInfoEmployee(record.employee_id, "internal_id")}</>
			),
		},
		{
			title: t("common:columns.full_name"),
			key: "employee_id",
			render: (employee_id: number, record) => (
				// <IdFetcherRender
				//   value={record.employee_id}
				//   mapping={employees}
				//   key_value="text"
				// />
				<>{renderInfoEmployee(record.employee_id, "full_name")}</>
			),
		},
		{
			title: t("common:columns.department"),
			key: "department_id",
			render: (_, record) => (
				<IdFetcherRender
					value={record.department_id}
					mapping={departments}
					key_value="text"
				/>
			),
		},
		{
			title: t("common:columns.job_title"),
			key: "job_title",
			toggletype: "extend",
			width: 100,
			render: (_, record) => (
				<>{renderInfoEmployee(record.employee_id, "job_title")}</>
			),
		},

		{
			title: t("leaveapprove:columns.day_off"),
			key: "dayoff",
			align: "left",
			width: 120,
			sorter: (a: any, b: any) => a.leave_day - b.leave_day,
			render: (dayoff) => <p>{dayoff}</p>,
		},
		{
			title: t("common:columns.date_created"),
			key: "date_created",
			toggletype: "enable",
			render: (_: any, record) => {
				return (
					<Tag>
						<TextDateTime
							format="HH:mm - DD/MM/YYYY"
							ts={record.date_created}
						/>
					</Tag>
				);
			},
		},
		{
			title: t("common:columns.from"),
			key: "leave_start",
			render: (_: any, record) => {
				return (
					<Tag>
						<TextDateTime format="HH:mm - DD/MM/YYYY" ts={record.date_from} />
					</Tag>
				);
			},
		},

		{
			title: t("common:columns.to"),
			key: "leave_end",
			render: (_: any, record) => {
				return (
					<Tag>
						{" "}
						<TextDateTime format="HH:mm - DD/MM/YYYY" ts={record.date_to} />
					</Tag>
				);
			},
		},

		{
			title: t("common:columns.status"),
			key: "status",
			fixed: "right",

			render: (status) => {
				let statusInfo = LeaveModel.getStatus(status);
				let color =
					status === 1
						? "#4EBA00"
						: status === 0
						? "#D81A61"
						: status === 3
						? "#FFCD00"
						: "#FF7805";
				let backgroundColor =
					status === 1
						? "#53B40D26"
						: status === 0
						? "#D81A6126"
						: status === 3
						? "#FFCD0026"
						: "#FF780526";
				let borderColor =
					status === 1
						? "#53B40D80"
						: status === 0
						? "#D81A6180"
						: status === 3
						? "#FFCD0080"
						: "#FF780580";
				return (
					<Tag
						key={status}
						style={{
							backgroundColor: backgroundColor,
							color: color,
							border: `1px solid ${borderColor}`,
							fontWeight: "bold",
						}}>
						{statusInfo?.label}
						{/* {status_tag} */}
					</Tag>
				);
			},
		},

		{
			title: t(""),
			key: "actions",
			toggletype: "trigger",
			width: 120,
			align: "right",
			fixed: "right",
			render: (_: any, record: LeaveJson) => {
				return (
					<RoleCheck roles={[]} hideOnFail>
						<TableEdit
							label={t("leaveapprove:approval.edit")}
							link={`/leaveapprove/approval_edit/id/${record.id}`}
						/>
						<TableInfo record={record} />
					</RoleCheck>
				);
			},
		},
	];

	///////////////////////
	//function
	const fetchData = useCallback(async (): Promise<LeaveCollection> => {
		const response = await new LeaveApproveRepository().getItemsApproval({
			...filters,
		});
		setTotal(response.total);
		setDataSource(response.items);

		const departmentIds = response.items.map((i) => i.department_id || 0);

		getListDepartment(departmentIds);
		return response;
	}, [filters]);

	//get list employee from list id
	// const getListEmployee = useCallback(async (id_list: number[]) => {
	//   const response = await getListIdFetcher({
	//     object_type: "employee",
	//     ids: id_list,
	//   });

	//   if (!response.hasError()) {
	//     const result = response.items.filter(
	//       (i) => i.object_type === "employee"
	//     )[0].result!;
	//     if (response.items[0].result.length > 0) {
	//       const departmentIds = response.items[0].result.map(
	//         (i) => i.department_id || 0
	//       );
	//       getListDepartment(departmentIds);
	//     }
	//     setEmployees(result);
	//   }
	// }, []);

	//get list deapartment from list id
	const getListDepartment = useCallback(async (id_list: number[]) => {
		const response = await getListIdFetcher({
			object_type: "department",
			ids: id_list,
		});

		if (!response.hasError()) {
			const result = response.items.filter(
				(i) => i.object_type === "department"
			)[0].result!;
			setDepartments(result);
		}
	}, []);

	const onEdit = (id: number) => {
		setEditingId(id);
		setAddVisible(true);
	};
	return (
		<div>
			<LeaveApprovalListHeader />
			<LeaveApprovalListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			<PageDataTable<FilterLeave, LeaveModel, LeaveCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "leaveapproval",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</div>
	);
};

export default LeaveApprovalList;
