import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const ProductInventoryCheckingUserFormHeader = ({
	isEditing,
	id,
}: {
	isEditing: boolean;
	id: number;
}) => {
	const { t } = useTranslation();

	const nav: Nav = [
		{
			text: t("productinventorychecking:heading_nav"),
			link: "/productinventorycheckinguser",
		},
	];

	return (
		<PageHeader
			nav={nav}
			heading={
				t(
					isEditing
						? "productinventorychecking:heading_edit"
						: "productinventorychecking:heading_add"
				) + (id > 0 ? " #" + id : "")
			}
			siteMenuSelectedKey="/productinventorycheckinguser"
			siteMenuHidden></PageHeader>
	);
};

export default ProductInventoryCheckingUserFormHeader;
