import { IconMoodSad, IconMoodSmile } from "@tabler/icons-react";
import CashflowReceipt from "common/constants/CashflowReceipt";
import Role from "common/constants/Role";
import RoleCheck from "components/RoleCheck";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useTranslation } from "react-i18next";

const CashflowReceiptListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("cashflowreceipt:heading_list")}
      siteMenuSelectedKey="/cashflowreceipt"
    >
      <RoleCheck roles={[Role.CASHFLOW_ADD]}>
        <PageHeaderButton
          type="primary"
          link={
            "/cashflowreceipt/add/direction/" + CashflowReceipt.DIRECTION_INCOME
          }
          className="mr-2"
          icon={<IconMoodSmile size={18} className="mr-0.5 -mt-0.5" />}
        >
          {t("cashflowreceipt:add_button_income")}
        </PageHeaderButton>

        <PageHeaderButton
          type="primary"
          link={
            "/cashflowreceipt/add/direction/" +
            CashflowReceipt.DIRECTION_EXPENSE
          }
          icon={<IconMoodSad size={18} className="mr-0.5 -mt-0.5" />}
        >
          {t("cashflowreceipt:add_button_expense")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default CashflowReceiptListHeader;
