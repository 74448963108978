import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";

import Helper from "common/utils/helper";
import {
  FilterNotification,
  NotificationCollectionJson,
  NotificationJsonAddEdit,
} from "common/types/Notification";
import { GetListProps } from "common/types/Filter";
import NotificationModel from "common/models/NotificationModel";
import NotificationCollection from "common/collections/NotificationCollection";

const SERVICE_URL = "/publications";

class NotificationApi extends BaseApi {
  //
  async getList(
    props: GetListProps<FilterNotification>
  ): Promise<NotificationCollection> {
    let collection = new NotificationCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<NotificationCollection>(
        SERVICE_URL,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getDetail(id: number): Promise<NotificationModel> {
    let item = new NotificationModel(NotificationModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new NotificationModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add(props: NotificationJsonAddEdit): Promise<NotificationModel> {
    let item = new NotificationModel(NotificationModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new NotificationModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: NotificationJsonAddEdit): Promise<NotificationModel> {
    let item = new NotificationModel(NotificationModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new NotificationModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editPin(props: NotificationJsonAddEdit): Promise<string[]> {
    let resultErrors = [];
    let pinValue;
    if (props.pin === 0) {
      pinValue = 1;
    } else {
      pinValue = 0;
    }

    try {
      //For create item
      let postData = {
        pin: pinValue,
      };
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/updatepin/" + props.id,
        postData
      );
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
  //
  async editStatus(props: NotificationJsonAddEdit): Promise<string[]> {
    let resultErrors = [];

    let statusValue;
    if (props.status === 1) {
      statusValue = 3;
    } else {
      statusValue = 1;
    }

    try {
      //For create item
      let postData = {
        status: statusValue,
      };
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/updatestatus/" + props.id,
        postData
      );
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default NotificationApi;
