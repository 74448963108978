import CmsPostApi from 'common/api/CmsPostApi';
import CmsPostCollection from 'common/collections/CmsPostCollection';
import CmsPostModel from 'common/models/CmsPostModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type { CmsPostJsonAddEdit, FilterCmsPost } from "common/types/CmsPost";

class CmsPostRepository extends BaseRepository<CmsPostApi> {
  _api: CmsPostApi | null;

  constructor() {
    super();
    this._api = new CmsPostApi(true);
  }

  static getDefaultFilters(): FilterCmsPost {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterCmsPost>) {
    return this._api ? await this._api.getList(props) : new CmsPostCollection();
  }

  async getItem(id: number): Promise<CmsPostModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new CmsPostModel(CmsPostModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: CmsPostJsonAddEdit): Promise<CmsPostModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new CmsPostModel(CmsPostModel.getDefaultData());
    }
  }
}

export default CmsPostRepository;
