import { BrandCollectionJson, BrandJson } from "common/types/Brand";
import BaseCollection from "./BaseCollection";
import BrandModel from "common/models/BrandModel";

class BrandCollection extends BaseCollection<
  BrandJson,
  BrandModel,
  BrandCollectionJson
> {
  itemsFromJson(jsonItems: BrandJson[]): BrandModel[] {
    return jsonItems.map((item) => new BrandModel(item));
  }
}
export default BrandCollection;
