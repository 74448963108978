const WorkChat = {
  STATUS_UNACTIVATED: 0,
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,
  STATUS_DELETED: 5,

  TYPE_CHANNEL: 1,
  TYPE_DIRECT_MESSAGE: 3,
  TYPE_DISCUSSION: 5,
  TYPE_THREAD: 7,

  MODE_PUBLIC: 1,
  MODE_PRIVATE: 3,

  READ_ENABLE: 1,
  READ_DISABLE: 0,
};

export default WorkChat;
