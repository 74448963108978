import EcomLog from 'common/constants/EcomLog';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { EcomLogJson } from "common/types/EcomLog";

class EcomLogModel extends BaseModel implements BaseModelJson<EcomLogJson> {
  creator_id: number;
  company_id: number;
  ecom_platform_id: number;
  id: number;
  type: number;
  topic: string;
  identifier: string;
  data_content_type: string;
  data: string;
  trigger_action: string;
  trigger_action_output: string;
  trigger_retry_count: number;
  status: number;
  date_created: number;
  date_modified: number;
  date_triggered: number;

  constructor(json: EcomLogJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.ecom_platform_id = json.ecom_platform_id || 0;
    this.id = json.id || 0;
    this.type = json.type || 0;
    this.topic = json.topic || "";
    this.identifier = json.identifier || "";
    this.data_content_type = json.data_content_type || "";
    this.data = json.data || "";
    this.trigger_action = json.trigger_action || "";
    this.trigger_action_output = json.trigger_action_output || "";
    this.trigger_retry_count = json.trigger_retry_count || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_triggered = json.date_triggered || 0;
  }

  static getDefaultData(): EcomLogJson {
    return {
      creator_id: 0,
      company_id: 0,
      ecom_platform_id: 0,
      id: 0,
      type: 0,
      topic: "",
      identifier: "",
      data_content_type: "",
      data: "",
      trigger_action: "",
      trigger_action_output: "",
      trigger_retry_count: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
      date_triggered: 0,
    };
  }

  toJson(): EcomLogJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      ecom_platform_id: this.ecom_platform_id,
      id: this.id,
      type: this.type,
      topic: this.topic,
      identifier: this.identifier,
      data_content_type: this.data_content_type,
      data: this.data,
      trigger_action: this.trigger_action,
      trigger_action_output: this.trigger_action_output,
      trigger_retry_count: this.trigger_retry_count,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_triggered: this.date_triggered,
    };
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: EcomLog.TYPE_WEBHOOK,
        label: i18n.t("ecomlog:TYPE_WEBHOOK"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: EcomLog.STATUS_DUPLICATE,
        label: i18n.t("ecomlog:STATUS_DUPLICATE"),
        color: "green",
      },
      {
        value: EcomLog.STATUS_IGNORE,
        label: i18n.t("ecomlog:STATUS_IGNORE"),
        color: "red",
      },
      {
        value: EcomLog.STATUS_QUEUEING,
        label: i18n.t("ecomlog:STATUS_QUEUEING"),
        color: "yellow",
      },
      {
        value: EcomLog.STATUS_PROCESSING,
        label: i18n.t("ecomlog:STATUS_PROCESSING"),
        color: "yellow",
      },
      {
        value: EcomLog.STATUS_SUCCESS,
        label: i18n.t("ecomlog:STATUS_SUCCESS"),
        color: "green",
      },
      {
        value: EcomLog.STATUS_ERROR,
        label: i18n.t("ecomlog:STATUS_ERROR"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default EcomLogModel;
