import { Popconfirm } from "antd";
import { PosCartOrderDetail } from "common/types/PosCart";

import { IconX } from "@tabler/icons-react";

const PosCartItemDelete = ({
  item,
  onDelete,
}: {
  item: PosCartOrderDetail;
  onDelete: () => void;
}) => {
  return (
    <Popconfirm
      title="Bạn muốn xóa sản phẩm này khỏi giỏ hàng?"
      placement="bottomRight"
      onConfirm={onDelete}
      okText="Có"
      cancelText="Không"
    >
      <span className="p-2 cursor-pointer text-blue-900 hover:text-red-600">
        <IconX size={16} />
      </span>
    </Popconfirm>
  );
};

export default PosCartItemDelete;
