import { Button, Spin, Tooltip } from "antd";
import { SelectValue } from "antd/es/select";
import DataGridWorkspaceModel from "common/models/DataGridWorkspaceModel";
import DataGridWorkspaceRepository from "common/repositories/DataGridWorkspaceRepository";
import ErrorAlert from "components/ErrorAlert";
import FormSelect from "components/form/FormSelect";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDataGridPage from "zustands/useDataGridPage";

import {
  IconDatabase,
  IconDatabaseOff,
  IconPencil,
  IconPlus,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react";

import DataGridTableListNavigation from "./table/DataGridTableListNavigation";
import DataGridWorkspaceFormModal from "./workspace/DataGridWorkspaceFormModal";

const DataGridMainNavigation = ({
  source,
}: {
  source: "submenu" | "tooltip";
}) => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  // Workspace loading & active
  const [activeWorkspaceId, setActiveWorkspaceId] = useDataGridPage((state) => [
    state.activeWorkspaceId,
    state.setActiveWorkspaceId,
  ]);
  const [workspaces, setWorkspaces] = useDataGridPage((state) => [
    state.workspaces,
    state.setWorkspaces,
  ]);

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  //////////////////////////////////////////
  // Workspace add & editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  const fetchWorkspace = useCallback(async () => {
    setErrors([]);
    const collection = await new DataGridWorkspaceRepository().getItems({
      filters: DataGridWorkspaceRepository.getDefaultFilters(),
    });

    setLoading(false);
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setWorkspaces(collection.items.map((item) => item.toJson()));

      //active first workspace for working
      if (collection.items.length > 0 && activeWorkspaceId === 0) {
        setActiveWorkspaceId(collection.items[0].id);
      }
    }
  }, [setActiveWorkspaceId, activeWorkspaceId, setWorkspaces]);

  const onSaveSuccess = (item: DataGridWorkspaceModel) => {
    setLoading(true);
    setActiveWorkspaceId(item.id);
  };

  const onDeleteWorkspaceCallback = useCallback(
    (deletedId: number) => {
      const newWorkspaces = workspaces.filter((i) => i.id !== deletedId);
      if (newWorkspaces.length > 0) {
        setActiveWorkspaceId(newWorkspaces[0].id);
      }
      setWorkspaces(newWorkspaces);
    },
    [workspaces, setActiveWorkspaceId, setWorkspaces]
  );

  const onChangeActiveWorkspace = useCallback(
    (value: SelectValue) => {
      if (typeof value === "number") {
        //detect select "Create new workspace"
        if (value === -1) {
          setAddVisible(true);
        } else {
          const foundItem = workspaces.find((i) => i.id === value);
          if (typeof foundItem !== "undefined") {
            setActiveWorkspaceId(value);
          } else {
            alert(
              "Error while finding activeWorkspace from dataSource (See console log for more details)"
            );
            console.log(
              "Error while finding activeWorkspace from dataSource.",
              workspaces,
              value
            );
          }
        }
      } else {
        console.log("not type number", value);
      }
    },
    [workspaces, setActiveWorkspaceId]
  );

  useEffect(() => {
    if (loading) {
      fetchWorkspace();
    }
  }, [fetchWorkspace, loading]);

  return (
    <>
      {loading ? (
        <div className="p-8 text-center spin-darkmode">
          <Spin />
        </div>
      ) : (
        <>
          {errors.length > 0 ? (
            <div className="text-center">
              <ErrorAlert
                items={errors}
                translate_prefix="datagridworkspace:form.error"
              />
            </div>
          ) : null}

          {workspaces.length === 0 ? (
            <div className="p-8 text-center ">
              <div onClick={() => setLoading(true)}>
                <IconDatabaseOff
                  className="text-gray-50"
                  size={64}
                  stroke={1}
                />
              </div>
              <div className="my-4 text-gray-100">
                {t("datagridworkspace:empty")}
              </div>
              <div className="mt-2">
                <Button
                  type="default"
                  ghost
                  icon={<IconPlus size={18} className="mr-1 -mt-0.5" />}
                  onClick={() => setAddVisible(true)}
                >
                  {t("datagridworkspace:create_button")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="px-4 py-2">
              <div className="mb-1 text-xs text-gray-400 uppercase">
                <small>{t("datagridworkspace:active_label")}</small>
                <Tooltip title={t("datagridworkspace:refresh_title")}>
                  <span
                    className="float-right cursor-pointer"
                    onClick={() => setLoading(true)}
                  >
                    {loading ? (
                      <Spin size="small" />
                    ) : (
                      <IconRefresh size={14} className="text-gray-400" />
                    )}
                  </span>
                </Tooltip>
              </div>
              <FormSelect
                className="mb-2"
                popupMatchSelectWidth={false}
                suffixIcon={
                  <IconDatabase size={18} className="text-gray-400" />
                }
                onChange={onChangeActiveWorkspace}
                value={activeWorkspaceId || undefined}
                options={[
                  ...workspaces.map((item) => ({
                    value: item.id,
                    label: item.name,
                  })),
                  {
                    value: -1,
                    label: "[+] " + t("datagridworkspace:create_button") + "..",
                  },
                ]}
              />

              {activeWorkspaceId > 0 ? (
                <div>
                  <div className="mb-20">
                    <DataGridTableListNavigation
                      key={activeWorkspaceId}
                      workspaceId={activeWorkspaceId}
                    />
                  </div>
                  <div className="py-2 bg-gray-600" style={{ width: 240 }}>
                    <div className="flex justify-between py-2 mx-4 text-gray-500">
                      <TableEdit
                        link="#"
                        onClick={() => {
                          setAddVisible(true);
                          setEditingId(activeWorkspaceId);
                        }}
                        icon={<IconPencil size={12} className="-mt-0.5 mr-1" />}
                        label={t("datagridworkspace:edit")}
                        className="text-gray-500"
                      ></TableEdit>

                      <TableDelete
                        error_translate_prefix="datagridworkspace:form.error"
                        onDeleteCallback={onDeleteWorkspaceCallback}
                        repository={new DataGridWorkspaceRepository()}
                        id={activeWorkspaceId}
                        title={t("datagridworkspace:delete_confirm")}
                        label={t("datagridworkspace:delete")}
                        icon={<IconTrash size={12} className="-mt-0.5 mr-1" />}
                        className="text-gray-500"
                        buttonType="link"
                      ></TableDelete>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </>
      )}

      <DataGridWorkspaceFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default DataGridMainNavigation;
