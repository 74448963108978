import { Col, Form, Row, Select, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosClient from "common/api/AxiosClient";
import {
	LocationPickerProps,
	LocationPickerValue,
} from "common/interfaces/LocationPicker";

export interface Option {
	label: string;
	value: string | number;
}

export type LocationJson = {
	location_id: number;
	parent_id: number;
	name: string;
	code: string;
	address: string;
	primary: number;
	type: number;
	deprecated: number;
	is_deleted: number;
};

//api
const getLocationList = async ({
	type,
	parent_id,
	code,
}: {
	type?: number;
	parent_id?: number;
	code?: string;
}) => {
	try {
		let queryData = {
			type: type,
			parent_id: parent_id ? parent_id : null,
			code: code ? code : null,
		};

		const { data } = await AxiosClient().get<any>(`/locations/public/type`, {
			params: queryData,
		});
		return data?.items as LocationJson[];
	} catch (error) {
		notification.error({
			message: "Có lỗi xảy ra vui lòng thử lại",
		});
		return [];
	}
};

const LocationPicker: React.FC<LocationPickerProps> = ({
	value,
	onChange,
	disabled,
	span,
	noStyle,
	className,
	required,
	rules,
}: LocationPickerProps) => {
	const { t } = useTranslation();
	const [provinces, setProvinces] = useState<LocationJson[]>([]);
	const [districts, setDistricts] = useState<LocationJson[]>([]);
	const [streets, setStreets] = useState<LocationJson[]>([]);

	const [countryId, setCountryId] = useState<number>(0);

	const [selectedProvince, setSelectedProvince] = useState<
		LocationJson["location_id"] | null
	>(value?.shipping_province || null);
	const [selectedDistrict, setSelectedDistrict] = useState<
		LocationJson["location_id"] | null
	>(value?.shipping_district || null);
	const [selectedWard, setSelectedWard] = useState<
		LocationJson["location_id"] | null
	>(value?.shipping_ward || null);

	const [loadingProvinces, setLoadingProvinces] = useState(false);
	const [loadingDistricts, setLoadingDistricts] = useState(false);
	const [loadingStreets, setLoadingStreets] = useState(false);

	const triggerChange = (changedValue: LocationPickerValue) => {
		const valueNew = { ...value, ...changedValue };
		onChange?.(valueNew);
		setSelectedProvince(valueNew?.shipping_province || null);
		setSelectedDistrict(valueNew?.shipping_district || null);
		setSelectedWard(valueNew?.shipping_ward || null);
	};

	function onRegionIdChange(newId: number) {
		setSelectedProvince(newId);
		triggerChange({
			shipping_province: newId,
			shipping_district: null,
			shipping_ward: null,
		});
	}

	const onSubRegionIdChange = (newId: number) => {
		setSelectedDistrict(newId);
		triggerChange({ shipping_district: newId, shipping_ward: 0 });
	};

	const onSubSubRegionIdChange = (newId: number) => {
		setSelectedWard(newId);
		triggerChange({ shipping_ward: newId });
	};

	const fetchData = useCallback(async () => {
		try {
			let collection = await getLocationList({
				parent_id: 0,
				code: "VN",
			});
			setCountryId(collection?.[0]?.location_id);
		} catch (error) {}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		const fetchProvinces = async () => {
			if (countryId) {
				setLoadingProvinces(true);
				const provincesData = await getLocationList({
					type: 1,
					parent_id: countryId,
				});
				setProvinces(provincesData);
				setLoadingProvinces(false);
			}
		};

		fetchProvinces();
	}, [countryId]);

	useEffect(() => {
		if (selectedProvince) {
			const fetchDistricts = async () => {
				setLoadingDistricts(true);
				const districtsData = await getLocationList({
					type: 10,
					parent_id: selectedProvince,
				});
				setDistricts(districtsData);
				setLoadingDistricts(false);
			};

			fetchDistricts();
		}
	}, [selectedProvince]);

	useEffect(() => {
		if (selectedDistrict) {
			const fetchStreets = async () => {
				setLoadingStreets(true);
				const streetsData = await getLocationList({
					type: 110,
					parent_id: selectedDistrict,
				});
				setStreets(streetsData);
				setLoadingStreets(false);
			};

			fetchStreets();
		}
	}, [selectedDistrict]);

	return (
		<Row gutter={16}>
			<Col md={span || 8} xs={24}>
				<Form.Item
					label={t("common:region")}
					noStyle={noStyle}
					className={className}
					required={required}>
					<Select
						loading={loadingProvinces}
						value={selectedProvince}
						allowClear
						disabled={disabled}
						onChange={onRegionIdChange}
						placeholder={t("common:region_placeholder")}
						style={{ width: "100%" }}
						showSearch
						options={provinces.map((i) => ({
							value: i.location_id,
							label: i.name,
						}))}
						optionFilterProp="label"
						popupMatchSelectWidth={false}
						filterOption={(input: any, option: any) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.label
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.replace(/[đĐ]/g, "d")
								.toLowerCase()
								.indexOf(
									input
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.replace(/[đĐ]/g, "d")
										.toLowerCase()
								) >= 0
						}
					/>
				</Form.Item>
			</Col>
			{typeof selectedProvince !== "undefined" &&
				selectedProvince !== null &&
				selectedProvince > 0 && (
					<>
						<Col md={span || 8} xs={24}>
							<Form.Item
								label={t("common:sub_region")}
								noStyle={noStyle}
								className={className}
								required={required}>
								<Select
									loading={loadingDistricts}
									value={selectedDistrict}
									allowClear
									disabled={disabled}
									onChange={onSubRegionIdChange}
									placeholder={t("common:sub_region_placeholder")}
									style={{ width: "100%" }}
									popupMatchSelectWidth={false}
									showSearch
									options={districts.map((i) => ({
										value: i.location_id,
										label: i.name,
									}))}
									optionFilterProp="label"
									filterOption={(input: any, option: any) =>
										option.label.toLowerCase().indexOf(input.toLowerCase()) >=
											0 ||
										option.label
											.normalize("NFD")
											.replace(/[\u0300-\u036f]/g, "")
											.replace(/[đĐ]/g, "d")
											.toLowerCase()
											.indexOf(
												input
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.replace(/[đĐ]/g, "d")
													.toLowerCase()
											) >= 0
									}
								/>
							</Form.Item>
						</Col>
						{typeof selectedDistrict !== "undefined" &&
							selectedDistrict !== null &&
							selectedDistrict > 0 && (
								<Col md={span || 8} xs={24}>
									<Form.Item
										label={t("common:sub_sub_region")}
										noStyle={noStyle}
										className={className}
										required={required}>
										<Select
											loading={loadingStreets}
											value={selectedWard}
											allowClear
											disabled={disabled}
											onChange={onSubSubRegionIdChange}
											placeholder={t("common:sub_sub_region_placeholder")}
											style={{ width: "100%" }}
											showSearch
											popupMatchSelectWidth={false}
											options={streets.map((i) => ({
												value: i.location_id,
												label: i.name,
											}))}
											optionFilterProp="label"
											filterOption={(input: any, option: any) =>
												option.label
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0 ||
												option.label
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.replace(/[đĐ]/g, "d")
													.toLowerCase()
													.indexOf(
														input
															.normalize("NFD")
															.replace(/[\u0300-\u036f]/g, "")
															.replace(/[đĐ]/g, "d")
															.toLowerCase()
													) >= 0
											}
										/>
									</Form.Item>
								</Col>
							)}
					</>
				)}
		</Row>
	);
};

export default LocationPicker;
