import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import SupplierModel from "common/models/SupplierModel";
import { PurchaseOrderJson } from "common/types/PurchaseOrder";
import { SupplierJson } from "common/types/Supplier";
import create, { StateCreator } from "zustand";

type EditingPurchaseOrderStore = {
  supplier: SupplierJson;
  purchaseOrder: PurchaseOrderJson;
  setPurchaseOrder: (v: PurchaseOrderJson, s: SupplierJson) => void;
};

//init store data
let store: StateCreator<EditingPurchaseOrderStore> = (set) => ({
  supplier: SupplierModel.getDefaultData(),
  purchaseOrder: PurchaseOrderModel.getDefaultData(),
  setPurchaseOrder: (purchaseOrder, supplier) =>
    set((state) => ({ ...state, purchaseOrder, supplier })),
});

//create store
const useEditingPurchaseOrderStore = create(store);

export default useEditingPurchaseOrderStore;
