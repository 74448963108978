import ColorHash from "color-hash";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Avatar,
	Card,
	Col,
	Pagination,
	Row,
	Skeleton,
	Tooltip,
	Typography,
} from "antd";

import FileModel from "common/models/FileModel";
import PhoneBookModel from "common/models/PhoneBookModel";
import PhoneBookCollection from "common/collections/PhoneBookCollection";
import PhoneBookRepository from "common/repositories/PhoneBookRepository";

import PhoneBookModal from "../form/PhoneBookModal";
import PhoneBookListFilter from "./PhoneBookListFilter";
import PhoneBookListHeader from "./PhoneBookListHeader";
import PageDataPagination from "components/page/PageDataPagination";
import Helper from "common/utils/helper";
import PhoneBookCheckin from "../components/PhoneBookCheckin";

const PhoneBookList = () => {
	// default filter
	const defaultFilters = useMemo(() => {
		return PhoneBookRepository.getDefaultFilter();
	}, []);

	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<PhoneBookModel[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [filters, setFilters] = useStateFilter(defaultFilters);

	//set modal id
	const [modal, setModal] = useState(false);
	const [modalId, setModalId] = useState<number>(0);
	useFilterLocation(defaultFilters, filters);

	//table columns

	//functions
	//fetch data
	const fetchData = useCallback(async (): Promise<PhoneBookCollection> => {
		setLoading(true);
		const response = await new PhoneBookRepository().getItems({
			filters: filters,
		});
		setTotal(response.total);
		setDataSource(response.items);
		setLoading(false);

		return response;
	}, [filters]);

	//set modal id
	const handleClickCard = (id: number) => {
		setModalId(id);
		setModal(true);
	};

	useEffect(() => {
		fetchData();
	}, [filters]);

	return (
		<div>
			<PhoneBookListHeader />
			<PhoneBookListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>
			{loading ? (
				<Skeleton />
			) : (
				<div className="mt-6 mx-3 h-full block mb-2">
					<div
						id="contact_book"
						className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
						{dataSource.length > 0 &&
							dataSource.map((item, index) => {
								let isOnline =
									Helper.checkTimestampInDay(item.last_checkin) ||
									Helper.checkTimestampInDay(item.last_checkout);
								return (
									<Card
										key={index}
										bodyStyle={{
											overflow: "hidden",
											padding: 0,
											width: "100%",
										}}
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
											handleClickCard(item.id);
											setModal(true);
										}}
										hoverable
										className="min-h-[281px] w-full  h-full flex flex-col items-center p-4 gap-4 rounded-lg"
										cover={
											<div className="relative">
												<div className="flex justify-center items-center">
													{item.avatar.id > 0 ? (
														<Avatar
															shape="circle"
															size={80}
															icon={
																<img
																	src={FileModel.getThumbnailFromUrl(
																		item.avatar.file_path
																	)}
																	alt=""
																	className="object-contain"
																/>
															}
														/>
													) : (
														<Avatar
															shape="circle"
															size={80}
															style={{
																backgroundColor: new ColorHash().hex(
																	item.full_name
																),
															}}>
															{item.full_name
																.trim()
																.split(" ")
																.slice(-1)
																.toString()
																.charAt(0)}
														</Avatar>
													)}
												</div>
												<span
													className={`absolute border-[2px] border-white w-[14px] h-[14px] ${
														isOnline ? "bg-green-400" : "bg-gray-400"
													} rounded-full ml-16 -mt-5 z-[99]`}></span>
											</div>
										}>
										<div className="flex flex-col items-center">
											<Tooltip title={item.full_name}>
												<Typography.Text
													className=" max-w-[200px] truncate"
													strong>
													{item.full_name}
												</Typography.Text>
											</Tooltip>
											<Typography.Text className="line-clamp-1" strong>
												{item.internal_id}
											</Typography.Text>
											<p className="line-clamp-1 capitalize mb-0">
												{item.job_title}
											</p>
											<p className="line-clamp-1 capitalize  mb-2">
												{item.department_id}
											</p>
											{/* <p className="line-clamp-1">{item.work_email}</p> */}
											<PhoneBookCheckin
												item={{
													last_checkin: item.last_checkin,
													last_checkout: item.last_checkout,
													last_login: item.last_login,
												}}
												titleCheckin=""
												titleCheckout=""></PhoneBookCheckin>

											{/* <p>
                            <a
                              href={`https://zalo.me/${item.work_phone}`}
                              target="_blank"
                              rel="noreferrer"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <span className="text-blue-400">Zalo</span>
                            </a>
                            <span> | </span>
                            <a
                              href={`https://t.me/+84${item.work_phone.slice(1)}`}
                              target="_blank"
                              rel="noreferrer"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <span className="text-blue-400">Telegram</span>
                            </a>
                          </p> */}
										</div>
									</Card>
								);
							})}
					</div>
				</div>
			)}
			{/* <Pagination
        showSizeChanger
        className="text-center my-10"
        current={+filters.page}
        pageSize={+filters.limit}
        total={total}
        hideOnSinglePage={false}
        showQuickJumper={false}
        onChange={(newPage, pageSize) =>
          setFilters({ ...filters, page: newPage, limit: pageSize })
        }
        pageSizeOptions={["12", "24", "48"]}
      /> */}
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>

			<PhoneBookModal
				id={modalId}
				visible={modal}
				setVisible={(isVisible) => {
					setModal(isVisible);
				}}
			/>
		</div>
	);
};

export default PhoneBookList;
