import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DmsBannerFormEditorHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:group_delivery"),
      link: "",
    },
    {
      text: t("sitemenu:delivery_portal"),
      link: "/dmsbanner",
    },
    {
      text: t("sitemenu:delivery_portal_banner"),
      link: "/dmsbanner",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t(
        isEditing ? "dmsbanner:heading_edit" : "dmsbanner:heading_add"
      )}
    ></PageHeader>
  );
};

export default DmsBannerFormEditorHeader;
