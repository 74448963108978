import { ColorPicker, ConfigProvider } from "antd";
import { Color } from "antd/es/color-picker";
import { ColorFormat, TriggerType } from "antd/es/color-picker/interface";
import React from "react";

const CustomColorPicker = ({
  value,
  onChange,
  onFormatChange,
  format,
  allowClear,
  disabled,
  trigger
}: {
  value?: string | Color | undefined;
  onChange?: ((value: Color, hex: string) => void) | undefined;
  onFormatChange?: ((format: ColorFormat) => void) | undefined;
  format?: "hex" | "rgb" | "hsb" | undefined;
  allowClear?: boolean | undefined;
  disabled?: boolean | undefined;
  trigger?: TriggerType | undefined;
}) => {
  return (
    <ConfigProvider>
      <ColorPicker
        value={value}
        onChange={onChange}
        onFormatChange={onFormatChange}
        format={format}
        allowClear={allowClear}
        disabled={disabled}
        trigger={trigger || "hover"}
      />
    </ConfigProvider>
  );
};

export default CustomColorPicker;
