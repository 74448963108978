import TagModel from "common/models/TagModel";
import BaseCollection from "./BaseCollection";

import { TagCollectionJson, TagJson } from "common/types/Tag";

class TagCollection extends BaseCollection<
  TagJson,
  TagModel,
  TagCollectionJson
> {
  itemsFromJson(jsonItems: TagJson[]): TagModel[] {
    return jsonItems.map((item) => new TagModel(item));
  }
}
export default TagCollection;
