import ReportDeliveryCollection from "common/collections/ReportDeliveryCollection";
import { GetListProps } from "common/types/Filter";
import { FilterReportDelivery } from "common/types/ReportDelivery";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/deliveries";

class ReportDeliveryApi extends BaseApi {
  async getList(
    props: GetListProps<FilterReportDelivery>
  ): Promise<ReportDeliveryCollection> {
    let collection = new ReportDeliveryCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          date_created: filters.date_created > 0 ? filters.date_created : null,
          date_completed: filters.date_completed > 0 ? filters.date_completed : null,
          soandcode: filters.soandcode ? filters.soandcode : null,
          actor_id: filters.actor_id > 0 ? filters.actor_id : null,
          action_code: "ACTION_COMPLETE",
          find_internal: filters.find_internal ? filters.find_internal : null,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/report",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async exportExcel(
    props: GetListProps<FilterReportDelivery>
  ): Promise<boolean> {
    let filters = props.filters;
    let queryData = {
      params: {
        soandcode: filters.soandcode ? filters.soandcode : null,
        date_created: filters.date_created > 0 ? filters.date_created : null,
        date_completed: filters.date_completed > 0 ? filters.date_completed : null,
        find_internal: filters.find_internal ? filters.find_internal : null,
        time: Math.floor(Math.random() * 100),
      },
    };

    try {
      // const response = await AxiosClient().get<any>(
      //   SERVICE_URL + "/export",
      //   queryData
      // );

      let url = process.env.REACT_APP_BASE_URL + SERVICE_URL + "/export?";
      Object.entries(queryData.params).forEach(([key, value]) => {
        if (value !== null) {
          url += key + "=" + value + "&";
        }
      });
      let loginAccount = BaseApi.loginAccount;
      url += "__jwtAuthorization=" + loginAccount.jwt;

      window.open(url, "_blank");
    } catch (error) {
      return false;
    }
    return true;
  }

  async getSumByReport(filters: FilterReportDelivery): Promise<any> {
    let collection = {};

    try {
      // let filters = props.filters;
      let queryData = {
        params: {
          // key: filters.key,
          actor_id: filters.actor_id > 0 ? filters.actor_id : null,
          date_created: filters.date_created > 0 ? filters.date_created : null,
          date_completed: filters.date_completed > 0 ? filters.date_completed : null,
          // actor_already: 1,
          soandcode: filters.soandcode ? filters.soandcode : null,
          action_code: "ACTION_COMPLETE",
          keyword: filters.keyword ? filters.keyword : null,
          find_internal: filters.find_internal ? filters.find_internal : null,
          // day: filters.day > 0 ? filters.day : null,
          // month: filters.month > 0 ? filters.month : null,
          // year: filters.year > 0 ? filters.year : null,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/admin/sumby",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection = response.data;
        // collection.fromJson(response.data);
      }
    } catch (error) {
      // collection.withError(BaseApi.handleError(error));
      // collection.push("error_response_not_200");
    }

    return collection;
  }
}

export default ReportDeliveryApi;
