import ContractTypeApi from "common/api/ContractTypeApi";
import ContractTypeModel from "common/models/ContractTypeModel";
import {
  ContractTypeJson,
  FilterContractType,
} from "common/types/ContractType";
import BaseRepository from "./BaseRepository";
import ContractTypeCollection from "common/collections/ContractTypeCollection";
import { GetListProps } from "common/types/Filter";

class ContractTypeRepository extends BaseRepository<ContractTypeApi> {
  _api: ContractTypeApi | null;

  constructor() {
    super();
    this._api = new ContractTypeApi(true);
  }
  static getDefaultFilters(): FilterContractType {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: "",
    };
  }

  async getItems(
    props: GetListProps<FilterContractType>
  ): Promise<ContractTypeCollection> {
    return this._api
      ? await this._api.getList(props)
      : new ContractTypeCollection();
  }

  async getFullData() {
    return this._api ? await this._api.getFullData() : [];
  }

  async getItem(id: number): Promise<ContractTypeModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ContractTypeModel(ContractTypeModel.getDefaultData());
  }

  async saveRemote(data: ContractTypeJson): Promise<any> {
    if (this._api) {
      if (data.id > 0) {
        // return await this._api.edit(data);
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ContractTypeModel(ContractTypeModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }
}

export default ContractTypeRepository;
