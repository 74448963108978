import Role from "common/constants/Role";
import RoleCheck from "components/RoleCheck";
import usePosCartStore from "zustands/usePosCartStore";

import PosContainer from "./PosContainer";
import PosStorePicker from "./PosStorePicker";

const Pos = () => {
  const activeStore = usePosCartStore((state) => state.activeStore);

  return (
    <>
      <RoleCheck
        roles={[Role.ORDER_ADD]}
        hideOnFail={false}
        componentOnFail={<>NOT PERMISSION</>}
      >
        {typeof activeStore === "undefined" ? (
          <PosStorePicker />
        ) : (
          <PosContainer />
        )}
      </RoleCheck>
    </>
  );
};

export default Pos;
