import { Tag, Tooltip } from "antd";
import {
  PosCartOrderDetail,
  PosCartOutOfStock,
  PosCartPromotionProductDetail,
} from "common/types/PosCart";
import TextMoney from "components/TextMoney";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import { isUndefined } from "lodash";
import useCompanySettingStore from "zustands/useCompanySettingStore";

const PosCartItemDetail = ({
  item,
  onClickItemPrice,
  outOfStockItem,
}: {
  item: PosCartOrderDetail;
  onClickItemPrice: () => void;
  outOfStockItem?: PosCartOutOfStock;
}) => {
  const disableManualDiscount = useCompanySettingStore(
    (state) => state.getSetting("disable_manual_promotion") || 0
  );

  const appliedPromotions: PosCartPromotionProductDetail[] = item.promotions;

  return (
    <div>
      <span style={{ color: "white" }}>{item.item_name}</span>
      <div style={{ fontSize: 11 }} className="pos-cart-item-option">
        <TextProductVariantColor id={item.item_color} /> <small>|</small>&nbsp;
        <TextProductVariantSize id={item.item_size} /> <small>|</small>
        &nbsp;SKU: {item.sku}
        <div>
          <span>
            {item.manual_discount ? null : appliedPromotions.length > 0 ? (
              <Tooltip
                title={
                  <div>
                    CTKM: {appliedPromotions[0].promotion_name} (ID #
                    {appliedPromotions[0].promotion_id})
                  </div>
                }
                mouseEnterDelay={0.4}
              >
                <Tag style={{ fontSize: 11 }} color="green">
                  KM
                </Tag>
              </Tooltip>
            ) : null}
          </span>

          {item.price_before_promotion !== item.price ? (
            <span>
              <span
                style={{ color: "#888888", textDecoration: "line-through" }}
              >
                <TextMoney money={item.price_before_promotion} />
              </span>{" "}
              &nbsp;
            </span>
          ) : null}
          {disableManualDiscount !== 1 ? (
            <span className="pos-cart-item-price">
              <Tooltip
                title="Nhập khuyến mãi cho sản phẩm"
                placement="right"
                mouseEnterDelay={0.4}
                overlayStyle={{ fontSize: 11 }}
              >
                <span onClick={onClickItemPrice}>
                  <TextMoney money={item.price} />
                </span>
              </Tooltip>
            </span>
          ) : (
            <span>
              <TextMoney money={item.price} />
            </span>
          )}
        </div>
      </div>

      {outOfStockItem != null && !isUndefined(outOfStockItem) ? (
        <Tag color="red">
          {outOfStockItem.available === 0 ? (
            <span>Hết hàng</span>
          ) : (
            <span>
              Không đủ tồn kho. Hiện chỉ còn: {outOfStockItem.available}
            </span>
          )}
        </Tag>
      ) : null}
    </div>
  );
};

export default PosCartItemDetail;
