import { StoreJson } from 'common/types/Store';
import useDatabaseTable from 'hooks/useDatabaseTable';

const TextStore = ({ id }: { id: number }) => {
  const [items] = useDatabaseTable<StoreJson>("store");

  return <>{items.find((item) => item.id === id)?.name || "--"}</>;
};

export default TextStore;
