import { Col, Row } from "antd";
import useWindowDimensions from "hooks/useWindowDimensions";
import useMenuStore from "zustands/useMenuStore";

import useDidMount from "@rooks/use-did-mount";

import HomeLeft from "./HomeLeft";
import HomeRight from "./HomeRight";

const HomeIndex = () => {
  const { height } = useWindowDimensions();

  const [setOpenKeys, setSelectedKeys] = useMenuStore((state) => [
    state.setOpenKeys,
    state.setSelectedKeys,
  ]);

  useDidMount(() => {
    setOpenKeys(["sale"]);
    setSelectedKeys([]);
  });

  return (
    <>
      <Row
        className="md:flex-nowrap h-full"
        gutter={0}
        style={{ minHeight: height - 60 }}
      >
        <Col flex={"auto"}>
          <HomeLeft />
        </Col>
        <Col
          flex={"400px"}
          className="bg-gray-100 border-l border-gray-200 inopen lg:open mt-1 md:mt-0 lg:flex-grow-1 "
        >
          <HomeRight />
        </Col>
      </Row>
    </>
  );
};

export default HomeIndex;
