import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import type { RoleUserJson } from "common/types/RbacRoleUser";

class RbacRoleUserModel
  extends BaseModel
  implements BaseModelJson<RoleUserJson>
{
  company_id: number;
  id: number;
  role_id: number;
  user_id: number;
  date_created: number;
  date_modified: number;

  constructor(json: RoleUserJson) {
    super();

    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.role_id = json.role_id || 0;
    this.user_id = json.user_id || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): RoleUserJson {
    return {
      company_id: 0,
      id: 0,
      role_id: 0,
      user_id: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): RoleUserJson {
    return {
      company_id: this.company_id,
      id: this.id,
      role_id: this.role_id,
      user_id: this.user_id,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default RbacRoleUserModel;
