import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Category } from "common/types/Collection";
import { SizeCard } from "../../types";
import { CollectionJson } from "common/types/Collection";
import styles from "./TagCategories.module.css";
import { StoryCollectionItemJson } from "common/types/Story";
type Props = {
  data: StoryCollectionItemJson[];
  size: SizeCard;
};
const TagCategories = ({ data, size }: Props) => {
  const varibleClass = useMemo(() => {
    switch (size) {
      case "small":
        return "sizeSmall";

      default:
        return "sizeDefault";
    }
  }, [size]);

  return (
    <div>
      <div className={`${varibleClass} ${styles.wrapper}`}>
        {data.length > 0 &&
          data.map((item) => {
            return (
              <Link className={styles.link} to={`/academy/${item.handle}`}>
                <span className={styles.tag}>{item.title}</span>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default TagCategories;
