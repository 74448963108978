import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import { GetListProps } from "common/types/Filter";

import TagCollection from "common/collections/TagCollection";
import {
  DeleteProductRelatedTagJson,
  FilterTag,
  TagAddEditJson,
  TagDetailAddJson,
  TagDisplayUpdateJson,
  TagProductStructAddEditJson,
  TagRelatedAddJson,
} from "common/types/Tag";
import Helper from "common/utils/helper";
import TagModel from "common/models/TagModel";
import TagDetailCollection from "common/collections/TagDetailCollection";
import { FilterTagDetail, TagStructItem } from "common/types/TagDetail";

const SERVICE_URL = "/tags";

class TagApi extends BaseApi {
  async getList(props: GetListProps<FilterTag>): Promise<TagCollection> {
    let collection = new TagCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async getDetail(id: number): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  async add(props: TagAddEditJson): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: TagAddEditJson): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  //add tag to detail
  async addTagDetail(props: TagDetailAddJson): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/detail",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  async deleteTagDetailItem(list_ids: number[]): Promise<string[]> {
    let resultErrors = [];
    let dataProps: any = { ids: list_ids.toString() };
    try {
      let postData = { ...dataProps };
      console.log("🥺 ~ TagApi ~ deleteTagDetailItem ~ postData:", postData);
      const response = await AxiosClient().delete(
        SERVICE_URL + "/detail/listid",
        {
          data: postData,
        }
      );
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async deleteProductRelatedTagDetailItem(
    data: DeleteProductRelatedTagJson
  ): Promise<string[]> {
    let resultErrors = [];
    let dataProps: any = { ...data };
    try {
      let postData = { ...dataProps };
      console.log("🥺 ~ TagApi ~ deleteTagDetailItem ~ postData:", postData);
      const response = await AxiosClient().delete(SERVICE_URL + "/detail", {
        data: postData,
      });
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async getTagDetailList(
    props: GetListProps<FilterTagDetail>
  ): Promise<TagDetailCollection> {
    let collection = new TagDetailCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/public/detail",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  //update display tag to detail
  async updateTagDetailDisplay(props: TagDisplayUpdateJson): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());

    let dataProps: any = { ...props };

    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/updatedisplay/" + props.tagdetail_id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  
  
  //update display tag to detail
  async updateTagDetailDisplayProduct(props: TagDisplayUpdateJson): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());

    let dataProps: any = { ...props };

    const convertProps = Helper.convertParams(dataProps , true);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/detail/display/" + props.tagdetail_id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  //update tag productstruct
  //update display tag to detail
  async updateTagProductStruct(
    props: TagProductStructAddEditJson
  ): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());

    let dataProps: any = { ...props };

    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/fulltag",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  //add tag to detail
  async addTagRelated(props: TagRelatedAddJson): Promise<TagModel> {
    let item = new TagModel(TagModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/relateitem",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  //get struct
  async getStructList(
    props: GetListProps<FilterTag>
  ): Promise<TagStructItem[]> {
    let collection: TagStructItem[] = [];
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/default",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection = response.data;
      }
    } catch (error) {
      // collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
}

export default TagApi;
