import {
	Badge,
	Button,
	Col,
	Form,
	Input,
	message,
	Modal,
	Popconfirm,
	Row,
	Table,
	TableProps,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import SettingRepository from "common/repositories/SettingRepository";
import {
	DealthomConfig,
	PromotionJson,
	TypeTagConfig,
} from "common/types/Promotion";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DealthomConfigHeader from "./DealthomConfigHeader";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import { IconSettings, IconTrash } from "@tabler/icons-react";
import { SettingAddEditJson, SettingJson } from "common/types/Setting";
import SettingModel from "common/models/SettingModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import DealthomPromotionForm from "../form/DealthomPromotionForm";
import DealthomForm from "../form/DealthomForm";
import { ClearOutlined, GifOutlined, GiftOutlined } from "@ant-design/icons";
import TableDelete from "components/table/TableDelete";
import DealthomStyleForm from "../form/DealthomStyleForm";

export default function DealthomConfigList() {
	const keySettingDealthom = "setting_show_web_code_promotion";
	const { t } = useTranslation();
	const [messageApi, contextHolder] = message.useMessage();

	const [dataSourceSetting, setDataSourceSetting] = useState<SettingJson>(
		SettingModel.getDefaultData()
	);
	const [allPromnotion, setAllPromotion] = useState<PromotionJson[]>([]);

	const [loading, setLoading] = useState<boolean>(true);
	const [modelEdit, setModelEdit] = useState<DealthomConfig | null>(null);
	const [indexEdit, setIndexEdit] = useState<number>(-1);

	const [collumUpdating, setCollumUpdating] = useState<
		"" | keyof DealthomConfig | "all"
	>("");

	const data: DealthomConfig[] = [
		{
			code: "Dealthom 5",
			promotion_id: [416, 500273023],
			tag_infor: [
				{
					bg_color: "red",
					text_color: "white",
					name: "Sale",
					type: TypeTagConfig.VARIANT,
				},
				{
					bg_color: "red",
					text_color: "white",
					name: "Sale",
					type: TypeTagConfig.CARD,
				},
			],
		},
	];

	const columns: TableProps<DealthomConfig>["columns"] = [
		{
			title: t("Mã code"),
			key: "code",
			width: 200,
			align: "center",
			dataIndex: "code",
			render: (code, record, index) => {
				if (!code.length) {
					return (
						<Tag
							color="green"
							icon={<GiftOutlined size={14} />}
							className=" ml-1 cursor-pointer inline-flex items-center gap-1"
							// onClick={() => {
							//   setEditPromotionCouponVisible(true);
							//   setEditingPromotionModel(record);
							// }}
						>
							CK áp dụng trực tiếp
						</Tag>
					);
				}
				return (
					<Tag
						color="default"
						icon={<IconSettings size={14} />}
						className=" ml-1 cursor-pointer inline-flex items-center gap-1"
						// onClick={() => {
						//   setEditPromotionCouponVisible(true);
						//   setEditingPromotionModel(record);
						// }}

						onClick={() => {
							setIndexEdit(index);
							setCollumUpdating("code");
							setModelEdit(record);
						}}>
						<Typography.Text className="text-blue-600">{code}</Typography.Text>
					</Tag>
				);
			},
		},

		{
			title: t("Chương trình KM"),
			key: "promotion_id",
			width: 200,
			align: "center",
			dataIndex: "promotion_id",
			render: (_, record, index) => {
				return (
					<div className="flex items-center gap-4">
						<Tooltip
							title={t("Cấu hình các chương trình khuyến mãi áp dụng")}
							mouseEnterDelay={0.4}>
							<Badge count={record.promotion_id.length}>
								<Tag
									color="default"
									icon={<IconSettings size={14} />}
									className=" ml-1 cursor-pointer inline-flex items-center gap-1"
									// onClick={() => {
									//   setEditPromotionCouponVisible(true);
									//   setEditingPromotionModel(record);
									// }}

									onClick={() => {
										setIndexEdit(index);

										setCollumUpdating("promotion_id");
										setModelEdit(record);
									}}>
									<Typography.Text>Nhóm khuyễn mãi</Typography.Text>
								</Tag>
							</Badge>
						</Tooltip>
						{record.promotion_id.length > 0 && (
							<Popconfirm
								title="Reset tất cả collection đã áp dụng"
								onConfirm={() => {
									handleResetCollection(record, index);
								}}>
								<Tag
									color="default"
									icon={<ClearOutlined size={14} />}
									className="text-right cursor-pointer inline-flex items-center gap-1 p-1"
									// onClick={() => {
									//   setEditPromotionCouponVisible(true);
									//   setEditingPromotionModel(record);
									// }}
								></Tag>
							</Popconfirm>
						)}
					</div>
				);
			},
		},
		{
			title: t("Cài đặt khác"),
			key: "tag_infor",
			width: 200,
			align: "center",
			dataIndex: "tag_infor",

			render: (_, record, index) => {
				return (
					<Tooltip
						title={t("Cấu hình các thông tin hiển thị cho Deal thơm")}
						mouseEnterDelay={0.4}>
						<Tag
							color="default"
							icon={<IconSettings size={14} />}
							className=" ml-1 cursor-pointer inline-flex items-center gap-1"
							// onClick={() => {
							//   setEditPromotionCouponVisible(true);
							//   setEditingPromotionModel(record);
							// }}

							onClick={() => {
								setIndexEdit(index);

								setCollumUpdating("tag_infor");
								setModelEdit(record);
							}}>
							<Typography.Text>Thiết lập Phong cách</Typography.Text>
						</Tag>
					</Tooltip>
				);
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (id: number, record, index) => {
				return (
					<div>
						<Popconfirm
							placement="topRight"
							title={<>Bạn chắc chắn muốn xóa ?</>}
							description={"Gỡ chương trình khỏi cài đặt "}
							okText="Đồng ý"
							cancelText="Hủy"
							onConfirm={() => onDelete(record, index)}>
							{/* <TableEdit label="" icon={<IconTrash></IconTrash>} link={""} /> */}
							<IconTrash className="cursor-pointer"></IconTrash>
						</Popconfirm>
						,
					</div>
				);
			},
		},
	];

	const dataSource: DealthomConfig[] = useMemo(() => {
		if (dataSourceSetting.id > 0) {
			return dataSourceSetting.value as DealthomConfig[];
		}
		return [];
	}, [dataSourceSetting]);

	const listIdPromotionUsed = useMemo(() => {
		if (modelEdit) {
			const dataSettingFilter = dataSource.filter(
				(i) => i.code !== modelEdit.code
			);
			return dataSettingFilter.flatMap((i) => i.promotion_id);
		}
		return [];
	}, [modelEdit, dataSource]);

	const getData = async (isInit?: boolean) => {
		setLoading(true);
		Promise.allSettled([
			new SettingRepository().getItem(keySettingDealthom),
			// new PromotionRepository().getItems({
			// 	filters: PromotionRepository.getDefaultFilters(),
			// }),
		]).then(([settingRes]) => {
			if (settingRes.status === "fulfilled" && !settingRes.value.hasError()) {
				setDataSourceSetting(settingRes.value.toJson());
			}
			// if (
			// 	promotionRes.status === "fulfilled" &&
			// 	!promotionRes.value.hasError()
			// ) {
			// 	setAllPromotion(promotionRes.value.toJson().items);
			// }
		});
		setLoading(false);
	};

	////////////////////////////////////////

	const onUpdate = async (data: Partial<DealthomConfig>) => {
		const listConfig = dataSourceSetting.value as DealthomConfig[];
		return new Promise(async (resolve, reject) => {
			if (modelEdit) {
				let dataEditing = { ...modelEdit };

				for (const key in data) {
					if (dataEditing.hasOwnProperty(key)) {
						dataEditing = Object.assign(dataEditing, data);
					}
				}

				const newSettingConfigs = listConfig.reduce(
					(curr: DealthomConfig[], prev: DealthomConfig, index) => {
						if (index === indexEdit) {
							curr.push(Object.assign(prev, dataEditing));
						} else {
							curr.push(prev);
						}

						return curr;
					},
					[]
				);

				const dataEdit: SettingAddEditJson = {
					...dataSourceSetting,
					value: JSON.stringify(newSettingConfigs),
				};
				const res = await new SettingRepository().saveRemote(dataEdit);
				if (res.hasError()) {
					messageApi.error("Thay đổi thất bại !");
				} else {
					messageApi.success("Thay đổi thành công !");
					reset();
					getData();
				}
				resolve(1);
			} else {
				reject();
			}
		});
	};

	const onAdd = async (data: DealthomConfig) => {
		const listConfig = dataSourceSetting.value as DealthomConfig[];

		const newListConfig = [...listConfig].concat(data);
		const res = await new SettingRepository().saveRemote({
			...dataSourceSetting,
			value: JSON.stringify(newListConfig),
		});
		if (!res.hasError()) {
			messageApi.success("Tạo cài đặt thành công !");
			getData();
			setCollumUpdating("");
		} else {
			messageApi.error("Tạo cài đặt thất bại !");
		}
	};

	const onDelete = async (data: DealthomConfig, indexDelete: number) => {
		const listConfig = dataSourceSetting.value as DealthomConfig[];
		listConfig.splice(indexDelete, 1);
		const res = await new SettingRepository().saveRemote({
			...dataSourceSetting,
			value: JSON.stringify(listConfig),
		});
		if (!res.hasError()) {
			messageApi.success("Xóa thành công !");
			getData();
		} else {
			messageApi.error("Xóa thất bại !");
		}
	};

	const reset = () => {
		setModelEdit(null);
		setIndexEdit(-1);
		setCollumUpdating("");
	};

	async function handleResetCollection(data: DealthomConfig, index: number) {
		const listConfig = dataSourceSetting.value as DealthomConfig[];
		if (listConfig?.[index]) {
			listConfig[index].promotion_id = [];
		}
		const dataEdit: SettingAddEditJson = {
			...dataSourceSetting,
			value: JSON.stringify(listConfig),
		};
		const res = await new SettingRepository().saveRemote(dataEdit);
		if (!res.hasError()) {
			messageApi.success("Rest thành công !");
			getData();
		} else {
			messageApi.error("Reset thất bại !");
		}
	}

	useEffect(() => {
		getData();
	}, []);
	return (
		<div>
			{contextHolder}
			<DealthomConfigHeader>
				<>
					<Button
						onClick={() => {
							setCollumUpdating("all");
						}}>
						Thêm mới
					</Button>
				</>
			</DealthomConfigHeader>
			<Table
				loading={loading}
				columns={columns}
				dataSource={dataSource}></Table>
			<Modal
				title="Cài đặt Deal Thơm"
				destroyOnClose
				open={modelEdit !== null || collumUpdating === "all"}
				onCancel={() => {
					setIndexEdit(-1);
					setCollumUpdating("");
					setModelEdit(null);
				}}
				okButtonProps={{ className: "hidden" }}>
				{modelEdit && (
					<>
						{collumUpdating === "promotion_id" && (
							<DealthomPromotionForm
								idPromtionUsed={listIdPromotionUsed}
								promotion_id={modelEdit.promotion_id}
								onUpdate={onUpdate}></DealthomPromotionForm>
						)}
						{collumUpdating === "code" && (
							<DealthomCodeForm
								code={modelEdit.code}
								listCodeExited={dataSource.map((c) => c.code)}
								onUpdate={onUpdate}></DealthomCodeForm>
						)}
						{collumUpdating === "tag_infor" && (
							<DealthomStyleForm
								tag_infor={modelEdit.tag_infor}
								onUpdate={onUpdate}></DealthomStyleForm>
						)}
					</>
				)}
				{collumUpdating === "all" && (
					<DealthomForm
						listCodeExited={dataSource.map((c) => c.code)}
						onAdd={onAdd}></DealthomForm>
				)}
			</Modal>
		</div>
	);
}

function DealthomCodeForm({
	code,
	listCodeExited,
	onUpdate,
}: {
	code: string;
	listCodeExited: string[];
	onUpdate: (data: Partial<DealthomConfig>) => Promise<unknown>;
}) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const onSubmit = (formData: { code: string }) => {
		setLoading(true);
		onUpdate({ code: formData.code.trim() }).finally(() => {
			setLoading(false);
		});
	};
	return (
		<Form form={form} onFinish={onSubmit} initialValues={{ code: code }}>
			<Row gutter={8}>
				<Col>
					<Form.Item
						rules={[
							{
								required: true,
								message: "Vui lòng nhập code",
							},
							{
								pattern: /^[a-zA-Z0-9À-ỹà-ỹ]+$/,
								message: "Vui lòng không sử dụng ký tự đặc biệt",
							},
							{
								message: "Đã có mã code tồn tại",
								validator: (_: any, value: string) => {
									if (listCodeExited.includes(value)) {
										return Promise.reject("Some message here");
									} else {
										return Promise.resolve();
									}
								},
							},
						]}
						// help={<>Code hiển thị cho khác hàng nhập</>}
						name={"code"}
						initialValue={{ code: code }}>
						<Input></Input>
					</Form.Item>
					<small className="text-gray-500">
						Chú ý: Code hiển thị cho khách hàng nhập
					</small>
				</Col>

				<Col>
					<Form.Item className="text-right">
						<Button loading={loading} htmlType="submit">
							Xác nhận
						</Button>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
