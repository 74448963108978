import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate, useParams } from "react-router-dom";
import StoryRepository from "common/repositories/StoryRepository";
import StoryModel from "common/models/StoryModel";
import EyesIcon from "features/academy/icon/EyesIcon";
import TextDateTime from "components/TextDateTime";
import AcademyBreadcrumb from "../AcademyBreadcrumb/AcademyBreadcrumb";
import UserIcon from "features/academy/icon/UserIcon";
import ClockIcon from "features/academy/icon/ClockIcon";
import ThumbUpIcon from "features/academy/icon/ThumbUpIcon";
import MessageIcon from "features/academy/icon/MessageIcon";
import AcademySkeleton from "./../AcademySkeleton/AcademySkeleton";
const AcademyStoryDetail = () => {
  const { storeid } = useParams();
  const navigate = useNavigate();
  const id = storeid!;

  const [model, setModel] = useState(StoryModel.getDefaultData());
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleFetchDetail = async () => {
    setLoading(true);
    const response = await new StoryRepository().getItemPublic({ handle: id });
    if (!response.hasError()) {
      setModel(response.toJson());
    } else {
      setErrors(response.error.errors);
      navigate("/academy/notfound");
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchDetail();
  }, [id]);

  return (
    <div className={styles.wrapper}>
      <AcademySkeleton
        loading={loading}
        isImage={false}
        skeletonProps={{
          paragraph: {
            rows: 6,
            width: "100%",
          },
          active: true,
          round: true,
          title: true,
        }}
        children={
          <>
            <div className={styles.breadcrumb}>
              <AcademyBreadcrumb />
            </div>
            <div className={styles.title}>
              <h1>{model.title}</h1>
            </div>
            <div className={styles.information}>
              <div className={styles.created}>
                <span>
                  <UserIcon></UserIcon>
                  {model.creator_id}
                </span>
              </div>
              <div className={styles.tag}>
                <span className={styles.spanTag}>
                  <ClockIcon></ClockIcon>
                  <TextDateTime ts={model.date_created}></TextDateTime>
                </span>
                <span className={styles.spanTag}>
                  <EyesIcon></EyesIcon>
                  {model.viewed}
                </span>
                <span className={styles.spanTag}>
                  <ThumbUpIcon color="gray"></ThumbUpIcon>
                  {model.viewed}
                </span>
                <span className={styles.spanTag}>
                  <MessageIcon color="gray"></MessageIcon>
                  {model.visitor}
                </span>
              </div>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: model.detail }}
            ></div>
          </>
        }
      ></AcademySkeleton>
    </div>
  );
};

export default AcademyStoryDetail;
