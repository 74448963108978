import PurchaseOrder from "common/constants/PurchaseOrder";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import CashflowReceiptModel from "./CashflowReceiptModel";
import ProductReceiptModel from "./ProductReceiptModel";
import PurchaseOrderDetailModel from "./PurchaseOrderDetailModel";

import type { PurchaseOrderJson } from "common/types/PurchaseOrder";
class PurchaseOrderModel
  extends BaseModel
  implements BaseModelJson<PurchaseOrderJson>
{
  company_id: number;
  creator_id: number;
  id: number;
  supplier_id: number;
  warehouse_id: number;
  invoice_id: string;
  price_sell: number;
  price_shipping: number;
  price_discount: number;
  price_final: number;
  price_deposit: number;
  price_debt: number;
  product_receipt_id_list: number[];
  product_receipt_list: ProductReceiptModel[];
  cashflow_receipt_id_list: number[];
  cashflow_receipt_list: CashflowReceiptModel[];
  quantity: number;
  note: string;
  status: number;
  cancel_reason: number;
  tag: string;
  date_created: number;
  date_modified: number;
  details: PurchaseOrderDetailModel[];

  constructor(json: PurchaseOrderJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.supplier_id = json.supplier_id || 0;
    this.warehouse_id = json.warehouse_id || 0;
    this.invoice_id = json.invoice_id || "";
    this.price_sell = json.price_sell || 0;
    this.price_shipping = json.price_shipping || 0;
    this.price_discount = json.price_discount || 0;
    this.price_final = json.price_final || 0;
    this.price_deposit = json.price_deposit || 0;
    this.price_debt = json.price_debt || 0;
    this.product_receipt_id_list = json.product_receipt_id_list || [];
    this.product_receipt_list = json.product_receipt_list.map(
      (item) => new ProductReceiptModel(item)
    );

    this.cashflow_receipt_id_list = json.cashflow_receipt_id_list || [];
    this.cashflow_receipt_list = json.cashflow_receipt_list.map(
      (item) => new CashflowReceiptModel(item)
    );
    this.quantity = json.quantity || 0;
    this.note = json.note || "";
    this.status = json.status || 0;
    this.cancel_reason = json.cancel_reason || 0;
    this.tag = json.tag || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.details = Array.isArray(json.details)
      ? json.details.map((item) => new PurchaseOrderDetailModel(item))
      : [];
  }

  static getDefaultData(): PurchaseOrderJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      supplier_id: 0,
      warehouse_id: 0,
      invoice_id: "",
      price_sell: 0,
      price_shipping: 0,
      price_discount: 0,
      price_final: 0,
      price_deposit: 0,
      price_debt: 0,
      product_receipt_id_list: [],
      product_receipt_list: [],
      cashflow_receipt_id_list: [],
      cashflow_receipt_list: [],
      quantity: 0,
      note: "",
      status: PurchaseOrder.STATUS_DRAFT,
      cancel_reason: 0,
      tag: "",
      date_created: 0,
      date_modified: 0,
      details: [],
    };
  }

  toJson(): PurchaseOrderJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      supplier_id: this.supplier_id,
      warehouse_id: this.warehouse_id,
      invoice_id: this.invoice_id,
      price_sell: this.price_sell,
      price_shipping: this.price_shipping,
      price_discount: this.price_discount,
      price_final: this.price_final,
      price_deposit: this.price_deposit,
      price_debt: this.price_debt,
      product_receipt_id_list: this.product_receipt_id_list,
      product_receipt_list: this.product_receipt_list.map((item) =>
        item.toJson()
      ),
      cashflow_receipt_id_list: this.cashflow_receipt_id_list,
      cashflow_receipt_list: this.cashflow_receipt_list.map((item) =>
        item.toJson()
      ),
      quantity: this.quantity,
      note: this.note,
      status: this.status,
      cancel_reason: this.cancel_reason,
      tag: this.tag,
      date_created: this.date_created,
      date_modified: this.date_modified,
      details: this.details.map((item) => item.toJson()),
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: PurchaseOrder.STATUS_DRAFT,
        label: i18n.t("purchaseorder:STATUS_DRAFT"),
        color: "black",
      },
      {
        value: PurchaseOrder.STATUS_WAIT_FOR_APPROVAL,
        label: i18n.t("purchaseorder:STATUS_WAIT_FOR_APPROVAL"),
        color: "orange",
      },
      {
        value: PurchaseOrder.STATUS_APPROVED,
        label: i18n.t("purchaseorder:STATUS_APPROVED"),
        color: "blue",
      },
      {
        value: PurchaseOrder.STATUS_COMPLETED,
        label: i18n.t("purchaseorder:STATUS_COMPLETED"),
        color: "green",
      },
      {
        value: PurchaseOrder.STATUS_CANCELLED,
        label: i18n.t("purchaseorder:STATUS_CANCELLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getCancelReasonList(): SelectOption[] {
    return [
      {
        value: PurchaseOrder.CANCEL_REASON_OUT_OF_STOCK,
        label: i18n.t("purchaseorder:CANCEL_REASON_OUT_OF_STOCK"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_DUPLICATE,
        label: i18n.t("purchaseorder:CANCEL_REASON_DUPLICATE"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_INCORRECT_ITEM,
        label: i18n.t("purchaseorder:CANCEL_REASON_INCORRECT_ITEM"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_HIGH_SHIPPING_FEE,
        label: i18n.t("purchaseorder:CANCEL_REASON_HIGH_SHIPPING_FEE"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_DELIVERY_NOT_ONTIME,
        label: i18n.t("purchaseorder:CANCEL_REASON_DELIVERY_NOT_ONTIME"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_CUSTOMER_NOT_SATISFY,
        label: i18n.t("purchaseorder:CANCEL_REASON_CUSTOMER_NOT_SATISFY"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_SYSTEM_ERROR,
        label: i18n.t("purchaseorder:CANCEL_REASON_SYSTEM_ERROR"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_PARTNER_ERROR,
        label: i18n.t("purchaseorder:CANCEL_REASON_PARTNER_ERROR"),
      },
      {
        value: PurchaseOrder.CANCEL_REASON_OTHER,
        label: i18n.t("purchaseorder:CANCEL_REASON_OTHER"),
      },
    ];
  }

  static getCancelReason(value: number): SelectOption | undefined {
    return this.getCancelReasonList().find((item) => item.value === value);
  }
}

export default PurchaseOrderModel;
