import ProductInventoryCheckingDetailApi from "common/api/ProductInventoryCheckingDetailApi";
import ProductInventoryCheckingDetailCollection from "common/collections/ProductInventoryCheckingDetailCollection";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  ProductInventoryCheckingDetailJsonAddEdit,
  FilterProductInventoryCheckingDetail,
} from "common/types/ProductInventoryCheckingDetail";

class ProductInventoryCheckingDetailRepository extends BaseRepository<ProductInventoryCheckingDetailApi> {
  _api: ProductInventoryCheckingDetailApi | null;

  constructor() {
    super();
    this._api = new ProductInventoryCheckingDetailApi(true);
  }

  static getDefaultFilters(): FilterProductInventoryCheckingDetail {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      product_inventory_checking_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterProductInventoryCheckingDetail>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductInventoryCheckingDetailCollection();
  }

  async getItem(id: number): Promise<ProductInventoryCheckingDetailModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductInventoryCheckingDetailModel(
          ProductInventoryCheckingDetailModel.getDefaultData()
        );
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductInventoryCheckingDetailJsonAddEdit
  ): Promise<ProductInventoryCheckingDetailModel> {
    if (this._api) {
      if (data.id && data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductInventoryCheckingDetailModel(
        ProductInventoryCheckingDetailModel.getDefaultData()
      );
    }
  }
}

export default ProductInventoryCheckingDetailRepository;
