import TemplateModel from "common/models/TemplateModel";
import TemplateRepository from "common/repositories/TemplateRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import TemplateForm from "features/template/form/TemplateForm";
import TemplateFormHeader from "features/template/form/TemplateFormHeader";
import TemplateList from "features/template/list/TemplateList";
import usePathParams from "hooks/usePathParams";
import React, { useState } from "react";

const TemplatePage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new TemplateModel(TemplateModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  let com = <></>;

  switch (pathParams.action) {
    case "add":
      com = <TemplateForm model={model}></TemplateForm>;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <TemplateForm model={model}></TemplateForm>
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new TemplateRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<TemplateFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    default:
      com = <TemplateList></TemplateList>;
      break;
  }
  return com;
};

export default TemplatePage;
