import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const ShippingTypeListHeader = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("shippingtype:heading_list")}
      siteMenuOpenKey="setting_delivery"
      siteMenuSelectedKey="/shippingtype">
      {children}
    </PageHeader>
  );
};

export default ShippingTypeListHeader;
