import WorkTrackingRange from "common/constants/WorkTrackingRange";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import { Dayjs } from "dayjs";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type {
  WorkTrackingRangeJson,
  WorkTrackingRangeJsonFetcherResult,
} from "common/types/WorkTrackingRange";
class WorkTrackingRangeModel
  extends BaseModel
  implements BaseModelJson<WorkTrackingRangeJson>
{
  company_id: number;
  id: number;
  name: string;
  color: string;
  display_order: number;
  time_start: string;
  time_end: string;
  status: number;
  ratio: number;
  ignore_duration: string;
  key: number;
  time_picker: Dayjs[];

  constructor(json: WorkTrackingRangeJson) {
    super();

    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.color = "#" + json.color || "";
    this.display_order = json.display_order || 0;
    this.time_start = json.time_start || "";
    this.time_end = json.time_end || "";
    this.status = json.status || -1;
    this.ratio = json.ratio || 0;
    this.ignore_duration = json.ignore_duration || "";
    this.key = json.id || 0;
    this.time_picker = json.time_picker || [];
  }

  static getDefaultFetcherResultData(): WorkTrackingRangeJsonFetcherResult {
    return {
      id: 0,
      name: "",
      color: "",
      time_start: "",
      time_end: "",
    };
  }

  static getDefaultData(): WorkTrackingRangeJson {
    return {
      company_id: 0,
      id: 0,
      name: "",
      color: "",
      display_order: 0,
      time_start: "",
      time_end: "",
      status: 0,
      ratio: 0,
      ignore_duration: "",
      key: 0,
      time_picker: [],
    };
  }

  toJson(): WorkTrackingRangeJson {
    return {
      company_id: this.company_id,
      id: this.id,
      name: this.name,
      color: this.color,
      display_order: this.display_order,
      time_start: this.time_start,
      time_end: this.time_end,
      status: this.status,
      ratio: this.ratio,
      ignore_duration: this.ignore_duration,
      key: this.key,
      time_picker: this.time_picker,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: WorkTrackingRange.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: WorkTrackingRange.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default WorkTrackingRangeModel;
