import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

import StoryModel from "common/models/StoryModel";
import StoryRepository from "common/repositories/StoryRepository";

import StoryList from "features/story/list/StoryList";
import StoryForm from "features/story/form/StoryForm";
import FormEditFetcher from "components/form/FormEditFetcher";
import StoryFormHeader from "features/story/form/StoryFormHeader";
import StoryCollectionList from "features/storycollection/list/StoryCollectionList";
import StoryCollectionForm from "features/storycollection/form/StoryCollectionForm";
import StoryCollectionFormHeader from "features/storycollection/form/StoryCollectionFormHeader";
import StoryCollectionModel from "common/models/StoryCollectionModel";
import StoryCollectionRepository from "common/repositories/StoryCollectionRepository";

const StoryCollectionPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new StoryCollectionModel(StoryCollectionModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new StoryCollectionModel(StoryCollectionModel.getDefaultData()));
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    case "add":
      com = <StoryCollectionForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <StoryCollectionForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new StoryCollectionRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<StoryCollectionFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    default:
      com = <StoryCollectionList />;
      break;
  }
  return com;
};

export default StoryCollectionPage;
