import useDidMount from "@rooks/use-did-mount";
import { GeoLocation } from "common/types/Gps";
import { useState } from "react";

const useGeoLocation = () => {
  const [location, setLocation] = useState<GeoLocation>(() => {
    return {
      loaded: false,
      require: false,
      coordinates: {
        lat: 0,
        lng: 0,
      },
      timestamp: 0,
    };
  });
  const onSuccess = (location: GeolocationPosition) => {
    setLocation((prev) => {
      return {
        loaded: true,
        require: true,
        coordinates: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        },
        timestamp: location.timestamp,
      };
    });
  };
  const onError = (error: any) => {
    setLocation({
      ...error,
      isError: true,
      loaded: true,
      require: false,
    });
  };
  const getGps = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      onError({
        code: 0,
        message: "Geoloation not supported",
      });
    }
  };
  useDidMount(() => {
    getGps();
  });
  return {
    location,
  };
};
export default useGeoLocation;
