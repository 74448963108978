import TaxInvoiceDetailApi from "common/api/TaxInvoiceDetailApi";
import TaxInvoiceDetailCollection from "common/collections/TaxInvoiceDetailCollection";

import BaseRepository from "./BaseRepository";

import type { FilterTaxInvoiceDetail } from "common/types/TaxInvoiceDetail";

class TaxInvoiceDetailRepository extends BaseRepository<TaxInvoiceDetailApi> {
  _api: TaxInvoiceDetailApi | null;

  constructor() {
    super();
    this._api = new TaxInvoiceDetailApi(true);
  }

  static getDefaultFilters(): FilterTaxInvoiceDetail {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      tax_invoice_id: -1,
    };
  }

  async getFullDetailOfInvoice(invoiceId: number) {
    return this._api
      ? await this._api.getFullDetailOfInvoice(invoiceId)
      : new TaxInvoiceDetailCollection();
  }
}

export default TaxInvoiceDetailRepository;
