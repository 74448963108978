import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import {
  IconAlignBoxLeftBottom,
  IconAssembly,
  IconMaximize,
  IconQrcode,
  IconWifi,
} from "@tabler/icons-react";

type ActionItem = {
  link: string;
  label: string;
  icon: React.ReactNode;
};
type RowData = "row1" | "row2";
type Data = Record<RowData, ActionItem[]>;
const HomeRightUtilities = () => {
  const IconClassname = "mr-1";
  const IconSize = 16;
  const data: Data = {
    row1: [
      {
        label: "Tạo QR code",
        link: "/tool",
        icon: <IconQrcode size={IconSize} className={IconClassname} />,
      },
      {
        label: "Tạo Wifi QR code",
        link: "/tool/wifiqrcode",
        icon: <IconWifi size={IconSize} className={IconClassname} />,
      },
    ],
    row2: [
      {
        label: "Tạo avatar",
        link: "/tool/avatarframe",
        icon: <IconMaximize size={IconSize} className={IconClassname} />,
      },
      {
        label: "IT Request",
        link: "/itrequest",
        icon: <IconAssembly size={IconSize} className={IconClassname} />,
      },
    ],
  };
  return (
    <>
      <div className="px-3 mb-6">
        <div className="font-semibold text-base mb-2">
          <IconAlignBoxLeftBottom className="-mt-1" /> Tiện ích{" "}
        </div>
        <div className="ml-3 pl-4 border-l border-gray-300 leading-relaxed">
          {Object.entries(data).map(
            ([key, value]: [key: any, value: ActionItem[]]) => {
              return (
                <Row key={key} gutter={[8, 8]} className="mb-4">
                  {value.map((item, index) => {
                    return (
                      <Col key={index} span={24} xl={12}>
                        <Link to={item.link}>
                          <Button
                            className="flex items-center justify-center"
                            block
                            shape="round"
                            type="primary"
                            size="middle"
                            icon={item.icon}
                          >
                            {item.label}
                          </Button>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default HomeRightUtilities;
