const Ecom = {
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,

  TYPE_HARAVAN: 1,
  TYPE_SHOPIFY: 3,
  TYPE_WOO_COMMERCE: 5,
  TYPE_KIOTVIET: 6,

};

export default Ecom;
