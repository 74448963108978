import TaxInvoiceModel from "common/models/TaxInvoiceModel";
import TableEdit from "components/table/TableEdit";
import React from "react";

const TaxInvoiceItemEdit = ({
  model,
  children,
  notPermissionFallback
}: {
  model: TaxInvoiceModel;
  children?: React.ReactNode;
  notPermissionFallback?: React.ReactNode;
}) => {
  return (
    <>{children || <TableEdit link={"/taxinvoice/edit/id/" + model.id} />}</>
  );
};

export default TaxInvoiceItemEdit;
