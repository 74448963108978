import React from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const RoleCheck = ({
  roles,
  children,
  hideOnFail,
  componentOnFail,
}: {
  roles: Array<number>;
  children: JSX.Element | JSX.Element[];
  hideOnFail?: boolean;
  componentOnFail?: React.ReactNode;
}) => {
  const accountRoleSubjects = useLoginAccountStore((state) =>
    state.account.role.map((r) => r.subject_id)
  );

  // if current logged in user include ONE role need to check, it's PASS
  const hasRole =
    roles.length > 0
      ? roles.filter((r) => accountRoleSubjects.includes(r)).length > 0
      : true;

  if (hasRole) {
    return <>{children}</>;
  } else {
    if (typeof hideOnFail !== "undefined" && hideOnFail) {
      return null;
    } else {
      if (typeof componentOnFail !== "undefined") {
        return <>{componentOnFail}</>;
      } else {
        return null;
      }
    }
  }
};

export default RoleCheck;
