import { Tooltip } from "antd";
import ProductVariantInMemoryCollection from "common/collections/ProductVariantInMemoryCollection";
import Role from "common/constants/Role";
import InventoryStockModel from "common/models/InventoryStockModel";
import ProductVariantInMemoryModel from "common/models/ProductVariantInMemoryModel";
import InventoryStockRepository from "common/repositories/InventoryStockRepository";
import ProductVariantRepository from "common/repositories/ProductVariantRepository";
import { InventoryStockNumberType } from "common/types/InventoryStock";
import { ProductCategoryJson } from "common/types/ProductCategory";
import { FilterProductVariantInMemory } from "common/types/ProductVariant";
import { TableColumnsType, TableColumnToggleType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableLinkText from "components/table/TableLinkText";
import TextProductVariantColor from "components/TextProductVariantColor";
import TextProductVariantSize from "components/TextProductVariantSize";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { findIndex, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconLockOpen } from "@tabler/icons-react";

import InventoryBySkuCell from "./InventoryBySkuCell";
import InventoryBySkuFilter from "./InventoryBySkuFilter";
import InventoryBySkuHeader from "./InventoryBySkuHeader";
import TableInfo from "components/table/TableInfo";

const InventoryBySku = () => {
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);

  //for fetching inventorystock
  const [quantitySignature, setQuantitySignature] = useState<any[]>([]);
  const allowWarehouseIdList = useLoginAccountStore((state) =>
    state.getRoleObjectList(Role.INVENTORY_NUMBER)
  );
  const [warehouseItems] = useDatabaseTable<WarehouseJson>("warehouse");
  const [stockType, setStockType] =
    useState<InventoryStockNumberType>("available");
  const lastInventoryStockResultRef = useRef<InventoryStockModel[]>([]);

  //////////////////////////////////////////
  //Filtering
  const [categoryItems] =
    useDatabaseTable<ProductCategoryJson>("productcategory");

  const defaultFilters: FilterProductVariantInMemory = useMemo(
    () => ProductVariantRepository.getDefaultInMemoryFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterProductVariantInMemory>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<ProductVariantInMemoryModel[]>(
    []
  );
  const fetchData =
    useCallback(async (): Promise<ProductVariantInMemoryCollection> => {
      let collection = await new ProductVariantRepository().getInMemoryItems({
        filters,
      });

      //find the stockresult from previous fetch
      const newItems = collection.items.map((item) => {
        const foundIndex = findIndex(lastInventoryStockResultRef.current, {
          product_variant_id: item.id,
        });

        return new ProductVariantInMemoryModel({
          ...item.toJson(),
          inventory_details:
            foundIndex >= 0
              ? lastInventoryStockResultRef.current[foundIndex].stocks
              : [],
        });
      });
      setDataSource(newItems);
      setTotal(collection.total);

      return collection;
    }, [filters]);

  const updateItemWithInventoryStock = useCallback(
    (results: InventoryStockModel[]) => {
      //store for re-use if not change signature ^^!
      lastInventoryStockResultRef.current = results;

      if (dataSource.length > 0 && results.length > 0) {
        const newItems = dataSource.map((item) => {
          const foundIndex = findIndex(results, {
            product_variant_id: item.id,
          });

          return new ProductVariantInMemoryModel({
            ...item.toJson(),
            inventory_details:
              foundIndex >= 0 ? results[foundIndex].stocks : [],
          });
        });

        //VERY IMPORTANT, to prevent update
        if (!isEqual(newItems, dataSource)) {
          setDataSource(newItems);
        }
      }
    },
    [dataSource]
  );

  //change quantity signature
  //to prepare for update inventory check
  useEffect(() => {
    setQuantitySignature(
      dataSource.map((item) => {
        return {
          product_variant_id: item.id,
        };
      })
    );
  }, [dataSource]);

  //check inventory when quantitysignature change
  useDeepCompareEffect(() => {
    const idList = quantitySignature.map((item) => item.product_variant_id);

    if (idList.length > 0) {
      (async () => {
        const collection =
          await new InventoryStockRepository().getListProductVariantQuantity(
            idList.join(","),
            "inventory"
          );

        if (!collection.hasError()) {
          updateItemWithInventoryStock(collection.items);
        }
      })();
    }
  }, [quantitySignature]);

  //Table columns
  const columns: TableColumnsType<ProductVariantInMemoryModel> = [
    {
      title: t("product:category_id"),
      key: "category_id",
      width: 100,
      fixed: "left",
      render: (category_id: number) => {
        if (category_id > 0) {
          const foundCategory = categoryItems.find((i) => i.id === category_id);
          if (typeof foundCategory !== "undefined") {
            return (
              <Tooltip
                title={t("inventory:filter_by_this_category")}
                mouseEnterDelay={0.4}
                placement="topLeft"
              >
                <>
                  <TableLinkText
                    onClick={() =>
                      setFilters({
                        ...filters,
                        category_id: category_id,
                        page: 1,
                      })
                    }
                  >
                    {foundCategory.name}
                  </TableLinkText>
                </>
              </Tooltip>
            );
          } else {
            return "ID #" + category_id;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: t("product:name"),
      key: "product_name",
      width: 200,
      fixed: "left",
    },
    {
      title: t("product:code"),
      key: "product_code",
      width: 100,
      fixed: "left",
    },
    {
      title: t("productvariant:sku"),
      key: "sku",
      width: 100,
      fixed: "left",
      render: (sku: string, record) => {
        return (
          <>
            <>
              {record.sell_on_zero === 1 ? (
                <div className="float-right">
                  <Tooltip
                    title={t("product:sell_on_zero_tag")}
                    mouseEnterDelay={0.4}
                  >
                    <IconLockOpen
                      size={18}
                      className="text-green-500 -mt-0.5"
                    />
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </>

            <div>{sku}</div>
          </>
        );
      },
    },
    {
      title: t("productvariant:size"),
      key: "size",
      width: 100,
      fixed: "left",
      render: (size: number) => {
        if (size > 0) {
          return <TextProductVariantSize id={size} />;
        } else {
          return "-";
        }
      },
    },
    {
      title: t("productvariant:color"),
      key: "color",
      width: 100,
      fixed: "left",
      render: (color: number) => {
        if (color > 0) {
          return <TextProductVariantColor id={color} />;
        } else {
          return "-";
        }
      },
    },

    ...warehouseItems
      .filter(
        (item) =>
          allowWarehouseIdList.length === 0 ||
          allowWarehouseIdList.includes(item.id)
      )
      .map((item) => {
        return {
          title: <div className="text-center text-blue-900">{item.name}</div>,
          key: "",
          className: "bg-gray-50",
          width: 100,
          toggletype: "ignore" as TableColumnToggleType,
          render: (_: any, record: ProductVariantInMemoryModel) => {
            return (
              <div className="text-center">
                <InventoryBySkuCell
                  type={stockType}
                  data={record}
                  activeWarehouseId={item.id}
                />
              </div>
            );
          },
        };
      }),

    {
      title: " ",
      toggletype: "trigger",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 40,
      render: (id: number, record) => {
        return (
          <>
            <TableInfo record={record}></TableInfo>
          </>
        );
      },
    },
  ];

  return (
    <>
      <InventoryBySkuHeader />

      <InventoryBySkuFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
        stockType={stockType}
        setStockType={setStockType}
      />

      <PageDataTable<
        FilterProductVariantInMemory,
        ProductVariantInMemoryModel,
        ProductVariantInMemoryCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "inventorybysku",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default InventoryBySku;
