import { GoongMapCoordinates } from 'common/types/goongmap/GoongMapType';
import eventEmitter from 'common/utils/eventEmitter';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import ReactMapGL, {
    FlyToInterpolator, LinearInterpolator, MapEvent, WebMercatorViewport
} from '@goongmaps/goong-map-react';
import bbox from '@turf/bbox';

import GoongMapControlPanel from './GoongMapControlPanel';

const GoongMapWrapper = ({
  goongApiAccessToken,
  children,
  latLng,
}: {
  goongApiAccessToken: string;
  children?: ReactNode;
  latLng: GoongMapCoordinates;
}) => {
  const eventEmitterRef = useRef<any>();

  const [viewport, setViewport] = useState<any>({
    zoom: 12,
    latitude: latLng.latitude,
    longitude: latLng.longitude,
  });

  // View marker by Viewport
  const onFlyToInterpolator = useCallback(
    (coordinates: GoongMapCoordinates) => {
      if (coordinates.latitude > 0 && coordinates.longitude > 0) {
        setViewport({
          ...viewport,
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          zoom: 15,
          transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
          transitionDuration: 1200,
        });
      }
    },
    [viewport]
  );

  // handle event: Zoom To Bounds
  const onClick = (event: MapEvent) => {
    if (event.features) {
      const feature = event.features[0];
      if (feature) {
        // calculate the bounding box of the feature
        const [minLng, minLat, maxLng, maxLat] = bbox(feature);
        // construct a viewport instance from the current state
        const vp = new WebMercatorViewport(viewport);
        const { longitude, latitude } = vp.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: 40,
          }
        );
        setViewport({
          ...viewport,
          longitude,
          latitude,
          zoom: 17,
          transitionInterpolator: new LinearInterpolator({
            around: [event.offsetCenter.x, event.offsetCenter.y],
          }),
          transitionDuration: 1200,
        });
      }
    }
  };

  useEffect(() => {
    if (!eventEmitterRef.current) {
      eventEmitterRef.current = eventEmitter.addListener(
        "DASHBOARD_GOONGMAP_GOTOMARKER",
        (_data: any) => {
          onFlyToInterpolator({
            latitude: _data.latitude,
            longitude: _data.longitude,
          });
        }
      );
    }
  }, [onFlyToInterpolator]);

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);

  return (
    <ReactMapGL
      {...viewport}
      onViewportChange={setViewport}
      width="100%"
      height="100%"
      goongApiAccessToken={goongApiAccessToken}
      mapStyle="https://tiles.goong.io/assets/goong_map_dark.json"
      onClick={onClick}
    >
      {children}
      <GoongMapControlPanel />
    </ReactMapGL>
  );
};

export default GoongMapWrapper;
