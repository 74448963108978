import { Button, Col, Pagination, Row, Spin, Table } from "antd";
import BaseModel from "common/models/BaseModel";
import { Filter } from "common/types/Filter";
import {
  TableExpandableConfig,
  TableFilters,
  TablePaginationConfig,
  TableSorter,
} from "common/types/Table";
import ReportUtil from "common/utils/report";
import { diff } from "deep-object-diff";
import useBreakpoint from "hooks/useBreakpoint";
import { mapValues, omit } from "lodash";
import { RowClassName } from "rc-table/lib/interface";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconX } from "@tabler/icons";
import { MenuOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { arrayMoveImmutable } from "array-move";
import type { SortableContainerProps, SortEnd } from "react-sortable-hoc";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import BaseCollection from "common/collections/BaseCollection";

interface DataType extends BaseModel {
  key: string;
}

////////////////////
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);

////////////////////

const PageDataDragTableView = <
  F extends Filter,
  M extends BaseModel,
  C extends BaseCollection<any, any, any>
>({
  total,
  dataSource,
  loading,
  filters,
  setFilters,
  defaultFilters,
  columns,
  handleTableChange,
  children,
  expandable,
  rowClassName,
  setDataSource,
  handleSortChange,
  onRow,
}: {
  total: number;
  dataSource: any[];
  loading: boolean;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  columns: any[];
  handleTableChange: (f: TableFilters, s: TableSorter<M>) => any;
  children: React.ReactNode;
  expandable?: TableExpandableConfig<M>;
  rowClassName?: RowClassName<M>;
  setDataSource: React.Dispatch<React.SetStateAction<any[]>>;
  handleSortChange: ({
    old_index,
    new_index,
    id,
  }: {
    old_index: number;
    new_index: number;
    id: number;
  }) => Promise<void>;
  onRow?: any;
}) => {
  const { t } = useTranslation();
  const [isSmall] = useBreakpoint();
  //
  const [newColumns, setNewColumns] = React.useState<any[]>(columns);

  React.useEffect(() => {
    let arr = columns;

    arr.unshift({
      title: "Sort",
      dataIndex: "sort",
      width: 50,
      className: "drag-visible",
      render: () => <DragHandle />,
    });

    setNewColumns(arr);
  }, [columns]);
  ////////////////////////////////////////////
  //Compare fitlers with default filters to detect isfilerting or not
  const ignoreCheckFiltering = ["page", "limit", "sortby", "sorttype"];
  const refinedFilters = mapValues(
    omit(filters, ignoreCheckFiltering),
    (v) => v + ""
  );
  const refinedDefaultFilters = mapValues(
    omit(defaultFilters, ignoreCheckFiltering),
    (v) => v + ""
  );
  const isFiltering =
    Object.keys(diff(refinedDefaultFilters, refinedFilters)).length > 0;
  //end check isFiltering
  ////////////////////////////////////////

  const onChangeTable = (
    _: TablePaginationConfig,
    tablefilters: TableFilters,
    sorter: TableSorter<M>
  ) => {
    handleTableChange(tablefilters, sorter);
  };

  //detect scrollinfo if found "fixed" column(s)
  const scrollInfo =
    typeof columns.find(
      (c) => c.hasOwnProperty("fixed") && c.fixed.length > 0
    ) !== "undefined"
      ? { x: 800 }
      : {};
  //
  // asdf?

  const switchValueFromArray = (
    arr: any[],
    oldIndex: number,
    newIndex: number,
    key: any
  ) => {
    let newArr = [...arr];
    if (newArr.length > 0) {
      const oldValue = arr[oldIndex][key];
      const newValue = arr[newIndex][key];
      newArr[oldIndex][key] = newValue;
      newArr[newIndex][key] = oldValue;
      return newArr;
    }
    return new Error("cos looix");
  };

  function swapArrayItemsByKeyAndIndex(
    array: any[],
    oldIndex: number,
    newIndex: number,
    key: any
  ) {
    // lấy giá trị của thuộc tính key tại oldIndex
    if (array.length > 0) {
      const oldValue = array[oldIndex][key];

      // lấy giá trị của thuộc tính key tại newIndex
      const newValue = array[newIndex][key];

      // hoán đổi giá trị của thuộc tính key giữa oldIndex và newIndex
      array[oldIndex][key] = newValue;
      array[newIndex][key] = oldValue;

      // trả về mảng sau khi đã hoán đổi
      return array;
    }
    return [];
  }

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    let arrID = dataSource.map((i: any) => {
      return Number(i.key);
    });
    arrID.sort((a, b) => a - b);
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        // swapArrayItemsByKeyAndIndex(
        //   dataSource,
        //   oldIndex,
        //   newIndex,
        //   "display_order"
        // )
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el: DataType) => !!el);

      handleSortChange({
        old_index: dataSource[oldIndex].display_order,
        new_index: dataSource[newIndex].display_order,
        id: dataSource[oldIndex].id,
      });

      let newArr: any = [];
      newArr = newData.map((i: any, index: number) => {
        return {
          old_index: i.key,
          new_index: i.key,
        };
      });

      setDataSource(newData);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  // const DraggableBodyRow: React.FC<any> = useCallback(
  //   ({ className, style, ...restProps }) => {
  //     const index = dataSource.findIndex(
  //       (x) => x.index === restProps["data-row-key"]
  //     );
  //     return <SortableItem index={index} {...restProps} />;
  //   },
  //   [dataSource]
  // );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };
  // const memozied = useMemo(() => {
  //   return (
  //     <Table<M>
  //       loading={loading}
  //       rowKey="index"
  //       size="small"
  //       sticky={{ offsetHeader: isSmall ? -2 : 59 }}
  //       scroll={scrollInfo}
  //       pagination={{
  //         pageSize: +filters.limit,
  //         hideOnSinglePage: true,
  //       }}
  //       rowClassName={rowClassName}
  //       columns={newColumns}
  //       dataSource={dataSource}
  //       onChange={onChangeTable}
  //       components={{
  //         body: {
  //           wrapper: DraggableContainer,
  //           row: DraggableBodyRow,
  //         },
  //       }}
  //       expandable={expandable}
  //       footer={() => {
  //         return filters.limit > 0 ? (
  //           <Pagination
  //             className="text-right"
  //             current={+filters.page}
  //             pageSize={+filters.limit}
  //             total={total}
  //             hideOnSinglePage={false}
  //             showQuickJumper={true}
  //             onChange={(newpage, pageSize) =>
  //               setFilters({ ...filters, page: newpage, limit: pageSize })
  //             }
  //             pageSizeOptions={["10", "20", "50"]}
  //             showSizeChanger={true}
  //           />
  //         ) : null;
  //       }}
  //     />
  //   );
  // }, [dataSource]);

  return (
    <div className="p-6">
      <Row gutter={8} className="mb-4" justify="space-between">
        <Col>
          <div style={{ lineHeight: 2.2 }}>
            {loading ? (
              <>{t("common:table.fetching")}</>
            ) : (
              <>
                {total > 0
                  ? t("common:table.result", {
                      from: ReportUtil.numberFormatter(
                        1 + (+filters.page - 1) * +filters.limit
                      ),
                      to: ReportUtil.numberFormatter(
                        dataSource.length + (+filters.page - 1) * +filters.limit
                      ),
                      total: ReportUtil.numberFormatter(total),
                    })
                  : t("common:table.empty_result")}
              </>
            )}
          </div>
        </Col>
        {/* {memozied} */}
        <Table<M>
          loading={loading}
          rowKey="index"
          size="small"
          sticky={{ offsetHeader: isSmall ? -2 : 59 }}
          scroll={scrollInfo}
          pagination={{
            pageSize: +filters.limit,
            hideOnSinglePage: true,
          }}
          rowClassName={rowClassName}
          columns={newColumns}
          dataSource={dataSource}
          onChange={onChangeTable}
          onRow={onRow}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          expandable={expandable}
          footer={() => {
            return Number(filters.limit) > 0 ? (
              <Pagination
                className="text-right"
                current={+filters.page}
                pageSize={+filters.limit}
                total={total}
                hideOnSinglePage={false}
                showQuickJumper={true}
                onChange={(newpage, pageSize) =>
                  setFilters({ ...filters, page: newpage, limit: pageSize })
                }
                pageSizeOptions={["10", "20", "50"]}
                showSizeChanger={true}
              />
            ) : null;
          }}
        />
        {/* <Col>
          <Row>
            {isFiltering ? (
              <Col>
                <Button
                  type="link"
                  danger
                  icon={<IconX size="16" className="mr-1 -mt-0.5" />}
                  onClick={() => setFilters(defaultFilters)}
                >
                  {t("common:filter.reset")}
                </Button>
              </Col>
            ) : null}
            <Col>{children}</Col>
          </Row>
        </Col> */}
      </Row>
    </div>
  );
};

export default PageDataDragTableView;
