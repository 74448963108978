import { Alert, App, Button, Col, Empty, Popover, Row, Table, Tag } from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import PurchaseOrder from "common/constants/PurchaseOrder";
import Role from "common/constants/Role";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import ProductReceiptRepository from "common/repositories/ProductReceiptRepository";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import ErrorAlert from "components/ErrorAlert";
import RoleCheck from "components/RoleCheck";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextWarehouse from "components/TextWarehouse";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

import { IconLink, IconRefresh, IconSquarePlus } from "@tabler/icons-react";

import PurchaseOrderFormSectionProductReceiptLinkForm from "./PurchaseOrderFormSectionProductReceiptLinkForm";

const PurchaseOrderFormSectionProductReceipt = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();

  //limit select warehouse option
  const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
    "warehouse",
    Role.INVENTORY_ADD
  );

  const purchaseOrder = useEditingPurchaseOrderStore(
    (state) => state.purchaseOrder
  );
  const [dataSource, setDataSource] = useState<ProductReceiptModel[]>([]);
  const [warehouseId, setWarehouseId] = useState(0);

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  //get productreceipt of current order
  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrors([]);

    //First, fetch product receipt
    let collection = await new ProductReceiptRepository().getItems({
      filters: {
        ...ProductReceiptRepository.getDefaultFilters(),
        purchase_order_id: purchaseOrder.id
      }
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      //Everything is OK, set data for display
      setDataSource(collection.items);
      setLoading(false);
    }
  }, [purchaseOrder.id]);

  const initFirstProductReceipt = useCallback(async () => {
    //check status before init
    if (purchaseOrder.status === PurchaseOrder.STATUS_APPROVED) {
      setProcessing(true);
      setErrors([]);

      const newProductReceipt =
        await new PurchaseOrderHelperRepository().initProductReceipt({
          warehouse_id: warehouseId,
          purchase_order_id: purchaseOrder.id
        });

      setProcessing(false);

      if (newProductReceipt.hasError()) {
        setErrors(newProductReceipt.error.errors);
      } else {
        //success, reload
        fetchData();
      }
    } else {
      message.error(
        t(
          "purchaseorder:form.error.error_init_first_product_receipt_status_approved_required"
        )
      );
    }
  }, [purchaseOrder, warehouseId, t, fetchData, message]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //Table columns
  const columns: TableColumnsType<ProductReceiptModel> = [
    {
      title: t("productreceipt:id"),
      dataIndex: "id",
      align: "center",
      width: 120,
      render: (_, record) => {
        return (
          <>
            <TableLinkText
              link={"/productreceipt/edit/id/" + record.id}
              target="_blank"
              className="font-bold text-md">
              {record.id}
            </TableLinkText>
          </>
        );
      }
    },
    {
      title: t("productreceipt:warehouse_id"),
      dataIndex: "warehouse_id",
      width: 150,
      render: (warehouse_id: number) => {
        return <TextWarehouse id={warehouse_id} />;
      }
    },

    {
      title: t("common:status"),
      dataIndex: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = ProductReceiptModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 120
    },
    {
      title: t("common:date_created"),
      dataIndex: "date_created",
      width: 120,
      render: (ts: number) => <TextDateTime format="HH:mm, DD/MM" ts={ts} />
    }
  ];

  const moreButtons = (
    <div className="my-2">
      <Button
        onClick={() => fetchData()}
        icon={<IconRefresh size={16} className="mr-1" />}
        className="opacity-40 hover:opacity-80"
        size="small"
        type="text">
        {t("purchaseorder:form.refresh_receipt")}
      </Button>

      {dataSource.length > 0 ? (
        <Link
          to={
            "/productreceipt/add/direction/" +
            ProductReceipt.DIRECTION_INPUT +
            "/purchase_order_id/" +
            purchaseOrder.id
          }
          target="_blank">
          <Button
            icon={<IconSquarePlus size={16} className="mr-1 -mt-0.5" />}
            className="opacity-40 hover:opacity-80"
            size="small"
            type="text">
            {t("purchaseorder:form.add_more_receipt")}
          </Button>
        </Link>
      ) : null}

      <Popover
        title={t("purchaseorder:form.link_receipt.title")}
        content={
          <PurchaseOrderFormSectionProductReceiptLinkForm
            onSuccess={() => fetchData()}
          />
        }
        placement="rightTop"
        trigger={["click"]}>
        <Button
          icon={<IconLink size={16} className="mr-1 -mt-0.5" />}
          className="opacity-40 hover:opacity-80"
          size="small"
          type="text">
          {t("purchaseorder:form.link_receipt.button")}
        </Button>
      </Popover>
    </div>
  );

  return (
    <div className="mt-8">
      <Row className="p-2 bg-gray-100">
        <Col
          span={3}
          className="px-2 text-xl font-extrabold text-right text-gray-600 uppercase bg-gray-100">
          {t("purchaseorder:form.section_receiptprefix")}
          <br />
          {t("purchaseorder:form.section_productreceipt")}
        </Col>
        <Col span={21}>
          {errors.length > 0 ? (
            <div className="p-2">
              <ErrorAlert
                items={errors}
                translate_prefix="purchaseorder:form.error"
              />
            </div>
          ) : null}

          {!loading && dataSource.length === 0 ? (
            <div className="p-4 m-2 bg-white rounded-md">
              <Empty
                imageStyle={{ height: 40 }}
                description={
                  <div className="">
                    {t("purchaseorder:form.product_receipt_empty")}
                  </div>
                }>
                <Popover
                  title={t("purchaseorder:form.init_product_receipt.heading")}
                  content={
                    <>
                      <RoleCheck
                        roles={[Role.INVENTORY_ADD]}
                        componentOnFail={
                          <Alert
                            type="warning"
                            showIcon
                            message={t(
                              "purchaseorder:form.product_receipt_not_add_permission"
                            )}></Alert>
                        }>
                        <>
                          <WarehouseFormSelect
                            label=""
                            name="warehouse_id"
                            className="mb-2"
                            placeholder={t(
                              "purchaseorder:form.init_product_receipt.select_warehouse"
                            )}
                            initOptions={warehouseItemsWithLimit.map((i) => ({
                              value: i.id,
                              label: i.name
                            }))}
                            onChangeSelect={(value) =>
                              setWarehouseId(
                                typeof value !== "undefined" ? +value : 0
                              )
                            }
                          />
                          <Button
                            type="primary"
                            block
                            loading={processing}
                            onClick={() => initFirstProductReceipt()}>
                            {t(
                              "purchaseorder:form.init_product_receipt.submit"
                            )}
                          </Button>
                        </>
                      </RoleCheck>
                    </>
                  }
                  placement="rightTop"
                  trigger={["click"]}>
                  <Button type="primary" ghost loading={processing}>
                    {t("purchaseorder:form.init_product_receipt.button")}
                  </Button>
                </Popover>
                <div className="mt-4">{moreButtons}</div>
              </Empty>
            </div>
          ) : null}

          <div className={!loading && dataSource.length > 0 ? "" : "hidden"}>
            <Table<ProductReceiptModel>
              className="border"
              rowKey={"id"}
              pagination={{
                pageSize: 100,
                hideOnSinglePage: true
              }}
              columns={columns}
              dataSource={dataSource}
              loading={loading}
            />

            {moreButtons}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseOrderFormSectionProductReceipt;
