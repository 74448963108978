import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import ChevronRightIcon from "features/academy/icon/ChevronRightIcon";
import useCollectionStore from "zustands/useCollectionStore";
import usePathParams from "hooks/usePathParams";
import "./academyBreadcrumb.css";

type Category = {
  id: number;
  title: string;
  sub_categories: Category[];
  parent_id: number;
  description: string;
  detail: string;
  handle: string;
};

const AcademyBreadcrumb = () => {
  const [pathParams] = usePathParams();
  const firstHandle = pathParams.action.toString();

  //state
  const [openItems, setOpenItems] = useState<Category[]>([]);
  const [parentItems, setParentItems] = useState<number[]>([]);

  const Categories = useCollectionStore((state) => state.categories);

  function findItemAndParents(items: Category[], idToFind: string) {
    let foundParents: number[] = [];

    function findItemRecursively(items: Category[], idToFind: string) {
      for (const item of items) {
        if (item.handle === idToFind) {
          foundParents = [item.parent_id, ...foundParents];
          setParentItems((prev) => [item.parent_id, ...prev]);
          setOpenItems((prev) => [item, ...prev]);
          return item;
        }

        if (item.sub_categories.length > 0) {
          if (findItemRecursively(item.sub_categories, idToFind)) {
            foundParents = [item.parent_id, ...foundParents];
            setParentItems((prev) => [item.parent_id, ...prev]);
            setOpenItems((prev) => [item, ...prev]);
            return item;
          }
        }
      }

      return null;
    }

    const foundItem = findItemRecursively(items, idToFind);

    return { foundItem, foundParents };
  }

  useEffect(() => {
    setOpenItems([]);
    setParentItems([]);
    const { foundItem, foundParents } = findItemAndParents(
      Categories,
      firstHandle
    );

    if (foundItem) {
      // console.log("Mục tìm thấy:", foundItem);
      // console.log("Các mục cha/ông:", foundParents);
    } else {
      // console.log(`Không tìm thấy mục có handle ${firstHandle}`);
    }
  }, [firstHandle]);

  return (
    <div className="academy-breadcrumb">
      {/* {loading ? (
        <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
          <div className="flex items-center gap-1">
            <div className="w-12 h-2 bg-gray-300 rounded-md dark:bg-gray-700"></div>
            <div className="w-16 h-2 bg-gray-200 rounded-md dark:bg-gray-700"></div>
            <div className="w-20 h-2 bg-gray-300 rounded-md dark:bg-gray-700"></div>
          </div>
        </div>
      ) : ( */}
      <Breadcrumb
        separator={<ChevronRightIcon size="small" color="gray" />}
        style={{
          height: "14px",
          lineHeight: "14px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {openItems.map((item) => (
          <Breadcrumb.Item href={`/#/academy/${item.handle}`}>
            <span
              style={{
                color: "#898889",
                fontSize: "10px",
                fontWeight: 500,
                height: "14px",
                lineHeight: "14px",
              }}
            >
              {item.title}
            </span>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      {/* )} */}
    </div>
  );
};

export default AcademyBreadcrumb;
