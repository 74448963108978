import ShippingHubModel from "common/models/ShippingHubModel";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import create, { StateCreator } from "zustand";

type DeliveryDashboardStore = {
  height: number;
  width: number;
  activeRoute?: ShippingRouteModel;
  orderItemsByActiveRoute: ShippingOrderModel[];
  hubItemByActiveRoute: ShippingHubModel;
  setWidthHeight: (width: number, height: number) => void;
  setActiveRoute: (v: ShippingRouteModel) => void;
  setOrderItems: (items: ShippingOrderModel[]) => void;
  setOrderItemsByRouteId: (id: number, items: ShippingOrderModel[]) => void;
  setHubItemById: (id: number, items: ShippingHubModel[]) => void;
};

//init store data
let store: StateCreator<DeliveryDashboardStore> = (set) => ({
  height: 0,
  width: 0,
  setWidthHeight: (width, height) =>
    set((state) => ({ ...state, width, height })),
  activeRoute: undefined,
  orderItemsByActiveRoute: [],
  hubItemByActiveRoute: new ShippingHubModel(ShippingHubModel.getDefaultData()),
  setActiveRoute: (activeRoute) => set((state) => ({ ...state, activeRoute })),
  setOrderItems: (orderItemsByActiveRoute) =>
    set((state) => ({ ...state, orderItemsByActiveRoute })),
  setOrderItemsByRouteId: (id: number, items: ShippingOrderModel[]) => {
    set((state) => ({
      ...state,
      orderItemsByActiveRoute: items.filter((i) => i.route_id === id),
    }));
  },
  setHubItemById: (id: number, items: ShippingHubModel[]) => {
    set((state) => ({
      ...state,
      hubItemByActiveRoute: items.find((i) => i.id === id),
    }));
  },
});

//create store
const useDeliveryDashboardStore = create(store);

export default useDeliveryDashboardStore;
