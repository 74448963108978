import { Form } from "antd";
import LeaveModel from "common/models/LeaveModel";
import { Filter } from "common/types/Filter";
import { SelectOption } from "common/types/SelectOption";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs, { Dayjs } from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const LeaveListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
  leaveTypeItems,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  leaveTypeItems: SelectOption[];
}) => {
  const { t } = useTranslation();

  // FILTER: DATESTARTED AND DATEENDED
  const getDateCreatedFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "date_from" || key === "date_to")
      .map(([key, val]) => {
        return val;
      }) as number[];
    return (
      +entries[0] > 0 && +entries[1] > 0
        ? [
            dayjs(dayjs.unix(entries[0]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
            dayjs(dayjs.unix(entries[1]).format("DD/MM/YYYY"), "DD/MM/YYYY"),
          ] || null
        : [null, null] || null
    ) as RangeValue;
  }, [filters]);
  const onChangeDateCreatedFilter: RangePickerProps["onChange"] = (
    value: RangePickerProps["value"],
    dateString: [string, string]
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setFilters({
        ...filters,
        date_from: +dayjs(
          "00:00, " + value[0]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix(),
        date_to: +dayjs(
          "23:59, " + value[1]?.format("DD/MM").toString(),
          "HH:mm, DDMM"
        ).unix(),
      });
    } else {
      setFilters({
        ...filters,
        date_from: -1,
        date_to: -1,
      });
    }
  };

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[
          "leavetype_id",
          "date_from",
          "date_to",
          "status",
        ]}
      >
        <FormSelect
          name="leavetype_id"
          placeholder={t("leave:filter.choose_leave_type")}
          options={leaveTypeItems}
          allowClear
          popupMatchSelectWidth={false}
        />

        <FormSelect
          name="status"
          placeholder={t("common:status")}
          options={LeaveModel.getLeaveStatus()}
          allowClear
          popupMatchSelectWidth={false}
        />

        {/* <Form.Item
            className="lg:w-[400px] gap-1 "
            // label={t("common:date_created")}
          >
            <DateFormFilter
              rangeValue={getDateCreatedFilter}
              allowClear={true}
              showNow={false}
              mode="range"
              format="DD/MM/YYYY"
              onRangeChange={onChangeDateCreatedFilter}
            />
          </Form.Item> */}
        <Form.Item name="">
          <DatePickerRangeFilter
            filters={filters}
            setFilters={setFilters}
            keyEnd="date_to"
            keyStart="date_from"
          ></DatePickerRangeFilter>
        </Form.Item>
      </PageDataFilterForm>
    </>
  );
};

export default LeaveListFilter;
