import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomerPointListHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const nav: Nav = [
    {
      text: t("customer:heading_nav"),
      link: "/customer",
    },
  ];

  return (
    <PageHeader
      heading={t("customerpoint:heading_list")}
      nav={nav}
      siteMenuOpenKey="sale"
      siteMenuSelectedKey="/customer"
    >
      {children}
    </PageHeader>
  );
};

export default CustomerPointListHeader;
