const EventHistoryConstant = {
	SOURE_TYPE: {
		SOURCE_SALE_ORDER: 1,
		SOURCE_CASHFLOW_RECEIPT: 2,
		SOURCE_INVENTORY_RECEIPT: 3,
		SOURCE_OTHER: 4,
	},
};

export default EventHistoryConstant;
