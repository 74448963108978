import BaseRepository from "./BaseRepository";
import BiCenterApi from "common/api/BiCenterApi";
import BiCenterCollection from "common/collections/BiCenterCollection";
import type { DataQuery, FilterBiCenter } from "common/types/BiCenter";

class BiCenterRepository extends BaseRepository<BiCenterApi> {
  _api: BiCenterApi;

  constructor() {
    super();
    this._api = new BiCenterApi(true);
  }

  async queryRemote(data: DataQuery): Promise<BiCenterCollection> {
    return await this._api.query(data);
  }

  static getDefaultFilters(): FilterBiCenter {
    return {
      page: 1,
      sortby: "",
      sorttype: "",
      status: -1,
      keyword: "",
      service: "",
      start: "",
      end: "",
      startcompare: "",
      endcompare: "",
      table: "",
      join: "",
      select: "",
      filter: "",
      groupby: "",
      orderby: "",
      limit: "",
      offset: "",
      timeserie: "",
    };
  }
}

export default BiCenterRepository;
