import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShippingCarrierListHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("shippingcarrier:heading_list")}
      siteMenuSelectedKey="/shippingcarrier"
      siteMenuOpenKey="setting_sale"
    >
      {children}
    </PageHeader>
  );
};

export default ShippingCarrierListHeader;
