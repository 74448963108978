import BaseCollection from "common/collections/BaseCollection";
import FullDataModel from "common/models/FullDataModel";
import { FullDataJson, FullDataCollectionJson } from "common/types/FullData";

class FullDataCollection extends BaseCollection<
  FullDataJson,
  FullDataModel,
  FullDataCollectionJson
> {
  itemsFromJson(jsonItems: FullDataJson[]): FullDataModel[] {
    return jsonItems.map((item) => new FullDataModel(item));
  }
}

export default FullDataCollection;
