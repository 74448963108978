import BaseCollection from "common/collections/BaseCollection";
import IdFetcherModel from "common/models/IdFetcherModel";
import { IdFetcherJson, IdFetcherCollectionJson } from "common/types/IdFetcher";

class IdFetcherCollection extends BaseCollection<
  IdFetcherJson,
  IdFetcherModel,
  IdFetcherCollectionJson
> {
  itemsFromJson(jsonItems: IdFetcherJson[]): IdFetcherModel[] {
    return jsonItems.map((item) => new IdFetcherModel(item));
  }
}

export default IdFetcherCollection;
