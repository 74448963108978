import { Form, Radio } from "antd";
import ProductInventoryChecking from "common/constants/ProductInventoryChecking";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type StatusOption = {
	value: number;
	disabled: boolean;
	label: React.ReactNode;
};
const ProductInventoryCheckingFormStatus = ({
	status,
	isDisable,
	isedit,
	role,
}: {
	isDisable: boolean;
	isedit: boolean;
	status: number;
	role: "manager" | "employee";
}) => {
	const { t } = useTranslation();

	const checkDisabled = useCallback(
		(s: number) => {
			if (isDisable) return true;

			switch (role) {
				case "manager":
					return false;
				case "employee":
					const statusConditions = {
						[ProductInventoryChecking.STATUS_PENDING]: () =>
							status === ProductInventoryChecking.STATUS_COMPLETED ||
							status === ProductInventoryChecking.STATUS_PROCESSING ||
							status === ProductInventoryChecking.STATUS_CANCELLED,

						[ProductInventoryChecking.STATUS_PROCESSING]: () =>
							status === ProductInventoryChecking.STATUS_COMPLETED ||
							status === ProductInventoryChecking.STATUS_PROCESSING ||
							status === ProductInventoryChecking.STATUS_CANCELLED,
						[ProductInventoryChecking.STATUS_REJECT]: () => true,
						[ProductInventoryChecking.STATUS_COMPLETED]: () =>
							status >= ProductInventoryChecking.STATUS_COMPLETED || !isedit,

						[ProductInventoryChecking.STATUS_CANCELLED]: () =>
							status !== ProductInventoryChecking.STATUS_PENDING || !isedit,
					};
					return statusConditions[status]?.() || false;
				default:
					break;
			}
		},
		[status, isDisable, isedit]
	);
	//////////////////////////////

	const statusList: StatusOption[] = useMemo(() => {
		const allStatus = [
			ProductInventoryChecking.STATUS_PENDING,
			ProductInventoryChecking.STATUS_PROCESSING,
			ProductInventoryChecking.STATUS_REJECT,
			// ProductInventoryChecking.STATUS_APPROVED,
			ProductInventoryChecking.STATUS_COMPLETED,
			ProductInventoryChecking.STATUS_CANCELLED,
		];

		switch (role) {
			case "manager":
				return allStatus.map((status) => {
					return {
						disabled: checkDisabled(status),
						label: (
							<>{ProductInventoryCheckingModel.getStatus(status)?.label}</>
						),
						value: status,
					};
				}) as StatusOption[];
			case "employee":
				return (
					allStatus
						// .filter((i) => ![ProductInventoryChecking.STATUS_REJECT].includes(i))
						.map((status) => {
							return {
								disabled: checkDisabled(status),
								label: (
									<>{ProductInventoryCheckingModel.getStatus(status)?.label}</>
								),
								value: status,
							};
						}) as StatusOption[]
				);
			default:
				return [];
		}
	}, [role, checkDisabled, status]);

	return (
		<Form.Item label={t("common:status")} className="mb-0" name="status">
			<Radio.Group buttonStyle="solid" value={status} defaultValue={status}>
				{statusList.map((i) => {
					return (
						<Radio.Button key={i.value} disabled={i.disabled} value={i.value}>
							{i.label}
						</Radio.Button>
					);
				})}
				{/* {isedit ? (
					<Radio.Button
						disabled={
							isDisable ||
							status === ProductInventoryChecking.STATUS_COMPLETED ||
							status === ProductInventoryChecking.STATUS_CANCELLED
						}
						value={ProductInventoryChecking.STATUS_REJECT}>
						{t("Chưa chấp nhận")}
					</Radio.Button>
				) : (
					<Radio.Button
						disabled={
							isDisable ||
							status === ProductInventoryChecking.STATUS_COMPLETED ||
							status === ProductInventoryChecking.STATUS_CANCELLED
						}
						value={ProductInventoryChecking.STATUS_PENDING}>
						{t("productinventorychecking:STATUS_PENDING")}
					</Radio.Button>
				)}

				<Radio.Button
					disabled={
						isDisable ||
						status === ProductInventoryChecking.STATUS_COMPLETED ||
						status === ProductInventoryChecking.STATUS_CANCELLED
					}
					value={ProductInventoryChecking.STATUS_PROCESSING}>
					{t("productinventorychecking:STATUS_PROCESSING")}
				</Radio.Button>
				<Radio.Button
					value={ProductInventoryChecking.STATUS_COMPLETED}
					disabled={
						isDisable ||
						status >= ProductInventoryChecking.STATUS_COMPLETED ||
						!isedit
					}>
					{t("productinventorychecking:STATUS_COMPLETED")}
				</Radio.Button>
				<Radio.Button
					value={ProductInventoryChecking.STATUS_CANCELLED}
					disabled={
						isDisable ||
						status === ProductInventoryChecking.STATUS_COMPLETED ||
						!isedit
					}>
					{t("productinventorychecking:STATUS_CANCELLED")}
				</Radio.Button> */}
			</Radio.Group>
		</Form.Item>
	);
};

export default ProductInventoryCheckingFormStatus;
