import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import PurchaseOrderCostSummaryModel from "common/models/PurchaseOrderCostSummaryModel";
import {
  PurchaseOrderInitCashflowReceiptRequest,
  PurchaseOrderInitProductReceiptRequest,
} from "common/types/PurchaseOrder";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

const SERVICE_URL = "/purchaseorderhelpers";

class PurchaseOrderHelperApi extends BaseApi {
  async getCostSummary(
    productVariantId: number,
    supplierId: number
  ): Promise<PurchaseOrderCostSummaryModel> {
    let item = new PurchaseOrderCostSummaryModel(
      PurchaseOrderCostSummaryModel.getDefaultData()
    );
    try {
      //For create item
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/getcost/" + productVariantId + "/" + supplierId
      );
      if (response.hasOwnProperty("data")) {
        item = new PurchaseOrderCostSummaryModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  async initProductReceipt(
    data: PurchaseOrderInitProductReceiptRequest
  ): Promise<ProductReceiptModel> {
    let item = new ProductReceiptModel(ProductReceiptModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/initproductreceipt",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ProductReceiptModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async initCashflowReceipt(
    data: PurchaseOrderInitCashflowReceiptRequest
  ): Promise<CashflowReceiptModel> {
    let item = new CashflowReceiptModel(CashflowReceiptModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/initcashflowreceipt",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CashflowReceiptModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async linkProductReceipt(
    purchase_order_id: number,
    product_receipt_id: string
  ): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(
        SERVICE_URL +
          "/linkproductreceipt/" +
          purchase_order_id +
          "/" +
          product_receipt_id
      );
      if (response.status !== 200) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async linkCashflowReceipt(
    purchase_order_id: number,
    cashflow_receipt_id: string
  ): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(
        SERVICE_URL +
          "/linkcashflowreceipt/" +
          purchase_order_id +
          "/" +
          cashflow_receipt_id
      );
      if (response.status !== 200) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default PurchaseOrderHelperApi;
