import EcomLogApi from 'common/api/EcomLogApi';
import EcomLogCollection from 'common/collections/EcomLogCollection';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type { FilterEcomLog } from "common/types/EcomLog";

class EcomLogRepository extends BaseRepository<EcomLogApi> {
  _api: EcomLogApi | null;

  constructor() {
    super();
    this._api = new EcomLogApi(true);
  }

  static getDefaultFilters(): FilterEcomLog {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      type: -1,
      topic: "",
      ecom_platform_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterEcomLog>) {
    return this._api ? await this._api.getList(props) : new EcomLogCollection();
  }
}

export default EcomLogRepository;
