import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { PurchaseOrderCostSummary } from "common/types/PurchaseOrder";
class PurchaseOrderCostSummaryModel
  extends BaseModel
  implements BaseModelJson<PurchaseOrderCostSummary>
{
  original_cost: number;
  latest_cost: number;

  constructor(json: PurchaseOrderCostSummary) {
    super();

    this.original_cost = json.original_cost || 0;
    this.latest_cost = json.latest_cost || 0;
  }

  static getDefaultData(): PurchaseOrderCostSummary {
    return {
      original_cost: 0,
      latest_cost: 0,
    };
  }

  toJson(): PurchaseOrderCostSummary {
    return {
      original_cost: this.original_cost,
      latest_cost: this.latest_cost,
    };
  }
}

export default PurchaseOrderCostSummaryModel;
