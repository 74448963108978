import { Button } from "antd";
import CustomerTypeCollection from "common/collections/CustomerTypeCollection";
import Role from "common/constants/Role";
import CustomerTypeModel from "common/models/CustomerTypeModel";
import CustomerTypeRepository from "common/repositories/CustomerTypeRepository";
import { FilterCustomerType } from "common/types/CustomerType";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableInfoRow from "components/table/TableInfoRow";
import TableLinkText from "components/table/TableLinkText";
import TextMoney from "components/TextMoney";
import CustomerTypeFormModal from "features/customertype/form/CustomerTypeFormModal";
import CustomerTypeListHeader from "features/customertype/list/CustomerTypeListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomerTypeListFilter from "./CustomerTypeListFilter";

const CustomerTypeList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterCustomerType = useMemo(
    () => CustomerTypeRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterCustomerType>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CustomerTypeModel[]>([]);
  const fetchData = useCallback(async (): Promise<CustomerTypeCollection> => {
    let collection = await new CustomerTypeRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);

    return collection;
  }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<CustomerTypeModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(record.id)}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("customertype:name"),
      key: "name",
      sortby: "name",
      width: 200,
    },

    {
      title: t("customertype:description"),
      key: "description",
    },
    {
      title: t("customertype:auto_upgrade_quota_short"),
      key: "auto_upgrade_quota",
      sortby: "auto_upgrade_quota",
      width: 200,
      align: "right",
      render: (auto_upgrade_quota) => {
        return (
          <>
            <TextMoney money={auto_upgrade_quota} />
          </>
        );
      },
    },
    {
      title: t("common:display_order_long"),
      width: 140,
      key: "display_order",
      sortby: "display_order",
      align: "center",
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: CustomerTypeModel) => (
        <RoleCheck roles={[Role.SETTING_SALE]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="customertype:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new CustomerTypeRepository()}
            id={record.id}
          />
          <TableInfo record={record}>
            <TableInfoRow
              label={t("customertype:code")}
              content={record.code}
            />
          </TableInfo>
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: CustomerTypeModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        })
      );
    }
  };

  return (
    <>
      <CustomerTypeListHeader>
        <RoleCheck roles={[Role.SETTING_SALE]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </CustomerTypeListHeader>
      <CustomerTypeListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterCustomerType,
        CustomerTypeModel,
        CustomerTypeCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <CustomerTypeFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default CustomerTypeList;
