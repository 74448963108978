import { useCallback } from "react";
import dbm from "dbm";
import db from "db";
import AccountRepository from "common/repositories/AccountRepository";
import AccountModel from "common/models/AccountModel";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import eventEmitter from "common/utils/eventEmitter";
import useProcessStore from "zustands/useProcessStore";

const useFunction = () => {
	const [account, setAccount] = useLoginAccountStore((state) => [
		state.account,
		state.setAccount,
	]);
	const [fetchErrors, setFetchErrors] = useProcessStore((state) => [
		state.fetchErrors,
		state.setFetchErrors,
	]);
	console.log("🚀 ~ useFunction ~ account:", account);

	// Function
	const doLogOut = async (callback?: () => void) => {
		if (account.id > 0 && account.jwt.length > 0) {
			new AccountRepository()
				.logout()
				.then((errors) => {
					if (errors.length > 0) {
						console.log("Logout on server ERROR", errors);
					}
				})
				.catch(() => {})
				.finally(() => {
					setFetchErrors([]);
					setAccount(new AccountModel(AccountModel.getDefaultData()));
					console.log("Logout on server SUCCESS (Added JWT to blacklist).");
				});
		} else {
			setFetchErrors([]);
		}

		callback && callback();
	};

	const triggerLogout = useCallback(() => {
		setFetchErrors([]);
		eventEmitter.emit("DO_LOGOUT", { source: "fetch_error" });
	}, []);

	const handleErrorFetch = (errors: string[]) => {
		setFetchErrors([...fetchErrors, ...errors]);
	};

	function getGpsPromise() {
		return new Promise((resole, reject) => {
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(
					() => {
						resole(true);
					},
					() => {
						reject(new Error("get_gps_notfound"));
					}
				);
			} else {
				reject(new Error("get_gps_notfound"));
			}
		});
	}

	function dataURLtoFile(dataurl: any, filename: any) {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
	const convertBast64ToFile = (imageBase64: string, fileName: string) => {
		if (imageBase64) {
			var file = dataURLtoFile(`${imageBase64}`, fileName);

			// const base64Response = await fetch(imageBase64);
			// const blob = await base64Response.blob();
			return file;
		}
	};
	//////////////////////////////////
	return {
		doLogOut,
		getGpsPromise,
		convertBast64ToFile,
		triggerLogout,
		handleErrorFetch,
	};
};
export default useFunction;
