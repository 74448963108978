import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const DeliveryPlanningListHeader = ({
  children,
  afterHeading,
}: {
  children?: React.ReactNode;
  afterHeading?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("deliveryplanning:heading_list")}
      siteMenuSelectedKey="/shippingroute"
      siteMenuHidden
      afterHeading={afterHeading}
    >
      {children}
    </PageHeader>
  );
};

export default DeliveryPlanningListHeader;
