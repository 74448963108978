import SettingApi from "common/constants/Setting";
import { SettingGroupType } from "common/types/Setting";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const ShopSettingListHeader = ({
	type,
	children,
}: {
	type: SettingGroupType;
	children: React.ReactNode;
}) => {
	const { t } = useTranslation();

	const renderKey = (typeHeader: SettingGroupType) => {
		switch (typeHeader) {
			case SettingApi.GROUP.SHOP:
				return "/shopsetting";

			case SettingApi.GROUP.ERP:
				return "/erpsetting";

			case SettingApi.GROUP.LANGUAGE:
				return "/languagesetting";

			case SettingApi.GROUP.COMMON:
				return "/commonsetting";

			case SettingApi.GROUP.PAGE:
				return "/pagesetting";
			case SettingApi.GROUP.ALL:
				return "/shopsettingall";
		}
	};

	const renderTitle = (typeHeader: SettingGroupType) => {
		switch (typeHeader) {
			case SettingApi.GROUP.SHOP:
				return "Cài đặt shop";

			case SettingApi.GROUP.ERP:
				return "Cài đặt ERP";

			case SettingApi.GROUP.LANGUAGE:
				return "Cài đặt Ngôn ngữ";

			case SettingApi.GROUP.COMMON:
				return "Cài đặt chung (COMMON)";

			case SettingApi.GROUP.PAGE:
				return "Cài đặt Page";
			case SettingApi.GROUP.ALL:
				return "Cài đặt tất cả";
			default:
				return "Shop setting";
		}
	};

	return (
		<PageHeader
			heading={renderTitle(type)}
			siteMenuSelectedKey={renderKey(type)}
			siteMenuOpenKey="manualsetting">
			{children}
		</PageHeader>
	);
};

export default ShopSettingListHeader;
