import { Button, Checkbox, Popover } from "antd";
import BaseModel from "common/models/BaseModel";
import { TableColumnsType, TableColumnType } from "common/types/Table";
import { xor } from "lodash";
import { useCallback } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import usePageDataColumnToggleStore from "zustands/usePageDataTableColumnToggleStore";

import { IconColumns } from "@tabler/icons-react";

const PageDataTableColumnToggle = <M extends BaseModel>({
  columns,
  tableColumnToggleKey,
}: {
  columns: TableColumnsType<M>;
  tableColumnToggleKey: string;
}) => {
  const { t } = useTranslation();

  const tableColumnToggleInfo = usePageDataColumnToggleStore((state) =>
    state.getTable(tableColumnToggleKey)
  );
  const updateTable = usePageDataColumnToggleStore(
    (state) => state.updateTable
  );

  const getColumnToggleKey = useCallback((column: TableColumnType<M>) => {
    return typeof column.togglekey !== "undefined" &&
      column.togglekey.length > 0
      ? column.togglekey
      : typeof column.dataIndex === "string" && column.dataIndex.length > 0
      ? column.dataIndex
      : typeof column.key !== "undefined"
      ? column.key.toString()
      : "";
  }, []);

  const isColumnEnable = useCallback(
    (column: TableColumnType<M>) => {
      let enable = false;

      if (column.toggletype === "extend") {
        //check if this is extend
        if (
          typeof tableColumnToggleInfo !== "undefined" &&
          tableColumnToggleInfo.extend_columns?.includes(
            getColumnToggleKey(column)
          )
        ) {
          enable = true;
        }
      } else {
        //check if this is disabled or not
        if (
          column.toggletype !== "ignore" &&
          (typeof tableColumnToggleInfo === "undefined" ||
            !tableColumnToggleInfo.disable_columns.includes(
              getColumnToggleKey(column)
            ))
        ) {
          enable = true;
        }
      }

      return enable;
    },
    [tableColumnToggleInfo, getColumnToggleKey]
  );

  const onChangeColumnToggle = useCallback(
    (column: TableColumnType<M>, checked: boolean) => {
      const columnKey = getColumnToggleKey(column);
      let updatedDisableColumns: string[] = [];
      let updatedExtendColumns: string[] = [];

      if (column.toggletype === "extend") {
        //update `extend` section
        if (typeof tableColumnToggleInfo !== "undefined") {
          updatedDisableColumns = tableColumnToggleInfo.disable_columns;
          //toggle extend column
          updatedExtendColumns = xor(tableColumnToggleInfo.extend_columns, [
            columnKey,
          ]);
        } else {
          //init new list
          updatedExtendColumns = [columnKey];
        }
      } else {
        //update `disable` section
        if (typeof tableColumnToggleInfo !== "undefined") {
          //toggle disabled column
          updatedDisableColumns = xor(tableColumnToggleInfo.disable_columns, [
            columnKey,
          ]);
          updatedExtendColumns = tableColumnToggleInfo.extend_columns;
        } else {
          //init new list
          updatedDisableColumns = [columnKey];
        }
      }

      updateTable({
        table: tableColumnToggleKey,
        disable_columns: updatedDisableColumns,
        extend_columns: updatedExtendColumns,
      });
    },
    [
      tableColumnToggleInfo,
      updateTable,
      tableColumnToggleKey,
      getColumnToggleKey,
    ]
  );

  const displayColumns = columns.filter((c) => c.toggletype !== "trigger");

  const content = (
    <div>
      <div className="font-bold mb-2">{t("common:table.toggle.heading")}:</div>
      {displayColumns.map((column: any) => (
        <div key={getColumnToggleKey(column)} className="capitalize py-0.5">
          <Checkbox
            disabled={column.toggletype === "ignore"}
            className="hover:text-gray-800"
            checked={isColumnEnable(column)}
            onChange={(e) => onChangeColumnToggle(column, e.target.checked)}
          >
            {column.togglelabel || column.title}
          </Checkbox>
        </div>
      ))}
    </div>
  );

  return (
    <Popover
      overlayInnerStyle={{ width: 240 }}
      content={
        displayColumns.length > 10 ? (
          <Scrollbars
            style={{
              height: 300,
              overflow: "hidden",
            }}
            autoHide
          >
            {content}
          </Scrollbars>
        ) : (
          content
        )
      }
      placement="bottomRight"
      trigger={"click"}
      zIndex={5}
    >
      <Button
        title={t("common:table.toggle.trigger_tooltip")}
        icon={<IconColumns size={24} />}
        type="text"
        size="small"
      ></Button>
    </Popover>
  );
};

export default PageDataTableColumnToggle;
