import {
	App,
	Button,
	Collapse,
	FormInstance,
	Modal,
	notification,
	Select,
	Skeleton,
	Table,
	Tabs,
	Tag,
	Typography,
} from "antd";
import Order from "common/constants/Order";
import OrderDetailModel from "common/models/OrderDetailModel";
import OrderDetailRepository from "common/repositories/OrderDetailRepository";
import { OrderDetailJson } from "common/types/OrderDetail";
import { ProductVariantInMemory } from "common/types/ProductVariant";
import ErrorAlert from "components/ErrorAlert";
import dbm from "dbm";
import ProductVariantSearch from "features/product/ProductVariantSearch";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import addToListFx from "sounds/pos/notify.mp3";
import useSound from "use-sound";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import useUiSettingStore from "zustands/useUiSettingStore";
import OrderFormSectionDetailList from "../detail/OrderFormSectionDetailList";
import OrderFormSectionPriceSummary from "../OrderFormSectionPriceSummary";
import OrderModel from "common/models/OrderModel";
import { OrderJson } from "common/types/Order";
import OrderFormListItem from "./OrderFormListItem";
import { CartOrderJson } from "common/types/Cart";
import OrderDetailPriceSummary from "./OrderSellerDetailPriceSummary";
import { TableColumnsType } from "common/types/Table";
import i18n from "i18n";
import Helper from "common/utils/helper";
import OrderSellerRepository from "common/repositories/OrderSellerRepository";
const OrderFormSectionDetail = ({
	model,
	setDetails,
	form,
	allowEditInput,
}: {
	model: OrderJson;
	setDetails: (v: OrderDetailJson[]) => void;
	form: FormInstance;
	allowEditInput: boolean;
}) => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const order = useEditingSaleOrderStore((state) => state.order);
	const enableSoundFx = useUiSettingStore((state) => state.enableSoundFx);
	const [soundFxAddToListSuccess] = useSound(addToListFx);
	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<OrderDetailModel[]>([]);
	const [dataSourceEditable, setDataSourceEditable] = useState<
		OrderDetailModel[]
	>([]);
	const [errors, setErrors] = useState<string[]>([]);

	const [openUpdateStatusPayment, setOpenUpdateStatusPayment] = useState(false)
	const [statusPayment, setStatusPayment] = useState(model.payment_status)
	const totalQuantity = useMemo(() => {
		let quantity = 0;
		dataSourceEditable.forEach((i) => {
			quantity += i.item_quantity;
		});
		return quantity;
	}, [dataSourceEditable]);

	const onChangeItem = useCallback(
		(item: CartOrderJson) => {
			// setDataSourceEditable(
			//   dataSourceEditable.map((i) => (i.sku === item.sku ? item : i))
			// );
		},
		[dataSourceEditable]
	);

	const onRemoveItem = useCallback(
		(item: CartOrderJson) => {
			// setDataSourceEditable(
			//   dataSourceEditable.filter((i) => {
			//     if (item.id > 0) {
			//       return i.id !== item.id;
			//     } else {
			//       //new append on UI
			//       return i.sku !== item.sku;
			//     }
			//   })
			// );
		},
		[dataSourceEditable]
	);

	const onSelectVariant = useCallback(
		async (product_variant_id: number) => {
			let insertPass = true;

			const foundIndex = dataSourceEditable.findIndex(
				(r) => r.product_variant_id === product_variant_id
			);
			let newItems: OrderDetailModel[] = [];
			if (foundIndex >= 0) {
				//update current item
				newItems = update(dataSourceEditable, {
					[foundIndex]: {
						$set: new OrderDetailModel({
							...dataSourceEditable[foundIndex],
							item_quantity: dataSourceEditable[foundIndex].item_quantity + 1,
						}),
					},
				});
			} else {
				await (async () => {
					const foundItems = await dbm
						.getCollection("productvariant")
						.chain()
						.find({
							id: product_variant_id,
						})
						.limit(1)
						.data();
					if (foundItems.length > 0) {
						const foundVariant: ProductVariantInMemory = foundItems[0];
						//append new item to list
						newItems = update(dataSourceEditable, {
							$push: [
								new OrderDetailModel({
									...OrderDetailModel.getDefaultData(),
									order_id: order.invoice_id,
									product_id: foundVariant.product_id,
									product_variant_id: product_variant_id,
									sku: foundVariant.sku,
									item_name: foundVariant.product_name,
									item_title: foundVariant.title,
									item_size: foundVariant.size,
									item_color: foundVariant.color,
									item_quantity: 1,
									item_unit_price_original: foundVariant.price,
									item_unit_price: foundVariant.price,
								}),
							],
						});
					} else {
						insertPass = false;
						message.error(
							t("productvariant:id_not_found", { id: product_variant_id })
						);
					}
				})();
			}

			if (insertPass) {
				setDataSourceEditable(newItems);
				if (enableSoundFx) {
					soundFxAddToListSuccess();
				}
			}
		},
		[
			dataSourceEditable,
			setDataSourceEditable,
			enableSoundFx,
			soundFxAddToListSuccess,
			t,
			order.invoice_id,
			message,
		]
	);

	const fetchData = async (order_id: number) => {
		setLoading(true);
		setErrors([]);
		const collection = await new OrderDetailRepository().getItemsOfOrder(
			order_id
		);
		setLoading(false);
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			setDataSource(collection.items);
			setDataSourceEditable(collection.items);
		}
	};

	useEffect(() => {
		// fetchData(order.id);
	}, [order.id]);

	useEffect(() => {
		setDetails(
			dataSourceEditable.map((i) => {
				return i.toJson();
			})
		);
	}, [setDetails, dataSourceEditable]);

	///////////////////////////
	let statusInfo = OrderModel.getStatusPayment(model.payment_status);


	async function updateStatusPayment() {
		try {
			const res = await new OrderSellerRepository().saveRemote(
				{
					id: model.id,
					payment_status: statusPayment
				}
			);
			notification.error({message : "Cập nhập trạng thái thanh toán thành công"})

		} catch (error) {
			notification.error({message : "Có lỗi xảy ra vui lòng thử lại"})
		}
	}




	const columns: TableColumnsType<{
		amount: number;
		method: string;
		code?: string;
	}> = [
			{
				title: t("order:detail.no"),
				dataIndex: "id",
				width: 60,
				align: "center",
				render: (_, __, index: any) => {
					return <>{index + 1}</>;
				},
			},

			{
				title: t("Phương thức"),
				dataIndex: "method",
				align: "left",
				render: (method: string, record) => {
					let namePayment = "";

					// const listOptionPayments = DeliveryModel.getPaymentMethodSelect();
					// namePayment =
					// 	listOptionPayments.find((i) => i.value === method)?.label || "";
					const name = OrderModel.getOrderPaymentMethod(method);
					// switch (method) {
					// 	case "Voucher":
					// 		namePayment = i18n.t("delivery:method.METHOD_GIFTCARD");
					// 		break;
					// 	case "transfer":
					// 		namePayment = i18n.t("delivery:method.METHOD_BANK");
					// 		break;
					// 	case "vnpay":
					// 		namePayment = i18n.t("delivery:method.METHOD_VNPAY");
					// 		break;
					// 	default:
					// 		break;
					// }

					return (
						<>
							{name} {record?.code && <strong>{record.code}</strong>}{" "}
						</>
					);
				},
			},
			{
				title: t("Số tiền"),
				dataIndex: "amount",
				align: "right",
				render: (amount: number) => {
					return <>{Helper.moneyFormat(amount)}</>;
				},
			},
		];

	const items = [
		{
			label: "Thông tin",
			key: "1",
			children: (
				<>
					<OrderFormListItem
						totalQuantity={model.details.total}
						readonlyItems={model.details.data}
						items={model.details.data}
						onChangeItem={onChangeItem}
						onRemoveItem={onRemoveItem}
						allowEditInput={allowEditInput}
					/>
					<OrderDetailPriceSummary
						model={model}
						items={dataSourceEditable}
						form={form}
						allowEditInput={allowEditInput}
					/>
				</>
			),
		},
		{
			label: "Lịch sử thanh toán",
			key: "2",
			children: (
				<>
					<div className=" flex gap-3 items-center mb-4">
						<span>Trạng thái thánh toán :</span>
						<Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>

						{/* {model.type === Order.TYPE_INVOICE ? <Button onClick={() => setOpenUpdateStatusPayment(true)}>Cập nhập thanh toán</Button> : null} */}
						{/* {model.type === Order.TYPE_INVOICE ? : null} */}
						<Button onClick={() => setOpenUpdateStatusPayment(true)}>Cập nhập thanh toán</Button>


					</div>
					<Table
						size="small"
						rowKey="id"
						pagination={{ hideOnSinglePage: true }}
						columns={columns}
						dataSource={order.payments}
						scroll={{ x: 700 }}
					/>
				</>
			),
		},
	];

	return (
		<div>
			<div className="mb-2">
				{loading ? null : (
					<div className="">
						{/* <ProductVariantSearch
							disabled={
								order.status >= Order.STATUS_SHIPPING || !allowEditInput
							}
							onSelect={(product_variant_id) =>
								onSelectVariant(product_variant_id)
							}
						/> */}
					</div>
				)}
			</div>

			{loading ? (
				<Skeleton loading />
			) : (
				<>
					{errors.length > 0 ? (
						<ErrorAlert
							items={errors}
							heading={t("common:error.error_fetching_data")}
							onRetry={() => {
								setErrors([]);
								fetchData(order.invoice_id);
							}}
						/>
					) : (
						<Tabs items={items}></Tabs>
					)}
				</>
			)}

			<Modal destroyOnClose open={openUpdateStatusPayment} onCancel={() => setOpenUpdateStatusPayment(false)} onOk={updateStatusPayment}>
				<Select
					style={{ width: 300 }}
					defaultValue={statusPayment}
					onChange={(e) => {
						setStatusPayment(e)
					}}
					options={[{ label: "Thiếu, chưa thanh toán", value: Order.STATUS_PAYMENT_NONE },
					{ label: "Thanh toán đủ", value: Order.STATUS_PAYMENT_SUCCESS }]}>

				</Select>
			</Modal>
		</div>
	);
};

export default OrderFormSectionDetail;
