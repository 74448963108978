import CheckinModel from "common/models/CheckinModel";
import BaseCollection from "./BaseCollection";
import { CheckinJson, CheckinCollectionJson } from "common/types/Checkin";

class CheckinCollection extends BaseCollection<
  CheckinJson,
  CheckinModel,
  CheckinCollectionJson
> {
  itemsFromJson(jsonItems: CheckinJson[]): CheckinModel[] {
    return jsonItems.map((item) => new CheckinModel(item));
  }
}
export default CheckinCollection;
