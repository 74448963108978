import { Button, TableColumnsType, Tag, Tooltip } from "antd";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";

import TableDelete from "components/table/TableDelete";
import useFilterLocation from "hooks/useFilterLocation";

import { IconBox, IconPerfume, IconTag } from "@tabler/icons-react";
import NestedCollection from "common/collections/NestedCollection";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import {
  FilterProductCollection,
  ProductCollectionJsonWithChildren,
} from "common/types/ProductCollection";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTableWithChildren from "components/page/PageDataTableWithChildren";
import ProductCollectionProductModal from "../product/ProductCollectionProductModal";
import ProductCollectionTagModal from "../tag/ProductCollectionTagModal";
import ProductCollectionListFilter from "./ProductCollectionFilter";
import ProductCollectionListHeader from "./ProductCollectionListHeader";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import PageDataTable from "components/page/PageDataTable";
import ProductCollectionCollection from "common/collections/ProductCollectionCollection";
import ProductCollectionToCollectionModal from "../collection/ProductCollectionToCollectionModal";

const ProductCollectionList = () => {
  const { t } = useTranslation();

  // default filter
  const defaultFilters = useMemo(() => {
    return ProductCollectionRepository.getDefaultFilters();
  }, []);

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ProductCollectionModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //id

  //tag
  const [addTagVisible, setAddTagVisible] = useState(false);
  const [editingTagId, setEditingTagId] = useState(0);

  const onEditTag = (id: number) => {
    setEditingTagId(id);
    setAddTagVisible(true);
  };

  //product
  const [addProductVisible, setAddProductVisible] = useState(false);
  const [existProductId, setExistProductId] = useState<number[]>([]);
  const [editingProductCollectionId, setEditingProductCollectionId] =
    useState<number>(0);

  const onEditProduct = (record: ProductCollectionModel) => {
    setEditingProductCollectionId(record.id);
    setExistProductId(record.item_ids);
    setAddProductVisible(true);
  };

  //collection
  const [addCollectionVisible, setAddCollectionVisible] = useState(false);
  const [editingCollectionId, setEditingCollectionId] = useState(0);

  const onEditCollection = (id: number) => {
    setEditingCollectionId(id);
    setAddCollectionVisible(true);
  };

  //Table columns
  const columns: TableColumnsType<ProductCollectionModel> = [
    {
      title: t("common:columns.id"),
      key: "id",
      width: 100,
      render: (id) => {
        return <Tag color="blue">{id}</Tag>;
      },
    },
    {
      title: t("collection:columns.title"),
      key: "title",
      width: 100,
      render: (_: any, record: ProductCollectionModel) => {
        return <>{record.title}</>;
      },
    },
    {
      title: "",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 200,
      render: (id: number, record: ProductCollectionModel) => {
        return (
          <RoleCheck roles={[]} hideOnFail>
            <TableEdit link={`/productcollection/edit/id/${record.id}`} />
            <Button
              size={"small"}
              type={"text"}
              onClick={() => onEditTag(record.id)}
            >
              <Tooltip title="Quản lý tag">
                <IconTag
                  className="-mt-0.5 text-gray-400 hover:text-red-500"
                  size="18"
                />
              </Tooltip>
            </Button>

            <Button
              size={"small"}
              type={"text"}
              onClick={() => onEditProduct(record)}
            >
              <Tooltip title="Quản lý sản phẩm">
                <IconPerfume
                  className="-mt-0.5 text-gray-400 hover:text-red-500"
                  size="18"
                />
              </Tooltip>
            </Button>

            <Button
              size={"small"}
              type={"text"}
              onClick={() => onEditCollection(record.id)}
            >
              <Tooltip title="Quản lý danh mục">
                <IconBox
                  className="-mt-0.5 text-gray-400 hover:text-red-500"
                  size="18"
                />
              </Tooltip>
            </Button>

            <TableDelete
              error_translate_prefix="collection:form.error"
              onDeleteCallback={(id) => {
                setDataSource(dataSource.filter((item) => item.id !== id));
              }}
              repository={new ProductCollectionRepository()}
              id={record.id}
            />
            <TableInfo record={record} />
          </RoleCheck>
        );
      },
    },
  ];

  //function
  const fetchData =
    useCallback(async (): Promise<ProductCollectionCollection> => {
      const response = await new ProductCollectionRepository().getItems({
        filters: filters,
      });
      if (!response.hasError()) {
        setTotal(response.total);
        setDataSource(response.items);
      }
      return response;
    }, [filters]);

  return (
    <div>
      <ProductCollectionListHeader />

      <ProductCollectionListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />

      <PageDataTable<
        FilterProductCollection,
        ProductCollectionModel,
        ProductCollectionCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "storycollection",
        }}
      />
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource as any}
      />
      <ProductCollectionTagModal
        id={editingTagId}
        key={editingTagId}
        open={addTagVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingTagId(0);
          }
          setAddTagVisible(isOpen);
        }}
      />

      <ProductCollectionToCollectionModal
        id={editingCollectionId}
        key={editingCollectionId}
        open={addCollectionVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingCollectionId(0);
          }
          setAddCollectionVisible(isOpen);
        }}
      />

      <ProductCollectionProductModal
        id={editingProductCollectionId}
        key={editingProductCollectionId}
        open={addProductVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingProductCollectionId(0);
          }
          setAddProductVisible(isOpen);
        }}
      />
    </div>
  );
};

export default ProductCollectionList;
