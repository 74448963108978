import DmsNewsApi from 'common/api/DmsNewsApi';
import DmsNewsCollection from 'common/collections/DmsNewsCollection';
import DmsNewsModel from 'common/models/DmsNewsModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type { DmsNewsJsonAddEdit, FilterDmsNews } from "common/types/DmsNews";

class DmsNewsRepository extends BaseRepository<DmsNewsApi> {
  _api: DmsNewsApi | null;

  constructor() {
    super();
    this._api = new DmsNewsApi(true);
  }

  static getDefaultFilters(): FilterDmsNews {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      news_category_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterDmsNews>) {
    return this._api ? await this._api.getList(props) : new DmsNewsCollection();
  }

  async getItem(id: number): Promise<DmsNewsModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new DmsNewsModel(DmsNewsModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: DmsNewsJsonAddEdit): Promise<DmsNewsModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new DmsNewsModel(DmsNewsModel.getDefaultData());
    }
  }
}

export default DmsNewsRepository;
