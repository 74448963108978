import TypeOfWorkCollection from "common/collections/TypeOfWorkCollection";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
const SERVICE_URL = "/employees";

class TypeOfWorkApi extends BaseApi {
  async getList(): // props: GetListProps<FilterTypeWork>
  Promise<TypeOfWorkCollection> {
    let collection = new TypeOfWorkCollection();

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/gettypeworks"
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default TypeOfWorkApi;
