import BaseCollection from 'common/collections/BaseCollection';
import CustomerPointModel from 'common/models/CustomerPointModel';
import { CustomerPointCollectionJson, CustomerPointJson } from 'common/types/CustomerPoint';

class CustomerPointCollection extends BaseCollection<
  CustomerPointJson,
  CustomerPointModel,
  CustomerPointCollectionJson
> {
  itemsFromJson(jsonItems: CustomerPointJson[]): CustomerPointModel[] {
    return jsonItems.map((item) => new CustomerPointModel(item));
  }
}

export default CustomerPointCollection;
