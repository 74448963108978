import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Input, Row, Switch } from "antd";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";
import FormSelectNormal from "components/form/FormSelectNormal";

import StoryModel from "common/models/StoryModel";
import {
  PublicationsJsonAddEdit,
  PublicationsStatus,
} from "common/types/Story";
import DocumentFormHeader from "./DocumentFormHeader";
import PublicationsRepository from "common/repositories/PublicationsRepository";
import FileUploaderItem from "components/file/uploader/FileUploaderItem";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
import FileModel from "common/models/FileModel";
import useGetfilesByIds from "hooks/useGetfilesByIds";

type Props = {
  model: StoryModel;
};
const DocumentForm = ({ model }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;

  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [status, setStatus] = useState(model.status);
  const [errors, setErrors] = useState<string[]>([]);

  const [files, setFiles] = useState<FileModel[]>([]);

  const fileIds = model.meta_file.split(",").map(Number);
  const { files: filess } = useGetfilesByIds(fileIds, { origin: "company"  , object_type : 30});
  useEffect(() => {
    if (filess) {
      const files = fileIds
        .map((file) => {
          return filess[file];
        })
        .filter(Boolean) as FileModel[];
      setFiles(files);
    }
  }, [filess]);
  console.log(files);
  //default data
  const initialValues = useMemo(() => {
    return {
      meta_title: model.meta_title,
      content_type: model.content_type ? model.content_type : 3,
      meta_file: model.meta_file,
      status: model.status,
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: Partial<PublicationsJsonAddEdit> = {
        meta_title: formData.meta_title,
        content_type: "3",
        meta_file: files.map((file) => file.id).join(","),
        status: status,
      };

      return submitData;
    },
    [model.id, files, status]
  );

  useEffect(() => {
    setStatus(model.status);
  }, [model.status]);

  //submit data to server
  const onSubmit = async (formData: PublicationsJsonAddEdit) => {
    const response = await new PublicationsRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      setIsSuccess(true);
    } else {
      setErrors(response.error.errors);
    }
  };

  const sidebarItems = (
    <div className=" mb-4">
      <Col md={24} xs={24}>
        <p>Trạng thái</p>
        <Switch
          checked={status === PublicationsStatus.ACTIVE}
          onChange={(e) => {
            if (e.valueOf()) {
              setStatus(PublicationsStatus.ACTIVE);
            } else {
              setStatus(PublicationsStatus.UNACTIVE);
            }
          }}
        />
      </Col>
    </div>
  );

  //   const { files } = useGetfilesByIds(
  //     [...imgIds, model.banner_desktop, model.banner_mobile].filter(Boolean)
  //   );

  function onUploadCompleted(f: FileModel) {
    setFiles([...files, f]);
  }

  function onDeleteCompleted(f: FileModel) {
      console.log(f)
    setFiles((pre) => {
      const newFiles = pre.filter((file) => file.id !== f.id);
      return newFiles;
    });
  }

  return (
    <div>
      <DocumentFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        error_translate_prefix="common:form.error"
        onSubmit={onSubmit}
        redirectOnSuccess="/documents"
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("document:form.section_main")}
          subtitle={t("document:form.section_subtitle_main")}
          divider
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                name="meta_title"
                label={t("document:title")}
                required
                rules={[
                  {
                    required: true,
                    message: t("document:rules.title"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <FormSelectNormal
                name="content_type"
                label={t("Loại")}
                value={3}
                defaultValue={3}
                // disabled
                options={[
                  {
                    value: 3,
                    label: "Chứng từ",
                  },
                ]}
              />
            </Col>

            <Col md={24} xs={24}>
              <FileUploaderButton
                allowExtensions={FileModel.getAllSupportUploadExtensions()}
                origin="company"
                onUploadCompleted={onUploadCompleted}
                onDeleteCompleted={onDeleteCompleted}
                uploadRenderType="list"
                initFiles={FileModel.convertToUploadFiles(files)}
              />
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default DocumentForm;
