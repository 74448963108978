import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

import ShopCollectionList from "features/shopcollection/list/ShopCollectionList";
import ShopPopupList from "features/shoppopup/list/ShopPopupList";
import ShopFooterList from "features/shopfooter/list/ShopFooterList";

const ShopFooterPage = () => {
  const [pathParams] = usePathParams();

  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    case "edit":
      break;
    default:
      com = <ShopFooterList />;
      break;
  }
  return com;
};

export default ShopFooterPage;
