import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const DataGridEditorHeaderFieldAddButton = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-32 flex flex-col bg-gray-50 h-full">
      <Tooltip title={t("datagridtablefield:add_tooltip")}>
        <button
          onClick={() => onClick()}
          className="w-full border-none outline-none h-9 bg-gray-300 border-b border-gray-200 cursor-pointer hover:bg-gray-200"
        >
          +
        </button>
      </Tooltip>
    </div>
  );
};

export default DataGridEditorHeaderFieldAddButton;
