import FileHelperApi from 'common/api/FileHelperApi';
import FileStatsModel from 'common/models/FileStatsModel';

import BaseRepository from './BaseRepository';

class FileHelperRepository extends BaseRepository<FileHelperApi> {
  _api: FileHelperApi | null;

  constructor() {
    super();
    this._api = new FileHelperApi(true);
  }

  async getStats(): Promise<FileStatsModel> {
    return this._api
      ? await this._api.getStats()
      : new FileStatsModel(FileStatsModel.getDefaultData());
  }
}

export default FileHelperRepository;
