import DmsNews from "common/constants/DmsNews";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

import type { DmsNewsJson } from "common/types/DmsNews";

class DmsNewsModel extends BaseModel implements BaseModelJson<DmsNewsJson> {
  creator_id: number;
  company_id: number;
  id: number;
  news_category_id: number;
  file_id: number;
  image: FileModel;
  title: string;
  content: string;
  related_ids: number;
  seo_slug: string;
  seo_title: string;
  seo_keyword: string;
  seo_description: string;
  graph_image_id: number;
  graph_image: FileModel;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: DmsNewsJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.news_category_id = json.news_category_id || 0;
    this.file_id = json.file_id || 0;
    this.image = new FileModel(json.image || FileModel.getDefaultData());
    this.title = json.title || "";
    this.content = json.content || "";
    this.related_ids = json.related_ids || 0;
    this.seo_slug = json.seo_slug || "";
    this.seo_title = json.seo_title || "";
    this.seo_keyword = json.seo_keyword || "";
    this.seo_description = json.seo_description || "";
    this.graph_image_id = json.graph_image_id || 0;
    this.graph_image = new FileModel(
      json.graph_image || FileModel.getDefaultData()
    );
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): DmsNewsJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      news_category_id: 0,
      file_id: 0,
      image: new FileModel(FileModel.getDefaultData()),
      title: " ",
      content: "",
      related_ids: 0,
      seo_slug: "",
      seo_title: " ",
      seo_keyword: "",
      seo_description: "",
      graph_image_id: 0,
      graph_image: new FileModel(FileModel.getDefaultData()),
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): DmsNewsJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      news_category_id: this.news_category_id,
      file_id: this.file_id,
      image: this.image,
      title: this.title,
      content: this.content,
      related_ids: this.related_ids,
      seo_slug: this.seo_slug,
      seo_title: this.seo_title,
      seo_keyword: this.seo_keyword,
      seo_description: this.seo_description,
      graph_image_id: this.graph_image_id,
      graph_image: this.graph_image,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: DmsNews.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: DmsNews.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default DmsNewsModel;
