import CmsNewsCategoryApi from 'common/api/CmsNewsCategoryApi';
import CmsNewsCategoryCollection from 'common/collections/CmsNewsCategoryCollection';
import NestedCollection from 'common/collections/NestedCollection';
import CmsNewsCategoryModel from 'common/models/CmsNewsCategoryModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  CmsNewsCategoryJsonAddEdit,
  CmsNewsCategoryJsonWithChildren,
  FilterCmsNewsCategory,
} from "common/types/CmsNewsCategory";
class CmsNewsCategoryRepository extends BaseRepository<CmsNewsCategoryApi> {
  _api: CmsNewsCategoryApi | null;

  constructor() {
    super();
    this._api = new CmsNewsCategoryApi(true);
  }

  static getDefaultFilters(): FilterCmsNewsCategory {
    return {
      page: 1,
      limit: 500,
      sortby: "display_order",
      sorttype: "ASC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterCmsNewsCategory>) {
    return this._api
      ? await this._api.getList(props)
      : new CmsNewsCategoryCollection();
  }

  async getFullNestedItems() {
    return this._api
      ? await this._api.getAll()
      : new NestedCollection<CmsNewsCategoryJsonWithChildren>();
  }

  async getItem(id: number): Promise<CmsNewsCategoryModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new CmsNewsCategoryModel(CmsNewsCategoryModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: CmsNewsCategoryJsonAddEdit
  ): Promise<CmsNewsCategoryModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new CmsNewsCategoryModel(CmsNewsCategoryModel.getDefaultData());
    }
  }
}

export default CmsNewsCategoryRepository;
