const Promotion = {
  STATUS_PENDING: 1,
  STATUS_RUNNING: 3,
  STATUS_PAUSED: 5,
  STATUS_ENDED: 7,
  STATUS_REMOVED: 9,

  // GROUP_PERMANENT: 1,
  GROUP_SEASONAL: "seasonal",
  GROUP_COUPON: "coupon",



  TYPE_SEASONAL_ORDER_DISCOUNT: 101,
  TYPE_SEASONAL_PRODUCT_DISCOUNT: 103,
  TYPE_SEASONAL_PRODUCT_GIFT: 105,
  TYPE_SEASONAL_ORDER_BATCH: 107,
  TYPE_SEASONAL_ORDER_GIFT: 109,
  TYPE_LOYALTY_VIP_DISCOUNT: 301,
  TYPE_LOYALTY_NEW_MEMBER: 303,
  TYPE_LOYALTY_COUPON: 305,
  TYPE_PERMANENT_PRODUCT_DISCOUNT: 501,

  TYPE_BODY : "order",
  TYPE_ITEM : "product",


  STORESCOPE_ALL: 1,
  STORESCOPE_CUSTOM: 3,

  CUSTOMERSCOPE_ALL: 1,
  CUSTOMERSCOPE_CUSTOM: 3,

  VALUETYPE_PERCENT: 1,
  VALUETYPE_CURRENCY: 3,
  VALUETYPE_RANGE: 5,
  VALUETYPE_FIXPRICE: 7,
  VALUETYPE_QUANTITY: 9,

  VALUETYPERANGE_PERCENT: 1,
  VALUETYPERANGE_CURRENCY: 3,
  VALUETYPERANGE_FIXPRICE: 7,
};

export default Promotion;
