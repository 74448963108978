import ProjectTaskCommentCollection from "common/collections/ProjectTaskCommentCollection";
import ProjectTaskCommentModel from "common/models/ProjectTaskCommentModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterProjectTaskComment,
  ProjectTaskCommentJsonAdd,
} from "common/types/ProjectTaskComment";

const SERVICE_URL = "/projecttaskcomments";

class ProjectTaskCommentApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProjectTaskComment>,
  ): Promise<ProjectTaskCommentCollection> {
    let collection = new ProjectTaskCommentCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          company_id: +filters.company_id > 0 ? filters.company_id : null,
          project_task_id:
            +filters.project_task_id > 0 ? filters.project_task_id : null,
          comment_id_before:
            +filters.comment_id_before > 0 ? filters.comment_id_before : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ProjectTaskCommentModel> {
    let item = new ProjectTaskCommentModel(
      ProjectTaskCommentModel.getDefaultData(),
    );

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ProjectTaskCommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: ProjectTaskCommentJsonAdd): Promise<ProjectTaskCommentModel> {
    let item = new ProjectTaskCommentModel(
      ProjectTaskCommentModel.getDefaultData(),
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProjectTaskCommentModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ProjectTaskCommentApi;
