import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";
import { PhoneBookJson } from "common/types/PhoneBook";
import { FileJson } from "common/types/File";
import FileModel from "./FileModel";

class PhoneBookModel extends BaseModel implements BaseModelJson<PhoneBookJson> {
	id: number;
	avatar: FileJson;
	internal_id: string;
	full_name: string;
	job_title: string;
	department_id: string;
	office_id: string;
	work_email: string;
	personal_email: string;
	work_phone: string;
	personal_phone: string;
	address: string;
	birthday: string;
	last_checkin: number;
	last_checkout: number;
	last_login: number;

	constructor(json: PhoneBookJson) {
		super();

		this.id = json.id || 0;
		this.internal_id = json.internal_id || "";
		this.avatar = new FileModel(json.avatar || FileModel.getDefaultData());
		this.full_name = json.full_name || "";
		this.job_title = json.job_title || "";
		this.address = json.address || "";
		this.birthday = json.birthday || "";
		this.department_id = json.department_id || "";
		this.office_id = json.office_id || "";
		this.work_email = json.work_email || "";
		this.personal_email = json.personal_email || "";
		this.work_phone = json.work_phone || "";
		this.personal_phone = json.personal_phone || "";

		this.last_checkin = json.last_checkin;
		this.last_checkout = json.last_checkout;
		this.last_login = json.last_login;
	}

	toJson(): PhoneBookJson {
		return {
			id: this.id,
			avatar: this.avatar,
			internal_id: this.internal_id,
			full_name: this.full_name,
			job_title: this.job_title,
			department_id: this.department_id,
			office_id: this.office_id,
			work_email: this.work_email,
			personal_email: this.personal_email,
			work_phone: this.work_phone,
			personal_phone: this.personal_phone,
			address: this.address,
			birthday: this.birthday,
			last_checkin: this.last_checkin,
			last_checkout: this.last_checkin,
			last_login: this.last_checkin,
		};
	}

	static getDefaultData(): PhoneBookJson {
		return {
			id: 0,
			avatar: FileModel.getDefaultData(),
			internal_id: "",
			full_name: "",
			job_title: "",
			department_id: "",
			office_id: "",
			work_email: "",
			personal_email: "",
			work_phone: "",
			personal_phone: "",
			address: "",
			birthday: "",
			last_checkin: 0,
			last_checkout: 0,
			last_login: 0,
		};
	}
}

export default PhoneBookModel;
