import { Descriptions, Spin, message } from "antd";
import EmployeeModel from "common/models/EmployeeModel";
import TaskModel from "common/models/TaskModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import TaskRepository from "common/repositories/TaskRepository";
import TextDateTime from "components/TextDateTime";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScopeTask from "./ScopeTask";
import { TaskScopeItemJson } from "common/types/Task";

type Props = {
  id: number;
};
const DetailTask = ({ id }: Props) => {
  const { t } = useTranslation();

  //state
  const [detail, setDetail] = useState(TaskModel.getDefaultData());
  const [userInfo, setUserInfo] = useState<EmployeeModel>();
  const [taskId, setTaskId] = useState<TaskScopeItemJson[]>([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState("");

  // function
  const fetchDetail = async () => {
    setLoading(true);
    const item = await new TaskRepository().getItem(id);
    if (item.hasError()) {
      message.open({
        type: "warning",
        content: "Có lỗi rồi !",
        duration: 2,
      });
      return;
    } else {
      item.scope_ids.length > 0 && fetchListTaskId(item.scope_ids);
      setDetail(item);
      fetchDetailEmployee(item.employee_id);
    }
    setLoading(false);
  };

  const fetchListTaskId = async (ids: number[]) => {
    const collection = await new TaskRepository().getItemsScope(ids);
    if (collection.length > 0) {
      if (collection[0]?.hasOwnProperty("error")) {
        setErrors(JSON.stringify(collection[0].error));
      } else {
        setTaskId(collection);
      }
    }
  };

  const fetchDetailEmployee = useCallback(async (id: number) => {
    const info = await new EmployeeRepository().getItem(id);
    if (info.hasError()) {
      return;
    } else {
      setUserInfo(info);
    }
  }, []);
  //
  let statusDes:
    | "warning"
    | "success"
    | "error"
    | "processing"
    | "default"
    | undefined = "default";
  switch (detail.status) {
    case 1:
      statusDes = "warning";
      break;
    case 2:
      statusDes = "processing";
      break;
    case 3:
      statusDes = "success";

      break;
    case 4:
      statusDes = "error";

      break;

    default:
      break;
  }
  //
  useEffect(() => {
    fetchDetail();
  }, [id, reload]);

  return (
    <Spin spinning={loading}>
      <div className="px-6">
        <Descriptions
          title={t(`Thông tin chi tiết công việc ${id}`)}
          bordered
          size="small"
          column={2}
        >
          <Descriptions.Item label="ID">{detail.id}</Descriptions.Item>
          <Descriptions.Item label={t("task:org_fromtime")}>
            <TextDateTime format="HH:mm, DD/MM/YYYY" ts={detail.org_fromtime} />
          </Descriptions.Item>
          <Descriptions.Item label={t("task:org_totime")}>
            <TextDateTime format="HH:mm, DD/MM/YYYY" ts={detail.org_totime} />
          </Descriptions.Item>

          <Descriptions.Item label={t("task:name")}>
            {detail.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("task:detail.rel_fromtime")}>
            {detail.rel_fromtime !== 0 ? (
              <TextDateTime
                format="HH:mm, DD/MM/YYYY"
                ts={detail.rel_fromtime}
              />
            ) : (
              "__"
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t("task:detail.rel_totime")}>
            {detail.rel_totime !== 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={detail.rel_totime} />
            ) : (
              "__"
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t("task:detail.createdBy")}>
            {userInfo?.full_name}
          </Descriptions.Item>
          <Descriptions.Item label={t("task:detail.note")}>
            <span
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: detail.note }}
            ></span>
          </Descriptions.Item>

          {/* <Descriptions.Item label="Các task liên quan">
          <ScopeTask ids={detail.scope_ids} />
        </Descriptions.Item> */}
        </Descriptions>
      </div>
    </Spin>
  );
};

export default DetailTask;
