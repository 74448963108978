import { Dropdown, List, Tag } from "antd";
import Role from "common/constants/Role";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingOrderRepository from "common/repositories/ShippingOrderRepository";
import { DdCollapsible } from "common/types/DeliveryDashboard";
import eventEmitter from "common/utils/eventEmitter";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import DeliveryDashboardContext from "contexts/DeliveryDashboardContext";
import DeliveryDashboardGetMarkerImage from "features/deliverydashboard/DeliveryDashboardGetMarkerImage";
import update from "immutability-helper";
import React, { useContext } from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import { IconSettings } from "@tabler/icons-react";

const DeliveryDashboardSectionCenterOrderItem = ({
  item,
  index,
  setOpen,
  setEditingOrder,
  orderCollapsible,
  setOrderCollapsible,
  distanceFromPreviousPoint,
}: {
  item: ShippingOrderModel;
  index: number;
  setOpen: (v: boolean) => void;
  setEditingOrder: (v: ShippingOrderModel) => void;
  orderCollapsible: DdCollapsible[];
  setOrderCollapsible: (v: DdCollapsible[]) => void;
  distanceFromPreviousPoint: number;
}) => {
  const deliveryDashboardProvider = useContext(DeliveryDashboardContext);
  const [orderItemsByActiveRoute, setOrderItems] = useDeliveryDashboardStore(
    (state) => [state.orderItemsByActiveRoute, state.setOrderItems]
  );

  let statusInfo = ShippingOrderModel.getStatus(item.status);
  let findShippingTypeInfo = deliveryDashboardProvider.shippingTypeItems.find(
    (i) => i.id === item.shipping_type
  );

  const onClickEditOrder = (item: ShippingOrderModel) => {
    setOpen(true);
    setEditingOrder(item);
  };

  const onChangeOrderCollapsible = (item: ShippingOrderModel) => {
    let foundIndex = orderCollapsible.findIndex((i) => i.activeKey === item.id);
    if (foundIndex >= 0) {
      let findActiveItem = orderCollapsible.find(
        (i) => i.activeKey === item.id
      );
      if (findActiveItem !== undefined) {
        setOrderCollapsible(
          update(orderCollapsible, {
            [foundIndex]: {
              isCollapsible: {
                $set: !findActiveItem?.isCollapsible,
              },
            },
          })
        );
      }
    }
  };

  const goToMarker = () => {
    eventEmitter.emit("DASHBOARD_GOONGMAP_GOTOMARKER", {
      latitude: item.order_shipping_lat,
      longitude: item.order_shipping_long,
    });
  };

  return (
    <>
      <List.Item
        className={
          "hover:bg-gray-100 " +
          (item.id ===
            orderCollapsible.find((i) => i.activeKey === item.id)?.activeKey &&
          orderCollapsible.find((i) => i.activeKey === item.id)?.isCollapsible
            ? " text-white hover:bg-gray-50"
            : "")
        }
        key={item.id}
        onClick={() => {
          onChangeOrderCollapsible(item);
          goToMarker();
        }}
      >
        <List.Item.Meta
          avatar={
            <>
              <DeliveryDashboardGetMarkerImage
                index={index + 1}
                status={item.status}
                width={40}
                height={40}
                className="rounded"
              />
            </>
          }
          title={
            <>
              <div className="flex items-baseline justify-between">
                <h3 className="text-sm font-medium">
                  {item.order_shipping_address}
                </h3>

                <div className="flex-shrink-0 whitespace-nowrap">
                  <Dropdown
                    placement="bottom"
                    arrow
                    dropdownRender={() => (
                      <>
                        <div className="flex items-center p-1 bg-white border divide-x-2 rounded-md">
                          <RoleCheck
                            roles={[Role.SHIPPING_ORDER_MANAGE]}
                            hideOnFail
                          >
                            <>
                              <div className="mr-1">
                                <TableEdit
                                  link=""
                                  onClick={() => onClickEditOrder(item)}
                                />
                              </div>
                              <div>
                                <TableDelete
                                  className="ml-1"
                                  error_translate_prefix="shippingorder:form.error"
                                  onDeleteCallback={(id) => {
                                    //delete order item from store
                                    setOrderItems(
                                      orderItemsByActiveRoute.filter(
                                        (item) => item.id !== id
                                      )
                                    );
                                    //delete order item from provider
                                    deliveryDashboardProvider.setOrderItems(
                                      deliveryDashboardProvider.orderItems.filter(
                                        (item) => item.id !== id
                                      )
                                    );
                                  }}
                                  repository={new ShippingOrderRepository()}
                                  id={item.id}
                                />
                              </div>
                            </>
                          </RoleCheck>
                        </div>
                      </>
                    )}
                  >
                    <Tag
                      color="blue"
                      icon={<IconSettings size={12} />}
                      className="ml-1 cursor-pointer"
                    >
                      ...
                    </Tag>
                  </Dropdown>
                </div>
              </div>
            </>
          }
          description={
            <>
              <div>
                <div>
                  <span>{item.order_customer_fullname}</span> {" - "}
                  <span>{item.order_customer_phone}</span>
                </div>

                <div>
                  <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
                </div>

                {item.id ===
                  orderCollapsible.find((i) => i.activeKey === item.id)
                    ?.activeKey &&
                orderCollapsible.find((i) => i.activeKey === item.id)
                  ?.isCollapsible ? (
                  <div
                    className="mt-4 border-l-4 border-solid"
                    style={{
                      borderColor: ShippingOrderModel.getStatus(item.status)
                        ?.color,
                    }}
                  >
                    <div className="ml-2 text-xs">
                      <div>
                        {"Mã vận đơn: "}
                        <strong>{item.random_code}</strong>
                      </div>
                      <div>
                        {"Loại dịch vụ: "}
                        <strong>
                          {findShippingTypeInfo !== undefined
                            ? findShippingTypeInfo.name
                            : "---"}
                        </strong>
                      </div>
                      <div>
                        {"Quãng đường di chuyển từ điểm trước đó: "}
                        <strong>{distanceFromPreviousPoint} km</strong>
                      </div>
                      <div>
                        {"Tiền COD cần thu: "}
                        <strong>
                          <TextMoney money={item.cod} />
                        </strong>
                      </div>
                      <div>
                        {"Mã đơn hàng: "}
                        <strong>{item.invoice_id}</strong>
                      </div>
                      <div>
                        {"Ghi chú: "}
                        <strong>{item.order_note}</strong>
                      </div>
                      <div>
                        {"Ngày cập nhật: "}
                        <strong>
                          <TextDateTime
                            ts={item.date_modified}
                            format={"HH:mm, DD/MM/YYYY"}
                          />
                        </strong>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          }
        />
      </List.Item>
    </>
  );
};

export default DeliveryDashboardSectionCenterOrderItem;
