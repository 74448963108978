// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_academyHomepage__CTiIJ {
  margin-top: 28px;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyHomePage/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".academyHomepage {\n  margin-top: 28px;\n  max-width: 992px;\n  margin-left: auto;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"academyHomepage": `styles_academyHomepage__CTiIJ`
};
export default ___CSS_LOADER_EXPORT___;
