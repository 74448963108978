import BaseCollection from 'common/collections/BaseCollection';
import ProductVariantSizeModel from 'common/models/ProductVariantSizeModel';
import {
    ProductVariantSizeCollectionJson, ProductVariantSizeJson
} from 'common/types/ProductVariantSize';

class ProductVariantSizeCollection extends BaseCollection<
  ProductVariantSizeJson,
  ProductVariantSizeModel,
  ProductVariantSizeCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductVariantSizeJson[]
  ): ProductVariantSizeModel[] {
    return jsonItems.map((item) => new ProductVariantSizeModel(item));
  }
}

export default ProductVariantSizeCollection;
