import { Tag, Tooltip } from "antd";
import ShippingRouteCollection from "common/collections/ShippingRouteCollection";
import Role from "common/constants/Role";
import ShippingOrder from "common/constants/ShippingOrder";
import ShipperModel from "common/models/ShipperModel";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import ShipperRepository from "common/repositories/ShipperRepository";
import ShippingRouteRepository from "common/repositories/ShippingRouteRepository";
import { IdFetcherResult } from "common/types/IdFetcher";
import { FilterShippingRoute, StatsJson } from "common/types/ShippingRoute";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import ShipperAvatar from "components/ShipperAvatar";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextDateTimeYmd from "components/TextDateTimeYmd";
import TextMoney from "components/TextMoney";
import ShippingRouteFormModal from "features/shippingroute/form/ShippingRouteFormModal";
import ShippingRouteListHeader from "features/shippingroute/list/ShippingRouteListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  IconChecks,
  IconClock,
  IconDeviceDesktop,
  IconGps,
  IconMap2,
  IconRoute,
  IconWeight
} from "@tabler/icons-react";

import ShippingRouteListFilter from "./ShippingRouteListFilter";

const ShippingRouteList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShippingRoute = useMemo(
    () => ShippingRouteRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterShippingRoute>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  // call idfetcher
  const [shippingHub, setShippingHub] = useState<
    IdFetcherResult[] | undefined
  >();

  //////////////////////////////////////////
  // Lazy loaded dynamic
  //shipper
  const [shipperItems, setShipperItems] = useState<ShipperModel[]>([]);
  const [loadingShipper, setLoadingShipper] = useState(true);
  const fetchShipper = useCallback(async () => {
    setLoadingShipper(true);
    const collection = await new ShipperRepository().getItems({
      filters: { ...ShipperRepository.getDefaultFilters(), limit: 100 }
    });
    if (!collection.hasError()) {
      setShipperItems(collection.items);
    }
    setLoadingShipper(false);
  }, []);

  const fetchingShippingHub = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "shippinghub",
      id_list
    });

    let results =
      collection.items.find((i) => i.object_type === "shippinghub")?.result ||
      [];

    results.forEach((result, i) => {
      if (result.text === "[invalid]") {
        results[i].text = "---";
      } else {
        results[i].text = results[i].text.toString();
      }
    });
    setShippingHub(results);
  }, []);

  //////////////////////////////////////////
  // Get distance, duration and weight from stats items
  const getStats = useCallback(
    (stats: StatsJson) => {
      let distance = "---",
        weight = "---";

      // distance
      distance = stats.sum_distance + "km";

      // duration
      let sumDuration = "";
      if (stats.sum_duration > 0) {
        let totalMinutes = stats.sum_duration * 60;
        let hour = Math.floor(totalMinutes / 60);
        let minute = Math.floor(totalMinutes % 60);

        if (hour > 0) {
          sumDuration += hour + t("shippingroute:duration_hour");
        }

        if (minute > 0) {
          if (sumDuration !== "") {
            sumDuration += "";
          }
          sumDuration += minute + "" + t("shippingroute:duration_minute");
        }
      }

      // weight
      weight = Helper.numberFormat(stats.sum_order_weight / 1000) + "kg";

      return {
        distance: distance,
        duration: sumDuration,
        weight: weight
      };
    },
    [t]
  );

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ShippingRouteModel[]>([]);
  const fetchData = useCallback(async (): Promise<ShippingRouteCollection> => {
    let collection = await new ShippingRouteRepository().getItems({
      filters
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    let shippingHubIdList = collection.items
      .map((item) =>
        item.from_type === ShippingOrder.FROM_TYPE_OFFICE
          ? item.from_warehouse_id
          : 0
      )
      .filter((i) => i > 0 && i !== undefined);
    if (shippingHubIdList.length > 0) {
      fetchingShippingHub(shippingHubIdList);
    }

    return collection;
  }, [filters, fetchingShippingHub]);

  //////////////////////////////////////////
  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ShippingRouteModel> = [
    {
      title: " ",
      togglelabel: t("shippingroute:shipper"),
      key: "shipper_id",
      width: 50,
      align: "center",
      render: (_: any, record: ShippingRouteModel) => {
        let shipperInfo = shipperItems.find((i) => i.id === record.shipper_id);
        return <ShipperAvatar shipper={shipperInfo} />;
      }
    },
    {
      title: t("shippingroute:code"),
      key: "code",
      width: 150,
      render: (code, record) => {
        return (
          <>
            <RoleCheck
              roles={[Role.SHIPPING_ROUTE_MANAGE]}
              componentOnFail={<>{code}</>}>
              <TableLinkText onClick={() => onEdit(record.id)}>
                {code}
              </TableLinkText>
            </RoleCheck>
          </>
        );
      }
    },
    {
      title: t("shippingroute:ymd"),
      key: "ymd",
      width: 100,
      render: (_: any, record: ShippingRouteModel) => (
        <TextDateTimeYmd
          ts={record.ymd}
          format={"YYYYMMDD"}
          dateFormat={"DD/MM/YYYY"}
        />
      )
    },
    {
      title: t("shippingroute:from_place"),
      key: "from_warehouse_id",
      width: 180,
      render: (_: any, record: ShippingRouteModel) => {
        return (
          <div>
            <IdFetcherRender
              value={record.from_warehouse_id}
              mapping={shippingHub}
            />
            <div className="mt-1 text-xs text-gray-400 hover:text-gray-800">
              <Tooltip title={t("shippingroute:weight")}>
                <IconWeight size={16} className="mr-1 -mt-0.5" />
                {getStats(record.stats).weight}
              </Tooltip>
              <Tooltip title={t("shippingroute:duration")}>
                <IconClock size={16} className="ml-2 mr-1 -mt-0.5" />
                {getStats(record.stats).duration}
              </Tooltip>
              <Tooltip title={t("shippingroute:distance")}>
                <IconMap2 size={16} className="ml-2 mr-1 -mt-0.5" />
                {getStats(record.stats).distance}
              </Tooltip>
            </div>
          </div>
        );
      }
    },
    {
      title: t("shippingroute:count_success"),
      key: "count_success",
      align: "right",
      width: 120,
      render: (_: any, record: ShippingRouteModel) => (
        <>
          {record.count_success} /{" "}
          <>
            {record.count_total > 0 ? (
              <Link
                to={"/shippingorder?route_id=" + record.id}
                className="text-blue-500"
                // target="_blank"
              >
                {record.count_total + " " + t("shippingroute:order_unit")}
              </Link>
            ) : (
              <>{record.count_total + " " + t("shippingroute:order_unit")}</>
            )}
          </>
        </>
      )
    },
    {
      title: t("shippingroute:count_fail"),
      key: "count_fail",
      width: 75,
      align: "center",
      render: (_: any, record: ShippingRouteModel) => (
        <span className="text-red-5 00">
          {record.count_fail > 0
            ? record.count_fail + " " + t("shippingroute:order_unit")
            : "-"}
        </span>
      )
    },
    {
      title: t("shippingroute:cod_total"),
      key: "cod_total",
      width: 110,
      align: "right",
      render: (_: any, record: ShippingRouteModel) => (
        <>
          <Tooltip title={t("shippingroute:cod_success")}>
            <span className="text-xs text-green-600">
              <IconChecks size={14} /> <TextMoney money={record.cod_success} />
            </span>{" "}
          </Tooltip>{" "}
          <br />
          <Tooltip title={t("shippingroute:cod_total")}>
            <span>
              <TextMoney money={record.cod_total} />
            </span>
          </Tooltip>
        </>
      )
    },
    {
      title: t("common:status"),
      key: "status",
      width: 120,
      render: (status: number) => {
        let statusInfo = ShippingRouteModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      }
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      toggletype: "extend",
      width: 80,
      render: (ts: number) => {
        return <TextDateTime ts={ts} format="HH:mm, DD/MM" />;
      }
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 155,
      render: (_: any, record: ShippingRouteModel) => (
        <>
          <RoleCheck roles={[Role.SHIPPING_ROUTE_MANAGE]} hideOnFail>
            <TableEdit link="" onClick={() => onEdit(record.id)} />
          </RoleCheck>
          <RoleCheck roles={[Role.DASHBOARD_VIEW]} hideOnFail>
            <TableEdit
              link={"/deliverydashboard?id=" + record.id}
              label=""
              tooltip={t("shippingroute:dashboard")}
              icon={<IconDeviceDesktop size={14} color={"blue"} />}
            />
          </RoleCheck>
          <RoleCheck roles={[Role.SHIPPING_ROUTE_MANAGE]} hideOnFail>
            <TableDelete
              error_translate_prefix="shippingroute:form.error"
              onDeleteCallback={(id) => {
                setDataSource(dataSource.filter((item) => item.id !== id));
              }}
              repository={new ShippingRouteRepository()}
              id={record.id}
            />
          </RoleCheck>
          <TableInfo record={record}></TableInfo>
        </>
      )
    }
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: ShippingRouteModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item
          }
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $push: [item]
        })
      );
    }
  };

  useEffect(() => {
    if (loadingShipper) {
      fetchShipper();
    }
  }, [loadingShipper, fetchShipper]);

  return (
    <>
      <ShippingRouteListHeader>
        <RoleCheck roles={[Role.SHIPPER_MANAGE_JOB]}>
          <PageHeaderButton
            className="mr-2"
            link="/deliveryplanning"
            icon={<IconRoute className="mr-1 -mt-0.5" size={18} />}>
            {t("shippingroute:delivery_planning_button")}
          </PageHeaderButton>
        </RoleCheck>

        <RoleCheck roles={[Role.DASHBOARD_VIEW]}>
          <PageHeaderButton
            link="/deliverydashboard"
            icon={<IconGps className="mr-1 -mt-0.5" size={18} />}>
            {t("shippingroute:delivery_dashboard_button")}
          </PageHeaderButton>
        </RoleCheck>
      </ShippingRouteListHeader>

      <ShippingRouteListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<
        FilterShippingRoute,
        ShippingRouteModel,
        ShippingRouteCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "shippingroute"
        }}></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <ShippingRouteFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ShippingRouteList;
