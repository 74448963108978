import React, { useState } from "react";
import WorkChat from "./workchatcenter/WorkChat";
import WorkChatThread from "./workchatright/WorkChatThread";
import WorkChatChannelList from "./workchatleft/WorkChatChannelList";

const WorkChatContainer = () => {
  const [isThreadVisible, setIsThreadVisible] = useState(true);

  const handleThreadVisibility = () => {
    setIsThreadVisible(!isThreadVisible);
  };

  return (
    <div className="flex w-full h-[92vh] text-gray-700">
      <WorkChatChannelList />
      <WorkChat />
      {isThreadVisible && <WorkChatThread onClick={handleThreadVisibility} />}
    </div>
  );
};

export default WorkChatContainer;
