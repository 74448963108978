import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Divider, Typography } from "antd";
import UserForgotPassForm from "features/user/UserForgotPassForm";

const UserForgotPassPage = () => {
  const { t } = useTranslation();

  return (
    <div className="p-6 mt-4 mx-auto max-w-md">
      <Typography.Title level={3}>
        {t("user:heading_forgotpass")}
      </Typography.Title>
      <p>{t("user:subtitle_forgotpass")}</p>

      <UserForgotPassForm />

      <p>{t("user:form.forgotpass_help_text1")}</p>
      <Divider />
      <Link to="/user/login">
        <Button type="link">
          &laquo; {t("user:form.forgotpass_help_text2")}
        </Button>
      </Link>
    </div>
  );
};

export default UserForgotPassPage;
