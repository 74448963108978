
import { message } from "antd";
import { Button } from "antd";
import { Col, Form, Input, Row } from "antd";
import { DataZoneBD, ZoneDataBanner } from "common/types/Event";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";
import { useForm } from "antd/es/form/Form";

const SectionBannerDB = ({
	data,
	onSubmit
}: {
	data: ZoneDataBanner;
	onSubmit: (data: DataZoneBD) => void;

}) => {
	const { t } = useTranslation();
	const [imgMobile, setImgMobile] = useState(data.image_mobile);
	const [imgDesktop, setImgDesktop] = useState(data.image_desktop);

	const [form] = useForm()


	const init = data
	const onFinish = (values: any) => {
		onSubmit(values)
	};


	return (

		<Form
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			form={form}
			name="dynamic_form_products"
			autoComplete="off"
			initialValues={{ items: init }}
			layout="vertical"
			onFinish={onFinish}
		>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item
						initialValue={data.url}
						label={"Đường dẫn banner"}
						name="url"
						rules={[
							{
								required: true,
								message: t("template:form.error.error_fullname_required"),
							},
						]}>
						<Input autoFocus />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item
													labelCol={{ span: 24 }}

								name="image_desktop"
								initialValue={data.image_desktop}
								className="min-h-[150px]  w-full"
								label="Ảnh banner desktop">
								<UploadEvent
									defaultUrl={imgDesktop}
									height={150}
									onSuccess={(url) => {
										message.success("Thay đổi thành công");
										form.setFieldValue("image_desktop", url);
										setImgDesktop(url);
									}}></UploadEvent>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
													labelCol={{ span: 24 }}
							
								name="image_mobile"
								initialValue={data.image_mobile}
								label="Ảnh banner mobile"
								className="min-h-[150px]">
								<UploadEvent
									defaultUrl={imgMobile}
									height={150}
									width={150}
									onSuccess={(url) => {
										message.success("Thay đổi thành công");
										form.setFieldValue("image_mobile", url);
										setImgMobile(url);
									}}></UploadEvent>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>

			<Button className="  absolute bottom-10 right-4 w-[100px]" type="primary" htmlType="submit">
				Lưu
			</Button>
		</Form>
	);
};

export default SectionBannerDB;

