const Employee = {
  STATUS_UNACTIVATED: 0,
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,
  STATUS_DELETED: 5,
  STATUS_DISABLED_CUSTOMER: 0,
  GENDER_UNKNOWN: 0,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2,

  POSITION_INTERNSHIP: 100,
  POSITION_STAFF: 0,
  POSITION_GROUP_LEAD: 60,
  POSITION_STORE_ASSISTANT_MANAGER: 51,
  POSITION_ASSISTANT_MANAGER: 50,
  POSITION_STORE_MANAGER: 11,
  POSITION_MANAGER: 10,
  POSITION_SUBDIRECTOR: 7,
  POSITION_DIRECTOR: 5,
  POSITION_CEO: 4,
  POSITION_SUBCHAIRMAN: 2,
  POSITION_CHAIRMAN: 1,

  TYPE_FULLTIME: 0,
  TYPE_PARTTIME: 1,
  TYPE_PREFLIGHT: 3,
  TYPE_OVERTIME: 5,
  TYPE_SEASONAL: 7,
  TYPE_PROJECT: 9,

  CHECKIN_TYPE_IGNORE: 1,
  CHECKIN_TYPE_OFFICE: 3,
  CHECKIN_TYPE_SCHEDULE: 5,

  MARITAL_STATUS_SINGLE: 0,
  MARITAL_STATUS_MARRIED: 1,
};

export default Employee;
