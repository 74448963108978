import Error from 'common/api/Error';
import { arrayToTree, Item } from 'performant-array-to-tree';

class NestedCollection<MJsonWithChildren extends Item> {
  items: MJsonWithChildren[];
  total: number;
  error: Error;

  constructor(items?: MJsonWithChildren[]) {
    this.items = items || [];
    this.total = items?.length || 0;
    this.error = new Error({ statusCode: 0, errors: [] });
  }

  buildNested(items: MJsonWithChildren[]) {
    this.total = items.length;
    this.items = arrayToTree(items, {
      parentId: "parent_id",
      dataField: null,
      rootParentIds: { "0": true },
    }) as MJsonWithChildren[];
  }

  withError(error: Error) {
    this.items = [];
    this.total = 0;
    this.error = error;
  }

  hasError() {
    return (
      this.error != null &&
      this.error.errors != null &&
      this.error.errors.length > 0
    );
  }
}

export default NestedCollection;
