import { Col, Form, Input, Row } from "antd";
import File from "common/constants/File";
import FileInput from "components/form/fileinput/FileInput";
import SimpleCollapse from "components/SimpleCollapse";
import { useTranslation } from "react-i18next";

const ShippingOrderFormSectionShipper = () => {
  const { t } = useTranslation();

  return (
    <>
      <SimpleCollapse
        header={t("shippingorder:orderform_heading_shipper")}
        defaultOpen={false}
        className="mb-4"
        childrenItem={
          <Row gutter={32}>
            <Col xs={24} md={8} lg={8}>
              <Form.Item
                label={t("shippingorder:shipper_note")}
                name="shipper_note">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} md={16} lg={16}>
              <Form.Item
                name="graph_image_input"
                label={t("file:select_file_image")}>
                <FileInput
                  objectType={File.OBJECTTYPE_ATTACHMENT}
                  origin="dms"
                  max_item={4}
                  allowExtensions={["pdf", "png", "jpeg"]}
                />
              </Form.Item>
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default ShippingOrderFormSectionShipper;
