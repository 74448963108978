import Role from 'common/constants/Role';
import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import RoleCheck from 'components/RoleCheck';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShipperListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("shipper:heading_list")}
      siteMenuSelectedKey="/shipper"
    >
      <RoleCheck roles={[Role.SHIPPER_MANAGE]}>
        <PageHeaderButton link="/shipper/add">
          {t("shipper:add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default ShipperListHeader;
