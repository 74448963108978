import {
	Button,
	Col,
	DatePicker,
	Form,
	Image,
	Input,
	message,
	Row,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { ZoneDataSubBanner } from "common/types/Event";
import FormSection from "components/form/FormSection";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadEvent from "./UploadEvent";
import dayjs from "dayjs";
import { RangeValue } from "components/datetime/DateFormFilter";
import Helper from "common/utils/helper";

export default function SectionSubbanner({
	data,
	form,
}: {
	data: ZoneDataSubBanner[];
	form: FormInstance<any>;
}) {
	const { RangePicker } = DatePicker;
	const dateFormat = "HH:mm:ss DD/MM/YYYY";

	const { t } = useTranslation();

	const [imgs, setImgs] = useState({ img1: "", img2: "", img3: "" });
	return (
		<div>
			<FormSection
				title={t("Thông banner blog")}
				subtitle={t("Cài đặt 3 bannner blog")}
				leftColumnSize={6}
				divider>
				<Row gutter={16}>
					<Form.List name={"sub_banner"} initialValue={data}>
						{(fileds, { add, remove }) => (
							<Row gutter={[4, 4]}>
								{fileds.map((field, index) => {
									return (
										<Col key={index} span={8}>
											<Form.Item name={[field.name, "url"]}>
												<UploadEvent
													height={120}
													defaultUrl={data?.[index]?.img || ""}
													onSuccess={(url) => {
														const key = field.key;
														let data = form.getFieldValue("sub_banner");
														data[key] = { ...data[key], ["img"]: url };
														form.setFieldValue("sub_banner", data);
													}}></UploadEvent>
											</Form.Item>
											<Form.Item
												name={[field.name, "url"]}
												initialValue={data?.[index]?.url}>
												<Input size="small"></Input>
											</Form.Item>
											<Form.Item
												className="mb-2"
												// name={[field.name, "dates"]}
												initialValue={[
													dayjs(data?.[index]?.from_time * 1000 || Date.now()),
													dayjs(data?.[index]?.to_time * 1000 || Date.now()),
												]}
												rules={[
													{
														type: "array" as const,
														required: true,
														message: "Yêu cầu nhập!",
													},
												]}>
												<RangePicker
													inputReadOnly
													onChange={(
														dates: RangeValue,
														formats: [string, string]
													) => {
														const key = field.key;

														let dateFrom = data?.[index]?.from_time || 0;
														let dateTo = data?.[index]?.to_time || 0;

														if (dates?.[0]) {
															dateFrom = Helper.datePickerValueToTimestamp(
																dates[0]
															);
														}
														if (dates?.[1]) {
															dateTo = Helper.datePickerValueToTimestamp(
																dates[1]
															);
														}
														let datas = form.getFieldValue("sub_banner");
														datas[key] = {
															...datas[key],
															["from_time"]: dateFrom,
															["to_time"]: dateTo,
														};
														form.setFieldValue("sub_banner", datas);
													}}
													format={dateFormat}
													placeholder={["bắt đầu", "kết thúc"]}
													allowClear={false}
													defaultValue={[
														dayjs(
															data?.[index]?.from_time * 1000 || Date.now()
														),
														dayjs(data?.[index]?.to_time * 1000 || Date.now()),
													]}
													showTime={{
														showNow: true,
														format: "HH:mm:ss",
													}}
												/>
											</Form.Item>
										</Col>
									);
								})}
								<Col span={24}>
									<Button
										onClick={() => {
											add({
												from_time: "",
												to_time: "",
												img: "",
												url: "",
											});
										}}>
										ADD
									</Button>
								</Col>
							</Row>
						)}
					</Form.List>
					{/* {data.map((i, index) => {
						return (
							<Col key={index} span={8}>
								<Form.Item name={} label={`Ảnh ${index + 1}`}>
									<UploadEvent
										height={120}
										defaultUrl={i.img}
										onSuccess={(url) => {
											message.success("Thay đổi thành công");
											setImgs((prev) => {
												return { ...prev, [`img${index + 1}`]: "" };
											});
										}}></UploadEvent>
								</Form.Item>
							</Col>
						);
					})} */}
				</Row>
			</FormSection>
		</div>
	);
}
