import PageDataTable from "components/page/PageDataTable";
import React, { useCallback, useMemo, useState } from "react";
import PromotionCouponModel from "common/models/PromotionCouponModel";
import { FilterPromotionCoupon } from "common/types/PromotionCoupon";
import { PromotionCouponCollectionJson } from "common/types/PromotionCoupon";
import PromotionCouponRepository from "common/repositories/PromotionCouponRepository";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import PageDataPagination from "components/page/PageDataPagination";
import {
	Dropdown,
	message,
	Modal,
	Statistic,
	TableColumnsType,
	Tag,
	Typography,
} from "antd";
import PromotionCouponCollection from "common/collections/PromotionCouponCollection";
import usePathParams from "hooks/usePathParams";
import TextDateTime from "components/TextDateTime";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import Role from "common/constants/Role";
import PromotionListHeader from "./PromotionListHeader";
import CouponListHeader from "./CouponListHeader";
import TableDelete from "components/table/TableDelete";
import PageHeaderButton from "components/page/PageHeaderButton";
import { useTranslation } from "react-i18next";
import CouponForm from "../form/CouponForm";
import { MoreOutlined } from "@ant-design/icons";
import TableInfo from "components/table/TableInfo";
import { MenuProps } from "antd/lib";

type Props = {
	promotionId?: number;
};
export default function CouponList({ promotionId }: Props) {
	const [pathParams] = usePathParams();
	const { t } = useTranslation();
	const [messageApi, context] = message.useMessage();
	const id = promotionId ?? pathParams.id ?? 0;
	const defaultFilters: FilterPromotionCoupon = useMemo(
		() => ({
			...PromotionCouponRepository.getDefaultFilters(),
			promotion_id: Number(id),
		}),
		[pathParams.id]
	);
	const [filters, setFilters] =
		useStateFilter<FilterPromotionCoupon>(defaultFilters);

	const [openAdd, setOpenAdd] = useState(false);
	// useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [total, setTotal] = useState(0);
	const [dataSource, setDataSource] = useState<PromotionCouponModel[]>([]);
	const fetchData =
		useCallback(async (): Promise<PromotionCouponCollection> => {
			let collection = await new PromotionCouponRepository().getItems({
				filters,
			});
			setDataSource(collection.items);
			setTotal(collection.total);

			return collection;
		}, [filters]);

	const onDelete = async (id: number) => {
		// const res = await new PromotionCouponRepository().deleteItem(id);
		// if (!res.length > 0) {
		// 	messageApi.success("Xóa thành công");
		// } else {
		// 	messageApi.warning("Xóa thất bại");
		// }
	};

	const onEdit = (record: PromotionCouponModel) => {};

	const columns: TableColumnsType<PromotionCouponModel> = [
		{
			title: "Mã code",
			key: "code",
			render: (code, record) => {
				return (
					<Tag color="blue">
						<Typography.Text copyable>{code}</Typography.Text>
					</Tag>
				);
			},
		},

		{
			title: "Số lần dùng",
			key: "count_usage",
			width: 120,

			render: (count_usage, record) => {
				return <>{count_usage}</>;
			},
		},
		{
			title: t("promotion:promotioncoupon.max_count_apply"),
			dataIndex: "max_count_apply",
			key: "max_count_apply",
			width: 180,
			render: (max_count_apply: number) => {
				if (max_count_apply > 0) {
					return <>{max_count_apply}</>;
				} else {
					return <>{t("promotion:promotioncoupon.unlimit")}</>;
				}
			},
		},

		{
			title: "Tình trạng",
			key: "status",
			render: (_, record) => {
				const status = record.status;
				let color = "default";
				let value = "";
				switch (status) {
					case 1:
						value = "Đã phát hành";
						color = "green";
						break;
					case 2:
						value = "Chưa phát hành";
						color = "blue";

						break;
					case 3:
						value = "Ẩn";
						color = "warning";

						break;
					case 4:
						color = "gray";

						value = "Đã sử dụng";

						break;
					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 150,
			render: (_: any, record: PromotionCouponModel) => {
				const items: MenuProps["items"] = [
					{
						key: "1",
						label: <></>,
					},
				];

				return (
					<RoleCheck roles={[Role.PROMOTION_MANAGE]} hideOnFail>
						{/* <Dropdown trigger={["click"]} menu={{ items }}>
				<MoreOutlined
					style={{ fontSize: "16pt" }}
					className="cursor-pointer "
				/>
			</Dropdown> */}
						{/* <TableEdit link="" onClick={() => onEdit(record)} /> */}
						<div className="flex justify-end">
							{/* <TableEdit link=""></TableEdit> */}
							<TableDelete
								onDeleteCallback={onDelete}
								id={record.id}
								repository={new PromotionCouponRepository()}></TableDelete>
							<TableInfo record={record} className="mr-4" />
						</div>
					</RoleCheck>
				);
			},
		},
	];

	return (
		<div>
			{context}
			<CouponListHeader>
				<RoleCheck roles={[]}>
					<PageHeaderButton
						type="primary"
						link=""
						onClick={() => {
							setOpenAdd(true);
						}}>
						{t("promotion:add_button")}
					</PageHeaderButton>
				</RoleCheck>
			</CouponListHeader>
			<PageDataTable<
				FilterPromotionCoupon,
				PromotionCouponModel,
				PromotionCouponCollection
			>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "promotion",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
			<Modal
				okButtonProps={{ className: "hidden" }}
				title="Tạo mới các mã coupon"
				open={openAdd}
				onCancel={() => {
					setOpenAdd((prev) => !prev);
				}}>
				<CouponForm
					onFinish={() => {
						fetchData();
						setOpenAdd(false);
					}}
					idEditing={Number(id)}
					initialValue={{ codes: [] }}></CouponForm>
			</Modal>
		</div>
	);
}
