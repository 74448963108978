import { Popover } from 'antd';
import { InventoryStock, InventoryStockNumberType } from 'common/types/InventoryStock';
import { SearchResultItem } from 'common/types/Pos';
import { findIndex } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import InventoryBySkuCellPopover from './InventoryBySkuCellPopover';

const InventoryBySkuCell = ({
  data,
  activeWarehouseId,
  type,
}: {
  data: SearchResultItem;
  activeWarehouseId: number;
  type: InventoryStockNumberType;
}) => {
  const [showPopover, setShowPopover] = useState(false);

  const [activeInventoryStock, setActiveInventoryStock] =
    useState<InventoryStock>();

  const displayStockNumber = useCallback(
    (inventoryStock: InventoryStock) => {
      if (type === "onhand") {
        return inventoryStock.onhand;
      } else if (type === "allocated") {
        return inventoryStock.allocated;
      } else if (type === "incoming") {
        return inventoryStock.incoming;
      } else {
        return inventoryStock.available;
      }
    },
    [type]
  );

  useEffect(() => {
    const foundIndex: number = findIndex(data.inventory_details, {
      warehouse_id: activeWarehouseId,
    });

    if (foundIndex >= 0) {
      setActiveInventoryStock(data.inventory_details[foundIndex]);
    } else {
      setActiveInventoryStock(undefined);
    }
  }, [data, activeWarehouseId]);

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Popover
        overlayInnerStyle={{ width: 460 }}
        open={showPopover}
        onOpenChange={(open) => setShowPopover(open)}
        overlayClassName="pos-inventory-popover"
        className="pos-inventory-popover"
        placement="right"
        content={
          <InventoryBySkuCellPopover
            items={data.inventory_details}
            activeItem={activeInventoryStock}
            activeWarehouseId={activeWarehouseId}
            type={type}
          />
        }
        title={
          <span>
            {data.product_name} / {data.sku}
          </span>
        }
        trigger="click"
      >
        <span
          className="cursor-pointer hover:text-blue-400 text-blue-700"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {typeof activeInventoryStock !== "undefined" &&
          activeInventoryStock.warehouse_id >= 0
            ? displayStockNumber(activeInventoryStock)
            : "-"}
        </span>
      </Popover>
    </span>
  );
};

export default InventoryBySkuCell;
