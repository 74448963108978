import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";
import { ReportDeliveryJson } from "common/types/ReportDelivery";

class ReportDeliveryModel
  extends BaseModel
  implements BaseModelJson<ReportDeliveryJson>
{
  company_id: number;
  sale_order_id: number;
  id: number;
  code: string;
  date_created: number;
  date_completed: number;
  full_name: string;
  office: string;
  quantity: number;
  cost_total: number;
  cod: number;
  cost_on_transfer: number;
  cost_note_transfer: number;
  cotips: number;
  receive_cod: number;
  return_cod: number;
  contact_address: string;
  kpi_shiper: string;
  note: string;
  hastips: number;
  return_tips: number;
  cod_total: number;
  date_delivered: number;
  date_return_cod: number;
  date_return_tips: number;

  constructor(json: ReportDeliveryJson) {
    super();
    // this.stt = json.stt || 0;
    this.company_id = json.company_id || 0;
    this.sale_order_id = json.sale_order_id || 0;
    this.id = json.id || 0;
    this.code = json.code || "";
    this.date_created = json.date_created || 0;
    this.date_completed = json.date_completed || 0;
    this.full_name = json.full_name || "";
    this.office = json.office || "";
    this.quantity = json.quantity || 0;
    this.cost_total = json.cost_total || 0;
    this.cod = json.cod || 0;
    this.cost_on_transfer = json.cost_on_transfer || 0;
    this.cost_note_transfer = json.cost_note_transfer || 0;
    this.cotips = json.cotips || 0;
    this.receive_cod = json.receive_cod || 0;
    this.return_cod = json.return_cod || 0;
    this.contact_address = json.contact_address || "";
    this.kpi_shiper = json.kpi_shiper || "";
    this.note = json.note || "";
    this.hastips = json.hastips || 0;
    this.return_tips = json.return_tips || 0;
    this.cod_total = json.cod_total || 0;
    this.date_delivered = json.date_delivered || 0;
    this.date_return_cod = json.date_return_cod || 0;
    this.date_return_tips = json.date_return_tips || 0;
  }

  static getDefaultData(): ReportDeliveryJson {
    return {
      company_id: 0,
      sale_order_id: 0,
      id: 0,
      code: "",
      date_created: 0,
      date_completed: 0,
      full_name: "",
      office: "",
      quantity: 0,
      cost_total: 0,
      cod: 0,
      cost_on_transfer: 0,
      cost_note_transfer: 0,
      cotips: 0,
      receive_cod: 0,
      return_cod: 0,
      contact_address: "",
      kpi_shiper: "",
      note: "",
      hastips: 0,
      return_tips: 0,
      cod_total: 0,
      date_delivered: 0,
      date_return_cod: 0,
      date_return_tips: 0,
    };
  }

  toJson(): ReportDeliveryJson {
    return {
      company_id: this.company_id,
      sale_order_id: this.sale_order_id,
      id: this.id,
      code: this.code,
      date_created: this.date_created,
      date_completed: this.date_completed,
      full_name: this.full_name,
      office: this.office,
      quantity: this.quantity,
      cost_total: this.cost_total,
      cod: this.cod,
      cost_on_transfer: this.cost_on_transfer,
      cost_note_transfer: this.cost_note_transfer,
      cotips: this.cotips,
      receive_cod: this.receive_cod,
      return_cod: this.return_cod,
      contact_address: this.contact_address,
      kpi_shiper: this.kpi_shiper,
      note: this.note,
      hastips: this.hastips,
      return_tips: this.return_tips,
      cod_total: this.cod_total,
      date_delivered: this.date_delivered,
      date_return_cod: this.date_return_cod,
      date_return_tips: this.date_return_tips,
    };
  }
}

export default ReportDeliveryModel;
