import AccountModel from "common/models/AccountModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import { PasswordUpdateJson, ProfileUpdateJson } from "common/types/Account";
import AuthorizedCompanyModel from "common/models/AuthorizedCompanyModel";

const SERVICE_URL = "/users";

class AccountApi extends BaseApi {
  async login(
    username: string,
    password: string,
    hostname: string
  ): Promise<AccountModel> {
    let account = new AccountModel(AccountModel.getDefaultData());

    try {
      let postData = {};
      if (username.includes("@")) {
        postData = {
          email: username,
          password: password,
          hostname: hostname,
          platform: 1,
          version: "1.0.0",
          no_cache: 1,
        };
      } else {
        postData = {
          internalid: username,
          password: password,
          hostname: hostname,
          platform: 1,
          version: "1.0.0",
          no_cache: 1,
        };
      }

      const response = await AxiosClient().post(
        SERVICE_URL + "/login",
        postData
      );

      if ("data" in response) {
        account = new AccountModel(
          AccountModel.parseLoginResponse(response.data)
        );
      }
    } catch (error) {
      account.withError(BaseApi.handleError(error));
    }

    return account;
  }

  async getAuthorizedCompany(): Promise<AuthorizedCompanyModel> {
    let account = new AuthorizedCompanyModel(
      AuthorizedCompanyModel.getDefaultData()
    );

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/authorizedcompany"
      );
      if ("data" in response) {
        account = new AuthorizedCompanyModel(response.data);
      }
    } catch (error) {
      account.withError(BaseApi.handleError(error));
    }

    return account;
  }

  async logout(): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/logout");
      if (response.status !== 200) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async edit(data: ProfileUpdateJson): Promise<AccountModel> {
    let item = new AccountModel(AccountModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new AccountModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async forgotpassword(username: string, hostname: string): Promise<string[]> {
    let resultErrors = [];

    try {
      let postData = {
        email: username,
        hostname: hostname,
      };

      const response = await AxiosClient().post(
        SERVICE_URL + "/forgotpassword",
        postData
      );
      if (response.status !== 200) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async resetpassword(
    email: string,
    verification_code: string,
    new_password: string,
    hostname: string
  ): Promise<string[]> {
    let resultErrors = [];

    try {
      let postData = {
        email: email,
        code: verification_code,
        password: new_password,
        password2: new_password,
        hostname: hostname,
      };

      const response = await AxiosClient().post(
        SERVICE_URL + "/resetpassword",
        postData
      );
      if (response.status !== 200) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async editpassword(data: PasswordUpdateJson): Promise<string[]> {
    let errors: string[] = [];

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/changepassword/" + data.id,
        postData
      );
      if (!response.hasOwnProperty("data")) {
        errors.push("error_data_not_found");
      }
    } catch (error) {
      errors = BaseApi.handleError(error).errors;
    }

    return errors;
  }
}

export default AccountApi;
