import { List } from "antd";
import ShippingOrder from "common/constants/ShippingOrder";
import { GoongMapCoordinates } from "common/types/goongmap/GoongMapType";
import eventEmitter from "common/utils/eventEmitter";
import OficeGrey from "images/mapmarkers/office_grey.png";
import React from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

const DeliveryDashboardSectionCenterPickupList = () => {
  const [activeRoute, hubItemByActiveRoute] = useDeliveryDashboardStore(
    (state) => [state.activeRoute, state.hubItemByActiveRoute]
  );

  const goToMarker = () => {
    let coordinates: GoongMapCoordinates = {
      latitude: 0,
      longitude: 0,
    };
    if (
      activeRoute?.from_type === ShippingOrder.FROM_TYPE_OFFICE &&
      hubItemByActiveRoute &&
      hubItemByActiveRoute.lat > 0 &&
      hubItemByActiveRoute.long > 0
    ) {
      coordinates.latitude = hubItemByActiveRoute.lat;
      coordinates.longitude = hubItemByActiveRoute.long;
    }
    eventEmitter.emit("DASHBOARD_GOONGMAP_GOTOMARKER", coordinates);
  };

  return (
    <>
      <List className="bg-gray-200 cursor-pointer">
        <List.Item onClick={goToMarker}>
          <List.Item.Meta
            style={{ marginBottom: 0 }}
            avatar={
              <>
                <div
                  style={{
                    position: "relative",
                    width: 40,
                    height: 30,
                    float: "left",
                  }}
                >
                  <img
                    style={{ margin: "0 auto", maxWidth: "100%", height: 30 }}
                    src={OficeGrey}
                    alt=""
                  />
                </div>
              </>
            }
            title={
              <>
                <div className="text-sm font-medium">
                  <strong className="text-gray-900">{"Bưu cục: "}</strong>
                  <span className="text-sm text-gray-600 font-extralight">
                    {hubItemByActiveRoute !== undefined
                      ? hubItemByActiveRoute.name
                      : ""}
                  </span>
                </div>
              </>
            }
          />
        </List.Item>
      </List>
    </>
  );
};

export default DeliveryDashboardSectionCenterPickupList;
