import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import Promotion from "common/constants/Promotion";
import PromotionModel from "common/models/PromotionModel";
import FormSelect from "components/form/FormSelect";
import MoneyInput from "components/MoneyInput";
import React from "react";
import { useTranslation } from "react-i18next";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconX } from "@tabler/icons-react";
import { OrderProductJson } from "common/types/Delivery";
import { MegaMenuTextDetailJson } from "common/types/ShopMegaMenu";

type Props = {
  name: string;
};

const CollectionParamDetailForm = ({ name }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Row gutter={16}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      {...restField}
                      label={
                        <span className="flex items-center">
                          {t("Name ")}
                          {name + 1}
                          <IconX
                            onClick={() => remove(name)}
                            className="p-1 cursor-pointer hover:text-red-500"
                          />
                        </span>
                      }
                      name={[name, "name"]}
                      rules={[
                        {
                          required: true,
                          message: t("Bạn chưa nhập thông tin"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={12} xs={24}>
                    <Form.Item
                      {...restField}
                      label={
                        <span>
                          {t("Value ")}
                          {name + 1}
                        </span>
                      }
                      name={[name, "value"]}
                      rules={[
                        {
                          required: false,
                          message: t("Bạn chưa nhập thông tin"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
            {fields.length < 20 ? (
              <Row gutter={16}>
                <Col md={11}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
          </>
        )}
      </Form.List>
    </>
  );
};

export default CollectionParamDetailForm;
