import FileStatsModel from 'common/models/FileStatsModel';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

const SERVICE_URL = "/filehelpers";

class FileHelperApi extends BaseApi {
  async getStats(): Promise<FileStatsModel> {
    let item = new FileStatsModel(FileStatsModel.getDefaultData());

    try {
      let queryData = {
        params: {},
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/stats",
        queryData
      );
      if (
        response.hasOwnProperty("data") &&
        response.data.hasOwnProperty("company_id")
      ) {
        item = new FileStatsModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default FileHelperApi;
