import BaseModel from "./BaseModel";

import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { ReportLeaveJson } from "common/types/ReportLeave";

class ReportLeaveModel
  extends BaseModel
  implements BaseModelJson<ReportLeaveJson>
{
  company_id: number;
  date_created: number;
  date_modified: number;
  department_id: number;
  department_name: string;
  employee_id: number;
  employee_name: string;
  id: number;
  insurance_leave: number;
  internalid: string;
  jobtitle: string;
  month: number;
  office_id: number;
  office_name: string;
  paid_leave: number;
  sum: number;
  unpaid_leave: number;
  year: number;

  constructor(json: ReportLeaveJson) {
    super();
    this.company_id = json.company_id || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.department_id = json.department_id || 0;
    this.department_name = json.department_name || "";
    this.employee_id = json.employee_id || 0;
    this.employee_name = json.employee_name || "";
    this.id = json.id || 0;
    this.insurance_leave = json.insurance_leave || 0;
    this.internalid = json.internalid || "";
    this.jobtitle = json.jobtitle || "";
    this.month = json.month || 0;
    this.office_id = json.office_id || 0;
    this.office_name = json.office_name || "";
    this.paid_leave = json.paid_leave;
    this.sum = json.sum || 0;
    this.unpaid_leave = json.unpaid_leave || 0;
    this.year = json.year;
  }

  static getDefaultData(): ReportLeaveJson {
    return {
      company_id: 0,
      date_created: 0,
      date_modified: 0,
      department_id: 0,
      department_name: "",
      employee_id: 0,
      employee_name: "",
      id: 0,
      insurance_leave: 0,
      internalid: "",
      jobtitle: "",
      month: 0,
      office_id: 0,
      office_name: "",
      paid_leave: 0,
      sum: 0,
      unpaid_leave: 0,
      year: 0,
    };
  }

  toJson(): ReportLeaveJson {
    return {
      company_id: this.company_id,
      date_created: this.date_created,
      date_modified: this.date_modified,
      department_id: this.department_id,
      department_name: this.department_name,
      employee_id: this.employee_id,
      employee_name: this.employee_name,
      id: this.id,
      insurance_leave: this.insurance_leave,
      internalid: this.internalid,
      jobtitle: this.jobtitle,
      month: this.month,
      office_id: this.office_id,
      office_name: this.office_name,
      paid_leave: this.paid_leave,
      sum: this.sum,
      unpaid_leave: this.unpaid_leave,
      year: this.year,
    };
  }
}

export default ReportLeaveModel;
