import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { PromotionCouponJson } from "common/types/PromotionCoupon";

class PromotionCouponModel
	extends BaseModel
	implements BaseModelJson<PromotionCouponJson>
{
	company_id: number;
	creator_id: number;
	promotion_id: number;
	id: number;
	code: string;
	max_count_apply: number;
	count_usage: number;
	start_date: number;
	end_date: number;
	modified_date: number;
	create_date: number;
	status: number;
	type: number;
	value: number;
	value_type: number;
	description: string;
	condition: object;
	name: string;
	constructor(json: PromotionCouponJson) {
		super();

		this.company_id = json.company_id || 0;
		this.creator_id = json.creator_id || 0;
		this.promotion_id = json.promotion_id || 0;
		this.id = json.id || 0;
		this.code = json.code || "";
		this.max_count_apply = json.max_count_apply || 0;
		this.count_usage = json.count_usage || 0;
		this.start_date = json.start_date;
		this.end_date = json.end_date;
		this.modified_date = json.modified_date;
		this.create_date = json.create_date;
		this.status = json.status;
		this.type = json.type;
		this.value = json.value;
		this.value_type = json.value_type;
		this.description = json.description;
		this.condition = json.condition;
		this.name = json.name;
	}

	static getDefaultData(): PromotionCouponJson {
		return {
			company_id: 0,
			creator_id: 0,
			promotion_id: 0,
			id: 0,
			code: "",
			max_count_apply: 0,
			count_usage: 0,
			condition: {},
			create_date: 0,
			description: "",
			end_date: 0,
			modified_date: 0,
			start_date: 0,
			status: 0,
			type: 0,
			value: 0,
			value_type: 0,
			name: "",
		};
	}

	toJson(): PromotionCouponJson {
		return {
			company_id: this.company_id,
			creator_id: this.creator_id,
			promotion_id: this.promotion_id,
			id: this.id,
			code: this.code,
			max_count_apply: this.max_count_apply,
			count_usage: this.count_usage,
			start_date: this.start_date,
			end_date: this.end_date,
			modified_date: this.modified_date,
			create_date: this.create_date,
			status: this.status,
			type: this.type,
			value: this.value,
			value_type: this.value_type,
			description: this.description,
			condition: this.condition,
			name: this.name,
		};
	}
}

export default PromotionCouponModel;
