import BaseModel from "./BaseModel";

import type { RbacRoleJson } from "common/types/RbacRole";
import type { Subject, RoleUserDetail } from "common/types/RbacRoleUserDetail";

class RbacRoleUserDetailModel extends BaseModel {
  is_root_admin: boolean;
  roles: RbacRoleJson[];
  subjects: Subject[];
  user_id: number;

  constructor(json: RoleUserDetail) {
    super();

    this.is_root_admin = json.is_root_admin || false;
    this.roles = json.roles || [];
    this.subjects = json.subjects || [];
    this.user_id = json.user_id || 0;
  }

  static getDefaultData(): RoleUserDetail {
    return {
      is_root_admin: false,
      roles: [],
      subjects: [],
      user_id: 0,
    };
  }

  toJson(): RoleUserDetail {
    return {
      is_root_admin: this.is_root_admin,
      roles: this.roles,
      subjects: this.subjects,
      user_id: this.user_id,
    };
  }
}

export default RbacRoleUserDetailModel;
