import { Form, Radio, Tooltip } from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import Role from "common/constants/Role";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconBan, IconCircleCheck } from "@tabler/icons-react";

const InventoryReceiptFormStatus = ({
  id,
  warehouse_id,
  initStatus,
  status,
  setStatus,
  disable
}: {
  id: number;
  warehouse_id: number;
  initStatus: number;
  status: number;
  setStatus: (v: number) => void;
  disable?: boolean
}) => {
  const { t } = useTranslation();
  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );

  //check allow set status to APPROVE
  const allowStatusApprove = useMemo(() => {
    return (
      hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, warehouse_id) &&
      initStatus <= ProductReceipt.STATUS_APPROVED
    );
  }, [warehouse_id, hasRoleAndLimit, initStatus]);

  //check allow set status to CHECK
  const allowStatusCheck = useMemo(() => {
    return (
      hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, warehouse_id) &&
      initStatus <= ProductReceipt.STATUS_APPROVED
    );
  }, [warehouse_id, hasRoleAndLimit, initStatus]);

  //check allow set status to COMPLETE
  const allowStatusComplete = useMemo(() => {
    return (
      hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, warehouse_id) &&
      initStatus <= ProductReceipt.STATUS_COMPLETED
    );
  }, [warehouse_id, hasRoleAndLimit, initStatus]);

  //check allow set status to CANCEL
  const allowStatusCancel = useMemo(() => {
    if (initStatus === ProductReceipt.STATUS_COMPLETED) return false
    return (
      id > 0 &&
      hasRoleAndLimit(Role.INVENTORY_STATUS_CANCEL, warehouse_id) &&
      initStatus < ProductReceipt.STATUS_CANCELLED
    );
  }, [id, warehouse_id, hasRoleAndLimit, initStatus]);

  return (
    <Form.Item
      label={t("productreceipt:status")}
      className="mb-0"
      tooltip={t("productreceipt:status_help")}
    >
      <Radio.Group
        buttonStyle="solid"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        disabled={disable}
      >
        <Radio.Button
          value={ProductReceipt.STATUS_DRAFT}
          disabled={initStatus > ProductReceipt.STATUS_DRAFT || disable}
        >
          {t("productreceipt:STATUS_DRAFT")}
        </Radio.Button>
        <Radio.Button
          value={ProductReceipt.STATUS_WAIT_FOR_APPROVAL}
          disabled={initStatus > ProductReceipt.STATUS_WAIT_FOR_APPROVAL || disable}
        >
          {t("productreceipt:STATUS_WAIT_FOR_APPROVAL")}
        </Radio.Button>
        {/* <Radio.Button
          value={ProductReceipt.STATUS_APPROVED}
          disabled={!allowStatusApprove}
        >
          {t("productreceipt:STATUS_APPROVED")}
        </Radio.Button>
        <Radio.Button
          value={ProductReceipt.STATUS_CHECKED}
          disabled={!allowStatusCheck}
        >
          {t("productreceipt:STATUS_CHECKED")}
        </Radio.Button> */}
        <Radio.Button
          value={ProductReceipt.STATUS_COMPLETED}
          disabled={!allowStatusComplete || disable}
        >
          <Tooltip
            title={t("productreceipt:warning_for_status_complete_cancel")}
            mouseEnterDelay={0.4}
          >
            <IconCircleCheck
              size={18}
              className="-mt-0.5 mr-1 text-green-500"
            />
            {t("productreceipt:STATUS_COMPLETED")}
          </Tooltip>
        </Radio.Button>

        <Radio.Button
          value={ProductReceipt.STATUS_CANCELLED}
          disabled={!allowStatusCancel || disable}
        >
          <Tooltip
            title={t("productreceipt:warning_for_status_complete_cancel")}
            mouseEnterDelay={0.4}
          >
            <IconBan size={18} className="-mt-0.5 mr-1 text-red-500" />
            {t("productreceipt:STATUS_CANCELLED")}
          </Tooltip>
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export default InventoryReceiptFormStatus;
