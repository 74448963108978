import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import ReportLeaveCollection from "common/collections/ReportLeaveCollection";
import { FilterReportLeave } from "common/types/ReportLeave";
import { GetListProps } from "common/types/Filter";

const SERVICE_URL = "/leaveanalysiss";

class ReportLeaveApi extends BaseApi {
	async getList(
		props: GetListProps<FilterReportLeave>
	): Promise<ReportLeaveCollection> {
		let collection = new ReportLeaveCollection();
		try {
			let filters = props.filters;
			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sortby: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					status: filters.status ? filters.status : null,
					department_id:
						Number(filters.department_id) > 0 ? filters.department_id : null,

					month:
						Number(filters.month) > 0
							? filters.month
							: new Date().getMonth() + 1,
					year:
						Number(filters.year) > 0 ? filters.year : new Date().getFullYear(),
					employee_id: filters.employee_id ? +filters.employee_id : null,
				},
			};

			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async getListUser(
		props: GetListProps<FilterReportLeave>
	): Promise<ReportLeaveCollection> {
		let collection = new ReportLeaveCollection();
		try {
			let filters = props.filters;
			let queryData = {
				params: {
					page: filters.page,
					limit: filters.limit,
					sortby: filters.sortby,
					sort_type: filters.sorttype,
					keyword: filters.keyword ? filters.keyword : null,
					status: filters.status ? filters.status : null,
					department_id:
						Number(filters.department_id) > 0 ? filters.department_id : null,

					month:
						Number(filters.month) > 0
							? filters.month
							: new Date().getMonth() + 1,
					year:
						Number(filters.year) > 0 ? filters.year : new Date().getFullYear(),
				},
			};

			const response = await AxiosClient().get<any>(
				SERVICE_URL + "/mine",
				queryData
			);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}

		return collection;
	}

	async exportExcel(props: GetListProps<FilterReportLeave>): Promise<boolean> {
		let filters = props.filters;
		let queryData = {
			params: {
				month:
					Number(filters.month) > 0 ? filters.month : new Date().getMonth() + 1,
				year:
					Number(filters.year) > 0 ? filters.year : new Date().getFullYear(),
				department_id:
					Number(filters.department_id) > 0 ? filters.department_id : null,
				keyword: filters.keyword ? filters.keyword : null,
				time: Math.floor(Math.random() * 100),
			},
		};

		try {
			// const response = await AxiosClient().get<any>(
			//   SERVICE_URL + "/excel",
			//   queryData
			// );

			let url = process.env.REACT_APP_BASE_URL + SERVICE_URL + "/excel?";
			Object.entries(queryData.params).forEach(([key, value]) => {
				if (value !== null) {
					url += key + "=" + value + "&";
				}
			});
			let loginAccount = BaseApi.loginAccount;
			url += "__jwtAuthorization=" + loginAccount.jwt;
			window.open(url, "_blank");
		} catch (error) {
			return false;
		}
		return true;
	}
}

export default ReportLeaveApi;
