import ProductVariant from "common/constants/ProductVariant";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { ProductVariantJson } from "common/types/ProductVariant";
class ProductVariantModel
  extends BaseModel
  implements BaseModelJson<ProductVariantJson>
{
  company_id: number;
  creator_id: number;
  product_id: number;
  id: number;
  sku: string;
  title: string;
  color: number;
  size: number;
  weight: number;
  cost: number;
  price: number;
  status: number;
  date_created: number;
  date_modified: number;
  key: number;

  constructor(json: ProductVariantJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.product_id = json.product_id || 0;
    this.id = json.id || 0;
    this.sku = json.sku || "";
    this.title = json.title || "";
    this.color = json.color || 0;
    this.size = json.size || 0;
    this.weight = json.weight || 0;
    this.cost = json.cost || 0;
    this.price = json.price || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.key = this.id || 0;
  }

  static getDefaultData(): ProductVariantJson {
    return {
      company_id: 0,
      creator_id: 0,
      product_id: 0,
      id: 0,
      sku: "",
      title: " ",
      color: 0,
      size: 0,
      weight: 0,
      cost: 0,
      price: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
      key: 0,
    };
  }

  toJson(): ProductVariantJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      product_id: this.product_id,
      id: this.id,
      sku: this.sku,
      title: this.title,
      color: this.color,
      size: this.size,
      weight: this.weight,
      cost: this.cost,
      price: this.price,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      key: this.id,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ProductVariant.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ProductVariant.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default ProductVariantModel;
