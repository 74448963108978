import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import { LeaveTypeJson } from "common/types/LeaveType";

class LeaveTypeModel extends BaseModel implements BaseModelJson<LeaveTypeJson> {
  office_id: number;
  company_id: number;
  department_id: number;
  id: number;
  // type: number;
  name: string;
  salary_type: string;
  note: string;
  summax: number;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: LeaveTypeJson) {
    super();

    this.office_id = json.office_id || 0;
    this.company_id = json.company_id || 0;
    this.department_id = json.department_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.salary_type = json.salary_type || "";
    this.note = json.note || "";
    // this.type = json.type || 0;
    this.summax = json.summax || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): LeaveTypeJson {
    return {
      office_id: 0,
      company_id: 0,
      department_id: 0,
      id: 0,
      // type: 0,
      name: "",
      salary_type: "",
      note: "",
      summax: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): LeaveTypeJson {
    return {
      office_id: this.office_id,
      company_id: this.company_id,
      department_id: this.department_id,
      id: this.id,
      name: this.name,
      salary_type: this.salary_type,
      // type: this.type,
      note: this.note,
      summax: this.summax,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default LeaveTypeModel;
