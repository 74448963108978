import { AutoComplete, Col, Input, Row, Select } from 'antd';
import MapServiceAutocompletePredictionModel from 'common/models/MapServiceAutoCompletePredictionModel';
import MapServiceRepository from 'common/repositories/MapServiceRepository';
import { AddressGeo } from 'common/types/AddressGeo';
import logoGoogle from 'images/powered_by_google_on_white.png';
import React, { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import AddressInputAutocompleteOption from './AddressInputAutocompleteOption';

const AddressInputAutocomplete = ({
  service,
  address,
  setAddress,
  triggerChange,
  queryMapButton,
  updateAddress,
}: {
  service: string;
  address: string;
  setAddress: (v: string) => void;
  triggerChange: (v: AddressGeo) => void;
  queryMapButton: ReactNode;
  updateAddress: (v: string) => void;
}) => {
  const { t } = useTranslation();
  const debounceTimeout = 500;
  const timeoutRef = useRef<any>(null);
  const selectRef = useRef<any>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [autocompleteSessionToken, setAutocompleteSessionToken] = useState(
    uuidv4()
  );
  const [fetchEmpty, setFetchEmpty] = useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<
    MapServiceAutocompletePredictionModel[]
  >([]);

  //request server to get autocomplete predictions for dropdowm
  const fetchPredictions = useCallback(
    async (query: string) => {
      //change parent state to update address text
      updateAddress(query);
      setFetching(true);

      //keep dropdown open while typing & searching (visual hints)
      if (options.length === 0) {
        setDropdownVisible(false);
      }

      const collection =
        await new MapServiceRepository().queryPlaceAutocomplete({
          service,
          query,
          session_token: autocompleteSessionToken,
        });
      if (!collection.hasError()) {
        if (collection.items.length === 0) {
          setFetchEmpty(true);
          setDropdownVisible(false);
        } else {
          //show dropdown
          setDropdownVisible(true);
          setOptions(collection.items);
        }
      } else {
        //remove current data
        setOptions([]);
      }

      setFetching(false);
    },
    [service, autocompleteSessionToken, options.length, updateAddress]
  );

  const onSelect = async (value: any, option: any) => {
    setOptions([]);

    //display address
    if (option && option.title) {
      setAddress(option.title);
    }

    //fetch places detail
    const place = await new MapServiceRepository().queryPlaceDetail({
      service,
      place_id: value,
      session_token: autocompleteSessionToken,
    });
    if (!place.hasError()) {
      //Everything OK, just update to addressGeo
      triggerChange({
        address: place.address,
        lat: place.coord.lat,
        long: place.coord.long,
        map_place_id: place.place_id,
      });
    }

    //re-generate sessiontoken (for new autocomplete session)
    setAutocompleteSessionToken(uuidv4());
  };

  const dropdownRender = (menu: ReactElement) => {
    return (
      <>
        {menu}
        <Row className="px-3 py-1">
          <Col span={12}>{queryMapButton}</Col>
          <Col span={12} className="text-right">
            {service === "google" ? (
              <img
                src={logoGoogle}
                alt="Powered by Google"
                className="inline"
              />
            ) : null}
          </Col>
        </Row>
      </>
    );
  };

  const onSearch = (query: string) => {
    setAddress(query);
    setFetchEmpty(false);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    //only search if have input text (query)
    if (query.length > 0) {
      timeoutRef.current = setTimeout(
        () => fetchPredictions(query),
        debounceTimeout
      );
    } else {
      //remove all options
      setOptions([]);
    }
  };

  return (
    <Input.Group>
      <AutoComplete
        style={{ width: "100%" }}
        className={fetchEmpty ? "ant-select-address-geo-with-query-map" : ""}
        allowClear={!fetching}
        value={address}
        open={dropdownVisible}
        placeholder={t("common:address_input:placeholder")}
        defaultActiveFirstOption
        onSearch={onSearch}
        onSelect={onSelect}
        onBlur={() => {
          setDropdownVisible(false);
        }}
        onClear={() => {
          if (selectRef.current !== null) {
            selectRef.current.focus();
          }
          //Everything OK, just update to addressGeo
          triggerChange({
            address: "",
            lat: 0,
            long: 0,
            map_place_id: "",
          });
        }}
        dropdownRender={dropdownRender}
      >
        {options.map((option: MapServiceAutocompletePredictionModel) => {
          return (
            <Select.Option
              key={option.id}
              value={option.id}
              title={option.name}
              style={{ borderBottom: "1px solid #eee" }}
            >
              <AddressInputAutocompleteOption data={option} />
            </Select.Option>
          );
        })}
      </AutoComplete>
      {fetchEmpty ? (
        <span style={{ position: "absolute", right: 0, top: 3 }}>
          {queryMapButton}
        </span>
      ) : null}
    </Input.Group>
  );
};

export default AddressInputAutocomplete;
