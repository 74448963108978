import { Button } from "antd";
import NestedCollection from "common/collections/NestedCollection";
import Role from "common/constants/Role";
import ProductCategoryModel from "common/models/ProductCategoryModel";
import ProductCategoryRepository from "common/repositories/ProductCategoryRepository";
import { ProductCategoryJsonWithChildren } from "common/types/ProductCategory";
import { TableColumnsType } from "common/types/Table";
import PageDataTableWithChildren from "components/page/PageDataTableWithChildren";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import ProductCategoryFormModal from "features/productcategory/form/ProductCategoryFormModal";
import ProductCategoryListHeader from "features/productcategory/list/ProductCategoryListHeader";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronDownLeft } from "@tabler/icons-react";

const ProductCategoryList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<
    ProductCategoryJsonWithChildren[]
  >([]);
  const fetchData = useCallback(async (): Promise<
    NestedCollection<ProductCategoryJsonWithChildren>
  > => {
    let collection = await new ProductCategoryRepository().getFullNestedItems();
    setDataSource(collection.items);
    return collection;
  }, []);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<ProductCategoryJsonWithChildren> = [
    {
      title: t("productcategory:name"),
      key: "name",
    },
    {
      title: t("common:display_order_long"),
      width: 120,
      key: "display_order",
      render: (_, record) => (
        <span>
          {Array(record.level)
            .fill(1)
            .map((a, index) => (
              <IconChevronDownLeft
                key={index}
                size={16}
                color="#dddddd"
                className="-ml-0.5 mr-1.5"
              />
            ))}
          <span className="ml-0.5" style={{ opacity: 1 - record.level / 3 }}>
            {record.display_order}
          </span>
        </span>
      ),
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: ProductCategoryJsonWithChildren) => (
        <RoleCheck roles={[Role.PRODUCT_MANAGE]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="productcategory:form.error"
            onDeleteCallback={(id) => {
              fetchData();
            }}
            repository={new ProductCategoryRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: ProductCategoryModel) => {
    fetchData();
  };

  return (
    <>
      <ProductCategoryListHeader>
        <RoleCheck roles={[Role.PRODUCT_MANAGE]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </ProductCategoryListHeader>
      <PageDataTableWithChildren<ProductCategoryJsonWithChildren>
        {...{
          columns,
          dataSource,
          fetchData,
        }}
      ></PageDataTableWithChildren>

      <ProductCategoryFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ProductCategoryList;
