import ContactMailList from 'features/contactmail/list/ContactMailList';
import usePathParams from 'hooks/usePathParams';

const ContactMailPage = () => {
  const [pathParams] = usePathParams();

  let com = null;
  switch (pathParams.action) {
    default:
      com = <ContactMailList />;
  }

  return com;
};

export default ContactMailPage;
