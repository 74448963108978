import Company from "common/constants/Company";
import CompanyPlanCheck from "components/CompanyPlanCheck";
import WebHookList from "features/webhook/list/WebHookList";
import usePathParams from "hooks/usePathParams";

const WebHookPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    default:
      com = (
        <CompanyPlanCheck minPlan={Company.PLAN_02} prefix="Webhook">
          <WebHookList />
        </CompanyPlanCheck>
      );
  }

  return com;
};

export default WebHookPage;
