import {
  Button,
  Collapse,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { TableColumnsType } from "common/types/Table";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconPerfume } from "@tabler/icons-react";
import TagTypeCollection from "common/collections/TagTypeCollection";
import TagConstant from "common/constants/Tag";
import TagModel from "common/models/TagModel";
import TagTypeModel from "common/models/TagTypeModel";
import TagRepository from "common/repositories/TagRepository";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import PageDataPagination from "components/page/PageDataPagination";
import TableDelete from "components/table/TableDelete";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import TagInsideList from "../children/TagInsideList";
import TagProductListHeader from "./TagOutSideListHeader";
import TagTypeOutSizeListFilter from "features/tagtype/list/TagTypeOutSizeListFilter";

type Props = {
  type: number;
};

const TagOutSideList = ({ type }: Props) => {
  const { t } = useTranslation();
  // default filter
  const defaultFilters = useMemo(() => {
    return {
      ...TagTypeRepository.getDefaultFilters(),
      resource_type: type,
    };
  }, [type]);
  //////////////////////////////////////////
  //Fetch data from this collections
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const [dataSource, setDataSource] = useState<TagTypeModel[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchData = useCallback(async (): Promise<TagTypeCollection> => {
    setLoading(true);
    const response = await new TagTypeRepository().getItems({
      filters: filters,
    });
    if (!response.hasError()) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      message.error("common:error.error_fetching_data");
    }
    setLoading(false);
    return response;
  }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  const renderTagStatus = (value: number) => {
    switch (value) {
      case 1:
        return <Tag color="green">Hiện</Tag>;
      case 2:
        return <Tag>Nháp</Tag>;
      case 3:
        return <Tag color="red">Ẩn</Tag>;
      default:
        return null;
    }
  };

  const [addProductVisible, setAddProductVisible] = useState(false);
  const [tags, setTags] = useState<string>("");
  const [tagName, setTagName] = useState<string>("");
  const [editingProductTagId, setEditingProductTagId] = useState<number>(0);
  const onEditProduct = (record: TagTypeModel) => {
    setEditingProductTagId(record.id);
    setTags(record.code);
    setTagName(record.name);
    setAddProductVisible(true);
  };

  //Table columns

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: TagModel) => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const ExpandedContent: React.FC<{ record: TagTypeModel }> = ({ record }) => (
    <div className="mt-4">
      <TagInsideList record={record} />
    </div>
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const handleExpand = (expanded: boolean, record: TagTypeModel) => {
    const keys = expanded ? [record.id] : [];
    setExpandedRowKeys(keys);
  };

  return (
    <>
      <TagProductListHeader type={type}>
        {/* <RoleCheck roles={[]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck> */}
      </TagProductListHeader>

      <TagTypeOutSizeListFilter
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        total={total}
      />
      {!loading ? (
        <>
          <Collapse>
            {dataSource.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <Collapse.Panel
                    header={
                      <>
                        <Typography.Text>
                          {TagTypeModel.getNameTypeTranslate(item.name)} (
                          {item.name})
                        </Typography.Text>
                      </>
                    }
                    key={item.id}
                  >
                    <TagInsideList record={item} />
                  </Collapse.Panel>
                </React.Fragment>
              );
            })}
          </Collapse>
        </>
      ) : (
        <>
          <Skeleton active />
        </>
      )}

      {dataSource.length > 0 && (
        <>
          <PageDataPagination
            total={total}
            filters={filters}
            setFilters={setFilters}
            dataSource={dataSource}
          />
        </>
      )}

      {/* <TagFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      /> */}

      {/* <ProductToTagModal
        id={editingProductTagId}
        key={editingProductTagId}
        open={addProductVisible}
        tags={tags}
        tagName={tagName}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingProductTagId(0);
            setTags("");
          }
          setAddProductVisible(isOpen);
        }}
      /> */}
    </>
  );
};

export default TagOutSideList;
