import { Badge, Table, Tag } from "antd";
import Role from "common/constants/Role";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import { InventoryReceiptCollectionJson, InventoryReceiptFilter, InventoryReceiptSourceType, InventoryReceiptStatus } from "common/types/InventoryResceipt";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextProductReceiptDirection from "components/TextProductReceiptDirection";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import InventoryReceiptListHeader from "./InventoryReceiptListHeader";
import InventoryReceiptListFilter from "./InventoryReceiptListFilter";
import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import Helper from "common/utils/helper";
import WarehouseCollection from "common/collections/WarehouseCollection";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import WarehouseModel from "common/models/WarehouseModel";
import { Link } from "react-router-dom";
interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}
const InventoryReceiptList = () => {
  const { t } = useTranslation();

  const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
    "warehouse",
    Role.INVENTORY_VIEW
  );

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: InventoryReceiptFilter = useMemo(
    () => ({...InventoryReceiptRepository.getDefaultFilters()}),
    []
  );
  const [filters, setFilters] =
    useStateFilter<InventoryReceiptFilter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<InventoryReceiptModel[]>([]);
  const [warehouseItems, setWarehouseItems] = useState<WarehouseModel[]>([]);

  const fetchData = useCallback(async (): Promise<InventoryReceiptCollection> => {
    let collection = await new InventoryReceiptRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  const fetchDataWarehouse = useCallback(async (): Promise<WarehouseCollection> => {
    let collection = await new WarehouseRepository().getItems({
      filters: {
        ...WarehouseRepository.getDefaultFilters(),
      },
    });
    setWarehouseItems(collection.items);

    return collection;
  }, []);

  useEffect(() => {
    fetchDataWarehouse()
  }, [fetchDataWarehouse])


  //Table columns
  const columns: TableColumnsType<InventoryReceiptModel> = [
    {
      title: t("Mã"),
      key: "id",
      align: "center",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <TableLinkText link={"/inventoryreceipt/edit/id/" + record.id}>
              {record.code}
            </TableLinkText>
          </>
        );
      },
    },
    {
      title: t("Loại phiếu"),
      key: "direction",
      render: (direction: number) => {
        return <TextProductReceiptDirection direction={direction} />;
      },
      width: 80,
    },
    {
      title: t("Từ kho"),
      key: "warehouse_id",
      width: 150,
      render: (warehouse_id: number) => {
        const found = warehouseItems.find(
          (i) => i.id === warehouse_id
        );
        if (typeof found !== "undefined") {
          return <span>{found.name}</span>;
        } else {
          return "ID #" + warehouse_id;
        }
      },
    },
    {
      title: t("Nguồn"),
      key: "source",
      width: 150,
      render: (source: any) => {
        if(source.type === InventoryReceiptSourceType.ORDER){

          return <Link  target="_blank" to={`/order/edit/id/${source.id}`}>Đơn hàng #{source.id}</Link>
        }
      },
    },
    {
      title: t("productreceipt:type"),
      key: "type",
      width: 100,

      render: (type: number) => {
        const typeList = InventoryReceiptModel.getTypeList();

        return <>{typeList.find((i) => i.value === type)?.label}</>;
      },
    },
    // {
    //   title: t("productreceipt:order"),
    //   key: "sale_order_id",
    //   width: 130,
    //   render: (_, record) => {
    //     return (
    //       <>
    //         {record.sale_order_id > 0 ? (
    //           <TableLinkText
    //             link={"/order/edit/id/" + record.sale_order_id}
    //             title={t("productreceipt:sale_order")}
    //             target="_blank"
    //           >
    //             {t("productreceipt:sale_order_short")} #{record.sale_order_id}
    //           </TableLinkText>
    //         ) : null}
    //       </>
    //     );
    //   },
    // },
    {
      title: t("common:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = InventoryReceiptModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 120,
    },
    {
      title: t("Ngày tạo"),
      key: "date_created",
      width: 100,
      render: (ts: number) => <p>{Helper.formatTimestampToText(ts)}</p>,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 95,
      render: (_: any, record: InventoryReceiptModel) => (
        <>
          {/* <ProductReceiptItemEdit model={record} /> */}
          <TableInfo record={record}></TableInfo>
        </>
      ),
    },
  ];


  return (
    <>
      <InventoryReceiptListHeader />

      <InventoryReceiptListFilter
      warehouseItemsWithLimit={warehouseItems}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<
        InventoryReceiptFilter,
        InventoryReceiptModel,
        InventoryReceiptCollection
      >
        {...{
          
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "inventoryreceipt",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default InventoryReceiptList;
