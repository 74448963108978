import { Button, Form, InputNumber, Modal, Radio } from "antd";
import Promotion from "common/constants/Promotion";
import TextMoney from "components/TextMoney";
import { useEffect, useState } from "react";

const PosCartModalDiscount = ({
  priceSell,
  defaultValue,
  show,
  setShow,
  onChange,
}: {
  priceSell: number;
  defaultValue: number;
  show: boolean;
  setShow: (v: boolean) => void;
  onChange: (v: number) => void;
}) => {
  const handleClose = () => {
    setShow(false);
  };

  const [manualDiscount, setManualDiscount] = useState(defaultValue);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountType, setDiscountType] = useState(Promotion.VALUETYPE_PERCENT);

  useEffect(() => {
    if (discountType === Promotion.VALUETYPE_PERCENT) {
      setManualDiscount(Math.floor((priceSell * discountValue) / 100));
    } else if (discountType === Promotion.VALUETYPE_CURRENCY) {
      setManualDiscount(Math.floor(discountValue));
    }
  }, [discountType, priceSell, discountValue]);

  const onApply = () => {
    onChange(manualDiscount);
    setShow(false);
  };

  return (
    <Modal
      open={show}
      onCancel={handleClose}
      title="Nhập thông tin giảm giá đơn hàng"
      footer={[
        <div key="value" className="float-left">
          Tổng tiền: <TextMoney money={priceSell} />, Giá trị giảm:{" "}
          <span style={{ color: "green" }}>
            <TextMoney money={manualDiscount} />
          </span>
        </div>,
        <Button key="primary" type="primary" onClick={onApply}>
          Áp dụng
        </Button>,
      ]}
    >
      <div className="w-100">
        <div className="mb-4">
          Khuyến mãi theo:
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            value={discountType}
            onChange={(e) => {
              setDiscountType(e.target.value);
              setDiscountValue(0);
            }}
          >
            <Radio.Button value={Promotion.VALUETYPE_PERCENT}>
              Phần trăm (%)
            </Radio.Button>
            <Radio.Button value={Promotion.VALUETYPE_CURRENCY}>
              Giá trị
            </Radio.Button>
          </Radio.Group>
        </div>
        <Form.Item
          label={
            discountType === Promotion.VALUETYPE_PERCENT
              ? "Chọn % (phần trăm) giảm giá"
              : "Chọn số tiền giảm"
          }
        >
          <InputNumber
            style={{
              width: discountType === Promotion.VALUETYPE_PERCENT ? 80 : 120,
            }}
            value={discountValue}
            min={0}
            max={discountType === Promotion.VALUETYPE_PERCENT ? 100 : undefined}
            step={discountType === Promotion.VALUETYPE_PERCENT ? 1 : 1000}
            onChange={(value) => setDiscountValue(value ?? 0)}
            precision={0}
            decimalSeparator="."
          />
          &nbsp; {discountType === Promotion.VALUETYPE_PERCENT ? "%" : ""}
        </Form.Item>
      </div>
    </Modal>
  );
};

export default PosCartModalDiscount;
