import WorkChatContainer from "features/workchat/WorkChatContainer";
import React from "react";
import { useTranslation } from "react-i18next";

const WorkChatPage = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <>
      <WorkChatContainer />
    </>
  );
};

export default WorkChatPage;
