import { Col, Form, Input, Row, Switch } from 'antd';
import ShippingCarrierModel from 'common/models/ShippingCarrierModel';
import FormSelect from 'components/form/FormSelect';
import RegionPicker from 'components/RegionPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ApiTypeGhn = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 p-4">
      <Row gutter={16}>
        <Col md={4} xs={24}>
          <Form.Item
            name="is_sandbox"
            label={t("shippingcarrier:form.is_sandbox")}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.shop_id")}
            name="shop_id"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={16} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.token")}
            name="token"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.return_address")}
            name="return_address"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item name="regions">
            <RegionPicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.return_phone")}
            name="return_phone"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col md={8} xs={24}>
          <FormSelect
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_choose_required"),
              },
            ]}
            label={t("shippingcarrier:form.service_type_id")}
            name="service_type_id"
            options={ShippingCarrierModel.getGhnServiceTypeList()}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ApiTypeGhn;
