import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShippingOrderListHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("shippingorder:heading_list")}
      siteMenuSelectedKey="/shippingorder"
    >
      {children}
    </PageHeader>
  );
};

export default ShippingOrderListHeader;
