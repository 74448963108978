import BaseModel from "./BaseModel";

import i18n from "i18n";

import {
  CollectionJson,
  FilterCollectionCategory,
} from "common/types/Collection";
import Collection from "common/constants/Collection";
import { SelectOption } from "common/types/SelectOption";
import { BaseModelJson } from "common/interfaces/BaseModelJson";

class CollectionModel
  extends BaseModel
  implements BaseModelJson<CollectionJson>
{
  id: number;
  item_type: string;
  // item_ids: string;
  item_ids: number;
  image: number;
  banner_desktop: number;
  banner_mobile: number;
  title: string;
  description: string;
  keyword: string;
  present: string;
  detail: string;
  quote: string;
  handle: string;
  show: number;
  creator_id: number;
  parent_id: number;
  display_order: number;
  date_published: number;
  publish_from: number;
  publish_to: number;
  status: number;
  is_delete: number;
  date_created: number;
  date_modified: number;
  date_deleted: number;
  itemtotal: number;
  section_ai_namperfume: string;

  constructor(json: CollectionJson) {
    super();

    this.id = json.id || 0;
    // this.item_ids = json.item_ids || "";
    this.item_ids = json.item_ids || 0;
    this.item_type = json.item_type || "";
    this.image = json.image || 0;
    this.banner_desktop = json.banner_desktop || 0;
    this.banner_mobile = json.banner_mobile || 0;
    this.title = json.title || "";
    this.description = json.description || "";
    this.keyword = json.keyword || "";
    this.present = json.present || "";
    this.detail = json.detail || "";
    this.quote = json.quote || "";
    this.handle = json.handle || "";
    this.show = json.show || 0;
    this.creator_id = json.creator_id || 0;
    this.parent_id = json.parent_id || 0;
    this.display_order = json.display_order || 0;
    this.date_published = json.date_published || 0;
    this.publish_from = json.publish_from || 0;
    this.publish_to = json.publish_to || 0;
    this.status = json.status || 0;
    this.is_delete = json.is_delete || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_deleted = json.date_deleted || 0;
    this.itemtotal = json.itemtotal || 0;
    this.section_ai_namperfume = json.section_ai_namperfume || "";
  }

  toJson(): CollectionJson {
    return {
      id: this.id,
      item_ids: this.item_ids,
      item_type: this.item_type,
      image: this.image,
      banner_desktop: this.banner_desktop,
      banner_mobile: this.banner_mobile,
      title: this.title,
      description: this.description,
      keyword: this.keyword,
      present: this.present,
      detail: this.detail,
      quote: this.quote,
      handle: this.handle,
      show: this.show,
      creator_id: this.creator_id,
      parent_id: this.parent_id,
      display_order: this.display_order,
      date_published: this.date_published,
      publish_from: this.publish_from,
      publish_to: this.publish_to,
      status: this.status,
      is_delete: this.is_delete,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_deleted: this.date_deleted,
      itemtotal: this.itemtotal,
      section_ai_namperfume: this.section_ai_namperfume,
    };
  }

  static getDefaultData(): CollectionJson {
    return {
      id: 0,
      // item_ids: "",
      item_ids: 0,
      item_type: "",
      image: 0,
      banner_desktop: 0,
      banner_mobile: 0,
      title: "",
      description: "",
      keyword: "",
      present: "",
      detail: "",
      quote: "",
      handle: "",
      show: 0,
      creator_id: 0,
      parent_id: 0,
      display_order: 0,
      date_published: 0,
      publish_from: 0,
      publish_to: 0,
      status: 0,
      is_delete: 0,
      date_created: 0,
      date_modified: 0,
      date_deleted: 0,
      itemtotal: 0,
      section_ai_namperfume: "",
    };
  }

  static getCollectionTypeList(): SelectOption[] {
    return [
      {
        value: Collection.TYPE_FILTER.TYPE_STORY,
        label: "Bài viết",
      },
      {
        value: Collection.TYPE_FILTER.TYPE_ACADEMY,
        label: "Academy",
      },
      {
        value: Collection.TYPE_FILTER.TYPE_AI_NTEAM,
        label: "Admin",
      },
      {
        value: Collection.TYPE_FILTER.TYPE_WEBPAGE,
        label: "Webpage",
      },
    ];
  }

  static getDefaultCollectionCategoryFilters(): FilterCollectionCategory {
    return {
      collection_id: 0,
      type: 0,
    };
  }
}

export default CollectionModel;
