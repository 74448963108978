import { Button, Col, Form, Row, Space } from "antd";
import { Filter } from "common/types/Filter";
import { diff } from "deep-object-diff";
import useBreakpoint from "hooks/useBreakpoint";
import { debounce, mapValues, omit } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconSearch, IconX } from "@tabler/icons-react";

import PageDataFilterFormExtra from "./PageDataFilterFormExtra";
import FilterBody from "components/filter/FilterBody";

const PageDataFilterForm = <F extends Filter>({
	filters,
	setFilters,
	defaultFilters,
	children,
	total,
	extra,
	labelAll,
	convertValueByKeyFilter,
	rightBox,
	submitOnChange = true,
	triggerOnSubmit = [],
}: {
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
	children?: React.ReactNode;
	total: number;
	extra?: React.ReactNode | boolean;
	labelAll?: React.ReactNode;
	convertValueByKeyFilter?: string[];
	rightBox?: React.ReactNode;
	submitOnChange?: boolean;
	triggerOnSubmit?: string[];
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [isSmall] = useBreakpoint();
	const [visibleOnSmall, setVisibleOnSmall] = useState(!isSmall);

	useEffect(() => {}, [isSmall]);

	const onFormSubmit = useCallback(
		(formData: any) => {
			setFilters({ ...filters, ...formData, page: 1 });
		},
		[filters, setFilters]
	);

	const handleValuesChange = useCallback(() => {
		submitOnChange && submitOnChange === true && form.submit();
	}, [form]);

	useEffect(() => {
		// convert type object status, type, priority in filter
		let entries = Object.entries(filters).map(([key, val]) => {
			let findKey = convertValueByKeyFilter?.find(
				(i) => i === key && +val >= 0
			);
			if (findKey && findKey !== "") {
				return [key, +val];
			}
			return [key, val];
		});

		form.setFieldsValue(
			mapValues(Object.fromEntries(entries), (f) =>
				typeof f === "number" && f < 0 ? undefined : f
			)
		);
	}, [filters, form, convertValueByKeyFilter]);

	////////////////////////////////////////////
	//Compare fitlers with default filters to detect isfilerting or not
	const ignoreCheckFiltering = [
		"page",
		"limit",
		"sortby",
		"sorttype",
		"tags",
		// "lang",
	];
	const refinedFilters = mapValues(omit(filters, ignoreCheckFiltering), (v) =>
		v === undefined ? -1 : v
	);
	const refinedDefaultFilters = mapValues(
		omit(defaultFilters, ignoreCheckFiltering),
		(v) => v
	);
	const countFiltering = Object.keys(
		diff(refinedDefaultFilters, refinedFilters)
	).length;
	//end check isFiltering
	////////////////////////////////////////

	const visibleMobileToggler = isSmall ? (
		<span className="ml-2">
			<Button
				type="text"
				size="small"
				onClick={() => setVisibleOnSmall(!visibleOnSmall)}
				icon={<IconSearch size={16} className="mr-1 -mt-0.5" />}>
				{t("common:filter.mobile_toggle")}
			</Button>
		</span>
	) : undefined;

	return (
		<>
			{typeof extra !== "boolean" || (typeof extra === "boolean" && extra) ? (
				<PageDataFilterFormExtra
					countFiltering={countFiltering}
					filters={filters}
					setFilters={setFilters}
					defaultFilters={defaultFilters}
					total={total}
					extra={extra}
					labelAll={labelAll}>
					{visibleMobileToggler}
				</PageDataFilterFormExtra>
			) : (
				visibleMobileToggler
			)}

			<div
				className={
					"px-6 py-3 bg-gray-200" + (!visibleOnSmall ? " hidden" : "")
				}>
				<Form
					form={form}
					initialValues={filters}
					onFinish={onFormSubmit}
					layout="inline"
					autoComplete="off"
					onValuesChange={debounce(handleValuesChange, 500)}
					className="max-sm:flex max-sm:flex-wrap max-sm:gap-y-2 block">
					<div>
						<Row gutter={[8, 8]}>
							<Col span={24} sm={4} lg={2}>
								<span className="leading-8 mr-2">
									{t("common:filter.search")}:
								</span>
							</Col>
							<Col span={24} sm={18} lg={20}>
								<FilterBody>{children}</FilterBody>
							</Col>
							<Col span={24} sm={2} lg={2} className="text-left lg:text-right ">
								<Button type="primary" ghost htmlType="submit">
									{t("common:filter.submit")}
								</Button>
								{countFiltering > 0 ? (
									<Button
										danger
										type="text"
										icon={<IconX size="16" className="mr-1 -mt-0.5" />}
										onClick={() => setFilters(defaultFilters)}
										className="ml-2">
										{t("common:filter.reset")}
									</Button>
								) : undefined}
							</Col>
						</Row>
					</div>

					{typeof rightBox !== "undefined" ? (
						<div className="xl:absolute right-0">{rightBox}</div>
					) : null}
				</Form>
			</div>
		</>
	);
};

export default PageDataFilterForm;
