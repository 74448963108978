import CustomerTypeApi from "common/api/CustomerTypeApi";
import CustomerTypeCollection from "common/collections/CustomerTypeCollection";
import CustomerTypeModel from "common/models/CustomerTypeModel";
import BaseRepository from "./BaseRepository";
import { GetListProps } from "common/types/Filter";
import type { CustomerTypeJsonAddEdit, FilterCustomerType } from "common/types/CustomerType";

class CustomerTypeRepository extends BaseRepository<CustomerTypeApi> {
  _api: CustomerTypeApi | null;

  constructor() {
    super();
    this._api = new CustomerTypeApi(true);
  }

  static getDefaultFilters(): FilterCustomerType {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterCustomerType>) {
    return this._api ? await this._api.getList(props) : new CustomerTypeCollection();
  }

  async getItem(id: number): Promise<CustomerTypeModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new CustomerTypeModel(CustomerTypeModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: CustomerTypeJsonAddEdit): Promise<CustomerTypeModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new CustomerTypeModel(CustomerTypeModel.getDefaultData());
    }
  }
}

export default CustomerTypeRepository;

