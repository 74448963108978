import WarehouseCollection from "common/collections/WarehouseCollection";
import WarehouseModel from "common/models/WarehouseModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { FullData } from "common/types/FullData";
import type { GetListProps } from "common/types/Filter";
import type {
  FilterWarehouse,
  WarehouseJson,
  WarehouseJsonAddEdit,
  WarehouseCollectionJson,
} from "common/types/Warehouse";
import WarehouseStoreCollection from "common/collections/WarehouseStoreCollection";
import WarehouseStoreModel from "common/models/WarehouseStoreModel";

const SERVICE_URL = "/warehouses";

class WarehouseApi extends BaseApi {
  async getList(
    props: GetListProps<FilterWarehouse>
  ): Promise<WarehouseCollection> {
    let collection = new WarehouseCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          is_not_deleted: 1,
          keyword: filters.keyword ? filters.keyword : null,
          id: +filters.id > 0 ? filters.id : null,
          creator_id: +filters.creator_id > 0 ? filters.creator_id : null,
          company_id: +filters.company_id > 0 ? filters.company_id : null,
          code: filters.code ? filters.code : null,
          id_list: filters.id_list ? filters.id_list : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getListStore(
    props: GetListProps<Partial<FilterWarehouse>>
  ): Promise<WarehouseStoreCollection> {
    let collection = new WarehouseStoreCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/storewarehouses",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetailWarehouseStore(id: number): Promise<WarehouseStoreModel> {
    let item = new WarehouseStoreModel(WarehouseStoreModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/storewarehouses/" + id);
      if (response.hasOwnProperty("data")) {
        item = new WarehouseStoreModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async getDetail(id: number): Promise<WarehouseModel> {
    let item = new WarehouseModel(WarehouseModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new WarehouseModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: WarehouseJsonAddEdit): Promise<WarehouseModel> {
    let item = new WarehouseModel(WarehouseModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new WarehouseModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: WarehouseJsonAddEdit): Promise<WarehouseModel> {
    let item = new WarehouseModel(WarehouseModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new WarehouseModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  ///////////////////////////////////////////////////////////////
  // FULL DATA fetcher
  async getFullData({
    companyId,
    version,
  }: FullData): Promise<WarehouseCollection> {
    return this.getFullDataFetcher<
      WarehouseJson,
      WarehouseModel,
      WarehouseCollectionJson,
      WarehouseCollection
    >({
      collection: new WarehouseCollection(),
      companyId,
      version,
      url: SERVICE_URL + "/fulldata",
    });
  }
}

export default WarehouseApi;
