import DeliverySumbyManagerApi from "common/api/DeliverySumbyManagerApi";
import DeliverySumbyManagerModel from "common/models/DeliverySumbyManagerModel";
import { FilterDeliverySumByManager } from "common/types/DeliverySumbyManager";
import BaseRepository from "./BaseRepository";

class DeliverySumbyManagerRepository extends BaseRepository<DeliverySumbyManagerApi> {
  _api: DeliverySumbyManagerApi | null;

  constructor() {
    super();
    this._api = new DeliverySumbyManagerApi(true);
  }

  async getSumByReport(
    filters: FilterDeliverySumByManager
  ): Promise<DeliverySumbyManagerModel> {
    return this._api
      ? await this._api.getSumByReport(filters)
      : new DeliverySumbyManagerModel(
          DeliverySumbyManagerModel.getDefaultData()
        );
  }

  async getSumByManager(
    filters: FilterDeliverySumByManager
  ): Promise<DeliverySumbyManagerModel> {
    return this._api
      ? await this._api.getSumByManager(filters)
      : new DeliverySumbyManagerModel(
          DeliverySumbyManagerModel.getDefaultData()
        );
  }

  async getSumByAccountant(
    filters: FilterDeliverySumByManager
  ): Promise<DeliverySumbyManagerModel> {
    return this._api
      ? await this._api.getSumByAccountant(filters)
      : new DeliverySumbyManagerModel(
          DeliverySumbyManagerModel.getDefaultData()
        );
  }
}

export default DeliverySumbyManagerRepository;
