import { Button } from "antd";
import moment from "moment";
import { LoadingOutlined, PrinterOutlined } from "@ant-design/icons";
import usePrint from "hooks/usePrint";
import useBarcode from "hooks/useBarcode";
import DeliveryModel from "common/models/DeliveryModel";
import { convertTemplateToHtmlString } from "utils";

const KEY_TEMPLATE = "setting_template_print_delivery";

export default function DeliveryPrint({
  delivery,
}: {
  delivery: DeliveryModel;
}) {
  const datastore = {
    website: "https://namperfume.net",
    phone: "19000129",
		address: "45-47 CMT8, Phường Bến Thành, Quận 1, Hồ Chí Minh, Vietnam",
    name: "namperfume",
    email: "hotro@namperfume.net",
    owner_name: "Namperfume",
  };

  const { barcodeSrc } = useBarcode(delivery.id.toString());

  const dataPrint = {
    ...delivery,
    barcode_img: barcodeSrc,
    date_created: moment.unix(delivery.date_created).format("DD/MM/YYYY HH:mm"),
    store: datastore,
    partnerShipName:
      DeliveryModel.getPartnersShip().find(
        (item) => item.value === delivery.partner_delivery
      )?.label || "",
  };
  const { dataPrintHTML, isLoading, printBill } = usePrint({
    key: KEY_TEMPLATE,
    data: dataPrint,
  });

  if (isLoading || !barcodeSrc) {
    return (
      <div className=" flex justify-center mt-4">
        <p>Đang chuẩn bị dữ liệu...</p>
        <LoadingOutlined />
      </div>
    );
  }
  return (
    <div className=" flex justify-end my-2">
      <Button
        className=" mt-4"
        type="primary"
        onClick={() => {
          printBill(dataPrintHTML);
        }}
        icon={<PrinterOutlined />}
      >
        In
      </Button>
    </div>
  );
}


// const template = `    <meta charset="UTF-8">
// <div style="margin:0;font-family: Helvetica;font-size:12px;page-break-after:always;">
//   <div>
//     <div style="float: right; text-align: right; padding-right:20px;">
//       <p>Ngày đặt hàng: {{ date_created }}</p>
//       <img style="margin-right:-10px" class="print_barcode order" src="{{ barcode_img }}"></img>
//   </div>
//   <div style="margin: 10px 0 1.5em 0;">
//     <p><strong style="font-size: 18px;">{{ store.name }}</strong></p>
//     {% if store.address %}
//     <p><strong>Địa chỉ: </strong>{{ store.address }}</p>
//     {% endif %}
//     {% if store.phone %}
//     <p><strong>Điện thoại:</strong> {{ store.phone }}</p>
//     {% endif %}
//     <p><strong>Website:</strong> {{ store.website }}</p>
//     <p><strong>Email:</strong> {{ store.email }}</p>
//   </div>
//   <div style="clear:both"></div>
// </div>
// <div>
//   <div style="width:60%;float:left;">
//     <h3 style="font-size: 14px;line-height: 0">Chi tiết đơn hàng  {{ sale_order_id }}</h3>
//     <hr style="border: none; border-top: 2px solid #0975BD;"/>
//     <table style="margin: 0 0 1.5em 0;font-size: 12px;width:100%;">
//       <thead>
//         <tr>
//           <th style="width:25%;text-align: left;padding: 5px 0px">Mã sản phẩm</th>
//           <th style="width:40%;text-align: left;padding: 5px 0px">Sản phẩm</th>
//           <th style="width:15%;text-align: right;padding: 5px 0px">Số lượng</th>
//           <th style="width:20%;text-align: right;padding: 5px 0px">Tổng tiền</th>
//         </tr>
//       </thead>
//       <tbody>
//         {% for product in order_detail %}
//         <tr valign="top" style="border-top: 1px solid #d9d9d9;">
//           <td align="left" style="padding: 5px 0px">{{ product.product_sku }}</td>
//           <td align="left" style="padding: 5px 5px 5px 0px;white-space: normal;">

//             <p style="margin-bottom: 5px;">{{ product.product_name }}</p>
//             <p style="margin: 0">{{ product.variant_name }}</p>

//           </td>
//           <td align="center" style="padding: 5px 0px">{{ product.product_quantity }}</td>
//           <td align="center" style="padding: 5px 0px">{{ product.price | currency}}</td>
//         </tr>
//         {% endfor %}
//       </tbody>
//     </table>
//   </div>
//   <div style="width:38%;padding: 0px 0 0 2%;float:left;">
//     <h3 style="font-size: 14px;line-height: 0">Thông tin vận chuyển</h3>
//     <hr style="border: none; border-top: 2px solid #0975BD;"/>
//     <div style="margin: 0 0 1em 0; padding: 1em; border: 1px solid #d9d9d9;">
//       <p><b>Mã vận đơn: </b>{{id}}</p>
// {% if code %}
//       <p><b>Mã phiếu giao hàng: </b>{{code}}</p>
//        {% endif%}
//         {% if partnerShipName %}
//       <p><b>Nhà vận chuyển: </b>{{partnerShipName}}</p>
//        {% endif%}
//       <p><b>Tiền thu hộ: </b>{{ cod_total | currency }}</p>
//     </div>
//     <h3 style="font-size: 14px;line-height: 0">Thông tin người gửi</h3>
//     <hr style="border: none; border-top: 2px solid #0975BD;"/>
//     <div style="margin: 0 0 1em 0; padding: 1em; border: 1px solid #d9d9d9;">
//       <p><b>Người gửi: </b>{{store.owner_name}}</p>
//       {% if store.address %}
//       <p><strong>Địa chỉ: </strong>{{ store.address }}</p>
//       {% endif %}
//       {% if store.phone %}
//       <p><strong>Điện thoại:</strong> {{ store.phone }}</p>
//       {% endif%}
//     </div>

//     <h3 style="font-size: 14px;line-height: 0">Thông tin người nhận</h3>
//     <hr style="border: none; border-top: 2px solid #0975BD;"/>
//     <div style="margin: 0 0 1em 0; padding: 1em; border: 1px solid #d9d9d9;  white-space: normal;">
//       <p><strong>Người nhận: </strong> {{ contact_fullname }}</p>
//       {% if contact_address %}
//       <p><strong>Địa chỉ: </strong>{{ contact_address }}</p>
//       {% endif %}
//       {% if contact_phone %}
//       <p><strong>Điện thoại: </strong>{{ contact_phone }}</p>
//       {% endif %}
//     </div>

//   </div>
//   <div style="clear:both"></div>
// </div>
// <p>Nếu bạn có thắc mắc, vui lòng liên hệ chúng tôi qua email {{ store.email }} {% if store.phone %} hoặc {{ store.phone }} {% endif %}</p>
// </div>`;