import { Rate, Select } from "antd";
import { useTranslation } from "react-i18next";
import RateStars from "./RateStars";
const { Option } = Select;

type Props = {
  className?: string;
  onChange?: any;
  placeholder?: string;
  style?: React.CSSProperties | undefined;
  disable?: boolean;
  defaultValue?: number | string;
  value?: number | string;
};
const RateStarsSelect = ({
  className,
  style,
  onChange,
  placeholder,
  disable = false,
  defaultValue,
}: Props) => {
  const stylesStar = {
    color: "#dadce0",
    fontSize: "14px",
  };
  const styles = {
    optStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
    },
    optStyleLeft: {
      display: "flex",
    },
  };
  const { t } = useTranslation();

  const dropdownRender = (menu: any) => (
    <div>
      <div style={{ textAlign: "center" }}>{menu}</div>
    </div>
  );
  return (
    <>
      <Select
        className={className}
        style={{ width: "100%", textAlign: "left", ...style }}
        onChange={onChange}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        disabled={disable}
        defaultValue={defaultValue}
        allowClear={true}
      >
        <Option value={1}>
          <div style={{ ...styles.optStyle }}>
            <div style={{ ...styles.optStyleLeft }}>
              <RateStars stars={1} />
            </div>
            <div>1 {t("comment:model.star")}</div>
          </div>
        </Option>
        <Option value={2}>
          <div style={{ ...styles.optStyle }}>
            <div style={{ ...styles.optStyleLeft }}>
              <RateStars stars={2} />
            </div>
            <div>2 {t("comment:model.stars")}</div>
          </div>
        </Option>
        <Option value={3}>
          <div style={{ ...styles.optStyle }}>
            <div style={{ ...styles.optStyleLeft }}>
              <RateStars stars={3} />
            </div>
            <div>3 {t("comment:model.stars")}</div>
          </div>
        </Option>
        <Option value={4}>
          <div style={{ ...styles.optStyle }}>
            <div style={{ ...styles.optStyleLeft }}>
              <RateStars stars={4} />
            </div>
            <div>4 {t("comment:model.stars")}</div>
          </div>
        </Option>
        <Option value={5}>
          <div style={{ ...styles.optStyle }}>
            <div style={{ ...styles.optStyleLeft }}>
              <RateStars stars={5} />
            </div>
            <div>5 {t("comment:model.stars")}</div>
          </div>
        </Option>
      </Select>
    </>
  );
};

export default RateStarsSelect;
