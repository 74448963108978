import { Dropdown, List, MenuProps, Typography } from "antd";
import Role from "common/constants/Role";
import FileModel from "common/models/FileModel";
import FileRepository from "common/repositories/FileRepository";
import TextUtil from "common/utils/TextUtil";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconFolderX, IconPencil } from "@tabler/icons-react";

import type { ViewType } from "common/types/File";
const FileItemDirectory = ({
  item,
  viewType,
  highlight,
  onClick,
  onDeleteCompleted,
  onClickEditToggle,
}: {
  item: FileModel;
  viewType: ViewType;
  highlight: string;
  onClick: (f: FileModel) => void;
  onDeleteCompleted: (f: FileModel) => void;
  onClickEditToggle: (f: FileModel) => void;
}) => {
  const { t } = useTranslation();

  const [account, hasRole] = useLoginAccountStore((state) => [
    state.account,
    state.hasRole,
  ]);

  //check if current logged user have the permission to edit this file
  const isEditable =
    hasRole(Role.FILE_MANAGE) ||
    (item.creator_id === account.id && !item.isOldFile());

  const actions = isEditable
    ? [
        <TableEdit
          key="edit"
          link=""
          onClick={() => onClickEditToggle(item)}
        />,
        <TableDelete
          key="delete"
          error_translate_prefix="file:form.error"
          onDeleteCallback={(id) => {
            onDeleteCompleted(item);
          }}
          repository={new FileRepository()}
          id={item.id}
          title={t("file:delete_directory_confirm")}
        />,
      ]
    : [];

  const dropdownMenu: MenuProps["items"] = isEditable
    ? [
        {
          label: (
            <TableEdit
              key="edit"
              link=""
              buttonType="text"
              buttonBlock={true}
              onClick={() => onClickEditToggle(item)}
              icon={<IconPencil className="-mt-0.5 mr-1" size="18" />}
              label={t("file:edit_directory")}
              buttonSize="middle"
            />
          ),
          key: "edit",
        },
        {
          label: (
            <>
              <TableDelete
                key="delete"
                error_translate_prefix="file:form.error"
                onDeleteCallback={(id) => {
                  onDeleteCompleted(item);
                }}
                repository={new FileRepository()}
                id={item.id}
                title={t("file:delete_directory_confirm")}
                label={t("file:delete_directory")}
                icon={
                  <IconFolderX
                    className="-mt-0.5 mr-1 text-red-400"
                    size="18"
                  />
                }
                buttonBlock={true}
                buttonSize="middle"
              />
            </>
          ),
          key: "delete",
        },
      ]
    : [];

  return (
    <List.Item
      actions={viewType === "list" ? actions : []}
      className={viewType === "list" ? "hover:bg-gray-50" : ""}
    >
      {viewType === "list" ? (
        <div>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onClick(item);
            }}
            style={{
              width: 30,
              height: 30,
              display: "inline-block",
              float: "left",
              textAlign: "center",
            }}
          >
            <img height={30} src={item.getIcon()} alt="Directory" />
          </a>
          <Typography.Link
            ellipsis
            style={{ maxWidth: 450, marginLeft: 10, lineHeight: "30px" }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onClick(item);
            }}
          >
            <Highlighter
              highlightClassName=""
              searchWords={[highlight]}
              autoEscape={true}
              textToHighlight={item.title}
            />
          </Typography.Link>
        </div>
      ) : (
        <Dropdown
          menu={{ items: dropdownMenu, className: "menu-without-item-padding" }}
          trigger={["contextMenu"]}
        >
          <div
            className="rounded border-2 border-white hover:border-blue-100 cursor-pointer p-3 "
            onClick={(e) => {
              e.preventDefault();
              onClick(item);
            }}
          >
            <img
              style={{ width: "100%" }}
              src={item.getIcon()}
              alt="Directory"
            />

            <div className="text-center text-xs text-gray-500 mt-2">
              <span>{TextUtil.truncateFromMiddle(item.title, 28, "... ")}</span>
            </div>
          </div>
        </Dropdown>
      )}
    </List.Item>
  );
};

export default FileItemDirectory;
