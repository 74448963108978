import { Col, Row } from "antd";
import DeliveryPlanning from "common/constants/DeliveryPlanning";
import React from "react";
import { useTranslation } from "react-i18next";

const DeliveryPlanningCartSection = ({
  action,
  setAction,
}: {
  action: number;
  setAction: (v: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="border-b-2 border-blue-500 w-full px-4 mb-2" gutter={8}>
        <Col flex={1}>
          <div
            className={`flex items-center justify-center  rounded-tl-lg rounded-tr-lg px-1 py-2 cursor-pointer ${
              action === DeliveryPlanning.ADD_ROUTE
                ? "bg-blue-500"
                : "hover:bg-blue-800"
            }`}
            onClick={() => setAction(DeliveryPlanning.ADD_ROUTE)}
          >
            <div>{t("deliveryplanning:cart.action_add_route")}</div>
          </div>
        </Col>
        <Col flex={1}>
          <div
            className={`flex items-center justify-center  rounded-tl-lg rounded-tr-lg px-1 py-2 cursor-pointer ${
              action === DeliveryPlanning.ASSIGN_ROUTE
                ? "bg-blue-500"
                : "hover:bg-blue-800"
            }`}
            onClick={() => setAction(DeliveryPlanning.ASSIGN_ROUTE)}
          >
            <div>{t("deliveryplanning:cart.action_asign_route")}</div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DeliveryPlanningCartSection;
