import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import ReportChartNumber from "features/report/chart/ReportChartNumber";
import ReportChartTimeSeries from "features/report/chart/ReportChartTimeSeries";
import React from "react";

import { IconReportMoney, IconShoppingCart } from "@tabler/icons-react";

const ReportInventoryInputGeneral = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Số phiếu nhập kho"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="count_id"
              dataService="inventory"
              dataTable="productreceipt"
              dataSelect="count(id)"
              dataFilter="direction = 3,status = 9"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Tổng lượng nhập kho"}
              icon={<IconShoppingCart size="18" />}
              keyMapping="sum_quantity"
              dataTable="productreceiptdetail"
              dataService="inventory"
              dataSelect="sum(quantity)"
              dataFilter="productreceipt_direction = 3,productreceipt_status = 9"
              dataJoin="productreceipt"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="money"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"Sản phẩm có nhập kho"}
              icon={<IconReportMoney size="18" />}
              keyMapping="countdistinct_product"
              dataTable="productreceiptdetail"
              dataService="inventory"
              dataSelect="countdistinct(product)"
              dataFilter="productreceipt_direction = 3,productreceipt_status = 9"
              dataJoin="productreceipt"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <ReportChartNumber
              className="p-2"
              label={"SKU có nhập kho"}
              icon={<IconReportMoney size="18" />}
              keyMapping="countdistinct_sku"
              dataTable="productreceiptdetail"
              dataService="inventory"
              dataSelect="countdistinct(sku)"
              dataFilter="productreceipt_direction = 3,productreceipt_status = 9"
              dataJoin="productreceipt"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
              valueType="number"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Số phiếu nhập kho"}
              dataTable="productreceipt"
              dataService="inventory"
              dataSelect="count(id)"
              dataFilter="direction = 3,status = 9"
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "count_id" }}
              height={250}
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col flex="1">
            <ReportChartTimeSeries
              className="p-4"
              title={"Tổng số lượng sản phẩm nhập kho"}
              height={300}
              dataTable="productreceiptdetail"
              dataService="inventory"
              dataSelect="sum(quantity)"
              dataFilter="productreceipt_direction = 3,productreceipt_status = 9"
              dataGroupBy=""
              dataOrderBy=""
              dataJoin="productreceipt"
              keyMapping={{ name: "comparetext", value: "sum_quantity" }}
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportInventoryInputGeneral;
