import { ITRequestCollectionJson } from "common/types/ITRequest";
import BaseCollection from "./BaseCollection";

//////////////////////////////////
//Phần thay thế
import ITRequestModel from "common/models/ITRequestModel";
import { ITRequestJson } from "common/types/ITRequest";

class ITRequestCollection extends BaseCollection<
  ITRequestJson,
  ITRequestModel,
  ITRequestCollectionJson
> {
  itemsFromJson(jsonItems: ITRequestJson[]): ITRequestModel[] {
    return jsonItems.map((item) => new ITRequestModel(item));
  }
}
export default ITRequestCollection;
