import { Alert, Button } from "antd";
import PosCartModel from "common/models/PosCartModel";
import { PosCartPayment } from "common/types/PosCart";
import TextMoney from "components/TextMoney";
import { filter, forEach } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePosCartStore from "zustands/usePosCartStore";

import { IconSquarePlus } from "@tabler/icons-react";

import PosRightPaymentItem from "./PosRightPaymentItem";
import PosRightPaymentModal from "./PosRightPaymentModal";

const PosRightPayment = () => {
  const activeCart = usePosCartStore((state) => state.getActiveCart());
  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);
  const calculatedData = useMemo(
    () =>
      typeof activeCart !== "undefined"
        ? PosCartModel.calculatePrices(activeCart)
        : undefined,
    [activeCart]
  );

  const [showModal, setShowModal] = useState(false);
  const [remainPrice, setRemainPrice] = useState(0);

  const removePayment = useCallback(
    (deletedPayment: PosCartPayment) => {
      updateActiveCart({
        payments: filter(
          activeCart?.payments,
          (payment) => deletedPayment._id !== payment._id
        ),
      });
    },
    [activeCart?.payments, updateActiveCart]
  );

  //calculate remain money
  useEffect(() => {
    if (
      typeof calculatedData !== "undefined" &&
      typeof activeCart !== "undefined"
    ) {
      let remainMoney = calculatedData.price_final;
      forEach(activeCart.payments, (payment) => {
        remainMoney -= payment.value;
      });
      setRemainPrice(remainMoney);
    }
  }, [calculatedData, activeCart]);

  //incase price_final change, we clear all payments
  useEffect(() => {
    updateActiveCart({
      payments: [],
    });
  }, [calculatedData?.price_final, updateActiveCart]);

  if (typeof activeCart === "undefined") {
    return null;
  }

  return (
    <div className="p-4 border-t border-l-4 border-pink-500">
      <div className="float-right">
        <Button
          type="primary"
          style={{ marginTop: -10 }}
          icon={<IconSquarePlus size={16} className="mr-1 -mt-1" />}
          onClick={(e) => {
            e.preventDefault();
            setShowModal(true);
          }}
        >
          Chọn..
        </Button>
      </div>
      <div className="mb-2 font-bold uppercase">THANH TOÁN</div>

      {activeCart.payments.map((payment) => (
        <PosRightPaymentItem
          key={payment._id}
          payment={payment}
          onRemove={() => removePayment(payment)}
        />
      ))}

      <div>
        - Cần phải thu (COD):{" "}
        <strong>
          <TextMoney money={remainPrice} />
        </strong>
      </div>

      {remainPrice > 0 && activeCart.delivery.shipping_carrier_id === 0 ? (
        <>
          <Alert
            type="error"
            showIcon
            className="mt-1"
            message={
              <span className="text-sm">
                Bắt buộc thu đủ tiền (cho đơn không giao hàng)
              </span>
            }
          />
        </>
      ) : null}

      <PosRightPaymentModal
        key={remainPrice}
        payValue={remainPrice}
        show={showModal}
        setShow={setShowModal}
      />
    </div>
  );
};

export default PosRightPayment;
