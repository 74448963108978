import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";
import ContactMailApi from "common/api/ContactMailApi";
import {
  ContactMailAddEditJson,
  FilterContactMail,
} from "common/types/ContactMail";
import ContactMailCollection from "common/collections/ContactMailCollection";
import ContactMailModel from "common/models/ContactMailModel";

class ContactMailRepository extends BaseRepository<ContactMailApi> {
  _api: ContactMailApi | null;

  constructor() {
    super();
    this._api = new ContactMailApi(true);
  }

  static getDefaultFilters(): FilterContactMail {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      contact: "",
    };
  }

  async getItems(props: GetListProps<FilterContactMail>) {
    return this._api
      ? await this._api.getList(props)
      : new ContactMailCollection();
  }

  async getItem(id: number): Promise<ContactMailModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ContactMailModel(ContactMailModel.getDefaultData());
  }

  async saveRemote(data: ContactMailAddEditJson): Promise<ContactMailModel> {
    if (this._api) {
      if (data.id > 0) {
        // return await this._api.edit(data);
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ContactMailModel(ContactMailModel.getDefaultData());
    }
  }
}

export default ContactMailRepository;
