import { Table, TableColumnsType, Typography } from "antd";
import ProjectTaskChangeModel from "common/models/ProjectTaskChangeModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import TextDateTime from "components/TextDateTime";
import UserIdAvatar from "components/UserIdAvatar";
import React from "react";
import { useTranslation } from "react-i18next";

const ProjectTaskTabUpdateHistory = ({
  dataSource,
}: {
  dataSource: ProjectTaskChangeModel[];
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<ProjectTaskChangeModel> = [
    {
      title: " ",
      key: "date_created",
      dataIndex: "date_created",
      render: (_: any, record: ProjectTaskChangeModel) =>
        record.date_created ? (
          <TextDateTime ts={record.date_created} format="HH:mm, DD/MM/YYYY" />
        ) : (
          ""
        ),
    },
    {
      title: " ",
      key: "creator_id",
      dataIndex: "creator_id",
      render: (_any, record: ProjectTaskChangeModel) =>
        record.creator_id ? (
          <UserIdAvatar id={record.creator_id} hideTooltip={false} />
        ) : (
          ""
        ),
    },
    {
      title: " ",
      key: "old_status",
      dataIndex: "old_status",
      render: (_: any, record: ProjectTaskChangeModel) => {
        let oldStatusInfo = ProjectTaskModel.getStatus(record.old_status);
        let newStatusInfo = ProjectTaskModel.getStatus(record.new_status);

        return record.old_status !== record.new_status ? (
          <Typography.Text>
            {oldStatusInfo?.label} - {newStatusInfo?.label}
          </Typography.Text>
        ) : (
          ""
        );
      },
    },
    {
      title: " ",
      key: "change_note",
      dataIndex: "change_note",
      render: (_: any, record: ProjectTaskChangeModel) => (
        <Typography.Text>{record.change_note}</Typography.Text>
      ),
    },
  ];

  return (
    <>
      {dataSource.length > 0 ? (
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          showHeader={false}
          bordered={false}
        />
      ) : (
        <div className="italic">{t("projecttask:status_history_empty")}</div>
      )}
    </>
  );
};

export default ProjectTaskTabUpdateHistory;
