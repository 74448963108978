import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import { DateRange, FilterBiCenter } from "common/types/BiCenter";
import dayjs from "dayjs";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReportDateRangePicker from "../ReportDateRangePicker";
import ReportViewHeader from "../view/ReportViewHeader";
import ReportCashflowReceiptIncomePartnerCustomer from "./partner/ReportCashflowReceiptIncomePartnerCustomer";
import ReportCashflowReceiptIncomePartnerEmployee from "./partner/ReportCashflowReceiptIncomePartnerEmployee";
import ReportCashflowReceiptIncomePartnerGeneral from "./partner/ReportCashflowReceiptIncomePartnerGeneral";
import ReportCashflowReceiptIncomePartnerSupplier from "./partner/ReportCashflowReceiptIncomePartnerSupplier";
import ReportCashflowReceiptIncomeAccount from "./ReportCashflowReceiptIncomeAccount";
import ReportCashflowReceiptIncomeCategory from "./ReportCashflowReceiptIncomeCategory";
import ReportCashflowReceiptIncomeGeneral from "./ReportCashflowReceiptIncomeGeneral";
import ReportCashflowReceiptIncomeMethod from "./ReportCashflowReceiptIncomeMethod";
import ReportCashflowReceiptIncomeStore from "./ReportCashflowReceiptIncomeStore";

const ReportCashflowReceiptIncomeWrapper = ({
  siteMenuOpenKey,
  siteMenuSelectedKey
}: {
  siteMenuOpenKey: string;
  siteMenuSelectedKey: string;
}) => {
  const { t } = useTranslation();

  const defaultFilters: FilterBiCenter = useMemo(
    () => BiCenterRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterBiCenter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const getPathNameParts = useMemo(() => {
    let pathNameParts = siteMenuSelectedKey.split("/");
    //discard first item (empty string)
    pathNameParts.shift();
    //discard controller item
    pathNameParts.shift();
    //discard action item
    pathNameParts.shift();
    return pathNameParts;
  }, [siteMenuSelectedKey]);

  const getNavByPathLocation = useMemo(() => {
    const nav = [
      {
        text: t("report:heading_view")
      },
      {
        text: t("report:heading_report_cashflowreceipt_income")
      }
    ];
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          nav.push({
            text: t("report:heading_report_cashflowreceipt_income_general")
          });
          break;
        case "store":
          nav.push({
            text: t("report:heading_report_cashflowreceipt_income_store")
          });
          break;
        case "category":
          nav.push({
            text: t("report:heading_report_cashflowreceipt_income_category")
          });
          break;
        case "account":
          nav.push({
            text: t("report:heading_report_cashflowreceipt_income_account")
          });
          break;
        case "method":
          nav.push({
            text: t("report:heading_report_cashflowreceipt_income_method")
          });
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      nav.push({
        text: t("report:heading_report_cashflowreceipt_income_partner")
      });
      switch (getPathNameParts[1]) {
        case "general":
          nav.push({
            text: t(
              "report:heading_report_cashflowreceipt_income_partner_general"
            )
          });
          break;
        case "customer":
          nav.push({
            text: t(
              "report:heading_report_cashflowreceipt_income_partner_customer"
            )
          });
          break;
        case "supplier":
          nav.push({
            text: t(
              "report:heading_report_cashflowreceipt_income_partner_supplier"
            )
          });
          break;
        case "employee":
          nav.push({
            text: t(
              "report:heading_report_cashflowreceipt_income_partner_employee"
            )
          });
          break;
      }
    }
    return nav;
  }, [t, getPathNameParts]);

  const getHeadingByPathLocation = useMemo(() => {
    let heading = "";
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          heading = "Báo cáo tổng quan dựa vào phiếu thu của sổ quỹ";
          break;
        case "store":
          heading = "Báo cáo thu tiền theo cửa hàng";
          break;
        case "category":
          heading = "Báo cáo thu tiền theo danh mục";
          break;
        case "account":
          heading = "Báo cáo thu tiền theo tài khoản ngân hàng";
          break;
        case "method":
          heading = "Báo cáo thu tiền theo phương thức thanh toán";
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      switch (getPathNameParts[1]) {
        case "general":
          heading = "Báo cáo thu tiền theo đối tượng";
          break;
        case "customer":
          heading = "Báo cáo thu tiền theo đối tượng là khách hàng";
          break;
        case "supplier":
          heading = "Báo cáo thu tiền theo đối tượng là nhà cung cấp";
          break;
        case "employee":
          heading = "Báo cáo thu tiền theo đối tượng là nhân viên";
          break;
      }
    }

    return heading;
  }, [getPathNameParts]);

  const onChangeDateRange = (
    value: DateRange,
    value_compare: DateRange | null
  ) => {
    setFilters({
      ...filters,
      start: BiCenterModel.momentToString(value[0]),
      end: BiCenterModel.momentToString(value[1]),
      ...(value_compare
        ? {
            startcompare: BiCenterModel.momentToString(value_compare[0]),
            endcompare: BiCenterModel.momentToString(value_compare[1])
          }
        : { startcompare: "", endcompare: "" })
    });
  };

  const getRange = useCallback((): DateRange => {
    if (filters.start.length > 0 && filters.end.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.start),
        BiCenterModel.stringToMoment(filters.end)
      ];
    } else {
      //default: last30days
      return [
        dayjs().subtract(30, "days").startOf("day"),
        dayjs().subtract(1, "days").endOf("day")
      ];
    }
  }, [filters.start, filters.end]);

  const getRangeCompare = useCallback((): DateRange | null => {
    if (filters.startcompare.length > 0 && filters.endcompare.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.startcompare),
        BiCenterModel.stringToMoment(filters.endcompare)
      ];
    } else {
      //user already set date start by MANUAL,
      //so, do not return default with compare (disable compare)
      if (filters.start.length > 0 && filters.end.length > 0) {
        return null;
      } else {
        //default: previousperiod of last30days
        return [
          dayjs().subtract(60, "days").startOf("day"),
          dayjs().subtract(31, "days").endOf("day")
        ];
      }
    }
  }, [filters.startcompare, filters.endcompare, filters.start, filters.end]);

  const getComponentByPathLocation = useMemo(() => {
    let com = null;
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          com = (
            <ReportCashflowReceiptIncomeGeneral
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "store":
          com = (
            <ReportCashflowReceiptIncomeStore
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "category":
          // com = (
          //   <ReportCashflowReceiptIncomeCategory
          //     getRange={getRange}
          //     getRangeCompare={getRangeCompare}
          //   />
          // );
          break;
        case "account":
          // com = (
          //   <ReportCashflowReceiptIncomeAccount
          //     getRange={getRange}
          //     getRangeCompare={getRangeCompare}
          //   />
          // );
          break;
        case "method":
          com = (
            <ReportCashflowReceiptIncomeMethod
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
      }
    }
    if (getPathNameParts.length === 2) {
      switch (getPathNameParts[1]) {
        case "general":
          com = (
            <ReportCashflowReceiptIncomePartnerGeneral
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "customer":
          com = (
            <ReportCashflowReceiptIncomePartnerCustomer
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "supplier":
          com = (
            <ReportCashflowReceiptIncomePartnerSupplier
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
        case "employee":
          com = (
            <ReportCashflowReceiptIncomePartnerEmployee
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
      }
    }

    return com;
  }, [getPathNameParts, getRange, getRangeCompare]);

  return (
    <>
      <ReportViewHeader
        heading={getHeadingByPathLocation}
        nav={getNavByPathLocation}
        siteMenuOpenKey={siteMenuOpenKey}
        siteMenuSelectedKey={siteMenuSelectedKey}>
        <ReportDateRangePicker
          range={getRange()}
          range_compare={getRangeCompare()}
          onChange={onChangeDateRange}
          format="DD/MM/YYYY"
        />
      </ReportViewHeader>
      {getComponentByPathLocation}
    </>
  );
};

export default ReportCashflowReceiptIncomeWrapper;
