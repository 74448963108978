import ProjectIterationApi from 'common/api/ProjectIterationApi';
import ProjectIterationCollection from 'common/collections/ProjectIterationCollection';
import ProjectIterationModel from 'common/models/ProjectIterationModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProjectIterationJsonAddEdit,
  FilterProjectIteration,
} from "common/types/ProjectIteration";

class ProjectIterationRepository extends BaseRepository<ProjectIterationApi> {
  _api: ProjectIterationApi | null;

  constructor() {
    super();
    this._api = new ProjectIterationApi(true);
  }

  static getDefaultFilters(): FilterProjectIteration {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      project_id: -1,
      company_id: -1,
      ids: "",
    };
  }

  async getItems(props: GetListProps<FilterProjectIteration>) {
    return this._api
      ? await this._api.getList(props)
      : new ProjectIterationCollection();
  }

  async getItemsByIds(ids: string) {
    return this._api
      ? await this._api.getIds(ids)
      : new ProjectIterationCollection();
  }

  async getItem(id: number): Promise<ProjectIterationModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProjectIterationModel(ProjectIterationModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProjectIterationJsonAddEdit
  ): Promise<ProjectIterationModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProjectIterationModel(ProjectIterationModel.getDefaultData());
    }
  }
}

export default ProjectIterationRepository;
