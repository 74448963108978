import { useTranslation } from "react-i18next";

import { Filter } from "common/types/Filter";
import ITRequestModel from "common/models/ITRequestModel";

import FormSelectNormal from "components/form/FormSelectNormal";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";

const ITRequestListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["status", "islate", "date_from", "date_to"]}
      >
        <DatePickerRangeFilter
          filters={filters}
          setFilters={setFilters}
          keyEnd="date_to"
          keyStart="date_from"
        />
        <FormSelectNormal
          name="status"
          placeholder={t("itrequest:filter.status")}
          options={ITRequestModel.getStatusOption()}
          allowClear
          popupMatchSelectWidth={false}
        />
        <FormSelectNormal
          name="islate"
          placeholder={t("itrequest:filter.islate")}
          options={ITRequestModel.getIsLateOption()}
          allowClear
          popupMatchSelectWidth={false}
        />
      </PageDataFilterForm>
    </>
  );
};

export default ITRequestListFilter;
