import { Modal } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import SupplierFormSelect from "features/supplier/SupplierFormSelect";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PurchaseOrderListHeader = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  return (
    <PageHeader
      heading={t("purchaseorder:heading_list")}
      nav={[]}
      siteMenuOpenKey="sale"
      siteMenuSelectedKey="/purchaseorder"
    >
      <RoleCheck roles={[Role.PURCHASE_ORDER_ADD]}>
        <PageHeaderButton
          type="primary"
          link=""
          onClick={() => {
            setModalVisible(true);
          }}
        >
          {t("purchaseorder:add_button")}
        </PageHeaderButton>
      </RoleCheck>

      <Modal
        title={t("purchaseorder:add_button_modal")}
        footer={null}
        width={480}
        open={modalVisible}
        closable={true}
        maskClosable={true}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <SupplierFormSelect
          name="supplier_id"
          onChange={(id: number) => {
            navigate("/purchaseorder/add/supplier_id/" + id);
          }}
        />
      </Modal>
    </PageHeader>
  );
};

export default PurchaseOrderListHeader;
