import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { DealthomConfig, TypeTagConfig } from "common/types/Promotion";
import React, { useMemo, useState } from "react";
import FormSelect from "components/form/FormSelect";
import { SelectOption } from "common/types/SelectOption";
import { PromotionJson } from "common/types/Promotion";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import usePromotion from "hooks/usePromotion";
import FormLoading from "components/form/FormLoading";
import { debounce } from "lodash";
type Props = {
	listCodeExited: string[];
	onAdd?: (data: DealthomConfig) => Promise<void>;
};
export default function DealthomForm({ listCodeExited, onAdd }: Props) {
	const [form] = Form.useForm();
	console.log("🚀 ~ DealthomForm ~ form:", form.getFieldsError(["code"]));

	const {
		dataSource: dataPromotion,
		promotions,
		errors,
		fetchData,
		loading: loadingPromotion,
	} = usePromotion({});
	const [isUseCode, setIsUseCode] = useState(true);
	const initialValues: DealthomConfig = useMemo(() => {
		return {
			code: "",
			promotion_id: [],
			tag_infor: [
				{
					bg_color: "red",
					name: "Sale",
					text_color: "white",
					type: TypeTagConfig.CARD,
				},
				{
					bg_color: "red",
					name: "Sale",
					text_color: "white",
					type: TypeTagConfig.VARIANT,
				},
			],
		};
	}, []);

	const optionPromotions: SelectOption[] = useMemo(() => {
		const options: SelectOption[] = promotions.map((i: PromotionJson) => {
			return {
				label: i.name,
				value: i.id,
			};
		});
		return options;
	}, [promotions]);

	const [loading, setLoading] = useState(loadingPromotion);
	const [loadingSearch, setLoadingSearch] = useState(false);

	const onfetchDataDebounce = debounce((value: string) => {
		setLoadingSearch(true);
		value.length &&
			fetchData({ keyword: value }).finally(() => {
				setLoadingSearch(false);
			});
	}, 1000);

	const onSubmit = async (fomrData: DealthomConfig) => {
		onAdd && onAdd(fomrData);
	};

	return (
		<FormLoading loading={loading}>
			<Form form={form} onFinish={onSubmit} initialValues={initialValues}>
				<Form.Item label="Sử dụng mã nhập">
					<Checkbox
						defaultChecked={isUseCode}
						onChange={(e: CheckboxChangeEvent) => {
							form.resetFields(["code"]);
							setIsUseCode((prev) => !prev);
						}}></Checkbox>
				</Form.Item>
				<Row gutter={4}>
					<Col span={24}>
						<Form.Item
							hidden={!isUseCode}
							labelCol={{
								span: 24,
							}}
							label="Mã nhập"
							rules={[
								{
									required: isUseCode,
									message: "Vui lòng nhập code",
								},
								{
									pattern: /^[a-zA-Z0-9À-ỹà-ỹ]+$/,
									message: "Vui lòng không sử dụng ký tự đặc biệt",
								},
								{
									message: "Đã có mã code tồn tại",
									validator: (_: any, value: string) => {
										if (isUseCode && listCodeExited.includes(value)) {
											return Promise.reject("Some message here");
										} else {
											return Promise.resolve();
										}
									},
								},
							]}
							// help={<>Code hiển thị cho khác hàng nhập</>}
							name={"code"}>
							<Input></Input>
						</Form.Item>
					</Col>

					<Col span={24}>
						<FormSelect
							placeholder="Nhập đề tìm"
							loading={loadingSearch}
							onSearch={onfetchDataDebounce}
							multiple={true}
							lableCol={{
								span: 24,
							}}
							label="Chương trình áp dụng"
							options={optionPromotions}
							name={"promotion_id"}></FormSelect>
					</Col>
					<Col span={24}>
						<Form.Item className="text-right">
							<Button type="primary" htmlType="submit">
								Xác nhận
							</Button>
						</Form.Item>
					</Col>
					<Form.Item hidden name={"tag_infor"}></Form.Item>
				</Row>
			</Form>
		</FormLoading>
	);
}
