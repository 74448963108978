import { IconMapPin } from "@tabler/icons-react";

const DeliveryAddress = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) => {
  return (
    <>
      <a
        href={`https://maps.google.com/?q=${latitude},${longitude}`}
        target="_blank"
      >
        <IconMapPin />
      </a>
    </>
  );
};

export default DeliveryAddress;
