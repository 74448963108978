import { App, Badge, Button } from "antd";
import DeliveryPlanning from "common/constants/DeliveryPlanning";
import Role from "common/constants/Role";
import ShippingOrder from "common/constants/ShippingOrder";
import ShippingRoute from "common/constants/ShippingRoute";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import ShipperRepository from "common/repositories/ShipperRepository";
import ShippingHubRepository from "common/repositories/ShippingHubRepository";
import ShippingOrderRepository from "common/repositories/ShippingOrderRepository";
import ShippingRouteRepository from "common/repositories/ShippingRouteRepository";
import {
  DpFromPlaceSelectOption,
  DpShipperSelectOption
} from "common/types/DeliveryPlanning";
import RoleCheck from "components/RoleCheck";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useCallback, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import DeliveryPlanningCartSection from "./DeliveryPlanningCartSection";
import DeliveryPlanningCartSectionAdd from "./section/DeliveryPlanningCartSectionAdd";
import DeliveryPlanningCartSectionAssign from "./section/DeliveryPlanningCartSectionAssign";

const DeliveryPlanningCart = ({
  idList,
  setIdList,
  selectedOrder,
  setSelectedOrder,
  onSaveSuccess
}: {
  idList: number[];
  setIdList: (v: number[]) => void;
  selectedOrder: ShippingOrderModel[];
  setSelectedOrder: (v: ShippingOrderModel[]) => void;
  onSaveSuccess: (v: ShippingOrderModel[]) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [action, setAction] = useState<number>(DeliveryPlanning.ADD_ROUTE);
  const account = useLoginAccountStore((state) => state.account);

  const { height } = useWindowDimensions();

  //////////////////////////////////////////
  // Lazy loaded dynamic (for filters)
  //shipper
  const [shipperItems, setShipperItems] = useState<DpShipperSelectOption[]>([]);
  const [loadingShipper, setLoadingShipper] = useState(true);
  const fetchShipper = useCallback(async () => {
    setLoadingShipper(true);
    const collection = await new ShipperRepository().getItems({
      filters: { ...ShipperRepository.getDefaultFilters(), limit: 100 }
    });
    if (!collection.hasError()) {
      setShipperItems(
        collection.items.map((item) => ({
          label:
            item.internal_id !== ""
              ? item.full_name + " - " + item.internal_id
              : item.full_name,
          value: item.id,
          internal_id: item.internal_id
        }))
      );
    }
    setLoadingShipper(false);
  }, []);
  //////////////////////////////////////////
  // Lazy loaded dynamic (for filters)
  //shipping hub
  const [shippingHubItems, setShippingHubItems] = useState<
    DpFromPlaceSelectOption[]
  >([]);
  const [loadingShippingHub, setLoadingShippingHub] = useState(true);
  const fetchShippingHub = useCallback(async () => {
    setLoadingShippingHub(true);
    const collection = await new ShippingHubRepository().getItems({
      filters: { ...ShippingHubRepository.getDefaultFilters(), limit: 100 }
    });
    if (!collection.hasError()) {
      setShippingHubItems(
        collection.items.map((item, key) => ({
          label: item.name,
          value: key + 1,
          id: item.id,
          from_type: ShippingOrder.FROM_TYPE_OFFICE
        }))
      );
    }
    setLoadingShippingHub(false);
  }, []);

  //////////////////////////////////////////
  // Lazy loaded dynamic (for filters)
  //shipping route
  const [shippingRouteItems, setShippingRouteItems] = useState<
    ShippingRouteModel[]
  >([]);
  const [loadingShippingRoute, setLoadingShippingRoute] = useState(true);
  const fetchShippingRoute = useCallback(async () => {
    setLoadingShippingRoute(true);
    const collection = await new ShippingRouteRepository().getItems({
      filters: {
        ...ShippingRouteRepository.getDefaultFilters(),
        limit: 100,
        not_status_list: [
          ShippingRoute.STATUS_COMPLETED,
          ShippingRoute.STATUS_CANCELLED
        ]
      }
    });
    if (!collection.hasError()) {
      setShippingRouteItems(collection.items);
    }
    setLoadingShippingRoute(false);
  }, []);

  //////////////////////////////////////////
  // add route_id to order
  const addRouteIdToOrder = async (routeId: number) => {
    let errorCodeList: string[] = [];
    let orderSaveSuccess: ShippingOrderModel[] = [];

    for (let index = 0; index < selectedOrder.length; index++) {
      let item = selectedOrder[index];
      let orderItem: ShippingOrderModel =
        await new ShippingOrderRepository().saveRemote({
          ...item,
          company_id: account.company.id,
          creator_id: account.id,
          route_id: routeId
        });
      if (orderItem.hasError()) {
        errorCodeList.push(item.invoice_id);
        message.error({
          content:
            t("deliveryplanning:form.error.add_route_error") +
            " (" +
            errorCodeList.join(", ") +
            ".)",
          className: "message_error",
          key: "message",
          duration: 5
        });
      } else {
        orderSaveSuccess.push(orderItem);
        message.success({
          content: t("deliveryplanning:form.assign.success"),
          className: "message_success",
          key: "message",
          duration: 2
        });

        setIdList([]);
        setSelectedOrder([]);
      }
    }
    onSaveSuccess(orderSaveSuccess);
  };

  //////////////////////////////////////////
  // useEffect
  useEffect(() => {
    if (loadingShipper) {
      fetchShipper();
    }
  }, [loadingShipper, fetchShipper]);
  useEffect(() => {
    if (loadingShippingHub) {
      fetchShippingHub();
    }
  }, [loadingShippingHub, fetchShippingHub]);
  useEffect(() => {
    if (loadingShippingRoute) {
      fetchShippingRoute();
    }
  }, [loadingShippingRoute, fetchShippingRoute]);

  return (
    <div
      className={
        "fixed z-50 text-white duration-300 bg-gray-700 w-80 shadow-md rounded-bl-xl"
      }
      style={{ top: 60, height: height - 90 }}>
      <Scrollbars
        style={{
          height: height - 60,
          overflow: "hidden"
        }}
        autoHide>
        {idList.length === 0 ? (
          <div className="p-4 text-yellow-300">
            {t("deliveryplanning:cart.empty")}
          </div>
        ) : (
          <>
            <div className="p-4">
              {t("deliveryplanning:cart.selected_text_1")}
              <Badge
                count={idList.length}
                showZero={true}
                className="mx-1.5"
                style={{
                  backgroundColor: idList.length > 0 ? "#1890ff" : "#ff4d4f"
                }}
              />
              {t("deliveryplanning:cart.selected_text_2")}.
              <span
                className="ml-1 text-red-500 cursor-pointer hover:underline"
                onClick={() => {
                  setIdList([]);
                  setSelectedOrder([]);
                }}>
                {t("deliveryplanning:cart.clear")}
              </span>
              {idList.length > 0 ? (
                <>
                  <div className="mt-2 text-xs italic opacity-50">
                    {t("deliveryplanning:cart.selected_text_3")}
                  </div>
                </>
              ) : null}
            </div>
            <DeliveryPlanningCartSection
              action={action}
              setAction={setAction}
            />
          </>
        )}

        <div>
          {idList.length > 0 && action > 0 ? (
            action === DeliveryPlanning.ADD_ROUTE ? (
              <DeliveryPlanningCartSectionAdd
                shippingHubItems={shippingHubItems}
                shipperItems={shipperItems}
                selectedOrder={selectedOrder}
                addRouteIdToOrder={addRouteIdToOrder}
              />
            ) : (
              <DeliveryPlanningCartSectionAssign
                shippingRouteItems={shippingRouteItems}
                shippingHubItems={shippingHubItems}
                shipperItems={shipperItems}
                selectedOrder={selectedOrder}
                addRouteIdToOrder={addRouteIdToOrder}
              />
            )
          ) : null}
        </div>

        <div className={idList.length > 0 ? "px-4 mb-4" : "hidden"}>
          <RoleCheck roles={[Role.SHIPPING_ROUTE_MANAGE]} hideOnFail>
            <Button
              form={
                action === DeliveryPlanning.ADD_ROUTE
                  ? "add-route-form"
                  : "assign-route-form"
              }
              htmlType="submit"
              type="primary"
              block
              size="large">
              {action === DeliveryPlanning.ADD_ROUTE
                ? t("deliveryplanning:cart.btn_add_route")
                : t("deliveryplanning:cart.btn_assign_route")}
            </Button>
          </RoleCheck>
        </div>
      </Scrollbars>
    </div>
  );
};

export default DeliveryPlanningCart;
