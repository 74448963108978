import { Avatar, Tooltip } from "antd";
import ColorHash from "color-hash";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EmployeeJson } from "common/types/Employee";

type Props = {
  full_name?: string;
  className?: string;
  hideTooltip?: boolean;
  employee_id: number;
};
const UserNameAvatar = ({
  full_name = "",
  className = "",
  hideTooltip,
  employee_id,
}: Props) => {
  const [items] = useDatabaseTable<EmployeeJson>("employee");

  ///////////////////////////////
  //state
  const [employee, setEmployee] = useState<EmployeeJson | undefined>();

  ///////////////////////////////
  const findEmployee = useCallback(() => {
    const foundEmployee = items.find((i) => {
      return i.id === employee_id;
    });

    if (typeof foundEmployee !== "undefined") {
      setEmployee(foundEmployee);
    }
  }, [employee_id, items]);

  const letter = useMemo(() => {
    if (full_name.length > 0) {
      return full_name.split(" ").slice(-1).toString().charAt(0);
    } else {
      if (typeof employee !== "undefined") {
        return employee.full_name.split(" ").slice(-1).toString().charAt(0);
      } else return "";
    }
  }, [full_name]);

  const backgroundColor = useMemo(() => {
    return new ColorHash().hex(full_name);
  }, [full_name]);

  const avatar = (
    <Avatar style={{ backgroundColor: backgroundColor }}>{letter}</Avatar>
  );
  useEffect(() => {
    findEmployee();
  }, [full_name]);

  return (
    <div className={"avatar avatar-sm " + className}>
      {typeof hideTooltip === "undefined" || !hideTooltip ? (
        <Tooltip title={full_name}>{avatar}</Tooltip>
      ) : (
        avatar
      )}
    </div>
  );
};

export default UserNameAvatar;
