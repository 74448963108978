import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Helper from "common/utils/helper";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Col, DatePicker, Form, Input, Row } from "antd";
import React, { useCallback, useMemo, useState } from "react";

import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";
import ITRequestFormHeader from "./ITRequestFormHeader";

import ITRequestModel from "common/models/ITRequestModel";
import { ITRequestJsonAddEdit } from "common/types/ITRequest";
import ITRequestRepository from "common/repositories/ITRequestRepository";

type Props = {
  model: ITRequestModel;
};

const ITRequestForm = ({ model }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.id > 0;

  //state
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  //default data
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      deadline: model.deadline > 0 ? dayjs(new Date(model.deadline * 1000)) : 0,
      note: model.note,
      priority: model.priority > 0 ? model.priority : 1,
      status: model.status,
      public: model.public,
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ITRequestJsonAddEdit = {
        // ...ITRequestModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        deadline:
          Helper.convertedDateToTimeStamp(formData.deadline) > 0
            ? Helper.convertedDateToTimeStamp(formData.deadline)
            : 0,
        note: formData.note,
        priority: formData.priority,
        status: formData.status,
        public: formData.public,
        approve_by: "APPROVE_BY_MANAGER",
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: ITRequestJsonAddEdit) => {
    const response = await new ITRequestRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      setIsSuccess(true);
      setErrors([]);
    } else {
      setErrors(response.error.errors);
    }
  };

  //sidebar form
  const sidebarItems = <></>;

  const toolbarOptions = [
    [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction"],
    ["link", "image", "video", "formula"],
    ["clean"],
  ];

  return (
    <div>
      <ITRequestFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("common:form.success.general")
            : t("common:form.success.save")
        }
        redirectOnSuccess="/itrequest"
        error_translate_prefix="common:form.error.heading"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("itrequest:form.info")}
          subtitle={t("itrequest:form.details")}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("itrequest:name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("itrequest:form.error.error_name_require"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <FormSelect
                showSearch
                // required
                label={t("itrequest:priority")}
                name="priority"
                placeholder="Độ ưu tiên"
                options={ITRequestModel.getPriorityOption()}
              />
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label={t("itrequest:deadline")}
                name="deadline"
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "object" as const,
                    required: true,
                    message: t("itrequest:rules.deadline_required"),
                  },
                ]}
              >
                <DatePicker
                  showTime
                  inputReadOnly
                  style={{ width: "100%" }}
                  placeholder={"HH:mm dd/mm/yyyy"}
                  format="HH:mm DD/MM/YYYY"
                />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item name="note" label={t("itrequest:note")}>
                <ReactQuill
                  theme="snow"
                  style={{
                    height: "500px",
                    width: "100%",
                    marginBottom: "90px",
                  }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                  placeholder={t("itrequest:form.placeholder_note")}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </div>
  );
};

export default ITRequestForm;
