import EcomChannelOrderApi from 'common/api/EcomChannelOrderApi';
import EcomChannelOrderCollection from 'common/collections/EcomChannelOrderCollection';
import EcomSyncOrderModel from 'common/models/EcomSyncOrderModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type { FilterEcomChannelOrder } from "common/types/EcomChannelOrder";

class EcomChannelOrderRepository extends BaseRepository<EcomChannelOrderApi> {
  _api: EcomChannelOrderApi | null;

  constructor() {
    super();
    this._api = new EcomChannelOrderApi(true);
  }

  static getDefaultFilters(): FilterEcomChannelOrder {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      ids: "",
    };
  }

  async getItems(
    props: GetListProps<FilterEcomChannelOrder>,
    channel_id: number
  ) {
    return this._api
      ? await this._api.getList(props, channel_id)
      : new EcomChannelOrderCollection();
  }

  async syncOrder(
    channel_id: number,
    order_id: number
  ): Promise<EcomSyncOrderModel> {
    return this._api
      ? await this._api.syncOrder(channel_id, order_id)
      : new EcomSyncOrderModel(EcomSyncOrderModel.getDefaultData());
  }
}

export default EcomChannelOrderRepository;
