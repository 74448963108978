import React, { useCallback, useEffect, useState } from "react";

import type {
	FilterWarehouse,
	WarehouseJson,
	WarehouseJsonAddEdit,
	WarehouseCollectionJson,
} from "common/types/Warehouse";
import WarehouseRepository from "common/repositories/WarehouseRepository";

const useWarehouseStore = () => {
	const [filters, setFilters] = useState(
		WarehouseRepository.getDefaultFilters()
	);
	const [warehouse, setWarehouse] = useState<WarehouseJson[]>([]);

	const [loading, setLoading] = useState<boolean>(true);

	const fetchWarehouse = useCallback(async () => {
		setLoading(true);
		const res = await new WarehouseRepository().getItems({
			filters: filters,
		});
		if (!res.hasError()) {
			setWarehouse(res.toJson().items);
		}
		setLoading(false);
	}, [filters]);

	useEffect(() => {
		fetchWarehouse();
	}, [fetchWarehouse]);
	return {
		warehouse,
		loading,
		setFilters,
	};
};

export default useWarehouseStore;
