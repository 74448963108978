import { Form } from 'antd';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';
import { PosCartPayment } from 'common/types/PosCart';
import FormSelect from 'components/form/FormSelect';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';

import PosRightPaymentMethodCash from './PosRightPaymentMethodCash';

const PosRightPaymentMethodGiftcard = ({
  disabled,
  method,
  payValue,
  setEditingPayment,
  children,
}: {
  disabled: boolean;
  method: number;
  payValue: number;
  setEditingPayment: (v: PosCartPayment) => void;
  children?: React.ReactNode;
}) => {
  const [type, setType] = useState(0);

  const setEditingPaymentProxy = useCallback(
    (data: PosCartPayment) => {
      const foundType = _.find(CashflowReceiptModel.getGiftcardList(), [
        "value",
        type,
      ]);

      setEditingPayment({
        _id: "",
        method: method,
        value: data.value,
        note:
          "[" +
          (_.isUndefined(foundType) ? "" : foundType.label) +
          "] " +
          data.note,
      });
    },
    [method, setEditingPayment, type]
  );

  return (
    <PosRightPaymentMethodCash
      disabled={disabled}
      method={method}
      payValue={payValue}
      setEditingPayment={setEditingPaymentProxy}
    >
      <Form.Item label="Chọn loại thẻ thanh toán">
        <FormSelect
          name="type"
          value={type}
          onChange={(value) =>
            typeof value === "number" ? setType(value) : null
          }
          options={CashflowReceiptModel.getGiftcardList()}
        />
      </Form.Item>
    </PosRightPaymentMethodCash>
  );
};

export default PosRightPaymentMethodGiftcard;
