import ProductInventoryCheckingModel from 'common/models/ProductInventoryCheckingModel';
import ProductInventoryCheckingRepository from 'common/repositories/ProductInventoryCheckingRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import ProductInventoryCheckingForm from 'features/productinventorychecking/form/ProductInventoryCheckingForm';
import ProductInventoryCheckingFormHeader from 'features/productinventorychecking/form/ProductInventoryCheckingFormHeader';
import ProductInventoryCheckingList from 'features/productinventorychecking/list/ProductInventoryCheckingList';
import ProductInventoryCheckingMergeForm from 'features/productinventorycheckingmerge/form/ProductInventoryCheckingMergeForm';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const ProductInventoryCheckingPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<ProductInventoryCheckingModel>(
    new ProductInventoryCheckingModel(
      ProductInventoryCheckingModel.getDefaultData()
    )
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(
      new ProductInventoryCheckingModel(
        ProductInventoryCheckingModel.getDefaultData()
      )
    );
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <ProductInventoryCheckingForm
          model={
            new ProductInventoryCheckingModel({
              ...ProductInventoryCheckingModel.getDefaultData(),
              warehouse_id: +pathParams.warehouse_id,
            })
          }
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ProductInventoryCheckingForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel =
                await new ProductInventoryCheckingRepository().getItem(
                  parseInt(pathParams.id.toString())
                );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={
              <ProductInventoryCheckingFormHeader
                isEditing={true}
                id={+pathParams.id}
              />
            }
          />
        );

      break;
    case "merge":
      com = (
        <ProductInventoryCheckingMergeForm
          model={
            new ProductInventoryCheckingModel({
              ...ProductInventoryCheckingModel.getDefaultData(),
              warehouse_id: +pathParams.warehouse_id,
            })
          }
        />
      );
      break;
    default:
      com = <ProductInventoryCheckingList />;
  }

  return com;
};

export default ProductInventoryCheckingPage;
