import HomeIndex from "features/home/HomeIndex";
import React from "react";

const HomePage = () => {
  return (
    <>
      <HomeIndex />
    </>
  );
};

export default HomePage;
