import ContactMailCollection from "common/collections/ContactMailCollection";
import ContactMailModel from "common/models/ContactMailModel";
import {
  ContactMailAddEditJson,
  FilterContactMail,
} from "common/types/ContactMail";
import type { GetListProps } from "common/types/Filter";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import Helper from "common/utils/helper";

const SERVICE_URL = "/customers/notification";
const SERVICE_URL_2 = "/customers/public/notification";

class ContactMailApi extends BaseApi {
  async getList(
    props: GetListProps<FilterContactMail>
  ): Promise<ContactMailCollection> {
    let collection = new ContactMailCollection();
    const convertProps = Helper.convertParams(props.filters);

    const queryData = {
      params: {
        ...convertProps,
      },
    };
    try {
      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async getDetail(id: number): Promise<ContactMailModel> {
    let item = new ContactMailModel(ContactMailModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ContactMailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async add(data: ContactMailAddEditJson): Promise<ContactMailModel> {
    // console.log(data);
    let item = new ContactMailModel(ContactMailModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL_2, postData);
      if (response.hasOwnProperty("data")) {
        item = new ContactMailModel(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: ContactMailAddEditJson): Promise<any> {
    let item = new ContactMailModel(ContactMailModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL_2 + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new ContactMailModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ContactMailApi;
