import { Dropdown, MenuProps } from "antd";
import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import { useTranslation } from "react-i18next";

import {
  IconBorderHorizontal,
  IconBorderInner,
  IconBorderOuter,
  IconBorderVertical,
  IconColumnInsertLeft,
  IconColumnInsertRight,
  IconRowInsertBottom,
  IconRowInsertTop,
  IconTable,
  IconTableOff,
  IconTool,
} from "@tabler/icons-react";

import HtmlEditorMenuBarButton from "./HtmlEditorMenuBarButton";

const HtmlEditorMenuBarButtonTable = ({ editor }: { editor: HtmlEditor }) => {
  const { t } = useTranslation("htmleditor");

  if (!editor) {
    return null;
  }

  const insertTable = () => {
    editor
      .chain()
      .focus()
      .insertTable({ rows: 3, cols: 4, withHeaderRow: true })
      .run();
  };

  const onClickItem = ({ key }: { key: string }) => {
    switch (key) {
      case "add_column_before":
        editor.chain().focus().addColumnBefore().run();
        break;
      case "add_column_after":
        editor.chain().focus().addColumnAfter().run();
        break;
      case "delete_column":
        editor.chain().focus().deleteColumn().run();
        break;
      case "add_row_before":
        editor.chain().focus().addRowBefore().run();
        break;
      case "add_row_after":
        editor.chain().focus().addRowAfter().run();
        break;
      case "delete_row":
        editor.chain().focus().deleteRow().run();
        break;
      case "merge_cells":
        editor.chain().focus().mergeCells().run();
        break;
      case "split_cell":
        editor.chain().focus().splitCell().run();
        break;

      case "insert_table":
        insertTable();
        break;
      case "delete_table":
        editor.chain().focus().deleteTable().run();
        break;

      default:
    }
  };

  const iconProps = { size: 18 };

  const isActive = editor.isActive("table");
  const items: MenuProps["items"] = [
    {
      key: "add_column_before",
      label: t("table.add_column_before"),
      icon: <IconColumnInsertLeft {...iconProps} />,
      disabled: !editor.can().addColumnBefore(),
    },
    {
      key: "add_column_after",
      label: t("table.add_column_after"),
      icon: <IconColumnInsertRight {...iconProps} />,
      disabled: !editor.can().addColumnAfter(),
    },
    {
      key: "add_row_before",
      label: t("table.add_row_before"),
      icon: <IconRowInsertTop {...iconProps} />,
      disabled: !editor.can().addRowBefore(),
    },
    {
      key: "add_row_after",
      label: t("table.add_row_after"),
      icon: <IconRowInsertBottom {...iconProps} />,
      disabled: !editor.can().addRowAfter(),
    },
    {
      key: "tool",
      label: t("table.menu_other"),
      icon: <IconTool {...iconProps} />,
      children: [
        {
          key: "merge_cells",
          label: t("table.merge_cells"),
          icon: <IconBorderOuter {...iconProps} />,
          disabled: !editor.can().mergeCells(),
        },
        {
          key: "split_cell",
          label: t("table.split_cell"),
          icon: <IconBorderInner {...iconProps} />,
          disabled: !editor.can().splitCell(),
        },
        {
          key: "insert_table",
          label: t("table.insert_table_inside"),
          icon: <IconTable {...iconProps} />,
        },
        {
          type: "divider",
        },
        {
          key: "delete_row",
          label: t("table.delete_row"),
          icon: <IconBorderHorizontal {...iconProps} />,
          disabled: !editor.can().deleteRow(),
        },
        {
          key: "delete_column",
          label: t("table.delete_column"),
          icon: <IconBorderVertical {...iconProps} />,
          disabled: !editor.can().deleteColumn(),
        },

        {
          key: "delete_table",
          label: t("table.delete_table"),
          icon: <IconTableOff {...iconProps} />,
          disabled: !editor.can().deleteTable(),
          danger: true,
        },
      ],
    },
  ];

  return (
    <>
      {isActive ? (
        <>
          <Dropdown menu={{ items, onClick: onClickItem }} trigger={["click"]}>
            <HtmlEditorMenuBarButton
              active={false}
              disabled={false}
              icon={<IconTable />}
              title={""}
              onClick={() => {}}
              showCaret
            />
          </Dropdown>
        </>
      ) : (
        <HtmlEditorMenuBarButton
          active={false}
          disabled={false}
          icon={<IconTable />}
          title={t("table.insert_table")}
          onClick={() => {
            insertTable();
          }}
        />
      )}
    </>
  );
};

export default HtmlEditorMenuBarButtonTable;
