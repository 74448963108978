import { Button, Tag } from "antd";
import WebHookCollection from "common/collections/WebHookCollection";
import Role from "common/constants/Role";
import WebHookModel from "common/models/WebHookModel";
import WebHookRepository from "common/repositories/WebHookRepository";
import { TableColumnsType } from "common/types/Table";
import { FilterWebHook } from "common/types/WebHook";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import WebHookFormModal from "features/webhook/form/WebHookFormModal";
import WebHookListHeader from "features/webhook/list/WebHookListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import WebHookListFilter from "./WebHookListFilter";

const WebHookList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterWebHook = useMemo(
    () => WebHookRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterWebHook>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<WebHookModel[]>([]);
  const fetchData = useCallback(async (): Promise<WebHookCollection> => {
    let collection = await new WebHookRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
    return collection;
  }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<WebHookModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(id)}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("webhook:name"),
      key: "event",
      render: (event: string) => {
        var str = "event_" + event.replace(/\./g, "_");
        return (
          <>
            {t("webhook:" + str)}{" "}
            <Tag className="float-right font-mono" color="gray">
              {event}
            </Tag>
          </>
        );
      },
    },
    {
      title: t("webhook:hook_url"),
      key: "meta_data",
      render: (meta_data: any) => {
        return <>{meta_data.webhook.url}</>;
      },
    },
    {
      title: t("common:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = WebHookModel.getStatus(status);
        return <>{statusInfo?.label}</>;
      },
      width: 110,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: WebHookModel) => (
        <RoleCheck roles={[Role.SETTING_ADVANCED]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="webhook:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new WebHookRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: WebHookModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        })
      );
    }
  };

  return (
    <>
      <WebHookListHeader>
        <RoleCheck roles={[Role.SETTING_ADVANCED]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </WebHookListHeader>
      <WebHookListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<FilterWebHook, WebHookModel, WebHookCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <WebHookFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default WebHookList;
