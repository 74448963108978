import { Button, Col, Modal, Row } from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import { PosCartPayment } from "common/types/PosCart";
import TextMoney from "components/TextMoney";
import update from "immutability-helper";
import { difference, head } from "lodash";
import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
import usePosCartStore from "zustands/usePosCartStore";

import PosRightPaymentMethodBank from "./method/PosRightPaymentMethodBank";
import PosRightPaymentMethodCash from "./method/PosRightPaymentMethodCash";
import PosRightPaymentMethodCustomerPoint from "./method/PosRightPaymentMethodCustomerPoint";
import PosRightPaymentMethodGiftcard from "./method/PosRightPaymentMethodGiftcard";
import PosRightPaymentMethodItem from "./PosRightPaymentMethodItem";

const PosRightPaymentModal = ({
  payValue,
  show,
  setShow,
}: {
  payValue: number;
  show: boolean;
  setShow: (v: boolean) => void;
}) => {
  const payments: PosCartPayment[] = usePosCartStore(
    (state) => state.getActiveCart()?.payments || []
  );
  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);
  const selectedMethods = payments.map((item) => item.method);

  //Display Card for select payment method
  const posMethods = [
    CashflowReceipt.METHOD_CASH,
    CashflowReceipt.METHOD_BANK,
    CashflowReceipt.METHOD_GIFTCARD,
    CashflowReceipt.METHOD_CUSTOMER_POINT,
    // CashflowReceipt.METHOD_MOMO,
    // CashflowReceipt.METHOD_VNPAY,
    // CashflowReceipt.METHOD_MEMBERCARD,
  ];

  const handleClose = () => {
    setShow(false);
  };

  const [activeMethod, setActiveMethod] = useState(
    head(difference(posMethods, selectedMethods))
  );
  const [editingPayment, setEditingPayment] = useState<PosCartPayment>({
    _id: "",
    method: 0,
    value: 0,
    note: "",
  });

  //save payment
  const onApply = useCallback(() => {
    if (editingPayment.value > 0) {
      if (editingPayment.value <= payValue) {
        updateActiveCart({
          payments: update(payments, {
            $push: [
              {
                _id: uuid(),
                method: activeMethod,
                value: editingPayment.value,
                note: editingPayment.note,
              } as PosCartPayment,
            ],
          }),
        });
        setShow(false);
      } else {
        alert(
          "Số tiền cần thu lớn hơn giá trị còn lại của đơn hàng cần thanh toán."
        );
      }
    }
  }, [
    editingPayment,
    payValue,
    payments,
    activeMethod,
    updateActiveCart,
    setShow,
  ]);

  let activeMethodCom = null;
  let editProps = {
    method: activeMethod || -1,
    payValue: payValue,
    setEditingPayment: setEditingPayment,
  };

  switch (activeMethod) {
    case CashflowReceipt.METHOD_CASH:
      activeMethodCom = (
        <PosRightPaymentMethodCash
          disabled={selectedMethods.indexOf(activeMethod) >= 0}
          {...editProps}
        />
      );
      break;
    case CashflowReceipt.METHOD_BANK:
      activeMethodCom = (
        <PosRightPaymentMethodBank
          disabled={selectedMethods.indexOf(activeMethod) >= 0}
          {...editProps}
        />
      );
      break;
    case CashflowReceipt.METHOD_GIFTCARD:
      activeMethodCom = (
        <PosRightPaymentMethodGiftcard
          disabled={selectedMethods.indexOf(activeMethod) >= 0}
          {...editProps}
        />
      );
      break;
    case CashflowReceipt.METHOD_CUSTOMER_POINT:
      activeMethodCom = (
        <PosRightPaymentMethodCustomerPoint
          disabled={selectedMethods.indexOf(activeMethod) >= 0}
          {...editProps}
        />
      );
      break;
    case CashflowReceipt.METHOD_MOMO:
      // activeMethodCom = (
      //   <PosRightPaymentMethodMomo
      //     disabled={selectedMethods.indexOf(activeMethod) >= 0}
      //     {...editProps}
      //   />
      // );
      break;
    case CashflowReceipt.METHOD_VNPAY:
      // activeMethodCom = (
      //   <PosRightPaymentMethodVnPay
      //     disabled={selectedMethods.indexOf(activeMethod) >= 0}
      //     {...editProps}
      //   />
      // );
      break;
    case CashflowReceipt.METHOD_MEMBERCARD:
      // activeMethodCom = (
      //   <PosRightPaymentMethodMemberCard
      //     disabled={selectedMethods.indexOf(activeMethod) >= 0}
      //     {...editProps}
      //   />
      // );
      break;
    default:
  }

  return (
    <Modal
      width={1000}
      open={show}
      onCancel={handleClose}
      title="Chọn phương thức thanh toán"
      footer={[
        <Button key="label" className="float-left" type="text">
          Cần phải thu:{" "}
          <strong>
            <TextMoney money={payValue} />
          </strong>
        </Button>,
        <Button
          key="primary"
          type="primary"
          disabled={payValue < 0}
          onClick={onApply}
        >
          Áp dụng
        </Button>,
      ]}
    >
      <Row align="bottom">
        {posMethods.map((method: number) => (
          <Col key={method} flex="1">
            <PosRightPaymentMethodItem
              method={method}
              disabled={selectedMethods.indexOf(method) >= 0}
              onClick={() => {
                setActiveMethod(method);
              }}
              active={activeMethod === method}
            />
          </Col>
        ))}
      </Row>
      <div className="p-4 pos-payment-method-container">{activeMethodCom}</div>
    </Modal>
  );
};

export default PosRightPaymentModal;
