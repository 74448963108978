import { Skeleton } from "antd";
import DepartmentModel from "common/models/DepartmentModel";
import DepartmentRepository from "common/repositories/DepartmentRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import DepartmentForm from "features/department/form/DepartmentForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DepartmentFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: DepartmentModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<DepartmentModel>(
    new DepartmentModel(DepartmentModel.getDefaultData()),
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new DepartmentRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <FormModal
      title={
        id > 0 ? t("department:heading_edit") : t("department:heading_add")
      }
      width={400}
      open={open}
      setOpen={setOpen}
      confirmLoading={processing}
      formId="department-form"
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <DepartmentForm
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <DepartmentForm
          model={new DepartmentModel(DepartmentModel.getDefaultData())}
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </FormModal>
  );
};

export default DepartmentFormModal;
