import { Alert, Form, Tag } from "antd";
import PurchaseOrder from "common/constants/PurchaseOrder";
import Role from "common/constants/Role";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import PurchaseOrderRepository from "common/repositories/PurchaseOrderRepository";
import { PurchaseOrderJsonAddEdit } from "common/types/PurchaseOrder";
import { PurchaseOrderDetailJson } from "common/types/PurchaseOrderDetail";
import LayoutForm from "components/form/LayoutForm";
import RoleCheck from "components/RoleCheck";
import SimpleCollapse from "components/SimpleCollapse";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import PurchaseOrderFormHeader from "./PurchaseOrderFormHeader";
import PurchaseOrderFormSectionCashflowReceipt from "./section/cashflowreceipt/PurchaseOrderFormSectionCashflowReceipt";
import PurchaseOrderFormSectionProductReceipt from "./section/productreceipt/PurchaseOrderFormSectionProductReceipt";
import PurchaseOrderFormSectionDetail from "./section/PurchaseOrderFormSectionDetail";
import PurchaseOrderFormSectionGeneral from "./section/PurchaseOrderFormSectionGeneral";
import PurchaseOrderFormSectionInfo from "./section/PurchaseOrderFormSectionInfo";
import PurchaseOrderFormSectionStatus from "./section/PurchaseOrderFormSectionStatus";

const PurchaseOrderForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [purchaseOrder, supplier, setPurchaseOrder] =
    useEditingPurchaseOrderStore((state) => [
      state.purchaseOrder,
      state.supplier,
      state.setPurchaseOrder
    ]);
  const [status, setStatus] = useState(purchaseOrder.status);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const hasRole = useLoginAccountStore((state) => state.hasRole);

  //check can edit update input info (add/edit features)
  const allowEditInput = useMemo(() => {
    return (
      (purchaseOrder.id > 0 &&
        hasRole(Role.PURCHASE_ORDER_EDIT) &&
        purchaseOrder.status < PurchaseOrder.STATUS_COMPLETED) ||
      (purchaseOrder.id === 0 && hasRole(Role.PURCHASE_ORDER_ADD))
    );
  }, [purchaseOrder.status, purchaseOrder.id, hasRole]);

  //check allow set status to COMPLETE
  const allowStatusComplete = useMemo(() => {
    return (
      hasRole(Role.PURCHASE_ORDER_STATUS_COMPLETE) &&
      purchaseOrder.status <= PurchaseOrder.STATUS_COMPLETED
    );
  }, [hasRole, purchaseOrder.status]);

  //check allow set status to CANCEL
  const allowStatusCancel = useMemo(() => {
    return (
      hasRole(Role.PURCHASE_ORDER_STATUS_CANCEL) &&
      purchaseOrder.status < PurchaseOrder.STATUS_CANCELLED
    );
  }, [hasRole, purchaseOrder.status]);

  const enableSubmitButton = useMemo(() => {
    return allowEditInput || allowStatusComplete || allowStatusCancel;
  }, [allowEditInput, allowStatusComplete, allowStatusCancel]);

  const [details, setDetails] = useState<PurchaseOrderDetailJson[]>([]);

  const initialValues = useMemo(() => {
    return {
      price_sell: purchaseOrder.price_sell,
      price_discount: purchaseOrder.price_discount,
      price_shipping: purchaseOrder.price_shipping,
      price_final: purchaseOrder.price_final,

      cancel_reason:
        purchaseOrder.cancel_reason > 0 ? purchaseOrder.cancel_reason : null,
      note: purchaseOrder.note,
      tag: purchaseOrder.tag
    };
  }, [purchaseOrder]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: PurchaseOrderJsonAddEdit = {
        id: purchaseOrder.id,
        supplier_id: purchaseOrder.supplier_id,
        warehouse_id: formData.warehouse_id,
        invoice_id: formData.invoice_id,
        status: status,
        cancel_reason: formData.cancel_reason,
        tag: formData.tag,
        note: formData.note,

        price_sell: formData.price_sell,
        price_discount: formData.price_discount,
        price_shipping: formData.price_shipping,
        price_final: formData.price_final,

        details
      };

      return submitData;
    },
    [purchaseOrder.id, purchaseOrder.supplier_id, details, status]
  );

  //submit data to server
  const onSubmit = useCallback(
    async (formData: any) => {
      // setIsSuccess(false);

      const preparedData = doPrepareData(formData);

      let updatedPurchaseOrder: PurchaseOrderModel =
        await new PurchaseOrderRepository().saveRemote(preparedData);

      if (updatedPurchaseOrder.hasError()) {
        setErrors(updatedPurchaseOrder.error.errors);
      } else {
        //sync status to UI
        // setInitStatus(item.status);
        setPurchaseOrder({ ...purchaseOrder, ...preparedData }, supplier);

        setIsSuccess(true);
      }
    },
    [doPrepareData, purchaseOrder, supplier, setPurchaseOrder]
  );

  let sidebarItems = (
    <>
      <PurchaseOrderFormSectionStatus
        allowEditInput={allowEditInput}
        allowStatusComplete={allowStatusComplete}
        allowStatusCancel={allowStatusCancel}
        status={status}
        setStatus={setStatus}
      />

      <PurchaseOrderFormSectionInfo />

      <div className="">
        <PurchaseOrderFormSectionGeneral
          form={form}
          allowEditInput={allowEditInput}
        />
      </div>
    </>
  );

  const detailSection = (
    <PurchaseOrderFormSectionDetail
      setDetails={setDetails}
      form={form}
      allowEditInput={allowEditInput}
    />
  );

  return (
    <>
      <PurchaseOrderFormHeader />

      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          purchaseOrder.id > 0
            ? t("purchaseorder:form.success.update")
            : t("purchaseorder:form.success.add")
        }
        error_translate_prefix="purchaseorder:form.error"
        onSubmit={onSubmit}
        submitText={
          purchaseOrder.id > 0 ? t("common:form.save") : t("common:form.submit")
        }
        submitDisabled={!enableSubmitButton}
        sidebarItems={sidebarItems}
        id="purchaseorder-form"
        redirectOnSuccess={purchaseOrder.id > 0 ? "" : "/purchaseorder"}>
        <div>
          {purchaseOrder.id > 0 ? (
            <>
              <SimpleCollapse
                header={
                  <>
                    {t("purchaseorder:form.section_detail")}{" "}
                    <Tag color="blue">{supplier.name}</Tag>
                  </>
                }
                childrenItem={detailSection}
              />

              <RoleCheck
                roles={[Role.INVENTORY_VIEW]}
                componentOnFail={
                  <Alert
                    className="mt-4"
                    type="warning"
                    showIcon
                    message={t(
                      "purchaseorder:form.product_receipt_not_view_permission"
                    )}></Alert>
                }>
                <PurchaseOrderFormSectionProductReceipt />
              </RoleCheck>

              <RoleCheck
                roles={[Role.CASHFLOW_VIEW]}
                componentOnFail={
                  <Alert
                    className="mt-4"
                    type="warning"
                    showIcon
                    message={t(
                      "purchaseorder:form.cashflow_receipt_not_view_permission"
                    )}></Alert>
                }>
                <PurchaseOrderFormSectionCashflowReceipt form={form} />
              </RoleCheck>
            </>
          ) : (
            detailSection
          )}
        </div>
      </LayoutForm>
    </>
  );
};

export default PurchaseOrderForm;
