import { App, Input } from "antd";
import OrderHelperRepository from "common/repositories/OrderHelperRepository";
import ErrorAlert from "components/ErrorAlert";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

const OrderFormSectionProductReceiptLinkForm = ({
  onSuccess
}: {
  onSuccess: () => void;
}) => {
  const { message } = App.useApp();

  const order = useEditingSaleOrderStore((state) => state.order);

  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);

  const doLinking = useCallback(
    async (receipt_id: string) => {
      setProcessing(true);
      setErrors([]);
      const error: string[] =
        await new OrderHelperRepository().linkProductReceipt(
          order.invoice_id,
          receipt_id
        );
      setProcessing(false);
      if (error.length > 0) {
        setErrors(error);
      } else {
        //success
        message.success(t("order:form.link_receipt.success"));
        onSuccess();
      }
    },
    [setProcessing, order.invoice_id, onSuccess, t, message]
  );

  return (
    <>
      <Input.Search
        placeholder={t("order:form.link_receipt.product_receipt_id")}
        addonBefore="#"
        onSearch={(value) => (value.length > 0 ? doLinking(value) : {})}
        loading={processing}
        enterButton={t("order:form.link_receipt.submit")}
        allowClear
      />
      {errors.length > 0 && (
        <>
          <ErrorAlert
            items={errors}
            translate_prefix="order:form.link_receipt.error"
            className="p-2 mt-2"
          />
        </>
      )}
    </>
  );
};

export default OrderFormSectionProductReceiptLinkForm;
