import BaseCollection from 'common/collections/BaseCollection';
import EcomLogModel from 'common/models/EcomLogModel';
import { EcomLogCollectionJson, EcomLogJson } from 'common/types/EcomLog';

class EcomLogCollection extends BaseCollection<
  EcomLogJson,
  EcomLogModel,
  EcomLogCollectionJson
> {
  itemsFromJson(jsonItems: EcomLogJson[]): EcomLogModel[] {
    return jsonItems.map((item) => new EcomLogModel(item));
  }
}

export default EcomLogCollection;
