import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import update from "immutability-helper";

import {
  FilterTask,
  TaskEmployeeCollectionJson,
  TaskJsonAddEdit,
  TaskJsonEdit,
  TaskJsonWithChildren,
  TaskMOMCollectionJson,
  TaskScopeItemJson,
} from "common/types/Task";
import Helper from "common/utils/helper";
import TaskModel from "common/models/TaskModel";
import { GetListProps } from "common/types/Filter";
import TaskCollection from "common/collections/TaskCollection";
import NestedCollection from "common/collections/NestedCollection";
import TaskHistoryCollection from "common/collections/TaskHistoryCollection";

const SERVICE_URL = "/tasks";
class TaskApi extends BaseApi {
  //
  async getList(props: GetListProps<FilterTask>): Promise<TaskCollection> {
    let collection = new TaskCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
        sort_by: props.filters.sortby,
        sort_type: props.filters.sorttype,
      },
    };

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getListUser(props: GetListProps<FilterTask>): Promise<TaskCollection> {
    let collection = new TaskCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
        sort_by: props.filters.sortby,
        sort_type: props.filters.sorttype,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/user",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getListCurrentEmployee(
    props: GetListProps<FilterTask>
  ): Promise<TaskEmployeeCollectionJson> {
    let collection = {
      total: 0,
      items: [],
      error: [],
    } as TaskEmployeeCollectionJson;

    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/employee",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection = response.data;
      }
    } catch (error) {
      collection.error = [JSON.stringify(error)];
    }
    return collection;
  }
  //
  async getListMOMEmployee(
    props: GetListProps<Pick<FilterTask, "page" | "limit" | "employee_id">>
  ): Promise<TaskMOMCollectionJson> {
    let collection = {
      total: 0,
      currentpage: 0,
      limit: 0,
      standup_today: [],
      standup_yesterday: [],
      standup_lastndays: [],
      error: [],
    } as TaskMOMCollectionJson;

    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
        sort_by: "org_fromtime",
        sort_type: "DESC",
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/mine",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection = { ...response.data, error: [] };
      }
    } catch (error) {
      collection.error = [JSON.stringify(error)];
    }
    return collection;
  }
  //
  async getListHistoryTask(
    props: GetListProps<FilterTask>
  ): Promise<TaskHistoryCollection> {
    let collection = new TaskHistoryCollection();

    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/getlisthistory",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getAll(
    props: GetListProps<FilterTask>
  ): Promise<NestedCollection<TaskJsonWithChildren>> {
    let allItems: TaskJsonWithChildren[] = [];
    let collection = new NestedCollection<TaskJsonWithChildren>();
    let page = 1;

    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
        page: page,
        limit: 500,
        sort_by: "",
        sort_type: "ASC",
      },
    };

    try {
      do {
        const currentPageCollection = await AxiosClient().get<any>(
          SERVICE_URL,
          queryData
        );

        if (currentPageCollection.hasOwnProperty("data")) {
          allItems = update(allItems, {
            $push: currentPageCollection.data.items,
          });

          if (
            currentPageCollection.data.items.length < queryData.params.limit
          ) {
            break;
          } else {
            page++;
          }
        } else {
          break;
        }
      } while (true);

      collection.buildNested(allItems);
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getListScope(ids: number[]): Promise<TaskScopeItemJson[]> {
    let collection = [];

    const queryData = {
      params: {
        list_scope_ids: ids.join(","),
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/scope",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection = response.data;
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getDetail(id: number): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add(props: TaskJsonAddEdit): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: TaskJsonAddEdit): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editRequest(props: TaskJsonEdit): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());
    // remove id key
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editStatus(status: number, id: number): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    try {
      const postData = {
        status: status,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editDisplayOrder(
    id: number,
    display_order: number
  ): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    try {
      const postData = {
        display_order: display_order,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editKanbanPosition(
    id: number,
    status: number,
    display_order: number
  ): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    try {
      const postData = {
        status: status,
        my_display_order: display_order,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editKanbanDisplayOrder(
    id: number,
    display_order: number
  ): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    try {
      const postData = {
        my_display_order: display_order,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editKanbanDepartmentPosition(
    id: number,
    status: number,
    display_order: number
  ): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    try {
      const postData = {
        status: status,
        full_display_order: display_order,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async editFullDisplayOrder(
    id: number,
    display_order: number
  ): Promise<TaskModel> {
    let item = new TaskModel(TaskModel.getDefaultData());

    try {
      const postData = {
        full_display_order: display_order,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TaskModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(SERVICE_URL + "/delete/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default TaskApi;
