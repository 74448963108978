import ShippingRouteApi from "common/api/ShippingRouteApi";
import ShippingRouteCollection from "common/collections/ShippingRouteCollection";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import { GetListProps } from "common/types/Filter";

import BaseRepository from "./BaseRepository";

import type {
  ShippingRouteJsonAddEdit,
  FilterShippingRoute,
  ShippingRouteTwoPointCoordinates,
  ShippingRouteDistanceTwoPoint,
} from "common/types/ShippingRoute";

class ShippingRouteRepository extends BaseRepository<ShippingRouteApi> {
  _api: ShippingRouteApi | null;

  constructor() {
    super();
    this._api = new ShippingRouteApi(true);
  }

  static getDefaultFilters(): FilterShippingRoute {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      creator_id: -1,
      shipper_id: -1,
      from_warehouse_id: -1,
      ymd: -1,
      id: -1,
      ymdstart: -1,
      ymdend: -1,
      date_started: -1,
      date_ended: -1,
      not_status_list: [],
      get_full_info: false,
    };
  }

  async getItems(props: GetListProps<FilterShippingRoute>) {
    return this._api
      ? await this._api.getList(props)
      : new ShippingRouteCollection();
  }

  async getItem(id: number): Promise<ShippingRouteModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShippingRouteModel(ShippingRouteModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ShippingRouteJsonAddEdit
  ): Promise<ShippingRouteModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShippingRouteModel(ShippingRouteModel.getDefaultData());
    }
  }

  async getDistanceTwoPoint(
    coordinates: ShippingRouteTwoPointCoordinates
  ): Promise<ShippingRouteDistanceTwoPoint> {
    return this._api
      ? await this._api.getDistanceTwoPoint(coordinates)
      : ShippingRouteModel.getDefaultDataCoordinates();
  }
}

export default ShippingRouteRepository;
