import BaseCollection from 'common/collections/BaseCollection';
import ProductReceiptSyncStockModel from 'common/models/ProductReceiptSyncStockModel';
import {
    ProductReceiptSyncStockCollectionJson, ProductReceiptSyncStockJson
} from 'common/types/ProductReceiptSyncStock';

class ProductReceiptSyncStockCollection extends BaseCollection<
  ProductReceiptSyncStockJson,
  ProductReceiptSyncStockModel,
  ProductReceiptSyncStockCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductReceiptSyncStockJson[]
  ): ProductReceiptSyncStockModel[] {
    return jsonItems.map((item) => new ProductReceiptSyncStockModel(item));
  }
}

export default ProductReceiptSyncStockCollection;
