import React, { ReactNode } from 'react';

import { Marker } from '@goongmaps/goong-map-react';

const GoongMapMarker = ({
  longitude,
  latitude,
  children,
}: {
  longitude: number;
  latitude: number;
  children?: ReactNode;
}) => {
  return (
    <>
      <Marker
        longitude={longitude}
        latitude={latitude}
        offsetTop={-20}
        offsetLeft={-15}
      >
        {children}
      </Marker>
    </>
  );
};

export default GoongMapMarker;
