import React, { useMemo } from "react";
import styles from "./TagInfor.module.css";
import TextDateTimeRelative from "components/TextDateTimeRelative";
import EyesIcon from "features/academy/icon/EyesIcon";
import ThumbUpIcon from "features/academy/icon/ThumbUpIcon";
import MessageIcon from "features/academy/icon/MessageIcon";
import { StoryJson } from "common/types/Story";
import { SizeCard } from "../../types";
type Props = {
  item: StoryJson;
  size: SizeCard;
};
const TagInfor = ({ item, size }: Props) => {
  const varibleClass = useMemo(() => {
    switch (size) {
      case "small":
        return "sizeSmall";

      default:
        return "sizeDefault";
    }
  }, [size]);
  return (
    <div className={`${varibleClass} ${styles.wrapper}`}>
      <div className="w-full whitespace-nowrap">
        <TextDateTimeRelative ts={item.date_created}></TextDateTimeRelative>
      </div>
      <div className="inline-flex w-full justify-start lg:justify-end gap-2 text-center">
        <span className="whitespace-nowrap text-center flex items-center leading-2">
          <EyesIcon></EyesIcon>
          {item.viewed}
        </span>
        <span className="whitespace-nowrap text-center flex items-center leading-2">
          <ThumbUpIcon color="gray"></ThumbUpIcon>
          {item.visitor}
        </span>
        <span className="whitespace-nowrap text-center flex items-center leading-2">
          <MessageIcon color="gray"></MessageIcon>
          {item.comment_total}
        </span>
      </div>
    </div>
  );
};

export default TagInfor;
