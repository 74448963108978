import BaseCollection from 'common/collections/BaseCollection';
import OrderDetailModel from 'common/models/OrderDetailModel';
import { OrderDetailCollectionJson, OrderDetailJson } from 'common/types/OrderDetail';

class OrderDetailCollection extends BaseCollection<
  OrderDetailJson,
  OrderDetailModel,
  OrderDetailCollectionJson
> {
  itemsFromJson(jsonItems: OrderDetailJson[]): OrderDetailModel[] {
    return jsonItems.map((item) => new OrderDetailModel(item));
  }
}

export default OrderDetailCollection;
