import BaseCollection from "common/collections/BaseCollection";
import DepartmentModel from "common/models/DepartmentModel";
import {
  DepartmentJson,
  DepartmentCollectionJson,
} from "common/types/Department";

class DepartmentCollection extends BaseCollection<
  DepartmentJson,
  DepartmentModel,
  DepartmentCollectionJson
> {
  itemsFromJson(jsonItems: DepartmentJson[]): DepartmentModel[] {
    return jsonItems.map((item) => new DepartmentModel(item));
  }
}

export default DepartmentCollection;
