import { Button } from "antd";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import {
  ProjectTaskKanbanColumn,
  ProjectTaskKanbanDraggableLocation,
  ProjectTaskKanbanTotalTaskPerColumn,
} from "common/types/ProjectTask";
import useWindowDimensions from "hooks/useWindowDimensions";
import React, { useCallback, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars";

import {
  Draggable,
  DraggableLocation,
  DraggableStateSnapshot,
  DraggableStyle,
  Droppable,
} from "@hello-pangea/dnd";

import ProjectTaskKanbanBoardItem from "./ProjectTaskKanbanBoardItem";

const ProjectTaskKanbanBoard = ({
  column,
  projectIterationItems,
  setViewingTask,
  source,
  destination,
  loadMoreData,
  kanbanTotalTaskPerColumn,
}: {
  column: ProjectTaskKanbanColumn;
  projectIterationItems: ProjectIterationModel[];
  setViewingTask: (v: ProjectTaskModel) => void;
  source: ProjectTaskKanbanDraggableLocation | null;
  destination: DraggableLocation | null;
  loadMoreData: (p: number, s: number) => void;
  kanbanTotalTaskPerColumn: ProjectTaskKanbanTotalTaskPerColumn[];
}) => {
  const pageHeaderNavHeight = 59;
  const pageHeaderHeight = 64;
  const pageFilterHeight = 54;
  const pageHeaderTitleHeight = 45;

  //get screen height
  const { height } = useWindowDimensions();
  const boardHeight =
    height -
    pageHeaderNavHeight -
    pageHeaderHeight -
    pageFilterHeight -
    pageHeaderTitleHeight;

  // console.log(kanbanTotalTaskPerColumn);
  const visibleLoadMore = useMemo(() => {
    let findColumn = kanbanTotalTaskPerColumn.find(
      (i) => i.status === column.value,
    );
    return findColumn?.load_more && findColumn?.total > 4;
  }, [column.value, kanbanTotalTaskPerColumn]);

  const getItemStyle = (
    style: DraggableStyle | undefined,
    snapshot: DraggableStateSnapshot,
  ) => {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  };

  const isDropDisabled = useMemo(() => {
    let disabled = false;
    if (source !== null && source.columnValue === column.value) {
      disabled = true;
    }
    return disabled;
  }, [source, column]);

  const shouldRenderSnapshot = useMemo(() => {
    return (
      source !== null &&
      destination !== null &&
      source.droppableId !== destination.droppableId
    );
  }, [source, destination]);

  const handleClickLoadMore = useCallback(
    (currentCol: number, currrentItems: ProjectTaskModel[]) => {
      let pageCount = 0;
      let pageSize = 50;
      let total = currrentItems.length;

      if (total < pageSize) {
        pageCount = 1;
      } else {
        pageCount = Math.ceil(total / pageSize);
      }

      // call api to get more data
      loadMoreData(pageCount + 1, currentCol);
    },
    [loadMoreData],
  );

  return (
    <>
      <Droppable
        droppableId={column.value.toString()}
        key={column.value.toString()}
        isDropDisabled={isDropDisabled}>
        {(droppableProvided, droppableSnapshot) => {
          return (
            <div className="bg-gray-200 shadow-md">
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                className={`${
                  droppableSnapshot.isDraggingOver ? "bg-yellow-100" : ""
                }`}>
                {shouldRenderSnapshot && droppableSnapshot.isDraggingOver ? (
                  <div
                    style={{
                      transform: "none !important",
                    }}
                    className="px-2 pt-2">
                    <div className="border-2 border-gray-900 border-dotted rounded-lg">
                      {droppableProvided.placeholder}
                    </div>
                  </div>
                ) : null}
                <Scrollbars
                  style={{
                    height: boardHeight,
                    overflow: "hidden",
                    borderRight: "1px solid #eee",
                  }}
                  autoHide>
                  {column.items.length > 0
                    ? column.items.map((item, index) => {
                        return (
                          <div key={index}>
                            <Draggable
                              key={item.id.toString()}
                              draggableId={item.id.toString()}
                              index={index}>
                              {(draggableProvided, draggableSnapshot) => {
                                return (
                                  <>
                                    <div
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                      style={getItemStyle(
                                        draggableProvided.draggableProps?.style,
                                        draggableSnapshot,
                                      )}
                                      onClick={() => setViewingTask(item)}
                                      className={`px-2 pt-2`}>
                                      <ProjectTaskKanbanBoardItem
                                        item={item}
                                        projectIterationItems={
                                          projectIterationItems
                                        }
                                      />
                                    </div>
                                    {draggableSnapshot.isDragging ? (
                                      <div
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                        style={{
                                          transform: "none !important",
                                        }}
                                        className="px-2 pt-2">
                                        <div className="relative">
                                          <div className="absolute w-full h-full bg-white rounded-lg bg-opacity-40"></div>
                                          <ProjectTaskKanbanBoardItem
                                            item={item}
                                            projectIterationItems={
                                              projectIterationItems
                                            }
                                            isDragging={
                                              draggableSnapshot.isDragging
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                );
                              }}
                            </Draggable>
                          </div>
                        );
                      })
                    : null}

                  {/* load more task, only display when items bigger than 4 */}
                  {visibleLoadMore ? (
                    <div className="w-full px-2 pt-2">
                      <div className="flex items-center justify-center">
                        <Button
                          className="text-base"
                          type="link"
                          onClick={() =>
                            handleClickLoadMore(column.value, column.items)
                          }>
                          Xem thêm...
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </Scrollbars>
              </div>
            </div>
          );
        }}
      </Droppable>
    </>
  );
};

export default ProjectTaskKanbanBoard;
