import { DataGridWorkspaceJson } from "common/types/DataGridWorkspace";
import create, { StateCreator } from "zustand";

type DataGridPage = {
  activeWorkspaceId: number;
  setActiveWorkspaceId: (v: number) => void;
  workspaces: DataGridWorkspaceJson[];
  setWorkspaces: (v: DataGridWorkspaceJson[]) => void;
};

//init store data
let store: StateCreator<DataGridPage> = (set) => ({
  activeWorkspaceId: 0,
  workspaces: [],
  setActiveWorkspaceId: (activeWorkspaceId) =>
    set((state) => ({ ...state, activeWorkspaceId })),
  setWorkspaces: (workspaces) => set((state) => ({ ...state, workspaces })),
});

//create store
const useDataGridPage = create(store);

export default useDataGridPage;
