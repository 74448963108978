import Warehouse from "common/constants/Warehouse";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type { DirectionAlow, WarehouseJson } from "common/types/Warehouse";
class WarehouseModel extends BaseModel implements BaseModelJson<WarehouseJson> {
	creator_id?: number;
	company_id?: number;
	id: number;
	name: string;
	code: string;
	address: string;
	lat: number;
	long: number;
	map_place_id: string;
	foreign_id: string;
	contact_email: string;
	contact_fullname: string;
	contact_phone: string;
	date_created?: number;
	date_modified?: number;
	description: string;
	display_order: number;
	foreign_id_more: string;
	region_id: number;
	sub_region_id: number;
	sub_sub_region_id: number;
	status: number;
	direction_in: DirectionAlow;
	direction_out: DirectionAlow;

	constructor(json: WarehouseJson) {
		super();

		this.id = json.id || 0;
		this.company_id = json.company_id || 0;
		this.name = json.name || "";
		this.code = json.code || "";
		this.address = json.address || "";
		this.foreign_id = json.foreign_id || "";
		this.contact_email = json.contact_email || "";
		this.contact_fullname = json.contact_fullname || "";
		this.contact_phone = json.contact_phone || "";
		this.creator_id = json.creator_id || 0;
		this.date_created = json.date_created || 0;
		this.date_modified = json.date_modified || 0;
		this.description = json.description || "";
		this.display_order = json.display_order || 0;
		this.foreign_id_more = json.foreign_id_more || "";
		this.lat = json.lat || 0;
		this.long = json.long || 0;
		this.map_place_id = json.map_place_id || "";
		this.region_id = json.region_id || 0;
		this.sub_region_id = json.sub_region_id || 0;
		this.sub_sub_region_id = json.sub_sub_region_id || 0;
		this.status = json.status || 0;
		this.direction_in = json.direction_in || 0;
		this.direction_out = json.direction_out || 0;
	}

	static getDefaultData(): WarehouseJson {
		return {
			id: 0,
			name: "",
			code: "",
			address: "",
			foreign_id: "",
			contact_email: "",
			contact_fullname: "",
			contact_phone: "",
			creator_id: 0,
			date_created: 0,
			date_modified: 0,
			description: "",
			display_order: 0,
			foreign_id_more: "",
			lat: 0,
			long: 0,
			map_place_id: "",
			region_id: 0,
			sub_region_id: 0,
			sub_sub_region_id: 0,
			status: 0,
			direction_in: 0,
			direction_out: 0,
		};
	}

	toJson(): WarehouseJson {
		return {
			company_id: this.company_id,
			id: this.id,
			name: this.name,
			code: this.code,
			address: this.address,
			foreign_id: this.foreign_id,
			contact_email: this.contact_email,
			contact_fullname: this.contact_fullname,
			contact_phone: this.contact_phone,
			creator_id: this.creator_id,
			date_created: this.date_created,
			date_modified: this.date_modified,
			description: this.description,
			display_order: this.display_order,
			foreign_id_more: this.foreign_id_more,
			lat: this.lat,
			long: this.long,
			map_place_id: this.map_place_id,
			region_id: this.region_id,
			sub_region_id: this.sub_region_id,
			sub_sub_region_id: this.sub_sub_region_id,
			status: this.status,
			direction_in: this.direction_in,
			direction_out: this.direction_out,
		};
	}

	static getStatusList(): SelectOption[] {
		return [
			{
				value: Warehouse.STATUS_ENABLE,
				label: i18n.t("common:STATUS_ENABLE"),
				color: "green",
			},
			{
				value: Warehouse.STATUS_DISABLE,
				label: i18n.t("common:STATUS_DISABLED"),
				color: "red",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}
}

export default WarehouseModel;
