import WorkScheduleModel from "common/models/WorkScheduleModel";
import { IdFetcherResult } from "common/types/IdFetcher";
import useDbForSelect from "hooks/useDbForSelect";

import { IconHome } from "@tabler/icons-react";

const WorkScheduleMineItem = ({
  model,
  range,
}: {
  model: WorkScheduleModel;
  range?: IdFetcherResult;
}) => {
  const [officeItems] = useDbForSelect("companyoffice");

  return (
    <div
      style={{ backgroundColor: "#" + range?.worktrackingrange?.color }}
      className="text-xs text-left p-2 rounded mb-1"
    >
      <span className="">
        <div className="text-gray-600 mb-1">
          <IconHome size={12} />{" "}
          {officeItems.find((i) => i.value === model.office_id)?.label}{" "}
        </div>

        <div className="font-bold">
          {range?.worktrackingrange?.name} (
          {range?.worktrackingrange?.time_start} -{" "}
          {range?.worktrackingrange?.time_end})
        </div>
      </span>
    </div>
  );
};

export default WorkScheduleMineItem;
