import ProductReceipt from 'common/constants/ProductReceipt';
import ProductReceiptModel from 'common/models/ProductReceiptModel';

const TextProductReceiptDirection = ({
  direction,
  prefix,
}: {
  direction: number;
  prefix?: string;
}) => {
  let directionInfo = ProductReceiptModel.getDirection(direction);
  return (
    <span
      className={
        direction === ProductReceipt.DIRECTION_INPUT
          ? "text-green-500"
          : "text-red-500"
      }
    >
      {prefix}
      {directionInfo?.label}
    </span>
  );
};

export default TextProductReceiptDirection;
