import LeaveSummaryApi from "common/api/LeaveSummaryApi";
import BaseRepository from "./BaseRepository";
import { LeaveSummaryJson } from "common/types/LeaveSummary";

class LeaveSummaryRepository extends BaseRepository<LeaveSummaryApi> {
  _api: LeaveSummaryApi | null;

  constructor() {
    super();
    this._api = new LeaveSummaryApi(true);
  }

  async getSummaryDate(id: number): Promise<LeaveSummaryJson> {
    return this._api ? this._api.getSummaryDate(id) : { available_days: 0 };
  }
}

export default LeaveSummaryRepository;
