// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagCategories_wrapper__GrsjA {
  line-height: normal;
  font-size: var(--text-fontsize);
  color: var(--active-color);
  font-weight: 500;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--line-clamp);
  display: -webkit-box;
  overflow: hidden;
}

.TagCategories_link__eX\\+Fk:not(:first-child) {
  margin: 0 12px;
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyCard/components/TagCategories/TagCategories.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,+BAA+B;EAC/B,0BAA0B;EAC1B,gBAAgB;EAChB,4BAA4B;EAC5B,qCAAqC;EACrC,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".wrapper {\n  line-height: normal;\n  font-size: var(--text-fontsize);\n  color: var(--active-color);\n  font-weight: 500;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: var(--line-clamp);\n  display: -webkit-box;\n  overflow: hidden;\n}\n\n.link:not(:first-child) {\n  margin: 0 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TagCategories_wrapper__GrsjA`,
	"link": `TagCategories_link__eX+Fk`
};
export default ___CSS_LOADER_EXPORT___;
