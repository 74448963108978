import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DepartmentFormFilter from "features/department/DepartmentFormFilter";
import OfficeFormFilter from "features/office/OfficeFormFilter";
import { useTranslation } from "react-i18next";

const EmployeeListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[
          "office_id",
          "department_id",
          // "type",
          // "status",
        ]}
      >
    
          <Form.Item name="keyword_name">
            <Input
              allowClear
              placeholder={t("employee:filter_keyword_placeholder")}
            />
          </Form.Item>

          <OfficeFormFilter
            placeholder={t("employee:office_short")}
            name="office_id"
            allowClear
            popupMatchSelectWidth={false}
          />

          <DepartmentFormFilter
            placeholder={t("employee:department")}
            name="department_id"
            allowClear
            popupMatchSelectWidth={false}
          />

          {/* <FormSelect
          name="type"
          placeholder={t("employee:type")}
          options={EmployeeModel.getTypeList()}
          allowClear
          popupMatchSelectWidth={false}
        />

        <FormSelect
          name="status"
          placeholder={t("common:status")}
          options={EmployeeModel.getStatusList()}
          allowClear
          popupMatchSelectWidth={false}
        /> */}
  
      </PageDataFilterForm>
    </>
  );
};

export default EmployeeListFilter;
