import DeliveryModel from "common/models/DeliveryModel";
import { OrderJson } from "common/types/Order";
import dayjs from "dayjs";
import { Moment } from "moment";

class Helper {
  static moneyFormat(value: number): string {
    let output = "";

    //languagecode-countrycode:
    //languagecode: full list @ https://www.w3schools.com/tags/ref_language_codes.asp
    //countrycode: full list @ https://www.w3schools.com/tags/ref_country_codes.asp
    const locale = "vi-VN";

    //full currencylist at https://www.currency-iso.org/en/home/tables/table-a1.html
    const currency = "VND";

    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    });

    //formating data
    output = formatter.format(value);

    return output;
  }

  static numberFormat(value: number): string {
    let output = "";

    const locale = "vi-VN";
    const formatter = new Intl.NumberFormat(locale);

    //formating data
    output = formatter.format(value);

    return output;
  }

  static round(number: number, precision: number) {
    var shift = (number: number, precision: number) => {
      var numArray = ("" + number).split("e");
      return +(
        numArray[0] +
        "e" +
        (numArray[1] ? +numArray[1] + precision : precision)
      );
    };
    return shift(Math.round(shift(number, +precision)), -precision);
  }

  static isNumber(n: string) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  static fileExtension(filename: string) {
    if (!filename) return "";
    var ext = (/[^./\\]*$/.exec(filename) || [""])[0];
    return ext.toLowerCase();
  }

  static codau2khongdau(str: string): string {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D");
  }

  static checkDateString(date: number) {
    return date.toString().length < 8 ? "0" + date.toString() : date.toString();
  }

  static checkTimeString(time: number) {
    return time.toString().length < 4 ? "0" + time.toString() : time.toString();
  }

  static getHostname(): string {
    //this values will detect as NO-HOSTNAME
    //and trigger show hostname input on login / forgotpass / reset pass
    const markAsEmptyHostnameItems = ["localhost", "betaapp.cropany.com"];
    const hostname = document.location.hostname || "";
    if (hostname === "" || markAsEmptyHostnameItems.includes(hostname)) {
      return "ldevadmin.namefragrance.vn";
      // return "admin.namefragrance.vn";
    } else {
      return hostname;
    }
  }

  static getTranslateKey(): string {
    const markAsEmptyHostnameItems = [
      "localhost",
      "betaapp.cropany.com",
      "ldevadmin.namefragrance.vn",
      "uatadmin.namefragrance.vn",
    ];
    const hostname = document.location.hostname || "";
    if (hostname === "" || markAsEmptyHostnameItems.includes(hostname)) {
      return "uat.namefragrance.vn_config";
      // return "admin.namefragrance.vn";
    } else {
      return "beta.namefragrance.vn_config";
    }
  }

  static isJsonParsable(text: string): boolean {
    try {
      JSON.parse(text);
    } catch (e) {
      return false;
    }
    return true;
  }

  static convertParams(params: any, useZeroValue?: boolean): object {
    let newParam = { ...params };
    for (const prop in newParam) {
      const conditionNumber = useZeroValue
        ? typeof newParam[prop] === "number" && newParam[prop] < 0
        : typeof newParam[prop] === "number" && newParam[prop] <= 0;

      const conditionString = useZeroValue
        ? typeof newParam[prop] === "string" && newParam[prop].length <= 0
        : typeof newParam[prop] === "string" && newParam[prop].length <= 0;

      if (
        newParam[prop] === null ||
        newParam[prop] === undefined ||
        conditionNumber ||
        conditionString
      ) {
        delete newParam[prop];
      }
    }
    return newParam;
  }

  static convertedDateToTimeStamp = (
    myDate: Moment,
    defaultValue?: number
  ): number => {
    const timestamp = myDate ? myDate.unix() : defaultValue ?? -1;
    return timestamp;
  };

  static convertedTimestampToDate = (timestamp: number) => {
    return (
      new Date(timestamp * 1000).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }) +
      ", " +
      new Date(timestamp * 1000).toLocaleDateString("en-GB")
    );
  };

  static validateEmail = (value: string) => {
    if (value.trim().includes("@")) {
      return true;
    }
    return false;
  };

  static validatePhoneNumber(phone: string) {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    return phone.match(regexPhoneNumber) ? true : false;
  }

  static datePickerValueToTimestamp(datePickerValue: any) {
    // Check if the datePickerValue is valid and not null or undefined

    // Parse the selected date from the DatePicker value (assuming the format is 'YYYY-MM-DD')
    const selectedDate = dayjs(datePickerValue);

    // Get the timestamp in milliseconds from the Day.js object
    const timestamp = selectedDate.unix();

    return timestamp;
  }

  static checkAllHasTip = (selectDeliveryArr: DeliveryModel[]) => {
    for (let i = 0; i < selectDeliveryArr.length; i++) {
      if (selectDeliveryArr[i].hastips === 0) {
        return false;
      }
    }
    return true;
  };

  static checkHasActionCode = (
    selectDeliveryArr: DeliveryModel[],
    action_code: string
  ) => {
    for (let i = 0; i < selectDeliveryArr.length; i++) {
      if (selectDeliveryArr[i].action_code === action_code) {
        return true;
      }
    }
    return false;
  };

  static checkSameActionCode = (
    selectDeliveryArr: DeliveryModel[],
    action_code: string
  ) => {
    for (let i = 0; i < selectDeliveryArr.length; i++) {
      if (selectDeliveryArr[i].action_code !== action_code) {
        return false;
      }
    }
    return true;
  };

  static isMobile = () => {
    let widthDevice = window.innerWidth;
    const setWidthDevice = () => {
      widthDevice = window.innerWidth;
    };
    window.addEventListener("resize", setWidthDevice);
    if (widthDevice <= 768) {
      return true;
    } else {
      return false;
    }
  };

  static parseData<T>(data: T): T {
    const dataJson = JSON.stringify(data);
    let dataParse = data;
    try {
      dataParse = JSON.parse(dataJson) as T;
    } catch (error) {
      // return data
      throw new Error(JSON.stringify({ data: data }));
    }

    return dataParse;
  }

  static checkEditOrder(id: string | number) {
    return (
      (typeof id === "string" && id.length > 0) ||
      (typeof id === "number" && id > 0)
    );
  }

  static formatTimestampToText(timestamp: number): string {
    const now = new Date().getTime();
    const diffInSeconds = (now - timestamp * 1000) / 1000;
    const diffInMinutes = Math.round(diffInSeconds / 60);
    const diffInHours = Math.round( diffInMinutes / 60)
    // const diffInDays = diffInHours / 24;

    const date = new Date(timestamp * 1000);
    
    if (diffInMinutes <= 0) {
      return `Mới đây`;
    } else if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)} phút trước`; // Dưới 1 tiếng
    } else if (diffInHours < 24) {
      return `Hôm nay ${date.toLocaleTimeString("vi-VN", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`; // Hôm nay nếu dưới 1 ngày
    } else {
      return date.toLocaleString("vi-VN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    }
  }

  static formatTimestampToText2(timestamp: number): string {
    const now = new Date().getTime();
    const diffInSeconds = (now - timestamp * 1000) / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    // const diffInDays = diffInHours / 24;

    const date = new Date(timestamp * 1000);

    if (this.checkTimestampInDay(timestamp)) {
      return `Hôm nay ${date.toLocaleTimeString("vi-VN", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })}`;
    } else {
      return date.toLocaleString("vi-VN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    }
  }
  static checkValueValidStringNumber(value: string | number) {
    if (typeof value === "string" && value.length > 0) {
      return true;
    }
    if (typeof value === "number" && value > 0) {
      return true;
    }

    return false;
  }

  // static checkKeyValid(object: Object, key: keyof Object) {
  // 	if (object.hasOwnProperty("key")) {
  // 		if (typeof object[key] === "boolean" && object[key] === false) {
  // 			return false;
  // 		}
  // 	}
  // }
  static checkKeyValid(object: any, key: string): boolean {
    if (typeof object === "object" && object.hasOwnProperty(key)) {
      console.log("true");
      const value = object[key];
      console.log("🚀 ~ Helper ~ checkKeyValid ~ value:", typeof value);
      if (typeof value === "boolean" && value === true) {
        return false;
      }
      return true; // Return true for boolean true or non-boolean values
    }
    return true; // Return false if the key doesn't exist
  }

  static checkTimestampInDay(timestamp: number) {
    const date = dayjs(timestamp * 1000);
    const startOfToday = dayjs().startOf("day");
    const endOfToday = dayjs().endOf("day");

    return date.isAfter(startOfToday) && date.isBefore(endOfToday);
  }

  static distributeElements<T>(
    parentArray: T[],
    array1: T[],
    array2: T[]
  ): { array1: T[]; array2: T[]; array3: T[] } {
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    const array3 = parentArray.filter(
      (item) => !set1.has(item) && !set2.has(item)
    );

    return { array1, array2, array3 };
  }


  static moveItem<T>(arr: T[], oldIdx: number, newIdx: number) {
    // Kiểm tra xem các chỉ số có hợp lệ không
    if (
      oldIdx >= 0 &&
      oldIdx < arr.length &&
      newIdx >= 0 &&
      newIdx <= arr.length
    ) {
      // Lưu giá trị của phần tử tại vị trí cũ vào biến tạm thời
      var itemToMove = arr.splice(oldIdx, 1)[0];
      // Chèn phần tử vào vị trí mới
      arr.splice(newIdx, 0, itemToMove);
    }
  }
}

export default Helper;
