import ProductReceiptDetailCollection from 'common/collections/ProductReceiptDetailCollection';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

const SERVICE_URL = "/productreceiptdetails";

class ProductReceiptDetailApi extends BaseApi {
  async getFullDetailOfReceipt(
    receiptId: number
  ): Promise<ProductReceiptDetailCollection> {
    let collection = new ProductReceiptDetailCollection();

    try {
      let queryData = {
        params: {},
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/receipt/" + receiptId,
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default ProductReceiptDetailApi;
