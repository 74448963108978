import { Menu } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconBrandChrome, IconListSearch } from "@tabler/icons-react";

import CompanyInfoSystemCompanyResource from "./system/CompanyInfoSystemCompanyResource";
import CompanyInfoSystemSearchIndex from "./system/searchindex/CompanyInfoSystemSearchIndex";

const CompanyInfoSystem = () => {
  const { t } = useTranslation();

  const [currentMenu, setCurrentMenu] = useState("companyresource");

  const menuItems = [
    {
      label: (
        <>
          <IconBrandChrome size={24} className="-mt-0.5 mr-2" />
          {t("company:info.system.companyresource")}
        </>
      ),
      key: "companyresource",
    },
    {
      label: (
        <>
          <IconListSearch size={24} className="-mt-0.5 mr-2" />
          {t("company:info.system.searchindex.label")}
        </>
      ),
      key: "searchindex",
    },
  ];

  const systemContent = useMemo(() => {
    let com = null;
    switch (currentMenu) {
      case "companyresource":
        com = <CompanyInfoSystemCompanyResource />;
        break;
      case "searchindex":
        com = <CompanyInfoSystemSearchIndex />;
        break;
    }
    return com;
  }, [currentMenu]);

  return (
    <>
      <div className="mx-auto max-w-4xl">
        <h3 className="text-xl text-gray-600 py-4">
          {t("company:info.system.heading")}
        </h3>

        <div className="mb-2">
          <Menu
            items={menuItems}
            mode="horizontal"
            onClick={(e) => setCurrentMenu(e.key)}
            selectedKeys={[currentMenu]}
          />
        </div>
      </div>

      {systemContent}
    </>
  );
};

export default CompanyInfoSystem;
