import Employee from "common/constants/Employee";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";
import { EmployeeJson } from "common/types/Employee";
import FileModel from "./FileModel";
import { FileJson } from "common/types/File";

class EmployeeModel extends BaseModel implements BaseModelJson<EmployeeJson> {
	company_id: number;
	creator_id: number;
	id: number;
	user_id: number;
	office_id: number;
	department_id: number;
	position_id: number;
	email: string;
	internal_id: string;
	full_name: string;
	job_title: string;
	checkin_type: number;
	gender: number;
	type: number;
	phone: string;
	status: number;
	date_created: number;
	date_modified: number;
	day_work: number;
	address: string;
	birthday: number;
	bank_number: number;
	bank_id: number;
	marital_status: number;
	relatives_name: string;
	relatives_phone: string;
	relationship: string;
	tax: number;
	insurance: number;
	date_start: number;
	personal_email: string;
	personal_phone: string;
	role_id: number;
	cccd: string;
	permanent_address: string;
	cccd_issue_day: number;
	cccd_publish: string;
	basic_salary: number;
	salary_offer: number;
	contract: any;
	contract_code: string;
	contract_date: number;
	contract_date_extant: number;
	contract_work: number;
	avatar_file_id: number;
	avatar: FileModel;
	attendance_id: number;

	constructor(json: EmployeeJson) {
		super();

		this.company_id = json.company_id || 0;
		this.creator_id = json.creator_id || 0;
		this.id = json.id || 0;
		this.user_id = json.user_id || 0;
		this.office_id = json.office_id || 0;
		this.department_id = json.department_id || 0;
		this.position_id = json.position_id || 0;
		this.type = json.type || 0;
		this.email = json.email || "";
		this.internal_id = json.internal_id || "";
		this.full_name = json.full_name || "";
		this.job_title = json.job_title || "";
		this.checkin_type = json.checkin_type || 0;
		this.gender = json.gender || 1;
		this.phone = json.phone || "";
		this.status = json.status || 0;
		this.date_created = json.date_created || 0;
		this.date_modified = json.date_modified || 0;
		this.address = json.address || "";
		this.birthday = json.birthday || 0;
		this.bank_number = json.bank_number || 0;
		this.bank_id = json.bank_id || 0;
		this.marital_status = json.marital_status || 0;
		this.relatives_name = json.relatives_name || "";
		this.relatives_phone = json.relatives_phone || "";
		this.relationship = json.relationship || "";
		this.tax = json.tax || 0;
		this.insurance = json.insurance || 0;
		this.date_start = json.date_start || 0;
		this.personal_email = json.personal_email || "";
		this.personal_phone = json.personal_phone || "";
		this.role_id = json.role_id || 0;
		this.permanent_address = json.permanent_address || "";
		this.cccd = json.cccd || "";
		this.cccd_issue_day = json.cccd_issue_day || 0;
		this.cccd_publish = json.cccd_publish || "";
		this.basic_salary = json.basic_salary || 0;
		this.salary_offer = json.salary_offer || 0;
		this.contract = json.contract || null;
		this.contract_code = json.contract_code || "";
		this.day_work = json.day_work || 0;
		this.contract_date = json.contract_date || 0;
		this.contract_date_extant = json.contract_date_extant || 0;
		this.contract_work = json.contract_work || 0;
		this.avatar_file_id = json.avatar_file_id || 0;
		this.avatar = new FileModel(json.avatar || FileModel.getDefaultData());
		this.attendance_id = json.attendance_id || 0;
	}

	static getDefaultData(): EmployeeJson {
		return {
			company_id: 0,
			user_id: 0,
			type: 0,
			full_name: "",
			gender: 1,
			creator_id: 0,
			date_created: 0,
			date_modified: 0,
			day_work: 0,
			id: 0,
			office_id: 0,
			department_id: 0,
			position_id: 0,
			email: "",
			internal_id: "",
			job_title: "",
			checkin_type: 0,
			phone: "",
			status: 0,
			address: "",
			marital_status: 0,
			relatives_name: "",
			relatives_phone: "",
			relationship: "",
			bank_id: 0,
			bank_number: 0,
			birthday: 0,
			tax: 0,
			insurance: 0,
			date_start: 0,
			personal_email: "",
			personal_phone: "",
			role_id: 0,
			permanent_address: "",
			cccd: "",
			cccd_issue_day: 0,
			cccd_publish: "",
			basic_salary: 0,
			salary_offer: 0,
			contract: null,
			contract_code: "",
			contract_date: 0,
			contract_date_extant: 0,
			contract_work: 0,
			avatar_file_id: 0,
			avatar: FileModel.getDefaultData(),
			attendance_id: 0,
		};
	}

	toJson(): EmployeeJson {
		return {
			id: this.id,
			company_id: this.company_id,
			user_id: this.user_id,
			office_id: this.office_id,
			department_id: this.department_id,
			position_id: this.position_id,
			type: this.type,
			email: this.email,
			internal_id: this.internal_id,
			full_name: this.full_name,
			job_title: this.job_title,
			checkin_type: this.checkin_type,
			gender: this.gender,
			phone: this.phone,
			creator_id: this.creator_id,
			status: this.status,
			date_created: this.date_created,
			date_modified: this.date_modified,
			address: this.address,
			marital_status: this.marital_status,
			relatives_name: this.relatives_name,
			relatives_phone: this.relatives_phone,
			relationship: this.relationship,
			bank_id: this.bank_id,
			bank_number: this.bank_number,
			birthday: this.birthday,
			tax: this.tax,
			insurance: this.insurance,
			date_start: this.date_start,
			personal_email: this.personal_email,
			personal_phone: this.personal_phone,
			role_id: this.role_id,
			permanent_address: this.permanent_address,
			cccd: this.cccd,
			cccd_issue_day: this.cccd_issue_day,
			cccd_publish: this.cccd_publish,
			salary_offer: this.salary_offer,
			basic_salary: this.basic_salary,
			contract: this.contract,
			contract_code: this.contract_code,
			day_work: this.day_work,
			contract_date: this.contract_date,
			contract_date_extant: this.contract_date_extant,
			contract_work: this.contract_work,
			avatar: this.avatar,
			avatar_file_id: this.avatar_file_id,
			attendance_id: this.attendance_id,
		};
	}

	static getGenderList(): SelectOption[] {
		return [
			// {
			//   value: Employee.GENDER_UNKNOWN,
			//   label: i18n.t("common:gender_unknown"),
			// },
			{
				value: Employee.GENDER_MALE,
				label: i18n.t("common:gender_male"),
			},
			{
				value: Employee.GENDER_FEMALE,
				label: i18n.t("common:gender_female"),
			},
		];
	}

	static getStatusList(customer?: boolean): SelectOption[] {
		return [
			{
				value: Employee.STATUS_ENABLE,
				label: i18n.t("common:STATUS_ENABLE"),
				color: "green",
			},
			{
				value: customer
					? Employee.STATUS_DISABLED_CUSTOMER
					: Employee.STATUS_DISABLED,
				label: i18n.t("common:STATUS_DISABLED"),
				color: "red",
			},
		];
	}

	static getStatus(value: number): SelectOption | undefined {
		return this.getStatusList().find((item) => item.value === value);
	}

	static getTypeList(): SelectOption[] {
		return [
			{
				value: Employee.TYPE_FULLTIME,
				label: i18n.t("employee:TYPE_FULLTIME"),
			},
			{
				value: Employee.TYPE_PARTTIME,
				label: i18n.t("employee:TYPE_PARTTIME"),
			},
		];
	}

	static getCheckinTypeList(): SelectOption[] {
		return [
			{
				value: Employee.CHECKIN_TYPE_IGNORE,
				label: i18n.t("employee:CHECKIN_TYPE_IGNORE"),
			},
			{
				value: Employee.CHECKIN_TYPE_OFFICE,
				label: i18n.t("employee:CHECKIN_TYPE_OFFICE"),
			},
		];
	}

	static getCheckin(value: number): SelectOption | undefined {
		return this.getCheckinTypeList().find((item) => item.value === value);
	}

	static getMaritalStatusList(): SelectOption[] {
		return [
			{
				value: Employee.MARITAL_STATUS_SINGLE,
				label: i18n.t("employee:MARITAL_STATUS_SINGLE"),
			},
			{
				value: Employee.MARITAL_STATUS_MARRIED,
				label: i18n.t("employee:MARITAL_STATUS_MARRIED"),
			},
		];
	}

	static getTypeWorkList(value: string) {
		const typeWorkName: any = {
			TYPE_FULLTIME: i18n.t("employee:TYPE_FULLTIME"),
			TYPE_PARTTIME: i18n.t("employee:TYPE_PARTTIME"),
			TYPE_PREFLIGHT: i18n.t("employee:TYPE_PREFLIGHT"),
			TYPE_OVERTIME: i18n.t("employee:TYPE_OVERTIME"),
			TYPE_SEASONAL: i18n.t("employee:TYPE_SEASONAL"),
			TYPE_PROJECT: i18n.t("employee:TYPE_PROJECT"),
		};
		return typeWorkName[value];
	}

	static getPositionList(value: string) {
		const typeWorkName: any = {
			POSITION_DIRECTOR: i18n.t("employee:POSITION_DIRECTOR"),
			POSITION_STAFF: i18n.t("employee:POSITION_STAFF"),
			POSITION_MANAGER: i18n.t("employee:POSITION_MANAGER"),
		};
		return typeWorkName[value];
	}

	static getContractWork(value: number) {
		switch (value) {
			case 1:
				return i18n.t("employee:TYPE_FULLTIME");
			case 2:
				return i18n.t("employee:TYPE_PREFLIGHT");
			default:
				return "";
		}
	}
}

export default EmployeeModel;
