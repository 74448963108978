import { Button, Popconfirm, Table } from "antd";
import { TableColumnsType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconTrash } from "@tabler/icons-react";
import WarehouseRepository from "common/repositories/WarehouseRepository";

const StoreWarehouseList = ({
  warehouse_ids,
  handleWarehouseDeleteId,
}: {
  warehouse_ids: number[];
  handleWarehouseDeleteId: (id: number) => void;
}) => {
  const { t } = useTranslation();
  // const [warehouseItems] = useDatabaseTable<WarehouseJson>("warehouse");


  const [dataSource, setDataSource] = useState<WarehouseJson[]>([]);

  const fetchData = useCallback(
    async (ids: number[]) => {
      const res = await new WarehouseRepository().getItems({ filters: WarehouseRepository.getDefaultFilters() })
      let warehouses: WarehouseJson[] = res.toJson().items
      const items = warehouses.filter(item =>ids.includes(item.id))

      setDataSource(items);
    },
    []
  );

  useEffect(() => {
    fetchData(warehouse_ids);
  }, [fetchData, warehouse_ids]);

  //Table columns
  const columns: TableColumnsType<WarehouseJson> = [
    {
      title: t("store:warehouse"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (record: WarehouseJson) => (
        <Popconfirm
          title={t("common:table.confirm_delete_title")}
          placement="topRight"
          onConfirm={() => {
            handleWarehouseDeleteId(record.id);
          }}
          okText={t("common:table.confirm_ok")}
          cancelText={t("common:table.confirm_cancel")}
        >
          <Button
            size="small"
            type="text"
            icon={<IconTrash size="14" color="red" />}
          ></Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        className="table-dashboard"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="id"
      />
    </>
  );
};

export default StoreWarehouseList;
