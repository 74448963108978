import { FileOrigin } from 'common/types/File';

class FileUploaderProvider {
  objectType: number;
  origin: FileOrigin;

  constructor(objectType: number, origin: FileOrigin) {
    this.objectType = objectType;
    this.origin = origin;
  }
}

export default FileUploaderProvider;
