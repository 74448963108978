import { TimePicker, TimePickerProps, TimeRangePickerProps } from "antd";
import {
  DataPickerPlacements,
  DateTimePickerModes
} from "common/types/DateTime";
import { Dayjs } from "dayjs";
import React from "react";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const TimeFormFilter = ({
  value,
  rangeValue,
  mode,
  format,
  placement,
  className,
  placeholder,
  placeholderRange,
  onChange,
  onRangeChange,
  showNow,
  allowClear
}: {
  value?: Dayjs | null;
  rangeValue?: RangeValue;
  mode: DateTimePickerModes;
  format: string;
  placement?: DataPickerPlacements;
  className?: string;
  placeholder?: string;
  placeholderRange?: [string, string];
  onChange?: TimePickerProps["onChange"];
  onRangeChange?: TimeRangePickerProps["onChange"];
  showNow?: boolean;
  allowClear?: boolean;
}) => {
  return (
    <>
      {mode === "single" ? (
        <TimePicker
          value={value}
          changeOnBlur
          allowClear={allowClear}
          className={className}
          format={format}
          showNow={showNow}
          placement={placement}
          onChange={(time, timeString) => {
            if (onChange !== undefined) {
              onChange(time, timeString);
            }
          }}
        />
      ) : (
        <TimePicker.RangePicker
          value={rangeValue}
          changeOnBlur
          allowClear={allowClear}
          className={className}
          format={format}
          showNow={showNow}
          placement={placement}
          onChange={(time, timeString) => {
            if (onRangeChange !== undefined) {
              onRangeChange(time, timeString);
            }
          }}
        />
      )}
    </>
  );
};

export default TimeFormFilter;
