import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BrandListHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("brand:heading_list")}
      siteMenuSelectedKey="/brand"
      siteMenuOpenKey="product"
    >
      {children}
    </PageHeader>
  );
};

export default BrandListHeader;
