import { GetListProps } from "common/types/Filter";

import SettingApi from "common/api/SettingApi";
import SettingCollection from "common/collections/SettingCollection";
import SettingModel from "common/models/SettingModel";
import { FilterSetting, SettingAddEditJson } from "common/types/Setting";
import BaseRepository from "./BaseRepository";

class SettingRepository extends BaseRepository<SettingApi> {
	_api: SettingApi | null;

	constructor() {
		super();
		this._api = new SettingApi(true);
	}

	static getDefaultFilters(): FilterSetting {
		return {
			page: 1,
			limit: 20,
			sortby: "",
			sorttype: "",
			keyword: "",
			status: -1,
			ids: "",
			group: undefined,
			keys: "",
			lang: "",
		};
	}

	async getItems(
		props: GetListProps<FilterSetting>
	): Promise<SettingCollection> {
		return this._api ? await this._api.getList(props) : new SettingCollection();
	}

	async getItem(id: string | number, lang?: string): Promise<SettingModel> {
		const language = lang ?? "";
		return this._api
			? await this._api.getDetail(id, language)
			: new SettingModel(SettingModel.getDefaultData());
	}

	//add - edit
	async saveRemote(data: SettingAddEditJson): Promise<SettingModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new SettingModel(SettingModel.getDefaultData());
		}
	}
	async deleteItem(ids: number) {
		return this._api ? await this._api.deleteItem(ids) : [];
	}
}

export default SettingRepository;
