import { Button, Tooltip } from "antd";
import React from "react";

import { IconChevronDown } from "@tabler/icons-react";

const HtmlEditorMenuBarButton = ({
  icon,
  title,
  active,
  onClick,
  disabled,
  showCaret,
}: {
  icon: React.ReactNode;
  title: string;
  active: boolean;
  onClick: () => void;
  disabled?: boolean;
  showCaret?: boolean;
}) => {
  const withCaret = typeof showCaret !== "undefined" && showCaret;

  const btn = (
    <Button
      disabled={disabled || false}
      icon={icon}
      type="text"
      className={
        (withCaret ? "ant-btn-with-caret " : "") + (active ? "is-active" : "")
      }
      style={withCaret ? { paddingLeft: 6 } : undefined}
      onClick={onClick}
    >
      {withCaret ? (
        <span style={{ marginLeft: -7 }} className="he-menu-caret1">
          <IconChevronDown
            size="14"
            style={{ paddingTop: 2, strokeWidth: 1.5, stroke: "#E9AC18" }}
          />
        </span>
      ) : null}
    </Button>
  );

  if (title.length === 0) return btn;

  return (
    <Tooltip title={title} mouseEnterDelay={0.4}>
      <Button
        disabled={disabled || false}
        icon={icon}
        type="text"
        className={
          (withCaret ? "ant-btn-with-caret " : "") + (active ? "is-active" : "")
        }
        style={withCaret ? { paddingLeft: 6 } : undefined}
        onClick={onClick}
      >
        {withCaret ? (
          <span style={{ marginLeft: -7 }} className="he-menu-caret1">
            <IconChevronDown
              size="14"
              style={{ paddingTop: 2, strokeWidth: 1.5, stroke: "#E9AC18" }}
            />
          </span>
        ) : null}
      </Button>
    </Tooltip>
  );
};

export default HtmlEditorMenuBarButton;
