import { BaseModelJson } from 'common/interfaces/BaseModelJson';

import BaseModel from './BaseModel';

import type { ProjectTaskChangeJson } from "common/types/ProjectTaskChange";

class ProjectTaskChangeModel
  extends BaseModel
  implements BaseModelJson<ProjectTaskChangeJson>
{
  company_id: number;
  creator_id: number;
  project_id: number;
  project_task_id: number;
  id: number;
  old_status: number;
  new_status: number;
  old_progress_percent: number;
  new_progress_percent: number;
  change_note: string;
  date_created?: number;
  date_modified?: number;

  constructor(json: ProjectTaskChangeJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.project_id = json.project_id || 0;
    this.project_task_id = json.project_task_id || 0;
    this.id = json.id || 0;
    this.old_status = json.old_status || 0;
    this.new_status = json.new_status || 0;
    this.old_progress_percent = json.old_progress_percent || 0;
    this.new_progress_percent = json.new_progress_percent || 0;
    this.change_note = json.change_note || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ProjectTaskChangeJson {
    return {
      company_id: 0,
      creator_id: 0,
      project_id: 0,
      project_task_id: 0,
      id: 0,
      old_status: 0,
      new_status: 0,
      old_progress_percent: 0,
      new_progress_percent: 0,
      change_note: "",
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): ProjectTaskChangeJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      project_id: this.project_id,
      project_task_id: this.project_task_id,
      id: this.id,
      old_status: this.old_status,
      new_status: this.new_status,
      old_progress_percent: this.old_progress_percent,
      new_progress_percent: this.new_progress_percent,
      change_note: this.change_note,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default ProjectTaskChangeModel;
