import { SettingCollectionJson, SettingJson } from "common/types/Setting";
import BaseCollection from "./BaseCollection";
import SettingModel from "common/models/SettingModel";

class SettingCollection extends BaseCollection<
  SettingJson,
  SettingModel,
  SettingCollectionJson
> {
  itemsFromJson(jsonItems: SettingJson[]): SettingModel[] {
    return jsonItems.map((item) => new SettingModel(item));
  }
}
export default SettingCollection;
