import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";

import { IconAward } from "@tabler/icons-react";

const CustomerListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      heading={t("customer:heading_list")}
      siteMenuOpenKey="sale"
      siteMenuSelectedKey="/customer"
    >
      <Row gutter={16} className="text-right">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
          <RoleCheck roles={[Role.CUSTOMER_VIEW, Role.CUSTOMER_MANAGE]}>
            <PageHeaderButton
              link="/customerpoint"
              type="default"
              icon={<IconAward size={18} className=" mr-1 -mt-0.5" />}
            >
              {t("customer:point_list_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <RoleCheck roles={[Role.CUSTOMER_VIEW, Role.CUSTOMER_MANAGE]}>
            <PageHeaderButton link="/customer/add">
              {t("customer:add_button")}
            </PageHeaderButton>
          </RoleCheck>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default CustomerListHeader;
