import ProductTransmitModel from 'common/models/ProductTransmitModel';
import ProductTransmitRepository from 'common/repositories/ProductTransmitRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import ProductTransmitForm from 'features/producttransmit/form/ProductTransmitForm';
import ProductTransmitFormHeader from 'features/producttransmit/form/ProductTransmitFormHeader';
import ProductTransmitList from 'features/producttransmit/list/ProductTransmitList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const ProductTransmitPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<ProductTransmitModel>(
    new ProductTransmitModel(ProductTransmitModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new ProductTransmitModel(ProductTransmitModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <ProductTransmitForm
          myObj={
            new ProductTransmitModel(ProductTransmitModel.getDefaultData())
          }
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ProductTransmitForm myObj={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new ProductTransmitRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={
              <ProductTransmitFormHeader isEditing={true} id={+pathParams.id} />
            }
          />
        );

      break;
    default:
      com = <ProductTransmitList />;
  }

  return com;
};

export default ProductTransmitPage;
