import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import update from "immutability-helper";

import {
  Category,
  CollectionFormDataPost,
  CollectionJsonAddEdit,
  CollectionJsonWithChildren,
  FilterCollection,
  FilterCollectionCategory,
} from "common/types/Collection";
import Helper from "common/utils/helper";
import { GetListProps } from "common/types/Filter";
import CollectionModel from "common/models/CollectionModel";
import CollectionCollection from "common/collections/CollectionCollection";
import NestedCollection from "common/collections/NestedCollection";

const SERVICE_URL = "/collections";

class CollectionApi extends BaseApi {
  //
  async getList(
    props: GetListProps<FilterCollection>
  ): Promise<CollectionCollection> {
    let collection = new CollectionCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/admin",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getListPublic(
    props: GetListProps<FilterCollection>
  ): Promise<CollectionCollection> {
    let collection = new CollectionCollection();
    // const convertProps = Helper.convertParams(props);
    // const queryData = {
    //   params: {
    //     ...convertProps,
    //   },
    // };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/public"
        // queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getCategoryListPublic(
    props: GetListProps<FilterCollectionCategory>
  ): Promise<Category[]> {
    let res: Category[] = [];

    try {
      let queryData = {
        params: {
          type: props.filters.type > 0 ? props.filters.type : null,
          collection_id: props.filters.collection_id,
        },
      };
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/public/categories",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        res = response.data;
      }
    } catch (error) {
      return [];
    }
    return res;
  }
  //
  async getAll(
    props: GetListProps<FilterCollection>
  ): Promise<NestedCollection<CollectionJsonWithChildren>> {
    let allItems: CollectionJsonWithChildren[] = [];
    let collection = new NestedCollection<CollectionJsonWithChildren>();
    let page = 1;

    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
        page: page,
        limit: 500,
        sort_by: "display_order",
        sort_type: "ASC",
        item_type: props.filters.item_type > 0 ? props.filters.item_type : 0,
      },
    };

    try {
      do {
        const currentPageCollection = await AxiosClient().get<any>(
          SERVICE_URL + "/public",
          queryData
        );

        if (currentPageCollection.hasOwnProperty("data")) {
          allItems = update(allItems, {
            $push: currentPageCollection.data.items,
          });

          if (
            currentPageCollection.data.items.length < queryData.params.limit
          ) {
            break;
          } else {
            page++;
          }
        } else {
          break;
        }
      } while (true);

      collection.buildNested(allItems);
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
  //
  async getFullFetcher(
    props: GetListProps<FilterCollection>
  ): Promise<CollectionModel[]> {
    let allItems: any[] = [];
    let page = 1;

    try {
      do {
        let queryData = {
          params: {
            page: page,
            limit: 500,
            sort_by: "display_order",
            sort_type: "ASC",
            item_type: props.filters.item_type && props.filters.item_type,
          },
        };

        const currentPageCollection = await AxiosClient().get<any>(
          SERVICE_URL + "/public",
          queryData
        );

        if (currentPageCollection.hasOwnProperty("data")) {
          allItems = update(allItems, {
            $push: currentPageCollection.data.items,
          });

          if (
            currentPageCollection.data.items.length < queryData.params.limit
          ) {
            break;
          } else {
            page++;
          }
        } else {
          break;
        }
      } while (true);
    } catch (error) {
      allItems.push({ error: error });
    }

    return allItems;
  }
  // async getDetail(props: {
  //   handle: string | number;
  // }): Promise<CollectionModel> {
  //   const newHandle =
  //     typeof props.handle === "string" ? props.handle : props.handle.toString();
  //   let model = new CollectionModel(CollectionModel.getDefaultData());
  //   try {
  //     const response = await AxiosClient().get<any>(
  //       SERVICE_URL + "/public/" + newHandle
  //     );
  //     if (response) {
  //       model = new CollectionModel(response.data);
  //     }
  //   } catch (error) {
  //     model.withError(BaseApi.handleError(error));
  //   }
  //   return model;
  // }
  //
  async getDetail(id: number | string): Promise<CollectionModel> {
    let item = new CollectionModel(CollectionModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/public/" + id
      );
      if (response.hasOwnProperty("data")) {
        item = new CollectionModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  // async getDetail(props: {
  //   handle: string | number;
  // }): Promise<CollectionModel> {
  //   const newHandle =
  //     typeof props.handle === "string" ? props.handle : props.handle.toString();

  //   let item = new CollectionModel(CollectionModel.getDefaultData());

  //   try {
  //     const response = await AxiosClient().get<any>(
  //       SERVICE_URL + "/public/" + newHandle
  //     );
  //     if (response.hasOwnProperty("data")) {
  //       item = new CollectionModel(response.data);
  //     }
  //   } catch (error) {
  //     item.withError(BaseApi.handleError(error));
  //   }
  //   return item;
  // }
  //
  async add(
    props: CollectionJsonAddEdit | CollectionFormDataPost
  ): Promise<CollectionModel> {
    let item = new CollectionModel(CollectionModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/admin",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CollectionModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(
    props: CollectionJsonAddEdit | CollectionFormDataPost
  ): Promise<CollectionModel> {
    let item = new CollectionModel(CollectionModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/admin/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new CollectionModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/admin/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default CollectionApi;
