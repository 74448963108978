import Shipper from 'common/constants/Shipper';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';
import FileModel from './FileModel';

import type { ShipperJson, ShipperLoginJson } from "common/types/Shipper";
class ShipperModel extends BaseModel implements BaseModelJson<ShipperJson> {
  company_id: number;
  creator_id: number;
  shipping_hub_id: number;
  id: number;
  email: string;
  phone: string;
  full_name: string;
  password: string;
  gender: number;
  internal_id: string;
  avatar_file_id: number;
  avatar: FileModel;
  status: number;
  transportation: number;
  transportation_note: string;
  date_created: number;
  date_modified: number;
  date_last_login: number;

  constructor(json: ShipperJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.shipping_hub_id = json.shipping_hub_id || 0;
    this.id = json.id || 0;
    this.email = json.email || "";
    this.phone = json.phone || "";
    this.password = json.password || "";
    this.full_name = json.full_name || "";
    this.gender = json.gender || 0;
    this.internal_id = json.internal_id || "";
    this.avatar_file_id = json.avatar_file_id || 0;
    this.avatar = new FileModel(json.avatar || FileModel.getDefaultData());
    this.status = json.status || 0;
    this.transportation = json.transportation || 0;
    this.transportation_note = json.transportation_note || "";
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_last_login = json.date_last_login || 0;
  }

  static getDefaultLoginData(): ShipperLoginJson {
    return {
      id: 0,
      email: "",
      internal_id: "",
      phone: "",
      status: 0,
    };
  }

  static getDefaultData(): ShipperJson {
    return {
      ...this.getDefaultLoginData(),
      company_id: 0,
      shipping_hub_id: 0,
      full_name: "",
      password: "",
      gender: 0,
      avatar_file_id: 0,
      avatar: FileModel.getDefaultData(),
      creator_id: 0,
      transportation: 0,
      transportation_note: "",
      date_created: 0,
      date_modified: 0,
      date_last_login: 0,
    };
  }

  toJson(): ShipperJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      shipping_hub_id: this.shipping_hub_id,
      id: this.id,
      email: this.email,
      phone: this.phone,
      password: this.password,
      full_name: this.full_name,
      gender: this.gender,
      internal_id: this.internal_id,
      avatar_file_id: this.avatar_file_id,
      avatar: this.avatar,
      status: this.status,
      transportation: this.transportation,
      transportation_note: this.transportation_note,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_last_login: this.date_last_login,
    };
  }

  static getGenderList(): SelectOption[] {
    return [
      {
        value: Shipper.GENDER_UNKNOWN,
        label: "--",
      },
      {
        value: Shipper.GENDER_FEMALE,
        label: i18n.t("common:gender_female"),
      },
      {
        value: Shipper.GENDER_MALE,
        label: i18n.t("common:gender_male"),
      },
    ];
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: Shipper.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: Shipper.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getTransportationList(): SelectOption[] {
    return [
      {
        value: Shipper.TYPE_MOTOBIKE,
        label: i18n.t("common:TYPE_MOTOBIKE"),
        color: "green",
      },
      {
        value: Shipper.TYPE_CAR,
        label: i18n.t("common:TYPE_CAR"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getGender(value: number): SelectOption | undefined {
    return this.getGenderList().find((item) => item.value === value);
  }

  static getTransportation(value: number): SelectOption | undefined {
    return this.getTransportationList().find((item) => item.value === value);
  }
}

export default ShipperModel;
