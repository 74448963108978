import { Divider } from "antd";
import { HtmlEditor } from "common/interfaces/HtmlEditorInput";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  IconBold,
  IconBorderInner,
  IconBorderOuter,
  IconCode,
  IconItalic,
} from "@tabler/icons-react";
import { BubbleMenu } from "@tiptap/react";

import HtmlEditorMenuBarButton from "../HtmlEditorMenuBarButton";
import HtmlEditorBubbleMenuImagePicker from "./HtmlEditorBubbleMenuImagePicker";
import HtmlEditorBubbleMenuLink from "./HtmlEditorBubbleMenuLink";

const HtmlEditorBubbleMenu = ({
  editor,
  popupVisible,
}: {
  editor: HtmlEditor;
  popupVisible: boolean;
}) => {
  const { t } = useTranslation("htmleditor");

  if (!editor) {
    return null;
  }

  let moreClass = "";

  //hold all menu
  let menu = [];
  if (editor.isActive("link")) {
    menu.push(<HtmlEditorBubbleMenuLink key="link_edit" editor={editor} />);
    moreClass = "";
  } else if (editor.isActive("table")) {
    if (editor.can().mergeCells()) {
      menu.push(
        <HtmlEditorMenuBarButton
          key="table.merge_cells"
          icon={<IconBorderOuter />}
          title={t("table.merge_cells")}
          active={false}
          disabled={!editor.can().mergeCells()}
          onClick={() => editor.chain().focus().mergeCells().run()}
        />
      );
    }

    if (editor.can().splitCell()) {
      menu.push(
        <HtmlEditorMenuBarButton
          key="table.split_cell"
          icon={<IconBorderInner />}
          title={t("table.split_cell")}
          active={false}
          disabled={!editor.can().splitCell()}
          onClick={() => editor.chain().focus().splitCell().run()}
        />
      );
    }
  } else if (editor.isActive("imagepicker")) {
    menu.push(
      <HtmlEditorBubbleMenuImagePicker key="image_edit" editor={editor} />
    );
    // moreClass = "he-bubble-menu-white";
  } else {
    menu.push(
      <HtmlEditorMenuBarButton
        key="bold"
        icon={<IconBold />}
        title={t("bold")}
        active={editor.isActive("bold")}
        onClick={() => editor.chain().focus().toggleBold().run()}
      />
    );

    menu.push(
      <HtmlEditorMenuBarButton
        key="italic"
        icon={<IconItalic />}
        title={t("italic")}
        active={editor.isActive("italic")}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      />
    );

    menu.push(
      <HtmlEditorMenuBarButton
        key="code"
        icon={<IconCode />}
        title={t("code")}
        active={editor.isActive("code")}
        onClick={() => editor.chain().focus().toggleCode().run()}
      />
    );

    menu.push(<Divider key="divider_1" type="vertical" />);
    menu.push(<HtmlEditorBubbleMenuLink key="link_add" editor={editor} />);
  }

  const tippyOptions = {
    //if set duration > 0, will get error Invalid Position..MUST BE 0
    duration: 0,
  };

  return (
    <>
      {editor && (
        <BubbleMenu
          className={"ant-tooltip-placement-top he-bubble-menu " + moreClass}
          tippyOptions={tippyOptions}
          editor={editor}
        >
          {menu.length > 0 && !popupVisible ? (
            <div className="ant-tooltip-content">
              <div className="ant-tooltip-arrow">
                <span className="ant-tooltip-arrow-content"></span>
              </div>
              <div className="ant-tooltip-inner">{menu}</div>
            </div>
          ) : null}
        </BubbleMenu>
      )}
    </>
  );
};

export default HtmlEditorBubbleMenu;
