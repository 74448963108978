import { Radio, Space, Spin } from "antd";
import Promotion from "common/constants/Promotion";
import PosCartModel from "common/models/PosCartModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import {
  PosCartOrderDetail,
  PosCartPromotionCheckSignature,
  PosCartPromotionOrder,
} from "common/types/PosCart";
import { PromotionCouponCheckResultJson } from "common/types/PromotionCheck";
import Error from "components/Error";
import { find, forEach, isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "use-deep-compare-effect";
import useCompanySettingStore from "zustands/useCompanySettingStore";
import usePosCartStore from "zustands/usePosCartStore";

import PosRightPromotionCouponItem from "./PosRightPromotionCouponItem";
import PosRightPromotionCouponModal from "./PosRightPromotionCouponModal";
import PosRightPromotionOrderGiftItem from "./PosRightPromotionOrderGiftItem";
import PosRightPromotionOrderGiftModal from "./PosRightPromotionOrderGiftModal";

const PosRightPromotion = () => {
  const { t } = useTranslation();
  const [activeCart, updateActiveCart] = usePosCartStore((state) => [
    state.getActiveCart(),
    state.updateActiveCart,
  ]);

  const coupons: PromotionCouponCheckResultJson[] = usePosCartStore(
    (state) => state.getActiveCart()?.coupons || []
  );

  const disableManualDiscount = useCompanySettingStore(
    (state) => state.getSetting("disable_manual_promotion") || 0
  );

  const [showModal, setShowModal] = useState(false);
  const [showModalPromotionGift, setShowModalPromotionGift] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promotionGroup, setPromotionGroup] = useState(
    coupons.length > 0 ? "loyalty" : ""
  );
  const [checkPromotionSignature, setCheckPromotionSignature] =
    useState<PosCartPromotionCheckSignature>({});

  const [error, setError] = useState<string[]>([]);
  const [availableOrderPromotions, setAvailableOrderPromotion] = useState<
    PosCartPromotionOrder[]
  >([]);

  const [
    availableProductSeasonalPromotions,
    setAvailableProductSeasonalPromotion,
  ] = useState<PosCartPromotionOrder[]>([]);

  const onSelectCoupon = useCallback(
    (couponCheckResult: PromotionCouponCheckResultJson) => {
      updateActiveCart({
        promotion_gifts: [],
        promotions: [],
        coupons: [couponCheckResult],
      });
    },
    [updateActiveCart]
  );

  const onSelectPromotionGift = useCallback(
    (item: PosCartOrderDetail) => {
      updateActiveCart({
        promotion_gifts: [item],
        coupons: [],
      });
    },
    [updateActiveCart]
  );

  const removeCoupon = useCallback(() => {
    updateActiveCart({ coupons: [] });
  }, [updateActiveCart]);

  const removePromotionGifts = useCallback(() => {
    updateActiveCart({ promotion_gifts: [] });
  }, [updateActiveCart]);

  const handleGroupChange = useCallback(
    (groupname: string) => {
      if (promotionGroup !== groupname && groupname.length > 0) {
        //remove current selected promotion
        updateActiveCart({
          promotions: [],
          manual_discount: false,
        });
        removeCoupon();
        removePromotionGifts();
      }
      setPromotionGroup(groupname);
    },
    [promotionGroup, updateActiveCart, removeCoupon, removePromotionGifts]
  );

  const handleChange = (e: any) => {
    const promotionId = parseInt(e.target.value);
    console.log("handlechange", promotionId);

    if (promotionId >= 0) {
      const selectedPromotion: PosCartPromotionOrder | undefined = find(
        [...availableOrderPromotions, ...availableProductSeasonalPromotions],
        {
          id: promotionId,
        }
      );

      updateActiveCart({
        promotions:
          typeof selectedPromotion === "undefined" ? [] : [selectedPromotion],
        manual_discount: false,
      });
      //do not use coupon
      removeCoupon();
      removePromotionGifts();

      if (selectedPromotion?.type === Promotion.TYPE_SEASONAL_ORDER_GIFT) {
        setShowModalPromotionGift(true);
      }
    } else if (promotionId === -1) {
      //allow manual
      updateActiveCart({
        promotions: [],
        promotion_gifts: [],
        manual_discount: true,
      });
    } else if (promotionId === -7) {
      //coupon
    }

    if (promotionId === 0 || promotionId === -1) {
      //promotionId can be -7 (coupon ^^!)
      setPromotionGroup("");
    }
  };

  const fetchAllPromotions = useCallback(async () => {
    if (typeof activeCart !== "undefined" && activeCart.details.length > 0) {
      const calculatedData = PosCartModel.calculatePrices(activeCart);
      // console.log("load Promotion", calculatedData);
      setLoading(true);

      let repository = new PromotionRepository();
      let promotionCheckResult = await repository.posCheckPromotions({
        store_id: activeCart.store_id,
        customer_id: activeCart.customer.id,
        price_sell: calculatedData.price_sell,
        details: activeCart.details,
      });
      setLoading(false);

      if (promotionCheckResult.hasError()) {
        setError(promotionCheckResult.error.errors);
      } else {
        //filter promotionorder by price_sell and promotion rule (RANGE..)
        const orderPromotions: PosCartPromotionOrder[] =
          promotionCheckResult.order.filter((item: PosCartPromotionOrder) => {
            let flag = true;
            switch (item.value_type) {
              case Promotion.VALUETYPE_CURRENCY:
                if (
                  typeof item.value === "number" &&
                  calculatedData.price_sell < item.value
                ) {
                  flag = false;
                }
                break;

              case Promotion.VALUETYPE_PERCENT:
                if (typeof item.value === "number" && item.value > 100) {
                  flag = false;
                }
                break;

              case Promotion.VALUETYPE_RANGE:
                if (Array.isArray(item.value)) {
                  forEach(item.value, function (index, vitem) {
                    //todo: check range
                    // switch (vitem.value_type) {
                    //   case Promotion.VALUETYPERANGE_CURRENCY:
                    //   case Promotion.VALUETYPERANGE_FIXPRICE:
                    //     if (
                    //       vitem.from <= calculatedData.price_sell &&
                    //       vitem.to >= calculatedData.price_sell &&
                    //       vitem.value > calculatedData.price_sell
                    //     ) {
                    //       flag = false;
                    //     }
                    //     break;
                    //   case Promotion.VALUETYPERANGE_PERCENT:
                    //     if (vitem.value > 100) {
                    //       flag = false;
                    //     }
                    //     break;
                    //   default:
                    // }
                  });
                }

                break;
              case Promotion.VALUETYPE_QUANTITY:
                if (calculatedData.price_sell < item.min_check) {
                  flag = false;
                }
                break;
              default:
                flag = false;
            }
            return flag;
          });

        //store order promotions after filterout invalid promotions
        setAvailableOrderPromotion(orderPromotions);
        setAvailableProductSeasonalPromotion(
          promotionCheckResult.product_seasonal
        );

        //update list of product promotion for cart
        //for product promotion, du not filter out like order promotions
        updateActiveCart({
          product_promotions: [...promotionCheckResult.product],
        });
      }
    }
  }, [updateActiveCart, activeCart]);

  useEffect(() => {
    //build signature
    if (typeof activeCart !== "undefined") {
      const calculatedData = PosCartModel.calculatePrices(activeCart);
      setCheckPromotionSignature({
        store_id: activeCart.store_id,
        customer_id: activeCart.customer.id,
        price_sell: calculatedData.price_sell,
        details: activeCart.details,
      });
    } else {
      setCheckPromotionSignature({});
    }
  }, [activeCart]);

  useDeepCompareEffect(() => {
    fetchAllPromotions();
  }, [checkPromotionSignature]);

  //reset selected promotion
  useEffect(() => {
    //store all promotion (fetched from server)
    const availablePromotions = [
      ...availableOrderPromotions,
      ...availableProductSeasonalPromotions,
    ];

    let validPromotions: PosCartPromotionOrder[] = [];
    activeCart?.promotions.forEach((promotion) => {
      //find matching promotion from server and localstorage (activeCart.promotions)
      const foundPromotion = availablePromotions.find(
        (p) => p.id === promotion.id
      );
      if (typeof foundPromotion !== "undefined") {
        //we need to update the products list, instead of just use old cart promotion
        validPromotions.push(foundPromotion);
      }
    });

    if (!isEqual(activeCart?.promotions, validPromotions)) {
      updateActiveCart({
        promotions: validPromotions,
      });
    }
  }, [
    availableOrderPromotions,
    availableProductSeasonalPromotions,
    activeCart,
    updateActiveCart,
  ]);

  useEffect(() => {
    if (
      !showModalPromotionGift &&
      Array.isArray(activeCart?.promotion_gifts) &&
      activeCart?.promotion_gifts.length === 0 &&
      activeCart?.promotions.filter(
        (promotion) => promotion.type === Promotion.TYPE_SEASONAL_ORDER_GIFT
      ).length > 0
    ) {
      //clear promotion if close and do not show modal
      updateActiveCart({
        promotions: [],
      });
    }
  }, [showModalPromotionGift, activeCart, updateActiveCart]);

  // let countSeasonal =
  //   availableOrderPromotions.filter(
  //     (promotion) => promotion.group === Promotion.GROUP_SEASONAL
  //   ).length + availableProductSeasonalPromotions.length;

  let selectedPromotionId = useMemo(() => {
    if (typeof activeCart === "undefined") {
      return 0;
    } else {
      return activeCart.promotions.length > 0
        ? activeCart.promotions[0].id
        : activeCart.manual_discount
        ? -1
        : promotionGroup.length > 0
        ? activeCart.coupons.length > 0
          ? -7
          : -10000
        : 0;
    }
  }, [activeCart, promotionGroup]);

  // console.log("countSeasonal", countSeasonal);

  //return if not active
  if (typeof activeCart === "undefined") return null;

  return (
    <div className="p-4 border-l-4 border-green-500 border-t">
      {loading && (
        <div className="float-right">
          <Spin size="small"></Spin>
          <span className="text-gray-500">
            <small> Đang tìm khuyến mãi</small>
          </span>
        </div>
      )}
      <div className="font-bold uppercase mb-2">
        {t("pos:promotion.select_label")}
      </div>

      <Radio.Group onChange={handleChange} value={selectedPromotionId}>
        <Space direction="vertical">
          <Radio value={0} className="d-block pd-y-5">
            Không khuyến mãi
          </Radio>

          <Radio
            disabled={disableManualDiscount === 1}
            value={-1}
            className="d-block pd-y-5"
          >
            Nhập khuyến mãi bằng tay
          </Radio>

          <label
            style={{ fontSize: "14px" }}
            className={
              "ant-radio-wrapper d-block pd-y-5 ant-radio-wrapper-checked" +
              (promotionGroup === "seasonal"
                ? " ant-radio-wrapper-checked"
                : "")
            }
            onClick={(e) => handleGroupChange("seasonal")}
          >
            <span
              className={
                "ant-radio" +
                (promotionGroup === "seasonal" ? " ant-radio-checked" : "")
              }
            >
              <span className="ant-radio-inner"></span>
            </span>
            <span style={{ color: "#0168fa", cursor: "pointer" }}>
              {/* Nhóm khuyến mãi Thời vụ ({countSeasonal}) */}
              {promotionGroup === "seasonal" ? ": " : ".."}
            </span>
          </label>

          {availableOrderPromotions
            .filter(
              (promotion) =>
                // promotion.group === Promotion.GROUP_SEASONAL &&
                promotionGroup === "seasonal"
            )
            .map((promotion) => {
              return (
                <Radio
                  value={promotion.id}
                  key={promotion.id}
                  className="p-1.5 ml-4"
                >
                  {promotion.name}{" "}
                  {selectedPromotionId === promotion.id &&
                  promotion.type === Promotion.TYPE_SEASONAL_ORDER_GIFT &&
                  Array.isArray(activeCart.promotion_gifts) &&
                  activeCart.promotion_gifts.length > 0 ? (
                    <span>
                      {activeCart.promotion_gifts.map((gift) => (
                        <PosRightPromotionOrderGiftItem
                          key={gift.sku}
                          gift={gift}
                          onRemove={removePromotionGifts}
                        />
                      ))}
                    </span>
                  ) : null}
                </Radio>
              );
            })}
          {promotionGroup === "seasonal" &&
            availableProductSeasonalPromotions.map((promotion) => {
              return (
                <Radio
                  value={promotion.id}
                  key={promotion.id}
                  className="p-2 ml-4"
                >
                  {promotion.name}{" "}
                </Radio>
              );
            })}

          <label
            style={{ fontSize: "14px" }}
            className={
              "ant-radio-wrapper d-block pd-y-5 ant-radio-wrapper-checked" +
              (promotionGroup === "seasonal"
                ? " ant-radio-wrapper-checked"
                : "")
            }
            onClick={(e) => handleGroupChange("loyalty")}
          >
            <span
              className={
                "ant-radio" +
                (promotionGroup === "loyalty" ? " ant-radio-checked" : "")
              }
            >
              <span className="ant-radio-inner"></span>
            </span>
            <span style={{ color: "#0168fa", cursor: "pointer" }}>
              Coupon giảm giá{promotionGroup === "loyalty" ? ": " : ".."}
            </span>
          </label>

          {promotionGroup === "loyalty" ? (
            <Radio
              value={-7}
              key={-7}
              className="p-1.5 ml-4"
              onClick={(e) => setShowModal(true)}
            >
              {t("pos:promotion.coupon.select_label")}
            </Radio>
          ) : null}
        </Space>
      </Radio.Group>

      {coupons.map((couponCheckResult) => (
        <PosRightPromotionCouponItem
          key={couponCheckResult.coupon?.code}
          couponCheckResult={couponCheckResult}
          onRemove={removeCoupon}
        />
      ))}

      <PosRightPromotionCouponModal
        show={showModal}
        setShow={setShowModal}
        onSelectCoupon={onSelectCoupon}
      />

      <PosRightPromotionOrderGiftModal
        show={showModalPromotionGift}
        setShow={setShowModalPromotionGift}
        onSelect={onSelectPromotionGift}
      />

      {error.length > 0 ? (
        <div className="alert alert-danger">
          <Error
            contentPadding={0}
            items={error}
            translate_prefix="pos:promotion"
          />
        </div>
      ) : null}
    </div>
  );
};

export default PosRightPromotion;
