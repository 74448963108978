import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	Modal,
	Popover,
	Row,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import Role from "common/constants/Role";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import CashflowReceiptRepository from "common/repositories/CashflowReceiptRepository";
import { StoreJson } from "common/types/Store";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import TableLinkText from "components/table/TableLinkText";
import TextCashflowReceiptMethod from "components/TextCashflowReceiptMethod";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import useDatabaseTable from "hooks/useDatabaseTable";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";

import { IconLink, IconRefresh, IconSquarePlus } from "@tabler/icons-react";

import OrderFormSectionCashflowReceiptLinkForm from "./OrderFormSectionCashflowReceiptLinkForm";
import { CashflowReceiptJson } from "common/types/CashflowReceipt";
import RoleCheck from "components/RoleCheck";
import TableInfo from "components/table/TableInfo";
import Helper from "common/utils/helper";
import LayoutForm from "components/form/LayoutForm";
import CashflowReceiptForm from "features/cashflowreceipt/form/CashflowReceiptForm";
import { OrderJson } from "common/types/Order";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import Order from "common/constants/Order";

type Props = {
	order: OrderJson;
	isSuccess?: boolean;
	status?: number;
	onConfirmCashFlow?: (value: boolean) => void;
	refetchOrder?: () => void;
};
const OrderFormSectionCashflowReceipt = ({
	order,
	isSuccess,
	status,
	refetchOrder,
	onConfirmCashFlow,
}: Props) => {
	const { t } = useTranslation();

	const [storeItemsWithLimit] = useDatabaseTable<StoreJson>(
		"store",
		Role.CASHFLOW_VIEW
	);

	const hasRole = useLoginAccountStore((state) => state.hasRole);

	// const order = useEditingSaleOrderStore((state) => state.order);
	const [dataSource, setDataSource] = useState<CashflowReceiptModel[]>([]);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);

	const [itemView, setItemView] = useState<CashflowReceiptModel | null>(null);
	const [openItemView, setOpenItemView] = useState(false);
	const [isConfirmCashflow, setIsConfirmCashflow] = useState(false);

	const fetchData = useCallback(async () => {
		setLoading(true);
		let collection = await new CashflowReceiptRepository().getItems({
			filters: {
				...CashflowReceiptRepository.getDefaultFilters(),
				source: CashflowReceipt.SOURCE_SALE_ORDER,
				source_id: +order.id,
			},
		});

		setLoading(false);
		if (collection.hasError()) {
			setErrors(collection.error.errors);
		} else {
			setDataSource(collection.items);
		}
	}, [order]);

	function handlerViewItem(item: CashflowReceiptModel) {
		setItemView(item);
		setOpenItemView(true);
	}

	function handlerViewAddItem(direction: number) {
		const itemPrev = dataSource.length ? dataSource[0] : null;
		const isIn = direction === CashflowReceipt.DIRECTION_INCOME;
		const itemAdd: CashflowReceiptModel = new CashflowReceiptModel({
			...CashflowReceiptModel.getDefaultData(),
			direction: direction,
			cashflow_group: isIn
				? CashflowReceipt.GROUP_IN_SALE
				: CashflowReceipt.GROUP_OUT_RETURN,
			method: itemPrev?.method || CashflowReceipt.METHOD_CASH,
			source:
				itemPrev?.source ||
				(isIn
					? CashflowReceipt.SOURCE_SALE_ORDER
					: CashflowReceipt.SOURCE_SALE_ORDER_RETURN),
			value: isIn ? order.debt : order.total_payment,
			target: CashflowReceipt.TARGET_CUSTOMER,
			target_id: order.customer_id,
			status: CashflowReceipt.STATUS_DRAFT,
			store_id: order.store_id,
			source_id: +order.id,
		});
		setItemView(itemAdd);
		setOpenItemView(true);
	}

	useEffect(() => {
		fetchData();
	}, [fetchData]);
	useEffect(() => {
		if (isSuccess) {
			fetchData();
		}
	}, [isSuccess]);

	//////////////////////////////////////////
	//Table columns
	const columns: TableColumnsType<CashflowReceiptModel> = [
		{
			title: t("cashflowreceipt:id"),
			dataIndex: "id",
			align: "center",
			fixed: "left",
			width: 200,
			render: (id, record) => {
				return (
					<>
						<Typography.Text
							copyable
							className=" cursor-pointer font-semibold text-blue-500"
							onClick={() => {
								handlerViewItem(record);
							}}>
							{id}
						</Typography.Text>
						{/* <RoleCheck roles={[Role.CASHFLOW_EDIT]} componentOnFail={}>
							<Popover
								title={
									<>
										Chi tiết
										{CashflowReceiptModel.getDirection(record.direction)?.label}
									</>
								}
							>
								<Typography.Link target="_blank" href={`/cashflowreceipt/edit/id/${id}`} className="  text-blue-500 font-semibold">{id}</Typography.Link>
							</Popover>
						</RoleCheck> */}
					</>
				);
			},
		},
		{
			title: t("order:inventoryreceipt.direction"),
			dataIndex: "direction",
			sortby: "direction",
			render: (direction: number) => {
				let directionInfo = CashflowReceiptModel.getDirection(direction);
				return <Tag color={directionInfo?.color}>{directionInfo?.label}</Tag>;
			},
			width: 120,
		},
		{
			title: t("common:status"),
			dataIndex: "status",
			render: (status: number) => {
				let statusInfo = CashflowReceiptModel.getStatus(status);
				return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
			},
			width: 120,
		},
		// {
		// 	title: t("cashflowreceipt:filter_store_id"),
		// 	dataIndex: "store_id",
		// 	width: 200,
		// 	render: (store_id: number) => {
		// 		if (store_id > 0) {
		// 			const found = storeItemsWithLimit.find((i) => i.id === store_id);
		// 			if (typeof found !== "undefined") {
		// 				return <span>{found.name}</span>;
		// 			} else {
		// 				return "ID #" + store_id;
		// 			}
		// 		} else {
		// 			return "-";
		// 		}
		// 	},
		// },
		// {
		//   title: t("cashflowreceipt:name"),
		//   dataIndex: "name",
		// },
		{
			title: t("cashflowreceipt:value"),
			dataIndex: "value",
			align: "right",
			className: "font-bold",
			render: (value: number) => {
				return <TextMoney money={value} />;
			},
		},

		{
			title: (
				<>
					<Tooltip
						title={t("cashflowreceipt:filter_method")}
						mouseEnterDelay={0.4}>
						{t("cashflowreceipt:method_sort")}
					</Tooltip>
				</>
			),
			dataIndex: "method",
			align: "center",
			render: (method: number) => {
				return <TextCashflowReceiptMethod method={method} />;
			},
		},

		{
			// title: t("cashflowreceipt:date_affected_short"),
			togglelabel: t("cashflowreceipt:date_affected"),
			dataIndex: "date_affected",
			align: "end",
			render: (date_affected, record) => {
				return (
					<>
						{/* {date_affected > 0 ? (
							<TextDateTime format="HH:mm, DD/MM" ts={date_affected} />
						) : (
							<span>Trống</span>
						)} */}

						<TableInfo record={record}></TableInfo>
					</>
				);
			},
		},
	];

	return (
		<div className="mt-8">
			<Row className="bg-gray-100 p-2">
				<Col
					span={3}
					className="text-xl text-gray-600 font-extrabold uppercase text-right px-2 bg-gray-100">
					{/* {t("order:form.section_receiptprefix")}
          <br /> */}
					{t("order:form.section_inventory_cashflowreceipt")}
					<Row>
						{status && status === Order.STATUS_CANCEL && (
							<Col span={24}>
								<Tooltip title="Xác nhận này sẽ cho phép hủy đơn mà không cần kiểm tra trình trạng cân bằng của phiếu thu - chi">
									<Checkbox
										defaultChecked={false}
										onChange={(value) => {
											setIsConfirmCashflow(value.target.checked);
											onConfirmCashFlow &&
												onConfirmCashFlow(value.target.checked);
										}}>
										Xác nhận hủy
									</Checkbox>
								</Tooltip>
							</Col>
						)}
					</Row>
				</Col>

				<Col span={21}>
					{errors.length > 0 ? (
						<ErrorAlert
							items={errors}
							heading={t("common:error.error_fetching_data")}
							onRetry={() => {
								setErrors([]);
								fetchData();
							}}
							translate_prefix="order:form.error"
						/>
					) : null}

					{!loading && dataSource.length === 0 ? (
						<div className="italic">{t("order:cashflow_receipt_empty")}</div>
					) : null}

					<div>
						<Table<CashflowReceiptModel>
							className="w-full border"
							rowKey={"id"}
							pagination={{
								pageSize: 100,
								hideOnSinglePage: true,
							}}
							columns={columns}
							dataSource={dataSource}
							loading={loading}
						/>

						<div className="my-2">
							<Button
								onClick={() => fetchData()}
								icon={<IconRefresh size={16} className="mr-1" />}
								className="opacity-40 hover:opacity-80"
								size="small"
								type="text">
								{t("order:form.refresh_receipt")}
							</Button>
							{order.status !== Order.STATUS_CANCEL && (
								<>
									<RoleCheck roles={[Role.CASHFLOW_ADD]}>
										<Button
											onClick={() =>
												handlerViewAddItem(CashflowReceipt.DIRECTION_INCOME)
											}
											icon={
												<IconSquarePlus size={16} className="mr-1 -mt-0.5" />
											}
											className="opacity-40 hover:opacity-80"
											size="small"
											type="text">
											{t("order:form.add_more_receipt_INCOME")}
										</Button>
									</RoleCheck>

									<RoleCheck roles={[Role.CASHFLOW_ADD]}>
										<Button
											onClick={() =>
												handlerViewAddItem(CashflowReceipt.DIRECTION_EXPENSE)
											}
											icon={
												<IconSquarePlus size={16} className="mr-1 -mt-0.5" />
											}
											className="opacity-40 hover:opacity-80"
											size="small"
											type="text">
											{t("order:form.add_more_receipt_DIRECTION_EXPENSE")}
										</Button>
									</RoleCheck>
								</>
							)}

							<Popover
								title={t("order:form.link_receipt.title")}
								content={
									<OrderFormSectionCashflowReceiptLinkForm
										onSuccess={() => fetchData()}
									/>
								}
								placement="rightTop"
								trigger={["click"]}>
								<Button
									icon={<IconLink size={16} className="mr-1 -mt-0.5" />}
									className="opacity-40 hover:opacity-80"
									size="small"
									type="text">
									{t("order:form.link_receipt.button")}
								</Button>
							</Popover>
						</div>
					</div>
				</Col>
			</Row>

			<Modal
				open={openItemView}
				destroyOnClose
				onCancel={() => setOpenItemView(false)}
				style={{ minWidth: 1000 }}
				bodyStyle={{ maxHeight: 600, overflow: "auto" }}
				title={``}
				footer={
					<div className=" flex gap-4  justify-between">
						{itemView && itemView?.id > 0 && (
							<RoleCheck roles={[Role.CASHFLOW_EDIT]} hideOnFail>
								<Link
									target="_blank"
									to={`/cashflowreceipt/edit/id/${itemView?.id}`}>
									<Button type="link" color="blue">
										Cập nhật thông tin phiếu #{itemView?.id}
									</Button>
								</Link>
							</RoleCheck>
						)}

						<Button onClick={() => setOpenItemView(false)}>Đóng</Button>
					</div>
				}>
				{itemView && (
					<CashflowReceiptForm
						fieldsLock={itemView.id ? [] : ["source", "source_id", "store_id"]}
						model={itemView}
						disable={order.status === Order.STATUS_CANCEL}
						refetch={() => {
							fetchData();
							refetchOrder?.();
							setOpenItemView(false);
						}}
					/>
				)}
			</Modal>
		</div>
	);
};

export default OrderFormSectionCashflowReceipt;
