import { Drawer } from "antd";
import File from "common/constants/File";
import FileModel from "common/models/FileModel";
import FileList from "components/file/list/FileList";
import LayoutTitle from "components/LayoutTitle";
import PageSiteMenu from "components/page/PageSiteMenu";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useFileManagerStore from "zustands/useFileManagerStore";

import FileDetail from "./FileDetail";
import FileListNavigation from "./FileListNavigation";

const FileListIndex = () => {
  const { t } = useTranslation();

  const [activeObjectType, activeOrigin] = useFileManagerStore((state) => [
    state.activeObjectType,
    state.activeOrigin,
  ]);

  const [activeFile, setActiveFile] = useFileManagerStore((state) => [
    state.activeFile,
    state.setActiveFile,
  ]);

  const objectTypeHeading = useMemo(() => {
    let heading = "";
    switch (activeObjectType) {
      case File.OBJECTTYPE_FILE:
        heading = t("file:navigation.heading.OBJECTTYPE_FILE");
        break;
      case File.OBJECTTYPE_CMS:
        heading = t("file:navigation.heading.OBJECTTYPE_CMS");
        break;
      case File.OBJECTTYPE_ATTACHMENT:
        heading = t("file:navigation.heading.OBJECTTYPE_ATTACHMENT");
        break;
    }

    return heading;
  }, [t, activeObjectType]);

  const onSelectFile = useCallback(
    (file: FileModel) => {
      if (activeFile?.id === file.id) {
        setActiveFile(undefined);
      } else {
        setActiveFile(file);
      }
    },
    [activeFile, setActiveFile]
  );

  useEffect(() => {
    setActiveFile(undefined);
  }, [activeObjectType, activeOrigin, setActiveFile]);

  return (
    <>
      <LayoutTitle title={t("file:heading_list")} />
      <PageSiteMenu siteMenuSelectedKey={"/filemanager"} />

      <FileListNavigation />

      <div className="p-4">
        <div className="mb-1 font-semibold text-2xl text-gray-800">
          {objectTypeHeading}
        </div>
        <div className="mb-3 text-gray-500">
          {activeObjectType !== File.OBJECTTYPE_FILE
            ? t("file:navigation.summary_prefix")
            : null}
          {t("file:navigation.origin." + activeOrigin)}
        </div>

        <FileList
          key={activeObjectType}
          objectType={activeObjectType}
          origin={
            activeObjectType === File.OBJECTTYPE_FILE ? "company" : activeOrigin
          }
          allowExtensions={
            activeObjectType === File.OBJECTTYPE_FILE
              ? FileModel.getAllSupportUploadExtensions()
              : []
          }
          onSelectFile={onSelectFile}
        />
      </div>

      <Drawer
        title={t("file:description.heading")}
        open={typeof activeFile !== "undefined"}
        onClose={() => setActiveFile(undefined)}
      >
        <FileDetail />
      </Drawer>
    </>
  );
};

export default FileListIndex;
