import { ConfigProvider, App } from "antd";

const AppWithThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <App>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 4,
            // colorPrimary: "#00b96b",
            // colorBgContainer: "#f6ffed",
          },

          components: {},
        }}
      >
        {children}
      </ConfigProvider>
    </App>
  );
};

export default AppWithThemeProvider;
