import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";
import CompanyModel from "./CompanyModel";
import EmployeeModel from "./EmployeeModel";

import type {
  AccountCompanyEmployee,
  AccountJson,
  AccountRole,
} from "common/types/Account";
import FileModel from "./FileModel";
import { CompanyJson } from "common/types/Company";
import { FileJson } from "common/types/File";
class AccountModel extends BaseModel implements BaseModelJson<AccountJson> {
  id: number;
  full_name: string;
  screenname: string;
  avatar_file_id: number;
  avatar: FileModel;
  jwt: string;
  role: AccountRole[];
  company: CompanyJson;
  constructor(json: AccountJson) {
    super();

    this.id = json.id || 0;
    this.full_name = json.full_name || "";
    this.screenname = json.screenname || "";
    this.avatar_file_id = json.avatar_file_id || 0;
    this.avatar = new FileModel(json.avatar || FileModel.getDefaultData());
    this.jwt = json.jwt || "";
    this.role = json.role || [];
    this.company = new CompanyModel(
      json.company || CompanyModel.getDefaultData()
    );
  }

  static getDefaultData(): AccountJson {
    return {
      id: 0,
      full_name: "",
      screenname: "",
      avatar_file_id: 0,
      avatar: FileModel.getDefaultData(),
      jwt: "",
      role: [],
      company: CompanyModel.getDefaultData(),
    };
  }

  toJson(): AccountJson {
    return {
      id: this.id,
      full_name: this.full_name,
      screenname: this.full_name,
      avatar_file_id: this.avatar_file_id,
      avatar: this.avatar,
      jwt: this.jwt,
      role: this.role,
      company: this.company,
    };
  }

  static parseLoginResponse(json: any): AccountJson {
    //the hardest part is the "role" section
    //response role has format: id,id,id-subid-subid..
    //this role need to convert to 'AccountRole' array
    let roles: AccountRole[] = [];
    if (typeof json.role === "string" && json.role.length > 0) {
      roles = json.role.split(",").flatMap((rolePart: string) => {
        //detect has objectid
        if (rolePart.indexOf(":") !== -1) {
          const rolePartGroup = rolePart.split(":", 2);
          return [
            {
              subject_id: parseInt(rolePartGroup[0]),
              id_list: rolePartGroup[1].split("-").map(Number),
            },
          ];
        } else {
          return [
            {
              subject_id: parseInt(rolePart),
              id_list: [],
            },
          ];
        }
      });
    }

    return {
      id: json.user.id || 0,
      full_name: json.user.full_name || "",
      screenname: json.user.screenname || "",
      avatar_file_id: json.user.avatar_file_id || 0,
      avatar: json.user.avatar || FileModel.getDefaultData(),
      jwt: json.jwt || "",
      role: roles,
      company:
        typeof json.company !== "undefined"
          ? json.company
          : CompanyModel.getDefaultData(),
    };
  }
}

export default AccountModel;
