import WorkTrackingReviewApi from 'common/api/WorkTrackingReviewApi';
import WorkTrackingCollection from 'common/collections/WorkTrackingCollection';
import WorkTrackingModel from 'common/models/WorkTrackingModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  WorkTrackingJsonAddEdit,
  WorkTrackingJsonVerify,
  FilterWorkTrackingReview,
} from "common/types/WorkTracking";
class WorkTrackingReviewRepository extends BaseRepository<WorkTrackingReviewApi> {
  _api: WorkTrackingReviewApi | null;

  constructor() {
    super();
    this._api = new WorkTrackingReviewApi(true);
  }

  static getDefaultFilters(): FilterWorkTrackingReview {
    return {
      page: 1,
      limit: 20,
      sortby: "name",
      sorttype: "ASC",
      status: -1,
      keyword: "",
      id: 0,
      office_id: 0,
    };
  }

  async getItems(props: GetListProps<FilterWorkTrackingReview>) {
    return this._api
      ? await this._api.getList(props)
      : new WorkTrackingCollection();
  }

  async getItem(id: number, office_id: number): Promise<WorkTrackingModel> {
    if (this._api) {
      const collection = await this._api.getList({
        filters: {
          ...WorkTrackingReviewRepository.getDefaultFilters(),
          limit: 1,
          id,
          office_id,
        },
      });
      if (!collection.hasError() && collection.items.length > 0) {
        return collection.items[0];
      }
    }

    return new WorkTrackingModel(WorkTrackingModel.getDefaultData());
  }

  async edit(data: WorkTrackingJsonAddEdit): Promise<WorkTrackingModel> {
    if (this._api) {
      return await this._api.edit(data);
    } else {
      return new WorkTrackingModel(WorkTrackingModel.getDefaultData());
    }
  }

  async saveVerify(data: WorkTrackingJsonVerify): Promise<WorkTrackingModel> {
    if (this._api) {
      return await this._api.verify(data);
    } else {
      return new WorkTrackingModel(WorkTrackingModel.getDefaultData());
    }
  }
}

export default WorkTrackingReviewRepository;
