  import TagConstant from "common/constants/Tag";
import BrandModel from "common/models/BrandModel";
import BrandRepository from "common/repositories/BrandRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import BrandForm from "features/brand/form/BrandForm";
import BrandFormHeader from "features/brand/form/BrandFormHeader";
import BrandList from "features/brand/list/BrandList";
import TagOutSideList from "features/tagproduct/list/TagOutSideList";
import usePathParams from "hooks/usePathParams";
import { useEffect, useState } from "react";

const BrandPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new BrandModel(BrandModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new BrandModel(BrandModel.getDefaultData()));
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    case "add":
      com = <BrandForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <BrandForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new BrandRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<BrandFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    default:
      com = <BrandList />;
      break;
  }
  return com;
};

export default BrandPage;
