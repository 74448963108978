import React from "react";
import CustomerTypeList from "features/customertype/list/CustomerTypeList";
import usePathParams from "hooks/usePathParams";

const CustomerTypePage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <CustomerTypeList />;
  }

  return com;
};

export default CustomerTypePage;
