import { IconEdit } from "@tabler/icons-react";
import { Button, ButtonProps, Tooltip } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TableEdit = ({
	link,
	icon,
	label,
	tooltip,
	disabled,
	onClick,
	buttonType,
	buttonBlock,
	buttonSize,
	className,
}: {
	link: string;
	icon?: React.ReactNode;
	label?: string;
	tooltip?: string;
	disabled?: boolean;
	onClick?: () => void;
	buttonType?: ButtonProps["type"];
	buttonBlock?: ButtonProps["block"];
	buttonSize?: ButtonProps["size"];
	className?: string;
}) => {
	const { t } = useTranslation();
	let navigate = useNavigate();

	const onClickProxy = useCallback(() => {
		if (typeof onClick !== "undefined") {
			onClick();
		}

		if (typeof link !== "undefined" && link && link.length > 0) {
			navigate(link);
		}
	}, [navigate, link, onClick]);

	const buttonCom = (
		<Button
			onClick={onClickProxy}
			type={buttonType || "link"}
			size={buttonSize || "small"}
			disabled={disabled}
			style={{ whiteSpace: "break-spaces" }}
			icon={icon ?? undefined}
			block={buttonBlock}
			className={className}>
			{typeof label !== "undefined" ? label : t("common:table.edit")}
		</Button>
	);

	if (typeof tooltip === "undefined") {
		return buttonCom;
	}

	return (
		<Tooltip
			title={tooltip}
			mouseEnterDelay={0.4}
			placement={tooltip.length > 10 ? "topRight" : "top"}>
			{buttonCom}
		</Tooltip>
	);
};

export default TableEdit;
