import LeaveApi from "common/api/LeaveApi";
import LeaveCollection from "common/collections/LeaveCollection";
import LeaveModel from "common/models/LeaveModel";
import { GetListProps } from "common/types/Filter";
import {
	FilterHistoryLeave,
	FilterLeave,
	LeaveJsonAddEdit,
} from "common/types/Leave";
import BaseRepository from "./BaseRepository";
import LeaveHistoryCollection from "common/collections/LeaveHistoryCollection";

class LeaveRepository extends BaseRepository<LeaveApi> {
	_api: LeaveApi | null;

	constructor() {
		super();
		this._api = new LeaveApi(true);
	}

	static getDefaultFilters(): FilterLeave {
		return {
			page: 1,
			limit: 20,
			sortby: "",
			sorttype: "",
			keyword: "",
			status: -1,
			leavetype_id: -1,
			date_from: -1,
			date_to: -1,
			department_id: -1,
			sort_type: "DESC",
			sort_by: "leave_start",
		};
	}

	async getItems(props: GetListProps<FilterLeave>) {
		return this._api ? await this._api.getList(props) : new LeaveCollection();
	}
	async getLeaveOffToday(): Promise<LeaveCollection> {
		return this._api
			? await this._api.getLeaveOffToday()
			: new LeaveCollection();
	}
	async getItem(id: number): Promise<LeaveModel> {
		return this._api
			? await this._api.getDetail(id)
			: new LeaveModel(LeaveModel.getDefaultData());
	}

	async saveRemote(data: LeaveJsonAddEdit): Promise<LeaveModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new LeaveModel(LeaveModel.getDefaultData());
		}
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}

	async getHistoryItems(
		props: FilterHistoryLeave
	): Promise<LeaveHistoryCollection> {
		return this._api
			? await this._api.getListHistory(props)
			: new LeaveHistoryCollection();
	}
}

export default LeaveRepository;
