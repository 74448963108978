const Task = {
  STATUS: {
    TODO: 0,
    PENDING: 1,
    INPROGRESS: 2,
    DONE: 3,
    BLOCK: 4,
    BACKLOG: 5,
  },
};

export default Task;
