import StoreModel from 'common/models/StoreModel';
import { StoreJson } from 'common/types/Store';
import useDatabaseTable from 'hooks/useDatabaseTable';

const TextStore = ({ id   ,stores}: { id: number , stores?: StoreJson[] }) => {
  const [items] =  useDatabaseTable<StoreJson>("store");

  const storeItems = stores || items

  return <>{storeItems.find((item) => item.id === id)?.name || "--"}</>;
};

export default TextStore;
