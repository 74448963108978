import { Form, Input } from 'antd';

const PosRightPaymentMethodInputNote = ({
  note,
  setNote,
}: {
  note: string;
  setNote: (v: string) => void;
}) => {
  return (
    <Form.Item label="Ghi chú thanh toán">
      <Input.TextArea
        value={note}
        rows={1}
        autoSize={true}
        onChange={(e) => setNote(e.currentTarget.value)}
      ></Input.TextArea>
    </Form.Item>
  );
};

export default PosRightPaymentMethodInputNote;
