import { Alert, App, Modal } from "antd";
import ProductRepository from "common/repositories/ProductRepository";
import ErrorAlert from "components/ErrorAlert";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductSyncFormModal = ({
  open,
  setOpen
}: {
  open: boolean;
  setOpen: (v: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [companyResources] = useDatabaseTable<any>("companyresource");
  const jsVersion = useMemo(() => {
    if (Array.isArray(companyResources) && companyResources.length > 0) {
      return companyResources[0].jsversion;
    }
  }, [companyResources]);

  const onSubmit = async () => {
    setProcessing(true);
    setErrors([]);
    const responseErrors =
      await new ProductRepository().resyncCompanyResource();
    if (responseErrors.length > 0) {
      setErrors(responseErrors);
    } else {
      message.success({
        content: t("common:form.success.general"),
        className: "message_success",
        duration: 2
      });
    }
    setProcessing(false);
  };

  return (
    <>
      <Modal
        title={t("product:sync_title")}
        width={720}
        open={open}
        closable
        maskClosable
        onCancel={() => {
          setOpen(false);
        }}
        confirmLoading={processing}
        onOk={onSubmit}
        okText={t("product:sync_product")}
        cancelText={t("common:close")}>
        <div className="mb-2 text-lg">Các dữ liệu sẽ được đồng bộ bao gồm:</div>
        <ul className="ml-5 list-disc">
          <li>Sản phẩm (version hiện tại: {jsVersion?.product})</li>
          <li>SKU (version hiện tại: {jsVersion?.productvariant})</li>
        </ul>{" "}
        <br />
        {errors.length > 0 ? (
          <ErrorAlert items={errors} className="mb-2" />
        ) : null}
        <Alert
          message="Sau khi đồng bộ, các máy tính khác sẽ nhận được thông tin sản phẩm mới."
          type="info"
        />
      </Modal>
    </>
  );
};

export default ProductSyncFormModal;
