import { Empty, Table } from "antd";
import WorkTracking from "common/constants/WorkTracking";
import WorkTrackingModel from "common/models/WorkTrackingModel";
import { TableColumnsType } from "common/types/Table";
import { WorkTrackingDetailViewHistoryJson } from "common/types/WorkTracking";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import UserIdAvatar from "components/UserIdAvatar";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconArrowBigRight } from "@tabler/icons-react";
import { UserSwitchOutlined } from "@ant-design/icons";

const WorkTrackingApprovalFormViewHistory = ({
  dataSource,
}: {
  dataSource: WorkTrackingDetailViewHistoryJson[];
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<WorkTrackingDetailViewHistoryJson> = [
    {
      title: t("common:date_created"),
      key: "datecreated",
      dataIndex: "datecreated",
      render: (_: any, record: WorkTrackingDetailViewHistoryJson) => (
        <TextDateTime ts={record.datecreated} format={"HH:mm DD/MM/YYYY"} />
      ),
    },
    {
      title: " ",
      key: "user-id-avatar",
      dataIndex: "user-id-avatar",
      render: (_: any, record: WorkTrackingDetailViewHistoryJson) => (
        <UserSwitchOutlined></UserSwitchOutlined>
      ),
    },
    {
      title: t("worktracking:editor"),
      key: "editor",
      dataIndex: "editor",
      render: (_: any, record: WorkTrackingDetailViewHistoryJson) => (
        <TextUser id={record.editor} />
      ),
    },
    {
      title: t("worktracking:edit_detail"),
      key: "edit_detail",
      dataIndex: "edit_detail",
      render: (_: any, record: WorkTrackingDetailViewHistoryJson) =>
        record.action === "edit" ? renderDetailActionEdit(record) : null,
    },
    {
      title: t("worktracking:edit_note"),
      key: "edit_note",
      dataIndex: "edit_note",
      render: (_: any, record: WorkTrackingDetailViewHistoryJson) => (
        <span>{record.editnote}</span>
      ),
    },
  ];

  const getAllowLateText = (allowLate: number) => {
    let text = "";
    switch (allowLate) {
      case WorkTracking.ALLOW_LATE_ALLOW:
        text = t("worktracking:yes");
        break;
      case WorkTracking.ALLOW_LATE_NOT_ALLOW:
        text = t("worktracking:no");
        break;
      default:
        text = t("worktracking:no");
    }
    return text;
  };

  const renderDetailActionEdit = (
    record: WorkTrackingDetailViewHistoryJson
  ) => {
    let details = [];

    if (record.checkinfrom !== record.checkinto) {
      details.push(
        <p key={Math.floor(Math.random() * 1000 + 1)}>
          {t("worktracking:checkin_history_in")}:{" "}
          {record.checkinfrom > 0 ? (
            <strong>
              <TextDateTime
                ts={record.checkinfrom}
                format={"HH:mm, DD/MM/YYYY"}
              />
            </strong>
          ) : (
            "---"
          )}{" "}
          <IconArrowBigRight size={14} color="orange" />{" "}
          {record.checkinto > 0 ? (
            <strong>
              <TextDateTime
                ts={record.checkinto}
                format={"HH:mm, DD/MM/YYYY"}
              />
            </strong>
          ) : (
            "---"
          )}
        </p>
      );
    }

    if (record.checkoutfrom !== record.checkoutto) {
      details.push(
        <p key={Math.floor(Math.random() * 1000 + 1)}>
          {t("worktracking:checkin_history_out")}:{" "}
          {record.checkoutfrom > 0 ? (
            <strong>
              <TextDateTime
                ts={record.checkoutfrom}
                format={"HH:mm, DD/MM/YYYY"}
              />
            </strong>
          ) : (
            "---"
          )}{" "}
          <IconArrowBigRight size={14} color="orange" />{" "}
          {record.checkoutto > 0 ? (
            <strong>
              <TextDateTime
                ts={record.checkoutto}
                format={"HH:mm, DD/MM/YYYY"}
              />
            </strong>
          ) : (
            "---"
          )}
        </p>
      );
    }

    //because there the problem with 0 and not allow (3), we need to parse text first and compare this text diff
    let allowLateFromText = getAllowLateText(record.allowlatefrom);
    let allowLateToText = getAllowLateText(record.allowlateto);
    if (allowLateFromText !== allowLateToText) {
      details.push(
        <p key={Math.floor(Math.random() * 1000 + 1)}>
          {t("worktracking:checkin_history_allowlate")}:{" "}
          <strong>{allowLateFromText}</strong>{" "}
          <IconArrowBigRight size={14} color="orange" />{" "}
          <strong>{allowLateToText}</strong>
        </p>
      );
    }

    //because there the problem with 0 and not allow (3), we need to parse text first and compare this text diff
    let allowEarlyFromText = getAllowLateText(record.allowearlyfrom);
    let allowEarlyToText = getAllowLateText(record.allowearlyto);
    if (allowEarlyFromText !== allowEarlyToText) {
      details.push(
        <p key={Math.floor(Math.random() * 1000 + 1)}>
          {t("worktracking:checkin_history_allowearly")}:{" "}
          <strong>{allowEarlyFromText}</strong>{" "}
          <IconArrowBigRight size={14} color="orange" />{" "}
          <strong>{allowEarlyToText}</strong>
        </p>
      );
    }

    if (record.allowotfrom !== record.allowotto) {
      details.push(
        <p key={Math.floor(Math.random() * 1000 + 1)}>
          {t("worktracking:checkin_history_ot")}:{" "}
          <strong>{WorkTrackingModel.getOT(record.allowotfrom)?.label}</strong>{" "}
          <IconArrowBigRight size={14} color="orange" />{" "}
          <strong>{WorkTrackingModel.getOT(record.allowotto)?.label}</strong>
        </p>
      );
    }

    return details;
  };

  return (
    <>
      {dataSource.length > 0 ? (
        <Table
          rowKey="datecreated"
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default WorkTrackingApprovalFormViewHistory;
