import { IconShoppingCart } from "@tabler/icons-react";
import { Card, Table } from "antd";
import TaxInvoiceDetailModel from "common/models/TaxInvoiceDetailModel";
import { TableColumnsType } from "common/types/Table";
import TextMoney from "components/TextMoney";
import { useTranslation } from "react-i18next";

const TaxInvoiceFormSectionDetailList = ({
  taxInvoiceDetails,
}: {
  taxInvoiceDetails: TaxInvoiceDetailModel[];
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<TaxInvoiceDetailModel> = [
    {
      title: t("taxinvoice:order"),
      dataIndex: "id",
      width: 50,
      align: "center",
      render: (id, record, index) => {
        return index + 1;
      },
    },
    {
      title: t("taxinvoice:product"),
      dataIndex: "item_name",
    },
    {
      title: t("taxinvoice:unit"),
      dataIndex: "item_unit_name",
      align: "center",
    },
    {
      title: t("taxinvoice:quantity"),
      dataIndex: "item_quantity",
      align: "center",
    },
    {
      title: t("taxinvoice:unit_price"),
      dataIndex: "item_unit_price",
      align: "right",
      render: (item_unit_price: number, record) => {
        return <TextMoney money={item_unit_price} />;
      },
    },
    {
      title: t("taxinvoice:tax"),
      dataIndex: "tax",
      align: "center",
      render: (tax: number, record) => {
        return <span>{tax}%</span>;
      },
    },
    {
      title: t("taxinvoice:price_sell"),
      dataIndex: "price_sell",
      align: "right",
      render: (price_sell: number, record) => {
        return <TextMoney money={price_sell} />;
      },
    },
    {
      title: t("taxinvoice:price_tax"),
      dataIndex: "price_tax",
      align: "right",
      render: (price_tax: number, record) => {
        return <TextMoney money={price_tax} />;
      },
    },
    {
      title: t("taxinvoice:price_final"),
      dataIndex: "price_final",
      align: "right",
      render: (price_final: number, record) => {
        return <TextMoney money={price_final} />;
      },
    },
  ];

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      title={
        <span className="uppercase">
          <IconShoppingCart className="mr-1 -mt-1" />
          {t("taxinvoice:group_detail")}
        </span>
      }
      headStyle={{ background: "#eee" }}
    >
      <Table
        size="small"
        rowKey="id"
        pagination={{ hideOnSinglePage: true }}
        columns={columns}
        dataSource={taxInvoiceDetails}
      />
    </Card>
  );
};

export default TaxInvoiceFormSectionDetailList;
