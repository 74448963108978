import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const SettingTaxHeader = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("setting:heading_tax")}
      siteMenuSelectedKey="/setting/tax"
      siteMenuOpenKey="setting_sale"
    >
      {children}
    </PageHeader>
  );
};

export default SettingTaxHeader;
