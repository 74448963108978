import { Modal, Skeleton } from "antd";
import ContractTypeModel from "common/models/ContractTypeModel";
import ContractTypeRepository from "common/repositories/ContractTypeRepositories";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContractTypesForm from "./ContractTypeForm";
import ErrorAlert from "components/ErrorAlert";

const ContractTypesFormModal = ({
  id,
  visible,
  setVisible,
  onSaveSuccess,
}: {
  id: number;
  visible: boolean;
  setVisible: (v: boolean) => void;
  onSaveSuccess: (m: ContractTypeModel) => void;
}) => {
  const { t } = useTranslation();

  //state
  const [model, setModel] = useState<ContractTypeModel>(
    new ContractTypeModel(ContractTypeModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  //fetch
  const fetchDetail = async (id: number) => {
    let myObj = await new ContractTypeRepository().getItem(id);
    console.log("myObj", myObj);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);
  return (
    <Modal
      title={id > 0 ? t("Chỉnh sửa") : t("Thêm mới")}
      width={400}
      open={visible}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        setModel(new ContractTypeModel(ContractTypeModel.getDefaultData()));
        setVisible(false);
      }}
      okButtonProps={{ form: "contracttypes-form", htmlType: "submit" }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      confirmLoading={processing}
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <ContractTypesForm
                  model={model}
                  setVisible={setVisible}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <ContractTypesForm
          model={new ContractTypeModel(ContractTypeModel.getDefaultData())}
          setVisible={setVisible}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </Modal>
  );
};

export default ContractTypesFormModal;
