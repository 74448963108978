import CmsNewsCategoryRepository from 'common/repositories/CmsNewsCategoryRepository';
import { SelectOption } from 'common/types/SelectOption';
import FormSelect from 'components/form/FormSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CmsNewsCategoryFormSelect = ({
  label,
  name,
  parentId,
  ignoreValues,
  placeholder,
}: {
  label: string;
  name: string;
  parentId?: number;
  ignoreValues?: number[];
  placeholder?: string;
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new CmsNewsCategoryRepository().getItems({
      filters: CmsNewsCategoryRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
          parent_id: item.parent_id,
        }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  return (
    <FormSelect
      isTree={typeof parentId === "undefined"}
      placeholder={placeholder ?? t("common:form.select_category")}
      loading={loading}
      showSearch
      label={label}
      allowClear
      name={name}
      options={options
        .filter((item) =>
          typeof parentId === "undefined" ? true : parentId === item.parent_id
        )
        .filter(
          (item) =>
            typeof ignoreValues === "undefined" ||
            !ignoreValues.includes(item.value)
        )}
      errors={errors}
      errorRetry={fetchData}
    />
  );
};

export default CmsNewsCategoryFormSelect;
