import BaseCollection from 'common/collections/BaseCollection';
import ProductInventoryCheckingDetailModel from 'common/models/ProductInventoryCheckingDetailModel';
import {
    ProductInventoryCheckingDetailCollectionJson, ProductInventoryCheckingDetailJson
} from 'common/types/ProductInventoryCheckingDetail';

class ProductInventoryCheckingDetailCollection extends BaseCollection<
  ProductInventoryCheckingDetailJson,
  ProductInventoryCheckingDetailModel,
  ProductInventoryCheckingDetailCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductInventoryCheckingDetailJson[]
  ): ProductInventoryCheckingDetailModel[] {
    return jsonItems.map(
      (item) => new ProductInventoryCheckingDetailModel(item)
    );
  }
}

export default ProductInventoryCheckingDetailCollection;
