import BaseRepository from "./BaseRepository";
import type { GetListProps } from "common/types/Filter";

//////////////////////////////////
//Phần thay thế
import { FilterInventory, InventoryJsonAddEdit } from "common/types/Inventory";
import InventoryApi from "common/api/InventoryApi";
import InventoryCollection from "common/collections/InventoryCollection";
import InventoryModel from "common/models/InventoryModel";

class TemplateRepository extends BaseRepository<InventoryApi> {
	_api: InventoryApi | null;
	constructor() {
		super();
		this._api = new InventoryApi(true);
	}

	static getDefaultFilter(): FilterInventory {
		return {
			page: 1,
			limit: 20,
			sortby: "",
			sorttype: "DESC",
			keyword: "",
			status: "",
			warehouse_id: undefined,
		};
	}

	async getItems(
		props: GetListProps<FilterInventory>
	): Promise<InventoryCollection> {
		return this._api
			? await this._api.getList(props.filters)
			: new InventoryCollection();
	}

	async getItem(id: number): Promise<InventoryModel> {
		return this._api
			? await this._api.getDetail(id)
			: new InventoryModel(InventoryModel.getDefaultData());
	}

	async saveRemote(data: InventoryJsonAddEdit): Promise<InventoryModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new InventoryModel(InventoryModel.getDefaultData());
		}
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.delete(id) : [];
	}
}

export default TemplateRepository;
