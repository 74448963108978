import dbm from 'dbm';
import React, { useEffect, useState } from 'react';

const TextProductVariantColor = ({ id }: { id: number }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    (async () => {
      const foundItems = await dbm
        .getCollection("productvariantcolor")
        .chain()
        .find({
          id,
        })
        .limit(1)
        .data();
      if (foundItems.length > 0) {
        setName(foundItems[0].name);
      }
    })();
  }, [id]);

  return <span>{name ?? "-"}</span>;
};

export default TextProductVariantColor;
