import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Image, TableColumnsType, Tag, Typography } from "antd";

import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";

import PageDataPagination from "components/page/PageDataPagination";
import TextDateTime from "components/TextDateTime";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";

import { CheckinJson, FilterCheckin } from "common/types/Checkin";
import { IdFetcherResult } from "common/types/IdFetcher";

import CheckinRepository from "common/repositories/CheckinRepository";
import CheckinModel from "common/models/CheckinModel";
import CheckinCollection from "common/collections/CheckinCollection";

import CheckinListFilter from "./CheckinListFilter";
import CheckinListHeader from "./CheckinListHeader";
import useIdFetcher from "hooks/useIdFetcher";
import { IconMapPin } from "@tabler/icons-react";
import FileModel from "common/models/FileModel";
import useDatabaseTable from "hooks/useDatabaseTable";
import { EmployeeJson } from "common/types/Employee";

const CheckinList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { getListIdFetcher } = useIdFetcher();
	const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");
	////////////////////////
	// modal editing
	const [addVisible, setAddVisible] = useState(false);
	const [editingId, setEditingId] = useState(0);

	////////////////////////
	// default filter
	const defaultFilters = useMemo(() => {
		return CheckinRepository.getDefaultFilters();
	}, []);

	///////////////////////
	//state
	const [total, setTotal] = useState<number>(0);
	const [dataSource, setDataSource] = useState<CheckinModel[]>([]);
	const [departments, setDepartments] = useState<IdFetcherResult[]>([]);

	const [filters, setFilters] = useStateFilter(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//Table columns
	const columns: TableColumnsType<CheckinModel> = [
		{
			title: t("checkin:full_name"),
			key: "full_name",
			fixed: "left",
			align: "center",
			width: 120,
			render: (_, record) => {
				return (
					// <IdFetcherRender mapping={employees} value={record.employee_id} />
					<Typography.Text>
						{employeeItems.find((i) => i.id === record.employee_id)?.full_name}
					</Typography.Text>
				);
			},
		},
		{
			title: t("common:columns.internal_id"),
			key: "id",
			width: 100,
			render: (_, record) => {
				// const internalId =
				//   handleEmployeeResult(record.employee_id)?.internal_id || "";
				// return <Tag>{internalId}</Tag>;
				return (
					<Tag>
						{
							employeeItems.find((i) => i.id === record.employee_id)
								?.internal_id
						}
					</Tag>
				);
			},
		},

		{
			title: t("checkin:job_title"),
			key: "job_title",
			width: 100,
			render: (_, record) => {
				// const jobTitle =
				//   handleEmployeeResult(record.employee_id)?.job_title || "";

				// return jobTitle;
				return (
					<Typography.Text className="capitalize">
						{employeeItems.find((i) => i.id === record.employee_id)?.job_title}
					</Typography.Text>
				);
			},
		},
		{
			title: t("checkin:department_title"),
			key: "department_id",
			width: 120,
			render: (department_id: number, record) => {
				return (
					<Typography.Text className="capitalize">
						<IdFetcherRender
							mapping={departments}
							value={department_id}
							key_value="text"
						/>
					</Typography.Text>
				);
			},
		},
		{
			title: t("checkin:type"),
			key: "type",
			width: 120,
			render: (type: number) => {
				return (
					<Tag color={type === 0 ? "orange" : "green"}>
						{CheckinModel.getCheckinType(type)}
					</Tag>
				);
			},
		},
		{
			title: t("checkin:time_checkin"),
			key: "datecreated",
			width: 150,

			render: (datecreated) => {
				return (
					<TextDateTime
						format="HH:mm, DD/MM/YYYY"
						ts={datecreated}></TextDateTime>
				);
			},
		},
		{
			key: "latitude",
			title: t("checkin:latitude"),
			width: 100,
			render: (_: any, record: CheckinModel) => {
				return (
					<>
						<Typography.Link
							href={`https://maps.google.com/?q=${record.latitude},${record.longitude}`}
							target="_blank">
							<IconMapPin />
						</Typography.Link>
					</>
				);
			},
		},
		{
			key: "image",
			title: t("checkin:image"),
			align: "center",
			width: 120,
			render: (_, record) => {
				if (record.image.id <= 0) {
					return <Tag>Trống</Tag>;
				}
				return (
					<Image
						width={120}
						height={120}
						src={FileModel.getThumbnailFromUrl(
							record.image.file_path,
							480,
							480,
							"resize"
						)}></Image>
				);
			},
		},

		// {
		//   title: "",
		//   key: "actions",
		//   width: 100,
		//   align: "right",
		//   fixed: "right",
		//   render: (_: any, record: CheckinJson) => (
		//     <RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
		//       <TableEdit link="" onClick={() => onEdit(record.id)} />
		//       <TableDelete
		//         error_translate_prefix="department:form.error"
		//         onDeleteCallback={(id) => {
		//           fetchData();
		//         }}
		//         repository={new CheckinRepository()}
		//         id={record.id}
		//       />
		//       <TableInfo record={record} />
		//     </RoleCheck>
		//   ),
		// },
	];

	///////////////////////
	//function

	//get list deapartment from list id
	const getListDepartment = useCallback(
		async (id_list: number[]) => {
			const response = await getListIdFetcher({
				object_type: "department",
				ids: id_list,
			});

			if (!response.hasError()) {
				const result = response.items[0].result!;
				setDepartments(result);
			}
		},
		[getListIdFetcher]
	);
	const fetchData = useCallback(async (): Promise<CheckinCollection> => {
		const response = await new CheckinRepository().getItems({
			filters: filters,
		});
		setTotal(response.total);
		setDataSource(response.items);

		if (!response.hasError()) {
			if (response.toJson().items.length === 0) return response;
			const departmentIds = [
				...new Set(response.toJson().items.map((i) => i.department_id || 0)),
			];
			getListDepartment(departmentIds);
		}

		return response;
	}, [filters, getListDepartment]);

	//get list employee from list id
	// const getListEmployee = useCallback(async (id_list: number[]) => {
	//   const response = await getListIdFetcher({
	//     object_type: "employee",
	//     ids: id_list,
	//   });

	//   if (!response.hasError()) {
	//     const result = response.items[0].result!;
	//     setEmployees(result);

	//     if (result.length > 0) {
	//       const departmentIds = result.map((i) => i.department_id || 0);
	//       getListDepartment(departmentIds);
	//     }
	//   }
	// }, []);

	//get result employee
	// const handleEmployeeResult = useCallback(
	//   (id: number): IdFetcherResult | null => {
	//     const employeeResult =
	//       employees.length > 0
	//         ? employees.filter((i) => i.value === id)[0]
	//         : null;
	//     return employeeResult;
	//   },
	//   [employees]
	// );

	// const onEdit = (id: number) => {
	//   setEditingId(id);
	//   setAddVisible(true);
	// };
	return (
		<div>
			<CheckinListHeader></CheckinListHeader>
			<CheckinListFilter
				total={total}
				filters={filters}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
			/>

			<PageDataTable<FilterCheckin, CheckinModel, CheckinCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "checkin",
				}}></PageDataTable>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</div>
	);
};

export default CheckinList;
