import { IconPoint } from "@tabler/icons-react";
import { Spin } from "antd";
import DataGridTableModel from "common/models/DataGridTableModel";
import DataGridTableRepository from "common/repositories/DataGridTableRepository";
import eventEmitter from "common/utils/eventEmitter";
import ErrorAlert from "components/ErrorAlert";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DataGridTableJson } from "../../../common/types/DataGridTable";
import DataGridTableFormModal from "./form/DataGridTableFormModal";

const DataGridTableListNavigation = ({
  workspaceId,
}: {
  workspaceId: number;
}) => {
  const { t } = useTranslation();
  const eventEmitterRef = useRef<any>();

  //////////////////////////////////////////
  // Table loading
  const [dataSource, setDataSource] = useState<DataGridTableModel[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  //////////////////////////////////////////
  // Table add & editing
  const [addVisible, setAddVisible] = useState(false);

  const fetchTable = useCallback(async () => {
    setLoading(true);
    const collection = await new DataGridTableRepository().getItems({
      filters: {
        ...DataGridTableRepository.getDefaultFilters(),
        datagrid_workspace_id: workspaceId,
      },
    });
    setLoading(false);
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setDataSource(collection.items);
    }
  }, [workspaceId]);

  const onSaveSuccess = (item: DataGridTableModel) => {
    setLoading(true);
  };

  useEffect(() => {
    if (loading && errors.length === 0) {
      fetchTable();
    }
  }, [fetchTable, errors, loading]);

  useEffect(() => {
    //remove current listener first
    if (eventEmitterRef.current) {
      eventEmitterRef.current.remove();
    }

    //we update listerner every datasource change (to allow)
    eventEmitterRef.current = eventEmitter.addListener(
      "CHANGED_DATAGRIDTABLE",
      ({ action, item }: { action: string; item: DataGridTableJson }) => {
        if (action === "edit") {
          setDataSource(
            dataSource.map((i) =>
              i.id !== item.id ? i : new DataGridTableModel(item)
            )
          );
        } else if (action === "delete") {
          setDataSource(dataSource.filter((i) => i.id !== item.id));
        }
      }
    );
  }, [dataSource]);

  useEffect(() => {
    return () => {
      if (eventEmitterRef.current) {
        eventEmitterRef.current.remove();
      }
    };
  }, []);

  return (
    <div className="px-2 mt-4">
      <div className="text-gray-300 text-xs mb-2 pb-2 border-b border-gray-500">
        <span className="float-right">
          <span
            className="text-blue-400 cursor-pointer hover:text-blue-200"
            onClick={() => setAddVisible(true)}
          >
            {t("datagridtable:create_button")}
          </span>
        </span>

        <span
          className="uppercase text-xs cursor-pointer"
          onClick={() => setLoading(true)}
          title={t("datagridtable:refresh_title")}
        >
          {t("datagridtable:listing_heading")}{" "}
          {dataSource.length > 5 ? <>({dataSource.length})</> : null}
        </span>
      </div>

      {loading ? (
        <div className="text-center p-8 spin-darkmode">
          <Spin />
        </div>
      ) : (
        <>
          {errors.length > 0 ? (
            <div className="text-center">
              <ErrorAlert
                items={errors}
                translate_prefix="datagridtable:form.error"
              />
            </div>
          ) : null}

          <div className="">
            {dataSource.length === 0 ? (
              <div className="text-gray-100 mb-4 italic">
                {t("datagridtable:empty")}
              </div>
            ) : (
              <div className="">
                {dataSource.map((table) => (
                  <div key={table.id}>
                    <Link
                      title={table.name}
                      to={
                        "/etable/view/workspace/" +
                        workspaceId +
                        "/id/" +
                        table.id
                      }
                      className="text-gray-50 py-1 my-1 block"
                      style={{
                        width: 180,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <IconPoint className="-mt-0.5" size={16} stroke={1} />{" "}
                      {table.name}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}

      <DataGridTableFormModal
        workspaceId={workspaceId}
        id={0}
        open={addVisible}
        setOpen={(isOpen) => {
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </div>
  );
};

export default DataGridTableListNavigation;
