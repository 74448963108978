import { Col, Row } from "antd";
import Helper from "common/utils/helper";
import TextDateTime from "components/TextDateTime";
import { useEffect, useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import DeliveryDashboardSectionCenterOrderList from "./DeliveryDashboardSectionCenterOrderList";
import DeliveryDashboardSectionCenterPickupList from "./DeliveryDashboardSectionCenterPickupList";
import DeliveryDashboardSectionCenterRouteStatistic from "./DeliveryDashboardSectionCenterRouteStatistic";
import DeliveryDashboardSectionCenterRouteStatus from "./DeliveryDashboardSectionCenterRouteStatus";

import type { DistanceBetweenPointJson } from "common/types/ShippingRoute";
const DeliveryDashboardSectionCenter = () => {
  const { t } = useTranslation();

  const height = useDeliveryDashboardStore((state) => state.height);
  const activeRoute = useDeliveryDashboardStore((state) => state.activeRoute);
  const headerHeight = 50;

  //////////////////////////////////////////
  // stats order
  const [countOrder, setCountOrder] = useState<string>("---");
  const [sumOrderWeight, setSumOrderWeight] = useState<string>("---");
  const [sumDuration, setSumDuration] = useState<string>("---");
  const [sumDurationTitle, setSumDurationTitle] = useState<string>("");
  const [sumDistance, setSumDistance] = useState<string>("---");
  const [distanceBetweenPoint, setDistanceBetweenPoint] = useState<
    DistanceBetweenPointJson[]
  >([]);

  const getStatsInfo = useMemo(() => {
    let stats = activeRoute?.stats;
    if (stats !== undefined) {
      let sumDuration = "---",
        sumDistance = "---",
        countOrder = "---",
        sumDurationTitle = "---",
        sumOrderWeight = "---",
        distanceBetweenPoint: DistanceBetweenPointJson[] = [];

      distanceBetweenPoint = stats.distance_between_point;

      if (stats.sum_distance > 0) {
        sumDistance = stats.sum_distance.toString() + "km";
      }

      if (stats.sum_order_weight > 0) {
        sumOrderWeight =
          Helper.numberFormat(stats.sum_order_weight / 1000) + "kg";
      }

      if (stats.count_order > 0) {
        countOrder =
          Helper.numberFormat(stats.count_order) +
          " " +
          t("deliverydashboard:order_unit");
      }

      if (stats.sum_duration > 0) {
        var totalminutes = stats.sum_duration * 60;
        var hour = Math.floor(totalminutes / 60);
        var minute = Math.floor(totalminutes % 60);
        sumDuration = "";
        if (hour > 0) {
          sumDuration += hour + t("deliverydashboard:duration_hour");
        }

        if (minute > 0) {
          if (sumDuration !== "") {
            sumDuration += " ";
          }
          sumDuration += minute + t("deliverydashboard:duration_minute");
        }

        sumDurationTitle =
          t("deliverydashboard:duration_help") +
          " " +
          stats.speed_km_per_hour +
          "km/h" +
          t("deliverydashboard:duration_help2");
        sumDurationTitle +=
          " (" +
          stats.rest_minute_per_order +
          " " +
          t("deliverydashboard:duration_help_minute_unit") +
          ").";
      }

      return {
        countOrder: countOrder,
        sumOrderWeight: sumOrderWeight,
        sumDuration: sumDuration,
        sumDurationTitle: sumDurationTitle,
        sumDistance: sumDistance,
        distanceBetweenPoint: distanceBetweenPoint,
      };
    }
  }, [t, activeRoute]);

  useEffect(() => {
    if (activeRoute !== undefined) {
      // detect stats
      setCountOrder(getStatsInfo?.countOrder || "");
      setSumOrderWeight(getStatsInfo?.sumOrderWeight || "");
      setSumDuration(getStatsInfo?.sumDuration || "");
      setSumDurationTitle(getStatsInfo?.sumDurationTitle || "");
      setSumDistance(getStatsInfo?.sumDistance || "");
      setDistanceBetweenPoint(getStatsInfo?.distanceBetweenPoint || []);
    }
  }, [activeRoute, getStatsInfo]);
  return (
    <>
      {activeRoute && activeRoute.id > 0 ? (
        <>
          <div
            className="px-2 text-xs text-gray-700 uppercase bg-gray-200 border-l-8 border-gray-500"
            style={{ height: headerHeight, lineHeight: headerHeight + "px" }}
          >
            <Row justify="space-between" align="middle">
              <Col span={12} className="pt-0.5">
                <strong className="pl-2">{activeRoute?.code}</strong>
              </Col>
              <Col span={12}>
                <div className="flex items-end justify-end">
                  <span className="pr-2 font-thin">
                    <TextDateTime ts={activeRoute?.date_created ?? 0} />
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          <Scrollbars
            style={{
              height: height - headerHeight,
              overflow: "hidden",
              borderRight: "1px solid #eee",
            }}
            autoHide
          >
            <div
              style={{
                height: headerHeight,
                lineHeight: headerHeight + "px",
              }}
            >
              {/* Statistic */}
              <DeliveryDashboardSectionCenterRouteStatistic
                countOrder={countOrder}
                sumOrderWeight={sumOrderWeight}
                sumDurationTitle={sumDurationTitle}
                sumDuration={sumDuration}
                sumDistance={sumDistance}
              />

              {/* STATUS */}
              <DeliveryDashboardSectionCenterRouteStatus />

              {/* HUB AND MERCHANTPICKUP */}
              <DeliveryDashboardSectionCenterPickupList />

              {/* ORDER LIST */}
              <DeliveryDashboardSectionCenterOrderList
                distanceBetweenPoint={distanceBetweenPoint}
              />
            </div>
          </Scrollbars>
        </>
      ) : null}
    </>
  );
};

export default DeliveryDashboardSectionCenter;
