import ShipperApply from 'common/constants/ShipperApply';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { ShipperApplyJson } from "common/types/ShipperApply";

class ShipperApplyModel
  extends BaseModel
  implements BaseModelJson<ShipperApplyJson>
{
  company_id: number;
  creator_id: number;
  id: number;
  full_name: string;
  phone: string;
  email: string;
  birthday: number;
  gender: number;
  marriage_status: number;
  address: string;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
  vehicle_type: number;
  introduction: string;
  salary: number;
  note: string;
  file_id_list: string;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: ShipperApplyJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.full_name = json.full_name || "";
    this.email = json.email || "";
    this.birthday = json.birthday || 0;
    this.gender = json.gender || 0;
    this.marriage_status = json.marriage_status || 0;
    this.phone = json.phone || "";
    this.address = json.address || "";
    this.region_id = json.region_id || 0;
    this.sub_region_id = json.sub_region_id || 0;
    this.sub_sub_region_id = json.sub_sub_region_id || 0;
    this.vehicle_type = json.vehicle_type || 0;
    this.introduction = json.introduction || "";
    this.salary = json.salary || 0;
    this.note = json.note || "";
    this.file_id_list = json.file_id_list || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ShipperApplyJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      full_name: "",
      email: "",
      birthday: 0,
      gender: 0,
      marriage_status: 0,
      phone: "",
      address: "",
      region_id: 0,
      sub_region_id: 0,
      sub_sub_region_id: 0,
      vehicle_type: 0,
      introduction: "",
      salary: 0,
      note: "",
      file_id_list: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): ShipperApplyJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      full_name: this.full_name,
      email: this.email,
      birthday: this.birthday,
      gender: this.gender,
      marriage_status: this.marriage_status,
      phone: this.phone,
      address: this.address,
      region_id: this.region_id,
      sub_region_id: this.sub_region_id,
      sub_sub_region_id: this.sub_sub_region_id,
      vehicle_type: this.vehicle_type,
      introduction: this.introduction,
      salary: this.salary,
      note: this.note,
      file_id_list: this.file_id_list,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ShipperApply.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ShipperApply.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getGenderList(): SelectOption[] {
    return [
      {
        value: ShipperApply.GENDER_UNKNOWN,
        label: "--",
      },
      {
        value: ShipperApply.GENDER_FEMALE,
        label: i18n.t("common:gender_female"),
      },
      {
        value: ShipperApply.GENDER_MALE,
        label: i18n.t("common:gender_male"),
      },
    ];
  }

  static getGender(value: number): SelectOption | undefined {
    return this.getGenderList().find((item) => item.value === value);
  }

  static getMarriageStatusList(): SelectOption[] {
    return [
      {
        value: ShipperApply.MARRIAGE_SINGLE,
        label: i18n.t("common:MARRIAGE_SINGLE"),
      },
      {
        value: ShipperApply.MARRIAGE_MARRY,
        label: i18n.t("common:MARRIAGE_MARRY"),
      },
      {
        value: ShipperApply.MARRIAGE_OTHER,
        label: i18n.t("common:MARRIAGE_OTHER"),
      },
    ];
  }

  static getMarriageStatus(value: number): SelectOption | undefined {
    return this.getMarriageStatusList().find((item) => item.value === value);
  }

  static getVehicleTypeList(): SelectOption[] {
    return [
      {
        value: ShipperApply.TYPE_MOTOBIKE,
        label: i18n.t("common:TYPE_MOTOBIKE"),
      },
      {
        value: ShipperApply.TYPE_CAR,
        label: i18n.t("common:TYPE_CAR"),
      },
    ];
  }

  static getVehicleType(value: number): SelectOption | undefined {
    return this.getVehicleTypeList().find((item) => item.value === value);
  }
}

export default ShipperApplyModel;
