import { useCallback, useEffect, useState } from "react";

import {
	Button,
	Checkbox,
	Col,
	Collapse,
	DatePicker,
	Form,
	Input,
	Modal,
	notification,
	Row,
} from "antd";
import LayoutForm from "components/form/LayoutForm";

//////////////////////////////////

import dayjs from "dayjs";
import useDbForSelect from "hooks/useDbForSelect";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import MoneyInput from "components/MoneyInput";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";

import { IconPencil } from "@tabler/icons-react";

import Employee from "common/constants/Employee";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import PositionRepository from "common/repositories/PositionRepository";
import TypeOfWorkRepository from "common/repositories/TypeOfWorkRepository";
import { BankInfoEmployee, EmployeeJsonAddEdit } from "common/types/Employee";
import Helper from "common/utils/helper";
import DepartmentFormSelect from "features/department/DepartmentFormSelect";
import EmployeeEditorHeader from "./EmployeeFormHeader";
import EmployeePasswordModal from "./components/EmployeePasswordModal";
import OfficeFormSelect from "./components/OfficeFormSelect";
import ErrorAlert from "components/ErrorAlert";
import SelectStore from "components/SelectStore";
import RbacRoleUserRepository from "common/repositories/RbacRoleUserRepository";
import { AccountJson } from "common/types/Account";
import { StoreJson } from "common/types/Store";

type Props = {
	model: EmployeeModel;
};

const EmployeeEditor = ({ model }: Props) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const isEditing = model.id > 0;
	const loginAccount = useLoginAccountStore((state) => state.account);
	const [openPermissonStore, setOpenPermissonStore] = useState(false);
	//state
	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	const [officeItems] = useDbForSelect("companyoffice");
	const [showPasswordFields, setShowPasswordFields] = useState(false);

	const [bankList, setBankList] = useState<any>([]);
	const [typeWorks, setTypeWorks] = useState<any>([]);
	const [positions, setPositions] = useState<any>([]);
	const [contractCompleted, setContractCompleted] = useState<boolean>(false);

	const convertArrayToContract = (contract: any) => {
		if (contract !== null) {
			if (model.contract.length === 7) {
				setContractCompleted(true);
			}
			for (let i = 0; i < 8; i++) {
				if (model.contract.includes(i)) {
					switch (i) {
						case 1:
							form.setFieldsValue({ syll: true });
							break;
						case 2:
							form.setFieldsValue({ cmtnd: true });
							break;
						case 3:
							form.setFieldsValue({ picture: true });
							break;
						case 4:
							form.setFieldsValue({ btnn: true });
							break;
						case 5:
							form.setFieldsValue({ gksk: true });
							break;
						case 6:
							form.setFieldsValue({ dxv: true });
							break;
						case 7:
							form.setFieldsValue({ hddk: true });
							break;
					}
				}
			}
		}
	};

	const converContractToArray = (arr: boolean[]) => {
		let ans = [];
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === true) {
				ans.push(i + 1);
			}
		}
		return ans;
	};

	const initialValues = {
		email: model.email,
		full_name: model.full_name,
		gender: model.gender,
		phone: model.phone,
		internal_id: model.internal_id,
		office_id: model.office_id > 0 ? model.office_id : null,
		type: model.type || Employee.TYPE_FULLTIME,
		department_id: model.department_id > 0 ? model.department_id : null,
		position_id: model.position_id || Employee.POSITION_STAFF,
		job_title: model.job_title,
		checkin_type: model.checkin_type || Employee.CHECKIN_TYPE_OFFICE,
		status: model.status || Employee.STATUS_ENABLE,

		address: model.address || "",
		birthday:
			model.birthday > 0 ? dayjs(new Date(model.birthday * 1000)) : null,
		marital_status: model.marital_status > 0 ? model.marital_status : 0,
		bank_number: model.bank_number || "",
		bank_id: model.bank_id > 0 ? model.bank_id : 6,
		relatives_name: model.relatives_name || "",
		relatives_phone: model.relatives_phone || "",
		relationship: model.relationship || "",
		tax: model.tax || "",
		insurance: model.insurance || "",
		date_start:
			model.date_start > 0 ? dayjs(new Date(model.date_start * 1000)) : null,
		personal_email: model.personal_email || "",
		personal_phone: model.personal_phone || "",
		role_id: model.role_id > 0 ? model.role_id : null,
		permanent_address: model.permanent_address || "",
		cccd: model.cccd || "",
		cccd_issue_day:
			model.cccd_issue_day > 0
				? dayjs(new Date(model.cccd_issue_day * 1000))
				: 0,
		cccd_publish: model.cccd_publish || "",
		salary_offer: model.salary_offer || "",
		basic_salary: model.basic_salary || "",
		contract_code: model.contract_code || "",
		contract: model.contract,
		attendance_id: model.attendance_id,
	};

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: EmployeeJsonAddEdit = {
				...EmployeeModel.getDefaultData(),
				id: model.id,
				email: formData.email,
				full_name: formData.full_name,
				password: formData.password,
				gender: formData.gender,
				phone: formData.phone,
				internal_id: formData.internal_id,
				office_id: formData.office_id,
				type: formData.type,
				department_id: formData.department_id,
				position_id: formData.position_id,
				job_title: formData.job_title,
				checkin_type: formData.checkin_type,
				status: formData.status,
				address: formData.address,
				marital_status: formData.marital_status,
				bank_id: formData.bank_id,
				bank_number: formData.bank_number,
				relatives_name: formData.relatives_name,
				relatives_phone: formData.relatives_phone,
				relationship: formData.relationship,
				birthday:
					Helper.datePickerValueToTimestamp(formData.birthday) > 0
						? Helper.datePickerValueToTimestamp(formData.birthday)
						: -1,
				tax: formData.tax,
				insurance: formData.insurance || "",
				date_start:
					Helper.datePickerValueToTimestamp(formData.date_start) > 0
						? Helper.datePickerValueToTimestamp(formData.date_start)
						: -1,
				personal_email: formData.personal_email,
				personal_phone: formData.personal_phone,
				// role_id: formData.role_id,
				role_id: 8,
				permanent_address: formData.permanent_address,
				cccd: formData.cccd,
				cccd_issue_day:
					Helper.datePickerValueToTimestamp(formData.cccd_issue_day) > 0
						? Helper.datePickerValueToTimestamp(formData.cccd_issue_day)
						: -1,
				cccd_publish: formData.cccd_publish,
				salary_offer: formData.salary_offer,
				basic_salary: formData.basic_salary,
				contract_code: formData.contract_code,
				contract: converContractToArray([
					formData.syll,
					formData.cmtnd,
					formData.picture,
					formData.btnn,
					formData.gksk,
					formData.dxv,
					formData.hddk,
				]),
				attendance_id: formData.attendance_id.toString().trim(),
			};

			return submitData;
		},
		[model.id]
	);

	//submit data to server
	const onSubmit = async (formData: any) => {
		doPrepareData(formData);

		let myObj: EmployeeModel = await new EmployeeRepository().saveRemote(
			doPrepareData(formData)
		);
		if (myObj.hasError()) {
			setErrors(myObj.error.errors);
		} else {
			setIsSuccess(true);
		}
	};
	//for edit, show status
	const sidebarItems = (
		<div className="lg:pt-0 pt-5">
			{/* {errors.length > 0 && (
        <ErrorAlert
          items={errors}
          heading={t("common:errors.fetch_api")}
          translate_prefix="employee:form.error"
        ></ErrorAlert>
      )} */}
			<FormSelect
				label={t("employee:checkin_type")}
				name="checkin_type"
				required
				options={EmployeeModel.getCheckinTypeList()}
			/>
			<FormSelect
				label={t("common:status")}
				name="status"
				required
				options={EmployeeModel.getStatusList()}
			/>
			<Form.Item>
				<Button type="primary" size="large" block htmlType="submit">
					Lưu thông tin
				</Button>
			</Form.Item>
		</div>
	);

	const getBankList = async () => {
		const items: BankInfoEmployee[] = await new EmployeeRepository().getBank();
		const data = items.map((item: BankInfoEmployee) => {
			return {
				value: item.id,
				label: item.brand_Name,
			};
		});

		setBankList(data);
		return data;
	};

	const getTypeWorks = async () => {
		const collection: any = await new TypeOfWorkRepository().getItems();
		const data = collection.items.map((item: any) => {
			return {
				value: item.type_work_id,
				label: EmployeeModel.getTypeWorkList(item.type_work_code),
			};
		});

		setTypeWorks(data);
		return data;
	};

	const getPositions = async () => {
		const collection: any = await new PositionRepository().getItems();

		const data = collection.items.map((item: any) => {
			return {
				value: item.position_id,
				label: EmployeeModel.getPositionList(item.position_code),
			};
		});

		setPositions(data);
		return data;
	};

	useEffect(() => {
		getBankList();
		getTypeWorks();
		getPositions();
		convertArrayToContract(model.contract);
	}, []);

	return (
		<>
			<EmployeeEditorHeader isEditing={isEditing} />
			<LayoutForm
				form={form}
				initialValues={initialValues}
				errors={errors}
				isSuccess={isSuccess}
				successTitle={
					isEditing
						? t("employee:form.success.update")
						: t("employee:form.success.add")
				}
				redirectOnSuccess="/employee"
				error_translate_prefix="employee:form.error"
				onSubmit={onSubmit}
				sidebarItems={sidebarItems}
				hideSubmitButton={true}>
				<FormSection
					title={t("employee:form.section_account")}
					subtitle={t("employee:form.section_account_subtitle")}
					className="bg-green-50 rounded-sm p-6 pb-4 -m-6 mb-6 border-b border-gray-100"
					gutter={0}>
					<Row gutter={16} className="pl-1.5">
						<Col md={12} xs={24}>
							<Form.Item
								label={t("employee:internal_id")}
								name="internal_id"
								// tooltip={t("employee:internal_id_help")}
								rules={[
									{
										required: true,
										message: t("employee:form.error.error_internalid_required"),
									},
								]}>
								<Input size="large" />
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item label={t("employee:password")} name="password">
								{isEditing ? (
									<Button
										type="primary"
										size="large"
										danger
										ghost
										icon={<IconPencil size="14" className="mr-2" />}
										onClick={() => setShowPasswordFields(!showPasswordFields)}>
										{t("employee:password_change")}
									</Button>
								) : (
									<Input.Password
										size="large"
										placeholder={t("employee:password_set_placeholder")}
									/>
								)}
							</Form.Item>
						</Col>
					</Row>
				</FormSection>

				<FormSection
					title={t("employee:form.section_contact")}
					subtitle={t("employee:form.section_contact_subtitle")}
					divider>
					<Row gutter={16}>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("common:full_name")}
								name="full_name"
								rules={[
									{
										required: true,
										message: t("common:error.required_full_name"),
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:birthday")}
								name="birthday"
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_birthday_required"),
								//   },
								// ]}
							>
								<DatePicker
									className="w-full"
									placeholder={"dd/mm/yyyy"}
									format={"DD/MM/YYYY"}
								/>
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<FormSelect
								showSearch
								// required
								label={t("common:gender")}
								name="gender"
								options={EmployeeModel.getGenderList()}
								// rules={[
								//   {
								//     required: true,
								//     message: t("common:error.required_gender"),
								//   },
								// ]}
							/>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								// label={t("common:phone")}
								label={t("employee:phone")}
								name="personal_phone"
								rules={[
									{
										validator(_, value: string) {
											if (value.length > 0) {
												if (Helper.validatePhoneNumber(value)) {
													return Promise.resolve();
												} else {
													return Promise.reject(
														new Error(t("common:error.error_phone_format"))
													);
												}
											} else {
												return Promise.resolve();
											}
										},
									},
								]}>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:personal_email")}
								// required
								name="personal_email"
								rules={[
									{
										validator(_, value: string) {
											if (value.length > 0) {
												if (Helper.validateEmail(value)) {
													return Promise.resolve();
												} else {
													return Promise.reject(
														new Error(t("common:error.error_email_format"))
													);
												}
											} else {
												return Promise.resolve();
											}
										},
									},
								]}
								// tooltip={t("employee:form.email_extra")}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:address")}
								name="address"
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_phone_required"),
								//   },
								// ]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:permanent_address")}
								name="permanent_address"
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_phone_required"),
								//   },
								// ]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<FormSelect
								showSearch
								// required
								label={t("employee:marital_status")}
								name="marital_status"
								options={EmployeeModel.getMaritalStatusList()}
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_marital_status"),
								//   },
								// ]}
							/>
						</Col>

						{/* <Col md={8} xs={24}>
            <FormSelect
              showSearch
              // required
              label={t("Loại thanh toán lương")}
              name="paytype"
              options={[
                { label: "Qua thẻ", value: 0 },
                { label: "Tiền mặt", value: 1 },
              ]}
            />
          </Col> */}

						<Col md={8} xs={24}>
							<FormSelect
								showSearch
								// required
								label={t("employee:bank_id")}
								name="bank_id"
								options={bankList}
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_bank_id_required"),
								//   },
								// ]}
							/>
						</Col>

						{/* <Col md={8} xs={24}>
            <Form.Item
              label={t("employee:date_start")}
              name="date_start"
              rules={[
                {
                  required: true,
                  message: t("employee:form.error.error_date_start_required"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={"dd/mm/yyyy"}
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
          </Col> */}

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:bank_number")}
								name="bank_number"
								rules={[
									{
										message: t("employee:form.error.error_number_format"),
										validator: (_, value) => {
											// console.log(typeof value)
											// if (typeof value === "number" && value > 0) {
											if (Number(value) >= 0) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:insurance")}
								name="insurance"
								rules={[
									{
										message: t("employee:form.error.error_number_format"),
										validator: (_, value) => {
											// console.log(typeof value)
											// if (typeof value === "number" && value > 0) {
											if (Number(value) >= 0) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:tax")}
								name="tax"
								rules={[
									{
										message: t("employee:form.error.error_number_format"),
										validator: (_, value) => {
											// console.log(typeof value)
											// if (typeof value === "number" && value > 0) {
											if (Number(value) >= 0) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item label={t("employee:cccd")} name="cccd">
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:cccd_issue_day")}
								name="cccd_issue_day"
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_date_start_required"),
								//   },
								// ]}
							>
								<DatePicker
									className="w-full"
									placeholder={"dd/mm/yyyy"}
									format={"DD/MM/YYYY"}
								/>
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item label={t("employee:cccd_publish")} name="cccd_publish">
								<Input />
							</Form.Item>
						</Col>
					</Row>

					{/* <EmployeeFormExperience isEditing={true} /> */}
				</FormSection>

				<FormSection
					title={t("employee:form.section_more")}
					subtitle={t("employee:form.section_more_subtitle")}
					divider>
					<Row gutter={16}>
						<Col md={8} xs={24}>
							<OfficeFormSelect
								label={t("employee:office")}
								name="office_id"
								placeholder="Chọn văn phòng"
							/>
						</Col>

						<Col md={8} xs={24}>
							<FormSelect
								showSearch
								label={t("employee:type")}
								name="type"
								options={typeWorks}
							/>
						</Col>
						<Col md={8} xs={24}>
							<DepartmentFormSelect
								label={t("employee:department")}
								name="department_id"
								required={true}
							/>
						</Col>

						<Col md={8} xs={24}>
							<FormSelect
								showSearch
								label={t("employee:position")}
								name="position_id"
								options={positions}
							/>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:job_title")}
								name="job_title"
								rules={[
									{
										required: true,
										message: t("employee:form.error.required_job_title"),
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:email")}
								name="email"
								rules={[
									{
										validator(_, value: string) {
											if (value.length > 0) {
												if (Helper.validateEmail(value)) {
													return Promise.resolve();
												} else {
													return Promise.reject(
														new Error(t("common:error.error_email_format"))
													);
												}
											} else {
												return Promise.resolve();
											}
										},
									},
								]}
								// tooltip={t("employee:form.email_extra")}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:work_phone")}
								name="phone"
								rules={[
									{
										validator(_, value: string) {
											if (value.length > 0) {
												if (Helper.validatePhoneNumber(value)) {
													return Promise.resolve();
												} else {
													return Promise.reject(
														new Error(t("common:error.error_phone_format"))
													);
												}
											} else {
												return Promise.resolve();
											}
										},
									},
								]}>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:date_start")}
								name="date_start"
								rules={[
									{
										required: true,
										message: t("employee:form.error.error_date_start_required"),
									},
								]}>
								<DatePicker
									className="w-full"
									placeholder={"dd/mm/yyyy"}
									format={"DD/MM/YYYY"}
								/>
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:contract_code")}
								name="contract_code">
								<Input />
							</Form.Item>
						</Col>

						{/* <RoleCheck roles={[Role.EMPLOYEE_MANAGE]}> */}
						<Col md={8} xs={24}>
							<Form.Item label={t("employee:salary_offer")} name="salary_offer">
								<MoneyInput className="w-full" addonAfter="VND" />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item label={t("employee:basic_salary")} name="basic_salary">
								<MoneyInput className="w-full" addonAfter="VND" />
							</Form.Item>
						</Col>
						{/* </RoleCheck> */}

						{/* <Col md={8} xs={24}>
            {!isEditing ? (
              <>
                <RoleFormSelect
                  label={t("employee:role_id")}
                  name="role_id"
                  placeholder="Chọn nhóm quyền"
                />
              </>
            ) : (
              <></>
            )}
          </Col> */}
						<Col md={8} xs={24}>
							<Form.Item
								label={t("Mã chấm công")}
								name="attendance_id"
								rules={[
									{
										required: false,
										message: t("Mã chấm công bắt buộc"),
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</FormSection>

				<FormSection
					title={t("employee:form.section_contract")}
					subtitle={t("employee:form.section_contract_subtitle")}
					divider>
					<Collapse>
						<Collapse.Panel
							header={
								<p>
									Tình trạng hồ sơ:{" "}
									{/* <span className="text-green-500 font-semibold">Hoàn tất</span> */}
									{contractCompleted ? (
										<span className="text-green-500 font-semibold">
											Hoàn tất
										</span>
									) : (
										<span className="text-red-500 font-semibold">Thiếu</span>
									)}
								</p>
							}
							key="1">
							<Row gutter={16}>
								<Col md={8} xs={24}>
									<Form.Item name="syll" valuePropName="checked">
										<Checkbox>{t("employee:syll")}</Checkbox>
									</Form.Item>
								</Col>

								<Col md={8} xs={24}>
									<Form.Item name="cmtnd" valuePropName="checked">
										<Checkbox>{t("employee:cmtnd")}</Checkbox>
									</Form.Item>
								</Col>

								<Col md={8} xs={24}>
									<Form.Item name="picture" valuePropName="checked">
										<Checkbox>{t("employee:picture")}</Checkbox>
									</Form.Item>
								</Col>

								<Col md={8} xs={24}>
									<Form.Item name="btnn" valuePropName="checked">
										<Checkbox>{t("employee:btnn")}</Checkbox>
									</Form.Item>
								</Col>

								<Col md={8} xs={24}>
									<Form.Item name="gksk" valuePropName="checked">
										<Checkbox>{t("employee:gksk")}</Checkbox>
									</Form.Item>
								</Col>

								<Col md={8} xs={24}>
									<Form.Item name="dxv" valuePropName="checked">
										<Checkbox>{t("employee:dxv")}</Checkbox>
									</Form.Item>
								</Col>

								<Col md={8} xs={24}>
									<Form.Item name="hddk" valuePropName="checked">
										<Checkbox>{t("employee:hddk")}</Checkbox>
									</Form.Item>
								</Col>
							</Row>
						</Collapse.Panel>
					</Collapse>
				</FormSection>

				<FormSection
					title={t("employee:form.section_relative")}
					subtitle={t("employee:form.section_relative_subtitle")}
					divider>
					<Row gutter={16}>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:relatives_name")}
								name="relatives_name"
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_phone_required"),
								//   },
								// ]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:relatives_phone")}
								name="relatives_phone"
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_phone_required"),
								//   },
								// ]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("employee:relationship")}
								name="relationship"
								// rules={[
								//   {
								//     required: true,
								//     message: t("employee:form.error.error_phone_required"),
								//   },
								// ]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</FormSection>
			</LayoutForm>

			<EmployeePasswordModal
				model={model}
				visible={showPasswordFields}
				setVisible={(isVisible) => {
					setShowPasswordFields(isVisible);
				}}
			/>
		</>
	);
};

export default EmployeeEditor;
