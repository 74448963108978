import { Form, Input, Modal } from 'antd';
import ShipperModel from 'common/models/ShipperModel';
import ShipperRepository from 'common/repositories/ShipperRepository';
import { PasswordUpdateJson } from 'common/types/Shipper';
import LayoutForm from 'components/form/LayoutForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ShipperPasswordModal = ({
  model,
  open,
  setOpen,
}: {
  model: ShipperModel;
  open: boolean;
  setOpen: (b: boolean) => any;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = {};

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: PasswordUpdateJson = {
        id: model.id,
        password: formData.password,
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = useCallback(
    async (formData: any) => {
      setErrors([]);
      setIsSuccess(false);

      const data = doPrepareData(formData);

      let requestErrors = await new ShipperRepository().setPassword(data);
      if (requestErrors.length > 0) {
        setErrors(requestErrors);
        setIsSuccess(false);
      } else {
        setIsSuccess(true);

        setTimeout(() => setOpen(false), 200);
      }
    },
    [doPrepareData, setOpen]
  );

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsSuccess(false);
    }
  }, [open, form]);

  return (
    <Modal
      title={t("shipper:password_change_heading")}
      open={open}
      closable={true}
      maskClosable={true}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        form.submit();
      }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      destroyOnClose={true}
    >
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={t("shipper:form.success.change_password")}
        error_translate_prefix="shipper:form.error"
        onSubmit={onSubmit}
        submitText={t("common:form.save")}
        sidebarItems={[]}
        hideSidebar={true}
      >
        <Form.Item
          label={t("shipper:password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("shipper:form.error.error_password_required"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </LayoutForm>
    </Modal>
  );
};

export default ShipperPasswordModal;
