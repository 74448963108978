import { App, Button, Image, Select, Tag, Tooltip, Typography } from "antd";
import WorkTrackingCollection from "common/collections/WorkTrackingCollection";
import Role from "common/constants/Role";
import WorkTracking from "common/constants/WorkTracking";
import FileModel from "common/models/FileModel";
import WorkTrackingModel from "common/models/WorkTrackingModel";
import RbacRoleUserRepository from "common/repositories/RbacRoleUserRepository";
import WorkTrackingRangeRepository from "common/repositories/WorkTrackingRangeRepository";
import WorkTrackingReviewRepository from "common/repositories/WorkTrackingReviewRepository";
import { SelectOption } from "common/types/SelectOption";
import { TableColumnsType } from "common/types/Table";
import {
  FilterWorkTrackingReview,
  WorkTrackingJson,
  WorkTrackingJsonVerify
} from "common/types/WorkTracking";
import { WorkTrackingRangeJson } from "common/types/WorkTrackingRange";
import Error from "components/LayoutError";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableInfoRow from "components/table/TableInfoRow";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import dayjs from "dayjs";
import WorkTrackingApprovalFormModal from "features/worktrackingapproval/form/WorkTrackingApprovalFormModal";
import WorkTrackingApprovalFormViewHistoryModal from "features/worktrackingapproval/form/WorkTrackingApprovalFormViewHistoryModal";
import WorkTrackingApprovalListHeader from "features/worktrackingapproval/list/WorkTrackingApprovalListHeader";
import useDbForSelect from "hooks/useDbForSelect";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
  IconClock,
  IconDiscountCheck,
  IconHistory,
  IconPhoto
} from "@tabler/icons-react";

import WorkTrackingApprovalListFilter from "./WorkTrackingApprovalListFilter";

const WorkTrackingApprovalList = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const account = useLoginAccountStore((state) => state.account);
  const [officeItems] = useDbForSelect("companyoffice");
  const [office, setOffice] = useState<SelectOption>({ value: 0, label: "" });
  const [ranges, setRanges] = useState<WorkTrackingRangeJson[]>([]);
  const [loadingRange, setLoadingRange] = useState(true);

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterWorkTrackingReview = useMemo(
    () => WorkTrackingReviewRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterWorkTrackingReview>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const [approvedOfficeIdList, setApprovedOfficeIdList] = useState<number[]>(
    []
  );
  const loadDataRoleUserDetail = useCallback(async () => {
    const collection = await new RbacRoleUserRepository().getRolesFromUser(
      account.id
    );
    if (!collection.hasError()) {
      let roleUserDetail = collection.toJson();
      let extractRoleSubjectId = roleUserDetail.subjects.find(
        (i) => i.subject_id === Role.CHECKIN_VERIFY
      );
      setApprovedOfficeIdList(
        extractRoleSubjectId ? extractRoleSubjectId.id_list : []
      );
    }
  }, [account.id]);

  //////////////////////////////////////////
  // modal editing
  const [editVisible, setEditVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //////////////////////////////////////////
  // modal view history
  const [viewHistoryVisible, setViewHistoryVisible] = useState(false);
  const [dataSourceWorkTracking, setDataSourceWorkTracking] =
    useState<WorkTrackingJson>(WorkTrackingModel.getDefaultData());

  const fetchRanges = useCallback(async () => {
    const collection = await new WorkTrackingRangeRepository().getItems({
      filters: {
        ...WorkTrackingRangeRepository.getDefaultFilters()
      }
    });
    setLoadingRange(false);

    if (collection.hasError()) {
      message.error({
        content: (
          <Error
            contentPadding={0}
            onClickClose={() => {
              message.destroy("approvalform");
            }}
            heading={t("common:error.form_submit")}
            translate_prefix={"worktracking:form.error"}
            items={collection.error.errors}
          />
        ),
        className: "message_error",
        key: "approvalform",
        duration: 3
      });
    } else {
      setRanges(collection.items.map((item) => item.toJson()));
    }
  }, [setRanges, t, message]);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<WorkTrackingModel[]>([]);
  const fetchData = useCallback(async (): Promise<WorkTrackingCollection> => {
    if (office.value > 0) {
      let collection = await new WorkTrackingReviewRepository().getItems({
        filters: {
          ...filters,
          sortby: "date_created",
          sorttype: "DESC",
          office_id: office.value
        }
      });
      setTotal(collection.total);
      setDataSource(collection.items);

      return collection;
    } else {
      return new WorkTrackingCollection();
    }
  }, [filters, office.value]);

  const getRange = useCallback(
    (record: WorkTrackingModel) => {
      let foundWorkTrackingRange = ranges.find(
        (i) => i.id === record.worktracking_range_id
      );

      let checkInTime = { className: "", tooltip: "" },
        checkOutTime = { className: "", tooltip: "" };

      if (
        foundWorkTrackingRange !== undefined &&
        foundWorkTrackingRange.id === record.worktracking_range_id
      ) {
        let timeIn = parseInt(
          dayjs.unix(record.check_in_time).format("HH:mm").replace(":", "")
        );
        let timeInFinal = parseInt(
          dayjs
            .unix(record.check_in_time_final)
            .format("HH:mm")
            .replace(":", "")
        );
        let rangeTimeIn = parseInt(
          foundWorkTrackingRange.time_start
            ? foundWorkTrackingRange.time_start.replace(":", "")
            : ""
        );

        let timeOut = parseInt(
          dayjs.unix(record.check_out_time).format("HH:mm").replace(":", "")
        );
        let timeOutFinal = parseInt(
          dayjs
            .unix(record.check_out_time_final)
            .format("HH:mm")
            .replace(":", "")
        );

        if (
          dayjs.unix(record.check_out_time).format("DD") !==
          dayjs.unix(record.check_in_time).format("DD")
        ) {
          timeOut += 2400;
        }

        if (
          dayjs.unix(record.check_out_time_final).format("DD") !==
          dayjs.unix(record.check_in_time_final).format("DD")
        ) {
          timeOutFinal += 2400;
        }

        let rangeTimeOut = parseInt(
          foundWorkTrackingRange.time_end
            ? foundWorkTrackingRange.time_end.replace(":", "")
            : ""
        );

        if (timeOut !== rangeTimeOut) {
          let classNameCheckOutTime = "",
            tooltipCheckOutTime = "",
            timeCheckOutTime = "";
          timeCheckOutTime = dayjs.unix(record.check_out_time).format("HH:mm");

          if (record.check_out_time <= 0) {
            timeCheckOutTime = "...";
          }

          if (timeOut < rangeTimeOut) {
            classNameCheckOutTime = "text-red-500";
            tooltipCheckOutTime =
              t("worktracking:checkoutearly") +
              ", " +
              t("worktracking:realcheck") +
              ": " +
              timeCheckOutTime;

            if (timeOut === timeOutFinal) {
              tooltipCheckOutTime =
                t("worktracking:checkoutearly") + ": " + timeCheckOutTime;
            }
          } else if (timeOut > rangeTimeOut) {
            classNameCheckOutTime =
              "underline decoration-dotted decoration-sky";
            tooltipCheckOutTime =
              t("worktracking:checkoutlate") +
              ", " +
              t("worktracking:realcheck") +
              ": " +
              timeCheckOutTime;
            if (timeOut === timeOutFinal) {
              tooltipCheckOutTime =
                t("worktracking:checkoutlate") + ": " + timeCheckOutTime;
            }
          }

          checkOutTime = {
            className: classNameCheckOutTime,
            tooltip: tooltipCheckOutTime
          };
        }

        if (timeIn !== rangeTimeIn) {
          let classNameCheckInTime = "",
            tooltipCheckInTime = "",
            timeCheckInTime = "";

          timeCheckInTime = dayjs.unix(record.check_in_time).format("HH:mm");
          if (timeIn < rangeTimeIn) {
            classNameCheckInTime = "underline decoration-dotted decoration-sky";
            tooltipCheckInTime =
              t("worktracking:checkinearly") +
              ", " +
              t("worktracking:realcheck") +
              ": " +
              timeCheckInTime;
            if (timeIn === timeInFinal) {
              tooltipCheckInTime =
                t("worktracking:checkinearly") + ": " + timeCheckInTime;
            }
          } else {
            if (timeIn > rangeTimeIn) {
              classNameCheckInTime = "text-red-500";
              tooltipCheckInTime =
                t("worktracking:checkinlate") +
                ", " +
                t("worktracking:realcheck") +
                ": " +
                timeCheckInTime;
              if (timeIn === timeInFinal) {
                tooltipCheckInTime =
                  t("worktracking:checkinlate") + ": " + timeCheckInTime;
              }
            }
          }
          checkInTime = {
            className: classNameCheckInTime,
            tooltip: tooltipCheckInTime
          };
        }
      }

      return {
        checkInTime: checkInTime,
        checkOutTime: checkOutTime
      };
    },
    [ranges, t]
  );

  const getLateInRule = (record: WorkTrackingModel) => {
    let content: React.ReactNode = "";
    if (
      record.late_in_rule > WorkTracking.LATE_NOT &&
      record.allow_late === WorkTracking.ALLOW_LATE_ALLOW
    ) {
      content = (
        <Tooltip
          title={t("worktracking:ALLOW_LATE_ALLOW")}
          mouseEnterDelay={0.4}>
          <IconDiscountCheck className="ml-1 -mt-1 text-green-600" size={24} />
        </Tooltip>
      );
    }

    return content;
  };

  const getEarlyInRule = (record: WorkTrackingModel) => {
    let content: React.ReactNode = "";

    if (
      record.late_in_rule > WorkTracking.EARLY_NOT &&
      record.allow_late === WorkTracking.ALLOW_EARLY_ALLOW
    ) {
      content = (
        <Tooltip
          title={t("worktracking:ALLOW_EARLY_ALLOW")}
          mouseEnterDelay={0.4}>
          <IconDiscountCheck className="ml-1 -mt-1 text-green-600" size={24} />
        </Tooltip>
      );
    }

    return content;
  };

  const formatTimeEnd = (time: string | undefined) => {
    let str = "";
    if (time !== undefined) {
      let arrData = time.split(":");
      let hour = parseInt(arrData[0]);
      let newHour = hour;
      let endfix = "";

      if (hour > 23) {
        newHour = hour - 24;
        endfix = " Hôm sau";
      }
      str = ("0" + newHour).slice(-2) + ":" + arrData[1] + endfix;
    }

    return str;
  };

  const showImage = (record: WorkTrackingJson) => {
    let btnImageElement = document.getElementById("btnImage_" + record.id);
    let displayImageElement = document.getElementById(
      "displayImage_" + record.id
    );
    if (btnImageElement !== null && displayImageElement !== null) {
      btnImageElement.classList.add("hidden");
      displayImageElement.classList.remove("hidden");
    }
  };

  useEffect(() => {
    if (loadingRange) {
      fetchRanges();
    }
  }, [loadingRange, fetchRanges]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<WorkTrackingModel> = [
    {
      title: t("worktracking:datecreated"),
      key: "date_created",
      width: 90,
      render: (_: any, record: WorkTrackingModel) => (
        <TextDateTime ts={record.date_created} format={"DD/MM/YYYY"} />
      )
    },
    {
      title: t("worktracking:employee"),
      key: "creator_id",
      width: 120,
      render: (_: any, record: WorkTrackingModel) =>
        record.creator_id && record.creator_id > 0 ? (
          <TextUser id={record.creator_id} />
        ) : (
          "---"
        )
    },
    {
      title: t("worktracking:range"),
      key: "range",
      width: 120,
      render: (_: any, record: WorkTrackingModel) => {
        let foundItem = ranges.find(
          (i) => i.id === record.worktracking_range_id
        );

        return record.worktracking_range_id > 0 && foundItem ? (
          <>
            <div>
              <Tag
                color={foundItem.color}
                style={{ minWidth: 120, color: "black" }}>
                {foundItem?.name}
              </Tag>
              <div className="mt-1 text-xs text-gray-400">
                {foundItem.time_start} - {formatTimeEnd(foundItem.time_end)}
              </div>
            </div>
          </>
        ) : (
          "---"
        );
      }
    },
    {
      title: t("worktracking:timeinout"),
      key: "timeinout",
      width: 180,
      render: (_: any, record: WorkTrackingModel) => {
        const otInfo = WorkTrackingModel.getOT(record.ot_allow);
        return (
          <>
            {record.check_in_time_final > 0 ? (
              <>
                <Tooltip
                  title={getRange(record)?.checkInTime.tooltip}
                  mouseEnterDelay={0.4}>
                  <Typography.Text
                    className={getRange(record)?.checkInTime.className}>
                    <TextDateTime
                      ts={record.check_in_time_final}
                      format="HH:mm"
                    />
                  </Typography.Text>
                </Tooltip>
                {getLateInRule(record)}
              </>
            ) : null}
            {" - "}
            {record.check_out_time_final > 0 ? (
              <>
                <Tooltip
                  title={getRange(record)?.checkOutTime.tooltip}
                  mouseEnterDelay={0.4}>
                  <Typography.Text
                    className={getRange(record)?.checkOutTime.className}>
                    <TextDateTime
                      ts={record.check_out_time_final}
                      format="HH:mm"
                    />
                  </Typography.Text>
                </Tooltip>
                {getEarlyInRule(record)}
              </>
            ) : null}{" "}
            {record.ot_allow > 0 ? (
              <div className="text-xs text-green-500">{otInfo?.label}</div>
            ) : null}
            {record.duration > 0 ? (
              <Tooltip
                title={record.duration_format + record.duration_second_format}
                mouseEnterDelay={0.4}>
                <div className="pt-1 text-xs text-gray-500">
                  <IconClock size={14} className="mr-0.5 -mt-0.5" />{" "}
                  {record.duration_format}
                </div>
              </Tooltip>
            ) : null}
          </>
        );
      }
    },
    {
      title: t("worktracking:ratio"),
      key: "ratio_final",
      width: 70,
      render: (ratio_final) => {
        return (
          <>
            <NumericFormat
              value={ratio_final}
              decimalScale={3}
              fixedDecimalScale={true}
              displayType={"text"}
            />
          </>
        );
      }
    },
    {
      title: t("common:status"),
      key: "status",
      width: 110,
      render: (_: any, record: WorkTrackingModel) => {
        let statusInfo = WorkTrackingModel.getStatus(record.status);
        return (
          <>
            <span style={{ color: statusInfo?.color }}>
              {statusInfo?.label}
            </span>
          </>
        );
      }
    },
    {
      title: t("worktracking:ipaddress"),
      key: "ip_address",
      width: 140,
      render: (_: any, record: WorkTrackingModel) => {
        let previewImage = false;
        if (record.hasOwnProperty("check_in_file_detail")) {
          if (
            record.check_in_file_detail.id > 0 ||
            record.check_out_file_detail.id > 0
          ) {
            previewImage = true;
          }
        }

        let arrImage = [];
        if (record.check_in_file_detail.id > 0) {
          arrImage.push(record.check_in_file_detail);
        }
        if (record.check_out_file_detail.id > 0) {
          arrImage.push(record.check_out_file_detail);
        }

        let checkoutip = "";
        if (record.detail.check_out_ipaddress !== "0.0.0.0") {
          checkoutip = record.detail.check_out_ipaddress;
        }
        let checkinIpAddressBox = record.detail.check_in_ipaddress;
        if (checkoutip !== "" && checkoutip === checkinIpAddressBox) {
          checkinIpAddressBox += " (2)";
        } else {
          checkinIpAddressBox += " - " + checkoutip;
        }
        return (
          <>
            <div className="flex">
              <Typography.Text className="flex items-center">
                {checkinIpAddressBox}
              </Typography.Text>
              {previewImage ? (
                <Button
                  id={"btnImage_" + record.id}
                  onClick={(e) => {
                    e.preventDefault();
                    showImage(record);
                  }}
                  type="link"
                  size="small"
                  style={{ whiteSpace: "break-spaces" }}
                  icon={<IconPhoto size={14} color={"blue"} />}></Button>
              ) : null}
            </div>
            <div id={"displayImage_" + record.id} className="hidden">
              {arrImage.map((item) => (
                <Image
                  key={item.id}
                  style={{ borderRadius: 4 }}
                  src={FileModel.getThumbnailFromUrl(item.file_path)}
                  preview={{
                    src: item.url
                  }}
                />
              ))}
            </div>
          </>
        );
      }
    },
    {
      title: " ",
      key: "verify_status",
      width: 100,
      align: "center",
      render: (_: any, record: WorkTrackingModel) => {
        if (record.check_out_time_final > 0) {
          let className = "bg-green-500 hover:bg-green-400";
          let verifyStatusInfo = WorkTrackingModel.getVerify(
            record.verify_status
          );
          let strVerify = t("worktracking:verify");
          if (verifyStatusInfo?.label === "Verified") {
            strVerify = t("worktracking:unverify");
            className = "bg-yellow-500 hover:bg-yellow-400";
          }
          return (
            <RoleCheck roles={[Role.CHECKIN_VERIFY]} hideOnFail>
              <>
                <div
                  className={
                    "cursor-pointer px-3 py-1 rounded-xl text-white " +
                    className
                  }
                  onClick={() => onSaveVerify(record)}>
                  {strVerify}
                </div>
                {record.reviewer_id > 0 ? (
                  <div className="pt-1 text-xs text-center text-gray-400">
                    <TextUser id={record.reviewer_id} />
                  </div>
                ) : null}
              </>
            </RoleCheck>
          );
        } else {
          return "";
        }
      }
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 120,
      render: (_: any, record: WorkTrackingModel) => (
        <>
          <RoleCheck roles={[Role.CHECKIN_EDIT]} hideOnFail>
            <TableEdit
              disabled={record.status === WorkTracking.STATUS_VERIFIED}
              tooltip={
                record.status === WorkTracking.STATUS_VERIFIED
                  ? t(
                      "worktracking:form.error.error_can_not_edit_after_verified"
                    )
                  : undefined
              }
              link=""
              onClick={() => onEdit(record.id)}
            />
          </RoleCheck>

          <TableEdit
            tooltip={t("worktracking:viewhistory_title")}
            link=""
            icon={<IconHistory size={18} className="-mt-1.5" />}
            onClick={() => onViewHistory(record)}
            label=""
          />

          <TableInfo record={record}>
            {record.detail.check_in_note.length > 0 ? (
              <TableInfoRow
                label={t("worktracking:checkinnote")}
                content={record.detail.check_in_note}
              />
            ) : null}

            {record.detail.check_out_note.length > 0 ? (
              <TableInfoRow
                label={t("worktracking:checkoutnote")}
                content={record.detail.check_out_note}
              />
            ) : null}
          </TableInfo>
        </>
      )
    }
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setEditVisible(true);
  };

  const onViewHistory = (record: WorkTrackingJson) => {
    setDataSourceWorkTracking(record);
    setViewHistoryVisible(true);
  };

  const onSaveVerify = async (record: WorkTrackingModel) => {
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let verify = WorkTracking.VERIFY_STATUS_VERIFIED;
    let unverify = WorkTracking.VERIFY_STATUS_UNVERIFIED;
    let verifyStatus = verify;
    let msg = t("worktracking:verify_success");
    if (record.verify_status === verify) {
      verifyStatus = unverify;
      msg = t("worktracking:unverify_success");
    }

    let formData: WorkTrackingJsonVerify = {
      id: record.id,
      company_id: account.company.id,
      creator_id: account.id,
      check_in_time_final: record.check_in_time_final,
      check_out_time_final: record.check_out_time_final,
      worktracking_range_id: record.worktracking_range_id,
      ot_allow: record.ot_allow,
      status: record.status,
      allow_late: record.allow_late,
      allow_early: record.allow_early,
      office_id: record.office_id,
      verify_status: verifyStatus
    };

    let myObj: WorkTrackingModel =
      await new WorkTrackingReviewRepository().saveVerify(formData);
    if (myObj.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="worktracking:form.error"
            items={myObj.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: msg,
        className: "message_success",
        key: "message",
        duration: 2
      });

      onSaveSuccess(myObj);
    }
  };

  const onSaveSuccess = (item: WorkTrackingModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item
          }
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item]
        })
      );
    }
  };

  useEffect(() => {
    loadDataRoleUserDetail();
  }, [loadDataRoleUserDetail]);

  return (
    <>
      <WorkTrackingApprovalListHeader />

      <WorkTrackingApprovalListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}>
        <>
          {/* <span className="inline-block pt-1 mr-2">
            {t("worktracking:select_office_for_approve")}
          </span> */}
          <Select
            className="mr-4"
            status={office.value > 0 ? "" : "error"}
            options={
              approvedOfficeIdList.length > 0
                ? officeItems.filter((i) =>
                    approvedOfficeIdList.includes(i.value)
                  )
                : officeItems
            }
            style={{ width: 240 }}
            placeholder={t("workschedule:office_placeholder")}
            value={office.value === 0 ? undefined : office.value}
            onChange={(value) => {
              setOffice(
                officeItems.find((i) => i.value === value) ?? {
                  value: 0,
                  label: ""
                }
              );
            }}
          />
        </>
      </WorkTrackingApprovalListFilter>

      <PageDataTable<
        FilterWorkTrackingReview,
        WorkTrackingModel,
        WorkTrackingCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData
        }}></PageDataTable>
      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <WorkTrackingApprovalFormModal
        id={editingId}
        key={editingId}
        open={editVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setEditVisible(isOpen);
        }}
        ranges={ranges}
        onSaveSuccess={onSaveSuccess}
        officeId={office.value}
      />

      <WorkTrackingApprovalFormViewHistoryModal
        dataSourceWorkTracking={dataSourceWorkTracking}
        key={"work-tracking-form-view-history-modal"}
        open={viewHistoryVisible}
        setOpen={(isOpen) => {
          setViewHistoryVisible(isOpen);

          if (!isOpen) {
            setDataSourceWorkTracking(WorkTrackingModel.getDefaultData());
          }
        }}
      />
    </>
  );
};

export default WorkTrackingApprovalList;
