import Helper from "./helper";

class ReportUtil {
  static numberFormatter(v: number | string): string {
    return `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);
  }

  static calculateChange(
    from: number,
    to: number,
    decimalpoint?: number
  ): string {
    var change = "";

    decimalpoint = typeof decimalpoint !== "undefined" ? decimalpoint : 2;

    if (from < to) {
      if (from === 0) {
        change = "∞";
      } else {
        change = Math.abs(
          Helper.round((to / from - 1) * 100, decimalpoint)
        ).toString();
      }
    } else if (from > to) {
      change = Helper.round(
        ((from - to) / from) * 100,
        decimalpoint
      ).toString();
    } else {
      change = "0";
    }

    return change;
  }

  static getColors(isFull?: boolean): string[] {
    let fullColorPalette = [
      "#1890ff",
      "#aec7e8",
      "#ffa500",
      "#ffbb78",
      "#2ca02c",
      "#98df8a",
      "#d62728",
      "#ff9896",
      "#9467bd",
      "#c5b0d5",
      "#8c564b",
      "#c49c94",
      "#e377c2",
      "#f7b6d2",
      "#7f7f7f",
      "#c7c7c7",
      "#bcbd22",
      "#dbdb8d",
      "#17becf",
      "#9edae5",
    ];

    if (typeof isFull === "undefined" || !isFull) {
      return fullColorPalette.filter((c, index) => index % 2 === 0);
    } else {
      return fullColorPalette;
    }
  }
}

export default ReportUtil;
