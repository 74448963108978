import MapServiceAutocompletePredictionModel from "common/models/MapServiceAutoCompletePredictionModel";
import { IconMapPin } from "@tabler/icons-react";

const AddressInputAutocompleteOption = ({
  data,
}: {
  data: MapServiceAutocompletePredictionModel;
}) => {
  return (
    <>
      <div className="float-left" style={{ width: 40, height: 30 }}>
        <IconMapPin size="24" color="#ccc" style={{ margin: 2 }} />{" "}
      </div>
      <div className="" style={{ lineHeight: "28px" }}>
        {data.name}
      </div>
    </>
  );
};

export default AddressInputAutocompleteOption;
