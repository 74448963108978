import { Col, DatePicker, Row, Table } from "antd";
import WorkScheduleModel from "common/models/WorkScheduleModel";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import WorkScheduleRepository from "common/repositories/WorkScheduleRepository";
import { IdFetcherResult } from "common/types/IdFetcher";
import { TableColumnsType } from "common/types/Table";
import ErrorAlert from "components/ErrorAlert";
import dayjs, { Dayjs } from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import WorkScheduleMineHeader from "./WorkScheduleMineHeader";
import WorkScheduleMineItem from "./WorkScheduleMineItem";

const WorkScheduleMine = () => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf("week"));
  const [items, setItems] = useState<WorkScheduleModel[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const [accountId] = useLoginAccountStore((state) => [state.account.id]);

  const [workTrackingRangeFetcher, setWorkTrackingRangeFetcher] = useState<
    IdFetcherResult[] | undefined
  >();
  const fetchingWorkTrackingRange = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "worktrackingrange",
      id_list
    });

    let results =
      collection.items.find((i) => i.object_type === "worktrackingrange")
        ?.result || [];

    results.forEach((result, i) => {
      if (result.text === "[invalid]") {
        results[i].text = "---";
      }
    });

    setWorkTrackingRangeFetcher(results);
  }, []);

  const fetchData = useCallback(async () => {
    if (loadingRef.current === false) {
      setErrors([]);
      setLoading(true);

      const collection = await new WorkScheduleRepository().getMine({
        filters: {
          ...WorkScheduleRepository.getDefaultFilters(),
          user_id: accountId,
          date_schedule_started: startDate.unix(),
          date_schedule_ended: dayjs(startDate).endOf("week").unix()
        }
      });

      if (collection.hasError()) {
        setErrors(collection.error.errors);
      } else {
        setItems(collection.items);

        // call idfetcher
        let workTrackingRangeIdList = collection.items
          .map((item) => item.work_tracking_range_id)
          .filter((i) => i > 0 && i !== undefined);
        workTrackingRangeIdList.length > 0 &&
          fetchingWorkTrackingRange(workTrackingRangeIdList);
      }

      setLoading(false);
    }
  }, [startDate, accountId, fetchingWorkTrackingRange]);

  useEffect(() => {
    fetchData();
  }, [fetchData, startDate]);

  console.log(startDate);

  const getPickerDisplayText = useMemo(() => {
    return t("workschedule:week_range", {
      start: startDate.format("DD/MM"),
      end: dayjs(startDate).endOf("week").format("DD/MM/YYYY")
    });
  }, [startDate, t]);

  const dataSource = useMemo(() => {
    let obj: any = {
      key: accountId
    };

    Array(7)
      .fill(1)
      .forEach((i, index) => {
        const date = startDate.clone().add(index, "d");

        //find schedule on this DATE for this USER
        obj["events-of-" + date.format("D-M")] = items.filter((item) =>
          dayjs.unix(item.date_scheduled).isSame(date, "day")
        );
      });

    return [obj];
  }, [startDate, items, accountId]);

  const getDateColumns = useMemo(() => {
    return Array(7)
      .fill(1)
      .map((i, index) => {
        const date = startDate.clone().add(index, "d");
        return {
          title: (
            <>
              <span className="leading-6 capitalize">
                {date.format("dddd, D/M")}
              </span>
            </>
          ),
          dataIndex: "events-of-" + date.format("D-M"),
          render: (events: WorkScheduleModel[]) => {
            return (
              <>
                <div className="text-center">
                  {events.length > 0 ? (
                    <>
                      {events.map((event) => (
                        <WorkScheduleMineItem
                          model={event}
                          key={event.id}
                          range={workTrackingRangeFetcher?.find(
                            (i) => i.value === event.work_tracking_range_id
                          )}
                        />
                      ))}
                    </>
                  ) : (
                    <span className="text-gray-300">
                      {t("workschedule:mine_empty")}
                    </span>
                  )}
                </div>
              </>
            );
          }
        };
      });
  }, [startDate, t, workTrackingRangeFetcher]);

  const columns: TableColumnsType<any> = [...getDateColumns];

  return (
    <>
      <WorkScheduleMineHeader />

      <div className="p-4">
        {errors.length > 0 ? (
          <ErrorAlert items={errors} translate_prefix="" onRetry={fetchData} />
        ) : null}

        <Row gutter={16} className="mb-4">
          <Col>
            <DatePicker
              changeOnBlur
              size="large"
              allowClear={false}
              value={startDate}
              style={{ width: "100%" }}
              picker="week"
              format={getPickerDisplayText}
              onChange={(value) =>
                setStartDate(
                  value
                    ? (value.startOf("week") as Dayjs)
                    : (dayjs().startOf("week") as Dayjs)
                )
              }
            />
          </Col>
        </Row>

        <Table
          columns={columns}
          bordered
          size="small"
          dataSource={dataSource}
          loading={loading}
          pagination={{
            pageSize: 1000,
            hideOnSinglePage: true
          }}
        />
      </div>
    </>
  );
};

export default WorkScheduleMine;
