import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import PhoneBookDetails from "../components/PhoneBookDetails";

const PhoneBookModal = ({
  id,
  visible,
  setVisible,
}: {
  id: number;
  visible: boolean;
  setVisible: (b: boolean) => any;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("phonebook:modal.title")}
      destroyOnClose
      open={visible}
      closable={true}
      maskClosable={true}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        setVisible(false);
      }}
      cancelText={t("common:close")}
    >
      <PhoneBookDetails id={id} />
    </Modal>
  );
};

export default PhoneBookModal;
