import { Col, Form, Input, Row } from 'antd';
import { FileOrigin } from 'common/types/File';
import FileInput from 'components/form/fileinput/FileInput';
import FormSection from 'components/form/FormSection';
import SerpPreview from 'components/SerpPreview';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormSectionSeo = ({
  sectionTitle,
  sectionSubtitle,
  disablePreview,
  graphImageFileOrigin,
  fileObjectType,
  fileOrigin,
}: {
  sectionTitle?: string;
  sectionSubtitle?: string;
  disablePreview?: boolean;
  graphImageFileOrigin?: string;
  fileObjectType: number;
  fileOrigin: FileOrigin;
}) => {
  const { t } = useTranslation();

  return (
    <FormSection
      title={sectionTitle || t("common:seo.section_info")}
      subtitle={sectionSubtitle || t("common:seo.section_info_help")}
    >
      {typeof disablePreview === "undefined" || !disablePreview ? (
        <Row>
          <Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <div
                  style={{
                    padding: 8,
                    marginBottom: 16,
                    borderRadius: 8,
                    border: "3px solid #eee",
                  }}
                >
                  <SerpPreview
                    title={getFieldValue("seo_title") || "Meta Title"}
                    metaDescription={
                      getFieldValue("seo_description") || "Meta Description"
                    }
                    url={getFieldValue("seo_slug") || "url-slug"}
                    width={600}
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      ) : null}

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item name="seo_slug" label={t("common:seo.url")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item name="seo_title" label={t("common:seo.meta_title")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item name="seo_keyword" label={t("common:seo.meta_keyword")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            name="seo_description"
            label={t("common:seo.meta_description")}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            name="graph_image_input"
            label={t("common:seo.graph_image")}
          >
            <FileInput origin={fileOrigin} objectType={fileObjectType} />
          </Form.Item>
        </Col>
      </Row>
    </FormSection>
  );
};

export default FormSectionSeo;
