import { Col, Form, Row, Select, Tag } from "antd";
import React, { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { RcFile, UploadProps } from "antd/es/upload";
import { useTranslation } from "react-i18next";

import GenerateCanvas from "../components/GenerateCanvas";
import FormSection from "./../../../components/form/FormSection";
import AvatarFrameHeader from "./AvatarFrameHeader";

const AvatarFrame = () => {
	const { t } = useTranslation();
	const [form] = useForm();

	const [outSize, setOutSize] = useState(1080);

	const [frameImage, setFrameImage] = useState<string>("");

	//
	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		});

	const handleChangeBg: UploadProps["onChange"] = ({
		file,
		fileList: newFileList,
	}) => {
		if (file) {
			getBase64(file as RcFile)
				.then((res) => {
					setFrameImage(res);
					// createCanvasResult();
				})
				.catch((err) => {});
		}
	};

	const handleChangeOutSize = (v: any) => {
		setOutSize(v);
	};

	const onFinish = () => {};
	return (
		<div>
			<AvatarFrameHeader />
			<Form form={form} onFinish={onFinish}>
				<Row style={{ height: "100%", minHeight: "780px" }}>
					<Col span={24} style={{ padding: " 16px 16px 0" }}>
						<FormSection
							title={t("tool:avatar_info")}
							subtitle={t("tool:avatar_details")}
							leftColumnSize={6}
							// anotherSubtitle={
							//   disabled && (
							//     <Typography.Text type="danger">
							//       Nhấn 'Làm lại' để tạo mới!
							//     </Typography.Text>
							//   )
							// }
							divider={true}>
							<Row
								gutter={[
									{ xs: 4, md: 32 },
									{ xs: 4, md: 16 },
								]}
								style={{ width: "100%", display: "block" }}>
								<Col span={24} md={8}>
									{/* <img src="./imgs/nteam/bg.png" alt="" /> */}
									<Form.Item
										label={t("tool:avatar_size")}
										labelCol={{ span: 24 }}
										name="out_size">
										<Select
											defaultValue={1080}
											onChange={handleChangeOutSize}
											options={[
												{
													label: <Tag>1080 Pixels</Tag>,
													value: 1080,
												},
												{ label: <Tag>2048 Pixels</Tag>, value: 2048 },
											]}></Select>
									</Form.Item>
									{/* <Form.Item
                      labelCol={{ span: 24 }}
                      name="out_size"
                      label="Kích thước xuất hình"
                    >
                      <Input
                        disabled
                        name="out_size"
                        type="number"
                        defaultValue={1080}
                        min={350}
                        step={50}
                        onChange={(e) => {
                          setOutSize(Number(e.target.value));
                        }}
                        addonAfter={<span>PX</span>}
                      ></Input>
                    </Form.Item> */}
								</Col>
								{/* <Col span={24} md={6}>
                    <Form.Item labelCol={{ span: 24 }} label="Chon Hình">
                      <Upload
                        listType="picture"
                        maxCount={1}
                        accept=".jpg,.png,.jpeg"
                        beforeUpload={() => false}
                        onChange={handleChangeBg}
                      >
                        <Button block icon={<UploadOutlined />} type="primary">
                          Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col> */}
							</Row>
						</FormSection>
						<FormSection
							title={t("tool:avatar")}
							subtitle={t("tool:avatar_generate")}
							leftColumnSize={6}
							// anotherSubtitle={
							//   disabled && (
							//     <Typography.Text type="danger">
							//       Nhấn 'Làm lại' để tạo mới!
							//     </Typography.Text>
							//   )
							// }
							divider={false}>
							<Row gutter={[8, 8]}>
								<Col span={24}>
									<GenerateCanvas out_size={outSize} />
								</Col>
							</Row>
						</FormSection>
					</Col>
					{/* <Col span={4} className="" style={{ background: "#f0f0f0" }}>
              <Button block htmlType="submit" type="primary">
                Tạo Avatar
              </Button>
            </Col> */}
				</Row>
			</Form>
		</div>
	);
};

export default AvatarFrame;
