import ProjectTask from 'common/constants/ProjectTask';
import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

type ProjectTaskLayoutStore = {
  layoutActived: number;
  setLayoutActived: (v: number) => void;
};

//init store data
let store: StateCreator<ProjectTaskLayoutStore> = (set) => ({
  layoutActived: ProjectTask.LAYOUT_LIST,
  setLayoutActived: (layoutActived) =>
    set((state) => ({ ...state, layoutActived })),
});

//create store
const useProjectTaskLayoutStore = create(
  persist(store, { name: "MAIN::ProjectTaskLayout" })
);

export default useProjectTaskLayoutStore;
