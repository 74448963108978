import BaseCollection from "common/collections/BaseCollection";
import DmsNewsCategoryModel from "common/models/DmsNewsCategoryModel";
import { DmsNewsCategoryJson, DmsNewsCategoryCollectionJson } from "common/types/DmsNewsCategory";

class DmsNewsCategoryCollection extends BaseCollection<
  DmsNewsCategoryJson,
  DmsNewsCategoryModel,
  DmsNewsCategoryCollectionJson
> {
  itemsFromJson(jsonItems: DmsNewsCategoryJson[]): DmsNewsCategoryModel[] {
    return jsonItems.map((item) => new DmsNewsCategoryModel(item));
  }
}

export default DmsNewsCategoryCollection;


