import CashflowReceipt from "common/constants/CashflowReceipt";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import FormSelect from "components/form/FormSelect";
import { useEffect, useMemo, useState } from "react";

import CashflowReceiptTargetCustomer from "./CashflowReceiptTargetCustomer";
import CashflowReceiptTargetEmployee from "./CashflowReceiptTargetEmployee";
import CashflowReceiptTargetSupplier from "./CashflowReceiptTargetSupplier";
import { Form, Input, Typography } from "antd";
import CashflowReceiptSearch from "../components/CashflowReceiptSearch";
import { t } from "i18next";
import { Rule } from "antd/es/form";
import SearchComponent from "components/search/SearchLayout";
import {
	CashflowReceiptJson,
	FilterCashflowReceipt,
} from "common/types/CashflowReceipt";
import CashflowReceiptCollection from "common/collections/CashflowReceiptCollection";
import CashflowReceiptRepository from "common/repositories/CashflowReceiptRepository";

const CashflowReceiptTargetSelect = ({
	label,
	model,
	targetId,
	setTargetId,
	disabled,
	placeholder,
	rules,
}: {
	label: string;
	model: CashflowReceiptModel;
	targetId: number;
	setTargetId: (v: number) => void;
	disabled: boolean;
	placeholder?: string;
	rules?: Rule[];
}) => {
	const [target, setTarget] = useState(model.target);

	const detailCom = useMemo(() => {
		let com = null;
		switch (target) {
			case CashflowReceipt.TARGET_EMPLOYEE:
				com = (
					<CashflowReceiptTargetEmployee
						id={targetId}
						setId={setTargetId}
						disabled={disabled}
					/>
				);
				break;
			case CashflowReceipt.TARGET_CUSTOMER:
				com = (
					<CashflowReceiptTargetCustomer
						id={targetId}
						setId={setTargetId}
						disabled={disabled}
					/>
				);
				break;
			case CashflowReceipt.TARGET_SUPPLIER:
				com = (
					<CashflowReceiptTargetSupplier
						id={targetId}
						setId={setTargetId}
						disabled={disabled}
					/>
				);
				break;
			// case CashflowReceipt.TARGET_OTHER:
			//   com = <p>Khác</p>
			//   break;

			default:
				com = (
					// <CashflowReceiptSearch
					// 	label={"Mã (mã phiếu đối xứng ...)"}
					// 	name="target_id"
					// 	disabled={disabled}
					// 	suffixLabel={(item, label) => {
					// 		return (
					// 			<>
					// 				{label?.label} - {item.id}{" "}
					// 			</>
					// 		);
					// 	}}
					// />
					<SearchComponent<
						CashflowReceiptJson,
						FilterCashflowReceipt,
						CashflowReceiptCollection
					>
						fetchRepository={(filers) => {
							return new CashflowReceiptRepository().getItems({
								filters: filers,
							});
						}}
						defaultFilters={CashflowReceiptRepository.getDefaultFilters()}
						formItemProps={{
							label: "Mã (mã phiếu đối xứng ...)",
							name: "target_id",
						}}
						keyValue="id"
						showFilters={["keyword", "id"]}
						defaultUseFilters={["keyword"]}
						renderLabel={(item) => {
							const label = CashflowReceiptModel.getDirection(item.direction);
							return (
								<Typography.Text className={`text-${label?.color}-500`}>
									{label?.label} - {item.id}{" "}
								</Typography.Text>
							);
						}}
					/>
				);
				break;
		}

		return com;
	}, [target, targetId, setTargetId, disabled]);

	useEffect(() => {
		setTarget(model.target);
	}, [model]);

	return (
		<>
			<FormSelect
				placeholder={placeholder}
				required
				rules={rules}
				className="mb-0 w-1/2"
				disabled={disabled}
				label={label}
				name="target"
				options={CashflowReceiptModel.getTargetListByDirection(model.direction)}
				onChange={(value) => {
					if (typeof value !== "undefined") {
						setTarget(+value);
						setTargetId(0);
					}
				}}
			/>
			<div className="pt-2">
				<Form.Item
					name={"target_id"}
					rules={[
						{
							validator(rule, value, callback) {
								return new Promise((resolve, reject) => {
									if (
										value <= 0 &&
										target !== CashflowReceipt.TARGET_OTHER &&
										target > 0
									) {
										reject(new Error("Yêu cầu chọn đối tượng thu - chi"));
									} else {
										resolve(1);
									}
								});
							},
						},
					]}>
					{detailCom}
				</Form.Item>{" "}
			</div>
		</>
	);
};

export default CashflowReceiptTargetSelect;
