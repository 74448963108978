import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import dbm from "dbm";
import React, { useEffect, useState } from "react";

const UserFormFilter = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  defaultOptions,
  placeholder,
  className,
  popupMatchSelectWidth,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  className?: string;
  popupMatchSelectWidth?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>(defaultOptions || []);

  useEffect(() => {
    if (loading && typeof defaultOptions === "undefined") {
      let userItems = dbm.getCollection("employee")?.chain().data();
      if (
        typeof userItems !== "undefined" &&
        userItems.hasOwnProperty("length") &&
        userItems.length > 0
      ) {
        setOptions(
          userItems.map((i) => {
            return {
              value: i.id,
              label: i.full_name,
            };
          }),
        );
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [loading, defaultOptions]);

  return (
    <FormSelect
      className={className}
      disabled={disabled}
      required={required}
      loading={loading}
      showSearch
      label={label}
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      labelAlign={labelAlign}
      allowClear={allowClear}
      placeholder={placeholder}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
};

export default UserFormFilter;
