import { Button, Tag } from "antd";
import CompanyModel from "common/models/CompanyModel";
import InfoContent from "components/InfoContent";
import InfoContentItem from "components/InfoContentItem";
import TextUser from "components/TextUser";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const CompanyInfoGeneral = () => {
  const { t } = useTranslation();

  const account = useLoginAccountStore((state) => state.account);

  return (
    <div className="mx-auto max-w-7xl py-12 sm:px-6 lg:px-8">
      <InfoContent
        title={t("company:heading_info")}
        subTitle={t("company:info.subtitle")}
      >
        <InfoContentItem
          label={t("company:info.id")}
          content={account.company.id}
        />
        <InfoContentItem
          label={t("company:info.name")}
          content={<strong>{account.company.name}</strong>}
        />

        <InfoContentItem
          label={t("company:info.plan")}
          content={
            <>
              <div className="flex">
                <div className="flex-grow">
                  {CompanyModel.getPlan(account.company.plan)?.label}{" "}
                </div>
                <div className="ml-4 flex-shrink-0">
                  <Link to="/company/upgrade" className="">
                    <Button type="primary" ghost>
                      {t("company:plan.upgrade_label")}
                    </Button>
                  </Link>
                </div>
              </div>
            </>
          }
        />
        {/* <InfoContentItem
          label={t("company:info.url")}
          content={new CompanyModel(account.company).getIdentityUrl()}
        /> */}
        <InfoContentItem
          label={t("company:info.owner")}
          content={
            <>
              <TextUser id={account.company.user_id} />{" "}
              {account.company.user_id === account.id ? (
                <Tag color="blue">{t("company:info.owner_is_you")}</Tag>
              ) : null}
            </>
          }
        />
      </InfoContent>
    </div>
  );
};

export default CompanyInfoGeneral;
