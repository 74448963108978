import { Tag } from 'antd';
import Promotion from 'common/constants/Promotion';
import { PromotionCouponCheckResultJson } from 'common/types/PromotionCheck';
import TextMoney from 'components/TextMoney';
import { useTranslation } from 'react-i18next';

const PosRightPromotionCouponItem = ({
  couponCheckResult,
  onRemove,
}: {
  couponCheckResult: PromotionCouponCheckResultJson;
  onRemove: (v: PromotionCouponCheckResultJson) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Tag
      closable={true}
      onClose={() => onRemove(couponCheckResult)}
      color="green"
      className="d-block mg-r-0 mg-t-10"
      style={{ color: "#2C662D", padding: 7, borderStyle: "dashed" }}
    >
      {t("pos:promotion.coupon.label")}:{" "}
      <strong>{couponCheckResult.coupon?.code}</strong> (
      {t("pos:promotion.coupon.label_apply_value")}{" "}
      {couponCheckResult.promotion?.value_type ===
      Promotion.VALUETYPE_PERCENT ? (
        <span>{couponCheckResult.promotion?.value + "%"}</span>
      ) : null}
      {couponCheckResult.promotion?.value_type ===
        Promotion.VALUETYPE_CURRENCY &&
      !Array.isArray(couponCheckResult.promotion?.value) ? (
        <span>
          <TextMoney money={couponCheckResult.promotion?.value} />
        </span>
      ) : null}
      )
    </Tag>
  );
};

export default PosRightPromotionCouponItem;
