import { Tooltip } from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const CashflowReceiptItemSource = ({
  model,
}: {
  model: CashflowReceiptModel;
}) => {
  const sourceLink = useMemo(() => {
    let link = "";
    switch (model.source) {
      case CashflowReceipt.SOURCE_SALE_ORDER:
        link = "/order/edit/id/" + model.source_id;
        break;
      case CashflowReceipt.SOURCE_PURCHASE_ORDER:
        link = "/purchaseorder/edit/id/" + model.source_id;
        break;
    }

    return link;
  }, [model]);

  let sourceInfo = CashflowReceiptModel.getSource(model.source);

  return (
    <>
      {sourceLink.length > 0 ? (
        <Tooltip title={sourceInfo?.label}>
          <Link
            to={sourceLink}
            target="_blank"
            className="text-blue-500"
            title={sourceLink}
          >
            #{model.source_id}
          </Link>
        </Tooltip>
      ) : null}
    </>
  );
};

export default CashflowReceiptItemSource;
