import usePathParams from "hooks/usePathParams";

import BrandList from "features/shophome/brand/list/BrandList";

const ShopBrandHomePage = () => {
	const [pathParams] = usePathParams();
	let com = <></>;

	switch (pathParams.action) {
		default:
			com = <BrandList />;
			break;
	}
	return com;
};

export default ShopBrandHomePage;
