import { BaseModelJson } from 'common/interfaces/BaseModelJson';

import BaseModel from './BaseModel';

import type { OfficeJson } from "common/types/Office";

class OfficeModel extends BaseModel implements BaseModelJson<OfficeJson> {
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  code: string;
  address: string;
  display_order: number;
  date_created: number;
  date_modified: number;
  date_review_checkin: number;
  allow_early_time: number;
  allow_late_time: number;

  constructor(json: OfficeJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.code = json.code || "";
    this.address = json.address || "";
    this.display_order = json.display_order || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_review_checkin = json.date_review_checkin || 0;
    this.allow_early_time = json.allow_early_time || 0;
    this.allow_late_time = json.allow_late_time || 0;
  }

  static getDefaultData(): OfficeJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      code: "",
      address: "",
      display_order: 0,
      date_created: 0,
      date_modified: 0,
      date_review_checkin: 0,
      allow_early_time: 0,
      allow_late_time: 0,
    };
  }

  toJson(): OfficeJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      code: this.code,
      address: this.address,
      display_order: this.display_order,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_review_checkin: this.date_review_checkin,
      allow_early_time: this.allow_early_time,
      allow_late_time: this.allow_late_time,
    };
  }
}

export default OfficeModel;
