import React, { useCallback, useEffect, useMemo, useState } from "react";
import CommentModel from "common/models/CommentModel";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import LayoutForm from "components/form/LayoutForm";
import { useTranslation } from "react-i18next";
import FileModel from "common/models/FileModel";
import FileCollection from "common/collections/FileCollection";
import CommentFormHeader from "../form/CommentFormHeader";
import ProcessMainDetail from "./ProcessMainDetail";
import ProcessListReply from "./ProcessListReply";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import CommentRepository from "common/repositories/CommentRepository";
import { CommentJsonAddEditNew } from "common/types/Comment";
import {
  CheckSquareOutlined,
  EyeTwoTone,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import ClockIcon from "features/academy/icon/ClockIcon";
import CloseIcon from "features/academy/icon/CloseIcon";
import { IconCheck, IconX } from "@tabler/icons-react";
type Props = {
  model: CommentModel;
};
const ProcessDetailComemnt = ({ model }: Props) => {
  //   const countComment = useLoginAccountStore((state) => state.countComment);
  ///////////////////////////////
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const [messageApi, Context] = message.useMessage();
  const loginAccount = useLoginAccountStore((state) => state.account);

  ///////////////////////////////

  const isEditing = model.id > 0;
  ///////////////////////////////
  const [loading, setLoading] = useState<boolean>(false);
  const [openApprove, setOpenApprove] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [modelParent, setModelParent] = useState(
    new CommentModel(CommentModel.getDefaultData())
  );

  //state for form
  const [idPost, setIdPost] = useState<number>(0);
  const [postType, setPostType] = useState<"admin" | "customer">("admin");
  const [isSuccess, setIsSuccess] = useState(false);
  const [reloadListAdmin, setReloadListAdmin] = useState<boolean>(false);
  const [statusModel, setStatusModel] = useState(
    modelParent.id > 0 ? modelParent.status : model.status
  );
  //state for list
  const [idFilterAdmin, setIdFilterAdmin] = useState(0);
  const [idFilterCustomer, setIdFilterCustomer] = useState(0);

  ///////////////////////////////
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      phone: model.phone,
      item_id: model.item_id,
      email: model.email,
      detail: model.detail,
      status: model.status > 0 ? model.status : 1,
      parent_id: model.parent_id,
      images: FileModel.convertToUploadFiles(
        new FileCollection().itemsFromJson(model.images)
      ),
      info_item_id: model.item_id,
      info_item_type: model.item_type,
      info_type: model.type,
      info_rate: model.rate,
      info_count_chill: model.count_chill,
      info_status: model.status,
      info_email: model.email,
      info_phone: model.phone,
      info_name: model.name,
      info_images: model.images,
      info_detail: model.detail,
    };
  }, [
    model.count_chill,
    model.detail,
    model.email,
    model.images,
    model.item_id,
    model.item_type,
    model.name,
    model.parent_id,
    model.phone,
    model.rate,
    model.status,
    model.type,
  ]);

  //////////////////////////////
  //function

  const doPrepareData = useCallback(
    (formData: CommentJsonAddEditNew) => {
      const submitData: CommentJsonAddEditNew = {
        ...formData,
        // detail: formData.detail,
        parent_id: idPost,
        item_id: model.item_id,
        item_type: model.item_type,
        employee_id: loginAccount.company.employee.id,
      };
      return submitData;
    },
    [idPost, loginAccount.company.employee.id, model.item_id, model.item_type]
  );

  const handleCreateNewComment = async (formData: any) => {
    try {
      setIsSuccess(false);
      const resoponse = await new CommentRepository().add(
        doPrepareData(formData)
      );
      if (resoponse.hasError()) {
        setErrors(resoponse.error.errors);
        return;
      }

      setIsSuccess(true);
      setTimeout(() => {
        setReloadListAdmin((prev) => !prev);
        form.resetFields();
        setIdPost(0);
      }, 0);
    } catch (error) {}
  };

  const handleFetchDetail = async () => {
    setLoading(true);
    const response = await new CommentRepository().getItem(model.parent_id);

    if (response.hasError()) {
      setErrors(response.error.errors);
    } else {
      setModelParent(response);
    }
    setLoading(false);
  };

  const handleError = ({ errors }: { errors: string[] }) => {
    errors.length > 0 &&
      messageApi.open({
        content: "Có lỗi thao tác thất bại !",
        type: "error",
      });

    errors.length === 0 &&
      messageApi.open({
        content: "Thao tác thành công  !",
        type: "success",
      });
  };

  const handleApproveItem = useCallback(
    async ({ status }: { status: 1 | 2 }) => {
      setLoading(true);
      const res = await new CommentRepository().edit(
        doPrepareData({
          status: status,
          id: modelParent.id > 0 ? modelParent.id : model.id,
        })
      );
      if (res.hasError()) {
        handleError({ errors: res.error.errors });
      } else {
        modelParent.id > 0
          ? handleFetchDetail()
          : setStatusModel((prev) => (prev === 1 ? 2 : 1));
        handleError({ errors: [] });
      }
      setOpenApprove(false);
      setLoading(false);
    },
    [doPrepareData, model.id, modelParent.id]
  );

  

  //////////////////////////////
  //   useEffect(() => {
  //     if (model.type === 3 && countComment === 0) {
  //       setErrors([]);
  //     }
  //   }, [countComment]);

  useEffect(() => {
    if (model.type === 2) {
      handleFetchDetail();
      if (model.employee_id > 0) {
        // khi comment là của Admin
        setIdFilterAdmin(model.id);
      } else {
        setIdFilterCustomer(model.id);
      }
    }
  }, [model]);

  useEffect(() => {
    if (modelParent.id > 0) {
      setStatusModel(modelParent.status);
    }
  }, [modelParent]);

  //////////////////////////////

  // render Box 1
  const Box1 = useMemo(() => {
    if (model.type === 2) {
      if (modelParent.id > 0) {
        return (
          <Collapse
            activeKey={"1"}
            defaultActiveKey={"1"}
            className="shadow-lg"
          >
            <Panel
              key={"1"}
              header={
                <Row style={{ width: "100%" }} gutter={[16, 16]}>
                  <Col span={24} md={12}>
                    <Typography.Text strong>
                      {t("comment:response_for")}
                      {CommentModel.getType(modelParent.type)[0].label}{" "}
                      <Tag color="blue" className="mx-0 ml-2">
                        #{modelParent.id}
                      </Tag>
                    </Typography.Text>
                    <Tag
                      className="mx-0 ml-2"
                      color={modelParent.status === 2 ? "magenta" : "green"}
                    >
                      {modelParent.status === 2
                        ? t("comment:model.not_approved")
                        : t("comment:model.approved")}
                    </Tag>
                  </Col>
                  <Col
                    className="tooltip_product"
                    span={24}
                    md={12}
                    style={{ textAlign: "right" }}
                  >
                    <div className="flex items-center gap-2 justify-end">
                      <Popconfirm
                        trigger={["hover"]}
                        open={openApprove}
                        title={`Xác nhận ${
                          modelParent.status === 1
                            ? t("comment:form.button.cancel")
                            : t("comment:form.button.approved")
                        }`}
                        onConfirm={() =>
                          handleApproveItem({
                            status: modelParent.status === 1 ? 2 : 1,
                          })
                        }
                        onCancel={() => {
                          setOpenApprove(false);
                        }}
                        okButtonProps={{ loading: loading }}
                      >
                        <Button
                          onClick={() => {
                            setOpenApprove(true);
                          }}
                          size="small"
                          type="primary"
                          htmlType="button"
                          style={{
                            backgroundColor:
                              modelParent.status === 1 ? "#ef4444" : "#22c55e",
                            border:
                              modelParent.status === 1 ? "#ef4444" : "#22c55e",
                          }}
                          className="flex items-center gap-1"
                        >
                          {modelParent.status === 1 ? (
                            <IconX size={16} color="white" />
                          ) : (
                            <IconCheck size={16} color="white" />
                          )}
                          {modelParent.status === 1
                            ? t("comment:form.button.cancel_approved")
                            : t("comment:form.button.approved")}
                        </Button>
                      </Popconfirm>
                      <Button
                        size="small"
                        onClick={() => {
                          window.open(
                            `https://namperfume.net/products/${model.item_detail.data_handle}`,
                            "_blank"
                          );
                        }}
                      >
                        <ShareAltOutlined />
                      </Button>
                      <Tooltip
                        className="ss"
                        style={{ maxWidth: "300px" }}
                        title={
                          <div>
                            <Row style={{ width: "100%" }}>
                              <Col
                                span={10}
                                style={{ fontWeight: 300, color: "#aaa" }}
                              >
                                {t("comment:product.name")} :
                              </Col>
                              <Col span={14}>
                                {model.item_detail.data_title}
                              </Col>
                            </Row>
                            <Row style={{ width: "100%" }}>
                              <Col
                                span={10}
                                style={{ fontWeight: 300, color: "#aaa" }}
                              >
                                {t("comment:product.brand")} :
                              </Col>
                              <Col span={14}>
                                {model.item_detail.data_vendor}
                              </Col>
                            </Row>
                            <Row style={{ width: "100%" }}>
                              <Col
                                span={10}
                                style={{ fontWeight: 300, color: "#aaa" }}
                              >
                                {t("comment:product.sex")} :
                              </Col>
                              <Col span={14}>
                                {model.item_detail.data_gender}
                              </Col>
                            </Row>
                          </div>
                        }
                      >
                        <Button size="small">
                          <EyeTwoTone></EyeTwoTone>
                        </Button>
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              }
            >
              {modelParent.id > 0 && (
                <ProcessMainDetail model={modelParent}></ProcessMainDetail>
              )}
            </Panel>
          </Collapse>
        );
      } else {
        return <Spin spinning={true} />;
      }
    } else {
      return (
        <Collapse
          activeKey={"1"}
          defaultActiveKey={"1"}
          className="p-[-16px] shadow-lg"
        >
          <Panel
            key={"1"}
            header={
              <Row gutter={[16, 16]} style={{ width: "100%" }}>
                <Col span={24} sm={12}>
                  <Typography.Text strong>
                    {t("comment:info_of")}{" "}
                    {CommentModel.getType(model.type)[0].label}
                    {model.employee_id > 0 && " Admin"}
                  </Typography.Text>
                  <Tag
                    style={{ marginLeft: 8 }}
                    color={statusModel === 2 ? "magenta" : "green"}
                  >
                    {statusModel === 2
                      ? t("comment:model.not_approved")
                      : t("comment:model.approved")}
                  </Tag>
                </Col>
                <Col className="tooltip_product" span={24} sm={12}>
                  <div className="flex items-center justify-end gap-2">
                    <Popconfirm
                      trigger={["hover"]}
                      open={openApprove}
                      title={`Xác nhận ${
                        statusModel === 1
                          ? t("comment:form.button.not_approved")
                          : t("comment:form.button.approved")
                      }`}
                      onConfirm={() =>
                        handleApproveItem({
                          status: statusModel === 1 ? 2 : 1,
                        })
                      }
                      onCancel={() => {
                        setOpenApprove(false);
                      }}
                      okButtonProps={{ loading: loading }}
                    >
                      <Button
                        onClick={() => {
                          setOpenApprove(true);
                        }}
                        size="small"
                        type="primary"
                        htmlType="button"
                        style={{
                          backgroundColor:
                            statusModel === 1 ? "#ef4444" : "#22c55e",
                          border: statusModel === 1 ? "#ef4444" : "#22c55e",
                        }}
                        className="flex items-center gap-1"
                      >
                        {statusModel === 1 ? (
                          <IconX size={16} color="white" />
                        ) : (
                          <IconCheck size={16} color="white" />
                        )}
                        {statusModel === 1
                          ? t("comment:form.button.cancel_approved")
                          : t("comment:form.button.approved")}
                      </Button>
                    </Popconfirm>
                    <Button
                      size="small"
                      onClick={() => {
                        window.open(
                          `https://namperfume.net/products/${model.item_detail.data_handle}?themeid=1000885609`,
                          "_blank"
                        );
                      }}
                    >
                      <ShareAltOutlined />
                    </Button>
                    <Tooltip
                      className="ss"
                      style={{ maxWidth: "300px" }}
                      title={
                        <div>
                          <Row style={{ width: "100%" }}>
                            <Col
                              span={10}
                              style={{ fontWeight: 300, color: "#aaa" }}
                            >
                              {t("comment:product.name")} :
                            </Col>
                            <Col span={14}>{model.item_detail.data_title}</Col>
                          </Row>
                          <Row style={{ width: "100%" }}>
                            <Col
                              span={10}
                              style={{ fontWeight: 300, color: "#aaa" }}
                            >
                              {t("comment:product.brand")} :
                            </Col>
                            <Col span={14}>{model.item_detail.data_vendor}</Col>
                          </Row>
                          <Row style={{ width: "100%" }}>
                            <Col
                              span={10}
                              style={{ fontWeight: 300, color: "#aaa" }}
                            >
                              {t("comment:product.sex")} :
                            </Col>
                            <Col span={14}>{model.item_detail.data_gender}</Col>
                          </Row>
                        </div>
                      }
                    >
                      <Button size="small">
                        <EyeTwoTone></EyeTwoTone>
                      </Button>
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            }
          >
            <ProcessMainDetail model={model} />
          </Panel>
        </Collapse>
      );
    }
  }, [
    Panel,
    handleApproveItem,
    loading,
    model,
    modelParent,
    openApprove,
    statusModel,
  ]);
  // render Box 2
  const Box2 = useMemo(() => {
    if (model.type === 2) {
      if (modelParent.id > 0) {
        return (
          <Collapse
            activeKey={"2"}
            defaultActiveKey={"2"}
            className="shadow-lg"
          >
            <Panel
              style={{
                minHeight: "70px",
              }}
              key={"2"}
              header={
                <div className="w-full h-auto">
                  <Row gutter={[16, 16]} style={{ width: "100%", margin: 0 }}>
                    <Col span={24} sm={12}>
                      <Typography.Text strong>
                        {t("comment:admin_response_of")}
                        {CommentModel.getType(modelParent.type)[0].label}{" "}
                        <Tag color="blue">{`#${modelParent.id}`}</Tag>
                      </Typography.Text>
                    </Col>
                    <Col span={24} sm={12} style={{ textAlign: "right" }}>
                      <Button
                        size="small"
                        type="primary"
                        htmlType="button"
                        onClick={() => {
                          setIdPost(modelParent.id);
                          setPostType("admin");
                        }}
                      >
                        <PlusOutlined />
                        {t("comment:form.button.new")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              }
            >
              <ProcessListReply
                parent_id={modelParent.id}
                type_list="admin"
                reload={reloadListAdmin}
                idFilter={idFilterAdmin}
              />
            </Panel>
          </Collapse>
        );
      }
    } else {
      return (
        <Collapse activeKey={"2"} defaultActiveKey={"2"} className="shadow-lg">
          <Panel
            style={{
              minHeight: "70px",
            }}
            key={"2"}
            header={
              <div className="w-full h-auto">
                <Row gutter={[16, 16]} style={{ width: "100%", margin: 0 }}>
                  <Col span={24} sm={12}>
                    <Typography.Text strong>
                      {t("comment:admin_response")}
                    </Typography.Text>
                  </Col>
                  <Col span={24} sm={12} style={{ textAlign: "right" }}>
                    <Button
                      size="small"
                      type="primary"
                      htmlType="button"
                      onClick={() => {
                        setIdPost(model.id);
                        setPostType("admin");
                      }}
                    >
                      <PlusOutlined />
                      {t("comment:form.button.new")}
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          >
            <ProcessListReply
              parent_id={model.id}
              type_list="admin"
              reload={reloadListAdmin}
            ></ProcessListReply>
          </Panel>
        </Collapse>
      );
    }
  }, [
    model.type,
    model.id,
    modelParent.id,
    modelParent.type,
    Panel,
    reloadListAdmin,
    idFilterAdmin,
  ]);
  // render Box 3
  const Box3 = useMemo(() => {
    if (model.type === 2) {
      if (modelParent.id > 0) {
        return (
          <Collapse
            activeKey={"3"}
            // accordion={true}
            defaultActiveKey={"3"}
            className="shadow-lg"
            collapsible="header"
          >
            <Panel
              key={"3"}
              header={
                <div className="w-full h-auto">
                  <Row
                    justify="space-between"
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Col span={24}>
                      <Typography.Text strong>
                        {t("comment:customer_response_of")}
                        {CommentModel.getType(modelParent.type)[0].label}{" "}
                        <Tag color="blue">#{modelParent.id}</Tag>
                      </Typography.Text>
                    </Col>
                  </Row>
                </div>
              }
            >
              <ProcessListReply
                parent_id={modelParent.id}
                type_list="customer"
                reload={false}
                idFilter={idFilterCustomer}
              />
            </Panel>
          </Collapse>
        );
      } else {
        return <Spin spinning={true}></Spin>;
      }
    } else {
      return (
        <Collapse
          activeKey={"3"}
          accordion={true}
          defaultActiveKey={"3"}
          className="shadow-lg"
        >
          <Panel
            key={"3"}
            header={
              <div className="w-full h-auto">
                <Row
                  justify="space-between"
                  style={{ width: "100%", margin: 0 }}
                >
                  <Col span={24}>
                    <Typography.Text strong>
                      {" "}
                      {t("comment:customer_response")}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            }
          >
            <ProcessListReply
              parent_id={model.id}
              type_list="customer"
              reload={false}
            ></ProcessListReply>
          </Panel>
        </Collapse>
      );
    }
  }, [
    model.type,
    model.id,
    modelParent.id,
    modelParent.type,
    Panel,
    idFilterCustomer,
  ]);

  /////////////////////////////
  return (
    <>
      {Context}
      <CommentFormHeader isEditing={isEditing} id={model.id} />
      <Spin spinning={loading}>
        <div className="p-6 collasp_content_comment">
          <Row gutter={[8, 16]}>
            <Col span={24}>{Box1}</Col>
            <Col span={24} md={12}>
              {Box2}
            </Col>
            <Col span={24} md={12}>
              {Box3}
            </Col>
          </Row>
        </div>
      </Spin>
      <Modal
        open={idPost > 0 ? true : false}
        title={`${t("comment:form.object_create")} ${
          postType === "admin" ? "Admin" : "Khách"
        }`}
        // okButtonProps={{ style: { display: "none" } }}
        // cancelButtonProps={{ style: { display: "none" } }}
        okText={t("comment:model.confirm")}
        cancelText={t("comment:model.cancel")}
        onOk={() => {
          form.submit();
        }}
        bodyStyle={{ padding: "16px 16px 0 16px" }}
        // footer={null}
        onCancel={() => {
          setIdPost(0);
        }}
        destroyOnClose={true}
      >
        <LayoutForm
          isSuccess={isSuccess}
          successTitle={t("comment:form.success.create")}
          errors={errors}
          form={form}
          onSubmit={handleCreateNewComment}
          sidebarItems={<></>}
          hideSidebar={true}
          error_translate_prefix="comment:form:error"
        >
          <Form.Item
            name="detail"
            label={t("comment:form.detail")}
            rules={[
              {
                required: true,
                message: t("comment:form.error.content_requid"),
              },
            ]}
          >
            <TextArea
              placeholder={t("comment:form.type_detail")}
              maxLength={800}
              showCount={true}
              rows={6}
            ></TextArea>
          </Form.Item>

          {postType === "customer" && (
            <Form.Item
              name="name"
              label={t("comment:form.name_show")}
              rules={[
                {
                  required: true,
                  message: t("comment:form.error.name_requid"),
                },
              ]}
            >
              <Input placeholder={t("comment:form.type_name")}></Input>
            </Form.Item>
          )}
        </LayoutForm>
      </Modal>
    </>
  );
};

export default ProcessDetailComemnt;
