import WarehouseApi from 'common/api/WarehouseApi';
import WarehouseCollection from 'common/collections/WarehouseCollection';
import WarehouseModel from 'common/models/WarehouseModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  WarehouseJsonAddEdit,
  FilterWarehouse,
} from "common/types/Warehouse";
import WarehouseStoreCollection from 'common/collections/WarehouseStoreCollection';
import WarehouseStoreModel from 'common/models/WarehouseStoreModel';

class WarehouseRepository extends BaseRepository<WarehouseApi> {
  _api: WarehouseApi | null;

  constructor() {
    super();
    this._api = new WarehouseApi(true);
  }

  static getDefaultFilters(): FilterWarehouse {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      id: -1,
      creator_id: -1,
      company_id: -1,
      code: "",
      id_list: "",
    };
  }

  async getFullData(
    companyId: number,
    version: number
  ): Promise<WarehouseCollection> {
    return this._api
      ? await this._api.getFullData({ companyId, version })
      : new WarehouseCollection();
  }

  async getItems(props: GetListProps<FilterWarehouse>) {
    return this._api
      ? await this._api.getList(props)
      : new WarehouseCollection();
  }

  async getItemsStore(props: GetListProps<Partial<FilterWarehouse>>) {
    return this._api
      ? await this._api.getListStore(props)
      : new WarehouseStoreCollection();
  }

  async getItem(id: number): Promise<WarehouseModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new WarehouseModel(WarehouseModel.getDefaultData());
  }
  async getItemWarehouseStore(id: number): Promise<WarehouseStoreModel> {
    return this._api
      ? await this._api.getDetailWarehouseStore(id)
      : new WarehouseStoreModel(WarehouseStoreModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: WarehouseJsonAddEdit): Promise<WarehouseModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new WarehouseModel(WarehouseModel.getDefaultData());
    }
  }
}

export default WarehouseRepository;
