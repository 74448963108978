import SettingModel from "common/models/SettingModel";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import { FilterSetting, SettingAddEditJson } from "common/types/Setting";
import Helper from "common/utils/helper";
import { GetListProps } from "common/types/Filter";
import SettingCollection from "common/collections/SettingCollection";

const SERVICE_URL = "/settings";

class SettingApi extends BaseApi {
	async getList(
		props: GetListProps<FilterSetting>
	): Promise<SettingCollection> {
		let collection = new SettingCollection();
		const convertProps = Helper.convertParams(props.filters);
		const queryData = {
			params: {
				...convertProps,
				ids: props.filters.ids.length > 0 ? props.filters.ids : null,
				keys: props.filters.keys.length > 0 ? props.filters.keys.trim() : null,
				group:
					props.filters.group && props.filters.group.length > 0
						? props.filters.group
						: null,
			},
		};

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
			if (response.hasOwnProperty("data")) {
				collection.fromJson(response.data);
			}
		} catch (error) {
			collection.withError(BaseApi.handleError(error));
		}
		return collection;
	}

	async getDetail(id: string | number, lang: string): Promise<SettingModel> {
		let item = new SettingModel(SettingModel.getDefaultData());

		try {
			const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id, {
				params: {
					lang: lang && lang.length > 0 ? lang : undefined,
				},
			});
			if (response.hasOwnProperty("data")) {
				item = new SettingModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}

	async add(props: SettingAddEditJson): Promise<SettingModel> {
		let item = new SettingModel(SettingModel.getDefaultData());

		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps = Helper.convertParams(dataProps);

		try {
			let postData = {
				...convertProps,
			};

			const response = await AxiosClient().post<any>(SERVICE_URL, postData);
			if (response.hasOwnProperty("data")) {
				item = new SettingModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}
		return item;
	}
	//
	async edit(props: SettingAddEditJson): Promise<SettingModel> {
		let item = new SettingModel(SettingModel.getDefaultData());
		// remove id key
		///////////////
		let dataProps: any = { ...props };
		delete dataProps.id;
		const convertProps: any = Helper.convertParams(dataProps);
		try {
			const postData = {
				...convertProps,
			};

			//For create item
			const response = await AxiosClient().put<any>(
				SERVICE_URL + "/" + props.id,
				postData
			);
			if (response.hasOwnProperty("data")) {
				item = new SettingModel(response.data);
			}
		} catch (error) {
			item.withError(BaseApi.handleError(error));
		}

		return item;
	}
	async delete(ids: number[]): Promise<string[]> {
		let resultErrors = [];
		const params = {
			settings_ids: ids,
		};
		try {
			const response = await AxiosClient().delete(SERVICE_URL, {
				data: params,
			});
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}

	async deleteItem(ids: number): Promise<string[]> {
		let resultErrors = [];
		const params = {
			setting_ids: [ids],
		};
		try {
			const response = await AxiosClient().delete(SERVICE_URL, {
				data: params,
			});
			if (response.status !== 200) {
				resultErrors.push("error_delete_response_not_204");
			}
		} catch (error) {
			resultErrors = BaseApi.handleError(error).errors;
		}

		return resultErrors;
	}
}

export default SettingApi;
