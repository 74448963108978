import { Button, Form, Input, message } from "antd";
import AccountRepository from "common/repositories/AccountRepository";
import Helper from "common/utils/helper";
import Error from "components/LayoutError";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
// import useWorkTrackingOfficeStore from "zustand/useWorkTrackingOfficeStore";

// import UserHostname from "./UserHostname";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import UserHostname from "./UserHostName";
import useProcessStore from "zustands/useProcessStore";

const UserLoginForm = () => {
  const messageKey = "editor";
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { initialPathName } = useLoginAccountStore();
  const setFetchErrors = useProcessStore((state) => state.setFetchErrors);

  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);

  const setAccount = useLoginAccountStore((state) => state.setAccount);
  // const setLastWorkTrackingOffice = useWorkTrackingOfficeStore(
  //   (state) => state.setLastWorkTrackingOffice
  // );

  //show message loading when change processing ON
  useEffect(() => {
    if (processing) {
      message.loading({
        content: t("common:form.processing"),
        key: messageKey,
        duration: 0,
      });
    }
  }, [processing, t]);

  const loginSubmit = useCallback(
    async (internalid: string, password: string, hostname: string) => {
      let repository = new AccountRepository();
      setProcessing(true);
      let account = await repository.login(
        internalid,
        password,
        Helper.getHostname()
      );
      setProcessing(false);

      if (account.id > 0 && account.jwt.length > 0) {
        message.destroy(messageKey);
        setAccount(account.toJson());
        setFetchErrors([]);
        // setLastWorkTrackingOffice(account.company.employee.office_id);
        navigate(
          initialPathName !== (undefined || "/") ? initialPathName : "/"
        );
      } else {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy(messageKey);
              }}
              heading={t("user:form.error.heading_login")}
              translate_prefix="user:form.error"
              items={account.error.errors}
            />
          ),
          className: "message_error",
          key: messageKey,
          duration: 10,
        });
      }
    },
    [setAccount, navigate, t]
  );
  const [img, setImg] = React.useState<string>("");
  const handleChangeImg = (imgBase64: string) => {
    setImg(imgBase64);
  };
  //formsubmit onFinish proxy, wrapping to set processing (to show spinner) and call onSubmit callback from parent
  const onFormSubmit = useCallback(
    (formData: any) => {
      loginSubmit(formData.internalid, formData.password, formData.hostname);
    },
    [loginSubmit]
  );

  return (
    <div>
      <Form
        initialValues={{ hostname: Helper.getHostname() }}
        form={form}
        onFinish={onFormSubmit}
        layout="vertical"
      >
        <Form.Item hidden={true} name="hostname">
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="internalid"
          rules={[
            {
              required: true,
              message: t("Bạn chưa nhập mã nhân viên hoặc Email"),
            },
          ]}
          label={t("Mã nhân viên hoặc Email")}
          className="mg-b-16"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("common:password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("user:form.error.required_password"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" loading={processing} htmlType="submit">
            {t("user:login_btn")}
          </Button>
          <Link to="/user/forgotpass" className="float-right">
            <Button type="link">{t("user:forgot_password")}</Button>
          </Link>
        </Form.Item>
      </Form>
      {/* <DeliveryWebcam
        widthImg={250}
        heightImg={250}
        changeImgSrc={handleChangeImg}
        imgScreenshotSrc={img}
        enableWebcam={true}
      ></DeliveryWebcam> */}
      {/* <DeliveryScannerModal /> */}
    </div>
  );
};

export default UserLoginForm;
