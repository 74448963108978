import ProductVariantApi from 'common/api/ProductVariantApi';
import ProductVariantCollection from 'common/collections/ProductVariantCollection';
import ProductVariantInMemoryCollection from 'common/collections/ProductVariantInMemoryCollection';
import ProductVariantModel from 'common/models/ProductVariantModel';
import { GetListProps } from 'common/types/Filter';
import dbm from 'dbm';
import escapeStringRegexp from 'escape-string-regexp';

import BaseRepository from './BaseRepository';

import type {
  ProductVariantJsonAddEdit,
  FilterProductVariant,
  FilterProductVariantInMemory,
  ProductVariantInMemory,
} from "common/types/ProductVariant";
class ProductVariantRepository extends BaseRepository<ProductVariantApi> {
  _api: ProductVariantApi | null;

  constructor() {
    super();
    this._api = new ProductVariantApi(true);
  }

  static getDefaultFilters(): FilterProductVariant {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      product_id: -1,
    };
  }

  static getDefaultInMemoryFilters(): FilterProductVariantInMemory {
    return {
      ...this.getDefaultFilters(),
      sortby: "product_id",
      category_id: -1,
      product_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterProductVariant>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductVariantCollection();
  }

  async getItem(id: number): Promise<ProductVariantModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductVariantModel(ProductVariantModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductVariantJsonAddEdit
  ): Promise<ProductVariantModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductVariantModel(ProductVariantModel.getDefaultData());
    }
  }

  async getInMemoryItems(props: GetListProps<FilterProductVariantInMemory>) {
    let collection = new ProductVariantInMemoryCollection();

    const limit: number = +props.filters.limit;
    const page: number = +props.filters.page;
    const keyword: string = props.filters.keyword + "";
    const sort_by: string = props.filters.sortby + "";
    const sort_type: string = props.filters.sorttype + "";
    const category_id: number = +props.filters.category_id;
    const product_id: number = +props.filters.product_id;

    let findObject: any = {};
    //search by keyword
    if (keyword.length > 0) {
      findObject.search = {
        $regex: [escapeStringRegexp(keyword), "i"],
      };
    }
    if (category_id > 0) {
      findObject.category_id = category_id;
    }
    if (product_id > 0) {
      findObject.product_id = product_id;
    }

    const total = dbm
      .getCollection("productvariant")
      .chain()
      .find(findObject)
      .count();

    const foundItems: ProductVariantInMemory[] = dbm
      .getCollection("productvariant")
      .chain()
      .find(findObject)
      .simplesort(sort_by, sort_type === "DESC")
      .offset((page - 1) * limit)
      .limit(limit)
      .data();

    collection.fromJson({
      total,
      items: foundItems,
      currentpage: page,
      limit: limit,
    });

    return collection;
  }
}

export default ProductVariantRepository;
