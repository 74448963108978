import { Alert, Button, Tag, Tooltip } from "antd";
import RbacRoleCollection from "common/collections/RbacRoleCollection";
import Role from "common/constants/Role";
import RbacRoleModel from "common/models/RbacRoleModel";
import RbacRoleRepository from "common/repositories/RbacRoleRepository";
import { FilterRbacRole } from "common/types/RbacRole";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import SelectEmployee from "features/employee/SelectEmployee";
import RbacRoleListHeader from "features/rbacrole/list/RbacRoleListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IconPencil, IconUser } from "@tabler/icons-react";

import RbacRoleEditorModal from "../editor/RbacRoleEditorModal";
import RbacRolePermissionEditorModal from "../editor/RbacRolePermissionEditorModal";
import RbacRoleAssignedSubject from "./RbacRoleAssignedSubject";
import RbacRoleListFilter from "./RbacRoleListFilter";

const RbacRoleList = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterRbacRole = useMemo(
    () => RbacRoleRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterRbacRole>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<RbacRoleModel[]>([]);
  const fetchData = useCallback(async (): Promise<RbacRoleCollection> => {
    let collection = await new RbacRoleRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
    return collection;
  }, [filters]);

  //////////////////////////////////////////
  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //////////////////////////////////////////
  // modal editing permissions of Role
  const [editPermissionVisible, setEditPermissionVisible] = useState(false);
  const [editingPermissionModel, setEditingPermissionModel] = useState<
    RbacRoleModel | undefined
  >();

  const handleChangeUser = (values: number[]) => {
    if (values.length > 0) {
      navigate("/role/user/id/" + values[0]);
    }
  };

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<RbacRoleModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(id)}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("role:name"),
      key: "name",
      sortby: "name",
      width: 240,
    },
    {
      title: t("role:assigned_ticket"),
      key: "assigned_subjects",
      render: (assigned_subjects: number[], record: RbacRoleModel) => {
        return (
          <>
            <Tooltip title={t("role:edit_permission")} mouseEnterDelay={0.4}>
              <Tag
                color="blue"
                icon={<IconPencil size={14} />}
                className="cursor-pointer hover:opacity-100 float-right"
                onClick={() => {
                  setEditPermissionVisible(true);
                  setEditingPermissionModel(record);
                }}
              >
                ...
              </Tag>
            </Tooltip>
            <RbacRoleAssignedSubject subjects={assigned_subjects} />
          </>
        );
      },
    },
    {
      title: t("role:assigned_employee"),
      key: "count_user",
      width: 120,
      align: "center",
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: RbacRoleModel) => (
        <RoleCheck roles={[Role.SETTING_HRM]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="role:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new RbacRoleRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: RbacRoleModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $push: [item],
        })
      );
    }
  };

  return (
    <>
      <RbacRoleListHeader>
        <RoleCheck roles={[Role.SETTING_HRM]}>
          <SelectEmployee
            width={240}
            placeholder={t("role:view_one_user")}
            onChange={handleChangeUser}
            icon={<IconUser size="12" />}
          />

          <Button
            className="ml-2"
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("role:add_button")}
          </Button>
        </RoleCheck>
      </RbacRoleListHeader>

      <RbacRoleListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterRbacRole, RbacRoleModel, RbacRoleCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <Alert
        className="m-2 text-xs"
        showIcon
        closable
        type="info"
        message={t("role:list_short_help_info")}
      ></Alert>

      <RbacRoleEditorModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />

      {typeof editingPermissionModel !== "undefined" && (
        <RbacRolePermissionEditorModal
          model={editingPermissionModel}
          open={editPermissionVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingPermissionModel(undefined);
            }
            setEditPermissionVisible(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </>
  );
};

export default RbacRoleList;
