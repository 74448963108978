import { Descriptions, List, Tag } from "antd";
import LeaveHistoryModel from "common/models/LeaveHistoryModel";
import LeaveStepModel from "common/models/LeaveStepModel";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveApproveRepository from "common/repositories/LeaveApproveRepository";
import LeaveStepRepository from "common/repositories/LeaveStepRepository";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";
import { IdFetcherResult } from "common/types/IdFetcher";
import { LeaveHistoryJson } from "common/types/Leave";
import TextDateTime from "components/TextDateTime";
import useIdFetcher from "hooks/useIdFetcher";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import LeaveModel from "common/models/LeaveModel";
import useDatabaseTable from "hooks/useDatabaseTable";
import { EmployeeJson } from "common/types/Employee";

function LeaveApprovalhistoryList({ leave_id }: { leave_id: number }) {
  const [data, setData] = useState<LeaveHistoryJson[]>([]);

  const { t } = useTranslation();
  const [leaveTypes, setLeaveTypes] = useState<LeaveStepModel[]>([]);
  // const [listEmployee, setListEmployee] = useState<IdFetcherResult[]>([]);
  const { getListIdFetcher } = useIdFetcher();

  const [listEmployee] = useDatabaseTable<EmployeeJson>("employee");

  const getListLeaveSteps = async () => {
    const response = await new LeaveStepRepository().getItems();
    if (!response.hasError()) {
      setLeaveTypes(response.items);
    } else {
    }
  };

  const getListApprovalHistory = useCallback(
    async function () {
      const filter = LeaveApproveRepository.getDefaultFilter();
      const response =
        await new LeaveApproveRepository().getItemsApprovalHistory({
          ...filter,
          id: leave_id,
        });
      if (!response.hasError()) {
        setData(response.items);
        // if (response.items.length > 0) {
        //   const list = await getListIdFetcher({
        //     ids: response.items.map((item) => item.employee_approver_id),
        //     object_type: "employee",
        //   });
        //   if (list.items?.[0]?.result) {
        //     setListEmployee(list.items[0].result);
        //   }
        // }
      } else {
        // setErrors(response.error.errors.);
      }
    },
    [ leave_id]
  );
  useEffect(() => {
    getListApprovalHistory();
    getListLeaveSteps();
  }, [getListApprovalHistory]);

  return (
    <List
      itemLayout="vertical"
      grid={{ column: 1 }}
      size="small"
      className="relative"
      dataSource={data}
      renderItem={(item, index) => (
        <List.Item key={index} style={{ marginBlockEnd: 0, padding: 0 }}>
          <Descriptions
            title={t("Chi tiết duyệt của đơn") + " #" + leave_id}
            bordered
            size="small"
          >
            <Descriptions.Item span={24} label="Bước duyệt">
              {
                LeaveModel.getStep(
                  leaveTypes.find((type) => type.steps === item.status)
                    ?.steps || -1
                )?.label
              }
            </Descriptions.Item>
            <Descriptions.Item span={24} label="Người duyệt">
              {listEmployee?.length > 0 &&
                listEmployee?.find((i) => i.id === item.employee_approver_id)?.full_name}
            </Descriptions.Item>
            <Descriptions.Item span={24} label=" Ngày duyệt">
              <TextDateTime ts={item.datemodified} />
            </Descriptions.Item>
            <Descriptions.Item span={24} label="Ghi chú">
              {item.note !== "" ? item.note : <Tag>Trống</Tag>}
            </Descriptions.Item>
          </Descriptions>
        </List.Item>
      )}
    />
  );
}

export default LeaveApprovalhistoryList;
