import { Form, Input } from "antd";
import ShipperModel from "common/models/ShipperModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import ShippingHubFormFilter from "features/shippinghub/ShippingHubFormFilter";
import { useTranslation } from "react-i18next";

const ShipperListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={["shipping_hub_id", "transportation", "status"]}>
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("shipper:filter_keyword_placeholder")}
        />
      </Form.Item>
      <Form.Item name="internal_id">
        <Input allowClear placeholder={t("shipper:internal_id_placeholder")} />
      </Form.Item>
      <ShippingHubFormFilter
        name="shipping_hub_id"
        allowClear
        placeholder={t("shipper:shipping_hub")}
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="transportation"
        options={ShipperModel.getTransportationList()}
        allowClear
        placeholder={t("shipper:transportation_short")}
        popupMatchSelectWidth={false}
      />
      <FormSelect
        name="status"
        options={ShipperModel.getStatusList()}
        allowClear
        placeholder={t("common:status")}
        popupMatchSelectWidth={false}
      />
    </PageDataFilterForm>
  );
};

export default ShipperListFilter;
