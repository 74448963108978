import { Col, Row, Typography } from "antd";
import { Nav } from "common/types/PageNavigation";
import LayoutTitle from "components/LayoutTitle";
import useBreakpoint from "hooks/useBreakpoint";
import produce from "immer";
import React from "react";

import PageBreadcrumb from "./PageBreadcrumb";
import PageSiteMenu, { PageSiteMenuProps } from "./PageSiteMenu";

type Props = PageSiteMenuProps & {
  heading: string;
  children?: React.ReactNode;
  nav?: Nav;
  afterHeading?: React.ReactNode;
};
const PageHeader = ({
  heading,
  children,
  nav,
  siteMenuOpenKey,
  siteMenuSelectedKey,
  siteMenuHidden,
  siteMenuHiddenBackLink,
  siteMenuSelectedParentKey,
  afterHeading,
}: Props) => {
  const [isSmall] = useBreakpoint();

  const headingBox = (
    <Typography.Title level={3} style={{ marginBottom: 0 }}>
      {heading}
    </Typography.Title>
  );

  return (
    <div className={"px-6 pt-4 pb-2 bg-gray-100 relative "}>
      <div className="absolute right-6 top-4">{children}</div>

      <LayoutTitle title={heading} />
      <PageSiteMenu
        siteMenuSelectedKey={siteMenuSelectedKey}
        siteMenuOpenKey={siteMenuOpenKey}
        siteMenuHidden={siteMenuHidden}
        siteMenuHiddenBackLink={siteMenuHiddenBackLink}
        siteMenuSelectedParentKey={siteMenuSelectedParentKey}
      />
      <div>
        <PageBreadcrumb
          nav={
            // isSmall
            //   ? produce(nav, (draft) => {
            //       if (typeof draft !== "undefined" && draft.length > 1) {
            //         delete draft[0];
            //       }
            //     })
            //   :
            nav
          }
        />

        {typeof heading === "string" ? (
          <>
            {typeof afterHeading !== "undefined" ? (
              <Row gutter={0}>
                <Col>{headingBox}</Col>
                <Col>{afterHeading}</Col>
              </Row>
            ) : (
              headingBox
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PageHeader;
