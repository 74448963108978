import React from "react";

type Props = {
  size?: "extraLarge" | "large" | "small";
  color?: "main" | "gray";
};

const ChevronRightIcon = ({ size, color = "main" }: Props) => {
  let icon;
  switch (size) {
    case "extraLarge":
      icon = (
        <svg
          width={42}
          height={42}
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5925 34.86L27.0025 23.45C28.35 22.1025 28.35 19.8975 27.0025 18.55L15.5925 7.14001"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    case "large":
      icon = (
        <svg
          width={18}
          height={18}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.6825 14.94L11.5725 10.05C12.15 9.4725 12.15 8.5275 11.5725 7.95L6.6825 3.06"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    case "small":
      icon = (
        <svg
          width={10}
          height={10}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.19751 11.62L9.00084 7.81667C9.45001 7.36751 9.45001 6.63251 9.00084 6.18334L5.19751 2.38"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width={18}
          height={18}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.6825 14.94L11.5725 10.05C12.15 9.4725 12.15 8.5275 11.5725 7.95L6.6825 3.06"
            stroke={color === "main" ? "#292D32" : "#898889"}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
  return icon;
};

export default ChevronRightIcon;
