import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import type {
  MapServicePlaceJson,
  PlaceLocation,
} from "common/types/MapServicePlace";

class MapServicePlaceModel
  extends BaseModel
  implements BaseModelJson<MapServicePlaceJson>
{
  service: string;
  id: string;
  name: string;
  place_id: string;
  address: string;
  coord: PlaceLocation;
  icon: string;
  types: Array<string>;

  constructor(json: MapServicePlaceJson) {
    super();

    this.service = json.service || "";
    this.id = json.id || "";
    this.name = json.name || "";
    this.place_id = json.place_id || "";
    this.address = json.address || "";
    this.coord = json.coord || { lat: 0, long: 0 };
    this.icon = json.icon || "";
    this.types = json.types || [];
  }

  static getDefaultData(): MapServicePlaceJson {
    return {
      service: "",
      id: "",
      name: "",
      place_id: "",
      address: "",
      coord: { lat: 0, long: 0 },
      icon: "",
      types: [],
    };
  }

  toJson(): MapServicePlaceJson {
    return {
      service: this.service,
      id: this.id,
      name: this.name,
      place_id: this.place_id,
      address: this.address,
      coord: this.coord,
      icon: this.icon,
      types: this.types,
    };
  }
}

export default MapServicePlaceModel;
