const Project = {
  STATUS_ENABLE: 1,
  STATUS_WORKING: 3,
  STATUS_COMPLETED: 5,
  STATUS_DISABLE: 7,

  TYPE_NORMAL: 0,
  TYPE_SCRUM: 1,
};

export default Project;
