import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EcomListHeader = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader heading={t("ecom:heading_list")} siteMenuSelectedKey="/ecom">
      {children}
    </PageHeader>
  );
};

export default EcomListHeader;
