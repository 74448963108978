// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_skeletonWrapper__ZySUD .styles_ant-skeleton__ZYdz3 {
  width: 100% !important;
  min-height: 300px !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademySkeleton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,4BAA4B;AAC9B","sourcesContent":[".skeletonWrapper .ant-skeleton {\n  width: 100% !important;\n  min-height: 300px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeletonWrapper": `styles_skeletonWrapper__ZySUD`,
	"ant-skeleton": `styles_ant-skeleton__ZYdz3`
};
export default ___CSS_LOADER_EXPORT___;
