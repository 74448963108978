import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const DealthomConfigHeader = ({ children }: { children: React.ReactNode }) => {
	const { t } = useTranslation();

	return (
		<PageHeader
			heading={t("Cài đặt thông tin hiển thị Deal Thơm")}
			siteMenuSelectedKey="/dealthomsetting"
			siteMenuOpenKey="dealthom">
			{children}
		</PageHeader>
	);
};

export default DealthomConfigHeader;
