import {
  CheckSquareOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { IconTextCaption, IconTrash } from "@tabler/icons";
import {
  Alert,
  Badge,
  Button,
  Col,
  List,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import CommentModel from "common/models/CommentModel";
import CommentRepository from "common/repositories/CommentRepository";
import { FilterComment } from "common/types/Comment";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProcessCommentItem from "./ProcessCommentItem";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import EmployeeModel from "common/models/EmployeeModel";

type Props = {
  parent_id: number;
  type_list?: "admin" | "customer";
  reload: boolean;
  idFilter?: number;
};
const ProcessListReply = ({
  parent_id,
  reload,
  type_list = "admin",
  idFilter,
}: Props) => {
  // const setCountComment = useLoginAccountStore(
  //   (state) => state.setCountComment
  // );
  const defaultFilters: FilterComment = useMemo(
    () => CommentRepository.getDefaultFilter(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterComment>({
    ...defaultFilters,
    status: -1,
    // id: idFilter && idFilter > 0 ? idFilter : undefined,
  });
  // useFilterLocation(defaultFilters, filters);

  const [loading, setLoading] = useState(false);
  const [totals, setTotals] = useState(0);

  const [is_refetch, setIs_refetch] = useState(false);
  ////////////////////////////
  const [listData, setListData] = useState<CommentModel[]>([]);
  const [employees, setEmployees] = useState<EmployeeModel[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  ////////////////////////////
  const fetchListCommentChild = useCallback(async () => {
    try {
      setLoading(true);
      const respones = await new CommentRepository().getItems({
        filters:
          type_list === "admin"
            ? { ...filters, parent_id: parent_id, is_user: 2 }
            : { ...filters, parent_id: parent_id, is_user: 3 },
      });
      if (respones.hasError()) {
        setErrors(respones.error.errors);
      } else {
        setListData(respones.items);
        setTotals(respones.total);
        if (type_list === "admin") {
          handleFetchEmp(respones.items.map((i) => i.employee_id));
        }
      }
      setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [is_refetch, reload, parent_id, filters, type_list]);

  ////////////////////////////
  // const doPrepareData = useCallback(
  //   (formData: any) => {
  //     const submitData = {
  //       ...model.toJson(),
  //       detail: formData.detail,
  //     };
  //     return submitData;
  //   },
  //   [model.id]
  // );
  const handleFetchEmp = async (ids: number[]) => {
    const res = await new EmployeeRepository().getIdsItems({
      object_type: "employee",
      ids: ids,
    });
    if (res.hasError()) {
    } else {
      setEmployees(res.items);
    }
  };

  const handleEditSuccess = () => {
    setIs_refetch((prev) => !prev);
  };
  ////////////////////////////

  useEffect(() => {
    fetchListCommentChild();
  }, [parent_id, filters, reload, is_refetch]);

  // useEffect(() => {
  //   setCountComment(listData.length);
  // }, [listData]);

  // useEffect(() => {
  //   if (idFilter && idFilter > 0) {
  //     setFilters({ ...filters, id: idFilter });
  //   }
  // }, [idFilter]);
  ////////////////////////////
  return (
    <div className="process_list_wrapper mx-[-16px] p-[16px] max-h-[50vh] min-h-[50vh] overflow-y-auto overflow-x-hidden">
      {errors.length > 0 ? (
        <Alert type="error" message="Có lỗi rồi !" />
      ) : (
        <>
          <div className="flex items-center justify-between mt-[-16px]">
            <Typography.Text strong className="mb-0 block">
              Danh sách phản hồi
            </Typography.Text>
            <Select
              placeholder="Chọn trạng thái"
              dropdownStyle={{ border: "none" }}
              className="m-0 w-[110px]"
              options={CommentModel.getStatus()}
              defaultValue={-1}
              onChange={(e) => {
                setFilters({ ...filters, status: e, id: undefined, page: 1 });
              }}
            />
          </div>
          <List
            dataSource={listData}
            itemLayout="vertical"
            loading={loading}
            footer={
              <div className="flex items-center justify-between mt-4">
                {listData.length > 0 && (
                  <Typography.Text>Tất cả ({totals})</Typography.Text>
                )}
                <Pagination
                  className="text-right"
                  current={+filters.page}
                  pageSize={+filters.limit}
                  total={totals}
                  hideOnSinglePage={true}
                  showQuickJumper={false}
                  onChange={(newpage, pageSize) =>
                    setFilters({ ...filters, page: newpage, limit: pageSize })
                  }
                  pageSizeOptions={["10", "20", "50"]}
                  showSizeChanger={true}
                />
              </div>
            }
            renderItem={(item: CommentModel, index) => {
              if (idFilter && item.id === idFilter) {
                return (
                  <Badge.Ribbon text="Bạn đang sửa comment này " color="cyan">
                    <div style={{ paddingTop: "12px" }}>
                      <ProcessCommentItem
                        className={idFilter ? "process_item_active" : ""}
                        key={index}
                        modelItem={item}
                        handleEditSuccess={handleEditSuccess}
                        handleDelete={() => {}}
                        handleEdit={() => {}}
                        employees={employees}
                      />
                    </div>
                  </Badge.Ribbon>
                  // <Tooltip
                  //   title="Bạn đang chỉnh sửa comment này !"
                  //   color="volcano"
                  //   placement="topRight"
                  //   defaultVisible={true}
                  // >
                  //   <ProcessCommentItem
                  //     className={idFilter ? "process_item_active" : ""}
                  //     key={index}
                  //     modelItem={item}
                  //     handleEditSuccess={handleEditSuccess}
                  //     handleDelete={() => {}}
                  //     handleEdit={() => {}}
                  //     employees={employees}
                  //   />
                  // </Tooltip>
                );
              }
              return (
                <ProcessCommentItem
                  className={idFilter ? "process_item_active" : ""}
                  key={index}
                  modelItem={item}
                  handleEditSuccess={handleEditSuccess}
                  handleDelete={() => {}}
                  handleEdit={() => {}}
                  employees={employees}
                />
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default ProcessListReply;
