import DmsPostCollection from "common/collections/DmsPostCollection";
import DmsPost from "common/constants/DmsPost";
import Role from "common/constants/Role";
import DmsPostModel from "common/models/DmsPostModel";
import DmsPostRepository from "common/repositories/DmsPostRepository";
import { DmsPostJson, FilterDmsPost } from "common/types/DmsPost";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import DmsPostListHeader from "features/dmspost/list/DmsPostListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DmsPostListFilter from "./DmsPostListFilter";

const DmsPostList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterDmsPost = useMemo(
    () => DmsPostRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterDmsPost>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<DmsPostModel[]>([]);
  const fetchData = useCallback(async (): Promise<DmsPostCollection> => {
    let collection = await new DmsPostRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);
    return collection;
  }, [filters]);

  //Table columns
  const columns: TableColumnsType<DmsPostModel> = [
    {
      title: t("dmspost:filter_type"),
      key: "type",
      width: 120,
      render: (_: any, record: DmsPostModel) => {
        let typeInfo = DmsPostModel.getType(record.type);
        return <span>{typeInfo?.label}</span>;
      },
    },
    {
      title: t("dmspost:identifier"),
      key: "identifier",
    },
    {
      title: t("dmspost:title"),
      key: "title",
    },
    {
      title: t("dmspost:slug"),
      key: "seo_slug",
    },
    {
      title: t("dmspost:display_order"),
      key: "display_order",
      width: 120,
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 120,
      render: (ts: number) => <TextDateTime ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_: any, record: DmsPostJson) => (
        <RoleCheck roles={[Role.COMPANY_SETTING]} hideOnFail>
          <TableEdit link={"/dmspost/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="dmspost:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new DmsPostRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <DmsPostListHeader />

      <DmsPostListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterDmsPost, DmsPostModel, DmsPostCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          rowClassName: (record) =>
            record.status === DmsPost.STATUS_ENABLED
              ? ""
              : "table-row-stripes opacity-70",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default DmsPostList;
