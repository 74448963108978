import { PosPrintMoreData } from 'common/types/PosCart';
import { PosOrder } from 'common/types/PosOrder';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import PosSubmitPrintPaper from './PosSubmitPrintPaper';

const PosSubmitPrinter = ({
  printTemplate,
  order,
  onAfterPrintCallback,
  printMoreData,
}: {
  printTemplate: string;
  order: PosOrder;
  onAfterPrintCallback: () => void;
  printMoreData: PosPrintMoreData;
}) => {
  const componentPrintRef = useRef(null);

  const handlePrint = useReactToPrint({
    copyStyles: false,
    pageStyle: "@media print { body { color: black; } }",
    removeAfterPrint: true, //prevent bug on set copyStyles to false
    suppressErrors: true, //prevent bug on set copyStyles to false
    content: () => componentPrintRef.current,
    documentTitle: "Don Hang " + order.id,
    onAfterPrint: () => onAfterPrintCallback(),
  });

  const [printing, setPrinting] = useState(true);

  //wait for
  useLayoutEffect(() => {
    if (printing) {
      setPrinting(false);
      if (typeof handlePrint !== "undefined") {
        handlePrint();
      }
    }
  }, [printing, handlePrint]);

  return (
    <div>
      <div style={{ display: "none" }} key={order.id}>
        <PosSubmitPrintPaper
          reprint={false}
          printTemplate={printTemplate}
          printMoreData={printMoreData}
          order={order}
          ref={componentPrintRef}
        />
      </div>
    </div>
  );
};

export default PosSubmitPrinter;
