import { Col, Divider, Row, Typography } from "antd";
import React from "react";

const FormSection = ({
  children,
  title,
  subtitle,
  anotherSubtitle,
  divider,
  leftColumnSize,
  className,
  gutter,
  suffix,
}: {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  anotherSubtitle?: React.ReactNode;
  divider?: boolean;
  leftColumnSize?: number;
  className?: string;
  gutter?: number;
  suffix?: React.ReactNode;
}) => {
  const leftSize =
    typeof leftColumnSize !== "undefined" && leftColumnSize < 24
      ? leftColumnSize
      : 8;
  const rightSize = 24 - leftSize;

  return (
    <>
      <Row
        className={className}
        gutter={typeof gutter !== "undefined" ? gutter : [16, 16]}
      >
        {leftSize > 0 ? (
          <Col lg={leftSize} xs={24} className="d-none d-lg-block">
            <Typography.Title level={5}>{title}</Typography.Title>
            <Typography.Text>{subtitle}</Typography.Text>
            {anotherSubtitle && (
              <Typography.Text className="block" type="secondary">
                {anotherSubtitle}
              </Typography.Text>
            )}
            {suffix}
          </Col>
        ) : null}

        <Col lg={rightSize} xs={24}>
          {children}
        </Col>
      </Row>

      {typeof divider == "boolean" && divider ? <Divider /> : null}
    </>
  );
};

export default FormSection;
