import BaseCollection from "common/collections/BaseCollection";
import DataGridWorkspaceModel from "common/models/DataGridWorkspaceModel";
import {
  DataGridWorkspaceJson,
  DataGridWorkspaceCollectionJson,
} from "common/types/DataGridWorkspace";

class DataGridWorkspaceCollection extends BaseCollection<
  DataGridWorkspaceJson,
  DataGridWorkspaceModel,
  DataGridWorkspaceCollectionJson
> {
  itemsFromJson(jsonItems: DataGridWorkspaceJson[]): DataGridWorkspaceModel[] {
    return jsonItems.map((item) => new DataGridWorkspaceModel(item));
  }
}

export default DataGridWorkspaceCollection;
