import BaseCollection from 'common/collections/BaseCollection';
import DmsPostModel from 'common/models/DmsPostModel';
import { DmsPostCollectionJson, DmsPostJson } from 'common/types/DmsPost';

class DmsPostCollection extends BaseCollection<
  DmsPostJson,
  DmsPostModel,
  DmsPostCollectionJson
> {
  itemsFromJson(jsonItems: DmsPostJson[]): DmsPostModel[] {
    return jsonItems.map((item) => new DmsPostModel(item));
  }
}

export default DmsPostCollection;
