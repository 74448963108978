import BaseCollection from "common/collections/BaseCollection";
import DetailProductCollectionModel from "common/models/DetailProductCollectionModel";
import { DetailProductCollectionCollectionJson } from "common/types/ProductCollection";
import { DetailProductCollectionItem } from "common/types/ProductCollection";

class DetailProductCollectionCollection extends BaseCollection<
	DetailProductCollectionItem,
	DetailProductCollectionModel,
	DetailProductCollectionCollectionJson
> {
	itemsFromJson(
		jsonItems: DetailProductCollectionItem[]
	): DetailProductCollectionModel[] {
		return jsonItems.map((item) => new DetailProductCollectionModel(item));
	}
}

export default DetailProductCollectionCollection;
