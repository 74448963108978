import { Skeleton, Typography } from "antd";
import TagModel from "common/models/TagModel";

import TagRepository from "common/repositories/TagRepository";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingForm from "./SettingForm";
import SettingModel from "common/models/SettingModel";
import SettingRepository from "common/repositories/SettingRepository";
import { SettingGroupType } from "common/types/Setting";

const SettingFormModal = ({
	id,
	type,
	lang,
	open,
	setOpen,
	onSaveSuccess,
	is_created,
	createdKey,
}: {
	id: number;
	open: boolean;
	type: SettingGroupType;
	lang: string;
	setOpen: (v: boolean) => any;
	onSaveSuccess: (m: SettingModel) => void;
	is_created: boolean;
	createdKey: string;
}) => {
	const { t } = useTranslation();

	const [model, setModel] = useState<SettingModel>(
		new SettingModel(SettingModel.getDefaultData())
	);
	const [errors, setErrors] = useState<string[]>([]);
	const [processing, setProcessing] = useState(false);

	const fetchDetail = async (id: number) => {
		let myObj = await new SettingRepository().getItem(id, lang);
		if (myObj.hasError()) {
			setErrors(myObj.error.errors);
		} else {
			setModel(myObj);
		}
	};

	useEffect(() => {
		if (id > 0 && errors.length === 0) {
			fetchDetail(id);
		} else if (is_created) {
			setModel(
				new SettingModel({ ...SettingModel.getDefaultData(), key: createdKey })
			);
		}
	}, [id, errors, is_created, lang]);

	return (
		<FormModal
			title={id > 0 ? t("tagtype:heading_edit") : t("tagtype:heading_add")}
			width={480}
			open={open}
			setOpen={setOpen}
			confirmLoading={processing}
			formId="shopsetting-form">
			{is_created && (
				<Typography.Text className="text-red-500">
					Key không tồn tại, bạn có thể tạo key mới với tên này.
				</Typography.Text>
			)}
			{id > 0 ? (
				<>
					{model.id > 0 || errors.length > 0 ? (
						<>
							{errors.length > 0 ? (
								<ErrorAlert
									items={errors}
									heading={t("tag:error.error_fetching_data")}
									onRetry={() => {
										setErrors([]);
										fetchDetail(id);
									}}
								/>
							) : (
								<SettingForm
									lang={lang}
									type={type}
									model={model}
									setOpen={setOpen}
									setProcessing={setProcessing}
									onSaveSuccess={onSaveSuccess}
								/>
							)}
						</>
					) : (
						<>
							<Skeleton active />
						</>
					)}
				</>
			) : is_created ? (
				<>
					<SettingForm
						lang={lang}
						type={type}
						model={model}
						setOpen={setOpen}
						setProcessing={setProcessing}
						onSaveSuccess={onSaveSuccess}
					/>
				</>
			) : (
				<SettingForm
					lang={lang}
					type={type}
					model={new SettingModel(SettingModel.getDefaultData())}
					setOpen={setOpen}
					setProcessing={setProcessing}
					onSaveSuccess={onSaveSuccess}
				/>
			)}
		</FormModal>
	);
};

export default SettingFormModal;
