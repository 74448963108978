import TagTypeCollection from "common/collections/TagTypeCollection";
import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import { GetListProps } from "common/types/Filter";
import { FilterTagType, TagTypeAddEditJson } from "common/types/TagType";

import Helper from "common/utils/helper";
import TagTypeModel from "common/models/TagTypeModel";

const SERVICE_URL = "/tags";

class TagTypeApi extends BaseApi {
  async getList(
    props: GetListProps<FilterTagType>
  ): Promise<TagTypeCollection> {
    let collection = new TagTypeCollection();
    const convertProps = Helper.convertParams(props.filters);
    const queryData = {
      params: {
        ...convertProps,
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/type",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async getDetail(id: number): Promise<TagTypeModel> {
    let item = new TagTypeModel(
      TagTypeModel.getDefaultData()
    );

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/type/" + id
      );
      if (response.hasOwnProperty("data")) {
        item = new TagTypeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  async add(
    props: TagTypeAddEditJson
  ): Promise<TagTypeModel> {
    let item = new TagTypeModel(
      TagTypeModel.getDefaultData()
    );

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(
        SERVICE_URL + "/type",
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagTypeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(
    props: TagTypeAddEditJson
  ): Promise<TagTypeModel> {
    let item = new TagTypeModel(
      TagTypeModel.getDefaultData()
    );
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/type/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new TagTypeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/type/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default TagTypeApi;
