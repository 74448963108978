import LeaveTypeCollection from "common/collections/LeaveTypeCollection";
import LeaveTypeModel from "common/models/LeaveTypeModel";
import {
  FilterLeaveType,
  LeaveTypeAddEditJson,
  LeaveTypeJson,
} from "common/types/LeaveType";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";
import { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";

const SERVICE_URL = "/leavetypes";

class LeaveTypeApi extends BaseApi {
  async getList(): Promise<LeaveTypeModel[]> {
    let items = [];
    try {
      const response = await AxiosClient().get<any>(SERVICE_URL);
      if (response.hasOwnProperty("data")) {
        items = response.data;
      }
    } catch (error) {
      items.push({ error: error });
    }

    return items;
  }

  async getListLeaveType(
    props: GetListProps<FilterLeaveType>
  ): Promise<LeaveTypeCollection> {
    let collection = new LeaveTypeCollection();

    let filters = props.filters;
    try {
      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby ? filters.sortby : null,
          sort_type: filters.sorttype ? filters.sorttype : null,
          status: filters.status ? filters.status : null,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/getlisttype",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 201) {
        resultErrors.push("error_delete_response_not_201");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: LeaveTypeAddEditJson): Promise<LeaveTypeModel> {
    let item = new LeaveTypeModel(LeaveTypeModel.getDefaultData());
    let dataProps: any = { ...data };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new LeaveTypeModel(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: LeaveTypeAddEditJson): Promise<LeaveTypeModel> {
    let item = new LeaveTypeModel(LeaveTypeModel.getDefaultData());

    let dataProps: any = { ...data };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new LeaveTypeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async getDetail(id: number): Promise<LeaveTypeModel> {
    let item = new LeaveTypeModel(LeaveTypeModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new LeaveTypeModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default LeaveTypeApi;
