import {
	Alert,
	App,
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	Modal,
	notification,
	Row,
	Typography,
} from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import Role from "common/constants/Role";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import CashflowReceiptRepository from "common/repositories/CashflowReceiptRepository";
import OrderHelperRepository from "common/repositories/OrderHelperRepository";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import { StoreJson } from "common/types/Store";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import MoneyInput from "components/MoneyInput";
import TextStore from "components/TextStore";
import dayjs from "dayjs";
import CashflowReceiptFormHeader from "features/cashflowreceipt/form/CashflowReceiptFormHeader";
import StoreFormSelect from "features/store/StoreFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import CashflowReceiptTargetSelect from "../target/CashflowReceiptTargetSelect";
import CashflowReceiptFormStatus from "./CashflowReceiptFormStatus";

import {
	CashflowReceiptJson,
	CashflowreceiptsMethod,
	CashflowreceiptsStatus,
	CashflowreceiptsType,
	type CashflowReceiptJsonAddEdit,
} from "common/types/CashflowReceipt";
import { useNavigate } from "react-router-dom";
import StoreRepository from "common/repositories/StoreRepository";
import StoreCollection from "common/collections/StoreCollection";
const CashflowReceiptForm = ({
	model,
	need_link_sale_order_id,
	need_link_purchase_order_id,
	disable,
	refetch,
	setModel,
	hideFormSubmit,
	onlyView,
}: {
	model: CashflowReceiptModel;
	need_link_sale_order_id?: number;
	need_link_purchase_order_id?: number;
	disable?: boolean;
	refetch?: () => void;
	setModel?: (model: CashflowReceiptModel) => void;
	hideFormSubmit?: boolean;
	onlyView?: boolean;
}) => {
	console.log("🚀 ~ need_link_sale_order_id:", need_link_sale_order_id);
	const { t } = useTranslation();
	const { message } = App.useApp();
	const [form] = Form.useForm();

	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);
	const [modal, contextHolder] = Modal.useModal();

	const [status, setStatus] = useState(model.status);

	const navigate = useNavigate();
	//check can edit update input info (add/edit features)
	const allowEditInput = useMemo(() => {
		if (disable) return false;

		return (
			(model.id === 0 || hasRoleAndLimit(Role.CASHFLOW_EDIT, model.store_id)) &&
			model.status < CashflowReceipt.STATUS_APPROVED
		);
	}, [disable, model.id, model.store_id, model.status, hasRoleAndLimit]);

	const isDisableSubmit =
		model.status === CashflowreceiptsStatus.CANCEL ||
		model.status === CashflowreceiptsStatus.SUCCSECC;

	const isEditing = model.id > 0;
	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [targetId, setTargetId] = useState(model.target_id);

	const [storeItemsWithLimit, setStoreItemsWithLimit] = useState<StoreJson[]>(
		[]
	);

	const fetchData = useCallback(async (): Promise<StoreCollection> => {
		let collection = await new StoreRepository().getItems({
			filters: StoreRepository.getDefaultFilters(),
		});
		setStoreItemsWithLimit(collection.toJson().items);
		return collection;
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const initialValues = useMemo(
		() => ({
			identifier: model.identifier,
			store_id: model.store_id || null,
			cashflow_group: model.cashflow_group,
			target: model.target || null,
			name: model.name,
			note: model.note,
			method: model.method || null,
			value: model.value,
			source_id: need_link_sale_order_id || model.source_id,
			source: model.source,
			status: model.status,
			direction: model.direction,
			id: model.id,
			target_id: model.target_id,
		}),
		[model]
	);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: CashflowReceiptJsonAddEdit = {
				...CashflowReceiptModel.getDefaultData(),
				store_id: formData.store_id,
				identifier: model.identifier,
				status,
				cashflow_group: formData.cashflow_group,
				id: model.id,
				direction: model.direction,
				source: model.source,
				source_id: need_link_sale_order_id || model.source_id,
				target: formData.target,
				target_id: targetId,
				name: formData.name,
				note: formData.note,
				method: formData.method,
				value: formData.value,
			};

			return submitData;
		},
		[model, status, targetId]
	);

	const doLinkingSaleOrderAfterAdd = useCallback(
		async (receipt_id: number) => {
			if (typeof need_link_sale_order_id === "number") {
				message.loading({
					content: t("cashflowreceipt:form.linking_with_sale_order", {
						id: need_link_sale_order_id,
					}),
					key: "message_linking",
				});

				const error: string[] =
					await new OrderHelperRepository().linkCashflowReceipt(
						need_link_sale_order_id,
						receipt_id.toString()
					);

				//detect error on Linking
				if (error.length > 0) {
					message.error({ content: error, key: "message_linking" });
				} else {
					message.destroy("message_linking");
				}

				//show success on Product receipt
				setIsSuccess(true);
			}
		},
		[need_link_sale_order_id, t, message]
	);

	const doLinkingPurchaseOrderAfterAdd = useCallback(
		async (receipt_id: number) => {
			if (typeof need_link_purchase_order_id === "number") {
				message.loading({
					content: t("cashflowreceipt:form.linking_with_purchase_order", {
						id: need_link_purchase_order_id,
					}),
					key: "message_linking",
				});

				const error: string[] =
					await new PurchaseOrderHelperRepository().linkCashflowReceipt(
						need_link_purchase_order_id,
						receipt_id.toString()
					);

				//detect error on Linking
				if (error.length > 0) {
					message.error({ content: error, key: "message_linking" });
				} else {
					message.destroy("message_linking");
				}

				//show success on Product receipt
				setIsSuccess(true);
			}
		},
		[need_link_purchase_order_id, t, message]
	);

	// async function handleCancelCashflow() {
	//   let body: CashflowReceiptJsonAddEdit = {
	//     store_id: model.store_id,
	//     identifier: model.identifier,
	//     status: CashflowreceiptsStatus.DRAFT,
	//     cashflow_group: model.cashflow_group,
	//     id: 0,
	//     direction: model.direction,
	//     source: model.source,
	//     source_id: model.source_id,
	//     target: 7,
	//     target_id: model.id,
	//     name: model.name,
	//     note: model.note,
	//     method: model.method,
	//     value: model.value,
	//   }

	//   if (model.direction === CashflowreceiptsType.PAYMENT) {
	//     body = { ...body, direction: CashflowreceiptsType.RECEIPT }
	//   }

	//   if (model.direction === CashflowreceiptsType.RECEIPT) {
	//     body = { ...body, direction: CashflowreceiptsType.PAYMENT }
	//   }

	//   let myObj: CashflowReceiptModel = await new CashflowReceiptRepository().saveRemote(
	//     body
	//   );

	//   if (!myObj.hasError()) {
	//     notification.success({ message: "thành công" })

	//   } else {
	//     notification.success({ message: "thất bại" })
	//   }

	// }

	function handleCreate() {
		let body: CashflowReceiptJson = {
			...CashflowReceiptModel.getDefaultData(),

			store_id: model.store_id,
			identifier: model.identifier,
			status: CashflowreceiptsStatus.DRAFT,
			cashflow_group: model.cashflow_group,
			id: 0,
			direction: model.direction,
			source: model.source,
			source_id: model.source_id,
			target: 7,
			target_id: model.id,
			name: model.name,
			note: model.note,
			method: model.method,
			value: model.value,
		};

		if (model.direction === CashflowreceiptsType.PAYMENT) {
			body = { ...body, direction: CashflowreceiptsType.RECEIPT };
		}

		if (model.direction === CashflowreceiptsType.RECEIPT) {
			body = { ...body, direction: CashflowreceiptsType.PAYMENT };
		}

		setModel?.(new CashflowReceiptModel(body));
		navigate(`/cashflowreceipt/add/direction/${body.direction}`);
	}

	//submit data to server
	const onSubmit = useCallback(
		async (formData: any) => {
			// if (model.status === CashflowreceiptsStatus.SUCCSECC && status === CashflowreceiptsStatus.CANCEL) {
			//   // mai viet tiep
			//   modal.confirm({
			//     title: 'Phiếu thu chi đã hoàn thành không thể hủy bạn có muốn tạo phiếu đối xứng ?',

			//     okText: "Xác nhận",
			//     cancelText: "Đóng",
			//     onOk: handleCancelCashflow
			//   });
			//   return
			// }

			setErrors([]);

			let myObj: CashflowReceiptModel =
				await new CashflowReceiptRepository().saveRemote(
					doPrepareData(formData)
				);
			if (myObj.hasError()) {
				setErrors(myObj.error.errors);
			} else {
				//do linking with sale order
				if (typeof need_link_sale_order_id === "number") {
					doLinkingSaleOrderAfterAdd(myObj.id);
				} else if (typeof need_link_purchase_order_id === "number") {
					//do linking with purchase order
					doLinkingPurchaseOrderAfterAdd(myObj.id);
				} else {
					//trigget new status to update EDIT FORM LOGIC (status)
					setStatus(myObj.status);
					refetch?.();
					setIsSuccess(true);
				}
			}
		},
		[
			doPrepareData,
			need_link_sale_order_id,
			need_link_purchase_order_id,
			doLinkingSaleOrderAfterAdd,
			doLinkingPurchaseOrderAfterAdd,
			refetch,
		]
	);

	//set default store if only one store
	useEffect(() => {
		if (storeItemsWithLimit.length === 1 && initialValues.store_id === null) {
			form.setFieldValue("store_id", storeItemsWithLimit[0].id);
		}
	}, [storeItemsWithLimit, initialValues.store_id, form]);

	useEffect(() => {
		setStatus(model.status);
		setTargetId(model.target_id);
		form.setFieldValue("target", model.target);
	}, [form, model]);

	let sidebarItems = (
		<>
			{model.status === CashflowreceiptsStatus.SUCCSECC && !onlyView && (
				<Button onClick={handleCreate} className=" mb-4" type="primary">
					Tạo phiếu đối xứng
				</Button>
			)}
			{isEditing ? (
				<div className="pb-4 mb-4 border-b border-gray-300">
					{(model.direction === CashflowReceipt.DIRECTION_INCOME
						? t("cashflowreceipt:income_short")
						: t("cashflowreceipt:expense_short")) +
						" " +
						t("cashflowreceipt:store")}
					: <br />
					<span className="text-lg font-bold">
						<TextStore id={model.store_id} />
					</span>
				</div>
			) : (
				<StoreFormSelect
					size="large"
					required
					disabled={model.id > 0}
					label={
						(model.direction === CashflowReceipt.DIRECTION_INCOME
							? t("cashflowreceipt:income_short")
							: t("cashflowreceipt:expense_short")) +
						" " +
						t("cashflowreceipt:store")
					}
					name="store_id"
					defaultOptions={storeItemsWithLimit.map((i) => ({
						value: i.id,
						label: i.name,
					}))}
				/>
			)}

			<Form.Item label={t("cashflowreceipt:note")} name="note">
				<Input.TextArea rows={2} disabled={!allowEditInput || disable} />
			</Form.Item>
		</>
	);

	const methodsNotEdit = [
		CashflowreceiptsMethod.TRASNFER,
		CashflowreceiptsMethod.VNPAY,
		CashflowreceiptsMethod.VOUCHER,
	];

	return (
		<>
			<CashflowReceiptFormHeader
				isEditing={isEditing}
				direction={model.direction}
				id={model.id}
				onlyView={onlyView}
			/>

			<LayoutForm
				form={form}
				disable_status_loading
				initialValues={initialValues}
				errors={errors}
				isSuccess={isSuccess}
				submitDisabled={isDisableSubmit}
				hideFormSubmit={hideFormSubmit}
				successTitle={
					isEditing
						? t("cashflowreceipt:form.success.update")
						: t("cashflowreceipt:form.success.add")
				}
				redirectOnSuccess={isEditing ? "" : "/cashflowreceipt"}
				error_translate_prefix="cashflowreceipt:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={sidebarItems}
				id="cashflowreceipt-form">
				<FormSection
					title={t("cashflowreceipt:form.section_info_income")}
					subtitle={t("cashflowreceipt:form.section_detail_income")}
					divider>
					<Row gutter={16}>
						<CashflowReceiptFormStatus
							disable={disable}
							store_id={model.store_id}
							id={model.id}
							initStatus={model.status}
							status={status}
							setStatus={setStatus}
						/>
						<Divider />
						<Col md={12} xs={24}>
							<Form.Item
								label={"Mã phiếu (Tự sinh nếu bỏ trống)"}
								name="identifier">
								<Input
									type="text"
									style={{ width: "100%" }}
									disabled={!allowEditInput}
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								label={
									model.direction === CashflowReceipt.DIRECTION_INCOME
										? t("cashflowreceipt:value_income")
										: t("cashflowreceipt:value_expense")
								}
								name="value"
								required
								rules={[
									{
										required: true,
										message: t(
											"cashflowreceipt:form.error.error_value_required"
										),
									},
								]}>
								<MoneyInput
									style={{ width: "100%" }}
									className=""
									controls
									disabled={
										!allowEditInput || methodsNotEdit.includes(model.method)
									}
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<FormSelect
								disabled={!allowEditInput}
								label={t("cashflowreceipt:filter_method")}
								name="method"
								options={CashflowReceiptModel.getMethodList()}
								required
								rules={[
									{
										required: true,
										message: t(
											"cashflowreceipt:form.error.error_method_required"
										),
									},
								]}
								className="mb-0"
							/>
						</Col>
					</Row>
					{contextHolder}
				</FormSection>
				<FormSection
					title={t("cashflowreceipt:form.section_info_general")}
					subtitle={t("cashflowreceipt:form.section_info_general_detail")}>
					{model.source !== CashflowReceipt.SOURCE_MANUAL ? (
						<Alert
							className="mb-4"
							showIcon
							closable
							type="warning"
							message={t("cashflowreceipt:form.warning_manual_lock_changes")}
						/>
					) : null}

					<Row gutter={16}>
						<Col md={12} xs={24}>
							<FormSelect
								showSearch
								label={t("cashflowreceipt:filter_cashflow_group")}
								name="cashflow_group"
								options={CashflowReceiptModel.getGroupList()}
								required
								disabled={!allowEditInput}
							/>
						</Col>
						<Col md={12} xs={24}>
							<Row gutter={16}>
								<Col md={24} xs={24}>
									<CashflowReceiptTargetSelect
										disabled={
											// model.source !== CashflowReceipt.SOURCE_MANUAL ||
											!allowEditInput
										}
										model={model}
										targetId={targetId}
										setTargetId={setTargetId}
										label={
											model.direction === CashflowReceipt.DIRECTION_INCOME
												? t("cashflowreceipt:target_from")
												: t("cashflowreceipt:target_to")
										}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col md={12} xs={24}>
							<FormSelect
								showSearch
								label={t("Nguồn")}
								name="source"
								options={CashflowReceiptModel.getSourceList()}
								required
								disabled={!allowEditInput}
							/>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item required label={"Nguồn id"} name="source_id">
								<Input
									type="text"
									style={{ width: "100%" }}
									disabled={!allowEditInput}
								/>
							</Form.Item>
						</Col>
					</Row>
				</FormSection>
			</LayoutForm>
		</>
	);
};

export default CashflowReceiptForm;
