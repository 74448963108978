import { Button } from "antd";
import ShippingCarrierCollection from "common/collections/ShippingCarrierCollection";
import Role from "common/constants/Role";
import ShippingCarrier from "common/constants/ShippingCarrier";
import ShippingCarrierModel from "common/models/ShippingCarrierModel";
import ShippingCarrierRepository from "common/repositories/ShippingCarrierRepository";
import { FilterShippingCarrier } from "common/types/ShippingCarrier";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import ShippingCarrierFormModal from "features/shippingcarrier/form/ShippingCarrierFormModal";
import ShippingCarrierListHeader from "features/shippingcarrier/list/ShippingCarrierListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconApi, IconPlugConnectedX } from "@tabler/icons-react";

import ShippingCarrierFeeModal from "../fee/form/ShippingCarrierFeeModal";
import ShippingCarrierListFilter from "./ShippingCarrierListFilter";

const ShippingCarrierList = () => {
  const { t } = useTranslation();

  // modal detail fee setting
  const [editFeeDetailVisible, setEditFeeDetailVisible] = useState(false);

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShippingCarrier = useMemo(
    () => ShippingCarrierRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterShippingCarrier>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ShippingCarrierModel[]>([]);
  const fetchData =
    useCallback(async (): Promise<ShippingCarrierCollection> => {
      let collection = await new ShippingCarrierRepository().getItems({
        filters,
      });
      setDataSource(collection.items);
      setTotal(collection.total);
      return collection;
    }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<ShippingCarrierModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(record.id)}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("shippingcarrier:name"),
      key: "name",
      sortby: "name",
    },
    {
      title: t("shippingcarrier:api_type"),
      key: "api_type",
      sortby: "api_type",
      width: 200,
      render: (api_type: number) => {
        let typeInfo = ShippingCarrierModel.getApiType(api_type);
        return (
          <>
            {api_type === ShippingCarrier.APITYPE_EMPTY ? (
              <IconPlugConnectedX size={22} className="text-gray-300 -mt-0.5" />
            ) : (
              <IconApi size={22} className="text-blue-500 -mt-0.5" />
            )}{" "}
            {typeInfo?.label}
          </>
        );
      },
    },
    {
      title: t("shippingcarrier:fee"),
      key: "detail",
      render: (_: any, record: ShippingCarrierModel) => {
        return (
          <span
            className="text-blue-500 cursor-pointer"
            onClick={() => {
              setEditFeeDetailVisible(true);
              setEditingId(record.id);
            }}
          >
            {" "}
            {t("shippingcarrier:fee_detail")}
          </span>
        );
      },
    },
    {
      title: t("common:display_order_long"),
      width: 140,
      key: "display_order",
      align: "center",
      sortby: "display_order",
    },
    {
      title: t("common:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = ShippingCarrierModel.getStatus(status);
        return (
          <span
            className={
              status === ShippingCarrier.STATUS_DISABLED ? "text-red-500" : ""
            }
          >
            {statusInfo?.label}
          </span>
        );
      },
      width: 110,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: ShippingCarrierModel) => (
        <RoleCheck roles={[Role.SETTING_SALE]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="shippingcarrier:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ShippingCarrierRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: ShippingCarrierModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        })
      );
    }
  };

  return (
    <>
      <ShippingCarrierListHeader>
        <RoleCheck roles={[Role.SETTING_SALE]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </ShippingCarrierListHeader>
      <ShippingCarrierListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<
        FilterShippingCarrier,
        ShippingCarrierModel,
        ShippingCarrierCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      {editFeeDetailVisible ? (
        <ShippingCarrierFeeModal
          id={editingId}
          open={editFeeDetailVisible}
          setOpen={(isOpen) => {
            //clear editing id when close
            if (!isOpen) {
              setEditingId(0);
            }
            setEditFeeDetailVisible(isOpen);
          }}
        />
      ) : null}

      <ShippingCarrierFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ShippingCarrierList;
