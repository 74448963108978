import Board, {
  BoardType,
  CardBag,
  CardType,
  ColumnType,
  DestinationType,
  SourceType,
} from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import "./Kanban.css";

const Kanban = ({
  intialBoard,
  renderCard,
  onCardDragEnd,
  renderColumnHeader,
  disableCardDrag,
  allowRemoveColumn,
}: {
  intialBoard: BoardType;
  renderCard?: (card: CardType, cardBag: CardBag) => React.ReactNode;
  onCardDragEnd?: (
    card: CardType,
    source: SourceType,
    destination: DestinationType
  ) => void;
  renderColumnHeader?: (column: ColumnType) => React.ReactNode;
  disableCardDrag?: boolean;
  allowRemoveColumn?: boolean;
}) => {
  // const [board, setBoard] = useState(intialBoard);

  return (
    <>
      <Board
        onCardDragEnd={onCardDragEnd}
        disableColumnDrag
        renderCard={renderCard}
        renderColumnHeader={renderColumnHeader}
        disableCardDrag={disableCardDrag}
        allowRemoveColumn={allowRemoveColumn}
      >
        {intialBoard}
      </Board>
    </>
  );
};

export default Kanban;
