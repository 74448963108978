import { BaseCollectionJson } from "./BaseCollectionJson";
import { Filter } from "./Filter";

type PromotionJson = {
	creator_id: number;
	company_id: number;
	id: number;
	group: number;
	type: number;
	code: string;
	name: string;
	store_scope: number;
	store_id_list: number[];
	customer_scope: number;
	min_check: number;
	value_type: number;
	value: string | string[];
	pass_code: string;
	status: number;
	start_date: number;
	end_date: number;
	modified_date: number;
	create_date: number;
	date_created: number;
	description: string;
	condition: object;
	date_modified: number;
	collection_list: number[];
	max_quantity_check: number;
	min_quantity_check: number;
	store_list: number[];
};

type CampaignJson = {
	id: number;
	name?: string;
	code?: string;
	description?: string;
};

type PromotionJson2 = {
	id: number;
	status: boolean;
	create_date: number;
	req_subtotal: number;
	req_productid: number;
	req_quantity: number;
	discount_value: number;
	discount_type: string;
	req_productids: string;
	campaign_info: CampaignJson;
	priority: number;
	is_use?: number;
	discount_value_type: string;
};

////////////////////////////////

export enum TypeTagConfig {
	VARIANT,
	CARD,
}

interface TagPromotionInfo {
	name: string;
	bg_color: string;
	text_color: string;
	type: TypeTagConfig;
}
type DealthomConfig = {
	code: string;
	promotion_id: number[];
	tag_infor: TagPromotionInfo[];
};

type PromotionJsonAddEdit = Omit<Partial<PromotionJson>, "collection_list"> & {
	id: number;
	collection_list?: string;
	date_started?: number;
	date_ended?: number;
};

type PromotionCollectionJson = BaseCollectionJson<PromotionJson>;

type FilterPromotion = Filter & {
	id: number;
	group: number;
	type: number;
	date_started: number;
	date_ended: number;
	list_id?: string;
};

export type {
	CampaignJson,
	PromotionJson2,
	PromotionJson,
	PromotionJsonAddEdit,
	PromotionCollectionJson,
	FilterPromotion,
	DealthomConfig,
	TagPromotionInfo,
};
