const InventoryReceipt = {
	STATUS_DRAFT: 1,
	STATUS_WAIT_FOR_APPROVAL: 3,
	STATUS_APPROVED: 5,
	STATUS_SHIPPED: 7,
	STATUS_COMPLETED: 9,
	STATUS_CANCELLED: 11,

	DIRECTION_OUTPUT: 1,
	DIRECTION_INPUT: 3,

	//type của phiếu xuất kho
	TYPE_SELLING: 3, // ban le
	TYPE_OUT_RECEIVE: 13, // xuất trả,
	TYPE_OUT_EXPORT_CANCEL: 25, // xuất hủy,
	TYPE_OUT_GIFT_OUT: 24,

	// common
	TYPE_OTHER: 21, // khac
	TYPE_TRANSFERSTORE: 5, // chuiyeenr kho

	//type của phiếu nhâpj kho
	TYPE_IN_BUYING: 1, // mua
	TYPE_IN_INPUTRECEIVE: 11, // nhap hang
	TYPE_IN_GIFT_IN: 23, // quà nhận
	TYPE_IN_BALANCESTORE: 7, // cân bằng kho
	TYPE_SELLING_RETURN: 14, // nhập trả hàng
};

export default InventoryReceipt;
