import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import { StoreJson } from "common/types/Store";
import TextMoney from "components/TextMoney";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback } from "react";

import ReportChartCategory from "../chart/ReportChartCategory";
import ReportChartDataTable from "../chart/ReportChartDataTable";

const ReportCashflowReceiptIncomeStore = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const [storeItemsWithLimit] = useDatabaseTable<StoreJson>("store");
  const formatterStoreValue = useCallback(
    (value: number) => {
      return storeItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [storeItemsWithLimit]
  );
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Giá trị phiếu thu theo cửa hàng"}
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              dataSelect="sum(value), store"
              dataFilter="direction = 5,status < 2"
              dataGroupBy="store"
              dataOrderBy="sum(value) desc"
              keyMapping={{
                name: "store",
                value: "sum_value",
                formatter: (value: number) => {
                  return formatterStoreValue(value) || "--";
                }
              }}
              height={400}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo phiếu thu theo cửa hàng"}
              subtitle="Chỉ tính dựa trên phiếu thu chưa thanh toán và đã thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Của hảng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  },
                  sortField: "store"
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(value)"
              dataFilter="direction = 5,status < 2"
              dataGroupBy="store"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền cần thu theo cửa hàng"}
              subtitle="Chỉ tính các phiếu thu có trạng thái chưa thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Của hảng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  },
                  sortField: "store"
                },
                {
                  title: "Tiền cần thu",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(value)"
              dataFilter="direction = 5,status = 0"
              dataGroupBy="store"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền đã thu theo cửa hàng"}
              subtitle="Chỉ tính các phiếu thu có trạng thái đã thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  }
                },
                {
                  title: "Tiền đã thu",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(value)"
              dataFilter="direction = 5,status = 1"
              dataGroupBy="store"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Giá trị phiếu thu hủy theo cửa hàng"}
              subtitle="Chỉ tính các phiếu thu có trạng thái hủy"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {storeItemsWithLimit.find((i) => i.id === value)?.name}
                      </>
                    );
                  }
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(value)"
              dataFilter="direction = 5,status = 2"
              dataGroupBy="store"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCashflowReceiptIncomeStore;
