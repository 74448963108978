import { Modal } from 'antd';
import CustomerPointCollection from 'common/collections/CustomerPointCollection';
import CustomerPointModel from 'common/models/CustomerPointModel';
import CustomerPointRepository from 'common/repositories/CustomerPointRepository';
import { FilterCustomerPoint } from 'common/types/CustomerPoint';
import ErrorAlert from 'components/ErrorAlert';
import CustomerFormLatestPoint from 'features/customer/form/CustomerFormLatestPoint';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomerFormLatestPointModal = ({
  customerId,
  open,
  setOpen,
}: {
  customerId: number;
  open: boolean;
  setOpen: (v: boolean) => any;
}) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterCustomerPoint = useMemo(
    () => CustomerPointRepository.getDefaultFilters(),
    []
  );

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<CustomerPointModel[]>([]);

  const fetchData = useCallback(async (): Promise<CustomerPointCollection> => {
    let collection = await new CustomerPointRepository().getItems({
      filters: { ...defaultFilters, customer_id: customerId },
    });
    setDataSource(collection.items);
    return collection;
  }, [defaultFilters, customerId]);

  useEffect(() => {
    if (customerId > 0 && errors.length === 0) {
      fetchData();
    }
  }, [fetchData, customerId, errors]);

  return (
    <Modal
      title={t("customer:lastest_point")}
      width={720}
      open={open}
      closable
      maskClosable
      footer={null}
      onCancel={() => {
        setOpen(false);
      }}
      cancelText={t("common:close")}
    >
      {customerId > 0 ? (
        <>
          {dataSource.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchData();
                  }}
                />
              ) : (
                <CustomerFormLatestPoint
                  customerId={customerId}
                  dataSource={dataSource}
                  setOpen={setOpen}
                />
              )}
            </>
          ) : null}
        </>
      ) : (
        <CustomerFormLatestPoint
          customerId={0}
          dataSource={[]}
          setOpen={setOpen}
        />
      )}
    </Modal>
  );
};

export default CustomerFormLatestPointModal;
