import { WarehouseJson } from 'common/types/Warehouse';
import useDatabaseTable from 'hooks/useDatabaseTable';

const TextWarehouse = ({ id , warehouseItems }: { id: number  , warehouseItems?: WarehouseJson[] }) => {
  const [warehouseItemsDB ] = useDatabaseTable<WarehouseJson>("warehouse");


  const items = warehouseItems ||warehouseItemsDB

  return <>{items.find((item) => item.id === id)?.name || "--"}</>;
};

export default TextWarehouse;
