import { App, DatePicker, DatePickerProps, Form, Input } from "antd";
import { SelectValue } from "antd/es/select";
import ShippingRoute from "common/constants/ShippingRoute";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import ShippingRouteRepository from "common/repositories/ShippingRouteRepository";
import {
  DpFromPlaceSelectOption,
  DpShipperSelectOption
} from "common/types/DeliveryPlanning";
import { ShippingRouteJsonAddEdit } from "common/types/ShippingRoute";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const DeliveryPlanningCartSectionAdd = ({
  shippingHubItems,
  shipperItems,
  selectedOrder,
  addRouteIdToOrder
}: {
  shippingHubItems: DpFromPlaceSelectOption[];
  shipperItems: DpShipperSelectOption[];
  selectedOrder: ShippingOrderModel[];
  addRouteIdToOrder: (v: number) => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [loading, setLoading] = useState<boolean>(true);
  const [ymd, setYmd] = useState<dayjs.Dayjs | null>();
  const [selectedFromPlace, setSelectedFromPlace] =
    useState<DpFromPlaceSelectOption>({
      label: "",
      value: 0,
      id: 0,
      from_type: 0
    });

  const initialValues = useMemo(() => {
    return {
      ymd: dayjs(dayjs().format("YYYYMMDD"))
    };
  }, []);

  const checkLatLngOrder = useMemo(() => {
    let pass = true;
    for (let i = 0; i < selectedOrder.length; i++) {
      let orderItem = selectedOrder[i];
      if (
        orderItem.order_shipping_lat <= 0 ||
        orderItem.order_shipping_long <= 0
      ) {
        pass = false;
        break;
      }
    }
    return pass;
  }, [selectedOrder]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ShippingRouteJsonAddEdit = {
        id: 0,
        shipper_id: formData.shipper_id,
        from_warehouse_id: selectedFromPlace.id,
        status: ShippingRoute.STATUS_PENDING,
        from_type: selectedFromPlace.from_type,
        ymd: +dayjs(ymd, "YYYYMMDD").format("YYYYMMDD"),
        code: formData.code
      };

      return submitData;
    },
    [selectedFromPlace, ymd]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    if (checkLatLngOrder) {
      let routeItem: ShippingRouteModel =
        await new ShippingRouteRepository().saveRemote(doPrepareData(formData));
      if (routeItem.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="deliveryplanning:form.error"
              items={routeItem.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4
        });
      } else {
        if (checkLatLngOrder) {
          addRouteIdToOrder(routeItem.id);
        } else {
          message.error({
            content: t(
              "deliveryplanning:form.error.error_exist_order_miss_lat_lng"
            ),
            className: "message_error",
            key: "message",
            duration: 4
          });
        }
      }
    } else {
      message.error({
        content: t(
          "deliveryplanning:form.error.error_exist_order_miss_lat_lng"
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    }
  };

  const getCode = (value: dayjs.Dayjs | null) => {
    let newCode = "";
    newCode = dayjs(value, "YYYYMMDD").format("DDMM") + "X000";
    return newCode.toUpperCase();
  };

  const onShipperChange = (value: SelectValue) => {
    let newCode = "";
    if (value && +value > 0) {
      let shipperText = "000";
      let findShipper = shipperItems.find((i) => i.value === value);
      if (
        findShipper &&
        findShipper.value > 0 &&
        findShipper.internal_id !== ""
      ) {
        shipperText = findShipper.internal_id;
      }
      newCode = dayjs(ymd, "YYYYMMDD").format("DDMM") + "X" + shipperText;
    }

    form.setFieldValue("code", newCode.toUpperCase());
  };

  const onChangeFromPlace = (value: SelectValue) => {
    if (value !== undefined && +value > 0) {
      let findFromPlaceOption = shippingHubItems.find((i) => i.value === value);
      if (findFromPlaceOption !== undefined) {
        setSelectedFromPlace(findFromPlaceOption);
      }
    }
  };

  const onDateChange: DatePickerProps["onChange"] = (value, _dateString) => {
    if (typeof value !== "undefined") {
      // set code
      form.setFieldValue("code", getCode(value as Dayjs));

      setYmd(value as Dayjs);
    }
  };

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    if (inputValue !== "") {
      form.setFieldValue("code", inputValue.toUpperCase());
    }
  };

  useEffect(() => {
    if (loading) {
      // set init code
      form.setFieldValue("code", getCode(dayjs(dayjs().format("YYYYMMDD"))));
      setYmd(dayjs(dayjs().format("YYYYMMDD")));
      setLoading(false);
    }
  }, [loading, form]);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={onSubmit}
        onFinishFailed={(formx) => console.log(formx)}
        id="add-route-form"
        className="px-2 py-2">
        <div className="px-2">
          <Form.Item
            name="ymd"
            label={
              <>
                <span className="text-white">
                  {t("deliveryplanning:cart.ymd")}
                </span>
              </>
            }>
            <DatePicker
              changeOnBlur
              clearIcon={false}
              placement="topLeft"
              style={{ width: "100%" }}
              onChange={onDateChange}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                <span className="text-white">
                  {t("deliveryplanning:cart.from_place")}
                </span>
              </>
            }>
            <FormSelect
              options={shippingHubItems}
              allowClear
              showSearch
              placeholder={t("deliveryplanning:cart.from_place_placeholder")}
              placement="topRight"
              onChange={onChangeFromPlace}
              className="mb-0"
            />
          </Form.Item>

          <Form.Item
            name="code"
            label={
              <>
                <span className="text-white">
                  {t("deliveryplanning:cart.code")}
                </span>
              </>
            }>
            <Input
              allowClear
              placeholder={t("deliveryplanning:cart.code_placeholder")}
              onChange={(e) => {
                e.preventDefault();
                onCodeChange(e);
              }}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                <span className="text-white">
                  {t("deliveryplanning:cart.shipper")}
                </span>
              </>
            }>
            <FormSelect
              name="shipper_id"
              options={shipperItems}
              allowClear
              showSearch
              placeholder={t("deliveryplanning:cart.shipper_placeholder")}
              placement="topRight"
              onChange={(v: SelectValue) => onShipperChange(v)}
              className="mb-0"
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default DeliveryPlanningCartSectionAdd;
