import {
  ReportDeliveryCollectionJson,
  ReportDeliveryJson,
} from "common/types/ReportDelivery";
import BaseCollection from "./BaseCollection";
import ReportDeliveryModel from "common/models/ReportDeliveryModel";

class ReportDeliveryCollection extends BaseCollection<
  ReportDeliveryJson,
  ReportDeliveryModel,
  ReportDeliveryCollectionJson
> {
  itemsFromJson(jsonItems: ReportDeliveryJson[]): ReportDeliveryModel[] {
    return jsonItems.map((item) => new ReportDeliveryModel(item));
  }
}

export default ReportDeliveryCollection;
