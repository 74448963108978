import BaseCollection from 'common/collections/BaseCollection';
import ProductReceiptModel from 'common/models/ProductReceiptModel';
import { ProductReceiptCollectionJson, ProductReceiptJson } from 'common/types/ProductReceipt';

class ProductReceiptCollection extends BaseCollection<
  ProductReceiptJson,
  ProductReceiptModel,
  ProductReceiptCollectionJson
> {
  itemsFromJson(jsonItems: ProductReceiptJson[]): ProductReceiptModel[] {
    return jsonItems.map((item) => new ProductReceiptModel(item));
  }
}

export default ProductReceiptCollection;
