import { Modal, Skeleton } from 'antd';
import WebHookModel from 'common/models/WebHookModel';
import WebHookRepository from 'common/repositories/WebHookRepository';
import ErrorAlert from 'components/ErrorAlert';
import WebHookForm from 'features/webhook/form/WebHookForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const WebHookFormModal = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: WebHookModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<WebHookModel>(
    new WebHookModel(WebHookModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new WebHookRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (id > 0 && errors.length === 0) {
      fetchDetail(id);
    }
  }, [id, errors]);

  return (
    <Modal
      title={id > 0 ? t("webhook:heading_edit") : t("webhook:heading_add")}
      width={720}
      open={open}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        setModel(new WebHookModel(WebHookModel.getDefaultData()));
        setOpen(false);
      }}
      okButtonProps={{ form: "webhook-form", htmlType: "submit" }}
      okText={t("common:form.save")}
      cancelText={t("common:close")}
      confirmLoading={processing}
    >
      {id > 0 ? (
        <>
          {model.id > 0 || errors.length > 0 ? (
            <>
              {errors.length > 0 ? (
                <ErrorAlert
                  items={errors}
                  heading={t("common:error.error_fetching_data")}
                  onRetry={() => {
                    setErrors([]);
                    fetchDetail(id);
                  }}
                />
              ) : (
                <WebHookForm
                  id={id}
                  model={model}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  onSaveSuccess={onSaveSuccess}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton active />
            </>
          )}
        </>
      ) : (
        <WebHookForm
          id={0}
          model={new WebHookModel(WebHookModel.getDefaultData())}
          setOpen={setOpen}
          setProcessing={setProcessing}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </Modal>
  );
};

export default WebHookFormModal;
