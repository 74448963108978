import { Button, Form, Input, Typography } from "antd";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import { Filter } from "common/types/Filter";
import DateFormFilter from "components/datetime/DateFormFilter";
import FormSelect from "components/form/FormSelect";
import dayjs from "dayjs";
import { diff } from "deep-object-diff";
import { mapValues, omit } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import { IconX } from "@tabler/icons-react";

const DeliveryDashboardFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const setActiveRoute = useDeliveryDashboardStore(
    (state) => state.setActiveRoute,
  );

  ////////////////////////////////////////////
  //Compare fitlers with default filters to detect isfilerting or not
  const ignoreCheckFiltering = ["page", "limit", "sortby", "sorttype"];
  const refinedFilters = mapValues(omit(filters, ignoreCheckFiltering), (v) =>
    v === undefined ? -1 : v,
  );
  const refinedDefaultFilters = mapValues(
    omit(defaultFilters, ignoreCheckFiltering),
    (v) => v,
  );
  const countFiltering = Object.keys(
    diff(refinedDefaultFilters, refinedFilters),
  ).length;
  //end check isFiltering
  ////////////////////////////////////////

  const onFormSubmit = useCallback(
    (formData: any) => {
      setFilters({ ...filters, ...formData, page: 1 });

      // clear activeRoute
      setActiveRoute(
        new ShippingRouteModel(ShippingRouteModel.getDefaultData()),
      );
    },
    [filters, setFilters, setActiveRoute],
  );

  const getYmdInFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "ymd")
      .map(([key, val]) => {
        return val;
      }) as number[];

    return entries[0] > 0
      ? dayjs(
          dayjs(entries[0].toString(), "YYYYMMDD").format("DD/MM/YYYY"),
          "DD/MM/YYYY",
        )
      : undefined;
  }, [filters]);

  useEffect(() => {
    form.setFieldsValue(
      mapValues(filters, (f) =>
        typeof f === "number" && f < 0 ? undefined : f,
      ),
    );
  }, [filters, form]);

  return (
    <div className="">
      <div className="px-6 py-4 bg-gray-100 border-b border-gray-300">
        <Form
          form={form}
          initialValues={filters}
          onFinish={onFormSubmit}
          layout="inline"
          autoComplete="off">
          <Typography.Title
            level={4}
            style={{ marginBottom: 0 }}
            className="mr-6">
            {t("deliverydashboard:heading_list")}
          </Typography.Title>

          <Form.Item name="keyword">
            <Input
              allowClear
              placeholder={t("deliverydashboard:filter_keyword_placeholder")}
            />
          </Form.Item>

          <Form.Item name="id">
            <Input
              allowClear
              placeholder={t("deliverydashboard:filter_route_id_placeholder")}
            />
          </Form.Item>

          {/* FILTER: YMD */}
          <Form.Item
            name="ymd"
            valuePropName="ymd"
            normalize={(value, prevValue) => {
              return +dayjs(value).format("YYYYMMDD");
            }}>
            <DateFormFilter
              value={getYmdInFilter}
              allowClear={false}
              showNow={false}
              mode="single"
              format="DD/MM/YYYY"
              className="w-60"
              placeholder={t("deliverydashboard:filter_ymd_placeholder")}
            />
          </Form.Item>

          <FormSelect
            name="status"
            options={ShippingRouteModel.getStatusList()}
            allowClear
            placeholder={t("deliverydashboard:filter_status_placeholder")}
            className="w-60"
            popupMatchSelectWidth={false}
          />

          <Button type="primary" htmlType="submit">
            {t("common:table.filter_submit_button")}
          </Button>

          {countFiltering > 0 ? (
            <Button
              danger
              type="text"
              icon={<IconX size="16" className="mr-1 -mt-0.5" />}
              onClick={() => setFilters(defaultFilters)}
              className="ml-2">
              {t("common:filter.reset")}
            </Button>
          ) : undefined}
        </Form>
      </div>
    </div>
  );
};

export default DeliveryDashboardFilter;
