import ProductVariantSizeApi from 'common/api/ProductVariantSizeApi';
import ProductVariantSizeCollection from 'common/collections/ProductVariantSizeCollection';
import ProductVariantSizeModel from 'common/models/ProductVariantSizeModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProductVariantSizeJsonAddEdit,
  FilterProductVariantSize,
} from "common/types/ProductVariantSize";

class ProductVariantSizeRepository extends BaseRepository<ProductVariantSizeApi> {
  _api: ProductVariantSizeApi | null;

  constructor() {
    super();
    this._api = new ProductVariantSizeApi(true);
  }

  static getDefaultFilters(): FilterProductVariantSize {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterProductVariantSize>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductVariantSizeCollection();
  }

  async getItem(id: number): Promise<ProductVariantSizeModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductVariantSizeModel(ProductVariantSizeModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductVariantSizeJsonAddEdit
  ): Promise<ProductVariantSizeModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductVariantSizeModel(
        ProductVariantSizeModel.getDefaultData()
      );
    }
  }
}

export default ProductVariantSizeRepository;
