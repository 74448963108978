import { ColProps, Form, Select, SelectProps, TreeSelect } from "antd";
import { SelectCommonPlacement } from "antd/es/_util/motion";
import { Rule } from "antd/es/form";
import { FormLabelAlign, NamePath } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import { SelectOption } from "common/types/SelectOption";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import ErrorAlert from "../ErrorAlert";

const FormSelect = ({
	value,
	onChange,
	isTree,
	name,
	rules,
	options,
	label,
	colon,
	noStyle,
	required,
	className,
	popupClassName,
	allowClear,
	autoFocus,
	disabled,
	loading,
	showSearch,
	size,
	listHeight,
	placeholder,
	suffixIcon,
	dropdownStyle,
	errors,
	errorRetry,
	labelAlign,
	multiple,
	tooltip,
	placement,
	onClear,
	bordered,
	popupMatchSelectWidth,
	status,
	lableCol,
	onSearch,
	tagRender,
	optionRender,
}: {
	value?: SelectValue;
	onChange?: (v: SelectValue) => void;
	isTree?: boolean;
	name?: string | NamePath;
	rules?: Rule[];
	options: SelectOption[] | any;
	label?: React.ReactNode;
	colon?: boolean;
	noStyle?: boolean;
	required?: boolean;
	className?: string;
	popupClassName?: string;
	allowClear?: boolean;
	autoFocus?: boolean;
	disabled?: boolean;
	loading?: boolean;
	showSearch?: boolean;
	size?: SelectProps["size"];
	listHeight?: number;
	placeholder?: string;
	suffixIcon?: React.ReactNode;
	dropdownStyle?: CSSProperties;
	errors?: string[];
	errorRetry?: () => void;
	labelAlign?: FormLabelAlign;
	multiple?: boolean;
	tooltip?: string;
	placement?: SelectCommonPlacement;
	onClear?: () => void;
	bordered?: boolean;
	popupMatchSelectWidth?: boolean;
	status?: SelectProps["status"];
	lableCol?: ColProps;
	onSearch?: (v: string) => void;
	tagRender?: SelectProps["tagRender"];
	optionRender?: any;
}) => {
	const [allOptions, setAllOptions] = useState<SelectOption[]>(options);

	useEffect(() => {
		setAllOptions(options);
	}, [options]);

	const renderer = useCallback(
		(menu: React.ReactNode) => {
			if (Array.isArray(errors) && errors.length > 0) {
				return (
					<ErrorAlert items={errors} translate_prefix="" onRetry={errorRetry} />
				);
			}

			return <>{menu}</>;
		},
		[errors, errorRetry]
	);

	return (
		<Form.Item
			name={name}
			rules={rules}
			noStyle={noStyle}
			label={label}
			required={required}
			className={className}
			labelAlign={labelAlign}
			tooltip={tooltip}
			colon={colon}
			labelCol={lableCol}>
			{isTree ? (
				<TreeSelect
					showSearch
					loading={loading}
					placeholder={placeholder}
					dropdownRender={renderer}
					style={{ width: "100%" }}
					disabled={disabled}
					allowClear
					filterTreeNode={(input: any, item: any) =>
						item.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
						item.title
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/[đĐ]/g, "d")
							.toLowerCase()
							.indexOf(
								input
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.replace(/[đĐ]/g, "d")
									.toLowerCase()
							) >= 0
					}
					treeData={allOptions.map((option) => ({
						id: option.value,
						pId: option.parent_id,
						value: option.value,
						title: option.label,
					}))}
					treeDataSimpleMode={true}
					treeDefaultExpandAll={true}
					treeLine={{ showLeafIcon: false }}
					popupMatchSelectWidth={popupMatchSelectWidth}></TreeSelect>
			) : (
				<Select
					value={value}
					onChange={onChange}
					onClear={onClear}
					options={allOptions}
					placement={placement}
					style={{ width: "100%" }}
					allowClear={allowClear}
					autoFocus={autoFocus}
					disabled={disabled}
					loading={loading}
					showSearch={showSearch}
					size={size}
					popupClassName={popupClassName}
					popupMatchSelectWidth={popupMatchSelectWidth}
					listHeight={listHeight}
					dropdownStyle={dropdownStyle}
					suffixIcon={suffixIcon}
					placeholder={placeholder}
					dropdownRender={renderer}
					onSearch={onSearch}
					bordered={bordered}
					tagRender={tagRender}
					optionFilterProp="label"
					mode={typeof multiple !== "undefined" ? "multiple" : undefined}
					status={status}
					filterOption={(input: any, option: any) =>
						option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
						option.label
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/[đĐ]/g, "d")
							.toLowerCase()
							.indexOf(
								input
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.replace(/[đĐ]/g, "d")
									.toLowerCase()
							) >= 0
					}></Select>
			)}
		</Form.Item>
	);
};
export default FormSelect;
