import BaseCollection from "common/collections/BaseCollection";
import ApiKeyModel from "common/models/ApiKeyModel";
import { ApiKeyJson, ApiKeyCollectionJson } from "common/types/ApiKey";

class ApiKeyCollection extends BaseCollection<
  ApiKeyJson,
  ApiKeyModel,
  ApiKeyCollectionJson
> {
  itemsFromJson(jsonItems: ApiKeyJson[]): ApiKeyModel[] {
    return jsonItems.map((item) => new ApiKeyModel(item));
  }
}

export default ApiKeyCollection;


