import { Button, InputNumber } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconMinus, IconPlus } from "@tabler/icons-react";

import type { QuantityInputProps } from "common/interfaces/QuantityInput";
const QuantityInput: React.FC<QuantityInputProps> = ({
  value,
  onChange,
  allowNegative,
  controlsHoverOnly,
  wrapperClassName,
  ...props
}: QuantityInputProps) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(value || 0);

  const triggerChange = useCallback(
    (changedValue: number) => {
      onChange?.(changedValue);
    },
    [onChange]
  );

  const updateQuantity = useCallback(
    (newQuantity: number) => {
      const { max, min } = props;

      if (typeof max !== "undefined" && newQuantity > max) {
        return;
      }
      if (typeof min !== "undefined" && newQuantity < min) {
        return;
      }

      setQuantity(newQuantity);
      triggerChange(newQuantity);
    },
    [props, triggerChange]
  );

  //handling change from input/buttons
  const onChangeQuantity = useCallback(
    (v: number | null) => {
      updateQuantity(v ?? 0);
    },
    [updateQuantity]
  );

  const onClickMinus = useCallback(() => {
    const newQuantity = quantity - 1;
    if (
      newQuantity >= 0 ||
      (typeof allowNegative !== "undefined" && allowNegative)
    ) {
      updateQuantity(newQuantity);
    } else {
      updateQuantity(0);
    }
  }, [allowNegative, quantity, updateQuantity]);

  const onClickPlus = useCallback(
    (_: any) => {
      updateQuantity(quantity + 1);
    },
    [quantity, updateQuantity]
  );

  useEffect(() => {
    setQuantity(value || 0);
  }, [value]);

  return (
    <div
      className={
        "quantity-input-wrapper flex whitespace-nowrap  border rounded-sm " +
        (wrapperClassName || "border-gray-200")
      }
    >
      <Button
        title={t("common:quantity_input.minus")}
        onClick={onClickMinus}
        disabled={props.disabled}
        size={props.size || "large"}
        className={
          "px-2 hover:bg-red-100 hover:text-red-500 hover:border-red-500" +
          (typeof controlsHoverOnly !== "undefined" && controlsHoverOnly
            ? " opacity-5 hover:opacity-100"
            : "")
        }
        style={{ borderWidth: 0 }}
      >
        <IconMinus size={18} className="-mt-0.5" />
      </Button>

      <InputNumber<number>
        size={props.size || "large"}
        onPressEnter={() => {}}
        value={quantity}
        onChange={onChangeQuantity}
        min={
          typeof allowNegative !== "undefined" && allowNegative ? undefined : 0
        }
        decimalSeparator=""
        precision={0}
        step={1}
        controls={false}
        stringMode={false}
        className={props.className}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) =>
          typeof value !== "undefined"
            ? Number(value.replace(/\$\s?|(,*)/g, ""))
            : 0
        }
        {...props}
      />
      <Button
        disabled={props.disabled}
        title={t("common:quantity_input.plus")}
        onClick={onClickPlus}
        size={props.size || "large"}
        className={
          "px-2 hover:bg-green-100 hover:text-green-500 hover:border-green-500" +
          (typeof controlsHoverOnly !== "undefined" && controlsHoverOnly
            ? " opacity-5 hover:opacity-100"
            : "")
        }
        style={{ borderWidth: 0 }}
      >
        <IconPlus size={18} className="-mt-0.5" />
      </Button>
    </div>
  );
};

export default QuantityInput;
