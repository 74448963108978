import { App, Col, Form, Input, InputNumber, Row } from "antd";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import TagTypeModel from "common/models/TagTypeModel";
import TagTypeRepository from "common/repositories/TagTypeRepository";
import FormSelect from "components/form/FormSelect";
import { TagTypeAddEditJson } from "common/types/TagType";
const TagTypeForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess,
}: {
  model: TagTypeModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: TagTypeModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [getValue, setValue] = useFormDefaultStore((state) => [
    state.getValue,
    state.setValue,
  ]);

  const [form] = Form.useForm();
  const initialValues = useMemo(() => {
    return {
      name: model.name,
      code: model.code.length > 0 ? model.code : "",
      status: model.status > 0 ? model.status : 1,
      resoucre_type: model.id > 0 ? model.resource_type : 1,
    };
  }, [model, getValue]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: TagTypeAddEditJson = {
        ...TagTypeModel.getDefaultData(),
        id: model.id,
        code: formData.code.length > 0 ? formData.code.trim() : "",
        name: formData.name.length > 0 ? formData.name.trim() : "",
        status: formData.status,
        resource_type: formData.resoucre_type,
      };

      return submitData;
    },
    [model.id, model.code, setValue]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    let item: TagTypeModel = await new TagTypeRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="tagtype:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}
    >
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item label={t("tagtype:code")} name="code" className="mb-0">
            <Input placeholder={t("tag:form.code_placeholder")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("tagtype:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("tagtype:form.error.error_name_require"),
              },
            ]}
            className="mb-0"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <FormSelect
            label={t("tagtype:status")}
            name="status"
            placeholder={"Trạng thái"}
            options={TagTypeModel.getStatusSelectList()}
            popupMatchSelectWidth={false}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <FormSelect
            label={"Dùng cho"}
            name="resoucre_type"
            options={TagTypeModel.getResouceTypeList()}
            popupMatchSelectWidth={false}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default TagTypeForm;
