import BaseRepository from "./BaseRepository";
import RbacRoleUserApi from "common/api/RbacRoleUserApi";
import RbacRoleUserCollection from "common/collections/RbacRoleUserCollection";
import type { GetListProps } from "common/types/Filter";
import type { FilterRoleUser } from "common/types/RbacRoleUser";
import RbacRoleUserDetailModel from "common/models/RbacRoleUserDetailModel";

class RbacRoleUserRepository extends BaseRepository<RbacRoleUserApi> {
  _api: RbacRoleUserApi;

  constructor() {
    super();
    this._api = new RbacRoleUserApi(true);
  }

  async getItems(
    props: GetListProps<FilterRoleUser>
  ): Promise<RbacRoleUserCollection> {
    return await this._api.getList(props);
  }

  async getRolesFromUser(user_id: number): Promise<RbacRoleUserDetailModel> {
    return await this._api.getRolesFromUser(user_id);
  }

  async limitObjectIdList(props: {
    rbac_role_id: number,
    subject_ids?: string,
    creator_id: number
    list_ids?: number[],
  }): Promise<string[]> {
    return await this._api.limitObjectIdList(props);
  }


  async getSubjectObjectList(filter: {
    rbac_role_id: number;
    subject_id: number;
  }) {
    return await this._api.getListSubjectObject(filter)
  }
}

export default RbacRoleUserRepository;
