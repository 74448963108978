import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

import StoryModel from "common/models/StoryModel";
import StoryRepository from "common/repositories/StoryRepository";

import StoryList from "features/story/list/StoryList";
import StoryForm from "features/story/form/StoryForm";
import FormEditFetcher from "components/form/FormEditFetcher";
import StoryFormHeader from "features/story/form/StoryFormHeader";

const StoryPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new StoryModel(StoryModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new StoryModel(StoryModel.getDefaultData()));
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    case "add":
      com = <StoryForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <StoryForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new StoryRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<StoryFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    default:
      com = <StoryList />;
      break;
  }
  return com;
};

export default StoryPage;
