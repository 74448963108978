import { Form, Select } from 'antd';
import { RegionJson } from 'common/types/Region';
import dbm from 'dbm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { RegionStandabloneInputProps } from "common/interfaces/RegionPicker";
const RegionStandalonePicker: React.FC<RegionStandabloneInputProps> = ({
  value,
  onChange,
  excludeValue,
}: RegionStandabloneInputProps) => {
  const { t } = useTranslation();
  const [regionItems, setRegionItems] = useState<RegionJson[]>([]);
  const [regionIdList, setRegionIdList] = useState<number[]>(value || []);

  const triggerChange = (changedValue: number[]) => {
    onChange?.(changedValue);
  };

  const onRegionIdChange = (newIdList: number[]) => {
    setRegionIdList(newIdList);
    triggerChange(newIdList);
  };

  useEffect(() => {
    setRegionIdList(value || []);
  }, [value]);

  useEffect(() => {
    let items = dbm.getCollection("region")?.chain().data();
    if (
      typeof items !== "undefined" &&
      items.hasOwnProperty("length") &&
      items.length > 0
    ) {
      setRegionItems(items);
    }
  }, []);

  return (
    <Form.Item label={t("common:region")} required className="mb-0">
      <Select<number[]>
        listHeight={300}
        value={regionIdList}
        allowClear
        mode="multiple"
        showArrow
        onChange={onRegionIdChange}
        placeholder={t("common:region_placeholder")}
        style={{ width: "100%" }}
        showSearch
        options={regionItems
          .filter(
            (i) =>
              i.parent_id === 0 &&
              (typeof excludeValue === "undefined" ||
                !excludeValue.includes(i.id))
          )
          .sort((a, b) => {
            return a.display_order > b.display_order ? 1 : -1;
          })
          .map((i) => ({
            value: i.id,
            label: i.name,
          }))}
        optionFilterProp="label"
        filterOption={(input: any, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.label
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[đĐ]/g, "d")
            .toLowerCase()
            .indexOf(
              input
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[đĐ]/g, "d")
                .toLowerCase()
            ) >= 0
        }
      />
    </Form.Item>
  );
};

export default RegionStandalonePicker;
