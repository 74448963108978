import { Avatar, Popover, Tag } from "antd";
import ColorHash from "color-hash";
import ShipperCollection from "common/collections/ShipperCollection";
import Role from "common/constants/Role";
import Shipper from "common/constants/Shipper";
import ShipperModel from "common/models/ShipperModel";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import ShipperRepository from "common/repositories/ShipperRepository";
import { IdFetcherResult } from "common/types/IdFetcher";
import { FilterShipper } from "common/types/Shipper";
import { TableColumnsType } from "common/types/Table";
import IdFetcherRender from "components/idfetcher/IdFetcherRender";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableInfoRow from "components/table/TableInfoRow";
import ShipperListHeader from "features/shipper/list/ShipperListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IconCar,
  IconGenderFemale,
  IconGenderMale,
  IconMotorbike,
} from "@tabler/icons-react";

import ShipperListFilter from "./ShipperListFilter";

const ShipperList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShipper = useMemo(
    () => ShipperRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterShipper>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  // call idfetcher
  const [shippingHub, setShippingHub] = useState<
    IdFetcherResult[] | undefined
  >();
  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ShipperModel[]>([]);

  const fetchingShippingHub = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "shippinghub",
      id_list,
    });

    let results =
      collection.items.find((i) => i.object_type === "shippinghub")?.result ||
      [];
    setShippingHub(results);
  }, []);

  const fetchData = useCallback(async (): Promise<ShipperCollection> => {
    let collection = await new ShipperRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);

    let shippingHubIdList = collection.items
      .map((item) => item.shipping_hub_id)
      .filter((i) => i > 0 && i !== undefined);
    if (shippingHubIdList.length > 0) {
      fetchingShippingHub(shippingHubIdList);
    }
    return collection;
  }, [filters, fetchingShippingHub]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ShipperModel> = [
    {
      title: " ",
      key: "avatar_file_id",
      width: 40,
      render: (_: any, record: ShipperModel) => {
        let avatar =
          record.avatar.id > 0 ? (
            <Avatar src={record.avatar.url} size="small" shape="circle" />
          ) : (
            <Avatar
              style={{
                backgroundColor: new ColorHash({ lightness: 0.75 }).hex(
                  record.full_name
                ),
              }}
              size="small"
              shape="circle"
            >
              {record.full_name.split(" ").slice(-1).toString().charAt(0)}
            </Avatar>
          );

        return avatar;
      },
    },
    {
      title: t("common:full_name"),
      key: "full_name",
    },
    {
      title: t("common:gender"),
      key: "gender",
      align: "center",
      width: 80,
      render: (gender: number) => {
        return gender === Shipper.GENDER_MALE ? (
          <IconGenderMale size={20} className="text-green-500" />
        ) : gender === Shipper.GENDER_FEMALE ? (
          <IconGenderFemale size={20} className="text-red-500" />
        ) : (
          "--"
        );
      },
    },
    {
      title: " ",
      togglelabel: t("shipper:transportation"),
      key: "transportation",
      align: "center",
      width: 50,
      render: (_: any, record: ShipperModel) => {
        return (
          <Popover
            placement="left"
            title={t("shipper:transportation_note") + ":"}
            content={
              record.transportation_note.length > 0
                ? record.transportation_note
                : "--"
            }
            mouseLeaveDelay={0.4}
          >
            {record.transportation === Shipper.TYPE_MOTOBIKE ? (
              <IconMotorbike size={20} />
            ) : record.transportation === Shipper.TYPE_CAR ? (
              <IconCar size={20} />
            ) : null}
          </Popover>
        );
      },
    },
    {
      title: t("common:phone"),
      key: "phone",
      width: 120,
    },
    {
      title: t("shipper:shipping_hub"),
      key: "shipping_hub_id",
      render: (_: any, record: ShipperModel) =>
        record.shipping_hub_id > 0 ? (
          <IdFetcherRender
            value={record.shipping_hub_id}
            mapping={shippingHub}
          />
        ) : (
          "---"
        ),
    },
    {
      title: t("common:status"),
      key: "status",
      render: (status: number) => {
        let statusInfo = ShipperModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 100,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 125,
      render: (_: any, record: ShipperModel) => (
        <RoleCheck roles={[Role.SHIPPER_MANAGE]} hideOnFail>
          <TableEdit link={"/shipper/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="shipper:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ShipperRepository()}
            id={record.id}
          />
          <TableInfo record={record}>
            <TableInfoRow label={t("common:email")} content={record.email} />
            <TableInfoRow
              label={t("shipper:internal_id")}
              content={record.internal_id}
            />
          </TableInfo>
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <ShipperListHeader />
      <ShipperListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<FilterShipper, ShipperModel, ShipperCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "shipper",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ShipperList;
