import {
  Button,
  Progress,
  TableColumnsType,
  Tag,
  Typography,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import usePathParams from "hooks/usePathParams";
import useStateFilter from "hooks/useStateFilter";
import { useNavigate } from "react-router-dom";
import useFilterLocation from "hooks/useFilterLocation";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Task from "common/constants/Task";
import TaskModel from "common/models/TaskModel";
import EmployeeModel from "common/models/EmployeeModel";
import { FilterTask, TaskJson } from "common/types/Task";
import TaskCollection from "common/collections/TaskCollection";
import TaskRepository from "common/repositories/TaskRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import TaskListHeader from "./TaskListHeader";
import TaskListFilter from "./TaskListFilter";
import TextDateTime from "components/TextDateTime";
import TableInfo from "components/table/TableInfo";
import PageDataDragTable from "components/page/PageDataDragTable";
import PhoneBookModal from "features/phonebook/form/PhoneBookModal";

type Props = {
  type: "default" | "all" | "current";
};

const TaskList = ({ type }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pathParams] = usePathParams();
  const { account } = useLoginAccountStore();

  // default filter
  const defaultFilters = useMemo(() => {
    return TaskRepository.getDefaultFilter();
  }, []);

  //state
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<TaskModel[]>([]);
  const [filters, setFilters] = useStateFilter(defaultFilters);
  const [modalId, setModalId] = useState<number>(0);
  const [employees, setEmployees] = useState<any[]>([]);
  const [currentPut, setCurrentPut] = useState<number>(0);
  const [userInfo, setUserInfo] = useState<EmployeeModel>();
  const [showContactFields, setShowContactFields] = useState(false);

  useFilterLocation(defaultFilters, filters);

  //Table columns
  const columns: TableColumnsType<TaskModel> = [
    {
      title: t("task:id"),
      key: "id",
      dataIndex: "id",
      width: 60,
      render: (id) => {
        return <Tag color="blue">{id}</Tag>;
      },
      // defaultSortOrder: "ascend",
    },

    {
      title: t("task:name"),
      key: "name",
      dataIndex: "name",
      width: 160,
      render: (name, record: TaskModel) => {
        return (
          <Typography.Text>
            {record.org_id > 0 && (
              <span style={{ color: "#ec5a00" }}>
                {"("}
                {record.org_id}
                {") "}
              </span>
            )}
            {name}
          </Typography.Text>
        );
      },
      // defaultSortOrder: "ascend",
    },
    {
      title: t("task:type"),
      key: "type",
      dataIndex: "type",
      width: 80,
      // defaultSortOrder: "ascend",
      render: (type: number) => {
        const type_name = TaskModel.getTypeTask(type);
        const color =
          type_name === "Feature"
            ? "blue"
            : type_name === "Task"
            ? "green"
            : type_name === "Bug"
            ? "red"
            : "orange";
        return <Tag color={color}>{type_name}</Tag>;
      },
    },

    {
      title: t("task:creted_by"),
      key: "employee_id",
      // dataIndex: "employee_id",
      width: 160,
      render: (employee_id) => {
        return (
          <Typography.Text>
            {employee_id === 0 ? (
              <Tag>{t("delivery:action.empty")}22</Tag>
            ) : (
              <Tag
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setModalId(employee_id);
                  setShowContactFields(true);
                }}
              >
                {employees.length > 0
                  ? employees.find((i) => i.value === employee_id)?.text
                  : t("delivery:action.empty")}
              </Tag>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: t("task:org_fromtime"),
      key: "org_fromtime",
      dataIndex: "org_fromtime",
      width: 120,
      render: (org_fromtime, record: TaskModel) => (
        <div>
          {/* <Typography.Text>
            {org_fromtime > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={org_fromtime} />
            ) : (
              "----"
            )}
          </Typography.Text>
          <Typography.Text>{" : "}</Typography.Text> */}
          <Typography.Text>
            {record.datestart > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={record.datestart} />
            ) : (
              "----"
            )}
          </Typography.Text>
        </div>
      ),
    },

    {
      title: t("task:org_totime"),
      key: "org_totime",
      dataIndex: "org_totime",

      width: 120,
      render: (org_totime, record: TaskModel) => (
        <div>
          {/* <Typography.Text>
            {org_totime > 0 ? (
              <TextDateTime format="HH:mm, DD/MM/YYYY" ts={org_totime} />
            ) : (
              "----"
            )}
          </Typography.Text>
          <Typography.Text>{" : "}</Typography.Text> */}
          <Typography.Text>
            {record.datecompleted > 0 ? (
              <TextDateTime
                format="HH:mm, DD/MM/YYYY"
                ts={record.datecompleted}
              />
            ) : (
              "----"
            )}
          </Typography.Text>
        </div>
      ),
    },

    {
      title: t("task:list.org_complete"),
      key: "org_cost",
      dataIndex: "org_cost",
      width: 60,
      render: (_, record) => {
        return <Typography.Text>{record.org_cost} giờ</Typography.Text>;
      },
    },
    {
      title: t("task:number_task_child"),

      key: "has_child",
      dataIndex: "has_child",
      width: 100,
      render: (_, record: TaskJson) => {
        return <Typography.Text>{record.has_child}</Typography.Text>;
      },
    },
    {
      title: t("task:list.deviation"),
      key: "deviation",
      dataIndex: "deviation",
      width: 150,
      render: (deviation, record: TaskModel) => {
        const day = Math.floor(Math.abs(deviation / (3600 * 24)));
        const houre = Math.floor(Math.abs(deviation / 3600) % 24);
        const minutes = Math.round(
          Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
        );
        const is_deviation = (day || houre || minutes) !== 0;
        return (
          <>
            {is_deviation ? (
              <Tag color={deviation > 0 ? "volcano" : "green"}>
                {day > 0 && day + " ngày "}
                {houre > 0 && houre + " giờ "}
                {minutes > 0 && minutes + " phút"}
                {/* {minutes} */}
                {is_deviation ? (
                  deviation > 0 ? (
                    <IconArrowDown
                      size={14}
                      style={{ color: "#cf1322" }}
                      className="ml-1"
                    />
                  ) : (
                    <IconArrowUp
                      size={14}
                      style={{ color: "#3f8600" }}
                      className="ml-1"
                    />
                  )
                ) : (
                  ""
                )}
              </Tag>
            ) : (
              "0"
            )}
          </>
        );
      },
    },
    {
      title: t("task:list.time_pending"),
      key: "time_pending",
      dataIndex: "time_pending",
      width: 150,
      render: (deviation, record: TaskModel) => {
        const day = Math.floor(Math.abs(deviation / (3600 * 24)));
        const houre = Math.floor(Math.abs(deviation / 3600) % 24);
        const minutes = Math.round(
          Number((Math.abs(deviation / 3600) % 1).toFixed(2)) * 60
        );
        const is_deviation = (day || houre || minutes) !== 0;
        return (
          <>
            {is_deviation ? (
              <Tag color={deviation > 0 ? "volcano" : "green"}>
                {day > 0 && day + " ngày "}
                {houre > 0 && houre + " giờ "}
                {minutes > 0 && minutes + " phút"}
                {/* {minutes} */}
              </Tag>
            ) : (
              "0"
            )}

            {/* {is_deviation ? (
              deviation > 0 ? (
                <ArrowDownOutlined style={{ color: "#cf1322" }} />
              ) : (
                <ArrowUpOutlined style={{ color: "#3f8600" }} />
              )
            ) : (
              ""
            )} */}
          </>
        );
      },
    },
    {
      title: t("task:list.progress"),
      key: "complete",
      dataIndex: "complete",
      width: 100,
      render: (_, record: TaskModel) => {
        return (
          <div>
            <Progress
              percent={record.org_complete}
              status="active"
              strokeColor={{ from: "#108ee9", to: "#87d068" }}
            />
            <Progress
              percent={record.complete}
              status="active"
              strokeColor={{ from: "#f61a5e", to: "#ffcd00" }}
            />
          </div>
        );
      },
    },
    {
      title: t("task:status"),
      key: "status",
      dataIndex: "status",
      width: 70,
      render: (status) => {
        const color =
          status === 1
            ? "blue"
            : status === 2
            ? "magenta"
            : status === 3
            ? "green"
            : status === 4
            ? "volcano"
            : "cyan";
        return <Tag color={color}>{TaskModel.getStatus(status)}</Tag>;
      },
    },
    {
      title: t(""),
      key: "actions",
      width: 130,
      align: "right",
      fixed: "right",
      render: (_: any, record: TaskModel) => (
        <div>
          <Button
            type={"link"}
            size={"small"}
            style={{ whiteSpace: "break-spaces" }}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/task/add/id/${record.id}`);
            }}
          >
            Tạo
          </Button>
          {record.status === Task.STATUS.TODO ? (
            record.public === 1 ||
            record.employee_id === account.company.employee.id ? (
              <Button
                type={"link"}
                size={"small"}
                style={{ whiteSpace: "break-spaces" }}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/task/edit_request/id/${record.id}`);
                }}
              >
                {t("task:edit")}
              </Button>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <TableInfo record={record} />
        </div>
      ),
    },
  ];

  //function
  const fetchData = useCallback(async (): Promise<any> => {
    let collection = await new TaskRepository().getItems({
      filters:
        type === "current"
          ? {
              ...filters,
              employee_id: Number(pathParams.employee_id),
              department_id: -1,
              parent_id: -1,
            }
          : {
              ...filters,
              // department_id: account.company.employee.department_id,
            },
    });
    if (collection.error.errors.length > 0) {
      message.open({
        type: "error",
        content: "Có lỗi rồi !",
      });
    } else {
      if (collection.items.length > 0) {
        fetchingEmployee(collection.items.map((item) => item.employee_id));
      }
      let newArr: any[] = [];
      let current = 1;
      collection.items.forEach((i: TaskModel, index) => {
        newArr.push({
          ...i,
          index: i.id,
          key: i.id,
        });
      });

      setDataSource(newArr);
      setTotal(collection.total);
    }
    return collection;
  }, [filters, pathParams.action]);

  const fetchingEmployee = useCallback(async (id_list: number[]) => {
    const collection = await new IdFetcherRepository().doFetching({
      object_type: "employee",
      id_list,
    });

    setEmployees(
      collection.items.find((i) => i.object_type === "employee")?.result || []
    );
  }, []);

  const fetchDetailEmployee = useCallback(async (id: number) => {
    const infor = await new EmployeeRepository().getItem(id);
    if (infor.hasError()) {
      return;
    } else {
      setUserInfo(infor);
    }
  }, []);

  const onRow = (record: TaskJson, rowIndex: number) => {
    return {
      onClick: (e: any) => {}, // click row
    };
  };
  const handleSortChange = async ({
    old_index,
    new_index,
    id,
  }: {
    old_index: number;
    new_index: number;
    id: number;
  }) => {
    await new TaskRepository()
      .editDisplayOrder(id, new_index)
      .then((res) => {
        if (res.hasError()) {
          res?.error?.errors?.forEach((err: string) => {
            let errMessage = t(`task:form.error.${err}`);
            message.open({
              type: "error",
              content: errMessage,
              duration: 2,
            });
          });
        } else {
          message.open({
            type: "success",
            content: t(`task:form.success.update`),

            duration: 2,
          });
        }
      })
      .catch((err) => {
        message.open({
          type: "error",
          content: "Thay đổi thất bại!",
          duration: 2,
        });
      })
      .finally(() => {
        setCurrentPut((prev) => (prev += 1));
      });
  };

  //
  useEffect(() => {
    if (pathParams.action === "current" && pathParams.employee_id) {
      fetchDetailEmployee(Number(pathParams.employee_id));
    }
  }, [pathParams.action]);

  //
  useEffect(() => {
    // if (currentPut > 0) {
    fetchData();
    // }
  }, [currentPut]);
  return (
    <div>
      <TaskListHeader />
      <TaskListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <PageDataDragTable<FilterTask, TaskModel, TaskCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          setDataSource,
          handleSortChange,
          onRow,
          expandable: {
            expandRowByClick: true,
            onExpand(expanded, record) {
              // setVisible(record.id);
              // setDataBreadCrumb((prev) => {
              //   if ([...prev].some((i) => i.id == record.id)) {
              //     return [...prev];
              //   } else {
              //     return [...prev].concat({
              //       id: record.id,
              //       name: record.name,
              //     });
              //   }
              // });
              // setFilters({ ...filters, parent_id: record.id });

              navigate(`/task/details?parent_id=${record.id}`);
            },

            expandIcon: ({ expanded, onExpand, record }) => {
              return null;
            },
          },
        }}
      />

      <PhoneBookModal
        id={modalId}
        visible={showContactFields}
        setVisible={(isVisible) => {
          setShowContactFields(isVisible);
        }}
      />
    </div>
  );
};

export default TaskList;
