import { Col, Divider, Form, Input, Row, Switch } from 'antd';
import RegionPicker from 'components/RegionPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ApiTypeGhtk = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 p-4">
      <Row gutter={16}>
        <Col md={4} xs={24}>
          <Form.Item
            name="is_sandbox"
            label={t("shippingcarrier:form.is_sandbox")}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col md={20} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.token")}
            name="token"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">
        {t("shippingcarrier:form.setting_pick_ghtk")}
      </Divider>
      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.pick_name")}
            name="pick_name"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.pick_tel")}
            name="pick_tel"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.pick_email")}
            name="pick_email"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.pick_address")}
            name="pick_address"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            name="pick_regions"
            className="mb-0"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}
          >
            <RegionPicker className="mb-0" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ApiTypeGhtk;
