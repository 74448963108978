import { Button, Form, Input, InputNumber, message, notification } from "antd";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import { DataChangeDislayOrder } from "common/types/ProductCollection";
import React, { useState } from "react";

type Props = {
	initialValue: DataChangeDislayOrder;
	onSuccess?: () => void;
	onFailed?: () => void;
};

export default function FormEditDisplayOrder({
	initialValue,
	onSuccess,
	onFailed,
}: Props) {
	const [form] = Form.useForm();
	const [messageApi, context] = message.useMessage();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (formData: DataChangeDislayOrder) => {
		setLoading(true);
		const res =
			await new ProductCollectionRepository().editDisplayOrderProductCollection(
				formData
			);

		if (res.length <= 0) {
			messageApi.success("Thành công");
			onSuccess && onSuccess();
		} else {
			notification.error({message : "Có lỗi xảy ra vui lòng thử lại hoặc liên hệ bộ phận kỷ thuật"})
			onFailed && onFailed();
		}
		setLoading(false);
	};
	return (
		<div>
			{context}
			<Form form={form} initialValues={initialValue} onFinish={onSubmit}>
				<Form.Item hidden name={"id"}></Form.Item>
				<Form.Item
					labelCol={{
						span: 24,
					}}
					label="Độ ưu tiên hiển thị (tỷ lệ thuận với giá trị nhập)"
					name="display_order"
					rules={[
						{
							required: true,
							message: "Yêu cầu nhập",
						},
					]}>
					<InputNumber min={0}></InputNumber>
				</Form.Item>

				<Form.Item>
					<Button loading={loading} htmlType="submit" type="primary">
						Xác nhận
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
