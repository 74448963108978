import { Button } from "antd";
import ApiKeyCollection from "common/collections/ApiKeyCollection";
import Role from "common/constants/Role";
import ApiKeyModel from "common/models/ApiKeyModel";
import ApiKeyRepository from "common/repositories/ApiKeyRepository";
import { FilterApiKey } from "common/types/ApiKey";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import ApiKeyFormModal from "features/apikey/form/ApiKeyFormModal";
import ApiKeyListHeader from "features/apikey/list/ApiKeyListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ApiKeyListFilter from "./ApiKeyListFilter";

const ApiKeyList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterApiKey = useMemo(
    () => ApiKeyRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterApiKey>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ApiKeyModel[]>([]);
  const fetchData = useCallback(async (): Promise<ApiKeyCollection> => {
    let collection = await new ApiKeyRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
    return collection;
  }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //Table columns
  const columns: TableColumnsType<ApiKeyModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id, record) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(id)}>
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("apikey:name"),
      key: "name",
      sortby: "name",
    },
    {
      title: t("apikey:type"),
      width: 100,
      key: "type",
      toggletype: "extend",
      render: (type: number) => {
        let typeInfo = ApiKeyModel.getType(type);
        return <>{typeInfo?.label}</>;
      },
    },
    {
      title: t("apikey:api_key"),
      key: "api_key",
    },
    {
      title: t("apikey:description"),
      key: "description",
      toggletype: "extend",
    },
    {
      title: t("common:status"),
      key: "status",
      sortby: "status",
      render: (status: number) => {
        let statusInfo = ApiKeyModel.getStatus(status);
        return <>{statusInfo?.label}</>;
      },
      width: 110,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: ApiKeyModel) => (
        <RoleCheck roles={[Role.SETTING_ADVANCED]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="apikey:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ApiKeyRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: ApiKeyModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        }),
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $unshift: [item],
        }),
      );
    }
  };

  return (
    <>
      <ApiKeyListHeader>
        <RoleCheck roles={[Role.SETTING_ADVANCED]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}>
            {t("common:table.add_button")}
          </Button>
        </RoleCheck>
      </ApiKeyListHeader>
      <ApiKeyListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<FilterApiKey, ApiKeyModel, ApiKeyCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <ApiKeyFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ApiKeyList;
