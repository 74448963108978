import BaseCollection from "common/collections/BaseCollection";
import { Filter } from "common/types/Filter";
import { cn } from "common/utils/utils";
import React, { ReactNode, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

const PageSortList = ({
  // defaultFilters,
  // children,
  // filters,
  // setFilters,
  dataSource,
  className,
  listKey,
  // fetchData,
  renderListItem,
  handleDragEnd,
}: {
  // defaultFilters: F;
  // filters: F;
  // setFilters: (f: F) => void;
  className?: string
  dataSource: any[];
  // fetchData: () => Promise<C>;
  listKey: string;
  children?: React.ReactNode;
  renderListItem: (item: any, index: number) => ReactNode;
  handleDragEnd: (result: DropResult) => void;
}) => {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems(
      dataSource.map((item, index) => {
        return { ...item, id: item.id ? item.id + "" : undefined };
      })
    );
  }, [dataSource]);

  const handleDragEndComponent = (result: DropResult) => {
    handleDragEnd(result);

    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setItems(reorderedItems);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEndComponent} >
        <Droppable droppableId={listKey}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={cn("flex flex-col gap-2" , className)}
            >
              {items.map((item, index) => (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="shadow-sm"
                      style={{
                        userSelect: "none",
                        backgroundColor: "white",
                        ...provided.draggableProps.style,
                      }}
                    >
                      {renderListItem(item, index)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default PageSortList;
