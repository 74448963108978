import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import CompanyResourceCollection from "common/collections/CompanyResourceCollection";

const SERVICE_URL = "/companyresources";

class CompanyResourceApi extends BaseApi {
  async getList({
    companyId,
  }: {
    companyId: number;
  }): Promise<CompanyResourceCollection> {
    let collection = new CompanyResourceCollection();

    try {
      let queryData = {
        params: {
          company_id: companyId,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }
}

export default CompanyResourceApi;
