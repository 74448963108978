import { Form, Input, Space, TimePicker } from "antd";
import { Color } from "antd/es/color-picker";
import WorkTrackingRangeModel from "common/models/WorkTrackingRangeModel";
import { WorkTrackingRangeJsonAddEdit } from "common/types/WorkTrackingRange";
import CustomColorPicker from "components/color/CustomColorPicker";
import FormSelect from "components/form/FormSelect";
import React from "react";
import { useTranslation } from "react-i18next";

const TIME_FORMAT = "HH:mm";

interface EditableCellProps {
  title: React.ReactNode;
  inputType: string;
  editing: boolean;
  children: React.ReactNode;
  require: boolean;
  dataIndex: string;
  record: WorkTrackingRangeJsonAddEdit;
}

const WorkTrackingRangeFormTableCell: React.FC<EditableCellProps> = ({
  title,
  inputType,
  editing,
  children,
  dataIndex,
  require,
  record,
  ...restProps
}) => {
  const { t } = useTranslation();
  const getInput = (dataIndex: string) => {
    switch (inputType) {
      case "time_picker":
        return (
          <Form.Item
            name={"time_picker"}
            style={{ margin: 0 }}
            rules={[
              {
                required: require ? true : false,
                message: t(
                  "worktrackingrange:form.error.error_time_start_more_than_time_end"
                )
              }
            ]}>
            <TimePicker.RangePicker format={TIME_FORMAT} changeOnBlur />
          </Form.Item>
        );

      case "select":
        return (
          <div style={{ paddingTop: "24px" }}>
            <FormSelect
              name="status"
              rules={[
                {
                  required: require ? true : false
                }
              ]}
              options={WorkTrackingRangeModel.getStatusList()}
            />
          </div>
        );

      case "color_picker":
        return (
          <>
            <Space>
              <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                  {
                    required: require ? true : false,
                    message: t(
                      `worktrackingrange:form.error.required_${dataIndex}`
                    )
                  }
                ]}>
                <CustomColorPicker format="hex" />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.gender !== currentValues.gender
                }>
                {({ getFieldValue }) => {
                  let colorHex: Color | string = getFieldValue(dataIndex);
                  let displayColor =
                    typeof colorHex === "string"
                      ? colorHex
                      : colorHex.toHexString();
                  return <span>{displayColor}</span>;
                }}
              </Form.Item>
            </Space>
          </>
        );

      default:
        return (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: require ? true : false,
                message: t(`worktrackingrange:form.error.required_${dataIndex}`)
              }
            ]}>
            <Input />
          </Form.Item>
        );
    }
  };

  return <td {...restProps}>{editing ? getInput(dataIndex) : children}</td>;
};

export default WorkTrackingRangeFormTableCell;
