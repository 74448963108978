// import { DiffFilter, Filter } from 'common/types/Filter';
// import { diff } from 'deep-object-diff';
// import qs from 'qs';
// import { useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// const useFilterLocation = <F extends Filter>(
//   defaultFilters: F,
//   filters: F
// ): [DiffFilter] => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   //tracking change
//   const [diffFilter, setDiffFilter] = useState<DiffFilter>({});

//   //when filters changed, we need to calculate the diffFitler
//   useEffect(() => {
//     setDiffFilter(diff(defaultFilters, filters) as DiffFilter);
//   }, [filters, defaultFilters]);

//   //check diffFilter and change URL
//   useEffect(() => {
//     const newSearch =
//       Object.keys(diffFilter).length > 0 ? "?" + qs.stringify(diffFilter) : "";
//     if (newSearch !== location.search) {
//       //when navigate() by filter, we replace current
//       navigate(location.pathname + newSearch, {
//         replace: true,
//         state: {
//           ignoreUpdateFilters: true,
//         },
//       });
//     }
//   }, [diffFilter, location.search, location.pathname, navigate]);

//   return [diffFilter];
// };

// export default useFilterLocation;

import { DiffFilter, Filter } from "common/types/Filter";
import Helper from "common/utils/helper";
import { diff } from "deep-object-diff";
import qs from "qs";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useFilterLocation = <F extends Filter>(
	defaultFilters: F,
	filters: F,
	isConvertParams?: boolean
): [DiffFilter] => {
	const navigate = useNavigate();
	const location = useLocation();

	// Use ref to store defaultFilters for stable reference
	const defaultFiltersRef = useRef<F>(defaultFilters);

	// tracking change
	const [diffFilter, setDiffFilter] = useState<DiffFilter>({});

	// when filters changed, calculate the diffFilter if it changes
	useEffect(() => {
		const newDiffFilter = diff(
			defaultFiltersRef.current,
			// filters
			isConvertParams ? Helper.convertParams(filters, true) : filters
		) as DiffFilter;
		if (JSON.stringify(newDiffFilter) !== JSON.stringify(diffFilter)) {
			setDiffFilter(newDiffFilter);
		}
	}, [filters, diffFilter]);

	// check diffFilter and change URL
	useEffect(() => {
		const newSearch =
			Object.keys(diffFilter).length > 0 ? "?" + qs.stringify(diffFilter, { encodeValuesOnly: true }) : "";

		if (newSearch !== location.search) {
			navigate(location.pathname + newSearch, {
				replace: true,
				state: {
					ignoreUpdateFilters: true,
				},
			});
		}
	}, [diffFilter, location.search, location.pathname, navigate]);

	return [diffFilter];
};

export default useFilterLocation;
