import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { List, Skeleton } from "antd";
import { DeliveryJson, FilterDelivery } from "common/types/Delivery";
import { useTranslation } from "react-i18next";
import useStateFilter from "hooks/useStateFilter";
import ErrorAlert from "components/ErrorAlert";

import DeliveryMineItem from "./component/DeliveryMineItem";
import DeliveryModel from "common/models/DeliveryModel";
import DeliveryRepository from "common/repositories/DeliveryRepository";
import DeliveryMineListFilter from "./DeliveryMineListFilter";

type Data = DeliveryJson & {
  loading?: boolean;
};

type Props = {
  isComplete: number;
};
const DeliveryMineListRender = ({ isComplete }: Props) => {
  const { t } = useTranslation();
  const defaultFilters = useMemo(() => {
    return DeliveryRepository.getDefaultFilters();
  }, []);
  const [filters, setFilters] = useStateFilter(defaultFilters);

  const [dataSource, setDataSource] = useState<Data[]>(
    Array.from({ length: 10 }).map(() => ({
      ...DeliveryModel.getDefaultData(),
      loading: true,
    }))
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(async () => {
    const response = await new DeliveryRepository().getItemsDeilveryShipperView(
      {
        filters: {
          ...filters,
          is_complete: isComplete,
        },
      }
    );
    if (!response.hasError()) {
      setTotal(response.total);
      setDataSource(response.items);
    } else {
      setErrors(response.error.errors);
    }
  }, [filters, isComplete]);

  //useEffect
  useEffect(() => {
    fetchData();
  }, [filters, isComplete]);
  return (
    <div className="pb-1 px-1">
      {errors.length > 0 ? (
        <ErrorAlert
          items={errors}
          heading={t("common:errors.fetch_api")}
          translate_prefix="delivery.errors"
        />
      ) : (
        <>
          <DeliveryMineListFilter
            total={total}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
          />
          <List
            className="mt-1"
            dataSource={dataSource}
            grid={{
              gutter: 8,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 4,
            }}
            pagination={{
              size: "small",
              hideOnSinglePage: true,
              style: { margin: 0, position: "sticky", bottom: 0, right: 0 },
              onChange: (page) => {
                setFilters({ ...filters, page: page });
              },
              pageSize: 10,
              total: total,
            }}
            renderItem={(item) => {
              return (
                <List.Item style={{ marginBlockEnd: 4 }}>
                  <Skeleton
                    loading={item.loading}
                    active
                    className="w-full h-[200px]"
                  >
                    <DeliveryMineItem is_complete={isComplete} item={item} />
                  </Skeleton>
                </List.Item>
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default memo(DeliveryMineListRender);
