import IdFetcherApi from "common/api/IdFetcherApi";
import IdFetcherCollection from "common/collections/IdFetcherCollection";
import BaseRepository from "./BaseRepository";
import type { FetchingRequest } from "common/types/IdFetcher";

class IdFetcherRepository extends BaseRepository<IdFetcherApi> {
  _api: IdFetcherApi | null;

  constructor() {
    super();
    this._api = new IdFetcherApi(true);
  }

  async doFetching(props: FetchingRequest) {
    return this._api
      ? await this._api.doFetching(props)
      : new IdFetcherCollection();
  }
}

export default IdFetcherRepository;
