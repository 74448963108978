import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";

import PageHeader from "components/page/PageHeader";

const AvatarFrameHeader = () => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:tool"),
      link: "",
    },
    {
      text: t("tool:heading_avatar_frame"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t("tool:heading_avatar_frame")}
      nav={nav}
      siteMenuOpenKey="tool"
      siteMenuSelectedKey="/tool/avatarframe"
    ></PageHeader>
  );
};

export default AvatarFrameHeader;
