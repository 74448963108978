import { Avatar, Tag, Tooltip } from "antd";
import ProjectTaskCollection from "common/collections/ProjectTaskCollection";
import ProjectTask from "common/constants/ProjectTask";
import Role from "common/constants/Role";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectTaskRepository from "common/repositories/ProjectTaskRepository";
import { FilterProjectTask } from "common/types/ProjectTask";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableInfoRow from "components/table/TableInfoRow";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextTagList from "components/TextTagList";
import UserIdAvatar from "components/UserIdAvatar";
import dayjs, { Dayjs } from "dayjs";
import useFilterLocation from "hooks/useFilterLocation";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import {
  IconAlertTriangle,
  IconMessages,
  IconPencil,
} from "@tabler/icons-react";

const ProjectTaskList = ({
  fetchData,
  dataSource,
  total,
  setDataSource,
  projectIterationItems,
  projectModel,
  filters,
  setFilters,
  setEditingTaskId,
  setIsModalAddEditTaskOpen,
  setViewingTask,
  onViewProjectTaskFormUpdateStatus,
}: {
  fetchData: () => Promise<ProjectTaskCollection>;
  dataSource: ProjectTaskModel[];
  total: number;
  setDataSource: (v: ProjectTaskModel[]) => void;
  projectIterationItems: ProjectIterationModel[];
  projectModel: ProjectModel;
  filters: FilterProjectTask;
  setFilters: (v: FilterProjectTask) => void;
  setEditingTaskId: (v: number) => void;
  setIsModalAddEditTaskOpen: (v: boolean) => void;
  setViewingTask: (v: ProjectTaskModel) => void;
  onViewProjectTaskFormUpdateStatus: (v: number) => void;
}) => {
  const { t } = useTranslation();
  const account = useLoginAccountStore((state) => state.account);

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterProjectTask = useMemo(
    () => ProjectTaskRepository.getDefaultFilters(),
    []
  );
  useFilterLocation(defaultFilters, filters);

  const getOutOfDay = (now: Dayjs, end: Dayjs) => {
    var format = "";
    var outOfDate = now.diff(end, "days");

    if (outOfDate > 0) {
      if (outOfDate > 30) {
        outOfDate = now.diff(end, "months");
        if (outOfDate > 12) {
          outOfDate = now.diff(end, "years");
          format =
            t("projecttask:late") +
            " " +
            outOfDate +
            " " +
            t("projecttask:year");
        } else {
          format =
            t("projecttask:late") +
            " " +
            outOfDate +
            " " +
            t("projecttask:month");
        }
      } else {
        format =
          t("projecttask:late") + " " + outOfDate + " " + t("projecttask:day");
      }
    } else {
      format = "projecttask:còn " + outOfDate * -1 + " " + t("projecttask:day");
    }

    return format;
  };

  const checkDurationAndOutOfDay = (record: ProjectTaskModel) => {
    let durationDay = 1;
    let outOfDate = false;
    let outOfDateString = "";

    if (
      record.status !== ProjectTask.STATUS_COMPLETED &&
      record.status !== ProjectTask.STATUS_CANCELLED
    ) {
      if (record.date_completed && record.date_completed > 0) {
        let dateEnd = dayjs.unix(record.date_completed);
        let now = dayjs();
        outOfDateString = getOutOfDay(now, dateEnd);
        outOfDate = now.diff(dateEnd, "days") > 0 ? true : false;

        if (record.date_started && record.date_started > 0) {
          let dateStarted = dayjs.unix(record.date_started);
          if (dateStarted.format("DDMMYYYY") !== dateEnd.format("DDMMYYYY")) {
            durationDay = dateEnd.diff(dateStarted, "days");
          }
        } else {
          durationDay = 0;
        }
      }
    }

    return {
      durationDay: durationDay,
      outOfDate: outOfDate,
      outOfDateString: outOfDateString,
    };
  };

  const canAddEditProjectTask = useMemo(() => {
    let canAdd = false;

    let uid = account.id;
    let rolePermission = projectModel.role_permission;
    let developmentTeam = projectModel.development_team;
    let scrumMaster = projectModel.scrum_master;

    for (var i = 0; i < developmentTeam.length; i++) {
      if (
        uid === developmentTeam[i] &&
        rolePermission.development_team_add_story === 1
      ) {
        canAdd = true;
        break;
      }
    }

    for (var j = 0; j < scrumMaster.length; j++) {
      if (
        uid === scrumMaster[j] &&
        rolePermission.scrum_master_add_story === 1
      ) {
        canAdd = true;
        break;
      }
    }
    return canAdd;
  }, [account.id, projectModel]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ProjectTaskModel> = [
    {
      title: t("projecttask:code"),
      key: "code",
      align: "right",
      width: 130,
      render: (_: any, record: ProjectTaskModel) => {
        return (
          <span className="block whitespace-nowrap">
            {checkDurationAndOutOfDay(record).outOfDate &&
            record.status !== ProjectTask.STATUS_COMPLETED &&
            record.status !== ProjectTask.STATUS_CANCELLED ? (
              <Tooltip
                title={checkDurationAndOutOfDay(record).outOfDateString}
                mouseEnterDelay={0.4}
              >
                <span className="text-red-500">
                  <IconAlertTriangle size={14} className="mr-1" />
                </span>
              </Tooltip>
            ) : null}
            {record.code}
          </span>
        );
      },
    },
    {
      title: t("projecttask:name"),
      key: "name",
      render: (name, record) => {
        return (
          <>
            <TableLinkText onClick={() => setViewingTask(record)}>
              {record.name}
            </TableLinkText>

            {record.count_comment > 0 ? (
              <Tag
                className="ml-1"
                icon={<IconMessages size={14} color="gray" />}
              >
                {" "}
                {record.count_comment}
              </Tag>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: t("projecttask:tag"),
      key: "tags",
      width: 200,
      render: (_: any, record: ProjectTaskModel) => {
        return (
          <>
            {record.tags.length > 0 ? (
              <TextTagList<FilterProjectTask>
                tag={record.tags}
                filters={filters}
                filterName={"tag"}
                setFilters={setFilters}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: t("projecttask:iteration"),
      key: "project_iteration_id",
      width: 140,
      render: (_: any, record: ProjectTaskModel) => {
        let findIterration = projectIterationItems.find(
          (i) => i.id === record.project_iteration_id
        );
        return (
          <>
            {findIterration && findIterration.id > 0
              ? findIterration.name
              : t("projecttask:un_milestone")}
          </>
        );
      },
    },
    {
      title: t("projecttask:type"),
      key: "type",
      width: 100,
      render: (_: any, record: ProjectTaskModel) => {
        let typeInfo = ProjectTaskModel.getType(record.type);
        return <span>{typeInfo?.label}</span>;
      },
    },
    {
      title: t("projecttask:assignees"),
      key: "assignees",
      width: 120,
      render: (_: any, record: ProjectTaskModel) =>
        record.assignees !== "" ? (
          <Avatar.Group maxCount={5} size="small">
            {record.assignees.split(",").map((i) => (
              <UserIdAvatar key={i} id={+i} hideTooltip={false} />
            ))}
          </Avatar.Group>
        ) : null,
    },
    {
      title: t("common:status"),
      key: "status",
      width: 150,
      render: (status: number) => {
        let statusInfo = ProjectTaskModel.getStatus(status);
        let color = "";
        switch (status) {
          case ProjectTask.STATUS_PENDING:
            color = "#e798ba";
            break;
          case ProjectTask.STATUS_ASSIGNED:
            color = "#3498db";
            break;
          case ProjectTask.STATUS_WORKING:
            color = "#2980b9";
            break;
          case ProjectTask.STATUS_NEED_FOR_APPROVAL:
            color = "#ac0";
            break;
          case ProjectTask.STATUS_REVIEWING:
            color = "#8e44ad";
            break;
          // case ProjectTask.STATUS_TESTING:
          //   color = "#8e44ad";
          //   break;
          case ProjectTask.STATUS_COMPLETED_WITH_BUG:
            color = "#CC4C9A";
            break;
          case ProjectTask.STATUS_COMPLETED:
            color = "#2ecc71";
            break;
          case ProjectTask.STATUS_CANCELLED:
            color = "#7f8c8d";
            break;
        }
        return <Tag color={color}>{statusInfo?.label}</Tag>;
      },
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 200,
      render: (_: any, record: ProjectTaskModel) => {
        return (
          <>
            {record.can_update_status === 1 ? (
              <TableEdit
                link=""
                tooltip={t("projecttask:update_status_title")}
                label={t("projecttask:update_status")}
                onClick={() => onViewProjectTaskFormUpdateStatus(record.id)}
              />
            ) : null}

            {canAddEditProjectTask ? (
              <TableEdit
                link=""
                label=""
                tooltip={t("projecttask:heading_edit")}
                icon={
                  <IconPencil
                    className="-mt-0.5 text-gray-400 hover:text-blue-500"
                    size="18"
                  />
                }
                onClick={() => onEditProjectTaskForm(record.id)}
              />
            ) : null}

            <RoleCheck roles={[Role.PROJECT_MANAGE]} hideOnFail>
              <TableDelete
                error_translate_prefix="projecttask:form.error"
                onDeleteCallback={(id) => {
                  setDataSource(dataSource.filter((item) => item.id !== id));
                }}
                repository={new ProjectTaskRepository()}
                id={record.id}
              />
            </RoleCheck>

            <TableInfo record={record}>
              <TableInfoRow
                label={t("common:date_started")}
                content={
                  <>
                    {record.date_started ? (
                      <TextDateTime
                        format="DD/MM/YYYY"
                        ts={record.date_started}
                      />
                    ) : (
                      "---"
                    )}
                  </>
                }
              />

              <TableInfoRow
                label={t("common:date_completed")}
                content={
                  <>
                    {record.date_completed ? (
                      <TextDateTime
                        format="DD/MM/YYYY"
                        ts={record.date_completed}
                      />
                    ) : (
                      "---"
                    )}
                  </>
                }
              />
            </TableInfo>
          </>
        );
      },
    },
  ];

  const onEditProjectTaskForm = (id: number) => {
    setEditingTaskId(id);
    setIsModalAddEditTaskOpen(true);
  };

  return (
    <>
      <PageDataTable<FilterProjectTask, ProjectTaskModel, ProjectTaskCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "projecttask",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ProjectTaskList;
