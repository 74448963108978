import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RbacRoleUserHeader = ({
  children,
  full_name,
}: {
  children: React.ReactNode;
  full_name: string;
}) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("setting:menu.permission"),
      link: "/role",
    },
  ];

  return (
    <PageHeader
      nav={nav}
      heading={t("role:heading_user", { full_name })}
      siteMenuSelectedKey="/role"
      siteMenuOpenKey="setting_hrm"
    >
      {children}
    </PageHeader>
  );
};

export default RbacRoleUserHeader;
