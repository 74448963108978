import { Tooltip } from 'antd';
import CashflowReceiptModel from 'common/models/CashflowReceiptModel';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CashflowReceiptItemTargetSupplier = ({
  model,
}: {
  model: CashflowReceiptModel;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip
        title={t("cashflowreceipt:TARGET_SUPPLIER")}
        mouseEnterDelay={0.4}
      >
        <span className="px-1 py-0.5 text-xs font-semibold mr-1 bg-blue-500 rounded text-white">
          {t("cashflowreceipt:target.supplier_short")}
        </span>
      </Tooltip>
      {model.target_id > 0 ? (
        <Link
          to={"/supplier/detail/id/" + model.target_id}
          target="_blank"
          className=""
        >
          {model.target_label.length > 0 ? (
            model.target_label
          ) : (
            <>ID #{model.target_id}</>
          )}
        </Link>
      ) : (
        "n/a"
      )}
    </>
  );
};

export default CashflowReceiptItemTargetSupplier;
