import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingRepository from "common/repositories/ProductInventoryCheckingRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import ProductInventoryCheckingUserForm from "features/productinventorycheckinguser/form/ProductInventoryCheckingUserForm";
import ProductInventoryCheckingUserFormHeader from "features/productinventorycheckinguser/form/ProductInventoryCheckingUserFormHeader";
import ProductInventoryCheckingUserList from "features/productinventorycheckinguser/list/ProductInventoryCheckingUserList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const ProductInventoryCheckingUserPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<ProductInventoryCheckingModel>(
    new ProductInventoryCheckingModel(
      ProductInventoryCheckingModel.getDefaultData()
    )
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(
      new ProductInventoryCheckingModel(
        ProductInventoryCheckingModel.getDefaultData()
      )
    );
    setFetchErrors([]);
  }, [pathParams.action]);

  async function fetchData() {
    let fetchedModel = await new ProductInventoryCheckingRepository().getItem(
      parseInt(pathParams.id.toString())
    );
    if (fetchedModel.hasError()) {
      setFetchErrors(fetchedModel.error.errors);
    } else {
      setModel(fetchedModel);
    }
  }

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <ProductInventoryCheckingUserForm

          model={
            new ProductInventoryCheckingModel({
              ...ProductInventoryCheckingModel.getDefaultData(),
              warehouse_id: +pathParams.warehouse_id,
            })
          }
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ProductInventoryCheckingUserForm model={model} refetch={fetchData}/>
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              await fetchData()
            }}
            errors={fetchErrors}
            heading={
              <ProductInventoryCheckingUserFormHeader
                isEditing={true}
                id={+pathParams.id}
              />
            }
          />
        );

      break;
    default:
      com = <ProductInventoryCheckingUserList />;
  }

  return com;
};

export default ProductInventoryCheckingUserPage;
