import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const DeliveryPlanningListTotal = ({ total }: { total: number }) => {
  const { t } = useTranslation();

  return (
    <div className={"px-6 bg-gray-100 pb-0 absolute -mt-0.5"}>
      <div>
        <div className="">
          <div className="hidden lg:block">
            <nav className="flex -mb-px space-x-4" aria-label="Tabs">
              <span
                className={
                  "whitespace-nowrap flex pt-2 pb-1 px-4 border-b-2 font-medium text-sm items-center rounded-t-md " +
                  "border-b-0 text-black bg-gray-200"
                }
              >
                {t("deliveryplanning:tab_result_text_1")}{" "}
                <span className="mx-1 text-lg font-bold">{total}</span>
                {t("deliveryplanning:tab_result_text_2")}
                <Tooltip
                  title={t("deliveryplanning:tab_result_text_3_tooltip")}
                >
                  &nbsp;
                  <abbr className="border-b-2 border-black border-dotted">
                    {t("deliveryplanning:tab_result_text_3")}
                  </abbr>
                </Tooltip>
              </span>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPlanningListTotal;
