import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import type { QueryPlaceProps } from "common/types/MapServiceAutoCompletePrediction";
import type { QueryPlaceDetailProps } from "common/types/MapServicePlace";
import MapServiceAutocompletePredictionCollection from "common/collections/MapServiceAutoCompletePredictionCollection";
import MapServicePlaceModel from "common/models/MapServicePlaceModel";

const SERVICE_URL = "/mapservices";

class MapServiceApi extends BaseApi {
  async queryPlaceAutocomplete(
    props: QueryPlaceProps
  ): Promise<MapServiceAutocompletePredictionCollection> {
    let collection = new MapServiceAutocompletePredictionCollection();

    try {
      let queryData = {
        params: {
          service: props.service,
          query: props.query,
          autocomplete_uuid: props.session_token,
        },
      };
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/placeautocomplete",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    //testing
    // collection.items = [];

    return collection;
  }

  async queryPlaceDetail(
    props: QueryPlaceDetailProps
  ): Promise<MapServicePlaceModel> {
    let item = new MapServicePlaceModel(MapServicePlaceModel.getDefaultData());

    try {
      let queryData = {
        params: {
          service: props.service,
          place_id: props.place_id,
          autocomplete_uuid: props.session_token,
        },
      };
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/placedetail",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        item = new MapServicePlaceModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default MapServiceApi;
