import ApiKey from 'common/constants/ApiKey';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { ApiKeyJson } from "common/types/ApiKey";

class ApiKeyModel extends BaseModel implements BaseModelJson<ApiKeyJson> {
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  type: number;
  api_key: string;
  api_secret: string;
  description: string;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: ApiKeyJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.type = json.type || 0;
    this.api_key = json.api_key || "";
    this.api_secret = json.api_secret || "";
    this.description = json.description || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): ApiKeyJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      type: 0,
      api_key: "",
      api_secret: "",
      description: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): ApiKeyJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      type: this.type,
      api_key: this.api_key,
      api_secret: this.api_secret,
      description: this.description,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ApiKey.STATUS_ENABLE,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: ApiKey.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
      {
        value: ApiKey.STATUS_LOCKED,
        label: i18n.t("apikey:STATUS_LOCKED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: ApiKey.TYPE_PRIVATE,
        label: i18n.t("apikey:TYPE_PRIVATE"),
      },
      {
        value: ApiKey.TYPE_PUBLIC,
        label: i18n.t("apikey:TYPE_PUBLIC"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }
}

export default ApiKeyModel;
