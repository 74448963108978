import DmsBanner from 'common/constants/DmsBanner';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';
import FileModel from './FileModel';

import type { DmsBannerJson } from "common/types/DmsBanner";
class DmsBannerModel extends BaseModel implements BaseModelJson<DmsBannerJson> {
  creator_id: number;
  company_id: number;
  id: number;
  file_id: number;
  image: FileModel;
  link: string;
  caption: string;
  position: number;
  status: number;
  date_created: number;
  date_modified: number;

  constructor(json: DmsBannerJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.file_id = json.file_id || 0;
    this.image = new FileModel(json.image || FileModel.getDefaultData());
    this.link = json.link || "";
    this.caption = json.caption || "";
    this.position = json.position || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): DmsBannerJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      file_id: 0,
      image: new FileModel(FileModel.getDefaultData()),
      link: "",
      caption: "",
      position: 0,
      status: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): DmsBannerJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      file_id: this.file_id,
      image: this.image,
      link: this.link,
      caption: this.caption,
      position: this.position,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: DmsBanner.STATUS_ENABLED,
        label: i18n.t("common:STATUS_ENABLE"),
        color: "green",
      },
      {
        value: DmsBanner.STATUS_DISABLED,
        label: i18n.t("common:STATUS_DISABLED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }

  static getTypeList(): SelectOption[] {
    return [
      {
        value: DmsBanner.TYPE_LOGO,
        label: i18n.t("dmsbanner:TYPE_LOGO"),
      },
      {
        value: DmsBanner.TYPE_HOMEPAGE_COVER,
        label: i18n.t("dmsbanner:TYPE_HOMEPAGE_COVER"),
      },
      {
        value: DmsBanner.TYPE_GALLERY,
        label: i18n.t("dmsbanner:TYPE_GALLERY"),
      },
    ];
  }

  static getType(value: number): SelectOption | undefined {
    return this.getTypeList().find((item) => item.value === value);
  }
}

export default DmsBannerModel;
