import BaseCollection from "common/collections/BaseCollection";
import DmsNewsModel from "common/models/DmsNewsModel";
import { DmsNewsJson, DmsNewsCollectionJson } from "common/types/DmsNews";

class DmsNewsCollection extends BaseCollection<
  DmsNewsJson,
  DmsNewsModel,
  DmsNewsCollectionJson
> {
  itemsFromJson(jsonItems: DmsNewsJson[]): DmsNewsModel[] {
    return jsonItems.map((item) => new DmsNewsModel(item));
  }
}

export default DmsNewsCollection;


