import { Col, Row } from "antd";
import SupplierModel from "common/models/SupplierModel";
import SupplierRepository from "common/repositories/SupplierRepository";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React, { useCallback, useEffect, useState } from "react";

const ReportCashflowReceiptIncomePartnerSupplier = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const [supplierItems, setSupplierItems] = useState<SupplierModel[]>([]);
  const fetchSupplierData = useCallback(async () => {
    const collection = await new SupplierRepository().getItems({
      filters: SupplierRepository.getDefaultFilters()
    });
    if (!collection.hasError()) {
      setSupplierItems(collection.items);
    }
  }, []);
  useEffect(() => {
    fetchSupplierData();
  }, [fetchSupplierData]);

  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col flex={1}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo phiếu thu theo nhà cung cấp"}
              subtitle="Chỉ tính dựa trên phiếu thu chưa thanh toán và đã thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhà cung cấp",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {supplierItems.find((item) => item.id === value)
                          ?.name || "--"}
                      </>
                    );
                  },
                  sortField: "targetid"
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 5,status < 2,target = 5"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền cần thu theo nhà cung cấp"}
              subtitle="Chỉ tính các phiếu thu có trạng thái chưa thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhà cung cấp",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {supplierItems.find((item) => item.id === value)
                          ?.name || "--"}
                      </>
                    );
                  },
                  sortField: "targetid"
                },
                {
                  title: "Tiền cần thu",
                  name: "sum_value",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(value)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 5,status = 0,target = 5"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Số tiền đã thu theo nhà cung cấp"}
              subtitle="Chỉ tính các phiếu thu có trạng thái đã thanh toán"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhà cung cấp",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {supplierItems.find((item) => item.id === value)
                          ?.name || "--"}
                      </>
                    );
                  }
                },
                {
                  title: "Tiền đã thu",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 5,status = 1,target = 5"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Giá trị phiếu thu hủy theo nhà cung cấp"}
              subtitle="Chỉ tính các phiếu thu có trạng thái hủy"
              dataTable="cashflowreceipt"
              dataService="cashflowreceipt"
              keyMapping={[
                {
                  title: "Nhà cung cấp",
                  name: "targetid",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {supplierItems.find((item) => item.id === value)
                          ?.name || "--"}
                      </>
                    );
                  }
                },
                {
                  title: "Tổng số tiền",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Số phiếu",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), targetid, sum(value)"
              dataFilter="direction = 5,status = 2,target = 5"
              dataGroupBy="targetid"
              dataOrderBy="sum(value) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCashflowReceiptIncomePartnerSupplier;
