import PosCartModel from 'common/models/PosCartModel';
import { PosCartCustomerPoint } from 'common/types/PosCart';

import AxiosClient from './AxiosClient';
import BaseApi from './BaseApi';

const SERVICE_URL = "/customerhelpers";

class CustomerHelperApi extends BaseApi {
  async getPointForPos(customer_id: number): Promise<PosCartCustomerPoint> {
    let result = PosCartModel.getDefaultCartCustomerPoint();

    try {
      let queryData = {
        params: {
          customer_id,
        },
      };

      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/point",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        result.point = response.data.point || 0;
        result.point_exchange = response.data.point_exchange || 0;
      }
    } catch (error) {}

    return result;
  }
}

export default CustomerHelperApi;
