import { Button, Form } from "antd";
import React from "react";

const FormSubmit = ({
  icon,
  text,
  loading,
  disabled,
  hideSubmitButton,
}: {
  icon: React.ReactNode;
  text: string;
  loading: boolean;
  disabled?: boolean;
  hideSubmitButton?: boolean;
}) => {
  return (
    <>
      {!hideSubmitButton ? (
        <Form.Item className="mg-b-0">
          <Button
            icon={icon}
            size="large"
            block
            type="primary"
            loading={loading}
            htmlType="submit"
            disabled={disabled}
          >
            {text}
          </Button>
        </Form.Item>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormSubmit;
