import DataGridWorkspaceModel from "common/models/DataGridWorkspaceModel";
import DataGridWorkspaceRepository from "common/repositories/DataGridWorkspaceRepository";
import ErrorAlert from "components/ErrorAlert";
import PageHeader from "components/page/PageHeader";
import NotFoundPage from "pages/NotFoundPage";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DataGridTableList from "./table/DataGridTableList";

const DataGridWorkspaceHome = ({ workspaceId }: { workspaceId: number }) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<DataGridWorkspaceModel>(
    new DataGridWorkspaceModel(DataGridWorkspaceModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  const [isNotFound, setIsNotFound] = useState<boolean>();

  const fetchWorkspace = useCallback(async () => {
    let fetchedModel = await new DataGridWorkspaceRepository().getItem(
      workspaceId
    );
    if (fetchedModel.hasError()) {
      //for not found error, just show error page
      if (fetchedModel.error.statusCode === 404) {
        setIsNotFound(true);
      } else {
        setErrors(fetchedModel.error.errors);
      }
    } else {
      setModel(fetchedModel);
      setErrors([]);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (workspaceId > 0) {
      fetchWorkspace();
    } else {
      setIsNotFound(true);
    }
  }, [workspaceId, fetchWorkspace]);

  return (
    <div>
      {typeof isNotFound !== "undefined" && isNotFound ? (
        <NotFoundPage />
      ) : (
        <div>
          {errors.length > 0 ? (
            <ErrorAlert
              translate_prefix="datagridtable:form.error"
              items={errors}
            />
          ) : null}
          <div>
            {model.id > 0 ? (
              <div>
                <PageHeader heading={"Worskspace: " + model.name} />
                <div className="p-6">
                  <h3 className="text-gray-500">
                    {t("datagridtable:active_table_empty")}
                  </h3>
                  <div>
                    <DataGridTableList workspaceId={model.id} />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataGridWorkspaceHome;
