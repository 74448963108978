import {
	App,
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
} from "antd";
import Promotion from "common/constants/Promotion";
import PromotionModel from "common/models/PromotionModel";
import PromotionRepository from "common/repositories/PromotionRepository";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import dayjs from "dayjs";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import PromotionFormLoyalty from "./PromotionFormLoyalty";
import PromotionFormPermanent from "./PromotionFormPermanent";
import PromotionFormSeasonal from "./PromotionFormSeasonal";

import type { PromotionJsonAddEdit } from "common/types/Promotion";
import MoneyInput from "components/MoneyInput";
const { RangePicker } = DatePicker;
const DATE_FORMAT = "DD/MM/YYYY HH:mm";

const PromotionForm = ({
	model,
	setOpen,
	setProcessing,
	onSaveSuccess,
}: {
	model: PromotionModel;
	setOpen: (v: boolean) => any;
	setProcessing: (p: boolean) => any;
	onSaveSuccess: (o: PromotionModel) => void;
}) => {
	console.log("🚀 ~ model:", model);
	const { t } = useTranslation();
	const { message } = App.useApp();
	const formChangedProvider = useContext(FormChangedContext);

	const [form] = Form.useForm();
	const isEditing = model.id > 0;
	const value_type = Form.useWatch("value_type", form);
	const initialValues = useMemo(() => {
		return {
			group: model.group > 0 ? model.group : undefined,
			type: model.type > 0 ? model.type : null,
			name: model.name,
			promotion_date: [
				model.start_date !== undefined && model.start_date > 0
					? dayjs(dayjs.unix(model.start_date).format(DATE_FORMAT), DATE_FORMAT)
					: undefined,
				// dayjs(dayjs().format(DATE_FORMAT), DATE_FORMAT)
				model.end_date !== undefined && model.end_date > 0
					? dayjs(dayjs.unix(model.end_date).format(DATE_FORMAT), DATE_FORMAT)
					: undefined,
				// dayjs(dayjs().add(1, "M").format(DATE_FORMAT), DATE_FORMAT)
			],
			value: model.value,
			values: model.value,
			min_check: model.min_check,
			store_id_list: model.store_id_list,
			value_type:
				model.value_type > 0 ? model.value_type : Promotion.VALUETYPE_PERCENT,
			status: model.status > 0 ? model.status : Promotion.STATUS_PENDING,
		};
	}, [model]);

	/////////////////////////////////////////

	const [loading, setLoading] = useState(false);
	/////////////////////////////////////////

	useEffect(() => {
		if (model.group === Promotion.GROUP_SEASONAL_ID) {
			form.setFieldsValue({ type: Promotion.TYPE_PERMANENT_PRODUCT_DISCOUNT });
		}

		if (
			model.group === Promotion.GROUP_SEASONAL_ID &&
			model.type === Promotion.TYPE_SEASONAL_ORDER_GIFT
		) {
			form.setFieldsValue({ value: model.min_check });
		}
	}, [model.group, form, model.min_check, model.type]);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: PromotionJsonAddEdit = {
				// ...PromotionModel.getDefaultData(),
				// ...model.toJson(),
				id: model.id,
				// code: model.code,
				group: formData.group,
				name: formData.name,

				// store_id_list: model.store_id_list,
				date_started: dayjs(formData.promotion_date[0], DATE_FORMAT).unix(),
				date_ended: dayjs(formData.promotion_date[1], DATE_FORMAT).unix(),
				status: formData?.status || undefined,
				type: formData.type,
				value: formData.value,
				// min_check: formData.min_check,
				// store_scope: 0
			};

			// if (model.group === Promotion.GROUP_LOYALTY) {
			//   submitData.type = Promotion.TYPE_LOYALTY_COUPON;
			// } else {
			//   submitData.type = formData.type;
			// }

			// if (
			// 	model.type === Promotion.TYPE_SEASONAL_ORDER_DISCOUNT ||
			// 	formData.type === Promotion.TYPE_SEASONAL_ORDER_DISCOUNT
			// ) {
			// 	submitData.value = formData.values;
			// 	submitData.value_type = Promotion.VALUETYPE_RANGE;
			// } else {
			// 	submitData.value_type = formData.value_type;
			// 	submitData.value = formData.value;
			// }

			// if (model.group === Promotion.GROUP_PERMANENT) {
			//   submitData.type = Promotion.TYPE_PERMANENT_PRODUCT_DISCOUNT;
			// }

			// if (
			// 	model.group === Promotion.GROUP_SEASONAL &&
			// 	formData.type === Promotion.TYPE_SEASONAL_ORDER_GIFT
			// ) {
			// 	submitData.min_check = parseInt(formData.value);
			// 	submitData.value_type = Promotion.VALUETYPE_QUANTITY;
			// 	submitData.value = "1";
			// }

			// if (
			// 	model.group === Promotion.GROUP_SEASONAL &&
			// 	model.type === Promotion.TYPE_SEASONAL_PRODUCT_GIFT
			// ) {
			// 	submitData.value_type = Promotion.VALUETYPE_QUANTITY;
			// }

			// if (model.store_id_list.length === 0) {
			// 	submitData.store_scope = Promotion.STORESCOPE_ALL;
			// } else {
			// 	submitData.store_scope = Promotion.CUSTOMERSCOPE_CUSTOM;
			// }

			return submitData;
		},
		[model.id, model.type, model.group, model.code, model.store_id_list]
	);

	let childrenForm = null;
	switch (model.group) {
		// case Promotion.GROUP_LOYALTY:
		//   childrenForm = <PromotionFormLoyalty isEditing={isEditing} />;
		//   break;
		case Promotion.GROUP_SEASONAL_ID:
			childrenForm = <PromotionFormSeasonal isEditing={isEditing} />;
			break;
		// case Promotion.GROUP_PERMANENT:
		//   childrenForm = <PromotionFormPermanent />;
		//   break;
	}

	//submit data to server
	const onSubmit = async (formData: any) => {
		setProcessing(true);
		setLoading(true);
		message.loading({
			content: t("common:form.processing"),
			key: "message",
			duration: 0,
		});

		let item: PromotionModel = await new PromotionRepository().saveRemote(
			doPrepareData(formData)
		);

		setProcessing(false);
		if (item.hasError()) {
			message.error({
				content: (
					<Error
						onClickClose={() => {
							message.destroy("message");
						}}
						heading={t("common:form.error.heading")}
						translate_prefix="promotion:form.error"
						items={item.error.errors}
					/>
				),
				className: "message_error",
				key: "message",
				duration: 4,
			});
		} else {
			message.success({
				content:
					model.id > 0
						? t("promotion:form.success.update")
						: t("promotion:form.success.add"),
				className: "message_success",
				key: "message",
				duration: 2,
			});
			onSaveSuccess(item);
			setOpen(false);
		}

		setLoading(false);
	};

	return (
		<Form
			layout="vertical"
			initialValues={initialValues}
			form={form}
			onFinish={onSubmit}
			id={formChangedProvider.id}
			onFieldsChange={() => formChangedProvider.setChanged(true)}>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label={t("promotion:group")}
						name="group"
						rules={[
							{
								required: true,
								message: t("common:form.required"),
							},
						]}>
						<Select
							placeholder="Nhóm chương trình"
							disabled={false}
							options={PromotionModel.getGroupList()}
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item
						label={t("promotion:name")}
						name="name"
						rules={[
							{
								required: true,
								message: t("promotion:form.error.error_name_required"),
							},
						]}>
						<Input placeholder="Tên chương trình" />
					</Form.Item>
				</Col>
				<Col span={24}>
					<FormSelect
						label="Loại giảm"
						placeholder="Chọn loại giảm"
						name={"type"}
						options={PromotionModel.getTypeListInSeasonal()}
						rules={[
							{
								required: true,
								message: t("common:form.required"),
							},
						]}></FormSelect>
				</Col>

				<Col span={24}>
					<Row gutter={4}>
						<Col span={12}>
							<Form.Item
								label={t("promotion:value_type")}
								name="value_type"
								rules={[
									{
										required: true,
										message: t("common:form.required"),
									},
								]}>
								<Select
									placeholder="Loại giảm"
									disabled={false}
									options={PromotionModel.getValueTypeList()}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={t("promotion:value")}
								name="value"
								rules={[
									{
										required: true,
										message: t("common:form.required"),
									},
								]}>
								{value_type == Promotion.VALUETYPE_AMOUNT ? (
									<MoneyInput addonAfter="VNĐ"></MoneyInput>
								) : (
									<InputNumber max={100} min={0} addonAfter="%"></InputNumber>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Col>
				{isEditing ? (
					<Col md={8} xs={24}>
						<FormSelect
							label={t("common:status")}
							name="status"
							options={PromotionModel.getStatusList()}
						/>
					</Col>
				) : null}
			</Row>

			<Row gutter={16}>
				<Col md={16} xs={24}>
					<Form.Item
						label={`${t("promotion:date_started")} - ${t(
							"promotion:date_ended"
						)}`}
						name="promotion_date"
						rules={[
							{
								required: true,
								message: t("common:form.required"),
								validator(rule, value, callback) {
									if (value.some((i: any) => i === undefined)) {
										return Promise.reject("Yêu cầu nhập");
									}
									return Promise.resolve(1);
								},
							},
						]}>
						<RangePicker
							changeOnBlur
							format={DATE_FORMAT}
							showTime
							style={{ width: "100%" }}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16}>
				<Col md={16} xs={24}>
					<Button loading={loading} htmlType="submit" type="primary">
						Xác nhận
					</Button>
				</Col>
			</Row>

			{/* {childrenForm} */}
		</Form>
	);
};

export default PromotionForm;
