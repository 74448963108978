import BaseCollection from "common/collections/BaseCollection";
import RbacRoleModel from "common/models/RbacRoleModel";
import { RbacRoleJson, RbacRoleCollectionJson } from "common/types/RbacRole";

class RbacRoleCollection extends BaseCollection<
  RbacRoleJson,
  RbacRoleModel,
  RbacRoleCollectionJson
> {
  itemsFromJson(jsonItems: RbacRoleJson[]): RbacRoleModel[] {
    return jsonItems.map((item) => new RbacRoleModel(item));
  }
}

export default RbacRoleCollection;
