const ShippingType = {
  STATUS_ENABLE: 1,
  STATUS_DISABLE: 2,

  TYPE_NONE: 0,
  TYPE_WEIGHT: 1, //Theo trọng lượng
  TYPE_KM: 3, //Theo khoảng cách
};

export default ShippingType;
