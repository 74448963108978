import BaseCollection from "common/collections/BaseCollection";
import TypeOfWorkModel from "common/models/TypeOfWorkModel";
import {
  TypeOfWorkCollectionJson,
  TypeOfWorkJson,
} from "common/types/TypeOfWork";

class TypeOfWorkCollection extends BaseCollection<
  TypeOfWorkJson,
  TypeOfWorkModel,
  TypeOfWorkCollectionJson
> {
  itemsFromJson(jsonItems: TypeOfWorkJson[]): TypeOfWorkModel[] {
    return jsonItems.map((item) => new TypeOfWorkModel(item));
  }
}

export default TypeOfWorkCollection;
