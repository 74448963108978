import { Col, Form, Input, Row, Select } from "antd";
import CmsPost from "common/constants/CmsPost";
import File from "common/constants/File";
import CmsPostModel from "common/models/CmsPostModel";
import FileModel from "common/models/FileModel";
import CmsPostRepository from "common/repositories/CmsPostRepository";
import FileInput from "components/form/fileinput/FileInput";
import FormSection from "components/form/FormSection";
import FormSectionSeo from "components/form/FormSectionSeo";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import HtmlEditorInput from "components/htmleditorinput/HtmlEditorInput";
import CmsPostEditorHeader from "features/cmspost/form/CmsPostFormHeader";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { CmsPostJsonAddEdit } from "common/types/CmsPost";
const CmsPostEditor = ({ model }: { model: CmsPostModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const account = useLoginAccountStore((state) => state.account);

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const [type, setType] = useState<number>(0);

  // modal editing
  const initialValues = {
    type: model.type > 0 ? model.type : CmsPost.TYPE_SINGLE_PAGE,
    identifier: model.identifier,

    title: model.title,
    content: model.content,

    seo_slug: model.seo_slug,
    seo_title: model.seo_title,
    seo_keyword: model.seo_keyword,
    seo_description: model.seo_description,
    graph_image_id: model.graph_image_id,

    file_id: model.file_id,
    image_input: {
      files: FileModel.convertToUploadFiles([model.image]),
      first_file_id: model.file_id,
    },
    display_order: model.display_order,
    status: model.status || CmsPost.STATUS_ENABLED,
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let file_id =
        typeof formData.image_input.file_id_list?.[0] !== "undefined"
          ? formData.image_input.file_id_list?.[0]
          : model.file_id;

      const submitData: CmsPostJsonAddEdit = {
        ...CmsPostModel.getDefaultData(),
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,

        type: formData.type,
        identifier: formData.identifier,

        title: formData.title,
        content: formData.content,

        seo_slug: formData.seo_slug,
        seo_title: formData.seo_title,
        seo_keyword: formData.seo_keyword,
        seo_description: formData.seo_description,

        status: formData.status || CmsPost.STATUS_ENABLED,
        file_id: file_id,
        display_order: formData.display_order,
      };

      return submitData;
    },
    [model.id, model.file_id, account]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: CmsPostModel = await new CmsPostRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  const onTypeChange = (type: number) => {
    setType(type);
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <Col>
        <Form.Item name="image_input" label={t("cmspost:image")}>
          <FileInput
            objectType={File.OBJECTTYPE_ATTACHMENT}
            origin="cms"
            max_item={1}
            allowExtensions={["pdf", "png", "jpeg"]}
          />
        </Form.Item>
      </Col>
      {isEditing ? (
        <Col>
          <Form.Item name="display_order" label={t("cmspost:display_order")}>
            <Input />
          </Form.Item>
        </Col>
      ) : null}
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={CmsPostModel.getStatusList()}
      />
    </div>
  );

  useEffect(() => {
    if (model.id > 0) {
      setType(model.type);
    }
  }, [model]);

  return (
    <>
      <CmsPostEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("cmspost:form.success.update")
            : t("cmspost:form.success.add")
        }
        redirectOnSuccess="/cmspost"
        error_translate_prefix="cmspost:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("cmspost:form.section_title")}
          subtitle={t("cmspost:form.section_description")}
          divider
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("cmspost:filter_type")}
                name="type"
                required
                rules={[
                  {
                    required: true,
                    message: t("cmspost:form.error.error_type_required"),
                  },
                ]}
              >
                <Select
                  disabled={isEditing ? true : false}
                  onChange={onTypeChange}
                  options={CmsPostModel.getTypeList()}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("cmspost:identifier")}
                name="identifier"
                required
                rules={[
                  {
                    required: true,
                    message: t("cmspost:form.error.error_identifier_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("cmspost:title")}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t("cmspost:form.error.error_title_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("news:content")} name="content" required>
                <HtmlEditorInput
                  fileObjectType={File.OBJECTTYPE_CMS}
                  fileOrigin="cms"
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        {type === CmsPost.TYPE_SINGLE_PAGE ? (
          <FormSectionSeo
            fileObjectType={File.OBJECTTYPE_ATTACHMENT}
            fileOrigin="cms"
          />
        ) : null}
      </LayoutForm>
    </>
  );
};

export default CmsPostEditor;
