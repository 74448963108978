import BaseCollection from "common/collections/BaseCollection";
import DataGridTableRowModel from "common/models/DataGridTableRowModel";
import {
  DataGridTableRowJson,
  DataGridTableRowCollectionJson,
} from "common/types/DataGridTableRow";

class DataGridTableRowCollection extends BaseCollection<
  DataGridTableRowJson,
  DataGridTableRowModel,
  DataGridTableRowCollectionJson
> {
  itemsFromJson(jsonItems: DataGridTableRowJson[]): DataGridTableRowModel[] {
    return jsonItems.map((item) => new DataGridTableRowModel(item));
  }
}

export default DataGridTableRowCollection;
