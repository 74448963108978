import ProjectIterationRepository from 'common/repositories/ProjectIterationRepository';
import { SelectOption } from 'common/types/SelectOption';
import FormSelect from 'components/form/FormSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProjectIterationFormSelect = ({
  label,
  name,
  projectId,
  ignoreValues,
  placeholder,
}: {
  label: string;
  name: string;
  projectId: number;
  ignoreValues?: number[];
  placeholder?: string;
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    const collection = await new ProjectIterationRepository().getItems({
      filters: {
        ...ProjectIterationRepository.getDefaultFilters(),
        project_id: projectId,
      },
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
    setLoading(false);
  }, [projectId]);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData, projectId]);

  return (
    <FormSelect
      placeholder={
        placeholder ?? t("projectiteration:projectiteration_placeholder")
      }
      allowClear
      loading={loading}
      showSearch
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
    />
  );
};

export default ProjectIterationFormSelect;
