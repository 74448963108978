import { FileJson } from "./File";
import { CollectionJson } from "./Collection";
import { Filter, FilterValue } from "./Filter";
import { BaseCollectionJson } from "./BaseCollectionJson";

type FilterStory = Filter & {
  publish_from: number;
  publish_to: number;
  collection_id: number | string;
  pin: number;
  type: number;
  not_id: string;
  tags: string | undefined;
  content_type?: number;
};

type StoryImageJson = {
  file_path: string;
  id: number;
  // width: number;
  // height: number;
  // title: string;
  // description: string;
  url: string;
};

type StoryTagJson = {
  type: string;
  name: string;
  code: string;
  value: string;
  display_order: number;
};

type StoryCollectionItemJson = {
  id: number;
  title: string;
  handle: string;
};

type StoryJson = {
  id: number;
  title: string;
  meta_title: string;

  story_id: number;
  creator_id: number;
  images: string;
  banner_desktop: number;
  banner_mobile: number;
  handle: string;
  description: string;
  keyword: string;
  present: string;
  content_type: string;
  quote: string;
  comment: string;
  comment_total: number;
  viewed: number;
  visitor: number;
  date_published: number;
  publish_from: number;
  publish_to: number;
  pin: number;
  status: number;
  type: number;
  web_id: number;
  is_deleted: number;
  date_created: number;
  date_modified: number;
  date_deleted: number;
  tags: StoryTagJson[];
  collections: StoryCollectionItemJson[];
  detail: string;
  meta_file: string;
};
type StoryJsonAddEdit = {
  id: number;
  title: string;
  banner_desktop: number;
  banner_mobile: number;
  handle: string;
  description: string;
  keyword: string;
  present: string;
  detail: string;
  quote: string;
  publish_from: number;
  publish_to: number;
  date_published: number;
  status: number;
  image: string;
  pin: number;
  collection_id: string;
  content_type: string;
  type: number;
};

export enum PublicationsStatus {
  ACTIVE = 1,
  UNACTIVE = 3,
}
type PublicationsJsonAddEdit = {
  meta_title: string;
  content_type: string;
  meta_file: string;
  status: PublicationsStatus;
};
type StoryJsonAdd = {
  title: string;
  banner_desktop: number;
  banner_mobile: number;
  handle: string;
  description: string;
  keyword: string;
  present: string;
  detail: string;
  quote: string;
  publish_from: number;
  publish_to: number;
  date_published: number;
  status: number;
  images: number;
  pin: number;
  collection_id: string;
  content_type: string;
  type: number;
};

type DefaultFilterStory = {
  limit?: FilterValue;
};

type FilterStoryDetail = {
  handle: string | number;
  pin?: number;
};

type StoryCollectionJson = BaseCollectionJson<StoryJson>;

export type {
  StoryJson,
  StoryCollectionJson,
  FilterStory,
  StoryJsonAddEdit,
  FilterStoryDetail,
  DefaultFilterStory,
  StoryCollectionItemJson,
  StoryImageJson,
  StoryTagJson,
  PublicationsJsonAddEdit,
};
