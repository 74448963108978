import { useTranslation } from "react-i18next";

import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";

import { Form, Input } from "antd";
import SettingModel from "common/models/SettingModel";
import { Filter } from "common/types/Filter";
import FormSelectNormal from "components/form/FormSelectNormal";
import { SettingGroupType } from "common/types/Setting";

const ShopSettingFitler = <F extends Filter>({
  type,
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  type: SettingGroupType;
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[""]}
        submitOnChange={false}
      >
        {/* {type === "LANGUAGE" && ( */}
        <FormSelectNormal
          name="lang"
          placeholder={"Ngôn ngữ"}
          options={SettingModel.getLangSelect()}
          allowClear
          popupMatchSelectWidth={false}
          defaultValue={undefined}
        />
        {/* )} */}

        <Form.Item name="keys">
          <Input allowClear placeholder={t("Nhập tên key")} />
        </Form.Item>
      </PageDataFilterForm>
    </>
  );
};

export default ShopSettingFitler;
