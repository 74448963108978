import ProductInventoryCheckingApi from 'common/api/ProductInventoryCheckingApi';
import ProductInventoryCheckingCollection from 'common/collections/ProductInventoryCheckingCollection';
import ProductInventoryCheckingModel from 'common/models/ProductInventoryCheckingModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ProductInventoryCheckingJsonAddEdit,
  FilterProductInventoryChecking,
  ProductInventoryMerge,
} from "common/types/ProductInventoryChecking";

class ProductInventoryCheckingRepository extends BaseRepository<ProductInventoryCheckingApi> {
  _api: ProductInventoryCheckingApi | null;

  constructor() {
    super();
    this._api = new ProductInventoryCheckingApi(true);
  }

  static getDefaultFilters(): FilterProductInventoryChecking {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      warehouse_id: -1,
      id: -1,
    };
  }

  async getItems(props: GetListProps<FilterProductInventoryChecking>) {
    return this._api
      ? await this._api.getList(props)
      : new ProductInventoryCheckingCollection();
  }

  async getItemsUser(props: GetListProps<FilterProductInventoryChecking>) {
    return this._api
      ? await this._api.getListUser(props)
      : new ProductInventoryCheckingCollection();
  }
  async getItem(id: number): Promise<ProductInventoryCheckingModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ProductInventoryCheckingModel(
          ProductInventoryCheckingModel.getDefaultData()
        );
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: ProductInventoryCheckingJsonAddEdit
  ): Promise<ProductInventoryCheckingModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ProductInventoryCheckingModel(
        ProductInventoryCheckingModel.getDefaultData()
      );
    }
  }

  async merge(
    data: ProductInventoryMerge
  ): Promise<ProductInventoryCheckingModel> {
    if (this._api) {
      return await this._api.merge(data);
    } else {
      return new ProductInventoryCheckingModel(
        ProductInventoryCheckingModel.getDefaultData()
      );
    }
  }
}

export default ProductInventoryCheckingRepository;
