import { Button, Popconfirm, Tooltip } from "antd";
import { PosCart } from "common/types/PosCart";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import usePosCartStore from "zustands/usePosCartStore";

import { IconCaretDown, IconPlus } from "@tabler/icons-react";

const HeaderPosOrder = () => {
  const { t } = useTranslation();
  const account = useLoginAccountStore((state) => state.account);

  const [
    activeStore,
    setActiveStore,
    carts,
    activeCartId,
    setActiveCartId,
    insertNewCart,
  ] = usePosCartStore((state) => [
    state.activeStore,
    state.setActiveStore,
    state.carts,
    state.activeCartId,
    state.setActiveCartId,
    state.insertNewCart,
  ]);

  const activeCart = useCallback(
    (cart: PosCart) => {
      setActiveCartId(cart._id);
    },
    [setActiveCartId]
  );

  const addNewCart = useCallback(() => {
    insertNewCart({ company_id: account.company.id, creator_id: account.id });
  }, [insertNewCart, account.company.id, account.id]);

  const isSelectedStore = typeof activeStore !== "undefined";

  return (
    <>
      <div className={"navbar-brand" + (isSelectedStore ? " -mt-6" : "")}>
        <span className="df-logo">
          Cropany <span>POS</span>
        </span>
      </div>

      {isSelectedStore ? (
        <>
          <div className="absolute top-7">
            <Popconfirm
              title={t("pos:change_store_confirm")}
              onConfirm={() => setActiveStore()}
              placement="bottomLeft"
              overlayStyle={{ width: 360 }}
              okText={t("common:table.confirm_ok")}
              cancelText={t("common:table.confirm_cancel")}
            >
              <Button type="text" className="text-xs hover:text-red-500">
                {activeStore.name}
                <IconCaretDown
                  size={14}
                  className="ml-0.5 -mt-0.5 text-blue-500"
                />{" "}
              </Button>
            </Popconfirm>
          </div>

          <div className="absolute left-60 top-4">
            <ul
              className="flex flex-wrap text-sm font-medium text-center text-gray-500 list-none"
              style={{ marginTop: -1 }}
            >
              {carts.map((cart) => (
                <li className="mr-1" key={cart._id}>
                  <span
                    className={
                      "inline-block py-3 px-4 rounded-t-lg cursor-pointer " +
                      (cart._id === activeCartId
                        ? "text-blue-600 bg-gray-200  active"
                        : "hover:text-gray-600 hover:bg-gray-200")
                    }
                    onClick={() => activeCart(cart)}
                  >
                    {cart._cart_name}
                  </span>
                </li>
              ))}
              <li className="mr-1">
                <Tooltip title={t("pos:insert_new_cart")} placement="right">
                  <span
                    className={
                      "inline-block py-3 px-4 rounded-t-lg cursor-pointer hover:text-white hover:bg-blue-300 "
                    }
                    onClick={() => addNewCart()}
                  >
                    <IconPlus size={16} />
                  </span>
                </Tooltip>
              </li>
            </ul>
          </div>
        </>
      ) : null}
    </>
  );
};

export default HeaderPosOrder;
