import BaseRepository from "./BaseRepository";

import {
  FilterITRequest,
  ITRequestJsonAddEdit,
  ITRequestJsonEdit,
} from "common/types/ITRequest";
import ITRequestApi from "common/api/ITRequestApi";
import type { GetListProps } from "common/types/Filter";
import ITRequestModel from "common/models/ITRequestModel";
import ITRequestCollection from "common/collections/ITRequestCollection";

class ITRequestRepository extends BaseRepository<ITRequestApi> {
  _api: ITRequestApi | null;
  constructor() {
    super();
    this._api = new ITRequestApi(true);
  }

  static getDefaultFilter(): FilterITRequest {
    return {
      page: 1,
      limit: 20,
      sortby: "",
      sorttype: "",
      keyword: "",
      status: -1,
      employee_id: -1,
      parent_id: -1,
      department_id: -1,
      type: -1,
      islate: -1,
      date_from: -1,
      date_to: -1,
    };
  }

  async getItemsMine(
    props: GetListProps<FilterITRequest>
  ): Promise<ITRequestCollection> {
    return this._api
      ? await this._api.getListMine(props)
      : new ITRequestCollection();
  }

  async getItem(id: number): Promise<ITRequestModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ITRequestModel(ITRequestModel.getDefaultData());
  }

  async saveRemote(data: ITRequestJsonAddEdit): Promise<ITRequestModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ITRequestModel(ITRequestModel.getDefaultData());
    }
  }

  async editDisplayOrder(data: ITRequestJsonEdit): Promise<ITRequestModel> {
    return this._api
      ? await this._api.editDisplayOrder(data)
      : new ITRequestModel(ITRequestModel.getDefaultData());
  }
}

export default ITRequestRepository;
