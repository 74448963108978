import BaseModel from "./BaseModel";
import { EventHistoryJson } from "common/types/EventHistory";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import i18n from "i18n";
import EventHistoryConstant from "common/constants/EventHistory";

const defaultData: EventHistoryJson = {
	company_id: 0,
	id: 0,
	user_id: 0,
	source_type: 0,
	source_id: 0,
	action: "",
	detail: {
		employee_id: null,
		action_detail: "",
		employee_fullname: null,
		employee_internal_id: null,
	},
	date_created: "",
};

class EventHistoryModel
	extends BaseModel
	implements BaseModelJson<EventHistoryJson>
{
	company_id: number = defaultData.company_id;
	id: number = defaultData.id;
	user_id: number = defaultData.user_id;
	source_type: number = defaultData.source_type;
	source_id: number = defaultData.source_id;
	action: string = defaultData.action;
	detail = defaultData.detail;
	date_created: string = defaultData.date_created;

	constructor(json: EventHistoryJson) {
		super();
		Object.assign(this, { ...defaultData, ...json });
	}

	static getDefaultData(): EventHistoryJson {
		return { ...defaultData };
	}

	toJson(): EventHistoryJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static getSoureTypeLabel(soure_type: number) {
		switch (soure_type) {
			case EventHistoryConstant.SOURE_TYPE.SOURCE_CASHFLOW_RECEIPT:
				return {
					color: "blue",
					label: i18n.t(`order:event_history.SOURCE_CASHFLOW_RECEIPT`),
				};
			case EventHistoryConstant.SOURE_TYPE.SOURCE_INVENTORY_RECEIPT:
				return {
					color: "yellow",

					label: i18n.t(`order:event_history.SOURCE_INVENTORY_RECEIPT`),
				};

			case EventHistoryConstant.SOURE_TYPE.SOURCE_OTHER:
				return {
					color: "gray",
					label: i18n.t(`order:event_history.SOURCE_OTHER`),
				};

			case EventHistoryConstant.SOURE_TYPE.SOURCE_SALE_ORDER:
				return {
					color: "green",
					label: i18n.t(`order:event_history.SOURCE_SALE_ORDER`),
				};

			default:
				break;
		}
	}
}

export default EventHistoryModel;
