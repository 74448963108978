import { Tag, Tooltip, Typography, message } from "antd";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ReportDeliveryCollection from "common/collections/ReportDeliveryCollection";
import ReportDeliveryModel from "common/models/ReportDeliveryModel";
import ReportDeliveryRepository from "common/repositories/ReportDeliveryRepository";
import { FilterReportDelivery } from "common/types/ReportDelivery";
import Helper from "common/utils/helper";
import { TableColumnsType } from "common/types/Table";

import TextDateTime from "components/TextDateTime";
import PageDataTable from "components/page/PageDataTable";

import ReportDeliveryHeader from "./ReportDeliveryHeader";
import ReportDeliveryFilter from "./ReportDeliveryFilter";
import ReportDeliveryExport from "./ReportDeliveryExport";
import DeliverySumByManager from "features/delivery/sumby/DeliverySumbyManager";
import PageDataPagination from "components/page/PageDataPagination";

const ReportLeave = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterReportDelivery = useMemo(
    () => ReportDeliveryRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterReportDelivery>(defaultFilters);
  const [dataSource, setDataSource] = useState<ReportDeliveryModel[]>([]);
  const [total, setTotal] = useState<number>(0);

  useFilterLocation(defaultFilters, filters);

  //Fetch data from this collections

  const fetchData = useCallback(async (): Promise<ReportDeliveryCollection> => {
    let collection = await new ReportDeliveryRepository().getItems({ filters });
    if (collection) {
      setTotal(collection.total);
      setDataSource(collection.items);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return collection;
  }, [filters]);

  //////////////////////////////////////////

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ReportDeliveryModel> = [
    {
      title: t("reportdelivery:columns.id"),
      key: "id",
      width: 100,
      fixed: "left",
    },
    {
      title: t("reportdelivery:columns.date_created"),
      key: "date_created",
      width: 120,
      render: (ts: number) => {
        return ts === 0 ? (
          "--"
        ) : (
          <TextDateTime format="HH:mm, DD/MM/YYYY" ts={ts} />
        );
      },
    },
    {
      title: t("reportdelivery:columns.date_delivered"),
      key: "date_delivered",
      width: 120,
      render: (ts: number) => {
        return ts === 0 ? (
          "--"
        ) : (
          <TextDateTime format="HH:mm, DD/MM/YYYY" ts={ts} />
        );
      },
    },
    {
      title: t("reportdelivery:columns.full_name"),
      key: "full_name",
      width: 200,
      render: (value: string) => {
        return value.length > 0 ? (
          <Typography.Text>{value}</Typography.Text>
        ) : (
          <Tag>{t("delivery:action.empty")}</Tag>
        );
      },
    },
    {
      title: t("reportdelivery:columns.office"),
      key: "office",
      width: 150,
      render: (value: string) => {
        return value.length > 0 ? (
          <Typography.Text>{value}</Typography.Text>
        ) : (
          <Tag>{t("delivery:action.empty")}</Tag>
        );
      },
    },
    {
      title: t("reportdelivery:columns.quantity"),
      key: "quantity",
      width: 150,
    },
    {
      title: t("reportdelivery:columns.cost_total"),
      key: "cost_total",
      width: 120,
      render(value) {
        return <Typography.Text>{Helper.moneyFormat(value)}</Typography.Text>;
      },
    },
    {
      title: t("reportdelivery:columns.cod_total"),
      key: "cod_total",
      width: 120,
      render(value) {
        return <Typography.Text>{Helper.moneyFormat(value)}</Typography.Text>;
      },
    },
    {
      title: t("reportdelivery:columns.cod"),
      key: "cod",
      width: 120,
      render(value) {
        return <Typography.Text>{Helper.moneyFormat(value)}</Typography.Text>;
      },
    },
    {
      title: t("reportdelivery:columns.cost_on_transfer"),
      key: "cost_on_transfer",
      width: 120,
      render(value) {
        return <Typography.Text>{Helper.moneyFormat(value)}</Typography.Text>;
      },
    },
    {
      title: t("reportdelivery:columns.cotips"),
      key: "cotips",
      width: 120,
      render(value) {
        return <Typography.Text>{Helper.moneyFormat(value)}</Typography.Text>;
      },
    },
    {
      title: t("reportdelivery:columns.return_tips"),
      key: "return_tips",
      width: 125,
      render(value, record: ReportDeliveryModel) {
        return value === 0 ? (
          <Tag color="red">No</Tag>
        ) : (
          <Tag color="green">
            <TextDateTime
              format="HH:mm, DD/MM/YYYY"
              ts={record.date_return_tips}
            />
          </Tag>
        );
      },
    },
    {
      title: t("reportdelivery:columns.return_cod"),
      key: "return_cod",
      width: 125,
      render(value, record: ReportDeliveryModel) {
        return value === 0 ? (
          <Tag color="red">No</Tag>
        ) : (
          <TextDateTime
            format="HH:mm, DD/MM/YYYY"
            ts={record.date_return_cod}
          />
        );
      },
    },
    {
      title: t("reportdelivery:columns.contact_address"),
      key: "contact_address",
      width: 150,
      render(value: string) {
        return <Tooltip title={value}>{value.slice(0, 30)}</Tooltip>;
      },
    },
    {
      title: t("reportdelivery:columns.kpi_shiper"),
      key: "kpi_shiper",
      width: 100,
      render(value: string, record, index) {
        return value.length > 0 ? (
          <Typography.Text>{value}</Typography.Text>
        ) : (
          <Tag>Chưa tính</Tag>
        );
      },
    },
    {
      title: t("reportdelivery:columns.note"),
      key: "note",
      width: 200,
      render(value: string) {
        return <Tooltip title={value}>{value.slice(0, 30)}</Tooltip>;
      },
    },
  ];

  return (
    <>
      <ReportDeliveryHeader>
        {total > 0 && <ReportDeliveryExport filters={filters} />}
      </ReportDeliveryHeader>

      <ReportDeliveryFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />

      <DeliverySumByManager
        type="report"
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
      />

      <PageDataTable<
        FilterReportDelivery,
        ReportDeliveryModel,
        ReportDeliveryCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ReportLeave;
