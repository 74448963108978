import BaseCollection from "common/collections/BaseCollection";
import DataGridTableModel from "common/models/DataGridTableModel";
import {
  DataGridTableJson,
  DataGridTableCollectionJson,
} from "common/types/DataGridTable";

class DataGridTableCollection extends BaseCollection<
  DataGridTableJson,
  DataGridTableModel,
  DataGridTableCollectionJson
> {
  itemsFromJson(jsonItems: DataGridTableJson[]): DataGridTableModel[] {
    return jsonItems.map((item) => new DataGridTableModel(item));
  }
}

export default DataGridTableCollection;
