const Company = {
  STATUS_ENABLE: 1,
  STATUS_DISABLED: 3,

  PLAN_01: 1,
  PLAN_02: 2,
  PLAN_03: 3,
  PLAN_ON_PREMISE: 100,
};

export default Company;
