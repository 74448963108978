import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import dbm from "dbm";
import React, { useCallback } from "react";

import ReportChartCategory from "../chart/ReportChartCategory";
import ReportChartDataTable from "../chart/ReportChartDataTable";

const ReportCustomerRegion = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const regionItemsWithLimit = dbm.getCollection("region")?.chain().data();
  const formatterRegionValue = useCallback(
    (value: number) => {
      return regionItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [regionItemsWithLimit]
  );
  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartCategory
              className="p-4"
              title={"Số khách hàng theo tỉnh / thành phố"}
              dataTable="customer"
              dataService="customer"
              dataSelect="count(id), region"
              dataFilter=""
              dataGroupBy="region"
              dataOrderBy="count(id) desc"
              keyMapping={{
                name: "region",
                value: "count_id",
                formatter: (value: number) => {
                  return formatterRegionValue(value) || "";
                }
              }}
              height={400}
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo khách hàng theo khu vực"}
              subtitle="Ngày tạo khách hàng theo tỉnh / thành phố"
              dataTable="customer"
              dataService="customer"
              keyMapping={[
                {
                  title: "Tỉnh / Thành phố",
                  name: "region",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        {regionItemsWithLimit.find((i) => i.id === value)?.name}
                      </>
                    );
                  }
                },
                {
                  title: "Số khách hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), region"
              dataFilter=""
              dataGroupBy="region"
              dataOrderBy="count(id) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportCustomerRegion;
