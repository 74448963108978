import { Form, Select } from "antd";
import React, { useMemo } from "react";
import { DebounceInput } from "react-debounce-input";
import useCompanySettingStore from "zustands/useCompanySettingStore";
import usePosCartStore from "zustands/usePosCartStore";

const PosRightInfo = () => {
  const activeCart = usePosCartStore((state) => state.getActiveCart());
  const updateActiveCart = usePosCartStore((state) => state.updateActiveCart);

  const settingTagDefault = useCompanySettingStore(
    (state) => state.getSetting("tag_default") || 0
  );
  const tagOptions = useMemo(() => {
    let availableTags: { value: string; label: string }[] = [];
    if (typeof settingTagDefault === "string" && settingTagDefault.length > 0) {
      availableTags = settingTagDefault.split(",").map((itemtag) => {
        return { value: itemtag, label: itemtag };
      });
    }
    return availableTags;
  }, [settingTagDefault]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  if (typeof activeCart === "undefined") {
    return null;
  }

  return (
    <div className="p-4 border-t border-l-4 border-blue-500">
      <div className="mb-2 font-bold uppercase">Thông tin khác</div>

      <Form.Item label="Gắn nhãn" {...layout} className="mb-2" colon={false}>
        <Select<string[]>
          style={{ width: "100%" }}
          allowClear
          tokenSeparators={[","]}
          value={activeCart.tag.split(",").filter((v) => v.length > 0)}
          onChange={(values) =>
            updateActiveCart({
              tag: values.filter((v) => v.length > 0).join(","),
            })
          }
          options={tagOptions}
          mode="tags"
        />
      </Form.Item>

      <Form.Item label={"Ghi chú"} {...layout} className="mb-0" colon={false}>
        <DebounceInput
          className="w-full px-2 py-1 border border-gray-300 rounded"
          placeholder="Ghi chú cho đơn hàng"
          minLength={2}
          debounceTimeout={300}
          value={activeCart.note}
          onChange={(e) => updateActiveCart({ note: e.target.value })}
        />
      </Form.Item>
    </div>
  );
};

export default PosRightInfo;
