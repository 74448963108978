import ShippingHubApi from 'common/api/ShippingHubApi';
import ShippingHubCollection from 'common/collections/ShippingHubCollection';
import ShippingHubModel from 'common/models/ShippingHubModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ShippingHubJsonAddEdit,
  FilterShippingHub,
} from "common/types/ShippingHub";

class ShippingHubRepository extends BaseRepository<ShippingHubApi> {
  _api: ShippingHubApi | null;

  constructor() {
    super();
    this._api = new ShippingHubApi(true);
  }

  static getDefaultFilters(): FilterShippingHub {
    return {
      page: 1,
      limit: 200,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      ids: "",
    };
  }

  async getFullData(
    companyId: number,
    version: number
  ): Promise<ShippingHubCollection> {
    return this._api
      ? await this._api.getFullData({ companyId, version })
      : new ShippingHubCollection();
  }

  async getItems(props: GetListProps<FilterShippingHub>) {
    return this._api
      ? await this._api.getList(props)
      : new ShippingHubCollection();
  }

  async getItem(id: number): Promise<ShippingHubModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShippingHubModel(ShippingHubModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: ShippingHubJsonAddEdit): Promise<ShippingHubModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShippingHubModel(ShippingHubModel.getDefaultData());
    }
  }
}

export default ShippingHubRepository;
