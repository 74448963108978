import ProjectIterationCollection from "common/collections/ProjectIterationCollection";
import ProjectIterationModel from "common/models/ProjectIterationModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterProjectIteration,
  ProjectIterationJsonAddEdit,
} from "common/types/ProjectIteration";

const SERVICE_URL = "/projectiterations";

class ProjectIterationApi extends BaseApi {
  async getList(
    props: GetListProps<FilterProjectIteration>,
  ): Promise<ProjectIterationCollection> {
    let collection = new ProjectIterationCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          project_id: +filters.project_id > 0 ? filters.project_id : null,
          company_id: +filters.company_id > 0 ? filters.company_id : null,
          ids: filters.ids ? filters.ids : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getIds(ids: string): Promise<ProjectIterationCollection> {
    let collection = new ProjectIterationCollection();

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/ids?ids=" + ids,
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ProjectIterationModel> {
    let item = new ProjectIterationModel(
      ProjectIterationModel.getDefaultData(),
    );

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ProjectIterationModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }

  async add(data: ProjectIterationJsonAddEdit): Promise<ProjectIterationModel> {
    let item = new ProjectIterationModel(
      ProjectIterationModel.getDefaultData(),
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ProjectIterationModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(
    data: ProjectIterationJsonAddEdit,
  ): Promise<ProjectIterationModel> {
    let item = new ProjectIterationModel(
      ProjectIterationModel.getDefaultData(),
    );

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new ProjectIterationModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
}

export default ProjectIterationApi;
