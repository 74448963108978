import { valueType } from "antd/es/statistic/utils";
import { NumericFormat } from "react-number-format";

const TextMoney = ({
  money,
  noSymbol,
}: {
  money: valueType;
  noSymbol?: boolean;
}) => {
  //can get value from setting
  const symbolPrefix = "";
  const symbolSuffix = " ₫";
  const thousandSeparator = ",";
  const decimalScale = 0;

  return (
    <NumericFormat
      prefix={typeof noSymbol !== "undefined" && noSymbol ? "" : symbolPrefix}
      suffix={typeof noSymbol !== "undefined" && noSymbol ? "" : symbolSuffix}
      value={money}
      displayType="text"
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
    />
  );
};

export default TextMoney;
