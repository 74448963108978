import { Col, Form, Input, Row } from "antd";
import ProjectTask from "common/constants/ProjectTask";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const ProjectTaskTabUpdateStatus = ({
  projectModel,
}: {
  projectModel: ProjectModel;
}) => {
  const { t } = useTranslation();
  const account = useLoginAccountStore((state) => state.account);

  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    // accept status for development team
    let scrumMaster = projectModel.scrum_master;
    let developmentTeam = projectModel.development_team;
    let allowStatusList: number[] = [
      ProjectTask.STATUS_PENDING,
      ProjectTask.STATUS_ASSIGNED,
      ProjectTask.STATUS_WORKING,
      ProjectTask.STATUS_NEED_FOR_APPROVAL,
    ];

    if (
      !scrumMaster.includes(account.id) &&
      developmentTeam.includes(account.id)
    ) {
      setOptions(
        ProjectTaskModel.getStatusList().map((i) => {
          return {
            value: i.value,
            label: i.label,
            color: i.color,
            disabled: !allowStatusList.includes(i.value) ? true : false,
          };
        })
      );
    } else {
      setOptions(ProjectTaskModel.getStatusList());
    }
  }, [account.id, projectModel.development_team, projectModel.scrum_master]);

  return (
    <>
      <Row gutter={16}>
        <Col md={8} xs={24}>
          <FormSelect
            label={t("common:status")}
            name="status"
            options={options}
          />
        </Col>
        <Col md={16} xs={24}>
          <Form.Item label={t("projecttask:change_note")} name="change_note">
            <Input.TextArea autoSize></Input.TextArea>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ProjectTaskTabUpdateStatus;
