import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import usePosStore from "zustands/usePosStore";

import PosRightCustomer from "./right/customer/PosRightCustomer";
import PosRightDelivery from "./right/delivery/PosRightDelivery";
import PosRightPayment from "./right/payment/PosRightPayment";
import PosRightInfo from "./right/PosRightInfo";
import PosRightPromotion from "./right/promotion/PosRightPromotion";
import PosSubmitCreateOrder from "./submit/PosSubmitCreateOrder";

const PosRight = () => {
  const height = usePosStore((state) => state.height);
  const submitButtonHeight = 60;

  return (
    <>
      <Scrollbars
        style={{
          height: height - submitButtonHeight,
          overflow: "hidden",
        }}
        autoHide
      >
        <PosRightCustomer />
        <PosRightDelivery />
        <PosRightPromotion />
        <PosRightInfo />
        <PosRightPayment />
      </Scrollbars>
      <PosSubmitCreateOrder height={submitButtonHeight} />
    </>
  );
};

export default PosRight;
