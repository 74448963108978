import { Col, Row, Typography } from "antd";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectIterationRepository from "common/repositories/ProjectIterationRepository";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import React, { useCallback, useEffect, useState } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const ProjectIterationList = ({
  projectModel,
  canManage,
  inputKeyword,
  doFilter,
  setProjectIterationId,
}: {
  projectModel: ProjectModel;
  canManage: boolean;
  inputKeyword: string | number;
  doFilter: (id: number) => void;
  setProjectIterationId: (id: number) => void;
}) => {
  const account = useLoginAccountStore((state) => state.account);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<ProjectIterationModel[]>([]);
  const fetchProjectIteration = useCallback(async () => {
    let collection = await new ProjectIterationRepository().getItems({
      filters: {
        ...ProjectIterationRepository.getDefaultFilters(),
        project_id: projectModel.id,
        company_id: account.company.id,
        keyword: inputKeyword,
      },
    });

    if (!collection.hasError()) {
      setDataSource(collection.items);
    }
  }, [account.company.id, projectModel.id, inputKeyword]);

  useEffect(() => {
    fetchProjectIteration();
  }, [fetchProjectIteration]);

  return (
    <>
      <div className={dataSource.length > 0 ? "border-t-2" : ""}>
        {dataSource.length > 0
          ? dataSource.map((item) => (
              <Row gutter={16} key={item.id} className="pt-4">
                <Col
                  md={14}
                  xs={24}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e?.preventDefault();
                    doFilter(item.id);
                  }}
                >
                  <Typography.Text>{item.name}</Typography.Text>
                  {" ("}
                  <Typography.Text>{item.count_story}</Typography.Text>
                  {")"}
                </Col>
                {canManage ? (
                  <Col md={10} xs={24}>
                    <div className="flex items-center justify-end flex-shrink-0">
                      <TableEdit
                        link=""
                        onClick={() => setProjectIterationId(item.id)}
                      />
                      <TableDelete
                        error_translate_prefix="project:form.error"
                        onDeleteCallback={(id) => {
                          setDataSource(
                            dataSource.filter((item) => item.id !== id)
                          );
                        }}
                        repository={new ProjectIterationRepository()}
                        id={item.id}
                      />
                    </div>
                  </Col>
                ) : null}
              </Row>
            ))
          : null}
      </div>
    </>
  );
};

export default ProjectIterationList;
