import { IconEye, IconLoader, IconPlus, IconTag } from "@tabler/icons-react";
import {
  Button,
  Image,
  Modal,
  Skeleton,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import TagConstant from "common/constants/Tag";
import FileModel from "common/models/FileModel";
import ProductCollectionModel from "common/models/ProductCollectionModel";
import ProductModel from "common/models/ProductModel";
import TagDetailModel from "common/models/TagDetailModel";
import TagModel from "common/models/TagModel";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import ProductRepository from "common/repositories/ProductRepository";
import TagRepository from "common/repositories/TagRepository";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import ErrorAlert from "components/ErrorAlert";
import NoPhoto from "components/NoPhoto";
import FormModal from "components/form/FormModal";
import ProductForm from "features/product/form/ProductForm";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductCollectionToCollectionModal = ({
  id,
  open,
  setOpen,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => void;
}) => {
  const { t } = useTranslation();

  const [processing, setProcessing] = useState<boolean>(false);
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>([]);
  console.log("🚀 ~ existValues:", existValues);

  //add product to collection detail
  const onSubmitCollectionDetail = async (v: SelectOption[]) => {
    console.log("🥺 ~ onSubmitCollectionDetail ~ v:", v);
    setProcessing(true);
    if (v.length > 0) {
      message.loading({ content: "Vui lòng đợi", duration: 1 });
      const response =
        await new ProductCollectionRepository().addCollectionInCollection({
          parent_id: id,
          collection_ids: v.map((item) => Number(item.value)),
          type: "update",
        });
      if (!response.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue([...existValues, ...v.map((item) => item.value)]);
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error(response.error.errors);
        setProcessing(false);

        return false;
      }
    }
    setProcessing(false);
  };

  //delete product collection detial
  const onDeleteSelectedItem = async (v: SelectOption) => {
    setProcessing(true);
    console.log("🚀 ~ onDeleteSelectedItem ~ v:", v);
    message.loading({ content: "Vui lòng đợi", duration: 1 });

    const response =
      await new ProductCollectionRepository().addCollectionInCollection({
        parent_id: id,
        type: "remove",
        collection_id: Number(v.value),
      });

    if (!response.hasError()) {
      message.success({ content: "Thao tác thành công", duration: 1 });
      setExistValue(existValues.filter((item) => item !== Number(v.value)));
      setSelectReload(!selectReload);
    } else {
      message.error("Thao tác thất bại");
    }

    setProcessing(false);
  };
  const [pathParams] = usePathParams();

  //addsku to item
  const handleAddSkuSuccess = async (skus: string[]) => {
    // const fetchModel = await new ProductCollectionRepository().getItem(
    //   Number(pathParams.id) || 0
    // );
    // if (!fetchModel.hasError()) {
    //   setExistValue(fetchModel.item_ids);
    // }
    setSelectReload(!selectReload);
  };

  const changeProductCollectionExistValue = (
    newTag: ProductCollectionModel[]
  ) => {
    console.log("🚀 ~ changeProductExistValue ~ newTag:", newTag);
    setExistValue(newTag.map((item) => item.id));
  };

  const handleRemoveCurrentItem = async (v: SelectOptionNormal[]) => {
    console.log("🥺 ~ handleRemoveCurrentItem ~ v:", v);
    setProcessing(true);

    if (v.length > 0) {
      const reponse =
        await new ProductCollectionRepository().addCollectionInCollection({
          parent_id: id,
          type:"remove",
          collection_ids: v.map((item) => Number(item.value)),
        });
      if (!reponse.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        // setExistValue(existValues.filter((item) => item !== v.value));
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error("Thao tác thất bại");
        setProcessing(false);

        return false;
      }
    }
  };

  return (
    <Modal
      title={
        <>
          <div>
            <Typography.Text>{`Quản lý danh mục con của danh mục`}</Typography.Text>
            {processing && <IconLoader className="animate-spin" />}
          </div>
        </>
      }
      width={920}
      bodyStyle={{
        paddingTop: 8,
        paddingBottom: 8,
        overflowY: "auto",
        maxHeight: "calc(100vh - 250px)",
      }}
      open={open}
      confirmLoading={processing}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        setOpen(false);
      }}
      maskClosable={false}
      okButtonProps={{ style: { display: "none" } }}
      cancelText={"Đóng"}
      destroyOnClose
    >
      <AddListToItemComponent
        type="collectionincollection"
        reload={selectReload}
        existValues={existValues}
        id={id}
        onSaveSuccess={onSubmitCollectionDetail}
        handleRemoveItem={(v: SelectOption) => {
          onDeleteSelectedItem(v);
        }}
        handleRemoveCurrentItem={handleRemoveCurrentItem}
        handleAddSkuSuccess={handleAddSkuSuccess}
        changeExistValue={changeProductCollectionExistValue}
        renderItems={(item: ProductCollectionModel) => {
          return (
            <>
              <div className="flex flex-col">
                <div className="text-blue-600 text-md font-semibold">
                  {item.title}
                </div>
                <div className="text-xs text-gray-400 mt-1">
                  URL: {item.handle}
                </div>
              </div>
            </>
          );
        }}
      ></AddListToItemComponent>
    </Modal>
  );
};

export default ProductCollectionToCollectionModal;
