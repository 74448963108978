import React, { useEffect, useState } from "react";
import SettingRepository from "common/repositories/SettingRepository";
import { SettingJson } from "common/types/Setting";
import { Badge, message, Modal, Popconfirm, Table, Typography } from "antd";
import { ImageStoreData } from "common/types/Shop";
import TableEdit from "components/table/TableEdit";
import { IconTrash } from "@tabler/icons-react";
import { TableColumnsType } from "antd";
import useSetting from "hooks/useSetting";
import SettingModel from "common/models/SettingModel";
import StoreHeader from "./StoreHeader";
import ImageForm from "../form/ImageForm";

export default function SettingImageList() {
	const key = "image_shop_namperfume";

	const [messageApi, context] = message.useMessage();

	const [settingJson, setSettingJson] = useState<SettingJson>(
		SettingModel.getDefaultData()
	);

	const [images, setImages] = useState<ImageStoreData[]>([]);

	const [dataEdit, setDataEdit] = useState<ImageStoreData | null>(null);
	const [indexEdit, setIndexEdit] = useState<number>(-1);

	const [typeSubmit, setTypeSubmit] = useState<"add" | "edit" | "">("");

	const [loading, setLoading] = useState(false);

	const { onAdd, onUpdate } = useSetting<ImageStoreData>({
		settingJson: settingJson,
	});

	const getData = async () => {
		setLoading(true);
		const res = await new SettingRepository().getItem(key);

		if (!res.hasError()) {
			setSettingJson(res.toJson());
			setImages(res.toJson().value as ImageStoreData[]);
		}
		setLoading(false);
	};

	const onReset = () => {
		setIndexEdit(-1);
		setDataEdit(null);
		setTypeSubmit("");
	};

	const handleFinish = (data: ImageStoreData) => {
		if (typeSubmit === "edit") {
			dataEdit &&
				indexEdit >= 0 &&
				onUpdate(data, dataEdit, indexEdit)
					.then(() => {
						messageApi.success("Thay đổi thành công!");

						getData();
						// setReload((prev) => ({ ...prev, grid: true }));
					})
					.catch(() => {
						messageApi.success("Thay đổi thất bại!");
					});
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const column: TableColumnsType<ImageStoreData> = [
		{
			title: "id",
			key: "key",
			dataIndex: "key",
			render(value, record, index) {
				return (
					<>
						<Typography.Text>{index}</Typography.Text>
					</>
				);
			},
		},
		{
			title: "Cửa hàng",
			key: "title",
			dataIndex: "title",
			render(value, record, index) {
				return <Typography.Text>{value}</Typography.Text>;
			},
		},
		{
			title: "Ảnh",
			key: "image",
			dataIndex: "image",
			render(value, record, index) {
				return (
					<>
						{/* <Image width={60} height={60} src={record.image}></Image>; */}
						<Badge count={value.length}></Badge>
					</>
				);
			},
		},

		{
			title: " ",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (_, record, index) => {
				return (
					<div className="flex gap-1 justify-end">
						<TableEdit
							link=""
							onClick={() => {
								// setTypeAction("edit");
								// setIndexUpdate(index);
								// setDataEditing(record);
								setDataEdit(record);
								setIndexEdit(index);
								setTypeSubmit("edit");
							}}></TableEdit>

						<Popconfirm
							placement="topRight"
							title={<>Bạn chắc chắn muốn xóa ?</>}
							description={"Gỡ chương trình khỏi cài đặt "}
							okText="Đồng ý"
							cancelText="Hủy"
							onConfirm={() => {}}>
							{/* <TableEdit label="" icon={<IconTrash></IconTrash>} link={""} /> */}
							<IconTrash
								className=" cursor-pointer text-gray-400 hover:text-red-500"
								size="18"
							/>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	return (
		<div>
			{context}
			<StoreHeader></StoreHeader>
			<Table
				loading={loading}
				columns={column}
				rowKey={"id"}
				dataSource={images || []}></Table>
			<Modal
				width={"800px"}
				okButtonProps={{ className: "hidden" }}
				destroyOnClose
				title="Chỉnh sửa brand"
				open={dataEdit !== null}
				onCancel={() => {
					onReset();
				}}>
				{/* {dataEditing && (
					<AdsCollectionForm
						initialValue={dataEditing}
						onFinish={(data) => {
							hanleUpdate(data);
						}}></AdsCollectionForm>
				)} */}
				{/* {dataEdit && (
					<BrandForm
						initialValue={dataEdit}
						onFinish={handleFinish}></BrandForm>
				)} */}
				{dataEdit && (
					<ImageForm
						initialValue={dataEdit}
						onFinish={handleFinish}></ImageForm>
				)}
			</Modal>
		</div>
	);
}
