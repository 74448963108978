import { Button, Tag, Tooltip, Typography, message } from "antd";
import { TableColumnsType } from "common/types/Table";
import RoleCheck from "components/RoleCheck";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import TagCollection from "common/collections/TagCollection";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { FilterTag } from "common/types/Tag";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableDelete from "components/table/TableDelete";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import ShopSettingListHeader from "./ShopSettingListHeader";
import SettingRepository from "common/repositories/SettingRepository";
import SettingModel from "common/models/SettingModel";
import SettingCollection from "common/collections/SettingCollection";
import { FilterSetting, SettingGroupType } from "common/types/Setting";
import SettingFormModal from "../form/SettingFormModal";
import ShopSettingFitler from "./ShopSettingFilter";
import { IconLanguage } from "@tabler/icons-react";
import SettingApi from "common/constants/Setting";
import LanguageSettingModal from "../languagesetting/LanguageSettingModal";
import Helper from "common/utils/helper";

type Props = {
  type: SettingGroupType;
};

const ShopSettingList = ({ type = "SHOP" }: Props) => {
  const { t } = useTranslation();
  // default filter
  const defaultFilters = useMemo(() => {
    return type === "LANGUAGE"
      ? {
          ...SettingRepository.getDefaultFilters(),
          group: type,
          lang: "vi",
        }
      : type === "ALL"
      ? {
          ...SettingRepository.getDefaultFilters(),
          group: "",
          // lang: "empty",
        }
      : {
          ...SettingRepository.getDefaultFilters(),
          group: type,
          // lang: "empty",
        };
  }, [type]);
  //////////////////////////////////////////
  //Fetch data from this collections
  const [filters, setFilters] = useStateFilter(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const [dataSource, setDataSource] = useState<SettingModel[]>([]);
  const [total, setTotal] = useState<number>(0);
  const fetchData = useCallback(async (): Promise<SettingCollection> => {
    const response = await new SettingRepository().getItems({
      filters: filters,
    });
    if (!response.hasError()) {
      setTotal(response.total);
      setDataSource(response.items);

      if (response.items.length === 0) {
        if (filters.keys.length > 0) {
          setEditingId(0);
          setAddVisible(true);
          setIsCreated(true);
          setCreatedKey(filters.keys.trim());
        }
      }
    } else {
      message.error("common:error.error_fetching_data");
    }

    return response;
  }, [filters]);

  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);
  const [isCreated, setIsCreated] = useState(false);
  const [createdKey, setCreatedKey] = useState<string>("");

  //language
  const [languageVisible, setLanguageVisible] = useState<boolean>(false);
  const [languageKey, setLanguageKey] = useState<string>(
    Helper.getTranslateKey()
  );
  function convertCodeToLang(code: string) {
    switch (code) {
      case "*":
        return "Đa ngôn ngữ";
	
      case "vi":
        return "Tiếng việt";
      case "en":
		return "Tiếng anh";
      default:
		return "Chưa xác định";
    }
  }

  //Table columns
  const columns: TableColumnsType<SettingModel> = [
    {
      title: "ID",
      key: "id",
      width: 80,
    },
    {
      title: "Key",
      key: "key",
	  width : 300,

    },
    {
      title: "Loại ngôn ngữ",
      key: "lang",
	  width:160,

      render: (_, record) => {
        return <>{convertCodeToLang(record.lang)}</>;
      },
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 125,
      render: (_: any, record: SettingModel) => (
        <RoleCheck roles={[]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="collection:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new SettingRepository()}
            id={record.id}
          />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const columnsLanguage: TableColumnsType<SettingModel> = [
    {
      title: "ID",
      key: "id",
      width: 80,
    },
    {
      title: "Key",
      key: "key",
	  width : 300,
    },
    {
      title: "Loại ngôn ngữ",
      key: "lang",
	  render: (_, record) => {
        return <>{convertCodeToLang(record.lang)}</>;
      },
	  width:160,
    },
    {
      title: "Giá trị",
      key: "value",
      render(value, record, index) {
        return (
          <Typography.Text className="line-clamp-1">
            {JSON.stringify(value)}
          </Typography.Text>
        );
      },
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      toggletype: "trigger",
      width: 125,
      render: (_: any, record: SettingModel) => (
        <RoleCheck roles={[]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableInfo record={record} />
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: SettingModel) => {
    fetchData();
  };

  return (
    <>
      <ShopSettingListHeader type={type}>
        <RoleCheck roles={[]}>
          <div className="flex gap-2">
            {/* {type === "LANGUAGE" && (
              <Tooltip title="Đổi ngôn ngữ">
                <Button
                  onClick={() => {
                    setLanguageVisible(true);
                  }}
                  icon={<IconLanguage />}
                ></Button>
              </Tooltip>
            )} */}
            <Button
              type="primary"
              onClick={() => {
                setEditingId(0);
                setAddVisible(true);
              }}
            >
              {t("common:table.add_button")}
            </Button>
          </div>
        </RoleCheck>
      </ShopSettingListHeader>

      <ShopSettingFitler
        type={type}
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        total={total}
      />

      <PageDataTable<FilterSetting, SettingModel, SettingCollection>
        {...{
          columns: type === "LANGUAGE" ? columnsLanguage : columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "shopsetting",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <SettingFormModal
        is_created={isCreated}
        type={type}
        lang={filters.lang}
        id={editingId}
        key={editingId}
        open={addVisible}
        createdKey={createdKey}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
            setIsCreated(false);
            setCreatedKey("");
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />

      <LanguageSettingModal
        type={type}
        lang={filters.lang}
        key={languageKey}
        handle_key={languageKey}
        open={languageVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          // if (!isOpen) {
          //   setEditingId(0);
          // }
          setLanguageVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ShopSettingList;
