import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";

import PageHeader from "components/page/PageHeader";

const NotificationFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("sitemenu:notification"),
      link: "/notification",
    },
    {
      text: t(isEditing ? "notification:form.edit" : "notification:form.add"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t(
        isEditing ? "notification:form.edit" : "notification:form.add"
      )}
      nav={nav}
      siteMenuOpenKey="notification"
      siteMenuSelectedKey="/notification"
      siteMenuHidden
    ></PageHeader>
  );
};

export default NotificationFormHeader;
