import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import {
  StoryCollectionImageJson,
  StoryCollectionJson,
  StoryCollectionTagJson,
} from "common/types/StoryCollection";
import FileModel from "./FileModel";
import { FileJson } from "common/types/File";

const defaultData: StoryCollectionJson = {
  id: 0,
  images: [],
  banner_desktop: "",
  banner_mobile: "",
  title: "",
  handle: "",
  description: "",
  creator_id: 0,
  parent_id: 0,
  display_order: 0,
  date_published: 0,
  publish_from: 0,
  publish_to: 0,
  pin: 0,
  tags: [],
  status: 0,
  type: 0,
  is_deleted: 0,
  date_deleted: 0,
  date_created: 0,
  date_modified: 0,
};

class StoryCollectionModel
  extends BaseModel
  implements BaseModelJson<StoryCollectionJson>
{
  id: number = defaultData.id;
  images: FileJson[] = defaultData.images;
  banner_desktop: string = defaultData.banner_desktop;
  banner_mobile: string = defaultData.banner_mobile;
  title: string = defaultData.title;
  handle: string = defaultData.handle;
  description: string = defaultData.description;
  creator_id: number = defaultData.creator_id;
  parent_id: number = defaultData.parent_id;
  display_order: number = defaultData.display_order;
  date_published: number = defaultData.date_published;
  publish_from: number = defaultData.publish_from;
  publish_to: number = defaultData.publish_to;
  pin: number = defaultData.pin;
  tags: StoryCollectionTagJson[] = defaultData.tags;
  status: number = defaultData.status;
  is_deleted: number = defaultData.is_deleted;
  type: number = defaultData.type;
  date_deleted: number = defaultData.date_deleted;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;

  constructor(json: StoryCollectionJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): StoryCollectionJson {
    return { ...defaultData };
  }
  toJson(): StoryCollectionJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }

  static getPinSelectList(): SelectOption[] {
    return [
      {
        value: 1,
        label: "Ghim",
      },
      {
        value: 0,
        label: "Không ghim",
      },
    ];
  }

  static getStatusSelectList(): SelectOption[] {
    return [
      {
        value: 2,
        label: "Nháp",
      },
      {
        value: 1,
        label: "Hiện",
      },
      {
        value: 0,
        label: "Ẩn",
      },
    ];
  }
}

export default StoryCollectionModel;
