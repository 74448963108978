import ShippingHubList from 'features/shippinghub/list/ShippingHubList';
import usePathParams from 'hooks/usePathParams';
import React from 'react';

const ShippingHubPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "edit":
      break;

    default:
      com = <ShippingHubList />;
  }

  return com;
};

export default ShippingHubPage;
