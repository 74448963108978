import ProductReceiptModel from "common/models/ProductReceiptModel";
import ProductReceiptRepository from "common/repositories/ProductReceiptRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import ProductReceiptForm from "features/productreceipt/form/ProductReceiptForm";
import ProductReceiptFormHeader from "features/productreceipt/form/ProductReceiptFormHeader";
import ProductReceiptList from "features/productreceipt/list/ProductReceiptList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const ProductReceiptPage = () => {
  const [pathParams] = usePathParams();

  const [model, setModel] = useState<ProductReceiptModel>(
    new ProductReceiptModel(ProductReceiptModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new ProductReceiptModel(ProductReceiptModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      if (pathParams.direction !== undefined) {
        com = (
          <ProductReceiptForm
            model={
              new ProductReceiptModel({
                ...ProductReceiptModel.getDefaultData(),
                direction: +pathParams.direction,
              })
            }
            need_link_sale_order_id={+pathParams.sale_order_id || undefined}
            need_link_purchase_order_id={
              +pathParams?.purchase_order_id || undefined
            }
          />
        );
      }

      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ProductReceiptForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new ProductReceiptRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={
              <ProductReceiptFormHeader
                id={+pathParams.id}
                direction={
                  pathParams.direction !== undefined
                    ? +pathParams.direction
                    : model.direction
                }
                isEditing={true}
              />
            }
          />
        );

      break;
    default:
      com = <ProductReceiptList />;
  }

  return com;
};

export default ProductReceiptPage;
