import { Button, Input, Popconfirm, Table, Tooltip } from "antd";
import ProductInventoryCheckingDetailCollection from "common/collections/ProductInventoryCheckingDetailCollection";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingDetailRepository from "common/repositories/ProductInventoryCheckingDetailRepository";
import { TableColumnsType } from "common/types/Table";
import QuantityInput from "components/QuantityInput";
import TableDelete from "components/table/TableDelete";
import ProductVariantSearch from "features/product/ProductVariantSearch";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconHistory, IconX } from "@tabler/icons-react";
import ProductInventoryCheckingDetailLogModal from "./ProductInventoryCheckingDetailLogModal";
import { ProductJsonPublic } from "common/types/Product";

const ProductInventoryCheckingDetailList = ({
  detailItems,
  productIventoryCheckingModel,
  setDetailItems,
  defaultWarehouseId,
  isAllowEdit,
}: {
  detailItems: ProductInventoryCheckingDetailModel[];
  productIventoryCheckingModel: ProductInventoryCheckingModel;
  setDetailItems: (items: ProductInventoryCheckingDetailModel[]) => void;
  defaultWarehouseId: number;
  isAllowEdit: boolean;
}) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [
    productIventoryCheckingDetailModel,
    setProductIventoryCheckingDetailModel,
  ] = useState<ProductInventoryCheckingDetailModel>(
    new ProductInventoryCheckingDetailModel(
      ProductInventoryCheckingDetailModel.getDefaultData()
    )
  );
  const confirmingDeleteSku = useRef("");
  const [loading, setLoading] = useState(false);
  const isDraft = isAllowEdit;

  const fetchProductInventoryCheckingDetail = useCallback(
    async (id: number) => {
      setLoading(true);
      //fetch all
      let foundItem: ProductInventoryCheckingDetailModel[] = [];
      let page = 1;
      let limit = 50;
      let fetchedCollection = new ProductInventoryCheckingDetailCollection();
      do {
        fetchedCollection =
          await new ProductInventoryCheckingDetailRepository().getItems({
            filters: {
              product_inventory_checking_id: id,
              page,
              limit,
              sortby: "id",
              sorttype: "ASC",
              keyword: "",
              status: 0,
            },
          });

        if (!fetchedCollection.hasError()) {
          fetchedCollection.items.forEach(
            (item: ProductInventoryCheckingDetailModel) => {
              foundItem.push(item);
            }
          );
        }
        page++;
      } while (
        !fetchedCollection.hasError() &&
        fetchedCollection.items.length === limit
      );

      setLoading(false);
      setDetailItems(foundItem);
    },
    [setDetailItems]
  );

  useEffect(() => {
    if (productIventoryCheckingModel.id > 0) {
      fetchProductInventoryCheckingDetail(productIventoryCheckingModel.id);
    }
  }, [productIventoryCheckingModel.id, fetchProductInventoryCheckingDetail]);

  const doRemoveRowWithSku = useCallback(
    (sku: string) => {
      setDetailItems(detailItems.filter((item) => item.product_sku !== sku));
      confirmingDeleteSku.current = "";
    },
    [detailItems, setDetailItems]
  );
  const onSelectVariant = useCallback(
    (product_variant: ProductJsonPublic) => {
      const foundItem = detailItems.find(
        (r) => r.product_id === product_variant.id
      );

      if (foundItem) {
      } else {
        const system_quan = product_variant.quantities.find(
          (item) => item.store_id === defaultWarehouseId
        );

        const itemNew: ProductInventoryCheckingDetailModel =
          new ProductInventoryCheckingDetailModel({
            ...ProductInventoryCheckingDetailModel.getDefaultData(),
            product_id: product_variant.id,
            product_sku: product_variant.sku,
            system_quantity: system_quan ? system_quan.quantity : 0,
            quantity: 0,
            quality: 100,
          });

        setDetailItems([...detailItems, itemNew]);
      }
    },
    [defaultWarehouseId, detailItems, setDetailItems]
  );

  function onUpdateQuantity(
    item: ProductInventoryCheckingDetailModel,
    quantity: number
  ) {
    // Tạo một bản sao của mảng hiện tại
    const itemsPrev = [...detailItems];

    // Tìm index của item cần cập nhật
    const index = itemsPrev.findIndex((i) => i.product_id === item.product_id);
    if (index !== -1) {
      // Cập nhật số lượng của item
      itemsPrev[index].quantity = quantity;
    }

    // Cập nhật lại mảng detailItems
    setDetailItems(itemsPrev);
  }

  function onUpdateQuality(
    item: ProductInventoryCheckingDetailModel,
    quality: number
  ) {
    // Tạo một bản sao của mảng hiện tại
    const itemsPrev = [...detailItems];

    // Tìm index của item cần cập nhật
    const index = itemsPrev.findIndex((i) => i.product_id === item.product_id);

    if (index !== -1) {
      // Cập nhật chất lượng của item
      itemsPrev[index].quality = quality;
    }

    // Cập nhật lại mảng detailItems
    setDetailItems(itemsPrev);
  }

  function onUpdateNote(
    item: ProductInventoryCheckingDetailModel,
    note: string
  ) {
    // Tạo một bản sao của mảng hiện tại
    const itemsPrev = [...detailItems];

    // Tìm index của item cần cập nhật
    const index = itemsPrev.findIndex((i) => i.product_id === item.product_id);

    if (index !== -1) {
      // Cập nhật chất lượng của item
      itemsPrev[index].note = note;
    }

    // Cập nhật lại mảng detailItems
    setDetailItems(itemsPrev);
  }

  const columns: TableColumnsType<ProductInventoryCheckingDetailModel> = [
    {
      title: t("common:table.index"),
      dataIndex: "id",
      align: "center",
      render: (_, __, index: number) => {
        return <>{index + 1}</>;
      },
      width: 50,
    },

    {
      title: t("productinventorychecking:form.product_info"),
      dataIndex: "produc_id",
      render: (product_variant_id: number, record) => {
        return (
          <div>
            {/* <TextProductVariant id={product_variant_id} />; */}
            <p>{record.product_sku}</p>
          </div>
        );
      },
    },
    {
      title: t("productinventorychecking:form.system_quantity"),
      dataIndex: "system_quantity",
      width: 150,
      align: "center",
    },
    {
      title: t("productinventorychecking:form.quantity"),
      dataIndex: "quantity",
      width: 140,
      align: "center",
      render: (_: any, record: ProductInventoryCheckingDetailModel) => {
        return (
          <>
            {!isAllowEdit ? (
              <Input
                style={{ width: "100%" }}
                size="large"
                value={record.quantity}
                className="text-center"
                disabled
              />
            ) : (
              <QuantityInput
                key={record.id}
                value={record.quantity}
                onChange={(quantity: number | null) => {
                  onUpdateQuantity(record, quantity ?? 1);
                }}
              />
            )}
          </>
        );
      },
    },

    {
      title: t("Chất lượng (%)"),
      dataIndex: "quality",
      width: 140,
      align: "center",
      render: (_: any, record: ProductInventoryCheckingDetailModel) => {
        return (
          <>
            {!isAllowEdit ? (
              <Input
                style={{ width: "100%" }}
                size="large"
                value={record.quality}
                className="text-center"
                disabled
              />
            ) : (
              <QuantityInput
                key={record.id}
                value={record.quality}
                min={0}
                max={100}
                onChange={(quantity: number | null) => {
                  if (quantity && quantity <= 100) {
                    onUpdateQuality(record, quantity ?? 1);
                  }
                }}
              />
            )}
          </>
        );
      },
    },

    {
      title: t("Ghi chú"),
      dataIndex: "note",
      width: 140,
      align: "center",
      render: (_: any, record: ProductInventoryCheckingDetailModel) => {
        return (
          <>
            <Input
              disabled={!isAllowEdit}
              style={{ width: "100%" }}
              size="large"
              value={record.note}
              onChange={(e) => {
                onUpdateNote(record, e.target.value);
              }}
              className="text-center"
            />
          </>
        );
      },
    },

    {
      title: t("productinventorychecking:form.deviant_quantity"),
      dataIndex: "deviant_quantity",
      width: 100,
      align: "center",
      render: (_: any, record: ProductInventoryCheckingDetailModel) => {
        const deviant_quantity = record.quantity - record.system_quantity;

        return (
          <>
            {/* {deviant_quantity > 0 ? "" : "-"} */}
            {deviant_quantity}
          </>
        );
      },
    },
    ...(isDraft
      ? [
          {
            title: " ",
            dataIndex: "operation",
            width: 50,
            render: (_: any, record: ProductInventoryCheckingDetailModel) => {
              return (
                <>
                  <div className="flex items-center content-between">
                    {record.id === 0 ? (
                      <Popconfirm
                        title={t("common:table.confirm_delete_title")}
                        placement="topRight"
                        onConfirm={() => {
                          doRemoveRowWithSku(record.product_sku);
                        }}
                        onCancel={() => (confirmingDeleteSku.current = "")}
                        okText={t("common:table.confirm_ok")}
                        cancelText={t("common:table.confirm_cancel")}
                      >
                        <Button
                          type="link"
                          size="small"
                          className="text-red-200 hover:text-red-500"
                          onClick={() =>
                            (confirmingDeleteSku.current = record.product_sku)
                          }
                        >
                          <IconX />
                        </Button>
                      </Popconfirm>
                    ) : (
                      <TableDelete
                        error_translate_prefix="productinventorychecking:form.error"
                        onDeleteCallback={(id) => {
                          setDetailItems(
                            detailItems.filter((item) => item.id !== id)
                          );
                        }}
                        repository={
                          new ProductInventoryCheckingDetailRepository()
                        }
                        id={record.id}
                      />
                    )}
                    {record.changelog.length > 0 ? (
                      <Tooltip
                        title={t("productinventorychecking:tooltip_changelog")}
                        placement={"top"}
                      >
                        <Button
                          type="link"
                          size="small"
                          className="text-gray-500 hover:text-gray-700"
                          onClick={() => {
                            setModalVisible(true);
                            setProductIventoryCheckingDetailModel(record);
                          }}
                        >
                          <IconHistory size={18} />
                        </Button>
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              );
            },
          },
        ]
      : [
          {
            title: " ",
            dataIndex: "operation",
            width: 50,
            render: (_: any, record: ProductInventoryCheckingDetailModel) => {
              return (
                <>
                  <div className="flex items-center content-between">
                    {record.changelog.length > 0 ? (
                      <Tooltip
                        title={t("productinventorychecking:tooltip_changelog")}
                        placement={"top"}
                      >
                        <Button
                          type="link"
                          size="small"
                          className="text-gray-500 hover:text-gray-700"
                          onClick={() => {
                            setModalVisible(true);
                            setProductIventoryCheckingDetailModel(record);
                          }}
                        >
                          <IconHistory size={18} />
                        </Button>
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              );
            },
          },
        ]),
  ];

  return (
    <div>
      <div className="mb-2">
        <ProductVariantSearch
          filter={{
            store_id: defaultWarehouseId,
          }}
          placeholder={
            !defaultWarehouseId
              ? "Vui lòng chọn kho"
              : "Nhập SKU , Tên sản phẩm..."
          }
          disabled={!defaultWarehouseId}
          // disabled={
          //   productIventoryCheckingModel.status !==
          //     ProductInventoryChecking.STATUS_PENDING ||
          //   defaultWarehouseId === 0
          // }
          size="large"
          onSelect={(product_variant) => {
            onSelectVariant(product_variant);
          }}
        />
      </div>

      <Table
        size="small"
        className="w-full table-dashboard"
        dataSource={detailItems}
        columns={columns}
        rowKey={"product_id"}
        bordered
        loading={loading}
        pagination={{ hideOnSinglePage: true }}
        footer={() => null}
        summary={() => {
          const system_quantity_total = detailItems.reduce(
            (accumulator, current) =>
              accumulator + Number(current["system_quantity"]),
            0
          );

          const quantity_total = detailItems.reduce(
            (accumulator, current) => accumulator + Number(current["quantity"]),
            0
          );

          return (
            <>
              {false ? (
                <>
                  <Table.Summary fixed>
                    <Table.Summary.Row className="text-center">
                      <Table.Summary.Cell
                        colSpan={1}
                        index={0}
                        className="font-bold"
                      >
                        {t("producttransmit:form.quantity_total")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className="font-bold">
                        {/* {system_quantity_total} */}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} className="font-bold">
                        {system_quantity_total}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} className="font-bold">
                        {quantity_total}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="font-bold">
                        {quantity_total - system_quantity_total}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                </>
              ) : null}
            </>
          );
        }}
      />
      {modalVisible ? (
        <ProductInventoryCheckingDetailLogModal
          open={modalVisible}
          setOpen={(hidden: boolean) => {
            setModalVisible(hidden);
            setProductIventoryCheckingDetailModel(
              new ProductInventoryCheckingDetailModel(
                ProductInventoryCheckingDetailModel.getDefaultData()
              )
            );
          }}
          productIventoryCheckingDetailModel={
            productIventoryCheckingDetailModel
          }
        />
      ) : null}
    </div>
  );
};

export default ProductInventoryCheckingDetailList;
