import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type {
  EcomChannelProductJson,
  Variant,
  Image,
} from "common/types/EcomChannelProduct";

class EcomChannelProductModel
  extends BaseModel
  implements BaseModelJson<EcomChannelProductJson>
{
  id: string;
  slug: string;
  tags: string;
  title: string;
  description: string;
  type: string;
  vendor: string;
  datecreated: number;
  datemodified: number;
  images: Image[];
  variants: Variant[];

  constructor(json: EcomChannelProductJson) {
    super();

    this.id = json.id || "";
    this.slug = json.slug || "";
    this.tags = json.tags || "";
    this.title = json.title || "";
    this.description = json.description || "";
    this.type = json.type || "";
    this.vendor = json.vendor || "";
    this.datecreated = json.datecreated || 0;
    this.datemodified = json.datemodified || 0;
    this.images = json.images || [];
    this.variants = json.variants || [];
  }

  static getDefaultData(): EcomChannelProductJson {
    return {
      id: "",
      slug: "",
      tags: "",
      title: " ",
      description: "",
      type: "",
      vendor: "",
      datecreated: 0,
      datemodified: 0,
      images: [],
      variants: [],
    };
  }

  toJson(): EcomChannelProductJson {
    return {
      id: this.id,
      slug: this.slug,
      tags: this.tags,
      title: this.title,
      description: this.description,
      type: this.type,
      vendor: this.vendor,
      datecreated: this.datecreated,
      datemodified: this.datemodified,
      images: this.images,
      variants: this.variants,
    };
  }
}

export default EcomChannelProductModel;
