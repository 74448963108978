import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import PageHeader from "components/page/PageHeader";
import { ReactNode } from "react";

const ShopMegaMenuHeader = ({ children }: { children?: ReactNode }) => {
	const { t } = useTranslation();
	return (
		<PageHeader
			heading={"Mega Menu"}
			siteMenuOpenKey="shophome"
			siteMenuSelectedKey="/shopmegamenu">
			<Row gutter={16} className="text-right">
				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					{children}
				</Col>
			</Row>
		</PageHeader>
	);
};

export default ShopMegaMenuHeader;
