import React from "react";
import { useTranslation } from "react-i18next";

import { Nav } from "common/types/PageNavigation";

import PageHeader from "components/page/PageHeader";

const CollectionFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("collection:heading_nav"),
      link: "/collection",
    },
    {
      text: t(isEditing ? "collection:heading_edit" : "collection:heading_add"),
      link: "",
    },
  ];

  return (
    <PageHeader
      heading={t(
        isEditing ? "collection:heading_edit" : "collection:heading_add"
      )}
      nav={nav}
      siteMenuOpenKey="content"
      siteMenuSelectedKey="/collection"
      siteMenuHidden
    ></PageHeader>
  );
};

export default CollectionFormHeader;
