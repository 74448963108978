import StoreModel from 'common/models/StoreModel';
import StoreRepository from 'common/repositories/StoreRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import StoreForm from 'features/store/form/StoreForm';
import StoreFormHeader from 'features/store/form/StoreFormHeader';
import StoreList from 'features/store/list/StoreList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const StorePage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<StoreModel>(
    new StoreModel(StoreModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new StoreModel(StoreModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = <StoreForm model={new StoreModel(StoreModel.getDefaultData())} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <StoreForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new StoreRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<StoreFormHeader isEditing={true} />}
          />
        );
      break;
    default:
      com = <StoreList />;
  }

  return com;
};

export default StorePage;
