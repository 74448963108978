import { Tooltip } from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import Role from "common/constants/Role";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import TextCashflowReceiptDirection from "components/TextCashflowReceiptDirection";
import TextMoney from "components/TextMoney";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconCashBanknote } from "@tabler/icons-react";

const OrderCashflowReceiptItem = ({ data }: { data: CashflowReceiptModel }) => {
  const { t } = useTranslation();

  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );

  const havePermission = useMemo(() => {
    return (
      hasRoleAndLimit(Role.CASHFLOW_EDIT, data.store_id) ||
      hasRoleAndLimit(Role.CASHFLOW_STATUS_APPROVE, data.store_id) ||
      hasRoleAndLimit(Role.CASHFLOW_STATUS_COMPLETE, data.store_id) ||
      hasRoleAndLimit(Role.CASHFLOW_STATUS_CANCEL, data.store_id)
    );
  }, [hasRoleAndLimit, data.store_id]);

  const boxClassName = useMemo(() => {
    if (data.status === CashflowReceipt.STATUS_COMPLETED) {
      return "text-green-500";
    } else if (data.status === CashflowReceipt.STATUS_CANCELLED) {
      return "text-red-500";
    } else if (data.status === CashflowReceipt.STATUS_DRAFT) {
      return "text-grey-500";
    } else {
      return "text-yellow-500";
    }
  }, [data]);

  return (
    <>
      <Tooltip
        mouseEnterDelay={0.4}
        title={
          <div className="text-xs leading-5">
            <div className="uppercase">
              <TextCashflowReceiptDirection direction={data.direction} /> #
              {data.id}
            </div>
            <div>[{CashflowReceiptModel.getMethod(data.method)?.label}]</div>
            <div>
              {t("cashflowreceipt:value")}: <TextMoney money={data.value} />
            </div>
            <div>
              {t("cashflowreceipt:status")}:{" "}
              {CashflowReceiptModel.getStatus(data.status)?.label}
            </div>
          </div>
        }
      >
        <Link
          to={"/cashflowreceipt/edit/id/" + data.id}
          target="_blank"
          onClick={havePermission ? undefined : (e) => e.preventDefault()}
          className="cursor-pointer block px-1 py-0.5 border border-white hover:border-blue-200 mb-0.5 rounded-sm text-blue-600"
        >
          <IconCashBanknote
            className={"mr-1 -mt-0.5 " + boxClassName}
            size="22"
            strokeWidth={1.2}
          />
          <span className="text-xs">
            <TextMoney money={data.value} />
          </span>
        </Link>
      </Tooltip>
    </>
  );
};

export default OrderCashflowReceiptItem;
