import EcomChannelOrder from "common/constants/EcomChannelOrder";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import type {
  EcomChannelOrderJson,
  LineItem,
} from "common/types/EcomChannelOrder";

class EcomChannelOrderModel
  extends BaseModel
  implements BaseModelJson<EcomChannelOrderJson>
{
  id: string;
  invoiceid: string;
  pricesell: number;
  pricediscount: number;
  priceshipping: number;
  pricefinal: number;
  currency: string;
  discountcode: string;
  billingcompany: string;
  billingaddress: string;
  billingregion: number;
  billingsubregion: number;
  billingsubsubregion: number;
  billingzip: string;
  billingcountry: string;
  billingfullname: string;
  billingphone: string;
  shippingfullname: string;
  shippingcompany: string;
  shippingaddress: string;
  shippingregion: number;
  shippingsubregion: number;
  shippingsubsubregion: number;
  shippingzip: string;
  shippingcountry: string;
  shippingphone: string;
  shippingtrackingcode: string;
  weight: number;
  note: string;
  tags: string;
  fulfillmentstatus: number;
  financialstatus: number;
  datecreated: number;
  datemodified: number;
  dateclosed: number;
  datecancelled: number;
  locationid: number;
  lineitems: LineItem[];

  constructor(json: EcomChannelOrderJson) {
    super();

    this.id = json.id || "";
    this.invoiceid = json.invoiceid || "";
    this.pricesell = json.pricesell || 0;
    this.pricediscount = json.pricediscount || 0;
    this.priceshipping = json.priceshipping || 0;
    this.pricefinal = json.pricefinal || 0;
    this.currency = json.currency || "";
    this.discountcode = json.discountcode || "";
    this.billingcompany = json.billingcompany || "";
    this.billingaddress = json.billingaddress || "";
    this.billingregion = json.billingregion || 0;
    this.billingsubregion = json.billingsubregion || 0;
    this.billingsubsubregion = json.billingsubsubregion || 0;
    this.billingzip = json.billingzip || "";
    this.billingcountry = json.billingcountry || "";
    this.billingfullname = json.billingfullname || "";
    this.billingphone = json.billingphone || "";
    this.shippingfullname = json.shippingfullname || "";
    this.shippingcompany = json.shippingcompany || "";
    this.shippingaddress = json.shippingaddress || "";
    this.shippingregion = json.shippingregion || 0;
    this.shippingsubregion = json.shippingsubregion || 0;
    this.shippingsubsubregion = json.shippingsubsubregion || 0;
    this.shippingzip = json.shippingzip || "";
    this.shippingcountry = json.shippingcountry || "";
    this.shippingphone = json.shippingphone || "";
    this.shippingtrackingcode = json.shippingtrackingcode || "";
    this.weight = json.weight || 0;
    this.note = json.note || "";
    this.tags = json.tags || "";
    this.fulfillmentstatus = json.fulfillmentstatus || 0;
    this.financialstatus = json.financialstatus || 0;
    this.datecreated = json.datecreated || 0;
    this.datemodified = json.datemodified || 0;
    this.dateclosed = json.dateclosed || 0;
    this.datecancelled = json.datecancelled || 0;
    this.locationid = json.locationid || 0;
    this.lineitems = json.lineitems || [];
  }

  static getDefaultData(): EcomChannelOrderJson {
    return {
      id: "",
      invoiceid: "",
      pricesell: 0,
      pricediscount: 0,
      priceshipping: 0,
      pricefinal: 0,
      currency: "",
      discountcode: "",
      billingcompany: "",
      billingaddress: "",
      billingregion: 0,
      billingsubregion: 0,
      billingsubsubregion: 0,
      billingzip: "",
      billingcountry: "",
      billingfullname: "",
      billingphone: "",
      shippingfullname: "",
      shippingcompany: "",
      shippingaddress: "",
      shippingregion: 0,
      shippingsubregion: 0,
      shippingsubsubregion: 0,
      shippingzip: "",
      shippingcountry: "",
      shippingphone: "",
      shippingtrackingcode: "",
      weight: 0,
      note: "",
      tags: "",
      fulfillmentstatus: 0,
      financialstatus: 0,
      datecreated: 0,
      datemodified: 0,
      dateclosed: 0,
      datecancelled: 0,
      locationid: 0,
      lineitems: [],
    };
  }

  toJson(): EcomChannelOrderJson {
    return {
      id: this.id,
      invoiceid: this.invoiceid,
      pricesell: this.pricesell,
      pricediscount: this.pricediscount,
      priceshipping: this.priceshipping,
      pricefinal: this.pricefinal,
      currency: this.currency,
      discountcode: this.discountcode,
      billingcompany: this.billingcompany,
      billingaddress: this.billingaddress,
      billingregion: this.billingregion,
      billingsubregion: this.billingsubregion,
      billingsubsubregion: this.billingsubsubregion,
      billingzip: this.billingzip,
      billingcountry: this.billingcountry,
      billingfullname: this.billingfullname,
      billingphone: this.billingphone,
      shippingfullname: this.shippingfullname,
      shippingcompany: this.shippingcompany,
      shippingaddress: this.shippingaddress,
      shippingregion: this.shippingregion,
      shippingsubregion: this.shippingsubregion,
      shippingsubsubregion: this.shippingsubsubregion,
      shippingzip: this.shippingzip,
      shippingcountry: this.shippingcountry,
      shippingphone: this.shippingphone,
      shippingtrackingcode: this.shippingtrackingcode,
      weight: this.weight,
      note: this.note,
      tags: this.tags,
      fulfillmentstatus: this.fulfillmentstatus,
      financialstatus: this.financialstatus,
      datecreated: this.datecreated,
      datemodified: this.datemodified,
      dateclosed: this.dateclosed,
      datecancelled: this.datecancelled,
      locationid: this.locationid,
      lineitems: this.lineitems,
    };
  }

  static getFulfillmentStatusList(): SelectOption[] {
    return [
      {
        value: EcomChannelOrder.FULFILLMENT_STATUS_NULL,
        label: i18n.t("ecomchannelorder:FULFILLMENT_STATUS_NULL"),
      },
      {
        value: EcomChannelOrder.FULFILLMENT_STATUS_PARTIAL,
        label: i18n.t("ecomchannelorder:FULFILLMENT_STATUS_PARTIAL"),
      },
      {
        value: EcomChannelOrder.FULFILLMENT_STATUS_FULLFILLED,
        label: i18n.t("ecomchannelorder:FULFILLMENT_STATUS_FULLFILLED"),
      },
      {
        value: EcomChannelOrder.FULFILLMENT_STATUS_RESTOCKED,
        label: i18n.t("ecomchannelorder:FULFILLMENT_STATUS_RESTOCKED"),
      },
    ];
  }

  static getFulfillmentStatus(value: number): SelectOption | undefined {
    return this.getFulfillmentStatusList().find((item) => item.value === value);
  }

  static getFinancialStatusList(): SelectOption[] {
    return [
      {
        value: EcomChannelOrder.FINANCIAL_STATUS_PENDING,
        label: i18n.t("ecomchannelorder:FINANCIAL_STATUS_PENDING"),
      },
      {
        value: EcomChannelOrder.FINANCIAL_STATUS_AUTHORIZED,
        label: i18n.t("ecomchannelorder:FINANCIAL_STATUS_AUTHORIZED"),
      },
      {
        value: EcomChannelOrder.FINANCIAL_STATUS_PARTIALLY_PAID,
        label: i18n.t("ecomchannelorder:FINANCIAL_STATUS_PARTIALLY_PAID"),
      },
      {
        value: EcomChannelOrder.FINANCIAL_STATUS_PAID,
        label: i18n.t("ecomchannelorder:FINANCIAL_STATUS_PAID"),
      },
      {
        value: EcomChannelOrder.FINANCIAL_STATUS_PARTIALLY_REFUND,
        label: i18n.t("ecomchannelorder:FINANCIAL_STATUS_PARTIALLY_REFUND"),
      },
      {
        value: EcomChannelOrder.FINANCIAL_STATUS_REFUNDED,
        label: i18n.t("ecomchannelorder:FINANCIAL_STATUS_REFUNDED"),
      },
      {
        value: EcomChannelOrder.FINANCIAL_STATUS_VOIDED,
        label: i18n.t("ecomchannelorder:FINANCIAL_STATUS_VOIDED"),
      },
    ];
  }

  static getFinancialStatus(value: number): SelectOption | undefined {
    return this.getFinancialStatusList().find((item) => item.value === value);
  }
}

export default EcomChannelOrderModel;
