import Order from 'common/constants/Order';
import haravan from 'images/ecom/haravan.png';
import pos from 'images/ecom/pos.png';
import woocommerce from 'images/ecom/woocommerce.png';

const OrderItemEcomPlatformTypeImage = ({
  type,
  alt,
  width,
  height,
  className,
  style,
}: {
  type: number;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const getEcomImage = (type: number): string => {
    let img = "";
    switch (type) {
      case Order.ECOM_PLATFORM_TYPE_POS:
        img = pos;
        break;
      case Order.ECOM_PLATFORM_TYPE_HARAVAN:
        img = haravan;
        break;
      case Order.ECOM_PLATFORM_TYPE_WOO_COMMERCE:
        img = woocommerce;
        break;
    }

    return img;
  };

  return (
    <img
      src={getEcomImage(type)}
      alt={alt}
      width={width}
      height={height}
      className={className}
      style={style}
    />
  );
};

export default OrderItemEcomPlatformTypeImage;
