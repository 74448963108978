import { Table } from "antd";

const ProductReceiptPrintVariable = () => {
  return (
    <>
      <Table
        size="small"
        columns={[
          {
            title: "Biến",
            dataIndex: "code",
            key: "code"
          },
          {
            title: "Giá trị sẽ thay thế khi thêm mẫu in",
            dataIndex: "description",
            key: "description"
          }
        ]}
        dataSource={[
          {
            key: "ORDER_ITEM_ROW",
            code: "[[ORDER_ITEM_ROW]]",
            description: "Thông tin sản phẩm"
          },
          {
            key: "ORDER_ID",
            code: "[[ORDER_ID]]",
            description: "Mã đơn hàng"
          },
          {
            key: "ORDER_DATE",
            code: "[[ORDER_DATE]]",
            description: "Ngày thêm đơn hàng"
          },
          {
            key: "CUSTOMER_FULL_NAME",
            code: "[[CUSTOMER_FULL_NAME]]",
            description: "Họ tên khách hàng"
          },
          {
            key: "CUSTOMER_ID",
            code: "[[CUSTOMER_ID]]",
            description: "Mã khách hàng"
          },
          {
            key: "CUSTOMER_PHONE",
            code: "[[CUSTOMER_PHONE]]",
            description: "Số điện thoại khách hàng"
          },
          {
            key: "CUSTOMER_EMAIL",
            code: "[[CUSTOMER_EMAIL]]",
            description: "Email khách hàng"
          },
          {
            key: "CUSTOMER_ADDRESS",
            code: "[[CUSTOMER_ADDRESS]]",
            description: "Địa chỉ khách hàng"
          },
          {
            key: "CUSTOMER_SUB_SUB_REGION",
            code: "[[CUSTOMER_SUB_SUB_REGION]]",
            description: "Địa chỉ phường khách hàng"
          },
          {
            key: "CUSTOMER_SUB_REGION",
            code: "[[CUSTOMER_SUB_REGION]]",
            description: "Địa chỉ Quận / Huyện khách hàng"
          },
          {
            key: "CUSTOMER_REGION",
            code: "[[CUSTOMER_REGION]]",
            description: "Địa chỉ Tỉnh / Thành Phố khách hàng"
          },
          {
            key: "ORDER_NOTE",
            code: "[[ORDER_NOTE]]",
            description: "Ghi chú trong đơn hàng"
          },
          {
            key: "ORDER_SUBTOTAL",
            code: "[[ORDER_SUBTOTAL]]",
            description: "Thành tiền"
          },
          {
            key: "ORDER_DISCOUNT",
            code: "[[ORDER_DISCOUNT]]",
            description: "Giảm giá"
          },
          {
            key: "ORDER_SHIPPING",
            code: "[[ORDER_SHIPPING]]",
            description: "Phí giao hàng"
          },
          {
            key: "ORDER_TOTAL_AMOUNT",
            code: "[[ORDER_TOTAL_AMOUNT]]",
            description: "Tổng tiền của đơn hàng"
          },
          {
            key: "TAG",
            code: "[[TAG]]",
            description: "Tag ghi chú"
          },
          {
            key: "ORDER_CUSTOMER_PAID",
            code: "[[ORDER_CUSTOMER_PAID]]",
            description: "Tiền khách đưa"
          },
          {
            key: "ORDER_CUSTOMER_RETURN",
            code: "[[ORDER_CUSTOMER_RETURN]]",
            description: "Tiền thối lại khách hàng"
          },
          {
            key: "LOCATION",
            code: "[[LOCATION]]",
            description: "Tên cửa hàng"
          },
          {
            key: "LOCATION_ADDRESS",
            code: "[[LOCATION_ADDRESS]]",
            description: "Địa chỉ cửa hàng"
          },
          {
            key: "LOCATION_PHONE",
            code: "[[LOCATION_PHONE]]",
            description: "Số điẹn thoại cửa hàng"
          },
          {
            key: "CREATOR",
            code: "[[CREATOR]]",
            description: "Nhân viên thêm đơn hàng"
          },
          {
            key: "ORIGIN",
            code: "[[ORIGIN]]",
            description: "Nguồn đơn hàng"
          },
          {
            key: "SHIPPING_CARRIER",
            code: "[[SHIPPING_CARRIER]]",
            description: "Tên người nhận hàng"
          },
          {
            key: "SHIPPING_ADDRESS",
            code: "[[SHIPPING_ADDRESS]]",
            description: "Địa chỉ giao hàng"
          },
          {
            key: "SHIPPING_SUB_SUB_REGION",
            code: "[[SHIPPING_SUB_SUB_REGION]]",
            description: "Địa chỉ Phường / Xã giao hàng"
          },
          {
            key: "SHIPPING_SUB_REGION",
            code: "[[SHIPPING_SUB_REGION]]",
            description: "Địa chỉ Quận / Huyện giao hàng"
          },
          {
            key: "SHIPPING_REGION",
            code: "[[SHIPPING_REGION]]",
            description: "Địa chỉ Tỉnh / Thành phố giao hàng"
          },
          {
            key: "PAYMENT_METHOD",
            code: "[[PAYMENT_METHOD]]",
            description: "Phương thức thanh toán"
          }
        ]}
        pagination={{
          pageSize: 100,
          hideOnSinglePage: true
        }}
      />
    </>
  );
};

export default ProductReceiptPrintVariable;
