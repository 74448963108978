import { Button, Modal } from 'antd';
import EcomModel from 'common/models/EcomModel';
import { useTranslation } from 'react-i18next';

import EcomItemTypeImage from './item/EcomItemTypeImage';

const EcomTypeSelectModal = ({
  open,
  setOpen,
  onSelect,
}: {
  open: boolean;
  setOpen: (v: boolean) => any;
  onSelect: (v: number) => any;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("ecom:heading_select_type")}
      footer={null}
      width={615}
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
    >
      {EcomModel.getTypeList().map((item) => (
        <Button
          key={item.value}
          onClick={(e) => {
            e.preventDefault();
            onSelect(item.value);
          }}
          className="mr-4"
          style={{ height: 160 }}
        >
          <EcomItemTypeImage
            type={item.value}
            alt={item.label}
            width={140}
            style={{ cursor: "pointer" }}
          />
        </Button>
      ))}
    </Modal>
  );
};

export default EcomTypeSelectModal;
