import { Avatar, Tag } from "antd";
import ProjectCollection from "common/collections/ProjectCollection";
import Role from "common/constants/Role";
import ProjectModel from "common/models/ProjectModel";
import ProjectRepository from "common/repositories/ProjectRepository";
import { FilterProject } from "common/types/Project";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import UserIdAvatar from "components/UserIdAvatar";
import ProjectListHeader from "features/project/list/ProjectListHeader";
import ProjectListReport from "features/project/list/ProjectListReport";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProjectListFilter from "./ProjectListFilter";

const ProjectList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterProject = useMemo(
    () => ProjectRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterProject>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ProjectModel[]>([]);
  const fetchData = useCallback(async (): Promise<ProjectCollection> => {
    let collection = await new ProjectRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ProjectModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
    },
    {
      title: t("project:name"),
      key: "name",
      render: (name, record) => {
        return (
          <TableLinkText
            title={t("common:table.detail")}
            link={"/project/detail/id/" + record.id}>
            {name}
          </TableLinkText>
        );
      },
    },
    {
      title: t("project:count_story"),
      key: "count_story",
      align: "center",
      width: 100,
    },
    {
      title: t("project:member"),
      key: "members",
      width: 120,
      render: (members: string[]) =>
        members.length > 0 ? (
          <Avatar.Group maxCount={5} size="small">
            {members.map((i) => (
              <UserIdAvatar key={i} id={+i} hideTooltip={false} />
            ))}
          </Avatar.Group>
        ) : (
          ""
        ),
    },
    {
      title: t("common:status"),
      key: "status",
      width: 120,
      render: (status: number) => {
        let statusInfo = ProjectModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
    },
    {
      title: t("project:date_started"),
      key: "date_started",
      width: 120,
      render: (_: any, record: ProjectModel) =>
        record.date_started !== undefined ? (
          <TextDateTime ts={record.date_started} />
        ) : (
          "---"
        ),
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_: any, record: ProjectModel) => (
        <RoleCheck roles={[Role.PROJECT_MANAGE]} hideOnFail>
          <TableEdit link={"/project/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="project:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ProjectRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <ProjectListHeader />

      <ProjectListReport />

      <ProjectListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterProject, ProjectModel, ProjectCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ProjectList;
