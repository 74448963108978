import Link from "@tiptap/extension-link";

export type LinkAttribute = {
  href: string;
  target?: string;
  title?: string;
  rel?: string;
};

export const LINK_EXTENSION_NAME = "linkextra";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    [LINK_EXTENSION_NAME]: {
      /**
       * Set a link mark
       */
      setLinkExtra: (attributes: LinkAttribute) => ReturnType;
      /**
       * Toggle a link mark
       */
      toggleLinkExtra: (attributes: LinkAttribute) => ReturnType;
      /**
       * Unset a link mark
       */
      unsetLink: () => ReturnType;
    };
  }
}

const HtmlEditorExtensionFullLink = Link.extend({
  name: LINK_EXTENSION_NAME,
  priority: 1000,
  addAttributes() {
    return {
      ...this.parent?.(),
      title: {
        default: "",
      },
      rel: {
        default: "",
      },
    };
  },
  addCommands() {
    return {
      setLinkExtra:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark("link", attributes);
        },
      toggleLinkExtra:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark("link", attributes);
        },
      unsetLink:
        () =>
        ({ commands }) => {
          return commands.unsetMark("link");
        },
    };
  },
});

export default HtmlEditorExtensionFullLink;
