import { Tag } from "antd";
import ShipperApplyCollection from "common/collections/ShipperApplyCollection";
import Role from "common/constants/Role";
import ShipperApply from "common/constants/ShipperApply";
import ShipperApplyModel from "common/models/ShipperApplyModel";
import ShipperApplyRepository from "common/repositories/ShipperApplyRepository";
import { FilterShipperApply } from "common/types/ShipperApply";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import TextDateTimeYmd from "components/TextDateTimeYmd";
import TextMoney from "components/TextMoney";
import ShipperApplyListHeader from "features/shipperapply/list/ShipperApplyListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IconCar,
  IconGenderFemale,
  IconGenderMale,
  IconMotorbike,
} from "@tabler/icons-react";

import ShipperApplyListFilter from "./ShipperApplyListFilter";

const ShipperApplyList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShipperApply = useMemo(
    () => ShipperApplyRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterShipperApply>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ShipperApplyModel[]>([]);
  const fetchData = useCallback(async (): Promise<ShipperApplyCollection> => {
    let collection = await new ShipperApplyRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);

    return collection;
  }, [filters]);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ShipperApplyModel> = [
    {
      title: t("common:sid"),
      key: "id",
      width: 50,
      fixed: "left",
    },
    {
      title: t("common:full_name"),
      key: "full_name",
    },
    {
      title: t("common:gender"),
      key: "gender",
      align: "center",
      width: 80,
      render: (gender: number) => {
        return gender === ShipperApply.GENDER_MALE ? (
          <IconGenderMale size={20} className="text-green-500" />
        ) : gender === ShipperApply.GENDER_FEMALE ? (
          <IconGenderFemale size={20} className="text-red-500" />
        ) : (
          "--"
        );
      },
    },
    {
      title: t("common:phone"),
      key: "phone",
      render: (phone, record) => {
        return (
          <>
            {phone}
            {record.email.length > 0 ? (
              <div className="text-xs text-gray-400">{record.email}</div>
            ) : null}
          </>
        );
      },
    },
    {
      title: t("shipperapply:birthday"),
      key: "birthday",
      width: 90,
      render: (_: any, record: ShipperApplyModel) => (
        <TextDateTimeYmd
          ts={record.birthday}
          format={"DDMMYYYY"}
          dateFormat={"DD/MM/YYYY"}
        />
      ),
    },
    {
      title: (
        <>
          <span title={t("shipperapply:marriagestatus")}>
            {t("shipperapply:marriagestatus_short")}
          </span>
        </>
      ),
      togglelabel: t("shipperapply:marriagestatus"),
      key: "marriage_status",
      width: 100,
      render: (marriage_status: number) => {
        let statusInfo = ShipperApplyModel.getMarriageStatus(marriage_status);
        return statusInfo?.label;
      },
    },
    {
      title: t("shipperapply:vehicletype"),
      key: "vehicle_type",
      align: "center",
      width: 100,
      render: (vehicle_type: number) => {
        return vehicle_type === ShipperApply.TYPE_MOTOBIKE ? (
          <IconMotorbike size={20} />
        ) : vehicle_type === ShipperApply.TYPE_CAR ? (
          <IconCar size={20} />
        ) : null;
      },
    },
    {
      title: t("shipperapply:salary"),
      key: "salary",
      width: 100,
      render: (salary: number) => {
        return <TextMoney money={salary} />;
      },
    },
    {
      title: t("common:status"),
      key: "status",
      render: (status: number) => {
        let statusInfo = ShipperApplyModel.getStatus(status);
        return <Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>;
      },
      width: 100,
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      render: (ts: number) => {
        return <TextDateTime ts={ts} />;
      },
      width: 90,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 95,
      toggletype: "trigger",
      render: (_: any, record: ShipperApplyModel) => (
        <RoleCheck roles={[Role.ECOM_SHIPPER_APPLY_MANAGE]} hideOnFail>
          <TableEdit link={"/shipperapply/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="shipperapply:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ShipperApplyRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <ShipperApplyListHeader />

      <ShipperApplyListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<
        FilterShipperApply,
        ShipperApplyModel,
        ShipperApplyCollection
      >
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "shipper-apply",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ShipperApplyList;
