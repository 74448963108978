import { Alert, Button, Col, Form, Input, Row } from "antd";
import Store from "common/constants/Store";
import StoreModel from "common/models/StoreModel";
import StoreRepository from "common/repositories/StoreRepository";
import AddressInput from "components/addressinput/AddressInput";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import RegionPicker from "components/RegionPicker";
import StoreEditorHeader from "features/store/form/StoreFormHeader";
import StoreWarehouseList from "features/store/list/StoreWarehouseList";
import SelectWarehouse from "features/store/SelectWarehouse";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LocationPicker from "components/LocationPicker";

const StoreEditor = ({ model }: { model: StoreModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;
  const [loading, setLoading] = useState<boolean>(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [warehouseListIds, setWarehouseListIds] = useState<number[]>(model.warehouse_ids.map(item => item.id));
  const [valueSelected, setValueSelected] = useState<number>(0);
  const [defaultValues, setDefaultValues] = useState<number[]>([]);

  const handleChangeWarehouseList = () => {
    setWarehouseListIds([
      ...warehouseListIds.filter((idx) => idx !== valueSelected),
      valueSelected,
    ]);
    setDefaultValues([]);
  };

  const initialValues = {
    name: model.name,
    code: model.code,
    phone: model.phone,
    road: model.road,
    link_map: model.link_map,
    show_web: model.show_web,
    localtion: {
      country_id: 241,
      province_id: model.province,
      district_id: model.district,
      ward_id: model.ward,

    },
    address: {
      address: model.address,
      lat: model.lat,
      long: model.long,
      map_place_id: "",
    },
    warehouse_ids: model.warehouse_ids,
    status: model.status || Store.STATUS_ENABLE,
    tax_seller_name: model.tax_seller_name,
    tax_seller_address: model.tax_seller_address,
    tax_seller_phone: model.tax_seller_phone,
    tax_seller_account_number: model.tax_seller_account_number,
    tax_seller_tax_number: model.tax_seller_tax_number,

    regions: {
      shipping_province: model.province,
      shipping_district: model.district,
      shipping_ward: model.ward,
    },
  };
  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: any = {
        ...StoreModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        code: formData.code,
        phone: formData.phone,
        address: formData.address.address,
        link_map: formData.link_map,
        lat: formData.address.lat,
        long: formData.address.long,
        warehouse_id: warehouseListIds?.[0] || undefined,
        tax_seller_name: formData.tax_seller_name,
        tax_seller_address: formData.tax_seller_address,
        tax_seller_phone: formData.tax_seller_phone,
        tax_seller_account_number: formData.tax_seller_account_number,
        tax_seller_tax_number: formData.tax_seller_tax_number,
        status: formData.status,
        show_web: formData.show_web,

        country_id: 241,
        road: formData.road,
        district_id: formData.regions.shipping_district,
        ward_id: formData.regions.shipping_ward,
        province_id: formData.regions.shipping_province,
      };

      return submitData;
    },
    [model.id, warehouseListIds]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    // doPrepareData(formData);
    // console.log(doPrepareData(formData))
    let myObj: StoreModel = await new StoreRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={StoreModel.getStatusList()}
      />
      <FormSelect
        label={t("common:showweb")}
        name="show_web"
        required
        options={StoreModel.getShowList()}
      />
    </div>
  );



  return (
    <>
      <StoreEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("store:form.success.update")
            : t("store:form.success.add")
        }
        redirectOnSuccess="/store"
        error_translate_prefix="store:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("store:form.section_info")}
          subtitle={t("store:form.section_info_subtitle")}
          divider
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("store:name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("store:form.error.error_name_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label={t("store:code")}
                name="code"
                tooltip={t("store:form.help_code")}
                rules={[
                  {
                    required: true,
                    message: t("store:form.error.error_code_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:phone")} name="phone">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("store:address")} name="address">
                <AddressInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:link_map")} name="link_map">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="regions" noStyle>
            <LocationPicker />
          </Form.Item>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("Tên đường")} name="road">
                <Input />
              </Form.Item>
            </Col>
          </Row>

        </FormSection>

        <FormSection
          title={t("store:form.section_warehouse_info")}
          subtitle={""}
          divider
        >
          <Row gutter={16}>
            <Col md={20} xs={24}>
              <Form.Item label="">
                <SelectWarehouse
                  placeholder={t("store:form.select_warehouse")}
                  multiple={false}
                  onChange={(ids: number[]) => {
                    setValueSelected(ids[0]);
                    setDefaultValues(ids);
                  }}
                  values={defaultValues}
                />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Button
                size="middle"
                block
                type="default"
                loading={false}
                onClick={handleChangeWarehouseList}
              >
                {t("store:form.choose")}
              </Button>
            </Col>
          </Row>

          <Col>
            <StoreWarehouseList
              warehouse_ids={warehouseListIds}
              handleWarehouseDeleteId={(id: number) => {
                setWarehouseListIds(
                  warehouseListIds.filter((idx) => idx !== id)
                );
              }}
            />
          </Col>
        </FormSection>


        <FormSection
          title={t("store:form.section_tax")}
          subtitle={t("store:form.section_tax_subtitle")}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("store:tax_seller_name")}
                name="tax_seller_name"
              >
                <Input placeholder={t("store:tax_seller_name_placeholder")} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("store:tax_seller_phone")}
                name="tax_seller_phone"
              >
                <Input placeholder={t("store:tax_seller_phone_placeholder")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("store:tax_seller_address")}
                name="tax_seller_address"
              >
                <Input
                  placeholder={t("store:tax_seller_address_placeholder")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("store:tax_seller_tax_number")}
                name="tax_seller_tax_number"
              >
                <Input
                  placeholder={t("store:tax_seller_tax_number_placeholder")}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("store:tax_seller_account_number")}
                name="tax_seller_account_number"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default StoreEditor;
