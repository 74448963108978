import { Form, Input, InputNumber } from "antd";
import Role from "common/constants/Role";
import ProductVariantModel from "common/models/ProductVariantModel";
import { ProductVariantJsonAddEdit } from "common/types/ProductVariant";
import FormSelect from "components/form/FormSelect";
import MoneyInput from "components/MoneyInput";
import RoleCheck from "components/RoleCheck";
import ProductVariantColorFormSelect from "features/productvariantcolor/ProductVariantColorFormSelect";
import ProductVariantSizeFormSelect from "features/productvariantsize/ProductVariantSizeFormSelect";
import React from "react";
import { useTranslation } from "react-i18next";

interface EditableCellProps {
  title: React.ReactNode;
  inputType: string;
  editing: boolean;
  children: React.ReactNode;
  require: boolean;
  dataIndex: string;
  record: ProductVariantJsonAddEdit;
}

const ProductVariantFormTableCell: React.FC<EditableCellProps> = ({
  title,
  inputType,
  editing,
  children,
  dataIndex,
  require,
  record,
  ...restProps
}) => {
  const { t } = useTranslation();

  const getInput = (dataIndex: string) => {
    switch (inputType) {
      case "size":
        return (
          <Form.Item noStyle>
            <ProductVariantSizeFormSelect name="size" label={""} noStyle />
          </Form.Item>
        );
      case "color":
        return (
          <Form.Item noStyle>
            <ProductVariantColorFormSelect name="color" label={""} noStyle />
          </Form.Item>
        );
      case "price":
        return (
          <Form.Item name={dataIndex} noStyle>
            <MoneyInput style={{ width: 140 }} />
          </Form.Item>
        );
      case "cost":
        return (
          <RoleCheck
            roles={[Role.PRODUCT_VIEW_COST]}
            componentOnFail={
              <div>
                <Input
                  disabled
                  defaultValue={"*******"}
                  title={t("productvariant:cost_not_permission")}
                />
              </div>
            }
          >
            <Form.Item name={dataIndex} noStyle>
              <MoneyInput style={{ width: 140 }} />
            </Form.Item>
          </RoleCheck>
        );
      case "weight":
        return (
          <Form.Item name={dataIndex} noStyle>
            <InputNumber addonAfter="g" step={50} min={0} />
          </Form.Item>
        );

      case "sku":
        if (record.sku.length > 0) {
          return (
            <Form.Item name={dataIndex} noStyle>
              <Input readOnly={true} disabled={true} />
            </Form.Item>
          );
        } else {
          return (
            <Form.Item
              name={dataIndex}
              noStyle
              rules={[
                {
                  required: true,
                  message: t(`productvariant:form.error.error_sku_require`),
                },
              ]}
            >
              <Input autoFocus />
            </Form.Item>
          );
        }
      case "status":
        return (
          <Form.Item noStyle>
            <FormSelect
              noStyle
              name="status"
              options={ProductVariantModel.getStatusList()}
            />
          </Form.Item>
        );

      default:
        return (
          <Form.Item
            noStyle
            name={dataIndex}
            rules={[
              {
                required: require ? true : false,
                message: t(
                  `productvariant:form.error.error_${dataIndex}_require`,
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
        );
    }
  };

  return <td {...restProps}>{editing ? getInput(dataIndex) : children}</td>;
};

export default ProductVariantFormTableCell;
