import { IconEye, IconLoader } from "@tabler/icons-react";
import { Image, Modal, Typography, message } from "antd";
import TagConstant from "common/constants/Tag";
import ProductModel from "common/models/ProductModel";
import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { SelectOption } from "common/types/SelectOption";
import NoPhoto from "components/NoPhoto";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductRelatedComponent from "./children/ProductRelatedComponent";
import ProductPublicModel from "common/models/ProductPublicModel";
import Helper from "common/utils/helper";
import { ProductStatus } from "common/types/Product";
import { Link } from "react-router-dom";
import { TagResourceType } from "common/types/Tag";

import {
  DropResult,
} from "react-beautiful-dnd";
const ProductRelated = ({
  id,
  data,
  onSaveSuccess,
}: {
  id: number;
  data: ProductModel | ProductPublicModel;
  onSaveSuccess: (m: ProductModel | ProductPublicModel) => void;
}) => {
  const { t } = useTranslation();

  const [model, setModel] = useState<ProductModel | ProductPublicModel>(data);
  const [processing, setProcessing] = useState(false);
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [listTagReload, setListTagReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>([]);
  const [activeTag, setActiveTag] = useState<TagModel>(
    new TagModel(TagModel.getDefaultData())
  );



  //submit tag detail
  const onSubmitTagDetail = async (v: SelectOption[], activeTag: TagModel) => {
    setProcessing(true);
    if (v.length > 0) {
      message.loading({ content: "Vui lòng đợi", duration: 1 });

      const response = await new TagRepository().addTagRelated({
        item_id: id,
        relate_type: TagConstant.RELATED_TYPE.ONE_PARENT_TO_MORE_CHILDREN,
        res_item_ids: v.map((item) => item.value),
        tag_code: activeTag.code,
        type: "product",
      });
      if (!response.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue([...existValues, ...v.map((item) => item.value)]);
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error(response.error.errors);
        setProcessing(false);

        return false;
      }
    }
    setProcessing(false);
  };

  //submit reverted tag detail
  const onSubmitRevertTagDetail = async (v: SelectOption[]) => {
    setProcessing(true);
    if (v.length > 0) {
      message.loading({ content: "Vui lòng đợi", duration: 1 });

      const response = await new TagRepository().addTagRelated({
        item_id: id,
        relate_type: TagConstant.RELATED_TYPE.ONE_CHILDREN_TO_MORE_PARENT,
        res_item_ids: v.map((item) => item.value),
        tag_code: activeTag.code,
        type: "product",
      });
      if (!response.hasError()) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        // setExistValue([...existValues, ...v.map((item) => item.value)]);
        // setSelectReload(!selectReload);
        setProcessing(false);
        return true;
      } else {
        message.error(response.error.errors);
        setProcessing(false);
        return false;
      }
    }
    setProcessing(false);
  };

  //delete collection detial
  const onDeleteSelectedItem = async (v: SelectOption) => {
    message.loading({ content: "Vui lòng đợi", duration: 1 });
    setProcessing(true);

    const reponse = await new TagRepository().deleteProductRelatedInTag({
      tags: activeTag.code + "-" + id,
      resource_id: v.value.toString(),
      resource_type: TagResourceType.PRODUCT,
    });
    if (reponse.length === 0) {
      message.success({ content: "Thao tác thành công", duration: 1 });
      // setExistValue(existValues.filter((item) => item !== v.value));
      setSelectReload(!selectReload);
      setProcessing(false);

      return true;
    } else {
      message.error("Thao tác thất bại");
      setProcessing(false);

      return false;
    }
  };

  const handleRemoveCurrentItem = async (v: SelectOption[]) => {
    setProcessing(true);
    if (v.length > 0) {
      const reponse = await new TagRepository().deleteProductRelatedInTag({
        tags: activeTag.code + "-" + id,
        resource_id: v.map((item) => item.value).toString(),
        resource_type: TagResourceType.PRODUCT,
      });
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error("Thao tác thất bại");
        setProcessing(false);
        return false;
      }
    }
  };

  //update existvalue
  const changeTagExistValue = (newTag: ProductModel[]) => {
    setExistValue(newTag.map((item) => item.id));
  };

  //update activeTag
  const changeActiveTagValue = (newTag: TagModel) => {
    setActiveTag(newTag);
  };

  //reload after add struct
  const onAddTagStructSuccess = () => {
    setListTagReload(!listTagReload);
  };

  //detele struct

  //delete collection detial
  const onDeleteTabItem = async (v: SelectOption) => {
    setProcessing(true);
    message.loading({ content: "Vui lòng đợi", duration: 1 });
    const findTagDetailId = await new TagRepository().getItemsDetail({
      filters: {
        ...TagRepository.getDefaultTagDetailFilters(),
        resource_id: v.value,
        resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
        tag_id: activeTag.id,
      },
    });
    if (!findTagDetailId.hasError() && findTagDetailId.items.length > 0) {
      const reponse = await new TagRepository().deleteTagDetailItem([
        findTagDetailId.items[0].id,
      ]);
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setListTagReload(!listTagReload);
        setSelectReload(!selectReload);
        setProcessing(false);

        return true;
      } else {
        message.error("Thao tác thất bại");
        setProcessing(false);
        return false;
      }
    } else {
      message.error("Thao tác thất bại");
      setProcessing(false);
      return false;
    }
    setProcessing(false);
  };


 async function handleUpdateDisplayOrder(data :DropResult , items : any[]){
    const idProduct = +data.draggableId
    console.log(data)
    if(idProduct){
      const findInList = items.find(item=>item.id == idProduct)
      if(findInList && data.destination){
        const tagDetail = findInList.tagDetail
        console.log(data.destination.index)
        const res = await new TagRepository().updateTagDetailDisplayProduct({display_order :data.destination.index + 1 , tagdetail_id : tagDetail.id })
      }
      
    }

  }




  return (
    <ProductRelatedComponent
      type={"productrelated"}
      activeTag={activeTag}
      changeActiveTagValue={changeActiveTagValue}
      productDetail={model}
      reload={selectReload}
      reloadListTag={listTagReload}
      existValues={existValues}
      id={id}
      onSaveSuccess={onSubmitTagDetail}
      onSaveReverted={onSubmitRevertTagDetail}
      handleRemoveItem={onDeleteSelectedItem}
      handleRemoveCurrentItem={handleRemoveCurrentItem}
      handleRemoveTabItem={onDeleteTabItem}
      changeExistValue={changeTagExistValue}
      onAddTagStructSuccess={() => {
        onAddTagStructSuccess();
      }}
      onDragEnd={handleUpdateDisplayOrder}
      renderItems={(item: ProductPublicModel) => {
        let statusInfo = ProductPublicModel.getStatus(item.status);

        return (
          <div className=" flex gap-10 lg:gap-12 justify-between w-full">
            <div onClick={(e) => e.stopPropagation()}>
              {item && item.images.length > 0 ? (
                <Image
                  className="max-w-[50px] max-h-[50px]"
                  src={item.images[0].url}
                  preview={{
                    src: item.images[0].url,
                    mask: <IconEye size="16" />,
                  }}
                />
              ) : (
                <NoPhoto width={50} height={50} />
              )}
            </div>

            <div className="flex flex-col w-[240px]">
              <div className="text-blue-600 text-md font-semibold line-clamp-2">
                {item.full_name.length > 0 ? item.full_name : item.name}
              </div>
              <div className="text-xs text-gray-400 mt-1">
                Mã sản phẩm: {item.sku}
              </div>
            </div>

            <div className=" text-md font-semibold">
              Giá: {Helper.moneyFormat(item.price)}
            </div>
            {/* <div className=" text-md">Tổng tồn kho: <b className={item.quantity ? "text-green-500":" text-red-500"}>{item.quantity}</b></div> */}

            <div>
              <p> Trạng thái: </p>
              <b
                className={
                  item.status === ProductStatus.UNACTIVE
                    ? "text-red-500"
                    : " text-green-500"
                }
              >
                {statusInfo?.label}
              </b>
            </div>

            <div>
              <Link to={`/product/edit/id/${item.id}`}>Chi tiết</Link>
            </div>
          </div>
        );
      }}
    ></ProductRelatedComponent>
  );
};

export default ProductRelated;
