import React, { useEffect, useState } from "react";
import ProductList from "features/product/list/ProductList";
import ProductForm from "features/product/form/ProductForm";
import usePathParams from "hooks/usePathParams";
import ProductModel from "common/models/ProductModel";
import FormEditFetcher from "components/form/FormEditFetcher";
import ProductRepository from "common/repositories/ProductRepository";
import ProductFormHeader from "features/product/form/ProductFormHeader";
import { ProductJson2 } from "common/types/Product";

const ProductPage = () => {
  const [pathParams] = usePathParams();

  const [model, setModel] = useState<ProductModel>(
    new ProductModel(ProductModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  async function getProduct(id: number, defaultField?: Partial<ProductJson2>) {
    let fetchedModel = await new ProductRepository().getItem(id, 0);
    if (fetchedModel.hasError()) {
      setFetchErrors(fetchedModel.error.errors);
    } else {
      // if (opt.isAdd) {
      //   setModel(new ProductModel({ ...fetchedModel.toJson(), id: 0 , sku  : "" , barcode : "" }));
      //   return;
      // }

      // if (opt.isParent) {
      //   setModel(new ProductModel({ ...fetchedModel.toJson(), id: 0  , parent_id :id, sku  : "" , barcode : "" }));
      //   return;
      // }

      setModel(new ProductModel({ ...fetchedModel.toJson(),...defaultField }));

      // setModel(fetchedModel);
    }
  }
  //clear edit model when change action
  useEffect(() => {
    setModel(new ProductModel(ProductModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);


  useEffect(() => {
    if (pathParams.action === "add" && pathParams.copy) {
      getProduct(+pathParams.copy, {id: 0 , sku  : "" , barcode : "" , handle : ""});
    }
  }, [pathParams.copy, pathParams.action]);

  useEffect(() => {
    if (pathParams.action === "add" && pathParams.parent) {
      const id = +pathParams.parent
      getProduct(id, {id: 0  , parent_id :id, sku  : "" , barcode : "",handle : ""});
    }
  }, [pathParams.parent, pathParams.action]);

  let com = null;
  switch (pathParams.action) {
    case "add":
      com = (
        <ProductForm
          isCopy={!!pathParams.copy}
          isParent={!!pathParams.parent}
          key={JSON.stringify(model)}
          onSaveSuccess={(pro) => setModel(pro)}
          model={model}
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <ProductForm
            key={JSON.stringify(model)}
            model={model}
            onSaveSuccess={(pro) => setModel(pro)}
          />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              await getProduct(parseInt(pathParams.id.toString()));
            }}
            errors={fetchErrors}
            heading={<ProductFormHeader isEditing={true} />}
          />
        );

      break;

    default:
      com = <ProductList />;
  }

  return com;
};

export default ProductPage;
