import PhoneBookModel from "common/models/PhoneBookModel";
import PhoneBookRepository from "common/repositories/PhoneBookRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import PhoneBookList from "features/phonebook/list/PhoneBookList";
import usePathParams from "hooks/usePathParams";
import React, { useState } from "react";

const PhoneBookPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new PhoneBookModel(PhoneBookModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  let com = <></>;

  switch (pathParams.action) {
    default:
      com = <PhoneBookList />;
      break;
  }
  return com;
};

export default PhoneBookPage;
