import { Col, DatePicker, Form, Input, Row } from "antd";
import ShipperApply from "common/constants/ShipperApply";
import ShipperApplyModel from "common/models/ShipperApplyModel";
import ShipperApplyRepository from "common/repositories/ShipperApplyRepository";
import Helper from "common/utils/helper";
import FormRadio from "components/form/FormRadio";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import MoneyInput from "components/MoneyInput";
import RegionPicker from "components/RegionPicker";
import dayjs from "dayjs";
import ShipperApplyFormHeader from "features/shipperapply/form/ShipperApplyFormHeader";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { ShipperApplyJsonAddEdit } from "common/types/ShipperApply";
const ShipperApplyForm = ({ model }: { model: ShipperApplyModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;
  const account = useLoginAccountStore((state) => state.account);

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const initialValues = {
    full_name: model.full_name,
    phone: model.phone,
    email: model.email,
    birthday:
      model.birthday > 0
        ? dayjs(
            dayjs(Helper.checkDateString(model.birthday), "DDMMYYYY").format(
              "DD/MM/YYYY"
            ),
            "DD/MM/YYYY"
          )
        : null,
    gender: model.gender >= 0 ? model.gender : ShipperApply.GENDER_MALE,
    marriage_status:
      model.marriage_status > 0
        ? model.marriage_status
        : ShipperApply.MARRIAGE_OTHER,
    address: model.address,
    regions: {
      region_id: model.region_id,
      sub_region_id: model.sub_region_id,
      sub_sub_region_id: model.sub_sub_region_id
    },
    vehicle_type:
      model.vehicle_type > 0 ? model.vehicle_type : ShipperApply.TYPE_MOTOBIKE,
    introduction: model.introduction,
    salary: model.salary,
    note: model.note,
    status: model.status > 0 ? model.status : ShipperApply.STATUS_ENABLE
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ShipperApplyJsonAddEdit = {
        ...ShipperApplyModel.getDefaultData(),
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,
        full_name: formData.full_name,
        phone: formData.phone,
        email: formData.email,
        birthday: +dayjs(formData.birthday).format("DDMMYYYY"),
        gender: formData.gender,
        marriage_status: formData.marriage_status,
        address: formData.address,
        region_id: formData.regions.region_id,
        sub_region_id: formData.regions.sub_region_id,
        sub_sub_region_id: formData.regions.sub_sub_region_id,
        vehicle_type: formData.vehicle_type,
        introduction: formData.introduction,
        salary: formData.salary,
        note: formData.note,
        status: formData.status
      };

      return submitData;
    },
    [model.id, account]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: ShipperApplyModel =
      await new ShipperApplyRepository().saveRemote(doPrepareData(formData));
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <Form.Item label={t("shipperapply:note")} name="note">
        <Input.TextArea rows={3} />
      </Form.Item>

      {isEditing ? (
        <FormSelect
          label={t("common:status")}
          name="status"
          required
          options={ShipperApplyModel.getStatusList()}
        />
      ) : null}
    </div>
  );

  return (
    <>
      <ShipperApplyFormHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("shipperapply:form.success.update")
            : t("shipperapply:form.success.add")
        }
        redirectOnSuccess="/shipperapply"
        error_translate_prefix="shipperapply:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}>
        <FormSection
          title={t("shipperapply:form.section_account")}
          subtitle={t("shipperapply:form.section_account_subtitle")}
          gutter={0}
          divider>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("common:full_name")}
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: t(
                      "shipperapply:form.error.error_fullname_required"
                    )
                  }
                ]}>
                <Input autoFocus />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <FormRadio
                label={t("common:gender")}
                name="gender"
                options={ShipperApplyModel.getGenderList()}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("common:phone")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("shipperapply:form.error.error_phone_required")
                  }
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("common:email")} name="email">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("shipperapply:address")}
                name="address"
                rules={[
                  {
                    required: true,
                    message: t("shipperapply:form.error.error_address_required")
                  }
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="regions" noStyle>
            <RegionPicker required={true} />
          </Form.Item>
        </FormSection>
        <FormSection
          title={t("shipperapply:form.section_job")}
          subtitle={t("shipperapply:form.section_job_subtitle")}
          gutter={0}>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label={t("shipperapply:birthday")}
                name="birthday"
                required
                rules={[
                  {
                    required: true,
                    message: t(
                      "shipperapply:form.error.error_birthday_required"
                    )
                  }
                ]}>
                <DatePicker
                  changeOnBlur
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  placeholder={t("shipperapply:birthday_placeholder")}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <FormRadio
                label={t("shipperapply:marriagestatus")}
                name="marriage_status"
                options={ShipperApplyModel.getMarriageStatusList()}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <FormSelect
                required
                label={t("shipperapply:vehicletype")}
                name="vehicle_type"
                options={ShipperApplyModel.getVehicleTypeList()}
                rules={[
                  {
                    required: true,
                    message: t(
                      "shipperapply:form.error.error_vehicletype_required"
                    )
                  }
                ]}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label={t("shipperapply:salary")} name="salary">
                <MoneyInput style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("shipperapply:introduction")}
                name="introduction">
                <Input.TextArea
                  autoSize
                  style={{ minHeight: 56, maxHeight: 300 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default ShipperApplyForm;
