import { Button, Divider, Result, Spin } from "antd";
import { ErrorLocal } from "common/api/BaseApi";
import ErrorApi from "common/constants/ErrorApi";
import AccountRepository from "common/repositories/AccountRepository";
import LogoutModal from "components/LogoutModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useProcessStore from "zustands/useProcessStore";

const AppWithSessionProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState<string[]>([]);

	const errorLocal = localStorage.getItem("MAIN::Process")!;
	const errorLocalParse = JSON.parse(errorLocal) as ErrorLocal;

	const [account, setUser] = useLoginAccountStore((state) => [
		state.account,
		state.setAccount,
	]);

	const fetchErrors = useProcessStore((state) => state.fetchErrors);

	const setFetchErrors = useProcessStore((state) => state.setFetchErrors);

	/////////////////////////////////
	const getSessionData = useCallback(async () => {
		setLoading(true);
		setErrors([]);

		if (
			fetchErrors.length <= 0 &&
			errorLocalParse?.state?.fetchErrors.length > 0
		) {
			setFetchErrors(errorLocalParse?.state?.fetchErrors || []);
		}
		const account = await new AccountRepository().getAuthorizedCompany();

		if (account.hasError()) {
			setErrors(account.error.errors);
		} else {
		}
		await new Promise((resolve, reject) => {
			setTimeout(() => {
				
				if (account.id > 0) {
					resolve(1);
					setErrors([]);
				} else {
					setErrors(["jwt_not_found"]);
					resolve(1);
				}
			}, 2000);
		});

		setLoading(false);
	}, [account, errorLocal]);

	const isFetchError = useMemo(() => {
		let isErr = false;
		if (fetchErrors.length > 0) {
			if (fetchErrors.includes(ErrorApi.error_jwt_hash_not_found)) {
				isErr = true;
			}
		}
		return isErr;
	}, [fetchErrors]);

	////////////////////////////////
	useEffect(() => {
		if (loading) {
			getSessionData();
		}
	}, [loading, getSessionData, errorLocal]);

	useEffect(() => {
		const handleStorageChange = () => {
			if (errorLocalParse.state.fetchErrors.length > 0) {
				// setFetchErrors(errorLocalParse.state.fetchErrors);
			}
		};
		window.addEventListener("storage", handleStorageChange);

		return () => {
			window.removeEventListener("storage", handleStorageChange);
		};
	}, [errorLocalParse]);

	return (
		<>
			{process.env.REACT_APP_MAINTENANCE_MODE === "1" ? (
				<div className="w-screen h-screen flex justify-center items-center">
					<Result
						status="warning"
						title="Ứng dụng đang bảo trì. Vui lòng thử lại sau !"
						subTitle="Sorry, the application is under maintenance, please try again later."
					/>
				</div>
			) : loading ? (
				<div className="text-center p-9">
					<Spin size="small" /> &nbsp; {t("common:session.checking_text")}
				</div>
			) : (
				<>
					{isFetchError ? (
						<div className="mx-auto my-5 lg:mx-20 lg:my-20">
							<h2 className="pl-3 lg:pl-0 font-bold text-3xl text-red-500">
								{process.env.REACT_APP_BRANDNAME}
							</h2>
							<Divider />
							<LogoutModal />
							<Divider />
						</div>
					) : (
						<>{children}</>
					)}
				</>
			)}
		</>
	);
};

export default AppWithSessionProvider;
