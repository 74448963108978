const PageDataFilterFormExtraDivider = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <span className={className || "px-4 text-gray-300"}>{children || "|"}</span>
  );
};

export default PageDataFilterFormExtraDivider;
