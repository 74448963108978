import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { LogAuditJson } from "common/types/LogAudit";

const defaultData = {
	company_id: 0,
	creator_id: 0,
	id: 0,
	display_name: "",
	method: 0,
	severity: 0,
	controller: "",
	action: "",
	object_id: 0,
	tag: "",
	more_data: {},
	visibility: 0,
	date_created: 0,
	dynamic_data: {},
	//order
	ip_address: "string",
	chain_identifier: "string",
	new_data: {},
	old_data: {},
	request_method: "string",
	date_modified: 0,
	request_uri: "string",
};

class LogAuditModel extends BaseModel implements BaseModelJson<LogAuditJson> {
	company_id: number = defaultData.company_id;
	creator_id: number = defaultData.id;
	id: number = defaultData.id;
	display_name: string = defaultData.display_name;
	method: number = defaultData.method;
	severity: number = defaultData.severity;
	controller: string = defaultData.controller;
	action: string = defaultData.action;
	object_id: number = defaultData.object_id;
	tag: string = defaultData.tag;
	more_data: any = defaultData.more_data;
	visibility: number = defaultData.visibility;
	date_created: number = defaultData.date_created;
	dynamic_data: any = defaultData.dynamic_data;

	//order
	ip_address: string = defaultData.ip_address;
	chain_identifier: string = defaultData.chain_identifier;
	new_data: any = defaultData.new_data;
	old_data: any = defaultData.old_data;
	request_method: string = defaultData.request_method;
	date_modified: number = defaultData.date_modified;
	request_uri: string = defaultData.request_uri;

	constructor(json: LogAuditJson) {
		super();

		Object.assign(this, { ...defaultData, ...json });
	}

	static getDefaultData(): LogAuditJson {
		return defaultData;
	}

	toJson(): LogAuditJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	getObjectTextAndLink(): { text: string; link: string } {
		let text = "";
		let link = "";

		switch (this.controller) {
			case "order":
				text = "#" + this.object_id;
				link = "/order/edit/id/" + this.object_id;
				break;
			case "customer":
				text = "#" + this.object_id;
				link = "/customer/edit/id/" + this.object_id;
				break;
			case "cashflowreceipt":
				text = "#" + this.object_id;
				link = "/cashflowreceipt/edit/id/" + this.object_id;
				break;
			case "promotion":
				text = "#" + this.object_id;
				link = "/promotion?id=" + this.object_id;
				break;
			case "productreceipt":
				text = "#" + this.object_id;
				link = "/productreceipt/edit/id/" + this.object_id;
				break;
			case "user":
				text = "";
				break;
			default:
				text = " có ID #" + this.object_id;
		}

		return { text, link };
	}
}

export default LogAuditModel;
