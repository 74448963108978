import {
	Button,
	Checkbox,
	Form,
	Input,
	notification,
	Popconfirm,
	Tag,
} from "antd";
import ProductInventoryChecking from "common/constants/ProductInventoryChecking";
import Role from "common/constants/Role";
import ProductInventoryCheckingDetailModel from "common/models/ProductInventoryCheckingDetailModel";
import ProductInventoryCheckingModel from "common/models/ProductInventoryCheckingModel";
import ProductInventoryCheckingRepository from "common/repositories/ProductInventoryCheckingRepository";
import { WarehouseJson } from "common/types/Warehouse";
import LayoutForm from "components/form/LayoutForm";
import TextWarehouse from "components/TextWarehouse";
import ProductInventoryCheckingFormHeader from "features/productinventorychecking/form/ProductInventoryCheckingFormHeader";
import ProductInventoryCheckingDetailList from "features/productinventorycheckingdetail/list/ProductInventoryCheckingDetailList";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import ProductInventoryCheckingBalanceFormModal from "./ProductInventoryCheckingBalanceFormModal";
import ProductInventoryCheckingFormStatus from "./ProductInventoryCheckingFormStatus";

import type {
	ProductInventoryAttachments,
	ProductInventoryCheckingJsonAddEdit,
} from "common/types/ProductInventoryChecking";
import { ProductJsonPublic } from "common/types/Product";
import ProductInventoryCheckingFormAttachments from "./ProductInventoryCheckingFormAttachments";
import RoleCheck from "components/RoleCheck";
import Error from "common/api/Error";

const ProductInventoryCheckingForm = ({
	model,
	setModel,
}: {
	model: ProductInventoryCheckingModel;
	setModel: (model: ProductInventoryCheckingModel) => void;
}) => {
	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);

	const hasRole = useLoginAccountStore((state) => state.hasRole);

	const havePermission = useMemo(() => {
		return (
			hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, model.warehouse_id) ||
			hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, model.warehouse_id) ||
			hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, model.warehouse_id) ||
			hasRoleAndLimit(Role.INVENTORY_STATUS_CANCEL, model.warehouse_id)
		);
	}, [hasRoleAndLimit, model.warehouse_id]);

	const isDisable =
		!havePermission ||
		model.status === ProductInventoryChecking.STATUS_CANCELLED;
	const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
		"warehouse",
		Role.INVENTORY_STOCK_CHECK
	);

	const { t } = useTranslation();
	const [form] = Form.useForm();

	const isEditing = model.id > 0;
	const isAllowEdit = havePermission;
	// model.status !== ProductInventoryChecking.STATUS_PROCESSING &&
	// model.status < ProductInventoryChecking.STATUS_COMPLETED;

	const isAllowEditAtt =
		havePermission && model.status < ProductInventoryChecking.STATUS_COMPLETED;

	const [detailItems, setDetailItems] = useState<
		ProductInventoryCheckingDetailModel[]
	>([]);

	const [Items, setItems] = useState<ProductJsonPublic[]>([]);

	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	// const [productIventoryCheckingModel, setProductIventoryCheckingModel] =
	// 	useState<ProductInventoryCheckingModel>(model);
	const [modalVisible, setModalVisible] = useState(false);
	const [defaultWarehouseId, setDefaultWarehouseId] = useState<number>(
		model.warehouse_id
	);

	const [attachments, setAttachments] = useState(model.attachments);

	const initialValues = {
		note: model.note,
		description: model.description,
		warning: model.warning === 1 ? true : false,

		warehouse_id: model.id > 0 ? model.warehouse_id : null,
		status:
			model.status > 0 ? model.status : ProductInventoryChecking.STATUS_PENDING,
		detailItems: model.data_detail,
	};

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const attachments = formData.attachments
				? formData.attachments.map((item: any) => {
						if (true) {
							return {
								type: item.type,
								receipt_type: item?.receipt_type,
								id: +item.id ? +item.id : item.id,
							};
						}
						return item;
				  })
				: [];
			const submitData: ProductInventoryCheckingJsonAddEdit = {
				id: model.id,
				warehouse_id: defaultWarehouseId,
				note: formData.note,
				status: formData.status,
				description: formData.description,
				attachments: attachments,
				// warning: formData.warning ? 1 : 0,
				// details: detailItems.map((item) => {
				// 	return {
				// 		id: item.id,
				// 		note: item.note,
				// 		product_id: item.product_id,
				// 		quality: item.quality,
				// 		quantity: item.quantity,
				// 		serial_number: "",
				// 	};
				// }),
			};

			return submitData;
		},
		[model.id, detailItems, defaultWarehouseId]
	);

	//submit data to server
	const onSubmit = async (formData: any) => {
		setIsSuccess(false);
		if (!detailItems.length) {
			notification.error({ message: "Chi tiết phiếu kiểm kho trống" });
			return;
		}

		if (!defaultWarehouseId) {
			notification.error({ message: "Vui lòng chọn kho kiểm" });
			return;
		}

		let myObj: ProductInventoryCheckingModel =
			await new ProductInventoryCheckingRepository().saveRemote(
				doPrepareData(formData)
			);
		if (myObj.hasError()) {
			processErrors(myObj.error, formData);
			setErrors(myObj.error.errors);
			// setDetailItems(myObj.data_detail)
		} else {
			setIsSuccess(true);
			setModel(myObj);
		}
	};
	let statusInfo = ProductInventoryCheckingModel.getWarning(model.warning);

	const processErrors = (err: Error, formData: any) => {
		try {
			const attachmentErrors = [
				"error_doc_id_invalid",
				"error_inventory_receipt_id_invalid",
			];
			const attachments = formData.attachments as ProductInventoryAttachments[];
			const errors = err.errors;
			const errorDetail = err.error_detail;
			if (errors.length > 0 && errorDetail && errorDetail.length > 0) {
				// const attachmentError = formData.attachments.map((i)=>i.id===errorDetail[0].)
				if (attachmentErrors.some((i) => attachmentErrors.includes(i))) {
					// const idAttachErr = errorDetail[0]?.["error_doc_id_invalid"]?.[
					// 	"error_inventory_receipt_id_invalid"
					// ] as string | string[] | undefined;
					const idAttachErr = attachmentErrors.reduce(
						(acc: string | string[] | undefined, pre) => {
							if (typeof errorDetail[0][pre] !== "undefined") {
								acc = errorDetail[0][pre];
							}
							return acc;
						},
						""
					);
					if (idAttachErr) {
						const valueFindIndex =
							typeof idAttachErr === "string" ? idAttachErr : idAttachErr[0];
						const indexOfError = attachments.findIndex(
							(i: any) => i.id === valueFindIndex
						);
						indexOfError >= 0 &&
							form.setFields([
								{
									name: ["attachments", indexOfError, "id"],
									errors: ["Mã không hợp lệ"],
								},
							]);
					}
				}
			}
		} catch (error) {}
	};

	//set default warehouse if only one warehouse
	useEffect(() => {
		if (
			warehouseItemsWithLimit.length === 1 &&
			initialValues.warehouse_id === null
		) {
			form.setFieldValue("warehouse_id", warehouseItemsWithLimit[0].id);
		}
	}, [warehouseItemsWithLimit, initialValues.warehouse_id, form]);

	useEffect(() => {
		setDetailItems(model.data_detail);
	}, [model.data_detail]);

	let sidebarItems = (
		<>
			{isEditing ? (
				<div className="pb-4 mb-4 border-b border-gray-300">
					{t("productinventorychecking:form.warehouse_id")}
					: <br />
					<span className="text-lg font-bold">
						<TextWarehouse id={model.warehouse_id} />
					</span>
				</div>
			) : (
				<>
					{defaultWarehouseId > 0 ? (
						<div className="pb-4 mb-4 border-b border-gray-300">
							{t("productinventorychecking:form.warehouse_id")}
							: <br />
							<span className="text-lg font-bold">
								<TextWarehouse id={defaultWarehouseId} />
							</span>
							<Popconfirm
								title="Khi đổi kho thì danh sách sản phẩm vừa thêm vào phiếu trước đó sẽ bị mất. Bạn có chắc chắn muốn đổi kho?"
								onConfirm={() => {
									setDefaultWarehouseId(0);
									setDetailItems([]);
									form.setFieldValue("warehouse_id", null);
								}}
								okText="Có"
								cancelText="Không">
								<span className="inline-block p-3 text-xs text-red-500 cursor-pointer">
									[{"Đổi kho"}]
								</span>
							</Popconfirm>
						</div>
					) : (
						<WarehouseFormSelect
							size="large"
							required
							disabled={model.id > 0}
							label={t("productinventorychecking:form.warehouse_id")}
							name="warehouse_id"
							initOptions={warehouseItemsWithLimit.map((i) => ({
								value: i.id,
								label: i.name,
							}))}
							onChangeSelect={(value) => {
								if (value !== undefined) {
									setDefaultWarehouseId(+value);
								}
							}}
						/>
					)}
				</>
			)}

			{/* <div className=" flex gap-1 ">
				<span>Tình trạng:</span>
				<Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
			</div> */}

			<Form.Item label={t("productinventorychecking:note")} name="note">
				<Input.TextArea rows={3} disabled={!isAllowEdit} />
			</Form.Item>

			<Form.Item
				label={t("productinventorychecking:description")}
				name="description">
				<Input.TextArea rows={3} disabled={!isAllowEdit} />
			</Form.Item>
		</>
	);
	return (
		<>
			<ProductInventoryCheckingFormHeader isEditing={isEditing} id={model.id} />
			<LayoutForm
				disable_message
				disable_status_loading
				form={form}
				initialValues={initialValues}
				errors={errors}
				submitDisabled={
					!havePermission ||
					model.status === ProductInventoryChecking.STATUS_CANCELLED
				}
				isSuccess={isSuccess}
				successTitle={
					isEditing
						? t("productinventorychecking:form.success.update")
						: t("productinventorychecking:form.success.add")
				}
				redirectOnSuccess={model.id === 0 ? "/productinventorychecking" : ""}
				error_translate_prefix="productinventorychecking:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={<>{sidebarItems}</>}>
				<div className="my-6">
					<ProductInventoryCheckingFormStatus
						role="manager"
						isDisable={isDisable}
						status={model.status}
						isedit={isEditing}
					/>
				</div>
				<ProductInventoryCheckingDetailList
					isAllowEdit={isAllowEdit}
					productIventoryCheckingModel={model}
					detailItems={detailItems}
					setDetailItems={setDetailItems}
					defaultWarehouseId={defaultWarehouseId}
				/>

				<div className=" my-6">
					<ProductInventoryCheckingFormAttachments
						isEditing={isEditing}
						files={attachments}
						isAllowEdit={isAllowEditAtt}
					/>
				</div>

				{/* {havePermission ? (
					<>
						{model.id > 0 && detailItems.length > 0 ? (
							<Button
								className="p-0 m-0"
								type="link"
								onClick={() => setModalVisible(true)}>
								{t("productinventorychecking:form.create_balance_sheet")}
							</Button>
						) : null}
					</>
				) : null} */}
			</LayoutForm>
			{modalVisible ? (
				<ProductInventoryCheckingBalanceFormModal
					open={modalVisible}
					setOpen={setModalVisible}
					warehouse_id={model.warehouse_id}
					detailItems={detailItems}
				/>
			) : null}
		</>
	);
};

export default ProductInventoryCheckingForm;
