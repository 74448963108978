import { Form, Input } from "antd";
import CustomerPointModel from "common/models/CustomerPointModel";
import { Filter } from "common/types/Filter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import { useTranslation } from "react-i18next";

const CustomerPointListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}>
      <Form.Item name="customer_id">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("customerpoint:filter_keyword_placeholder")}
        />
      </Form.Item>

      <FormSelect
        name="status"
        placeholder={t("common:status")}
        options={CustomerPointModel.getStatusList()}
        allowClear
        popupMatchSelectWidth={false}
      />
    </PageDataFilterForm>
  );
};

export default CustomerPointListFilter;
