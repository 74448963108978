import { Form, Input } from "antd";
import { Filter } from "common/types/Filter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import CustomerTypeFormSelect from "features/customertype/CustomerTypeFormSelect";
import { useTranslation } from "react-i18next";


const CustomerListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <PageDataFilterForm
      filters={filters}
      setFilters={setFilters}
      total={total}
      defaultFilters={defaultFilters}
      convertValueByKeyFilter={["customer_type_id"]}
    >
        <Form.Item name="keyword">
          <Input
            allowClear
            placeholder={t("customer:filter_keyword_placeholder")}
          />
        </Form.Item>
        <Form.Item name="id">
          <Input allowClear placeholder={t("customer:id")} />
        </Form.Item>

        <CustomerTypeFormSelect
          name="customer_type_id"
          label=""
          placeholder={t("customer:customer_type")}
          popupMatchSelectWidth={false}
        />
    </PageDataFilterForm>
  );
};

export default CustomerListFilter;
