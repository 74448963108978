import { useTranslation } from "react-i18next";
import { Filter } from "common/types/Filter";
import TaskModel from "common/models/TaskModel";
import FormSelectNormal from "components/form/FormSelectNormal";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";

const TaskListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[
          "type",
          "status",
          "islate",
          "date_from",
          "date_to",
        ]}
      >
        <DatePickerRangeFilter
          filters={filters}
          setFilters={setFilters}
          keyEnd="date_to"
          keyStart="date_from"
        />
        <FormSelectNormal
          name="type"
          placeholder={t("task:filter.task_type")}
          options={TaskModel.getTypeOption()}
          allowClear
          popupMatchSelectWidth={false}
        />
        <FormSelectNormal
          name="status"
          placeholder={t("task:filter.status")}
          options={TaskModel.getStatusOption()}
          allowClear
          popupMatchSelectWidth={false}
        />
        <FormSelectNormal
          name="islate"
          placeholder={t("task:filter.task_select_late")}
          options={TaskModel.getIsLateOption}
          allowClear
          popupMatchSelectWidth={false}
        />
      </PageDataFilterForm>
    </>
  );
};

export default TaskListFilter;
