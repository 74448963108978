import { Col, Form, Input, Row, Switch } from "antd";
import ShippingCarrierModel from "common/models/ShippingCarrierModel";
import FormSelect from "components/form/FormSelect";
import RegionPicker from "components/RegionPicker";
import React from "react";
import { useTranslation } from "react-i18next";

const ApiTypeJTExpress = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 p-4">
      <Row gutter={16}>
        <Col md={4} xs={24}>
          <Form.Item
            name="is_sandbox"
            label={t("shippingcarrier:form.is_sandbox")}
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col md={5} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.ec_company_id")}
            name="ec_company_id"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col md={5} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.customer_id")}
            name="customer_id"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col md={10} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.key")}
            name="key"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.pick_address")}
            name="sender_address"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item name="regions">
            <RegionPicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("shippingcarrier:form.pick_tel")}
            name="sender_phone"
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_input_required"),
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>

        <Col md={8} xs={24}>
          <FormSelect
            rules={[
              {
                required: true,
                message: t("shippingcarrier:form.error.error_choose_required"),
              },
            ]}
            label={t("shippingcarrier:form.pay_type")}
            name="pay_type"
            options={ShippingCarrierModel.getJTExpressPayTypeList()}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ApiTypeJTExpress;
