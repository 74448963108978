import { Button, Input, List } from "antd";
import FileModel from "common/models/FileModel";
import Error from "components/Error";
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { IconAlertTriangle, IconX } from "@tabler/icons-react";

import type {
  ViewType,
  EditingQueue,
  FileJsonAddEdit,
} from "common/types/File";
const FileItemEditDirectory = ({
  item,
  viewType,
  onClickEditToggle,
  onSave,
  editingQueue,
}: {
  item: FileModel;
  viewType: ViewType;
  onClickEditToggle: (f: FileModel) => void;
  onSave: (d: FileJsonAddEdit) => Promise<void>;
  editingQueue: EditingQueue;
}) => {
  const inputRef = useRef<any>(null);
  const { t } = useTranslation();
  const [name, setName] = useState(item.title);

  const onSubmit = useCallback(() => {
    onSave({
      id: item.id,
      directory_id: item.directory_id,
      title: name,
      description: "",
    });
  }, [item.id, item.directory_id, name, onSave]);

  useEffect(() => {
    setTimeout(() => {
      typeof inputRef !== "undefined" &&
        inputRef.current &&
        inputRef.current.focus();
    }, 10);
  }, [inputRef]);

  const actions =
    viewType === "list"
      ? [
          <Button
            onClick={() => onClickEditToggle(item)}
            type="text"
            size="small"
            style={{ whiteSpace: "break-spaces" }}
            icon={
              <IconX size="14" color={"grey"} style={{ marginRight: 5 }} />
            }>
            {t("common:close")}
          </Button>,
        ]
      : [
          <Button
            onClick={() => onClickEditToggle(item)}
            type="text"
            size="small"
            style={{ whiteSpace: "break-spaces" }}
            icon={
              <IconX size="14" color={"grey"} style={{ marginRight: 5 }} />
            }>
            {t("common:close")}
          </Button>,
        ];

  return (
    <List.Item actions={actions} className="list-item-with-center-actions">
      {viewType === "list" ? (
        <div>
          <span
            style={{
              width: 30,
              height: 30,
              display: "inline-block",
              float: "left",
              textAlign: "center",
            }}>
            <img height={30} src={item.getIcon()} alt="Directory" />
          </span>
          <span style={{ marginLeft: 10 }}>
            <Input.Search
              loading={editingQueue.processing}
              ref={inputRef}
              style={{ width: 500, padding: "3px 8px" }}
              placeholder={item.title}
              enterButton={t("common:form.save_short")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              onSearch={(value) => value.length > 0 && onSubmit()}
              suffix={
                <>
                  {name.length === 0 ? (
                    <IconAlertTriangle size="14" color="red" />
                  ) : null}
                </>
              }
            />
            {editingQueue.errors.length > 0 ? (
              <>
                <Error
                  className="block p-1 ml-12 text-xs text-red-500"
                  contentPadding={0}
                  items={editingQueue.errors}
                  translate_prefix="file:form.error"
                />
              </>
            ) : null}
          </span>
        </div>
      ) : (
        <div className="">
          <div
            style={{
              width: "100%",
              textAlign: "center",
              padding: 5,
            }}>
            <div
              style={{
                lineHeight: 1.1,
                textAlign: "left",
              }}>
              <Input.TextArea
                ref={inputRef}
                size="small"
                autoSize={{ minRows: 4, maxRows: 4 }}
                style={{
                  width: "100%",
                  padding: "3px 8px",
                  lineHeight: 1.2,
                  fontSize: "small",
                }}
                placeholder={item.title}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onPressEnter={(e: KeyboardEvent) =>
                  (e.target as HTMLTextAreaElement).value.length > 0 &&
                  onSubmit()
                }
              />
              {editingQueue.errors.length > 0 ? (
                <>
                  <Error
                    className="block p-1 text-xs text-red-500"
                    contentPadding={0}
                    items={editingQueue.errors}
                    translate_prefix="file:form.error"
                  />
                </>
              ) : null}
            </div>
            <div style={{ marginTop: 3 }}>
              <Button
                block
                size="small"
                type="primary"
                onClick={(e) => onSubmit()}>
                {t("common:form.save_short")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </List.Item>
  );
};

export default FileItemEditDirectory;
