import WorkChatCollection from "../collections/WorkChatCollection";
import WorkChatModel from "../models/WorkChatModel";

import AxiosClient from "common/api/AxiosClient";
import BaseApi from "common/api/BaseApi";

import type { GetListProps } from "common/types/Filter";

import type { FilterWorkChat, WorkChatJsonAddEdit } from "../types/WorkChat";

const SERVICE_URL = "/workchatrooms";

class WorkchatApi extends BaseApi {
  // GET LIST
  async getList(
    props: GetListProps<FilterWorkChat>
  ): Promise<WorkChatCollection> {
    let collection = new WorkChatCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  // DETAIL
  async getDetail(id: number): Promise<WorkChatModel> {
    let item = new WorkChatModel(WorkChatModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);

      if (response.hasOwnProperty("data")) {
        item = new WorkChatModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  // POST
  async add(data: WorkChatJsonAddEdit): Promise<WorkChatModel> {
    let item = new WorkChatModel(WorkChatModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const reponse = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (reponse.hasOwnProperty("data")) {
        item = new WorkChatModel(reponse.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }

  // EDIT
  async edit(data: WorkChatJsonAddEdit): Promise<WorkChatModel> {
    let item = new WorkChatModel(WorkChatModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new WorkChatModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default WorkchatApi;
