import create, { StateCreator } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Category } from "common/types/Collection";
type CollectionStore = {
  categories: Category[];
  subCategories: Category[];
  urlCollection: string;
  keyword: string;
  setCategories: (categories: Category[]) => void;
  setSubCategories: (categories: Category[]) => void;
  setKeyword: (keywords: string) => void;
};

//init store data
let store: StateCreator<CollectionStore> = (set, get) => ({
  categories: [],
  subCategories: [],
  urlCollection: "",
  keyword: "",
  setCategories: (categories) =>
    set((state) => ({ ...state, categories: categories })),
  setSubCategories: (categories) =>
    set((state) => ({ ...state, subCategories: categories })),
  setKeyword: (keywords) => set((state) => ({ ...state, keyword: keywords })),
});

//create store
const useCollectionStore = create(
  persist(store, {
    name: "MAIN::Collection",
    partialize: (state) => ({ categories: state.categories }),
  })
);

export default useCollectionStore;
