import React, { Dispatch, SetStateAction } from "react";
import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Image,
  Input,
  message,
  Result,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";

import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import useDidMount from "@rooks/use-did-mount";
import { IconCameraRotate, IconTrash } from "@tabler/icons-react";

const DeliveryWebcam = ({
  imgScreenshotSrc,
  changeImgSrc,
  widthImg,
  heightImg,
  enableWebcam,
}: {
  imgScreenshotSrc: any;
  changeImgSrc: any;
  widthImg?: number | string;
  heightImg?: number | string;
  enableWebcam: boolean;
  // setEnableWebcam: Dispatch<SetStateAction<Boolean>>;
}) => {
  const { t } = useTranslation();
  const webcamRef = useRef(null) as MutableRefObject<any>;
  const { Text, Title } = Typography;

  const [videoConstraints, setVideoConstraints] = useState<any>({
    // facingMode: { exact: "user" },
    facingMode: { exact: "environment" },
  });

  // state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);

  // function
  const captureScreenshot = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      width: widthImg,
      height: heightImg,
    });
    changeImgSrc(imageSrc);
  }, [webcamRef, changeImgSrc]);

  const handleLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // effect
  React.useEffect(() => {
    handleLoading();
    return () => {
      let stream = webcamRef.current?.video?.srcObject;
      const tracks = stream?.getTracks();
      tracks?.forEach((track: any) => track.stop());
    };
  }, [enableWebcam]);
  // useDidMount(async () => {
  //   try {
  //     await navigator.mediaDevices.getUserMedia({ video: true });
  //   } catch (error) {
  //     setError(true);
  //   }

  //   return async () => {
  //     await navigator.mediaDevices.getUserMedia({ video: false });
  //   };
  // });
  return (
    <Space
      className="relative"
      style={{ width: "100%", height: "auto", display: "inline-block" }}
    >
      {error ? (
        <Alert
          message={
            <Text>
              Yêu cầu truy cập camera{". "}
              <Typography.Link>Nhấn để truy cập</Typography.Link>{" "}
            </Text>
          }
          type="warning"
          showIcon
          closable={false}
          onClick={async () => {
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((stream) => {
                setVideoConstraints({ facingMode: { exact: "user" } });
                setError(false);
              })
              .catch((error) => {
                message.open({
                  type: "warning",
                  content: "Bạn hãy mở quyền truy cập camera !",
                  key: "err",
                  duration: 2,
                });
              });
          }}
        />
      ) : (
        <>
          <Row className="block md:flex" style={{ margin: 4 }}>
            {enableWebcam ? (
              <Col span={24}>
                {/* <Button
                type="link"
                onClick={() => {
                  setEnableWebcam(false);
                  //   setImgScreenshotSrc("");
                  changeImgSrc("");
                }}
              >
                <Text> {t("worktracking:enablewebcam_off")}</Text>
              </Button> */}
                {/* <Tooltip
                  trigger="hover"
                  placement="topLeft"
                  title={t("đổi camera")}
                > */}
                <Button
                  type="default"
                  className="h-full"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    if (videoConstraints.facingMode === "user") {
                      setVideoConstraints({
                        facingMode: { exact: "environment" },
                      });
                    } else {
                      setVideoConstraints({ facingMode: "user" });
                    }
                  }}
                >
                  <IconCameraRotate />
                </Button>
                {/* </Tooltip> */}
              </Col>
            ) : null}
          </Row>
          <Row gutter={[8, 8]}>
            {imgScreenshotSrc === "" && enableWebcam ? (
              <>
                <Col span={24} className="flex justify-center">
                  <>
                    {enableWebcam && !loading ? (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        style={{
                          margin: "0 auto",
                          width: 325,
                          height: 250,
                        }}
                        className="relative"
                        screenshotFormat="image/webp"
                        videoConstraints={videoConstraints}
                        imageSmoothing
                        onUserMediaError={() => {
                          setError(true);
                        }}
                        // mirrored={false}

                        // onUserMedia={handleLoading}
                      />
                    ) : (
                      <Skeleton active></Skeleton>
                    )}
                  </>
                </Col>
                {enableWebcam && !error && !loading && (
                  <Col span={24}>
                    <Button
                      disabled={!enableWebcam}
                      block
                      type="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        captureScreenshot();
                      }}
                    >
                      {t("worktracking:capture")}
                    </Button>
                  </Col>
                )}
              </>
            ) : (
              <>
                {/* <Col span={24} style={{ margin: "4px 0" }}> */}
                <Space direction="vertical" style={{ margin: "0 auto" }}>
                  <Image
                    src={imgScreenshotSrc}
                    preview={false}
                    width={325}
                    height={250}
                    className="max-w-full "
                    style={{ objectFit: "cover", maxWidth: "100%" }}
                  />

                  <Button
                    size="small"
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      //   setImgScreenshotSrc("");
                      changeImgSrc("");
                    }}
                    icon={
                      <IconTrash size="16" color="red" className="-mt-1 mr-1" />
                    }
                    className="text-right"
                  >
                    {t("worktracking:webcamphoto_remove")}
                  </Button>
                </Space>
                {/* </Col> */}
              </>
            )}
          </Row>
        </>
      )}
    </Space>
  );
};

export default DeliveryWebcam;
