import {
  Alert,
  App,
  Checkbox,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space
} from "antd";
import Employee from "common/constants/Employee";
import WorkSchedule from "common/constants/WorkSchedule";
import WorkScheduleModel from "common/models/WorkScheduleModel";
import WorkScheduleRepository from "common/repositories/WorkScheduleRepository";
import { EmployeeJson } from "common/types/Employee";
import { WorkScheduleJsonAddEdit } from "common/types/WorkSchedule";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useFormDefaultStore from "zustands/useFormDefaultStore";
import useWorkScheduleManageStore from "zustands/useWorkScheduleManage";

const WorkScheduleManageForm = ({
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: WorkScheduleModel[]) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");
  const [form] = Form.useForm();
  const [getValue, setValue] = useFormDefaultStore((state) => [
    state.getValue,
    state.setValue
  ]);

  const [office, date_unix, employee, ranges] = useWorkScheduleManageStore(
    (state) => [state.office, state.date_unix, state.employee, state.ranges]
  );

  const initialValues = useMemo(() => {
    return {
      work_tracking_range_id: getValue(
        "workschedulemanage:work_tracking_range_id"
      ),
      user_id_list: employee.user_id > 0 ? [employee.user_id] : [],
      user_id_other_list: []
    };
  }, [getValue, employee.user_id]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: WorkScheduleJsonAddEdit = {
        ...WorkScheduleModel.getDefaultData(),
        date_scheduled: date_unix,
        office_id: office.value,
        user_id: employee.user_id,
        work_tracking_range_id: formData.work_tracking_range_id,
        status: WorkSchedule.STATUS_NEW
      };

      setValue(
        "workschedulemanage:work_tracking_range_id",
        formData.work_tracking_range_id
      );

      return submitData;
    },
    [date_unix, office.value, employee.user_id, setValue]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    const queueUserIdList = [
      ...formData.user_id_list,
      ...formData.user_id_other_list
    ];
    if (queueUserIdList.length > 0) {
      setProcessing(true);
      message.loading({
        content: t("common:form.processing"),
        key: "message",
        duration: 0
      });

      let addedItems: WorkScheduleModel[] = [];
      let addedErrors: string[] = [];
      const preparedData = doPrepareData(formData);

      //loop to create multiple item
      for (var index = 0; index < queueUserIdList.length; index++) {
        const item = await new WorkScheduleRepository().saveRemote({
          ...preparedData,
          user_id: queueUserIdList[index]
        });

        if (item.hasError()) {
          addedErrors.push(item.error.errors[0]);
        } else {
          addedItems.push(item);
        }
      }

      setProcessing(false);

      //show errors
      if (addedErrors.length > 0) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="workschedule:form.error"
              items={addedErrors}
            />
          ),
          className: "message_error",
          key: addedItems.length === 0 ? "message" : "message_error",
          duration: 4
        });
      }

      if (addedItems.length > 0) {
        message.success({
          content: t(
            addedErrors.length === 0
              ? "workschedule:form.success.add"
              : "workschedule:form.success.add_with_some_error"
          ),
          className: "message_success",
          key: "message",
          duration: 2
        });

        onSaveSuccess(addedItems);
        setOpen(false);
      }
    } else {
      message.error({
        content: t("workschedule:form.error.error_user_id_list_required"),
        key: "message",
        duration: 2
      });
    }
  };

  //list of employee in selected Office, with schedule checkedin
  const inOfficeEmployees = employeeItems.filter(
    (item) =>
      item.office_id === office.value &&
      item.checkin_type === Employee.CHECKIN_TYPE_SCHEDULE
  );

  //list of employee in other Office, with schedule checkedin
  const otherOfficeEmpoyees = employeeItems.filter(
    (item) =>
      item.office_id !== office.value &&
      item.checkin_type === Employee.CHECKIN_TYPE_SCHEDULE
  );

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      {inOfficeEmployees.length === 0 && otherOfficeEmpoyees.length === 0 ? (
        <Alert
          type="warning"
          showIcon
          closable
          className="mb-4"
          message={t("workschedule:employee_select_note")}></Alert>
      ) : null}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <span className="font-bold uppercase">
                {t("workschedule:employee_select", { office: office.label })}
              </span>
            }
            name="user_id_list">
            {inOfficeEmployees.length > 0 ? (
              <Checkbox.Group>
                <Space direction="vertical">
                  {inOfficeEmployees.map((item) => (
                    <Checkbox key={item.id} value={item.user_id}>
                      {item.full_name}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            ) : (
              <div className="text-xs leading-relaxed mr-14">
                <span className="text-yellow-700">
                  {t("workschedule:employee_select_empty")}
                </span>
                <Link to={"/employee"} className="text-blue-500">
                  {t("workschedule:goto_employee_list")} &raquo;
                </Link>
              </div>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <span className="font-bold uppercase">
                {t("workschedule:employee_other_select")}
              </span>
            }
            name="user_id_other_list">
            {otherOfficeEmpoyees.length > 0 ? (
              <Select
                allowClear
                mode="multiple"
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/[đĐ]/g, "d")
                    .toLowerCase()
                    .indexOf(
                      input
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replace(/[đĐ]/g, "d")
                        .toLowerCase()
                    ) >= 0
                }>
                {otherOfficeEmpoyees.map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.user_id}
                    label={item.full_name}>
                    {item.full_name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <div className="text-xs leading-relaxed mr-14">
                <span className="text-yellow-700">
                  {t("workschedule:employee_other_select_empty")}
                </span>
                <Link to={"/employee"} className="text-blue-500">
                  {t("workschedule:goto_employee_list")} &raquo;
                </Link>
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Form.Item
        label={
          <span className="font-bold uppercase">
            {t("workschedule:range_select")}
          </span>
        }
        name="work_tracking_range_id">
        <Radio.Group
          disabled={
            inOfficeEmployees.length === 0 && otherOfficeEmpoyees.length === 0
          }>
          <Space direction="vertical">
            {ranges.map((range) => (
              <Radio value={range.id} key={range.id}>
                {range.name}{" "}
                <span className="text-gray-400">
                  ({range.time_start} - {range.time_end})
                </span>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

export default WorkScheduleManageForm;
