import BaseCollection from "common/collections/BaseCollection";
import MapServiceAutoCompletePredictionModel from "common/models/MapServiceAutoCompletePredictionModel";
import {
  MapServiceAutoCompletePredictionJson,
  MapServiceAutoCompletePredictionCollectionJson,
} from "common/types/MapServiceAutoCompletePrediction";

class MapServiceAutoCompletePredictionCollection extends BaseCollection<
  MapServiceAutoCompletePredictionJson,
  MapServiceAutoCompletePredictionModel,
  MapServiceAutoCompletePredictionCollectionJson
> {
  itemsFromJson(
    jsonItems: MapServiceAutoCompletePredictionJson[]
  ): MapServiceAutoCompletePredictionModel[] {
    return jsonItems.map(
      (item) => new MapServiceAutoCompletePredictionModel(item)
    );
  }
}

export default MapServiceAutoCompletePredictionCollection;
