import WorkSchedule from 'common/constants/WorkSchedule';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { WorkScheduleJson } from "common/types/WorkSchedule";

class WorkScheduleModel
  extends BaseModel
  implements BaseModelJson<WorkScheduleJson>
{
  company_id: number;
  creator_id: number;
  work_tracking_range_id: number;
  office_id: number;
  user_id: number;
  id: number;
  note: string;
  status: number;
  reviewer: number;
  date_created: number;
  date_modified: number;
  date_scheduled: number;

  constructor(json: WorkScheduleJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.work_tracking_range_id = json.work_tracking_range_id || 0;
    this.office_id = json.office_id || 0;
    this.user_id = json.user_id || 0;
    this.id = json.id || 0;
    this.note = json.note || "";
    this.status = json.status || 0;
    this.reviewer = json.reviewer || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.date_scheduled = json.date_scheduled || 0;
  }

  static getDefaultData(): WorkScheduleJson {
    return {
      company_id: 0,
      creator_id: 0,
      work_tracking_range_id: 0,
      office_id: 0,
      user_id: 0,
      id: 0,
      note: "",
      status: 0,
      reviewer: 0,
      date_created: 0,
      date_modified: 0,
      date_scheduled: 0,
    };
  }

  toJson(): WorkScheduleJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      work_tracking_range_id: this.work_tracking_range_id,
      office_id: this.office_id,
      user_id: this.user_id,
      id: this.id,
      note: this.note,
      status: this.status,
      reviewer: this.reviewer,
      date_created: this.date_created,
      date_modified: this.date_modified,
      date_scheduled: this.date_scheduled,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: WorkSchedule.STATUS_NEW,
        label: i18n.t("workschedule:STATUS_NEW"),
        color: "green",
      },
      {
        value: WorkSchedule.STATUS_REVIEWED,
        label: i18n.t("workschedule:STATUS_REVIEWED"),
        color: "red",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default WorkScheduleModel;
