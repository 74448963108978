import HeaderCompanyResourceUpdate from "components/header/HeaderCompanyResourceUpdate";
import HeaderRight from "components/header/HeaderRight";
import useBreakpoint from "hooks/useBreakpoint";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useMatch, useNavigate } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useMenuStore from "zustands/useMenuStore";

import {
  IconArrowLeft,
  IconHome,
  IconIndentDecrease,
  IconMenu2,
  IconX,
} from "@tabler/icons-react";

import HeaderCompanyPlan from "./HeaderCompanyPlan";
import HeaderPosOrder from "./HeaderPosOrder";
import HeaderSearch from "./HeaderSearch";

const Header = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [minimize, setMinimize] = useMenuStore((state) => [
    state.minimize,
    state.setMinimize,
  ]);
  const [forceHidden, forceHiddenBackLink] = useMenuStore((state) => [
    state.forceHidden,
    state.forceHiddenBackLink,
  ]);
  const [showOnSmall, setShowOnSmall] = useMenuStore((state) => [
    state.showOnSmall,
    state.setShowOnSmall,
  ]);
  const [isSmall] = useBreakpoint();
  const account = useLoginAccountStore((state) => state.account);
  const isRoutePos = useMatch("/pos/*") !== null;

  const handleGoBackOnSiteMenuHidden = useCallback(() => {
    if (typeof forceHiddenBackLink === "undefined") {
      //default go back in history
      navigate(-1);
    } else {
      if (typeof forceHiddenBackLink === "string") {
        navigate(forceHiddenBackLink);
      } else {
        //this is function, call it
        forceHiddenBackLink();
      }
    }
  }, [forceHiddenBackLink, navigate]);

  return (
    <>
      <header className="navbar navbar-header navbar-header-fixed">
        {isRoutePos ? (
          <>
            <HeaderPosOrder />
          </>
        ) : (
          <>
            <>
              {forceHidden ? (
                <>
                  <span
                    title={t("common:header_nav_back")}
                    onClick={() => handleGoBackOnSiteMenuHidden()}
                    id="mainMenuOpen"
                    className="burger-menu"
                    style={{ cursor: "pointer" }}
                  >
                    <IconArrowLeft />
                  </span>
                </>
              ) : (
                <>
                  {isSmall ? (
                    <span
                      onClick={() => setShowOnSmall(!showOnSmall)}
                      id="mainMenuOpen"
                      className="burger-menu"
                      style={{ cursor: "pointer" }}
                    >
                      {showOnSmall ? <IconX /> : <IconMenu2 />}
                    </span>
                  ) : (
                    <span
                      onClick={() => setMinimize(!minimize)}
                      id="mainMenuOpen"
                      className="burger-menu"
                      style={{ cursor: "pointer" }}
                    >
                      {minimize ? <IconMenu2 /> : <IconIndentDecrease />}
                    </span>
                  )}
                </>
              )}
            </>

            <div className="navbar-brand">
              <Link to="/" className="df-logo hideonsmall">
                {isSmall ? (
                  <>
                    <IconHome className="text-blue-400" />
                  </>
                ) : (
                  <>
                    <span>namperfume</span>
                  </>
                )}
              </Link>
            </div>
            {account.id > 0 ? (
              <>
                <HeaderSearch />
                <HeaderCompanyPlan />
              </>
            ) : null}
          </>
        )}
        {account.id > 0 && <HeaderCompanyResourceUpdate />}
        <HeaderRight />
      </header>
    </>
  );
};

export default Header;
