import BaseCollection from 'common/collections/BaseCollection';
import CmsNewsModel from 'common/models/CmsNewsModel';
import { CmsNewsCollectionJson, CmsNewsJson } from 'common/types/CmsNews';

class CmsNewsCollection extends BaseCollection<
  CmsNewsJson,
  CmsNewsModel,
  CmsNewsCollectionJson
> {
  itemsFromJson(jsonItems: CmsNewsJson[]): CmsNewsModel[] {
    return jsonItems.map((item) => new CmsNewsModel(item));
  }
}

export default CmsNewsCollection;
