import DataGridTableApi from "common/api/DataGridTableApi";
import DataGridTableCollection from "common/collections/DataGridTableCollection";
import DataGridTableModel from "common/models/DataGridTableModel";
import BaseRepository from "./BaseRepository";
import { GetListProps } from "common/types/Filter";
import type {
  DataGridTableJsonAddEdit,
  FilterDataGridTable,
} from "common/types/DataGridTable";

class DataGridTableRepository extends BaseRepository<DataGridTableApi> {
  _api: DataGridTableApi | null;

  constructor() {
    super();
    this._api = new DataGridTableApi(true);
  }

  static getDefaultFilters(): FilterDataGridTable {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      datagrid_workspace_id: 0,
      keyword: "",
    };
  }

  async getItems(props: GetListProps<FilterDataGridTable>) {
    return this._api
      ? await this._api.getList(props)
      : new DataGridTableCollection();
  }

  async getItem(id: number): Promise<DataGridTableModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new DataGridTableModel(DataGridTableModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(
    data: DataGridTableJsonAddEdit
  ): Promise<DataGridTableModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new DataGridTableModel(DataGridTableModel.getDefaultData());
    }
  }
}

export default DataGridTableRepository;
