import ShippingTypeList from "features/shippingtype/list/ShippingTypeList";
import usePathParams from "hooks/usePathParams";
import React from "react";

const ShippingTypePage = () => {
  const [pathParams] = usePathParams();
  let com = null;

  switch (pathParams.action) {
    case "add":
      break;
    case "edit":
      break;
    default:
      com = <ShippingTypeList />;
  }

  return com;
};

export default ShippingTypePage;
