import { App, Form, Input } from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import Role from "common/constants/Role";
import ProductReceiptDetailModel from "common/models/ProductReceiptDetailModel";
import ProductReceiptModel from "common/models/ProductReceiptModel";
import OrderHelperRepository from "common/repositories/OrderHelperRepository";
import ProductReceiptRepository from "common/repositories/ProductReceiptRepository";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import { WarehouseJson } from "common/types/Warehouse";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import TextWarehouse from "components/TextWarehouse";
import ProductReceiptFormHeader from "features/productreceipt/form/ProductReceiptFormHeader";
import ProductReceiptDetailList from "features/productreceiptdetail/list/ProductReceiptDetailList";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import ProductReceiptFormStatus from "./ProductReceiptFormStatus";

import type { ProductReceiptJsonAddEdit } from "common/types/ProductReceipt";
const ProductReceiptForm = ({
	model,
	need_link_sale_order_id,
	need_link_purchase_order_id,
}: {
	model: ProductReceiptModel;
	need_link_sale_order_id?: number;
	need_link_purchase_order_id?: number;
}) => {
	const { t } = useTranslation();
	const { message } = App.useApp();
	const [form] = Form.useForm();
	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);
	const [initStatus, setInitStatus] = useState(
		model.status || ProductReceipt.STATUS_DRAFT
	);

	const [status, setStatus] = useState(initStatus);

	//check can edit update input info (add/edit features)
	const allowEditInput = useMemo(() => {
		return (
			(model.id === 0 ||
				hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, model.warehouse_id) ||
				hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, model.warehouse_id) ||
				hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, model.warehouse_id)) &&
			initStatus < ProductReceipt.STATUS_COMPLETED
		);
	}, [model.id, initStatus, model.warehouse_id, hasRoleAndLimit]);

	//limit select warehouse option
	const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
		"warehouse",
		Role.INVENTORY_ADD
	);

	const isEditing = model.id > 0;

	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);

	//fetch all ProductReceiptDetail
	const [detailItems, setDetailItems] = useState<ProductReceiptDetailModel[]>(
		new Array(10).fill(ProductReceiptDetailModel.getDefaultData())
	);

	const initialValues = useMemo(
		() => ({
			type: model.id > 0 ? model.type : null,
			warehouse_id: model.id > 0 ? model.warehouse_id : null,
			note: model.note,
		}),
		[model]
	);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: ProductReceiptJsonAddEdit = {
				id: model.id,
				direction: model.direction,
				status: status,
				warehouse_id: formData.warehouse_id,
				type: formData.type,
				note: formData.note,
				details: detailItems.map((item) => item.toJson()),
			};

			return submitData;
		},
		[model.id, model.direction, status, detailItems]
	);

	const doLinkingSaleOrderAfterAdd = useCallback(
		async (receipt_id: number) => {
			if (typeof need_link_sale_order_id === "number") {
				message.loading({
					content: t("productreceipt:form.linking_with_sale_order", {
						id: need_link_sale_order_id,
					}),
					key: "message_linking",
				});

				const error: string[] =
					await new OrderHelperRepository().linkProductReceipt(
						need_link_sale_order_id,
						receipt_id.toString()
					);

				//detect error on Linking
				if (error.length > 0) {
					message.error({ content: error, key: "message_linking" });
				} else {
					message.destroy("message_linking");
				}

				//show success on Product receipt
				setIsSuccess(true);
			}
		},
		[need_link_sale_order_id, t, message]
	);

	const doLinkingPurchaseOrderAfterAdd = useCallback(
		async (receipt_id: number) => {
			if (typeof need_link_purchase_order_id === "number") {
				message.loading({
					content: t("productreceipt:form.linking_with_purchase_order", {
						id: need_link_purchase_order_id,
					}),
					key: "message_linking",
				});

				const error: string[] =
					await new PurchaseOrderHelperRepository().linkProductReceipt(
						need_link_purchase_order_id,
						receipt_id.toString()
					);

				//detect error on Linking
				if (error.length > 0) {
					message.error({ content: error, key: "message_linking" });
				} else {
					message.destroy("message_linking");
				}

				//show success on Product receipt
				setIsSuccess(true);
			}
		},
		[need_link_purchase_order_id, t, message]
	);

	//submit data to server
	const onSubmit = useCallback(
		async (formData: any) => {
			setErrors([]);
			setIsSuccess(false);
			doPrepareData(formData);

			let myObj: ProductReceiptModel =
				await new ProductReceiptRepository().saveRemote(
					doPrepareData(formData)
				);
			if (myObj.hasError()) {
				setErrors(myObj.error.errors);
			} else {
				//do linking with sale order
				if (typeof need_link_sale_order_id === "number") {
					doLinkingSaleOrderAfterAdd(myObj.id);
				} else if (typeof need_link_purchase_order_id === "number") {
					//do linking with purchase order
					doLinkingPurchaseOrderAfterAdd(myObj.id);
				} else {
					//trigget new status to update EDIT FORM LOGIC (status)
					setInitStatus(myObj.status);

					setIsSuccess(true);
				}
			}
		},
		[
			doPrepareData,
			need_link_sale_order_id,
			need_link_purchase_order_id,
			doLinkingSaleOrderAfterAdd,
			doLinkingPurchaseOrderAfterAdd,
		]
	);

	//set default warehouse if only one warehouse
	useEffect(() => {
		if (
			warehouseItemsWithLimit.length === 1 &&
			initialValues.warehouse_id === null
		) {
			form.setFieldValue("warehouse_id", warehouseItemsWithLimit[0].id);
		}
	}, [warehouseItemsWithLimit, initialValues.warehouse_id, form]);

	let sidebarItems = (
		<>
			{isEditing ? (
				<div className="pb-4 mb-4 border-b border-gray-300">
					{model.direction === ProductReceipt.DIRECTION_INPUT
						? t("productreceipt:warehouse_id_in")
						: t("productreceipt:warehouse_id_out")}
					: <br />
					<span className="text-lg font-bold">
						<TextWarehouse id={model.warehouse_id} />
					</span>
				</div>
			) : (
				<WarehouseFormSelect
					size="large"
					required
					disabled={model.id > 0}
					label={
						model.direction === ProductReceipt.DIRECTION_INPUT
							? t("productreceipt:warehouse_id_in")
							: t("productreceipt:warehouse_id_out")
					}
					name="warehouse_id"
					initOptions={warehouseItemsWithLimit.map((i) => ({
						value: i.id,
						label: i.name,
					}))}
				/>
			)}

			<FormSelect
				required
				disabled={model.id > 0}
				label={
					model.direction === ProductReceipt.DIRECTION_INPUT
						? t("productreceipt:type_in")
						: t("productreceipt:type_out")
				}
				name="type"
				options={
					model.direction === ProductReceipt.DIRECTION_INPUT
						? ProductReceiptModel.getTypeInList()
						: ProductReceiptModel.getTypeOutList()
				}
			/>

			<Form.Item label={t("productreceipt:note")} name="note">
				<Input.TextArea rows={3} />
			</Form.Item>
		</>
	);

	return (
		<>
			<ProductReceiptFormHeader
				isEditing={isEditing}
				direction={model.direction}
				id={model.id}
			/>

			<LayoutForm
				form={form}
				initialValues={initialValues}
				errors={errors}
				isSuccess={isSuccess}
				successTitle={
					isEditing
						? t("productreceipt:form.success.update")
						: t("productreceipt:form.success.add")
				}
				error_translate_prefix="productreceipt:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={sidebarItems}
				id="productreceipt-form"
				redirectOnSuccess={isEditing ? "" : "/productreceipt"}
				
				
				>
				<ProductReceiptDetailList
					productReceiptModel={model}
					detailItems={detailItems}
					setDetailItems={setDetailItems}
					allowEditInput={allowEditInput}
					productReceiptStatus={initStatus}
				/>
				<br />
				<ProductReceiptFormStatus
					warehouse_id={model.warehouse_id}
					id={model.id}
					initStatus={initStatus}
					status={status}
					setStatus={setStatus}
				/>
			</LayoutForm>
		</>
	);
};

export default ProductReceiptForm;
