import {
	App,
	Button,
	Col,
	Form,
	Input,
	notification,
	Row,
	Select,
	Tooltip,
	Typography,
} from "antd";
import ProductReceipt from "common/constants/ProductReceipt";
import Role from "common/constants/Role";
import OrderHelperRepository from "common/repositories/OrderHelperRepository";
import PurchaseOrderHelperRepository from "common/repositories/PurchaseOrderHelperRepository";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import WarehouseFormSelect from "features/warehouse/WarehouseFormSelect";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";

import InventoryReceiptFormStatus from "./InventoryReceiptFormStatus";

import InventoryReceiptFormHeader from "./InventoryReceiptFormHeader";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import {
	InventoryReceiptDirection,
	InventoryReceiptJson,
	InventoryReceiptJsonAddEdit,
	InventoryReceiptSourceType,
	InventoryReceiptStatus,
	ProductInventoryReceiptJson,
	InventoryReceiptFilter,
} from "common/types/InventoryResceipt";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import WarehouseCollection from "common/collections/WarehouseCollection";
import WarehouseModel from "common/models/WarehouseModel";
import { Link, useNavigate } from "react-router-dom";
import InventoryReceiptProduct from "./components/InventoryReceiptProduct";
// import Helper from "common/utils/helper";
import InventoryReceiptTarget from "./components/InventoryReceiptTarget";
import InventoryReceiptSource from "./components/InventoryReceiptSource";
import FormSection from "components/form/FormSection";
import { WarehouseJson } from "common/types/Warehouse";
import StoreModel from "common/models/StoreModel";
import StoreRepository from "common/repositories/StoreRepository";
import InventoryReceipt from "common/constants/InventoryReceipt";
import usePathParams from "hooks/usePathParams";
import { useWatch } from "antd/es/form/Form";
import TextWarehouse from "components/TextWarehouse";
import AttachmentSearch from "features/productinventorychecking/components/AttachmentSearch";
import MultiSearchLayout from "components/search/MultiSearchLayout";
import InventoryReceiptCollection from "common/collections/InventoryReceiptCollection";
import { isArray } from "lodash";

const InventoryReceiptForm = ({
	model,
	need_link_sale_order_id,
	need_link_purchase_order_id,
	disable,
	type_form,
	refetch,
	setInven,
	fieldsLock,
	isView,
}: {
	model: InventoryReceiptModel;
	need_link_sale_order_id?: number;
	need_link_purchase_order_id?: number;
	disable?: boolean;
	type_form?:
		| "export_transfer"
		| "export_sale"
		| "export_cancel"
		| "import_transfer";
	refetch?: () => void;
	setInven?: (model: InventoryReceiptModel) => void;
	fieldsLock?: (keyof InventoryReceiptJsonAddEdit)[];
	isView?: boolean;
}) => {
	const { t } = useTranslation();
	const [pathParams] = usePathParams();

	const { message } = App.useApp();
	const [form] = Form.useForm();

	const type = useWatch("type", form);

	const typeReceipt = model.direction;
	const isIn = typeReceipt === ProductReceipt.DIRECTION_INPUT;

	const hasRoleAndLimit = useLoginAccountStore(
		(state) => state.hasRoleAndLimit
	);
	const [warehouseItems, setWarehouseItems] = useState<WarehouseModel[]>([]);
	const [warehouseItem, setWarehouseItem] = useState<WarehouseJson>(
		WarehouseModel.getDefaultData()
	);

	const [status, setStatus] = useState(model.status);

	const navigate = useNavigate();

	const productRef = useRef<any>(null);

	function handleIsDisableFieldsLock(name: keyof InventoryReceiptJsonAddEdit) {
		return fieldsLock ? fieldsLock.includes(name) : false;
	}
	//check can edit update input info (add/edit features)
	const allowEditInput = useMemo(() => {
		return true;

		// if (disable) return false;
		return (
			(model.id === 0 ||
				hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, model.warehouse_id) ||
				hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, model.warehouse_id) ||
				hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, model.warehouse_id)) &&
			model.status < ProductReceipt.STATUS_COMPLETED
		);
	}, [disable, model.id, model.warehouse_id, model.status, hasRoleAndLimit]);

	//limit select warehouse option
	// const [warehouseItemsWithLimit] = useDatabaseTable<WarehouseJson>(
	//   "warehouse",
	//   Role.INVENTORY_ADD
	// );

	useEffect(() => {
		setStatus(model.status);
	}, [model]);

	const fetchDataWarehouse =
		useCallback(async (): Promise<WarehouseCollection> => {
			let collection = await new WarehouseRepository().getItems({
				filters: WarehouseRepository.getDefaultFilters(),
			});
			setWarehouseItems(collection.items);

			return collection;
		}, []);

	useEffect(() => {
		fetchDataWarehouse();
	}, [fetchDataWarehouse]);

	useEffect(() => {
		form.setFieldValue("warehouse_id", model.warehouse_id);
	}, [warehouseItems, model.warehouse_id]);

	const isEditing = model.id > 0;

	const [errors, setErrors] = useState<string[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);

	//fetch all ProductReceiptDetail
	const [detailItems, setDetailItems] = useState<ProductInventoryReceiptJson[]>(
		model.detail
	);
	const initialValues = useMemo(
		() => ({
			...model,
			type:
				model.type > 0
					? model.type
					: model.direction === ProductReceipt.DIRECTION_INPUT
					? ProductReceipt.TYPE_BUYING
					: ProductReceipt.TYPE_SELLING,
			warehouse_id: model.id > 0 ? model.warehouse_id : undefined,
			note: model.note,
			referral_id: model.referral_id,
			detail: model.detail,
			related_receipt_id: model.related_receipt_id,
			return_receipt_id: model.return_receipt_id,

			source: model.source.id
				? {
						id: +model.source.id,
						type: model.source.type,
				  }
				: {
						id: null,
						type: null,
				  },
			target: model.target.id
				? {
						id: +model.target.id,
						type: model.target.type,
				  }
				: {
						id: null,
						type: null,
				  },
		}),
		[model]
	);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			console.log(formData);
			const submitData: InventoryReceiptJsonAddEdit = {
				id: model.id,
				direction: model.direction,
				status: status,
				warehouse_id: isEditing ? model.warehouse_id : formData.warehouse_id,
				type: formData.type,
				note: formData.note,
				source_id: formData.source.id
					? +formData.source.id || formData.source.id || +model.source.id
					: 1,
				source_type: formData.source.type || model.source.type,
				target_id: formData.target.id
					? +formData.target.id || formData.target.id || +model.target.id
					: 1,
				target_type: formData.target.type || model.target.type,
				details: detailItems,
				// related_receipt_id: formData.related_receipt_id,
				return_receipt_id: isArray(formData.return_receipt_id)
					? formData.return_receipt_id
							.filter((i: any) => i.value.toString().length > 0)
							.map((ii: any) => ii.value)
							.join(",")
					: typeof formData.return_receipt_id === "string"
					? formData.return_receipt_id
					: model.return_receipt_id,
				referral_id: formData.referral_id,
				related_receipt_id: isArray(formData.related_receipt_id)
					? formData.related_receipt_id
							.filter((i: any) => i.value.toString().length > 0)
							.map((ii: any) => ii.value)
							.join(",")
					: typeof formData.related_receipt_id === "string"
					? formData.related_receipt_id
					: model.related_receipt_id,
			};

			return submitData;
		},
		[
			JSON.stringify(model),
			model.id,
			model.direction,
			model.source.id,
			model.source.type,
			model.target.id,
			model.target.type,
			status,
			detailItems,
		]
	);

	function createRollbackReceipt() {
		let body: InventoryReceiptJson = {
			...InventoryReceiptModel.getDefaultData(),
			id: 0,
			direction: model.direction,
			status: InventoryReceiptStatus.WAIT_APPROVE,
			warehouse_id: model.warehouse_id,
			type: model.type,
			note: model.note,

			source: {
				id: model.source.id,
				type: model.source.type,
			},
			target: {
				id: model.target.id,
				type: model.target.type,
				status: model.target.status,
			},
			detail: detailItems,
		};
		if (model.direction === InventoryReceiptDirection.IN) {
			body = { ...body, direction: InventoryReceiptDirection.OUT };
		}

		if (model.direction === InventoryReceiptDirection.OUT) {
			body = { ...body, direction: InventoryReceiptDirection.IN };
		}
		console.log(body);
		const inven = new InventoryReceiptModel({ ...body });
		setInven?.(inven);
		navigate(`/inventoryreceipt/add/direction/${inven.direction}`);
	}

	const doLinkingSaleOrderAfterAdd = useCallback(
		async (receipt_id: number) => {
			if (typeof need_link_sale_order_id === "number") {
				message.loading({
					content: t("productreceipt:form.linking_with_sale_order", {
						id: need_link_sale_order_id,
					}),
					key: "message_linking",
				});

				const error: string[] =
					await new OrderHelperRepository().linkProductReceipt(
						need_link_sale_order_id,
						receipt_id.toString()
					);

				//detect error on Linking
				if (error.length > 0) {
					message.error({ content: error, key: "message_linking" });
				} else {
					message.destroy("message_linking");
				}

				//show success on Product receipt
				setIsSuccess(true);
			}
		},
		[need_link_sale_order_id, t, message]
	);

	const doLinkingPurchaseOrderAfterAdd = useCallback(
		async (receipt_id: number) => {
			if (typeof need_link_purchase_order_id === "number") {
				message.loading({
					content: t("productreceipt:form.linking_with_purchase_order", {
						id: need_link_purchase_order_id,
					}),
					key: "message_linking",
				});

				const error: string[] =
					await new PurchaseOrderHelperRepository().linkProductReceipt(
						need_link_purchase_order_id,
						receipt_id.toString()
					);

				//detect error on Linking
				if (error.length > 0) {
					message.error({ content: error, key: "message_linking" });
				} else {
					message.destroy("message_linking");
				}

				//show success on Product receipt
				setIsSuccess(true);
			}
		},
		[need_link_purchase_order_id, t, message]
	);

	async function submitEdit(
		body: InventoryReceiptJsonAddEdit,
		dataDeatils: ProductInventoryReceiptJson[]
	) {
		const dataPrev = model.detail;
		const dataAdd = dataDeatils.filter(
			(newItem) => !dataPrev.some((prevItem) => prevItem.id === newItem.id)
		);

		const dataUpdate = dataDeatils.filter((newItem) => {
			const prevItem = dataPrev.find((prevItem) => prevItem.id === newItem.id);
			// Only include items that have actual changes
			return prevItem && JSON.stringify(prevItem) !== JSON.stringify(newItem);
		});

		const dataDetele = dataPrev.filter(
			(prevItem) => !dataDeatils.some((newItem) => newItem.id === prevItem.id)
		);

		// Prepare promises array
		const promises = [];

		if (dataAdd.length > 0) {
			promises.push(
				new InventoryReceiptRepository()
					.editDetail({
						details: dataAdd,
						action: "add",
						id: model.id,
					})
					.then((res) => {
						if (res.hasError()) {
							setErrors(res.error.errors);
							throw new Error(res.error.errors[0]);
						}
					})
			);
		}

		if (dataDetele.length > 0) {
			promises.push(
				new InventoryReceiptRepository()
					.editDetail({
						details: dataDetele,
						action: "delete",
						id: model.id,
					})
					.then((res) => {
						if (res.hasError()) {
							setErrors(res.error.errors);
							throw new Error(res.error.errors[0]);
						}
					})
			);
		}

		if (dataUpdate.length > 0) {
			promises.push(
				new InventoryReceiptRepository()
					.editDetail({
						details: dataUpdate,
						action: "update",
						id: model.id,
					})
					.then((res) => {
						if (res.hasError()) {
							setErrors(res.error.errors);
							throw new Error(res.error.errors[0]);
						}
					})
			);
		}

		// Execute all promises concurrently

		try {
			await Promise.all(promises);
			const myObj: InventoryReceiptModel =
				await new InventoryReceiptRepository().saveRemote(body);
			if (myObj.hasError()) {
				setErrors(myObj.error.errors);
			} else {
				setStatus(myObj.status);
				setIsSuccess(true);
				refetch?.();
			}
		} catch (error) {
			// notification.error({ message: JSON.stringify(error) });
			console.error("Error saving remote data:", error);
		}
	}

	//submit data to server
	const onSubmit = useCallback(
		async (formData: any) => {
			console.log("🚀 ~ formData:", formData);
			const body = doPrepareData(formData);
			const dataDeatils = formData.detail as ProductInventoryReceiptJson[];

			if (!isEditing) {
				let myObj: InventoryReceiptModel =
					await new InventoryReceiptRepository().saveRemote({
						...body,
						details: dataDeatils,
					});
				if (myObj.hasError()) {
					setErrors(myObj.error.errors);
				} else {
					refetch?.();
					setStatus(myObj.status);
					setIsSuccess(true);
				}
				return;
			}
			await submitEdit(body, dataDeatils);
		},
		[
			JSON.stringify(model),
			doPrepareData,
			need_link_sale_order_id,
			need_link_purchase_order_id,
			doLinkingSaleOrderAfterAdd,
			doLinkingPurchaseOrderAfterAdd,
			refetch,
			isEditing,
		]
	);

	const warehouse_id = Form.useWatch("warehouse_id", form);
	let sidebarItems = (
		<>
			{model.status === InventoryReceiptStatus.COMPLETE && !isView && (
				<Button onClick={createRollbackReceipt}>Tạo phiếu đối xứng</Button>
			)}
			{isEditing ? (
				<div className="pb-4 mb-4 border-b border-gray-300">
					{model.direction === ProductReceipt.DIRECTION_INPUT
						? t("productreceipt:warehouse_id_in")
						: t("productreceipt:warehouse_id_out")}
					: <br />
					<span className="text-lg font-bold">
						<TextWarehouse
							id={model.warehouse_id}
							warehouseItems={warehouseItems}
						/>
					</span>
				</div>
			) : (
				<WarehouseFormSelect
					rules={[
						{
							required: true,
							message: "Vui lòng chọn",
							validator: (rules, value) => {
								if (value <= 0) {
									return Promise.reject("Vui lòng chọn");
								}
								return Promise.resolve();
							},
						},
					]}
					placeholder={
						model.direction === ProductReceipt.DIRECTION_INPUT
							? t("productreceipt:warehouse_id_in")
							: t("productreceipt:warehouse_id_out")
					}
					size="large"
					required
					// disabled={model.id > 0 || handleIsDisableFieldsLock("warehouse_id")}
					label={
						model.direction === ProductReceipt.DIRECTION_INPUT
							? t("productreceipt:warehouse_id_in")
							: t("productreceipt:warehouse_id_out")
					}
					name="warehouse_id"
				/>
			)}

			<FormSelect
				required
				// disabled={model.id > 0 || handleIsDisableFieldsLock("type")}
				label={
					model.direction === ProductReceipt.DIRECTION_INPUT
						? t("productreceipt:type_in")
						: t("productreceipt:type_out")
				}
				name="type"
				options={InventoryReceiptModel.getTypeList(model.direction)}
			/>

			{model.source.type === InventoryReceiptSourceType.ORDER ? (
				<div>
					Nguồn:
					<Link target="_blank" to={`/order/edit/id/${model.source.id}`}>
						Đơn hàng #{model.source.id}
					</Link>
				</div>
			) : null}

			<Form.Item label={t("productreceipt:note")} name="note">
				<Input.TextArea
					rows={3}
					disabled={
						!allowEditInput || disable || handleIsDisableFieldsLock("note")
					}
				/>
			</Form.Item>
		</>
	);

	useEffect(() => {
		form.setFieldValue("detail", model.detail);
		form.setFieldValue("type", model.type);
		model.warehouse_id > 0 &&
			form.setFieldValue("warehouse_id", model.warehouse_id);
		form.setFieldValue("related_receipt_id", model.related_receipt_id);
	}, [form, model]);

	useEffect(() => {
		const direction = pathParams.direction;
		const type = pathParams.type;
		if (direction && type) {
			form.resetFields();
			productRef.current?.resetState();
		}
	}, [pathParams.type, pathParams.direction, isEditing]);

	useEffect(() => {
		setErrors([]);
	}, [type]);
	return (
		<>
			<InventoryReceiptFormHeader
				isEditing={isEditing}
				direction={model.direction}
				id={model.code}
			/>

			<LayoutForm
				form={form}
				initialValues={initialValues}
				errors={errors}
				isSuccess={isSuccess}
				disable_status_loading
				submitDisabled={disable || !allowEditInput}
				successTitle={
					isEditing
						? t("productreceipt:form.success.update")
						: t("productreceipt:form.success.add")
				}
				error_translate_prefix="inventoryreceipt:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={sidebarItems}
				id="productreceipt-form"
				validateTrigger="onChange"
				redirectOnSuccess={
					isEditing
						? ""
						: `${
								+pathParams.direction === InventoryReceiptDirection.IN
									? "/inventoryreceipt/in"
									: +pathParams.direction === InventoryReceiptDirection.OUT
									? "/inventoryreceipt/out"
									: ""
						  }`
				}>
				<InventoryReceiptFormStatus
					type={type}
					direction={model.direction}
					warehouse_id={model.warehouse_id}
					id={model.id}
					initStatus={model.status}
					status={status}
					setStatus={setStatus}
					disable={!allowEditInput || handleIsDisableFieldsLock("status")}
				/>
				<br />

				<InventoryReceiptProduct
					ref={productRef}
					store_id={warehouse_id}
					model={model}
					data={model.detail}
					disabled={!allowEditInput || handleIsDisableFieldsLock("details")}
					onUpdate={(data) => {
						form.setFieldValue("detail", data);
					}}></InventoryReceiptProduct>

				<FormSection
					className="mb-8"
					subtitle={
						isIn
							? "Thông tin chi tiết về nguồn phát sinh phiếu nhập"
							: "Thông tin chi tiết về nguồn phát sinh phiếu xuất"
					}
					title={`Nguồn phiếu ${isIn ? "nhập" : "xuất"}`}>
					<InventoryReceiptSource
						model={model}
						form={form}
						disabled={
							!allowEditInput || handleIsDisableFieldsLock("source_type")
						}></InventoryReceiptSource>
				</FormSection>

				<FormSection
					className="mb-0"
					subtitle={
						isIn
							? "Thông tin chi tiết về đích phiếu nhập"
							: "Thông tin chi tiết về đích phiếu xuất"
					}
					title={`Đích phiếu ${isIn ? "nhập" : "xuất"}`}>
					<InventoryReceiptTarget
						model={model}
						disabled={
							!allowEditInput || handleIsDisableFieldsLock("target_type")
						}></InventoryReceiptTarget>
				</FormSection>
				{model.direction === InventoryReceipt.DIRECTION_OUTPUT &&
					model.type === InventoryReceipt.TYPE_TRANSFERSTORE && (
						<FormSection
							className=" flex  justify-between mt-10"
							subtitle={
								isIn
									? "Thông tin chi tiết về các phiếu liên quan phiếu nhập"
									: "Thông tin chi tiết về các phiếu liên quan phiếu xuất"
							}
							title={`Các phiếu liên quan`}>
							<Row gutter={8}>
								<Col span={24} md={12}>
									{/* <Form.Item
										name="related_receipt_id"
										label={"Phiếu liên quan"}>
										<Input placeholder="mã phiếu" />
									</Form.Item> */}

									<MultiSearchLayout<
										InventoryReceiptJson,
										InventoryReceiptFilter,
										InventoryReceiptCollection
									>
										formItemProps={{
											name: "related_receipt_id",
											validateTrigger: "onBlur",
											label: "Mã phiếu",
											rules: [
												{
													required: errors.includes(
														"error_related_receipt_id_required"
													),
													message: "error_related_receipt_id_required",
												},
											],
										}}
										defaultValues={
											isArray(model.related_receipt_id)
												? model.related_receipt_id.split(",")
												: undefined
										}
										defaultFilters={InventoryReceiptRepository.getDefaultFilters()}
										keyValue="id"
										showFilters={["keyword"]}
										defaultUseFilters={["keyword"]}
										fetchRepository={(f) =>
											new InventoryReceiptRepository().getItems({ filters: f })
										}
										renderLabel={(item) => {
											const title = (
												<>
													{item.direction === InventoryReceipt.DIRECTION_INPUT
														? "Phiếu nhập"
														: "Phiếu xuất"}{" "}
													- {InventoryReceiptModel.getType(item.type)?.label}-{" "}
													{item.code}
												</>
											);
											return (
												<Tooltip title={title}>
													<Typography.Text
														className={`text-${
															InventoryReceiptModel.getDirectionLabel(
																item.direction
															).color
														}-500`}>
														{title}
													</Typography.Text>
												</Tooltip>
											);
										}}
									/>

									{/* <Form.Item
										name="related_receipt_id"
										label={"Mã phiếu"}
										validateTrigger="onBlur"
										rules={[
											{
												required: errors.includes(
													"error_related_receipt_id_required"
												),
												message: "error_related_receipt_id_required",
											},
										]}>
										<Select
											mode="multiple"
											defaultValue={model.related_receipt_id.split(",")}
											placeholder="Tìm kiếm"
											style={{ flex: 1 }}
											options={model.related_receipt_id.split(",").map((i) => ({
												label: i,
												value: i,
											}))}
										/>
										{model.referral_id > 0 && isEditing && (
											<Link target="_blank" to={`edit/id/${model.referral_id}`}>
												Xem chi tiết phiếu xuất #{model.referral_id}
											</Link>
										)}

										{errors.includes("error_related_receipt_id_required") ? (
											<p className="  text-red-500">
												Vui lòng nhập phiếu liên quan nếu muốn nhập trả hàng
											</p>
										) : null}
									</Form.Item> */}
								</Col>

								<Col span={24} md={12}>
									{/* <Form.Item
										name="return_receipt_id"
										label={"Phiếu trả hàng (nếu có)"}>
										<Input placeholder="mã phiếu" />
									</Form.Item> */}

									{/* <Form.Item
										name="return_receipt_id"
										label={"Phiếu trả hàng (nếu có)"}
										validateTrigger="onBlur"
										rules={[
											{
												required: errors.includes(
													"error_return_receipt_id_required"
												),
												message: "error_return_receipt_id_required",
											},
										]}>
										<Select
											mode="multiple"
											defaultValue={
												model.return_receipt_id.length > 0
													? model.return_receipt_id.split(",")
													: null
											}
											placeholder="Tìm kiếm"
											style={{ flex: 1 }}
											options={
												model.return_receipt_id.length > 0
													? model.return_receipt_id.split(",").map((i) => ({
															label: i,
															value: i,
													  }))
													: undefined
											}
										/>
										{model.referral_id > 0 && isEditing && (
											<Link target="_blank" to={`edit/id/${model.referral_id}`}>
												Xem chi tiết phiếu xuất #{model.referral_id}
											</Link>
										)}

										{errors.includes("error_related_receipt_id_required") ? (
											<p className="  text-red-500">
												Vui lòng nhập phiếu liên quan nếu muốn nhập trả hàng
											</p>
										) : null}
									</Form.Item> */}

									<MultiSearchLayout<
										InventoryReceiptJson,
										InventoryReceiptFilter,
										InventoryReceiptCollection
									>
										formItemProps={{
											name: "return_receipt_id",
											validateTrigger: "onBlur",
											label: "Phiếu trả hàng (nếu có)",
											rules: [
												{
													required: errors.includes(
														"error_return_receipt_id_required"
													),
													message: "error_return_receipt_id_required",
												},
											],
										}}
										defaultValues={
											isArray(model.return_receipt_id)
												? model.return_receipt_id.split(",")
												: undefined
										}
										defaultFilters={InventoryReceiptRepository.getDefaultFilters()}
										keyValue="id"
										showFilters={["keyword"]}
										defaultUseFilters={["keyword"]}
										fetchRepository={(f) =>
											new InventoryReceiptRepository().getItems({ filters: f })
										}
										renderLabel={(item) => {
											const title = (
												<>
													{item.direction === InventoryReceipt.DIRECTION_INPUT
														? "Phiếu nhập"
														: "Phiếu xuất"}{" "}
													- {InventoryReceiptModel.getType(item.type)?.label}-{" "}
													{item.code}
												</>
											);
											return (
												<Tooltip title={title}>
													<Typography.Text
														className={`text-${
															InventoryReceiptModel.getDirectionLabel(
																item.direction
															).color
														}-500`}>
														{title}
													</Typography.Text>
												</Tooltip>
											);
										}}
									/>
								</Col>
							</Row>
						</FormSection>
					)}

				{model.direction === InventoryReceipt.DIRECTION_INPUT && (
					<FormSection
						className=" flex  justify-between mt-10"
						subtitle={"Các phiếu liên kết "}
						title={`Liên kết phiếu`}>
						<MultiSearchLayout<
							InventoryReceiptJson,
							InventoryReceiptFilter,
							InventoryReceiptCollection
						>
							formItemProps={{
								name: "related_receipt_id",
								validateTrigger: "onBlur",
								rules: [
									{
										required: errors.includes(
											"error_related_receipt_id_required"
										),
										message: "error_related_receipt_id_required",
									},
								],
							}}
							defaultValues={
								isArray(model.related_receipt_id)
									? model.related_receipt_id.split(",")
									: undefined
							}
							defaultFilters={InventoryReceiptRepository.getDefaultFilters()}
							keyValue="id"
							showFilters={["keyword"]}
							defaultUseFilters={["keyword"]}
							fetchRepository={(f) =>
								new InventoryReceiptRepository().getItems({ filters: f })
							}
							renderLabel={(item) => (
								<Typography.Text
									className={`text-${
										InventoryReceiptModel.getDirectionLabel(item.direction)
											.color
									}-500`}>
									{item.direction === InventoryReceipt.DIRECTION_INPUT
										? "Phiếu nhập"
										: "Phiếu xuất"}{" "}
									- {item.code}
								</Typography.Text>
							)}
						/>

						{/* <Form.Item
							name="related_receipt_id"
							label={"Mã phiếu"}
							validateTrigger="onBlur"
							rules={[
								{
									required: errors.includes(
										"error_related_receipt_id_required"
									),
									message: "error_related_receipt_id_required",
								},
							]}>
							<Select
								mode="multiple"
								defaultValue={model.related_receipt_id.split(",")}
								placeholder="Tìm kiếm"
								style={{ flex: 1 }}
								options={model.related_receipt_id.split(",").map((i) => ({
									label: i,
									value: i,
								}))}
							/>
							{model.referral_id > 0 && isEditing && (
								<Link target="_blank" to={`edit/id/${model.referral_id}`}>
									Xem chi tiết phiếu xuất #{model.referral_id}
								</Link>
							)}

							{errors.includes("error_related_receipt_id_required") ? (
								<p className="  text-red-500">
									Vui lòng nhập phiếu liên quan nếu muốn nhập trả hàng
								</p>
							) : null}
						</Form.Item> */}
					</FormSection>
				)}

				{type === InventoryReceipt.TYPE_OUT_EXPORT_CANCEL && (
					<FormSection
						className=" flex  justify-between mt-10"
						subtitle={"Yêu cầu chứng từ cho việc xuất - hủy"}
						title={`Chứng từ hủy`}>
						<AttachmentSearch
							required
							name="referral_id"
							label={"Chứng từ hủy"}
							validateTrigger="onBlur"
							rules={[
								{
									required: true,
									message: t("common:form.required"),
									validator: (_, value) => {
										if (!value) {
											return Promise.reject(t("common:form.required"));
										}
										return Promise.resolve();
									},
								},
							]}
						/>
					</FormSection>
				)}
			</LayoutForm>
		</>
	);
};

export default InventoryReceiptForm;

function TextStore({ warehouse_id }: { warehouse_id: number }) {
	//   const [warehouseItems, setWarehouseItems] = useState<WarehouseStoreModel[]>(
	//     []
	//   );
	const [store, setStore] = useState(StoreModel.getDefaultData());

	const fetchDataWarehouseStore = useCallback(async () => {
		let collection = await new WarehouseRepository().getItemWarehouseStore(
			warehouse_id
		);
		if (!collection.hasError()) {
			const warehouseStore = collection;
			if (warehouseStore) {
				const storeJson = await new StoreRepository().getItem(
					warehouseStore.store_id
				);
				setStore(storeJson.toJson());
			}
		}
	}, [warehouse_id]);

	useEffect(() => {
		fetchDataWarehouseStore();
	}, [fetchDataWarehouseStore]);

	return <p>{store.name ? store.name : "không tìm thấy"}</p>;
}
