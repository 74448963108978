import BaseCollection from "common/collections/BaseCollection";
import ContractTypeModel from "common/models/ContractTypeModel";
import { ContractTypeCollectionJson, ContractTypeJson } from "common/types/ContractType";

class ContractTypeCollection extends BaseCollection<
  ContractTypeJson,
  ContractTypeModel,
  ContractTypeCollectionJson
> {
  itemsFromJson(jsonItems: ContractTypeJson[]): ContractTypeModel[] {
    return jsonItems.map((item) => new ContractTypeModel(item));
  }
}

export default ContractTypeCollection;
