import { Col, Form, Row, Select } from "antd";
import { RegionJson } from "common/types/Region";
import dbm from "dbm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type {
	RegionValue,
	RegionInputProps,
} from "common/interfaces/RegionPicker";
const RegionPicker: React.FC<RegionInputProps> = ({
	value,
	onChange,
	disabled,
	span,
	noStyle,
	className,
	required,
	rules,
}: RegionInputProps) => {

	console.log(value)
	const { t } = useTranslation();
	const [regionItems, setRegionItems] = useState<RegionJson[]>([]);
	const [regionId, setRegionId] = useState<RegionValue["region_id"]>(
		value?.region_id === 0 ? null : value?.region_id
	);
	const [subRegionId, setSubRegionId] = useState<RegionValue["sub_region_id"]>(
		value?.sub_region_id === 0 ? null : value?.sub_region_id
	);
	const [subSubRegionId, setSubSubRegionId] = useState<
		RegionValue["sub_sub_region_id"]
	>(value?.sub_sub_region_id || null);

	const triggerChange = (changedValue: RegionValue) => {
		onChange?.({ ...value, ...changedValue });
	};

	const onRegionIdChange = (newId: number) => {
		setRegionId(newId);
		triggerChange({
			region_id: newId,
			sub_region_id: null,
			sub_sub_region_id: null,
		});
	};

	const onSubRegionIdChange = (newId: number) => {
		setSubRegionId(newId);
		triggerChange({ sub_region_id: newId, sub_sub_region_id: 0 });
	};

	const onSubSubRegionIdChange = (newId: number) => {
		setSubSubRegionId(newId);
		triggerChange({ sub_sub_region_id: newId });
	};

	useEffect(() => {
		setRegionId(value?.region_id);
		setSubRegionId(value?.sub_region_id);
		setSubSubRegionId(value?.sub_sub_region_id);
	}, [value]);

	// useEffect(() => {
	// 	let items = dbm.getCollection("region")?.chain().data();
	// 	if (
	// 		typeof items !== "undefined" &&
	// 		items.hasOwnProperty("length") &&
	// 		items.length > 0
	// 	) {
	// 		setRegionItems(items);
	// 	}
	// }, []);

	return (
		<Row gutter={16}>
			<Col md={span || 8} xs={24}>
				<Form.Item
					label={t("common:region")}
					noStyle={noStyle}
					className={className}
					required={required}>
					<Select
						value={regionId === 0 ? null : regionId}
						allowClear
						disabled={disabled}
						onChange={onRegionIdChange}
						placeholder={t("common:region_placeholder")}
						style={{ width: "100%" }}
						showSearch
						options={regionItems
							.filter((i) => i.parent_id === 0)
							.sort((a, b) => {
								return a.display_order > b.display_order ? 1 : -1;
							})
							.map((i) => ({
								value: i.id,
								label: i.name,
							}))}
						optionFilterProp="label"
						popupMatchSelectWidth={false}
						filterOption={(input: any, option: any) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.label
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.replace(/[đĐ]/g, "d")
								.toLowerCase()
								.indexOf(
									input
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.replace(/[đĐ]/g, "d")
										.toLowerCase()
								) >= 0
						}
					/>
				</Form.Item>
			</Col>
			{typeof regionId !== "undefined" && regionId !== null && regionId > 0 && (
				<>
					<Col md={span || 8} xs={24}>
						<Form.Item
							label={t("common:sub_region")}
							noStyle={noStyle}
							className={className}
							required={required}>
							<Select
								value={subRegionId === 0 ? null : subRegionId}
								allowClear
								disabled={disabled}
								onChange={onSubRegionIdChange}
								placeholder={t("common:sub_region_placeholder")}
								style={{ width: "100%" }}
								popupMatchSelectWidth={false}
								showSearch
								options={regionItems
									.filter((i) => i.parent_id === regionId)
									.sort((a, b) => {
										return a.display_order > b.display_order ? 1 : -1;
									})
									.map((i) => ({
										value: i.id,
										label: i.name,
									}))}
								optionFilterProp="label"
								filterOption={(input: any, option: any) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >=
										0 ||
									option.label
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.replace(/[đĐ]/g, "d")
										.toLowerCase()
										.indexOf(
											input
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.replace(/[đĐ]/g, "d")
												.toLowerCase()
										) >= 0
								}
							/>
						</Form.Item>
					</Col>
					{typeof subRegionId !== "undefined" &&
						subRegionId !== null &&
						subRegionId > 0 && (
							<Col md={span || 8} xs={24}>
								<Form.Item
									label={t("common:sub_sub_region")}
									noStyle={noStyle}
									className={className}
									required={required}>
									<Select
										value={subSubRegionId === 0 ? null : subSubRegionId}
										allowClear
										disabled={disabled}
										onChange={onSubSubRegionIdChange}
										placeholder={t("common:sub_sub_region_placeholder")}
										style={{ width: "100%" }}
										showSearch
										popupMatchSelectWidth={false}
										options={regionItems
											.filter((i) => i.parent_id === subRegionId)
											.sort((a, b) => {
												return a.display_order > b.display_order ? 1 : -1;
											})
											.map((i) => ({
												value: i.id,
												label: i.name,
											}))}
										optionFilterProp="label"
										filterOption={(input: any, option: any) =>
											option.label.toLowerCase().indexOf(input.toLowerCase()) >=
												0 ||
											option.label
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.replace(/[đĐ]/g, "d")
												.toLowerCase()
												.indexOf(
													input
														.normalize("NFD")
														.replace(/[\u0300-\u036f]/g, "")
														.replace(/[đĐ]/g, "d")
														.toLowerCase()
												) >= 0
										}
									/>
								</Form.Item>
							</Col>
						)}
				</>
			)}
		</Row>
	);
};

export default RegionPicker;
