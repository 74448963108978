import PageHeader from 'components/page/PageHeader';
import PageHeaderButton from 'components/page/PageHeaderButton';
import { useTranslation } from 'react-i18next';

const DmsNewsCategoryListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader heading={t("dmsnewscategory:heading_list")}>
      <PageHeaderButton link="/dmsnewscategory/add">
        {t("dmsnewscategory:add_button")}
      </PageHeaderButton>
    </PageHeader>
  );
};

export default DmsNewsCategoryListHeader;
