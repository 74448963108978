import { Button, Form, FormInstance, Input, Upload as UploadAntd } from "antd";
import FileApi from "common/api/FileApi";
import File from "common/constants/File";
import FileModel from "common/models/FileModel";
import ErrorAlert from "components/ErrorAlert";
import FileUploaderItem from "components/file/uploader/FileUploaderItem";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronUp, IconPlus } from "@tabler/icons-react";

import type { UploadRenderType, Upload } from "common/types/File";
const FileUploaderButton = ({
  directoryId,
  objectType,
  objectId,
  origin,
  icon,
  label,
  onUploadCompleted,
  onDeleteCompleted,
  allowExtensions,
  multipleSize,
  allowHideList,
  uploadRenderType,
  isSingleFile,
  initFiles,
  formName,
  form,
  maxCount,
  showUploadList = true,
  showInput,
}: {
  onUploadCompleted?: (f: FileModel) => void;
  onDeleteCompleted?: (f: FileModel) => void;
  icon?: React.ReactNode;
  label?: string;
  directoryId?: number;
  objectType?: number;
  objectId?: number;
  origin: string;
  allowExtensions?: string[];
  multipleSize?: number;
  allowHideList?: boolean;
  uploadRenderType?: UploadRenderType;
  isSingleFile?: boolean;
  initFiles?: Upload[];
  formName?: string;
  form?: FormInstance;
  maxCount?: number;
  showUploadList?: boolean;
  showInput?: boolean;
}) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState(initFiles || []);
  const [errors, setErrors] = useState([]);

  const triggerFormChangeValues = useCallback(
    (values: any) => {
      //detect update form fields
      if (typeof formName !== "undefined" && typeof form !== "undefined") {
        form.setFieldsValue({
          [formName]: values,
        });
      }
    },
    [form, formName]
  );

  const onDeleteCompletedProxy = (file: Upload) => {
    //trigger remove from models
    const updatedList = files.filter((f) => f.uid !== file.uid);

    if (
      typeof file.response !== "undefined" &&
      typeof file.response !== "string" &&
      file?.response?.id > 0
    ) {
      if (typeof onDeleteCompleted === "function") {
        onDeleteCompleted(new FileModel(file.response));
      }

      triggerFormChangeValues(updatedList);
    }

    //remove from upload list first
    setFiles(updatedList);
  };

  const uploadPropsFileApi = new FileApi(false).getUploadProps({
    directory_id: directoryId || 0,
    object_type: objectType || File.OBJECTTYPE_ATTACHMENT,
    object_id: objectId || 0,
    origin: origin,
  });

  const maxMultipleSize = multipleSize || 1;

  //default: Images only
  const acceptExtensions =
    typeof allowExtensions !== "undefined" &&
    allowExtensions &&
    allowExtensions.length > 0
      ? allowExtensions
      : FileModel.getImageExtensions();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // useEffect(() => {
  // 	if (initFiles && initFiles?.length > 0) {
  // 		setFiles(
  // 			initFiles?.map((f) => ({
  // 				...f,
  // 				url: process.env.REACT_APP_ASSET_URL + "/uploads/filecloud/" + f.url,
  // 			}))
  // 		);
  // 	}
  // }, [initFiles]);
  // !f.url?.includes("https")?process.env.REACT_APP_ASSET_URL+""
  return (
    <div>
      <Form.Item
        name={formName}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
      >
        <UploadAntd
          {...uploadPropsFileApi}
          showUploadList={showUploadList}
          maxCount={maxCount}
          className={
            typeof isSingleFile !== "undefined" &&
            isSingleFile &&
            files.length === 1
              ? "ant-upload-hide-trigger"
              : ""
          }
          multiple={maxMultipleSize > 1}
          listType={uploadRenderType === "list" ? "picture" : "picture-card"}
          fileList={files}
          accept={
            acceptExtensions.length > 0
              ? acceptExtensions.map((e) => "." + e).join(",")
              : ""
          }
          beforeUpload={(file, fileList) => {
            if (fileList.length > maxMultipleSize) {
              setErrors([
                t("file:form.error.error_multiple_size_exceed", {
                  maxFile: maxMultipleSize,
                }),
              ]);
              return UploadAntd.LIST_IGNORE;
            } else {
              setErrors([]);
            }

            return true;
          }}
          itemRender={(_originNode, file, _fileList, actions) => {
            //we extract file from state, not directory from `file` object,
            //because on error, the file.response in state will contain string, this is correct
            const foundFile = files.find((f) => f.uid === file.uid);

            return typeof foundFile !== "undefined" ? (
              <FileUploaderItem
                file={foundFile}
                uploadRenderType={uploadRenderType || "list"}
                onDeleteCompleted={(f) => {
                  onDeleteCompletedProxy(f);
                  actions.remove();
                }}
              />
            ) : null;
          }}
          onChange={({ file, fileList, event }) => {
            //change the response of error file
            setFiles(
              fileList.map((f) => {
                return f.status === "error"
                  ? {
                      ...f,
                      response:
                        typeof f?.response !== "undefined" &&
                        typeof f.response.error === "string"
                          ? f.response.error
                          : Array.isArray(f?.response?.error)
                          ? f.response.error.join(".")
                          : "UploadError",
                    }
                  : f;
              })
            );

            //append uploaded file to list
            if (
              file?.status === "done" &&
              typeof file?.response === "object" &&
              file?.response?.id > 0 &&
              typeof onUploadCompleted === "function"
            ) {
              const newFile = new FileModel(file.response);
              onUploadCompleted(newFile);
            }
          }}
        >
          <div style={{ marginBottom: 8 }}>
            {typeof isSingleFile !== "undefined" &&
            isSingleFile &&
            files.length === 1 ? null : (
              <>
                {uploadRenderType === "list" ? (
                  <Button
                    shape="round"
                    type="dashed"
                    icon={
                      icon || <IconPlus size="14" style={{ marginRight: 5 }} />
                    }
                  >
                    {label || t("file:select_file")}
                  </Button>
                ) : (
                  <div>
                    {icon || <IconPlus size="18" style={{ marginRight: 5 }} />}
                    <div style={{ marginTop: 8 }}>
                      {label || t("file:select_file")}
                    </div>
                  </div>
                )}
              </>
            )}

            {errors.length > 0 ? (
              <ErrorAlert
                style={{ marginTop: 10 }}
                translate_prefix=""
                items={errors}
                type="warning"
              />
            ) : null}
          </div>
        </UploadAntd>
      </Form.Item>

      {typeof allowHideList !== "undefined" &&
      allowHideList === true &&
      files.length > 0 ? (
        <div className="mt-2 border-t border-gray-100">
          <Button
            style={{ marginTop: 8, opacity: 0.7 }}
            icon={<IconChevronUp size="14" style={{ marginRight: 5 }} />}
            size="small"
            type="text"
            onClick={(e) => {
              setFiles([]);
            }}
          >
            <small>{t("file:hide_success_list")}</small>
          </Button>
        </div>
      ) : null}

      {showInput && <Input placeholder="Hoặc nhập đường dẫn" onBlur={(e)=>{
		if(e.target.value){

			onUploadCompleted?.(new FileModel({...FileModel.getDefaultData() , url : e.target.value}))
		}

	  }} />}
    </div>
  );
};

export default FileUploaderButton;
