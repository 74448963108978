import React from "react";

const EyesIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08833 7.00002C9.08833 8.15502 8.155 9.08835 7 9.08835C5.845 9.08835 4.91167 8.15502 4.91167 7.00002C4.91167 5.84502 5.845 4.91168 7 4.91168C8.155 4.91168 9.08833 5.84502 9.08833 7.00002Z"
        stroke="#898889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.8242C9.05917 11.8242 10.9783 10.6108 12.3142 8.51085C12.8392 7.68835 12.8392 6.30585 12.3142 5.48335C10.9783 3.38335 9.05917 2.17001 7 2.17001C4.94083 2.17001 3.02167 3.38335 1.68583 5.48335C1.16083 6.30585 1.16083 7.68835 1.68583 8.51085C3.02167 10.6108 4.94083 11.8242 7 11.8242Z"
        stroke="#898889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyesIcon;
