import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { AuthorizedcompanyJson, AccountRole } from "common/types/Account";
import BaseModel from "./BaseModel";
import FileModel from "./FileModel";
import CompanyModel from "./CompanyModel";

class AuthorizedCompanyModel
  extends BaseModel
  implements BaseModelJson<AuthorizedcompanyJson>
{
  date_created: number;
  date_last_login: number;
  date_modified: number;
  email: string;
  full_name: string;
  id: number;

  constructor(json: AuthorizedcompanyJson) {
    super();
    this.id = json.id || 0;
    this.date_created = json.date_created || 0;
    this.date_last_login = json.date_last_login || 0;
    this.date_modified = json.date_modified || 0;
    this.email = json.email || "";
    this.full_name = json.full_name || "";
  }

  toJson(): AuthorizedcompanyJson {
    return {
      id: this.id,
      date_created: this.date_created,
      date_last_login: this.date_last_login,
      date_modified: this.date_modified,
      email: this.email,
      full_name: this.full_name,
    };
  }

  static getDefaultData(): AuthorizedcompanyJson {
    return {
      id: 0,
      date_created: 0,
      date_last_login: 0,
      date_modified: 0,
      email: "",
      full_name: "",
    };
  }

  // static parseSessionResponse(json: AuthorizedcompanyJson): AccountJson {
  //   //the hardest part is the "role" section
  //   //response role has format: id,id,id-subid-subid..
  //   //this role need to convert to 'AccountRole' array
  //   let roles: AccountRole[] = [];
  //   if (typeof json.role === "string" && json.role.length > 0) {
  //     roles = json.role.split(",").flatMap((rolePart: string) => {
  //       //detect has objectid
  //       if (rolePart.indexOf(":") !== -1) {
  //         const rolePartGroup = rolePart.split(":", 2);
  //         return [
  //           {
  //             subject_id: parseInt(rolePartGroup[0]),
  //             id_list: rolePartGroup[1].split("-").map(Number),
  //           },
  //         ];
  //       } else {
  //         return [
  //           {
  //             subject_id: parseInt(rolePart),
  //             id_list: [],
  //           },
  //         ];
  //       }
  //     });
  //   }

  //   return {
  //     id: json.user.id || 0,
  //     full_name: json.user.full_name || "",
  //     screenname: json.user.screenname || "",
  //     avatar_file_id: json.user.avatar_file_id || 0,
  //     avatar: json.user.avatar || FileModel.getDefaultData(),
  //     jwt: json.jwt || "",
  //     role: roles,
  //     company:
  //       typeof json.company !== "undefined"
  //         ? json.company
  //         : CompanyModel.getDefaultData(),
  //   };
  // }
}

export default AuthorizedCompanyModel;
