import { Typography, message } from "antd";
import PageDataTable from "components/page/PageDataTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ReportLeaveCollection from "common/collections/ReportLeaveCollection";
import ReportLeaveModel from "common/models/ReportLeaveModel";
import ReportLeaveRepository from "common/repositories/ReportLeaveRepository";
import { FilterReportLeave } from "common/types/ReportLeave";
import { TableColumnsType } from "common/types/Table";
import ReportLeaveExport from "./ReportLeaveExport";
import ReportLeaveFilter from "./ReportLeaveFilter";
import ReportLeaveHeader from "./ReportLeaveHeader";
import PageDataPagination from "components/page/PageDataPagination";

const ReportLeave = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterReportLeave = useMemo(
    () => ReportLeaveRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterReportLeave>(defaultFilters);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  useFilterLocation(defaultFilters, filters);

  //Fetch data from this collections

  const fetchData = useCallback(async (): Promise<any> => {
    let collection = await new ReportLeaveRepository().getItems({ filters });
    if (collection) {
      setTotal(collection.total);
      setDataSource(collection.items);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return collection;
  }, [filters]);

  //////////////////////////////////////////

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ReportLeaveModel> = [
    {
      title: "ID",
      key: "internalid",
      width: 90,
    },
    {
      title: t("common:columns.full_name"),
      key: "employee_name",
      width: 200,
    },
    {
      title: t("common:columns.department"),
      key: "department_name",
      width: 190,
    },
    {
      title: t("common:columns.job_title"),
      key: "jobtitle",
      width: 160,
    },

    {
      title: t("reportleave:columns.ncl"),
      key: "paid_leave",
      width: 180,
    },
    {
      title: t("reportleave:columns.nkl"),
      key: "unpaid_leave",
      width: 180,
    },
    {
      title: t("reportleave:columns.nbh"),
      key: "insurance_leave",
      width: 180,
    },
    {
      title: (
        <span className="font-medium" style={{ color: "#e15388" }}>
          {t("reportleave:columns.sum")}
        </span>
      ),
      key: "sum",
      fixed: "right",
      width: 140,
      align: "right",
      // sorter: (a: any, b: any) => a.sum - b.sum,
      render: (date) => (
        <Typography.Text className="font-medium" style={{ color: "#e15388" }}>
          {date}
        </Typography.Text>
      ),
    },
  ];

  return (
    <>
      <ReportLeaveHeader>
        {total > 0 && <ReportLeaveExport filters={filters} />}
      </ReportLeaveHeader>

      <ReportLeaveFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />

      <PageDataTable<FilterReportLeave, ReportLeaveModel, ReportLeaveCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default ReportLeave;
