import BaseApi from "./BaseApi";
import AxiosClient from "./AxiosClient";
import { GetListProps } from "common/types/Filter";
import Helper from "common/utils/helper";

//////////////////////////////////
//Phần thay thế
import { FilterLeaveStep, LeaveStepJsonAddEdit } from "common/types/LeaveStep";
import LeaveStepCollection from "common/collections/LeaveStepCollection";
import LeaveStepModel from "common/models/LeaveStepModel";

const SERVICE_URL = "/leaves";
const SERVICE_URL_APPROVER = "/approvers";

class LeaveStepApi extends BaseApi {
  //
  async getList(): Promise<LeaveStepCollection> {
    let collection = new LeaveStepCollection();
    const queryData = {
      params: {
        type: "leave",
      },
    };

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL_APPROVER + "/getallsteps",
        queryData
      );
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }
    return collection;
  }
  //
  async getDetail(id: number): Promise<LeaveStepCollection> {
    let item = new LeaveStepCollection();

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/steps/" + id
      );
      if (response.hasOwnProperty("data")) {
        item = new LeaveStepCollection(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async add(props: LeaveStepJsonAddEdit): Promise<LeaveStepModel> {
    let item = new LeaveStepModel(LeaveStepModel.getDefaultData());

    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);

    try {
      let postData = {
        ...convertProps,
      };

      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new LeaveStepModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }
    return item;
  }
  //
  async edit(props: LeaveStepJsonAddEdit): Promise<LeaveStepModel> {
    let item = new LeaveStepModel(LeaveStepModel.getDefaultData());
    // remove id key
    ///////////////
    let dataProps: any = { ...props };
    delete dataProps.id;
    const convertProps = Helper.convertParams(dataProps);
    try {
      const postData = {
        ...convertProps,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + props.id,
        postData
      );
      if (response.hasOwnProperty("data")) {
        item = new LeaveStepModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }
  //
  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().put(SERVICE_URL + "/delete/" + id);
      if (response.status !== 200) {
        resultErrors.push("error_delete_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default LeaveStepApi;
