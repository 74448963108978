import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";

import type { MoneyInputProps } from "common/interfaces/MoneyInput";

const MoneyInput: React.FC<MoneyInputProps> = ({
  value,
  onChange,
  ...props
}: MoneyInputProps) => {
  const [money, setMoney] = useState(value);

  const triggerChange = (changedValue: number) => {
    onChange?.(changedValue);
  };

  const updateMoney = (newMoney: number) => {
    setMoney(newMoney);

    triggerChange(newMoney);
  };

  const onChangeMoney = (v: number | null) => {
    if (typeof v !== "undefined" && v !== null) {
      updateMoney(v);
    }
  };

  useEffect(() => {
    setMoney(value);
  }, [value]);

  return (
    <InputNumber<number>
      disabled={props.disabled}
      controls={props.controls || false}
      className={props.className || "input-number-right-align"}
      addonBefore={props.addonBefore}
      value={money}
      onChange={onChangeMoney}
      min={0}
      decimalSeparator=""
      precision={0}
      prefix={<span className="text-gray-500">{props.prefix}</span>}
      step={1000}
      stringMode={false}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      parser={(value) =>
        typeof value !== "undefined"
          ? Number(value.replace(/\$\s?|(,*)/g, ""))
          : 0
      }
      {...props}
    />
  );
};

export default MoneyInput;
