import { Col, Form, Image, Input, InputNumber, Modal, Row } from "antd";
import FileModel from "common/models/FileModel";
import FileList from "components/file/list/FileList";
import FileUploaderContext from "contexts/FileUploaderContext";
import useWindowDimensions from "hooks/useWindowDimensions";
import React, { useCallback, useContext, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import useFileManagerStore from "zustands/useFileManagerStore";

import { IconEye } from "@tabler/icons-react";

import { ImageAttribute } from "./extensions/HtmlEditorExtensionImagePicker";

const HtmlEditorImagePickerModal = ({
  open,
  setOpen,
  onSubmit,
  attributes,
}: {
  open: boolean;
  setOpen: (open: boolean) => any;
  onSubmit: (attributes: ImageAttribute) => void;
  attributes: ImageAttribute;
}) => {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation("htmleditor");
  const myFileUploader = useContext(FileUploaderContext);

  const [activeFile, setActiveFile] = useFileManagerStore((state) => [
    state.activeFile,
    state.setActiveFile,
  ]);

  const [form] = Form.useForm();
  const initialValues = {
    src: attributes.src,
    alt: attributes.alt,
    title: attributes.title,
    width: attributes.width,
    height: attributes.height,
  };

  const onSave = async (formData: any) => {
    const submitData: ImageAttribute = {
      src: formData.src,
      alt: formData.alt,
      title: formData.title,
      width: formData.width,
      height: formData.height,
    };
    onSubmit(submitData);
    setOpen(false);
    form.resetFields();
  };

  const onSelectFile = useCallback(
    (file: FileModel) => {
      if (activeFile?.id === file.id) {
        setActiveFile(undefined);

        form.setFieldsValue({
          src: "",
          caption: "",
          width: 0,
          height: 0,
        });
      } else {
        setActiveFile(file);

        form.setFieldsValue({
          src: file.url,
          caption: "",
          width: file.width > 0 ? file.width : 50,
          height: file.height > 0 ? file.height : 50,
        });
      }
    },
    [activeFile, setActiveFile, form]
  );

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        src: attributes.src,
        alt: attributes.alt,
        title: attributes.title,
        width: attributes.width,
        height: attributes.height,
      });
    }
  }, [form, attributes]);

  //positioning the modal
  const margin = 50;
  const modalWidth = width - margin * 2;
  const contentHeight = height - margin * 2 - 50 - 40 - 53;

  return (
    <Modal
      style={{ top: margin, bottom: margin }}
      className="file-manager-modal"
      width={modalWidth}
      open={open}
      title={t("image_picker_modal.heading")}
      closable={true}
      maskClosable={true}
      okButtonProps={{ disabled: typeof activeFile === "undefined" }}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        form.submit();
      }}
      okText={t("image_picker_modal.submit")}
      cancelText={t("common:close")}
      forceRender
    >
      <Row gutter={0}>
        <Col span={18} className="pr-4">
          <div>
            {myFileUploader.objectType > 0 &&
            myFileUploader.origin.length > 0 ? (
              <FileList
                objectType={myFileUploader.objectType}
                origin={myFileUploader.origin}
                allowExtensions={[]}
                onSelectFile={onSelectFile}
              />
            ) : (
              <>File Uploader is not configured.</>
            )}
          </div>
        </Col>
        <Col span={6} className="p-2 bg-gray-100 rounded-md">
          <Scrollbars
            style={{
              height: contentHeight,
              overflow: "hidden",
            }}
            autoHide
          >
            <Form
              className="m-2"
              layout="vertical"
              initialValues={initialValues}
              form={form}
              onFinish={onSave}
            >
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.src !== currentValues.src
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("src") !== "" ? (
                    <>
                      <Row gutter={16}>
                        <Col md={24} xs={24}>
                          <Form.Item
                            label={t("image_picker_modal.alt")}
                            name="alt"
                          >
                            <Input.TextArea autoSize />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16} className="hidden">
                        <Col md={24} xs={24}>
                          <Form.Item
                            label={t("image_picker_modal.url")}
                            name="src"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "image_picker_modal.error_url_required"
                                ),
                              },
                            ]}
                          >
                            <Input.TextArea
                              autoSize
                              placeholder="https://..."
                              readOnly
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: 10 }}>
                        <Image
                          key={getFieldValue("src")}
                          src={getFieldValue("src")}
                          style={{
                            borderRadius: 4,
                            maxWidth: "100%",
                            maxHeight: 200,
                            boxShadow: "2px 2px 5px 5px #eee",
                          }}
                          preview={{
                            src: getFieldValue("src"),
                            mask: (
                              <>
                                <IconEye size="16" /> &nbsp; {t("file:preview")}
                              </>
                            ),
                          }}
                        />
                      </Row>
                      <Row gutter={0}>
                        <Col md={12} xs={12}>
                          <Form.Item
                            label={t("image_picker_modal.width")}
                            name="width"
                          >
                            <InputNumber step={10} />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={12}>
                          <Form.Item
                            label={t("image_picker_modal.height")}
                            name="height"
                          >
                            <InputNumber step={10} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null
                }
              </Form.Item>
            </Form>
          </Scrollbars>
        </Col>
      </Row>
    </Modal>
  );
};

export default HtmlEditorImagePickerModal;
