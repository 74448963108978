import ShippingBillApi from 'common/api/ShippingBillApi';
import ShippingBillCollection from 'common/collections/ShippingBillCollection';
import ShippingBillModel from 'common/models/ShippingBillModel';
import { GetListProps } from 'common/types/Filter';

import BaseRepository from './BaseRepository';

import type {
  ShippingBillJsonAddEdit,
  FilterShippingBill,
} from "common/types/ShippingBill";

class ShippingBillRepository extends BaseRepository<ShippingBillApi> {
  _api: ShippingBillApi | null;

  constructor() {
    super();
    this._api = new ShippingBillApi(true);
  }

  static getDefaultFilters(): FilterShippingBill {
    return {
      page: 1,
      limit: 20,
      sortby: "id",
      sorttype: "DESC",
      status: -1,
      keyword: "",
      sale_order_id: -1,
    };
  }

  async getItems(props: GetListProps<FilterShippingBill>) {
    return this._api
      ? await this._api.getList(props)
      : new ShippingBillCollection();
  }

  async getItem(id: number): Promise<ShippingBillModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new ShippingBillModel(ShippingBillModel.getDefaultData());
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async saveRemote(data: ShippingBillJsonAddEdit): Promise<ShippingBillModel> {
    if (this._api) {
      if (data.id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new ShippingBillModel(ShippingBillModel.getDefaultData());
    }
  }

  async syncItemStatus(id: number): Promise<ShippingBillModel> {
    if (this._api) {
      return await this._api.syncStatusWithGateway(id);
    } else {
      return new ShippingBillModel(ShippingBillModel.getDefaultData());
    }
  }
}

export default ShippingBillRepository;
