import { Tag, Tooltip, Typography } from "antd";
import OrderCollection from "common/collections/OrderCollection";
import Order from "common/constants/Order";
import OrderModel from "common/models/OrderModel";
import OrderRepository from "common/repositories/OrderRepository";
import { FilterOrder } from "common/types/Order";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import TextDateTime from "components/TextDateTime";
import TextMoney from "components/TextMoney";
import TextRegion from "components/TextRegion";
import TextStore from "components/TextStore";
import TextTagList from "components/TextTagList";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconDiscountCheck } from "@tabler/icons-react";

import OrderItemEcomPlatformTypeImage from "../item/OrderItemEcomPlatformTypeImage";
import OrderListFilter from "./OrderListFilter";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import OrderSellerRepository from "common/repositories/OrderSellerRepository";
import TableDelete from "components/table/TableDelete";
import OrderSellerListHeader from "./OrderSellerListHeader";
import { Link } from "react-router-dom";

const OrderSellerList = () => {
	const { t } = useTranslation();
	const account = useLoginAccountStore((state) => state.account);

	const [total, setTotal] = useState(0);

	//////////////////////////////////////////
	//Filtering
	const defaultFilters: FilterOrder = useMemo(() => {
		return { ...OrderRepository.getDefaultFilters(), seller_id: account.id };
	}, [account.id]);
	const [filters, setFilters] = useStateFilter<FilterOrder>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [dataSource, setDataSource] = useState<OrderModel[]>([]);
	const fetchData = useCallback(async (): Promise<OrderCollection> => {
		let collection = await new OrderSellerRepository().getItems({
			filters,
		});

		setDataSource(collection.items);
		setTotal(collection.total);

		return collection;
	}, [filters]);

	//Table columns
	const columns: TableColumnsType<OrderModel> = [
		{
			title: t("order:id"),
			key: "code",
			width: 200,
			align: "center",
			toggletype: "ignore",
			render: (code, record) => {
				return (
					<TableLinkText
						title={t("order:detail_button")}
						link={"/orderseller/edit/id/" + record.id}
						className="text-base font-bold">
						<Typography.Text className="text-blue-600">{code}</Typography.Text>
					</TableLinkText>
				);
			},
		},
		{
			title: " ",
			togglelabel: t("order:ecom_platform_type"),
			key: "ecom_platform_type",
			width: 50,
			toggletype: "extend",
			render: (ecom_platform_type: number) => {
				const typeInfo = OrderModel.getEcomPlatformType(ecom_platform_type);
				return (
					<Tooltip
						placement="topLeft"
						mouseEnterDelay={0.4}
						title={
							<>
								{t("order:ecom_platform_type_prefix")}&nbsp;
								{typeInfo?.label}
							</>
						}>
						<div>
							<OrderItemEcomPlatformTypeImage
								type={ecom_platform_type}
								width={40}
								height={40}
								className="p-1"
							/>
						</div>
					</Tooltip>
				);
			},
		},
		{
			title: t("cashflowreceipt:store"),
			key: "store_id",
			toggletype: "extend",
			render: (store_id: number, record) => {
				return (
					<>
						<TextStore id={store_id} />
						<br />
						{record.tag.length > 0 ? (
							<TextTagList<FilterOrder>
								tag={record.tag}
								filters={filters}
								filterName={"tag"}
								setFilters={setFilters}
							/>
						) : null}
					</>
				);
			},
		},
		{
			title: t("order:billing"),
			key: "",
			render: (_: any, record: OrderModel) => {
				const billingInfor = {
					billing_fullname: record.billing_fullname.length
						? record.billing_fullname
						: record.shipping_fullname,
					billing_phone: record.billing_phone.length
						? record.billing_phone
						: record.shipping_phone,
					billing_province:
						record.billing_province > 0
							? record.billing_province
							: record.shipping_province,
				};
				return (
					<>
						<div>
							<div>
								<span className="">{billingInfor.billing_fullname}</span>
								<span className=""> / {billingInfor.billing_phone}</span>
							</div>
							<div className="text-xs text-gray-500">
								<TextRegion id={billingInfor.billing_province} />
							</div>
						</div>
					</>
				);
			},
		},
		{
			title: t("order:price_discount"),
			key: "price_discount",
			align: "right",
			toggletype: "extend",
			render: (price_discount: number, record) => {
				return (
					<>
						{record.promotion_id > 0 &&
						typeof record.promotion_detail === "object" ? (
							<Tooltip
								title={<>{record.promotion_detail}</>}
								mouseEnterDelay={0.4}>
								<IconDiscountCheck
									size={16}
									className="mr-1 -mt-0.5"
									color="green"
								/>
							</Tooltip>
						) : null}
						<TextMoney money={price_discount} />
					</>
				);
			},
		},
		{
			title: t("order:price_final"),
			key: "price_final",
			align: "right",
			width: 120,
			render: (price_final: number) => {
				return (
					<span className="font-bold">
						<TextMoney money={price_final} />
					</span>
				);
			},
		},
		{
			title: t("common:status"),
			key: "status",
			render: (status: number, record) => {
				let statusInfo = OrderModel.getStatus(status, record.type);
				return (
					<>
						<Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
						{status === Order.STATUS_CANCEL && record.cancel_reason > 0 ? (
							<div
								className="px-2 text-xs text-red-400"
								style={{ fontSize: 9 }}>
								{OrderModel.getCancelReason(record.cancel_reason)?.label}
							</div>
						) : null}
					</>
				);
			},
			width: 120,
		},
		{
			title: t("Trạng thái thanh toán"),
			key: "payment_status",
			render: (payment_status: number, record) => {
				let statusInfo = OrderModel.getStatusPayment(payment_status);
				return (
					<>
						<Tag color={statusInfo?.color}>{statusInfo?.label}</Tag>
					</>
				);
			},
			width: 180,
		},
		{
			title: t("order:date_created"),
			key: "date_created",
			width: 100,
			render: (ts: number) => <TextDateTime format="HH:mm, DD/MM" ts={ts} />,
		},
		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 200,
			render: (id: number, record) => {
				return (
					<>
						{/* <TableDelete id={record.id} repository={OrderSellerRepository} onDeleteCallback={(id)=>console.log(id)}/> */}
						{[Order.STATUS_DRAFT, Order.STATUS_OPEN].includes(
							record.status
						) && (
							<Link
								title="Xử lí đơn hàng"
								target="_blank"
								to={`${process.env.REACT_APP_SHOP_DOMAIN_URL}/pos?code=${record.code}`}>
								Xử lí đơn hàng
							</Link>
						)}

						{![19, 21].includes(record.status) &&
							record.type === Order.TYPE_INVOICE && (
								<TableEdit
									link={"/delivery/add/id/" + record.id}
									label="Tạo vận đơn"
								/>
							)}
						<TableInfo record={record}></TableInfo>
					</>
				);
			},
		},
	];

	useEffect(() => {
		const intervalId = setInterval(fetchData, 60000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<>
			<OrderSellerListHeader />
			<OrderListFilter<FilterOrder>
				filters={{
					...filters,
					type: !isNaN(Number(filters.type)) ? Number(filters.type) : -1,
				}}
				setFilters={setFilters}
				defaultFilters={defaultFilters}
				total={total}
			/>
			<PageDataTable<FilterOrder, OrderModel, OrderCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "order",
				}}
			/>
			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default OrderSellerList;
