import React from "react";

type Props = {
  children: React.ReactNode;
};
const HomeLeftItem = ({ children }: Props) => {
  return (
    <div className="rounded-md overflow-hidden h-full w-full py-3 pl-2 border-[1px] border-gray-200 shadow-lg">
      {children}
    </div>
  );
};

export default HomeLeftItem;
