import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Popover,
  Row,
  Select
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { RangePickerProps } from "antd/es/date-picker";
import BiCenterModel from "common/models/BiCenterModel";
import { DateRange } from "common/types/BiCenter";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "use-onclickoutside";

import { IconChevronDown } from "@tabler/icons-react";

const ReportDateRangePicker = ({
  range,
  range_compare,
  onChange,
  format,
  disableCompare
}: {
  range: DateRange;
  range_compare: DateRange | null;
  onChange: (f: DateRange, t: DateRange | null) => void;
  format: string;
  disableCompare?: boolean;
}) => {
  const { t } = useTranslation();

  //HTMLElement will contain the calendar (2 month panel) of Rangepicker (so that it can always SHOW)
  const containerRef = useRef<any>(null);

  //bypass clickoutside on Select Options
  const containerPresetRef = useRef<any>(null);

  const presetRanges = BiCenterModel.getPresetDateRangePicker();

  //control the open of Popup
  const [popupVisible, setPopupVisible] = useState(false);

  const last30DayIndex = presetRanges.findIndex((r) => r.id === "last30days");
  //editing range (will apply if submit), get from props if passed
  const [editingRange, setEditingRange] = useState<DateRange>(
    typeof range !== "undefined" && range
      ? range
      : last30DayIndex >= 0
      ? presetRanges[last30DayIndex].range
      : [dayjs(), dayjs()]
  );

  //compareing range (will apply if submit), get from props if passed
  const [compareRange, setCompareRange] = useState<DateRange | null>(
    typeof range_compare !== "undefined" && range_compare ? range_compare : null
  );

  //value of SELECT preset
  const [selectedRangeId, setSelectedRangeId] = useState("");
  const [selectedCompare, setSelectedCompare] = useState("previousperiod");

  //enable Compare or not
  const [isCompare, setIsCompare] = useState<boolean>(
    typeof range_compare !== "undefined" && range_compare ? true : false
  );

  const ref = React.useRef(null);
  useOnClickOutside(ref, (e) => {
    //detect click on TOGGLER to prevent mark as clickoutside
    let isClickToggler = false;

    if (e.target) {
      let currentElem = e.target as Element;
      while (currentElem) {
        if (
          typeof currentElem.parentElement !== "undefined" &&
          currentElem.parentElement
        ) {
          currentElem = currentElem.parentElement;
          if (
            typeof currentElem !== "undefined" &&
            typeof currentElem.className === "string" &&
            currentElem.className.indexOf("drp_toggler") >= 0
          ) {
            isClickToggler = true;
            break;
          }
        } else {
          break;
        }
      }
      if (!isClickToggler) {
        setPopupVisible(false);
      }
    }
  });

  const onChangePresetRange = (value: string) => {
    setSelectedRangeId(value);

    if (value !== "custom") {
      const foundRange = presetRanges.find((r) => r.id === value);
      if (typeof foundRange !== "undefined") {
        setEditingRange(foundRange.range);
      }
    }
  };

  const onChangeCompare = (value: string) => {
    setSelectedCompare(value);

    if (value !== "custom") {
      //calculate compare range
    }
  };

  const onChangeIsCompare = (e: CheckboxChangeEvent) => {
    setIsCompare(e.target.checked);
  };

  const onChangeSelectedDateRange: RangePickerProps["onChange"] = (values) => {
    if (values && Array.isArray(values) && values.length === 2) {
      if (values[0] && values[1]) {
        setEditingRange(values as DateRange);
      }
    }
  };

  const pickerDateRender: RangePickerProps["cellRender"] = (current, today) => {
    let compareClassName = "";

    const style = {};
    if (compareRange) {
      if (current.isSame(compareRange[0], "date")) {
        compareClassName = "drp_cell_compare_start";
      } else if (current.isSame(compareRange[1], "date")) {
        compareClassName = "drp_cell_compare_end";
      } else if (
        current.isAfter(compareRange[0]) &&
        current.isBefore(compareRange[1])
      ) {
        compareClassName = "drp_cell_compare_between";
      }
    }

    return (
      <div
        className={"ant-picker-cell-inner " + compareClassName}
        style={style}>
        {current.date()}
      </div>
    );
  };

  const doSubmitChange = () => {
    setPopupVisible(false);
    if (typeof onChange !== "undefined") {
      onChange(editingRange, isCompare ? compareRange : null);
    }
  };

  //update SELECT preset value based on current range
  useEffect(() => {
    const foundRange = presetRanges.find(
      (r) =>
        r.range[0].isSame(editingRange[0], "date") &&
        r.range[1].isSame(editingRange[1], "date")
    );
    if (typeof foundRange !== "undefined") {
      setSelectedRangeId(foundRange.id);
    } else {
      setSelectedRangeId("custom");
    }
  }, [editingRange, presetRanges]);

  //calculate compare range when update compare setting (ON/OFF or select another compare)
  useEffect(() => {
    if (isCompare) {
      //clculate compare range
      let calculatedCompareRange: DateRange | null = null;

      switch (selectedCompare) {
        case "previousperiod":
          const diffDays = editingRange[1].diff(editingRange[0], "days");
          calculatedCompareRange = [
            editingRange[0]
              .clone()
              .subtract(diffDays + 1, "days")
              .startOf("day"),
            editingRange[0].clone().subtract(1, "days").endOf("day")
          ];
          break;
        case "previousyear":
          calculatedCompareRange = [
            editingRange[0].clone().subtract(1, "year").startOf("day"),
            editingRange[1].clone().subtract(1, "year").endOf("day")
          ];
          break;
        default:
      }
      setCompareRange(calculatedCompareRange);
    } else {
      setCompareRange(null);
    }
  }, [isCompare, selectedCompare, editingRange]);

  const popUp = (
    <div ref={ref}>
      <Row>
        <Col style={{ width: 460 }}>
          <div className="drp_container" ref={containerRef}></div>
        </Col>
        <Col style={{ width: 240 }}>
          <Form layout="vertical">
            <Row className="mt-1 mb-1">
              <Col flex="1">
                <span>{t("report:picker.period")}</span>
              </Col>
              <Col flex="1">
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={selectedRangeId}
                  onChange={onChangePresetRange}
                  getPopupContainer={() => containerPresetRef.current}>
                  {presetRanges.map((r) => (
                    <Select.Option value={r.id} key={r.id}>
                      {t("report:picker.preset." + r.id)}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Form.Item>
              <DatePicker.RangePicker
                open
                changeOnBlur
                allowClear={false}
                value={editingRange}
                mode={["date", "date"]}
                format={format}
                popupClassName="drp_compare"
                className={
                  selectedRangeId === "custom"
                    ? "drp_input_custom"
                    : "drp_input"
                }
                getPopupContainer={() => containerRef.current}
                onChange={onChangeSelectedDateRange}
                popupStyle={{ position: "relative" }}
                style={{
                  width: "100%",
                  top: "0 !important",
                  left: "0 !important"
                }}
                cellRender={pickerDateRender}
              />
            </Form.Item>

            {typeof disableCompare === "undefined" || !disableCompare ? (
              <>
                <Row className="mb-1">
                  <Col flex="1">
                    <Checkbox checked={isCompare} onChange={onChangeIsCompare}>
                      {t("report:picker.compare.label")}
                    </Checkbox>
                  </Col>
                  <Col flex="1">
                    <Select
                      size="small"
                      style={{ width: "100%" }}
                      value={selectedCompare}
                      onChange={onChangeCompare}
                      disabled={!isCompare}
                      getPopupContainer={() => containerPresetRef.current}>
                      {BiCenterModel.getComparePreset().map((r) => (
                        <Select.Option value={r} key={r}>
                          {t("report:picker.compare." + r)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>

                {isCompare ? (
                  <DatePicker.RangePicker
                    value={compareRange}
                    changeOnBlur
                    disabled={true}
                    format={format}
                    style={{ width: "100%" }}
                  />
                ) : null}
              </>
            ) : null}

            <div ref={containerPresetRef}></div>

            <Button onClick={doSubmitChange} type="primary" className="mt-2">
              {t("report:picker.apply")}
            </Button>
            <Button
              onClick={() => setPopupVisible(false)}
              type="text"
              className="mt-2">
              {t("report:picker.close")}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );

  return (
    <Popover
      content={popUp}
      placement="bottomRight"
      trigger="click"
      open={popupVisible}>
      <div className="drp_toggler">
        <Button className="" onClick={() => setPopupVisible(!popupVisible)}>
          <div className="float-right" style={{ opacity: 0.5 }}>
            <IconChevronDown size={16} />
          </div>
          <div className="mr-6">
            <h4 className="">
              {typeof range !== "undefined" && range && range[0] ? (
                <>
                  {range[0].format(format)} - {range[1].format(format)}
                </>
              ) : null}
            </h4>

            {typeof disableCompare === "undefined" || !disableCompare ? (
              <>
                {typeof range_compare !== "undefined" &&
                range_compare &&
                range_compare[0] ? (
                  <div>
                    <em>{t("report:picker.compare.label")}</em>
                    <span className="ml-1">
                      {range_compare[0].format(format)} -{" "}
                      {range_compare[1].format(format)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <em>{t("report:picker.compare.label")}</em>
                    <span className="ml-1">...</span>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </Button>
      </div>
    </Popover>
  );
};

export default ReportDateRangePicker;
