// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AcademyLayout_academyLayout__puUeI {
  /* max-width: 1087px;
  width: 100%;
  margin: 38px auto 0; */
}

.AcademyLayout_academyBody__GpZAy {
  width: 100%;
  margin: 0 auto 38px;
  padding-bottom: 38px;
}
.AcademyLayout_academyLogo__0G5u9 {
  color: var(--active-color);
}
.AcademyLayout_sidebar_mobile__kKbdR {
  display: none;
}
@media screen and (max-width: 768px) {
  .AcademyLayout_academyLayout__puUeI {
    padding: 0 10px;
  }
  .AcademyLayout_sidebar_mobile__kKbdR {
    display: unset;
  }
}
/* //////////// */

/* AcademyHomePage */
`, "",{"version":3,"sources":["webpack://./src/features/academy/css/AcademyLayout.module.css"],"names":[],"mappings":"AAAA;EACE;;wBAEsB;AACxB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,cAAc;EAChB;AACF;AACA,iBAAiB;;AAEjB,oBAAoB","sourcesContent":[".academyLayout {\n  /* max-width: 1087px;\n  width: 100%;\n  margin: 38px auto 0; */\n}\n\n.academyBody {\n  width: 100%;\n  margin: 0 auto 38px;\n  padding-bottom: 38px;\n}\n.academyLogo {\n  color: var(--active-color);\n}\n.sidebar_mobile {\n  display: none;\n}\n@media screen and (max-width: 768px) {\n  .academyLayout {\n    padding: 0 10px;\n  }\n  .sidebar_mobile {\n    display: unset;\n  }\n}\n/* //////////// */\n\n/* AcademyHomePage */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"academyLayout": `AcademyLayout_academyLayout__puUeI`,
	"academyBody": `AcademyLayout_academyBody__GpZAy`,
	"academyLogo": `AcademyLayout_academyLogo__0G5u9`,
	"sidebar_mobile": `AcademyLayout_sidebar_mobile__kKbdR`
};
export default ___CSS_LOADER_EXPORT___;
