import BaseRepository from "./BaseRepository";
import type { GetListProps } from "common/types/Filter";

//////////////////////////////////
//Phần thay thế
import {
  FilterLeaveAssign,
  LeaveAssignJsonAddEdit,
} from "common/types/LeaveAssign";
import {
  FilterHistoryLeave,
  FilterLeave,
  LeaveJsonEditStatus,
} from "common/types/Leave";

import LeaveApproveApi from "common/api/LeaveApproveApi";
import LeaveAssignCollection from "common/collections/LeaveAssignCollection";
import LeaveAssignModel from "common/models/LeaveAssignModel";
import LeaveCollection from "common/collections/LeaveCollection";
import LeaveModel from "common/models/LeaveModel";
import LeaveNoteCollection from "common/collections/LeaveNoteCollection";
import LeaveStepCollection from "common/collections/LeaveStepCollection";
import LeaveHistoryCollection from "common/collections/LeaveHistoryCollection";

class LeaveApproveRepository extends BaseRepository<LeaveApproveApi> {
  _api: LeaveApproveApi | null;
  constructor() {
    super();
    this._api = new LeaveApproveApi(true);
  }

  static getDefaultFilter(): FilterLeaveAssign {
    return {
      page: 1,
      limit: 20,
      sortby: "datecreated",
      sorttype: "DESC",
      keyword: "",
      status: "",
      department_id: -1,
    };
  }

  async getItems(
    props: GetListProps<FilterLeaveAssign>
  ): Promise<LeaveAssignCollection> {
    return this._api
      ? await this._api.getList(props.filters)
      : new LeaveAssignCollection();
  }

  async getItem(id: number): Promise<LeaveAssignModel> {
    return this._api
      ? await this._api.getDetail(id)
      : new LeaveAssignModel(LeaveAssignModel.getDefaultData());
  }

  async saveRemote(data: LeaveAssignJsonAddEdit): Promise<LeaveAssignModel> {
    if (this._api) {
      if (data.approver_id > 0) {
        return await this._api.edit(data);
      } else {
        return await this._api.add(data);
      }
    } else {
      return new LeaveAssignModel(LeaveAssignModel.getDefaultData());
    }
  }

  async deleteItem(id: number): Promise<string[]> {
    return this._api ? await this._api.delete(id) : [];
  }

  async editStatus(props: LeaveJsonEditStatus): Promise<LeaveModel> {
    return this._api
      ? await this._api.editStatus(props)
      : new LeaveModel(LeaveModel.getDefaultData());
  }

  async getItemsApproval(props: FilterLeave) {
    return this._api
      ? await this._api.getListApproval(props)
      : new LeaveCollection();
  }
  // hàm get note leave chưa config type Props
  async getNoteItems(props: any) {
    return this._api
      ? this._api.getNoteApprove(props)
      : new LeaveNoteCollection();
  }

  async getItemsApprovalHistory(
    props: FilterHistoryLeave
  ): Promise<LeaveHistoryCollection> {
    return this._api
      ? await this._api.getListApprovalHistory(props)
      : new LeaveHistoryCollection();
  }
}

export default LeaveApproveRepository;
