import { App, Col, DatePicker, Form, Input, Radio, Row } from "antd";
import File from "common/constants/File";
import ProjectTask from "common/constants/ProjectTask";
import FileModel from "common/models/FileModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectTaskModel from "common/models/ProjectTaskModel";
import ProjectTaskRepository from "common/repositories/ProjectTaskRepository";
import FileInput from "components/form/fileinput/FileInput";
import FormSelect from "components/form/FormSelect";
import HtmlEditorInput from "components/htmleditorinput/HtmlEditorInput";
import Error from "components/LayoutError";
import TagStringInput from "components/TagStringInput";
import FormChangedContext from "contexts/FormChangedContext";
import dayjs, { Dayjs } from "dayjs";
import ProjectEmpoyeeFormSelect from "features/project/ProjectEmployeeFormSelect";
import ProjectIterationFormSelect from "features/projectiteration/ProjectIterationFormSelect";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { ProjectTaskJsonAddEdit } from "common/types/ProjectTask";
const ProjectTaskForm = ({
  projectModel,
  projectTaskModel,
  setProcessing,
  onSaveSuccess,
}: {
  projectModel: ProjectModel;
  projectTaskModel: ProjectTaskModel;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (v: ProjectTaskModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();
  const [dateStart, setDateStart] = useState<Dayjs | null>(
    projectTaskModel.date_started !== undefined &&
      projectTaskModel.date_started > 0
      ? dayjs.unix(projectTaskModel.date_started)
      : null
  );

  const account = useLoginAccountStore((state) => state.account);
  const [selectedUserAssignees, setSelectedUserAssignees] = useState<string>(
    projectTaskModel.assignees !== "" ? projectTaskModel.assignees : ""
  );
  const [priority, setPriority] = useState<number>(
    projectTaskModel.priority > 0
      ? projectTaskModel.priority
      : ProjectTask.PRIORITY_LOW
  );
  const [description, setDescription] = useState<string>(
    projectTaskModel.description.length > 0 ? projectTaskModel.description : ""
  );

  const initialValues = useMemo(() => {
    return {
      priority:
        projectTaskModel.priority > 0
          ? projectTaskModel.priority
          : ProjectTask.PRIORITY_LOW,
      name: projectTaskModel.name,
      description: projectTaskModel.description,
      tags: projectTaskModel.tags,
      status: projectTaskModel.status || ProjectTask.STATUS_PENDING,
      type: projectTaskModel.type || ProjectTask.TYPE_FEATURE,
      iteration_id: projectTaskModel.project_iteration_id || null,
      assignees:
        projectTaskModel.assignees !== "" ? projectTaskModel.assignees : "",
      date_started:
        projectTaskModel.date_started !== undefined &&
        projectTaskModel.date_started > 0
          ? dayjs.unix(projectTaskModel.date_started)
          : null,
      date_completed:
        projectTaskModel.date_completed !== undefined &&
        projectTaskModel.date_completed > 0
          ? dayjs.unix(projectTaskModel.date_completed)
          : null,
      attached_file_id: {
        files: FileModel.convertToUploadFiles(projectTaskModel.files),
      },
    };
  }, [projectTaskModel]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProjectTaskJsonAddEdit = {
        id: projectTaskModel.id,
        company_id: account.company.id,
        creator_id: account.id,
        project_id: projectModel.id,

        iteration_id: formData.iteration_id,
        type: formData.type,
        name: formData.name,
        description: description,
        priority: priority,
        status: formData.status,
        start: formData.date_started?.unix() || 0,
        finish: formData.date_completed?.unix() || 0,
        attached_file_id: formData.attached_file_id.file_id_list,
        assignees: selectedUserAssignees,
        tags: formData.tags,
      };

      return submitData;
    },
    [
      projectTaskModel.id,
      account.company.id,
      account.id,
      projectModel,
      priority,
      selectedUserAssignees,
      description,
    ]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);

    let item: ProjectTaskModel = await new ProjectTaskRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(true);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="projecttask:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
      setProcessing(false);
    } else {
      onSaveSuccess(item);
    }
  };

  const disabledDateCompleted = (current: Dayjs) => {
    // Can not select days before date_start
    if (dateStart !== null) {
      return current && current < dateStart;
    }
    return false;
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}
    >
      <Row gutter={0}>
        <Col xs={24} sm={24} md={24} lg={17} xl={17} className="pr-6 pt-6">
          <>
            <Row gutter={16}>
              <Col md={18} xs={24}>
                <Form.Item
                  label={t("projecttask:name")}
                  name="name"
                  required
                  rules={[
                    {
                      required: true,
                      message: t("projecttask:form.error.error_name_required"),
                    },
                  ]}
                >
                  <Input autoFocus />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <FormSelect
                  label={t("projecttask:type")}
                  name="type"
                  options={ProjectTaskModel.getTypeList()}
                />
              </Col>
            </Row>

            <Form.Item label={""} name="description">
              <HtmlEditorInput
                fileObjectType={File.OBJECTTYPE_CMS}
                fileOrigin="project"
                value={description}
                onChange={setDescription}
                placeholder="Nhập nội dung công việc"
              />
            </Form.Item>

            <Row gutter={16}>
              <Col md={14} xs={24}>
                <Form.Item
                  label={t("projecttask:tag")}
                  name="tags"
                  className={projectTaskModel.id > 0 ? "md-0" : ""}
                >
                  <TagStringInput
                    placeholder={t("projecttask:tag_placeholder")}
                  />
                </Form.Item>
              </Col>
              <Col md={10} xs={24}>
                <Form.Item name="priority" label={t("projecttask:priority")}>
                  <Radio.Group
                    buttonStyle="solid"
                    className="w-full"
                    value={priority}
                    onChange={(e) => setPriority(+e.target.value)}
                  >
                    {ProjectTaskModel.getPriorityList().map((item, index) => (
                      <Radio.Button key={index} value={item.value}>
                        {item.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={12} xs={24}>
                {projectTaskModel.id === 0 ? (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "100%",
                    }}
                    className="mb-0"
                  >
                    <FormSelect
                      label={t("common:status")}
                      name="status"
                      options={ProjectTaskModel.getStatusList()}
                    />
                  </Form.Item>
                ) : null}
              </Col>
            </Row>
          </>
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7} className="p-6 bg-gray-100">
          <>
            <ProjectIterationFormSelect
              label={t("projecttask:iteration")}
              name="iteration_id"
              projectId={projectModel.id}
            />

            <Form.Item label={t("projecttask:assign_for")} name="assignees">
              <ProjectEmpoyeeFormSelect
                placeholder={t("common:form.select_employees")}
                multiple={true}
                onChange={(ids: number[]) =>
                  setSelectedUserAssignees(ids.join(","))
                }
                ortherOptions={projectModel.members.map((i) => {
                  return +i;
                })}
                values={
                  projectTaskModel.assignees !== ""
                    ? projectTaskModel.assignees.split(",").map((i) => {
                        return +i;
                      })
                    : []
                }
              />
            </Form.Item>

            <Form.Item className="mb-0">
              <Form.Item
                label={t("projecttask:start_date")}
                name="date_started"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 12px)",
                }}
              >
                <DatePicker
                  changeOnBlur
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  placeholder={t("projecttask:start_date_placeholder")}
                  onChange={(value, dateString) => {
                    setDateStart(value as Dayjs);
                  }}
                />
              </Form.Item>
              <span
                style={{
                  display: "inline-block",
                  width: "24px",
                  lineHeight: "32px",
                  textAlign: "center",
                }}
              ></span>
              <Form.Item
                label={t("projecttask:finish_date")}
                name="date_completed"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 12px)",
                }}
              >
                <DatePicker
                  changeOnBlur
                  style={{ width: "100%" }}
                  format={"DD/MM/YYYY"}
                  placeholder={t("projecttask:finish_date_placeholder")}
                  disabledDate={(value) =>
                    disabledDateCompleted(value as Dayjs)
                  }
                />
              </Form.Item>
            </Form.Item>

            <Form.Item
              className="mb-0"
              name="attached_file_id"
              label={t("file:select_file_image")}
            >
              <FileInput
                objectType={File.OBJECTTYPE_ATTACHMENT}
                origin="project"
                max_item={10}
                allowExtensions={FileModel.getAllSupportUploadExtensions()}
              />
            </Form.Item>
          </>
        </Col>
      </Row>
    </Form>
  );
};

export default ProjectTaskForm;
