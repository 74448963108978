import {
  IconBan,
  IconBarcode,
  IconSearch,
  IconTruckLoading,
  IconX,
} from "@tabler/icons-react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import useMessage from "antd/es/message/useMessage";
import { FormInstance } from "antd/lib/form";
import InventoryReceiptModel from "common/models/InventoryReceiptModel";
import InventoryReceiptRepository from "common/repositories/InventoryReceiptRepository";
import ProductRepository from "common/repositories/ProductRepository";
import {
  InventoryReceiptJsonAddEdit,
  ProductInventoryReceiptJson,
} from "common/types/InventoryResceipt";
import { InventoryReceiptJson } from "common/types/InventoryResceipt";
import { ProductJson, ProductJson2 } from "common/types/Product";
import { TableColumnsType } from "common/types/Table";
import Helper from "common/utils/helper";
import { mergeObjects } from "common/utils/utils";
import MoneyInput from "components/MoneyInput";
import QuantityInput from "components/QuantityInput";
import TextDateTime from "components/TextDateTime";
import TextProductVariantColor from "components/TextProductVariantColor";
import ProductReceiptDetailProductInfo from "features/productreceiptdetail/item/ProductReceiptDetailProductInfo";
import ProductReceiptDetailQuantityInputWrapper from "features/productreceiptdetail/item/ProductReceiptDetailQuantityInputWrapper";
import { debounce } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
export interface ChildComponentHandle {
  resetState: () => void;
}
type Props = {
  data: InventoryReceiptJson["detail"];
  model: InventoryReceiptJson;
  disabled?: boolean;
  onUpdate?: (data: InventoryReceiptJson["detail"]) => void;
  refetch?: () => void;
  store_id: number;
  form?: FormInstance;
};
const InventoryReceiptProduct = forwardRef(
  (
    { data, model, onUpdate, refetch, disabled = false, store_id, form }: Props,
    ref
  ) => {
    const isDraft = true;
    const allowEditInput = !disabled;
    const { t } = useTranslation();
    // const [dataSource, setDataSource] =
    //   useState<ProductInventoryReceiptJson[]>(data);

    const [dataItems, setDataItems] =
      useState<ProductInventoryReceiptJson[]>(data);

    const [dataProducts, setDataProducts] = useState<ProductJson2[]>([]);
    const [loading, setLoading] = useState(false);

    const getProducts = async (ids?: string) => {
      const res = await new ProductRepository().getItems({
        filters: {
          ...ProductRepository.getDefaultFilters(),
          list_product_id: ids ?? dataItems.map((i) => i.item_id).join(","),
        },
      });
      if (!res.hasError()) {
        setDataProducts(res.toJson().items);
      }
    };
    const handleChangeData = (
      id: number,
      data: Partial<ProductInventoryReceiptJson>
    ) => {
      const modelEdit = dataItems.find((i) => i.item_id === id);
      if (!modelEdit) {
        return;
      }
      const dataItemUpdate = mergeObjects(
        modelEdit,
        data
      ) as ProductInventoryReceiptJson;
      const dataUpdated = dataItems.map((item) =>
        item.item_id === id ? dataItemUpdate : item
      ) as ProductInventoryReceiptJson[];
      onUpdate && onUpdate(dataUpdated);
      setDataItems((prev) => dataUpdated);
    };

    const handleRemove = (id: number) => {
      // const dataExitedWithActionAdd = dataItems.find(
      //   (i) =>
      //     i.item_id === id &&
      //     InventoryReceiptModel.checkDetailItemHaveKeyValue(
      //       i,
      //       "typeAction",
      //       "add"
      //     )
      // );
      // if (dataExitedWithActionAdd) {
      //   const dataUpdated = dataItems.filter(
      //     (i) => i.item_id !== dataExitedWithActionAdd.item_id
      //   );
      //   setDataItems(dataUpdated);
      //   onUpdate && onUpdate(dataUpdated);

      //   return;
      // }
      // const dataUpdated: ProductInventoryReceiptJson[] = dataItems.map((item) =>
      //   item.item_id === id ? { ...item, typeAction: "delete" } : item
      // );
      const dataUpdated: ProductInventoryReceiptJson[] = dataItems.filter(
        (item) => item.item_id !== id
      );

      setDataItems(dataUpdated);
      onUpdate && onUpdate(dataUpdated);
    };

    const handleAdd = async (product: ProductJson2) => {
      const dataPre = [...dataItems];

      const itemExist = dataPre.findIndex(
        (item) => item.item_id === product.id
      );

      if (itemExist >= 0) {
        dataPre[itemExist].quantity += 1;
        setDataItems(dataPre);
        onUpdate && onUpdate(dataPre);
        getProducts(dataPre.map((i) => i.item_id).join(","));
        return;
      }

      const dataAdd = {
        item_id: product.id,
        item_type: 1,
        quantity: 1,
        unit: 1,
        price: -1,
        unit_price: product.price,
        typeAction: "add",
      };
      const dataUpdated = mergeObjects(
        InventoryReceiptModel.getDefaultDetailData(),
        dataAdd,
        true
      ) as ProductInventoryReceiptJson;
      const dataItemUdpated = [...dataItems].concat(
        dataUpdated
      ) as ProductInventoryReceiptJson[];

      setDataItems(dataItemUdpated);
      onUpdate && onUpdate(dataItemUdpated);
      getProducts(dataItemUdpated.map((i) => i.item_id).join(","));
    };

    //////////////////////////////////////

    const columns: TableColumnsType<ProductInventoryReceiptJson> = [
      {
        title: t("common:table.index"),
        dataIndex: "id",
        align: "center",
        render: (_, __, index: number) => {
          return <>{index + 1}</>;
        },
        width: 50,
      },
      {
        title: t("productvariant:sku"),
        dataIndex: "sku",
        render: (sku: string, record) => {
          return <>{sku} </>;
        },
      },
      {
        title: t("productreceipt:form.product_info"),
        dataIndex: "product_variant_id",
        render: (_: any, record: ProductInventoryReceiptJson) => {
          const product = dataProducts.find((i) => i.id === record.item_id);
          if (product) {
            return (
              <Typography.Text ellipsis={{ tooltip: {} }}>
                {product.full_name}
              </Typography.Text>
            );
          }

          return (
            <ProductReceiptDetailProductInfo variant_id={record.item_id} />
          );
        },
      },

      {
        title: t("Gía nhập/xuất"),
        dataIndex: "cost_price",
        render: (_: any, record: ProductInventoryReceiptJson) => {
          return (
            <MoneyInput
              disabled={!allowEditInput}
              onChange={(value) => {
                handleChangeData(record.item_id, { cost_price: value || 0 });
              }}
              value={record.cost_price}
            ></MoneyInput>
          );
        },
      },

      {
        title: isDraft
          ? t("productreceipt:form.item_quantity_initial")
          : t("productreceipt:form.item_quantity"),
        dataIndex: "quantity",
        width: 140,
        align: "center",
        render: (quantity: number, record: ProductInventoryReceiptJson) => {
          return (
            <>
              {!allowEditInput ? (
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  value={quantity}
                  className="text-center"
                  disabled
                />
              ) : (
                <QuantityInput
                  disabled={!allowEditInput}
                  value={quantity}
                  max={record.max_quantity}
                  onChange={(value) => {
                    if (value) {
                        handleChangeData(record.item_id, { quantity: value });
                    }
                  }}
                ></QuantityInput>
              )}
            </>
          );
        },
      },
      ...(isDraft
        ? [
            {
              title: " ",
              dataIndex: "operation",
              width: 50,
              render: (_: any, record: ProductInventoryReceiptJson) => {
                return (
                  <>
                    <Popconfirm
                      disabled={!allowEditInput}
                      title={t("common:table.confirm_delete_title")}
                      placement="topRight"
                      onConfirm={() => {
                        handleRemove(record.item_id);
                        // doRemoveRowWithSku(record.sku);
                      }}
                      onCancel={() => {}}
                      okText={t("common:table.confirm_ok")}
                      cancelText={t("common:table.confirm_cancel")}
                    >
                      <Button
                        type="link"
                        size="small"
                        className="text-red-200 hover:text-red-500"
                        onClick={
                          () => {}
                          // (confirmingDeleteSku.current = record.sku)
                        }
                      >
                        <IconX />
                      </Button>
                    </Popconfirm>
                  </>
                );
              },
            },
          ]
        : []),
    ];
    /////////////////////////////////////

    useEffect(() => {
      if (data.length > 0) {
        getProducts();
      }
    }, [JSON.stringify(data)]);

    useEffect(() => {
      if (dataProducts.length) {
        setDataItems(
          dataItems.map((i) => {
            const product = dataProducts.find((p) => i.item_id === p.id);
            if (product) {
              return { ...i, sku: product.sku };
            }

            return i;
          })
        );
      }
    }, [dataProducts]);

    useEffect(() => {
      if (form) {
        form.setFieldValue("detail", dataItems);
      }
    }, [dataItems, form, model]);

    useImperativeHandle(ref, () => ({
      resetState: () => {
        // Đặt lại tất cả state
        setDataItems([]);
      },
    }));

    /////////////////////////////////////

    return (
      <Form.Item
        // rules={[
        // 	{
        // 		validator(rule, value, callback) {
        // 			return new Promise((resolve, reject) => {
        // 				if (Array.isArray(value) && value.length <= 0) {
        // 					reject("Vui lòng chọn sản phẩm");
        // 				} else {
        // 					resolve(1);
        // 				}
        // 			});
        // 		},
        // 	},
        // ]}
        name={"detail"}
        label="Thông tin sản phẩm"
      >
        <SearchProduct
          onSelect={(product) => {
            handleAdd(product);
          }}
          store_id={store_id}
        ></SearchProduct>
        <Table
          size="small"
          className="w-full"
          dataSource={dataItems.filter(
            (i) =>
              !InventoryReceiptModel.checkDetailItemHaveKeyValue(
                i,
                "typeAction",
                "delete"
              )
          )}
          columns={columns}
          // rowKey={(record) => record.product_variant_id + "-" + record.sku}
          bordered
          loading={loading}
          pagination={{ hideOnSinglePage: true }}
          footer={() => null}
          scroll={{ x: 800 }}
        />
      </Form.Item>
    );
  }
);

export default InventoryReceiptProduct;

type SearchProductProps = {
  onSelect: (product: ProductJson2) => void;
  store_id: number;
};
const SearchProduct = ({ onSelect, store_id }: SearchProductProps) => {
  const { Search } = Input;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [disabled] = useState(false);
  const [items, setItems] = useState<ProductJson2[]>([]);
  const [keyword, setKeyword] = useState("");

  const doSelect = (product: ProductJson2) => {
    onSelect(product);
  };

  const doSearch = async (query: string, isPressEnter: boolean) => {
    setLoading(true);
    setKeyword(query);
    // if (typeof onSelect !== "undefined" && productVariantId > 0) {
    // 	onSelect(productVariantId);
    // }
    const resProducts = await new ProductRepository().getItems({
      filters: {
        ...ProductRepository.getDefaultFilters(),
        keyword: query,
        store_id,
      },
    });
    if (!resProducts.hasError()) {
      setItems(resProducts.toJson().items);
    }

    setLoading(false);
  };

  ///////////////////////////
  return (
    <div className="mb-2">
      <Select
        // mode="multiple"
        showArrow={true}
        suffixIcon={<IconSearch size={18} color="#dddddd" />}
        bordered={true}
        loading={loading}
        disabled={disabled}
        autoClearSearchValue={false}
        // autoClearSearchValue
        placeholder={t("productvariant:search_placeholder")}
        // onInputKeyDown={handleInputKeydown}
        size="middle"
        style={{ width: "100%" }}
        showSearch
        value={null}
        onSearch={debounce((value: string) => doSearch(value, false), 1000)}
        filterOption={false}
        dropdownRender={(menu) => <>{menu}</>}
        onSelect={(selectedId) => {
          const pro = items.find((i) => i.id === selectedId);
          pro && doSelect(pro);
        }}
        // onFocus={handleFocus}
        // onPopupScroll={handlePopupScroll}
        notFoundContent={
          <>
            {loading ? (
              <>
                <IconTruckLoading></IconTruckLoading>
                "Đang tìm..."
              </>
            ) : keyword.length > 0 ? (
              <>
                <IconBan size={24} className="-mt-0.5 mr-1" />
                {t("productvariant:search_notfound_empty")}
              </>
            ) : (
              <>
                <IconBarcode size={24} className="-mt-0.5 mr-1" />
                {t("productvariant:search_notfound_empty_keyword")}
              </>
            )}
          </>
        }
      >
        {items.map((item, index) => (
          <Select.Option key={item.id} value={item.id}>
            <span className="text-right inline-block text-gray-400 text-xs w-5">
              {index + 1}.
            </span>{" "}
            {item.full_name} | <span className="font-bold">{item.sku}</span> -{" "}
            {/* <span className="text-gray-400">
									{t("productvariant:color_short")}:
								</span>{" "}
								<span className="text-gray-400">
									{t("productvariant:size_short")}:{" "}
								</span> */}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
