import { Button, Col, Form, Input, Modal, Row } from "antd";
import PosCartModel from "common/models/PosCartModel";
import { PosCartDelivery } from "common/types/PosCart";
import RegionPicker from "components/RegionPicker";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import usePosCartStore from "zustands/usePosCartStore";

const PosRightDeliveryModal = ({
  show,
  setShow,
  onSelect,
  setEditCompleted,
  setCheckedCopyFromCustomer,
}: {
  show: boolean;
  setShow: (v: boolean) => void;
  onSelect: (v: PosCartDelivery) => void;
  setEditCompleted: (v: boolean) => void;
  setCheckedCopyFromCustomer: (v: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const activeDelivery = usePosCartStore(
    (state) =>
      state.getActiveCart()?.delivery || PosCartModel.getDefaultCartDelivery()
  );

  const handleClose = () => {
    setShow(false);
    setEditCompleted(true);
    if (activeDelivery.is_customize) {
      onFormSubmit(form.getFieldsValue(true));
    } else {
      setCheckedCopyFromCustomer(true);
    }
  };

  const onFormSubmit = useCallback(
    (formData: any) => {
      onSelect({
        ...PosCartModel.getDefaultCartDelivery(),
        is_customize: true,
        full_name: formData.full_name,
        phone: formData.phone,
        address: formData.address,
        region_id: formData.regions?.region_id || 0,
        sub_region_id: formData.regions?.sub_region_id || 0,
        sub_sub_region_id: formData.regions?.sub_sub_region_id || 0,
      });
      setShow(false);
      setEditCompleted(true);
    },
    [onSelect, setShow, setEditCompleted]
  );

  return (
    <Modal
      open={show}
      onCancel={handleClose}
      width={720}
      title="Thông tin nhận hàng"
      footer={[
        <Button key="close" onClick={handleClose}>
          Đóng
        </Button>,
        <Button key="primary" type="primary" onClick={() => form.submit()}>
          Áp dụng
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFormSubmit}>
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              label={t("common:full_name")}
              name="full_name"
              rules={[
                {
                  required: true,
                  message: t("customer:form.error.error_fullname_required"),
                },
              ]}
            >
              <Input autoFocus={true} />
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              label="Điện thoại"
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("customer:form.error.error_fullname_required"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Địa chỉ" name="address">
          <Input />
        </Form.Item>

        <Form.Item name="regions" noStyle>
          <RegionPicker />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PosRightDeliveryModal;
