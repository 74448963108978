import React from "react";
import useCompanySettingStore from "zustands/useCompanySettingStore";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import DeliveryDashboardSectionRightGoogleMapWrapper from "./googlemap/DeliveryDashboardSectionRightGoogleMapWrapper";
import DeliveryDashboardSectionRightGoongMapWrapper from "./goongmap/DeliveryDashboardSectionRightGoongMapWrapper";

const DeliveryDashboardSectionRight = () => {
  const height = useDeliveryDashboardStore((state) => state.height);
  const [mapViewSource, mapViewApiKey] = useCompanySettingStore((state) => [
    state.getSetting("map_view_source") || "",
    state.getSetting("map_view_api_key") || "",
  ]);
  const [activeRoute] = useDeliveryDashboardStore((state) => [
    state.activeRoute,
  ]);

  return activeRoute && activeRoute.id > 0 ? (
    <div className="bg-gray-50" style={{ height }}>
      {mapViewSource === "google" ? (
        <DeliveryDashboardSectionRightGoogleMapWrapper
          apiKey={mapViewApiKey.toString()}
        />
      ) : mapViewSource === "goong" ? (
        <DeliveryDashboardSectionRightGoongMapWrapper
          apiKey={mapViewApiKey.toString()}
        />
      ) : null}
    </div>
  ) : null;
};

export default DeliveryDashboardSectionRight;
