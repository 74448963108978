import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import { PositionJson } from "common/types/Position";
class PositionModel extends BaseModel implements BaseModelJson<PositionJson> {
  position_id: number;
  position_name: string;
  position_code: string;

  constructor(json: PositionJson) {
    super();

    this.position_id = json.position_id || 0;
    this.position_name = json.position_name || "";
    this.position_code = json.position_code || "";
  }

  static getDefaultData(): PositionJson {
    return {
      position_id: 0,
      position_name: "",
      position_code: "",
    };
  }

  toJson(): PositionJson {
    return {
      position_id: this.position_id,
      position_name: this.position_name,
      position_code: this.position_code,
    };
  }
}

export default PositionModel;
