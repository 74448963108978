import { Col, Row } from "antd";
import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import { useTranslation } from "react-i18next";
import { IconAward } from "@tabler/icons-react";

const DeliveryMineListHeader = () => {
  const { t } = useTranslation();
  return (
    <PageHeader heading={t("delivery:heading_list")} siteMenuOpenKey="delivery">
      <Row gutter={16} className="text-right"></Row>
    </PageHeader>
  );
};

export default DeliveryMineListHeader;
