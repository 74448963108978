import React from 'react';

import PosSearch from './search/PosSearch';

const PosCenter = () => {
  return (
    <>
      <PosSearch />
    </>
  );
};

export default PosCenter;
