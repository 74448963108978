import CompanySetting from 'common/constants/CompanySetting';
import { SelectOption } from 'common/types/SelectOption';
import db from 'db';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { CompanySettingEntry } from "common/types/CompanySetting";

class CompanySettingModel extends BaseModel {
  data: CompanySettingEntry;

  constructor(json: CompanySettingEntry) {
    super();

    this.data = json || {};
  }

  static getDefaultData(): CompanySettingEntry {
    return {};
  }

  toJson(): CompanySettingEntry {
    return this.data;
  }

  static async getAllSettings(): Promise<CompanySettingEntry | null> {
    let settings: CompanySettingEntry | null = null;

    if (
      db.hasOwnProperty("companysetting") &&
      db.table("companysetting") !== null
    ) {
      let items = await db.table("companysetting").toArray();
      if (
        typeof items !== "undefined" &&
        items.hasOwnProperty("length") &&
        items.length === 1
      ) {
        settings = items[0];
      }
    }

    return settings;
  }

  static getCurrencyFormatList(prefix: string, suffix: string): SelectOption[] {
    return [
      {
        value: CompanySetting.CURRENCY_FORMAT_NO_DECIMAL,
        label: i18n.t("setting:CURRENCY_FORMAT_NO_DECIMAL", { prefix, suffix }),
      },
      {
        value: CompanySetting.CURRENCY_FORMAT_TWO_DECIMAL,
        label: i18n.t("setting:CURRENCY_FORMAT_TWO_DECIMAL", {
          prefix,
          suffix,
        }),
      },
    ];
  }
}

export default CompanySettingModel;
