import { App, Col, Form, Input, Row } from "antd";
import Error from "components/LayoutError";
import FormChangedContext from "contexts/FormChangedContext";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormDefaultStore from "zustands/useFormDefaultStore";

import TagModel from "common/models/TagModel";
import TagRepository from "common/repositories/TagRepository";
import { TagAddEditJson } from "common/types/Tag";
import FormSelect from "components/form/FormSelect";
import SettingModel from "common/models/SettingModel";
import { SettingAddEditJson, SettingGroupType } from "common/types/Setting";
import SettingRepository from "common/repositories/SettingRepository";
import TextArea from "antd/lib/input/TextArea";
import FormSelectNormal from "components/form/FormSelectNormal";
const LanguageForm = ({
	model,
	type,
	lang,
	setOpen,
	setProcessing,
	onSaveSuccess,
}: {
	model: SettingModel;
	type: SettingGroupType;
	lang: string;
	setOpen: (v: boolean) => any;
	setProcessing: (p: boolean) => any;
	onSaveSuccess: (o: SettingModel) => void;
}) => {
	console.log(model);
	const { t } = useTranslation();
	const { message } = App.useApp();
	const formChangedProvider = useContext(FormChangedContext);

	const [getValue, setValue] = useFormDefaultStore((state) => [
		state.getValue,
		state.setValue,
	]);

	const [form] = Form.useForm();
	const initialValues = useMemo(() => {
		return {
			id: model.id,
			lang: model.value.lang,
		};
	}, [model, getValue]);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: SettingAddEditJson = {
				id: model.id,
				group: model.group,
				key: model.key,
				value: JSON.stringify({
					...model.value,
					lang: formData.lang,
					label: formData.lang === "vi" ? "Tiếng Việt" : "Tiếng Anh",
				}),
				lang: model.lang,
			};

			return submitData;
		},
		[model.id, setValue]
	);

	//submit data to server
	const onSubmit = async (formData: any) => {
		console.log("🥺 ~ onSubmit ~ formData:", doPrepareData(formData));
		setProcessing(true);
		message.loading({
			content: t("common:form.processing"),
			key: "message",
			duration: 0,
		});

		let item: SettingModel = await new SettingRepository().saveRemote(
			doPrepareData(formData)
		);

		setProcessing(false);
		if (item.hasError()) {
			message.error({
				content: (
					<Error
						onClickClose={() => {
							message.destroy("message");
						}}
						heading={t("common:form.error.heading")}
						translate_prefix="setting:form.error"
						items={item.error.errors}
					/>
				),
				className: "message_error",
				key: "message",
				duration: 4,
			});
		} else {
			message.success({
				content: t("common:form.success.save"),
				className: "message_success",
				key: "message",
				duration: 2,
			});
			onSaveSuccess(item);
			setOpen(false);
		}
	};

	return (
		<Form
			layout="vertical"
			initialValues={initialValues}
			form={form}
			onFinish={onSubmit}
			id={formChangedProvider.id}
			onFieldsChange={() => formChangedProvider.setChanged(true)}>
			<Row gutter={16}>
				<Col md={24} xs={24}>
					<FormSelectNormal
						options={SettingModel.getLangSelect()}
						label={t("Chọn ngôn ngữ")}
						name="lang"
						className="mb-0"
						rules={[
							{
								required: true,
								message: t("Vui lòng nhập"),
							},
						]}></FormSelectNormal>
				</Col>
			</Row>
		</Form>
	);
};

export default LanguageForm;
