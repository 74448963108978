import { IconPlus, IconTag } from "@tabler/icons-react";
import {
  Button,
  Modal,
  Skeleton,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import TagConstant from "common/constants/Tag";
import ProductModel from "common/models/ProductModel";
import TagDetailModel from "common/models/TagDetailModel";
import TagModel from "common/models/TagModel";
import ProductRepository from "common/repositories/ProductRepository";
import TagRepository from "common/repositories/TagRepository";
import { SelectOption } from "common/types/SelectOption";
import ErrorAlert from "components/ErrorAlert";
import FormModal from "components/form/FormModal";
import ProductForm from "features/product/form/ProductForm";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ConfirmDeleteProductStruct = ({
  id,
  open,
  setOpen,
  onSaveSuccess,
  handleRemoveTabItem,
}: {
  id: number;
  open: boolean;
  setOpen: (v: boolean) => void;
  handleRemoveTabItem: any;
  onSaveSuccess: (m: ProductModel) => void;
}) => {
  const { t } = useTranslation();

  // const [model, setModel] = useState<ProductModel>(
  //   new ProductModel(ProductModel.getDefaultData())
  // );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const [selectReload, setSelectReload] = useState<boolean>(false);
  const [existValues, setExistValue] = useState<number[]>([]);

  //submit tag detail

  //delete collection detial
  const onDeleteSelectedItem = async (v: SelectOption) => {
    message.loading({ content: "Vui lòng đợi", duration: 1 });
    const findTagDetailId = await new TagRepository().getItemsDetail({
      filters: {
        ...TagRepository.getDefaultTagDetailFilters(),
        resource_id: id,
        resource_type: TagConstant.RESOURCE_TYPE.PRODUCT,
        tag_id: v.value,
      },
    });
    if (!findTagDetailId.hasError() && findTagDetailId.items.length > 0) {
      const reponse = await new TagRepository().deleteTagDetailItem([
        findTagDetailId.items[0].id,
      ]);
      if (reponse.length === 0) {
        message.success({ content: "Thao tác thành công", duration: 1 });
        setExistValue(existValues.filter((item) => item !== v.value));
        setSelectReload(!selectReload);
      } else {
        message.error("Thao tác thất bại");
      }
    } else {
      message.error("Thao tác thất bại");
    }
  };

  //update existvalue
  const changeTagExistValue = (newTag: TagDetailModel[]) => {
    setExistValue(newTag.map((item) => item.tag_id));
  };

  return (
    <Modal
      title={"Xác nhận"}
      width={400}
      bodyStyle={{
        paddingTop: 8,
        paddingBottom: 8,
        overflowY: "auto",
        maxHeight: "calc(100vh - 250px)",
      }}
      centered
      open={open}
      confirmLoading={processing}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={handleRemoveTabItem}
      maskClosable={false}
      cancelText={"Đóng"}
      destroyOnClose
    >
      <Typography.Text>Xác nhận xóa tag này khỏi sản phẩm</Typography.Text>
    </Modal>
  );
};

export default ConfirmDeleteProductStruct;
