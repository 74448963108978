import WorkScheduleModel from "common/models/WorkScheduleModel";
import FormModal from "components/form/FormModal";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useWorkScheduleManageStore from "zustands/useWorkScheduleManage";

import WorkScheduleManageForm from "./WorkScheduleManageForm";

const WorkScheduleManageFormModal = ({
  open,
  setOpen,
  onSaveSuccess,
}: {
  open: boolean;
  setOpen: (v: boolean) => any;
  onSaveSuccess: (m: WorkScheduleModel[]) => void;
}) => {
  const { t } = useTranslation();

  const [processing, setProcessing] = useState(false);
  const [office, date_unix, employee] = useWorkScheduleManageStore((state) => [
    state.office,
    state.date_unix,
    state.employee,
  ]);

  return (
    <FormModal
      title={
        <span className="capitalize">
          {t(
            employee.id > 0
              ? "workschedule:heading_add_with_employee"
              : "workschedule:heading_add",
            {
              office: office.label,
              date: dayjs.unix(date_unix).format("dddd, D/M/YYYY"),
              full_name: employee.full_name,
            }
          )}
        </span>
      }
      width={920}
      confirmLoading={processing}
      open={open}
      setOpen={setOpen}
      formId="workschedule-form"
    >
      <WorkScheduleManageForm
        setOpen={setOpen}
        setProcessing={setProcessing}
        onSaveSuccess={onSaveSuccess}
      />
    </FormModal>
  );
};

export default WorkScheduleManageFormModal;
