import { Form, Input, Modal } from "antd";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectIterationRepository from "common/repositories/ProjectIterationRepository";
import { FilterProjectIteration } from "common/types/ProjectIteration";
import { FilterProjectTask } from "common/types/ProjectTask";
import ProjectIterationFormAddEdit from "features/projectiteration/form/ProjectIterationFormAddEdit";
import ProjectIterationFormInformation from "features/projectiteration/form/ProjectIterationFormInformation";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronRight, IconSearch } from "@tabler/icons-react";

const ProjectIterationFormModal = ({
  open,
  setOpen,
  projectModel,
  totalTask,
  canAddIteration,
  filters,
  setFilters,
  defaultFilters,
  onSaveSuccess,
}: {
  open: boolean;
  setOpen: (v: boolean) => any;
  projectModel: ProjectModel;
  totalTask: number;
  canAddIteration: boolean;
  filters: FilterProjectTask;
  setFilters: (f: FilterProjectTask) => void;
  defaultFilters: FilterProjectTask;
  onSaveSuccess: (v: ProjectIterationModel) => void;
}) => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultProjectIterationFilters: FilterProjectIteration = useMemo(
    () => ProjectIterationRepository.getDefaultFilters(),
    []
  );
  const [inputKeyword, setInputKeyword] = useState(
    defaultProjectIterationFilters.keyword
  );

  //////////////////////////////////////////
  //Do Add Edit
  const [isDoAddEdit, setIsDoAddEdit] = useState<boolean>(false);
  const [projectIterationId, setProjectIterationId] = useState<number>(0);
  const [model, setModel] = useState<ProjectIterationModel>(
    new ProjectIterationModel(ProjectIterationModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);

  const fetchDetail = async (id: number) => {
    let myObj = await new ProjectIterationRepository().getItem(id);
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setModel(myObj);
    }
  };

  useEffect(() => {
    if (projectIterationId > 0 && errors.length === 0) {
      fetchDetail(projectIterationId);
    }
  }, [projectIterationId, errors]);

  return (
    <>
      <Modal
        title={
          <>
            {isDoAddEdit ? (
              <>
                {projectModel.name}
                {<IconChevronRight size={14} />}
                {projectIterationId > 0
                  ? model.name
                  : t("projectiteration:create_iteration")}
              </>
            ) : (
              <Form layout="inline">
                <Form.Item className="w-full">
                  <Input
                    className="h-9"
                    value={inputKeyword}
                    allowClear={true}
                    placeholder={t("projectiteration:keyword_placeholder")}
                    prefix={<IconSearch size="16" color="lightgrey" />}
                    onChange={(e) => {
                      e.preventDefault();
                      setInputKeyword(e.target.value);
                    }}
                  />
                </Form.Item>
              </Form>
            )}
          </>
        }
        style={{ top: 20 }}
        width={600}
        open={open}
        closable={false}
        maskClosable={true}
        onCancel={() => {
          setOpen(false);
          setProjectIterationId(0);
          setModel(
            new ProjectIterationModel(ProjectIterationModel.getDefaultData())
          );
        }}
        okButtonProps={{
          form: "project-iteration-form",
          htmlType: "submit",
          style: { display: !isDoAddEdit ? "none" : "" },
        }}
        okText={t("common:form.save")}
        cancelText={t("common:close")}
        confirmLoading={processing}
      >
        {isDoAddEdit ? (
          <ProjectIterationFormAddEdit
            model={model}
            projectModel={projectModel}
            setProcessing={setProcessing}
            setProjectIterationId={(id) => {
              setIsDoAddEdit(false);
              setProjectIterationId(id);
              setModel(
                new ProjectIterationModel(
                  ProjectIterationModel.getDefaultData()
                )
              );
            }}
            onSaveSuccess={onSaveSuccess}
          />
        ) : (
          <ProjectIterationFormInformation
            setOpen={setOpen}
            projectModel={projectModel}
            totalTask={totalTask}
            canAddIteration={canAddIteration}
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            setProjectIterationId={(id) => {
              setIsDoAddEdit(true);
              setProjectIterationId(id);

              if (id === 0) {
                setModel(
                  new ProjectIterationModel(
                    ProjectIterationModel.getDefaultData()
                  )
                );
              }
            }}
            inputKeyword={inputKeyword}
          />
        )}
      </Modal>
    </>
  );
};

export default ProjectIterationFormModal;
