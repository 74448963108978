const TaxInvoice = {
  TYPE_EINVOICE: 1,
  TYPE_EINVOICE_POS: 3,

  METHOD_CASH: 1,
  METHOD_BANK: 3,

  BUYER_RECEIVE_METHOD_IGNORE: 1,
  BUYER_RECEIVE_METHOD_EMAIL: 3,
  BUYER_RECEIVE_METHOD_DIRECT: 5,
  BUYER_RECEIVE_METHOD_LATER: 7,
  BUYER_RECEIVE_METHOD_OTHER: 9,

  STATUS_DRAFT: 1,
  STATUS_WAIT_FOR_APPROVAL: 3,
  STATUS_APPROVED: 5,
  STATUS_ISSUED: 7,
  STATUS_COMPLETED: 9,
  STATUS_CANCELLED: 11,
  STATUS_CLOSED: 13,

  ISSUE_PROVIDER_SELF: 1,
  ISSUE_PROVIDER_WINTECH: 3,

  ISSUE_STATUS_PENDING: 1,
  ISSUE_STATUS_WIP: 3,
  ISSUE_STATUS_FAILED: 5,
  ISSUE_STATUS_CREATED: 7,
  ISSUE_STATUS_CANCELLED: 9,
  ISSUE_STATUS_SIGNED: 11,
};

export default TaxInvoice;
