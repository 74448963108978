import { Col, Form, Input, InputNumber, message, Row } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";

import LeaveTypeModel from "common/models/LeaveTypeModel";
import LeaveTypeRepository from "common/repositories/LeaveTypeRepository";
import { LeaveTypeAddEditJson, LeaveTypeJson } from "common/types/LeaveType";

import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";

type Props = {
  model: LeaveTypeModel;
  setVisible: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: LeaveTypeModel) => void;
};

const LeaveTypeForm = ({
  model,
  setVisible,
  setProcessing,
  onSaveSuccess,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const salaryTypeItems = [
    { label: t("leavetype:salary_type.NCL"), value: "NCL" },
    { label: t("leavetype:salary_type.NKL"), value: "NKL" },
    { label: t("leavetype:salary_type.NBH"), value: "NBH" },
  ];

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      salary_type: model.salary_type.length > 0 ? model.salary_type : null,
      note: model.note,
      summax: model.summax,
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: LeaveTypeAddEditJson = {
        // ...LeaveTypeModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        summax: formData.summax,
        salary_type: formData.salary_type || 0,
        note: formData.note,
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0,
    });

    let item: LeaveTypeModel = await new LeaveTypeRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="leavetype:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4,
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2,
      });
      onSaveSuccess(item);
      setVisible(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id="leavetypes-form"
    >
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("leavetype:form.name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("leavetype:form.error.required_name"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          {/* <LeaveTypesFormSelect
            label={t("Chế độ lương")}
            placeholder={t("Chọn chế độ lương")}
            name="salary_type"
            parentId={0}
            ignoreValues={model.id > 0 ? [model.id] : []}
          /> */}
          <FormSelect
            showSearch
            required
            label={t("leavetype:form.salary_type")}
            placeholder={"Chọn loại phép"}
            name="salary_type"
            options={salaryTypeItems}
            rules={[
              {
                required: true,
                message: t("leavetype:form.error.required_salary_type"),
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            required
            name="summax"
            label={t("leavetype:form.summax")}
            className="mb-4"
            validateFirst
            rules={[
              {
                required: true,
                message: t("leave:form.error.required_dayoff"),
              },
              {
                message: t("leave:form.error.error_leave_dayoff_format"),
                validator: (_, value) => {
                  // console.log(typeof value)
                  // if (typeof value === "number" && value > 0) {
                  if (Number(value) > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Some message here");
                  }
                },
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            required
            name="note"
            label={t("leavetype:form.note")}
            className="mb-0"
            rules={[
              {
                required: true,
                message: t("leavetype:form.error.required_note"),
              },
            ]}
          >
            <TextArea rows={4} placeholder={t("leave:reason")} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            hidden={model.id === 0}
            name="display_order"
            label={t("common:display_order_long")}
            tooltip={t("common:display_order_help")}
            className="mb-0"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row> */}
    </Form>
  );
};

export default LeaveTypeForm;
