import { App, Button, Col, DatePicker, Form, Input, Row } from "antd";
import ProjectIteration from "common/constants/ProjectIteration";
import ProjectIterationModel from "common/models/ProjectIterationModel";
import ProjectModel from "common/models/ProjectModel";
import ProjectIterationRepository from "common/repositories/ProjectIterationRepository";
import { ProjectIterationJsonAddEdit } from "common/types/ProjectIteration";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconArrowBack } from "@tabler/icons-react";

const ProjectIterationFormAddEdit = ({
  model,
  projectModel,
  setProcessing,
  setProjectIterationId,
  onSaveSuccess
}: {
  model: ProjectIterationModel;
  projectModel: ProjectModel;
  setProcessing: (p: boolean) => any;
  setProjectIterationId: (id: number) => void;
  onSaveSuccess: (v: ProjectIterationModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const account = useLoginAccountStore((state) => state.account);

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      description: model.description,
      status: model.status > 0 ? model.status : ProjectIteration.STATUS_PENDING,
      date_started:
        model.date_started !== undefined && model.date_started > 0
          ? dayjs(
              dayjs.unix(model.date_started).format("DD/MM/YYYY"),
              "DD/MM/YYYY"
            )
          : dayjs(dayjs().format("DD/MM/YYYY"), "DD/MM/YYYY"),
      date_completed:
        model.date_completed !== undefined && model.date_completed > 0
          ? dayjs(
              dayjs.unix(model.date_completed).format("DD/MM/YYYY"),
              "DD/MM/YYYY"
            )
          : dayjs(dayjs().format("DD/MM/YYYY"), "DD/MM/YYYY")
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: ProjectIterationJsonAddEdit = {
        ...ProjectIterationModel.getDefaultData(),
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,
        name: formData.name,
        project_id: projectModel.id,
        description: formData.description,
        status: formData.status,
        start: dayjs(formData.date_started, "DD/MM/YYYY").unix(),
        finish: dayjs(formData.date_completed, "DD/MM/YYYY").unix()
      };

      return submitData;
    },
    [model.id, account.company.id, account.id, projectModel.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: ProjectIterationModel =
      await new ProjectIterationRepository().saveRemote(
        doPrepareData(formData)
      );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="projectiteration:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      setProjectIterationId(0);
      onSaveSuccess(item);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <>
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onSubmit}
        id="project-iteration-form">
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Form.Item
              label={t("projectiteration:name")}
              name="name"
              required
              rules={[
                {
                  required: true,
                  message: t("projectiteration:form.error.error_name_required")
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item label={t("projectiteration:start")} name="date_started">
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                changeOnBlur
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={t("projectiteration:finish")}
              name="date_completed">
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                changeOnBlur
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <FormSelect
              label={t("common:status")}
              name="status"
              options={ProjectIterationModel.getStatusList()}
            />
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={t("projectiteration:description")}
              name="description">
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setProjectIterationId(0);
              }}
              icon={<IconArrowBack className="mr-1" size={16} />}>
              Quay lại
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ProjectIterationFormAddEdit;
