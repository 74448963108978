import DmsNewsCollection from "common/collections/DmsNewsCollection";
import DmsNews from "common/constants/DmsNews";
import Role from "common/constants/Role";
import DmsNewsCategoryModel from "common/models/DmsNewsCategoryModel";
import DmsNewsModel from "common/models/DmsNewsModel";
import DmsNewsCategoryRepository from "common/repositories/DmsNewsCategoryRepository";
import DmsNewsRepository from "common/repositories/DmsNewsRepository";
import { DmsNewsJson, FilterDmsNews } from "common/types/DmsNews";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import DmsNewsListHeader from "features/dmsnews/list/DmsNewsListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DmsNewsListFilter from "./DmsNewsListFilter";

const DmsNewsList = () => {
  const { t } = useTranslation();

  //fetch category
  const [categoryItems, setCategoryItems] = useState<DmsNewsCategoryModel[]>(
    [],
  );
  const fetchDataCategory = useCallback(async () => {
    const collection = await new DmsNewsCategoryRepository().getItems({
      filters: DmsNewsCategoryRepository.getDefaultFilters(),
    });
    if (!collection.hasError()) {
      setCategoryItems(collection.items);
    }
  }, []);

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterDmsNews = useMemo(
    () => DmsNewsRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterDmsNews>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<DmsNewsModel[]>([]);
  const fetchData = useCallback(async (): Promise<DmsNewsCollection> => {
    let collection = await new DmsNewsRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);
    return collection;
  }, [filters]);

  useEffect(() => {
    fetchDataCategory();
  }, [fetchDataCategory]);

  //Table columns
  const columns: TableColumnsType<DmsNewsModel> = [
    {
      title: t("dmsnews:title"),
      key: "title",
      width: 200,
    },
    {
      title: t("dmsnews:news_category_id"),
      key: "news_category_id",
      width: 200,
      render: (news_category_id: number) => {
        if (news_category_id > 0) {
          const foundItem = categoryItems.find(
            (i) => i.id === news_category_id,
          );
          if (typeof foundItem !== "undefined") {
            return <span>{foundItem.name}</span>;
          } else {
            return "ID #" + news_category_id;
          }
        } else {
          return "-";
        }
      },
    },
    {
      title: t("common:seo.url"),
      key: "seo_slug",
      width: 300,
      render: (seo_slug) => {
        return <>/{seo_slug}</>;
      },
    },
    {
      title: t("common:seo.meta_title"),
      key: "seo_title",
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 100,
      render: (ts: number) => <TextDateTime ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_: any, record: DmsNewsJson) => (
        <RoleCheck roles={[Role.COMPANY_SETTING]} hideOnFail>
          <TableEdit link={"/dmsnews/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="dmsnews:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new DmsNewsRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <DmsNewsListHeader />

      <DmsNewsListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterDmsNews, DmsNewsModel, DmsNewsCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          rowClassName: (record) =>
            record.status === DmsNews.STATUS_ENABLE
              ? ""
              : "table-row-stripes opacity-70",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default DmsNewsList;
