import BaseCollection from 'common/collections/BaseCollection';
import ProductInventoryCheckingModel from 'common/models/ProductInventoryCheckingModel';
import {
    ProductInventoryCheckingCollectionJson, ProductInventoryCheckingJson
} from 'common/types/ProductInventoryChecking';

class ProductInventoryCheckingCollection extends BaseCollection<
  ProductInventoryCheckingJson,
  ProductInventoryCheckingModel,
  ProductInventoryCheckingCollectionJson
> {
  itemsFromJson(
    jsonItems: ProductInventoryCheckingJson[]
  ): ProductInventoryCheckingModel[] {
    return jsonItems.map((item) => new ProductInventoryCheckingModel(item));
  }
}

export default ProductInventoryCheckingCollection;
