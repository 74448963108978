// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__Ghc6N {
  --h1-fontsize: 32px;
  --h2-fontsize: 32px;
  --h3-fontsize: 32px;
  --h4-fontsize: 32px;

  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.styles_title__O0DPg {
  font-size: var(--h1-fontsize);
  color: var(--title-color);
}

.styles_content__OuCgf {
  position: relative;
  padding: 20px;
}

.styles_content__OuCgf::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: 100px;
  border-bottom: 5px solid var(--border-color);
}

.styles_action_back__VR8Tb {
  padding: 2px 8px 4px;
  border: 1px solid var(--title-color);
  border-radius: 4px;
  text-align: center;
  transition: all 0.2s;
  margin: 16px 8px 0;
}
.styles_action_back__VR8Tb:hover {
  background-color: var(--title-color);
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/features/academy/component/AcademyNotFound/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;;EAEnB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE,oBAAoB;EACpB,oCAAoC;EACpC,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;AACpB;AACA;EACE,oCAAoC;EACpC,WAAW;AACb","sourcesContent":[".wrapper {\n  --h1-fontsize: 32px;\n  --h2-fontsize: 32px;\n  --h3-fontsize: 32px;\n  --h4-fontsize: 32px;\n\n  width: 100vw;\n  height: 90vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  text-align: center;\n}\n.title {\n  font-size: var(--h1-fontsize);\n  color: var(--title-color);\n}\n\n.content {\n  position: relative;\n  padding: 20px;\n}\n\n.content::before {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  transform: translateX(-50%);\n  height: 5px;\n  width: 100px;\n  border-bottom: 5px solid var(--border-color);\n}\n\n.action_back {\n  padding: 2px 8px 4px;\n  border: 1px solid var(--title-color);\n  border-radius: 4px;\n  text-align: center;\n  transition: all 0.2s;\n  margin: 16px 8px 0;\n}\n.action_back:hover {\n  background-color: var(--title-color);\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__Ghc6N`,
	"title": `styles_title__O0DPg`,
	"content": `styles_content__OuCgf`,
	"action_back": `styles_action_back__VR8Tb`
};
export default ___CSS_LOADER_EXPORT___;
