import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import TextMoney from "components/TextMoney";
import ReportChartDataTable from "features/report/chart/ReportChartDataTable";
import React from "react";

const ReportSalePromotionCampain = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  return (
    <>
      <div className="p-6">
        <Row>
          <Col flex="1">
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo áp dụng theo chương trình khuyến mãi"}
              fetcherObject="promotion"
              fetcherKeyMapping="promotion"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Chương trình khuyến mãi",
                  name: "promotion",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "promotion"
                },
                {
                  title: "Tổng giá trị giảm",
                  name: "sum_pricediscount",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricediscount)"
                },
                {
                  title: "Tổng số đơn hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), promotion, sum(pricediscount)"
              dataFilter="status < 20,promotion != 0"
              dataGroupBy="promotion"
              dataOrderBy="sum(pricediscount) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportSalePromotionCampain;
