import DeliveryApi from "common/api/DeliveryApi";
import DeliveryCollection from "common/collections/DeliveryCollection";
import DeliveryModel from "common/models/DeliveryModel";
import EmployeeModel from "common/models/EmployeeModel";
import {
	DeliveryAddEditJson,
	DeliveryInternalAddEditJson,
	FilterDelivery,
} from "common/types/Delivery";
import { GetListProps } from "common/types/Filter";
import BaseRepository from "./BaseRepository";
import DeliveryHistoryCollection from "common/collections/DeliveryHistoryCollection";

class DeliveryRepository extends BaseRepository<DeliveryApi> {
	_api: DeliveryApi | null;

	constructor() {
		super();
		this._api = new DeliveryApi(true);
	}

	static getDefaultFilters(): FilterDelivery {
		return {
			page: 1,
			limit: 20,
			sortby: "id",
			sorttype: "DESC",
			status: -1,
			keyword: "",
			action_code: "",
			internal_id: "",
			soandcode: "",
			date_created: -1,
			date_completed: -1,
			actor_id: -1,
			find_internal: "",
		};
	}

	async getItemsDeilveryManager(
		props: GetListProps<FilterDelivery>
	): Promise<DeliveryCollection> {
		return this._api
			? await this._api.getListDeliveryManager(props)
			: new DeliveryCollection();
	}

	async getItemsDeilveryAccountants(
		props: GetListProps<FilterDelivery>
	): Promise<DeliveryCollection> {
		return this._api
			? await this._api.getListDeliveryAccoutants(props)
			: new DeliveryCollection();
	}

	async getItemsDeilveryShipperView(
		props: GetListProps<FilterDelivery>
	): Promise<DeliveryCollection> {
		return this._api
			? await this._api.getListDeliveryShipperView(props)
			: new DeliveryCollection();
	}

	async getItemsDeliveryWaiting(props: GetListProps<FilterDelivery>) {
		return this._api
			? await this._api.getListItemsWaiting(props)
			: new DeliveryCollection();
	}

	async getItem(id: number | string): Promise<DeliveryModel> {
		return this._api
			? await this._api.getDetail(id)
			: new DeliveryModel(DeliveryModel.getDefaultData());
	}

	async getItemsDeliveryHistory(
		props: GetListProps<FilterDelivery>,
		id: number | string
	): Promise<DeliveryHistoryCollection> {
		return this._api
			? await this._api.getListDeliveryHistory(props, id)
			: new DeliveryHistoryCollection();
	}

	async addAction(data: DeliveryAddEditJson): Promise<DeliveryModel> {
		return this._api
			? await this._api.addActionDelivery(data)
			: new DeliveryModel(DeliveryModel.getDefaultData());
	}

	async getEmployeeInDelivery(id: number): Promise<EmployeeModel> {
		return this._api
			? await this._api.getDeliveryPersonDetail(id)
			: new EmployeeModel(EmployeeModel.getDefaultData());
	}

	async getDetailOrderNotInDelivery(id: number): Promise<DeliveryModel> {
		return this._api
			? await this._api.getDetailOrderNotInDelivery(id)
			: new DeliveryModel(DeliveryModel.getDefaultData());
	}

	async saveRemote(data: DeliveryInternalAddEditJson): Promise<DeliveryModel> {
		if (this._api) {
			if (data.id > 0) {
				return await this._api.edit(data);
			} else {
				return await this._api.add(data);
			}
		} else {
			return new DeliveryModel(DeliveryModel.getDefaultData());
		}
	}

	async deleteItem(id: number): Promise<string[]> {
		return this._api ? await this._api.deleteItem(id) : [];
	}

	async scanItem(order_id: string): Promise<DeliveryModel> {
		return this._api
			? await this._api.scanItem(order_id)
			: new DeliveryModel(DeliveryModel.getDefaultData());
	}
}

export default DeliveryRepository;
