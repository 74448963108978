import DmsBannerModel from 'common/models/DmsBannerModel';
import DmsBannerRepository from 'common/repositories/DmsBannerRepository';
import FormEditFetcher from 'components/form/FormEditFetcher';
import DmsBannerFormEditor from 'features/dmsbanner/form/DmsBannerFormEditor';
import DmsBannerFormEditorHeader from 'features/dmsbanner/form/DmsBannerFormEditorHeader';
import DmsBannerList from 'features/dmsbanner/list/DmsBannerList';
import usePathParams from 'hooks/usePathParams';
import React, { useEffect, useState } from 'react';

const DmsBannerPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState<DmsBannerModel>(
    new DmsBannerModel(DmsBannerModel.getDefaultData())
  );
  const [fetchErrors, setFetchErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new DmsBannerModel(DmsBannerModel.getDefaultData()));
    setFetchErrors([]);
  }, [pathParams.action]);

  let com = null;

  switch (pathParams.action) {
    case "add":
      com = (
        <DmsBannerFormEditor
          model={new DmsBannerModel(DmsBannerModel.getDefaultData())}
        />
      );
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <DmsBannerFormEditor model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              let fetchedModel = await new DmsBannerRepository().getItem(
                parseInt(pathParams.id.toString())
              );
              if (fetchedModel.hasError()) {
                setFetchErrors(fetchedModel.error.errors);
              } else {
                setModel(fetchedModel);
              }
            }}
            errors={fetchErrors}
            heading={<DmsBannerFormEditorHeader isEditing={true} />}
          />
        );

      break;
    default:
      com = <DmsBannerList />;
  }

  return com;
};

export default DmsBannerPage;
