import { useTranslation } from "react-i18next";
import PageHeader from "components/page/PageHeader";

type Props = {
  children?: React.ReactNode;
};

const ReportCheckinHeader = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("reportcheckin:heading_reportcheckin")}
      siteMenuSelectedKey="/report/checkin"
      siteMenuOpenKey="report"
    >
      {children}
    </PageHeader>
  );
};

export default ReportCheckinHeader;
