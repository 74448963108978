import { Col, Menu, MenuProps, Row } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import File from "common/constants/File";
import Role from "common/constants/Role";
import FileStatsModel from "common/models/FileStatsModel";
import FileRepository from "common/repositories/FileRepository";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useFileManagerStore from "zustands/useFileManagerStore";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import { IconFolder, IconLock, IconServer } from "@tabler/icons-react";

import FileStats from "./FileStats";

const FileListNavigation = () => {
  const { t } = useTranslation();

  const hasRole = useLoginAccountStore((state) => state.hasRole);

  const [activeObjectType, setActiveObjectType] = useFileManagerStore(
    (state) => [state.activeObjectType, state.setActiveObjectType]
  );

  const [activeOrigin, setActiveOrigin] = useFileManagerStore((state) => [
    state.activeOrigin,
    state.setActiveOrigin,
  ]);

  const [fileStats, setFileStats] = useState<FileStatsModel>();

  const onClickMenu: MenuProps["onClick"] = useCallback(
    (e: any) => {
      const parts = e.key.split(".");
      if (parts.length === 2) {
        setActiveObjectType(+parts[0]);
        setActiveOrigin(parts[1]);
      }
    },
    [setActiveObjectType, setActiveOrigin]
  );

  const getMenuItems = useCallback(() => {
    const iconProps = { size: 20, className: "-mt-1 mr-2 text-gray-600" };
    const iconLockProps = { size: 20, className: "-mt-1 mr-2 text-red-600" };

    //default is "company" origin
    const items: MenuProps["items"] = [
      {
        label: (
          <>
            <IconServer {...iconProps} />
            {t("file:navigation.OBJECTTYPE_FILE")}
          </>
        ),
        key: File.OBJECTTYPE_FILE + ".company",
      },
    ];

    //ensure fileStats is load
    //and have role
    if (typeof fileStats !== "undefined" && hasRole(Role.FILE_MANAGE)) {
      const groups = [
        {
          label: (
            <>
              <IconLock {...iconLockProps} />
              {t("file:navigation.OBJECTTYPE_ATTACHMENT")}
            </>
          ),
          key: File.OBJECTTYPE_ATTACHMENT,
        },
        {
          label: (
            <>
              <IconLock {...iconLockProps} />
              {t("file:navigation.OBJECTTYPE_CMS")}
            </>
          ),
          key: File.OBJECTTYPE_CMS,
        },
      ];

      //loop to check showable origin item
      groups.forEach((item) => {
        const subItems: ItemType[] = [];
        fileStats.count_file_by_type
          .filter(
            (i) =>
              i.object_type === item?.key &&
              FileRepository.getAllowOrigin().includes(i.origin) &&
              i.value > 0
          )
          .forEach((i) => {
            subItems.push({
              label: (
                <span className="pl-4 py-1 text-sm text-gray-500">
                  <IconFolder className="mr-1 -mt-1 text-gray-400" size={18} />{" "}
                  {t("file:navigation.origin." + i.origin)}
                </span>
              ),
              key: item?.key + "." + i.origin,
            });
          });
        if (subItems.length > 0) {
          items.push({
            label: item.label || "",
            key: item.key || "",
            children: subItems,
          });
        }
      });
    }

    return items;
  }, [fileStats, t, hasRole]);

  return (
    <div className=" bg-blue-100">
      <Row>
        <Col flex={"120px"}>
          <div className="text-xs pt-4 ml-4 uppercase text-gray-500">
            {t("file:navigation.menu")}
          </div>
        </Col>
        <Col flex={"auto"}>
          <Menu
            mode="horizontal"
            style={{
              borderRight: 0,
              background: "transparent",
              paddingLeft: 0,
            }}
            selectedKeys={[activeObjectType + "." + activeOrigin]}
            items={getMenuItems()}
            triggerSubMenuAction="click"
            onClick={onClickMenu}
          />
        </Col>
        <Col flex={"200px"}>
          <FileStats fileStats={fileStats} setFileStats={setFileStats} />
        </Col>
      </Row>
    </div>
  );
};

export default FileListNavigation;
