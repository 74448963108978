import CashflowReceipt from "common/constants/CashflowReceipt";
import CashflowReceiptModel from "common/models/CashflowReceiptModel";
import momo from "images/momo.png";
import vnpay from "images/vnpay.png";

import {
  IconCashBanknote,
  IconCreditCard,
  IconGift,
  IconMedal,
  IconUserCircle,
} from "@tabler/icons-react";

const PosRightPaymentMethodItem = ({
  disabled,
  method,
  onClick,
  active,
}: {
  disabled: boolean;
  method: number;
  onClick: () => void;
  active: boolean;
}) => {
  let iconCom = null;

  const methodInfo = CashflowReceiptModel.getMethod(method);

  const iconStyle = {
    size: 48,
    strokeWidth: 1,
    className: "m-3",
  };

  switch (method) {
    case CashflowReceipt.METHOD_CASH:
      iconCom = <IconCashBanknote {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_BANK:
      iconCom = <IconCreditCard {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_GIFTCARD:
      iconCom = <IconGift {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_CUSTOMER_POINT:
      iconCom = <IconMedal {...iconStyle} />;
      break;
    case CashflowReceipt.METHOD_MOMO:
      iconCom = <img src={momo} className="inline m-4" width="42" alt="momo" />;
      break;
    case CashflowReceipt.METHOD_VNPAY:
      iconCom = (
        <img src={vnpay} width="42" alt="vnpay" className="inline m-4" />
      );
      break;
    case CashflowReceipt.METHOD_MEMBERCARD:
      iconCom = <IconUserCircle {...iconStyle} />;
      break;
    default:
  }

  return (
    <div
      onClick={() => onClick()}
      className={
        "pb-4 hover:bg-red-50 pos-payment-method-item" +
        (active ? " active" : "") +
        (disabled ? " disabled" : "")
      }
    >
      {iconCom}
      <p className="pd-t-10">{methodInfo?.label}</p>
    </div>
  );
};

export default PosRightPaymentMethodItem;
