import BaseApi from "./BaseApi";
import RbacRoleUserCollection from "common/collections/RbacRoleUserCollection";
import AxiosClient from "./AxiosClient";
import type { GetListProps } from "common/types/Filter";
import type { FilterRoleUser } from "common/types/RbacRoleUser";
import RbacRoleUserDetailModel from "common/models/RbacRoleUserDetailModel";
import { RbacSubjectOj } from "common/types/RbacSubject";

const SERVICE_URL = "/rbacroleusers";

class RbacRoleUserApi extends BaseApi {
  async getList(
    props: GetListProps<FilterRoleUser>
  ): Promise<RbacRoleUserCollection> {
    let collection = new RbacRoleUserCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          role_id: filters.role_id,
          page: filters.page || 1,
          limit: filters.limit || 50,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }


  async getListSubjectObject(filter: {
    rbac_role_id: number
    subject_id: number
  }): Promise<RbacSubjectOj[]> {


    const { data } = await AxiosClient().get<any>(SERVICE_URL  + "/rbacsubjectobject", { params: filter });

    return data.items
  }
  async getRolesFromUser(user_id: number): Promise<RbacRoleUserDetailModel> {
    let item = new RbacRoleUserDetailModel(RbacRoleUserDetailModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(
        SERVICE_URL + "/fromuser?user_id=" + user_id
      );
      if (response.hasOwnProperty("data")) {
        item = new RbacRoleUserDetailModel(response.data["items"][0]);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async limitObjectIdList({
    rbac_role_id,
    list_ids,
    subject_ids,
    creator_id
  }: {
    rbac_role_id: number,
    list_ids?: number[],
    subject_ids?: string,
    creator_id: number
  }): Promise<string[]> {
    let resultErrors = [];

    try {
      let postData = {
        rbac_role_id,
        subject_ids,
        creator_id,
        list_ids,
      };

      //For create item
      const response = await AxiosClient().post(
        SERVICE_URL + "/limitobjectidlist",
        postData
      );
      if (response.status !== 200 && response.status !== 201) {
        resultErrors.push("error_response_not_200");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default RbacRoleUserApi;
