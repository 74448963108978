import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import type { DataGridWorkspaceJson } from "common/types/DataGridWorkspace";

class DataGridWorkspaceModel
  extends BaseModel
  implements BaseModelJson<DataGridWorkspaceJson>
{
  company_id: number;
  creator_id: number;
  id: number;
  name: string;
  display_order: number;
  date_created: number;
  date_modified: number;

  constructor(json: DataGridWorkspaceJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.display_order = json.display_order || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): DataGridWorkspaceJson {
    return {
      company_id: 0,
      creator_id: 0,
      id: 0,
      name: "",
      display_order: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): DataGridWorkspaceJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      id: this.id,
      name: this.name,
      display_order: this.display_order,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default DataGridWorkspaceModel;
