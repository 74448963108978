import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import i18n from "i18n";

import BaseModel from "./BaseModel";

import { LeaveJson } from "common/types/Leave";
import Leave from "common/constants/Leave";

class LeaveModel extends BaseModel implements BaseModelJson<LeaveJson> {
  id: number;
  company_id: number;
  employee_id: number;
  leavetypes_id: number;
  dayoff: number;
  date_from: any;
  date_to: any;
  note: string;
  status: number;
  date_created: number;
  date_modified: number;
  isdeleted: number;
  datedeleted: number;
  department_id: number;
  constructor(json: LeaveJson) {
    super();
    // this.stt = json.stt || 0;
    this.id = json.id || 0;
    this.company_id = json.company_id || 0;
    this.employee_id = json.employee_id || 0;
    this.leavetypes_id = json.leavetypes_id || 0;
    this.dayoff = json.dayoff || 0;
    this.date_from = json.date_from || 0;
    this.date_to = json.date_to || 0;
    this.note = json.note || "";
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.isdeleted = json.isdeleted || 0;
    this.datedeleted = json.datedeleted || 0;
    this.department_id = json.department_id || 0;
  }

  static getDefaultData(): LeaveJson {
    return {
      id: 0,
      company_id: 0,
      employee_id: 0,
      leavetypes_id: 0,
      dayoff: 1,
      date_from: 0,
      date_to: 0,
      note: "",
      status: 0,
      date_created: 0,
      date_modified: 0,
      isdeleted: 0,
      datedeleted: 0,
      department_id: 0,
    };
  }

  toJson(): LeaveJson {
    return {
      id: this.id,
      company_id: this.company_id,
      employee_id: this.employee_id,
      leavetypes_id: this.leavetypes_id,
      dayoff: this.dayoff,
      date_from: this.date_from,
      date_to: this.date_to,
      note: this.note,
      status: this.status,
      date_created: this.date_created,
      date_modified: this.date_modified,
      isdeleted: this.isdeleted,
      datedeleted: this.datedeleted,
      department_id: this.department_id,
    };
  }

  static getLeaveSalaryType(value: string) {
    const salary_type: any = {
      NCL: i18n.t("leave:salary_type.NCL"),
      NKL: i18n.t("leave:salary_type.NKL"),
      NBH: i18n.t("leave:salary_type.NBH"),
    };
    return salary_type[value];
  }

  static getLeaveStatus(): SelectOption[] {
    return [
      {
        value: Leave.STATUS_PENDING,
        label: i18n.t("leave:status.pending"),
      },
      {
        value: Leave.STATUS_APPROVED,
        label: i18n.t("leave:status.approved"),
      },
      {
        value: Leave.STATUS_DENED,
        label: i18n.t("leave:status.dened"),
      },
      {
        value: 3,
        label: i18n.t("leave:status.approved_3"),
      },
      {
        value: 4,
        label: i18n.t("leave:status.approved_4"),
      },
    ];
  }
  static getStatus(value: number): SelectOption | undefined {
    return this.getLeaveStatus().find((item) => item.value === value);
  }
  static getSalaryType(): SelectOption[] {
    return [
      {
        value: Leave.SALARY_1,
        label: i18n.t("leaveapprove:salary.none"),
      },
      {
        value: Leave.SALARY_2,
        label: i18n.t("leaveapprove:salary.bhxh"),
      },
      {
        value: Leave.SALARY_3,
        label: i18n.t("leaveapprove:salary.company"),
      },
    ];
  }
  static getSalary(value: number): SelectOption | undefined {
    return this.getSalaryType().find((item) => item.value === value);
  }

  static getStepsName(): SelectOption[] {
    return [
      {
        value: Leave.STEP_0,
        label: i18n.t("leave:status:.approved_0"),
      },

      {
        value: Leave.STEP_3,
        label: i18n.t("leave:status.approved_3"),
      },
      {
        value: Leave.STEP_4,
        label: i18n.t("leave:status.approved_4"),
      },
    ];
  }
  static getStep(value: number): SelectOption | undefined {
    return this.getStepsName().find((item) => item.value === value);
  }
}

export default LeaveModel;
