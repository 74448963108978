import ProjectModel from 'common/models/ProjectModel';
import PageHeader from 'components/page/PageHeader';
import React from 'react';

const ProjectTaskHeader = ({
  model,
  children,
}: {
  model: ProjectModel;
  children?: React.ReactNode;
}) => {
  return (
    <PageHeader
      heading={model.name}
      siteMenuSelectedKey="/project"
      siteMenuHidden
    >
      {children}
    </PageHeader>
  );
};

export default ProjectTaskHeader;
