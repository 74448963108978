import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "antd/lib/form/Form";
import { Button, Col, Form, Input, Row, message } from "antd";

import { useTranslation } from "react-i18next";
import { debounce, filter } from "lodash";

import useStateFilter from "hooks/useStateFilter";

//////////////////////////////////
//Phần thay thế
import LeaveAssignModel from "common/models/LeaveAssignModel";
import LeaveApproveRepository from "common/repositories/LeaveApproveRepository";
import { LeaveAssignJsonAddEdit } from "common/types/LeaveAssign";
import FormSelectNormal from "components/form/FormSelectNormal";
import LeaveStepModel from "common/models/LeaveStepModel";
import { EmployeeJson, FilterEmployee } from "common/types/Employee";
import { SelectOptionNormal } from "common/types/SelectOption";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import DepartmentFormSelect from "features/department/DepartmentFormSelect";
import useDatabaseTable from "hooks/useDatabaseTable";

type Props = {
  model: LeaveAssignModel;
  steps: LeaveStepModel[];
  onClose: () => void;
};

type Options = {
  label: string;
  value: string | number;
};
const LeaveAssignForm = ({ model, steps, onClose }: Props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const isEditing = model.approver_id > 0;
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");

  /////////////////////////////////
  //filter employee list
  const defaultFilters = useMemo(() => {
    return {
      ...EmployeeRepository.getDefaultFilters(),
      limit: 20,
      keyword: isEditing ? JSON.stringify(model.employee_id) : "",
    };
  }, [model.employee_id]);
  const [filters, setFilters] = useStateFilter<FilterEmployee>(defaultFilters);
  /////////////////////////////////
  //state
  const [errors, setErrors] = useState<string[]>([]);
  const [errorsEmployee, setErrorsEmployee] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const [resourceType, setResourceType] = useState<string>("department");
  const [stepOptions, setStepOptions] = useState<Options[]>([]);

  /////////////////////////////////
  //default data
  const initialValues = useMemo(() => {
    return {
      ...LeaveAssignModel.getDefaultData(),
      type: model.type !== "" ? model.type : "leave",
      step_name: model.stepname,
      resource_type:
        model.resourcetype !== "" ? model.resourcetype : "department",
      resource_id: model.resourceid !== 0 ? model.resourceid : null,
      step: model.step !== 0 ? model.step : null,
      status: model.status >= 0 ? model.status : null,
      employee_id: model.employee_id > 0 ? model.employee_id : null,
    };
  }, [model]);

  /////////////////////////////////
  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: LeaveAssignJsonAddEdit) => {
      const submitData: LeaveAssignJsonAddEdit = {
        ...formData,
        approver_id: model.approver_id,
        stepname:
          steps.find((i) => i.steps === formData.step)?.approve_name || "",
      };

      return submitData;
    },
    [model.approver_id]
  );
  /////////////////////////////////
  //submit data to server
  const onSubmit = async (formData: LeaveAssignJsonAddEdit) => {
    setLoading(true);
    const response = await new LeaveApproveRepository().saveRemote(
      doPrepareData(formData)
    );
    if (!response.hasError()) {
      message.success({
        content: t("common:form.success.save"),
        duration: 3,
      });
      form.resetFields();
      onClose();
    } else {
      setErrors(response.error.errors);
    }
    setLoading(false);
  };

  // const getListEmployee = useCallback(async () => {
  //   const res = await new EmployeeRepository().getItems({ filters: filters });
  //   if (!res.hasError() && res.items.length > 0) {
  //     let options: Options[] = [];
  //     res.items.forEach((i) => {
  //       options.push({
  //         label: i.full_name + " " + i.internal_id,
  //         value: i.id,
  //       });
  //     });
  //     setEmployeeOptions(options);
  //   } else {
  //     setErrorsEmployee(res.error.errors);
  //   }
  // }, [filters.keyword]);

  const getAllStep = useCallback(() => {
    let options: Options[] = [];
    steps.forEach((i) => {
      options.push({
        label: t(`leaveapprove:approval.form.steps.${i.approve_name}`),
        value: i.steps,
      });
    });
    setStepOptions(options);
  }, [steps]);

  const handleCallInit = () => {
    getAllStep();
  };
  /////////////////////////////////
  //handle event
  const handleResourceTypeChange = (e: any) => {
    setResourceType(e);
  };

  const handleInputChage = debounce((value: string) => {
    setFilters({
      ...filters,
      page: 1,
      keyword: value,
    });
    setLoading(false);
  }, 1000);
  /////////////////////////////////
  //
  const typeApproveOptions: SelectOptionNormal[] = useMemo(() => {
    let options: SelectOptionNormal[] = [];
    options.push({
      label: t("leaveapprove:assign.form.type_approve.leave"),
      value: "leave",
    });
    return options;
  }, []);

  const employeeFilteredOptions =
    (employeeItems &&
      employeeItems.map((e) => {
        return {
          value: e.id,
          label: e.full_name,
        };
      })) ||
    [];

  //////////////////////////////
  useEffect(() => {
    handleCallInit();
  }, []);

  // useEffect(() => {
  //   getListEmployee();
  // }, [filters.keyword]);

  useEffect(() => {
    if (errors.length > 0) {
      message.error({
        content: t(`leaveapprove:assign.form.errors.${errors[0]}`),
        duration: 3,
      });
    }
  }, [errors]);
  return (
    <div>
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onSubmit}
      >
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <FormSelectNormal
              // onSearch={(value) => {
              //   setLoading(true);
              //   handleInputChage(value);
              // }}
              loading={loading}
              showSearch={true}
              label={t("leaveapprove:assign.form.person_assign")}
              placeholder={t(
                "leaveapprove:assign.form.person_assign_placeholder"
              )}
              name="employee_id"
              disabled={isEditing}
              errors={errorsEmployee}
              options={employeeFilteredOptions}
              rules={[
                {
                  required: !isEditing,
                  message: "Loại duyệt không được trống",
                },
              ]}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormSelectNormal
              showSearch
              required
              label={t("leaveapprove:assign.form.type_approve.label")}
              placeholder={t(
                "leaveapprove:assign.form.type_approve_placeholder"
              )}
              defaultValue="leave"
              name="type"
              disabled={true}
              options={typeApproveOptions}
              rules={[
                {
                  required: !isEditing,
                  message: "Loại duyệt không được trống",
                },
              ]}
            />
          </Col>
          <Col md={24} xs={24}>
            <Form.Item
              // required
              hidden
              name="step_name"
              label={t("leaveapprove:assign.form.step_name")}
              className="mb-4"
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item
              required
              name="resource_type"
              label={t("Duyệt trên")}
              className="mb-0"
              rules={[
                {
                  required: !isEditing,
                  message: t("Nơi duyệt không được trống"),
                },
              ]}
            >
              <FormSelectNormal
                showSearch
                required
                name="resource_type"
                disabled={true}
                onChange={(e) => handleResourceTypeChange(e)}
                options={[{ label: "department", value: "department" }]}
                rules={[
                  {
                    required: !isEditing,
                    message: "Nơi duyệt không được trống",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item required name="resource_id" className="mb-0">
              <DepartmentFormSelect
                required={!isEditing}
                disabled={false}
                label={t("employee:department")}
                name="resource_id"
                placeholder="Chọn phòng ban"
              />
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <FormSelectNormal
              showSearch
              disabled={isEditing}
              label={t("Chọn bước duyệt")}
              placeholder={"Chọn bước duyệt "}
              name="step"
              options={stepOptions}
              rules={[
                {
                  required: !isEditing,
                  message: "Bước duyệt không được trống",
                },
              ]}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormSelectNormal
              required
              label={t("Hiệu lực")}
              placeholder={"Chọn hiệu lực "}
              name="status"
              defaultValue={model.status > 0 ? model.status : 1}
              options={[
                {
                  value: 0,
                  label: "không có hiệu lực",
                },
                {
                  value: 1,
                  label: "Có hiệu lực",
                },
              ]}
              rules={[
                {
                  required: !isEditing,
                  message: "Hiệu lực không được trống",
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <Form.Item className="m-0">
              <Button htmlType="submit" block loading={loading} type="primary">
                {t("leaveapprove:assign.form.add")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LeaveAssignForm;
