import React from "react";

import { IconPhoto } from "@tabler/icons-react";

const NoPhoto = ({
  width,
  height,
  iconRatio,
  borderRadius,
  border,
  color
}: {
  width?: number;
  height?: number;
  iconRatio?: number;
  borderRadius?: number;
  color?: string;
  border?: string;
}) => {
  //style options
  const _color = typeof color !== "undefined" ? color : "#ddd";
  const _border = typeof border !== "undefined" ? border : "1px solid #eee";
  const _borderRadius = typeof borderRadius !== "undefined" ? borderRadius : 4;

  //dynamic size options
  const borderWidth = 1;
  const myWidth =
    (typeof width !== "undefined" && width > 0 ? width : 100) - 2 * borderWidth;
  const myHeight =
    (typeof height !== "undefined" && height > 0 ? height : 100) -
    2 * borderWidth;

  const myIconRatio =
    typeof iconRatio !== "undefined" && iconRatio > 0 && iconRatio < 1
      ? iconRatio
      : 0.6;

  const iconSize = Math.floor(myWidth * myIconRatio);

  const style = {
    width: myWidth,
    height: myHeight,
    display: "inline-block",
    paddingTop: (myHeight - iconSize) / 2 - borderWidth,
    paddingLeft: (myWidth - iconSize - 2) / 2 + borderWidth,
    border: _border,
    borderRadius: _borderRadius,
    color: _color,
    textAlign: "left" as "left",
    verticalAlign: "top"
  };

  return (
    <span style={style}>
      <IconPhoto size={iconSize} />
    </span>
  );
};

export default NoPhoto;
