import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import DeliveryModel from "common/models/DeliveryModel";
import { Filter } from "common/types/Filter";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import FormSelectNormal from "components/form/FormSelectNormal";
import PageDataFilterForm from "components/page/PageDataFilterForm";

const DeliveryManagerListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={[]}
      >
    
          <Form.Item
          // className="lg:w-[400px] gap-1 "
          // label={t("common:date_created")}
          >
            <DatePickerRangeFilter
              filters={filters}
              keyStart="date_created"
              keyEnd="date_completed"
              setFilters={setFilters}
              placeholderRange={[
                t("reportdelivery:placeholder_datefrom"),
                t("reportdelivery:placeholder_dateto"),
              ]}
            />
          </Form.Item>

          <Form.Item name="find_internal">
            <Input
              allowClear
              placeholder={t("reportdelivery:placeholder_find_internal")}
            />
          </Form.Item>

          <FormSelectNormal
            name="action_code"
            dropdownStyle={{ border: "none" }}
            placeholder={t("delivery:filter.choose_action_code")}
            allowClear={true}
            options={DeliveryModel.getManagerActionStatusList()}
            showSearch
          ></FormSelectNormal>

          <Form.Item name="soandcode">
            <Input
              allowClear
              placeholder={t("reportdelivery:placeholder_soandcode")}
            />
          </Form.Item>
     
      </PageDataFilterForm>
    </>
  );
};

export default DeliveryManagerListFilter;
