import NestedCollection from "common/collections/NestedCollection";
import DmsNewsCategory from "common/constants/DmsNewsCategory";
import Role from "common/constants/Role";
import DmsNewsCategoryRepository from "common/repositories/DmsNewsCategoryRepository";
import { DmsNewsCategoryJsonWithChildren } from "common/types/DmsNewsCategory";
import { TableColumnsType } from "common/types/Table";
import PageDataTableWithChildren from "components/page/PageDataTableWithChildren";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import DmsNewsCategoryListHeader from "features/dmsnewscategory/list/DmsNewsCategoryListHeader";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronDownLeft } from "@tabler/icons-react";

const DmsNewsCategoryList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Fetch data from this collections
  const [dataSource, setDataSource] = useState<
    DmsNewsCategoryJsonWithChildren[]
  >([]);
  const fetchData = useCallback(async (): Promise<
    NestedCollection<DmsNewsCategoryJsonWithChildren>
  > => {
    let collection = await new DmsNewsCategoryRepository().getFullNestedItems();
    setDataSource(collection.items);
    return collection;
  }, []);

  //Table columns
  const columns: TableColumnsType<DmsNewsCategoryJsonWithChildren> = [
    {
      title: t("dmsnewscategory:name"),
      key: "name",
    },
    {
      title: t("common:seo.meta_title"),
      key: "seo_title",
    },
    {
      title: t("common:display_order_long"),
      key: "display_order",
      width: 120,
      render: (_, record) => (
        <span>
          {record.parent_id > 0 ? (
            <IconChevronDownLeft
              size={16}
              color="#dddddd"
              className="-ml-0.5 mr-1.5"
            />
          ) : null}
          <span
            className="ml-0.5"
            style={{ opacity: record.parent_id === 0 ? 1 : 0.7 }}
          >
            {record.display_order}
          </span>
        </span>
      ),
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 120,
      render: (ts: number) => <TextDateTime ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_: any, record: DmsNewsCategoryJsonWithChildren) => (
        <RoleCheck roles={[Role.COMPANY_SETTING]} hideOnFail>
          <TableEdit link={"/dmsnewscategory/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="dmsnewscategory:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new DmsNewsCategoryRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <DmsNewsCategoryListHeader />

      <PageDataTableWithChildren<DmsNewsCategoryJsonWithChildren>
        {...{
          columns,
          dataSource,
          fetchData,
          rowClassName: (record) =>
            record.status === DmsNewsCategory.STATUS_ENABLE
              ? ""
              : "table-row-stripes opacity-70",
        }}
      ></PageDataTableWithChildren>
    </>
  );
};

export default DmsNewsCategoryList;
