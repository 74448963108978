import BaseModel from "./BaseModel";
import { BaseModelJson } from "common/interfaces/BaseModelJson";
import { SelectOption } from "common/types/SelectOption";
import { TagDetailJson, TagItemDetailJson } from "common/types/TagDetail";

const defaultData: TagDetailJson = {
  id: 0,
  company_id: 0,
  resoucre_id: 0,
  resoucre_type: 0,
  display_order: 0,
  tag_id: 0,
  creator_id: 0,
  status: 0,
  date_created: 0,
  date_modified: 0,
  is_deleted: 0,
  date_deleted: 0,
  tag: [],
  tag_type: [],
};

class TagDetailModel extends BaseModel implements BaseModelJson<TagDetailJson> {
  id: number = defaultData.id;
  company_id: number = defaultData.company_id;
  resoucre_id: number = defaultData.resoucre_id;
  resoucre_type: number = defaultData.resoucre_type;
  display_order: number = defaultData.display_order;
  tag_id: number = defaultData.tag_id;
  creator_id: number = defaultData.creator_id;
  status: number = defaultData.status;
  is_deleted: number = defaultData.is_deleted;
  date_created: number = defaultData.date_created;
  date_modified: number = defaultData.date_modified;
  date_deleted: number = defaultData.date_deleted;
  tag: TagItemDetailJson[] = defaultData.tag;
  tag_type = defaultData.tag_type;

  constructor(json: TagDetailJson) {
    super();
    Object.assign(this, { ...defaultData, ...json });
  }
  static getDefaultData(): TagDetailJson {
    return { ...defaultData };
  }
  toJson(): TagDetailJson {
    return JSON.parse(JSON.stringify({ ...this }));
  }
}

export default TagDetailModel;
