import { AccountJson } from 'common/types/Account';
import { SiteSearchItem, SiteSearchItemDefault } from 'common/types/SiteSearch';
import i18n from 'i18n';

import SiteMenuRepository from './SiteMenuRepository';

class SiteSearchRepository {
  static getAllResults(account: AccountJson): SiteSearchItem[] {
    //append from sitemenu
    const menu: SiteSearchItem[] = [
      ...SiteMenuRepository.getSiteMenuWithRoleCheck(account).flatMap(
        (group: any) => {
          return group.children.flatMap((item: any) => {
            if (item.label.length === 0) {
              return [];
            } else {
              //detect if item have children
              //we need to loop through subitem
              if (
                typeof item.children !== "undefined" &&
                item.children.length > 0
              ) {
                return item.children.map((subitem: any) => {
                  return {
                    name: subitem.label,
                    link: subitem.key,
                    query: subitem.label + " " + subitem.key,
                  };
                });
              } else {
                return [
                  {
                    name: item.label,
                    link: item.key,
                    query: item.label + " " + item.key,
                  },
                ];
              }
            }
          });
        }
      ),

      ...this.getDefaultResult().map((item) => ({
        ...item,
        query: item.name + " " + item.link,
      })),
    ];

    return menu;
  }

  static getDefaultResult(): SiteSearchItemDefault[] {
    let defaultItems: SiteSearchItemDefault[] = [
      {
        name: i18n.t("sitemenu:employee_add"),
        link: "/employee/add",
      },
      {
        name: i18n.t("order:pos_button"),
        link: "/pos",
      },
    ];

    return defaultItems;
  }
}

export default SiteSearchRepository;
