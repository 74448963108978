import AcademyLayout from "features/academy/list/AcademyLayout";
import usePathParams from "hooks/usePathParams";
import "../../src/features/academy/css/Academy.css";
import AcademyNotFound from "features/academy/component/AcademyNotFound";

const AcademyPage = () => {
  const [pathParams] = usePathParams();

  let com = null;

  switch (pathParams.action) {
    case "notfound":
      com = <AcademyNotFound />;
      break;
    default:
      com = <AcademyLayout />;
  }

  return com;
};

export default AcademyPage;
