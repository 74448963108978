import { Col, Form, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

import { Filter } from "common/types/Filter";

import OfficeFormFilter from "features/office/OfficeFormFilter";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import DepartmentFormFilter from "features/department/DepartmentFormFilter";
import FormSelect from "components/form/FormSelect";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import DatePickerRangeFilter from "components/datetime/DatePickerRangeFilter";
import dayjs from "dayjs";

const PhoneBookListFilter = <F extends Filter>({
	total,
	filters,
	setFilters,
	defaultFilters,
}: {
	total: number;
	filters: F;
	setFilters: (f: F) => any;
	defaultFilters: F;
}) => {
	const { t } = useTranslation();

	return (
		<PageDataFilterForm
			total={total}
			filters={filters}
			setFilters={setFilters}
			defaultFilters={defaultFilters}
			convertValueByKeyFilter={[
				"office_id",
				"department_id",
				"type",
				"status",
			]}>
			<Form.Item name="keyword">
				<Input
					allowClear
					placeholder={t("phonebook:filter.keyword_placeholder")}
				/>
			</Form.Item>

			<OfficeFormFilter
				placeholder={t("phonebook:filter.office")}
				name="office_id"
				allowClear
				popupMatchSelectWidth={false}
			/>

			<DepartmentFormFilter
				placeholder={t("phonebook:filter.department")}
				name="department_id"
				allowClear
				popupMatchSelectWidth={false}
			/>

			<FormSelect
				placeholder="Tất cả trạng thái checkin hôm nay"
				name="today_checkin"
				options={[
					{
						value: -1,
						label: "Tất cả trạng thái checkin hôm nay",
					},
					{
						value: 1,
						label: "Có Checkin hôm nay",
					},
					{
						value: 0,
						label: "Chưa Checkin hôm nay",
					},
				]}></FormSelect>

			<FormSelect
				placeholder="Thông tin sắp xếp"
				name="sort_by"
				options={[
					{
						value: "last_checkin",
						label: "Checkin lần cuối",
					},
					{
						value: "last_checkout",
						label: "Checkout lần cuối",
					},
					{
						value: "last_login",
						label: "Đăng nhập lần cuối",
					},
				]}></FormSelect>

			<FormSelect
				placeholder="Loại sắp xếp"
				name="sort_type"
				options={[
					{
						value: "DESC",
						label: "Giảm dần",
					},
					{
						value: "ASC",
						label: "Tăng dần",
					},
				]}></FormSelect>

			{/* <Form.Item>
				<DatePickerRangeFilter
					filters={filters}
					setFilters={setFilters}
					keyEnd="date_to"
					keyStart="date_from"></DatePickerRangeFilter>
			</Form.Item> */}
		</PageDataFilterForm>
	);
};

export default PhoneBookListFilter;

export const FilterCheckin = <F extends Filter>({
	filters,
	setFilters,
}: {
	filters: F;
	setFilters: (f: F) => any;
}) => {
	const { t } = useTranslation();
	const keyConstant = ["last_checkin", "last_checkout", "last_login"];
	const [key, setKey] = useState("");
	console.log("🚀 ~ key:", key);
	const [filterD, setFilterD] = useState(filters);
	const [loading, setLoading] = useState(true);

	const setFilter = (f: any) => {
		setFilterD(f);
		if (key && key.length > 0) {
			setFilters(f);
		}
	};
	const handleChange = (val: string) => {
		if (!val) {
			return;
		}
		const newFilterD = { ...filterD } as any;
		const fromExited = newFilterD[`from_${key}`];

		const toExited = newFilterD[`to_${key}`];

		if (fromExited) {
			newFilterD[`from_${val}`] = fromExited;
			delete newFilterD[`from_${key}`];
		}

		if (toExited) {
			newFilterD[`to_${val}`] = toExited;

			delete newFilterD[`to_${key}`];
		}

		setFilterD(newFilterD);
		setFilters(newFilterD);

		// setFilter(newFilterD);
	};

	useLayoutEffect(() => {
		setLoading(true);
		const listKeys = Object.keys(filters);
		const keyFilterDateFrom = listKeys.find((i) => {
			const newFilterD = { ...filterD } as any;

			if (
				i.includes("from_") &&
				newFilterD[i] &&
				newFilterD[i].toString() !== "-1"
			) {
				return true;
			}

			return false;
		});
		if (keyFilterDateFrom) {
			const keySelect = keyFilterDateFrom.split("_").slice(1).join("_");

			if (keySelect.length) {
				setKey(keySelect);
				setFilterD(filters);
			}
		}

		if (listKeys) {
		}
		setLoading(false);
	}, [filters]);

	return (
		<Row>
			<Col span={16}>
				{!loading && (
					<DatePickerRangeFilter
						// rangeDefaultValue={[dayjs.unix(filters[`to_${form}`]), dayjs.unix(toTimestamp)]}
						placeholderRange={["Từ", "Đến"]}
						className="m-0"
						filters={{ ...filterD }}
						setFilters={setFilter}
						keyEnd={`to_${key}`}
						keyStart={`from_${key}`}></DatePickerRangeFilter>
				)}
			</Col>

			<Col span={8}>
				{!loading && (
					<Select
						defaultValue={key.length > 0 ? key : null}
						allowClear
						onClear={() => {
							const dataFilter = { ...filters } as any;
							delete dataFilter[`to_${key}`];
							delete dataFilter[`from_${key}`];
							setFilter(dataFilter);
							setFilterD(dataFilter);
						}}
						placeholder="Chọn loại"
						className="w-full"
						onChange={(val: string) => {
							handleChange(val);
							setKey(val);
						}}
						options={keyConstant.map((i) => ({
							label: t(`phonebook:filters.${i}`),
							value: i,
						}))}></Select>
				)}
			</Col>
		</Row>
	);
};
