import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const LeaveApprovalFormHeader = ({ isEditing }: { isEditing: boolean }) => {
  const { t } = useTranslation();

  const nav: Nav = [
    {
      text: t("leaveapprove:approval.form.heading_nav"),
      link: "/leaveapprove/approval",
    },
  ];

  return (
    <PageHeader
      heading={t(
        isEditing ? "leaveapprove:heading_edit" : "leaveapprove:heading_add"
      )}
      nav={nav}
      siteMenuOpenKey="hrm"
      siteMenuHidden
      siteMenuSelectedParentKey="/leaveapprove/approval"
    ></PageHeader>
  );
};

export default LeaveApprovalFormHeader;
