import BaseCollection from 'common/collections/BaseCollection';
import ProjectTaskCommentModel from 'common/models/ProjectTaskCommentModel';
import {
    ProjectTaskCommentCollectionJson, ProjectTaskCommentJson
} from 'common/types/ProjectTaskComment';

class ProjectTaskCommentCollection extends BaseCollection<
  ProjectTaskCommentJson,
  ProjectTaskCommentModel,
  ProjectTaskCommentCollectionJson
> {
  itemsFromJson(
    jsonItems: ProjectTaskCommentJson[]
  ): ProjectTaskCommentModel[] {
    return jsonItems.map((item) => new ProjectTaskCommentModel(item));
  }
}

export default ProjectTaskCommentCollection;
