import { FormInstance } from "antd";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import FormSelect from "components/form/FormSelect";
import ShippingOrderContext from "contexts/ShippingOrderContext";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ShippingOrderFormSectionHow = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation();
  const shippingOrderProvider = useContext(ShippingOrderContext);

  useEffect(() => {
    form.setFieldsValue({ km: shippingOrderProvider.totalDistance });
  }, [form, shippingOrderProvider.totalDistance]);

  return (
    <>
      <FormSelect
        label={t("shippingorder:status")}
        name="status"
        options={ShippingOrderModel.getStatusList()}
        className="mb-0"
      />
    </>
  );
};

export default ShippingOrderFormSectionHow;
