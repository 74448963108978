import Role from "common/constants/Role";
import { InventoryStockNumberType } from "common/types/InventoryStock";
import { TableColumnsType, TableColumnToggleType } from "common/types/Table";
import { WarehouseJson } from "common/types/Warehouse";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import useDatabaseTable from "hooks/useDatabaseTable";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import InventoryBySkuCell from "./InventoryBySkuCell";
import InventoryBySkuFilter from "./InventoryBySkuFilter";
import InventoryBySkuHeader from "./InventoryBySkuHeader";
import TableInfo from "components/table/TableInfo";
import ProductRepository from "common/repositories/ProductRepository";
import { FilterProduct } from "common/types/Product";
import ProductPublicCollection from "common/collections/ProductPublicCollection";
import ProductPublicModel from "common/models/ProductPublicModel";
import { FileJson } from "common/types/File";
import { IconEye } from "@tabler/icons-react";
import { Image } from "antd";
import NoPhoto from "components/NoPhoto";
import WarehouseModel from "common/models/WarehouseModel";
import WarehouseRepository from "common/repositories/WarehouseRepository";
import WarehouseCollection from "common/collections/WarehouseCollection";

const InventoryBySku = () => {
	const { t } = useTranslation();

	const [total, setTotal] = useState(0);

	//for fetching inventorystock
	const allowWarehouseIdList = useLoginAccountStore((state) =>
		state.getRoleObjectList(Role.INVENTORY_NUMBER)
	);
	// const [warehouseItems] = useDatabaseTable<WarehouseJson>("warehouse");
	const [warehouseItems, setWarehouseItems] = useState<WarehouseModel[]>([]);

	const fetchDataWarehouse =
	useCallback(async (): Promise<WarehouseCollection> => {
		let collection = await new WarehouseRepository().getItems({
			filters: WarehouseRepository.getDefaultFilters(),
		});
		setWarehouseItems(collection.items);

		return collection;
	}, []);

useEffect(() => {
	fetchDataWarehouse();
}, [fetchDataWarehouse]);
	const [stockType, setStockType] =
		useState<InventoryStockNumberType>("available");

	const defaultFilters: FilterProduct = useMemo(
		() => ProductRepository.getDefaultFilters(),
		[]
	);
	const [filters, setFilters] = useStateFilter<FilterProduct>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	//////////////////////////////////////////
	//Fetch data from this collections
	const [dataSource, setDataSource] = useState<ProductPublicModel[]>([]);
	const fetchData = useCallback(async (): Promise<ProductPublicCollection> => {
		let collection = await new ProductRepository().getItemsPublic({
			filters: { ...filters, store_id: 0, parent_id: -1 , limit : 10 },
		});
		setDataSource(collection.items);
		setTotal(collection.total);

		return collection;
	}, [filters]);

	//Table columns
	const columns: TableColumnsType<ProductPublicModel> = [
		{
			title: t("product:image_short"),
			key: "images",
			align: "center",
			width: 40,
			fixed: "left",

			render: (images: FileJson[], record) => {
				return images.length > 0 ? (
					<div onClick={(e) => e.stopPropagation()}>
						<Image
							width="40"
							src={images[0].url}
							preview={{
								src: images[0].url,
								mask: <IconEye size="16" />,
							}}
						/>
					</div>
				) : (
					<NoPhoto width={40} height={40} />
				);
			},
		},
		{
			title: t("product:name"),
			key: "full_name",
			width: 200,
			fixed: "left",
			render: (full_name: string, record) => {
				return (
					<>
						<p title={full_name} className=" line-clamp-2">{full_name}</p>
					</>
				);
			},
		},
		{
			title: t("productvariant:sku"),
			key: "sku",
			width: 100,
			fixed: "left",
			render: (sku: string, record) => {
				return (
					<>
						<div className=" font-bold">{sku}</div>
					</>
				);
			},
		},

		...warehouseItems
			.filter(
				(item) =>
					allowWarehouseIdList.length === 0 ||
					allowWarehouseIdList.includes(item.id)
			)
			.map((item) => {
				return {
					title: <div className="text-center text-blue-900">{item.name}</div>,
					key: "",
					className: "bg-gray-50",
					width: 120,
					toggletype: "ignore" as TableColumnToggleType,
					render: (_: any, record: ProductPublicModel) => {
						return (
							<div className="text-center">
								<InventoryBySkuCell
									type={stockType}
									data={record.quantities}
									activeWarehouseId={item.id}
								/>
							</div>
						);
					},
				};
			}),

		{
			title: " ",
			toggletype: "trigger",
			key: "actions",
			align: "right",
			fixed: "right",
			width: 40,
			render: (id: number, record) => {
				return (
					<>
						<TableInfo record={record}></TableInfo>
					</>
				);
			},
		},
	];

	return (
		<>
			<InventoryBySkuHeader />

			<div className=" mb-10">
				<InventoryBySkuFilter
					filters={filters}
					setFilters={setFilters}
					defaultFilters={defaultFilters}
					total={total}
					stockType={stockType}
					setStockType={setStockType}
				/>
			</div>

			<PageDataTable<FilterProduct, ProductPublicModel, ProductPublicCollection>
				{...{
					columns,
					defaultFilters,
					filters,
					setFilters,
					dataSource,
					fetchData,
					tableColumnToggleKey: "inventorybysku",
				}}></PageDataTable>

			<PageDataPagination
				total={total}
				filters={filters}
				setFilters={setFilters}
				dataSource={dataSource}
			/>
		</>
	);
};

export default InventoryBySku;
