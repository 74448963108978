import { Form, Input } from "antd";
import { SelectValue } from "antd/es/select";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import { Filter } from "common/types/Filter";
import { RegionJson } from "common/types/Region";
import Helper from "common/utils/helper";
import DateFormFilter from "components/datetime/DateFormFilter";
import TimeFormFilter from "components/datetime/TimeFormFilter";
import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";
import dayjs from "dayjs";
import dbm from "dbm";
import RegionFormFilter from "features/region/RegionFormFilter";
import ShippingHubFormFilter from "features/shippinghub/ShippingHubFormFilter";
import ShippingRouteFormFilter from "features/shippingroute/ShippingRouteFormFilter";
import ShippingTypeFormFilter from "features/shippingtype/ShippingTypeFormFilter";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const DeliveryPlanningListFilter = <F extends Filter>({
  filters,
  setFilters,
  defaultFilters,
  total,
}: {
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
  total: number;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [regionId, setRegionId] = useState<number>(0);
  const [regionItems, setRegionItems] = useState<RegionJson[]>([]);

  const getYmdInFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "ymd")
      .map(([key, val]) => {
        return val;
      }) as number[];

    return entries[0] > 0
      ? dayjs(
          dayjs(Helper.checkDateString(entries[0]), "YYYYMMDD").format(
            "DD/MM/YYYY",
          ),
          "DD/MM/YYYY",
        )
      : undefined;
  }, [filters]);

  const getHourStartInFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "hourstart")
      .map(([key, val]) => {
        return val;
      }) as number[];

    return entries[0] >= 0
      ? dayjs(Helper.checkTimeString(entries[0]), "HH:mm")
      : undefined;
  }, [filters]);

  const getHourEndInFilter = useMemo(() => {
    let entries = Object.entries(filters)
      .filter(([key, val]) => key === "hourend")
      .map(([key, val]) => {
        return val;
      }) as number[];

    return entries[0] >= 0
      ? dayjs(Helper.checkTimeString(entries[0]), "HH:mm")
      : undefined;
  }, [filters]);

  useEffect(() => {
    let items = dbm.getCollection("region")?.chain().data();
    if (
      typeof items !== "undefined" &&
      items.hasOwnProperty("length") &&
      items.length > 0
    ) {
      setRegionItems(items);
    }
  }, []);

  return (
    <PageDataFilterForm
      total={total}
      filters={filters}
      setFilters={setFilters}
      defaultFilters={defaultFilters}
      extra={false}>
      <Form.Item name="keyword">
        <Input
          allowClear
          placeholder={t("deliveryplanning:filter_keyword_placeholder")}
        />
      </Form.Item>

      <ShippingTypeFormFilter
        name="shipping_type"
        allowClear
        placeholder={t("deliveryplanning:filter_shipping_type_placeholder")}
        popupMatchSelectWidth={false}
      />

      <ShippingHubFormFilter
        name="office_id"
        allowClear
        placeholder={t("deliveryplanning:filter_hub_placeholder")}
        popupMatchSelectWidth={false}
      />

      <ShippingRouteFormFilter
        name="route_id"
        allowClear
        placeholder={t("deliveryplanning:filter_route_id_placeholder")}
        displayPickup={true}
        popupMatchSelectWidth={false}
      />

      <RegionFormFilter
        name="order_shipping_region"
        allowClear
        popupMatchSelectWidth={false}
        defaultOptions={regionItems
          .filter((i) => i.parent_id === 0)
          .sort((a, b) => {
            return a.display_order > b.display_order ? 1 : -1;
          })
          .map((i) => ({
            value: i.id,
            label: i.name,
          }))}
        placeholder={t("deliveryplanning:filter_region_id_placeholder")}
        onChange={(v: SelectValue) => {
          setRegionId(typeof v === "number" ? +v : 0);
          form.setFieldValue("order_shipping_subregion", null);
        }}
      />

      {regionId > 0 ? (
        <RegionFormFilter
          name="order_shipping_subregion"
          popupMatchSelectWidth={false}
          defaultOptions={regionItems
            .filter((i) => i.parent_id === regionId)
            .sort((a, b) => {
              return a.display_order > b.display_order ? 1 : -1;
            })
            .map((i) => ({
              value: i.id,
              label: i.name,
            }))}
          placeholder={t("deliveryplanning:filter_sub_region_id_placeholder")}
        />
      ) : null}

      <FormSelect
        name="status_asigned_route"
        options={ShippingOrderModel.getStatusAssignedList()}
        allowClear
        placeholder={t(
          "deliveryplanning:filter_status__assigned_route_placeholder",
        )}
        popupMatchSelectWidth={false}
      />

      {/* FILTER: YMD */}
      <Form.Item
        name="ymd"
        valuePropName="ymd"
        label={t("deliveryplanning:filter_ymd")}
        normalize={(value, prevValue) => {
          return dayjs(value).format("YYYYMMDD");
        }}>
        <DateFormFilter
          value={getYmdInFilter}
          allowClear={false}
          showNow={false}
          mode="single"
          format="DD/MM/YYYY"
          placeholder={t("deliveryplanning:choose_date")}
        />
      </Form.Item>

      {/* FILTER: HOURSTART AND HOUREND */}
      <Form.Item
        label={t("deliveryplanning:filter_hour")}
        style={{ marginBottom: 0 }}>
        <Form.Item
          name="hourstart"
          valuePropName="hourstart"
          normalize={(value, prevValue) => {
            return dayjs(value).format("HHmm");
          }}
          style={{ display: "inline-block", marginRight: "0px" }}>
          <TimeFormFilter
            value={getHourStartInFilter}
            allowClear={false}
            showNow={false}
            mode="single"
            format="HH:mm"
            placeholder={t("deliveryplanning:choose_hour")}
          />
        </Form.Item>
        <span
          style={{
            display: "inline-block",
            width: "24px",
            lineHeight: "32px",
            textAlign: "center",
          }}>
          -
        </span>
        <Form.Item
          name="hourend"
          valuePropName="hourend"
          normalize={(value, prevValue) => {
            return dayjs(value).format("HHmm");
          }}
          style={{ display: "inline-block", marginRight: "0px" }}>
          <TimeFormFilter
            value={getHourEndInFilter}
            allowClear={false}
            showNow={false}
            mode="single"
            format="HH:mm"
            placeholder={t("deliveryplanning:choose_hour")}
          />
        </Form.Item>
      </Form.Item>
    </PageDataFilterForm>
  );
};

export default DeliveryPlanningListFilter;
