import { Col, Row } from "antd";
import { DateRange } from "common/types/BiCenter";
import { StoreJson } from "common/types/Store";
import TextMoney from "components/TextMoney";
import useDatabaseTable from "hooks/useDatabaseTable";
import React, { useCallback } from "react";

import ReportChartDataTable from "../chart/ReportChartDataTable";
import ReportChartTimeSeries from "../chart/ReportChartTimeSeries";

const ReportSaleStore = ({
  getRange,
  getRangeCompare
}: {
  getRange: () => DateRange;
  getRangeCompare: () => DateRange | null;
}) => {
  const [storeItemsWithLimit] = useDatabaseTable<StoreJson>("store");
  const formatterStoreValue = useCallback(
    (value: number) => {
      return storeItemsWithLimit.find((i) => i.id === value)?.name;
    },
    [storeItemsWithLimit]
  );

  return (
    <>
      <div className="p-6">
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartTimeSeries
              className="p-4"
              title={"Giá trị đơn hàng theo cửa hàng"}
              dataTable="order"
              dataService="order"
              dataSelect="sum(pricefinal)"
              dataFilter=""
              dataGroupBy=""
              dataOrderBy=""
              keyMapping={{ name: "comparetext", value: "sum_pricefinal" }}
              height={350}
              dataInterval="day"
              dateRange={getRange()}
              dateRangeCompare={getRangeCompare()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo bán hàng theo cửa hàng"}
              subtitle="Lấy tất cả trạng thái đơn hàng"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng giá trị đơn hàng",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Tổng số đơn hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(pricefinal)"
              dataFilter=""
              dataGroupBy="store"
              dataOrderBy="sum(pricefinal) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>

        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo theo cửa hàng dựa trên đơn hoàn thành"}
              subtitle="Chỉ tính các đơn hàng có trạng thái hoàn thành"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng giá trị đơn hàng",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Tổng số đơn hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(pricefinal)"
              dataFilter="status = 19"
              dataGroupBy="store"
              dataOrderBy="sum(pricefinal) DESC"
              dateRange={getRange()}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <ReportChartDataTable
              className="p-4"
              title={"Báo cáo theo cửa hàng dựa trên đơn đã hủy"}
              subtitle="Chỉ tính các đơn hàng có trạng thái hủy"
              dataTable="order"
              dataService="order"
              keyMapping={[
                {
                  title: "Cửa hàng",
                  name: "store",
                  formatter: (value: number | string) => {
                    return <>{formatterStoreValue(+value)}</>;
                  }
                },
                {
                  title: "Tổng giá trị đơn hàng",
                  name: "sum_pricefinal",
                  formatter: (value: number | string) => {
                    return (
                      <>
                        <TextMoney money={value} />
                      </>
                    );
                  },
                  sortField: "sum(pricefinal)"
                },
                {
                  title: "Tỏng số đơn hàng",
                  name: "count_id",
                  formatter: (value: number | string) => {
                    return <>{value}</>;
                  },
                  sortField: "count(id)"
                }
              ]}
              dataSelect="count(id), store, sum(pricefinal)"
              dataFilter="status = 21"
              dataGroupBy="store"
              dataOrderBy="sum(pricefinal) DESC"
              dateRange={getRange()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportSaleStore;
