import { App, Col, Form, Input, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import DataGridTableField from "common/constants/DataGridTableField";
import DataGridTableFieldModel from "common/models/DataGridTableFieldModel";
import DataGridTableFieldRepository from "common/repositories/DataGridTableFieldRepository";
import { DataGridTableFieldJsonAddEdit } from "common/types/DataGridTableField";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import TagStringInput from "components/TagStringInput";
import FormChangedContext from "contexts/FormChangedContext";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const DataGridTableFieldForm = ({
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  model: DataGridTableFieldModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: DataGridTableFieldModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const formChangedProvider = useContext(FormChangedContext);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    return {
      name: model.name,
      datagrid_table_id: model.datagrid_table_id,
      display_order: model.display_order,
      type: model.type > 0 ? model.type : DataGridTableField.TYPE_TEXT,
      width: model.width,
      is_fixed: model.is_fixed,
      is_primary: model.is_primary,
      is_readonly: false,
      value_options: model.value_options
    };
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: DataGridTableFieldJsonAddEdit = {
        id: model.id,
        datagrid_table_id: model.datagrid_table_id,
        display_order: model.display_order,
        name: formData.name,
        type: formData.type,
        is_primary: formData.is_primary,
        is_readonly: formData.is_readonly,
        value_options: formData.value_options,
        width: formData.width,
        is_fixed: formData.is_fixed
      };

      return submitData;
    },
    [model.id, model.datagrid_table_id, model.display_order]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: DataGridTableFieldModel =
      await new DataGridTableFieldRepository().saveRemote(
        doPrepareData(formData)
      );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="datagridtablefield:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id={formChangedProvider.id}
      onFieldsChange={() => formChangedProvider.setChanged(true)}>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("datagridtablefield:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("datagridtablefield:form.error.error_name_required")
              }
            ]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col md={16} xs={24}>
          <FormSelect
            showSearch
            label={t("datagridtablefield:type")}
            name="type"
            required
            options={DataGridTableFieldModel.getTypeList()}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }>
            {({ getFieldValue }) =>
              getFieldValue("type") === DataGridTableField.TYPE_SINGLE_SELECT ||
              getFieldValue("type") === DataGridTableField.TYPE_MULTI_SELECT ? (
                <FormItem
                  name={"value_options"}
                  label={t("datagridtablefield:value_options")}>
                  <TagStringInput />
                </FormItem>
              ) : null
            }
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DataGridTableFieldForm;
