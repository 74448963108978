import BaseCollection from "common/collections/BaseCollection";
import LeaveStepModel from "common/models/LeaveStepModel";
import { LeaveStepCollectionJson, LeaveStepJson } from "common/types/LeaveStep";

class LeaveStepCollection extends BaseCollection<
  LeaveStepJson,
  LeaveStepModel,
  LeaveStepCollectionJson
> {
  itemsFromJson(jsonItems: LeaveStepJson[]): LeaveStepModel[] {
    return jsonItems.map((item) => new LeaveStepModel(item));
  }
}

export default LeaveStepCollection;
