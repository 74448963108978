import { Col, Menu, Modal, Row, Typography } from "antd";
import SiteMenuRepository from "common/repositories/SiteMenuRepository";
import DataGridMainNavigation from "features/datagrid/DataGridMainNavigation";
import useBreakpoint from "hooks/useBreakpoint";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useCallback, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import useMenuStore from "zustands/useMenuStore";

import MainNavigationItem from "./MainNavigationItem";
import { useTranslation } from "react-i18next";

const MainNavigationWrapper = () => {
	const pageHeaderHeight = 60;
	const containerFilterFormRef = useRef<any>(null);

	//get screen height
	const { height } = useWindowDimensions();
	const { t } = useTranslation();
	const selectedKeys = useMenuStore((state) => state.selectedKeys);

	const [openKeys, setOpenKeys] = useMenuStore((state) => [
		state.openKeys,
		state.setOpenKeys,
	]);

	const [showOnSmall, setShowOnSmall] = useMenuStore((state) => [
		state.showOnSmall,
		state.setShowOnSmall,
	]);
	const [isSmall] = useBreakpoint();
	const minimizeMenu = useMenuStore((state) => state.minimize);

	const activeGroup = useMenuStore((state) => state.activeGroup);

	const account = useLoginAccountStore((state) => state.account);

	////////////////////////////////
	const [openWS, setOpenWs] = useState(false);

	////////////////////////////////

	const dataGridCom = useMemo(() => {
		return <DataGridMainNavigation source="submenu" />;
	}, []);
	//mapping function to allow recursive and change label from string to Link
	const map: any = ({ children = [], ...o }) => ({
		...o,
		label:
			Array.isArray(children) && children.length > 0 ? (
				o.label
			) : o.key !== "/datagridworkspace" ? (
				<Link
					to={o.key}
					onClick={() => {
						//hide menu when click on mobile
						if (isSmall && showOnSmall) {
							setShowOnSmall(false);
						}
					}}>
					{o.label}
				</Link>
			) : (
				<Typography.Link onClick={() => setOpenWs(true)}>
					{o.label}
				</Typography.Link>
			),
		children:
			Array.isArray(children) && children.length > 0
				? children.map(map)
				: undefined, // recursive call
	});

	const getSubMenu = useCallback(
		(key: string) => {
			return (
				SiteMenuRepository.getSiteMenuWithRoleCheck(account).find(
					(i: any) => i.key === key
				)?.children || []
			);
		},
		[account]
	);

	return (
		<>
			<div
				style={{
					height: height - pageHeaderHeight,
					overflow: "hidden",
				}}
				className="bg-gray-600">
				<Row className="h-full">
					<Col
						flex={"80px"}
						className="px-2 h-full pt-2 overflow-y-auto overflow-x-hidden hide_scrollbar"
						style={{
							backgroundColor: "#0168fa",
							height: height - pageHeaderHeight,
						}}>
						{SiteMenuRepository.getSiteMenuWithRoleCheck(account).map((i) => (
							<MainNavigationItem
								key={i.key}
								menuItem={i}
								getSubMenu={(key) => getSubMenu(key).map(map)}
								popupContainer={containerFilterFormRef.current}
							/>
						))}
					</Col>
					{minimizeMenu && !isSmall ? null : (
						<Col flex={"240px"}>
							<Scrollbars
								style={{
									height: height - pageHeaderHeight,
									overflow: "hidden",
								}}
								className="bg-gray-600"
								autoHide>
								{activeGroup === "datagrid" ? (
									dataGridCom
								) : (
									<Menu
										className="main-navigation w-full"
										selectedKeys={selectedKeys}
										openKeys={openKeys}
										onOpenChange={(v) => {
											setOpenKeys(v);
										}}
										mode="inline"
										triggerSubMenuAction="click"
										items={getSubMenu(activeGroup).map<any>(map)}
										style={{
											background: "transparent",
											maxWidth: 240,
											width: 240,
										}}
										theme="dark"
									/>
								)}
							</Scrollbars>
						</Col>
					)}
				</Row>
			</div>
			<div ref={containerFilterFormRef}></div>
			<Modal
				title={t("sitemenu:datagrid.createworkspace")}
				closable={false}
				open={openWS}
				bodyStyle={{ backgroundColor: "rgb(75 85 99 / 1" }}
				onCancel={() => setOpenWs(false)}
				okButtonProps={{ style: { display: "none" } }}>
				<DataGridMainNavigation source="tooltip" />
			</Modal>
		</>
	);
};

export default MainNavigationWrapper;
