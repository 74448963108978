import BiCenterModel from "common/models/BiCenterModel";
import BiCenterRepository from "common/repositories/BiCenterRepository";
import { DateRange, FilterBiCenter } from "common/types/BiCenter";
import dayjs from "dayjs";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReportDateRangePicker from "../ReportDateRangePicker";
import ReportViewHeader from "../view/ReportViewHeader";
import ReportInventoryInputGeneral from "./input/ReportInventoryInputGeneral";
import ReportInventoryInputProduct from "./input/ReportInventoryInputProduct";
import ReportInventoryInputProductCategory from "./input/ReportInventoryInputProductCategory";
import ReportInventoryInputSupplier from "./input/ReportInventoryInputSupplier";
import ReportInventoryInputType from "./input/ReportInventoryInputType";
import ReportInventoryInputVender from "./input/ReportInventoryInputVender";
import ReportInventoryInputWarehouse from "./input/ReportInventoryInputWarehouse";
import ReportInventoryOutputGeneral from "./output/ReportInventoryOutputGeneral";
import ReportInventoryOutputProduct from "./output/ReportInventoryOutputProduct";
import ReportInventoryOutputProductCategory from "./output/ReportInventoryOutputProductCategory";
import ReportInventoryOutputSupplier from "./output/ReportInventoryOutputSupplier";
import ReportInventoryOutputType from "./output/ReportInventoryOutputType";
import ReportInventoryOutputVender from "./output/ReportInventoryOutputVender";
import ReportInventoryOutputWarehouse from "./output/ReportInventoryOutputWarehouse";
import ReportInventoryGeneral from "./ReportInventoryGeneral";

const ReportInventoryWrapper = ({
  siteMenuOpenKey,
  siteMenuSelectedKey
}: {
  siteMenuOpenKey: string;
  siteMenuSelectedKey: string;
}) => {
  const { t } = useTranslation();

  const defaultFilters: FilterBiCenter = useMemo(
    () => BiCenterRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] = useStateFilter<FilterBiCenter>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  const getPathNameParts = useMemo(() => {
    let pathNameParts = siteMenuSelectedKey.split("/");
    //discard first item (empty string)
    pathNameParts.shift();
    //discard controller item
    pathNameParts.shift();
    //discard action item
    pathNameParts.shift();
    return pathNameParts;
  }, [siteMenuSelectedKey]);

  const getNavByPathLocation = useMemo(() => {
    const nav = [
      {
        text: t("report:heading_view")
      },
      {
        text: t("report:heading_report_inventory")
      }
    ];
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          nav.push({
            text: t("report:heading_report_inventory_general")
          });
          break;
      }
    }

    if (getPathNameParts.length === 2) {
      if (getPathNameParts[0] === "input") {
        nav.push({
          text: t("report:heading_report_inventory_input")
        });
        switch (getPathNameParts[1]) {
          case "general":
            nav.push({
              text: t("report:heading_report_inventory_input_general")
            });
            break;
          case "warehouse":
            nav.push({
              text: t("report:heading_report_inventory_input_warehouse")
            });
            break;
          case "type":
            nav.push({
              text: t("report:heading_report_inventory_input_type")
            });
            break;
          case "productcategory":
            nav.push({
              text: t("report:heading_report_inventory_input_product_category")
            });
            break;
          case "product":
            nav.push({
              text: t("report:heading_report_inventory_input_product")
            });
            break;
          case "supplier":
            nav.push({
              text: t("report:heading_report_inventory_input_supplier")
            });
            break;
          case "vender":
            nav.push({
              text: t("report:heading_report_inventory_input_vender")
            });
            break;
        }
      }
      if (getPathNameParts[0] === "output") {
        nav.push({
          text: t("report:heading_report_inventory_output")
        });
        switch (getPathNameParts[1]) {
          case "general":
            nav.push({
              text: t("report:heading_report_inventory_output_general")
            });
            break;
          case "warehouse":
            nav.push({
              text: t("report:heading_report_inventory_output_warehouse")
            });
            break;
          case "type":
            nav.push({
              text: t("report:heading_report_inventory_output_type")
            });
            break;
          case "productcategory":
            nav.push({
              text: t("report:heading_report_inventory_output_product_category")
            });
            break;
          case "product":
            nav.push({
              text: t("report:heading_report_inventory_output_product")
            });
            break;
          case "supplier":
            nav.push({
              text: t("report:heading_report_inventory_output_supplier")
            });
            break;
          case "vender":
            nav.push({
              text: t("report:heading_report_inventory_output_vender")
            });
            break;
        }
      }
    }
    return nav;
  }, [t, getPathNameParts]);

  const getHeadingByPathLocation = useMemo(() => {
    let heading = "";
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          heading = "Báo cáo phiếu nhập xuất kho tổng quan";
          break;
      }
    }
    if (getPathNameParts.length === 2) {
      if (getPathNameParts[0] === "input") {
        switch (getPathNameParts[1]) {
          case "general":
            heading = "Báo cáo tổng quan nhập kho";
            break;
          case "warehouse":
            heading = "Báo cáo nhập kho theo nhà kho";
            break;
          case "type":
            heading = "Báo cáo nhập kho theo loại";
            break;
          case "productcategory":
            heading = "Báo cáo nhập kho theo danh mục sản phẩm";
            break;
          case "product":
            heading = "Báo cáo nhập kho theo Sản phẩm";
            break;
          case "supplier":
            heading = "Báo cáo nhập kho theo nhà cung cấp";
            break;
          case "vender":
            heading = "Báo cáo nhập kho theo thương hiệu";
            break;
        }
      }
      if (getPathNameParts[0] === "output") {
        switch (getPathNameParts[1]) {
          case "general":
            heading = "Báo cáo tổng quan xuất kho";
            break;
          case "warehouse":
            heading = "Báo cáo xuất kho theo nhà kho";
            break;
          case "type":
            heading = "Báo cáo xuất kho theo loại";
            break;
          case "productcategory":
            heading = "Báo cáo xuất kho theo danh mục sản phẩm";
            break;
          case "product":
            heading = "Báo cáo xuất kho theo Sản phẩm";
            break;
          case "supplier":
            heading = "Báo cáo xuất kho theo nhà cung cấp";
            break;
          case "vender":
            heading = "Báo cáo xuất kho theo thương hiệu";
            break;
        }
      }
    }

    return heading;
  }, [getPathNameParts]);

  const onChangeDateRange = (
    value: DateRange,
    value_compare: DateRange | null
  ) => {
    setFilters({
      ...filters,
      start: BiCenterModel.momentToString(value[0]),
      end: BiCenterModel.momentToString(value[1]),
      ...(value_compare
        ? {
            startcompare: BiCenterModel.momentToString(value_compare[0]),
            endcompare: BiCenterModel.momentToString(value_compare[1])
          }
        : { startcompare: "", endcompare: "" })
    });
  };

  const getRange = useCallback((): DateRange => {
    if (filters.start.length > 0 && filters.end.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.start),
        BiCenterModel.stringToMoment(filters.end)
      ];
    } else {
      //default: last30days
      return [
        dayjs().subtract(30, "days").startOf("day"),
        dayjs().subtract(1, "days").endOf("day")
      ];
    }
  }, [filters.start, filters.end]);

  const getRangeCompare = useCallback((): DateRange | null => {
    if (filters.startcompare.length > 0 && filters.endcompare.length > 0) {
      return [
        BiCenterModel.stringToMoment(filters.startcompare),
        BiCenterModel.stringToMoment(filters.endcompare)
      ];
    } else {
      //user already set date start by MANUAL,
      //so, do not return default with compare (disable compare)
      if (filters.start.length > 0 && filters.end.length > 0) {
        return null;
      } else {
        //default: previousperiod of last30days
        return [
          dayjs().subtract(60, "days").startOf("day"),
          dayjs().subtract(31, "days").endOf("day")
        ];
      }
    }
  }, [filters.startcompare, filters.endcompare, filters.start, filters.end]);

  const getComponentByPathLocation = useMemo(() => {
    let com = null;
    if (getPathNameParts.length === 1) {
      switch (getPathNameParts[0]) {
        case "general":
          com = (
            <ReportInventoryGeneral
              getRange={getRange}
              getRangeCompare={getRangeCompare}
            />
          );
          break;
      }
    }
    if (getPathNameParts.length === 2) {
      if (getPathNameParts[0] === "input") {
        switch (getPathNameParts[1]) {
          case "general":
            com = (
              <ReportInventoryInputGeneral
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "warehouse":
            com = (
              <ReportInventoryInputWarehouse
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "type":
            com = (
              <ReportInventoryInputType
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "productcategory":
            com = (
              <ReportInventoryInputProductCategory
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "product":
            com = (
              <ReportInventoryInputProduct
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "supplier":
            com = (
              <ReportInventoryInputSupplier
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "vender":
            com = (
              <ReportInventoryInputVender
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
        }
      }
      if (getPathNameParts[0] === "output") {
        switch (getPathNameParts[1]) {
          case "general":
            com = (
              <ReportInventoryOutputGeneral
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "warehouse":
            com = (
              <ReportInventoryOutputWarehouse
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "type":
            com = (
              <ReportInventoryOutputType
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "productcategory":
            com = (
              <ReportInventoryOutputProductCategory
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "product":
            com = (
              <ReportInventoryOutputProduct
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "supplier":
            com = (
              <ReportInventoryOutputSupplier
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
          case "vender":
            com = (
              <ReportInventoryOutputVender
                getRange={getRange}
                getRangeCompare={getRangeCompare}
              />
            );
            break;
        }
      }
    }

    return com;
  }, [getPathNameParts, getRange, getRangeCompare]);

  return (
    <>
      <ReportViewHeader
        heading={getHeadingByPathLocation}
        nav={getNavByPathLocation}
        siteMenuOpenKey={siteMenuOpenKey}
        siteMenuSelectedKey={siteMenuSelectedKey}>
        <ReportDateRangePicker
          range={getRange()}
          range_compare={getRangeCompare()}
          onChange={onChangeDateRange}
          format="DD/MM/YYYY"
        />
      </ReportViewHeader>
      {getComponentByPathLocation}
    </>
  );
};

export default ReportInventoryWrapper;
