import React, { useEffect } from "react";
import { Space, Typography } from "antd";
import useDatabaseTable from "hooks/useDatabaseTable";

import Helper from "common/utils/helper";
import { EmployeeJson } from "common/types/Employee";
import NotificationModel from "common/models/NotificationModel";

import "./notificationDetail.css";

type Props = {
  model: NotificationModel;
};

const NotificationDetail = React.memo(({ model }: Props) => {
  //state
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");

  const employeeFilteredOptions =
    (employeeItems &&
      employeeItems
        .filter((o) => o.id > 0)
        .map((e) => {
          return {
            value: e.id,
            label: e.full_name,
          };
        })) ||
    [];

  useEffect(() => {
    const detail_wrapper = document.getElementById("meta_detail");
    const div = document.createElement("div");
    div.style.width = "100%";
    div.style.height = "100%";
    div.style.padding = "1rem 1rem 0 1rem";
    div.style.textAlign = "left";
    div.className = "ql-editor";
    if (model.id > 0) {
      div.innerHTML = model.meta_detail;
      div.innerHTML += `<div class="ant-divider css-dev-only-do-not-override-1lehumu ant-divider-horizontal ant-divider-with-text ant-divider-with-text-center" role="separator"><span class="ant-divider-inner-text">Hết</span></div>`;
      detail_wrapper?.appendChild(div);
    } else {
    }
    return () => {
      if (div.innerHTML !== "") {
        detail_wrapper?.removeChild(div);
      }
    };
  }, [model.id]);

  return (
    <>
      <Space className="block w-full text-center h-full relative">
        <div className="pr-4 mb-4">
          <Typography.Title level={4}>{model.meta_title}</Typography.Title>
          <Typography.Text
            style={{ fontSize: "12px", color: "#898889", display: "block" }}
          >
            {
              employeeFilteredOptions?.find(
                (emp: any) => emp.value === model.creator_id
              )?.label
            }
            {" - "}
            {Helper.convertedTimestampToDate(model.date_created)}
          </Typography.Text>
          <Typography.Text
            style={{ fontSize: "14px", display: "block" }}
            className="overflow-text"
            strong
          >
            {model.meta_summary}
          </Typography.Text>
        </div>

        <div
          id="meta_detail"
          className={`relative`}
          style={{
            width: "100%",
            height: "100%",
            minHeight: "30vh",
            maxHeight: "40vh",
            overflowY: "scroll",
          }}
        ></div>
      </Space>
    </>
  );
});

export default NotificationDetail;
