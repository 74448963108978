import CashflowReceipt from "common/constants/CashflowReceipt";
import Customer from "common/constants/Customer";
import Promotion from "common/constants/Promotion";
import {
	PosCart,
	PosCartCustomer,
	PosCartCustomerPoint,
	PosCartDelivery,
	PosCartOrderDetail,
	PosCartPayment,
	PosCartPrice,
	PosCartPromotionOrder,
	PosCartPromotionOrderRange,
	PosCartPromotionProductDetail,
} from "common/types/PosCart";
import { StoreJson } from "common/types/Store";
import dayjs from "dayjs";
import _ from "lodash";
import { v4 as uuid } from "uuid";

class PosCartModel {
	static calculatePrices(cart: PosCart): PosCartPrice {
		let priceSell = 0;
		//currently not use ^^!
		let priceHandling = 0;
		let priceDiscount = 0;
		let priceDiscountByCoupon = 0;
		let priceDiscountByPromotion = 0;
		let priceDiscountByManual = cart.manual_discount ? cart.price_discount : 0;
		let priceFinal = 0;
		let quantity = 0;

		//shipping price copy from cart info, not calculate ^^!
		let priceShipping = cart.price_shipping;

		//calculate subtotal & total quantity
		cart.details.forEach((item: PosCartOrderDetail) => {
			const itemSubTotal = item.price * item.item_quantity;
			priceSell += itemSubTotal;
			quantity += item.item_quantity;
		});

		if (cart.manual_discount) {
			priceDiscount = priceDiscountByManual;
		} else {
			//calculate discount from coupons
			cart.coupons.forEach((couponCheckResult) => {
				if (
					typeof couponCheckResult?.promotion !== "undefined" &&
					!Array.isArray(couponCheckResult?.promotion.value)
				) {
					//apply percent value first
					if (
						couponCheckResult.promotion?.value_type ===
						Promotion.VALUETYPE_PERCENT
					) {
						priceDiscountByCoupon += Math.floor(
							(couponCheckResult.promotion?.value / 100) * priceSell
						);
					} else if (
						couponCheckResult.promotion?.value_type ===
						Promotion.VALUETYPE_CURRENCY
					) {
						priceDiscountByCoupon += Math.floor(
							couponCheckResult.promotion?.value
						);
					}
				}
			});

			//calculate discount from order promotions
			cart.promotions.forEach((promotion: PosCartPromotionOrder) => {
				priceDiscountByPromotion += this.calculatePromotionDiscount(
					cart,
					priceSell,
					quantity,
					promotion
				);
			});

			//sum price discount
			priceDiscount = priceDiscountByCoupon + priceDiscountByPromotion;
		}

		priceFinal = priceSell + priceHandling - priceDiscount + priceShipping;
		priceFinal = priceFinal >= 0 ? priceFinal : 0;

		return {
			price_sell: priceSell,
			price_handling: priceHandling,
			price_discount: priceDiscount,
			price_discount_by_coupon: priceDiscountByCoupon,
			price_discount_by_promotion: priceDiscountByPromotion,
			price_discount_by_manual: priceDiscountByManual,
			price_shipping: priceShipping,
			price_final: priceFinal,
			quantity,
		};
	}

	static getDefaultCart(activeStore?: StoreJson): PosCart {
		return {
			_cart_name: dayjs().format("#hhmmss"),
			_id: uuid(),
			company_id: 0,
			creator_id: 0,
			note: "",
			tag: "",
			store_id: activeStore?.id || 0,
			warehouse_id: 0,
			customer: this.getDefaultCartCustomer(),
			delivery: this.getDefaultCartDelivery(),
			coupons: [],
			promotions: [],
			product_promotions: [],
			promotion_gifts: [],
			applied_product_promotions: [],
			payments: [],
			manual_discount: false,
			employee_id: 0,
			price_sell: 0,
			price_shipping: 0,
			price_handling: 0,
			price_discount: 0,
			price_final: 0,
			price_debt: 0,
			price_deposit: 0,
			quantity: 0,
			details: [],
			out_of_stock: [],
		};
	}

	static calculatePromotionDiscount(
		cart: PosCart,
		priceSell: number,
		quantity: number,
		promotion: PosCartPromotionOrder
	): number {
		let priceDiscountByPromotion = 0;
		let calculatingMethod = "";

		switch (promotion.type) {
			case Promotion.TYPE_SEASONAL_ORDER_DISCOUNT:
			case Promotion.TYPE_LOYALTY_VIP_DISCOUNT:
			case Promotion.TYPE_LOYALTY_NEW_MEMBER:
				calculatingMethod = "simple";
				break;
			case Promotion.TYPE_SEASONAL_ORDER_BATCH:
				//need to check min_check
				if (quantity >= promotion.min_check) {
					calculatingMethod = "simple";
				}
				break;
			case Promotion.TYPE_SEASONAL_PRODUCT_DISCOUNT:
				calculatingMethod = "seasonal_product_discount";
				break;
			case Promotion.TYPE_SEASONAL_PRODUCT_GIFT:
				calculatingMethod = "seasonal_product_gift";
				break;
			default:
		}

		switch (calculatingMethod) {
			case "simple":
				priceDiscountByPromotion = this.calculatingMethodSimple(
					priceSell,
					promotion
				);
				break;
			case "seasonal_product_discount":
				priceDiscountByPromotion =
					this.calculatingMethodSeasonalProductDiscount(cart, promotion);
				break;
			case "seasonal_product_gift":
				priceDiscountByPromotion = this.calculatingMethodSeasonalProductGift(
					cart,
					promotion
				);
				break;
			default:
		}

		return priceDiscountByPromotion;
	}

	static calculatingMethodSimple(
		priceSell: number,
		promotion: PosCartPromotionOrder
	): number {
		let priceDiscountByPromotion = 0;

		switch (promotion.value_type) {
			case Promotion.VALUETYPE_PERCENT:
				if (typeof promotion.value === "number") {
					priceDiscountByPromotion += Math.floor(
						(promotion.value / 100) * priceSell
					);
				}

				break;
			case Promotion.VALUETYPE_CURRENCY:
				if (typeof promotion.value === "number") {
					priceDiscountByPromotion += Math.floor(promotion.value);
				}
				break;

			case Promotion.VALUETYPE_RANGE:
				//item.value now contains value_range setting data ()
				if (typeof promotion.value === "object") {
					const validRanges: PosCartPromotionOrderRange[] =
						promotion.value.filter((itemrange: PosCartPromotionOrderRange) => {
							return itemrange.from <= priceSell && itemrange.to >= priceSell;
						});
					if (validRanges.length > 0) {
						//only care FIRST range found
						switch (validRanges[0].value_type) {
							case Promotion.VALUETYPERANGE_PERCENT:
								priceDiscountByPromotion += Math.floor(
									(validRanges[0].value / 100) * priceSell
								);
								break;

							case Promotion.VALUETYPERANGE_CURRENCY:
								priceDiscountByPromotion += Math.floor(validRanges[0].value);
								break;

							default:
						}
					}
				}
				break;
			default:
		}

		return priceDiscountByPromotion;
	}

	static calculatingMethodSeasonalProductDiscount(
		cart: PosCart,
		promotion: PosCartPromotionOrder
	): number {
		let priceDiscountByPromotion = 0;

		//loop throught all details and calculate discount value for each detail item
		cart.details.forEach((item: PosCartOrderDetail) => {
			typeof promotion.products !== "undefined" &&
				promotion.products.forEach(
					(promotionProduct: PosCartPromotionProductDetail) => {
						if (promotionProduct.sku === item.sku) {
							//summing discount for this sku
							let priceDiscount = 0;
							switch (promotionProduct.value_type) {
								case Promotion.VALUETYPE_PERCENT:
									priceDiscount =
										Math.floor((promotionProduct.value / 100) * item.price) *
										item.item_quantity;
									break;
								case Promotion.VALUETYPE_CURRENCY:
									priceDiscount =
										Math.floor(promotionProduct.value) * item.item_quantity;
									break;

								default:
							}
							priceDiscountByPromotion += priceDiscount;
						}
					}
				);
		});

		return priceDiscountByPromotion;
	}

	static calculatingMethodSeasonalProductGift(
		cart: PosCart,
		promotion: PosCartPromotionOrder
	): number {
		let priceDiscountByPromotion = 0;
		console.log("promotiongift", promotion);

		//loop throught all details and calculate discount value for each detail item
		let countAffectedQuantity = 0;
		let discountValueList: number[] = [];
		cart.details.forEach((item: PosCartOrderDetail) => {
			typeof promotion.products !== "undefined" &&
				promotion.products.forEach(
					(promotionProduct: PosCartPromotionProductDetail) => {
						if (promotionProduct.sku === item.sku && item.item_quantity > 0) {
							countAffectedQuantity += item.item_quantity;
							//create value to extract discount item
							discountValueList = discountValueList.concat(
								new Array(item.item_quantity).fill(item.price)
							);
						}
					}
				);
		});

		let multiplier = 0;
		//it's a number
		if (typeof promotion.value === "number") {
			multiplier =
				Math.floor(
					countAffectedQuantity / (promotion.min_check + promotion.value)
				) * promotion.value;
		}

		console.log(
			"Affected Count",
			countAffectedQuantity,
			"multiplier",
			multiplier
		);

		if (multiplier >= 1) {
			//sort by discountvalue (so that can get least value to discount)
			discountValueList.sort((a, b) => a - b);

			//get `multiplier` elements from discount value
			// and sum
			priceDiscountByPromotion = discountValueList
				.slice(0, multiplier)
				.reduce((a, b) => a + b, 0);
		}

		return priceDiscountByPromotion;
	}

	static getDefaultCartCustomer(): PosCartCustomer {
		return {
			id: 0,
			full_name: "",
			customer_type_id: 0,
			email: "",
			phone: "",
			code: "",
			gender: Customer.GENDER_UNKNOWN,
			birthday: 0,
			address: "",
			region_id: 0,
			sub_region_id: 0,
			sub_sub_region_id: 0,
			is_new: false,
			count_order: 0,
			point: 0,
		};
	}

	static getDefaultCartCustomerPoint(): PosCartCustomerPoint {
		return {
			point: 0,
			point_exchange: 0,
		};
	}

	static getDefaultCartDelivery(): PosCartDelivery {
		return {
			is_customize: false,
			full_name: "",
			email: "",
			phone: "",
			address: "",
			region_id: 0,
			sub_region_id: 0,
			sub_sub_region_id: 0,
			shipping_carrier_id: 0,
			shipping_carrier_price: 0,
			date_arrived: 0,
		};
	}

	static getOnlinePayments(payments: PosCartPayment[]): PosCartPayment[] {
		let onlinePayments: PosCartPayment[] = [];

		if (payments.length > 0) {
			onlinePayments = _.filter(
				payments,
				(item) =>
					_.indexOf(
						[
							CashflowReceipt.METHOD_MOMO,
							CashflowReceipt.METHOD_VNPAY,
							CashflowReceipt.METHOD_MEMBERCARD,
						],
						item.method
					) >= 0
			);
		}

		return onlinePayments;
	}
}

export default PosCartModel;
