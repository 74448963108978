import { BaseModelJson } from "common/interfaces/BaseModelJson";
import BaseModel from "./BaseModel";
import { ToolJson } from "common/types/Tool";

class ToolModel extends BaseModel implements BaseModelJson<ToolJson> {
  result: string;

  constructor(json: ToolJson) {
    super();
    this.result = json.result || "";
  }

  toJson(): ToolJson {
    return {
      result: this.result,
    };
  }

  static getDefaultData(): ToolJson {
    return {
      result: "",
    };
  }
}

export default ToolModel;
