import { useTranslation } from "react-i18next";
import PageHeader from "components/page/PageHeader";

type Props = {
  children?: React.ReactNode;
};

const ReportLeaveHeader = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("reportleave:heading_reportleave")}
      siteMenuSelectedKey="/report/leave"
    >
      {children}
    </PageHeader>
  );
};

export default ReportLeaveHeader;
