import { Alert, AlertProps, Button } from "antd";
import Error from "components/LayoutError";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorAlert = ({
  heading,
  className,
  items,
  translate_prefix,
  style,
  onRetry,
  type,
}: {
  items: string[];
  translate_prefix?: string;
  onRetry?: () => any;
  style?: Object;
  className?: string;
  heading?: string;
  type?: AlertProps["type"];
}) => {
  const { t } = useTranslation();

  const withoutHeading = !(
    typeof heading !== "undefined" &&
    heading &&
    heading.length > 0
  );

  const withRetry = typeof onRetry === "function";

  return (
    <Alert
      style={style}
      showIcon
      type={type || "error"}
      className={
        (className || "") + (withoutHeading ? " ant-alert-without-message" : "")
      }
      message={heading}
      action={
        withRetry ? (
          <Button
            size="small"
            type="link"
            onClick={() => typeof onRetry !== "undefined" && onRetry()}
          >
            {t("common:table.retry_load_data")}
          </Button>
        ) : null
      }
      description={
        <Error
          heading=""
          contentPadding={withoutHeading ? 0 : 20}
          translate_prefix={translate_prefix || ""}
          items={items}
        />
      }
    />
  );
};

export default ErrorAlert;
