import Role from "common/constants/Role";
import PageHeader from "components/page/PageHeader";
import PageHeaderButton from "components/page/PageHeaderButton";
import RoleCheck from "components/RoleCheck";
import React from "react";
import { useTranslation } from "react-i18next";

const EmployeeListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("employee:heading_list")}
      siteMenuSelectedKey="/employee"
    >
      <RoleCheck roles={[Role.EMPLOYEE_MANAGE]}>
        <PageHeaderButton link="/employee/add">
          {t("common:table.add_button")}
        </PageHeaderButton>
      </RoleCheck>
    </PageHeader>
  );
};

export default EmployeeListHeader;
