import CollectionModel from "common/models/CollectionModel";
import CollectionRepository from "common/repositories/CollectionRepository";
import FormEditFetcher from "components/form/FormEditFetcher";
import CollectionForm from "features/collection/form/CollectionForm";
import CollectionFormHeader from "features/collection/form/CollectionFormHeader";
import CollectionList from "features/collection/list/CollectionList";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";

const CollectionPage = () => {
  const [pathParams] = usePathParams();
  const [model, setModel] = useState(
    new CollectionModel(CollectionModel.getDefaultData())
  );
  const [errors, setErrors] = useState<string[]>([]);

  //clear edit model when change action
  useEffect(() => {
    setModel(new CollectionModel(CollectionModel.getDefaultData()));
    setErrors([]);
  }, [pathParams.action]);

  let com = <></>;

  switch (pathParams.action) {
    case "add":
      com = <CollectionForm model={model} />;
      break;
    case "edit":
      com =
        model.id > 0 ? (
          <CollectionForm model={model} />
        ) : (
          <FormEditFetcher
            doLoad={async () => {
              const fetchModel = await new CollectionRepository().getItem(
                Number(pathParams.id) || 0
              );
              if (!fetchModel.hasError()) {
                setModel(fetchModel);
              } else {
                setErrors(fetchModel.error.errors);
              }
            }}
            heading={<CollectionFormHeader isEditing={true} />}
            errors={errors}
          />
        );
      break;
    default:
      com = <CollectionList />;
      break;
  }
  return com;
};

export default CollectionPage;
