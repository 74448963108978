import { Col, Form, Input, Row } from "antd";
import DmsBanner from "common/constants/DmsBanner";
import File from "common/constants/File";
import DmsBannerModel from "common/models/DmsBannerModel";
import FileModel from "common/models/FileModel";
import DmsBannerRepository from "common/repositories/DmsBannerRepository";
import FileInput from "components/form/fileinput/FileInput";
import FormSection from "components/form/FormSection";
import FormSelect from "components/form/FormSelect";
import LayoutForm from "components/form/LayoutForm";
import DmsBannerEditorHeader from "features/dmsbanner/form/DmsBannerFormEditorHeader";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import type { DmsBannerJsonAddEdit } from "common/types/DmsBanner";
const DmsBannerFormEditor = ({ model }: { model: DmsBannerModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const account = useLoginAccountStore((state) => state.account);

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  // modal editing

  const initialValues = {
    link: model.link,
    caption: model.caption,
    position: model.position > 0 ? model.position : null,
    file_id: model.file_id,
    image_input: {
      files: FileModel.convertToUploadFiles([model.image]),
      first_file_id: model.file_id,
    },
    status: model.status > 0 ? model.status : null,
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let file_id =
        typeof formData.image_input.file_id_list?.[0] !== "undefined"
          ? formData.image_input.file_id_list?.[0]
          : model.file_id;

      const submitData: DmsBannerJsonAddEdit = {
        ...DmsBannerModel.getDefaultData(),
        id: model.id,
        company_id: account.company.id,
        creator_id: account.id,

        link: formData.link,
        caption: formData.caption,
        position: formData.position,
        status: formData.status || DmsBanner.STATUS_ENABLED,
        file_id: file_id,
      };

      return submitData;
    },
    [model.id, model.file_id, account]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: DmsBannerModel = await new DmsBannerRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <Col>
        <Form.Item name="image_input" label={t("dmsbanner:image")}>
          <FileInput
            objectType={File.OBJECTTYPE_ATTACHMENT}
            origin="dmsportal"
            max_item={1}
            allowExtensions={["pdf", "png", "jpeg"]}
          />
        </Form.Item>
      </Col>
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        rules={[
          {
            required: true,
            message: t("dmsbanner:form.error.error_status_required"),
          },
        ]}
        options={DmsBannerModel.getStatusList()}
      />
    </div>
  );

  return (
    <>
      <DmsBannerEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("dmsbanner:form.success.update")
            : t("dmsbanner:form.success.add")
        }
        redirectOnSuccess="/dmsbanner"
        error_translate_prefix="dmsbanner:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("dmsbanner:form.section_title")}
          subtitle={t("dmsbanner:form.section_description")}
          divider
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("dmsbanner:link")}
                name="link"
                rules={[
                  {
                    required: true,
                    message: t("dmsbanner:form.error.error_link_required"),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("dmsbanner:caption")} name="caption">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <FormSelect
                label={t("dmsbanner:position")}
                name="position"
                required
                rules={[
                  {
                    required: true,
                    message: t("dmsbanner:form.error.error_position_required"),
                  },
                ]}
                options={DmsBannerModel.getTypeList()}
              />
            </Col>
          </Row>
        </FormSection>
      </LayoutForm>
    </>
  );
};

export default DmsBannerFormEditor;
