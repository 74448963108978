import React from 'react'

const WorkChatThreadUser = (
    {
        username,
        avatar,
        realtime,
        text,
        button,
    }:{
        username: string,
        avatar: string,
        realtime: string,
        text: string,
        button: { icon: string; count: string}[],
    }
) => {
  return (
    <div className="flex px-4 py-3">
        <img className="h-10 w-10 rounded flex-shrink-0 object-cover" src={avatar} alt="" />
        <div className="ml-2">
            <div className="-mt-1">
                <span className="text-sm font-semibold">{username}</span>
                <span className="ml-1 text-xs text-gray-500">{realtime}</span>
            </div>
            <p className="text-sm">{text}</p>
            <div className="flex space-x-2 mt-1">
                {button.map(({ icon, count }, index) => (
                    (icon === "" && count === "") ? (
                        null
                    ) : (
                        <button
                            key={index}
                            className="flex items-center pl-1 pr-2 h-5 bg-gray-300 hover:bg-gray-400 rounded-full text-xs">
                            <span>{icon}</span>
                            <span className="ml-1 font-medium">{count}</span>
                        </button>
                    )
                    
                ))}
            </div>
        </div>
    </div>
  )
}

export default WorkChatThreadUser;
