import React from "react";

type Props = {
  size?: "medium" | "small";
  color?: "main" | "gray";
};

const ClockIcon = ({ size, color = "main" }: Props) => {
  let icon;
  switch (size) {
    case "medium":
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00002 13.2709C3.54085 13.2709 0.729187 10.4592 0.729187 7.00002C0.729187 3.54085 3.54085 0.729187 7.00002 0.729187C10.4592 0.729187 13.2709 3.54085 13.2709 7.00002C13.2709 10.4592 10.4592 13.2709 7.00002 13.2709ZM7.00002 1.60419C4.02502 1.60419 1.60419 4.02502 1.60419 7.00002C1.60419 9.97502 4.02502 12.3959 7.00002 12.3959C9.97502 12.3959 12.3959 9.97502 12.3959 7.00002C12.3959 4.02502 9.97502 1.60419 7.00002 1.60419Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
          <path
            d="M9.16414 9.29253C9.08831 9.29253 9.01248 9.27503 8.94248 9.22836L7.13414 8.14919C6.68498 7.88086 6.35248 7.29169 6.35248 6.77253V4.38086C6.35248 4.14169 6.55081 3.94336 6.78998 3.94336C7.02914 3.94336 7.22748 4.14169 7.22748 4.38086V6.77253C7.22748 6.98253 7.40248 7.29169 7.58331 7.39669L9.39165 8.47586C9.60165 8.59836 9.66581 8.86669 9.54331 9.07669C9.45581 9.21669 9.30998 9.29253 9.16414 9.29253Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
        </svg>
      );
      break;

    case "small":
      icon = (
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.99998 9.47915C2.52915 9.47915 0.520813 7.47081 0.520813 4.99998C0.520813 2.52915 2.52915 0.520813 4.99998 0.520813C7.47081 0.520813 9.47915 2.52915 9.47915 4.99998C9.47915 7.47081 7.47081 9.47915 4.99998 9.47915ZM4.99998 1.14581C2.87498 1.14581 1.14581 2.87498 1.14581 4.99998C1.14581 7.12498 2.87498 8.85415 4.99998 8.85415C7.12498 8.85415 8.85415 7.12498 8.85415 4.99998C8.85415 2.87498 7.12498 1.14581 4.99998 1.14581Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
          <path
            d="M6.54581 6.63748C6.49164 6.63748 6.43748 6.62498 6.38748 6.59165L5.09581 5.82082C4.77498 5.62915 4.53748 5.20832 4.53748 4.83748V3.12915C4.53748 2.95832 4.67914 2.81665 4.84998 2.81665C5.02081 2.81665 5.16248 2.95832 5.16248 3.12915V4.83748C5.16248 4.98748 5.28748 5.20832 5.41664 5.28332L6.70831 6.05415C6.85831 6.14165 6.90414 6.33332 6.81664 6.48332C6.75414 6.58332 6.64998 6.63748 6.54581 6.63748Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width={14}
          height={14}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00002 13.2709C3.54085 13.2709 0.729187 10.4592 0.729187 7.00002C0.729187 3.54085 3.54085 0.729187 7.00002 0.729187C10.4592 0.729187 13.2709 3.54085 13.2709 7.00002C13.2709 10.4592 10.4592 13.2709 7.00002 13.2709ZM7.00002 1.60419C4.02502 1.60419 1.60419 4.02502 1.60419 7.00002C1.60419 9.97502 4.02502 12.3959 7.00002 12.3959C9.97502 12.3959 12.3959 9.97502 12.3959 7.00002C12.3959 4.02502 9.97502 1.60419 7.00002 1.60419Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
          <path
            d="M9.16414 9.29253C9.08831 9.29253 9.01248 9.27503 8.94248 9.22836L7.13414 8.14919C6.68498 7.88086 6.35248 7.29169 6.35248 6.77253V4.38086C6.35248 4.14169 6.55081 3.94336 6.78998 3.94336C7.02914 3.94336 7.22748 4.14169 7.22748 4.38086V6.77253C7.22748 6.98253 7.40248 7.29169 7.58331 7.39669L9.39165 8.47586C9.60165 8.59836 9.66581 8.86669 9.54331 9.07669C9.45581 9.21669 9.30998 9.29253 9.16414 9.29253Z"
            fill={color === "main" ? "#292D32" : "#898889"}
          />
        </svg>
      );
  }
  return icon;
};

export default ClockIcon;
