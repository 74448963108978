import {
	DeleteOutlined,
	EditOutlined,
	RollbackOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Image, message, Row, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadChangeParam } from "antd/lib/upload/interface";
import FileApi from "common/api/FileApi";
import File from "common/constants/File";
import React, { useState } from "react";
type Props = {
	width?: number | string;
	height?: number | string;
	defaultUrl?: string;
	className?: string;
	styleCard?: React.CSSProperties;
	actionCard?: React.ReactNode[];
	onSuccess: (url: string) => void;
};
export default function UploadEvent({
	width,
	height,
	defaultUrl,
	className,
	styleCard,
	actionCard,
	onSuccess,
}: Props) {
	console.log("🚀 ~ defaultUrl:", defaultUrl);
	const [messageApi, contextHolder] = message.useMessage();

	const [isEdit, setIsEdit] = useState(false);
	const [img, setImg] = useState(defaultUrl || "");

	return (
		<div className={className}>
			{contextHolder}
			{!isEdit && img.length > 0 ? (
				<Row gutter={[8, 8]}>
					<Col>
						<Card
							size="small"
							style={styleCard}
							cover={
								<Image
									className="object-cover"
									src={img}
									width={width}
									height={height}></Image>
							}
							actions={[
								<EditOutlined
									onClick={() => {
										setIsEdit((prev) => !prev);
									}}
									key="edit"
								/>,
								actionCard && actionCard,
							]}></Card>
					</Col>
				</Row>
			) : (
				<>
					<Upload
						{...new FileApi(false).getUploadProps({
							directory_id: 0,
							object_type: File.OBJECTTYPE_ATTACHMENT,
							object_id: 0,
							origin: "avatar",
						})}
						maxCount={1}
						itemRender={(_originNode, file, _fileList, actions) => {
							if (file.status === "error") {
								return (
									<div
										className={`${
											width ? ` w-[${width}px]` : "w-full"
										} h-[${height}px] border border-red-500 flex flex-col gap-1 justify-center items-center text-red-500 font-bold max-w-[235px]`}>
										<span>Error</span>
										<Button onClick={() => actions.remove()}>
											<DeleteOutlined className="w-4 h-4" />
										</Button>
									</div>
								);
							}

							setIsEdit(false);
							setImg(file?.response?.url || "");
							return (
								<div className="flex gap-1">
									<Image
										src={file?.response?.url || ""}
										width={width}
										height={height}></Image>
									<Button size="small" onClick={() => actions.remove()}>
										<EditOutlined className="w-4 h-4" />
									</Button>
								</div>
							);
						}}
						onChange={(info: UploadChangeParam<UploadFile<any>>) => {
							if (info.file.status === "done") {
								messageApi.success("Thay đổi thành công");
								onSuccess(info.file.response.url);
							}
						}}
						listType="picture"
						defaultFileList={[]}>
						<div
							className={`w-[${width}px] h-[${height}] flex justify-center items-center`}>
							<Button
								size="small"
								icon={<UploadOutlined className="w-4 h-4" />}>
								Upload
							</Button>
						</div>
					</Upload>
					<Button
						size="small"
						className="mt-[2px]"
						onClick={() => setIsEdit(false)}
						icon={<RollbackOutlined className="w-4 h-4" />}>
						Back
					</Button>
				</>
			)}
		</div>
	);
}
