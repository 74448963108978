import { Col, List, Row } from "antd";
import ShippingRouteCollection from "common/collections/ShippingRouteCollection";
import ShippingOrder from "common/constants/ShippingOrder";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import { GoongMapCoordinates } from "common/types/goongmap/GoongMapType";
import eventEmitter from "common/utils/eventEmitter";
import ShipperAvatar from "components/ShipperAvatar";
import SimplePagination from "components/SimplePagination";
import DeliveryDashboardContext from "contexts/DeliveryDashboardContext";
import FilterContext from "contexts/FilterContext";
import React, { useContext, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

const DeliveryDashboardSectionLeft = ({
  shippingRouteItem,
}: {
  shippingRouteItem?: ShippingRouteCollection;
}) => {
  const { t } = useTranslation();
  const deliveryDashboardProvider = useContext(DeliveryDashboardContext);

  const myFilter = useContext(FilterContext);

  const height = useDeliveryDashboardStore((state) => state.height);

  const [activeRoute, setActiveRoute, setOrderItemsByRouteId, setHubItemById] =
    useDeliveryDashboardStore((state) => [
      state.activeRoute,
      state.setActiveRoute,
      state.setOrderItemsByRouteId,
      state.setHubItemById,
    ]);

  const headerHeight = 50;

  const getDefaultGoongMapCoordinates = useMemo(() => {
    return ShippingRouteModel.getDefaultGoongMapCoordinates();
  }, []);

  const goToMarker = (item: ShippingRouteModel) => {
    let viewport: GoongMapCoordinates = getDefaultGoongMapCoordinates;

    if (item.from_type === ShippingOrder.FROM_TYPE_OFFICE) {
      let findHub = deliveryDashboardProvider.hubItems.find(
        (i) => i.id === item.from_warehouse_id
      );
      if (findHub && findHub.lat > 0 && findHub.long > 0) {
        viewport.latitude = findHub.lat;
        viewport.longitude = findHub.long;
      }
    }

    // go to marker when change activeRoute
    eventEmitter.emit("DASHBOARD_GOONGMAP_GOTOMARKER", viewport);
  };

  const onRouteSelected = (item: ShippingRouteModel) => {
    setActiveRoute(item);
    setOrderItemsByRouteId(item.id, deliveryDashboardProvider.orderItems);
    if (item.from_type === ShippingOrder.FROM_TYPE_OFFICE) {
      setHubItemById(
        item.from_warehouse_id,
        deliveryDashboardProvider.hubItems
      );
    }

    // go to marker
    goToMarker(item);
  };

  return (
    <>
      <div
        className="px-2 text-xs text-gray-700 uppercase bg-gray-200"
        style={{ height: headerHeight, lineHeight: headerHeight + "px" }}
      >
        <Row justify="space-between" align="middle">
          <Col className="pt-0.5">
            <span className="pl-2 mr-2 text-lg font-bold">
              {shippingRouteItem?.total}
            </span>
            {t("deliverydashboard:route_heading")}
          </Col>
          <Col>
            <SimplePagination
              filters={myFilter.filters}
              setFilters={myFilter.setFilters}
              total={shippingRouteItem?.total || 0}
            />
          </Col>
        </Row>
      </div>
      <Scrollbars
        style={{
          height: height - headerHeight,
          overflow: "hidden",
          borderRight: "1px solid #eee",
        }}
        autoHide
      >
        <List
          dataSource={shippingRouteItem?.items}
          renderItem={(item: ShippingRouteModel, index: number) => {
            const isActive = item.id === activeRoute?.id;
            let routeStatusInfo = ShippingRouteModel.getStatus(item.status);
            let shipperInfo = deliveryDashboardProvider.shipperItems.find(
              (i) => i.id === item.shipper_id
            );
            let orderList = deliveryDashboardProvider.orderItems.filter(
              (i) => i.route_id === item.id
            );

            return (
              <List.Item
                className={
                  "cursor-pointer hover:bg-gray-100 border-l-4 border-b-0 " +
                  (isActive ? " bg-gray-600 text-white hover:bg-gray-700" : "")
                }
                style={{
                  borderLeftColor: routeStatusInfo?.color,
                  padding: 12,
                }}
                onClick={() => onRouteSelected(item)}
              >
                <List.Item.Meta
                  avatar={<ShipperAvatar shipper={shipperInfo} />}
                  title={
                    <span className={isActive ? " text-white" : ""}>
                      {item.code}
                    </span>
                  }
                  description={
                    <>
                      <div className="flex">
                        {orderList.length > 0
                          ? orderList.map((order, index) => {
                              let orderStatusInfo =
                                ShippingOrderModel.getStatus(order.status);
                              return (
                                <span
                                  key={order.id}
                                  className="w-2.5 h-2.5 mr-0.5 rounded-sm"
                                  style={{
                                    background: orderStatusInfo?.color,
                                  }}
                                ></span>
                              );
                            })
                          : null}
                      </div>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        ></List>
      </Scrollbars>
    </>
  );
};

export default DeliveryDashboardSectionLeft;
