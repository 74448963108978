import { useTranslation } from "react-i18next";

import FormSelect from "components/form/FormSelect";
import PageDataFilterForm from "components/page/PageDataFilterForm";

import { Form, Input } from "antd";
import TagModel from "common/models/TagModel";
import { Filter } from "common/types/Filter";
import TagTypeFormSelectFilter from "./TagTypeFormSelectFilter";

const TagListFilter = <F extends Filter>({
  total,
  filters,
  setFilters,
  defaultFilters,
}: {
  total: number;
  filters: F;
  setFilters: (f: F) => any;
  defaultFilters: F;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageDataFilterForm
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        convertValueByKeyFilter={["status", "type"]}
      >
        <FormSelect
          name="status"
          placeholder={"Trạng thái"}
          options={TagModel.getStatusSelectList()}
          allowClear
          popupMatchSelectWidth={false}
        />
        <FormSelect
          name="resource_type"
          placeholder={"Loại"}
          options={TagModel.getResouceTypeList()}
          allowClear
          popupMatchSelectWidth={false}
        />
        <TagTypeFormSelectFilter
          name="type"
          popupMatchSelectWidth={false}
          placeholder="Chọn loại tag"
        />
        <Form.Item name="keyword">
          <Input allowClear placeholder={t("Nhập tên")} />
        </Form.Item>
      </PageDataFilterForm>
    </>
  );
};

export default TagListFilter;
