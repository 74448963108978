import BaseCollection from "common/collections/BaseCollection";
import CustomerModel from "common/models/CustomerModel";
import { CustomerJson, CustomerCollectionJson } from "common/types/Customer";

class CustomerCollection extends BaseCollection<
  CustomerJson,
  CustomerModel,
  CustomerCollectionJson
> {
  itemsFromJson(jsonItems: CustomerJson[]): CustomerModel[] {
    return jsonItems.map((item) => new CustomerModel(item));
  }
}

export default CustomerCollection;


