import db from 'db';
import { useCallback, useEffect, useState } from 'react';

import type { SelectOption } from "common/types/SelectOption";

type UseStatus = {
  loading: boolean;
  data: SelectOption[];
  error: string;
};

const useDbForSelect = (table: string) => {
  const [status, setStatus] = useState<UseStatus>({
    loading: false,
    data: [],
    error: "",
  });

  const fetchNow = useCallback(
    async (url: string) => {
      setStatus({ data: [], loading: true, error: "" });

      if (db.hasOwnProperty(table) && db.table(table) !== null) {
        let items = await db.table(table).toArray();
        if (
          typeof items !== "undefined" &&
          items.hasOwnProperty("length") &&
          items.length > 0
        ) {
          setStatus({
            error: "",
            loading: false,
            data: items.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            }),
          });
        }
      } else {
        setStatus({ data: [], loading: false, error: "table not found" });
      }
    },
    [table]
  );

  useEffect(() => {
    if (table) {
      fetchNow(table);
    }
  }, [table, fetchNow]);

  return [status.data, status.loading, status.error, fetchNow] as const;
};

export default useDbForSelect;
