import { Nav } from 'common/types/PageNavigation';
import PageHeader from 'components/page/PageHeader';
import { useTranslation } from 'react-i18next';

const InventoryWaitingHeader = () => {
  const { t } = useTranslation();
  const nav: Nav = [
    {
      text: t("inventory:heading_nav"),
      link: "/inventory",
    },
  ];

  return (
    <PageHeader
      heading={t("inventory:heading_waiting")}
      nav={nav}
      siteMenuSelectedKey="/inventory"
    ></PageHeader>
  );
};

export default InventoryWaitingHeader;
