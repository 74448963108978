import {
  Alert,
  App,
  Button,
  Col,
  Divider,
  InputNumber,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
} from "antd";
import CashflowReceipt from "common/constants/CashflowReceipt";
import Order from "common/constants/Order";
import regionData from "common/data/region.json";
import OrderModel from "common/models/OrderModel";
import PosCartModel from "common/models/PosCartModel";
import OrderRepository from "common/repositories/OrderRepository";
import { EmployeeJson } from "common/types/Employee";
import { PosCartOrderDetail, PosPrintMoreData } from "common/types/PosCart";
import {
  PosOrder,
  PosOrderCoupon,
  PosOrderDetail,
  PosOrderPayment,
} from "common/types/PosOrder";
import { ShippingCarrierJson } from "common/types/ShippingCarrier";
import { StoreJson } from "common/types/Store";
import eventEmitter from "common/utils/eventEmitter";
import Error from "components/LayoutError";
import TextAddress from "components/TextAddress";
import TextMoney from "components/TextMoney";
import dayjs from "dayjs";
import useDatabaseTable from "hooks/useDatabaseTable";
import { forEach, reverse } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnline } from "rooks";
import orderSuccessFx from "sounds/pos/success.mp3";
import useSound from "use-sound";
import useCompanySettingStore from "zustands/useCompanySettingStore";
import usePosCartStore from "zustands/usePosCartStore";
import usePosStore from "zustands/usePosStore";

import { IconAlertTriangle } from "@tabler/icons-react";

import PosSubmitPrinter from "./PosSubmitPrinter";

const PosSubmitReview = ({
  showReview,
  setShowReview,
}: {
  showReview: boolean;
  setShowReview: (v: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const onlineStatus = useOnline() || false;
  const enableSoundFx = usePosStore((state) => state.enableSoundFx);
  const [soundFxOrderSuccess] = useSound(orderSuccessFx);
  const activeStore = usePosCartStore((state) => state.activeStore);
  const removeActiveCart = usePosCartStore((state) => state.removeActiveCart);
  const [storeItems] = useDatabaseTable<StoreJson>("store");
  const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");
  const [shippingCarrierItems] =
    useDatabaseTable<ShippingCarrierJson>("shippingcarrier");

  const posPrintTemplate = useCompanySettingStore(
    (state) => state.getSetting("pos_receipt_template") || ""
  );

  const activeCart = usePosCartStore(
    (state) => state.getActiveCart() || PosCartModel.getDefaultCart()
  );
  const [createdOrder, setCreatedOrder] = useState<OrderModel>();
  const [submitOrderData, setSubmitOrderData] = useState<PosOrder>();

  const [cashTotal, setCashTotal] = useState(0);
  const [cashNote, setCashNote] = useState(0);
  const [remainPrice, setRemainPrice] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [printMoreData, setPrintMoreData] = useState<PosPrintMoreData>({});

  const calculatedData = useMemo(
    () => PosCartModel.calculatePrices(activeCart),
    [activeCart]
  );

  const onlinePayments = useMemo(
    () => PosCartModel.getOnlinePayments(activeCart.payments),
    [activeCart]
  );

  const handleClose = useCallback(() => {
    if (processing) {
      message.loading("Đơn hàng đang được thêm. Vui lòng chờ trong giây lát.");
    } else {
      setShowReview(false);
    }
  }, [processing, setShowReview, message]);

  const doCreateOrder = useCallback(
    async (orderData: PosOrder) => {
      message.loading({
        content: t("common:form.processing"),
        key: "message",
        duration: 0,
      });
      setProcessing(true);
      const newOrder = await new OrderRepository().createRemote(orderData);
      setProcessing(false);
      if (newOrder.hasError()) {
        message.error({
          content: (
            <Error
              onClickClose={() => {
                message.destroy("message");
              }}
              heading={t("common:form.error.heading")}
              translate_prefix="order:form.error"
              items={newOrder.error.errors}
            />
          ),
          className: "message_error",
          key: "message",
          duration: 4,
        });
      } else {
        setSubmitOrderData(orderData);
        setCreatedOrder(newOrder);

        if (enableSoundFx) {
          soundFxOrderSuccess();
        }

        message.success({
          content: t("order:form.success.add"),
          className: "message_success",
          key: "message",
          duration: 2,
        });

        eventEmitter.emit("POS_CREATE_ORDER_SUCCESS", orderData);
        removeActiveCart();

        if (onlinePayments.length === 0) {
          setShowReview(false);
        } else {
          //continue to payment
        }
      }
    },
    [
      t,
      setShowReview,
      onlinePayments.length,
      removeActiveCart,
      enableSoundFx,
      soundFxOrderSuccess,
      message,
    ]
  );

  //create order
  const doPrepareOrder = useCallback(async () => {
    if (!onlineStatus) {
      message.error({
        content: (
          <>
            Hiện tại không có kết nối tới INTERNET nên không thể thêm đơn hàng
            này.
          </>
        ),
        key: "message_error",
        className: "message_error",
        duration: 3,
      });
    } else {
      //////////////////////////////
      //process shipping detail
      let shipping_address = "";
      let shipping_phone = "";
      let shipping_full_name = "";
      let shipping_region_id = 0;
      let shipping_sub_region_id = 0;
      let shipping_sub_sub_region_id = 0;
      let date_arrived = 0;
      if (activeCart.delivery.shipping_carrier_id > 0) {
        if (activeCart.delivery.is_customize) {
          shipping_address = activeCart.delivery.address;
          shipping_phone = activeCart.delivery.phone;
          shipping_full_name = activeCart.delivery.full_name;
          shipping_region_id = activeCart.delivery.region_id;
          shipping_sub_region_id = activeCart.delivery.sub_region_id;
          shipping_sub_sub_region_id = activeCart.delivery.sub_sub_region_id;
        } else {
          shipping_address = activeCart.customer.address;
          shipping_phone = activeCart.customer.phone;
          shipping_full_name = activeCart.customer.full_name;
          shipping_region_id = activeCart.customer.region_id;
          shipping_sub_region_id = activeCart.customer.sub_region_id;
          shipping_sub_sub_region_id = activeCart.customer.sub_sub_region_id;
        }
        date_arrived = activeCart.delivery.date_arrived;
      }

      //Process payment
      let payments: PosOrderPayment[] = [];
      let paymentRemain = calculatedData.price_final;

      activeCart.payments.forEach((item) => {
        //minus value from original price_final
        paymentRemain -= item.value;

        payments.push({
          ...item,
          cashflow_group: CashflowReceipt.GROUP_IN_SALE,
        });
      });

      //Create COD to fullfil 100% money from payments to price_final
      if (paymentRemain > 0) {
        payments.push({
          _id: "cod-remain",
          method: CashflowReceipt.METHOD_COD,
          value: paymentRemain,
          note: "",
          cashflow_group: CashflowReceipt.GROUP_IN_SALE,
        });
      }

      //Process detail
      let submitTotalQuantity = calculatedData.quantity;
      let details: PosOrderDetail[] = [];
      activeCart.details.forEach((item) => {
        //compose detail
        const orderDetail: PosOrderDetail = {
          ...item,
          is_gift_product: 0,
          promotion: item.promotions.length > 0 ? item.promotions[0] : null,
          promotion_manual: item.manual_discount
            ? {
                value_type: item.manual_discount_type,
                value: item.manual_discount_value,
              }
            : null,
        };

        //get promotion product
        details.push(orderDetail);
      });

      //convert promotion_gifts as detail items
      if (Array.isArray(activeCart.promotion_gifts)) {
        activeCart.promotion_gifts.forEach((item) => {
          //compose detail
          const orderDetail: PosOrderDetail = {
            ...item,
            is_gift_product: 1,
            promotion: null,
            promotion_manual: null,
          };

          //get promotion product
          details.push(orderDetail);

          //sum up quantity of gift
          submitTotalQuantity += item.item_quantity;
        });
      }

      //Process coupon
      let coupons: PosOrderCoupon[] = [];
      activeCart.coupons.forEach((item) => {
        coupons.push({
          code: item.coupon?.code || "",
          discount_type: item.promotion?.value_type || 0,
          discount_value:
            typeof item.promotion?.value === "number"
              ? item.promotion?.value
              : 0,
        });
      });

      //process ORDER PROMOTION
      let activeCartPromotions = activeCart.promotions;
      if (
        activeCartPromotions.length === 0 &&
        activeCart.coupons.length > 0 &&
        typeof activeCart.coupons[0].promotion !== "undefined"
      ) {
        activeCartPromotions.push(activeCart.coupons[0].promotion);
      }

      //compose final data
      let orderData: PosOrder = {
        //Orderbase section
        company_id: activeCart?.company_id,
        creator_id: activeCart?.creator_id,
        customer_id: activeCart.customer.id,
        store_id: activeStore?.id || 0,
        id: 0,
        price_sell: calculatedData.price_sell,
        price_shipping: calculatedData.price_shipping,
        price_discount: calculatedData.price_discount,
        price_final: calculatedData.price_final,
        promotion_id:
          activeCartPromotions.length === 0 ? 0 : activeCartPromotions[0].id,
        contact_email: activeCart.customer.email,
        billing_full_name: activeCart.customer.full_name,
        billing_phone: activeCart.customer.phone,
        billing_address: activeCart.customer.address,
        billing_sub_sub_region_id: activeCart.customer.sub_sub_region_id,
        billing_sub_region_id: activeCart.customer.sub_region_id,
        billing_region_id: activeCart.customer.region_id,
        shipping_full_name,
        shipping_phone,
        shipping_address,
        shipping_sub_sub_region_id,
        shipping_sub_region_id,
        shipping_region_id,
        warehouse_id: activeCart.warehouse_id,
        shipping_carrier: activeCart.delivery.shipping_carrier_id,
        quantity: submitTotalQuantity,
        note: activeCart.note,
        cod_amount: 0,
        status: Order.STATUS_OPEN,
        cancel_reason: 1,
        tag: activeCart.tag,
        date_created: dayjs().unix(),
        date_arrived,

        //PosOrder section
        payments,
        coupons,
        details: reverse(details),
        promotion:
          activeCartPromotions.length === 0 ? null : activeCartPromotions[0],
        remote_sync_result: JSON.stringify({}),
        cash_customer_paid: cashNote,
        cash_customer_return:
          cashNote - cashTotal >= 0 ? cashNote - cashTotal : 0,
        new_customer_gender: activeCart?.customer?.is_new
          ? activeCart?.customer?.gender
          : 0,
        new_customer_type_id: activeCart?.customer?.is_new
          ? activeCart?.customer?.customer_type_id
          : 0,
        new_customer_birthday: activeCart?.customer?.is_new
          ? activeCart?.customer?.birthday
          : "",
      };

      //everything is OK
      //submit order
      doCreateOrder(orderData);
    }
  }, [
    activeStore,
    calculatedData,
    activeCart,
    cashNote,
    cashTotal,
    doCreateOrder,
    onlineStatus,
    message,
  ]);

  //after print dialog close (cancel or PRINT)
  const handleAfterPrint = useCallback(() => {
    //clear data
  }, []);

  //get total cash value
  useEffect(() => {
    let cashValue = 0;
    activeCart.payments.forEach((payment) => {
      if (payment.method === CashflowReceipt.METHOD_CASH) {
        cashValue += payment.value;
      }
    });
    setCashTotal(cashValue);
  }, [activeCart.payments]);

  //calculate remain money
  useEffect(() => {
    let remainMoney = calculatedData.price_final;
    forEach(activeCart.payments, (payment) => {
      remainMoney -= payment.value;
    });
    setRemainPrice(remainMoney);
  }, [calculatedData.price_final, activeCart.payments]);

  useEffect(() => {
    //get store
    const foundStore = storeItems.find((i) => i.id === activeCart?.store_id);
    let storeName = "";
    let storeAddress = "";
    let storePhone = "";
    if (typeof foundStore !== "undefined") {
      storeName = foundStore.name;
      storeAddress = foundStore.address;
      storePhone = foundStore.phone;
    }

    //get creator
    const foundEmployee = employeeItems.find(
      (i) => i.user_id === activeCart?.creator_id
    );
    let creatorFullName = "";
    if (typeof foundEmployee !== "undefined") {
      creatorFullName = foundEmployee.full_name;
    }

    //get shippingcarrier
    const foundShippingCarrier = shippingCarrierItems.find(
      (i) => i.id === activeCart?.delivery?.shipping_carrier_id
    );
    let shippingCarrierName = "";
    if (typeof foundShippingCarrier !== "undefined") {
      shippingCarrierName = foundShippingCarrier.name;
    }

    //get region of billing
    const billingRegion =
      regionData.find((i) => i.id === activeCart?.customer.region_id)?.name ||
      "";
    const billingSubRegion =
      regionData.find((i) => i.id === activeCart?.customer.sub_region_id)
        ?.name || "";
    const billingSubSubRegion =
      regionData.find((i) => i.id === activeCart?.customer.sub_sub_region_id)
        ?.name || "";

    //get region of delivery/shipping
    const shippingRegion = activeCart.delivery.is_customize
      ? regionData.find((i) => i.id === activeCart?.delivery.region_id)?.name ||
        ""
      : billingRegion;

    const shippingSubRegion = activeCart.delivery.is_customize
      ? regionData.find((i) => i.id === activeCart?.delivery.sub_region_id)
          ?.name || ""
      : billingSubRegion;

    const shippingSubSubRegion = activeCart.delivery.is_customize
      ? regionData.find((i) => i.id === activeCart?.delivery.sub_sub_region_id)
          ?.name || ""
      : billingSubSubRegion;

    setPrintMoreData({
      store_name: storeName,
      store_address: storeAddress,
      store_phone: storePhone,
      creator_full_name: creatorFullName,
      shipping_carrier_name: shippingCarrierName,
      billing_region: billingRegion,
      billing_sub_region: billingSubRegion,
      billing_sub_sub_region: billingSubSubRegion,
      shipping_region: shippingRegion,
      shipping_sub_region: shippingSubRegion,
      shipping_sub_sub_region: shippingSubSubRegion,
    });
  }, [activeCart, storeItems, employeeItems, shippingCarrierItems]);

  return (
    <>
      {" "}
      <Modal
        maskClosable={false}
        style={{ top: 70 }}
        width={1000}
        open={showReview}
        onCancel={handleClose}
        title="Xác nhận đơn hàng"
        footer={null}
        bodyStyle={{ padding: 0 }}
        afterClose={() => {
          setCashNote(0);
        }}
      >
        <Row>
          <Col span={16} className="p-4">
            <Divider
              orientation="left"
              orientationMargin={0}
              style={{ marginTop: 0, marginBottom: 8 }}
            >
              <div className="text-blue-500">
                CHI TIẾT ĐƠN HÀNG ({calculatedData.quantity})
              </div>
            </Divider>
            <Table
              showHeader={false}
              size="small"
              pagination={{ hideOnSinglePage: true }}
              columns={[
                {
                  title: "Số lượng",
                  dataIndex: "item_quantity",
                  key: "item_quantity",
                  width: 40,
                  align: "right",
                },
                {
                  title: " ",
                  render: () => <>x</>,
                  width: 20,
                },
                {
                  title: "Tên sản phẩm",
                  render: (_, item: PosCartOrderDetail) => {
                    return (
                      <Tooltip title="" placement="left" mouseEnterDelay={0.4}>
                        {item.item_name}
                        <small className="text-muted"> (SKU: {item.sku})</small>
                      </Tooltip>
                    );
                  },
                },
              ]}
              dataSource={activeCart.details.map((item) => {
                return {
                  ...item,
                  key: item.row_identifier,
                  times: "x",
                };
              })}
            />

            {Array.isArray(activeCart.promotion_gifts) &&
              activeCart.promotion_gifts.map((gift) => (
                <Tag
                  color="green"
                  className="d-block mg-r-0 mg-t-10"
                  style={{
                    color: "#2C662D",
                    padding: 7,
                    borderStyle: "dashed",
                  }}
                >
                  Quà tặng:{" "}
                  <strong>
                    {gift.item_name} (SKU: {gift.sku})
                  </strong>
                </Tag>
              ))}

            <Row gutter={24} className="mt-4">
              <Col span={12}>
                <Divider
                  orientation="left"
                  orientationMargin={0}
                  style={{ marginBottom: 8 }}
                >
                  <div className="text-blue-500">KHÁCH HÀNG</div>
                </Divider>
                <div>
                  {activeCart.customer.full_name === "" ? (
                    <>Khách lẻ (không ghi nhận thông tin khách hàng)</>
                  ) : (
                    <>
                      {activeCart.customer.is_new ? (
                        <Tag color="green">NEW</Tag>
                      ) : (
                        ""
                      )}
                      <strong>{activeCart.customer.full_name}</strong> /{" "}
                      {activeCart.customer.phone} / {activeCart.customer.email}{" "}
                      <br />
                      <TextAddress
                        address={activeCart.customer.address}
                        region_id={activeCart.customer.region_id}
                        sub_region_id={activeCart.customer.sub_region_id}
                        sub_sub_region_id={
                          activeCart.customer.sub_sub_region_id
                        }
                      />
                    </>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <Divider
                  orientation="left"
                  orientationMargin={0}
                  style={{ marginBottom: 8 }}
                >
                  <div className="text-blue-500">GIAO HÀNG</div>
                </Divider>
                <div className="">
                  {activeCart.delivery.shipping_carrier_id === 0 ? (
                    <Tag color="blue">KHÔNG GIAO HÀNG</Tag>
                  ) : activeCart.delivery.is_customize ? (
                    <div>
                      <strong>{activeCart.delivery.full_name}</strong> /{" "}
                      {activeCart.delivery.phone}
                      <br />
                      <TextAddress
                        address={activeCart.delivery.address}
                        region_id={activeCart.delivery.region_id}
                        sub_region_id={activeCart.delivery.sub_region_id}
                        sub_sub_region_id={
                          activeCart.delivery.sub_sub_region_id
                        }
                      />
                    </div>
                  ) : (
                    <div className="text-muted">Giống thông tin khách hàng</div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="p-4 bg-gray-100">
            <div className="text-lg text-red-600">
              <Row>
                <Col span={12}>TỔNG CỘNG:</Col>
                <Col span={12} className="text-right">
                  <big>
                    <strong>
                      <TextMoney money={calculatedData.price_final} />
                    </strong>
                  </big>
                </Col>
              </Row>
            </div>
            <Divider />
            {cashTotal > 0 ? (
              <div className="container">
                <Row className="mb-4">
                  <Col span={12}>Tiền mặt cần trả</Col>
                  <Col span={12} className="text-right">
                    <TextMoney money={cashTotal} />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col
                    span={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Khách đưa
                  </Col>
                  <Col span={12} className="text-right">
                    <InputNumber<number>
                      style={{ width: 120 }}
                      autoFocus={true}
                      value={cashNote}
                      min={cashTotal}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) =>
                        typeof value !== "undefined"
                          ? Number(value.replace(/\$\s?|(,*)/g, ""))
                          : 0
                      }
                      onChange={(value) => setCashNote(value ?? 0)}
                      precision={0}
                      decimalSeparator="."
                      step={50000}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col span={12}>Tiền trả lại khách</Col>
                  <Col span={12} className="text-right">
                    <TextMoney
                      money={
                        cashNote - cashTotal < 0 ? 0 : cashNote - cashTotal
                      }
                    />
                  </Col>
                </Row>
              </div>
            ) : typeof submitOrderData === "undefined" ? (
              <div>
                <Tooltip
                  title={
                    <span>
                      Bạn thấy thông báo này bởi vì số tiền cần phải thu là{" "}
                      <TextMoney money={remainPrice} /> và chưa chọn phương thức
                      thanh toán loại TIỀN MẶT nào.
                    </span>
                  }
                  placement="left"
                  mouseEnterDelay={0.4}
                >
                  <Alert
                    message="Đơn hàng này không thu tiền mặt."
                    type="warning"
                    showIcon
                  />
                </Tooltip>
              </div>
            ) : null}
            <Button
              loading={processing}
              type="primary"
              danger={!onlineStatus}
              block={true}
              onClick={doPrepareOrder}
              shape="round"
              size="large"
              className="mt-4 bg-green-500 border-green-500 hover:bg-green-400 hover:border-green-400"
            >
              {!onlineStatus ? (
                <IconAlertTriangle size={14} className="mr-2 -mt-0.5" />
              ) : null}
              Xác nhận & thêm đơn hàng
            </Button>
            {onlinePayments.length > 0 ? (
              <div>
                <div className="px-4 py-2 text-xs text-center text-gray-500">
                  Sau khi đơn được thêm thành công, sẽ hiển thị màn hình thanh
                  toán trực tuyến
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
      </Modal>
      {typeof submitOrderData !== "undefined" &&
      typeof createdOrder !== "undefined" &&
      createdOrder.invoice_id > 0 ? (
        <PosSubmitPrinter
          key={"order-" + createdOrder.invoice_id}
          printMoreData={printMoreData}
          printTemplate={posPrintTemplate + ""}
          order={{ ...submitOrderData, id: createdOrder.invoice_id }}
          onAfterPrintCallback={handleAfterPrint}
        />
      ) : null}
    </>
  );
};

export default PosSubmitReview;
