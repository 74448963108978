import { Alert, Button, Form } from "antd";
import Order from "common/constants/Order";
import Role from "common/constants/Role";
import OrderModel from "common/models/OrderModel";
import OrderRepository from "common/repositories/OrderRepository";
import { OrderDetailJson } from "common/types/OrderDetail";
import LayoutForm from "components/form/LayoutForm";
import RoleCheck from "components/RoleCheck";
import SimpleCollapse from "components/SimpleCollapse";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useEditingSaleOrderStore from "zustands/useEditingSaleOrder";
import useLoginAccountStore from "zustands/useLoginAccountStore";

import OrderFormHeader from "./OrderSellerFormHeader";
import OrderFormSectionDetail from "./section/OrderFormSectionDetail";
import OrderFormSectionEcomPlatform from "./section/OrderFormSectionEcomPlatform";
import OrderFormSectionGeneral from "./section/OrderFormSectionGeneral";
import OrderFormSectionInfo from "./section/OrderFormSectionInfo";
import OrderFormSectionStatus from "./section/OrderFormSectionStatus";
import OrderFormSectionTaxInvoice from "./section/OrderFormSectionTaxInvoice";
import OrderFormSectionCashflowReceipt from "./section/shipping/OrderFormSectionCashflowReceipt";
import OrderFormSectionProductReceipt from "./section/shipping/OrderFormSectionProductReceipt";
import OrderSellerFormSectionShipping from "./section/shipping/OrderSellerFormSectionShipping";

import type { OrderJson, OrderJsonEdit, OrderSellerJsonEdit } from "common/types/Order";
import OrderDetailListForm from "./section/orderdetail/OrderDetailListForm";
import Helper from "common/utils/helper";
import OrderPrintBill from "./OrderPrintBill";
import OrderSellerApi from "common/api/OrderSellerApi";
import OrderSellerRepository from "common/repositories/OrderSellerRepository";
import { Link } from "react-router-dom";

const OrderSellerForm = ({ order }: { order: OrderJson }) => {






  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [initStatus, setInitStatus] = useState(order.status);
  const [status, setStatus] = useState(initStatus);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );

  //check can edit update input info (add/edit features)
  const allowEditInput = useMemo(() => {
    return (
      // hasRoleAndLimit(Role.SELF_ORDER_EDIT, order.store_id) && [Order.STATUS_OPEN, Order.STATUS_DRAFT].includes(initStatus)
      [Order.STATUS_OPEN, Order.STATUS_DRAFT].includes(initStatus)
    );
  }, [initStatus, order.store_id]);



  const allowStatusComplete = useMemo(() => {
    return (
      hasRoleAndLimit(Role.ORDER_STATUS_COMPLETE, order.store_id) &&
      initStatus <= Order.STATUS_COMPLETE
    );
  }, [order.store_id, hasRoleAndLimit, initStatus]);

  //check allow set status to CANCEL
  const allowStatusCancel = useMemo(() => {
    return (
      hasRoleAndLimit(Role.ORDER_STATUS_CANCEL, order.store_id) &&
      initStatus < Order.STATUS_CANCEL
    );
  }, [order.store_id, hasRoleAndLimit, initStatus]);

  const enableSubmitButton = useMemo(() => {
    return allowEditInput || allowStatusComplete || allowStatusCancel;
  }, [allowEditInput, allowStatusComplete, allowStatusCancel]);


  const [details, setDetails] = useState<OrderDetailJson[]>([]);
  const initialValues: OrderSellerJsonEdit & { shipping_regions: any } = useMemo(() => {
    return {
      id: order.id,
      shipping_phone: order.shipping_phone,
      shipping_address: order.shipping_address,
      shipping_district: order.shipping_district,
      shipping_ward: order.shipping_ward,
      shipping_province: order.shipping_province,
      status: status,
      shipping_carrier: order.shipping_carrier,
      shipping_firstname: order.shipping_firstname,
      shipping_lastname: order.shipping_lastname,
      discount_custom_type: order.order_custom_type,
      discount_custom_value: order.order_custom_discount,
      note: order.note,
      shipping_regions: {
        region_id: order.shipping_province,
        sub_region_id: order.shipping_district,
        sub_sub_region_id: order.shipping_ward
      },
    };
  }, [order.id, order.note, order.order_custom_discount, order.order_custom_type, order.shipping_address, order.shipping_carrier, order.shipping_district, order.shipping_firstname, order.shipping_lastname, order.shipping_phone, order.shipping_province, order.shipping_ward, status]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      const submitData: OrderSellerJsonEdit = {
        id: order.id,
        shipping_phone: formData.shipping_phone,
        shipping_address: formData.shipping_address,
        // shipping_district: formData.shipping_regions.shipping_district,
        // shipping_ward: formData.shipping_regions.shipping_ward,
        // shipping_province: formData.shipping_regions.shipping_province,
        status: status,
        shipping_carrier: formData.shipping_carrier,
        shipping_firstname: formData.shipping_firstname,
        shipping_lastname: formData.shipping_lastname,
        discount_custom_type: formData.discount_custom_type ? formData.discount_custom_type : undefined,
        discount_custom_value: formData.discount_custom_value,
        note: formData.note,
        shipping_district: formData.shipping_regions.shipping_district,
        shipping_ward: formData.shipping_regions.shipping_ward,
        shipping_province: formData.shipping_regions.shipping_province,
      };
      console.log(submitData)
      return submitData;
    },
    [order.id, status]
  );

  //submit data to server
  const onSubmit = useCallback(
    async (formData: any) => {
      setIsSuccess(false);

      let item: OrderModel = await new OrderSellerRepository().saveRemote(
        doPrepareData(formData)
      );

      if (item.hasError()) {
        setErrors(item.error.errors);
      } else {
        //sync status to UI
        setInitStatus(item.status);

        setIsSuccess(true);
      }
    },
    [doPrepareData]
  );

  let sidebarItems = (
    <div className=" -my-6">
  

      <div className=" mb-2 mt-4 flex justify-end items-center gap-4">
      {[Order.STATUS_DRAFT, Order.STATUS_OPEN].includes(order.status) &&
        <div className="">
          <Link title="Xử lí đơn hàng" target="_blank" to={`${process.env.REACT_APP_SHOP_DOMAIN_URL}/pos?code=${order.code}`} ><Button >Xử lí đơn hàng</Button></Link>
        </div>
      }
        <OrderPrintBill order={order} />
      </div>

      <OrderFormSectionStatus
        allowEditInput={allowEditInput}
        allowStatusComplete={allowStatusComplete}
        allowStatusCancel={allowStatusCancel}
        status={status}
        setStatus={setStatus}
        initStatus={initStatus}
        type={order.type}
      />
      <OrderFormSectionInfo />
      <div className="pt-4">
        <OrderFormSectionGeneral form={form} allowEditInput={allowEditInput} />
      </div>
    </div>
  );

  return (
    <>
      <OrderFormHeader
        isEditing={Helper.checkEditOrder(order.id)}
        id={order.code}
      />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          Helper.checkEditOrder(order.id)
            ? t("order:form.success.update")
            : t("order:form.success.add")
        }
        error_translate_prefix="order:form.error"
        onSubmit={onSubmit}
        submitText={
          Helper.checkEditOrder(order.id)
            ? t("common:form.save")
            : t("common:form.submit")
        }
        submitDisabled={!enableSubmitButton}
        sidebarItems={sidebarItems}
        id="productreceipt-form"
        redirectOnSuccess={Helper.checkEditOrder(order.id) ? "" : "/order"}
      >
        <OrderSellerFormSectionShipping form={form} allowEditInput={allowEditInput} />
        <SimpleCollapse
          header={t("order:form.section_detail")}
          defaultOpen={true}
          childrenItem={
            <OrderDetailListForm
              model={order}
              setDetails={setDetails}
              form={form}
              allowEditInput={false}
            />
          }
        />

        {/* <RoleCheck
          roles={[Role.INVENTORY_VIEW]}
          componentOnFail={
            <Alert
              className="mt-4"
              type="warning"
              showIcon
              message={t("order:form.product_receipt_not_view_permission")}
            ></Alert>
          }
        >
          <OrderFormSectionProductReceipt
            form={form}
            allowEditInput={allowEditInput}
          />
        </RoleCheck>
        <RoleCheck
          roles={[Role.CASHFLOW_VIEW]}
          componentOnFail={
            <Alert
              className="mt-4"
              type="warning"
              showIcon
              message={t("order:form.cashflow_receipt_not_view_permission")}
            ></Alert>
          }
        >
          <OrderFormSectionCashflowReceipt />
        </RoleCheck>
        <RoleCheck
          roles={[Role.TAX_INVOICE_VIEW]}
          componentOnFail={
            <Alert
              className="mt-4"
              type="warning"
              showIcon
              message={t("order:form.tax_invoice_not_view_permission")}
            ></Alert>
          }
        >
          <OrderFormSectionTaxInvoice form={form} />
        </RoleCheck> */}

        {/* <OrderFormSectionEcomPlatform /> */}
      </LayoutForm>
    </>
  );
};

export default OrderSellerForm;
