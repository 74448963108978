import SupplierRepository from "common/repositories/SupplierRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SupplierFormSelect = ({
  label,
  name,
  onChange,
  className,
  multiple,
  placeholder,
  popupMatchSelectWidth,
}: {
  label?: string;
  name: string;
  onChange?: (v: number) => void;
  className?: string;
  multiple?: boolean;
  placeholder?: string;
  popupMatchSelectWidth?: boolean;
}) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchData = useCallback(async () => {
    setErrors([]);
    const collection = await new SupplierRepository().getItems({
      filters: SupplierRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  return (
    <FormSelect
      loading={loading}
      className={className}
      placeholder={placeholder || t("supplier:select_placeholder")}
      showSearch
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
      onChange={(value) =>
        typeof value === "number" && typeof onChange !== "undefined"
          ? onChange(value)
          : null
      }
      multiple={multiple}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
};

export default SupplierFormSelect;
