import { App, Button, Form, Input, Popover } from "antd";
import FileModel from "common/models/FileModel";
import FileRepository from "common/repositories/FileRepository";
import Error from "components/Error";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconFolderPlus } from "@tabler/icons-react";

import type { FileJsonAddEdit } from "common/types/File";
const FileListDirectoryAdd = ({
  directoryId,
  objectType,
  origin,
  onUploadCompleted
}: {
  directoryId: number;
  objectType: number;
  origin: string;
  onUploadCompleted: (f: FileModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const messageKey = "directory_add";
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const inputRef = useRef<any>(null);

  const onSubmit = useCallback(async () => {
    setProcessing(true);

    const data: FileJsonAddEdit = {
      id: 0,
      is_directory: 1,
      directory_id: directoryId,
      object_type: objectType,
      title: name,
      description: "",
      origin
    };
    const newDirectory = await new FileRepository().saveDirectoryRemote(data);
    setProcessing(false);

    if (newDirectory.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy(messageKey);
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="file:form.error"
            items={newDirectory.error.errors}
            contentPadding={0}
          />
        ),
        className: "message_error",
        key: messageKey,
        duration: 3
      });
    } else {
      message.success({
        content: t("file:form.success.add_directory"),
        className: "message_success",
        key: messageKey,
        duration: 2
      });

      //ok
      onUploadCompleted(newDirectory);
      setOpen(false);
      setName("");
    }
  }, [directoryId, objectType, name, t, onUploadCompleted, origin, message]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        typeof inputRef !== "undefined" &&
          inputRef.current &&
          inputRef.current.focus();
      }, 10);
    }
  }, [open]);

  const uploaderContent = (
    <Form layout="vertical">
      <Form.Item
        label={t("file:directory_name")}
        required
        rules={[
          {
            required: true,
            message: t("file:form.error.error_directory_name_required")
          }
        ]}>
        <Input.Search
          ref={inputRef}
          loading={processing}
          enterButton={t("common:form.add")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onSearch={(value) => value.length > 0 && onSubmit()}
        />
      </Form.Item>
    </Form>
  );

  return (
    <Popover
      content={uploaderContent}
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={(value) => setOpen(value)}>
      <Button
        title={t("file:button_add_directory_title")}
        onClick={(e) => setOpen(!open)}
        size="small"
        type="link"
        icon={<IconFolderPlus size="14" style={{ marginRight: 5 }} />}>
        {t("file:button_add_directory")}
      </Button>
    </Popover>
  );
};

export default FileListDirectoryAdd;
