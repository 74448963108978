import { Button, Popover, Spin } from "antd";
import CustomerModel from "common/models/CustomerModel";
import CustomerRepository from "common/repositories/CustomerRepository";
import CustomerSearchWidget from "features/customer/CustomerSearchWidget";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconCircleCheck } from "@tabler/icons-react";

const CustomerPointTargetCustomer = ({
  id,
  setId,
  disabled,
}: {
  id: number;
  setId: (v: number) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState<CustomerModel>();

  const fetchCustomer = useCallback(async (customerId: number) => {
    const customerModel = await new CustomerRepository().getItem(customerId);
    if (customerModel.hasError()) {
    } else {
      setCustomer(customerModel);
    }
  }, []);

  const customerPopover = useMemo(() => {
    return (
      <>
        <div className="p-2 font-bold bg-gray-200 ">
          {t("customerpoint:target.customer_search_heading")}:
        </div>
        <div className="p-2">
          <CustomerSearchWidget onSelect={(item) => setId(item.id)} />
        </div>
      </>
    );
  }, [setId, t]);

  useEffect(() => {
    if (id > 0) {
      setCustomer(undefined);
      fetchCustomer(id);
    }
  }, [id, fetchCustomer]);

  return (
    <>
      {id > 0 ? (
        <>
          <IconCircleCheck size={16} className="-mt-0.5 mr-1 text-green-500" />
          {typeof customer !== "undefined" ? (
            <>
              {customer.hasError() ? (
                <>Load error (ID: {id}).</>
              ) : (
                <>
                  {customer.full_name} ({t("common:phone")}: {customer.phone})
                </>
              )}
            </>
          ) : (
            <>
              <Spin size="small" />
            </>
          )}
          {!disabled ? (
            <Button danger type="text" onClick={() => setId(0)}>
              {t("customerpoint:target.clear")}
            </Button>
          ) : null}
        </>
      ) : (
        <>
          {!disabled ? (
            <Popover
              placement="right"
              content={customerPopover}
              trigger="click"
              overlayInnerStyle={{ width: 440 }}
              overlayClassName="ant-popover-inner-nopadding"
              defaultOpen
            >
              <Button type="link" size="small">
                {t("customerpoint:target.customer_select")}
              </Button>
            </Popover>
          ) : null}
        </>
      )}
    </>
  );
};

export default CustomerPointTargetCustomer;
