import { Col, Row } from "antd";
import useWindowDimensions from "hooks/useWindowDimensions";
import React, { useEffect } from "react";
import usePosStore from "zustands/usePosStore";

import PosCenter from "./PosCenter";
import PosLeft from "./PosLeft";
import PosRight from "./PosRight";

const PosContainer = () => {
  const pageHeaderHeight = 60;

  //get screen height
  const { width, height } = useWindowDimensions();

  //ready to set to pos height
  const setWidthHeight = usePosStore((state) => state.setWidthHeight);

  useEffect(() => {
    if (width > 0 && height > 0) {
      setWidthHeight(width, height - pageHeaderHeight);
    }
  }, [width, height, setWidthHeight]);

  useEffect(() => {
    document.body.classList.add("pos");

    return function cleanup() {
      document.body.classList.remove("pos");
    };
  });

  return (
    <div style={{ height: height - pageHeaderHeight }}>
      <Row gutter={0} className="h-full">
        <Col xs={8} className="bg-blue-600 h-full">
          <PosLeft />
        </Col>
        <Col xs={10}>
          <PosCenter />
        </Col>
        <Col xs={6}>
          <PosRight />
        </Col>
      </Row>
    </div>
  );
};

export default PosContainer;
