import CmsNewsCollection from "common/collections/CmsNewsCollection";
import CmsNews from "common/constants/CmsNews";
import Role from "common/constants/Role";
import CmsNewsCategoryModel from "common/models/CmsNewsCategoryModel";
import CmsNewsModel from "common/models/CmsNewsModel";
import CmsNewsCategoryRepository from "common/repositories/CmsNewsCategoryRepository";
import CmsNewsRepository from "common/repositories/CmsNewsRepository";
import { CmsNewsJson, FilterCmsNews } from "common/types/CmsNews";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TextDateTime from "components/TextDateTime";
import CmsNewsListHeader from "features/cmsnews/list/CmsNewsListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CmsNewsListFilter from "./CmsNewsListFilter";

const CmsNewsList = () => {
  const { t } = useTranslation();

  //fetch category
  const [categoryItems, setCategoryItems] = useState<CmsNewsCategoryModel[]>(
    [],
  );
  const fetchDataCategory = useCallback(async () => {
    const collection = await new CmsNewsCategoryRepository().getItems({
      filters: CmsNewsCategoryRepository.getDefaultFilters(),
    });
    if (!collection.hasError()) {
      setCategoryItems(collection.items);
    }
  }, []);

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterCmsNews = useMemo(
    () => CmsNewsRepository.getDefaultFilters(),
    [],
  );
  const [filters, setFilters] = useStateFilter<FilterCmsNews>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<CmsNewsModel[]>([]);
  const fetchData = useCallback(async (): Promise<CmsNewsCollection> => {
    let collection = await new CmsNewsRepository().getItems({
      filters,
    });
    setTotal(collection.total);
    setDataSource(collection.items);
    return collection;
  }, [filters]);

  useEffect(() => {
    fetchDataCategory();
  }, [fetchDataCategory]);

  //Table columns
  const columns: TableColumnsType<CmsNewsModel> = [
    {
      title: t("cmsnews:title"),
      key: "title",
      width: 200,
    },
    {
      title: t("cmsnews:news_category_id"),
      key: "news_category_id",
      width: 200,
      render: (news_category_id: number) => {
        if (news_category_id > 0) {
          const foundItem = categoryItems.find(
            (i) => i.id === news_category_id,
          );
          if (typeof foundItem !== "undefined") {
            return <span>{foundItem.name}</span>;
          } else {
            return "ID #" + news_category_id;
          }
        } else {
          return "-";
        }
      },
    },
    {
      title: t("common:seo.url"),
      key: "seo_slug",
      width: 300,
      render: (seo_slug) => {
        return <>/{seo_slug}</>;
      },
    },
    {
      title: t("common:seo.meta_title"),
      key: "seo_title",
    },
    {
      title: t("common:date_created"),
      key: "date_created",
      width: 100,
      render: (ts: number) => <TextDateTime ts={ts} />,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_: any, record: CmsNewsJson) => (
        <RoleCheck roles={[Role.CMS_NEWS_EDIT]} hideOnFail>
          <TableEdit link={"/cmsnews/edit/id/" + record.id} />
          <TableDelete
            error_translate_prefix="cmsnews:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new CmsNewsRepository()}
            id={record.id}
          />
        </RoleCheck>
      ),
    },
  ];

  return (
    <>
      <CmsNewsListHeader />

      <CmsNewsListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />

      <PageDataTable<FilterCmsNews, CmsNewsModel, CmsNewsCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          rowClassName: (record) =>
            record.status === CmsNews.STATUS_ENABLE
              ? ""
              : "table-row-stripes opacity-70",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
    </>
  );
};

export default CmsNewsList;
