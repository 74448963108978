import { List, Spin } from "antd";
import DataGridTableModel from "common/models/DataGridTableModel";
import DataGridTableRepository from "common/repositories/DataGridTableRepository";
import ErrorAlert from "components/ErrorAlert";
import TextDateTime from "components/TextDateTime";
import TextUser from "components/TextUser";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconTable } from "@tabler/icons-react";

const DataGridTableList = ({ workspaceId }: { workspaceId: number }) => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  // Table loading
  const [dataSource, setDataSource] = useState<DataGridTableModel[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTable = useCallback(async () => {
    setLoading(true);
    const collection = await new DataGridTableRepository().getItems({
      filters: {
        ...DataGridTableRepository.getDefaultFilters(),
        datagrid_workspace_id: workspaceId
      }
    });
    setLoading(false);
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setDataSource(collection.items);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (loading && errors.length === 0) {
      fetchTable();
    }
  }, [fetchTable, errors, loading]);

  return (
    <div className="px-2 mt-4">
      {loading ? (
        <div className="p-8 text-center spin-darkmode">
          <Spin />
        </div>
      ) : (
        <>
          {errors.length > 0 ? (
            <div className="text-center">
              <ErrorAlert
                items={errors}
                translate_prefix="datagridtable:form.error"
              />
            </div>
          ) : null}

          <div className="">
            {dataSource.length === 0 ? (
              <div className="">{t("datagridtable:empty")}</div>
            ) : (
              <div className="">
                <List
                  dataSource={dataSource}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <IconTable size={50} className="text-gray-500 " />
                        }
                        title={
                          <Link
                            title={item.name}
                            to={
                              "/etable/view/workspace/" +
                              workspaceId +
                              "/id/" +
                              item.id
                            }
                            className="leading-none">
                            <span className="text-lg font-semibold">
                              {item.name}
                            </span>
                          </Link>
                        }
                        description={
                          <div className="text-xs -mt-0.5">
                            {t("common:creator_id")}:&nbsp;
                            <TextUser id={item.creator_id} /> -&nbsp;
                            {t("common:date_created")}:&nbsp;
                            <TextDateTime ts={item.date_created} />
                          </div>
                        }
                      />
                    </List.Item>
                  )}></List>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DataGridTableList;
