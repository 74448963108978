import ProjectIteration from 'common/constants/ProjectIteration';
import { BaseModelJson } from 'common/interfaces/BaseModelJson';
import { SelectOption } from 'common/types/SelectOption';
import i18n from 'i18n';

import BaseModel from './BaseModel';

import type { ProjectIterationJson } from "common/types/ProjectIteration";

class ProjectIterationModel
  extends BaseModel
  implements BaseModelJson<ProjectIterationJson>
{
  company_id: number;
  creator_id: number;
  project_id: number;
  id: number;
  name: string;
  description: string;
  count_bug: number;
  count_story: number;
  is_deleted: number;
  status: number;
  date_completed?: number;
  date_completed_plan?: number;
  date_created?: number;
  date_deleted?: number;
  date_modified?: number;
  date_started?: number;

  constructor(json: ProjectIterationJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.project_id = json.project_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.count_bug = json.count_bug || 0;
    this.count_story = json.count_story || 0;
    this.is_deleted = json.is_deleted || 0;
    this.description = json.description || "";
    this.status = json.status || 0;
    this.date_completed = json.date_completed || 0;
    this.date_completed_plan = json.date_completed_plan || 0;
    this.date_created = json.date_created || 0;
    this.date_deleted = json.date_deleted || 0;
    this.date_modified = json.date_modified || 0;
    this.date_started = json.date_started || 0;
  }

  static getDefaultData(): ProjectIterationJson {
    return {
      company_id: 0,
      creator_id: 0,
      project_id: 0,
      id: 0,
      name: "",
      count_bug: 0,
      count_story: 0,
      is_deleted: 0,
      description: "",
      status: 0,
      date_completed: 0,
      date_completed_plan: 0,
      date_created: 0,
      date_deleted: 0,
      date_modified: 0,
      date_started: 0,
    };
  }

  toJson(): ProjectIterationJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      project_id: this.project_id,
      id: this.id,
      name: this.name,
      count_bug: this.count_bug,
      count_story: this.count_story,
      is_deleted: this.is_deleted,
      description: this.description,
      status: this.status,
      date_completed: this.date_completed,
      date_completed_plan: this.date_completed_plan,
      date_created: this.date_created,
      date_deleted: this.date_deleted,
      date_modified: this.date_modified,
      date_started: this.date_started,
    };
  }

  static getStatusList(): SelectOption[] {
    return [
      {
        value: ProjectIteration.STATUS_PENDING,
        label: i18n.t("projectiteration:STATUS_PENDING"),
        color: "yellow",
      },
      {
        value: ProjectIteration.STATUS_WORKING,
        label: i18n.t("projectiteration:STATUS_WORKING"),
        color: "blue",
      },
      {
        value: ProjectIteration.STATUS_COMPLETED,
        label: i18n.t("projectiteration:STATUS_COMPLETED"),
        color: "green",
      },
      {
        value: ProjectIteration.STATUS_EXPIRED,
        label: i18n.t("projectiteration:STATUS_EXPIRED"),
        color: "gray",
      },
    ];
  }

  static getStatus(value: number): SelectOption | undefined {
    return this.getStatusList().find((item) => item.value === value);
  }
}

export default ProjectIterationModel;
