import React from "react";
import { Typography } from "antd";

const SerpPreview = ({
  title,
  metaDescription,
  url,
  width,
}: {
  title: string;
  metaDescription: string;
  url: string;
  width: number;
}) => {
  const styles = {
    root: {
      fontFamily: "arial,sans-serif",
    },
    title: {
      color: "#1a0dab",
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: width,
    },
    url: {
      height: 18,
      color: "#006621",
      fontSize: 14,
      lineHeight: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    description: {
      color: "#545454",
      fontSize: 13,
      lineHeight: 1.4,
    },
  };

  return (
    <div style={{ ...styles.root, width }}>
      <Typography.Text style={styles.title}>{title}</Typography.Text>
      <div style={styles.url}>{url}</div>
      <Typography.Text style={styles.description}>
        {metaDescription}
      </Typography.Text>
    </div>
  );
};

export default SerpPreview;
