import { Alert } from "antd";

import { IconApple } from "@tabler/icons-react";

import HomeRightAction from "./right/HomeRightAction";
import HomeRightChangelog from "./right/HomeRightChangelog";
import HomeRightSupport from "./right/HomeRightSupport";
import HomeRightUtilities from "./right/HomeRightUtilities";

const HomeRight = () => {
  return (
    <>
      <div className="pt-6">
        <HomeRightAction />
        <HomeRightUtilities />
        {/* <HomeRightSupport /> */}
        {/* <HomeRightChangelog /> */}
        <Alert
          type="info"
          className="mx-6 mt-12"
          message="Mẹo hay"
          description="Bạn cần thêm hết sản phẩm trước khi bán hàng và nhập xuất kho. (Tính năng bán hàng và nhập xuất kho sử dụng thông tin sản phẩm được lưu trên trình duyệt.)"
          icon={<IconApple size={36} className="" />}
          showIcon
        ></Alert>
      </div>
    </>
  );
};

export default HomeRight;
