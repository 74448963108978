import { App, Button, Col, Divider, Form, Input, Row, Select } from "antd";
import WebHook from "common/constants/WebHook";
import WebHookModel from "common/models/WebHookModel";
import WebHookRepository from "common/repositories/WebHookRepository";
import FormSelect from "components/form/FormSelect";
import Error from "components/LayoutError";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import type { WebHookJsonAddEdit } from "common/types/WebHook";

const WebHookForm = ({
  id,
  model,
  setOpen,
  setProcessing,
  onSaveSuccess
}: {
  id: number;
  model: WebHookModel;
  setOpen: (v: boolean) => any;
  setProcessing: (p: boolean) => any;
  onSaveSuccess: (o: WebHookModel) => void;
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    const formData = {
      name: model.name,

      event: model.event,
      description: model.description,
      action: model.action,
      meta_data: model.meta_data,
      status: model.status > 0 ? model.status : WebHook.STATUS_ENABLE,
      url: "",
      group_event: "",
      headers: [{ key: "", value: "" }]
    };
    if (model.event.length > 0) {
      formData.group_event = model.event.split(".")[0];
    }
    if (model.meta_data.hasOwnProperty("webhook")) {
      if (model.meta_data.webhook.hasOwnProperty("url")) {
        formData.url = model.meta_data.webhook.url;
      }

      if (model.meta_data.webhook.hasOwnProperty("headers")) {
        var headers = [];
        for (let props in model.meta_data.webhook.headers) {
          headers.push({
            key: props,
            value: model.meta_data.webhook.headers[props]
          });
        }

        formData.headers = headers;
      }
    }

    return formData;
  }, [model]);

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let metaData = {
        webhook: {
          url: "",
          headers: {}
        }
      };

      metaData.webhook.url = formData.url;
      let refineHeaders: any = {};
      if (typeof formData.headers !== "undefined") {
        const headers = formData.headers;
        for (var i = 0; i < formData.headers.length; i++) {
          var header = headers[i];
          refineHeaders[header.key] = header.value;
        }
      }
      metaData.webhook.headers = refineHeaders;

      const submitData: WebHookJsonAddEdit = {
        ...WebHookModel.getDefaultData(),
        id: model.id,
        name: formData.name,
        event: formData.event,
        action: WebHook.ACTION_WEBHOOK,
        meta_data: metaData,
        status: formData.status || WebHook.STATUS_ENABLE
      };

      return submitData;
    },
    [model.id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    setProcessing(true);
    message.loading({
      content: t("common:form.processing"),
      key: "message",
      duration: 0
    });

    let item: WebHookModel = await new WebHookRepository().saveRemote(
      doPrepareData(formData)
    );

    setProcessing(false);
    if (item.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="webhook:form.error"
            items={item.error.errors}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 4
      });
    } else {
      message.success({
        content: t("common:form.success.save"),
        className: "message_success",
        key: "message",
        duration: 2
      });
      onSaveSuccess(item);
      setOpen(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
      id="webhook-form"
      onFieldsChange={(changedFields) => {
        //detect change group, reset event
        if (
          changedFields.length === 1 &&
          Array.isArray(changedFields[0].name) &&
          changedFields[0].name.includes("group_event") &&
          changedFields[0].touched
        ) {
          form.setFieldValue("event", null);
        }
      }}>
      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Form.Item
            label={t("webhook:group_event")}
            name="group_event"
            rules={[
              {
                required: true,
                message: t("webhook:form.error.group_event_required")
              }
            ]}>
            <Select options={WebHookModel.getGroupList()} />
          </Form.Item>
        </Col>
        <Col md={16} xs={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.group_event !== currentValues.group_event
            }>
            {({ getFieldValue }) => {
              switch (getFieldValue("group_event")) {
                case "order":
                  return (
                    <Form.Item
                      label={t("webhook:event")}
                      name="event"
                      rules={[
                        {
                          required: true,
                          message: t("webhook:form.error.event_required")
                        }
                      ]}>
                      <Select options={WebHookModel.getGroupOrderList()} />
                    </Form.Item>
                  );
                case "promotion":
                  return (
                    <Form.Item
                      label={t("webhook:event")}
                      name="event"
                      rules={[
                        {
                          required: true,
                          message: t("webhook:form.error.event_required")
                        }
                      ]}>
                      <Select options={WebHookModel.getGroupPromotionList()} />
                    </Form.Item>
                  );
                case "productreceipt":
                  return (
                    <Form.Item
                      label={t("webhook:event")}
                      name="event"
                      rules={[
                        {
                          required: true,
                          message: t("webhook:form.error.event_required")
                        }
                      ]}>
                      <Select options={WebHookModel.getGroupInventoryList()} />
                    </Form.Item>
                  );
                case "cashflowreceipt":
                  return (
                    <Form.Item
                      label={t("webhook:event")}
                      name="event"
                      rules={[
                        {
                          required: true,
                          message: t("webhook:form.error.event_required")
                        }
                      ]}>
                      <Select options={WebHookModel.getGroupCashflowList()} />
                    </Form.Item>
                  );
                case "customer":
                  return (
                    <Form.Item
                      label={t("webhook:event")}
                      name="event"
                      rules={[
                        {
                          required: true,
                          message: t("webhook:form.error.event_required")
                        }
                      ]}>
                      <Select options={WebHookModel.getGroupCustomerList()} />
                    </Form.Item>
                  );
                case "product":
                  return (
                    <Form.Item
                      label={t("webhook:event")}
                      name="event"
                      rules={[
                        {
                          required: true,
                          message: t("webhook:form.error.event_required")
                        }
                      ]}>
                      <Select options={WebHookModel.getGroupProductList()} />
                    </Form.Item>
                  );
                default:
                  return <></>;
              }
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Form.Item
            label={t("webhook:hook_url")}
            name="url"
            rules={[
              {
                required: true,
                message: t("webhook:form.error.url_required")
              }
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8} xs={24}>
          <FormSelect
            options={WebHookModel.getStatusList()}
            name="status"
            label={t("common:status")}
          />
        </Col>
      </Row>
      <Divider orientation="left">{t("webhook:form.header_value")}</Divider>
      <Row gutter={16}>
        <Col md={24}>
          <Form.List name="headers">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={16}>
                    <Col md={8}>
                      <Form.Item {...restField} name={[name, "key"]}>
                        <Input
                          placeholder={t("webhook:form.key")}
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={15}>
                      <Form.Item {...restField} name={[name, "value"]}>
                        <Input
                          placeholder={t("webhook:form.value")}
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={1}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}

                <Row gutter={16}>
                  <Col md={8}>
                    <Form.Item className="mb-0">
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}>
                        {t("webhook:form.add_row")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
};

export default WebHookForm;
