import { IconEye, IconLoader, IconPlus, IconTag } from "@tabler/icons-react";
import {
	Button,
	Image,
	Modal,
	Skeleton,
	Tabs,
	TabsProps,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";
import FileModel from "common/models/FileModel";
import ProductModel from "common/models/ProductModel";
import ProductCollectionRepository from "common/repositories/ProductCollectionRepository";
import { SelectOption, SelectOptionNormal } from "common/types/SelectOption";
import ErrorAlert from "components/ErrorAlert";
import NoPhoto from "components/NoPhoto";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import usePathParams from "hooks/usePathParams";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductJson2 } from "common/types/Product";
import ListProductCollectionDetail from "../list/children/ListProductCollectionDetail";
import { UsergroupDeleteOutlined } from "@ant-design/icons";

const ProductCollectionProductModal = ({
	id,
	open,
	setOpen,
	onSuccsec,
}: {
	id: number;
	open: boolean;
	setOpen: (v: boolean) => void;
	onSuccsec?: () => void;
}) => {
	const { t } = useTranslation();

	const [processing, setProcessing] = useState<boolean>(false);
	const [selectReload, setSelectReload] = useState<boolean>(false);
	const [existValues, setExistValue] = useState<number[]>([]);

	//add product to collection detail
	const onSubmitCollectionDetail = async (v: SelectOption[]) => {
		setProcessing(true);
		if (v.length > 0) {
			message.loading({ content: "Vui lòng đợi", duration: 1 });
			const response = await new ProductCollectionRepository().saveRemoteDetail(
				{
					collection_id: id,
					item_ids: v.map((item) => item.value),
				}
			);
			if (!response.hasError()) {
				message.success({ content: "Thao tác thành công", duration: 1 });
				setExistValue([...existValues, ...v.map((item) => item.value)]);
				setSelectReload(!selectReload);
				setProcessing(false);
				onSuccsec?.();
				return true;
			} else {
				message.error(response.error.errors);
				setProcessing(false);

				return false;
			}
		}
		setProcessing(false);
	};

	//delete product collection detial
	const onDeleteSelectedItem = async (v: SelectOption) => {
		setProcessing(true);
		message.loading({ content: "Vui lòng đợi", duration: 1 });

		const response = await new ProductCollectionRepository().deleteItemDetail({
			collection_id: id,
			product_ids: [Number(v.value)],
		});

		if (response.length === 0) {
			message.success({ content: "Thao tác thành công", duration: 1 });
			setExistValue(existValues.filter((item) => item !== Number(v.value)));
			setSelectReload(!selectReload);
			onSuccsec?.();
		} else {
			message.error("Thao tác thất bại");
		}

		setProcessing(false);
	};
	const [pathParams] = usePathParams();

	//addsku to item
	const handleAddSkuSuccess = async (skus: string[]) => {
		// const fetchModel = await new ProductCollectionRepository().getItem(
		//   Number(pathParams.id) || 0
		// );
		// if (!fetchModel.hasError()) {
		//   setExistValue(fetchModel.item_ids);
		// }
		setSelectReload(!selectReload);
	};

	const changeProductExistValue = (newTag: ProductModel[]) => {
		console.log("🚀 ~ changeProductExistValue ~ newTag:", newTag);
		setExistValue(newTag.map((item) => item.id));
	};

	const handleRemoveCurrentItem = async (v: SelectOptionNormal[]) => {
		setProcessing(true);

		if (v.length > 0) {
			const reponse = await new ProductCollectionRepository().deleteItemDetail({
				collection_id: id,
				product_ids: v.map((item) => Number(item.value)),
			});
			if (reponse.length === 0) {
				message.success({ content: "Thao tác thành công", duration: 1 });
				// setExistValue(existValues.filter((item) => item !== v.value));
				setSelectReload(!selectReload);
				setProcessing(false);

				return true;
			} else {
				message.error("Thao tác thất bại");
				setProcessing(false);

				return false;
			}
		}
	};

	const items: TabsProps["items"] = [
		{
			key: "1",
			label: "Thiết lập sản phẩm",
			children: (
				<>
					<AddListToItemComponent
						type="product"
						reload={selectReload}
						existValues={existValues}
						id={id}
						onSaveSuccess={onSubmitCollectionDetail}
						handleRemoveItem={(v: SelectOption) => {
							onDeleteSelectedItem(v);
						}}
						handleRemoveCurrentItem={handleRemoveCurrentItem}
						handleAddSkuSuccess={handleAddSkuSuccess}
						changeExistValue={changeProductExistValue}
						renderItems={(item: ProductModel) => {
							return (
								<>
									<div onClick={(e) => e.stopPropagation()}>
										{item.images.length > 0 ? (
											<></>
											// <Image
											// 	className="max-w-[35px] max-h-[35px]"
											// 	src={FileModel.getThumbnailFromFilePath(
											// 		item.images[0].file_path,
											// 		40,
											// 		40
											// 	)}
											// 	preview={{
											// 		src: item.images[0].url,
											// 		mask: <IconEye size="16" />,
											// 	}}
											// />
										) : (
											<NoPhoto width={35} height={35} />
										)}
									</div>

									<div className="flex flex-col">
										<div className="text-blue-600 text-md font-semibold">
											{item.full_name.length > 0 ? item.full_name : item.name}
										</div>
										<div className="text-xs text-gray-400 mt-1">
											Mã sản phẩm: {item.sku}
										</div>
									</div>
								</>
							);
						}}></AddListToItemComponent>
				</>
			),
		},
		{
			key: "2",
			label: "Thiết lập ưu tiên hiển thị",
			children: (
				<>
					<ListProductCollectionDetail
						collection_id={id}
						process="display"
						renderItems={(item: ProductJson2) => {
							return (
								<>
									{/* <div onClick={(e) => e.stopPropagation()}>
										{item.images.length > 0 ? (
											<Image
												className="max-w-[35px] max-h-[35px]"
												src={FileModel.getThumbnailFromFilePath(
													item.images[0].file_path,
													40,
													40
												)}
												preview={{
													src: item.images[0].url,
													mask: <IconEye size="16" />,
												}}
											/>
										) : (
											<NoPhoto width={35} height={35} />
										)}
									</div> */}

									<div className="flex flex-col">
										<div  className=" flex items-center gap-2">
											<p className="text-blue-600 text-md font-semibold mb-0">

											{item.full_name.length > 0 ? item.full_name : item.name}
											</p>
											<p>{item.parent_id === 0 ? <UsergroupDeleteOutlined /> : ""}</p>
										</div>
										<div className="text-xs text-gray-400 mt-1">
											Mã sản phẩm: {item.sku}
										</div>
									</div>
								</>
							);
						}}></ListProductCollectionDetail>
				</>
			),
		},
	];

	return (
		<Modal
			title={
				<>
					<div>
						<Typography.Text>{`Quản lý sản phẩm trong danh mục`}</Typography.Text>
						{processing && <IconLoader className="animate-spin" />}
					</div>
				</>
			}
			width={920}
			bodyStyle={{
				paddingTop: 8,
				paddingBottom: 8,
				overflowY: "auto",
				maxHeight: "calc(100vh - 250px)",
			}}
			open={open}
			confirmLoading={processing}
			onCancel={() => {
				setOpen(false);
			}}
			onOk={() => {
				setOpen(false);
			}}
			maskClosable={false}
			okButtonProps={{ style: { display: "none" } }}
			cancelText={"Đóng"}
			destroyOnClose>
			<Tabs items={items} destroyInactiveTabPane></Tabs>
		</Modal>
	);
};

export default ProductCollectionProductModal;
