import { useCallback, useMemo, useState } from "react";

import { message } from "antd";
import PageDataTable from "components/page/PageDataTable";
import useStateFilter from "hooks/useStateFilter";
import { useTranslation } from "react-i18next";

import useFilterLocation from "hooks/useFilterLocation";

//////////////////////////////////

import EmployeeCollection from "common/collections/EmployeeCollection";
import EmployeeModel from "common/models/EmployeeModel";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import { FilterEmployee } from "common/types/Employee";
import { TableColumnsType } from "common/types/Table";

import TextDateTime from "components/TextDateTime";
import PageDataPagination from "components/page/PageDataPagination";
import ContactMailListFilter from "./ContactMailListFilter";
import ContactMailListHeader from "./ContactMailListHeader";
import ContactMailRepository from "common/repositories/ContactMailRepository";
import { FilterContactMail } from "common/types/ContactMail";
import ContactMailModel from "common/models/ContactMailModel";
import ContactMailCollection from "common/collections/ContactMailCollection";

const ContactMailList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterContactMail = useMemo(
    () => ContactMailRepository.getDefaultFilters(),
    []
  );

  //state

  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<ContactMailModel[]>([]);

  const [filters, setFilters] = useStateFilter<FilterContactMail>(defaultFilters);
  console.log("🥺 ~ ContactMailList ~ filters:", filters)
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////

  //////////////////////////////////////////
  //Fetch data from this collections
  const fetchData = useCallback(async (): Promise<ContactMailCollection> => {
    let collection = await new ContactMailRepository().getItems({
      filters,
    });

    if (collection) {
      setTotal(collection.total);
      setDataSource(collection.items);
    } else {
      message.error(t("common:error.error_fetching_data"));
    }

    return collection;
  }, [filters]);

  //////////////////////////////////////////
  //Table columns

  const columns: TableColumnsType<ContactMailModel> = [
    {
      title: "ID",
      key: "id",
      width: 80,
    },
    {
      title: t("contactmail:email"),
      key: "contact",
    },
    {
      title: t("contactmail:date_created"),
      key: "date_created",
      render(value, record) {
        return (
          <TextDateTime ts={record.date_created} format="HH:mm, DD/MM/YYYY" />
        );
      },
    },
  ];

  return (
    <>
      <ContactMailListHeader />
      <ContactMailListFilter
        total={total}
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />

      <PageDataTable<FilterContactMail, ContactMailModel, ContactMailCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
          tableColumnToggleKey: "contactmail",
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />
      {/* <EmployeeLeaveFormModal
        setModel={setEmployee}
        model={employee}
        visible={showModal}
        setVisible={setShowModal}
        onSaveSuccess={onSaveSuccess}
      /> */}
    </>
  );
};

export default ContactMailList;
