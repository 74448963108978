import { BaseModelJson } from 'common/interfaces/BaseModelJson';

import BaseModel from './BaseModel';

import type { CustomerTypeJson } from "common/types/CustomerType";

class CustomerTypeModel
  extends BaseModel
  implements BaseModelJson<CustomerTypeJson>
{
  creator_id: number;
  company_id: number;
  id: number;
  name: string;
  code: string;
  description: string;
  auto_upgrade_quota: number;
  display_order: number;
  date_created: number;
  date_modified: number;

  constructor(json: CustomerTypeJson) {
    super();

    this.creator_id = json.creator_id || 0;
    this.company_id = json.company_id || 0;
    this.id = json.id || 0;
    this.name = json.name || "";
    this.code = json.code || "";
    this.description = json.description || "";
    this.auto_upgrade_quota = json.auto_upgrade_quota || 0;
    this.display_order = json.display_order || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
  }

  static getDefaultData(): CustomerTypeJson {
    return {
      creator_id: 0,
      company_id: 0,
      id: 0,
      name: "",
      code: "",
      description: "",
      auto_upgrade_quota: 0,
      display_order: 0,
      date_created: 0,
      date_modified: 0,
    };
  }

  toJson(): CustomerTypeJson {
    return {
      creator_id: this.creator_id,
      company_id: this.company_id,
      id: this.id,
      name: this.name,
      code: this.code,
      description: this.description,
      auto_upgrade_quota: this.auto_upgrade_quota,
      display_order: this.display_order,
      date_created: this.date_created,
      date_modified: this.date_modified,
    };
  }
}

export default CustomerTypeModel;
