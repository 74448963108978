import { BaseModelJson } from "common/interfaces/BaseModelJson";

import BaseModel from "./BaseModel";

import { TypeOfWorkJson } from "common/types/TypeOfWork";
class TypeOfWorkModel
  extends BaseModel
  implements BaseModelJson<TypeOfWorkJson>
{
  type_work_id: number;
  type_work_name: string;
  type_work_code: string;

  constructor(json: TypeOfWorkJson) {
    super();

    this.type_work_id = json.type_work_id || 0;
    this.type_work_name = json.type_work_name || "";
    this.type_work_code = json.type_work_code || "";
  }

  static getDefaultData(): TypeOfWorkJson {
    return {
      type_work_id: 0,
      type_work_name: "",
      type_work_code: "",
    };
  }

  toJson(): TypeOfWorkJson {
    return {
      type_work_id: this.type_work_id,
      type_work_name: this.type_work_name,
      type_work_code: this.type_work_code,
    };
  }
}

export default TypeOfWorkModel;
