import { Button, Result, Spin, Tabs, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import EmployeeRepository from "common/repositories/EmployeeRepository";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import EmployeeModel from "common/models/EmployeeModel";
import useStateFilter from "hooks/useStateFilter";
import { FilterTask, TaskEmployeeCollectionJson } from "common/types/Task";
import TaskRepository from "common/repositories/TaskRepository";
import useFilterLocation from "hooks/useFilterLocation";
import { EmployeeJson } from "common/types/Employee";
import { IdFetcherResult } from "common/types/IdFetcher";
import TaskModel from "common/models/TaskModel";
import TaskDepartmentListHeader from "./TaskDepartmentListHeader";
import TaskDeptKanban from "../kanban/TaskDeptKanban";
import TaskMOM from "../components/TaskMOM";
import TaskDepartment from "../components/TaskDepartment";
import useLoginAccountStore from "zustands/useLoginAccountStore";
import TaskBacklogList from "../backlog/TaskBacklogList";
import useDatabaseTable from "hooks/useDatabaseTable";

type Props = {
	type: "default" | "view" | "department" | "kanban" | "employeekanban";
};

const TaskDepartmentList = ({ type }: Props) => {
	const account = useLoginAccountStore();
	const [employeeItems] = useDatabaseTable<EmployeeJson>("employee");

	//state
	const [error, setError] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<TaskEmployeeCollectionJson>(
		TaskModel.getDefaultCurrentEmpData()
	);
	const [departments, setDepartments] = React.useState<IdFetcherResult[]>([]);
	const [employees, setEmployees] = React.useState<EmployeeJson[]>([]);
	// defaultState
	const defaultFilters: FilterTask = useMemo(
		() => TaskRepository.getDefaultFilter(),
		[]
	);
	const [filters, setFilters] = useStateFilter<FilterTask>(defaultFilters);
	useFilterLocation(defaultFilters, filters);

	// functions
	const fetchRecentTaskOfEmployee = async (department_id: number) => {
		const collection = await new TaskRepository().getItemsCurrentEmployee({
			filters: {
				...filters,
				department_id: department_id,
			},
		});

		if (collection) {
			setData(collection);
			// fetchInfoListEmployees(collection.items.map((i: any) => i.employee_id));
		}
		setLoading(false);
	};

	// fetch departments
	const fetchDepartments = useCallback(async (id_list: number[]) => {
		const collection = await new IdFetcherRepository().doFetching({
			object_type: "department",
			id_list,
		});

		setDepartments(
			collection.items.find((i) => i.object_type === "department")?.result || []
		);
	}, []);

	//fetch employees
	const fetchInfoListEmployees = useCallback(async (id_list: number[]) => {
		if (id_list.length > 0) {
			const collection = await new EmployeeRepository().getIdsItems({
				ids: id_list,
				object_type: "employee",
			});
			if (collection) {
				const newEmp = collection.items.map((e) =>
					new EmployeeModel(e).toJson()
				);
				setEmployees(newEmp);
			}
		}
	}, []);
	//

	React.useEffect(() => {
		fetchRecentTaskOfEmployee(account.account.company.employee.department_id);
		fetchDepartments([account.account.company.employee.department_id]);
	}, []);

	return (
		<>
			<TaskDepartmentListHeader />
			<Spin spinning={loading}>
				<Tabs defaultActiveKey={type === "kanban" ? "4" : "1"} className="px-6">
					{/* <Tabs.TabPane tab={<Typography.Text>Tất cả</Typography.Text>} key="1">
            {!loading && employees.length <= 0 ? (
              <Result
                status="warning"
                title="Danh sách nhân viên gặp lỗi."
                extra={
                  <Button type="primary" key="console">
                    Vui lòng tải lại !
                  </Button>
                }
              />
            ) : (
              <TaskDepartment
                data={data}
                department_id={account.account.company.employee.department_id}
                DepartmentList={departments}
                employeesList={employees}
              />
            )}
          </Tabs.TabPane> */}
					<Tabs.TabPane
						tab={
							<Typography.Text>
								<Typography.Text>
									MOM{", "}
									{new Date(Date.now()).toLocaleDateString("vi-VN", {
										weekday: "long",
										day: "numeric",
										month: "long",
									})}
								</Typography.Text>
							</Typography.Text>
						}
						key="2">
						{!loading && employeeItems.length <= 0 ? (
							<Result
								status="warning"
								title="Không có dữ liệu công việc của phòng ban."
								extra={
									<Button type="primary" key="console">
										Vui lòng tải lại !
									</Button>
								}
							/>
						) : (
							<TaskMOM
								department_id={account.account.company.employee.department_id}
								listEmployee={employeeItems}
							/>
						)}
					</Tabs.TabPane>
					{/* <Tabs.TabPane
            tab={
              <Typography.Text>
                <Typography.Text>Chưa hoàn thành</Typography.Text>
              </Typography.Text>
            }
            key="3"
            className="px-0"
          >
            <TaskBacklogList type="notdone" />
          </Tabs.TabPane> */}
					<Tabs.TabPane
						tab={
							<Typography.Text>
								<Typography.Text>Kanban phòng ban</Typography.Text>
							</Typography.Text>
						}
						key="4">
						<TaskDeptKanban
							department_id={account.account.company.employee.department_id}
							listEmployee={employeeItems}
						/>
					</Tabs.TabPane>
				</Tabs>
			</Spin>
		</>
	);
};

export default TaskDepartmentList;
