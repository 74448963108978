import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import ShippingOrder from "common/constants/ShippingOrder";
import ShippingRouteModel from "common/models/ShippingRouteModel";
import IdFetcherRepository from "common/repositories/IdFetcherRepository";
import ShippingRouteRepository from "common/repositories/ShippingRouteRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";

const ShippingRouteFormFilter = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  defaultOptions,
  placeholder,
  className,
  displayPickup,
  popupMatchSelectWidth,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  className?: string;
  displayPickup?: boolean;
  popupMatchSelectWidth?: boolean;
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const convertAndMappingPickup = async (items: ShippingRouteModel[]) => {
    const fetcherShippingHub = await new IdFetcherRepository().doFetching({
      object_type: "shippinghub",
      id_list: items
        .map((item) =>
          item.from_type === ShippingOrder.FROM_TYPE_OFFICE
            ? item.from_warehouse_id
            : 0,
        )
        .filter((i) => i > 0 && i !== undefined),
    });
    let fetcherShippingHubResults =
      fetcherShippingHub.items.find((i) => i.object_type === "shippinghub")
        ?.result || [];

    let shippingRouteOptions: SelectOption[] = [];
    items.forEach((item) => {
      let label = item.code;
      let getShippingHub = fetcherShippingHubResults.find(
        (i) =>
          i.value === item.from_warehouse_id &&
          item.from_type === ShippingOrder.FROM_TYPE_OFFICE,
      );

      if (getShippingHub?.text !== "[invalid]") {
        label += " - " + getShippingHub?.text;
      }

      return shippingRouteOptions.push({
        label: label,
        value: item.id,
      });
    });

    setOptions(shippingRouteOptions);
  };
  const fetchData = useCallback(async () => {
    const collection = await new ShippingRouteRepository().getItems({
      filters: ShippingRouteRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      if (displayPickup && !displayPickup) {
        setOptions(
          collection.items.map((item) => ({
            value: item.id,
            label: item.code,
          })),
        );
      } else {
        convertAndMappingPickup(collection.items);
      }
    }
    setLoading(false);
  }, [displayPickup]);

  useEffect(() => {
    if (defaultOptions && defaultOptions.length > 0) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (loading && typeof defaultOptions === "undefined") {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [loading, fetchData, defaultOptions]);

  return (
    <FormSelect
      className={className}
      disabled={disabled}
      required={required}
      loading={loading}
      showSearch
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
      value={value}
      onChange={onChange}
      labelAlign={labelAlign}
      allowClear={allowClear}
      placeholder={placeholder}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
};

export default ShippingRouteFormFilter;
