import Role from "common/constants/Role";
import PurchaseOrderModel from "common/models/PurchaseOrderModel";
import TableEdit from "components/table/TableEdit";
import React, { useMemo } from "react";
import useLoginAccountStore from "zustands/useLoginAccountStore";

const PurchaseOrderItemEdit = ({
  model,
  children,
}: {
  model: PurchaseOrderModel;
  children?: React.ReactNode;
}) => {
  const hasRoleAndLimit = useLoginAccountStore(
    (state) => state.hasRoleAndLimit
  );

  const havePermission = useMemo(() => {
    return (
      hasRoleAndLimit(Role.INVENTORY_STATUS_APPROVE, model.warehouse_id) ||
      hasRoleAndLimit(Role.INVENTORY_STATUS_CHECK, model.warehouse_id) ||
      hasRoleAndLimit(Role.INVENTORY_STATUS_COMPLETE, model.warehouse_id) ||
      hasRoleAndLimit(Role.INVENTORY_STATUS_CANCEL, model.warehouse_id)
    );
  }, [hasRoleAndLimit, model.warehouse_id]);

  return (
    <>
      {havePermission ? (
        <>
          {children || (
            <TableEdit link={"/purchaseorder/edit/id/" + model.id} />
          )}
        </>
      ) : null}
    </>
  );
};

export default PurchaseOrderItemEdit;
