import { Button, Divider, Image, Skeleton, Typography, message } from "antd";
import SettingApi from "common/constants/Setting";

import { ShopBannerHomeJson } from "common/types/ShopBannerHome";
import PageSortList from "components/list/PageSortList";
import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import ShopBannerHomeHeader from "./ShopBannerHomeListHeader";
import ShopBannerHomeForm from "./ShopBannerHomeForm";
import useHandleSetting from "hooks/useHandleSetting";
import ListDeleteButton from "components/list/ListDeleteButton";
import ShopBannerHomeFilter from "./ShopBannerHomeFilter";

const ShopBannerHomeList = () => {
  const defaultDataPopup: ShopBannerHomeJson = {
    id: 0,
    image: "",
    height: 0,
    width : 0,
    link: "",
    title: "",
    alt: "",
    image_mobile : ""
  };

  const [open, setOpen] = useState<boolean>(false);
  const [lang, setLang] = useState<string>("vi");

  const [data, setData] = useState<{
    data: ShopBannerHomeJson;
    index: number;
    type: "add" | "edit";
  }>({
    data: defaultDataPopup,
    index: -1,
    type: "add",
  });

  const {
    dataSource,
    loading,
    addItemSetting,
    deleteItemSetting,
    editItemSetting,
    handleDrag,
  } = useHandleSetting<ShopBannerHomeJson>(SettingApi.KEY.k_banner_home, lang);

  const handleDragEnd = async (result: DropResult) => {
    try {
      if (!result.destination) return;
      await handleDrag(result.source.index, result.destination.index);
      message.success({
        content: "Thao tác thành công",
        duration: 1,
      });
    } catch (error) {
      message.error({
        content: "Thao tác thất bại",
        duration: 1,
      });
    }
  };

  function handlelOpenModalAdd(data: ShopBannerHomeJson) {
    setData({
      data: data,
      type: "add",
      index: -1,
    });
    setOpen(true);
  }
  function handlelOpenModalEdit(data: ShopBannerHomeJson, index: number) {
    setData({
      data: data,
      type: "edit",
      index,
    });
    setOpen(true);
  }

  async function handleSumit(dataRes: ShopBannerHomeJson) {
    try {
      if (data.type === "add") {
        // await editItemSetting(data)
        await addItemSetting(dataRes);
      } else {
        await editItemSetting(dataRes, data.index);
      }
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }

  async function handleDelete(data: ShopBannerHomeJson, index: number) {
    try {
      await deleteItemSetting(data, index);
    } catch (error) {
    } finally {
      setOpen(false);
    }
  }

  const changeLang = (newLang: string) => {
    setLang(newLang);
  };

  return (
    <>
      <ShopBannerHomeHeader>
        <Button
          type="primary"
          onClick={() => handlelOpenModalAdd(defaultDataPopup)}
        >
          Thêm
        </Button>
      </ShopBannerHomeHeader>
      <ShopBannerHomeFilter defaultLang={lang} changeLang={changeLang} />
      <Divider />
      {!loading ? (
        <>
          <PageSortList
            dataSource={dataSource}
            handleDragEnd={handleDragEnd}
            listKey="shopBannerHome"
            renderListItem={(item: ShopBannerHomeJson, index) => {
              return (
                <>
                  <div className="p-2 hover:bg-gray-200">
                    <div className="flex  items-center justify-between">
                    
                      <div className=" w-2/3 relative">
                        <Image src={item.image} />
                        <p className=" font-bold text-xl  absolute top-4 right-4 w-10 h-10 rounded-full bg-black text-white flex justify-center items-center">{index + 1}</p>
                      </div>
                      <div className="  w-1/3 flex justify-center gap-2">
                        <Button
                          onClick={() => {
                            handlelOpenModalEdit(item, index);
                          }}
                        >
                          Sửa
                        </Button>

                        <ListDeleteButton
                          handleDelete={() => {
                            handleDelete(item, index);
                          }}
                          id={index}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        </>
      ) : (
        <Skeleton active />
      )}
      <ShopBannerHomeForm
        type={data.type}
        data={data.data}
        open={open}
        onCancel={() => setOpen(false)}
        onFinish={async (data) => {
          await handleSumit(data);
        }}
      />
    </>
  );
};

export default ShopBannerHomeList;
