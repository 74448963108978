import { Table, TableProps } from 'antd';
import BaseModel from 'common/models/BaseModel';
import { Filter } from 'common/types/Filter';
import { TableFilters, TablePaginationConfig, TableSorter } from 'common/types/Table';
import useBreakpoint from 'hooks/useBreakpoint';
import React from 'react';

const PageDataTableView = <F extends Filter, M extends BaseModel>({
  dataSource,
  loading,
  filters,
  columns,
  handleTableChange,
  children,
  expandable,
  rowClassName,
  rowSelection,
  onRow,
  rowKey,
}: {
  dataSource: any[];
  loading: boolean;
  filters: F;
  columns: any[];
  handleTableChange: (f: TableFilters, s: TableSorter<M>) => any;
  children: React.ReactNode;
  expandable?: TableProps<M>["expandable"];
  rowClassName?: TableProps<M>["rowClassName"];
  rowSelection?: TableProps<M>["rowSelection"];
  onRow?: TableProps<M>["onRow"];
  rowKey?: string;
}) => {
  const [isSmall] = useBreakpoint();

  const onChangeTable = (
    _: TablePaginationConfig,
    tablefilters: TableFilters,
    sorter: TableSorter<M>
  ) => {
    handleTableChange(tablefilters, sorter);
  };

  //detect scrollinfo if found "fixed" column(s)
  const scrollInfo =
    typeof columns.find(
      (c) => c.hasOwnProperty("fixed") && c.fixed.length > 0
    ) !== "undefined"
      ? { x: 600 }
      : {};

  return (
    <div className="pt-0.5 pb-1 px-0.5">
      {typeof children !== "undefined" ? (
        <div className="p-4">{children}</div>
      ) : null}

      <Table<M>
        loading={loading}
        className="pagedatatableview"
        rowKey={rowKey || "id"}
        size="small"
        sticky={{ offsetHeader: isSmall ? -2 : 59 }}
        scroll={scrollInfo}
        pagination={{
          pageSize: +filters.limit,
          hideOnSinglePage: true,
        }}
        rowClassName={rowClassName}
        columns={columns}
        dataSource={dataSource}
        onChange={onChangeTable}
        expandable={expandable}
        rowSelection={rowSelection}
        onRow={onRow}
      />
    </div>
  );
};

export default PageDataTableView;
