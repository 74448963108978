import Order from "common/constants/Order";
import OrderModel from "common/models/OrderModel";
import { OrderJson } from "common/types/Order";
import { Nav } from "common/types/PageNavigation";
import Helper from "common/utils/helper";
import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";

const OrderFormHeader = ({
	isEditing,
	id,
	type,
}: {
	isEditing: boolean;
	id: number | string;
	type?: number;
}) => {
	const { t } = useTranslation();

	const nav: Nav = [
		{
			text: t("order:heading_nav"),
			link: "/order",
		},
	];

	return (
		<>
			<PageHeader
				nav={nav}
				heading={
					t( isEditing ? type === Order.TYPE_INVOICE ?  "order:heading_edit_invoice": "order:heading_edit" : "order:heading_add") +
					(Helper.checkEditOrder(id) ? " #" + id : "")
				}
				siteMenuSelectedKey="/order"
				siteMenuHidden>

				</PageHeader>
		</>
	);
};

export default OrderFormHeader;
