import BaseCollection from "common/collections/BaseCollection";
import WarehouseStoreModel from "common/models/WarehouseStoreModel";
import {
	WarehouseStoreCollectionJson,
	WarehouseStoreJson,
} from "common/types/Warehouse";

class WarehouseStoreCollection extends BaseCollection<
	WarehouseStoreJson,
	WarehouseStoreModel,
	WarehouseStoreCollectionJson
> {
	itemsFromJson(jsonItems: WarehouseStoreJson[]): WarehouseStoreModel[] {
		return jsonItems.map((item) => new WarehouseStoreModel(item));
	}
}

export default WarehouseStoreCollection;
