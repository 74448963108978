import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RoleListHeader = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("role:heading_list")}
      siteMenuSelectedKey="/role"
      siteMenuOpenKey="setting_hrm"
    >
      {children}
    </PageHeader>
  );
};

export default RoleListHeader;
