import BaseProjectTaskCollection from 'common/collections/BaseProjectTaskCollection';
import ProjectTaskModel from 'common/models/ProjectTaskModel';
import { ProjectTaskCollectionJson, ProjectTaskJson } from 'common/types/ProjectTask';

class ProjectTaskCollection extends BaseProjectTaskCollection<
  ProjectTaskJson,
  ProjectTaskModel,
  ProjectTaskCollectionJson
> {
  itemsFromJson(jsonItems: ProjectTaskJson[]): ProjectTaskModel[] {
    return jsonItems.map((item) => new ProjectTaskModel(item));
  }
}

export default ProjectTaskCollection;
