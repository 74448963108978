import RbacObjectType from "common/constants/RbacObjectType";
import Role from "common/constants/Role";
import {
  RbacAssignedSubjectGroup,
  RbacSubjectGroup,
} from "common/types/RbacSubject";

class RbacSubjectRepository {
  static getAllSubjects(): RbacSubjectGroup[] {
    const groups: RbacSubjectGroup[] = [
      {
        name: "setting",
        items: [
          {
            id: Role.PRODUCT_MANAGE,
            ticket: "product.manage",
          },
          {
            id: Role.SETTING_SALE,
            ticket: "setting.sale",
          },
          {
            id: Role.SETTING_HRM,
            ticket: "setting.hrm",
          },
          {
            id: Role.SETTING_DMS,
            ticket: "setting.dms",
          },
          {
            id: Role.SETTING_ADVANCED,
            ticket: "setting.advanced",
          },
        ],
      },
      {
        name: "order",
        items: [
          {
            id: Role.ORDER_VIEW,
            ticket: "order.view",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.ORDER_ADD,
            ticket: "order.add",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.ORDER_EDIT,
            ticket: "order.edit",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.ORDER_MANAGE_SHIPPING,
            ticket: "order.manageshipping",
          },
          {
            id: Role.ORDER_STATUS_COMPLETE,
            ticket: "order.statuscomplete",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.ORDER_STATUS_CANCEL,
            ticket: "order.statuscancel",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.PROMOTION_VIEW,
            ticket: "promotion.view",
          },
          {
            id: Role.PROMOTION_MANAGE,
            ticket: "promotion.manage",
          },
          {
            id: Role.PRODUCT_VIEW_COST,
            ticket: "product.viewcost",
          },

          {
            id: Role.SELF_ORDER_VIEW,
            ticket: "self.order.view",
          },
          {
            id: Role.SELF_ORDER_ADD,
            ticket: "self.order.add",
          },
          {
            id: Role.SELF_ORDER_EDIT,
            ticket: "self.order.edit",
          },
          {
            id: Role.SELF_ORDER_DELETE,
            ticket: "self.order.delete",
          },


          {
            id: Role.CROSS_ORDER_VIEW,
            ticket: "cross.order.view",
          },
          {
            id: Role.CROSS_ORDER_ADD,
            ticket: "cross.order.add",
          },
          {
            id: Role.CROSS_ORDER_EDIT,
            ticket: "cross.order.edit",
          },
          {
            id: Role.CROSS_ORDER_DELETE,
            ticket: "cross.order.delete",
          },
        ],
      },
      {
        name: "inventory",
        items: [
          {
            id: Role.INVENTORY_NUMBER,
            ticket: "inventory.number",
            object_type: RbacObjectType.WAREHOUSE,
          },
          {
            id: Role.INVENTORY_VIEW,
            ticket: "inventory.view",
            object_type: RbacObjectType.WAREHOUSE,
          },
          {
            id: Role.INVENTORY_ADD,
            ticket: "inventory.add",
            object_type: RbacObjectType.WAREHOUSE,
          },
          {
            id: Role.INVENTORY_STATUS_APPROVE,
            ticket: "inventory.statusapprove",
            object_type: RbacObjectType.WAREHOUSE,
          },
          {
            id: Role.INVENTORY_STATUS_CHECK,
            ticket: "inventory.statuscheck",
            object_type: RbacObjectType.WAREHOUSE,
          },
          {
            id: Role.INVENTORY_STATUS_COMPLETE,
            ticket: "inventory.statuscomplete",
            object_type: RbacObjectType.WAREHOUSE,
          },
          {
            id: Role.INVENTORY_STATUS_CANCEL,
            ticket: "inventory.statuscancel",
            object_type: RbacObjectType.WAREHOUSE,
          },
          {
            id: Role.INVENTORY_TRANSFER_REQUEST,
            ticket: "inventory.transferrequest",
          },
          {
            id: Role.INVENTORY_TRANSFER_APPROVE,
            ticket: "inventory.transferapprove",
          },
          {
            id: Role.INVENTORY_STOCK_CHECK,
            ticket: "inventory.stockcheck",
            object_type: RbacObjectType.WAREHOUSE,
          },
        ],
      },
      {
        name: "purchaseorder",
        items: [
          {
            id: Role.PURCHASE_ORDER_VIEW,
            ticket: "purchaseorder.view",
          },
          {
            id: Role.PURCHASE_ORDER_ADD,
            ticket: "purchaseorder.add",
          },
          {
            id: Role.PURCHASE_ORDER_EDIT,
            ticket: "purchaseorder.edit",
          },
          {
            id: Role.PURCHASE_ORDER_STATUS_COMPLETE,
            ticket: "purchaseorder.statuscomplete",
          },
          {
            id: Role.PURCHASE_ORDER_STATUS_CANCEL,
            ticket: "purchaseorder.statuscancel",
          },
        ],
      },
      {
        name: "cashflow",
        items: [
          {
            id: Role.CASHFLOW_VIEW,
            ticket: "cashflow.view",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.CASHFLOW_ADD,
            ticket: "cashflow.add",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.CASHFLOW_EDIT,
            ticket: "cashflow.edit",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.CASHFLOW_STATUS_APPROVE,
            ticket: "cashflow.statusapprove",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.CASHFLOW_STATUS_COMPLETE,
            ticket: "cashflow.statuscomplete",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.CASHFLOW_STATUS_CANCEL,
            ticket: "cashflow.statuscancel",
            object_type: RbacObjectType.STORE,
          },
        ],
      },
      {
        name: "taxinvoice",
        items: [
          {
            id: Role.TAX_INVOICE_VIEW,
            ticket: "taxinvoice.view",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.TAX_INVOICE_EDIT_INFO,
            ticket: "taxinvoice.editinfo",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.TAX_INVOICE_ISSUE,
            ticket: "taxinvoice.issue",
            object_type: RbacObjectType.STORE,
          },
          {
            id: Role.TAX_INVOICE_SIGN,
            ticket: "taxinvoice.sign",
            object_type: RbacObjectType.STORE,
          },
        ],
      },
      {
        name: "customer",
        items: [
          {
            id: Role.CUSTOMER_VIEW,
            ticket: "customer.view",
          },
          {
            id: Role.CUSTOMER_MANAGE,
            ticket: "customer.manage",
          },
          {
            id: Role.CUSTOMER_POINT_VIEW,
            ticket: "customerpoint.view",
          },
          {
            id: Role.CUSTOMER_POINT_MANAGE,
            ticket: "customerpoint.manage",
          },
        ],
      },
      {
        name: "eoffice",
        items: [
          {
            id: Role.EMPLOYEE_MANAGE,
            ticket: "employee.manage",
          },
          {
            id: Role.CHECKIN_SCHEDULING,
            ticket: "checkin.scheduling",
            object_type: RbacObjectType.OFFICE,
          },
          {
            id: Role.CHECKIN_EDIT,
            ticket: "checkin.edit",
            object_type: RbacObjectType.OFFICE,
          },
          {
            id: Role.CHECKIN_VERIFY,
            ticket: "checkin.verify",
            object_type: RbacObjectType.OFFICE,
          },
          {
            id: Role.PROJECT_MANAGE,
            ticket: "project.manage",
          },
          {
            id: Role.FILE_MANAGE,
            ticket: "file.manage",
          },
          {
            id: Role.LEAVE_MANAGER,
            ticket: "leave.manage",
          },
          {
            id: Role.COMPANY_SETTING,
            ticket: "leave.assign",
          },
          {
            id: Role.FILE_UPLOAD,
            ticket: "file.upload",
          },
        ],
      },
      // {
      //   name: "cms",
      //   items: [
      //     {
      //       id: Role.SETTING_CMS,
      //       ticket: "setting.cms",
      //     },
      //     {
      //       id: Role.CMS_NEWS_VIEW,
      //       ticket: "cmsnews.view",
      //     },
      //     {
      //       id: Role.CMS_NEWS_ADD,
      //       ticket: "cmsnews.add",
      //     },
      //     {
      //       id: Role.CMS_NEWS_EDIT,
      //       ticket: "cmsnews.edit",
      //     },
      //     {
      //       id: Role.CMS_POST_VIEW,
      //       ticket: "cmspost.view",
      //     },
      //     {
      //       id: Role.CMS_POST_ADD,
      //       ticket: "cmspost.add",
      //     },
      //     {
      //       id: Role.CMS_POST_EDIT,
      //       ticket: "cmspost.edit",
      //     },
      //   ],
      // },
      {
        name: "shipping",
        items: [
          {
            id: Role.SHIPPER_VIEW,
            ticket: "shipper.view",
          },
          {
            id: Role.SHIPPER_MANAGE,
            ticket: "shipper.manage",
          },
          {
            id: Role.ECOM_SHIPPER_APPLY_MANAGE,
            ticket: "ecomshipperapply.manage",
          },
          {
            id: Role.SHIPPING_ORDER_MANAGE,
            ticket: "shippingorder.manage",
          },
          {
            id: Role.SHIPPING_ORDER_VIEW,
            ticket: "shippingorder.view",
          },
          {
            id: Role.SHIPPING_ROUTE_MANAGE,
            ticket: "shippingroute.manage",
          },
          {
            id: Role.SHIPPING_ROUTE_VIEW,
            ticket: "shippingroute.view",
          },
          {
            id: Role.DASHBOARD_VIEW,
            ticket: "dashboard.view",
          },
          {
            id: Role.SHIPPER_MANAGE_JOB,
            ticket: "shipper.managejob",
          },
        ],
      },
      // {
      //   name: "deliveryportal",
      //   items: [
      //     {
      //       id: Role.ECOM_NEWS_VIEW,
      //       ticket: "ecomnews.view",
      //     },
      //     {
      //       id: Role.ECOM_NEWS_MANAGE,
      //       ticket: "ecomnews.manage",
      //     },
      //     {
      //       id: Role.ECOM_POST_VIEW,
      //       ticket: "ecompost.view",
      //     },
      //     {
      //       id: Role.ECOM_POST_MANAGE,
      //       ticket: "ecompost.manage",
      //     },
      //     {
      //       id: Role.ECOM_BANNER_VIEW,
      //       ticket: "ecombanner.view",
      //     },
      //     {
      //       id: Role.ECOM_BANNER_MANAGE,
      //       ticket: "ecombanner.manage",
      //     },
      //   ],
      // },
      {
        name: "report",
        items: [
          {
            id: Role.REPORT_SALE,
            ticket: "report.sale",
          },
          {
            id: Role.REPORT_CASHFLOW,
            ticket: "report.cashflow",
          },
          {
            id: Role.REPORT_CUSTOMER,
            ticket: "report.customer",
          },
          {
            id: Role.REPORT_HRM,
            ticket: "report.hrm",
          },
          {
            id: Role.REPORT_DELIVERY,
            ticket: "report.delivery",
          },
        ],
      },
      {
        name: "delivery",
        items: [
          {
            id: Role.DELIVERY_SHIPPER,
            ticket: "delivery.shipper",
          },
          {
            id: Role.DELIVERY_MANAGER,
            ticket: "delivery.manager",
          },
          {
            id: Role.DELIVERY_ACCOUNTANTS,
            ticket: "delivery.accountants",
          },
          {
            id: Role.ORDER_MANAGER_DELIVERY,
            ticket: "order.managedelivery",
          },
        ],
      },
      {
        name: "content",
        items: [
          {
            id: Role.PUBLICATION_MANAGER,
            ticket: "publication.verify",
            object_type: RbacObjectType.OFFICE,
          },
          {
            id: Role.COMMENT_MANAGER,
            ticket: "comment.verify",
          },
          {
            id: Role.STORY_MANAGER,
            ticket: "story.verify",
          },
          {
            id: Role.COLLECTION_MANAGER,
            ticket: "collection.verify",
          },
          {
            id: Role.SOCIALLIVE_MANAGER,
            ticket: "socialive.verify",
          },
        ],
      },
      {
        name: "ailibrary",
        items: [
          {
            id: Role.AILIBRARY_MANAGER,
            ticket: "ailibrary.verify",
          },
        ],
      },
    ];

    return groups;
  }

  //find group with at least one items
  static findAssignedGroups(subjects: number[]): RbacAssignedSubjectGroup[] {
    const assignedGroups: RbacAssignedSubjectGroup[] = [];
    this.getAllSubjects().forEach((group) => {
      const assignedItems = group.items.filter((i) => subjects.includes(i.id));
      if (assignedItems.length > 0) {
        assignedGroups.push({
          ...group,
          assigned_items: assignedItems,
        });
      }
    });

    return assignedGroups;
  }
}

export default RbacSubjectRepository;
