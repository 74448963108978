import PageHeader from 'components/page/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SettingGeneralHeader = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      heading={t("setting:heading_general")}
      siteMenuSelectedKey="/setting/general"
      siteMenuOpenKey="setting_sale"
    >
      {children}
    </PageHeader>
  );
};

export default SettingGeneralHeader;
