import BaseCollection from "common/collections/BaseCollection";
import RbacRoleUserModel from "common/models/RbacRoleUserModel";
import {
  RoleUserJson,
  RoleUserCollectionJson,
} from "common/types/RbacRoleUser";

class RoleUserCollection extends BaseCollection<
  RoleUserJson,
  RbacRoleUserModel,
  RoleUserCollectionJson
> {
  itemsFromJson(jsonItems: RoleUserJson[]): RbacRoleUserModel[] {
    return jsonItems.map((item) => new RbacRoleUserModel(item));
  }
}

export default RoleUserCollection;
