import { Nav } from "common/types/PageNavigation";
import PageHeader from "components/page/PageHeader";
import { useTranslation } from "react-i18next";
import useEditingPurchaseOrderStore from "zustands/useEditingPurchaseOrder";

const PurchaseOrderFormHeader = () => {
  const { t } = useTranslation();

  const [purchaseOrder, supplier] = useEditingPurchaseOrderStore((state) => [
    state.purchaseOrder,
    state.supplier,
  ]);

  const nav: Nav = [
    {
      text: t("purchaseorder:heading_nav"),
      link: "/purchaseorder",
    },
  ];

  return (
    <>
      <PageHeader
        nav={nav}
        heading={
          t(
            purchaseOrder.id > 0
              ? "purchaseorder:heading_edit"
              : "purchaseorder:heading_add"
          ) +
          (purchaseOrder.id > 0 ? " #" + purchaseOrder.id : "") +
          " " +
          t("purchaseorder:heading_edit_supplier_text", {
            supplier: supplier.name,
          })
        }
        siteMenuSelectedKey="/purchaseorder"
        siteMenuHidden
      ></PageHeader>
    </>
  );
};

export default PurchaseOrderFormHeader;
