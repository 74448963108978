import { List } from "antd";
import ShippingOrderModel from "common/models/ShippingOrderModel";
import { DdCollapsible } from "common/types/DeliveryDashboard";
import { DistanceBetweenPointJson } from "common/types/ShippingRoute";
import DeliveryDashboardContext from "contexts/DeliveryDashboardContext";
import ShippingOrderFormModal from "features/shippingorder/form/ShippingOrderFormModal";
import update from "immutability-helper";
import React, { useCallback, useContext, useMemo, useState } from "react";
import useDeliveryDashboardStore from "zustands/useDeliveryDashboardStore";

import DeliveryDashboardSectionCenterOrderItem from "./DeliveryDashboardSectionCenterOrderItem";

const DeliveryDashboardOrderList = ({
  distanceBetweenPoint,
}: {
  distanceBetweenPoint: DistanceBetweenPointJson[];
}) => {
  const deliveryDashboardProvider = useContext(DeliveryDashboardContext);

  const [orderItemsByActiveRoute, setOrderItems] = useDeliveryDashboardStore(
    (state) => [state.orderItemsByActiveRoute, state.setOrderItems]
  );

  //////////////////////////////////////////
  // modal editing
  const [open, setOpen] = useState<boolean>(false);
  const [editingOrder, setEditingOrder] = useState<ShippingOrderModel>(
    new ShippingOrderModel(ShippingOrderModel.getDefaultData())
  );

  //////////////////////////////////////////
  // Delivery Dashboard Collapsible
  const getDefauOrderCollapsible = useMemo(() => {
    return deliveryDashboardProvider.orderItems.map((i) => {
      return {
        activeKey: i.id,
        isCollapsible: false,
      };
    });
  }, [deliveryDashboardProvider.orderItems]);

  const [orderCollapsible, setOrderCollapsible] = useState<DdCollapsible[]>(
    getDefauOrderCollapsible
  );

  const findDistancePreviousPoint = useCallback(
    (orderId: number) => {
      let distance = 0;
      let findItem = distanceBetweenPoint.find((i) => i.id === orderId);
      if (findItem) {
        distance = findItem.distance;
      }
      return distance;
    },
    [distanceBetweenPoint]
  );

  const onSaveSuccess = (item: ShippingOrderModel) => {
    const foundIndexByActiveRoute = orderItemsByActiveRoute.findIndex(
      (r) => r.id === item.id
    );

    if (foundIndexByActiveRoute >= 0) {
      //update current order item to store
      setOrderItems(
        update(orderItemsByActiveRoute, {
          [foundIndexByActiveRoute]: {
            $set: item,
          },
        })
      );
    }

    const foundIndexInProvider = deliveryDashboardProvider.orderItems.findIndex(
      (r) => r.id === item.id
    );
    if (foundIndexInProvider >= 0) {
      //update current order item to provider
      deliveryDashboardProvider.setOrderItems(
        update(deliveryDashboardProvider.orderItems, {
          [foundIndexInProvider]: { $set: item },
        })
      );
    }
  };

  return (
    <>
      {orderItemsByActiveRoute.length > 0 ? (
        <List
          className="cursor-pointer"
          dataSource={orderItemsByActiveRoute}
          renderItem={(item: ShippingOrderModel, index: number) => {
            return (
              <DeliveryDashboardSectionCenterOrderItem
                key={index}
                item={item}
                index={index}
                setOpen={setOpen}
                setEditingOrder={setEditingOrder}
                orderCollapsible={orderCollapsible}
                setOrderCollapsible={setOrderCollapsible}
                distanceFromPreviousPoint={findDistancePreviousPoint(item.id)}
              />
            );
          }}
        ></List>
      ) : null}

      {editingOrder.id > 0 && open ? (
        <ShippingOrderFormModal
          editingOrder={editingOrder}
          key={editingOrder.id}
          open={open}
          setOpen={(isOpen) => {
            //clear when close
            if (!isOpen) {
              setEditingOrder(
                new ShippingOrderModel(ShippingOrderModel.getDefaultData())
              );
            }
            setOpen(isOpen);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      ) : null}
    </>
  );
};

export default DeliveryDashboardOrderList;
