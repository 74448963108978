import { FormLabelAlign } from "antd/es/form/interface";
import { SelectValue } from "antd/es/select";
import CmsNewsCategoryRepository from "common/repositories/CmsNewsCategoryRepository";
import { SelectOption } from "common/types/SelectOption";
import FormSelect from "components/form/FormSelect";
import React, { useCallback, useEffect, useState } from "react";

const CmsNewsCategoryFormFilter = ({
  label,
  name,
  disabled,
  value,
  onChange,
  labelAlign,
  required,
  allowClear,
  defaultOptions,
  placeholder,
  className,
  popupMatchSelectWidth,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
  value?: SelectValue;
  onChange?: (v: SelectValue) => void;
  labelAlign?: FormLabelAlign;
  required?: boolean;
  allowClear?: boolean;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  className?: string;
  popupMatchSelectWidth?: boolean;
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>(defaultOptions || []);

  const fetchData = useCallback(async () => {
    const collection = await new CmsNewsCategoryRepository().getItems({
      filters: CmsNewsCategoryRepository.getDefaultFilters(),
    });
    if (collection.hasError()) {
      setErrors(collection.error.errors);
    } else {
      setOptions(
        collection.items.map((item) => ({
          value: item.id,
          label: item.name,
          parent_id: item.parent_id,
        })),
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading && typeof defaultOptions === "undefined") {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [loading, fetchData, defaultOptions]);

  return (
    <FormSelect
      className={className}
      disabled={disabled}
      required={required}
      loading={loading}
      showSearch
      label={label}
      name={name}
      options={options}
      errors={errors}
      errorRetry={fetchData}
      value={value}
      onChange={onChange}
      labelAlign={labelAlign}
      allowClear={allowClear}
      placeholder={placeholder}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
};

export default CmsNewsCategoryFormFilter;
