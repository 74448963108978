import FileModel from 'common/models/FileModel'
import FileRepository from 'common/repositories/FileRepository'
import { useEffect, useState } from 'react'


interface Files {
  [id: number]: FileModel | undefined
}
export default function useGetfilesByIds(ids: number[]) {
  const [files, setFiles] = useState<Files | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string[]>([])

  useEffect(() => {
    (async () => {
      if (!ids.length) {
        setLoading(false)
        return
      }
      const filter = FileRepository.getDefaultFilters()
      setLoading(true)
      const idsRoot = ids.join(",")

      const files = await new FileRepository().getFileAndDirectories({ filters: { ...filter, object_type: 30, origin: "avatar", list_file_ids: idsRoot } })

      if (files.hasError()) {
        setError(files.error.errors)
        return
      }


      const filesOj = ids.reduce((pre: Files, id) => {
        pre[id] = files.items.find(file => file.id === id)
        return pre
      }, {})

      setFiles(filesOj)
      setLoading(false)
    })()

  }, [JSON.stringify(ids)])


  return {
    files,
    isLoading,
    error
  }


}
