import dayjs from "dayjs";
import ReactQuill from "react-quill";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { IconPhoto, IconPlus, IconTrash } from "@tabler/icons-react";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import {
	Button,
	Col,
	Collapse,
	DatePicker,
	Divider,
	Form,
	Image,
	Input,
	Popconfirm,
	Row,
	Select,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";

import StoryFormHeader from "./StoryFormHeader";
import LayoutForm from "components/form/LayoutForm";
import FormSection from "components/form/FormSection";
import FormSelectNormal from "components/form/FormSelectNormal";
import FileUploaderButton from "components/file/uploader/FileUploaderButton";
// import StoryCollectionFormSelect from "../components/StoryCollectionFormSelect";

import Helper from "common/utils/helper";
import FileModel from "common/models/FileModel";
import StoryModel from "common/models/StoryModel";
import Collection from "common/constants/Collection";
import { StoryJsonAddEdit } from "common/types/Story";
import StoryRepository from "common/repositories/StoryRepository";
import CollectionRepository from "common/repositories/CollectionRepository";
import { Category, CollectionCascadeOptions } from "common/types/Collection";
import StoryCollectionConstant from "common/constants/StoryCollection";
import StoryCollectionFormSelect from "features/storycollection/form/StoryCollectionFormSelect";
import StoryCollectionRepository from "common/repositories/StoryCollectionRepository";
import FormSelect from "components/form/FormSelect";
import TagModel from "common/models/TagModel";
import AddListToItemComponent from "features/productcollection/form/children/AddListToItemComponent";
import { SelectOption } from "common/types/SelectOption";
import TagRepository from "common/repositories/TagRepository";
import TagConstant from "common/constants/Tag";
import TagDetailModel from "common/models/TagDetailModel";
import TextArea from "antd/es/input/TextArea";
import useGetfilesByIds from "hooks/useGetfilesByIds";

type Props = {
	model: StoryModel;
};
const StoryForm = ({ model }: Props) => {
	const [form] = useForm();
	const { t } = useTranslation();
	const isEditing = model.id > 0;

	//state
	const [isSuccess, setIsSuccess] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [typeCollection, setTypeCollection] = useState(Collection.TYPE_STORY);
	const [selectedStoryCollection, setSelectedStoryCollection] = useState<
		number[]
	>(model.collections.map((item) => item.id));




	const [images, setImages] = useState<{
		banner_desktop: FileModel | null ,
		banner_mobile:  FileModel | null ,
		images_card:  FileModel | null 
	}>({
		banner_desktop: null,
		banner_mobile: null,
		images_card: null
	});

	//default data
	const initialValues = useMemo(() => {
		return {
			title: model.title,
			description: model.description,
			keyword: model.keyword,
			present: model.present,
			quote: model.quote,
			status: model.id === 0 ? 1 : model.status,
			publish_from:
				model.publish_from > 0 ? dayjs(new Date(model.publish_from * 1000)) : 0,
			publish_to:
				model.publish_to > 0 ? dayjs(new Date(model.publish_to * 1000)) : 0,
			date_published:
				model.date_published > 0
					? dayjs(new Date(model.date_published * 1000))
					: 0,
			detail: model.detail,
			handle: model.handle,
			pin: model.pin,
			banner_desktop: [],
			banner_mobile: [],
			images:[],
			content_type:
				model.content_type !== "0" && model.content_type !== ""
					? model.content_type.split(",").map(Number)
					: [],
			type: model.type > 0 ? model.type : null,
		};
	}, [model]);

	//prepare data for submit
	const doPrepareData = useCallback(
		(formData: any) => {
			const submitData: Partial<StoryJsonAddEdit> = {
				id: model.id,
				title: formData.title,
				description: formData.description,
				keyword: formData.keyword,
				present: formData.present,
				quote: formData.quote,
				status: formData.status,
				publish_from:
					Helper.datePickerValueToTimestamp(formData.publish_from) > 0
						? Helper.datePickerValueToTimestamp(formData.publish_from)
						: -1,
				publish_to:
					Helper.datePickerValueToTimestamp(formData.publish_to) > 0
						? Helper.datePickerValueToTimestamp(formData.publish_to)
						: -1,
				date_published:
					Helper.datePickerValueToTimestamp(formData.date_published) > 0
						? Helper.datePickerValueToTimestamp(formData.date_published)
						: -1,
				detail: formData.detail,
				handle: formData.handle ? formData.handle : undefined,
				banner_desktop:images.banner_desktop ?  images.banner_desktop.id :  undefined,
				banner_mobile: images.banner_mobile ?  images.banner_mobile.id :  undefined,
				image: images.images_card ?  images.images_card.id.toString() :  undefined,
				collection_id: selectedStoryCollection.toString(),
				content_type:
					formData.content_type.length > 0
						? formData.content_type.join(",")
						: "0",
				type: formData.type,
				pin: formData.pin,
			};

			return submitData;
		},
		[images.banner_desktop, images.banner_mobile, images.images_card, model.id, selectedStoryCollection]
	);


	//submit data to server
	const onSubmit = async (formData: StoryJsonAddEdit) => {
		const response = await new StoryRepository().saveRemote(
			doPrepareData(formData)
		);
		if (!response.hasError()) {
				setIsSuccess(true);

			if (selectedStoryCollection.length > 0) {
				const responseDetail =
					await new StoryCollectionRepository().saveRemoteDetail({
						collection_id: selectedStoryCollection,
						item_id: model.id > 0 ? model.id : response.id,
					});
				if (!responseDetail.hasError()) {
					setIsSuccess(true);
				} else {
					setErrors(responseDetail.error.errors);
				}
			} else {
				setIsSuccess(true);
			}
		} else {
			setErrors(response.error.errors);
		}
	};

	const sidebarItems = <></>;

	const toolbarOptions = [
		[{ font: [] }],
		// [{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ size: ["small", false, "large", "huge"] }],
		["bold", "italic", "underline", "strike"],
		[{ align: [] }],
		[{ color: [] }, { background: [] }],
		[{ script: "super" }, { script: "sub" }],
		[{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["direction"],
		["link", "image", "video", "formula"],
		["clean"],
	];


	const imgIds = model.images.split(",").map(Number)


	const { files } = useGetfilesByIds([...imgIds, model.banner_desktop, model.banner_mobile].filter(Boolean))
		useEffect(() => {
		if (files) {
			setImages((prev) => ({
				...prev,
				banner_desktop:files[model.banner_desktop] || null,
				banner_mobile: files[model.banner_mobile] || null,
				images_card: files[imgIds?.[0]] || null,
			}));
		}
	}, [files , JSON.stringify(imgIds),model.banner_desktop , model.banner_mobile]);
	return (
		<div>
			<StoryFormHeader isEditing={isEditing} />
			<LayoutForm
				form={form}
				initialValues={initialValues}
				errors={errors}
				isSuccess={isSuccess}
				successTitle={
					isEditing
						? t("common:form.success.general")
						: t("common:form.success.save")
				}
				error_translate_prefix="common:form.error"
				onSubmit={onSubmit}
				submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
				sidebarItems={sidebarItems}>
				<FormSection
					title={t("story:form.section_main")}
					subtitle={t("story:form.section_subtitle_main")}
					divider>
					<Row gutter={16}>
						<Col md={12} xs={24}>
							<Form.Item
								name="title"
								label={t("story:title")}
								required
								rules={[
									{
										required: true,
										message: t("story:rules.title"),
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<FormSelectNormal
								required
								name="type"
								label={t("story:story_type")}
								placeholder={t("story:story_type")}
								onChange={(e) => {
									setTypeCollection(Number(e));
								}}
								rules={[
									{
										required: true,
										message: t("story:rules.story_type"),
									},
								]}
								options={[
									{
										value: StoryCollectionConstant.TYPE_ACADEMY,
										label: "Academy",
									},
									{
										value: StoryCollectionConstant.TYPE_ADMIN,
										label: "Admin",
									},
									{
										value: StoryCollectionConstant.TYPE_STORY,
										label: "Blog",
									},
								]}
							/>
						</Col>
						
						<Col md={24} xs={24}>
							<Form.Item
								required
								name="collection_id"
								label="Danh mục"
								rules={[
									{
										message: "Bạn chưa chọn danh mục",
										validator: (_: any, value: string) => {
											if (selectedStoryCollection.length > 0) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<StoryCollectionFormSelect
									placeholder="Chọn loại danh mục"
									values={selectedStoryCollection}
									onChange={(v) => {
										if (v.length > 0) {
											setSelectedStoryCollection(v);
										} else {
											setSelectedStoryCollection([]);
										}
									}}
									existData={model.collections}
									multiple={true}
									isMulti={true}
								/>
							</Form.Item>
						</Col>
						<Col md={24} xs={24}>
							<Form.Item name="handle" label={t("story:handle")}>
								<Input placeholder={t("product:placeholder_URL")} />
							</Form.Item>
						</Col>
						<Col md={24} xs={24}>
							<Form.Item
								name="detail"
								label={t("story:detail")}
								required
								validateFirst
								rules={[
									{
										required: true,
										message: t("story:rules.detail"),
									},
									{
										message: t("story:rules.detail"),
										validator: (_: any, value: string) => {
											if (value !== "<p><br></p>") {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<ReactQuill
									theme="snow"
									style={{
										height: "300px",
										width: "100%",
										marginBottom: "70px",
									}}
									modules={{
										toolbar: toolbarOptions,
									}}
								/>
							</Form.Item>
						</Col>

						{/* <Col md={24} xs={24}>
							<FormSelect
								name="type"
								label="Hiển thị thông báo"
								options={[
									{ label: "Có", value: 1 },
									{ label: "Không", value: 0 },
								]}
							/>
						</Col> */}
					</Row>
				</FormSection>
				<FormSection
					title={t("story:form.section_sub")}
					subtitle={t("story:form.section_subtitle_sub")}>
					<Row gutter={[8, 8]}>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("productcollection:effective_date")}
								name="date_published">
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("story:publish_from")}
								name="publish_from"
								style={{ width: "100%" }}>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
								/>
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item
								label={t("story:publish_to")}
								name="publish_to"
								style={{ width: "100%" }}
								rules={[
									{
										message: t("leave:form.error.error_range_date"),
										validator: (_: any, value: any) => {
											if (
												Helper.datePickerValueToTimestamp(
													form.getFieldValue("publish_from")
												) < Helper.datePickerValueToTimestamp(value) ||
												!Helper.datePickerValueToTimestamp(value)
											) {
												return Promise.resolve();
											} else {
												return Promise.reject("Some message here");
											}
										},
									},
								]}>
								<DatePicker
									style={{ width: "100%" }}
									placeholder={"HH:mm dd/mm/yyyy"}
									format="HH:mm DD/MM/YYYY"
									showTime
									disabledDate={(current) => {
										return current.isBefore(form.getFieldValue("publish_from"));
									}}
								/>
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<FormSelectNormal
								name="status"
								label={t("story:display")}
								options={StoryModel.getDisplayList()}
							/>
						</Col>
						<Col md={8} xs={8}>
							<FormSelect
								name="pin"
								label="Ghim"
								options={[
									{ label: "Có", value: 1 },
									{ label: "Không", value: 0 },
								]}
							/>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item name="keyword" label={t("story:keyword")}>
								<Input placeholder="" />
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item name="quote" label={t("story:quote")}>
								<Input placeholder={t("story:quote_placeholder")} />
							</Form.Item>
						</Col>
						<Col md={8} xs={24}>
							<Form.Item name="content_type" label={t("story:attachments")}>
								<Select
									mode="multiple"
									placeholder={t("story:attachments_placeholder")}
									options={[
										{
											label: t("story:image"),
											value: Collection.CONTENT_TYPE.CONTENT_IMAGE,
										},
										{
											label: t("story:video"),
											value: Collection.CONTENT_TYPE.CONTENT_VIDEO,
										},
										{
											label: t("story:audio"),
											value: Collection.CONTENT_TYPE.CONTENT_AUDIO,
										},
									]}></Select>
							</Form.Item>
						</Col>

						<Col md={24} xs={24} span={24}>
							<Collapse className="mt-4 mb-2 bg-white">
								<Collapse.Panel header={t("collection:image_group")} key={"1"}>
									<Row gutter={3} >

									<Col md={12}>
              {images.images_card ? (
                <>
                  <p>images_card</p>
                  <div className="flex items-start">
                    <Image
                      className="cursor-pointer"
                      src={images.images_card.url}
                      width={640}
                      height={225}
                      preview={true}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImages(pre=>({...pre,images_card : null}));
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="images"
                      label={"Ảnh đại diện"}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
												console.log(f)
                        setImages(pre=>({...pre,images_card : f}));

                      }}
                      showInput
                    />
                  </Form.Item>
                </>
              )}
           			 </Col>


						<Col md={12}>
              {images.banner_mobile ? (
                <>
                  <p>banner_mobile</p>
                  <div className="flex items-start">
                    <Image
                      className="cursor-pointer"
                      src={images.banner_mobile.url}
                      width={640}
                      height={225}
                      preview={true}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImages(pre=>({...pre,banner_mobile : null}));
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="12312"
                      label={"Ảnh banner mobile"}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setImages(pre=>({...pre,banner_mobile : f}));
                      }}
                      showInput
                    />
                  </Form.Item>
                </>
              )}
            </Col>
						<Col md={24}>
              {images.banner_desktop ? (
                <>
                  <p>banner_desktop</p>
                  <div className="flex items-start">
                    <Image
                      className="cursor-pointer"
                      src={images.banner_desktop.url}
                      width={640}
                      height={225}
                      preview={true}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Bạn có muốn xóa ảnh"
                      onConfirm={() => {
                        setImages(pre=>({...pre,banner_desktop : null}));
                      }}
                    >
                      <Button
                        icon={
                          <IconTrash className="w-4 h-4 text-gray-500 hover:text-red-500" />
                        }
                      ></Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  <Form.Item className="m-0">
                    <FileUploaderButton
                      form={form}
                      formName="banner_desktop"
                      label={"Ảnh banner desktop"}
                      icon={<IconPhoto size="24" color="grey" />}
                      origin="avatar"
                      uploadRenderType="thumbnail"
                      isSingleFile={true}
                      maxCount={1}
                      initFiles={[]}
                      onUploadCompleted={(f) => {
                        setImages(pre=>({...pre,banner_desktop : f}));

                      }}
                      showInput
                    />
                  </Form.Item>
                </>
              )}
            </Col>

				
									
									</Row>
								</Collapse.Panel>
							</Collapse>
						</Col>
						<Col md={24} xs={24}>
							<Form.Item name="description" label={t("Mô tả bài viết")}>
								<TextArea rows={4} />
							</Form.Item>
						</Col>
					</Row>
				</FormSection>
			</LayoutForm>
		</div>
	);
};

export default StoryForm;
