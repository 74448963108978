import BaseCollection from 'common/collections/BaseCollection';
import ProjectIterationModel from 'common/models/ProjectIterationModel';
import {
    ProjectIterationCollectionJson, ProjectIterationJson
} from 'common/types/ProjectIteration';

class ProjectIterationCollection extends BaseCollection<
  ProjectIterationJson,
  ProjectIterationModel,
  ProjectIterationCollectionJson
> {
  itemsFromJson(jsonItems: ProjectIterationJson[]): ProjectIterationModel[] {
    return jsonItems.map((item) => new ProjectIterationModel(item));
  }
}

export default ProjectIterationCollection;
