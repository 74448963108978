import { Button } from 'antd';
import { PosCartOrderDetail } from 'common/types/PosCart';

const PosRightPromotionOrderGiftItem = ({
  gift,
  onRemove,
}: {
  gift: PosCartOrderDetail;
  onRemove: () => void;
}) => {
  return (
    <div className="bg-green-50 border border-green-500 border-dashed text-green-700 text-xs p-2 my-2">
      <Button
        type="default"
        className="float-right"
        size="small"
        onClick={() => onRemove()}
      >
        Xoá
      </Button>
      Quà tặng: <br />{" "}
      <strong>
        {gift.item_name} <br />({gift.sku})
      </strong>
    </div>
  );
};

export default PosRightPromotionOrderGiftItem;
