import React, { useCallback, useEffect, useState } from "react";
import { Alert, App, Spin } from "antd";
import Error from "components/Error";
import { useTranslation } from "react-i18next";

import useLoginAccountStore from "zustands/useLoginAccountStore";
import CheckinV2Form from "./form/CheckinV2Form";

import CheckinWrapperHeader from "features/checkin/CheckinWrapperHeader";
import CheckinRepository from "common/repositories/CheckinRepository";
import CheckinModel from "common/models/CheckinModel";
import CheckinV2WrapperHeader from "./CheckinV2WrapperHeader";

const CheckinV2Wrapper = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const account = useLoginAccountStore((state) => state.account);

  ///////////////////////
  //state
  const [loadingPendingWorkTracking, setLoadingPendingWorkTracking] =
    useState(true);
  const [isCheckin, setIsCheckin] = useState<boolean>(false);

  const [isDoneCheckin, setIsDoneCheckin] = useState<boolean>(false);

  const [pendingWorkTracking, setPendingWorkTracking] =
    useState<CheckinModel>();

  ///////////////////////
  //function
  const fetchPendingWorkTracking = useCallback(async () => {
    let collection = await new CheckinRepository().getItemsToday({
      filters: {
        ...CheckinRepository.getDefaultFilters(),
        employee_id: account.company.employee.id,
        limit: 500,
      },
    });
    if (collection.hasError()) {
      message.error({
        content: (
          <Error
            onClickClose={() => {
              message.destroy("message");
            }}
            heading={t("common:form.error.heading")}
            translate_prefix="worktracking:form.error"
            items={collection.error.errors}
            contentPadding={0}
          />
        ),
        className: "message_error",
        key: "message",
        duration: 3,
      });
    } else {
      if (collection.items.length > 0) {
        setIsCheckin(collection.items.length % 2 === 0); // Kiểm tra lần này có phải là checkin hay checkout ( set = true nếu là checkin)
        setPendingWorkTracking(collection.items[0]);
        setIsDoneCheckin(collection.items.length === 2);
      } else {
        setIsCheckin(true);
      }
    }
    setLoadingPendingWorkTracking(false);
  }, [isCheckin]);

  // useEffect(() => {
  //   if (
  //     loadingPendingWorkTracking &&
  //     account.company.employee.checkin_type !== Employee.CHECKIN_TYPE_IGNORE
  //   ) {
  //     fetchPendingWorkTracking();
  //   }
  // }, [
  //   loadingPendingWorkTracking,
  //   fetchPendingWorkTracking,
  //   account.company.employee.checkin_type,
  // ]);
  useEffect(() => {
    if (loadingPendingWorkTracking) {
      fetchPendingWorkTracking();
    }
  }, [loadingPendingWorkTracking, fetchPendingWorkTracking]);
  return (
    <>
      <CheckinV2WrapperHeader />
      {/* account.company.employee.checkin_type === Employee.CHECKIN_TYPE_IGNORE  */}
      {false ? (
        <Alert
          message={t("worktracking:form.error.warning_checkin_type_ignore")}
          type="warning"
        />
      ) : (
        <div className="p-8">
          {loadingPendingWorkTracking ? (
            <div>
              <Spin size="small" className="mr-2" />{" "}
              {t("worktracking:fetching_pending")}
            </div>
          ) : (
            <CheckinV2Form
              pending={pendingWorkTracking}
              isCheckin={isCheckin}
              fetchData={fetchPendingWorkTracking}
              isDoneCheckin={isDoneCheckin}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CheckinV2Wrapper;
