import { Button } from "antd";
import ShippingHubCollection from "common/collections/ShippingHubCollection";
import Role from "common/constants/Role";
import ShippingHubModel from "common/models/ShippingHubModel";
import ShippingHubRepository from "common/repositories/ShippingHubRepository";
import { FilterShippingHub } from "common/types/ShippingHub";
import { TableColumnsType } from "common/types/Table";
import PageDataPagination from "components/page/PageDataPagination";
import PageDataTable from "components/page/PageDataTable";
import RoleCheck from "components/RoleCheck";
import TableDelete from "components/table/TableDelete";
import TableEdit from "components/table/TableEdit";
import TableInfo from "components/table/TableInfo";
import TableLinkText from "components/table/TableLinkText";
import ShippingHubFormModal from "features/shippinghub/form/ShippingHubFormModal";
import ShippingHubListHeader from "features/shippinghub/list/ShippingHubListHeader";
import useFilterLocation from "hooks/useFilterLocation";
import useStateFilter from "hooks/useStateFilter";
import update from "immutability-helper";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconMapPin } from "@tabler/icons-react";

import ShippingHubListFilter from "./ShippingHubListFilter";

const ShippingHubList = () => {
  const { t } = useTranslation();

  //////////////////////////////////////////
  //Filtering
  const defaultFilters: FilterShippingHub = useMemo(
    () => ShippingHubRepository.getDefaultFilters(),
    []
  );
  const [filters, setFilters] =
    useStateFilter<FilterShippingHub>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //////////////////////////////////////////
  //Fetch data from this collections
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ShippingHubModel[]>([]);
  const fetchData = useCallback(async (): Promise<ShippingHubCollection> => {
    let collection = await new ShippingHubRepository().getItems({
      filters,
    });
    setDataSource(collection.items);
    setTotal(collection.total);
    return collection;
  }, [filters]);

  //////////////////////////////////////////
  // modal editing
  const [addVisible, setAddVisible] = useState(false);
  const [editingId, setEditingId] = useState(0);

  //////////////////////////////////////////
  //Table columns
  const columns: TableColumnsType<ShippingHubModel> = [
    {
      title: t("common:sid"),
      key: "id",
      align: "center",
      width: 60,
      render: (id) => {
        return (
          <TableLinkText
            title={t("common:table.edit")}
            onClick={() => onEdit(id)}
          >
            {id}
          </TableLinkText>
        );
      },
    },
    {
      title: t("shippinghub:name"),
      key: "name",
      sortby: "name",
      width: 200,
    },
    {
      title: t("shippinghub:address"),
      key: "address",
    },
    {
      title: t("common:coord"),
      key: "coord",
      render: (_: any, record: ShippingHubModel) => {
        return (
          <span>
            {record.lat !== 0 && record.long !== 0 ? (
              <a
                target="_blank"
                className="text-blue-600 "
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  record.lat +
                  "," +
                  record.long
                }
                rel="noreferrer"
              >
                <IconMapPin size="16" />
              </a>
            ) : null}
          </span>
        );
      },
      width: 80,
      align: "center",
    },
    {
      title: t("common:display_order_long"),
      key: "display_order",
      sortby: "display_order",
      align: "center",
      width: 140,
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      fixed: "right",
      width: 125,
      render: (_: any, record: ShippingHubModel) => (
        <RoleCheck roles={[Role.SETTING_DMS]} hideOnFail>
          <TableEdit link="" onClick={() => onEdit(record.id)} />
          <TableDelete
            error_translate_prefix="shippinghub:form.error"
            onDeleteCallback={(id) => {
              setDataSource(dataSource.filter((item) => item.id !== id));
            }}
            repository={new ShippingHubRepository()}
            id={record.id}
          />
          <TableInfo record={record}></TableInfo>
        </RoleCheck>
      ),
    },
  ];

  const onEdit = (id: number) => {
    setEditingId(id);
    setAddVisible(true);
  };

  const onSaveSuccess = (item: ShippingHubModel) => {
    //detech this is NEW or UPDATE
    const foundIndex = dataSource.findIndex((r) => r.id === item.id);
    if (foundIndex >= 0) {
      //update current role item info
      setDataSource(
        update(dataSource, {
          [foundIndex]: {
            $set: item,
          },
        })
      );
    } else {
      //append new item to list
      setDataSource(
        update(dataSource, {
          $push: [item],
        })
      );
    }
  };

  return (
    <>
      <ShippingHubListHeader>
        <RoleCheck roles={[Role.SETTING_DMS]}>
          <Button
            type="primary"
            onClick={() => {
              setEditingId(0);
              setAddVisible(true);
            }}
          >
            {t("shippinghub:add_button")}
          </Button>
        </RoleCheck>
      </ShippingHubListHeader>
      <ShippingHubListFilter
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        total={total}
      />
      <PageDataTable<FilterShippingHub, ShippingHubModel, ShippingHubCollection>
        {...{
          columns,
          defaultFilters,
          filters,
          setFilters,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>

      <PageDataPagination
        total={total}
        filters={filters}
        setFilters={setFilters}
        dataSource={dataSource}
      />

      <ShippingHubFormModal
        id={editingId}
        key={editingId}
        open={addVisible}
        setOpen={(isOpen) => {
          //clear editing id when close
          if (!isOpen) {
            setEditingId(0);
          }
          setAddVisible(isOpen);
        }}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ShippingHubList;
