import { Breadcrumb } from "antd";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import React from "react";

import { IconFolder } from "@tabler/icons-react";

const FileListBreadcrumb = ({
  routes,
  onClick,
}: {
  routes: ItemType[];
  onClick: (route: ItemType) => void;
}) => {
  return (
    <Breadcrumb
      className="text-gray-700"
      items={routes}
      itemRender={(route: ItemType, params: any) => {
        const isLast =
          routes.length > 1 && routes[routes.length - 1].path === route.path;

        if (isLast) {
          return route.breadcrumbName;
        }

        return (
          <a
            style={{ color: "#000000" }}
            href={route.path}
            type="text"
            onClick={(e) => {
              e.preventDefault();
              onClick(route);
            }}>
            {route.path === "" ? (
              <>
                <IconFolder size="16" className="-mt-0.5" />{" "}
                {route.breadcrumbName}
              </>
            ) : (
              route.breadcrumbName
            )}
          </a>
        );
      }}
    />
  );
};

export default FileListBreadcrumb;
