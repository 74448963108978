import ShippingOrderCollection from "common/collections/ShippingOrderCollection";
import ShippingOrderModel from "common/models/ShippingOrderModel";

import AxiosClient from "./AxiosClient";
import BaseApi from "./BaseApi";

import type { GetListProps } from "common/types/Filter";
import type {
  FilterShippingOrder,
  ShippingOrderJsonEdit,
} from "common/types/ShippingOrder";
const SERVICE_URL = "/shippingorders";

class ShippingOrderApi extends BaseApi {
  async getList(
    props: GetListProps<FilterShippingOrder>,
  ): Promise<ShippingOrderCollection> {
    let collection = new ShippingOrderCollection();

    try {
      let filters = props.filters;

      let queryData = {
        params: {
          page: filters.page,
          limit: filters.limit,
          sort_by: filters.sortby,
          sort_type: filters.sorttype,
          keyword: filters.keyword ? filters.keyword : null,
          status: +filters.status >= 0 ? filters.status : null,
          creator_id: +filters.creator_id > 0 ? filters.creator_id : null,
          from_warehouse_id:
            +filters.from_warehouse_id > 0 ? filters.from_warehouse_id : null,
          to_warehouse_id:
            +filters.to_warehouse_id > 0 ? filters.to_warehouse_id : null,
          merchant_id: +filters.merchant_id > 0 ? filters.merchant_id : null,
          office_id: +filters.office_id > 0 ? filters.office_id : null,
          office_id_to: +filters.office_id_to > 0 ? filters.office_id_to : null,
          service_type_id:
            +filters.service_type_id > 0 ? filters.service_type_id : null,
          foreign_id: +filters.foreign_id > 0 ? filters.foreign_id : null,
          ensurance_type:
            +filters.ensurance_type > 0 ? filters.ensurance_type : null,
          shipping_type:
            +filters.shipping_type > 0 ? filters.shipping_type : null,
          type: +filters.type >= 0 ? filters.type : null,
          product_type: +filters.product_type > 0 ? filters.product_type : null,
          order_shipping_region:
            +filters.order_shipping_region > 0
              ? filters.order_shipping_region
              : null,
          order_shipping_subregion:
            +filters.order_shipping_subregion > 0
              ? filters.order_shipping_subregion
              : null,
          order_shipping_thirdregion:
            +filters.order_shipping_thirdregion > 0
              ? filters.order_shipping_thirdregion
              : null,
          route_id: +filters.route_id > 0 ? filters.route_id : null,
          source: +filters.source > 0 ? filters.source : null,
          delivery_status:
            +filters.delivery_status > 0 ? filters.delivery_status : null,
          pickup_status:
            +filters.pickup_status > 0 ? filters.pickup_status : null,
          return_status:
            +filters.return_status > 0 ? filters.return_status : null,
          cod_status: +filters.cod_status > 0 ? filters.cod_status : null,
          fail_reason: +filters.fail_reason > 0 ? filters.fail_reason : null,
          ymd: +filters.ymd > 0 ? filters.ymd : null,
          ymdstart: +filters.ymdstart > 0 ? filters.ymdstart : null,
          ymdend: +filters.ymdend > 0 ? filters.ymdend : null,
          date_started: +filters.date_started > 0 ? filters.date_started : null,
          date_ended: +filters.date_ended > 0 ? filters.date_ended : null,
          id: +filters.id > 0 ? filters.id : null,
          hourstart: +filters.hourstart > 0 ? filters.hourstart : null,
          hourend: +filters.hourend > 0 ? filters.hourend : null,
          random_code: filters.random_code ? filters.random_code : null,
          route_ids: filters.route_ids ? filters.route_ids : null,
          statuses: filters.statuses ? filters.statuses : null,
          shipper_id: +filters.shipper_id > 0 ? filters.shipper_id : null,
          status_asigned_route:
            filters.status_asigned_route > 0
              ? filters.status_asigned_route
              : null,
        },
      };

      const response = await AxiosClient().get<any>(SERVICE_URL, queryData);
      if (response.hasOwnProperty("data")) {
        collection.fromJson(response.data);
      }
    } catch (error) {
      collection.withError(BaseApi.handleError(error));
    }

    return collection;
  }

  async getDetail(id: number): Promise<ShippingOrderModel> {
    let item = new ShippingOrderModel(ShippingOrderModel.getDefaultData());

    try {
      const response = await AxiosClient().get<any>(SERVICE_URL + "/" + id);
      if (response.hasOwnProperty("data")) {
        item = new ShippingOrderModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async add(data: ShippingOrderJsonEdit): Promise<ShippingOrderModel> {
    let item = new ShippingOrderModel(ShippingOrderModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().post<any>(SERVICE_URL, postData);
      if (response.hasOwnProperty("data")) {
        item = new ShippingOrderModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async edit(data: ShippingOrderJsonEdit): Promise<ShippingOrderModel> {
    let item = new ShippingOrderModel(ShippingOrderModel.getDefaultData());

    try {
      let postData = {
        ...data,
      };

      //For create item
      const response = await AxiosClient().put<any>(
        SERVICE_URL + "/" + data.id,
        postData,
      );
      if (response.hasOwnProperty("data")) {
        item = new ShippingOrderModel(response.data);
      }
    } catch (error) {
      item.withError(BaseApi.handleError(error));
    }

    return item;
  }

  async delete(id: number): Promise<string[]> {
    let resultErrors = [];

    try {
      const response = await AxiosClient().delete(SERVICE_URL + "/" + id);
      if (response.status !== 204) {
        resultErrors.push("error_delete_response_not_204");
      }
    } catch (error) {
      resultErrors = BaseApi.handleError(error).errors;
    }

    return resultErrors;
  }
}

export default ShippingOrderApi;
