import { BaseModelJson } from 'common/interfaces/BaseModelJson';

import BaseModel from './BaseModel';

import type { ProductTransmitDetailJson } from "common/types/ProductTransmitDetail";

class ProductTransmitDetailModel
  extends BaseModel
  implements BaseModelJson<ProductTransmitDetailJson>
{
  company_id: number;
  creator_id: number;
  product_id: number;
  product_variant_id: number;
  product_sku: string;
  product_transmit_id: number;
  id: number;
  quantity: number;
  from_item_quantity: number;
  from_item_quantity_initial: number;
  to_item_quantity: number;
  to_item_quantity_initial: number;
  date_created: number;
  date_modified: number;
  deviant_quantity: number;

  constructor(json: ProductTransmitDetailJson) {
    super();

    this.company_id = json.company_id || 0;
    this.creator_id = json.creator_id || 0;
    this.product_id = json.product_id || 0;
    this.product_variant_id = json.product_variant_id || 0;
    this.product_sku = json.product_sku || "";
    this.product_transmit_id = json.product_transmit_id || 0;
    this.id = json.id || 0;
    this.quantity = json.quantity || 0;
    this.from_item_quantity = json.from_item_quantity || 0;
    this.from_item_quantity_initial = json.from_item_quantity_initial || 0;
    this.to_item_quantity = json.to_item_quantity || 0;
    this.to_item_quantity_initial = json.to_item_quantity_initial || 0;
    this.date_created = json.date_created || 0;
    this.date_modified = json.date_modified || 0;
    this.deviant_quantity = json.from_item_quantity - json.to_item_quantity;
  }

  static getDefaultData(): ProductTransmitDetailJson {
    return {
      company_id: 0,
      creator_id: 0,
      product_id: 0,
      product_variant_id: 0,
      product_sku: "",
      product_transmit_id: 0,
      id: 0,
      from_item_quantity: 0,
      from_item_quantity_initial: 0,
      to_item_quantity: 0,
      to_item_quantity_initial: 0,
      quantity: 0,
      date_created: 0,
      date_modified: 0,
      deviant_quantity: 0,
    };
  }

  toJson(): ProductTransmitDetailJson {
    return {
      company_id: this.company_id,
      creator_id: this.creator_id,
      product_id: this.product_id,
      product_variant_id: this.product_variant_id,
      product_sku: this.product_sku,
      product_transmit_id: this.product_transmit_id,
      id: this.id,
      quantity: this.quantity,
      from_item_quantity: this.from_item_quantity,
      from_item_quantity_initial: this.from_item_quantity_initial,
      to_item_quantity: this.to_item_quantity,
      to_item_quantity_initial: this.to_item_quantity_initial,
      date_created: this.date_created,
      date_modified: this.date_modified,
      deviant_quantity: this.from_item_quantity - this.to_item_quantity,
    };
  }
}

export default ProductTransmitDetailModel;
