import { Col, Row, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterDeliverySumByManager } from "common/types/DeliverySumbyManager";
import DeliverySumbyManagerModel from "common/models/DeliverySumbyManagerModel";
import DeliverySumbyManagerRepository from "common/repositories/DeliverySumbyManagerRepository";
import DeliveryStatisic from "./DeliveryStatisic";

const DeliverySumByManager = ({
  actorid = 0,
  defaultFilters,
  setFilters,
  filters,
  type = "manager",
}: {
  actorid?: number;
  defaultFilters: FilterDeliverySumByManager;
  setFilters: any;
  filters: FilterDeliverySumByManager;
  type?: "manager" | "accountant" | "report";
}) => {
  const { t } = useTranslation();

  //
  const [dataSumBy, setDataSumBy] = useState<DeliverySumbyManagerModel>(
    new DeliverySumbyManagerModel(DeliverySumbyManagerModel.getDefaultData())
  );

  //sumby

  const fetchData = async () => {
    if (type === "report") {
      const collection =
        await new DeliverySumbyManagerRepository().getSumByReport(filters);
      if (collection) {
        setDataSumBy(collection);
      } else {
        message.error(t("common:error.error_fetching_data"));
      }
    } else if (type === "accountant") {
      const collection =
        await new DeliverySumbyManagerRepository().getSumByAccountant(filters);
      if (collection) {
        setDataSumBy(collection);
      } else {
        message.error(t("common:error.error_fetching_data"));
      }
    } else if (type === "manager") {
      const collection =
        await new DeliverySumbyManagerRepository().getSumByManager(filters);
      if (collection) {
        setDataSumBy(collection);
      } else {
        message.error(t("common:error.error_fetching_data"));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  return (
    <div className="">
      <Space
        style={{
          margin: "24px 16px 0px",
          height: "auto",
          display: "block",
        }}
      ></Space>
      <Row gutter={[16, 16]} className="p-5">
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title={t("reportdelivery:sumby.total_title")}
            count={dataSumBy.total_count}
            total={dataSumBy.total_sum}
            explainContent={t("reportdelivery:sumby.total_desc")}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title={t("reportdelivery:sumby.totalcod_title")}
            count={dataSumBy.totalcod_count}
            total={dataSumBy.totalcod_sum}
            explainContent={t("reportdelivery:sumby.totalcod_desc")}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title={t("reportdelivery:sumby.transfer_title")}
            count={dataSumBy.transfer_count}
            total={dataSumBy.transfer_sum}
            explainContent={t("reportdelivery:sumby.transfer_desc")}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title={t("reportdelivery:sumby.current_title")}
            count={dataSumBy.current_count}
            total={dataSumBy.current_sum}
            explainContent={t("reportdelivery:sumby.current_desc")}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title={t("reportdelivery:sumby.returned_title")}
            count={dataSumBy.returned_count}
            total={dataSumBy.returned_sum}
            explainContent={t("reportdelivery:sumby.returned_desc")}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="">
          <DeliveryStatisic
            title={t("reportdelivery:sumby.receiving_title")}
            count={dataSumBy.receiving_count}
            total={dataSumBy.receiving_sum}
            explainContent={t("reportdelivery:sumby.receiving_desc")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DeliverySumByManager;
