import { Col, Form, Input, Row } from 'antd';
import CmsNews from 'common/constants/CmsNews';
import File from 'common/constants/File';
import CmsNewsModel from 'common/models/CmsNewsModel';
import FileModel from 'common/models/FileModel';
import CmsNewsRepository from 'common/repositories/CmsNewsRepository';
import FileInput from 'components/form/fileinput/FileInput';
import FormSection from 'components/form/FormSection';
import FormSectionSeo from 'components/form/FormSectionSeo';
import FormSelect from 'components/form/FormSelect';
import LayoutForm from 'components/form/LayoutForm';
import HtmlEditorInput from 'components/htmleditorinput/HtmlEditorInput';
import CmsNewsEditorHeader from 'features/cmsnews/form/CmsNewsFormEditorHeader';
import CmsNewsCategoryFormSelect from 'features/cmsnewscategory/CmsNewsCategoryFormSelect';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CmsNewsJsonAddEdit } from "common/types/CmsNews";
const CmsNewsFormEditor = ({ model }: { model: CmsNewsModel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = model.id > 0;

  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  // modal editing

  const initialValues = {
    title: model.title,
    content: model.content,
    news_category_id:
      model.news_category_id > 0 ? model.news_category_id : null,
    status: model.status || CmsNews.STATUS_ENABLE,
    file_id: model.file_id,
    image_input: {
      files: FileModel.convertToUploadFiles([model.image]),
      first_file_id: model.file_id,
    },
    seo_slug: model.seo_slug,
    seo_title: model.seo_title,
    seo_keyword: model.seo_keyword,
    seo_description: model.seo_description,
    graph_image_id: model.graph_image_id,
    graph_image_input: {
      files: FileModel.convertToUploadFiles([model.graph_image]),
      first_file_id: model.graph_image_id,
    },
  };

  //prepare data for submit
  const doPrepareData = useCallback(
    (formData: any) => {
      let file_id =
        typeof formData.image_input.file_id_list?.[0] !== "undefined"
          ? formData.image_input.file_id_list?.[0]
          : model.file_id;

      let graph_image_id =
        typeof formData.graph_image_input.file_id_list?.[0] !== "undefined"
          ? formData.graph_image_input.file_id_list?.[0]
          : model.graph_image_id;

      const submitData: CmsNewsJsonAddEdit = {
        ...CmsNewsModel.getDefaultData(),
        id: model.id,
        title: formData.title,
        content: formData.content,
        news_category_id: formData.news_category_id,
        status: formData.status || CmsNews.STATUS_ENABLE,
        file_id: file_id,
        seo_slug: formData.seo_slug,
        seo_title: formData.seo_title,
        seo_keyword: formData.seo_keyword,
        seo_description: formData.seo_description,
        graph_image_id: graph_image_id,
      };

      return submitData;
    },
    [model.id, model.graph_image_id, model.file_id]
  );

  //submit data to server
  const onSubmit = async (formData: any) => {
    let myObj: CmsNewsModel = await new CmsNewsRepository().saveRemote(
      doPrepareData(formData)
    );
    if (myObj.hasError()) {
      setErrors(myObj.error.errors);
    } else {
      setIsSuccess(true);
    }
  };

  //for edit, show status
  const sidebarItems = (
    <div>
      <Col>
        <Form.Item name="image_input" label={t("cmsnews:file_id")}>
          <FileInput objectType={File.OBJECTTYPE_ATTACHMENT} origin="cms" />
        </Form.Item>
      </Col>
      <FormSelect
        label={t("common:status")}
        name="status"
        required
        options={CmsNewsModel.getStatusList()}
      />
    </div>
  );

  return (
    <>
      <CmsNewsEditorHeader isEditing={isEditing} />
      <LayoutForm
        form={form}
        initialValues={initialValues}
        errors={errors}
        isSuccess={isSuccess}
        successTitle={
          isEditing
            ? t("cmsnews:form.success.update")
            : t("cmsnews:form.success.add")
        }
        redirectOnSuccess="/cmsnews"
        error_translate_prefix="cmsnews:form.error"
        onSubmit={onSubmit}
        submitText={isEditing ? t("common:form.save") : t("common:form.submit")}
        sidebarItems={sidebarItems}
      >
        <FormSection
          title={t("cmsnews:form.section_title")}
          subtitle={t("cmsnews:form.section_description")}
          divider
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label={t("cmsnews:title")}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t("cmsnews:form.error.error_title_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <CmsNewsCategoryFormSelect
                label={t("cmsnews:news_category_id")}
                name="news_category_id"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item label={t("news:content")} name="content">
                <HtmlEditorInput
                  fileObjectType={File.OBJECTTYPE_CMS}
                  fileOrigin="cms"
                />
              </Form.Item>
            </Col>
          </Row>
        </FormSection>

        <FormSectionSeo
          fileObjectType={File.OBJECTTYPE_ATTACHMENT}
          fileOrigin="cms"
        />
      </LayoutForm>
    </>
  );
};

export default CmsNewsFormEditor;
