import React, { useCallback, useMemo, useState } from "react";
import ContractTypeListHeader from "./ContractTypeListHeader";
import { Button, Dropdown, MenuProps, Tag } from "antd";
import { useTranslation } from "react-i18next";

import ContractTypeRepository from "common/repositories/ContractTypeRepositories";
import useStateFilter from "hooks/useStateFilter";
import useFilterLocation from "hooks/useFilterLocation";
import ContractModel from "common/models/ContractTypeModel";
import { TableColumnsType } from "common/types/Table";
import TableDelete from "components/table/TableDelete";
import PageDataTable from "components/page/PageDataTable";
import TextDateTime from "components/TextDateTime";
import ContractTypesFormModal from "../form/ContractTypeFormModal";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import { FilterContractType } from "common/types/ContractType";
import ContractCollection from "common/collections/ContractTypeCollection";

const ContractTypesList = () => {
  const { t } = useTranslation();

  //filter
  const defaultFilters: FilterContractType = useMemo(() => {
    return ContractTypeRepository.getDefaultFilters();
  }, []);
  const [filters, setFilters] =
    useStateFilter<FilterContractType>(defaultFilters);
  useFilterLocation(defaultFilters, filters);

  //state
  const [dataSource, setDataSource] = useState<ContractModel[]>([]);
  const [editingId, setEditingId] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  //fetch data from this collection

  //function
  const fetchData = useCallback(async (): Promise<ContractCollection> => {
    const response = await new ContractTypeRepository().getItems({
      filters: filters,
    });
    setDataSource(response.items);
    return response;
  }, [filters]);

  //functions
  const onEdit = (id: number) => {
    setEditingId(id);
    setVisible(true);
  };

  const onSaveSuccess = (item: ContractModel) => {
    fetchData();
  };

  //table columns
  const columns: TableColumnsType<any> = [
    {
      title: t("ID"),
      key: "id",
      width: 60,
    },
    {
      title: t("contract:type_contract"),
      key: "work_status",
      width: 200,
      render: (_: any, record: ContractModel) =>
        record.work_status === 1 ? (
          <Tag color="blue">{t("contract:official_staff")}</Tag>
        ) : (
          <Tag color="orange">{t("contract:probationary_staff")}</Tag>
        ),
    },
    {
      title: t("contract:name_contract"),
      key: "name",
      width: 200,
    },

    {
      title: t("contract:note_contract"),
      key: "note",
      width: 300,
    },
    {
      title: t("contract:date_created"),
      key: "date_created",
      width: 100,
      render: (_: any, record: ContractModel) => (
        <>
          {record.date_created > 0 ? (
            <TextDateTime format="DD/MM/YYYY" ts={record.date_created} />
          ) : (
            "----"
          )}
        </>
      ),
    },
    // {
    //   title: t("Trạng thái"),
    //   key: "status",
    //   width: 100,
    //   render: (_: any, record: ContractModel) =>
    //     record.status === 1 ? (
    //       <Tag color="green">{t("Hoàn thiện")}</Tag>
    //     ) : (
    //       <Tag color="red">{t("Bản nháp")}</Tag>
    //     ),
    // },
    {
      title: t(""),
      key: "actions",
      width: 100,
      align: "right",
      fixed: "right",
      render: (_: any, record: ContractModel) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <Button
                size="small"
                type="text"
                disabled={editing}
                loading={editing}
                className="w-full flex justify-start px-0 pl-1"
                onClick={() => onEdit(record.id)}
              >
                <div className="h-full w-full flex items-center justify-start gap-2">
                  <IconEdit size="18" className=" text-gray-400 " />
                  {t("common:table.edit")}
                </div>
              </Button>
            ),
          },
          {
            key: "2",
            label: (
              <TableDelete
                className="w-full flex justify-start px-0 items-center gap-2"
                error_translate_prefix={"comment:form.error"}
                onDeleteCallback={(id) => {
                  setDataSource(
                    dataSource.filter((item: any) => item.id !== id)
                  );
                }}
                label={t("common:table.delete")}
                repository={new ContractTypeRepository()}
                id={record.id}
                icon={
                  <IconTrash
                    // className=" text-gray-400 hover:text-red-500"
                    size="18"
                  />
                }
              />
            ),
          },
        ];

        return (
          <>
            <Dropdown menu={{ items }} placement="bottomRight">
              <IconDotsVertical
                style={{ fontSize: "16pt" }}
                className="cursor-pointer "
              />
            </Dropdown>
          </>
        );
      },
    },
  ];
  return (
    <>
      <ContractTypeListHeader>
        <Button
          type="primary"
          onClick={() => {
            setEditingId(0);
            setVisible(true);
          }}
        >
          {t("common:table.add_button")}
        </Button>
      </ContractTypeListHeader>
      <PageDataTable<FilterContractType, ContractModel, ContractCollection>
        {...{
          defaultFilters,
          filters,
          setFilters,
          columns,
          dataSource,
          fetchData,
        }}
      ></PageDataTable>
      <ContractTypesFormModal
        id={editingId}
        key={editingId}
        visible={visible}
        setVisible={setVisible}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default ContractTypesList;
